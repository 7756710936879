import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, withStyles, Typography, IconButton, MenuItem, Chip, TableCell, TableRow, Table, TableHead, TableBody, TableSortLabel } from '@material-ui/core';
import classNames from 'classnames';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import Search from '../TextBox/Search.jsx';
import ReferenceConfig from './ReferenceConfig.jsx';
import { getReferenceLibrary, updateReferenceLibrary, uploadReferenceLibrary, deleteReferenceLibrary } from '../../actions/settingActions';
import { nanoid } from 'nanoid';
import Loader from '../Loaders/Loader.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import ToolTipComponent from '../../components/Tooltip/Tooltip.jsx';
import { appConstants } from '../../constants/appConstants';
import { sortTable } from '../../helpers/appHelpers.js';


const Reference = (props) => {
    const { classes, theme, organizationId, type } = props;
    const [open, setOpen] = useState(false);
    // const [isSearch, enableSearch] = useState(false);
    const [search, setSearch] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [newLibrary] = useState({
        "name": '', "type": '', "path": '', "ref_columns": '', "valid_values": [], "invalid_values": [], "file_type": "", "is_referenced": false, "is_default": false, "parameters": [{ key: '', value: '' }]
    });
    const [libraries, setLibraries] = useState([{ ...newLibrary }]);
    const [paramIndex, setParamIndex] = useState(-1);
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [isChanged, setisChanged] = useState(false);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');

    const onChangeOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    const getReference = useCallback(() => {
        setLoading(true);
        dispatch(getReferenceLibrary(organizationId)).then((response) => {
            setLoading(false);
            const libraryList = response && response.length !== 0 ? response : [{ ...newLibrary }];
            setLibraries([...libraryList]);
        });
    }, [dispatch, newLibrary, organizationId]);

    useEffect(() => {
        getReference();
    }, [getReference, organizationId]);
    const openPopover = (event, index) => {
        setOpen(true);
        setAnchorEl(event.target);
        setParamIndex(index);
    };

    const closePopover = () => {
        setOpen(false);
    };

    const addLibrary = () => {
        setOrderBy('');
        const libarary = libraries.find((data) => data.name === "");
        if (!libarary) {
            libraries.unshift({ ...newLibrary });
            setLibraries([...libraries]);
        }
    };

    const onChangeSearch = (value) => {
        setSearch(value);
    };

    const onChange = (property, index, value) => {
        libraries[index][property] = value;
        if (property === "type") {
            libraries[index].path = "";
            libraries[index].parameters = [{ "key": "", "value": "" }];
        }
        setLibraries([...libraries]);
        setisChanged(true);
        if (property === "path" || property === "api_url") {
            onSave(index, property);
        }
    };

    const onChangeType = (event, index) => {
        libraries[index][event.target.name] = event.target.value;
        libraries[index].path = "";
        libraries[index].parameters = [{ "key": "", "value": "" }];
        setOpen(true);
        setAnchorEl(event.target);
        setParamIndex(index);
        setLibraries([...libraries]);
        onSave(index, event.target.name);
    };

    const onRemoveConfig = (index, id) => {
        if (id) {
            libraries[index].isLoading = true;
            dispatch(deleteReferenceLibrary(id, organizationId)).then(() => {
                // libraries[index].isLoading = false;
                setLibraries([...libraries]);
            });
        }
        libraries.splice(index, 1);
        if (libraries.length === 0) {
            libraries.unshift({ ...newLibrary });
        }
        setLibraries([...libraries]);
    };

    const addParameter = () => {
        libraries[paramIndex].parameters.push({ key: '', value: '' });
        setLibraries([...libraries]);
    };

    const removeParameter = (index) => {
        libraries[paramIndex].parameters.splice(index, 1);
        setLibraries([...libraries]);
    };

    const onChangeParameter = (property, value, index) => {
        if (property === "path") {
            libraries[paramIndex][property] = value;
        } else {
            libraries[paramIndex].parameters[index][property] = value;
        }
        setLibraries([...libraries]);
    };


    const saveParameters = () => {
        setOpen(false);
        onSave(paramIndex, 'path');
    };

    const onSave = (index, property) => {
        let model = {};
        if (libraries[index].name !== "") {
            libraries[index].isLoading = true;
            if (!libraries[index].id) {
                libraries[index].id = nanoid();
                model = {
                    ...libraries[index],
                    "index": index,
                    "is_create": true
                };
                delete model.isLoading;
            } else {
                model = {
                    "index": index,
                    "property": property,
                    "value": libraries[index][property],
                    "parameters": libraries[index].parameters,
                    "is_create": false
                };
            }
            setLibraries([...libraries]);
            dispatch(updateReferenceLibrary(model, organizationId)).then(() => {
                libraries[index].isLoading = false;
                setLibraries([...libraries]);
            });
        }
    };

    const onSaveName = (index, value) => {

        const libraryNames = libraryList.map((data) => data.name);
        const duplicate = libraryNames.filter((data, j) => index !== j && data.toLowerCase() === value.toLowerCase());
        const regex = /^[A-Za-z][A-Za-z0-9]+$/;
        libraryList[index].isduplicate = false;
        libraryList[index].isInvalidName = false;
        if (duplicate.length > 0) {
            libraryList[index].isduplicate = true;
        } else if (!regex.test(value)) {
            libraryList[index].isInvalidName = true;
        } else if (isChanged) {
            onSave(index, "name");
        }
        setLibraries([...libraryList]);
    };

    const uploadFiles = (files) => {
        const file = new FormData();
        file.append('file', files[0]);
        file.append('index', paramIndex);
        libraries[paramIndex].isLoading = true;
        setOpen(false);
        dispatch(uploadReferenceLibrary(file, organizationId)).then((response) => {
            if (response) {
                libraries[paramIndex].path = response;
            }
            libraries[paramIndex].isLoading = false;
            setLibraries([...libraries]);
        });
    };

    const validateReferenceName = (library) => {
        if (library.isduplicate) {
            return "Name is Duplicated";
        } else if (library.isInvalidName) {
            return "Reference Name should be without special characters";
        }
        return "";
    };

    const libraryList = search !== "" ? libraries.filter((item) => item.name.toLowerCase().includes(search.toLowerCase())) : libraries;

    return (
        <Grid className={type === "settingsPanel" ? classes.referenceContainer : classes.referenceContainer2}>
            <Grid container justify="space-between" className={classes.marginBottom15}>
                <Typography variant="h5" component="h5">
                    Reference Lists
                </Typography>
                <Grid item className={classes.search} >
                    <Search value={search}
                        placeholder="Search Ref.name"
                        onChange={(value) => onChangeSearch(value)}
                        className={classNames(classes.dqSearchBoxRefList)} />
                    <ToolTipComponent title={"Add Reference"} arrow>
                        <IconButton className={classNames(classes.marginLeft10, classes.alignIconCenter)} onClick={() => addLibrary()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                    <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                        <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                        <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                    </g>
                                </g>
                            </svg>
                        </IconButton>
                    </ToolTipComponent>
                </Grid>
            </Grid>
            <ValidatorForm onSubmit={() => { }}>
                <Table className={classNames(classes.standardTable)} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                appConstants && appConstants.referenceTableHeader &&
                                appConstants.referenceTableHeader.map((header, index) => {
                                    return (
                                        <TableCell key={index} sortDirection={orderBy === header.id ? order : false}>
                                            {
                                                header.sort === false ?
                                                    <Typography variant="body1" className={classes.tableHeader}>
                                                        {header.label}
                                                    </Typography> :
                                                    <TableSortLabel
                                                        active={orderBy === header.id}
                                                        direction={orderBy === header.id ? order : 'asc'}
                                                        onClick={() => onChangeOrder(header.id)}
                                                    >
                                                        <Typography variant="body1" className={classes.tableHeader}>
                                                            {header.label}
                                                        </Typography>
                                                    </TableSortLabel>
                                            }

                                        </TableCell>
                                    );
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            libraryList && sortTable(libraryList, order, orderBy).map((library, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Grid>
                                                <TextBox
                                                    placeholder="Reference Name"
                                                    value={library.name ? library.name : ''}
                                                    className={classNames(classes.inlinetxt, classes.referenceInput)}
                                                    name="name"
                                                    onChange={(event) => onChange(event.target.name, index, event.target.value)}
                                                    // onBlur={(event) => onSave(index, event.target.name)}
                                                    onBlur={(event) => onSaveName(index, event.target.value)}
                                                    fullWidth
                                                    error={library.isduplicate || library.isInvalidName}
                                                    helperText={validateReferenceName(library)}
                                                    disabled={library.is_default || library.is_referenced} />
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid>
                                                {
                                                    library.id &&
                                                    <TextBox
                                                        placeholder="Reference Type"
                                                        value={library.type ? library.type : ''}
                                                        select
                                                        name="type"
                                                        onChange={(event) => onChangeType(event, index)}
                                                        // onBlur={(event) => onSave(index, event.target.name)}
                                                        className={classNames(classes.inlinetxt, classes.referenceInput)}
                                                        fullWidth
                                                        disabled={library.is_default || library.is_referenced}
                                                    >
                                                        <MenuItem value="API" className={classes.referenceMenu}>
                                                            API
                                                        </MenuItem>
                                                        <MenuItem value="File" className={classes.referenceMenu}>
                                                            File
                                                        </MenuItem>
                                                    </TextBox>
                                                }

                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid>
                                                {
                                                    (library.path !== "") && library.id &&
                                                    <Grid>
                                                        {
                                                            (!library.is_default || (!library.is_referenced && !library.is_default)) ?
                                                                <ToolTipComponent title={library.path} arrow>
                                                                    <Chip
                                                                        className={classNames(classes.chip, classes.referenceChip)}
                                                                        label={library.path}
                                                                        onDelete={() => onChange(library.type === "File" ? "path" : "api_url", index, "")}
                                                                        deleteIcon={
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="8.029" height="8.029" viewBox="0 0 8.029 8.029">
                                                                                <path fill="#c8cbcc" d="M4.725,4.015,7.882.858a.5.5,0,1,0-.71-.71L4.015,3.305.857.148a.5.5,0,0,0-.71.71L3.3,4.015.147,7.173a.5.5,0,1,0,.71.71L4.015,4.725,7.172,7.883a.5.5,0,1,0,.71-.71Z" transform="translate(0 -0.001)" />
                                                                            </svg>
                                                                        }
                                                                    />
                                                                </ToolTipComponent> :
                                                                <ToolTipComponent title={library.path} arrow>
                                                                    <Chip
                                                                        className={classes.chip}
                                                                        label={library.path}
                                                                    />
                                                                </ToolTipComponent>
                                                        }
                                                    </Grid>
                                                }

                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid align="center">
                                                <Grid container alignItems="center" justify="center" className={classes.libraryTabActions}>
                                                    {
                                                        <Grid className={classNames((!library.is_default || (!library.is_referenced && !library.is_default)) && !library.isLoading && library.id && "active", "libActionItem")}>
                                                            <ToolTipComponent title={"Reference Settings"} arrow>
                                                                <IconButton className={classNames(classes.referenceIcon, classes.referenceSettingIcon)} onClick={(event) => openPopover(event, index)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.823" height="16.249" viewBox="0 0 15.823 16.249">
                                                                        <path fill={theme.palette.primary.main} d="M22.26,9.905,20.766,8.737a5.88,5.88,0,0,0,.035-.613,5.7,5.7,0,0,0-.035-.613l1.495-1.169a.732.732,0,0,0,.177-.933L20.884,2.722a.72.72,0,0,0-.9-.323l-1.762.708a6.042,6.042,0,0,0-1.058-.614L16.9.623A.719.719,0,0,0,16.18,0H13.065a.717.717,0,0,0-.716.617L12.08,2.494a6.229,6.229,0,0,0-1.056.614L9.257,2.4a.734.734,0,0,0-.892.318L6.81,5.406a.728.728,0,0,0,.176.938L8.48,7.512a5.357,5.357,0,0,0,0,1.226L6.985,9.907a.732.732,0,0,0-.176.933l1.553,2.687a.719.719,0,0,0,.9.323l1.762-.708a6.1,6.1,0,0,0,1.058.614l.268,1.869a.718.718,0,0,0,.718.624H16.18a.718.718,0,0,0,.717-.617l.269-1.875a6.258,6.258,0,0,0,1.056-.615l1.767.71a.728.728,0,0,0,.265.05.715.715,0,0,0,.626-.367l1.56-2.7A.731.731,0,0,0,22.26,9.905Zm-7.637.928a2.708,2.708,0,1,1,2.708-2.708A2.711,2.711,0,0,1,14.622,10.833Z" transform="translate(-6.71)" />
                                                                    </svg>
                                                                </IconButton>
                                                            </ToolTipComponent>
                                                        </Grid>
                                                    }
                                                    {
                                                        <Grid className={classNames((!library.is_default || (!library.is_referenced && !library.is_default)) && !library.isLoading && "active", "libActionItem")}>
                                                            <ToolTipComponent title={"Delete Reference"} arrow>
                                                                <IconButton className={classes.referenceIcon} onClick={() => onRemoveConfig(index, library.id)}>
                                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 32 32">
                                                                        <g id="Delete">
                                                                            <path fill={theme.palette.primary.main}
                                                                                stroke={theme.palette.primary.main}
                                                                                strokeWidth="0.92"
                                                                                strokeMiterlimit="10"
                                                                                d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                                                            <path fill={theme.palette.primary.main}
                                                                                stroke={theme.palette.primary.main}
                                                                                strokeWidth="0.92"
                                                                                strokeMiterlimit="10"
                                                                                d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                                        </g>
                                                                    </svg>
                                                                </IconButton>
                                                            </ToolTipComponent>
                                                        </Grid>
                                                    }
                                                    {library.isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
            </ValidatorForm>
            <Grid className={classes.referenceSection}>
                {
                    libraryList.length === 0 && !isLoading &&
                    <Grid className={classes.noresult}>
                        <NoResultFound height="90%" />
                    </Grid>
                }
            </Grid>

            {
                open && <ReferenceConfig
                    config={libraries[paramIndex] ? libraries[paramIndex] : {}}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => closePopover()}
                    addParameter={() => addParameter()}
                    removeParameter={(index) => removeParameter(index)}
                    onChange={(property, value, index) => onChangeParameter(property, value, index)}
                    onSave={() => saveParameters()}
                    uploadFiles={(files) => uploadFiles(files)} />
            }
            {isLoading && <Loader />}
        </Grid >
    );

};

Reference.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    organizationId: PropTypes.number,
    type: PropTypes.string
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Reference);