import React, { useRef, useState } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DataPreparationStyles from './DataPreparationStyles.jsx';
import Xarrow from "react-xarrows";

const ConnectPointsWrapper = (props) => {
    const { boxId, handler, ref0, classes, joinDisabled, theme } = props;
    const ref1 = useRef();

    const connectPointOffset = {
        left: { left: 0, top: "50%", transform: "translate(-50%, -50%)" },
        right: { left: "100%", top: "50%", transform: "translate(-50%, -50%)" },
        top: { left: "50%", top: 0, transform: "translate(-50%, -50%)" },
        bottom: { left: "50%", top: "100%", transform: "translate(-50%, -50%)" }
    };

    const [position, setPosition] = useState({});
    const [beingDragged, setBeingDragged] = useState(false);
    return (
        <React.Fragment>
            <div
                className={classNames(joinDisabled ? null : classes.connector, "connectionPoint")}
                style={
                    {
                        ...connectPointOffset[handler],
                        ...position
                    }
                }
                draggable
                onDragStart={
                    (event) => {
                        setBeingDragged(true);
                        event.dataTransfer.setData("arrow", boxId);
                    }
                }
                onDrag={
                    (event) => {
                        setPosition({
                            position: "fixed",
                            left: event.clientX,
                            top: event.clientY,
                            transform: "none",
                            opacity: 0
                        });
                    }
                }
                ref={ref1}
                onDragEnd={
                    (event) => {
                        setPosition({});
                        // event.dataTransfer.setData("arrow", null);
                        setBeingDragged(false);
                    }
                }
            />
            {beingDragged ? <Xarrow start={ref0} end={ref1} headSize={0} strokeWidth={2} lineColor={theme.palette.secondary.main} /> : null}
        </React.Fragment>
    );
};

ConnectPointsWrapper.propTypes = {
    boxId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    handler: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    ref0: PropTypes.object,
    classes: PropTypes.object,
    joinDisabled: PropTypes.bool,
    theme: PropTypes.object
};


export default withStyles((theme) => ({
    ...DataPreparationStyles(theme)
}), { withTheme: true })(ConnectPointsWrapper);