import React from 'react';
import { withStyles, Grid, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberInput from '../../TextBox/NumberInput.jsx';
import RuleBuilderStyles from '../QueryBuilderStyles.jsx';
import TextBox from '../../TextBox/TextBox.jsx';
import { getConditionOperators } from '../QueryBuilderUtil.jsx';
// import AutoComplete from '../../AutoComplete/AutoComplete.jsx';
import AutoCompleteInput from '../../AutoComplete/AutoCompleteInput.jsx';

const CharacterCountEditor = (props) => {
    const { rule, onChange, onBlur, onKeyDown, onOperatorChanged, classes } = props;
    return (
        <Grid className={classNames(classes.rangeContainer, classes.characterCountContainer)}>
            <TextBox className={classNames(classes.inputStyle, 'small-input')}
                onChange={(event) => onChange(event.target.value)}
                onBlur={(event) => onBlur(event.target.value)}
                onKeyDown={(event) => onKeyDown(event)}
                validators={['required']}
                errorMessages={['Value is required']}
                value={rule.value ? rule.value : ''} />


            <AutoCompleteInput
                name="operator"
                popperWidth={300}
                options={[...getConditionOperators()]}
                value={rule.conditionOperator && rule.conditionOperator.label ? rule.conditionOperator.label : ''}
                getOptionLabel={
                    (option) => {
                        if (option && option.label) {
                            return option.label;
                        }
                        return option;
                    }
                }
                getOptionSelected={(option, value) => option.label === value}
                renderInput={
                    (params) => <TextField {...params}
                        className={classNames('operator', classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                        placeholder="Condition Operator" />
                }
                onChange={(_, selectedValue) => onOperatorChanged(selectedValue, 'conditionOperator')}
                openOnFocus
                blurOnSelect
                disableClearable
                fullWidth
                forcePopupIcon={false}
                noOptionsText={""}
            />
            {/* <AutoComplete className={'operator'}
                placeholder="Condition Operator"
                onChange={(event) => event.target.selectedItem && onOperatorChanged(event.target.selectedItem, 'conditionOperator')}
                onBlur={() => onOperatorChanged(rule.conditionOperator, 'conditionOperator')}
                value={rule.conditionOperator && rule.conditionOperator.label ? rule.conditionOperator.label : ''}
                options={[...getConditionOperators()]}
                selectedValuePath="value"
                displayMemberPath="label"
                openSuggestionsOnClick
                name="operator" /> */}
            <NumberInput className={classNames(classes.inputStyle, 'small-input')}
                onChange={(event) => { onChange(event.target.value, 'count'); }}
                onBlur={(event) => onBlur(event.target.value, 'count')}
                onKeyDown={(event) => onKeyDown(event)}
                validators={['required']}
                errorMessages={['Value is required']}
                value={(rule.count || rule.count === 0) ? rule.count : ''}
                style={{ marginLeft: 14 }} />
        </Grid>
    );
};

CharacterCountEditor.propTypes = {
    classes: PropTypes.object,
    rule: PropTypes.object,
    onOperatorChanged: PropTypes.func,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func
};

export default withStyles(RuleBuilderStyles)(CharacterCountEditor);