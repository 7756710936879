import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Button } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextBox from '../../TextBox/TextBox.jsx';
import Styles from '../../../layouts/Styles.jsx';
import Loader from '../../Loaders/Loader.jsx';

const Snowflake = (props) => {
    const { classes, onSave, onChange, storage, type, isLoading } = props;
    return (
        <ValidatorForm onSubmit={() => onSave(type)}>
            <Grid container direction="row" spacing={2} >
                {/* <Grid item xs={6}>
                    <Grid container>
                        <Grid xs={8}>
                            <TextBox
                                label="Host"
                                name="host"
                                validators={['required']}
                                errorMessages={['Host is required']}
                                value={storage.host ? storage.host : ''}
                                onChange={(event) => onChange(type, event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid> */}
                <Grid item xs={6}>
                    <Grid container>
                        <Grid xs={8}>
                            <TextBox
                                label="URL"
                                name="url"
                                validators={['required']}
                                errorMessages={['URL is required']}
                                value={storage.url ? storage.url : ''}
                                onChange={(event) => onChange(type, event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid xs={8}>
                            <TextBox
                                label="Account Name"
                                name="account"
                                validators={['required']}
                                errorMessages={['Account Name is required']}
                                value={storage.account ? storage.account : ''}
                                onChange={(event) => onChange(type, event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid xs={8}>
                            <TextBox
                                label="Database Name"
                                name="database"
                                validators={['required']}
                                errorMessages={['Database Name is required']}
                                value={storage.database ? storage.database : ''}
                                onChange={(event) => onChange(type, event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid xs={8}>
                            <TextBox
                                label="Username"
                                name="user"
                                validators={['required']}
                                errorMessages={['Username is required']}
                                value={storage.user ? storage.user : ''}
                                onChange={(event) => onChange(type, event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid xs={8}>
                            <TextBox
                                label="Password"
                                name="password"
                                validators={['required']}
                                errorMessages={['password is required']}
                                type="password"
                                value={storage.password ? storage.password : ''}
                                onChange={(event) => onChange(type, event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid xs={8}>
                            <TextBox
                                label="Role"
                                name="role"
                                validators={['required']}
                                errorMessages={['Role is required']}
                                value={storage.role ? storage.role : ''}
                                onChange={(event) => onChange(type, event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid xs={8}>
                            <TextBox
                                label="Schema Name"
                                name="schema_name"
                                validators={['required']}
                                errorMessages={['Schema  is required']}
                                value={storage.schema_name ? storage.schema_name : ''}
                                onChange={(event) => onChange(type, event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid xs={8}>
                            <TextBox
                                label="Warehouse Name"
                                name="warehouse"
                                validators={['required']}
                                errorMessages={['Warehouse Name  is required']}
                                value={storage.warehouse ? storage.warehouse : ''}
                                onChange={(event) => onChange(type, event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classes.actionButtons}>
                        {'Connect'}
                        {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                    </Button>
                </Grid>
            </Grid>

        </ValidatorForm>
    );
};

Snowflake.propTypes = {
    storage: PropTypes.object,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    classes: PropTypes.object,
    type: PropTypes.string,
    isLoading: PropTypes.bool
};


export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(Snowflake);