import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, TextField, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import Styles from '../../layouts/Styles.jsx';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import { appConstants } from '../../constants/appConstants.js';

const FilterByTransform = (props) => {
    const { classes, theme, attributes, transformation, onUpdateTransformation } = props;
    const [transform, setTransform] = useState({
        conditions: [
            {
                attribute: {},
                condition: {},
                value: ''
            }
        ]
    });

    const getFilterQuery = useCallback((conditions) => {
        let queryString = '';
        if (conditions.length <= 0) {
            return queryString;
        }

        const queries = [];
        for (const condition of conditions) {
            const attribute = condition && condition.attribute && condition.attribute.name ? condition.attribute.name : '';
            let operator = condition && condition.condition && condition.condition.value ? condition.condition.value : '';
            if (!operator && condition && condition.condition && condition.condition.name && condition.condition.name.toLowerCase() === 'is between') {
                operator = condition.condition.name;
            }

            let value = condition.value;
            if (typeof (condition.value) === 'object') {
                if (condition.value.type && condition.value.type === 'constant') {
                    value = condition.value.value;
                }
            } else {
                value = condition.value ? `"${condition.value}"` : '';
            }

            if (operator === 'is between') {
                operator = 'between';
                const values = value.split(',');
                if (values.length > 1) {
                    value = `${values[0]} and ${values[1]}`;
                } else {
                    value = `${values[0]} and ${values[0]}`;
                }
            }
            if (!attribute || !operator || !value) {
                continue;
            }
            const query = `${attribute}.format ${operator} ${value}`;
            if (!query) {
                continue;
            }
            queries.push(query);
        }
        queryString = queries.join(' and ');
        return queryString;
    }, []);

    const updateTransformation = useCallback((transform) => {
        const conditions = [];
        if (transform && transform.conditions) {
            for (const condition of transform.conditions) {
                if (condition.attribute && condition.attribute.name
                    && condition.condition && condition.condition.name && condition.value) {
                    conditions.push(condition);
                }
            }
        }
        if (conditions.length <= 0) {
            return;
        }

        const queryString = getFilterQuery(conditions);
        if (!queryString) {
            return;
        }
        if (onUpdateTransformation) {
            onUpdateTransformation({
                'params': {
                    'query': queryString,
                    'conditions': conditions ? [...conditions] : []
                }
            });
        }
    }, [getFilterQuery, onUpdateTransformation]);

    const loadTransform = useCallback((transform) => {
        setTransform({
            conditions: transform && transform.params && transform.params.conditions ? [...transform.params.conditions] : []
        });
    }, []);

    const addCondition = useCallback(() => {
        if (!transform.conditions) {
            transform.conditions = [];
        }
        transform.conditions.push({
            attribute: {},
            condition: {},
            value: ''
        });
        setTransform({ ...transform });
    }, [transform]);


    const removeCondition = useCallback((conditionIndex) => {
        transform.conditions.splice(conditionIndex, 1);
        if (transform.conditions.length <= 0) {
            transform.conditions.push({
                attribute: {},
                condition: {},
                value: ''
            });
        }
        setTransform({ ...transform });
        updateTransformation(transform);
    }, [transform, updateTransformation]);

    const updateCondition = useCallback((conditionIndex, property, value) => {
        const selectedCondition = transform.conditions[conditionIndex];
        selectedCondition[property] = value;
        transform.conditions.splice(conditionIndex, 1, selectedCondition);
        setTransform({ ...transform });
        updateTransformation(transform);
    }, [transform, updateTransformation]);

    const onBlurValueInput = useCallback((conditionIndex, property, value) => {
        const selectedConstant = appConstants.transformationConstants.find((p) => p.name.toLowerCase() === value.toLowerCase());
        if (selectedConstant) {
            return;
        }
        const selectedCondition = transform.conditions[conditionIndex];
        selectedCondition[property] = value;
        transform.conditions.splice(conditionIndex, 1, selectedCondition);
        setTransform({ ...transform });
        updateTransformation(transform);
    }, [transform, updateTransformation]);

    useEffect(() => {
        if (transformation) {
            return;
        }
        loadTransform(transformation);
    }, [loadTransform, transformation]);

    const getAttributes = useCallback(() => {
        const attributeList = [];
        for (const attribute of attributes) {
            if (attribute.isTransformAttribute) {
                continue;
            }
            attributeList.push({ ...attribute });
        }
        return attributeList;
    }, [attributes]);

    return (
        <Grid cotainer direction="column" justify="flex-start">

            <Grid item>
                <Grid container direction="row" justify="space-between">
                    <Typography>
                        {'Add Conditions'}
                    </Typography>
                    <IconButton onClick={() => addCondition()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                    <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                    <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                </g>
                            </g>
                        </svg>
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item>
                {
                    transform.conditions && transform.conditions.length > 0 && transform.conditions.map((condition, index) => {
                        return (
                            <Grid container direction="row" justify="flex-start" alignItems="center" key={`condition_${index}`}>
                                <Grid item xs={3}>
                                    <AutoCompleteInput
                                        name="attribute"
                                        popperWidth={300}
                                        options={[...getAttributes()]}
                                        value={condition && condition.attribute ? { ...condition.attribute } : ''}
                                        getOptionLabel={
                                            (option) => {
                                                if (option && option.name) {
                                                    return option.name;
                                                }
                                                return option;
                                            }
                                        }
                                        renderInput={
                                            (params) =>
                                                <TextField {...params}
                                                    className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                                    placeholder="Input Attributes"
                                                    onBlur={() => updateTransformation(transform)}
                                                />
                                        }
                                        onChange={(_, selectedValue) => updateCondition(index, 'attribute', selectedValue)}
                                        openOnFocus
                                        blurOnSelect
                                        disableClearable
                                        fullWidth
                                        forcePopupIcon={false}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <AutoCompleteInput
                                        name="condition"
                                        options={transformation.functions ? [...transformation.functions] : []}
                                        value={condition && condition.condition ? { ...condition.condition } : ''}
                                        getOptionLabel={
                                            (option) => {
                                                if (option && option.name) {
                                                    return option.name;
                                                }
                                                return option;
                                            }
                                        }
                                        renderInput={
                                            (params) =>
                                                <TextField {...params}
                                                    className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                                    placeholder="Condition"
                                                />
                                        }
                                        onChange={(_, selectedValue) => updateCondition(index, 'condition', selectedValue)}
                                        openOnFocus
                                        blurOnSelect
                                        disableClearable
                                        forcePopupIcon={false}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <AutoCompleteInput
                                        name="value"
                                        options={appConstants.transformationConstants}
                                        value={condition && condition.value ? condition.value : ''}
                                        getOptionLabel={
                                            (option) => {
                                                if (option && option.name) {
                                                    return option.name;
                                                }
                                                return option;
                                            }
                                        }
                                        renderInput={
                                            (params) =>
                                                <TextField {...params}
                                                    className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                                    placeholder="Value"
                                                    onBlur={(event) => onBlurValueInput(index, 'value', event.target.value)}
                                                />
                                        }
                                        onChange={(_, selectedValue) => updateCondition(index, 'value', selectedValue)}
                                        freeSolo
                                        openOnFocus
                                        blurOnSelect
                                        disableClearable
                                        forcePopupIcon={false}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton className={classNames(classes.deleteDataset)} style={{ padding: 8 }} onClick={() => removeCondition(index)}>
                                        <svg version="1.1"
                                            id="Layer_1"
                                            width="21"
                                            height="21"
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 32 32">
                                            <g id="Delete">
                                                <path fill={theme.palette.primary.main}
                                                    stroke={theme.palette.primary.main}
                                                    strokeWidth="0.92"
                                                    strokeMiterlimit="10"
                                                    d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
		L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
		L22.3,25.8z" />
                                                <path fill={theme.palette.primary.main}
                                                    stroke={theme.palette.primary.main}
                                                    strokeWidth="0.92"
                                                    strokeMiterlimit="10"
                                                    d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
		c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
		c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                            </g>
                                        </svg>

                                    </IconButton>
                                </Grid>
                            </Grid>
                        );
                    })
                }
            </Grid>
        </Grid>
    );
};

FilterByTransform.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    transformation: PropTypes.object,
    attributes: PropTypes.array,
    onUpdateTransformation: PropTypes.func
};

export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(FilterByTransform);