import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Avatar } from '@material-ui/core';
import moment from 'moment-timezone';
import classnames from 'classnames';
import { getRandomColor, getInitials, returnImage } from '../../helpers/appHelpers';
import { appConstants } from '../../constants/appConstants';
import Styles from '../../layouts/Styles.jsx';
import ConversationStyles from './ConversationStyles.jsx';

const ConversationMessageList = (props) => {
    const { classes, conversation, selectConversation, selectedConversation, userId } = props;

    const presentDate = moment().format('MMM DD YYYY');
    const messageDate = moment(conversation.messages[conversation.messages.length - 1].created_date).format('MMM DD YYYY');

    const getUnreadCount = useCallback((conversation) => {
        return conversation.messages.filter((item) => item.receiver_id === userId && !item.is_read).length;
    }, [userId]);
    return (
        <Grid className={classnames(classes.conversationUser)} onClick={() => selectConversation(conversation)}>
            <Avatar
                style={
                    {
                        backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, conversation.avatar)})`,
                        backgroundColor: getRandomColor(conversation.first_name !== "" ? `${conversation.first_name} ${conversation.last_name}` : conversation.email)
                    }
                }>
                {
                    !conversation.avatar &&
                    <span>
                        {getInitials(conversation.first_name !== "" ? `${conversation.first_name} ${conversation.last_name}` : conversation.email)}
                    </span>
                }
            </Avatar>
            <Grid container className={classnames(classes.conversationListSection, (conversation.id === selectedConversation.id && conversation.sourceId === selectedConversation.sourceId && conversation.datasetId === selectedConversation.datasetId && conversation.domainId === selectedConversation.domainId) ? classes.cardSelect : null)}>
                <Grid className={classes.conversationMessageSection}>
                    <Typography className={classes.conversationUserTxt}>
                        {conversation.first_name !== "" ? `${conversation.first_name} ${conversation.last_name}` : conversation.email}
                    </Typography>
                    <Typography className={classes.timeTxt}>
                        {
                            ((conversation.messages) && (messageDate === presentDate)) ?
                            moment(conversation.messages[conversation.messages.length - 1].created_date).calendar() :
                            moment(conversation.messages[conversation.messages.length - 1].created_date).format('MMM DD, YYYY HH:mm')
                        }
                    </Typography>
                </Grid>
                <Grid className={classes.conversationMessageSection}>
                    <Typography className={classes.messageTxt}>
                        {conversation.messages ? conversation.messages[conversation.messages.length - 1].message : ''}
                    </Typography>
                    <Grid className={classes.unreadSection}>
                        {getUnreadCount(conversation)}
                    </Grid>
                </Grid>
                <Grid container>
                    {
                        conversation.type === "Domain" ?
                            <Grid>
                                <Typography className={classes.conversationDataTxt}>
                                    {conversation.domainName && conversation.domainName !== "" ? conversation.domainName : "Untitled Domain"}
                                </Typography>
                            </Grid> :
                            <Grid container>
                                <Typography className={classes.conversationDataTxt}>
                                    {conversation.sourceName && conversation.sourceName !== "" ? conversation.sourceName : "Untitled Datasource"}
                                </Typography>
                                {
                                    conversation.datasetName &&
                                    <Typography className={classes.timeTxt}>
                                        {' |  '}
                                    </Typography>
                                }
                                {
                                    conversation.datasetName &&
                                    <Typography className={classes.conversationDataTxt}>
                                        {conversation.datasetName && conversation.datasetName !== "" ? conversation.datasetName : "Untitled Dataset"}
                                    </Typography>
                                }
                            </Grid>
                    }

                </Grid>
            </Grid>
        </Grid>
    );

};

ConversationMessageList.propTypes = {
    classes: PropTypes.object,
    conversation: PropTypes.object,
    selectConversation: PropTypes.func,
    selectedConversation: PropTypes.object,
    userId: PropTypes.number
};

export default withStyles((theme) => ({
    ...ConversationStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ConversationMessageList);