import React, { useState } from 'react';
import {
    withStyles, Dialog, DialogTitle, DialogContent, Grid, Typography, Slide, Switch, FormGroup, FormControlLabel, FormHelperText, Button
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Styles from '../../layouts/Styles.jsx';
// import { Close } from '@material-ui/icons';
import classnames from 'classnames';
import SettingComponentStyles from '../Settings/SettingComponentStyles.jsx';
import Loader from '../Loaders/Loader.jsx';
import { getModuleConfig, showUserCustomMsg, updateConnectorConfig, updateLicenseConfiguration, getOrganizationConfig, updateLicenseConnectionStatus, getLicenseModules } from '../../actions/settingActions';


const Transition = React.forwardRef(Object.assign((props, ref) => <Slide direction="up" ref={ref} {...props} />), { displayName: 'Transition' });
const SelectConnectors = (props) => {
    const { classes, open, onClose, connectionUpdated, organizationId } = props;
    const [isLoading, setLoading] = useState(true);
    const [onSubmit, setSubmit] = useState(false);
    // const [selectAll, setSelectAll] = useState(false);
    const config = useSelector(({ setting }) => setting.licenseConfig);
    let connections = useSelector(({ datasource }) => datasource.connectionTypes);
    const [moduleConfig, setModuleConfig] = useState({ input_connectors: [] });
    const dispatch = useDispatch();
    connections = connections.filter((connection) => connection.is_input_connection);
    const [isDownGrade, setDownGrade] = useState(false);

    const loadConnectors = () => {
        dispatch(getModuleConfig(organizationId)).then((response) => {
            if (response) {
                response.input_connectors = response.data.input_connectors ? response.data.input_connectors : [];
                setDownGrade(response.input_connectors.length > config.no_of_connectors);
                setModuleConfig({ ...response.data });
            }
            setLoading(false);
        });
    };

    const changeProperty = (name, value, status) => {
        const index = moduleConfig[name].indexOf(value);
        if (index !== -1) {
            moduleConfig[name].splice(index, 1);
        } else {
            moduleConfig[name].push(value);
        }
        value = moduleConfig[name];
        setModuleConfig({ ...moduleConfig });
    };

    const updateConfig = () => {
        setSubmit(true);
        if (moduleConfig.input_connectors.length !== 0 && moduleConfig.input_connectors.length === config.no_of_connectors) {
            dispatch(showUserCustomMsg("Connector can be changed only after 72 hours"));
        }

        if (moduleConfig.input_connectors.length !== 0 && moduleConfig.input_connectors.length <= config.no_of_connectors) {
            const requestParams = { 'input_connectors': moduleConfig.input_connectors };
            dispatch(updateLicenseConfiguration(organizationId, { 'input_connectors': moduleConfig.input_connectors }));
            dispatch(updateConnectorConfig(organizationId, requestParams)).then(() => {
                dispatch(getOrganizationConfig());
                dispatch(getLicenseModules(organizationId));
                dispatch(updateLicenseConnectionStatus());
                onClose();
                if (connectionUpdated) {
                    connectionUpdated();
                }
            });
        }
    };

    const filterConnections = (connectionList = []) => {
        if (moduleConfig.allocated_input_connectors && moduleConfig.allocated_input_connectors.length) {
            connectionList = connectionList.filter((data) => moduleConfig.allocated_input_connectors.includes(data.id));
        }
        return connectionList;
    };

    return (
        <Dialog open={open}
            maxWidth="md"
            onEnter={() => loadConnectors()}
            TransitionComponent={Transition}
            className={classes.queryFilterDialog}
        >
            <DialogTitle>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {`Select Connectors - ${moduleConfig.input_connectors.length}/${config.no_of_connectors}`}
                        </Typography>
                        <Typography variant="body2" className={classnames(classes.marginTop10, classes.disabledtxt)}>
                            {`You are ${isDownGrade ? "downgrading" : "upgrading"} a license and please select the desired data connectors, NOTE: Other connectors and existing operation may not work`}
                        </Typography>
                    </Grid>
                    {/* <Grid item>
                        <FormControlLabel
                            control={<Switch color="secondary" onChange={() => onSelectAll()} checked={selectAll} />}
                            label={"Select All"}
                        />
                    </Grid> */}
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Grid className={classes.relative}>
                    <Grid className={classes.formGroup}>
                        {
                            onSubmit && moduleConfig.input_connectors.length === 0 &&
                            <FormHelperText className={classes.errorTxt}>
                                Please Select atleast one mode
                            </FormHelperText>
                        }
                        {
                            (moduleConfig.input_connectors.length > config.no_of_connectors) &&
                            <FormHelperText className={classes.errorTxt}>
                                {`Maximum Connection Limit ${config.no_of_connectors}`}
                            </FormHelperText>
                        }
                        <FormGroup row>
                            {
                                filterConnections(connections).map((type, index) =>
                                    <FormControlLabel
                                        key={index}
                                        className={classnames(classes.formLabelSection, classes.formLabel)}
                                        control={<Switch color="secondary" onChange={() => changeProperty("input_connectors", type.id, !moduleConfig.catalog)} checked={moduleConfig.input_connectors ? moduleConfig.input_connectors.indexOf(type.id) !== -1 : false} />}
                                        label={type.name}
                                    />
                                )
                            }
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12} className={classes.marginTop30}>
                        <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classes.submitBtn} onClick={() => updateConfig()}>
                            Save
                            {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                        </Button>

                        {/* <Button className={classes.cancelButton}
                            style={{ marginLeft: 20 }}
                            disabled={isLoading}
                            onClick={() => onClose()}>
                            {'Close'}
                        </Button> */}
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

SelectConnectors.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    connectionUpdated: PropTypes.func,
    organizationId: PropTypes.number

};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SelectConnectors);