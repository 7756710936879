const SettingStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    title: {
        marginTop: 10,
        marginBottom: 10,
        fontSize: 24
    },
    settingsTabContainer: {
        marginTop: '-1px'
    }
});

export default SettingStyles;