import config from "./PatternQueryBuilderConfig.jsx";
import { nanoid } from 'nanoid';

export const isPatternRuleGroup = (rule) => {
    return Boolean(rule.connector);
};

export const createPatternRule = (ruleParams) => {
    const connectionType = ruleParams?.connectionType ?? '';
    const isScan = ruleParams?.isScan ?? false;
    return {
        id: `rule-${nanoid()}`,
        pattern: '',
        connectionType,
        isScan
    };
};

export const createPatternRuleGroup = (ruleGroupParams) => {
    const connectionType = ruleGroupParams?.connectionType ?? '';
    const isScan = ruleGroupParams?.isScan ?? false;

    const ruleParams = {
        connectionType,
        isScan
    };
    const rule = createPatternRule(ruleParams);
    return {
        id: `group-${nanoid()}`,
        connector: config.connectors[0].value,
        not: false,
        connectionType,
        isScan,
        rules: [
            {
                ...rule
            }
        ]
    };
};

export const validateRuleGroup = (ruleGroup) => {
    if (!ruleGroup.id) {
        ruleGroup.id = ruleGroup.id ? ruleGroup.id : `group-${nanoid()}`;
    }
    const rules = [...ruleGroup.rules];
    for (let rule of rules) {
        if (isPatternRuleGroup(rule)) {
            rule = validateRuleGroup(rule);
        } else {
            rule.id = rule.id ? rule.id : `rule-${nanoid()}`;
        }
    }
    return ruleGroup;
};