import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, withStyles, Typography, TextField, MenuItem } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';
import TextBox from '../TextBox/TextBox.jsx';
/*
 * import DatasetStyles from '../../DatasetStyles.jsx';
 * import TileViewStyles from '../TileView/TileViewStyles.jsx';
 */
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const MultiSelectChipInput = (props) => {
    const { classes, displayKey, rule, keyvalue, onTypeUpdae, index, options, onUpdate, disabled, theme, onDelete, defaultAttributeType } = props;
    const [attributes, setAtrributes] = useState([]);

    const loadValue = useCallback((value) => {
        setAtrributes([...value]);
    }, [setAtrributes]);

    useEffect(() => {
        loadValue(rule ? [...rule] : []);
    }, [rule, displayKey, loadValue]);

    const getAttributes = () => {
        const selectedAttributes = rule ? [...rule] : [];
        const attributeList = [];
        for (const option of options) {
            const attributeIndex = selectedAttributes.findIndex((p) => p.name === option.name);
            if (attributeIndex === -1) {
                attributeList.push({ ...option });
            }
        }
        return attributeList;
    };

    const addAttribute = (attribute) => {
        setAtrributes([...attribute]);
        onUpdate(attribute, index);
    };

    const getTagType = (type) => {
        if (type === "T") {
            type = "C";
        } else if (type === "I" || type === "N") {
            type = "N";
        } else {
            type = "T";
        }
        return type;
    };
    const getAttributeTag = () => {
        let tag = getTagType(defaultAttributeType);
        for (const attribute of attributes) {
            let type = attribute.datatype ? attribute.datatype.substring(0, 1).toUpperCase() : "T";
            type = getTagType(type);
            tag = `${tag}${type}`;
        }
        return tag;
    };

    return (
        <Grid container direction="column" flex-direction="column" justify="flex-start" alignItems="center" wrap="nowrap" className={classes.basicRuleListItem}>
            <Grid container justify="space-between" alignItems="center">
                <Grid item xs={6} md={6} sm={6}>
                    <Typography className={classes.tag}>
                        {getAttributeTag()}
                    </Typography>
                </Grid>
                <Grid item xs={4} md={4} sm={4} className={classes.paddingLeft10}>
                    <TextBox
                        label="Filter By"
                        value={keyvalue}
                        name="filter"
                        fullWidth
                        select
                        onChange={(event) => onTypeUpdae(event.target.value, index)}
                        SelectProps={
                            {
                                value: keyvalue,
                                onChange: (event) => onTypeUpdae(event.target.value, index),
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "center"
                                    },
                                    getContentAnchorEl: null
                                }
                                // IconComponent: () => DropDownIcon()
                            }
                        }
                    >
                        {
                            ["Avg", "Count", "Max", "Min", "Sum", "Value"].map((status, index) =>
                                <MenuItem key={index} value={status}>
                                    {status}
                                </MenuItem>
                            )
                        }
                    </TextBox>

                </Grid>
                {
                    !disabled &&
                    <Grid item xs={2} md={2} sm={2}>
                        <ToolTipComponent title="Delete" arrow>
                            <IconButton onClick={() => onDelete(index)}>
                                <svg version="1.1" id="Layer_1" width="22" height="22" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
                                    <g id="Delete">
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                    </g>
                                </svg>
                            </IconButton>
                        </ToolTipComponent>
                    </Grid>
                }
            </Grid>
            <Grid container className={classes.autoCompleteInputContainer}>
                <AutoCompleteInput
                    name="attributes"
                    popperWidth={300}
                    options={[...getAttributes()]}
                    freeSolo
                    value={attributes ? [...attributes] : []}
                    getOptionLabel={
                        (option) => {
                            if (option && option.name) {
                                return option.name;
                            }
                            return option;
                        }
                    }
                    renderInput={
                        (params) =>
                            <TextField {...params}
                                className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                placeholder="Select Attributes"
                                margin="normal"
                                onBlur={() => onUpdate(attributes, index)}
                                disabled={disabled}
                            />
                    }
                    onChange={(_, selectedValue) => addAttribute(selectedValue)}
                    openOnFocus
                    disableCloseOnSelect
                    disableClearable
                    fullWidth
                    multiple
                    forcePopupIcon={false}
                    noOptionsText={"No Attributes Found"}
                />
            </Grid>
        </Grid>
    );
};


MultiSelectChipInput.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    displayKey: PropTypes.string,
    keyvalue: PropTypes.string,
    rule: PropTypes.object,
    index: PropTypes.number,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    onTypeUpdae: PropTypes.func,
    disabled: PropTypes.bool,
    isSelected: PropTypes.bool,
    options: PropTypes.array,
    defaultAttributeType: PropTypes.string
};


export default withStyles((theme) => ({
    /*
     * ...DatasetStyles(theme),
     * ...TileViewStyles(theme),
     */
    ...Styles(theme)
}), { withTheme: true })(MultiSelectChipInput);