import React, { Fragment } from 'react';
import { Grid, withStyles, Accordion, Typography, Paper, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import DomainStyles from './DomainStyles.jsx';
import DomainListRuleHeader from './DomainListRuleHeader.jsx';
import DomainListDatasetRuleHeader from './DomainListDatasetRuleHeader.jsx';
import DomainListCard from './DomainListCard.jsx';

import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import Loader from '../Loaders/Loader.jsx';
import { useSelector } from 'react-redux';

const DomainListView = (props) => {
    const { classes, datasources, datasets, attributes, rules, onChange, openDialog, redirect, isInvalidAttribute, isInvalidDataset, group_by_datasource, redirectData, dq_score_threshold, loadDomainDatasets, loadDomainAttributes, loadDomainRules, loadDomainDatasources, handleAccordionChange, datasourceExpanded, datasetExpanded, attributeExpanded, isLoading } = props;

    const { domainDatasources: { isLoading: datasourceLoading }, domainDatasets: { isLoading: datasetLoading }, domainAttributes: { isLoading: attributeLoading }, domainRules: { isLoading: ruleLoading } } = useSelector(({ domain }) => domain);

    const openPreviewDialog = (event, rule, type, contenttype) => {
        event.stopPropagation();
        openDialog(rule, type, contenttype);
    };

    const onScroll = (event) => {
        if (event.target.scrollTop + event.target.offsetHeight + 55 >= event.target.scrollHeight) {
            let needToLoad = true;
            if (datasources && datasources.length > 0) {
                if (datasources[0]?.total_count === datasources.length) {
                    needToLoad = false;
                }
            }
            if (needToLoad && !datasourceLoading) {
                loadDomainDatasources(group_by_datasource, true);
            }
        }
    };

    return (
        <Grid>
            <Grid container className={classes.listSection}>
                <Grid item className={classes.gridNameContainer}>
                    <Typography>
                        Datasource / Dataset / Attributes
                    </Typography>
                </Grid>
                <Grid item className={classes.countContainer}>
                    <Typography>
                        Total Records
                    </Typography>
                </Grid>
                <Grid item className={classes.countContainer}>
                    <Typography>
                        Invalid Records
                    </Typography>
                </Grid>
                <Grid item className={classes.countContainer}>
                    <Typography>
                        Valid Records
                    </Typography>
                </Grid>
                <Grid item className={classes.gridNameContainer}>
                    <Typography>
                        DQScore
                    </Typography>
                </Grid>
                <Grid item className={classes.gridNameContainer}>
                    <Typography>
                        Weightage
                    </Typography>
                </Grid>
                <Grid item xs={1} className={classes.countContainer} >
                    <Typography>
                        Actions
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.domainListContainer} onScroll={onScroll}>
                {
                    !isLoading ?
                        <Fragment>
                            {
                                !group_by_datasource &&
                                <Grid className={classes.domainListSection}>
                                    <Paper>
                                        {
                                            datasources && datasources.length > 0 ?
                                                datasources.map((datasource, datasourceIndex) =>
                                                    <Fragment key={datasourceIndex}>
                                                        <Accordion
                                                            className={(datasourceIndex !== 0) && classes.borderTopColor}
                                                            key={datasourceIndex}
                                                            defaultExpanded={false}
                                                            expanded={datasetExpanded === datasourceIndex}
                                                            onChange={handleAccordionChange(datasourceIndex, 'dataset', datasource.id)}
                                                            TransitionProps={{ unmountOnExit: true }}
                                                        // defaultExpanded={(selectedRule.dataset_id === dataset.dataset_id) || (datasetIndex === 0 && !selectedRule.dataset_id)}
                                                        >
                                                            <DomainListDatasetRuleHeader
                                                                header={datasource}
                                                                datasource={datasource.name}
                                                                download
                                                                type="dataset"
                                                                group_by_datasource={group_by_datasource}
                                                                isInvalidDataset={isInvalidDataset}
                                                                redirect={(rule, type) => redirect(rule, type)}
                                                                count={datasource.attribute_count ? datasource.attribute_count : 0}
                                                                openDialog={(event, rule, type, contenttype) => openPreviewDialog(event, rule, type, contenttype)}
                                                                dq_score_threshold={datasource.dq_score_threshold && Object.keys(datasource.dq_score_threshold).length > 0 ? datasource.dq_score_threshold : dq_score_threshold}
                                                            />
                                                            <Grid className={classes.accordianAttributeContainer}>
                                                                {
                                                                    attributes && attributes.length > 0 ?
                                                                        attributes.map((attribute, attributeIndex) =>
                                                                            <Fragment key={attributeIndex}>
                                                                                <Accordion
                                                                                    key={attributeIndex}
                                                                                    defaultExpanded={false}
                                                                                    expanded={attributeExpanded === attributeIndex}
                                                                                    onChange={handleAccordionChange(attributeIndex, 'attribute', attribute.id, attribute.schedule_id)}
                                                                                    TransitionProps={{ unmountOnExit: true }}
                                                                                // defaultExpanded={(selectedRule.attribute_id === attribute.attribute_id)}
                                                                                >
                                                                                    <DomainListRuleHeader
                                                                                        header={{ ...attribute, total_records: datasource.total_records }}
                                                                                        redirect={(rule, type) => redirect(rule, type)}
                                                                                        type="attribute"
                                                                                        count={attribute.rules_count ? attribute.rules_count : 0}
                                                                                        isInvalidAttribute={isInvalidAttribute}
                                                                                        redirectData={() => redirectData()}
                                                                                        openDialog={(event, rule, type, contenttype) => openPreviewDialog(event, rule, type, contenttype)}
                                                                                        dq_score_threshold={datasource.dq_score_threshold && Object.keys(datasource.dq_score_threshold).length > 0 ? datasource.dq_score_threshold : dq_score_threshold}
                                                                                    />
                                                                                    <Grid>
                                                                                        {
                                                                                            rules && rules.length > 0 ?
                                                                                                rules.sort((a, b) => b.is_active - a.is_active).map((rule, index) =>
                                                                                                    <Fragment key={index}>
                                                                                                        <DomainListCard
                                                                                                            isInvalidDataset={isInvalidDataset}
                                                                                                            isInvalidAttribute={isInvalidAttribute}
                                                                                                            redirect={(rule, type) => redirect(rule, type)}
                                                                                                            openDialog={(event, rule, type, contenttype) => openPreviewDialog(event, rule, type, contenttype)}
                                                                                                            key={index}
                                                                                                            rule={{ ...rule, total_records: datasource.total_records, attribute_name: attribute.name }}
                                                                                                            onChange={(property, value) => onChange(property, value, rule.id)}
                                                                                                            dq_score_threshold={datasource.dq_score_threshold && Object.keys(datasource.dq_score_threshold).length > 0 ? datasource.dq_score_threshold : dq_score_threshold}
                                                                                                            attribute={attribute}
                                                                                                        />
                                                                                                        {
                                                                                                            !ruleLoading && index === rules.length - 1 && rules[0].total_count > rules.length && (
                                                                                                                <Grid style={{ display: 'flex', justifyContent: 'right' }}>
                                                                                                                    <Button
                                                                                                                        variant="outlined"
                                                                                                                        color="primary"
                                                                                                                        endIcon={<KeyboardArrowDown />}
                                                                                                                        onClick={() => loadDomainRules(attribute.id, attribute.schedule_id, true)}
                                                                                                                    >
                                                                                                                        Load More Rules
                                                                                                                    </Button>
                                                                                                                </Grid>
                                                                                                            )
                                                                                                        }
                                                                                                    </Fragment>
                                                                                                )
                                                                                                :
                                                                                                !ruleLoading && <NoResultFound />
                                                                                        }
                                                                                        {
                                                                                            ruleLoading &&
                                                                                            <Grid style={{ position: 'relative', height: '52px' }}>
                                                                                                <Loader />
                                                                                            </Grid>
                                                                                        }
                                                                                    </Grid>
                                                                                </Accordion>
                                                                                {
                                                                                    !attributeLoading && attributeIndex === attributes.length - 1 && attributes[0].total_count > attributes.length && (
                                                                                        <Grid style={{ display: 'flex', justifyContent: 'right' }}>
                                                                                            <Button
                                                                                                variant="outlined"
                                                                                                color="primary"
                                                                                                endIcon={<KeyboardArrowDown />}
                                                                                                onClick={() => loadDomainAttributes(datasource.id, true)}
                                                                                            >
                                                                                                Load More Attributes
                                                                                            </Button>
                                                                                        </Grid>
                                                                                    )
                                                                                }
                                                                            </Fragment>
                                                                        )
                                                                        :
                                                                        !attributeLoading && <NoResultFound />
                                                                }
                                                                {
                                                                    attributeLoading &&
                                                                    <Grid style={{ position: 'relative', height: '52px' }}>
                                                                        <Loader />
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Accordion>
                                                    </Fragment>
                                                )
                                                :
                                                !datasourceLoading && <NoResultFound />

                                        }
                                        {
                                            datasourceLoading &&
                                            <Grid style={{ position: 'relative', height: '52px' }}>
                                                <Loader />
                                            </Grid>
                                        }
                                    </Paper>
                                </Grid>
                            }
                            {
                                group_by_datasource &&
                                <Grid className={classes.domainListSection}>
                                    {
                                        datasources && datasources.length > 0 ?
                                            datasources.map((datasource, datasourceIndex) =>
                                                <Fragment key={datasourceIndex}>
                                                    <Paper key={datasourceIndex}>
                                                        <Accordion
                                                            className={(datasourceIndex !== 0) && classes.borderTopColor}
                                                            key={datasourceIndex}
                                                            defaultExpanded={false}
                                                            expanded={datasourceExpanded === datasourceIndex}
                                                            onChange={handleAccordionChange(datasourceIndex, 'datasource', datasource.id)}
                                                            TransitionProps={{ unmountOnExit: true }}
                                                        >

                                                            <DomainListDatasetRuleHeader
                                                                header={datasource}
                                                                datasource={datasource.name}
                                                                download
                                                                type="datasource"
                                                                isInvalidDataset={isInvalidDataset}
                                                                redirect={(rule, type) => redirect(rule, type)}
                                                                count={datasource.dataset_count ? datasource.dataset_count : 0}
                                                                openDialog={(event, rule, type, contenttype) => openPreviewDialog(event, rule, type, contenttype)}
                                                                dq_score_threshold={datasource.dq_score_threshold && Object.keys(datasource.dq_score_threshold).length > 0 ? datasource.dq_score_threshold : dq_score_threshold}
                                                            />
                                                            <Grid className={classes.accordianAttributeContainer}>
                                                                {
                                                                    datasets && datasets.length > 0 ?
                                                                        datasets.map((dataset, datasetIndex) =>
                                                                            <Fragment key={datasetIndex}>
                                                                                <Accordion
                                                                                    className={(datasetIndex !== 0 || datasourceIndex > 0) && classes.borderTopColor}
                                                                                    key={datasetIndex}
                                                                                    defaultExpanded={false}
                                                                                    expanded={datasetExpanded === datasetIndex}
                                                                                    onChange={handleAccordionChange(datasetIndex, 'dataset', dataset.id)}
                                                                                    TransitionProps={{ unmountOnExit: true }}
                                                                                // defaultExpanded={(selectedRule.dataset_id === dataset.dataset_id)}
                                                                                >
                                                                                    <DomainListDatasetRuleHeader
                                                                                        header={dataset}
                                                                                        datasource={datasource.name}
                                                                                        download
                                                                                        type="dataset"
                                                                                        isInvalidDataset={isInvalidDataset}
                                                                                        group_by_datasource={group_by_datasource}
                                                                                        redirect={(rule, type) => redirect(rule, type)}
                                                                                        count={dataset.attribute_count ? dataset.attribute_count : 0}
                                                                                        openDialog={(event, rule, type, contenttype) => openPreviewDialog(event, rule, type, contenttype)}
                                                                                        dq_score_threshold={datasource.dq_score_threshold && Object.keys(datasource.dq_score_threshold).length > 0 ? datasource.dq_score_threshold : dq_score_threshold}
                                                                                    />
                                                                                    <Grid className={classes.accordianAttributeContainer}>
                                                                                        {
                                                                                            attributes && attributes.length > 0 ?
                                                                                                attributes.map((attribute, attributeIndex) =>
                                                                                                    <Fragment key={attributeIndex}>
                                                                                                        <Accordion
                                                                                                            key={attributeIndex}
                                                                                                            defaultExpanded={false}
                                                                                                            expanded={attributeExpanded === attributeIndex}
                                                                                                            onChange={handleAccordionChange(attributeIndex, 'attribute', attribute.id, attribute.schedule_id)}
                                                                                                            TransitionProps={{ unmountOnExit: true }}
                                                                                                        // defaultExpanded={(selectedRule.attribute_id === attribute.attribute_id)}
                                                                                                        >
                                                                                                            <DomainListRuleHeader
                                                                                                                header={{ ...attribute, total_records: dataset.total_records }}
                                                                                                                redirect={(rule, type) => redirect(rule, type)}
                                                                                                                type="attribute"
                                                                                                                count={attribute.rules_count ? attribute.rules_count : 0}
                                                                                                                isInvalidAttribute={isInvalidAttribute}
                                                                                                                redirectData={() => redirectData()}
                                                                                                                openDialog={(event, rule, type, contenttype) => openPreviewDialog(event, rule, type, contenttype)}
                                                                                                                dq_score_threshold={datasource.dq_score_threshold && Object.keys(datasource.dq_score_threshold).length > 0 ? datasource.dq_score_threshold : dq_score_threshold} />

                                                                                                            <Grid>
                                                                                                                {
                                                                                                                    rules && rules.length > 0 ?
                                                                                                                        rules.sort((a, b) => b.is_active - a.is_active).map((rule, index) =>
                                                                                                                            <Fragment key={index}>
                                                                                                                                <DomainListCard
                                                                                                                                    attribute={attribute}
                                                                                                                                    isInvalidDataset={isInvalidDataset}
                                                                                                                                    isInvalidAttribute={isInvalidAttribute}
                                                                                                                                    redirect={(rule, type) => redirect(rule, type)}
                                                                                                                                    openDialog={(event, rule, type, contenttype) => openPreviewDialog(event, rule, type, contenttype)}
                                                                                                                                    key={index}
                                                                                                                                    rule={{ ...rule, total_records: dataset.total_records, attribute_name: attribute.name }}
                                                                                                                                    onChange={(property, value) => onChange(property, value, rule.id)}
                                                                                                                                    dq_score_threshold={datasource.dq_score_threshold && Object.keys(datasource.dq_score_threshold).length > 0 ? datasource.dq_score_threshold : dq_score_threshold}
                                                                                                                                />
                                                                                                                                {/* {
                                                                                                                                    !ruleLoading && index === rules.length - 1 && rules[0].total_count > rules.length && (
                                                                                                                                        <Grid style={{ display: 'flex', justifyContent: 'right' }}>
                                                                                                                                            <Button
                                                                                                                                                variant="outlined"
                                                                                                                                                color="primary"
                                                                                                                                                endIcon={<KeyboardArrowDown />}
                                                                                                                                                onClick={() => loadDomainRules(attribute.id, attribute.schedule_id, true)}
                                                                                                                                            >
                                                                                                                                                Load More Rules
                                                                                                                                            </Button>
                                                                                                                                        </Grid>
                                                                                                                                    )
                                                                                                                                } */}
                                                                                                                            </Fragment>
                                                                                                                        )
                                                                                                                        :
                                                                                                                        !ruleLoading &&
                                                                                                                        <NoResultFound />

                                                                                                                }
                                                                                                                {
                                                                                                                    ruleLoading &&
                                                                                                                    <Grid style={{ position: 'relative', height: '52px' }}>
                                                                                                                        <Loader />
                                                                                                                    </Grid>
                                                                                                                }
                                                                                                            </Grid>
                                                                                                        </Accordion>
                                                                                                        {
                                                                                                            !attributeLoading && attributeIndex === attributes.length - 1 && attributes[0].total_count > attributes.length && (
                                                                                                                <Grid style={{ display: 'flex', justifyContent: 'right' }}>
                                                                                                                    <Button
                                                                                                                        variant="outlined"
                                                                                                                        color="primary"
                                                                                                                        endIcon={<KeyboardArrowDown />}
                                                                                                                        onClick={() => loadDomainAttributes(dataset.id, true)}
                                                                                                                    >
                                                                                                                        Load More Attributes
                                                                                                                    </Button>
                                                                                                                </Grid>
                                                                                                            )
                                                                                                        }
                                                                                                    </Fragment>
                                                                                                )
                                                                                                :
                                                                                                !attributeLoading && <NoResultFound />

                                                                                        }
                                                                                        {
                                                                                            attributeLoading &&
                                                                                            <Grid style={{ position: 'relative', height: '52px' }}>
                                                                                                <Loader />
                                                                                            </Grid>
                                                                                        }
                                                                                    </Grid>
                                                                                </Accordion>
                                                                                {
                                                                                    !datasetLoading && datasetIndex === datasets.length - 1 && datasets[0].total_count > datasets.length && (
                                                                                        <Grid style={{ display: 'flex', justifyContent: 'right' }}>
                                                                                            <Button
                                                                                                variant="outlined"
                                                                                                color="primary"
                                                                                                endIcon={<KeyboardArrowDown />}
                                                                                                onClick={() => loadDomainDatasets(datasource.id, true)}
                                                                                            >
                                                                                                Load More Datasets
                                                                                            </Button>
                                                                                        </Grid>
                                                                                    )
                                                                                }
                                                                            </Fragment>
                                                                        )
                                                                        :
                                                                        !datasetLoading && <NoResultFound />

                                                                }
                                                                {
                                                                    datasetLoading &&
                                                                    <Grid style={{ position: 'relative', height: '52px' }}>
                                                                        <Loader />
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Accordion>
                                                    </Paper>
                                                </Fragment>
                                            )
                                            :
                                            <NoResultFound />
                                    }
                                    {
                                        datasourceLoading &&
                                        <Grid style={{ position: 'relative', height: '52px' }}>
                                            <Loader />
                                        </Grid>
                                    }
                                </Grid>
                            }
                        </Fragment>
                        :
                        <Loader />
                }
            </Grid>
        </Grid>
    );
};

DomainListView.propTypes = {
    classes: PropTypes.object,
    datasources: PropTypes.array,
    datasets: PropTypes.array,
    attributes: PropTypes.array,
    rules: PropTypes.array,
    onChange: PropTypes.func,
    openDialog: PropTypes.func,
    redirect: PropTypes.func,
    redirectData: PropTypes.func,
    isInvalidAttribute: PropTypes.bool,
    group_by_datasource: PropTypes.bool,
    isInvalidDataset: PropTypes.bool,
    dq_score_threshold: PropTypes.object,
    loadDomainDatasources: PropTypes.func,
    loadDomainDatasets: PropTypes.func,
    loadDomainAttributes: PropTypes.func,
    loadDomainRules: PropTypes.func,
    handleAccordionChange: PropTypes.func,
    datasourceExpanded: PropTypes.bool,
    datasetExpanded: PropTypes.bool,
    attributeExpanded: PropTypes.bool,
    isLoading: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DomainListView);