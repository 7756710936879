import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Table, TableHead, TableBody, TableRow, TableCell, Typography, withStyles, Card, IconButton, Popover, Box } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';

import { appConstants } from '../../constants/appConstants';
import { getModelExecutionLogs, killMasterJob, restartMasterJobSchedule } from '../../actions/masterActions';
import Styles from '../../layouts/Styles.jsx';
import { getDuration } from '../../helpers/appHelpers';
import Loader from '../Loaders/Loader.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import MasterStyles from './MasterStyles.jsx';
import ReplayIcon from '@material-ui/icons/Replay';

const MasterExecutionLogs = (props) => {
    const { classes, theme, modelId, tabIndex, isEditable } = props;
    const [executionLogs, setExecutionLogs] = useState([]);
    const [error, setError] = useState('');
    const [anchorElement, setAnchorElement] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();

    const loadExecutionLogs = useCallback((response) => {
        const datetimeFormat = 'MM/DD/YYYY HH:mm:ss';
        for (const executionLog of response) {
            executionLog['start_time'] = moment(executionLog['start_time']).format(datetimeFormat);
            if (executionLog['end_time']) {
                executionLog['end_time'] = moment(executionLog['end_time']).format(datetimeFormat);
            }
            if (executionLog.duration) {
                executionLog.duration = getDuration(executionLog.duration);
            }
        }
        setExecutionLogs(response ? [...response] : []);
    }, []);

    const showError = (event, error) => {
        if (!error) {
            return;
        }
        setError(error);
        setAnchorElement(event.currentTarget);
    };

    const closeError = () => {
        setAnchorElement(null);
        setTimeout(() => setError(''), 100);
    };

    const getExecutionLogs = useCallback((modelId) => {
        if (!modelId) {
            return;
        }
        dispatch(getModelExecutionLogs(modelId)).then((response) => {
            setIsLoading(false);
            if (response) {
                loadExecutionLogs(response);
            }
        });
    }, [dispatch, loadExecutionLogs]);

    const restartJob = useCallback((scheduleId) => {
        dispatch(restartMasterJobSchedule(scheduleId)).then(() => {
            getExecutionLogs(modelId);
        });
    }, [dispatch, getExecutionLogs, modelId]);

    const killJobs = useCallback((scheduleId) => {
        dispatch(killMasterJob(scheduleId)).then(() => {
            getExecutionLogs(modelId);
        });
    }, [dispatch, getExecutionLogs, modelId]);

    useEffect(() => {
        if (!modelId || tabIndex !== 5) {
            return;
        }
        getExecutionLogs(modelId);
    }, [modelId, tabIndex, getExecutionLogs]);

    const reloadExecution = () => {
        setIsLoading(true);
        getExecutionLogs(modelId);
    };

    const open = Boolean(anchorElement);
    return (
        <Grid wrap="nowrap" className={classes.executionLogContainer}>
            <Grid style={{ height: '100%' }} container item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container justify="space-between" alignItems="center" className={classes.executionTitle}>
                    <Typography component="h5" variant="h5">
                        {'Execution Logs'}
                    </Typography>
                    <Grid item>
                        <ToolTipComponent title="Reload" arrow>
                            <IconButton className={classNames(classes.reloadIcon, classes.nopadding)} onClick={() => reloadExecution()}>
                                <ReplayIcon />
                            </IconButton>
                        </ToolTipComponent>
                    </Grid>
                </Grid>
                <Card style={{ width: '100%', height: '90%' }} className={classNames(classes.executionLogTable, classes.tableWrapperStyle)}>
                    {
                        (executionLogs.length > 0) &&
                        <Table stickyHeader className={classes.tableStyle}>
                            <TableHead>
                                <TableRow>
                                    {
                                        appConstants.masterExecutionLogHeaders.map((executionLogHeader, index) =>
                                            <TableCell key={`executionLogHeader${index}`} align={executionLogHeader === 'Actions' ? "center" : 'left'}>
                                                <Typography variant="body1" className={classes.tableHeader}>
                                                    {executionLogHeader}
                                                </Typography>
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    executionLogs.map((executionLog, index) => {
                                        return (
                                            <TableRow key={`executionLog${index}`}>
                                                <TableCell>
                                                    <ToolTipComponent title={executionLog.model_name ? executionLog.model_name : ''} arrow placement="bottom-start">
                                                        <Typography variant="body2" noWrap>
                                                            {executionLog.model_name ? executionLog.model_name : ''}
                                                        </Typography>
                                                    </ToolTipComponent>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" noWrap>
                                                        {executionLog.start_time ? executionLog.start_time : ''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" noWrap>
                                                        {executionLog.end_time ? executionLog.end_time : ''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" noWrap>
                                                        {executionLog.duration ? executionLog.duration : ''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" noWrap>
                                                        {executionLog.type ? executionLog.type : ''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" noWrap>
                                                        {executionLog.source ? executionLog.source : ''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" noWrap>
                                                        {executionLog.dataset ? executionLog.dataset : ''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography variant="body2" noWrap>
                                                        {executionLog.status ? executionLog.status : ''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Grid container direction="row" justify="center" alignItems="center">
                                                        {
                                                            isEditable && (executionLog && executionLog.status !== 'Started') &&
                                                            <ToolTipComponent title="Run" arrow>
                                                                <IconButton className={classes.executionLogActions} onClick={() => restartJob(executionLog.schedule_id)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.174" height="18.174" viewBox="0 0 18.174 18.174">
                                                                        <g id="ui" transform="translate(-1 -1)">
                                                                            <path id="Path_893" data-name="Path 893" d="M10.087,1a9.087,9.087,0,1,0,9.087,9.087A9.087,9.087,0,0,0,10.087,1Zm0,16.522a7.435,7.435,0,1,1,7.435-7.435A7.435,7.435,0,0,1,10.087,17.522Z" transform="translate(0 0)" fill={theme.palette.primary.main} />
                                                                            <path id="Path_894" data-name="Path 894" d="M15.873,10.256,9.264,6.126A.826.826,0,0,0,8,6.826v8.261a.826.826,0,0,0,1.264.7l6.609-4.131a.826.826,0,0,0,0-1.4ZM9.652,13.6V8.317l4.224,2.639Z" transform="translate(-1.217 -0.869)" fill={theme.palette.primary.main} />
                                                                        </g>
                                                                    </svg>
                                                                </IconButton>
                                                            </ToolTipComponent>
                                                        }
                                                        {
                                                            isEditable && (executionLog && executionLog.status === 'Started') &&
                                                            <ToolTipComponent title="Kill" arrow>
                                                                <IconButton className={classes.executionLogActions} onClick={() => killJobs(executionLog.schedule_id)}>
                                                                    <HighlightOff color="primary" />
                                                                </IconButton>
                                                            </ToolTipComponent>
                                                        }
                                                        {
                                                            executionLog.status === 'Failed' && isEditable &&
                                                            <ToolTipComponent title="View Error Log" arrow>
                                                                <IconButton color={'primary'}
                                                                    aria-owns={open ? 'view-error-popover' : undefined}
                                                                    aria-describedby={open ? 'view-error-popover' : undefined}
                                                                    aria-haspopup="true"
                                                                    className={classes.executionLogActions}
                                                                    onClick={(event) => showError(event, executionLog.error_log)}>
                                                                    <svg id="information" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                                                                        <path id="Path_15040" data-name="Path 15040" d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,18.182A8.182,8.182,0,1,1,18.182,10,8.191,8.191,0,0,1,10,18.182Z" fill={theme.palette.primary.main} />
                                                                        <path id="Path_15041" data-name="Path 15041" d="M146.214,70a1.212,1.212,0,1,0,1.212,1.213A1.214,1.214,0,0,0,146.214,70Z" transform="translate(-136.214 -65.758)" fill={theme.palette.primary.main} />
                                                                        <path id="Path_15042" data-name="Path 15042" d="M150.909,140a.909.909,0,0,0-.909.909v5.455a.909.909,0,1,0,1.818,0v-5.455A.909.909,0,0,0,150.909,140Z" transform="translate(-140.909 -131.515)" fill={theme.palette.primary.main} />
                                                                    </svg>
                                                                </IconButton>
                                                            </ToolTipComponent>
                                                        }
                                                    </Grid>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    }
                    {
                        (executionLogs.length === 0) && !isLoading &&
                        <NoResultFound />
                    }
                </Card>
                {isLoading && <Loader />}
            </Grid>
            <Popover id="view-error-popover"
                open={open}
                anchorEl={anchorElement}
                classes={
                    {
                        paper: classes.errorLogContainer
                    }
                }
                anchorOrigin={
                    {
                        vertical: 'bottom',
                        horizontal: 'left'
                    }
                }
                transformOrigin={
                    {
                        vertical: 'top',
                        horizontal: 'left'
                    }
                }
                onClose={() => closeError()}>
                <Box p={2} className={classes.errorContainer}>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item>
                            <Typography component="h5" variant="h5">
                                Error Log
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => closeError()} className={classes.padding5}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Typography className={classes.marginTop15}>
                        {error}
                    </Typography>
                </Box>
            </Popover>
        </Grid >
    );
};

MasterExecutionLogs.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    modelId: PropTypes.number,
    tabIndex: PropTypes.number,
    isEditable: PropTypes.bool
};

export default withStyles((theme) => ({
    ...MasterStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MasterExecutionLogs);