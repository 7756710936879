import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Grid, Tabs, Tab } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { datasetPropertiesTab } from '../../../../actions/datasetActions';

import AttributeTileHeader from './AttributeTileHeader.jsx';
import DatasetStyles from '../../DatasetStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';
import { appConstants } from '../../../../constants/appConstants.js';
import AttributeProperty from './AttributeProperty.jsx';
import TabPanel from '../../../TabPanel/TabPanel.jsx';
import AttributeValues from './AttributeValues.jsx';
import AttributeRules from './AttributeRules.jsx';
import TileViewStyles from './TileViewStyles.jsx';
import AttributeCurate from './AttributeCurate.jsx';
import AttributeLearning from './AttributeLearning.jsx';
import AttributeDrift from './AttributeDrift.jsx';
import AttributeField from './AttributeField.jsx';
import Comments from '../../../Comments/Comments.jsx';
import Loader from '../../../Loaders/Loader.jsx';
import { createAuditLog, getAttributeRating } from '../../../../actions/datasourceActions';
import { getAuditLogText } from '../../../../helpers/appHelpers';


const PropertyTile = (props) => {
    const dispatch = useDispatch();
    const { classes, attribute, isEditable, updatePropertiesList, datasetId, fields, tabFields, customTabs, isDeletePermission, isEditPermission, updateFieldProperties, isVisible } = props;
    const tabIndex = useSelector(({ dataset }) => dataset.propertiesTabIndex);
    const config = useSelector(({ setting }) => setting.config);
    const profileSetting = useSelector(({ setting }) => setting.profileSetting);
    const [tabHeader, setTabHeader] = useState(appConstants.attributeTabHeader);
    const [attribRating, setAttribRating] = useState(0);

    const attributeTabHeader = useCallback(() => {
        const newTabs = [...appConstants.attributeTabHeader];
        if (fields.length > 0) {
            newTabs.push(appConstants.fieldKeys.tabName);
        }
        if (!config.drift) {
            newTabs.splice(newTabs.indexOf('Drift'), 1);
        }
        if (!config.curate) {
            newTabs.splice(newTabs.indexOf('Curate'), 1);
            newTabs.splice(newTabs.indexOf('Learning'), 1);
        }
        if (!config.collaboration) {
            newTabs.splice(newTabs.indexOf('Comments'), 1);
        }
        setTabHeader([...newTabs, ...customTabs]);
    }, [config.collaboration, config.curate, config.drift, customTabs, fields.length]);

    /**
     * @name useEffect
     * @description UseEffect run when page initialize and any updates happends
     */
    useEffect(() => {
        attributeTabHeader();
    }, [attributeTabHeader]);

    useEffect(() => {
        setAttribRating(attribute.attribute_rating);
    }, [attribute.attribute_rating]);


    /**
     * get Tab Index
     * @param {*} index
     * @returns
     */
    const getTabIndex = (tabName) => {
        const index = tabHeader.indexOf(tabName);
        return index;
    };

    const setPropertiesTabIndex = useCallback((index) => {
        dispatch(datasetPropertiesTab(index));
    }, [dispatch]);

    const updateRating = () => {
        dispatch(getAttributeRating(props.attribute.source, props.attribute.dataset, 0, props.attribute.attribute)).then((response) => {
            if (response) {
                setAttribRating(response);
            }
        });
    };


    const updateAuditLogComments = (property, text) => {
        const data = getAuditLogText(property, attribute.attribute_name, 'attribute', text);
        const requestParams = {
            "source_id": props.attribute.source,
            "dataset_id": props.attribute.dataset,
            "attribute_id": props.attribute.attribute,
            "audit_logtext": data.text,
            "type": data.type
        };
        dispatch(createAuditLog(requestParams));
    };

    return (
        <Grid container direction="column" className={classes.propertySliderItem}>
            <AttributeTileHeader attribute={attribute ? attribute : {}} isEditable={isEditable || isEditPermission} updatePropertiesList={updatePropertiesList} updateFieldProperties={updateFieldProperties} attribRating={attribRating} />
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid container direction="row" justify="flex-start">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid className={classes.datasourceTabContainer}>
                            <Tabs
                                value={tabIndex}
                                indicatorColor="secondary"
                                textColor="secondary"
                                onChange={(event, index) => setPropertiesTabIndex(index)}
                                aria-label="dataset tabs"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                {
                                    tabHeader && tabHeader.map((name, index) =>
                                        <Tab key={`attributeTabPanel${index}`} label={name === "Attributes" ? `${name} (${this.props.datasetInfo.total_attributes ? this.props.datasetInfo.total_attributes : '0'})` : name} />
                                    )
                                }
                            </Tabs>
                        </Grid>
                        <Grid className={classNames(classes.marginTop15, classes.tabContainer, classes.propertyTabContainer)} style={{ height: 'calc(100vh - 410px)' }}>
                            <TabPanel value={tabIndex} index={getTabIndex('Property')}>
                                {
                                    (tabIndex === getTabIndex('Property')) &&
                                    <AttributeProperty isEditable={isEditable || isEditPermission} attribute={attribute ? attribute : {}} updatePropertiesList={updatePropertiesList} />
                                }
                            </TabPanel>
                            <TabPanel value={tabIndex} index={getTabIndex('Values')}>
                                {
                                    (tabIndex === getTabIndex('Values')) &&
                                    <AttributeValues isEditable={isEditable} isEditPermission={isEditPermission} isDeletePermission={isDeletePermission} attribute={attribute ? attribute : {}} updatePropertiesList={updatePropertiesList} />
                                }
                            </TabPanel>
                            <TabPanel value={tabIndex} index={getTabIndex('Rule')}>
                                {
                                    (tabIndex === getTabIndex('Rule')) &&
                                    <AttributeRules isEditable={isEditable} isEditPermission={isEditPermission} isDeletePermission={isDeletePermission} attribute={attribute ? attribute : {}} profileSetting={profileSetting ? profileSetting : {}} updatePropertiesList={updatePropertiesList} />
                                }
                            </TabPanel>
                            <TabPanel value={tabIndex} index={getTabIndex('Curate')}>
                                {
                                    (tabIndex === getTabIndex('Curate')) &&
                                    <AttributeCurate isEditPermission={isEditPermission} isDeletePermission={isDeletePermission} isEditable={isEditable} tabIndex={tabIndex} attribute={attribute ? attribute : {}} updatePropertiesList={updatePropertiesList} />
                                }
                            </TabPanel>
                            <TabPanel value={tabIndex} index={getTabIndex('Learning')}>
                                {
                                    (tabIndex === getTabIndex('Learning')) &&
                                    <AttributeLearning isEditable={isEditable} attribute={attribute ? attribute : {}} isDeletePermission={isDeletePermission} updatePropertiesList={updatePropertiesList} datasetId={datasetId} />
                                }
                            </TabPanel>
                            <TabPanel value={tabIndex} index={getTabIndex('Drift')}>
                                {
                                    (tabIndex === getTabIndex('Drift')) &&
                                    <AttributeDrift isEditPermission={isEditPermission} isEditable={isEditable} attribute={attribute ? attribute : {}} updatePropertiesList={updatePropertiesList} datasetId={datasetId} />
                                }
                            </TabPanel>
                            <TabPanel value={tabIndex} index={getTabIndex('Comments')}>
                                {
                                    ((tabIndex === getTabIndex('Comments')) && isVisible) ?
                                        <Comments type="attribute" datasourceId={props.attribute.source} datasetId={props.attribute.dataset} attributeId={props.attribute.attribute} attributeName={props.attribute.attribute_name} updateRating={updateRating} updateAuditLogComments={updateAuditLogComments} /> :
                                        <Loader />
                                }
                            </TabPanel>
                            {
                                fields.length > 0 &&
                                <TabPanel value={tabIndex} index={getTabIndex(appConstants.fieldKeys.tabName)}>
                                    {
                                        (tabIndex === getTabIndex(appConstants.fieldKeys.tabName)) &&
                                        <AttributeField isEditable={isEditable || isEditPermission} attribute={attribute ? attribute : {}} datasetId={datasetId} fields={fields} />
                                    }
                                </TabPanel>
                            }
                            {
                                customTabs && customTabs.map((field, index) =>
                                    <TabPanel key={index} value={tabIndex} index={getTabIndex(field)}>
                                        {
                                            (tabIndex === getTabIndex(field)) &&
                                            <AttributeField isEditable={isEditable || isEditPermission} attribute={attribute ? attribute : {}} datasetId={datasetId} fields={tabFields.filter((tab) => tab.tab_name === field)} />
                                        }
                                    </TabPanel>
                                )
                            }

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

PropertyTile.propTypes = {
    classes: PropTypes.object,
    attribute: PropTypes.object,
    isEditable: PropTypes.bool,
    updatePropertiesList: PropTypes.func,
    datasetId: PropTypes.string,
    fields: PropTypes.array,
    tabFields: PropTypes.array,
    customTabs: PropTypes.array,
    isDeletePermission: PropTypes.bool,
    isEditPermission: PropTypes.bool,
    updateFieldProperties: PropTypes.func,
    isVisible: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(PropertyTile);