const PermissionStyles = (theme) => ({
    root: {
        flexGrow: '1 auto',
        height: '100%'
    },
    paddingRight15: {
        paddingRight: 15
    },
    marginBottom10: {
        marginBottom: 10
    },
    cardContainer: {
        backgroundColor: theme.palette.grey.exteremeLight,
        padding: 12,
        letterSpacing: 0,
        height: '100%'
    },
    permissionText: {
        color: theme.palette.grey.dark,
        fontSize: 11,
        marginBottom: 10
    },
    permissionTitleText: {
        color: theme.palette.grey.darkTxt
    },
    formGroup: {
        flexDirection: 'row',
        '& .MuiFormControlLabel-root': {
            marginRight: 42
        },
        '& .MuiTypography-body1': {
            color: theme.palette.grey.default,
            fontSize: 13
        }
    },
    termsInput: {
        '& textarea': {
            fontSize: 11,
            height: 65
        }
    },
    permissionContainer: {
        height: 250,
        overflow: "auto"
    },
    listChip: {
        display: 'flex',
        marginRight: 5,
        alignItems: "center",
        animation: 'slide-up 0.4s ease',
        '& .MuiAvatar-root': {
            display: 'inline-flex',
            marginRight: 2
        },
        cursor: 'pointer'
    },
    chipLast: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        justifyContent: 'center',
        marginRight: 10,
        alignItems: 'center',
        cursor: 'pointer'
    },
    chipLastTxt: {
        fontSize: 12,
        color: theme.palette.background.paper
    },
    userChip: {
        display: 'inline-flex',
        alignItems: 'center',
        marginRight: 5,
        '& .MuiTypography-body2': {
            marginLeft: 2,
            marginRight: 2
        }
    },
    userChipSection: {
        margin: '5px 0px'
    },
    badge: {
        marginLeft: 5,
        width: 18,
        height: 18,
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.primary.main,
        fontSize: 10,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%'
    },
    stewardContainer: {
        marginLeft: 10,
        marginTop: -10
    },
    txteditorContainer: {
        '&: hover': {
            borderBottom: `1px solid #fff`
        },
        '& .ck.ck-editor__editable_inline': {
            maxHeight: '61px',
            paddingLeft: 0,
            height: 61,
            overflow: 'auto',
            color: `${theme.palette.grey.dark}`,
            fontSize: 11,
            // borderBottom: `1px solid ${theme.palette.primary.dark}`,
            scrollbarColor: `#9EAEB4 ${theme.palette.background.default}`,
            scrollbarWidth: 'thin',
            "&::-webkit-scrollbar": {
                height: '8px',
                backgroundColor: theme.palette.background.default,
                width: '8px',
                zIndex: 9999
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: '#9EAEB4',
                borderRadius: '4px',
                zIndex: 9999
            }
        },
        '& .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused': {
            outline: 'none !important',
            border: 'none !important',
            borderBottom: `2px solid ${theme.palette.primary.dark} !important`,
            boxShadow: 'none !important'
        },
        '& .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred': {
            outline: 'none !important'
        },
        '& .ck-content p': {
            wordBreak: 'break-all',
            fontSize: 11,
            color: `${theme.palette.grey.dark}`,
            marginTop: '0 !important',
            marginBottom: 3
        },
        '& .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred:hover': {
            borderBottom: `1px solid ${theme.palette.grey.dark} !important`
        }
    },
    txteditor: {
        borderBottom: `1px solid ${theme.palette.primary.default}`
    },
    UserIcon: {
        '&:hover': {
            '& g path': {
                fill: theme.palette.secondary.main
            },
            '& g#Ellipse_990': {
                stroke: theme.palette.secondary.main
            }
        }
    },
    accessPopover: {
        width: 700,
        padding: 15,
        '& .MuiListItem-gutters': {
            padding: '0px !important'
        },
        '& .MuiListItemAvatar-root': {
            marginRight: 10
        }
    },
    height20: {
        height: 20
    },
    overviewUserList: {
        '& .selectContainer': {
            paddingTop: 3
            // overflowY: 'auto', maxHeight: '300px !important'
        }
    },
    userClose: {
        background: '#fff',
        padding: 2,
        borderRadius: '30px'
    },
    requestInfo: {
        marginRight: 10,
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginLeft: 10
        }
    },
    requestPopup: {
        minWidth: 350,
        padding: 15
    },
    wordBreak: {
        wordBreak: 'break-all',
        paddingRight: 150
    }
});

export default PermissionStyles;