import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dialog, withStyles, IconButton, Grid, Typography, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../layouts/Styles.jsx';
import ActionItemStyles from './ActionItemStyles.jsx';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ActionListDetailComment from './ActionListDetailComment.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import SendIcon from '@material-ui/icons/Send';
import { appConstants } from '../../constants/appConstants';
import { getMentionName, getPosition } from '../../helpers/appHelpers';
import { getActionItemDetail } from '../../actions/notificationActions';

const ActionDetail = (props) => {
    const { classes, actionItem, open, addComment, goBack, updateActionItemDetail, history } = props;
    const [comment, setComment] = useState('');
    const [details, setDetails] = useState({ "asset_path": "", comments: [] });
    const [componentLoad, setComponentLoad] = useState(false);
    const users = useSelector(({ account }) => account.users);
    const user = useSelector(({ account }) => account.user);
    const dispatch = useDispatch();
    const imageref = useRef();
    const [imageWidth, setImageWidth] = useState();
    const [imageHeight, setImageHeight] = useState();

    const getImageDimension = () => {
        const newWidth = imageref.current?.clientWidth;
        setImageWidth(newWidth);
        const newHeight = imageref.current?.clientHeight;
        setImageHeight(newHeight);
    };

    useEffect(() => {
        getImageDimension();
      }, [details]);

    const getDetail = useCallback(() => {
        dispatch(getActionItemDetail(actionItem.id)).then((response) => {
            if (response) {
                setDetails({ ...response });
            }
        });
    }, [actionItem.id, dispatch]);

    useEffect(() => {
        getDetail();
    }, [getDetail]);

    const postComment = useCallback(() => {
        addComment(comment);
        setComment('');
    }, [addComment, comment]);

    const setRender = useCallback(() => {
        setComponentLoad(true);
    }, []);

    const permission = actionItem && actionItem.users ? actionItem.users.some((userInfo) => userInfo.assign_user_id === user.id || user.email === actionItem.assigneduser) : false;
    return (
        <Dialog fullScreen
            open={open}
        >

            {!componentLoad && setRender()}
            <Grid container justify="space-between" className={classes.detailDialog}>
                <Grid item>
                    <Grid container alignItems="center">
                        <IconButton className={classes.nopadding} onClick={() => goBack()}>
                            <HighlightOffIcon />
                        </IconButton>
                        <Typography className={classes.marginLeft15} component="h5" variant="h5" dangerouslySetInnerHTML={{ __html: (actionItem && actionItem.comments) ? getMentionName(actionItem.comments[0].comment, users) : '' }} />
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container alignItems="center" justify="space-between">
                        <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                            <TextBox
                                label="Assignee"
                                value={actionItem.assigneduser ? actionItem.assigneduser : ''}
                                name="assigneduser"
                                fullWidth
                                // select
                                onChange={(event) => updateActionItemDetail(event.target.name, event.target.value)}
                                disabled
                                className={classes.menuItem}
                                SelectProps={
                                    {

                                        value: actionItem.assigneduser ? actionItem.assigneduser : '',
                                        onChange: (event) => updateActionItemDetail(event.target.name, event.target.value),
                                        className: classes.menuItem,
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center"
                                            },
                                            getContentAnchorEl: null
                                        }
                                        // IconComponent: () => DropDownIcon()
                                    }
                                }
                            >
                                {
                                    users.map((item, index) =>
                                        <MenuItem className={classes.menuItem} key={index} value={item.username}>
                                            {item.username}
                                        </MenuItem>
                                    )
                                }
                            </TextBox>
                        </Grid>
                        <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                            <TextBox
                                label="Reporter"
                                value={actionItem.reporteruser ? actionItem.reporteruser : ''}
                                name="reporter"
                                fullWidth
                                // select
                                onChange={(event) => updateActionItemDetail(event.target.name, event.target.value)}
                                disabled
                                className={classes.menuItem}
                                SelectProps={
                                    {

                                        value: actionItem.reporteruser ? actionItem.reporteruser : '',
                                        onChange: (event) => updateActionItemDetail(event.target.name, event.target.value),
                                        className: classes.menuItem,
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center"
                                            },
                                            getContentAnchorEl: null
                                        }
                                        // IconComponent: () => DropDownIcon()
                                    }
                                }
                            >
                                {
                                    users.map((item, index) =>
                                        <MenuItem className={classes.menuItem} key={index} value={item.username}>
                                            {item.username}
                                        </MenuItem>
                                    )
                                }
                            </TextBox>
                        </Grid>
                        <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                            <TextBox
                                value={actionItem.status ? actionItem.status : 'open'}
                                name="status"
                                fullWidth
                                select
                                onChange={(event) => updateActionItemDetail(event.target.name, event.target.value)}
                                disabled={!permission}
                                className={classes.menuItem}
                                SelectProps={
                                    {

                                        value: actionItem.status ? actionItem.status : 'open',
                                        onChange: (event) => updateActionItemDetail(event.target.name, event.target.value),
                                        className: classes.menuItem,
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center"
                                            },
                                            getContentAnchorEl: null
                                        }
                                        // IconComponent: () => DropDownIcon()
                                    }
                                }
                            >
                                {
                                    appConstants.status.map((status, index) =>
                                        <MenuItem className={classes.menuItem} key={index} value={status}>
                                            {status}
                                        </MenuItem>
                                    )
                                }
                            </TextBox>
                        </Grid>
                        <Grid item className={classNames(classes.select, classes.filterselect)}>
                            <TextBox
                                value={actionItem.priority ? actionItem.priority : 'High'}
                                name="priority"
                                fullWidth
                                select
                                onChange={(event) => updateActionItemDetail(event.target.name, event.target.value)}
                                disabled={!permission}
                                className={classes.menuItem}
                                SelectProps={
                                    {

                                        value: actionItem.priority ? actionItem.priority : 'High',
                                        onChange: (event) => updateActionItemDetail(event.target.name, event.target.value),
                                        className: classes.menuItem,
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center"
                                            },
                                            getContentAnchorEl: null
                                        }
                                        // IconComponent: () => DropDownIcon()
                                    }
                                }
                            >
                                {
                                    appConstants.priority.map((priority, index) =>
                                        <MenuItem key={index} value={priority}>
                                            {priority}
                                        </MenuItem>
                                    )
                                }
                            </TextBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container className={classes.detailContentContainer}>
                <Grid item className={classes.detailContentSection}>
                    <Grid align="center" className={classes.detailImage} >
                        <img src={details.asset_path}
                            alt=""
                            ref={imageref}
                            className={classNames(classes.detailImage, classes.cursorPointer)}
                            onClick={
                                () => {
                                    if (actionItem && actionItem.source_id) {
                                        if (actionItem.dataset_id) {
                                            switch (actionItem.tab_index) {
                                                case 'Info': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: true, executionLogs: false, curate: false, dqscore: false, attributeList: false, attribute: false, monitoring: false } });
                                                                break;
                                                case 'Attributes': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, executionLogs: false, curate: false, dqscore: false, attributeList: true, attribute: false, monitoring: false } });
                                                                break;
                                                case 'Properties': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, executionLogs: false, curate: false, dqscore: false, attributeList: false, attribute: true, monitoring: false, propertyTabIndex: actionItem.properties_tab_index } });
                                                                break;
                                                case 'Profile': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, executionLogs: false, curate: false, dqscore: true, attributeList: false, attribute: false, monitoring: false } });
                                                                break;
                                                case 'Monitoring': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, executionLogs: false, curate: false, dqscore: false, attributeList: false, attribute: false, monitoring: true } });
                                                                break;
                                                case 'Exportmetrics': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, executionLogs: false, curate: false, dqscore: false, attributeList: false, attribute: false, monitoring: true } });
                                                                break;
                                                case 'Curate': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, executionLogs: false, curate: true, dqscore: false, attributeList: false, attribute: false, monitoring: false } });
                                                                break;
                                                case 'Execution Logs': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, executionLogs: true, curate: false, dqscore: false, attributeList: false, attribute: false, monitoring: false } });
                                                                break;
                                                default: history.push({ pathname: `/dataset/${actionItem.dataset_id}` });
                                                    break;
                                            }
                                        } else {
                                            switch (actionItem.tab_index) {
                                                case 'Info': history.push({ pathname: `/catalog/${actionItem.source_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: true, dqscore: false, executionLogs: false, metrics: false } });
                                                                break;
                                                case 'Datasets': history.push({ pathname: `/catalog/${actionItem.source_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, dqscore: true, executionLogs: false, metrics: false } });
                                                                break;
                                                case 'Metrics': history.push({ pathname: `/catalog/${actionItem.source_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, dqscore: false, executionLogs: false, metrics: true } });
                                                                break;
                                                case 'Execution Logs': history.push({ pathname: `/catalog/${actionItem.source_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, dqscore: false, executionLogs: true, metrics: false } });
                                                                break;
                                                default: history.push(`/catalog/${actionItem.source_id}`);
                                                        break;
                                            }
                                        }
                                    } else {
                                        history.push(`/actionitem`);
                                    }
                                }
                            }
                        />
                        <Grid className={classNames(classes.circleDot, classes.cursorPointer)} style={{ top: getPosition(actionItem.screen.height, imageHeight ? imageHeight : 0, actionItem.position.top), left: getPosition(actionItem.screen.width, imageWidth ? imageWidth : 0, actionItem.position.left) }}>
                            {actionItem.count}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={4} className={classes.detailCommentSection}>
                    <Grid className={classes.detailCommentList}>
                        {
                            actionItem.comments.map((comment, index) =>
                                <ActionListDetailComment key={index} comment={comment} />
                            )
                        }
                    </Grid>
                    {
                        permission &&
                        <Grid className={classes.addCommentSection}>
                            <Grid className={classes.commentContainer}>
                                <TextBox
                                    name="comment"
                                    multiline
                                    rows="4"
                                    placeholder="Comment"
                                    id="comment"
                                    fullWidth
                                    margin="normal"
                                    value={comment ? comment : ''}
                                    onChange={(event) => setComment(event.target.value)}
                                    className={classNames(classes.inputOutline, classes.validationTextBox)} />
                                <IconButton className={classes.commentSendBtn} onClick={() => (comment !== "" ? postComment() : null)}>
                                    <SendIcon color="primary" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Dialog >
    );

};

ActionDetail.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    actionItem: PropTypes.object,
    addComment: PropTypes.func,
    goBack: PropTypes.func,
    updateActionItemDetail: PropTypes.func,
    history: PropTypes.object
};


export default withStyles((theme) => ({
    ...ActionItemStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ActionDetail);