export const modelActions = {

    CREATE_SEMANTIC_MODEL_REQUEST: 'CREATE_SEMANTIC_MODEL_REQUEST',
    CREATE_SEMANTIC_MODEL_SUCCESS: 'CREATE_SEMANTIC_MODEL_SUCCESS',
    CREATE_SEMANTIC_MODEL_FAILURE: 'CREATE_SEMANTIC_MODEL_FAILURE',

    GET_ALL_SEMANTIC_MODEL_REQUEST: 'GET_ALL_SEMANTIC_MODEL_REQUEST',
    GET_ALL_SEMANTIC_MODEL_SUCCESS: 'GET_ALL_SEMANTIC_MODEL_SUCCESS',
    GET_ALL_SEMANTIC_MODEL_FAILURE: 'GET_ALL_SEMANTIC_MODEL_FAILURE',

    GET_ALL_SEMANTIC_MODEL_BY_ID_REQUEST: 'GET_ALL_SEMANTIC_MODEL_BY_ID_REQUEST',
    GET_ALL_SEMANTIC_MODEL_BY_ID_SUCCESS: 'GET_ALL_SEMANTIC_MODEL_BY_ID_SUCCESS',
    GET_ALL_SEMANTIC_MODEL_BY_ID_FAILURE: 'GET_ALL_SEMANTIC_MODEL_BY_ID_FAILURE',

    CREATE_ATTRIBUTE_REQUEST: 'CREATE_ATTRIBUTE_REQUEST',
    CREATE_ATTRIBUTE_SUCCESS: 'CREATE_ATTRIBUTE_SUCCESS',
    CREATE_ATTRIBUTE_FAILURE: 'CREATE_ATTRIBUTE_FAILURE',

    GET_ALL_ATTRIBUTE_REQUEST: 'GET_ALL_ATTRIBUTE_REQUEST',
    GET_ALL_ATTRIBUTE_SUCCESS: 'GET_ALL_ATTRIBUTE_SUCCESS',
    GET_ALL_ATTRIBUTE_FAILURE: 'GET_ALL_ATTRIBUTE_FAILURE',

    DELETE_ATTRIBUTE_REQUEST: 'DELETE_ATTRIBUTE_REQUEST',
    DELETE_ATTRIBUTE_SUCCESS: 'DELETE_ATTRIBUTE_SUCCESS',
    DELETE_ATTRIBUTE_FAILURE: 'DELETE_ATTRIBUTE_FAILURE',

    UPDATE_ATTRIBUTE_REQUEST: 'UPDATE_ATTRIBUTE_REQUEST',
    UPDATE_ATTRIBUTE_SUCCESS: 'UPDATE_ATTRIBUTE_SUCCESS',
    UPDATE_ATTRIBUTE_FAILURE: 'UPDATE_ATTRIBUTE_FAILURE',

    UPDATE_MODEL_REQUEST: 'UPDATE_MODEL_REQUEST',
    UPDATE_MODEL_SUCCESS: 'UPDATE_MODEL_SUCCESS',
    UPDATE_MODEL_FAILURE: 'UPDATE_MODEL_FAILURE',

    IMPORT_MODEL_REQUEST: 'IMPORT_MODEL_REQUEST',
    IMPORT_MODEL_SUCCESS: 'IMPORT_MODEL_SUCCESS',
    IMPORT_MODEL_FAILURE: 'IMPORT_MODEL_FAILURE',

    GET_ALL_SEMANTIC_MODEL_SHARING_INFO_REQUEST: 'GET_ALL_SEMANTIC_MODEL_SHARING_INFO_REQUEST',
    GET_ALL_SEMANTIC_MODEL_SHARING_INFO_SUCCESS: 'GET_ALL_SEMANTIC_MODEL_SHARING_INFO_SUCCESS',
    GET_ALL_SEMANTIC_MODEL_SHARING_INFO_FAILURE: 'GET_ALL_SEMANTIC_MODEL_SHARING_INFO_FAILURE',

    CREATE_SEMANTIC_MODEL_SHARE_REQUEST: 'CREATE_SEMANTIC_MODEL_SHARE_REQUEST',
    CREATE_SEMANTIC_MODEL_SHARE_SUCCESS: 'CREATE_SEMANTIC_MODEL_SHARE_SUCCESS',
    CREATE_SEMANTIC_MODEL_SHARE_FAILURE: 'CREATE_SEMANTIC_MODEL_SHARE_FAILURE',

    DELETE_SEMANTIC_MODEL_SHARE_REQUEST: 'DELETE_SEMANTIC_MODEL_SHARE_REQUEST',
    DELETE_SEMANTIC_MODEL_SHARE_SUCCESS: 'DELETE_SEMANTIC_MODEL_SHARE_SUCCESS',
    DELETE_SEMANTIC_MODEL_SHARE_FAILURE: 'DELETE_SEMANTIC_MODEL_SHARE_FAILURE',

    EXPORT_MODEL_REQUEST: 'EXPORT_MODEL_REQUEST',
    EXPORT_MODEL_SUCCESS: 'EXPORT_MODEL_SUCCESS',
    EXPORT_MODEL_FAILURE: 'EXPORT_MODEL_FAILURE',

    DELETE_MODEL_REQUEST: 'DELETE_MODEL_REQUEST',
    DELETE_MODEL_SUCCESS: 'DELETE_MODEL_SUCCESS',
    DELETE_MODEL_FAILURE: 'DELETE_MODEL_FAILURE',

    GET_SEMANTIC_MODEL_GLOSSARY_REQUEST: 'GET_SEMANTIC_MODEL_GLOSSARY_REQUEST',
    GET_SEMANTIC_MODEL_GLOSSARY_SUCCESS: 'GET_SEMANTIC_MODEL_GLOSSARY_SUCCESS',
    GET_SEMANTIC_MODEL_GLOSSARY_FAILURE: 'GET_SEMANTIC_MODEL_GLOSSARY_FAILURE',

    CREATE_SEMANTIC_GLOSSARY_REQUEST: 'CREATE_SEMANTIC_GLOSSARY_REQUEST',
    CREATE_SEMANTIC_GLOSSARY_SUCCESS: 'CREATE_SEMANTIC_GLOSSARY_SUCCESS',
    CREATE_SEMANTIC_GLOSSARY_FAILURE: 'CREATE_SEMANTIC_GLOSSARY_FAILURE',

    GET_GLOSSARY_DETAIL_REQUEST: 'GET_GLOSSARY_DETAIL_REQUEST',
    GET_GLOSSARY_DETAIL_SUCCESS: 'GET_GLOSSARY_DETAIL_SUCCESS',
    GET_GLOSSARY_DETAIL_FAILURE: 'GET_GLOSSARY_DETAIL_FAILURE',

    GET_GLOSSARY_ASSET_FIELDS_REQUEST: 'GET_GLOSSARY_ASSET_FIELDS_REQUEST',
    GET_GLOSSARY_ASSET_FIELDS_SUCCESS: 'GET_GLOSSARY_ASSET_FIELDS_SUCCESS',
    GET_GLOSSARY_ASSET_FIELDS_FAILURE: 'GET_GLOSSARY_ASSET_FIELDS_FAILURE',

    GET_SEMANTIC_MODEL_DEFINITION_REQUEST: 'GET_SEMANTIC_MODEL_DEFINITION_REQUEST',
    GET_SEMANTIC_MODEL_DEFINITION_SUCCESS: 'GET_SEMANTIC_MODEL_DEFINITION_SUCCESS',
    GET_SEMANTIC_MODEL_DEFINITION_FAILURE: 'GET_SEMANTIC_MODEL_DEFINITION_FAILURE',

    LINK_ASSET_GLOSSARY_REQUEST: 'LINK_ASSET_GLOSSARY_REQUEST',
    LINK_ASSET_GLOSSARY_SUCCESS: 'LINK_ASSET_GLOSSARY_SUCCESS',
    LINK_ASSET_GLOSSARY_FAILURE: 'LINK_ASSET_GLOSSARY_FAILURE',

    UNLINK_ASSET_FIELD_REQUEST: 'UNLINK_ASSET_FIELD_REQUEST',
    UNLINK_ASSET_FIELD_SUCCESS: 'UNLINK_ASSET_FIELD_SUCCESS',
    UNLINK_ASSET_FIELD_FAILURE: 'UNLINK_ASSET_FIELD_FAILURE',

    GET_ALL_ENRICHMENT_REQUEST: 'GET_ALL_ENRICHMENT_REQUEST',
    GET_ALL_ENRICHMENT_SUCCESS: 'GET_ALL_ENRICHMENT_SUCCESS',
    GET_ALL_ENRICHMENT_FAILURE: 'GET_ALL_ENRICHMENT_FAILURE',

    GET_BREAD_CRUMBS_REQUEST: 'GET_BREAD_CRUMBS_REQUEST',
    GET_BREAD_CRUMBS_SUCCESS: 'GET_BREAD_CRUMBS_SUCCESS',
    GET_BREAD_CRUMBS_FAILURE: 'GET_BREAD_CRUMBS_FAILURE',

    SELECT_GLOSSARY: 'SELECT_GLOSSARY',

    SET_SEMANTIC_FILTER: 'SET_SEMANTIC_FILTER',

    DELETE_SMENATIC_MODEL_ATTRIBUTE_REQUEST: 'DELETE_SMENATIC_MODEL_ATTRIBUTE_REQUEST',
    DELETE_SMENATIC_MODEL_ATTRIBUTE_SUCCESS: 'DELETE_SMENATIC_MODEL_ATTRIBUTE_SUCCESS',
    DELETE_SMENATIC_MODEL_ATTRIBUTE_FAILURE: 'DELETE_SMENATIC_MODEL_ATTRIBUTE_FAILURE',

    GET_SEARCH_RESULT_TAGS_REQUEST: 'GET_SEARCH_RESULT_TAGS_REQUEST',
    GET_SEARCH_RESULT_TAGS_SUCCESS: 'GET_SEARCH_RESULT_TAGS_SUCCESS',
    GET_SEARCH_RESULT_TAGS_FAILURE: 'GET_SEARCH_RESULT_TAGS_FAILURE'

};