import React, { memo } from 'react';
import {
    Grid, withStyles, Typography, IconButton
} from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TooltipComponent from '../Tooltip/Tooltip.jsx';

import Styles from '../../layouts/Styles.jsx';
import DatasetStyles from './DatasetStyles.jsx';
import noPrev from '../../assets/images/nopreview.svg';


const WorkbookItemGrid = ({ datasourceId, workbookId, classes, theme, items, deleteView, isChecked, selectView }) => {
    return (
        <Grid sx={{ flexGrow: 1 }} container spacing={2} className={classes.itemHeight} >
            <Grid item xs={12}>
                <Grid container justifyContent="center">
                    {
                        items.map((item, index) => (
                            <Grid key={item} item xs={3} className={classes.cursorPointer}>
                                <Grid className={classes.itemGrid}>
                                    <TooltipComponent title={"Datasources"} arrow className={classes.icoBtn}>
                                        <IconButton className={classNames(classes.icoBtn)} onClick={() => deleteView(item.id)}>
                                            <svg version="1.1"
                                                id="Layer_1"
                                                width="18"
                                                height="18"
                                                xmlns="http://www.w3.org/2000/svg"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 32 32">
                                                <g id="Delete">
                                                    <path fill={theme.palette.primary.main}
                                                        stroke={theme.palette.primary.main}
                                                        strokeWidth="0.92"
                                                        strokeMiterlimit="10"
                                                        d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
                                                        L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
                                                        L22.3,25.8z" />
                                                    <path fill={theme.palette.primary.main}
                                                        stroke={theme.palette.primary.main}
                                                        strokeWidth="0.92"
                                                        strokeMiterlimit="10"
                                                        d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
                                                        c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
                                                        c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                </g>
                                            </svg>
                                        </IconButton>
                                    </TooltipComponent>
                                    <Grid className={classes.imgContainer}>
                                        {
                                            item.thumb_image !== "" ?
                                                <img src={item.thumb_image} alt="" width="100%" height="175px" />
                                                :
                                                <Grid className={classes.noprevContainer}>
                                                <img src={noPrev} alt="" width="40px" />
                                                <Typography variant="h6" gutterBottom component="div">
                                                    No Preview Available
                                                </Typography>
                                                </Grid>
                                        }
                                    </Grid>
                                    <Grid className={classes.padding15} onClick={() => selectView(item)}>
                                        <Typography variant="h6" gutterBottom component="div">
                                            {item.name}
                                        </Typography>
                                        <Typography variant="body2" gutterBottom className={classes.gridTag}>
                                            {item && item.view_type ? item.view_type : 'Sheet'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

WorkbookItemGrid.propTypes = {
    classes: PropTypes.object,
    datasourceId: PropTypes.number,
    workbookId: PropTypes.string,
    theme: PropTypes.object,
    items: PropTypes.object,
    deleteView: PropTypes.func,
    isChecked: PropTypes.bool,
    selectView: PropTypes.func

};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(memo(WorkbookItemGrid));