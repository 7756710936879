import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

// import material-ui components
import { withStyles, Grid, Typography } from '@material-ui/core';
import classNames from "classnames";
import AttributeSelect from '../AttributeSelect/AttributeSelect.jsx';


// import styles
import Styles from '../../layouts/Styles.jsx';
import DatasetStyles from './DatasetStyles.jsx';

// import components
import MonitoringAlerts from '../Monitoring/MonitoringAlerts.jsx';

// import action files
import { getAllMonitoringDetails } from '../../actions/datasetActions';
import { getMonitoringStatistical } from '../../actions/scheduleActions.js';


const DatasetMonitoring = (props) => {
    const { classes, datasetId, datasourceId, properties, isSelected } = props;
    const dispatch = useDispatch();

    // add-icon onclick
    const [monitoringDetails, setMonitoringDetails] = useState([]);
    const [statisticalDetails, setStatisticalDetails] = useState({});


    const getMonitoringDetails = useCallback((datasetId) => {
        dispatch(getAllMonitoringDetails(datasetId)).then((response) => {
            setMonitoringDetails(response);
        });
        dispatch(getMonitoringStatistical(datasetId)).then((response) => {
            setStatisticalDetails({ ...response });
        });
    }, [dispatch]);

    useEffect(() => {
        if (!datasetId || !isSelected) {
          return;
        }
        getMonitoringDetails(datasetId);
    }, [datasetId, isSelected, getMonitoringDetails]);

    return (
        <Grid className={classes.tabSection} style={{ overflow: 'auto' }}>
        <Grid container>
            <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              direction="row"
              className={classNames(classes.card, classes.container)}
            >
              <Grid
                item
                xs={2}
                className={classNames(classes.selectContainer, classes.CurationSelectAttribute)}
              >
                <AttributeSelect
                  placeholder="Select Dataset or Attribute"
                  value={"Dataset"}
                  options={[{ name: "Dataset" }]}
                  selectedValuePath="name"
                  displayMemberPath="name"
                  removeClass
                  openSuggestionsOnClick
                  name="DriftOptions"
                  id="DriftOptions"
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <Grid className={classes.scoreContainer}>
                  <Grid>
                    <Typography
                      component="h5"
                      variant="h5"
                      className={classes.attributeAnalysisText}
                    >
                      {statisticalDetails.level}
                    </Typography>
                    <Typography>
                      {"Alert Level"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  component="h5"
                  variant="h5"
                  className={classes.attributeAnalysisText}
                >
                  {statisticalDetails.total_alert}
                </Typography>
                <Typography>
                  Total Alerts
                </Typography>
              </Grid>

              <Grid item xs={3}>
                <Typography
                  component="h5"
                  variant="h5"
                  className={classes.attributeAnalysisText}
                >
                  {statisticalDetails.completed_monitoring}
                </Typography>
                <Typography>
                  Monitored
                </Typography>
              </Grid>
            </Grid>
            </Grid>
            <Grid item xs={12}>
                <MonitoringAlerts datasetId={datasetId} datasourceId={datasourceId} properties={properties} monitoringDetails={monitoringDetails} />
            </Grid>
        </Grid>
        </Grid>
    );
};

DatasetMonitoring.propTypes = {
    classes: PropTypes.object,
    datasetId: PropTypes.number,
    datasourceId: PropTypes.number,
    properties: PropTypes.object,
    isSelected: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...Styles(theme)
  }), { withTheme: true })(DatasetMonitoring);