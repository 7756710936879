export const masterActions = {

    CREATE_MASTER_MODEL_REQUEST: 'CREATE_MASTER_MODEL_REQUEST',
    CREATE_MASTER_MODEL_SUCCESS: 'CREATE_MASTER_MODEL_SUCCESS',
    CREATE_MASTER_MODEL_FAILURE: 'CREATE_MASTER_MODEL_FAILURE',

    GET_ALL_MASTER_MODEL_REQUEST: 'GET_ALL_MASTER_MODEL_REQUEST',
    GET_ALL_MASTER_MODEL_SUCCESS: 'GET_ALL_MASTER_MODEL_SUCCESS',
    GET_ALL_MASTER_MODEL_FAILURE: 'GET_ALL_MASTER_MODEL_FAILURE',

    GET_ALL_MASTER_MODEL_BY_ID_REQUEST: 'GET_ALL_MASTER_MODEL_BY_ID_REQUEST',
    GET_ALL_MASTER_MODEL_BY_ID_SUCCESS: 'GET_ALL_MASTER_MODEL_BY_ID_SUCCESS',
    GET_ALL_MASTER_MODEL_BY_ID_FAILURE: 'GET_ALL_MASTER_MODEL_BY_ID_FAILURE',

    GET_ALL_MASTER_MODEL_SHARING_INFO_REQUEST: 'GET_ALL_MASTER_MODEL_SHARING_INFO_REQUEST',
    GET_ALL_MASTER_MODEL_SHARING_INFO_SUCCESS: 'GET_ALL_MASTER_MODEL_SHARING_INFO_SUCCESS',
    GET_ALL_MASTER_MODEL_SHARING_INFO_FAILURE: 'GET_ALL_MASTER_MODEL_SHARING_INFO_FAILURE',

    CREATE_MODEL_ATTRIBUTES_REQUEST: 'CREATE_MODEL_ATTRIBUTES_REQUEST',
    CREATE_MODEL_ATTRIBUTES_SUCCESS: 'CREATE_MODEL_ATTRIBUTES_SUCCESS',
    CREATE_MODEL_ATTRIBUTES_FAILURE: 'CREATE_MODEL_ATTRIBUTES_FAILURE',

    UPDATE_MODEL_ATTRIBUTES_REQUEST: 'UPDATE_MODEL_ATTRIBUTES_REQUEST',
    UPDATE_MODEL_ATTRIBUTES_SUCCESS: 'UPDATE_MODEL_ATTRIBUTES_SUCCESS',
    UPDATE_MODEL_ATTRIBUTES_FAILURE: 'UPDATE_MODEL_ATTRIBUTES_FAILURE',

    GET_MODEL_ATTRIBUTES_REQUEST: 'GET_MODEL_ATTRIBUTES_REQUEST',
    GET_MODEL_ATTRIBUTES_SUCCESS: 'GET_MODEL_ATTRIBUTES_SUCCESS',
    GET_MODEL_ATTRIBUTES_FAILURE: 'GET_MODEL_ATTRIBUTES_FAILURE',

    DELETE_MODEL_ATTRIBUTES_REQUEST: 'DELETE_MODEL_ATTRIBUTES_REQUEST',
    DELETE_MODEL_ATTRIBUTES_SUCCESS: 'DELETE_MODEL_ATTRIBUTES_SUCCESS',
    DELETE_MODEL_ATTRIBUTES_FAILURE: 'DELETE_MODEL_ATTRIBUTES_FAILURE',

    SET_SEARCH: 'SET_SEARCH',

    UPDATE_MODEL_REQUEST: 'UPDATE_MODEL_REQUEST',
    UPDATE_MODEL_SUCCESS: 'UPDATE_MODEL_SUCCESS',
    UPDATE_MODEL_FAILURE: 'UPDATE_MODEL_FAILURE',

    CREATE_MASTER_SHARE_REQUEST: 'CREATE_MASTER_SHARE_REQUEST',
    CREATE_MASTER_SHARE_SUCCESS: 'CREATE_MASTER_SHARE_SUCCESS',
    CREATE_MASTER_SHARE_FAILURE: 'CREATE_MASTER_SHARE_FAILURE',

    DELETE_MASTER_SHARE_REQUEST: 'DELETE_MASTER_SHARE_REQUEST',
    DELETE_MASTER_SHARE_SUCCESS: 'DELETE_MASTER_SHARE_SUCCESS',
    DELETE_MASTER_SHARE_FAILURE: 'DELETE_MASTER_SHARE_FAILURE',

    DELETE_MODEL_REQUEST: 'DELETE_MODEL_REQUEST',
    DELETE_MODEL_SUCCESS: 'DELETE_MODEL_SUCCESS',
    DELETE_MODEL_FAILURE: 'DELETE_MODEL_FAILURE',

    SEARCH_SEMANTIC_MODEL_REQUEST: 'SEARCH_SEMANTIC_MODEL_REQUEST',
    SEARCH_SEMANTIC_MODEL_SUCCESS: 'SEARCH_SEMANTIC_MODEL_SUCCESS',
    SEARCH_SEMANTIC_MODEL_FAILURE: 'SEARCH_SEMANTIC_MODEL_FAILURE',

    CLEAR_MASTER_SEARCH_RESULTS: 'CLEAR_MASTER_SEARCH_RESULTS',

    CREATE_MATCH_COMBINATION_REQUEST: 'CREATE_MATCH_COMBINATION_REQUEST',
    CREATE_MATCH_COMBINATION_SUCCESS: 'CREATE_MATCH_COMBINATION_SUCCESS',
    CREATE_MATCH_COMBINATION_FAILURE: 'CREATE_MATCH_COMBINATION_FAILURE',

    UPDATE_MATCH_COMBINATION_REQUEST: 'UPDATE_MATCH_COMBINATION_REQUEST',
    UPDATE_MATCH_COMBINATION_SUCCESS: 'UPDATE_MATCH_COMBINATION_SUCCESS',
    UPDATE_MATCH_COMBINATION_FAILURE: 'UPDATE_MATCH_COMBINATION_FAILURE',

    DELETE_MATCH_COMBINATION_REQUEST: 'DELETE_MATCH_COMBINATION_REQUEST',
    DELETE_MATCH_COMBINATION_SUCCESS: 'DELETE_MATCH_COMBINATION_SUCCESS',
    DELETE_MATCH_COMBINATION_FAILURE: 'DELETE_MATCH_COMBINATION_FAILURE',

    GET_ALL_MATCH_COMBINATIONS_REQUEST: 'GET_ALL_MATCH_COMBINATIONS_REQUEST',
    GET_ALL_MATCH_COMBINATIONS_SUCCESS: 'GET_ALL_MATCH_COMBINATIONS_SUCCESS',
    GET_ALL_MATCH_COMBINATIONS_FAILURE: 'GET_ALL_MATCH_COMBINATIONS_FAILURE',

    UPDATE_MATCH_COMBINATION_RANK_REQUEST: 'UPDATE_MATCH_COMBINATION_RANK_REQUEST',
    UPDATE_MATCH_COMBINATION_RANK_SUCCESS: 'UPDATE_MATCH_COMBINATION_RANK_SUCCESS',
    UPDATE_MATCH_COMBINATION_RANK_FAILURE: 'UPDATE_MATCH_COMBINATION_RANK_FAILURE',

    PUBLISH_SEMANTIC_MODEL_REQUEST: 'PUBLISH_SEMANTIC_MODEL_REQUEST',
    PUBLISH_SEMANTIC_MODEL_SUCCESS: 'PUBLISH_SEMANTIC_MODEL_SUCCESS',
    PUBLISH_SEMANTIC_MODEL_FAILURE: 'PUBLISH_SEMANTIC_MODEL_FAILURE',

    UPDATE_ATTRIBUTES_POSITION_REQUEST: 'UPDATE_ATTRIBUTES_POSITION_REQUEST',
    UPDATE_ATTRIBUTES_POSITION_SUCCESS: 'UPDATE_ATTRIBUTES_POSITION_SUCCESS',
    UPDATE_ATTRIBUTES_POSITION_FAILURE: 'UPDATE_ATTRIBUTES_POSITION_FAILURE',

    GET_DATASET_SCHEDULE_REQUEST: 'GET_DATASET_SCHEDULE_REQUEST',
    GET_DATASET_SCHEDULE_SUCCESS: 'GET_DATASET_SCHEDULE_SUCCESS',
    GET_DATASET_SCHEDULE_FAILURE: 'GET_DATASET_SCHEDULE_FAILURE',

    CREATE_DATASET_SCHEDULE_REQUEST: 'CREATE_DATASET_SCHEDULE_REQUEST',
    CREATE_DATASET_SCHEDULE_SUCCESS: 'CREATE_DATASET_SCHEDULE_SUCCESS',
    CREATE_DATASET_SCHEDULE_FAILURE: 'CREATE_DATASET_SCHEDULE_FAILURE',

    UPDATE_DATASET_SCHEDULE_REQUEST: 'UPDATE_DATASET_SCHEDULE_REQUEST',
    UPDATE_DATASET_SCHEDULE_SUCCESS: 'UPDATE_DATASET_SCHEDULE_SUCCESS',
    UPDATE_DATASET_SCHEDULE_FAILURE: 'UPDATE_DATASET_SCHEDULE_FAILURE',

    RUN_MASTER_MODEL_JOB_REQUEST: 'RUN_MASTER_MODEL_JOB_REQUEST',
    RUN_MASTER_MODEL_JOB_SUCCESS: 'RUN_MASTER_MODEL_JOB_SUCCESS',
    RUN_MASTER_MODEL_JOB_FAILURE: 'RUN_MASTER_MODEL_JOB_FAILURE',

    LOAD_GENERIC_TABLE_JOB_REQUEST: 'LOAD_GENERIC_TABLE_JOB_REQUEST',
    LOAD_GENERIC_TABLE_JOB_SUCCESS: 'LOAD_GENERIC_TABLE_JOB_SUCCESS',
    LOAD_GENERIC_TABLE_JOB_FAILURE: 'LOAD_GENERIC_TABLE_JOB_FAILURE',

    GENERATE_PERSION_ID_JOB_REQUEST: 'GENERATE_PERSION_ID_JOB_REQUEST',
    GENERATE_PERSION_ID_JOB_SUCCESS: 'GENERATE_PERSION_ID_JOB_SUCCESS',
    GENERATE_PERSION_ID_JOB_FAILURE: 'GENERATE_PERSION_ID_JOB_FAILURE',

    DELETE_DATASET_SCHEDULE_REQUEST: 'DELETE_DATASET_SCHEDULE_REQUEST',
    DELETE_DATASET_SCHEDULE_SUCCESS: 'DELETE_DATASET_SCHEDULE_SUCCESS',
    DELETE_DATASET_SCHEDULE_FAILURE: 'DELETE_DATASET_SCHEDULE_FAILURE',

    GET_MODEL_MAPPINGS_REQUEST: 'GET_MODEL_MAPPINGS_REQUEST',
    GET_MODEL_MAPPINGS_SUCCESS: 'GET_MODEL_MAPPINGS_SUCCESS',
    GET_MODEL_MAPPINGS_FAILURE: 'GET_MODEL_MAPPINGS_FAILURE',

    GET_EXECUTION_LOGS_REQUEST: 'GET_EXECUTION_LOGS_REQUEST',
    GET_EXECUTION_LOGS_SUCCESS: 'GET_EXECUTION_LOGS_SUCCESS',
    GET_EXECUTION_LOGS_FAILURE: 'GET_EXECUTION_LOGS_FAILURE',

    KILL_MASTER_MODEL_JOB_REQUEST: 'KILL_MASTER_MODEL_JOB_REQUEST',
    KILL_MASTER_MODEL_JOB_SUCCESS: 'KILL_MASTER_MODEL_JOB_SUCCESS',
    KILL_MASTER_MODEL_JOB_FAILURE: 'KILL_MASTER_MODEL_JOB_FAILURE',

    RESTART_MASTER_JOB_SCHEDULE_REQUEST: 'RESTART_MASTER_JOB_SCHEDULE_REQUEST',
    RESTART_MASTER_JOB_SCHEDULE_SUCCESS: 'RESTART_MASTER_JOB_SCHEDULE_SUCCESS',
    RESTART_MASTER_JOB_SCHEDULE_FAILURE: 'RESTART_MASTER_JOB_SCHEDULE_FAILURE',

    GET_MASTER_MODEL_TYPES_REQUEST: 'GET_MASTER_MODEL_TYPES_REQUEST',
    GET_MASTER_MODEL_TYPES_SUCCESS: 'GET_MASTER_MODEL_TYPES_SUCCESS',
    GET_MASTER_MODEL_TYPES_FAILURE: 'GET_MASTER_MODEL_TYPES_FAILURE',

    SET_ATTRIBUTES: 'SET_ATTRIBUTES',

    GET_MASTER_GLOSSARY_FIELDS_REQUEST: 'GET_MASTER_GLOSSARY_FIELDS_REQUEST',
    GET_MASTER_GLOSSARY_FIELDS_SUCCESS: 'GET_MASTER_GLOSSARY_FIELDS_SUCCESS',
    GET_MASTER_GLOSSARY_FIELDS_FAILURE: 'GET_MASTER_GLOSSARY_FIELDS_FAILURE',

    CREATE_MODEL_ATTRIBUTE_FIELDS_REQUEST: 'CREATE_MODEL_ATTRIBUTE_FIELDS_REQUEST',
    CREATE_MODEL_ATTRIBUTE_FIELDS_SUCCESS: 'CREATE_MODEL_ATTRIBUTE_FIELDS_SUCCESS',
    CREATE_MODEL_ATTRIBUTE_FIELDS_FAILURE: 'CREATE_MODEL_ATTRIBUTE_FIELDS_FAILURE',

    UPDATE_MASTER_MODEL_STATUS_REQUEST: 'UPDATE_MASTER_MODEL_STATUS_REQUEST',
    UPDATE_MASTER_MODEL_STATUS_SUCCESS: 'UPDATE_MASTER_MODEL_STATUS_SUCCESS',
    UPDATE_MASTER_MODEL_STATUS_FAILURE: 'UPDATE_MASTER_MODEL_STATUS_FAILURE'
};