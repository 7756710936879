import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import PublicLayout from '../layouts/PublicLayout.jsx';
import { getCookie, getUserProperty, getMenuPath, setCookie } from '../helpers/appHelpers';
import { useSelector } from 'react-redux';

const PublicRoute = ({ component: Component, ...rest }) => {
	const headerText = useSelector(({ setting }) => setting?.theme?.page_title || '');

	const loggeInStatus = getCookie('token');
	const userDetails = getUserProperty();
	if (rest.path && rest.path === "/activation/:id") {
		setCookie('token', '');
	}

	const getPageTitle = (pageTitle) => {
		if (pageTitle) {
			document.title = `${headerText.trim() || 'DQ Labs'} | ${pageTitle}`;
		} else if (document.title !== 'DQ Labs') {
			document.title = headerText || 'DQ Labs';
		}
	};

	return (
		<Route {...rest}
			render={
				(props) => (
					loggeInStatus === "" ?
						<PublicLayout>
							<Component {...props} getPageTitle={getPageTitle} />
						</PublicLayout>
						:
						<Redirect to={{ pathname: userDetails && userDetails.last_login ? getMenuPath(userDetails && userDetails.config ? userDetails.config : {}) : "/catalog", state: { from: props.location } }} />
				)
			} />
	);
};

PublicRoute.propTypes = {
	component: PropTypes.elementType,
	location: PropTypes.object
};

export default PublicRoute;