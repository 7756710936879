import React, { useState, useCallback, useEffect } from 'react';
import { Grid, IconButton, Button, Typography, withStyles, Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import Loader from '../Loaders/Loader.jsx';
import { getCurrentlyLivyStatementJobs, deleteStatementJobs, deleteSessionJobs, deleteBatchsJobs } from '../../actions/settingActions';
import moment from 'moment-timezone';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';


const LivyJobs = (props) => {
    const { classes, theme } = props;
    const [runningjobs, setrunningjobs] = useState([]);
    const [runningbatchs, setrunningbatchs] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const getRunningJob = useCallback(() => {
        setLoading(true);
        dispatch(getCurrentlyLivyStatementJobs()).then((response) => {
            if (response) {
                setrunningjobs(response.session);
                setrunningbatchs(response.batches);
            }
            setLoading(false);
        });
    }, [dispatch]);

    const killstatement = useCallback((jobid) => {
        dispatch(deleteStatementJobs(jobid));
    }, [dispatch]);

    const killbatches = useCallback((jobid) => {
        dispatch(deleteBatchsJobs(jobid));
    }, [dispatch]);

    const killsession = useCallback(() => {
        dispatch(deleteSessionJobs());
    }, [dispatch]);

    useEffect(() => {
        getRunningJob();
    }, [getRunningJob]);

    return (
        <Grid className={classes.referenceContainer2}>
            <Grid container justify="space-between">
                <Typography variant="h5" component="h5">
                    Running Livy Jobs
                </Typography>
            </Grid>
            <Grid>
                <Grid className={classes.marginTop10}>
                    <Typography variant="h5" component="h5">
                        Sessions Livy
                    </Typography>
                    <Table className={classes.marginTop10}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeadCell}>
                                    ID
                                </TableCell>
                                <TableCell className={classes.tableHeadCell}>
                                    State
                                </TableCell>
                                <TableCell className={classes.tableHeadCell} align="center">
                                    Action
                                </TableCell>
                                {/* <TableCell className={classes.tableHeadCell} align="center">
                                    Actions
                                </TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.loggingTable}>
                            {
                                runningjobs && runningjobs.map((jobs, index) =>
                                    <TableRow key={`error_${index}`}>
                                        <TableCell className={classes.tableCell}>
                                            {jobs.id ? jobs.id : 0}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {jobs.state ? jobs.state : ""}
                                        </TableCell>
                                        <TableCell align="center">
                                            <ToolTipComponent title="Delete" arrow>
                                                <IconButton onClick={() => killstatement(jobs.id)}>
                                                    <svg version="1.1" id="Layer_1" width="22" height="22" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
                                                        <g id="Delete">
                                                            <path fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="0.92" strokeMiterlimit="10" d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                                            <path fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="0.92" strokeMiterlimit="10" d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                        </g>
                                                    </svg>
                                                </IconButton>
                                            </ToolTipComponent>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </Grid>
                <br />

                <Grid className={classes.marginTop10}>
                    <Typography variant="h5" component="h5">
                        Batchs Livy
                    </Typography>
                    <Table className={classes.marginTop10}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeadCell}>
                                    Batch ID
                                </TableCell>
                                <TableCell className={classes.tableHeadCell}>
                                    Batch State
                                </TableCell>
                                <TableCell className={classes.tableHeadCell} align="center">
                                    Action
                                </TableCell>
                                {/* <TableCell className={classes.tableHeadCell} align="center">
                                    Actions
                                </TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.loggingTable}>
                            {
                                runningbatchs && runningbatchs.map((jobs, index) =>
                                    <TableRow key={`error_${index}`}>
                                        <TableCell className={classes.tableCell}>
                                            {jobs.id ? jobs.id : 0}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {jobs.state ? jobs.state : ""}
                                        </TableCell>
                                        <TableCell align="center">
                                            <ToolTipComponent title="Delete" arrow>
                                                <IconButton onClick={() => killbatches(jobs.id)}>
                                                    <svg version="1.1" id="Layer_1" width="22" height="22" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
                                                        <g id="Delete">
                                                            <path fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="0.92" strokeMiterlimit="10" d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                                            <path fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="0.92" strokeMiterlimit="10" d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                        </g>
                                                    </svg>
                                                </IconButton>
                                            </ToolTipComponent>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </Grid>

                <br />
                <Grid container justify="space-between">
                    <Button 
                        onClick={() => killsession()}
                        variant="contained"
                        color="primary">
                        {' Kill Current Session'}
                    </Button>
                </Grid>
            </Grid>
            {
                isLoading &&
                <Loader />
            }
            {!isLoading && runningjobs && runningjobs.length === 0 && <NoResultFound />}
        </Grid>
    );
};

LivyJobs.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(LivyJobs);