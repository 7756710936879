import React, { useEffect, useState, useCallback } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import jStat from 'jstat';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import ChartStyles from './ChartStyles.jsx';
import { appConstants } from '../../constants/appConstants';
import Group2138 from '../../assets/images/Group_2138.svg';
import Group2136 from '../../assets/images/Group_2136.svg';
import { getValue } from '../../helpers/appHelpers';

const Distribution = (props) => {

    const { classes, theme, analysis } = props;
    const [chartData, setChartData] = useState([]);

    const loadProperties = useCallback((analysisData) => {
        setChartData([...analysisData]);
    }, []);

    useEffect(() => {
        loadProperties([...analysis]);
    }, [analysis, loadProperties]);

    const normal = (score) => {
        let x = 0,
            y = 0;
        let rds = 0;
        do {
            x = Math.random() * 2 - 1;
            y = Math.random() * 2 - 1;
            rds = x * x + y * y;
        } while (rds === 0 || rds > 1);
        const c = Math.sqrt(-2 * Math.log(rds) / rds);
        return x * c;
    };

    const gaussian = (x) => {
        const gaussianConstant = 1 / Math.sqrt(2 * Math.PI);
        const mean = 0;
        const sigma = 1;
        x = (x - mean) / sigma;
        return gaussianConstant * Math.exp(-0.5 * x * x) / sigma;
    };

    useEffect(() => {
        if (chartData && chartData.length !== 0) {
            const scores = chartData.map((data) => data.dqscore);
            /*
             * const mean = jStat(scores).mean();
             * const std = jStat.stdev(scores);
             */
            const distributionData = [];
            for (const score of scores) {
                const q = normal(score);
                const p = gaussian(q);
                const el = {
                    x: q,
                    y: p
                };
                distributionData.push(el);
            }
            const xPoint = distributionData.map((d) => d.x);
            const mean = jStat(xPoint).mean();
            const std = jStat.stdev(xPoint);
            const lowerLimit = mean - (2 * std);
            const upperLimit = mean + (2 * std);

            const margin = { top: 40, right: 20, bottom: 50, left: 40 };
            let width = 960 - margin.left - margin.right;
            const height = 280;

            d3.select('.distribution-chart > *').remove();
            const svg = d3.select(".distribution-chart").append("svg")
                .attr("width", "100%")
                .style("margin", "auto")
                .attr("height", height + margin.top)
                .append("g")
                .attr("width", (_, i, nodes) => {
                    width = nodes[i].parentNode.clientWidth - 100;
                    return width;
                })
                .attr("height", height + margin.bottom)
                .attr("transform", "translate(" + (margin.left) + "," + (margin.top / 2) + ")");

            const x = d3.scaleLinear()
                .domain(d3.extent(distributionData, (d) => {
                    return d.x;
                }))
                .range([0, width])
                .nice();

            const minY = d3.min(distributionData, (d) => {
                return d.y;
            });
            const maxY = d3.max(distributionData, (d) => {
                return d.y;
            });
            const y = d3.scaleLinear().domain([minY, maxY + (minY / 2)]).range([height, 0]).nice();

            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .attr("color", theme.palette.chartColors.axis)
                .attr('class', 'axis-x')
                .transition()
                .duration(2000)
                .call(d3.axisBottom(x).tickSize(-height));

            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
            svg.selectAll('.axis-y .tick-text').text('').append('tspan').text((d) => { return d; });

            const defs = svg.append('defs');
            defs
                .append('pattern')
                .attr('id', 'pattern-stripe')
                .attr('patternUnits', 'userSpaceOnUse')
                .attr('width', 4)
                .attr('height', 4)
                .append('rect')
                .attr('width', 3)
                .attr('height', 3)
                .attr('transform', 'translate(0,0)')
                .attr('patternTransform', 'rotate(45)')
                .attr('fill', 'white');

            defs.append('mask')
                .attr('id', 'mask-stripe')
                .append('rect')
                .attr('x', 0)
                .attr('y', 0)
                .attr('width', '100%')
                .attr('height', '100%')
                .attr('fill', 'url(#pattern-stripe)');
            svg
                .selectAll('.hbar')
                .data(distributionData)
                .enter()
                .append('rect')
                .attr('class', 'hbar')
                .attr('width', 5)
                .attr('x', (d) => { return x(d.x); })
                .attr('y', (d) => y(d.y))
                .attr('height', (d) => height - y(d.y))
                .attr('fill', (d) => {
                    if (d.x <= lowerLimit) {
                        return '#F38080';
                    } else if (d.x >= upperLimit) {
                        return '#F38080';
                    }
                    return '#AFB2B3';
                });

            let lines = ["mean", "min", "max"];
            const statisticalCount = { mean: 0, min: lowerLimit, max: upperLimit };
            svg.selectAll('.distribution-interval-line')
                .data(lines)
                .enter().append("line")
                .attr("fill", "none")
                .style('storke-width', '1')
                .style('stroke-dasharray', '4')
                .style('storke', (d) => {
                    if (d === "min") {
                        return '#F38080';
                    } else if (d === "max") {
                        return '#54DF9A';
                    }
                    return '#222';
                })
                .attr("x1", (line, index, nodes) => {
                    let x1 = parseFloat(statisticalCount[line] ? statisticalCount[line] : 0);
                    d3.select(nodes[index]).attr("class", "distribution-interval-line " + line + " ").attr('line', line)
                        .attr("value", x1);
                    x1 = x(x1);
                    return x1 >= 0 ? x1 : 0;
                })
                .attr("x2", (line) => {
                    let x2 = parseFloat(statisticalCount[line] ? statisticalCount[line] : 0);
                    x2 = x(x2);
                    return x2 >= 0 ? x2 : 0;
                })
                .attr("y1", () => { return y(-6) <= height - 20 ? y(-6) : height - 20; })
                .attr("y2", () => { return y(d3.max(distributionData, (d) => { return d[1]; })); });


            lines = ["min", "max"];

            const topClass = d3.select(".distribution-chart").append("div")
                .attr('class', 'length-container')
                .style("width", `${width}px`)
                .style("transform", `translate(${margin.left + 30}px,${(margin.top / 2) - 15}px)`)
                .append('div')
                .attr("class", "length-row")
                .style("position", 'relative');
            topClass.selectAll('.length-row')
                .data(lines)
                .enter()
                .append("div")
                .attr("class", (lines) => {
                    return `length-column ${lines}`;
                })
                .style(('left'), (lines) => {
                    const leftPosition = x(statisticalCount[lines]) - (lines === "min" ? 140 : 20);
                    return `${leftPosition}px`;
                })
                .style('top', '20px')
                .style('text-align', (lines) => {
                    return (lines === "min") ? "right" : "left";
                });
            // Bold value

            topClass.selectAll('.length-column')
                .data(lines)
                .append("b")
                .attr('class', 'interval-up-bold-text')
                .style('text-transform', 'capitalize')
                .style('font-weight', 'bold')
                .text((lines) => {
                    return (lines === "min") ? "Lower Bound" : "Upper Bound";
                });


            // Normal Text
            topClass.selectAll('.length-column')
                .data(lines)
                .append("p")
                .attr('class', 'interval-up-normal-text')
                .style('text-transform', 'capitalize')
                .text((line) => {
                    return line === "min" ? getValue(lowerLimit) : getValue(upperLimit);
                });

            // Image Text
            topClass.selectAll('.length-column')
                .data(lines)
                .append("img")
                .attr('class', 'interval-up-img-text')
                .attr('src', (line, index) => {
                    if (line === "min") {
                        return Group2136;
                    }
                    return Group2138;
                })
                .attr("width", "60")
                .attr("height", "50");

        }
    }, [chartData, theme.palette.chartColors.axis, theme.palette.chartColors.impactValid, theme.palette.chartColors.inValid, theme.palette.chartColors.valid]);

    return (
        <ChartContainer
            title="DQScore Distribution (Across Datasources)"
            id={appConstants.charts.basicCuration.id}
            chartData>
            <Grid container className={classNames(classes.chartStyles, classes.distribution, "distribution-chart")} />
        </ChartContainer>
    );
};

Distribution.propTypes = {
    classes: PropTypes.object,
    analysis: PropTypes.array,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Distribution);