const ReactQueryEditorStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    editor: {
        margin: 0,
        border: 0,
        background: "none",
        boxSizing: "inherit",
        display: "inherit",
        fontFamily: "inherit",
        fontSize: "inherit",
        fontStyle: "inherit",
        fontVariantLigatures: "inherit",
        fontWeight: "inherit",
        letterSpacing: "inherit",
        lineHeight: "inherit",
        tabSize: "inherit",
        textIndent: "inherit",
        textRendering: "inherit",
        textTransform: "inherit",
        whiteSpace: "preWrap",
        wordBreak: "keepAll",
        overflowWrap: "breakWord",
        position: "absolute",
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        resize: "none",
        color: "inherit",
        overflow: "auto",
        '-webkiFontSmoothing': "antialiased",
        padding: 10,
        scrollbarColor: `#9EAEB4 ${theme.palette.background.default}`,
        scrollbarWidth: 'thin',
        "&::-webkit-scrollbar": {
            height: '5px',
            backgroundColor: theme.palette.background.default,
            width: '6px !important'
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: '#9EAEB4',
            borderRadius: '3px'
        }
    },
    editorTab: {
        backgroundColor: theme.palette.background.paper,
        position: 'relative'
    },
    expressionTabContainer: {
        backgroundColor: theme.palette.grey.extraLight,
        alignItems: 'center'
    },
    expressionBtn: {
        fontSize: 11,
        padding: '5px 0px',
        fontWeight: 600
    },
    listItem: {
        paddingTop: 4,
        paddingBottom: 4,
        borderBottom: `1px solid ${theme.palette.grey.extraLight}`
    },
    searchContainer: {
        textAlign: 'center',
        margin: 5,
        width: "97%",
        '& .MuiFormControl-root': {
            width: "100%"
        }
    },
    error: {
        '& pre': {
            textDecoration: "underline",
            textDecorationColor: "red"
        }
    },
    containerScroll: {
        overflowY: 'auto'
    },
    editorContainer: {
        height: '90%',
        position: 'relative',
        textAlign: 'left',
        boxSizing: 'border-box',
        padding: 0,
        overflow: 'hidden',
        backgroundColor: '#fff'
    },
    modelEditor: {
        minWidth: 700
    },
    editorCode: {
        margin: 0,
        border: 0,
        background: "none",
        boxSizing: 'inherit',
        display: 'inherit',
        fontFamily: 'inherit',
        fontSize: 'inherit',
        fontStyle: 'inherit',
        fontVariantLigatures: 'inherit',
        fontWeight: 'inherit',
        letterSpacing: 'inherit',
        lineHeight: 'inherit',
        tabSize: 'inherit',
        textIndent: 'inherit',
        textRendering: 'inherit',
        textTransform: 'inherit',
        whiteSpace: 'preWrap',
        wordBreak: 'keepAll',
        overflowWrap: 'breakWord',
        position: "relative",
        pointerEvents: "none",
        padding: 10,
        minHeight: 93,
        zIndex: -5
    },
    editorSuggestion: {
        position: "absolute",
        boxShadow: "0px 0px 15px #0000000F",
        maxHeight: 150,
        minWidth: 150,
        overflowY: "auto",
        zIndex: 999,
        border: '1px solid #f2f6f7',
        background: '#fdfdfd'
    },
    editorMenu: {
        padding: "2px 8px",
        '& .MuiTypography-body1': {
            fontSize: 12
        },
        '& .MuiListItemIcon-root': {
            minWidth: 20
        }
    },
    highlightBtn: {
        fontWeight: 600,
        color: theme.palette.primary.main
    },
    previewContainer: {
        position: 'relative',
        display: 'flex',
        minHeight: 100,
        flexDirection: 'nowrap',
        '& .MuiTableCell-head': {
            // color: theme.palette.grey.dark
        },
        '& .MuiTableCell-head .MuiTypography-body1': {
            fontWeight: 600
        },
        '& .MuiTableCell-root': {
            padding: '8px 16px'
        },
        '& .MuiTableCell-body > p': {
            color: theme.palette.grey.darkTxt
        }
        /*
         * '& .MuiTableCell-stickyHeader': {
         *     backgroundColor: '#ffffff'
         * }
         */
    },
    noDataMessage: {
        margin: 0
    },
    previewTable: {
        border: `1px solid ${theme.palette.grey.extraLight} !important`,
        overflow: 'auto',
        marginTop: 10,
        marginBottom: 10,
        maxHeight: '200px',
        width: '100%'
    },
    btnValidate: {
        position: 'absolute',
        zIndex: 999,
        marginTop: 26
    }

});

export default ReactQueryEditorStyles;