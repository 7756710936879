const IconSelectorStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    iconContainer: {
        width: 50,
        height: 48,
        background: theme.palette.transparent,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0px 0px 10px #197F991F',
        margin: 5,
        // borderRadius: 8,
        cursor: 'pointer',
        position: 'relative',
        '&:hover': {
            background: theme.palette.primary.dark,
            boxShadow: '0px 0px 10px #197F991F'
        },
        '&:hover img': {
            filter: 'brightness(0) invert(1)'
        }
    },
    SelectediconContainer: {
        background: theme.palette.primary.dark,
        boxShadow: '0px 0px 10px #197F991F',
        '& img': {
            filter: 'brightness(0) invert(1)'
        }
    },
    selectedIcon: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: theme.palette.primary.main
    }
});

export default IconSelectorStyles;