import React, { useState, useEffect, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { getAllDataPreparation } from '../../actions/dataPreparationActions';
import DataPreparationList from '../../components/DataPreparation/DataPreparationList.jsx';
import DataPreparationFilter from '../../components/DataPreparationFilter/DataPreparationFilter.jsx';

const DataPreparation = (props) => {
    const { history } = props;
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [dataPrepearationList, setDataPreparationList] = useState([]);

    const getPreparation = useCallback(() => {
        setIsPageLoading(true);
        dispatch(getAllDataPreparation()).then((response) => {
            if (response && response.length > 0) {
                setDataPreparationList([...response]);
                setIsPageLoading(false);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getPreparation();
    }, [getPreparation]);

    const onChangeSearch = useCallback((value) => {
        setSearch(value);
    }, []);


    const list = search.length > 0 ? dataPrepearationList.filter((data) => data.name.toLowerCase().includes(search.toLowerCase())) : dataPrepearationList;

    return (
        <Grid>
            <DataPreparationFilter
                search={search}
                history={history}
                onChangeFilter={(value) => onChangeSearch(value)} />
            <DataPreparationList
                history={history}
                sources={list}
                isPageLoading={isPageLoading}
            />
        </Grid>
    );
};


DataPreparation.propTypes = {
    history: PropTypes.object
};
export default DataPreparation;