import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, withStyles, Typography, Slider } from '@material-ui/core';
import Styles from '../../../../layouts/Styles.jsx';
import DatasetStyles from '../../DatasetStyles.jsx';
import TileViewStyles from './TileViewStyles.jsx';
import AutoComplete from '../../../AutoComplete/AutoComplete.jsx';
import ToolTipComponent from '../../../Tooltip/Tooltip.jsx';


const AdvancedCurateRuleItem = (props) => {
    const { classes, theme, displayKey, rule, isSelected, index, options, onUpdate, onDelete, isDeletePermission, disabled, isEditPermission } = props;
    const [value, setValue] = useState('');

    const loadValue = useCallback((value) => {
        setValue(value);
    }, [setValue]);

    useEffect(() => {
        loadValue(rule.name);
    }, [rule, displayKey, loadValue]);

    return (
        <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap" className={classes.advancedRulesListItem}>
            <Grid container
                direction="row"
                justify="flex-start"
                alignItems="center"
                spacing={4}>
                <Grid item className="title">
                    {
                        !isSelected ?
                            <AutoComplete
                                placeholder="Select Rule"
                                onChange={(event) => event.target.selectedItem && onUpdate(event.target.selectedItem, index)}
                                value={value ? value : ''}
                                noDropPostion
                                disabled={Boolean(options.length === 0) && disabled && !isEditPermission}
                                options={options}
                                selectedValuePath="name"
                                displayMemberPath="name"
                                openSuggestionsOnClick
                                name="advancedrule" />
                            :
                            <Typography variant="body2" className={classes.valuesListItemText} noWrap>
                                {value ? value : ''}
                            </Typography>
                    }
                </Grid>
                <Grid item className="description">
                    <Typography variant="body2" className={classes.valuesListItemText} noWrap>
                        {rule.description ? rule.description : ''}
                    </Typography>
                </Grid>
                <Grid item className="threshold">
                    <Grid container direction="row" justify="center" alignItems="center" wrap="nowrap">
                        <Slider name="sensitivity"
                            color="secondary"
                            className={classes.SensitivityMuiSlider}
                            style={{ width: '60%' }}
                            defaultValue={0.5}
                            track={'normal'}
                            value={rule.threshold ? rule.threshold : 0.5}
                            marks
                            min={0}
                            max={1}
                            step={0.1}
                            onChange={(_event, value) => { onUpdate({ ...rule, threshold: value }, index); _event.stopPropagation(); }}
                            disabled={disabled && !isEditPermission}
                        />
                        <Typography variant="body2" className={classes.sliderValue}>
                            {rule.threshold ? rule.threshold : '0.5'}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item className="actions">
                    {
                        (isDeletePermission || !disabled) &&
                        <ToolTipComponent title="Delete" arrow>
                            <IconButton style={{ alignSelf: 'center' }} onClick={() => onDelete(index)} className={classes.padding5}>
                                <svg version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="18"
                                    height="18"
                                    viewBox="0 0 32 32">
                                    <g id="Delete">
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
		L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
		L22.3,25.8z" />
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
		c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
		c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                    </g>
                                </svg>
                            </IconButton>
                        </ToolTipComponent>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};


AdvancedCurateRuleItem.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    displayKey: PropTypes.string,
    rule: PropTypes.object,
    index: PropTypes.number,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    isSelected: PropTypes.bool,
    options: PropTypes.array,
    isDeletePermission: PropTypes.bool,
    isEditPermission: PropTypes.bool,
    disabled: PropTypes.bool
};


export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(AdvancedCurateRuleItem);