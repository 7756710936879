import React from 'react';
import { Grid, withStyles, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import CommentStyles from './CommentStyles.jsx';
import Rating from '../Rating/Rating.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import classNames from 'classnames';

const AddCommentBox = (props) => {
    const { classes, comment, onChange, postComment } = props;
    return (
        <Grid className={classes.addCommentContainer}>
            <Grid container className={classes.marginBottom5}>
                <Grid item>
                    <Typography className={classes.commentRateTitle}>
                        {'Rate and Comment'}
                    </Typography>
                </Grid>
                <Grid item>
                    <Rating
                        name="rating"
                        value={comment.rating ? comment.rating : 0}
                        precision={0.5}
                        size="large"
                        onChange={(event, value) => onChange("rating", value)} />
                </Grid>
            </Grid>
            <TextBox
                className={classNames(classes.inputOutline, classes.commentInput)}
                name="comment"
                value={comment && comment.comment ? comment.comment : ''}
                placeholder="Comment on this discussion"
                onChange={(event) => onChange(event.target.name, event.target.value)}
                multiline
                rows="2"
            />
            {
                (comment.comment !== "" || comment.rating !== 0) &&
                <Button variant="contained" color="primary" className={classes.marginTop10} onClick={() => postComment()}>
                    Post Comment
                </Button>
            }
        </Grid>
    );
};

AddCommentBox.propTypes = {
    classes: PropTypes.object,
    comment: PropTypes.object,
    onChange: PropTypes.func,
    postComment: PropTypes.func
};


export default withStyles((theme) => ({
    ...CommentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(AddCommentBox);