import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Button, InputAdornment, IconButton } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextBox from '../../TextBox/TextBox.jsx';
import Styles from '../../../layouts/Styles.jsx';
import Loader from '../../Loaders/Loader.jsx';
import { Visibility, VisibilityOffOutlined } from '@material-ui/icons';

const DBFS = (props) => {
    const { classes, onSave, onChange, storage, type, isLoading } = props;
    const [showToken, setShowToken] = useState(false);

    return (
        <ValidatorForm onSubmit={() => onSave(type)}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextBox
                        label="Base Url"
                        name="base_url"
                        value={storage.base_url ? storage.base_url : ""}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                        validators={['required']}
                        errorMessages={['Base url is required']}
                    />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4}>
                    <TextBox
                        label="Token"
                        name="auth_token"
                        type={showToken ? 'text' : 'password'}
                        value={storage.auth_token ? storage.auth_token : ""}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                        validators={['required']}
                        errorMessages={['Token is required']}
                        InputProps={
                            {
                                endAdornment:
                                    <InputAdornment position="end" >
                                        <IconButton className={classes.passwordAdornment} onClick={() => setShowToken(!showToken)}>
                                            {showToken ? <Visibility /> : <VisibilityOffOutlined />}
                                        </IconButton>
                                    </InputAdornment>
                            }
                        }
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextBox
                        label="Path"
                        name="basepath"
                        value={storage.basepath ? storage.basepath : ""}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                        validators={['required']}
                        errorMessages={['Path is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classes.actionButtons}>
                        {'Connect'}
                        {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                    </Button>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
};

DBFS.propTypes = {
    storage: PropTypes.object,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    classes: PropTypes.object,
    type: PropTypes.string,
    isLoading: PropTypes.bool
};


export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(DBFS);