import React, { Fragment } from 'react';
import { Grid, Button, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import PatternRule from './PatternRule.jsx';
import { isPatternRuleGroup } from './PatternQueryBuilderUtil.jsx';
import CombinatorSelector from './PatternCombinatorSelector.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const PatternRuleGroup = (props) => {
    const { id, parentId, rules, connector, not, builderConfig, className, theme } = props;
    const { classes, onAddRule, onAddRuleGroup, onGroupRemove, onPropertyChange } = builderConfig;
    const hasParentGroup = () => Boolean(parentId);
    const onCombinatorChange = (value) => {
        onPropertyChange('connector', value, id);
    };

    const onNotToggleChange = (checked) => {
        onPropertyChange('not', checked, id);
    };

    const addRule = () => {
        onAddRule(id);
    };

    const addGroup = () => {
        onAddRuleGroup(id);
    };

    const removeGroup = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onGroupRemove(id, parentId);
    };

    return (
        <Grid container className={classNames(classes.group, className)}>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap" className={classes.groupHeader}>
                <Grid item>
                    <CombinatorSelector
                        selectedConnector={connector}
                        not={not}
                        disabled={rules.length <= 1}
                        onNotToggleChange={() => onNotToggleChange(!not)}
                        onChange={(value) => onCombinatorChange(value)}
                    />
                </Grid>
                <Grid item>
                    <Button className={classes.btnAction} onClick={addRule}>
                        {'Add Rule'}
                    </Button>
                    <Button className={classes.btnAction} onClick={addGroup} style={{ marginRight: 10 }}>
                        {'Add Group'}
                    </Button>
                    {
                        hasParentGroup() &&
                        <ToolTipComponent title="Delete">
                            <IconButton className={classes.actionIconButton} onClick={(event) => removeGroup(event)}>
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 32 32">
                                    <g id="Delete">
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                    </g>
                                </svg>
                            </IconButton>
                        </ToolTipComponent>
                    }
                </Grid>
            </Grid>
            <Grid className={classes.groupChildren}>
                {
                    rules.map((rule, index) => {
                        return (
                            <Fragment key={index}>
                                {
                                    isPatternRuleGroup(rule)
                                        ? <PatternRuleGroup id={rule.id}
                                            parentId={id}
                                            rules={[...rule.rules]}
                                            connector={rule.connector}
                                            not={rule.not}
                                            builderConfig={builderConfig}
                                            theme={theme} />
                                        : <PatternRule id={rule.id}
                                            parentId={id}
                                            rule={rule}
                                            builderConfig={builderConfig}
                                            theme={theme} />
                                }
                            </Fragment>
                        );
                    })
                }
            </Grid>
        </Grid >
    );
};

PatternRuleGroup.propTypes = {
    classes: PropTypes.object,
    rules: PropTypes.array,
    id: PropTypes.string,
    parentId: PropTypes.string,
    connector: PropTypes.string,
    not: PropTypes.bool,
    className: PropTypes.string,
    builderConfig: PropTypes.object,
    theme: PropTypes.object
};

export default PatternRuleGroup;