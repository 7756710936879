import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ValidatorForm } from "react-material-ui-form-validator";
import { Grid, Typography, withStyles, IconButton, ClickAwayListener, Slider, FormLabel, MenuItem, TextField } from '@material-ui/core';
import classNames from 'classnames';

import { sortTable } from '../../helpers/appHelpers.js';
import Styles from '../../layouts/Styles.jsx';
import SearchStyles from './SearchStyles.jsx';
import DatasourceListStyles from '../../components/DatasourceList/DatasourceListStyles.jsx';

import { appConstants } from '../../constants/appConstants';
import TextBox from '../../components/TextBox/TextBox.jsx';
import CheckboxComponent from '../../components/ChecboxComponent/CheckboxComponent.jsx';
import UserAutoComplete from '../../components/UserAutoComplete/UserAutoComplete.jsx';
import ToolTipComponent from '../../components/Tooltip/Tooltip.jsx';
import AutoCompleteInput from '../../components/AutoComplete/AutoCompleteInput.jsx';
import { getAllTags } from '../../actions/searchActions';
import Verified from '../../assets/images/verified.svg';
import InProgess from '../../assets/images/wip.svg';
import Deprecated from '../../assets/images/deprecated.svg';
import Issues from '../../assets/images/issues.svg';

const SearchFilter = (props) => {
	const { classes, theme, enableFilter, searchFilters, setFilter, updateFilter, isRemovedUserChange, isUserChange } = props;
	const settingConfig = useSelector(({ setting }) => setting.config);
	let datasourceTypesList = useSelector(({ datasource }) => datasource.connectionTypes);
	datasourceTypesList = datasourceTypesList.filter((data) => settingConfig.input_connectors.includes(data.id));
	const users = useSelector(({ account }) => account.users);

	const [userInput, setUserInput] = useState('');
	const [filters, setSearchFilters] = useState({
		primary_key: false,
		status: ''
	});
	const [tagList, setTagList] = useState([]);
	const [selectedTag, setSelectedTag] = useState([]);
	const dispatch = useDispatch();


	const [usersList, setUsersList] = useState([
		{
			isDefault: true,
			isSelected: true,
			isUser: false,
			name: 'All'
		}
	]);

	/**
	 *
	 */
	useEffect(() => {
		setSearchFilters(searchFilters);
	}, [searchFilters]);

	useEffect(() => {
		setSelectedTag(searchFilters.tags);
	}, [searchFilters.tags]);

	useEffect(() => {
		dispatch(getAllTags()).then((response) => {
			setTagList([...response]);
		});
	}, [dispatch]);

	useEffect(() => {
		if (usersList.length === 1 && users.length > 0) {
			const organizationUsers = users.map((user) => {
				return {
					...user,
					isSelected: false,
					name: user.first_name !== "" ? `${user.first_name} ${user.last_name}` : user.email,
					isDefault: false,
					isUser: true
				};
			});
			organizationUsers.unshift({
				isDefault: true,
				isSelected: true,
				isUser: false,
				name: 'All'
			});
			setUsersList([...sortTable(organizationUsers, "asc", "username")]);

		}
	}, [users, usersList]);

	/**
	 *
	 * @param {*} value
	 * @returns
	 */
	const getThresholdText = (value) => {
		return value ? `${value}%` : '0%';
	};

	/**
	 *
	 */
	const resetFilter = useCallback((index) => {
		const reset_filters = {
			connectionType: [],
			sensitivity: [],
			users: [
				{
					isDefault: true,
					isSelected: true,
					isUser: false,
					name: 'All'
				}
			],
			dqScore: [0, 100],
			favourite: false,
			certified: false,
			tags: [],
			status: '',
			primary_key: false
		};
		for (const user of usersList) {
			user.isSelected = user.isDefault;
		}
		setUsersList([...sortTable(usersList, "asc", "username")]);
		setUserInput('');
		setSelectedTag([]);
		setSearchFilters(reset_filters);
		updateFilter({ ...reset_filters, offset: 0 });

	}, [usersList, updateFilter]);

	/**
	 *
	 */
	const onSliderChange = useCallback((property, value) => {
		setSearchFilters({ ...filters, [property]: value });
	}, [setSearchFilters, filters]);

	/**
	 *
	 */
	const onChangeFilter = useCallback((property, value) => {
		const filter_object = { ...filters };
		const defaultUsers = [
			{
				isDefault: true,
				isSelected: true,
				isUser: false,
				name: 'All'
			}
		];
		if (property === "users") {
			if (value === 'All') {
				const defaultUser = filter_object[property].find((p) => p.name === "All");
				if (defaultUser) {
					return false;
				}
				filter_object[property] = defaultUsers;
			} else {
				const user = usersList.find((p) => p.name === value);
				const index = filter_object.users.findIndex((p) => p.id === user.id);
				if (index > -1) {
					isRemovedUserChange({ "user": user.email });
					filter_object.users.splice(index, 1);
				} else {
					isUserChange({ "user": user.email });
					filter_object.users.push({ ...user });
				}

				if (filter_object.users.length === 0) {
					filter_object.users = defaultUsers;
				} else {
					const defaultUserIndex = filters.users.findIndex((p) => p.name === 'All');
					if (defaultUserIndex > -1) {
						filter_object.users.splice(defaultUserIndex, 1);
					}
				}
				const userItem = usersList.find((p) => p.name === value);
				const userItemIndex = usersList.findIndex((p) => p.id === user.id);
				userItem.isSelected = !userItem.isSelected;
				usersList.splice(userItemIndex, 1, { ...userItem });
				setUsersList(sortTable(usersList, "asc", "username"));
			}
		} else if (property === "sensitivity") {
			const index = filter_object[property].indexOf(value);
			if (index !== -1) {
				filter_object[property].splice(index, 1);
			} else {
				filter_object[property].push(value);
			}
		} else if (property === "tags" || property === "primary_key" || (property === "status" && filter_object[property] !== '')) {
			filter_object[property] = value;
		} else {
			if (property === "favourite" || property === "certified") {
				filter_object.users = defaultUsers;
			}
			filter_object[property] = value;
		}
		setUserInput('');
		setSearchFilters({ ...filter_object });
		updateFilter({ ...filter_object, offset: 0 });
	}, [filters, updateFilter, usersList, isRemovedUserChange, isUserChange]);

	/**
	 *
	 * @param {*} event
	 */
	const onChangeUser = (event) => {
		onChangeFilter("users", event);
	};

	/**
	 *
	 * @param {*} value
	 */
	const onInputValueOnChange = (value) => {
		setUserInput(value);
	};

	/**
	 *
	 */
	const removeItem = useCallback((index) => {
		usersList.splice(index, 1);
		setUsersList([...sortTable(usersList, "asc", "username")]);
		setTimeout(() => {
			setUserInput('');
		}, 100);
	}, [usersList]);

	const updateTagFilter = (tag, value) => {
		setSelectedTag(value);
		onChangeFilter('tags', value);
	};

	const renderStatusIcon = (status) => {
        if (status.toLowerCase() === "verified") {
            return Verified;
        } else if (status.toLowerCase() === "work in progress") {
            return InProgess;
        } else if (status.toLowerCase() === "issues") {
            return Issues;
        } else if (status.toLowerCase() === "deprecated") {
			return Deprecated;
        }
		return '';
    };

	return (
		<Grid item className={classes.filterSection}>
			{
				<ValidatorForm onSubmit={() => null} style={{ display: 'flex', alignItems: 'center' }}>
					{
						enableFilter &&
						<Grid className={classNames(classes.FilterHideShowContainer, classes.filterPosition)}>
							<ClickAwayListener onClickAway={() => setFilter(false)} mouseEvent={'onMouseUp'} touchEvent={'onTouchEnd'}>
								<Grid container alignItems="center" justify="space-between" className={classNames(classes.datasourceFilterSection, "datasourceFilterSection")}>
									<ToolTipComponent title="Close" placement="bottom" arrow>
										<IconButton onClick={() => setFilter(!enableFilter)} className={classes.marginRight12}>
											<svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
												<path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
												<path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
											</svg>
										</IconButton>
									</ToolTipComponent>
									<Grid item className={classes.marginRight25}>
										<IconButton onClick={() => onChangeFilter("favourite", !filters.favourite)}>
											<ToolTipComponent title="Favourites" placement="bottom" arrow>
												{
													filters.favourite ?
														<svg version="1.1"
															id="Layer_1"
															xmlns="http://www.w3.org/2000/svg"
															x="0px"
															y="0px"
															width="19"
															height="19"
															viewBox="0 0 32 32">
															<g id="heart_2_" transform="translate(0 -16.896)">
																<g id="Group_1546" transform="translate(0 16.896)">
																	<g id="Path_249">
																		<path fill={theme.palette.primary.main}
																			d="M25.6,7.3c-2.4-2.5-6.3-2.7-8.9-0.3c-0.1,0.1-0.2,0.2-0.3,0.3L16,7.8l-0.4-0.4C13.2,4.8,9.3,4.6,6.7,7
	c0,0,0,0,0,0C6.6,7.1,6.5,7.2,6.4,7.3c-2.5,2.7-2.5,6.9,0,9.6l9,9.5c0.3,0.3,0.8,0.3,1.2,0c0,0,0,0,0,0l0,0l9-9.5
	C28.1,14.2,28.1,10,25.6,7.3z M24.4,15.8L24.4,15.8L16,24.7l-8.4-8.9c-1.9-2.1-1.9-5.3,0-7.3c1.7-1.9,4.7-2,6.6-0.3c0,0,0,0,0,0
	c0.1,0.1,0.2,0.2,0.3,0.3l1,1.1c0.3,0.3,0.9,0.3,1.2,0l1-1.1c1.7-1.9,4.7-2,6.6-0.3c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3
	C26.4,10.5,26.4,13.7,24.4,15.8L24.4,15.8z" />
																		<path fill={theme.palette.primary.main}
																			d="M16,26.9C16,26.9,16,26.9,16,26.9c-0.3,0-0.6-0.1-0.8-0.3l-9-9.5c-2.6-2.8-2.6-7.1,0-9.9
	c0.1-0.1,0.2-0.2,0.3-0.3c2.6-2.4,6.8-2.3,9.2,0.3L16,7.4l0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c1.3-1.2,2.9-1.8,4.7-1.7
	c1.7,0.1,3.3,0.8,4.5,2.1l0,0c2.6,2.8,2.6,7.1,0,9.9l-9.1,9.6C16.5,26.8,16.3,26.9,16,26.9z M11,5.5c-1.5,0-3,0.5-4.1,1.6
	C6.8,7.3,6.6,7.4,6.5,7.5c-2.4,2.6-2.4,6.7,0,9.3l9,9.5c0.2,0.2,0.6,0.2,0.8,0l9-9.5c2.4-2.6,2.4-6.7,0-9.3
	c-1.1-1.2-2.6-1.9-4.2-1.9c-1.6-0.1-3.2,0.5-4.3,1.6c-0.1,0.1-0.2,0.2-0.3,0.3L16,8.1l-0.6-0.6C14.2,6.2,12.6,5.5,11,5.5z M16,25
	l-8.6-9.1c-2-2.1-2-5.5,0-7.6c0.9-1,2.1-1.5,3.4-1.6c1.3-0.1,2.5,0.4,3.5,1.3c0.1,0.1,0.2,0.2,0.3,0.3l1,1.1
	c0.2,0.2,0.6,0.2,0.9,0l1-1.1c0.9-1,2.1-1.5,3.4-1.6c1.3-0.1,2.5,0.4,3.5,1.3c0.1,0.1,0.2,0.2,0.3,0.3c1.9,2,2,5.1,0.3,7.3h0
	l-0.4,0.4L16,25z M11,7.2c-0.1,0-0.1,0-0.2,0c-1.2,0-2.3,0.6-3.1,1.4c-1.8,2-1.8,5.1,0,7l8.3,8.7l8.3-8.7c1.8-2,1.8-5.1,0-7
	c-0.1-0.1-0.2-0.2-0.3-0.3c-0.9-0.8-2-1.2-3.2-1.2c-1.2,0-2.3,0.6-3.1,1.4l-1,1.1c-0.4,0.4-1.1,0.4-1.5,0l-1-1.1
	c-0.1-0.1-0.2-0.2-0.3-0.3C13.2,7.6,12.1,7.2,11,7.2z" />
																		<polygon fill={theme.palette.primary.main}
																			points="16,25.9 10.1,19.7 6.4,15.5 5.2,12.9 5.2,10.4 6.7,7.9 9.2,6.4 11.3,6.4 12.6,6.7 13.8,7 15.5,8.3
	16.4,8.6 17.8,7 20.3,6.1 22.8,6.1 24.6,7.4 25.9,9.4 26.7,11.3 26.7,13.2 25.6,15.8 18.8,23.1 " />
																	</g>
																</g>
															</g>
														</svg>
														:
														<svg version="1.1"
															id="Layer_1"
															xmlns="http://www.w3.org/2000/svg"
															x="0px"
															y="0px"
															width="19"
															height="19"
															viewBox="0 0 32 32">
															<g id="heart_2_" transform="translate(0 -16.896)">
																<g id="Group_1546" transform="translate(0 16.896)">
																	<g id="Path_249">
																		<path fill="#AFB2B3"
																			d="M25.6,7.3c-2.4-2.5-6.3-2.7-8.9-0.3c-0.1,0.1-0.2,0.2-0.3,0.3L16,7.8l-0.4-0.4C13.2,4.8,9.3,4.6,6.7,7
	c0,0,0,0,0,0C6.6,7.1,6.5,7.2,6.4,7.3c-2.5,2.7-2.5,6.9,0,9.6l9,9.5c0.3,0.3,0.8,0.3,1.2,0c0,0,0,0,0,0l0,0l9-9.5
	C28.1,14.2,28.1,10,25.6,7.3z M24.4,15.8L24.4,15.8L16,24.7l-8.4-8.9c-1.9-2.1-1.9-5.3,0-7.3c1.7-1.9,4.7-2,6.6-0.3c0,0,0,0,0,0
	c0.1,0.1,0.2,0.2,0.3,0.3l1,1.1c0.3,0.3,0.9,0.3,1.2,0l1-1.1c1.7-1.9,4.7-2,6.6-0.3c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3
	C26.4,10.5,26.4,13.7,24.4,15.8L24.4,15.8z" />
																		<path fill="#AFB2B3"
																			d="M16,26.9C16,26.9,16,26.9,16,26.9c-0.3,0-0.6-0.1-0.8-0.3l-9-9.5c-2.6-2.8-2.6-7.1,0-9.9
	c0.1-0.1,0.2-0.2,0.3-0.3c2.6-2.4,6.8-2.3,9.2,0.3L16,7.4l0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c1.3-1.2,2.9-1.8,4.7-1.7
	c1.7,0.1,3.3,0.8,4.5,2.1l0,0c2.6,2.8,2.6,7.1,0,9.9l-9.1,9.6C16.5,26.8,16.3,26.9,16,26.9z M11,5.5c-1.5,0-3,0.5-4.1,1.6
	C6.8,7.3,6.6,7.4,6.5,7.5c-2.4,2.6-2.4,6.7,0,9.3l9,9.5c0.2,0.2,0.6,0.2,0.8,0l9-9.5c2.4-2.6,2.4-6.7,0-9.3
	c-1.1-1.2-2.6-1.9-4.2-1.9c-1.6-0.1-3.2,0.5-4.3,1.6c-0.1,0.1-0.2,0.2-0.3,0.3L16,8.1l-0.6-0.6C14.2,6.2,12.6,5.5,11,5.5z M16,25
	l-8.6-9.1c-2-2.1-2-5.5,0-7.6c0.9-1,2.1-1.5,3.4-1.6c1.3-0.1,2.5,0.4,3.5,1.3c0.1,0.1,0.2,0.2,0.3,0.3l1,1.1
	c0.2,0.2,0.6,0.2,0.9,0l1-1.1c0.9-1,2.1-1.5,3.4-1.6c1.3-0.1,2.5,0.4,3.5,1.3c0.1,0.1,0.2,0.2,0.3,0.3c1.9,2,2,5.1,0.3,7.3h0
	l-0.4,0.4L16,25z M11,7.2c-0.1,0-0.1,0-0.2,0c-1.2,0-2.3,0.6-3.1,1.4c-1.8,2-1.8,5.1,0,7l8.3,8.7l8.3-8.7c1.8-2,1.8-5.1,0-7
	c-0.1-0.1-0.2-0.2-0.3-0.3c-0.9-0.8-2-1.2-3.2-1.2c-1.2,0-2.3,0.6-3.1,1.4l-1,1.1c-0.4,0.4-1.1,0.4-1.5,0l-1-1.1
	c-0.1-0.1-0.2-0.2-0.3-0.3C13.2,7.6,12.1,7.2,11,7.2z" />
																	</g>
																</g>
															</g>
														</svg>
												}
											</ToolTipComponent>
										</IconButton>
									</Grid>
									<Grid item className={classes.marginRight25}>
										<IconButton onClick={() => onChangeFilter("certified", !filters.certified)}>
											<ToolTipComponent title="Certified" placement="bottom" arrow>
												<svg id="verified" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
													<rect id="Rectangle_1768" data-name="Rectangle 1768" width="20" height="20" fill="none" />
													<path id="Path_2546" data-name="Path 2546" d="M18.667,4.65,9.158,14.167,5.625,10.633,6.8,9.458l2.358,2.358,8.333-8.333Zm-1.842,4.2a6.662,6.662,0,1,1-6.492-5.183A6.6,6.6,0,0,1,13.9,4.708l1.2-1.2A8.25,8.25,0,0,0,10.333,2a8.355,8.355,0,1,0,7.833,5.508Z" transform="translate(-0.333 -0.333)" fill={filters.certified ? "#7EE19A" : "#B5B6B8"} />
												</svg>
											</ToolTipComponent>
										</IconButton>
									</Grid>
									{
										filters.viewType === 'Attributes' &&
										<Grid item className={classes.marginRight25}>
											<IconButton onClick={() => onChangeFilter("primary_key", !filters.primary_key)}>
												<ToolTipComponent title="Certified" placement="bottom" arrow>
													<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 16 17.044">
														<defs />
														<g transform="translate(-13.899)">
														<path fill={filters.primary_key ? theme.palette.primary.main : "#e1e5e6"} d="M21.078,12.848,19.87,11.639l4.158-4.159A3.988,3.988,0,1,0,22.418,5.87l-8.187,8.188a1.139,1.139,0,0,0,1.611,1.611l1.208,1.208a.569.569,0,0,0,.806,0l.806-.805a.569.569,0,0,0,0-.805l-1.208-1.208.805-.808,1.209,1.211a.569.569,0,0,0,.806,0l.805-.805A.572.572,0,0,0,21.078,12.848ZM24.7,2.779a1.71,1.71,0,1,1,0,2.416A1.711,1.711,0,0,1,24.7,2.779Z" transform="translate(0)" />
														</g>
													</svg>
												</ToolTipComponent>
											</IconButton>
										</Grid>
									}
									{
										filters.viewType === appConstants.filterTypes[2] &&
										<Grid item className={classes.marginRight25}>
											<Typography className={classes.filterdqscoreText}>
												Sensitivity Level
											</Typography>
											<Grid className={classes.sensitivitySelect}>
												<Typography onClick={() => onChangeFilter("sensitivity", 1)} component="div" className={filters.sensitivity.includes(1) ? 'active' : ''}>
													1
												</Typography>
												<Typography onClick={() => onChangeFilter("sensitivity", 2)} component="div" className={filters.sensitivity.includes(2) ? 'active' : ''}>
													2
												</Typography>
												<Typography onClick={() => onChangeFilter("sensitivity", 3)} component="div" className={filters.sensitivity.includes(3) ? 'active' : ''}>
													3
												</Typography>
											</Grid>
										</Grid>
									}
									<Grid item className={classes.marginRight25}>
										<Typography className={classNames(classes.filterdqscoreText)} style={{ width: '115px' }}>
											{`DQScore (${filters.dqScore[0]} - ${filters.dqScore[1]})`}
										</Typography>
										<Slider
											defaultValue={0}
											getAriaValueText={(value) => getThresholdText(value)}
											valueLabelDisplay="auto"
											value={filters.dqScore ? filters.dqScore : 0}
											onChange={(event, value) => onSliderChange("dqScore", value)}
											onChangeCommitted={(event, value) => onChangeFilter("dqScore", value)}
											step={1}
											min={0}
											max={100}
											color="secondary"
											className={classNames(classes.slider, classes.filterSlider, classes.SensitivityMuiSlider)}
										/>
									</Grid>
									{
										(
											filters.viewType === 'Attributes' ||
											filters.viewType === 'Datasets' ||
											filters.viewType === 'Datasources' ||
											filters.viewType === 'Worksheets' ||
											filters.viewType === 'Workbooks'
										) &&
										<Grid item className={classNames(classes.marginRight25, classes.statusSelect, classes.select, classes.filterselect)}>
											<FormLabel>
												Status
											</FormLabel>
											<TextBox
												name="status"
												select
												onChange={(event, value) => { onChangeFilter("status", event.target.value); }}
												value={filters.status}
												className={classes.glossarySelect}
												SelectProps={
													{
														MenuProps: {
															anchorOrigin: {
																vertical: "bottom",
																horizontal: "center"
															},
															transformOrigin: {
																vertical: "top",
																horizontal: "center"
															},
															getContentAnchorEl: null
														}
														// IconComponent: () => DropDownIcon()
													}
												}

											>
												{
													[{ name: "", value: "" }, ...appConstants.glossaryStatus].map((status, index) =>
														<MenuItem key={index} className={classes.menuItem} value={status.value}>
															<img src={renderStatusIcon(status.value)} alt="" className={classes.marginRight5} />
															<Typography>
																{status.name}
															</Typography>
														</MenuItem>
													)
												}
											</TextBox>
										</Grid>
									}
									<Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
										<FormLabel>
											Source Type
										</FormLabel>
										<TextBox
											// label="Source Type"
											name="connectionType"
											placeholder="select"
											value={filters.connectionType ? filters.connectionType : []}
											select
											onChange={(event, value) => { onChangeFilter("connectionType", event.target.value); }}
											SelectProps={
												{
													multiple: true,
													value: filters.connectionType ? filters.connectionType : [],
													onChange: (event) => onChangeFilter("connectionType", event.target.value),
													renderValue: (selected) => selected.join(', '),
													MenuProps: {
														anchorOrigin: {
															vertical: "bottom",
															horizontal: "center"
														},
														transformOrigin: {
															vertical: "top",
															horizontal: "center"
														},
														getContentAnchorEl: null
													}
													// IconComponent: () => DropDownIcon()
												}
											}
											fullWidth
										>
											{
												sortTable(datasourceTypesList, "asc", "name").map((connection, index) =>
													<MenuItem key={index} value={connection.type}>
														<CheckboxComponent checked={filters.connectionType.includes(connection.type)} name={connection.name} checkboxLabel={connection.name} />
													</MenuItem>
												)
											}
										</TextBox>
									</Grid>
									<Grid item className={classes.userSelectContainer}>
										<FormLabel>
											Users
										</FormLabel>
										<UserAutoComplete
											availableList={sortTable(usersList, "asc", "name")}
											selectedList={filters.users ? filters.users : []}
											displayValue="name"
											onChange={onChangeUser}
											onInputValueChange={onInputValueOnChange}
											inputValue={userInput}
											onRemoveItem={removeItem}
											disabled={Boolean(filters.favourite)}
										/>
									</Grid>
									<Grid item className={classNames(classes.userSelectContainer, classes.tagFilter)}>
										<AutoCompleteInput
											name="tags"
											popperWidth={300}
											options={tagList}
											freeSolo
											value={selectedTag ? selectedTag : []}
											getOptionLabel={
												(option) => {
													if (option && option.name) {
														return option.name;
													}
													return option;
												}
											}
											renderInput={
												(params) =>
													<TextField {...params}
														placeholder="Select Tags"
														margin="normal"
													/>
											}
											tab="drift"
											onChange={(_, selectedValue) => updateTagFilter("selected_tag", selectedValue)}
											openOnFocus
											disableCloseOnSelect
											disableClearable
											fullWidth
											multiple
											forcePopupIcon={false}
											noOptionsText={"No Tags Found"}
											noIcon
										/>
									</Grid>
									<Grid item>
										<ToolTipComponent title="Clear Filter" placement="bottom" arrow>
											<IconButton onClick={() => resetFilter()}>
												<svg xmlns="http://www.w3.org/2000/svg" width="20" height="22.795" viewBox="0 0 20 22.795">
													<g id="filter_10_" data-name="filter (10)" transform="translate(-31.388)">
														<path id="Path_1437" data-name="Path 1437" d="M46.071,0a5.326,5.326,0,0,0-5.1,3.8h-8.59a1,1,0,0,0-.814,1.564l5.776,7.882a1.046,1.046,0,0,1,.185.6V21.75a1.044,1.044,0,0,0,1.668.835l2.564-1.937a1.527,1.527,0,0,0,.612-1.22V13.84a1.047,1.047,0,0,1,.185-.6l2.07-2.825A5.31,5.31,0,1,0,46.071,0ZM41.477,12.462a2.375,2.375,0,0,0-.438,1.377c0,6.076.033,5.658-.075,5.737l-2.1,1.586V13.84a2.376,2.376,0,0,0-.428-1.364l-5.38-7.341h7.7A5.307,5.307,0,0,0,43.369,9.88Zm4.594-3.18a3.973,3.973,0,1,1,3.982-3.973A3.982,3.982,0,0,1,46.071,9.282Z" transform="translate(0)" fill="#f38080" />
														<path id="Path_1438" data-name="Path 1438" d="M321.45,76.089a.668.668,0,0,0-.944,0l-.793.791-.793-.791a.668.668,0,0,0-.944.945l.791.79-.791.79a.668.668,0,0,0,.944.945l.793-.791.793.791a.668.668,0,0,0,.944-.945l-.791-.79.791-.79A.668.668,0,0,0,321.45,76.089Z" transform="translate(-273.642 -72.515)" fill="#f38080" />
													</g>
												</svg>
											</IconButton>
										</ToolTipComponent>
									</Grid>
								</Grid>
							</ClickAwayListener>
						</Grid>
					}

				</ValidatorForm>
			}
		</Grid >
	);
};

SearchFilter.propTypes = {
	classes: PropTypes.object,
	theme: PropTypes.object,
	enableFilter: PropTypes.bool,
	searchFilters: PropTypes.object,
	setFilter: PropTypes.func,
	updateFilter: PropTypes.func,
	isRemovedUserChange: PropTypes.func,
	isUserChange: PropTypes.func
};

export default withStyles((theme) => ({
	...SearchStyles(theme),
	...DatasourceListStyles(theme),
	...Styles(theme)
}), { withTheme: true })(SearchFilter);