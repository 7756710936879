import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import { alertActions } from '../constants/actionTypes/alertActionTypes';
import { datasourceActions } from '../constants/actionTypes/datasourceActionTypes';
import { datasetActions } from '../constants/actionTypes/datasetActionTypes';
import * as service from '../services/apiService';
import FileSaver from 'file-saver';
import { alerts } from '../config/alerts';
import { disableRolePermission, logout } from '../actions/accountActions';
import { resetConfig, getUpdateLicenseConnectionStatus, getOrganizationConfig, getLicenseModules } from '../actions/settingActions';
import { getCancellationToken, getUserProperty } from '../helpers/appHelpers';

export const getDataSource = (sourceId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDataSourceByID}/${sourceId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_DATASOURCE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_DATASOURCE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_DATASOURCE_FAILURE, error));
        });
    };
};

export const updateDataSource = (sourceId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.updateSource}/${sourceId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.UPDATE_DATASOURCE_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasourceActions.UPDATE_DATASOURCE_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(datasourceActions.UPDATE_DATASOURCE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
        });
    };
};

export const getSourceAuditLogs = (sourceId, datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getAuditLog}/${sourceId}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_AUDIT_LOGS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_AUDIT_LOGS_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_AUDIT_LOGS_FAILURE, error));
        });
    };
};

export const getSourceFavorites = (sourceId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getAuditLog}/${sourceId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_AUDIT_LOGS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_AUDIT_LOGS_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_AUDIT_LOGS_FAILURE, error));
        });
    };
};

export const createDataSource = (requestParams) => {
    return (dispatch) => {
        dispatch(request(datasourceActions.CREATE_DATASOURCE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.catalog.createSource, requestParams).then((response) => {
            dispatch(success(datasourceActions.CREATE_DATASOURCE_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.CREATE_DATASOURCE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
            throw error;
        });
    };
};

export const deleteDatasource = (sourceId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.deleteSource}/${sourceId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.DELETE_DATASOURCE_REQUEST));
        return service.deleteRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.DELETE_DATASOURCE_SUCCESS, response));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.deleteDatasource));
            return response.success;
        }, (error) => {
            dispatch(failure(datasourceActions.DELETE_DATASOURCE_FAILURE, error));
        });
    };
};

export const getDatasets = (sourceId, key, start, end, cacellationToken = null) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getdatasets}/${sourceId}/${key}/${start}/${end}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_DATASET_REQUEST));
        return service.getRequest(endpoint, cacellationToken).then((response) => {
            dispatch(success(datasourceActions.GET_DATASET_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_DATASET_FAILURE, error));
        });
    };
};

export const getDatasetAttributes = (datasetId, start, end) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getattributes}/${datasetId}/${start}/${end}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_ATTRIBUTES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_ATTRIBUTES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_ATTRIBUTES_FAILURE, error));
        });
    };
};

export const createAuditLog = (requestParams) => {
    return (dispatch) => {
        dispatch(request(datasourceActions.CREATE_AUDITLOG_REQUEST));
        return service.postRequest(appConstants.END_POINTS.catalog.createAuditLog, requestParams).then((response) => {
            dispatch(success(datasourceActions.CREATE_AUDITLOG_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.CREATE_AUDITLOG_FAILURE, error));
        });
    };
};

export const createSourceShare = (requestParams) => {
    return (dispatch) => {
        dispatch(request(datasourceActions.CREATE_DATASOURCE_SHARE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.catalog.sourceShare, requestParams).then((response) => {
            dispatch(success(datasourceActions.CREATE_DATASOURCE_SHARE_SUCCESS, response.data));
            return response.success;
        }, (error) => {
            dispatch(failure(datasourceActions.CREATE_DATASOURCE_SHARE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
            throw error;
        });
    };
};

export const getSourceShare = (sourceId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getSourceShare}/${sourceId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_SOURCE_SHARE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_SOURCE_SHARE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_SOURCE_SHARE_FAILURE, error));
        });
    };
};

export const inviteSourceUser = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.inviteUser}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.INVITE_SOURCE_SHARE_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasourceActions.INVITE_SOURCE_SHARE_SUCCESS, response));
            if (requestParams[0].type === 'datasource') {
                dispatch(success(datasourceActions.CREATE_DATASOURCE_SHARE_SUCCESS, response.source_share));
            } else {
                dispatch(success(datasetActions.CREATE_DATASET_SHARE_SUCCESS, response.source_share));
            }
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.INVITE_SOURCE_SHARE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
        });
    };
};


export const getFavorites = (datasourceId, type) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getFavorites}/${datasourceId}/${type}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_FAVOURITES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_FAVORITES_SUCCESS, response.success));
            return response.success;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_FAVORITES_FAILURE, error));
        });
    };
};

export const updateFavorites = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.favorites}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.UPDATE_FAVOURITES_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasourceActions.UPDATE_FAVORITES_SUCCESS, response.success));
            return response.success;
        }, (error) => {
            dispatch(failure(datasourceActions.UPDATE_FAVORITES_FAILURE, error));
        });
    };
};

export const getSourceDqScore = (datasourceId, datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDqScore}/${datasourceId}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_SOURCE_DQSCORE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_SOURCE_DQSCORE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_SOURCE_DQSCORE_FAILURE, error));
        });
    };
};

export const getAllDatasource = (start, end) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getAllDataSource}/${start}/${end}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_ORGANIZATION_DATASOURCE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_ORGANIZATION_DATASOURCE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_ORGANIZATION_DATASOURCE_FAILURE, error));
        });
    };
};

export const checkLicense = () => {
    const cancellationToken = getCancellationToken();
    const endpoint = `${appConstants.END_POINTS.catalog.checkLicense}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.CHECK_LICENSE_REQUEST));
        return service.getRequest(endpoint, cancellationToken).then((response) => {
            dispatch(success(datasourceActions.CHECK_LICENSE_SUCCESS, response.data));
            const user = getUserProperty();
            if (response.data === 0) {
                dispatch(disableRolePermission());
                if (user.role && user.role.name !== "Admin" && !user.is_superuser) {
                    dispatch(logout());
                } else {
                    dispatch(resetConfig());
                }
                dispatch(getUpdateLicenseConnectionStatus());
                dispatch(failure(alertActions.ALERT_ERROR, "Your license is Deactived or Expired. Please Contact Your Admin for Info."));
            }
            if (response.data === 1) {
                dispatch(getUpdateLicenseConnectionStatus());
                dispatch(getOrganizationConfig());
                dispatch(getLicenseModules(user.organization ? user.organization : user.organization_id));
            }
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.CHECK_LICENSE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, "Your license is Deactived or Expired. Please Contact Your Admin for Info."));
        });
    };
};

export const getCurationRules = () => {
    const cancellationToken = getCancellationToken();
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_CURATION_RULES_REQUEST));
        return service.getRequest(appConstants.END_POINTS.catalog.getCurationRules, cancellationToken).then((response) => {
            dispatch(success(datasourceActions.GET_CURATION_RULES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_CURATION_RULES_FAILURE, error));
        });
    };
};

export const getDefaultTypes = () => {
    const cancellationToken = getCancellationToken();
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_DEFAULT_TYPES_REQUEST));
        return service.getRequest(appConstants.END_POINTS.catalog.getTypes, cancellationToken).then((response) => {
            dispatch(success(datasourceActions.GET_DEFAULT_TYPES_SUCCESS, response.data));
        }, (error) => {
            dispatch(failure(datasourceActions.GET_DEFAULT_TYPES_FAILURE, error));
        });
    };
};

export const getUserSharing = () => {
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_USER_SHARING_REQUEST));
        return service.getRequest(appConstants.END_POINTS.catalog.userSharing).then((response) => {
            dispatch(success(datasourceActions.GET_USER_SHARING_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_USER_SHARING_FAILURE, error));
        });
    };
};

export const getViews = (datasourceId, datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getViews}/${datasourceId}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_VIEWS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_VIEWS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_VIEWS_FAILURE, error));
        });
    };
};

export const updateViews = (requestParams) => {
    return (dispatch) => {
        dispatch(request(datasourceActions.UPDATE_VIEWS_REQUEST));
        return service.postRequest(appConstants.END_POINTS.catalog.updateViews, requestParams).then((response) => {
            dispatch(success(datasourceActions.UPDATE_VIEWS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.UPDATE_VIEWS_FAILURE, error));
        });
    };
};

export const getReferences = () => {
    const cancellationToken = getCancellationToken();
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_REFERENCE_RULES_REQUEST));
        return service.getRequest(appConstants.END_POINTS.catalog.getReferences, cancellationToken).then((response) => {
            const library = response.data ? response.data.filter((library) => library.name && library.name.length && library.type && library.type.length) : [];
            dispatch(success(datasourceActions.GET_REFERENCE_RULES_SUCCESS, library));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_REFERENCE_RULES_FAILURE, error));
        });
    };
};

export const updateDataSourceIcon = (datasourceInfo) => {
    const endpoint = `${appConstants.END_POINTS.catalog.updateDataSourceIcon}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.UPDATE_DATASOURCE_ICON_REQUEST));
        return service.putRequest(endpoint, datasourceInfo).then((response) => {
            dispatch(success(datasourceActions.UPDATE_DATASOURCE_ICON_SUCCESS, response));
        }, (error) => {
            dispatch(failure(datasourceActions.UPDATE_DATASOURCE_ICON_FAILURE, error));
        });
    };
};


export const deleteSourceShare = (requestParams) => {
    return (dispatch) => {
        dispatch(request(datasourceActions.DELETE_DATASOURCE_SHARE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.catalog.deleteDatasourceShare, requestParams).then((response) => {
            dispatch(success(datasourceActions.DELETE_DATASOURCE_SHARE_SUCCESS, requestParams.deleteUserList));
            return response.success;
        }, (error) => {
            dispatch(failure(datasourceActions.DELETE_DATASOURCE_SHARE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
            throw error;
        });
    };
};

export const deleteDatasetShare = (requestParams) => {
    return (dispatch) => {
        dispatch(request(datasourceActions.DELETE_DATASET_SHARE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.catalog.deleteDatasetShare, requestParams).then((response) => {
            dispatch(success(datasourceActions.DELETE_DATASET_SHARE_SUCCESS, response));
            dispatch(success(datasetActions.DELETE_DATASET_SHARE, requestParams.deleteUserList));
            return response.success;
        }, (error) => {
            dispatch(failure(datasourceActions.DELETE_DATASET_SHARE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
            throw error;
        });
    };
};


export const loadConnectionTypes = () => {
    return (dispatch) => {
        dispatch(request(datasourceActions.LOAD_CONNECTION_TYPES_REQUEST));
        return service.postRequest(appConstants.END_POINTS.catalog.loadConnectionTypes).then((response) => {
            dispatch(success(datasourceActions.LOAD_CONNECTION_TYPES_SUCCESS, response));
            return response.success;
        }, (error) => {
            dispatch(failure(datasourceActions.LOAD_CONNECTION_TYPES_FAILURE, error));
        });
    };
};

export const getConnectionTypes = () => {
    const cancellationToken = getCancellationToken();
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_CONNECTION_TYPES_REQUEST));
        return service.getRequest(appConstants.END_POINTS.catalog.connectionType, cancellationToken).then((response) => {
            dispatch(success(datasourceActions.GET_CONNECTION_TYPES_SUCCESS, response.data));
        }, (error) => {
            dispatch(failure(datasourceActions.GET_CONNECTION_TYPES_FAILURE, error));
        });
    };
};

export const getModuleConnectionTypes = () => {
    const cancellationToken = getCancellationToken();
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_MODULE_CONNECTION_TYPES_REQUEST));
        return service.getRequest(appConstants.END_POINTS.catalog.moduleConnectionType, cancellationToken).then((response) => {
            dispatch(success(datasourceActions.GET_MODULE_CONNECTION_TYPES_SUCCESS, response.data));
        }, (error) => {
            dispatch(failure(datasourceActions.GET_MODULE_CONNECTION_TYPES_FAILURE, error));
        });
    };
};


export const getAllDatasets = (start, end) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getAllDataSet}/${start}/${end}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_ORGANIZATION_DATASET_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_ORGANIZATION_DATASET_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_ORGANIZATION_DATASET_FAILURE, error));
        });
    };
};


export const getAllAttributes = (start, end) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getAllAttributes}/${start}/${end}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_ORGANIZATION_ATTRIBUTE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_ORGANIZATION_ATTRIBUTE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_ORGANIZATION_ATTRIBUTE_FAILURE, error));
        });
    };
};


export const getUserDatasources = () => {
    const endpoint = `${appConstants.END_POINTS.catalog.getUserDatasources}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_USER_DATASOURCES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_USER_DATASOURCES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_USER_DATASOURCES_FAILURE, error));
        });
    };
};

export const setSelectedDatasourceId = (datasourceId) => {
    return {
        type: datasourceActions.SET_SELECTED_DATASOURCE_ID,
        datasourceId
    };
};


export const getOrganizationSources = () => {
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_ORGANIZATION_SOURCE_REQUEST));
        return service.getRequest(appConstants.END_POINTS.catalog.getOrganizationSources).then((response) => {
            dispatch(success(datasourceActions.GET_ORGANIZATION_SOURCE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_ORGANIZATION_SOURCE_FAILURE, error));
        });
    };
};

export const createDomain = (domain) => {
    return (dispatch) => {
        if (domain === "Error in Name") {
            return dispatch(failure(alertActions.ALERT_ERROR, alerts.domainNameError));
        }
        dispatch(request(datasourceActions.CREATE_DOMAIN_REQUEST));
        return service.postRequest(appConstants.END_POINTS.catalog.createDomain, domain).then((response) => {
            dispatch(success(datasourceActions.CREATE_DOMAIN_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.CREATE_DOMAIN_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
            throw error;
        });
    };
};

export const updateDomain = (domain) => {
    return (dispatch) => {
        dispatch(request(datasourceActions.UPDATE_DOMAIN_REQUEST));
        return service.putRequest(appConstants.END_POINTS.domain.updateDomain, domain).then((response) => {
            dispatch(success(datasourceActions.UPDATE_DOMAIN_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.UPDATE_DOMAIN_FAILURE, error));
        });
    };
};

export const getDomain = (organizationId) => {
    organizationId = organizationId ? organizationId : 0;
    const endpoint = `${appConstants.END_POINTS.catalog.getDomains}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_ORGANIZATION_DOMAIN_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_ORGANIZATION_DOMAIN_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_ORGANIZATION_DOMAIN_FAILURE, error));
        });
    };
};

export const getAllDomains = (start, end) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getAllDomains}/${start}/${end}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_ORGANIZATION_ALL_DOMAIN_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_ORGANIZATION_ALL_DOMAIN_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_ORGANIZATION_ALL_DOMAIN_FAILURE, error));
        });
    };
};

export const getDomainShare = (domainId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDomainShare}/${domainId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_DOMAIN_SOURCE_SHARE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_DOMAIN_SOURCE_SHARE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_DOMAIN_SOURCE_SHARE_FAILURE, error));
        });
    };
};


export const deleteDomain = (domainId) => {
    domainId = domainId ? domainId : 0;
    const endpoint = `${appConstants.END_POINTS.domain.deleteDomain}/${domainId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.DELETE_DOMAIN_REQUEST));
        return service.deleteRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.DELETE_DOMAIN_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.deleteDomain));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.DELETE_DOMAIN_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
            throw error;
        });
    };
};

export const setFilters = (filters) => {
    return {
        type: datasourceActions.SET_SOURCE_FILTERS,
        filters
    };
};

export const updateDomainImage = (file) => {
    return (dispatch) => {
        dispatch(request(datasourceActions.UPLOAD_DOMAIN_IMAGE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.domain.uploadDomainImage, file, true).then((response) => {
            dispatch(success(datasourceActions.UPLOAD_DOMAIN_IMAGE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.UPLOAD_DOMAIN_IMAGE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};


export const updateJobStatus = (data) => {
    return {
        type: datasourceActions.UPDATE_JOB_STATUS,
        data
    };
};


export const getDatasetEnrichments = (sourceId, datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDatasetEnrichments}/${sourceId}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_DATASET_ENRICHMENTS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_DATASET_ENRICHMENTS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_DATASET_ENRICHMENTS_FAILURE, error));
        });
    };
};

export const createDatasetEnrichment = (requestParams) => {
    return (dispatch) => {
        dispatch(request(datasourceActions.CREATE_DATASET_ENRICHMENTS_REQUEST));
        return service.postRequest(appConstants.END_POINTS.catalog.createDatasetEnrichment, requestParams).then((response) => {
            dispatch(success(datasourceActions.CREATE_DATASET_ENRICHMENTS_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.createDatasetEnrichment));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.CREATE_DATASET_ENRICHMENTS_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const updateDatasetEnrichment = (requestParams, enrichmentId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.updateDatasetEnrichment}/${enrichmentId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.UPDATE_DATASET_ENRICHMENTS_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasourceActions.UPDATE_DATASET_ENRICHMENTS_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.updateDatasetEnrichment));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.UPDATE_DATASET_ENRICHMENTS_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };

};

export const deleteDatasetEnrichment = (enrichmentId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.deleteDatasetEnrichment}/${enrichmentId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.DELETE_DATASET_ENRICHMENTS_REQUEST));
        return service.deleteRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.DELETE_DATASET_ENRICHMENTS_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.deleteDatasetEnrichment));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.DELETE_DATASET_ENRICHMENTS_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

/**
 * @name getFieldPropertyByLevel
 * @param {*} organizationId
 * @param {*} level
 * @returns
 */
export const getFieldPropertyByLevel = (levelId, level, optionalId = 0) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getFieldPropertyByLevel}/${levelId}/${level}/${optionalId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_FIELD_PROPERTIES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_FIELD_PROPERTIES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_FIELD_PROPERTIES_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

/**
 * @name createFieldProperty
 * @description Create Field Property For Orgainzation, Level
 * @param {*} field
 * @author Ashok
 */
export const createFieldProperty = (field) => {
    return (dispatch) => {
        dispatch(request(datasourceActions.CREATE_FIELD_PROPERTIES_REQUEST));
        return service.postRequest(appConstants.END_POINTS.catalog.createFieldProperty, field).then((response) => {
            dispatch(success(datasourceActions.CREATE_FIELD_PROPERTIES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.CREATE_FIELD_PROPERTIES_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const exportPropertiesReport = (requestParams, type) => {
    const endpoint = `${appConstants.END_POINTS.catalog.exportPropertiesReport}`;
    return (dispatch) => {
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasourceActions.EXPORT_REPORT, response.data));
            if (response.data) {
                const rows = response.data;
                const csvContent = rows.map((row) => row.map((val) => {
                    if (typeof val === 'object' && !Array.isArray(val)) {
                        const value = JSON.stringify(val).replace(/"/g, "\\'");
                        return `"${value}"`;
                    } else if (Array.isArray(val)) {
                        const value = val.map((myObj) => JSON.stringify(myObj).replace(/"/g, "\\'"));
                        return `"${value}"`;
                    } return val;
                })).join("\n");
                const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
                if (type === "datasource") {
                    FileSaver.saveAs(blob, 'datasource_attribute_properties.csv');
                }
                else {
                    FileSaver.saveAs(blob, 'dataset_attribute_properties.csv');
                }
            }
            return response.success;
        }, (error) => {
            dispatch(failure(datasourceActions.EXPORT_REPORT, error));
        });
    };
};

export const getExportReport = (requestParams, type) => {
    const endpoint = `${appConstants.END_POINTS.setting.exportReport}`;
    return (dispatch) => {
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasourceActions.EXPORT_REPORT, response.data));
            if (response.data) {
                const datasetAttributes = JSON.stringify(response.data);
                const blob = new Blob([datasetAttributes], { type: 'application/json' });
                if (type === "datasource") {
                    FileSaver.saveAs(blob, 'datasource_report.json');
                }
                else {
                    FileSaver.saveAs(blob, 'dataset_report.json');
                }
            }
            return response.success;
        }, (error) => {
            dispatch(failure(datasourceActions.EXPORT_REPORT, error));
        });
    };
};

export const getImportReport = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.importReport}`;
    return (dispatch) => {
        if (requestParams === "Import Error") {
            return dispatch(failure(alertActions.ALERT_ERROR, requestParams));
        }
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasourceActions.IMPORT_REPORT, response));
            if (response.data) {
                dispatch(success(alertActions.ALERT_SUCCESS, alerts.getImportReport));
            }
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.IMPORT_REPORT, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
        });

    };
};

export const getSourceNames = () => {
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_ORGANIZATION_SOURCE_NAMES_REQUEST));
        return service.getRequest(appConstants.END_POINTS.catalog.getSourceNames).then((response) => {
            dispatch(success(datasourceActions.GET_ORGANIZATION_SOURCE_NAMES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_ORGANIZATION_SOURCE_NAMES_FAILURE, error));
        });
    };
};


export const getSourceDatasets = () => {
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_ORGANIZATION_SOURCE_DATASETS_REQUEST));
        return service.getRequest(appConstants.END_POINTS.catalog.getSourceDatasets).then((response) => {
            dispatch(success(datasourceActions.GET_ORGANIZATION_SOURCE_DATASETS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_ORGANIZATION_SOURCE_DATASETS_FAILURE, error));
        });
    };
};


export const getSourceRating = (datasourceId, datasetId, itemId, attributeId = 0) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getSourceRating}/${datasourceId}/${datasetId}/${itemId}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_SOURCE_RATING_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_SOURCE_RATING_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_SOURCE_RATING_FAILURE, error));
        });
    };
};

export const linkTerm = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.linkTerm}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.LINK_TERM_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasourceActions.LINK_TERM_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.LINK_TERM_FAILURE, error));
        });
    };
};

export const getDuplicateColumn = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDuplicateColumn}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_DATASET_DUPLICATE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_DATASET_DUPLICATE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_DATASET_DUPLICATE_FAILURE, error));
        });
    };
};

export const saveDuplicate = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.saveDuplicateColumn}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.SAVE_DUPLICATE_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasourceActions.SAVE_DUPLICATE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.SAVE_DUPLICATE_FAILURE, error));
        });
    };
};

export const getCatalogListData = (searchConfig, resetData = false, cancellationToken = null) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getCatalogListData}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_CATALOG_DATASOURCES_REQUEST));
        return service.postRequest(endpoint, searchConfig, false, cancellationToken).then((response) => {
            dispatch(success(datasourceActions.GET_CATALOG_DATASOURCES_SUCCESS, { data: response.data, resetData, searchConfig }));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_CATALOG_DATASOURCES_FAILURE, error));
        });
    };
};

export const clearCatalogList = () => {
    return {
        type: datasourceActions.CLEAR_CATALOG_LIST_DATA
    };
};

export const updateDatasource = (datasources) => {
    return {
        type: datasourceActions.UPDATE_DATASOURCE_LOAD,
        datasources: [...datasources]
    };
};

export const updateDataset = (datasets) => {
    return {
        type: datasourceActions.UPDATE_DATASET_LOAD,
        datasets: [...datasets]
    };
};

export const importCatalogMetadata = (requestParams) => {
    return (dispatch) => {
        dispatch(request(datasourceActions.IMPORT_DATA_REQUEST));
        return service.postRequest(appConstants.END_POINTS.catalog.importCatalogMetadata, requestParams).then((response) => {
            dispatch(success(datasourceActions.IMPORT_DATA_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.IMPORT_DATA_FAILURE, error));
        });
    };
};

export const updateLicenseStatus = () => {
    return {
        type: datasourceActions.CHECK_LICENSE_SUCCESS,
        responseData: 1
    };
};

export const getOrganizationDatasources = () => {
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_ORGANIZATION_DATASOURCE_REQUEST));
        return service.getRequest(appConstants.END_POINTS.catalog.getOrganizationDatasources).then((response) => {
            dispatch(success(datasourceActions.GET_ORGANIZATION_DATASOURCE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_ORGANIZATION_DATASOURCE_FAILURE, error));
        });
    };
};

export const getDatasetByDatasource = (datasourceId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDatasetListByDatasource}/${datasourceId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_DATASET_LIST_BYDATASOURCE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_DATASET_LIST_BYDATASOURCE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_DATASET_LIST_BYDATASOURCE_FAILURE, error));
        });
    };
};

export const getAttributeByDatasource = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getAttributeListByDatasource}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_ATTRIBUTE_LIST_BYDATASOURCE_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasourceActions.GET_ATTRIBUTE_LIST_BYDATASOURCE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_ATTRIBUTE_LIST_BYDATASOURCE_FAILURE, error));
        });
    };
};

export const importDatasetRules = (requestParams) => {
    return (dispatch) => {
        dispatch(request(datasourceActions.IMPORT_DATA_REQUEST));
        return service.postRequest(appConstants.END_POINTS.setting.importDatasetRules, requestParams).then((response) => {
            dispatch(success(datasourceActions.IMPORT_DATA_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.IMPORT_DATA_FAILURE, error));
        });
    };
};

export const updateCertified = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.updateCertified}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.UPDATE_CERTIFIED_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasourceActions.UPDATE_CERTIFIED_SUCCESS, response.success));
            return response.success;
        }, (error) => {
            dispatch(failure(datasourceActions.UPDATE_CERTIFIED_FAILURE, error));
        });
    };
};

export const getCertified = (datasourceId, type) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getCertified}/${datasourceId}/${type}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_CERTIFIED_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_CERTIFIED_SUCCESS, response.success));
            return response.success;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_CERTIFIED_FAILURE, error));
        });
    };
};

export const getAttributeRating = (datasourceId, datasetId, itemId, attributeId = 0) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getAttributeRating}/${datasourceId}/${datasetId}/${itemId}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(datasourceActions.GET_ATTRIBUTE_RATING_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasourceActions.GET_ATTRIBUTE_RATING_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.GET_ATTRIBUTE_RATING_FAILURE, error));
        });
    };
};

export const importUsers = (requestParams) => {
    return (dispatch) => {
        dispatch(request(datasourceActions.IMPORT_DATA_REQUEST));
        return service.postRequest(appConstants.END_POINTS.setting.importUsers, requestParams).then((response) => {
            dispatch(success(datasourceActions.IMPORT_DATA_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasourceActions.IMPORT_DATA_FAILURE, error));
        });
    };
};

export const updateTabIndex = (index) => {
    return (dispatch) => {
        dispatch(request(datasourceActions.UPDATE_TABINDEX_REQUEST));
        dispatch(success(datasourceActions.UPDATE_TABINDEX_SUCCESS, index));
    };
};