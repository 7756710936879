import React, { Fragment, useCallback } from 'react';
import { Grid, withStyles, Typography, IconButton } from '@material-ui/core';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import MasterListDetail from './MasterListDetail.jsx';
import TooltipComponent from '../Tooltip/Tooltip.jsx';

const MasterList = (props) => {
    const { classes, theme, models, history } = props;
    const user = useSelector(({ account }) => account.user);

    const getSharingIcon = (sharingInfo, model) => {
        const userId = user ? user.id : 0;
        const modelDetail = sharingInfo.find((info) => info.user_id === userId);
        return (
            <Fragment>
                {
                    (!modelDetail) &&
                    <Grid>
                        <TooltipComponent title={"Request Datasource"} arrow>
                            <IconButton className={classes.nopadding}
                            // onClick={() => requestAccess(model)}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
                                    <g transform="translate(0 0)">
                                        <circle fill={theme.palette.primary.main} cx="0.5" cy="0.5" r="0.5" transform="translate(12.999 13)" />
                                        <path fill={theme.palette.primary.main} d="M9.3,18.439H3.125a1.564,1.564,0,0,1-1.563-1.563V10.47A1.564,1.564,0,0,1,3.125,8.907h11.25a1.564,1.564,0,0,1,1.563,1.563v1.563a.781.781,0,0,0,1.563,0V10.47a3.129,3.129,0,0,0-3.125-3.125h-.939V4.59A4.644,4.644,0,0,0,8.749,0,4.644,4.644,0,0,0,4.061,4.59V7.345H3.125A3.129,3.129,0,0,0,0,10.47v6.406A3.129,3.129,0,0,0,3.125,20H9.3a.781.781,0,1,0,0-1.563ZM5.624,4.59A3.08,3.08,0,0,1,8.749,1.564,3.08,3.08,0,0,1,11.874,4.59V7.345H5.624V4.59Z" transform="translate(0 -0.001)" />
                                        <path fill={theme.palette.primary.main} d="M272.439,322.145a.781.781,0,0,0-1.09.18L267.5,327.7a.446.446,0,0,1-.642.033l-2.489-2.429a.781.781,0,0,0-1.091,1.118l2.493,2.433.007.007a2.014,2.014,0,0,0,1.386.554q.066,0,.133,0a2.014,2.014,0,0,0,1.432-.746l.026-.035,3.866-5.4A.781.781,0,0,0,272.439,322.145Z" transform="translate(-252.765 -309.421)" />
                                        <ellipse fill={theme.palette.primary.main} cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(8.999 13)" />
                                        <ellipse fill={theme.palette.primary.main} cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(2.999 13)" />
                                        <circle fill={theme.palette.primary.main} cx="0.5" cy="0.5" r="0.5" transform="translate(6.999 13)" />
                                    </g>
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                        <Typography component="span" className={classes.viewTxt}>
                            Request Access
                        </Typography>
                    </Grid>
                }
                {
                    (modelDetail && modelDetail.is_requested && (!modelDetail.is_request_accepted && !modelDetail.is_request_decline)) &&
                    <Grid>
                        <TooltipComponent title={"Waiting"} arrow>
                            <IconButton className={classes.padding5}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.831" height="20.072" viewBox="0 0 18.831 20.072">
                                    <ellipse fill="#dcc9d6" cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(9 13)" />
                                    <path fill="#dcc9d6" d="M11.015,18.437H3.125a1.564,1.564,0,0,1-1.562-1.562V10.469A1.564,1.564,0,0,1,3.125,8.906h11.25a1.564,1.564,0,0,1,1.562,1.562v1.719a.781.781,0,0,0,1.562,0V10.469a3.129,3.129,0,0,0-3.125-3.125h-.939V4.588A4.644,4.644,0,0,0,8.748,0,4.644,4.644,0,0,0,4.061,4.588V7.344H3.125A3.129,3.129,0,0,0,0,10.469v6.406A3.129,3.129,0,0,0,3.125,20h7.891a.781.781,0,0,0,0-1.562ZM5.623,4.588A3.08,3.08,0,0,1,8.748,1.562a3.08,3.08,0,0,1,3.125,3.026V7.344H5.623V4.588Z" />
                                    <circle fill="#dcc9d6" cx="0.5" cy="0.5" r="0.5" transform="translate(7 13)" />
                                    <circle fill="#dcc9d6" cx="0.5" cy="0.5" r="0.5" transform="translate(4 13)" />
                                    <g transform="translate(12.679 13.921)">
                                        <path fill="#dcc9d6" d="M313.581,472h0a3.075,3.075,0,1,0,3.077,3.075A3.075,3.075,0,0,0,313.58,472Z" transform="translate(-310.505 -472)" />
                                    </g>
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                        <Typography component="span" className={classes.viewTxt}>
                            Request Waiting
                        </Typography>
                    </Grid>
                }
                {
                    (modelDetail && modelDetail.is_requested && modelDetail.is_request_decline) &&
                    <Grid>
                        <TooltipComponent title={"Decline"} arrow>
                            <IconButton className={classes.padding5}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <ellipse fill="#e1e5e6" cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(9 13)" />
                                    <path fill="#e1e5e6" d="M11.015,18.437H3.125a1.564,1.564,0,0,1-1.562-1.562V10.469A1.564,1.564,0,0,1,3.125,8.906h11.25a1.564,1.564,0,0,1,1.562,1.562v1.719a.781.781,0,0,0,1.562,0V10.469a3.129,3.129,0,0,0-3.125-3.125h-.939V4.588A4.644,4.644,0,0,0,8.748,0,4.644,4.644,0,0,0,4.061,4.588V7.344H3.125A3.129,3.129,0,0,0,0,10.469v6.406A3.129,3.129,0,0,0,3.125,20h7.891a.781.781,0,0,0,0-1.562ZM5.623,4.588A3.08,3.08,0,0,1,8.748,1.562a3.08,3.08,0,0,1,3.125,3.026V7.344H5.623V4.588Z" />
                                    <circle fill="#e1e5e6" cx="0.5" cy="0.5" r="0.5" transform="translate(7 13)" />
                                    <path fill="#e1e5e6" d="M348.411,347.306l-1.987-1.987,1.987-1.987a.781.781,0,1,0-1.1-1.1l-1.987,1.987-1.987-1.987a.781.781,0,1,0-1.1,1.1l1.987,1.987-1.987,1.987a.781.781,0,0,0,1.1,1.1l1.987-1.987,1.987,1.987a.781.781,0,0,0,1.1-1.1Z" transform="translate(-328.641 -328.64)" />
                                    <circle fill="#e1e5e6" cx="0.5" cy="0.5" r="0.5" transform="translate(4 13)" />
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                        <Typography component="span" className={classes.viewTxt}>
                            Request Decline
                        </Typography>
                    </Grid>
                }
            </Fragment>
        );
    };

    const getSteward = (sharingInfo) => {
        const userId = user ? user.id : 0;
        const sourceDetail = sharingInfo.find((info) => info.is_steward_user && info.user_id === userId);
        return Boolean(sourceDetail);
    };

    const getDatasourceIcon = useCallback((source) => {
        if (source.name === 'Untitled Data Source') {
            return (
                <svg id="Group_2154" data-name="Group 2154" xmlns="http://www.w3.org/2000/svg" width="34.459" height="18.227" viewBox="0 0 34.459 18.227">
                    <path id="Path_246" data-name="Path 246" d="M170.469,193.936V175.959h6.379a9.332,9.332,0,0,1,3.77.709,7.743,7.743,0,0,1,2.708,1.924,8.013,8.013,0,0,1,1.632,2.848,10.934,10.934,0,0,1,.547,3.48,10.745,10.745,0,0,1-.606,3.708,7.944,7.944,0,0,1-1.743,2.835,7.837,7.837,0,0,1-2.734,1.823,9.436,9.436,0,0,1-3.572.645Zm11.6-9.012a7.816,7.816,0,0,0-.345-2.38,5.359,5.359,0,0,0-1.013-1.873,4.518,4.518,0,0,0-1.641-1.215,5.432,5.432,0,0,0-2.213-.43h-2.972v11.847h2.967a5.237,5.237,0,0,0,2.237-.456,4.592,4.592,0,0,0,1.632-1.257,5.62,5.62,0,0,0,1-1.886,7.736,7.736,0,0,0,.344-2.355Zm18.634,7.848a8.634,8.634,0,0,1-2.077.95,8.047,8.047,0,0,1-2.369.342,7.717,7.717,0,0,1-3.5-.785,8.87,8.87,0,0,1-2.7-2.051,9.222,9.222,0,0,1-1.743-2.911,9.505,9.505,0,0,1-.618-3.367,9.224,9.224,0,0,1,.655-3.443,9.54,9.54,0,0,1,1.806-2.911,8.618,8.618,0,0,1,2.734-2.012,7.954,7.954,0,0,1,3.437-.747,7.569,7.569,0,0,1,3.474.8,8.709,8.709,0,0,1,2.677,2.081,9.661,9.661,0,0,1,1.731,2.937,9.427,9.427,0,0,1,.618,3.342,9.33,9.33,0,0,1-.606,3.342,9.226,9.226,0,0,1-1.693,2.835l2.4,2.764h-3.214Zm-4.515-1.8a4.561,4.561,0,0,0,1.249-.165,4.893,4.893,0,0,0,1.075-.443l-2.67-3.088h3.214l1.211,1.393a7.272,7.272,0,0,0,1.04-3.721,7.154,7.154,0,0,0-.358-2.253,6.262,6.262,0,0,0-1.013-1.924,5,5,0,0,0-1.608-1.342,4.483,4.483,0,0,0-2.139-.507,4.574,4.574,0,0,0-2.2.518,4.844,4.844,0,0,0-1.608,1.38,6.349,6.349,0,0,0-.977,1.937,7.421,7.421,0,0,0,.013,4.44,6.062,6.062,0,0,0,1,1.924,5,5,0,0,0,1.608,1.342A4.54,4.54,0,0,0,196.183,190.97Z" transform="translate(-170.469 -175.837)" fill="#fff" />
                    <g id="Group_2192" data-name="Group 2192" transform="translate(24.368 10.787)">
                        <path id="Path_245-2" data-name="Path 245-2" d="M236.075,204.878h3.656l6.429,7.306-3.509.009Z" transform="translate(-236.075 -204.878)" fill="#fff" />
                    </g>
                </svg>
            );
        }

        let title = source.name.split(' ');
        if (title.length === 1) {
            title = `${title[0][0]}${title[0][1]}`;
        }
        if (title.length >= 1) {
            title = `${title[0][0]}${title[1][0]}`;
        }

        return (
            <Typography variant="h3" className={classes.categoryListLogoTxt}>
                {title}
            </Typography>
        );
    }, [classes.categoryListLogoTxt]);
    return (
        <Grid>
            {
                models && models.map((model, index) =>
                    <MasterListDetail history={history} getDatasourceIcon={getDatasourceIcon} key={index} model={model} getSteward={getSteward} getSharingIcon={getSharingIcon} />
                )
            }
        </Grid>
    );
};


MasterList.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    models: PropTypes.array,
    history: PropTypes.object
};

export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(MasterList);