/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography, FormGroup, IconButton, Popover, Button } from '@material-ui/core';
import classNames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import UserList from './UserList.jsx';
import PermissionStyles from './PermissionStyles.jsx';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import CheckboxComponent from '../ChecboxComponent/CheckboxComponent.jsx';
import { failure, success } from '../../actions/baseActions.js';
import { alertActions } from '../../constants/actionTypes/alertActionTypes.js';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';


let governanceLevel = 0;
class Permission extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: ['Validate', 'Append', 'Report'],
            expandPermission: {
                accessLevel3: false,
                accessLevel2: false,
                accessLevel1: false,
                steward: false
            },
            inputProps: {
                accessLevel3: '',
                accessLevel2: '',
                accessLevel1: '',
                steward: ''
            },
            userPermission: [],
            deletedUserPermission: [],
            users: [],
            isChanged: false,
            isUpdated: false,
            requests: [],
            historyUserPermission: [],
            updating: false,
            checkLevel: 0,
            level2Requested: false,
            level1Requested: false,
            open: false,
            anchorEl: null,
            requestLevel: 0,
            requestDesc: ""
        };
    }


    static getDerivedStateFromProps(props, state) {
        if ((props.users && props.users.length > 0 && state.users.length === 0 && props.governance.length !== 0 && !props.loading) || props.isGovernanceUpdate) {
            const users = [];
            let userPermission = [];
            const governance = props.steward ? props.governance.filter((data) => data.is_steward_user) : props.governance;
            if (props.userDetail) {
                const user_id = props.userDetail && props.userDetail.id ? props.userDetail.id : '';
                const accessLevel = props.governance.filter((item) => item.user_id === user_id);
                if (accessLevel && accessLevel.length > 0) {
                    governanceLevel = accessLevel[0].is_steward_user ? 0 : accessLevel[0].access_level;
                }
            }
            for (const info of governance) {
                let level = "accessLevel3";
                if (info.access_level === 2) {
                    level = "accessLevel2";
                } else if (info.access_level === 1) {
                    level = "accessLevel1";
                }
                userPermission.push({
                    level: level,
                    name: "",
                    isEmail: false,
                    isStewardUser: info.is_steward_user,
                    ...info
                });

            }
            for (const user of props.users) {
                users.push({
                    name: (user.first_name !== "" && user.first_name) ? `${user.first_name} ${user.last_name}` : user.username,
                    isSelected: false,
                    ...user
                });
            }
            userPermission = userPermission.map((item) => {
                let info = item;
                for (const user of users) {
                    if (info.user_id === user.id) {
                        user.isSelected = true;
                        info = {
                            ...item,
                            ...user,
                            name: (user.first_name !== "" && user.first_name) ? `${user.first_name} ${user.last_name}` : user.username
                        };
                    }
                }
                return info;
            });
            const requests = props.type === "datasource" ? userPermission.filter((item) => item.is_requested && !item.is_request_accepted && !item.is_request_decline) : [];
            userPermission = props.type === "datasource" ? userPermission.filter((item) => (item.is_request_accepted && (props.steward ? item.is_steward_user : !item.is_steward_user))) : userPermission;
            if (props.isGovernanceUpdate) {
                props.updateGovernanceUpdate();
            }
            return {
                ...state,
                users,
                userPermission,
                requests,
                historyUserPermission: JSON.parse(JSON.stringify(userPermission))
            };
        }
        return { ...state };
    }

    onKeyDown = (event, level) => {
        const { userPermission, inputProps } = this.state;
        const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (event.keyCode === 13 && emailRegex.test(event.target.value)) {
            const existingEmail = userPermission.find((user) => user.name === event.target.value && user.isEmail);
            const isExists = userPermission.find((user) => user.name === event.target.value);
            if (isExists) {
                this.props.dispatch(failure(alertActions.ALERT_ERROR, 'User already exists'));
                return;
            }
            if (!existingEmail) {
                inputProps[level] = "";
                userPermission.push({
                    name: event.target.value,
                    level: level,
                    isEmail: true,
                    email: event.target.value,
                    isStewardUser: Boolean(this.props.steward),
                    "is_request_accepted": true
                });
            }
        } else if (event.keyCode === 8 && event.target.value === "" && userPermission.length > 0) {
            const allusers = [...userPermission];
            let filterUsers = [];
            if (level === "steward") {
                filterUsers = allusers.filter((user) => user.isStewardUser === true);
            } else {
                filterUsers = allusers.filter((user) => user.level === level);
            }
            const userData = filterUsers.length !== 0 ? filterUsers[filterUsers.length - 1] : {};
            const userIndex = userPermission.findIndex((user) => user === userData);
            if (userData && !userIndex.isEmail && (!this.props.steward || filterUsers.length > 1) && userIndex !== -1) {
                const { deletedUserPermission } = this.state;
                deletedUserPermission.push(userPermission[userIndex].user_id);
                this.setState({ deletedUserPermission, isChanged: true });
            }
            if ((!this.props.steward || filterUsers.length > 1) && userIndex !== -1) {
                userPermission.splice(userIndex, 1);
            }
        }
        this.setState({ inputProps, userPermission: [...userPermission] });
    };

    onRemoveUser = (name, type) => {
        const { deletedUserPermission, userPermission, users, historyUserPermission } = this.state;
        const index = userPermission.findIndex((user) => user.name === name);
        deletedUserPermission.push(userPermission[index].id);
        userPermission.splice(index, 1);
        const userIndex = users.findIndex((user) => user.name === name);
        if (userIndex !== -1) {
            users[userIndex].isSelected = false;
        }
        const historyIndex = historyUserPermission.findIndex((user) => user.name === name);
        historyUserPermission.splice(historyIndex, 1);
        if (type === "remove") {
            const model = {
                "source_id": this.props.sourceId,
                "dataset_id": this.props.datasetId,
                "deleteUserList": deletedUserPermission
            };
            this.props.deleteShare(model).then(() => {
                this.props.updateGovernance();
                this.props.getAuditLogs();
            });
        }
        this.setState({ deletedUserPermission, userPermission, users, isChanged: true, historyUserPermission });
        this.props.dispatch(success(alertActions.ALERT_SUCCESS, 'User Removed Successfully'));
    };

    onChange = (value, level) => {
        const { inputProps, userPermission } = this.state;
        let { users } = this.state;
        let userData = {};
        users = users.map((user) => {
            const userItem = user;
            if (userItem.name === value) {
                userItem.isSelected = true;
                userData = userItem;
            }
            return userItem;
        });

        userPermission.push({
            level: level,
            name: value,
            isEmail: false,
            isStewardUser: Boolean(this.props.steward),
            "is_request_accepted": true,
            ...userData
        });
        inputProps[level] = "";
        this.setState({ inputProps, userPermission, users, isChanged: true });
    };

    onInputValueOnChange = (value, level) => {
        const { inputProps } = this.state;
        inputProps[level] = value;
        this.setState({ inputProps });
    }

    onChangePlatformUse = (name, value) => {
        const mdmFields = this.props.source.platform_use ? this.props.source.platform_use : [];
        if (value) { mdmFields.push(name); }
        else {
            const index = mdmFields.indexOf(name);
            mdmFields.splice(index, 1);
        }
        this.props.onChange('platform_use', mdmFields);
    }

    expandList = (property) => {
        // if (this.props.isEditable) {
        const { expandPermission } = this.state;
        expandPermission[property] = true;
        this.setState({ expandPermission });
        // }
    }

    onBlurList = (property) => {
        const { expandPermission, inputProps, userPermission, deletedUserPermission, historyUserPermission } = this.state;
        expandPermission[property] = false;
        inputProps[property] = "";
        const model = {
            "source_id": this.props.sourceId,
            "dataset_id": this.props.datasetId,
            "shareUserList": []
        };

        const deleteModel = {
            "source_id": this.props.sourceId,
            "dataset_id": this.props.datasetId,
            "deleteUserList": deletedUserPermission
        };

        if (deletedUserPermission.length !== 0) {
            this.props.deleteShare(deleteModel).then(() => {
                this.props.updateGovernance();
                this.props.getAuditLogs();
            });
        }
        this.setState({ expandPermission, inputProps });

        const permissionList = JSON.parse(JSON.stringify(userPermission.filter((data) => data.level === property)));
        const accessLevelData = permissionList.filter((data) => {
            const userData = historyUserPermission.filter((user) => data.level === user.level && data.name === user.name);
            return userData.length === 0;
        });

        const inviteUser = [];
        for (const user of accessLevelData) {
            let level = 3;
            if (user.level === "accessLevel2") {
                level = 2;
            } else if (user.level === "accessLevel1") {
                level = 1;
            }
            if (!user.isEmail) {
                model.shareUserList.push({
                    "user_id": user.id,
                    "access_level": level,
                    "type": this.props.type,
                    "is_stewarduser": user.isStewardUser,
                    "is_owner": false,
                    "source_id": this.props.sourceId,
                    "dataset_id": this.props.datasetId,
                    "is_request_accepted": true,
                    "is_decline": false,
                    "is_requested": false
                });
            } else {
                inviteUser.push({
                    "email": user.name,
                    "access_level": level,
                    "type": this.props.type,
                    "is_stewarduser": user.isStewardUser,
                    "is_owner": false,
                    "source_id": this.props.sourceId,
                    "dataset_id": this.props.datasetId
                });
            }
        }
        if (this.state.isChanged && model.shareUserList.length !== 0) {
            this.setState({ updating: true });
            this.props.createSourceShare(model).then(() => {
                this.setState({ updating: false });
                this.props.updateGovernance();
                if (!this.props.hideAuditlog) {
                    this.props.getAuditLogs();
                }
            });
            this.setState({ isChanged: false, historyUserPermission: [...userPermission] });
        }

        if (inviteUser.length > 0) {
            this.props.inviteSourceUser(inviteUser).then((response) => {
                if (response) {
                    this.props.updateGovernance();
                    if (!this.props.hideAuditlog) {
                        this.props.getAuditLogs();
                    }
                    const { users } = this.state;
                    for (const user of response) {
                        users.push({
                            name: (user.first_name !== "" && user.first_name) ? `${user.first_name} ${user.last_name}` : user.username,
                            isSelected: true,
                            avatar: '',
                            ...user
                        });
                    }
                    this.props.updateUser(users);
                    this.setState({ users });
                }
            });
        }
        this.setState({ deletedUserPermission: [] });
    }

    actionRequest = (event, type, sharingInfo) => {
        event.stopPropagation();
        let level = 3;
        if (sharingInfo.level === "accessLevel2") {
            level = 2;
        } else if (sharingInfo.level === "accessLevel1") {
            level = 1;
        }
        const model = {
            "source_id": sharingInfo.source_id,
            "shareUserList": [
                {
                    "user_id": sharingInfo.user_id,
                    "is_stewarduser": sharingInfo.is_steward_user,
                    "type": this.props.type,
                    "access_level": level,
                    "source_id": sharingInfo.source_id,
                    "is_request_accepted": type === "accept",
                    "is_decline": type === "decline",
                    "is_requested": true,
                    "is_owner": false
                }
            ],
            "dataset_id": this.props.datasetId
        };
        this.props.createSourceShare(model).then(() => {
            const { userPermission, requests } = this.state;
            const index = requests.findIndex((user) => user.id === sharingInfo.id);
            if (index !== -1) {
                if (type === "accept") {
                    requests[index]["is_request_accepted"] = true;
                    userPermission.push({
                        ...requests[index]
                    });
                }
                requests.splice(index, 1);
                this.setState({ requests, userPermission });
            }
            this.props.getAuditLogs();
        });
    }

    requestAccess = (request_level, governance_sel) => {
        this.setState({ open: false, anchorEl: null });
        let level = 1;
        if (request_level === "level3") {
            level = 3;
        } else if (request_level === "level2") {
            level = 2;
        }
        const model = {
            "source_id": this.props.sourceId,
            "shareUserList": [
                {
                    "user_id": governance_sel[0]?.user_id,
                    "is_stewarduser": governance_sel[0]?.is_steward_user,
                    "type": this.props.type,
                    "source_id": this.props.sourceId,
                    "is_request_accepted": true,
                    "is_decline": false,
                    "is_requested": false,
                    "is_owner": false,
                    "is_higher_requested": true,
                    "is_higher_request_accepted": false,
                    "is_higher_request_decline": false,
                    "new_access_level": level,
                    "access_level": level - 1,
                    "request_comment": this.state.requestDesc
                }
            ],
            "dataset_id": this.props.datasetId
        };
        this.props.createSourceShare(model).then((response) => {
            if (request_level === "level3") {
                this.setState({ level2Requested: true });
            } else if (request_level === "level2") {
                this.setState({ level1Requested: true });
            }
        });
    }

    openRequestAccess = (event, level) => {
        this.setState({ open: true, anchorEl: event.currentTarget, requestLevel: level });
    };

    closeRequestAccess = () => {
        this.setState({ open: false, anchorEl: null });
    };

    onChangeDesc = (name, value) => {
        this.setState({ requestDesc: value });
    }

    render() {
        const { classes, source, onChange, onSave, isEditable, steward, hideplatform, showComment, comment, theme } = this.props;
        const userId = this.props.user.id;
        const governance_sel = this.props.governance.filter((data) => data.user_id === userId);
        const currentlevel = governance_sel[0]?.access_level;
        return (
            <Grid>
                {
                    !steward ?
                        <Grid className={classes.card}>
                            <Grid container justify="space-between">
                                <Typography component="h5" variant="h5" className={classes.marginBottom10}>
                                    Governance
                                </Typography>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4} >
                                    <Grid className={classNames(classes.cardContainer, classes.ribbonPosition)}>
                                        {
                                            governanceLevel === 3 &&
                                            <TooltipComponent title="You are Here" arrow>
                                                <Grid className={classes.governanceRibbon} />
                                            </TooltipComponent>
                                        }
                                        <Typography className={classes.permissionTitleText}>
                                            Restricted (Level 3)
                                            {
                                                this.state.requests.filter((data) => data.access_level === 3).length !== 0 &&
                                                <Grid className={classes.badge}>
                                                    {this.state.requests.length}
                                                </Grid>
                                            }
                                        </Typography>
                                        <Typography className={classes.permissionText}>
                                            Provides access to Data that is classified as Restricted when the unauthorized disclosure, alteration or destruction of that data could cause a significant level of risk to the Organization or its affiliates.
                                        </Typography>
                                        <UserList
                                            expandList={(level) => this.expandList(level)}
                                            level="accessLevel3"
                                            isOPen={this.state.expandPermission.accessLevel3}
                                            users={this.state.users.filter((user) => user.governance === "Level 3" || user.governance === "None")}
                                            selectedList={this.state.userPermission.filter((item) => item.level === "accessLevel3" && !item.is_steward_user && item.name)}
                                            inputValue={this.state.inputProps.accessLevel3}
                                            onKeyDown={(event) => this.onKeyDown(event, "accessLevel3")}
                                            onChange={(event) => this.onChange(event, "accessLevel3")}
                                            onBlur={() => this.onBlurList("accessLevel3")}
                                            onInputValueOnChange={(value) => this.onInputValueOnChange(value, "accessLevel3")}
                                            onRemoveUser={(name, type) => this.onRemoveUser(name, type)}
                                            userRequests={this.state.requests.filter((item) => item.level === "accessLevel3")}
                                            actionRequest={(event, type, request) => this.actionRequest(event, type, request)}
                                            disabled={!isEditable}
                                            showAdd
                                            count={3}
                                            upis_steward_userdating={this.state.updating} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid className={classNames(classes.cardContainer, classes.ribbonPosition)}>
                                        <Grid container>
                                            <Grid>
                                                {
                                                    governanceLevel === 2 &&
                                                    <TooltipComponent title="You are Here" arrow>
                                                        <Grid className={classes.governanceRibbon} />
                                                    </TooltipComponent>
                                                }
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography className={classes.permissionTitleText}>
                                                    Private (Level 2)
                                                    {
                                                        this.state.requests.filter((data) => data.access_level === 2).length !== 0 &&
                                                        <Grid className={classes.badge}>
                                                            {this.state.requests.length}
                                                        </Grid>
                                                    }
                                                </Typography>
                                            </Grid>
                                            {
                                                currentlevel === 2 &&
                                                <Grid item xs={6}>
                                                    <Grid className={classes.paddingLeft12} style={{ textAlign: 'right' }} onClick={this.state.level2Requested || governance_sel[0]?.is_higher_requested ? null : (event) => this.openRequestAccess(event, 'level3')}>
                                                        <TooltipComponent title={"Request Datasource"} arrow>
                                                            <IconButton className={classes.nopadding}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
                                                                    <g transform="translate(0 0)">
                                                                        <circle fill={theme.palette.primary.main} cx="0.5" cy="0.5" r="0.5" transform="translate(12.999 13)" />
                                                                        <path fill={theme.palette.primary.main} d="M9.3,18.439H3.125a1.564,1.564,0,0,1-1.563-1.563V10.47A1.564,1.564,0,0,1,3.125,8.907h11.25a1.564,1.564,0,0,1,1.563,1.563v1.563a.781.781,0,0,0,1.563,0V10.47a3.129,3.129,0,0,0-3.125-3.125h-.939V4.59A4.644,4.644,0,0,0,8.749,0,4.644,4.644,0,0,0,4.061,4.59V7.345H3.125A3.129,3.129,0,0,0,0,10.47v6.406A3.129,3.129,0,0,0,3.125,20H9.3a.781.781,0,1,0,0-1.563ZM5.624,4.59A3.08,3.08,0,0,1,8.749,1.564,3.08,3.08,0,0,1,11.874,4.59V7.345H5.624V4.59Z" transform="translate(0 -0.001)" />
                                                                        <path fill={theme.palette.primary.main} d="M272.439,322.145a.781.781,0,0,0-1.09.18L267.5,327.7a.446.446,0,0,1-.642.033l-2.489-2.429a.781.781,0,0,0-1.091,1.118l2.493,2.433.007.007a2.014,2.014,0,0,0,1.386.554q.066,0,.133,0a2.014,2.014,0,0,0,1.432-.746l.026-.035,3.866-5.4A.781.781,0,0,0,272.439,322.145Z" transform="translate(-252.765 -309.421)" />
                                                                        <ellipse fill={theme.palette.primary.main} cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(8.999 13)" />
                                                                        <ellipse fill={theme.palette.primary.main} cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(2.999 13)" />
                                                                        <circle fill={theme.palette.primary.main} cx="0.5" cy="0.5" r="0.5" transform="translate(6.999 13)" />
                                                                    </g>
                                                                </svg>
                                                            </IconButton>
                                                        </TooltipComponent>
                                                        <Typography component="span" className={classes.viewTxt}>
                                                            {(this.state.level2Requested || governance_sel[0]?.is_higher_requested) ? "Request Pending" : "Request Higher Access"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Typography className={classes.permissionText}>
                                            Provides access to Data that is classified as Private when the unauthorized disclosure, alteration or destruction of that data could result in a moderate level of risk to the Organization or its affiliates.
                                        </Typography>
                                        <UserList
                                            expandList={(level) => this.expandList(level)}
                                            level="accessLevel2"
                                            isOPen={this.state.expandPermission.accessLevel2}
                                            users={this.state.users.filter((user) => user.governance === "Level 3" || user.governance === "Level 2" || user.governance === "None")}
                                            selectedList={this.state.userPermission.filter((item) => item.level === "accessLevel2" && item.name)}
                                            inputValue={this.state.inputProps.accessLevel2}
                                            onKeyDown={(event) => this.onKeyDown(event, "accessLevel2")}
                                            onChange={(event) => this.onChange(event, "accessLevel2")}
                                            onBlur={() => this.onBlurList("accessLevel2")}
                                            onRemoveUser={(name, type) => this.onRemoveUser(name, type)}
                                            onInputValueOnChange={(value) => this.onInputValueOnChange(value, "accessLevel2")}
                                            userRequests={this.state.requests.filter((item) => item.level === "accessLevel2")}
                                            actionRequest={(event, type, request) => this.actionRequest(event, type, request)}
                                            disabled={!isEditable}
                                            showAdd
                                            count={3}
                                            updating={this.state.updating} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={4}>
                                    <Grid className={classNames(classes.cardContainer, classes.ribbonPosition)}>
                                        <Grid container>
                                            <Grid>
                                                {
                                                    governanceLevel === 1 &&
                                                    <TooltipComponent title="You are Here" arrow>
                                                        <Grid className={classes.governanceRibbon} />
                                                    </TooltipComponent>
                                                }
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography className={classes.permissionTitleText}>
                                                    Public (Level 1)
                                                    {
                                                        this.state.requests.filter((data) => data.access_level === 1).length !== 0 &&
                                                        <Grid className={classes.badge}>
                                                            {this.state.requests.length}
                                                        </Grid>
                                                    }
                                                </Typography>
                                            </Grid>
                                            {
                                                currentlevel === 1 &&
                                                <Grid item xs={6}>
                                                    <Grid className={classes.paddingLeft12} style={{ textAlign: 'right' }} onClick={this.state.level1Requested || governance_sel[0]?.is_higher_requested ? null : (event) => this.openRequestAccess(event, 'level2')}>
                                                        <TooltipComponent title={"Request Datasource"} arrow>
                                                            <IconButton className={classes.nopadding}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
                                                                    <g transform="translate(0 0)">
                                                                        <circle fill={theme.palette.primary.main} cx="0.5" cy="0.5" r="0.5" transform="translate(12.999 13)" />
                                                                        <path fill={theme.palette.primary.main} d="M9.3,18.439H3.125a1.564,1.564,0,0,1-1.563-1.563V10.47A1.564,1.564,0,0,1,3.125,8.907h11.25a1.564,1.564,0,0,1,1.563,1.563v1.563a.781.781,0,0,0,1.563,0V10.47a3.129,3.129,0,0,0-3.125-3.125h-.939V4.59A4.644,4.644,0,0,0,8.749,0,4.644,4.644,0,0,0,4.061,4.59V7.345H3.125A3.129,3.129,0,0,0,0,10.47v6.406A3.129,3.129,0,0,0,3.125,20H9.3a.781.781,0,1,0,0-1.563ZM5.624,4.59A3.08,3.08,0,0,1,8.749,1.564,3.08,3.08,0,0,1,11.874,4.59V7.345H5.624V4.59Z" transform="translate(0 -0.001)" />
                                                                        <path fill={theme.palette.primary.main} d="M272.439,322.145a.781.781,0,0,0-1.09.18L267.5,327.7a.446.446,0,0,1-.642.033l-2.489-2.429a.781.781,0,0,0-1.091,1.118l2.493,2.433.007.007a2.014,2.014,0,0,0,1.386.554q.066,0,.133,0a2.014,2.014,0,0,0,1.432-.746l.026-.035,3.866-5.4A.781.781,0,0,0,272.439,322.145Z" transform="translate(-252.765 -309.421)" />
                                                                        <ellipse fill={theme.palette.primary.main} cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(8.999 13)" />
                                                                        <ellipse fill={theme.palette.primary.main} cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(2.999 13)" />
                                                                        <circle fill={theme.palette.primary.main} cx="0.5" cy="0.5" r="0.5" transform="translate(6.999 13)" />
                                                                    </g>
                                                                </svg>
                                                            </IconButton>
                                                        </TooltipComponent>
                                                        <Typography component="span" className={classes.viewTxt}>
                                                            {(this.state.level1Requested || governance_sel[0]?.is_higher_requested) ? "Request Pending" : "Request Higher Access"}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                        <Typography className={classes.permissionText}>
                                            Provides access to Data that is classified as Public when the unauthorized disclosure, alteration or destruction of that data would result in little or no risk to the Organization and its affiliates.
                                        </Typography>
                                        <UserList
                                            expandList={(level) => this.expandList(level)}
                                            level="accessLevel1"
                                            isOPen={this.state.expandPermission.accessLevel1}
                                            users={this.state.users.filter((user) => user.governance === "Level 3" || user.governance === "Level 2" || user.governance === "Level 1" || user.governance === "None")}
                                            selectedList={this.state.userPermission.filter((item) => item.level === "accessLevel1" && item.name)}
                                            inputValue={this.state.inputProps.accessLevel1}
                                            onKeyDown={(event) => this.onKeyDown(event, "accessLevel1")}
                                            onChange={(event) => this.onChange(event, "accessLevel1")}
                                            onBlur={() => this.onBlurList("accessLevel1")}
                                            onRemoveUser={(name, type) => this.onRemoveUser(name, type)}
                                            onInputValueOnChange={(value) => this.onInputValueOnChange(value, "accessLevel1")}
                                            userRequests={this.state.requests.filter((item) => item.level === "accessLevel1")}
                                            actionRequest={(event, type, request) => this.actionRequest(event, type, request)}
                                            disabled={!isEditable}
                                            showAdd
                                            count={3}
                                            updating={this.state.updating} />
                                    </Grid>
                                </Grid>
                                <Grid item xs={!hideplatform || showComment ? 8 : 12}>
                                    <Grid className={classNames(classes.cardContainer, classes.txteditorContainer)}>
                                        <Typography className={classes.permissionTitleText}>
                                            User Terms
                                        </Typography>
                                        <CKEditor
                                            className={classes.txteditor}
                                            disabled={!isEditable}
                                            onInit={(editor) => editor.setData(source.terms_description ? source.terms_description : '')}
                                            onChange={
                                                (event, editor) => {
                                                    onChange('terms_description', editor.getData());
                                                }
                                            }
                                            onBlur={() => onSave('terms_description')}
                                            config={
                                                {
                                                    placeholder: "Enter User Terms",
                                                    toolbar: ['bold', 'italic', 'underline', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
                                                }
                                            }
                                            editor={InlineEditor}
                                            data={source.terms_description ? source.terms_description : ''}
                                        />
                                    </Grid>
                                </Grid>
                                {
                                    !hideplatform &&
                                    <Grid item xs={4}>
                                        <Grid className={classes.cardContainer}>
                                            <Typography className={classes.permissionTitleText}>
                                                Platform Use
                                            </Typography>
                                            <Typography className={classes.permissionText}>
                                                Defines the level of use in the Data Platform. Validate means the source will be use only for checks while Append for Data Output and Report for consolidation of Data with other sources.
                                            </Typography>
                                            <FormGroup className={classes.formGroup}>
                                                {
                                                    this.state.fields.map((field, index) =>
                                                        <Grid key={`chk${index}`} className={classNames(classes.inlineBlock, classes.paddingRight15)}>
                                                            <CheckboxComponent disabled={!isEditable} onChange={((event) => this.onChangePlatformUse(field, event.target.checked))} checked={source.platform_use ? source.platform_use.indexOf(field) !== -1 : false} name={field} checkboxLabel={field} />
                                                        </Grid>
                                                    )
                                                }

                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    showComment &&
                                    <Grid item xs={4}>
                                        <Grid className={classes.cardContainer}>
                                            <Typography className={classes.permissionTitleText}>
                                                Comment
                                            </Typography>
                                            <Typography className={classes.permissionText}>
                                                {comment ? comment : ""}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid >
                        :
                        <Grid className={classes.stewardContainer}>
                            <UserList
                                expandList={(level) => this.expandList(level)}
                                level="steward"
                                isOPen={this.state.expandPermission.steward}
                                users={this.state.users}
                                selectedList={this.state.userPermission.filter((item) => item.isStewardUser && item.name)}
                                inputValue={this.state.inputProps.steward}
                                onKeyDown={(event) => this.onKeyDown(event, "steward")}
                                onChange={(event) => this.onChange(event, "steward")}
                                onBlur={() => this.onBlurList("steward")}
                                onInputValueOnChange={(value) => this.onInputValueOnChange(value, "steward")}
                                onRemoveUser={(name, type) => this.onRemoveUser(name, type)}
                                userRequests={this.state.requests.filter((item) => item.isStewardUser)}
                                actionRequest={(event, type, request) => this.actionRequest(event, type, request)}
                                disabled={!isEditable}
                                count={1}
                                updating={this.state.updating}
                            />
                        </Grid>
                }
                <Popover
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.closeRequestAccess()}
                    anchorOrigin={
                        {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                    transformOrigin={
                        {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    }
                >
                    <Grid className={classes.requestPopup}>
                        <Typography className={classNames(classes.marginBottom5)} component="h5" variant="h5" >
                            Request Higher Access
                        </Typography>
                        <ValidatorForm name="comments" onSubmit={() => this.requestAccess(this.state.requestLevel, governance_sel)}>
                            <TextBox
                                name="description"
                                placeholder="Enter Description"
                                multiline
                                value={this.state.requestDesc}
                                rows="3"
                                onChange={(event) => this.onChangeDesc(event.target.name, event.target.value)}
                                fullWidth
                                className={classNames(classes.inlinetxt, classes.descriptionTextBox, classes.marginBottom10)}
                            />
                            <Button type="submit" variant="contained" color="primary" size="large">
                                Request
                            </Button>
                        </ValidatorForm>
                    </Grid>
                </Popover>

            </Grid>

        );
    }
}

Permission.propTypes = {
    classes: PropTypes.object,
    source: PropTypes.object,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    users: PropTypes.array,
    createSourceShare: PropTypes.func,
    inviteSourceUser: PropTypes.func,
    sourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    getAuditLogs: PropTypes.func,
    type: PropTypes.string,
    datasetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    governance: PropTypes.array,
    loading: PropTypes.bool,
    isEditable: PropTypes.bool,
    steward: PropTypes.bool,
    deleteShare: PropTypes.func,
    updateUser: PropTypes.func,
    dispatch: PropTypes.func,
    hideplatform: PropTypes.bool,
    hideAuditlog: PropTypes.bool,
    showComment: PropTypes.bool,
    comment: PropTypes.string,
    updateGovernance: PropTypes.func,
    isGovernanceUpdate: PropTypes.bool,
    updateGovernanceUpdate: PropTypes.func,
    theme: PropTypes.object,
    user: PropTypes.object,
    userDetail: PropTypes.object
};

export default withStyles((theme) => ({
    ...PermissionStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Permission);