import React from 'react';
import { Grid, IconButton, TextField } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// import AutoComplete from '../AutoComplete/AutoComplete.jsx';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import { getOperators } from './QueryBuilderUtil.jsx';
import ValueEditor from './Widgets/ValueEditor.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const Rule = (props) => {
    const { rule, id, parentId, builderConfig, theme, defaultFieldType } = props;
    const { operators, onPropertyChange, onRuleRemove, classes } = builderConfig;

    ValidatorForm.addValidationRule('dateValidation', (value, params) => {
        const validationParams = params ? params.split(',') : [];
        if (validationParams.length < 1) {
            throw Error('Invalid validation params');
        }
        const format = validationParams[0];
        const disableValidation = validationParams.length > 1 ? (validationParams[1] === 'true') : false;
        if (disableValidation) {
            return true;
        }
        const dateValue = moment(new Date(value), format);
        return dateValue.isValid();
    });


    const onElementChanged = (property, value) => {
        onPropertyChange(property, value, id);
    };

    const onOperatorChanged = (value, property) => {
        onElementChanged(property ? property : 'operator', value);
    };

    const onValueChanged = (value, property) => {
        onElementChanged(property ? property : 'value', value);
    };


    const removeRule = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onRuleRemove(id, parentId);
    };


    const getRuleOperators = (rule) => {
        let selectedOperators = [];
        if (!rule) {
            return selectedOperators;
        }
        rule.attributeType = defaultFieldType ? defaultFieldType : "text";
        rule.fieldType = defaultFieldType ? defaultFieldType : "text";
        selectedOperators = [...operators, ...getOperators(rule.attributeType.toLowerCase(), false)];
        return selectedOperators;
    };

    return (
        <Grid container direction="row" wrap="nowrap" justify="space-between" alignItems="center" className={classNames(classes.rule, classes.scroll)}>
            <Grid item className={classes.ruleContainer}>
                <Grid container direction="row" alignItems="center" wrap="nowrap">
                    <Grid className={classes.marginRight10}>
                        <AutoCompleteInput
                            name="operator"
                            popperWidth={300}
                            options={[...getRuleOperators(rule)]}
                            value={rule.operator && rule.operator.label ? rule.operator.label : ''}
                            getOptionLabel={
                                (option) => {
                                    if (option && option.label) {
                                        return option.label;
                                    }
                                    return option;
                                }
                            }
                            getOptionSelected={(option, value) => option.label === value}
                            renderInput={
                                (params) => <TextField {...params}
                                    className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                    placeholder="Operator" />
                            }
                            onChange={(_, selectedValue) => onOperatorChanged(selectedValue)}
                            // disabled={!rule.field}
                            openOnFocus
                            blurOnSelect
                            disableClearable
                            fullWidth
                            forcePopupIcon={false}
                            noOptionsText={""}
                        />
                    </Grid>
                    {
                        // Value editor for simple rules
                        rule.operator && rule.operator.valueRequired && (!rule.isComplexRule || rule.operator.range) &&
                        <Grid className={classes.marginRight10}>
                            <ValueEditor rule={{ ...rule }}
                                onChange={(...props) => onValueChanged(...props)}
                                onOperatorChanged={(...props) => onOperatorChanged(...props)}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>

            <Grid item>
                <ToolTipComponent title="Remove Rule" arrow>
                    <IconButton className={classes.actionIconButton} onClick={(event) => removeRule(event)}>
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="16.408" height="16" viewBox="0 0 19.408 22">
                        <g transform="translate(-76.035 -52)">
                            <path d="M128.865,166H114.641a.549.549,0,0,0-.536.61l1.459,12.768a2.789,2.789,0,0,0,2.77,2.474h7.082a2.777,2.777,0,0,0,2.775-2.525L129.4,166.6a.554.554,0,0,0-.138-.421A.546.546,0,0,0,128.865,166Zm-1.748,13.219a1.708,1.708,0,0,1-1.7,1.555h-7.082a1.715,1.715,0,0,1-1.7-1.523l-1.39-12.173h13.028Z" transform="translate(-36.014 -107.853)" fill={theme.palette.primary.main} />
                            <path d="M94.9,54.7H89.19v-1.03A1.682,1.682,0,0,0,87.51,52H83.968a1.682,1.682,0,0,0-1.68,1.667V54.7H76.574a.539.539,0,0,0,0,1.078H94.9a.539.539,0,0,0,0-1.078Zm-6.792,0H83.366v-1.03a.6.6,0,0,1,.6-.588H87.51a.6.6,0,0,1,.6.588Z" fill={theme.palette.primary.main} />
                        </g>
                    </svg> */}
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 32 32">
                            <g id="Delete">
                                <path fill={theme.palette.primary.main}
                                    stroke={theme.palette.primary.main}
                                    strokeWidth="0.92"
                                    strokeMiterlimit="10"
                                    d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                <path fill={theme.palette.primary.main}
                                    stroke={theme.palette.primary.main}
                                    strokeWidth="0.92"
                                    strokeMiterlimit="10"
                                    d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                            </g>
                        </svg>
                    </IconButton>
                </ToolTipComponent>
            </Grid>
        </Grid>
    );
};

Rule.propTypes = {
    rule: PropTypes.object,
    id: PropTypes.string,
    parentId: PropTypes.string,
    builderConfig: PropTypes.object,
    theme: PropTypes.object,
    defaultFieldType: PropTypes.string
};

export default Rule;