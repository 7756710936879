import React, { useState, useCallback } from 'react';
import { withStyles, Dialog, DialogTitle, DialogContent, Grid, Typography, IconButton, Slide, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Close } from '@material-ui/icons';
import classNames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import DomainDataPreviewStyles from './DomainDataPreviewStyles.jsx';
import { previewInvalidData } from '../../actions/scheduleActions.js';
import DomainDataTable from './DomainDataTable.jsx';
import Loader from '../Loaders/Loader.jsx';
import NoResultImg from '../../assets/images/NoDataset.png';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
// import TextBox from '../TextBox/TextBox.jsx';
import Search from '../TextBox/Search.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import { ExportJsonCsv } from 'react-export-json-csv';

const Transition = React.forwardRef(Object.assign((props, ref) => <Slide direction="up" ref={ref} {...props} />), { displayName: 'Transition' });


const DomainDataPreview = (props) => {
    const { classes, open, params, onClose, datasets, theme } = props;
    const [searchKey, setSearchKey] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [selectedDataset, setSelectedDatasetId] = useState({});
    const [invalidHeader, setinvalidHeader] = useState([]);
    const dispatch = useDispatch();
    const [previewData, setPreviewData] = useState({
        userAccessLevel: 1,
        headers: [],
        attributes: {},
        inputData: [],
        totalRecords: 0,
        count: 0
    });

    const displayName = useCallback(() => {
        if (selectedDataset && Object.keys(selectedDataset).length > 0) {
            const dataset = datasets.find((dataset) => dataset.id === selectedDataset.id);
            return dataset && dataset.name ? ` ${dataset.name}` : "Untitled Dataset";
        }
        return params.name ? `${params.name}` : "";
    }, [datasets, params.name, selectedDataset]);

    const getPreviewData = useCallback((datasetId) => {
        const model = {
            type: datasetId ? "dataset" : params.type,
            "dataset_id": datasetId ? datasetId : params.datasetId,
            "attribute_name": params.attributeName ? params.attributeName : 0,
            "rule_name": params.ruleName ? params.ruleName : '',
            polarity: params.polarity ? params.polarity : 'positive',
            metrics_type: params.metrics_type ? params.metrics_type : 'Invalid_Records',
            "method": "Domain"

        };
        setSearchKey('');
        setLoading(true);
        dispatch(previewInvalidData(params.domainId, model)).then((response) => {
            const headers = response && response.attributes ? response.attributes.map((attribute) => attribute.replace(/ /g, "_")) : [];
            if ((params.type === 'rule' || params.type === 'attribute') && params.attributeName.length > 0) {
                const attributeIndex = headers.findIndex((header) => header === params.attributeName);
                if (attributeIndex > -1) {
                    headers.splice(attributeIndex, 1);
                    headers.splice(0, 0, params.attributeName);
                }
            }
            setinvalidHeader([]);
            const header_details = [];
            // eslint-disable-next-line func-names
            headers.forEach(function (value) {
                const temp_dict = {};
                temp_dict.key = value;
                temp_dict.name = value;
                header_details.push(temp_dict);
            });
            setinvalidHeader(header_details);
            setPreviewData({
                attributes: response && response.attribute_rules ? { ...response.attribute_rules } : {},
                inputData: response && response.preview_data ? [...response.preview_data] : [],
                headers: [...headers],
                userAccessLevel: response && response.user_access_level ? response.user_access_level : 1,
                totalRecords: response && response.total_count ? response.total_count : 0,
                invalidRows: response && response.invalid_rows ? [...response.invalid_rows] : [],
                count: response && response.count ? response.count : 0
            });
            setLoading(false);
        });
    }, [dispatch, params]);


    const onChangeDataset = useCallback((value) => {
        setSelectedDatasetId(value);
        getPreviewData(value.id);
    }, [getPreviewData]);

    const onOpenDialog = useCallback(() => {
        setSelectedDatasetId({});
        getPreviewData();
    }, [getPreviewData]);

    const headers = searchKey ? previewData.headers.filter((header) => header.toString().toLowerCase().includes(searchKey.toLowerCase())) : previewData.headers;

    return (
        <Dialog open={open}
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={Transition}
            className={classes.queryFilterDialog}
            onEnter={() => onOpenDialog()}
            onExit={() => onClose()}>
            <DialogTitle style={{ paddingBottom: 0 }}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6" className={classNames(classes.QueryfilterDataTitle)}>
                            {'Data Preview'}
                        </Typography>
                        <Typography variant="h6" className={classNames(classes.marginBottom5, classes.QueryfilterTitle)}>
                            {displayName()}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton className={classes.nopadding} onClick={() => onClose()}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ position: "relative" }} className={classes.scroll}>
                <Grid>
                    <Grid container justify="space-between" alignItems="center" style={{ marginTop: 4 }}>
                        <Grid item>
                            <Grid container alignItems="center" direction="row" style={{ margin: '3px 0' }}>
                                <Grid item className={classNames(classes.textboxContainer, classes.includeSearchContainer)}>
                                    {/* <TextBox
                                        name="Datasets"
                                        fullWidth
                                        select
                                        label="Datasets"
                                        onChange={(event) => onChangeDataset(event.target.value)}
                                        value={selectedDataset}
                                        className={classes.inlineTxt}
                                        SelectProps={
                                            {
                                                MenuProps: {
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "center"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "center"
                                                    },
                                                    getContentAnchorEl: null
                                                }
                                            }
                                        }
                                    >
                                        {
                                            datasets && datasets.map((dataset, index) => (
                                                <MenuItem key={`menuProperty_Options_${index}`} value={dataset.id} className={classes.menuItem}>
                                                    {dataset.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextBox> */}
                                    <AutoCompleteInput
                                        name="attribute"
                                        options={datasets ? datasets : []}
                                        value={selectedDataset ? selectedDataset : ''}
                                        // popperWidth={300}
                                        getOptionLabel={
                                            (option) => {
                                                if (option && option.name) {
                                                    return option.name;
                                                }
                                                return option;
                                            }
                                        }
                                        getOptionSelected={(option, value) => option.name === value}
                                        renderInput={
                                            (params) => <TextField {...params}
                                                className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                                placeholder="Dataset" />
                                        }
                                        onChange={(_, selectedValue) => onChangeDataset(selectedValue)}
                                        openOnFocus
                                        blurOnSelect
                                        disableClearable
                                        fullWidth
                                        forcePopupIcon={false}
                                        noOptionsText={""}
                                    />
                                </Grid>
                                {
                                    (!isLoading && previewData && previewData.headers.length > 0) &&
                                    <Grid item className={classes.marginLeft15}>
                                        <Typography>
                                            {`${previewData.totalRecords < previewData.count ? previewData.totalRecords : previewData.count} of ${previewData.totalRecords} records`}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>

                        </Grid>
                        <Grid item>
                            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                                <Grid item className={classNames(classes.includeSearchContainer)}>
                                    <Search
                                        value={searchKey}
                                        placeholder="Search Attributes"
                                        className={classes.dqSearchBox}
                                        onChange={(value) => setSearchKey(value)}
                                    />
                                </Grid>
                                {
                                    (!isLoading && headers && headers.length > 0) &&
                                    <Grid item style={{ marginLeft: 10 }}>
                                        <ExportJsonCsv headers={invalidHeader} items={previewData.inputData}>
                                            <ToolTipComponent title="Download" arrow>
                                                <IconButton className={classes.padding6}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 21 21">
                                                        <g id="download_4_" data-name="download (4)" transform="translate(-1.5 -1.5)">
                                                            <path id="Path_14400" data-name="Path 14400" d="M22,23.714v2.857A1.43,1.43,0,0,1,20.571,28H3.428A1.43,1.43,0,0,1,2,26.572V23.714a.714.714,0,0,1,1.428,0v2.857H20.571V23.714a.714.714,0,0,1,1.429,0Z" transform="translate(0 -6)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                                            <path id="Path_14401" data-name="Path 14401" d="M8.21,12.5a.714.714,0,1,1,1.01-1.01L13,15.275V2.714a.714.714,0,1,1,1.428,0V15.275l3.781-3.78a.714.714,0,1,1,1.01,1.01l-5,5a.715.715,0,0,1-1.011,0Z" transform="translate(-1.715)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                                        </g>
                                                    </svg>
                                                </IconButton>
                                            </ToolTipComponent>
                                        </ExportJsonCsv>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.previewContainer}>
                    {
                        (!isLoading && headers && headers.length > 0) &&
                        <DomainDataTable
                            headers={headers ? [...headers] : []}
                            previewData={previewData}
                        />
                    }
                    {
                        headers && headers.length === 0 && !isLoading &&
                        <Grid container alignItems="center" justify="center" className={classes.noResultFound}>
                            <Grid>
                                <img src={NoResultImg} alt="No Result Found" />
                                <Typography variant="h4" align="center">
                                    {'No Result Found'}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    {isLoading && <Loader />}

                </Grid>
            </DialogContent>
        </Dialog>
    );
};

DomainDataPreview.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    params: PropTypes.object,
    onClose: PropTypes.func,
    datasets: PropTypes.array,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...DomainDataPreviewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DomainDataPreview);