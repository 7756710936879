import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { driftRuleDashboard } from '../../actions/scheduleActions';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import { appConstants } from '../../constants/appConstants';
import PieChart from '../Charts/PieCharts.jsx';
import StockerBarChart from '../Charts/StockedBarChart.jsx';
import DriftDashBoardStyle from './DriftDashBoardStyle.jsx';
import { Grid, withStyles } from '@material-ui/core';

const DriftDashboard = (props) => {
    const { attributeId } = props;
    const [analysisData, setAnalysisData] = useState({});
    const [trendData, setTrendData] = useState([]);

    const dispatch = useDispatch();

    const getDashboardStatistics = useCallback(() => {
        setAnalysisData({});
        setTrendData([]);
        dispatch(driftRuleDashboard(attributeId)).then((response) => {
            if (response) {
                setAnalysisData({ ...response.data });
                const stackedDataList = [];
                const stackedData = response.stocked_bar_data ? response.stocked_bar_data : [];
                const keys = [...new Set(stackedData.map((data) => data.name))];
                for (const key of keys) {
                    const stackData = { "name": key };
                    const filterStackedData = stackedData.filter((data) => data.name === key);
                    const filterKeys = filterStackedData ? Object.keys(filterStackedData[0]).filter((data) => data !== "name") : {};
                    for (const data of filterKeys) {
                        const total = filterStackedData.reduce((a, b) => a + (b[data] || 0), 0);
                        stackData[data] = total;
                    }
                    stackedDataList.push({ ...stackData });
                }
                setTrendData([...stackedDataList]);
            }
        });
    }, [attributeId, dispatch]);

    useEffect(() => {
        getDashboardStatistics();
    }, [getDashboardStatistics]);


    return (
        <ChartContainer
            title={appConstants.charts.driftDashBoardStatistics.name}
            id={appConstants.charts.driftDashBoardStatistics.id}
            chartData={analysisData}
        >
            <Grid container spacing={3}>
                <Grid item xs={7} md={7}>
                    <StockerBarChart
                        chartData={trendData}
                        chartClassName="drift-stocked-chart"
                    />
                </Grid>
                <Grid item xs={5} md={5}>
                    {
                        analysisData && Object.keys(analysisData).length > 0 &&
                        <PieChart
                            chartData={analysisData}
                            chartClassName="drift-pie-chart"
                        />
                    }
                </Grid>
            </Grid>
        </ChartContainer>
    );
};

DriftDashboard.propTypes = {
    attributeId: PropTypes.number
};

export default withStyles((theme) => ({
    ...DriftDashBoardStyle(theme),
    ...Styles(theme)
}), { withTheme: true })(DriftDashboard);