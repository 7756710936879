import { searchActions } from '../constants/actionTypes/searchActionTypes';

const initialState = {
	isLoading: false,
	isLazyLoading: false,
	dashboard: {},
	suggestions: [],
	frequent_searchkeys: [],
	search_result: {
		All: [],
		Datasources: [],
		Datasets: [],
		Domains: [],
		Attributes: [],
		Workbooks: [],
		Worksheets: [],
		Comments: [],
		total_count: 0
	},
	filters: {
		tabIndex: 0,
		viewType: 'All',
		search: '',
		connectionType: [],
		sensitivity: [],
		users: [
			{
				isDefault: true,
				isSelected: true,
				isUser: false,
				name: 'All'
			}
		],
		dqScore: [0, 100],
		favourite: false,
		certified: false,
		offset: 0,
		sortBy: 'Relevant',
		tags: []
	},
	selectedAttr: ""

};

export const searchReducer = (state = initialState, action) => {
	if (!action || (action && !action.type)) {
		return state;
	}

	switch (action.type) {
		case searchActions.CLEAR_SERACH_RESULTS:
			return {
				...state,
				isLoading: true,
				isLazyLoading: false,
				search_result: {
					All: [],
					Datasources: [],
					Datasets: [],
					Domains: [],
					Attributes: [],
					Workbooks: [],
					Worksheets: [],
					Comments: [],
					total_count: 0
				}
			};
		case searchActions.UPDATE_SERACH_FILTERS:
			return {
				...state,
				isLoading: true,
				isLazyLoading: false,
				filters: { ...state.filters, ...action.responseData },
				search_result: {
					All: [],
					Datasources: [],
					Datasets: [],
					Domains: [],
					Attributes: [],
					Workbooks: [],
					Worksheets: [],
					Comments: [],
					total_count: 0
				}
			};
		case searchActions.UPDATE_SERACH_RESULT_OFFSET:
			return {
				...state,
				isLoading: false,
				isLazyLoading: true,
				filters: { ...state.filters, ...action.responseData }
			};
		case searchActions.GET_SEARCH_DASHBORAD_INFO_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case searchActions.UPDATE_SELECTED_ATTRIBUTE:
			return {
				...state,
				isLoading: true,
				selectedAttr: action.responseData
			};
		case searchActions.GET_SEARCH_DASHBORAD_INFO_SUCCESS:
			return {
				...state,
				dashboard: action.responseData.data,
				suggestions: action.responseData.suggestions,
				frequent_searchkeys: action.responseData.frequent_searchkeys,
				isLoading: false
			};
		case searchActions.GET_SEARCH_DASHBORAD_INFO_FAILURE:
			return {
				...state,
				dashboard: {},
				isLoading: false
			};
		case searchActions.GET_SEARCH_RESULT_COUNT_REQUEST:
			return {
				...state
			};
		case searchActions.GET_SEARCH_RESULT_COUNT_SUCCESS:
			return {
				...state
			};
		case searchActions.GET_SEARCH_RESULT_COUNT_FAILURE:
			return {
				...state
			};
		case searchActions.GET_SEARCH_RESULT_REQUEST:
			return {
				...state
			};
		case searchActions.GET_SEARCH_RESULT_SUCCESS:
			let result = { [state.filters.viewType]: state.search_result[state.filters.viewType] };
			if (action.responseData[state.filters.viewType]) {
				result = { [state.filters.viewType]: [...state.search_result[state.filters.viewType], ...action.responseData[state.filters.viewType]] };
			}
			const result_count = { 'total_count': action.responseData['total_count'] };
			return {
				...state,
				search_result: { ...state.search_result, ...result_count, ...result },
				isLoading: false,
				isLazyLoading: false
			};
		case searchActions.GET_SEARCH_RESULT_FAILURE:
			return {
				...state,
				isLoading: false,
				isLazyLoading: false
			};
		case searchActions.SAVE_SEARCH_TRACK_REQUEST:
			return {
				...state
			};
		case searchActions.SAVE_SEARCH_TRACK_SUCCESS:
			return {
				...state
			};
		case searchActions.SAVE_SEARCH_TRACK_FAILURE:
			return {
				...state
			};
		default:
			return state;
	}
};