import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, ButtonGroup, Button, TextField, withStyles, Popover, Typography } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import DataPreparationJoin from './DataPreparationJoin.jsx';
import { updateDataPreparation, getConfiguration, createConfiguration, updateConfiguration } from '../../actions/dataPreparationActions';
import { getOrganizationDatasets } from '../../actions/datasetActions';
import DataPreparationjoinBuilderModal from '../DataPreparationjoinBuilder/DataPreparationjoinBuilderModal.jsx';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import DataPreparationStyles from './DataPreparationStyles.jsx';
import TextBox from '../TextBox/TextBox.jsx';

const DataPreparationConfiguration = (props) => {
    const { classes, theme, dataPreparation, openDataPreivew, updatePreparationProperty, dataId } = props;
    const [selectedDatasets, setSelectedDatasets] = useState(dataPreparation && dataPreparation.dataset_configuration ? dataPreparation.dataset_configuration : []);
    const [datasets, setDatasets] = useState([]);
    const [open, setOpen] = useState(false);
    const [type, setType] = useState(dataPreparation && dataPreparation.configuration && dataPreparation.configuration.is_join ? "join" : "");
    const [joinDatasets, setJoinDatasets] = useState([]);
    const [isJoinPerform, setJoinPerform] = useState(false);
    const [transformConfiguration, setTransformConfiguration] = useState([]);
    const [isFetchDataset, setFetchDataset] = useState(false);
    const dispatch = useDispatch();
    const [cloneOpen, setCloneOpen] = useState(false);
    const [cloneAnchorEl, setCloneAnchorEl] = useState(false);
    const [cloneParams, setCloneParams] = useState({});

    const getDatasets = useCallback(() => {
        dispatch(getOrganizationDatasets()).then((response) => {
            if (response) {
                const datasetConfiguration = (dataPreparation && dataPreparation.dataset_configuration) ? dataPreparation.dataset_configuration : [];
                const configuration = datasetConfiguration.map((data) => data.id);
                response = response.filter((data) => !configuration.includes(data.id));
                setDatasets([...response]);
            }
            setFetchDataset(true);
        });
    }, [dataPreparation, dispatch]);

    const getDatasetConfiguration = useCallback(() => {
        let datasetConfig = [];
        for (const config of transformConfiguration) {
            if (config.configuration && config.configuration.is_join) {
                const configuration = config.source_datasets.map((data) => {
                    return {
                        "start": data.id.toString(),
                        "end": "join",
                        "is_source": data.is_source ? data.is_source : false
                    };
                });
                if (config.join_configuration && Object.keys(config.join_configuration).length > 0) {
                    configuration.push({
                        "start": "join",
                        "end": "target_dataset"
                    });
                }
                datasetConfig = [...datasetConfig, ...configuration];
            } else if (config.configuration && !config.configuration.is_join) {
                datasetConfig.push({
                    "start": config.target_dataset ? config.target_dataset.id.toString() : "",
                    "end": config.target_dataset ? `${config.target_dataset.id.toString()}_1_target` : "",
                    "is_source": true
                });
            }
        }
        setJoinDatasets([...datasetConfig]);
    }, [transformConfiguration]);


    const getDataPreparationConfiguration = useCallback(() => {
        dispatch(getConfiguration(dataPreparation.id)).then((response) => {
            if (response) {
                setTransformConfiguration([...response]);
                const join = response.some((data) => data.configuration && data.configuration.is_join);
                const joinPerform = response.some((data) => data.configuration && data.configuration.is_join && Object.keys(data.join_configuration).length > 0);
                setJoinPerform(joinPerform);
                if (join) {
                    setType('join');
                }
            }
        });
    }, [dataPreparation.id, dispatch]);

    useEffect(() => {
        if (transformConfiguration.length <= 0 && !isFetchDataset) {
            getDataPreparationConfiguration();
        }
        if (joinDatasets.length <= 0 && !isFetchDataset) {
            getDatasetConfiguration();
        }
        if (!isFetchDataset) {
            getDatasets();
        }

    }, [getDatasets, getDataPreparationConfiguration, getDatasetConfiguration, transformConfiguration.length, joinDatasets.length, isFetchDataset]);


    const updateProperty = useCallback((requestParams) => {
        dispatch(updateDataPreparation(dataPreparation.id, requestParams));
    }, [dataPreparation.id, dispatch]);

    const selectDataset = useCallback((dataset) => {
        selectedDatasets.push({ ...dataset });
        setSelectedDatasets([...selectedDatasets]);
        const datasetList = datasets.filter((data) => data.id !== dataset.id);
        const requestParams = {
            'dataset_configuration': selectedDatasets
        };
        updateProperty(requestParams);
        updatePreparationProperty(requestParams);
        setDatasets([...datasetList]);
    }, [updateProperty, datasets, selectedDatasets, updatePreparationProperty]);

    const createTransformDataset = useCallback((transformation) => {
        dispatch(createConfiguration(transformation)).then((response) => {
            if (response) {
                transformConfiguration.push({ ...response });
                setTransformConfiguration([...transformConfiguration]);
            }
        }, []);
    }, [dispatch, transformConfiguration]);

    const updateTransformDataset = useCallback((transformation) => {
        dispatch(updateConfiguration(transformation.id, transformation)).then((response) => {
            if (response) {
                transformConfiguration.push({ ...response });
                setTransformConfiguration([...transformConfiguration]);
            }
        }, []);
    }, [dispatch, transformConfiguration]);

    const addDatasetTarget = (dataset) => {
        const datasetInfo = selectedDatasets.find((data) => data.id === parseInt(dataset.id));
        const targetConfiguration = {
            "configuration": { "is_join": false },
            "source_datasets": [{ ...datasetInfo, "is_source": true }],
            "target_dataset": { ...datasetInfo, "target_name": `${datasetInfo.name}_1` },
            "join_configuration": {},
            "data_preparation_id": dataId
        };
        joinDatasets.push({ start: datasetInfo.id.toString(), end: `${datasetInfo.id}_1_target`, 'is_source': true });
        setJoinDatasets([...joinDatasets]);
        createTransformDataset(targetConfiguration);
    };

    const setConfiguration = useCallback((value) => {
        setType(value);
        if (value === "join") {
            const targetConfiguration = {
                "configuration": { "is_join": true, 'join_datasets': [] },
                "source_datasets": [],
                "target_dataset": {},
                "join_configuration": {},
                "data_preparation_id": dataId
            };
            createTransformDataset(targetConfiguration);
        }
    }, [dataId, createTransformDataset]);


    const getSourceDataset = useCallback(() => {
        const configuration = transformConfiguration.find((transform) => transform.configuration && transform.configuration.is_join);
        return configuration && configuration.configuration && configuration.configuration.join_datasets ? configuration.configuration.join_datasets.find((dataset) => dataset.is_source) : {};
    }, [transformConfiguration]);

    const addConfigureDataset = ({ start, end }) => {
        if (end !== "join" && start && start !== "") {
            return false;
        }
        const isSourceDataset = joinDatasets.some((dataset) => dataset.end === "join");
        const joinDataset = { start, end, "is_source": isSourceDataset };
        joinDatasets.push({ ...joinDataset });
        updateJoinProperty([...joinDatasets]);
        setJoinDatasets([...joinDatasets]);

    };

    const updateJoinProperty = useCallback((joinDataseList) => {
        const transformConfig = transformConfiguration.find((transform) => transform.configuration && transform.configuration.is_join);
        if (transformConfig) {
            joinDataseList = joinDataseList.filter((data) => data.end === "join");
            const configureDatasets = joinDataseList.map((dataset) => {
                const datasetInfo = selectedDatasets.find((data) => data.id === parseInt(dataset.start));
                return {
                    ...datasetInfo,
                    "is_source": dataset.is_source
                };
            });
            transformConfig["source_datasets"] = configureDatasets;
            transformConfig["data_preparation_id"] = dataId;
            transformConfig.configuration = { ...transformConfig.configuration, "join_datasets": configureDatasets };
            updateTransformDataset(transformConfig);
        }
    }, [dataId, selectedDatasets, transformConfiguration, updateTransformDataset]);


    const openPopover = () => {
        const transformConfig = transformConfiguration.some((transform) => transform.configuration && transform.configuration.is_join && (transform.configuration.join_datasets && transform.configuration.join_datasets.length > 1));
        if (transformConfig) {
            setOpen(true);
        }
    };

    const onClose = () => {
        setOpen(false);
    };

    const getJoinQuery = () => {
        const joinConfig = transformConfiguration.find((transform) => transform.configuration && transform.configuration.is_join);
        return joinConfig ? joinConfig : {};
    };

    const joinPerform = (value) => {
        setJoinPerform(value);
        joinDatasets.push({
            "start": "join",
            "end": "target_dataset"
        });
        setJoinDatasets([...joinDatasets]);
    };

    const updateJoinQuery = (params, id) => {
        const index = transformConfiguration.findIndex((config) => config.id === id);
        if (index !== -1) {
            transformConfiguration[index] = {
                ...transformConfiguration[index],
                ...params
            };
            setTransformConfiguration([...transformConfiguration]);
        }
    };

    const openCloneModal = (event) => {
        setCloneOpen(true);
        setCloneAnchorEl(event.target);
    };

    const onChangeClone = (name, value) => {
        cloneParams[name] = value;
        setCloneParams({ ...cloneParams });
    };

    return (
        <Grid container justify="space-between">
            <Grid item xs={2}>
                <AutoCompleteInput
                    name="Select Dataset"
                    options={datasets ? datasets : []}
                    popperwidth={300}
                    getOptionLabel={
                        (option) => {
                            if (option && option.name) {
                                return option.name;
                            }
                            return option;
                        }
                    }
                    getOptionSelected={(option, value) => option.name === value}
                    renderInput={
                        (params) => <TextField {...params}
                            className={classNames(classes.autoCompleteInput, classes.fontSize13)}
                            placeholder="Select Dataset" />
                    }
                    onChange={(_, selectedValue) => selectDataset(selectedValue)}
                    openOnFocus
                    blurOnSelect
                    disableClearable
                    fullWidth
                    forcePopupIcon={false}
                    noOptionsText={""}
                />
            </Grid>
            {
                selectedDatasets.length > 1 &&
                <Grid item align="right">
                    <ButtonGroup className={classes.buttonGroup}>
                        <Button variant="contained" className={type === "bubble" ? 'active' : ''}>
                            <TooltipComponent title="Schedule" placement="bottom" arrow>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22">
                                    <circle fill={theme.palette.grey.default} cx="1" cy="1" r="1" transform="translate(12 11.999)" />
                                    <path fill={theme.palette.grey.default} d="M276,272.78l-4.718,4.708a.86.86,0,0,0-.215.359l-1.027,3.383a.859.859,0,0,0,1.052,1.078l3.469-.961a.86.86,0,0,0,.378-.22l4.708-4.7A2.578,2.578,0,0,0,276,272.78Zm-2.117,6.97-1.745.483.511-1.681,3.183-3.176,1.216,1.216Zm4.548-4.539-.167.166-1.215-1.215.166-.166a.859.859,0,0,1,1.216,1.215Z" transform="translate(-258.398 -260.338)" />
                                    <ellipse fill={theme.palette.grey.default} cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(8 15.999)" />
                                    <circle fill={theme.palette.grey.default} cx="1" cy="1" r="1" transform="translate(8 11.999)" />
                                    <circle fill={theme.palette.grey.default} cx="1" cy="1" r="1" transform="translate(8 7.999)" />
                                    <circle fill={theme.palette.grey.default} cx="1" cy="1" r="1" transform="translate(4 11.999)" />
                                    <ellipse fill={theme.palette.grey.default} cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(4 15.999)" />
                                    <circle fill={theme.palette.grey.default} cx="1" cy="1" r="1" transform="translate(4 7.999)" />
                                    <ellipse fill={theme.palette.grey.default} cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(16 7.999)" />
                                    <path fill={theme.palette.grey.default} d="M18.562,1.719H17.445V.859a.859.859,0,0,0-1.719,0v.859h-3.91V.859a.859.859,0,1,0-1.719,0v.859H6.23V.859a.859.859,0,1,0-1.719,0v.859H3.437A3.441,3.441,0,0,0,0,5.156V18.562A3.441,3.441,0,0,0,3.437,22H8.68a.859.859,0,1,0,0-1.719H3.437a1.721,1.721,0,0,1-1.719-1.719V5.156A1.721,1.721,0,0,1,3.437,3.437H4.512V4.3a.859.859,0,1,0,1.719,0V3.437H10.1V4.3a.859.859,0,1,0,1.719,0V3.437h3.91V4.3a.859.859,0,0,0,1.719,0V3.437h1.117a1.721,1.721,0,0,1,1.719,1.719V9.023a.859.859,0,1,0,1.719,0V5.156A3.441,3.441,0,0,0,18.562,1.719Z" />
                                    <circle fill={theme.palette.grey.default} cx="1" cy="1" r="1" transform="translate(12 7.999)" />
                                </svg>
                            </TooltipComponent>
                        </Button>
                        <Button variant="contained" className={type === "bubble" ? 'active' : ''} onClick={() => setType('bubble')} disabled>
                            <TooltipComponent title="Handle" placement="bottom" arrow>
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.23" height="20" viewBox="0 0 19.23 20">
                                    <path id="hand-tool" d="M29.083,5.91V8.861a.782.782,0,0,1-1.563,0V5.91a.684.684,0,0,0-1.368,0V8.861a.782.782,0,0,1-1.563,0V3.995a.684.684,0,0,0-1.368,0V8.861a.782.782,0,0,1-1.563,0V2.236a.684.684,0,0,0-1.368,0V8.861a.782.782,0,0,1-1.563,0V3.643a.684.684,0,0,0-1.368,0v8.305a.782.782,0,0,1-1.131.7,11.088,11.088,0,0,0-3.4-1.343h-.005c-1.053-.229-1.254.265-1.33.451a.68.68,0,0,0-.013.567.955.955,0,0,0,.517.43q.033.013.064.03a9.28,9.28,0,0,1,3.637,3.14c.133.172.27.349.418.537a5.168,5.168,0,0,0,4.075,1.976h3.427a3.912,3.912,0,0,0,3.221-1.694.782.782,0,1,1,1.287.887A5.475,5.475,0,0,1,23.611,20H20.185a6.76,6.76,0,0,1-5.3-2.57c-.154-.194-.293-.375-.429-.55a7.731,7.731,0,0,0-3.09-2.693,2.328,2.328,0,0,1-.039-4.325,3.118,3.118,0,0,1,1.824-.084h.005a9.686,9.686,0,0,1,2.64.918V3.643a2.256,2.256,0,0,1,3.041-2.1,2.251,2.251,0,0,1,4.354.323,2.259,2.259,0,0,1,2.951,1.908A2.259,2.259,0,0,1,29.083,5.91Zm0,8.618V11.792a.782.782,0,0,0-1.563,0v2.736a.782.782,0,1,0,1.563,0Z" transform="translate(-9.854 0.001)" fill={type === "hand" ? theme.palette.background.paper : theme.palette.grey.default} />
                                </svg>
                            </TooltipComponent>
                        </Button>
                        <Button variant="contained" className={type === "join" ? 'active' : ''} onClick={() => setConfiguration('join')}>
                            <TooltipComponent title="Join" placement="bottom" arrow>
                                <svg xmlns="http://www.w3.org/2000/svg" id="connection" width="20" height="20" viewBox="0 0 20 20">
                                    <path id="Path_2363" data-name="Path 2363" d="M14.154,14.277l.954,1.186-3.2,3.2-1.187-.97a8.72,8.72,0,0,1-1.84.759L8.72,20H5.9V17.617H3.408a1.758,1.758,0,1,1,0-1.172H5.9V15.273h.586a5.273,5.273,0,1,0,0-10.547H5.9V3.555H3.408a1.758,1.758,0,1,1,0-1.172H5.9V0H8.773l.152,1.563a8.7,8.7,0,0,1,1.837.767l1.186-.954,3.2,3.2-.972,1.187a8.728,8.728,0,0,1,.759,1.84l1.551.164v4.523l-1.563.152a8.693,8.693,0,0,1-.767,1.837Zm0,0" transform="translate(3.516 0)" fill={type === "join" ? theme.palette.background.paper : theme.palette.grey.default} />
                                    <path id="Path_2364" data-name="Path 2364" d="M227.758,211a1.755,1.755,0,0,1,1.65,1.172h7.764v1.172h-7.764a1.755,1.755,0,1,1-1.65-2.344Zm0,0" transform="translate(-226 -202.758)" fill={type === "join" ? theme.palette.background.paper : theme.palette.grey.default} />
                                    <path id="Path_2365" data-name="Path 2365" d="M152.623,91a1.76,1.76,0,0,1,1.893,1.753,1.722,1.722,0,0,1-.022.213l1.918.959h5.073a3.617,3.617,0,0,1,3.617,3.617,3.415,3.415,0,0,1-3.415,3.415h-5.275l-1.918.959a1.722,1.722,0,0,1,.022.213,1.767,1.767,0,1,1-.541-1.264l2.16-1.08h5.451a2.344,2.344,0,1,0,0-4.687h-5.451l-2.16-1.08A1.756,1.756,0,1,1,152.623,91Zm0,0" transform="translate(-151 -87.445)" fill={type === "join" ? theme.palette.background.paper : theme.palette.grey.default} />
                                </svg>
                            </TooltipComponent>
                        </Button>
                        <Button variant="contained" className={type === "clone" ? 'active' : ''} onClick={(event) => openCloneModal(event)}>
                            <TooltipComponent title="Clone" placement="bottom" arrow>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19">
                                    <path id="pathfinder" d="M20.091,7.045H14.636V1.864A.887.887,0,0,0,13.727,1H1.909A.887.887,0,0,0,1,1.864V13.091a.887.887,0,0,0,.909.864H7.364v5.182A.887.887,0,0,0,8.273,20H20.091A.887.887,0,0,0,21,19.136V7.909A.887.887,0,0,0,20.091,7.045ZM2.818,12.227v-9.5h10V7.045H8.273a.887.887,0,0,0-.909.864v4.318Zm10-3.455v3.455H9.182V8.773Zm6.364,9.5h-10V13.955h4.545a.887.887,0,0,0,.909-.864V8.773h4.545Z" transform="translate(-1 -1)" fill={type === "path" ? theme.palette.background.paper : theme.palette.grey.default} />
                                </svg>
                            </TooltipComponent>
                        </Button>
                    </ButtonGroup>
                </Grid>
            }
            <DataPreparationJoin
                openDataPreivew={(type, source) => openDataPreivew(type, source)}
                datasets={selectedDatasets}
                arrows={joinDatasets}
                datasetConfigurations={transformConfiguration}
                addDatasetTarget={(dataset) => addDatasetTarget(dataset)}
                addArrow={(start, end) => addConfigureDataset(start, end)}
                type={type}
                isJoinPerform={isJoinPerform}
                openPopover={(event) => openPopover(event)}
            />
            {
                open &&
                <DataPreparationjoinBuilderModal
                    open={open}
                    setJoinPerform={(value) => joinPerform(value)}
                    sourceDataset={getSourceDataset()}
                    dataPreparation={getJoinQuery()}
                    onClose={() => onClose()}
                    updatePreparationProperty={(params, id) => updateJoinQuery(params, id)} />
            }
            {
                cloneOpen &&
                <Popover
                    open={cloneOpen}
                    anchorEl={cloneAnchorEl}
                    anchorOrigin={
                        {
                            vertical: 'center',
                            horizontal: 'center'
                        }
                    }
                    transformOrigin={
                        {
                            vertical: 'center',
                            horizontal: 'center'
                        }
                    }
                    onClose={() => { setCloneOpen(false); setCloneParams({}); }}
                >
                    <Grid container className={classes.cloneModalContainer}>
                        <Typography component="h6" variant="h6">
                            Clone
                        </Typography>
                        <Grid item className={classes.marginTop15} xs={12}>
                            <TextBox
                                label="Name"
                                name="name"
                                fullWidth
                                value={cloneParams.name ? cloneParams.name : ""}
                                onChange={(event) => onChangeClone(event.target.name, event.target.value)}
                            />
                        </Grid>
                        <Grid item align="right" xs={12} className={classes.marginTop15}>
                            <Grid container direction="row" justify="flex-end">
                                <Grid item className={classes.btnContainer}>
                                    <Button variant="contained"
                                        onClick={() => { setCloneOpen(false); setCloneParams({}); }}
                                        color="primary"
                                        style={{ marginRight: 20 }}
                                        className={classNames(classes.actionButton)}>
                                        {'Submit'}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button className={classNames(classes.cancelButton, classes.actionButton)}
                                        onClick={() => { setCloneOpen(false); setCloneParams({}); }}>
                                        {'Cancel'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Popover>
            }
        </Grid>
    );
};


DataPreparationConfiguration.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    dataPreparation: PropTypes.object,
    openDataPreivew: PropTypes.func,
    updatePreparationProperty: PropTypes.func,
    dataId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default (withStyles((theme) => ({
    ...DataPreparationStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DataPreparationConfiguration));