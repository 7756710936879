import { datasourceActions } from '../constants/actionTypes/datasourceActionTypes';
import { datasetActions } from '../constants/actionTypes/datasetActionTypes';
import { catalogActions } from '../constants/actionTypes/catalogActionTypes';

const initialState = {
    isLoading: false,
    datasource: {},
    curationRules: [],
    organizationSource: [],
    userAttributes: [],
    datatypes: [],
    fieldTypes: [],
    references: [],
    connectionTypes: [],
    moduleConnectionTypes: [],
    selectedDatasourceId: 0,
    sharingInfo: [],
    domains: [],
    domainSharingInfo: [],
    jobDetail: {},
    datasourceNames: [],
    isLicensedValidated: false,
    isLicensed: false,
    isLicenseRequestLoading: true,
    rating: 0,
    attributeRating: 0,
    datasourceCatalogInfo: null,
    datasetCatalogInfo: null,
    attributesCatalogInfo: null,
    recentQueries: null,
    ddlQuery: null,
    frequentTables: null,
    frequentAttributes: null,
    frequentRoles: null,
    datasourceSchemaCatalog: null,
    tableConstraints: null,
    attributeMetadata: null,
    datasourceTabIndex: ''
};

export const datasourceReducer = (state = initialState, action) => {
    if (!action || (action && !action.type)) { return state; }
    switch (action.type) {
        case datasourceActions.GET_DATASOURCE_REQUEST:
        case datasourceActions.GET_DATASOURCE_FAILURE:
        case datasourceActions.UPDATE_DATASOURCE_REQUEST:
        case datasourceActions.UPDATE_DATASOURCE_SUCCESS:
        case datasourceActions.UPDATE_DATASOURCE_FAILURE:
        case datasourceActions.GET_AUDIT_LOGS_REQUEST:
        case datasourceActions.GET_AUDIT_LOGS_SUCCESS:
        case datasourceActions.GET_AUDIT_LOGS_FAILURE:
        case datasourceActions.CREATE_DATASOURCE_REQUEST:
        case datasourceActions.CREATE_DATASOURCE_SUCCESS:
        case datasourceActions.CREATE_DATASOURCE_FAILURE:
        case datasourceActions.DELETE_DATASOURCE_REQUEST:
        case datasourceActions.DELETE_DATASOURCE_SUCCESS:
        case datasourceActions.DELETE_DATASOURCE_FAILURE:
        case datasourceActions.GET_DATASET_REQUEST:
        case datasourceActions.GET_DATASET_SUCCESS:
        case datasourceActions.GET_DATASET_FAILURE:
        case datasourceActions.GET_CURATION_RULES_REQUEST:
        case datasetActions.GET_CONNECTION_TYPES_REQUEST:
        case datasetActions.GET_CONNECTION_TYPES_FAILURE:
        case datasetActions.GET_MODULE_CONNECTION_TYPES_REQUEST:
        case datasetActions.GET_MODULE_CONNECTION_TYPES_FAILURE:
        case datasetActions.GET_USER_ATTRIBUTES_REQUEST:
        case catalogActions.RUN_CATALOG_FAILURE:
        case catalogActions.RUN_CATALOG_SUCCESS:
        case catalogActions.RUN_CATALOG_REQUEST:
            return {
                ...state
            };
        case datasourceActions.GET_DATASOURCE_SUCCESS:
            return {
                ...state,
                datasource: action.responseData ? action.responseData : {}
            };
        case datasourceActions.GET_CURATION_RULES_SUCCESS:
            return {
                ...state,
                curationRules: action.responseData ? [...action.responseData] : []
            };
        case datasourceActions.GET_CURATION_RULES_FAILURE:
            return {
                ...state,
                curationRules: []
            };
        case datasourceActions.GET_DEFAULT_TYPES_SUCCESS:
            return {
                ...state,
                datatypes: action.responseData.datatypes ? [...action.responseData.datatypes] : [],
                fieldTypes: action.responseData.fieldtypes ? [...action.responseData.fieldtypes] : []
            };
        case datasourceActions.GET_ORGANIZATION_DATASOURCE_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case datasourceActions.GET_ORGANIZATION_DATASOURCE_SUCCESS:
            return {
                ...state,
                organizationSource: action.responseData ? action.responseData : [],
                isLoading: false
            };
        case datasourceActions.GET_ORGANIZATION_DATASOURCE_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case datasourceActions.GET_REFERENCE_RULES_SUCCESS:
            return {
                ...state,
                references: action.responseData ? [...action.responseData] : []
            };
        case datasourceActions.GET_REFERENCE_RULES_FAILURE:
            return {
                ...state,
                references: []
            };
        case datasourceActions.GET_CONNECTION_TYPES_SUCCESS:
            return {
                ...state,
                connectionTypes: action.responseData ? [...action.responseData] : []
            };
        case datasourceActions.GET_MODULE_CONNECTION_TYPES_SUCCESS:
            return {
                ...state,
                moduleConnectionTypes: action.responseData ? [...action.responseData] : []
            };
        case datasourceActions.SET_SELECTED_DATASOURCE_ID:
            return {
                ...state,
                selectedDatasourceId: action.datasourceId
            };
        case datasourceActions.GET_DATASET_SHARE_REQUEST:
        case datasourceActions.GET_DATASET_SHARE_FAILURE:
            return {
                ...state,
                sharingInfo: []
            };
        case datasourceActions.CHECK_LICENSE_REQUEST:
            return {
                ...state,
                isLicensed: true,
                isLicenseRequestLoading: true
            };
        case datasourceActions.CHECK_LICENSE_SUCCESS:
            return {
                ...state,
                isLicensedValidated: action.responseData === 1,
                isLicenseRequestLoading: false
            };
        case datasourceActions.CHECK_LICENSE_FAILURE:
            return {
                ...state,
                isLicenseRequestLoading: false
            };
        case datasourceActions.GET_SOURCE_SHARE_SUCCESS:
            return {
                ...state,
                sharingInfo: action.responseData ? action.responseData : []
            };

        case datasourceActions.GET_ORGANIZATION_ALL_DOMAIN_REQUEST:
        case datasourceActions.GET_ORGANIZATION_ALL_DOMAIN_FAILURE:
            return {
                ...state,
                domains: []
            };
        case datasourceActions.GET_ORGANIZATION_ALL_DOMAIN_SUCCESS:
            return {
                ...state,
                domains: [...action.responseData]
            };
        case datasourceActions.GET_DOMAIN_SOURCE_SHARE_SUCCESS:
            return {
                ...state,
                domainSharingInfo: [...action.responseData]
            };
        case datasourceActions.GET_DOMAIN_SOURCE_SHARE_REQUEST:
        case datasourceActions.GET_DOMAIN_SOURCE_SHARE_FAILURE:
            return {
                ...state,
                domainSharingInfo: []
            };
        case datasourceActions.UPDATE_JOB_STATUS:
            return {
                ...state,
                jobDetail: { ...action.data }
            };
        case datasourceActions.GET_ORGANIZATION_SOURCE_NAMES_REQUEST:
        case datasourceActions.GET_ORGANIZATION_SOURCE_NAMES_FAILURE:
            return {
                ...state,
                datasourceNames: []
            };
        case datasourceActions.GET_ORGANIZATION_SOURCE_NAMES_SUCCESS:
            const sources = action.responseData ? action.responseData.map((data) => data.name) : [];
            return {
                ...state,
                datasourceNames: [...sources]
            };
        case datasourceActions.CREATE_DATASOURCE_SHARE_SUCCESS:
            const users = action.responseData ? action.responseData : [];
            return {
                ...state,
                sharingInfo: [...users]
            };
        case datasourceActions.DELETE_DATASOURCE_SHARE_SUCCESS:
            const toDelete = action.responseData ? action.responseData : [];
            let currentList = state.sharingInfo;
            toDelete.map((removeUser) => {
                currentList = currentList.filter((user) => user.user_id !== removeUser);
                return currentList;
            });
            return {
                ...state,
                sharingInfo: [...currentList]
            };
        case datasourceActions.GET_SOURCE_RATING_FAILURE:
            return {
                ...state,
                rating: 0
            };
        case datasourceActions.GET_SOURCE_RATING_SUCCESS:
            return {
                ...state,
                rating: action.responseData ? action.responseData : 0
            };
        case datasourceActions.GET_ATTRIBUTE_RATING_SUCCESS:
            return {
                ...state,
                attributeRating: action.responseData ? action.responseData : 0
            };
        case catalogActions.GET_DATASOURCE_CATALOG_INFO_SUCCESS:
            return {
                ...state,
                datasourceCatalogInfo: { ...action.responseData }
            };
        case catalogActions.GET_DATASOURCE_CATALOG_INFO_FAILURE:
            return {
                ...state,
                datasourceCatalogInfo: null
            };
        case catalogActions.GET_DATASET_CATALOG_INFO_SUCCESS:
            return {
                ...state,
                datasetCatalogInfo: { ...action.responseData }
            };
        case catalogActions.GET_DATASET_CATALOG_INFO_FAILURE:
            return {
                ...state,
                datasetCatalogInfo: null
            };
        case catalogActions.GET_ATTRIBUTES_CATALOG_INFO_SUCCESS:
            return {
                ...state,
                attributesCatalogInfo: action.responseData ? [...action.responseData] : []
            };
        case catalogActions.GET_ATTRIBUTES_CATALOG_INFO_FAILURE:
            return {
                ...state,
                attributesCatalogInfo: null
            };
        case catalogActions.GET_RECENT_QUERIES_SUCCESS:
            return {
                ...state,
                recentQueries: action.responseData ? [...action.responseData] : []
            };
        case catalogActions.GET_RECENT_QUERIES_FAILURE:
            return {
                ...state,
                recentQueries: []
            };
        case catalogActions.GET_DDL_QUERY_SUCCESS:
            return {
                ...state,
                ddlQuery: action.responseData ? [...action.responseData] : []
            };
        case catalogActions.GET_DDL_QUERY_FAILURE:
            return {
                ...state,
                ddlQuery: []
            };
        case catalogActions.GET_DATASOURCE_CATALOG_TABLES_SUCCESS:
            return {
                ...state,
                frequentTables: action.responseData && action.responseData.frequent_tables ? [...action.responseData.frequent_tables] : [],
                frequentRoles: action.responseData && action.responseData.frequent_roles ? [...action.responseData.frequent_roles] : [],
                datasourceSchemaCatalog: action.responseData && action.responseData.schema_details ? [...action.responseData.schema_details] : []
            };
        case catalogActions.GET_DATASOURCE_CATALOG_TABLES_FAILURE:
            return {
                ...state,
                frequentTables: [],
                frequentRoles: [],
                datasourceSchemaCatalog: []
            };
        case catalogActions.GET_DATASET_CATALOG_TABLES_SUCCESS:
            return {
                ...state,
                frequentAttributes: action.responseData && action.responseData.frequent_columns ? [...action.responseData.frequent_columns] : [],
                tableConstraints: action.responseData && action.responseData.constraints_info ? [...action.responseData.constraints_info] : []
            };
        case catalogActions.GET_DATASET_CATALOG_TABLES_FAILURE:
            return {
                ...state,
                frequentAttributes: [],
                tableConstraints: []
            };
        case catalogActions.CLEAR_CATALOG_INFO:
            return {
                ...state,
                datasourceCatalogInfo: null,
                datasetCatalogInfo: null,
                attributesCatalogInfo: null,
                recentQueries: null,
                frequentTables: null,
                frequentAttributes: null,
                frequentRoles: null,
                datasourceSchemaCatalog: null,
                tableConstraints: null
            };
        case catalogActions.GET_TABLE_COLUMN_METADATA_SUCCESS:
            return {
                ...state,
                attributeMetadata: action.responseData ? [...action.responseData] : []
            };
        case catalogActions.GET_TABLE_COLUMN_METADATA_FAILURE:
            return {
                ...state,
                attributeMetadata: null
            };
        case datasetActions.GET_USER_ATTRIBUTES_SUCCESS:
            return {
                ...state,
                userAttributes: action.responseData ? [...action.responseData] : []
            };
        case datasetActions.GET_USER_ATTRIBUTES_FAILURE:
            return {
                ...state,
                userAttributes: []
            };
        case datasourceActions.UPDATE_TABINDEX_REQUEST:
            return {
                ...state
            };
        case datasourceActions.UPDATE_TABINDEX_SUCCESS:
            return {
                ...state,
                datasourceTabIndex: action.responseData
            };
        default:
            return state;
    }
};