import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import ReportConfiguration from './ReportConfiguration.jsx';
import ReportList from './ReportList.jsx';


const Report = (props) => {
    const { organizationId, onEditConfig, isConfiguration, onClose } = props;
    const [report, setReportId] = useState(null);


    const onEdit = (type, roleId, roleList) => {
        if (type === "Edit Report") {
            setReportId(roleId);
        } else {
            setReportId(null);
        }
        onEditConfig(type);
    };

    return (
        <Grid>
            {
                isConfiguration ?
                    <ReportConfiguration
                        reportId={report}
                        organizationId={organizationId}
                        onClose={() => onClose()}
                    />
                    :
                    <ReportList
                        organizationId={organizationId}
                        onEdit={(type, roleData, roleList) => onEdit(type, roleData, roleList)} />
            }
        </Grid>
    );
};

Report.propTypes = {
    organizationId: PropTypes.number,
    onEditConfig: PropTypes.func,
    isConfiguration: PropTypes.bool,
    onClose: PropTypes.func
};

export default Report;