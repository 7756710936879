import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Popover, withStyles, Typography } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import ScheduleMonthCalenderStyles from './ScheduleMonthCalenderStyles.jsx';
const ScheduleMonthCalender = (props) => {
    const { classes, onOpenPopover, anchorElement, onClose, onDateSelectionChange, selectedDays } = props;
    const [days] = useState([...Array.from({ length: 31 }, (i, index) => (index + 1).toString())]);

    return (
        <Popover open={onOpenPopover}
            anchorEl={anchorElement}
            onClose={() => onClose()}
            anchorOrigin={
                {
                    vertical: "bottom",
                    horizontal: "center"
                }
            }
            transformOrigin={
                {
                    vertical: "top",
                    horizontal: "center"
                }
            }>
            <Grid className={classes.calenderContainer}>
                {
                    days.map((day, index) =>
                        <Typography
                            onClick={() => onDateSelectionChange(day)}
                            key={`${day}-${index}`}
                            component="div"
                            className={
                                `${classes.dayContainer} 
                            ${selectedDays.indexOf(day.toString()) !== -1
                                    ? classes.daySelected : ''}`
                            }>
                            {day}
                        </Typography>
                    )
                }
            </Grid>
        </Popover>
    );
};
ScheduleMonthCalender.propTypes = {
    classes: PropTypes.object,
    onOpenPopover: PropTypes.bool,
    anchorElement: PropTypes.object,
    onDateSelectionChange: PropTypes.func,
    onClose: PropTypes.func,
    selectedDays: PropTypes.array
};

export default withStyles((theme) => ({
    ...ScheduleMonthCalenderStyles(theme),
    ...Styles(theme)
}))(ScheduleMonthCalender);