import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import Styles from '../../layouts/Styles.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';


const ConcatTransform = (props) => {
    const { classes, attributes, transformation, onUpdateTransformation } = props;
    const [transform, setTransform] = useState({
        inputAttributes: [],
        outputAttribute: '',
        delimiter: ''
    });

    const updateTransformation = useCallback((transform) => {
        if (transform.inputAttributes && transform.inputAttributes.length <= 0) {
            return;
        }
        if (onUpdateTransformation) {
            onUpdateTransformation({
                'input_attributes': transform.inputAttributes,
                'output_attribute': transform.outputAttribute ? transform.outputAttribute : '',
                params: {
                    delimiter: transform.delimiter
                }
            });
        }
    }, [onUpdateTransformation]);

    const loadTransform = useCallback((transform) => {
        const inputAttributes = transform && transform['input_attributes'] ? [...transform['input_attributes']] : [];
        setTransform({
            inputAttributes,
            delimiter: transform && transform.params && transform.params.delimiter ? transform.params.delimiter : '',
            outputAttribute: transform && transform['output_attribute'] ? transform['output_attribute'] : ''
        });
    }, []);

    const addAttribute = useCallback((attributes) => {
        transform.inputAttributes = [...attributes];
        setTransform({ ...transform });
    }, [transform]);

    const handleChange = useCallback((property, value) => {
        transform[property] = value;
        setTransform({ ...transform });
    }, [transform]);

    useEffect(() => {
        if (transformation) {
            return;
        }
        loadTransform(transformation);
    }, [loadTransform, transformation]);

    const getAttributes = useCallback(() => {
        const selectedAttributes = transform && transform.inputAttributes ? [...transform.inputAttributes] : [];
        const attributeList = [];
        for (const attribute of attributes) {
            if (attribute.isTransformAttribute) {
                continue;
            }
            const attributeIndex = selectedAttributes.findIndex((p) => p.name === attribute.name);
            if (attributeIndex === -1) {
                attributeList.push({ ...attribute });
            }
        }
        return attributeList;
    }, [attributes, transform]);


    return (
        <Grid cotainer direction="column" justify="flex-start">
            <Grid item>
                <AutoCompleteInput
                    name="attributes"
                    popperWidth={300}
                    options={[...getAttributes()]}
                    freeSolo
                    value={transform && transform.inputAttributes ? [...transform.inputAttributes] : []}
                    getOptionLabel={
                        (option) => {
                            if (option && option.name) {
                                return option.name;
                            }
                            return option;
                        }
                    }
                    renderInput={
                        (params) =>
                            <TextField {...params}
                                className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                placeholder="Input Attributes"
                                margin="normal"
                                onBlur={() => updateTransformation(transform)}
                            />
                    }
                    onChange={(_, selectedValue) => addAttribute(selectedValue)}
                    openOnFocus
                    disableCloseOnSelect
                    disableClearable
                    fullWidth
                    multiple
                    forcePopupIcon={false}
                    noOptionsText={"No Attributes Found"}
                />
            </Grid>
            <Grid item>
                <TextBox
                    name={'delimiter'}
                    value={transform.delimiter ? transform.delimiter : ''}
                    placeholder={'Delimiter'}
                    onChange={(event) => handleChange(event.target.name, event.target.value)}
                    onBlur={() => updateTransformation(transform)}
                    id="delimiter"
                    margin="normal"
                    fullWidth
                    className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                />
            </Grid>
            <Grid item>
                <TextBox
                    name={'outputAttribute'}
                    value={transform.outputAttribute ? transform.outputAttribute : ''}
                    placeholder={'Output Attribute'}
                    onChange={(event) => handleChange(event.target.name, event.target.value)}
                    onBlur={() => updateTransformation(transform)}
                    id="outputAttribute"
                    margin="normal"
                    fullWidth
                    className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                />
            </Grid>
        </Grid>
    );
};

ConcatTransform.propTypes = {
    classes: PropTypes.object,
    transformation: PropTypes.object,
    attributes: PropTypes.array,
    onUpdateTransformation: PropTypes.func
};

export default withStyles((theme) => ({
    ...Styles(theme)
}))(ConcatTransform);