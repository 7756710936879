const AutoCompleteStyles = (theme) => ({
    root: {
        flexGrow: 1,
        height: 250
    },
    container: {
        flexGrow: 1,
        position: 'relative',
        '& .MuiBackdrop-root': {
            opacity: '0 !important',
            zIndex: 1330
        }
    },
    paper: {
        position: 'fixed',
        zIndex: 1330,
        marginTop: theme.spacing(1),
        maxWidth: 'max-content',
        maxHeight: 200,
        overflowY: 'auto',
        width: 300
    },
    chip: {
        margin: theme.spacing(0.5, 0.25)
    },
    inputRoot: {
        flexWrap: 'wrap'
    },
    inputInput: {
        width: 'auto',
        flexGrow: 1,
        textTransform: 'capitalize',
        direction: 'ltr',
        textRendering: 'auto'
    },
    divider: {
        height: theme.spacing(2)
    },
    attributeCurateClass: {
        border: '1px solid #646666',
        height: '26px',
        padding: '1px 7px',
        fontSize: '13px',
        borderColor: '#e1e5e6',
        marginRight: '14px',
        borderRadius: '0'
    }
});

export default AutoCompleteStyles;