import React, { useEffect, useCallback, useState, memo } from 'react';
import { useSelector } from 'react-redux';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import ChartStyles from './ChartStyles.jsx';
import { getValue, wrapText, getCountChartRule } from '../../helpers/appHelpers';
import { appConstants } from '../../constants/appConstants.js';

const HorizontalStackedBarChart = (props) => {
    const { chartData, attribute, classes, chartClass, theme, chartType, name, chartWidth, yLabel, showAxis, changeTick, lengend, colors, id, onFilter, wrapWidth, tabIndex, attributeChange, attributeRules, countRules, barStack } = props;
    const [analysis, setAnalysis] = useState([]);

    // Get Selected Datasource connectionType
    const datasource = useSelector(({ datasource }) => datasource.datasource);

    const onFilterChange = useCallback((barValue) => {
        const inputParams = {
            chartType: (appConstants.charts[chartType].type === 'countstatistics') ? 'Count' : appConstants.charts[chartType].type,
            chartName: appConstants.charts[chartType].name,
            filterOptions: false,
            defaultQuery: '',
            scan: Boolean(datasource?.scan ?? false)
        };

        if (barValue) {
            if (chartType === 'customrule') {
                inputParams.data = barValue;
                inputParams.selectedAttribute = attribute;
                inputParams.ruleName = barValue.value.name;
                let rule = JSON.parse(JSON.stringify(barValue.value.ruleGroup));
                if (!rule) {
                    rule = {};
                }
                const not = (barValue.filterType === appConstants.QueryFilterTypes[0]);
                if (rule) {
                    rule.not = not;
                    if (rule.isComplexRule) {
                        rule.not = (barValue.filterType === appConstants.QueryFilterTypes[1]);
                    }
                }
                if (barValue.value && !barValue.value.is_user_defined) {
                    barValue.value.not = not;
                }
                if (barValue.value.is_user_defined && barValue.value.isQueryFilter && barValue.filterType === "Invalid") {
                    const attributeName = attribute && attribute.name ? attribute.name.toLowerCase() : '';
                    const ruleName = barValue.value && barValue.value.name ? barValue.value.name.toLowerCase().replace(/[^A-Za-z0-9_]/g, '_') : '';
                    let query = `select * from <table_name> where temp_row_index in(select distinct temp_row_index from <invalid_table_name> where attribute=='${attributeName}' and rule_name=='${ruleName}')`;
                    if (barValue?.value?.sql_query) {
                        query = barValue?.value?.rule_query ?? '';
                        const queries = query.split(/where/i);
                        if (queries.length > 0) {
                            let condition = queries[queries.length - 1];
                            condition = condition.replace(/(GROUP BY|HAVING|ORDER BY|OFFSET|FETCH|UNION|INTERSECT|EXCEPT|LIMIT|RLIKE)|/ig, "");
                            // const isSql = datasource?.type?.toLowerCase() === 'sql';
                            const isScan = datasource?.scan ?? false;
                            const updated_condition = isScan ? ` not (${condition}) ` : ` (${condition}) == False `;
                            query = query.replace(condition, updated_condition);
                            if (!isScan && (query.includes('HAVING') || query.includes('having'))) {
                                query = query.includes('HAVING') ? query.replace('HAVING', 'HAVING NOT') : query.replace('having', 'having not');
                            }
                            if (!isScan && (query.includes('RLIKE') || query.includes('rlike'))) {
                                query = query.includes('RLIKE') ? query.replace('RLIKE', 'NOT RLIKE') : query.replace('rlike', 'not rlike');
                            }
                        }
                    }
                    barValue.value.invalidQuery = query;
                }

                if (barValue.value.polarity === 'negative') {
                    barValue.filterType = barValue.filterType === appConstants.QueryFilterTypes[0] ? appConstants.QueryFilterTypes[1] : appConstants.QueryFilterTypes[0];
                }
                inputParams.rule = rule;
                inputParams.isCustomRule = true;
                inputParams.filterType = barValue.filterType;
            }
            if (chartType === 'countstatistics') {
                inputParams.data = barValue;
                inputParams.ruleName = 'Count';
                const connectionType = datasource && datasource.type ? datasource.type.toLowerCase() : '';
                const isScan = datasource?.scan ?? false;
                inputParams.rule = getCountChartRule(attribute, barValue.filterType, barValue.value.name.toLowerCase(), connectionType, isScan);
                inputParams.rule.chartType = (chartType === 'countstatistics') ? 'Count' : chartType;
                inputParams.filterType = barValue.filterType;
            }
        }

        onFilter(inputParams);
    }, [chartType, datasource, onFilter, attribute]);

    const loadProperties = useCallback((analysisData) => {
        setAnalysis([...analysisData]);
        if (countRules) {
            const availableRules = analysisData.filter((data) => countRules.includes(data.default_name) && (data.default_name.toLowerCase() !== "missing" && data.default_name.toLowerCase() !== "infinite"));
            setAnalysis([...availableRules]);
        }
    }, [countRules]);

    useEffect(() => {
        if (attributeChange || attributeRules) {
            loadProperties([...chartData]);
        }
    }, [chartData, loadProperties, attribute, attributeChange, attributeRules]);

    const loadChart = useCallback(() => {
        const groups = ["valid_percentage", "invalid_percentage"];
        const barData = analysis;
        const barHeight = 23;
        const barOpacity = 0.5;
        const barHighlightOpacity = 0.75;
        // set the dimensions and margins of the graph
        const margin = { top: 60, right: 20, bottom: 50, left: 100 };
        let width = 960 - margin.left - margin.right;
        const height = countRules ? (barStack ? barStack : 38) * (analysis.length - 1) : 340;
        d3.select(`.${chartClass} > *`).remove();
        d3.select(`.${chartClass} > svg`).remove();
        d3.select(`.${chartClass} > .yLabel`).remove();
        d3.select(`.${chartClass} > .yAxis`).remove();
        const svg = d3.select(`.${chartClass}`).append("svg")
            .attr("width", chartWidth)
            .attr('class', classes.stackChart)
            .attr("height", showAxis ? (height + margin.top + margin.bottom) : (height + margin.top))
            .append("g")
            .attr("width", (d, i, p) => {
                width = p[i].parentNode.clientWidth - 60;
                return width;
            })
            .attr("height", height + margin.top + margin.bottom)
            .attr("transform",
                "translate(" + (margin.left + 65) + "," + 0 + ")");

        const layers = d3.stack()
            .keys(groups)
            .order(d3.stackOrderNone)
            .offset(d3.stackOffsetNone)(barData);

        // set the ranges
        const x = d3.scaleLinear()
            .domain([0, 110])
            .range([0, width - (margin.left + margin.right)])
            .nice();

        const y = d3.scaleBand()
            .range([chartType === 'countstatistics' ? height + 34 : height, 0])
            .domain(barData.map((d) => { return d.name; }));

        // Tooltip div
        d3.select(`.${chartClass} > .tooltip`).remove();
        const div = d3.select(`.${chartClass}`).append("div")
            .attr("class", "tooltip")
            .style("opacity", 0)
            .style("zIndex", 1);

        const g = svg.selectAll('g')
            .data(layers)
            .enter()
            .append('g');

        const rect = g.selectAll('rect')
            .data((d) => {
                d.forEach((rectData) => {
                    rectData.key = d.key;
                    return rectData;
                });
                return d;
            })
            .enter().append("rect")
            .attr("class", (d) => {
                const color = (d.key === "valid") ? 'accepted' : 'not-accepted';
                return "bar " + color;
            })
            .style("opacity", barOpacity)
            .attr("x", 0)
            .attr("y", (d) => { return y(d.data.name) + ((y.bandwidth() / 2) - (barHeight / 2)); })
            .attr("height", barHeight)
            .attr("width", 0)
            .attr("cursor", 'pointer')
            .attr("fill", (d) => {
                const color = d.key === "valid_percentage" ? colors[0] : colors[1];
                /*
                 * if (d.data.polarity && d.data.polarity === "negative") {
                 *     color = d.key === "valid_percentage" ? colors[1] : colors[0];
                 * }
                 */
                return color;
            });

        rect.transition()
            .duration(500)
            .delay((_, i) => { return i * 100; })
            .attr("x", (d) => { return x(d[0]); })
            .attr("width", (d) => { return x(d[1]) - x(d[0]); });

        rect.on('mouseover', (d) => {
            let x = 0;
            let y = 0;
            div.attr("transform", (_, i, nodes) => {
                const mouseCoords = d3.mouse(nodes[i].parentNode);
                let xCo = 0;
                let yCo = 0;
                if (mouseCoords[0] + 10 >= width * 0.80) {
                    xCo = mouseCoords[0] - parseFloat(div
                        .attr("width"));
                    yCo = mouseCoords[1] + 10;
                } else {
                    xCo = mouseCoords[0] + 10;
                    yCo = mouseCoords[1];
                }
                x = xCo;
                y = yCo;
            });
            div.transition()
                .duration(200)
                .style("opacity", 0.9);
            const count = d.key === "invalid_percentage" ? d.data.invalid_records : d.data.valid_records;
            div.html(`Name : ${d.data.name} <br/> Percentage : ${getValue(d.data[d.key])}% <br/> Count : ${count}`)
                .style("left", x + "px")
                .style("top", y + "px");
        }).on('mouseout', (d, i, p) => {
            d3.select(p[i]).lower();
            d3.select(p[i]).style("opacity", barOpacity);
            div.transition()
                .duration(500)
                .style("opacity", 0);
        }).on('click', (d) => {
            const filterType = (d.key === `${appConstants.QueryFilterTypes[0].toLowerCase()}_percentage`)
                ? appConstants.QueryFilterTypes[0] : appConstants.QueryFilterTypes[1];
            onFilterChange({ value: d.data, filterType });
        });

        g.selectAll('text').data((d) => {
            d.forEach((textData) => {
                textData.key = d.key;
                return textData;
            });
            return d;
        }).enter().append("text")
            .style("font-size", "12px")
            .style("font-weight", 500)
            .text((d, i, p) => {
                if (chartType === 'countstatistics' && d.key === "invalid_percentage") {
                    return '';
                }
                return d.data[d.key] >= 4 ? `${getValue(d.data[d.key])}%` : '';
            })
            .attr("x", (d, i, p) => {
                const textLength = d3.select(p[i]).node().getComputedTextLength();
                return x(d[0]) + ((x(d[1]) - x(d[0])) / 2) - (textLength / 2);
            })
            .attr("y", (d, i, p) => {
                const height = d3.select(p[i]).node().getBoundingClientRect().height;
                return y(d.data.name) + ((y.bandwidth() / 2) + (height / 2) - 3);
            })
            .attr("height", barHeight)
            .attr("width", (d) => { return x(d[1]) - x(d[0]); })
            .attr("fill", "#222")
            .attr("style", (d, i, p) => {
                if (((x(d[1]) - x(d[0]))) < d3.select(p[i]).node().getComputedTextLength()) {
                    return "opacity: 0";
                }
                return "opacity: 1";
            })
            .on('click', (d) => {
                const filterType = (d.key === `${appConstants.QueryFilterTypes[0].toLowerCase()}_percentage`)
                    ? appConstants.QueryFilterTypes[0] : appConstants.QueryFilterTypes[1];
                onFilterChange({ value: d.data, filterType });
            });

        if (showAxis) {
            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .attr("color", theme.palette.chartColors.axis)
                .attr('class', 'axis-x')
                .call(d3.axisBottom(x).ticks(10));
        }

        // text label for the x axis
        let xAxisHeight = showAxis ? height + (margin.top + 10) : height + 20;
        if (chartType === 'countstatistics') {
            xAxisHeight += 35;
        }

        svg.append("text")
            .attr("transform",
                "translate(" + ((width / 2) - 140) + " ," +
                (xAxisHeight) + ")")
            .style("text-anchor", "middle")
            .text("Percentage");

        // add the y Axis
        svg.append("g")
            .attr("color", theme.palette.chartColors.axis)
            .attr('class', 'axis-y')
            .call(d3.axisLeft(y));

        if (yLabel) {
            d3.select(`.${chartClass}`).append("div").attr("class", 'yLabel').html(yLabel);
        }


        svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
        svg.selectAll(".axis-x .tick:last-of-type text").remove();
        if (changeTick) {
            svg.selectAll('.axis-y .tick').selectAll('line').remove();
            d3.select(`.${chartClass}`).append("div").attr('class', 'yAxis').attr('id', 'yAxis');
            d3.select(`.${chartClass}`).selectAll('.axis-y .tick').each((d, i, p) => {
                const text = d3.select(p[i]).select('.tick-text').text();
                p[i].remove();
                const appenDiv = document.createElement('div');
                appenDiv.classList = "axisText";
                appenDiv.innerHTML = text;

                if (chartType === 'countstatistics' && attributeRules && chartData.find((x) => x.name === text).default_name) {
                    const key = chartData.find((x) => x.name === text).default_name;
                    appenDiv.classList = attributeRules[key] ? "axisText valid" : 'axisText invalid';
                }

                appenDiv.key = analysis[i];
                appenDiv.onclick = (event) => {
                    const filterType = appConstants.QueryFilterTypes[0];
                    onFilterChange({ value: event.target.key, filterType });
                };
                appenDiv.onmouseover = (event) => {
                    const key = chartData.find((x) => x.name === text);
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html(`Name : ${text} <br/> Valid Count : ${key.valid_records ? key.valid_records : 0} <br/> Invalid Count : ${key.invalid_records ? key.invalid_records : 0}`)
                        .style("left", event.layerX + "px")
                        .style("top", (event.layerY) + "px");
                };
                appenDiv.onmouseleave = () => {
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                };
                const list = document.getElementById("yAxis");
                list.insertBefore(appenDiv, list.childNodes[0]);
            });
        } else {
            svg.selectAll('.axis-y .tick-text').text('').append('tspan').attr("cursor", 'pointer').text((d) => { return d; }).each((d, i, p) => {
                const textElement = d3.select(p[i]);
                const width = 150;
                wrapText(textElement, width);
            }).on('click', (_, i) => {
                const rule = analysis[i];
                let filterType = appConstants.QueryFilterTypes[0];
                if (rule && rule.rule_name.toLowerCase() === 'customrule' && rule.polarity === 'negative') {
                    filterType = appConstants.QueryFilterTypes[1];
                }
                onFilterChange({ value: rule, filterType });
            });
        }

        const lines = lengend;
        //    Legends
        const legendGap = 100;
        if (wrapWidth) {
            width = (width / 2) + legendGap + 50;
        }
        const legendX = lines.length > 1 ? (width / 1.2) - (legendGap + 40) : (width / 2) - ((legendGap + 40) / 2);
        const lengendXHeight = showAxis ? height + margin.top + 20 : height + 30;
        const legendGroup = svg.append("g")
            .attr("class", "legends")
            .attr("transform", "translate(" + legendX + "," + (lengendXHeight + 15) + ")");

        // Legend rectangle
        legendGroup.selectAll(".legend-group")
            .data(lines)
            .enter()
            .append("g")
            .attr("class", "legend-group")
            .style("opacity", 1)
            .on("mouseover", (d, i, p) => {
                d3.select(p[i]).style("opacity", barHighlightOpacity);
                const selectedBar = (d === "valid") ? "accepted" : "not-accepted";
                d3.selectAll(`.${chartClass} .bar.${selectedBar}`).style("opacity", barHighlightOpacity);
            })
            .on("mouseout", (d, i, p) => {
                d3.select(p[i]).style("opacity", 1);
                d3.selectAll(`.bar`).style("opacity", barOpacity);
            })
            .append("rect")
            .attr("class", "legend-rect")
            .attr("width", 14)
            .attr("height", 10)
            .attr('x', (_, index) => { return (index * legendGap); })
            .style("fill", (_, index) => { return index === 0 ? colors[0] : colors[1]; });


        // Legend label
        legendGroup.selectAll(".legend-group")
            .append("text")
            .attr("class", "legend-text")
            .attr("x", (_, index) => { return index * legendGap + 20; })
            .attr("y", 6)
            .text((line) => { return `${line}`; })
            .attr("alignment-baseline", "middle");
    }, [analysis, attributeRules, barStack, changeTick, chartClass, chartData, chartType, chartWidth, classes.stackChart, colors, countRules, lengend, onFilterChange, showAxis, theme.palette.chartColors.axis, wrapWidth, yLabel]);


    useEffect(() => {
        if (analysis && analysis instanceof Array && analysis.length !== 0 && (attributeChange || attributeRules)) {
            loadChart();
        }
    }, [analysis, attributeChange, attributeRules, changeTick, chartClass, chartType, chartWidth, classes.stackChart, colors, lengend, loadChart, onFilterChange, showAxis, tabIndex, theme.palette.chartColors.axis, wrapWidth, yLabel]);

    return (
        <ChartContainer
            id={id}
            title={name}
            chartData={chartData}
            filterProperties={
                {
                    enableFilter: false,
                    onFilter: onFilterChange
                }
            }>
            <Grid container className={classNames(classes.chartStyles, chartClass)} />
        </ChartContainer >
    );
};

HorizontalStackedBarChart.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    theme: PropTypes.object,
    name: PropTypes.string,
    chartClass: PropTypes.string,
    chartWidth: PropTypes.string,
    yLabel: PropTypes.string,
    showAxis: PropTypes.bool,
    changeTick: PropTypes.bool,
    lengend: PropTypes.array,
    colors: PropTypes.array,
    id: PropTypes.string,
    chartType: PropTypes.string,
    attribute: PropTypes.object,
    onFilter: PropTypes.func,
    wrapWidth: PropTypes.bool,
    tabIndex: PropTypes.number,
    attributeChange: PropTypes.bool,
    attributeRules: PropTypes.object,
    countRules: PropTypes.array,
    barStack: PropTypes.number
};

export default memo(withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(HorizontalStackedBarChart));