import React, { useEffect, useState, useCallback, memo } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import ChartStyles from './ChartStyles.jsx';
import { appConstants } from '../../constants/appConstants';
import { createRule, getFieldType } from '../RuleBuilder/QueryBuilderUtil.jsx';
import Group2138 from '../../assets/images/Group_2138.svg';
import Group2136 from '../../assets/images/Group_2136.svg';
import { learningRule } from '../../actions/scheduleActions';
import { useDispatch, useSelector } from 'react-redux';
import ProfileChartFilterOperators from '../../constants/chartOperators.js';


const LengthChart = (props) => {
    const { attribute, profileData, datasetId, classes, theme, onFilter, tabIndex, config, profileRuleEnable, isActive, attributeChange, invalidQuery } = props;
    const datasource = useSelector(({ datasource }) => datasource.datasource);
    const [menuType, setMenuType] = useState(appConstants.rangeFilterOptions[0]);
    const [chartData, setChartData] = useState({});
    const [edit, setEdit] = useState(false);
    const [edited, setEdited] = useState(false);
    const [interactionData, setInteractionData] = useState({ min: 0, max: 0 });
    const [learningUpdated, setLearningUpdated] = useState(false);
    const barWidth = 20;
    const dispatch = useDispatch();

    const loadChartData = useCallback((chartData) => {
        setChartData({ ...chartData });
    }, []);

    useEffect(() => {
        if (attributeChange) {
            const chartData = profileData.Length;
            loadChartData({ ...chartData });
        }
    }, [attribute, loadChartData, profileData, attributeChange]);

    const updateLegendText = (lineValue) => {
        return lineValue >= 0 ? lineValue - (barWidth / 2) : -9999;
    };

    const onChangeMenu = (value) => {
        setEdited(true);
        setMenuType(value);
    };

    const onFilterChange = useCallback((barValue) => {
        const inputParams = {
            chartType: appConstants.charts.length.type,
            chartName: appConstants.charts.length.name,
            "min": chartData?.input_params?.min_length,
            "max": chartData?.input_params?.max_length,
            "avg": (chartData?.input_params?.min_length ?? 0 + chartData?.input_params?.max_length ?? 0) / 2,
            filterOptions: true,
            ruleName: 'Length',
            defaultQuery: ""
        };
        if (barValue) {
            inputParams.data = barValue;
            const ruleParams = {
                defaultField: attribute.name,
                defaultFieldType: getFieldType(attribute.type),
                attributeType: attribute.type,
                connectionType: datasource?.type,
                isScan: datasource?.scan
            };
            const rule = createRule(ruleParams);
            const operators = ProfileChartFilterOperators.Length;
            rule.operator = { ...operators[0] };
            // datasource?.type.toLowerCase() === "oracle" && barValue.length === 0 ? { ...operators[operators.length - 1] } : { ...operators[0] };
            rule.value = barValue.length.toString();
            inputParams.rule = rule;
            inputParams.filterType = barValue.filterType;
        } else {
            inputParams.filterOptions = false;
            if (invalidQuery) {
                inputParams.defaultQuery = `select * from <table_name> where ${invalidQuery}`;
            } else {
                inputParams.defaultQuery = `select * from <table_name> where not length(${attribute.name}.null_format) between ${chartData?.input_params?.min_length} and ${chartData?.input_params?.max_length}`;
            }
        }

        onFilter(inputParams);
    }, [attribute.name, attribute.type, chartData?.input_params?.max_length, chartData?.input_params?.min_length, datasource?.scan, datasource?.type, invalidQuery, onFilter]);

    const learning = () => {
        const model = {
            "attribute": attribute.name,
            "property": {
                "min_length": interactionData.min,
                "max_length": interactionData.max
            },
            "type": "Profile"
        };
        dispatch(learningRule(attribute.id, datasetId, model));

    };

    const convertToNumber = (str) => {
        return str * 100 / 100;
    };

    const dragstarted = useCallback((d, nodes, i) => {
        if (edit) {
            const selectedBar = d3.select(nodes[i]);
            if (selectedBar.attr('line') !== "avg") {
                selectedBar.raise().classed("active", true);
            }
        }
    }, [edit]);

    const dragged = useCallback((d, nodes, i, x, statistics, max) => {
        if (edit) {
            const selectedBar = d3.select(nodes[i]);
            if (selectedBar.attr('line') !== "avg") {
                selectedBar.raise().attr("x1", d = d3.event.x).attr("x2", d = d3.event.x);
                const selecteLine = selectedBar.attr('line');
                const lineValue = updateLegendText(d3.event.x);
                d3.select(String(".interval-line-text." + selecteLine)).attr("x", lineValue);
                d3.select(String(".interval-up-bold-text." + selecteLine)).attr("x", lineValue + 20);
                d3.select(String(".interval-up-normal-text." + selecteLine)).attr("x", lineValue + 20);
                d3.select(String(".interval-up-second-normal-text." + selecteLine)).attr("x", lineValue + 20);
                d3.select(String(".interval-up-img-text." + selecteLine)).attr("x", lineValue + 20);
                statistics = { ...statistics, [selecteLine]: x.invert(d3.event.x) };
                // d3.select(String(".interval-up-img-text." + selecteLine)).attr("src", lengthStatisticsSrc('min', statistics, max));
                d3.select(`.length-chart .length-container .length-row .${selecteLine}`).style('left', selecteLine === "min" && (statistics.min >= 2) ? `calc(${x(statistics.min) - 120}px)` : `${x(updateLengthPosition(selecteLine, statistics))}px`);
            }
        }

    }, [edit]);

    const updateLengthPosition = (line, statistics, max) => {
        return statistics[line];
    };

    const updateLengthTopPosition = useCallback((line, statistics, max) => {
        if (line === "min") {
            if ((statistics.avg - statistics.min) <= getScaleValue(2, max) && (statistics.max - statistics.avg) <= getScaleValue(2, max)) {
                return 'auto';
            }
        } else if (line === "avg") {
            if ((statistics.avg - statistics.min) <= getScaleValue(3, max) && (statistics.max - statistics.avg) <= getScaleValue(3, max)) {
                return '100px';
            }
        } else if (line === "max") {
            if ((statistics.max - statistics.avg) <= getScaleValue(3, max) && (statistics.avg - statistics.min) <= getScaleValue(3, max)) {
                return '200px';
            } else if ((statistics.max - statistics.avg) <= getScaleValue(3, max)) {
                return '100px';
            }
        }
        return 'auto';
    }, []);

    const dragended = useCallback((d, x, nodes, i, max) => {
        if (edit) {
            const multiLines = [
                'Computed Min Length is  ',
                'Computed Avg Length is ',
                'Computed Max Length is '
            ];
            if (d3.select(nodes[i]).attr('line') !== "avg") {
                const selectedBar = d3.select(nodes[i]);
                const selectedLine = selectedBar.attr('line');
                const secondLine = selectedLine === 'min' ? 'max' : 'min';
                const secondBar = d3.select(`.length-interval-line.${secondLine}`);

                selectedBar.raise().classed("active highlight click", false);
                let selectedBarValue = convertToNumber(x.invert(selectedBar.attr('x1'))?.toFixed(2));
                const secondBarValue = convertToNumber(x.invert(secondBar.attr('x1'))?.toFixed(2));
                if (selectedLine === "min" && selectedBarValue < 0) {
                    selectedBarValue = 0;
                    let barValue = x(selectedBarValue);
                    barValue = updateLegendText(barValue);
                    d3.select(String(".interval-line-text." + selectedLine)).attr("x", barValue);
                } else if (selectedLine === "max" && selectedBarValue > max) {
                    let barValue = x(max);
                    barValue = updateLegendText(barValue);
                    d3.select(String(".interval-line-text." + selectedLine)).attr("x", barValue);
                }

                // Control minimum and maximum values limit
                if (selectedLine === "min") { selectedBarValue = selectedBarValue >= secondBarValue ? secondBarValue : selectedBarValue; }
                if (selectedLine === "max") { selectedBarValue = selectedBarValue <= secondBarValue ? secondBarValue : selectedBarValue; }

                // Update selected line position
                d3.select(nodes[i]).raise().attr("x1", d = x(selectedBarValue)).attr("x2", d = x(selectedBarValue));
                // Update second line text position
                let secondLineTextPosition = x(secondBarValue);
                secondLineTextPosition = updateLegendText(secondLineTextPosition);
                d3.select(String(".interval-line-text." + secondLine)).attr("x", secondLineTextPosition);
                let selectLineTextPosition = selectedLine === "min" ? parseInt(secondBarValue - selectedBarValue) : parseInt(selectedBarValue - secondBarValue);
                if (selectLineTextPosition === 0) {
                    selectLineTextPosition = selectedLine === "min" ? selectedBarValue - 0.4 : selectedBarValue + 0.4;
                    selectLineTextPosition = x(selectLineTextPosition);
                    selectLineTextPosition = updateLegendText(selectLineTextPosition);
                    d3.select(String(".interval-line-text." + selectedLine)).attr("x", selectLineTextPosition);
                    let secondLineTextPosition = secondLine === "min" ? selectedBarValue - 0.4 : selectedBarValue + 0.4;
                    secondLineTextPosition = x(secondLineTextPosition);
                    secondLineTextPosition = updateLegendText(secondLineTextPosition);
                    d3.select(String(".interval-line-text." + secondLine)).attr("x", secondLineTextPosition);
                }
                // Update legend text values
                const minLine = d3.select(".length-interval-line.min").attr('x1');
                const minimumLength = convertToNumber(x.invert(minLine)?.toFixed(2));
                const maxLine = d3.select(".length-interval-line.max").attr('x1');
                const maximumLength = convertToNumber(x.invert(maxLine)?.toFixed(2));
                if (minimumLength === maximumLength) {
                    d3.select(".length-chart .length-container .length-row .min").style("display", 'none');
                    d3.select(".length-chart .length-container .length-row .max").style("display", 'none');
                } else {
                    d3.select(".length-chart .length-container .length-row .min").style("display", 'unset');
                    d3.select(".length-chart .length-container .length-row .max").style("display", 'unset');
                }

                d3.select(String(".legend-text." + selectedLine)).text(`${selectedLine} (${selectedLine === "min" ? minimumLength : maximumLength})`);
                const averageLength = ((minimumLength + maximumLength) / 2)?.toFixed(2);
                d3.select(".legend-text.avg").text(`Avg (${averageLength})`);
                d3.select(".length-interval-line.avg").raise().attr("x1", d = x(averageLength)).attr("x2", d = x(averageLength));
                const averageLineTextPosition = updateLegendText(x(averageLength));
                d3.select(".interval-line-text.avg").attr("x", averageLineTextPosition);
                d3.select(".interval-up-bold-text.avg").attr("x", averageLineTextPosition + 20);
                d3.select(".interval-up-normal-text.avg").attr("x", averageLineTextPosition + 20);
                d3.select(".interval-up-second-normal-text.avg").attr("x", averageLineTextPosition + 20);
                d3.select(".interval-up-img-text.avg").attr("x", averageLineTextPosition + 20);
                setInteractionData({ min: minimumLength, max: maximumLength });
                d3.select(".length-chart .length-container .length-row .min .interval-up-normal-text").html(multiLines[0] + minimumLength);
                d3.select(".length-chart .length-container .length-row .avg .interval-up-normal-text").html(multiLines[1] + averageLength);
                d3.select(".length-chart .length-container .length-row .max .interval-up-normal-text").html(multiLines[2] + maximumLength);

                const statistics = { min: minimumLength, avg: averageLength, max: maximumLength };
                d3.select(".length-chart .length-container .length-row .min").style('text-align', (statistics.min >= 2) ? "right" : "left");
                d3.select(".length-chart .length-container .length-row .min .interval-up-img-text").attr("src", lengthStatisticsSrc('min', statistics, max));
                d3.select(".length-chart .length-container .length-row .min").style("top", updateLengthTopPosition('min', statistics, max));
                d3.select(".length-chart .length-container .length-row .min").style("left", (statistics.min >= 2) ? `calc(${x(statistics.min) - 120}px)` : `${x(statistics.min)}px`);
                d3.select(".length-chart .length-container .length-row .avg .interval-up-img-text").attr("src", lengthStatisticsSrc('avg', statistics, max));
                d3.select(".length-chart .length-container .length-row .avg").style("top", updateLengthTopPosition('avg', statistics, max));
                d3.select(".length-chart .length-container .length-row .avg").style("left", `${x(updateLengthPosition('avg', statistics))}px`);
                d3.select(".length-chart .length-container .length-row .max .interval-up-img-text").attr("src", lengthStatisticsSrc('max', statistics, max));
                d3.select(".length-chart .length-container .length-row .max").style("top", updateLengthTopPosition('max', statistics, max));
                d3.select(".length-chart .length-container .length-row .max").style("left", `${x(updateLengthPosition('max', statistics, max))}px`);
            }
            if (!learningUpdated) {
                setLearningUpdated(true);
            }
        }
    }, [edit, learningUpdated, updateLengthTopPosition]);

    const lengthStatisticsSrc = (line, statistics, max) => {
        if (line === "min" && (statistics.min >= 2)) {
            return Group2136;
        }
        return Group2138;
    };

    const getScaleValue = (value, max) => {
        let scaleValue = 0.5;
        if (max >= 11 && max <= 99) {
            scaleValue = 1;
        } else if (max >= 100 && max <= 999) {
            scaleValue = 10;
        } else if (max >= 1000 && max <= 9999) {
            scaleValue = 100;
        }
        return value * scaleValue;
    };


    const discardEdit = useCallback(() => {
        setEdited(false);
    }, []);

    const extractValues = useCallback((frequency, records) => {
        const keys = Object.keys(frequency);
        return keys.map((key) => {
            const value = (parseFloat(frequency[key]) / records) * 100;
            return {
                key: key === "None" ? 0 : parseFloat(key),
                value: Math.round(value) !== value ? parseFloat(value?.toFixed(2)) : value,
                count: frequency[key]
            };
        });
    }, []);

    useEffect(() => {
        if (Object.keys(chartData).length !== 0 && (attributeChange || edited)) {
            if (edited) {
                discardEdit();
            }
            const selectionRange = menuType === 'Top 10' ? 'topN' : 'bottomN';
            const validValues = extractValues(chartData?.frequency_count?.valid ? chartData?.frequency_count?.valid : {}, chartData?.total_records_processed);
            const invalidValues = extractValues(chartData?.frequency_count?.invalid ? chartData?.frequency_count?.invalid : {}, chartData?.total_records_processed);
            const validData = selectionRange === "topN" ? validValues.slice(0, 10) : validValues.slice(Math.max(validValues.length - 10, 1));
            const invalidData = selectionRange === "topN" ? invalidValues.slice(0, 10) : invalidValues.slice(Math.max(invalidValues.length - 10, 1));
            const lengthChartData = [...validData, ...invalidData];
            const statistics = {
                "min": parseFloat(chartData?.input_params?.min_length),
                "max": parseFloat(chartData?.input_params?.max_length),
                "avg": (parseFloat(chartData?.input_params?.min_length) + parseFloat(chartData?.input_params?.max_length)) / 2
            };
            const multiLines = [
                'Computed Min Length is  ',
                'Computed Avg Length is ',
                'Computed Max Length is '
            ];

            const margin = { top: 40, right: 20, bottom: 50, left: 40 };
            const tickCount = 10;
            const height = 280;
            let width = 960 - margin.left - margin.right;
            const minBarHeightThreshold = 2;
            const barOpacity = 0.5;
            const barHighlightOpacity = 0.75;
            d3.select('.length-chart > *').remove();
            const svg = d3.select(".length-chart").append("svg")
                .attr("width", "100%")
                .style('margin-top', '45px')
                .attr("height", height + margin.top + margin.bottom + 20)
                .append("g")
                // .attr("width", 1574)
                .attr("width", (d, i, p) => {
                    width = p[i]?.parentNode?.clientWidth;
                    return width;
                })
                .attr("height", height + margin?.top + margin?.bottom - 10)
                .attr("transform",
                    "translate(" + (margin?.left + 20) + "," + ((margin?.top / 2) - 15) + ")");

            let max = d3.max(lengthChartData, (d) => { return parseInt(d.key); });
            max = max > statistics.max ? max : statistics.max;
            max = max > 10 ? max : 10;
            const x = d3.scaleLinear()
                .domain([0, max + (Math.round(max / tickCount))])
                .range([0, width - (margin.left + margin.right)])
                .nice();

            const yMax = Math.max(...lengthChartData.map((o) => o.value), 0);
            const y = d3.scaleLinear()
                .domain([0, yMax])
                .range([height, 0])
                .nice();

            // Tooltip div
            d3.select(".length-chart >.tooltip").remove();
            const div = d3.select(".length-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            // add the x Axis
            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .attr("color", theme.palette.chartColors.axis)
                .call(d3.axisBottom(x).ticks(10));

            // text label for the x axis
            svg.append("text")
                .attr("transform",
                    "translate(" + (width / 2) + " ," +
                    (height + margin.top + 10 + ((margin.top / 2) - 5)) + ")")
                .style("text-anchor", "middle")
                .text("Length");

            // add the y Axis
            svg.append("g")
                .attr("color", theme.palette.chartColors.axis)
                .call(d3.axisLeft(y).ticks(10));

            // text label for the y axis
            svg.append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - (margin.left + 20))
                .attr("x", 0 - (height / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text("Percentage");

            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
            svg.selectAll(".tick:last-of-type text").remove();

            svg.selectAll(".bar")
                .data(lengthChartData)
                .enter().append("rect")
                .attr("x", (d) => { return x(d.key) - (barWidth / 2); })
                .attr("width", barWidth)
                .attr("fill", (d) => {
                    return (parseFloat(d.key) >= parseFloat(statistics.min) && parseFloat(d.key) <= parseFloat(statistics.max)) ? theme.palette.chartColors.valid : theme.palette.chartColors.inValid;
                })
                .attr("class", (d) => {
                    const color = (parseFloat(d.key) >= parseFloat(statistics.min) && parseFloat(d.key) <= parseFloat(statistics.max)) ? 'accepted' : 'not-accepted';
                    return "bar " + color;
                })
                .attr("y", (d) => { return height; })
                .style("opacity", barOpacity)
                .on('mouseover',
                    (d, i, nodes) => {
                        d3.select(nodes[i]).style("opacity", barHighlightOpacity);

                        // Show Tooltip
                        const rect = nodes[i].getBBox();
                        div.transition()
                            .duration(200)
                            .style("opacity", 0.9);
                        div.html(`Length : ${d.key} <br/> Percentage : ${d.value}% <br/> Count : ${d.count}`)
                            .style("left", (rect.x + (barWidth / 2)) + "px")
                            .style("top", (rect.y - ((barWidth * 2) + barWidth)) + "px");
                    })
                .on('mouseout', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", barOpacity);
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .on('click', (d) => {
                    const filterType = (d.key >= Math.round(statistics.min) && parseInt(d.key) <= Math.round(statistics.max)) ? appConstants.QueryFilterTypes[0] : appConstants.QueryFilterTypes[1];
                    onFilterChange({ length: d.key, filterType });
                })
                .attr("height", 0)
                .transition()
                .duration(500)
                .delay((d, i) => {
                    return i * 100;
                })
                .attr("y", (d, i) => {
                    return height - y(d.value) > minBarHeightThreshold ? y(d.value) : y(d.value) - minBarHeightThreshold;
                })
                .attr("height", (d, i) => {
                    return height - y(d.value) > minBarHeightThreshold ? height - y(d.value) : (height - y(d.value) + minBarHeightThreshold);
                });

            let lines = ['min', 'avg', 'max'];

            const drag = d3.drag()
                .on("start", (d, i, nodes) => { dragstarted(d, nodes, i); })
                .on("drag", (d, i, nodes) => { dragged(d, nodes, i, x, statistics, max); })
                .on("end", (d, i, nodes) => {
                    dragended(d, x, nodes, i, max);
                });
            svg.selectAll('.length-interval-line')
                .data(lines)
                .enter().append("line")
                .attr("fill", "none")
                .attr("x1", (line, i, p) => {
                    let x1 = parseFloat(statistics[line] ? statistics[line] : 0);
                    d3.select(p[i]).attr("class", "length-interval-line " + line + " ").attr('line', line)
                        .attr("value", x1);
                    x1 = x(x1);
                    return x1 >= 0 ? x1 : -9999;
                })
                .attr("x2", (line) => {
                    let x2 = parseFloat(statistics[line] ? statistics[line] : 0);
                    x2 = x(x2);
                    return x2 >= 0 ? x2 : -9999;
                })
                .attr("y1", () => {
                    return y(0) <= 280 ? y(0) : 280;
                })
                .attr("y2", () => { return y(d3.max(lengthChartData, (d) => { return d[1] + (barWidth / 2); })); })
                .attr("stroke-linecap", "butt")
                .on('mouseover', (_, i, nodes) => {
                    if (edit) {
                        if (d3.select(nodes[i]).attr('class').indexOf('click') === -1) {
                            d3.select(nodes[i]).raise().classed("highlight hover", true);
                        }
                    }
                })
                .on('mouseout', (_, i, nodes) => {
                    if (edit) {
                        if (d3.select(nodes[i]).attr('class').indexOf('hover') !== -1) {
                            d3.select(nodes[i]).raise().classed("highlight hover", false);
                        }
                    }
                })
                .call(drag);

            svg.selectAll('.interval-line-text')
                .data(lines)
                .enter().append("text")
                .attr('class', 'interval-line-text')
                .style('text-transform', 'capitalize')
                .style('font-size', 13)
                .text((line) => { return line; })
                .attr("x", (line, index, nodes) => {
                    let lineValue = parseFloat(statistics[line] ? statistics[line] : 0);
                    if (line !== "avg") {
                        const value = line === "min" ? parseFloat(statistics.avg - lineValue) : parseFloat(lineValue - statistics.avg);
                        if (value === 0) {
                            line === "min" ? lineValue -= 1 : lineValue += 1;
                        }
                    }
                    d3.select(nodes[index]).attr("class", `interval-line-text ${line}`);
                    lineValue = x(lineValue);
                    return updateLegendText(lineValue);
                })
                .attr("y", () => { return y(0) + 35; });
            const minValue = parseFloat(statistics.min ? statistics.min : 0);
            const maxValue = parseFloat(statistics.max ? statistics.max : 0);
            d3.select(".length-chart >.length-container").remove();
            const topClass = d3.select(".length-chart").append("div")
                .attr('class', 'length-container')
                .style("width", `${width}px`)
                .style("transform", `translate(${margin.left + 30}px,${(margin.top / 2) - 15}px)`)
                .append('div')
                .attr("class", "length-row")
                .style("position", 'relative');
            topClass.selectAll('.length-row')
                .data(lines)
                .enter()
                .append("div")
                .attr("class", (lines) => {
                    if (lines === 'min') {
                        return 'length-column min';
                    } else if (lines === 'avg') {
                        return 'length-column avg';
                    } else if (lines === 'max') {
                        return 'length-column max';
                    }
                })
                .style(('left'), (lines) => {
                    if (lines === "min" && (statistics.min >= 2)) {
                        return `calc(${x(statistics.min) - 120}px)`;
                    }
                    const leftPosition = x(updateLengthPosition(lines, statistics, max));
                    return `${leftPosition}px`;
                })
                .style('top', (lines) => {
                    return updateLengthTopPosition(lines, statistics, max);
                })
                .style('text-align', (lines) => {
                    return (lines === "min" && (statistics.min >= 2)) ? "right" : "left";
                });
            // Bold value

            topClass.selectAll('.length-column')
                .data(lines)
                .append("b")
                .attr('class', 'interval-up-bold-text')
                .style('text-transform', 'capitalize')
                .style('font-weight', 'bold')
                .text((line, index) => lines[index] + " Length ");


            // Normal Text
            topClass.selectAll('.length-column')
                .data(lines)
                .append("p")
                .attr('class', 'interval-up-normal-text')
                .style('text-transform', 'capitalize')
                .text((line, index) => {
                    if (lines[index] === 'min') {
                        return multiLines[index] + statistics[line];
                    } else if (lines[index] === 'avg') {
                        return multiLines[index] + statistics[line];
                    } else if (lines[index] === 'max') {
                        return multiLines[index] + statistics[line];
                    }
                });

            // Image Text
            topClass.selectAll('.length-column')
                .data(lines)
                .append("img")
                .attr('class', 'interval-up-img-text')
                .attr('src', (line, index) => {
                    return lengthStatisticsSrc(lines[index], statistics, max);
                })
                .attr("width", "60")
                .attr("height", "50");
            if ((minValue === maxValue)) {
                d3.select(".length-chart .length-container .length-row .min").style("display", 'none');
                d3.select(".length-chart .length-container .length-row .max").style("display", 'none');
                // d3.select(".length-chart .length-container .length-row .avg").style("display", 'none')
            } else {
                d3.select(".length-chart .length-container .length-row .min").style("display", 'unset');
                d3.select(".length-chart .length-container .length-row .max").style("display", 'unset');
            }
            const legendPosition = 45;
            //   Interval Legends
            const legendGap = 130;

            let legendX = lines.length > 1 ? 0 : (width / 2) - (legendGap / 2);
            legendX = 0;
            let legendGroup = svg.append("g")
                .attr("class", "legends")
                .attr("transform", "translate(" + (legendX - 50) + "," + (height + margin.top + legendPosition) + ")");

            // Interval Legend rectangle
            legendGroup.selectAll(".legend-group")
                .data(lines)
                .enter()
                .append("g")
                .attr("class", "legend-group")
                .style("opacity", 1)
                .append("rect")
                .attr("class", "legend-rect")
                .attr("width", 14)
                .attr("height", 10)
                .attr('x', (_, index) => { return (index * legendGap); })
                .style("fill", theme.palette.chartColors.valid);

            legendGroup.selectAll(".legend-group")
                .append("text")
                .attr("class", "legend-text")
                .attr("x", (_, index) => { return index * legendGap + 20; })
                .attr("y", 6)
                .text((line, i, nodes) => {
                    d3.select(nodes[i]).attr("class", `legend-text ${line} `);
                    const value = line in statistics ? statistics[line] : 0;
                    return `${line} (${value?.toFixed(2)})`;
                })
                .attr("alignment-baseline", "middle");

            lines = ["Valid", "Invalid"];

            legendX = (width / 1.1) - 220;
            legendGroup = svg.append("g")
                .attr("class", "legends")
                .attr("transform", "translate(" + (legendX - 50) + "," + (height + margin.top + legendPosition) + ")");

            // Legend rectangle
            legendGroup.selectAll(".legend-group")
                .data(lines)
                .enter()
                .append("g")
                .attr("class", "legend-group")
                .style("opacity", 1)
                .on("mouseover", (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", barHighlightOpacity);
                    const selectedBar = (d === "valid") ? "accepted" : "not-accepted";
                    d3.selectAll(`.length-chart.bar.${selectedBar} `).style("opacity", barHighlightOpacity);
                })
                .on("mouseout", (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", 1);
                    d3.selectAll(`.bar`).style("opacity", barOpacity);
                })
                .append("rect")
                .attr("class", "legend-rect")
                .attr("width", 14)
                .attr("height", 10)
                .attr('x', (_, index) => { return (index * legendGap); })
                .style("fill", (_, index) => { return index === 0 ? theme.palette.chartColors.valid : theme.palette.chartColors.inValid; });

            // Legend label
            legendGroup.selectAll(".legend-group")
                .append("text")
                .attr("class", "legend-text")
                .attr("x", (_, index) => { return index * legendGap + 20; })
                .attr("y", 6)
                .text((line, index) => {
                    let percent = line === "Valid" ? chartData.valid_percentage : chartData.invalid_percentage;
                    if (Math.round(percent) !== percent) {
                        percent = percent?.toFixed(2);
                    }
                    return `${line} (${percent}%)`;
                })
                .attr("alignment-baseline", "middle");
        }
    }, [attributeChange, chartData, discardEdit, dragended, dragged, dragstarted, edit, edited, extractValues, menuType, onFilterChange, tabIndex, theme.palette.chartColors.axis, theme.palette.chartColors.inValid, theme.palette.chartColors.valid, updateLengthTopPosition]);

    const onEdit = (type) => {
        if (type === "edit") {
            setEdit(true);
        } else if (type === "accept") {
            if (learningUpdated) {
                chartData.input_params["min_length"] = interactionData.min;
                chartData.input_params["max_length"] = interactionData.max;
                setChartData({ ...chartData });
                learning();
            }
            setEdit(false);
        } else {
            setEdit(false);
        }
        setEdited(true);
    };

    const onRuleChange = (value) => {
        profileRuleEnable(appConstants.charts.length.type, value);
    };


    return (
        <ChartContainer
            menuProperties={
                [
                    {
                        options: appConstants.rangeFilterOptions,
                        onChange: onChangeMenu,
                        value: menuType
                    }
                ]
            }
            editProperties={
                {
                    edited: edit,
                    edit: config.learning,
                    onEdit: onEdit
                }
            }
            filterProperties={
                {
                    enableFilter: config.query,
                    onFilter: onFilterChange
                }
            }
            ruleProperties={
                {
                    edit: true,
                    onRule: onRuleChange,
                    rule: isActive
                }
            }
            title={appConstants.charts.length.name}
            id={appConstants.charts.length.id}
            chartData={chartData} >
            <Grid container className={classNames(classes.chartStyles, "length-chart", classes.marginTop10)} />
        </ChartContainer >
    );
};

LengthChart.propTypes = {
    classes: PropTypes.object,
    attribute: PropTypes.object,
    profileData: PropTypes.object,
    theme: PropTypes.object,
    tabIndex: PropTypes.number,
    onFilter: PropTypes.func,
    config: PropTypes.object,
    profileRuleEnable: PropTypes.func,
    isActive: PropTypes.bool,
    datasetId: PropTypes.string,
    attributeChange: PropTypes.bool,
    invalidQuery: PropTypes.string
};

export default memo(withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(LengthChart));