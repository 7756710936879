import React, { memo, useEffect, useState } from 'react';
import {
    Grid, withStyles, Typography, Box
} from '@material-ui/core';
import PropTypes from 'prop-types';

import Styles from '../../layouts/Styles.jsx';
import DatasetStyles from './DatasetStyles.jsx';
import { getWorkbookDatasource } from '../../actions/datasetActions';
import { useDispatch } from 'react-redux';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';


const WorkbookDatasource = ({ datasourceId, workbookId, classes, theme, name }) => {

    const dispatch = useDispatch();
    const [datasourceList, setDatasourceList] = useState([]);

    const datasourceIco = <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 11.289 15.394" fill={theme.palette.grey.light}>
        <path id="database_17_" data-name="database (17)" d="M15.645,17.394c2.805,0,5.645-.793,5.645-2.309V4.309C21.289,2.793,18.45,2,15.645,2S10,2.793,10,4.309V15.085C10,16.6,12.839,17.394,15.645,17.394Zm0-14.368c2.865,0,4.618.831,4.618,1.283s-1.753,1.283-4.618,1.283-4.618-.831-4.618-1.283S12.78,3.026,15.645,3.026ZM11.026,5.695a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924V7.9c0,.452-1.753,1.283-4.618,1.283S11.026,8.353,11.026,7.9Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Z" transform="translate(-10 -2)" fill={theme.palette.grey.light} />
                          </svg>;

    /**
     * Loads the Workbook Views
     */
    useEffect(() => {
        const requestParams = {
            datasource_id: datasourceId,
            workbook_id: workbookId
        };
        dispatch(getWorkbookDatasource(requestParams)).then((response) => {
            setDatasourceList([...response]);
        });
    }, [datasourceId, dispatch, workbookId]);


    return (
        <Grid>
            <Grid sx={{ flexGrow: 1 }} container spacing={2} className={classes.itemHeight} >
                <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        {
                            datasourceList.map((item, index) => (
                                <Grid key={item} item xs={3}>
                                    <Grid className={classes.itemGrid}>
                                        <Grid className={classes.icoContainer}>
                                            {datasourceIco}
                                        </Grid>
                                        <Grid className={classes.padding15}>
                                            <Typography variant="h6" gutterBottom component="div" className={classes.sourceName}>
                                                {item.datasource.name}
                                            </Typography>
                                            <ToolTipComponent title={item.type} arrow>
                                            <Typography variant="h6" gutterBottom component="div" className={classes.gridTag}>
                                                <Box component="span">
                                                Connects To :
                                                </Box>
                                                {item.type}
                                            </Typography>
                                            </ToolTipComponent>
                                            <ToolTipComponent title={name} arrow>
                                            <Typography variant="h6" gutterBottom component="div" className={classes.gridTag}>
                                                <Box component="span">
                                                Embedded In :
                                                </Box>
                                                {' '}
                                                {name}
                                            </Typography>
                                            </ToolTipComponent>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

WorkbookDatasource.propTypes = {
    classes: PropTypes.object,
    datasourceId: PropTypes.number,
    workbookId: PropTypes.string,
    theme: PropTypes.object,
    name: PropTypes.string

};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(memo(WorkbookDatasource));