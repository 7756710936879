const DomainStyles = (theme) => ({
    domainInfoContainer: {
        paddingTop: 20
    },
    kabanviewContainer: {
        overflowY: 'hidden',
        overflowX: 'auto',
        flexWrap: 'nowrap'
    },
    kanbanviewSection: {
        minWidth: 400,
        marginRight: 25
    },
    domainCardContainer: {
        backgroundColor: theme.palette.background.paper,
        marginBottom: 15,
        boxShadow: '0px 0px 15px #5F60611A',
        padding: '10px 15px',
        '& .MuiListItem-root': {
            padding: '3px 0px',
            borderBottom: '1px solid #eee'
        },
        position: 'relative',
        overflow: 'hidden',
        borderLeft: '4px solid'
    },
    cardTitleTxt: {
        fontSize: 15,
        width: 250,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        marginLeft: 10
    },
    scoreContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    invalidCount: {
        fontWeight: 500,
        color: theme.palette.primary.main
    },
    titleContainer: {
        padding: '5px 15px',
        marginBottom: 15,
        position: 'sticky',
        top: 0
    },
    titleTxt: {
        fontSize: 14,
        width: 150,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    highCard: {
        borderColor: theme.palette.chartColors.score100
    },
    mediumCard: {
        borderColor: theme.palette.chartColors.score50
    },
    lowCard: {
        borderColor: theme.palette.chartColors.score25
    },
    cardDetail: {
        borderBottom: `1px solid ${theme.palette.border.default}`,
        paddingBottom: 5
    },
    cardAction: {
        borderTop: `1px solid ${theme.palette.border.default}`
    },
    attributeContainer: {
        margin: '10px 0px',
        display: 'flex'
    },
    attributeCount: {
        width: 28,
        height: 28,
        borderRadius: 25,
        fontSize: 11,
        marginLeft: 8,
        backgroundColor: theme.palette.grey.extraLight,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center'
    },
    chip: {
        height: 28,
        fontSize: 12.5
    },
    chipHigh: {
        backgroundColor: '#ccfbe4',
        border: '1px solid #a7ecca'
    },
    chipMedium: {
        border: '1px solid #fdd59f',
        backgroundColor: '#fef6eb'
    },
    chipLow: {
        backgroundColor: '#ffeded',
        border: '1px solid #f5bdbd'
    },
    kanbanSection: {
        height: 'calc(100vh - 278px)',
        paddingRight: 5
    },
    noWrap: {
        flexWrap: 'wrap'
    },
    accordianSummary: {
        '& .MuiAccordionSummary-content': {
            flexDirection: 'column'
        }
    },
    domainListContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 'calc(100vh - 270px)',
        flexDirection: 'column',
        flexWrap: 'nowrap !important',
        '& .MuiTableCell-root': {
            border: 'none'
        },
        '& .MuiTableCell-head': {
            paddingBottom: '0px'
        },
        '& .MuiAccordionDetails-root': {
            flexDirection: 'column'
        },
        '& .MuiAccordion-root': {
            width: '100%',
            borderRadius: '0px',
            position: 'initial'
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0
        }
    },
    listCardRowHeader: {
        border: '1px solid #e1e5e6',
        backgroundColor: '#fff',
        borderBottom: '1px solid #e1e5e6'
    },
    listCardRow: {
        borderBottom: `8px solid #fff`,
        backgroundColor: theme.palette.background.container
    },
    ruleCountTxt: {
        color: theme.palette.grey.dark,
        marginBottom: 15
    },
    listCountCell: {
        width: '10%'
    },
    listDQscoreCell: {
        width: '15%'
    },
    listAttributeCell: {
        width: '14%'
    },
    listNameCell: {
        width: '14%'
    },
    listActionCell: {
        width: '10%'
    },
    actionIcons: {
        padding: 5
    },
    cardSummary: {
        padding: 15,
        boxShadow: '0px 0px 6px #e1e5e6',
        backgroundColor: theme.palette.background.paper,
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    timelineContainer: {
        height: '100%',
        display: 'flex'
    },
    ruleContainer: {
        backgroundColor: theme.palette.background.paper,
        top: 140,
        right: 0,
        boxShadow: '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
        padding: 20,
        position: 'fixed',
        height: '100%',
        width: '65%',
        zIndex: 2
    },
    ruleDescriptionText: {
        color: theme.palette.grey.dark,
        maxHeight: 35
    },
    ruleInfoSection: {
        display: 'flex',
        alignItems: 'center'
    },
    ruleInfoTxt: {
        fontWeight: 500,
        fontSize: 16,
        marginLeft: 10
    },
    selectAttribute: {
        textAlign: 'left'
    },
    domainFilter: {
        display: 'flex',
        alignItems: 'center'
    },
    ribbon: {
        position: "absolute",
        top: -13,
        left: -12,
        width: 25,
        transform: 'rotate(-45deg)',
        background: theme.palette.secondary.main,
        transformOrigin: 0,
        height: 34
    },
    domainDataTable: {
        '& tbody > .MuiTableRow-root > .MuiTableCell-root': {
            padding: 2,
            '& > *': {
                padding: "3px 10px"
            }
        },
        '& tbody > .MuiTableRow-root:last-child > .MuiTableCell-root': {
            borderBottom: 0
        }
    },
    domainFilterContainer: {
        right: 65,
        top: 35
    },
    ruleEditor: {
        borderTop: `1px solid ${theme.palette.border.default}`,
        paddingTop: 20,
        height: '100%'
    },
    backdrop: {
        zIndex: 1
    },
    listContainer: {
        maxHeight: 280,
        boxShadow: 'none'
    },
    sliderValue: {
        marginLeft: 16,
        marginTop: 4,
        color: `${theme.palette.grey.default} !important`
    },
    customRuleSection: {
        height: '100%',
        display: 'block'
    },
    dataContainer: {
        // height: 'calc(100vh - 236px)',
        backgroundColor: theme.palette.common.white,
        position: 'relative'
    },
    gridNameContainer: {
        maxWidth: '20%',
        flexBasis: '20%',
        padding: '0px 15px',
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-body1': {
            textAlign: 'left',
            marginRight: 10
        }
    },
    countContainer: {
        maxWidth: '10%',
        flexBasis: '10%',
        padding: '0px 15px',
        display: 'flex',
        alignItems: 'center'
    },
    actionContainer: {
        maxWidth: '7%',
        flexBasis: '7%',
        padding: '0px 15px',
        display: 'flex',
        flexDirection: 'column'
    },
    gridScoreContainer: {
        maxWidth: '23%',
        flexBasis: '23%',
        padding: '0px 15px'
    },
    accordian: {
        // border: `1px solid ${theme.palette.border.dark}`
    },
    accordianDetail: {
        padding: "2px 0px 15px 0px",
        background: theme.palette.background.default
    },
    accordianDatasetContainer: {
        '& .MuiAccordion-root': {
            backgroundColor: theme.palette.background.container
        }
    },
    borderTopColor: {
        borderTop: '2px solid #F4F6F9'
    },
    accordianAttributeContainer: {
        padding: "12px",
        backgroundColor: theme.palette.background.container,
        '& .MuiAccordion-root': {
            backgroundColor: theme.palette.background.paper,
            padding: '0px',
            border: 'none',
            position: 'initial',
            marginBottom: 4
        },
        '& .MuiAccordionSummary-root': {
            minHeight: 30
        },
        '& .MuiCollapse-container': {
            padding: "0px 10px"
        }
    },
    domainListCardContainer: {
        padding: '5px 0px',
        backgroundColor: theme.palette.background.container,
        alignItems: 'center',
        marginBottom: 4,
        '& :last-child': {
            marginBottom: 0
        }
    },
    invalidRecordText: {
        color: theme.palette.tertiary,
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.tertiary
        }
    },
    listSection: {
        margin: "10px 0px 10px 0px",
        width: '100%',
        justifyContent: 'space-evenly',
        padding: '8px 12px',
        '& .MuiTypography-body1': {
            fontWeight: 600
        }
    },
    domainMonitoringListContainer: {
        margin: "10px 0px 10px 0px",
        width: '100%',
        justifyContent: 'space-evenly',
        backgroundColor: '#FFF'
    },
    domainMonitoringValueListContainer: {
        margin: "10px 0px 10px 0px",
        width: '100%',
        justifyContent: 'space-evenly',
        padding: '8px 12px',
        borderBottom: `1px solid ${theme.palette.border.default}`,
        '& .MuiGrid-item': {
            alignSelf: 'center'
        }
    },
    domainMonitoringDifference: {
        backgroundColor: '#54DF9A',
        padding: 8,
        color: '#FFF'
    },
    domainSplit: {
        margin: '0px 5px'
    },
    sourceCountText: {
        marginLeft: 21
    },
    ruleCountText: {
        marginLeft: 0
    },
    attributeCountText: {
        marginLeft: -4
    },
    datasetCountText: {
        // marginLeft: 21
    },
    kanbanContainer: {
        display: 'flex',
        marginTop: 10,
        overflowX: 'auto'
    },
    sourceTitleSection: {
        display: 'flex',
        alignItems: 'center'
    },
    domainTxt: {
        color: theme.palette.grey.dark
    },
    kanbanBlueHeader: {
        backgroundImage: 'linear-gradient(to right, #aad6f4 , #e9f2f8)',
        zIndex: 1
    },
    kanbanGreyHeader: {
        backgroundImage: 'linear-gradient(to right, #ebeeef , #ebeeef)',
        zIndex: 1
    },
    notApplicableText: {
        color: theme.palette.grey.light,
        fontSize: 13
    },
    sliderContainer: {
        '& > .MuiSlider-root > .MuiSlider-thumb.Mui-disabled': {
            width: 12,
            height: 12,
            marginTop: -4,
            marginLeft: -6
        }
    },
    noScoreContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    fieldTxt: {
        cursor: 'pointer',
        // marginLeft: 10,
        color: theme.palette.grey.dark
    },
    datasetTitleSection: {
        display: 'flex',
        // flexWrap: 'wrap',
        wordBreak: 'break-word'
    },
    cardCountText: {
        fontWeight: 600,
        fontSize: 14
    },
    downloadIcon: {
        marginLeft: 55,
        padding: 5
    },
    header: {
        display: 'flex',
        alignItems: 'center'
    },
    AttritypeTitleTxt: {
        fontWeight: 500,
        marginLeft: 10
    },
    containerBorder: {
        border: `1px solid ${theme.palette.border.dark}`,
        borderRightColor: 'none'
    },
    domainAccordion: {
        border: `1px solid ${theme.palette.border.dark}`,
        borderLeft: 'none'
    },
    ruleListContainer: {
        backgroundColor: theme.palette.background.paper,
        height: 'calc(100% - 166px) !important',
        padding: 15,
        '& .MuiAccordion-root:before': {
            opacity: 0
        }
    },
    domainListSection: {
        border: `1px solid ${theme.palette.grey.extraLight}`
    },
    domainAccordTextLink: {
        '& .MuiTypography-root': {
            color: theme.palette.grey.default
        },
        '&:hover': {
            color: theme.palette.primary.dark,
            cursor: 'pointer'
        }
    },
    domainHeader: {
        wordBreak: 'break-all'
    },
    attributethead: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        minWidth: 'auto !important'
    },
    dqDimensionContainer: {
        height: '100%'
    },
    domainAttributetxt: {
        wordBreak: 'break-all',
        paddingRight: '15px'
    },
    collapsegridScoreContainer: {
        '& .linearContainer': {
            width: '82% !important',
            marginRight: 'auto !important'
        }
    },
    newAccordionContainer: {
        background: "#f6f8f9",
        margin: 10

    },
    newAccordionRow: {
        background: "#ffffff",
        width: '100%',
        justifyContent: 'space-evenly'
    },
    newdomainListCardContainer: {
        padding: '5px 0px',
        backgroundColor: theme.palette.background.container,
        alignItems: 'center',
        marginBottom: 4,
        width: '100%',
        justifyContent: 'space-evenly',
        '& :last-child': {
            marginBottom: 0
        }
    },
    accordionIco: {
        '& .MuiAccordionSummary-root': {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            minHeight: 30
        }
    },
    reportBg: {
        background: '#f6f8f9'
    },
    filterSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiIconButton-root': {
            padding: 7
        },
        '& .MuiSlider-root': {
            padding: '3px 0 !important'
        },
        '& .searchBox': {
            height: "32.92px !important"
        }
    },
    FilterHideShowContainer: {
        position: 'absolute',
        right: 225,
        background: '#fff',
        zIndex: 99999,
        marginRight: '10px',
        transitionDuration: '500ms',
        transition: 'width .55s ease',
        animation: 'slide-left536 0.4s ease',
        minWidth: '80vw',
        // border: '1px solid #d8d5d5',
        boxShadow: '0px 0px 5px 3px #80808047',
        '& .datasourceFilterSection': {
            minHeight: 60,
            padding: '14px'
        }
    },
    filterPosition: {
        right: "75px !important",
        top: 0
    },
    textEllipse: {
        '& p': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
        }
    },
    wordWrap: {
        wordWrap: 'break-word'
    }
});

export default DomainStyles;