const AuditLogsStyles = (theme) => ({
    listContainer: {
        marginLeft: 10,
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    auditLogPaper: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingRight: 35
    },
    list: {
        borderBottom: `1px solid ${theme.palette.border.default}`
    },
    auditLogContainer: {
        minHeight: 192,
        height: 192,
        overflow: 'hidden',
        borderBottom: 'none !important',
        '& .MuiList-padding': {
            paddingTop: '0px',
            paddingBottom: '0px'
        },
        '& .MuiListItem-root': {
            paddingTop: '4px',
            paddingBottom: '5px'
        }
    },
    logUsername: {
        color: theme.palette.grey.default,
        marginRight: 5,
        fontWeight: 500
    },
    logTimeTxt: {
        fontStyle: 'italic',
        color: theme.palette.grey.light,
        fontSize: 12
    },
    logTxt: {
        color: theme.palette.grey.dark
    },
    showAllBtn: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        fontWeight: 500,
        position: 'absolute',
        float: 'right',
        top: '20px',
        right: '15px',
        zIndex: 1,
        '&:hover': {
            color: theme.palette.primary.dark
        }
    },
    alertNoResult: {
        height: '100%',
        '& img': {
            maxWidth: '50%',
            marginBottom: '-20px'
        }
    },
    alertnolog: {
        maxWidth: '62% !important'
    }
});

export default AuditLogsStyles;