import React, { useCallback, useState, useEffect } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import DatasetStyles from './DatasetStyles.jsx';
import classNames from 'classnames';
import PropertiesSlider from './Properties/TileView/PropertiesSlider.jsx';
import { useSelector, useDispatch } from 'react-redux';
import AttributePropertiesHeader from './Properties/AttributePropertiesHeader.jsx';
import Loader from '../Loaders/Loader.jsx';
import PropertiesListView from './Properties/ListView/PropertiesListView.jsx';
import { updateDatasetProperties, getAttributeProperties, setPropertySliderIndex, setPropertyView, getAttributeRules } from '../../actions/datasetActions';
import { appConstants } from '../../constants/appConstants.js';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';

// Import Redux Actions and Global Constants
import { getAllFieldsByLevel, getCustomTabs } from '../../actions/settingActions';

const DatasetProperties = (props) => {
  const { classes, isEditable, selectedAttribute, datasetId, setSelectedAttribute, profileTab, changeTab, isDeletePermission, isEditPermission, subTabIndex } = props;
  // const [view, setView] = useState('tile');
  const [searchValue, setSearchValue] = useState("");
  const [fields, setFields] = useState([]);
  const [tabFields, setTabFields] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState({});
  const [selectedPropertyIndex, setSelectedPropertyIndex] = useState(0);
  const properties = useSelector(({ dataset }) => dataset.properties);
  const propertyLoading = useSelector(({ dataset }) => dataset.propertyLoading);
  const attributeList = useSelector(({ dataset }) => dataset.propertiesList);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [carouselRef, setCarouselRef] = useState(null);
  const currentIndex = useSelector(({ dataset }) => dataset.propertySliderIndex);
  const view = useSelector(({ dataset }) => dataset.propertyView);
  const [customTabs, setCustomTabs] = useState([]);
  const [attributeThreshold] = useState(30);
  const [loadSize, setLoadSize] = useState(attributeThreshold);

  const searchAttributes = (key) => {
    setSearchValue(key);
    const selectedProperty = attributeList.find((p) => p.name.toLowerCase() === (key.toLowerCase()));
    const attributes = Object.keys(selectedProperties);
    if (selectedProperty && !attributes.includes(selectedProperty.name)) {
      const requestParams = {
        id: selectedProperty.id,
        start: 0,
        end: 0,
        threshold: false,
        dataset_id: datasetId
      };
      loadAttributeProperty(requestParams, "search", selectedProperties);
    }
  };


  const onInitSlider = useCallback(() => {
    if (selectedAttribute && selectedProperties && !isInitialized) {
      const propertyKeys = Object.keys(selectedProperties);
      const selectedProperty = propertyKeys.find((p) => p.toLowerCase() === selectedAttribute.toLowerCase());
      const propertyIndex = propertyKeys.indexOf(selectedProperty);
      setSelectedPropertyIndex(propertyIndex > 0 ? propertyIndex : 0);
      setIsInitialized(true);
    } else {
      setSelectedPropertyIndex(currentIndex > 0 ? currentIndex : 0);
      setIsInitialized(true);
    }
  }, [selectedAttribute, selectedProperties, isInitialized, currentIndex]);


  /**
   * Get Fields List
   */
  const getAllAttributeFields = useCallback(() => {
    Promise.all([dispatch(getAllFieldsByLevel(appConstants.fieldKeys.attribute)), dispatch(getCustomTabs(0, "catalog"))]).then((response) => {
      if (response && response.length > 0) {
        let customFields = [];
        if (response[0] && response[0].length) {
          const fieldList = response[0].filter((data) => data.tab_name === "");
          customFields = response[0].filter((data) => data.tab_name !== "");
          customFields = customFields.sort((a, b) => b.display_order > a.display_order);
          setTabFields([...customFields]);
          setFields([...fieldList]);
        } else {
          setFields([]);
          setTabFields([]);
        }
        if (response[1] && response[1].length) {
          const modifiedTabs = response[0];
          const tabs = modifiedTabs.filter((data) => customFields.some((a) => a.tab_name.toLowerCase() === data.tab_name.toLowerCase())).map((data) => data.tab_name);
          setCustomTabs([...new Set(tabs)]);
        }
      }
    });
  }, [dispatch]);

  const updateProperties = useCallback(() => {
    dispatch(updateDatasetProperties(selectedProperties));
  }, [dispatch, selectedProperties]);

  const loadProperties = useCallback((type, responseProperties, existingProperties) => {
    setLoading(true);
    let propertyItems = { ...responseProperties };
    if (type === "slide" || type === "search") {
      propertyItems = { ...existingProperties, ...responseProperties };
    } else if (type === "listview") {
      propertyItems = { ...existingProperties };
      for (const property of Object.keys(responseProperties)) {
        if (!Object.keys(existingProperties).includes(property)) {
          propertyItems[property] = responseProperties[property];
        }
      }
    }
    setSelectedProperties({ ...propertyItems });
    dispatch(updateDatasetProperties(propertyItems));
    setLoading(false);
    if (carouselRef) {
      if (type === "search") {
        carouselRef.slickGoTo(0);
      } else if (type === "slide") {
        setTimeout(() => { carouselRef.slickNext(); dispatch(setPropertySliderIndex(Object.keys(propertyItems).length - 1)); }, 30);
      }
    }
  }, [carouselRef, dispatch]);

  const loadInitialAttributeProperty = useCallback(() => {
    setLoading(true);
    const requestParams = {
      threshold: attributeList.length <= attributeThreshold,
      start: 0,
      end: attributeThreshold,
      id: null,
      dataset_id: parseInt(datasetId),
      include_attribute: selectedAttribute
    };
    dispatch(getAttributeProperties(requestParams)).then((response) => {
      if (response) {
        loadProperties("initial", { ...response }, { ...properties });
      }
      setLoading(false);
    });
  }, [attributeList.length, attributeThreshold, datasetId, dispatch, loadProperties, properties, selectedAttribute]);


  const loadAttributeProperty = (params, type, existingProperties) => {
    setLoading(true);
    dispatch(getAttributeProperties(params)).then((response) => {
      if (response) {
        loadProperties(type, { ...response }, existingProperties);
      }
      setLoading(false);
    });
  };

  const loadListProperties = () => {
    if (loadSize <= attributeList.length && !loading) {
      const requestParams = {
        id: null,
        threshold: false,
        start: loadSize,
        end: (loadSize + attributeThreshold),
        dataset_id: datasetId
      };
      setLoading(true);
      setLoadSize(loadSize + attributeThreshold);
      loadAttributeProperty(requestParams, "listview", selectedProperties);
    }
  };

  const changeSlider = (index) => {
    const propertyList = Object.keys(selectedProperties);
    if (!propertyList.includes(attributeList[index].name)) {
      const requestParams = {
        id: attributeList[index] ? attributeList[index].id : 0,
        threshold: false,
        start: 0,
        end: 0,
        dataset_id: datasetId
      };
      loadAttributeProperty(requestParams, "slide", selectedProperties);
    }
  };

  useEffect(() => {
    getAllAttributeFields();
  }, [getAllAttributeFields]);

  useEffect(() => {
    if (selectedProperties && Object.keys(selectedProperties).length === 0) {
      loadInitialAttributeProperty();
    }
  }, [loadInitialAttributeProperty, selectedProperties]);


  const updateSelectedproperties = useCallback((updatedProperties) => {
    if (updatedProperties) {
      selectedProperties[updatedProperties.name] = { ...selectedProperties[updatedProperties.name], ...updatedProperties.value };
      if (updatedProperties.value.primary_key) {
        const attributeKeys = Object.keys(selectedProperties);
        for (const key of attributeKeys) {
          if (updatedProperties.name !== key) {
            selectedProperties[key]["primary_key"] = false;
          }
        }
      }
      setSelectedProperties({ ...selectedProperties });
      updateProperties();
    }
  }, [selectedProperties, updateProperties]);


  const listViewUpdateProperties = useCallback((property) => {
    if (property) {
      selectedProperties[property.attribute] = { [property.property]: property.value, ...selectedProperties[property.attribute] };
      setSelectedProperties({ ...selectedProperties });
      updateProperties();
    }
  }, [selectedProperties, updateProperties]);

  const setCarouselReference = (slider) => {
    setCarouselRef(slider);
    if (slider && selectedPropertyIndex) {
      slider.slickGoTo(selectedPropertyIndex);
      setTimeout(() => dispatch(setPropertySliderIndex(selectedPropertyIndex)), 0);
      setSelectedPropertyIndex(0);
    }
  };


  const setView = (view) => {
    dispatch(setPropertyView(view));
  };

  const setProfileTab = (attr) => {
    setSelectedAttribute(attr);
    if (profileTab >= 0) {
      changeTab(profileTab);
    }
  };

  const getAttributeRuleInfo = (attributeId) => {
    dispatch(getAttributeRules(attributeId));
  };

  const updateFieldProperties = (propertiesInfo, attribute) => {
    const property = propertiesInfo[attribute.name];
    if (property) {
      selectedProperties[attribute.name] = { ...property };
      setSelectedProperties({ ...selectedProperties });
      getAttributeRuleInfo(attribute.attribute);
    }

  };

  const searchProperty = () => {
    return { [searchValue]: selectedProperties[searchValue] ? selectedProperties[searchValue] : {} };
  };

  const length = Object.keys(selectedProperties).length;

  const filterProperties = searchValue.length !== 0 ? searchProperty() : selectedProperties;
  return (
    <React.Fragment>
      {loading && <Loader />}
      {
        (length) ?
          <Grid container direction="column" wrap="nowrap">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <AttributePropertiesHeader
                view={view}
                carouselRef={carouselRef}
                onSearchKeyChange={(key) => searchAttributes(key)}
                onViewChange={(view) => setView(view)}
                setSelectedAttribute={setSelectedAttribute}
                changeSlider={changeSlider} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ position: 'relative' }}>
              {
                view === 'tile' &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classNames(classes.propertySliderContainer, classes.marginTop15)}>
                  <PropertiesSlider
                    datasetId={datasetId}
                    setCarouselRef={setCarouselReference}
                    onInitSlider={(event) => onInitSlider(event)}
                    properties={filterProperties}
                    selectedAttribute={selectedAttribute}
                    isEditable={isEditable}
                    updateProperties={updateSelectedproperties}
                    fields={fields}
                    setSelectedAttribute={setSelectedAttribute}
                    datasetProperties={properties}
                    tabFields={tabFields}
                    customTabs={customTabs}
                    isDeletePermission={isDeletePermission}
                    isEditPermission={isEditPermission}
                    updateFieldProperties={updateFieldProperties}
                  />
                </Grid>
              }
              {
                view === 'list' &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classNames(classes.propertySliderContainer, classes.marginTop15)}>
                  <PropertiesListView
                    datasetId={datasetId}
                    properties={filterProperties}
                    selectedAttribute={selectedAttribute}
                    isEditable={isEditable}
                    updateProperties={listViewUpdateProperties}
                    fields={fields}
                    tabFields={tabFields}
                    setSelectedAttribute={(attr) => setProfileTab(attr)}
                    customTabs={customTabs}
                    isDeletePermission={isDeletePermission}
                    isEditPermission={isEditPermission}
                    loadListProperties={loadListProperties}
                    attributeLoading={loading}
                    subTabIndex={subTabIndex}
                  />
                </Grid>
              }
              {(loading || propertyLoading) && <Loader classList={classes.loaderContainer} />}
            </Grid>
          </Grid>
          : <NoResultFound />
      }
    </React.Fragment>
  );
};

DatasetProperties.propTypes = {
  classes: PropTypes.object,
  isEditable: PropTypes.bool,
  selectedAttribute: PropTypes.string,
  datasetId: PropTypes.string,
  setSelectedAttribute: PropTypes.func,
  profileTab: PropTypes.number,
  changeTab: PropTypes.func,
  isDeletePermission: PropTypes.bool,
  isEditPermission: PropTypes.bool,
  subTabIndex: PropTypes.number
};

export default withStyles((theme) => ({
  ...DatasetStyles(theme),
  ...Styles(theme)
}), { withTheme: true })(DatasetProperties);