import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Popover, withStyles, Typography, ButtonGroup, Button, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import TodayIcon from '@material-ui/icons/Today';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';

import AutoComplete from '../../AutoComplete/AutoComplete.jsx';
import NumberInput from '../../TextBox/NumberInput.jsx';
import MasterScheduleStyles from './MasterScheduleStyles.jsx';
import Styles from '../../../layouts/Styles.jsx';
import { appConstants } from '../../../constants/appConstants';
import ScheduleMonthCalender from '../../ScheduleMonthCalender/ScheduleMonthCalender.jsx';
import TextBox from '../../TextBox/TextBox.jsx';
import { createSemanticModelSchedule, getSemanticModelSchedule, updateSemanticModelSchedule, deleteSemanticModelSchedule } from '../../../actions/masterActions.js';
import ToolTipComponent from '../../Tooltip/Tooltip.jsx';


const MasterSchedule = (props) => {
    const { classes, theme, scheduleProps, onClose } = props;
    const zones = [{ name: moment.tz.guess() }, ...appConstants.TimeZones];
    const defaultParams = {
        "schedule_method": "Month",
        "week_day": ["Sunday"],
        "month_date": [moment(new Date()).format('DD').replace(/^0+/, '')],
        "date": new Date(),
        "time": new Date(),
        "time_zone": zones[0] ? zones[0].name : ""
    };
    const [semanticModelSchedule, setSemanticModelSchedule] = useState({ ...defaultParams });
    const [timeZones] = useState([...zones]);
    const dispatch = useDispatch();
    const [open, setMonthCalender] = useState(false);
    const [anchorEl, setAchorEl] = useState(null);

    useEffect(() => {
        if (!scheduleProps.modelId) {
            return;
        }
        dispatch(getSemanticModelSchedule(scheduleProps.modelId)).then((response) => {
            if (response && Object.keys(response).length !== 0) {
                const hour = response && response.day_week_month_time ? response.day_week_month_time : '';
                const min = response && response.day_week_month_minitues ? response.day_week_month_minitues : '';
                let time = `${hour}:${min}`;
                const hasTime = (time.split(':').filter((p) => p.length > 0)).length > 0;
                if (!hasTime) {
                    time = Date.now();
                } else {
                    time = time ? moment(moment().tz(response.time_zone).format('YYYY-MM-DD') + ` ${time}`) : '';
                }
                const schedule = {
                    "id": response.id,
                    "minitues_interval": response.minitues_interval,
                    "time_zone": response.time_zone,
                    "week_day": response.week_day !== "" ? response.week_day.split(',') : ["Sunday"],
                    "month_date": response.month_date !== "" ? response.month_date.split(',') : [moment(new Date()).format('DD').replace(/^0+/, '')],
                    "schedule_method": response.schedule_method,
                    "date": moment(response.start_time).tz(response.time_zone),
                    "time": moment(response.start_time).tz(response.time_zone),
                    "day": time
                };
                setSemanticModelSchedule(schedule);
            }
        });
    }, [scheduleProps.modelId, dispatch, setSemanticModelSchedule]);

    const changeSchedule = (value, property) => {
        const schedule = semanticModelSchedule;
        if (property === "week_day" || property === "month_date") {
            const index = schedule[property].indexOf(value);
            if (index !== -1) {
                schedule[property].splice(index, 1);
            } else {
                schedule[property].push(value);
            }
        } else {
            if (property === "schedule_method") {
                schedule["minitues_interval"] = '';
                if (value === "Minute") {
                    schedule["minitues_interval"] = 60;
                }
                if (value === "Hour") {
                    schedule["minitues_interval"] = 1;
                }
            }
            if (property === 'time_zone') {
                schedule.date = moment(schedule.date).tz(value);
                schedule.time = moment(schedule.time).tz(value);
            }
            schedule[property] = value;
        }
        setSemanticModelSchedule({ ...schedule });
    };

    const closeMonthCalender = () => {
        setAchorEl(null);
        setMonthCalender(false);
    };

    const deleteSchedule = useCallback(() => {
        if (semanticModelSchedule && semanticModelSchedule.id) {
            dispatch(deleteSemanticModelSchedule(scheduleProps.modelId));
            setSemanticModelSchedule({ ...semanticModelSchedule, id: null });
        }
        onClose();
    }, [dispatch, onClose, scheduleProps.modelId, semanticModelSchedule]);


    const openMonthCalender = (event) => {
        setAchorEl(event.target);
        setMonthCalender(true);
    };

    const onSchedule = useCallback(() => {
        const hours = semanticModelSchedule.day !== "" ? parseInt(moment(semanticModelSchedule.day).format("HH")) : 0;
        const minitues = semanticModelSchedule.day !== "" ? parseInt(moment(semanticModelSchedule.day).format("mm")) : 0;
        const schedule = {
            "schedule_method": semanticModelSchedule.schedule_method,
            "minitues_interval": semanticModelSchedule.minitues_interval !== "" ? semanticModelSchedule.minitues_interval : 0,
            "time_zone": semanticModelSchedule.time_zone,
            "week_day": semanticModelSchedule.schedule_method === "Week" ? semanticModelSchedule.week_day.toString() : '',
            "month_date": semanticModelSchedule.schedule_method === "Month" ? semanticModelSchedule.month_date.toString() : '',
            "start_time": `${moment(semanticModelSchedule.date).format('YYYY-MM-DD')} ${moment(semanticModelSchedule.time).format('HH:mm')}`,
            "day_week_month_time": hours,
            "day_week_month_minitues": minitues,
            "model_id": scheduleProps.modelId ? parseInt(scheduleProps.modelId) : 0,
            'user_timezone': moment.tz.guess()
        };
        if (!semanticModelSchedule.id) {
            dispatch(createSemanticModelSchedule(schedule)).then((response) => {
                if (response) {
                    setSemanticModelSchedule({ ...semanticModelSchedule, id: response.id });
                    onClose();
                }
            });
        } else {
            dispatch(updateSemanticModelSchedule(schedule)).then((response) => {
                if (response) {
                    onClose();
                }
            });
        }
    }, [semanticModelSchedule, dispatch, scheduleProps.modelId, onClose]);

    const getMinDate = (date) => {
        date = new Date(date);
        let minDate = date;
        const currentDate = new Date();
        if (currentDate < date) {
            minDate = currentDate;
        }
        return minDate;
    };

    return (
        <Popover
            open={scheduleProps.open}
            anchorEl={scheduleProps.anchorElement}
            onClose={() => onClose()}
            anchorOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }
            transformOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            }>
            <ValidatorForm autoComplete="off" className={classNames(classes.scheduleContainer)} onSubmit={() => onSchedule()}>
                <Grid>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Typography component="h5" variant="h5">
                            {'Schedule'}
                        </Typography>

                        <ToolTipComponent title={"Clear Schedule"} arrow>
                            <IconButton style={{ padding: 0 }} onClick={() => deleteSchedule()}>
                                <svg version="1.1" id="Layer_1" width="22" height="22" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
                                    <g id="Delete">
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                    </g>
                                </svg>
                            </IconButton>
                        </ToolTipComponent>
                    </Grid>
                    <Grid container direction="row" justify="space-between" className={classes.marginTop15}>
                        <DatePicker autoOk
                            variant="inline"
                            label="Start Date"
                            format="MM/DD/YYYY"
                            minDate={semanticModelSchedule.date ? getMinDate(semanticModelSchedule.date) : new Date()}
                            value={semanticModelSchedule.date ? semanticModelSchedule.date : Date.now()}
                            onChange={(date) => changeSchedule(date, 'date')}
                        />
                        <TimePicker
                            ampm={false}
                            label="Start Time"
                            format="HH:mm"
                            value={semanticModelSchedule.time ? semanticModelSchedule.time : Date.now()}
                            onChange={(time) => changeSchedule(time, 'time')}
                        />
                    </Grid>
                    <Grid className={classNames(classes.marginTop15, classes.schedulerSection)}>
                        <Grid container justify="center" alignItems="center">
                            <ButtonGroup className={classes.TimingBtnGroup}>

                                {
                                    appConstants.scheduleMethods.map((method, index) =>
                                        <Button
                                            className={classNames(classes.tabBtn, method.value === semanticModelSchedule.schedule_method ? 'active' : '')}
                                            variant="contained"
                                            key={`schedule_${index}`}
                                            onClick={() => changeSchedule(method.value, 'schedule_method')}>
                                            {method.name}
                                        </Button>
                                    )
                                }
                            </ButtonGroup>
                        </Grid>
                        <Grid className={classes.scheduleContent}>
                            {
                                appConstants.scheduleMethods[0].value === semanticModelSchedule.schedule_method &&
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <Grid item xs={4}>
                                        <NumberInput label="Every"
                                            id="minute"
                                            value={semanticModelSchedule.minitues_interval ? semanticModelSchedule.minitues_interval : ''}
                                            onChange={(event) => changeSchedule(event.target.value, "minitues_interval")}
                                            validators={['required', 'maxNumber:60']}
                                            errorMessages={['Duration is required', 'Enter below 60 Minutes']}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>
                                            Minute(s)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            {
                                appConstants.scheduleMethods[1].value === semanticModelSchedule.schedule_method &&
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <Grid item xs={4}>
                                        <NumberInput label="Every"
                                            id="hour"
                                            value={semanticModelSchedule.minitues_interval ? semanticModelSchedule.minitues_interval : ''}
                                            onChange={(event) => changeSchedule(event.target.value, "minitues_interval")}
                                            validators={['required', 'maxNumber:24']}
                                            errorMessages={['Duration is required', 'Enter below 24 Hours']}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>
                                            Hour(s)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            {
                                appConstants.scheduleMethods[2].value === semanticModelSchedule.schedule_method &&
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <TimePicker
                                        ampm={false}
                                        label="Select a Time"
                                        format="HH:mm"
                                        value={semanticModelSchedule.day ? semanticModelSchedule.day : Date.now()}
                                        onChange={(time) => changeSchedule(time, "day")}
                                    />
                                </Grid>
                            }
                            {
                                appConstants.scheduleMethods[3].value === semanticModelSchedule.schedule_method &&
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <ButtonGroup>
                                        {
                                            appConstants.weekDays.map((days, index) =>
                                                <Button
                                                    onClick={() => changeSchedule(days.value, "week_day")}
                                                    variant="contained"
                                                    key={`days_${index}`}
                                                    className={classNames(classes.dayBtn, semanticModelSchedule.week_day.indexOf(days.value) !== -1 ? 'active' : '')}
                                                >
                                                    {days.name}
                                                </Button>
                                            )
                                        }
                                    </ButtonGroup>
                                    <TimePicker
                                        ampm={false}
                                        label="Select a Time"
                                        format="HH:mm"
                                        margin="normal"
                                        value={semanticModelSchedule.day ? semanticModelSchedule.day : Date.now()}
                                        onChange={(time) => changeSchedule(time, "day")}
                                    />
                                </Grid>
                            }
                            {
                                appConstants.scheduleMethods[4].value === semanticModelSchedule.schedule_method &&
                                <Grid container align="center" justify="center" spacing={4}>
                                    <Grid item>
                                        <TextBox
                                            label="Select a Date"
                                            value={semanticModelSchedule.month_date.toString()}
                                            readOnly
                                        />
                                        <IconButton onClick={(event) => openMonthCalender(event)}>
                                            <TodayIcon className={classes.calendarIcon} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <TimePicker
                                            ampm={false}
                                            label="Select a Time"
                                            format="HH:mm"
                                            // margin="normal"
                                            value={semanticModelSchedule.day ? semanticModelSchedule.day : Date.now()}
                                            onChange={(time) => changeSchedule(time, "day")}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid container className={classes.marginTop15}>
                        <Grid item xs={12}>
                            <AutoComplete
                                label="Timezone"
                                placeholder="Select Timezone"
                                onChange={(event) => changeSchedule(event.target.value, "time_zone")}
                                onBlur={() => { }}
                                value={semanticModelSchedule.time_zone ? semanticModelSchedule.time_zone : ''}
                                validators={['required']}
                                errorMessages={['Timezone is required']}
                                options={timeZones}
                                selectedValuePath="name"
                                displayMemberPath="name"
                                removeClass
                                openSuggestionsOnClick
                                name="timzone"
                                id="timzone"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-end" alignItems="center" className={classes.marginTop10}>
                        <Button className={classes.tabBtn} type="submit" variant="contained" color="primary">
                            Submit
                        </Button>
                        <Button onClick={() => onClose()} style={{ marginLeft: '10px' }} variant="contained" className={classNames(classes.cancelBtn, classes.tabBtn)}>
                            Cancel
                        </Button>
                    </Grid>

                </Grid>
                {
                    open && <ScheduleMonthCalender
                        onOpenPopover={open}
                        anchorElement={anchorEl}
                        onClose={() => closeMonthCalender()}
                        onDateSelectionChange={(day) => changeSchedule(day, "month_date")}
                        selectedDays={semanticModelSchedule.month_date}
                    />
                }
            </ValidatorForm>
        </Popover>
    );
};

MasterSchedule.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    scheduleProps: PropTypes.object,
    onClose: PropTypes.func
};

export default withStyles((theme) => ({
    ...MasterScheduleStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MasterSchedule);