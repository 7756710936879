import React, { useCallback, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, withStyles, Typography, Table, TableRow, TableHead, TableCell, TableBody } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import Styles from '../../layouts/Styles.jsx';
import MasterStyles from '../Master/MasterStyles.jsx';
import { getAttributes, createAttribute, updateAttributes, deleteAttributes } from '../../actions/modelActions';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';
import Loader from '../Loaders/Loader.jsx';
import SemanticModelAttributeRow from './SematicModelAttributeRow.jsx';
import Search from '../TextBox/Search.jsx';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import classNames from 'classnames';

const SemanticModelAttributes = (props) => {
    const { classes, tabIndex, modelId } = props;

    const [attributes, setAttributes] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [search, setSearch] = useState("");


    const addAttribute = useCallback(() => {
        const defaultAttributes = { "name": '', "type": '', "datatype": '', "min_length": '', "max_length": '', "threshold": '67', "sensitivity": 1, "keyword": { contains: [], equals: [] }, "patterns": [], 'enum': { values: [] }, "is_active": true };
        const attributeIndex = attributes.findIndex((p) => !p.id);
        if (attributeIndex >= 0) {
            return;
        }
        attributes.push({ ...defaultAttributes });
        setAttributes([...attributes]);
    }, [attributes]);

    const onChangeProperties = useCallback((attribute) => {
        let attributeIndex = attributes.findIndex((p) => p.id === attribute.id);
        if (!attribute.id) {
            attributeIndex = attributes.length - 1;
        }
        if (attributeIndex >= 0) {
            attributes.splice(attributeIndex, 1, { ...attribute });
        }
        setAttributes([...attributes]);
    }, [attributes]);

    const onUpdateProperties = useCallback((attributeData) => {
        const attribute = { ...attributeData };
        const requestParams = {
            ...attribute,
            "semantic_model_id": modelId
        };
        if (attribute.id) {
            dispatch(updateAttributes(attribute.id, requestParams));
        } else {
            dispatch(createAttribute(requestParams)).then((response) => {
                if (response) {
                    attribute.id = response.id;
                    const index = attributes.findIndex((data) => data.name === attribute.name);
                    if (index !== -1) {
                        attributes.splice(index, 1, { ...attribute });
                        setAttributes([...attributes]);
                    }
                }
            });
        }
    }, [attributes, dispatch, modelId]);


    const getAllAttributes = useCallback(() => {
        const defaultAttributes = { "name": '', "type": '', "datatype": '', "min_length": '', "max_length": '', "threshold": '67', "sensitivity": 1, "keyword": { contains: [], equals: [] }, "patterns": [], 'enum': { values: [] }, "is_active": true };
        dispatch(getAttributes(modelId)).then((response) => {
            if (response) {
                if (response.length === 0) {
                    response = [{ ...defaultAttributes }];
                }
                setAttributes([...response]);
            }
            setIsLoading(false);
        });
    }, [dispatch, modelId]);

    useEffect(() => {
        if (tabIndex !== 0) {
            return;
        }
        setIsLoading(true);
        getAllAttributes();
    }, [getAllAttributes, tabIndex]);

    const onAlertCancel = useCallback(() => {
        setTimeout(() => {
            setSelectedAttribute(null);
        }, 200);
    }, []);

    const deleteModelAttribute = useCallback((attribute) => {
        if (attribute.id) {
            dispatch(deleteAttributes(modelId, attribute.id));
        }
        let attributeIndex = attributes.findIndex((p) => p.id === attribute.id);
        if (!attribute.id) {
            attributeIndex = attributes.length - 1;
        }
        if (attributeIndex >= 0) {
            attributes.splice(attributeIndex, 1);
        }
        setAttributes([...attributes]);
        onAlertCancel();
    }, [attributes, dispatch, modelId, onAlertCancel]);

    const attributeList = search.length ? attributes.filter((attribute) => attribute.name.toLowerCase().includes(search.toLowerCase())) : attributes;

    return (
        <Grid container style={{ position: 'relative' }}>
            <ValidatorForm name="model_attributes" style={{ width: '100%' }} onSubmit={() => null}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item xs={3}>
                        <Search
                            value={search ? search : ''}
                            placeholder="Search Here"
                            className={classes.dqSearchBox}
                            onChange={(value) => setSearch(value)}
                        />
                    </Grid>

                    <Grid item align="right">
                        <TooltipComponent title="Add Field" placement="bottom" arrow>
                            <IconButton onClick={() => addAttribute()} className={classes.addIconButton}>
                                <AddIcon />
                            </IconButton>
                        </TooltipComponent>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.marginTop15}>
                    <Grid container direction="row" justify="center" className={classNames(classes.masterAttributesTableContainer, classes.autoHeight)}>
                        <Table stickyHeader className={classes.propertyListPageTable}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography variant="body1" className={classes.tableHeader}>
                                            {'Field Name'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '8%' }}>
                                        <Typography variant="body1" className={classes.tableHeader}>
                                            {'Data Type'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '12%' }}>
                                        <Typography variant="body1" className={classes.tableHeader}>
                                            {'Length'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography variant="body1" className={classes.tableHeader}>
                                            {'Sensitivity'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '10%' }}>
                                        <Typography variant="body1" className={classes.tableHeader}>
                                            {'Threshold'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '12%' }}>
                                        <Typography variant="body1" className={classes.tableHeader}>
                                            {'Patterns'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '20%' }}>
                                        <Typography variant="body1" className={classes.tableHeader}>
                                            {'Keyword'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell style={{ width: '12%' }}>
                                        <Typography variant="body1" className={classes.tableHeader}>
                                            {'Enum'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="body1" className={classes.tableHeader}>
                                            {'Actions'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    attributeList.map((attribute, index) =>
                                        <Fragment key={`attribute_${index}`}>
                                            <SemanticModelAttributeRow
                                                onChangeProperty={(selectedAttribute) => onChangeProperties(selectedAttribute)}
                                                onValueChange={(selectedAttribute, property, value) => onUpdateProperties(selectedAttribute, property, value)}
                                                attribute={attribute}
                                                onDelete={(attribute) => setSelectedAttribute(attribute)} />
                                            <TableRow className={classes.emptyRow}>
                                                <TableCell colSpan={12} />
                                            </TableRow>
                                        </Fragment>
                                    )
                                }
                            </TableBody>
                        </Table>
                        {
                            attributeList.length === 0 &&
                            <Grid className={classes.attributesEmptyContainer}>
                                <NoResultFound />
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </ValidatorForm>
            {isLoading && <Loader />}
            <AlertDialog title={"Delete Attribute"}
                message={"Deleting this attribute already mapped in fieldname. Are you sure you want to delete the selected attribute?"}
                okButtonText="OK"
                cancelButtonText={selectedAttribute ? "Cancel" : ""}
                show={Boolean(selectedAttribute)}
                onClickOK={
                    () => {
                        if (selectedAttribute) {
                            deleteModelAttribute(selectedAttribute);
                        } else {
                            onAlertCancel();
                        }
                    }
                }
                onClickCancel={() => onAlertCancel()} />
        </Grid >
    );
};

SemanticModelAttributes.propTypes = {
    classes: PropTypes.object,
    modelId: PropTypes.string,
    tabIndex: PropTypes.number
};

export default withStyles((theme) => ({
    ...MasterStyles(theme),
    ...Styles(theme)
}))(SemanticModelAttributes);