const ChartContainerStyles = (theme) => ({
    icon: {
        padding: 5
    },
    paddingSix: {
        padding: 6
    },
    chartMenuContainer: {
        '& .MuiListItem-gutters': {
            paddingLeft: 15,
            paddingRight: 15
        },
        '& .MuiInput-root': {
            minWidth: 65
        }
    },
    iconColor: {
        color: theme.palette.grey.dark
    },
    DropDownIcon: {
        top: "auto !important",
        right: '0',
        position: 'absolute',
        pointerEvents: 'none'
    },
    toggleContainer: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-body1': {
            fontSize: 15,
            paddingLeft: 10,
            paddingRight: 10,
            color: theme.palette.grey.dark
        },
        '& .MuiTypography-body1:last-child': {
            paddingRight: 10
        },
        '& .active': {
            color: theme.palette.secondary.main
        }
    }
});

export default ChartContainerStyles;