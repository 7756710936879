import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withStyles, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import { getDomainAsset } from '../../actions/scheduleActions';
import GroupedBarChart from '../Charts/GroupedBarChart.jsx';
import DashboardStyles from './DashboardStyles.jsx';

const DashboardDomainAsset = (props) => {
    const { theme, classes } = props;
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    const [domainAsset, setDomainAsset] = useState({});

    const getDomainDQScore = useCallback(() => {
        dispatch(getDomainAsset()).then((response) => {
            if (response) {
                const analysis = {
                    data: [],
                    subgroups: [],
                    groups: []
                };
                for (const asset of response) {
                    analysis.groups.push(asset.name);
                    const keys = Object.keys(asset).filter((d) => d !== "id" && d !== "name");
                    const data = {
                        group: asset.name
                    };
                    for (const key of keys) {
                        data[key] = asset[key];
                        analysis.subgroups.push(key);
                    }
                    analysis.data.push({
                        ...data,
                        value: Math.max(...Object.values(data).filter((a) => typeof (a) === "number"))
                    });
                }
                setDomainAsset({ ...analysis });
            }
            setLoading(false);
        });
    }, [dispatch]);

    useEffect(() => {
        getDomainDQScore();
    }, [getDomainDQScore]);

    return (
        <Grid className={classes.section}>
            <Typography variant="h4" component="h4">
                Assets Per Domain
            </Typography>
            <GroupedBarChart
                className={classes.relative}
                chartClassName="domain-asset-chart"
                analysis={domainAsset}
                chartHeight={220}
                showYAxis
                barWidth={15}
                align={"center"}
                legends={[{ name: "Datasource", color: theme.palette.domainAsset[0] }, { name: "Dataset", color: theme.palette.domainAsset[1] }, { name: "Attribute", color: theme.palette.domainAsset[2] }]}
                colors={theme.palette.domainAsset}
                tooltipName="Asset"
                isLoading={isLoading}
            />
        </Grid>
    );
};

DashboardDomainAsset.propTypes = {
    theme: PropTypes.object,
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DashboardDomainAsset);