import React, { useState, useCallback } from 'react';
import { Grid, withStyles, Typography, ButtonGroup, Button, IconButton, Chip, TextField, InputAdornment } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import TodayIcon from '@material-ui/icons/Today';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';

import AutoComplete from '../AutoComplete/AutoComplete.jsx';
import NumberInput from '../TextBox/NumberInput.jsx';
import ScheduleStyles from './ScheduleStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import { appConstants } from '../../constants/appConstants';
import ScheduleMonthCalender from '../ScheduleMonthCalender/ScheduleMonthCalender.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import { removeDataSourceSchedule, removeSchedule } from '../../actions/scheduleActions';
import CheckboxComponent from '../ChecboxComponent/CheckboxComponent.jsx';
import AlertDialog from '../../components/AlertDialog/AlertDialog.jsx';
import { Autocomplete } from '@material-ui/lab';


const TimeSchedule = (props) => {
    const { classes, scheduleProps, onClose, sourceType, datasetSchedule, setDatasetSchedule, isExistingSchedule, setExistingSchedule, defaultParams, onSubmitSchedule, submission, setSubmission } = props;
    const moduleConfig = useSelector(({ setting }) => setting.config);
    const zones = [{ name: moment.tz.guess() }, ...appConstants.TimeZones];
    const [showRemoveSchedule, setShowRemoveSchedule] = useState(false);

    const [timeZones] = useState([...zones]);
    const dispatch = useDispatch();
    const [open, setMonthCalender] = useState(false);
    const [updatetime, setupdatetime] = useState(true);
    const [anchorEl, setAchorEl] = useState(null);

    const changeSchedule = (value, property) => {
        const schedule = datasetSchedule;
        if (isExistingSchedule) {
            if (property === "date" || property === "time") {
                setupdatetime(false);
            }
        }
        if (property === "start_profile" && value === false) {
            schedule['start_export_metrics'] = false;
        }

        if (property === "start_export_metrics" && value === true) {
            if (schedule['start_profile'] === false) {
                schedule['start_export_metrics'] = false;
                return false;
            }
        }

        if (property === "week_day" || property === "month_date") {
            if (isExistingSchedule && updatetime) {
                const get_datasetSchedule = datasetSchedule;
                get_datasetSchedule.date = new Date();
                get_datasetSchedule.time = new Date();
                setDatasetSchedule(get_datasetSchedule);
            }
            const index = schedule[property].indexOf(value);
            if (index !== -1) {
                schedule[property].splice(index, 1);
            } else {
                schedule[property].push(value);
            }

            if (schedule[property].length === 0) {
                setSubmission(false);
            } else {
                setSubmission(true);
            }
        } else if (property === 'day') {
            if (isExistingSchedule && updatetime) {
                const get_datasetSchedule = datasetSchedule;
                get_datasetSchedule.date = new Date();
                get_datasetSchedule.time = new Date();
                setDatasetSchedule(get_datasetSchedule);
            }
            const scheduleCopy = JSON.parse(JSON.stringify(schedule[property]));
            const dayIndex = scheduleCopy.map(((elem) => moment(elem).format('hh:mm A'))).indexOf(moment(value).format('hh:mm A'));
            if (dayIndex < 0 && schedule[property].length < 12) {
                schedule[property] = [...schedule[property], moment(value)];
            }
        } else {
            if (property === "schedule_method") {
                schedule["minitues_interval"] = '';
                if (value === "Minute") {
                    schedule["minitues_interval"] = 60;
                }
                if (value === "Hour") {
                    schedule["minitues_interval"] = 1;
                }
            }
            if (property === 'time_zone') {
                schedule.date = moment(schedule.date).tz(value);
                schedule.time = moment(schedule.time).tz(value);
            }
            schedule[property] = value;
        }
        setDatasetSchedule({ ...schedule });
    };

    const closeMonthCalender = () => {
        setAchorEl(null);
        setMonthCalender(false);
    };

    const openMonthCalender = (event) => {
        setAchorEl(event.target);
        setMonthCalender(true);
    };

    const onSchedule = useCallback(() => {
        if ((datasetSchedule.schedule_method === 'Month' || datasetSchedule.schedule_method === 'Week' || datasetSchedule.schedule_method === 'Day') && (!datasetSchedule?.day || datasetSchedule?.day.length === 0)) {
            return;
        }
        setSubmission(false);
        let schedule_time = [];
        let minutesInterval = 0;
        if (datasetSchedule.schedule_method === "Hour") {
            minutesInterval = (datasetSchedule.minitues_interval !== "" ? parseInt(datasetSchedule.minitues_interval) * 60 : 0);
        } else {
            minutesInterval = (datasetSchedule.minitues_interval !== "" ? datasetSchedule.minitues_interval : 0);
        }

        if (datasetSchedule.schedule_method === 'Month' || datasetSchedule.schedule_method === 'Week' || datasetSchedule.schedule_method === 'Day') {
            schedule_time = datasetSchedule.day ? datasetSchedule.day.map((elem) => `${moment(elem).format('YYYY-MM-DD HH:mm')}`) : [];
        }

        const schedule = {
            "schedule_method": datasetSchedule.schedule_method,
            "start_profile": datasetSchedule.start_profile,
            "start_curate": datasetSchedule.start_curate,
            "start_export_metrics": datasetSchedule.start_export_metrics,
            "minitues_interval": minutesInterval,
            "time_zone": datasetSchedule.time_zone,
            "week_day": datasetSchedule.schedule_method === "Week" ? datasetSchedule.week_day.toString() : '',
            "month_date": datasetSchedule.schedule_method === "Month" ? datasetSchedule.month_date.toString() : '',
            "start_time": `${moment(datasetSchedule.date).format('YYYY-MM-DD')} ${moment(datasetSchedule.time).format('HH:mm')}`,
            "day_week_month_time": 0,
            "day_week_month_minitues": 0,
            "schedule_time": schedule_time,
            "dataset_id": scheduleProps.datasetId,
            "source_id": scheduleProps.datasourceId,
            'user_timezone': moment.tz.guess()
        };

        if (moduleConfig && !moduleConfig.curate) {
            schedule['start_curate'] = false;
        }
        if (moduleConfig && !moduleConfig.export_metrics) {
            schedule['start_export_metrics'] = false;
        }
        if (moduleConfig && !moduleConfig.profile) {
            schedule['start_profile'] = false;
        }
        onSubmitSchedule(schedule);
    }, [datasetSchedule, scheduleProps.datasetId, scheduleProps.datasourceId, scheduleProps.isDataset, scheduleProps.selectedDataset, moduleConfig, dispatch, onClose]);

    const getMinDate = (date) => {
        date = new Date(date);
        let minDate = date;
        const currentDate = new Date();
        if (currentDate < date) {
            minDate = currentDate;
        }
        return minDate;
    };

    const onRemoveSchedule = () => {
        if (scheduleProps.isDataset) {
            const selectedDataset = {
                'dataset_list': scheduleProps.selectedDataset,
                'datasource_id': scheduleProps.datasourceId
            };
            dispatch(removeDataSourceSchedule(selectedDataset)).then(() => {
                setDatasetSchedule({ ...defaultParams });
                setExistingSchedule(false);
                setShowRemoveSchedule(false);
                onClose();
            });
        } else {
            dispatch(removeSchedule(scheduleProps.datasetId)).then(() => {
                setDatasetSchedule({ ...defaultParams });
                setExistingSchedule(false);
                setShowRemoveSchedule(false);
                onClose();
            });
        }
    };

    const showRemoveAlert = () => {
        setShowRemoveSchedule(true);
    };

    const closeScheduleAlert = () => {
        setShowRemoveSchedule(false);
    };

    const removeTimeFromSchedule = (index) => {
        const schedule = datasetSchedule;
        if (index >= 0) {
            schedule.day.splice(index, 1);
            setDatasetSchedule({ ...schedule });
        }
    };

    const getTimeHelperText = () => {
        if (datasetSchedule.day.length === 12) {
            return 'Schedule reached max limit for the day';
        }
        if (!datasetSchedule.day || datasetSchedule?.day.length === 0) {
            return 'Time is required';
        }
        return '';
    };

    return (
        <ValidatorForm autoComplete="off" className={classNames(classes.scheduleContainer)} onSubmit={() => onSchedule()}>
            <Grid>
                <Typography component="h5" variant="h5">
                    {'Schedule Profile'}
                </Typography>
                <Grid container direction="row" justify="space-between" className={classes.marginTop15}>
                    <DatePicker autoOk
                        variant="inline"
                        label="Start Date"
                        format="MM/DD/YYYY"
                        minDate={datasetSchedule.date ? getMinDate(datasetSchedule.date) : new Date()}
                        value={datasetSchedule.date ? datasetSchedule.date : Date.now()}
                        onChange={(date) => changeSchedule(date, 'date')}
                    />
                    <TimePicker
                        ampm={false}
                        label="Start Time"
                        format="HH:mm"
                        value={datasetSchedule.time ? datasetSchedule.time : Date.now()}
                        onChange={(time) => changeSchedule(time, 'time')}
                    />
                </Grid>
                <Grid className={classNames(classes.marginTop15, classes.schedulerSection)}>
                    <Grid container justify="center" alignItems="center">
                        <ButtonGroup className={classes.TimingBtnGroup}>

                            {
                                appConstants.scheduleMethods.map((method, index) =>
                                    <Button
                                        className={classNames(classes.tabBtn, method.value === datasetSchedule.schedule_method ? 'active' : '')}
                                        variant="contained"
                                        key={`schedule_${index}`}
                                        onClick={() => changeSchedule(method.value, 'schedule_method')}>
                                        {method.name}
                                    </Button>
                                )
                            }
                        </ButtonGroup>
                    </Grid>
                    <Grid className={classes.scheduleContent}>
                        {
                            appConstants.scheduleMethods[0].value === datasetSchedule.schedule_method &&
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item xs={12}>
                                    <NumberInput label="Every"
                                        id="minute"
                                        value={datasetSchedule.minitues_interval ? datasetSchedule.minitues_interval : ''}
                                        onChange={(event) => changeSchedule(event.target.value, "minitues_interval")}
                                        validators={['required', 'maxNumber:60']}
                                        errorMessages={['Duration is required', 'Enter below 60 Minutes']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Minute(s)
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                        {
                            appConstants.scheduleMethods[1].value === datasetSchedule.schedule_method &&
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item xs={12}>
                                    <NumberInput label="Every"
                                        id="hour"
                                        value={datasetSchedule.minitues_interval ? datasetSchedule.minitues_interval : ''}
                                        onChange={(event) => changeSchedule(event.target.value, "minitues_interval")}
                                        validators={['required']}
                                        errorMessages={['Duration is required']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        Hour(s)
                                    </Typography>
                                </Grid>
                            </Grid>
                        }
                        {
                            appConstants.scheduleMethods[2].value === datasetSchedule.schedule_method &&
                            <Grid container direction="row" justify="center" alignItems="center">
                                <Grid item xs={12}>
                                    <TimePicker
                                        ampm={false}
                                        label="Select a Time"
                                        format="HH:mm"
                                        // value={datasetSchedule.day ? datasetSchedule.day : Date.now()}
                                        onChange={(time) => changeSchedule(time, "day")}
                                        TextFieldComponent={
                                            (props) => {
                                                return (
                                                    <Autocomplete
                                                        multiple
                                                        id="tags-standard"
                                                        options={[]}
                                                        // eslint-disable-next-line react/prop-types
                                                        defaultValue={props.value}
                                                        disableClearable
                                                        renderTags={
                                                            (value, getTagProps) =>
                                                                datasetSchedule.day.map((option, index) => {
                                                                    return (
                                                                        <div key={index} className={classNames(classes.schedulerTimeChip)} style={{ position: 'relative' }}>
                                                                            <Chip variant="outlined" label={moment(option).format('hh:mm A')} {...getTagProps({ index })} onDelete={() => removeTimeFromSchedule(index)} />
                                                                        </div>
                                                                    );
                                                                })
                                                        }
                                                        renderInput={
                                                            (params) => {
                                                                return (
                                                                    <TextField
                                                                        {...props}
                                                                        {...params}
                                                                        inputProps={{ ...params.inputProps }}
                                                                        error={!datasetSchedule.day || datasetSchedule?.day.length === 0}
                                                                        helperText={getTimeHelperText()}
                                                                    />
                                                                );
                                                            }
                                                        }
                                                    />
                                                );
                                            }
                                        }
                                    />
                                </Grid>
                            </Grid>
                        }
                        {
                            appConstants.scheduleMethods[3].value === datasetSchedule.schedule_method &&
                            <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                                <Grid item xs={12}>
                                    <ButtonGroup>
                                        {
                                            appConstants.weekDays.map((days, index) =>
                                                <Button
                                                    onClick={() => changeSchedule(days.value, "week_day")}
                                                    variant="contained"
                                                    key={`days_${index}`}
                                                    className={classNames(classes.dayBtn, datasetSchedule.week_day.indexOf(days.value) !== -1 ? 'active' : '')}
                                                >
                                                    {days.name}
                                                </Button>
                                            )
                                        }
                                    </ButtonGroup>
                                </Grid>
                                <Grid item xs={12}>
                                    <TimePicker
                                        ampm={false}
                                        label="Select a Time"
                                        format="HH:mm"
                                        // value={datasetSchedule.day ? datasetSchedule.day : Date.now()}
                                        onChange={(time) => changeSchedule(time, "day")}
                                        TextFieldComponent={
                                            (props) => {
                                                return (
                                                    <Autocomplete
                                                        multiple
                                                        id="tags-standard"
                                                        options={[]}
                                                        // eslint-disable-next-line react/prop-types
                                                        defaultValue={props.value}
                                                        disableClearable
                                                        renderTags={
                                                            (value, getTagProps) =>
                                                                datasetSchedule.day.map((option, index) => {
                                                                    return (
                                                                        <div key={index} className={classNames(classes.schedulerTimeChip)} style={{ position: 'relative' }}>
                                                                            <Chip variant="outlined" label={moment(option).format('hh:mm A')} {...getTagProps({ index })} onDelete={() => removeTimeFromSchedule(index)} />
                                                                        </div>
                                                                    );
                                                                })
                                                        }
                                                        renderInput={
                                                            (params) => {
                                                                return (
                                                                    <TextField
                                                                        {...props}
                                                                        {...params}
                                                                        inputProps={{ ...params.inputProps }}
                                                                        error={!datasetSchedule.day || datasetSchedule?.day.length === 0}
                                                                        helperText={getTimeHelperText()}
                                                                    />
                                                                );
                                                            }
                                                        }
                                                    />
                                                );
                                            }
                                        }
                                    />
                                </Grid>
                            </Grid>
                        }
                        {
                            appConstants.scheduleMethods[4].value === datasetSchedule.schedule_method &&
                            <Grid container align="center" justify="center" spacing={2}>
                                <Grid item xs={12}>
                                    <TextBox
                                        label="Select a Date"
                                        value={datasetSchedule.month_date.toString()}
                                        readOnly
                                        fullWidth
                                        InputProps={
                                            {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={(event) => openMonthCalender(event)}>
                                                            <TodayIcon className={classes.calendarIcon} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TimePicker
                                        ampm={false}
                                        label="Select a Time"
                                        format="HH:mm"
                                        // value={datasetSchedule.day ? datasetSchedule.day : Date.now()}
                                        onChange={(time) => changeSchedule(time, "day")}
                                        TextFieldComponent={
                                            (props) => {
                                                return (
                                                    <Autocomplete
                                                        multiple
                                                        id="tags-standard"
                                                        options={[]}
                                                        // eslint-disable-next-line react/prop-types
                                                        defaultValue={props.value}
                                                        disableClearable
                                                        renderTags={
                                                            (value, getTagProps) =>
                                                                datasetSchedule.day.map((option, index) => {
                                                                    return (
                                                                        <div key={index} className={classNames(classes.schedulerTimeChip)} style={{ position: 'relative' }}>
                                                                            <Chip variant="outlined" label={moment(option).format('hh:mm A')} {...getTagProps({ index })} onDelete={() => removeTimeFromSchedule(index)} />
                                                                        </div>
                                                                    );
                                                                })
                                                        }
                                                        renderInput={
                                                            (params) => {
                                                                return (
                                                                    <TextField
                                                                        {...props}
                                                                        {...params}
                                                                        inputProps={{ ...params.inputProps }}
                                                                        error={!datasetSchedule.day || datasetSchedule?.day.length === 0}
                                                                        helperText={getTimeHelperText()}
                                                                    />
                                                                );
                                                            }
                                                        }
                                                    />
                                                );
                                            }
                                        }
                                    />
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid container className={classes.marginTop15}>
                    <Grid item xs={12}>
                        <AutoComplete
                            label="Timezone"
                            placeholder="Select Timezone"
                            onChange={(event) => changeSchedule(event.target.value, "time_zone")}
                            onBlur={() => { }}
                            value={datasetSchedule.time_zone ? datasetSchedule.time_zone : ''}
                            validators={['required']}
                            errorMessages={['Timezone is required']}
                            options={timeZones}
                            selectedValuePath="name"
                            displayMemberPath="name"
                            removeClass
                            openSuggestionsOnClick
                            name="timzone"
                            id="timzone"
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justify="space-between" className={classes.marginTop20}>
                    {
                        sourceType !== "Tableau" && moduleConfig && moduleConfig.profile &&
                        <CheckboxComponent checked={datasetSchedule.start_profile} onChange={(event) => changeSchedule(event.target.checked, "start_profile")} checkboxLabel="Profile" />
                    }
                    {
                        sourceType !== "Tableau" && moduleConfig && moduleConfig.profile && moduleConfig.export_metrics &&
                        <CheckboxComponent checked={datasetSchedule.start_export_metrics && datasetSchedule.start_profile} disabled={!datasetSchedule.start_profile} onChange={(event) => changeSchedule(event.target.checked, "start_export_metrics")} checkboxLabel="Export Metrics" />
                    }
                    {
                        sourceType !== "Tableau" && moduleConfig && moduleConfig.curate &&
                        <CheckboxComponent checked={datasetSchedule.start_curate} onChange={(event) => changeSchedule(event.target.checked, "start_curate")} checkboxLabel="Curate" />
                    }

                </Grid>
                <Grid container justify="space-between" alignItems="center" className={classes.marginTop20}>
                    <Grid item xs={6}>
                        <Button onClick={() => showRemoveAlert()} className={classes.tabBtn} variant="contained" color="primary" disabled={!isExistingSchedule} >
                            Remove Schedule
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container justify="flex-end" alignItems="center">
                            <Button className={classes.tabBtn} type="submit" variant="contained" color="primary" disabled={!submission} >
                                Submit
                            </Button>
                            <Button onClick={() => onClose()} style={{ marginLeft: '10px' }} variant="contained" className={classNames(classes.cancelBtn, classes.tabBtn)}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                    {
                        showRemoveSchedule && <AlertDialog
                            title="Delete Schedule"
                            message={`Are you sure you want to delete the schedule for the dataset?`}
                            okButtonText="OK"
                            cancelButtonText="Cancel"
                            show={showRemoveSchedule}
                            onClickOK={onRemoveSchedule}
                            onClickCancel={closeScheduleAlert}
                        />
                    }
                </Grid>
                {
                    open && <ScheduleMonthCalender
                        onOpenPopover={open}
                        anchorElement={anchorEl}
                        onClose={() => closeMonthCalender()}
                        onDateSelectionChange={(day) => changeSchedule(day, "month_date")}
                        selectedDays={datasetSchedule.month_date}
                    />
                }
            </Grid>
        </ValidatorForm>
    );
};

TimeSchedule.propTypes = {
    classes: PropTypes.object,
    scheduleProps: PropTypes.object,
    onClose: PropTypes.func,
    sourceType: PropTypes.string,
    datasetSchedule: PropTypes.object,
    setDatasetSchedule: PropTypes.func,
    isExistingSchedule: PropTypes.bool,
    setExistingSchedule: PropTypes.func,
    defaultParams: PropTypes.object,
    submission: PropTypes.bool,
    setSubmission: PropTypes.func,
    onSubmitSchedule: PropTypes.func
};

export default withStyles((theme) => ({
    ...ScheduleStyles(theme),
    ...Styles(theme)
}))(TimeSchedule);