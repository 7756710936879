import React, { useState, Fragment } from 'react';
import { Grid, withStyles, Popover, Typography, IconButton, Chip, MenuItem, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import TextBox from '../TextBox/TextBox.jsx';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import SemanticGlossaryStyles from './SemanticGlossaryStyles.jsx';
import classNames from 'classnames';

const SelectLibrary = (props) => {
    const { classes, items, onUpdate, theme, onBlur, property } = props;
    const libraries = useSelector(({ datasource }) => datasource.references);
    const defaultLibrary = { "name": "", "attribute": "", "resultant_attribute": "" };
    const [library, setLibrary] = useState({ ...defaultLibrary });
    const [anchorEl, setAnchorEl] = useState(null);

    const onChange = (property, value) => {
        library[property] = value;
        if (property === "name") {
            const lib = libraries.find((lib) => lib.name === value);
            if (lib) {
                library.refId = lib && lib.id ? lib.id : '';
                library.attribute = '';
                library.resultant_attribute = '';
                library['storage_method'] = lib.storage_method ? lib.storage_method : '';
                library['file_url'] = lib.file_url ? lib.file_url : '';
                library['web_file_url'] = lib.web_file_url ? lib.web_file_url : '';
            }
        }
        setLibrary({ ...library });
    };

    const removeChip = (index) => {
        items.splice(index, 1);
        onUpdate(property, items);
        onBlur(property, items);
    };

    const getAttributes = () => {
        const lib = libraries.find((lib) => lib.name === library.name);
        return lib && lib.attributes ? lib.attributes.map((attribute) => attribute.attribute) : [];
    };

    const resultantAttribute = () => {
        const lib = libraries.find((lib) => lib.name === library.name);
        const attributes = lib && lib.attributes ? lib.attributes.map((attribute) => attribute.attribute) : [];
        return attributes.filter((attribute) => library.attribute !== attribute);
    };

    const onSaveLibray = () => {
        setAnchorEl(null);
        onUpdate(property, [{ ...library }]);
        onBlur(property, [{ ...library }]);
    };

    return (
        <Grid className={classes.marginTop10}>
            <Grid style={{ display: 'flex', alignItems: 'center', flexWrap: "wrap" }}>
                {
                    items.map((item, index) =>
                        <Grid key={`${item.name}-${index}`} className={classes.chipList}>
                            <Chip
                                className={classes.chip}
                                label={item.name}
                                onClick={(event) => { setLibrary(item); setAnchorEl(event.target); }}
                            />
                            <IconButton onClick={() => removeChip(index)} className={classNames(classes.nopadding, 'chipremove')}>
                                <CloseIcon className={classNames(classes.chipIcon, classes.closeIcon)} />
                            </IconButton>
                        </Grid>
                    )
                }
                {
                    items.length === 0 &&
                    <Fragment>
                        <TooltipComponent title="Add" arrow>
                            <IconButton onClick={(event) => { setAnchorEl(event.target); setLibrary({ ...defaultLibrary }); }} className={classes.addIcon}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18">
                                    <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                        <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                            <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.secondary.main} />
                                            <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.secondary.main} />
                                        </g>
                                    </g>
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                        <Typography onClick={(event) => { setAnchorEl(event.target); setLibrary({}); }} variant={"body2"} className={classes.addTags}>
                            {'Add'}
                        </Typography>
                    </Fragment>
                }

            </Grid>
            {
                Boolean(anchorEl) &&
                <Popover anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={
                        {
                            vertical: 'center',
                            horizontal: 'center'
                        }
                    }
                    transformOrigin={
                        {
                            vertical: 'center',
                            horizontal: 'center'
                        }
                    }
                >
                    <ValidatorForm onSubmit={() => onSaveLibray()}>
                        <Grid container className={classes.libraryContainer} spacing={2}>
                            <Grid item>
                                <Grid container direction="row" justify="space-between" alignItems="center">
                                    <Typography component="h5" variant="h5">
                                        {'Add Curation Library'}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <TextBox
                                    name="name"
                                    label="Name"
                                    value={library.name ? library.name : ""}
                                    select
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    fullWidth
                                >
                                    {
                                        libraries.length > 0 ?
                                        libraries.map((lib, index) => {
                                            return (
                                                <MenuItem key={`loadType_${index}`} value={lib.name}>
                                                    {lib.name}
                                                </MenuItem>
                                            );
                                        }) :
                                        <MenuItem disabled>
                                                    Library is empty
                                        </MenuItem>
                                    }
                                </TextBox>
                            </Grid>
                            <Grid item xs={12}>
                                <TextBox
                                    label="Attribute"
                                    name="attribute"
                                    value={library.attribute ? library.attribute : ""}
                                    select
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    fullWidth
                                    disabled={Boolean(library.name === "")}
                                >
                                    {
                                        getAttributes().length > 0 ?
                                        getAttributes().map((attribute, index) => {
                                            return (
                                                <MenuItem key={`loadType_${index}`} value={attribute}>
                                                    {attribute}
                                                </MenuItem>
                                            );
                                        }) :
                                        <MenuItem disabled>
                                                    Attribute is empty
                                        </MenuItem>
                                    }
                                </TextBox>
                            </Grid>
                            <Grid item xs={12}>
                                <TextBox
                                    label="Resultant Attribute"
                                    name="resultant_attribute"
                                    value={library.resultant_attribute ? library.resultant_attribute : ""}
                                    select
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    fullWidth
                                    disabled={Boolean(library.attribute === "")}
                                >
                                    {
                                        resultantAttribute().length > 0 ?
                                        resultantAttribute().map((attribute, index) => {
                                            return (
                                                <MenuItem key={`loadType_${index}`} value={attribute}>
                                                    {attribute}
                                                </MenuItem>
                                            );
                                        }) :
                                        <MenuItem disabled>
                                                    Resultant Attribute is empty
                                        </MenuItem>
                                    }
                                </TextBox>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container justify="flex-end" alignItems="center" className={classes.marginTop10}>
                                    <Button className={classes.tabBtn} type="submit" variant="contained" color="primary">
                                        Submit
                                    </Button>
                                    <Button onClick={() => setAnchorEl(null)} style={{ marginLeft: '10px' }} variant="contained" className={classNames(classes.cancelBtn, classes.tabBtn)}>
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </Popover>
            }
        </Grid>
    );
};

SelectLibrary.propTypes = {
    classes: PropTypes.object,
    items: PropTypes.array,
    onUpdate: PropTypes.func,
    theme: PropTypes.object,
    property: PropTypes.string,
    onBlur: PropTypes.func

};

export default withStyles((theme) => ({
    ...SemanticGlossaryStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SelectLibrary);