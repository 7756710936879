import React, { useCallback, useRef } from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { getValue } from '../../helpers/appHelpers';

import DomainCard from './DomainCard.jsx';
import Styles from '../../layouts/Styles.jsx';
import DomainStyles from './DomainStyles.jsx';

const DomainKanbanView = (props) => {
    const { rules, classes, dragAttribute, history, redirectData, dq_score_threshold } = props;
    const containerRef = useRef();
    const onDragEnd = useCallback((result) => {
        dragAttribute(result);
    }, [dragAttribute]);


    return (
        <DragDropContext
            onDragEnd={onDragEnd}
        >
            <Grid className={classes.kanbanContainer} ref={containerRef}>
                {
                    rules.sort((a, b) => b.name - a.name).map((rule, index) =>
                        <Grid key={index} className={classes.kanbanviewSection}>
                            <Grid className={classNames(classes.titleContainer, rule.name !== "" ? classes.kanbanBlueHeader : classes.kanbanGreyHeader)} container justify="space-between">
                                <Typography className={classes.titleTxt}>
                                    {rule.name ? rule.name : "Others"}
                                </Typography>
                                <Typography align="right" className={classes.titleTxt}>
                                    {`${rule.score ? getValue(rule.score) : 0}%`}
                                </Typography>

                            </Grid>

                            <Droppable droppableId={`${rule.name ? rule.name : "Others"}`}>
                                {
                                    (provided, snapshot) => (
                                        <Grid
                                            className={classes.kanbanSection}
                                            ref={provided.innerRef}>
                                            {
                                                rule.attributes && rule.attributes.map((attribute, attributeIndex) =>
                                                    <DomainCard redirectData={(params) => redirectData(params)} key={attributeIndex} attribute={attribute} index={attributeIndex} history={history} dq_score_threshold={dq_score_threshold} />
                                                )
                                            }
                                            {provided.placeholder}
                                        </Grid>
                                    )
                                }
                            </Droppable>
                        </Grid>
                    )
                }
            </Grid>
        </DragDropContext>
    );
};

DomainKanbanView.propTypes = {
    classes: PropTypes.object,
    rules: PropTypes.array,
    dragAttribute: PropTypes.func,
    history: PropTypes.object,
    redirectData: PropTypes.func,
    dq_score_threshold: PropTypes.object
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DomainKanbanView);