import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Popover, MenuItem, Divider, Typography } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';
import { getAllActionItem, createActionComment, updateActionItem, deleteActionItem } from '../../actions/notificationActions.js';
import ActionItemFilter from '../../components/ActionItem/ActionItemFilter.jsx';
import ActionitemMetrics from '../../components/ActionItem/ActionitemMetrics.jsx';
import ActionItemDetailList from '../../components/ActionItem/ActionItemDetailList.jsx';
import ActionDetail from '../../components/ActionItem/ActionDetail.jsx';
import NoResultFound from '../../components/NoResultFound/NoResultFound.jsx';
import Loader from '../../components/Loaders/Loader.jsx';
import { setCurrentPage } from '../../helpers/apiHelpers.js';

const ActionItem = (props) => {
    const { classes, history, getPageTitle } = props;
    const dispatch = useDispatch();
    const [actionItems, setActionItems] = useState([]);
    const [open, setOpen] = useState(false);
    const [actionItem, setSelectedItem] = useState({});
    const user = useSelector(({ account }) => account.user);
    const userList = useSelector(({ account }) => account.users);
    const [isLoading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        search: '',
        filter: props.location?.state?.filters ? props.location.state.filters : 'All',
        sort: 'High',
        reporter: 'All',
        users: [
            {
                isDefault: true,
                isSelected: true,
                isUser: false,
                name: 'All'
            }
        ]
    });
    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState(["High", "Medium", "Low"]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isOpen, setPopoverOpen] = useState(false);
    const [limit, setLimit] = useState(10);

    const loadUsers = useCallback(() => {
        const userItems = userList.map((user) => {
            return {
                ...user,
                isSelected: false,
                name: user.first_name !== "" ? `${user.first_name} ${user.last_name}` : user.email,
                isDefault: false,
                isUser: true
            };
        });
        userItems.unshift({
            isDefault: true,
            isSelected: true,
            isUser: false,
            name: 'All'
        });
        setUsers([...userItems]);
    }, [userList]);

    useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    useEffect(() => {
        getPageTitle('Action Item');
    });

    const getActionsItems = useCallback(() => {
        setLoading(true);
        dispatch(getAllActionItem()).then((response) => {
            setLoading(false);
            if (response) {
                setActionItems([...response]);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        setCurrentPage('actionitems');
        getActionsItems();
    }, [getActionsItems]);

    const updateActionStatus = useCallback((property, value, actionId) => {
        const requestParams = {
            property: property,
            value: value
        };
        dispatch(updateActionItem(requestParams, actionId));
    }, [dispatch]);

    const openDialogDetail = useCallback((item) => {
        setOpen(true);
        setPopoverOpen(false);
        if (item) {
            if (item.status === "new") {
                updateActionStatus('status', 'open', item.id);
                item.status = "open";
            }
            setSelectedItem({ ...item });
        }
    }, [updateActionStatus]);

    const addComment = useCallback((message) => {
        const comment = {
            "comment": message,
            "is_first_comment": false,
            "comment_user_id": user.id,
            "created_date": Date.now(),
            "action_id": actionItem.id,
            ...user
        };
        actionItem.comments.push(comment);
        dispatch(createActionComment(comment));
        setSelectedItem({ ...actionItem });
    }, [actionItem, dispatch, user]);

    const goBack = useCallback(() => {
        setOpen(false);
        const index = actionItems.findIndex((item) => item.id === actionItem.id);
        actionItems[index] = { ...actionItem };
        setActionItems([...actionItems]);
    }, [actionItem, actionItems]);


    const updateItem = useCallback((params, id) => {
        dispatch(updateActionItem(params, id));
    }, [dispatch]);

    const updateActionItemDetail = useCallback((property, value) => {
        actionItem[property] = value;
        setSelectedItem({ ...actionItem });
        const requestParams = {
            property: property,
            value: value
        };
        updateItem(requestParams, actionItem.id);
    }, [actionItem, updateItem]);

    const updateActionItemStatus = useCallback((actionId, status) => {
        const index = actionItems.findIndex((item) => item.id === actionId);
        actionItems[index].status = status;
        setActionItems([...actionItems]);
        const requestParams = {
            property: 'status',
            value: status
        };
        updateItem(requestParams, actionItems[index].id);
    }, [actionItems, updateItem]);

    const onChangeFilter = useCallback((property, value) => {
        if (property === "users") {
            let userItems = [];
            if (value === "All") {
                userItems = users.map((user) => {
                    const userList = user;
                    if (userList.name !== "All") {
                        userList.isSelected = false;
                    } else {
                        userList.isSelected = true;
                    }
                    return userList;
                });
                filters[property] = users.filter((user) => user.name === value);
            } else {
                let filterUsers = [...filters.users];
                const index = filterUsers.findIndex((user) => user.name === value);
                const allIndex = filterUsers.findIndex((user) => user.name === "All");
                if (allIndex !== -1) {
                    filterUsers.splice(allIndex, 1);
                }
                if (index !== -1) {
                    filterUsers.splice(index, 1);
                } else {
                    const user = users.find((user) => user.name === value);
                    filterUsers.push({ ...user });
                }
                if (filterUsers.length === 0) {
                    filterUsers = users.filter((user) => user.name === "All");
                }
                if (filterUsers.length === userList.length) {
                    filterUsers = users.filter((user) => user.name === "All");
                }
                userItems = users.map((user) => {
                    const userList = user;
                    if (userList.name === value && index === -1) {
                        userList.isSelected = true;
                    } else if ((userList.name === value && index !== -1)) {
                        userList.isSelected = false;
                    } else if (userList.name === "All") {
                        if (filterUsers.length === 0) {
                            userList.isSelected = true;
                        } else {
                            userList.isSelected = false;
                        }
                    }
                    return userList;
                });
                filters[property] = filterUsers;
            }
            setUsers([...userItems]);
        } else if (property === "reporter") {
            filters[property] = value;
        } else {
            filters[property] = value;
            if (property === "sort") {
                let statusList = [...status];
                statusList.unshift(value);
                statusList = [...new Set(statusList)];
                setStatus([...statusList]);
            }
        }
        setFilters({ ...filters });
    }, [filters, status, userList.length, users]);

    const openPopover = useCallback((event, item) => {
        setPopoverOpen(true);
        setAnchorEl(event.target);
        setSelectedItem({ ...item });
    }, []);


    const deleteAction = useCallback(() => {
        dispatch(deleteActionItem(actionItem.id));
        const index = actionItems.findIndex((item) => item.id === actionItem.id);
        actionItems.splice(index, 1);
        setActionItems([...actionItems]);
        setPopoverOpen(false);
    }, [actionItem.id, actionItems, dispatch]);


    let items = actionItems.filter((item) => {
        let searchFilter = true;
        let statusFilter = true;
        let permissionFilter = false;
        let reportFilter = true;

        if (filters.search.length) {
            searchFilter = item.comments ? item.comments[0].comment.toLowerCase().includes(filters.search.toLowerCase()) : true;
        }
        if (filters.filter !== "All") {
            statusFilter = item.status.toLowerCase() === filters.filter.toLowerCase();
        }
        if (filters.reporter !== "All") {
            if (item.created_by_id__email && filters.reporter) {
                reportFilter = item.created_by_id__email.toLowerCase() === filters.reporter.toLowerCase();
            } else {
                reportFilter = false;
            }
        }

        if (filters.users.length === 1 && filters.users[0].name === "All") {
            permissionFilter = true;
        } else {
            const userList = filters.users.map((user) => user.username);
            permissionFilter = userList.includes(item.assigneduser);
            // permissionFilter = item.users.some((user) => userList.includes(user.assign_user_id));
        }
        return searchFilter && statusFilter && reportFilter && permissionFilter;
    });
    if (items.length) {
        const statusOrder = {};
        for (const index in status) {
            statusOrder[status[index]] = parseInt(index + 1);
        }
        items = items.sort((a, b) => statusOrder[a.priority] - statusOrder[b.priority]);
    }

    const actionItemList = items.filter((message, index) => index < limit);
    const onScroll = (event) => {
        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
            setLimit(limit + 10);
        }
    };

    return (
        <Grid>
            <ValidatorForm name="Action item filter" onSubmit={() => null}>
                <ActionItemFilter filters={filters} onChangeFilter={(property, value) => onChangeFilter(property, value)} />
                <ActionitemMetrics isLoading={isLoading} items={actionItems} userList={users} users={filters.users} onChangeFilter={(property, value) => onChangeFilter(property, value)} />
                <Grid className={classNames(classes.actionItemListContainer, classes.relative, classes.zIndex1)} onScroll={onScroll}>
                    {
                        actionItemList.map((item, index) =>
                            <ActionItemDetailList
                                updateActionItemStatus={(id, status) => updateActionItemStatus(id, status)}
                                actionItem={item}
                                key={index}
                                openDialogDetail={(item) => openDialogDetail(item)}
                                openPopover={(event, itemInfo) => openPopover(event, itemInfo)} />
                        )
                    }
                    {
                        (items.length === 0 && !isLoading) &&
                        <NoResultFound text="No Action Items Found" />
                    }
                    {isLoading && <Loader />}
                </Grid>
                {
                    open && <ActionDetail
                        goBack={() => goBack()}
                        addComment={(message) => addComment(message)}
                        open={open}
                        actionItem={actionItem}
                        history={history}
                        updateActionItemDetail={(property, value) => updateActionItemDetail(property, value)} />
                }
            </ValidatorForm>
            {
                isOpen &&
                <Popover
                    open={isOpen}
                    anchorEl={anchorEl}
                    onClose={() => setPopoverOpen(false)}
                    anchorOrigin={
                        {
                            vertical: 'center',
                            horizontal: 'right'
                        }
                    }
                    transformOrigin={
                        {
                            vertical: 'bottom',
                            horizontal: 'left'
                        }
                    }>
                    <MenuItem className={classes.logout} onClick={() => openDialogDetail()}>
                        <Typography className={classes.logoutTxt}>
                            {'View'}
                        </Typography>
                    </MenuItem>
                    <Divider />
                    {
                        actionItem && actionItem.users.some((item) => item.assign_user_id === user.id) &&
                        <MenuItem className={classes.logout} onClick={() => deleteAction()}>
                            <Typography className={classes.logoutTxt}>
                                {'Delete'}
                            </Typography>
                        </MenuItem>
                    }

                </Popover>
            }
        </Grid>
    );
};

ActionItem.propTypes = {
    classes: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    getPageTitle: PropTypes.func
};

export default withStyles((theme) => ({
    ...Styles(theme)
}))(ActionItem);