export const metricsActions = {
    GET_TOTAL_COUNT_REQUEST: 'GET_TOTAL_COUNT_REQUEST',
    GET_TOTAL_COUNT_SUCCESS: 'GET_TOTAL_COUNT_SUCCESS',
    GET_TOTAL_COUNT_FAILURE: 'GET_TOTAL_COUNT_FAILURE',

    GET_FILTERDATASOURCE_LIST_REQUEST: 'GET_FILTERDATASOURCE_LIST_REQUEST',
    GET_FILTERDATASOURCE_LIST_SUCCESS: 'GET_FILTERDATASOURCE_LIST_SUCCESS',
    GET_FILTERDATASOURCE_LIST_FAILURE: 'GET_FILTERDATASOURCE_LIST_FAILURE',

    GET_DATASOURCE_LIST_REQUEST: 'GET_DATASOURCE_LIST_REQUEST',
    GET_DATASOURCE_LIST_SUCCESS: 'GET_DATASOURCE_LIST_SUCCESS',
    GET_DATASOURCE_LIST_FAILURE: 'GET_DATASOURCE_LIST_FAILURE',

    GET_FILTERDATASET_LIST_REQUEST: 'GET_FILTERDATASET_LIST_REQUEST',
    GET_FILTERDATASET_LIST_SUCCESS: 'GET_FILTERDATASET_LIST_SUCCESS',
    GET_FILTERDATASET_LIST_FAILURE: 'GET_FILTERDATASET_LIST_FAILURE',

    GET_DATASET_LIST_REQUEST: 'GET_DATASET_LIST_REQUEST',
    GET_DATASET_LIST_SUCCESS: 'GET_DATASET_LIST_SUCCESS',
    GET_DATASET_LIST_FAILURE: 'GET_DATASET_LIST_FAILURE',

    GET_FILTERATTRIBUTE_LIST_REQUEST: 'GET_FILTERATTRIBUTE_LIST_REQUEST',
    GET_FILTERATTRIBUTE_LIST_SUCCESS: 'GET_FILTERATTRIBUTE_LIST_SUCCESS',
    GET_FILTERATTRIBUTE_LIST_FAILURE: 'GET_FILTERATTRIBUTE_LIST_FAILURE',

    GET_ATTRIBUTE_LIST_REQUEST: 'GET_ATTRIBUTE_LIST_REQUEST',
    GET_ATTRIBUTE_LIST_SUCCESS: 'GET_ATTRIBUTE_LIST_SUCCESS',
    GET_ATTRIBUTE_LIST_FAILURE: 'GET_ATTRIBUTE_LIST_FAILURE',

    GET_PROFILE_RULES_REQUEST: 'GET_PROFILE_RULES_REQUEST',
    GET_PROFILE_RULES_SUCCESS: 'GET_PROFILE_RULES_SUCCESS',
    GET_PROFILE_RULES_FAILURE: 'GET_PROFILE_RULES_FAILURE',

    GET_DQSCORE_RULES_REQUEST: 'GET_DQSCORE_RULES_REQUEST',
    GET_DQSCORE_RULES_SUCCESS: 'GET_DQSCORE_RULES_SUCCESS',
    GET_DQSCORE_RULES_FAILURE: 'GET_DQSCORE_RULES_FAILURE',

    GET_METRIC_REQUEST: 'GET_METRIC_REQUEST',
    GET_METRIC_SUCCESS: 'GET_METRIC_SUCCESS',
    GET_METRIC_FAILURE: 'GET_METRIC_FAILURE',

    GET_PREVIEW_DATA_CATEGORY_REQUEST: 'GET_PREVIEW_DATA_CATEGORY_REQUEST',
    GET_PREVIEW_DATA_CATEGORY_SUCCESS: 'GET_PREVIEW_DATA_CATEGORY_SUCCESS',
    GET_PREVIEW_DATA_CATEGORY_FAILURE: 'GET_PREVIEW_DATA_CATEGORY_FAILURE',

    GET_EXPORT_DATA_CATEGORY_REQUEST: 'GET_EXPORT_DATA_CATEGORY_REQUEST',
    GET_EXPORT_DATA_CATEGORY_SUCCESS: 'GET_EXPORT_DATA_CATEGORY_SUCCESS',
    GET_EXPORT_DATA_CATEGORY_FAILURE: 'GET_EXPORT_DATA_CATEGORY_FAILURE',

    SET_METRIC_DATA_SUCCESS: 'SET_METRIC_DATA_SUCCESS',
    CLEAR_WIDGET: 'CLEAR_WIDGET',
    SET_SEARCH_KEY: 'SET_SEARCH_KEY'
};