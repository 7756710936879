import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Grid, withStyles, Typography, Avatar } from '@material-ui/core';
import { getRandomColor, getInitials, returnImage, getMentionName } from '../../helpers/appHelpers';
import Styles from '../../layouts/Styles.jsx';
import ActionItemStyles from './ActionItemStyles.jsx';
import { appConstants } from '../../constants/appConstants';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
const ActionListComment = (props) => {
    const { classes, actionItem, selectActionItem, type } = props;
    const users = useSelector(({ account }) => account.users);
    const presentDate = moment().format('MMM DD YYYY');

    return (
        <Grid className={classes.actionitemlist} onClick={() => (type === "main" ? selectActionItem(actionItem) : null)}>
            <Typography className={classnames('description', classes.marginBottom10)} dangerouslySetInnerHTML={{ __html: type === "main" ? getMentionName(actionItem.comments[0].comment, users) : getMentionName(actionItem.comment, users) }} />
            <Grid container justify="space-between">
                <Avatar
                    style={
                        {
                            backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, actionItem.avatar)})`,
                            backgroundColor: getRandomColor(actionItem.first_name !== "" ? `${actionItem.first_name} ${actionItem.last_name}` : actionItem.email)
                        }
                    }>
                    {
                        !actionItem.avatar &&
                        <span>
                            {getInitials(actionItem.first_name !== "" ? `${actionItem.first_name} ${actionItem.last_name}` : actionItem.email)}
                        </span>
                    }
                </Avatar>
                <Typography className={classes.actionTimeTxt}>
                    {
                        ((actionItem) && (moment(actionItem.created_date).format('MMM DD YYYY') === presentDate)) ?
                        moment(actionItem.created_date).calendar() :
                        moment(actionItem.created_date).format('MMM DD, YYYY HH:mm')
                    }
                </Typography>
            </Grid>
        </Grid >
    );

};

ActionListComment.propTypes = {
    classes: PropTypes.object,
    actionItem: PropTypes.object,
    selectActionItem: PropTypes.func,
    type: PropTypes.string
};


export default withStyles((theme) => ({
    ...ActionItemStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ActionListComment);