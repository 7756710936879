import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, MenuItem, FormLabel, IconButton, ClickAwayListener } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';
import TextBox from '../TextBox/TextBox.jsx';
import Search from '../TextBox/Search.jsx';
import DatasourceListStyles from '../DatasourceList/DatasourceListStyles.jsx';
import NotificationStyles from './NotificationStyles.jsx';
// import UserAutoComplete from '../UserAutoComplete/UserAutoComplete.jsx';
import { setFilters, getNotificationRules } from '../../actions/notificationActions';
import { getOrganizationDatasources } from '../../actions/datasourceActions';
import { getOrganizationDatasetList } from '../../actions/datasetActions';
import { getProperties } from '../../actions/settingActions.js';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import { appConstants } from '../../constants/appConstants.js';
import { removeUnderscore, getUserProperty, sortTable } from '../../helpers/appHelpers.js';
import AutoCompleteCheckBox from '../AutoCompleteCheckBox/AutoCompleteCheckBox.jsx';

const NotificationFilter = (props) => {
    const { classes, theme, onChangeFilterResult, history } = props;
    const [filterStatus, setFilterTabs] = useState([]);
    const controllerRef = useRef();
    const [enableFilter, setFilter] = useState(false);
    const [datasources, setDatasources] = useState([]);
    const [datasets, setDatasets] = useState([]);
    const [datasetsToList, setDatasetsToList] = useState([]);
    const [usersList, setUsersList] = useState([
        {
            isDefault: true,
            isSelected: true,
            isUser: false,
            name: 'All'
        }
    ]);
    const [defaultFilter] = useState({
        users: [...usersList],
        datasources: [{ name: 'All', isDefault: true }],
        datasets: [{ name: 'All', isDefault: true }],
        attributes: [{ name: 'All', isDefault: true }],
        types: "All",
        jobstatus: "All",
        filter: "Last 3 Days",
        notification: "All",
        search: ""
    });
    const [filters, setNotificationFilters] = useState({ ...JSON.parse(JSON.stringify(defaultFilter)) });
    const [isFiltersLoaded, setIsFiltersLoaded] = useState(false);
    const [driftFilters, setDriftFilters] = useState([]);
    const [ruleFilters, setRuleFilters] = useState([]);
    const [selectedIds, setSelectedIds] = useState([]);
    // const [userInput, setUserInput] = useState('');
    const [typeFilters, setTypeFilters] = useState([{ name: "All", value: "All" }]);
    const notificationFilters = useSelector(({ notification }) => notification.notificationFilters);
    const users = useSelector(({ account }) => account.users);
    const config = useSelector(({ setting }) => setting.config);
    const dispatch = useDispatch();

    const getDatasources = useCallback(() => {
        dispatch(getOrganizationDatasources()).then((response) => {
            if (response) {
                // response = [{ isDefault: true, name: "All" }, ...response];
                response = response.map((data) => { return { ...data, name: data.name ? data.name : "Untitled Datasource" }; });
                const filterDatasource = notificationFilters.datasources;
                const index = filterDatasource.findIndex((data) => data.isDefault);
                const sources = [];

                if (filterDatasource[index]?.isDefault === true) {
                    response = response.map((option) => { sources.push(option.id); return { ...option, isSelected: true }; });
                    filters.datasources = response.filter((option) => !option.isDefault);
                } else {
                    const selectedIdList = filterDatasource.map((data) => data.id);
                    response = response.map((data) => { return { ...data, isSelected: selectedIdList.includes(data.id) }; });
                }
                setDatasources([...response]);
                setSelectedIds([...sources]);
            }
        });
    }, [dispatch, filters, notificationFilters.datasources]);

    const getDatasets = useCallback(() => {
        dispatch(getOrganizationDatasetList()).then((response) => {
            if (response) {
                response = response.map((data) => { return { ...data, name: `${data.source_name ? data.source_name : "Untitled Datasource"}.${data.name ? data.name : "Untitled Dataset"}` }; });
                const filterDataset = notificationFilters.datasets;
                const index = filterDataset.findIndex((data) => data.isDefault);

                if (filterDataset[index]?.isDefault === true) {
                    response = response.map((option) => { return { ...option, isSelected: true }; });
                    filters.datasets = response.filter((option) => !option.isDefault);
                } else {
                    const selectedIdList = filterDataset.map((data) => data.id);
                    response = response.map((data) => { return { ...data, isSelected: selectedIdList.includes(data.id) }; });
                }

                setDatasetsToList([...response]);
                setDatasets([...response]);
            }
        });
    }, [dispatch, filters, notificationFilters.datasets]);

    const getDriftProperties = useCallback(() => {
        const userInfo = getUserProperty();
        dispatch(getProperties(userInfo.organization ? userInfo.organization : userInfo.organization_id)).then((response) => {
            let filters = [{ name: "All", value: "All" }];
            if (response) {
                let driftRules = response && response.driftProperties ? Object.keys(response.driftProperties).map((data) => data.toLowerCase()) : [];
                driftRules = [...new Set(driftRules)];
                driftRules = driftRules.map((data) => {
                    let name = removeUnderscore(data);
                    name = name.charAt(0).toUpperCase() + name.slice(1);
                    return {
                        name: `Drift - ${name}`,
                        value: data ? data.toLowerCase() : ""
                    };
                });
                setDriftFilters([...driftRules]);
                filters = [...filters, ...driftRules];
            }
            setTypeFilters(filters);
        });
    }, [dispatch]);

    const getRuleProperties = useCallback(() => {
        dispatch(getNotificationRules()).then((response) => {
            let filters = [{ name: "All", value: "All" }];
            if (response) {
                const rules = response.map((data) => {
                    return {
                        name: `Rule - ${data}`,
                        value: data ? data.toLowerCase() : ""
                    };
                });
                setRuleFilters([...rules]);
                filters = [...filters, ...rules];
            }
            setTypeFilters(filters);
        });
    }, [dispatch]);


    const updateNotificationTypes = useCallback((value) => {
        if (value === "Drift") {
            getDriftProperties();
        } else if (value === "Schedule") {
            setTypeFilters([...appConstants.notificationScheduleTypeFilters]);
        } else if (value === "Audit Log") {
            setTypeFilters([...appConstants.notificationAuditTypeFilters]);
        } else if (value === "ActionItem") {
            setTypeFilters([...appConstants.notificationActionItemTypeFilters]);
        } else if (value === "Permissioning") {
            setTypeFilters([...appConstants.notificationPermissionTypeFilters]);
        } else if (value === "UserActivity") {
            setTypeFilters([...appConstants.notificationUserActivityTableHeaders]);
        } else if (value === "All") {
            setTypeFilters([...appConstants.notificationOverallFilters, ...driftFilters, ...ruleFilters]);
        } else {
            getRuleProperties();
        }

    }, [driftFilters, getDriftProperties, getRuleProperties, ruleFilters]);

    useEffect(() => {
        if (datasources.length === 0) {
            getDatasources();
        }
        if (datasets.length === 0) {
            getDatasets();
        }
    }, [datasets.length, datasources.length, getDatasets, getDatasources]);

    useEffect(() => {
        if (!isFiltersLoaded) {
            setNotificationFilters({ ...notificationFilters });
            updateNotificationTypes(notificationFilters.notification);
            setIsFiltersLoaded(true);
        }
    }, [isFiltersLoaded, notificationFilters, updateNotificationTypes]);

    useEffect(() => {
        if (usersList.length === 1 && users.length > 0) {
            const organizationUsers = users.map((user) => {
                return {
                    ...user,
                    isSelected: false,
                    name: user.first_name !== "" ? `${user.first_name} ${user.last_name}` : user.email,
                    isDefault: false,
                    isUser: true
                };
            });
            organizationUsers.unshift({
                isDefault: true,
                isSelected: true,
                isUser: false,
                name: 'All'
            });
            setUsersList([...organizationUsers]);
        }
    }, [users, usersList]);

    /*
     * const onInputValueOnChange = (value) => {
     *     setUserInput(value);
     * };
     */

    /*
     * const removeItem = useCallback((index) => {
     *     usersList.splice(index, 1);
     *     setUsersList([...usersList]);
     *     setTimeout(() => {
     *         setUserInput('');
     *     }, 100);
     * }, [usersList]);
     */

    const setTabs = useCallback(() => {
        const tabs = ["All", "ActionItem", "Audit Log", "Drift", "Permissioning", "Schedule", "Rule", "UserActivity"];
        if (!config.action_items) {
            const index = tabs.indexOf("ActionItem");
            tabs.splice(index, 1);
        }
        if (!config.drift) {
            const index = tabs.indexOf("Drift");
            tabs.splice(index, 1);
        }
        setFilterTabs([...tabs]);
    }, [config.action_items, config.drift]);

    useEffect(() => {
        setTabs();
    }, [setTabs]);

    const getNotificationList = useCallback((filters) => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;
        const signal = controllerRef.current?.signal;
        onChangeFilterResult(filters, signal);
    }, [onChangeFilterResult]);

    const updateDatasourceList = useCallback((sourcesSelcted, updatedList) => {
        /*
         * const updatedDatasources = datasources.map((p) => {
         *     if (sourcesSelcted.length > 0) {
         *         if (sourcesSelcted.includes(p.id)) {
         *             return { ...p, isSelected: true };
         *         } return { ...p, isSelected: false };
         *     }
         *     return { ...p, isSelected: false };
         * });
         * setDatasources([...updatedDatasources]);
         */
        if (sourcesSelcted.length > 0) {
            updatedList = updatedList.filter((p) => sourcesSelcted.includes(p.source_id));
        } else {
            updatedList = updatedList.map((p) => { return { ...p, isSelected: false }; });
        }
        setDatasetsToList([...updatedList]);
    }, []);

    const updateDatasetList = useCallback((sourcesSelcted) => {
        const updatedDatasets = datasets.filter((dataset) => sourcesSelcted.includes(dataset.source_id));
        setDatasetsToList([...updatedDatasets]);
    }, [datasets]);

    const onChangeFilter = useCallback((property, value) => {
        if (property === "users") {
            const defaultUsers = [
                {
                    isDefault: true,
                    isSelected: true,
                    isUser: false,
                    name: 'All'
                }
            ];
            if (value === 'All') {
                filters[property] = defaultUsers;
            } else {
                const user = usersList.find((p) => p.name === value);
                const index = filters.users.findIndex((p) => p.id === user.id);
                if (index > -1) {
                    filters.users.splice(index, 1);
                } else {
                    filters.users.push({ ...user });
                }

                if (filters.users.length === 0) {
                    filters.users = defaultUsers;
                } else {
                    const defaultUserIndex = filters.users.findIndex((p) => p.name === 'All');
                    if (defaultUserIndex > -1) {
                        filters.users.splice(defaultUserIndex, 1);
                    }
                }
                const userItem = usersList.find((p) => p.name === value);
                const userItemIndex = usersList.findIndex((p) => p.id === user.id);
                userItem.isSelected = !userItem.isSelected;
                usersList.splice(userItemIndex, 1, { ...userItem });
                setUsersList(usersList);
            }
        } else if ((property === "datasources" || property === "datasets") && typeof value !== 'string') {
            let sourceList = property === "datasources" ? datasources : datasets;
            let selectedSources = [];
            if (value.name === 'all') {
                if (value.isSelected) {
                    sourceList = sourceList.map((option) => {
                        if (property === "datasources") {
                            selectedSources.push(option.id);
                        } else {
                            if (!selectedSources.includes(option.source_id)) {
                                selectedSources.push(option.source_id);
                            }
                        }
                        return { ...option, isSelected: true };
                    });
                } else {
                    sourceList = sourceList.map((option) => { return { ...option, isSelected: false }; });
                }
                (property === "datasets") && setDatasets([...sourceList]);
                if (property === "datasources") {
                    filters.datasources = [];
                }
                filters.datasets = [];
            } else {
                const sourceItemIndex = sourceList.findIndex((p) => p.id === value.id);
                sourceList[sourceItemIndex].isSelected = !sourceList[sourceItemIndex]?.isSelected;
                const idString = property === "datasources" ? "id" : "source_id";
                if (sourceList[sourceItemIndex].isSelected) {
                    (!selectedSources.includes(sourceList[sourceItemIndex][idString])) && selectedSources.push(sourceList[sourceItemIndex][idString]);
                    selectedSources = [...selectedSources, ...selectedIds];
                } else {
                    if (selectedIds.includes(sourceList[sourceItemIndex][idString])) {
                        const index = selectedIds.indexOf(sourceList[sourceItemIndex][idString]);
                        let selectedList = [];
                        if (property === "datasets") {
                            selectedList = sourceList.filter((p) => (p.isSelected === true) && (p.source_id === sourceList[sourceItemIndex][idString]));
                        }
                        selectedList.length === 0 && selectedIds.splice(index, 1);
                        selectedSources = [...selectedIds];
                    }
                }
                filters[property] = sourceList.filter((p) => p.isSelected === true);
                if (filters[property].length > 1) {
                    filters[property] = filters[property].filter((data) => !data.isDefault && data?.isSelected === true);
                }
            }

            setSelectedIds([...selectedSources]);

            if (property === "datasources") {
                setDatasources([...sourceList]);
                updateDatasetList(selectedSources);
            } else {
                setDatasetsToList([...sourceList]);
                updateDatasourceList(selectedSources, sourceList);
            }
        } else {
            filters[property] = value;
        }

        if (property === "notification") {
            updateNotificationTypes(value);
        }
        setNotificationFilters({ ...filters });
        // setUserInput('');
        dispatch(setFilters({ ...filters }));
        getNotificationList({ ...filters });
    }, [datasets, datasources, dispatch, filters, getNotificationList, selectedIds, updateDatasetList, updateDatasourceList, updateNotificationTypes, usersList]);

    const resetFilter = useCallback(() => {
        const filter = {
            users: [...usersList],
            datasources: [{ name: 'All', isDefault: true }],
            datasets: [{ name: 'All', isDefault: true }],
            attributes: [{ name: 'All', isDefault: true }],
            types: "All",
            jobstatus: "All",
            filter: "Last 3 Days",
            notification: "All",
            search: ""
        };
        const filters = {
            ...filter
        };
        for (const user of usersList) {
            user.isSelected = user.isDefault;
        }
        const datasourceList = datasources.filter((data) => !data.isDefault).map((data) => { return { ...data, isSelected: true }; });
        const datasetList = datasets.filter((data) => !data.isDefault).map((data) => { return { ...data, isSelected: true }; });
        setUsersList([...usersList]);
        setDatasets([...datasetList]);
        setDatasetsToList([...datasetList]);
        setDatasources([...datasourceList]);
        // setUserInput('');
        setNotificationFilters({ ...filters });
        getNotificationList({ ...filters });
        dispatch(setFilters({ ...filters }));
        setTypeFilters([...appConstants.notificationOverallFilters, ...driftFilters, ...ruleFilters]);
    }, [datasets, datasources, dispatch, driftFilters, getNotificationList, ruleFilters, usersList]);

    const enableFilterInfo = () => {
        setFilter(true);
        setNotificationFilters({ ...notificationFilters });
        updateNotificationTypes(notificationFilters.notification);
    };

    return (
        <Grid container alignItems="center" justify="space-between" className={classNames(classes.headerContainer, classes.catHeaderContainer)}>
            <Grid item>
                <Typography variant="h2" component="h2">
                    Notification
                </Typography>
            </Grid>
            <Grid item>
                <Grid container alignItems="center">
                    <Grid style={{ marginRight: 7 }}>
                        <Search
                            value={filters.search ? filters.search : ""}
                            placeholder="Search Here"
                            className={classes.dqSearchBox}
                            onChange={(value) => onChangeFilter('search', value)}
                        />
                    </Grid>
                    {
                        enableFilter &&
                        <Grid className={classNames(classes.filterContainer)}>
                            <ClickAwayListener onClickAway={() => setFilter(false)} mouseEvent={'onMouseUp'} touchEvent={'onTouchEnd'}>
                                <Grid container alignItems="center" justify="space-between" className={classNames(classes.datasourceFilterSection, "datasourceFilterSection")}>
                                    <TooltipComponent title="Close" placement="bottom" arrow>
                                        <IconButton onClick={() => setFilter(!enableFilter)} className={classes.marginRight12}>
                                            <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                            </svg>
                                        </IconButton>
                                    </TooltipComponent>
                                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                                        <FormLabel>
                                            Notification
                                        </FormLabel>
                                        <TextBox
                                            value={filters.notification}
                                            name="notification"
                                            fullWidth
                                            select
                                            onChange={(event) => onChangeFilter(event.target.name, event.target.value)}
                                            SelectProps={
                                                {
                                                    className: classes.cursorPointer,
                                                    value: filters.notification,
                                                    onChange: (event) => onChangeFilter(event.target.name, event.target.value),
                                                    MenuProps: {
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "center"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "center"
                                                        },
                                                        getContentAnchorEl: null
                                                    }
                                                    // IconComponent: () => DropDownIcon()
                                                }
                                            }
                                        >
                                            {
                                                sortTable(filterStatus, "asc", "name").map((status, index) =>
                                                    <MenuItem key={index} value={status}>
                                                        {status}
                                                    </MenuItem>
                                                )
                                            }
                                        </TextBox>
                                    </Grid>

                                    <Grid item className={classes.userSelectContainer}>
                                        <FormLabel>
                                            Datasources
                                        </FormLabel>
                                        <AutoCompleteCheckBox
                                            availableList={sortTable(datasources, "asc", "name")}
                                            displayValue="name"
                                            onChange={(value) => onChangeFilter("datasources", value)}
                                        />
                                    </Grid>
                                    <Grid item className={classes.userSelectContainer}>
                                        <FormLabel>
                                            Datasets
                                        </FormLabel>
                                        <AutoCompleteCheckBox
                                            availableList={sortTable(datasetsToList, "asc", "name")}
                                            displayValue="name"
                                            onChange={(value) => onChangeFilter("datasets", value)}
                                        />
                                    </Grid>
                                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                                        <FormLabel>
                                            Type
                                        </FormLabel>
                                        <TextBox
                                            value={filters.types}
                                            name="types"
                                            fullWidth
                                            select
                                            onChange={(event) => onChangeFilter(event.target.name, event.target.value)}
                                            SelectProps={
                                                {
                                                    className: classes.cursorPointer,
                                                    value: filters.types,
                                                    onChange: (event) => onChangeFilter(event.target.name, event.target.value),
                                                    MenuProps: {
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "center"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "center"
                                                        },
                                                        getContentAnchorEl: null
                                                    }
                                                    // IconComponent: () => DropDownIcon()
                                                }
                                            }
                                        >
                                            {
                                                sortTable(typeFilters, "asc", "name").map((filter, index) =>
                                                    <MenuItem key={index} value={filter.value}>
                                                        {filter.name}
                                                    </MenuItem>
                                                )
                                            }
                                        </TextBox>
                                    </Grid>
                                    {
                                        filters.notification === "Schedule" &&

                                        <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                                            <TextBox
                                                label="Job Status"
                                                value={filters.jobstatus}
                                                name="jobstatus"
                                                fullWidth
                                                select
                                                onChange={(event) => onChangeFilter(event.target.name, event.target.value)}
                                                SelectProps={
                                                    {
                                                        className: classes.cursorPointer,
                                                        value: filters.jobstatus,
                                                        onChange: (event) => onChangeFilter(event.target.name, event.target.value),
                                                        MenuProps: {
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "center"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "center"
                                                            },
                                                            getContentAnchorEl: null
                                                        }
                                                        // IconComponent: () => DropDownIcon()
                                                    }
                                                }
                                            >
                                                {
                                                    ["All", "Completed", "Started", "Created", "Failed", "Killed", "System Killed"].map((status, index) =>
                                                        <MenuItem key={index} value={status}>
                                                            {status}
                                                        </MenuItem>
                                                    )
                                                }
                                            </TextBox>
                                        </Grid>
                                    }
                                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                                        <TextBox
                                            label="Filter By"
                                            value={filters.filter}
                                            name="filter"
                                            fullWidth
                                            select
                                            onChange={(event) => onChangeFilter(event.target.name, event.target.value)}
                                            SelectProps={
                                                {
                                                    className: classes.cursorPointer,
                                                    value: filters.filter,
                                                    onChange: (event) => onChangeFilter(event.target.name, event.target.value),
                                                    MenuProps: {
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "center"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "center"
                                                        },
                                                        getContentAnchorEl: null
                                                    }
                                                    // IconComponent: () => DropDownIcon()
                                                }
                                            }
                                        >
                                            {
                                                ["All", "Last 3 Days", "Last 7 Days", "Last 30 Days", "Last 60 Days", "Last 90 Days", "Today"].map((status, index) =>
                                                    <MenuItem key={index} value={status}>
                                                        {status}
                                                    </MenuItem>
                                                )
                                            }
                                        </TextBox>
                                    </Grid>
                                    {/* <Grid item className={classes.userSelectContainer}>
                                        <FormLabel>
                                            Users
                                        </FormLabel>
                                        <UserAutoComplete
                                            availableList={usersList}
                                            selectedList={filters.users ? filters.users : []}
                                            displayValue="name"
                                            onChange={(value) => onChangeFilter("users", value)}
                                            onInputValueChange={(value) => onInputValueOnChange(value)}
                                            inputValue={userInput}
                                            onRemoveItem={(index) => removeItem(index)}
                                        />
                                    </Grid> */}
                                    <Grid item>
                                        <TooltipComponent title="Clear Filter" placement="bottom" arrow>
                                            <IconButton onClick={() => resetFilter()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22.795" viewBox="0 0 20 22.795">
                                                    <g id="filter_10_" data-name="filter (10)" transform="translate(-31.388)">
                                                        <path id="Path_1437" data-name="Path 1437" d="M46.071,0a5.326,5.326,0,0,0-5.1,3.8h-8.59a1,1,0,0,0-.814,1.564l5.776,7.882a1.046,1.046,0,0,1,.185.6V21.75a1.044,1.044,0,0,0,1.668.835l2.564-1.937a1.527,1.527,0,0,0,.612-1.22V13.84a1.047,1.047,0,0,1,.185-.6l2.07-2.825A5.31,5.31,0,1,0,46.071,0ZM41.477,12.462a2.375,2.375,0,0,0-.438,1.377c0,6.076.033,5.658-.075,5.737l-2.1,1.586V13.84a2.376,2.376,0,0,0-.428-1.364l-5.38-7.341h7.7A5.307,5.307,0,0,0,43.369,9.88Zm4.594-3.18a3.973,3.973,0,1,1,3.982-3.973A3.982,3.982,0,0,1,46.071,9.282Z" transform="translate(0)" fill="#f38080" />
                                                        <path id="Path_1438" data-name="Path 1438" d="M321.45,76.089a.668.668,0,0,0-.944,0l-.793.791-.793-.791a.668.668,0,0,0-.944.945l.791.79-.791.79a.668.668,0,0,0,.944.945l.793-.791.793.791a.668.668,0,0,0,.944-.945l-.791-.79.791-.79A.668.668,0,0,0,321.45,76.089Z" transform="translate(-273.642 -72.515)" fill="#f38080" />
                                                    </g>
                                                </svg>
                                            </IconButton>
                                        </TooltipComponent>
                                    </Grid>
                                </Grid>
                            </ClickAwayListener>
                        </Grid>
                    }
                    <TooltipComponent title="Filters" placement="bottom" arrow>
                        <IconButton onClick={() => enableFilterInfo()} className={classNames(classes.marginRight7, classes.filterImg)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.923" viewBox="0 0 20 16.923">
                                <g transform="translate(-3 -5)">
                                    <path id="Path_1391" data-name="Path 1391" d="M22.231,6.538H11.462A1.54,1.54,0,0,0,9.923,5H8.385A1.54,1.54,0,0,0,6.846,6.538H3.769a.769.769,0,1,0,0,1.538H6.846A1.54,1.54,0,0,0,8.385,9.615H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,8.077V6.538H9.923V7.3s0,0,0,0,0,0,0,0v.766Z" fill={theme.palette.primary.main} />
                                    <path id="Path_1392" data-name="Path 1392" d="M22.231,14.538H17.615A1.54,1.54,0,0,0,16.077,13H14.538A1.54,1.54,0,0,0,13,14.538H3.769a.769.769,0,0,0,0,1.538H13a1.54,1.54,0,0,0,1.538,1.538h1.538a1.54,1.54,0,0,0,1.538-1.538h4.615a.769.769,0,1,0,0-1.538Zm-7.692,1.538V14.538h1.538V15.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -1.846)" fill={theme.palette.primary.main} />
                                    <path id="Path_1393" data-name="Path 1393" d="M22.231,22.538H11.462A1.54,1.54,0,0,0,9.923,21H8.385a1.54,1.54,0,0,0-1.538,1.538H3.769a.769.769,0,0,0,0,1.538H6.846a1.54,1.54,0,0,0,1.538,1.538H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,24.077V22.538H9.923V23.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -3.692)" fill={theme.palette.primary.main} />
                                </g>
                            </svg>
                        </IconButton>
                    </TooltipComponent>
                    <TooltipComponent title="Drift Report" placement="bottom" arrow>
                        <IconButton onClick={() => history.push('/report')} className={classNames(classes.marginRight7, classes.filterImg)}>
                            <svg fill={theme.palette.primary.main} xmlns="http://www.w3.org/2000/svg" width="15.057" height="15.661" viewBox="0 0 15.057 26.661">
                                <path id="Path_16135" data-name="Path 16135" d="M-16689.465-6041.682l-6.477,9.552h4.371l-6.152,6.961h3.4l-10.2,9.876,4.7-8.9h-4.7l4.7-7.933h-4.7l5.211-9.823" transform="translate(16704.521 6041.953)" />
                            </svg>
                        </IconButton>
                    </TooltipComponent>
                </Grid>
            </Grid>
        </Grid>
    );
};

NotificationFilter.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    onChangeFilterResult: PropTypes.func,
    history: PropTypes.object
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...NotificationStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(NotificationFilter);