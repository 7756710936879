import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import { withStyles, Typography, TableCell, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Styles from '../../layouts/Styles.jsx';
import MasterStyles from '../Master/MasterStyles.jsx';
import classNames from 'classnames';

const DriftReportRow = (props) => {
    const { classes, report, columns, enableToggle, isExpanded, expandAccordian, navigateReport } = props;

    const toggleRow = (event) => {
        event.stopPropagation();
        expandAccordian(report);
    };

    return (
        <Fragment>
            {
                columns.map((column, index) =>
                    <TableCell onClick={(event) => toggleRow(event)} key={`row_${index}`} className={classNames(columns.length - 1 === index ? classes.flexLast : null, index === 0 ? classes.firstColumn : classes.otherColumn, columns.length - 1 === index && report.isParent ? classes.parentLastCol : null, index === 0 && report.isParent ? classes.parentFirstCol : null)}>
                        <Typography>
                            <span className={classes.cursor} onClick={(event) => { event.stopPropagation(); navigateReport(report, column.value); }}>
                                {report[column.value] ? report[column.value] : 0}
                            </span>
                        </Typography>
                        {
                            (columns.length - 1 === index) &&
                            <IconButton onClick={(event) => toggleRow(event)} className={classNames(classes.attributeRowExpandIcon, classes.noPadding, isExpanded ? 'expanded' : '')} style={{ "visibility": enableToggle ? 'visible' : 'hidden' }}>
                                <ExpandMoreIcon />
                            </IconButton>
                        }
                    </TableCell>
                )
            }

        </Fragment>
    );
};


DriftReportRow.propTypes = {
    classes: PropTypes.object,
    report: PropTypes.object,
    columns: PropTypes.array,
    enableToggle: PropTypes.bool,
    isExpanded: PropTypes.bool,
    expandAccordian: PropTypes.func,
    navigateReport: PropTypes.func

};

export default withStyles((theme) => ({
    ...MasterStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DriftReportRow);