const ProfileChartFilterOperators = {
    Length: [
        {
            label: 'length is equal to',
            valueRequired: true,
            operator: 'length(field.null_format)==value',
            sqlOperator: 'len(field.null_format)==value',
            isChartOperation: true,
            removeTypeParser: true,
            disableCase: true,
            removerFormatter: true,
            removeDateParser: true,
            chartType: 'Length'
        }, {
            label: 'length is not equal to',
            operator: 'length(field.null_format)!=value',
            sqlOperator: 'len(field.null_format)!=value',
            valueRequired: true,
            isChartOperation: true,
            removeTypeParser: true,
            disableCase: true,
            removerFormatter: true,
            removeDateParser: true,
            chartType: 'Length'
        }, {
            label: 'length is greater than',
            operator: 'length(field.null_format)>value',
            sqlOperator: 'len(field.null_format)>value',
            valueRequired: true,
            isChartOperation: true,
            removeTypeParser: true,
            disableCase: true,
            removerFormatter: true,
            removeDateParser: true,
            chartType: 'Length'
        }, {
            label: 'length is less than',
            operator: 'length(field.null_format)<value',
            sqlOperator: 'len(field.null_format)<value',
            valueRequired: true,
            isChartOperation: true,
            removeTypeParser: true,
            disableCase: true,
            removerFormatter: true,
            removeDateParser: true,
            chartType: 'Length'
        }, {
            label: 'length is greater than or equal to',
            operator: 'length(field.null_format)>=value',
            sqlOperator: 'len(field.null_format)>=value',
            valueRequired: true,
            isChartOperation: true,
            removeTypeParser: true,
            disableCase: true,
            removerFormatter: true,
            removeDateParser: true,
            chartType: 'Length'
        }, {
            label: 'length is less than or equal to',
            operator: 'length(field.null_format)<=value',
            sqlOperator: 'len(field.null_format)<=value',
            valueRequired: true,
            isChartOperation: true,
            removeTypeParser: true,
            disableCase: true,
            removerFormatter: true,
            removeDateParser: true,
            chartType: 'Length'
        }
    ],
    Pattern: [
        {
            label: 'matches',
            operator: "field.null_format rlike 'value'",
            sqlOperator: "field.null_format like 'value'",
            valueRequired: true,
            isChartOperation: true,
            removeTypeParser: true,
            disableCase: true,
            removerFormatter: true,
            removeDateParser: true,
            chartType: 'Pattern'
        },
        {
            label: 'not matches',
            operator: "field.null_format not rlike 'value'",
            sqlOperator: "field.null_format not like 'value'",
            valueRequired: true,
            isChartOperation: true,
            removeTypeParser: true,
            disableCase: true,
            removeDateParser: true,
            removerFormatter: true,
            chartType: 'Pattern'
        }
    ],
    Range: [
        {
            label: 'equals to',
            operator: 'trim(field)==value',
            sqlOperator: 'field==value',
            valueRequired: true,
            isChartOperation: true,
            chartType: 'Range'
        }
    ],
    CaseSensitivity: [
        {
            label: 'case matches',
            operator: "trim(field) rlike 'value'",
            sqlOperator: "field like 'value'",
            valueRequired: true,
            isChartOperation: true,
            disableCase: true,
            chartType: 'CaseSensitivity'
        }, {
            label: 'case not matches',
            operator: "trim(field) not rlike 'value'",
            sqlOperator: "field not like 'value'",
            valueRequired: true,
            isChartOperation: true,
            disableCase: true,
            chartType: 'CaseSensitivity'
        }
    ],
    Soundex: [
        {
            label: 'sound matches',
            operator: "soundex(field)=='value'",
            sqlOperator: "soundex(field)=='value'",
            valueRequired: true,
            isChartOperation: true,
            disableCase: true,
            field: 'Soundex',
            chartType: 'Soundex'
        }, {
            label: 'sound not matches',
            operator: "soundex(trim(field))=='value'",
            sqlOperator: "soundex(field)=='value'",
            valueRequired: true,
            isChartOperation: true,
            disableCase: true,
            field: 'Soundex',
            chartType: 'Soundex'
        }
    ],
    Count: [
        {
            label: 'is unique',
            operator: 'temp_rank == 1',
            sqlOperator: 'count(*) == 1',
            valueRequired: false,
            isChartOperation: true,
            disableCase: true,
            hasGroup: true
        }, {
            label: 'is not unique',
            operator: 'temp_rank > 1',
            sqlOperator: 'count(*) > 1',
            valueRequired: false,
            isChartOperation: true,
            disableCase: true,
            hasGroup: true
        }, {
            label: 'is duplicate',
            operator: 'temp_rank > 1',
            sqlOperator: 'temp_rank > 1',
            valueRequired: false,
            isChartOperation: true,
            disableCase: true,
            hasGroup: true
        }, {
            label: 'is distinct',
            operator: 'temp_rank == 1',
            sqlOperator: 'temp_rank == 1',
            valueRequired: false,
            isChartOperation: true,
            disableCase: true,
            hasGroup: true
        }, {
            label: 'matches',
            operator: "field.null_format rlike 'value'",
            sqlOperator: "field.null_format like 'value'",
            valueRequired: true,
            isChartOperation: true,
            removeDateParser: true,
            disableCase: true
        },
        {
            label: 'not matches',
            operator: "field.null_format not rlike 'value'",
            sqlOperator: "field.null_format not like 'value'",
            valueRequired: true,
            isChartOperation: true,
            removeDateParser: true,
            disableCase: true
        },
        {
            label: 'is missing',
            operator: "field.null_format rlike '(^$)|(^[ ]+$)'",
            sqlOperator: "field.null_format like ''",
            valueRequired: false,
            isChartOperation: true,
            removeDateParser: true,
            disableCase: true
        },
        {
            label: 'is not missing',
            operator: "field.null_format not rlike '(^$)|(^[ ]+$)'",
            sqlOperator: "field.null_format not like ''",
            valueRequired: false,
            isChartOperation: true,
            removeDateParser: true,
            disableCase: true
        }
    ]
};

export default ProfileChartFilterOperators;