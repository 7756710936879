import { appConstants } from '../constants/appConstants';
import { getCookie, removeCookie } from '../helpers/appHelpers';
import { history } from '../config/history';

const apiBaseUrl = appConstants.API_BASE_URL;
let previousPageName = '';
let currentPageName = '';
let abortController = null;

export const setCurrentPage = (page) => {
	if (currentPageName === page) {
		return;
	}
	previousPageName = currentPageName;
	currentPageName = page;
	abortController?.abort();
	abortController = new AbortController();
};

const getCancellationToken = () => {
	return { signal: abortController.signal };
};


/**
 * GET request
 * @param {*} endpoint
 */
export const getRequest = async (endpoint, cancellationToken) => {
	return makeRequest(`${apiBaseUrl}/${endpoint}/`, 'GET', null, false, cancellationToken);
};

/**
 * PUT requests
 * @param {* The put endpoint} endpoint
 * @param {* The request body data} body
 * @param {* <true> if the put method contains any images, <false> otherwise.} isFormData
 */
export const putRequest = async (endpoint, body, isFormData, cancellationToken) => {
	return makeRequest(`${apiBaseUrl}/${endpoint}/`, 'PUT', body, isFormData, cancellationToken);
};

/**
 * POST request
 * @param {* The post endpoint} endpoint
 * @param {* The request body data} body
 * @param {* <true> if the post method contains any images, <false> otherwise.} isFormData
 */
export const postRequest = async (endpoint, body, isFormData, cancellationToken) => {
	return makeRequest(`${apiBaseUrl}/${endpoint}/`, 'POST', body, isFormData, cancellationToken);
};

/**
 * DELETE request
 * @param {* The delete endpoint} endpoint
 */
export const deleteRequest = async (endpoint, cancellationToken) => {
	return makeRequest(`${apiBaseUrl}/${endpoint}/`, 'DELETE', null, false, cancellationToken);
};

const makeRequest = (endpoint, verb, body, isFormData = false, cancellationToken = null) => {
	if (!cancellationToken) {
		if (previousPageName !== currentPageName) {
			cancellationToken = getCancellationToken();
		}
	}
	if (!isFormData) {
		body = body ? JSON.stringify(body) : null;
	}
	const requestOptions = {
		method: verb,
		headers: getHeaders(isFormData),
		body,
		signal: cancellationToken?.signal
	};
	return fetch(endpoint, requestOptions)
		.then(handleResponse)
		.then((response) => {
			return response;
		}).catch((error) => {
			throw error;
		});
};

/**
 * Handler for the response recieved from fetch request
 * @param {* The response recieved from fetch request} response
 */
const handleResponse = (response) => {
	if (response.status !== 200) {
		if (response.status === 401) {
			removeCookie();
			history.push({
				pathname: '/'
			});
		}
		return Promise.reject(response.statusText);
	}
	if (response.url.includes('exportdata') || response.url.includes('exportMetadata') || response.url.includes('exportRules') || response.url.includes('exportusers')) {
		return response.blob().then((blob) => {
			return blob;
		});
	}
	return response.text().then((text) => {
		try {
			if (text && text.includes("\\'")) {
				text = text.replace(/[\\]+'/g, "\\\\'").replace(/(Infinity)/g, 0);
			}
			const responseData = text && JSON.parse(`${text}`);
			return responseData;
		} catch (error) {
			// eslint-disable-next-line no-console
			console.log(error, text);
		}
	});
};

/**
 * Prepares headers for the request
 * @param {* <true> if the request contains any images, <false> otherwise.} isFormData
 */
const getHeaders = (isFormData) => {
	const token = getCookie('token');
	const headers = new Headers();
	if (token) { headers.set('Authorization', `JWT ${token}`); }
	if (!isFormData) { headers.set('Content-Type', 'application/json'); }

	return headers;
};

/**
 * Prepares the query string based on the given input prams
 * @param {* The params used to prepare query string} params
 */
export const makeQueryString = (params) => {
	let queries = Object.keys(params).map((key) => (params[key] && params[key].length > 0 ? `${key} + '=' + ${params[key]}` : ''));
	queries = queries.filter((query) => query.length > 0);
	return '?' + queries.join('&');
};