const DomainSelectStyles = (theme) => ({
    paper: {
        '& .MuiMenuItem-root': {
            width: 165
        },
        position: 'absolute',
        zIndex: 1330,
        marginTop: 20,
        maxWidth: 'max-content',
        maxHeight: 200,
        overflowY: 'auto',
        width: 300,
        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)"
    },
    chipContainer: {
        width: 'auto',
        display: 'inline-flex',
        marginBottom: '2px'
    },
    avatar: {
        marginRight: 5,
        transition: 'all 2s'
    },
    allText: {
        marginRight: 8,
        marginTop: 3
    },
    menuItem: {
        justifyContent: 'space-between'
    },
    padding2: {
        padding: 2
    },
    filterSelectedavatar: {
        width: '20px !important',
        height: '20px !important',
        fontSize: '9px !important'
    },
    selectContainer: {
        display: 'flex'
    },
    selectDropDown: {
        position: 'relative',
        width: '100%'
    },
    inputHoverBg: {
        '&:hover': {
            background: '#f2f6f7'
        }
    },
    chipEllipsis: {
        '& .MuiChip-label': {
            maxWidth: '150px'
        }
    }
});

export default DomainSelectStyles;