import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid } from '@material-ui/core';

import DatasetStyles from '../../DatasetStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';
import PropertyTile from './PropertyTile.jsx';
import { setPropertySliderIndex } from '../../../../actions/datasetActions';
import { useDispatch, useSelector } from 'react-redux';
import TileViewStyles from './TileViewStyles.jsx';

import Slider from "react-slick";

const PropertiesSlider = (props) => {
    const { onInitSlider, properties, isEditable, datasetId, setCarouselRef, fields, tabFields, customTabs, isDeletePermission, isEditPermission, updateFieldProperties } = props;
    const [fieldsValue, setfieldsValue] = useState(fields);
    const { datePickerToggle } = useSelector((state) => state.common);
    const { sidePanel, type } = useSelector((state) => state.notification);


    /**
     * @name useEffect
     * @description UseEffect run when page initialize and any updates happends
     */
    useEffect(() => {
        if (fields.length > 0) {
            setfieldsValue(fields);
        }
    }, [datasetId, fields]);

    const dispatch = useDispatch();
    const currentIndex = useSelector(({ dataset }) => dataset.propertySliderIndex);
    const settings = {
        // This is for disabling slide operations when date picker is open
        accessibility: !datePickerToggle,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        onInit: (event) => onInitSlider(event),
        afterChange: (event) => updateSliderIndex(event)
    };
    const updateSliderIndex = useCallback((index) => {
        if (index === null) {
            return;
        }
        setTimeout(() => dispatch(setPropertySliderIndex(index)), 0);
    }, [dispatch]);

    return (
        <Grid>
            {
                properties && Object.keys(properties).length > 0 ?
                    <Slider ref={(slider) => setCarouselRef(slider)} {...settings}>
                        {
                            Object.keys(properties).map((attribute, index) =>
                                <PropertyTile
                                    style={{ width: (sidePanel && type === "conversation") ? 'calc(100vw - 450px) ' : 'calc(100vw - 150px)', marginRight: 40 }}
                                    datasetId={datasetId}
                                    isVisible={index === currentIndex}
                                    isEditable={isEditable}
                                    key={`attributeProperty${index}`}
                                    attribute={properties[attribute] ? properties[attribute] : {}}
                                    updatePropertiesList={props.updateProperties}
                                    fields={fieldsValue}
                                    tabFields={tabFields}
                                    customTabs={customTabs}
                                    isDeletePermission={isDeletePermission}
                                    isEditPermission={isEditPermission}
                                    updateFieldProperties={updateFieldProperties}
                                />
                            )
                        }
                    </Slider>
                    : null
            }
        </Grid>
    );
};
PropertiesSlider.displayName = "PropertiesSlider";

PropertiesSlider.propTypes = {
    classes: PropTypes.object,
    onInitSlider: PropTypes.func,
    properties: PropTypes.object,
    isEditable: PropTypes.bool,
    updateProperties: PropTypes.func,
    datasetId: PropTypes.string,
    setCarouselRef: PropTypes.func,
    fields: PropTypes.array,
    tabFields: PropTypes.array,
    customTabs: PropTypes.array,
    isDeletePermission: PropTypes.bool,
    isEditPermission: PropTypes.bool,
    changeSlider: PropTypes.func,
    updateFieldProperties: PropTypes.func
};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(PropertiesSlider);