import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { withStyles, Grid, Button } from '@material-ui/core';

import ConfigureDataSourceStyles from './ConfigureDataSourceStyles.jsx';
import Connector from '../../components/Connectors/Connector.jsx';
import Styles from '../../layouts/Styles.jsx';

const ConfigureDataSource = (props) => {
    const { classes, history, location, getPageTitle } = props;

    useEffect(() => {
        getPageTitle('Configure Datasource');
    });

    return (
        <Grid container direction="column">
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.backButtonContainer}>
                <Button className={classnames(classes.block, classes.nopadding, classes.backButton, classes.bgNone)}
                    onClick={() => history.goBack()}>
                    {/* <svg id="backArrow" xmlns="http://www.w3.org/2000/svg" width="8.335" height="13.909" viewBox="0 0 8.335 13.909" style={{fill:'#6f777c', marginRight:"5px"}}><defs></defs><g transform="translate(-11.172 0)"><path class="a" d="M11.564,5.967a1.359,1.359,0,0,1,.228-.181L17.183.395A1.347,1.347,0,0,1,19.088,2.3L14.446,6.943l4.666,4.667a1.347,1.347,0,1,1-1.9,1.905L11.792,8.1a1.4,1.4,0,0,1-.228-2.133Z" transform="translate(0 0)"/></g></svg> */}
                    <svg id="backArrow" xmlns="http://www.w3.org/2000/svg" width="8.335" height="13.909" viewBox="0 0 8.335 13.909" style={{ fill: '#6f777c', marginRight: "5px" }}>
                        <defs />
                        <g transform="translate(-11.172 0)">
                            <path className="a" d="M11.564,5.967a1.359,1.359,0,0,1,.228-.181L17.183.395A1.347,1.347,0,0,1,19.088,2.3L14.446,6.943l4.666,4.667a1.347,1.347,0,1,1-1.9,1.905L11.792,8.1a1.4,1.4,0,0,1-.228-2.133Z" transform="translate(0 0)" />
                        </g>
                    </svg>
                    {'Back'}
                </Button>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.connectorContainer}>
                <Connector
                    sourceType={location.state.datasourceType}
                    copyConfig={location.state.con_config ? location.state.con_config : null}
                    history={props.history} />
            </Grid>
        </Grid>
    );
};

ConfigureDataSource.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    getPageTitle: PropTypes.func
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(withStyles((theme) => ({
    ...ConfigureDataSourceStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ConfigureDataSource));