import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    withStyles, Grid, Typography, Button, Table, TableHead, Card, RadioGroup, Radio, FormControlLabel,
    TableBody, TableRow, TableCell, IconButton, MenuItem, Accordion, AccordionDetails, AccordionSummary, Switch
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';

import ConnectorStyles from '../ConnectorStyles.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextBox from '../../TextBox/TextBox.jsx';
import { appConstants } from '../../../constants/appConstants.js';
import Styles from '../../../layouts/Styles.jsx';
import { appConfig } from '../../../config/appConfig.js';
import { getConnectedDatasets, connectDataset, connectDatasource, getSchema } from '../../../actions/connectorActions';
import { deleteDataSet } from '../../../actions/datasetActions.js';
import Loader from '../../Loaders/Loader.jsx';
import AlertDialog from '../../AlertDialog/AlertDialog.jsx';
import CheckboxComponent from '../../ChecboxComponent/CheckboxComponent.jsx';
import ToolTipComponent from '../../Tooltip/Tooltip.jsx';
import PullConfiguration from '../PullConfiguration.jsx';
import QuerySetContainer from '../QuerySetContainer.jsx';
import AddIcon from '@material-ui/icons/Add';
import { failure, success } from '../../../actions/baseActions';
import { alertActions } from '../../../constants/actionTypes/alertActionTypes';
import WarningIcon from '../../Warning/WarningIcon.jsx';

const ORACLEConnector = (props) => {
    const { classes, datasourceType, datasourceId, onConnect, onCancel, theme, history, copyConfig } = props;
    const dispatch = useDispatch();
    const licenseConfig = useSelector(({ setting }) => setting.config);
    const [connectionConfig, setConnectionConfig] = useState({
        server: '',
        port: '',
        username: '',
        password: '',
        SID: '',
        schema: '',
        mode: 'datasets',
        scan: Boolean(licenseConfig.scan && !licenseConfig.pull)
    });
    const [defaultCount] = useState(20);
    const [datasetList, setDatasetList] = useState([]);
    const [pullConfiguration, setPullConfiguration] = useState(null);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [showAlert, setshowAlert] = useState(false);
    const [datasets, setDatasets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [datasource, setDatasource] = useState(null);
    const [selectedDataSet, setSelectedDataSet] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [isOnUpdate, setIsOnUpdate] = useState(Boolean(!datasourceId));
    const [isValid, setIsValid] = useState(true);
    const [isAnySelected, setIsAnySelected] = useState(false);
    const [onUpdate, setOnUpdate] = useState(false);
    const [selectedDatasets, setSelectedDatasets] = useState([]);
    const [filterSelected, setFilterSelected] = useState(false);
    const userConfig = useSelector(({ setting }) => setting.user_config);

    const onFilter = useCallback((_event) => {
        setFilterSelected(!filterSelected);
    }, [filterSelected]);

    const onSearchDatasets = useCallback((key) => {
        setSearchKey(key);
        const filteredDatasets = datasets.filter((p) => (p.name.toLowerCase().includes(key.toLowerCase()))
            || (p.attributes && p.attributes.filter((attribute) => attribute.name.toLowerCase().includes(key.toLowerCase())).length > 0)
        ).slice(0, defaultCount);
        setDatasetList(filteredDatasets);
    }, [datasets, defaultCount]);

    const updateSelectedDataset = useCallback((selectedDatasets) => {
        for (const name of selectedDatasets) {
            const dataset = datasetList.find((p) => p.table_name === name);
            if (dataset) {
                dataset.isSelected = true;
            }
            const sourceDataset = datasets.find((p) => p.table_name === name);
            if (sourceDataset) {
                sourceDataset.isSelected = true;
            }
        }
        setDatasets([...datasets]);
        setDatasetList(datasetList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasetList]);

    useEffect(() => {
        updateSelectedDataset(selectedDatasets);
    }, [selectedDatasets, updateSelectedDataset]);

    /**
     * Handles the connection configuration changes
     * @param {Name of the property} property
     * @param {Value of the property} value
     */
    const handleChange = useCallback((property, value) => {
        if (isPageLoading) {
            return false;
        }
        if (datasourceId && (connectionConfig[property] !== value)) {
            setIsOnUpdate(true);
        }
        connectionConfig[property] = value;
        if (property === 'authType' && value === appConstants.sqlAuthTypes[0]) {
            connectionConfig.username = '';
            connectionConfig.password = '';
        }
        setConnectionConfig({ ...connectionConfig });
        setHasChanges(true);
    }, [connectionConfig, datasourceId, isPageLoading]);

    /**
     * Removes the selected file
     * @param {Object} dataset - This file object
     */
    const removeDataset = useCallback((dataset) => {
        let tables = [...datasetList];
        const index = tables.findIndex((p) => p.dataset_id === dataset.dataset_id);
        if (index > -1) {
            if (connectionConfig && connectionConfig.mode === 'datasets' && dataset.dataset_id) {
                setHasChanges(true);
                dispatch(deleteDataSet({ datasets: [dataset.dataset_id] })).then((response) => {
                    if (response) {
                        dataset['dataset_id'] = null;
                        dataset.isSelected = false;
                        tables.splice(index, 1, { ...dataset });
                        setDatasetList([...tables]);
                        setIsAllSelected(false);
                        setDatasets([...tables]);
                    }
                });
                setSelectedDataSet(null);
            } else {
                if (dataset.dataset_id) {
                    setHasChanges(true);
                    dispatch(deleteDataSet({ datasets: [dataset.dataset_id] })).then((response) => {
                        if (response) {
                            dataset['dataset_id'] = null;
                            dataset.isSelected = true;
                            tables.splice(index, 1);
                            if (tables && tables.length <= 0) {
                                tables = [{ ...appConstants.defaultQuerySet }];
                            }
                            setDatasetList([...tables]);
                        }
                    });
                    setSelectedDataSet(null);
                } else {
                    dataset['dataset_id'] = null;
                    tables.splice(index, 1);
                    if (tables && tables.length <= 0) {
                        tables = [{ ...appConstants.defaultQuerySet }];
                    }
                    setDatasetList([...tables]);
                    setSelectedDataSet(null);
                    setDatasets([...tables]);
                }

            }
        }
    }, [connectionConfig, datasetList, dispatch]);

    const onScroll = useCallback((event) => {
        if (event.target.scrollTop + event.target.offsetHeight + 55 >= event.target.scrollHeight) {
            let nextItems = datasets;
            if (searchKey) {
                nextItems = datasets.filter((p) => (p.name.toLowerCase().includes(searchKey.toLowerCase()))
                    || (p.attributes && p.attributes.filter((attribute) => attribute.name.toLowerCase().includes(searchKey.toLowerCase())).length > 0)
                );
            }
            nextItems = nextItems.slice(datasetList.length, datasetList.length + defaultCount);
            setDatasetList([...datasetList, ...nextItems]);
        }
    }, [datasetList, datasets, defaultCount, searchKey]);


    const deleteSelectedDataset = useCallback((event, dataset) => {
        event.stopPropagation();
        if (!dataset.dataset_id) {
            removeDataset(dataset);
            return;
        }
        // setSelectedDataSet(dataset);
        setOnUpdate(true);
    }, [removeDataset]);


    const onSelectionChange = useCallback((selectedDataset) => {
        let index = -1;
        const datasetIndex = datasetList.indexOf(selectedDataset);
        for (const dataset of datasetList) {
            index += 1;
            if (dataset.name.toLowerCase() !== selectedDataset.name.toLowerCase()) {
                continue;
            }
            if (datasetIndex >= 0 && index !== datasetIndex) {
                continue;
            }
            dataset.isSelected = !dataset.isSelected;
            const hasSelectedAttributes = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length > 0;
            if (dataset.isSelected && dataset.attributes && !hasSelectedAttributes) {
                for (const attribute of dataset.attributes) {
                    attribute.isSelected = true;
                }
                dataset.isAllAttributesSelected = true;
            }

            if (!dataset.isSelected && dataset.attributes) {
                for (const attribute of dataset.attributes) {
                    attribute.isSelected = false;
                }
                dataset.isAllAttributesSelected = false;
            }
        }
        const selectedDatasets = datasetList.filter((p) => p.isSelected).map((p) => p.name);
        setSelectedDatasets(selectedDatasets);
        setIsAllSelected(datasetList.filter((p) => p.isSelected).length === datasetList.length);
        setDatasets([...datasetList]);
    }, [datasetList]);

    const onUpdateDataset = useCallback((dataset, property, value) => {
        const tables = [...datasetList];
        const index = tables.indexOf(dataset);
        if (index > -1) {
            dataset[property] = value;
            if (property === 'load_type' && dataset.incremental_columns && dataset.incremental_columns.length > 0) {
                dataset['watermark_column'] = (value === appConstants.loadType[1]) ? dataset.incremental_columns[0] : '';
            }
            if (property === 'load_type' && dataset.load_type === null) {
                setOnUpdate(true);
                dataset['load_type'] = "Full Load";
            } else if (property === 'load_type') {
                setOnUpdate(true);
                dataset['load_type'] = value;
            }
            if (property === 'con_type') {
                setOnUpdate(true);
                dataset['con_type'] = value;
            }
            if (property === 'pull_config' && dataset.pull_config) {
                setOnUpdate(true);
                dataset['configuration_detail'] = value;
            }
            if (property === 'query_string') {
                setOnUpdate(true);
                dataset['query_string'] = value;
            } else if (property === 'name') {
                setOnUpdate(true);
                dataset.name = value;
            }

            tables.splice(index, 1, { ...dataset });
            setDatasetList([...tables]);
            setDatasets([...tables]);
        }
    }, [datasetList]);

    /**
     * Handles the select all files option
     */
    const onSelectAll = useCallback(() => {
        setIsAllSelected(!isAllSelected);
        for (const dataset of datasets) {
            dataset.isSelected = !isAllSelected;
            const hasSelectedAttributes = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length > 0;
            if (dataset.isSelected && dataset.attributes && !hasSelectedAttributes) {
                for (const attribute of dataset.attributes) {
                    attribute.isSelected = true;
                }
            }
            if (!dataset.isSelected && dataset.attributes) {
                for (const attribute of dataset.attributes) {
                    attribute.isSelected = false;
                }
            }
            dataset.isAllAttributesSelected = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length === dataset.attributes.length;
        }
        const selectedDatasets = datasetList.filter((p) => p.isSelected).map((p) => p.table_name);
        setSelectedDatasets(selectedDatasets);
        setDatasetList(datasetList);
    }, [datasetList, datasets, isAllSelected]);

    const loadDataSource = useCallback((datasource) => {
        if (datasource && datasource.config) {
            const config = {
                server: datasource.config.server ? datasource.config.server : '',
                port: datasource.config.port ? datasource.config.port : '',
                username: datasource.config.username ? datasource.config.username : '',
                password: datasource.config.password ? datasource.config.password : '',
                SID: datasource.config.SID ? datasource.config.SID : '',
                schema: datasource.config.schema ? datasource.config.schema : '',
                mode: datasource.config.mode ? datasource.config.mode : 'datasets',
                'source_id': datasource.id ? datasource.id : null,
                scan: datasource.config.scan ? datasource.config.scan : false,
                'connection_establish': datasource.connection_establish
            };
            setConnectionConfig({ ...config });
            setDatasource({ ...datasource });
        }
    }, []);

    const loadDatasets = useCallback((datasets, connectionConfig) => {
        datasets = datasets.map((elem) => {
            elem.include_organization_domain_score = (elem.include_organization_domain_score || elem.include_organization_domain_score === false) ? elem.include_organization_domain_score : true;
            return elem;
        });
        if (connectionConfig && connectionConfig.mode === 'query') {
            const selectedQueryDatasets = datasets.filter((d) => d.isSelected);
            datasets = [{ ...appConstants.defaultQuerySet }, ...selectedQueryDatasets];
            setIsAnySelected(datasets.filter((p) => p.isSelected).length > 0 && selectedQueryDatasets.length > 0);
        }
        if (datasets && datasets.length > 0 && connectionConfig && connectionConfig.mode === 'datasets') {
            setIsAllSelected(datasets.filter((p) => p.isSelected).length === datasets.length);
            setIsAnySelected(datasets.filter((p) => p.isSelected).length > 0);
        }
        setIsAllSelected(datasets.filter((p) => p.isSelected).length === datasets.length);
        setDatasets([...datasets]);
        if (connectionConfig && connectionConfig.mode === 'query') {
            setDatasetList([...datasets]);
        } else {
            setDatasetList([...datasets.splice(0, defaultCount)]);
        }
        setHasChanges(false);
    }, [defaultCount]);

    /**
     * Connectes the source with the given credentials
     */
    const connectToDatasource = useCallback(() => {
        connectionConfig['connection_type'] = datasourceType.type;
        const datasource = {
            datasourceId: datasourceId ? datasourceId : 0,
            name: appConfig.defaultDataSourceName,
            type: datasourceType.type,
            description: '',
            config: {
                ...connectionConfig
            }
        };
        setIsLoading(true);
        dispatch(connectDatasource(datasource)).then((response) => {
            if (!response) {
                setIsLoading(false);
                setIsValid(false);
                return;
            }
            connectionConfig['source_id'] = response.datasource ? response.datasource.id : null;
            connectionConfig['connection_establish'] = true;
            setConnectionConfig({ ...connectionConfig });
            setDatasource(response.datasource ? { ...response.datasource } : {});
            if (datasourceId) {
                dispatch(getConnectedDatasets(datasourceId)).then((response) => {
                    setIsLoading(false);
                    setIsValid(true);
                    if (response) {
                        loadDataSource(response.datasource);
                        loadDatasets(response.datasets ? [...response.datasets] : [], connectionConfig);
                        setIsOnUpdate(false);
                    }
                });
            } else {
                setIsLoading(false);
                setIsValid(true);
                loadDatasets(response.datasets ? [...response.datasets] : [], connectionConfig);
                setIsOnUpdate(false);
            }
        }).catch(() => {
            setIsLoading(false);
            setIsOnUpdate(true);
            setIsValid(false);
        });
    }, [connectionConfig, datasourceId, datasourceType.type, dispatch, loadDataSource, loadDatasets]);


    /**
     * Creates a new dataset
     */
    const createDatasets = useCallback(() => {
        if (!datasourceId && !datasource) {
            return;
        }
        let selectedDatasets = datasets.filter((p) => p.isSelected);
        if (connectionConfig && connectionConfig.mode === 'query') {
            selectedDatasets = selectedDatasets.filter((p) => p.name.length > 0 && p.query_string.length > 0);
        }
        for (const dataset of selectedDatasets) {
            if (dataset.query_string === '') {
                dispatch(failure(alertActions.ALERT_ERROR, 'Query string is required'));
                return;
            }
        }
        const datasetsToConnect = [];
        for (const dataset of selectedDatasets) {
            if ((dataset.load_type === "Incremental Load") && (!dataset.watermark_column)) {
                setshowAlert(true);
                return;
            }

            if (!dataset.dataset_id) {
                datasetsToConnect.push(dataset);
            } else if (dataset.dataset_id && onUpdate) {
                datasetsToConnect.push(dataset);
            }
        }
        if (datasetsToConnect.length === 0) {
            dispatch(failure(alertActions.ALERT_ERROR, 'No new datasets are found'));
            return;
        }

        let selectedDatasourceId = datasourceId;
        if (!datasourceId && datasource) {
            selectedDatasourceId = datasource.id;
        }

        const datasetConfig = {
            'datasource_id': selectedDatasourceId,
            datasets: datasetsToConnect
        };
        setIsLoading(true);
        dispatch(connectDataset(datasetConfig)).then((response) => {
            if (!response) {
                setIsLoading(false);
                return;
            }
            if (onConnect) {
                onConnect(selectedDatasourceId, true);
            }
        }).catch(() => setIsLoading(false));
    }, [connectionConfig, datasets, datasource, datasourceId, dispatch, onConnect, onUpdate]);


    useEffect(() => {
        if (copyConfig) {
            if (copyConfig.source_id) {
                delete copyConfig.source_id;
            }
            if (copyConfig.connection_establish) {
                delete copyConfig.connection_establish;
            }
            setConnectionConfig({ ...copyConfig });
            dispatch(success(alertActions.ALERT_SUCCESS, 'Datasource Duplicated Successfully'));
        }
    }, [copyConfig, dispatch]);


    useEffect(() => {
        if (!datasourceId) {
            return;
        }
        setIsPageLoading(true);
        dispatch(getConnectedDatasets(datasourceId)).then((response) => {
            setIsPageLoading(false);
            if (response) {
                loadDataSource(response.datasource);
                loadDatasets(response.datasets, response.datasource ? response.datasource.config : {});
            }
        });
    }, [datasourceId, dispatch, loadDataSource, loadDatasets]);

    const getDatasetSchema = useCallback((dataset) => {
        if (!dataset || (dataset && dataset.attributes && dataset.attributes.length > 0)) {
            return;
        }

        dataset.isLoadingAttributes = true;
        const datasetIndex = datasetList.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
        datasetList.splice(datasetIndex, 1, { ...dataset });

        // setDatasets([...datasetList]);
        setDatasetList([...datasetList]);
        const datasetConfig = {
            'connection_type': datasourceType.type,
            'table': dataset.name,
            ...connectionConfig,
            ...dataset
        };
        dispatch(getSchema(datasetConfig)).then((response) => {
            if (response && response.length > 0) {
                dataset.attributes = [...response];
                const hasSelectedAttributes = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length > 0;
                if (dataset.isSelected && dataset.attributes && !hasSelectedAttributes) {
                    for (const attribute of dataset.attributes) {
                        attribute.isSelected = true;
                    }
                    dataset.isAllAttributesSelected = true;
                }
            }
            dataset.isAllAttributesSelected = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length === dataset.attributes.length;
            dataset.isLoadingAttributes = false;
            const datasetIndex = datasetList.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
            datasetList.splice(datasetIndex, 1, { ...dataset });
            setDatasetList([...datasetList]);
        });

    }, [connectionConfig, datasetList, datasourceType.type, dispatch]);

    const addNewQuerySet = useCallback(() => {
        if (datasetList && datasetList.length > 0 && datasetList[0].name.length > 0 && datasetList[0].query_string.length > 0) {
            datasetList.splice(0, 0, { ...appConstants.defaultQuerySet });
            setDatasetList([...datasetList]);
        }
    }, [datasetList]);

    const onSelectAttribute = useCallback((dataset, attribute, attributeIndex) => {
        const attributes = [...dataset.attributes];
        attribute.isSelected = !attribute.isSelected;
        attributes.splice(attributeIndex, 1, { ...attribute });
        dataset.attributes = [...attributes];
        dataset.isAllAttributesSelected = attributes.filter((p) => p.isSelected).length === attributes.length;
        dataset.isSelected = dataset.attributes.filter((attribute) => attribute.isSelected).length === 0;

        const selectedDatasetIndex = datasetList.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
        datasetList.splice(selectedDatasetIndex, 1, JSON.parse(JSON.stringify(dataset)));
        setOnUpdate(true);
        setDatasetList([...datasetList]);
        onSelectionChange(dataset);
    }, [datasetList, onSelectionChange]);

    const onSelectAllAttribute = useCallback((dataset) => {
        dataset.isAllAttributesSelected = !dataset.isAllAttributesSelected;
        for (const attribute of dataset.attributes) {
            attribute.isSelected = dataset.isAllAttributesSelected;
        }
        dataset.isSelected = dataset.attributes.filter((attribute) => attribute.isSelected).length === 0;
        const selectedDatasetIndex = datasetList.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
        datasetList.splice(selectedDatasetIndex, 1, JSON.parse(JSON.stringify(dataset)));
        setDatasetList([...datasetList]);
        onSelectionChange(dataset);
    }, [datasetList, onSelectionChange]);

    const onCloseDatasetConfigModal = useCallback((dataset) => {
        setPullConfiguration(null);
    }, []);

    const onOpenDatasetConfigModal = useCallback((event, dataset) => {
        event.stopPropagation();
        setPullConfiguration({
            anchorElement: event.target,
            dataset,
            onCancel: onCloseDatasetConfigModal,
            onSave: onUpdateDataset
        });
    }, [onCloseDatasetConfigModal, onUpdateDataset]);

    const getDatasets = () => {
        let filterdDatasetList = datasetList;
        let otherDatasets = [];
        if (filterSelected) {
            otherDatasets = filterdDatasetList.filter((dataset) => !dataset.isSelected);
            filterdDatasetList = datasets.filter((dataset) => dataset.isSelected);
            filterdDatasetList = [...filterdDatasetList, ...otherDatasets];
        }
        return filterdDatasetList;
    };

    return (
        <ValidatorForm form="sql_config" autoComplete={"off"} onSubmit={() => connectToDatasource()} style={{ height: '100%' }}>
            <Grid container className={classes.connectcontainer} direction="row" justify="flex-start" alignItems="flex-start" style={{ height: '100%', position: 'relative' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography component="h4" variant="h4" className={classes.connectTitle}>
                                {'Configure ORACLE Connection'}
                            </Typography>
                            <Typography variant="body2" className={classes.connectdescription}>
                                {datasourceType.description}
                            </Typography>
                        </Grid>
                        {
                            datasourceId &&
                            <Grid item xs={2} className={classnames(classes.textRight, classes.duplicateDatasource)}>
                                <ToolTipComponent title="Duplicate Datasource" arrow>
                                    <IconButton onClick={() => history.push({ pathname: '/configuredatasource', state: { datasourceType: datasourceType, con_config: connectionConfig, isCopy: true } })}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={theme.palette.primary.main}>
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                                        </svg>
                                    </IconButton>
                                </ToolTipComponent>
                            </Grid>
                        }
                    </Grid>

                    <Grid container direction="row" spacing={3} style={{ marginTop: 20 }}>

                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="Server Name"
                                        name="server"
                                        validators={['required']}
                                        errorMessages={['Server Name is required']}
                                        value={connectionConfig.server}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="SID"
                                        name="SID"
                                        disabled={isAnySelected}
                                        validators={['required']}
                                        errorMessages={['SID is required']}
                                        value={connectionConfig.SID}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="Username"
                                        name="username"
                                        disabled={isAnySelected}
                                        validators={['required']}
                                        errorMessages={['Username is required']}
                                        value={connectionConfig.username}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="Password"
                                        name="password"
                                        validators={['required']}
                                        errorMessages={['password is required']}
                                        type="password"
                                        value={connectionConfig.password}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="Port"
                                        name="port"
                                        validators={['required']}
                                        errorMessages={['Port  is required']}
                                        type="number"
                                        value={connectionConfig.port}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        disabled={isAnySelected}
                                        label="Schema Name"
                                        name="schema"
                                        value={connectionConfig.schema}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container className={classes.queryOptions}>
                                <RadioGroup name="mode"
                                    value={connectionConfig && connectionConfig.mode ? connectionConfig.mode : ''}
                                    onChange={(event) => handleChange(event.target.name, event.target.value)}>
                                    {
                                        licenseConfig.query &&
                                        <FormControlLabel disabled={datasets.length > 0} value="datasets" control={<Radio />} label="Datasets" />
                                    }
                                    {
                                        licenseConfig.query &&
                                        <FormControlLabel disabled={datasets.length > 0 || !licenseConfig.pull || datasets.length > 0} value="query" control={<Radio />} label="Query" />
                                    }
                                </RadioGroup>
                            </Grid>
                        </Grid>
                        <Grid item xs={3} className={classes.checkboxContainer}>
                            {
                                licenseConfig.scan &&
                                <CheckboxComponent disabled={!licenseConfig.pull || isAnySelected || datasets.length > 0} checked={connectionConfig.scan} onClick={() => handleChange("scan", !connectionConfig.scan)} checkboxLabel="Scan" />
                            }
                        </Grid>
                    </Grid>
                </Grid>
                {
                    (isOnUpdate) &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container direction="row" justify="flex-start" style={{ marginTop: 40 }}>
                            <Grid item className={classes.btnContainer}>
                                <Button type="submit"
                                    variant="contained"
                                    disabled={isLoading}
                                    color="primary">
                                    {'Validate'}
                                    {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button className={classnames(classes.cancelButton)}
                                    style={{ marginLeft: 20 }}
                                    disabled={isLoading}
                                    onClick={() => onCancel(hasChanges)}>
                                    {'Cancel'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {
                    ((connectionConfig && connectionConfig.mode === 'datasets') ||
                    (connectionConfig && connectionConfig.mode === 'query' && connectionConfig.source_id && connectionConfig.connection_establish)) &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.searchInputContainer}>
                            <TextBox placeholder={'Search Dataset'}
                                onChange={(event) => onSearchDatasets(event.target.value)}
                                name="searchKey"
                                id="searchKey"
                                value={searchKey}
                                className={classes.inputOutline} />
                            {
                                searchKey.length === 0 ?
                                    <IconButton className={classes.includeSearchIcon}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.094" height="13.092" viewBox="0 0 13.094 13.092">
                                            <g transform="translate(0 -0.035)">
                                                <path fill="#afb2b3" d="M5.27,10.57A5.257,5.257,0,0,0,8.5,9.46l3.483,3.483a.655.655,0,0,0,.926-.926L9.427,8.534A5.267,5.267,0,1,0,5.27,10.57ZM2.471,2.5a3.958,3.958,0,1,1,0,5.6h0a3.944,3.944,0,0,1-.02-5.577l.02-.02Z" transform="translate(0 0)" />
                                            </g>
                                        </svg>
                                    </IconButton> :
                                    <IconButton className={classes.includeDeleteIcon} onClick={() => onSearchDatasets('')}>
                                        <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                            <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                            <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                        </svg>
                                    </IconButton>
                            }
                        </Grid>
                        <Grid container justify="flex-end">
                            {
                                connectionConfig && connectionConfig.mode !== 'query' &&
                                <FormControlLabel
                                    control={
                                        <CheckboxComponent
                                            className={"attributeSelectorCheck"}
                                            checked={filterSelected}
                                            onClick={onFilter}
                                            checkboxLabel={''}
                                        />
                                    }
                                    label="Show Selected"
                                />
                            }
                        </Grid>
                        <Grid container justify="flex-end">
                            {
                                connectionConfig && connectionConfig.mode === 'query' &&
                                <ToolTipComponent title="Add Query" placement="bottom" arrow>
                                    <IconButton className={classnames(classes.addIconButton, classes.iconButtonSmall)}
                                        classes={
                                            {
                                                root: classes.addIconButtonRoot
                                            }
                                        }
                                        onClick={() => addNewQuerySet()}>
                                        <AddIcon className={classes.addIcon} />
                                    </IconButton>
                                </ToolTipComponent>
                            }
                        </Grid>
                    </Grid>
                }
                <Grid container direction="column" className={classnames('datasetConfiguration', (connectionConfig && connectionConfig.mode === 'query') ? classes.connectTableContainer : null)}>
                    {
                        (connectionConfig && connectionConfig.mode === 'datasets') &&
                        <Card className={classnames(classes.tableaccordian, classes.connectTableContainer, connectionConfig.authType === appConstants.sqlAuthTypes[1] ? classes.serverView : '')} style={{ minHeight: 200 }} onScroll={onScroll}>
                            {
                                datasetList && getDatasets().map((dataset, index) =>
                                    <Accordion key={`accordian_${index}`} className={`${classes.datasetTableHeaderAccordions} ${"datasetTableHeaderAccordions"}`}>
                                        <Table stickyHeader className={classnames(classes.datasettable, classes.designTable)} aria-label="a dense table">
                                            <TableHead className={"datasetTableHeader"}>
                                                <TableRow>
                                                    <TableCell className={classes.datasetHeaderTitleCell}>
                                                        <Grid className={classes.datasetTitleSection}>
                                                            <Grid className={classes.inlineBlock}>
                                                                <CheckboxComponent
                                                                    className={"attributeSelectorCheck"}
                                                                    checked={isAllSelected}
                                                                    onClick={() => onSelectAll()}
                                                                    checkboxLabel={'Datasets'}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </TableCell>
                                                    {
                                                        appConstants && appConstants.ORACLEConnectorTableHeaders &&
                                                        appConstants.ORACLEConnectorTableHeaders.map((header, index) => {
                                                            return (
                                                                <TableCell key={`sqlConnectorTable${index}`} className={(index !== 0 && index !== 3)}>
                                                                    {header}
                                                                </TableCell>
                                                            );
                                                        })
                                                    }
                                                </TableRow>
                                            </TableHead>
                                            <TableBody className={classes.datasetTableAccordHeader}>
                                                <TableRow className={classes.tableRowClass} onClick={() => getDatasetSchema(dataset)}>
                                                    <TableCell component="td" scope="row" className={classes.paddingLeft6}>
                                                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                                                            <Grid className={classnames(classes.datasetTitleSection, classes.checkBoxTitle, classes.noPaddingLeft)}>
                                                                <CheckboxComponent checked={Boolean(dataset.isSelected)} onClick={(event) => event.stopPropagation()} onChange={() => onSelectionChange(dataset)} />
                                                                <ToolTipComponent title={dataset.name} arrow>
                                                                    <Typography className={classnames(classes.datasetName, classes.fixedTableCell)} component="span">
                                                                        {dataset.name}
                                                                    </Typography>
                                                                </ToolTipComponent>
                                                            </Grid>
                                                        </AccordionSummary>
                                                    </TableCell>
                                                    <TableCell>
                                                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                                                            <Typography className={classes.datasetTabText}>
                                                                {dataset.columns_count}
                                                            </Typography>
                                                        </AccordionSummary>
                                                    </TableCell>
                                                    <TableCell>
                                                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                                                            <Typography className={classes.datasetTabText}>
                                                                {dataset.num_rows}
                                                            </Typography>
                                                        </AccordionSummary>
                                                    </TableCell>
                                                    <TableCell className={classes.loadTypeInput}>
                                                        <TextBox
                                                            name="load_type"
                                                            value={dataset.load_type ? dataset.load_type : appConstants.loadType[0]}
                                                            disabled={!(dataset.isSelected && dataset.incremental_columns && dataset.incremental_columns.length > 0)}
                                                            select
                                                            onChange={(event) => onUpdateDataset(dataset, event.target.name, event.target.value)}
                                                            fullWidth
                                                        >
                                                            {
                                                                appConstants.loadType.map((loadType, index) => {
                                                                    return (
                                                                        <MenuItem key={`loadType_${index}`} value={loadType}>
                                                                            {loadType}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </TextBox>
                                                    </TableCell>
                                                    <TableCell className={classes.watermarkInput}>
                                                        <TextBox
                                                            name="watermark_column"
                                                            value={dataset.watermark_column ? dataset.watermark_column : ''}
                                                            select
                                                            disabled={!(dataset.isSelected && dataset.incremental_columns && dataset.incremental_columns.length > 0)}
                                                            onChange={(event) => onUpdateDataset(dataset, event.target.name, event.target.value)}
                                                            fullWidth
                                                        >
                                                            {
                                                                dataset.incremental_columns && dataset.incremental_columns.map((incrementalColumn, index) => {
                                                                    return (
                                                                        <MenuItem key={`incrementalColumn_${index}`} value={incrementalColumn}>
                                                                            {incrementalColumn}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </TextBox>
                                                    </TableCell>
                                                    <TableCell className={classes.loadTypeInput}>
                                                        <TextBox
                                                            name="con_type"
                                                            value={dataset.con_type ? dataset.con_type : appConstants.connectionType[0].value}
                                                            onClick={(event) => event.stopPropagation()}
                                                            select
                                                            disabled={!dataset.isSelected}
                                                            onChange={(event) => onUpdateDataset(dataset, event.target.name, event.target.value)}
                                                            fullWidth
                                                        >
                                                            {
                                                                appConstants.connectionType.map((conType, index) => {
                                                                    return (
                                                                        <MenuItem key={`conType${index}`} value={conType.value}>
                                                                            {conType.name}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </TextBox>
                                                    </TableCell>
                                                    {
                                                        userConfig.include_organization_domain_score &&
                                                        <TableCell align="center" onClick={(event) => event.stopPropagation()}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={dataset.include_organization_domain_score}
                                                                        onChange={(event) => { onUpdateDataset(dataset, 'include_organization_domain_score', !dataset.include_organization_domain_score); }}
                                                                        name="include_organization_domain_score"
                                                                        disabled={!dataset.isSelected}
                                                                    />
                                                                }
                                                            />
                                                        </TableCell>
                                                    }
                                                    <TableCell>
                                                        <AccordionSummary
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                            expandIcon={<ExpandMoreIcon />}>
                                                            <ToolTipComponent title="Properties" arrow>
                                                                <IconButton disabled={dataset.load_type === appConstants.loadType[1]} onClick={(event) => onOpenDatasetConfigModal(event, dataset)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.823" height="16.249" viewBox="0 0 15.823 16.249">
                                                                        <path fill={dataset.load_type !== appConstants.loadType[1] ? theme.palette.primary.main : '#00000061'} d="M22.26,9.905,20.766,8.737a5.88,5.88,0,0,0,.035-.613,5.7,5.7,0,0,0-.035-.613l1.495-1.169a.732.732,0,0,0,.177-.933L20.884,2.722a.72.72,0,0,0-.9-.323l-1.762.708a6.042,6.042,0,0,0-1.058-.614L16.9.623A.719.719,0,0,0,16.18,0H13.065a.717.717,0,0,0-.716.617L12.08,2.494a6.229,6.229,0,0,0-1.056.614L9.257,2.4a.734.734,0,0,0-.892.318L6.81,5.406a.728.728,0,0,0,.176.938L8.48,7.512a5.357,5.357,0,0,0,0,1.226L6.985,9.907a.732.732,0,0,0-.176.933l1.553,2.687a.719.719,0,0,0,.9.323l1.762-.708a6.1,6.1,0,0,0,1.058.614l.268,1.869a.718.718,0,0,0,.718.624H16.18a.718.718,0,0,0,.717-.617l.269-1.875a6.258,6.258,0,0,0,1.056-.615l1.767.71a.728.728,0,0,0,.265.05.715.715,0,0,0,.626-.367l1.56-2.7A.731.731,0,0,0,22.26,9.905Zm-7.637.928a2.708,2.708,0,1,1,2.708-2.708A2.711,2.711,0,0,1,14.622,10.833Z" transform="translate(-6.71)" />
                                                                    </svg>
                                                                </IconButton>
                                                            </ToolTipComponent>
                                                            <ToolTipComponent title="Delete" arrow>
                                                                <IconButton
                                                                    disabled={isLoading}
                                                                    onClick={(event) => deleteSelectedDataset(event, dataset)}>
                                                                    <svg version="1.1"
                                                                        id="Layer_1"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        x="0px"
                                                                        y="0px"
                                                                        width="22px"
                                                                        height="22px"
                                                                        viewBox="0 0 32 32">
                                                                        <g id="Delete">
                                                                            <path fill={theme.palette.primary.main}
                                                                                stroke={theme.palette.primary.main}
                                                                                strokeWidth="0.92"
                                                                                strokeMiterlimit="10"
                                                                                d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
		L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
		L22.3,25.8z" />
                                                                            <path fill={theme.palette.primary.main}
                                                                                stroke={theme.palette.primary.main}
                                                                                strokeWidth="0.92"
                                                                                strokeMiterlimit="10"
                                                                                d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
		c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
		c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                                        </g>
                                                                    </svg>
                                                                </IconButton>
                                                            </ToolTipComponent>
                                                        </AccordionSummary>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <AccordionDetails>
                                            {
                                                dataset.isLoadingAttributes &&
                                                <Grid container className={classes.attributeLoader}>
                                                    <Loader size="small" />
                                                </Grid>
                                            }
                                            {
                                                dataset.attributes &&
                                                <Grid container>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell className={classes.datasetHeaderTitleCell}>
                                                                    <Grid className={classes.datasetTitleSection}>
                                                                        <Grid className={classnames(classes.inlineBlock, classes.displayFlex)}>
                                                                            {!isValid && <WarningIcon />}
                                                                            <CheckboxComponent
                                                                                className={"attributeSelectorCheck"}
                                                                                checked={dataset.isAllAttributesSelected}
                                                                                onClick={() => onSelectAllAttribute(dataset)}
                                                                                checkboxLabel={'Attribute'}
                                                                                showCheckBox={isValid}
                                                                            />
                                                                        </Grid>
                                                                    </Grid>
                                                                </TableCell>
                                                                {
                                                                    appConstants.sqlConnectorAttributesTableHeaders.map((header, index) =>
                                                                        index !== 0 &&
                                                                        <TableCell key={`attribute_${index}`}>
                                                                            {header}
                                                                        </TableCell>
                                                                    )
                                                                }
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {
                                                                dataset.attributes.map((attribute, attrIndex) =>
                                                                    <TableRow className={classes.tableRowClass} key={`dataset${index}attribute${attrIndex}`}>
                                                                        <TableCell>
                                                                            <Grid className={classnames(classes.datasetTitleSection, classes.checkBoxTitle, classes.noPaddingLeft)}>
                                                                                {!isValid && <WarningIcon />}
                                                                                <CheckboxComponent checked={Boolean(attribute.isSelected)} onClick={(event) => event.stopPropagation()} onChange={() => onSelectAttribute(dataset, attribute, attrIndex)} showCheckBox={isValid} />
                                                                                <ToolTipComponent title={attribute.name} arrow>
                                                                                    <Typography className={classes.datasetName} component="span">
                                                                                        {attribute.name}
                                                                                    </Typography>
                                                                                </ToolTipComponent>
                                                                            </Grid>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            {
                                                                                attribute.business_name ? attribute.business_name :
                                                                                    <span className={classes.disabledtxt}>
                                                                                        {"NA"}
                                                                                    </span>
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <span className="description">
                                                                                {
                                                                                    attribute.description ? attribute.description :
                                                                                        <span className={classes.disabledtxt}>
                                                                                            {"NA"}
                                                                                        </span>
                                                                                }
                                                                            </span>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <Typography className={classes.typeText}>
                                                                                {attribute.datatype ? attribute.datatype : ''}
                                                                            </Typography>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                )
                                                            }
                                                        </TableBody>
                                                    </Table>

                                                </Grid>
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                )
                            }
                        </Card>
                    }
                    {
                        (connectionConfig && connectionConfig.mode === 'query' && connectionConfig.source_id && connectionConfig.connection_establish) &&
                        <QuerySetContainer
                            datasets={datasetList ? [...datasetList] : []}
                            onUpdateDataset={onUpdateDataset}
                            deleteSelectedDataset={deleteSelectedDataset}
                        />
                    }
                </Grid>
                {
                    (datasetList.length > 0 && !isOnUpdate) &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container direction="row" justify="flex-end">
                            <Grid item className={classes.btnContainer}>
                                <Button variant="contained"
                                    disabled={isLoading}
                                    color="primary"
                                    onClick={() => createDatasets()}
                                    className={classnames(classes.actionButtons)}>
                                    {'Connect'}
                                    {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button className={classnames(classes.cancelButton, classes.actionButtons)}
                                    disabled={isLoading}
                                    onClick={() => onCancel(hasChanges)}>
                                    {'Cancel'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                <AlertDialog title="Delete Dataset"
                    message={`Are you sure you want to delete the selected dataset ${selectedDataSet ? selectedDataSet.name : ''}?`}
                    okButtonText="OK"
                    cancelButtonText="Cancel"
                    show={Boolean(selectedDataSet)}
                    onClickOK={() => removeDataset(selectedDataSet)}
                    onClickCancel={() => setSelectedDataSet(null)} />
                {isPageLoading && <Loader />}
                <AlertDialog title="Alert"
                    message={`Need to select fingerprint value`}
                    okButtonText="OK"
                    cancelButtonText="Cancel"
                    show={Boolean(showAlert)}
                    onClickOK={() => setshowAlert(false)}
                    onClickCancel={() => setshowAlert(false)} />
                {isPageLoading && <Loader />}
                <PullConfiguration
                    open={Boolean(pullConfiguration)}
                    anchorEl={pullConfiguration && pullConfiguration.anchorElement ? pullConfiguration.anchorElement : null}
                    dataset={pullConfiguration && pullConfiguration.dataset ? pullConfiguration.dataset : null}
                    onSave={pullConfiguration && pullConfiguration.onSave ? pullConfiguration.onSave : null}
                    onCancel={pullConfiguration && pullConfiguration.onCancel ? pullConfiguration.onCancel : null}
                />
            </Grid>
        </ValidatorForm >
    );

};

ORACLEConnector.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    datasourceType: PropTypes.object,
    datasourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onConnect: PropTypes.func,
    onCancel: PropTypes.func,
    history: PropTypes.object,
    copyConfig: PropTypes.object
};

export default withStyles((theme) => ({
    ...ConnectorStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ORACLEConnector);