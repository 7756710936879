import React, { useCallback } from 'react';
import { Grid, withStyles, Typography, Button, IconButton } from '@material-ui/core';
// import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DatasourceListStyles from '../DatasourceList/DatasourceListStyles.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import Circular from '../Charts/Circular.jsx';
import Styles from '../../layouts/Styles.jsx';
import Logo from '../../assets/images/dqicon_placeholder.svg';
import DatasourceStyles from '../Datasource/DatasourceStyles.jsx';
import ViewIcon from '../../assets/images/eye3.svg';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import Search from '../TextBox/Search.jsx';
import { useDispatch } from 'react-redux';
import { pushTransformedDataset } from '../../actions/dataPreparationActions';
// import { toggleSidePanel } from '../../actions/notificationActions';

const DataPrparationInfo = (props) => {
    const { classes, dataPreparation, trasformDatasetId, breadcrumb, infoAction, redirect, loading, onChange, onSave, theme, isPreview, onChangeSearch, search, openTransformationDialog } = props;
    const dispatch = useDispatch();

    const pushTransformedData = useCallback(() => {
        const transformParams = {
            'transformed_dataset_id': trasformDatasetId
        };
        dispatch(pushTransformedDataset(transformParams));
    }, [dispatch, trasformDatasetId]);

    const showPanel = useCallback((type) => {
        // dispatch(toggleSidePanel({ type: type, sidePanel: true }));
    }, []);

    return (
        <Grid>
            <Grid container className={classNames(classes.marginBottom5, classes.domainInfoContainer)}>
                {
                    breadcrumb && breadcrumb.map((crumb, index) =>
                        <Typography variant="body2" key={`crumb_${index}`} component="span" className={classes.link} onClick={() => (crumb.path !== "" ? redirect(crumb) : null)}>
                            {crumb.name}
                            {index !== breadcrumb.length - 1 && ' | '}
                        </Typography>
                    )
                }
            </Grid>
            <Grid container justify="space-between">
                <Grid item className={classes.datasourceTitle}>
                    <Grid className={classes.defaultIconContainer}>
                        <img src={Logo} alt="logo" className={classNames(classes.datasourceIcon, classes.datasourceIconSelectImg)} />
                    </Grid>
                    <TextBox
                        name="name"
                        placeholder="Untitled Data Preparation"
                        value={dataPreparation.name ? dataPreparation.name : ''}
                        className={classNames(classes.inlinetxt, classes.headerTitleTxt)}
                        onChange={(event) => onChange(event.target.name, event.target.value)}
                        onBlur={() => onSave('name')}
                        error={!loading && dataPreparation.name === ""}
                        helperText={!loading && dataPreparation.name === "" ? "Please enter the name" : ""}
                        disabled={dataPreparation.is_disabled}
                    />
                    <span>
                        <Circular
                            showPercentage={Boolean(dataPreparation.dqscore && dataPreparation.dqscore !== 0)}
                            showValue
                            value={dataPreparation.dqscore}
                            size={40} />
                    </span>
                </Grid>
                <Grid item align="right" style={{ display: 'flex' }}>
                    {
                        isPreview &&
                        <React.Fragment>
                            <Grid style={{ marginRight: 7 }}>
                                <Search
                                    value={search}
                                    placeholder="Search Here"
                                    className={classes.dqSearchBox}
                                    onChange={(value) => onChangeSearch(value)}
                                />
                            </Grid>
                            <TooltipComponent title={"Transformations"} arrow>
                                <IconButton className={classes.padding8} onClick={(event) => openTransformationDialog(event)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23.98" viewBox="0 0 24 23.98">
                                        <g id="workflow" transform="translate(0 -0.054)">
                                            <path id="Path_2345" data-name="Path 2345" d="M5.25,20H1.5A1.5,1.5,0,0,0,0,21.5v3.75a1.5,1.5,0,0,0,1.5,1.5H5.25a1.5,1.5,0,0,0,1.5-1.5V21.5A1.5,1.5,0,0,0,5.25,20ZM1.5,25.25V21.5H5.25v3.75Z" transform="translate(0 -12.466)" fill={theme.palette.primary.main} />
                                            <path id="Path_2346" data-name="Path 2346" d="M29.75,25.25V21.5a1.5,1.5,0,0,0-1.5-1.5H24.5A1.5,1.5,0,0,0,23,21.5v3.75a1.5,1.5,0,0,0,1.5,1.5h3.75A1.5,1.5,0,0,0,29.75,25.25ZM24.5,21.5h3.75v3.75H24.5Z" transform="translate(-14.375 -12.466)" fill={theme.palette.primary.main} />
                                            <path id="Path_2347" data-name="Path 2347" d="M51.25,20H47.5A1.5,1.5,0,0,0,46,21.5v3.75a1.5,1.5,0,0,0,1.5,1.5h3.75a1.5,1.5,0,0,0,1.5-1.5V21.5A1.5,1.5,0,0,0,51.25,20ZM47.5,25.25V21.5h3.75v3.75Z" transform="translate(-28.75 -12.466)" fill={theme.palette.primary.main} />
                                            <path id="Path_2348" data-name="Path 2348" d="M15.749,3.945a.788.788,0,0,0-1.17.255A.75.75,0,0,0,14.916,5.2l1.5.75a.75.75,0,0,0,.934-.221l1.125-1.5a.75.75,0,0,0-.15-1.05.784.784,0,0,0-1.155.289A5.25,5.25,0,0,0,7,5.284a.75.75,0,1,0,1.5,0,3.75,3.75,0,0,1,7.249-1.339Z" transform="translate(-4.375 0)" fill={theme.palette.primary.main} />
                                            <path id="Path_2349" data-name="Path 2349" d="M40.35,42.3a.75.75,0,0,0-.934-.221l-1.5.75a.75.75,0,0,0-.338,1.005.788.788,0,0,0,1.17.255A3.75,3.75,0,0,1,31.5,42.75a.75.75,0,0,0-1.5,0,5.25,5.25,0,0,0,10.17,1.811.784.784,0,0,0,1.155.289.75.75,0,0,0,.15-1.05Z" transform="translate(-18.75 -26.216)" fill={theme.palette.primary.main} />
                                            <path id="Path_2350" data-name="Path 2350" d="M50.414,9.529a.75.75,0,0,0,.979.071l3-2.25a.75.75,0,1,0-.9-1.2L51.014,8.025l-.667-.679A.75.75,0,0,0,49.289,8.4Z" transform="translate(-30.693 -3.716)" fill={theme.palette.primary.main} />
                                            <path id="Path_2351" data-name="Path 2351" d="M7.2,43.275,5.7,42.15a.75.75,0,0,0-.9,0L3.3,43.275a.75.75,0,0,0-.15,1.05c.364.5.975.3,1.35-.075V46.5a.75.75,0,1,0,1.5,0V44.25c.375.375.986.57,1.35.075a.75.75,0,0,0-.15-1.05Z" transform="translate(-1.875 -26.216)" fill={theme.palette.primary.main} />
                                            <circle id="Ellipse_1857" data-name="Ellipse 1857" cx="0.75" cy="0.75" r="0.75" transform="translate(2.625 22.534)" fill="#f38080" />
                                        </g>
                                    </svg>
                                </IconButton>
                            </TooltipComponent>
                            <TooltipComponent title={"Store Data"} arrow>
                                <IconButton className={classes.padding8} onClick={() => pushTransformedData()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="28.875" height="22" viewBox="0 0 28.875 22">
                                        <path id="cloud-data" d="M25.411,14.375a6.32,6.32,0,0,0-8.23-5.667V3.841c0-1.174-1-2.206-2.809-2.907A16.568,16.568,0,0,0,8.59,0,16.568,16.568,0,0,0,2.809.934C1,1.634,0,2.667,0,3.841V14.669c0,1.174,1,2.206,2.809,2.907a15.9,15.9,0,0,0,5.031.92A4.424,4.424,0,0,0,12.161,22H25.054a3.821,3.821,0,0,0,.357-7.625ZM8.59,1.691c4.464,0,6.9,1.42,6.9,2.149S13.054,5.99,8.59,5.99s-6.9-1.42-6.9-2.149,2.435-2.149,6.9-2.149Zm-6.9,4.523a7.573,7.573,0,0,0,1.117.533,16.568,16.568,0,0,0,5.781.934,16.568,16.568,0,0,0,5.781-.934,7.584,7.584,0,0,0,1.117-.533V9.255a.8.8,0,0,1-.307.521q-.106.084-.208.173c-.933.694-3.114,1.456-6.383,1.456-4.464,0-6.9-1.42-6.9-2.149ZM7.814,16.8c-3.969-.152-6.123-1.448-6.123-2.134V11.628a7.551,7.551,0,0,0,1.117.534A16.568,16.568,0,0,0,8.59,13.1a18.225,18.225,0,0,0,4.586-.551,6.309,6.309,0,0,0-.211.693A4.42,4.42,0,0,0,7.814,16.8Zm17.239,3.5H12.161a2.725,2.725,0,1,1,1.135-5.2l1.111.51.086-1.22a4.624,4.624,0,0,1,9.236.329c0,.142-.007.291-.022.443l-.1,1.1,1.089-.186a2.129,2.129,0,1,1,.362,4.226Zm0,0" transform="translate(0 0)" fill={theme.palette.primary.main} />
                                    </svg>
                                </IconButton>
                            </TooltipComponent>
                            <TooltipComponent title={"Run Now"} arrow>
                                <IconButton className={classes.padding8} onClick={() => infoAction('run')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 19.003 22.001">
                                        <g transform="translate(-34.883 0)">
                                            <g transform="translate(34.883 0)">
                                                <path fill={theme.palette.primary.main} d="M52.457,8.461,39.463.452a3,3,0,0,0-4.58,2.559V18.957a3.036,3.036,0,0,0,3,3.044h.013a3.015,3.015,0,0,0,1.558-.469.858.858,0,1,0-.894-1.465,1.316,1.316,0,0,1-.668.218A1.333,1.333,0,0,1,36.6,18.957V3.01a1.289,1.289,0,0,1,1.964-1.1l12.994,8.01a1.289,1.289,0,0,1-.006,2.2l-9.395,5.752a.858.858,0,1,0,.9,1.463l9.394-5.752a3,3,0,0,0,.011-5.123Z" transform="translate(-34.883 0)" />
                                            </g>
                                        </g>
                                    </svg>
                                </IconButton>
                            </TooltipComponent>
                        </React.Fragment>
                    }
                    <TooltipComponent title={"Delete Transformation"} arrow>
                        <IconButton className={classes.padding8} onClick={() => infoAction('delete')}>
                            <svg version="1.1" id="Layer_1" width="22" height="22" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
                                <g id="Delete">
                                    <path fill={theme.palette.primary.main}
                                        stroke={theme.palette.primary.main}
                                        strokeWidth="0.92"
                                        strokeMiterlimit="10"
                                        d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                    <path fill={theme.palette.primary.main}
                                        stroke={theme.palette.primary.main}
                                        strokeWidth="0.92"
                                        strokeMiterlimit="10"
                                        d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                </g>
                            </svg>
                        </IconButton>
                    </TooltipComponent>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item className={classes.datasourceDetailIcons}>
                    <img className={classes.datasourceIcon} src={ViewIcon} alt="view" style={{ width: "22px", height: "21px" }} />
                    <Typography className={classes.datasourceDetailText} variant="body2">
                        {`${dataPreparation.views ? dataPreparation.views : 0} Views`}
                    </Typography>
                </Grid>
                <Grid item className={classNames(classes.datasourceDetailIcons, classes.cursor)} onClick={() => showPanel('actionitem')}>
                    <span className={classes.relative}>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={classes.datasourceIcon} width="23" height="20" viewBox="0 0 32 32" >
                            <g id="Actions_1_">
                                <path fill="#AFB2B3"
                                    d="M23.1,16.7v-1.6v-0.6c-0.5,0.3-1,0.5-1.6,0.5c-0.1,0-0.2,0-0.3,0v0.1v1.6c-2.9,0.4-5.2,2.9-5.2,5.9c0,0.7,0.1,1.4,0.3,2H5.9v-19h11.7l1.3,1.9c0.5-0.6,1-1.1,1.7-1.3l-2-2.8H4.1v23.5h13.8c1.1,1.1,2.6,1.8,4.2,1.8c3.3,0,6-2.7,6-6C28.1,19.7,25.9,17.2,23.1,16.7z M22.1,26.6c-2.2,0-4-1.8-4-4s1.8-4,4-4c2.2,0,4,1.8,4,4S24.3,26.6,22.1,26.6z" />
                                <g>
                                    <rect x="7.6" y="10.2" fill="#AFB2B3" width="5.5" height="2.2" />
                                    <rect x="15.3" y="10.2" fill="#AFB2B3" width="2.5" height="2.2" />
                                    <rect x="7.6" y="14" fill="#AFB2B3" width="5.5" height="2.2" />
                                    <rect x="7.6" y="17.8" fill="#AFB2B3" width="5.5" height="2.2" />
                                    <rect x="15.3" y="14" fill="#AFB2B3" width="2.5" height="2.2" />
                                    <polyline fill="none" stroke="#AFB2B3" strokeWidth="2" strokeMiterlimit="10" points="20.1,21.6 21.4,23.6 25.1,21.6 25.1,21.6 " />
                                </g>
                                <circle fill="#54DF9A" cx="21.5" cy="10.5" r="3" />
                            </g>
                        </svg>
                    </span>

                    <Typography className={classes.datasourceDetailText} variant="body2">
                        {`${dataPreparation.actionitems ? dataPreparation.actionitems : 0} Action Items`}
                    </Typography>
                </Grid>
                <Grid item className={classNames(classes.cursor, classes.datasourceDetailIcons)} onClick={() => showPanel('conversation')}>
                    <span className={classes.relative}>
                        <svg className={classes.datasourceIcon} version="1.1" id="Layer_1" width="23" height="23.421" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
                            <g id="conversation_1_">
                                <g>
                                    <path fill="#AFB2B3" d="M13.5,18.4c0.6,0,1-0.4,1-0.9c0-0.6-0.4-1-1-1s-1,0.4-1,1C12.5,18,12.9,18.4,13.5,18.4z" />
                                    <polygon fill="#AFB2B3" points="24.9,8.9 26.8,8.9 26.8,7 24.9,7 7.8,7 7.8,8.9 24,8.9 " />
                                    <path fill="#AFB2B3" d="M17.3,18.4c0.6,0,1-0.4,1-0.9c0-0.6-0.4-1-1-1s-1,0.4-1,1C16.3,18,16.7,18.4,17.3,18.4z" />
                                    <path fill="#AFB2B3" d="M9.7,18.4c0.6,0,1-0.4,1-0.9c0-0.6-0.4-1-1-1s-1,0.4-1,1C8.8,18,9.1,18.4,9.7,18.4z" />
                                    <path fill="#AFB2B3" d="M5.9,10.8H4v1.9v11.4V26l5.7-1.9h11.4H23v-1.9v-9.5v-1.9h-1.9H5.9z M21.1,22.2H9.7l-3.8,1.2V12.7h15.2V22.2z" />
                                    <g>
                                        <rect x="24.9" y="15" fill="#AFB2B3" width="1.9" height="4.3" />
                                        <rect x="24.9" y="10.8" fill="#AFB2B3" width="1.9" height="2.2" />
                                    </g>
                                    <circle cx="26" fill="#54DF9A" cy="12" r="3" />
                                </g>
                            </g>
                        </svg>

                    </span>

                    <Typography className={classes.datasourceDetailText} variant="body2">
                        {`${dataPreparation.conversations ? dataPreparation.conversations : 0} Conversations`}
                    </Typography>
                </Grid>
                <Grid item className={classes.datasourceDetailIcons}>
                    <Button className={classes.bgNone} onClick={() => onChange("favorite", !dataPreparation.favorite)}>
                        {
                            dataPreparation.favorite ?
                                <svg version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 32 32">
                                    <g id="heart_2_" transform="translate(0 -16.896)">
                                        <g id="Group_1546" transform="translate(0 16.896)">
                                            <g id="Path_249">
                                                <path fill={theme.palette.primary.main}
                                                    d="M25.6,7.3c-2.4-2.5-6.3-2.7-8.9-0.3c-0.1,0.1-0.2,0.2-0.3,0.3L16,7.8l-0.4-0.4C13.2,4.8,9.3,4.6,6.7,7
				c0,0,0,0,0,0C6.6,7.1,6.5,7.2,6.4,7.3c-2.5,2.7-2.5,6.9,0,9.6l9,9.5c0.3,0.3,0.8,0.3,1.2,0c0,0,0,0,0,0l0,0l9-9.5
				C28.1,14.2,28.1,10,25.6,7.3z M24.4,15.8L24.4,15.8L16,24.7l-8.4-8.9c-1.9-2.1-1.9-5.3,0-7.3c1.7-1.9,4.7-2,6.6-0.3c0,0,0,0,0,0
				c0.1,0.1,0.2,0.2,0.3,0.3l1,1.1c0.3,0.3,0.9,0.3,1.2,0l1-1.1c1.7-1.9,4.7-2,6.6-0.3c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3
				C26.4,10.5,26.4,13.7,24.4,15.8L24.4,15.8z" />
                                                <path fill={theme.palette.primary.main}
                                                    d="M16,26.9C16,26.9,16,26.9,16,26.9c-0.3,0-0.6-0.1-0.8-0.3l-9-9.5c-2.6-2.8-2.6-7.1,0-9.9
				c0.1-0.1,0.2-0.2,0.3-0.3c2.6-2.4,6.8-2.3,9.2,0.3L16,7.4l0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c1.3-1.2,2.9-1.8,4.7-1.7
				c1.7,0.1,3.3,0.8,4.5,2.1l0,0c2.6,2.8,2.6,7.1,0,9.9l-9.1,9.6C16.5,26.8,16.3,26.9,16,26.9z M11,5.5c-1.5,0-3,0.5-4.1,1.6
				C6.8,7.3,6.6,7.4,6.5,7.5c-2.4,2.6-2.4,6.7,0,9.3l9,9.5c0.2,0.2,0.6,0.2,0.8,0l9-9.5c2.4-2.6,2.4-6.7,0-9.3
				c-1.1-1.2-2.6-1.9-4.2-1.9c-1.6-0.1-3.2,0.5-4.3,1.6c-0.1,0.1-0.2,0.2-0.3,0.3L16,8.1l-0.6-0.6C14.2,6.2,12.6,5.5,11,5.5z M16,25
				l-8.6-9.1c-2-2.1-2-5.5,0-7.6c0.9-1,2.1-1.5,3.4-1.6c1.3-0.1,2.5,0.4,3.5,1.3c0.1,0.1,0.2,0.2,0.3,0.3l1,1.1
				c0.2,0.2,0.6,0.2,0.9,0l1-1.1c0.9-1,2.1-1.5,3.4-1.6c1.3-0.1,2.5,0.4,3.5,1.3c0.1,0.1,0.2,0.2,0.3,0.3c1.9,2,2,5.1,0.3,7.3h0
				l-0.4,0.4L16,25z M11,7.2c-0.1,0-0.1,0-0.2,0c-1.2,0-2.3,0.6-3.1,1.4c-1.8,2-1.8,5.1,0,7l8.3,8.7l8.3-8.7c1.8-2,1.8-5.1,0-7
				c-0.1-0.1-0.2-0.2-0.3-0.3c-0.9-0.8-2-1.2-3.2-1.2c-1.2,0-2.3,0.6-3.1,1.4l-1,1.1c-0.4,0.4-1.1,0.4-1.5,0l-1-1.1
				c-0.1-0.1-0.2-0.2-0.3-0.3C13.2,7.6,12.1,7.2,11,7.2z" />
                                                <polygon fill={theme.palette.primary.main}
                                                    points="16,25.9 10.1,19.7 6.4,15.5 5.2,12.9 5.2,10.4 6.7,7.9 9.2,6.4 11.3,6.4 12.6,6.7 13.8,7 15.5,8.3
				16.4,8.6 17.8,7 20.3,6.1 22.8,6.1 24.6,7.4 25.9,9.4 26.7,11.3 26.7,13.2 25.6,15.8 18.8,23.1 " />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                :
                                <svg version="1.1"
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 32 32">
                                    <g id="heart_2_" transform="translate(0 -16.896)">
                                        <g id="Group_1546" transform="translate(0 16.896)">
                                            <g id="Path_249">
                                                <path fill="#AFB2B3"
                                                    d="M25.6,7.3c-2.4-2.5-6.3-2.7-8.9-0.3c-0.1,0.1-0.2,0.2-0.3,0.3L16,7.8l-0.4-0.4C13.2,4.8,9.3,4.6,6.7,7
				c0,0,0,0,0,0C6.6,7.1,6.5,7.2,6.4,7.3c-2.5,2.7-2.5,6.9,0,9.6l9,9.5c0.3,0.3,0.8,0.3,1.2,0c0,0,0,0,0,0l0,0l9-9.5
				C28.1,14.2,28.1,10,25.6,7.3z M24.4,15.8L24.4,15.8L16,24.7l-8.4-8.9c-1.9-2.1-1.9-5.3,0-7.3c1.7-1.9,4.7-2,6.6-0.3c0,0,0,0,0,0
				c0.1,0.1,0.2,0.2,0.3,0.3l1,1.1c0.3,0.3,0.9,0.3,1.2,0l1-1.1c1.7-1.9,4.7-2,6.6-0.3c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3
				C26.4,10.5,26.4,13.7,24.4,15.8L24.4,15.8z" />
                                                <path fill="#AFB2B3"
                                                    d="M16,26.9C16,26.9,16,26.9,16,26.9c-0.3,0-0.6-0.1-0.8-0.3l-9-9.5c-2.6-2.8-2.6-7.1,0-9.9
				c0.1-0.1,0.2-0.2,0.3-0.3c2.6-2.4,6.8-2.3,9.2,0.3L16,7.4l0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c1.3-1.2,2.9-1.8,4.7-1.7
				c1.7,0.1,3.3,0.8,4.5,2.1l0,0c2.6,2.8,2.6,7.1,0,9.9l-9.1,9.6C16.5,26.8,16.3,26.9,16,26.9z M11,5.5c-1.5,0-3,0.5-4.1,1.6
				C6.8,7.3,6.6,7.4,6.5,7.5c-2.4,2.6-2.4,6.7,0,9.3l9,9.5c0.2,0.2,0.6,0.2,0.8,0l9-9.5c2.4-2.6,2.4-6.7,0-9.3
				c-1.1-1.2-2.6-1.9-4.2-1.9c-1.6-0.1-3.2,0.5-4.3,1.6c-0.1,0.1-0.2,0.2-0.3,0.3L16,8.1l-0.6-0.6C14.2,6.2,12.6,5.5,11,5.5z M16,25
				l-8.6-9.1c-2-2.1-2-5.5,0-7.6c0.9-1,2.1-1.5,3.4-1.6c1.3-0.1,2.5,0.4,3.5,1.3c0.1,0.1,0.2,0.2,0.3,0.3l1,1.1
				c0.2,0.2,0.6,0.2,0.9,0l1-1.1c0.9-1,2.1-1.5,3.4-1.6c1.3-0.1,2.5,0.4,3.5,1.3c0.1,0.1,0.2,0.2,0.3,0.3c1.9,2,2,5.1,0.3,7.3h0
				l-0.4,0.4L16,25z M11,7.2c-0.1,0-0.1,0-0.2,0c-1.2,0-2.3,0.6-3.1,1.4c-1.8,2-1.8,5.1,0,7l8.3,8.7l8.3-8.7c1.8-2,1.8-5.1,0-7
				c-0.1-0.1-0.2-0.2-0.3-0.3c-0.9-0.8-2-1.2-3.2-1.2c-1.2,0-2.3,0.6-3.1,1.4l-1,1.1c-0.4,0.4-1.1,0.4-1.5,0l-1-1.1
				c-0.1-0.1-0.2-0.2-0.3-0.3C13.2,7.6,12.1,7.2,11,7.2z" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                        }
                        <Typography className={classes.datasourceDetailText} variant="body2">
                            {dataPreparation.favorite ? 'Added to Favorites' : 'Add to Favorites'}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

DataPrparationInfo.propTypes = {
    classes: PropTypes.object,
    dataPreparation: PropTypes.object,
    breadcrumb: PropTypes.array,
    redirect: PropTypes.func,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    theme: PropTypes.object,
    infoAction: PropTypes.func,
    isPreview: PropTypes.bool,
    search: PropTypes.string,
    trasformDatasetId: PropTypes.number,
    onChangeSearch: PropTypes.func,
    openTransformationDialog: PropTypes.func
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...DatasourceStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DataPrparationInfo);