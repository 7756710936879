import React, { useState, useEffect, useCallback } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';

import NotificationMetrics from '../../components/Notification/NotificationMetrics.jsx';
import NoficationFilter from '../../components/Notification/NotificationFilter.jsx';
import NotificationGridView from '../../components/Notification/NotificationGridView.jsx';
import { getNotification, readNotification, getNotificationMetricAnalysis, setFilters, getUserBasedActivity } from '../../actions/notificationActions';
import { updateViews } from '../../actions/datasourceActions';
import Styles from '../../layouts/Styles.jsx';
import { setCurrentPage } from '../../helpers/apiHelpers.js';

const Notification = (props) => {
    const { history, classes, getPageTitle } = props;
    const dispatch = useDispatch();

    const fetchLimit = 30;
    const notifications = useSelector(({ notification }) => notification.notifications);
    const notificationFilters = useSelector(({ notification }) => notification.notificationFilters);
    const [isLoading, setLoading] = useState(true);
    const [isLoadingcontent, setisLoadingcontent] = useState(false);
    const [metricAnalysis, setMetricAnalysis] = useState({});
    const [userAudit, setUserAudit] = useState([]);
    const [currentnotification, setcurrentnotification] = useState('');
    const [currentdayfilter, setcurrentdayfilter] = useState('All');
    const [recordFetch, setRecordFetch] = useState(false);


    const onChangeFilter = (filters, signal) => {
        getUserNotification(filters, true, signal, notifications.length);
    };

    const onChangeMetricFilter = (value) => {
        const filters = { ...notificationFilters };
        filters.notification = value;
        dispatch(setFilters({ ...filters }));
        getUserNotification(filters, true, null, notifications.length);
    };


    const processFilters = useCallback((notificationParams) => {
        let notification_type = 'all';
        switch (notificationParams.notification) {
            case 'Drift':
                notification_type = 'drift';
                break;
            case 'Permissioning':
                notification_type = 'permissioning';
                break;
            case 'Schedule':
                notification_type = 'schedule';
                break;
            case 'Audit Log':
                notification_type = 'audit';
                break;
            case 'ActionItem':
                notification_type = 'actionitem';
                break;
            case 'UserActivity':
                notification_type = 'useractivity';
                break;
            case 'Rule':
                notification_type = 'rule';
                break;
            default:
                notification_type = 'all';
                break;
        }
        notificationParams['notification_type'] = notification_type;
        setcurrentnotification(notification_type);
        notificationParams.users = notificationParams.users ? notificationParams.users.filter((data) => !data.isDefault).map((data) => data.id) : [];
        notificationParams.datasources = notificationParams.datasources ? notificationParams.datasources.filter((data) => !data.isDefault).map((data) => data.id) : [];
        notificationParams.datasets = notificationParams.datasets ? notificationParams.datasets.filter((data) => !data.isDefault).map((data) => data.id) : [];
        return notificationParams;
    }, []);

    const getMetricAnalysis = useCallback((params) => {
        dispatch(getNotificationMetricAnalysis(params)).then((response) => {
            if (response) {
                setMetricAnalysis({ ...response });
            }
        });
    }, [dispatch]);

    const getUserActivity = useCallback((userid) => {
        const notificationParams = {
            'user_id': userid,
            'filter': currentdayfilter
        };
        setisLoadingcontent(true);
        dispatch(getUserBasedActivity(notificationParams)).then((response) => {
            if (response) {
                setUserAudit(response);
            }
            setisLoadingcontent(false);
        });
    }, [currentdayfilter, dispatch]);

    const getUserNotification = useCallback((filters, isFilterChanged = false, signal = null, offsetCount = 0) => {
        if (isFilterChanged) {
            setLoading(true);
        }
        const filterList = filters ? filters : {
            users: [
                {
                    isDefault: true,
                    isSelected: true,
                    isUser: false,
                    name: 'All'
                }
            ],
            datasources: [{ name: 'All', isDefault: true }],
            datasets: [{ name: 'All', isDefault: true }],
            attributes: [{ name: 'All', isDefault: true }],
            types: "All",
            filter: "Last 3 Days",
            notification: "All",
            search: ""
        };
        let notificationParams = {
            ...filterList,
            offset: !isFilterChanged ? offsetCount : 0,
            limit: fetchLimit
        };
        setcurrentdayfilter(notificationParams.filter);
        notificationParams = processFilters(JSON.parse(JSON.stringify(notificationParams)));
        getMetricAnalysis(notificationParams);
        dispatch(getNotification(notificationParams, isFilterChanged, { signal: signal })).then((response) => {
            if (response) {
                setLoading(false);
            }
        });
    }, [dispatch, getMetricAnalysis, processFilters]);

    const updateReadNoitification = useCallback(() => {
        dispatch(readNotification());
    }, [dispatch]);

    useEffect(() => {
        if (!metricAnalysis || (metricAnalysis && Object.keys(metricAnalysis).length === 0)) {
            setCurrentPage('notification');
            updateReadNoitification();
        }

        if (!recordFetch) {
            setRecordFetch(true);
            getUserNotification(notificationFilters, true);
        }
    }, [getMetricAnalysis, getUserNotification, metricAnalysis, notificationFilters, recordFetch, updateReadNoitification]);

    useEffect(() => {
        getPageTitle('Notification');
    });

    /*
     * const onChangeNotification = (id, property, value) => {
     *     dispatch(updateNotificationProperty({ id: id, property: property, value: value }));
     * };
     */

    /*
     * const notificationAction = (notification, type) => {
     *     const requestParams = {
     *         "source_id": notification.source_id,
     *         "shareUserList": [
     *             {
     *                 "user_id": notification.created_by_id,
     *                 "is_stewarduser": false,
     *                 "type": "datasource",
     *                 "access_level": notification.notification_text,
     *                 "source_id": notification.source_id,
     *                 "is_request_accepted": type === "accept",
     *                 "is_decline": type === "decline",
     *                 "is_requested": true,
     *                 "is_owner": false
     *             }
     *         ]
     *     };
     *     const notificationParams = {
     *         "source_id": notification.source_id,
     *         "created_by_id": notification.created_by_id,
     *         "notification_type": notification.notification_type,
     *         "notification_text_type": notification.notification_text_type
     *     };
     *     dispatch(createSourceShare(requestParams));
     *     dispatch(updateNotification(notificationParams));
     *     onChangeNotification(notification.id, 'is_action_perform', false);
     */

    // };

    const editData = useCallback((type, notification) => {
        const model = {
            'source_id': null,
            'dataset_id': null
        };
        if (type === "master") {
            history.push({
                pathname: `/semanticmodel/${notification.master_id}`, state: {
                    model: {
                        name: notification && notification.master__name ? notification.master__name : '',
                        id: notification && notification.master_id ? notification.master_id : ''
                    }
                }
            });
        } else if (type === "model") {
            history.push({
                pathname: `/model/${notification.semantic_id}`, state: {
                    model: {
                        name: notification && notification.semantic__name ? notification.semantic__name : '',
                        id: notification && notification.semantic_id ? notification.semantic_id : ''
                    }
                }
            });
        }
        else if (type === "datasource") {
            model['source_id'] = notification.source_id;
            history.push(`/catalog/${notification.source_id}`);
        } else if (type === "attribute") {
            model['source_id'] = notification.source_id;
            model['dataset_id'] = notification.dataset_id;
            const attribute = notification.attribute_id ? notification.attribute__name : "";
            history.push({ pathname: `/dataset/${notification.dataset_id}`, state: { datasource: {}, datasourceId: notification.source_id, isdrift: false, attribute: attribute } });
        } else if (type === "rule") {
            model['source_id'] = notification.source_id;
            model['dataset_id'] = notification.dataset_id;
            const attribute = notification.attribute_id ? notification.attribute__name : "";
            history.push({ pathname: `/dataset/${notification.dataset_id}`, state: { datasource: {}, datasourceId: notification.source_id, dqscore: true, isdrift: false, attribute: attribute } });
        } else if (type === "drift") {
            model['source_id'] = notification.source_id;
            model['dataset_id'] = notification.dataset_id;
            let attribute = "";
            let ruleName = "";
            let filterRule = "";
            if (notification.notification_type === "drift") {
                const notificationText = notification.notification_text ? notification.notification_text : "";
                attribute = notificationText.split("-");
                attribute = attribute && attribute.length > 0 ? attribute[0].trim() : "";
                ruleName = notificationText.split(`${attribute} -`);
                ruleName.splice(0, 1);
                ruleName = ruleName.join("");
                ruleName = ruleName.split("exceeded");
                ruleName = ruleName && ruleName.length > 0 ? ruleName[0].trim() : "";
                filterRule = ruleName.split('-');
                filterRule = filterRule.length > 1 ? filterRule[1].trim() : "";
                ruleName = ruleName.replace(/ /g, "_");
            }
            attribute = notification.attribute_id ? notification.attribute__name : attribute;
            history.push({ pathname: `/dataset/${notification.dataset_id}`, state: { datasource: {}, datasourceId: notification.source_id, isdrift: (notification.notification_type === "drift"), attribute: attribute, ruleName: ruleName, filterRule: filterRule } });
        } else if (type === "storage") {
            history.push({ pathname: `/settings`, state: { tabIndex: 2, navigateTab: "Storage" } });
        } else if (type === "reference") {
            history.push({ pathname: `/settings`, state: { tabIndex: 4 } });
        } else if (type === "Modules") {
            history.push({ pathname: `/settings`, state: { tabIndex: 3, navigateTab: "Modules" } });
        } else if (type === "Themes") {
            history.push({ pathname: `/settings`, state: { tabIndex: 5 } });
        } else if (type === "Properties") {
            history.push({ pathname: `/settings`, state: { tabIndex: 3, navigateTab: "Properties" } });
        } else if (type === "Enrichment") {
            history.push({ pathname: `/settings`, state: { tabIndex: 6 } });
        } else if (type === "Domain") {
            history.push({ pathname: `/settings`, state: { tabIndex: 3, navigateTab: "Domain" } });
        } else if (type === "Field") {
            history.push({ pathname: `/settings`, state: { tabIndex: 2, navigateTab: "Fields" } });
        } else if (type === "Dashboard") {
            history.push(`/dashboard`);
        } else if (type === "License") {
            history.push({ pathname: `/settings`, state: { tabIndex: 2 } });
        } else {
            model['source_id'] = notification.source_id;
            model['dataset_id'] = notification.dataset_id;
            history.push({ pathname: `/dataset/${notification.dataset_id}`, state: { datasource: {}, datasourceId: notification.source_id, isdrift: notification.notification_type === "Alerts" } });
        }
        if (type !== "master") {
            dispatch(updateViews(model));
        }
    }, [dispatch, history]);

    const onScrollChange = () => {
        getUserNotification(notificationFilters, false, null, notifications.length);
    };

    return (
        <Grid>
            <ValidatorForm name="Notification" onSubmit={() => null}>
                <NoficationFilter onChangeFilterResult={(filters, signal) => onChangeFilter(filters, signal)} history={history} />
                <NotificationMetrics filters={notificationFilters} metricAnalysis={metricAnalysis} onChangeFilter={(value) => onChangeMetricFilter(value)} />
                <Grid className={classes.container}>
                    <NotificationGridView currentnotification={currentnotification} getUserBasedActivity={(userid) => getUserActivity(userid)} isLoadingcontent={isLoadingcontent} onChangeFilter={(value) => onChangeMetricFilter(value)} isPageLoading={isLoading} userAudit={userAudit} editData={(type, notification) => editData(type, notification)} onScrollChange={() => onScrollChange()} />
                </Grid>
            </ValidatorForm>
        </Grid>
    );
};

Notification.propTypes = {
    history: PropTypes.object,
    classes: PropTypes.object,
    getPageTitle: PropTypes.func
};

export default withStyles((theme) => ({
    ...Styles(theme)
}))(Notification);