import React, { useState, useEffect, useCallback } from 'react';
import { Grid, withStyles, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import Loader from '../Loaders/Loader.jsx';
import classnames from 'classnames';
import { createOrganization, getOrganizationDetail, updateOrganization } from '../../actions/settingActions';

const OrganizationDetail = (props) => {
    const { classes, organizationId, setOrganizationId } = props;
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [isPageLoading, setPageLoading] = useState(false);
    const [organization, setOrganization] = useState({});
    const [changes, setChanges] = useState(false);
    const user = useSelector(({ account }) => account.user);
    const roleId = user ? user.role : 1;

    const onChange = (name, value) => {
        setChanges(true);
        const regex = /^[0-9]*$/;
        if (name === "phone_number" && !regex.test(value)) {
            return false;
        }
        if (name === "name") {
            value = value.replaceAll(" ", "");
        }
        organization[name] = value;
        setOrganization({ ...organization });
    };

    const onSubmit = () => {
        setLoading(true);
        onUpdateOrganization(organization);

    };

    const onUpdateOrganization = (requestParams) => {
        dispatch(organizationId === 0 ? createOrganization(requestParams) : updateOrganization(requestParams, organizationId)).then((response) => {
            setLoading(false);
            if (response && organizationId === 0) {
                setOrganizationId(response);
            }
        });
    };

    const getOrganization = useCallback(() => {
        setPageLoading(true);
        dispatch(getOrganizationDetail(organizationId)).then((response) => {
            if (response) {
                setOrganization(response);
                setPageLoading(false);
            }
        });
    }, [dispatch, organizationId]);

    useEffect(() => {
        if (organizationId !== 0) {
            getOrganization();
        }
    }, [organizationId, getOrganization]);

    return (
        <ValidatorForm className={classes.container} form="My profile" onSubmit={() => onSubmit()}>
            <Grid container className={classes.organizationDetailContainer}>
                <Grid item xs={10}>
                    <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <TextBox
                                label="Organization Name"
                                name="name"
                                value={organization.name ? organization.name : ''}
                                fullWidth
                                validators={['required']}
                                errorMessages={['Organization Name is required']}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                disabled={roleId === 3}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                label="Short Name"
                                name="short_name"
                                value={organization.short_name ? organization.short_name : ''}
                                fullWidth
                                validators={['required']}
                                errorMessages={['Short Name is required']}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                disabled={roleId === 3}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                label="Email"
                                name="email"
                                type="email"
                                value={organization.email ? organization.email : ''}
                                fullWidth
                                validators={['required', 'isEmail']}
                                errorMessages={['Email is required', 'Enter valid Email Address']}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                disabled={roleId === 3}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                label="Phone Number"
                                name="phone_number"
                                value={organization.phone_number ? organization.phone_number : ''}
                                fullWidth
                                validators={['maxStringLength:10', 'minStringLength:10']}
                                errorMessages={['Phone No is required', 'Enter Valid Phone No', 'Enter Valid Phone No']}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                disabled={roleId === 3}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                label="Address"
                                name="address_line1"
                                value={organization.address_line1 ? organization.address_line1 : ''}
                                fullWidth
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                disabled={roleId === 3}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextBox
                                label="Country"
                                name="country"
                                value={organization.country ? organization.country : ''}
                                fullWidth
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                disabled={roleId === 3}
                            />
                        </Grid>
                        {
                            (roleId !== 3) &&
                            <Grid item xs={12}>
                                <Button disabled={isLoading || !changes} variant="contained" color="primary" type="submit" className={classnames(classes.actionButtons, classes.submitBtn)}>
                                    Save
                                    {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                </Button>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
            {isPageLoading && <Loader />}
        </ValidatorForm >
    );
};

OrganizationDetail.propTypes = {
    classes: PropTypes.object,
    organizationId: PropTypes.number,
    setOrganizationId: PropTypes.func
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}))(OrganizationDetail);