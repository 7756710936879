import React, { useEffect } from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import NopermissionComponentStyles from './NopermissionComponentStyles.jsx';
import layerBulb from '../../assets/images/layer_bulb.png';
import errorImage from '../../assets/images/404.png';


const NopermissionComponent = (props) => {
    const { classes, getPageTitle } = props;

    useEffect(() => {
        getPageTitle('Error');
    });

    return (
        <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.container}>
            <Grid item xs={5} className={classes.center}>
                <img src={layerBulb} alt="" />
            </Grid>
            <Grid item xs={7}>
                <img src={errorImage} alt="" />
                <Typography className={classes.text}>
                    Seems like we have a problem. Please contact your Administrator
                </Typography>
            </Grid>
        </Grid>
    );
};

NopermissionComponent.propTypes = {
    classes: PropTypes.object,
    getPageTitle: PropTypes.func
};

export default withStyles((theme) => ({
    ...NopermissionComponentStyles(theme)
}), { withTheme: true })(NopermissionComponent);