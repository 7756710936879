import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, IconButton, withStyles, Typography, TextField } from '@material-ui/core';
import Styles from '../../../layouts/Styles.jsx';
import DatasetStyles from '../DatasetStyles.jsx';
import TileViewStyles from './TileView/TileViewStyles.jsx';
import AutoComplete from './../../AutoComplete/AutoComplete.jsx';
import ToolTipComponent from '../../Tooltip/Tooltip.jsx';

const ValuesListItem = (props) => {
    const { classes, theme, data, index, count, displayKey, textOnly, onUpdate, onDelete, disabled, autoComplete, dropValues, isPatternList, isEditPermission, isDeletePermission } = props;
    // const [value, setValue] = useState(data && displayKey && data[displayKey] ? data[displayKey] : "");

    const getBackgroundColor = useCallback((valid) => {
        if (valid) {
            return theme.palette.countColors.low;
        }
        return theme.palette.countColors.high;
    }, [theme.palette.countColors.high, theme.palette.countColors.low]);

    return (
        <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap" className={classes.valuesListItem}>
            {
                !textOnly && !autoComplete && !isPatternList &&
                <Grid container className={classNames(classes.animateLeft)}>
                    <TextField
                        name={displayKey}
                        placeholder="Enter Text"
                        value={data[displayKey] ? data[displayKey] : ''}
                        className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone)}
                        onChange={(event) => onUpdate(displayKey, event.target.value, index)}
                        onBlur={(event) => onUpdate(displayKey, event.target.value, index)}
                        disabled={disabled && !isEditPermission}
                    />
                </Grid>
            }
            {
                textOnly && !isPatternList &&
                <Grid container>
                    <Typography variant="body2" className={classes.valuesListItemText} noWrap>
                        {data[displayKey]}
                    </Typography>
                </Grid>
            }
            {
                isPatternList &&
                <Grid container onClick={(event) => (!disabled || isEditPermission) && onUpdate(event, index)} className={classes.valuesListItemTextClickable}>
                    <Typography variant="body2" className={classes.valuesListItemText} noWrap>
                        {data[displayKey]}
                    </Typography>
                </Grid>
            }
            {
                autoComplete &&
                <Grid container className={classes.width100}>
                    <AutoComplete
                        placeholder="Dataset"
                        onChange={(event) => event.target.selectedItem && onUpdate(displayKey, event.target.selectedItem, index)}
                        onBlur={() => { }}
                        value={data[displayKey] ? data[displayKey] : ''}
                        noDropPostion={1}
                        className={'attribute_curate'}
                        inputStyles={'attributeCurateClass'}
                        options={dropValues}
                        selectedValuePath="name"
                        displayMemberPath="name"
                        openSuggestionsOnClick
                        name="ruleSet" />
                </Grid>
            }
            <Grid item className={classes.patternCountDelete}>
                {
                    !isNaN(count) &&
                    <ToolTipComponent title={count} arrow>
                        <Typography variant={'body2'} className={classes.countLable} style={{ backgroundColor: getBackgroundColor(data.isvalid) }}>
                            {count}
                        </Typography>
                    </ToolTipComponent>
                }
                {
                    (!disabled || isDeletePermission) &&
                    <ToolTipComponent title="Delete" arrow>
                        <IconButton onClick={() => onDelete(index)}>
                            <svg version="1.1"
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="21.408"
                                height="24"
                                viewBox="0 0 32 32">
                                <g id="Delete">
                                    <path fill={theme.palette.primary.main}
                                        stroke={theme.palette.primary.main}
                                        strokeWidth="0.92"
                                        strokeMiterlimit="10"
                                        d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
		L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
		L22.3,25.8z" />
                                    <path fill={theme.palette.primary.main}
                                        stroke={theme.palette.primary.main}
                                        strokeWidth="0.92"
                                        strokeMiterlimit="10"
                                        d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
		c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
		c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                </g>
                            </svg>

                        </IconButton>
                    </ToolTipComponent>
                }
            </Grid>
        </Grid>
    );
};


ValuesListItem.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    data: PropTypes.object,
    displayKey: PropTypes.string,
    index: PropTypes.number,
    count: PropTypes.number,
    textOnly: PropTypes.bool,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    disabled: PropTypes.bool,
    autoComplete: PropTypes.bool,
    dropValues: PropTypes.array,
    isPatternList: PropTypes.bool,
    isDeletePermission: PropTypes.bool,
    isEditPermission: PropTypes.bool
};


export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ValuesListItem);