import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button, withStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextBox from '../../TextBox/TextBox.jsx';
import Styles from '../../../layouts/Styles.jsx';
import Loader from '../../Loaders/Loader.jsx';

const DeltaLake = (props) => {
    const { classes, onSave, onChange, storage, type, isLoading } = props;
    return (
        <ValidatorForm onSubmit={() => onSave(type)} className={classes.marginTop5}>
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <TextBox
                        label="Base Path"
                        name="basepath"
                        value={storage.basepath ? storage.basepath : ""}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classes.actionButtons}>
                        {'Connect'}
                        {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                    </Button>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
};

DeltaLake.propTypes = {
    storage: PropTypes.object,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    classes: PropTypes.object,
    type: PropTypes.string,
    isLoading: PropTypes.bool
};

export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(DeltaLake);