import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, withStyles, List, ListItem, ListItemAvatar, ListItemText, Typography, Avatar } from '@material-ui/core';
import DashboardStyles from './DashboardStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import list from '../../assets/images/list.svg';
import memo from '../../assets/images/memo.svg';
import comment from '../../assets/images/comment.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getOrganizationGovernanceAnalysis } from '../../actions/scheduleActions';
// import Circular from '../Charts/Circular.jsx';
import PieCircular from '../Charts/PieCircular.jsx';
// import StackedCircular from '../Charts/StackedCircular.jsx';
import Loader from '../../components/Loaders/Loader.jsx';
import { Link } from 'react-router-dom';


const DashboardGovernance = (props) => {
    const { classes, config } = props;
    const [analysis, setAnalysis] = useState({});
    const [isLoading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const notificationFilters = useSelector(({ notification }) => notification.notificationFilters);


    const getAnalysis = useCallback(() => {
        dispatch(getOrganizationGovernanceAnalysis()).then((response) => {
            if (response) {
                setAnalysis({ ...response });
                setLoading(false);
            }
        });
    }, [dispatch]);
    useEffect(() => {
        getAnalysis();
    }, [getAnalysis]);

    const getNavigateLink = (path, title, filter) => {
        if (path !== "") {
            // dispatch(navigatePage(title));
            sessionStorage.setItem('navigationPath', title);
            if (path === "notification") {
                const filters = { ...notificationFilters };
                filters.notification = filter;
                filters.filter = "All";
                // dispatch(setFilters({ ...filters }));
                return ({ pathname: `/${path}`, state: { filters: { type: filter ? filter : "", day: "All" } } });
            }
            return ({ pathname: `/${path}`, state: { filters: filter ? filter : "" } });

        }
    };

    return (
        <Grid className={classNames(classes.section, classes.container)}>
            <Typography variant="h4" component="h4">
                Governance
            </Typography>
            <Grid container alignItems="center" justify="space-around" className={classes.relative}>
                {isLoading && <Loader />}
                <Grid item>
                    <List className={classes.governanceListContainer}>
                        {
                            config.action_items &&
                            <ListItem className={classes.governanceList}>
                                <ListItemAvatar className={classes.cursorPointer}>
                                    <Avatar>
                                        <img src={list} alt="action item" />
                                    </Avatar>
                                </ListItemAvatar>
                                <Link style={{ textDecoration: 'unset', color: 'inherit' }} to={getNavigateLink('actionitem', 'actionitem', 'Open')} >
                                    <ListItemText primary="Open Items" className={classes.cursor} />
                                </Link>
                                <Typography className={classNames(classes.governanceSummaryTxt, classes.governanceActionText, classes.cursorPointer)}>
                                    {analysis.openitem ? analysis.openitem : 0}
                                </Typography>
                            </ListItem>
                        }
                        <ListItem className={classes.governanceList}>
                            <ListItemAvatar className={classes.cursorPointer}>
                                <Avatar>
                                    <img src={memo} alt="memo" />
                                </Avatar>
                            </ListItemAvatar>
                            <Link style={{ textDecoration: 'unset', color: 'inherit' }} to={getNavigateLink('notification', 'Permissioning', 'Permissioning')} >
                                <ListItemText primary="Permissioning" className={classes.cursor} />
                            </Link>
                            <Typography className={classNames(classes.governanceSummaryTxt, classes.governanceissueText)}>
                                {analysis.permissioning ? analysis.permissioning : 0}
                            </Typography>
                        </ListItem>
                        {
                            config.conversation &&
                            <ListItem className={classes.governanceList}>
                                <ListItemAvatar className={classes.cursorPointer}>
                                    <Avatar>
                                        <img src={comment} alt="memo" />
                                    </Avatar>
                                </ListItemAvatar>
                                <Link style={{ textDecoration: 'unset', color: 'inherit' }} to={getNavigateLink('conversation', 'conversation')} >
                                    <ListItemText primary="Conversations" className={classes.cursor} />
                                </Link>
                                <Typography className={classNames(classes.governanceSummaryTxt, classes.governanceConversationText, classes.cursorPointer)}>
                                    {analysis.conversation ? analysis.conversation : 0}
                                </Typography>
                            </ListItem>
                        }
                    </List>
                </Grid>
                <Grid item>
                    <PieCircular
                        chartClassName="governance-progress"
                        width={130}
                        height={130}
                        showPercentage
                        innerRadius={50}
                        value={analysis.completed ? analysis.completed : 0} />
                    <Typography align="center">
                        Completed
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

DashboardGovernance.propTypes = {
    classes: PropTypes.object,
    config: PropTypes.object
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DashboardGovernance);