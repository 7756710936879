const MultiSelectStyles = (theme) => ({
    root: {
        flexGrow: '1 auto',
        height: '100%'
    },
    selectContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        border: theme.palette.border.default,
        padding: '1px 5px',
        // margin: '10px 0px',
        position: 'relative',
        zIndex: 2,
        transition: "2s linear",
        '& .MuiChip-root .MuiChip-avatar': {
            color: '#efefef !important'
        },
        '& .MuiChip-label': {
            maxWidth: 200
        }
    },
    paper: {
        width: "100%",
        bottom: "-30px",
        zIndex: 99999,
        position: "absolute",
        boxShadow: "0px 0px 15px #0000000F",
        maxHeight: "150px",
        overflowY: "auto",
        borderRadius: 4,
        left: 0
    },
    dropBottom: {
        top: "34px",
        bottom: "auto"
    },
    chip: {
        marginBottom: 5,
        '& .MuiChip-root .MuiChip-avatar': {
            color: '#efefef !important'
        }
    },
    input: {
        flexGrow: 1,
        flexWrap: "wrap",
        animation: 'slide-up 0.5s ease',
        width: 'auto !important',
        '&:hover': {
            backgroundColor: theme.palette.grey.exteremeLight
        }

    },
    menuItem: {
        paddingLeft: 10,
        paddingRight: 10
    },
    backdrop: {
        zIndex: -1
    }
});

export default MultiSelectStyles;