import React, { useEffect, useRef } from "react";
import { Grid, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as d3 from "d3";
import Styles from "../../layouts/Styles.jsx";
import ChartStyles from "./ChartStyles.jsx";

const StepChart = (props) => {
  const { classes, chartData, chartClassName, theme, chartWidth, chartHeight, showSnapShot, inputKey, chartType } = props;
  const chartContainerRef = useRef(null);
  const valueKey = inputKey ? inputKey : 'points';

  useEffect(() => {
    const data = chartData;
    const alertsData = data.filter((p) => p[valueKey + '_alert'] === true);
    const nonalertsData = data.filter((p) => p[valueKey + '_alert'] === false);
    const margin = {
      top: 20,
      right: 20,
      bottom: 10,
      left: 50
    };

    let width = chartWidth, height = chartHeight - margin.top - margin.bottom;
    if (chartWidth && chartHeight) {
      width = chartWidth - margin.left - margin.right;
    } else {
      if (chartContainerRef && chartContainerRef.current) {
        width = chartContainerRef.current.offsetWidth - 100;
        width = width - margin.left - margin.right;
      }
    }
    if (showSnapShot) {
      width = chartWidth;
      height = chartHeight;
    }

    const axisPoisition = height - (margin.top + margin.bottom);
    // const parseDate = d3.timeFormat("%Y-%m-%d");
    const bisectDate = d3.bisector((d) => d.start_date).left,
      formatDate = d3.timeFormat("%d %b, %y"),
      x = d3.scaleTime().range([0, width]),
      y = d3.scaleLinear().range([(showSnapShot ? height : axisPoisition), 0]),
      xAxis = d3.axisBottom(x)
        .tickFormat((d) => formatDate(d)),
      yAxis = d3.axisLeft(y)
        .ticks(6)
        .tickFormat((d) => d);
    data.forEach((d, i) => {
      d['start_date'] = new Date(d.start_date);
      d['end_date'] = new Date(d.end_date);
    });

    x.domain([
      d3.min(data, (d) => d.start_date),
      d3.max(data, (d) => d.end_date)
    ]);
    y.domain(
      [
        d3.min(data, (d) => parseFloat(d[valueKey])) - (d3.max(data, (d) => parseFloat(d[valueKey])) / 10),
        d3.max(data, (d) => parseFloat(d[valueKey])) + (d3.max(data, (d) => parseFloat(d[valueKey])) / 10)
      ]
    );

    let line = "M";
    /*
     * let fill = `M0,0`;
     * let alertline = "M", alertfill = `M0,0`;
     */
    d3.select(`.${chartClassName} > svg`).remove();

    const div = d3.select(`.${chartClassName}`).append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("zIndex", 1);

    let svg = null;
    if (showSnapShot) {
      svg = d3
        .select(`.${chartClassName}`)
        .append("svg")
        .attr("height", `${height}`)
        .attr("viewBox", `0 0 ${width} ${height}`)
        .attr("preserveAspectRatio", 'none')
        .style("width", '100%');

    } else {
      svg = d3
        .select(`.${chartClassName}`)
        .append("svg")
        .attr("height", `${chartHeight}`)
        .attr("viewBox", `0 0 ${width} ${height}`)
        .attr("preserveAspectRatio", 'xMidYMid meet')
        .style("width", '100%')
        .append("g")
        .attr("transform", `translate(0,10)`);
    }


    if (chartType !== "Value") {

      data.forEach((d, i) => {
        const y0 = y(d[valueKey]),
          x0 = x(d.end_date);
        if (i === 0) {
          line += `${x(d.start_date)},${y0}H${x0}`;
        } else {
          line += `H${x0}`;
        }

        // fill += `V${y0}H${x0}`;

        if (data[i + 1]) {
          line += `V${y(data[i + 1][valueKey])}`;
        }
      });
      // fill += `V${height}Z`;
    }
    const avg = d3.mean(data, (d) => d[valueKey]);


    /*
     * alertsData.forEach((d, i) => {
     *   let alertline = "M";
     *   const y0 = y(d[valueKey]),
     *     x0 = x(d.end_date);
     *     alertline += `${x(d.start_date)},${y0}H${x0}`;
     */

    /*
     * if (alertsData[i + 1]) {
     *   alertline += `V${y(alertsData[i + 1][valueKey])}`;
     * }
     */

    /*
     *   if (!showSnapShot) {
     *     svg.append("path")
     *     .attr("class", "alertline")
     *     .attr("d", alertline)
     *     .attr('stroke', "red")
     *     .attr("stroke-width", 2)
     *     .attr('fill', 'none');
     *   }
     * });
     */

    if (!showSnapShot) {
      svg.append("g")
        .attr("class", "x axis")
        .attr("transform", `translate(0,${axisPoisition})`)
        .attr("color", theme.palette.chartColors.axis)
        .call(xAxis);

      svg.append("g")
        .attr("class", "y axis")
        .attr("color", theme.palette.chartColors.axis)
        .call(yAxis)
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 6)
        .attr("dy", ".71em")
        .style("text-anchor", "end")
        .text("Points");
    }

    const focus = svg.append("g")
      .attr("class", "tooltip")
      .style("display", "none");

    if (chartType !== "Value") {

      svg.append("path")
        .attr("class", "line")
        .attr("d", line)
        .attr('stroke', "#78D2FF")
        .attr('fill', 'none');


      /*
       * svg.append("path")
       *   .attr("class", "path-fill")
       *   .attr("d", fill);
       */


      svg.append("line")
        .attr("class", "avg-line")
        .attr("x1", 0)
        .attr("x2", width)
        .attr("y1", y(avg))
        .attr("y2", y(avg));


      focus.append("circle")
        .attr("class", "tooltip-point")
        .attr("r", 6)
        .attr("fill", "#78D2FF");

      focus.append("text")
        .attr("class", "y1")
        .attr("dx", "-2em")
        .attr("dy", "-.75em");

      focus.append("line")
        .attr("class", "tooltip-line tooltip-start-date")
        .attr("y1", height)
        .attr("y2", height);

      focus.append("line")
        .attr("class", "tooltip-line tooltip-end-date")
        .attr("y1", height)
        .attr("y2", height);

      focus.append("line")
        .attr("class", "tooltip-line tooltip-mileage")
        .attr("x1", 0)
        .attr("x2", width)
        .attr("y1", height)
        .attr("y2", height);

      focus.append("text")
        .attr("class", "x1")
        .attr("dx", "-4.5em")
        .attr("dy", "-.5em")
        .attr("transform", "rotate(90)");

      focus.append("text")
        .attr("class", "x2")
        .attr("dx", "-4.5em")
        .attr("dy", "-.5em");
    }

    if (!showSnapShot) {
      svg.append("rect")
        .attr("width", width)
        .attr("height", axisPoisition)
        .style("fill", "none")
        .style("pointer-events", "all")
        .on("mouseover", () => focus.style("display", null))
        .on("mouseout", () => focus.style("display", "none"))
        .on("mousemove", mousemove);

      svg.append("text")
        .attr("transform",
          "translate(" + (width / 2) + " ," +
          (height + 10) + ")")
        .style("text-anchor", "middle")
        .text("Time");
    }
    if (!showSnapShot) {
      svg.selectAll("myCircles")
        .data(nonalertsData)
        .enter()
        .append("circle")
        .attr("fill", theme.palette.chartColors.axis)
        .attr("stroke-width", 2)
        .attr("z-index", 1300)
        // .attr("stroke", theme.palette.chartColors.trending)
        .attr("cx", 0)
        .attr("cy", (d) => { return y(d[valueKey]); })
        .attr("r", 4)
        .on('mouseover', (d, i, nodes) => {
          const rect = nodes[i].getBBox();
          div.transition()
            .duration(200)
            .style("opacity", 0.9);
          div.html(`${d[valueKey]}`)
            .style("left", x(d.start_date) + "px")
            .style("top", (rect.y) + "px");
        })
        .on('mouseout', () => {
          div.transition()
            .duration(500)
            .style("opacity", 0);
        })
        .transition()
        .delay((d, i) => { return Number(i); })
        .duration(2000)
        .attr("cx", (d) => { return x(d.start_date); });

      svg.selectAll("myCircles")
        .data(alertsData)
        .enter()
        .append("circle")
        .attr("fill", "red")
        .attr("z-index", 1400)
        .attr("stroke-width", 2)
        // .attr("stroke", theme.palette.chartColors.trending)
        .attr("cx", 0)
        .attr("cy", (d) => { return y(d[valueKey]); })
        .attr("r", 4)
        .on('mouseover', (d, i, nodes) => {
          const rect = nodes[i].getBBox();
          div.transition()
            .duration(200)
            .style("opacity", 0.9);
          div.html(`${d[valueKey]}`)
            .style("left", x(d.start_date) + "px")
            .style("top", (rect.y) + "px");
        })
        .on('mouseout', () => {
          div.transition()
            .duration(500)
            .style("opacity", 0);
        })
        .transition()
        .delay((d, i) => { return Number(i); })
        .duration(2000)
        .attr("cx", (d) => { return x(d.start_date); });
    }

    function mousemove() {
      const x0 = x.invert(d3.mouse(this)[0]),
        i = bisectDate(data, x0, 1),
        d0 = data[i - 1],
        d1 = data[i];

      const d = typeof d1 !== "undefined" && x0 - d0.strat_date > d1.start_date - x0 ? d1 : d0;

      focus.select("circle.tooltip-point")
        .attr("transform", `translate(${d3.mouse(this)[0]},${y(d[valueKey])})`);

      focus.select("text.y1")
        .attr("transform", "translate(" + d3.mouse(this)[0] + "," + y(d[valueKey]) + ")");

      let tooltipY = y(d[valueKey]) - 120;
      if (tooltipY <= 0) {
        tooltipY += 150;
      }
      focus.select("text.x1")
        .attr("transform", "translate(" + x(d.start_date) + "," + tooltipY + ") rotate(-90)");

      focus.select("text.x2")
        .attr("transform", "translate(" + x(d.end_date) + "," + tooltipY + ") rotate(-90)");

      focus.select("line.tooltip-start-date")
        .attr("y2", y(d[valueKey]))
        .attr("x1", x(d.start_date))
        .attr("x2", x(d.start_date));

      focus.select("line.tooltip-end-date")
        .attr("y2", y(d[valueKey]))
        .attr("x1", x(d.end_date))
        .attr("x2", x(d.end_date));

      focus.select("line.tooltip-mileage")
        .attr("y1", y(d[valueKey]))
        .attr("y2", y(d[valueKey]));

      focus.select("text.y1").text(d[valueKey]);

      focus.select("text.x1").text(formatDate(d.start_date));

      focus.select("text.x2").text(formatDate(d.end_date));
    }

  }, [chartClassName, chartData, chartHeight, chartType, chartWidth, inputKey, showSnapShot, theme, valueKey]);

  return (
    <Grid
      ref={chartContainerRef}
      style={{ padding: 0, width: '100%', height: '100%' }}
      className={classNames(classes.chartStyles, !showSnapShot ? 'step-chart' : '', chartClassName)}
    />
  );
};

StepChart.propTypes = {
  classes: PropTypes.object,
  theme: PropTypes.object,
  chartData: PropTypes.array,
  chartWidth: PropTypes.number,
  chartHeight: PropTypes.number,
  showSnapShot: PropTypes.bool,
  chartClassName: PropTypes.string,
  inputKey: PropTypes.string,
  chartType: PropTypes.string
};

export default withStyles(
  (theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
  }),
  { withTheme: true }
)(StepChart);