import React, { useCallback, useState } from 'react';
import { Grid, withStyles, Typography, Card, Table, TableRow, TableHead, TableCell, TableBody, TableSortLabel, TextField } from '@material-ui/core';
import classNames from 'classnames';
// import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import MasterSearchDetailStyles from './MasterSearchDetailStyles.jsx';
// import { sortTable } from '../../helpers/appHelpers.js';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import CollapsibleTableRow from './CollapsibleTableRow.jsx';
// import TooltipComponent from '../Tooltip/Tooltip.jsx';

const MasterSearchResult = (props) => {
    const { classes, searchResults } = props;
    const [groupBy, setGroupBy] = useState([]);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const [target_ele_id, setTargetEle] = useState(-1);

    const first_parent_record = searchResults.length > 0 ? searchResults[0].master : {};
    let first_child_record = searchResults.length > 0 ? searchResults[0].related_records : [];
    first_child_record = first_child_record.length > 0 ? first_child_record[0] : {};
    let parent_headers = Object.keys(first_parent_record);
    let child_headers = Object.keys(first_child_record);
    child_headers = child_headers.filter((header) => header !== 'dq_temp_rank');
    parent_headers = parent_headers.filter((header) => header !== 'dq_temp_rank');
    const onChangeOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    const collapseToggle = (target_id) => {
        setTargetEle(target_id);
    };

    const getGroupRecords = useCallback(() => {
        const modifiedSearchResult = JSON.parse(JSON.stringify(searchResults)).map((m_record) => {
            const modifiedRecord = JSON.parse(JSON.stringify(m_record));
            if (m_record['related_records'].length > 0) {
                const groupedArray = [];
                modifiedRecord['related_records'].forEach((related_record) => {
                    let isExsisting = false;
                    const groupedObj = {};
                    groupedObj['group_key'] = '';
                    groupedObj['parent_record'] = JSON.parse(JSON.stringify(related_record));
                    groupedObj['child_records'] = [];
                    let group_key = '';
                    groupBy.forEach((key) => {
                        group_key += `${related_record[key]}`;
                    });
                    const targetIndex = groupedArray.findIndex((d) => d.group_key === group_key);
                    if (targetIndex > -1) {
                        isExsisting = true;
                    }
                    if (!isExsisting) {
                        groupedObj['group_key'] = group_key;
                        groupedObj.child_records.push(related_record);
                        groupedArray.push(groupedObj);
                    } else {
                        groupedArray[targetIndex].child_records.push(related_record);
                    }
                });

                modifiedRecord['grouped_records'] = groupedArray;
                return modifiedRecord;
            }
            return modifiedRecord;
        });

        return modifiedSearchResult;
    }, [groupBy, searchResults]);

    const GroupedResults = useCallback(() => {

        let result_array = [];
        if (groupBy && groupBy.length > 0) {
            result_array = getGroupRecords();
        } else {
            result_array = JSON.parse(JSON.stringify(searchResults));
        }
        return (
            <Table stickyHeader className={classes.groupedTable}>
                <TableHead>
                    <TableRow>
                        {
                            parent_headers.map((semanticModelHeader, index) =>
                                <TableCell key={`semanticModelHeader${index}`}
                                    sortDirection={orderBy === semanticModelHeader ? order : false}>
                                    <TableSortLabel
                                        active={orderBy === semanticModelHeader}
                                        direction={orderBy === semanticModelHeader ? order : 'asc'}
                                        onClick={() => onChangeOrder(semanticModelHeader)}
                                    >
                                        <Typography variant="body1" className={classes.tableHeader}>
                                            {semanticModelHeader}
                                        </Typography>
                                    </TableSortLabel>
                                </TableCell>
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        result_array && result_array.map((searchResult, index) => {
                            return (
                                <CollapsibleTableRow key={`table_row_${index}`} searchResult={searchResult} parent_headers={parent_headers} child_headers={child_headers} collapseToggle={collapseToggle} eleId={index} target_ele_id={target_ele_id} />
                            );
                        })
                    }
                </TableBody>
            </Table >
        );
    }, [child_headers, classes.groupedTable, classes.tableHeader, getGroupRecords, groupBy, onChangeOrder, order, orderBy, parent_headers, searchResults, target_ele_id]);

    return (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Grid container direction="row" justify="flex-end">
                <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                    <AutoCompleteInput
                        name="attributes"
                        popperWidth={300}
                        options={[...child_headers]}
                        value={groupBy && groupBy.length > 0 ? [...groupBy] : []}
                        getOptionLabel={
                            (option) => {
                                if (option && option.name) {
                                    return option.name;
                                }
                                return option;
                            }
                        }
                        renderInput={
                            (params) =>
                                <TextField {...params}
                                    className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                    placeholder="Group By"
                                    margin="normal"
                                    fullWidth
                                />
                        }
                        onChange={(_, selectedValue) => setTimeout(() => setGroupBy(selectedValue), 10)}
                        openOnFocus
                        disableClearable
                        fullWidth
                        multiple
                        forcePopupIcon={false}
                        noOptionsText={"No Attributes Found"}
                    />
                </Grid>
            </Grid>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Card className={classNames(classes.masterSearchTable)}>
                    {GroupedResults()}
                </Card>
            </Grid>
        </Grid>
    );
};

MasterSearchResult.propTypes = {
    classes: PropTypes.object,
    searchResults: PropTypes.array
};

export default withStyles((theme) => ({
    ...MasterSearchDetailStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MasterSearchResult);