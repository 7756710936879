import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, IconButton } from '@material-ui/core';
import DatasourceInfo from './DatasourceInfo.jsx';
import Styles from '../../layouts/Styles.jsx';
import DatasourceListStyles from './DatasourceListStyles.jsx';
import { useSelector, useDispatch } from 'react-redux';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import RequestAccess from '../Permission/RequestAccess.jsx';
import { updateViews } from '../../actions/datasourceActions';
import { appConstants } from '../../constants/appConstants.js';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader.jsx';
import NoResultImg from '../../assets/images/no_result.png';
import { updateNotification } from '../../actions/notificationActions';
// import { showAlert } from '../../actions/alertActions';


const DatasourceList = (props) => {
    const { classes, sources, theme, type, history, containerRef, requestAccess, responseRequest, redirectDetail, isPageLoading } = props;
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [sourceIndex, setSourceIndex] = useState(0);
    const user = useSelector(({ account }) => account.user);
    const isLoading = useSelector(({ catalog }) => catalog.isLoading);
    const users = useSelector(({ account }) => account.users);
    /*
     * const connectionTypes = useSelector(({ datasource }) => datasource.connectionTypes);
     * const licenseConfig = useSelector(({ setting }) => setting.licenseConfig);
     */
    const userId = user ? user.id : 0;
    const dispatch = useDispatch();

    const getSteward = (sharingInfo) => {
        const sourceDetail = sharingInfo.find((info) => info.is_steward_user && info.user_id === userId);
        return Boolean(sourceDetail);
    };

    const getDatasourceIcon = useCallback((source) => {
        if (source.name === 'Untitled Data Source') {
            return (
                <svg id="Group_2154" data-name="Group 2154" xmlns="http://www.w3.org/2000/svg" width="34.459" height="18.227" viewBox="0 0 34.459 18.227">
                    <path id="Path_246" data-name="Path 246" d="M170.469,193.936V175.959h6.379a9.332,9.332,0,0,1,3.77.709,7.743,7.743,0,0,1,2.708,1.924,8.013,8.013,0,0,1,1.632,2.848,10.934,10.934,0,0,1,.547,3.48,10.745,10.745,0,0,1-.606,3.708,7.944,7.944,0,0,1-1.743,2.835,7.837,7.837,0,0,1-2.734,1.823,9.436,9.436,0,0,1-3.572.645Zm11.6-9.012a7.816,7.816,0,0,0-.345-2.38,5.359,5.359,0,0,0-1.013-1.873,4.518,4.518,0,0,0-1.641-1.215,5.432,5.432,0,0,0-2.213-.43h-2.972v11.847h2.967a5.237,5.237,0,0,0,2.237-.456,4.592,4.592,0,0,0,1.632-1.257,5.62,5.62,0,0,0,1-1.886,7.736,7.736,0,0,0,.344-2.355Zm18.634,7.848a8.634,8.634,0,0,1-2.077.95,8.047,8.047,0,0,1-2.369.342,7.717,7.717,0,0,1-3.5-.785,8.87,8.87,0,0,1-2.7-2.051,9.222,9.222,0,0,1-1.743-2.911,9.505,9.505,0,0,1-.618-3.367,9.224,9.224,0,0,1,.655-3.443,9.54,9.54,0,0,1,1.806-2.911,8.618,8.618,0,0,1,2.734-2.012,7.954,7.954,0,0,1,3.437-.747,7.569,7.569,0,0,1,3.474.8,8.709,8.709,0,0,1,2.677,2.081,9.661,9.661,0,0,1,1.731,2.937,9.427,9.427,0,0,1,.618,3.342,9.33,9.33,0,0,1-.606,3.342,9.226,9.226,0,0,1-1.693,2.835l2.4,2.764h-3.214Zm-4.515-1.8a4.561,4.561,0,0,0,1.249-.165,4.893,4.893,0,0,0,1.075-.443l-2.67-3.088h3.214l1.211,1.393a7.272,7.272,0,0,0,1.04-3.721,7.154,7.154,0,0,0-.358-2.253,6.262,6.262,0,0,0-1.013-1.924,5,5,0,0,0-1.608-1.342,4.483,4.483,0,0,0-2.139-.507,4.574,4.574,0,0,0-2.2.518,4.844,4.844,0,0,0-1.608,1.38,6.349,6.349,0,0,0-.977,1.937,7.421,7.421,0,0,0,.013,4.44,6.062,6.062,0,0,0,1,1.924,5,5,0,0,0,1.608,1.342A4.54,4.54,0,0,0,196.183,190.97Z" transform="translate(-170.469 -175.837)" fill="#fff" />
                    <g id="Group_2192" data-name="Group 2192" transform="translate(24.368 10.787)">
                        <path id="Path_245-2" data-name="Path 245-2" d="M236.075,204.878h3.656l6.429,7.306-3.509.009Z" transform="translate(-236.075 -204.878)" fill="#fff" />
                    </g>
                </svg>
            );
        }
        const name = source.name ? source.name.trim() : "";
        let title = name.split(' ');
        if (title.length === 1) {
            title = `${title[0][0]}${title[0][1]}`;
        }
        if (title.length >= 1) {
            title = `${title[0][0]}${title[1][0]}`;
        }

        return (
            <Typography variant="h3" className={classes.categoryListLogoTxt}>
                {title}
            </Typography>
        );
    }, [classes.categoryListLogoTxt]);


    const getSharingIcon = (sharingInfo, source, index) => {
        const userId = user ? user.id : 0;
        const sourceDetail = sharingInfo.find((info) => info.user_id === userId);
        return (
            <Fragment>
                {
                    (!sourceDetail) &&
                    <Grid className={classes.paddingLeft12}>
                        <TooltipComponent title={"Request Datasource"} arrow>
                            <IconButton className={classes.nopadding} onClick={() => requestAccess(index)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
                                    <g transform="translate(0 0)">
                                        <circle fill={theme.palette.primary.main} cx="0.5" cy="0.5" r="0.5" transform="translate(12.999 13)" />
                                        <path fill={theme.palette.primary.main} d="M9.3,18.439H3.125a1.564,1.564,0,0,1-1.563-1.563V10.47A1.564,1.564,0,0,1,3.125,8.907h11.25a1.564,1.564,0,0,1,1.563,1.563v1.563a.781.781,0,0,0,1.563,0V10.47a3.129,3.129,0,0,0-3.125-3.125h-.939V4.59A4.644,4.644,0,0,0,8.749,0,4.644,4.644,0,0,0,4.061,4.59V7.345H3.125A3.129,3.129,0,0,0,0,10.47v6.406A3.129,3.129,0,0,0,3.125,20H9.3a.781.781,0,1,0,0-1.563ZM5.624,4.59A3.08,3.08,0,0,1,8.749,1.564,3.08,3.08,0,0,1,11.874,4.59V7.345H5.624V4.59Z" transform="translate(0 -0.001)" />
                                        <path fill={theme.palette.primary.main} d="M272.439,322.145a.781.781,0,0,0-1.09.18L267.5,327.7a.446.446,0,0,1-.642.033l-2.489-2.429a.781.781,0,0,0-1.091,1.118l2.493,2.433.007.007a2.014,2.014,0,0,0,1.386.554q.066,0,.133,0a2.014,2.014,0,0,0,1.432-.746l.026-.035,3.866-5.4A.781.781,0,0,0,272.439,322.145Z" transform="translate(-252.765 -309.421)" />
                                        <ellipse fill={theme.palette.primary.main} cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(8.999 13)" />
                                        <ellipse fill={theme.palette.primary.main} cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(2.999 13)" />
                                        <circle fill={theme.palette.primary.main} cx="0.5" cy="0.5" r="0.5" transform="translate(6.999 13)" />
                                    </g>
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                        <Typography component="span" className={classes.viewTxt}>
                            Request Access
                        </Typography>
                    </Grid>
                }
                {
                    (sourceDetail && sourceDetail.is_requested && (!sourceDetail.is_request_accepted && !sourceDetail.is_request_decline)) &&
                    <Grid className={classes.paddingLeft12}>
                        <TooltipComponent title={"Waiting"} arrow>
                            <IconButton className={classes.padding5}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18.831" height="20.072" viewBox="0 0 18.831 20.072">
                                    <ellipse fill="#dcc9d6" cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(9 13)" />
                                    <path fill="#dcc9d6" d="M11.015,18.437H3.125a1.564,1.564,0,0,1-1.562-1.562V10.469A1.564,1.564,0,0,1,3.125,8.906h11.25a1.564,1.564,0,0,1,1.562,1.562v1.719a.781.781,0,0,0,1.562,0V10.469a3.129,3.129,0,0,0-3.125-3.125h-.939V4.588A4.644,4.644,0,0,0,8.748,0,4.644,4.644,0,0,0,4.061,4.588V7.344H3.125A3.129,3.129,0,0,0,0,10.469v6.406A3.129,3.129,0,0,0,3.125,20h7.891a.781.781,0,0,0,0-1.562ZM5.623,4.588A3.08,3.08,0,0,1,8.748,1.562a3.08,3.08,0,0,1,3.125,3.026V7.344H5.623V4.588Z" />
                                    <circle fill="#dcc9d6" cx="0.5" cy="0.5" r="0.5" transform="translate(7 13)" />
                                    <circle fill="#dcc9d6" cx="0.5" cy="0.5" r="0.5" transform="translate(4 13)" />
                                    <g transform="translate(12.679 13.921)">
                                        <path fill="#dcc9d6" d="M313.581,472h0a3.075,3.075,0,1,0,3.077,3.075A3.075,3.075,0,0,0,313.58,472Z" transform="translate(-310.505 -472)" />
                                    </g>
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                        <Typography component="span" className={classes.viewTxt}>
                            Request Waiting
                        </Typography>
                    </Grid>
                }
                {
                    (sourceDetail && sourceDetail.is_requested && sourceDetail.is_request_decline) &&
                    <Grid className={classes.paddingLeft12}>
                        <TooltipComponent title={"Decline"} arrow>
                            <IconButton className={classes.padding5}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <ellipse fill="#e1e5e6" cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(9 13)" />
                                    <path fill="#e1e5e6" d="M11.015,18.437H3.125a1.564,1.564,0,0,1-1.562-1.562V10.469A1.564,1.564,0,0,1,3.125,8.906h11.25a1.564,1.564,0,0,1,1.562,1.562v1.719a.781.781,0,0,0,1.562,0V10.469a3.129,3.129,0,0,0-3.125-3.125h-.939V4.588A4.644,4.644,0,0,0,8.748,0,4.644,4.644,0,0,0,4.061,4.588V7.344H3.125A3.129,3.129,0,0,0,0,10.469v6.406A3.129,3.129,0,0,0,3.125,20h7.891a.781.781,0,0,0,0-1.562ZM5.623,4.588A3.08,3.08,0,0,1,8.748,1.562a3.08,3.08,0,0,1,3.125,3.026V7.344H5.623V4.588Z" />
                                    <circle fill="#e1e5e6" cx="0.5" cy="0.5" r="0.5" transform="translate(7 13)" />
                                    <path fill="#e1e5e6" d="M348.411,347.306l-1.987-1.987,1.987-1.987a.781.781,0,1,0-1.1-1.1l-1.987,1.987-1.987-1.987a.781.781,0,1,0-1.1,1.1l1.987,1.987-1.987,1.987a.781.781,0,0,0,1.1,1.1l1.987-1.987,1.987,1.987a.781.781,0,0,0,1.1-1.1Z" transform="translate(-328.641 -328.64)" />
                                    <circle fill="#e1e5e6" cx="0.5" cy="0.5" r="0.5" transform="translate(4 13)" />
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                        <Typography component="span" className={classes.viewTxt}>
                            Request Decline
                        </Typography>
                    </Grid>
                }
            </Fragment>
        );
    };

    const editData = (source, property) => {
        const model = {
            'source_id': null,
            'dataset_id': null
        };
        /*
         * const inputConnections = licenseConfig && licenseConfig.input_connectors ? licenseConfig.input_connectors : [];
         * const connections = connectionTypes.filter((connection) => inputConnections.includes(connection.id));
         */
        if (type === appConstants.filterTypes[3] || (getPermission(source.sharing_info) && (user.role && user.role.properties_permission && user.role.properties_permission.is_view)) || getStewardPermission(source.sharing_info)) {
            if (type === appConstants.filterTypes[0]) {
                model['source_id'] = source.id;
                if (property === "main") {
                    history.push(`/catalog/${source.id}`);
                } else if (property === "dataset" && source.name !== "") {
                    model['dataset_id'] = source.datasetId;
                    history.push({ pathname: `/dataset/${source.datasetId}`, state: { datasource: source } });
                } else {
                    history.push({ pathname: `/catalog/${source.id}`, state: { dqscore: true } });
                }
                /*
                 * const connectionStatus = connections.some((data) => data.type === source.type);
                 * if (!connectionStatus) {
                 *     dispatch(showAlert("Connection Disabled"));
                 * }
                 */
            } else if (type === appConstants.filterTypes[1]) {
                model['source_id'] = source.source_id;
                model['dataset_id'] = source.id;
                if (property === 'main' && source.datasource.name !== "") {
                    history.push({ pathname: `/dataset/${source.id}`, state: { datasource: source.datasource ? source.datasource : {} } });
                } else if (property === "attribute" && source.datasource.name !== "") {
                    history.push({ pathname: `/dataset/${source.id}`, state: { datasource: source.datasource ? source.datasource : {}, attribute: source.attribute } });
                } else if (source.datasource.name !== "") {
                    history.push({ pathname: `/dataset/${source.id}`, state: { datasource: source.datasource ? source.datasource : {}, dqscore: true } });
                }
                /*
                 * const connectionStatus = connections.some((data) => data.type === source.type);
                 * if (!connectionStatus) {
                 *     dispatch(showAlert("Connection Disabled"));
                 * }
                 */
            } else if (type === appConstants.filterTypes[2]) {
                model['source_id'] = source.datasource_id;
                model['dataset_id'] = source.dataset_id;
                if (property === "dqscore" && source.datasource.name !== "") {
                    history.push({ pathname: `/dataset/${source.dataset_id}`, state: { datasource: source.datasource ? source.datasource : {}, dqscore: true, attribute: source.name } });
                } else if (property === "datasource" && source.datasource.name !== "") {
                    history.push({ pathname: `/catalog/${source.datasource_id}` });
                } else if (property === "dataset" && source.datasource.name !== "") {
                    history.push({ pathname: `/dataset/${source.dataset_id}`, state: { datasource: source.datasource ? source.datasource : {} } });
                } else if (source.datasource.name !== "") {
                    history.push({ pathname: `/dataset/${source.dataset_id}`, state: { datasource: source.datasource ? source.datasource : {}, attribute: source.name } });
                }
            } else {
                model['domain_id'] = source.id;
                if (property === "main") {
                    history.push({ pathname: `/domain/${source.id}`, state: { domain: source } });
                } else {
                    history.push({ pathname: `/domain/${source.id}`, state: { domain: source, rule: true } });
                }
            }
            dispatch(updateViews(model));
        } else {
            let sourceDetail = source;
            if (type === appConstants.filterTypes[1]) {
                sourceDetail = source.datasource;
            } else if (type === appConstants.filterTypes[2]) {
                sourceDetail = source.datasource;
            }
            redirectDetail("detail", sourceDetail);
        }
    };

    const editDataLink = (source, property) => {
        const model = {
            'source_id': null,
            'dataset_id': null
        };
        /*
         * const inputConnections = licenseConfig && licenseConfig.input_connectors ? licenseConfig.input_connectors : [];
         * const connections = connectionTypes.filter((connection) => inputConnections.includes(connection.id));
         */
        if (type === appConstants.filterTypes[3] || (getPermission(source.sharing_info) && (user.role && user.role.properties_permission && user.role.properties_permission.is_view)) || getStewardPermission(source.sharing_info)) {
            if (type === appConstants.filterTypes[0]) {
                model['source_id'] = source.id;
                if (property === "main") {
                    return ({ pathname: `/catalog/${source.id}` });
                } else if (property === "dataset" && source.name !== "") {
                    model['dataset_id'] = source.datasetId;
                    return ({ pathname: `/dataset/${source.datasetId}`, state: { datasource: source } });
                }
                    return ({ pathname: `/catalog/${source.id}`, state: { dqscore: true } });

                /*
                 * const connectionStatus = connections.some((data) => data.type === source.type);
                 * if (!connectionStatus) {
                 *     dispatch(showAlert("Connection Disabled"));
                 * }
                 */
            } else if (type === appConstants.filterTypes[1]) {
                model['source_id'] = source.source_id;
                model['dataset_id'] = source.id;
                if (property === 'main' && source.datasource.name !== "") {
                    return ({ pathname: `/dataset/${source.id}`, state: { datasource: source.datasource ? source.datasource : {} } });
                } else if (property === "attribute" && source.datasource.name !== "") {
                    return ({ pathname: `/dataset/${source.id}`, state: { datasource: source.datasource ? source.datasource : {}, attribute: source.attribute } });
                } else if (source.datasource.name !== "") {
                    return ({ pathname: `/dataset/${source.id}`, state: { datasource: source.datasource ? source.datasource : {}, dqscore: true } });
                }
                /*
                 * const connectionStatus = connections.some((data) => data.type === source.type);
                 * if (!connectionStatus) {
                 *     dispatch(showAlert("Connection Disabled"));
                 * }
                 */
            } else if (type === appConstants.filterTypes[2]) {
                model['source_id'] = source.datasource_id;
                model['dataset_id'] = source.dataset_id;
                if (property === "dqscore" && source.datasource.name !== "") {
                    return ({ pathname: `/dataset/${source.dataset_id}`, state: { datasource: source.datasource ? source.datasource : {}, dqscore: true, attribute: source.name } });
                } else if (property === "datasource" && source.datasource.name !== "") {
                    return ({ pathname: `/catalog/${source.datasource_id}` });
                } else if (property === "dataset" && source.datasource.name !== "") {
                    return ({ pathname: `/dataset/${source.dataset_id}`, state: { datasource: source.datasource ? source.datasource : {} } });
                } else if (source.datasource.name !== "") {
                    return ({ pathname: `/dataset/${source.dataset_id}`, state: { datasource: source.datasource ? source.datasource : {}, attribute: source.name } });
                }
            } else {
                model['domain_id'] = source.id;
                if (property === "main") {
                    return ({ pathname: `/domain/${source.id}`, state: { domain: source } });
                }
                    return ({ pathname: `/domain/${source.id}`, state: { domain: source, rule: true } });

            }
            // dispatch(updateViews(model));
        } else {
            let sourceDetail = source;
            if (type === appConstants.filterTypes[1]) {
                sourceDetail = source.datasource;
            } else if (type === appConstants.filterTypes[2]) {
                // eslint-disable-next-line no-unused-vars
                sourceDetail = source.datasource;
            }
            // redirectDetail("detail", sourceDetail);
        }
    };

    const openRequestAccess = (event, index) => {
        setOpen(true);
        setAnchorEl(event.target);
        setSourceIndex(index);
    };

    const closeRequestAccess = () => {
        setOpen(false);
        setAnchorEl(null);
    };

    const userRequestAccessAccept = (useraction, user) => {
        const model = {
            "source_id": user.source_id,
            "dataset_id": user.dataset_id,
            "shareUserList": []
        };
        const userInfo = users.find((data) => user.user_id === data.id);
        let access = user.access_level;
        if (user.is_higher_requested && useraction === "accept") {
            access = user.new_access_level;
        } else if (user.is_higher_requested && useraction !== "accept") {
            access = user.access_level;
        } else if (userInfo && userInfo.governance === "Level 2") {
            access = 2;
        } else if (userInfo && userInfo.governance === "Level 1") {
            access = 1;
        }

        model.shareUserList.push({
            "user_id": user.user_id,
            "access_level": access,
            "type": type,
            "is_stewarduser": user.is_steward_user,
            "is_owner": false,
            "source_id": user.source_id,
            "dataset_id": user.dataset_id,
            "is_request_accepted": useraction === "accept" || user.is_higher_requested,
            "is_decline": useraction !== "accept" && !user.is_higher_requested,
            "is_requested": false,
            "is_higher_requested": false,
            "is_higher_request_accepted": useraction === "accept",
            "is_higher_request_decline": useraction !== "accept",
            "request_comment": user.request_comment
        });

        const type_change = user.level === "Dataset" ? appConstants.filterTypes[1] : type;

        responseRequest(type_change, model);

        const notificationModel = {
            "created_by_id": user.user_id,
            "notification_text_type": "Request Access",
            "notification_type": "permissioning",
            "source_id": user.source_id
        };
        dispatch(updateNotification(notificationModel));
    };

    const getSharingDetail = (sources) => {
        const request_list = [];
        const access_request = sources.sharing_info.filter((user) => (user.is_requested && (!user.is_request_accepted && !user.is_decline)));
        if (access_request.length > 0) {
            access_request.forEach((list) => {
                request_list.push(list);
            });
        }
        const source_request = sources.sharing_info.filter((user) => (user.is_higher_requested && (!user.is_higher_request_accepted && !user.is_higher_request_decline)));
        if (source_request.length > 0) {
            source_request.forEach((list) => {
                request_list.push(list);
            });
        }
        sources.datasets && sources.datasets.forEach((element) => {
            const dataset_request = element.sharing_info.filter((datstet_user) => (datstet_user.is_higher_requested && (!datstet_user.is_higher_request_accepted && !datstet_user.is_higher_request_decline)));
            if (dataset_request.length > 0) {
                dataset_request.forEach((list) => {
                    list.dataset_name = element.name;
                    request_list.push(list);
                });
            }
        });
        return request_list;
    };


    const getPermission = (sharingInfo) => {
        const userId = user ? user.id : 0;
        const sourceDetail = sharingInfo.find((info) => info.user_id === userId);
        return (sourceDetail && sourceDetail.is_request_accepted);
    };


    const getStewardPermission = (sharingInfo) => {
        const userId = user ? user.id : 0;
        const sourceDetail = sharingInfo.find((info) => info.user_id === userId && info.is_steward_user);
        return (sourceDetail && sourceDetail.is_request_accepted);
    };

    return (
        <Grid className={classes.datasouceListContainer} ref={containerRef}>
            {
                sources && sources.length > 0 &&
                sources.map((datasource, index) =>
                    <DatasourceInfo
                        key={index}
                        index={index}
                        type={type}
                        source={datasource}
                        getSteward={getSteward}
                        getDatasourceIcon={getDatasourceIcon}
                        getSharingIcon={getSharingIcon}
                        editData={editData}
                        editDataLink={editDataLink}
                        openRequestAccess={openRequestAccess}
                        history={history}
                    />
                )
            }
            {
                (isLoading || (isPageLoading && sources && sources.length === 0)) &&
                <SkeletonLoader />
            }
            {
                open &&
                <RequestAccess
                    onclose={closeRequestAccess}
                    respondRequest={userRequestAccessAccept}
                    open={open}
                    anchorEl={anchorEl}
                    governance={sources[sourceIndex] ? getSharingDetail(sources[sourceIndex]) : []}
                />
            }
            {
                (!sources || sources.length === 0) && !isLoading && !isPageLoading &&
                <Grid container alignItems="center" justify="center" className={classes.noResultFound}>
                    <Grid>
                        <img src={NoResultImg} alt="No Result Found" />
                        <Typography variant="h4" align="center">
                            {'No Result Found'}
                        </Typography>
                    </Grid>
                </Grid>
            }

        </Grid>
    );
};
DatasourceList.propTypes = {
    classes: PropTypes.object,
    sources: PropTypes.array,
    theme: PropTypes.object,
    type: PropTypes.string,
    history: PropTypes.object,
    containerRef: PropTypes.object,
    requestAccess: PropTypes.func,
    redirectDetail: PropTypes.func,
    responseRequest: PropTypes.func,
    isPageLoading: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DatasourceList);