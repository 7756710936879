const SearchStyles = (theme) => ({
	root: {
		flexGrow: '1 auto',
		height: '100%',
		fontSize: 13
	},
	subtitle1: {
		fontSize: 13
	},
	width100: {
		width: '100%'
	},
	highlight_text: {
		fontWeight: 'bold',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},
	contNew: {
		height: '100%',
		alignContent: "center"
	},
	logocontainer: {
		position: 'relative',
		height: '157px',
		width: '157px',
		background: '#fff',
		boxShadow: '0px 0px 6px #e1e5e6',
		borderRadius: 2,
		padding: 16,
		display: 'flex',
		alignItems: 'center'
	},
	searchLogo: {
		width: '100%'
	},
	searchtick: {
		position: 'absolute',
		bottom: '-22px',
		right: '-24px'
	},
	header: {
		textAlign: "center",
		padding: '0px',
		margin: '0px',
		fontWeight: 'normal'
	},
	description: {
		textAlign: 'center',
		color: "#969899",
		margin: 4,
		marginBottom: 20
	},
	textCenter: {
		textAlign: 'center'
	},
	search: {
		width: "70%",
		margin: "0px auto",
		"& .MuiFormControl-root": {
			width: "100%"
		}
	},
	searchicon: {
		position: "relative",
		"& img": {
			position: "absolute",
			top: 6,
			right: 5,
			maxWidth: 32,
			display: "block !important",
			cursor: "pointer",
			zIndex: 9999,
			borderRadius: "40%",
			padding: 7,
			"&:hover": {
				backgroundColor: "rgba(0, 0, 0, 0.04)"
			}
		}
	},
	searchclear: {
		"& .MuiAutocomplete-clearIndicator": {
			visibility: "visible"
		},
		"& .MuiAutocomplete-endAdornment": {
			right: "40px !important"
		}
	},
	listSearch: {
		margin: 0
	},
	cardTitle: {
		fontSize: 30,
		fontFamily: theme.palette.titleFont
	},
	popIndex: {
		position: "relative",
		zIndex: 5,
		maxWidth: '85%',
		margin: 'auto'
	},
	cardTxt: {
		fontSize: 13
	},
	icoBtn: {
		backgroundColor: "rgba(0, 0, 0, 0.04)"
	},
	cardTile: {
		alignItems: "center",
		boxShadow: "0px 0px 6px #e1e5e6",
		backgroundColor: "#FFF",
		padding: "10px 20px",
		cursor: 'pointer',
		position: 'relative',
		zIndex: '1'
	},
	textLeft: {
		textAlign: "left"
	},
	textRight: {
		textAlign: "right"
	},
	cardList: {
		marginTop: 100,
		display: "flex",
		justifyContent: "center"
	},
	Listheader: {
		margin: "20px 0px"
	},
	valueText: {
		fontWeight: "bold",
		color: theme.palette.grey.default,
		paddingLeft: 5,
		fontSize: 13
	},
	resultsFound: {
		color: "#1D2C2F"
	},
	listContainer: {
		backgroundColor: "#FFF",
		background: "#fff",
		padding: "20px 30px",
		border: '1px solid rgba(26,54,126,0.125)',
		width: "100%",
		// boxShadow: "0px 0px 6px #e1e5e6",
		marginBottom: 20,
		'&:hover': {
			boxShadow: '0 4px 25px 0 rgba(0,0,0,.1)',
			transition: 'all 0.2s ease'
		}
	},
	ribbon: {
		position: "absolute",
		top: -13,
		left: -12,
		width: 25,
		transform: 'rotate(-45deg)',
		background: theme.palette.primary.main,
		transformOrigin: 0,
		height: 34
		// borderRadius: "30px"
	},
	listCont: {
		display: "block"
	},
	flexCont: {
		display: "flex",
		marginRight: 25,
		alignItems: 'center',
		maxWidth: '100%'
	},
	greenChip: {
		backgroundColor: "#E0F9ED"
	},
	searchCont: {
		margin: "15px 0px",
		lineHeight: "24px",
		maxHeight: "50px",
		overflow: "hidden",
		textOverflow: "ellipsis",
		WebkitLineClamp: 2,
		WebkitBoxOrient: "vertical",
		display: "-webkit-box"
	},
	searchListContainer: {
		height: "calc(100vh - 190px)",
		position: "relative",
		overflowY: "auto",
		display: "block",
		paddingRight: 8
	},
	HeaderTab: {
		borderBottom: `1px solid ${theme.palette.border.normal}`,
		marginBottom: 20
	},
	filterPosition: {
		right: "75px !important",
		top: 0
	},
	fullBox: {
		border: 0,
		"& div.MuiAutocomplete-inputRoot": {
			border: "1px solid #E9ECEC",
			padding: "0px 15px"
		}
	},
	plusChip: {
		background: "#FFFFFF !important",
		margin: 20
	},
	linkHeading: {
		paddingLeft: 10,
		fontSize: 16,
		cursor: "pointer",
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		"&:hover": {
			color: "#3abeff"
		}
	},
	starLabel: {
		paddingLeft: 5,
		paddingTop: 2,
		fontSize: 13
	},
	testClass: {
		"& .MuiInputBase-adornedEnd": {
			paddingRight: "9px !important"
		}
	},
	iconList: {
		display: "flex",
		alignItems: "center",
		"& svg": {
			marginRight: 10
		}
	},
	newInput: {
		width: "100%",
		border: "1px solid #E6EAEA",
		background: "#FFFFFF",
		padding: "5px 20px",
		"& .MuiInput-underline:before": {
			border: 0
		},
		"& .MuiInput-underline:hover:before": {
			border: 0
		}
	},
	trendBox: {
		position: "absolute",
		background: "#FFFFFF",
		boxShadow: "0px 0px 6px #e1e5e6",
		top: 42,
		width: "100%",
		padding: 15
	},
	valignCenter: {
		alignItems: "center",
		marginTop: 15,
		cursor: "pointer"
	},
	backDrop: {
		backgroundColor: "rgba(0, 0, 0, 0)",
		width: "100%",
		height: "100%",
		position: "fixed",
		top: 0,
		left: 0,
		zIndex: -1
	},
	fontWeight600: {
		fontWeight: 600
	},
	userListCOntainer: {
		justifyContent: "end"
	},
	moreLink: {
		display: "flex",
		alignItems: "center",
		fontWeight: "bold",
		cursor: "pointer"
	},
	moreDisableLink: {
		display: "flex",
		alignItems: "center",
		color: theme.palette.grey.light
	},
	moreList: {
		padding: "15px 40px",
		cursor: "pointer"
	},
	selectedLink: {
		color: theme.palette.secondary.main
	},
	disableLink: {
		color: theme.palette.grey.light
	}
});

export default SearchStyles;