import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';
import { getValue, getColor } from '../../helpers/appHelpers';
import { appConstants } from '../../constants/appConstants';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';

const DataQualityChart = (props) => {
    const { classes, theme, chartQualityData, linearData, dqScoreThreshold } = props;
    const [chartData, setChartData] = useState({});
    const [chartType, setChartType] = useState('Circular Plot');
    const [nodeView, setNodeView] = useState(false);

    const containerRef = useRef();

    const changeChart = useCallback((value) => {
        setChartType(value);
    }, []);

    const loadProperties = useCallback((qualityData) => {
        setChartData({ ...qualityData });
    }, []);

    useEffect(() => {
        loadProperties({ ...chartQualityData });
    }, [chartQualityData, loadProperties]);

    const getFilter = (d) => {
        let drillDown = {};
        if (d.data.isDataset) {
            drillDown = { name: d.data.name, node: d.data.node, children: [], isAttribute: true, drillDownParent: d.parent, isDrillDown: true };
            for (const attribute of d.data.children) {
                const attributeData = { name: attribute.name, node: d.data.node, isAttribute: true, children: [{ name: attribute.dqscore, node: d.data.node, dqscore: attribute.dqscore }] };
                drillDown.children.push({ ...attributeData });
            }
        } else if (d.data.isAttribute && d.data.children) {
            drillDown = { name: d.parent.data.name, node: d.data.node, isDrillDown: true, drillDownParent: d.parent, children: [{ name: d.data.name, node: d.data.node, children: [{ node: d.data.node, name: d.data.children[0].name, dqscore: d.data.children[0].dqscore }] }], isDataset: true };
        } else if (!d.data.isDrillDownEnd) {
            drillDown = { name: d.data.name, node: d.data.node, isDrillDown: true, drillDownParent: d.parent, children: [{ name: '', node: d.data.node, dqscore: d.data.dqscore, isDrillDownEnd: true }], isDataset: true };
        }
        setChartData({ ...drillDown });
        setNodeView(true);

    };

    const drillDownReset = useCallback(() => {
        if (chartData.drillDownParent) {
            const drillDownData = chartData.drillDownParent.data;
            drillDownData.isDrillDown = true;
            setChartData({ ...drillDownData });
            setNodeView(false);
        } else if (chartData.isDrillDown) {
            setChartData({ ...chartQualityData });
        }
    }, [chartData.drillDownParent, chartData.isDrillDown, chartQualityData]);

    const radialPoint = (x, y) => {
        return [(y = Number(y)) * Math.cos(x -= Math.PI / 2), y * Math.sin(x)];
    };


    const getAttributeCount = useCallback((type) => {
        let count = 0;
        for (const attribute of chartData.children) {
            if (attribute.children) {
                count += attribute.children.length;
            } else {
                count += 1;
            }
        }
        return type === "count" ? count : count / 4;
    }, [chartData.children]);

    const getRotation = useCallback(() => {
        const attributeCount = getAttributeCount("count");
        if ((attributeCount >= 1) && attributeCount <= 11) {
            return (90 - (attributeCount * 5));
        } else if (attributeCount >= 12) {
            return (90 - (attributeCount * 4.5));
        }
        return 0;
    }, [getAttributeCount]);

    /*
     * const getHeight = useCallback(() => {
     *     if (initalLoad) {
     *         const attributeCount = getAttributeCount("count");
     *         if (attributeCount <= 2) {
     *             return 300;
     *         } else if (attributeCount <= 5) {
     *             return 500;
     *         }
     *     }
     *     return 1000;
     * }, [getAttributeCount, initalLoad]);
     */

    const getData = useCallback(() => {
        const qualityData = { ...chartData };
        const attributePercentage = 42 / chartData.children.length;
        qualityData.children = chartData.children.map((d) => { const data = d.children.slice(0, attributePercentage); return { ...d, children: data }; });
        setChartData({ ...qualityData });
    }, [chartData]);

    const responsivefy = (svg) => {
        // get container + svg aspect ratio
        const container = d3.select(svg.node().parentNode),
            width = parseInt(svg.style('width')) + 660,
            height = parseInt(svg.style('height')),
            aspect = width / height;

        // get width of container and resize svg to fit it
        const resize = () => {
            const targetWidth = parseInt(container.style('width'));
            svg.attr('width', targetWidth);
            svg.attr('height', Math.round(targetWidth / aspect));
        };

        /*
         * add viewBox and preserveAspectRatio properties,
         * and call resize so that svg resizes on inital page load
         */
        svg
            .attr('viewBox', '0 0 ' + width + ' ' + height)
            .attr('perserveAspectRatio', 'xMinYMid')
            .call(resize);

        d3.select(window).on('resize.' + container.attr('id'), resize);
    };

    const circularPlot = useCallback(() => {
        if (chartData && Object.keys(chartData).length !== 0) {
            if (getAttributeCount("count") > 42) {
                getData();
            }
            const width = 900;
            const height = 800;
            const radius = (width / 2) - 180;
            const highlightOpacity = 0.6;
            const tree = d3.tree()
                .size([getAttributeCount() * 0.6, radius])
                .separation((a, b) => (a.parent === b.parent ? 1 : 2) / a.depth);
            const root = tree(d3.hierarchy(chartData));

            const color = appConstants.charts.dataqualityChart.colors;
            const chartHeight = () => {
                if ((getAttributeCount("count")) <= 4) {
                    return (height / 2.8);
                }
                else if (((getAttributeCount("count")) >= 5) && ((getAttributeCount("count")) <= 7)) {
                    return (height / 2);
                }
                else if (((getAttributeCount("count")) >= 8) && ((getAttributeCount("count")) <= 10)) {
                    return (height / 1.7);
                }
                else if (((getAttributeCount("count")) >= 11) && ((getAttributeCount("count")) <= 13)) {
                    return (height / 1.2);
                }
                return height;
            };

            d3.select(".dataquality-chart > svg").remove();

            d3.select(".dataquality-chart > .tooltip").remove();
            const div = d3.select(".dataquality-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            const svg = d3.select(".dataquality-chart")
                .append("svg")
                .style("margin", '0 auto')
                .attr("width", '100%')
                .attr("height", chartHeight())
                .call(responsivefy);

            svg.append('rect').attr('width', width).attr('height', height).style('fill', theme.palette.background.paper).on('click', () => {
                drillDownReset();
            });
            const yTranslate = () => {
                if ((getAttributeCount("count")) <= 4) {
                    return (height / 5);
                }
                else if (((getAttributeCount("count")) >= 5) && ((getAttributeCount("count")) <= 7)) {
                    return (height / 3.5);
                }
                else if (((getAttributeCount("count")) >= 8) && ((getAttributeCount("count")) <= 10)) {
                    return (height / 2.8);
                }
                else if (((getAttributeCount("count")) >= 11) && ((getAttributeCount("count")) <= 13)) {
                    return (height / 2.3);
                }
                return (height / 2);
            };

            const xTranslate = () => {
                if (window.screen.width <= 1920 && window.screen.width > 1600) {
                    return width + 130;
                } else if (window.screen.width <= 1600 && window.screen.width > 1368) {
                    return width;
                } return width / 1.2;
            };

            const g = svg.append("g")
                .attr("class", "radial-tree")
                .attr("transform", "translate(" + xTranslate() + "," + yTranslate() + ")");

            const datasetPath = root.links().filter((data) => data.target.children);
            const attributePath = root.links().filter((data) => !data.target.children);
            const node = g.append('g').attr('class', 'link-node').style("transform", `rotate(${getRotation()}deg)`);
            node.append("g")
                .attr("fill", "none")
                .attr("stroke", "#ddd")
                .attr("stroke-opacity", 0.4)
                .attr("stroke-width", 1.5)
                .selectAll("line")
                .data(datasetPath)
                .enter().append("line")
                .attr("x1", (d) => radialPoint(d.source.x, d.source.y)[0])
                .attr("y1", (d) => radialPoint(d.source.x, d.source.y)[1])
                .attr("x2", (d) => radialPoint(d.target.x, d.target.y)[0])
                .attr("y2", (d) => radialPoint(d.target.x, d.target.y)[1]);

            node.append("g")
                .attr("fill", "none")
                .attr("stroke", "#ddd")
                .attr("stroke-opacity", 0.4)
                .attr("stroke-width", 1.5)
                .selectAll("path")
                .data(attributePath)
                .join("path")
                .attr("d", d3.linkRadial()
                    .angle(0)
                    .radius(0))
                .transition()
                .duration(2000)
                .attr("d", d3.linkRadial()
                    .angle((d) => d.x + 0)
                    .radius((d) => d.y));

            const datasets = root.descendants().filter((data) => data.children);
            const attributes = root.descendants().filter((data) => !data.children);
            const linknode = g.append('g').attr('class', 'node').style("transform", `rotate(${getRotation()}deg)`);
            linknode.append("g")
                .selectAll("circle")
                .data(datasets)
                .join("circle")
                .attr("transform", (d) => `
                          rotate(${d.x * 180 / Math.PI - 90})
                          translate(70,0)
                        `)
                .attr("fill", (d) => { return (d.data.isDatasource ? '#fff' : color[d.data.node]); })
                .attr("r", 0)
                .attr("opacity", 0)
                .on('click', (d) => {
                    getFilter(d);
                })
                .on('mouseover', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", highlightOpacity);
                    div.transition()
                        .duration(200)
                        .style("opacity", 1);

                    let x = 0;
                    let y = 0;
                    div.attr("transform", (_, index, p) => {
                        const mouseCoords = d3.mouse(p[index].parentNode);
                        let xCo = 0;
                        let yCo = 0;
                        if (mouseCoords[0] + 10 >= width * 0.80) {
                            xCo = mouseCoords[0] - parseFloat(div
                                .attr("width"));
                            yCo = mouseCoords[1] + 10;
                        } else {
                            xCo = mouseCoords[0] + 10;
                            yCo = mouseCoords[1];
                        }
                        x = isNaN(xCo) ? mouseCoords[0] : xCo;
                        y = isNaN(yCo) ? mouseCoords[1] : yCo;
                    });
                    div.html(`Dataset :${d.data.name} <br /> Total Attributes : ${d.data.children.length}`)
                        .style("left", x + "px")
                        .style("top", y + "px");
                })
                .on('mouseout', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", 1);
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .transition()
                .duration(2000)
                .attr("r", 5)
                .attr("cx", "-30px")
                .attr("opacity", 1);


            linknode.append("g")
                .selectAll("rect")
                .data(attributes)
                .join("rect")
                .attr("transform", (d) => `
                          rotate(${d.x * 180 / Math.PI - 90})
                          translate(${d.y + 5},0)
                        `)
                .attr("fill", (d) => getColor(d.data.dqscore, dqScoreThreshold))
                .attr('x', 0)
                .attr('y', '-19')
                .attr('width', 0)
                .attr('height', 35)
                .on('click', (d) => {
                    getFilter(d);
                })
                .on('mouseover', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", highlightOpacity);
                    div.transition()
                        .duration(200)
                        .style("opacity", 1);

                    let x = 0;
                    let y = 0;
                    if (nodes[i] && nodes[i].parentNode) {
                        div.attr("transform", (_, index, p) => {
                            if (p[index] && p[index].parentNode) {
                                const mouseCoords = d3.mouse(p[index].parentNode);
                                let xCo = 0;
                                let yCo = 0;
                                if (mouseCoords[0] + 10 >= width * 0.80) {
                                    xCo = mouseCoords[0] - parseFloat(div
                                        .attr("width"));
                                    yCo = mouseCoords[1] + 10;
                                } else {
                                    xCo = mouseCoords[0] + 10;
                                    yCo = mouseCoords[1];
                                }
                                x = isNaN(xCo) ? mouseCoords[0] : xCo;
                                y = isNaN(yCo) ? mouseCoords[1] : yCo;
                            }
                        });
                    }
                    div.html(`Attribute : ${d.data.name} <br /> DQ Score : ${getValue(d.data.dqscore)}%`)
                        .style("left", x + "px")
                        .style("top", y + "px");
                })
                .on('mouseout', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", 1);
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .transition()
                .duration(2000)
                .attr('width', (d) => d.data.dqscore + 5);

            linknode.append("g")
                .attr("font-family", "Roboto")
                .attr("font-size", 18)
                // .attr("stroke-linejoin", "round")
                .attr("stroke-width", 2)
                .selectAll("text")
                .data(datasets)
                .join("text")
                .attr("transform", (d) => `
                      rotate(${d.x * 180 / Math.PI - 90}) 
                      translate(${nodeView ? d.y - 100 : d.y},0)
                      rotate(${d.x >= Math.PI ? 180 : 0})
                    `)
                .style("fill", (d) => color[d.data.node])
                .attr("dx", (d) => `${d.x >= Math.PI ? -80 : 80}px`)
                .attr("dy", "0.31em")
                .attr("x", 0)
                .attr("opactity", 0)
                .attr("text-anchor", (d) => ((d.x < Math.PI) === !d.children ? "start" : "end"))
                .text((d) => (d.data.name !== "" && d.data.name.length >= 20 ? `${d.data.name.substring(0, 12)}...` : d.data.name))
                .clone(true).lower()
                .attr("stroke", "white")
                .transition()
                .duration(2000)
                .attr("opacity", 1)
                .attr("x", (d) => ((d.x < Math.PI) === !d.children ? 6 : -6));

        }

    }, [chartData, dqScoreThreshold, drillDownReset, getAttributeCount, getData, getRotation, nodeView, theme.palette.background.paper]);


    const barPlot = useCallback(() => {
        if (linearData && linearData.length !== 0) {
            const margin = { top: 20, right: 20, bottom: 0, left: 100 };
            let width = 940 - margin.left - margin.right;
            const height = 280;
            d3.select(".dataquality-chart > svg").remove();

            d3.select(".dataquality-chart > .tooltip").remove();

            // eslint-disable-next-line func-names
            const wrap = function () {
                const self = d3.select(this);
                if (self && self.node()) {
                    let textLength = self.node().getComputedTextLength(),
                        text = self.text();
                    while (textLength > (120) && text.length > 0) {
                        text = text.slice(0, -1);
                        self.text(text + '...');
                        textLength = self.node().getComputedTextLength();
                    }
                }
            };

            const svg = d3.select(".dataquality-chart")
                .append("svg")
                .attr("width", "100%")
                .style('margin-top', '45px')
                .attr("height", height + ((margin.top + margin.right) * 2))
                .append("g")
                .attr("width", (d, i, p) => {
                    width = p[i].parentNode.clientWidth - 200;
                    return width;
                })
                .attr("transform",
                    "translate(" + (margin.left + 20) + "," + ((margin.top / 2) - 15) + ")");

            const div = d3.select(".dataquality-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            const groups = linearData.map((data) => data.name);

            let subGroup = [];
            const chartLinearData = [];
            for (const data of linearData) {
                const group = {
                    group: data.name
                };
                for (const attribute of data.attributes) {
                    subGroup.push(attribute.name);
                    group[attribute.name] = attribute.dqscore;
                }
                chartLinearData.push({ ...group });
            }
            subGroup = [...new Set(subGroup)];

            const x = d3.scaleBand()
                .domain(groups)
                .range([0, width])
                .padding([0.2]);

            svg.append("g")
                .attr("class", "x-axis")
                .attr("color", theme.palette.chartColors.axis)
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x))
                .selectAll("text")
                .on('mouseover', (d) => {
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html(`${d}`)
                        .style("left", d3.event.pageX - 160 + "px")
                        .style("top", d3.event.layerY + 60 + "px")
                        .style("width", "auto");
                })
                .on('mouseout', (d) => {
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .each(wrap);

            const y = d3.scaleLinear()
                .domain([0, 110])
                .range([height, 0]);

            svg.append("g")
                .attr("color", theme.palette.chartColors.axis)
                .call(d3.axisLeft(y).ticks(10));

            const xSubgroup = {};

            for (const attribute of chartLinearData) {
                const keys = Object.keys(attribute);
                keys.splice(0, 1);
                keys.map((key) => key);

                xSubgroup[attribute.group] = d3.scaleBand()
                    .domain(keys)
                    .range([0, x.bandwidth()])
                    .padding([0.05]);
            }

            svg.append("g")
                .selectAll("g")
                .data(chartLinearData)
                .enter()
                .append("g")
                .attr("transform", (d) => { return "translate(" + x(d.group) + ",0)"; })
                .selectAll("rect")
                .data((d) => {
                    const keys = Object.keys(d);
                    keys.splice(0, 1);
                    return keys.map((key) => { return { key: key, value: d[key], groupName: d.group }; });
                })
                .enter().append("rect")
                .attr("x", (d) => { return xSubgroup[d.groupName](d.key); })
                .attr("y", (d) => { return y(d.value); })
                .attr("width", (d) => xSubgroup[d.groupName].bandwidth())
                .attr("height", (d) => { return height - y(d.value); })
                .attr("fill", (d) => getColor(d.value, dqScoreThreshold))
                .on('mouseover', (d) => {
                    let x = 0;
                    let y = 0;
                    div.attr("transform", (_, i, nodes) => {
                        const mouseCoords = d3.mouse(nodes[i].parentNode);
                        let xCo = 0;
                        let yCo = 0;
                        if (mouseCoords[0] + 10 >= width * 0.80) {
                            xCo = mouseCoords[0] - parseFloat(div
                                .attr("width"));
                            yCo = mouseCoords[1] + 10;
                        } else {
                            xCo = mouseCoords[0] + 10;
                            yCo = mouseCoords[1];
                        }
                        x = xCo;
                        y = yCo;
                    });
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html(`Attribute Name : ${d.key ? d.key : ''} <br/> DQ Score : ${getValue(d.value)} <br /> Dataset Name : ${d.groupName ? d.groupName : ''}`)
                        .style("left", x + "px")
                        .style("top", y + "px");
                })
                .on('mouseout', (d) => {
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                });

            svg.append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", -60)
                .attr("x", 0 - (height / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text("DQ Score");

            svg.append("text")
                .attr("transform",
                    "translate(" + (width / 2) + " ," +
                    (height + margin.top + 30 + ((margin.top / 2) - 5)) + ")")
                .style("text-anchor", "middle")
                .text("Dataset");

            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');

        }
    }, [dqScoreThreshold, linearData, theme.palette.chartColors.axis]);

    useEffect(() => {
        if (chartType === "Circular Plot") {
            circularPlot();
        } else {
            barPlot();
        }
    }, [barPlot, chartType, circularPlot]);

    return (
        <ChartContainer
            title={appConstants.charts.dataqualityChart.name}
            id={appConstants.charts.dataqualityChart.id}
            chartData={chartData}
            menuProperties={
                [
                    {
                        options: ["Bar Plot", "Circular Plot"],
                        onChange: changeChart,
                        value: chartType
                    }
                ]
            }>
            <Grid ref={containerRef} id="tree-container" container className={classNames(classes.chartStyles, "dataquality-chart")} style={{ padding: 0 }} />
            <div id="xaxis" />
        </ChartContainer>
    );
};

DataQualityChart.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    chartQualityData: PropTypes.object,
    linearData: PropTypes.array,
    dqScoreThreshold: PropTypes.object
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DataQualityChart);