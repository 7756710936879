export const lineageActions = {
    GET_LINEAGE_GRPAH_REQUEST: 'GET_LINEAGE_GRPAH_REQUEST',
    GET_LINEAGE_GRPAH_SUCCESS: 'GET_LINEAGE_GRPAH_SUCCESS',
    GET_LINEAGE_GRPAH_FAILURE: 'GET_LINEAGE_GRPAH_FAILURE',
    GET_SOURCECODE_REQUEST: 'GET_SOURCECODE_REQUEST',
    GET_SOURCECODE_SUCCESS: 'GET_SOURCECODE_SUCCESS',
    GET_SOURCECODE_FAILURE: 'GET_SOURCECODE_FAILURE',
    GET_LINEAGE_ENTRY_REQUEST: 'GET_LINEAGE_ENTRY_REQUEST',
    GET_LINEAGE_ENTRY_SUCCESS: 'GET_LINEAGE_ENTRY_SUCCESS',
    GET_LINEAGE_ENTRY_FAILURE: 'GET_LINEAGE_ENTRY_FAILURE',
    CLEAR_LINEAGE_GRPAH: 'CLEAR_LINEAGE_GRPAH'
};