import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from '@material-ui/core';
import { appConstants } from '../../constants/appConstants';
import { lineageIcons } from './LineageIconStore.jsx';

const BackForwardLineageButtons = ({ onDirectionChange, currentDirection }) => {
    return (
        <ButtonGroup variant="contained" color="primary">
            <Button color="primary" onClick={() => onDirectionChange(appConstants.lineageModule.B)} style={{ backgroundColor: currentDirection === appConstants.lineageModule.B ? '#54bfff' : '' }}>
                <img src={lineageIcons.ArrowRight} alt={'Backward Lineage'} style={{ transform: 'scaleX(-1)' }} />
            </Button>
            <Button color="primary" onClick={() => onDirectionChange(appConstants.lineageModule.BO)} style={{ backgroundColor: currentDirection === appConstants.lineageModule.BO ? '#54bfff' : '' }}>
                <img src={lineageIcons.ArrowDouble} alt={'Double side Lineage'} />
            </Button>
            <Button color="primary" onClick={() => onDirectionChange(appConstants.lineageModule.F)} style={{ backgroundColor: currentDirection === appConstants.lineageModule.F ? '#54bfff' : '' }}>
                <img src={lineageIcons.ArrowRight} alt={'Forward Lineage'} />
            </Button>
        </ButtonGroup>
    );
};

BackForwardLineageButtons.propTypes = {
    onDirectionChange: PropTypes.func,
    currentDirection: PropTypes.string
};

export default BackForwardLineageButtons;