import React from 'react';
import { Grid, withStyles, Accordion, Typography, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import DomainStyles from './DomainStyles.jsx';
import DomainListRuleHeader from './DomainListRuleHeader.jsx';
import DomainListDatasetRuleHeader from './DomainListDatasetRuleHeader.jsx';
import DomainListCard from './DomainListCard.jsx';


const DomainListView = (props) => {
    const { classes, rules, onChange, openDialog, redirect, isInvalidAttribute, isInvalidDataset, group_by_datasource, redirectData, dq_score_threshold } = props;

    const openPreviewDialog = (event, rule, type, contenttype) => {
        event.stopPropagation();
        openDialog(rule, type, contenttype);
    };


    return (
        <Grid>
            <Grid container className={classes.listSection}>
                <Grid item className={classes.gridNameContainer}>
                    <Typography>
                        Datasource / Dataset / Attributes
                    </Typography>
                </Grid>
                <Grid item className={classes.countContainer}>
                    <Typography>
                        Total Records
                    </Typography>
                </Grid>
                <Grid item className={classes.countContainer}>
                    <Typography>
                        Invalid Records
                    </Typography>
                </Grid>
                <Grid item className={classes.countContainer}>
                    <Typography>
                        Valid Records
                    </Typography>
                </Grid>
                <Grid item className={classes.gridNameContainer}>
                    <Typography>
                        DQScore
                    </Typography>
                </Grid>
                <Grid item className={classes.gridNameContainer}>
                    <Typography>
                        Weightage
                    </Typography>
                </Grid>
                <Grid item xs={1} className={classes.countContainer} >
                    <Typography>
                        Actions
                    </Typography>
                </Grid>
            </Grid>
            <Grid container className={classes.domainListContainer}>
                {
                    !group_by_datasource &&
                    <Grid className={classes.domainListSection}>
                        {
                            rules && rules.map((datasource, datasourceIndex) =>
                                <Accordion
                                    style={{ margin: '0px' }}
                                    key={datasourceIndex}
                                    defaultExpanded={false}
                                // defaultExpanded={(selectedRule.source_id === datasource.source_id) || (datasourceIndex === 0 && !selectedRule.source_id)}
                                >
                                    <Paper>
                                        {
                                            datasource && datasource.datasets && datasource.datasets.slice(0, 15).map((dataset, datasetIndex) =>
                                                <Accordion
                                                    className={(datasetIndex !== 0 || datasourceIndex > 0) && classes.borderTopColor}
                                                    key={datasetIndex}
                                                    defaultExpanded={false}
                                                // defaultExpanded={(selectedRule.dataset_id === dataset.dataset_id) || (datasetIndex === 0 && !selectedRule.dataset_id)}
                                                >
                                                    <DomainListDatasetRuleHeader
                                                        header={dataset}
                                                        datasource={datasource.name}
                                                        download
                                                        type="dataset"
                                                        group_by_datasource={group_by_datasource}
                                                        isInvalidDataset={isInvalidDataset}
                                                        redirect={(rule, type) => redirect(rule, type)}
                                                        count={dataset.attributes ? dataset.attributes.length : 0}
                                                        openDialog={(event, rule, type, contenttype) => openPreviewDialog(event, rule, type, contenttype)}
                                                        dq_score_threshold={datasource.dq_score_threshold && Object.keys(datasource.dq_score_threshold).length > 0 ? datasource.dq_score_threshold : dq_score_threshold}
                                                    />
                                                    <Grid className={classes.accordianAttributeContainer}>
                                                        {
                                                            dataset && dataset.attributes.slice(0, 50).map((attribute, attributeIndex) =>
                                                                <Accordion
                                                                    key={attributeIndex}
                                                                    defaultExpanded={false}
                                                                // defaultExpanded={(selectedRule.attribute_id === attribute.attribute_id)}
                                                                >
                                                                    <DomainListRuleHeader
                                                                        header={attribute}
                                                                        redirect={(rule, type) => redirect(rule, type)}
                                                                        type="attribute"
                                                                        count={attribute.rules ? attribute.rules.length : 0}
                                                                        isInvalidAttribute={isInvalidAttribute}
                                                                        redirectData={() => redirectData()}
                                                                        openDialog={(event, rule, type, contenttype) => openPreviewDialog(event, rule, type, contenttype)}
                                                                        dq_score_threshold={datasource.dq_score_threshold && Object.keys(datasource.dq_score_threshold).length > 0 ? datasource.dq_score_threshold : dq_score_threshold}
                                                                    />
                                                                    <Grid>
                                                                        {
                                                                            attribute && attribute.rule_id > 0 && attribute.rules.sort((a, b) => b.is_active - a.is_active).map((rule, index) =>
                                                                                <DomainListCard isInvalidDataset={isInvalidDataset} isInvalidAttribute={isInvalidAttribute} redirect={(rule, type) => redirect(rule, type)} openDialog={(event, rule, type, contenttype) => openPreviewDialog(event, rule, type, contenttype)} key={index} rule={rule} onChange={(property, value) => onChange(property, value, datasourceIndex, datasetIndex, attributeIndex, index)} dq_score_threshold={datasource.dq_score_threshold && Object.keys(datasource.dq_score_threshold).length > 0 ? datasource.dq_score_threshold : dq_score_threshold} />
                                                                            )
                                                                        }
                                                                    </Grid>
                                                                </Accordion>
                                                            )
                                                        }
                                                    </Grid>
                                                </Accordion>
                                            )
                                        }
                                    </Paper>
                                </Accordion>

                            )
                        }
                    </Grid>
                }
                {
                    group_by_datasource &&
                    <Grid className={classes.domainListSection}>
                        {
                            rules && rules.map((datasource, datasourceIndex) =>
                                <Paper key={datasourceIndex}>
                                    <Accordion
                                        className={(datasourceIndex !== 0) && classes.borderTopColor}
                                        key={datasourceIndex}
                                        defaultExpanded={false}
                                    >

                                        <DomainListDatasetRuleHeader
                                            header={datasource}
                                            datasource={datasource.name}
                                            download
                                            type="datasource"
                                            isInvalidDataset={isInvalidDataset}
                                            redirect={(rule, type) => redirect(rule, type)}
                                            count={datasource.datasets ? datasource.datasets.length : 0}
                                            openDialog={(event, rule, type, contenttype) => openPreviewDialog(event, rule, type, contenttype)}
                                            dq_score_threshold={datasource.dq_score_threshold && Object.keys(datasource.dq_score_threshold).length > 0 ? datasource.dq_score_threshold : dq_score_threshold}
                                        />
                                        <Grid className={classes.accordianAttributeContainer}>
                                            {
                                                datasource && datasource.datasets && datasource.datasets.map((dataset, datasetIndex) =>
                                                    <Accordion
                                                        className={(datasetIndex !== 0 || datasourceIndex > 0) && classes.borderTopColor}
                                                        key={datasetIndex}
                                                        defaultExpanded={false}
                                                    // defaultExpanded={(selectedRule.dataset_id === dataset.dataset_id)}
                                                    >
                                                        <DomainListDatasetRuleHeader
                                                            header={dataset}
                                                            datasource={datasource.name}
                                                            download
                                                            type="dataset"
                                                            isInvalidDataset={isInvalidDataset}
                                                            group_by_datasource={group_by_datasource}
                                                            redirect={(rule, type) => redirect(rule, type)}
                                                            count={dataset.attributes ? dataset.attributes.length : 0}
                                                            openDialog={(event, rule, type, contenttype) => openPreviewDialog(event, rule, type, contenttype)}
                                                            dq_score_threshold={datasource.dq_score_threshold && Object.keys(datasource.dq_score_threshold).length > 0 ? datasource.dq_score_threshold : dq_score_threshold}
                                                        />
                                                        <Grid className={classes.accordianAttributeContainer}>
                                                            {
                                                                dataset && dataset.attributes.map((attribute, attributeIndex) =>
                                                                    <Accordion
                                                                        key={attributeIndex}
                                                                        defaultExpanded={false}
                                                                    // defaultExpanded={(selectedRule.attribute_id === attribute.attribute_id)}
                                                                    >
                                                                        <DomainListRuleHeader
                                                                            header={attribute}
                                                                            redirect={(rule, type) => redirect(rule, type)}
                                                                            type="attribute"
                                                                            count={attribute.rules ? attribute.rules.length : 0}
                                                                            isInvalidAttribute={isInvalidAttribute}
                                                                            redirectData={() => redirectData()}
                                                                            openDialog={(event, rule, type, contenttype) => openPreviewDialog(event, rule, type, contenttype)}
                                                                            dq_score_threshold={datasource.dq_score_threshold && Object.keys(datasource.dq_score_threshold).length > 0 ? datasource.dq_score_threshold : dq_score_threshold} />

                                                                        <Grid>
                                                                            {
                                                                                attribute && attribute.rule_id > 0 && attribute.rules.sort((a, b) => b.is_active - a.is_active).map((rule, index) =>
                                                                                    <DomainListCard isInvalidDataset={isInvalidDataset} isInvalidAttribute={isInvalidAttribute} redirect={(rule, type) => redirect(rule, type)} openDialog={(event, rule, type, contenttype) => openPreviewDialog(event, rule, type, contenttype)} key={index} rule={rule} onChange={(property, value) => onChange(property, value, datasourceIndex, datasetIndex, attributeIndex, index)} dq_score_threshold={datasource.dq_score_threshold && Object.keys(datasource.dq_score_threshold).length > 0 ? datasource.dq_score_threshold : dq_score_threshold} />
                                                                                )
                                                                            }
                                                                        </Grid>
                                                                    </Accordion>
                                                                )
                                                            }
                                                        </Grid>
                                                    </Accordion>
                                                )
                                            }
                                        </Grid>
                                    </Accordion>
                                </Paper>
                            )
                        }
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};

DomainListView.propTypes = {
    classes: PropTypes.object,
    rules: PropTypes.array,
    onChange: PropTypes.func,
    openDialog: PropTypes.func,
    redirect: PropTypes.func,
    redirectData: PropTypes.func,
    isInvalidAttribute: PropTypes.bool,
    group_by_datasource: PropTypes.bool,
    isInvalidDataset: PropTypes.bool,
    dq_score_threshold: PropTypes.object
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DomainListView);