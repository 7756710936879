import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import DataPreparationjoinBuilderStyles from './DataPreparationjoinBuilderStyles.jsx';
import operators from './Operator.jsx';
import Styles from '../../layouts/Styles.jsx';
import TextBox from '../TextBox/TextBox.jsx';


const DataPreparationjoinBuilder = (props) => {
    const { classes, theme, datasets, selectedJoin, onFieldChanged, deleteJoin, index } = props;

    const getAllAttributes = (datasetName) => {
        const dataset = datasets.find((dataset) => dataset.name === datasetName);
        return dataset ? dataset.attributes : [];
    };

    const getDatasets = (datasetName) => {
        return datasets.filter((dataset) => dataset.name !== datasetName);
    };

    return (
        <Grid container className={classes.cardContainer}>
            <Grid container justify="flex-end" align="right">
                <IconButton onClick={() => deleteJoin(index)}>
                    <svg version="1.1" id="Layer_1" width="22" height="22" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
                        <g id="Delete">
                            <path fill={theme.palette.primary.main}
                                stroke={theme.palette.primary.main}
                                strokeWidth="0.92"
                                strokeMiterlimit="10"
                                d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                            <path fill={theme.palette.primary.main}
                                stroke={theme.palette.primary.main}
                                strokeWidth="0.92"
                                strokeMiterlimit="10"
                                d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                        </g>
                    </svg>
                </IconButton>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <AutoCompleteInput
                        name="Current Dataset"
                        popperWidth={300}
                        options={getDatasets(selectedJoin.join_dataset ? selectedJoin.join_dataset : "")}
                        value={selectedJoin.current_dataset ? selectedJoin.current_dataset : ''}
                        getOptionLabel={
                            (option) => {
                                if (option && option.name) {
                                    return option.name;
                                }
                                return option;
                            }
                        }
                        getOptionSelected={(option, value) => option.name === value}
                        renderInput={
                            (params) => <TextBox {...params}
                                value={selectedJoin.current_dataset ? selectedJoin.current_dataset : ''}
                                validators={['required']}
                                errorMessages={['Value is required']}
                                className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                placeholder="Current Dataset" />
                        }
                        onChange={(_, selectedValue) => onFieldChanged(selectedValue, "current_dataset", index)}
                        openOnFocus
                        blurOnSelect
                        disableClearable
                        fullWidth
                        forcePopupIcon={false}
                        noOptionsText={""}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AutoCompleteInput
                        name="Current Attribute"
                        popperWidth={300}
                        options={[...getAllAttributes(selectedJoin.current_dataset ? selectedJoin.current_dataset : '')]}
                        value={selectedJoin.current_attribute ? selectedJoin.current_attribute : ''}
                        getOptionLabel={
                            (option) => {
                                if (option && option.name) {
                                    return option.name;
                                }
                                return option;
                            }
                        }
                        getOptionSelected={(option, value) => option.name === value}
                        renderInput={
                            (params) => <TextBox {...params}
                                value={selectedJoin.current_attribute ? selectedJoin.current_attribute : ''}
                                validators={['required']}
                                errorMessages={['Value is required']}
                                className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                placeholder="Current Attribute" />
                        }
                        onChange={(_, selectedValue) => onFieldChanged(selectedValue, "current_attribute", index)}
                        openOnFocus
                        blurOnSelect
                        disableClearable
                        fullWidth
                        forcePopupIcon={false}
                        noOptionsText={""}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AutoCompleteInput
                        name="Condition"
                        popperWidth={300}
                        options={[...operators ? operators : []]}
                        value={selectedJoin.condition ? selectedJoin.condition : ''}
                        getOptionLabel={
                            (option) => {
                                if (option && option.name) {
                                    return option.name;
                                }
                                return option;
                            }
                        }
                        getOptionSelected={(option, value) => option.name === value}
                        renderInput={
                            (params) => <TextBox {...params}
                                value={selectedJoin.condition && selectedJoin.condition.name ? selectedJoin.condition.name : ''}
                                validators={['required']}
                                errorMessages={['Value is required']}
                                className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                placeholder="Condition" />
                        }
                        onChange={(_, selectedValue) => onFieldChanged(selectedValue, "condition", index)}
                        openOnFocus
                        blurOnSelect
                        disableClearable
                        fullWidth
                        forcePopupIcon={false}
                        noOptionsText={""}
                    />
                </Grid>
                <Grid item xs={6} />
                <Grid item xs={6}>
                    <AutoCompleteInput
                        disabled
                        name="Join Dataset"
                        popperWidth={300}
                        options={[]}
                        value={selectedJoin.join_dataset ? selectedJoin.join_dataset : ''}
                        getOptionLabel={
                            (option) => {
                                if (option && option.name) {
                                    return option.name;
                                }
                                return option;
                            }
                        }
                        getOptionSelected={(option, value) => option.name === value}
                        renderInput={
                            (params) => <TextBox {...params}
                                disabled
                                value={selectedJoin.join_dataset ? selectedJoin.join_dataset : ''}
                                validators={['required']}
                                errorMessages={['Value is required']}
                                className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                placeholder="Join Dataset" />
                        }
                        onChange={(_, selectedValue) => onFieldChanged(selectedValue, "join_dataset", index)}
                        openOnFocus
                        blurOnSelect
                        disableClearable
                        fullWidth
                        forcePopupIcon={false}
                        noOptionsText={""}
                    />
                </Grid>
                <Grid item xs={6}>
                    <AutoCompleteInput
                        name="Join Attribute"
                        popperWidth={300}
                        options={[...getAllAttributes(selectedJoin.join_dataset ? selectedJoin.join_dataset : '')]}
                        value={selectedJoin.join_attribute ? selectedJoin.join_attribute : ''}
                        getOptionLabel={
                            (option) => {
                                if (option && option.name) {
                                    return option.name;
                                }
                                return option;
                            }
                        }
                        getOptionSelected={(option, value) => option.name === value}
                        renderInput={
                            (params) => <TextBox {...params}
                                value={selectedJoin.join_attribute ? selectedJoin.join_attribute : ''}
                                validators={['required']}
                                errorMessages={['Value is required']}
                                className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                placeholder="Current Attribute" />
                        }
                        onChange={(_, selectedValue) => onFieldChanged(selectedValue, "join_attribute", index)}
                        openOnFocus
                        blurOnSelect
                        disableClearable
                        fullWidth
                        forcePopupIcon={false}
                        noOptionsText={""}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

DataPreparationjoinBuilder.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    datasets: PropTypes.array,
    selectedJoin: PropTypes.object,
    onFieldChanged: PropTypes.func,
    deleteJoin: PropTypes.func,
    index: PropTypes.number
};

export default withStyles((theme) => ({
    ...DataPreparationjoinBuilderStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DataPreparationjoinBuilder);