import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, FormLabel } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import DashboardChartTile from '../Dashboard/DashboardChartTile.jsx';
import UserAutoComplete from '../UserAutoComplete/UserAutoComplete.jsx';
import ActionItemStyles from '../ActionItem/ActionItemStyles.jsx';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { getConversationMetricAnalysis } from '../../actions/scheduleActions';
import Loader from '../../components/Loaders/Loader.jsx';
import classNames from 'classnames';

const ConversationMetrics = (props) => {
    const { classes, userList, users, onChangeFilter, isLoading } = props;
    const [userInput, setUserInput] = useState('');
    const [analysis, setAnalysis] = useState({});
    const [metrics, setMetrics] = useState({ new: 0, older: 0, all: 0, lastMonth: 0 });
    const dispatch = useDispatch();


    const getAnalysis = useCallback(() => {
        const lastMonthEnd = moment(new Date()).subtract(1, 'months').endOf('month').format('MM-DD-YYYY');
        const lastMonthStart = moment(new Date()).subtract(1, 'months').startOf('month').format('MM-DD-YYYY');
        const olderMonthEnd = moment(new Date(lastMonthEnd)).subtract(1, 'months').endOf('month').format('MM-DD-YYYY');
        dispatch(getConversationMetricAnalysis()).then((response) => {
            if (response && Object.keys(response).length > 0) {
                if (response.analysis) {
                    const timelineAnalysis = {
                        new: [],
                        all: [],
                        month: [],
                        older: []
                    };

                    for (const analysis of response.analysis) {
                        timelineAnalysis.new.push(analysis.conversation_new_analysis);
                        timelineAnalysis.all.push(analysis.conversation_analysis);
                    }
                    timelineAnalysis.month = timelineAnalysis.all.filter((data) => new Date(data.date) >= new Date(lastMonthStart) && new Date(data.date) <= new Date(lastMonthEnd));
                    timelineAnalysis.older = timelineAnalysis.all.filter((data) => new Date(data.date) <= new Date(olderMonthEnd));
                    setAnalysis({ ...timelineAnalysis });
                }
                if (response.metric && response.metric.length > 0) {
                    const newConversation = response.metric.filter((data) => !data.is_read).length;
                    const month = response.metric.filter((data) => new Date(data.created_date) >= new Date(lastMonthStart) && new Date(data.created_date) <= new Date(lastMonthEnd)).length;
                    const older = response.metric.filter((data) => new Date(data.created_date) <= new Date(olderMonthEnd)).length;
                    const metrics = {
                        new: newConversation, older: older, all: response.metric.length, lastMonth: month
                    };
                    setMetrics({ ...metrics });
                }
            }
        });
    }, [dispatch]);

    useEffect(() => {
        setTimeout(() => {
            getAnalysis();
        }, 0);
    }, [getAnalysis]);

    const onChangeUser = (event) => {
        onChangeFilter("users", event);
        setUserInput('');
    };

    const onInputValueOnChange = (value) => {
        setUserInput(value);
    };


    return (
        <Grid container spacing={0} xs={12} justify="space-between">
            <Grid item className={classNames(classes.cardSection, classes.relative)} xs={2.4}>
                {isLoading && <Loader />}
                <Grid className={classes.card}>
                    <FormLabel>
                        Users
                    </FormLabel>
                    <Grid className={classes.marginTop10}>
                        <UserAutoComplete
                            availableList={userList}
                            selectedList={users}
                            displayValue="name"
                            onChange={onChangeUser}
                            onInputValueChange={onInputValueOnChange}
                            inputValue={userInput}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item className={classNames(classes.cardSection, classes.relative)} xs={2.4}>
                {isLoading && <Loader />}
                <DashboardChartTile chartColor="#FF5C5C" chartClassName={"conversation-new-timeline"} type="New" count={metrics.new} analysis={analysis.new ? analysis.new : []} />
            </Grid>
            <Grid item className={classNames(classes.cardSection, classes.relative)} xs={2.4}>
                {isLoading && <Loader />}
                <DashboardChartTile chartColor="#FDAC42" chartClassName={"conversation-month-timeline"} type="Last Month" count={metrics.lastMonth} analysis={analysis.month ? analysis.month : []} />
            </Grid>
            <Grid item className={classNames(classes.cardSection, classes.relative)} xs={2.4}>
                {isLoading && <Loader />}
                <DashboardChartTile chartColor="#54DF9A" chartClassName={"conversation-older-timeline"} type="Older" count={metrics.older} analysis={analysis.older ? analysis.older : []} />
            </Grid>
            <Grid item className={classNames(classes.cardSection, classes.relative)} xs={2.4}>
                {isLoading && <Loader />}
                <DashboardChartTile chartColor="#CBECFC" chartClassName={"conversation-all-timeline"} type="All" count={metrics.all} analysis={analysis.all ? analysis.all : []} />
            </Grid>
        </Grid>
    );
};

ConversationMetrics.propTypes = {
    classes: PropTypes.object,
    userList: PropTypes.array,
    users: PropTypes.array,
    onChangeFilter: PropTypes.func,
    isLoading: PropTypes.bool
};

export default withStyles((theme) => ({
    ...ActionItemStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ConversationMetrics);