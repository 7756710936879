import React, { useCallback, useState, useEffect } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import DataTable from '../DataTable/DataTable.jsx';
import { previewTransformation } from '../../actions/dataPreparationActions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Loaders/Loader.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import TransformationsModal from '../Transformations/TransformationsModal.jsx';

const DataPreparationDataTable = (props) => {
    const { transformId, classes, search } = props;
    const [isLoading, setLoading] = useState(true);
    const [headers, setHeaders] = useState([]);
    const [previewData, setPreviewData] = useState([]);
    const [selectAttribute, setSelecteAttribute] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [attributes, setAttributes] = useState([]);
    const transformationPreview = useSelector(({ dataPreparation }) => dataPreparation.previewData);
    const dispatch = useDispatch();

    const enableFunctionDialog = useCallback((event, attribute) => {
        setSelecteAttribute({ ...attribute });
        setAnchorEl(event.target);
    }, []);


    const getHeaders = useCallback((columns) => {
        const columnList = columns.map((column) => {
            return {
                name: column && column.name ? column.name : column,
                datatype: column && column.datatype ? column.datatype : "Text",
                isTransformAttribute: Boolean(column.is_transformed_attribute)
            };
        });
        setAttributes([...columnList]);
        setHeaders([...columnList]);
    }, []);

    useEffect(() => {
        if (transformationPreview && Object.keys(transformationPreview).length > 0 && transformationPreview.data) {
            setPreviewData([...transformationPreview.data]);
            getHeaders([...transformationPreview.columns]);
        }
    }, [getHeaders, transformationPreview]);

    const getPreview = useCallback(() => {
        const transform = {
            'transformed_dataset_id': transformId,
            'preview_source_data': true
        };
        dispatch(previewTransformation(transform)).then((response) => {
            if (response && Object.keys(response).length > 0 && response.data) {
                setPreviewData([...response.data]);
                getHeaders([...response.columns]);
            }
            setLoading(false);
        });
    }, [dispatch, transformId, getHeaders]);

    useEffect(() => {
        if (!transformId) {
            return;
        }
        getPreview();
    }, [getPreview, transformId]);

    const columns = search.length !== 0 ? headers.filter((header) => header.name.toLowerCase().includes(search.toLowerCase())) : headers;
    return (
        <Grid className={classes.container} container>
            <DataTable
                headers={columns}
                previewData={previewData}
                enableFunctionDialog={(event, attribute) => enableFunctionDialog(event, attribute)} />
            {
                columns.length === 0 && !isLoading &&
                <NoResultFound />
            }
            {
                isLoading &&
                <Loader />
            }
            <TransformationsModal
                anchorEl={anchorEl}
                transformId={transformId ? transformId : 0}
                attributes={[...attributes]}
                isOpen={(selectAttribute && Object.keys(selectAttribute).length > 0)}
                onClose={() => setSelecteAttribute(null)}
                sourceAttribute={{ ...selectAttribute }}
            />
        </Grid>
    );
};


DataPreparationDataTable.propTypes = {
    transformId: PropTypes.number,
    classes: PropTypes.object,
    search: PropTypes.string
};

export default (withStyles((theme) => ({
    ...Styles(theme)
}))(DataPreparationDataTable));