import { modelActions } from '../constants/actionTypes/modelActionTypes';

const initialState = {
    isLoading: false,
    enrichment: [],
    selectedGlossary: {},
    breadCrumbs: [],
    filters: {}
};

export const modelReducer = (state = initialState, action) => {
    if (!action || (action && !action.type)) { return state; }
    switch (action.type) {
        case modelActions.CREATE_SEMANTIC_MODEL_REQUEST:
        case modelActions.CREATE_SEMANTIC_MODEL_SUCCESS:
        case modelActions.CREATE_SEMANTIC_MODEL_FAILURE:
            return {
                ...state
            };
        case modelActions.GET_ALL_ENRICHMENT_REQUEST:
        case modelActions.GET_ALL_ENRICHMENT_FAILURE:
            return {
                ...state,
                enrichment: []
            };
        case modelActions.GET_ALL_ENRICHMENT_SUCCESS:
            return {
                ...state,
                enrichment: action.responseData ? action.responseData : []
            };
        case modelActions.SELECT_GLOSSARY:
            return {
                ...state,
                selectedGlossary: action.data ? action.data : {}
            };
        case modelActions.GET_BREAD_CRUMBS_REQUEST:
        case modelActions.GET_BREAD_CRUMBS_FAILURE:
            return {
                ...state,
                breadCrumbs: []
            };
        case modelActions.GET_BREAD_CRUMBS_SUCCESS:
            return {
                ...state,
                breadCrumbs: action.responseData ? action.responseData : []
            };
        case modelActions.SET_SEMANTIC_FILTER:
            return {
                ...state,
                filters: { ...action.filters }
            };
        default:
            return state;
    }
};