import React, { useState, useCallback } from 'react';
import { Grid, withStyles, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import ActionComments from './ActionComments.jsx';
import Styles from '../../layouts/Styles.jsx';
import ActionItemStyles from './ActionItemStyles.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { createActionItem, getActionItemCount, getDomainActionItemCount } from '../../actions/notificationActions';
import CloseIcon from '@material-ui/icons/Close';
import { getAssignString } from '../../helpers/appHelpers';

const AddActionItem = (props) => {
    const { classes, discard, screenImage } = props;
    const [actions, setActions] = useState([]);
    const [open, setPopover] = useState(false);
    const [actionIndex, setSelectedIndex] = useState(0);
    const dispatch = useDispatch();
    const datasetId = useSelector(({ dataset }) => dataset.selectedDatasetId);
    const datasourceId = useSelector(({ datasource }) => datasource.selectedDatasourceId);
    const domainId = useSelector(({ domain }) => domain.selectedDomainId);
    const actionCount = useSelector(({ notification }) => notification.count);
    const [count, setCount] = useState(actionCount || actionCount === 0 ? actionCount + 1 : 0);
    const user = useSelector(({ account }) => account.user);
    const datasourceTabIndex = useSelector(({ datasource }) => datasource.datasourceTabIndex);
    const datasetTabIndex = useSelector(({ dataset }) => dataset.datasetTabIndex);
    const propertiesTabIndex = useSelector(({ dataset }) => dataset.propertiesTabIndex);
    const selectedAttribute = useSelector(({ dataset }) => dataset.selectedAttribute);

    const addActions = useCallback((event) => {
        if (!open) {
            const bodyContent = document.querySelector('body');
            const screen = {
                width: bodyContent.clientWidth,
                height: bodyContent.clientHeight
            };
            actions.push({
                "count": count,
                "comments": [{ "is_first_comment": true, comment: "" }],
                "users": [{ "user": user.id ? user.id : 0, "is_owner": true }],
                "position": { left: event.clientX, top: event.clientY },
                "screen": screen,
                "priority": 'High',
                "status": "new"
            });
            setPopover(true);
            setActions([...actions]);
            setSelectedIndex(actions.length - 1);
        }
    }, [actions, count, open, user.id]);

    const discardComment = useCallback(() => {
        actions.splice(actions.length - 1);
        setActions([...actions]);
        setPopover(false);
        if (window.location.href.includes('domain')) {
            dispatch(getDomainActionItemCount(domainId));
        } else {
            dispatch(getActionItemCount(datasourceId, window.location.href.includes('dataset') ? datasetId : 0));
        }

    }, [actions, datasetId, datasourceId, dispatch, domainId]);

    const onChangeComment = useCallback((property, value, index) => {
        if (property === "priority" || property === "assigneduser") {
            actions[actionIndex][property] = value;
        } else {
            actions[actionIndex].comments[index][property] = value;
        }
        setActions([...actions]);
    }, [actionIndex, actions]);

    const postComment = useCallback((assignUsers) => {
        const comments = actions[actionIndex].comments.map((commentInfo) => {
            return {
                ...commentInfo, comment: getAssignString(commentInfo.comment)
            };
        });
        let users = [...actions[actionIndex].users, ...assignUsers];
        users = [...new Map(users.map((item) => [item.user, item])).values()];
        let type = "datasource";
        if (window.location.href.includes('dataset')) {
            type = "dataset";
        } else if (window.location.href.includes('domain')) {
            type = "domain";
        }
        const dataset_id = window.location.href.includes('dataset') ? datasetId : null;
        const requestParams = {
            ...actions[actionIndex],
            "asset_path": screenImage,
            "type": type,
            "source_id": window.location.href.includes('domain') ? null : datasourceId,
            "dataset_id": dataset_id,
            "domain_id": window.location.href.includes('domain') ? domainId : null,
            "users": users,
            "comments": comments,
            "tab_index": dataset_id && dataset_id !== null ? datasetTabIndex : datasourceTabIndex,
            "properties_tab_index": propertiesTabIndex,
            "selected_attribute": selectedAttribute
        };
        setCount(count + 1);

        dispatch(createActionItem(requestParams));
        setPopover(false);
    }, [actionIndex, actions, count, datasetId, datasetTabIndex, datasourceId, datasourceTabIndex, dispatch, domainId, propertiesTabIndex, screenImage, selectedAttribute]);

    return (
        <Grid className={classes.addActionContainer} style={{ zIndex: open ? 1 : 1301 }} onClick={(event) => addActions(event)}>
            <IconButton onClick={() => discard()} className={classes.actionCloseBtn}>
                <CloseIcon />
            </IconButton>
            {
                actions.map((action, index) =>
                    <div className={classes.actionDot} key={index} style={{ left: action.position.left, top: action.position.top }}>
                        {action.count ? action.count : 1}
                    </div>
                )
            }
            {
                open &&
                <ActionComments
                    open={open}
                    closePopover={() => discardComment()}
                    action={actions[actionIndex] ? actions[actionIndex] : {}}
                    onChangeComment={(property, value, index) => onChangeComment(property, value, index)}
                    postComment={(userList) => postComment(userList)}
                />
            }
        </Grid>
    );
};

AddActionItem.propTypes = {
    classes: PropTypes.object,
    discard: PropTypes.func,
    screenImage: PropTypes.string
};


export default withStyles((theme) => ({
    ...ActionItemStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(AddActionItem);