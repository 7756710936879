import palette from "../../theme/palette";

const ColorConst = palette.Lineage;

const LineageTableauStyle = {
    svgStyle: {
        width: '100%',
        height: '100%'
    },
    headerImage: {
        width: '1rem'
    },
    headerText: {
        paddingLeft: '0.5rem',
        wordWrap: "anywhere",
        maxWidth: '10rem'
    },
    tableContainer: {
        border: '1px solid #c8cbcc'
    },
    tableHeader: {
        display: 'flex',
        alignItems: 'center',
        wordWrap: "anywhere",
        borderBottom: '1px solid #c8cbcc',
        padding: '.5rem',
        backgroundColor: '#f6f8f9'
    },
    listParent: {
        backgroundColor: '#ffffff'
    },
    listContainer: {
        listStyle: 'none',
        paddingInlineStart: '0px',
        padding: '.5rem',
        margin: '0px'
    },
    listItem: (selected) => ({
        backgroundColor: {
            1: '#fcf3f0',
            2: '#f1f8fe',
            3: ColorConst.SearchSelected
        }[selected],
        border: `1px solid ${{
            1: '#ec9073',
            2: '#71bdf0',
            3: '#ec9073'
        }[selected]}`,
        borderRadius: '10px',
        marginTop: '1rem',
        marginBottom: '1rem',
        padding: '.5rem',
        cursor: 'pointer'
    }),
    optionsContainer: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    childrenItemsContainer: {
        listStyle: 'none',
        padding: '0px'
    },
    childrenItem: (selected) => ({
        backgroundColor: {
            1: '#fcf3f0',
            2: '#daf7e9',
            3: ColorConst.SearchSelected
        }[selected],
        border: `1px solid ${{
            1: '#ec9073',
            2: '#71bdf0',
            3: '#ec9073'
        }[selected]}`,
        padding: '0.5rem',
        // border: '1px solid #9affce',
        borderRadius: '10px',
        // backgroundColor: '#daf7e9',
        margin: '0.5rem 0px 0.5rem'
    }),
    parentNameContainer: {
        display: 'flex',
        alignItems: 'center',
        wordWrap: "anywhere",
        justifyContent: "space-between"
    },
    actionImage: {
        width: '1rem',
        cursor: 'pointer',
        marginLeft: '1px'
    },
    actionContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    embededDatasourceContainer: {
        paddingTop: '1rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        display: 'block',
        fontWeight: 'bold'
    },
    noResultFound: {
        height: "100%",
        position: "absolute",
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        '.MuiTypographyRoot': {
            marginTop: '15px',
            color: 'gray',
            overflowY: 'hidden'
        }
    }
};

export default LineageTableauStyle;