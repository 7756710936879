import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { appConstants } from '../../constants/appConstants.js';
import { Grid, withStyles } from '@material-ui/core';
import SemanticModelFilter from '../../components/SemanticModel/SemanticModelFilter.jsx';
import SemanticGlossary from '../../components/SemanticGlossary/SemanticGlossary.jsx';
import Styles from '../../layouts/Styles.jsx';
import { importModel, setSemanticFilter } from '../../actions/modelActions';
import { useDispatch, useSelector } from 'react-redux';
import Master from '../../containers/Master/Master.jsx';


const SemanticModel = (props) => {
    const { history, classes, getPageTitle } = props;
    const presistFilters = useSelector(({ model }) => model.filters);
    const defaultFilters = Object.keys(presistFilters).length ? presistFilters : {
        search: "",
        sort: '',
        type: '',
        status: '',
        tags: [],
        viewType: appConstants.semanticTypes[0]
    };
    const [filters, setFilters] = useState({ ...defaultFilters });
    const dispatch = useDispatch();

    useEffect(() => {
        getPageTitle('Semantic Model');
    });

    const createImportSemanticModel = useCallback((modelData) => {
        const requestParams = {
            'model_data': modelData
        };
        dispatch(importModel(requestParams)).then((response) => {
            if (response) {
                history.push({ pathname: `/model` });
            }
        });
    }, [dispatch, history]);

    const importSemanticModel = useCallback((files) => {
        const reader = new FileReader();
        reader.onload = (file) => {
            const modelData = JSON.parse(file.target.result);
            if (modelData) {
                createImportSemanticModel(modelData);
            }
        };
        reader.readAsText(files[0]);
    }, [createImportSemanticModel]);


    const onChangeFilter = useCallback((property, value) => {
        filters[property] = value;
        setFilters({ ...filters });
        dispatch(setSemanticFilter({ ...filters }));
    }, [dispatch, filters]);

    const resetFilter = useCallback(() => {
        setFilters({
            ...filters,
            search: "",
            sort: '',
            type: '',
            status: '',
            tags: []
        });
        dispatch(setSemanticFilter({
            ...filters,
            search: "",
            sort: '',
            type: '',
            status: '',
            tags: []
        }));
    }, [dispatch, filters]);

    return (
        <Grid className={classes.container}>
            <SemanticModelFilter
                filters={filters}
                history={history}
                changeView={(value) => onChangeFilter("viewType", value)}
                viewType={filters.viewType}
                importModel={(files) => importSemanticModel(files)}
                onChangeFilter={onChangeFilter}
                resetFilter={resetFilter}
            />
            <Grid className={classes.container}>
                {
                    appConstants.semanticTypes[0] === filters.viewType ?
                        <SemanticGlossary filters={filters} history={history} /> :
                        <Master
                            filters={filters}
                            history={history}
                        />
                }
            </Grid>
        </Grid>
    );
};

SemanticModel.propTypes = {
    history: PropTypes.object,
    classes: PropTypes.object,
    getPageTitle: PropTypes.func
};

export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(SemanticModel);