export const accountActions = {
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_FAILURE: 'LOGIN_FAILURE',

	GET_USER_REQUEST: 'GET_USER_REQUEST',
	GET_USER_SUCCESS: 'GET_USER_SUCCESS',
	GET_USER_FAILURE: 'GET_USER_FAILURE',

	GET_FORGOT_PASSWORD_REQUEST: 'GET_FORGOT_PASSWORD_REQUEST',
	GET_FORGOT_PASSWORD_SUCCESS: 'GET_FORGOT_PASSWORD_SUCCESS',
	GET_FORGOT_PASSWORD_FAILURE: 'GET_FORGOT_PASSWORD_FAILURE',

	GET_VERIFY_TOKEN_REQUEST: 'GET_VERIFY_TOKEN_REQUEST',
	GET_VERIFY_TOKEN_SUCCESS: 'GET_VERIFY_TOKEN_SUCCESS',
	GET_VERIFY_TOKEN_FAILURE: 'GET_VERIFY_TOKEN_FAILURE',

	RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
	RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
	RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

	GET_ORGANIZATION_USERS_REQUEST: 'GET_ORGANIZATION_USERS_REQUEST',
	GET_ORGANIZATION_USERS_SUCCESS: 'GET_ORGANIZATION_USERS_SUCCESS',
	GET_ORGANIZATION_USERS_FAILURE: 'GET_ORGANIZATION_USERS_FAILURE',

	LOGOUT: 'LOGOUT',
	USER_ACTIVATE_REQUEST: 'USER_ACTIVATE_REQUEST',
	USER_ACTIVATE_SUCCESS: 'USER_ACTIVATE_SUCCESS',
	USER_ACTIVATE_FAILURE: 'USER_ACTIVATE_FAILURE',

	GET_USERS_LIST_REQUEST: 'GET_USERS_LIST_REQUEST',
	GET_USERS_LIST_SUCCESS: 'GET_USERS_LIST_SUCCESS',
	GET_USERS_LIST_FAILURE: 'GET_USERS_LIST_FAILURE',

	INVITE_USER_REQUEST: 'INVITE_USER_REQUEST',
	INVITE_USER_SUCCESS: 'INVITE_USER_SUCCESS',
	INVITE_USER_FAILURE: 'INVITE_USER_FAILURE',

	UPDATE_USER: 'UPDATE_USER',

	DISABLE_ROLE_PERMISSION: 'DISABLE_ROLE_PERMISSION'

};