export const datasetActions = {
    SET_SELECTED_DATASET_ID: 'SET_SELECTED_DATASET_ID',
    SET_SELECTED_DATASET: 'SET_SELECTED_DATASET',

    GET_DATASET_DETAIL_REQUEST: 'GET_DATASET_DETAIL_REQUEST',
    GET_DATASET_DETAIL_SUCCESS: 'GET_DATASET_DETAIL_SUCCESS',
    GET_DATASET_DETAIL_FAILURE: 'GET_DATASET_DETAIL_FAILURE',

    UPDATE_DATASET_DETAIL_REQUEST: 'UPDATE_DATASET_DETAIL_REQUEST',
    UPDATE_DATASET_DETAIL_SUCCESS: 'UPDATE_DATASET_DETAIL_SUCCESS',
    UPDATE_DATASET_DETAIL_FAILURE: 'UPDATE_DATASET_DETAIL_FAILURE',

    UPDATE_TABLEAU_ITEM_DETAIL_REQUEST: 'UPDATE_TABLEAU_ITEM_DETAIL_REQUEST',
    UPDATE_TABLEAU_ITEM_DETAIL_SUCCESS: 'UPDATE_TABLEAU_ITEM_DETAIL_SUCCESS',
    UPDATE_TABLEAU_ITEM_DETAIL_FAILURE: 'UPDATE_TABLEAU_ITEM_DETAIL_FAILURE',

    UPDATE_DATASET_ICON_REQUEST: 'UPDATE_DATASET_ICON_REQUEST',
    UPDATE_DATASET_ICON_SUCCESS: 'UPDATE_DATASET_ICON_SUCCESS',
    UPDATE_DATASET_ICON_FAILURE: 'UPDATE_DATASET_ICON_FAILURE',

    GET_PREVIEW_DATA_REQUEST: 'GET_PREVIEW_DATA_REQUEST',
    GET_PREVIEW_DATA_SUCCESS: 'GET_PREVIEW_DATA_SUCCESS',
    GET_PREVIEW_DATA_FAILURE: 'GET_PREVIEW_DATA_FAILURE',

    GET_PROPERTIES_REQUEST: 'GET_PROPERTIES_REQUEST',
    GET_PROPERTIES_SUCCESS: 'GET_PROPERTIES_SUCCESS',
    GET_PROPERTIES_FAILURE: 'GET_PROPERTIES_FAILURE',

    GET_DATASET_INFO_REQUEST: 'GET_DATASET_INFO_REQUEST',
    GET_DATASET_INFO_SUCCESS: 'GET_DATASET_INFO_SUCCESS',
    GET_DATASET_INFO_FAILURE: 'GET_DATASET_INFO_FAILURE',

    DELETE_DATASET_REQUEST: 'DELETE_DATASET_REQUEST',
    DELETE_DATASET_SUCCESS: 'DELETE_DATASET_SUCCESS',
    DELETE_DATASET_FAILURE: 'DELETE_DATASET_FAILURE',
    DELETE_DATASET_SHARE: 'DELETE_DATASET_SHARE',

    GET_DATASET_SHARE_REQUEST: 'GET_DATASET_SHARE_REQUEST',
    GET_DATASET_SHARE_SUCCESS: 'GET_DATASET_SHARE_SUCCESS',
    GET_DATASET_SHARE_FAILURE: 'GET_DATASET_SHARE_FAILURE',

    CREATE_DATASET_SHARE_REQUEST: 'CREATE_DATASET_SHARE_REQUEST',
    CREATE_DATASET_SHARE_SUCCESS: 'CREATE_DATASET_SHARE_SUCCESS',
    CREATE_DATASET_SHARE_FAILURE: 'CREATE_DATASET_SHARE_FAILURE',

    SET_PROPERTY_SLIDER_INDEX: 'SET_PROPERTY_SLIDER_INDEX',
    SET_PROPERTY_VIEW: 'SET_PROPERTY_VIEW',

    UPDATE_PROPERTY_REQUEST: 'UPDATE_PROPERTY_REQUEST',
    UPDATE_PROPERTY_SUCCESS: 'UPDATE_PROPERTY_SUCCESS',
    UPDATE_PROPERTY_FAILURE: 'UPDATE_PROPERTY_FAILURE',

    GET_DATASET_RULES_REQUEST: 'GET_DATASET_RULES_REQUEST',
    GET_DATASET_RULES_SUCCESS: 'GET_DATASET_RULES_SUCCESS',
    GET_DATASET_RULES_FAILURE: 'GET_DATASET_RULES_FAILURE',

    UPDATE_DATASET_RULES_REQUEST: 'UPDATE_DATASET_RULES_REQUEST',
    UPDATE_DATASET_RULES_SUCCESS: 'UPDATE_DATASET_RULES_SUCCESS',
    UPDATE_DATASET_RULES_FAILURE: 'UPDATE_DATASET_RULES_FAILURE',

    UPDATE_ATTRIBUTES_REQUEST: 'UPDATE_ATTRIBUTES_REQUEST',
    UPDATE_ATTRIBUTES_SUCCESS: 'UPDATE_ATTRIBUTES_SUCCESS',
    UPDATE_ATTRIBUTES_FAILURE: 'UPDATE_ATTRIBUTES_FAILURE',

    FILTER_INPUT_REQUEST: 'FILTER_INPUT_REQUEST',
    FILTER_INPUT_SUCCESS: 'FILTER_INPUT_SUCCESS',
    FILTER_INPUT_FAILURE: 'FILTER_INPUT_FAILURE',

    UPDATE_RULE_ATTRIBUTES: 'UPDATE_RULE_ATTRIBUTES',

    UPDATE_LIST_PROPERTY_REQUEST: 'UPDATE_LIST_PROPERTY_REQUEST',
    UPDATE_LIST_PROPERTY_SUCCESS: 'UPDATE_LIST_PROPERTY_SUCCESS',
    UPDATE_LIST_PROPERTY_FAILURE: 'UPDATE_LIST_PROPERTY_FAILURE',

    UPDATE_LIST_DATASET_RULES_REQUEST: 'UPDATE_LIST_DATASET_RULES_REQUEST',
    UPDATE_LIST_DATASET_RULES_SUCCESS: 'UPDATE_LIST_DATASET_RULES_SUCCESS',
    UPDATE_LIST_DATASET_RULES_FAILURE: 'UPDATE_LIST_DATASET_RULES_FAILURE',

    UPDATE_DATASET_PROPERTIES: 'UPDATE_DATASET_PROPERTIES',
    UPDATE_LEARNING_ATTRIBUTE: 'UPDATE_LEARNING_ATTRIBUTE',

    GET_DATASET_NAMES_REQUEST: 'GET_DATASET_NAMES_REQUEST',
    GET_DATASET_NAMES_SUCCESS: 'GET_DATASET_NAMES_SUCCESS',
    GET_DATASET_NAMES_FAILURE: 'GET_DATASET_NAMES_FAILURE',

    GET_USER_DATASETS_REQUEST: 'GET_USER_DATASETS_REQUEST',
    GET_USER_DATASETS_SUCCESS: 'GET_USER_DATASETS_SUCCESS',
    GET_USER_DATASETS_FAILURE: 'GET_USER_DATASETS_FAILURE',

    GET_USER_ATTRIBUTES_REQUEST: 'GET_USER_ATTRIBUTES_REQUEST',
    GET_USER_ATTRIBUTES_SUCCESS: 'GET_USER_ATTRIBUTES_SUCCESS',
    GET_USER_ATTRIBUTES_FAILURE: 'GET_USER_ATTRIBUTES_FAILURE',

    UPDATE_LEARNING_ATTIBUTE_PROPERTY: 'UPDATE_LEARNING_ATTIBUTE_PROPERTY',

    GET_ATTRIBUTES_REQUEST: 'GET_ATTRIBUTES_REQUEST',
    GET_ATTRIBUTES_SUCCESS: 'GET_ATTRIBUTES_SUCCESS',
    GET_ATTRIBUTES_FAILURE: 'GET_ATTRIBUTES_FAILURE',

    GET_ORGANIZATION_DATASETS_REQUEST: 'GET_ORGANIZATION_DATASETS_REQUEST',
    GET_ORGANIZATION_DATASETS_SUCCESS: 'GET_ORGANIZATION_DATASETS_SUCCESS',
    GET_ORGANIZATION_DATASETS_FAILURE: 'GET_ORGANIZATION_DATASETS_FAILURE',

    VALIDATE_QUERY_REQUEST: 'VALIDATE_QUERY_REQUEST',
    VALIDATE_QUERY_SUCCESS: 'VALIDATE_QUERY_SUCCESS',
    VALIDATE_QUERY_FAILURE: 'VALIDATE_QUERY_FAILURE',

    GET_DATASET_ATTRIBUTE_REQUEST: 'GET_DATASET_ATTRIBUTE_REQUEST',
    GET_DATASET_ATTRIBUTE_SUCCESS: 'GET_DATASET_ATTRIBUTE_SUCCESS',
    GET_DATASET_ATTRIBUTE_FAILURE: 'GET_DATASET_ATTRIBUTE_FAILURE',

    GET_DRIFT_CUSTOM_RULES_REQUEST: 'GET_DRIFT_CUSTOM_RULES_REQUEST',
    GET_DRIFT_CUSTOM_RULES_SUCCESS: 'GET_DRIFT_CUSTOM_RULES_SUCCESS',
    GET_DRIFT_CUSTOM_RULES_FAILURE: 'GET_DRIFT_CUSTOM_RULES_FAILURE',

    GET_DATASET_PROPERTIES_TAB: 'GET_DATASET_PROPERTIES_TAB',

    GET_ORGANIZATION_DATASET_LIST_REQUEST: 'GET_ORGANIZATION_DATASET_LIST_REQUEST',
    GET_ORGANIZATION_DATASET_LIST_SUCCESS: 'GET_ORGANIZATION_DATASET_LIST_SUCCESS',
    GET_ORGANIZATION_DATASET_LIST_FAILURE: 'GET_ORGANIZATION_DATASET_LIST_FAILURE',

    GET_ORGANIZATION_ATTRIBUTE_LIST_REQUEST: 'GET_ORGANIZATION_ATTRIBUTE_LIST_REQUEST',
    GET_ORGANIZATION_ATTRIBUTE_LIST_SUCCESS: 'GET_ORGANIZATION_ATTRIBUTE_LIST_SUCCESS',
    GET_ORGANIZATION_ATTRIBUTE_LIST_FAILURE: 'GET_ORGANIZATION_ATTRIBUTE_LIST_FAILURE',

    GET_ALL_MONITORING_DETAILS: 'GET_ALL_MONITORING_DETAILS',
    GET_ALL_MONITORING_DETAILS_SUCCESS: 'GET_ALL_MONITORING_DETAILS_SUCCESS',
    GET_ALL_MONITORING_DETAILS_FAILURE: 'GET_ALL_MONITORING_DETAILS_FAILURE',

    GET_EXPORT_METRIXS_SETTINGS_REQUEST: 'GET_EXPORT_METRIXS_SETTINGS_REQUEST',
    GET_EXPORT_METRIXS_SETTINGS_SUCCESS: 'GET_EXPORT_METRIXS_SETTINGS_SUCCESS',
    GET_EXPORT_METRIXS_SETTINGS_FAILURE: 'GET_EXPORT_METRIXS_SETTINGS_FAILURE',

    GET_EXPORT_ATTRIBUTE_REQUEST: 'GET_EXPORT_ATTRIBUTE_REQUEST',
    GET_EXPORT_ATTRIBUTE_SUCCESS: 'GET_EXPORT_ATTRIBUTE_SUCCESS',
    GET_EXPORT_ATTRIBUTE_FAILURE: 'GET_EXPORT_ATTRIBUTE_FAILURE',

    SAVE_EXPORT_METRIXS_SETTINGS_REQUEST: 'SAVE_EXPORT_METRIXS_SETTINGS_REQUEST',
    SAVE_EXPORT_METRIXS_SETTINGS_SUCCESS: 'SAVE_EXPORT_METRIXS_SETTINGS_SUCCESS',
    SAVE_EXPORT_METRIXS_SETTINGS_FAILURE: 'SAVE_EXPORT_METRIXS_SETTINGS_FAILURE',

    REMOVE_EXPORT_METRIXS_SETTINGS_REQUEST: 'REMOVE_EXPORT_METRIXS_SETTINGS_REQUEST',
    REMOVE_EXPORT_METRIXS_SETTINGS_SUCCESS: 'REMOVE_EXPORT_METRIXS_SETTINGS_SUCCESS',
    REMOVE_EXPORT_METRIXS_SETTINGS_FAILURE: 'REMOVE_EXPORT_METRIXS_SETTINGS_FAILURE',

    CLEAR_PREVIEW_DATA: 'CLEAR_PREVIEW_DATA',

    GET_PROPERTIES_LIST_REQUEST: 'GET_PROPERTIES_LIST_REQUEST',
    GET_PROPERTIES_LIST_SUCCESS: 'GET_PROPERTIES_LIST_SUCCESS',
    GET_PROPERTIES_LIST_FAILURE: 'GET_PROPERTIES_LIST_FAILURE',

    GET_ATTRIBUTE_PROPERTIES_LIST_REQUEST: 'GET_ATTRIBUTE_PROPERTIES_LIST_REQUEST',
    GET_ATTRIBUTE_PROPERTIES_LIST_SUCCESS: 'GET_ATTRIBUTE_PROPERTIES_LIST_SUCCESS',
    GET_ATTRIBUTE_PROPERTIES_LIST_FAILURE: 'GET_ATTRIBUTE_PROPERTIES_LIST_FAILURE',

    GET_ATTRIBUTE_PROPERTIES_REQUEST: 'GET_ATTRIBUTE_PROPERTIES_REQUEST',
    GET_ATTRIBUTE_PROPERTIES_SUCCESS: 'GET_ATTRIBUTE_PROPERTIES_SUCCESS',
    GET_ATTRIBUTE_PROPERTIES_FAILURE: 'GET_ATTRIBUTE_PROPERTIES_FAILURE',

    GET_ATTRIBUTE_RULES_REQUEST: 'GET_ATTRIBUTE_RULES_REQUEST',
    GET_ATTRIBUTE_RULES_SUCCESS: 'GET_ATTRIBUTE_RULES_SUCCESS',
    GET_ATTRIBUTE_RULES_FAILURE: 'GET_ATTRIBUTE_RULES_FAILURE',

    UPDATE_DATASET_QUERY_REQUEST: 'UPDATE_DATASET_QUERY_REQUEST',
    UPDATE_DATASET_QUERY_SUCCESS: 'UPDATE_DATASET_QUERY_SUCCESS',
    UPDATE_DATASET_QUERY_FAILURE: 'UPDATE_DATASET_QUERY_FAILURE',

    GET_WORKBOOK_ITEMS_REQUEST: 'GET_WORKBOOK_ITEMS_REQUEST',
    GET_WORKBOOK_ITEMS_SUCCESS: 'GET_WORKBOOK_ITEMS_SUCCESS',
    GET_WORKBOOK_ITEMS_FAILURE: 'GET_WORKBOOK_ITEMS_FAILURE',

    GET_WORKBOOK_DATASOURCE_REQUEST: 'GET_WORKBOOK_DATASOURCE_REQUEST',
    GET_WORKBOOK_DATASOURCE_SUCCESS: 'GET_WORKBOOK_DATASOURCE_SUCCESS',
    GET_WORKBOOK_DATASOURCE_FAILURE: 'GET_WORKBOOK_DATASOURCE_FAILURE',

    DELETE_WORKBOOK_ITEMS_REQUEST: 'DELETE_WORKBOOK_ITEMS_REQUEST',
    DELETE_WORKBOOK_ITEMS_SUCCESS: 'DELETE_WORKBOOK_ITEMS_SUCCESS',
    DELETE_WORKBOOK_ITEMS_FAILURE: 'DELETE_WORKBOOK_ITEMS_FAILURE',

    GET_WORKBOOK_INFO_REQUEST: 'GET_WORKBOOK_INFO_REQUEST',
    GET_WORKBOOK_INFO_SUCCESS: 'GET_WORKBOOK_INFO_SUCCESS',
    GET_WORKBOOK_INFO_FAILURE: 'GET_WORKBOOK_INFO_FAILURE',

    GET_EXPORT_RULES_REQUEST: 'GET_EXPORT_RULES_REQUEST',
    GET_EXPORT_RULES_SUCCESS: 'GET_EXPORT_RULES_SUCCESS',
    GET_EXPORT_RULES_FAILURE: 'GET_EXPORT_RULES_FAILURE',

    EXPORT_DOWNLOAD_RULES_REQUEST: 'EXPORT_DOWNLOAD_RULES_REQUEST',
    EXPORT_DOWNLOAD_RULES_SUCCESS: 'EXPORT_DOWNLOAD_RULES_SUCCESS',
    EXPORT_DOWNLOAD_RULES_FAILURE: 'EXPORT_DOWNLOAD_RULES_FAILURE',

    GET_DRIFT_ATTRIBUTES_REQUEST: 'GET_DRIFT_ATTRIBUTES_REQUEST',
    GET_DRIFT_ATTRIBUTES_SUCCESS: 'GET_DRIFT_ATTRIBUTES_SUCCESS',
    GET_DRIFT_ATTRIBUTES_FAILURE: 'GET_DRIFT_ATTRIBUTES_FAILURE',

    UPDATE_TABINDEX_REQUEST: 'UPDATE_TABINDEX_REQUEST',
    UPDATE_TABINDEX_SUCCESS: 'UPDATE_TABINDEX_SUCCESS',
    UPDATE_TABINDEX_FAILURE: 'UPDATE_TABINDEX_FAILURE',

    UPDATE_PROPERTY_TABINDEX_REQUEST: 'UPDATE_PROPERTY_TABINDEX_REQUEST',
    UPDATE_PROPERTY_TABINDEX_SUCCESS: 'UPDATE_PROPERTY_TABINDEX_SUCCESS',
    UPDATE_PROPERTY_TABINDEX_FAILURE: 'UPDATE_PROPERTY_TABINDEX_FAILURE',

    SET_SELECTED_ATTRIBUTE_NAME: 'SET_SELECTED_ATTRIBUTE_NAME'
};