import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, FormControlLabel, Grid, Popover, Radio, RadioGroup, Typography, withStyles } from '@material-ui/core';

import Styles from '../../layouts/Styles.jsx';
import ConnectorStyles from './ConnectorStyles.jsx';
import NumberInput from '../TextBox/NumberInput.jsx';


const PullConfiguration = (props) => {
    const { classes, open, dataset, anchorEl, onCancel, onSave, isS3Delta } = props;
    const [pullConfig, setPullConfig] = useState({});

    const onConfigChange = useCallback((configType) => {
        let config = null;
        if (configType) {
            config = {
                'type': configType
            };
            if (configType === 'days') {
                config['column_name'] = dataset && dataset.incremental_columns && dataset.incremental_columns.length > 0 ? dataset.incremental_columns[0] : '';
            }

            if (isS3Delta) {
                config = { ...config, 'interval': pullConfig?.interval ?? '' };
            }
        }
        setPullConfig(config);
    }, [dataset, isS3Delta, pullConfig]);

    const onValueChange = useCallback((property, value) => {
        const config = pullConfig ? pullConfig : {};
        config[property] = value;
        setPullConfig({ ...config });
    }, [pullConfig]);

    const loadPullConfig = useCallback((pullConfig) => {
        setPullConfig({ ...pullConfig });
    }, []);

    const validatePullConfig = useCallback((pullConfig) => {
        let validatedConfig = {};
        if (!pullConfig) {
            return validatedConfig;
        }
        if (pullConfig.type === 'days' || pullConfig.type === 'percentage') {
            validatedConfig = pullConfig.value ? pullConfig : {};
        }
        if (isS3Delta) {
            validatedConfig = pullConfig;
        }
        return validatedConfig;
    }, [isS3Delta]);


    const onClose = useCallback(() => {
        setPullConfig(null);
        if (onCancel) {
            onCancel();
        }
    }, [onCancel]);

    const onSaveConfig = useCallback(() => {
        const validatedConfig = validatePullConfig(pullConfig);
        if (onSave) {
            onSave(dataset, 'pull_config', validatedConfig);
        }
        onClose();
    }, [dataset, onClose, onSave, pullConfig, validatePullConfig]);

    useEffect(() => {
        if (dataset && dataset.pull_config) {
            loadPullConfig(dataset?.pull_config ?? {});
        }
        if (dataset && dataset.configuration_detail && Object.keys(dataset.configuration_detail).length > 0) {
            const prevConfig = { ...dataset.configuration_detail };
            setPullConfig(prevConfig);
        }
    }, [dataset, loadPullConfig]);

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={
                {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
            transformOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'center'
                }
            }
            classes={
                {
                    paper: classes.pullConfigContainer
                }
            }>
            <Grid container direction="column" justify="flex-start" wrap="nowrap">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="h6">
                        {'Dataset Load Configuration'}
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <RadioGroup value={pullConfig && pullConfig.type ? pullConfig.type : ''}
                        onChange={(event) => onConfigChange(event.target.value)}>
                        <FormControlLabel value="days" control={<Radio />} label="Days" />
                        <FormControlLabel value="percentage" control={<Radio />} label="Percentage" />
                    </RadioGroup>
                    {
                        pullConfig && pullConfig.type &&
                        <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap" className={classes.pullParamsContainer}>
                            <NumberInput
                                fullWidth
                                integerOnly
                                placeholder={pullConfig && pullConfig.type === 'days' ? 'Enter the number of days to pull' : 'Enter the percentage of data to pull'}
                                value={pullConfig && pullConfig.value ? pullConfig.value : ''}
                                onChange={(event) => onValueChange('value', event.target.value)}
                                pattern={pullConfig && pullConfig.type === 'days' ? '^[1-9][0-9]*$' : '^[1-9][0-9]?$|^100$'}
                            />
                        </Grid>
                    }
                </Grid>
                {
                    isS3Delta &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap" className={classes.pullParamsContainer}>
                            <NumberInput
                                label="Schedule Interval"
                                fullWidth
                                integerOnly
                                placeholder={'Enter schedule interval in minutes'}
                                value={pullConfig && pullConfig.interval ? pullConfig.interval : ''}
                                onChange={(event) => onValueChange('interval', event.target.value)}
                            />
                        </Grid>
                    </Grid>
                }

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container direction="row" justify="flex-end">
                        <Button onClick={() => onSaveConfig()} variant="contained" color="primary" type="submit" className={classNames(classes.actionButtons, classes.submitBtn)}>
                            {'Save'}
                        </Button>
                        <Button onClick={() => onClose()} className={classNames(classes.cancelButton, classes.actionButtons)}>
                            {'Cancel'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Popover >
    );
};

PullConfiguration.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    dataset: PropTypes.object,
    anchorEl: PropTypes.element,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    isS3Delta: PropTypes.bool
};

export default withStyles((theme) => ({
    ...ConnectorStyles(theme),
    ...Styles(theme)
}))(PullConfiguration);