import React, { useState, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm } from "react-material-ui-form-validator";
import { Grid, Typography, withStyles, IconButton, Tabs, Tab, TextField, Chip, Popover, MenuItem } from '@material-ui/core';

import Styles from '../../layouts/Styles.jsx';
import SearchStyles from './SearchStyles.jsx';
import classNames from 'classnames';

import ToolTipComponent from '../../components/Tooltip/Tooltip.jsx';
import TabPanel from '../../components/TabPanel/TabPanel.jsx';
import SearchResultComponent from './SearchResultComponent.jsx';
import SearchFilter from './SearchFilter.jsx';
import { getSearchCount, getSearch, addfilterusersearch, updateSearchFilter, updateSearchResultOffset, updateSelectedAttribute, clearSearchResult, trackSearch, addusersearch } from '../../actions/searchActions';
import searchIco from '../../assets/images/search.svg';
import moreIco from '../../assets/images/more.svg';
import DatasourceDetails from '../../components/DatasourceList/DatasourceDetails.jsx';
import { getWorkbookInfo } from '../../actions/datasetActions';
import { navigatePage } from '../../actions/notificationActions';
import TextBox from '../../components/TextBox/TextBox.jsx';


const SearchList = (props) => {
    const { classes, theme, history, getPageTitle } = props;
    const dispatch = useDispatch();

    const searchFilters = useSelector(({ search }) => search.filters);
    const searchResult = useSelector(({ search }) => search.search_result);
    const { isLoading, isLazyLoading } = useSelector(({ search }) => search);
    const total_records_count = useSelector(({ search }) => search.search_result.total_count);
    const user = useSelector(({ account }) => account.user);
    const config = useSelector(({ setting }) => setting.config);
    const datasourceTypes = useSelector(({ datasource }) => datasource.connectionTypes);
    const isTableauConnection = datasourceTypes.some((data) => data.name === "Tableau" && config.input_connectors && config.input_connectors.includes(data.id));


    const [search_text, setSearch] = useState('');
    const [tabIndex, setTabIndex] = useState(searchFilters.tabIndex);
    const [tabName, setTabName] = useState(searchFilters.viewType);
    const [preTabName, setPrevTabName] = useState('');
    const [preFilter, setPrevFilterData] = useState('');
    const [enableFilter, setEnableFilter] = useState(false);
    const [unPermissionSource, setUnPermissionSource] = useState(null);
    const [dataset_count, setDatasetCount] = useState(0);
    const [datasource_count, setDatasourceCount] = useState(0);
    const [domain_count, setDomainCount] = useState(0);
    const [attribute_count, setAttributeCount] = useState(0);
    const [comment_count, setCommentCount] = useState(0);
    const [worksheet_count, setWorksheetCount] = useState(0);
    const [workbook_count, setWorkbookCount] = useState(0);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const sortOptions = ["Relevant", "Name A - Z", "Name Z - A", "Comments - High to Low", "Views - High to Low", "Ratings - High to Low", "Ratings - Low to High"];

    useEffect(() => {
        getPageTitle(`Search List | ${tabName}`);
    });

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    useEffect(() => {
        setSearch(searchFilters.search);
    }, [searchFilters]);

    const [tabList, setTabList] = useState([
        {
            "id": 0,
            "name": "All"
        },
        {
            "id": 1,
            "name": "Datasources"
        },
        {
            "id": 2,
            "name": "Datasets"
        },
        {
            "id": 3,
            "name": "Attributes"
        },
        {
            "id": 4,
            "name": "Workbooks"
        },
        {
            "id": 5,
            "name": "Worksheets"
        },
        {
            "id": 6,
            "name": "Domains"
        },
        {
            "id": 7,
            "name": "Comments"
        }
    ]);

    const setTabs = useCallback(() => {
        const tabs = [{ "id": 0, "name": "All" }, { "id": 1, "name": "Datasources" }, { "id": 2, "name": "Datasets" }, { "id": 3, "name": "Attributes" }, { "id": 4, "name": "Workbooks" }, { "id": 5, "name": "Worksheets" }, { "id": 6, "name": "Domains" }, { "id": 7, "name": "Comments" }];
        if (!config.domain) {
            const index = tabs.findIndex((data) => data.name === "Domains");
            tabs.splice(index, 1);
        }
        if (!config.collaboration) {
            const index = tabs.findIndex((data) => data.name === "Comments");
            tabs.splice(index, 1);
        }
        if (!isTableauConnection) {
            const index = tabs.findIndex((data) => data.name === "Workbooks");
            tabs.splice(index, 1);
            const sheetIndex = tabs.findIndex((data) => data.name === "Worksheets");
            tabs.splice(sheetIndex, 1);
        }
        setTabList([...tabs]);
    }, [config.collaboration, config.domain, isTableauConnection]);

    useEffect(() => {
        setTabs();
    }, [setTabs]);

    const loadCount = useCallback(() => {
        tabList.forEach((tab) => {
            dispatch(getSearchCount(tab.name, { ...searchFilters, viewType: tab.name, offset: 0, limit: 1 })).then((response) => {
                if (response && response.data) {
                    switch (tab.id) {
                        case 1:
                            setDatasourceCount(response.total_counts);
                            break;
                        case 2:
                            setDatasetCount(response.total_counts);
                            break;
                        case 3:
                            setAttributeCount(response.total_counts);
                            break;
                        case 4:
                            setWorkbookCount(response.total_counts);
                            break;
                        case 5:
                            setWorksheetCount(response.total_counts);
                            break;
                        case 6:
                            setDomainCount(response.total_counts);
                            break;
                        case 7:
                            setCommentCount(response.total_counts);
                            break;
                        default:
                            break;
                    }
                }
            });
        });
    }, [dispatch, searchFilters, tabList]);

    /**
     *
     * @param {*} value
     */
    const onChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    /**
     *
     */
    const handleSubmit = () => {
        if (search_text && search_text.trim().length > 0) {
            const searchFilter = {
                search: search_text,
                viewType: tabName,
                tabIndex: tabIndex,
                offset: 0
            };

            if ((preTabName === tabName) && (searchFilters.search === search_text)) {
                return;
            }
            dispatch(addusersearch({ 'search': search_text, 'page': 'search and discovery' }));
            dispatch(updateSearchFilter(searchFilter));
        }
    };

    /**
     *
     * @param {*} event
     * @param {*} newValue
     */
    const handleTabChange = (event, newValue) => {
        const index = tabList.findIndex((p) => p.id === newValue);
        const new_tab = tabList[index].name;
        if (searchFilters.viewType !== tabList[index].name) {
            const searchFilter = {
                viewType: tabList[index].name,
                offset: searchResult[tabList[index].name].length,
                tabIndex: newValue
            };
            if (new_tab === "Domains") {
                searchFilter.sortBy = 'Relevant';
            }
            if (
                (tabList[index].name === "Attributes" || tabList[index].name === "Comments") &&
                (searchFilters.sortBy === "Comments - High to Low" || searchFilters.sortBy === "Views - High to Low")
            ) {
                searchFilter.sortBy = 'Relevant';
            }
            if (
                (tabList[index].name === "Domains") &&
                (searchFilters.sortBy === "Ratings - High to Low" ||
                    searchFilters.sortBy === "Ratings - Low to High" ||
                    searchFilters.sortBy === "Comments - High to Low" ||
                    searchFilters.sortBy === "Comments - Low to High")
            ) {
                searchFilter.sortBy = 'Relevant';
            }
            dispatch(updateSearchFilter(searchFilter));
            setTabName(tabList[index].name);
            setTabIndex(newValue);
            getPageTitle(`Search List | ${tabList[index].name}`);
        }
        handleClose();
    };

    /**
     *
     */
    const loadSearchResult = useCallback(() => {
        if ((preTabName === tabName) && (JSON.stringify(preFilter) === JSON.stringify(searchFilters))) {
            return;
        }
        setPrevTabName(tabName);
        setPrevFilterData(JSON.parse(JSON.stringify(searchFilters)));
        loadCount();
        dispatch(getSearch(tabName, { ...searchFilters })).then((response) => { });
    }, [dispatch, loadCount, preFilter, preTabName, searchFilters, tabName]);

    /**
     *
     */
    useEffect(() => {
        loadSearchResult();
    }, [searchFilters, tabIndex, tabName, loadSearchResult, dispatch]);

    /**
     * Clear Search Results When Page will Bind
     */
    useEffect(() => {
        dispatch(clearSearchResult());
    }, [dispatch]);

    /**
     *
     * @param {*} value
     */
    const setFilter = (value) => {
        setEnableFilter(value);
    };

    const diff = (obj1, obj2) => {
        const result = {};
        if (Object.is(obj1, obj2)) {
            return undefined;
        }
        if (!obj2 || typeof obj2 !== 'object') {
            return obj2;
        }
        Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach((key) => {
            if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
                result[key] = obj2[key];
            }
            if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
                const value = diff(obj1[key], obj2[key]);
                if (value !== undefined) {
                    result[key] = value;
                }
            }
        });
        return result;
    };

    /**
     *
     * @param {*} value
     */
    const updateFilter = (value) => {
        const filter = { ...searchFilters };
        const difference = diff(filter, { ...value });
        let is_connectiontype = false;
        if (Object.keys(difference).length === 1) {
            if ('connectionType' in difference) {
                is_connectiontype = true;
            }
        }
        if (is_connectiontype) {
            let appliedvalue = "removed";
            let differance_value = filter.connectionType.filter((x) => value.connectionType.indexOf(x) === -1);
            if (differance_value.length === 0) {
                differance_value = value.connectionType.filter((x) => filter.connectionType.indexOf(x) === -1);
                appliedvalue = "applied";
            }
            if (differance_value.length > 0) {
                dispatch(addfilterusersearch({ "filter": { "connectionType": differance_value[0] }, "page": "search and discovery", "appliedType": appliedvalue }));
            }
        } else {
            if (Object.keys(difference).length > 0 && Object.keys(difference).length < 4) {
                dispatch(addfilterusersearch({ "filter": difference, "page": "search and discovery" }));
            }
        }
        dispatch(updateSearchFilter({ ...value }));
    };

    /**
     *
     */
    const onScrollChange = () => {
        if (searchResult[tabName] && total_records_count > searchResult[tabName].length + 1) { dispatch(updateSearchResultOffset({ ...searchFilters, 'offset': searchResult[tabName].length })); }
    };

    const isUserChange = (value) => {
        dispatch(addfilterusersearch({ "filter": value, "page": "search and discovery", "appliedType": "applied" }));
    };

    const isRemovedUserChange = (value) => {
        dispatch(addfilterusersearch({ "filter": value, "page": "search and discovery", "appliedType": "removed" }));
    };

    const resetFilterByKey = (key) => {
        const filter = { ...searchFilters };
        switch (key) {
            case 'dqScore':
                filter[key] = [0, 100];
                break;
            case 'favourite':
                filter[key] = false;
                break;
            case 'connectionType':
                filter[key] = [];
                break;
            case 'tags':
                filter[key] = [];
                break;
            case 'users':
                filter[key] = [
                    {
                        isDefault: true,
                        isSelected: true,
                        isUser: false,
                        name: 'All'
                    }
                ];
                break;
            default:
                break;
        }
        dispatch(updateSearchFilter({ ...filter }));
    };

    /**
     *
     * @param {*} data
     */
    const redirectToSelectedItem = (data) => {
        // Save Search Tracks
        track_search(data);

        const domain_id = Number(data.domain_id);
        if (data.type !== "domain") {
            const userId = user ? user.id : 0;
            const filterUser = data.users.find((data) => data.user_id === userId);
            if (!filterUser) {
                data.sharing_info = data.users.map((userList) => { return { ...userList, is_request_accepted: true }; });
                data.id = data.source_id;
                data.loadDatasource = Boolean(data.dataset_id);
                setUnPermissionSource({ ...data });
                return false;
            }
        }
        const dataId = {
            'source_id': data.source_id,
            'dataset_id': data.dataset_id,
            'item_id': data.worksheet_id,
            'item_name': data.name
        };
        dispatch(navigatePage('catalog'));
        switch (data.type) {
            case 'datasource':
                dispatch(addfilterusersearch({ "filter": { "type": "datasource" }, "page": "redriect", "search_text": searchFilters.search }));
                history.push(`/catalog/${data.source_id}`);
                break;
            case 'dataset':
                dispatch(addfilterusersearch({ "filter": { "type": "dataset" }, "page": "redriect", "search_text": searchFilters.search }));
                history.push(`/dataset/${data.dataset_id}`);
                break;
            case 'attribute':
                dispatch(addfilterusersearch({ "filter": { "type": "attribute" }, "page": "redriect", "search_text": searchFilters.search }));
                dispatch(updateSelectedAttribute(data.name));
                history.push(`/dataset/${data.dataset_id}`);
                break;
            case 'workbook':
                dispatch(addfilterusersearch({ "filter": { "type": "workbook" }, "page": "redriect", "search_text": searchFilters.search }));
                history.push(`/dataset/${data.dataset_id}`);
                break;
            case 'worksheet':
                dispatch(addfilterusersearch({ "filter": { "type": "worksheet" }, "page": "redriect", "search_text": searchFilters.search }));
                if (data.dataset_id && data.dataset_id !== 0) {
                    dispatch(getWorkbookInfo({ dataset_id: data.dataset_id })).then((response) => {
                    });
                }
                history.push({ pathname: `/item/${data.worksheet_id}`, state: { dataId: dataId } });
                break;
            case 'domain':
                dispatch(addfilterusersearch({ "filter": { "type": "domain" }, "page": "redriect", "search_text": searchFilters.search }));
                history.push({ pathname: `/domain/${domain_id}`, state: { domain: data } });
                break;
            case 'comments':
                dispatch(addfilterusersearch({ "filter": { "type": "comments" }, "page": "redriect", "search_text": searchFilters.search }));
                if (data.worksheet_id) {
                    history.push({ pathname: `/item/${data.worksheet_id}`, state: { dataId: dataId } });
                }
                else if (data.dataset_id) {
                    history.push(`/dataset/${data.dataset_id}`);
                } else {
                    history.push(`/catalog/${data.source_id}`);
                }
                break;
            default:
                break;
        }
    };

    /**
     *
     * @param {*} data
     */
    const getRedirectToSelectedItemLink = (data) => {
        const domain_id = Number(data.domain_id);
        if (data.type !== "domain") {
            const userId = user ? user.id : 0;
            if (data.users) {
                const filterUser = data.users.find((data) => data.user_id === userId);
                if (!filterUser) {
                    data.sharing_info = data.users.map((userList) => { return { ...userList, is_request_accepted: true }; });
                    data.id = data.source_id;
                    data.loadDatasource = Boolean(data.dataset_id);
                    // setUnPermissionSource({ ...data });
                    return false;
                }
            }
        }
        const dataId = {
            'source_id': data.source_id,
            'dataset_id': data.dataset_id,
            'item_id': data.worksheet_id,
            'item_name': data.name
        };
        switch (data.type) {
            case 'datasource':
                return (`/catalog/${data.source_id}`);
            case 'dataset':
                return (`/dataset/${data.dataset_id}`);
            case 'attribute':
                return (`/dataset/${data.dataset_id}`);
            case 'workbook':
                return (`/dataset/${data.dataset_id}`);
            case 'worksheet':
                return ({ pathname: `/item/${data.worksheet_id}`, state: { dataId: dataId } });
            case 'domain':
                return ({ pathname: `/domain/${domain_id}`, state: { domain: data } });
            case 'comments':
                if (data.worksheet_id) {
                    return ({ pathname: `/item/${data.worksheet_id}`, state: { dataId: dataId } });
                }
                else if (data.dataset_id) {
                    return (`/dataset/${data.dataset_id}`);
                }
                return (`/catalog/${data.source_id}`);

            default:
                break;
        }
    };

    /**
     *
     * @param {*} data
     */
    const track_search = (data) => {
        let needTrack = true;
        const tracks_object = {
            type: data.type
        };
        switch (data.type) {
            case 'datasource':
                tracks_object['redirect_id'] = data.source_id;
                tracks_object.name = data.name;
                tracks_object.params = {
                    name: data.name
                };
                break;
            case 'dataset':
                tracks_object['redirect_id'] = data.dataset_id;
                tracks_object.name = data.name;
                tracks_object.params = {
                    source_id: data.source_id,
                    name: data.name
                };
                break;
            case 'attribute':
                tracks_object['redirect_id'] = data.dataset_id;
                tracks_object.name = data.name;
                tracks_object.params = {
                    attribute_id: data.attribute_id,
                    source_id: data.source_id,
                    dataset_id: data.dataset_id,
                    name: data.name
                };
                break;
            case 'domain':
                tracks_object['redirect_id'] = data.domain_id;
                tracks_object.name = data.name;
                tracks_object.params = {
                    name: data.name
                };
                break;
            case 'comments':
                if (data.dataset_id) {
                    tracks_object['redirect_id'] = data.dataset_id;
                    tracks_object.name = data.dataset_name;
                    tracks_object.params = {
                        comment_id: data.comment_id,
                        source_id: data.source_id,
                        dataset_id: data.dataset_id,
                        name: data.dataset_name
                    };
                } else {
                    tracks_object['redirect_id'] = data.source_id;
                    tracks_object.name = data.datasource_name;
                    tracks_object.params = {
                        comment_id: data.comment_id,
                        source_id: data.source_id,
                        name: data.datasource_name
                    };
                }
                break;
            default:
                needTrack = false;
                break;
        }

        if (needTrack) {
            dispatch(trackSearch(tracks_object));
        }
    };

    const shouldDisable = (tabId) => {
        let disableTab = false;
        switch (tabId) {
            case 1:
                disableTab = datasource_count === 0;
                break;
            case 2:
                disableTab = dataset_count === 0;
                break;
            case 3:
                disableTab = attribute_count === 0;
                break;
            case 4:
                disableTab = workbook_count === 0;
                break;
            case 5:
                disableTab = worksheet_count === 0;
                break;
            case 6:
                disableTab = domain_count === 0;
                break;
            case 7:
                disableTab = comment_count === 0;
                break;
            default:
                break;
        }
        return disableTab;
    };

    const onSortingChange = (name, value) => {
        dispatch(updateSearchFilter({ sortBy: value, offset: 0 }));
    };

    const getSortOptions = () => {
        let filteredOptions = sortOptions;
        if (tabName === "Attributes" || tabName === "Comments") {
            filteredOptions = filteredOptions.filter((option) => option !== "Comments - High to Low" && option !== "Views - High to Low");
        }
        if (tabName === "Domains") {
            filteredOptions = filteredOptions.filter((option) => option !== "Comments - High to Low" && option !== "Ratings - High to Low" && option !== "Ratings - Low to High");
        }
        return filteredOptions;
    };

    return (
        <Grid container>
            {
                unPermissionSource ?
                    <Grid className={classNames(classes.container, classes.width100)}>
                        <DatasourceDetails breadcrumb={[{ name: 'Search', path: '/search' }, { name: unPermissionSource.name, path: '' }]} redirect={() => setUnPermissionSource(null)} source={unPermissionSource} history={history} />
                    </Grid> :
                    <Fragment>
                        <Grid container className={classes.Listheader}>
                            <Grid item xs={4} align="left">
                                <ValidatorForm
                                    name="searchform"
                                    onSubmit={handleSubmit}
                                    autoComplete={"off"}
                                    className={search_text ? classes.searchclear : null}
                                >
                                    <Grid className={classNames(classes.search, classes.listSearch, search_text ? classes.searchicon : null)}>
                                        <TextField id="outlined-search" autocomplete="off" placeholder="Search field" value={search_text} type="text" onChange={(value) => onChangeSearch(value)} className={classes.newInput} />
                                        <img src={searchIco} alt="" style={{ display: "none" }} onClick={handleSubmit} width={35} />
                                    </Grid>
                                </ValidatorForm>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container alignItems="center" justify="flex-end">
                                    {
                                        searchFilters && searchFilters.favourite &&
                                        <Grid item className={classes.marginRight15}>
                                            <Typography variant="subtitle1" component="span">
                                                Favourites:
                                            </Typography>
                                            <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                Yes
                                            </Typography>
                                            <IconButton style={{ width: '30px', padding: 7 }} onClick={() => resetFilterByKey('favourite')}>
                                                <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                    <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                    <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                                </svg>
                                            </IconButton>
                                        </Grid>
                                    }
                                    {
                                        searchFilters && searchFilters.dqScore &&
                                        <Grid item className={classes.marginRight15}>
                                            <Typography variant="subtitle1" component="span">
                                                DQ Score
                                            </Typography>
                                            <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                {searchFilters.dqScore[0]}
                                                -
                                                {searchFilters.dqScore[1]}
                                            </Typography>
                                            {
                                                (searchFilters.dqScore[0] > 0 || searchFilters.dqScore[1] < 100) &&
                                                <IconButton style={{ width: '30px', padding: 7 }} onClick={() => resetFilterByKey('dqScore')}>
                                                    <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                        <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                        <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                                    </svg>
                                                </IconButton>
                                            }
                                        </Grid>
                                    }
                                    {
                                        searchFilters && searchFilters.connectionType &&
                                        <Grid item className={classes.marginRight15}>
                                            <Typography variant="subtitle1" component="span">
                                                Datasource Type:
                                            </Typography>
                                            <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                {searchFilters.connectionType.length === 0 ? 'All' : searchFilters.connectionType[0]}
                                            </Typography>
                                            {
                                                searchFilters.connectionType.length > 1 &&

                                                <Chip label={`+${searchFilters.connectionType.length - 1}`} size="small" classname={classes.plusChip} style={{ background: '#FFFFFF', margin: "0px 5px" }} />
                                            }
                                            {
                                                searchFilters.connectionType.length > 1 &&
                                                <IconButton style={{ width: '30px', padding: 7 }} onClick={() => resetFilterByKey('connectionType')}>
                                                    <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                        <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                        <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                                    </svg>
                                                </IconButton>
                                            }
                                        </Grid>
                                    }
                                    {
                                        searchFilters && searchFilters.users.length > 0 &&
                                        <Grid item className={classes.marginRight15}>
                                            <Typography variant="subtitle1" component="span">
                                                Users:
                                            </Typography>
                                            <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                {searchFilters.users[0].name}
                                            </Typography>
                                            {
                                                searchFilters.users.length > 1 &&
                                                <Chip label={`+${searchFilters.users.length - 1}`} size="small" classname={classes.plusChip} style={{ background: '#FFFFFF', margin: "0px 5px" }} />
                                            }
                                            {
                                                searchFilters.users[0].name !== 'All' &&
                                                <IconButton style={{ width: '30px', padding: 7 }} onClick={() => resetFilterByKey('users')}>
                                                    <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                        <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                        <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                                    </svg>
                                                </IconButton>
                                            }
                                        </Grid>
                                    }

                                    {
                                        searchFilters && searchFilters.tags.length > 0 &&
                                        <Grid item className={classes.marginRight15}>
                                            <Typography variant="subtitle1" component="span">
                                                Tags:
                                            </Typography>
                                            <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                {searchFilters.tags[0]}
                                            </Typography>
                                            {
                                                searchFilters.tags.length > 1 &&
                                                <Chip label={`+${searchFilters.tags.length - 1}`} size="small" classname={classes.plusChip} style={{ background: '#FFFFFF', margin: "0px 5px" }} />
                                            }
                                            <IconButton style={{ width: '30px', padding: 7 }} onClick={() => resetFilterByKey('tags')}>
                                                <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                    <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                    <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                                </svg>
                                            </IconButton>
                                        </Grid>
                                    }

                                    <ToolTipComponent title="Filters" placement="bottom" arrow>
                                        <IconButton onClick={() => setFilter(!enableFilter)} className={classNames(classes.marginRight7, classes.filterImg)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.923" viewBox="0 0 20 16.923">
                                                <g transform="translate(-3 -5)">
                                                    <path id="Path_1391" data-name="Path 1391" d="M22.231,6.538H11.462A1.54,1.54,0,0,0,9.923,5H8.385A1.54,1.54,0,0,0,6.846,6.538H3.769a.769.769,0,1,0,0,1.538H6.846A1.54,1.54,0,0,0,8.385,9.615H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,8.077V6.538H9.923V7.3s0,0,0,0,0,0,0,0v.766Z" fill={theme.palette.primary.main} />
                                                    <path id="Path_1392" data-name="Path 1392" d="M22.231,14.538H17.615A1.54,1.54,0,0,0,16.077,13H14.538A1.54,1.54,0,0,0,13,14.538H3.769a.769.769,0,0,0,0,1.538H13a1.54,1.54,0,0,0,1.538,1.538h1.538a1.54,1.54,0,0,0,1.538-1.538h4.615a.769.769,0,1,0,0-1.538Zm-7.692,1.538V14.538h1.538V15.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -1.846)" fill={theme.palette.primary.main} />
                                                    <path id="Path_1393" data-name="Path 1393" d="M22.231,22.538H11.462A1.54,1.54,0,0,0,9.923,21H8.385a1.54,1.54,0,0,0-1.538,1.538H3.769a.769.769,0,0,0,0,1.538H6.846a1.54,1.54,0,0,0,1.538,1.538H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,24.077V22.538H9.923V23.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -3.692)" fill={theme.palette.primary.main} />
                                                </g>
                                            </svg>
                                        </IconButton>
                                    </ToolTipComponent>

                                    <SearchFilter enableFilter={enableFilter} searchFilters={searchFilters} setFilter={setFilter} isRemovedUserChange={isRemovedUserChange} isUserChange={isUserChange} updateFilter={updateFilter} />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container className={classes.HeaderTab}>
                            <Tabs
                                value={tabIndex}
                                onChange={handleTabChange}
                                indicatorColor="secondary"
                                textColor="secondary"
                                aria-label="dashboard tabs"
                            >
                                {
                                    tabList.slice(0, 5).map((dashboard) => {
                                        return (
                                            <Tab key={dashboard.id} label={dashboard.name} value={dashboard.id} disabled={shouldDisable(dashboard.id)} />
                                        );
                                    })
                                }
                            </Tabs>
                            {
                                tabList.length > 5 &&
                                <Grid onClick={handleClick} className={worksheet_count > 0 || domain_count > 0 || comment_count > 0 ? classes.moreLink : classes.moreDisableLink}>
                                    <img src={moreIco} alt="" />
                                    {' '}
                                    More
                                </Grid>
                            }
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={
                                    {
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    }
                                }
                            >
                                <Grid>
                                    {
                                        tabList.slice(5, tabList.length).map((tab) => {
                                            return (
                                                <Grid key={tab.id}
                                                    className={classNames(classes.moreList, tabIndex === tab.id ? classes.selectedLink : null, shouldDisable(tab.id) ? classes.disableLink : null)}
                                                    onClick={() => (!shouldDisable(tab.id) ? handleTabChange('', tab.id) : null)}>
                                                    {tab.name}
                                                </Grid>
                                            );
                                        })
                                    }
                                </Grid>
                            </Popover>
                        </Grid>
                        <Grid container className={classes.listCont}>
                            {
                                tabList.map((tab, index) =>
                                    <TabPanel key={tab.id} value={tabIndex} index={tab.id}>
                                        {
                                            tabIndex === tab.id &&
                                            <Grid>
                                                {
                                                    !isLoading &&
                                                    <Grid container className={classes.flexSpaceBetween}>
                                                        <Typography variant="h4" gutterBottom component="div" className={classes.resultsFound}>
                                                            {total_records_count ? total_records_count : 0}
                                                            {' '}
                                                            Results Found
                                                            {' '}
                                                            {searchFilters.search ? 'for Keyword : ' : ''}
                                                            {
                                                                searchFilters.search ?
                                                                    <span className={classes.highlight_text}>
                                                                        {searchFilters.search}
                                                                    </span> : ''
                                                            }
                                                        </Typography>
                                                        {
                                                            <Grid item className={classes.minWidth110}>
                                                                <ValidatorForm
                                                                    name="searchform"
                                                                >
                                                                    <TextBox
                                                                        className={classes.inlinetxt}
                                                                        onChange={(event) => onSortingChange(event.target.name, event.target.value)}
                                                                        value={searchFilters.sortBy}
                                                                        select
                                                                        name="sortby"
                                                                        label="Sort By"
                                                                        SelectProps={
                                                                            {
                                                                                MenuProps: {
                                                                                    anchorOrigin: {
                                                                                        vertical: "bottom",
                                                                                        horizontal: "center"
                                                                                    },
                                                                                    transformOrigin: {
                                                                                        vertical: "top",
                                                                                        horizontal: "center"
                                                                                    },
                                                                                    getContentAnchorEl: null
                                                                                }
                                                                                // IconComponent: () => DropDownIcon()
                                                                            }
                                                                        }
                                                                        fullWidth>
                                                                        {
                                                                            getSortOptions().map((option, index) => (
                                                                                <MenuItem key={index} value={option} className={classes.menuItem}>
                                                                                    {option}
                                                                                </MenuItem>
                                                                            ))
                                                                        }
                                                                    </TextBox>
                                                                </ValidatorForm>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                }
                                                {
                                                    isLoading &&
                                                    <Typography variant="h4" gutterBottom component="div" className={classes.resultsFound}>
                                                        Searching Results
                                                        {' '}
                                                        {' '}
                                                        {searchFilters.search ? 'for Keyword : ' : ''}
                                                        {
                                                            searchFilters.search ?
                                                                <span className={classes.highlight_text}>
                                                                    {searchFilters.search}
                                                                </span> : ''
                                                        }
                                                    </Typography>
                                                }
                                                <SearchResultComponent searchResults={searchResult[tabName]} searchFilters={searchFilters} tabName={tabName} isLoading={isLoading} isLazyLoading={isLazyLoading} onScrollChange={onScrollChange} redirectToSelectedItem={redirectToSelectedItem} getRedirectToSelectedItemLink={getRedirectToSelectedItemLink} />
                                            </Grid>
                                        }
                                    </TabPanel>
                                )
                            }
                        </Grid>
                    </Fragment>
            }
        </Grid>
    );
};


SearchList.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    history: PropTypes.object,
    getPageTitle: PropTypes.func
};

export default withStyles((theme) => ({
    ...SearchStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SearchList);