import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import RoleList from './RoleList.jsx';
import RoleConfiguration from './RoleConfiguration.jsx';
import { getSuperOrganization } from '../../actions/settingActions';


const Role = (props) => {
    const { organizationId, onEditConfig, isConfiguration, onClose } = props;
    const [role, setRoleId] = useState(null);
    const [roles, setRoles] = useState([]);
    const [issuperorganization, setsuperorganization] = useState(false);
    const dispatch = useDispatch();


    const onEdit = (type, roleId, roleList) => {
        if (type === "Edit Role") {
            setRoleId(roleId);
        } else {
            setRoleId(null);
        }
        setRoles([...roleList]);
        onEditConfig(type);
    };

    const getIsSuperOrganization = useCallback(() => {
        dispatch(getSuperOrganization(organizationId)).then((response) => {
            if (response) {
                setsuperorganization(response.is_super_organization);
            }
        });

    }, [dispatch, organizationId]);

    useEffect(() => {
        getIsSuperOrganization();
    }, [getIsSuperOrganization]);


    return (
        <Grid>
            {
                isConfiguration ?
                    <RoleConfiguration
                        roleId={role}
                        organizationId={organizationId}
                        issuperorganization={issuperorganization}
                        roles={roles}
                        onClose={() => onClose()} />
                    :
                    <RoleList
                        organizationId={organizationId}
                        issuperorganization={issuperorganization}
                        onEdit={(type, roleData, roleList) => onEdit(type, roleData, roleList)} />
            }
        </Grid>
    );
};

Role.propTypes = {
    organizationId: PropTypes.number,
    onEditConfig: PropTypes.func,
    isConfiguration: PropTypes.bool,
    onClose: PropTypes.func
};

export default Role;