const ScheduleMonthCalenderStyles = (theme) => ({
    calenderContainer: {
        display: 'block',
        maxWidth: 325,
        minWidth: 310,
        minHeight: 220,
        overflowX: "hidden",
        flexDirection: "column",
        justifyContent: "center",
        padding: 10
    },
    dayContainer: {
        display: 'inline-flex',
        width: 36,
        height: 36,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 25,
        cursor: 'pointer',
        margin: 2,
        '&:hover': {
            backgroundColor: 'rgba(237, 242, 244, 0.5)'
        }
    },
    daySelected: {
        color: theme.palette.background.paper,
        fontWeight: 500,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main
        }
    }
});

export default ScheduleMonthCalenderStyles;