import React, { useState, useEffect, useCallback } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import HorizontalBarChart from './HorizontalBarChart.jsx';
// import DensityChart from './DensityChart.jsx';
import { appConstants } from '../../constants/appConstants';
import CurationDataPreview from '../CurationDataPreview/CurationDataPreview.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { learningRule } from '../../actions/scheduleActions';
import { updateLearningAttribute } from '../../actions/datasetActions';

const CurationCharts = (props) => {
    const { analysis, attribute, classes, datasetId, attributeId } = props;
    const [basicAnalysis, setBasicAnalysis] = useState([]);
    const [advanceAnalysis, setAdvanceAnalysis] = useState([]);
    const [referenceAnalysis, setReferenceAnalysis] = useState([]);
    const [learningData, setLearningData] = useState({});
    const [learningType, setLearningType] = useState('');
    const [open, setLearningOpen] = useState(false);
    const dispatch = useDispatch();
    const properties = useSelector(({ dataset }) => dataset.properties);

    const getProperties = useCallback(() => {
        const basicRules = [];
        const advanceRules = [];
        const referenceRules = [];
        if (analysis) {
            const attributeData = analysis;
            if (attributeData && attributeData) {
                const keys = Object.keys(attributeData);
                for (const key of keys) {
                    if (attributeData[key].rule_type === "basic" && (attributeData[key].statistical_result && attributeData[key].statistical_result.length) !== 0 && attributeData[key].status === "Completed") {
                        basicRules.push(attributeData[key]);
                    } else if (attributeData[key].rule_type === "advanced" && (attributeData[key].statistical_result && attributeData[key].statistical_result.length) !== 0 && attributeData[key].status === "Completed") {
                        advanceRules.push(attributeData[key]);
                    } else if ((attributeData[key].statistical_result && attributeData[key].statistical_result.length) !== 0 && attributeData[key].status === "Completed") {
                        referenceRules.push(attributeData[key]);
                    }
                }
            }
            debugger;
            setBasicAnalysis([...basicRules]);
            setAdvanceAnalysis([...advanceRules]);
            setReferenceAnalysis([...referenceRules]);
        }
    }, [analysis]);

    useEffect(() => {
        getProperties({ ...analysis });
    }, [analysis, getProperties]);

    const enableLearning = (previewData, property) => {
        if (previewData && previewData.statistical_result && previewData.statistical_result.length) {
            setLearningType(property);
            setLearningData(previewData);
            setLearningOpen(true);
        }
    };

    const closeDialog = () => {
        setLearningOpen(false);
        setLearningData([]);
    };

    const learningAction = (rule, index, property) => {
        for (let index = 0; index < advanceAnalysis.length; index++) {
            for (let i = 0; i < advanceAnalysis[index].statistical_result.length; i++) {
                if (advanceAnalysis[index].statistical_result[i].input_value === rule.input_value && advanceAnalysis[index].statistical_result[i].curated_value === rule.curated_value) {
                    advanceAnalysis[index].statistical_result.splice(i, 1);
                }
            }
        }
        setAdvanceAnalysis([...advanceAnalysis]);
        setLearningData({ ...learningData });
        if (learningData.statistical_result.length === 0) {
            setLearningOpen(false);
        }
        const accept = properties[attribute] && properties[attribute].curate_learning && properties[attribute].curate_learning.accept ? properties[attribute].curate_learning.accept : [];
        const decline = properties[attribute] && properties[attribute].curate_learning && properties[attribute].curate_learning.decline ? properties[attribute].curate_learning.decline : [];
        const learning = [...accept, ...decline];
        const duplicate = learning.find((d) => d.curated_value === rule.curated_value && rule.input_value === d.input_value);
        if (!duplicate) {
            const model = {
                attribute: attribute,
                type: "Curation",
                property: {
                    ...rule,
                    'is_accept': property
                }
            };
            const requestParams = {
                name: attribute,
                data: { ...rule },
                property: property ? "accept" : "decline"
            };
            dispatch(updateLearningAttribute(requestParams));
            dispatch(learningRule(attributeId, datasetId, model));
        }
    };

    const learningEdit = (index, property, value) => {
        learningData.statistical_result[index][property] = value;
        setLearningData({ ...learningData });
    };


    const getChartProps = () => {
        if (learningType === "basic") {
            return appConstants.charts.basicCuration.name;
        } else if (learningType === "advanced") {
            return appConstants.charts.advanceCuration.name;
        }
        return appConstants.charts.referenceCuration.name;
    };


    return (
        <Grid>
            {
                basicAnalysis.length > 0 &&
                <Grid>
                    <HorizontalBarChart enableLearning={enableLearning} chartProps={appConstants.charts.basicCuration} analysis={basicAnalysis} attribute={attribute} chartClass="basic-rule" ruleType="basic" />
                </Grid>
            }
            {
                advanceAnalysis.length > 0 &&
                <Grid className={classes.marginTop15}>
                    <HorizontalBarChart enableLearning={enableLearning} chartProps={appConstants.charts.advanceCuration} analysis={advanceAnalysis} attribute={attribute} chartClass="advance-rule" ruleType="advanced" />
                </Grid>
            }
            {
                referenceAnalysis.length > 0 &&
                <Grid className={classes.marginTop15}>
                    <HorizontalBarChart enableLearning={enableLearning} chartProps={appConstants.charts.referenceCuration} analysis={referenceAnalysis} attribute={attribute} chartClass="reference-rule" ruleType="reference" />
                </Grid>
            }
            {
                open &&
                <CurationDataPreview
                    open={open}
                    title={getChartProps()}
                    previewData={learningData}
                    onClose={() => closeDialog()}
                    ruleType={learningType}
                    learningAction={learningAction}
                    learningEdit={learningEdit} />
            }
        </Grid>
    );
};

CurationCharts.propTypes = {
    analysis: PropTypes.object,
    attribute: PropTypes.string,
    classes: PropTypes.object,
    datasetId: PropTypes.string,
    attributeId: PropTypes.number
};

export default withStyles((theme) => ({
    ...Styles(theme)
}))(CurationCharts);