/* eslint-disable no-nested-ternary */
import React, { useState, Fragment, useEffect } from 'react';
import { withStyles, Typography, TableRow, TableCell, Collapse, Table, TableBody, TableHead, Grid } from '@material-ui/core';

import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import Styles from '../../layouts/Styles.jsx';
import MasterSearchDetailStyles from './MasterSearchDetailStyles.jsx';

const CollapsibleChild = (props) => {
    const { classes, child_headers, record } = props;
    const [open, setOpen] = useState(false);

    return (
        <Fragment>
            <TableRow onClick={() => setOpen(!open)}>
                {
                    child_headers.map((header, headerIndex) =>
                        <TableCell key={`child_record_${headerIndex}`} style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10 }}>
                            <Typography variant="body1" noWrap className={open && record['child_records'].length > 1 ? classes.boldText : null}>
                                {header.toLowerCase().includes('date') ? moment(new Date(record['parent_record'][header])).format("DD/MM/YYYY") : record['parent_record'][header]}
                            </Typography>
                        </TableCell>
                    )
                }
            </TableRow>
            <TableRow>
                <TableCell colSpan={child_headers.length} style={{ paddingBottom: 0, paddingTop: 0, padding: 0, overflowY: 'hidden', overflowX: 'auto' }} >
                    <Grid container xs={12}>
                        <Collapse in={open} timeout="auto" unmountOnExit style={{ width: '100%' }}>
                            <Table style={{ backgroundColor: '#f4f6f9', marginTop: '-43px' }}>
                                <TableHead>
                                    <TableRow>
                                        {
                                            child_headers.map((header, headerIndex) =>
                                                <TableCell key={`child_record_${headerIndex}`} style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10 }}>
                                                    <Typography variant="body1" noWrap className={classes.boldText}>
                                                        {header}
                                                    </Typography>
                                                </TableCell>
                                            )
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                    record['child_records'].map((grouped_c_record, grouped_c_index) =>
                                        <TableRow key={`grouped_child_record_${grouped_c_index}`}>
                                            {
                                                child_headers.map((header, headerIndex) =>
                                                    <TableCell key={`child_record_${headerIndex}`} style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10 }}>
                                                        <Typography variant="body1" noWrap className={classes.boldText}>
                                                            {header.toLowerCase().includes('date') ? moment(new Date(grouped_c_record[header])).format("DD/MM/YYYY") : grouped_c_record[header]}
                                                        </Typography>
                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                    )
                                    }
                                </TableBody>
                            </Table>
                        </Collapse>
                    </Grid>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};

CollapsibleChild.propTypes = {
    classes: PropTypes.object,
    child_headers: PropTypes.array,
    record: PropTypes.object
};

const CollapsibleTableRow = (props) => {
    const { classes, searchResult, parent_headers, child_headers, collapseToggle, eleId, target_ele_id } = props;
    const [open, setOpen] = useState(false);

    // const groupedRows = getGroupedRows(searchResult);
    const master_record = searchResult ? searchResult.master : [];
    const related_records = searchResult ? searchResult.related_records : [];
    const grouped_records = searchResult ? searchResult.grouped_records : [];

    const open_ele = () => {
        collapseToggle(eleId);
        setOpen(!open);
    };

    useEffect(() => {
        if (eleId === target_ele_id) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [eleId, target_ele_id]);

    return (
        <Fragment>
            <TableRow style={{ cursor: related_records && related_records.length > 0 ? 'pointer' : '' }} onClick={() => open_ele()}>
                {
                    parent_headers.map((header, headerIndex) =>
                        <TableCell key={`collapsibleTableRow${headerIndex}`} style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10 }}>
                            <Typography variant="body1" noWrap className={open && related_records.length > 1 ? classes.boldText : null}>
                                {header.toLowerCase().includes('date') ? moment(new Date(master_record[header])).format("DD/MM/YYYY") : master_record[header]}
                            </Typography>
                        </TableCell>
                    )
                }
            </TableRow>
            {
                related_records && related_records.length > 0 &&
                <TableRow>
                    <TableCell colSpan={child_headers.length} style={{ paddingBottom: 0, paddingTop: 0, padding: 0, overflowY: 'hidden', overflowX: 'auto' }} >
                        {/* {getCollapsibeContent(child_headers, related_records, grouped_records)} */}
                        <Grid container xs={12}>
                            <Collapse in={open} timeout="auto" unmountOnExit style={{ width: '100%' }}>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                {
                                                    child_headers.map((header, headerIndex) =>
                                                        <TableCell key={`collapsibleTableRow2${headerIndex}`} style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10 }}>
                                                            <Typography variant="body1" noWrap className={open && related_records.length > 1 ? classes.boldText : null}>
                                                                {header}
                                                            </Typography>
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                !(grouped_records && grouped_records.length > 0) ?
                                                related_records.map((record, recordIndex) =>
                                                    <TableRow key={`collapsibleTableRowRecord${recordIndex}`}>
                                                        {
                                                            child_headers && child_headers.map((header, headerIndex) =>
                                                                <TableCell key={`child_record_${headerIndex}`} style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 10, paddingBottom: 10 }}>
                                                                    <Typography variant="body1" noWrap className={classes.boldText}>
                                                                        {header.toLowerCase().includes('date') ? moment(new Date(record[header])).format("DD/MM/YYYY") : record[header]}
                                                                    </Typography>
                                                                </TableCell>
                                                            )
                                                        }
                                                    </TableRow>
                                                )
                                                :
                                                grouped_records && grouped_records.map((record, recordIndex) =>
                                                    <CollapsibleChild key={`Collapsible_child_${recordIndex}`} classes={classes} child_headers={child_headers} record={record} recordIndex={recordIndex} />
                                                )
                                            }
                                        </TableBody>
                                    </Table>
                            </Collapse>
                        </Grid>
                    </TableCell>
                </TableRow>
            }
        </Fragment>
    );
};

CollapsibleTableRow.propTypes = {
    classes: PropTypes.object,
    searchResult: PropTypes.object,
    parent_headers: PropTypes.array,
    child_headers: PropTypes.array,
    collapseToggle: PropTypes.func,
    eleId: PropTypes.number,
    target_ele_id: PropTypes.number
};

export default withStyles((theme) => ({
    ...MasterSearchDetailStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(CollapsibleTableRow);