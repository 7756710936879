import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, withStyles, Switch, Typography, FormGroup, FormControlLabel, Divider, AccordionSummary, Accordion, AccordionDetails, Button, FormHelperText } from '@material-ui/core';
import classnames from 'classnames';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import { getModuleConfig, updateModuleConfig, getLicenseModules, showUserCustomMsg, updateLicenseConnectionStatus, getOrganizationConfig, updateConnectorConfig, updateLicenseConfiguration } from '../../actions/settingActions';
import Loader from '../Loaders/Loader.jsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getChangedProperties } from '../../helpers/appHelpers';

const Modules = (props) => {
    const { classes, organizationId, isSystemLicense } = props;
    const [moduleConfig, setModuleConfig] = useState({ pull: true, scan: true, metadata: true, query: true, input_connectors: [] });
    const [unChangedModuleConfig, setUnChangedModuleConfig] = useState({ pull: true, scan: true, metadata: true, query: true });
    const [systemConfig, setSystemConfig] = useState({});
    const [isLoading, setLoading] = useState(true);
    const [isChangedHours, setChangedHours] = useState(false);
    const connections = useSelector(({ datasource }) => datasource.moduleConnectionTypes);
    const [inputConnections, setInputConnections] = useState([...connections.filter((connection) => connection.is_input_connection)]);
    const [outputConnections, setOutputConnections] = useState([...connections.filter((connection) => connection.is_output_connection)]);
    const [inBoundConnections, setInBoundConnections] = useState([...connections.filter((connection) => connection.is_output_connection)]);
    const dispatch = useDispatch();
    const [selectedPanel, setSelectedPanel] = useState('Catalog');
    const [isChanged, setChanged] = useState(false);
    const [trackChangedProperties, setTrackChangedProperties] = useState([]);
    const [linceseInputConnections, setLicenseInputConnection] = useState([]);

    const getConfigDetails = useCallback(() => {
        Promise.all([dispatch(getModuleConfig(organizationId)), dispatch(getLicenseModules(organizationId))]).then((response) => {
            if (response.length) {
                let systemModuleConfig = {};
                if (response[0]) {
                    systemModuleConfig = { ...response[0].data };
                    setChangedHours(response[0].time_expire);
                    setModuleConfig({ ...systemModuleConfig });
                    setUnChangedModuleConfig({ ...systemModuleConfig });
                }
                if (response[1]) {
                    const licenseConfig = { ...response[1] };
                    const connectionList = JSON.parse(JSON.stringify(connections));
                    if (licenseConfig.input_connectors) {
                        let inputConnection = [];
                        if (((!organizationId && isSystemLicense) || (organizationId && !isSystemLicense)) && licenseConfig.no_of_connectors !== systemModuleConfig.input_connectors.length) {
                            inputConnection = connectionList.filter((connection) => connection.is_input_connection);
                            setLicenseInputConnection([...licenseConfig.input_connectors]);
                        } else {
                            inputConnection = connectionList.filter((connection) => connection.is_input_connection && licenseConfig.input_connectors.includes(connection.id));
                        }
                        setInputConnections([...inputConnection]);
                    }
                    if (licenseConfig.outbound_connectors) {
                        const outputConnection = connectionList.filter((connection) => licenseConfig.outbound_connectors.includes(connection.id)).map((connection) => {
                            return {
                                ...connection,
                                name: connection.name.toLowerCase() === "file" ? "Disk" : connection.name
                            };
                        });
                        setOutputConnections([...outputConnection]);
                    }
                    if (licenseConfig.inbound_connectors) {
                        const inBoundConnection = connectionList.filter((connection) => licenseConfig.inbound_connectors.includes(connection.id)).map((connection) => {
                            return {
                                ...connection,
                                name: connection.name.toLowerCase() === "file" ? "Disk" : connection.name
                            };
                        });
                        setInBoundConnections([...inBoundConnection]);
                    }
                    setSystemConfig({ ...licenseConfig });
                }
            }
            setLoading(false);
        });
    }, [connections, dispatch, isSystemLicense, organizationId]);


    useEffect(() => {
        getConfigDetails();
    }, [getConfigDetails]);

    const changeProperty = (name, value, status) => {
        setChanged(true);
        if (name === "input_connectors" || name === "outbound_connectors" || name === "inbound_connectors") {
            const index = moduleConfig[name].indexOf(value);
            if (index !== -1) {
                moduleConfig[name].splice(index, 1);
            } else {
                moduleConfig[name].push(value);
            }
            value = moduleConfig[name];
            if (name === "input_connectors") {
                if (moduleConfig.input_connectors.length === moduleConfig.no_of_connectors) {
                    dispatch(showUserCustomMsg("Connector can be changed only after 72 hours"));
                }
            }
        } else {
            moduleConfig[name] = value;
            switch (name) {
                case 'catalog':
                    moduleConfig["catalog_search"] = value;
                    moduleConfig["catalog_connect"] = value;
                    moduleConfig.pull = value;
                    moduleConfig.scan = value;
                    moduleConfig.query = value;
                    moduleConfig.metadata = value;
                    if (value) {
                        moduleConfig["input_connectors"] = inputConnections.map((connection) => connection.id);
                        moduleConfig["outbound_connectors"] = outputConnections.map((connection) => connection.id);
                        moduleConfig["inbound_connectors"] = outputConnections.map((connection) => connection.id);
                    } else {
                        moduleConfig["input_connectors"] = [];
                        moduleConfig["outbound_connectors"] = [];
                        moduleConfig["inbound_connectors"] = [];
                    }
                    break;
                case 'profile':
                    moduleConfig["profile_learning"] = value;
                    moduleConfig["profile_query"] = value;
                    moduleConfig["profile_rules"] = value;
                    break;
                case 'curate':
                    moduleConfig["curate_learning"] = value;
                    moduleConfig["curate_basic"] = value;
                    moduleConfig["curate_advanced"] = value;
                    moduleConfig["curate_reference"] = value;
                    break;
                default:
                    break;
            }
        }
        const trackIndex = trackChangedProperties.indexOf(name);
        if (trackIndex === -1) {
            trackChangedProperties.push(name);
            setTrackChangedProperties([...trackChangedProperties]);
        }
        setModuleConfig({ ...moduleConfig });
        // updateConfig(name, value, status);
    };

    const updateConfig = (status) => {
        // const moduleStatus = (name === "input_connectors" || name === "outbound_connectors" || name === "inbound_connectors") ? status : value;
        if ((moduleConfig.input_connectors.length > systemConfig.no_of_connectors) && ((!organizationId && isSystemLicense) || (organizationId && !isSystemLicense))) {
            return false;
        }
        const requestParams = {
            module_config: moduleConfig,
            changed_properties: getChangedProperties(unChangedModuleConfig, moduleConfig, trackChangedProperties)
        };
        setChanged(false);
        setUnChangedModuleConfig({ ...moduleConfig });
        setTrackChangedProperties([]);
        setLicenseInputConnection([...moduleConfig.input_connectors]);
        if (trackChangedProperties.includes('input_connectors') && ((!organizationId && isSystemLicense) || (organizationId && !isSystemLicense))) {
            dispatch(updateLicenseConfiguration(organizationId, { 'input_connectors': moduleConfig.input_connectors }));
        }
        const updaterequestParams = { 'input_connectors': moduleConfig.input_connectors };
        setTimeout(() => {
            dispatch(updateConnectorConfig(organizationId, updaterequestParams)).then(() => {
                dispatch(getOrganizationConfig());
                dispatch(getLicenseModules(organizationId));
                dispatch(updateLicenseConnectionStatus());
            });
        }, 100);

        dispatch(updateModuleConfig(requestParams, organizationId));
    };

    const toggleExpansionPanel = (panel) => (event, isExpanded) => {
        isExpanded = isExpanded ? panel : false;
        setSelectedPanel(isExpanded);
    };

    const validateConnector = (connectionList = []) => {
        if (moduleConfig.allocated_input_connectors && moduleConfig.allocated_input_connectors.length) {
            if (connectionList.length === moduleConfig.no_of_connectors) {
                return true;
            }
            return false;
        }
        return false;
    };

    const filterConnections = (connectionList = []) => {
        if (moduleConfig.allocated_input_connectors && moduleConfig.allocated_input_connectors.length) {
            connectionList = connectionList.filter((data) => moduleConfig.allocated_input_connectors.includes(data.id));
        }
        return connectionList;
    };

    return (
        <Grid>
            {
                isLoading ? <Loader /> :

                    <ValidatorForm form="modules" onSubmit={() => updateConfig()} >
                        <Grid container>
                            {
                                (systemConfig.catalog) &&
                                <Grid item xs={12} className={classes.marginTop20}>
                                    <Accordion expanded={selectedPanel === "Catalog"} onChange={toggleExpansionPanel("Catalog")} className={classes.storageAcoordion}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header">
                                            <Typography className={classes.heading}>
                                                {"Catalog"}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container justify="space-between">
                                                <Grid item />
                                                <Grid item>
                                                    <FormControlLabel
                                                        className={classes.formLabel}
                                                        control={
                                                            <Switch
                                                                color="secondary"
                                                                name="catalog"
                                                                value="catalog"
                                                                checked={Boolean(moduleConfig.catalog)}
                                                                onChange={(event) => changeProperty(event.target.name, !moduleConfig.catalog)}
                                                            />
                                                        }
                                                        label="ON"
                                                    />
                                                </Grid>
                                                <Divider className={classes.divider} />
                                                <Grid item xs={12} className={classes.formGroup}>
                                                    <FormGroup row>
                                                        <FormControlLabel
                                                            className={classnames(classes.formLabelSection, classes.formLabel)}
                                                            control={
                                                                <Switch
                                                                    color="secondary"
                                                                    name="catalog_search"
                                                                    checked={Boolean(moduleConfig.catalog_search)}
                                                                    disabled={!moduleConfig.catalog}
                                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.catalog_search)} />
                                                            }
                                                            label="Search"
                                                        />
                                                        <FormControlLabel
                                                            className={classnames(classes.formLabelSection, classes.formLabel)}
                                                            control={
                                                                <Switch
                                                                    color="secondary"
                                                                    name="catalog_connect"
                                                                    checked={Boolean(moduleConfig.catalog_connect)}
                                                                    disabled={!moduleConfig.catalog}
                                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.catalog_connect)} />
                                                            }
                                                            label="Connect"
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid item xs={12} className={classnames(classes.subModuleSection, classes.marginTop20)}>
                                                    <Grid container justify="space-between">
                                                        <Grid item>
                                                            <Typography variant="h6">
                                                                Connection Config
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider className={classes.divider} />
                                                    <Grid className={classes.formGroup}>
                                                        <FormGroup row>
                                                            {
                                                                systemConfig.pull &&
                                                                <FormControlLabel
                                                                    className={classnames(classes.formLabelSection, classes.formLabel)}
                                                                    control={<Switch disabled={!moduleConfig.catalog} color="secondary" name="pull" value={moduleConfig.pull} onChange={() => changeProperty("pull", !moduleConfig.pull)} checked={moduleConfig.pull} />}
                                                                    label={"Pull"}
                                                                />
                                                            }
                                                            {
                                                                systemConfig.scan &&
                                                                <FormControlLabel
                                                                    className={classnames(classes.formLabelSection, classes.formLabel)}
                                                                    control={<Switch disabled={!moduleConfig.catalog} color="secondary" name="scan" value={moduleConfig.scan} onChange={() => changeProperty("scan", !moduleConfig.scan)} checked={moduleConfig.scan} />}
                                                                    label={"Scan"}
                                                                />
                                                            }
                                                            {
                                                                systemConfig.query &&
                                                                <FormControlLabel
                                                                    className={classnames(classes.formLabelSection, classes.formLabel)}
                                                                    control={<Switch disabled={!moduleConfig.catalog} color="secondary" name="query" value={moduleConfig.query} onChange={() => changeProperty("query", !moduleConfig.query)} checked={moduleConfig.query} />}
                                                                    label={"Query"}
                                                                />
                                                            }
                                                            {
                                                                systemConfig.metadata &&
                                                                <FormControlLabel
                                                                    className={classnames(classes.formLabelSection, classes.formLabel)}
                                                                    control={<Switch disabled={!moduleConfig.catalog} color="secondary" name="metadata" value={moduleConfig.metadata} onChange={() => changeProperty("metadata", !moduleConfig.metadata)} checked={moduleConfig.metadata} />}
                                                                    label={"Metadata"}
                                                                />
                                                            }
                                                        </FormGroup>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={12} className={classnames(classes.subModuleSection, classes.marginTop20)}>
                                                    <Grid container justify="space-between">
                                                        <Grid item>
                                                            <Typography variant="h6">
                                                                Connectors
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider className={classes.divider} />
                                                    <Grid className={classes.formGroup}>
                                                        {
                                                            (moduleConfig.input_connectors.length > systemConfig.no_of_connectors) &&
                                                            <FormHelperText className={classes.errorTxt}>
                                                                {`Maximum Connection Limit ${systemConfig.no_of_connectors} Reached`}
                                                            </FormHelperText>
                                                        }
                                                        <FormGroup row>
                                                            {
                                                                filterConnections(inputConnections).map((type, index) =>
                                                                    <FormControlLabel
                                                                        key={index}
                                                                        className={classnames(classes.formLabelSection, classes.formLabel)}
                                                                        control={
                                                                            // eslint-disable-next-line no-nested-ternary
                                                                            <Switch disabled={(!moduleConfig.catalog || linceseInputConnections.indexOf(type.id) !== -1) ? true : validateConnector(inputConnections) ? !(isChangedHours) : false}
                                                                                color="secondary"
                                                                                onChange={() => changeProperty("input_connectors", type.id, !moduleConfig.catalog)}
                                                                                checked={moduleConfig.input_connectors ? moduleConfig.input_connectors.indexOf(type.id) !== -1 : false} />
                                                                        }
                                                                        label={type.name}
                                                                    />
                                                                )
                                                            }
                                                        </FormGroup>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} className={classnames(classes.subModuleSection, classes.marginTop20)}>
                                                    <Grid container justify="space-between">
                                                        <Grid item>
                                                            <Typography variant="h6">
                                                                InBound Connectors
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider className={classes.divider} />
                                                    <Grid className={classes.formGroup}>
                                                        <FormGroup row>
                                                            {
                                                                inBoundConnections.map((type, index) =>
                                                                    <FormControlLabel
                                                                        key={index}
                                                                        className={classnames(classes.formLabelSection, classes.formLabel)}
                                                                        control={<Switch disabled={!moduleConfig.catalog} color="secondary" onChange={() => changeProperty("inbound_connectors", type.id, !moduleConfig.catalog)} checked={moduleConfig.inbound_connectors ? moduleConfig.inbound_connectors.indexOf(type.id) !== -1 : false} />}
                                                                        label={type.name}
                                                                    />
                                                                )
                                                            }
                                                        </FormGroup>
                                                    </Grid>
                                                </Grid>
                                                <Grid item xs={12} className={classnames(classes.subModuleSection, classes.marginTop20)}>
                                                    <Grid container justify="space-between">
                                                        <Grid item>
                                                            <Typography variant="h6">
                                                                OutBound Connectors
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider className={classes.divider} />
                                                    <Grid className={classes.formGroup}>
                                                        <FormGroup row>
                                                            {
                                                                outputConnections.map((type, index) =>
                                                                    <FormControlLabel
                                                                        key={index}
                                                                        className={classnames(classes.formLabelSection, classes.formLabel)}
                                                                        control={<Switch disabled={!moduleConfig.catalog} color="secondary" onChange={() => changeProperty("outbound_connectors", type.id, !moduleConfig.catalog)} checked={moduleConfig.outbound_connectors ? moduleConfig.outbound_connectors.indexOf(type.id) !== -1 : false} />}
                                                                        label={type.name}
                                                                    />
                                                                )
                                                            }
                                                        </FormGroup>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            }
                            {
                                (systemConfig.profile) &&
                                <Grid item xs={12} className={classes.marginTop20}>
                                    <Accordion expanded={selectedPanel === "Profile"} onChange={toggleExpansionPanel("Profile")} className={classes.storageAcoordion}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header">
                                            <Typography className={classes.heading}>
                                                {"Profile"}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.paddingBottom0}>
                                            <Grid container justify="space-between">
                                                <Grid item />
                                                <Grid item>
                                                    <FormControlLabel
                                                        className={classes.formLabel}
                                                        control={
                                                            <Switch
                                                                color="secondary"
                                                                name="profile"
                                                                checked={Boolean(moduleConfig.profile)}
                                                                onChange={(event) => changeProperty(event.target.name, !moduleConfig.profile)} />
                                                        }
                                                        label="ON"
                                                    />
                                                </Grid>
                                                <Divider className={classes.divider} />
                                                <Grid item xs={12}>
                                                    <FormGroup row className={classes.paddingLeft12}>
                                                        <FormControlLabel
                                                            className={classnames(classes.formLabelSection, classes.formLabel)}
                                                            control={
                                                                <Switch disabled={!moduleConfig.profile}
                                                                    color="secondary"
                                                                    name="profile_learning"
                                                                    checked={Boolean(moduleConfig.profile_learning)}
                                                                    value={moduleConfig.profile_learning}
                                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.profile_learning)}
                                                                />
                                                            }
                                                            label="Learning"
                                                        />
                                                        <FormControlLabel
                                                            className={classnames(classes.formLabelSection, classes.formLabel)}
                                                            control={<Switch disabled={!moduleConfig.profile} color="secondary" name="profile_query" checked={Boolean(moduleConfig.profile_query)} value={moduleConfig.profile_query} onChange={(event) => changeProperty(event.target.name, !moduleConfig.profile_query)} />}
                                                            label="Query"
                                                        />
                                                        <FormControlLabel
                                                            className={classnames(classes.formLabelSection, classes.formLabel)}
                                                            control={<Switch disabled={!moduleConfig.profile} color="secondary" name="profile_rules" checked={Boolean(moduleConfig.profile_rules)} value={moduleConfig.profile_rules} onChange={(event) => changeProperty(event.target.name, !moduleConfig.profile_rules)} />}
                                                            label="Rules"
                                                        />
                                                    </FormGroup>
                                                </Grid>

                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            }
                            {
                                (systemConfig.curate) &&
                                <Grid item xs={12} className={classes.marginTop20}>
                                    <Accordion expanded={selectedPanel === "Curate"} onChange={toggleExpansionPanel("Curate")} className={classes.storageAcoordion}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header">
                                            <Typography className={classes.heading}>
                                                {"Curate"}
                                            </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails className={classes.paddingBottom0}>
                                            <Grid container justify="space-between">
                                                <Grid item />
                                                <Grid item>
                                                    <FormControlLabel
                                                        className={classes.formLabel}
                                                        control={
                                                            <Switch
                                                                color="secondary"
                                                                name="curate"
                                                                checked={Boolean(moduleConfig.curate)}
                                                                onChange={(event) => changeProperty(event.target.name, !moduleConfig.curate)} />
                                                        }
                                                        label="ON"
                                                    />
                                                </Grid>
                                                <Divider className={classes.divider} />
                                                <Grid item xs={12} className={classes.formGroup}>
                                                    <FormGroup row>
                                                        <FormControlLabel
                                                            className={classnames(classes.formLabelSection, classes.formLabel)}
                                                            control={
                                                                <Switch
                                                                    color="secondary"
                                                                    name="curate_learning"
                                                                    disabled={!moduleConfig.curate}
                                                                    checked={Boolean(moduleConfig.curate_learning)}
                                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.curate_learning)} />
                                                            }
                                                            label="Learning"
                                                        />
                                                        <FormControlLabel
                                                            className={classnames(classes.formLabelSection, classes.formLabel)}
                                                            control={
                                                                <Switch
                                                                    color="secondary"
                                                                    name="curate_basic"
                                                                    disabled={!moduleConfig.curate}
                                                                    checked={Boolean(moduleConfig.curate_basic)}
                                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.curate_basic)} />
                                                            }
                                                            label="Basic"
                                                        />
                                                        <FormControlLabel
                                                            className={classnames(classes.formLabelSection, classes.formLabel)}
                                                            control={
                                                                <Switch
                                                                    color="secondary"
                                                                    name="curate_advanced"
                                                                    disabled={!moduleConfig.curate}
                                                                    checked={Boolean(moduleConfig.curate_advanced)}
                                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.curate_advanced)} />
                                                            }
                                                            label="Advanced"
                                                        />
                                                        <FormControlLabel
                                                            className={classnames(classes.formLabelSection, classes.formLabel)}
                                                            control={
                                                                <Switch
                                                                    color="secondary"
                                                                    name="curate_reference"
                                                                    disabled={!moduleConfig.curate}
                                                                    checked={Boolean(moduleConfig.curate_reference)}
                                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.curate_reference)} />
                                                            }
                                                            label="Reference"
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            }
                            {
                                (systemConfig.conversation) &&
                                <Grid container justify="space-between" className={classes.moduleSection}>
                                    <Grid item>
                                        <Typography className={classes.moduleTitle}>
                                            Conversation
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className={classes.formLabel}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="conversation"
                                                    checked={Boolean(moduleConfig.conversation)}
                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.conversation)} />
                                            }
                                            label="ON"
                                        />
                                    </Grid>
                                    <Divider className={classes.divider} />
                                </Grid>
                            }
                            {
                                (systemConfig.lineage) &&
                                <Grid container justify="space-between" className={classes.moduleSection}>
                                    <Grid item>
                                        <Typography className={classes.moduleTitle}>
                                            Lineage
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className={classes.formLabel}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="lineage"
                                                    checked={Boolean(moduleConfig.lineage)}
                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.lineage)} />
                                            }
                                            label="ON"
                                        />
                                    </Grid>
                                    <Divider className={classes.divider} />
                                </Grid>
                            }
                            {
                                (systemConfig.semantics) &&
                                <Grid container justify="space-between" className={classes.moduleSection}>
                                    <Grid item>
                                        <Typography className={classes.moduleTitle}>
                                            Semantics
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className={classes.formLabel}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="semantics"
                                                    checked={Boolean(moduleConfig.semantics)}
                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.semantics)} />
                                            }
                                            label="ON"
                                        />
                                    </Grid>
                                    <Divider className={classes.divider} />
                                </Grid>
                            }
                            {
                                (systemConfig.semanticssearch) &&
                                <Grid container justify="space-between" className={classes.moduleSection}>
                                    <Grid item>
                                        <Typography className={classes.moduleTitle}>
                                            Semantics Search
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className={classes.formLabel}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="semanticssearch"
                                                    checked={Boolean(moduleConfig.semanticssearch)}
                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.semanticssearch)} />
                                            }
                                            label="ON"
                                        />
                                    </Grid>
                                    <Divider className={classes.divider} />
                                </Grid>
                            }
                            {
                                (systemConfig.drift) &&
                                <Grid container justify="space-between" className={classes.moduleSection}>
                                    <Grid item>
                                        <Typography className={classes.moduleTitle}>
                                            Drift
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className={classes.formLabel}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="drift"
                                                    checked={Boolean(moduleConfig.drift)}
                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.drift)} />
                                            }
                                            label="ON"
                                        />
                                    </Grid>
                                    <Divider className={classes.divider} />
                                </Grid>
                            }
                            {
                                (systemConfig.dashboard) &&
                                <Grid container justify="space-between" className={classes.moduleSection}>
                                    <Grid item>
                                        <Typography className={classes.moduleTitle}>
                                            Dashboard
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className={classes.formLabel}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="dashboard"
                                                    checked={Boolean(moduleConfig.dashboard)}
                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.dashboard)} />
                                            }
                                            label="ON"
                                        />
                                    </Grid>
                                    <Divider className={classes.divider} />
                                </Grid>
                            }
                            {
                                (systemConfig.action_items) &&
                                <Grid container justify="space-between" className={classes.moduleSection}>
                                    <Grid item>
                                        <Typography className={classes.moduleTitle}>
                                            Action Items
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className={classes.formLabel}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="action_items"
                                                    checked={Boolean(moduleConfig.action_items)}
                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.action_items)} />
                                            }
                                            label="ON"
                                        />
                                    </Grid>
                                    <Divider className={classes.divider} />
                                </Grid>
                            }
                            {
                                (systemConfig.collaboration) &&
                                <Grid container justify="space-between" className={classes.moduleSection}>
                                    <Grid item>
                                        <Typography className={classes.moduleTitle}>
                                            {'Collaboration'}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className={classes.formLabel}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="collaboration"
                                                    checked={Boolean(moduleConfig.collaboration)}
                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.collaboration)} />
                                            }
                                            label="ON"
                                        />
                                    </Grid>
                                    <Divider className={classes.divider} />
                                </Grid>
                            }
                            {
                                (systemConfig.monitoring) &&
                                <Grid container justify="space-between" className={classes.moduleSection}>
                                    <Grid item>
                                        <Typography className={classes.moduleTitle}>
                                            {'Monitoring'}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className={classes.formLabel}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="monitoring"
                                                    checked={Boolean(moduleConfig.monitoring)}
                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.monitoring)} />
                                            }
                                            label="ON"
                                        />
                                    </Grid>
                                    <Divider className={classes.divider} />
                                </Grid>
                            }
                            {
                                (systemConfig.domain) &&
                                <Grid container justify="space-between" className={classes.moduleSection}>
                                    <Grid item>
                                        <Typography className={classes.moduleTitle}>
                                            {'Domain'}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className={classes.formLabel}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="domain"
                                                    checked={Boolean(moduleConfig.domain)}
                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.domain)} />
                                            }
                                            label="ON"
                                        />
                                    </Grid>
                                    <Divider className={classes.divider} />
                                </Grid>
                            }
                            {
                                (systemConfig.api) &&
                                <Grid container justify="space-between" className={classes.moduleSection}>
                                    <Grid item>
                                        <Typography className={classes.moduleTitle}>
                                            {'Api'}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className={classes.formLabel}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="api"
                                                    checked={Boolean(moduleConfig.api)}
                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.api)} />
                                            }
                                            label="ON"
                                        />
                                    </Grid>
                                    <Divider className={classes.divider} />
                                </Grid>
                            }
                            {
                                (systemConfig.search) &&
                                <Grid container justify="space-between" className={classes.moduleSection}>
                                    <Grid item>
                                        <Typography className={classes.moduleTitle}>
                                            {'Search'}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className={classes.formLabel}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="search"
                                                    checked={Boolean(moduleConfig.search)}
                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.search)} />
                                            }
                                            label="ON"
                                        />
                                    </Grid>
                                    <Divider className={classes.divider} />
                                </Grid>
                            }
                            {
                                (systemConfig.export_metrics) &&
                                <Grid container justify="space-between" className={classes.moduleSection}>
                                    <Grid item>
                                        <Typography className={classes.moduleTitle}>
                                            {'Export Metrics'}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <FormControlLabel
                                            className={classes.formLabel}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="export_metrics"
                                                    checked={Boolean(moduleConfig.export_metrics)}
                                                    onChange={(event) => changeProperty(event.target.name, !moduleConfig.export_metrics)} />
                                            }
                                            label="ON"
                                        />
                                    </Grid>
                                    <Divider className={classes.divider} />
                                </Grid>
                            }
                            {
                                isChanged &&
                                <Grid item xs={12}>
                                    <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classnames(classes.actionButtons, classes.submitBtn)}>
                                        Save
                                        {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                    </Button>
                                </Grid>
                            }
                        </Grid>
                    </ValidatorForm>
            }
        </Grid>
    );
};

Modules.propTypes = {
    classes: PropTypes.object,
    organizationId: PropTypes.number,
    isSystemLicense: PropTypes.bool
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}))(Modules);