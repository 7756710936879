import palette from "../theme/palette";
import { appConstants } from "../constants/appConstants";
import * as queryBuilderUtil from "../components/RuleBuilder/QueryBuilderUtil.jsx";
import config from "../components/RuleBuilder/QueryBuilderConfig.jsx";
import ProfileChartFilterOperators from "../constants/chartOperators";

export const getUserProperty = () => {
	const user = localStorage.getItem('user');
	return user ? JSON.parse(user) : user;
};

export const getCancellationToken = () => {
	const controller = new AbortController();
	return { signal: controller.signal };
};


export const setCookie = (cookieName, value) => {
	const now = new Date();
	now.setTime(now.getTime() + 8 * 3600 * 1000);
	document.cookie = `${cookieName}=${value}; expires=${now.toUTCString()}; path=/`;
};

export const getCookie = (cookieName) => {
	const cookie = `${cookieName}=`;
	const decodeCookie = decodeURIComponent(document.cookie);
	const cookieData = decodeCookie.split(';');
	for (let i = 0; i < cookieData.length; i++) {
		const c = cookieData[i].trim();
		if (c.indexOf(cookie) === 0) {
			return c.substring(cookie.length, c.length);
		}
	}
	return "";
};

export const removeCookie = () => {
	document.cookie.split(";").forEach((c) => { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
};

export const getName = (users, userid) => {
	const userList = users ? users : [];
	const userInfo = userList.find((user) => user.id === userid);
	if (userInfo) {
		return userInfo.first_name !== "" ? `${userInfo.first_name} ${userInfo.last_name}` : userInfo.email;
	}
	return '';
};

export const getInitials = (name) => {
	name = name ? name : '';
	let user = '';
	if (name.includes('@')) {
		user = name.substring(0, 2).toUpperCase();
	} else {
		user = name.match(/\b(\w)/g);
		if (user) {
			user = user[0] + user[user.length - 1];
			user = user.toUpperCase();
		}
	}
	return user;
};

export const bytesToSize = ((bytes) => {
	if (bytes === 0) {
		return `0 B`;
	}
	const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
	const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
	if (i === 0) {
		return `${bytes} ${sizes[i]}`;
	}
	return `${(bytes / (1024 ** i)).toFixed(2)} ${sizes[i]}`;
});

export const getDuration = (seconds) => {
	seconds = Number(seconds);

	const hour = Math.floor(seconds / 3600);
	const minute = Math.floor(seconds % 3600 / 60);
	const sec = Math.floor(seconds % 3600 % 60);

	const hourValue = hour ? `${hour}h` : '';
	const minuteValue = minute ? `${minute}m` : '';
	const secValue = sec ? `${sec}s` : '';
	return `${hourValue} ${minuteValue} ${secValue}`;
};

export const getValue = (value) => {
	let progressText = value ? parseFloat(value) : 0;
	progressText = Math.abs(progressText);
	if (progressText > -1 && progressText < 1) {
		let decimalCount = progressText.toString().split('.');
		decimalCount = (decimalCount && decimalCount.length) ? decimalCount[decimalCount.length - 1].length : '';
		if (decimalCount <= 2) {
			return value;
		}
	}
	if (Math.round(progressText) !== progressText) {
		progressText = progressText.toString().substring(0, 4);
	}

	return progressText;
};

export const getDataTypeColor = (datatype) => {
	switch (datatype.toLowerCase()) {
		case 'integer':
		case 'numeric':
		case 'money':
			return palette.datatypeColors.integer;
		case 'date':
		case 'time':
		case 'datetime':
		case 'datetimeoffset':
			return palette.datatypeColors.date;
		default:
			return palette.datatypeColors.text;
	}
};

export const wrapText = (element, width) => {
	let textLength = element.node().getComputedTextLength();
	let text = element.text();
	while (textLength > width && text.length > 0) {
		text = text.slice(0, -1);
		element.text(text + '...');
		textLength = element.node().getComputedTextLength();
	}
};

export const getRandomColor = (text) => {
	const colors = ["#FF685C", "#E8DAB2", "#C0D6DF", "#77BA99", "#F68E5F", "#F7C59F", "#B5D99C", "#A8E6DF", "#798E56", "#F6867F", "#464C8B", "#EA8CC0", "#FFE08E", "#C1AA5D", "#BAC7B1", "#75A2C4", "#6A7890", "#C897A2", "#D1C6B1", "#EFEFD0", "#B5D99C", "#586BA4", "#F5DD90", "#D7C0D0"];
	text = text ? text : '';
	const charCodes = text
		.split('')
		.map((char) => char.charCodeAt(0))
		.join('');
	const codes = parseInt(charCodes, 10);
	const colorCode = colors[codes % colors.length];
	return colorCode;
};

export const isNumeric = (dataType) => {
	return (dataType.toLowerCase() === 'integer' || dataType.toLowerCase() === 'numeric'
		|| dataType.toLowerCase() === 'money');
};

export const isDateTime = (dataType) => {
	return (dataType.toLowerCase() === 'date' || dataType.toLowerCase() === 'datetime'
		|| dataType.toLowerCase() === 'datetimeoffset' || dataType.toLowerCase() === 'time');
};

export const getFieldType = (dataType) => {
	let fieldType = 'text';
	if (!dataType) {
		return fieldType;
	}

	if (isNumeric(dataType)) {
		fieldType = 'numeric';
	} else if (isDateTime(dataType)) {
		fieldType = 'datetime';
	} else {
		fieldType = 'text';
	}
	return fieldType;
};

export const getAuditLogText = (property, value, type, text) => {
	const data = {
		text: '',
		type: ''
	};
	switch (property) {
		case 'name':
			data.text = value;
			data.type = type === "dataset" ? "dataset name" : "datasource name";
			return data;
		case 'description':
			data.type = "description";
			return data;
		case 'tags':
		case 'updateTags':
			data.type = "tags";
			return data;
		case 'updateTerms':
			data.type = "glossary terms";
			return data;
		case 'terms_description':
			data.type = "terms";
			return data;
		case 'platform_use':
			data.type = "platform_use";
			return data;
		case 'user_share':
			data.type = "user_share";
			return data;
		case 'domain':
			data.type = "domain";
			data.text = value;
			return data;
		case 'removeDomain':
			data.type = "remove domain";
			data.text = value;
			return data;
		case 'export':
			data.type = "export";
			data.text = value;
			return data;
		case 'import':
			data.type = "import";
			data.text = value;
			return data;
		case 'edit comment':
			data.type = "edit comment";
			data.text = text;
			return data;
		case 'delete comment':
			data.type = "delete comment";
			data.text = text;
			return data;
		case 'add comment':
			data.type = "add comment";
			data.text = text;
			return data;
		default:
			return data;
	}
};

export const getCountChartRule = (attribute, filterType, ruleName, connectionType, isScan) => {
	ruleName = ruleName.toLowerCase().replace(' count', '');
	const rule = queryBuilderUtil.createRule({
		defaultField: attribute.name,
		defaultFieldType: queryBuilderUtil.getFieldType(attribute.type),
		attributeType: attribute.type,
		connectionType,
		isScan
	});
	const isValid = (filterType === appConstants.QueryFilterTypes[0]);
	getOperator(ruleName, rule, isValid);
	if (ruleName === 'white space' && rule.operator && rule.operator.operator) {
		rule.operator.operator = rule.operator.operator.replace('trim(field)', 'field');
	}
	if (rule.operator && rule.operator.valueRequired) {
		getCountChartRuleValue(ruleName, rule, connectionType, isScan);
	}
	return rule;
};

const getOperator = (ruleName, rule, isValid) => {
	let operator = null;
	let operatorName = '';
	switch (ruleName.toLowerCase()) {
		case 'blank':
			operatorName = isValid ? 'is empty' : 'is not empty';
			operator = config.Operators.find((p) => p.label === operatorName);
			break;
		case 'null':
			operatorName = isValid ? 'is null' : 'is not null';
			operator = config.Operators.find((p) => p.label === operatorName);
			break;
		case 'unique':
			operatorName = isValid ? 'is unique' : 'is not unique';
			operator = ProfileChartFilterOperators.Count.find((p) => p.label === operatorName);
			break;
		case 'distinct':
			operatorName = isValid ? 'is distinct' : 'is duplicate';
			operator = ProfileChartFilterOperators.Count.find((p) => p.label === operatorName);
			break;
		case 'duplicate':
			operatorName = isValid ? 'is duplicate' : 'is distinct';
			operator = ProfileChartFilterOperators.Count.find((p) => p.label === operatorName);
			break;
		case 'missing':
			operatorName = isValid ? 'is missing' : 'is not missing';
			operator = ProfileChartFilterOperators.Count.find((p) => p.label === operatorName);
			break;
		case 'infinite':
			operatorName = isValid ? 'is infinite' : 'is not infinite';
			operator = ProfileChartFilterOperators.Count.find((p) => p.label === operatorName);
			break;
		case 'special characters':
		case 'characters':
		case 'digits':
		case 'white space':
		case 'numeric values':
			operatorName = isValid ? 'matches' : 'not matches';
			operator = ProfileChartFilterOperators.Count.find((p) => p.label === operatorName);
			break;
		case 'zero':
		case 'zero values':
			operatorName = isValid ? 'matches' : 'not matches';
			operator = ProfileChartFilterOperators.Count.find((p) => p.label === operatorName);
			break;
		default:
			operator = config.Operators.find((p) => p.label === operatorName);
			break;
	}
	if (operator) {
		operator.isChartOperation = true;
	}
	rule.operator = operator;
};

const getCountChartRuleValue = (ruleName, rule, connectionType, isScan) => {
	const isSql = (connectionType === 'sql');
	switch (ruleName.toLowerCase()) {
		case 'special characters':
			if (isSql && isScan) {
				rule.value = '%[^A-Za-z0-9 ]%';
			} else {
				rule.value = '.*[^A-Za-z0-9 ].*';
			}
			rule.count = 0;
			break;
		case 'characters':
			if (isSql && isScan) {
				rule.value = '%[A-Za-z]%';
			} else {
				rule.value = '.*[A-Za-z].*';
			}
			rule.count = 0;
			break;
		case 'numeric values':
		case 'digits':
			if (isSql && isScan) {
				rule.value = '%[0-9]%';
			} else {
				rule.value = '.*[0-9].*';
			}
			rule.count = 0;
			break;
		case 'white space':
			if (isSql && isScan) {
				rule.value = '%[ ]%';
			} else {
				rule.value = '.*[ ].*';
			}
			rule.count = 0;
			break;
		case 'zero':
		case 'zero values':
			if (isSql && isScan) {
				rule.value = '[0]';
			} else {
				rule.value = '^([+-])?[0]+$';
			}
			rule.count = 0;
			break;
		case 'missing':
			if (isSql && isScan) {
				rule.value = '';
			} else {
				rule.value = '^$';
			}
			rule.count = 0;
			break;
		default:
			break;
	}
};

export const createElement = (href) => {
	const linkElement = document.createElement('link');
	linkElement.href = href;
	linkElement.type = 'text/css';
	linkElement.rel = 'stylesheet';
	return linkElement;
};

export const setThemePalette = (themePalette, theme) => {
	themePalette.palette.primary = {
		...themePalette.palette.primary,
		main: theme.primary_color
	};
	themePalette.palette.secondary = {
		...themePalette.palette.primary,
		main: theme.secondary_color
	};
	themePalette.palette.secondary = {
		...themePalette.palette.primary,
		main: theme.secondary_color
	};
	themePalette.palette.tertiary = theme.tertiary_color;
	themePalette.palette.grey = {
		...themePalette.palette.grey,
		darkTxt: theme.darkTxt,
		default: theme.default,
		exteremeLight: theme.exteremeLight,
		extraLight: theme.extraLight,
		light: theme.light,
		main: theme.main
	};
	themePalette.palette.titleFont = theme.heading_font;
	themePalette.palette.textFont = theme.text_font;
	themePalette.typography.body1.fontFamily = theme.text_font;
	themePalette.typography.body2.fontFamily = theme.text_font;
	themePalette.typography.subtitle1.fontFamily = theme.text_font;
	themePalette.typography.h1.fontFamily = theme.heading_font;
	themePalette.typography.h2.fontFamily = theme.heading_font;
	themePalette.typography.h3.fontFamily = theme.heading_font;
	themePalette.typography.h4.fontFamily = theme.heading_font;
	themePalette.typography.h5.fontFamily = theme.heading_font;
	themePalette.typography.h6.fontFamily = theme.heading_font;
	themePalette.typography.subtitle2.fontFamily = theme.heading_font;
	themePalette.typography.button.fontFamily = theme.heading_font;
	themePalette.typography.caption.fontFamily = theme.heading_font;
	themePalette.palette.logo = theme.logo;
	themePalette.palette.banner = theme.banner;
	themePalette.palette.loginCopyrightText = theme.login_copyright_text;
	themePalette.palette.footerCopyrightText = theme.footer_copyright_text;
	const titleFontFamily = appConstants.themeFonts.find((font) => font.fontName === theme.heading_font);
	const bodyFontFamily = appConstants.themeFonts.find((font) => font.fontName === theme.text_font);
	const titleElement = document.querySelector(`link[href="${titleFontFamily.url}"]`);
	const bodyLinkElement = document.querySelector(`link[href="${bodyFontFamily.url}"]`);
	if (titleFontFamily && !titleFontFamily.isDefault && !titleElement) {
		const linkElement = createElement(titleFontFamily.url);
		document.querySelector('head').appendChild(linkElement);
	}
	if (bodyFontFamily && !bodyFontFamily.isDefault && !bodyLinkElement) {
		const linkElement = createElement(bodyFontFamily.url);
		document.querySelector('head').appendChild(linkElement);
	}
	return themePalette;
};

export const returnImage = (baseUrl, value) => {
	return value;
};


export const getDatasourceIcons = () => {
	const iconCount = 50;
	const datasourceIcons = [];
	for (let i = 0; i <= iconCount; i++) {
		datasourceIcons.push({
			iconName: `icon${i}`,
			iconIndex: i,
			isSelected: false
		});
	}
	return datasourceIcons;
};

export const getAvatar = (users, userid) => {
	const userList = users ? users : [];
	const userInfo = userList.find((user) => user.id === userid);
	if (userInfo) {
		return userInfo.avatar;
	}
	return '';
};

export const dataAccessRestrict = (data, sensitivity, userSensitivity) => {
	data = (data || data === false || data === 0) ? String(data) : '';
	if (sensitivity > userSensitivity) {
		data = data.toString();
		data = data.replace(/[a-zA-Z0-9|:@._!@#$%&*=()'/''-']/g, 'x');
	}
	return data && data.toString().trim() ? data : '';
};

export const truncateText = (count, text) => {
	text = text ? text.substring(0, count) : '';
	return `${text}...`;
};

export const getColor = (value, dqScoreThreshold = {}) => {
	const colorCodes = ['#FF5C5C', '#FDAC42', '#54DF9A'];
	value = value ? parseFloat(value) : 0;
	if (dqScoreThreshold?.scores?.length > 0) {
		let chartColor = null;
		dqScoreThreshold?.scores.forEach((score) => {
			if (Math.floor(value) >= score.min && Math.floor(value) <= score.max) {
				chartColor = score.color;
			}

		});
		return chartColor;
	}
	else if (value <= 23) { return colorCodes[0]; }
	else if (value > 23 && value <= 74) { return colorCodes[1]; }
	return colorCodes[2];
};

export const getColorLineage = (value) => {
	const colorCodes = ['#f4cbcb', '#fbe6cb', '#daf7e9'];
	value = value ? parseFloat(value) : 0;
	if (value <= 23) { return colorCodes[0]; }
	else if (value > 23 && value <= 74) { return colorCodes[1]; }
	return colorCodes[2];
};

export const getMentionName = (text, users) => {
	text = text.replace(/\{{[0-9]+}}+/g, (mentionUserId) => {
		mentionUserId = mentionUserId.split('{{')[1].split('}}')[0];
		const user = users.find((user) => user.id === parseInt(mentionUserId));
		return user ? `<span class="mention">${user.first_name !== "" ? `${user.first_name} ${user.last_name}` : user.email}</span>` : '';
	});
	return text;
};

export const getUserId = (text) => {
	const users = [];
	text.replace(/\{{[0-9]+}}+/g, (mentionUserId) => {
		users.push({ "user": mentionUserId.split('{{')[1].split('}}')[0], "is_owner": false });
	});
	return users;
};

export const getAssignString = (text) => {
	return text.replace(/@\[(.*?)\]\(.*?\)/g, (repalceText) => { return '{{' + repalceText.replace(/[^0-9]/g, '') + '}}'; });
};

export const urlsToAbsolute = (nodeList) => {
	if (!nodeList.length) {
		return [];
	}
	let attrName = 'href';
	if (nodeList[0].__proto__ === HTMLImageElement.prototype
		|| nodeList[0].__proto__ === HTMLScriptElement.prototype) {
		attrName = 'src';
	}
	nodeList = [].map.call(nodeList, (el, i) => {
		const attr = el.getAttribute(attrName);
		if (!attr) {
			return;
		}
		const absURL = /^(https?|data):/i.test(attr);
		if (absURL) {
			return el;
		}
		return el;

	});
	return nodeList;
};

export const screenshotPage = () => {
	urlsToAbsolute(document.images);
	urlsToAbsolute(document.querySelectorAll("link[rel='stylesheet']"));
	const screenshot = document.documentElement.cloneNode(true);
	const b = document.createElement('base');
	b.href = document.location.protocol + '//' + window.location.host;
	const head = screenshot.querySelector('head');
	head.insertBefore(b, head.firstChild);
	screenshot.style.pointerEvents = 'none';
	screenshot.style.overflow = 'hidden';
	screenshot.style.webkitUserSelect = 'none';
	screenshot.style.mozUserSelect = 'none';
	screenshot.style.msUserSelect = 'none';
	screenshot.style.oUserSelect = 'none';
	screenshot.style.userSelect = 'none';
	screenshot.dataset.scrollX = window.scrollX;
	screenshot.dataset.scrollY = window.scrollY;
	const script = document.createElement('script');
	script.textContent = '(' + addOnPageLoad.toString() + ')();';
	screenshot.querySelector('body').appendChild(script);
	const blob = new Blob([screenshot.outerHTML], {
		type: 'text/html'
	});
	return blob;
};

export const addOnPageLoad = () => {
	window.addEventListener('DOMContentLoaded', () => {
		const scrollX = document.documentElement.dataset.scrollX || 0;
		const scrollY = document.documentElement.dataset.scrollY || 0;
		window.scrollTo(scrollX, scrollY);
	});
};

export const getPosition = (screenSize, size, dotScale) => {
	const radio = screenSize / size;
	return dotScale / radio;
};


export const getOrganizationUsers = () => {
	const users = localStorage.getItem('users');
	return users ? JSON.parse(users) : [];
};

export const scrollConversation = () => {
	const element = document.getElementById("conversation");
	if (element) {
		element.scrollTo(0, element.scrollHeight);
	}
};

export const textareaInsertText = (textId, text) => {
	const txtarea = document.querySelector(`.${textId} textarea`);
	if (txtarea) {
		const scrollPos = txtarea.scrollTop;
		let caretPos = txtarea.selectionStart;
		const front = (txtarea.value).substring(0, caretPos);
		const back = (txtarea.value).substring(txtarea.selectionEnd, txtarea.value.length);
		txtarea.value = front + text + back;
		caretPos += text.length;
		txtarea.selectionStart = caretPos;
		txtarea.selectionEnd = caretPos;
		txtarea.focus();
		txtarea.scrollTop = scrollPos;
	}
	return txtarea ? txtarea.value : '';
};

export const getTextAreaPosition = (textId, type) => {
	const textArea = document.querySelector(`.${textId} textarea`);
	const position = { left: 0, top: 0 };
	if (textArea) {
		position.top = (textArea.scrollTop + 40);
		position.left = type === "position" ? (textArea.selectionStart * 6) : textArea.selectionStart;
	}
	return position;
};

export const getCursorPositionText = (textId) => {
	const textArea = document.querySelector(`.${textId} textarea`);
	let value = "";
	if (textArea) {
		const startPosition = textArea.selectionStart;
		value = (textArea.value).substring(0, startPosition);
		value = value.split(/\(|\s/);
		if (value.length) {
			value = value[value.length - 1];
		}
	}
	return value;
};

export const removeExtension = (name) => {
	return name ? name.split(".")[0] : "";
};


const descendingComparator = (a, b, orderBy) => {
	if (typeof (a) !== 'object' && typeof (b) !== 'object') {
		a = a.toLowerCase().trim();
		b = b.toLowerCase().trim();
		if (b === "all") {
			return -1;
		}
		if (b < a) {
			return -1;
		}
		if (b > a) {
			return 1;
		}
	} else {
		const typea = typeof (a[orderBy]);
		const typeb = typeof (b[orderBy]);
		a = a[orderBy] && typea !== 'number' ? a[orderBy].toString().toLowerCase().trim() : a[orderBy];
		b = b[orderBy] && typeb !== 'number' ? b[orderBy].toString().toLowerCase().trim() : b[orderBy];
		if (b === "all") {
			return -1;
		}
		if (b < a) {
			return -1;
		}
		if (b > a) {
			return 1;
		}
	}
	return 0;
};

const getTableSortComparator = (order, orderBy) => {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
};

export const sortTable = (array, order, orderBy) => {
	const sortedArray = array.map((el, index) => [el, index]);
	if (!orderBy) {
		return array;
	}
	const comparator = getTableSortComparator(order, orderBy);
	sortedArray.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return sortedArray.map((el) => el[0]);
};

export const getMenuPath = (config, user = null) => {

	let menu = "404";
	if (config.search) {
		menu = "search";
	} else if (config.dashboard) {
		menu = "dashboard";
	} else if (config.lineage) {
		menu = "lineage";
	} else if (config.action_items) {
		menu = "actionitem";
	} else if (config.conversation) {
		menu = "conversation";
	} else if (config.datapreparation) {
		menu = "datapreparation";
	} else if (config.master) {
		menu = "semanticmodel";
	} else if (config.catalog) {
		menu = "catalog";
	} else if (config.semantics) {
		menu = "model";
	} else {
		menu = "catalog";
	}

	/**
	 * Added to redirect role "Users" to Search Landing Page
	 */
	if (user && user.role && user.role.properties_permission) {
		if (user.role.properties_permission.is_view && !user.role.properties_permission.is_edit && !user.role.properties_permission.is_delete) {
			menu = "search";
		}
	}

	sessionStorage.setItem('navigationPath', menu);
	return menu;
};

export const copyToClipBoard = (elementId) => {
	const element = document.getElementById(elementId);
	if (element) {
		element.focus();
		if (element.nodeName === 'P') {
			window.getSelection().removeAllRanges();
			const range = document.createRange();
			range.selectNode(element);
			window.getSelection().addRange(range);
		} else {
			element.select();
		}
		document.execCommand('copy');

		if (element.nodeName === 'P') {
			setTimeout(() => window.getSelection().removeAllRanges(), 100);
		}
	}
};


export const numberFormatter = (number) => {
	if (number <= 999) {
		// if value <= 999, nothing to do
		return number;
	} else if (number > 999 && number <= 1000000) {
		// convert to K for numberber from > 1000 < 1 million
		return (number / 1000).toFixed(1) + 'K';
	} else if (number > 1000000) {
		// convert to M for numberber from > 1 million
		return (number / 1000000).toFixed(1) + 'M';
	} else if (number > 1000000000) {
		// convert to B for numberber from > 1 billion
		return (number / 1000000000).toFixed(1) + 'B';
	} else if (number > 1000000000000) {
		// convert to T for numberber from > 1 trillion
		return (number / 1000000000000).toFixed(1) + 'T';
	}
};

export const removeUnderscore = (text = '') => {
	text = text ? text : "";
	return text.replace(/_/g, ' ');
};

export const getChangedProperties = (compareProperties = {}, properties = {}, changedProperitesList = []) => {
	const changedProperties = [];
	for (const property of changedProperitesList) {
		if (properties[property] !== compareProperties[property]) {
			changedProperties.push(property);
		}
	}
	return changedProperties;
};

const getInnerChildrenElementCount = (element, field, fieldCount) => {
	if (element.children && element.children.length > 0) {
		fieldCount += element.children.filter((elem) => elem.semantic_type === field).length;
		element.children.forEach((child) => {
			fieldCount += getInnerChildrenElementCount(child, field, fieldCount);
		});
	}
	return fieldCount;
};

export const sortArrayObjectString = (items, field, order) => {
	let sortResult = items.sort((a, b) => {
		let fieldA = '';
		let fieldB = '';
		if (field === 'field' || field === 'category') {
			fieldA = getInnerChildrenElementCount(a, field, 0);
			// fieldA = a.children && a.children.length > 0 ? a.children.filter((elem) => elem.semantic_type === field).length : 0;
			fieldB = getInnerChildrenElementCount(b, field, 0);
			// fieldB = b.children && b.children.length > 0 ? b.children.filter((elem) => elem.semantic_type === field).length : 0;
			return fieldA - fieldB;
		}
		fieldA = a[field].toUpperCase();
		fieldB = b[field].toUpperCase();
		if (fieldA < fieldB) {
			return -1;
		}
		if (fieldA > fieldB) {
			return 1;
		}
		return 0;

	});
	if (order === 'desc') {
		sortResult = sortResult.reverse();
	}
	sortResult = sortResult.sort((a, b) => a.semantic_type.localeCompare(b.semantic_type));
	return sortResult;
};

export const sortArrayObjectNumber = (items, field) => items.sort((a, b) => a[field] - b[field]);

export const getQuadrantError = (scoreList = [], score, index) => {
	if (score) {
		if ((!score.max && score.max !== 0) || (!score.min && score.min !== 0)) {
			return ("Min or Max value cannot be empty");
		} else if (score.max <= score.min) {
			return ("Min Value is greater than Max value");
		} else if (scoreList.filter((elem, i) => i !== index).some((elem) => ((elem.min <= score.max && score.max <= elem.max) || (elem.min <= score.min && score.min <= elem.max)))) {
			return ("Max or Min Value intersect with other quadrants");
		} else if (scoreList.filter((elem, i) => i !== index).some((elem) => elem.color === score.color)) {
			return ("Color intersect with other quadrants");
		}
	} else {
		if (scoreList.reduce((accumulator, elem) => accumulator + (((elem.max - (elem.min ? elem.min - 1 : elem.min))) / 100), 0) !== 1) {
			return ("The values should be between 0 and 100 and should not skip any values");
		}
	}
	return null;
};