import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, withStyles, Typography, IconButton } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import DatasetStyles from '../../DatasetStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';
import TileViewStyles from './TileViewStyles.jsx';
import AutoComplete from './../../../AutoComplete/AutoComplete.jsx';
import BasicCurateRuleItem from './BasicCurateRuleItem.jsx';
import AdvancedCurateRuleItem from './AdvancedCurateRuleItem.jsx';
import { updateDatasetRules, updateProperties, updateRuleAttributes } from '../../../../actions/datasetActions.js';
import Localization from '../../../../constants/localization';
import ToolTipComponent from '../../../Tooltip/Tooltip.jsx';


const AttributeCurate = (props) => {
    const { classes, attribute, tabIndex, theme, isEditable, updatePropertiesList, isDeletePermission, isEditPermission } = props;
    const dispatch = useDispatch();
    const datasetId = useSelector(({ dataset }) => dataset.selectedDatasetId);
    const rules = useSelector(({ dataset }) => dataset.rules.curate);
    const curationRules = useSelector(({ datasource }) => datasource.curationRules);
    const references = useSelector(({ datasource }) => datasource.references);
    const [basicRules, setBasicRules] = useState([]);
    const [advancedRules, setAdvancedRules] = useState([]);

    const [refAttributes, setRefAttributes] = useState([]);
    const [resultantAttributes, setResultantAttributes] = useState([]);
    const [isRulesChanged, setRuleChanged] = useState(false);
    const [isAdvancedRulesChanged, setAdvancedRuleChanged] = useState(false);
    const [isInputChanged, setInputChanged] = useState(false);

    const [reference, setReference] = useState({
        refId: 0,
        name: '',
        attribute: '',
        resultantAttribute: '',
        'storage_method': '',
        'file_url': '',
        'web_file_url': ''
    });

    const updateRules = useCallback((ruleType) => {
        const ruleInput = {
            attributeId: attribute.id,
            attribute: attribute.name,
            type: 'curate',
            'rule_type': ruleType,
            rules: ruleType === 'basic' ? [...new Map(basicRules.map((item) => [item.name.toLowerCase(), item])).values()] : [...new Map(advancedRules.map((item) => [item.name.toLowerCase(), item])).values()]
        };
        dispatch(updateDatasetRules(datasetId, ruleInput));
        dispatch(updateRuleAttributes(ruleInput));
    }, [attribute, basicRules, advancedRules, dispatch, datasetId]);

    const updateAttributeProperties = useCallback(() => {
        if (!reference) {
            return;
        }
        const enumVaue = { ...attribute.enum };
        enumVaue.name = reference.name;
        enumVaue.attribute = reference.attribute;
        enumVaue['ref_id'] = reference.refId;
        enumVaue['resultant_attribute'] = reference.resultantAttribute;
        enumVaue['storage_method'] = reference.storage_method ? reference.storage_method : "";
        enumVaue['file_url'] = reference.file_url ? reference.file_url : "";
        enumVaue['web_file_url'] = reference.web_file_url ? reference.web_file_url : "";
        const attributeProperty = {
            id: attribute.id,
            attribute: attribute.name,
            property: 'enum',
            value: { enum: enumVaue },
            'changed_properties': ['enum']
        };
        updatePropertiesList({ name: attribute.name, value: { enum: enumVaue } });
        dispatch(updateProperties(datasetId, attributeProperty));
    }, [reference, attribute.enum, attribute.id, attribute.name, updatePropertiesList, dispatch, datasetId]);


    const getBasicCurationRules = useCallback(() => {
        const remainingRules = [];
        if (!curationRules) {
            return remainingRules;
        }
        const defaultBasicCurationRules = curationRules.filter((p) => p.rule_type === 'basic');
        for (const rule of defaultBasicCurationRules) {
            const hasRule = basicRules.find((p) => p.name.toLowerCase() === rule.name.toLowerCase());
            if (!hasRule) {
                remainingRules.push(rule);
            }
        }
        return remainingRules;
    }, [curationRules, basicRules]);


    const getAdvancedCurationRules = useCallback(() => {
        const remainingRules = [];
        if (!curationRules) {
            return remainingRules;
        }
        const defaultAdvancedCurationRules = curationRules.filter((p) => p.rule_type === 'advanced');
        for (const rule of defaultAdvancedCurationRules) {
            const hasRule = advancedRules.find((p) => p.name.toLowerCase() === rule.name.toLowerCase());
            if (!hasRule) {
                remainingRules.push(rule);
            }
        }
        return remainingRules;
    }, [curationRules, advancedRules]);


    const updateReference = useCallback((property, value) => {
        if (property === 'name') {
            reference.refId = value && value.id ? value.id : '';
            reference.name = value && value.name ? value.name : '';
            reference.attribute = '';
            reference.resultantAttribute = '';
            reference['storage_method'] = value.storage_method ? value.storage_method : '';
            reference['file_url'] = value.file_url ? value.file_url : '';
            reference['web_file_url'] = value.web_file_url ? value.web_file_url : '';
            let refAttributes = [];
            if (value && value.attributes && value.attributes.length > 0) {
                refAttributes = [...value.attributes];
            }
            setRefAttributes(refAttributes);
            setResultantAttributes([]);
        }
        else {
            const attribute = value && value.attribute ? value.attribute : '';
            reference[property] = attribute;
            let attributes = [];
            if (reference.attribute) {
                attributes = refAttributes.filter((p) => p.attribute.toLowerCase() !== reference.attribute.toLowerCase());
            }
            setResultantAttributes([...attributes]);
        }
        setReference({ ...reference });
        setInputChanged(true);
    }, [refAttributes, reference]);


    const addBasicRules = useCallback(() => {
        if (basicRules && basicRules.length > 0 && basicRules[0].name.length <= 0) {
            return;
        }
        basicRules.splice(0, 0, { name: '', isSelected: false });
        setBasicRules([...new Map(basicRules.map((item) => [item.name.toLowerCase(), item])).values()]);
        setRuleChanged(true);
    }, [setBasicRules, basicRules]);

    const removeBasicRules = useCallback((index) => {
        basicRules.splice(index, 1);
        setBasicRules([...new Map(basicRules.map((item) => [item.name.toLowerCase(), item])).values()]);
        setRuleChanged(true);
    }, [basicRules]);

    const updateBasicRules = useCallback((value, index) => {
        basicRules[index] = { ...value, isSelected: true, attribute: attribute.name };
        setBasicRules([...new Map(basicRules.map((item) => [item.name.toLowerCase(), item])).values()]);
        setRuleChanged(true);
    }, [basicRules, attribute.name]);

    const addAdvancedRules = useCallback(() => {
        if (advancedRules && advancedRules.length > 0 && advancedRules[0].name.length <= 0) {
            return;
        }
        advancedRules.splice(0, 0, { name: '', isSelected: false });
        setAdvancedRules([...new Map(advancedRules.map((item) => [item.name.toLowerCase(), item])).values()]);
        setAdvancedRuleChanged(true);
    }, [advancedRules]);

    const removeAdvancedRules = useCallback((index) => {
        advancedRules.splice(index, 1);
        setAdvancedRules([...new Map(advancedRules.map((item) => [item.name.toLowerCase(), item])).values()]);
        setAdvancedRuleChanged(true);
    }, [advancedRules]);

    const updateAdvancedRules = useCallback((value, index) => {
        advancedRules[index] = { ...value, isSelected: true, attribute: attribute.name };
        setAdvancedRules([...new Map(advancedRules.map((item) => [item.name.toLowerCase(), item])).values()]);
        setAdvancedRuleChanged(true);
    }, [advancedRules, attribute.name]);

    const loadReference = useCallback((selectedAttribute) => {
        let enumValue = attribute && attribute.enum ? attribute.enum : {};
        if (selectedAttribute) {
            enumValue = selectedAttribute && selectedAttribute.enum ? selectedAttribute.enum : {};
        }
        setReference({
            refId: enumValue.ref_id ? enumValue.ref_id : 0,
            name: enumValue.name ? enumValue.name : '',
            attribute: enumValue.attribute ? enumValue.attribute : '',
            resultantAttribute: enumValue.resultant_attribute ? enumValue.resultant_attribute : '',
            'storage_method': enumValue.storage_method ? enumValue.storage_method : '',
            'file_url': enumValue.file_url ? enumValue.file_url : '',
            'web_file_url': enumValue.web_file_url ? enumValue.web_file_url : ''
        });

        /*
         * if (enumValue.ref_id) {
         *     const reference = references.find((p) => p.ref_id === enumValue.ref_id);
         *     if (reference && reference.attributes) {
         *         setRefAttributes([...reference.attributes]);
         *     }
         * }
         * if (enumValue.attribute) {
         *     const attributes = refAttributes.filter((p) => p.attribute.toLowerCase() !== enumValue.attribute.toLowerCase());
         *     setResultantAttributes([...attributes]);
         * }
         */
    }, [attribute]);

    const loadRules = useCallback(() => {
        let advancedRules = [];
        let basicRules = [];
        if (rules) {
            const attributeRules = rules[attribute.name];
            if (attributeRules) {
                basicRules = attributeRules.filter((p) => p.type === 'curate' && p.rule_type === 'basic');
                for (const rule of basicRules) {
                    rule.isSelected = true;
                }
                advancedRules = attributeRules.filter((p) => p.type === 'curate' && p.rule_type === 'advanced');
                for (const rule of advancedRules) {
                    rule.isSelected = true;
                }
            }
        }
        setBasicRules(basicRules ? [...new Map(basicRules.map((item) => [item.name.toLowerCase(), item])).values()] : []);
        setAdvancedRules(advancedRules ? [...new Map(advancedRules.map((item) => [item.name.toLowerCase(), item])).values()] : []);
    }, [attribute, rules]);

    useEffect(() => {
        if (!attribute || (tabIndex !== 3)) {
            return;
        }
        loadRules();
        loadReference();
    }, [attribute, loadRules, loadReference, tabIndex, rules]);

    const updateCurateProperty = () => {
        if (isInputChanged) {
            setInputChanged(false);
            updateAttributeProperties();
        }
        if (isRulesChanged) {
            setRuleChanged(false);
            updateRules('basic');
        }
        if (isAdvancedRulesChanged) {
            setAdvancedRuleChanged(false);
            updateRules('advanced');
        }
    };


    const basicCurationRules = getBasicCurationRules();
    const advancedCurationRules = getAdvancedCurationRules();
    return (
        <Grid container direction="column" wrap="nowrap" style={{ height: '100%' }} onMouseLeave={() => updateCurateProperty()}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: '100%' }}>
                <Grid container direction="row" wrap="nowrap" spacing={4}>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3} style={{ paddingBottom: 0 }}>
                        <Grid container direction="column" justify="flex-start" wrap="nowrap">
                            <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap" className={classes.valuesTitleContainer}>
                                <Typography component="h6" variant="h6">
                                    {'Basic'}
                                </Typography>
                                {
                                    (isEditable || isEditPermission) && (basicCurationRules && basicCurationRules.length > 0) &&
                                    <ToolTipComponent title="Add" arrow>
                                        <IconButton onClick={() => addBasicRules()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                                    <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                                        <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                                        <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                                    </g>
                                                </g>
                                            </svg>
                                        </IconButton>
                                    </ToolTipComponent>
                                }
                            </Grid>
                            <Typography variant="body2" className={classes.curateDescritionEllipsis}>
                                {Localization.properties.curate.basic}
                            </Typography>

                            <Grid container className={classNames(classes.basicRulesListContainer)}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.basicRuleContainer}>
                                    {
                                        basicRules && basicRules.map((rule, index) => {
                                            return (
                                                <BasicCurateRuleItem
                                                    key={`basicRuleList${index}`}
                                                    displayKey={'name'}
                                                    index={index}
                                                    rule={rule}
                                                    isSelected={Boolean(rule.isSelected)}
                                                    options={basicCurationRules}
                                                    onDelete={() => removeBasicRules(index)}
                                                    onUpdate={(...props) => updateBasicRules(...props)}
                                                    disabled={!isEditable}
                                                    isEditPermission={isEditPermission}
                                                    isDeletePermission={isDeletePermission} />
                                            );
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={9} sm={9} md={9} lg={9} xl={9} style={{ paddingBottom: 0 }}>
                        <Grid container direction="column" justify="flex-start" wrap="nowrap" style={{ height: '100%' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ flex: 0 }}>
                                <Grid container direction="row" justify="flex-start" wrap="nowrap" spacing={6}>
                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={classNames(classes.CurateruleContainer, classes.autoCompleteInput)}>
                                        <Grid container direction="column" justify="flex-start" wrap="nowrap">
                                            <Typography component="h6" variant="h6">
                                                {'Reference Dataset'}
                                            </Typography>
                                            <Typography variant="body2" className={classes.curateDescritionEllipsis}>
                                                {Localization.properties.curate.reference}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={classes.CurateruleContainer}>
                                        <Grid container direction="column" justify="flex-start" wrap="nowrap">
                                            <Typography component="h6" variant="h6">
                                                {'Lookup Attribute'}
                                            </Typography>
                                            <Typography variant="body2" className={classes.curateDescritionEllipsis}>
                                                {Localization.properties.curate.lookup}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={classes.CurateruleContainer}>
                                        <Grid container direction="column" justify="flex-start" wrap="nowrap">
                                            <Typography component="h6" variant="h6">
                                                {'Resultant Attribute'}
                                            </Typography>
                                            <Typography variant="body2" className={classes.curateDescritionEllipsis}>
                                                {Localization.properties.curate.resultant}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ flex: 0 }}>
                                <Grid container direction="row" justify="flex-start" wrap="nowrap" spacing={6}>
                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={classNames(classes.CurateruleContainer, classes.autoCompleteInput)}>
                                        <Grid container direction="column" justify="flex-start" wrap="nowrap">
                                            <Grid item className={classNames(classes.textboxContainer, classes.sliderInput, classes.propertyComponents)}>
                                                <AutoComplete
                                                    placeholder="Dataset"
                                                    onChange={(event) => updateReference('name', event.target.selectedItem)}
                                                    onBlur={() => { }}
                                                    value={reference.name ? reference.name : ''}
                                                    noDropPostion={1}
                                                    options={references}
                                                    selectedValuePath="name"
                                                    displayMemberPath="name"
                                                    name="Dataset"
                                                    disabled={!isEditable && !isEditPermission} />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={classes.CurateruleContainer}>
                                        <Grid container direction="column" justify="flex-start" wrap="nowrap">
                                            <Grid item className={classNames(classes.textboxContainer, classes.sliderInput, classes.propertyComponents)}>
                                                <AutoComplete
                                                    placeholder="Attribute"
                                                    onChange={(event) => updateReference('attribute', event.target.selectedItem)}
                                                    onBlur={() => { }}
                                                    value={reference.attribute ? reference.attribute : ''}
                                                    noDropPostion={1}
                                                    options={[...refAttributes]}
                                                    selectedValuePath="attribute"
                                                    displayMemberPath="attribute"
                                                    disabled={(reference.refId <= 0) && (!isEditable || !isEditPermission)}
                                                    name="ref_attribute" />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4} className={classes.CurateruleContainer}>
                                        <Grid container direction="column" justify="flex-start" wrap="nowrap">
                                            <Grid item className={classNames(classes.textboxContainer, classes.sliderInput, classes.propertyComponents)}>
                                                <AutoComplete
                                                    placeholder="Resultant Attribute"
                                                    onChange={(event) => updateReference('resultantAttribute', event.target.selectedItem)}
                                                    onBlur={() => { }}
                                                    value={reference.resultantAttribute ? reference.resultantAttribute : ''}
                                                    noDropPostion={1}
                                                    options={[...resultantAttributes]}
                                                    selectedValuePath="attribute"
                                                    displayMemberPath="attribute"
                                                    disabled={(resultantAttributes.length <= 0) && (!isEditable || !isEditPermission)}
                                                    name="resultant_attribute" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>


                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ flex: 1, marginTop: 20, paddingBottom: 0 }}>
                                <Grid container direction="column" justify="flex-start" wrap="nowrap">
                                    <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap" className={classes.valuesTitleContainer}>
                                        <Typography component="h6" variant="h6">
                                            {'Advanced'}
                                        </Typography>
                                        {
                                            (isEditable || isEditPermission) && (advancedCurationRules && advancedCurationRules.length > 0) &&
                                            <ToolTipComponent title="Add" arrow>
                                                <IconButton onClick={() => addAdvancedRules()}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                        <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                                            <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                                                <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                                                <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </IconButton>
                                            </ToolTipComponent>
                                        }
                                    </Grid>
                                    <Typography variant="body2" className={classes.curateDescritionEllipsis}>
                                        {Localization.properties.curate.advance}
                                    </Typography>
                                    <Grid container direction="column" justify="flex-start" alignItems="flex-start" wrap="nowrap" className={classes.advancedRulesContainer}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            {
                                                advancedRules && advancedRules.map((rule, index) => {
                                                    return (
                                                        <AdvancedCurateRuleItem
                                                            key={`advancedRuleList${index}`}
                                                            displayKey={'name'}
                                                            index={index}
                                                            rule={rule}
                                                            isSelected={Boolean(rule.isSelected)}
                                                            options={advancedCurationRules}
                                                            onDelete={() => removeAdvancedRules(index)}
                                                            onUpdate={(...props) => updateAdvancedRules(...props)}
                                                            disabled={!isEditable}
                                                            isEditPermission={isEditPermission}
                                                            isDeletePermission={isDeletePermission} />
                                                    );
                                                })

                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid >
            </Grid>
        </Grid>
    );
};

AttributeCurate.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    attribute: PropTypes.object,
    isEditable: PropTypes.bool,
    tabIndex: PropTypes.number,
    updatePropertiesList: PropTypes.func,
    isDeletePermission: PropTypes.bool,
    isEditPermission: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(AttributeCurate);