import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Typography, withStyles } from '@material-ui/core';
import Xarrow from "react-xarrows";
import classNames from 'classnames';
import ConnectionBox from './ConnectionBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import DataPreparationStyles from './DataPreparationStyles.jsx';
import TooltipComponent from '../Tooltip/Tooltip.jsx';

const DataPreparationJoin = (props) => {
    const { classes, datasets, theme, type, openPopover, addArrow, arrows, isJoinPerform, openDataPreivew, addDatasetTarget, datasetConfigurations } = props;


    const getDatasetConfiguration = (datasetId) => {
        return datasetConfigurations.find((dataset) => {
            const datasets = dataset.source_datasets.map((data) => data.id);
            return datasets.includes(datasetId) && (dataset.configuration && !dataset.configuration.is_join);
        });
    };

    const getTargetConfigurationId = () => {
        const config = datasetConfigurations.find((dataset) => dataset.configuration && dataset.configuration.is_join);
        return config ? config : {};
    };

    return (
        <Grid container spacing={2} alignItems="center" className={classes.joinContainer}>
            <Grid item xs={5}>
                {
                    datasets.map((dataset, index) =>
                        <Grid container key={index}>
                            <Grid item xs={5}>
                                <ConnectionBox joinDisabled={type !== "join"} draggable {...{ addArrow, handler: "right", boxId: dataset.id }}>
                                    <Grid container alignItems="center" className={classes.datasetCard}>
                                        <Grid>
                                            <Grid className={classes.databaseIcon}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="23.394" viewBox="0 0 11.289 15.394">
                                                    <path id="database_17_" data-name="database (17)" d="M15.645,17.394c2.805,0,5.645-.793,5.645-2.309V4.309C21.289,2.793,18.45,2,15.645,2S10,2.793,10,4.309V15.085C10,16.6,12.839,17.394,15.645,17.394Zm0-14.368c2.865,0,4.618.831,4.618,1.283s-1.753,1.283-4.618,1.283-4.618-.831-4.618-1.283S12.78,3.026,15.645,3.026ZM11.026,5.695a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924V7.9c0,.452-1.753,1.283-4.618,1.283S11.026,8.353,11.026,7.9Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Z" transform="translate(-10 -2)" fill={theme.palette.background.paper} />
                                                </svg>
                                            </Grid>
                                        </Grid>
                                        <Grid className={classes.marginLeft10}>
                                            <TooltipComponent title={dataset.name ? dataset.name : "Untitled Dataset"}>
                                                <Typography component="h6" variant="h6" className={classes.titleTxt}>
                                                    {dataset.name ? dataset.name : "Untitled Dataset"}
                                                </Typography>
                                            </TooltipComponent>
                                            <TooltipComponent title={dataset.source_name ? dataset.source_name : "Untitled Datasource"}>
                                                <Typography className={classes.cardSourceTxt}>
                                                    {dataset.source_name ? dataset.source_name : "Untitled Datasource"}
                                                </Typography>
                                            </TooltipComponent>
                                            <IconButton onClick={() => addDatasetTarget(dataset)} className="button">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                    <g id="_38_Question" data-name="38 Question" transform="translate(-0.005 -0.003)">
                                                        <path id="Path_2370" data-name="Path 2370" d="M.986,14a.983.983,0,0,1-.972-1.11L.365,10.21a1.116,1.116,0,0,1,.316-.642L9.931.318a1.078,1.078,0,0,1,1.522,0L13.69,2.555a1.078,1.078,0,0,1,0,1.522l-9.25,9.25a1.115,1.115,0,0,1-.642.316l-2.683.351A.993.993,0,0,1,.986,14Zm.723-3.53-.275,2.1,2.1-.275,8.983-8.983L10.692,1.49Z" transform="translate(0)" fill="#969899" />
                                                        <path id="Path_2371" data-name="Path 2371" d="M283.16,84.283a.682.682,0,0,1-.483-.2l-2.794-2.794a.684.684,0,0,1,.968-.967l2.793,2.793a.684.684,0,0,1-.483,1.167Z" transform="translate(-272.03 -77.928)" fill="#969899" />
                                                    </g>
                                                </svg>
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </ConnectionBox>
                            </Grid>
                            <Grid item xs={2} />
                            {
                                getDatasetConfiguration(dataset.id) &&
                                <Grid item xs={5}>
                                    <ConnectionBox draggable {...{ addArrow, handler: "left", boxId: `${dataset.id}_1_target` }} >
                                        <Grid container alignItems="center" className={classNames(classes.datasetCard, classes.targetCard)} onClick={() => openDataPreivew("dataset", getDatasetConfiguration(dataset.id))}>
                                            <Grid>
                                                <Grid className={classes.databaseIcon}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="23.394" viewBox="0 0 11.289 15.394">
                                                        <path id="database_17_" data-name="database (17)" d="M15.645,17.394c2.805,0,5.645-.793,5.645-2.309V4.309C21.289,2.793,18.45,2,15.645,2S10,2.793,10,4.309V15.085C10,16.6,12.839,17.394,15.645,17.394Zm0-14.368c2.865,0,4.618.831,4.618,1.283s-1.753,1.283-4.618,1.283-4.618-.831-4.618-1.283S12.78,3.026,15.645,3.026ZM11.026,5.695a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924V7.9c0,.452-1.753,1.283-4.618,1.283S11.026,8.353,11.026,7.9Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Z" transform="translate(-10 -2)" fill={theme.palette.background.paper} />
                                                    </svg>
                                                </Grid>
                                            </Grid>
                                            <Grid className={classes.marginLeft10}>
                                                <Typography component="h6" variant="h6">
                                                    {getDatasetConfiguration(dataset.id).target_dataset.target_name}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </ConnectionBox>
                                </Grid>
                            }
                        </Grid>
                    )
                }
            </Grid>

            <Grid item xs={2} />
            {
                type === "join" &&
                <Grid item>
                    <ConnectionBox draggable {...{ addArrow, handler: "left", boxId: "join" }} >
                        <Grid className={classNames(classes.joinIcon, 'active')} onClick={() => openPopover()}>
                            <svg xmlns="http://www.w3.org/2000/svg" id="connection" width="30" height="30" viewBox="0 0 20 20">
                                <path id="Path_2363" data-name="Path 2363" d="M14.154,14.277l.954,1.186-3.2,3.2-1.187-.97a8.72,8.72,0,0,1-1.84.759L8.72,20H5.9V17.617H3.408a1.758,1.758,0,1,1,0-1.172H5.9V15.273h.586a5.273,5.273,0,1,0,0-10.547H5.9V3.555H3.408a1.758,1.758,0,1,1,0-1.172H5.9V0H8.773l.152,1.563a8.7,8.7,0,0,1,1.837.767l1.186-.954,3.2,3.2-.972,1.187a8.728,8.728,0,0,1,.759,1.84l1.551.164v4.523l-1.563.152a8.693,8.693,0,0,1-.767,1.837Zm0,0" transform="translate(3.516 0)" fill={theme.palette.primary.main} />
                                <path id="Path_2364" data-name="Path 2364" d="M227.758,211a1.755,1.755,0,0,1,1.65,1.172h7.764v1.172h-7.764a1.755,1.755,0,1,1-1.65-2.344Zm0,0" transform="translate(-226 -202.758)" fill={theme.palette.primary.main} />
                                <path id="Path_2365" data-name="Path 2365" d="M152.623,91a1.76,1.76,0,0,1,1.893,1.753,1.722,1.722,0,0,1-.022.213l1.918.959h5.073a3.617,3.617,0,0,1,3.617,3.617,3.415,3.415,0,0,1-3.415,3.415h-5.275l-1.918.959a1.722,1.722,0,0,1,.022.213,1.767,1.767,0,1,1-.541-1.264l2.16-1.08h5.451a2.344,2.344,0,1,0,0-4.687h-5.451l-2.16-1.08A1.756,1.756,0,1,1,152.623,91Zm0,0" transform="translate(-151 -87.445)" fill={theme.palette.primary.main} />
                            </svg>
                        </Grid>
                    </ConnectionBox>
                </Grid>
            }
            <Grid item xs={1} />
            {
                isJoinPerform &&
                <Grid item xs={2}>
                    <ConnectionBox draggable {...{ addArrow, handler: "left", boxId: "target_dataset" }} >
                        <Grid container alignItems="center" className={classNames(classes.datasetCard, classes.targetCard)} onClick={() => openDataPreivew("join", getTargetConfigurationId())}>
                            <Grid>
                                <Grid className={classes.databaseIcon}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="23.394" viewBox="0 0 11.289 15.394">
                                        <path id="database_17_" data-name="database (17)" d="M15.645,17.394c2.805,0,5.645-.793,5.645-2.309V4.309C21.289,2.793,18.45,2,15.645,2S10,2.793,10,4.309V15.085C10,16.6,12.839,17.394,15.645,17.394Zm0-14.368c2.865,0,4.618.831,4.618,1.283s-1.753,1.283-4.618,1.283-4.618-.831-4.618-1.283S12.78,3.026,15.645,3.026ZM11.026,5.695a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924V7.9c0,.452-1.753,1.283-4.618,1.283S11.026,8.353,11.026,7.9Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Z" transform="translate(-10 -2)" fill={theme.palette.background.paper} />
                                    </svg>
                                </Grid>
                            </Grid>
                            <Grid className={classes.marginLeft10}>
                                <Typography component="h6" variant="h6">
                                    Target Dataset
                                </Typography>
                            </Grid>
                        </Grid>
                    </ConnectionBox>
                </Grid>
            }
            {
                arrows.map((ar) => (
                    <Xarrow
                        headSize={0}
                        strokeWidth={2}
                        start={ar.start}
                        end={ar.end}
                        lineColor={theme.palette.secondary.main}
                        key={ar.start + "-." + ar.start}
                    />
                ))
            }

        </Grid >
    );
};


DataPreparationJoin.propTypes = {
    classes: PropTypes.object,
    datasets: PropTypes.array,
    theme: PropTypes.object,
    type: PropTypes.string,
    openPopover: PropTypes.func,
    addArrow: PropTypes.func,
    arrows: PropTypes.array,
    isJoinPerform: PropTypes.bool,
    openDataPreivew: PropTypes.func,
    addDatasetTarget: PropTypes.func,
    datasetConfigurations: PropTypes.array
};

export default (withStyles((theme) => ({
    ...DataPreparationStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DataPreparationJoin));