import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography, MenuItem, IconButton, Tooltip, Switch } from '@material-ui/core';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import ChartContainerStyles from './ChartContainerStyles.jsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { FilterList } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const ChartContainer = (props) => {
    const { classes, menuProperties, height, editProperties, chartData, title, children, theme, filterProperties, id, ruleProperties, toggleProperties, fullScreenProperties, chartFilter, textBoxWidth } = props;

    const minWidth = (textBoxWidth) ? textBoxWidth : '';

    const DropDownIcon = (iconProps) => {
        return (
            <svg {...iconProps} className={classes.DropDownIcon} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" top="" width="12px" height="18px" viewBox="0 0 451.847 451.847">
                <g>
                    <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" fill={theme.palette.grey.dark} />
                </g>
            </svg>
        );
    };
    return (
        <Grid className={classes.card} id={id} style={{ height: height }}>
            <Grid container justify="space-between" className={classes.chartMenuContainer}>

                <Grid item>
                    <Typography className={classes.titleTxt} variant="h4" component="h4">
                        {title}
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container direction="row" alignItems="center" wrap="nowrap" spacing={3}>
                        {
                            (ruleProperties && ruleProperties.edit) &&
                            <ToolTipComponent title="Rule" arrow>
                                <Switch
                                    color="secondary"
                                    size="small"
                                    checked={ruleProperties.rule}
                                    onChange={(event) => ruleProperties.onRule(event.target.checked)} />
                            </ToolTipComponent>
                        }
                        {
                            menuProperties && menuProperties.length !== 0 && menuProperties.map((menu, index) =>
                                <Grid item key={`menuProperty_${index}`}>
                                    <TextBox
                                        placeholder={menu.placeholder}
                                        className={classes.inlinetxt}
                                        onChange={(event) => menu.onChange && menu.onChange(event.target.value, index)}
                                        value={menu.value}
                                        select
                                        SelectProps={
                                            {
                                                MenuProps: {
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "center"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "center"
                                                    },
                                                    getContentAnchorEl: null
                                                },
                                                IconComponent: (iconProps) => DropDownIcon(iconProps)
                                            }
                                        }
                                        fullWidth
                                    >
                                        {
                                            menu.options.map((option, index) => (
                                                <MenuItem key={`menuProperty_Options_${index}`} value={option} className={classes.menuItem}>
                                                    {option}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextBox>
                                </Grid>
                            )
                        }
                        {
                            (editProperties && editProperties.edit && !editProperties.edited) &&
                            <Tooltip title="Edit">
                                <IconButton className={classes.icon} onClick={() => editProperties.onEdit("edit")}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512.001 512.001" width="15" height="15">
                                        <g>
                                            <path d="M496.063,62.299l-46.396-46.4c-21.199-21.199-55.689-21.198-76.888,0C352.82,35.86,47.964,340.739,27.591,361.113    c-2.17,2.17-3.624,5.054-4.142,7.875L0.251,494.268c-0.899,4.857,0.649,9.846,4.142,13.339c3.497,3.497,8.487,5.042,13.338,4.143    L143,488.549c2.895-0.54,5.741-2.008,7.875-4.143l345.188-345.214C517.311,117.944,517.314,83.55,496.063,62.299z M33.721,478.276    l14.033-75.784l61.746,61.75L33.721,478.276z M140.269,452.585L59.41,371.721L354.62,76.488l80.859,80.865L140.269,452.585z     M474.85,117.979l-18.159,18.161l-80.859-80.865l18.159-18.161c9.501-9.502,24.96-9.503,34.463,0l46.396,46.4    C484.375,93.039,484.375,108.453,474.85,117.979z" fill={theme.palette.grey.dark} />
                                        </g>
                                    </svg>
                                </IconButton>
                            </Tooltip>
                        }
                        {
                            editProperties && editProperties.edited &&
                            <Fragment>
                                <IconButton className={classes.icon}
                                    style={{ color: theme.palette.chartColors.valid }}
                                    onClick={() => editProperties.onEdit("accept")}>
                                    <CheckCircleIcon />
                                </IconButton>
                                <IconButton className={classes.icon}
                                    style={{ color: theme.palette.chartColors.inValid }}
                                    onClick={() => editProperties.onEdit("discard")}>
                                    <CancelIcon />
                                </IconButton>
                            </Fragment>
                        }
                        {
                            filterProperties && filterProperties.enableFilter && title !== 'Custom Rule' &&
                            <Grid item>
                                <Tooltip title="Filter">
                                    <IconButton className={classes.paddingSix} onClick={() => filterProperties.onFilter && filterProperties.onFilter()}>
                                        <FilterList className={classes.iconColor} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        }
                        {
                            toggleProperties &&
                            <Grid item className={classes.toggleContainer}>
                                <Typography className={!toggleProperties.enable ? 'active' : null}>
                                    {toggleProperties.labels[0]}
                                </Typography>
                                <Switch
                                    color="secondary"
                                    size="medium"
                                    checked={toggleProperties.enable}
                                    onChange={(event) => toggleProperties.onChange(event.target.checked)} />
                                <Typography className={toggleProperties.enable ? 'active' : null}>
                                    {toggleProperties.labels[1]}
                                </Typography>
                            </Grid>
                        }
                        {
                            fullScreenProperties &&
                            <Grid item>
                                {
                                    !fullScreenProperties.enable ?
                                        <ToolTipComponent title="Full Screen" arrow>
                                            <IconButton className={classes.paddingZero} onClick={() => fullScreenProperties.onEdit && fullScreenProperties.onEdit(!fullScreenProperties.enable)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20.4 20.4">
                                                    <path fill={theme.palette.primary.main} d="M43.345,44.4H25.055A1.055,1.055,0,0,1,24,43.345V25.055A1.055,1.055,0,0,1,25.055,24h7.738a1.055,1.055,0,0,1,0,2.11H26.11V42.29H42.29V35.607a1.055,1.055,0,0,1,2.11,0v7.738A1.055,1.055,0,0,1,43.345,44.4ZM41.587,26.814H40.094l-4.53,4.53a1.055,1.055,0,1,0,1.492,1.492l4.53-4.53ZM44.4,30.683V25.055A1.055,1.055,0,0,0,43.345,24H37.718a1.055,1.055,0,1,0,0,2.11H42.29v4.573a1.055,1.055,0,1,0,2.11,0Z" transform="translate(-24 -24)" />
                                                </svg>
                                            </IconButton>
                                        </ToolTipComponent> :
                                        <IconButton className={classes.paddingZero} onClick={() => fullScreenProperties.onEdit && fullScreenProperties.onEdit(!fullScreenProperties.enable)}>
                                            <CloseIcon />
                                        </IconButton>
                                }
                            </Grid>
                        }
                        {
                            chartFilter &&
                            <Grid item>
                                <TextBox
                                    className={classNames(classes.inlinetxt, minWidth)}
                                    onChange={(event) => chartFilter.onFilter && chartFilter.onFilter(event.target.value)}
                                    value={chartFilter.value}
                                    select
                                    SelectProps={
                                        {
                                            MenuProps: {
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "center"
                                                },
                                                getContentAnchorEl: null
                                            },
                                            IconComponent: (iconProps) => DropDownIcon(iconProps)
                                        }
                                    }
                                >
                                    {
                                        chartFilter.properties.map((option, index) => (
                                            <MenuItem key={`menuProperty_Options_${index}`} value={option.value} className={classes.menuItem}>
                                                {option.name}
                                            </MenuItem>
                                        ))
                                    }
                                </TextBox>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
            {/* {
                chartFilter &&
                <Grid container>
                    <Grid item xs={2}>
                        <TextBox
                            className={classNames(classes.inlinetxt, minWidth)}
                            onChange={(event) => chartFilter.onFilter && chartFilter.onFilter(event.target.value)}
                            value={chartFilter.value}
                            select
                            SelectProps={
                                {
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    },
                                    IconComponent: (iconProps) => DropDownIcon(iconProps)
                                }
                            }
                        >
                            {
                                chartFilter.properties.map((option, index) => (
                                    <MenuItem key={`menuProperty_Options_${index}`} value={option.value} className={classes.menuItem}>
                                        {option.name}
                                    </MenuItem>
                                ))
                            }
                        </TextBox>
                    </Grid>
                </Grid>
            } */}
            {chartData && children}
        </Grid >
    );
};

ChartContainer.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.element,
    menuProperties: PropTypes.array,
    chartData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    editProperties: PropTypes.object,
    title: PropTypes.string,
    theme: PropTypes.object,
    filterProperties: PropTypes.object,
    id: PropTypes.string,
    ruleProperties: PropTypes.object,
    height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fullScreenProperties: PropTypes.object,
    chartFilter: PropTypes.object,
    toggleProperties: PropTypes.object,
    textBoxWidth: PropTypes.string
};

export default withStyles((theme) => ({
    ...ChartContainerStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ChartContainer);