import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, MenuItem, FormLabel, IconButton, ClickAwayListener, ButtonGroup, Button } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';
import TextBox from '../TextBox/TextBox.jsx';
import DatasourceListStyles from '../DatasourceList/DatasourceListStyles.jsx';
import NotificationStyles from '../Notification/NotificationStyles.jsx';
// import UserAutoComplete from '../UserAutoComplete/UserAutoComplete.jsx';
import { getOrganizationDatasources } from '../../actions/datasourceActions';
import { getOrganizationDatasetList, getDriftAttributes } from '../../actions/datasetActions';
// import { getProperties } from '../../actions/settingActions.js';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import AutoCompleteCheckBox from '../AutoCompleteCheckBox/AutoCompleteCheckBox.jsx';
import { getDriftProperties } from '../../actions/settingActions';
import { sortTable, getUserProperty } from '../../helpers/appHelpers.js';
import { setDriftFilters as setFilters, getGroupByAttributes } from '../../actions/notificationActions';
import { appConstants } from '../../constants/appConstants.js';

const DriftReportFilter = (props) => {
    const { classes, theme } = props;
    const showGrouppedReportView = useSelector(({ notification }) => notification.showGrouppedReportView);
    const [enableFilter, setFilter] = useState(false);
    const [datasources, setDatasources] = useState([]);
    const [datasets, setDatasets] = useState([]);
    const [datasetList, setDatasetList] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [attributeList, setAttributeList] = useState([]);
    const [rules, setRules] = useState([]);
    const [groupByAttributes, setGroupByAttributes] = useState([]);
    const [defaultFilter] = useState({
        datasources: [{ name: 'All', isDefault: true }],
        datasets: [{ name: 'All', isDefault: true }],
        attributes: [{ name: 'All', isDefault: true }],
        rules: [{ name: 'All', isDefault: true }],
        group_by: "attribute",
        range: appConstants.driftReportFilter[1].value,
        filter_count: 20,
        group_by_attributes: [{ name: 'All', isDefault: true }],
        grouped_keys: ""
    });
    const [isInitLoad, setInitLoad] = useState(true);
    const [filters, setNotificationFilters] = useState({ ...JSON.parse(JSON.stringify(defaultFilter)) });
    const [isFiltersLoaded, setIsFiltersLoaded] = useState(false);
    const notificationFilters = useSelector(({ notification }) => notification.driftFilters);
    const dispatch = useDispatch();
    const groupbyAttributesControllerRef = useRef();

    const getDatasources = useCallback(() => {
        dispatch(getOrganizationDatasources()).then((response) => {
            if (response) {
                response = response.map((data) => { return { ...data, name: data.name ? data.name : "Untitled Datasource" }; });
                const filterDatasource = notificationFilters.datasources;
                const index = filterDatasource.findIndex((data) => data.isDefault);
                const sources = [];

                if (filterDatasource[index]?.isDefault === true) {
                    response = response.map((option) => { sources.push(option.id); return { ...option, isSelected: true }; });
                    filters.datasources = response.filter((option) => !option.isDefault);
                } else {
                    const selectedIdList = filterDatasource.map((data) => data.id);
                    response = response.map((data) => { return { ...data, isSelected: selectedIdList.includes(data.id) }; });
                }
                setDatasources([...response]);
            }
        });
    }, [dispatch, filters, notificationFilters.datasources]);

    const getDatasets = useCallback(() => {
        dispatch(getOrganizationDatasetList()).then((response) => {
            if (response) {
                response = response.map((data) => { return { ...data, name: `${data.source_name ? data.source_name : "Untitled Datasource"}.${data.name ? data.name : "Untitled Dataset"}` }; });
                const filterDataset = notificationFilters.datasets;
                const index = filterDataset.findIndex((data) => data.isDefault);

                if (filterDataset[index]?.isDefault === true) {
                    response = response.map((option) => { return { ...option, isSelected: true }; });
                    filters.datasets = response.filter((option) => !option.isDefault);
                } else {
                    const selectedIdList = filterDataset.map((data) => data.id);
                    response = response.map((data) => { return { ...data, isSelected: selectedIdList.includes(data.id) }; });
                }
                setDatasetList([...response]);
                setDatasets([...response]);
            }
        });
    }, [dispatch, filters, notificationFilters.datasets]);

    const getAttributes = useCallback(() => {
        dispatch(getDriftAttributes()).then((response) => {
            if (response) {
                response = response.map((data) => { return { ...data, name: `${data.source_name ? data.source_name : "Untitled Datasource"}.${data.dataset_name ? data.dataset_name : "Untitled Dataset"}.${data.name}` }; });
                const filterAttributes = notificationFilters.attributes;
                const index = filterAttributes.findIndex((data) => data.isDefault);

                if (filterAttributes[index]?.isDefault === true) {
                    response = response.map((option) => { return { ...option, isSelected: true }; });
                    filters.attributes = response.filter((option) => !option.isDefault);
                } else {
                    const selectedIdList = filterAttributes.map((data) => data.id);
                    response = response.map((data) => { return { ...data, isSelected: selectedIdList.includes(data.id) }; });
                }
                setAttributes([...response]);
                setAttributeList([...response]);
            }
        });
    }, [dispatch, filters, notificationFilters.attributes]);

    const getGroupedAttributes = useCallback((datasets) => {
        if (!datasets) {
            datasets = filters.datasets;
        }
        const datasets_ids = datasets.filter((data) => data.isSelected).map((data) => data.id);
        const requstParams = {
            "datasets": datasets_ids
        };
        if (groupbyAttributesControllerRef && groupbyAttributesControllerRef.current) {
            groupbyAttributesControllerRef.current.abort();
        }
        const controller = new AbortController();
        groupbyAttributesControllerRef.current = controller;
        const token = { signal: groupbyAttributesControllerRef?.current?.signal };
        dispatch(getGroupByAttributes(requstParams, token)).then((response) => {
            if (response) {
                response = response.map((data) => { return { ...data, name: `${data.source_name ? data.source_name : "Untitled Datasource"}.${data.dataset_name ? data.dataset_name : "Untitled Dataset"}.${data.name}` }; });
                const filterAttributes = notificationFilters.group_by_attributes;
                const index = filterAttributes.findIndex((data) => data.isDefault);

                if (filterAttributes[index]?.isDefault === true) {
                    response = response.map((option) => { return { ...option, isSelected: false }; });
                    filters.attributes = response.filter((option) => !option.isDefault);
                } else {
                    const selectedIdList = filterAttributes.map((data) => data.id);
                    response = response.map((data) => { return { ...data, isSelected: selectedIdList.includes(data.id) }; });
                }
                setGroupByAttributes([...response]);
            }
        });
    }, [dispatch, filters, notificationFilters.group_by_attributes]);

    const getRules = useCallback(() => {
        const userInfo = getUserProperty();
        dispatch(getDriftProperties(userInfo.organization ? userInfo.organization : userInfo.organization_id)).then((response) => {
            if (response) {
                let driftRules = response && response.driftProperties ? Object.keys(response.driftProperties).filter((data) => response.driftProperties[data]) : [];
                driftRules = [...new Set(driftRules)].map((data) => { return { name: data, isSelected: false }; });
                const filterRules = notificationFilters.rules;
                const index = filterRules.findIndex((data) => data.isDefault);
                if (filterRules[index]?.isDefault === true) {
                    driftRules = driftRules.map((option) => { return { ...option, isSelected: true }; });
                    filters.rules = driftRules.filter((option) => !option.isDefault);
                } else {
                    const selectedIdList = filterRules.map((data) => data.name);
                    driftRules = driftRules.map((data) => { return { ...data, isSelected: selectedIdList.includes(data.name) }; });
                }
                setRules([...driftRules]);
            }
        });
    }, [dispatch, filters, notificationFilters.rules]);


    useEffect(() => {
        if (isInitLoad) {
            setInitLoad(false);
            getDatasources();
            getDatasets();
            getGroupedAttributes();
            getAttributes();
            getRules();
        }
    }, [getAttributes, getDatasets, getDatasources, getGroupedAttributes, getRules, isInitLoad]);

    useEffect(() => {
        if (!isFiltersLoaded) {
            setNotificationFilters({ ...notificationFilters });
            setIsFiltersLoaded(true);
        }
    }, [isFiltersLoaded, notificationFilters]);


    const setFilterSources = (type, values) => {
        if (type === "datasources") {
            setDatasources([...values]);
        } else if (type === "datasets") {
            setDatasets([...values]);
        } else if (type === "attributes") {
            setAttributes([...values]);
        } else if (type === "group_by_attributes") {
            setGroupByAttributes([...values]);
        } else {
            setRules([...values]);
        }
    };


    const onChangeFilter = (property, value) => {
        if ((property === "datasources" || property === "datasets" || property === "attributes" || property === "rules" || property === "group_by_attributes")) {
            if (typeof value !== 'string') {
                let sources = [];
                if (property === "datasources") {
                    sources = [...datasources];
                } else if (property === "datasets") {
                    sources = [...datasets];
                } else if (property === "attributes") {
                    sources = [...attributes];
                } else if (property === "group_by_attributes") {
                    sources = [...groupByAttributes];
                    const selectedAttribute = value.name.replace(`${value.source_name}.${value.dataset_name}.`, '');
                    let groupedKey = filters.grouped_keys ? filters.grouped_keys : "";
                    const groupedKeys = groupedKey.split(',').filter((key) => key.length > 0);
                    const selectedKeyIndex = groupedKeys.indexOf(selectedAttribute);
                    if (!value.isSelected) {
                        groupedKeys.push(selectedAttribute);
                    } else {
                        if (selectedKeyIndex > -1) {
                            groupedKeys.splice(selectedKeyIndex, 1);
                        }
                    }
                    groupedKey = groupedKeys.join(',');
                    filters.grouped_keys = (value.name !== 'all') ? groupedKey : '';
                } else {
                    sources = [...rules];
                }
                if (value.name === 'all') {
                    sources = sources.map((data) => { return { ...data, isSelected: value.isSelected }; });
                } else {
                    const index = property !== "rules" ? sources.findIndex((data) => data.id === value.id) : sources.findIndex((data) => data.name === value.name);
                    if (index !== -1) {
                        sources[index].isSelected = !value.isSelected;
                    }
                }
                setFilterSources(property, sources);
                filters[property] = [...sources];
                if (property === "datasources" || property === "datasets") {
                    const selectedList = sources.filter((source) => source.isSelected).map((data) => data.id);
                    if (property === "datasources") {
                        const datasetInfos = datasetList.filter((data) => selectedList.includes(data.source_id)).map((data) => { return { ...data, isSelected: true }; });
                        const attributeInfos = attributeList.filter((data) => selectedList.includes(data.source_id)).map((data) => { return { ...data, isSelected: true }; });
                        setFilterSources("datasets", datasetInfos);
                        setFilterSources("attributes", attributeInfos);
                        getGroupedAttributes(datasetInfos);
                    } else {
                        getGroupedAttributes(sources);
                        const attributeInfos = attributeList.filter((data) => selectedList.includes(data.dataset_id)).map((data) => { return { ...data, isSelected: true }; });
                        setFilterSources("attributes", attributeInfos);
                    }
                }
            }
        } else {
            filters[property] = value;
        }
        setNotificationFilters(JSON.parse(JSON.stringify(filters)));
        dispatch(setFilters({ ...filters }));
    };

    const resetFilter = useCallback(() => {
        const filter = {
            datasources: [{ name: 'All', isDefault: true }],
            datasets: [{ name: 'All', isDefault: true }],
            attributes: [{ name: 'All', isDefault: true }],
            rules: [{ name: 'All', isDefault: true }],
            group_by: "attribute",
            range: appConstants.driftReportFilter[1].value,
            filter_count: 20,
            group_by_attributes: [{ name: 'All', isDefault: true }],
            grouped_keys: ""
        };
        const filters = {
            ...filter
        };
        const datasourceList = datasources.filter((data) => !data.isDefault).map((data) => { return { ...data, isSelected: true }; });
        const datasetsList = datasetList.filter((data) => !data.isDefault).map((data) => { return { ...data, isSelected: true }; });
        const attributesList = attributeList.filter((data) => !data.isDefault).map((data) => { return { ...data, isSelected: true }; });
        const rulesList = rules.filter((data) => !data.isDefault).map((data) => { return { ...data, isSelected: true }; });
        const groupByAttributesList = groupByAttributes.filter((data) => !data.isDefault).map((data) => { return { ...data, isSelected: false }; });
        setDatasets([...datasetsList]);
        setDatasources([...datasourceList]);
        setAttributes([...attributesList]);
        setGroupByAttributes([...groupByAttributesList]);
        setRules([...rulesList]);
        dispatch(setFilters({ ...filters }));
    }, [attributeList, datasetList, datasources, dispatch, rules, groupByAttributes]);

    useEffect(() => {
        if (!isFiltersLoaded) {
            setNotificationFilters({ ...notificationFilters });
            setIsFiltersLoaded(true);
        }
    }, [isFiltersLoaded, notificationFilters]);

    const enableFilterInfo = () => {
        setFilter(true);
        setNotificationFilters({ ...notificationFilters });
    };

    return (
        <Grid container alignItems="center" justify="space-between" className={classNames(classes.headerContainer, classes.catHeaderContainer)}>
            <Grid item>
                <Typography variant="h2" component="h2">
                    Drift Report
                </Typography>
            </Grid>
            <Grid item style={{ display: 'flex' }}>
                {
                    !showGrouppedReportView &&
                    <ButtonGroup className={classNames(classes.buttonGroup, classes.marginRight10)}>
                        <Button
                            variant="contained"
                            onClick={() => onChangeFilter("group_by", "datasource")}
                            className={classNames(filters.group_by === 'datasource' ? 'active' : '')}>
                            <TooltipComponent title="Data Source" placement="bottom" arrow>
                                <svg xmlns="http://www.w3.org/2000/svg" width="11.289" height="15.394" viewBox="0 0 11.289 15.394" fill={theme.palette.primary.main}>
                                    <path id="database_17_" data-name="database (17)" d="M15.645,17.394c2.805,0,5.645-.793,5.645-2.309V4.309C21.289,2.793,18.45,2,15.645,2S10,2.793,10,4.309V15.085C10,16.6,12.839,17.394,15.645,17.394Zm0-14.368c2.865,0,4.618.831,4.618,1.283s-1.753,1.283-4.618,1.283-4.618-.831-4.618-1.283S12.78,3.026,15.645,3.026ZM11.026,5.695a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924V7.9c0,.452-1.753,1.283-4.618,1.283S11.026,8.353,11.026,7.9Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Z" transform="translate(-10 -2)" fill={filters.group_by === 'datasource' ? theme.palette.background.paper : theme.palette.grey.light} />
                                </svg>
                            </TooltipComponent>
                        </Button>
                        <Button variant="contained"
                            onClick={() => onChangeFilter("group_by", "dataset")}
                            className={classNames(filters.group_by === 'dataset' ? 'active' : '')}>
                            <TooltipComponent title="Dataset" placement="bottom" arrow>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17.751" height="16.711" viewBox="0 0 17.751 16.711">
                                    <path id="table" d="M0,15V31.711H17.751V15Zm16.711,1.04v2.115H1.04V16.04ZM9.4,22.315h3.12V24.4H9.4ZM8.355,24.4H5.235v-2.08h3.12Zm1.04-3.12V19.2h3.12v2.08Zm-1.04,0H5.235V19.2h3.12Zm-4.16,0H1.04V19.2H4.2Zm0,1.04V24.4H1.04v-2.08Zm0,3.12v2.08H1.04v-2.08Zm1.04,0h3.12v2.08H5.235Zm3.12,3.12v2.115H5.235V28.556Zm1.04,0h3.12v2.115H9.4Zm0-1.04v-2.08h3.12v2.08Zm4.16-2.08h3.155v2.08H13.556Zm0-1.04v-2.08h3.155V24.4Zm0-3.12V19.2h3.155v2.08ZM1.04,28.556H4.2v2.115H1.04Zm12.516,2.115V28.556h3.155v2.115Z" transform="translate(0 -15)" fill={filters.group_by === 'dataset' ? theme.palette.background.paper : theme.palette.grey.light} />
                                </svg>
                            </TooltipComponent>
                        </Button>
                        <Button variant="contained"
                            onClick={() => onChangeFilter("group_by", "attribute")}
                            className={classNames(filters.group_by === 'attribute' ? 'active' : '')}>
                            <TooltipComponent title="Data Attribute" placement="bottom" arrow>
                                <svg xmlns="http://www.w3.org/2000/svg" width="17.773" height="16.732" viewBox="0 0 17.773 16.732">
                                    <path id="table" d="M0,15V31.732H17.773V15Zm16.732,1.041v2.118h-7.3l.024-2.118H4.265v2.118H1.041V16.041H16.732ZM9.407,22.325h3.124v2.083H9.407Zm0-1.041V19.2h3.124v2.083Zm-5.207,0H1.041V19.2H4.2Zm0,1.041v2.083H1.041V22.325Zm0,3.124v2.083H1.041V25.449Zm5.207,3.124h3.124v2.118H9.407Zm0-1.041V25.449h3.124v2.083Zm4.166-2.083h3.159v2.083H13.573Zm0-1.041V22.325h3.159v2.083Zm0-3.124V19.2h3.159v2.083ZM1.041,28.573H4.2v2.118H1.041Zm12.532,2.118V28.573h3.159v2.118Z" transform="translate(0 -15)" fill={filters.group_by === 'attribute' ? theme.palette.background.paper : theme.palette.grey.light} />
                                </svg>
                            </TooltipComponent>
                        </Button>
                    </ButtonGroup>
                }
                <Grid container alignItems="center">
                    {
                        enableFilter &&
                        <Grid className={classNames(classes.filterContainer)}>
                            <ClickAwayListener onClickAway={() => setFilter(false)} mouseEvent={'onMouseUp'} touchEvent={'onTouchEnd'}>
                                <Grid container alignItems="center" justify="space-between" className={classNames(classes.datasourceFilterSection, "datasourceFilterSection")}>
                                    <TooltipComponent title="Close" placement="bottom" arrow>
                                        <IconButton onClick={() => setFilter(!enableFilter)} className={classes.marginRight12}>
                                            <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                            </svg>
                                        </IconButton>
                                    </TooltipComponent>
                                    <Grid item className={classes.userSelectContainer}>
                                        <FormLabel>
                                            Datasources
                                        </FormLabel>
                                        <AutoCompleteCheckBox
                                            availableList={sortTable(datasources, "asc", "name")}
                                            displayValue="name"
                                            onChange={(value) => onChangeFilter("datasources", value)}
                                        />
                                    </Grid>
                                    <Grid item className={classes.userSelectContainer}>
                                        <FormLabel>
                                            Datasets
                                        </FormLabel>
                                        <AutoCompleteCheckBox
                                            availableList={sortTable(datasets, "asc", "name")}
                                            displayValue="name"
                                            onChange={(value) => onChangeFilter("datasets", value)}
                                        />
                                    </Grid>
                                    <Grid item className={classes.userSelectContainer}>
                                        <FormLabel>
                                            Attributes
                                        </FormLabel>
                                        <AutoCompleteCheckBox
                                            availableList={sortTable(attributes, "asc", "name")}
                                            displayValue="name"
                                            onChange={(value) => onChangeFilter("attributes", value)}
                                        />
                                    </Grid>
                                    <Grid item className={classes.userSelectContainer}>
                                        <FormLabel>
                                            Rules
                                        </FormLabel>
                                        <AutoCompleteCheckBox
                                            availableList={sortTable(rules, "asc", "name")}
                                            displayValue="name"
                                            onChange={(value) => onChangeFilter("rules", value)}
                                        />
                                    </Grid>
                                    {
                                        showGrouppedReportView &&
                                        <Grid item className={classes.userSelectContainer}>
                                            <FormLabel>
                                                Group By
                                            </FormLabel>
                                            <AutoCompleteCheckBox
                                                availableList={groupByAttributes}
                                                displayValue="name"
                                                onChange={(value) => onChangeFilter("group_by_attributes", value)}
                                            />
                                        </Grid>
                                    }
                                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                                        <TextBox
                                            label="Date Range"
                                            value={filters.range}
                                            name="range"
                                            fullWidth
                                            select
                                            onChange={(event) => onChangeFilter(event.target.name, event.target.value)}
                                            SelectProps={
                                                {
                                                    className: classes.cursorPointer,
                                                    value: filters.range,
                                                    onChange: (event) => onChangeFilter(event.target.name, event.target.value),
                                                    MenuProps: {
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "center"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "center"
                                                        },
                                                        getContentAnchorEl: null
                                                    }
                                                }
                                            }
                                        >
                                            {
                                                appConstants.driftReportFilter.map((filter, index) =>
                                                    <MenuItem key={index} value={filter.value}>
                                                        {filter.label}
                                                    </MenuItem>
                                                )
                                            }
                                        </TextBox>
                                    </Grid>
                                    <Grid item>
                                        <TooltipComponent title="Clear Filter" placement="bottom" arrow>
                                            <IconButton onClick={() => resetFilter()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22.795" viewBox="0 0 20 22.795">
                                                    <g id="filter_10_" data-name="filter (10)" transform="translate(-31.388)">
                                                        <path id="Path_1437" data-name="Path 1437" d="M46.071,0a5.326,5.326,0,0,0-5.1,3.8h-8.59a1,1,0,0,0-.814,1.564l5.776,7.882a1.046,1.046,0,0,1,.185.6V21.75a1.044,1.044,0,0,0,1.668.835l2.564-1.937a1.527,1.527,0,0,0,.612-1.22V13.84a1.047,1.047,0,0,1,.185-.6l2.07-2.825A5.31,5.31,0,1,0,46.071,0ZM41.477,12.462a2.375,2.375,0,0,0-.438,1.377c0,6.076.033,5.658-.075,5.737l-2.1,1.586V13.84a2.376,2.376,0,0,0-.428-1.364l-5.38-7.341h7.7A5.307,5.307,0,0,0,43.369,9.88Zm4.594-3.18a3.973,3.973,0,1,1,3.982-3.973A3.982,3.982,0,0,1,46.071,9.282Z" transform="translate(0)" fill="#f38080" />
                                                        <path id="Path_1438" data-name="Path 1438" d="M321.45,76.089a.668.668,0,0,0-.944,0l-.793.791-.793-.791a.668.668,0,0,0-.944.945l.791.79-.791.79a.668.668,0,0,0,.944.945l.793-.791.793.791a.668.668,0,0,0,.944-.945l-.791-.79.791-.79A.668.668,0,0,0,321.45,76.089Z" transform="translate(-273.642 -72.515)" fill="#f38080" />
                                                    </g>
                                                </svg>
                                            </IconButton>
                                        </TooltipComponent>
                                    </Grid>
                                </Grid>
                            </ClickAwayListener>
                        </Grid>
                    }
                    <TooltipComponent title="Filters" placement="bottom" arrow>
                        <IconButton onClick={() => enableFilterInfo()} className={classNames(classes.marginRight7, classes.filterImg)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.923" viewBox="0 0 20 16.923">
                                <g transform="translate(-3 -5)">
                                    <path id="Path_1391" data-name="Path 1391" d="M22.231,6.538H11.462A1.54,1.54,0,0,0,9.923,5H8.385A1.54,1.54,0,0,0,6.846,6.538H3.769a.769.769,0,1,0,0,1.538H6.846A1.54,1.54,0,0,0,8.385,9.615H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,8.077V6.538H9.923V7.3s0,0,0,0,0,0,0,0v.766Z" fill={theme.palette.primary.main} />
                                    <path id="Path_1392" data-name="Path 1392" d="M22.231,14.538H17.615A1.54,1.54,0,0,0,16.077,13H14.538A1.54,1.54,0,0,0,13,14.538H3.769a.769.769,0,0,0,0,1.538H13a1.54,1.54,0,0,0,1.538,1.538h1.538a1.54,1.54,0,0,0,1.538-1.538h4.615a.769.769,0,1,0,0-1.538Zm-7.692,1.538V14.538h1.538V15.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -1.846)" fill={theme.palette.primary.main} />
                                    <path id="Path_1393" data-name="Path 1393" d="M22.231,22.538H11.462A1.54,1.54,0,0,0,9.923,21H8.385a1.54,1.54,0,0,0-1.538,1.538H3.769a.769.769,0,0,0,0,1.538H6.846a1.54,1.54,0,0,0,1.538,1.538H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,24.077V22.538H9.923V23.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -3.692)" fill={theme.palette.primary.main} />
                                </g>
                            </svg>
                        </IconButton>
                    </TooltipComponent>
                    {/* <TooltipComponent title={"Show Grouped View"} arrow>
                        <Switch
                            color="secondary"
                            size="small"
                            onChange={(event) => dispatch(toggleGroupReportView(!showGrouppedReportView))}
                            checked={showGrouppedReportView} />
                    </TooltipComponent> */}
                </Grid>
            </Grid>
        </Grid>
    );
};

DriftReportFilter.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...NotificationStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DriftReportFilter);