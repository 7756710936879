import React, { Fragment, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, withStyles, Typography, IconButton, Button, Box, MenuItem, Popover } from '@material-ui/core';
import classNames from 'classnames';
import Lottie from 'react-lottie';
import Loader from '../Loaders/Loader.jsx';
import { runProfile } from '../../actions/scheduleActions';
import { getSourceDqScore, getViews, updateDataSourceIcon, updateViews, getSourceRating } from '../../actions/datasourceActions';
import TextBox from '../TextBox/TextBox.jsx';
import Circular from '../Charts/Circular.jsx';
import Styles from '../../layouts/Styles.jsx';
import Logo from '../../assets/images/dqicon_placeholder.svg';
import ViewIcon from '../../assets/images/eye3.svg';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Schedule from '../Schedule/Schedule.jsx';
import Permission from '../Permission/Permission.jsx';
import DatasourceStyles from './DatasourceStyles.jsx';
import IconSelector from '../IconSelector/IconSelector.jsx';
import { updateDatasetIcon, getWorkbookInfo } from '../../actions/datasetActions';
import { toggleSidePanel, getCommentCount } from '../../actions/notificationActions';
import { getDatasourceIcons, getQuadrantError } from '../../helpers/appHelpers';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import animationData from '../../assets/json/heartanim.json';
import Rating from '../Rating/Rating.jsx';
import tableauico from '../../assets/images/tableau.png';
import Verified from '../../assets/images/verified.svg';
import InProgess from '../../assets/images/wip.svg';
import Deprecated from '../../assets/images/deprecated.svg';
import Issues from '../../assets/images/issues.svg';
import { appConstants } from '../../constants/appConstants.js';
import SettingsIcon from '@material-ui/icons/Settings';
import NumberInput from '../TextBox/NumberInput.jsx';
import palette from '../../theme/palette.js';

export const ColorDropIcon = (props) => {
    return (
        <svg className="ColorDropIcon" xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none" >
            <path fillRule="evenodd" clipRule="evenodd" d="M8.85589 22C3.98915 22 5.88428e-05 18.0906 5.88428e-05 13.224C5.88428e-05 8.5169 5.26551 3.96945 8.29753 0.299246C8.61671 -0.0996633 9.09531 -0.0996633 9.33462 0.299246C12.3663 3.96913 17.6321 8.51651 17.6321 13.224C17.6321 18.0907 13.7227 22 8.85608 22H8.85589Z" fill={props.dropColor} />
        </svg>
    );
};

ColorDropIcon.propTypes = {
    dropColor: PropTypes.string
};

const DatasourceInfo = (props) => {
    const { classes, source, onChange, onSave, deleteData, breadcrumb, history, theme, type, favorite, changeFavorite, redirectProfile, datasourceId, datasetId, isEditable, users, updateAuditLog, governance, sourceId, inviteSourceUser, createSourceShare, getAuditLogs, user, loading, deleteShare, isExportLoading, profileTab, exportProperties, exportRulesProperties, setDuplicateError, updateGovernance, selectedDataset, updateGovernanceUpdate, isGovernanceUpdate, sourceName, dataSetLink, navigateOverview, connectionStatus, onChangeTabEvent, tableauItems, connector_type, workbookId, itemId, getIcon, getPath, changeCertified, isCertified, dq_score_threshold } = props;

    const [score, setScore] = useState({});
    const [openIconSelector, setOpenIconSelector] = useState(false);
    const [openDqScoreThreshold, setOpenDqScoreThreshold] = useState(null);
    const [hasQuadrantError, setHasQuadrantError] = useState(false);
    const [schedule, setSchedule] = useState({ open: false, anchorElement: null, datasetId: 0, datasourceId: 0, isDataset: true });
    const [datasourceIcon, setIcon] = useState(null);
    const dispatch = useDispatch();
    const [views, setViews] = useState(0);
    const actionItemCount = useSelector(({ notification }) => notification.count);
    const conversationCount = useSelector(({ notification }) => notification.conversationCount);
    const jobDetail = useSelector(({ datasource }) => datasource.jobDetail);
    const datasources = useSelector(({ datasource }) => datasource.datasourceNames);
    const rating = useSelector(({ datasource }) => datasource.rating);
    const commentCount = useSelector(({ notification }) => notification.commentCount);
    const tableau_detail = useSelector(({ datasource }) => datasource.datasource);
    const tableau_workbook_detail = useSelector(({ dataset }) => dataset.workbookInfo);
    const config = useSelector(({ setting }) => setting.config);
    const [error, setError] = useState(false);
    const sensitive_datasets = useSelector(({ dataset }) => dataset.datasetInfo.sensitive_datasets);
    const [isSensitive, setIsSensitive] = useState(false);
    const userDetail = useSelector(({ account }) => account.user);
    const userConfig = useSelector(({ setting }) => setting.user_config);

    const showPanel = useCallback((type) => {
        dispatch(toggleSidePanel({ type: type, sidePanel: true }));
    }, [dispatch]);

    const navigate = (path, connector) => {
        if (path !== "") {
            if (path.includes('/catalog/')) {
                const model = {
                    'source_id': datasourceId,
                    'dataset_id': null
                };
                dispatch(updateViews(model));
            }
            history.push({ pathname: path, state: { datasetTab: true, datasource: source, connector: connector } });
        }
    };

    const updateIcon = useCallback((selectedIcon) => {
        if (selectedIcon) {
            setIcon(selectedIcon);
        }
        setOpenIconSelector(false);
        const icon = {
            icon: selectedIcon.iconName
        };
        if (type === 'dataset') {
            icon.datasetId = datasetId;
            dispatch(updateDatasetIcon(icon));
        } else {
            icon.datasourceId = datasourceId;
            dispatch(updateDataSourceIcon(icon));
        }
    }, [datasetId, datasourceId, dispatch, type]);

    const openScheduler = useCallback((event) => {
        setSchedule({ open: true, anchorElement: event.target, datasetId: datasetId, datasourceId: datasourceId, isDataset: false });
    }, [datasetId, datasourceId]);

    const datasetopenSchedule = useCallback((event) => {
        setSchedule({ open: true, anchorElement: event.target, datasourceId: datasourceId, isDataset: true, selectedDataset: selectedDataset });
    }, [datasourceId, selectedDataset]);

    const runDatasetProfile = useCallback(() => {
        dispatch(runProfile(source.id));
    }, [dispatch, source.id]);

    const getDqScore = useCallback(() => {
        dispatch(getSourceDqScore(datasourceId, type === "dataset" ? datasetId : 0)).then((response) => {
            if (response) {
                setScore({ ...response });
            }
        });
    }, [datasetId, datasourceId, dispatch, type]);

    const getSourceViews = useCallback(() => {
        dispatch(getViews(datasourceId, type === "dataset" ? datasetId : 0)).then((response) => {
            if (response) {
                setViews(response);
            }
        });
    }, [datasetId, datasourceId, dispatch, type]);

    const loadSourceIcon = useCallback((icon) => {
        if (datasourceIcon) {
            return;
        }
        const datasourceIcons = getDatasourceIcons();
        const sourceIcon = datasourceIcons.find((p) => p.iconName.toLowerCase() === icon.toLowerCase());
        if (sourceIcon) {
            setIcon({ ...sourceIcon });
        }
    }, [datasourceIcon]);

    const getJobStatus = useCallback(() => {
        if ((jobDetail.datasource_id === parseInt(datasourceId) && type !== "dataset") || (jobDetail.dataset_id === parseInt(datasetId) && type === "dataset")) {
            getDqScore();
        }
    }, [datasetId, datasourceId, getDqScore, jobDetail, type]);


    const getRating = useCallback(() => {
        dispatch(getSourceRating(datasourceId, type === "dataset" || type === "tableau_item" ? datasetId : 0, type === "tableau_item" ? itemId : 0));
    }, [datasetId, datasourceId, dispatch, itemId, type]);

    const commentTotalCount = useCallback(() => {
        dispatch(getCommentCount(datasourceId, type === "dataset" || type === "tableau_item" ? datasetId : 0, type === "tableau_item" ? itemId : 0));
    }, [datasetId, datasourceId, dispatch, itemId, type]);

    useEffect(() => {
        if (source && source.icon) {
            loadSourceIcon(source.icon);
        }
        /*
         * if (type !== 'dataset') {
         *     ValidatorForm.addValidationRule('isDuplicateCheck', (value) => {
         *         const matchedSource = datasources.filter((data) => data.toLowerCase() === value.toLowerCase());
         *         setDuplicateName(matchedSource.length >= 1);
         *         return matchedSource.length <= 1;
         *     });
         * }
         */
        if (sensitive_datasets && sensitive_datasets >= 1) {
            setIsSensitive(true);
        } else {
            setIsSensitive(false);
        }
    }, [datasetId, datasources, loadSourceIcon, sensitive_datasets, source, type]);

    useEffect(() => {
        getDqScore();
        getSourceViews();
        getRating();
        commentTotalCount();
    }, [datasourceId, datasetId, type, dispatch, getDqScore, getSourceViews, getRating, commentTotalCount]);

    useEffect(() => {
        getJobStatus();
    }, [getJobStatus]);

    useEffect(() => {
        if (workbookId && workbookId !== 0) {
            dispatch(getWorkbookInfo({ dataset_id: workbookId })).then((response) => {
            });
        }
    }, [dispatch, workbookId]);

    const redirectDataset = (type) => {
        if (type === "dataset" && profileTab >= 0) {
            redirectProfile(profileTab);
        } else if (type === "datasource") {
            onChangeTabEvent(2);
        }
    };

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const onSubmit = (value) => {
        if (value && value.trim() === sourceName && sourceName !== "") {
            return false;
        }
        const duplicate = datasources.filter((data) => (data === value && tableau_detail?.name !== value));
        if (setDuplicateError) {
            setDuplicateError(duplicate.length !== 0);
        }
        if (duplicate.length !== 0) {
            return setError(true);
        }
        setError(false);
        onSave('name');
    };

    const getHelperText = () => {
        if ((isEditable || (user.role && user.role.properties_permission && user.role.properties_permission.is_edit)) && !loading && source.name === "") {
            return "Please enter the source name";
        } else if (error) {
            return "Name is Duplicated";
        }
        return "";
    };

    const getCrumbIcon = (type) => {
        if (type === 'catalog') {
            return (
                <TooltipComponent title={type} placement="bottom" arrow>
                    <svg xmlns="http://www.w3.org/2000/svg" className={classes.crumbIcon} width="26.176" height="25.086" viewBox="0 0 26.176 25.086">
                        <g id="security" transform="translate(0 -1)">
                            <path id="Path_251" data-name="Path 251" d="M18.544,25.27a.547.547,0,0,1-.212-.043c-.26-.11-6.332-2.734-6.332-8.1V12.571a.546.546,0,0,1,.371-.517l6-2.025a.546.546,0,0,1,.349,0l6,2.025a.545.545,0,0,1,.371.517v4.558c0,5.364-6.073,7.988-6.332,8.1a.547.547,0,0,1-.212.043ZM13.091,12.963v4.165c0,4.127,4.423,6.5,5.453,6.995,1.03-.5,5.453-2.878,5.453-6.995V12.963l-5.453-1.842Z" transform="translate(1.088 0.816)" fill="#ffffff" />
                            <path id="Path_252" data-name="Path 252" d="M17.727,19.408H17.7a.541.541,0,0,1-.4-.2L15.12,16.476a.545.545,0,0,1,.852-.682l1.8,2.25,3.387-3.385a.545.545,0,1,1,.771.771l-3.817,3.817a.543.543,0,0,1-.385.16Z" transform="translate(1.36 1.224)" fill="#ffffff" />
                            <path id="Path_253" data-name="Path 253" d="M20.178,8.635H3.817A3.817,3.817,0,0,1,3.817,1h16.36a3.817,3.817,0,1,1,0,7.635ZM3.817,2.091a2.727,2.727,0,1,0,0,5.453h16.36a2.727,2.727,0,1,0,0-5.453Z" transform="translate(0 0)" fill="#ffffff" />
                            <path id="Path_254" data-name="Path 254" d="M4.636,6.272A1.636,1.636,0,1,1,6.272,4.636,1.638,1.638,0,0,1,4.636,6.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,4.636,4.091Z" transform="translate(0.272 0.181)" fill="#ffffff" />
                            <path id="Path_255" data-name="Path 255" d="M9.636,6.272a1.636,1.636,0,1,1,1.636-1.636A1.638,1.638,0,0,1,9.636,6.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,9.636,4.091Z" transform="translate(0.725 0.181)" fill="#ffffff" />
                            <path id="Path_256" data-name="Path 256" d="M10.361,14.635H3.817A3.817,3.817,0,0,1,3.817,7h16.36a3.74,3.74,0,0,1,2.321.792.546.546,0,0,1-.671.861,2.656,2.656,0,0,0-1.65-.562H3.817a2.727,2.727,0,0,0,0,5.453h6.544a.545.545,0,0,1,0,1.091Z" transform="translate(0 0.544)" fill="#ffffff" />
                            <path id="Path_257" data-name="Path 257" d="M4.636,12.272a1.636,1.636,0,1,1,1.636-1.636A1.638,1.638,0,0,1,4.636,12.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,4.636,10.091Z" transform="translate(0.272 0.725)" fill="#ffffff" />
                            <path id="Path_258" data-name="Path 258" d="M9.636,12.272a1.636,1.636,0,1,1,1.636-1.636A1.638,1.638,0,0,1,9.636,12.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,9.636,10.091Z" transform="translate(0.725 0.725)" fill="#ffffff" />
                            <path id="Path_259" data-name="Path 259" d="M10.361,20.635H3.817a3.817,3.817,0,0,1,0-7.635h6.544a.545.545,0,0,1,0,1.091H3.817a2.727,2.727,0,0,0,0,5.453h6.544a.545.545,0,1,1,0,1.091Z" transform="translate(0 1.088)" fill="#ffffff" />
                            <path id="Path_260" data-name="Path 260" d="M4.636,18.272a1.636,1.636,0,1,1,1.636-1.636A1.638,1.638,0,0,1,4.636,18.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,4.636,16.091Z" transform="translate(0.272 1.269)" fill="#ffffff" />
                            <path id="Path_261" data-name="Path 261" d="M9.636,18.272a1.636,1.636,0,1,1,1.636-1.636A1.638,1.638,0,0,1,9.636,18.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,9.636,16.091Z" transform="translate(0.725 1.269)" fill="#ffffff" />
                        </g>
                    </svg>
                </TooltipComponent>
            );
        } else if (type === 'datasource') {
            return (
                <TooltipComponent title={type} placement="bottom" arrow>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.289" height="15.394" viewBox="0 0 11.289 15.394" className={classes.crumbIcon}>
                        <path id="database_17_" data-name="database (17)" d="M15.645,17.394c2.805,0,5.645-.793,5.645-2.309V4.309C21.289,2.793,18.45,2,15.645,2S10,2.793,10,4.309V15.085C10,16.6,12.839,17.394,15.645,17.394Zm0-14.368c2.865,0,4.618.831,4.618,1.283s-1.753,1.283-4.618,1.283-4.618-.831-4.618-1.283S12.78,3.026,15.645,3.026ZM11.026,5.695a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924V7.9c0,.452-1.753,1.283-4.618,1.283S11.026,8.353,11.026,7.9Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Z" transform="translate(-10 -2)" fill="#FFFFFF" />
                    </svg>
                </TooltipComponent>
            );
        } else if (type === 'tableau_item') {
            return (
                <TooltipComponent title={type} placement="bottom" arrow>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.66" height="20.661" viewBox="0 0 20.66 20.661">
                        <g id="dashboard_2" transform="translate(-8203 -2812)">
                            <path id="Rectangle_13" data-name="Rectangle 13" d="M2.22,1.727a.494.494,0,0,0-.493.493V7.586a.494.494,0,0,0,.493.493H7.586a.494.494,0,0,0,.493-.493V2.22a.494.494,0,0,0-.493-.493H2.22M2.22,0H7.586a2.22,2.22,0,0,1,2.22,2.22V7.586a2.22,2.22,0,0,1-2.22,2.22H2.22A2.22,2.22,0,0,1,0,7.586V2.22A2.22,2.22,0,0,1,2.22,0Z" transform="translate(8203 2812)" fill="#f38080" />
                            <path id="Rectangle_15" data-name="Rectangle 15" d="M2.22,1.727a.494.494,0,0,0-.493.493V7.586a.494.494,0,0,0,.493.493H7.586a.494.494,0,0,0,.493-.493V2.22a.494.494,0,0,0-.493-.493H2.22M2.22,0H7.586a2.22,2.22,0,0,1,2.22,2.22V7.586a2.22,2.22,0,0,1-2.22,2.22H2.22A2.22,2.22,0,0,1,0,7.586V2.22A2.22,2.22,0,0,1,2.22,0Z" transform="translate(8203 2822.854)" fill="#f38080" />
                            <path id="Rectangle_16" data-name="Rectangle 16" d="M4.9,1.727A3.176,3.176,0,1,0,8.079,4.9,3.18,3.18,0,0,0,4.9,1.727M4.9,0A4.9,4.9,0,1,1,0,4.9,4.9,4.9,0,0,1,4.9,0Z" transform="translate(8213.854 2822.854)" fill="#f38080" />
                            <path id="Rectangle_14" data-name="Rectangle 14" d="M2.22,1.727a.494.494,0,0,0-.493.493V7.586a.494.494,0,0,0,.493.493H7.586a.494.494,0,0,0,.493-.493V2.22a.494.494,0,0,0-.493-.493H2.22M2.22,0H7.586a2.22,2.22,0,0,1,2.22,2.22V7.586a2.22,2.22,0,0,1-2.22,2.22H2.22A2.22,2.22,0,0,1,0,7.586V2.22A2.22,2.22,0,0,1,2.22,0Z" transform="translate(8213.854 2812)" fill="#f38080" />
                        </g>
                    </svg>
                </TooltipComponent>
            );
        }
        return (
            <TooltipComponent title={type} placement="bottom" arrow>
                <svg xmlns="http://www.w3.org/2000/svg" width="17.751" height="16.711" viewBox="0 0 17.751 16.711" className={classes.crumbIcon}>
                    <path id="table" d="M0,15V31.711H17.751V15Zm16.711,1.04v2.115H1.04V16.04ZM9.4,22.315h3.12V24.4H9.4ZM8.355,24.4H5.235v-2.08h3.12Zm1.04-3.12V19.2h3.12v2.08Zm-1.04,0H5.235V19.2h3.12Zm-4.16,0H1.04V19.2H4.2Zm0,1.04V24.4H1.04v-2.08Zm0,3.12v2.08H1.04v-2.08Zm1.04,0h3.12v2.08H5.235Zm3.12,3.12v2.115H5.235V28.556Zm1.04,0h3.12v2.115H9.4Zm0-1.04v-2.08h3.12v2.08Zm4.16-2.08h3.155v2.08H13.556Zm0-1.04v-2.08h3.155V24.4Zm0-3.12V19.2h3.155v2.08ZM1.04,28.556H4.2v2.115H1.04Zm12.516,2.115V28.556h3.155v2.115Z" transform="translate(0 -15)" fill="#FFFFFF" />
                </svg>
            </TooltipComponent>
        );

    };

    const renderStatusIcon = (status) => {
        if (status.toLowerCase() === "verified") {
            return Verified;
        } else if (status.toLowerCase() === "work in progress") {
            return InProgess;
        } else if (status.toLowerCase() === "issues") {
            return Issues;
        }
        return Deprecated;
    };

    const middleEllipse = (content) => {
        const ellipsedContent = `${content.substring(0, 30)} ... ${content.substring(content.length - 30, content.length)}`;
        return ellipsedContent;
    };

    const sensitiveContent = (isdataset, sensitive_datasets) => {
        if (!isdataset) {
            return sensitive_datasets > 1 ? " datasets" : " dataset";
        }
        return sensitive_datasets > 1 ? " attributes" : " attribute";
    };

    const onThresholdChange = (name, value) => {
        if (value.name === 'quadrant') {
            dq_score_threshold[value.name] = value.value;
            dq_score_threshold.scores = appConstants.quadrantScoreList[`${value.value}`];
        } else {
            dq_score_threshold.scores[value.index][value.name] = value.value;
        }
        setHasQuadrantError(getQuadrantError(dq_score_threshold.scores || [], dq_score_threshold.scores[value.index], value.index) !== null || getQuadrantError(dq_score_threshold.scores || [], null, null) !== null);
        onChange('dq_score_threshold', dq_score_threshold);
    };
    return (
        <Fragment>
            <Grid container className={classes.marginBottom5}>
                {
                    breadcrumb.map((crumb, index) =>
                        <Box key={`crumb_${index}`} className={classes.flexCol}>
                            {getCrumbIcon(crumb.type)}
                            <Typography variant="body2"
                                component="span"
                                className={classNames(classes.link, classes.breadcrumbnew)}
                                onClick={crumb.type === 'dataset' && dataSetLink ? () => navigateOverview() : () => navigate(crumb.path, crumb.connector ? crumb.connector : '')}>
                                <Box style={{ cursor: crumb.path !== '' || (crumb.type === 'dataset' && dataSetLink) ? 'pointer' : 'default' }}>
                                    {crumb.name}
                                    {index !== breadcrumb.length - 1 && ' / '}
                                </Box>
                            </Typography>
                        </Box>
                    )
                }
            </Grid>
            <Grid container justify="space-between">
                <Grid item xs={7} className={classes.datasourceTitle}>
                    <Grid className={datasourceIcon ? classes.sourceIconContainer : classes.defaultIconContainer}>
                        <img src={datasourceIcon ? require(`../../assets/images/sourceIcons/${datasourceIcon.iconName}.png`).default : Logo} alt="logo" onClick={() => setOpenIconSelector(true)} className={classNames(classes.datasourceIcon, classes.datasourceIconSelectImg)} />
                    </Grid>
                    <TextBox
                        name="name"
                        placeholder="Untitled Datasource"
                        value={tableauItems?.name || (source.name ? source.name : '')}
                        className={classNames(classes.inlinetxt, classes.headerTitleTxt)}
                        onChange={(event) => onChange(event.target.name, event.target.value)}
                        // onBlur={() => (!isDuplicateName ? onSave('name') : null)}
                        onBlur={(event) => onSubmit(event.target.value)}
                        disabled={((!isEditable && !(user.role && user.role.properties_permission && user.role.properties_permission.is_edit)) || !connectionStatus)}
                        /*
                         *validators={['isDuplicateCheck']}
                         *errorMessages={['Name is Duplicate']}
                         */
                        error={((isEditable || (user.role && user.role.properties_permission && user.role.properties_permission.is_edit)) && error && !loading && (source.name === "" || error))}
                        helperText={getHelperText()}
                        style={{ width: `${(source?.name?.length * 13) + 7}px`, maxWidth: 900, minWidth: 250 }}
                    />
                    {
                        connector_type && connector_type !== "Tableau" &&
                        <TooltipComponent title={"DQScore"} arrow>
                            <span onClick={() => redirectDataset(type)}>
                                <Circular
                                    showPercentage={Boolean(score.profile_score && score.profile_score !== 0)}
                                    showValue
                                    value={score.profile_score}
                                    size={40}
                                    dqScoreThreshold={dq_score_threshold}
                                />
                            </span>
                        </TooltipComponent>
                    }
                    {
                        (score.impact_score && score.impact_score !== 0 && config.curate) ?
                            <Grid className={classNames(classes.impactContainer, classes.datasourceImpactscore)}>
                                <ArrowDropUpIcon className={classes.impactIcon} />
                                <TooltipComponent title={"Impact Score"} arrow>
                                    <Typography className={classes.impactTxt} component="span">
                                        {`${parseFloat(score.impact_score).toFixed(2)}%`}
                                    </Typography>
                                </TooltipComponent>
                            </Grid> : null
                    }
                    {
                        isSensitive &&
                        <Grid container className={classes.paddingLeft10}>
                            <TooltipComponent title={'Contains Sensitive Information'} arrow>
                                <Grid className={classes.sensitiveIc}>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 24" width="18px" fill="#ffffff">
                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                        <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z" />
                                    </svg>
                                    {sensitive_datasets && sensitive_datasets}
                                    {' '}
                                    sensitive
                                    {sensitiveContent(datasetId, sensitive_datasets)}
                                </Grid>
                            </TooltipComponent>
                        </Grid>
                    }
                    {
                        userConfig.include_organization_domain_score && source.include_organization_domain_score &&
                        <Grid container className={classes.paddingLeft10}>
                            <TooltipComponent title={`DQScore Included`} arrow>
                                <Grid className={classes.dqscoreIc}>
                                    {/* {sensitive_datasets && sensitive_datasets}
                                    {' '} */}
                                    DQScore Included
                                </Grid>
                            </TooltipComponent>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={3}>
                    {
                        connector_type !== "Tableau" &&
                        <Grid alignItems="center" className={`${classes.pathDisplay} ${classes.widthCont2}`}>
                            {
                                getIcon(source.type ? source.type : connector_type) &&
                                <img src={getIcon(source.type ? source.type : connector_type)} alt={"file type"} height={20} />
                            }
                            <TooltipComponent title={getPath()} arrow>
                                <Typography variant="subtitle1" component="span" className={`${classes.paddingLeft10} ${classes.highlight_source2}`}>
                                    {getPath()}
                                </Typography>
                            </TooltipComponent>
                        </Grid>
                    }
                    {
                        connector_type === "Tableau" &&
                        <Grid className={classes.pathGrid}>
                            <img src={tableauico} alt="" width={20} />
                            <TooltipComponent title={
                                `Tableau /
                                    ${tableau_detail.site_name ? tableau_detail.site_name : 'default'}
                                    ${workbookId && tableau_workbook_detail && tableau_workbook_detail.project && tableau_workbook_detail.project.length > 0 ?
                                    ` / ${tableau_workbook_detail.project}` : ''
                                }
                                ${workbookId && tableau_workbook_detail && tableau_workbook_detail.name && tableau_workbook_detail.name.length > 0 ?
                                    ` / ${tableau_workbook_detail.name}` : ''
                                }

                                    ${tableauItems && tableauItems.view_name ?
                                    ` / ${tableauItems.view_name}` : ''
                                }`
                            }
                                arrow>
                                <Typography variant="subtitle2" component="div">
                                    Tableau /
                                    {tableau_detail.site_name ? tableau_detail.site_name : 'default'}
                                    {
                                        workbookId && tableau_workbook_detail && tableau_workbook_detail.project && tableau_workbook_detail.project.length > 0 &&
                                        ` / ${tableau_workbook_detail.project.length > 70 ? middleEllipse(tableau_workbook_detail.project) : tableau_workbook_detail.project}`
                                    }
                                    {
                                        workbookId && tableau_workbook_detail && tableau_workbook_detail.name && tableau_workbook_detail.name.length > 0 &&
                                        ` / ${tableau_workbook_detail.name}`
                                    }
                                    {
                                        tableauItems && tableauItems.view_name &&
                                        ` / ${tableauItems.view_name}`
                                    }
                                </Typography>
                            </TooltipComponent>
                        </Grid>
                    }
                </Grid>
                <Grid item xs={2} className={classes.textRight}>
                    <Fragment>
                        {
                            type === "dataset" &&
                            <Fragment>
                                {
                                    (isEditable || (user.role && user.role.properties_permission && user.role.properties_permission.is_edit)) && connectionStatus && source.name !== "" &&
                                    <TooltipComponent title={"Schedule Profile"} arrow>
                                        <IconButton className={classNames(classes.padding8, classes.marginLeft5)} onClick={(event) => openScheduler(event)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22">
                                                <circle fill={theme.palette.primary.main} cx="1" cy="1" r="1" transform="translate(12 11.999)" />
                                                <path fill={theme.palette.primary.main} d="M276,272.78l-4.718,4.708a.86.86,0,0,0-.215.359l-1.027,3.383a.859.859,0,0,0,1.052,1.078l3.469-.961a.86.86,0,0,0,.378-.22l4.708-4.7A2.578,2.578,0,0,0,276,272.78Zm-2.117,6.97-1.745.483.511-1.681,3.183-3.176,1.216,1.216Zm4.548-4.539-.167.166-1.215-1.215.166-.166a.859.859,0,0,1,1.216,1.215Z" transform="translate(-258.398 -260.338)" />
                                                <ellipse fill={theme.palette.primary.main} cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(8 15.999)" />
                                                <circle fill={theme.palette.primary.main} cx="1" cy="1" r="1" transform="translate(8 11.999)" />
                                                <circle fill={theme.palette.primary.main} cx="1" cy="1" r="1" transform="translate(8 7.999)" />
                                                <circle fill={theme.palette.primary.main} cx="1" cy="1" r="1" transform="translate(4 11.999)" />
                                                <ellipse fill={theme.palette.primary.main} cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(4 15.999)" />
                                                <circle fill={theme.palette.primary.main} cx="1" cy="1" r="1" transform="translate(4 7.999)" />
                                                <ellipse fill={theme.palette.primary.main} cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(16 7.999)" />
                                                <path fill={theme.palette.primary.main} d="M18.562,1.719H17.445V.859a.859.859,0,0,0-1.719,0v.859h-3.91V.859a.859.859,0,1,0-1.719,0v.859H6.23V.859a.859.859,0,1,0-1.719,0v.859H3.437A3.441,3.441,0,0,0,0,5.156V18.562A3.441,3.441,0,0,0,3.437,22H8.68a.859.859,0,1,0,0-1.719H3.437a1.721,1.721,0,0,1-1.719-1.719V5.156A1.721,1.721,0,0,1,3.437,3.437H4.512V4.3a.859.859,0,1,0,1.719,0V3.437H10.1V4.3a.859.859,0,1,0,1.719,0V3.437h3.91V4.3a.859.859,0,0,0,1.719,0V3.437h1.117a1.721,1.721,0,0,1,1.719,1.719V9.023a.859.859,0,1,0,1.719,0V5.156A3.441,3.441,0,0,0,18.562,1.719Z" />
                                                <circle fill={theme.palette.primary.main} cx="1" cy="1" r="1" transform="translate(12 7.999)" />
                                            </svg>
                                        </IconButton>
                                    </TooltipComponent>
                                }
                                {
                                    connector_type !== "Tableau" && (isEditable || (user.role && user.role.properties_permission && user.role.properties_permission.is_edit)) && connectionStatus && source.name !== "" &&
                                    <TooltipComponent title={"Run Now"} arrow>
                                        <IconButton onClick={() => runDatasetProfile()} className={classNames(classes.dataSetUparrow, classes.padding8, classes.marginLeft5)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 19.003 22.001">
                                                <g transform="translate(-34.883 0)">
                                                    <g transform="translate(34.883 0)">
                                                        <path fill={theme.palette.primary.main} d="M52.457,8.461,39.463.452a3,3,0,0,0-4.58,2.559V18.957a3.036,3.036,0,0,0,3,3.044h.013a3.015,3.015,0,0,0,1.558-.469.858.858,0,1,0-.894-1.465,1.316,1.316,0,0,1-.668.218A1.333,1.333,0,0,1,36.6,18.957V3.01a1.289,1.289,0,0,1,1.964-1.1l12.994,8.01a1.289,1.289,0,0,1-.006,2.2l-9.395,5.752a.858.858,0,1,0,.9,1.463l9.394-5.752a3,3,0,0,0,.011-5.123Z" transform="translate(-34.883 0)" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </IconButton>
                                    </TooltipComponent>
                                }
                            </Fragment>
                        }
                        {
                            type !== "dataset" && selectedDataset && selectedDataset.length > 0 && (isEditable || (user.role && user.role.properties_permission && user.role.properties_permission.is_edit)) && connectionStatus &&
                            <TooltipComponent title={connector_type !== "Tableau" ? "Schedule Profile" : "Schedule"} arrow>
                                <IconButton
                                    onClick={(event) => datasetopenSchedule(event)}
                                    className={classes.iconButtonSmall}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        id="calendar_4_"
                                        data-name="calendar (4)"
                                        width="18.174"
                                        height="18.174"
                                        viewBox="0 0 18.174 18.174"
                                    >
                                        <g id="Group_1602" data-name="Group 1602">
                                            <g id="Group_1601" data-name="Group 1601">
                                                <ellipse
                                                    id="Ellipse_946"
                                                    data-name="Ellipse 946"
                                                    cx="1"
                                                    cy="0.5"
                                                    rx="1"
                                                    ry="0.5"
                                                    transform="translate(13.087 7.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <path
                                                    id="Path_890"
                                                    data-name="Path 890"
                                                    d="M15.335,1.42h-.923V.71a.71.71,0,0,0-1.42,0v.71H9.762V.71a.71.71,0,1,0-1.42,0v.71H5.147V.71a.71.71,0,0,0-1.42,0v.71H2.84A2.843,2.843,0,0,0,0,4.26V15.335a2.843,2.843,0,0,0,2.84,2.84H8.271a.71.71,0,1,0,0-1.42H2.84a1.421,1.421,0,0,1-1.42-1.42V4.26A1.421,1.421,0,0,1,2.84,2.84h.887v.71a.71.71,0,1,0,1.42,0V2.84H8.342v.71a.71.71,0,1,0,1.42,0V2.84h3.23v.71a.71.71,0,0,0,1.42,0V2.84h.923a1.421,1.421,0,0,1,1.42,1.42V8.306a.71.71,0,0,0,1.42,0V4.26A2.843,2.843,0,0,0,15.335,1.42Z"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <path
                                                    id="Path_891"
                                                    data-name="Path 891"
                                                    d="M274.3,270a4.3,4.3,0,1,0,4.3,4.3A4.3,4.3,0,0,0,274.3,270Zm0,7.17a2.875,2.875,0,1,1,2.875-2.875A2.878,2.878,0,0,1,274.3,277.17Z"
                                                    transform="translate(-260.416 -260.416)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <path
                                                    id="Path_892"
                                                    data-name="Path 892"
                                                    d="M372.739,331.455h-.319v-.745a.71.71,0,1,0-1.42,0v1.455a.71.71,0,0,0,.71.71h1.029a.71.71,0,0,0,0-1.42Z"
                                                    transform="translate(-357.831 -318.286)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <circle
                                                    id="Ellipse_947"
                                                    data-name="Ellipse 947"
                                                    cx="0.5"
                                                    cy="0.5"
                                                    r="0.5"
                                                    transform="translate(10.087 7.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <circle
                                                    id="Ellipse_948"
                                                    data-name="Ellipse 948"
                                                    cx="0.5"
                                                    cy="0.5"
                                                    r="0.5"
                                                    transform="translate(7.087 10.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <ellipse
                                                    id="Ellipse_949"
                                                    data-name="Ellipse 949"
                                                    cx="1"
                                                    cy="0.5"
                                                    rx="1"
                                                    ry="0.5"
                                                    transform="translate(3.087 7.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <ellipse
                                                    id="Ellipse_950"
                                                    data-name="Ellipse 950"
                                                    cx="1"
                                                    cy="0.5"
                                                    rx="1"
                                                    ry="0.5"
                                                    transform="translate(3.087 10.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <circle
                                                    id="Ellipse_951"
                                                    data-name="Ellipse 951"
                                                    cx="1"
                                                    cy="1"
                                                    r="1"
                                                    transform="translate(3.087 13.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <ellipse
                                                    id="Ellipse_952"
                                                    data-name="Ellipse 952"
                                                    cx="0.5"
                                                    cy="1"
                                                    rx="0.5"
                                                    ry="1"
                                                    transform="translate(7.087 13.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <circle
                                                    id="Ellipse_953"
                                                    data-name="Ellipse 953"
                                                    cx="0.5"
                                                    cy="0.5"
                                                    r="0.5"
                                                    transform="translate(7.087 7.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </IconButton>
                            </TooltipComponent>
                        }
                        {
                            type !== "dataset" && (isEditable || (user.role && user.role.properties_permission && user.role.properties_permission.is_delete)) && connectionStatus &&
                            <TooltipComponent title={"DQScore Threshold"} arrow>
                                <IconButton className={classes.padding8} onClick={(event) => setOpenDqScoreThreshold(event.currentTarget)}>
                                    <SettingsIcon style={{ color: theme.palette.primary.main }} />
                                </IconButton>
                            </TooltipComponent>
                        }
                        {
                            openDqScoreThreshold &&
                            <Popover
                                id="dq-score-threshold"
                                open={Boolean(openDqScoreThreshold)}
                                anchorEl={openDqScoreThreshold}
                                onClose={() => { setOpenDqScoreThreshold(null); }}
                                anchorOrigin={
                                    {
                                        vertical: 'bottom',
                                        horizontal: 'left'
                                    }
                                }
                            >
                                <Grid container style={{ padding: 24 }}>
                                    <Grid item xs={3} style={{ padding: 12 }}>
                                        <TextBox
                                            label="No. of Quadrant"
                                            value={dq_score_threshold.quadrant}
                                            name="quadrant"
                                            fullWidth
                                            select
                                            onChange={(event) => onThresholdChange('dq_score_threshold', { name: event.target.name, value: event.target.value })}
                                            SelectProps={
                                                {
                                                    value: dq_score_threshold.quadrant,
                                                    onChange: (event) => onThresholdChange('dq_score_threshold', { name: event.target.name, value: event.target.value }),
                                                    MenuProps: {
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "center"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "center"
                                                        },
                                                        getContentAnchorEl: null
                                                    }
                                                }
                                            }
                                        >
                                            {
                                                appConstants?.quadrantList.map((quadrant, index) =>
                                                    <MenuItem key={`quadrant_${index}`} value={quadrant.value}>
                                                        {quadrant.name}
                                                    </MenuItem>
                                                )
                                            }
                                        </TextBox>
                                    </Grid>
                                    {
                                        dq_score_threshold.scores.map(((score, index) => (
                                            <Grid key={index} item xs={3} style={{ padding: 12 }}>
                                                <Grid container className={classes.scoringValuePicker}>
                                                    <Grid item className={classes.rangeFields}>
                                                        <NumberInput
                                                            id="length-min-ntb"
                                                            name={'min'}
                                                            value={score.min}
                                                            fullWidth
                                                            variant="outlined"
                                                            size="small"
                                                            integerOnly
                                                            onChange={(event) => onThresholdChange('dq_score_threshold', { index, name: event.target.name, value: event.target.value ? Number(event.target.value) : event.target.value })}
                                                        />
                                                        {' '}
                                                        <span className="rangeHypen">
                                                            -
                                                        </span>
                                                        <NumberInput
                                                            id="length-max-ntb"
                                                            name={'max'}
                                                            value={score.max}
                                                            fullWidth
                                                            variant="outlined"
                                                            size="small"
                                                            integerOnly
                                                            onChange={(event) => onThresholdChange('dq_score_threshold', { index, name: event.target.name, value: event.target.value ? Number(event.target.value) : event.target.value })}
                                                        />
                                                        <span className={classes.colorPicker}>
                                                            <ColorDropIcon
                                                                dropColor={score.color || palette.chartColors.trending}
                                                            />
                                                            <input
                                                                className="typeColor"
                                                                name="color"
                                                                type="color"
                                                                value={score.color}
                                                                onChange={(event) => onThresholdChange('dq_score_threshold', { index, name: event.target.name, value: event.target.value })}
                                                            />
                                                        </span>
                                                    </Grid>
                                                </Grid>
                                                {
                                                    hasQuadrantError &&
                                                    <Typography style={{ padding: 12, color: '#F44336' }}>
                                                        {getQuadrantError(dq_score_threshold.scores || [], score, index)}
                                                    </Typography>
                                                }
                                            </Grid>
                                        )))
                                    }

                                    {
                                        hasQuadrantError &&
                                        <Typography style={{ paddingTop: 24, color: '#F44336' }}>
                                            {getQuadrantError(dq_score_threshold.scores || [], null, null)}
                                        </Typography>
                                    }
                                    <Grid container spacing={2}>
                                        <Grid item>
                                            <Button
                                                disabled={hasQuadrantError}
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                className={classNames(classes.actionButtons, classes.submitBtn)}
                                                onClick={
                                                    () => {
                                                        onSave('dq_score_threshold', dq_score_threshold);
                                                        setOpenDqScoreThreshold(null);
                                                    }
                                                }
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                disabled={hasQuadrantError}
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                className={classNames(classes.actionButtons, classes.submitBtn)}
                                                onClick={
                                                    () => {
                                                        onChange('dq_score_threshold', null);
                                                        setOpenDqScoreThreshold(null);
                                                    }
                                                }
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Popover>
                        }
                        {
                            config.import_export_metadata && connector_type !== "Tableau" && (isEditable || (user.role && user.role.properties_permission && user.role.properties_permission.is_edit)) && connectionStatus &&
                            <TooltipComponent title="Export Metadata Properties" arrow>
                                <IconButton className={classes.padding8} onClick={() => exportProperties(type)}>
                                    {
                                        isExportLoading ?
                                            <Loader size={'extraSmall'} type={'button'} classList={classes.relative} /> :
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24.342 24.106" fill={theme.palette.primary.main}>
                                                <g id="upload_button" transform="translate(-1568.963 -266.973)">
                                                    <g id="Group_2" data-name="Group 2">
                                                        <path id="Path_2" data-name="Path 2" d="M1593.3,291.079h-24.342V276.71h2v12.369H1591.3V276.71h2Z" />
                                                        <path id="Path_3" data-name="Path 3" d="M1586.4,272.323l-1.41,1.42-3.03-3v10.78h-2v-10.7l-2.87,2.84-1.41-1.42,5.32-5.27Z" />
                                                    </g>
                                                </g>
                                            </svg>
                                    }
                                </IconButton>
                            </TooltipComponent>
                        }
                        {
                            type === "dataset" && config.export_metrics &&
                            <TooltipComponent title="Export Metrics" arrow>
                                <IconButton className={classes.padding8} onClick={() => exportRulesProperties()}>
                                    {
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24.782" height="24.383" viewBox="0 0 24.782 24.383">
                                            <g id="Group_101" data-name="Group 101" transform="translate(-1825.113 -2342.231)">
                                                <g id="Group_100" data-name="Group 100" transform="translate(216.042 103.786)">
                                                    <path id="Subtraction_7" data-name="Subtraction 7" d="M11.917,22.461H8.872a.469.469,0,0,1-.468-.468V.468A.469.469,0,0,1,8.872,0h4.679a.469.469,0,0,1,.468.468V11.515a7.618,7.618,0,0,0-2.1,10.944Zm-6.77,0H.468A.468.468,0,0,1,0,21.993V10.763a.469.469,0,0,1,.468-.468H5.147a.469.469,0,0,1,.468.468v11.23A.468.468,0,0,1,5.147,22.461ZM22.442,11.732h0a7.57,7.57,0,0,0-4.373-1.379,7.7,7.7,0,0,0-1.241.1v-5.3a.469.469,0,0,1,.468-.468h4.679a.469.469,0,0,1,.468.468v6.584Z" transform="translate(1609.071 2238.445)" fill={theme.palette.primary.main} />
                                                    <g id="Group_92" data-name="Group 92" transform="translate(1621.108 2250.082)">
                                                        <path id="Path_14" data-name="Path 14" d="M6.373.927A5.548,5.548,0,0,0,1.055,6.373a5.512,5.512,0,0,0,5.317,5.406,5.559,5.559,0,0,0,5.37-5.406A5.6,5.6,0,0,0,6.373.927m0-.927A6.373,6.373,0,1,1,0,6.373,6.373,6.373,0,0,1,6.373,0Z" transform="translate(0 0)" fill={theme.palette.primary.main} />
                                                    </g>
                                                </g>
                                                <g id="left-arrow" transform="translate(1852.697 2362.948) rotate(180)">
                                                    <path id="Path_41" data-name="Path 41" d="M11.647,2.507a.349.349,0,0,0-.35-.35H8.4L9.629.927a.352.352,0,0,0,0-.5L9.3.1a.352.352,0,0,0-.5,0L6.415,2.49a.348.348,0,0,0,0,.494L8.8,5.373a.352.352,0,0,0,.5,0l.33-.33a.352.352,0,0,0,0-.5L8.4,3.322h2.9a.35.35,0,0,0,.35-.35Z" transform="translate(0 0)" fill={theme.palette.primary.main} />
                                                </g>
                                            </g>
                                        </svg>
                                    }
                                </IconButton>
                            </TooltipComponent>
                        }
                    </Fragment>
                    {
                        (isEditable || (user.role && user.role.properties_permission && user.role.properties_permission.is_delete)) && connectionStatus &&
                        <TooltipComponent title={(breadcrumb.length === 3) ? "Delete Dataset" : "Delete Datasource"} arrow>
                            <IconButton className={classes.padding8} onClick={() => deleteData()}>
                                <svg version="1.1" id="Layer_1" width="22" height="22" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
                                    <g id="Delete">
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                    </g>
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                    }
                </Grid>
            </Grid>
            <Grid container alignItems="center">
                <Grid item className={classes.datasourceDetailIcons}>
                    <Typography className={classes.datasourceDetailText} variant="body2">
                        Steward
                    </Typography>
                    <Permission
                        users={users}
                        updateAuditLog={updateAuditLog}
                        createSourceShare={createSourceShare}
                        governance={governance}
                        sourceId={sourceId}
                        inviteSourceUser={inviteSourceUser}
                        getAuditLogs={getAuditLogs}
                        user={user}
                        type={type}
                        steward
                        loading={loading}
                        isEditable={(isEditable || (user.role && user.role.properties_permission && user.role.properties_permission.is_edit)) && connectionStatus && source.name !== ""}
                        deleteShare={deleteShare}
                        datasetId={datasetId}
                        updateUser={props.updateUser}
                        updateGovernance={updateGovernance}
                        isGovernanceUpdate={isGovernanceUpdate}
                        updateGovernanceUpdate={updateGovernanceUpdate}
                        dispatch={dispatch}
                        userDetail={userDetail}
                    />
                </Grid>
                {
                    config.collaboration &&
                    <Grid className={classes.flex}>
                        <TooltipComponent title={"The overall rating of the datasource/dataset"} arrow>
                            <Grid container alignItems="center">
                                <Rating
                                    name="read-only"
                                    value={rating}
                                    size="large"
                                    readOnly
                                    max={1} />
                                <Typography className={classNames(classes.datasourceDetailText, classes.alias, classes.ratingCount)} variant="body2">
                                    {Number.isInteger(rating) ? rating : rating.toFixed(1)}
                                </Typography>
                            </Grid>
                        </TooltipComponent>
                    </Grid>
                }
                <TooltipComponent title={"Number of times the datasource/dataset has been viewed"} arrow>
                    <Grid item className={classes.datasourceDetailIcons}>
                        <img className={classNames(classes.datasourceIcon, classes.alias)} src={ViewIcon} alt="view" style={{ width: "22px", height: "21px" }} />
                        <Typography className={classNames(classes.datasourceDetailText, classes.alias)} variant="body2">
                            {`${views} Views`}
                        </Typography>
                    </Grid>
                </TooltipComponent>
                {
                    connector_type !== "Tableau" && config.action_items &&
                    <TooltipComponent title={"Tickets for the issues found in the dataset/datasource"} arrow>
                        <Grid item className={classNames(classes.datasourceDetailIcons, classes.cursor, classes.bgNone)} onClick={() => showPanel('actionitem')}>
                            <span className={classes.relative}>
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={classes.datasourceIcon} width="23" height="20" viewBox="0 0 32 32" >
                                    <g id="Actions_1_">
                                        <path fill="#AFB2B3"
                                            d="M23.1,16.7v-1.6v-0.6c-0.5,0.3-1,0.5-1.6,0.5c-0.1,0-0.2,0-0.3,0v0.1v1.6c-2.9,0.4-5.2,2.9-5.2,5.9c0,0.7,0.1,1.4,0.3,2H5.9v-19h11.7l1.3,1.9c0.5-0.6,1-1.1,1.7-1.3l-2-2.8H4.1v23.5h13.8c1.1,1.1,2.6,1.8,4.2,1.8c3.3,0,6-2.7,6-6C28.1,19.7,25.9,17.2,23.1,16.7z M22.1,26.6c-2.2,0-4-1.8-4-4s1.8-4,4-4c2.2,0,4,1.8,4,4S24.3,26.6,22.1,26.6z" />
                                        <g>
                                            <rect x="7.6" y="10.2" fill="#AFB2B3" width="5.5" height="2.2" />
                                            <rect x="15.3" y="10.2" fill="#AFB2B3" width="2.5" height="2.2" />
                                            <rect x="7.6" y="14" fill="#AFB2B3" width="5.5" height="2.2" />
                                            <rect x="7.6" y="17.8" fill="#AFB2B3" width="5.5" height="2.2" />
                                            <rect x="15.3" y="14" fill="#AFB2B3" width="2.5" height="2.2" />
                                            <polyline fill="none" stroke="#AFB2B3" strokeWidth="2" strokeMiterlimit="10" points="20.1,21.6 21.4,23.6 25.1,21.6 25.1,21.6 " />
                                        </g>
                                        <circle fill="#54DF9A" cx="21.5" cy="10.5" r="3" />
                                    </g>
                                </svg>
                            </span>

                            <Typography className={classes.datasourceDetailText} variant="body2">
                                {`${actionItemCount} Action Items`}
                            </Typography>
                        </Grid>
                    </TooltipComponent>
                }
                {
                    connector_type !== "Tableau" && config.conversation &&
                    <TooltipComponent title={"Messages that are related to the datasource/dataset"} arrow>
                        <Grid item className={classNames(classes.datasourceDetailIcons, classes.cursor, classes.bgNone)} onClick={() => showPanel('conversation')}>
                            <span className={classes.relative}>
                                <svg className={classes.datasourceIcon} version="1.1" id="Layer_1" width="23" height="23.421" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
                                    <g id="conversation_1_">
                                        <g>
                                            <path fill="#AFB2B3" d="M13.5,18.4c0.6,0,1-0.4,1-0.9c0-0.6-0.4-1-1-1s-1,0.4-1,1C12.5,18,12.9,18.4,13.5,18.4z" />
                                            <polygon fill="#AFB2B3" points="24.9,8.9 26.8,8.9 26.8,7 24.9,7 7.8,7 7.8,8.9 24,8.9 " />
                                            <path fill="#AFB2B3" d="M17.3,18.4c0.6,0,1-0.4,1-0.9c0-0.6-0.4-1-1-1s-1,0.4-1,1C16.3,18,16.7,18.4,17.3,18.4z" />
                                            <path fill="#AFB2B3" d="M9.7,18.4c0.6,0,1-0.4,1-0.9c0-0.6-0.4-1-1-1s-1,0.4-1,1C8.8,18,9.1,18.4,9.7,18.4z" />
                                            <path fill="#AFB2B3" d="M5.9,10.8H4v1.9v11.4V26l5.7-1.9h11.4H23v-1.9v-9.5v-1.9h-1.9H5.9z M21.1,22.2H9.7l-3.8,1.2V12.7h15.2V22.2z" />
                                            <g>
                                                <rect x="24.9" y="15" fill="#AFB2B3" width="1.9" height="4.3" />
                                                <rect x="24.9" y="10.8" fill="#AFB2B3" width="1.9" height="2.2" />
                                            </g>
                                            <circle cx="26" fill="#54DF9A" cy="12" r="3" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            <Typography className={classes.datasourceDetailText} variant="body2">
                                {`${conversationCount ? conversationCount : 0} Conversations`}
                            </Typography>
                        </Grid>
                    </TooltipComponent>
                }
                <TooltipComponent title={"Provides easy access to frequently used items"} arrow>
                    <Grid item className={classes.datasourceDetailIcons}>
                        <Button className={classNames(classes.bgNone, classes.animateIcons)} onClick={() => changeFavorite()}>
                            {
                                favorite ?
                                    <Lottie options={defaultOptions}
                                        height={32}
                                        width={32}
                                        position="absolute"
                                    />

                                    :
                                    <svg version="1.1"
                                        className={classes.datasourceIcon}
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        width="19"
                                        height="19"
                                        viewBox="0 0 32 32">
                                        <g id="heart_2_" transform="translate(0 -16.896)">
                                            <g id="Group_1546" transform="translate(0 16.896)">
                                                <g id="Path_249">
                                                    <path fill="#AFB2B3"
                                                        d="M25.6,7.3c-2.4-2.5-6.3-2.7-8.9-0.3c-0.1,0.1-0.2,0.2-0.3,0.3L16,7.8l-0.4-0.4C13.2,4.8,9.3,4.6,6.7,7
                                                    c0,0,0,0,0,0C6.6,7.1,6.5,7.2,6.4,7.3c-2.5,2.7-2.5,6.9,0,9.6l9,9.5c0.3,0.3,0.8,0.3,1.2,0c0,0,0,0,0,0l0,0l9-9.5
                                                    C28.1,14.2,28.1,10,25.6,7.3z M24.4,15.8L24.4,15.8L16,24.7l-8.4-8.9c-1.9-2.1-1.9-5.3,0-7.3c1.7-1.9,4.7-2,6.6-0.3c0,0,0,0,0,0
                                                    c0.1,0.1,0.2,0.2,0.3,0.3l1,1.1c0.3,0.3,0.9,0.3,1.2,0l1-1.1c1.7-1.9,4.7-2,6.6-0.3c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3
                                                    C26.4,10.5,26.4,13.7,24.4,15.8L24.4,15.8z" />
                                                    <path fill="#AFB2B3"
                                                        d="M16,26.9C16,26.9,16,26.9,16,26.9c-0.3,0-0.6-0.1-0.8-0.3l-9-9.5c-2.6-2.8-2.6-7.1,0-9.9
                                                    c0.1-0.1,0.2-0.2,0.3-0.3c2.6-2.4,6.8-2.3,9.2,0.3L16,7.4l0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c1.3-1.2,2.9-1.8,4.7-1.7
                                                    c1.7,0.1,3.3,0.8,4.5,2.1l0,0c2.6,2.8,2.6,7.1,0,9.9l-9.1,9.6C16.5,26.8,16.3,26.9,16,26.9z M11,5.5c-1.5,0-3,0.5-4.1,1.6
                                                    C6.8,7.3,6.6,7.4,6.5,7.5c-2.4,2.6-2.4,6.7,0,9.3l9,9.5c0.2,0.2,0.6,0.2,0.8,0l9-9.5c2.4-2.6,2.4-6.7,0-9.3
                                                    c-1.1-1.2-2.6-1.9-4.2-1.9c-1.6-0.1-3.2,0.5-4.3,1.6c-0.1,0.1-0.2,0.2-0.3,0.3L16,8.1l-0.6-0.6C14.2,6.2,12.6,5.5,11,5.5z M16,25
                                                    l-8.6-9.1c-2-2.1-2-5.5,0-7.6c0.9-1,2.1-1.5,3.4-1.6c1.3-0.1,2.5,0.4,3.5,1.3c0.1,0.1,0.2,0.2,0.3,0.3l1,1.1
                                                    c0.2,0.2,0.6,0.2,0.9,0l1-1.1c0.9-1,2.1-1.5,3.4-1.6c1.3-0.1,2.5,0.4,3.5,1.3c0.1,0.1,0.2,0.2,0.3,0.3c1.9,2,2,5.1,0.3,7.3h0
                                                    l-0.4,0.4L16,25z M11,7.2c-0.1,0-0.1,0-0.2,0c-1.2,0-2.3,0.6-3.1,1.4c-1.8,2-1.8,5.1,0,7l8.3,8.7l8.3-8.7c1.8-2,1.8-5.1,0-7
                                                    c-0.1-0.1-0.2-0.2-0.3-0.3c-0.9-0.8-2-1.2-3.2-1.2c-1.2,0-2.3,0.6-3.1,1.4l-1,1.1c-0.4,0.4-1.1,0.4-1.5,0l-1-1.1
                                                    c-0.1-0.1-0.2-0.2-0.3-0.3C13.2,7.6,12.1,7.2,11,7.2z" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                            }
                            <Typography className={classNames(classes.datasourceDetailText, classes.iconWithText)} variant="body2">
                                {favorite ? 'Added to Favorites' : 'Add to Favorites'}
                            </Typography>
                        </Button>
                    </Grid>
                </TooltipComponent>
                <TooltipComponent title={"Provides easy access to certified items"} arrow>
                    <Grid item className={classes.datasourceDetailIcons}>
                        <Button className={classNames(classes.bgNone, classes.animateIcons)} onClick={() => changeCertified()}>
                            <svg id="verified" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <rect id="Rectangle_1768" data-name="Rectangle 1768" width="20" height="20" fill="none" />
                                <path id="Path_2546" data-name="Path 2546" d="M18.667,4.65,9.158,14.167,5.625,10.633,6.8,9.458l2.358,2.358,8.333-8.333Zm-1.842,4.2a6.662,6.662,0,1,1-6.492-5.183A6.6,6.6,0,0,1,13.9,4.708l1.2-1.2A8.25,8.25,0,0,0,10.333,2a8.355,8.355,0,1,0,7.833,5.508Z" transform="translate(-0.333 -0.333)" fill={isCertified ? "#7EE19A" : "#B5B6B8"} />
                            </svg>

                            <Typography className={classNames(classes.datasourceDetailText, classes.iconWithText)} variant="body2">
                                Certified
                            </Typography>
                        </Button>
                    </Grid>
                </TooltipComponent>
                <TextBox
                    name="status"
                    select
                    disabled={source.is_delete}
                    onChange={(event) => onChange(event.target.name, event.target.value)}
                    value={source.status ? source.status : appConstants.glossaryStatus[1].value}
                    className={classes.glossarySelect}
                    SelectProps={
                        {
                            MenuProps: {
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "center"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "center"
                                },
                                getContentAnchorEl: null
                            }
                            // IconComponent: () => DropDownIcon()
                        }
                    }

                >
                    {
                        appConstants.glossaryStatus.map((status, index) =>
                            <MenuItem key={index} className={classes.menuItem} value={status.value}>
                                <img src={renderStatusIcon(status.value)} alt="" className={classes.marginRight5} />
                                <Typography>
                                    {status.name}
                                </Typography>
                            </MenuItem>
                        )
                    }
                </TextBox>
                {
                    connector_type === "Tableau" &&
                    <Grid item className={classes.datasourceDetailIcons}>
                        <svg id="chat-comment-oval-speech-bubble-with-text-lines" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 22.576 22.576">
                            <g id="_x32__26_">
                                <g id="Group_161282" data-name="Group 161282">
                                    <path id="Path_2725" data-name="Path 2725" d="M14.816,11.994H7.761a.706.706,0,0,0,0,1.411h7.055a.706.706,0,0,0,0-1.411Zm1.411-4.233H6.35a.706.706,0,0,0,0,1.411h9.877a.706.706,0,0,0,0-1.411ZM11.288,0C5.054,0,0,4.422,0,9.877a9.454,9.454,0,0,0,4.233,7.7v5l4.945-3a12.945,12.945,0,0,0,2.11.179c6.235,0,11.288-4.422,11.288-9.877S17.523,0,11.288,0Zm0,18.343A11.417,11.417,0,0,1,8.9,18.084l-3.322,2,.044-3.272A8.18,8.18,0,0,1,1.411,9.877c0-4.675,4.422-8.466,9.877-8.466S21.165,5.2,21.165,9.877,16.743,18.343,11.288,18.343Z" fill="#AFB2B3" />
                                </g>
                            </g>
                        </svg>
                        <Typography className={classNames(classes.datasourceDetailText, classes.alias, classes.paddingLeft10)} variant="body2">
                            {`${commentCount} Comments`}
                        </Typography>
                    </Grid>
                }
            </Grid>
            <Schedule scheduleProps={schedule} onClose={() => setSchedule({ open: false, anchorElement: null, datasetId: 0, datasourceId: 0 })} />
            <IconSelector open={openIconSelector} icon={datasourceIcon} onCancel={() => setOpenIconSelector(false)} onSave={(selectedIcon) => updateIcon(selectedIcon)} />
        </Fragment>
    );
};


DatasourceInfo.propTypes = {
    classes: PropTypes.object,
    source: PropTypes.object,
    tableauItems: PropTypes.object,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    deleteData: PropTypes.func,
    history: PropTypes.object,
    breadcrumb: PropTypes.array,
    selectedDataset: PropTypes.array,
    theme: PropTypes.object,
    type: PropTypes.string,
    favorite: PropTypes.bool,
    changeFavorite: PropTypes.func,
    datasourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    datasetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isEditable: PropTypes.bool,
    users: PropTypes.array,
    updateAuditLog: PropTypes.func,
    governance: PropTypes.array,
    sourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    inviteSourceUser: PropTypes.func,
    createSourceShare: PropTypes.func,
    getAuditLogs: PropTypes.func,
    user: PropTypes.object,
    loading: PropTypes.bool,
    deleteShare: PropTypes.func,
    redirectProfile: PropTypes.func,
    updateUser: PropTypes.func,
    isExportLoading: PropTypes.bool,
    profileTab: PropTypes.number,
    exportProperties: PropTypes.func,
    exportRulesProperties: PropTypes.func,
    setDuplicateError: PropTypes.func,
    updateGovernance: PropTypes.func,
    updateGovernanceUpdate: PropTypes.func,
    isGovernanceUpdate: PropTypes.bool,
    sourceName: PropTypes.string,
    connectionStatus: PropTypes.bool,
    dataSetLink: PropTypes.bool,
    navigateOverview: PropTypes.func,
    onChangeTabEvent: PropTypes.func,
    connector_type: PropTypes.string,
    workbookId: PropTypes.number,
    itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    getIcon: PropTypes.func,
    getPath: PropTypes.func,
    changeCertified: PropTypes.func,
    isCertified: PropTypes.bool,
    dq_score_threshold: PropTypes.object
};


export default withStyles((theme) => ({
    ...DatasourceStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DatasourceInfo);