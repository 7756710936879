const QueryFilterModalStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    queryFilterDialog: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '900px !important',
            minWidth: 900
        }
    },
    marginBottom5: {
        marginBottom: 5
    },
    switchContainer: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        display: 'flex',
        '& .MuiSwitch-root': {
            marginLeft: 10,
            marginTop: -2
        }
    },
    previewContainer: {
        position: 'relative',
        display: 'flex',
        minHeight: 100,
        flexDirection: 'nowrap',
        '& .MuiTableCell-head': {
            // color: theme.palette.grey.dark
        },
        '& .MuiTableCell-head .MuiTypography-body1': {
            fontWeight: 600
        },
        '& .MuiTableCell-root': {
            padding: '8px 16px'
        },
        '& .MuiTableCell-body > p': {
            color: theme.palette.grey.darkTxt
        }
        /*
         * '& .MuiTableCell-stickyHeader': {
         *     backgroundColor: '#ffffff'
         * }
         */
    },
    noDataMessage: {
        margin: 0
    },
    previewTable: {
        border: `1px solid ${theme.palette.grey.extraLight} !important`,
        overflow: 'auto',
        marginTop: 10,
        marginBottom: 10,
        maxHeight: 'calc(100vh - 400px)',
        width: '100%'
    },
    queryBuilder: {
        border: '1px solid #CFE0E7',
        position: 'relative',
        marginTop: 8,
        marginBottom: 5,
        borderRadius: 3,
        backgroundColor: 'rgba(237, 242, 244, 0.6)',
        padding: 10,
        '& .ruleQueryBuilder:first-child > div:first-child': {
            border: 'none',
            backgroundColor: 'transparent',
            margin: 0,
            padding: 0,
            borderRadius: 0,
            paddingBottom: 4
        },
        '& form > div': {
            margin: 0,
            padding: 0
        }
    },
    searchInput: {
        '& input': {
            height: '28px !important'
        },
        '& .MuiInput-underline:before': {
            height: '27px !important'
        }
    },
    actionButton: {
        height: 28,
        marginLeft: 16,
        fontSize: 13,
        padding: "6px 12px !important"
    },
    nopadding: {
        padding: 0
    },
    includeSearchContainer: {
        position: 'relative'
    },
    includeSearchIcon: {
        position: 'absolute',
        top: 1,
        right: 3,
        padding: 8
    },
    includeDeleteIcon: {
        position: 'absolute',
        top: 1,
        right: 3,
        padding: 4
    },
    QueryfilterTitle: {
        fontWeight: 'bold',
        fontSize: '16px'
    }
});

export default QueryFilterModalStyles;