const SemanticModelStyles = (theme) => ({
    bgGrey: {
        '& .MuiInput-root': {
            backgroundColor: theme.palette.grey.exteremeLight,
            '& .MuiInputBase-input': {
                textAlign: "center",
                paddingLeft: '6px',
                paddingRight: '6px'
            }
        }
    },
    hoverBgGrey: {
        '& .MuiInput-root:hover': {
            backgroundColor: theme.palette.grey.exteremeLight
        }
    },
    Rangehyphen: {
        margin: '20px 10px 0px 5px'
    },
    sematicTabAction: {
        minWidth: 110
    }
});

export default SemanticModelStyles;