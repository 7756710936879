import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Grid, Typography, withStyles, TextField } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';
// import Search from '../TextBox/Search.jsx';
import DatasourceListStyles from '../DatasourceList/DatasourceListStyles.jsx';
// import { appConstants } from '../../constants/appConstants';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import { sortTable } from '../../helpers/appHelpers.js';

const ConversationFilter = (props) => {
    const { classes, filters, onChangeFilter, sources } = props;
    // const filterStatus = ["All", "New", "Open", "Resolved"];

    const [selectedValue] = useState({
        datasource: 'All',
        dataset: 'All',
        domain: 'All'
    });

    const [domainList, setDomainList] = useState([]);

    const optionList = useCallback((type) => {
        if (type === "datasource") {
            return sources && sources.datasources.map((source) => {
                return source.name !== "" ? source.name : "Untitled Datasource";
            });
        }
        else if (type === "dataset") {
            return sources && sources.datasets.map((dataset) => {
                return dataset.name !== "" ? dataset.name : "Untitled Dataset";
            });
        }
        else if (type === "domain") {
            return sources && sources.domains && sources.domains.map((domain) => {
                return (domain.name !== "" && domain.name !== null) ? domain.name : "Untitled Dataset";
            });
        }
    }, [sources]);

    const onChangeDropdown = (property, name) => {
        selectedValue[property] = name;
        if (name === "All" || name === null) {
            onChangeFilter(property, "All");
        } else {
            let selectedOption = [];
            if (property === "datasource") {
                selectedOption = sources.datasources.filter((source) => source.name === name);
            } else if (property === "dataset") {
                selectedOption = sources.datasets.filter((dataset) => dataset.name === name);
            } else if (property === "domain") {
                selectedOption = sources.domains.filter((domain) => domain.name === name);
            }
            const selectedOptionId = selectedOption[0]?.id;
            onChangeFilter(property, selectedOptionId);
        }
    };

    useEffect(() => {
        const domainNames = optionList("domain");
        setDomainList(domainNames);
    }, [filters, optionList]);

    return (

        <Grid container alignItems="center" justify="space-between" className={classNames(classes.headerContainer, classes.catHeaderContainer)}>
            <Grid item>
                <Typography variant="h2" component="h2">
                    Conversations
                </Typography>
            </Grid>
            <Grid item>
                <Grid container alignItems="center">
                    {
                        domainList && domainList.length > 0 &&
                        <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                            <AutoCompleteInput
                                name="domain"
                                options={sortTable(optionList("domain"), "asc", "name")}
                                value={selectedValue.domain ? selectedValue.domain : 'All'}
                                renderInput={
                                    (params) =>
                                        <TextField {...params}
                                            label="Domain"
                                            fullWidth
                                        />
                                }
                                onChange={(_, selectedName) => onChangeDropdown("domain", selectedName)}
                                fullWidth
                            />
                        </Grid>
                    }
                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                        <AutoCompleteInput
                            name="datasource"
                            options={sortTable(optionList("datasource"), "asc", "name")}
                            value={selectedValue.datasource ? selectedValue.datasource : 'All'}
                            renderInput={
                                (params) =>
                                    <TextField {...params}
                                        label="Datasource"
                                        fullWidth
                                    />
                            }
                            onChange={(_, selectedName) => onChangeDropdown("datasource", selectedName)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                        <AutoCompleteInput
                            name="dataset"
                            options={sortTable(optionList("dataset"), "asc", "name")}
                            value={selectedValue.dataset ? selectedValue.dataset : 'All'}
                            renderInput={
                                (params) =>
                                    <TextField {...params}
                                        label="Dataset"
                                        fullWidth
                                    />
                            }
                            onChange={(_, selectedName) => onChangeDropdown("dataset", selectedName)}
                            fullWidth
                        />
                    </Grid>
                    {/* <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                        <TextBox
                            label="Filter By"
                            value={filters.filter}
                            name="filter"
                            fullWidth
                            select
                            onChange={(event) => onChangeFilter(event.target.name, event.target.value)}
                            SelectProps={
                                {
                                    value: filters.filter,
                                    onChange: (event) => onChangeFilter(event.target.name, event.target.value),
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    },
                                    IconComponent: () => DropDownIcon()
                                }
                            }
                        >
                            {
                                filterStatus.map((status, index) =>
                                    <MenuItem key={index} value={status}>
                                        {status}
                                    </MenuItem>
                                )
                            }
                        </TextBox>
                    </Grid>
                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                        <TextBox
                            label="Sort By"
                            value={filters.sort}
                            name="sort"
                            fullWidth
                            select
                            onChange={(event) => onChangeFilter(event.target.name, event.target.value)}
                            SelectProps={
                                {
                                    value: filters.sort,
                                    onChange: (event) => onChangeFilter(event.target.name, event.target.value),
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    },
                                    IconComponent: () => DropDownIcon()
                                }
                            }
                        >
                            {
                                appConstants.priority.map((priority, index) =>
                                    <MenuItem key={index} value={priority}>
                                        {priority}
                                    </MenuItem>
                                )
                            }
                        </TextBox>
                    </Grid>
                    <Grid item>
                        <Search
                            value={filters.search}
                            placeholder="Search Here"
                            className={classes.dqSearchBox}
                            onChange={(value) => onChangeFilter('search', value)}
                        />
                    </Grid> */}
                </Grid>
            </Grid>
        </Grid >
    );
};

ConversationFilter.propTypes = {
    classes: PropTypes.object,
    filters: PropTypes.object,
    onChangeFilter: PropTypes.func,
    sources: PropTypes.object
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ConversationFilter);