const DatasourceMetricsStyles = (theme) => ({
    root: {
        flexGrow: '1 auto',
        height: '100%'
    },
    metricContiner: {
        height: '100%',
        position: 'relative'
    },
    metricsTableIconBg: {
        padding: '9px 15px',
        zIndex: 1,
        borderRadius: '4px'
    },
    metricTabIcon1: {
        background: theme.palette.MetricsTiles.tileOne
    },
    metricTabIcon2: {
        background: theme.palette.MetricsTiles.tileTwo
    },
    metricTabIcon3: {
        background: theme.palette.MetricsTiles.tileThree
    },
    metricTabIcon4: {
        background: theme.palette.MetricsTiles.tileFour
    },
    metricsTableText: {
        background: '#FFFFFF',
        boxShadow: '0px 0px 5px #0000000F',
        border: '1px solid #EFEFEF',
        borderRadius: '2px',
        padding: '17px 5px',
        paddingLeft: '58px',
        marginLeft: '-25px',
        width: '200px',
        height: '78px'
    },
    metricChartContiner: {
        padding: '15px 15px 15px 15px',
        backgroundColor: theme.palette.background.paper,
        height: '100%'
    },
    metricsCountContainer: {
        marginBottom: 20
    },
    metricsTableIcon: {
        fill: theme.palette.background.paper
    },
    metricCountText: {
        color: theme.palette.grey.dark
    }
});

export default DatasourceMetricsStyles;