import React, { useCallback, useEffect } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';

const HeadMapChart = (props) => {
    const { classes, chartData, datatypes, theme } = props;

    const getColors = useCallback((count) => {
        if (count === 0) {
            return theme.palette.heatmapColors[0];
        } else if (count >= 1 && count <= 10) {
            return theme.palette.heatmapColors[1];
        } else if (count >= 11 && count <= 20) {
            return theme.palette.heatmapColors[2];
        } else if (count >= 21 && count <= 30) {
            return theme.palette.heatmapColors[3];
        }
        return theme.palette.heatmapColors[4];
    }, [theme.palette.heatmapColors]);

    useEffect(() => {
        if (chartData && Object.keys(chartData).length !== 0) {
            const names = [...new Set(chartData.map((d) => { return d.name; }))];
            const data = [...chartData];
            for (const name of names) {
                const typeList = chartData.filter((data) => data.name === name).map((type) => type.type);
                const filteredTypes = datatypes.filter((datatype) => !typeList.includes(datatype));
                for (const type of filteredTypes) {
                    data.push({
                        name: name,
                        count: 0,
                        type: type
                    });
                }
            }
            const margin = {
                top: 80,
                right: 60,
                bottom: 20,
                left: 20
            },
                height = (names.length * 25);
            const width = 950;

            d3.select(".relationship-heat-chart > svg").remove();
            const svg = d3.select(".relationship-heat-chart")
                .append("svg")
                .attr("width", "100%")
                .style("margin", "0px auto")
                .attr("height", height + margin.top + margin.bottom + margin.left)
                .append("g")
                .attr("width", width)
                .attr("height", height + margin.bottom)
                .attr("transform",
                    "translate(" + (margin.left + 40) + "," + margin.top + ")");

            d3.select(".relationship-heat-chart >.tooltip").remove();
            const div = d3.select(".relationship-heat-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);


            const x = d3.scaleBand().domain(datatypes).rangeRound([0, width + 200]).padding(0.1),
                y = d3.scaleBand().domain(names).rangeRound([height, 0]).padding(0.1);

            svg.append("g")
                .attr("transform", `translate(${margin.top},0)`)
                .attr("color", theme.palette.chartColors.axis)
                .attr('class', 'axis-x')
                .transition()
                .duration(2000)
                .call(d3.axisTop(x))
                .selectAll("text")
                .text((d) => {
                    if (d.length >= 10) {
                        return (d.slice(0, 9) + "...");
                    }
                    return d;
                })
                .attr("y", 0)
                .attr("x", 10)
                .attr("dy", ".35em")
                .attr("transform", "rotate(270)")
                .style("text-anchor", "start");

            // add the y Axis
            svg.append("g")
                .attr("transform", `translate(${margin.top},0)`)
                .attr("color", theme.palette.chartColors.axis)
                .attr('class', 'axis-y')
                .transition()
                .duration(2000)
                .call(d3.axisLeft(y))
                .selectAll("text")
                .text((d) => {
                    if (d.length >= 17) {
                        return (d.slice(0, 15) + "...");
                    }
                    return d;
                });

            svg
                .append('g')
                .attr("transform", `translate(${margin.top},0)`)
                .selectAll()
                .data(data)
                .enter()
                .append("rect")
                .attr("x", (d) => { return x(d.type); })
                .attr("y", (d) => { return y(d.name); })
                .attr("width", x.bandwidth())
                .attr("height", 0)
                .style("fill", (d) => getColors(d.count))
                .on('mouseover',
                    (d, i, nodes) => {
                        d3.select(nodes[i]).style("opacity", 0.4);
                        // Show Tooltip
                        div.transition()
                            .duration(200)
                            .style("opacity", 0.9);
                        let x = 0;
                        let y = 0;
                        div.attr("transform", (_, i, nodes) => {
                            const mouseCoords = d3.mouse(nodes[i].parentNode);
                            let xCo = 0;
                            let yCo = 0;
                            if (mouseCoords[0] + 10 >= width * 0.80) {
                                xCo = mouseCoords[0] - parseFloat(div
                                    .attr("width"));
                                yCo = mouseCoords[1] + 10;
                            } else {
                                xCo = mouseCoords[0] + 10;
                                yCo = mouseCoords[1];
                            }
                            x = isNaN(xCo) ? mouseCoords[0] : xCo;
                            y = isNaN(yCo) ? mouseCoords[1] : yCo;
                        });
                        div.html(`Count : ${d.count}`)
                            .style("left", x + "px")
                            .style("top", y + "px");
                    })
                .on('mouseout', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", 1);
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .transition()
                .duration(500)
                .delay((d, i) => {
                    return i * 100;
                })
                .attr("height", y.bandwidth());
            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text')
            .on('mouseover', (d, i, nodes) => {
                d3.select(nodes[i]).style("opacity", 0.4);
                // Show Tooltip
                div.transition()
                    .duration(200)
                    .style("opacity", 0.9);
                let x = 0;
                let y = 0;
                div.attr("transform", (_, i, nodes) => {
                    const mouseCoords = d3.mouse(nodes[i].parentNode);
                    let xCo = 0;
                    let yCo = 0;
                    if (mouseCoords[0] + 10 >= width * 0.80) {
                        xCo = mouseCoords[0] - parseFloat(div
                            .attr("width"));
                        yCo = mouseCoords[1] + 10;
                    } else {
                        xCo = mouseCoords[0] + 10;
                        yCo = mouseCoords[1];
                    }
                    x = isNaN(xCo) ? mouseCoords[0] : xCo;
                    y = isNaN(yCo) ? mouseCoords[1] : yCo;
                });
                div.html(`${d}`)
                    .style("left", x + "px")
                    .style("top", y + "px");
            }).on('mouseout', (d, i, nodes) => {
                d3.select(nodes[i]).style("opacity", 1);
                div.transition()
                    .duration(500)
                    .style("opacity", 0);
            });
            svg.selectAll('.axis-y .tick-text').text('').append('tspan').text((d) => { return d; });

        }
    }, [chartData, datatypes, getColors, theme.palette.chartColors.axis]);

    return (
        <Grid className={classNames(classes.chartStyles, classes.align, 'relationship-heat-chart')} />
    );
};

HeadMapChart.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.array,
    datatypes: PropTypes.array,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(HeadMapChart);