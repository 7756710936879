import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import { appConstants } from '../../constants/appConstants';

const SensitivityChart = (props) => {
    const { chartData, classes, theme, datasourceName } = props;
    const containerRef = useRef();
    const [chartType, setChartType] = useState('Circular Plot');

    const changeChart = useCallback((value) => {
        setChartType(value);
    }, []);

    const responsivefy = (svg) => {
        // get container + svg aspect ratio
        const container = d3.select(svg.node().parentNode),
            width = parseInt(svg.style('width')),
            height = parseInt(svg.style('height')),
            aspect = width / height;

        // get width of container and resize svg to fit it
        const resize = () => {
            const targetWidth = parseInt(container.style('width'));
            svg.attr('width', targetWidth);
            svg.attr('height', Math.round(targetWidth / aspect));
        };

        /*
         * add viewBox and preserveAspectRatio properties,
         * and call resize so that svg resizes on inital page load
         */
        svg
            .attr('viewBox', '0 0 ' + width + ' ' + height)
            .attr('perserveAspectRatio', 'xMinYMid')
            .call(resize);

        d3.select(window).on('resize.' + container.attr('id'), resize);
    };

    const barChart = useCallback(() => {
        // const tickCount = 10;
        const margin = { top: 40, right: 20, bottom: 50, left: 40 };
        const height = 280;
        let width = 960 - margin.left - margin.right;

        const barOpacity = 0.8;
        const barHighlightOpacity = 1;
        const barWidth = 20;

        const columns = ['level1', 'level2', 'level3'];
        d3.select(".sensitivity-chart > svg").remove();
        const svg = d3.select(".sensitivity-chart").append("svg")
            .attr("width", "100%")
            .style('margin-top', '45px')
            .attr("height", height + margin.top + margin.bottom + 20)
            .append("g")
            // .attr("width", 1574)
            .attr("width", (d, i, p) => {
                width = p[i].parentNode.clientWidth;
                return width;
            })
            .attr("height", height + margin.top + margin.bottom - 10)
            .attr("transform",
                "translate(" + (margin.left + 20) + "," + ((margin.top / 2) - 15) + ")");

        const x = d3.scaleBand()
            .range([0, width])
            .domain(chartData.map((d) => { return d.name; }))
            .padding([0.9]);

        const y = d3.scaleLinear()
            .domain([0, d3.max(chartData, (d) => { return d.total; })])
            .range([height, 0]).nice();

        const z = d3.scaleOrdinal()
            .domain(columns)
            .range(["#ff8a67", "#4cdde8", "#eb6494"]);

        svg.append("g")
            .attr("class", "x-axis")
            .attr("color", theme.palette.chartColors.axis)
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x));

        svg.append("g")
            .attr("class", "y-axis")
            .attr("color", theme.palette.chartColors.axis)
            .call(d3.axisLeft(y));

        svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
        const stackedData = d3.stack()
            .keys(columns)
            .order(d3.stackOrderNone)
            .offset(d3.stackOffsetNone)(chartData);

        d3.select(".sensitivity-chart >.tooltip").remove();
        const div = d3.select(".sensitivity-chart").append("div")
            .attr("class", "tooltip")
            .style("opacity", 0)
            .style("zIndex", 1);

        svg.append("g")
            .selectAll("g")
            .data(stackedData)
            .enter().append("g")
            .attr("fill", (d) => { return z(d.key); })
            .selectAll("rect")
            .data((d) => { return d; })
            .enter().append("rect")
            .attr("x", (d) => { return x(d.data.name); })
            .attr("y", (d) => { return y(d[1]); })
            .attr("height", height)
            .attr("width", barWidth)
            .style("opacity", barOpacity)
            .on('mouseover',
                (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", barHighlightOpacity);

                    let x = 0;
                    let y = 0;
                    div.attr("transform", (_, i, nodes) => {
                        const mouseCoords = d3.mouse(nodes[i].parentNode);
                        let xCo = 0;
                        let yCo = 0;
                        if (mouseCoords[0] + 10 >= width * 0.80) {
                            xCo = mouseCoords[0] - parseFloat(div
                                .attr("width"));
                            xCo = isNaN(xCo) ? mouseCoords[0] : xCo;
                            yCo = mouseCoords[1] + 10;
                        } else {
                            xCo = mouseCoords[0] + 10;
                            yCo = mouseCoords[1];
                        }
                        x = xCo;
                        y = yCo;
                    });
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);

                    div.html(`Count : ${d[1] - d[0]}`)
                        .style("left", (x) + "px")
                        .style("top", (y) + "px");
                })
            .on('mouseout', (d, i, nodes) => {
                d3.select(nodes[i]).style("opacity", barOpacity);
                div.transition()
                    .duration(500)
                    .style("opacity", 0);
            })
            .attr("height", 0)
            .transition()
            .duration(500)
            .delay((d, i) => {
                return i * 100;
            })
            .attr("height", (d) => { return y(d[0]) - y(d[1]); });

        const legendColors = ["#ff8a67", "#4cdde8", "#eb6494"];
        const legendGap = 150;

        const legendX = (width / 2) - legendGap;
        const legendGroup = svg.append("g")
            .attr("class", "legends")
            .attr("transform", "translate(" + legendX + "," + (height + margin.top + 15) + ")");

        // Legend rectangle
        legendGroup.selectAll(".legend-group")
            .enter()
            .append("g")
            .attr("class", "legend-group")
            .style("opacity", 1)
            .on("mouseover", (d, i, nodes) => {
                d3.select(nodes[i]).style("opacity", barHighlightOpacity);
                const selectedBar = (d === "valid") ? "accepted" : "not-accepted";
                d3.selectAll(`.sensitivity-chart .bar.${selectedBar}`).style("opacity", barHighlightOpacity);
            })
            .on("mouseout", (_, i, nodes) => {
                d3.select(nodes[i]).style("opacity", 1);
                d3.selectAll(`.bar`).style("opacity", barOpacity);
            })
            .append("rect")
            .attr("class", "legend-rect")
            .attr("width", 14)
            .attr("height", 10)
            .attr('x', (_, index) => { return (index * legendGap); })
            .style("fill", (_, index) => { return legendColors[index]; });


        // Legend label
        legendGroup.selectAll(".legend-group")
            .append("text")
            .attr("class", "legend-text")
            .attr("x", (_, index) => { return index * legendGap + 20; })
            .attr("y", 6)
            .text((line) => line)
            .attr("alignment-baseline", "middle");

    }, [chartData, theme.palette.chartColors.axis]);

    const circularPlot = useCallback(() => {
        const margin = { top: 100, right: 0, bottom: 0, left: 20 };
        const width = 600;
        const height = 700;
        const innerRadius = 120;
        const outerRadius = Math.min(width, height) / 2.5;
        const columns = ['level1', 'level2', 'level3'];
        const xScaleOffset = Math.PI * 75 / innerRadius;
        let xSpace = 0;
        if (window.screen.width <= 1368) {
            xSpace = 432;
        } else if (window.screen.width > 1368 && window.screen.width <= 1600) {
            xSpace = 532;
        } else {
            xSpace = 680;
        }
        const yAxis = () => {
            if ((chartData.length >= 1) && (chartData.length <= 3)) {
                return (height / 2.5 - (chartData.length * 10));
            }
            else if ((chartData.length >= 4) && (chartData.length <= 8)) {
                return (height / 3 - (chartData.length * 12));
            }
            else if ((chartData.length >= 9) && (chartData.length <= 12)) {
                return (height / 3.5 - (chartData.length * 12));
            }
            else if ((chartData.length >= 13) && (chartData.length <= 19)) {
                return (height / 3.5 - 120);
            }
            else if (chartData.length === 20) {
                return (height / 3 - 100);
            }
            else if ((chartData.length >= 21) && (chartData.length <= 23)) {
                return (height / 20 - (-chartData.length * 14));
            }
            return (height / 1.7);
        };
        const chartHeight = () => {
            if ((chartData.length >= 1) && (chartData.length <= 3)) {
                return (height - 280 - (chartData.length * 10));
            }
            else if ((chartData.length >= 4) && (chartData.length <= 7)) {
                return (height - 320 - (chartData.length * 12));
            }
            else if ((chartData.length >= 8) && (chartData.length <= 12)) {
                return (height - 300 + (chartData.length * 3));
            }
            else if ((chartData.length >= 13) && (chartData.length <= 20)) {
                return (height - 160);
            }
            else if ((chartData.length >= 21) && (chartData.length <= 22)) {
                return (height - 160 + (chartData.length * 8));
            }
            return (height + margin.left + 120);
        };

        d3.select(".sensitivity-chart >*").remove();
        d3.select(".sensitivity-chart svg").remove();
        d3.select(".sensitivity-chart .pattern-legend").remove();
        const svg = d3.select(".sensitivity-chart")
            .append("svg")
            .style("margin", '0 auto')
            .attr("width", '100%')
            .attr("height", chartHeight())
            .call(responsivefy)
            .append("g")
            .attr("transform", "translate(" + xSpace + "," + yAxis() + ")");

        d3.select(".sensitivity-chart >.tooltip").remove();
        const div = d3.select(".sensitivity-chart").append("div")
            .attr("class", "tooltip")
            .style("opacity", 0)
            .style("zIndex", 1);

        const x = d3.scaleBand()
            .range([xScaleOffset, chartData.length / 4])
            .align(0)
            .domain(chartData.map((d) => { return d.name; }));

        const y = d3.scaleLinear()
            .domain([0, d3.max(chartData, (d) => { return d.total; })])
            .range([innerRadius, outerRadius]);


        const z = d3.scaleOrdinal()
            .domain(columns)
            .range(["#ff8a67", "#4cdde8", "#eb6494"]);

        svg.append("g")
            .selectAll("g")
            .data(d3.stack().keys(columns)(chartData))
            .enter().append("g")
            .attr("fill", (d) => { return z(d.key); })
            .attr("transform", (d) => `translate(${x(d[0].data.name)},0)`)
            .selectAll("path")
            .data((d) => { return d; })
            .enter().append("path")
            .attr("d", d3.arc()
                .innerRadius((d) => { return y(d[0]); })
                .outerRadius((d) => { return y(d[1]); })
                .startAngle((d) => { return x(d.data.name); })
                .endAngle((d) => { return x(d.data.name) + x.bandwidth(); })
                .padAngle(0.01)
                .padRadius(innerRadius))
            .on('mouseover', (d) => {
                let x = 0;
                let y = 0;
                div.attr("transform", (_, i, nodes) => {
                    const mouseCoords = d3.mouse(nodes[i].parentNode);
                    let xCo = 0;
                    let yCo = 0;
                    if (mouseCoords[0] + 10 >= width * 0.80) {
                        xCo = mouseCoords[0] - parseFloat(div
                            .attr("width"));
                        xCo = isNaN(xCo) ? mouseCoords[0] : xCo;
                        yCo = mouseCoords[1] + 10;
                    } else {
                        xCo = mouseCoords[0] + 10;
                        yCo = mouseCoords[1];
                    }
                    x = xCo;
                    y = yCo;
                });
                div.transition()
                    .duration(200)
                    .style("opacity", 0.9);
                div.html(`Count : ${d[1] - d[0]}`)
                    .style("left", x + "px")
                    .style("top", y + "px");
            })
            .on('mouseout', () => {
                div.transition()
                    .duration(200)
                    .style("opacity", 0);
            });

        svg.append("g")
            .append("text")
            .style("text-anchor", "middle")
            .text(datasourceName)
            .style('font-size', '13px');

        svg.append("g")
            .selectAll("g")
            .data(chartData)
            .enter()
            .append("g")
            .attr("text-anchor", (d) => { return (x(d.name) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) < Math.PI ? "end" : "start"; })
            .attr("transform", (d) => { return `rotate(${(x(d.name) + x.bandwidth() / 2) * 180 / Math.PI - 90})translate(${y(d.total) + 10},0)`; })
            .append("text")
            .text((d) => d.name)
            .attr("transform", (d) => { return (x(d.name) + x.bandwidth() / 2 + Math.PI) % (2 * Math.PI) < Math.PI ? "rotate(180)" : "rotate(0)"; })
            .style("font-size", "12px")
            .attr("font-family", "Roboto")
            .attr("alignment-baseline", "middle");
        const lines = ["Sensitivity 1", 'Sensitivity 2', 'Sensitivity 3'];
        const legendGroup = d3.select('.sensitivity-chart').append("div")
            .attr("class", "pattern-legend")
            .style("bottom", "-25px")
            .style("left", xSpace + "px");

        // Legend rectangle
        legendGroup.selectAll(".pattern-legendgroup")
            .data(lines)
            .enter()
            .append("div")
            .attr("class", "pattern-legendgroup")
            .append("div")
            .attr("class", (d) => {
                if (d === "Sensitivity 1") {
                    return "sensitivity1";
                } else if (d === "Sensitivity 2") {
                    return "sensitivity2";
                }
                return "sensitivity3";
            });


        // Legend label
        legendGroup.selectAll(".pattern-legendgroup")
            .append("span")
            .text((line) => {
                return `${line}`;
            });

    }, [chartData, datasourceName]);

    useEffect(() => {
        if (chartData && chartData.length !== 0) {
            if (chartType === "Circular Plot") {
                circularPlot();
            } else {
                barChart();
            }

        }
    }, [barChart, chartData, chartType, circularPlot, datasourceName, theme.palette.chartColors.axis, theme.palette.chartColors.soundex]);

    return (
        <ChartContainer
            title={appConstants.charts.sensitivityChart.name}
            id={appConstants.charts.sensitivityChart.id}
            chartData={chartData}
            menuProperties={
                [
                    {
                        options: ["Bar Plot", "Circular Plot"],
                        onChange: changeChart,
                        value: chartType
                    }
                ]
            }>
            <Grid ref={containerRef} container className={classNames(classes.chartStyles, "sensitivity-chart")} />
        </ChartContainer>
    );
};

SensitivityChart.propTypes = {
    theme: PropTypes.object,
    chartData: PropTypes.array,
    datasourceName: PropTypes.string,
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SensitivityChart);