import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { sortTable } from '../../helpers/appHelpers.js';

// import material-ui components
import { withStyles, Grid, IconButton, Typography, Popover, ButtonGroup, Button, InputLabel, MenuItem, TextField, Select } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment-timezone';

// import components
import AutoComplete from '../AutoComplete/AutoComplete.jsx';
import NumberInput from '../TextBox/NumberInput.jsx';
import ScheduleStyles from '../Schedule/ScheduleStyles.jsx';
// import ScheduleStyles from './ScheduleStyles.jsx';
import { appConstants } from '../../constants/appConstants';
import ScheduleMonthCalender from '../ScheduleMonthCalender/ScheduleMonthCalender.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';

// import styles
import Styles from '../../layouts/Styles.jsx';
import DatasetStyles from './DatasetStyles.jsx';

import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import { useDispatch } from 'react-redux';
import { getDatasetLevelRules } from '../../actions/scheduleActions.js';


const MonitoringSchedule = (props) => {
    const { classes, popover, onClose, monitoringDetails, datasetId, datasourceId, saveMonitoring, details, onChanges, changeSchedules, datasetSchedule } = props;

    const dispatch = useDispatch();

    // use state
    const [open, setMonthCalender] = useState(false);
    const [sourceRules, setSourceRules] = useState([]);
    const [targetRules, setTargetRules] = useState([]);
    const zones = [{ name: moment.tz.guess() }, ...appConstants.TimeZones];

    const [timeZones] = useState([...zones]);
    const [anchorEl, setAchorEl] = useState(null);

    useEffect(() => {
        if (datasetId) {
            dispatch(getDatasetLevelRules(datasetId)).then((response) => {
                if (response) {
                    setSourceRules(response);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasetId]);

    useEffect(() => {
        if (details.target) {
            dispatch(getDatasetLevelRules(details.target)).then((response) => {
                if (response) {
                    setTargetRules(response);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [details.target]);

    const changeSchedule = (value, property) => {
        changeSchedules(value, property);
    };

    const closeMonthCalender = () => {
        setAchorEl(null);
        setMonthCalender(false);
    };

    const openMonthCalender = (event) => {
        setAchorEl(event.target);
        setMonthCalender(true);
    };

    const onChange = (type, value) => {
        onChanges(type, value);
    };

    const getAttributeList = () => {
        return monitoringDetails.attributes;
    };

    const getTargetValue = () => {
        if (monitoringDetails?.target) {
            const selectedValue = monitoringDetails?.target.filter((elem) => elem.dataset_id === details.target);
            if (selectedValue.length > 0) {
                return selectedValue[0];
            }
        }
        return '';
    };

    const onSubmit = () => {
        datasetSchedule["start_time"] = `${moment(datasetSchedule.date).format('YYYY-MM-DD')} ${moment(datasetSchedule.time).format('HH:mm')}`;
        datasetSchedule["day_week_month_time"] = parseInt(moment(datasetSchedule.scheduleTime).format("HH"));
        datasetSchedule["day_week_month_minitues"] = parseInt(moment(datasetSchedule.scheduleTime).format("mm"));
        datasetSchedule["week_day"] = datasetSchedule["schedule_method"] === 'Week' ? datasetSchedule["week_day"] : '';
        datasetSchedule["month_date"] = datasetSchedule["schedule_method"] === 'Month' ? datasetSchedule["month_date"] : '';
        const monitoringContent = {
            "dataset_id": datasetId,
            "source_id": datasourceId,
            "name": details.name,
            "description": details.description,
            "tolerance": details.percentage,
            "duplicate_column": details.attribute === "" ? [] : details.attribute,
            "destination_dataset_id": details.target,
            "type": details.type,
            "edit": details.edit,
            "monitoring_id": details.monitoring_id,
            "schedule_details": { ...datasetSchedule },
            sourcerule: details?.sourcerule || {},
            targetrule: details?.targetrule || {}
        };

        saveMonitoring(monitoringContent);
    };

    return (
        <Popover
            open={popover}
            onClose={() => onClose()}
            anchorOrigin={
                {
                    vertical: 150,
                    horizontal: 'right'
                }
            }
            transformOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }>
            <ValidatorForm form="My profile" className={classes.monitoringContainer} onSubmit={() => onSubmit()}>
                <Grid container className={classes.schedulePopover}>
                    <Grid item className={classes.marginBottom15}>
                        <Typography variant="h4" component="h4">
                            Add a New Alert
                        </Typography>
                    </Grid>
                    <Grid item className={classes.marginBottom5}>
                        <InputLabel>
                            Select Type
                        </InputLabel>
                        <Select
                            fullWidth
                            value={details.type ? details.type : ""}
                            name="type"
                            disabled={details.edit}
                            className={classNames(classes.textboxContainer, classes.inputOutline, classes.businessNameTextBox, classes.paddingZero)}
                            onChange={(event) => onChange(event.target.name, event.target.value)}
                        >
                            <MenuItem value={"Duplicate"}>
                                {"Duplicate"}
                            </MenuItem>
                            <MenuItem value={"Source to Target Comparison"}>
                                {"Source to Target Comparison"}
                            </MenuItem>
                            <MenuItem value={"Source to Target Comparison - Custom Rules"}>
                                {"Source to Target Comparison - Custom Rules"}
                            </MenuItem>
                        </Select>
                    </Grid>
                    <Grid className={classes.displayFlexColumn}>
                        <TextBox
                            label="Monitoring Name"
                            name="name"
                            value={details.name ? details.name : ''}
                            validators={['required']}
                            errorMessages={['Name is required']}
                            onChange={(event) => onChange(event.target.name, event.target.value)}
                            fullWidth
                            className={classes.marginBottom10}
                        />
                        <TextBox
                            label="Monitoring Description"
                            name="description"
                            value={details.description ? details.description : ''}
                            errorMessages={['Description is required']}
                            onChange={(event) => onChange(event.target.name, event.target.value)}
                            fullWidth
                            className={classes.marginBottom10}
                        />
                    </Grid>
                    {
                        (details.type === "Source to Target Comparison" || details.type === "Source to Target Comparison - Custom Rules") &&
                        <Grid classList={classes.displayFlexColumn}>
                            <TextBox
                                label="Select Source (Dataset)"
                                name="source"
                                value={details.source ? details.source : ''}
                                validators={['required']}
                                errorMessages={['Source is required']}
                                fullWidth
                                className={classNames(classes.marginBottom10, classes.marginTop5)}
                                disabled
                            />
                            <Typography>
                                {'Target Source (Dataset)'}
                            </Typography>
                            <AutoCompleteInput
                                name="target"
                                popperWidth={300}
                                options={sortTable(monitoringDetails?.target || [], "asc", "dataset_schema")}
                                freeSolo
                                disabled={details.edit}
                                value={details.target ? getTargetValue() : ''}
                                getOptionLabel={
                                    (option) => {
                                        if (option && option.dataset_schema) {
                                            return option.dataset_schema;
                                        }
                                        return option;
                                    }
                                }
                                getOptionSelected={
                                    (option, value) => {
                                        if (option && option.dataset_schema && value && value.dataset_schema) {
                                            return option.dataset_schema === value.dataset_schema;
                                        }
                                        return option === value;
                                    }
                                }
                                renderInput={
                                    (params) =>
                                        <TextField {...params}
                                            className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                            placeholder="Select Target (Dataset)"
                                            margin="normal"
                                        />
                                }
                                onChange={(_, selectedValue) => onChange('target', selectedValue?.dataset_id || 0)}
                                openOnFocus
                                disableCloseOnSelect
                                disableClearable
                                fullWidth
                                // multiple
                                forcePopupIcon={false}
                                noOptionsText={"No Attributes Found"}
                            />
                        </Grid>
                    }
                    {
                        details.type === "Source to Target Comparison - Custom Rules" &&
                        <Grid>
                            <Grid>
                                <TextBox
                                    label="Source Rules"
                                    name="sourcerule"
                                    select
                                    value={details?.sourcerule?.rule_name ? details?.sourcerule?.rule_name : ''}
                                    // validators={['required']}
                                    // errorMessages={['Source Rules is required']}
                                    onChange={(event) => onChange(event.target.name, sourceRules.filter((elem) => elem.rule_name === event.target.value)[0])}
                                    fullWidth
                                >
                                    {
                                        sourceRules && sortTable(sourceRules, "asc", "dataset_schema").map((rule, index) => {
                                            return (
                                                <MenuItem key={`target_dataset_${index}`} value={rule.rule_name}>
                                                    <ToolTipComponent title={rule.rule_name} arrow>
                                                        <Grid className={classes.maxEcclipse}>
                                                            {rule.rule_name}
                                                        </Grid>
                                                    </ToolTipComponent>
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </TextBox>
                            </Grid>
                            <Grid>
                                <TextBox
                                    label="Target Rules"
                                    name="targetrule"
                                    select
                                    value={details?.targetrule?.rule_name ? details?.targetrule?.rule_name : ''}
                                    // validators={['required']}
                                    // errorMessages={['Target Rules is required']}
                                    onChange={(event) => onChange(event.target.name, targetRules.filter((elem) => elem.rule_name === event.target.value)[0])}
                                    fullWidth
                                >
                                    {
                                        targetRules && sortTable(targetRules, "asc", "dataset_schema").map((rule, index) => {
                                            return (
                                                <MenuItem key={`target_dataset_${index}`} value={rule.rule_name}>
                                                    <ToolTipComponent title={rule.rule_name} arrow>
                                                        <Grid className={classes.maxEcclipse}>
                                                            {rule.rule_name}
                                                        </Grid>
                                                    </ToolTipComponent>
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </TextBox>
                            </Grid>
                        </Grid>
                    }
                    {
                        details.type === "Duplicate" &&
                        <Grid classList={classes.displayFlexColumn}>
                            <AutoCompleteInput
                                name="attribute"
                                popperWidth={300}
                                options={getAttributeList()}
                                freeSolo
                                value={details.attribute ? details.attribute : []}
                                getOptionLabel={
                                    (option) => {
                                        if (option && option.name) {
                                            return option.name;
                                        }
                                        return option;
                                    }
                                }
                                getOptionSelected={
                                    (option, value) => {
                                        if (option && option.name && value && value.name) {
                                            return option.name === value.name;
                                        }
                                        return option === value;
                                    }
                                }
                                renderInput={
                                    (params) =>
                                        <TextField {...params}
                                            className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                            placeholder="Duplicate Column Attributes"
                                            margin="normal"
                                        />
                                }
                                onChange={(_, selectedValue) => onChange("attribute", selectedValue)}
                                openOnFocus
                                disableCloseOnSelect
                                disableClearable
                                fullWidth
                                multiple
                                forcePopupIcon={false}
                                noOptionsText={"No Attributes Found"}
                            />
                        </Grid>
                    }
                    <Grid>
                        <TextBox
                            label="Tolerance Percentage"
                            name="percentage"
                            value={details.percentage ? details.percentage : ''}
                            onChange={(event) => onChange(event.target.name, event.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid className={classes.marginTop15}>
                        <Typography component="h5" variant="h5">
                            {'Schedule Monitoring'}
                        </Typography>
                        <Grid container direction="row" justify="space-between" className={classes.marginTop15}>
                            <DatePicker autoOk
                                variant="inline"
                                label="Start Date"
                                format="MM/DD/YYYY"
                                value={datasetSchedule.date ? datasetSchedule.date : Date.now()}
                                onChange={(date) => changeSchedule(moment(date), 'date')}
                            />
                            <TimePicker
                                ampm={false}
                                label="Start Time"
                                format="HH:mm"
                                value={datasetSchedule.time ? datasetSchedule.time : Date.now()}
                                onChange={(time) => changeSchedule(moment(time), 'time')}
                            />
                        </Grid>
                        <Grid className={classNames(classes.marginTop15, classes.schedulerSection)}>
                            <Grid container justify="center" alignItems="center">
                                <ButtonGroup className={classes.TimingBtnGroup}>
                                    {
                                        appConstants.scheduleMethods.map((method, index) =>
                                            <Button
                                                className={classNames(classes.tabBtn, method.value === datasetSchedule.schedule_method ? 'active' : '')}
                                                variant="contained"
                                                key={`schedule_${index}`}
                                                onClick={() => changeSchedule(method.value, 'schedule_method')}>
                                                {method.name}
                                            </Button>
                                        )
                                    }
                                </ButtonGroup>
                            </Grid>
                            <Grid className={classes.scheduleContent}>
                                {
                                    appConstants.scheduleMethods[0].value === datasetSchedule.schedule_method &&
                                    <Grid container direction="row" justify="center" alignItems="center">
                                        <Grid item xs={4}>
                                            <NumberInput label="Every"
                                                id="minute"
                                                value={datasetSchedule.minitues_interval ? datasetSchedule.minitues_interval : ''}
                                                onChange={(event) => changeSchedule(event.target.value, "minitues_interval")}
                                                validators={['required', 'maxNumber:60']}
                                                errorMessages={['Duration is required', 'Enter below 60 Minutes']}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography>
                                                Minute(s)
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    appConstants.scheduleMethods[1].value === datasetSchedule.schedule_method &&
                                    <Grid container direction="row" justify="center" alignItems="center">
                                        <Grid item xs={4}>
                                            <NumberInput label="Every"
                                                id="hour"
                                                value={datasetSchedule.minitues_interval ? datasetSchedule.minitues_interval : ''}
                                                onChange={(event) => changeSchedule(event.target.value, "minitues_interval")}
                                                validators={['required', 'maxNumber:24']}
                                                errorMessages={['Duration is required', 'Enter below 24 Hours']}
                                            />
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography>
                                                Hour(s)
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    appConstants.scheduleMethods[2].value === datasetSchedule.schedule_method &&
                                    <Grid container direction="row" justify="center" alignItems="center">
                                        <TimePicker
                                            ampm={false}
                                            label="Select a Time"
                                            format="HH:mm"
                                            value={datasetSchedule.scheduleTime ? datasetSchedule.scheduleTime : Date.now()}
                                            onChange={(time) => changeSchedule(time, "scheduleTime")}
                                        />
                                    </Grid>
                                }
                                {
                                    appConstants.scheduleMethods[3].value === datasetSchedule.schedule_method &&
                                    <Grid container direction="row" justify="center" alignItems="center">
                                        <ButtonGroup>
                                            {
                                                appConstants.weekDays.map((days, index) =>
                                                    <Button
                                                        onClick={() => changeSchedule(days.value, "week_day")}
                                                        variant="contained"
                                                        key={`days_${index}`}
                                                        className={classNames(classes.dayBtn, datasetSchedule.week_day.indexOf(days.value) !== -1 ? 'active' : '')}
                                                    >
                                                        {days.name}
                                                    </Button>
                                                )
                                            }
                                        </ButtonGroup>
                                        <TimePicker
                                            ampm={false}
                                            label="Select a Time"
                                            format="HH:mm"
                                            margin="normal"
                                            value={datasetSchedule.scheduleTime ? datasetSchedule.scheduleTime : Date.now()}
                                            onChange={(time) => changeSchedule(time, "scheduleTime")}
                                        />
                                    </Grid>
                                }
                                {
                                    appConstants.scheduleMethods[4].value === datasetSchedule.schedule_method &&
                                    <Grid container align="center" justify="center" spacing={4}>
                                        <Grid item>
                                            <TextBox
                                                label="Select a Date"
                                                value={datasetSchedule.month_date.toString()}
                                                readOnly
                                            />
                                            <IconButton onClick={(event) => openMonthCalender(event)}>
                                                <TodayIcon className={classes.calendarIcon} />
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <TimePicker
                                                ampm={false}
                                                label="Select a Time"
                                                format="HH:mm"
                                                // margin="normal"
                                                value={datasetSchedule.scheduleTime ? datasetSchedule.scheduleTime : Date.now()}
                                                onChange={(time) => changeSchedule(time, "scheduleTime")}
                                            />
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        <Grid container className={classes.marginTop15}>
                            <Grid item xs={12} className={classes.timeZone}>
                                <AutoComplete
                                    label="Timezone"
                                    placeholder="Select Timezone"
                                    onChange={(event) => changeSchedule(event.target.value, "time_zone")}
                                    onBlur={() => { }}
                                    value={datasetSchedule.time_zone ? datasetSchedule.time_zone : ''}
                                    validators={['required']}
                                    errorMessages={['Timezone is required']}
                                    options={timeZones}
                                    selectedValuePath="name"
                                    displayMemberPath="name"
                                    removeClass
                                    openSuggestionsOnClick
                                    name="timzone"
                                    id="timzone"
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                        <Grid container justify="flex-end" alignItems="center" className={classes.marginTop20}>
                            <Button className={classes.tabBtn} type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                            <Button onClick={() => onClose()} style={{ marginLeft: '10px' }} variant="contained" className={classNames(classes.cancelBtn, classes.tabBtn)}>
                                Cancel
                            </Button>
                        </Grid>

                    </Grid>
                    {
                        open && <ScheduleMonthCalender
                            onOpenPopover={open}
                            anchorElement={anchorEl}
                            onClose={() => closeMonthCalender()}
                            onDateSelectionChange={(day) => changeSchedule(day, "month_date")}
                            selectedDays={datasetSchedule.month_date}
                        />
                    }
                </Grid>
            </ValidatorForm>
        </Popover >
    );
};

MonitoringSchedule.propTypes = {
    classes: PropTypes.object,
    popover: PropTypes.bool,
    onClose: PropTypes.func,
    saveMonitoring: PropTypes.func,
    onChanges: PropTypes.func,
    changeSchedules: PropTypes.func,
    datasetId: PropTypes.number,
    datasourceId: PropTypes.number,
    monitoringDetails: PropTypes.object,
    properties: PropTypes.object,
    details: PropTypes.object,
    sourceRules: PropTypes.object,
    datasetSchedule: PropTypes.object
};

export default withStyles((theme) => ({
    ...ScheduleStyles(theme),
    ...DatasetStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MonitoringSchedule);