
const ActionItemStyles = (theme) => ({
    actionbtn: {
        right: '16px',
        bottom: '16px',
        position: 'fixed',
        boxShadow: '0px 3px 20px #00000029',
        border: '1px solid #E1E5E6',
        height: '43px',
        width: '43px',
        background: '#fff',
        zIndex: 9999,
        '&:hover': {
            background: '#fff'
        }
    },
    addActionContainer: {
        cursor: 'crosshair',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        border: `8px solid ${theme.palette.secondary.main}`,
        transition: 'all .5s ease',
        zIndex: 1301,
        '& img': {
            maxWidth: '100%'
        }
    },
    addCloseBtn: {
        position: 'fixed',
        top: 5,
        right: 5
    },
    actionDot: {
        position: 'fixed',
        width: 40,
        height: 40,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: '0px 0px 10px #00000029',
        borderRadius: 50,
        textAlign: 'center',
        fontSize: 16,
        color: '#fff',
        lineHeight: '40px'
    },
    popOverContainer: {
        padding: 15,
        minWidth: 400
    },
    commentSection: {
        border: `1px solid ${theme.palette.border.default}`,
        margin: '10px 0px',
        padding: 5,
        '& input': {
            // height: 100
        },
        '& .MuiInput-underline:before': {
            borderBottom: 'none'
        },
        '& .MuiInput-underline:after': {
            borderBottom: 'none'
        }
    },
    listContainer: {
        padding: '0 15px',
        overflowY: 'auto',
        height: 'calc(100vh - 80px)'
    },
    actionitemlist: {
        borderRadius: 4,
        backgroundColor: theme.palette.background.container,
        border: `1px solid ${theme.palette.grey.extraLight}`,
        padding: 10,
        marginBottom: 12,
        width: '100%'
    },
    actionTimeTxt: {
        fontSize: 11,
        color: theme.palette.grey.main
    },
    actionImgContainer: {
        height: 150,
        margin: '10px 0px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        position: 'relative'
    },
    smallDot: {
        height: 15,
        width: 15,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        fontSize: 8,
        color: theme.palette.background.paper,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    circleDot: {
        height: 30,
        width: 30,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        fontSize: 10,
        color: theme.palette.background.paper,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    listScrollContainer: {
        height: 'calc(100vh - 220px)',
        overflowY: 'auto'
    },
    addCommentSection: {
        width: '100%',
        '& textarea': {
            padding: 5
        }
    },
    commentContainer: {
        position: 'relative'
    },
    commentSendBtn: {
        position: 'absolute',
        top: '50%',
        right: 20,
        padding: 0
    },
    actionCloseBtn: {
        position: 'absolute',
        right: 0,
        borderRadius: 0,
        backgroundColor: theme.palette.secondary.main
    },
    card: {
        padding: "10px 15px",
        boxShadow: "0px 0px 6px #e1e5e6",
        backgroundColor: "#fff"
    },
    actionListContainer: {
        padding: 12,
        boxShadow: '0px 0px 10px #0000000F',
        position: 'relative'
    },
    listDetail: {
        display: 'flex',
        alignItems: 'center'
    },
    actionListImg: {
        width: 80,
        height: 60
    },
    actionListPriorityTxt: {
        color: '#222',
        fontSize: 13
    },
    actionListTimeTxt: {
        color: theme.palette.grey.dark
    },
    descriptionTxt: {
        color: theme.palette.grey.main,
        fontSize: 13,
        marginBottom: 5
    },
    successBorder: {
        backgroundColor: '#54DF9A'
    },
    openBorder: {
        backgroundColor: '#FF5C5C'
    },
    newBorder: {
        backgroundColor: '#FDAC42'
    },
    statusBorder: {
        height: '100%',
        width: 4,
        position: 'absolute',
        left: 0
    },
    detailCommentSection: {
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.normal}`,
        maxWidth: '445px',
        flexBasis: '445px',
        padding: 15
    },
    detailDialog: {
        height: 60,
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.border.normal}`,
        padding: 15
    },
    detailContentContainer: {
        height: 'calc(100% - 60px)',
        backgroundColor: theme.palette.background.container
    },
    detailContentSection: {
        maxWidth: 'calc(100% - 445px)',
        flexBasis: 'calc(100% - 445px)',
        padding: 45,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& img': {
            maxWidth: '100%'
        }
    },
    cardContainer: {
        marginBottom: 15,
        borderRadius: 0,
        overflow: "hidden",
        border: '1px solid rgba(26,54,126,0.125)',
        background: "#fff",
        '&:last-child': {
            marginBottom: "none"
        },
        '& .MuiCardContent-root:last-child': {
            paddingBottom: '0px'
        },
        '&:hover': {
            boxShadow: '0 4px 25px 0 rgba(0,0,0,.1)',
            transition: 'all 0.2s ease'
        }
    },
    actionTitleTxt: {
        width: 200,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    detailImage: {
        width: '98%',
        // height: 450,
        ObjectFit: 'cover',
        position: 'relative'
    },
    detailCommentList: {
        height: 'calc(100vh - 200px)',
        overflowY: 'auto',
        paddingRight: '10px'
    },
    linkTxt: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.dark
        }
    },
    menuItem: {
        textTransform: 'capitalize',
        '& .MuiInput-input': {
            textTransform: 'capitalize'
        }
    }
});

export default ActionItemStyles;