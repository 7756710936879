/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Grid, Table, TableRow, TableHead, TableCell, Typography, TableBody, Slider, Switch, IconButton, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../../../layouts/Styles.jsx';

import TileViewStyles from '../TileView/TileViewStyles.jsx';
import PropertiesListViewStyles from './PropertiesListViewStyles.jsx';
import DatasetStyles from '../../DatasetStyles.jsx';
import { updateListProperties, setPropertySliderIndex } from '../../../../actions/datasetActions.js';
import AutoComplete from '../../../AutoComplete/AutoCompleteInput.jsx';
import TooltipComponent from '../../../Tooltip/Tooltip.jsx';


const PropertiesPanel = (props) => {
    const { classes, properties, isEditable, theme, updateProperties, setSelectedAttribute } = props;
    const dispatch = useDispatch();
    const datatypes = useSelector(({ datasource }) => datasource.datatypes);
    const fieldTypes = useSelector(({ datasource }) => datasource.fieldTypes);
    const semanticModelAttributes = useSelector(({ master }) => master.modelFieldTypes);
    const datasetId = useSelector(({ dataset }) => dataset.selectedDatasetId);
    const [selectedProperties, setSelectedProperties] = useState({});
    const attributesCatalogInfo = useSelector(({ datasource }) => datasource.attributesCatalogInfo);
    const [uniqueBusinessName, setUniqueBusinessName] = useState(false);

    const loadProperties = useCallback((properties) => {
        if (properties) {
            setSelectedProperties({ ...properties });
        }
    }, []);

    const updateAttributeProperties = useCallback((attributeValue, property, value, update = false) => {
        const attribute = attributeValue.name;

        selectedProperties[attribute][property] = value;
        if (property === 'primary_key') {
            if (value) {
                selectedProperties[attribute]['is_unique'] = true;
                selectedProperties[attribute]['is_null'] = false;
                selectedProperties[attribute]['is_blank'] = false;
            }
            const attributes = Object.keys(selectedProperties);
            for (const key of attributes) {
                if (key !== attribute) {
                    selectedProperties[key][property] = false;
                }
            }
        }
        if (property === 'is_unique') {
            if (value) {
                selectedProperties[attribute]['is_null'] = false;
                selectedProperties[attribute]['is_blank'] = false;
            }
        }
        setSelectedProperties({ ...selectedProperties });
        if (update) {
            const attributeProperty = {
                id: attributeValue.id,
                attribute,
                property,
                value
            };
            dispatch(updateListProperties(datasetId, attributeProperty));
            updateProperties(attributeProperty);
        }
    }, [datasetId, dispatch, selectedProperties, updateProperties]);


    useEffect(() => {
        const attribute_name = Object.keys(properties).map((attrib, index) => {
            return properties[attrib]?.name;
        });
        const business_name = Object.keys(properties).map((business, index) => {
            return properties[business]?.business_name;
        });
        const duplicate = business_name.filter((x) => !attribute_name.includes(x));
        if (duplicate.length > 0) {
            setUniqueBusinessName(true);
        } else {
            setUniqueBusinessName(false);
        }
    }, [properties]);

    useEffect(() => {
        if (datasetId && properties) {
            loadProperties(properties);
        }
    }, [datasetId, loadProperties, properties]);
    // const propertiesList = Object.keys(properties).filter((property, index) => index < limit)

    /*
     * const onScroll = (event) => {
     *     if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
     *         setLimit(limit + 10)
     *     }
     * }
     */
    const propertiesList = Object.keys(properties);
    const setPropIndex = (name, index) => {
        setTimeout(() => dispatch(setPropertySliderIndex(index)), 0);
        setSelectedAttribute(name);
    };

    const getAttributeCatalogComment = (attributeName) => {
        let comment = "";
        if (attributesCatalogInfo && attributesCatalogInfo.length !== 0) {
            comment = attributesCatalogInfo.find((data) => (data.attribute_name === attributeName));
            comment = comment && comment.comment ? comment.comment : "";
        }
        return comment;
    };

    const getDescriptionaMaxWidth = (elementIndex) => {
        const element = document.querySelector(`.description_${elementIndex}`);
        return element && element.clientWidth ? `${element.clientWidth}px` : '100%';

    };

    return (
        <Grid container direction="column">
            <Table stickyHeader className={classes.propertyListPageTable}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Attribute'}
                            </Typography>
                        </TableCell>
                        {
                            uniqueBusinessName &&
                            <TableCell>
                                <Typography variant="body1" className={classes.tableHeader}>
                                    {'Business Name'}
                                </Typography>
                            </TableCell>
                        }
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Description'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Data Type'}
                            </Typography>
                        </TableCell>
                        <TableCell className={classes.minWidth100}>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Field Type'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Null'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Blank'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Unique'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Master'}
                            </Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Primary'}
                            </Typography>
                        </TableCell>
                        <TableCell align="center">
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Report'}
                            </Typography>
                        </TableCell>
                        <TableCell style={{ width: '10%' }}>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Sensitivity'}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.propertyListPageTableBody}>
                    {
                        propertiesList.map((attribute, index) => {
                            const property = properties[attribute];
                            return (
                                <TableRow key={`attributeProperty${index}`}>
                                    <TableCell>
                                        <Grid className={classes.flex}>
                                            {
                                                (getAttributeCatalogComment(property && property.name ? property.name : '').length !== 0) &&
                                                <TooltipComponent arrow title={(getAttributeCatalogComment(property && property.name ? property.name : ''))} placement="bottom-start">
                                                    <IconButton className={classNames(classes.nopadding, classes.marginRight5, classes.smallIcon)}>
                                                        <svg id="information" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                                                            <path id="Path_15040" data-name="Path 15040" d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,18.182A8.182,8.182,0,1,1,18.182,10,8.191,8.191,0,0,1,10,18.182Z" fill={theme.palette.secondary.main} />
                                                            <path id="Path_15041" data-name="Path 15041" d="M146.214,70a1.212,1.212,0,1,0,1.212,1.213A1.214,1.214,0,0,0,146.214,70Z" transform="translate(-136.214 -65.758)" fill={theme.palette.secondary.main} />
                                                            <path id="Path_15042" data-name="Path 15042" d="M150.909,140a.909.909,0,0,0-.909.909v5.455a.909.909,0,1,0,1.818,0v-5.455A.909.909,0,0,0,150.909,140Z" transform="translate(-140.909 -131.515)" fill={theme.palette.secondary.main} />
                                                        </svg>
                                                    </IconButton>
                                                </TooltipComponent>
                                            }
                                            <TooltipComponent arrow title={property.name}>
                                                <Typography noWrap onClick={() => setPropIndex(property.name, index)} style={{ cursor: 'pointer' }}>
                                                    {property && property.name ? property.name : ''}
                                                </Typography>
                                            </TooltipComponent>
                                            {
                                                property.sensitivity > 1 &&
                                                <Grid item className={classNames(classes.flex, classes.sensitivityContainer)}>
                                                    <TooltipComponent title={'Contains Sensitive Information'} arrow>
                                                        <svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" width="30" height="30">
                                                            <g id="Logo" clipPath="url(#clip-Logo)">
                                                                <rect width="30" height="30" fill="#fff" />
                                                                <g id="Group_4" data-name="Group 4" transform="translate(-18.99 -64.748)">
                                                                    <g id="Group_3" data-name="Group 3" transform="translate(29.99 87.748)">
                                                                        <line id="Line_1" data-name="Line 1" x2="31.611" transform="translate(86.008 38.817)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeWidth="8" />
                                                                        <line id="Line_2" data-name="Line 2" x2="38.119" transform="translate(86.008 58.315)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeWidth="8" />
                                                                        <g id="Group_2" data-name="Group 2" transform="translate(18.138 16.479)">
                                                                            <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(10.103 0)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8">
                                                                                <circle cx="20.159" cy="20.159" r="20.159" stroke="none" />
                                                                                <circle cx="20.159" cy="20.159" r="16.159" fill="none" />
                                                                            </g>
                                                                            <path id="Path_1" data-name="Path 1" d="M226.1,131.262c.074-.283,3.875-28.637,29.415-28.637s30.225,28.907,29.96,28.637S226.029,131.544,226.1,131.262Z" transform="translate(-226.103 -60.531)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" />
                                                                        </g>
                                                                        <g id="Group_1" data-name="Group 1" transform="translate(108.877 66.576)">
                                                                            <g id="Rectangle_2" data-name="Rectangle 2" transform="translate(0 27.27)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8">
                                                                                <rect width="69.739" height="59.932" rx="10" stroke="none" />
                                                                                <rect x="4" y="4" width="61.739" height="51.932" rx="6" fill="none" />
                                                                            </g>
                                                                            <path id="Path_2" data-name="Path 2" d="M339.964,142.246s-4.831-29.99,19.725-29.889,19.222,29.889,19.222,29.889" transform="translate(-324.329 -112.357)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" />
                                                                            <g id="Rectangle_3" data-name="Rectangle 3" transform="translate(6.538 33.808)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeWidth="10">
                                                                                <rect width="56.663" height="47.945" rx="3" stroke="none" />
                                                                                <rect x="5" y="5" width="46.663" height="37.945" rx="2" fill="none" />
                                                                            </g>
                                                                            <circle id="Ellipse_2" data-name="Ellipse 2" cx="7.628" cy="7.628" r="7.628" transform="translate(27.242 49.064)" fill={theme.palette.primary.main} />
                                                                        </g>
                                                                        <path id="Path_3" data-name="Path 3" d="M349.571,85.856s1.193-37.659,0-40.24-4.906-6.753-10.15-6.854-122.488.394-127.475.193-6.955,1.994-6.955,9.317v99.934s.666,6.854,9.51,7.075,16.361,0,16.361,0,4.276-10.853,12.179-11.008,12.241,11.008,12.241,11.008l38.119-.471" transform="translate(-204.99 -38.748)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" />
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </TooltipComponent>
                                                </Grid>
                                            }
                                        </Grid>
                                    </TableCell>
                                    {
                                        uniqueBusinessName &&
                                        <TableCell>
                                            <TooltipComponent arrow title={property && property.business_name ? property.business_name : ''}>
                                                <TextField
                                                    name="business_name"
                                                    value={property && property.business_name ? property.business_name : ''}
                                                    className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey, classes.tableInputEllip)}
                                                    onChange={(event) => updateAttributeProperties(property, event.target.name, event.target.value)}
                                                    onBlur={(event) => updateAttributeProperties(property, event.target.name, event.target.value, true)}
                                                    disabled={!isEditable}
                                                />
                                            </TooltipComponent>
                                        </TableCell>
                                    }
                                    <TableCell className={`description_${index}`}>
                                        <TooltipComponent arrow title={property && property.description ? property.description : ''}>
                                            <TextField
                                                name="description"
                                                value={property && property.description ? property.description : ''}
                                                className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey, classes.tableInputEllip)}
                                                onChange={(event) => updateAttributeProperties(property, event.target.name, event.target.value)}
                                                onBlur={(event) => updateAttributeProperties(property, event.target.name, event.target.value, true)}
                                                disabled={!isEditable}
                                                style={{ width: `${property?.description?.length * 11}px`, minWidth: '100%', maxWidth: getDescriptionaMaxWidth(index) }}
                                            />
                                        </TooltipComponent>
                                    </TableCell>
                                    <TableCell style={{ padding: '6px 9px' }}>
                                        <TooltipComponent arrow title={property && property.datatype ? property.datatype : ''} disableTouchListener disableFocusListener>
                                            <AutoComplete
                                                name="datatype"
                                                options={datatypes}
                                                value={property && property.datatype ? property.datatype : ''}
                                                getOptionLabel={
                                                    (option) => {
                                                        if (option && option.name) {
                                                            return option.name;
                                                        }
                                                        return option;
                                                    }
                                                }
                                                getOptionSelected={(option, value) => option.name === value}
                                                renderInput={(params) => <TextField {...params} className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey, classes.tableInputEllip)} margin="normal" placeholder="DataType" />}
                                                onChange={(_, selectedValue) => updateAttributeProperties(property, "datatype", selectedValue.name, true)}
                                                openOnFocus
                                                blurOnSelect
                                                disableClearable
                                                fullWidth
                                                forcePopupIcon={false}
                                                noOptionsText={"No datatypes"}
                                                disabled={!isEditable}
                                                style={{ width: `${property?.datatype?.length * 11}px`, minWidth: '100%' }}
                                            />
                                        </TooltipComponent>
                                    </TableCell>
                                    <TableCell className={classes.FieldInputContainer} style={{ padding: '6px 9px' }}>
                                        <TooltipComponent arrow title={property && property.fieldtype ? property.fieldtype : ''}>
                                            <AutoComplete
                                                name="fieldtype"
                                                options={fieldTypes}
                                                value={property && property.fieldtype ? property.fieldtype : ''}
                                                getOptionLabel={
                                                    (option) => {
                                                        if (option && option.name) {
                                                            return option.name;
                                                        }
                                                        return option;
                                                    }
                                                }
                                                getOptionSelected={(option, value) => option.name === value}
                                                renderInput={(params) => <TextField {...params} className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey, classes.tableInputEllip)} margin="normal" placeholder="Field Type" />}
                                                onChange={(_, selectedValue) => updateAttributeProperties(property, "fieldtype", selectedValue && selectedValue.name ? selectedValue.name : '', true)}
                                                openOnFocus
                                                blurOnSelect
                                                fullWidth
                                                forcePopupIcon={false}
                                                noOptionsText={"No fieldtypes"}
                                                disabled={!isEditable}
                                                style={{ width: `${property?.fieldtype?.length * 11}px`, minWidth: '100%' }}
                                                optionTooltip
                                            />
                                        </TooltipComponent>
                                    </TableCell>
                                    <TableCell>
                                        <Switch checked={Boolean(property.is_null)}
                                            color="secondary"
                                            name="is_null"
                                            onChange={(event) => updateAttributeProperties(property, event.target.name, event.target.checked, true)}
                                            disabled={!isEditable || (property.is_unique || property.primary_key)} />
                                    </TableCell>
                                    <TableCell>
                                        <Switch checked={Boolean(property.is_blank)}
                                            color="secondary"
                                            name="is_blank"
                                            onChange={(event) => updateAttributeProperties(property, event.target.name, event.target.checked, true)}
                                            disabled={!isEditable || (property.is_unique || property.primary_key)} />
                                    </TableCell>
                                    <TableCell align="center">
                                        <Switch checked={Boolean(property.is_unique)}
                                            color="secondary"
                                            name="is_unique"
                                            onChange={(event) => updateAttributeProperties(property, event.target.name, event.target.checked, true)}
                                            disabled={!isEditable || (property.primary_key)} />
                                    </TableCell>
                                    <TableCell className={classes.masterInputContainer}>
                                        <TooltipComponent arrow title={property.master && property.master.value ? property.master.value : ''}>
                                            <AutoComplete
                                                name="master"
                                                options={semanticModelAttributes}
                                                value={property && property.master && property.master.value ? property.master.value : ''}
                                                getOptionLabel={
                                                    (option) => {
                                                        if (option &&
                                                            (option.source_name && option.source_name !== '') &&
                                                            (option.name && option.name !== '')
                                                        ) {
                                                            return `${option.source_name}.${option.name}`;
                                                        }
                                                        if (option && option.qualified_name) {
                                                            return option.qualified_name;
                                                        }
                                                        return option;
                                                    }
                                                }
                                                getOptionSelected={(option, value) => option.qualified_name === value || option.qualified_name === `${option.source_name}.${option.name}`}
                                                renderInput={(params) => <TextField {...params} fullWidth className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey, classes.tableInputEllip)} margin="normal" placeholder="Master Attribute" />}
                                                onChange={
                                                    (_, selectedValue) => updateAttributeProperties(property, "master", {
                                                        on: Boolean(selectedValue),
                                                        value: selectedValue && selectedValue.qualified_name ? selectedValue.qualified_name : '',
                                                        attribute: selectedValue ? { ...selectedValue } : {}
                                                    }, true)
                                                }
                                                openOnFocus
                                                blurOnSelect
                                                fullWidth
                                                forcePopupIcon={false}
                                                disabled={!isEditable}
                                                noOptionsText={"No attributes"}
                                                style={{ width: `${property?.master?.value?.length * 11}px`, minWidth: '100%' }}
                                            />
                                        </TooltipComponent>
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton disabled={!isEditable} onClick={() => updateAttributeProperties(property, 'primary_key', !property.primary_key, true)} className={classes.iconButton}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 16 17.044">
                                                <defs />
                                                <g transform="translate(-13.899)">
                                                    <path fill={property.primary_key ? theme.palette.primary.main : "#e1e5e6"} d="M21.078,12.848,19.87,11.639l4.158-4.159A3.988,3.988,0,1,0,22.418,5.87l-8.187,8.188a1.139,1.139,0,0,0,1.611,1.611l1.208,1.208a.569.569,0,0,0,.806,0l.806-.805a.569.569,0,0,0,0-.805l-1.208-1.208.805-.808,1.209,1.211a.569.569,0,0,0,.806,0l.805-.805A.572.572,0,0,0,21.078,12.848ZM24.7,2.779a1.71,1.71,0,1,1,0,2.416A1.711,1.711,0,0,1,24.7,2.779Z" transform="translate(0)" />
                                                </g>
                                            </svg>
                                        </IconButton>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Grid container direction="row" wrap="nowrap" justify="space-around">
                                            <IconButton disabled={!isEditable} onClick={() => (isEditable ? (property.report === 'dimension' ? updateAttributeProperties(property, 'report', '', true) : updateAttributeProperties(property, 'report', 'dimension', true)) : null)} className={classes.iconButton}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className={classes.reportTypeImg} width="20" height="20" viewBox="0 0 20 20" style={{ fill: property && property.report === 'dimension' ? theme.palette.primary.main : "#e1e5e6", marginRight: "8px" }}>
                                                    <defs />
                                                    <path className="a" d="M10,0,0,2.5V15l10,5,10-5V2.5Zm8,3.25L10.625,6,2.375,3.25,10,1.25l8,2Zm-16.75,11V4.125l8.75,3v11.5Z" />
                                                </svg>
                                            </IconButton>
                                            <IconButton disabled={!isEditable} onClick={() => (isEditable ? (property.report === 'measure' ? updateAttributeProperties(property, 'report', '', true) : updateAttributeProperties(property, 'report', 'measure', true)) : null)} className={classes.iconButton}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className={classes.reportTypeImg} width="20" height="20" viewBox="0 0 22 15.555" style={{ fill: property && property.report === 'measure' ? theme.palette.primary.main : "#e1e5e6", marginRight: "8px" }}>
                                                    <g transform="translate(0 -150)">
                                                        <path className="a" d="M21.355,164.266h-.687V150.645a.644.644,0,0,0-.645-.645H17.445a.644.644,0,0,0-.645.645v13.621H15.512v-9.754a.644.644,0,0,0-.645-.645H12.289a.644.644,0,0,0-.645.645v9.754H10.355v-4.6a.644.644,0,0,0-.645-.645H7.133a.644.644,0,0,0-.645.645v4.6H5.2V157.09a.644.644,0,0,0-.645-.645H1.977a.644.644,0,0,0-.645.645v7.176H.645a.645.645,0,1,0,0,1.289H21.355a.645.645,0,1,0,0-1.289Zm0,0" transform="translate(0 0)" />
                                                    </g>
                                                </svg>
                                            </IconButton>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                                            <Slider name="sensitivity"
                                                color="secondary"
                                                className={classes.SensitivityMuiSlider}
                                                style={{ width: '95%' }}
                                                defaultValue={1}
                                                track={'normal'}
                                                value={property && property.sensitivity ? property.sensitivity : 1}
                                                onChange={(_event, value) => updateAttributeProperties(property, 'sensitivity', value, true)}
                                                marks
                                                min={1}
                                                max={3}
                                                step={1}
                                                disabled={!isEditable}
                                            />
                                            <Typography variant="body2" className={classes.sliderValue}>
                                                {property.sensitivity ? property.sensitivity : '1'}
                                            </Typography>
                                        </Grid>
                                    </TableCell>

                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </Grid >
    );
};

PropertiesPanel.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    properties: PropTypes.object,
    isEditable: PropTypes.bool,
    updateProperties: PropTypes.func,
    setSelectedAttribute: PropTypes.func
};

export default withStyles((theme) => ({
    ...PropertiesListViewStyles(theme),
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(PropertiesPanel);