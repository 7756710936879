import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Slide, withStyles } from '@material-ui/core';
import { appConstants } from '../../constants/appConstants';
import FileConnector from './FileConnector/FileConnector.jsx';
import ConnectorStyles from './ConnectorStyles.jsx';
import MSSQLConnector from './MSSQLConnector/MSSQLConnector.jsx';
import RestApiConnector from './RestApiConnector/RestApiConnector.jsx';
import SalesForceConnector from './SalesForceConnector/SalesForceConnector.jsx';
import ORACLEConnector from './ORACLEConnector/ORACLEConnector.jsx';
import SnowFlakeConnector from './SnowFlakeConnector/SnowFlakeConnector.jsx';
import PostgreSQLConnector from './PostgreSQLConnector/PostgreSQLConnector.jsx';
import SftpConnector from './SftpConnector/SftpConnector.jsx';
import MySQLConnector from './MySQLConnector/MySQLConnector.jsx';
import FTPConnector from './FTPConnector/FTPConnector.jsx';
import S3Connector from './S3Connector/S3Connector.jsx';
import DB2Connector from './DB2Connector/DB2Connector.jsx';
import HadoopConnector from './HadoopConnector/HadoopConnector.jsx';
import MongoDBConnector from './MongoDBConnector/MongoDBConnector.jsx';
import DeltaLakeConnector from './DeltaLakeConnector/DeltaLakeConnector.jsx';
import TableauConnector from './TableauConnector/TableauConnector.jsx';
import RedshiftConnector from './RedshiftConnector/RedshiftConnector.jsx';
import AzuredataLakeConnector from './AzuredataLakeConnector/AzuredataLakeConnector.jsx';


const Transition = React.forwardRef(Object.assign((props, ref) => <Slide direction="up" ref={ref} {...props} />), { displayName: 'Transition' });

const Connector = (props) => {
    const { classes, history, sourceType, isDialog, datasourceId, showDialog, onCloseDialog, onRefresh, copyConfig } = props;
    if (!sourceType) {
        return null;
    }

    const onSuccess = (datasourceId, refresh) => {
        if (isDialog && onCloseDialog) {
            if (refresh && onRefresh) {
                onRefresh();
            }
            onCloseDialog();
            return;
        }
        history.push(`/catalog/${datasourceId}`);
    };

    const onCancel = (refresh) => {
        if (isDialog && onCloseDialog) {
            if (refresh && onRefresh) {
                onRefresh();
            }
            onCloseDialog();
            return;
        }
        history.push(`/catalog`);
    };

    const renderConnector = () => {
        switch (sourceType.name) {
            case appConstants.datasourceTypes.File:
                return <FileConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)} />;
            case appConstants.datasourceTypes.MSSQL:
                return <MSSQLConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    history={history}
                    copyConfig={copyConfig} />;
            case appConstants.datasourceTypes.DB2:
                return <DB2Connector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    history={history}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    copyConfig={copyConfig} />;
            case appConstants.datasourceTypes.MySQL:
                return <MySQLConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    history={history}
                    copyConfig={copyConfig} />;
            case appConstants.datasourceTypes.SalesForce:
                return <SalesForceConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    history={history}
                    copyConfig={copyConfig} />;
            case appConstants.datasourceTypes.FTP:
                return <FTPConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    history={history}
                    copyConfig={copyConfig} />;
            case appConstants.datasourceTypes.S3:
                return <S3Connector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    history={history}
                    copyConfig={copyConfig} />;
            case appConstants.datasourceTypes.RestAPI:
                return <RestApiConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)} />;
            case appConstants.datasourceTypes.Sftp:
                return <SftpConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    history={history}
                    copyConfig={copyConfig} />;
            case appConstants.datasourceTypes.ORACLE:
                return <ORACLEConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    history={history}
                    copyConfig={copyConfig} />;
            case appConstants.datasourceTypes.PostgreSQL:
                return <PostgreSQLConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    history={history}
                    copyConfig={copyConfig} />;
            case appConstants.datasourceTypes.Redshift:
                return <RedshiftConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    history={history}
                    copyConfig={copyConfig} />;
            case appConstants.datasourceTypes.Snowflake:
                return <SnowFlakeConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    history={history}
                    copyConfig={copyConfig} />;
            case appConstants.datasourceTypes.Hadoop:
                return <HadoopConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    history={history}
                    copyConfig={copyConfig} />;
            case appConstants.datasourceTypes.MongoDB:
                return <MongoDBConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    history={history}
                    copyConfig={copyConfig} />;
            case appConstants.datasourceTypes.DeltaLake:
                return <DeltaLakeConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    history={history}
                    copyConfig={copyConfig} />;
            case appConstants.datasourceTypes.Tableau:
                return <TableauConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    history={history}
                    copyConfig={copyConfig} />;
            case appConstants.datasourceTypes.AzuredataLake:
                return <AzuredataLakeConnector datasourceType={sourceType}
                    datasourceId={datasourceId}
                    onCancel={(refresh) => onCancel(refresh)}
                    onConnect={(datasourceId, refresh) => onSuccess(datasourceId, refresh)}
                    history={history}
                    copyConfig={copyConfig} />;
            default:
                return null;
        }
    };

    if (isDialog) {
        return (
            <Dialog fullScreen
                classes={
                    {
                        paperFullScreen: classes.modalStyle
                    }
                }
                open={showDialog}
                disableEscapeKeyDown
                TransitionComponent={Transition}
                onClose={() => onCloseDialog && onCloseDialog()}>
                {renderConnector()}
            </Dialog>
        );
    }

    return renderConnector();
};

Connector.propTypes = {
    classes: PropTypes.object,
    sourceType: PropTypes.object,
    history: PropTypes.object,
    isDialog: PropTypes.bool,
    datasourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showDialog: PropTypes.bool,
    onCloseDialog: PropTypes.func,
    onRefresh: PropTypes.func,
    copyConfig: PropTypes.object
};

export default withStyles((theme) => ({
    ...ConnectorStyles(theme)
}))(Connector);