const DynamicElementByDataTypeStyles = (theme) => ({
    grossaryContainer: {
        padding: 16,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey.extraLight}`,
        boxShadow: '0px 0px 15px #0000000F',
        position: 'relative',
        '& .MuiTypography-body2': {
            color: theme.palette.grey.dark
        }
    },
    glossaryRow: {
        minWidth: '15%',
        maxWidth: 'max-content'
    },
    hidden: {
        visibility: 'hidden',
        height: 0,
        fontSize: 10
    },
    dragOverviewContainer: {
        marginBottom: 15
    }
});
export default DynamicElementByDataTypeStyles;