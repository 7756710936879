import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button, Grid, IconButton, Popover, TextField, Typography, withStyles } from '@material-ui/core';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import Styles from '../../layouts/Styles.jsx';
import { Close } from '@material-ui/icons';
import ConcatTransform from './ConcatTransform.jsx';
import TransformationsModalStyles from './TransformationsModalStyles.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import { applyTransformation, clearTransformation, previewTransformation } from '../../actions/dataPreparationActions.js';
import TransformationTypes from '../Common/TransformationTypes.jsx';
import AddTransform from './AddTransform.jsx';
import GroupByTransform from './GroupByTransform.jsx';
import FilterByTransform from './FilterByTransform.jsx';

const TransformationsModal = (props) => {
    const { classes, transformId, attributes, isOpen, onClose, sourceAttribute, anchorEl } = props;
    const dispatch = useDispatch();
    const [transformation, setTransformation] = useState(null);
    const [transformations] = useState([...TransformationTypes]);

    const closeModal = useCallback((isApplied = false) => {
        if (onClose) {
            onClose();
            setTransformation(null);
            dispatch(clearTransformation(transformation, isApplied));
        }
    }, [dispatch, onClose, transformation]);

    const submitTransformation = useCallback((transformation, isApply = false) => {
        if (!transformId) {
            return;
        }
        transformation['transformed_dataset_id'] = transformId;
        if (!transformation.output_attribute) {
            transformation['output_attribute'] = sourceAttribute && sourceAttribute.name ? `${sourceAttribute.name}_1` : '';
        }
        if (isApply) {
            setTimeout(() => dispatch(applyTransformation(transformation)), 10);
            closeModal(true);
        } else {
            setTimeout(() => dispatch(previewTransformation(transformation)).then((response) => {
                if (response) {
                    const element = document.getElementById('data-table');
                    if (element) {
                        element.scrollLeft = 0;
                    }
                }
            }), 10);
        }
    }, [transformId, sourceAttribute, closeModal, dispatch]);

    const onUpdateTransformation = useCallback((transformAttributes) => {
        const updatedTransformation = { ...transformation, ...transformAttributes };
        setTransformation(updatedTransformation);
        submitTransformation(updatedTransformation);
    }, [submitTransformation, transformation]);

    const renderTransformation = () => {
        switch (transformation && transformation.class_name.toLowerCase()) {
            case 'concat':
                return (
                    <ConcatTransform
                        transformation={transformation}
                        attributes={attributes ? [...attributes] : []}
                        onUpdateTransformation={(transformation) => onUpdateTransformation(transformation)}
                    />
                );
            case 'add':
            case 'subtract':
            case 'multiply':
            case 'divide':
                return (
                    <AddTransform
                        transformation={transformation}
                        attributes={attributes ? [...attributes] : []}
                        onUpdateTransformation={(transformation) => onUpdateTransformation(transformation)}
                    />
                );
            case 'groupby':
                return (
                    <GroupByTransform
                        transformation={transformation}
                        attributes={attributes ? [...attributes] : []}
                        onUpdateTransformation={(transformation) => onUpdateTransformation(transformation)}
                    />
                );
            case 'filterby':
                return (
                    <FilterByTransform
                        transformation={transformation}
                        attributes={attributes ? [...attributes] : []}
                        onUpdateTransformation={(transformation) => onUpdateTransformation(transformation)}
                    />
                );
            default:
                return null;
        }
    };


    return (
        <Popover
            anchorEl={anchorEl}
            open={isOpen}
            anchorOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }
            transformOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }
            classes={
                {
                    root: classes.transformationDialog,
                    paper: classes.transformationsModal
                }
            }
            onClose={() => closeModal()}>
            <Grid style={{ paddingBottom: 0 }} className={classes.transformationDialog}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6" className={classNames(classes.marginBottom5, classes.QueryfilterTitle)}>
                            {'Transformations'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton className={classes.nopadding} onClick={() => closeModal()}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid style={{ position: "relative" }}>
                    <ValidatorForm name="comments" onSubmit={() => submitTransformation(transformation, true)}>
                        <Grid container direction="column" justify="flex-start">
                            <Grid item xs={12}>
                                <Grid container className={classes.scrollContainer} direction="column">
                                    <Grid item>
                                        <AutoCompleteInput
                                            name="transformation"
                                            popperWidth={300}
                                            options={[...transformations]}
                                            value={transformation && transformation.name ? transformation.name : ''}
                                            getOptionLabel={
                                                (option) => {
                                                    if (option && option.name) {
                                                        return option.name;
                                                    }
                                                    return option;
                                                }
                                            }
                                            getOptionSelected={(option, value) => option.name === value}
                                            renderInput={
                                                (params) => <TextField {...params}
                                                    className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                                    placeholder="Select Transformation"
                                                    margin="normal" />
                                            }
                                            onChange={(_, selectedValue) => setTransformation(selectedValue)}
                                            openOnFocus
                                            blurOnSelect
                                            disableClearable
                                            fullWidth
                                            forcePopupIcon={false}
                                            noOptionsText={""}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {renderTransformation()}
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Grid container direction="row" justify="flex-end">
                                    <Grid item className={classes.btnContainer}>
                                        <Button variant="contained"
                                            type="submit"
                                            color="primary"
                                            style={{ marginRight: 20 }}
                                            className={classNames(classes.actionButton)}>
                                            {'Apply'}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button className={classNames(classes.cancelButton, classes.actionButton)}
                                            onClick={() => closeModal()}>
                                            {'Cancel'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ValidatorForm>
                </Grid>
            </Grid>
        </Popover>
    );
};

TransformationsModal.propTypes = {
    classes: PropTypes.object,
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    attributes: PropTypes.array,
    sourceAttribute: PropTypes.object,
    transformId: PropTypes.number,
    anchorEl: PropTypes.element
};

export default withStyles((theme) => ({
    ...TransformationsModalStyles(theme),
    ...Styles(theme)
}))(TransformationsModal);