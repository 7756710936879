/**
 * @name Fields.jsx
 * @description Define Add , Update , List and Delete Field Functionalities
 * @author Ashok
 * CreatedAt : 07-June-2021
 */

import React, { useState, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, withStyles, Typography, IconButton, MenuItem, Paper, Popover, Chip, FormHelperText, Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel } from '@material-ui/core';
import classNames from 'classnames';
import { ValidatorForm } from 'react-material-ui-form-validator';
import CloseIcon from '@material-ui/icons/Close';

// Import Components
import TextBox from '../TextBox/TextBox.jsx';
import IntegerInput from '../TextBox/IntegerInput.jsx';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import Search from '../TextBox/Search.jsx';
import Loader from '../Loaders/Loader.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';
import ChipInput from '../../components/ChipInput/ChipInput.jsx';
import { sortTable } from '../../helpers/appHelpers.js';

// Import Redux Actions and Global Constants
import { getAllFields, createField, updateField, deleteField, getCustomTabs, getCustomGroups } from '../../actions/settingActions';
import { appConstants } from '../../constants/appConstants';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import FieldSelect from '../FieldSelect/FieldSelect.jsx';

const Fields = (props) => {

    // Get Props
    const { classes, theme, organizationId } = props;
    const datatype = appConstants.commonDataTypes;
    const [tabs] = useState([...appConstants.customFieldTabs]);

    // Set State
    const [searchKey, setSearchKey] = useState('');
    const sortBy = {
        order: 1,
        by: 'display_order',
        value: 'display_order'
    };
    const [selectedField, setSelectedField] = useState(null);
    const [Field] = useState({
        name: '',
        data_type: '',
        dropdown_options: [],
        description: '',
        level: '',
        display_order: 1,
        custom_tab_name: "",
        custom_group_name: "",
        tab_name: ""
    });
    const [fields, setFields] = useState([{ ...Field }]);
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [dialogBox, setDialogBox] = useState(false);
    const [optionList, setOptionList] = useState(Field.dropdown_options);
    const [selectedFieldIndex, setSelectedFieldIndex] = useState();
    const [customTabs, setTabs] = useState([]);
    const [customGroups, setCustomGroups] = useState([]);
    const [duplicate, setDuplicate] = useState(false);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');

    const onChangeOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    /**
     * @name getAllFields
     * @description Gel All Fields data
     */
    const getAllField = useCallback(() => {
        setLoading(true);
        dispatch(getAllFields(organizationId, sortBy.value)).then((response) => {
            const data = response && response.length !== 0 ? [...response] : [{ ...Field }];
            setFields([...data]);
            setLoading(false);
        });
    }, [dispatch, organizationId, sortBy.value, Field]);

    const getTabs = useCallback(() => {
        dispatch(getCustomTabs(organizationId, "system")).then((response) => {
            if (response) {
                setTabs([...response]);
            }
        });
    }, [dispatch, organizationId]);

    const getGroups = useCallback(() => {
        dispatch(getCustomGroups(organizationId, "system")).then((response) => {
            if (response) {
                setCustomGroups([...response]);
            }
        });
    }, [dispatch, organizationId]);

    /**
     * @name useEffect
     * @description UseEffect run when page initialize and any updates happends
     */
    useEffect(() => {
        getTabs();
        getGroups();
        getAllField();
    }, [getAllField, getTabs, getGroups]);

    /**
     * @name addNewField
     * @description Add New Field for Organination
     */
    const addNewField = useCallback(() => {
        setOrderBy('');
        if (fields.length > 0 && fields[0].name.length <= 0) {
            return;
        }

        fields.splice(0, 0, { ...Field });
        setFields([...fields]);
        setOptionList(Field.dropdown_options);
    }, [Field, fields]);

    /**
     * @name onChangeSearch
     * @description Set Search Field Value in Search Key State
     */
    const onChangeSearch = useCallback((value) => {
        setSearchKey(value);
    }, []);

    const checkDuplicateTabName = useCallback((name) => {
        const tabList = [...tabs.map((tab) => tab.toLowerCase()), ...customTabs.map((tab) => tab.name.toLowerCase())];
        const index = tabList.indexOf(name.toLowerCase());
        return index !== -1;
    }, [customTabs, tabs]);

    const checkDuplicateGroupName = useCallback((name) => {
        const tabList = [...customGroups.map((tab) => tab.name.toLowerCase())];
        const index = tabList.indexOf(name.toLowerCase());
        return index !== -1;
    }, [customGroups]);

    /**
     * @name onChange
     * @description Filter Fields by SearchKey State
     */
    const onChange = useCallback((index, property, value, id) => {
        setTimeout(() => {
            if (property === "custom_tab_name" || property === "custom_group_name") {
                const duplicate = (property === "custom_tab_name") ? checkDuplicateTabName(value) : checkDuplicateGroupName(value);
                if (duplicate) {
                    setDuplicate(true);
                } else {
                    setDuplicate(false);
                }
            }
        }, 1000);
        const fieldId = fields.map((field) => field.id);
        const selectedFieldIndex = fieldId.indexOf(id);
        if (selectedFieldIndex !== -1) {
            const fieldValueByIndex = fields[selectedFieldIndex];
            fieldValueByIndex[property] = value;
            fields.splice(selectedFieldIndex, 1, { ...fieldValueByIndex });
            setFields([...fields]);
        }
    }, [checkDuplicateGroupName, checkDuplicateTabName, fields]);

    /**
     * @name saveField
     * @description Save Field
     */
    const saveField = useCallback((obj, index) => {
        if (!obj || (obj && !obj.name)) {
            return;
        }
        if (tabs.indexOf(obj.tab_name) === -1) {
            obj['organization_id'] = organizationId;

            if (!obj.id) {
                fields[index].isLoading = true;
                setFields([...fields]);
                dispatch(createField(obj)).then((response) => {
                    fields[index].isLoading = false;
                    if (!response) {
                        return;
                    }
                    const updatedObj = fields[index];
                    updatedObj.id = response.id;
                    fields.splice(index, 1, { ...updatedObj });
                    setFields([...fields]);
                });
            } else {
                if (duplicate) {
                    return;
                }
                dispatch(updateField(obj))
                    .then(() => { getTabs(); getGroups(); });
            }
        }
    }, [dispatch, duplicate, fields, getGroups, getTabs, organizationId, tabs]);

    const onSaveCustomTab = useCallback((index, property, value, id) => {
        const fieldId = fields.map((field) => field.id);
        const selectedFieldIndex = fieldId.indexOf(id);
        const fieldValueByIndex = fields[selectedFieldIndex];
        fieldValueByIndex[property] = value;
        if (property === "tab_name" && value !== "") {
            const tabExist = customTabs.some((tab) => tab.name.toLowerCase() === value.toLowerCase());
            if (!tabExist) {
                customTabs.push({ name: value });
                setTabs([...customTabs]);
            }
        } else if (property === "group_name" && value !== "") {
            const groupExist = customGroups.some((tab) => tab.name.toLowerCase() === value.toLowerCase());
            if (!groupExist) {
                customGroups.push({ name: value });
                setCustomGroups([...customGroups]);
            }
        }
        fieldValueByIndex.custom_tab_name = "";
        fieldValueByIndex.custom_group_name = "";
        fields.splice(selectedFieldIndex, 1, { ...fieldValueByIndex });
        setFields([...fields]);
        saveField(fieldValueByIndex, selectedFieldIndex);
    }, [customGroups, customTabs, fields, saveField]);

    /**
     * @name onChange
     * @description Filter Fields by SearchKey State
     */
    const onChangeSelectBox = useCallback((index, property, value, id) => {
        const fieldId = fields.map((field) => field.id);
        const selectedFieldIndex = fieldId.indexOf(id);
        const fieldValueByIndex = fields[selectedFieldIndex];
        fieldValueByIndex[property] = value;
        fields.splice(selectedFieldIndex, 1, { ...fieldValueByIndex });
        setFields([...fields]);
        saveField(fieldValueByIndex, selectedFieldIndex);
    }, [fields, saveField]);

    /**
     * @name deleteSelectedField
     * @description Set Delete Field Object in State
     * @param {*} obj
     * @param {*} index
     * @returns
     */
    const deleteSelectedField = (obj, index) => {
        if (index === 0 && obj && obj.name.length <= 0) {
            return;
        }
        setSelectedField(obj);
    };

    /**
     * @name removeField
     * @description Remove Selected Field in Local and Database
     */
    const removeField = useCallback((obj) => {
        const index = fields.indexOf(obj);
        if (index > -1) {
            fields.splice(index, 1);
            setFields([...fields]);
            setSelectedField(null);
            if (obj.id) {
                dispatch(deleteField(obj.id))
                    .then(() => { getTabs(); getGroups(); })
                    ;
            }
        }
    }, [dispatch, fields, getGroups, getTabs]);

    // function to add new option for dropdown
    const addNewOption = () => {
        const emptyList = optionList.filter((value) => value === '');
        if (emptyList.length === 0) {
            optionList.push('');
            setOptionList([...optionList]);
        }
    };

    const onSubmitValue = (event, index) => {
        optionList[index] = event.target.value;
        setOptionList([...optionList]);
    };

    // function to open popup for dropdown
    const openPopUp = (event, index, id) => {
        const fieldId = fields.map((field) => field.id);
        const selectedFieldIndex = fieldId.indexOf(id);
        setSelectedFieldIndex(selectedFieldIndex);
        const fieldValueByIndex = fields[selectedFieldIndex];
        const fieldOption = fieldValueByIndex.dropdown_options;
        if (fieldOption.length === 0) {
            setOptionList(['']);
        }
        else {
            setOptionList([...fieldOption]);
        }
        setDialogBox(true);
    };

    const onCloseDialogBox = () => {
        setDialogBox(null);
        if (optionList[0] === "") {
            return;
        }
        const fieldValueByIndex = fields[selectedFieldIndex];
        fieldValueByIndex.dropdown_options = optionList;
        saveField(fieldValueByIndex, selectedFieldIndex);
    };

    const deleteOption = (index, id) => {
        const fieldId = fields.map((field) => field.id);
        const selectedFieldIndex = fieldId.indexOf(id);
        const fieldValueByIndex = fields[selectedFieldIndex];
        const fieldOption = fieldValueByIndex.dropdown_options;
        fieldOption.splice(index, 1);
        setOptionList(fieldOption);
        saveField(fieldValueByIndex, selectedFieldIndex);
    };

    /**
     * Set Filter Field by Search Key
     */
    const selectedFields = searchKey !== "" ? fields.filter((item) => item.name.toLowerCase().includes(searchKey.toLowerCase())) : fields;

    return (
        <Fragment>
            <Grid container justify="flex-end" className={classes.marginBottom15}>
                <Grid item className={classes.search} >
                    <Search value={searchKey}
                        placeholder="Search Fields"
                        onChange={(value) => onChangeSearch(value)}
                        className={classNames(classes.dqSearchBoxRefList)} />
                </Grid>
                <Grid item>
                    <ToolTipComponent title="Add Fields" arrow>
                        <IconButton className={classes.marginLeft10} onClick={() => addNewField()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                    <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                        <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                        <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                    </g>
                                </g>
                            </svg>
                        </IconButton>
                    </ToolTipComponent>
                </Grid>
            </Grid>

            <ValidatorForm onSubmit={() => { }}>
                <Table className={classNames(classes.standardTable)} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            {
                                appConstants && appConstants.fieldTableHeader &&
                                appConstants.fieldTableHeader.map((header, index) => {
                                    return (
                                        <TableCell key={index} sortDirection={orderBy === header.id ? order : false}>
                                            {
                                                header.sort === false ?
                                                    <Typography variant="body1" className={classes.tableHeader}>
                                                        {header.label}
                                                    </Typography> :
                                                    <TableSortLabel
                                                        active={orderBy === header.id}
                                                        direction={orderBy === header.id ? order : 'asc'}
                                                        onClick={() => onChangeOrder(header.id)}
                                                    >
                                                        <Typography variant="body1" className={classes.tableHeader}>
                                                            {header.label}
                                                        </Typography>
                                                    </TableSortLabel>
                                            }

                                        </TableCell>
                                    );
                                })
                            }
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {
                            selectedFields && sortTable(selectedFields, order, orderBy).map((Field, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Grid>
                                                <ToolTipComponent title={Field.name ? Field.name : ''} arrow>
                                                    <Grid>
                                                        <TextBox
                                                            placeholder="Field Name"
                                                            value={Field.name ? Field.name : ''}
                                                            className={classNames(classes.inlinetxt, classes.inputHoverBg, classes.referenceInput, classes.tableInputEllip)}
                                                            name="name"
                                                            onChange={(event) => onChange(index, event.target.name, event.target.value, Field.id)}
                                                            onBlur={() => (!checkDuplicateTabName(Field.custom_tab_name ? Field.custom_tab_name : '') ? saveField(Field, index) : null)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </ToolTipComponent>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid key={index}>
                                                {
                                                    !Field.isLoading &&
                                                    <ToolTipComponent title={Field.data_type ? Field.data_type : ''} arrow>
                                                        <Grid>
                                                            <TextBox
                                                                placeholder="Data Type"
                                                                value={Field.data_type ? Field.data_type : ''}
                                                                select
                                                                name="data_type"
                                                                onChange={(event) => onChangeSelectBox(index, event.target.name, event.target.value, Field.id)}
                                                                className={classNames(classes.inlinetxt, classes.referenceInput, classes.tableInputEllip)}
                                                                fullWidth
                                                                SelectProps={
                                                                    {
                                                                        MenuProps: {
                                                                            anchorOrigin: {
                                                                                vertical: "bottom",
                                                                                horizontal: "center"
                                                                            },
                                                                            transformOrigin: {
                                                                                vertical: "top",
                                                                                horizontal: "center"
                                                                            },
                                                                            getContentAnchorEl: null
                                                                        }
                                                                        // IconComponent: () => DropDownIcon()
                                                                    }
                                                                }
                                                                disabled={!Field.id}
                                                            >
                                                                {
                                                                    datatype.map((type, index) =>
                                                                        <MenuItem key={`key_${index}`} value={type} className={classes.referenceMenu}>
                                                                            {type}
                                                                        </MenuItem>
                                                                    )
                                                                }
                                                            </TextBox>
                                                        </Grid>
                                                    </ToolTipComponent>
                                                }
                                                {Field.isLoading && <Loader size={'small'} type={'button'} classList={(classes.btnLoader, classes.buttonLoader)} />}
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid>
                                                {
                                                    (Field.data_type === "DropDown") ?
                                                        <ChipInput
                                                            values={Field.dropdown_options ? Field.dropdown_options : ''}
                                                            displayCount={1}
                                                            name="options"
                                                            onClickAdd={(event) => openPopUp(event, index, Field.id)}
                                                            chipSize={60}
                                                            onClickEdit={() => { return false; }}
                                                            onDelete={(index) => deleteOption(index, Field.id)}
                                                            isDeletePermission
                                                            isEditPermission /> :
                                                        <span className={classes.disabledtxt}>
                                                            {"NA"}
                                                        </span>
                                                }
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid>
                                                {
                                                    !Field.isLoading &&
                                                    <ToolTipComponent title={Field.description ? Field.description : ''} arrow>
                                                        <Grid>
                                                            <TextBox
                                                                placeholder="Description"
                                                                value={Field.description ? Field.description : ''}
                                                                className={classNames(classes.inlinetxt, classes.inputHoverBg, classes.referenceInput, classes.tableInputEllip)}
                                                                name="description"
                                                                onChange={(event) => onChange(index, event.target.name, event.target.value, Field.id)}
                                                                onBlur={() => (!checkDuplicateTabName(Field.custom_tab_name ? Field.custom_tab_name : '') ? saveField(Field, index) : null)}
                                                                fullWidth
                                                                disabled={!Field.id} />
                                                        </Grid>
                                                    </ToolTipComponent>
                                                }
                                                {Field.isLoading && <Loader size={'small'} type={'button'} classList={(classes.btnLoader, classes.buttonLoader)} />}
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid>
                                                {
                                                    !Field.isLoading &&
                                                    <ToolTipComponent title={Field.level ? Field.level : ''} arrow>
                                                        <Grid>
                                                            <TextBox
                                                                placeholder="Level"
                                                                value={Field.level ? Field.level : ''}
                                                                select
                                                                name="level"
                                                                onChange={(event) => onChangeSelectBox(index, event.target.name, event.target.value, Field.id)}
                                                                className={classNames(classes.inlinetxt, classes.referenceInput, classes.tableInputEllip)}
                                                                fullWidth
                                                                SelectProps={
                                                                    {
                                                                        MenuProps: {
                                                                            anchorOrigin: {
                                                                                vertical: "bottom",
                                                                                horizontal: "center"
                                                                            },
                                                                            transformOrigin: {
                                                                                vertical: "top",
                                                                                horizontal: "center"
                                                                            },
                                                                            getContentAnchorEl: null
                                                                        }
                                                                        // IconComponent: () => DropDownIcon()
                                                                    }
                                                                }
                                                                disabled={!Field.id}
                                                            >

                                                                <MenuItem value="Attribute" className={classes.referenceMenu}>
                                                                    {'Attribute'}
                                                                </MenuItem>
                                                                <MenuItem value="DataSet" className={classes.referenceMenu}>
                                                                    {'DataSet'}
                                                                </MenuItem>
                                                                <MenuItem value="DataSource" className={classes.referenceMenu}>
                                                                    {'DataSource'}
                                                                </MenuItem>
                                                            </TextBox>
                                                        </Grid>
                                                    </ToolTipComponent>
                                                }
                                                {Field.isLoading && <Loader size={'small'} type={'button'} classList={(classes.btnLoader)} />}
                                            </Grid>
                                        </TableCell>
                                        <TableCell width={100}>
                                            <Grid>
                                                {
                                                    !Field.isLoading &&
                                                    <IntegerInput
                                                        placeholder="Display Order"
                                                        name="display_order"
                                                        type={'number'}
                                                        className={classes.inputHoverBg}
                                                        value={Field.display_order ? Field.display_order : ''}
                                                        onChange={(event) => onChange(index, event.target.name, event.target.value, Field.id)}
                                                        onBlur={() => (!checkDuplicateTabName(Field.custom_tab_name ? Field.custom_tab_name : '') ? saveField(Field, index) : null)}
                                                        fullWidth
                                                        disabled={!Field.id}
                                                    />
                                                }
                                                {Field.isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid>
                                                {
                                                    !Field.isLoading &&
                                                    <Grid>
                                                        {
                                                            Field.tab_name && Field.tab_name !== "" ?
                                                                <Grid style={{ display: 'flex', alignItems: 'center', flexWrap: "wrap" }}>
                                                                    <Grid className={classes.chipList}>
                                                                        <ToolTipComponent title={Field.tab_name} arrow>
                                                                            <Chip
                                                                                className={classes.chip}
                                                                                label={(Field.tab_name.length > 10) ? Field.tab_name.slice(0, 10) + "..." : Field.tab_name}
                                                                            />
                                                                        </ToolTipComponent>
                                                                        <IconButton onClick={() => onSaveCustomTab(index, "tab_name", "", Field.id)} className={classNames(classes.nopadding, 'chipremove')}>
                                                                            <CloseIcon className={classNames(classes.chipIcon, classes.closeIcon)} />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                                :
                                                                <Fragment>
                                                                    <FieldSelect
                                                                        placeholder="Tab Name"
                                                                        displayValue="name"
                                                                        onBlur={(value) => (value !== "" && !checkDuplicateTabName(Field.custom_tab_name ? Field.custom_tab_name : '') ? onSaveCustomTab(index, "tab_name", value, Field.id) : null)}
                                                                        inputValue={Field.custom_tab_name ? Field.custom_tab_name : ""}
                                                                        onInputValueChange={(value) => onChange(index, "custom_tab_name", value, Field.id)}
                                                                        onSelectDomain={(value) => (!checkDuplicateTabName(Field.name ? Field.name : '') ? onSaveCustomTab(index, "tab_name", value.name, Field.id) : null)}
                                                                        availableList={[...sortTable(customTabs, "asc", "name")]} />
                                                                    {
                                                                        checkDuplicateTabName(Field.custom_tab_name ? Field.custom_tab_name : '') &&
                                                                        <FormHelperText error>
                                                                            {checkDuplicateTabName(Field.custom_tab_name ? Field.custom_tab_name : '') ? "Name not allowed" : ""}
                                                                        </FormHelperText>
                                                                    }
                                                                </Fragment>

                                                        }
                                                    </Grid>
                                                }
                                                {Field.isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid>
                                                {
                                                    !Field.isLoading &&
                                                    <Grid>
                                                        {
                                                            Field.group_name && Field.group_name !== "" ?
                                                                <Grid style={{ display: 'flex', alignItems: 'center', flexWrap: "wrap" }}>
                                                                    <Grid className={classes.chipList}>
                                                                        <Chip
                                                                            className={classes.chip}
                                                                            label={Field.group_name}
                                                                        />
                                                                        <IconButton onClick={() => onSaveCustomTab(index, "group_name", "", Field.id)} className={classNames(classes.nopadding, 'chipremove')}>
                                                                            <CloseIcon className={classNames(classes.chipIcon, classes.closeIcon)} />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>
                                                                :
                                                                <Fragment>
                                                                    <FieldSelect
                                                                        placeholder="Group Name"
                                                                        displayValue="name"
                                                                        onBlur={(value) => (value !== "" && !checkDuplicateGroupName(Field.custom_group_name ? Field.custom_group_name : '') ? onSaveCustomTab(index, "group_name", value, Field.id) : null)}
                                                                        inputValue={Field.custom_group_name ? Field.custom_group_name : ""}
                                                                        onInputValueChange={(value) => onChange(index, "custom_group_name", value, Field.id)}
                                                                        onSelectDomain={(value) => (!checkDuplicateGroupName(Field.name ? Field.name : '') ? onSaveCustomTab(index, "group_name", value.name, Field.id) : null)}
                                                                        availableList={[...customGroups]} />
                                                                    {
                                                                        checkDuplicateGroupName(Field.custom_group_name ? Field.custom_group_name : '') &&
                                                                        <FormHelperText error>
                                                                            {checkDuplicateGroupName(Field.custom_group_name ? Field.custom_group_name : '') ? "Name not allowed" : ""}
                                                                        </FormHelperText>
                                                                    }
                                                                    {
                                                                        !checkDuplicateGroupName(Field.custom_group_name ? Field.custom_group_name : '') &&
                                                                        <FormHelperText error>
                                                                            * Required
                                                                        </FormHelperText>
                                                                    }
                                                                </Fragment>

                                                        }
                                                    </Grid>
                                                }
                                                {Field.isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid>
                                                {
                                                    !Field.isLoading &&
                                                    <ToolTipComponent title="Delete" arrow>
                                                        <IconButton className={classNames(classes.referenceIcon, classes.paddingZero)} onClick={() => deleteSelectedField(Field, index)}>
                                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 32 32">
                                                                <g id="Delete">
                                                                    <path fill={theme.palette.primary.main}
                                                                        stroke={theme.palette.primary.main}
                                                                        strokeWidth="0.92"
                                                                        strokeMiterlimit="10"
                                                                        d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                                                    <path fill={theme.palette.primary.main}
                                                                        stroke={theme.palette.primary.main}
                                                                        strokeWidth="0.92"
                                                                        strokeMiterlimit="10"
                                                                        d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                                </g>
                                                            </svg>
                                                        </IconButton>
                                                    </ToolTipComponent>
                                                }
                                                {Field.isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        }
                    </TableBody>
                </Table>
                {
                    dialogBox &&
                    <Popover dialogBox={dialogBox}
                        open={Boolean(dialogBox)}
                        onClose={() => onCloseDialogBox()}
                        anchorOrigin={
                            {
                                vertical: 'center',
                                horizontal: 'center'
                            }
                        }
                        transformOrigin={
                            {
                                vertical: 'center',
                                horizontal: 'center'
                            }
                        }
                    >
                        {
                            <Paper elevation={2} className={classes.listContainer}>
                                <Grid container className={classes.popUp}>
                                    <Grid item>
                                        <ToolTipComponent title="Add Fields" arrow>
                                            <IconButton className={classes.marginLeft10} onClick={() => addNewOption()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                    <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                                        <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                                            <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                                            <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </IconButton>
                                        </ToolTipComponent>
                                    </Grid>
                                    <Grid item className={classes.popUp}>
                                        {
                                            optionList.map((value, index) =>
                                                <Grid key={`option_${index}`}>
                                                    <TextBox className={classes.padding5} value={value} onChange={(event) => onSubmitValue(event, index)} >
                                                        {value}
                                                    </TextBox>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Paper>
                        }
                    </Popover>
                }
            </ValidatorForm>
            {
                selectedFields.length === 0 && !isLoading &&
                <NoResultFound height="90%" />
            }
            {isLoading && <Loader />}

            <AlertDialog title={"Delete Field"}
                message="Are you sure to delete the selected Field?"
                okButtonText="OK"
                cancelButtonText="Cancel"
                show={selectedField !== null}
                onClickOK={
                    () => {
                        if (selectedField) {
                            removeField(selectedField);
                        } else {
                            setSelectedField(null);
                        }
                    }
                }
                onClickCancel={() => setSelectedField(null)} />

        </Fragment>
    );

};

Fields.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    organizationId: PropTypes.number
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Fields);