const TransformationTypes = [
    {
        "name": "Add",
        "class_name": "Add"
    },
    {
        "name": "Subtract",
        "class_name": "Subtract"
    },
    {
        "name": "Multiply",
        "class_name": "Multiply"
    },
    {
        "name": "Divide",
        "class_name": "Divide"
    },
    {
        "name": "Group By",
        "class_name": "GroupBy",
        "functions": [
            {
                'name': 'Sum',
                'class_name': 'Sum'
            },
            {
                'name': 'Count',
                'class_name': 'Count'
            },
            {
                'name': 'Avg',
                'class_name': 'Average'
            },
            {
                'name': 'Min',
                'class_name': 'Min'
            },
            {
                'name': 'Max',
                'class_name': 'Max'
            }
        ]
    }, {
        "name": "Concatenate",
        "class_name": "Concat"
    },
    {
        "name": "Filter By",
        "class_name": "FilterBy",
        "functions": [
            {
                'name': 'is equal to',
                'value': '=='
            },
            {
                'name': 'is not equal to',
                'value': '!='
            },
            {
                'name': 'is between',
                'value': ''
            },
            {
                'name': 'is greater than',
                'class_name': '>'
            },
            {
                'name': 'is less than',
                'class_name': '<'
            }
        ]
    }
];

export default TransformationTypes;