/* eslint-disable no-tabs */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import PropTypes from 'prop-types';
import theme from './theme';
import Routes from './routes/config';
import Alert from './components/Alert/Alert.jsx';
import { clearAlert, ssoAlert } from './actions/alertActions';
import { getUser } from './actions/accountActions';
import { history } from './config/history';
import { getCookie, setCookie, setThemePalette } from './helpers/appHelpers';
import './App.css';
import { getDefaultTypes, getCurationRules, getReferences, getConnectionTypes, updateJobStatus, getSourceNames } from './actions/datasourceActions';
import { getTheme, setThemeUI, getUpdateLicenseConnectionStatus, getPropertiesOrganization, getProperties } from './actions/settingActions';
import { updateActions, updateConversationList, getNotificationCount } from './actions/notificationActions';
import { getOrganizationAttributeList } from './actions/datasetActions';
// import SocketService from './services/socketService';

class AppComponent extends Component {

	constructor(props) {
		super(props);
		if (window.location.search && window.location.search.includes('token')) {
			setCookie('token', window.location.search.substr(7));
		}
		if (window.location.search && window.location.search.includes('errormsg')) {
			this.props.ssoAlert(window.location.search.substr(10).replaceAll("_", " "));
		}
		history.listen((location) => {
			return location;
		});

		if (!props.account.isLoggedIn && getCookie('token') !== "") {
			this.getUser();
		}
		this.state = {
			defaultTheme: props.theme,
			theme: theme
		};
	}

	static getDerivedStateFromProps(props, state) {
		let link = document.querySelector("link[rel~='icon']");
		if (!link) {
			link = document.createElement('link');
			link.rel = 'icon';
			document.getElementsByTagName('head')[0].appendChild(link);
		}
		link.href = props?.theme?.logo || 'dq_favicon.png';
		if (props.theme !== state.defaultTheme && props.theme) {
			let themePalette = setThemePalette(state.theme, props.theme);
			themePalette = createMuiTheme(themePalette);
			return {
				...state,
				defaultTheme: props.theme,
				theme: themePalette
			};

		}
		return { ...state };
	}

	getUser = () => {
		this.props.getUser().then((response) => {
			if (response) {
				this.getTheme(response.is_superuser ? 0 : response.organization);
				this.props.getPropertiesOrganization(response.organization ? response.organization : response.organization_id);
				this.props.getProperties(response.organization ? response.organization : response.organization_id);
				/*
				 * const socket = new SocketService();
				 * socket.getDispatch(
				 * {
				 * notification: this.props.updateActions,
				 * conversation: this.props.updateConversationList,
				 * jobStatusUpdate: this.props.updateJobStatus
				 * }
				 * );
				 * socket.getNotification();
				 * socket.getJobStatus();
				 * socket.getHistory(history);
				 * socket.receiveMessage();
				 */

			}
		});
	}

	componentDidMount() {
		if (!this.props.account.isLoggedIn && getCookie('token') !== "") {
			this.props.getDefaultTypes();
			if (!this.props.curationRules.length) {
				this.props.getCurationRules();
			}
			if (!this.props.references.length) {
				this.props.getReferences();
			}
			if (!this.props.connectionTypes.length) {
				this.props.getConnectionTypes();
			}
			if (!this.props.datasourceNames.length) {
				this.props.getSourceNames();
			}
			this.props.getNotificationCount();
			this.props.getUpdateLicenseConnectionStatus();
		}
		this.props.setThemeUI(this.props.theme);
		this.prepareTheme(this.props.theme);
		if (!this.props.account.isLoggedIn && getCookie('token') === "") {
			this.getTheme(0);
		}
	}

	prepareTheme = (palette, update = false) => {
		let theme = setThemePalette(this.state.theme, palette);
		theme = createMuiTheme(theme);
		this.setState({
			theme: createMuiTheme(theme)
		});
	}

	getTheme = (organizationId) => {
		this.props.getTheme(organizationId).then((response) => {
			if (response && Object.keys(response).length) {
				this.props.setThemeUI(response);
				this.prepareTheme(response);
			}
		});
	}


	render() {
		return (
			<MuiThemeProvider theme={this.state.theme}>
				<CssBaseline />
				<MuiPickersUtilsProvider utils={MomentUtils}>
					<Routes />
					<Alert message={this.props.alert.message} type={this.props.alert.type} onAlertClick={() => this.props.clearAlert()} />
				</MuiPickersUtilsProvider>
			</MuiThemeProvider>
		);
	}
}

AppComponent.propTypes = {
	alert: PropTypes.object,
	clearAlert: PropTypes.func,
	account: PropTypes.object,
	getUser: PropTypes.func,
	getDefaultTypes: PropTypes.func,
	getCurationRules: PropTypes.func,
	getReferences: PropTypes.func,
	references: PropTypes.array,
	curationRules: PropTypes.array,
	theme: PropTypes.object,
	getTheme: PropTypes.func,
	setThemeUI: PropTypes.func,
	connectionTypes: PropTypes.array,
	getConnectionTypes: PropTypes.func,
	/*
	 * updateActions: PropTypes.func,
	 * updateConversationList: PropTypes.func,
	 */
	getNotificationCount: PropTypes.func,
	// updateJobStatus: PropTypes.func,
	datasourceNames: PropTypes.array,
	getSourceNames: PropTypes.func,
	getUpdateLicenseConnectionStatus: PropTypes.func,
	getProperties: PropTypes.func,
	getPropertiesOrganization: PropTypes.func,
	ssoAlert: PropTypes.func

};

const mapStateToProps = ({ account, alert, datasource, setting, notification, dataset }) => {
	return {
		account,
		alert,
		...datasource,
		...setting,
		...notification,
		...dataset
	};
};

const mapDisptachToProps = (dispatch) => {
	return bindActionCreators({ getUser, clearAlert, getDefaultTypes, getCurationRules, getReferences, getTheme, setThemeUI, getConnectionTypes, updateActions, updateConversationList, getNotificationCount, updateJobStatus, getSourceNames, getOrganizationAttributeList, getUpdateLicenseConnectionStatus, getProperties, getPropertiesOrganization, ssoAlert }, dispatch);
};

export default withRouter(connect(mapStateToProps, mapDisptachToProps)(AppComponent));