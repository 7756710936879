import React, { useEffect, useCallback, useState } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Search from '../TextBox/Search.jsx';
import DomainKanbanView from './DomainKanbanView.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { getDomainSemanticRules, updateSemanticField } from '../../actions/domainActions';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import Loader from '../Loaders/Loader.jsx';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';

const DomainRules = (props) => {
    const { classes, domainId, history, redirectData, dq_score_threshold } = props;
    const [isLoading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [groupedAttributes, setGroupedAttributes] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const fieldTypes = useSelector(({ datasource }) => datasource.fieldTypes);
    const dispatch = useDispatch();

    const updateField = useCallback((attributeId, field) => {
        attributeId = attributeId ? parseInt(attributeId) : 0;
        field = field === "Others" ? "" : field;
        const model = {
            property: "fieldtype",
            value: field
        };
        dispatch(updateSemanticField(attributeId, model));
        const sourceAttributes = [...attributes];
        const index = sourceAttributes.findIndex((attribute) => attribute.attribute_id === attributeId);
        if (index !== -1) {
            sourceAttributes[index].fieldtype = field;
            setAttributes([...sourceAttributes]);
        }
    }, [attributes, dispatch]);

    const groupByFields = useCallback((attributes, type) => {
        let fields = attributes.map((attribute) => (attribute.fieldtype && attribute.fieldtype !== "" ? attribute.fieldtype : ""));
        if (type !== "filter") {
            const fieldList = fieldTypes.map((field) => field.name);
            fields = [...fields, ...fieldList, ""];
        }
        fields = [...new Set(fields)];
        const attributeData = fields.map((field) => {
            const attributeList = attributes.filter((attribute) => attribute.fieldtype === field);
            const attributeScore = attributeList.filter((attribute) => attribute.dqscore && attribute.dqscore > 0);
            const score = (attributeScore.reduce((a, b) => a + b.dqscore, 0)) / attributeScore.length;
            return {
                name: field,
                score: score ? score : 0,
                attributes: attributeList
            };
        });
        setGroupedAttributes([...attributeData]);
    }, [fieldTypes]);

    const applyFilter = useCallback((searchString) => {
        const filterAttributes = searchString.length > 0 ? attributes.filter((attribute) =>
            attribute.fieldtype.toLowerCase().includes(searchString.toLowerCase()) || attribute.attribute_name.toLowerCase().includes(searchString.toLowerCase()) || attribute.source_name.toLowerCase().includes(searchString.toLowerCase()) || attribute.dataset_name.toLowerCase().includes(searchString.toLowerCase())
        ) : attributes;
        groupByFields(filterAttributes, "filter");
    }, [attributes, groupByFields]);

    const onChangeSearch = useCallback((value) => {
        setSearch(value);
        applyFilter(value);
    }, [applyFilter]);


    const getRules = useCallback(() => {
        dispatch(getDomainSemanticRules(domainId)).then((response) => {
            if (response) {
                setLoading(false);
                setAttributes([...response]);
                groupByFields(response, "list");
            }
        });
    }, [dispatch, domainId, groupByFields]);


    useEffect(() => {
        getRules();
    }, [getRules]);

    const move = (source, destination, draggableId) => {
        const attributeList = [...groupedAttributes];
        const sourceCloneIndex = attributeList.findIndex((attribute) => attribute.name === (source.droppableId === "Others" ? "" : source.droppableId));
        const destinationCloneIndex = attributeList.findIndex((attribute) => attribute.name === (destination.droppableId === "Others" ? "" : destination.droppableId));
        const [removed] = attributeList[sourceCloneIndex].attributes.splice(source.index, 1);
        if (destinationCloneIndex !== -1) {
            attributeList[destinationCloneIndex].attributes.splice(destination.id, 0, removed);
            const destinationAttributes = attributeList[destinationCloneIndex].attributes && attributeList[destinationCloneIndex].attributes.length ? attributeList[destinationCloneIndex].attributes.filter((attribute) => attribute.dqscore && attribute.dqscore > 0) : [];
            const destinationDQscore = (destinationAttributes.reduce((a, b) => a + b.dqscore, 0)) / destinationAttributes.length;
            attributeList[destinationCloneIndex].score = destinationDQscore;
        }
        if (sourceCloneIndex !== -1) {
            const sourceAttributes = attributeList[sourceCloneIndex].attributes && attributeList[sourceCloneIndex].attributes.length ? attributeList[sourceCloneIndex].attributes.filter((attribute) => attribute.dqscore && attribute.dqscore > 0) : [];
            const sourceDQscore = (sourceAttributes.reduce((a, b) => a + b.dqscore, 0)) / sourceAttributes.length;
            attributeList[sourceCloneIndex].score = sourceDQscore;
        }
        const attributeId = draggableId ? draggableId.split("_")[0] : 0;
        updateField(attributeId, destination.droppableId === "Others" ? "" : destination.droppableId);
        return attributeList;
    };


    const dragAttribute = (dragHandler) => {
        const { source, destination, draggableId } = dragHandler;
        if (!destination) {
            return false;
        }
        let arrangedAttributes = [];
        if (source.droppableId !== destination.droppableId) {
            arrangedAttributes = move(source, destination, draggableId);
            setGroupedAttributes([...arrangedAttributes]);
        }

    };

    return (
        <Grid className={classNames(classes.relative, classes.height100p)}>
            <Grid align="right">
                <Search
                    value={search}
                    onChange={(value) => onChangeSearch(value)}
                    placeholder="Search Here"
                />
            </Grid>
            <DomainKanbanView
                redirectData={(params) => redirectData(params)}
                history={history}
                rules={groupedAttributes ? groupedAttributes : []}
                dragAttribute={(dragHandler) => dragAttribute(dragHandler)}
                dq_score_threshold={dq_score_threshold}
            />
            {
                isLoading &&
                <Loader />
            }
            {
                !isLoading && groupedAttributes.length === 0 &&
                <NoResultFound height="calc(100vh - 230px)" />
            }
        </Grid>
    );
};

DomainRules.propTypes = {
    classes: PropTypes.object,
    domainId: PropTypes.number,
    history: PropTypes.object,
    redirectData: PropTypes.func,
    dq_score_threshold: PropTypes.object
};

export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(DomainRules);