import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import { alertActions } from '../constants/actionTypes/alertActionTypes';
import { accountActions } from '../constants/actionTypes/accountActionTypes';
import * as service from '../services/apiService';
import { setCookie, removeCookie, getMenuPath, getCancellationToken } from '../helpers/appHelpers';
import { history } from '../config/history';
import { checkLicense, getConnectionTypes, getSourceNames } from './datasourceActions';
import { getOrganizationConfig, getTheme, setThemeUI, getLicenseModules, getLicenseInfo, logoutuser } from './settingActions';
import { getNotificationCount, navigatePage } from './notificationActions';
import { alerts } from '../config/alerts';

export const login = (requestParams) => {
	return (dispatch) => {
		dispatch(request(accountActions.LOGIN_REQUEST));
		requestParams.username = requestParams.username.toLowerCase();
		return service.postRequest(appConstants.END_POINTS.authenticate.login, requestParams).then((response) => {
			setCookie('token', response.token);
			localStorage.setItem('user', JSON.stringify(response.user));
			dispatch(success(accountActions.LOGIN_SUCCESS, response.user));
			dispatch(getOrganizationConfig()).then((config) => {
				if (config) {
					const menu = getMenuPath(config, response.user);
					dispatch(navigatePage(menu));
					/*
					 * history.push({
					 * pathname: response.user.last_login ? menu : '/welcome'
					 * });
					 */
					history.push({
						pathname: menu
					});
				}
			});
			dispatch(getLicenseModules(response.user.organization));
			dispatch(getLicenseInfo(response.user.organization));
			dispatch(checkLicense());
			dispatch(getOrganizationUser());
			dispatch(getConnectionTypes());
			dispatch(getNotificationCount());
			dispatch(getSourceNames());
			dispatch(getTheme(response.user.is_superuser ? 0 : response.user.organization)).then((response) => {
				if (response) {
					dispatch(setThemeUI(response));
				}
			});
			return response.user;
		}, (error) => {
			dispatch(failure(accountActions.LOGIN_FAILURE, error));
			dispatch(failure(alertActions.ALERT_ERROR, alerts.login));
		});
	};
};

export const loginldap = (requestParams) => {
	return (dispatch) => {
		dispatch(request(accountActions.LOGIN_REQUEST));
		requestParams.username = requestParams.username.toLowerCase();
		return service.postRequest(appConstants.END_POINTS.authenticate.loginldap, requestParams).then((response) => {
			setCookie('token', response.token);
			localStorage.setItem('user', JSON.stringify(response.user));
			dispatch(success(accountActions.LOGIN_SUCCESS, response.user));
			dispatch(getOrganizationConfig()).then((config) => {
				if (config) {
					const menu = getMenuPath(config, response.user);
					dispatch(navigatePage(menu));
					/*
					 * history.push({
					 * pathname: response.user.last_login ? menu : '/welcome'
					 * });
					 */
					history.push({
						pathname: menu
					});
				}
			});
			dispatch(getLicenseModules(response.user.organization));
			dispatch(getLicenseInfo(response.user.organization));
			dispatch(checkLicense());
			dispatch(getOrganizationUser());
			dispatch(getConnectionTypes());
			dispatch(getNotificationCount());
			dispatch(getSourceNames());
			dispatch(getTheme(response.user.is_superuser ? 0 : response.user.organization)).then((response) => {
				if (response) {
					dispatch(setThemeUI(response));
				}
			});
			return response.user;
		}, (error) => {
			dispatch(failure(accountActions.LOGIN_FAILURE, error));
			dispatch(failure(alertActions.ALERT_ERROR, alerts.login));
		});
	};
};

export const userActivation = (model) => {
	return (dispatch) => {
		dispatch(request(accountActions.USER_ACTIVATE_REQUEST));
		return service.postRequest(appConstants.END_POINTS.authenticate.userActivate, model).then((response) => {
				if (response.success === false) {
					dispatch(failure(accountActions.USER_ACTIVATE_FAILURE, response.message));
					dispatch(failure(alertActions.ALERT_ERROR, response.message));
				} else {
					service.postRequest(appConstants.END_POINTS.authenticate.login, { username: response.user.username.toLowerCase(), password: model.password }).then((response) => {
						setCookie('token', response.token);
						localStorage.setItem('user', JSON.stringify(response.user));
						history.push(`/catalog`);
						window.location.reload();
					});
				}
		}, (error) => {
			dispatch(failure(accountActions.USER_ACTIVATE_FAILURE, error));
			dispatch(failure(alertActions.ALERT_ERROR, error));
		});
	};
};

export const getUser = () => {
	const cancellationToken = getCancellationToken();
	return (dispatch) => {
		dispatch(request(accountActions.GET_USER_REQUEST));
		return service.getRequest(appConstants.END_POINTS.authenticate.getUser, cancellationToken).then((response) => {
			localStorage.setItem('user', JSON.stringify(response.data));
			dispatch(getOrganizationConfig()).then((config) => {
				if (config) {
					if (window.location.pathname === "/") {
						const menu = getMenuPath(config);
						dispatch(navigatePage(menu));
						/*
						 * history.push({
						 * pathname: response.data.last_login ? menu : '/welcome'
						 * });
						 */
						history.push({
							pathname: menu
						});
					}
				}
			});
			dispatch(getLicenseModules(response.data.organization));
			dispatch(checkLicense());
			dispatch(getOrganizationUser());
			dispatch(success(accountActions.GET_USER_SUCCESS, response.data));
			return response.data;
		}, (error) => {
			dispatch(failure(accountActions.GET_USER_FAILURE, error));
			dispatch(failure(alertActions.ALERT_ERROR, error));
		});
	};
};

export const forgotPassword = (requestParams) => {
	return (dispatch) => {
		dispatch(request(accountActions.GET_FORGOT_PASSWORD_REQUEST));
		return service.postRequest(appConstants.END_POINTS.authenticate.forgotPassword, requestParams).then((response) => {
			dispatch(success(accountActions.GET_FORGOT_PASSWORD_SUCCESS, response));
			if (response && response.status) {
				dispatch(success(alertActions.ALERT_SUCCESS, response.message));
				history.push({
					pathname: '/'
				});
			}
			else {
				dispatch(failure(alertActions.ALERT_ERROR, response.message));
			}
			return response;
		}, (error) => {
			dispatch(failure(accountActions.GET_FORGOT_PASSWORD_FAILURE, error));
			dispatch(failure(alertActions.ALERT_ERROR, error));
		});
	};
};

export const verifyResetPasswordToken = (token) => {
	const endpoint = `${appConstants.END_POINTS.authenticate.verifyResetToken}/${token}`;
	return (dispatch) => {
		dispatch(request(accountActions.GET_VERIFY_TOKEN_REQUEST));
		return service.getRequest(endpoint).then((response) => {
			dispatch(success(accountActions.GET_VERIFY_TOKEN_SUCCESS, response));
			if (response && !response.status) {
				dispatch(failure(alertActions.ALERT_ERROR, response.message));
				history.push({
					pathname: '/'
				});
			}
			return response;
		}, (error) => {
			dispatch(failure(accountActions.GET_VERIFY_TOKEN_FAILURE, error));
			dispatch(failure(alertActions.ALERT_ERROR, error));
		});
	};
};

export const resetPassword = (requestParams) => {
	return (dispatch) => {
		dispatch(request(accountActions.RESET_PASSWORD_REQUEST));
		return service.postRequest(appConstants.END_POINTS.authenticate.resetPassword, requestParams).then((response) => {
			dispatch(success(accountActions.RESET_PASSWORD_SUCCESS, response));
			if (response && response.status) {
				dispatch(success(alertActions.ALERT_SUCCESS, response.message));
				history.push({
					pathname: '/'
				});
			}
			else {
				dispatch(failure(alertActions.ALERT_ERROR, response.message));
			}
			return response;
		}, (error) => {
			dispatch(failure(accountActions.RESET_PASSWORD_FAILURE, error));
			dispatch(failure(alertActions.ALERT_ERROR, error));
		});
	};
};

export const getOrganizationUser = () => {
	const cancellationToken = getCancellationToken();
	return (dispatch) => {
		dispatch(request(accountActions.GET_ORGANIZATION_USERS_REQUEST));
		return service.getRequest(appConstants.END_POINTS.authenticate.getOrganizationUser, cancellationToken).then((response) => {
			dispatch(success(accountActions.GET_ORGANIZATION_USERS_SUCCESS, response.data));
			localStorage.setItem('users', JSON.stringify(response.data));
			return response.data;
		}, (error) => {
			dispatch(failure(accountActions.GET_ORGANIZATION_USERS_FAILURE, error));
		});
	};
};

export const logout = () => {
	return (dispatch) => {
		dispatch(logoutuser()).then((responsevalue) => {
			if (responsevalue) {
				removeCookie();
				localStorage.clear();
				dispatch(getTheme(0)).then((response) => {
					if (response) {
						dispatch(setThemeUI(response));
					}
				});
				history.push('/');
				dispatch(success(accountActions.LOGOUT));
			}
		});
	};
};

export const getUsers = (organizationId) => {
	const endpoint = `${appConstants.END_POINTS.authenticate.getUsers}/${organizationId}`;
	return (dispatch) => {
		dispatch(request(accountActions.GET_USERS_LIST_REQUEST));
		return service.getRequest(endpoint).then((response) => {
			dispatch(success(accountActions.GET_USERS_LIST_SUCCESS, response.data));
			return response.data;
		}, (error) => {
			dispatch(failure(accountActions.GET_USERS_LIST_FAILURE, error));
		});
	};
};

export const inviteUser = (requestParams) => {
	return (dispatch) => {
		dispatch(request(accountActions.INVITE_USER_REQUEST));
		return service.postRequest(appConstants.END_POINTS.authenticate.inviteUser, requestParams).then((response) => {
			dispatch(success(accountActions.INVITE_USER_SUCCESS, response.data));
			dispatch(success(alertActions.ALERT_SUCCESS, alerts.inviteUser));
			return response.data;
		}, (error) => {
			dispatch(failure(accountActions.INVITE_USER_FAILURE, error));
			dispatch(failure(alertActions.ALERT_ERROR, error));
		});
	};
};

export const updateUser = (userList) => {
	return {
		type: accountActions.UPDATE_USER,
		responseData: userList
	};
};

export const disableRolePermission = () => {
	return {
		type: accountActions.DISABLE_ROLE_PERMISSION
	};
};