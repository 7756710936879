import React, { useEffect } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';
import noprofile from '../../assets/images/np_profile_data.jpg';
import moment from 'moment-timezone';
import { getValue } from '../../helpers/appHelpers';


const MultiLineChart = (props) => {
    const { classes, chartData, chartClassName, colors, legends, height, tooltipLabel } = props;

    useEffect(() => {
        if (chartData && Object.keys(chartData).length) {
            const margin = { top: 30, right: 10, bottom: 0, left: 20 };
            chartData.dates = chartData.dates.map((date) => new Date(date));
            if (chartData.dates.length === 1) {
                const yesterday = new Date(chartData.dates[0]);
                yesterday.setDate(yesterday.getDate() - 1);
                chartData.dates.unshift(yesterday);
            }
            // DriftAlert LineChart code to show data on all lines in  chart on mouseHover
            chartData.series = chartData.series.map((data) => {
                return {
                    ...data,
                    count: data.values.reduce((value, a) => value + a, 0)
                };
            });
            chartData.series = chartData.series.sort((a, b) => b.count - a.count);

            let width = 300;
            let height = 400;

            d3.select(`.${chartClassName}_chart > *`).remove();
            d3.select(`.${chartClassName}_chart > svg`).remove();
            d3.select(`.${chartClassName} > .legend-container`).remove();
            d3.select(`.${chartClassName}_chart > .tooltip`).remove();

            const toolTip = d3.select(`.${chartClassName}_chart`).append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);


            const svg = d3.select(`.${chartClassName}_chart`)
                .append("svg")
                .attr('width', (_, i, p) => {
                    width = p[i].parentNode.clientWidth;
                    height = p[i].parentNode.clientHeight - margin.top;
                })
                .attr("class", `${chartClassName}_chart`)
                .attr("viewBox", [0, 0, width, height])
                .attr("overflow", "auto")
                .append("g");

            const innerWidth = width - margin.left;
            const x = d3
                .scaleUtc()
                .domain(d3.extent(chartData.dates))
                .range([30, width - margin.right]);

            const y = d3
                .scaleLinear()
                .domain([0, d3.max(chartData.series, (d) => d3.max(d.values))])
                .nice()
                .range([height - (margin.top * 2), margin.top]);

            svg.append('g').attr('class', 'x-axis').attr(
                'transform',
                `translate(0,${height - (margin.top + margin.left)})`)
                .call(d3
                    .axisBottom(x))
                .call((group) => group.select('.domain').remove())
                .call((group) => group.selectAll('line').remove())
                .call((group) => group.selectAll('text').style('fill', '#afb2b3'));


            svg.append('g').attr('class', 'y-axis').attr('transform', `translate(${margin.top},0)`)
                .call(d3.axisLeft(y).ticks(4).tickPadding(5).tickSize(-innerWidth))
                .call((group) => group.select('.domain').remove())
                .call((group) => group.selectAll('text').style('fill', '#afb2b3'))
                .call((group) => group.selectAll('line').style('stroke', '#afb2b3'));


            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
            const myColor = d3
                .scaleOrdinal()
                .domain(
                    chartData.series.map((val) => {
                        return val.name;
                    })
                )
                .range(colors);

            height -= (margin.top * 2);
            const line = d3
                .line()
                .defined((d) => !isNaN(d))
                .curve(d3.curveMonotoneX)
                .x((d, i) => x(chartData.dates[i]))
                .y((d) => y(d));

            const path = d3
                .area()
                .defined((d) => !isNaN(d))
                .curve(d3.curveMonotoneX)
                .x((d, i) => x(chartData.dates[i]))
                .y0(height)
                .y1((d) => y(d));


            const createPaths = (group) => {
                group
                    .selectAll('g')
                    .data(chartData.series)
                    .join('g')
                    .attr('fill', (d) => myColor(d.name))
                    .attr("fill-opacity", 0.1)
                    .attr('class', 'path')
                    .attr('id', (d) => `${myColor(d.name)}`);

                group
                    .selectAll('.path')
                    .append('path')
                    .data(chartData.series)
                    .attr("stroke", "#eee")
                    .attr("stroke-linejoin", "round")
                    .style('mix-blend-mode', 'multiply')
                    .attr('stroke-width', 2)
                    .attr('d', (d) => path(d.values))
                    .attr('fill', (d) => myColor(d.name));
            };
            const createLinePaths = (group) => {
                group
                    .selectAll('g')
                    .data(chartData.series)
                    .join('g')
                    .attr('fill', 'none')
                    .attr('class', 'line')
                    .attr('id', (d) => `${myColor(d.name)}`);

                group
                    .selectAll('.line')
                    .append('path')
                    .data(chartData.series)
                    .attr("stroke-linejoin", "round")
                    .attr('stroke-width', 2)
                    .attr('d', (d) => line(d.values))
                    .attr('stroke', (d) => myColor(d.name));

            };

            const createCircles = (group) => {
                group.selectAll('.line').each((d, i, p) => {
                    const data = d;
                    // Inner circle
                    d3.select(p[i])
                        .append('g')
                        .selectAll('circle')
                        .data(d.values)
                        .join('circle')
                        .attr('class', 'inner-circle')
                        .attr('name', d.name)
                        .attr('stroke', myColor(d.name))
                        .attr('fill', '#fff')
                        .attr("stroke-width", 1.5)
                        .attr('r', '4')
                        .attr('cy', (d) => y(d))
                        .attr('cx', 0)
                        // .attr('cx', (d, i) => x(chartData.dates[i]))
                        .on('mouseover', (d, i, nodes) => {
                            const rect = nodes[i].getBBox();
                            toolTip.transition()
                                .duration(200)
                                .style("opacity", 0.9);
                            let leftValue = x(chartData.dates[i]);
                            if (innerWidth < leftValue) {
                                leftValue -= 80;
                            }
                            toolTip.html(`Date : ${moment(chartData.dates[i]).format('MM-DD-YYYY')}<br> Name : ${data.name} <br> ${tooltipLabel} : ${getValue(data.values[i])}`)
                                .style("left", leftValue + "px")
                                .style("top", (rect.y - 30) + "px");
                        })
                        .on('mouseout', () => {
                            toolTip.transition()
                                .duration(500)
                                .style("opacity", 0);
                        })
                        .transition()
                        .delay((d, i) => { return Number(i); })
                        .duration(2000)
                        .attr('cx', (d, i) => x(chartData.dates[i]));
                });
            };

            svg
                .append('g')
                .attr('fill', 'none')
                .attr('id', 'path-group')
                .call(createPaths)
                .call(createLinePaths)
                .call(createCircles);

            if (legends.length > 0) {
                const legendsContainer = d3.select(`.${chartClassName}`).append("div")
                    .attr('class', 'legend-container');

                legendsContainer.selectAll('.legend-container')
                    .data(legends)
                    .enter()
                    .append("div")
                    .attr("class", "legend-column");

                legendsContainer.selectAll('.legend-column')
                    .data(legends)
                    .append("div")
                    .style('background-color', (d) => myColor(d))
                    .attr("class", "legend-pattern");

                legendsContainer.selectAll('.legend-column')
                    .data(legends)
                    .append("span")
                    .attr('class', 'legend-text')
                    .style('text-transform', 'capitalize')
                    .text((legend) => legend);
            }
        }

    }, [chartClassName, chartData, colors, legends, tooltipLabel]);

    return (
        <Grid style={{ padding: 20 }} className={classNames(classes.chartStyles, classes.widgetContainer, chartClassName)}>
            {
                chartData && (Object.keys(chartData).length === 0 || (chartData.dates.length === 0 && chartData.series.length === 0)) &&
                <img src={noprofile} alt="" style={{ maxWidth: '100%' }} />
            }
            <Grid className={classNames(`${chartClassName}_chart`, classes.chartContainer)} style={{ height: height - 80 }} />
        </Grid>
    );
};

MultiLineChart.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.object,
    chartClassName: PropTypes.string,
    colors: PropTypes.array,
    legends: PropTypes.array,
    height: PropTypes.number,
    tooltipLabel: PropTypes.string
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}))(MultiLineChart);