import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography, Table, TableRow, TableHead, TableCell, TableBody, TableContainer, TableSortLabel, TextField } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Styles from '../../layouts/Styles.jsx';
import MasterStyles from './MasterStyles.jsx';
import { getSemanticModelMappings } from '../../actions/masterActions';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import Loader from '../Loaders/Loader.jsx';
import { sortTable } from '../../helpers/appHelpers.js';
import searchIco from '../../assets/images/search.svg';
import classNames from 'classnames';

const MasterModelMappings = (props) => {
    const { classes, tabIndex, modelId, history } = props;
    const headers = [
        {
            label: 'Model Attribute',
            key: 'generic_attribute',
            isSortable: true
        },
        {
            label: 'Datasource Name',
            key: 'datasource_name',
            isSortable: true
        },
        {
            label: 'Dataset Name',
            key: 'dataset_name',
            isSortable: true
        },
        {
            label: 'Attribute Name',
            key: 'source_attribute_name',
            isSortable: true
        }
    ];
    const [mappings, setMappings] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const [search_text, setSearchText] = useState('');

    const dispatch = useDispatch();

    const onChangeSortOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    const getAllMappings = useCallback((search_key = '') => {
        dispatch(getSemanticModelMappings(modelId, search_key)).then((response) => {
            if (response) {
                setMappings([...response]);
            }
            setIsLoading(false);
        });
    }, [dispatch, modelId]);

    const onClickNavigate = useCallback((sourceId, datasetId) => {
        history.push({ pathname: `/dataset/${datasetId}`, state: { datasource: {}, datasourceId: sourceId } });
    }, [history]);

    const handleSearchSubmit = (_e) => {
        getAllMappings(search_text);
    };

    const onChangeSearch = (event) => {
        setSearchText(event.target.value);
        if (event.target.value === '') {
            getAllMappings();
        }
    };

    useEffect(() => {
        if (tabIndex !== 4) {
            return;
        }
        setIsLoading(true);
        getAllMappings();
    }, [tabIndex, getAllMappings]);

    return (
        <Grid container className={classes.mappingsContainer}>
            {
                mappings && mappings.length > 0 &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container direction="row" justify="flex-end">
                        <Grid item xl={3} lg={3} md={3} sm={3} xs={3} />
                    </Grid>
                    <Grid container direction="row" justify="flex-end">
                        <Grid item xs={3} align="left">
                            <Grid className={search_text ? classes.searchclear : null}>
                                <Grid className={classNames(classes.search, classes.listSearch, search_text ? classes.searchicon : null)}>
                                    <TextField className={classes.newInput} id="outlined-search" autoComplete="off" placeholder="Search Asset" value={search_text} type="text" onChange={(value) => onChangeSearch(value)} />
                                    <img src={searchIco} alt="" style={{ display: "none" }} onClick={handleSearchSubmit} width={35} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <TableContainer className={classes.mappingsTableContainer}>
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    {
                                        headers.map((header, index) => {
                                            return (
                                                <TableCell key={`mapping_header_${index}`}>
                                                    {
                                                        header.isSortable ?
                                                            <TableSortLabel active={orderBy === header.key}
                                                                direction={orderBy === header.key ? order : 'asc'}
                                                                onClick={() => onChangeSortOrder(header.key)}
                                                            >
                                                                <Typography variant="body1" className={classes.tableHeader}>
                                                                    {header.label}
                                                                </Typography>
                                                            </TableSortLabel>
                                                            :
                                                            <Typography variant="body1" className={classes.tableHeader}>
                                                                {header.label}
                                                            </Typography>
                                                    }
                                                </TableCell>
                                            );
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    sortTable(mappings, order, orderBy).map((mapping, index) => {
                                        return (
                                            <TableRow key={`mapping_row_${index}`}>
                                                <TableCell>
                                                    <Typography>
                                                        {mapping.name ? mapping.name : ''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {mapping.source_name ? mapping.source_name : ''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className={classes.anchorLink} onClick={() => onClickNavigate(mapping.source_id, mapping.dataset_id)}>
                                                        {mapping.dataset_name ? mapping.dataset_name : ''}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {mapping.attribute_name ? mapping.attribute_name : ''}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            }
            {isLoading && <Loader />}
            {
                mappings.length === 0 &&
                <Grid container direction="row" justify="flex-end">
                    <Grid item xs={3} align="left">
                        <Grid className={search_text ? classes.searchclear : null}>
                            <Grid className={classNames(classes.search, classes.listSearch, search_text ? classes.searchicon : null)}>
                                <TextField className={classes.newInput} id="outlined-search" autoComplete="off" placeholder="Search Asset" value={search_text} type="text" onChange={(value) => onChangeSearch(value)} />
                                <img src={searchIco} alt="" style={{ display: "none" }} onClick={handleSearchSubmit} width={35} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <NoResultFound />
                </Grid>
            }
        </Grid >
    );
};

MasterModelMappings.propTypes = {
    classes: PropTypes.object,
    modelId: PropTypes.string,
    tabIndex: PropTypes.number,
    history: PropTypes.object
};

export default withStyles((theme) => ({
    ...MasterStyles(theme),
    ...Styles(theme)
}))(MasterModelMappings);