import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';
import TextBox from '../TextBox/TextBox.jsx';
/*
 * import AuditLogs from '../AuditLogs/AuditLogs.jsx';
 * import Tags from '../Tags/Tags.jsx';
 */
import Permission from '../Permission/Permission.jsx';


const MasterDetail = (props) => {
    const { classes, model, governance, onChangeModel, isEditable, onSave, deleteMasterShare, createMasterShare, updateUser } = props;
    const users = useSelector(({ account }) => account.users);
    const user = useSelector(({ account }) => account.user);
    const dispatch = useDispatch();
    return (
        <Grid container>
            <Grid className={classes.card}>
                <Typography component="h5" variant="h5" >
                    Description
                </Typography>
                <TextBox
                    name="description"
                    placeholder="Enter Description"
                    multiline
                    value={model.description ? model.description : ''}
                    rows="3"
                    onChange={(event) => onChangeModel(event.target.name, event.target.value)}
                    onBlur={() => onSave('description')}
                    fullWidth
                    className={classNames(classes.inlinetxt, classes.descriptionTextBox)}
                    disabled={!isEditable}
                />
                {/* <Typography component="h5" variant="h5" className={classNames(classes.marginTop5, classes.marginBottom5)}>
                    Tags
                </Typography>
                <Tags tags={[]} disabled /> */}
            </Grid>
            <Grid className={classes.marginTop15}>
                <Permission
                    hideplatform
                    source={JSON.parse(JSON.stringify(model))}
                    isEditable={isEditable}
                    onChange={(name, value) => onChangeModel(name, value)}
                    onSave={(property) => onSave(property)}
                    users={users}
                    //   updateAuditLog={this.updateAuditLog}
                    createSourceShare={createMasterShare}
                    governance={governance ? governance : []}
                    sourceId={model.id ? model.id : 0}
                    // inviteSourceUser={this.props.inviteSourceUser}
                    dispatch={dispatch}
                    user={user}
                    type="master"
                    deleteShare={deleteMasterShare}
                    updateUser={updateUser}
                    hideAuditlog

                />

            </Grid>
        </Grid>
    );
};

MasterDetail.propTypes = {
    classes: PropTypes.object,
    model: PropTypes.object,
    governance: PropTypes.array,
    onChangeModel: PropTypes.func,
    isEditable: PropTypes.bool,
    onSave: PropTypes.func,
    deleteMasterShare: PropTypes.func,
    createMasterShare: PropTypes.func,
    updateUser: PropTypes.func
};

export default withStyles((theme) => ({
    // ...DatasourceListStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MasterDetail);