import { domainActions } from '../constants/actionTypes/domainActionTypes';

const initialState = {
    isLoading: false,
    rules: [],
    selectedDomainId: 0,
    actionItemCount: 0,
    config: { filters: {}, tabConfig: { view: "List" } },
    domainDatasources: { isLoading: false, data: [] },
    domainDatasets: { isLoading: false, data: [] },
    domainAttributes: { isLoading: false, data: [] },
    domainRules: { isLoading: false, data: [] }
};

export const domainReducer = (state = initialState, action) => {
    if (!action || (action && !action.type)) { return state; }
    switch (action.type) {

        case domainActions.GET_DOMAIN_DETAIL_REQUEST:
        case domainActions.GET_DOMAIN_DETAIL_SUCCESS:
        case domainActions.GET_DOMAIN_DETAIL_FAILURE:
            return {
                ...state
            };
        case domainActions.SELECT_DOMAIN_ID:
            return {
                ...state,
                selectedDomainId: action.domainId
            };
        case domainActions.SET_ACTION_ITEM_COUNT:
            return {
                ...state,
                actionItemCount: action.count
            };
        case domainActions.SET_FILTERS:
            return {
                ...state,
                config: { ...action.filters }
            };
        case domainActions.GET_DOMAIN_DATASOURCES_REQUEST:
            return {
                ...state,
                domainDatasources: { isLoading: true, data: [] }
            };
        case domainActions.GET_DOMAIN_DATASOURCES_SUCCESS:
            return {
                ...state,
                domainDatasources: { isLoading: false, data: action }
            };
        case domainActions.GET_DOMAIN_DATASOURCES_FAILURE:
            return {
                ...state,
                domainDatasources: { isLoading: false, data: [] }
            };
        case domainActions.GET_DOMAIN_DATASETS_REQUEST:
            return {
                ...state,
                domainDatasets: { isLoading: true, data: [] }
            };
        case domainActions.GET_DOMAIN_DATASETS_SUCCESS:
            return {
                ...state,
                domainDatasets: { isLoading: false, data: action }
            };
        case domainActions.GET_DOMAIN_DATASETS_FAILURE:
            return {
                ...state,
                domainDatasets: { isLoading: false, data: [] }
            };
        case domainActions.GET_DOMAIN_ATTRIBUTES_REQUEST:
            return {
                ...state,
                domainAttributes: { isLoading: true, data: [] }
            };
        case domainActions.GET_DOMAIN_ATTRIBUTES_SUCCESS:
            return {
                ...state,
                domainAttributes: { isLoading: false, data: action }
            };
        case domainActions.GET_DOMAIN_ATTRIBUTES_FAILURE:
            return {
                ...state,
                domainAttributes: { isLoading: false, data: [] }
            };
        case domainActions.GET_DOMAINQ_RULES_REQUEST:
            return {
                ...state,
                domainRules: { isLoading: true, data: [] }
            };
        case domainActions.GET_DOMAINQ_DATASOURCES_SUCCESS:
            return {
                ...state,
                domainRules: { isLoading: false, data: action }
            };
        case domainActions.GET_DOMAINQ_DATASOURCES_FAILURE:
            return {
                ...state,
                domainRules: { isLoading: false, data: [] }
            };
        default:
            return state;
    }
};