import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import SidePanelStyles from './SidePanelStyles.jsx';
import { useSelector } from 'react-redux';
import ActionList from '../ActionItem/ActionList.jsx';
import ConversationList from '../Conversation/ConversationList.jsx';
import Comments from '../Comments/Comments.jsx';

const SidePanel = (props) => {
    const { classes, history } = props;
    const panelType = useSelector(({ notification }) => notification.type);
    const datasetId = useSelector(({ dataset }) => dataset.selectedDatasetId);
    const datasourceId = useSelector(({ datasource }) => datasource.selectedDatasourceId);
    return (
        <Grid className={classes.sidePanelContainer}>
            {
                panelType === "actionitem" &&
                <ActionList history={history} />
            }
            {
                panelType === "conversation" &&
                <ConversationList history={history} />
            }
            {
                panelType === "comments" &&
                <Comments history={history} datasourceId={datasourceId} datasetId={datasetId} />
            }
        </Grid>
    );
};


SidePanel.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object
};


export default withStyles((theme) => ({
    ...SidePanelStyles(theme)
}), { withTheme: true })(SidePanel);