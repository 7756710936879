import React, { useState, useEffect, useCallback } from 'react';
import { Grid, withStyles, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import Styles from '../../../../layouts/Styles.jsx';
import DatasourceStyles from '../../../Datasource/DatasourceStyles.jsx';
import TabPanel from '../../../TabPanel/TabPanel.jsx';
import { appConstants } from '../../../../constants/appConstants.js';
import PropertiesListViewStyles from './PropertiesListViewStyles.jsx';
import PropertiesPanel from './PropertiesPanel.jsx';
import ValuesPanel from './ValuesPanel.jsx';
import RulesPanel from './RulesPanel.jsx';
import CuratePanel from './CuratePanel.jsx';
import LearningPanel from './LearningPanel.jsx';
import DriftPanel from './DriftPanel.jsx';
import FieldPanel from './FieldPanel.jsx';
import { datasetPropertiesTab, updatePropertyTabIndex } from '../../../../actions/datasetActions';

const PropertiesListView = (props) => {
    const dispatch = useDispatch();
    const index = useSelector(({ dataset }) => dataset.propertiesTabIndex);
    const config = useSelector(({ setting }) => setting.config);
    const profileSetting = useSelector(({ setting }) => setting.profileSetting);
    const { classes, properties, isEditable, datasetId, updateProperties, fields, setSelectedAttribute, tabFields, customTabs, isDeletePermission, isEditPermission, loadListProperties, attributeLoading, subTabIndex } = props;
    const [tabIndex, setTabIndex] = useState(index);
    const [tabHeader, setTabHeader] = useState(appConstants.attributeTabHeader);

    const attributeTabHeader = useCallback(() => {
        const newTabs = [...appConstants.attributeTabHeader];
        if (fields.length > 0) {
            newTabs.push(appConstants.fieldKeys.tabName);
        }
        if (!config.drift) {
            newTabs.splice(newTabs.indexOf('Drift'), 1);
        }
        if (!config.curate) {
            newTabs.splice(newTabs.indexOf('Curate'), 1);
            newTabs.splice(newTabs.indexOf('Learning'), 1);
        }
        if (!config.collaboration) {
            newTabs.splice(newTabs.indexOf('Comments'), 1);
        }
        setTabHeader([...newTabs, ...customTabs]);
    }, [config, customTabs, fields.length]);


    const onScroll = (event) => {
        if (event.target.scrollTop + event.target.clientHeight + 10 >= event.target.scrollHeight && !attributeLoading) {
            loadListProperties();
        }
    };


    useEffect(() => {
        if (subTabIndex && subTabIndex !== 0) {
            setTabIndex(subTabIndex);
        }
        attributeTabHeader();
    }, [attributeTabHeader, subTabIndex]);


    /**
     * get Tab Index
     * @param {*} index
     * @returns
     */
    const getTabIndex = (tabName) => {
        const index = tabHeader.indexOf(tabName);
        return index;
    };

    const setPropertiesTabIndex = useCallback((index) => {
        setTabIndex(index);
        dispatch(datasetPropertiesTab(index));
        dispatch(updatePropertyTabIndex(index));
    }, [dispatch]);

    return (
        <Grid container direction="column" className={classNames(classes.container, classes.propertiesPanel)}>
            {
                properties && Object.keys(properties).length > 0 ?
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid className={classes.datasourceTabContainer}>
                            <Tabs
                                value={tabIndex}
                                indicatorColor="secondary"
                                textColor="secondary"
                                variant="scrollable"
                                onChange={(event, index) => setPropertiesTabIndex(index)}
                                aria-label="dataset tabs">
                                {
                                    tabHeader && tabHeader.map((name, index) =>
                                        <Tab key={`attributeTabPanel${index}`} label={name === "Attributes" ? `${name} (${this.props.datasetInfo.total_attributes ? this.props.datasetInfo.total_attributes : '0'})` : name} />
                                    )
                                }
                            </Tabs>
                        </Grid>
                        <Grid className={classNames(classes.propertyTabContainer, classes.listViewTableContainer)} onScroll={onScroll}>
                            <TabPanel value={tabIndex} index={getTabIndex('Property')}>
                                {
                                    (tabIndex === getTabIndex('Property')) &&
                                    <PropertiesPanel isEditable={isEditable || isEditPermission} properties={properties} setSelectedAttribute={setSelectedAttribute} updateProperties={updateProperties} />
                                }
                            </TabPanel>
                            <TabPanel value={tabIndex} index={getTabIndex('Values')}>
                                {
                                    (tabIndex === getTabIndex('Values')) &&
                                    <ValuesPanel isEditable={isEditable} isEditPermission={isEditPermission} isDeletePermission={isDeletePermission} properties={properties} updateProperties={updateProperties} />
                                }
                            </TabPanel>
                            <TabPanel value={tabIndex} index={getTabIndex('Rule')}>
                                {
                                    (tabIndex === getTabIndex('Rule')) &&
                                    <RulesPanel isEditable={isEditable} isEditPermission={isEditPermission} isDeletePermission={isDeletePermission} profileSetting={profileSetting} properties={properties} />
                                }
                            </TabPanel>
                            <TabPanel value={tabIndex} index={getTabIndex('Curate')}>
                                {
                                    (tabIndex === getTabIndex('Curate')) &&
                                    <CuratePanel isEditable={isEditable} isEditPermission={isEditPermission} isDeletePermission={isDeletePermission} properties={properties} updateProperties={updateProperties} />
                                }
                            </TabPanel>
                            <TabPanel value={tabIndex} index={getTabIndex('Learning')}>
                                {
                                    (tabIndex === getTabIndex('Learning')) &&
                                    <LearningPanel isDeletePermission={isDeletePermission} isEditable={isEditable} datasetId={datasetId} properties={properties} updateProperties={updateProperties} />
                                }
                            </TabPanel>
                            <TabPanel value={tabIndex} index={getTabIndex('Drift')}>
                                {
                                    (tabIndex === getTabIndex('Drift')) &&
                                    <DriftPanel isEditable={isEditable} isEditPermission={isEditPermission} datasetId={datasetId} properties={properties} updateProperties={updateProperties} />
                                }
                            </TabPanel>
                            {
                                fields.length > 0 &&
                                <TabPanel value={tabIndex} index={getTabIndex(appConstants.fieldKeys.tabName)}>
                                    {
                                        (tabIndex === getTabIndex(appConstants.fieldKeys.tabName)) &&
                                        <FieldPanel isEditable={isEditable || isEditPermission} properties={properties ? properties : {}} datasetId={datasetId} fields={fields} />
                                    }
                                </TabPanel>
                            }
                            {
                                customTabs && customTabs.map((field, index) =>
                                    <TabPanel key={index} value={tabIndex} index={getTabIndex(field)}>
                                        {
                                            (tabIndex === getTabIndex(field)) &&
                                            <FieldPanel isEditable={isEditable || isEditPermission} properties={properties ? properties : {}} datasetId={datasetId} fields={[...tabFields.filter((tab) => tab.tab_name === field)]} />
                                        }
                                    </TabPanel>
                                )
                            }
                        </Grid>
                    </Grid>
                    : null
            }
        </Grid>
    );
};

PropertiesListView.propTypes = {
    classes: PropTypes.object,
    properties: PropTypes.object,
    isEditable: PropTypes.bool,
    updateProperties: PropTypes.func,
    datasetId: PropTypes.string,
    fields: PropTypes.array,
    setSelectedAttribute: PropTypes.func,
    tabFields: PropTypes.array,
    customTabs: PropTypes.array,
    isDeletePermission: PropTypes.bool,
    isEditPermission: PropTypes.bool,
    loadListProperties: PropTypes.func,
    attributeLoading: PropTypes.bool,
    subTabIndex: PropTypes.number
};

export default withStyles((theme) => ({
    ...PropertiesListViewStyles(theme),
    ...DatasourceStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(PropertiesListView);