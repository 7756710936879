import * as apihelper from '../helpers/apiHelpers';

export const getRequest = (url, cancellationToken = null) => {
	return apihelper.getRequest(url, cancellationToken).then((response) => {
		return response;
	});
};

export const postRequest = (url, requestParams, isFormData = false, cancellationToken = null) => {
	return apihelper.postRequest(url, requestParams, isFormData, cancellationToken).then((response) => {
		return response;
	});
};

export const putRequest = (url, requestParams, isFormData = false, cancellationToken = null) => {
	return apihelper.putRequest(url, requestParams, isFormData, cancellationToken).then((response) => {
		return response;
	});
};

export const deleteRequest = (url, cancellationToken = null) => {
	return apihelper.deleteRequest(url, cancellationToken).then((response) => {
		return response;
	});
};