import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography } from '@material-ui/core';
import classNames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import DatasourceStyles from './DatasourceStyles.jsx';
import { bytesToSize, numberFormatter } from '../../helpers/appHelpers';
import { useSelector } from 'react-redux';
import Filetype from '../../assets/images/logo-sql-server.png';
import datasourceList from '../../config/datasourceTypes';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';


const DatasetDetail = (props) => {
    const { classes, type, datasetInfo, isSkipDuplicate } = props;
    const datasourceCatalogInfo = useSelector(({ datasource }) => datasource.datasourceCatalogInfo);
    const datasetCatalogInfo = useSelector(({ datasource }) => datasource.datasetCatalogInfo);
    const [datasourceType, setDatasourceType] = useState({});
    const datasourceTypes = useSelector(({ datasource }) => datasource.connectionTypes);

    useEffect(() => {
        if (datasetInfo && datasetInfo.source_type) {
            const datasource = datasourceTypes.find((p) => p.type.toLowerCase() === datasetInfo.source_type.toLowerCase());
            if (datasource) {
                setDatasourceType({ ...datasource });
            }
        }
    }, [datasetInfo, datasourceTypes]);

    const getIcon = (sourceType) => {
        let icon;
        if (sourceType) {
            icon = datasourceList.find((p) => p.type.toLowerCase() === sourceType.toLowerCase());
        }
        if (icon && icon.logo && typeof (icon.logo) === 'object') {
            return icon.logo.default;
        }
        return icon ? icon.logo : Filetype;
    };

    return (
        <Grid container justify="space-between" alignItems="center" wrap="nowrap" className={classNames(classes.card, classes.overviewPanel)}>
            <Grid item className={classes.datasetSourceIcon}>
                {
                    datasetInfo.source_type &&
                    <img src={getIcon(datasetInfo.source_type)} alt={"file type"} />
                }
                <Grid className={classes.datasetDetailContent}>
                    <Typography component="h5" variant="h5">
                        {datasourceType.name ? datasourceType.name : ' '}
                    </Typography>
                    <Typography className={classes.datasetText}>
                        Datasource Type
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container direction="row" wrap="nowrap" alignItems="center">
                    {
                        (type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.tables_count) &&
                        <ToolTipComponent title="Total Datasets">
                            <Typography component="h5" variant="h5">
                                {datasourceCatalogInfo.tables_count ? `${datasourceCatalogInfo.tables_count}` : ''}
                            </Typography>
                        </ToolTipComponent>
                    }
                    {
                        (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.tables_count) &&
                        <ToolTipComponent title="Total Datasets">
                            <Typography component="h5" variant="h5">
                                {datasetCatalogInfo.tables_count ? `${datasetCatalogInfo.tables_count}` : ''}
                            </Typography>
                        </ToolTipComponent>
                    }
                    <ToolTipComponent title="Processed Datasets">
                        <Typography component="h5" variant="h5" className={((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.tables_count) || (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.tables_count)) ? classes.processedCount : ''}>
                            {
                                ((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.tables_count) ||
                                    (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.tables_count)) ? '(' : ''
                            }
                            {datasetInfo && datasetInfo.count ? datasetInfo.count : 0}
                            {
                                ((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.tables_count) ||
                                    (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.tables_count)) ? ')' : ''
                            }
                        </Typography>
                    </ToolTipComponent>
                </Grid>
                <Typography className={classes.datasetText}>
                    {datasetInfo.source_type === "Tableau" ? "Workbooks" : "Dataset"}
                </Typography>
            </Grid>
            {
                datasetInfo.source_type === "Tableau" &&
                <Grid item>
                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                        <ToolTipComponent title="Processed Attributes">
                            <Typography component="h5" variant="h5" className={((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.columns_count) || (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.columns_count)) ? classes.processedCount : ''}>
                                {datasetInfo.total_worksheets}
                            </Typography>
                        </ToolTipComponent>
                    </Grid>

                    <Typography className={classes.datasetText}>
                        Worksheets
                    </Typography>
                </Grid>
            }
            {
                datasetInfo.source_type === "Tableau" &&
                <Grid item>
                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                        <ToolTipComponent title="Processed Attributes">
                            <Typography component="h5" variant="h5" className={((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.columns_count) || (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.columns_count)) ? classes.processedCount : ''}>
                                {datasetInfo.total_dashboards}
                            </Typography>
                        </ToolTipComponent>
                    </Grid>

                    <Typography className={classes.datasetText}>
                        Dashboards
                    </Typography>
                </Grid>
            }
            {
                datasetInfo.source_type === "Tableau" &&
                <Grid item>
                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                        <ToolTipComponent title="Processed Attributes">
                            <Typography component="h5" variant="h5" className={((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.columns_count) || (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.columns_count)) ? classes.processedCount : ''}>
                                {datasetInfo.total_datasources}
                            </Typography>
                        </ToolTipComponent>
                    </Grid>

                    <Typography className={classes.datasetText}>
                        Datasources
                    </Typography>
                </Grid>
            }
            {
                datasetInfo.source_type !== "Tableau" &&
                <Grid item>
                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                        {
                            (type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.columns_count) &&
                            <ToolTipComponent title="Total Attributes">
                                <Typography component="h5" variant="h5">
                                    {datasourceCatalogInfo.columns_count ? `${datasourceCatalogInfo.columns_count}` : ''}
                                </Typography>
                            </ToolTipComponent>
                        }
                        {
                            (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.columns_count) &&
                            <ToolTipComponent title="Total Attributes">
                                <Typography component="h5" variant="h5">
                                    {datasetCatalogInfo.columns_count ? `${datasetCatalogInfo.columns_count}` : ''}
                                </Typography>
                            </ToolTipComponent>
                        }
                        <ToolTipComponent title="Processed Attributes">
                            <Typography component="h5" variant="h5" className={((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.columns_count) || (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.columns_count)) ? classes.processedCount : ''}>
                                {
                                    ((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.columns_count) ||
                                        (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.columns_count)) ? '(' : ''
                                }
                                {datasetInfo && datasetInfo.total_attributes ? datasetInfo.total_attributes : 0}
                                {
                                    ((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.columns_count) ||
                                        (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.columns_count)) ? ')' : ''
                                }
                            </Typography>
                        </ToolTipComponent>
                    </Grid>

                    <Typography className={classes.datasetText}>
                        Total Attributes
                    </Typography>
                </Grid>
            }
            {
                datasetInfo.source_type !== "Tableau" &&
                <Grid item>
                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                        {
                            (type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.total_records) &&
                            <ToolTipComponent title={`Total Records - ${datasourceCatalogInfo.total_records ? datasourceCatalogInfo.total_records : 0}`}>
                                <Typography component="h5" variant="h5">
                                    {datasourceCatalogInfo.total_records ? `${numberFormatter(datasourceCatalogInfo.total_records)}` : ''}
                                </Typography>
                            </ToolTipComponent>
                        }
                        {
                            (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.rows_count) &&
                            <ToolTipComponent title={`Total Records - ${datasetCatalogInfo.rows_count ? datasetCatalogInfo.rows_count : 0}`}>
                                <Typography component="h5" variant="h5">
                                    {datasetCatalogInfo.rows_count ? `${numberFormatter(datasetCatalogInfo.rows_count)}` : ''}
                                </Typography>
                            </ToolTipComponent>
                        }
                        <ToolTipComponent title={`Processed Records - ${datasetInfo && datasetInfo.total_records ? datasetInfo.total_records : 0}`}>
                            <Typography component="h5" variant="h5" className={((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.total_records) || (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.rows_count)) ? classes.processedCount : ''}>
                                {
                                    ((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.total_records) ||
                                        (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.rows_count)) ? '(' : ''
                                }
                                {datasetInfo && datasetInfo.total_records ? numberFormatter(datasetInfo.total_records) : 0}
                                {
                                    ((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.total_records) ||
                                        (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.rows_count)) ? ')' : ''
                                }
                            </Typography>
                        </ToolTipComponent>
                    </Grid>
                    <Typography className={classes.datasetText}>
                        Total Records
                    </Typography>
                </Grid>
            }
            {
                datasetInfo.source_type !== "Tableau" && isSkipDuplicate &&
                <Grid item>
                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                        {
                            (type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.duplicate_count) &&
                            <ToolTipComponent title={`Total Records - ${datasourceCatalogInfo.duplicate_count ? datasourceCatalogInfo.duplicate_count : 0}`}>
                                <Typography component="h5" variant="h5">
                                    {datasourceCatalogInfo.duplicate_count ? `${numberFormatter(datasourceCatalogInfo.duplicate_count)}` : ''}
                                </Typography>
                            </ToolTipComponent>
                        }
                        {
                            (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.duplicate_count) &&
                            <ToolTipComponent title={`Total Records - ${datasetCatalogInfo.duplicate_count ? datasetCatalogInfo.duplicate_count : 0}`}>
                                <Typography component="h5" variant="h5">
                                    {datasetCatalogInfo.duplicate_count ? `${numberFormatter(datasetCatalogInfo.duplicate_count)}` : ''}
                                </Typography>
                            </ToolTipComponent>
                        }
                        <ToolTipComponent title={`Processed Records - ${datasetInfo && datasetInfo.duplicate_count ? datasetInfo.duplicate_count : 0}`}>
                            <Typography component="h5" variant="h5" className={((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.duplicate_count) || (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.duplicate_count)) ? classes.duplicate_count : ''}>
                                {
                                    ((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.duplicate_count) ||
                                        (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.duplicate_count)) ? '(' : ''
                                }
                                {datasetInfo && datasetInfo.total_records ? numberFormatter(datasetInfo.duplicate_count) : 0}
                                {
                                    ((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.duplicate_count) ||
                                        (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.duplicate_count)) ? ')' : ''
                                }
                            </Typography>
                        </ToolTipComponent>
                    </Grid>
                    <Typography className={classes.datasetText}>
                        Duplicate Records
                    </Typography>
                </Grid>
            }
            {
                datasetInfo.source_type !== "Tableau" &&
                <Grid item>
                    <Grid container direction="row" wrap="nowrap" alignItems="center">
                        {
                            (type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.database_size) &&
                            <ToolTipComponent title="Total Size">
                                <Typography component="h5" variant="h5">
                                    {datasourceCatalogInfo.database_size && datasourceCatalogInfo.database_size > 0 ? `${bytesToSize(datasourceCatalogInfo.database_size)}` : 0}
                                </Typography>
                            </ToolTipComponent>
                        }
                        {
                            (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.bytes) &&
                            <ToolTipComponent title="Total Size">
                                <Typography component="h5" variant="h5">
                                    {datasetCatalogInfo.bytes && datasetCatalogInfo.bytes > 0 ? `${bytesToSize(datasetCatalogInfo.bytes)}` : 0}
                                </Typography>
                            </ToolTipComponent>
                        }
                        <ToolTipComponent title="Processed Size">
                            <Typography component="h5" variant="h5" className={((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.database_size) || (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.bytes)) ? classes.processedCount : ''}>
                                {
                                    ((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.database_size) ||
                                        (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.bytes)) ? '(' : ''
                                }
                                {datasetInfo && datasetInfo.fileSize > 0 ? bytesToSize(datasetInfo.fileSize) : 'NA'}
                                {
                                    ((type === 'datasource' && datasourceCatalogInfo && datasourceCatalogInfo.database_size) ||
                                        (type === 'dataset' && datasetCatalogInfo && datasetCatalogInfo.bytes)) ? ')' : ''
                                }
                            </Typography>
                        </ToolTipComponent>
                    </Grid>
                    <Typography className={classes.datasetText}>
                        Data Size
                    </Typography>
                </Grid>
            }
        </Grid>
    );
};

DatasetDetail.propTypes = {
    classes: PropTypes.object,
    type: PropTypes.string,
    datasetInfo: PropTypes.object,
    isSkipDuplicate: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DatasourceStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DatasetDetail);