import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import GridLayout from 'react-grid-layout';
import '../../../node_modules/react-grid-layout/css/styles.css';
import '../../../node_modules/react-resizable/css/styles.css';

import Styles from '../../layouts/Styles.jsx';
import DashboardStyles from './DashboardStyles.jsx';
import DashboardWidget from './DashboardWidget.jsx';
import DashboardFilter from './DashboardFilter.jsx';
import DashbordToolbar from './DashboardToolbar.jsx';
import { updateDashboard } from '../../actions/dashboardActions';
import useWindowDimensions from './WindowDimensions.jsx';


const DashboardItem = (props) => {
    const { classes, history, dashboard, tabIndex, selectedTabIndex, onUpdateDashboard, onDeleteDashboard } = props;
    const dispatch = useDispatch();
    const [currentDashboard, setCurrentDashboard] = useState(null);
    const [showFilter, setShowFilter] = useState(false);
    const [refresh, doRefresh] = useState(0);

    const loadDashboard = useCallback((dashboard) => {
        setCurrentDashboard(dashboard);
    }, []);

    useEffect(() => {
        if (dashboard && !currentDashboard) {
            loadDashboard(dashboard);
        }
    }, [currentDashboard, dashboard, loadDashboard]);

    const onUpdateWidget = useCallback((dashboard) => {
        const updatedDashboard = Object.assign({}, { ...dashboard });
        updatedDashboard.widgets = JSON.stringify(updatedDashboard.widgets);
        dispatch(updateDashboard(dashboard, tabIndex));
    }, [dispatch, tabIndex]);

    const onSaveDashboard = useCallback((dashboard) => {
        onUpdateWidget(dashboard);
        onUpdateDashboard(dashboard);
    }, [onUpdateDashboard, onUpdateWidget]);

    const onChange = (property, value) => {
        const dashboard = { ...currentDashboard };
        dashboard[property] = value;
        setCurrentDashboard(dashboard);
    };

    const addDashboardFilter = () => {
        setShowFilter(true);
    };

    const closeDashboardFilter = () => {
        setShowFilter(false);
    };

    const updateDashboardFilter = (filterOption) => {
        const dashboard = { ...currentDashboard };
        dashboard.filters = filterOption;
        const widgets = (dashboard.widgets instanceof Array) ? dashboard.widgets : [];
        for (const widget of widgets) {
            widget.isModified = true;
        }
        dashboard.widgets = widgets;

        setCurrentDashboard(dashboard);
        onUpdateWidget(dashboard);
        onUpdateDashboard(dashboard);
        closeDashboardFilter();
        const prev = refresh + 1;
        doRefresh(prev);
    };

    const removeDashboardFilter = () => {
        const dashboard = { ...currentDashboard };
        dashboard.filters = {};
        setCurrentDashboard(dashboard);
        onUpdateWidget(dashboard);
        onUpdateDashboard(dashboard);
        // closeDashboardFilter();
    };

    const onAddWidget = useCallback(() => {
        const dashboard = Object.assign({}, { ...currentDashboard });
        const maxId = Math.max(...dashboard.widgets.map((widget) => widget.i.replace('_edit', '')), 0);
        dashboard.widgets.push(
            {
                i: (maxId + 1).toString() + '_edit',
                x: 0,
                y: 0,
                w: 3,
                h: 18,
                pw: 3,
                ph: 18,
                isResizable: false,
                isNew: true,
                isSettings: true,
                isLoading: false,
                metrics_type: null,
                level: null,
                datasource_id: [],
                dataset_ids: [],
                attribute_ids: [],
                rules: [],
                range: '15',
                xaxis: null,
                yaxis: null,
                show_null: false,
                show_empty: false
            });
        setCurrentDashboard(dashboard);
        onUpdateWidget(dashboard);
    }, [currentDashboard, onUpdateWidget]);

    const onDeleteWidget = useCallback((id) => {
        const dashboard = Object.assign({}, { ...currentDashboard });
        const wIndex = dashboard.widgets.findIndex((w) => w.i === id);
        if (wIndex > -1) {
            dashboard.widgets.splice(wIndex, 1);
        }
        setCurrentDashboard(dashboard);
        onUpdateWidget(dashboard);
    }, [currentDashboard, onUpdateWidget]);

    const isLayoutChanged = useCallback((layout) => {
        let isChanged = false;
        for (const widget of currentDashboard.widgets) {
            const currentLayout = layout.filter((l) => l.i === widget.i)[0];
            if (currentLayout && (currentLayout.x !== widget.x || currentLayout.y !== widget.y
                || currentLayout.widget !== widget.w || currentLayout.h !== widget.h)) {
                isChanged = true;
            }
        }
        return isChanged;
    }, [currentDashboard]);

    const onLayoutChange = useCallback((layout) => {
        if (!isLayoutChanged(layout)) {
            return;
        }
        const dashboard = Object.assign({}, { ...currentDashboard });
        dashboard.widgets = dashboard.widgets.map((w) => {
            const l = layout.filter((l) => l.i === w.i)[0];
            if (!l) {
                return w;
            }
            return {
                ...w,
                x: l.x,
                y: l.y,
                w: l.w,
                h: l.h,
                isLayoutUpdated: true
            };
        });
        setCurrentDashboard(dashboard);
        onUpdateWidget(dashboard);
    }, [currentDashboard, isLayoutChanged, onUpdateWidget]);

    const onChangeSettings = useCallback((wid, name, value) => {
        const wIndex = currentDashboard.widgets.findIndex((w) => w.i === wid);
        currentDashboard.widgets[wIndex][name] = value;
        setCurrentDashboard(currentDashboard);
    }, [currentDashboard]);

    const onSaveSettings = useCallback((widget) => {
        if (!widget) {
            return;
        }
        const dashboard = Object.assign({}, { ...currentDashboard });
        const wIndex = dashboard.widgets.findIndex((w) => w.i === widget.i);
        widget.i = widget.i.replace('_edit', '');
        widget.isNew = false;
        widget.isSettings = false;
        widget.isResizable = true;
        widget.isModified = true;
        widget.w = widget.pw;
        widget.h = widget.ph;
        dashboard.widgets[wIndex] = widget;
        setCurrentDashboard(dashboard);
        onUpdateWidget(dashboard);
    }, [currentDashboard, onUpdateWidget]);

    const { width } = useWindowDimensions();

    return (
        <Grid className={classes.dashboardWidgetContainer}>
            {
                currentDashboard && tabIndex === selectedTabIndex &&
                    showFilter &&
                    <DashboardFilter
                        dashboard={currentDashboard}
                        closeDashboardFilter={closeDashboardFilter}
                        updateDashboardFilter={(filterOption) => updateDashboardFilter(filterOption)}
                        removeDashboardFilter={() => removeDashboardFilter()} />

            }
            {
                currentDashboard && tabIndex === selectedTabIndex &&
                <GridLayout onLayoutChange={onLayoutChange} className="layout" cols={12} rowHeight={30} width={width - 100}>
                    <Grid key="toolbar" data-grid={{ x: 0, y: 0, w: 12, h: 2, minW: 12, minH: 2, static: true }} style={{ margin: 6 }}>
                        <DashbordToolbar
                            dashboard={currentDashboard}
                            onChange={onChange}
                            onAddWidget={onAddWidget}
                            addDashboardFilter={addDashboardFilter}
                            onSave={(dashboard) => onSaveDashboard(dashboard)}
                            onDeleteDashboard={() => onDeleteDashboard(currentDashboard)}
                        />
                    </Grid>
                    {
                        currentDashboard && currentDashboard.widgets && currentDashboard.widgets.map((w) => {
                            return (
                                <Grid key={w.i} data-grid={{ x: w.x, y: w.y, w: w.w, h: w.h, isResizable: w.isResizable, minW: 2, minH: 4 }} className={classes.dashboardComponentContainer}>
                                    <DashboardWidget
                                        widget={w}
                                        onChangeSettings={onChangeSettings}
                                        onSaveSettings={onSaveSettings}
                                        onDeleteWidget={onDeleteWidget}
                                        dashboard={currentDashboard}
                                        history={history}
                                        refresh={refresh}
                                    />
                                </Grid>
                            );
                        })
                    }
                </GridLayout>
            }
        </Grid>
    );

};

DashboardItem.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    dashboard: PropTypes.object,
    onUpdateDashboard: PropTypes.func,
    onDeleteDashboard: PropTypes.func,
    tabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedTabIndex: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}))(DashboardItem);