import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Button } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextBox from '../../TextBox/TextBox.jsx';
import Styles from '../../../layouts/Styles.jsx';
import Loader from '../../Loaders/Loader.jsx';

const Hadoop = (props) => {
    const { classes, onSave, onChange, storage, type, isLoading } = props;
    return (
        <ValidatorForm onSubmit={() => onSave(type)}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextBox
                        label="Name Node"
                        name="namenode"
                        value={storage.namenode ? storage.namenode : ""}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                        validators={['required']}
                        errorMessages={['Name node is required']}
                    />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4}>
                    <TextBox
                        label="Port"
                        name="namenodeport"
                        value={storage.namenodeport ? storage.namenodeport : ""}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                        validators={['required']}
                        errorMessages={['Name node port is required']}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextBox
                        label="Data Node"
                        name="datanode"
                        value={storage.datanode ? storage.datanode : ""}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                        validators={['required']}
                        errorMessages={['Data node is required']}
                    />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4}>
                    <TextBox
                        label="Port"
                        name="datanodeport"
                        value={storage.datanodeport ? storage.datanodeport : ""}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                        validators={['required']}
                        errorMessages={['Data node port is required']}
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextBox
                        label="Username"
                        name="username"
                        value={storage.username ? storage.username : ""}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                        validators={['required']}
                        errorMessages={['Username is required']}
                    />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4}>
                    <TextBox
                        label="Path"
                        name="basepath"
                        value={storage.basepath ? storage.basepath : ""}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                        validators={['required']}
                        errorMessages={['path is required']}
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextBox
                        label="Host"
                        name="host"
                        value={storage.host ? storage.host : ""}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                        validators={['required']}
                        errorMessages={['host is required']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classes.actionButtons}>
                        {'Connect'}
                        {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                    </Button>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
};

Hadoop.propTypes = {
    storage: PropTypes.object,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    classes: PropTypes.object,
    type: PropTypes.string,
    isLoading: PropTypes.bool
};


export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(Hadoop);