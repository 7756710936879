import React, { useCallback, useEffect, useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import ChartStyles from './ChartStyles.jsx';
import { appConstants } from '../../constants/appConstants';
import { createRule, getDefaultOperator, getFieldType } from '../RuleBuilder/QueryBuilderUtil.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { learningRule } from '../../actions/scheduleActions';

const ValueChart = (props) => {
    const emptyKey = 'Empty / Null';
    const { classes, profileData, theme, config, profileRuleEnable, isActive, datasetId, onFilter, attribute, tabIndex, attributeChange, invalidQuery } = props;
    const datasource = useSelector(({ datasource }) => datasource.datasource);
    const [menuType, setMenuType] = useState(appConstants.rangeFilterOptions[0]);
    const [chartType, setChartType] = useState("Count");
    const [chartData, setChartData] = useState({});
    const [valueChartData, setValueChartData] = useState({});
    const [edit, setEdit] = useState(false);
    const [edited, setEdited] = useState(false);
    const [changedEnum, setChangedEnum] = useState([]);
    const properties = useSelector(({ dataset }) => dataset.properties);
    const dispatch = useDispatch();


    const loadChartData = useCallback((chartData) => {
        setChartData(JSON.parse(JSON.stringify(chartData)));
        setValueChartData(JSON.parse(JSON.stringify(chartData)));
    }, []);

    useEffect(() => {
        if (attributeChange) {
            const chartData = profileData['Valid Values Check'];
            loadChartData({ ...chartData });
        }
    }, [attribute, attributeChange, loadChartData, profileData]);
    const onFilterChange = useCallback((barValue) => {
        const inputParams = {
            chartType: appConstants.charts.validvaluescheck.type,
            chartName: appConstants.charts.validvaluescheck.name,
            value: chartData.input_param ? chartData.input_param : '',
            filterOptions: true,
            ruleName: 'ValidValuesCheck',
            defaultQuery: ''
        };

        if (barValue) {
            inputParams.data = barValue;
            const ruleParams = {
                defaultField: attribute.name,
                defaultFieldType: getFieldType(attribute.type),
                attributeType: attribute.type,
                connectionType: datasource?.type,
                isScan: datasource?.scan
            };
            const rule = createRule(ruleParams);
            const operator = getDefaultOperator('text');
            rule.operator = { ...operator };
            rule.value = barValue.value;
            inputParams.rule = rule;
            inputParams.filterType = barValue.filterType;
        } else {
            inputParams.filterOptions = false;
            if (invalidQuery) {
                inputParams.defaultQuery = `select * from <table_name> where ${invalidQuery}`;
            } else {
                const chartParams = chartData.input_params && chartData.input_params.values ? chartData.input_params.values.filter((data) => data.isvalid).map((data) => (data.value ? data.value.toLowerCase() : "")) : [];
                if (chartParams.length) {
                    inputParams.defaultQuery = `select * from <table_name> where not lower(${attribute.name}.format) in(${"'" + chartParams.join("','") + "'"})`;
                }
            }
        }

        onFilter(inputParams);
    }, [attribute.name, attribute.type, chartData.input_param, chartData.input_params, datasource?.scan, datasource?.type, invalidQuery, onFilter]);

    const onChangeMenu = (value, index) => {
        setEdited(true);
        if (index === 0) {
            setMenuType(value);
        } else {
            setChartType(value);
        }
    };

    const interactVisual = useCallback((data, type) => {
        if (chartData.frequency_count) {
            const selectedItem = chartData.frequency_count[type === "accept" ? "valid" : "invalid"][data.key];
            delete chartData.frequency_count[type === "accept" ? "valid" : "invalid"][data.key];
            chartData.frequency_count[type === "accept" ? "invalid" : "valid"][data.key] = selectedItem;
            const index = changedEnum.findIndex((d) => d.value.toLowerCase() === data.key.toLowerCase());
            if (index !== -1) {
                changedEnum.splice(index, 1);
            }
            changedEnum.push({
                value: data.key,
                isvalid: type !== "accept"
            });
            setChangedEnum([...changedEnum]);
            setChartData({ ...chartData });
            setEdited(true);
        }
    }, [changedEnum, chartData]);

    const wrapText = (element, width) => {
        let textLength = element.node().getComputedTextLength();
        let text = element.text();
        while (textLength > width && text.length > 0) {
            text = text.slice(0, -1);
            element.text(text + '...');
            textLength = element.node().getComputedTextLength();
        }
    };

    const renderChart = useCallback((valueChartData) => {
        const tickCount = 10;
        const barWidth = 20;
        const barOpacity = 0.5;
        const barHighlightOpacity = 0.75;
        let maxY = d3.max(valueChartData, (d) => { return d.value; });
        if (chartType === 'Count') {
            maxY = d3.max(valueChartData, (d) => { return d.count; });
            maxY += (Math.round(maxY / tickCount));
        }
        const calcWidth = Math.round(maxY + (Math.round(maxY / tickCount))).toString();
        const margin = { top: 40, right: 20, bottom: 50, left: calcWidth ? (calcWidth.length + 2) * 10 : 40 };
        const height = 280;
        let width = 960 - margin.left - margin.right;
        d3.select('.value-chart > *').remove();
        const svg = d3.select(".value-chart").append("svg")
            .attr("width", "100%")
            .attr("height", height + margin.top + margin.bottom + 20)
            .append("g")
            .attr("width", (d, i, p) => {
                width = p[i].parentNode.clientWidth;
                return width;
            })
            .attr("height", height + margin.top + margin.bottom - 10)
            .attr("transform",
                "translate(" + (margin.left + 20) + "," + ((margin.top / 2) - 5) + ")");

        const x = d3.scaleBand()
            .range([0, width - (margin.left + margin.right)])
            .padding(0.1);

        const y = d3.scaleLinear()
            .domain([0, maxY])
            .range([height, 0])
            .nice();

        x.domain(valueChartData.map((d) => { return d.key; }));
        d3.select(".value-chart > .tooltip").remove();
        const div = d3.select(".value-chart").append("div")
            .attr("class", "tooltip")
            .style("opacity", 0)
            .style("zIndex", 1);

        // append the rectangles for the bar chart
        const minBarHeightThreshold = 2;
        svg.selectAll(".bar")
            .data(valueChartData)
            .enter().append("rect")
            .attr("x", (d) => { return x(d.key) + ((x.bandwidth() / 2) - (barWidth / 2)); })
            .attr("y", (d) => { return height; })
            .attr("width", Math.min(x.bandwidth(), barWidth))
            .attr("fill", (d) => {
                return d.is_valid ? theme.palette.chartColors.valid : theme.palette.chartColors.inValid;
            })
            .attr("class", (d) => {
                const color = d.is_valid ? 'accepted' : 'not-accepted';
                return "bar " + color;
            })
            .style("opacity", barOpacity)
            .on('mouseover', (d, i, nodes) => {
                d3.select(nodes[i]).style("opacity", barHighlightOpacity);

                // Show Tooltip
                const rect = nodes[i].getBBox();
                div.transition()
                    .duration(200)
                    .style("opacity", 0.9);
                div.html(`Value : ${d.key} <br/> Percentage : ${d.value}% <br/> Count : ${d.count}`)
                    .style("left", (rect.x + (barWidth / 2)) + "px")
                    .style("top", (rect.y - ((barWidth * 2) + barWidth)) + "px");
            })
            .on('mouseout', (_, i, nodes) => {
                d3.select(nodes[i]).style("opacity", barOpacity);
                div.transition()
                    .duration(500)
                    .style("opacity", 0);
            })
            .on('click', (d) => {
                const filterType = (d.is_valid) ? appConstants.QueryFilterTypes[0] : appConstants.QueryFilterTypes[1];
                onFilterChange({ value: d.key !== emptyKey ? d.key : '', filterType });
            })
            .attr("height", 0)
            .transition()
            .duration(500)
            .delay((d, i) => {
                return i * 100;
            })
            .attr("y", (d) => {
                const value = (chartType === 'Count') ? d.count : d.value;
                return height - y(value) > minBarHeightThreshold ? y(value) : y(value) - minBarHeightThreshold;
            })
            .attr("height", (d) => {
                const value = (chartType === 'Count') ? d.count : d.value;
                return height - y(value) > minBarHeightThreshold ? height - y(value) : height - y(value) + minBarHeightThreshold;
            });

        svg.append("g")
            .attr("transform", "translate(0," + height + ")")
            .attr("color", theme.palette.chartColors.axis)
            .attr('class', 'axis-x')
            .call(d3.axisBottom(x));

        svg.append("text")
            .attr("transform",
                "translate(" + (width / 2) + " ," +
                (height + margin.top + ((margin.top / 2) - 5)) + ")")
            .style("text-anchor", "middle")
            .text("Value");

        svg.append("g")
            .attr("color", theme.palette.chartColors.axis)
            .attr('class', 'axis-y')
            .transition().duration(500)
            .call(d3.axisLeft(y).ticks(tickCount));

        const yLabel = chartType === 'Count' ? 'Count' : 'Percentage';
        svg.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", 0 - (margin.left))
            .attr("x", 0 - (height / 2))
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .text(yLabel);

        svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
        svg.selectAll('.axis-x .tick-text').text('').append('tspan').text((d) => {
            if (d && d.length > 50) {
                d = d.substring(0, 50).concat('...');
            }
            return d;
        }).each((_, i, nodes) => {
            const textElement = d3.select(nodes[i]);
            const axisLength = d3.select(".value-chart svg g").node().getBoundingClientRect().width;
            const width = (axisLength / (valueChartData.length)) - barWidth;
            wrapText(textElement, width);
        });

        if (edit) {
            const accepted = `<svg  width="15px" height="15px" viewBox="0 0 20 20"><g><path style=" stroke:none;fill-rule:nonzero;fill:${theme.palette.chartColors.valid};fill-opacity:1;" d="M 19.226562 10 C 19.226562 15.09375 15.09375 19.226562 10 19.226562 C 4.90625 19.226562 0.773438 15.09375 0.773438 10 C 0.773438 4.90625 4.90625 0.773438 10 0.773438 C 15.09375 0.773438 19.226562 4.90625 19.226562 10 Z M 19.226562 10 "/><path style=" stroke:none;fill-rule:nonzero;fill:#525252;fill-opacity:1;" d="M 10 20 C 4.488281 20 0 15.515625 0 10 C 0 4.488281 4.488281 0 10 0 C 15.515625 0 20 4.488281 20 10 C 20 15.515625 15.515625 20 10 20 Z M 10 1.550781 C 5.339844 1.550781 1.550781 5.339844 1.550781 10 C 1.550781 14.660156 5.339844 18.449219 10 18.449219 C 14.660156 18.449219 18.449219 14.660156 18.449219 10 C 18.449219 5.339844 14.660156 1.550781 10 1.550781 Z M 10 1.550781 "/><path style=" stroke:none;fill-rule:nonzero;fill:#fff;fill-opacity:1;" d="M 8.792969 13.679688 C 8.585938 13.679688 8.386719 13.597656 8.238281 13.449219 L 5.382812 10.542969 C 5.082031 10.238281 5.085938 9.75 5.390625 9.449219 C 5.695312 9.148438 6.1875 9.152344 6.484375 9.457031 L 8.769531 11.777344 L 13.492188 6.574219 C 13.78125 6.257812 14.273438 6.234375 14.585938 6.523438 C 14.902344 6.808594 14.925781 7.300781 14.640625 7.617188 L 9.363281 13.425781 C 9.222656 13.585938 9.019531 13.675781 8.808594 13.679688 C 8.800781 13.679688 8.796875 13.679688 8.792969 13.679688 Z M 8.792969 13.679688 "/></g></svg>`;
            const unaccepted = `<svg width="15px" height="15px" viewBox="0 0 15 15"><g><path style=" stroke:none;fill-rule:nonzero;fill:${theme.palette.chartColors.inValid};fill-opacity:1;" d="M 7.5 0 C 3.363281 0 0 3.363281 0 7.5 C 0 11.636719 3.363281 15 7.5 15 C 11.636719 15 15 11.636719 15 7.5 C 15 3.363281 11.636719 0 7.5 0 Z M 10.535156 9.648438 C 10.65625 9.773438 10.65625 9.96875 10.535156 10.089844 L 10.089844 10.535156 C 9.96875 10.65625 9.773438 10.65625 9.648438 10.535156 L 7.5 8.382812 L 5.351562 10.535156 C 5.226562 10.65625 5.03125 10.65625 4.910156 10.535156 L 4.464844 10.089844 C 4.34375 9.96875 4.34375 9.773438 4.464844 9.648438 L 6.617188 7.5 L 4.464844 5.351562 C 4.34375 5.226562 4.34375 5.03125 4.464844 4.910156 L 4.910156 4.464844 C 5.03125 4.34375 5.226562 4.34375 5.351562 4.464844 L 7.5 6.617188 L 9.648438 4.464844 C 9.773438 4.34375 9.96875 4.34375 10.089844 4.464844 L 10.535156 4.910156 C 10.65625 5.03125 10.65625 5.226562 10.535156 5.351562 L 8.382812 7.5 Z M 10.535156 9.648438 "/></g></svg>`;
            svg.selectAll('.axis-x .tick').append('g')
                .attr('height', '15px')
                .attr('width', '15px')
                .attr("transform", "translate(-5,20)")
                .html((d, index) => {
                    const checkPatternValid = valueChartData.some((data) => data.key === d && data.is_valid);
                    d3.select(this).attr('class', `enum${index} ${checkPatternValid}`);
                    d3.select(this).raise();
                    return checkPatternValid ? unaccepted : accepted;
                }).on('click', (d, index) => {
                    const checkPatternValid = valueChartData.some((data) => data.key === d && data.is_valid);
                    const inputData = valueChartData.find((p) => p.key === d);
                    interactVisual(inputData, checkPatternValid ? "accept" : "non_accept");
                });
        }


        const legendGap = 150;
        const lines = ["valid", "invalid"];
        const legendX = lines.length > 1 ? (width / 1.1) - 200 : (width / 2) - (legendGap / 2);
        const legendGroup = svg.append("g")
            .attr("class", "legends")
            .attr("transform", "translate(" + (legendX - 50) + "," + (height + margin.top + 40) + ")");

        // Legend rectangle
        legendGroup.selectAll(".legend-group")
            .data(lines)
            .enter()
            .append("g")
            .attr("class", "legend-group")
            .style("opacity", 1)
            .on("mouseover", (d, i, nodes) => {
                d3.select(nodes[i]).style("opacity", barHighlightOpacity);
                const selectedBar = (d === "valid") ? "accepted" : "not-accepted";
                d3.selectAll(`.frequency-chart .bar.${selectedBar}`).style("opacity", barHighlightOpacity);
            })
            .on("mouseout", (_, i, nodes) => {
                d3.select(nodes[i]).style("opacity", 1);
                d3.selectAll(`.bar`).style("opacity", barOpacity);
            })
            .append("rect")
            .attr("class", "legend-rect")
            .attr("width", 14)
            .attr("height", 10)
            .attr('x', (_, index) => { return (index * legendGap); })
            .style("fill", (_, index) => { return index === 0 ? theme.palette.chartColors.valid : theme.palette.chartColors.inValid; });

        // Legend label
        legendGroup.selectAll(".legend-group")
            .append("text")
            .attr("class", "legend-text")
            .attr("x", (_, index) => { return index * legendGap + 20; })
            .attr("y", 6)
            .text((line) => {
                let percent = line === "valid" ? chartData.valid_percentage : chartData.invalid_percentage;
                if (Math.round(percent) !== percent) {
                    percent = percent.toFixed(2);
                }
                return `${line} (${percent}%)`;
            })
            .attr("alignment-baseline", "middle");
    }, [chartData.invalid_percentage, chartData.valid_percentage, chartType, edit, interactVisual, onFilterChange, theme.palette.chartColors]);


    const discardEdit = useCallback(() => {
        setEdited(false);
    }, []);

    const extractValues = useCallback((frequency, records, status) => {
        const keys = Object.keys(frequency);
        return keys.map((key) => {
            const value = (frequency[key] / records) * 100;
            return {
                key: key ? key : emptyKey,
                value: Math.round(value) !== value ? parseFloat(value.toFixed(2)) : value,
                count: frequency[key],
                "is_valid": status
            };
        });
    }, []);

    useEffect(() => {
        if (chartData && Object.keys(chartData).length !== 0 && (attributeChange || edited)) {
            if (edited) {
                discardEdit();
            }

            const selectionRange = menuType === 'Top 10' ? 'topN' : 'bottomN';
            let validValues = extractValues(chartData.frequency_count.valid ? chartData.frequency_count.valid : {}, chartData.total_records_processed, true);
            validValues = validValues.sort((a, b) => (a.count < b.count ? 1 : -1));
            let invalidValues = extractValues(chartData.frequency_count.invalid ? chartData.frequency_count.invalid : {}, chartData.total_records_processed, false);
            invalidValues = invalidValues.sort((a, b) => (a.count < b.count ? 1 : -1));
            const validData = selectionRange === "topN" ? validValues.slice(0, 10) : validValues.slice(Math.max(validValues.length - 10, 1));
            const invalidData = selectionRange === "topN" ? invalidValues.slice(0, 10) : invalidValues.slice(Math.max(invalidValues.length - 10, 1));
            let valueChartData = [...validData, ...invalidData];
            valueChartData = valueChartData.sort((a, b) => (a.count < b.count ? 1 : -1));
            renderChart(valueChartData);
        }
    }, [attributeChange, chartData, discardEdit, edited, menuType, renderChart, tabIndex, extractValues]);

    const learning = () => {
        const interactEnumeration = properties[attribute.name] ? properties[attribute.name].enum : {};
        let enumValues = interactEnumeration && interactEnumeration.values ? [...interactEnumeration.values] : [];
        for (const enumValue of changedEnum) {
            const index = enumValues.findIndex((p) => p.value.toLowerCase() === enumValue.value.toLowerCase());
            if (index !== -1) {
                enumValues[index].isvalid = enumValue.isvalid;
            } else {
                enumValues.push({
                    isvalid: enumValue.isvalid,
                    value: enumValue.value
                });
            }
        }
        enumValues = enumValues.filter((data) => data.isvalid);
        enumValues = enumValues.filter((data, index, self) =>
            index === self.findIndex((t) => (
                data.isvalid && t.value === data.value
            )));
        interactEnumeration.values = [...enumValues]
            ;
        const model = {
            "attribute": attribute.name,
            "property": {
                "enum": interactEnumeration
            },
            "type": "Profile"
        };
        dispatch(learningRule(attribute.id, datasetId, model));
    };

    const onEdit = (type) => {
        if (type === "edit") {
            setEdit(true);
        } else {
            setEdit(false);
            if (type !== "discard") {
                learning();
            } else {
                setChartData({ ...valueChartData });
            }
        }
        setEdited(true);
    };

    const onRuleChange = (value) => {
        profileRuleEnable(appConstants.charts.validvaluescheck.type, value);
    };

    return (
        <ChartContainer
            menuProperties={
                [
                    {
                        options: appConstants.rangeFilterOptions,
                        onChange: onChangeMenu,
                        value: menuType
                    },
                    {
                        options: ["Count", "Frequency"],
                        value: chartType,
                        onChange: onChangeMenu
                    }
                ]
            }
            filterProperties={
                {
                    enableFilter: config.query,
                    onFilter: onFilterChange
                }
            }
            editProperties={
                {
                    edited: edit,
                    edit: config.learning,
                    onEdit: onEdit
                }
            }
            ruleProperties={
                {
                    edit: true,
                    onRule: onRuleChange,
                    rule: isActive
                }
            }
            id={appConstants.charts.validvaluescheck.id}
            title={`${appConstants.charts.validvaluescheck.name} ${chartType}`}
            chartData={chartData}>
            <Grid container className={classNames(classes.chartStyles, "value-chart")} />
        </ChartContainer >
    );
};

ValueChart.propTypes = {
    classes: PropTypes.object,
    profileData: PropTypes.object,
    theme: PropTypes.object,
    config: PropTypes.object,
    profileRuleEnable: PropTypes.func,
    isActive: PropTypes.bool,
    onFilter: PropTypes.func,
    attribute: PropTypes.object,
    tabIndex: PropTypes.number,
    attributeChange: PropTypes.bool,
    datasetId: PropTypes.string,
    invalidQuery: PropTypes.string
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ValueChart);