import React, { memo, useCallback, useState } from 'react';
import {
    Grid, withStyles, Typography,
    Card, Table, TableBody, TableCell, TableRow, TableHead, IconButton
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import { getDataTypeColor } from '../../helpers/appHelpers.js';
import DomainStyles from '../Domain/DomainStyles.jsx';
import DatasetStyles from '../Dataset/DatasetStyles.jsx';

const DataTable = (props) => {
    const { classes, theme, previewData, headers, enableFunctionDialog } = props;
    const [sortingAttribute, setSortingAttribute] = useState('');
    const [orderBy, setOrderBy] = useState(0);
    let inputData = previewData;

    const sortBy = useCallback((attribute) => {
        if (attribute === sortingAttribute) {
            const order = orderBy === 1 ? -1 : 1;
            setOrderBy(order);
            setSortingAttribute(attribute);

        } else {
            setOrderBy(1);
            setSortingAttribute(attribute);
        }

    }, [orderBy, sortingAttribute]);

    if (inputData && inputData.length > 0 && sortingAttribute && orderBy) {
        inputData = inputData.sort((a, b) => {
            let order = 0;
            if (a[sortingAttribute] > b[sortingAttribute]) {
                order = 1;
            } else {
                order = -1;
            }
            return (order * orderBy);
        });
    }
    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classNames(classes.attributesTableContainer, classes.marginTop15, classes.dataContainer, previewData.attributes && previewData.attributes.length <= 0 ? 'loading' : '')} style={{ position: 'relative' }}>
                {
                    headers && headers.length > 0 &&
                    <Grid>
                        <Card className={classes.accordian}>
                            <Grid container className={classNames(classes.attributesTable)} id="data-table">
                                <Table stickyHeader aria-label="sticky table" className={classes.domainDataTable} cellSpacing={1}>
                                    <TableHead>
                                        <TableRow>
                                            {
                                                headers &&
                                                headers.map((header, index) => {
                                                    return (
                                                        <TableCell key={`header${index}`} align="center" style={{ padding: '2px 10px 2px 10px', backgroundColor: !header.isTransformAttribute ? theme.palette.common.white : theme.palette.background.light }}>
                                                            <Grid className={classes.header}>
                                                                <IconButton className={classes.nopadding} onClick={(event) => enableFunctionDialog(event, header)}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                                        <g id="Group_3919" data-name="Group 3919" transform="translate(-184 -175)">
                                                                            <g id="Ellipse_1869" data-name="Ellipse 1869" transform="translate(184 175)" fill="#f6f8f9" stroke="#e1e5e6" strokeWidth="1">
                                                                                <circle cx="9" cy="9" r="9" stroke="none" />
                                                                                <circle cx="9" cy="9" r="8.5" fill="none" />
                                                                            </g>
                                                                            <path id="symbol_1_" data-name="symbol (1)" d="M64.446,8.691H59.015a.542.542,0,0,1-.443-.856l2.491-3.5L58.573.857A.542.542,0,0,1,59.015,0h5.432a.543.543,0,0,1,.543.542V1.627H63.9V1.084H60.07l2.1,2.938a.542.542,0,0,1,0,.63L60.068,7.606H63.9V7.061h1.086V8.148A.543.543,0,0,1,64.446,8.691Z" transform="translate(131.529 180)" fill="#afb2b3" />
                                                                        </g>
                                                                    </svg>
                                                                </IconButton>
                                                                <Typography className={classNames(classes.AttritypeTitleTxt)} style={{ textTransform: 'none', cursor: 'pointer' }} onClick={() => sortBy(header.name)}>
                                                                    {header.name ? header.name : ""}
                                                                </Typography>
                                                            </Grid>
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                        <TableRow>
                                            {
                                                headers &&
                                                headers.map((header, index) => {
                                                    return (
                                                        <TableCell key={`datatype${index}`} align="center" style={{ padding: 6, backgroundColor: !header.isTransformAttribute ? theme.palette.common.white : theme.palette.background.light }}>
                                                            <Grid style={{ display: 'flex' }}>
                                                                <Grid className={classes.typeIcon}
                                                                    style={
                                                                        {
                                                                            color: getDataTypeColor(header.datatype ? header.datatype : ''),
                                                                            marginRight: '10px'
                                                                        }
                                                                    }>
                                                                    {header.datatype ? header.datatype[0].toUpperCase() : 'T'}
                                                                </Grid>
                                                                <Typography className={classNames(classes.typeText, classes.AttritypeText)}>
                                                                    {header.datatype ? header.datatype : ""}
                                                                </Typography>
                                                            </Grid>
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            inputData &&
                                            inputData.map((row, index) => (
                                                <TableRow key={`invalidInputData${index}`}>
                                                    {
                                                        headers &&
                                                        headers.map((header, index) => {
                                                            return (
                                                                <TableCell key={`row_${index}`}
                                                                    style={{ backgroundColor: !header.isTransformAttribute ? theme.palette.common.white : theme.palette.background.light }}
                                                                >
                                                                    <Typography noWrap style={{ height: row[header.name] === "" ? 'auto' : '42px', backgroundColor: !header.isTransformAttribute ? theme.palette.common.white : theme.palette.background.ligh }}>
                                                                        {row[header.name]}
                                                                    </Typography>
                                                                </TableCell>
                                                            );
                                                        })
                                                    }
                                                </TableRow>
                                            ))
                                        }

                                    </TableBody>
                                </Table>
                            </Grid>
                        </Card>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};

DataTable.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    previewData: PropTypes.object,
    headers: PropTypes.array,
    enableFunctionDialog: PropTypes.func
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...DatasetStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(memo(DataTable));