import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Avatar } from '@material-ui/core';
import DashboardStyles from './DashboardStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import { getUserAnalysis } from '../../actions/scheduleActions';
import GroupedBarChart from '../Charts/GroupedBarChart.jsx';
import { appConstants } from '../../constants/appConstants';
import { getRandomColor, getInitials, returnImage } from '../../helpers/appHelpers';

const DashboardCharts = (props) => {
    const { classes, theme } = props;
    const dispatch = useDispatch();
    const [userList, setUserList] = useState([]);
    const [userAnalysis, setUserAnalysis] = useState({});
    const legends = [{ name: 'Public', value: '(Level 1)', color: theme.palette.groupChart[0] }, { name: 'Private', value: ' (Level 2)', color: theme.palette.groupChart[1] }, { name: 'Restricted', value: '(Level 3)', color: theme.palette.groupChart[2] }];

    const getUserAnalysisData = useCallback(() => {
        dispatch(getUserAnalysis()).then((response) => {
            if (response) {
                if (response.users.length) {
                    const users = response.users.sort((a, b) => b.user_count - a.user_count);
                    setUserList([...users]);
                }
                if (response.analysis) {
                    const analysis = {
                        groups: ["Datasource", "Dataset"],
                        subgroups: [],
                        data: []
                    };
                    for (const d of response.analysis) {
                        analysis.data.push({
                            group: d.name
                        });
                        for (const value of d.values) {
                            analysis.data[analysis.data.length - 1][value.name] = value.value;
                            analysis.data[analysis.data.length - 1].value = value.value;
                            analysis.subgroups.push(value.name);
                        }
                    }
                    setUserAnalysis({ ...analysis });
                }
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getUserAnalysisData();
    }, [getUserAnalysisData]);

    const splitName = (email) => {
        const name = email.split("@");
        return name[0];
    };

    return (
        <Grid className={classes.section} style={{ height: '100%' }}>
            <Typography variant="h4" component="h4">
                User Analysis
            </Typography>
            <GroupedBarChart
                chartClassName="user-analysis-chart"
                analysis={userAnalysis}
                chartHeight={100}
                colors={theme.palette.groupChart}
                legends={legends}
                tooltipName="Sensitivity Level" />

            <Typography variant="h4" component="h4" className={classes.marginTop10}>
                Most Engaged Users
            </Typography>
            <Grid className={classes.engagedContainer}>
                {
                    userList.map((user, index) =>
                        <Grid className={classes.avatarContainer} key={index}>
                            <Avatar
                                style={
                                    {
                                        backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, user.avatar)})`,
                                        backgroundColor: getRandomColor(user.first_name !== "" ? `${user.first_name} ${user.last_name}` : user.email)
                                    }
                                }>
                                {
                                    !user.avatar &&
                                    <span className={classes.avatarLetter}>
                                        {getInitials(user.first_name !== "" ? `${user.first_name} ${user.last_name}` : splitName(user.email))}
                                    </span>
                                }

                            </Avatar>
                            <Typography className={classes.avatarUserTxt}>
                                {user.first_name !== "" ? `${user.first_name} ${user.last_name}` : splitName(user.email)}
                            </Typography>
                        </Grid>
                    )
                }
            </Grid>
        </Grid>
    );
};

DashboardCharts.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DashboardCharts);