import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography, withStyles, IconButton, Menu, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import DashboardStyles from './DashboardStyles.jsx';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WidgetSettingsView from './WidgetSettingsView.jsx';
import WidgetChartView from './WidgetChartView.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import classNames from 'classnames';

const DashboardWidget = (props) => {
    const { classes, widget, onChangeSettings, onSaveSettings, onDeleteWidget, dashboard, history, refresh } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentWidget, setCurrentWidget] = useState(null);

    const handleMenuClick = useCallback((event) => {
        setCurrentWidget({ ...currentWidget, isModified: false, isLayoutUpdated: false });
        setAnchorEl(event.currentTarget);
    }, [currentWidget]);

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onSettingsSelect = useCallback(() => {
        handleMenuClose();
        setCurrentWidget({ ...currentWidget, isSettings: true });
    }, [currentWidget, handleMenuClose]);

    const onCancelSettings = useCallback(() => {
        handleMenuClose();
        setCurrentWidget({ ...currentWidget, isSettings: false, isModified: true, isCancelled: false });
    }, [currentWidget, handleMenuClose]);

    const onSettingsChange = useCallback((wigdetId, property, value) => {
        const widget = { ...currentWidget, property: value, isModified: true };
        setCurrentWidget(widget);
        onChangeSettings(wigdetId, widget);
    }, [currentWidget, onChangeSettings]);

    const onSave = useCallback((widget) => {
        setCurrentWidget({ ...widget, isNew: false, isSettings: false, isModified: true, isCancelled: false });
        if (onSaveSettings) {
            onSaveSettings(widget);
        }
    }, [onSaveSettings]);

    const loadWidget = useCallback((widget) => {
        setCurrentWidget(widget);
    }, []);

    useEffect(() => {
        if (widget && !currentWidget) {
            loadWidget(widget);
        }
    }, [currentWidget, loadWidget, widget]);

    const widgetName = () => {
        const widgetBox = document.getElementById(currentWidget?.i);
        if (widgetBox && widgetBox.offsetWidth < 200 && currentWidget?.name && currentWidget?.name.length > 10) {
            let name = currentWidget?.name.slice(0, 8);
            name = `${name}...`;
            return name;
        }
        return currentWidget?.name;
    };

    return (
        <React.Fragment>
            <Grid container id={currentWidget?.i} className={classes.widgetContainer} justify="flex-start">
                <Grid item xs={12}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <Grid item xs={12}>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item className={classNames(classes.flex, classes.alignCenter)}>
                                    <IconButton size="small" edge="end" disabled="true">
                                        <DragIndicatorIcon className={classes.iconLight} />
                                    </IconButton>
                                    <Grid style={{ marginLeft: 8 }}>
                                        <ToolTipComponent title={currentWidget?.name} arrow placement="top">
                                            <Typography component="h4" variant="h4" style={{ cursor: 'alias' }} className={classes.widgetTitle}>
                                                {currentWidget?.isSettings ? "Add Widget" : widgetName()}
                                            </Typography>
                                        </ToolTipComponent>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <IconButton size="small" edge="end" aria-controls="simple-menu" aria-haspopup="true" onClick={handleMenuClick}>
                                        <MoreVertIcon className={classes.iconDark} />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={classes.widgetContainer}>
                    {
                        currentWidget && currentWidget.isSettings &&
                        <WidgetSettingsView widget={currentWidget} onChangeSettings={onSettingsChange} onSaveSettings={onSave} onCancelSettings={onCancelSettings} />
                    }
                    {
                        currentWidget && !currentWidget.isSettings &&
                        <WidgetChartView refresh={refresh} dashboard={dashboard} widget={currentWidget} history={history} />
                    }
                </Grid>
            </Grid>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
                PaperProps={
                    {
                        style: {
                            width: '16ch'
                        }
                    }
                }
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
                {
                    currentWidget && !currentWidget.isSettings &&
                    <MenuItem onClick={onSettingsSelect}>
                        Settings
                    </MenuItem>
                }
                <MenuItem onClick={() => onDeleteWidget(currentWidget?.i)}>
                    Delete
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

DashboardWidget.propTypes = {
    classes: PropTypes.object,
    widget: PropTypes.object,
    onChangeSettings: PropTypes.func,
    onSaveSettings: PropTypes.func,
    onDeleteWidget: PropTypes.func,
    dashboard: PropTypes.object,
    history: PropTypes.object,
    refresh: PropTypes.string
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DashboardWidget);