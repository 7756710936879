import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, withStyles, IconButton, Table, TableBody, TableHead, TableCell, TableRow, Typography, TextField } from '@material-ui/core';
import classnames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import SemanticGlossaryStyles from './SemanticGlossaryStyles.jsx';
import { getAssetFields, linkAssets, unlinkAssetField } from '../../actions/modelActions';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import Loader from '../Loaders/Loader.jsx';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import { appConstants } from '../../constants/appConstants.js';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const SemanticFieldAssets = (props) => {

    const { classes, theme, search, attributeList } = props;
    const [assets, setAssets] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [isLoading] = useState(false);
    const dispatch = useDispatch();
    const glossary = useSelector(({ model }) => model.selectedGlossary);
    const attributeLoad = useSelector(({ dataset }) => dataset.attributeLoading);

    const prepareAttributes = () => {
        const attributeIdList = [...assets, ...attributes].map((attribute) => attribute.attribute_id);
        return attributeList.filter((attribute) => !attributeIdList.includes(attribute.attribute_id));
    };

    const onChangeAttributes = (value) => {
        setAttributes([...value]);
    };

    const linkAssetDetail = () => {
        if (attributes.length) {
            const requestParams = {
                "fieldtype": glossary.name,
                "attribute_id": attributes.map((attribute) => attribute.attribute_id)
            };
            dispatch(linkAssets(requestParams));
            const attributeItems = attributes.map((attribute) => {
                return {
                    ...attribute,
                    name: attribute.attribute_name
                };
            });
            setAssets([...assets, ...attributeItems]);
            setAttributes([]);
        }
    };

    const unlinkAsset = (assetId) => {
        const index = assets.findIndex((asset) => asset.attribute_id === assetId);
        if (index !== -1) {
            dispatch(unlinkAssetField(assets[index].attribute_id));
            assets.splice(index, 1);
            setAssets([...assets]);
        }
    };

    const getFields = useCallback(() => {
        dispatch(getAssetFields(glossary.name)).then((response) => {
            if (response) {
                setAssets([...response]);
            }
        });
    }, [dispatch, glossary.name]);

    useEffect(() => {
        getFields();
    }, [getFields]);

    const assetList = search.length ? assets.filter((asset) => asset.name.toLowerCase().includes(search.toLowerCase()) || asset.dataset_name.toLowerCase().includes(search.toLowerCase()) || asset.source_name.toLowerCase().includes(search.toLowerCase())) : assets;
    return (
        <Grid className={classes.relative}>
            <Grid container justify="space-between" alignItems="center" className={classes.marginTop10}>
                <Grid item>
                    <Typography className={classes.lightTxt}>
                        User able to link/ Unlink the attribute here
                    </Typography>
                </Grid>
                {
                    glossary.status === appConstants.glossaryStatus[0].value && !attributeLoad &&
                    <Grid item xs={4} onMouseLeave={() => linkAssetDetail()}>
                        <AutoCompleteInput
                            name="attribute"
                            popperWidth={300}
                            options={prepareAttributes()}
                            getOptionLabel={
                                (option) => {
                                    if (option && option.name) {
                                        return option.name;
                                    }
                                    return option;
                                }
                            }
                            value={attributes ? attributes : []}
                            getOptionSelected={
                                (option, value) => {
                                    if (option && value) {
                                        return option.attribute_id === value.attribute_id;
                                    }
                                    return false;
                                }
                            }
                            renderInput={
                                (params) => <TextField {...params}
                                    className={classnames(classes.inputOutline, classes.assetInput, classes.fontSize13, classes.hoverBorderNone, classes.semanticsMultiSelect)}
                                    placeholder="Select and Add Assets"
                                />
                            }
                            onChange={(_, selectedValue) => onChangeAttributes(selectedValue)}
                            openOnFocus
                            blurOnSelect
                            disableClearable
                            multiple
                            fullWidth
                            forcePopupIcon={false}
                            noOptionsText={""}
                        />
                    </Grid>
                }
                {
                    attributeLoad &&
                    <Loader size={'extraSmall'} type={'button'} classList={classes.relative} />
                }
            </Grid>
            <Grid className={classnames(classes.marginTop10, classes.assetTable, classes.tableWrapperStyle)}>
                {
                    assetList.length ?
                        <Table stickyHeader className={classnames(classes.standardTable, classes.tableStyle)}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeadCell}>
                                        <Typography className={classes.assetTableHeaderText}>
                                            {'Datasource'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableHeadCell}>
                                        <Typography className={classes.assetTableHeaderText}>
                                            {'Dataset'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={`${classes.tableHeadCell} ${classes.attributeCell}`}>
                                        <Typography className={classes.assetTableHeaderText}>
                                            {'Attribute'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableHeadCell} align="center">
                                        <Typography className={classes.assetTableHeaderText}>
                                            {'Action'}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    assetList.map((asset, index) =>
                                        <TableRow key={index}>
                                            <TableCell className={classes.tableCell}>
                                                <ToolTipComponent title={asset.source_name} arrow placement="bottom-start">
                                                    <Typography className={classes.smallFontSize} nowrap>
                                                        {asset.source_name}
                                                    </Typography>
                                                </ToolTipComponent>
                                            </TableCell>
                                            <TableCell className={classes.tableCell}>
                                                <ToolTipComponent title={asset.dataset_name} arrow placement="bottom-start">
                                                    <Typography className={classes.smallFontSize} noWrap>
                                                        {asset.dataset_name}
                                                    </Typography>
                                                </ToolTipComponent>
                                            </TableCell>
                                            <TableCell className={`${classes.tableCell} ${classes.attributeCell}`}>
                                                <ToolTipComponent title={asset.name} arrow placement="bottom-start">
                                                    <Typography className={classes.smallFontSize} noWrap>
                                                        {asset.name}
                                                    </Typography>
                                                </ToolTipComponent>
                                            </TableCell>
                                            <TableCell align="center">
                                                <ToolTipComponent title={"Unlink"} arrow>
                                                    <IconButton className={classes.nopadding} onClick={() => unlinkAsset(asset.attribute_id)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15.994" viewBox="0 0 16 15.994">
                                                            <g id="broken-link_2_" data-name="broken-link (2)" transform="translate(-0.001 -0.104)">
                                                                <path id="Path_2560" data-name="Path 2560" d="M199.014,5.528a.311.311,0,0,0,.44,0l2.589-2.588a1.978,1.978,0,0,1,2.8,2.8l-2.59,2.589a.309.309,0,0,0,0,.437l1.063,1.063a.311.311,0,0,0,.44,0L206.477,7.1a4.1,4.1,0,0,0-5.8-5.8l-2.725,2.724a.309.309,0,0,0,0,.437Z" transform="translate(-191.677 0)" fill={theme.palette.primary.main} />
                                                                <path id="Path_2561" data-name="Path 2561" d="M8.564,205.555a.311.311,0,0,0-.44,0l-2.487,2.486a1.978,1.978,0,0,1-2.8-2.8l2.488-2.487a.309.309,0,0,0,0-.437l-1.063-1.063a.311.311,0,0,0-.44,0L1.2,203.879a4.1,4.1,0,0,0,5.8,5.8l2.624-2.622a.309.309,0,0,0,0-.437Z" transform="translate(0 -194.781)" fill={theme.palette.primary.main} />
                                                                <path id="Path_2562" data-name="Path 2562" d="M.567,139.77l2.2.55A.469.469,0,1,0,3,139.41l-2.2-.55a.469.469,0,0,0-.227.909Z" transform="translate(-0.205 -134.408)" fill={theme.palette.primary.main} />
                                                                <path id="Path_2563" data-name="Path 2563" d="M60.784,61.525a.469.469,0,0,0,.663-.663L59.74,59.157a.469.469,0,1,0-.663.663Z" transform="translate(-57.098 -57.075)" fill={theme.palette.primary.main} />
                                                                <path id="Path_2564" data-name="Path 2564" d="M139.365,3.1a.469.469,0,0,0,.909-.227l-.55-2.2a.469.469,0,1,0-.909.227Z" transform="translate(-134.463 -0.208)" fill={theme.palette.primary.main} />
                                                                <path id="Path_2565" data-name="Path 2565" d="M414.2,326.205l-2.2-.55a.469.469,0,1,0-.227.909l2.2.55a.469.469,0,1,0,.227-.909Z" transform="translate(-398.558 -315.366)" fill={theme.palette.primary.main} />
                                                                <path id="Path_2566" data-name="Path 2566" d="M369.35,368.63a.469.469,0,0,0-.663.663L370.394,371a.469.469,0,0,0,.663-.663Z" transform="translate(-357.034 -356.879)" fill={theme.palette.primary.main} />
                                                                <path id="Path_2567" data-name="Path 2567" d="M326.6,411.693a.469.469,0,1,0-.909.227l.55,2.2a.469.469,0,0,0,.909-.227Z" transform="translate(-315.502 -398.386)" fill={theme.palette.primary.main} />
                                                            </g>
                                                        </svg>
                                                        {/* <LinkOffIcon /> */}
                                                    </IconButton>
                                                </ToolTipComponent>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table> :
                        <NoResultFound />
                }
            </Grid>
            {isLoading && <Loader />}
        </Grid>
    );
};
SemanticFieldAssets.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    search: PropTypes.string,
    attributeList: PropTypes.array
};

export default withStyles((theme) => ({
    ...SemanticGlossaryStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SemanticFieldAssets);