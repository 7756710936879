import React from 'react';
import { Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Styles from '../../layouts/Styles.jsx';
import SemanticGlossaryStyles from './SemanticGlossaryStyles.jsx';
import CategoryIcon from '../../assets/images/folder_red.svg';
import FileIcon from '../../assets/images/termbig.svg';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';

import Verified from '../../assets/images/verified.svg';
import InProgess from '../../assets/images/wip.svg';
import Deprecated from '../../assets/images/deprecated.svg';
import Issues from '../../assets/images/issues.svg';
import { sortArrayObjectString } from '../../helpers/appHelpers.js';
const SemanticGlossaryCategoryList = (props) => {

    const { classes, categories, redirect, filters: { search = '', sort = '', type = '', status = '', tags = [] } } = props;

    const renderStatusIcon = (status) => {
        if (status.toLowerCase() === "verified") {
            return Verified;
        } else if (status.toLowerCase() === "work in progress") {
            return InProgess;
        } else if (status.toLowerCase() === "issues") {
            return Issues;
        }
        return Deprecated;
    };

    const filterList = () => {
        let categoryList = categories;
        if (search && search.trim()) {
            categoryList = categoryList.filter((elem) => elem.name.toLowerCase().includes(search.trim().toLowerCase()));
        }
        if (type) {
            categoryList = categoryList.filter((elem) => elem.semantic_type === type);
        }
        if (status) {
            categoryList = categoryList.filter((elem) => elem.status === status);
        }
        if (tags && tags.length > 0) {
            //tags.some((elem) => tags.includes(elem))
            categoryList = categoryList.filter((elem) => elem.tags.some((tag) => tags.includes(tag)));
        }
        if (sort) {
            const sortArray = sort.split('_');
            return sortArrayObjectString(categoryList, sortArray[0], sortArray[1]);
        }
        return categoryList;
    };
    let categoryList = filterList();
    categoryList = categoryList.sort((a, b) => a.semantic_type.localeCompare(b.semantic_type));
    return (
        <Grid>
            <Grid className={classnames(classes.categoryListContainer)}>
                {
                    categoryList.length === 0 &&
                    <NoResultFound />
                }
                {
                    categoryList.map((category, index) =>
                        <Grid container key={index} className={classes.glossaryList}>
                            <Grid item className={classes.grid11}>
                                <Grid className={classes.flex}>
                                    <img className={classes.marginRight10} src={category.semantic_type.toLowerCase() === "category" ? CategoryIcon : FileIcon} alt="" />
                                    <Typography variant="h4" component="h4" className={classes.titleTxt} onClick={() => redirect(category)}>
                                        {category.name}
                                    </Typography>
                                </Grid>
                                <Typography className={classnames(classes.listDescriptionText, "description")}>
                                    {category.description}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.grid1} align="center">
                                <Grid className={classes.flex}>
                                    <img src={renderStatusIcon(category.status ? category.status : "Verified")} alt="" className={classes.marginRight5} />
                                    <Typography className={classes.capitalize}>
                                        {category.status ? category.status : "Verified"}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                }
            </Grid>
        </Grid>
    );

};

SemanticGlossaryCategoryList.propTypes = {
    classes: PropTypes.object,
    categories: PropTypes.array,
    redirect: PropTypes.func,
    filters: PropTypes.object
};

export default withStyles((theme) => ({
    ...SemanticGlossaryStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SemanticGlossaryCategoryList);