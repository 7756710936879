import React from 'react';
import { Grid, withStyles, Typography, AccordionSummary } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getValue } from '../../helpers/appHelpers';
import Linear from '../Charts/Linear.jsx';
import Styles from '../../layouts/Styles.jsx';
import DomainStyles from './DomainStyles.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import theme from '../../theme';
import { useSelector } from 'react-redux';
// import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const DomainDatasetListView = (props) => {
    const { classes, header, type, count, openDialog, redirect, isInvalidDataset, redirectData, dq_score_threshold } = props;

    header.invalid_percentage = ((header.invalid_records || 0) / header.total_records) * 100;
    const userConfig = useSelector(({ setting }) => setting.user_config);

    return (
        <Grid container>
            <Grid item className={classes.gridNameContainer}>
                <AccordionSummary
                    // className={classes.accordianSummary}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid className={classNames(classes.datasetTitleSection, classes.noPaddingLeft)}>
                        {/* {
                            (type === 'dataset' && !group_by_datasource) &&
                            <Fragment>
                                <Typography component="span">
                                    {`${datasource}`}
                                </Typography>
                                <Typography component="span">
                                    {`|`}
                                </Typography>
                            </Fragment>
                        } */}
                        <Typography component="span" onClick={() => redirect(header, type)} className={classNames(classes.anchorLink, classes.domainHeader)}>
                            {`${header.name} (${count})`}
                        </Typography>
                        {
                            type === "attribute" &&
                            <Typography className={classes.fieldTxt} onClick={() => redirectData()}>
                                {header && header.fieldtype ? header.fieldtype : ""}
                            </Typography>
                        }
                        {
                            type === 'dataset' && userConfig.include_organization_domain_score && !header.include_organization_domain_score &&
                            <ToolTipComponent title={"DQScore is not included"} arrow>
                                <span>
                                    <svg id="information" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                                        <path id="Path_15040" data-name="Path 15040" d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,18.182A8.182,8.182,0,1,1,18.182,10,8.191,8.191,0,0,1,10,18.182Z" fill={theme.palette.secondary.main} />
                                        <path id="Path_15041" data-name="Path 15041" d="M146.214,70a1.212,1.212,0,1,0,1.212,1.213A1.214,1.214,0,0,0,146.214,70Z" transform="translate(-136.214 -65.758)" fill={theme.palette.secondary.main} />
                                        <path id="Path_15042" data-name="Path 15042" d="M150.909,140a.909.909,0,0,0-.909.909v5.455a.909.909,0,1,0,1.818,0v-5.455A.909.909,0,0,0,150.909,140Z" transform="translate(-140.909 -131.515)" fill={theme.palette.secondary.main} />
                                    </svg>
                                </span>
                            </ToolTipComponent>
                        }

                    </Grid>
                </AccordionSummary>
            </Grid>
            <Grid item className={classes.countContainer} align="center">
                <AccordionSummary
                    // className={classes.accordianSummary}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Grid className={classNames(type === "attribute" ? classes.attributeCountText : '', type === "dataset" ? classes.datasetCountText : '', type === "datasource" ? classes.sourceCountText : '', classes.noPaddingLeft)}>
                        <Typography component="span">
                            {header && header.total_records ? header.total_records : 0}
                        </Typography>
                    </Grid>
                </AccordionSummary>
            </Grid>
            {
                isInvalidDataset && type !== 'datasource' &&
                <Grid item className={classes.countContainer} align="center">
                    <AccordionSummary
                        // className={classes.accordianSummary}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid className={classNames(type === "attribute" ? classes.attributeCountText : '', type === "dataset" ? classes.datasetCountText : '', type === "datasource" ? classes.sourceCountText : '', classes.noPaddingLeft)}>
                            {/* {
                                header && header.rule_id && header.invalid_records === "NA" &&
                                <Typography className={classes.invalidRecordText} component="span">
                                    {'NA'}
                                </Typography>
                            } */}
                            {
                                // header && header.rule_id && header.invalid_records !== "NA" &&
                                header &&
                                <Typography className={classes.invalidRecordText} component="span" onClick={(event) => openDialog(event, header, type, "Invalid_Records")}>
                                    {
                                        header?.invalid_records === 'N/A' ?
                                            <b>
                                                {header?.invalid_records}
                                            </b>
                                            :
                                            `${header?.invalid_records || 0} (${getValue(header?.invalid_percentage || 0)}%)`
                                    }
                                </Typography>
                            }
                        </Grid>
                    </AccordionSummary>
                </Grid>
            }
            {
                (!isInvalidDataset || type === 'datasource') &&
                <Grid item className={classes.countContainer} align="center" />
            }
            {
                isInvalidDataset && type !== 'datasource' &&
                <Grid item className={classes.countContainer} align="center">
                    <AccordionSummary
                        // className={classes.accordianSummary}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Grid className={classNames(type === "attribute" ? classes.attributeCountText : '', type === "dataset" ? classes.datasetCountText : '', type === "datasource" ? classes.sourceCountText : '', classes.noPaddingLeft)}>
                            {/* {
                                header && header.rule_id && header.invalid_records === "NA" &&
                                <Typography className={classes.invalidRecordText} component="span">
                                    {'NA'}
                                </Typography>
                            } */}
                            {
                                // header && header.rule_id && header.invalid_records !== "NA" &&
                                header &&
                                <Typography className={classes.invalidRecordText} component="span" onClick={(event) => openDialog(event, header, type, "Valid_Records")}>
                                    {
                                        header?.invalid_records === 'N/A' ?
                                            <b>
                                                {header?.invalid_records}
                                            </b>
                                            :
                                            `${(header?.total_records - header?.invalid_records) < 0 ? header?.invalid_records || 0 : (header?.total_records - header?.invalid_records)} (${getValue(100 - (header?.invalid_percentage || 0))}%)`
                                    }
                                </Typography>
                            }
                        </Grid>
                    </AccordionSummary>
                </Grid>
            }
            {
                (!isInvalidDataset || type === 'datasource') &&
                <Grid item className={classes.countContainer} align="center" />
            }
            <Grid item className={classes.gridScoreContainer}>
                <AccordionSummary
                    // className={classes.accordianSummary}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {
                        (type === 'dataset' || type === 'attribute') &&
                        <Linear showPercentage value={header.dqscore || 'N/A'} dqScoreThreshold={dq_score_threshold} />
                    }
                    {
                        (type === 'datasource') &&
                        <Linear showPercentage value={header?.dqscore || 'N/A'} dqScoreThreshold={dq_score_threshold} />
                    }
                </AccordionSummary>
            </Grid>
            <Grid item className={classes.gridNameContainer} />
            <Grid item align="right" className={classes.actionContainer}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    // className={classes.accordianSummary}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    {/* {
                        !download &&
                        <ToolTipComponent title="Download" arrow>
                            <IconButton className={classes.iconButtonSmall}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 21 21">
                                    <g id="download_4_" data-name="download (4)" transform="translate(-1.5 -1.5)">
                                        <path id="Path_14400" data-name="Path 14400" d="M22,23.714v2.857A1.43,1.43,0,0,1,20.571,28H3.428A1.43,1.43,0,0,1,2,26.572V23.714a.714.714,0,0,1,1.428,0v2.857H20.571V23.714a.714.714,0,0,1,1.429,0Z" transform="translate(0 -6)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                        <path id="Path_14401" data-name="Path 14401" d="M8.21,12.5a.714.714,0,1,1,1.01-1.01L13,15.275V2.714a.714.714,0,1,1,1.428,0V15.275l3.781-3.78a.714.714,0,1,1,1.01,1.01l-5,5a.715.715,0,0,1-1.011,0Z" transform="translate(-1.715)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                    </g>
                                </svg>
                            </IconButton>
                        </ToolTipComponent>
                    } */}
                </AccordionSummary>
            </Grid>
        </Grid>
    );
};

DomainDatasetListView.propTypes = {
    classes: PropTypes.object,
    header: PropTypes.object,
    type: PropTypes.string,
    count: PropTypes.number,
    openDialog: PropTypes.func,
    redirect: PropTypes.func,
    redirectData: PropTypes.func,
    isInvalidDataset: PropTypes.bool,
    dq_score_threshold: PropTypes.object
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DomainDatasetListView);