import React from 'react';
import { Grid, makeStyles, Button, Typography, FormControlLabel, Switch } from '@material-ui/core';
import TextBox from '../TextBox/TextBox.jsx';
import PropTypes from 'prop-types';
import CheckboxComponent from '../ChecboxComponent/CheckboxComponent.jsx';

const Preview = (props) => {
    const { configuration } = props;
    const styles = makeStyles({
        submitBtn: {
            color: '#fff',
            backgroundColor: configuration.primary_color,
            borderRadius: 0,
            padding: "6px 20px",
            margin: "20px 10px 10px 0px",
            fontFamily: configuration.heading_font
        },
        cancelBtn: {
            backgroundColor: configuration.light,
            borderRadius: 0,
            padding: "6px 20px",
            margin: "20px 0px 10px 0px",
            fontFamily: configuration.heading_font,
            color: "#fff",
            '&:hover': {
                backgroundColor: configuration.light
            }
        },
        previewContainer: {
            padding: "20px",
            background: '#FFFFFF',
            boxShadow: '0px 2px 30px #CFE0E799',
            border: '1px dashed #DBE2E5'
        },
        previewBodyContainer: {
            background: "#F6F8F9",
            padding: "20px"
        },
        progressBar: {
            height: '14px',
            background: '#54DF9A',
            flexGrow: '1'
        },
        labelContainer: {
            '& .MuiCheckbox-colorSecondary.Mui-checked': {
                color: configuration.primary_color
            }
        },
        switchContainer: {
            '.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
                color: configuration.primary_color
            },
            '& .MuiCheckbox-colorSecondary.Mui-checked': {
                color: configuration.primary_color
            }
        },
        textBoxContainer: {
            margin: '10px 0px 10px 0px',
            '& .MuiInputLabel-root': {
                color: configuration.primary_color
            },
            '& .MuiInput-underline:before': {
                borderBottom: `2px solid ${configuration.primary_color}`
            }
        },
        marginBottom20: {
            marginBottom: 20
        },
        marginBottom15: {
            marginBottom: 15
        },
        formLabel: {
            '& .MuiTypography-body1': {
                fontSize: 13,
                color: '#222'
            }
        },
        heading: {
            color: configuration.darkTxt,
            fontFamily: configuration.heading_font
        },
        title: {
            fontFamily: configuration.heading_font
        },
        body: {
            fontFamily: configuration.text_font
        }

    });

    const classes = styles();

    return (
        <Grid container className={classes.previewContainer}>
            <Grid item sm={12} className={classes.marginBottom20}>
                <Grid container alignItems="center" justify="center" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="25.432" viewBox="0 0 28 25.432">
                        <g transform="translate(0 -2.415)">
                            <g transform="translate(0 2.415)">
                                <path fill={configuration.secondary_color} d="M17.79,23.723A8.777,8.777,0,0,1,9.47,17.709H4.811V15.99H9.088a8.652,8.652,0,0,1,.046-2.406H4.811V11.867H9.59a8.765,8.765,0,0,1,1.382-2.406H4.811V7.743h8.014a8.737,8.737,0,0,1,8.139-.949V2.415H0v22a3.447,3.447,0,0,0,3.437,3.437H17.528a3.447,3.447,0,0,0,3.437-3.437V23.125A8.715,8.715,0,0,1,17.79,23.723Z" transform="translate(0 -2.415)" />
                            </g>
                            <g transform="translate(10.743 7.914)">
                                <g transform="translate(0 0)">
                                    <path fill={configuration.secondary_color} d="M33.116,23.71a7.107,7.107,0,1,0-1.662,1.74l4.311,4.311,1.7-1.7Zm-5.862,1.42A5.327,5.327,0,1,1,32.581,19.8,5.333,5.333,0,0,1,27.254,25.13Z" transform="translate(-20.208 -12.758)" />
                                </g>
                            </g>
                        </g>
                    </svg>
                    <Typography variant="h5" className={classes.title}>
                        Preview Palette
                    </Typography>
                </Grid>
            </Grid>
            <Grid item sm={12} className={classes.marginBottom20}>
                <Grid container className={classes.previewBodyContainer}>
                    <Grid item sm={12} className={classes.marginBottom15}>
                        <Typography variant="h6" className={classes.heading}>
                            Heading
                        </Typography>
                        <Typography variant="body2" className={classes.body}>
                            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                        </Typography>
                    </Grid>
                    <Grid item sm={12} className={classes.marginBottom15}>
                        <TextBox
                            label="Label"
                            name="primary_colorcode"
                            value="Text"
                            fullWidth
                            className={classes.textBoxContainer}
                        />
                    </Grid>
                    <Grid item sm={12} className={classes.marginBottom20}>
                        <Grid container alignItems="center" justify="space-between" className={classes.switchContainer}>
                            {/* <FormControlLabel
                                control={
                                    <Checkbox
                                        checked
                                        name="Checkbox"
                                        color="Checkbox"
                                    />
                                }
                                label="Primary"
                            /> */}
                            <CheckboxComponent
                                checked
                                name="Checkbox"
                                color="Checkbox"
                                checkboxLabel="Primary"
                            />
                            <FormControlLabel
                                className={classes.formLabel}
                                control={
                                    <Switch size="small"
                                        color="secondary"
                                        name="on"
                                        value="on"
                                    />
                                }
                                label="Toggle"
                            />
                        </Grid>
                    </Grid>
                    <Grid item sm={12} className={classes.marginBottom20}>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Typography variant="body2" className={classes.body}>
                                    Datasource A
                                </Typography>
                            </Grid>
                            <Grid item className={classes.progressBar} />
                            <Grid item>
                                <Typography variant="body2" className={classes.body}>
                                    100%
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Typography variant="body2" className={classes.body}>
                                    Datasource A
                                </Typography>
                            </Grid>
                            <Grid item className={classes.progressBar} />
                            <Grid item>
                                <Typography variant="body2" className={classes.body}>
                                    100%
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Typography variant="body2" className={classes.body}>
                                    Datasource A
                                </Typography>
                            </Grid>
                            <Grid item className={classes.progressBar} />
                            <Grid item>
                                <Typography variant="body2" className={classes.body}>
                                    100%
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} className={classes.marginBottom20}>
                <Grid container justify="flex-end">
                    <Button size="small" variant="contained" color="primary" className={classes.submitBtn}>
                        Submit
                    </Button>
                    <Button size="small" className={classes.cancelBtn}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

Preview.propTypes = {
    configuration: PropTypes.object
};

export default Preview;