import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import Logo from '../../assets/images/dqicon_placeholder.svg';
import DatasourceStyles from '../Datasource/DatasourceStyles.jsx';
import TooltipComponent from '../Tooltip/Tooltip.jsx';


const SemanticModelInfo = (props) => {
    const { classes, model, theme, breadcrumb, onChangeModel, onSave, history, deleteModelData, exportModel } = props;

    return (
        <Fragment>
            <Grid container className={classes.marginBottom5}>
                {
                    breadcrumb && breadcrumb.map((crumb, index) =>
                        <Typography variant="body2" key={`crumb_${index}`} component="span" className={classes.link} onClick={() => (crumb.path !== "" ? history.push('/model') : null)}>
                            {crumb.name}
                            {index !== breadcrumb.length - 1 && ' | '}
                        </Typography>
                    )
                }
            </Grid>
            <Grid container justify="space-between">
                <Grid item className={classes.datasourceTitle}>
                    <Grid className={classes.defaultIconContainer}>
                        <img src={Logo} alt="logo" className={classNames(classes.datasourceIcon, classes.datasourceIconSelectImg)} />
                    </Grid>
                    <TextBox
                        name="name"
                        placeholder="Untitled Semantic Model"
                        value={model && model.name ? model.name : ''}
                        className={classNames(classes.inlinetxt, classes.headerTitleTxt)}
                        onChange={(event) => onChangeModel(event.target.name, event.target.value)}
                        onBlur={
                            (event) => {
                                if (event.target.getAttribute('aria-invalid') === 'true') {
                                    return;
                                }
                                if (event.target.value !== "") {
                                    onSave('name');
                                }
                            }
                        }
                        error={model && model.name === ""}
                        helperText={model && model.name === "" ? "Please enter the model name" : ""}
                        validators={['matchRegexp:^[A-Za-z][A-Za-z0-9]+$']}
                        errorMessages={['Please enter name without special character']}
                    />
                </Grid>
                <Grid item>

                    <TooltipComponent title={"Export Field"} arrow>
                        <IconButton className={classNames(classes.padding8, classes.marginRight5)} onClick={() => exportModel()}>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 67.671 67.671" width="18" height="18">
                                <g>
                                    <path fill={theme.palette.primary.main} d="M52.946,23.348H42.834v6h10.112c3.007,0,5.34,1.536,5.34,2.858v26.606c0,1.322-2.333,2.858-5.34,2.858H14.724   c-3.007,0-5.34-1.536-5.34-2.858V32.207c0-1.322,2.333-2.858,5.34-2.858h10.11v-6h-10.11c-6.359,0-11.34,3.891-11.34,8.858v26.606   c0,4.968,4.981,8.858,11.34,8.858h38.223c6.358,0,11.34-3.891,11.34-8.858V32.207C64.286,27.239,59.305,23.348,52.946,23.348z" />
                                    <path fill={theme.palette.primary.main} d="M24.957,14.955c0.768,0,1.535-0.293,2.121-0.879l3.756-3.756v13.028v6v11.494c0,1.657,1.343,3,3,3s3-1.343,3-3V29.348v-6   V10.117l3.959,3.959c0.586,0.586,1.354,0.879,2.121,0.879s1.535-0.293,2.121-0.879c1.172-1.171,1.172-3.071,0-4.242l-8.957-8.957   C35.492,0.291,34.725,0,33.958,0c-0.008,0-0.015,0-0.023,0s-0.015,0-0.023,0c-0.767,0-1.534,0.291-2.12,0.877l-8.957,8.957   c-1.172,1.171-1.172,3.071,0,4.242C23.422,14.662,24.189,14.955,24.957,14.955z" />
                                </g>
                            </svg>
                        </IconButton>
                    </TooltipComponent>
                    {
                        !model.is_default &&
                        <TooltipComponent title={"Delete Field"} arrow>
                            <IconButton className={classes.padding8} onClick={() => deleteModelData()}>
                                <svg version="1.1" id="Layer_1" width="22" height="22" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
                                    <g id="Delete">
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                    </g>
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                    }
                </Grid>
            </Grid>
        </Fragment>
    );
};


SemanticModelInfo.propTypes = {
    classes: PropTypes.object,
    breadcrumb: PropTypes.array,
    theme: PropTypes.object,
    model: PropTypes.object,
    onChangeModel: PropTypes.func,
    onSave: PropTypes.func,
    history: PropTypes.object,
    deleteModelData: PropTypes.func,
    exportModel: PropTypes.func
};

export default withStyles((theme) => ({
    ...DatasourceStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SemanticModelInfo);