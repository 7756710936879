import React, { useCallback, useEffect, useState, memo } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';
import { useSelector } from 'react-redux';

const CircleSpiderChart = (props) => {
    const { analysis, chartClassName, color, classes, width, height } = props;
    const [chartData, setChartData] = useState({});
    const { sidePanel, type } = useSelector((state) => state.notification);

    const loadProperties = useCallback((analysisData) => {
        setChartData({ ...analysisData });
    }, []);

    useEffect(() => {
        loadProperties({ ...analysis });
    }, [analysis, loadProperties]);

    useEffect(() => {
        if (chartData && Object.keys(chartData).length !== 0) {

            d3.select(`.${chartClassName} > *`).remove();

            d3.select(`.${chartClassName} >.tooltip`).remove();

            const keys = Object.keys(chartData);
            const spiderChartData = [];
            for (const key of keys) {
                spiderChartData.push({ area: key, value: chartData[key] });
            }

            const maxValue = d3.max(spiderChartData, (d) => {
                return d.value;
            });

            const config = {
                radius: 5,
                w: width,
                h: height,
                factor: 1,
                factorLegend: 0.85,
                levels: 5,
                radians: 2 * Math.PI,
                opacityArea: 0.5,
                ToRight: 5,
                TranslateX: 80,
                TranslateY: 40,
                ExtraWidthX: 160,
                ExtraWidthY: 80,
                color: d3.scaleOrdinal().range([color, color]),
                maxValue: maxValue
            };


            const allAxis = keys;
            const total = allAxis.length;
            const radius = config.factor * Math.min(config.w / 2, config.h / 2);
            d3.select(`.${chartClassName} > *`).remove();


            const svg = d3.select(`.${chartClassName}`)
                .append("svg")
                .attr("width", config.w + config.ExtraWidthX)
                .attr("height", config.h + config.ExtraWidthY)
                .append("g")
                .attr("transform", "translate(" + config.TranslateX + "," + config.TranslateY + ")");

            for (let index = 0; index < config.levels; index++) {
                const levelFactor = config.factor * radius * ((index + 1) / config.levels);
                svg.selectAll(".levels")
                    .data(allAxis)
                    .enter()
                    .append("line")
                    .attr("x1", (d, i) => { return levelFactor * (1 - config.factor * Math.sin(i * config.radians / total)); })
                    .attr("y1", (d, i) => { return levelFactor * (1 - config.factor * Math.cos(i * config.radians / total)); })
                    .attr("x2", (d, i) => { return levelFactor * (1 - config.factor * Math.sin((i + 1) * config.radians / total)); })
                    .attr("y2", (d, i) => { return levelFactor * (1 - config.factor * Math.cos((i + 1) * config.radians / total)); })
                    .attr("class", "line")
                    .style("stroke", "grey")
                    .style("stroke-opacity", "0.75")
                    .style("stroke-width", "0.3px")
                    .attr("transform", "translate(" + (config.w / 2 - levelFactor) + ", " + (config.h / 2 - levelFactor) + ")");
            }


            let series = 0;
            const axis = svg.selectAll(".axis")
                .data(allAxis)
                .enter()
                .append("g")
                .attr("class", "axis");
            axis.append("line")
                .attr("x1", config.w / 2)
                .attr("y1", config.h / 2)
                .attr("x2", (d, i) => { return config.w / 2 * (1 - config.factor * Math.sin(i * config.radians / total)); })
                .attr("y2", (d, i) => { return config.h / 2 * (1 - config.factor * Math.cos(i * config.radians / total)); })
                .attr("class", "line")
                .style("stroke", "grey")
                .style("stroke-opacity", "0.75")
                .style("stroke-width", "0.3px");
            axis.append("text")
                .attr("class", "spider-label")
                .text((d) => { return d; })
                .style("font-size", 11)
                .attr("text-anchor", "middle")
                .attr("dy", "1.5em")
                .style("cursor", "pointer")
                .attr("transform", (d, i) => { return "translate(0, -10)"; })
                .attr("x", (d, i) => { return config.w / 2 * (1 - config.factorLegend * Math.sin(i * config.radians / total)) - ((config.w > 200) ? 80 : 60) * Math.sin(i * config.radians / total); })
                .attr("y", (d, i) => { return config.h / 2 * (1 - Math.cos(i * config.radians / total)) - 20 * Math.cos(i * config.radians / total); });
            let dataValues = [];
            dataValues = [];
            svg.selectAll(".nodes")
                .data(spiderChartData, (j, i) => {
                    dataValues.push([
                        config.w / 2 * (1 - (parseFloat(Math.max(j.value, 0)) / config.maxValue) * config.factor * Math.sin(i * config.radians / total)),
                        config.h / 2 * (1 - (parseFloat(Math.max(j.value, 0)) / config.maxValue) * config.factor * Math.cos(i * config.radians / total))
                    ]);
                });

            svg.selectAll(".area")
                .data([dataValues])
                .enter()
                .append("polygon")
                .attr("class", "radar-chart-series" + series)
                .style("stroke-width", "2px")
                .style("stroke", config.color(series))
                .attr("points", (d) => {
                    let str = "";
                    for (let point = 0; point < spiderChartData.length; point++) {
                        str = str + d[point][0] + "," + d[point][1] + " ";
                    }
                    return str;
                })
                .style("fill", (j, i) => { return config.color(series); })
                .style("fill-opacity", config.opacityArea)
                .on('mouseover', (d, i, p) => {
                    const z = "polygon." + d3.select(p[i]).attr("class");
                    svg.selectAll("polygon")
                        .transition(200)
                        .style("fill-opacity", 0.1);
                    svg.selectAll(z)
                        .transition(200)
                        .style("fill-opacity", 0.7);
                })
                .on('mouseout', () => {
                    svg.selectAll("polygon")
                        .transition(200)
                        .style("fill-opacity", config.opacityArea);
                });

            const div = d3.select(`.${chartClassName}`).append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            svg.selectAll(".nodes")
                .data(spiderChartData).enter()
                .append("circle")
                .attr("class", "radar-chart-series" + series)
                .style("opactiy", 0)
                .attr('r', config.radius)
                .attr("alt", (j) => { return Math.max(j.value, 0); })
                .attr("cx", (j, i) => {
                    series++;
                    dataValues.push([
                        config.w / 2 * (1 - (parseFloat(Math.max(j.value, 0)) / config.maxValue) * config.factor * Math.sin(i * config.radians / total)),
                        config.h / 2 * (1 - (parseFloat(Math.max(j.value, 0)) / config.maxValue) * config.factor * Math.cos(i * config.radians / total))
                    ]);
                    return config.w / 2 * (1 - (Math.max(j.value, 0) / config.maxValue) * config.factor * Math.sin(i * config.radians / total));
                })
                .attr("cy", (j, i) => {
                    return config.h / 2 * (1 - (Math.max(j.value, 0) / config.maxValue) * config.factor * Math.cos(i * config.radians / total));
                })
                .attr("data-id", (j) => { return j.area; })
                .style("fill", "#fff")
                .style("stroke-width", "2px")
                .style("stroke-opacity", 0)
                .style("stroke", "transparent").style("fill-opacity", 0)
                .on('mouseover', (d, i, nodes) => {
                    const circle = d3.select(nodes[i]);
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html(`Dimension : ${d.area} <br/> Values: ${d.value}`)
                        .style("left", circle.attr("cx") + "px")
                        .style("top", (circle.attr("cy") - 50) + "px");
                    const z = "polygon." + d3.select(nodes[i]).attr("class");
                    svg.selectAll("polygon")
                        .transition(200)
                        .style("fill-opacity", 0.1);
                    svg.selectAll(z)
                        .transition(200)
                        .style("fill-opacity", 0.7);
                })
                .on('mouseout', () => {
                    div
                        .transition(200)
                        .style('opacity', 0);
                    svg.selectAll("polygon")
                        .transition(200)
                        .style("fill-opacity", config.opacityArea);
                });

        }
    }, [analysis, chartClassName, chartData, color, height, width]);

    return (
        <Grid className={classNames(chartClassName, (sidePanel && type === "conversation") ? classes.overflowBox : null, classes.chartStyles, classes.circlespiderChart)} />
    );
};

CircleSpiderChart.propTypes = {
    classes: PropTypes.object,
    analysis: PropTypes.array,
    chartClassName: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
};

export default memo(withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(CircleSpiderChart));