import React, { useState } from 'react';
import { Grid, withStyles, Typography, Button, MenuItem, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { appConstants } from '../../constants/appConstants.js';
import Styles from '../../layouts/Styles.jsx';
import SemanticGlossaryStyles from './SemanticGlossaryStyles.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextBox from '../TextBox/TextBox.jsx';
import Verified from '../../assets/images/verified.svg';
import InProgess from '../../assets/images/wip.svg';
import Deprecated from '../../assets/images/deprecated.svg';
import Issues from '../../assets/images/issues.svg';

const AddGlossary = (props) => {

    const { classes, glossary, type, onChange, onSave, onCancel, glossaryNames, loading } = props;
    const [duplicateNameError, setNameError] = useState(false);
    const addType = type ? type.charAt(0).toUpperCase() + type.slice(1) : "Field";

    const renderStatusIcon = (status) => {
        if (status.toLowerCase() === "verified") {
            return Verified;
        } else if (status.toLowerCase() === "work in progress") {
            return InProgess;
        } else if (status.toLowerCase() === "issues") {
            return Issues;
        }
        return Deprecated;
    };

    const validateNameDupicate = (value) => {
        const duplicate = glossaryNames.filter((data) => data.toLowerCase() === value.toLowerCase());
        if (duplicate.length !== 0) {
            return setNameError(true);
        }
        setNameError(false);
    };

    return (
        <ValidatorForm name="add new glossary" onSubmit={() => onSave()}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={7}>
                            <TextBox
                                name="name"
                                placeholder="Name"
                                label={addType}
                                value={glossary.name ? glossary.name : ""}
                                fullWidth
                                className={classes.glossaryNameTextField}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                onBlur={(event) => validateNameDupicate(event.target.value)}
                                validators={["required", 'matchRegexp:^[A-Za-z][A-Za-z0-9]+$']}
                                errorMessages={['Name is required', 'Special Character will not allowed']}
                                error={duplicateNameError}
                                helperText={duplicateNameError ? "Name is Duplicated" : ""}
                            />
                        </Grid>
                        <Grid item xs={3} />
                        <Grid item xs={2} align="right">
                            <TextBox
                                name="status"
                                select
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                value={glossary.status ? glossary.status : appConstants.glossaryStatus[1].value}
                                className={classes.glossarySelect}
                                SelectProps={
                                    {
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center"
                                            },
                                            getContentAnchorEl: null
                                        }
                                        // IconComponent: () => DropDownIcon()
                                    }
                                }
                            >
                                {
                                    appConstants.glossaryStatus.map((status, index) =>
                                        <MenuItem key={index} className={classes.menuItem} value={status.value}>
                                            <img src={renderStatusIcon(status.value)} alt="" className={classes.marginRight5} />
                                            <Typography>
                                                {status.name}
                                            </Typography>
                                        </MenuItem>
                                    )
                                }
                            </TextBox>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant="h5" component="h5">
                        {'Description'}
                    </Typography>
                    <TextBox
                        name="description"
                        placeholder="Enter Description"
                        multiline
                        value={glossary.description ? glossary.description : ''}
                        rows="1"
                        fullWidth
                        onChange={(event) => onChange(event.target.name, event.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction="row" justify="flex-start" style={{ marginTop: 40 }}>
                        <Grid item className={classes.btnContainer}>
                            <Button type="submit"
                                variant="contained"
                                color="primary"
                                disabled={duplicateNameError || loading}
                            >
                                {
                                    loading ? (
                                        <CircularProgress
                                            className={classes.progress}
                                            color="primary"
                                            size={24}
                                            thickness={4}
                                        />) : (`Save ${addType}`)
                                }
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button className={classes.cancelButton}
                                style={{ marginLeft: 20 }}
                                onClick={() => onCancel()}
                                disabled={loading}>
                                {'Cancel'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
};

AddGlossary.propTypes = {
    classes: PropTypes.object,
    glossary: PropTypes.object,
    type: PropTypes.string,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    glossaryNames: PropTypes.array,
    loading: PropTypes.bool
};

export default withStyles((theme) => ({
    ...SemanticGlossaryStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(AddGlossary);