import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Chip, Grid, IconButton, Paper, Popover, TextField, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Styles from '../../layouts/Styles.jsx';
import ChipInputStyles from './ChipInputStyles.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';


const ChipInput = (props) => {
    const { classes, theme, name, values, displayName, displayCount, enableAddButton, addtooltiptitle, onClickAdd, onClickEdit, isEditable, onSave, onDelete, chipSize, isDeletePermission, isEditPermission } = props;
    const [anchorEl, setAnchorElement] = useState(null);
    const [showInput, setShowInput] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const addNewInput = useCallback((event) => {
        if (onClickAdd) {
            onClickAdd(event);
            return;
        }
        setInputValue('');
        setSelectedIndex(-1);
        setShowInput(true);
    }, [onClickAdd]);

    const editInput = useCallback((value, index, event) => {
        if (onClickEdit) {
            if (isEditable && isEditPermission) {
                onClickEdit(value, index, event);
                return;
            } else {
                return;
            }
        }
        setAnchorElement(null);
        setInputValue(displayName ? value[displayName] : value);
        setSelectedIndex(index);
        setShowInput(true);
    }, [displayName, onClickEdit]);

    const saveInput = useCallback((name, value, selectedIndex) => {
        if (onSave && value) {
            onSave(name, value, selectedIndex);
        }
        setShowInput(false);
        setInputValue('');
        setSelectedIndex(-1);
    }, [onSave]);

    const deleteValue = useCallback((index) => {
        if (anchorEl) {
            setAnchorElement(null);
        }

        if (onDelete) {
            onDelete(index);
        }
    }, [anchorEl, onDelete]);


    return (
        <Fragment>
            {
                !showInput ?
                    <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap" className="chipoverall">
                        <Grid item className="chipitem">
                            <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                                {
                                    values && values.map((value, index) =>
                                        <Fragment key={`fragment-${name ? name : ''}-${index}`}>
                                            {
                                                index < displayCount &&
                                                <ToolTipComponent title={displayName ? value[displayName] : value}>
                                                    <Grid className={`${classNames(classes.chipList, classes.chipContainer)} chipList`}>
                                                        <Chip
                                                            className={classes.chip}
                                                            classes={
                                                                {
                                                                    root: classes.nomargin
                                                                }
                                                            }
                                                            style={{ width: chipSize }}
                                                            label={displayName ? value[displayName] : value}
                                                            onClick={(event) => editInput(value, index, event)}
                                                            disabled={!isEditable && !isEditPermission}
                                                        />
                                                        {
                                                            (isDeletePermission || isEditable) &&
                                                            <IconButton className={classNames(classes.nopadding, 'chipremove', classes.removeIcon)} onClick={() => deleteValue(index)} disabled={!isEditable}>
                                                                <CloseIcon className={classes.chipIcon} />
                                                            </IconButton>
                                                        }
                                                    </Grid>
                                                </ToolTipComponent>
                                            }
                                            {
                                                index === values.length - 1 && index >= displayCount &&
                                                <Typography className={classes.displayItemCount} onClick={(event) => setAnchorElement(event.target)}>
                                                    {`+${(values.length - displayCount)}`}
                                                </Typography>
                                            }
                                        </Fragment>
                                    )
                                }
                                {
                                    enableAddButton &&
                                    <Grid item>
                                        <ToolTipComponent title={addtooltiptitle} arrow>
                                            <IconButton className={classes.addButton} onClick={(event) => addNewInput(event)} disabled={!isEditable}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                    <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                                        <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                                            <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                                            <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </IconButton>
                                        </ToolTipComponent>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                        <TextField
                            name="min_length"
                            value={inputValue}
                            className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone)}
                            onChange={(event) => setInputValue(event.target.value)}
                            onBlur={(event) => saveInput(name, event.target.value, selectedIndex)}
                            autoFocus
                            disabled={!isEditable && !isEditPermission}
                        />
                        {
                            !isEditable && !isEditPermission &&
                            <IconButton className={classes.clearButton}
                                color="primary"
                                onClick={
                                    () => {
                                        setShowInput(false);
                                        setInputValue('');
                                        setSelectedIndex(-1);
                                    }
                                }>
                                <CloseIcon className={classes.chipIcon} />
                            </IconButton>
                        }

                    </Grid>
            }
            {
                anchorEl &&
                <Popover anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorElement(null)}
                    anchorOrigin={
                        {
                            vertical: 'center',
                            horizontal: 'center'
                        }
                    }
                    transformOrigin={
                        {
                            vertical: 'center',
                            horizontal: 'center'
                        }
                    }
                >
                    {
                        <Paper elevation={2} className={classes.listContainer}>
                            {
                                values && values.map((value, index) => (
                                    index >= displayCount &&
                                    <Grid key={`value-${index}`} container direction="row" justify="flex-start" alignItems="center">
                                        <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                            <ToolTipComponent title={displayName ? value[displayName] : value} arrow>
                                                <Typography onClick={(event) => editInput(value, index, event)} noWrap>
                                                    {displayName ? value[displayName] : value}
                                                </Typography>
                                            </ToolTipComponent>
                                        </Grid>
                                        <Grid item xl={3} lg={3} md={3} sm={3} xs={3}>
                                            {
                                                (isDeletePermission || isEditable) &&
                                                <ToolTipComponent title="Delete" arrow>
                                                    <IconButton onClick={() => deleteValue(index)} disabled={!isEditable}>
                                                        <svg version="1.1" id="Layer_1" width="22" height="22" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
                                                            <g id="Delete">
                                                                <path fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="0.92" strokeMiterlimit="10" d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                                                <path fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="0.92" strokeMiterlimit="10" d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                            </g>
                                                        </svg>
                                                    </IconButton>
                                                </ToolTipComponent>
                                            }
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Paper>

                    }

                </Popover>
            }
        </Fragment>
    );
};

ChipInput.defaultProps = {
    displayCount: 2,
    values: [],
    isEditable: true,
    enableAddButton: true,
    isDeletePermission: false,
    isEditPermission: false
};

ChipInput.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    values: PropTypes.array,
    displayCount: PropTypes.number,
    displayName: PropTypes.string,
    name: PropTypes.string,
    isEditable: PropTypes.bool,
    enableAddButton: PropTypes.bool,
    addtooltiptitle: PropTypes.string,
    onClickAdd: PropTypes.func,
    onClickEdit: PropTypes.func,
    onSave: PropTypes.func,
    onDelete: PropTypes.func,
    chipSize: PropTypes.number,
    isDeletePermission: PropTypes.bool,
    isEditPermission: PropTypes.bool
};

export default withStyles((theme) => ({
    ...ChipInputStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ChipInput);