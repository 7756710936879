import React, { Fragment, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, IconButton, Typography, Avatar } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

import MultiSelect from '../MultiSelect/MultiSelect.jsx';
import PermissionStyles from './PermissionStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import { getRandomColor, getInitials, returnImage } from '../../helpers/appHelpers';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import { appConstants } from '../../constants/appConstants';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';


const UserList = (props) => {
    const { classes, level, selectedList, users, type, onKeyDown, onChange, onInputValueOnChange, inputValue, expandList, isOPen, onBlur, onRemoveUser, theme, actionRequest, userRequests, disabled, showAdd, count, updating } = props;

    const [deleteUser, setDeleteUser] = useState(null);

    const onDeleteUser = useCallback((event, username) => {
        event.stopPropagation();
        setDeleteUser(username);
    }, []);

    const selectedUsers = () => {
        return users.filter((user) => !user.isSelected);
    };

    const removeItem = () => {
        onRemoveUser(deleteUser, "remove");
        setDeleteUser(null);
    };

    return (
        <Grid>
            <Grid className={classes.userChipSection}>
                {
                    userRequests && userRequests.map((user, index) =>
                        <Grid className={classes.userChip} key={`request_${index}`}>
                            <Avatar
                                style={
                                    {
                                        backgroundColor: getRandomColor(user.name)
                                    }
                                }>
                                {getInitials(user.name)}
                            </Avatar>
                            <Typography variant="body2" component="span">
                                {user.name}
                            </Typography>
                            {
                                (user.is_requested && (!user.is_request_accepted && !user.is_decline) && !disabled) &&
                                <Grid className={classNames(classes.nopadding, 'chipremove', 'inlineChip')}>
                                    <IconButton className={classes.nopadding} onClick={(event) => actionRequest(event, "accept", user)}>
                                        <svg width="20px" height="20px" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill={theme.palette.secondary.main} />
                                        </svg>
                                    </IconButton>
                                    <IconButton className={classes.nopadding} onClick={(event) => actionRequest(event, "decline", user)}>
                                        <svg width="20px" height="20px" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" fill={theme.palette.primary.main} />
                                        </svg>
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    )
                }
            </Grid>
            <Grid className={classNames(classes.marginTop10, classes.overviewUserList)}>
                {
                    isOPen &&
                    <MultiSelect
                        placeholder="Add or Invite User"
                        level={level}
                        dropDown
                        selectedList={selectedList}
                        availableList={selectedUsers()}
                        displayValue="name"
                        selectType="user"
                        onKeyDown={onKeyDown ? onKeyDown : null}
                        onChange={onChange}
                        onInputValueChange={onInputValueOnChange}
                        inputValue={inputValue}
                        onClose={onBlur}
                        onRemoveItem={onRemoveUser}
                        type={type}
                        disableRemove={level === "steward"}
                        disabled={disabled} />
                }
                {
                    !isOPen &&
                    <Grid container alignItems="center">
                        {
                            selectedList && selectedList.map((user, index) =>
                                <Fragment key={`user_${index}`}>
                                    {
                                        index < count &&
                                        <Grid onClick={!updating ? (event) => expandList(level, event) : null} className={classNames(classes.listChip, classes.chipList)}>
                                            <TooltipComponent title={user.name}>
                                                <Avatar
                                                    style={
                                                        {
                                                            backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, user.avatar)})`,
                                                            backgroundColor: getRandomColor(user.name)
                                                        }
                                                    }>
                                                    {
                                                        !user.avatar &&
                                                        <span>
                                                            {getInitials(user.name)}
                                                        </span>
                                                    }
                                                </Avatar>
                                            </TooltipComponent>
                                            {
                                                ((user.is_request_accepted) && !disabled && level !== "steward") &&
                                                <IconButton onClick={(event) => onDeleteUser(event, user.name)} className={classNames(classes.nopadding, 'chipremove', 'inlineChip')}>
                                                    <CloseIcon className={classNames(classes.chipIcon, classes.userClose)} />
                                                </IconButton>
                                            }
                                            {
                                                (user.is_requested && (!user.is_request_accepted && !user.is_decline)) &&
                                                <Fragment className={classNames(classes.nopadding, 'chipremove', 'inlineChip')}>
                                                    <IconButton className={classes.nopadding} onClick={(event) => actionRequest(event, "accept", user)}>
                                                        <svg width="20px" height="20px" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" fill={theme.palette.secondary.main} />
                                                        </svg>
                                                    </IconButton>
                                                    <IconButton className={classes.nopadding} onClick={(event) => actionRequest(event, "decline", user)}>
                                                        <svg width="20px" height="20px" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation">
                                                            <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z" fill={theme.palette.primary.main} />
                                                        </svg>
                                                    </IconButton>
                                                </Fragment>
                                            }
                                        </Grid>
                                    }
                                    {
                                        (index === selectedList.length - 1 && index >= count) &&
                                        <Grid onClick={!updating ? (event) => expandList(level, event) : null} className={classes.chipLast}>
                                            <Typography className={classes.chipLastTxt}>
                                                {`+${selectedList.length - count}`}
                                            </Typography>
                                        </Grid>

                                    }
                                </Fragment>
                            )
                        }
                        {
                            (!disabled && showAdd) &&
                            <TooltipComponent title="Add or Invite User" arrow>
                                <IconButton onClick={!updating ? (event) => expandList(level, event) : null} className={classNames(classes.nopadding, classes.height20)}>
                                    <svg className={classes.UserIcon} style={{ margin: '5px 0px' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                        <g id="Group_2025" data-name="Group 2025" transform="translate(-454 -620)">
                                            <g id="Ellipse_990" data-name="Ellipse 990" transform="translate(454 620)" fill="#fff" stroke="#969899" strokeWidth="1" strokeDasharray="2">
                                                <circle cx="10" cy="10" r="10" stroke="none" />
                                                <circle cx="10" cy="10" r="9.5" fill="none" />
                                            </g>
                                            <g id="_36_Filter" data-name="36 Filter" transform="translate(441.5 625)">
                                                <path id="Path_1025" data-name="Path 1025" d="M124.549,5.371a2.4,2.4,0,0,1-1.83-.913A3.015,3.015,0,0,1,122,2.549a2.549,2.549,0,0,1,5.1,0,3.015,3.015,0,0,1-.719,1.909A2.4,2.4,0,0,1,124.549,5.371Zm0-4.395a1.574,1.574,0,0,0-1.572,1.572,2.051,2.051,0,0,0,.488,1.279,1.32,1.32,0,0,0,2.168,0,2.051,2.051,0,0,0,.488-1.279A1.574,1.574,0,0,0,124.549.977Z" transform="translate(-102.459)" fill="#969899" />
                                                <path id="Path_1026" data-name="Path 1026" d="M26.328,304.141a.488.488,0,0,1-.488-.488v-1.12a1.444,1.444,0,0,0-1.558-1.556H20.034a1.444,1.444,0,0,0-1.558,1.556v1.12a.488.488,0,0,1-.977,0v-1.12A2.412,2.412,0,0,1,20.033,300h4.248a2.412,2.412,0,0,1,2.533,2.533v1.12a.488.488,0,0,1-.487.488Z" transform="translate(0 -294.141)" fill="#969899" />
                                            </g>
                                        </g>
                                    </svg>
                                </IconButton>
                            </TooltipComponent>
                        }
                    </Grid>
                }

            </Grid>
            <AlertDialog title="Remove User"
                message={`Are you sure you want to delete user ${deleteUser}?`}
                okButtonText="OK"
                cancelButtonText="Cancel"
                show={Boolean(deleteUser)}
                onClickOK={() => removeItem()}
                onClickCancel={() => setDeleteUser(null)} />
        </Grid>
    );
};

UserList.propTypes = {
    classes: PropTypes.object,
    level: PropTypes.string,
    selectedList: PropTypes.array,
    users: PropTypes.array,
    onKeyDown: PropTypes.func,
    onChange: PropTypes.func,
    onInputValueOnChange: PropTypes.func,
    inputValue: PropTypes.string,
    expandList: PropTypes.func,
    isOPen: PropTypes.bool,
    onBlur: PropTypes.func,
    onRemoveUser: PropTypes.func,
    theme: PropTypes.object,
    actionRequest: PropTypes.func,
    userRequests: PropTypes.array,
    disabled: PropTypes.bool,
    showAdd: PropTypes.bool,
    count: PropTypes.number,
    type: PropTypes.string,
    updating: PropTypes.bool
};

export default withStyles((theme) => ({
    ...PermissionStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(UserList);