import React, { useEffect } from "react";
import { Grid, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as d3 from "d3";
import Styles from "../../layouts/Styles.jsx";
import ChartStyles from "./ChartStyles.jsx";
// import { wrapText, getValue } from "../../helpers/appHelpers";
import noprofile from "../../assets/images/np_profile_data.jpg";
// import moment from "moment";

const DynamicHistogramChart = (props) => {

    const { classes, chartData, chartClassName, height, binCount, showXAxis, showYAxis } = props;

    useEffect(() => {

        let width = 300;
        const margin = { top: 15, right: 10, bottom: 0, left: 50 };
        let height = 400;
        d3.select(`.${chartClassName}_chart > svg`).remove();
        d3.select(`.${chartClassName} > .legend-container`).remove();

        d3.select(`.${chartClassName}_chart > .tooltip`).remove();

        /*
         * const toolTip = d3
         *     .select(`.${chartClassName}_chart`)
         *     .append("div")
         *     .attr("class", "tooltip")
         *     .style("opacity", 0)
         *     .style("zIndex", 1);
         */

        const svg = d3
            .select(`.${chartClassName}_chart`)
            .append("svg")
            .attr("width", (_, i, p) => {
                width = p[i].parentNode.clientWidth;
                height = p[i].parentNode.clientHeight - margin.top;
            })
            .attr("class", `${chartClassName}_chart`)
            .attr("viewBox", [0, 0, width, height])
            .attr("overflow", "auto")
            .append("g");

        const minX = d3.min(chartData, (d) => { return d; });
        const maxX = d3.max(chartData, (d) => { return d; });
        const x = d3.scaleLinear()
            .domain([minX, maxX + (maxX * 0.03)])
            .range([margin.left, width - margin.right]).nice();
        const histogram = d3.histogram(binCount)
            .value((d) => { return d; })
            .domain(x.domain())
            .thresholds(x.ticks(binCount));
        const bins = histogram(chartData);
        const maxY = d3.max(bins, (d) => { return d.length; });
        const y = d3.scaleLinear()
            .range([height - margin.top, margin.top]).domain([0, (maxY + maxY * 0.03)]);

        if (showXAxis) {
            svg
                .append("g")
                .attr("transform", `translate(0,${height - (margin.top + (margin.top / 2))})`)
                .attr("class", "axis-x")
                .call(d3.axisBottom(x))
                .call((g) => g.selectAll(".domain").remove());
        }

        if (showYAxis) {
            svg
                .append("g")
                .attr("transform", `translate(${margin.left},0)`)
                .attr("class", "axis-y")
                .call(d3.axisLeft(y).tickSizeOuter(0))
                .call((g) => g.selectAll(".domain").remove());
        }

        if (showXAxis || showYAxis) {
            svg.selectAll(".tick").selectAll("text").attr("class", "tick-text");
            svg.selectAll(".tick").selectAll("line").remove();
        }

        svg.selectAll("rect")
            .data(bins)
            .enter()
            .append("rect")
            .attr("x", 1)
            .attr("transform", (d) => {
                let yPosition = y(d.length);
                const h = height - y(d.length);
                if (d.length > 0 && h < 1) {
                    yPosition -= 2;
                }
                return "translate(" + x(d.x0) + "," + yPosition + ")";
            })
            .attr("width", (d) => { return x(d.x1) - x(d.x0); })
            .style("fill", "#87ceeb")
            .attr("y", (d) => { return 0; })
            .attr("height", (d, i) => {
                let barHeight = height - y(d.length);
                if (d.length > 0 && barHeight < 1) {
                    barHeight = 4;
                }
                return barHeight;
            });
    }, [chartClassName, binCount, chartData, showXAxis, showYAxis]);

    return (
        <Grid
            style={{ padding: 20 }}
            className={
                classNames(
                    classes.chartStyles,
                    classes.widgetContainer,
                    chartClassName
                )
            }
        >
            {
                chartData.length === 0 && (
                    <img src={noprofile} alt="" style={{ maxWidth: "100%" }} />
                )
            }
            <Grid
                className={
                    classNames(
                        `${chartClassName}_chart`,
                        classes.chartContainer
                    )
                }
                style={{ height: height - 60 }}
            />
        </Grid>
    );

};

DynamicHistogramChart.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.array,
    binCount: PropTypes.number,
    chartClassName: PropTypes.string,
    showXAxis: PropTypes.bool,
    showYAxis: PropTypes.bool,
    height: PropTypes.number
};

export default withStyles(
    (theme) => ({
        ...ChartStyles(theme),
        ...Styles(theme)
    }),
    { withTheme: true }
)(DynamicHistogramChart);