import React, { useState, useEffect, useCallback } from 'react';
import { Grid, withStyles, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { updateFavorites, getDomainShare } from '../../actions/datasourceActions';
import { appConstants } from '../../constants/appConstants';
import TabPanel from '../../components/TabPanel/TabPanel.jsx';
import DomainInfo from '../../components/Domain/DomainInfo.jsx';
import DomainSummary from '../../components/Domain/DomainSummary.jsx';
import DomainRules from '../../components/Domain/DomainRules.jsx';
// import DomainQuality from '../../components/Domain/DomainQuality.jsx';
import DomainSematic from '../../components/Domain/DomainSematic.jsx';
import Styles from '../../layouts/Styles.jsx';
import { getDomainDetail, updateDomainDetail, selectDomain, setActionItemCount, setDomainFilters, getDomainRules } from '../../actions/domainActions';
import DomainQuality from '../../components/Domain/DomainQuality.jsx';
import DomainMonitoring from '../../components/Domain/DomainMonitoring.jsx';

const Domain = (props) => {
    const { classes, history, location, match, getPageTitle } = props;

    const presistconfig = useSelector(({ domain }) => domain.config);

    const loadTab = useCallback(() => {
        if (presistconfig && presistconfig.tabConfig && presistconfig.tabConfig.tab) {
            return presistconfig.tabConfig.tab;
        } else if (location && location?.state?.rule) {
            return 1;
        } return 0;
    }, [location, presistconfig]);

    const [tabIndex, setTabIndex] = useState(loadTab());
    const [isLoading, setLoading] = useState(true);
    const [breadcrumb, setBreadCrumb] = useState([]);
    const [domain, setDomain] = useState({});
    const [selectedRule, setSelectedRule] = useState({});
    const dispatch = useDispatch();
    const dq_score_threshold = useSelector(({ setting }) => setting.user_config.dq_score_threshold);
    const [sourceRules, setRules] = useState([]);

    const totalRules = location && location.state ? location.state.domain.total_rules : 0;
    const updateDomainFavorites = useCallback((favorite) => {
        const requestParams = {
            "source_id": domain.id,
            "favorite": !favorite,
            "type": "domain"
        };
        dispatch(updateFavorites(requestParams));
    }, [dispatch, domain.id]);


    const getDomain = useCallback((domainId) => {
        dispatch(getDomainDetail(domainId)).then((response) => {
            // setLoading(false);
            if (response) {
                setBreadCrumb([{ name: 'Catalog', path: '/catalog' }, { name: response.name, path: '' }]);
                dispatch(setActionItemCount(response.actionitems ? response.actionitems : 0));
                setDomain({ ...response });
                getPageTitle(`${response.name}`);
                getRules();
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    const selectDomainId = useCallback((domainId) => {
        dispatch(selectDomain(domainId));
    }, [dispatch]);

    const getSharingInfo = useCallback((domainId) => {
        dispatch(getDomainShare(domainId));
    }, [dispatch]);

    const getRules = useCallback(() => {
        setLoading(true);
        dispatch(getDomainRules(match.params.id)).then((response) => {
            setLoading(false);
            if (response) {
                setRules([...response]);
            }
        });
    }, [dispatch, match.params.id]);

    useEffect(() => {
        getDomain(match.params.id);
        getSharingInfo(match.params.id);
        selectDomainId(match.params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.state, match.params.id]);


    const onPresistConfig = useCallback((property, value) => {
        dispatch(setDomainFilters({ ...presistconfig, [property]: value }));
    }, [dispatch, presistconfig]);

    const onChangeInfo = useCallback((property, value) => {
        domain[property] = value;
        if (property === "name") {
            breadcrumb[breadcrumb.length - 1].name = value;
            setBreadCrumb([...breadcrumb]);
        } else if (property === "favorite") {
            updateDomainFavorites(value);
        }
        setDomain({ ...domain });
    }, [domain, breadcrumb, updateDomainFavorites]);

    const onSaveInfo = useCallback(() => {
        dispatch(updateDomainDetail(domain.id, { name: domain.name }));
    }, [dispatch, domain]);

    const onChangeTab = (tabIndex) => {
        setTabIndex(tabIndex);
        onPresistConfig("tabConfig", { ...presistconfig.tabConfig, tab: tabIndex });
    };

    const redirectCatalog = () => {
        onPresistConfig("tabConfig", {});
        history.push('/catalog');
    };

    const redirectData = (params) => {
        if (params) {
            onChangeTab(1);
            setSelectedRule({ ...params });
        } else {
            onChangeTab(2);
        }
    };

    return (
        <ValidatorForm form="domain" onSubmit={() => null} className={classes.container}>
            <Grid>
                <DomainInfo
                    domain={domain}
                    redirectCatalog={() => redirectCatalog()}
                    breadcrumb={breadcrumb}
                    loading={isLoading}
                    onChange={(property, value) => onChangeInfo(property, value)}
                    onSave={() => onSaveInfo()}
                    onChangeTab={(index) => onChangeTab(index)}
                    dq_score_threshold={dq_score_threshold}
                />
                <Grid className={classes.datasourceTabContainer}>
                    <Tabs
                        value={tabIndex}
                        indicatorColor="secondary"
                        textColor="secondary"
                        onChange={(_, index) => onChangeTab(index)}
                        aria-label="domain tabs">
                        {
                            appConstants && appConstants.domainTabs.map((name, index) =>
                                <Tab key={index} label={name} />
                            )
                        }
                    </Tabs>
                </Grid>
            </Grid>
            <Grid className={classNames(classes.marginTop15, classes.container, classes.domainContainer)} >
                <TabPanel index={0} value={tabIndex}>
                    {tabIndex === 0 && <DomainSummary domain={domain} totalRules={totalRules} domainId={match.params.id} history={history} onChangeTab={(index) => onChangeTab(index)} dq_score_threshold={dq_score_threshold} />}
                </TabPanel>
                <TabPanel index={1} value={tabIndex}>
                    {
                        tabIndex === 1 &&
                        <DomainQuality
                            domainId={match.params.id}
                            history={history}
                        />
                        // <DomainRules
                        //     selectedRule={selectedRule}
                        //     domainId={match.params.id}
                        //     history={history}
                        //     tabConfig={presistconfig && presistconfig.tabConfig ? presistconfig.tabConfig : {}}
                        //     onPresistConfig={(property, value) => onPresistConfig(property, value)}
                        //     redirectData={(params) => redirectData(params)}
                        //     dq_score_threshold={dq_score_threshold}
                        //     sourceRules={sourceRules}
                        //     setRules={setRules}
                        //     rulesLoading={isLoading}
                        // />
                    }
                </TabPanel>
                <TabPanel index={2} value={tabIndex}>
                    {tabIndex === 2 && <DomainSematic domainId={domain.id} history={history} redirectData={(params) => redirectData(params)} dq_score_threshold={dq_score_threshold} />}
                </TabPanel>
                <TabPanel index={3} value={tabIndex}>
                    {tabIndex === 3 && <DomainMonitoring domainId={domain.id} history={history} redirectData={(params) => redirectData(params)} dq_score_threshold={dq_score_threshold} />}
                </TabPanel>
            </Grid>
        </ValidatorForm>
    );
};

Domain.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    match: PropTypes.object,
    getPageTitle: PropTypes.func
};

export default withStyles((theme) => ({
    ...Styles(theme)
}))(Domain);