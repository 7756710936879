import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import DashboardStyles from './DashboardStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import Circular from '../Charts/Circular.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import { useSelector } from 'react-redux';


const MostViewList = (props) => {
    const { viewData, classes, type } = props;
    const config = useSelector(({ setting }) => setting.config);
    const dq_score_threshold = useSelector(({ setting }) => setting.user_config.dq_score_threshold);

    return (
        <Grid container alignItems="center" wrap="nowrap" className={classes.viewList}>
            <Grid item>
                <Circular
                    showPercentage={Boolean(viewData.dqscore && viewData.dqscore !== 0 && config.profile)}
                    showValue
                    value={config.profile ? viewData.dqscore : 0}
                    size={50}
                    dqScoreThreshold={dq_score_threshold}
                />
            </Grid>
            <Grid item className={classes.viewListData}>
                <ToolTipComponent title={viewData.name !== '' ? viewData.name : `${type === "datasource" ? 'Untiled Data Source' : 'Untiled Dataset'}`} arrow>
                    <Typography>
                        {viewData.name !== '' ? viewData.name : `${type === "datasource" ? 'Untiled Data Source' : 'Untiled Dataset'}`}
                    </Typography>
                </ToolTipComponent>
                <Typography className={classes.viewListDateTxt}>
                    {`Last Modified Date ${moment(viewData.modified_date).format('MMM DD,YYYY')}`}
                </Typography>
            </Grid>
        </Grid>
    );
};

MostViewList.propTypes = {
    classes: PropTypes.object,
    viewData: PropTypes.object,
    type: PropTypes.string
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}))(MostViewList);