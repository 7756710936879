/**
 * @name ExportDQMetrics.jsx
 * @description Define Add , Update , List and Delete Field Functionalities
 * @author Srinath
 * CreatedAt : 16-Feb-2022
 */

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, withStyles, Button, FormLabel, Typography, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Loader from '../../components/Loaders/Loader.jsx';
import classNames from 'classnames';

// Import Styles
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import { copyToClipBoard } from '../../helpers/appHelpers';
import FileCopyIcon from '@material-ui/icons/FileCopy';

// import FileUpload from '../../assets/images/file-upload.svg';
import Dropzone from 'react-dropzone';
import Organizariondomain from '../OrganizationDomain/Organizariondomain.jsx';


// Import Components
import TextBox from '../TextBox/TextBox.jsx';

// Import Actions
import { getSSODetails, uploadSSOFiles, uploadSSOAlerts, updateSSODomain, updateSSOPrivateKey } from '../../actions/settingActions';

const SSO = (props) => {

    // Get Props
    const { classes, organizationId } = props;

    // Set State
    const [connectionConfig, setConnectionConfig] = useState({
        entity_id: '',
        acs_url: '',
        sign_in_url: '',
        updated_file: false,
        domain_properties: [],
        private_key: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [privatekey, setPrivateKey] = useState(false);
    const dispatch = useDispatch();
    /**
     * @name useEffect
     * @description UseEffect run when page initialize and any updates happends
     */
     useEffect(() => {
        dispatch(getSSODetails(organizationId)).then((response) => {
            if (response) {
                setConnectionConfig(response);
            }
        });
    }, [dispatch, organizationId]);

    const updateTags = (value, type, index) => {
        const properties = { ...connectionConfig };
        const tags = [...properties.domain_properties];
        value = value.trim();
        if (type === "add") {
            const tagExist = tags.some((tag) => tag?.toLowerCase() === value?.toLowerCase());
            if (!tagExist) { tags.push(value); }
            properties.domain_properties = tags;
            const requestParams = {
                "organizationId": organizationId,
                "tags": tags
            };
            dispatch(updateSSODomain(requestParams)).then((response) => {
                setConnectionConfig(properties);
            });
        } else {
            if (index === -1) { tags.splice(tags.length - 1, 1); }
            else { tags.splice(index, 1); }
            properties.domain_properties = tags;
            const requestParams = {
                "organizationId": organizationId,
                "tags": tags
            };
            dispatch(updateSSODomain(requestParams)).then((response) => {
                setConnectionConfig(properties);
            });
        }
    };

    const handleChange = useCallback((property, value) => {
        connectionConfig[property] = value;
        setConnectionConfig({ ...connectionConfig });
        setPrivateKey(true);
    }, [connectionConfig]);

    const handleSave = () => {
        setIsLoading(true);
        const requestParams = {
            "organizationId": organizationId,
            "private_key": connectionConfig['private_key']
        };
        dispatch(updateSSOPrivateKey(requestParams)).then((response) => {
            setIsLoading(false);
            setPrivateKey(false);
        });
    };

    const handleFileUpload = useCallback((report) => {
        const file = new FormData();
        file.append('file', report[0]);
        file.append('organizationId', organizationId);
        if (connectionConfig['private_key'].trim() !== "") {
            file.append('private_key', connectionConfig['private_key']);
            dispatch(uploadSSOFiles(file)).then((uploadedFiles) => {
                return '';
            });
        } else {
            dispatch(uploadSSOAlerts("Need to enter private key."));
        }
    }, [connectionConfig, dispatch, organizationId]);

    return (
        <Grid>
            <ValidatorForm name="export_metadata" onSubmit={() => null}>
                <Grid className={classNames(classes.filterContainer)}>
                    <Grid container direction="row" spacing={3} style={{ marginTop: 10 }}>
                        <Grid item xs={12}>
                            <b>
                                {"Basic SAML Configurations"}
                            </b>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={8} className={classes.ssotokenSection}>
                                    <FormLabel>
                                        Entity ID
                                    </FormLabel>
                                    <TextBox
                                        value={connectionConfig.entity_id ? connectionConfig.entity_id : ""}
                                        margin="normal"
                                        fullWidth
                                        readOnly
                                        id="entity_id"
                                        className={classNames(classes.inputOutline)}
                                    />
                                    <Button onClick={() => copyToClipBoard("entity_id")} variant="contained" className={classNames(classes.cancelButton, "button")}>
                                        <FileCopyIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={8} className={classes.ssotokenSection}>
                                    <FormLabel>
                                        SIGN IN URL
                                    </FormLabel>
                                    <TextBox
                                        value={connectionConfig.sign_in_url ? connectionConfig.sign_in_url : ""}
                                        margin="normal"
                                        fullWidth
                                        readOnly
                                        id="sign_in_url"
                                        className={classNames(classes.inputOutline)}
                                    />
                                    <Button onClick={() => copyToClipBoard("sign_in_url")} variant="contained" className={classNames(classes.cancelButton, "button")}>
                                        <FileCopyIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid item xs={8} className={classes.ssotokenSection}>
                                    <FormLabel>
                                        ACS URL
                                    </FormLabel>
                                    <TextBox
                                        value={connectionConfig.acs_url ? connectionConfig.acs_url : ""}
                                        margin="normal"
                                        fullWidth
                                        readOnly
                                        id="acs_url"
                                        className={classNames(classes.inputOutline)}
                                    />
                                    <Button onClick={() => copyToClipBoard("acs_url")} variant="contained" className={classNames(classes.cancelButton, "button")}>
                                        <FileCopyIcon />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component="h6" variant="h6">
                                {'Domains'}
                            </Typography>
                            <Organizariondomain type="tags" focus attributTag tags={connectionConfig.domain_properties} updateTags={updateTags} disabled={false} />
                        </Grid>
                        <Grid item xs={12}>
                            <b>
                                {"User Attributes and Claims"}
                            </b>
                        </Grid>
                        <Grid item xs={6}>
                            <Table>
                                <TableBody>
                                    <TableRow className={classes.tableRow} key={`claims_1`}>
                                        <TableCell className={classes.tableCell} style={{ borderBottom: "none" }}>
                                            {"emailaddress"}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{ borderBottom: "none" }}>
                                            {"User.email"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow} key={`claims_1`}>
                                        <TableCell className={classes.tableCell} style={{ borderBottom: "none" }}>
                                            {"role"}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{ borderBottom: "none" }}>
                                            {"User.role"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow} key={`claims_2`}>
                                        <TableCell className={classes.tableCell} style={{ borderBottom: "none" }}>
                                            {"Response"}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{ borderBottom: "none" }}>
                                            {"Signed"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow} key={`claims_3`}>
                                        <TableCell className={classes.tableCell} style={{ borderBottom: "none" }}>
                                            {"Assertion Signature"}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{ borderBottom: "none" }}>
                                            {"Signed"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow} key={`claims_4`}>
                                        <TableCell className={classes.tableCell} style={{ borderBottom: "none" }}>
                                            {"Signature Algorithm"}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{ borderBottom: "none" }}>
                                            {"RSA_SHA256"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow} key={`claims_5`}>
                                        <TableCell className={classes.tableCell} style={{ borderBottom: "none" }}>
                                            {"Digest Algorithm"}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{ borderBottom: "none" }}>
                                            {"SHA256"}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow className={classes.tableRow} key={`claims_6`}>
                                        <TableCell className={classes.tableCell} style={{ borderBottom: "none" }}>
                                            {"Assert Encryption"}
                                        </TableCell>
                                        <TableCell className={classes.tableCell} style={{ borderBottom: "none" }}>
                                            {"Unencrypted"}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={12}>
                            <b>
                                {"Organization Input Here"}
                            </b>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={10}>
                                    <TextBox
                                        label="Private Key"
                                        name="private_key"
                                        value={connectionConfig.private_key}
                                        validators={['required']}
                                        errorMessages={['Private Key is required']}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Dropzone
                                maxSize={2 * (1024 * 1024)}
                                accept={['.xml']}
                                disabled={false}
                                onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}
                            >
                                {
                                    ({ getRootProps, getInputProps, isDragActive }) => (
                                        <Grid className={classNames(classes.droparea)}
                                            container
                                            direction="row"
                                            alignItems="center"
                                            justify="center"
                                            {...getRootProps()}>
                                            <input {...getInputProps()} />
                                            <Grid container
                                                direction="column"
                                                justify="center"
                                                wrap="nowrap"
                                                className={classes.dropareaContent}>
                                                {/* <Grid item>
                                                    <img src={FileUpload} alt="file upload" className={classes.dropareaImage} />
                                                </Grid> */}
                                                <Grid item>
                                                    <Button variant="contained" color="primary">
                                                        {connectionConfig.updated_file ? 'Update Federation File(s)' : 'Choose Federation File(s)'}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </Dropzone>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.exportMetadataButton}>
                    {
                        connectionConfig.updated_file && privatekey &&
                        <Button disabled={false} onClick={() => handleSave()} variant="contained" color="primary">
                            {'Save'}
                            {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                        </Button>
                    }
                </Grid>
            </ValidatorForm>
        </Grid>
    );
};

SSO.propTypes = {
    classes: PropTypes.object,
    organizationId: PropTypes.number
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SSO);