import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography, IconButton, Switch, TableCell, TableContainer, TableHead, TableRow, Table, TableBody } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import Search from '../TextBox/Search.jsx';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import Loader from '../Loaders/Loader.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';

import { getRoleList, editRole, deleteRole } from '../../actions/settingActions';


const RoleList = (props) => {
    const { classes, organizationId, theme, onEdit } = props;
    const [isLoading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [roles, setRoles] = useState([]);
    const userRole = useSelector(({ account }) => account.user.role);
    const dispatch = useDispatch();

    const deleteRoleList = (roleId) => {
        const index = roles.findIndex((roleInfo) => roleInfo.id === roleId);
        if (index !== -1) {
            roles.splice(index, 1);
            setRoles([...roles]);
            dispatch(deleteRole(roleId));
        }
    };

    const editRoleList = (roleId, value) => {
        const index = roles.findIndex((roleInfo) => roleInfo.id === roleId);
        if (index !== -1) {
            roles[index].is_active = value;
            setRoles([...roles]);
            const requestParams = {
                is_active: value
            };
            dispatch(editRole(roleId, requestParams));
        }
    };

    const getRoles = useCallback(() => {
        setLoading(true);
        dispatch(getRoleList(organizationId)).then((response) => {
            if (response) {
                setRoles([...response]);
            }
            setLoading(false);
        });

    }, [dispatch, organizationId]);

    useEffect(() => {
        getRoles();
    }, [getRoles]);

    const filterRoles = search && search.length > 0 ? roles.filter((role) => role.name.toLowerCase().includes(search.toLowerCase()) || role.description.toLowerCase().includes(search.toLowerCase())) : roles;

    return (
        <Grid className={classes.referenceContainer}>
            <Grid container justify="space-between">
                <Typography variant="h5" component="h5">
                    Roles
                </Typography>
                <Grid item className={classes.search}>
                    <Search value={search}
                        placeholder="Search Role"
                        onChange={(value) => setSearch(value)}
                        className={classNames(classes.dqSearchBoxRefList)} />
                    <ToolTipComponent title={"Add Role"} arrow>
                        <IconButton className={classNames(classes.marginLeft10, classes.alignIconCenter)} onClick={() => onEdit("Add Role", null, roles)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                    <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                        <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                        <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                    </g>
                                </g>
                            </svg>
                        </IconButton>
                    </ToolTipComponent>
                </Grid>
            </Grid>


            <Grid container className={classNames(classes.referenceSection, classes.marginTop20)}>
                <TableContainer className={classes.tableWrapperStyle}>
                    <Table className={classes.tableStyle}>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="body1" className={classes.tableHeader}>
                                        Role Name
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1" className={classes.tableHeader}>
                                        Description
                                    </Typography>
                                </TableCell>
                                <TableCell style={{ maxWidth: 65 }}>
                                    <Typography variant="body1" className={classes.tableHeader}>
                                        Number of users assigned
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1" className={classes.tableHeader} align="center">
                                        Status
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="body1" className={classes.tableHeader} align="center">
                                        Actions
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                filterRoles.map((role, index) =>
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Grid item>
                                                <Typography>
                                                    {role.name ? role.name : ""}
                                                </Typography>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item>
                                                <Typography>
                                                    {role.description ? role.description : ""}
                                                </Typography>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item align="center">
                                                <Typography>
                                                    {role.users ? role.users : "NA"}
                                                </Typography>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item align="center">
                                                <Switch
                                                    color="secondary"
                                                    size="small"
                                                    name="is_active"
                                                    value="is_active"
                                                    checked={role.is_active}
                                                    disabled={userRole.name !== 'Admin'}
                                                    onChange={() => editRoleList(role.id, !role.is_active)}
                                                />
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item align="center">
                                                <ToolTipComponent title={"Edit/Update Role"} arrow>
                                                    <IconButton className={classNames(classes.referenceRoleIcon, classes.marginRight5)} onClick={() => onEdit("Edit Role", role.id, roles)}>
                                                        <svg
                                                            version="1.1"
                                                            id="Layer_1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="21.349"
                                                            height="23.349"
                                                            x="0px"
                                                            y="0px"
                                                            viewBox="0 0 32 32"
                                                        >
                                                            <g id="edit" transform="translate(0 0)">
                                                                <path
                                                                    fill={theme.palette.primary.main}
                                                                    stroke={theme.palette.primary.main}
                                                                    strokeWidth="0.3"
                                                                    strokeMiterlimit="10"
                                                                    d="M27.2,8L24,4.8c-0.8-0.8-2.2-0.8-3,0L6.5,19.4c-0.1,0.1-0.1,0.1-0.1,0.2l-2.1,7.5c-0.1,0.3,0.1,0.6,0.4,0.7
                        c0.1,0,0.2,0,0.3,0l7.5-2.1c0.1,0,0.2-0.1,0.2-0.1l14.6-14.4C28,10.2,28,8.9,27.2,8L27.2,8z M18.6,8.7l2,2L10.2,21l-0.7-1.5
                        c-0.1-0.2-0.3-0.3-0.5-0.3H8.2L18.6,8.7z M5.5,26.5L6.2,24L8,25.8L5.5,26.5z M11.7,24.7l-2.6,0.7l-2.6-2.6l0.7-2.6h1.4l0.9,1.8
                        c0.1,0.1,0.1,0.2,0.2,0.2l1.8,0.9L11.7,24.7L11.7,24.7z M12.8,23.8V23c0-0.2-0.1-0.4-0.3-0.5L11,21.7l10.3-10.3l2,2L12.8,23.8z
                        M26.4,10.3l-2.4,2.3l-4.7-4.7l2.3-2.4c0.4-0.4,1.1-0.4,1.5,0l3.2,3.2C26.8,9.2,26.8,9.9,26.4,10.3L26.4,10.3z"
                                                                />
                                                            </g>
                                                        </svg>
                                                    </IconButton>
                                                </ToolTipComponent>
                                                {
                                                    (!role.is_default && role.users === 0) &&
                                                    <ToolTipComponent title={"Delete Role"} arrow>
                                                        <IconButton className={classes.referenceRoleIcon} onClick={() => deleteRoleList(role.id)}>
                                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 32 32">
                                                                <g id="Delete">
                                                                    <path fill={theme.palette.primary.main}
                                                                        stroke={theme.palette.primary.main}
                                                                        strokeWidth="0.92"
                                                                        strokeMiterlimit="10"
                                                                        d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                                                    <path fill={theme.palette.primary.main}
                                                                        stroke={theme.palette.primary.main}
                                                                        strokeWidth="0.92"
                                                                        strokeMiterlimit="10"
                                                                        d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                                </g>
                                                            </svg>
                                                        </IconButton>
                                                    </ToolTipComponent>
                                                }
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                        {
                            !isLoading && filterRoles.length === 0 &&
                            <NoResultFound />
                        }
                        {
                            isLoading &&
                            <Loader />
                        }
                    </Table>
                </TableContainer>
            </Grid>

        </Grid>
    );

};

RoleList.propTypes = {
    classes: PropTypes.object,
    organizationId: PropTypes.number,
    theme: PropTypes.object,
    onEdit: PropTypes.func
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(RoleList);