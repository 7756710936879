import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classnames from 'classnames';

import MentionComment from '../MentionComment/MentionComment.jsx';
import ConversationStyles from './ConversationStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import TextBox from '../TextBox/TextBox.jsx';


const ConversationTextBox = (props) => {

    const { classes, message, onChange, users, messageType, addMessage, assignUser, placeHolder } = props;

    const onKeyDown = (event) => {
        if (event.keyCode === 13 && message.trim() !== "") {
            addMessage();
        }
    };

    return (
        <ValidatorForm name="conversation" onSubmit={() => null} className={classes.commentForm}>
            <Grid className={classes.commentContainer}>
                {
                    messageType === "new" ?
                        <MentionComment
                            rowCount={4}
                            comment={message ? message : ''}
                            onChangeComment={(value) => onChange(value)}
                            users={users ? users : []}
                            showHelperText={message === ""}
                            helperText={"Message is required"}
                            placeholder="@ using create new chat"
                            getAssignUsers={assignUser}
                        /> :
                        <TextBox
                            className={classnames(classes.inlinetxt, classes.textarea)}
                            multiline
                            rows={4}
                            value={message ? message : ''}
                            onChange={(event) => onChange(event.target.value)}
                            onKeyDown={(event) => onKeyDown(event)}
                            placeholder={placeHolder ? placeHolder : "Type a message"}
                        />
                }
                <IconButton disabled={message.length === 0} className={classes.commentSendBtn} onClick={() => (message !== "" ? addMessage() : null)}>
                    <SendIcon color="primary" />
                </IconButton>
            </Grid>
        </ValidatorForm>
    );
};

ConversationTextBox.propTypes = {
    classes: PropTypes.object,
    message: PropTypes.string,
    onChange: PropTypes.func,
    users: PropTypes.array,
    messageType: PropTypes.string,
    addMessage: PropTypes.func,
    assignUser: PropTypes.func,
    placeHolder: PropTypes.string
};

export default withStyles((theme) => ({
    ...ConversationStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ConversationTextBox);