import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography } from '@material-ui/core';
import OwlCarousel from 'react-owl-carousel2';
import classNames from 'classnames';
import './slider.css';

import SliderStyles from './SliderStyles.jsx';
import Folder from '../../assets/images/folder.svg';
import Database from '../../assets/images/database.svg';
import Analytics from '../../assets/images/analytics.svg';
import Clean from '../../assets/images/clean.svg';
import { appConstants } from '../../constants/appConstants';


const Slider = (props) => {
	const { classes } = props;
	const options = {
		items: 1,
		nav: false,
		rewind: true,
		autoplay: false,
		dots: true
	};
	return (
		<Grid className={classes.bodyContainer}>
			{
				appConstants.environment ?
					<Grid className={classes.bannerImage} style={{ backgroundImage: `url(${require(`../../assets/images/banner.png`).default})` }} />
					:
					<OwlCarousel options={options} className={classes.pageContainer}>
						<Grid container className={classes.container}>
							<Grid item className={classes.titleContainer}>
								<Typography component="h5" variant="h5">
									An Unified Suite of Modules for all Data
									<br />
									{' '}
									Management Needs.
								</Typography>
							</Grid>
							<Grid item className={classNames(classes.card, classes.slide1)}>
								<a href="https://www.dqlabs.ai/data-catalog/" target="_blank" className="ripple" rel="noopener noreferrer">
									<img src={Folder} alt="folder" />
								</a>
								<Grid component="a" href="https://www.dqlabs.ai/data-catalog/" target="_blank" className={classNames(classes.cardContent, classes.cardContent1, 'rippleContent')}>
									<Typography variant="body2" className={classes.title}>
										Data Catalog
									</Typography>
									<Typography variant="body2" className={classes.description}>
										DQLabs modernizes the meta data, catalog and taxonomy management with its out-of-the-box connectors and using its patent pending AI driven DataSense™ technology.
									</Typography>
								</Grid>
							</Grid>

							<Grid item className={classNames(classes.card, classes.slide2)}>
								<a href="https://www.dqlabs.ai/master-data-management/" target="_blank" className="ripple" rel="noopener noreferrer">
									<img src={Database} alt="Database" />
								</a>
								<Grid component="a" href="https://www.dqlabs.ai/master-data-management/" target="_blank" className={classNames(classes.cardContent, classes.cardContent2, 'rippleContent')}>
									<Typography variant="body2" className={classes.title}>
										Master Data Management
									</Typography>
									<Typography variant="body2" className={classes.description}>
										Easy entity definition and management of Master models and creation of master or golden key records for customers, products, devices, and other business entities.
									</Typography>
								</Grid>
							</Grid>
							<Grid item className={classNames(classes.card, classes.slide3)}>
								<a href="https://www.dqlabs.ai/data-quality/" target="_blank" className="ripple" rel="noopener noreferrer">
									<img src={Analytics} alt="folder" />
								</a>
								<Grid component="a" href="https://www.dqlabs.ai/data-quality/" target="_blank" className={classNames(classes.cardContent, classes.cardContent3, 'rippleContent')}>
									<Typography variant="body2" className={classes.title}>
										Data Quality
									</Typography>
									<Typography variant="body2" className={classes.description}>
										Ability to discover patterns, insights, fraud, missing values and correlations across attributes using all connected datasets and data sources within fraction of minutes using AI from users’ behaviors and automatic business rules configurations.
									</Typography>
								</Grid>
							</Grid>
							<Grid item className={classNames(classes.card, classes.slide4)}>
								<a href="https://www.dqlabs.ai/data-curation/" target="_blank" className="ripple" rel="noopener noreferrer">
									<img src={Clean} alt="Clean" />
								</a>
								<Grid component="a" href="https://www.dqlabs.ai/data-curation/" target="_blank" className={classNames(classes.cardContent, classes.cardContent4, 'rippleContent')}>
									<Typography variant="body2" className={classes.title}>
										Data Curation
									</Typography>
									<Typography variant="body2" className={classes.description}>
										ML based smart curation modules to automatically curate the data based on the metadata definition and using Reinforcement Learning at all three levels of curation – Basic, Reference and Advanced.
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</OwlCarousel>
			}

		</Grid>
	);
};

Slider.propTypes = {
	classes: PropTypes.object
};


export default withStyles(SliderStyles)(Slider);