import React, { useEffect } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../../layouts/Styles.jsx';
import ChartStyles from '../ChartStyles.jsx';
import { appConstants } from '../../../constants/appConstants.js';
import Group2138 from '../../../assets/images/Group_2138.svg';
import Group2136 from '../../../assets/images/Group_2136.svg';


const BoxPlot = (props) => {

    const { chartData, classes, theme, attribute, onFilterChange, attributeChange, discardEdit, edited } = props;

    useEffect(() => {
        renderChart();
    });

    const renderChart = () => {
        if (chartData && Object.keys(chartData).length !== 0 && (attributeChange || edited)) {
            if (edited) {
                discardEdit();
            }
            if (chartData && typeof(chartData.valid_data) === "object") {
                chartData.valid_data = [];
            }
            if (chartData && typeof(chartData.outliers) === "object") {
                chartData.outliers = [];
            }
            const { q1, q3, iqr } = chartData.input_params;
            const lowerLimit = chartData.input_params.lower_limit;
            const upperLimit = chartData.input_params.upper_limit;
            const validData = chartData.valid_data.map((data) => { return data[attribute]; });
            const outliers = chartData.outliers.map((data) => { return data[attribute]; });
            const boxPlotData = [...validData, ...outliers];

            const margin = { top: 40, right: 20, bottom: 50, left: 40 };
            let width = 960 - margin.left - margin.right;
            const height = 300;

            const barOpacity = 0.5;
            const barHighlightOpacity = 0.75;
            const center = width / 4;
            const barWidth = 150;

            const formatValue = d3.format(".2s");

            const min = q1 - 1.5 * iqr;
            const max = q1 + 1.5 * iqr;

            let minY = d3.min(boxPlotData);
            if (lowerLimit < minY) {
                minY = lowerLimit;
            }
            let maxY = d3.max(boxPlotData);
            if (upperLimit > maxY) {
                maxY = upperLimit;
            }
            minY = minY < min ? minY : min;
            maxY = maxY > max ? maxY : max;
            const y = d3.scaleLinear()
                .domain([(minY - (minY / 10)), (maxY + (maxY / 10))])
                .range([height, 0]);

            const yAxis = (g) => g
                .attr("transform", `translate(0,0)`)
                .attr("color", theme.palette.chartColors.axis)
                .attr('class', 'axis-y')
                .call(d3.axisLeft(y).tickFormat((d) => { return formatValue(d); }));

            d3.select(".box-plot-chart > svg").remove();
            d3.select(".box-plot-chart > .tooltip").remove();
            d3.select(".box-plot-chart > .anomaly-box").remove();
            d3.select('.box-plot-chart > .anomaly-legend').remove();

            const svg = d3.select(".box-plot-chart").append("svg")
                .attr("width", "75%")
                .style("margin", "0 auto")
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("width", (_, i, nodes) => {
                    width = nodes[i].parentNode.clientWidth;
                    return width;
                })
                .attr("height", height + margin.top + margin.bottom)
                .attr("transform",
                    "translate(" + (margin.left + 20) + "," + (margin.top / 2) + ")");
            svg.append("g").call(yAxis);

            //vertical line
            svg.append("line")
                .attr("x1", center)
                .attr("x2", center)
                .attr("y1", y(min))
                .attr("y2", y(max))
                .attr("stroke", "black");

            const div = d3.select(".box-plot-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0);

            //box
            svg.append("rect")
                .attr("x", center - barWidth / 2)
                .attr("y", y(q3))
                .attr("height", (y(q1) - y(q3)))
                .attr("width", barWidth)
                .attr("stroke", "black")
                .style("fill", theme.palette.chartColors.valid)
                .style("opacity", barOpacity)
                .on('mouseover', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", barHighlightOpacity);
                    // Show Tooltip
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html("Min: " + Math.round(min) + "<br />Max: " + Math.round(max) + "<br />Lower Quartile: " + Math.round(q1) + "<br />Upper Quartile: " + Math.round(q3) + "<br />Inter Quartile Range: " + Math.round(iqr))
                        .style("left", (d3.mouse(d3.select(".box-plot-chart").node())[0]) - 20 + "px")
                        .style("top", (d3.mouse(d3.select(".box-plot-chart").node())[1]) - 120 + "px")
                        .style("opacity", 0.9);
                })
                .on('mouseout', (_, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", barOpacity);
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                });

            svg.selectAll("toto")
                .data([min, max])
                .enter()
                .append("line")
                .attr("x1", center - barWidth / 2)
                .attr("x2", center + barWidth / 2)
                .attr("y1", (d) => { return (y(d)); })
                .attr("y2", (d) => { return (y(d)); })
                .attr("stroke", "black")
                .on('mouseover', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", barHighlightOpacity);
                    // Show Tooltip
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html("Min: " + Math.round(min) + "<br />Max: " + Math.round(max) + "<br />Lower Quartile: " + Math.round(q1) + "<br />Upper Quartile: " + Math.round(q3) + "<br />Inter Quartile Range: " + Math.round(iqr))
                        .style("left", (d3.mouse(d3.select(".box-plot-chart").node())[0]) - 20 + "px")
                        .style("top", (d3.mouse(d3.select(".box-plot-chart").node())[1]) - 120 + "px")
                        .style("opacity", 0.9);
                })
                .on('mouseout', (_, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", barOpacity);
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                });

            svg.append("g")
                .attr("stroke-width", 1.5)
                .attr("font-family", "sans-serif")
                .attr("font-size", 10)
                .selectAll("g")
                .data(outliers)
                .join("g")
                .attr("transform", (d) => `translate(${center},${y(d)})`)
                .call((g) => g.append("circle")
                    .attr("stroke", theme.palette.chartColors.inValid)
                    .attr("fill", theme.palette.chartColors.inValid)
                    .attr("r", 3)
                    .attr("opacity", 0)
                    .on('mouseover', (d, i, nodes) => {
                        d3.select(nodes[i]).style("opacity", barHighlightOpacity);
                        // Show Tooltip
                        div.transition()
                            .duration(200)
                            .style("opacity", 0.9);
                        div.html("Value: " + Math.round(d))
                            .style("left", (d3.mouse(d3.select(".box-plot-chart").node())[0]) - 40 + "px")
                            .style("top", (d3.mouse(d3.select(".box-plot-chart").node())[1]) - 50 + "px")
                            .style("opacity", 0.9);
                    })
                    .on('mouseout', (_, i, nodes) => {
                        d3.select(nodes[i]).style("opacity", barOpacity);
                        div.transition()
                            .duration(500)
                            .style("opacity", 0);
                    }).on('click', (d) => {
                        onFilterChange({ value: d, filterType: appConstants.QueryFilterTypes[0] });
                    }));

            svg.selectAll("circle")
                .transition()
                .delay((d, i) => { return (i * 3); })
                .duration(2000).attr("opacity", barOpacity);

            const lineWidth = (width - (margin.left + margin.right + barWidth * 2));
            const limitTextX = (lineWidth + 30);
            svg.append("text").attr("class", "interval-line-text").attr("y", () => {
                if (upperLimit === lowerLimit) {
                    return y(lowerLimit) + 20;
                }
                return y(lowerLimit) + 2;
            }).attr("x", limitTextX).text(((y(lowerLimit) - y(upperLimit)) > 10) ? "Lower Limit" : "");
            svg.append("g").append("line")
                .attr("class", "zero")
                .attr("x1", -30)
                .attr("y1", y(lowerLimit))
                .attr("x2", lineWidth)
                .attr("y2", y(lowerLimit))
                .style("stroke", theme.palette.secondary.main)
                .attr("transform", "translate(30,0)");

            //upper limit
            svg.append("text").attr("class", "interval-line-text").attr("y", y(upperLimit) + 2).attr("x", limitTextX).text(((y(lowerLimit) - y(upperLimit)) > 10) ? "Upper Limit" : "");
            svg.append("g").append("line")
                .attr("class", "zero")
                .attr("x1", -30)
                .attr("y1", y(upperLimit))
                .attr("x2", lineWidth)
                .attr("y2", y(upperLimit))
                .style("stroke", theme.palette.secondary.main)
                .attr("transform", "translate(30,0)");

            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');

            const lines = [{ labelName: 'Upper Limit', value: upperLimit }, { labelName: 'Lower Limit', value: lowerLimit }];
            const linesUpper = ['Upper Limit', 'Lower Limit'];
            const boxDescription = ['Computed upper limit of', 'Computed Lower limit of '];
            const legendGroup = d3.select(".box-plot-chart").append("div")
                .attr("class", "anomaly-legend");

            legendGroup.selectAll(".legend-group")
                .data(lines)
                .enter()
                .append("p")
                .attr("class", "legend-text")
                .attr("alignment-baseline", "middle")
                .text((line) => {
                    return `${line.labelName} (${line.value})`;
                })
                .style("opacity", 0)
                .transition()
                .duration(1000)
                .style("opacity", 1);

            // BoxPlot
            const legendBox = d3.select(".box-plot-chart").append("div")
                .attr("class", "anomaly-box")
                .style("position", 'absoulte')
                .append('div')
                .attr("class", "anomaly-box-row")
                .style("position", 'relative');

            legendBox.selectAll(".anomaly-box-row")
                .data(linesUpper)
                .enter()
                .append("div")
                .attr("class", "box-column")
                .style("left", '-200px')
                .style("top", (line) => {
                    if (line === "Lower Limit") {
                        return y(lowerLimit) - 10 + 'px';
                    } else if (line === "Upper Limit") {
                        return y(upperLimit) - 50 + 'px';
                    }
                });


            legendBox.selectAll(".box-column")
                .data(linesUpper)
                .append("b")
                .text((lines) => {
                    return lines;
                })
                .attr("class", "legend-box-bold");

            legendBox.selectAll(".box-column")
                .data(linesUpper)
                .append("p")
                .text((line, index) => {
                    if (line === "Upper Limit") {
                        return `${boxDescription[index]} ${upperLimit}`;
                    } else if (line === "Lower Limit") {
                        return `${boxDescription[index]} ${lowerLimit}`;

                    }
                })
                .attr("class", "legend-box-text");
            legendBox.selectAll(".box-column")
                .data(linesUpper)
                .append("img")
                .attr('src', (line, index) => {
                    if (line === "Upper Limit") {
                        return Group2136;
                    } else if (line === "Lower Limit") {
                        return Group2138;

                    }
                })
                .style('transform', (line, index) => {
                    if (line === "Upper Limit") {
                        return 'rotate(151deg)';
                    } else if (line === "Lower Limit") {
                        return 'rotate(41deg)';
                    }
                })

                .attr("class", "legend-box-img")
                .attr("width", "80")
                .attr("height", "45");

        }
    };

    return (
        <Grid container className={classNames(classes.chartStyles, "box-plot-chart")} />
    );
};

BoxPlot.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.object,
    theme: PropTypes.object,
    attribute: PropTypes.string,
    onFilterChange: PropTypes.func,
    attributeChange: PropTypes.bool,
    edited: PropTypes.bool,
    discardEdit: PropTypes.func
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(BoxPlot);