import React, { useState, useCallback } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import ActionItemStyles from './ActionItemStyles.jsx';
import CaptureScreen from './CaptureScreen.jsx';
import AddActionItem from './AddActionItem.jsx';
import html2canvas from 'html2canvas';


const ActionItem = () => {
    const [isActiveActionItem, setActiveActionItem] = useState(false);
    const [htmlContent, setHtmlContent] = useState('');
    const [translateVal, setTranslateVal] = useState(0);
    const screenCapture = useCallback(() => {
        if (document.getElementById('main_container').querySelector(".slick-track")) {
            let matrixValues = [0, 0, 0, 0, 0, 0];
            const currentTransform = window.getComputedStyle(document.querySelector('.slick-track')).transform;
            matrixValues = currentTransform.match(/matrix.*\((.+)\)/)[1].split(', ');
            setTranslateVal(matrixValues[4]);
            document.getElementById('main_container').querySelector(".slick-track").style.removeProperty('transform');
            document.getElementById('main_container').querySelectorAll(".slick-slide:not(.slick-active)").forEach((el) => el.style.display = 'none');
        }

         html2canvas(document.getElementById('main_container'), { ignoreElements: (el) => el.className === 'slick-slide'
            }).then((canvas) => {
              if (canvas) {
                  setHtmlContent(canvas.toDataURL());
              }
          });
        setActiveActionItem(true);
    }, []);

    const discard = useCallback(() => {
        setActiveActionItem(false);
        if (document.getElementById('main_container').querySelector(".slick-track")) {
            document.getElementById('main_container').querySelectorAll(".slick-slide:not(.slick-active)").forEach((el) => el.style.display = 'block');
            document.getElementById('main_container').querySelector(".slick-track").style.transform = `translate3d(${translateVal}px, 0px, 0px)`;
        }
        setHtmlContent('');
    }, [translateVal]);

    return (
        <Grid>
            {
                !isActiveActionItem ?
                    <CaptureScreen screenCapture={(base64) => screenCapture(base64)} /> :
                    <AddActionItem screenImage={htmlContent} discard={() => discard()} />
            }

        </Grid>
    );

};

ActionItem.propTypes = {
};


export default withStyles((theme) => ({
    ...ActionItemStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ActionItem);