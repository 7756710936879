import React, { Fragment } from 'react';
import { Grid, Button, IconButton, Switch, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Rule from './Rule.jsx';
import { isRuleGroup } from './QueryBuilderUtil.jsx';
import CombinatorSelector from './Widgets/CombinatorSelector.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import { useSelector } from 'react-redux';
import TextBox from '../TextBox/TextBox.jsx';

const RuleGroup = (props) => {
    const { id, parentId, rules, isConditionalRule, connector, not, builderConfig, className, theme, isPatternChart, disabled, export_metrics_custom_value, ruleGroup } = props;
    const { classes, isComplexRule, onAddRule, onAddRuleGroup, onGroupRemove, onPropertyChange, onChangeRuleType, onRuleGroupUpdate } = builderConfig;
    const datasource = useSelector(({ datasource }) => datasource?.datasource);
    const hasParentGroup = () => Boolean(parentId);
    const onCombinatorChange = (value) => {
        if (!disabled) {
            onPropertyChange('connector', value, id, isConditionalRule);
        }
    };

    const onNotToggleChange = (checked) => {
        if (!disabled) {
            onPropertyChange('not', checked, id, isConditionalRule);
        }
    };

    const addRule = () => {
        onAddRule(id, isConditionalRule);
    };

    const addGroup = () => {
        onAddRuleGroup(id, isConditionalRule);
    };

    const removeGroup = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onGroupRemove(id, parentId, isConditionalRule);
    };

    return (
        <Grid container className={classNames(classes.group, className)}>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap" className={classes.groupHeader}>
                <Grid item>
                    <CombinatorSelector
                        selectedConnector={connector}
                        not={not}
                        disabled={rules.length <= 1}
                        onNotToggleChange={() => onNotToggleChange(!not)}
                        onChange={(value) => onCombinatorChange(value)}
                    />
                </Grid>
                {
                    isComplexRule && !parentId &&
                    <Typography className={classes.complexRuleTitle} style={{ top: isConditionalRule ? -12 : -8 }}>
                        {!isConditionalRule ? 'IF' : 'THEN'}
                    </Typography>
                }
                {
                    !disabled &&
                    <Grid item style={{ display: 'flex' }}>
                        {
                            parentId && export_metrics_custom_value &&
                            <TextBox
                                // label="Custom Label"
                                value={ruleGroup?.custom_value ? ruleGroup?.custom_value : ""}
                                name="custom_value"
                                fullWidth
                                style={{ height: '100%' }}
                                onChange={(event) => onRuleGroupUpdate(event.target.name, event.target.value, id, isConditionalRule)}
                            />
                        }
                        <Button className={classes.btnAction} onClick={addRule}>
                            {'Add Rule'}
                        </Button>
                        <Button className={classes.btnAction} onClick={addGroup} style={{ marginRight: 10 }}>
                            {'Add Group'}
                        </Button>
                        {
                            (!parentId && !isConditionalRule && (!datasource?.scan)) &&
                            <ToolTipComponent title={isComplexRule ? "Complex Rules ON" : "Complex Rules OFF"}>
                                <Switch color="secondary"
                                    name="is_unique"
                                    checked={isComplexRule}
                                    onChange={(event) => onChangeRuleType(event.target.checked)}
                                />
                            </ToolTipComponent>
                        }
                        {
                            hasParentGroup() &&
                            <ToolTipComponent title="Remove Group" arrow>
                                <IconButton className={classes.actionIconButton} onClick={(event) => removeGroup(event)}>
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 32 32">
                                        <g id="Delete">
                                            <path fill={theme.palette.primary.main}
                                                stroke={theme.palette.primary.main}
                                                strokeWidth="0.92"
                                                strokeMiterlimit="10"
                                                d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                            <path fill={theme.palette.primary.main}
                                                stroke={theme.palette.primary.main}
                                                strokeWidth="0.92"
                                                strokeMiterlimit="10"
                                                d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                        </g>
                                    </svg>
                                </IconButton>
                            </ToolTipComponent>
                        }
                    </Grid>
                }
            </Grid>
            <Grid className={classes.groupChildren}>
                {
                    rules.map((rule, index) => {
                        return (
                            <Fragment key={index}>
                                {
                                    isRuleGroup(rule)
                                        ? <RuleGroup id={rule.id}
                                            parentId={id}
                                            rules={[...rule.rules]}
                                            connector={rule.connector}
                                            not={rule.not}
                                            builderConfig={builderConfig}
                                            theme={theme}
                                            disabled={disabled}
                                            isConditionalRule={isConditionalRule}
                                            export_metrics_custom_value={export_metrics_custom_value}
                                            ruleGroup={rule}
                                        />
                                        : <Rule id={rule.id}
                                            parentId={id}
                                            rule={rule}
                                            builderConfig={builderConfig}
                                            theme={theme}
                                            isPatternChart={isPatternChart}
                                            disabled={disabled}
                                            isConditionalRule={isConditionalRule}
                                            export_metrics_custom_value={export_metrics_custom_value}
                                            hasParentGroup={hasParentGroup()}
                                        />
                                }
                            </Fragment>
                        );
                    })
                }
            </Grid>
        </Grid >
    );
};

RuleGroup.propTypes = {
    classes: PropTypes.object,
    rules: PropTypes.array,
    id: PropTypes.string,
    parentId: PropTypes.string,
    connector: PropTypes.string,
    not: PropTypes.bool,
    className: PropTypes.string,
    builderConfig: PropTypes.object,
    theme: PropTypes.object,
    isPatternChart: PropTypes.bool,
    disabled: PropTypes.bool,
    isConditionalRule: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    export_metrics_custom_value: PropTypes.bool,
    ruleGroup: PropTypes.object
};

export default RuleGroup;