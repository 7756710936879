import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import MasterSearchPanel from '../MasterSearchPanel/MasterSearchPanel.jsx';
import MasterSearchDetail from './MasterSearchDetail.jsx';
import { clearMasterSearchResults, getAttributes, masterSearch } from '../../actions/masterActions';
import Styles from '../../layouts/Styles.jsx';
import MasterSearchDetailStyles from './MasterSearchDetailStyles.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import Loader from '../Loaders/Loader.jsx';

const MasterSearch = (props) => {
    const { classes, model, modelId, tabIndex } = props;
    const searchTabIndex = 3;
    const dispatch = useDispatch();
    const [attributes, setAttributes] = useState([]);
    const [view, setView] = useState('table');
    const [isLoading, setIsLoading] = useState(false);
    const [isRender, setRender] = useState(true);
    const masterData = useSelector(({ master }) => master.masterData);
    const searchResults = useSelector(({ master }) => master.searchResults);
    const defaultFields = useState(["MatchRecordID", "MatchLevel", "MatchRank", "MatchKeys", "HeaderRecordID"]);


    const getAttributeList = useCallback(() => {
        setView('table');
        dispatch(clearMasterSearchResults());
        // const defaultSearchFields = [{ "name": "MatchRecordID", "data_type": "Integer" }, { "name": "MatchLevel", "data_type": "Text" }, { "name": "MatchRank", "data_type": "Integer" }, { "name": "MatchKeys", "data_type": "Text" }, { "name": "HeaderRecordID", "data_type": "Integer" }];
        const defaultSearchFields = [{ "name": "MatchRecordID", "data_type": "Integer" }, { "name": "MatchPrefix", "data_type": "Text" }];
        // const defaultSearchFields = [];
        dispatch(getAttributes(modelId)).then((response) => {
            if (response) {
                response = response.sort((a, b) => a.id - b.id);
                response = [...response, ...defaultSearchFields];
                setAttributes([...response]);
            }
        });
    }, [dispatch, modelId]);

    useEffect(() => {
        if (tabIndex !== searchTabIndex) {
            return;
        }
        getAttributeList();
    }, [getAttributeList, tabIndex]);


    const prepareQueryParams = useCallback((searchParams, isExactMatch) => {
        const keys = Object.keys(searchParams).filter((key) => !defaultFields.includes(key));
        const ExactMatch = isExactMatch;
        const searchDict = {};
        for (const key of keys) {
            const searchInput = searchParams[key];
            if (searchInput && searchInput.value !== '') {
                let value = searchInput.datatype === 'Integer' || searchInput.datatype === 'Numeric' ? `${searchInput.value}` : `${searchInput.value.toUpperCase()}`;
                value = searchInput.datatype === 'Date' ? value : value.replace(/[-]/g, '');
                if (key === 'MatchRecordID') {
                    searchDict[model.name + "_RecordID"] = value;
                } else {
                    searchDict[key] = value;
                }
            }
        }
        if (ExactMatch) {
            searchDict.ExactMatch = true;
        } else {
            searchDict.ExactMatch = false;
        }

        return searchDict;
    }, [defaultFields, model]);

    const searchMasterModel = useCallback((searchParams, isExactMatch) => {
        const queryParams = prepareQueryParams(searchParams, isExactMatch);
        const searchInput = {
            'search_params': queryParams,
            'model_id': modelId
        };
        setIsLoading(true);
        dispatch(masterSearch(searchInput)).then(() => {
            setIsLoading(false);
            setRender(false);
        });
    }, [dispatch, prepareQueryParams, modelId]);

    const cancelSearch = useCallback(() => {
        dispatch(clearMasterSearchResults());
        setRender(true);
    }, [dispatch]);

    return (
        <Grid container spacing={2}>
            <Grid item className={classes.sidePanel}>
                <MasterSearchPanel
                    tabIndex={tabIndex}
                    searchTabIndex={searchTabIndex}
                    modelAttributes={attributes}
                    search={searchMasterModel}
                    cancelSearch={cancelSearch} />
            </Grid>
            <Grid item className={classes.rightPanel}>
                {/* <Grid container justify="flex-end" alignItems="center" margin="normal" className={classes.viewGroup}>
                    <ButtonGroup className={classes.buttonGroup}>
                        <Button variant="contained"
                            className={classNames(view === 'card' ? 'active' : '')}
                            onClick={() => setView('card')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16" viewBox="0 0 25 16">
                                <g id="Group_1523" data-name="Group 1523" transform="translate(-1233.716 -173.46)">
                                    <g id="Rectangle_618" data-name="Rectangle 618" transform="translate(1240.716 173.46)" fill="none" stroke="#afb2b3" strokeWidth="1.5">
                                        <rect width="11" height="16" stroke="none" />
                                        <rect x="0.75" y="0.75" width="9.5" height="14.5" fill="none" />
                                    </g>
                                    <g id="Rectangle_619" data-name="Rectangle 619" transform="translate(1253.716 176.46)" fill="none" stroke="#afb2b3" strokeWidth="1.5">
                                        <rect width="5" height="11" stroke="none" />
                                        <rect x="0.75" y="0.75" width="3.5" height="9.5" fill="none" />
                                    </g>
                                    <g id="Rectangle_620" data-name="Rectangle 620" transform="translate(1233.716 176.46)" fill="none" stroke="#afb2b3" strokeWidth="1.5">
                                        <rect width="5" height="11" stroke="none" />
                                        <rect x="0.75" y="0.75" width="3.5" height="9.5" fill="none" />
                                    </g>
                                </g>
                            </svg>
                        </Button>
                        <Button variant="contained"
                            className={classNames(view === 'table' ? 'active' : '')}
                            onClick={() => setView('table')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
                                <g id="Layer_2" data-name="Layer 2" transform="translate(-2.268 -4)">
                                    <path id="Path_236" data-name="Path 236" d="M21.71,4H11.3a1.3,1.3,0,1,0,0,2.6H21.71a1.3,1.3,0,0,0,0-2.6Z" transform="translate(-2.743)" fill="#afb2b3" />
                                    <circle id="Ellipse_88" data-name="Ellipse 88" cx="1" cy="1" r="1" transform="translate(2.268 4)" fill="#afb2b3" />
                                    <path id="Path_237" data-name="Path 237" d="M21.71,14H11.3a1.3,1.3,0,1,0,0,2.6H21.71a1.3,1.3,0,0,0,0-2.6Z" transform="translate(-2.743 -3.495)" fill="#afb2b3" />
                                    <circle id="Ellipse_89" data-name="Ellipse 89" cx="1" cy="1" r="1" transform="translate(2.268 11)" fill="#afb2b3" />
                                    <path id="Path_238" data-name="Path 238" d="M21.71,24H11.3a1.3,1.3,0,1,0,0,2.6H21.71a1.3,1.3,0,0,0,0-2.6Z" transform="translate(-2.743 -6.989)" fill="#afb2b3" />
                                    <ellipse id="Ellipse_90" data-name="Ellipse 90" cx="1" cy="1.5" rx="1" ry="1.5" transform="translate(2.268 17)" fill="#afb2b3" />
                                </g>
                            </svg>
                        </Button>
                    </ButtonGroup>
                </Grid> */}
                {
                    searchResults.length === 0 && !isRender &&
                        <NoResultFound />
                }
                {
                    searchResults.length > 0 && !isRender &&
                    <MasterSearchDetail
                        personDetails={masterData}
                        searchResults={searchResults}
                        view={view}
                        isLoading={isLoading}
                    />
                }
                {isLoading && <Loader classList={classNames(classes.searchLoader)} />}
            </Grid>
        </Grid>
    );
};

MasterSearch.propTypes = {
    classes: PropTypes.object,
    modelId: PropTypes.string,
    model: PropTypes.object,
    tabIndex: PropTypes.number
};

export default withStyles((theme) => ({
    ...MasterSearchDetailStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MasterSearch);