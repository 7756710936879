import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import PrivateLayout from '../layouts/PrivateLayout.jsx';
import { getCookie } from '../helpers/appHelpers';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
	const headerText = useSelector(({ setting }) => setting?.theme?.page_title || '');

	const loggeInStatus = getCookie('token');

	const getPageTitle = (pageTitle) => {
		if (pageTitle) {
			document.title = `${headerText.trim() || 'DQ Labs'} | ${pageTitle}`;
		} else if (document.title !== 'DQ Labs') {
			document.title = headerText || 'DQ Labs';
		}
	};

	return (
		<Route {...rest}
			render={
				(props) => (
					loggeInStatus !== "" ?
						(
							<PrivateLayout {...props}>
								<Component {...props} getPageTitle={getPageTitle} />
							</PrivateLayout>
						)
						: <Redirect to={{ pathname: '/', state: { from: props.location } }} />
				)
			} />
	);
};

PrivateRoute.propTypes = {
	component: PropTypes.elementType,
	location: PropTypes.object
};

export default PrivateRoute;