import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Button, TextField, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';
import * as XLSX from 'xlsx';

// import Styles
import DatasourceimportStyles from './DatasourceimportStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import classnames from 'classnames';
import FileUpload from '../../assets/images/file-upload.svg';

// import Actions
import { getCustomFieldsByOrg } from '../../actions/settingActions';
import { importCatalogMetadata } from '../../actions/datasourceActions';

// import Component
import AutoCompleteInput from '../../components/AutoComplete/AutoCompleteInput.jsx';
import { history } from '../../config/history';
import Loader from '../../components/Loaders/Loader.jsx';
import AlertDialog from '../../components/AlertDialog/AlertDialog.jsx';

const DatasourceImport = (props) => {

    const [show1, setShow1] = useState(true);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [alertText, setAlertText] = useState(true);

    const [importType, setImportType] = useState('');
    const [datasourceIndex, setDatasourceIndex] = useState('');
    const [datasetIndex, setDatasetIndex] = useState('');
    const [attributeIndex, setAttributeIndex] = useState('');
    const [fileData, setFileData] = useState([]);
    const [invalidRecords, setInvalidRecords] = useState([]);
    const [defaultFields, setDefaultFields] = useState({
        'datasource': '',
        'dataset': '',
        'attribute': '',
        'description': '',
        'domain': '',
        'tags': '',
        'comments': '',
        'min length': '',
        'max length': '',
        'min range': '',
        'max range': '',
        'null': '',
        'blank': '',
        'unique': '',
        'sensitivity': '',
        'primary': '',
        'business name': '',
        'datatype': '',
        'fieldtype': '',
        'status': ''
    });
    const [customFields, setCustomFields] = useState({});
    const [fileFields, setFileFields] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        props.getPageTitle('Datasource Import');
    });

    const handleBack = () => {
        history.back();
    };

    const handleBack1 = () => {
        setShow2(false);
        setShow1(true);
        setImportType('');
        setDefaultFields({
            'datasource': '',
            'dataset': '',
            'attribute': '',
            'description': '',
            'domain': '',
            'tags': '',
            'comments': '',
            'min length': '',
            'max length': '',
            'min range': '',
            'max range': '',
            'null': '',
            'blank': '',
            'unique': '',
            'sensitivity': '',
            'primary': '',
            'business name': '',
            'datatype': '',
            'fieldtype': '',
            'status': ''
        });
        setCustomFields({});
    };

    const handleBack2 = () => {
        setShow3(false);
        setShow2(true);
    };

    const onFieldChange = (property, value) => {
        const index = fileFields.indexOf(value);
        if (property === 'datasource') {
            setDatasourceIndex(index);
        } else if (property === 'dataset') {
            setDatasetIndex(index);
        } else if (property === 'attribute') {
            setAttributeIndex(index);
        }
        setDefaultFields({ ...defaultFields, [property]: value });
        // setFilteredOptions(fileFields.filter((evt) => ![...Object.values(defaultFields), ...Object.values(customFields)].includes(evt)));
    };

    const onCustomFieldChange = (property, value) => {
        setCustomFields({ ...customFields, [property]: value });
        // setFilteredOptions(fileFields.filter((evt) => ![...Object.values(customFields), ...Object.values(defaultFields)].includes(evt)));
    };

    const handleFileUpload = useCallback((report) => {
        const reader = new FileReader();
        reader.onload = (file) => {
            const bstr = file.target.result;
            const wb = XLSX.read(bstr, { type: 'array' });
            const wsname = wb.SheetNames[0];
            const sheet = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            const importData = data && data.length > 0 ? Object.assign(data, []) : [];
            setFileData(importData);
            const dataLength = data.length;
            const headerData = dataLength > 0 ? JSON.parse(JSON.stringify(data[0])) : [];
            let datasource_index = 0;
            let dataset_index = 0;
            let attribute_index = 0;
            let type = '';
            const filteredData = [];
            if (headerData.length > 0) {
                let columnMapping = {
                    'datasource': '',
                    'dataset': '',
                    'attribute': '',
                    'description': '',
                    'domain': '',
                    'tags': '',
                    'comments': '',
                    'min length': '',
                    'max length': '',
                    'min range': '',
                    'max range': '',
                    'null': '',
                    'blank': '',
                    'unique': '',
                    'sensitivity': '',
                    'primary': '',
                    'business name': '',
                    'datatype': '',
                    'fieldtype': '',
                    'status': ''
                };
                headerData.map((item, index) => {
                    const itemValue = item ? item.toLowerCase().trim() : '';
                    if (itemValue === 'datasource' || itemValue === 'datasources') {
                        datasource_index = index;
                        setDatasourceIndex(index);
                    }
                    if (itemValue === 'dataset' || itemValue === 'datasets') {
                        dataset_index = index;
                        setDatasetIndex(index);
                    }
                    if (itemValue === 'attribute' || itemValue === 'attributes') {
                        attribute_index = index;
                        setAttributeIndex(index);
                    }
                    if (columnMapping[itemValue] !== undefined) {
                        columnMapping = {
                            ...columnMapping,
                            [itemValue]: item
                        };
                        filteredData.push(item);
                    }
                    return '';
                });
                setDefaultFields(columnMapping);
                if (attribute_index > 0) {
                    type = 'Attribute';
                    setShow1(false);
                    setShow2(true);
                } else if (dataset_index > 0) {
                    type = 'DataSet';
                    setShow1(false);
                    setShow2(true);
                } else if (datasource_index >= 0) {
                    type = 'DataSource';
                    setShow1(false);
                    setShow2(true);
                } else {
                    dispatch(showAlert("No Datasource Found"));
                }
                setFileFields(headerData.filter((item) => item !== null));
            } else {
                dispatch(showAlert('Invalid Excel'));
            }
            setImportType(type);
            if (type !== '') {
                dispatch(getCustomFieldsByOrg(type)).then((response) => {
                    let customFieldData = response.reduce((a, b) => {
                        a[b.name] = '';
                        return a;
                    }, {});
                    headerData.map((item) => {
                        const itemValue = item ? item.trim() : '';
                        if (customFieldData[itemValue] !== undefined) {
                            customFieldData = {
                                ...customFieldData,
                                [itemValue]: item
                            };
                            filteredData.push(item);
                        }
                        return '';
                    });
                    setCustomFields(customFieldData);
                });
            }
        };
        reader.readAsArrayBuffer(report[0]);
    }, [dispatch, showAlert]);

    const handleImport = () => {
        setIsLoading(true);
        const requestParams = {
            type: importType,
            datasource_index: datasourceIndex,
            dataset_index: datasetIndex,
            attribute_index: attributeIndex,
            data: fileData,
            default_fields: defaultFields,
            custom_fields: customFields
        };
        dispatch(importCatalogMetadata(requestParams)).then((response) => {
            if (response) {
                setIsLoading(false);
                const errors = response.invalid_rows ? response.errors : 0;
                const totalRows = response.total_rows ? response.total_rows : 0;
                const invalidRows = response.invalid_rows ? response.invalid_rows : 0;
                if (invalidRows === 0) {
                    setShowAlert(true);
                    if (totalRows > 0) {
                        setAlertText('Metadata Imported Successfully');
                    } else {
                        setAlertText('Metadata Import Failed');
                    }
                } else {
                    if (errors.length > 0) {
                        const uniqueErrors = errors.filter((item, index) => {
                            return errors.indexOf(item) === index;
                        });
                        setInvalidRecords(uniqueErrors);
                        setShow2(false);
                        setShow3(true);
                    }
                }
            }
        });
    };

    const getRemainingOptions = (value) => {
        const selectedValues = [...Object.values(customFields), ...Object.values(defaultFields)];
        const remain = fileFields.filter((f) => selectedValues.indexOf(f) < 0);
        if (value) {
            remain.push(value);
        }
        return remain;
    };

    const close_alert = () => {
        setShowAlert(false);
        history.back();
    };


    const { classes } = props;
    return (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            {
                show1 &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid className={classes.backIcon}>
                        <Button className={classnames(classes.block, classes.nopadding, classes.backButton, classes.bgNone)}
                            onClick={() => handleBack()}>
                            <svg id="backArrow" xmlns="http://www.w3.org/2000/svg" width="8.335" height="13.909" viewBox="0 0 8.335 13.909" style={{ fill: '#6f777c', marginRight: "5px" }}>
                                <defs />
                                <g transform="translate(-11.172 0)">
                                    <path className="a" d="M11.564,5.967a1.359,1.359,0,0,1,.228-.181L17.183.395A1.347,1.347,0,0,1,19.088,2.3L14.446,6.943l4.666,4.667a1.347,1.347,0,1,1-1.9,1.905L11.792,8.1a1.4,1.4,0,0,1-.228-2.133Z" transform="translate(0 0)" />
                                </g>
                            </svg>
                            {'Back'}
                        </Button>
                    </Grid>
                    <Grid>
                        <Dropzone
                            maxSize={200 * (1024 * 1024)}
                            accept={['.csv', '.xlsx']}
                            disabled={false}
                            onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}
                        >
                            {
                                ({ getRootProps, getInputProps, isDragActive }) => (
                                    <Grid className={classnames(classes.droparea)}
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="center"
                                        {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Grid container
                                            direction="column"
                                            justify="center"
                                            alignItems="center"
                                            wrap="nowrap"
                                            className={classes.dropareaContent}>
                                            <Grid item>
                                                <img src={FileUpload} alt="file upload" className={classes.dropareaImage} />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h3" className={classes.dropareaText}>
                                                    {'Drag & Drop files here or'}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" color="primary">
                                                    {'Choose File(s)'}
                                                </Button>
                                            </Grid>

                                            <Grid item className={classes.hintContainer}>
                                                <Grid container direction="column" wrap="nowrap" justify="flex-start" alignItems="baseline">
                                                    <Typography variant="h3" className={classes.hintText}>
                                                        {'Supported file types: xlsx, csv'}
                                                    </Typography>
                                                    <Typography variant="h3" className={classes.hintText}>
                                                        {'Max. file size limit : 100MB'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Dropzone>
                    </Grid>
                </Grid>
            }
            {
                show2 &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.maxHeight}>
                    <Grid className={classes.backIcon}>
                        <Button className={classnames(classes.block, classes.nopadding, classes.backButton, classes.bgNone)}
                            onClick={() => handleBack1()}>
                            <svg id="backArrow" xmlns="http://www.w3.org/2000/svg" width="8.335" height="13.909" viewBox="0 0 8.335 13.909" style={{ fill: '#6f777c', marginRight: "5px" }}>
                                <defs />
                                <g transform="translate(-11.172 0)">
                                    <path className="a" d="M11.564,5.967a1.359,1.359,0,0,1,.228-.181L17.183.395A1.347,1.347,0,0,1,19.088,2.3L14.446,6.943l4.666,4.667a1.347,1.347,0,1,1-1.9,1.905L11.792,8.1a1.4,1.4,0,0,1-.228-2.133Z" transform="translate(0 0)" />
                                </g>
                            </svg>
                            {'Back'}
                        </Button>
                    </Grid>
                    <Grid className={classes.tablearea}>
                        <Grid className={classes.tableHeading}>
                            <Typography variant="h2" component="h2">
                                Upload Metadata
                            </Typography>
                        </Grid>
                        <Grid className={classes.table}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Fields
                                        </TableCell>
                                        <TableCell className={classes.fieldWidth}>
                                            Columns
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.keys(defaultFields).map((item, index) => {
                                            const property = item;
                                            return (
                                                <TableRow key={index} className={classes.rowHeight}>
                                                    <TableCell className={classes.fieldText}>
                                                        {item}
                                                    </TableCell>
                                                    <TableCell>
                                                        <AutoCompleteInput
                                                            name={`${index}DFields`}
                                                            popperWidth={300}
                                                            options={getRemainingOptions(defaultFields[property] ? defaultFields[property] : "")}
                                                            value={defaultFields[property] ? defaultFields[property] : ""}
                                                            renderInput={
                                                                (params) => <TextField {...params}
                                                                    className={classnames(classes.autocompleteWidth, classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                                                    placeholder="Fields" />
                                                            }
                                                            onChange={(_, selectedValue) => onFieldChange(property, selectedValue)}
                                                            capitalize
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                    {
                                        Object.keys(customFields).map((item, index) => {
                                            const property = item;
                                            return (
                                                <TableRow key={index} className={classes.rowHeight}>
                                                    <TableCell>
                                                        {item}
                                                    </TableCell>
                                                    <TableCell>
                                                        <AutoCompleteInput
                                                            name={`${index}CFields`}
                                                            popperWidth={300}
                                                            options={getRemainingOptions(customFields[property] ? customFields[property] : "")}
                                                            value={customFields[property] ? customFields[property] : ""}
                                                            renderInput={
                                                                (params) => <TextField {...params}
                                                                    className={classnames(classes.autocompleteWidth, classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                                                    placeholder="Fields" />
                                                            }
                                                            onChange={(_, selectedValue) => onCustomFieldChange(property, selectedValue)}
                                                            capitalize
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid className={classes.tableFooter}>
                            <Button disabled={isLoading} onClick={() => handleImport()} title="Import" variant="contained" color="primary" className={classes.importButton}>
                                {'Import'}
                                {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                            </Button>
                            <Button onClick={() => handleBack1()} title="Cancel" className={classnames(classes.cancelButton, classes.actionButtons)}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                show3 &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.maxHeight}>
                    <Grid className={classes.backIcon}>
                        <Button className={classnames(classes.block, classes.nopadding, classes.backButton, classes.bgNone)}
                            onClick={() => handleBack2()}>
                            <svg id="backArrow" xmlns="http://www.w3.org/2000/svg" width="8.335" height="13.909" viewBox="0 0 8.335 13.909" style={{ fill: '#6f777c', marginRight: "5px" }}>
                                <defs />
                                <g transform="translate(-11.172 0)">
                                    <path className="a" d="M11.564,5.967a1.359,1.359,0,0,1,.228-.181L17.183.395A1.347,1.347,0,0,1,19.088,2.3L14.446,6.943l4.666,4.667a1.347,1.347,0,1,1-1.9,1.905L11.792,8.1a1.4,1.4,0,0,1-.228-2.133Z" transform="translate(0 0)" />
                                </g>
                            </svg>
                            {'Back'}
                        </Button>
                    </Grid>
                    <Grid className={classes.backIcon}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Invalid Records
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    invalidRecords.map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {item}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid className={classes.tableFooter}>
                        <Button onClick={() => history.back()} title="Done" variant="contained" color="primary" className={classes.importButton}>
                            Done
                        </Button>
                    </Grid>
                </Grid>
            }
            {
                showAlert &&
                <AlertDialog
                    title="Import Metadata"
                    message={alertText}
                    okButtonText="OK"
                    show={showAlert}
                    onClickOK={() => close_alert(null)}
                    justifyCenter
                />

            }
        </Grid>
    );
};

DatasourceImport.propTypes = {
    classes: PropTypes.object,
    getPageTitle: PropTypes.func
};

export default withStyles((theme) => ({
    ...DatasourceimportStyles(theme),
    ...Styles(theme)
}))(DatasourceImport);