const DatasourceimportStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    datatype: {
        paddingTop: '30%',
        display: 'grid',
        justifyContent: 'center',
        alignContent: 'center',
        gap: '10px',
        gridAutoFlow: 'column'
    },
    dropzone: {
        paddingTop: '20px',
        height: '100%',
        border: '1px dashed'
    },
    tableHeader: {
        paddingTop: '20px'
    },
    tableRow: {
        padding: '1px'
    },
    dropzoneTop: {
        paddingTop: '25px'
    },
    droparea: {
        backgroundColor: theme.palette.common.white,
        paddingTop: 'calc(100vh - 90px)',
        border: `2px dashed ${theme.palette.border.light}`,
        borderRadius: 2,
        position: 'relative',
        outline: 'none'
    },
    dropareaContent: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: theme.palette.transparent
    },
    dropareaText: {
        margin: '10px 20px 20px'
    },
    dropareaImage: {
        height: 50
    },
    hintContainer: {
        position: 'absolute',
        bottom: 20,
        right: 20
    },
    hintText: {
        fontFamily: theme.palette.textFont,
        fontSize: 12,
        fontWeight: 500,
        color: theme.palette.grey.dark,
        lineHeight: 1.5
    },
    backIcon: {
        padding: '10px'
    },
    maxHeight: {
        maxHeight: '100%'
    },
    tablearea: {
        backgroundColor: theme.palette.common.white,
        position: 'relative',
        outline: 'none',
        height: 'calc(100vh - 90px)'
    },
    tableHeading: {
        padding: '25px',
        height: '8%'
    },
    table: {
        paddingLeft: '25px',
        height: '80%',
        overflow: 'auto'
    },
    tableFooter: {
        height: '12%',
        textAlign: 'right',
        padding: '30px'
    },
    importButton: {
        marginRight: '10px'
    },
    rowHeight: {
        height: '75px'
    },
    fieldWidth: {
        width: '70%'
    },
    autocompleteWidth: {
        width: '300px'
    },
    errorTable: {
        paddingTop: '10px',
        paddingLeft: '25px',
        height: '100%',
        overflow: 'auto'
    },
    fieldText: {
        textTransform: 'capitalize'
    }
});
export default DatasourceimportStyles;