import { dataPreparationActions } from '../constants/actionTypes/dataPreparationActionTypes';

const initialState = {
    isLoading: false,
    previewData: null
};

export const dataPreparationReducer = (state = initialState, action) => {
    if (!action || (action && !action.type)) {
        return state;
    }

    switch (action.type) {
        case dataPreparationActions.CLEAR_TRANSFORMATION:
            const previewData = state.previewData;
            if (!previewData) {
                return {
                    ...state
                };
            }
            if (action.isApplied) {
                return {
                    ...state
                };
            }
            const columnIndex = previewData.columns.findIndex((p) => action.transformation && p.name === action.transformation.output_attribute);
            if (columnIndex > -1) {
                previewData.columns.splice(columnIndex, 1);
            }
            return {
                ...state,
                previewData: { ...JSON.parse(JSON.stringify(previewData)) }
            };
        case dataPreparationActions.PREVIEW_TRANSFORMATION_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case dataPreparationActions.APPLY_TRANSFORMATION_SUCCESS:
        case dataPreparationActions.PREVIEW_TRANSFORMATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                previewData: { ...action.responseData.data }
            };
        case dataPreparationActions.PREVIEW_TRANSFORMATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                previewData: null
            };
        case dataPreparationActions.CREATE_DATA_PREPARATION_REQUEST:
        case dataPreparationActions.CREATE_DATA_PREPARATION_SUCCESS:
        case dataPreparationActions.CREATE_DATA_PREPARATION_FAILURE:
            return {
                ...state
            };
        default:
            return state;
    }
};