const config = {
    connectors: [
        { name: 'and', value: 'and' },
        { name: 'or', value: 'or' }
    ],
    Operators: [
        {
            label: 'is equal to',
            operator: '==',
            sqlOperator: '==',
            types: ['numeric', 'text', 'datetime'],
            valueRequired: true,
            isConditional: true,
            isComplexRule: true,
            isDefault: true,
            disableCase: true
        }, {
            label: 'is not equal to',
            operator: '!=',
            sqlOperator: '!=',
            types: ['numeric', 'text', 'datetime'],
            valueRequired: true,
            isConditional: true,
            isComplexRule: true,
            disableCase: true
        }, {
            label: 'is null',
            operator: 'is null',
            sqlOperator: 'is null',
            types: ['numeric', 'text', 'datetime'],
            valueRequired: false,
            isComplexRule: true,
            disableCase: true
        }, {
            label: 'is not null',
            operator: 'is not Null',
            sqlOperator: 'is not Null',
            types: ['numeric', 'text', 'datetime'],
            valueRequired: false,
            isComplexRule: true,
            disableCase: true
        }, {
            label: 'is empty',
            operator: "== ''",
            sqlOperator: "== ''",
            types: ['numeric', 'text', 'datetime'],
            valueRequired: false,
            isComplexRule: true,
            disableCase: true
        }, {
            label: 'is not empty',
            operator: "!= ''",
            sqlOperator: "!= ''",
            types: ['numeric', 'text', 'datetime'],
            valueRequired: false,
            isComplexRule: true,
            disableCase: true
        }, {
            label: 'contains',
            operator: "rlike '^.*value.*$'",
            sqlOperator: "like '%value%'",
            types: ['text'],
            valueRequired: true,
            isComplexRule: true,
            valueType: true,
            disableCase: false
        }, {
            label: 'length of character',
            operator: "length(regexp_replace(<field>, '[^<value>]', '')) <condition_opertaor> <count>",
            sqlOperator: "(len(cast(<field> as varchar(max))) - len(replace(cast(<field> as varchar(max)), substring(cast(<field> as varchar(max)), PATINDEX('%[<value>]%', cast(<field> as varchar(max))), 1), ''))) <condition_opertaor> <count>",
            types: ['text'],
            conditionOperator: 'is equal to',
            valueRequired: true,
            disableCase: false
        }, {
            label: 'starts with',
            operator: "rlike '^value.*$'",
            sqlOperator: "like 'value%'",
            types: ['text'],
            isComplexRule: true,
            valueRequired: true,
            valueType: true,
            disableCase: true
        }, {
            label: 'ends with',
            operator: "rlike '^.*value$'",
            sqlOperator: "like '%value'",
            types: ['text'],
            isComplexRule: true,
            valueRequired: true,
            valueType: true,
            disableCase: true
        }, {
            label: 'is greater than',
            operator: '>',
            sqlOperator: '>',
            types: ['numeric', 'datetime'],
            valueRequired: true,
            isConditional: true,
            isComplexRule: true,
            valueType: true,
            disableCase: true
        }, {
            label: 'is less than',
            operator: '<',
            sqlOperator: '<',
            types: ['numeric', 'datetime'],
            valueRequired: true,
            isConditional: true,
            isComplexRule: true,
            valueType: true,
            disableCase: true
        }, {
            label: 'is greater than or equal to',
            operator: '>=',
            sqlOperator: '>=',
            types: ['numeric', 'datetime'],
            valueRequired: true,
            isConditional: true,
            isComplexRule: true,
            valueType: true,
            disableCase: true
        }, {
            label: 'is less than or equal to',
            operator: '<=',
            sqlOperator: '<=',
            types: ['numeric', 'datetime'],
            valueRequired: true,
            isConditional: true,
            isComplexRule: true,
            valueType: true,
            disableCase: true
        }, {
            label: 'is between',
            operator: '{field}.format between {from} and {to}',
            sqlOperator: '{field}.format between {from} and {to}',
            types: ['numeric', 'datetime'],
            valueRequired: true,
            isComplexRule: true,
            valueType: true,
            range: true,
            disableCase: true
        },
        {
            label: 'matches',
            operator: "field.null_format rlike 'value'",
            sqlOperator: "field.null_format like 'value'",
            valueRequired: true,
            isConditional: true,
            disableCase: true,
            removeDateParser: true,
            types: ['numeric', 'text', 'datetime']
        },
        {
            label: 'not matches',
            operator: "field.null_format not rlike 'value'",
            sqlOperator: "field.null_format not like 'value'",
            valueRequired: true,
            isConditional: true,
            disableCase: true,
            removeDateParser: true,
            types: ['numeric', 'text', 'datetime']
        },
        {
            label: 'length is less than or equal to',
            operator: 'length(field.null_format)<=value',
            sqlOperator: 'len(field.null_format)<=value',
            types: ['numeric', 'text'],
            valueRequired: true,
            isConditional: true,
            isComplexRule: true,
            removeTypeParser: true,
            valueType: true,
            disableCase: true,
            removerFormatter: true,
            removeDateParser: true,
            chartType: 'Length',
            isChartOperation: true
        }, {
            label: 'length is greater than or equal to',
            operator: 'length(field.null_format)>=value',
            sqlOperator: 'len(field.null_format)>=value',
            types: ['numeric', 'text'],
            valueRequired: true,
            isConditional: true,
            isComplexRule: true,
            removeTypeParser: true,
            valueType: true,
            disableCase: true,
            removerFormatter: true,
            removeDateParser: true,
            chartType: 'Length',
            isChartOperation: true
        }, {
            label: 'length is equal to',
            operator: 'length(field.null_format)==value',
            sqlOperator: 'len(field.null_format)==value',
            types: ['numeric', 'text'],
            valueRequired: true,
            isConditional: true,
            isComplexRule: true,
            removeTypeParser: true,
            valueType: true,
            disableCase: true,
            removerFormatter: true,
            removeDateParser: true,
            chartType: 'Length',
            isChartOperation: true
        }, {
            label: 'length is not equal to',
            operator: 'length(field.null_format)!=value',
            sqlOperator: 'len(field.null_format)!=value',
            types: ['numeric', 'text'],
            valueRequired: true,
            isConditional: true,
            isComplexRule: true,
            removeTypeParser: true,
            valueType: true,
            disableCase: true,
            removerFormatter: true,
            removeDateParser: true,
            chartType: 'Length',
            isChartOperation: true
        }, {
            label: 'length is greater than',
            operator: 'length(field.null_format)>value',
            sqlOperator: 'len(field.null_format)>value',
            types: ['numeric', 'text'],
            valueRequired: true,
            isConditional: true,
            isComplexRule: true,
            removeTypeParser: true,
            valueType: true,
            disableCase: true,
            removerFormatter: true,
            removeDateParser: true,
            chartType: 'Length',
            isChartOperation: true
        }, {
            label: 'length is less than',
            operator: 'length(field.null_format)<value',
            sqlOperator: 'len(field.null_format)<value',
            types: ['numeric', 'text'],
            valueRequired: true,
            isConditional: true,
            isComplexRule: true,
            removeTypeParser: true,
            valueType: true,
            disableCase: true,
            removerFormatter: true,
            removeDateParser: true,
            chartType: 'Length',
            isChartOperation: true
        }
    ]
};

export default config;