import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Typography, withStyles, Paper } from '@material-ui/core';
import classNames from 'classnames';

import { selectConversationItem, createConversation, updateConversationList, updateNotificationRead } from '../../actions/notificationActions';
import ConversationUserCard from './ConversationUserCard.jsx';
import ConversationMessage from './ConversationMessage.jsx';
import ConversationTextBox from './ConversationTextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import ConversationStyles from './ConversationStyles.jsx';
// import SocketService from '../../services/socketService.js';
import { scrollConversation } from '../../helpers/appHelpers';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import Loader from '../../components/Loaders/Loader.jsx';
import Nomessages from '../../assets/images/NoConversation.svg';

const ConversationListDetail = (props) => {
    const { classes, conversations, selectedConversation, isLoading } = props;
    const dispatch = useDispatch();
    const users = useSelector(({ account }) => account.users);
    const user = useSelector(({ account }) => account.user);
    const [message, setMessage] = useState('');

    const onChangeMessage = useCallback((value) => {
        setMessage(value);
    }, []);

    const addMessage = useCallback(() => {
        const requestParams = {
            "sender_id": user.id,
            "receiver_id": selectedConversation.id,
            "message": message ? message.replace(/@\[(.*?)\]\(.*?\)/g, '').trim() : '',
            "dataset_id": selectedConversation.datasetId ? parseInt(selectedConversation.datasetId) : null,
            "source_id": selectedConversation.sourceId ? parseInt(selectedConversation.sourceId) : null,
            "domain_id": selectedConversation.domainId ? parseInt(selectedConversation.domainId) : null
        };
        setMessage('');
        dispatch(createConversation(requestParams));

        /*
         *  const socket = new SocketService();
         *  socket.sendMessage(requestParams);
         */

        dispatch(updateConversationList(users, requestParams, "sender", "detail"));
        scrollConversation();
    }, [dispatch, message, selectedConversation.datasetId, selectedConversation.domainId, selectedConversation.id, selectedConversation.sourceId, user.id, users]);

    const selectConversation = useCallback((conversation) => {
        conversation.messages = conversation.messages.sort((a, b) => b.created_date > a.created_date);
        dispatch(selectConversationItem({ ...conversation }));
        dispatch(updateNotificationRead({
            "receiver_id": user.id,
            "source_id": conversation.sourceId,
            "dataset_id": conversation.datasetId,
            "domain_id": conversation.domainId,
            "user_id": conversation.id
        }));
        scrollConversation();
    }, [dispatch, user.id]);

    return (
        <Grid container className={classes.marginTop15}>
            <Grid item xs={5} className={classNames(classes.conversationUserCard, classes.zIndex1, classes.relative)}>
                {isLoading && <Loader />}
                <Grid container>
                    <Typography component="h5" variant="h5">
                        Conversations
                    </Typography>
                    <Paper className={classes.panelNotification}>
                        {conversations.length}
                    </Paper>
                </Grid>
                <Grid container className={classes.marginTop15}>
                    <Grid className={classes.messageContainer}>
                        {
                            conversations.map((conversation, index) =>
                                <ConversationUserCard
                                    key={index}
                                    conversation={conversation}
                                    selectedConversation={selectedConversation}
                                    selectConversation={(conversation) => selectConversation(conversation)}
                                    userId={user.id} />
                            )
                        }
                        {
                            !isLoading && conversations.length === 0 &&
                            <Grid container className={classes.height100p} justify="center" alignItems="center" direction="column">
                                <img src={Nomessages} alt="No Conversation yet" />
                                <Typography variant="h4" color="textSecondary" className={classes.marginTop10}>
                                    No conversations yet
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={7} className={classNames(classes.conversationAreaSection, classes.zIndex1, classes.relative)}>
                {isLoading && <Loader />}
                {
                    Object.keys(selectedConversation).length !== 0 &&
                    <Grid className={classes.messageContainerRt} id="conversation">
                        {
                            selectedConversation.messages && selectedConversation.messages.map((message, index) =>
                                <ConversationMessage
                                    type="messageItems"
                                    key={index}
                                    message={message}
                                    userId={user.id}
                                />
                            )

                        }
                    </Grid>
                }
                {
                    Object.keys(selectedConversation).length !== 0 ?
                        <ConversationTextBox
                            addMessage={addMessage}
                            users={[]}
                            message={message}
                            onChange={(value) => onChangeMessage(value)}
                            messageType={"edit"} /> :
                        <NoResultFound />
                }

            </Grid>
        </Grid>
    );

};

ConversationListDetail.propTypes = {
    classes: PropTypes.object,
    conversations: PropTypes.array,
    selectedConversation: PropTypes.object,
    isLoading: PropTypes.bool
};

export default withStyles((theme) => ({
    ...ConversationStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ConversationListDetail);