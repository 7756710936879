export const connectorsActions = {
    CONNECT_FILES_REQUEST: 'CONNECT_FILES_REQUEST',
    CONNECT_FILES_SUCCESS: 'CONNECT_FILES_SUCCESS',
    CONNECT_FILES_FAILURE: 'CONNECT_FILES_FAILURE',

    GET_CONNECTED_FILES_REQUEST: 'GET_CONNECTED_FILES_REQUEST',
    GET_CONNECTED_FILES_SUCCESS: 'GET_CONNECTED_FILES_SUCCESS',
    GET_CONNECTED_FILES_FAILURE: 'GET_CONNECTED_FILES_FAILURE',

    CONNECT_DATASOURCE_REQUEST: 'CONNECT_DATASOURCE_REQUEST',
    CONNECT_DATASOURCE_SUCCESS: 'CONNECT_DATASOURCE_SUCCESS',
    CONNECT_DATASOURCE_FAILURE: 'CONNECT_DATASOURCE_FAILURE',

    CONNECT_DATASET_REQUEST: 'CONNECT_DATASET_REQUEST',
    CONNECT_DATASET_SUCCESS: 'CONNECT_DATASET_SUCCESS',
    CONNECT_DATASET_FAILURE: 'CONNECT_DATASET_FAILURE',

    GET_CONNECTED_DATASETS_REQUEST: 'GET_CONNECTED_DATASETS_REQUEST',
    GET_CONNECTED_DATASETS_SUCCESS: 'GET_CONNECTED_DATASETS_SUCCESS',
    GET_CONNECTED_DATASETS_FAILURE: 'GET_CONNECTED_DATASETS_FAILURE',

    GET_RESTAPI_CONNECTED_DATASETS_REQUEST: 'GET_RESTAPI_CONNECTED_DATASETS_REQUEST',
    GET_RESTAPI_CONNECTED_DATASETS_SUCCESS: 'GET_RESTAPI_CONNECTED_DATASETS_SUCCESS',
    GET_RESTAPI_CONNECTED_DATASETS_FAILURE: 'GET_RESTAPI_CONNECTED_DATASETS_FAILURE',

    CONNECT_RESTAPI_REQUEST: 'CONNECT_RESTAPI_REQUEST',
    CONNECT_RESTAPI_SUCCESS: 'CONNECT_RESTAPI_SUCCESS',
    CONNECT_RESTAPI_FAILURE: 'CONNECT_RESTAPI_FAILURE',

    CONNECT_DATASET_RESTAPI_REQUEST: 'CONNECT_DATASET_RESTAPI_REQUEST',
    CONNECT_DATASET_RESTAPI_SUCCESS: 'CONNECT_DATASET_RESTAPI_SUCCESS',
    CONNECT_DATASET_RESTAPI_FAILURE: 'CONNECT_DATASET_RESTAPI_FAILURE',

    CONNECT_UPLOADFILE_REQUEST: 'CONNECT_UPLOADFILE_REQUEST',
    CONNECT_UPLOADFILE_SUCCESS: 'CONNECT_UPLOADFILE_SUCCESS',
    CONNECT_UPLOADFILE_FAILURE: 'CONNECT_UPLOADFILE_FAILURE',

    GET_DATASET_SCHEMA_REQUEST: 'GET_DATASET_SCHEMA_REQUEST',
    GET_DATASET_SCHEMA_SUCCESS: 'GET_DATASET_SCHEMA_SUCCESS',
    GET_DATASET_SCHEMA_FAILURE: 'GET_DATASET_SCHEMA_FAILURE',

    UPDATE_DATASOURCE_PROPERTIES_REQUEST: 'UPDATE_DATASOURCE_PROPERTIES_REQUEST',
    UPDATE_DATASOURCE_PROPERTIES_SUCCESS: 'UPDATE_DATASOURCE_PROPERTIES_SUCCESS',
    UPDATE_DATASOURCE_PROPERTIES_FAILURE: 'UPDATE_DATASOURCE_PROPERTIES_FAILURE',

    GET_DATASET_CONNECTION_VIEWS_COUNT_REQUEST: 'GET_DATASET_CONNECTION_VIEWS_COUNT_REQUEST',
    GET_DATASET_CONNECTION_VIEWS_COUNT_SUCCESS: 'GET_DATASET_CONNECTION_VIEWS_COUNT_SUCCESS',
    GET_DATASET_CONNECTION_VIEWS_COUNT_FAILURE: 'GET_DATASET_CONNECTION_VIEWS_COUNT_FAILURE',

    GET_LIMITED_DATASETS_REQUEST: 'GET_LIMITED_DATASETS_REQUEST',
    GET_LIMITED_DATASETS_SUCCESS: 'GET_LIMITED_DATASETS_SUCCESS',
    GET_LIMITED_DATASETS_FAILURE: 'GET_LIMITED_DATASETS_FAILURE'

};