import React, { useState, useEffect, useCallback } from 'react';
import { Grid, withStyles, Button, TextField, MenuItem, InputAdornment, IconButton } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { useDispatch } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import Loader from '../Loaders/Loader.jsx';
import classnames from 'classnames';
import { createEnrichment, getEnrichmentDetail, updateEnrichment } from '../../actions/settingActions';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const EnrichmentDetail = (props) => {
    const { classes, organizationId, enrichmentId, onChangeEnrichment } = props;
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [isPageLoading, setPageLoading] = useState(false);
    const [enrichment, setEnrichment] = useState({ "organizationId": organizationId });
    const [urlParams, setUrlParam] = useState([]);
    const [queryParams, setQueryParam] = useState([]);
    const [resParams, setResParam] = useState([]);
    const [authType, setAuthType] = useState("");
    const [key, setKey] = useState("");
    const [value, setValue] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showValue, setShowValue] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const onChange = (name, value) => {
        if (name === "urlParams") {
            setUrlParam([...value]);
        } else if (name === "queryParams") {
            setQueryParam([...value]);
        } else if (name === "resParams") {
            setResParam([...value]);
        } else if (name === "key") {
            setKey(value);
        } else if (name === "value") {
            setValue(value);
        } else if (name === "username") {
            setUsername(value);
        } else if (name === "password") {
            setPassword(value);
        } else if (name === "authType") {
            setAuthType(value);
        } else {
            enrichment[name] = value;
            setEnrichment({ ...enrichment });
        }
    };

    const onSubmit = () => {
        setLoading(true);
        enrichment['url_parameters'] = urlParams.join();
        enrichment['query_parameters'] = queryParams.join();
        enrichment['response_parameters'] = resParams.join();

        const d = {};
        d.type = authType;
        if (authType === 'no_auth') {
            d.info = {};
        } else if (authType === 'api_key') {
            d.info = { key, value };
        } else if (authType === 'basic_auth') {
            d.info = { username, password };
        }
        enrichment.authorization = JSON.stringify(d);

        dispatch(enrichmentId === 0 ? createEnrichment(enrichment) : updateEnrichment(enrichment, enrichmentId)).then((response) => {
            setLoading(false);
            onChangeEnrichment('back');
        });
    };

    const readJson = (val) => {
        let d = '';
        try {
            d = JSON.parse(val);
        } catch (error) {
            d = val;
        }
        return d;
    };

    const getEnrichment = useCallback(() => {
        setPageLoading(true);
        dispatch(getEnrichmentDetail(enrichmentId)).then((response) => {
            if (response) {
                let uParam = response['url_parameters'].split(",");
                uParam = uParam.filter((x) => x.trim() !== '');
                if (uParam.length > 0) {
                    setUrlParam(uParam);
                }
                let qParam = response['query_parameters'].split(",");
                qParam = qParam.filter((x) => x.trim() !== '');
                if (qParam.length > 0) {
                    setQueryParam(qParam);
                }
                let rParam = response['response_parameters'].split(",");
                rParam = rParam.filter((x) => x.trim() !== '');
                if (rParam.length > 0) {
                    setResParam(rParam);
                }
                const d = readJson(response.authorization);
                setAuthType(d.type);
                if (d.type === 'api_key') {
                    setKey(d.info.key);
                    setValue(d.info.value);
                } else if (d.type === 'basic_auth') {
                    setUsername(d.info.username);
                    setPassword(d.info.password);
                }
                setEnrichment(response);
                setPageLoading(false);
            }
        });
    }, [dispatch, enrichmentId]);

    useEffect(() => {
        if (enrichmentId !== 0) {
            getEnrichment();
        } else {
            setAuthType('no_auth');
        }
    }, [enrichmentId, getEnrichment]);

    const validURL = (str) => {
        // protocol
        const pattern = new RegExp('^(https?:\\/\\/)?' +
            // domain name
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
            // OR ip (v4) address
            '((\\d{1,3}\\.){3}\\d{1,3}))' +
            // port and path
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
            // query string
            '(\\?[;&a-z\\d%_.~+=-]*)?' +
            // fragment locator
            '(\\#[-a-z\\d_]*)?$', 'i');
        return Boolean(pattern.test(str));
    };

    useEffect(() => {
        ValidatorForm.addValidationRule('isValidUrl', (value) => {
            if (validURL(value)) {
                return true;
            }
            return false;
        });
        return () => {
            ValidatorForm.removeValidationRule('isValidUrl');
        };
    }, []);

    const validNumber = (str) => {
        const pattern = new RegExp('^[0-9]+$');
        return Boolean(pattern.test(str));
    };

    useEffect(() => {
        ValidatorForm.addValidationRule('isValidNumber', (value) => {
            if (validNumber(value)) {
                return true;
            }
            return false;
        });
        return () => {
            ValidatorForm.removeValidationRule('isValidNumber');
        };
    }, []);

    const validName = (str) => {
        return (str !== 'Deduplicate');
    };

    useEffect(() => {
        ValidatorForm.addValidationRule('isValidName', (value) => {
            if (validName(value)) {
                return true;
            }
            return false;
        });
        return () => {
            ValidatorForm.removeValidationRule('isValidName');
        };
    }, []);

    return (
        <ValidatorForm className={classes.container} form="Enrichment" onSubmit={() => onSubmit()}>
            <Grid container className={classes.enrichmentDetailContainer}>
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={3}>
                            <TextBox
                                label="Name"
                                name="name"
                                value={enrichment.name ? enrichment.name : ''}
                                fullWidth
                                validators={['required', 'isValidName']}
                                errorMessages={['Enrichment Name is required', 'Enrichment Name is invalid']}
                                onChange={(event) => onChange(event.target.name, event.target.value)}

                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextBox
                                label="Endpoint"
                                name="endpoint"
                                value={enrichment.endpoint ? enrichment.endpoint : ''}
                                fullWidth
                                validators={['required', 'isValidUrl']}
                                errorMessages={['URL Endpoint is required', 'URL Endpoint is invalid']}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AutoCompleteInput
                                name="urlParams"
                                options={[]}
                                freeSolo
                                value={urlParams}
                                renderInput={
                                    (params) =>
                                        <TextField {...params}
                                            className={classnames(classes.autoCompleteInput)}
                                            label="URL Parameters"
                                            fullWidth
                                        />
                                }
                                onChange={(_, selectedValue) => onChange("urlParams", selectedValue)}
                                fullWidth
                                multiple
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AutoCompleteInput
                                name="queryParams"
                                options={[]}
                                freeSolo
                                value={queryParams}
                                renderInput={
                                    (params) =>
                                        <TextField {...params}
                                            className={classnames(classes.autoCompleteInput)}
                                            label="Query Parameters"
                                            fullWidth
                                        />
                                }
                                onChange={(_, selectedValue) => onChange("queryParams", selectedValue)}
                                fullWidth
                                multiple
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AutoCompleteInput
                                name="resParams"
                                options={[]}
                                freeSolo
                                value={resParams}
                                renderInput={
                                    (params) =>
                                        <TextField {...params}
                                            className={classnames(classes.autoCompleteInput)}
                                            label="Response Parameters"
                                            fullWidth
                                        />
                                }
                                onChange={(_, selectedValue) => onChange("resParams", selectedValue)}
                                fullWidth
                                multiple
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={4}>
                                <Grid item xs={6}>
                                    <TextBox
                                        name="authType"
                                        value={authType}
                                        label="Authorization"
                                        onChange={(event) => onChange(event.target.name, event.target.value)}
                                        fullWidth
                                        select
                                        SelectProps={
                                            {
                                                value: authType,
                                                MenuProps: {
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "center"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "center"
                                                    },
                                                    getContentAnchorEl: null
                                                }
                                                // IconComponent: () => DropDownIcon()
                                            }
                                        }
                                    >
                                        <MenuItem value="api_key">
                                            Api Key
                                        </MenuItem>
                                        <MenuItem value="basic_auth">
                                            Basic Auth
                                        </MenuItem>
                                        <MenuItem selected value="no_auth">
                                            No Auth
                                        </MenuItem>
                                    </TextBox>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container>
                                        <Grid item xs={2}>
                                            <TextBox
                                                name="throttle_limit"
                                                label="Throttle Limit"
                                                value={enrichment.throttle_limit ? enrichment.throttle_limit : 1000}
                                                fullWidth
                                                validators={['required', 'isValidNumber']}
                                                errorMessages={['Throttle Limit is required', 'Throttle Limit is invalid']}
                                                onChange={(event) => onChange(event.target.name, event.target.value)}

                                            />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <TextBox
                                                name="throttle_time_unit"
                                                value={enrichment.throttle_time_unit}
                                                label="Throttle Time Unit"
                                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                                fullWidth
                                                select
                                                SelectProps={
                                                    {
                                                        value: enrichment.throttle_time_unit ? enrichment.throttle_time_unit : 'm',
                                                        MenuProps: {
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "center"
                                                            },
                                                            transformOrigin: {
                                                                vertical: "top",
                                                                horizontal: "center"
                                                            },
                                                            getContentAnchorEl: null
                                                        }
                                                        // IconComponent: () => DropDownIcon()
                                                    }
                                                }
                                            >
                                                <MenuItem value="h">
                                                    Per Hour
                                                </MenuItem>
                                                <MenuItem selected value="m">
                                                    Per Minute
                                                </MenuItem>
                                                <MenuItem value="s">
                                                    Per Second
                                                </MenuItem>
                                            </TextBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {
                            authType === "api_key" &&
                            <Grid container xs={12} direction="row" className={classnames(classes.subModuleSection, classes.authorizationGrid)}>
                                {/* <Grid item xs={12}>
                                    <Typography component="h6" variant="h6" className={classes.moduleTitle}>
                                        API Key
                                    </Typography>
                                </Grid> */}
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <TextBox
                                            label="Key"
                                            name="key"
                                            value={key}
                                            fullWidth
                                            validators={['required']}
                                            errorMessages={['Key is required']}
                                            onChange={(event) => onChange(event.target.name, event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextBox
                                            label="Value"
                                            name="value"
                                            value={value}
                                            fullWidth
                                            validators={['required']}
                                            errorMessages={['Value is required']}
                                            onChange={(event) => onChange(event.target.name, event.target.value)}
                                            type={showValue ? 'text' : 'password'}
                                            InputProps={
                                                {
                                                    endAdornment:
                                                        <InputAdornment position="end" >
                                                            <IconButton className={classes.passwordAdornment} onClick={() => ((showValue) ? setShowValue(false) : setShowValue(true))}>
                                                                {
                                                                     (!showValue) ? <ToolTipComponent title="Show" arrow>
                                                                                        <Visibility />
                                                                                    </ToolTipComponent> :
                                                                                    <ToolTipComponent title="Hide" arrow>
                                                                                        <VisibilityOffOutlinedIcon />
                                                                                    </ToolTipComponent>
                                                                }
                                                            </IconButton>
                                                        </InputAdornment>
                                                }
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {
                            authType === "basic_auth" &&
                            <Grid container xs={12} direction="row" className={classnames(classes.subModuleSection, classes.authorizationGrid)}>
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <TextBox
                                            label="User Name"
                                            name="username"
                                            value={username}
                                            fullWidth
                                            validators={['required']}
                                            errorMessages={['User Name is required']}
                                            onChange={(event) => onChange(event.target.name, event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextBox
                                            label="Password"
                                            name="password"
                                            value={password}
                                            fullWidth
                                            validators={['required']}
                                            errorMessages={['Password is required']}
                                            onChange={(event) => onChange(event.target.name, event.target.value)}
                                            type={showPassword ? 'text' : 'password'}
                                            InputProps={
                                                {
                                                    endAdornment:
                                                        <InputAdornment position="end" >
                                                            <IconButton className={classes.passwordAdornment} onClick={() => ((showPassword) ? setShowPassword(false) : setShowPassword(true))}>
                                                                {(!showPassword) ? <Visibility /> : <VisibilityOffOutlinedIcon />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                }
                                            }
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classnames(classes.actionButtons, classes.submitBtn)}>
                                Save
                                {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                            </Button>
                            <Button onClick={() => onChangeEnrichment('back')} className={classnames(classes.cancelButton, classes.actionButtons)}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {isPageLoading && <Loader />}
        </ValidatorForm>
    );
};

EnrichmentDetail.propTypes = {
    classes: PropTypes.object,
    organizationId: PropTypes.number,
    enrichmentId: PropTypes.number,
    onChangeEnrichment: PropTypes.func
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}))(EnrichmentDetail);