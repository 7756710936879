import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Button, Paper, IconButton, Avatar } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CloseIcon from '@material-ui/icons/Close';

import { appConstants } from '../../constants/appConstants';
import ConversationTextBox from './ConversationTextBox.jsx';
import ConversationListItems from './ConversationListItems.jsx';
import ConversationMessageList from './ConversationMessageList.jsx';
import { createConversation, getConversationBySource, groupListConversation, selectConversationItem, updateConversationList, toggleSidePanel, updateNotificationRead } from '../../actions/notificationActions';
import { getRandomColor, getInitials, returnImage, scrollConversation } from '../../helpers/appHelpers';
// import SocketService from '../../services/socketService.js';
import Styles from '../../layouts/Styles.jsx';
import ActionItemStyles from '../ActionItem/ActionItemStyles.jsx';
import ConversationStyles from './ConversationStyles.jsx';

const ConversationList = (props) => {
    const { classes, history } = props;
    const dispatch = useDispatch();
    const [detailView, setDetailView] = useState(false);
    const [title, setTitle] = useState('Conversations');

    const users = useSelector(({ account }) => account.users);
    const user = useSelector(({ account }) => account.user);
    const [userList, setUserList] = useState([]);
    const [message, setMessage] = useState('');
    const [selectedUser, setSelectedUser] = useState({});
    const datasetId = useSelector(({ dataset }) => dataset.selectedDatasetId);
    const datasourceId = useSelector(({ datasource }) => datasource.selectedDatasourceId);
    const domainId = useSelector(({ domain }) => domain.selectedDomainId);
    const conversations = useSelector(({ notification }) => notification.conversations);
    const selectedConversation = useSelector(({ notification }) => notification.conversation);
    const datasetSharing = useSelector(({ dataset }) => dataset.sharingInfo);
    const datasourceSharing = useSelector(({ datasource }) => datasource.sharingInfo);
    const domainSharing = useSelector(({ datasource }) => datasource.domainSharingInfo);
    const [isLoading, setIsLoading] = useState(true);

    const goBack = useCallback(() => {
        setDetailView(false);
        setTitle('Conversations');
    }, []);

    const viewAll = useCallback(() => {
        history.push('/conversation');
        dispatch(toggleSidePanel({ panel: false, type: '' }));
    }, [dispatch, history]);

    const hidePanel = useCallback(() => {
        dispatch(toggleSidePanel({ panel: false, type: '' }));
    }, [dispatch]);

    const setUsers = useCallback(() => {
        let sharingInfo = datasourceSharing;
        if (window.location.href.includes('dataset')) {
            sharingInfo = datasetSharing;
        } else if (window.location.href.includes('domain')) {
            sharingInfo = domainSharing;
        }
        sharingInfo = sharingInfo.map((info) => info.user_id);
        const userList = users.filter((item) => sharingInfo.includes(item.id) && item.id !== user.id).map((user) => { return { ...user, display: user.first_name !== "" ? `${user.first_name} ${user.last_name}` : user.email, isSelected: false }; });
        setUserList([...userList]);
    }, [datasetSharing, datasourceSharing, domainSharing, user.id, users]);

    const getConversation = useCallback(() => {
        const userId = user.id;
        dispatch(getConversationBySource(window.location.href.includes('/domain') ? domainId : datasourceId, window.location.href.includes('/dataset/') ? datasetId : 0, window.location.href.includes('/domain') ? "domain" : "datasource")).then((response) => {
            if (response) {
                dispatch(groupListConversation(response, users, userId));
            }
            setIsLoading(false);
        });
    }, [datasetId, datasourceId, dispatch, domainId, user.id, users]);


    const updateNewConversation = useCallback((data, type) => {
        dispatch(updateConversationList(users, data, type, "list"));
        setMessage('');
    }, [dispatch, users]);

    useEffect(() => {
        setUsers();
        getConversation();
    }, [getConversation, setUsers]);

    const onChangeMessage = useCallback((message) => {
        setMessage(message);
    }, []);

    const assignUser = useCallback((user) => {
        const userItems = userList.map((item) => { return { ...item, isSelected: item.id === parseInt(user[0].user) }; });
        const userData = userList.find((item) => item.id === parseInt(user[0].user));
        setSelectedUser({ ...userData ? userData : {} });
        setUserList([...userItems]);
    }, [userList]);

    const addMessage = useCallback(() => {
        if (!detailView ? selectedUser.id : selectedConversation.id) {
            const requestParams = {
                "sender_id": parseInt(user.id),
                "receiver_id": !detailView ? selectedUser.id : selectedConversation.id,
                "message": message ? message.replace(/@\[(.*?)\]\(.*?\)/g, '').trim() : '',
                "dataset_id": window.location.href.includes('dataset') ? parseInt(datasetId) : null,
                "source_id": window.location.href.includes('domain') ? null : parseInt(datasourceId),
                "domain_id": window.location.href.includes('domain') ? domainId : null
            };

            dispatch(createConversation(requestParams));

            /*
             * const socket = new SocketService();
             * socket.sendMessage(requestParams);
             */

            updateNewConversation(requestParams, "sender");
        }
    }, [datasetId, datasourceId, detailView, dispatch, domainId, message, selectedConversation.id, selectedUser.id, updateNewConversation, user.id]);


    const selectConversation = useCallback((conversation) => {
        setDetailView(true);
        dispatch(selectConversationItem({ ...conversation }));
        dispatch(updateNotificationRead({
            "receiver_id": user.id,
            "source_id": conversation.sourceId,
            "dataset_id": conversation.datasetId,
            "user_id": conversation.id,
            "domain_id": conversation.domainId
        }));
        setTimeout(() => {
            scrollConversation();
        }, 0);
    }, [dispatch, user.id]);

    return (
        <Grid style={{ position: 'relative' }}>
            <Grid container justify="space-between" alignItems="center" className={classes.sidePanelHeader}>
                <Grid item style={{ display: 'flex' }}>
                    {
                        detailView &&
                        <IconButton className={classes.nopadding} onClick={() => goBack()}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    }

                    {
                        !detailView &&
                        <Typography component="h5" variant="h5" style={{ marginLeft: detailView ? "5px" : "0px" }} className={detailView ? classes.actionTitleTxt : ''} >
                            {title !== "" ? title : ''}
                        </Typography>
                    }
                    {
                        detailView &&
                        <Grid className={classes.titleSection}>
                            <Avatar
                                className={classes.marginLeft10}
                                style={
                                    {
                                        backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, selectedConversation.avatar)})`,
                                        backgroundColor: getRandomColor(selectedConversation.first_name !== "" ? `${selectedConversation.first_name} ${selectedConversation.last_name}` : selectedConversation.email)
                                    }
                                }>
                                {
                                    !selectedConversation.avatar &&
                                    <span>
                                        {getInitials(selectedConversation.first_name !== "" ? `${selectedConversation.first_name} ${selectedConversation.last_name}` : selectedConversation.email)}
                                    </span>
                                }
                            </Avatar>
                            <Typography component="h5" variant="h5" className={classes.marginLeft10}>
                                {selectedConversation.first_name !== "" ? `${selectedConversation.first_name} ${selectedConversation.last_name}` : selectedConversation.email}
                            </Typography>
                        </Grid>
                    }
                    {
                        !detailView &&
                        <Paper className={classes.panelNotification}>
                            {conversations.length}
                        </Paper>
                    }
                </Grid>
                <Grid item>
                    {
                        !detailView &&
                        <Button variant="text" onClick={() => viewAll()}>
                            View All
                        </Button>
                    }
                    <IconButton className={classes.nopadding} onClick={() => hidePanel()}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid>
                <Grid className={classes.listSection} id="conversation">
                    {
                        !detailView ?
                            <ConversationListItems isLoading={isLoading} userId={user.id} conversations={conversations} selectConversation={(conversation) => selectConversation(conversation)} />
                            :
                            <ConversationMessageList conversation={selectedConversation} />
                    }
                </Grid>
                <ConversationTextBox
                    addMessage={addMessage}
                    users={userList ? userList : []}
                    message={message}
                    onChange={(value) => onChangeMessage(value)}
                    messageType={!detailView ? "new" : "edit"}
                    assignUser={(user) => assignUser(user)}
                />
            </Grid>

        </Grid>
    );

};

ConversationList.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object
};


export default withStyles((theme) => ({
    ...ConversationStyles(theme),
    ...ActionItemStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ConversationList);