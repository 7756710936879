import React, { useEffect, useCallback, useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import ChartStyles from './ChartStyles.jsx';
import { appConstants } from '../../constants/appConstants';
import { createRule, getFieldType } from '../RuleBuilder/QueryBuilderUtil.jsx';
import { useSelector } from 'react-redux';
import ProfileChartFilterOperators from '../../constants/chartOperators.js';

const SpliderChart = (props) => {
    const { attribute, profileData, classes, theme, name, id, tabIndex, onFilter, profileRuleEnable, isActive, attributeChange } = props;
    const datasource = useSelector(({ datasource }) => datasource.datasource);
    const [chartData, setChartData] = useState({});
    const [cases, setCases] = useState([]);

    const loadChartData = useCallback((chartData) => {
        setChartData({ ...chartData });
        const options = [];
        for (const caseValue of chartData.input_params) {
            options.push({
                name: caseValue[1],
                value: caseValue[0]
            });
        }
        const patterns = chartData.input_params.map((p) => `(${p[0]})`);
        options.push({
            name: 'Others',
            value: patterns.join('|')
        });
        setCases([...options]);
    }, []);

    useEffect(() => {
        if (attributeChange) {
            const chartData = profileData['Case Sensitivity'];
            loadChartData({ ...chartData });
        }
    }, [attribute, attributeChange, loadChartData, profileData]);


    const getPattern = useCallback((selectedCase) => {
        const selectedPattern = cases.find((p) => p.name.toLowerCase() === selectedCase.toLowerCase());
        return selectedPattern;
    }, [cases]);

    const onFilterChange = useCallback((inputValue) => {
        if (typeof (inputValue) === "string") {
            inputValue = {
                area: getPattern(inputValue),
                value: (chartData?.frequency_count[inputValue] / chartData?.total_records_processed) * 100,
                data: chartData?.frequency_count[inputValue]
            };
        }
        const inputParams = {
            chartType: appConstants.charts.casesensitivity.type,
            chartName: appConstants.charts.casesensitivity.name,
            filterOptions: false,
            defaultQuery: ''
        };


        if (inputValue) {
            inputParams.data = inputValue;
            const ruleParams = {
                defaultField: attribute.name,
                defaultFieldType: getFieldType(attribute.type),
                attributeType: attribute.type,
                connectionType: datasource?.type,
                isScan: datasource?.scan
            };
            const rule = createRule(ruleParams);
            const operators = ProfileChartFilterOperators.CaseSensitivity;
            rule.operator = { ...operators[0] };
            rule.value = inputValue.area;
            rule.options = [...cases];
            inputParams.rule = rule;
            inputParams.filterType = appConstants.QueryFilterTypes[2];
        }
        onFilter(inputParams);
    }, [attribute.name, attribute.type, cases, chartData, datasource?.scan, datasource?.type, getPattern, onFilter]);


    useEffect(() => {
        if (chartData && Object.keys(chartData).length !== 0 && attributeChange) {
            const spiderChartData = [];
            const keys = chartData.frequency_count ? Object.keys(chartData.frequency_count) : [];
            for (const data of chartData.input_params) {
                const index = keys.indexOf(data[1]);
                spiderChartData.push({
                    area: data[1],
                    value: index !== -1 ? (chartData.frequency_count[keys[index]]) : 0,
                    data: index !== -1 ? chartData.frequency_count[keys[index]] : 0
                });
            }

            spiderChartData.push({ area: 'others', value: chartData.frequency_count.others ? chartData.frequency_count.others : 0, data: chartData.frequency_count.others ? chartData.frequency_count.others : 0 });
            const config = {
                radius: 5,
                w: 300,
                h: 290,
                factor: 1,
                factorLegend: 0.85,
                levels: 5,
                radians: 2 * Math.PI,
                opacityArea: 0.5,
                ToRight: 5,
                TranslateX: 80,
                TranslateY: 30,
                ExtraWidthX: 160,
                ExtraWidthY: 100,
                color: d3.scaleOrdinal().range([theme.palette.chartColors.valid, theme.palette.chartColors.valid]),
                maxValue: chartData.total_records_processed
            };

            const allAxis = (spiderChartData.map((i, j) => { return i.area; }));
            const total = allAxis.length;
            const radius = config.factor * Math.min(config.w / 2, config.h / 2);
            d3.select(".spider-chart > *").remove();

            const svg = d3.select(".spider-chart")
                .append("svg")
                .attr("width", config.w + config.ExtraWidthX)
                .attr("height", config.h + config.ExtraWidthY)
                .append("g")
                .attr("transform", "translate(" + config.TranslateX + "," + config.TranslateY + ")");

            for (let index = 0; index < config.levels; index++) {
                const levelFactor = config.factor * radius * ((index + 1) / config.levels);
                svg.selectAll(".levels")
                    .data(allAxis)
                    .enter()
                    .append("line")
                    .attr("x1", (d, i) => { return levelFactor * (1 - config.factor * Math.sin(i * config.radians / total)); })
                    .attr("y1", (d, i) => { return levelFactor * (1 - config.factor * Math.cos(i * config.radians / total)); })
                    .attr("x2", (d, i) => { return levelFactor * (1 - config.factor * Math.sin((i + 1) * config.radians / total)); })
                    .attr("y2", (d, i) => { return levelFactor * (1 - config.factor * Math.cos((i + 1) * config.radians / total)); })
                    .attr("class", "line")
                    .style("stroke", "grey")
                    .style("stroke-opacity", "0.75")
                    .style("stroke-width", "0.3px")
                    .attr("transform", "translate(" + (config.w / 2 - levelFactor) + ", " + (config.h / 2 - levelFactor) + ")");
            }


            let series = 0;
            const axis = svg.selectAll(".axis")
                .data(allAxis)
                .enter()
                .append("g")
                .attr("class", "axis");
            axis.append("line")
                .attr("x1", config.w / 2)
                .attr("y1", config.h / 2)
                .attr("x2", (d, i) => { return config.w / 2 * (1 - config.factor * Math.sin(i * config.radians / total)); })
                .attr("y2", (d, i) => { return config.h / 2 * (1 - config.factor * Math.cos(i * config.radians / total)); })
                .attr("class", "line")
                .style("stroke", "grey")
                .style("stroke-opacity", "0.75")
                .style("stroke-width", "0.3px");
            axis.append("text")
                .attr("class", "spider-label")
                .text((d) => { return d; })
                .attr("text-anchor", "middle")
                .attr("dy", "1.5em")
                .style("cursor", "pointer")
                .attr("transform", (d, i) => { return "translate(0, -10)"; })
                .attr("x", (d, i) => { return config.w / 2 * (1 - config.factorLegend * Math.sin(i * config.radians / total)) - 60 * Math.sin(i * config.radians / total); })
                .attr("y", (d, i) => { return config.h / 2 * (1 - Math.cos(i * config.radians / total)) - 20 * Math.cos(i * config.radians / total); })
                .on('mouseover', (d, i, nodes) => {
                    const circle = d3.select(nodes[i]);
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html(`Name : ${d} <br/> Values: ${chartData.frequency_count[d] ? chartData.frequency_count[d] : 0}`)
                        .style("left", circle.attr('x') + "px")
                        .style("top", (circle.attr('y') - 50) + "px");
                    d3.select(nodes[i]).style('fill', theme.palette.chartColors.valid);
                    const z = "polygon." + d3.select(nodes[i]).attr("class");
                    svg.selectAll("polygon")
                        .transition(200)
                        .style("fill-opacity", 0.1);
                    svg.selectAll(z)
                        .transition(200)
                        .style("fill-opacity", 0.7);
                })
                .on('mouseout', (_, i, nodes) => {
                    div
                        .transition(200)
                        .style('opacity', 0);
                    svg.selectAll("polygon")
                        .transition(200)
                        .style("fill-opacity", config.opacityArea);
                    d3.select(nodes[i]).style('fill', '#1d2c2f');
                })
                .on('click', (d) => {
                    onFilterChange(d);
                });
            let dataValues = [];
            dataValues = [];
            svg.selectAll(".nodes")
                .data(spiderChartData, (j, i) => {
                    dataValues.push([
                        config.w / 2 * (1 - (parseFloat(Math.max(j.value, 0)) / config.maxValue) * config.factor * Math.sin(i * config.radians / total)),
                        config.h / 2 * (1 - (parseFloat(Math.max(j.value, 0)) / config.maxValue) * config.factor * Math.cos(i * config.radians / total))
                    ]);
                });

            svg.selectAll(".area")
                .data([dataValues])
                .enter()
                .append("polygon")
                .attr("class", "radar-chart-series" + series)
                .style("stroke-width", "2px")
                .style("stroke", config.color(series))
                .attr("points", (d) => {
                    let str = "";
                    for (let point = 0; point < spiderChartData.length; point++) {
                        str = str + d[point][0] + "," + d[point][1] + " ";
                    }
                    return str;
                })
                .style("fill", (j, i) => { return config.color(series); })
                .style("fill-opacity", config.opacityArea)
                .on('mouseover', (d, i, p) => {
                    const z = "polygon." + d3.select(p[i]).attr("class");
                    svg.selectAll("polygon")
                        .transition(200)
                        .style("fill-opacity", 0.1);
                    svg.selectAll(z)
                        .transition(200)
                        .style("fill-opacity", 0.7);
                })
                .on('mouseout', () => {
                    svg.selectAll("polygon")
                        .transition(200)
                        .style("fill-opacity", config.opacityArea);
                });

            d3.select(".spider-chart > .tooltip").remove();
            const div = d3.select(".spider-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            svg.selectAll(".nodes")
                .data(spiderChartData).enter()
                .append("circle")
                .attr("class", "radar-chart-series" + series)
                .style("opactiy", 0)
                .attr('r', config.radius)
                .attr("alt", (j) => { return Math.max(j.value, 0); })
                .attr("cx", (j, i) => {
                    series++;
                    dataValues.push([
                        config.w / 2 * (1 - (parseFloat(Math.max(j.value, 0)) / config.maxValue) * config.factor * Math.sin(i * config.radians / total)),
                        config.h / 2 * (1 - (parseFloat(Math.max(j.value, 0)) / config.maxValue) * config.factor * Math.cos(i * config.radians / total))
                    ]);
                    return config.w / 2 * (1 - (Math.max(j.value, 0) / config.maxValue) * config.factor * Math.sin(i * config.radians / total));
                })
                .attr("cy", (j, i) => {
                    return config.h / 2 * (1 - (Math.max(j.value, 0) / config.maxValue) * config.factor * Math.cos(i * config.radians / total));
                })
                .attr("data-id", (j) => { return j.area; })
                .style("fill", "#fff")
                .style("stroke-width", "2px")
                .style("stroke-opacity", 0)
                .style("stroke", "transparent").style("fill-opacity", 0)
                .on('mouseover', (d, i, nodes) => {
                    const circle = d3.select(nodes[i]);
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html(`Name : ${d.area} <br/> Values: ${d.data}`)
                        .style("left", circle.attr("cx") + "px")
                        .style("top", (circle.attr("cy") - 50) + "px");
                    const z = "polygon." + d3.select(nodes[i]).attr("class");
                    svg.selectAll("polygon")
                        .transition(200)
                        .style("fill-opacity", 0.1);
                    svg.selectAll(z)
                        .transition(200)
                        .style("fill-opacity", 0.7);
                })
                .on('mouseout', () => {
                    div
                        .transition(200)
                        .style('opacity', 0);
                    svg.selectAll("polygon")
                        .transition(200)
                        .style("fill-opacity", config.opacityArea);
                })
                .on('click', (d) => {
                    onFilterChange(d);
                });

        }
    }, [attributeChange, chartData, onFilterChange, tabIndex, theme.palette.chartColors.valid]);

    const onRuleChange = (value) => {
        profileRuleEnable(appConstants.charts.casesensitivity.type, value);
    };

    return (
        <ChartContainer
            id={id}
            title={name}
            chartData={chartData}
            filterProperties={
                {
                    enableFilter: false,
                    onFilter: onFilterChange
                }
            }
            ruleProperties={
                {
                    edit: true,
                    onRule: onRuleChange,
                    rule: isActive
                }
            }
        >
            <Grid container className={classNames(classes.chartStyles, classes.spiderchart, "spider-chart")} />
        </ChartContainer >
    );
};

SpliderChart.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    name: PropTypes.string,
    id: PropTypes.string,
    attribute: PropTypes.object,
    profileData: PropTypes.object,
    tabIndex: PropTypes.number,
    onFilter: PropTypes.func,
    profileRuleEnable: PropTypes.func,
    isActive: PropTypes.bool,
    attributeChange: PropTypes.bool
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SpliderChart);