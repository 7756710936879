import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Table, TableRow, TableHead, TableCell, TableBody } from '@material-ui/core';
import classNames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import { getDriftReport } from '../../actions/notificationActions';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../Loaders/Loader.jsx';
import MasterStyles from '../Master/MasterStyles.jsx';
import DriftReportAccordian from './DriftReportAccordian.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';


const DriftReportComponent = (props) => {
    const { classes, history } = props;
    const filters = useSelector(({ notification }) => notification.driftFilters);
    const dispatch = useDispatch();
    const [columns, setColumns] = useState([]);
    const [driftReport, setReport] = useState([]);
    const [isLoading, setLoading] = useState(true);


    const extractChildReport = (type, reports, expandDetail, key, extractData = []) => {
        for (const report of reports) {
            if ((type === "attribute" && report.dataset_id === expandDetail.dataset_id) || (type === "dataset" && ((report.source_id === expandDetail.source_id && filters.group_by !== "attribute") || (report.source_id === expandDetail.source_id && expandDetail.attribute_rule === report.attribute_rule && filters.group_by === "attribute")))) {
                if (key === "isOpen") {
                    report.isOpen = Boolean(!expandDetail.isOpen);
                } else {
                    if (filters.group_by === "attribute") {
                        extractData = extractData.map((data) => {
                            return {
                                ...data,
                                "attribute_rule": report.attribute_rule ? report.attribute_rule : report.attribute_name
                            };
                        });
                    }
                    report.isOpen = Boolean(!expandDetail.isOpen);
                    report.children = [...extractData];
                }
                break;
            }
            if (report.children) {
                extractChildReport(type, report.children, expandDetail, key, extractData);
            }
        }
    };

    const extractReport = (type, expandDetail, key, extractData = []) => {
        for (const report of driftReport) {
            if ((type === "datasource" && report.attribute_name === expandDetail.attribute_name) || (type === "dataset" && report.source_id === expandDetail.source_id) || (type === "attribute" && report.dataset_id === expandDetail.dataset_id)) {
                if (key === "isOpen") {
                    report.isOpen = Boolean(!expandDetail.isOpen);
                } else {
                    if (filters.group_by === "attribute") {
                        extractData = extractData.map((data) => {
                            return {
                                ...data,
                                "attribute_rule": report.attribute_rule ? report.attribute_rule : report.attribute_name
                            };
                        });
                    }
                    report.isOpen = Boolean(!expandDetail.isOpen);
                    report.children = [...extractData];
                }
                break;
            }

            if (report.children) {
                extractChildReport(type, report.children, expandDetail, key, extractData);
            }
        }
        setReport([...driftReport]);
    };

    const getExtractReport = (type, expandInfo) => {
        let groupBy = "dataset";
        if (expandInfo.type === "dataset") {
            groupBy = "attribute";
        } else if (expandInfo.type === "attribute") {
            groupBy = "datasource";
        }
        const requestParams = {
            'filter_count': 5,
            'range': filters.range,
            'group_by': groupBy,
            'datasets': filters.datasets && filters.datasets.some((data) => data.name === "All") ? [] : filters.datasets.filter((data) => data.isSelected).map((data) => data.id),
            'datasources': filters.datasources && filters.datasources.some((data) => data.name === "All") ? [] : filters.datasources.filter((data) => data.isSelected).map((data) => data.id),
            'attributes': filters.attributes && filters.attributes.some((data) => data.name === "All") ? [] : filters.attributes.filter((data) => data.isSelected).map((data) => data.id),
            'rules': filters.rules && filters.rules.some((data) => data.name === "All") ? [] : filters.rules.filter((data) => data.isSelected).map((data) => data.name),
            'is_drilldown': true
        };
        if (expandInfo.type === "datasource") {
            if (expandInfo.attribute_rule) {
                requestParams.attribute_name = expandInfo.attribute_rule;
            }
            if (groupBy !== "datasource") {
                requestParams.datasources = [expandInfo.source_id];
            }
        } else if (expandInfo.type === "dataset") {
            requestParams.datasets = [expandInfo.dataset_id];
        } else if (expandInfo.type === "attribute") {
            requestParams.attribute_name = expandInfo.attribute_name;
        }

        dispatch(getDriftReport(requestParams)).then((response) => {
            if (response && response.length > 0) {
                response = response.map((report) => { return { ...report, type: type }; });
                extractReport(type, expandInfo, "children", [...response]);
            }
            setLoading(false);
        });
    };


    const expandAccordian = (expandDetail) => {
        let type = "dataset";
        if (expandDetail.type === "dataset") {
            type = "attribute";
        } else if (expandDetail.type === "attribute") {
            type = "datasource";
        }

        if (!expandDetail.isOpen) {
            getExtractReport(type, expandDetail);
        } else {
            extractReport(type, expandDetail, "isOPen");
        }
    };


    const initalizeReport = useCallback((reports, type) => {
        reports = reports.map((report) => { return { ...report, type: type }; });
        setReport([...reports]);
        const removeColumns = ["dataset_id", "source_id", "type", "isParent"];
        const headers = Object.keys(reports[0]).filter((data) => !removeColumns.includes(data)).map((data) => {
            return {
                name: data.replace(/_/g, ' '),
                value: data
            };
        });
        setColumns([...headers]);
    }, []);


    const getReport = useCallback((requestParams, isExpand = false) => {
        setLoading(true);
        dispatch(getDriftReport(requestParams)).then((response) => {
            if (response && response.length > 0) {
                response = response.map((report) => { return { ...report, isParent: true }; });
                initalizeReport([...response], requestParams.group_by);
            } else {
                setReport([]);
            }
            setLoading(false);
        });
    }, [dispatch, initalizeReport]);

    const getDriftInitLoad = useCallback(() => {
        const requestParams = {
            'filter_count': filters.filter_count,
            'range': filters.range,
            'group_by': filters.group_by,
            'datasets': filters.datasets && filters.datasets.some((data) => data.name === "All") ? [] : filters.datasets.filter((data) => data.isSelected).map((data) => data.id),
            'datasources': filters.datasources && filters.datasources.some((data) => data.name === "All") ? [] : filters.datasources.filter((data) => data.isSelected).map((data) => data.id),
            'attributes': filters.attributes && filters.attributes.some((data) => data.name === "All") ? [] : filters.attributes.filter((data) => data.isSelected).map((data) => data.id),
            'rules': filters.rules && filters.rules.some((data) => data.name === "All") ? [] : filters.rules.filter((data) => data.isSelected).map((data) => data.name)
        };

        getReport(requestParams, false);

    }, [filters, getReport]);

    useEffect(() => {
        getDriftInitLoad();
    }, [getDriftInitLoad]);

    const navigateReport = (report, columnName) => {
        if (filters.group_by === "datasource") {
            if (report.type === "attribute") {
                if (columnName === "attribute_name") {
                    history.push({ pathname: `/dataset/${report.dataset_id}`, state: { datasource: {}, datasourceId: report.source_id, isdrift: true, attribute: report.attribute_name } });
                } else {
                    history.push({ pathname: `/dataset/${report.dataset_id}`, state: { datasource: {}, datasourceId: report.source_id, isdrift: true, attribute: report.attribute_name, ruleName: columnName } });
                }
            } else if (report.dataset_id) {
                history.push({ pathname: `/dataset/${report.dataset_id}`, state: { datasource: {}, isdrift: true } });
            } else {
                history.push({ pathname: `/catalog/${report.source_id}`, state: { datasource: {} } });
            }
        } else if (filters.group_by === "dataset") {
            if (report.type === "attribute") {
                if (columnName === "attribute_name") {
                    history.push({ pathname: `/dataset/${report.dataset_id}`, state: { datasource: {}, datasourceId: report.source_id, isdrift: true, attribute: report.attribute_name } });
                } else {
                    history.push({ pathname: `/dataset/${report.dataset_id}`, state: { datasource: {}, datasourceId: report.source_id, isdrift: true, attribute: report.attribute_name, ruleName: columnName } });
                }
            } else if (report.dataset_id) {
                history.push({ pathname: `/dataset/${report.dataset_id}`, state: { datasource: {}, isdrift: true } });
            }
        } else {
            if (report.type === "attribute") {
                return false;
            }
            if (report.type === "datasource") {
                history.push({ pathname: `/catalog/${report.source_id}`, state: { datasource: {} } });
            } else if (columnName === "dataset_name") {
                history.push({ pathname: `/dataset/${report.dataset_id}`, state: { datasource: {}, datasourceId: report.source_id, isdrift: true, attribute: report.attribute_rule } });
            } else {
                history.push({ pathname: `/dataset/${report.dataset_id}`, state: { datasource: {}, datasourceId: report.source_id, isdrift: true, attribute: report.attribute_rule, ruleName: columnName } });
            }
        }
    };

    return (
        <Grid className={classNames(classes.container, classes.relative)} container>
            <Grid container direction="row" justify="center" className={classes.reportAttributesTableContainer}>
                {
                    driftReport.length > 0 ?
                        <Table stickyHeader className={classNames(classes.propertyListPageTable, classes.defineTable, classes.standardTable)}>
                            <TableHead>
                                <TableRow>
                                    {
                                        columns.map((column, index) =>
                                            <TableCell key={`column_${index}`} className={classNames(index === 0 ? classes.firstColumn : classes.otherColumn)}>
                                                <Typography variant="body1" className={classes.tableHeader}>
                                                    {index === 0 ? "Datasource / Dataset / Attribute" : column.name}
                                                </Typography>
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    driftReport.map((report, index) =>
                                        <DriftReportAccordian
                                            key={`report_${index}`}
                                            columns={columns}
                                            report={report}
                                            expandAccordian={(expandDetail) => expandAccordian(expandDetail)}
                                            navigateReport={(info, columnName) => navigateReport(info, columnName)}
                                        />
                                    )
                                }
                            </TableBody>
                        </Table> :
                        <NoResultFound />
                }
            </Grid>
            {isLoading && <Loader />}
        </Grid>
    );
};

DriftReportComponent.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object
};

export default withStyles((theme) => ({
    ...MasterStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DriftReportComponent);