import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import { alertActions } from '../constants/actionTypes/alertActionTypes';
import { dataPreparationActions } from '../constants/actionTypes/dataPreparationActionTypes';
import * as service from '../services/apiService';

export const createDataPreparation = (requestParams) => {
    return (dispatch) => {
        dispatch(request(dataPreparationActions.CREATE_DATA_PREPARATION_REQUEST));
        return service.postRequest(appConstants.END_POINTS.dataPreparation.createDataPreparation, requestParams).then((response) => {
            dispatch(success(dataPreparationActions.CREATE_DATA_PREPARATION_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(dataPreparationActions.CREATE_DATA_PREPARATION_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
        });
    };
};

export const getAllDataPreparation = () => {
    return (dispatch) => {
        dispatch(request(dataPreparationActions.GET_ALL_DATA_PREPARATION_REQUEST));
        return service.getRequest(appConstants.END_POINTS.dataPreparation.getAllDataPreparation).then((response) => {
            dispatch(success(dataPreparationActions.GET_ALL_DATA_PREPARATION_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(dataPreparationActions.GET_ALL_DATA_PREPARATION_FAILURE, error));
        });
    };
};


export const getDataPreparation = (dataId) => {
    const endpoint = `${appConstants.END_POINTS.dataPreparation.getDataPreparation}/${dataId}`;
    return (dispatch) => {
        dispatch(request(dataPreparationActions.GET_DATA_PREPARATION_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(dataPreparationActions.GET_DATA_PREPARATION_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(dataPreparationActions.GET_DATA_PREPARATION_FAILURE, error));
        });
    };
};

export const clearTransformation = (transformation, isApplied = false) => {
    return {
        type: dataPreparationActions.CLEAR_TRANSFORMATION,
        transformation,
        isApplied: Boolean(isApplied)
    };
};

export const updateDataPreparation = (dataId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.dataPreparation.updateDataPreparation}/${dataId}`;
    return (dispatch) => {
        dispatch(request(dataPreparationActions.UPDATE_DATA_PREPEATION_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(dataPreparationActions.UPDATE_DATA_PREPEATION_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(dataPreparationActions.UPDATE_DATA_PREPEATION_FAILURE, error));
        });
    };
};

export const deleteDataPreparation = (dataId) => {
    const endpoint = `${appConstants.END_POINTS.dataPreparation.deleteDataPreparation}/${dataId}`;
    return (dispatch) => {
        dispatch(request(dataPreparationActions.DELETE_DATA_PREPARATION_REQUEST));
        return service.deleteRequest(endpoint).then((response) => {
            dispatch(success(dataPreparationActions.DELETE_DATA_PREPARATION_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(dataPreparationActions.DELETE_DATA_PREPARATION_FAILURE, error));
        });
    };
};

export const getDatasetAttributes = (dataId) => {
    const endpoint = `${appConstants.END_POINTS.dataPreparation.getDatasetAttributes}/${dataId}`;
    return (dispatch) => {
        dispatch(request(dataPreparationActions.GET_DATASET_ATTRIBUTE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(dataPreparationActions.GET_DATASET_ATTRIBUTE_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(dataPreparationActions.GET_DATASET_ATTRIBUTE_FAILURE, error));
        });
    };
};

export const previewTransformation = (transformation) => {
    return (dispatch) => {
        dispatch(request(dataPreparationActions.PREVIEW_TRANSFORMATION_REQUEST));
        return service.postRequest(appConstants.END_POINTS.dataPreparation.previewTansformation, transformation).then((response) => {
            dispatch(success(dataPreparationActions.PREVIEW_TRANSFORMATION_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(dataPreparationActions.PREVIEW_TRANSFORMATION_FAILURE, error));
        });
    };
};

export const applyTransformation = (transformation) => async (dispatch) => {
    dispatch(request(dataPreparationActions.APPLY_TRANSFORMATION_REQUEST));
    const response = await service.postRequest(appConstants.END_POINTS.dataPreparation.applyTansformation, transformation);
    if (response && response.data) {
        dispatch(success(dataPreparationActions.APPLY_TRANSFORMATION_SUCCESS, response));
        return response.data;
    }
    dispatch(failure(dataPreparationActions.APPLY_TRANSFORMATION_FAILURE, response));
    dispatch(failure(alertActions.ALERT_ERROR, response.toString()));
};

export const getConfiguration = (dataId) => {
    const endpoint = `${appConstants.END_POINTS.dataPreparation.getConfiguration}/${dataId}`;
    return (dispatch) => {
        dispatch(request(dataPreparationActions.GET_DATA_PREPARATION_CONFIGURATION_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(dataPreparationActions.GET_DATA_PREPARATION_CONFIGURATION_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(dataPreparationActions.GET_DATA_PREPARATION_CONFIGURATION_FAILURE, error));
        });
    };
};

export const updateConfiguration = (transformId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.dataPreparation.updateConfiguration}/${transformId}`;
    return (dispatch) => {
        dispatch(request(dataPreparationActions.UPDATE_DATA_PREPEATION_CONFIGURATION_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(dataPreparationActions.UPDATE_DATA_PREPEATION_CONFIGURATION_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(dataPreparationActions.UPDATE_DATA_PREPEATION_CONFIGURATION_FAILURE, error));
        });
    };
};

export const createConfiguration = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.dataPreparation.createConfiguration}`;
    return (dispatch) => {
        dispatch(request(dataPreparationActions.CREATE_DATA_PREPEATION_CONFIGURATION_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(dataPreparationActions.CREATE_DATA_PREPEATION_CONFIGURATION_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(dataPreparationActions.CREATE_DATA_PREPEATION_CONFIGURATION_FAILURE, error));
        });
    };
};

export const pushTransformedDataset = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.dataPreparation.pushTransformedDataset}`;
    return (dispatch) => {
        dispatch(request(dataPreparationActions.PUSH_TRANSFORMED_DATASET_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(dataPreparationActions.PUSH_TRANSFORMED_DATASET_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(dataPreparationActions.PUSH_TRANSFORMED_DATASET_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
        });
    };
};

export const deleteConfiguration = (transformId) => {
    const endpoint = `${appConstants.END_POINTS.dataPreparation.deleteConfiguration}/${transformId}`;
    return (dispatch) => {
        dispatch(request(dataPreparationActions.DELETE_DATA_TRANSFORM_REQUEST));
        return service.deleteRequest(endpoint).then((response) => {
            dispatch(success(dataPreparationActions.DELETE_DATA_TRANSFORM_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(dataPreparationActions.DELETE_DATA_TRANSFORM_FAILURE, error));
        });
    };
};


export const getTransformations = (transformId) => {
    const endpoint = `${appConstants.END_POINTS.dataPreparation.getTransformations}/${transformId}`;
    return (dispatch) => {
        dispatch(request(dataPreparationActions.GET_TRANSFORMATION_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(dataPreparationActions.GET_TRANSFORMATION_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(dataPreparationActions.GET_TRANSFORMATION_FAILURE, error));
        });
    };
};


export const deleteTransform = (transformId) => {
    const endpoint = `${appConstants.END_POINTS.dataPreparation.deleteTransform}/${transformId}`;
    return (dispatch) => {
        dispatch(request(dataPreparationActions.DELETE_TRANSFORM_REQUEST));
        return service.deleteRequest(endpoint).then((response) => {
            dispatch(success(dataPreparationActions.DELETE_TRANSFORM_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(dataPreparationActions.DELETE_TRANSFORM_FAILURE, error));
        });
    };
};