const MasterSearchDetailStyles = (theme) => ({
    masterColumnGridItem: {
        flexGrow: 0,
        maxWidth: '12%',
        flexBasis: '12%',
        paddingLeft: '20px'
    },
    columnHeader: {
        color: '#4F6D7A',
        fontSize: '13px',
        fontWeight: 500,
        textTransform: 'capitalize'
    },
    masterGridItem: {
        flexGrow: 0,
        maxWidth: '12.4%',
        flexBasis: '12.4%',
        paddingLeft: 20
    },
    searchTableContainer: {
        width: '100%',
        marginTop: 20,
        overflowX: 'auto',
        borderBottom: `1px solid ${theme.palette.border.default}`,
        scrollbarColor: `#9EAEB4 ${theme.palette.background.default}`,
        scrollbarWidth: 'thin',
        "&::-webkit-scrollbar": {
            height: '8px',
            backgroundColor: theme.palette.background.default,
            width: '8px',
            zIndex: 9999
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: '#9EAEB4',
            borderRadius: '4px',
            zIndex: 9999
        },
        '& .MuiTableCell-head': {
            borderBottom: '0px',
            padding: '15px 10px',
            '& p': {
                textTransform: 'capitalize'
            }
        },
        '& .MuiTableCell-body': {
            borderBottom: '0px',
            padding: '15px 10px',
            '& p': {
                textTransform: 'none'
            }
        },
        '& .MuiTableHead-root': {
            backgroundColor: '#f3f7f9'
        },
        '& table': {
            marginBottom: 20,
            border: '1px solid #edf2f4'
        },
        '& table:last-child': {
            marginBottom: 0
        }
    },
    masterSearchListContainer: {
        paddingRight: '10px',
        overflowY: 'auto',
        scrollbarColor: `#9EAEB4 ${theme.palette.background.default}`,
        scrollbarWidth: 'thin',
        "&::-webkit-scrollbar": {
            height: '5px',
            backgroundColor: theme.palette.background.default,
            width: '6px'
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: '#9EAEB4',
            borderRadius: '3px'
        },
        '& .MuiMenuItem-root': {
            overflow: 'visible'
        },
        '& .MuiAccordionSummary-root': {
            margin: "8px 0px 0px 0px",
            padding: 0,
            boxShadow: "1px 1px 3px #eee",
            borderRadius: 0,
            height: 45,
            display: 'flex',
            alignItems: 'center'
        }
    },
    sidePanel: {
        flexBasis: '300px',
        maxWidth: '300px'
    },
    rightPanel: {
        position: 'relative',
        flexBasis: 'calc(100% - 300px)',
        maxWidth: 'calc(100% - 300px)'
    },
    masterSearchTableContainer: {
        // height: 'calc(100vh - 223px)',
        height: 'calc(100vh - 176px)',
        backgroundColor: theme.palette.common.white,
        padding: '0px 7px',
        position: 'relative',
        '& .MuiCard-root': {
            height: '100%',
            overflow: 'auto'
        },
        '& .MuiTable-stickyHeader': {
            padding: '6px 0'
        },
        '& .MuiTableCell-head': {
            color: "#222222"
        },
        '& .MuiTableCell-root': {
            padding: '7px 16px',
            background: "#fff"
        },
        '& .MuiTableCell-body > p': {
            color: theme.palette.grey.darkTxt,
            padding: '6px 10px'
        }
    },
    masterSearchTable: {
        '&.MuiCard-root': {
            height: 'calc(100vh - 250px) !important',
            margin: '5px 8px',
            overflow: 'auto'
        }
    },
    searchLoader: {
        top: 8,
        bottom: 8,
        left: 0,
        right: 0,
        background: theme.palette.common.white
    },
    viewGroup: {
        marginBottom: 15
    },
    tableView: {
        margin: 0,
        border: `1px solid ${theme.palette.grey.extraLight}`,
        borderBottom: 0,
        padding: '0 !important',
        '& tbody > tr > td > *': {
            paddingLeft: `0px !important`
        }
    },
    groupedTable: {
        margin: 0,
        border: `1px solid ${theme.palette.grey.extraLight}`,
        borderBottom: 0,
        padding: '0 !important',
        '& tbody > tr > td > *': {
            paddingLeft: `0px !important`
        }
    },
    collapseContainer: {
        background: `${theme.palette.background.default} !important`,
        '& > td': {
            background: `${theme.palette.background.default} !important`,
            borderBottom: 0
        },
        '& td .MuiCollapse-container.MuiCollapse-entered': {
            borderBottom: `1px solid ${theme.palette.grey.extraLight}`
        },
        '& td .MuiCollapse-container tbody': {
            border: `1px solid ${theme.palette.grey.extraLight}`
        },
        '& td .MuiCollapse-container th': {
            background: `${theme.palette.background.default} !important`
        },
        '& td .MuiCollapse-wrapperInner': {
            padding: '8px 10px'
        }
    }
});

export default MasterSearchDetailStyles;