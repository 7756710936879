import React from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DomainStyles from './DomainStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import Circular from '../Charts/Circular.jsx';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { updateViews } from '../../actions/datasourceActions';
import { getColor } from '../../helpers/appHelpers.js';

const DomainCard = (props) => {
    const { classes, attribute, index, history, dq_score_threshold } = props;
    const dispatch = useDispatch();

    const redirectSource = (type) => {
        const model = {
            "source_id": attribute.source_id,
            "dataset_id": type === "dataset" ? attribute.dataset_id : null,
            "domain_id": null
        };
        dispatch(updateViews(model));
        if (type === "datasource") {
            history.push(`/catalog/${attribute.source_id}`);
        } else if (type === "attributeDqscore") {
            history.push({ pathname: `/dataset/${attribute.dataset_id}`, state: { datasource: {}, dqscore: true, attribute: attribute.attribute_name } });
        } else if (type === "attributeProperty") {
            history.push({ pathname: `/dataset/${attribute.dataset_id}`, state: { datasource: {}, attribute: attribute.attribute_name } });
        } else {
            history.push({ pathname: `/dataset/${attribute.dataset_id}`, state: { datasource: {}, datasourceId: attribute.source_id } });
        }

    };


    return (
        <Draggable
            key={attribute.attribute_id}
            draggableId={`${attribute.attribute_id}_${attribute.attribute_name}`}
            index={index}>
            {
                (provided, snapshot) => (
                    <Grid
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={classNames(classes.domainCardContainer)}
                        style={{ borderColor: getColor(attribute.dqscore ? attribute.dqscore : 0, dq_score_threshold) }}
                    >
                        <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                            <Grid item>
                                <Typography variant="h5" component="h5" onClick={() => redirectSource("attributeProperty")} className={classNames(classes.cursor, classes.domainAttributetxt)}>
                                    {attribute.attribute_name ? attribute.attribute_name : ""}
                                </Typography>
                                <Grid className={classes.sourceTitleSection}>
                                    <Typography className={classNames(classes.domainTxt, classes.cursor)} onClick={() => redirectSource("datasource")}>
                                        {attribute.source_name ? attribute.source_name : "Untitled Datasource"}
                                    </Typography>
                                    <Typography className={classNames(classes.domainTxt, classes.domainSplit)}>
                                        {" | "}
                                    </Typography>
                                    <Typography className={classNames(classes.domainTxt, classes.cursor)} onClick={() => redirectSource("dataset")}>
                                        {attribute.dataset_name ? attribute.dataset_name : "Untitled Dataset"}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item align="right" onClick={() => redirectSource("attributeDqscore")}>
                                <Circular
                                    showPercentage={Boolean(attribute.dqscore && attribute.dqscore !== 0)}
                                    showValue
                                    value={attribute.dqscore}
                                    dqScoreThreshold={attribute.dq_score_threshold && Object.keys(attribute.dq_score_threshold).length > 0 ? attribute.dq_score_threshold : dq_score_threshold}
                                />
                            </Grid>
                        </Grid>
                        {
                            attribute.description.length > 0 &&
                            <Typography className={classNames(classes.domainTxt, classes.marginTop15, "description")}>
                                {attribute.description ? attribute.description : ""}
                            </Typography>
                        }
                        <Grid container alignItems="center" justify="space-between" className={classes.marginTop15}>
                            <Grid item>
                                <Typography className={classes.domainTxt}>
                                    Total Records
                                </Typography>
                                <Typography variant="h6" component="h6" className={classes.cardCountText}>
                                    {attribute.total_records ? attribute.total_records : 0}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography className={classes.domainTxt}>
                                    Invalid Records
                                </Typography>
                                <Typography variant="h6" component="h6" className={classes.cardCountText}>
                                    {attribute.invalid_records ? attribute.invalid_records : 0}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
        </Draggable>
    );
};

DomainCard.propTypes = {
    classes: PropTypes.object,
    attribute: PropTypes.object,
    index: PropTypes.number,
    history: PropTypes.object,
    dq_score_threshold: PropTypes.object
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DomainCard);