import React, { Fragment } from "react";
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TableBody, TableCell, Table, Collapse, TableRow, withStyles } from '@material-ui/core';
import DriftReportRow from "./DriftReportRow.jsx";
import MasterStyles from '../Master/MasterStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';


const DriftReportAccordian = (props) => {
    const { report, columns, classes, expandAccordian, navigateReport } = props;
    const filters = useSelector(({ notification }) => notification.driftFilters);

    const prepareColumns = (reportData) => {
        const removeColumns = ["source_id", "dataset_id", "isLoading", "isExpand", "type", "children", "attribute_rule", "isOpen"];
        const headers = Object.keys(reportData).filter((data) => !removeColumns.includes(data)).map((data) => {
            return {
                name: data.replace(/_/g, ' '),
                value: data
            };
        });
        return headers;
    };


    return (
        <Fragment>
            <TableRow className={classes.attributeRow}>
                <DriftReportRow
                    report={report}
                    columns={columns}
                    isExpanded={report.isOpen}
                    expandAccordian={(expandDetail) => (((filters.group_by === "datasource" || filters.group_by === "dataset") && report.type !== "attribute") || (filters.group_by === "attribute" && report.type !== "dataset")) && expandAccordian(expandDetail)}
                    navigateReport={(info, columnName) => navigateReport(info, columnName)}
                    enableToggle={(((filters.group_by === "datasource" || filters.group_by === "dataset") && report.type !== "attribute") || (filters.group_by === "attribute" && report.type !== "dataset"))} />
            </TableRow>
            {
                report.children && report.children.map((data, index) =>
                    <TableRow key={index} className={classes.collapseContainer}>
                        <TableCell colSpan={columns.length + 1} style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }} >
                            <Collapse in={report.isOpen} timeout="auto" unmountOnExit className={(((filters.group_by === "datasource" || filters.group_by === "dataset") && data.type !== "attribute") || (filters.group_by === "attribute" && data.type !== "dataset")) ? classes.collapsibleReportRow : classes.collapsibleSubRow}>
                                <Table stickyHeader className={classNames(classes.masterAttributesTable, classes.defineTable, classes.standardTable)}>
                                    <TableBody>
                                        <DriftReportAccordian
                                            report={data}
                                            classes={classes}
                                            columns={prepareColumns(data)}
                                            expandAccordian={(expandDetail) => expandAccordian(expandDetail)}
                                            navigateReport={(info, columnName) => navigateReport(info, columnName)}
                                        />
                                    </TableBody>
                                </Table>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                )
            }

        </Fragment>
    );
};


DriftReportAccordian.propTypes = {
    report: PropTypes.object,
    columns: PropTypes.array,
    classes: PropTypes.object,
    expandAccordian: PropTypes.func,
    navigateReport: PropTypes.func
};

export default withStyles((theme) => ({
    ...MasterStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DriftReportAccordian);