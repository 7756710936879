import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, FormLabel } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import DashboardChartTile from '../Dashboard/DashboardChartTile.jsx';
import UserAutoComplete from '../UserAutoComplete/UserAutoComplete.jsx';
import ActionItemStyles from './ActionItemStyles.jsx';
import { useDispatch } from 'react-redux';
import { getActionItemAnalysis } from '../../actions/notificationActions';
import Loader from '../../components/Loaders/Loader.jsx';
import classNames from 'classnames';

const ActionitemMetrics = (props) => {
    const { classes, userList, users, onChangeFilter, items, isLoading } = props;
    const [userInput, setUserInput] = useState('');
    const [analysis, setAnalysis] = useState({});
    const dispatch = useDispatch();


    const getAnalysis = useCallback(() => {
        dispatch(getActionItemAnalysis()).then((response) => {
            if (response) {
                const timelineAnalysis = {
                    open: [],
                    new: [],
                    resolved: [],
                    all: []
                };
                for (const analysis of response) {
                    timelineAnalysis.open.push(analysis.actionitem_open_analysis);
                    timelineAnalysis.new.push(analysis.actionitem_new_analysis);
                    timelineAnalysis.resolved.push(analysis.actionitem_resolved_analysis);
                    timelineAnalysis.all.push(analysis.actionitem_analysis);
                }
                setAnalysis({ ...timelineAnalysis });
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getAnalysis();
    }, [getAnalysis]);

    const onChangeUser = (event) => {
        onChangeFilter("users", event);
        setUserInput('');
    };

    const onInputValueOnChange = (value) => {
        setUserInput(value);
    };

    const getCount = useCallback((type) => {
        return items.filter((item) => item.status === type).length;
    }, [items]);

    return (
        <Grid xs={12} justify="space-between" container spacing={0}>
            <Grid xs={2.4} item className={classNames(classes.cardSection, classes.relative)}>
                <Grid className={classes.card}>
                    <FormLabel>
                        Assignee / Users
                    </FormLabel>
                    <Grid className={classes.marginTop10}>
                        <UserAutoComplete
                            availableList={userList}
                            selectedList={users}
                            displayValue="name"
                            onChange={onChangeUser}
                            onInputValueChange={onInputValueOnChange}
                            inputValue={userInput}
                        />
                    </Grid>
                </Grid>
                {isLoading && <Loader />}
            </Grid>
            <Grid xs={2.4} item className={classNames(classes.cardSection, classes.relative)}>
                {isLoading && <Loader />}
                <DashboardChartTile chartColor="#FF5C5C" chartClassName={"open-timeline"} type="Open" count={getCount('open')} analysis={analysis.open ? analysis.open : []} />
            </Grid>
            <Grid xs={2.4} item className={classNames(classes.cardSection, classes.relative)}>
                {isLoading && <Loader />}
                <DashboardChartTile chartColor="#FDAC42" chartClassName={"new-timeline"} type="New" count={getCount('new')} analysis={analysis.new ? analysis.new : []} />
            </Grid>
            <Grid xs={2.4} item className={classNames(classes.cardSection, classes.relative)}>
                {isLoading && <Loader />}
                <DashboardChartTile chartColor="#54DF9A" chartClassName={"resolved-timeline"} type="Resolved" count={getCount('resolved')} analysis={analysis.resolved ? analysis.resolved : []} />
            </Grid>
            <Grid xs={2.4} item className={classNames(classes.cardSection, classes.relative)}>
                {isLoading && <Loader />}
                <DashboardChartTile chartColor="#CBECFC" chartClassName={"all-timeline"} type="All" count={items.length} analysis={analysis.all ? analysis.all : []} />
            </Grid>
        </Grid>
    );
};

ActionitemMetrics.propTypes = {
    classes: PropTypes.object,
    userList: PropTypes.array,
    users: PropTypes.array,
    onChangeFilter: PropTypes.func,
    items: PropTypes.array,
    isLoading: PropTypes.bool
};

export default withStyles((theme) => ({
    ...ActionItemStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ActionitemMetrics);