import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, withStyles, Typography, MenuItem, TextField } from '@material-ui/core';
import { appConstants } from '../../constants/appConstants.js';
import { getDataTypeColor, sortTable } from '../../helpers/appHelpers.js';
import TextBox from '../TextBox/TextBox.jsx';
import classNames from 'classnames';
import AutoComplete from '../AutoComplete/AutoCompleteInput.jsx';
import Styles from '../../layouts/Styles.jsx';
import SemanticGlossaryStyles from './SemanticGlossaryStyles.jsx';
import Verified from '../../assets/images/verified.svg';
import InProgess from '../../assets/images/wip.svg';
import Deprecated from '../../assets/images/deprecated.svg';
import Issues from '../../assets/images/issues.svg';
import Tags from '../Tags/Tags.jsx';

const SemanticGlossaryInfo = (props) => {
    const { classes, glossary, breadcrumb, semanticInfo, onChange, onSave, redirect, glossaryNames } = props;
    const [duplicateNameError, setNameError] = useState(false);
    const datatypes = useSelector(({ datasource }) => datasource.datatypes);

    const renderStatusIcon = (status) => {
        if (status.toLowerCase() === "verified") {
            return Verified;
        } else if (status.toLowerCase() === "work in progress") {
            return InProgess;
        } else if (status.toLowerCase() === "issues") {
            return Issues;
        }
        return Deprecated;
    };

    const getGlossaryCount = (type) => {
        return semanticInfo ? semanticInfo.filter((p) => p.semantic_type.toLowerCase() === type.toLowerCase()).length : 0;
    };

    const onSaveName = (value) => {
        const duplicate = glossaryNames.filter((data) => data.toLowerCase() === value.toLowerCase());
        if (duplicate.length !== 0 || value === "") {
            return setNameError(true);
        }
        setNameError(false);
        const regex = /^[A-Za-z][A-Za-z0-9]+$/;
        if (!regex.test(value)) {
            return false;
        }
        onSave("name");
    };

    const updateTags = (value, type, index) => {
        const tags = glossary.tags ? glossary.tags : [];
        value = value.trim();
        if (type === "add") {
            const tagExist = tags.some((tag) => tag.toLowerCase() === value.toLowerCase());
            if (!tagExist) { tags.push(value); }
        } else {
            if (index === -1) { tags.splice(tags.length - 1, 1); }
            else { tags.splice(index, 1); }
        }
        onChange('tags', tags);
    };

    const updateTagItem = () => {
        onSave("tags");
    };

    const resetItems = useCallback(() => {
        setNameError(false);
    }, []);

    useEffect(() => {
        resetItems();
    }, [resetItems, glossary]);

    return (
        <Grid>
            <Grid container className={classNames(classes.marginBottom5, classes.sematicBreadcrumb)}>
                {
                    breadcrumb.map((crumb, index) =>
                        <Typography variant="body2" key={`crumb_${index}`} component="span" className={classes.link} onClick={() => (crumb.path !== "" ? redirect(crumb.path) : null)}>
                            {crumb && crumb.name ? crumb.name : ""}
                            {index !== breadcrumb.length - 1 && ' | '}
                        </Typography>
                    )
                }
            </Grid>
            <Grid container justify="space-between">
                <Grid item className={classes.datasourceTitle}>
                    <TextBox
                        name="name"
                        placeholder="Field Name"
                        value={glossary && glossary.name ? glossary.name : ''}
                        className={classNames(classes.inlinetxt, classes.headerTitleTxt)}
                        onChange={(event) => onChange(event.target.name, event.target.value)}
                        onBlur={(event) => onSaveName(event.target.value)}
                        validators={['required', 'matchRegexp:^[A-Za-z][A-Za-z0-9]+$']}
                        errorMessages={['Name is required', 'Special Character will not allowed']}
                        error={duplicateNameError}
                        helperText={duplicateNameError ? "Name is Duplicated" : ""}
                    />
                    {
                        glossary && glossary.semantic_type === "field" &&
                        <Grid className={classes.datatypeContainer}>
                            <Grid className={classNames(classes.typeIcon, classes.iconStyle)} style={{ color: getDataTypeColor(glossary.datatype ? glossary.datatype : '') }}>
                                {glossary && glossary.datatype ? glossary.datatype[0] : ""}
                            </Grid>
                            <AutoComplete
                                className={classes.marginBottom6}
                                name="datatype"
                                options={sortTable(datatypes, "asc", "name")}
                                value={glossary && glossary.datatype ? glossary.datatype : ''}
                                getOptionLabel={
                                    (option) => {
                                        if (option && option.name) {
                                            return option.name;
                                        }
                                        return option;
                                    }
                                }
                                getOptionSelected={(option, value) => option.name === value}
                                renderInput={(params) => <TextField {...params} className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)} margin="normal" placeholder="DataType" />}
                                onChange={(_, selectedValue) => onChange("datatype", selectedValue.name)}
                                onBlur={(event) => onSave("datatype")}
                                openOnFocus
                                disableClearable
                                fullWidth
                                forcePopupIcon={false}
                                noOptionsText={"No datatypes"}
                            />
                        </Grid>
                    }
                </Grid>
                <Grid item align="right">
                    <TextBox
                        name="status"
                        select
                        onChange={(event) => onChange(event.target.name, event.target.value)}
                        onBlur={() => onSave("status")}
                        value={glossary && glossary.status ? glossary.status : appConstants.glossaryStatus[0].value}
                        className={classNames(classes.inlinetxt, classes.glossarySelect)}
                        SelectProps={
                            {
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "center"
                                    },
                                    getContentAnchorEl: null
                                },
                                IconComponent: () => null
                            }
                        }
                    >
                        {
                            sortTable(appConstants.glossaryStatus, "asc", "name").map((status, index) =>
                                <MenuItem key={index} className={classes.menuItem} value={status.value}>
                                    <img src={renderStatusIcon(status.value)} alt="" className={classes.marginRight5} />
                                    <Typography>
                                        {status.name}
                                    </Typography>
                                </MenuItem>
                            )
                        }
                    </TextBox>
                </Grid>
            </Grid>
            {
                glossary.semantic_type && glossary.semantic_type.toLowerCase() !== "field" &&
                <Grid>
                    <Typography className={classNames(classes.lightTxt, classes.smallFontSize)} component="span" variant="span">
                        {`${getGlossaryCount("field")} Terms`}
                    </Typography>
                    <Typography className={classNames(classes.lightTxt, classes.seperator, classes.smallFontSize)} component="span" variant="span">
                        ,
                    </Typography>
                    <Typography className={classNames(classes.lightTxt, classes.smallFontSize)} component="span" variant="span">
                        {`${getGlossaryCount("category")} ${getGlossaryCount("category") > 1 ? "Categories" : "Category"}`}
                    </Typography>
                </Grid>
            }
            {
                glossary.semantic_type && glossary.semantic_type.toLowerCase() === "field" &&
                <Typography className={classes.lightTxt}>
                    {'Please provide Business Definition'}
                </Typography>
            }
            <Grid className={classNames(classes.marginTop10, classes.hoverBgGrey)}>
                <Typography component="h5" variant="h5">
                    Description
                </Typography>
                <TextBox
                    name="description"
                    placeholder="Description"
                    value={glossary.description ? glossary.description : ''}
                    onChange={(event) => onChange(event.target.name, event.target.value)}
                    onBlur={() => onSave("description")}
                    className={classNames(classes.inlinetxt)}
                    fullWidth
                    multiline
                    rows="2"
                />
            </Grid>
            {
                glossary && glossary.semantic_type === "field" &&
                <Grid className={classes.marginTop10}>
                    <Typography component="h6" variant="h6">
                        Tags
                    </Typography>
                    <Tags type="tags" focus tags={glossary.tags ? glossary.tags : []} updateTags={updateTags} updateTagItem={updateTagItem} />
                </Grid>
            }
        </Grid>
    );
};

SemanticGlossaryInfo.propTypes = {
    classes: PropTypes.object,
    glossary: PropTypes.object,
    breadcrumb: PropTypes.array,
    semanticInfo: PropTypes.array,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    redirect: PropTypes.func,
    glossaryNames: PropTypes.array
};

export default withStyles((theme) => ({
    ...SemanticGlossaryStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SemanticGlossaryInfo);