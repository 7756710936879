const NotificationStyles = (theme) => ({
    cardContainer: {
        marginBottom: 20,
        borderRadius: 0,
        overflow: "hidden",
        border: '1px solid rgba(26,54,126,0.125)',
        background: "#fff",
        padding: '25px 30px',
        '&:last-child': {
            marginBottom: "none"
        },
        '& .MuiCardContent-root:last-child': {
            paddingBottom: '0px'
        },
        '&:hover': {
            boxShadow: '0 4px 25px 0 rgba(0,0,0,.1)',
            transition: 'all 0.2s ease'
        }
    },
    listSection: {
        flexBasis: 'calc(100% - 60px)',
        maxWidth: 'calc(100% - 60px)'
    },
    noticationIconSection: {
        flexBasis: '60px',
        maxWidth: '60px'
    },
    notificationDetailSection: {
        marginLeft: 32,
        display: 'flex'
    },
    notificationDetailScheduleSection: {
        display: 'flex'
    },
    notificationIcon: {
        width: 30,
        height: 30,
        borderRadius: 50,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    isNewNotifiactionColor: {
        border: `2px solid ${theme.palette.grey.dark}`,
        color: theme.palette.grey.dark
    },
    isOldNotifiactionColor: {
        border: `2px solid ${theme.palette.border.normal}`,
        color: theme.palette.border.normal
    },
    notificationContainer: {
        marginTop: 25,
        position: 'relative',
        height: 'calc(100vh - 220px)',
        overflowY: 'auto',
        padding: '0px 10px 10px 10px',
        marginRight: 5,
        backgroundColor: theme.palette.background.paper,
        '& .MuiTableCell-root': {
            paddingLeft: '8px !important',
            paddingRight: '8px !important'
        }
    },
    notificationTitleContainer: {
        display: 'flex'
    },
    notificationTextHighlight: {
        color: theme.palette.grey.default,
        fontWeight: 500
        // marginLeft: 5
    },
    notificationText: {
        color: theme.palette.grey.dark,
        marginLeft: 5
    },
    listContainer: {
        display: 'flex'
    },
    notificationSelect: {
        '& .MuiSelect-select': {
            padding: '0px 24px 5px 10px'
        }
    },
    notificationSection: {
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            width: 4,
            height: '100%',
            backgroundColor: theme.palette.border.normal,
            left: 12,
            top: 50,
            zIndex: -1
        },
        '&::before:last-child': {
            display: 'none'
        }
    },
    tableHeadCell: {
        fontSize: 13,
        color: '#222'
    },
    tableCell: {
        color: "#222",
        fontSize: 13,
        padding: "5px 16px",
        textTransform: "capitalize"
    },
    filterContainer: {
        position: 'absolute',
        right: 20,
        background: '#fff',
        zIndex: 1,
        marginRight: '10px',
        transitionDuration: '500ms',
        transition: 'width .55s ease',
        animation: 'slide-left536 0.4s ease',

        // border: '1px solid #d8d5d5',
        boxShadow: '0px 0px 5px 3px #80808047',
        '& .datasourceFilterSection': {
            height: 60,
            padding: '0 10px'
        }
    },
    title: {
        fontSize: 13,
        color: theme.palette.grey.darkTxt,
        maxWidth: 150,
        display: "block",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        '&:hover': {
            color: theme.palette.primary.dark
        }
    },
    notifiUserEmail: {
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    noNotificationImg: {
        maxWidth: 230
    }
});
export default NotificationStyles;