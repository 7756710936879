import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, withStyles, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classnames from 'classnames';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import Paper from '@material-ui/core/Paper';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import { validateLicense, getLicenses, getLicenseModules, getOrganizationConfig, getSystemLicenseStatus, resetOrganizationConfig } from '../../actions/settingActions';
import { updateLicenseStatus } from '../../actions/datasourceActions';
import Loader from '../Loaders/Loader.jsx';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';
import SelectConnectors from '../SelectConnectors/SelectConnectors.jsx';
import moment from 'moment-timezone';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const License = (props) => {
    const { classes, organizationId } = props;
    const [licenseHistory, setLicenseHistory] = useState([]);
    const [activatedkey, setKey] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [showDialog, setDialog] = useState(false);
    const [openDialog, setConnectorDialog] = useState(false);
    const dispatch = useDispatch();

    const getOrganizationLicense = useCallback(() => {
        dispatch(getLicenses(organizationId)).then((response) => {
            if (response) {
                setLicenseHistory([...response]);
            }
        });
    }, [dispatch, organizationId]);

    useEffect(() => {
        getOrganizationLicense();
    }, [getOrganizationLicense]);

    const activateLicense = () => {
        setLoading(true);
        setDialog(false);
        dispatch(validateLicense(organizationId, activatedkey)).then((response) => {
            if (response) {
                dispatch(getSystemLicenseStatus());
                setLicenseHistory([...response]);
                dispatch(getLicenseModules(organizationId));
                dispatch(updateLicenseStatus());
                if (response.length > 0 && response[0] && response[0].license_type !== "Multi Tenant - Multiple Organizations") {
                    setConnectorDialog(response.length > 1 && !response[0].is_connection_updated);
                    dispatch(getOrganizationConfig(organizationId));
                } else {
                    dispatch(resetOrganizationConfig());
                }
                setKey("");
            }
            setLoading(false);
        });
    };

    const onSubmit = () => {
        if (licenseHistory.length === 0) {
            activateLicense();
        } else {
            setDialog(true);
        }
    };

    const onChange = (name, value) => {
        setKey(value);
    };

    return (
        <ValidatorForm form="My properties" onSubmit={() => onSubmit()}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextBox
                        label="License"
                        name="license"
                        value={activatedkey ? activatedkey : ''}
                        validators={['required']}
                        errorMessages={['License is required']}
                        onChange={(event) => onChange(event.target.name, event.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={3}>
                    <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classnames(classes.actionButtons, classes.submitBtn)}>
                        Activate
                        {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                    </Button>
                </Grid>
            </Grid>
            {
                licenseHistory.length !== 0 &&
                <Grid container style={{ marginTop: '15px' }}>
                    <Grid item xs={12}>
                        <TableContainer component={Paper} className={classes.tableWrapperStyle}>
                            <Table className={classnames(classes.table, classes.licenseTable, classes.standardTable, classes.tableStyle)} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'License Package Name'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'License Type'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'License Mode'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'Start Date'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'Expiry Date'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'License Key'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'Duration'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'Status'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        licenseHistory.map((row) => (
                                            <TableRow key={row.id}>
                                                <TableCell>
                                                    <Typography>
                                                        {row.license_package_name}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {row.license_type}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {row.license_mode}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {row.start_from ? moment(row.start_from).format('YYYY-MM-DD HH:mm:ss') : "NA"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {row.end_from ? moment(row.end_from).format('YYYY-MM-DD HH:mm:ss') : "NA"}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <ToolTipComponent title={row.key ? row.key : ""} arrow>
                                                        <Typography className={classes.licenseKeyContainer}>
                                                            {row.key}
                                                        </Typography>
                                                    </ToolTipComponent>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {row.duration}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography>
                                                        {row.status ? row.status : "DeActive"}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            }
            <AlertDialog title="Activate License"
                message={`If you change the connector then the connectors already in use will be disabled`}
                okButtonText="OK"
                cancelButtonText="Cancel"
                show={showDialog}
                onClickOK={() => activateLicense()}
                onClickCancel={() => setDialog(false)} />
            <SelectConnectors open={openDialog} onClose={() => setConnectorDialog(false)} organizationId={organizationId} />
        </ValidatorForm >
    );
};

License.propTypes = {
    classes: PropTypes.object,
    organizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(License);