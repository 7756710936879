import React, { useState, Fragment, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, withStyles, Typography, Card, Table, TableBody, TableHead, TableCell, TableRow, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import classNames from 'classnames';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Localization from '../../constants/localization';
import Linear from '../Charts/Linear.jsx';
import Styles from '../../layouts/Styles.jsx';
import { getDatasets, getDatasetAttributes, getDataSource } from '../../actions/datasourceActions';
import Circular from '../Charts/Circular.jsx';
import DatasourceListStyles from './DatasourceListStyles.jsx';
import Logo from '../../assets/images/sourceIcon.svg';
import ViewIcon from '../../assets/images/eye.svg';
import DatasourceStyles from '../Datasource/DatasourceStyles.jsx';
import Search from '../TextBox/Search.jsx';
import UserList from './UserList.jsx';
import Loader from '../Loaders/Loader.jsx';
import { getValue } from '../../helpers/appHelpers';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Collapse from '@material-ui/core/Collapse';
import { setCurrentPage } from '../../helpers/apiHelpers';

const AccordionTable = (props) => {
    const { dataset, index, classes, type } = props;
    const [open, setOpen] = useState(false);
    const [attributes, setAttributes] = useState([]);
    const initialFetch = 10;
    const [loadSize, setLoadSize] = useState(initialFetch);
    const [attributesTable, setAttributesTable] = useState(true);
    const [selectedDatasetId, setSelectedDatasetId] = useState();
    const dispatch = useDispatch();

    const getAttributesList = useCallback((start, end, type) => {
        dispatch(getDatasetAttributes(selectedDatasetId, start, end)).then((response) => {
            if (response && response.length !== 0) {
                setLoadSize(loadSize + initialFetch);
                setAttributes([...attributes, ...response]);
                setAttributesTable(true);
            } else {
                setAttributesTable(false);
            }
        });
    }, [attributes, dispatch, loadSize, selectedDatasetId]);

    const fetchData = useCallback(() => {
        if (attributesTable) {
            let startPage = 0;
            startPage = loadSize - initialFetch;
            getAttributesList(startPage, loadSize);
            setAttributesTable(false);
        }
    }, [getAttributesList, attributesTable, loadSize]);

    const onClickIcon = (datasetId) => {
        setOpen(!open);
        setAttributesTable(true);
        setSelectedDatasetId(datasetId);
    };

    useEffect(() => {
        if (open) {
            fetchData();
        }
    }, [fetchData, open]);

    return (
        <Fragment>
            <TableRow onClick={type !== "Tableau" ? () => onClickIcon(dataset.id) : null}>
                <TableCell>
                    <Grid className={classes.datasetTitleSection}>
                        <ToolTipComponent title={dataset.name} arrow>
                            <Typography component="span" className={classes.datasetName}>
                                {dataset.name}
                            </Typography>
                        </ToolTipComponent>
                    </Grid>
                </TableCell>
                <TableCell>
                    <ToolTipComponent title={type === "Tableau" ? dataset.total_worksheets : dataset.total_attributes} arrow placement="bottom-start">
                        <Typography className={classes.datasetTabText} noWrap>
                            {type === "Tableau" ? dataset.total_worksheets : dataset.total_attributes}
                        </Typography>
                    </ToolTipComponent>
                </TableCell>
                <TableCell>
                    <ToolTipComponent title={type === "Tableau" ? dataset.total_datasources : dataset.total_records} arrow placement="bottom-start">
                        <Typography className={classes.datasetTabText} noWrap>
                            {type === "Tableau" ? dataset.total_datasources : dataset.total_records}
                        </Typography>
                    </ToolTipComponent>
                </TableCell>
                <TableCell>
                    <Grid className={classes.timeSection}>
                        <Typography className={classes.datasetTabText} noWrap>
                            {moment(dataset.modified_date).format("DD MMM YYYY HH:mm")}
                        </Typography>
                    </Grid>
                </TableCell>
                <TableCell>
                    <Typography className={classes.datasetTabText}>
                        {dataset.load_type}
                    </Typography>
                </TableCell>
                {
                    type === "Tableau" &&
                    <TableCell>
                        <Typography
                            variant="body2"
                            className={classes.datasetTabText}
                        >
                            {dataset.owner}
                        </Typography>
                    </TableCell>
                }
                {
                    type !== "Tableau" &&
                    <TableCell style={{ paddingRight: 50 }}>
                        {
                            dataset.profile_score >= 0.1 ? (
                                <Linear showPercentage value={dataset.profile_score} />
                            ) : (
                                <Typography
                                    variant="body2"
                                    className={classes.disabledtxt}
                                >
                                    {"NA"}
                                </Typography>
                            )
                        }
                    </TableCell>
                }
                {
                    type !== "Tableau" &&
                    <TableCell>
                        {
                            dataset.curation_score >= 0.1 ? (
                                <Grid className={classes.curateScore}>
                                    <ArrowDropUpIcon
                                        className={
                                            classNames(
                                                classes.impactIcon,
                                                classes.impactArrow
                                            )
                                        }
                                    />
                                    <Typography className={classes.curateTxt}>
                                        {`${getValue(dataset.curation_score)}%`}
                                    </Typography>
                                </Grid>
                            ) : (
                                <Typography
                                    variant="body2"
                                    className={
                                        classNames(
                                            classes.notApplicableText,
                                            classes.curateNotApplicableText
                                        )
                                    }
                                >
                                    {"NA"}
                                </Typography>
                            )
                        }
                    </TableCell>
                }
                {
                    type !== "Tableau" &&
                    <TableCell>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </TableCell>
                }
            </TableRow>
            <TableRow>
                <TableCell colSpan={8} className={classes.nopadding}>
                    <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        className={classNames(classes.collapseTable, classes.enableScroll, classes.domainTableCollapse)}
                    >
                        <Table>
                            <TableHead>
                                <TableRow className={classes.noBackground}>
                                    {
                                        Localization.datasetTabAttributeHeaders.map(
                                            (header, index) => (
                                                <TableCell key={`attribute_${index}`}>
                                                    {header}
                                                </TableCell>
                                            )
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    attributes &&
                                    attributes.map((attribute, attrIndex) => (
                                        <TableRow
                                            key={`dataset${index}attribute${attrIndex}`}
                                        >
                                            <ToolTipComponent
                                                title={attribute.name}
                                                arrow
                                                placement="bottom-start"
                                            >
                                                <TableCell>
                                                    <Typography noWrap>
                                                        {attribute.name}
                                                    </Typography>
                                                </TableCell>
                                            </ToolTipComponent>
                                            <TableCell>
                                                <ToolTipComponent
                                                    title={attribute.name}
                                                    arrow
                                                    placement="bottom-start"
                                                >
                                                    <Typography noWrap>
                                                        {
                                                            attribute.business_name ? (
                                                                attribute.business_name
                                                            ) : (
                                                                <span
                                                                    className={classes.disabledtxt}
                                                                >
                                                                    {"NA"}
                                                                </span>
                                                            )
                                                        }
                                                    </Typography>
                                                </ToolTipComponent>
                                            </TableCell>
                                            <TableCell>
                                                <span className="description">
                                                    {
                                                        attribute.description ? (
                                                            attribute.description
                                                        ) : (
                                                            <span
                                                                className={classes.disabledtxt}
                                                            >
                                                                {"NA"}
                                                            </span>
                                                        )
                                                    }
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <Grid style={{ display: "flex" }}>
                                                    <Grid className={classes.typeIcon}>
                                                        {
                                                            attribute.datatype
                                                                ? attribute.datatype[0].toUpperCase()
                                                                : "T"
                                                        }
                                                    </Grid>
                                                    <Typography
                                                        className={classes.typeText}
                                                    >
                                                        {
                                                            attribute.datatype
                                                                ? attribute.datatype
                                                                : ""
                                                        }
                                                    </Typography>
                                                </Grid>
                                            </TableCell>
                                            <TableCell style={{ width: 200, paddingRight: '60px' }}>
                                                {
                                                    attribute.profile_score >= 0.1 ? (
                                                        <span>
                                                            <Linear
                                                                showPercentage
                                                                value={
                                                                    attribute.profile_score >
                                                                        99.9
                                                                        ? 100
                                                                        : attribute.profile_score
                                                                }
                                                            />
                                                        </span>
                                                    ) : (
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.disabledtxt}
                                                        >
                                                            {"NA"}
                                                        </Typography>
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell
                                                style={
                                                    {
                                                        width: 150,
                                                        padding: "0 10px"
                                                    }
                                                }
                                            >
                                                {
                                                    attribute.curation_score >= 0.1 ? (
                                                        <Grid
                                                            className={classes.curateScore}
                                                        >
                                                            <ArrowDropUpIcon
                                                                className={
                                                                    classNames(
                                                                        classes.impactIcon,
                                                                        classes.impactArrow
                                                                    )
                                                                }
                                                            />
                                                            <Typography
                                                                className={classes.curateTxt}
                                                            >
                                                                {
                                                                    `${getValue(
                                                                        attribute.curation_score
                                                                    ) > 99.9
                                                                        ? 100
                                                                        : getValue(
                                                                            attribute.curation_score
                                                                        )
                                                                    }%`
                                                                }
                                                            </Typography>
                                                        </Grid>
                                                    ) : (
                                                        <Typography
                                                            variant="body2"
                                                            className={classes.disabledtxt}
                                                        >
                                                            {"NA"}
                                                        </Typography>
                                                    )
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>

    );
};

AccordionTable.propTypes = {
    classes: PropTypes.object,
    dataset: PropTypes.object,
    index: PropTypes.object,
    type: PropTypes.string
};

const DatasourceDetails = (props) => {
    const { classes, source, redirect, breadcrumb } = props;
    const dispatch = useDispatch();
    const [search, setSearch] = useState('');
    const [datasetList, setDatasetList] = useState([]);
    const [datasets, setDatasets] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const initialFetch = 10;
    const [loadSize, setLoadSize] = useState(initialFetch);
    const [loadPage, setLoadPage] = useState(true);
    const [sourceDetail, setSourceDetail] = useState({ ...source });
    const [sourceBreadCrumb, setBreadCrumb] = useState([...breadcrumb]);
    const controllerRef = useRef();

    const getSourceDetail = useCallback(() => {
        dispatch(getDataSource(source.id)).then((response) => {
            if (response) {
                setSourceDetail({ ...response });
                setBreadCrumb([{ name: "Search", path: "search" }, { name: response.name, path: "" }]);
            }
        });
    }, [dispatch, source.id]);

    const getDatasetList = useCallback((start, end, key = '') => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }

        const controller = new AbortController();
        controllerRef.current = controller;
        const token = { signal: controllerRef?.current?.signal };
        const searchKey = key ? key : 'all';
        dispatch(getDatasets(source?.id, searchKey, start, end, token)).then((response) => {
            if (response && response?.datasets?.length !== 0) {
                setLoadSize(loadSize + initialFetch);
                setLoadPage(true);
            } else {
                setLoadPage(false);
            }
            let datasets = response?.datasets ? [...datasetList, ...response?.datasets] : [...datasetList];
            if (!start) {
                datasets = response?.datasets ? [...response?.datasets] : [];
            }
            if (searchKey && response?.key !== searchKey) {
                return;
            }
            setDatasetList(datasets);
            setDatasets(datasets);
            setLoading(false);
        });
    }, [datasetList, dispatch, loadSize, source?.id]);

    const onChangeSearch = (value) => {
        setSearch(value);
        setTimeout(() => getDatasetList(0, initialFetch, value), 0);
    };

    const fetchDatasets = useCallback((type) => {
        if (loadPage) {
            const startPage = (type === "referesh") ? 0 : datasetList.length;
            getDatasetList(startPage, initialFetch, search);
            setLoadPage(false);
        }
    }, [datasetList, getDatasetList, loadPage, search]);

    useEffect(() => {
        if (datasets && datasets.length <= 0) {
            setCurrentPage('datasource_detail');
            fetchDatasets();
        }
        if (source.loadDatasource) {
            getSourceDetail();
        }
    }, [source, dispatch, datasets, fetchDatasets, getSourceDetail]);

    const getSourceUsers = (sourceUsers) => {
        return sourceUsers.filter((user) => user.is_request_accepted);
    };

    const getCrumbIcon = (type) => {
        if (type === 'catalog') {
            return (
                <ToolTipComponent title={type} placement="bottom" arrow>
                    <svg xmlns="http://www.w3.org/2000/svg" className={classes.crumbIcon} width="26.176" height="25.086" viewBox="0 0 26.176 25.086">
                        <g id="security" transform="translate(0 -1)">
                            <path id="Path_251" data-name="Path 251" d="M18.544,25.27a.547.547,0,0,1-.212-.043c-.26-.11-6.332-2.734-6.332-8.1V12.571a.546.546,0,0,1,.371-.517l6-2.025a.546.546,0,0,1,.349,0l6,2.025a.545.545,0,0,1,.371.517v4.558c0,5.364-6.073,7.988-6.332,8.1a.547.547,0,0,1-.212.043ZM13.091,12.963v4.165c0,4.127,4.423,6.5,5.453,6.995,1.03-.5,5.453-2.878,5.453-6.995V12.963l-5.453-1.842Z" transform="translate(1.088 0.816)" fill="#ffffff" />
                            <path id="Path_252" data-name="Path 252" d="M17.727,19.408H17.7a.541.541,0,0,1-.4-.2L15.12,16.476a.545.545,0,0,1,.852-.682l1.8,2.25,3.387-3.385a.545.545,0,1,1,.771.771l-3.817,3.817a.543.543,0,0,1-.385.16Z" transform="translate(1.36 1.224)" fill="#ffffff" />
                            <path id="Path_253" data-name="Path 253" d="M20.178,8.635H3.817A3.817,3.817,0,0,1,3.817,1h16.36a3.817,3.817,0,1,1,0,7.635ZM3.817,2.091a2.727,2.727,0,1,0,0,5.453h16.36a2.727,2.727,0,1,0,0-5.453Z" transform="translate(0 0)" fill="#ffffff" />
                            <path id="Path_254" data-name="Path 254" d="M4.636,6.272A1.636,1.636,0,1,1,6.272,4.636,1.638,1.638,0,0,1,4.636,6.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,4.636,4.091Z" transform="translate(0.272 0.181)" fill="#ffffff" />
                            <path id="Path_255" data-name="Path 255" d="M9.636,6.272a1.636,1.636,0,1,1,1.636-1.636A1.638,1.638,0,0,1,9.636,6.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,9.636,4.091Z" transform="translate(0.725 0.181)" fill="#ffffff" />
                            <path id="Path_256" data-name="Path 256" d="M10.361,14.635H3.817A3.817,3.817,0,0,1,3.817,7h16.36a3.74,3.74,0,0,1,2.321.792.546.546,0,0,1-.671.861,2.656,2.656,0,0,0-1.65-.562H3.817a2.727,2.727,0,0,0,0,5.453h6.544a.545.545,0,0,1,0,1.091Z" transform="translate(0 0.544)" fill="#ffffff" />
                            <path id="Path_257" data-name="Path 257" d="M4.636,12.272a1.636,1.636,0,1,1,1.636-1.636A1.638,1.638,0,0,1,4.636,12.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,4.636,10.091Z" transform="translate(0.272 0.725)" fill="#ffffff" />
                            <path id="Path_258" data-name="Path 258" d="M9.636,12.272a1.636,1.636,0,1,1,1.636-1.636A1.638,1.638,0,0,1,9.636,12.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,9.636,10.091Z" transform="translate(0.725 0.725)" fill="#ffffff" />
                            <path id="Path_259" data-name="Path 259" d="M10.361,20.635H3.817a3.817,3.817,0,0,1,0-7.635h6.544a.545.545,0,0,1,0,1.091H3.817a2.727,2.727,0,0,0,0,5.453h6.544a.545.545,0,1,1,0,1.091Z" transform="translate(0 1.088)" fill="#ffffff" />
                            <path id="Path_260" data-name="Path 260" d="M4.636,18.272a1.636,1.636,0,1,1,1.636-1.636A1.638,1.638,0,0,1,4.636,18.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,4.636,16.091Z" transform="translate(0.272 1.269)" fill="#ffffff" />
                            <path id="Path_261" data-name="Path 261" d="M9.636,18.272a1.636,1.636,0,1,1,1.636-1.636A1.638,1.638,0,0,1,9.636,18.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,9.636,16.091Z" transform="translate(0.725 1.269)" fill="#ffffff" />
                        </g>
                    </svg>
                </ToolTipComponent>
            );
        } else if (type === 'datasource') {
            return (
                <ToolTipComponent title={type} placement="bottom" arrow>
                    <svg xmlns="http://www.w3.org/2000/svg" width="11.289" height="15.394" viewBox="0 0 11.289 15.394" className={classes.crumbIcon}>
                        <path id="database_17_" data-name="database (17)" d="M15.645,17.394c2.805,0,5.645-.793,5.645-2.309V4.309C21.289,2.793,18.45,2,15.645,2S10,2.793,10,4.309V15.085C10,16.6,12.839,17.394,15.645,17.394Zm0-14.368c2.865,0,4.618.831,4.618,1.283s-1.753,1.283-4.618,1.283-4.618-.831-4.618-1.283S12.78,3.026,15.645,3.026ZM11.026,5.695a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924V7.9c0,.452-1.753,1.283-4.618,1.283S11.026,8.353,11.026,7.9Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Z" transform="translate(-10 -2)" fill="#FFFFFF" />
                    </svg>
                </ToolTipComponent>
            );
        } else if (type === 'tableau_item') {
            return (
                <ToolTipComponent title={type} placement="bottom" arrow>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20.66" height="20.661" viewBox="0 0 20.66 20.661">
                        <g id="dashboard_2" transform="translate(-8203 -2812)">
                            <path id="Rectangle_13" data-name="Rectangle 13" d="M2.22,1.727a.494.494,0,0,0-.493.493V7.586a.494.494,0,0,0,.493.493H7.586a.494.494,0,0,0,.493-.493V2.22a.494.494,0,0,0-.493-.493H2.22M2.22,0H7.586a2.22,2.22,0,0,1,2.22,2.22V7.586a2.22,2.22,0,0,1-2.22,2.22H2.22A2.22,2.22,0,0,1,0,7.586V2.22A2.22,2.22,0,0,1,2.22,0Z" transform="translate(8203 2812)" fill="#f38080" />
                            <path id="Rectangle_15" data-name="Rectangle 15" d="M2.22,1.727a.494.494,0,0,0-.493.493V7.586a.494.494,0,0,0,.493.493H7.586a.494.494,0,0,0,.493-.493V2.22a.494.494,0,0,0-.493-.493H2.22M2.22,0H7.586a2.22,2.22,0,0,1,2.22,2.22V7.586a2.22,2.22,0,0,1-2.22,2.22H2.22A2.22,2.22,0,0,1,0,7.586V2.22A2.22,2.22,0,0,1,2.22,0Z" transform="translate(8203 2822.854)" fill="#f38080" />
                            <path id="Rectangle_16" data-name="Rectangle 16" d="M4.9,1.727A3.176,3.176,0,1,0,8.079,4.9,3.18,3.18,0,0,0,4.9,1.727M4.9,0A4.9,4.9,0,1,1,0,4.9,4.9,4.9,0,0,1,4.9,0Z" transform="translate(8213.854 2822.854)" fill="#f38080" />
                            <path id="Rectangle_14" data-name="Rectangle 14" d="M2.22,1.727a.494.494,0,0,0-.493.493V7.586a.494.494,0,0,0,.493.493H7.586a.494.494,0,0,0,.493-.493V2.22a.494.494,0,0,0-.493-.493H2.22M2.22,0H7.586a2.22,2.22,0,0,1,2.22,2.22V7.586a2.22,2.22,0,0,1-2.22,2.22H2.22A2.22,2.22,0,0,1,0,7.586V2.22A2.22,2.22,0,0,1,2.22,0Z" transform="translate(8213.854 2812)" fill="#f38080" />
                        </g>
                    </svg>
                </ToolTipComponent>
            );
        }
        return (
            <ToolTipComponent title={type} placement="bottom" arrow>
                <svg xmlns="http://www.w3.org/2000/svg" width="17.751" height="16.711" viewBox="0 0 17.751 16.711" className={classes.crumbIcon}>
                    <path id="table" d="M0,15V31.711H17.751V15Zm16.711,1.04v2.115H1.04V16.04ZM9.4,22.315h3.12V24.4H9.4ZM8.355,24.4H5.235v-2.08h3.12Zm1.04-3.12V19.2h3.12v2.08Zm-1.04,0H5.235V19.2h3.12Zm-4.16,0H1.04V19.2H4.2Zm0,1.04V24.4H1.04v-2.08Zm0,3.12v2.08H1.04v-2.08Zm1.04,0h3.12v2.08H5.235Zm3.12,3.12v2.115H5.235V28.556Zm1.04,0h3.12v2.115H9.4Zm0-1.04v-2.08h3.12v2.08Zm4.16-2.08h3.155v2.08H13.556Zm0-1.04v-2.08h3.155V24.4Zm0-3.12V19.2h3.155v2.08ZM1.04,28.556H4.2v2.115H1.04Zm12.516,2.115V28.556h3.155v2.115Z" transform="translate(0 -15)" fill="#FFFFFF" />
                </svg>
            </ToolTipComponent>
        );

    };


    return (
        <Fragment>
            <Grid container className={classes.headerContainer}>
                {
                    sourceBreadCrumb.map((crumb, index) =>
                        <Box key={`crumb_${index}`} className={classes.flexCol}>
                            {getCrumbIcon(crumb.type)}
                            <Typography variant="body2" key={`crumb_${index}`} component="span" className={classes.link} onClick={() => redirect("back")}>
                                {crumb.name}
                                {index !== breadcrumb.length - 1 && ' | '}
                            </Typography>
                        </Box>
                    )
                }
            </Grid>
            <Grid container justify="space-between">
                <Grid item className={classes.datasourceTitle}>
                    <Grid className={sourceDetail && sourceDetail.icon ? classes.sourceIconContainer : ''}>
                        <img src={sourceDetail && sourceDetail.icon ? require(`../../assets/images/sourceIcons/${sourceDetail.icon}.png`).default : Logo} alt="logo" className={classNames(classes.datasourceIcon, classes.datasourceIconSelectImg)} />
                    </Grid>
                    <Typography className={classes.headerTitleTxt}>
                        {sourceDetail.name ? sourceDetail.name : 'Untitled Data Source'}
                    </Typography>
                    <Circular
                        showPercentage={Boolean(sourceDetail.dqscore && sourceDetail.dqscore !== 0)}
                        showValue
                        value={sourceDetail.dqscore}
                        size={40} />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item>
                    <Typography variant="body2" className={`${classes.detailDescriptionTxt} description`}>
                        {sourceDetail.description ? sourceDetail.description : ''}
                    </Typography>
                </Grid>
                <Grid item className={classes.datasourceDetailIcons}>
                    <Typography className={classNames(classes.datasourceDetailText, classes.detailuserTxt)} variant="body2">
                        Users
                    </Typography>
                    <UserList users={getSourceUsers(source.sharing_info ? source.sharing_info : [])} itemCount={3} />
                </Grid>
                <Grid item className={classes.datasourceDetailIcons}>
                    <img className={classes.datasourceIcon} src={ViewIcon} alt="view" />
                    <Typography className={classes.datasourceDetailText} variant="body2">
                        {`${source.views ? source.views : 0} Views`}
                    </Typography>
                </Grid>
            </Grid>
            <Grid className={classNames(classes.datasettabLevel1Container, classes.marginTop15, classes.overflow)}>
                <Grid container justify="space-between" alignItems="center" className={classNames(classes.datasetSearchContainer)}>
                    <Typography component="h5" variant="h5">
                        {source.type === "Tableau" ? "Workbooks" : "Datasets"}
                    </Typography>
                    <Search value={search} placeholder="Search Dataset, Attribute" onChange={(value) => onChangeSearch(value)} />
                </Grid>
                <Grid>
                    <Card className={classNames(classes.tableaccordian, classes.tableWrapperStyle)}>
                        <Table className={`${classes.scrollWidthFix} ${classes.tableStyle}`}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <Typography className={classNames(classes.fontWeight600)}>
                                            {source.type === "Tableau" ? "Workbooks" : "Datasets"}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classNames(classes.fontWeight600)}>
                                            {source.type === "Tableau" ? 'Total Worksheets' : 'Total Attributes'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classes.fontWeight600}>
                                            {source.type === "Tableau" ? 'Total Datasources' : 'Total Records'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classNames(classes.fontWeight600)}>
                                            {'Updated Time'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography className={classNames(classes.fontWeight600)}>
                                            {'Load Type'}
                                        </Typography>
                                    </TableCell>
                                    {
                                        source.type === "Tableau" &&
                                        <TableCell>
                                            <Typography className={classNames(classes.fontWeight600)}>
                                                {'Owner'}
                                            </Typography>
                                        </TableCell>
                                    }
                                    {
                                        source.type !== "Tableau" &&
                                        <TableCell>
                                            <Typography className={classes.fontWeight600}>
                                                {'DQScore'}
                                            </Typography>
                                        </TableCell>
                                    }
                                    {
                                        source.type !== "Tableau" &&
                                        <TableCell className={classes.noPaddingLeft}>
                                            <Typography className={classes.fontWeight600}>
                                                {'Impact Score'}
                                            </Typography>
                                        </TableCell>
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    datasets && datasets.map((dataset, index) =>
                                        <AccordionTable key={index} dataset={dataset} type={source.type} index={index} classes={classes} />
                                    )
                                }
                            </TableBody>
                        </Table>
                    </Card>
                </Grid>

                {isLoading && <Loader />}
            </Grid>
        </Fragment>
    );
};

DatasourceDetails.propTypes = {
    classes: PropTypes.object,
    source: PropTypes.object,
    redirect: PropTypes.func,
    breadcrumb: PropTypes.array
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...DatasourceStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DatasourceDetails);