import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, withStyles } from '@material-ui/core';

import classNames from 'classnames';
import Styles from '../../../layouts/Styles.jsx';
import MasterStyles from '../MasterStyles.jsx';
import AutoCompleteInput from '../../AutoComplete/AutoCompleteInput.jsx';
import { useSelector } from 'react-redux';
import { sortTable } from '../../../helpers/appHelpers.js';


const MatchCombinationInput = (props) => {
    const { classes, matchAttributes, rowIndex, onValueChange } = props;
    const attributes = useSelector(({ master }) => master.attributes);
    const [currentRowIndex, setRowIndex] = useState(-1);
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const [options, setOptions] = useState([]);
    const updateValue = useCallback((value) => {
        if (onValueChange) {
            onValueChange('attributes', value ? [...value] : [...selectedAttributes], rowIndex);
        }
    }, [onValueChange, rowIndex, selectedAttributes]);

    const updateOptions = useCallback((matchAttributes = []) => {
        let attrOptions = attributes ? sortTable(attributes, "asc", "name") : [];
        if (matchAttributes) {
            attrOptions = attrOptions.filter((option) => {
                const targetIndex = matchAttributes.findIndex((attr) => attr === option.name);
                return targetIndex === -1;
            });
        }
        setOptions(attrOptions);
    }, [attributes]);

    const loadAttributes = useCallback((matchAttributes, rowIndex) => {
        setRowIndex(rowIndex);
        setSelectedAttributes([...matchAttributes]);
        updateOptions([...matchAttributes]);
    }, [updateOptions]);

    useEffect(() => {
        if (rowIndex === currentRowIndex) {
            return;
        }
        loadAttributes(matchAttributes, rowIndex);
    }, [currentRowIndex, rowIndex, matchAttributes, loadAttributes]);

    const removeCombination = (value, index, event) => {
        let modifiedAttrList = selectedAttributes;
        modifiedAttrList = modifiedAttrList.filter((d) => d !== value);
        onValueChange('attributes', modifiedAttrList, rowIndex);
        setSelectedAttributes([modifiedAttrList]);
        updateOptions(modifiedAttrList);
    };

    return (
        <AutoCompleteInput
            name="attributes"
            popperWidth={300}
            options={options}
            value={selectedAttributes && selectedAttributes.length > 0 ? [...selectedAttributes] : []}
            getOptionLabel={
                (option) => {
                    if (option && option.name) {
                        return option.name;
                    }
                    return option;
                }
            }
            getOptionSelected={
                (option, value) => {
                    if (option && option.name && value && value.name) {
                        return option.name === value.name;
                    }
                    return option === value;
                }
            }
            minRequiredValues={1}
            renderInput={
                (params) =>
                    <TextField {...params}
                        className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone)}
                        placeholder="Select Attributes"
                        margin="normal"
                        fullWidth
                    />
            }
            onChange={
                (_, selectedValue, reason) => {
                    if (reason === 'remove-option') {
                        updateValue(selectedValue);
                    } else {
                        setSelectedAttributes([...selectedValue]);
                        updateOptions([...selectedValue]);
                    }

                }
            }
            onBlur={() => updateValue()}
            openOnFocus
            disableCloseOnSelect
            disableClearable
            fullWidth
            multiple
            filterSelectedOptions
            forcePopupIcon={false}
            noOptionsText={"No Attributes Found"}
            onDeleteChip={(value, index, event) => removeCombination(value, index, event)}
        />
    );
};

MatchCombinationInput.propTypes = {
    classes: PropTypes.object,
    matchAttributes: PropTypes.array,
    rowIndex: PropTypes.number,
    onValueChange: PropTypes.func
};

export default withStyles((theme) => ({
    ...MasterStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MatchCombinationInput);