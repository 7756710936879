import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import DashboardChartTile from '../Dashboard/DashboardChartTile.jsx';
import { getNotificationAnalysis } from '../../actions/scheduleActions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loaders/Loader.jsx';
import classNames from 'classnames';

const ActionitemMetrics = (props) => {
    const { classes, metricAnalysis, onChangeFilter, filters } = props;
    const [analysis, setAnalysis] = useState({});
    const [isLoading, setLoading] = useState(false);
    const config = useSelector(({ setting }) => setting.config);
    const userRole = useSelector(({ account }) => account.user.role);
    const dispatch = useDispatch();


    const getAnalysis = useCallback(() => {
        setLoading(true);
        dispatch(getNotificationAnalysis({ type: filters.filter })).then((response) => {
            if (response) {
                const timelineAnalysis = {
                    drift: [],
                    schedule: [],
                    permissioning: [],
                    audits: [],
                    actionitem: [],
                    useractivity: [],
                    rule: [],
                    all: []
                };
                for (const analysis of response) {
                    timelineAnalysis.drift.push(analysis.notification_drift_analysis);
                    timelineAnalysis.actionitem.push(analysis.notification_actionitem_analysis);
                    timelineAnalysis.schedule.push(analysis.notification_schedule_analysis);
                    timelineAnalysis.audits.push(analysis.notification_audits_analysis);
                    timelineAnalysis.useractivity.push(analysis.notification_user_activity);
                    timelineAnalysis.all.push(analysis.notification_all_analysis);
                    timelineAnalysis.permissioning.push(analysis.notification_permissioing_analysis);
                    timelineAnalysis.rule.push(analysis.notification_rules_analysis);
                }
                timelineAnalysis.drift = timelineAnalysis.drift.sort((a, b) => new Date(b.date) - new Date(a.date));
                timelineAnalysis.actionitem = timelineAnalysis.actionitem.sort((a, b) => new Date(b.date) - new Date(a.date));
                timelineAnalysis.schedule = timelineAnalysis.schedule.sort((a, b) => new Date(b.date) - new Date(a.date));
                timelineAnalysis.audits = timelineAnalysis.audits.sort((a, b) => new Date(b.date) - new Date(a.date));
                timelineAnalysis.useractivity = timelineAnalysis.useractivity.sort((a, b) => new Date(b.date) - new Date(a.date));
                timelineAnalysis.all = timelineAnalysis.all.sort((a, b) => new Date(b.date) - new Date(a.date));
                timelineAnalysis.permissioning = timelineAnalysis.permissioning.sort((a, b) => new Date(b.date) - new Date(a.date));
                timelineAnalysis.rule = timelineAnalysis.rule.sort((a, b) => new Date(b.date) - new Date(a.date));
                setAnalysis({ ...timelineAnalysis });
            }
            setLoading(false);
        });
    }, [dispatch, filters.filter]);

    useEffect(() => {
        getAnalysis();
    }, [getAnalysis]);
    return (
        <Grid container spacing={1} xs={12} justify="space-between">
            {
                config.action_items &&
                <Grid item className={classNames(userRole && userRole.name !== 'Admin' ? classes.cardMultiSection : classes.cardMultiSections, classes.relative, classes.cursor, isLoading && classes.notificationCardTile)} onClick={() => onChangeFilter("ActionItem")}>
                    {isLoading && <Loader />}
                    <DashboardChartTile chartColor="#FF5C5C" chartClassName={"notification-actionitem-timeline"} type="ActionItem" count={metricAnalysis.actionitem ? metricAnalysis.actionitem : 0} analysis={analysis.actionitem ? analysis.actionitem : []} isHighlight={filters.notification === "ActionItem"} />
                </Grid>
            }
            <Grid item className={classNames(userRole && userRole.name !== 'Admin' ? classes.cardMultiSection : classes.cardMultiSections, classes.relative, classes.cursor, isLoading && classes.notificationCardTile)} onClick={() => onChangeFilter("Permissioning")}>
                {isLoading && <Loader />}
                <DashboardChartTile chartColor="#FF5C5C" chartClassName={"notification-permissiong-timeline"} type="Permissioning" count={metricAnalysis.permissioning ? metricAnalysis.permissioning : 0} analysis={analysis.permissioning ? analysis.permissioning : []} isHighlight={filters.notification === "Permissioning"} />
            </Grid>
            <Grid item className={classNames(userRole && userRole.name !== 'Admin' ? classes.cardMultiSection : classes.cardMultiSections, classes.relative, classes.cursor, isLoading && classes.notificationCardTile)} onClick={() => onChangeFilter("Audit Log")}>
                {isLoading && <Loader />}
                <DashboardChartTile chartColor="#FDAC42" chartClassName={"notification-audits-timeline"} type="AuditLogs" count={metricAnalysis.audit ? metricAnalysis.audit : 0} analysis={analysis.audits ? analysis.audits : []} isHighlight={filters.notification === "Audit Log"} />
            </Grid>
            {
                userRole && userRole.name === 'Admin' &&
                <Grid item className={classNames(classes.relative, classes.cursor, isLoading && classes.notificationCardTile, userRole && userRole.name !== 'Admin' ? classes.cardMultiSection : classes.cardMultiSections)} onClick={() => onChangeFilter("UserActivity")}>
                    {isLoading && <Loader />}
                    <DashboardChartTile chartColor="#FF5C5C" chartClassName={"notification-rule-timeline"} type="UserActivity" count={metricAnalysis.useractivity ? metricAnalysis.useractivity : 0} analysis={analysis.useractivity ? analysis.useractivity : []} isHighlight={filters.notification === "UserActivity"} />
                </Grid>
            }
            <Grid item className={classNames(userRole && userRole.name !== 'Admin' ? classes.cardMultiSection : classes.cardMultiSections, classes.relative, classes.cursor, isLoading && classes.notificationCardTile)} onClick={() => onChangeFilter("Schedule")}>
                {isLoading && <Loader />}
                <DashboardChartTile chartColor="#54DF9A" chartClassName={"notification-schedule-timeline"} type="Schedule" count={metricAnalysis.schedule ? metricAnalysis.schedule : 0} analysis={analysis.schedule ? analysis.schedule : []} isHighlight={filters.notification === "Schedule"} />
            </Grid>
            {
                config.drift &&
                <Grid item className={classNames(userRole && userRole.name !== 'Admin' ? classes.cardMultiSection : classes.cardMultiSections, classes.relative, classes.cursor, isLoading && classes.notificationCardTile)} onClick={() => onChangeFilter("Drift")}>
                    {isLoading && <Loader />}
                    <DashboardChartTile chartColor="#FF5C5C" chartClassName={"notification-drift-timeline"} type="Drift" count={metricAnalysis.drift ? metricAnalysis.drift : 0} analysis={analysis.drift ? analysis.drift : []} isHighlight={filters.notification === "Drift"} />
                </Grid>
            }
            <Grid item className={classNames(userRole && userRole.name !== 'Admin' ? classes.cardMultiSection : classes.cardMultiSections, classes.relative, classes.cursor, isLoading && classes.notificationCardTile)} onClick={() => onChangeFilter("Rule")}>
                {isLoading && <Loader />}
                <DashboardChartTile chartColor="#FF5C5C" chartClassName={"notification-rule-timeline"} type="Rule" count={metricAnalysis.rule ? metricAnalysis.rule : 0} analysis={analysis.rule ? analysis.rule : []} isHighlight={filters.notification === "Rule"} />
            </Grid>
            <Grid item className={classNames(userRole && userRole.name !== 'Admin' ? classes.cardMultiSection : classes.cardMultiSections, classes.relative, classes.cursor, isLoading && classes.notificationCardTile)} onClick={() => onChangeFilter("All")}>
                {isLoading && <Loader />}
                <DashboardChartTile chartColor="#CBECFC" chartClassName={"notification-all-timeline"} type="All" count={metricAnalysis.all ? metricAnalysis.all : 0} analysis={analysis.all ? analysis.all : []} isHighlight={filters.notification === "All"} />
            </Grid>
        </Grid>
    );
};

ActionitemMetrics.propTypes = {
    classes: PropTypes.object,
    // analysis: PropTypes.object
    metricAnalysis: PropTypes.object,
    onChangeFilter: PropTypes.func,
    filters: PropTypes.object
};

export default withStyles((theme) => ({
    // ...ActionItemStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ActionitemMetrics);