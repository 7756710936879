
export const request = (type, params) => {
	return {
		type,
		params
	};
};

export const success = (type, responseData) => {
	return {
		type,
		responseData
	};
};

export const failure = (type, error) => {
	if (error && error.toString().toLowerCase().includes('aborterror')) {
		return {
			type
		};
	}
	return {
		type,
		error
	};
};