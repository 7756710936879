import { appConstants } from '../constants/appConstants.js';
import { request, success, failure } from './baseActions';
import { datasetActions } from '../constants/actionTypes/datasetActionTypes';
import * as service from '../services/apiService';
import { alertActions } from '../constants/actionTypes/alertActionTypes';
import { alerts } from '../config/alerts';

export const setSelectedDatasetId = (datasetId) => {
    return {
        type: datasetActions.SET_SELECTED_DATASET_ID,
        datasetId
    };
};

export const setSelectedDataset = (dataset) => {
    return {
        type: datasetActions.SET_SELECTED_DATASET,
        dataset
    };
};

export const setPropertySliderIndex = (index) => {
    return {
        type: datasetActions.SET_PROPERTY_SLIDER_INDEX,
        index
    };
};

export const setPropertyView = (view) => {
    return {
        type: datasetActions.SET_PROPERTY_VIEW,
        view
    };
};

export const getDataSetDetail = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getdatasetDetail}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_DATASET_DETAIL_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_DATASET_DETAIL_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_DATASET_DETAIL_FAILURE, error));
        });
    };
};

export const updateDataset = (datasetId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.updatedatasetDetail}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.UPDATE_DATASET_DETAIL_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasetActions.UPDATE_DATASET_DETAIL_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(datasetActions.UPDATE_DATASET_DETAIL_FAILURE, error));
        });
    };
};

export const updateTableauItem = (itemId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.updateTableauItem}/${itemId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.UPDATE_TABLEAU_ITEM_DETAIL_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasetActions.UPDATE_TABLEAU_ITEM_DETAIL_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(datasetActions.UPDATE_TABLEAU_ITEM_DETAIL_FAILURE, error));
        });
    };
};

export const getDatasetShare = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDatasetShare}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_DATASET_SHARE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_DATASET_SHARE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_DATASET_SHARE_FAILURE, error));
        });
    };
};

export const createDatasetShare = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.createDatasetShare}`;
    return (dispatch) => {
        dispatch(request(datasetActions.CREATE_DATASET_SHARE_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasetActions.CREATE_DATASET_SHARE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.CREATE_DATASET_SHARE_FAILURE, error));
        });
    };
};


export const getPreviewData = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getPreviewData}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_PREVIEW_DATA_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_PREVIEW_DATA_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_PREVIEW_DATA_FAILURE, error));
        });
    };
};

export const getAllAttributeStats = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getAllAttributeStats}/${datasetId}`;
    return () => {
        return service.getRequest(endpoint);
    };
};

export const getProperties = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getProperties}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_PROPERTIES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_PROPERTIES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_PROPERTIES_FAILURE, error));
        });
    };
};

export const getDatasetInfo = (datasourceId, datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDatasetInfo}/${datasourceId}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_DATASET_INFO_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            let datasetSize = 0;
            if (response.data && response.data.size) {
                for (let fileSize of response.data.size) {
                    if (fileSize) {
                        fileSize = fileSize.toString().split()[0];
                        fileSize = parseFloat(fileSize);
                        datasetSize += fileSize;
                    }
                }
            }
            response.data.fileSize = datasetSize;
            dispatch(success(datasetActions.GET_DATASET_INFO_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_DATASET_INFO_FAILURE, error));
        });
    };
};

export const deleteDataSet = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.deleteDataset}`;
    return (dispatch) => {
        dispatch(request(datasetActions.DELETE_DATASET_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasetActions.DELETE_DATASET_SUCCESS, response));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.deleteDataSet));
            return response.success;
        }, (error) => {
            dispatch(failure(datasetActions.DELETE_DATASET_FAILURE, error));
        });
    };
};

export const updateProperties = (datasetId, updatedProperty) => {
    const endpoint = `${appConstants.END_POINTS.catalog.updateProperties}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.UPDATE_PROPERTY_REQUEST));
        return service.postRequest(endpoint, updatedProperty).then((response) => {
            dispatch(success(datasetActions.UPDATE_PROPERTY_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.UPDATE_PROPERTY_FAILURE, error));
        });
    };
};

export const getDriftCustomRule = () => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDriftCustomRule}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_DRIFT_CUSTOM_RULES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_DRIFT_CUSTOM_RULES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_DRIFT_CUSTOM_RULES_FAILURE, error));
        });
    };
};

export const getDatasetRules = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDatasetRules}/${datasetId}`;

    return (dispatch) => {
        dispatch(request(datasetActions.GET_DATASET_RULES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            const rules = response && response.data ? response.data : {};
            dispatch(success(datasetActions.GET_DATASET_RULES_SUCCESS, rules));
            return rules;
        }, (error) => {
            dispatch(failure(datasetActions.GET_DATASET_RULES_FAILURE, error));
        });
    };
};

export const updateDatasetRules = (datasetId, ruleInput) => {
    const endpoint = `${appConstants.END_POINTS.catalog.updateDatasetRules}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.UPDATE_DATASET_RULES_REQUEST));
        return service.postRequest(endpoint, ruleInput).then((response) => {
            const rules = response && response.data ? response.data : {};
            dispatch(success(datasetActions.UPDATE_DATASET_RULES_SUCCESS, rules));
            return rules;
        }, (error) => {
            dispatch(failure(datasetActions.UPDATE_DATASET_RULES_FAILURE, error));
        });
    };
};


export const updateAttributes = (attributesInfo) => {
    const endpoint = `${appConstants.END_POINTS.catalog.updateAttributes}`;
    return (dispatch) => {
        dispatch(request(datasetActions.UPDATE_ATTRIBUTES_REQUEST));
        return service.putRequest(endpoint, attributesInfo).then((response) => {
            dispatch(success(datasetActions.UPDATE_ATTRIBUTES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.UPDATE_ATTRIBUTES_FAILURE, error));
        });
    };
};


export const filterInputData = (filerInput) => {
    const endpoint = `${appConstants.END_POINTS.catalog.filterInput}`;
    return (dispatch) => {
        dispatch(request(datasetActions.FILTER_INPUT_REQUEST));
        return service.postRequest(endpoint, filerInput).then((response) => {
            dispatch(success(datasetActions.FILTER_INPUT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.FILTER_INPUT_FAILURE, error));
        });
    };
};


export const updateDatasetIcon = (datasetInfo) => {
    const endpoint = `${appConstants.END_POINTS.catalog.updateDataSetIcon}`;
    return (dispatch) => {
        dispatch(request(datasetActions.UPDATE_DATASET_ICON_REQUEST));
        return service.putRequest(endpoint, datasetInfo).then((response) => {
            dispatch(success(datasetActions.UPDATE_DATASET_ICON_SUCCESS, response));
        }, (error) => {
            dispatch(failure(datasetActions.UPDATE_DATASET_ICON_FAILURE, error));
        });
    };
};

export const updateRuleAttributes = (requestParams) => {
    return {
        type: datasetActions.UPDATE_RULE_ATTRIBUTES,
        responseData: requestParams
    };
};

export const updateListProperties = (datasetId, updatedProperty) => {
    const endpoint = `${appConstants.END_POINTS.catalog.updateListProperties}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.UPDATE_LIST_PROPERTY_REQUEST));
        return service.postRequest(endpoint, updatedProperty).then((response) => {
            dispatch(success(datasetActions.UPDATE_LIST_PROPERTY_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.UPDATE_LIST_PROPERTY_FAILURE, error));
        });
    };
};

export const updateListDatasetRules = (datasetId, ruleInput) => {
    const endpoint = `${appConstants.END_POINTS.catalog.updateDatasetListRules}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.UPDATE_LIST_DATASET_RULES_REQUEST));
        return service.postRequest(endpoint, ruleInput).then((response) => {
            const rules = response && response.data ? response.data : {};
            dispatch(success(datasetActions.UPDATE_LIST_DATASET_RULES_SUCCESS, rules));
            return rules;
        }, (error) => {
            dispatch(failure(datasetActions.UPDATE_LIST_DATASET_RULES_FAILURE, error));
        });
    };
};

export const updateDatasetProperties = (requestParams) => {
    return {
        type: datasetActions.UPDATE_DATASET_PROPERTIES,
        responseData: requestParams
    };
};

export const updateLearningAttribute = (requestParams) => {
    return {
        type: datasetActions.UPDATE_LEARNING_ATTRIBUTE,
        responseData: requestParams
    };
};

export const getDatasetNames = (datasourceId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDatasetNames}/${datasourceId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_DATASET_NAMES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_DATASET_NAMES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_DATASET_NAMES_FAILURE, error));
        });
    };
};

export const getUserDatasets = (datasourceId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getUserAttributes}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_USER_DATASETS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_USER_DATASETS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_USER_DATASETS_FAILURE, error));
        });
    };
};

export const getUserAttributes = (datasourceId, cancellationToken = null) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getUserAttributes}/${datasourceId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_USER_ATTRIBUTES_REQUEST));
        return service.getRequest(endpoint, cancellationToken).then((response) => {
            dispatch(success(datasetActions.GET_USER_ATTRIBUTES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_USER_ATTRIBUTES_FAILURE, error));
        });
    };
};

export const getOrganizationDatasets = () => {
    const endpoint = `${appConstants.END_POINTS.catalog.getOrganizationDatasets}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_ORGANIZATION_DATASETS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_ORGANIZATION_DATASETS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_ORGANIZATION_DATASETS_FAILURE, error));
        });
    };
};

export const validateQuery = (filerInput) => {
    const endpoint = `${appConstants.END_POINTS.catalog.filterInput}`;
    return (dispatch) => {
        dispatch(request(datasetActions.VALIDATE_QUERY_REQUEST));
        return service.postRequest(endpoint, filerInput).then((response) => {
            dispatch(success(datasetActions.VALIDATE_QUERY_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.VALIDATE_QUERY_FAILURE, error));
            dispatch(success(alertActions.ALERT_ERROR, error));
        });
    };
};

export const getDatasetAttributes = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDatasetAttributes}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_DATASET_ATTRIBUTE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_DATASET_ATTRIBUTE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_DATASET_ATTRIBUTE_FAILURE, error));
        });
    };
};

export const datasetPropertiesTab = (index) => {
    return {
        type: datasetActions.GET_DATASET_PROPERTIES_TAB,
        responseData: index
    };
};

export const loadDataset = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.loadDataset}/${datasetId}`;
    return () => {
        return service.getRequest(endpoint);
    };
};

export const getOrganizationDatasetList = () => {
    const endpoint = `${appConstants.END_POINTS.catalog.getOrganizationDatasetList}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_ORGANIZATION_DATASET_LIST_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_ORGANIZATION_DATASET_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_ORGANIZATION_DATASET_LIST_FAILURE, error));
        });
    };
};

export const getOrganizationAttributeList = () => {
    const endpoint = `${appConstants.END_POINTS.catalog.getOrganizationAttributeList}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_ORGANIZATION_ATTRIBUTE_LIST_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            const attributes = response.data ? response.data.map((attribute) => {
                return {
                    ...attribute,
                    attribute_name: attribute.name,
                    name: `${attribute.source_name}.${attribute.dataset_name}.${attribute.name}`
                };
            }) : [];
            dispatch(success(datasetActions.GET_ORGANIZATION_ATTRIBUTE_LIST_SUCCESS, attributes));
            return attributes;
        }, (error) => {
            dispatch(failure(datasetActions.GET_ORGANIZATION_ATTRIBUTE_LIST_FAILURE, error));
        });
    };
};

export const getAllMonitoringDetails = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getAllMonitoringDetails}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_ALL_MONITORING_DETAILS));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_ALL_MONITORING_DETAILS_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_ALL_MONITORING_DETAILS_FAILURE, error));
        });
    };
};

export const getExportAttributeRules = (datasource_id, datasetId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getExportAttribute}/${datasource_id}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_EXPORT_ATTRIBUTE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_EXPORT_ATTRIBUTE_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_EXPORT_ATTRIBUTE_FAILURE, error));
        });
    };
};

export const getExportMetricsSettingDetails = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.schedule.getExportMetricsSetting}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_EXPORT_METRIXS_SETTINGS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_EXPORT_METRIXS_SETTINGS_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_EXPORT_METRIXS_SETTINGS_FAILURE, error));
        });
    };
};

export const saveExportMetricsSettingDetails = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.schedule.saveExportMetricsSetting}`;
    return (dispatch) => {
        dispatch(request(datasetActions.SAVE_EXPORT_METRIXS_SETTINGS_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            if (response) {
                dispatch(success(datasetActions.SAVE_EXPORT_METRIXS_SETTINGS_SUCCESS, response.data));
                dispatch(success(alertActions.ALERT_SUCCESS, "Details Saved Sucessfully."));
                return response.data;
            }
        }, (error) => {
            dispatch(failure(datasetActions.SAVE_EXPORT_METRIXS_SETTINGS_FAILURE, error));
        });
    };
};

export const checkExportMetricsSettingName = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.schedule.checkExportMetricsSettingName}`;
    return (dispatch) => {
        dispatch(request(datasetActions.SAVE_EXPORT_METRIXS_SETTINGS_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            if (response) {
                dispatch(success(datasetActions.SAVE_EXPORT_METRIXS_SETTINGS_SUCCESS, response.data));
                return response.data;
            }
        }, (error) => {
            dispatch(failure(datasetActions.SAVE_EXPORT_METRIXS_SETTINGS_FAILURE, error));
        });
    };
};

export const removeExportMetricsSettingDetails = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.schedule.removeExportMetricsSetting}`;
    return (dispatch) => {
        dispatch(request(datasetActions.REMOVE_EXPORT_METRIXS_SETTINGS_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            if (response) {
                dispatch(success(datasetActions.REMOVE_EXPORT_METRIXS_SETTINGS_SUCCESS, response.data));
                dispatch(success(alertActions.ALERT_SUCCESS, "Removed Table Name Sucessfully."));
                return response.data;
            }
        }, (error) => {
            dispatch(failure(datasetActions.REMOVE_EXPORT_METRIXS_SETTINGS_FAILURE, error));
        });
    };
};


export const clearPreviewData = () => {
    return {
        type: datasetActions.CLEAR_PREVIEW_DATA
    };
};


export const getPropertiesList = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getPropertiesList}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_PROPERTIES_LIST_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_PROPERTIES_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_PROPERTIES_LIST_FAILURE, error));
        });
    };
};

export const getAttributePropertiesList = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getAttributePropertiesList}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_ATTRIBUTE_PROPERTIES_LIST_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_ATTRIBUTE_PROPERTIES_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_ATTRIBUTE_PROPERTIES_LIST_FAILURE, error));
        });
    };
};


export const getAttributeProperties = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getAttributeProperties}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_ATTRIBUTE_PROPERTIES_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            if (response) {
                dispatch(success(datasetActions.GET_ATTRIBUTE_PROPERTIES_SUCCESS, response.data));
                return response.data;
            }
        }, (error) => {
            dispatch(failure(datasetActions.GET_ATTRIBUTE_PROPERTIES_FAILURE, error));
        });
    };
};

export const getAttributeRules = (attributeId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getAttributeRules}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_ATTRIBUTE_RULES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            const rules = response && response.data ? response.data : {};
            dispatch(success(datasetActions.GET_ATTRIBUTE_RULES_SUCCESS, rules));
            return rules;
        }, (error) => {
            dispatch(failure(datasetActions.GET_ATTRIBUTE_RULES_FAILURE, error));
        });
    };
};


export const updateDatasetQuery = (query) => {
    const endpoint = `${appConstants.END_POINTS.catalog.updateDatasetQuery}`;
    return (dispatch) => {
        dispatch(request(datasetActions.UPDATE_DATASET_QUERY_REQUEST));
        return service.putRequest(endpoint, query).then((response) => {
            dispatch(success(datasetActions.UPDATE_DATASET_QUERY_SUCCESS, response.data));
        }, (error) => {
            dispatch(failure(datasetActions.UPDATE_DATASET_QUERY_FAILURE, error));
        });
    };
};

export const getWorkbookItems = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getWorkbookItems}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_WORKBOOK_ITEMS_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasetActions.GET_WORKBOOK_ITEMS_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_WORKBOOK_ITEMS_FAILURE, error));
        });
    };
};

export const getWorkbookDatasource = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getWorkbookDatasource}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_WORKBOOK_DATASOURCE_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasetActions.GET_WORKBOOK_DATASOURCE_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_WORKBOOK_DATASOURCE_FAILURE, error));
        });
    };
};

export const deleteWorkbookItems = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.deleteWorkbookItems}`;
    return (dispatch) => {
        dispatch(request(datasetActions.DELETE_WORKBOOK_ITEMS_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasetActions.DELETE_WORKBOOK_ITEMS_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.DELETE_WORKBOOK_ITEMS_FAILURE, error));
        });
    };
};

export const getWorkbookInfo = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getWorkbookInfo}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_WORKBOOK_INFO_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasetActions.GET_WORKBOOK_INFO_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_WORKBOOK_INFO_FAILURE, error));
        });
    };
};

export const getExportRulesValidity = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getExportRulesValidity}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_EXPORT_RULES_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(datasetActions.GET_EXPORT_RULES_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(datasetActions.GET_EXPORT_RULES_FAILURE, error));
        });
    };
};

export const getDriftAttributes = () => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDriftAttributes}`;
    return (dispatch) => {
        dispatch(request(datasetActions.GET_DRIFT_ATTRIBUTES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(datasetActions.GET_DRIFT_ATTRIBUTES_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(datasetActions.GET_DRIFT_ATTRIBUTES_FAILURE, error));
        });
    };
};

export const updateTabIndex = (index) => {
    return (dispatch) => {
        dispatch(request(datasetActions.UPDATE_TABINDEX_REQUEST));
        dispatch(success(datasetActions.UPDATE_TABINDEX_SUCCESS, index));
    };
};

export const updatePropertyTabIndex = (index) => {
    return (dispatch) => {
        dispatch(request(datasetActions.UPDATE_PROPERTY_TABINDEX_REQUEST));
        dispatch(success(datasetActions.UPDATE_PROPERTY_TABINDEX_SUCCESS, index));
    };
};

export const setAttributeName = (attributename) => {
    return {
        type: datasetActions.SET_SELECTED_ATTRIBUTE_NAME,
        attributename
    };
};