import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography, Button, MenuItem } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import Loader from '../Loaders/Loader.jsx';
import CheckboxComponent from '../ChecboxComponent/CheckboxComponent.jsx';

import { getRoleConfiguration, createRole, editRole } from '../../actions/settingActions';
import { appConstants } from '../../constants/appConstants.js';
import { sortTable } from '../../helpers/appHelpers.js';


const RoleConfiguration = (props) => {

    const { classes, organizationId, roleId, roles, onClose, issuperorganization } = props;
    const [isLoading, setLoading] = useState(false);
    const [isPageLoading, setPageLoading] = useState(false);
    const defaultRole = { name: "", description: "", "organization_id": organizationId, "properties_permission": { "is_edit": false, "is_delete": false, "is_view": true }, "page_permission": "Except Setting", "governance_permission": "Level 1", accessInfo: ["View"] };
    const [role, setRole] = useState({ ...defaultRole });
    const [roleData, setRoleId] = useState(roleId);
    const [unChangedConfig, setUnChangedConfig] = useState({ ...defaultRole });
    const [nameError, setError] = useState(false);
    const [isChanged, setChanged] = useState(false);
    const userRole = useSelector(({ account }) => account.user.role);
    const dispatch = useDispatch();

    const onChangeRoleConfig = (property, value) => {
        role[property] = value;
        if (property === "accessInfo") {
            if ((role[property].includes("Edit") && !role[property].includes("View")) || (role[property].includes("Delete") && !role[property].includes("View"))) {
                role[property].push("View");
            }
        }
        setChanged(true);
        setRole({ ...role });
    };

    const prepareParams = () => {
        const requestParams = { ...role };
        requestParams.properties_permission = {
            "is_edit": requestParams.accessInfo.includes("Edit"),
            "is_view": requestParams.accessInfo.includes("View"),
            "is_delete": requestParams.accessInfo.includes("Delete")
        };
        delete requestParams.accessInfo;
        return requestParams;
    };
    const updateRoleConfig = () => {
        if (!nameError) {
            setLoading(true);
            const requestParams = prepareParams();
            dispatch(editRole(roleId, requestParams)).then(() => {
                setLoading(false);
                setChanged(false);
            });
        }
    };

    const createNewRole = () => {
        if (!nameError) {
            setLoading(true);
            const requestParams = prepareParams();
            dispatch(createRole(requestParams)).then((response) => {
                if (response) {
                    setRole({ ...response });
                    setRoleId(response.id);
                    setChanged(false);
                }
                setLoading(false);
            });
        }
    };

    const validateName = () => {
        if (unChangedConfig.name !== role.name) {
            const validate = roles.filter((roleInfo) => roleInfo.name.toLowerCase() === role.name.toLowerCase()).length !== 0 && role.name !== "";
            setError(validate);
        } else {
            setError(false);
        }
    };

    const getRoles = useCallback(() => {
        setPageLoading(true);
        dispatch(getRoleConfiguration(roleData)).then((response) => {
            if (response) {
                const permission = [];
                for (const data of Object.keys(response.properties_permission)) {
                    if (response.properties_permission[data]) {
                        let permissionType = "Edit";
                        if (data === "is_view") {
                            permissionType = "View";
                        } else if (data === "is_delete") {
                            permissionType = "Delete";
                        }
                        permission.push(permissionType);
                    }
                }
                response.accessInfo = permission;
                setRole({ ...response });
                setUnChangedConfig({ ...response });
            }
            setPageLoading(false);
        });

    }, [dispatch, roleData]);

    useEffect(() => {
        getRoles();
    }, [getRoles]);

    return (
        <Grid className={classes.userContainer}>
            <Typography variant="h5" component="h5">
                {!roleData ? "New Role" : "Edit Role"}
            </Typography>
            <ValidatorForm onSubmit={() => (roleData ? updateRoleConfig() : createNewRole())} className={classes.marginTop20}>
                <Grid container spacing={4}>
                    <Grid item xs={4} className={classes.userRow}>
                        <TextBox
                            label="Enter Role Name"
                            value={role.name ? role.name : ""}
                            name="name"
                            fullWidth
                            validators={['required']}
                            errorMessages={['Name is required']}
                            onChange={(event) => onChangeRoleConfig(event.target.name, event.target.value)}
                            onBlur={() => validateName()}
                            disabled={isLoading}
                            error={nameError}
                            helperText={nameError ? "Name is duplicate" : ""}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextBox
                            label="Description"
                            value={role.description ? role.description : ""}
                            name="description"
                            fullWidth
                            rows="2"
                            multiline
                            onChange={(event) => onChangeRoleConfig(event.target.name, event.target.value)}
                            disabled={isLoading || (role.name && role.name.toLowerCase() === "admin") || (userRole.name && userRole.name.toLowerCase() !== "admin")}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextBox
                            label="Governance"
                            name="governance_permission"
                            fullWidth
                            select
                            onChange={(event) => onChangeRoleConfig("governance_permission", event.target.value)}
                            value={role.governance_permission ? role.governance_permission : appConstants.levelPermission[2]}
                            className={classes.inlineTxt}
                            disabled={((role.name && role.name.toLowerCase() === "admin") || (userRole.name && userRole.name.toLowerCase() !== "admin"))}
                            SelectProps={
                                {
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }
                                    // IconComponent: () => DropDownIcon()
                                }
                            }
                        >
                            {
                                sortTable(appConstants.levelPermission, "asc", "name").map((option, index) => (
                                    <MenuItem key={`menuProperty_Options_${index}`} value={option} className={classes.menuItem}>
                                        {option}
                                    </MenuItem>
                                ))
                            }
                        </TextBox>
                    </Grid>
                    <Grid item xs={4}>
                        <TextBox
                            label="Page"
                            name="page_permission"
                            fullWidth
                            select
                            onChange={(event) => onChangeRoleConfig("page_permission", event.target.value)}
                            value={role.page_permission ? role.page_permission : appConstants.pagePermission[1]}
                            className={classes.inlineTxt}
                            disabled={((role.name && role.name.toLowerCase() === "admin") || (userRole.name && userRole.name.toLowerCase() !== "admin"))}
                            SelectProps={
                                {
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }
                                    // IconComponent: () => DropDownIcon()
                                }
                            }
                        >
                            {
                                sortTable(appConstants.pagePermission, "asc", "name").map((option, index) => (
                                    <MenuItem key={`menuProperty_Options_${index}`} value={option} className={classes.menuItem}>
                                        {option}
                                    </MenuItem>
                                ))
                            }
                        </TextBox>
                    </Grid>
                    <Grid item xs={4}>
                        <TextBox
                            label="Properties"
                            name="accessInfo"
                            fullWidth
                            select
                            className={classes.inlineTxt}
                            disabled={((role.name && role.name.toLowerCase() === "admin") || (userRole.name && userRole.name.toLowerCase() !== "admin"))}
                            SelectProps={
                                {
                                    multiple: true,
                                    value: role.accessInfo ? role.accessInfo : [],
                                    onChange: (event) => onChangeRoleConfig("accessInfo", event.target.value),
                                    renderValue: (selected) => selected.join(', '),
                                    disabled: ((role.name && role.name.toLowerCase() === "admin") || (userRole.name && userRole.name.toLowerCase() !== "admin")),
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }
                                    // IconComponent: () => DropDownIcon()
                                }
                            }
                        >
                            {
                                ["Delete", "Edit", "View"].map((data, index) =>
                                    <MenuItem key={index} value={data}>
                                        <CheckboxComponent checked={role.accessInfo && role.accessInfo.includes(data)} name={data} checkboxLabel={data} />
                                    </MenuItem>
                                )
                            }
                        </TextBox>
                    </Grid>
                </Grid>
                {
                    issuperorganization &&
                    <Grid
                        item
                        xs={8}
                        style={
                            {
                                paddingTop: 16
                            }
                        }
                    >
                        <CheckboxComponent
                            checked={role.logs_permission}
                            name={'logs_permission'}
                            checkboxLabel={'Error Logs'}
                            onChange={(event) => onChangeRoleConfig(event.target.name, event.target.checked)}
                        />
                    </Grid>
                }
                <Grid container alignItems="center" className={classes.marginTop20}>
                    {
                        isChanged &&
                        <Button variant="contained" color="primary" type="submit" className={classNames(classes.actionButtons, classes.submitBtn)}>
                            Save
                            {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                        </Button>
                    }
                    <Button onClick={() => onClose()} className={classNames(classes.cancelButton, classes.actionButtons)}>
                        Cancel
                    </Button>
                </Grid>
            </ValidatorForm>
            {
                isPageLoading &&
                <Loader />
            }
        </Grid>
    );

};

RoleConfiguration.propTypes = {
    classes: PropTypes.object,
    organizationId: PropTypes.number,
    issuperorganization: PropTypes.bool,
    roles: PropTypes.array,
    roleId: PropTypes.number,
    onClose: PropTypes.func
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(RoleConfiguration);