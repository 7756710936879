const DatasourceStyles = (theme) => ({
    root: {
        flexGrow: '1 auto',
        height: '100%'
    },
    datasetHeaderTitleCell: {
        width: "210px"
    },
    datasetText: {
        color: theme.palette.grey.main,
        marginTop: 3
    },
    highlight_source2: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    marginBottom5: {
        marginBottom: 5
    },
    marginTop5: {
        marginTop: 5
    },
    marginLeft5: {
        marginLeft: 5
    },
    pl15: {
        paddingLeft: 15
    },
    datasetSourceIcon: {
        display: 'flex',
        alignItems: 'center',
        '& img': {
            marginRight: 10,
            height: 40
        }
    },
    datasourceImpactscore: {
        '& .MuiSvgIcon-root': {
            margin: '0 - 5px',
            fontSize: '2rem'
        }
    },
    datasetHeaderContainer: {
        padding: '0px 0px',
        alignItems: 'center',
        margin: '0px 0px',
        position: 'relative',
        '& .MuiTypography-body1': {
            fontWeight: 500,
            color: theme.palette.grey.darkTxt
        }
    },
    paddingLeft6: {
        paddingLeft: "6px !important"
    },
    datasetTitleSection: {
        '& > .checkbox ': {
            paddingLeft: 3
        },
        display: "flex",
        alignItems: "center",
        '& .MuiCheckbox-root': {
            padding: '0px 9px'
        }
    },
    /*
     * timeSection: {
     *     maxWidth: "11.33333%",
     *     flexBasis: "11.33333%"
     * },
     */
    accordian: {
        '& .MuiAccordionSummary-root': {
            padding: "0px 13px",
            border: `1px solid ${theme.palette.border.normal}`,
            minHeight: "50px",
            boxShadow: "0px 0px 2px #0000001A",
            position: "relative",
            marginBottom: 10
        },
        '& .MuiAccordionSummary-expandIcon': {
            position: "absolute",
            right: 5
        },
        '& .MuiAccordionSummary-content': {
            margin: 0
        },
        '& .MuiAccordionDetails-root': {
            padding: "2px 15px 15px 15px",
            background: theme.palette.background.default,
            border: `1px solid ${theme.palette.grey.extraLight}`
        },
        '& .MuiTableCell-root': {
            borderBottom: 0,
            padding: 10
        },
        '& .MuiTableCell-head': {
            color: theme.palette.grey.dark,
            fontSize: 13,
            fontFamily: theme.palette.textFont
        },
        '& .MuiTableBody-root .MuiTableRow-root': {
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.grey.extraLight}`
        },
        '& .MuiTableCell-body': {
            fontSize: 13,
            color: theme.palette.grey.darkTxt
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            marginBottom: '0px'
        },
        '& .MuiAccordion-root:before': {
            backgroundColor: 'transparent'
        }
    },
    tableaccordian: {
        // overflowX: "auto",
        '& .datasetTableHeader': {
            visibility: "collapse"
        },
        '& .datasetTableHeaderAccordions:first-child .datasetTableHeader': {
            visibility: "visible"
        },
        overflow: 'initial',
        '& .MuiAccordionSummary-root': {
            minWidth: "90px",
            padding: "0px 0px",
            minHeight: "40px",
            position: "relative"
            // marginBottom: 10
        },
        '& .MuiAccordionSummary-expandIcon': {
            position: "absolute",
            right: 5
        },
        '& .MuiAccordionSummary-content': {
            margin: 0
        },
        '& .MuiAccordionDetails-root': {
            padding: "2px 15px 15px 15px",
            background: theme.palette.background.default,
            border: `1px solid ${theme.palette.grey.extraLight}`
        },
        '& .MuiTableCell-root': {
            borderBottom: 0,
            padding: 10
        },
        '& .MuiTableCell-head': {
            // color: theme.palette.grey.dark,
            fontWeight: 500,
            fontSize: 13,
            fontFamily: theme.palette.textFont,
            minWidth: 50,
            '& .checkboxlabel': {
                fontWeight: 600
            }
        },
        '& .MuiTableBody-root .MuiTableRow-root': {
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.grey.extraLight}`
        },
        '& .MuiTableCell-body': {
            fontSize: 13,
            color: theme.palette.grey.darkTxt
        },
        '& .MuiAccordion-root.Mui-expanded': {
            margin: "0px !important"
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            marginBottom: '0px'
        },
        '& .MuiAccordion-root:before': {
            backgroundColor: 'transparent'
        }
    },
    datasetTableAccordHeader: {
        '& .MuiTypography-body1': {
            fontWeight: 400
        },
        '& .MuiTableCell-body': {
            padding: '2px 10px'
        }
    },
    chip: {
        marginTop: 5,
        marginBottom: 5,
        '& .MuiChip-label': {
            maxWidth: 200
        }
    },
    closeIcon: {
        width: 15,
        height: 15
    },
    datasetTabText: {
        fontSize: 13,
        color: theme.palette.grey.darkTxt
    },
    attributesTable: {
        maxHeight: 'calc(100vh - 236px)',
        overflow: 'auto',
        maxWidth: '100%',
        '& .MuiTableCell-root': {
            borderBottom: '1px solid #e1e5e6',
            padding: 10
        },
        '& .attributesTableChartRow': {
            backgroundColor: `${theme.palette.background.default} !important`
        }
    },
    attributeTitle: {
        '& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
            fontWeight: 500
        }
    },
    attributesTableChartRow: {
        height: '60px',
        backgroundColor: `${theme.palette.background.default} !important`
    },
    attributeTableDataType: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textboxContainer: {
        '& .MuiInput-underline:before': {
            height: '36px !important'
        },
        '& input': {
            width: 200,
            height: '36px !important',
            fontFamily: theme.palette.textFont
        }
    },
    datasettabContainer: {
        backgroundColor: theme.palette.background.paper,
        padding: '15px 15px 15px 15px',
        // height: '100%',
        position: 'relative',
        height: 'calc(100vh - 190px)',
        overflowY: 'auto'
    },
    datasettabLevel1Container: {
        backgroundColor: theme.palette.background.paper,
        padding: '15px 15px 15px 15px',
        height: 'calc(100vh - 140px)',
        position: 'relative'
    },
    datasetName: {
        fontSize: 13,
        color: theme.palette.grey.darkTxt,
        // maxWidth: 400,
        display: "block",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        cursor: "pointer",
        '&:hover': {
            color: theme.palette.primary.dark
        }
    },
    level1UserTable: {
        fontSize: 13,
        color: theme.palette.grey.darkTxt,
        maxWidth: 400,
        display: "block",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    padding8: {
        padding: 8
    },
    datasourceIconSelectImg: {
        width: 28,
        height: 28,
        objectFit: 'contain'
    },
    defaultIconContainer: {
        '& img': {
            width: 28,
            height: 28,
            background: '#e1e5e6',
            padding: 5
        }
    },
    searchTextbox: {
        width: '180px !important',
        // marginTop: 8,
        '& .MuiInput-underline:before': {
            height: '30px !important'
        },
        '& input': {
            height: '30px !important',
            fontSize: 13
        }
    },
    propertiesTab: {
        marginTop: 10,
        '& .Mui-selected': {
            backgroundColor: '#f38080',
            color: '#fff'
        }
    },
    boxList: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '2px 2px 15px #1D2C2F29',
        borderLeft: '4px solid red',
        height: '84px',
        marginBottom: '10px'
    },
    boxListTitle: {
        marginLeft: '10px'
    },
    boxListRightText: {
        color: theme.palette.grey.main
    },
    boxListDataType: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    BoxListTypeText: {
        color: theme.palette.grey.default,
        textTransform: 'uppercase'
    },
    boxListButtons: {
        width: '75px',
        height: '24px',
        background: '#F6F8F9',
        color: '#646666',
        fontSize: '13px !important',
        border: '1px solid #e1e5e6'
    },
    buttonTabs: {
        minWidth: '75px',
        minHeight: '24px',
        fontSize: '12px',
        borderRadius: '4px'
    },
    buttonTabSection: {
        marginLeft: '20px'
    },
    listContainer: {
        padding: 10
    },
    listHeading: {
        color: '#AFB2B3',
        padding: '10px 0px'
    },
    listContent: {
        color: '#969899',
        fontStyle: 'italic'
    },
    newTabStyle: {
        background: '#F6F8F9',
        color: '#646666',
        fontSize: '13px',
        border: '1px solid #e1e5e5',
        marginRight: theme.spacing(1)
    },
    propertiesSection: {
        minHeight: 'calc(100vh - 350px)',
        marginTop: '10px',
        background: '#fff',
        border: '1px solid #e1e5e6',
        padding: '10px',
        overflowY: 'auto'
    },
    propertySubTitle: {
        color: '#646666',
        fontSize: '13px',
        marginLeft: '10px'
    },
    datasetDetailContent: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-end',
        alignItems: 'flex-start'
    },
    executionLogContainer: {
        height: 'calc(100vh - 200px)',
        backgroundColor: theme.palette.common.white,
        overflow: 'auto',
        position: 'relative',
        '& .MuiCard-root': {
            height: '100%',
            overflow: 'auto',
            padding: '0px 27px'
        },
        '& .MuiTable-stickyHeader': {
            padding: '6px 0'
        },
        '& .MuiTableCell-head': {
            color: "#222222"
        },
        '& .MuiTableCell-root': {
            padding: '7px 16px',
            background: "#fff"
        },
        '& .MuiTableCell-body > p': {
            color: theme.palette.grey.darkTxt
        }
    },
    executionLogTable: {
        display: "flex",
        flexDirection: "column"
    },
    datasetsContainer: {
        height: 'calc(100vh - 300px)',
        oveflow: 'hidden',
        overflowY: 'auto',
        paddingRight: 2
    },
    dataSourcedetailContainer: {
        height: 'calc(100vh - 260px)',
        oveflow: 'hidden',
        overflowY: 'auto',
        paddingRight: 2
    },
    datasettablink: {
        cursor: "pointer",
        // color: `${theme.palette.primary.dark} !important`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        '&:hover': {
            color: `${theme.palette.primary.dark} !important`
        }
    },
    attributeSelectorCheck: {
        paddingLeft: 3
    },
    descriptionTextBox: {
        '& textarea': {
            fontSize: 12,
            color: `${theme.palette.grey.dark} !important`,
            "&::-webkit-scrollbar": {
                height: '8px',
                backgroundColor: theme.palette.background.default,
                width: '8px',
                zIndex: 9999
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: '#9EAEB4',
                borderRadius: '4px',
                zIndex: 9999
            }
        },
        '&:hover': {
            backgroundColor: theme.palette.grey.exteremeLight
        }
    },
    datasetsPropertiesRightContainer: {
        background: theme.palette.common.white
    },
    executionLogActions: {
        padding: 4
    },
    datasetSearchContainer: {
        marginBottom: 5,
        '& .MuiPaper-root': {
            height: '30px !important'
        },
        '& .MuiIconButton-root': {
            padding: '8px'
        },
        '& .MuiInputBase-root': {
            minWidth: "170px"
        }
    },
    listViewContainer: {
        height: 'calc(100vh - 242px) !important',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    curateScore: {
        display: 'inline-flex',
        alignItems: 'center',
        marginTop: 2,
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    curateIcon: {
        width: 40,
        height: 40
    },
    curateTxt: {
        fontWeight: 600
    },
    impactArrow: {
        width: 30,
        height: 30
    },
    notApplicableText: {
        color: theme.palette.grey.light,
        fontSize: 13
        // paddingLeft: 24, paddingLeft: '15px !important'
    },
    curateNotApplicableText: {
        paddingLeft: 40
    },
    bgNone: {
        '&:hover': {
            backgroundColor: 'rgb(0 0 0 / 0%) !important',
            color: theme.palette.primary.dary
        },
        '& .MuiTouchRipple-root': {
            display: 'none !important'
        }
    },
    animateIcons: {
        '& div, svg': {
            position: 'absolute !important',
            left: 0,
            width: 32
        }
    },
    iconWithText: {
        marginLeft: 20
    },
    sourceIconContainer: {
        cursor: 'pointer',
        // margin: '0px 8px',
        marginRight: '8px',
        '& img': {
            margin: '0 !important'
        }
    },
    datasettable: {

    },
    includeSearchContainer: {
        position: 'relative'
    },
    includeSearchIcon: {
        position: 'absolute',
        top: 1,
        right: 3,
        padding: 8
    },
    includeDeleteIcon: {
        position: 'absolute',
        top: 1,
        right: 3,
        padding: 6
    },
    dqSearchBox: {
        transitionDuration: '500ms',
        transition: 'width .55s ease',
        animation: 'slide-left180 0.4s ease',
        width: '180px'
    },
    dataSourceNotification: {
        position: 'absolute',
        top: '3px',
        right: '4px'
    },
    sctionItemsdataSourceNotification: {
        position: 'absolute',
        top: '3px',
        right: '10px'
    },
    noResultFound: {
        height: "100%",
        position: "absolute",
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        '& .MuiTypography-root': {
            marginTop: '15px',
            color: 'gray',
            overflowY: 'hidden'
        }
    },
    datasetTableHeaderAccordions: {
        overflowX: 'hidden'
    },
    deleteDataset: {
        marginLeft: -4
    },
    driftIcon: {
        width: '8px'
    },
    errorContainer: {
        padding: '10px 20px'
    },
    cursor: {
        cursor: 'pointer'
    },
    overflowHidden: {
        overflow: 'hidden'
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight600: {
        fontWeight: 600
    },
    marginRight8: {
        marginRight: 8
    },
    maxWidth170: {
        maxWidth: "170px !important"
    },
    alias: {
        // cursor: 'alias !important'
        cursor: 'context-menu'
    },
    width180: {
        width: "180px !important"
    },
    flextable: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between'
    },
    nopadding: {
        padding: "0px !important"
    },
    collapseTable: {
        padding: "2px 15px 15px 15px",
        background: "#f4f6f9"
    },
    enableScroll: {
        overflowX: "hidden",
        maxHeight: "45vh"
    },
    domainTableCollapse: {
        maxHeight: 'calc(100vh - 308px)',
        overflow: 'auto !important',
        '& .MuiTableCell-root': {
            maxWidth: 900
        },
        '& .MuiLinearProgress-root': {
            minWidth: 50
        }
    },
    noBackground: {
        background: "none !important",
        border: "0px !important",
        '& th': {
            background: "none !important"
        }
    },
    wrapText: {
        overflowWrap: 'break-word'
    },
    scrollWidthFix: {
        width: "99.98%",
        '& .MuiTableRow-root': {
            borderRight: 'none !important',
            borderLeft: 'none !important'
        }
    },
    ratingCount: {
        marginLeft: 10,
        fontWeight: 500,
        marginRight: 20
    },
    overviewPanel: {
        flexGrow: 1,
        marginTop: 15
    },
    metadataPanel: {
        height: 'auto !important'
        // marginBottom: 15
    },
    processedCount: {
        flexGrow: 1,
        color: '#969899',
        fontSize: 14,
        marginLeft: 2
    },
    queryText: {
        marginRight: 5,
        wordBreak: 'break-word',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        maxHeight: 134,
        '-webkit-line-clamp': 6,
        '-webkit-box-orient': 'vertical'
    },
    queryTextContainer: {
        position: 'relative',
        '&:hover': {
            '& .MuiIconButton-root': {
                display: 'inherit'
            }
        },
        '& .MuiIconButton-root': {
            position: 'absolute',
            transition: 'all ease 0.3s',
            top: -8,
            right: 0,
            padding: 8,
            display: 'none'
        }
    },
    catalogInfoView: {
        display: 'none'
    },
    executionTitle: {
        padding: '12px 15px 0px 27px',
        '& .MuiIconButton-root': {
            color: theme.palette.primary.main
        }
    },
    statisticsContainer: {
        minWidth: 500,
        padding: 10
    },
    pathDisplay: {
        paddingLeft: 20,
        display: "flex",
        alignItems: "center",
        maxWidth: "80%",
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    widthCont2: {
        width: "80%"
    },
    pathGrid: {
        color: theme.palette.grey.main,
        display: "flex",
        alignItems: "center",
        maxWidth: "100%"
    },
    textEllipse: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "100%",
        height: "1.2em",
        whiteSpace: "nowrap"
    },
    overViewcard: {
        alignItems: 'baseline !important'
    },
    reloadIcon: {
        margin: '0 6px'
    },
    errorLogContainer: {
        maxWidth: '60%',
        padding: 8
    },
    glossarySelect: {
        '& .MuiSelect-select.MuiSelect-select': {
            display: 'flex'
        }
    },
    configurationContainer: {
        // height: 'calc(100vh - 200px)',
        backgroundColor: theme.palette.common.white,
        overflow: 'auto',
        position: 'relative',
        padding: 25
    }
});

export default DatasourceStyles;