import { datasourceActions } from '../constants/actionTypes/datasourceActionTypes';
import { appConstants } from '../constants/appConstants';

const initialState = {
    isLoading: false,
    datasources: [],
    datasets: [],
    attributes: [],
    domains: [],
    filters: {
        viewType: appConstants.filterTypes[0],
        search: '',
        connectionType: [],
        sensitivity: [],
        users: [
            {
                isDefault: true,
                isSelected: true,
                isUser: false,
                name: 'All'
            }
        ],
        dqScore: [0, 100],
        favourite: false
    }
};

export const catalogReducer = (state = initialState, action) => {
    if (!action || (action && !action.type)) { return state; }
    switch (action.type) {
        case datasourceActions.SET_SOURCE_FILTERS:
            return {
                ...state,
                filters: { ...action.filters }
            };
        case datasourceActions.GET_CATALOG_DATASOURCES_REQUEST:
            return {
                ...state,
                isLoading: true
            };
        case datasourceActions.GET_CATALOG_DATASOURCES_SUCCESS:
            const searchConfig = action.responseData.searchConfig;
            const reset = action.responseData.resetData;
            const viewType = searchConfig.viewType;
            let sources = [...action.responseData.data];

            switch (viewType) {
                case appConstants.filterTypes[0]:
                    sources = sources.map((source) => {
                        source.sharing_info = source?.sharing_info?.map((sharing) => {
                            sharing.name = sharing.user__first_name !== "" ? `${sharing.user__first_name} ${sharing.user__last_name}` : sharing.user__email;
                            return sharing;
                        }) ?? [];
                        return source;
                    });
                    sources = reset ? [...sources] : [...state.datasources, ...sources];
                    return {
                        ...state,
                        datasources: [...sources],
                        isLoading: false
                    };
                case appConstants.filterTypes[1]:
                    sources = sources.map((source) => {
                        source.sharing_info = source?.sharing_info?.map((sharing) => {
                            sharing.name = sharing.user__first_name !== "" ? `${sharing.user__first_name} ${sharing.user__last_name}` : sharing.user__email;
                            return sharing;
                        }) ?? [];
                        return source;
                    });
                    sources = reset ? [...sources] : [...state.datasets, ...sources];
                    return {
                        ...state,
                        datasets: [...sources],
                        isLoading: false
                    };
                case appConstants.filterTypes[2]:
                    sources = reset ? [...sources] : [...state.attributes, ...sources];
                    return {
                        ...state,
                        attributes: [...sources],
                        isLoading: false
                    };
                case appConstants.filterTypes[3]:
                    sources = reset ? [...sources] : [...state.domains, ...sources];
                    return {
                        ...state,
                        domains: [...sources],
                        isLoading: false
                    };
                default:
                    return {
                        ...state,
                        isLoading: false
                    };
            }
        case datasourceActions.GET_CATALOG_DATASOURCES_FAILURE:
            return {
                ...state,
                datasources: [...state.datasources],
                isLoading: false
            };
        case datasourceActions.CLEAR_CATALOG_LIST_DATA:
            return {
                ...state,
                isLoading: false,
                datasources: [],
                datasets: [],
                attributes: [],
                domains: []
            };
        case datasourceActions.UPDATE_DATASOURCE_LOAD:
            return {
                ...state,
                datasources: [...action.datasources]
            };
        case datasourceActions.UPDATE_DATASET_LOAD:
            return {
                ...state,
                datasets: [...action.datasets]
            };
        default:
            return state;
    }
};