const DomainDataPreviewStyles = (theme) => ({
    queryFilterDialog: {
        background: 'rgb(255 255 255 / 50%)',
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '900px !important',
            minWidth: 900
        }
    },
    searchInput: {
        '& input': {
            height: '28px !important'
        },
        '& .MuiInput-underline:before': {
            height: '27px !important'
        }
    },
    actionButton: {
        height: 28,
        marginLeft: 16,
        fontSize: 13,
        padding: "6px 12px !important"
    },
    includeSearchContainer: {
        position: 'relative'
    },
    includeSearchIcon: {
        position: 'absolute',
        top: 1,
        right: 3,
        padding: 8
    },
    previewContainer: {
        position: 'relative',
        display: 'flex',
        minHeight: 300,
        flexDirection: 'nowrap'
    },
    QueryfilterTitle: {
        fontWeight: '500',
        fontSize: '16px'
    },
    textboxContainer: {
        minWidth: 150
    },
    QueryfilterDataTitle: {
        fontWeight: '500',
        fontSize: '14px',
        color: theme.palette.grey.dark
    }
});

export default DomainDataPreviewStyles;