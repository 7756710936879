export const searchActions = {
    UPDATE_SERACH_FILTERS: 'UPDATE_SERACH_FILTERS',
    UPDATE_SERACH_RESULT_OFFSET: 'UPDATE_SERACH_RESULT_OFFSET',
    UPDATE_SELECTED_ATTRIBUTE: 'UPDATE_SELECTED_ATTRIBUTE',
    CLEAR_SERACH_RESULTS: 'CLEAR_SERACH_RESULTS',

    GET_SEARCH_DASHBORAD_INFO_REQUEST: 'GET_SEARCH_DASHBORAD_INFO_REQUEST',
    GET_SEARCH_DASHBORAD_INFO_SUCCESS: 'GET_SEARCH_DASHBORAD_INFO_SUCCESS',
    GET_SEARCH_DASHBORAD_INFO_FAILURE: 'GET_SEARCH_DASHBORAD_INFO_FAILURE',

    GET_SEARCH_RESULT_REQUEST: 'GET_SEARCH_RESULT_REQUEST',
    GET_SEARCH_RESULT_SUCCESS: 'GET_SEARCH_RESULT_SUCCESS',
    GET_SEARCH_RESULT_FAILURE: 'GET_SEARCH_RESULT_FAILURE',

    SAVE_SEARCH_TRACK_REQUEST: 'SAVE_SEARCH_TRACK_REQUEST',
    SAVE_SEARCH_TRACK_SUCCESS: 'SAVE_SEARCH_TRACK_SUCCESS',
    SAVE_SEARCH_TRACK_FAILURE: 'SAVE_SEARCH_TRACK_FAILURE',

    SAVE_USER_SEARCH_REQUEST: 'SAVE_USER_SEARCH_REQUEST',
    SAVE_USER_SEARCH_SUCCESS: 'SAVE_USER_SEARCH_SUCCESS',
    SAVE_USER_SEARCH_FAILURE: 'SAVE_USER_SEARCH_FAILURE',

    SAVE_USER_FILTERSEARCH_REQUEST: 'SAVE_USER_FILTERSEARCH_REQUEST',
    SAVE_USER_FILTERSEARCH_SUCCESS: 'SAVE_USER_FILTERSEARCH_SUCCESS',
    SAVE_USER_FILTERSEARCH_FAILURE: 'SAVE_USER_FILTERSEARCH_FAILURE',

    GET_SEARCH_RESULT_COUNT_REQUEST: 'GET_SEARCH_RESULT_COUNT_REQUEST',
    GET_SEARCH_RESULT_COUNT_SUCCESS: 'GET_SEARCH_RESULT_COUNT_SUCCESS',
    GET_SEARCH_RESULT_COUNT_FAILURE: 'GET_SEARCH_RESULT_COUNT_FAILURE',

    GET_SEARCH_RESULT_TAGS_REQUEST: 'GET_SEARCH_RESULT_TAGS_REQUEST',
    GET_SEARCH_RESULT_TAGS_SUCCESS: 'GET_SEARCH_RESULT_TAGS_SUCCESS',
    GET_SEARCH_RESULT_TAGS_FAILURE: 'GET_SEARCH_RESULT_TAGS_FAILURE'
};