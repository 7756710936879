import { accountActions } from '../constants/actionTypes/accountActionTypes';

const initialState = {
	isLoading: false,
	isLoggedIn: false,
	user: {},
	users: []
};

export const accountReducer = (state = initialState, action) => {
	if (!action || (action && !action.type)) {
		return state;
	}
	switch (action.type) {
		case accountActions.LOGIN_REQUEST:
		case accountActions.USER_ACTIVATE_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case accountActions.LOGIN_SUCCESS:
			return {
				...state,
				user: action.responseData ? action.responseData : {},
				isLoggedIn: true,
				isLoading: false
			};
		case accountActions.LOGIN_FAILURE:
		case accountActions.USER_ACTIVATE_SUCCESS:
		case accountActions.USER_ACTIVATE_FAILURE:
			return {
				...state,
				isLoggedIn: false,
				isLoading: false
			};
		case accountActions.GET_USER_REQUEST:
			return {
				...state
			};
		case accountActions.GET_USER_SUCCESS:
			return {
				...state,
				isLoggedIn: true,
				user: action.responseData ? action.responseData : {}
			};
		case accountActions.GET_USER_FAILURE:
			return {
				...state,
				isLoggedIn: false
			};
		case accountActions.GET_FORGOT_PASSWORD_REQUEST:
		case accountActions.RESET_PASSWORD_REQUEST:
			return {
				...state,
				isLoading: true
			};
		case accountActions.GET_FORGOT_PASSWORD_SUCCESS:
		case accountActions.GET_FORGOT_PASSWORD_FAILURE:
		case accountActions.RESET_PASSWORD_SUCCESS:
		case accountActions.RESET_PASSWORD_FAILURE:
		case accountActions.GET_VERIFY_TOKEN_REQUEST:
		case accountActions.GET_VERIFY_TOKEN_SUCCESS:
		case accountActions.GET_VERIFY_TOKEN_FAILURE:
		case accountActions.GET_ORGANIZATION_USERS_REQUEST:
		case accountActions.GET_ORGANIZATION_USERS_FAILURE:
			return {
				...state,
				isLoading: false
			};
		case accountActions.LOGOUT:
			return {
				...state,
				user: {}
			};
		case accountActions.GET_ORGANIZATION_USERS_SUCCESS:
			return {
				...state,
				users: action.responseData ? action.responseData : []
			};
		case accountActions.UPDATE_USER:
			return {
				...state,
				users: [...action.responseData]
			};
		case accountActions.DISABLE_ROLE_PERMISSION:
			const userInfo = { ...state.user };
			if (userInfo && userInfo.role && userInfo.role.properties_permission) {
				userInfo.role.properties_permission = {
					is_delete: false,
					is_edit: false,
					is_view: true
				};
			}
			return {
				...state,
				user: userInfo
			};
		default:
			return state;
	}
};