import React, { useState } from 'react';
import { Grid, withStyles, AccordionSummary, Accordion, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import MyProfile from './MyProfile.jsx';
import OrganizationDetail from './OrganizationDetail.jsx';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';

const Profile = (props) => {
    const { classes, organizationId } = props;
    const [selectedPanel, setSelectedPanel] = useState('My Profile');

    const toggleExpansionPanel = (panel) => (event, isExpanded) => {
        isExpanded = isExpanded ? panel : false;
        setSelectedPanel(isExpanded);
    };

    return (
        <Grid className={classes.referenceContainer}>
            <Accordion expanded={selectedPanel === "My Profile"} onChange={toggleExpansionPanel("My Profile")} className={classes.storageAcoordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography className={classes.heading}>
                        {"My Profile"}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid className={classes.accordianSection}>
                        <MyProfile type="settingsPanel" />
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={selectedPanel === "Organization"} onChange={toggleExpansionPanel("Organization")} className={classes.storageAcoordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography className={classes.heading}>
                        {"Organization Profile"}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid className={classes.accordianSection}>
                        <OrganizationDetail type="settingsPanel" organizationId={organizationId} />
                    </Grid>
                </AccordionDetails>
            </Accordion>
        </Grid>
    );
};


Profile.propTypes = {
    classes: PropTypes.object,
    organizationId: PropTypes.number
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Profile);