const FileConnectorStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    droparea: {
        backgroundColor: theme.palette.common.white,
        paddingTop: 'calc(100vh - 90px)',
        border: `2px dashed ${theme.palette.border.light}`,
        borderRadius: 2,
        position: 'relative',
        outline: 'none'
    },
    dropareaContent: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        backgroundColor: theme.palette.transparent
    },
    dropareaText: {
        margin: '10px 20px 20px'
    },
    dropareaImage: {
        height: 50
    },
    hintContainer: {
        position: 'absolute',
        bottom: 20,
        right: 20
    },
    hintText: {
        fontFamily: theme.palette.textFont,
        fontSize: 12,
        fontWeight: 500,
        color: theme.palette.grey.dark,
        lineHeight: 1.5
    },
    connectorTable: {
        '& .MuiTableCell-head': {
            fontSize: "14px !important"
        },
        '& .MuiTableCell-stickyHeader': {
            padding: '2px 16px !important'
        },
        '.MuiTableCell-body': {
            fontSize: "13px !important"
        },
        '& tbody tr:hover': {
            '&': {
                boxShadow: '0 0px 5px 0px rgb(0 0 0 / 9%), 0 4px 15px 0 rgb(0 0 0 / 4%)',
                background: '#fff'
            }
        }
    }
});

export default FileConnectorStyles;