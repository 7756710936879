import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import VerticalStackedBarChart from '../Charts/VerticalStackedBarChart.jsx';
import { getConversationAnalysis } from '../../actions/scheduleActions';

const DashboardConversation = (props) => {
    const { isConversation } = props;
    const stackgroup = isConversation ? ["conversation", "view", "favorites"] : ["view", "favorites"];
    const dispatch = useDispatch();
    const [analysis, setAnalysis] = useState([]);
    const label = { x: 'Date', y: 'Count' };

    const getDistribution = useCallback(() => {
        dispatch(getConversationAnalysis()).then((response) => {
            if (response) {
                const analysisData = response.map((data) => {
                    return {
                        ...data.conversation_analysis,
                        total: data.conversation_analysis.conversation + data.conversation_analysis.view + data.conversation_analysis.favorites
                    };
                });
                setAnalysis([...analysisData]);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getDistribution();
    }, [getDistribution]);
    return (
        <ChartContainer
            title="Conversations (By Timeline Across All)"
            chartData
        >
            <VerticalStackedBarChart stackgroup={stackgroup} analysisData={analysis} label={label} />
        </ChartContainer>
    );
};

DashboardConversation.propTypes = {
    isConversation: PropTypes.bool
};

export default withStyles((theme) => ({
    ...Styles(theme)
}))(DashboardConversation);