import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
// import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import Distribution from '../Charts/Distribution.jsx';
import { getOrganizationDqscoreDistribution } from '../../actions/scheduleActions';

const DashboardDistribution = (props) => {
    const dispatch = useDispatch();
    const [analysis, setAnalysis] = useState([]);

    const getDistribution = useCallback(() => {
        dispatch(getOrganizationDqscoreDistribution()).then((response) => {
            if (response && response.dqscore_distribution) {
                setAnalysis([...response.dqscore_distribution.count]);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getDistribution();
    }, [getDistribution]);

    return (
        <Distribution analysis={analysis} />
    );
};

DashboardDistribution.propTypes = {
};

export default withStyles((theme) => ({
    ...Styles(theme)
}))(DashboardDistribution);