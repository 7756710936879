import React from 'react';
import { Grid, withStyles, IconButton, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import Styles from '../../layouts/Styles.jsx';
import DashboardStyles from './DashboardStyles.jsx';

const DashboardToolbar = (props) => {
    const { theme, dashboard, onChange, onAddWidget, addDashboardFilter, onDeleteDashboard, onSave } = props;
    return (
        <Grid container alignItems="center" justify="space-between">
            <Grid item style={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item style={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item style={{ flexGrow: 1 }}>
                                <TextField
                                    name="name"
                                    label="Name"
                                    value={dashboard.name}
                                    fullWidth
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    onBlur={() => onSave({ ...dashboard, name: dashboard.name ? dashboard.name : 'New' })}
                                    inputProps={{ style: { fontSize: 14 } }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ flexGrow: 4 }}>
                        <Grid container spacing={2}>
                            <Grid item style={{ flexGrow: 1 }}>
                                <TextField
                                    name="description"
                                    label="Description"
                                    value={dashboard.description}
                                    fullWidth
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    onBlur={() => onSave(dashboard)}
                                    inputProps={{ style: { fontSize: 14 } }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container>
                <Grid item>
                        <TooltipComponent title="Add Filter" placement="bottom" arrow>
                            <IconButton onClick={() => addDashboardFilter()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.923" viewBox="0 0 20 16.923">
                                    <g transform="translate(-3 -5)">
                                        <path id="Path_1391" data-name="Path 1391" d="M22.231,6.538H11.462A1.54,1.54,0,0,0,9.923,5H8.385A1.54,1.54,0,0,0,6.846,6.538H3.769a.769.769,0,1,0,0,1.538H6.846A1.54,1.54,0,0,0,8.385,9.615H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,8.077V6.538H9.923V7.3s0,0,0,0,0,0,0,0v.766Z" fill={theme.palette.primary.main} />
                                        <path id="Path_1392" data-name="Path 1392" d="M22.231,14.538H17.615A1.54,1.54,0,0,0,16.077,13H14.538A1.54,1.54,0,0,0,13,14.538H3.769a.769.769,0,0,0,0,1.538H13a1.54,1.54,0,0,0,1.538,1.538h1.538a1.54,1.54,0,0,0,1.538-1.538h4.615a.769.769,0,1,0,0-1.538Zm-7.692,1.538V14.538h1.538V15.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -1.846)" fill={theme.palette.primary.main} />
                                        <path id="Path_1393" data-name="Path 1393" d="M22.231,22.538H11.462A1.54,1.54,0,0,0,9.923,21H8.385a1.54,1.54,0,0,0-1.538,1.538H3.769a.769.769,0,0,0,0,1.538H6.846a1.54,1.54,0,0,0,1.538,1.538H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,24.077V22.538H9.923V23.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -3.692)" fill={theme.palette.primary.main} />
                                    </g>
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                        <TooltipComponent title="Add Widget" placement="bottom" arrow>
                            <IconButton onClick={() => onAddWidget()}>
                                <svg id="add_chart_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                    <g id="Group_4129" data-name="Group 4129">
                                        <rect id="Rectangle_1748" data-name="Rectangle 1748" width="24" height="24" fill="none" />
                                    </g>
                                    <g id="Group_4131" data-name="Group 4131" transform="translate(3 3)">
                                        <g id="Group_4130" data-name="Group 4130">
                                            <path id="Path_2499" data-name="Path 2499" d="M19,19H5V5h9V3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V10H19Z" transform="translate(-3 -3)" fill={theme.palette.primary.main} />
                                            <rect id="Rectangle_1749" data-name="Rectangle 1749" width="2" height="4" transform="translate(12 10)" fill={theme.palette.primary.main} />
                                            <rect id="Rectangle_1750" data-name="Rectangle 1750" width="2" height="7" transform="translate(4 7)" fill={theme.palette.primary.main} />
                                            <rect id="Rectangle_1751" data-name="Rectangle 1751" width="2" height="10" transform="translate(8 4)" fill={theme.palette.primary.main} />
                                            <path id="Path_2500" data-name="Path 2500" d="M19,5V3H17V5H15V7h2V9h2V7h2V5Z" transform="translate(-3 -3)" fill={theme.palette.primary.main} />
                                        </g>
                                    </g>
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                </Grid>
                    <Grid item style={{ marginTop: 2 }}>
                        <TooltipComponent title="Delete Dashboard" placement="bottom" arrow>
                            <IconButton onClick={() => onDeleteDashboard()}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15.991" height="18.06" viewBox="0 0 15.991 18.06">
                                    <g id="Group_4077" data-name="Group 4077" transform="translate(0.25 0.25)">
                                        <g id="trash_2_" data-name="trash (2)">
                                            <path id="Path_962" data-name="Path 962" d="M125.886,166H114.532a.438.438,0,0,0-.428.487l1.165,10.192a2.226,2.226,0,0,0,2.211,1.975h5.653a2.217,2.217,0,0,0,2.215-2.015l.967-10.157a.442.442,0,0,0-.11-.336A.436.436,0,0,0,125.886,166Zm-1.4,10.552a1.364,1.364,0,0,1-1.358,1.241H117.48a1.369,1.369,0,0,1-1.356-1.215l-1.11-9.716h10.4Z" transform="translate(-112.463 -161.093)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="0.5" />
                                            <path id="Path_963" data-name="Path 963" d="M91.1,54.152H86.535V53.33A1.342,1.342,0,0,0,85.194,52H82.367a1.342,1.342,0,0,0-1.341,1.33v.822H76.465a.43.43,0,0,0,0,.861H91.1a.43.43,0,0,0,0-.861Zm-5.421,0H81.887V53.33a.481.481,0,0,1,.48-.469h2.827a.481.481,0,0,1,.48.469Z" transform="translate(-76.035 -52)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="0.5" />
                                        </g>
                                        <g id="trash_2_2" data-name="trash (2)">
                                            <path id="Path_962-2" data-name="Path 962" d="M125.886,166H114.532a.438.438,0,0,0-.428.487l1.165,10.192a2.226,2.226,0,0,0,2.211,1.975h5.653a2.217,2.217,0,0,0,2.215-2.015l.967-10.157a.442.442,0,0,0-.11-.336A.436.436,0,0,0,125.886,166Zm-1.4,10.552a1.364,1.364,0,0,1-1.358,1.241H117.48a1.369,1.369,0,0,1-1.356-1.215l-1.11-9.716h10.4Z" transform="translate(-112.463 -161.093)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="0.5" />
                                            <path id="Path_963-2" data-name="Path 963" d="M91.1,54.152H86.535V53.33A1.342,1.342,0,0,0,85.194,52H82.367a1.342,1.342,0,0,0-1.341,1.33v.822H76.465a.43.43,0,0,0,0,.861H91.1a.43.43,0,0,0,0-.861Zm-5.421,0H81.887V53.33a.481.481,0,0,1,.48-.469h2.827a.481.481,0,0,1,.48.469Z" transform="translate(-76.035 -52)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="0.5" />
                                        </g>
                                    </g>
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

DashboardToolbar.propTypes = {
    theme: PropTypes.object,
    dashboard: PropTypes.object,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onAddWidget: PropTypes.func,
    addDashboardFilter: PropTypes.func,
    onDeleteDashboard: PropTypes.func
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DashboardToolbar);