/* eslint-disable react/no-danger */
import React, { useCallback, useRef } from 'react';
import { Grid, withStyles, Paper, MenuItem, ListItemIcon, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import { getCursorPositionText, getTextAreaPosition } from '../../../helpers/appHelpers';
import Styles from '../../../layouts/Styles.jsx';
import ReactQueryEditorStyles from '../ReactQueryEditorStyles.jsx';
import classNames from 'classnames';

const getSuggestion = (suggestions, value) => {
    return suggestions.filter((data) => data.label && data.label.toLowerCase().includes(value.toLowerCase()));
};

const Editor = (props) => {
    const { classes, highlight, theme, onChange, onBlur, value, suggestions, openSuggestion, enableSuggestion, suggesstionHeight, isModel, isScan, disabled } = props;
    const highlighted = highlight(value);
    const editorRef = useRef();

    const suggestionValue = getCursorPositionText("sql-editor");

    const onKeyDown = useCallback((event) => {
        if (event.keyCode === 40 && suggestionValue.length >= 2 && editorRef && editorRef.current) {
            editorRef.current.focus();
        }
    }, [suggestionValue.length]);

    const editorSuggestionChange = useCallback((selection) => {
        const selectionValue = selection.suggestionType !== "function" ? selection.label : selection.operator;
        const cursorPosition = getTextAreaPosition("sql-editor", "cursor");
        const startQuery = value.substring(0, Math.abs(suggestionValue.length - cursorPosition.left));
        const endQuery = value.substring(cursorPosition.left, value.length);
        const newQuery = `${startQuery}${selectionValue}${endQuery}`;
        enableSuggestion(false);
        onChange(newQuery);
    }, [enableSuggestion, onChange, suggestionValue.length, value]);

    const onChangeQuery = (value) => {
        if (!openSuggestion && suggestionValue.length >= 2) {
            enableSuggestion(true);
        }
        onChange(value);
    };

    const suggestionList = suggestionValue && suggestionValue.length > 0 ? getSuggestion(suggestions, suggestionValue) : [];

    const getSuggestionPosition = () => {
        let topPosition = 0;
        let leftPosition = 0;
        const top = 50;
        const left = 100;

        if (suggestionList.length > 0 && openSuggestion) {

            let containerBounds = document.querySelector('.sql-editor');
            if (containerBounds) {
                containerBounds = containerBounds.getBoundingClientRect();
            }
            let dropdownBounds = document.getElementById("suggestion");
            let computedStyle;
            if (dropdownBounds) {
                computedStyle = window.getComputedStyle(dropdownBounds);
                dropdownBounds = dropdownBounds.getBoundingClientRect();
            }
            let textareaBounds = document.querySelector('.sql-editor textarea');
            if (textareaBounds) {
                textareaBounds = textareaBounds.getBoundingClientRect();
            }

            if (dropdownBounds) {
                const marginTop = parseInt(
                    computedStyle.getPropertyValue("margin-top"),
                    10
                );
                const marginBottom = parseInt(
                    computedStyle.getPropertyValue("margin-bottom"),
                    10
                );
                const marginLeft = parseInt(
                    computedStyle.getPropertyValue("margin-left"),
                    10
                );
                const marginRight = parseInt(
                    computedStyle.getPropertyValue("margin-right"),
                    10
                );

                const dropdownBottom =
                    marginTop +
                    marginBottom +
                    textareaBounds.top +
                    top +
                    dropdownBounds.height;
                const dropdownRight =
                    marginLeft +
                    marginRight +
                    textareaBounds.left +
                    left +
                    dropdownBounds.width;

                if (dropdownRight > containerBounds.right) {
                    leftPosition = left - dropdownBounds.width;
                } else {
                    leftPosition = left;
                }

                if (dropdownBottom > containerBounds.bottom) {
                    topPosition = top - dropdownBounds.height;
                } else {
                    topPosition = top;
                }

                if (topPosition < 0) {
                    topPosition = 50;
                }
                if (leftPosition < 0) {
                    leftPosition = 20;
                }
                /*
                 * topPosition += textareaBounds.top;
                 * leftPosition += textareaBounds.left;
                 */
            }
            return {
                top: topPosition,
                left: leftPosition
            };
        }
    };

    const suggestionPosition = getSuggestionPosition();


    return (
        <Grid className={classNames(classes.editorContainer, isModel && isScan ? classes.modelEditor : null)}>
            <textarea
                className={classes.editor}
                resize="none"
                autoCapitalize="off"
                autoComplete="off"
                autoCorrect="off"
                spellCheck="false"
                data-gramm="false"
                disabled={disabled}
                onTouchStart={(event) => event.stopPropagation()}
                onTouchMove={(event) => event.stopPropagation()}
                onTouchEnd={(event) => event.stopPropagation()}
                onTouchCancel={(event) => event.stopPropagation()}
                onMouseDown={(event) => event.stopPropagation()}
                onMouseMove={(event) => event.stopPropagation()}
                onMouseUp={(event) => event.stopPropagation()}
                onMouseLeave={(event) => event.stopPropagation()}
                onKeyDown={(event) => onKeyDown(event)}
                onChange={(event) => onChangeQuery(event.target.value)}
                onBlur={() => (onBlur ? onBlur() : null)}
                value={value ? value : ''} />
            <pre className={classes.editorCode} dangerouslySetInnerHTML={{ __html: highlighted + '<br />' }} />
            {
                (suggestionValue.length >= 2 && openSuggestion && suggestionList.length > 0) &&
                <Paper
                    id="suggestion"
                    tabIndex="-1"
                    ref={editorRef}
                    className={classes.editorSuggestion}
                    disabled={disabled}
                    style={
                        {

                            left: suggestionPosition.left,
                            top: suggestionPosition.top,
                            height: suggesstionHeight ? suggesstionHeight : 0
                        }
                    }>

                    {
                        suggestionList.map((item, index) =>
                            <MenuItem key={index} className={classes.editorMenu} onClick={() => editorSuggestionChange(item)}>
                                <ListItemIcon>
                                    {
                                        item.suggestionType === "function" &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                            <g id="Group_3919" data-name="Group 3919" transform="translate(-184 -175)">
                                                <g id="Ellipse_1869" data-name="Ellipse 1869" transform="translate(184 175)" fill="#f6f8f9" stroke="#e1e5e6" strokeWidth="1">
                                                    <circle cx="9" cy="9" r="9" stroke="none" />
                                                    <circle cx="9" cy="9" r="8.5" fill="none" />
                                                </g>
                                                <path id="symbol_1_" data-name="symbol (1)" d="M64.446,8.691H59.015a.542.542,0,0,1-.443-.856l2.491-3.5L58.573.857A.542.542,0,0,1,59.015,0h5.432a.543.543,0,0,1,.543.542V1.627H63.9V1.084H60.07l2.1,2.938a.542.542,0,0,1,0,.63L60.068,7.606H63.9V7.061h1.086V8.148A.543.543,0,0,1,64.446,8.691Z" transform="translate(131.529 180)" fill="#afb2b3" />
                                            </g>
                                        </svg>
                                    }
                                    {
                                        item.suggestionType === "column" &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.751" height="16.711" viewBox="0 0 17.751 16.711">
                                            <path id="table" d="M0,15V31.711H17.751V15Zm16.711,1.04v2.115H1.04V16.04ZM9.4,22.315h3.12V24.4H9.4ZM8.355,24.4H5.235v-2.08h3.12Zm1.04-3.12V19.2h3.12v2.08Zm-1.04,0H5.235V19.2h3.12Zm-4.16,0H1.04V19.2H4.2Zm0,1.04V24.4H1.04v-2.08Zm0,3.12v2.08H1.04v-2.08Zm1.04,0h3.12v2.08H5.235Zm3.12,3.12v2.115H5.235V28.556Zm1.04,0h3.12v2.115H9.4Zm0-1.04v-2.08h3.12v2.08Zm4.16-2.08h3.155v2.08H13.556Zm0-1.04v-2.08h3.155V24.4Zm0-3.12V19.2h3.155v2.08ZM1.04,28.556H4.2v2.115H1.04Zm12.516,2.115V28.556h3.155v2.115Z" transform="translate(0 -15)" fill={theme.palette.grey.light} />
                                        </svg>
                                    }
                                    {
                                        item.suggestionType === "table" &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="17.773" height="16.732" viewBox="0 0 17.773 16.732">
                                            <path id="table" d="M0,15V31.732H17.773V15Zm16.732,1.041v2.118h-7.3l.024-2.118H4.265v2.118H1.041V16.041H16.732ZM9.407,22.325h3.124v2.083H9.407Zm0-1.041V19.2h3.124v2.083Zm-5.207,0H1.041V19.2H4.2Zm0,1.041v2.083H1.041V22.325Zm0,3.124v2.083H1.041V25.449Zm5.207,3.124h3.124v2.118H9.407Zm0-1.041V25.449h3.124v2.083Zm4.166-2.083h3.159v2.083H13.573Zm0-1.041V22.325h3.159v2.083Zm0-3.124V19.2h3.159v2.083ZM1.041,28.573H4.2v2.118H1.041Zm12.532,2.118V28.573h3.159v2.118Z" transform="translate(0 -15)" fill={theme.palette.grey.light} />
                                        </svg>
                                    }
                                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="11.289" height="15.394" viewBox="0 0 11.289 15.394">
                                        <path id="database_17_" data-name="database (17)" d="M15.645,17.394c2.805,0,5.645-.793,5.645-2.309V4.309C21.289,2.793,18.45,2,15.645,2S10,2.793,10,4.309V15.085C10,16.6,12.839,17.394,15.645,17.394Zm0-14.368c2.865,0,4.618.831,4.618,1.283s-1.753,1.283-4.618,1.283-4.618-.831-4.618-1.283S12.78,3.026,15.645,3.026ZM11.026,5.695a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924V7.9c0,.452-1.753,1.283-4.618,1.283S11.026,8.353,11.026,7.9Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Z" transform="translate(-10 -2)" fill={theme.palette.grey.light} />
                                    </svg> */}
                                </ListItemIcon>
                                <Typography>
                                    {item.label}
                                </Typography>
                            </MenuItem>

                        )
                    }
                </Paper>
            }
        </Grid >
    );
};

Editor.propTypes = {
    classes: PropTypes.object,
    highlight: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    suggestions: PropTypes.func,
    theme: PropTypes.object,
    isModel: PropTypes.bool,
    isScan: PropTypes.bool,
    openSuggestion: PropTypes.bool,
    enableSuggestion: PropTypes.func,
    suggesstionHeight: PropTypes.number,
    disabled: PropTypes.bool
};


export default withStyles((theme) => ({
    ...ReactQueryEditorStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Editor);