import React, { useEffect, useCallback, useState } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import CircleSpiderChart from '../Charts/CircleSpiderChart.jsx';
import { appConstants } from '../../constants/appConstants';
import { getDomainDimensions } from '../../actions/domainActions';
import DomainStyles from './DomainStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import noprofile from '../../assets/images/np_profile_data.jpg';

const DomainSummary = (props) => {
    const { classes, domainId } = props;
    const [analysis, setAnalysis] = useState([]);
    const [fullscreen, setFullscreen] = useState(false);
    const dispatch = useDispatch();

    const enableFullScreen = (value) => {
        setFullscreen(value);
    };

    const getTimelineAnalysis = useCallback(() => {
        dispatch(getDomainDimensions(domainId)).then((response) => {
            if (response && response.dimension_analysis && response.dimension_analysis.data) {
                setAnalysis({ ...response.dimension_analysis.data });
            }
        });
    }, [dispatch, domainId]);

    useEffect(() => {
        getTimelineAnalysis();
    }, [getTimelineAnalysis]);

    const getChartWidth = () => {
        if (!fullscreen) {
            if (window.innerWidth > 1600) {
                return 300;
            } else if (window.innerWidth < 1600 && window.innerWidth >= 1400) {
                return 240;
            }
            return 180;
        }
        return 500;
    };
    const getChartHeight = () => {
        if (!fullscreen) {
            if (window.innerWidth > 1600) {
                return 290;
            } else if (window.innerWidth < 1600 && window.innerWidth >= 1400) {
                return 230;
            }
            return 170;
        }
        return 490;
    };

    return (
        <Grid className={`${fullscreen ? classes.fullScreen : ''} ${classes.dqDimensionContainer}`}>
            <ChartContainer
                title={appConstants.charts.domainDimension.name}
                id={appConstants.charts.domainDimension.id}
                fullScreenProperties={
                    {
                        enable: fullscreen,
                        onEdit: enableFullScreen
                    }
                }
                chartData={analysis}>
                {
                    Object.keys(analysis).length > 0 ?
                        <CircleSpiderChart
                            width={getChartWidth()}
                            height={getChartHeight()}
                            chartClassName="domain-dimension"
                            analysis={analysis}
                            color="#ffaf97"
                        />
                        :
                        <Grid align="center" className={classes.noDataContainer}>
                            <img src={noprofile} alt="" className={classes.noDataImg} />
                        </Grid>
                }

            </ChartContainer>
        </Grid>
    );
};

DomainSummary.propTypes = {
    classes: PropTypes.object,
    domainId: PropTypes.number
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DomainSummary);