const DatasourceListStyles = (theme) => ({
    listContainer: {
        flexGrow: 0,
        flex: 1,
        maxWidth: 'calc(100% - 120px)',
        flexBasis: 'calc(100% - 120px)',
        padding: 20,
        position: 'relative'
    },
    listContainerfull: {
        maxWidth: '100%',
        flexBasis: '100%'
    },
    flexContainer: {
		display: "flex",
		marginRight: 25,
		alignItems: 'center',
		maxWidth: '100%'
	},
    widthCont: {
		width: '100%'
	},
    highlight_path: {
		fontWeight: 'bold',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},
    datasourceInfoLink: {
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        '&:hover': {
            color: theme.palette.primary.dark
        }
    },
    marginRight5: {
        marginRight: 5
    },
    marginRight7: {
        marginRight: 7
    },
    marginRight12: {
        marginRight: 12
    },
    marginLeft5: {
        marginLeft: 5
    },
    paddingRight10: {
        paddingRight: 10
    },
    paddingRight6: {
        paddingRight: 6
    },
    paddingRight4: {
        paddingRight: 4
    },
    dqScoreContainer: {
        flexGrow: 0,
        maxWidth: 120,
        flexBasis: 120,
        borderLeft: '1px solid #EDF2F4'
    },
    dqScoreSection: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center'
    },
    detailContainer: {
        display: 'inline-flex'
    },
    logo: {
        width: 45,
        height: 45
    },
    titleContainer: {
        // marginTop: 5,
        paddingLeft: 13,
        width: '90%'
    },
    titleTxt: {
        lineHeight: '0.6',
        marginBottom: 6,
        cursor: 'pointer',
        height: '18px',
        paddingTop: '4px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        '&:hover': {
            color: theme.palette.primary.dark
        }
    },
    dataTypeTxt: {
        color: theme.palette.grey.dark,
        lineHeight: 0,
        // marginTop: 12,
        textTransform: 'uppercase',
        fontSize: 13
    },
    datasrcFiletype: {
        height: 20,
        marginRight: "5px"
    },
    descriptionTxt: {
        color: theme.palette.grey.dark,
        maxHeight: 35
    },
    catdetailContainer: {
        marginBottom: 13,
        overflow: 'hidden'
    },
    datasetContainer: {
        marginTop: 18,
        '& .MuiChip-root': {
            marginBottom: 0,
            marginTop: 4
        }
    },
    linkChips: {
        '&:hover': {
            backgroundColor: '#3abeff21'
        },
        '& .MuiChip-root': {
            marginBottom: 0
        }
    },
    dqScoreTxt: {
        color: '#222',
        paddingTop: 2
    },
    dqScoreNumber: {
        fontSize: '16px'
    },
    dqscoreimpactContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '15px',
        marginTop: 15,
        '& .MuiSvgIcon-root': {
            fontSize: '2rem',
            margin: '0 -5px'
        }
    },
    dataSourceDefaultImg: {
        width: 44,
        height: 44
    },
    paddingRight15: {
        paddingRight: 20
    },
    lightTxt: {
        color: theme.palette.grey.light,
        fontSize: 13,
        paddingLeft: '5px',
        display: 'inline-flex',
        alignItems: 'center'
        // display: 'inline-block'

    },
    headerContainer: {
        marginTop: 16,
        marginBottom: 10,
        '& .MuiTypography-h2': {
            fontSize: 24
        }
    },
    catHeaderContainer: {
        height: 52
    },
    FilterHideShowContainer: {
        position: 'absolute',
        right: 225,
        background: '#fff',
        zIndex: 99999,
        marginRight: '10px',
        transitionDuration: '500ms',
        transition: 'width .55s ease',
        animation: 'slide-left536 0.4s ease',

        // border: '1px solid #d8d5d5',
        boxShadow: '0px 0px 5px 3px #80808047',
        '& .datasourceFilterSection': {
            minHeight: 60,
            padding: '0 10px'
        }
    },
    addIcon: {
        fontSize: 19,
        color: theme.palette.background.paper
    },

    select: {
        marginRight: 25,
        width: 100,
        '& .MuiFormControl-root': {
            width: 100
        },
        '& img': {
            width: 13,
            verticalAlign: 'middle',
            marginTop: 9,
            display: 'none'
        }
    },
    filterselect: {
        '& .MuiInputBase-input': {
            padding: '1px 0 7px',
            paddingRight: 20
        },
        '& .MuiFormLabel-root': {
            fontSize: '13px',
            color: 'rgba(0, 0, 0)'
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.grey.light}`
        },
        '& .MuiAutocomplete-option': {
            wordBreak: 'break-all !important'
        }
    },
    filterSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& .MuiIconButton-root': {
            padding: 7
        },
        '& .MuiSlider-root': {
            padding: '3px 0 !important'
        },
        '& .searchBox': {
            height: "32.92px !important"
        }
    },
    filterdqscoreText: {
        color: 'rgba(0, 0, 0)'
    },
    search: {
        marginRight: 25,
        '& .MuiPaper-root': {
            height: '32px !important'
        },
        '& .MuiIconButton-root': {
            padding: '9px'
        }
    },
    chip: {
        cursor: 'pointer',
        '& .MuiChip-label': {
            fontSize: 13,
            color: theme.palette.grey.darkTxt
        }
    },
    viewTxt: {
        color: '#041013',
        fontSize: 13,
        marginLeft: 10,
        cursor: 'default'
    },
    datasouceListContainer: {
        overflowY: 'auto',
        paddingRight: 10,
        // padding: 3,
        height: 'calc(100vh - 85px)',
        position: 'relative',
        margin: '0 -20px',
        padding: '20px',
        paddingTop: 5
    },
    nodataFound: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: 'translate(-50%, -50%)'
    },
    noresultfoundTxt: {
        color: theme.palette.grey.default
    },
    ribbon: {
        position: "absolute",
        top: -13,
        left: -12,
        width: 25,
        transform: 'rotate(-45deg)',
        background: theme.palette.primary.main,
        transformOrigin: 0,
        height: 34
        // borderRadius: "30px"
    },
    categoryListLogoTxt: {
        fontWeight: 'bold',
        textTransform: "uppercase"
    },
    menuItem: {
        padding: 5,
        '& .MuiIconButton-root': {
            padding: '0px 6px 0px 0px'
        }
    },
    listTxt: {
        margin: 0,
        '& .MuiTypography-body1': {
            fontSize: 13,
            textTransform: 'capitalize'
        }
    },
    detailuserTxt: {
        marginRight: 10
    },
    detailDescriptionTxt: {
        margin: '6px 0px',
        color: theme.palette.grey.dark
    },
    userAvatar: {
        marginRight: 5
    },
    dataSourceImg: {
        width: '44px',
        height: '44px',
        background: theme.palette.grey.extraLight,
        color: '#fff'
        // padding: 10
    },
    descriptionTooltip: {
        '& .MuiTooltip-popper': {
            maxWidth: 1000
        }
    },
    dataSourceImgIcon: {
        width: '44px',
        height: '44px',
        background: '#e1e5e6',
        color: '#fff',
        '& > img': {
            objectFit: "contain",
            width: '30px'
        }
    },
    sensitivitySelect: {
        display: 'flex',
        '& div': {
            backgroundColor: theme.palette.grey.extraLight,
            borderRadius: 50,
            marginRight: 10,
            width: 20,
            height: 20,
            fontSize: 10,
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.grey.dark,
            marginBottom: 3,
            cursor: 'pointer'
        },
        '& div:last-child': {
            marginRight: 0
        },
        '& .active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.paper
        }
    },
    filterLabel: {
        marginBottom: 10
    },
    marginRight25: {
        marginRight: 25
    },
    slider: {
        width: '100px !important'
    },
    userSelectContainer: {
        // borderBottom: '1px solid #ddd',
        '&:focus': {
            borderBottom: `2px solid ${theme.palette.primary.dark} !important`
        },
        '&> div': {
            display: "flex",
            flex: "1 auto"
        },
        '& .MuiInputBase-input': {
            padding: '2px 0 7px !important'
        },
        '& .MuiFormLabel-root': {
            fontSize: '13px',
            color: 'rgba(0, 0, 0)'
        },
        '& .MuiInput-underline:before': {
            borderBottom: `1px solid ${theme.palette.grey.light} !important`
        },
        marginRight: 10
    },
    dqSearchBox: {
        height: 30
    },
    dqSearchIcon: {
        marginBottom: -5,
        marginRight: '-6px !important'
    },
    filterSlider: {
        '& .MuiSlider-root': {
            padding: '10px 0 !important'
        },
        '& .MuiSlider-rail': {
            backgroundColor: '#ababab'
        },
        '& .MuiSlider-thumb': {
            boxShadow: '0px 0px 2px 2px #8080803d',
            background: '#fff'
        }
    },
    buttonGroup: {
        '& .MuiButton-root:first-child': {
            borderTopLeftRadius: '0px !important',
            borderBottomLeftRadius: '0px !important'
        },
        '& .MuiButton-root:last-child': {
            borderTopRightRadius: '0px !important',
            borderBottomRightRadius: '0px !important'
        }
    },
    noResultFound: {
        height: "100%",
        '& .MuiTypography-root': {
            marginTop: '-50px',
            color: 'gray'
        }
    },
    avatarText: {
        textTransform: 'uppercase'
    },
    pointer: {
        cursor: 'pointer'
    },
    filterImg: {
        background: '#fff',
        margin: '0 10px 0 3px',
        border: '1px solid #c8cbcc',
        borderRadius: '0px',
        padding: 7
    },
    dataAttributeSlider: {
        '& .MuiSlider-root': {
            cursor: 'default'
        }
    },
    cardContainer: {
        marginBottom: 20,
        borderRadius: 0,
        overflow: "hidden",
        border: '1px solid rgba(26,54,126,0.125)',
        background: "#fff",
        '&:last-child': {
            marginBottom: "none"
        },
        '& .MuiCardContent-root:last-child': {
            paddingBottom: '0px'
        },
        '&:hover': {
            boxShadow: '0 4px 25px 0 rgba(0,0,0,.1)',
            transition: 'all 0.2s ease'
        }
    },
    modelListContainer: {
        flexGrow: 0,
        flex: 1,
        maxWidth: '100%',
        flexBasis: '100%',
        padding: 20,
        position: 'relative'
    },
    titleSection: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiTypography-subtitle1': {
            fontSize: 13
        }
    },
    reportTxt: {
        fontSize: 13
    },
    sourceIcon: {
        maxWidth: '100%'
    },
    sensitivitySlider: {
        '& .MuiSlider-thumb': {
            display: 'none'
        }
    },
    sensitivityNumberStyle: {
        backgroundColor: theme.palette.grey.extraLight,
        color: theme.palette.grey.dark,
        textAlign: 'center',
        borderRadius: 50,
        height: 20,
        width: 20
    },
    ConverDatasetFilter: {
        maxWidth: 400
    },
    dataSourceIcon: {
        width: 30,
        height: 30,
        objectFit: 'contain'
    },
    sourceIconContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    domainChip: {
        maxWidth: '27.5%'
    },
    animateIcons: {
        minWidth: '30px !important',
        '& div, svg': {
            position: 'absolute !important',
            left: 0,
            width: 32
        }
    },
    datasetName: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    tagFilter: {
        minWidth: 200,
        maxWidth: 300,
        '& .MuiFormControl-marginNormal': {
            marginBottom: 0
        },
        '& .MuiChip-root': {
            marginBottom: '3px'
        }
    },
    flexCont: {
		display: "flex",
		marginRight: 25,
		alignItems: 'center',
		maxWidth: '100%'
	},
	linkHeading: {
		paddingLeft: 10,
		fontSize: 16,
		cursor: "pointer",
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		"&:hover": {
			color: "#3abeff"
		}
	},
    starLabel: {
		paddingLeft: 5,
		paddingTop: 2,
		fontSize: 13
	},
    catalogListContainer: {
        flexGrow: 0,
        flex: 1,
        maxWidth: '100%',
        flexBasis: '100%',
        paddingTop: 20,
        position: 'relative'
    },
    statusSelect: {
        width: '145px !important',
        '& .MuiFormControl-root': {
            width: '145px !important'
        },
        '& img': {
            display: 'unset',
            marginTop: 'unset',
            verticalAlign: 'unset',
            height: '16px',
            width: '16px'
        },
        '& .MuiSelect-select': {
            display: 'flex'
        },
        '& p': {
            lineHeight: 1
        }
    }
});

export default DatasourceListStyles;