import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import Styles from '../../layouts/Styles.jsx';
import DataPreparationInfo from '../../components/DataPreparation/DataPreparationInfo.jsx';
import DataPreparationConfiguration from '../../components/DataPreparation/DataPreparationConfiguration.jsx';
import { getDataPreparation, deleteDataPreparation, updateDataPreparation } from '../../actions/dataPreparationActions';
import AlertDialog from '../../components/AlertDialog/AlertDialog.jsx';

const DataPreparationDetail = (props) => {
    const { classes, match, location, history } = props;
    const dispatch = useDispatch();
    const dataId = match.params.id;
    const breadcrumb = [
        {
            name: 'Data Preparation',
            path: '/datapreparation'
        },
        {
            name: location.state.source.name,
            path: ''
        }
    ];
    const [isLoading, setLoading] = useState(true);
    const [dataPreparation, setDataPreparation] = useState({
        "name": '',
        "description": '',
        "terms_description": '',
        'dataset_configuration': [],
        'is_disabled': false
    });
    const [isDelete, setDeleteDialog] = useState(false);

    const onChange = useCallback((property, value) => {
        dataPreparation[property] = value;
        setDataPreparation({ ...dataPreparation });
    }, [dataPreparation]);


    const getDataPreparationById = useCallback(() => {
        dispatch(getDataPreparation(dataId)).then((response) => {
            if (response) {
                setDataPreparation({ ...response });
            }
            setLoading(false);
        });
    }, [dataId, dispatch, setLoading]);

    const updateProperties = useCallback((property) => {
        dispatch(updateDataPreparation(dataId, dataPreparation));
    }, [dispatch, dataPreparation, dataId]);


    useEffect(() => {
        getDataPreparationById();
    }, [getDataPreparationById]);


    const deleteData = useCallback(() => {
        dispatch(deleteDataPreparation(dataId)).then(() => {
            history.push('/datapreparation');
        });
    }, [dispatch, history, dataId]);


    const redirect = (data) => {
        if (data.path !== "") {
            history.push('/datapreparation');
        }
    };

    const setTargetPreview = (type, dataset) => {
        history.push({ pathname: `/transform/${dataset.id}`, state: { source: dataPreparation, transform: dataset, type: type } });
    };

    const updatePreparationProperty = useCallback((params) => {
        setDataPreparation({ ...dataPreparation, ...params });
    }, [dataPreparation]);

    const infoAction = useCallback((type) => {
        if (type === "delete") {
            setDeleteDialog(true);
        }
    }, []);

    return (
        <Grid className={classes.container}>
            <ValidatorForm name="master" onSubmit={() => null} >
                <Grid className={classes.paddingTop20}>
                    <DataPreparationInfo
                        redirect={redirect}
                        breadcrumb={breadcrumb}
                        dataPreparation={dataPreparation}
                        dataId={dataId}
                        onSave={updateProperties}
                        onChange={onChange}
                        history={history}
                        infoAction={infoAction}
                        search=""
                    />
                </Grid>
                {
                    !isLoading &&
                    <Grid className={classes.marginTop15} container>
                        <DataPreparationConfiguration
                            dataId={dataId}
                            updatePreparationProperty={(params) => updatePreparationProperty(params)}
                            dataPreparation={dataPreparation}
                            openDataPreivew={(type, source) => setTargetPreview(type, source)} />
                    </Grid>
                }
            </ValidatorForm >
            <AlertDialog title="Delete Data Preparation"
                message={`Are you sure you want to delete the dataset ${dataPreparation ? dataPreparation.name : ''}?`}
                okButtonText="OK"
                cancelButtonText="Cancel"
                show={isDelete}
                onClickOK={() => deleteData()}
                onClickCancel={() => setDeleteDialog(false)} />
        </Grid >
    );
};

DataPreparationDetail.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object
};


export default (withStyles((theme) => ({
    ...Styles(theme)
}))(DataPreparationDetail));