import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography, Avatar, ListItem, List, ListItemAvatar, Paper } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment-timezone';
import { getName, getRandomColor, getInitials, returnImage, getAvatar } from '../../helpers/appHelpers';
import Styles from '../../layouts/Styles.jsx';
import AuditLogsStyles from './AuditLogsStyles.jsx';
import { appConstants } from '../../constants/appConstants';
import { navigatePage } from '../../actions/notificationActions';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';


//Image
import NoDataset from '../../assets/images/NoAuditLog.svg';


const AuditLogs = (props) => {
    const { classes, users, auditLogs, auditLogsLength, fileType, history } = props;
    const dispatch = useDispatch();
    const notificationCount = useSelector(({ notification }) => notification.notificationCount);

    const getLogText = (type, value) => {
        let logText = "";
        let text = "Restricted (Level 3)";
        if (value === "2") {
            text = "Private (Level 2)";
        } else if (value === "1") {
            text = "Public (Level 1)";
        }
        switch (type) {
            case 'create_datasource':
            case 'created datasource':
                logText = 'Created Datasource';
                break;
            case 'update_datasource_icon':
                logText = 'Updated Datasource Icon';
                break;
            case 'create_dataset':
            case 'created dataset':
                logText = "Created Dataset";
                break;
            case 'created Workbook':
                logText = "Created Workbook";
                break;
            case 'update_dataset_icon':
                logText = 'Updated Dataset Icon';
                break;
            case 'datasource_name':
            case 'datasource name':
                logText = `Changed Datasource Name to ${value}`;
                break;
            case 'dataset_name':
            case 'dataset name':
                logText = `Changed Dataset Name to ${value}`;
                break;
            case 'description':
                logText = "Updated Description";
                break;
            case 'tags':
                logText = "Updated Tags";
                break;
            case 'upload_files':
                logText = `Uploaded ${value} Datasets of ${fileType} Data type`;
                break;
            case 'upload':
                logText = `Uploaded ${value} of ${fileType} Data type`;
                break;
            case 'platform_use':
                logText = 'Update Platform Use';
                break;
            case 'terms':
                logText = 'Update Terms Use';
                break;
            case 'user_share':
                logText = `Share User level ${value}`;
                break;
            case 'invite_user':
                logText = `Invite ${text} by`;
                break;
            case 'updated_attribute':
                logText = `Updated dataset atrributes`;
                break;
            case 'Request Access':
                logText = `Request Access`;
                break;
            case 'Share':
            case 'Access Accept':
                logText = `Joined ${text} by`;
                break;
            case 'Decline Access':
                logText = `Decline Access by`;
                break;
            case 'schedule':
                logText = `Scheduling for ${value}`;
                break;
            case 're_schedule':
                logText = `Re scheduling for ${value}`;
                break;
            case 'remove_access':
                logText = `Removed access from ${text} by`;
                break;
            case 'domain':
                logText = 'Update Domain';
                break;
            case 'removeDomain':
                logText = 'Removed Domain';
                break;
            case 'export':
                logText = `${value} exported`;
                break;
            case 'import':
                logText = `${value} imported`;
                break;
            case 'glossary terms':
                logText = "Update glossary terms";
                break;
            case 'add comment':
                logText = "Add new comment";
                break;
            case 'edit comment':
                logText = "Edit a comment";
                break;
            case 'delete comment':
                logText = "Delete a comment";
                break;
            case 'Accept Access':
                logText = `Provided ${text} Access to`;
                break;
            case 'Request Higher Access':
                logText = `Requested Higher Access`;
                break;
            case 'Decline Higher Access':
                logText = `Higher Access Declined by`;
                break;
            default:
                logText = value;
                break;
        }
        return logText;
    };

    const getUserId = (log) => {
        if (log.type === "Share" || log.type === "invite_user" || log.type === "Access Accept" || log.type === "Decline Access" || log.type === "remove_access") {
            return log.request_user_id;
        }
        return log.modified_by_id ? log.modified_by_id : log.modified_by;

    };

    const navigate = (path, title) => {
        if (path !== "") {
            sessionStorage.setItem('navigationPath', title);
            localStorage.setItem('newNotificationCount', notificationCount);
            dispatch(navigatePage(title));
            history.push(`/${path}`);
        }
    };

    return (
        <Grid className={`${classNames(classes.card)} auditLogCard`}>
            {
                auditLogsLength > 0 ?
                    <React.Fragment>
                        <Grid container justify="space-between">
                            {
                                auditLogsLength > 4 &&
                                <Typography variant="body2" className={classes.showAllBtn} onClick={() => navigate('notification', 'Notification')}>
                                    Show all
                                </Typography>
                            }
                        </Grid>
                        <Grid className={classNames(classes.auditLogContainer)}>
                            <List>
                                {
                                    auditLogs && auditLogs.map((log, index) =>
                                        <ListItem key={`log_${index}`} className={classes.list}>
                                            <ListItemAvatar>
                                                <Avatar
                                                    style={
                                                        {
                                                            backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, getAvatar(users, getUserId(log)))})`,
                                                            backgroundColor: getRandomColor(getName(users, getUserId(log)))
                                                        }
                                                    }>
                                                    {
                                                        !returnImage(appConstants.API_BASE_URL, getAvatar(users, getUserId(log))) &&
                                                        <span>
                                                            {getInitials(getName(users, getUserId(log)))}
                                                        </span>
                                                    }
                                                </Avatar>
                                            </ListItemAvatar>
                                            <Grid className={classes.listContainer}>
                                                <ToolTipComponent title={(log.type === "Share" || log.type === "invite_user" || log.type === "Access Accept" || log.type === "Decline Access" || log.type === "remove_access") ? getName(users, log.request_user_id) : getName(users, log.modified_by_id ? log.modified_by_id : log.modified_by) + getLogText(log.type, log.audit_logtext)} arrow>
                                                    <Paper className={classes.auditLogPaper}>
                                                        <Typography component="span" variant="body2" className={classes.logUsername}>
                                                            {(log.type === "Share" || log.type === "invite_user" || log.type === "Access Accept" || log.type === "Decline Access" || log.type === "remove_access" || log.type === "Decline Higher Access") ? getName(users, log.request_user_id) : getName(users, log.modified_by_id ? log.modified_by_id : log.modified_by)}
                                                        </Typography>
                                                        <Typography component="span" variant="body2" className={classes.logTxt}>
                                                            {getLogText(log.type, log.audit_logtext)}
                                                        </Typography>
                                                        {
                                                            (log.type === "Share" || log.type === "invite_user" || log.type === "Access Accept" || log.type === "Decline Access" || log.type === "remove_access" || log.type === "Decline Higher Access") &&
                                                            <Typography component="span" variant="body2" style={{ marginLeft: 5 }} className={classes.logUsername}>
                                                                {getName(users, log.modified_by_id ? log.modified_by_id : log.modified_by)}
                                                            </Typography>
                                                        }
                                                        {
                                                            (log.type === "Accept Access") &&
                                                            <Typography component="span" variant="body2" style={{ marginLeft: 5 }} className={classes.logUsername}>
                                                                {getName(users, log.request_user_id ? log.request_user_id : log.request_user_id)}
                                                            </Typography>
                                                        }
                                                    </Paper>
                                                </ToolTipComponent>

                                                <Typography variant="body2" className={classes.logTimeTxt}>
                                                    {moment(log.modified_date).format('MM/DD/YYYY HH:mm')}
                                                </Typography>
                                            </Grid>
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Grid>
                    </React.Fragment> :
                    <Grid container className={classes.alertNoResult} alignItems="center" justify="center" direction="column">
                        <Grid align="center">
                            <img className={classes.alertnolog} src={NoDataset} alt="No Record Found" />
                            <Typography color="textSecondary" component="h5" variant="h5" align="center" className={classes.marginBottom10}>
                                {"No Logs Found"}
                            </Typography>
                        </Grid>
                    </Grid>
            }
        </Grid>
    );
};

AuditLogs.propTypes = {
    classes: PropTypes.object,
    users: PropTypes.array,
    auditLogs: PropTypes.array,
    fileType: PropTypes.string,
    history: PropTypes.object,
    auditLogsLength: PropTypes.number
};

export default withStyles((theme) => ({
    ...AuditLogsStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(AuditLogs);