import React, { Fragment } from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment-timezone';
import NumberInput from '../../TextBox/NumberInput.jsx';
import { DateTimePicker, DatePicker, TimePicker } from '@material-ui/pickers';
import RuleBuilderStyles from '../QueryBuilderStyles.jsx';

const RangeValueEditor = (props) => {
    const { rule, onChange, onBlur, onKeyDown, classes } = props;

    if (!rule || (rule && (!rule.operator))) {
        return null;
    }

    switch (rule.fieldType.toLowerCase()) {
        case 'numeric':
            return (
                <Grid className={classes.rangeContainer}>
                    <Fragment>
                        <NumberInput className={classNames(classes.inputStyle)}
                            onChange={
                                (event) => {
                                    const value = rule.value;
                                    value[0] = event.target.value;
                                    onChange(value);
                                }
                            }
                            onBlur={
                                (event) => {
                                    const value = rule.value;
                                    value[0] = event.target.value;
                                    onBlur(value);
                                }
                            }
                            onKeyDown={(event) => onKeyDown(event)}
                            validators={['required']}
                            errorMessages={['Value is required']}
                            value={(rule.value && rule.value.length === 2) ? rule.value[0] : ''}
                            fullWidth />
                        <Typography>
                            {'and'}
                        </Typography>
                        <NumberInput className={classNames(classes.inputStyle)}
                            onChange={
                                (event) => {
                                    const value = rule.value;
                                    value[1] = event.target.value;
                                    onChange(value);
                                }
                            }
                            onBlur={
                                (event) => {
                                    const value = rule.value;
                                    value[1] = event.target.value;
                                    onBlur(value);
                                }
                            }
                            onKeyDown={(event) => onKeyDown(event)}
                            validators={['required']}
                            errorMessages={['Value is required']}
                            value={(rule.value && rule.value.length === 2) ? rule.value[1] : ''}
                            fullWidth />
                    </Fragment>
                </Grid>
            );
        case 'datetime':
            return (
                <Grid className={classes.rangeContainer}>
                    <Fragment>
                        {
                            rule.attributeType && rule.attributeType.toString().toLowerCase() === 'datetime' &&
                            <Fragment>
                                <DateTimePicker autoOk
                                    className={classNames(classes.inputStyle)}
                                    variant="inline"
                                    format="MM-DD-YYYY HH:mm"
                                    ampm={false}
                                    value={(rule.value && rule.value.length === 2) ? rule.value[0] : Date.now()}
                                    onChange={
                                        (date) => {
                                            const value = rule.value;
                                            value[0] = moment(date);
                                            onChange(value);
                                        }
                                    }
                                    onBlur={() => onBlur(rule.value)}
                                />
                                <Typography>
                                    {'and'}
                                </Typography>
                                <DateTimePicker autoOk
                                    className={classNames(classes.inputStyle)}
                                    variant="inline"
                                    format="MM-DD-YYYY HH:mm"
                                    ampm={false}
                                    value={(rule.value && rule.value.length === 2) ? rule.value[1] : Date.now()}
                                    onChange={
                                        (date) => {
                                            const value = rule.value;
                                            value[1] = moment(date);
                                            onChange(value);
                                        }
                                    }
                                    onBlur={() => onBlur(rule.value)}
                                />
                            </Fragment>
                        }
                        {
                            rule.attributeType && rule.attributeType.toString().toLowerCase() === 'date' &&
                            <Fragment>
                                <DatePicker autoOk
                                    className={classNames(classes.inputStyle)}
                                    variant="inline"
                                    format="MM-DD-YYYY"
                                    value={(rule.value && rule.value.length === 2) ? rule.value[0] : Date.now()}
                                    onChange={
                                        (date) => {
                                            const value = rule.value;
                                            value[0] = moment(date);
                                            onChange(value);
                                        }
                                    }
                                    onBlur={() => onBlur(rule.value)}
                                />
                                <Typography>
                                    {'and'}
                                </Typography>
                                <DatePicker autoOk
                                    className={classNames(classes.inputStyle)}
                                    variant="inline"
                                    format="MM-DD-YYYY"
                                    value={(rule.value && rule.value.length === 2) ? rule.value[1] : Date.now()}
                                    onChange={
                                        (date) => {
                                            const value = rule.value;
                                            value[1] = moment(date);
                                            onChange(value);
                                        }
                                    }
                                    onBlur={() => onBlur(rule.value)}
                                />
                            </Fragment>
                        }
                        {
                            rule.attributeType && rule.attributeType.toString().toLowerCase() === 'time' &&
                            <Fragment>
                                <TimePicker autoOk
                                    className={classNames(classes.inputStyle)}
                                    variant="inline"
                                    format="HH:mm"
                                    value={(rule.value && rule.value.length === 2) ? rule.value[0] : Date.now()}
                                    onChange={
                                        (date) => {
                                            const value = rule.value;
                                            value[0] = moment(date);
                                            onChange(value);
                                        }
                                    }
                                    onBlur={() => onBlur(rule.value)}
                                />
                                <Typography>
                                    {'and'}
                                </Typography>
                                <TimePicker autoOk
                                    className={classNames(classes.inputStyle)}
                                    variant="inline"
                                    format="HH:mm"
                                    value={(rule.value && rule.value.length === 2) ? rule.value[1] : Date.now()}
                                    onChange={
                                        (date) => {
                                            const value = rule.value;
                                            value[1] = moment(date);
                                            onChange(value);
                                        }
                                    }
                                    onBlur={() => onBlur(rule.value)}
                                />
                            </Fragment>
                        }
                    </Fragment>
                </Grid>
            );
        default:
            return null;
    }
};

RangeValueEditor.propTypes = {
    classes: PropTypes.object,
    rule: PropTypes.object,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func
};

export default withStyles(RuleBuilderStyles)(RangeValueEditor);