import React, { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Typography, withStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import Styles from '../../../layouts/Styles.jsx';
import MasterStyles from '../MasterStyles.jsx';
import { appConstants } from '../../../constants/appConstants.js';
import DroppableTableBody from './DroppableTableBody.jsx';
import AlertDialog from '../../AlertDialog/AlertDialog.jsx';
import DraggableTableRow from './DraggableTableRow.jsx';
import MatchCombinationInput from './MatchCombinationInput.jsx';
import MatchLevelInput from './MatchLevelInput.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { createMatchCombination, deleteMatchCombination, getMatchCombinations, updateMatchCombination, updateMatchCombinationRank } from '../../../actions/masterActions.js';
import Loader from '../../Loaders/Loader.jsx';
import NoResultFound from '../../NoResultFound/NoResultFound.jsx';
import { sortTable } from '../../../helpers/appHelpers.js';
import ToolTipComponent from '../../Tooltip/Tooltip.jsx';


const MasterGroups = (props) => {
    const { classes, theme, modelId, tabIndex, isEditable, onChangeModel } = props;
    const attributes = useSelector(({ master }) => master.attributes);
    const headers = appConstants.masterGroupTableHeader;
    const dispatch = useDispatch();
    const [defaultGroup] = useState({
        rank: 1,
        attributes: [],
        prefix: '',
        level: 1,
        record_count: 0
    });
    const [isLoading, setIsLoading] = useState(false);
    const [groups, setGroups] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');

    const onChangeSortOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    const updateRank = useCallback((groups) => {
        const matchCombination = {
            groups: [...groups],
            'model_id': modelId
        };
        dispatch(updateMatchCombinationRank(matchCombination));
        onChangeModel('status', 'work in progress');
    }, [dispatch, modelId, onChangeModel]);

    const saveCombination = useCallback((group, index) => {
        const matchCombination = {
            ...group,
            'model_id': modelId
        };
        if (!group.id) {
            dispatch(createMatchCombination(matchCombination)).then((response) => {
                if (response) {
                    groups.splice(index, 1, { ...group, id: response.id });
                    setGroups([...groups]);
                    onChangeModel('status', 'work in progress');
                }
            });
        } else {
            dispatch(updateMatchCombination(matchCombination));
            onChangeModel('status', 'work in progress');
        }
    }, [dispatch, groups, modelId, onChangeModel]);

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const addGroup = useCallback(() => {
        groups.push({ ...defaultGroup });
        let rank = 1;
        for (const group of groups) {
            group.rank = rank;
            rank += 1;
        }
        setGroups([...groups]);
    }, [defaultGroup, groups]);

    const onAlertCancel = useCallback(() => {
        setTimeout(() => {
            if (selectedGroup) {
                setSelectedGroup(null);
            }
        }, 200);
    }, [selectedGroup]);

    const deleteGroup = useCallback((selectedGroup) => {
        const index = groups.indexOf(selectedGroup);
        if (index > -1) {
            groups.splice(index, 1);
            let rank = 1;
            for (const group of groups) {
                group.rank = rank;
                rank += 1;
            }
            setGroups([...groups]);
            if (selectedGroup.id) {
                dispatch(deleteMatchCombination(modelId, selectedGroup.id));
                onChangeModel('status', 'work in progress');
            }
            onAlertCancel();
        }
    }, [dispatch, groups, modelId, onAlertCancel, onChangeModel]);

    const onChangeCombinations = useCallback((property, value, index) => {
        const selectedGroup = groups[index];
        selectedGroup[property] = value;
        selectedGroup.attributes = selectedGroup.attributes.map((d) => (typeof(d) === 'object' ? d.name : d));
        if (property === 'attributes') {
            const prefix = selectedGroup.attributes.reduce((prefix, attribute) => {
                prefix = attribute && attribute.length > 0 ? `${prefix}${attribute.substring(0, 1).toUpperCase()}` : prefix;
                return prefix;
            }, '');
            selectedGroup.prefix = prefix;
        }
        groups.splice(index, 1, { ...selectedGroup });
        setGroups([...groups]);
        saveCombination({ ...selectedGroup }, index);
    }, [groups, saveCombination]);

    const onDragEnd = useCallback((result) => {
        if (!result.destination) {
            return;
        }
        const orderedItems = reorder(
            groups,
            result.source.index,
            result.destination.index
        );
        let rank = 1;
        for (const group of orderedItems) {
            group.rank = rank;
            rank += 1;
        }
        setGroups([...orderedItems]);
        updateRank(orderedItems);
    }, [groups, updateRank]);


    const loadGroups = useCallback((modelId) => {
        dispatch(getMatchCombinations(modelId)).then((response) => {
            setOrder('asc');
            setOrderBy('');
            if (response && response.match) {
                response.match = response.match.map((match) => {
                    const matchPercentageData = response.match_result ? response.match_result.find((data) => data.RecCountByValue === match.prefix) : {};
                    return {
                        ...match,
                        matchPercentage: matchPercentageData && matchPercentageData.RecPercentage ? matchPercentageData.RecPercentage : null
                    };
                });
            }
            setGroups(response && response.match ? [...response.match] : [{ ...defaultGroup }]);
            setIsLoading(false);
        });
    }, [defaultGroup, dispatch]);

    useEffect(() => {
        if (tabIndex !== 2 || !modelId) {
            return;
        }
        setIsLoading(true);
        loadGroups(modelId);
    }, [loadGroups, modelId, tabIndex]);

    return (
        <Grid container direction="column" className={classes.groupsContainer}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.actionBar}>
                <Grid container direction="row" justify="flex-end" alignItems="center">
                    {
                        (isEditable && (attributes && attributes.length > 0)) &&
                        <Grid item>
                            <IconButton onClick={() => addGroup()} className={classes.addIconButton}>
                                <AddIcon />
                            </IconButton>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper}>
                    {
                        (groups && groups.length > 0) && (attributes && attributes.length > 0) &&
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={'dragHandlerContainer'}>
                                        <DragIndicatorIcon />
                                    </TableCell>
                                    {
                                        headers && headers.map((header, index) => {
                                            return (
                                                <TableCell key={`master_group_table_header_${index}`} className={header.key}>
                                                    {
                                                        header.isSortable ?
                                                            <TableSortLabel active={orderBy === header.key}
                                                                direction={orderBy === header.key ? order : 'asc'}
                                                                onClick={() => onChangeSortOrder(header.key)}
                                                            >
                                                                <Typography>
                                                                    {header.title}
                                                                </Typography>
                                                            </TableSortLabel>
                                                            :
                                                            <Typography>
                                                                {header.title}
                                                            </Typography>
                                                    }

                                                </TableCell>
                                            );
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody component={DroppableTableBody(onDragEnd)}>
                                {
                                    groups && sortTable(groups, order, orderBy).map((group, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <TableRow component={DraggableTableRow(`row_${index}`, index, groups ? groups.length : 0)}>
                                                    <TableCell>
                                                        <Typography>
                                                            {group.rank ? group.rank : 0}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.fixedChipWidth}>
                                                        <MatchCombinationInput
                                                            matchAttributes={group.attributes && group.attributes.length > 0 ? [...group.attributes] : []}
                                                            rowIndex={index}
                                                            onValueChange={(...props) => onChangeCombinations(...props)} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography>
                                                            {group.prefix ? group.prefix : ''}
                                                            <Typography component="span" style={{ marginLeft: 10 }}>
                                                                {`(${group.record_count})`}
                                                            </Typography>
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <MatchLevelInput
                                                            enabled={Boolean(group.id)}
                                                            matchLevel={group.level ? group.level : ''}
                                                            rowIndex={index}
                                                            onValueChange={(...props) => onChangeCombinations(...props)} />
                                                    </TableCell>
                                                    <TableCell>
                                                        <ToolTipComponent title="Delete">
                                                            <IconButton onClick={() => setSelectedGroup(group)}>
                                                                <svg version="1.1" id="Layer_1" xmlns="https://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 32 32">
                                                                    <g id="Delete">
                                                                        <path fill={theme.palette.primary.main}
                                                                            stroke={theme.palette.primary.main}
                                                                            strokeWidth="0.92"
                                                                            strokeMiterlimit="10"
                                                                            d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                                                        <path fill={theme.palette.primary.main}
                                                                            stroke={theme.palette.primary.main}
                                                                            strokeWidth="0.92"
                                                                            strokeMiterlimit="10"
                                                                            d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                                    </g>
                                                                </svg>
                                                            </IconButton>
                                                        </ToolTipComponent>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow className={classes.emptyBorder}>
                                                    <TableCell colSpan={(headers.length + 1)} />
                                                </TableRow>
                                            </Fragment>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    }
                    {
                        (!groups || (groups && groups.length === 0)) &&
                        <NoResultFound text={attributes && attributes.length === 0 ? 'No attributes defined' : ''} />
                    }
                </TableContainer>
                {isLoading && <Loader />}
                <AlertDialog title={"Delete Match Combination"}
                    message={"Are you sure to delete the selected combination?"}
                    okButtonText="OK"
                    cancelButtonText={selectedGroup ? "Cancel" : ""}
                    show={Boolean(selectedGroup)}
                    onClickOK={
                        () => {
                            if (selectedGroup) {
                                deleteGroup(selectedGroup);
                            } else {
                                onAlertCancel();
                            }
                        }
                    }
                    onClickCancel={() => onAlertCancel()} />
            </Grid>
        </Grid>
    );
};

MasterGroups.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    modelId: PropTypes.string,
    tabIndex: PropTypes.number,
    isEditable: PropTypes.bool,
    onChangeModel: PropTypes.func
};

export default withStyles((theme) => ({
    ...MasterStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MasterGroups);