import React, { useState, useEffect, useCallback } from 'react';
import { Grid, withStyles, Typography, Switch, Divider, FormControlLabel, FormGroup, AccordionSummary, TextField, Accordion, AccordionDetails } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import Loader from '../Loaders/Loader.jsx';
import classnames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getOrganizationNotificationConfig, updateOrganizationNotificationConfig } from '../../actions/settingActions';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import EmailConfig from './EmailConfig.jsx';

const Notification = (props) => {
    const { classes, organizationId } = props;
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [notificationDetail, setNotificationDetail] = useState({ "alert_config": { "on": false }, "push_notification_config": { "on": false }, "email_notification_config": { "on": false }, "slack_notification_config": { "on": false }, "teams_notification_config": { "on": false } });
    const licenseConfig = useSelector(({ setting }) => setting.licenseConfig);
    const config = useSelector(({ setting }) => setting.config);

    const getNotificationDetail = useCallback(() => {
        setLoading(true);
        dispatch(getOrganizationNotificationConfig(organizationId)).then((response) => {
            if (response) {
                if (!response.alert_config) {
                    response['alert_config'] = { on: false };
                }
                if (!response.teams_notification_config) {
                    response['teams_notification_config'] = { on: false };
                }
                if (!response.slack_notification_config) {
                    response['slack_notification_config'] = { on: false };
                }
                if (!response.push_notification_config) {
                    response['push_notification_config'] = { on: false };
                }
                if (!response.email_notification_config) {
                    response['email_notification_config'] = { on: false };
                }
                setNotificationDetail({ ...response });
            }
            setLoading(false);
        });
    }, [dispatch, organizationId]);

    const updateNotification = (params) => {
        dispatch(updateOrganizationNotificationConfig(organizationId, params));
    };

    const changeProperty = (property, name, value) => {
        if (property === "alert_config") {
            notificationDetail[property][name] = value;
        } else if (name === "on") {
            if (property === "email_notification_config") {
                notificationDetail[property] = {
                    "on": value,
                    "schedule": value,
                    "actionitem": value,
                    "permissioning": value,
                    "drift": value,
                    'audit': value,
                    "Monitoring": value,
                    "custom_rule": value,
                    "email_config": notificationDetail?.email_notification_config?.email_config ?? null
                };
            } else {
                notificationDetail[property] = {
                    "on": value,
                    "schedule": value,
                    "actionitem": value,
                    "permissioning": value,
                    "drift": value,
                    'audit': value,
                    "Monitoring": value
                };
            }
        } else if (name === "drift") {
            notificationDetail[property][name] = value;
            notificationDetail[property].statistics = value;
        } else {
            notificationDetail[property][name] = value;
        }
        if (name !== "channel" && name !== "token" && name !== "webhook_url") {
            updateNotification(notificationDetail);
        }
        setNotificationDetail({ ...notificationDetail });
    };

    useEffect(() => {
        if (organizationId !== 0) {
            getNotificationDetail();
        }
    }, [organizationId, getNotificationDetail]);

    return (
        <ValidatorForm form="notification" onSubmit={() => null} >
            {isLoading && <Loader />}
            <Grid container justify="space-between" className={classes.moduleSection}>
                <Grid item>
                    <Typography className={classes.moduleTitle}>
                        Alert Limit
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        className={classes.formLabel}
                        control={
                            <Switch
                                color="secondary"
                                name="on"
                                value="on"
                                checked={Boolean(notificationDetail.alert_config.on)}
                                onChange={(event) => changeProperty("alert_config", event.target.name, !notificationDetail.alert_config.on)}
                            />
                        }
                        label="ON"
                    />
                </Grid>
                <Divider className={classes.divider} />
                {
                    notificationDetail.alert_config.on &&
                    <Grid item xs={12} className={classes.formGroup}>
                        <FormGroup row>
                            <Grid xs={3}>
                                <TextField
                                    label="Number Of Alerts"
                                    style={{ width: '200px' }}
                                    value={notificationDetail && notificationDetail.alert_config ? notificationDetail.alert_config.alertCount : ''}
                                    onChange={(event) => changeProperty("alert_config", event.target.name, event.target.value)}
                                    name="alertCount"
                                    helperText={notificationDetail && notificationDetail.alert_config && notificationDetail.alert_config.alertCount === '' ? "Alert count is required" : null}
                                    FormHelperTextProps={
                                        {
                                            style: {
                                                color: 'red'
                                            }
                                        }
                                    }
                                />
                            </Grid>
                            <Grid xs={3} className={classes.driftTime}>
                                <TextField
                                    className={classes.alertText}
                                    label="Time Period"
                                    style={{ width: '100px' }}
                                    value={notificationDetail && notificationDetail.alert_config ? notificationDetail.alert_config.alertTime : ''}
                                    onChange={(event) => changeProperty("alert_config", event.target.name, event.target.value)}
                                    name="alertTime"
                                    helperText={notificationDetail && notificationDetail.alert_config && notificationDetail.alert_config.alertTime === '' ? "Time is required" : null}
                                    FormHelperTextProps={
                                        {
                                            style: {
                                                color: 'red'
                                            }
                                        }
                                    }
                                />
                                <AutoCompleteInput
                                    className={classes.driftType}
                                    label="Type"
                                    name={'alertType'}
                                    style={{ width: '50px' }}
                                    popperWidth={400}
                                    options={['Hours', 'Days', 'Weeks']}
                                    value={notificationDetail && notificationDetail.alert_config ? notificationDetail.alert_config.alertType : ''}
                                    renderInput={
                                        (params) => <TextField {...params}
                                            className={classnames(classes.autocompleteWidth, classes.hoverBgGrey)}
                                            placeholder="Type" />
                                    }
                                    onChange={(_, selectedValue) => changeProperty("alert_config", "alertType", selectedValue)}
                                />
                            </Grid>
                        </FormGroup>
                    </Grid>
                }
            </Grid>
            <Grid container justify="space-between" className={classes.moduleSection}>
                <Grid item>
                    <Typography className={classes.moduleTitle}>
                        Push Notification
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        className={classes.formLabel}
                        control={
                            <Switch
                                color="secondary"
                                name="on"
                                value="on"
                                checked={Boolean(notificationDetail.push_notification_config.on)}
                                onChange={(event) => changeProperty("push_notification_config", event.target.name, !notificationDetail.push_notification_config.on)}
                            />
                        }
                        label="ON"
                    />
                </Grid>
                <Divider className={classes.divider} />
                {
                    notificationDetail.push_notification_config.on &&
                    <Grid item xs={12} className={classes.formGroup}>
                        <FormGroup row>
                            {
                                config.drift &&
                                <FormControlLabel
                                    className={classnames(classes.formLabelSection, classes.formLabel)}
                                    control={
                                        <Switch
                                            color="secondary"
                                            name="drift"
                                            checked={Boolean(notificationDetail.push_notification_config.drift)}
                                            disabled={!notificationDetail.push_notification_config.on}
                                            onChange={(event) => changeProperty("push_notification_config", event.target.name, !notificationDetail.push_notification_config.drift)} />
                                    }
                                    label="Drift"
                                />
                            }
                            {
                                config.monitoring &&
                                <FormControlLabel
                                    className={classnames(classes.formLabelSection, classes.formLabel)}
                                    control={
                                        <Switch
                                            color="secondary"
                                            name="Monitoring"
                                            checked={Boolean(notificationDetail.push_notification_config.Monitoring)}
                                            disabled={!notificationDetail.push_notification_config.on}
                                            onChange={(event) => changeProperty("push_notification_config", event.target.name, !notificationDetail.push_notification_config.Monitoring)} />
                                    }
                                    label="Monitoring"
                                />
                            }
                            {
                                config.action_items &&
                                <FormControlLabel
                                    className={classnames(classes.formLabelSection, classes.formLabel)}
                                    control={
                                        <Switch
                                            color="secondary"
                                            name="actionitem"
                                            checked={Boolean(notificationDetail.push_notification_config.actionitem)}
                                            disabled={!notificationDetail.push_notification_config.on}
                                            onChange={(event) => changeProperty("push_notification_config", event.target.name, !notificationDetail.push_notification_config.actionitem)} />
                                    }
                                    label="ActionItem"
                                />
                            }
                            <FormControlLabel
                                className={classnames(classes.formLabelSection, classes.formLabel)}
                                control={
                                    <Switch
                                        color="secondary"
                                        name="permissioning"
                                        checked={Boolean(notificationDetail.push_notification_config.permissioning)}
                                        disabled={!notificationDetail.push_notification_config.on}
                                        onChange={(event) => changeProperty("push_notification_config", event.target.name, !notificationDetail.push_notification_config.permissioning)} />
                                }
                                label="Permissioning"
                            />
                            <FormControlLabel
                                className={classnames(classes.formLabelSection, classes.formLabel)}
                                control={
                                    <Switch
                                        color="secondary"
                                        name="audit"
                                        checked={Boolean(notificationDetail.push_notification_config.audit)}
                                        disabled={!notificationDetail.push_notification_config.on}
                                        onChange={(event) => changeProperty("push_notification_config", event.target.name, !notificationDetail.push_notification_config.audit)} />
                                }
                                label="AuditLogs"
                            />
                            <FormControlLabel
                                className={classnames(classes.formLabelSection, classes.formLabel)}
                                control={
                                    <Switch
                                        color="secondary"
                                        name="schedule"
                                        checked={Boolean(notificationDetail.push_notification_config.schedule)}
                                        disabled={!notificationDetail.push_notification_config.on}
                                        onChange={(event) => changeProperty("push_notification_config", event.target.name, !notificationDetail.push_notification_config.schedule)} />
                                }
                                label="Schedule"
                            />
                        </FormGroup>
                    </Grid>
                }
            </Grid>
            <Grid container justify="space-between" className={classes.moduleSection}>
                <Grid item>
                    <Typography className={classes.moduleTitle}>
                        Email Notification
                    </Typography>
                </Grid>
                <Grid item>
                    <FormControlLabel
                        className={classes.formLabel}
                        control={
                            <Switch
                                color="secondary"
                                name="on"
                                value="on"
                                checked={Boolean(notificationDetail.email_notification_config.on)}
                                onChange={(event) => changeProperty("email_notification_config", event.target.name, !notificationDetail.email_notification_config.on)}
                            />
                        }
                        label="ON"
                    />
                </Grid>
                <Divider className={classes.divider} />
                {
                    notificationDetail.email_notification_config.on &&
                    <Grid item xs={12} className={classes.formGroup}>
                        <FormGroup row>
                            {
                                config.drift &&
                                <Accordion className={classnames(classes.storageAcoordion, classes.formAccordian)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2a-content"
                                        id="panel2a-header">
                                        <FormControlLabel
                                            className={classnames(classes.formLabel)}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="drift"
                                                    checked={Boolean(notificationDetail.email_notification_config.drift)}
                                                    disabled={!notificationDetail.email_notification_config.on}
                                                    onChange={
                                                        (event) => {
                                                            event.stopPropagation();
                                                            changeProperty("email_notification_config", event.target.name, !notificationDetail.email_notification_config.drift);
                                                        }
                                                    } />
                                            }
                                            label="Drift"
                                        />
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormControlLabel
                                            className={classnames(classes.formLabel)}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="statistics"
                                                    checked={Boolean(notificationDetail.email_notification_config.statistics)}
                                                    disabled={!notificationDetail.email_notification_config.on || !notificationDetail.email_notification_config.drift}
                                                    onChange={(event) => changeProperty("email_notification_config", event.target.name, !notificationDetail.email_notification_config.statistics)} />
                                            }
                                            label="Statistical Report"
                                        />
                                    </AccordionDetails>
                                </Accordion>
                            }
                            {
                                config.monitoring &&
                                <FormControlLabel
                                    className={classnames(classes.formLabelSection, classes.formLabel)}
                                    control={
                                        <Switch
                                            color="secondary"
                                            name="Monitoring"
                                            checked={Boolean(notificationDetail.email_notification_config.Monitoring)}
                                            disabled={!notificationDetail.email_notification_config.on}
                                            onChange={(event) => changeProperty("email_notification_config", event.target.name, !notificationDetail.email_notification_config.Monitoring)} />
                                    }
                                    label="Monitoring"
                                />
                            }
                            {
                                config.action_items &&
                                <FormControlLabel
                                    className={classnames(classes.formLabelSection, classes.formLabel)}
                                    control={
                                        <Switch
                                            color="secondary"
                                            name="actionitem"
                                            checked={Boolean(notificationDetail.email_notification_config.actionitem)}
                                            disabled={!notificationDetail.email_notification_config.on}
                                            onChange={(event) => changeProperty("email_notification_config", event.target.name, !notificationDetail.email_notification_config.actionitem)} />
                                    }
                                    label="ActionItem"
                                />
                            }
                            <FormControlLabel
                                className={classnames(classes.formLabelSection, classes.formLabel)}
                                control={
                                    <Switch
                                        color="secondary"
                                        name="permissioning"
                                        checked={Boolean(notificationDetail.email_notification_config.permissioning)}
                                        disabled={!notificationDetail.email_notification_config.on}
                                        onChange={(event) => changeProperty("email_notification_config", event.target.name, !notificationDetail.email_notification_config.permissioning)} />
                                }
                                label="Permissioning"
                            />
                            <FormControlLabel
                                className={classnames(classes.formLabelSection, classes.formLabel)}
                                control={
                                    <Switch
                                        color="secondary"
                                        name="audit"
                                        checked={Boolean(notificationDetail.email_notification_config.audit)}
                                        disabled={!notificationDetail.email_notification_config.on}
                                        onChange={(event) => changeProperty("email_notification_config", event.target.name, !notificationDetail.email_notification_config.audit)} />
                                }
                                label="AuditLogs"
                            />
                            <FormControlLabel
                                className={classnames(classes.formLabelSection, classes.formLabel)}
                                control={
                                    <Switch
                                        color="secondary"
                                        name="schedule"
                                        checked={Boolean(notificationDetail.email_notification_config.schedule)}
                                        disabled={!notificationDetail.email_notification_config.on}
                                        onChange={(event) => changeProperty("email_notification_config", event.target.name, !notificationDetail.email_notification_config.schedule)} />
                                }
                                label="Schedule"
                            />
                            <FormControlLabel
                                className={classnames(classes.formLabel)}
                                control={
                                    <Switch
                                        color="secondary"
                                        name="custom_rule"
                                        checked={Boolean(notificationDetail.email_notification_config.custom_rule)}
                                        disabled={!notificationDetail.email_notification_config.on}
                                        onChange={
                                            (event) => {
                                                event.stopPropagation();
                                                changeProperty("email_notification_config", event.target.name, !notificationDetail.email_notification_config.custom_rule);
                                            }
                                        } />
                                }
                                label="Custom Rule Notification"
                            />
                        </FormGroup>
                    </Grid>
                }
                {
                    notificationDetail.email_notification_config.on &&
                    <React.Fragment>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.formGroup}>
                            <Grid container>
                                <EmailConfig organizationId={organizationId} config={notificationDetail?.email_notification_config?.email_config ?? null} notificationConfig={notificationDetail} />
                            </Grid>
                        </Grid>
                        <Divider className={classes.divider} />
                    </React.Fragment>
                }
            </Grid>
            {
                licenseConfig.slack &&
                <Grid container justify="space-between" className={classes.moduleSection}>
                    <Grid item>
                        <Typography className={classes.moduleTitle}>
                            Slack Notification
                        </Typography>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            className={classes.formLabel}
                            control={
                                <Switch
                                    color="secondary"
                                    name="on"
                                    value="on"
                                    checked={Boolean(notificationDetail.slack_notification_config.on)}
                                    onChange={(event) => changeProperty("slack_notification_config", event.target.name, !notificationDetail.slack_notification_config.on)}
                                />
                            }
                            label="ON"
                        />
                    </Grid>
                    <Divider className={classes.divider} />
                    {
                        notificationDetail.slack_notification_config.on &&
                        <Grid item xs={12}>
                            <Grid item xs={12} className={classes.formGroup}>
                                <FormGroup row>
                                    {
                                        config.drift &&
                                        <FormControlLabel
                                            className={classnames(classes.formLabelSection, classes.formLabel)}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="drift"
                                                    checked={Boolean(notificationDetail.slack_notification_config.drift)}
                                                    disabled={!notificationDetail.slack_notification_config.on}
                                                    onChange={(event) => changeProperty("slack_notification_config", event.target.name, !notificationDetail.slack_notification_config.drift)} />
                                            }
                                            label="Drift"
                                        />
                                    }
                                    {
                                        config.monitoring &&
                                        <FormControlLabel
                                            className={classnames(classes.formLabelSection, classes.formLabel)}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="Monitoring"
                                                    checked={Boolean(notificationDetail.slack_notification_config.Monitoring)}
                                                    disabled={!notificationDetail.slack_notification_config.on}
                                                    onChange={(event) => changeProperty("slack_notification_config", event.target.name, !notificationDetail.slack_notification_config.Monitoring)} />
                                            }
                                            label="Monitoring"
                                        />
                                    }
                                    {
                                        config.action_items &&

                                        <FormControlLabel
                                            className={classnames(classes.formLabelSection, classes.formLabel)}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="actionitem"
                                                    checked={Boolean(notificationDetail.slack_notification_config.actionitem)}
                                                    disabled={!notificationDetail.slack_notification_config.on}
                                                    onChange={(event) => changeProperty("slack_notification_config", event.target.name, !notificationDetail.slack_notification_config.actionitem)} />
                                            }
                                            label="ActionItem"
                                        />
                                    }
                                    <FormControlLabel
                                        className={classnames(classes.formLabelSection, classes.formLabel)}
                                        control={
                                            <Switch
                                                color="secondary"
                                                name="permissioning"
                                                checked={Boolean(notificationDetail.slack_notification_config.permissioning)}
                                                disabled={!notificationDetail.slack_notification_config.on}
                                                onChange={(event) => changeProperty("slack_notification_config", event.target.name, !notificationDetail.slack_notification_config.permissioning)} />
                                        }
                                        label="Permissioning"
                                    />
                                    <FormControlLabel
                                        className={classnames(classes.formLabelSection, classes.formLabel)}
                                        control={
                                            <Switch
                                                color="secondary"
                                                name="audit"
                                                checked={Boolean(notificationDetail.slack_notification_config.audit)}
                                                disabled={!notificationDetail.slack_notification_config.on}
                                                onChange={(event) => changeProperty("slack_notification_config", event.target.name, !notificationDetail.slack_notification_config.audit)} />
                                        }
                                        label="AuditLogs"
                                    />
                                    <FormControlLabel
                                        className={classnames(classes.formLabelSection, classes.formLabel)}
                                        control={
                                            <Switch
                                                color="secondary"
                                                name="schedule"
                                                checked={Boolean(notificationDetail.slack_notification_config.schedule)}
                                                disabled={!notificationDetail.slack_notification_config.on}
                                                onChange={(event) => changeProperty("slack_notification_config", event.target.name, !notificationDetail.slack_notification_config.schedule)} />
                                        }
                                        label="Schedule"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} className={classes.formGroup}>
                                <Typography>
                                    Slack Configuration
                                </Typography>
                                <Divider className={classes.divider} />
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <TextBox
                                            label="Web Hook"
                                            name="webhook_url"
                                            onChange={(event) => changeProperty("slack_notification_config", event.target.name, event.target.value)}
                                            value={notificationDetail.slack_notification_config.webhook_url ? notificationDetail.slack_notification_config.webhook_url : ""}
                                            fullWidth
                                            onBlur={() => (notificationDetail.slack_notification_config.webhook_url !== "" ? updateNotification(notificationDetail) : null)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            }
            {
                licenseConfig.teams &&
                <Grid container justify="space-between" className={classes.moduleSection}>
                    <Grid item>
                        <Typography className={classes.moduleTitle}>
                            Teams Notification
                        </Typography>
                    </Grid>
                    <Grid item>
                        <FormControlLabel
                            className={classes.formLabel}
                            control={
                                <Switch
                                    color="secondary"
                                    name="on"
                                    value="on"
                                    checked={Boolean(notificationDetail.teams_notification_config && notificationDetail.teams_notification_config.on)}
                                    onChange={(event) => changeProperty("teams_notification_config", event.target.name, !notificationDetail.teams_notification_config.on)}
                                />
                            }
                            label="ON"
                        />
                    </Grid>
                    <Divider className={classes.divider} />
                    {
                        notificationDetail.teams_notification_config.on &&
                        <Grid item xs={12}>
                            <Grid item xs={12} className={classes.formGroup}>
                                <FormGroup row>
                                    {
                                        config.drift &&
                                        <FormControlLabel
                                            className={classnames(classes.formLabelSection, classes.formLabel)}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="drift"
                                                    checked={Boolean(notificationDetail.teams_notification_config.drift)}
                                                    disabled={!notificationDetail.teams_notification_config.on}
                                                    onChange={(event) => changeProperty("teams_notification_config", event.target.name, !notificationDetail.teams_notification_config.drift)} />
                                            }
                                            label="Drift"
                                        />
                                    }
                                    {
                                        config.monitoring &&
                                        <FormControlLabel
                                            className={classnames(classes.formLabelSection, classes.formLabel)}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="Monitoring"
                                                    checked={Boolean(notificationDetail.teams_notification_config.Monitoring)}
                                                    disabled={!notificationDetail.teams_notification_config.on}
                                                    onChange={(event) => changeProperty("teams_notification_config", event.target.name, !notificationDetail.teams_notification_config.Monitoring)} />
                                            }
                                            label="Monitoring"
                                        />
                                    }
                                    {
                                        config.action_items &&
                                        <FormControlLabel
                                            className={classnames(classes.formLabelSection, classes.formLabel)}
                                            control={
                                                <Switch
                                                    color="secondary"
                                                    name="actionitem"
                                                    checked={Boolean(notificationDetail.teams_notification_config.actionitem)}
                                                    disabled={!notificationDetail.teams_notification_config.on}
                                                    onChange={(event) => changeProperty("teams_notification_config", event.target.name, !notificationDetail.teams_notification_config.actionitem)} />
                                            }
                                            label="ActionItem"
                                        />
                                    }
                                    <FormControlLabel
                                        className={classnames(classes.formLabelSection, classes.formLabel)}
                                        control={
                                            <Switch
                                                color="secondary"
                                                name="permissioning"
                                                checked={Boolean(notificationDetail.teams_notification_config.permissioning)}
                                                disabled={!notificationDetail.teams_notification_config.on}
                                                onChange={(event) => changeProperty("teams_notification_config", event.target.name, !notificationDetail.teams_notification_config.permissioning)} />
                                        }
                                        label="Permissioning"
                                    />
                                    <FormControlLabel
                                        className={classnames(classes.formLabelSection, classes.formLabel)}
                                        control={
                                            <Switch
                                                color="secondary"
                                                name="audit"
                                                checked={Boolean(notificationDetail.teams_notification_config.audit)}
                                                disabled={!notificationDetail.teams_notification_config.on}
                                                onChange={(event) => changeProperty("teams_notification_config", event.target.name, !notificationDetail.teams_notification_config.audit)} />
                                        }
                                        label="AuditLogs"
                                    />
                                    <FormControlLabel
                                        className={classnames(classes.formLabelSection, classes.formLabel)}
                                        control={
                                            <Switch
                                                color="secondary"
                                                name="schedule"
                                                checked={Boolean(notificationDetail.teams_notification_config && notificationDetail.teams_notification_config.schedule)}
                                                disabled={!notificationDetail.teams_notification_config.on}
                                                onChange={(event) => changeProperty("teams_notification_config", event.target.name, !notificationDetail.teams_notification_config.schedule)} />
                                        }
                                        label="Schedule"
                                    />
                                </FormGroup>
                            </Grid>
                            <Grid item xs={12} className={classes.formGroup}>
                                <Typography>
                                    Teams Configuration
                                </Typography>
                                <Divider className={classes.divider} />
                                <Grid container spacing={4}>
                                    <Grid item xs={6}>
                                        <TextBox
                                            label="Web Hook"
                                            name="webhook_url"
                                            onChange={(event) => changeProperty("teams_notification_config", event.target.name, event.target.value)}
                                            value={notificationDetail.teams_notification_config.webhook_url ? notificationDetail.teams_notification_config.webhook_url : ""}
                                            fullWidth
                                            onBlur={() => (notificationDetail.teams_notification_config.webhook_url !== "" ? updateNotification(notificationDetail) : null)}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            }
        </ValidatorForm>
    );
};

Notification.propTypes = {
    classes: PropTypes.object,
    organizationId: PropTypes.number
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}))(Notification);