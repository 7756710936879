import React, { memo, useEffect } from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import PropTypes from 'prop-types';

const MyPureIframe = memo(({ src, width, height }) => (
    <iframe title="Lineage" key="1" src={src} width={width} height={height} />
));
MyPureIframe.displayName = "MyPureIframe";
MyPureIframe.propTypes = {
    src: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number
};

const Lineage = (props) => {
    const { classes, getPageTitle } = props;

    useEffect(() => {
        getPageTitle('Lineage');
    });

    return (
        <Grid>
            <Grid container alignItems="center" justify="space-between" className={classes.dashboardHeaderContainer}>
                <Grid item>
                    <Typography variant="h2" component="h2">
                        Lineage
                    </Typography>
                </Grid>
            </Grid>
            <Grid>
                <MyPureIframe src="https://mantaflow.dqlabs.cloud/manta-dataflow-server/viewer" width="1740" height="900" />
            </Grid>
        </Grid >
    );
};


Lineage.propTypes = {
    classes: PropTypes.object,
    getPageTitle: PropTypes.func
};

export default withStyles((theme) => ({
    ...Styles(theme)
}))(Lineage);