import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { withStyles, Grid, Typography } from '@material-ui/core';
import Circular from '../Charts/Circular.jsx';
import Styles from '../../layouts/Styles.jsx';
import DashboardStyles from './DashboardStyles.jsx';
import { getDomainScore } from '../../actions/scheduleActions';
import { getValue } from '../../helpers/appHelpers';
import Loader from '../Loaders/Loader.jsx';
import { Link } from 'react-router-dom';

const DashboardDomain = (props) => {
    const { classes } = props;
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    const [domainDQScore, setDomainDQScore] = useState({});
    const dq_score_threshold = useSelector(({ setting }) => setting.user_config.dq_score_threshold);

    const getDomainDQScore = useCallback(() => {
        dispatch(getDomainScore()).then((response) => {
            if (response) {
                setDomainDQScore({ ...response });
            }
            setLoading(false);
        });
    }, [dispatch]);

    useEffect(() => {
        getDomainDQScore();
    }, [getDomainDQScore]);

    return (
        <Grid container alignItems="center" className={classes.container}>
            <Grid item xs={2} className={classNames(classes.card, classes.relative)}>
                <Typography component="h4" variant="h4">
                    {'Overall Domain DQScore'}
                </Typography>
                {
                    domainDQScore.all &&
                    <Grid className={classNames(classes.domainCircle, classes.marginTop10, classes.domainCircleScore)} style={{ width: 'auto' }}>
                        <Circular
                            size={70}
                            value={domainDQScore.all}
                            showPercentage
                            noLink
                            dqScoreThreshold={dq_score_threshold}
                        />
                        <Grid className={classes.marginLeft10}>
                            <Typography className={classes.fontWeight600} component="h3" variant="h3" >
                                {`${getValue(domainDQScore.all)}%`}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                {isLoading && <Loader />}
            </Grid>
            <Grid item xs={10} className={classNames(classes.card, classes.cardCustom, classes.relative)}>
                <Typography component="h4" variant="h4">
                    {'Domain DQScore'}
                </Typography>
                <Grid container className={classes.marginTop15}>
                    {
                        domainDQScore.domain && domainDQScore.domain.map((domain, index) =>
                            <Link key={index} style={{ textDecoration: 'unset', color: 'inherit' }} to={{ pathname: `/domain/${domain.id}`, state: { domain: {} } }} >
                                <Grid item
                                    className={classes.domainCircle}
                                >
                                    <Circular
                                        size={50}
                                        value={domain.dqscore}
                                        showPercentage
                                        dqScoreThreshold={dq_score_threshold}
                                    />
                                    <Grid className={classes.marginLeft10}>
                                        <Typography className={classes.fontWeight600} component="h3" variant="h3">
                                            {`${getValue(domain.dqscore)}%`}
                                        </Typography>
                                        <Typography className={classes.domainNametxt}>
                                            {domain.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Link>
                        )

                    }
                </Grid>
                {isLoading && <Loader />}
            </Grid>
        </Grid>
    );
};

DashboardDomain.propTypes = {
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}))(DashboardDomain);