import React from 'react';
import { Grid, withStyles, Typography, Accordion, AccordionDetails, AccordionSummary, Card, Table, TableRow, TableHead, TableCell, TableBody } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import Linear from '../Charts/Linear.jsx';
import Styles from '../../layouts/Styles.jsx';
import MasterSearchDetailStyles from './MasterSearchDetailStyles.jsx';
import MasterSearchResult from './MasterSearchResult.jsx';

const MasterSearchDetail = (props) => {
    const { classes, personDetails, searchResults, view } = props;
    const columns = ["PersonID", "FirstName", "LastName", "DateOfBirth", "City", "State", "ZipCode", "PersonConfidence"];
    const height = window.innerHeight - 190;
    return (
        <Grid>
            {
                view === 'card' ?
                    <Grid>
                        <Grid container>
                            {
                                columns.map((column, index) =>
                                    <Grid className={classes.masterColumnGridItem} item key={`column_${index}`}>
                                        <Typography className={classes.columnHeader}>
                                            {column}
                                        </Typography>
                                    </Grid>
                                )
                            }
                        </Grid>
                        <Grid className={classes.masterSearchListContainer} style={{ height: `${height}px` }}>
                            {
                                personDetails.map((personData, index) =>
                                    <Card key={index}>
                                        <Accordion defaultExpanded={index === 0}>
                                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                                {
                                                    columns.map((column, columnIndex) =>
                                                        <Grid className={classes.masterGridItem} item key={`column_${columnIndex}`}>
                                                            {
                                                                column !== "PersonConfidence" ?
                                                                    <Typography>
                                                                        {personData[column]}
                                                                    </Typography> :
                                                                    <Linear showPercentage value={personData[column] ? personData[column] : 0} type="master" />
                                                            }
                                                        </Grid>
                                                    )
                                                }
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid className={classes.searchTableContainer}>
                                                    {
                                                        personData.details.map((detail, index) =>
                                                            <Table key={index}>
                                                                <TableHead>
                                                                    <TableRow>
                                                                        {
                                                                            detail.columns.map((column, cellIndex) =>
                                                                                <TableCell key={cellIndex}>
                                                                                    <Typography>
                                                                                        {column}
                                                                                    </Typography>
                                                                                </TableCell>
                                                                            )
                                                                        }
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {
                                                                        detail.data && detail.data.map((searchData, index) =>
                                                                            <TableRow key={index}>
                                                                                {
                                                                                    detail.columns.map((column, index) =>
                                                                                        <TableCell key={index}>
                                                                                            {
                                                                                                column.indexOf("Confidence") === -1 ?
                                                                                                    <Typography>
                                                                                                        {searchData[column]}
                                                                                                    </Typography> :
                                                                                                    <Linear showPercentage value={searchData[column] ? searchData[column] : 0} type="master" />
                                                                                            }
                                                                                        </TableCell>
                                                                                    )
                                                                                }
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                </TableBody>
                                                            </Table>
                                                        )
                                                    }
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Card>
                                )
                            }
                        </Grid>
                    </Grid>
                    : <MasterSearchResult searchResults={searchResults} />
            }
        </Grid>
    );

};
MasterSearchDetail.propTypes = {
    classes: PropTypes.object,
    personDetails: PropTypes.array,
    searchResults: PropTypes.array,
    view: PropTypes.string
};

export default withStyles((theme) => ({
    ...MasterSearchDetailStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MasterSearchDetail);