import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, withStyles } from '@material-ui/core';

import ConversationFilter from '../../components/Conversation/ConversationFilter.jsx';
import ConversationMetrics from '../../components/Conversation/ConversationMetrics.jsx';
import ConversationListDetail from '../../components/Conversation/ConversationListDetail.jsx';
import Styles from '../../layouts/Styles.jsx';
import { getAllConversation, groupListConversation, updateNotificationRead, selectConversationItem } from '../../actions/notificationActions.js';
import { getOrganizationSources } from '../../actions/datasourceActions';
import { setCurrentPage } from '../../helpers/apiHelpers.js';

const Conversations = (props) => {
    const dispatch = useDispatch();
    const user = useSelector(({ account }) => account.user);
    const userList = useSelector(({ account }) => account.users);
    const [sources, setSources] = useState({
        datasources: [{ id: "All", name: "All" }],
        datasets: [{ id: "All", name: "All" }],
        domain: [{ id: "All", name: "All" }]
    });
    const [changeFilter, setChangeFilter] = useState(false);
    const conversations = useSelector(({ notification }) => notification.conversations);
    const selectedConversation = useSelector(({ notification }) => notification.conversation);
    const [isLoading, setLoading] = useState(true);
    const [filters, setFilters] = useState({
        search: '',
        datasource: 'All',
        dataset: 'All',
        domain: 'All',
        users: [
            {
                isDefault: true,
                isSelected: true,
                isUser: false,
                name: 'All'
            }
        ]
    });
    const [users, setUsers] = useState([]);

    useEffect(() => {
        props.getPageTitle('Conversation');
    });

    const loadUsers = useCallback(() => {
        const userItems = userList.map((user) => {
            return {
                ...user,
                isSelected: false,
                name: user.first_name !== "" ? `${user.first_name} ${user.last_name}` : user.email,
                isDefault: false,
                isUser: true
            };
        });
        userItems.unshift({
            isDefault: true,
            isSelected: true,
            isUser: false,
            name: 'All'
        });
        setUsers([...userItems]);
    }, [userList]);

    const onChangeFilter = useCallback((property, value) => {
        if (property === "users") {
            let userItems = [];
            if (value === "All") {
                userItems = users.map((user) => {
                    const userList = user;
                    if (userList.name !== "All") {
                        userList.isSelected = false;
                    } else {
                        userList.isSelected = true;
                    }
                    return userList;
                });
                filters[property] = users.filter((user) => user.name === value);
            } else {
                let filterUsers = [...filters.users];
                const index = filterUsers.findIndex((user) => user.name === value);
                const allIndex = filterUsers.findIndex((user) => user.name === "All");
                if (allIndex !== -1) {
                    filterUsers.splice(allIndex, 1);
                }
                if (index !== -1) {
                    filterUsers.splice(index, 1);
                } else {
                    const user = users.find((user) => user.name === value);
                    filterUsers.push({ ...user });
                }
                if (filterUsers.length === 0) {
                    filterUsers = users.filter((user) => user.name === "All");
                }
                userItems = users.map((user) => {
                    const userList = user;
                    if (userList.name === value && index === -1) {
                        userList.isSelected = true;
                    } else if ((userList.name === value && index !== -1)) {
                        userList.isSelected = false;
                    } else if (userList.name === "All") {
                        if (filterUsers.length === 0) {
                            userList.isSelected = true;
                        } else {
                            userList.isSelected = false;
                        }
                    }
                    return userList;
                });
                filters[property] = filterUsers;
            }
            setUsers([...userItems]);
        } else {
            filters[property] = value;
        }
        setChangeFilter(true);
        setFilters({ ...filters });
    }, [filters, users]);

    useEffect(() => {
        loadUsers();
    }, [loadUsers]);

    const getConversation = useCallback(() => {
        dispatch(getAllConversation()).then((response) => {
            if (response) {
                dispatch(groupListConversation(response, userList, user.id, "conversation"));
            }
            setLoading(false);
        });
    }, [dispatch, user.id, userList]);

    const getSources = useCallback(() => {
        dispatch(getOrganizationSources()).then((response) => {
            const datasources = {
                datasources: [{ id: "All", name: "All" }],
                datasets: [{ id: "All", name: "All" }],
                domains: [{ id: "All", name: "All" }]
            };
            if (response) {
                datasources.datasources = [...datasources.datasources, ...response.datasources];
                datasources.datasets = [...datasources.datasets, ...response.datasets];
                datasources.domains = [...datasources.domains, ...response.domains];
            }
            setSources({ ...datasources });
        });
    }, [dispatch]);


    useEffect(() => {
        setCurrentPage('conversations');
        getConversation();
        getSources();
    }, [getConversation, getSources]);

    const conversationList = conversations.filter((conversation) => {
        let permissionFilter = false;
        let datasourceFilter = true;
        let datasetFilter = true;
        let domainFilter = true;
        if (filters.users.length === 1 && filters.users[0].name === "All") {
            permissionFilter = true;
        } else {
            const userList = filters.users.map((user) => user.id);
            permissionFilter = userList.includes(conversation.id);
        }
        if (filters.datasource !== "All") {
            datasourceFilter = conversation.sourceId === filters.datasource;
        }
        if (filters.dataset !== "All") {
            datasetFilter = conversation.datasetId === filters.dataset;
        }
        if (filters.domain !== "All") {
            domainFilter = conversation.domainId === filters.domain;
        }
        return permissionFilter && datasourceFilter && datasetFilter && domainFilter;
    });

    if (changeFilter) {
        setChangeFilter(false);
        const selectConversation = conversationList && conversationList.length ? conversationList[0] : {};
        if (Object.keys(selectConversation).length) {
            selectConversation.messages = selectConversation.messages.sort((a, b) => b.created_date > a.created_date);
            dispatch(updateNotificationRead({
                "receiver_id": user.id,
                "source_id": selectConversation.sourceId,
                "dataset_id": selectConversation.datasetId,
                "domain_id": selectedConversation.domainId,
                "user_id": selectConversation.id
            }));
        }
        dispatch(selectConversationItem({ ...selectConversation }));
    }


    return (
        <Grid>
            <ValidatorForm name="Conversation filter" onSubmit={() => null}>
                <ConversationFilter
                    filters={filters}
                    onChangeFilter={(property, value) => onChangeFilter(property, value)}
                    sources={sources} />
                <ConversationMetrics isLoading={isLoading} items={[]} userList={users} users={filters.users} onChangeFilter={(property, value) => onChangeFilter(property, value)} />
                <ConversationListDetail conversations={conversationList} isLoading={isLoading} selectedConversation={selectedConversation} />
            </ValidatorForm>
        </Grid>
    );
};

Conversations.propTypes = {
    getPageTitle: PropTypes.func
};

export default withStyles((theme) => ({
    ...Styles(theme)
}))(Conversations);