import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField, withStyles } from '@material-ui/core';

import classNames from 'classnames';
import Styles from '../../../layouts/Styles.jsx';
import MasterStyles from '../MasterStyles.jsx';


const MatchLevelInput = (props) => {
    const { classes, matchLevel, rowIndex, onValueChange, enabled } = props;
    const [currentRowIndex, setRowIndex] = useState(-1);
    const [level, setLevel] = useState(null);

    const updateValue = useCallback(() => {
        if (onValueChange) {
            onValueChange('level', level, rowIndex);
        }
    }, [level, onValueChange, rowIndex]);

    const loadValue = useCallback((matchLevel, rowIndex) => {
        setRowIndex(rowIndex);
        setLevel(matchLevel);
    }, []);

    useEffect(() => {
        if (rowIndex === currentRowIndex) {
            return;
        }
        loadValue(matchLevel, rowIndex);
    }, [currentRowIndex, rowIndex, loadValue, matchLevel]);

    return (
        <TextField
            name="level"
            id="level"
            value={level ? level : ''}
            onChange={(event) => setLevel(event.target.value)}
            onBlur={() => updateValue()}
            className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.levelInput, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
            placeholder="Enter a level"
            margin="normal"
            disabled={!enabled}
        />
    );
};

MatchLevelInput.propTypes = {
    classes: PropTypes.object,
    matchLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    rowIndex: PropTypes.number,
    onValueChange: PropTypes.func,
    enabled: PropTypes.bool
};

export default withStyles((theme) => ({
    ...MasterStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MatchLevelInput);