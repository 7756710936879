import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import { domainActions } from '../constants/actionTypes/domainActionTypes';
import * as service from '../services/apiService';


export const updateDomainDetail = (domainId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.domain.updateDomainDetail}/${domainId}`;
    return (dispatch) => {
        dispatch(request(domainActions.UPDATE_DOMAIN_DETAIL_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(domainActions.UPDATE_DOMAIN_DETAIL_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(domainActions.UPDATE_DOMAIN_DETAIL_FAILURE, error));
        });
    };
};

export const getDomainDetail = (domainId) => {
    const endpoint = `${appConstants.END_POINTS.domain.getDomainDetail}/${domainId}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_DOMAIN_DETAIL_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(domainActions.GET_DOMAIN_DETAIL_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(domainActions.GET_DOMAIN_DETAIL_FAILURE, error));
        });
    };
};

export const getDomainRules = (domainId) => {
    const endpoint = `${appConstants.END_POINTS.domain.getDomainRules}/${domainId}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_DOMAIN_RULES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(domainActions.GET_DOMAIN_RULES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(domainActions.GET_DOMAIN_RULES_FAILURE, error));
        });
    };
};


export const getDomainMetrics = (domainId) => {
    const endpoint = `${appConstants.END_POINTS.domain.getDomainMetrics}/${domainId}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_DOMAIN_METRICS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(domainActions.GET_DOMAIN_METRICS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(domainActions.GET_DOMAIN_METRICS_FAILURE, error));
        });
    };
};

export const getDomainMetricAnalysis = (domainId) => {
    const endpoint = `${appConstants.END_POINTS.domain.getDomainMetricAnalysis}/${domainId}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_DOMAIN_METRIC_ANALYSIS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(domainActions.GET_DOMAIN_METRIC_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(domainActions.GET_DOMAIN_METRIC_ANALYSIS_FAILURE, error));
        });
    };
};
export const getDomainTimeline = (domainId) => {
    const endpoint = `${appConstants.END_POINTS.domain.getDomainTimeline}/${domainId}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_DOMAIN_TIMELINE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(domainActions.GET_DOMAIN_TIMELINE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(domainActions.GET_DOMAIN_TIMELINE_FAILURE, error));
        });
    };
};

export const getDomainDimensions = (domainId) => {
    const endpoint = `${appConstants.END_POINTS.domain.getDomainDimensions}/${domainId}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_DOMAIN_DIMENSIONS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(domainActions.GET_DOMAIN_DIMENSIONS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(domainActions.GET_DOMAIN_DIMENSIONS_FAILURE, error));
        });
    };
};

export const getDomainRuleProperty = (attributeId, ruleName) => {
    const endpoint = `${appConstants.END_POINTS.domain.getDomainRuleProperty}/${attributeId}/${ruleName}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_DOMAIN_RULE_PROPERTY_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(domainActions.GET_DOMAIN_RULE_PROPERTY_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(domainActions.GET_DOMAIN_RULE_PROPERTY_FAILURE, error));
        });
    };
};

export const updateDomainRuleProperty = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.domain.updateDomainRuleProperty}`;
    return (dispatch) => {
        dispatch(request(domainActions.UPDATE_DOMAIN_RULE_PROPERTY_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(domainActions.UPDATE_DOMAIN_RULE_PROPERTY_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(domainActions.UPDATE_DOMAIN_RULE_PROPERTY_FAILURE, error));
        });
    };
};

export const removeAttributeRule = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.domain.removeAttributeRule}`;
    return (dispatch) => {
        dispatch(request(domainActions.REMOVE_ATTRIBUTE_RULE_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(domainActions.REMOVE_ATTRIBUTE_RULE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(domainActions.REMOVE_ATTRIBUTE_RULE_FAILURE, error));
        });
    };
};

export const getOrganizationDomain = () => {
    const endpoint = `${appConstants.END_POINTS.domain.getOrganizationDomain}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_ORGANIZATION_DOMAINS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(domainActions.GET_ORGANIZATION_DOMAINS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(domainActions.GET_ORGANIZATION_DOMAINS_FAILURE, error));
        });
    };
};

export const getDomainDatasource = (domainId) => {
    const endpoint = `${appConstants.END_POINTS.domain.getDomainDatasource}/${domainId}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_DOMAIN_DATASOURCE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(domainActions.GET_DOMAIN_DATASOURCE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(domainActions.GET_DOMAIN_DATASOURCE_FAILURE, error));
        });
    };
};

export const getDomainDataset = (domainId) => {
    const endpoint = `${appConstants.END_POINTS.domain.getDomainDataset}/${domainId}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_DOMAIN_DATASET_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(domainActions.GET_DOMAIN_DATASET_SUCCESS, response.data));
            return response;
        }, (error) => {
            dispatch(failure(domainActions.GET_DOMAIN_DATASET_FAILURE, error));
        });
    };
};


export const selectDomain = (domainId) => {
    return {
        type: domainActions.SELECT_DOMAIN_ID,
        domainId
    };
};

export const setActionItemCount = (count) => {
    return {
        type: domainActions.SET_ACTION_ITEM_COUNT,
        count
    };
};

export const updateRuleWeightage = (ruleId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.domain.updateRuleWeightage}/${ruleId}`;
    return (dispatch) => {
        dispatch(request(domainActions.UPDATE_RULE_WEIGHTAGE_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(domainActions.UPDATE_RULE_WEIGHTAGE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(domainActions.UPDATE_RULE_WEIGHTAGE_FAILURE, error));
        });
    };
};

export const setDomainFilters = (filters) => {
    return {
        type: domainActions.SET_FILTERS,
        filters
    };
};

export const getDomainSemanticRules = (domainId) => {
    const endpoint = `${appConstants.END_POINTS.domain.getDomainSemanticRules}/${domainId}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_DOMAIN_SEMANTIC_RULES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(domainActions.GET_DOMAIN_SEMANTIC_RULES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(domainActions.GET_DOMAIN_SEMANTIC_RULES_FAILURE, error));
        });
    };
};

export const updateSemanticField = (attributeId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.domain.updateSemanticField}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(domainActions.UPDATE_ATTRIBUTE_SEMANTIC_FIELD_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(domainActions.UPDATE_ATTRIBUTE_SEMANTIC_FIELD_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(domainActions.UPDATE_ATTRIBUTE_SEMANTIC_FIELD_FAILURE, error));
        });
    };
};

export const getdomaindatasources = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.domain.getdomaindatasources}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_DOMAIN_DATASOURCES_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(domainActions.GET_DOMAIN_DATASOURCES_SUCCESS, response.data));
            return response;
        }, (error) => {
            dispatch(failure(domainActions.GET_DOMAIN_DATASOURCES_FAILURE, error));
        });
    };
};

export const getdomaindatasets = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.domain.getdomaindatasets}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_DOMAIN_DATASETS_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(domainActions.GET_DOMAIN_DATASETS_SUCCESS, response.data));
            return response;
        }, (error) => {
            dispatch(failure(domainActions.GET_DOMAIN_DATASETS_FAILURE, error));
        });
    };
};

export const getdomainattributes = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.domain.getdomainattributes}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_DOMAIN_ATTRIBUTES_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(domainActions.GET_DOMAIN_ATTRIBUTES_SUCCESS, response.data));
            return response;
        }, (error) => {
            dispatch(failure(domainActions.GET_DOMAIN_ATTRIBUTES_FAILURE, error));
        });
    };
};

export const getdomainrules = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.domain.getdomainrules}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_DOMAINQ_RULES_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(domainActions.GET_DOMAINQ_DATASOURCES_SUCCESS, response.data));
            return response;
        }, (error) => {
            dispatch(failure(domainActions.GET_DOMAINQ_DATASOURCES_FAILURE, error));
        });
    };
};

export const searchDomain = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.domain.searchdomain}`;
    return (dispatch) => {
        dispatch(request(domainActions.SEARCH_DOMAIN_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(domainActions.SEARCH_DOMAIN_SUCCESS, response.data));
            return response;
        }, (error) => {
            dispatch(failure(domainActions.SEARCH_DOMAIN_FAILURE, error));
        });
    };
};

export const getDomainMonitoring = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.domain.getdomainmonitoring}`;
    return (dispatch) => {
        dispatch(request(domainActions.GET_DOMAIN_MONITORING_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(domainActions.GET_DOMAIN_MONITORING_SUCCESS, response.data));
            return response?.data || [];
        }, (error) => {
            dispatch(failure(domainActions.GET_DOMAIN_MONITORING_FAILURE, error));
        });
    };
};