import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Avatar } from '@material-ui/core';

import { getRandomColor, getInitials, returnImage } from '../../helpers/appHelpers';
import { appConstants } from '../../constants/appConstants';
import ConversationStyles from './ConversationStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import moment from 'moment-timezone';
import Loader from '../Loaders/Loader.jsx';

const ConversationListItems = (props) => {
    const { classes, conversations, selectConversation, userId, isLoading } = props;

    const getUnreadCount = useCallback((conversation) => {
        return conversation.messages ? conversation.messages.filter((item) => item.receiver_id === userId && !item.is_read).length : 0;
    }, [userId]);

    const presentDate = moment().format('MMM DD YYYY');

    return (
        <Grid container>
            {
                conversations.map((conversation, index) =>
                    <Grid key={index} item xs={12} className={classes.conversationListContainer} onClick={() => selectConversation(conversation)}>
                        <Avatar
                            style={
                                {
                                    backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, conversation.avatar)})`,
                                    backgroundColor: getRandomColor(conversation.first_name !== "" ? `${conversation.first_name} ${conversation.last_name}` : conversation.email)
                                }
                            }>
                            {
                                !conversation.avatar &&
                                <span>
                                    {getInitials(conversation.first_name !== "" ? `${conversation.first_name} ${conversation.last_name}` : conversation.email)}
                                </span>
                            }
                        </Avatar>
                        <Grid container className={classes.conversationListSection}>
                            <Grid className={classes.conversationMessageSection}>
                                <Typography className={classes.conversationUserTxt}>
                                    {conversation.first_name !== "" ? `${conversation.first_name} ${conversation.last_name}` : conversation.email}
                                </Typography>
                                <Typography className={classes.timeTxt}>
                                {
                                     ((conversation.messages) && (moment(conversation.messages.created_date).format('MMM DD YYYY') === presentDate)) ?
                                     moment(conversation.messages.created_date).calendar() :
                                     moment(conversation.messages.created_date).format('MMM DD, YYYY HH:mm')
                                }
                                </Typography>
                            </Grid>
                            <Grid className={classes.conversationMessageSection}>
                                <Typography className={classes.messageTxt}>
                                    {conversation.messages ? conversation.messages[conversation.messages.length - 1].message : ''}
                                </Typography>
                                <Grid className={classes.unreadSection}>
                                    {getUnreadCount(conversation)}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            }
            { isLoading && <Loader /> }
        </Grid>
    );

};

ConversationListItems.propTypes = {
    classes: PropTypes.object,
    conversations: PropTypes.array,
    selectConversation: PropTypes.func,
    userId: PropTypes.number,
    isLoading: PropTypes.bool
};

export default withStyles((theme) => ({
    ...ConversationStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ConversationListItems);