import React from "react";
import PropTypes from 'prop-types';
import { Grid, Typography, Table, TableHead, TableBody, TableRow, TableCell, IconButton, AccordionSummary, MenuItem, FormControlLabel, Switch } from '@material-ui/core';
import classnames from 'classnames';
import { bytesToSize } from '../../../helpers/appHelpers.js';
import CheckboxComponent from '../../ChecboxComponent/CheckboxComponent.jsx';
import ToolTipComponent from '../../Tooltip/Tooltip.jsx';
import moment from 'moment-timezone';
import { appConstants } from '../../../constants/appConstants.js';
import { appConfig } from '../../../config/appConfig.js';
import Loader from '../../Loaders/Loader.jsx';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import WarningIcon from "../../Warning/WarningIcon.jsx";
import TextBox from '../../TextBox/TextBox.jsx';


export const AccordionTable = (props) => {
    const { dataset, isScan, rootColumns, index, classes, isLoading, theme, getDatasetSchema, onSelectionChange, deleteSelectedDataset, onSelectAllAttribute, onSelectAttribute, enableDatasetConfiguration, showCheckBox, onUpdateDataset, onOpenDatasetConfigModal, userConfig } = props;

    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <TableRow className={classes.tableRowClass}
                onClick={
                    () => {
                        setOpen(!open);
                        getDatasetSchema(dataset);
                    }
                }>
                <TableCell component="td" scope="row" className={classes.paddingLeft6}>
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                        <Grid className={classnames(classes.datasetTitleSection, classes.checkBoxTitle, classes.noPaddingLeft)}>
                            {!showCheckBox && <WarningIcon />}
                            <CheckboxComponent checked={Boolean(dataset.isSelected)} onClick={(event) => event.stopPropagation()} onChange={() => onSelectionChange(dataset)} showCheckBox={showCheckBox} />
                        </Grid>
                    </AccordionSummary>
                </TableCell>
                <TableCell component="td" scope="row" className={classnames(classes.paddingLeft6, classes.flexWidth)}>
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                        <Grid className={classnames(classes.datasetTitleSection, classes.checkBoxTitle, classes.noPaddingLeft)}>
                            <ToolTipComponent title={dataset.name} arrow>
                                <Typography className={classnames(classes.datasetName, classes.flexWidth)} component="span">
                                    {dataset.name}
                                </Typography>
                            </ToolTipComponent>
                        </Grid>
                    </AccordionSummary>
                </TableCell>
                {
                    !isScan &&
                    <TableCell>
                        <TextBox
                            name="group_by"
                            placeholder="Enter Comma Separated Values"
                            value={dataset.group_by ? dataset.group_by : ''}
                            onClick={(event) => event.stopPropagation()}
                            onChange={(event) => onUpdateDataset(dataset, event.target.name, event.target.value)}
                            fullWidth
                        />
                    </TableCell>
                }
                {/* {
                    isScan && dataset.is_delta &&
                    <TableCell>
                        <TextBox
                            name="partition_columns"
                            value={dataset.partition_columns ? dataset.partition_columns : ''}
                            onClick={(event) => event.stopPropagation()}
                            onChange={(event) => onUpdateDataset(dataset, event.target.name, event.target.value)}
                            fullWidth
                        />
                    </TableCell>
                } */}
                <TableCell>
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                        <ToolTipComponent title={dataset.table_name} arrow>
                            <Typography className={classnames(classes.datasetTabText, classes.fixedTableCell)}>
                                {`${dataset.fileType ? dataset.fileType.description : ""} ${dataset.fileType ? `(${dataset.fileType.type})` : ""}`}
                            </Typography>
                        </ToolTipComponent>
                    </AccordionSummary>
                </TableCell>
                {
                    !dataset.is_delta &&
                    <TableCell>
                        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                            <Typography className={classes.datasetTabText}>
                                {bytesToSize(dataset.size)}
                            </Typography>
                        </AccordionSummary>
                    </TableCell>
                }
                <TableCell>
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                        <Typography className={classes.datasetTabText}>
                            {moment.unix(dataset.modified_date).format(appConfig.dateFormat)}
                        </Typography>
                    </AccordionSummary>
                </TableCell>
                <TableCell className={classes.loadTypeInput}>
                    <TextBox
                        name="con_type"
                        value={dataset.con_type ? dataset.con_type : appConstants.connectionType[0].value}
                        onClick={(event) => event.stopPropagation()}
                        select
                        disabled={!dataset.isSelected || dataset.dataset_id}
                        onChange={(event) => onUpdateDataset(dataset, event.target.name, event.target.value)}
                        fullWidth
                    >
                        {
                            appConstants.connectionType.map((conType, index) => {
                                return (
                                    <MenuItem key={`conType${index}`} value={conType.value}>
                                        {conType.name}
                                    </MenuItem>
                                );
                            })
                        }
                    </TextBox>
                </TableCell>
                {
                    userConfig.include_organization_domain_score &&
                    <TableCell align="center" onClick={(event) => event.stopPropagation()}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={dataset.include_organization_domain_score}
                                    onChange={(event) => { onUpdateDataset(dataset, 'include_organization_domain_score', !dataset.include_organization_domain_score); }}
                                    name="include_organization_domain_score"
                                    disabled={!dataset.isSelected}
                                />
                            }
                        />
                    </TableCell>
                }
                <TableCell>
                    <Grid className={classnames(classes.flexBox, classes.floatRight)}>
                        <ToolTipComponent title="Properties" arrow>
                                <IconButton onClick={(event) => onOpenDatasetConfigModal(event, dataset)} disabled = {dataset.load_type === appConstants.loadType[1]}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.823" height="16.249" viewBox="0 0 15.823 16.249">
                                        <path fill={theme.palette.primary.main} d="M22.26,9.905,20.766,8.737a5.88,5.88,0,0,0,.035-.613,5.7,5.7,0,0,0-.035-.613l1.495-1.169a.732.732,0,0,0,.177-.933L20.884,2.722a.72.72,0,0,0-.9-.323l-1.762.708a6.042,6.042,0,0,0-1.058-.614L16.9.623A.719.719,0,0,0,16.18,0H13.065a.717.717,0,0,0-.716.617L12.08,2.494a6.229,6.229,0,0,0-1.056.614L9.257,2.4a.734.734,0,0,0-.892.318L6.81,5.406a.728.728,0,0,0,.176.938L8.48,7.512a5.357,5.357,0,0,0,0,1.226L6.985,9.907a.732.732,0,0,0-.176.933l1.553,2.687a.719.719,0,0,0,.9.323l1.762-.708a6.1,6.1,0,0,0,1.058.614l.268,1.869a.718.718,0,0,0,.718.624H16.18a.718.718,0,0,0,.717-.617l.269-1.875a6.258,6.258,0,0,0,1.056-.615l1.767.71a.728.728,0,0,0,.265.05.715.715,0,0,0,.626-.367l1.56-2.7A.731.731,0,0,0,22.26,9.905Zm-7.637.928a2.708,2.708,0,1,1,2.708-2.708A2.711,2.711,0,0,1,14.622,10.833Z" transform="translate(-6.71)" />
                                    </svg>
                                </IconButton>
                        </ToolTipComponent>
                        {
                            !dataset.is_delta &&
                            <ToolTipComponent title="Delete" arrow>
                                <IconButton
                                    disabled={isLoading}
                                    onClick={(event) => deleteSelectedDataset(event, dataset)}>
                                    <svg version="1.1"
                                        id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        width="22px"
                                        height="22px"
                                        viewBox="0 0 32 32">
                                        <g id="Delete">
                                            <path fill={theme.palette.primary.main}
                                                stroke={theme.palette.primary.main}
                                                strokeWidth="0.92"
                                                strokeMiterlimit="10"
                                                d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
                                                                                L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
                                                                                L22.3,25.8z" />
                                            <path fill={theme.palette.primary.main}
                                                stroke={theme.palette.primary.main}
                                                strokeWidth="0.92"
                                                strokeMiterlimit="10"
                                                d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
                                                                                c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
                                                                                c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                        </g>
                                    </svg>
                                </IconButton>
                            </ToolTipComponent>
                        }
                        {
                            dataset.fileType?.type === "xml" &&
                            <ToolTipComponent title="Configuration" arrow>
                                <IconButton disabled={isLoading} onClick={(event) => enableDatasetConfiguration(dataset, event)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15.823" height="16.249" viewBox="0 0 15.823 16.249">
                                        <path fill={theme.palette.primary.main} d="M22.26,9.905,20.766,8.737a5.88,5.88,0,0,0,.035-.613,5.7,5.7,0,0,0-.035-.613l1.495-1.169a.732.732,0,0,0,.177-.933L20.884,2.722a.72.72,0,0,0-.9-.323l-1.762.708a6.042,6.042,0,0,0-1.058-.614L16.9.623A.719.719,0,0,0,16.18,0H13.065a.717.717,0,0,0-.716.617L12.08,2.494a6.229,6.229,0,0,0-1.056.614L9.257,2.4a.734.734,0,0,0-.892.318L6.81,5.406a.728.728,0,0,0,.176.938L8.48,7.512a5.357,5.357,0,0,0,0,1.226L6.985,9.907a.732.732,0,0,0-.176.933l1.553,2.687a.719.719,0,0,0,.9.323l1.762-.708a6.1,6.1,0,0,0,1.058.614l.268,1.869a.718.718,0,0,0,.718.624H16.18a.718.718,0,0,0,.717-.617l.269-1.875a6.258,6.258,0,0,0,1.056-.615l1.767.71a.728.728,0,0,0,.265.05.715.715,0,0,0,.626-.367l1.56-2.7A.731.731,0,0,0,22.26,9.905Zm-7.637.928a2.708,2.708,0,1,1,2.708-2.708A2.711,2.711,0,0,1,14.622,10.833Z" transform="translate(-6.71)" />
                                    </svg>
                                </IconButton>
                            </ToolTipComponent>
                        }
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </Grid>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0, height: "auto" }} colSpan={rootColumns.length + 1}>
                    <Collapse in={open} timeout="auto" unmountOnExit style={{ position: "relative" }} className={classes.collapseTable}>
                        {
                            dataset.isLoadingAttributes &&
                            <Grid container className={classes.attributeLoader}>
                                <Loader size="small" />
                            </Grid>
                        }
                        {
                            dataset.attributes &&
                            <Grid container>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.noBackground}>
                                            <TableCell className={classes.datasetHeaderTitleCell}>
                                                <Grid className={classes.datasetTitleSection}>
                                                    <Grid className={classes.inlineBlock}>
                                                        <CheckboxComponent
                                                            className={"attributeSelectorCheck"}
                                                            checked={dataset.isAllAttributesSelected}
                                                            onClick={() => onSelectAllAttribute(dataset)}
                                                            checkboxLabel={'Attribute'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            {
                                                appConstants.sqlConnectorAttributesTableHeaders.map((header, index) =>
                                                    index !== 0 &&
                                                    <TableCell key={`attribute_${index}`}>
                                                        {header}
                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            dataset.attributes.map((attribute, attrIndex) =>
                                                <TableRow className={classes.tableRowClass} key={`dataset${index}attribute${attrIndex}`}>
                                                    <TableCell>
                                                        <Grid className={classnames(classes.datasetTitleSection, classes.checkBoxTitle, classes.noPaddingLeft)}>
                                                            <CheckboxComponent checked={Boolean(attribute.isSelected)} onClick={(event) => event.stopPropagation()} onChange={() => onSelectAttribute(dataset, attribute, attrIndex)} />
                                                            <ToolTipComponent title={attribute.name} arrow>
                                                                <Typography className={classes.datasetName} component="span">
                                                                    {attribute.name}
                                                                </Typography>
                                                            </ToolTipComponent>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            attribute.business_name ? attribute.business_name :
                                                                <span className={classes.disabledtxt}>
                                                                    {"NA"}
                                                                </span>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className="description">
                                                            {
                                                                attribute.description ? attribute.description :
                                                                    <span className={classes.disabledtxt}>
                                                                        {"NA"}
                                                                    </span>
                                                            }
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography className={classes.typeText}>
                                                            {attribute.datatype ? attribute.datatype : ''}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>
                            </Grid>
                        }

                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

AccordionTable.propTypes = {
    dataset: PropTypes.object,
    index: PropTypes.number,
    classes: PropTypes.object,
    isLoading: PropTypes.bool,
    isScan: PropTypes.bool,
    theme: PropTypes.object,
    getDatasetSchema: PropTypes.func,
    onSelectionChange: PropTypes.func,
    deleteSelectedDataset: PropTypes.func,
    enableDatasetConfiguration: PropTypes.func,
    onSelectAllAttribute: PropTypes.func,
    onSelectAttribute: PropTypes.func,
    onUpdateDataset: PropTypes.func,
    showCheckBox: PropTypes.bool,
    rootColumns: PropTypes.array,
    onOpenDatasetConfigModal: PropTypes.func,
    userConfig: PropTypes.object
};