import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ActionItemStyles from './ActionItemStyles.jsx';
import { withStyles, IconButton, Grid } from '@material-ui/core';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
// import { screenshotPage } from '../../helpers/appHelpers';

const CaptureScreen = (props) => {
    const { classes, screenCapture } = props;
    const [active, setActive] = useState(false);

    const getScreen = () => {
        setActive(false);
        setTimeout(() => screenCapture(), 100);
    };

    return (
        <Grid>
            {
                !active &&
                <ToolTipComponent title={"Add Action Item"} arrow>
                    <IconButton onClick={() => getScreen()} className={classes.actionbtn}>
                        <svg id="Group_2516" data-name="Group 2516" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 15.238 15.238">
                            <g id="Group_2515" data-name="Group 2515" transform="translate(0 0)">
                                <g id="_18_Pencil" data-name="18 Pencil">
                                    <g id="Group_2514" data-name="Group 2514">
                                        <path id="Path_1371" data-name="Path 1371" d="M733.6-7.018a2.026,2.026,0,0,0,.6-1.44,2.014,2.014,0,0,0-.6-1.44l-1.518-1.518a2.017,2.017,0,0,0-1.443-.6,2.014,2.014,0,0,0-1.44.6l-9.467,9.467a2.63,2.63,0,0,0-.768,2.006l0,2.422a.742.742,0,0,0,.744.744l2.422,0a2.63,2.63,0,0,0,2.006-.768.745.745,0,0,0-1.054-1.053,1.151,1.151,0,0,1-.884.333l-.048,0-1.7.006,0-1.7a.25.25,0,0,0,0-.048,1.151,1.151,0,0,1,.333-.884l7.889-7.889,2.3,2.3L725.044-.563a.744.744,0,0,0,0,1.053.744.744,0,0,0,1.054,0Zm-3.875-2.824.527-.527a.544.544,0,0,1,.39-.161.55.55,0,0,1,.39.161l1.518,1.518a.553.553,0,0,1,0,.78l-.527.527Z" transform="translate(-718.963 12.018)" fill="#afb2b3" />
                                        <path id="Path_1372" data-name="Path 1372" d="M962.793,449.982h-6.566a.728.728,0,1,0,0,1.456h6.566a.728.728,0,1,0,0-1.456Z" transform="translate(-948.283 -436.201)" fill="#afb2b3" />
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </IconButton>
                </ToolTipComponent>
            }
        </Grid>
    );
};

// prop types
CaptureScreen.propTypes = {
    classes: PropTypes.object,
    screenCapture: PropTypes.func
};

/** Export Component */
export default withStyles(ActionItemStyles)(CaptureScreen);