import React, { useEffect } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';

const RelationShipChart = (props) => {
    const { classes, chartData, datatypes, theme, filterValue } = props;
    function getUnique(array) {
        const uniqueArray = [];
        for (let i = 0; i < array.length; i++) {
            if (uniqueArray.indexOf(array[i]) === -1) {
                uniqueArray.push(array[i]);
            }
        }
        return uniqueArray;
    }

    useEffect(() => {
        if (chartData && Object.keys(chartData).length !== 0) {
            const names = chartData.map((d) => { return d.name; });
            const uniqueNames = getUnique(names);
            const margin = {
                top: 85,
                right: 80,
                bottom: 20,
                left: 20
            };
            const marginTop = filterValue === "Sensitivity Level" ? 25 : 85;
            let height = [];
            if (uniqueNames.length <= 25) {
                height = uniqueNames.length * 30;
            }
            else {
                height = uniqueNames.length * 20;
            }
            let width = 960 - margin.left;

            d3.select(".relationship-chart > svg").remove();
            const svg = d3.select(".relationship-chart")
                .append("svg")
                .attr("width", "100%")
                .style("margin", "0px auto")
                .attr("height", height + marginTop + margin.bottom + margin.left)
                .append("g")
                .attr("width", (_, i, nodes) => {
                    width = nodes[i].parentNode.clientWidth - margin.left - margin.top - margin.right - margin.bottom;
                    return width;
                })
                .attr("height", height + margin.bottom)
                .attr("transform",
                    "translate(" + (margin.left + 40) + "," + marginTop + ")");

            d3.select(".relationship-chart >.tooltip").remove();
            const div = d3.select(".relationship-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            const x = d3.scaleBand().domain(datatypes).range([0, width]).padding(1),
                y = d3.scaleBand().domain(names).range([height, 0]).padding(1);

            const xScale = d3.axisTop(x).tickSize(-height);
            const yScale = d3.axisLeft(y).tickSize(-width);
            svg.append("g")
                .attr("transform", `translate(${margin.top},0)`)
                .attr("color", theme.palette.chartColors.axis)
                .attr('class', 'axis-x')
                .transition()
                .duration(2000)
                .call(xScale)
                .selectAll("text")
                .attr("y", 0)
                .attr("x", 10)
                .attr("dy", ".35em")
                .attr("transform", "rotate(270)")
                .style("text-anchor", "start")
                .text((d) => {
                    if (d.length >= 10) {
                        return (d.slice(0, 9) + "...");
                    }
                    return d;
                });

            // add the y Axis
            svg.append("g")
                .attr("transform", `translate(${margin.top},0)`)
                .attr("color", theme.palette.chartColors.axis)
                .attr('class', 'axis-y')
                .transition()
                .duration(2000)
                .call(yScale)
                .selectAll("text")
                .text((d) => {
                    if (d.length >= 16) {
                        return (d.slice(0, 16) + "...");
                    }
                    return d;
                });


            svg.selectAll('.tick line').attr('stroke', '#E1E5E6');
            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text').attr("transform", `translate(-20,0)`);
            svg.selectAll('.axis-y .tick-text').text('').append('tspan').text((d) => { return d; });
            svg.selectAll('.axis-x text')
                .on('mouseover', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", 0.4);
                    // Show Tooltip
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    let x = 0;
                    let y = 0;
                    div.attr("transform", (_, i, nodes) => {
                        const mouseCoords = d3.mouse(nodes[i].parentNode);
                        let xCo = 0;
                        let yCo = 0;
                        if (mouseCoords[0] + 10 >= width * 0.80) {
                            xCo = mouseCoords[0] - parseFloat(div
                                .attr("width"));
                            yCo = mouseCoords[1] + 10;
                        } else {
                            xCo = mouseCoords[0] + 10;
                            yCo = mouseCoords[1];
                        }
                        x = isNaN(xCo) ? mouseCoords[0] : xCo;
                        y = isNaN(yCo) ? mouseCoords[1] : yCo;
                    });
                    div.html(`${d}`)
                        .style("left", x + "px")
                        .style("top", y + "px");
            }).on('mouseout', (d, i, nodes) => {
                d3.select(nodes[i]).style("opacity", 1);
                div.transition()
                    .duration(500)
                    .style("opacity", 0);
            });
            svg.selectAll('.axis-y text')
                .on('mouseover', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", 0.4);
                    // Show Tooltip
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    let x = 0;
                    let y = 0;
                    div.attr("transform", (_, i, nodes) => {
                        const mouseCoords = d3.mouse(nodes[i].parentNode);
                        let xCo = 0;
                        let yCo = 0;
                        if (mouseCoords[0] + 10 >= width * 0.80) {
                            xCo = mouseCoords[0] - parseFloat(div
                                .attr("width"));
                            yCo = mouseCoords[1] + 10;
                        } else {
                            xCo = mouseCoords[0] + 10;
                            yCo = mouseCoords[1];
                        }
                        x = isNaN(xCo) ? mouseCoords[0] : xCo;
                        y = isNaN(yCo) ? mouseCoords[1] : yCo;
                    });
                    div.html(`${d}`)
                        .style("left", x + "px")
                        .style("top", y + "px");
            }).on('mouseout', (d, i, nodes) => {
                d3.select(nodes[i]).style("opacity", 1);
                div.transition()
                    .duration(500)
                    .style("opacity", 0);
            });
            svg.append("g")
                .attr("transform", `translate(${margin.top},0)`)
                .selectAll("circle")
                .data(chartData)
                .enter()
                .append("circle")
                .style("fill", "#70C6FF")
                .attr("cx", 0)
                .attr("cy", (d) => { return y(d.name); })
                .attr('r', (d) => {
                    if (d.count >= 170) {
                        return 17;
                    } else if (d.count <= 20) {
                        return 3;
                    }
                    return d.count / 5;
                })
                .on('mouseover',
                    (d, i, nodes) => {
                        d3.select(nodes[i]).style("opacity", 0.4);
                        // Show Tooltip
                        div.transition()
                            .duration(200)
                            .style("opacity", 0.9);
                        let x = 0;
                        let y = 0;
                        div.attr("transform", (_, i, nodes) => {
                            const mouseCoords = d3.mouse(nodes[i].parentNode);
                            let xCo = 0;
                            let yCo = 0;
                            if (mouseCoords[0] + 10 >= width * 0.80) {
                                xCo = mouseCoords[0] - parseFloat(div
                                    .attr("width"));
                                yCo = mouseCoords[1] + 10;
                            } else {
                                xCo = mouseCoords[0] + 10;
                                yCo = mouseCoords[1];
                            }
                            x = isNaN(xCo) ? mouseCoords[0] : xCo;
                            y = isNaN(yCo) ? mouseCoords[1] : yCo;
                        });
                        div.html(`Count : ${d.count}`)
                            .style("left", x + "px")
                            .style("top", y + "px");
                })
                .on('mouseout', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", 1);
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .transition()
                .delay((d, i) => { return Number(i); })
                .duration(2000)
                .attr("cx", (d) => { return x(d.type); });

        }
    }, [chartData, datatypes, filterValue, theme.palette.chartColors.axis]);

    return (
        <Grid className={classNames(classes.chartStyles, 'relationship-chart')} />
    );
};

RelationShipChart.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.array,
    datatypes: PropTypes.array,
    theme: PropTypes.object,
    filterValue: PropTypes.string
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(RelationShipChart);