import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Grid, Table, TableRow, TableHead, TableCell, Typography, TableBody, TextField } from '@material-ui/core';
import PropertiesListViewStyles from './PropertiesListViewStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';
import DatasetStyles from '../../DatasetStyles.jsx';
import TileViewStyles from '../TileView/TileViewStyles.jsx';
import { updateListProperties } from '../../../../actions/datasetActions.js';
import { useDispatch, useSelector } from 'react-redux';
import ChipInputValues from '../../../ChipInput/ChipInputValues.jsx'
import PatternInput from '../../../PatternInput/PatternInput.jsx';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import { toggleDatePicker } from '../../../../actions/commonActions.js';
import ToolTipComponent from '../../../Tooltip/Tooltip.jsx';

const ValuesPanel = (props) => {
    const { classes, properties, isEditable, updateProperties, isDeletePermission, isEditPermission } = props;
    const dispatch = useDispatch();
    const datasetId = useSelector(({ dataset }) => dataset.selectedDatasetId);
    const [selectedProperties, setSelectedProperties] = useState({});

    const updateProperty = useCallback((attributeValue, property, value) => {
        const attribute = attributeValue.name;
        if (!property) {
            return;
        }
        const attributeProperty = {
            id: attributeValue.id,
            attribute,
            property,
            value
        };
        dispatch(updateListProperties(datasetId, attributeProperty));
        updateProperties(attributeProperty);
    }, [datasetId, dispatch, updateProperties]);


    const savePattern = useCallback((attributeValue, pattern, index) => {
        const attribute = attributeValue.name;
        const property = selectedProperties[attribute];
        const patterns = property.patterns;

        if (index >= 0) {
            patterns[index] = { ...pattern };
        } else {
            attributeValue.patterns.push({ ...pattern });
        }

        property.patterns = [...patterns];
        selectedProperties[attribute] = { ...property };
        setSelectedProperties({ ...selectedProperties });
        updateProperty(attributeValue, 'patterns', [...patterns]);
    }, [selectedProperties, updateProperty]);

    const removePattern = useCallback((attributeValue, index) => {
        const attribute = attributeValue.name;
        const property = selectedProperties[attribute];
        const patterns = property.patterns;
        patterns.splice(index, 1);
        property.patterns = [...patterns];
        selectedProperties[attribute] = { ...property };
        setSelectedProperties({ ...selectedProperties });
        updateProperty(attributeValue, 'patterns', [...patterns]);
    }, [selectedProperties, updateProperty]);


    const saveEnumValue = useCallback((attributeValue, value, index) => {
        const attribute = attributeValue.name;
        if (value.trim() !== "") {
            const property = selectedProperties[attribute];
            const enumValues = property.enum.values;
            let enumValue = {};
            if (index >= 0) {
                enumValue = enumValues[index];
                enumValue.value = value.trim();
                enumValues.splice(index, 1, { ...enumValue });
            } else {
                index = 0;
                enumValue = {
                    value,
                    isvalid: true,
                    sense: false
                };
                enumValues.splice(index, 0, { ...enumValue });
            }

            property.enum.values = [...enumValues];
            selectedProperties[attribute] = { ...property };
            setSelectedProperties({ ...selectedProperties });
            updateProperty(attributeValue, 'enum', { ...property.enum });
        }
    }, [selectedProperties, updateProperty]);

    const removeEnumValue = useCallback((attributeValue, index) => {
        const attribute = attributeValue.name;
        const property = selectedProperties[attribute];
        const enumValues = property.enum.values;
        enumValues.splice(index, 1);
        property.enum.values = [...enumValues];
        selectedProperties[attribute] = { ...property };
        setSelectedProperties({ ...selectedProperties });
        updateProperty(attributeValue, 'enum', { ...property.enum });
    }, [selectedProperties, updateProperty]);

    const loadProperties = useCallback((properties) => {
        if (properties) {
            setSelectedProperties({ ...properties });
        }
    }, []);

    const updateAttributeProperties = useCallback((attributeValue, property, value, update = false) => {
        const attribute = attributeValue.name;
        const regex = /^([+-])?[0-9]*(.[0-9]*)?$/;
        if ((property === "min_length" || property === "max_length" || property === "min" || property === "max") && !regex.test(value) && attributeValue.datatype !== "Date") {
            return false;
        }
        selectedProperties[attribute][property] = value;
        setSelectedProperties({ ...selectedProperties });
        if (update) {
            const attributeProperty = {
                id: attributeValue.id,
                attribute,
                property,
                value
            };
            dispatch(updateListProperties(datasetId, attributeProperty));
            updateProperties(attributeProperty);
        }
    }, [datasetId, dispatch, selectedProperties, updateProperties]);

    useEffect(() => {
        if (datasetId && properties) {
            loadProperties(properties);
        }
    }, [datasetId, loadProperties, properties]);

    return (
        <Grid container direction="column">
            <Table stickyHeader className={classes.propertyListPageTable}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Attribute'}
                            </Typography>
                        </TableCell>
                        <TableCell style={{ width: '20%' }}>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Length'}
                            </Typography>
                        </TableCell>
                        <TableCell style={{ width: '20%' }}>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Range'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Patterns'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Enumeration'}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.propListPageTableBody}>
                    {
                        Object.keys(properties).map((attribute, index) => {
                            const property = properties[attribute];

                            return (
                                <TableRow key={`attributeProperty${index}`}>
                                    <TableCell>
                                        <ToolTipComponent title={property && property.name ? property.name : ''} arrow placement="bottom-start">
                                            <Typography className={classes.valueAttributeHeader}>
                                                {property && property.name ? property.name : ''}
                                            </Typography>
                                        </ToolTipComponent>
                                    </TableCell>
                                    <TableCell>
                                        <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
                                            <ToolTipComponent title={property && property.min_length ? property.min_length : ''} arrow>
                                                <TextField
                                                    name="min_length"
                                                    value={property && property.min_length ? property.min_length : ''}
                                                    className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.bgGrey, classes.tableInput)}
                                                    onChange={(event) => updateAttributeProperties(property, event.target.name, event.target.value)}
                                                    onBlur={(event) => updateAttributeProperties(property, event.target.name, event.target.value, true)}
                                                    disabled={!isEditable && !isEditPermission}
                                                />
                                            </ToolTipComponent>
                                            <Typography variant="h2" className={classes.Rangehyphen}>
                                                {'-'}
                                            </Typography>
                                            <ToolTipComponent title={property && property.max_length ? property.max_length : ''} arrow>
                                                <TextField
                                                    name="max_length"
                                                    value={property && property.max_length ? property.max_length : ''}
                                                    className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.bgGrey, classes.tableInput)}
                                                    onChange={(event) => updateAttributeProperties(property, event.target.name, event.target.value)}
                                                    onBlur={(event) => updateAttributeProperties(property, event.target.name, event.target.value, true)}
                                                    disabled={!isEditable && !isEditPermission}
                                                />
                                            </ToolTipComponent>
                                        </Grid>
                                    </TableCell>
                                    <TableCell>
                                        <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
                                            {
                                                (property.datatype === "Date") ?
                                                    <DatePicker
                                                        autoOK
                                                        variant="inline"
                                                        format="MM/DD/YYYY"
                                                        minDateMessage=""
                                                        value={property.min ? moment(property.min).format('MM/DD/YYYY') : Date.now()}
                                                        onChange={(date) => updateAttributeProperties(property, 'min', moment(date).format('MM/DD/YYYY'), true)}
                                                        maxDate={property.max ? moment(property.max).format('MM/DD/YYYY') : Date.now()}
                                                        onOpen={() => dispatch(toggleDatePicker(true))}
                                                        onClose={() => dispatch(toggleDatePicker(false))}
                                                        className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.bgGrey)}
                                                        disabled={!isEditable && !isEditPermission}
                                                    /> :
                                                    <ToolTipComponent title={property && property.min ? property.min : ''} arrow>
                                                        <TextField
                                                            name="min"
                                                            value={property && property.min ? property.min : ''}
                                                            className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.bgGrey, classes.tableInput)}
                                                            onChange={(event) => updateAttributeProperties(property, event.target.name, event.target.value)}
                                                            onBlur={(event) => updateAttributeProperties(property, event.target.name, event.target.value, true)}
                                                            disabled={!isEditable && !isEditPermission}
                                                        />
                                                    </ToolTipComponent>
                                            }
                                            <Typography variant="h2" className={classes.Rangehyphen}>
                                                {'-'}
                                            </Typography>
                                            {
                                                (property.datatype === "Date") ?
                                                    <DatePicker
                                                        autoOk
                                                        variant="inline"
                                                        format="MM/DD/YYYY"
                                                        maxDateMessage=""
                                                        minDateMessage=""
                                                        maxDate={moment('12/31/9999').format('MM/DD/YYYY')}
                                                        minDate={property.min ? moment(property.min).format('MM/DD/YYYY') : Date.now()}
                                                        value={property.max ? moment(property.max).format('MM/DD/YYYY') : Date.now()}
                                                        onChange={(date) => updateAttributeProperties(property, 'max', moment(date).format('MM/DD/YYYY'), true)}
                                                        onOpen={() => dispatch(toggleDatePicker(true))}
                                                        onClose={() => dispatch(toggleDatePicker(false))}
                                                        className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.bgGrey)}
                                                        disabled={!isEditable && !isEditPermission}
                                                    /> :
                                                    <ToolTipComponent title={property && property.max ? property.max : ''} arrow>
                                                        <TextField
                                                            name="max"
                                                            value={property && property.max ? property.max : ''}
                                                            className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.bgGrey, classes.tableInput)}
                                                            onChange={(event) => updateAttributeProperties(property, event.target.name, event.target.value)}
                                                            onBlur={(event) => updateAttributeProperties(property, event.target.name, event.target.value, true)}
                                                            disabled={!isEditable && !isEditPermission}
                                                        />
                                                    </ToolTipComponent>
                                            }
                                        </Grid>
                                    </TableCell>
                                    <TableCell className={classNames(classes.tableChips, classes.rulePanelChipContainer)}>
                                        <PatternInput
                                            displayName="name"
                                            values={[...property.patterns]}
                                            displayCount={2}
                                            enableAddButton={isEditPermission || isEditable}
                                            isEditable={isEditable}
                                            onSave={(pattern, index) => savePattern(property, pattern, index)}
                                            onDelete={(index) => removePattern(property, index)}
                                            disabled={attribute.name === "" || (!isEditable || !isEditPermission)}
                                            chipSize={60}
                                            isDeletePermission={isDeletePermission}
                                        />
                                    </TableCell>
                                    <TableCell className={classNames(classes.tableChips, classes.rulePanelChipContainer)}>
                                        <ChipInputValues
                                            addtooltiptitle="Add Enumeration"
                                            values={property && property.enum && property.enum.values ? [...property.enum.values] : []}
                                            displayName="value"
                                            displayCount={2}
                                            name="enum"
                                            isEditPermission={isEditPermission}
                                            enableAddButton={isEditPermission || isEditable}
                                            isDeletePermission={isDeletePermission}
                                            onSave={(_property, value, index) => saveEnumValue(property, value, index)}
                                            onDelete={(index) => removeEnumValue(property, index)} />
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </Grid>
    );
};

ValuesPanel.propTypes = {
    classes: PropTypes.object,
    properties: PropTypes.object,
    isEditable: PropTypes.bool,
    updateProperties: PropTypes.func,
    isDeletePermission: PropTypes.bool,
    isEditPermission: PropTypes.bool
};

export default withStyles((theme) => ({
    ...PropertiesListViewStyles(theme),
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}))(ValuesPanel);