/**
 * @name ImportSettings.jsx
 * @description Define Add , Update , List and Delete Field Functionalities
 * @author Srinath
 * CreatedAt : 06-Apr-2022
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, FormLabel, Button, FormControl, MenuItem, Select } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classNames from 'classnames';

// Import Styles
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';

const ImportSettings = (props) => {

    // Get Props
    const { classes, history } = props;

    // Initialize State
    const [selectedType, setSelectedType] = useState('');

    // On change Autocomplete type
    const onChangeType = (event) => {
        setSelectedType(event.target.value);
    };

    // On Upload File
    const onHandleUpload = () => {
        if (selectedType === 1) {
            history.push('/datasourceimport');
        } else if (selectedType === 2) {
            history.push('/importrules');
        } else if (selectedType === 3) {
            history.push('/importusers');
        }
    };

    return (
        <Grid>
            <ValidatorForm name="import_metadata" onSubmit={() => null}>
                <Grid className={classNames(classes.filterContainer)}>
                    <Grid container alignItems="center" justify="space-between" className={classNames(classes.datasourceFilterSection, "datasourceFilterSection")}>
                        {/* <Grid item xs={3} sm={3} className={classes.displayGrid}>
                            <CheckboxComponent checked={isAllSelected} onClick={() => onChangeFilter("datasources", { isSelected: !isAllSelected, name: "all" })} name={'Select All'} checkboxLabel="Select All" />
                        </Grid> */}
                        <Grid item xs={3} sm={3} className={classes.displayGrid}>
                            <FormLabel>
                                Import Type
                            </FormLabel>
                            <FormControl variant="standard" className={classes.typeWidth}>
                                {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
                                <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={selectedType}
                                onChange={onChangeType}
                                label="Import"
                                >
                                <MenuItem value={1}>
                                    Import Metadata
                                </MenuItem>
                                <MenuItem value={2}>
                                    Import Rules
                                </MenuItem>
                                <MenuItem value={3}>
                                    Import Users
                                </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.exportMetadataButton}>
                    <Button disabled={selectedType === ''} onClick={onHandleUpload} variant="contained" color="primary">
                        {'Upload File'}
                    </Button>
                </Grid>
            </ValidatorForm>
        </Grid>
    );
};

ImportSettings.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ImportSettings);