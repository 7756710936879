import React, { useCallback, useEffect, useState, memo } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';
import Loader from '../Loaders/Loader.jsx';

const GroupedBarChart = (props) => {
    const { analysis, theme, classes, chartHeight, colors, legends, chartClassName, showYAxis, barWidth, align, tooltipName, isLoading } = props;
    const [chartData, setChartData] = useState({});

    const loadProperties = useCallback((analysisData) => {
        setChartData({ ...analysisData });
    }, []);

    useEffect(() => {
        loadProperties({ ...analysis });
    }, [analysis, loadProperties]);

    const getTooltipName = useCallback((type, key) => {
        return type === "string" ? key.replace(/\D/g, '') : key;
    }, []);

    useEffect(() => {
        if (chartData && Object.keys(chartData).length !== 0) {
            const data = chartData.data ? chartData.data : [];
            let subgroups = chartData.subgroups ? chartData.subgroups : [];
            const groups = chartData.groups ? chartData.groups : [];
            subgroups = [...new Set(subgroups)];
            const margin = { top: 40, right: 20, bottom: 30, left: 40 };
            let width = 300;
            const height = chartHeight;
            const yMax = d3.max(data, (d) => {
                return d.value;
            });

            d3.select(`.${chartClassName} > *`).remove();
            const svg = d3.select(`.${chartClassName}`).append("svg")
                .attr("width", align === "center" ? "80%" : "100%")
                .style("margin", align === "center" ? "40px auto 0px auto" : "40px auto 0px auto")
                .attr("height", align === "center" ? (height + (margin.left * 2) + 30) : height * 2 + 30)
                .append("g")
                .attr("width", (d, i, p) => {
                    width = align !== "center" ? p[i].parentNode.clientWidth : (p[i].parentNode.clientWidth - 200);
                    return width;
                })
                .attr("height", height)
                .attr("transform",
                    align === "center" ? "translate(" + (margin.left + 70) + "," + ((margin.top / 2) - 15) + ")" : "translate(" + (margin.top) + "," + ((margin.top)) + ")");

            d3.select(`.${chartClassName} >.tooltip`).remove();

            // eslint-disable-next-line func-names
            const wrap = function () {
                const self = d3.select(this);
                if (self && self.node()) {
                    let textLength = self.node().getComputedTextLength(),
                        text = self.text();
                    while (textLength > (50) && text.length > 0) {
                        text = text.slice(0, -1);
                        self.text(text + '...');
                        textLength = self.node().getComputedTextLength();
                    }
                }
            };

            const div = d3.select(`.${chartClassName}`).append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            const x = d3.scaleBand()
                .domain(groups)
                .range([0, width])
                .padding([0.2]);
            showYAxis ?
                svg.append("g")
                    .attr("class", "x-axis")
                    .attr("color", theme.palette.chartColors.axis)
                    .attr("transform", "translate(0," + height + ")")
                    .call(d3.axisBottom(x))
                    .selectAll("text")
                    .style("text-anchor", "end")
                    .attr("dx", "-.8em")
                    .attr("dy", ".15em")
                    .attr("transform", "rotate(-60)")
                    .on('mouseover', (d) => {
                        div.transition()
                            .duration(200)
                            .style("opacity", 0.9);
                        div.html(`${d}`)
                            .style("left", d3.event.pageX - 160 + "px")
                            .style("top", d3.event.layerY + 60 + "px")
                            .style("width", "auto");
                    })
                    .on('mouseout', (d) => {
                        div.transition()
                            .duration(500)
                            .style("opacity", 0);
                    })
                    .each(wrap) : svg.append("g")
                        .attr("class", "x-axis")
                        .attr("color", theme.palette.chartColors.axis)
                        .attr("transform", "translate(0," + height + ")")
                        .call(d3.axisBottom(x));

            const y = d3.scaleLinear()
                .domain([0, yMax])
                .range([height, 0]);
            if (showYAxis) {
                svg.append("g")
                    .attr("color", theme.palette.chartColors.axis)
                    .call(d3.axisLeft(y));
            }

            const xSubgroup = d3.scaleBand()
                .domain(subgroups)
                .range([0, x.bandwidth()])
                .padding([0.05]);


            // Show the bars
            svg.append("g")
                .selectAll("g")
                .data(data)
                .enter()
                .append("g")
                .attr("transform", (d) => { return "translate(" + x(d.group) + ",0)"; })
                .selectAll("rect")
                .data((d) => { return subgroups.map((key) => { return { key: key, value: d[key] }; }); })
                .enter().append("rect")
                .attr("x", (d) => { return xSubgroup(d.key); })
                .attr("y", (d) => { return y(d.value); })
                .attr("width", barWidth ? xSubgroup.bandwidth() : xSubgroup.bandwidth())
                .attr("height", (d) => { return height - y(d.value); })
                .attr("fill", (_, i) => { return legends[i] && legends[i].color ? legends[i].color : "#fff"; })
                .on('mouseover', (d, i, p) => {
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html(`${tooltipName} : ${getTooltipName(align === "center" ? "number" : "string", d.key ? d.key : '')} <br/> Count : ${d.value}`)
                        .style("left", d3.event.pageX - 160 + "px")
                        .style("top", d3.event.layerY - 40 + "px")
                        .style("width", "130px");
                })
                .on('mouseout', (d) => {
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                });

            const xTranslate = () => {
                if ((window.innerWidth >= 1549) && (window.innerWidth <= 1860)) {
                    return 20;
                }
                else if (window.innerWidth >= 1861) {
                    return 60;
                }
                return 0;
            };

            if (legends && legends.length) {
                const legendGroup = svg.append("g")
                    .attr("class", "legends")
                    .attr("transform", + Number(align === "center") ? "translate(" + (width / 5) + "," + (height + margin.left + 40) + ")"
                        : "translate(" + xTranslate() + "," + (height + margin.left + 10) + ")");

                const legendGap = align === "center" ? 180 : 80;

                legendGroup.selectAll(".legend-group")
                    .data(legends)
                    .enter()
                    .append("g")
                    .attr("class", "legend-group")
                    .style("opacity", 1)
                    .append("rect")
                    .attr("class", "legend-rect")
                    .attr("width", 14)
                    .attr("height", 10)
                    .attr('x', (_, index) => { return (index * legendGap); })
                    .style("fill", (d) => d.color);

                const legendText = legendGroup.selectAll(".legend-group")
                    .append("text")
                    .attr("class", "legend-text")
                    .attr("x", (_, index) => { return index * legendGap + 20; })
                    .attr("y", 10)
                    .attr("alignment-baseline", "middle");

                legendText
                    .append('tspan')
                    .text((line) => {
                        return line.name;
                    });

                legendText
                    .append('tspan')
                    .attr("x", (_, i) => i * legendGap + margin.top)
                    .attr("dy", "1.2em")
                    .text((line) => {
                        return line.value;
                    });

                svg.selectAll('.tick text').attr('class', 'sentenceCase');
            }

        }
    }, [align, barWidth, chartClassName, chartData, chartHeight, colors, getTooltipName, legends, showYAxis, theme.palette.chartColors.axis, theme.palette.groupChart, tooltipName]);

    return (
        <Grid className={classNames(chartClassName, classes.chartStyles, classes.groupchart, classes.relative)} style={{ minHeight: 60 }} align={align === "center" ? "center" : null} >
            {isLoading && <Loader />}
        </Grid>
    );
};

GroupedBarChart.propTypes = {
    classes: PropTypes.object,
    analysis: PropTypes.object,
    chartHeight: PropTypes.number,
    colors: PropTypes.array,
    theme: PropTypes.object,
    legends: PropTypes.array,
    chartClassName: PropTypes.string,
    showYAxis: PropTypes.bool,
    barWidth: PropTypes.number,
    align: PropTypes.string,
    tooltipName: PropTypes.string,
    isLoading: PropTypes.bool
};

export default memo(withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(GroupedBarChart));