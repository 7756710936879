/**
 * @name ExportDQMetrics.jsx
 * @description Define Add , Update , List and Delete Field Functionalities
 * @author Srinath
 * CreatedAt : 16-Feb-2022
 */

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, withStyles, Typography, Button } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Loader from '../../components/Loaders/Loader.jsx';
import classNames from 'classnames';

// Import Styles
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';

import Organizariondomain from '../OrganizationDomain/Organizariondomain.jsx';



// Import Components
import TextBox from '../TextBox/TextBox.jsx';

// Import Actions
import { getLDAPDetails, updateLDAPDetails } from '../../actions/settingActions';

const LDAP = (props) => {

    // Get Props
    const { classes, organizationId } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [ldapserver, setldapserver] = useState('');
    const [domainproperties, setdomainproperties] = useState([]);
    const dispatch = useDispatch();
    /**
     * @name useEffect
     * @description UseEffect run when page initialize and any updates happends
     */
     useEffect(() => {
        dispatch(getLDAPDetails(organizationId)).then((response) => {
            if (response) {
                setldapserver(response.ldap_server);
                setdomainproperties(response.ldap_domain_properties);
            }
        });
    }, [dispatch, organizationId]);


    const handleChange = useCallback((property, value) => {
        setldapserver(value);
    }, []);

    const updateTags = (value, type, index) => {
        const tags = [...domainproperties];
        value = value.trim();
        if (type === "add") {
            const tagExist = tags.some((tag) => tag?.toLowerCase() === value?.toLowerCase());
            if (!tagExist) { tags.push(value); }
            setdomainproperties(tags);
        } else {
            if (index === -1) { tags.splice(tags.length - 1, 1); }
            else { tags.splice(index, 1); }
            setdomainproperties(tags);
        }
    };

    const handleSave = () => {
        setIsLoading(true);
        const requestParams = {
            "organizationId": organizationId,
            "ldap_server": ldapserver,
            "ldap_domain_properties": domainproperties
        };
        dispatch(updateLDAPDetails(requestParams)).then((response) => {
            setIsLoading(false);
        });
    };

    return (
        <Grid>
            <ValidatorForm name="export_metadata" onSubmit={() => null}>
                <Grid className={classNames(classes.filterContainer)}>
                    <Grid container direction="row" spacing={3} style={{ marginTop: 10 }}>
                        <Grid item xs={12}>
                            <b>
                                {"LDAP Configurations"}
                            </b>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={5} className={classes.ssotokenSection}>
                                    <TextBox
                                        label="Server Name"
                                        name="server_name"
                                        value={ldapserver ? ldapserver : ""}
                                        validators={['required']}
                                        errorMessages={['Server Name is required']}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={1} />

                                <Grid item xs={5} className={classes.ssotokenSection}>
                                    <Typography component="h6" variant="h6">
                                        {'Domains'}
                                    </Typography>
                                    <Organizariondomain type="tags" focus attributTag tags={domainproperties} updateTags={updateTags} disabled={false} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.exportMetadataButton}>
                    {
                        <Button disabled={false} onClick={() => handleSave()} variant="contained" color="primary">
                            {'Save'}
                            {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                        </Button>
                    }
                </Grid>
            </ValidatorForm>
        </Grid>
    );
};

LDAP.propTypes = {
    classes: PropTypes.object,
    organizationId: PropTypes.number
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(LDAP);