const SidePanelStyles = (theme) => ({
    sidePanelContainer: {
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'absolute',
        left: 50,
        width: 300,
        border: `1px solid ${theme.palette.grey.extraLight}`
    }

});

export default SidePanelStyles;