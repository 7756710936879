import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import ActionItemStyles from './ActionItemStyles.jsx';
import UserList from '../DatasourceList/UserList.jsx';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
import { getMentionName } from '../../helpers/appHelpers';

const ActionItemDetailList = (props) => {
    const { classes, actionItem, openDialogDetail, updateActionItemStatus, theme, openPopover } = props;
    const users = useSelector(({ account }) => account.users);
    const user = useSelector(({ account }) => account.user);
    const presentDate = moment().format('MMM DD YYYY');

    const permission = actionItem && actionItem.users ? actionItem.users.some((userInfo) => userInfo.assign_user_id === user.id || user.email === actionItem.assigneduser) : false;
    return (
        <Grid className={classNames(classes.cardContainer, classes.actionListContainer)} container justify="space-between" alignItems="center">
            {
                actionItem.status === "resolved" &&
                <Grid className={classNames(classes.statusBorder, classes.successBorder)} />
            }
            {
                actionItem.status === "open" &&
                <Grid className={classNames(classes.statusBorder, classes.openBorder)} />
            }
            {
                actionItem.status === "new" &&
                <Grid className={classNames(classes.statusBorder, classes.newBorder)} />
            }
            <Grid item xs={8}>
                <Grid className={classes.listDetail}>
                    <Grid className={classes.marginRight10}>
                        <IconButton disabled={!permission} onClick={() => updateActionItemStatus(actionItem.id, actionItem.status === "resolved" ? "open" : "resolved")}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="23.113" height="23.113" viewBox="0 0 23.113 23.113">
                                <path id="Path_1384" data-name="Path 1384" d="M13.556,2A11.556,11.556,0,1,0,25.113,13.556,11.561,11.561,0,0,0,13.556,2Zm0,20.8A9.245,9.245,0,1,1,22.8,13.556,9.257,9.257,0,0,1,13.556,22.8Zm5.3-14.353-7.616,7.616L8.252,13.083,6.623,14.712l4.623,4.623L20.49,10.09Z" transform="translate(-2 -2)" fill={actionItem.status === "resolved" ? theme.palette.chartColors.score100 : theme.palette.border.normal} />
                            </svg>
                        </IconButton>
                    </Grid>
                    <Grid>
                        <Typography component="h5" className={classes.linkTxt} variant="h5" onClick={() => openDialogDetail(actionItem)} dangerouslySetInnerHTML={{ __html: (actionItem && actionItem.comments && actionItem.comments[0] && actionItem.comments[0].comment) ? getMentionName(actionItem.comments[0].comment, users) : '' }} />
                        <Grid container alignItems="center">
                            <Typography className={classNames(classes.descriptionTxt, classes.datasourceInfoLink)}>
                                {actionItem && actionItem.source_name ? actionItem.source_name : ''}
                            </Typography>
                            {
                                actionItem && actionItem.dataset_id &&
                                <Fragment>
                                    <Typography className={classes.descriptionTxt} style={{ marginLeft: 5, marginRight: 5 }}>
                                        |
                                    </Typography>
                                    <Typography className={classNames(classes.descriptionTxt, classes.datasourceInfoLink)}>
                                        {actionItem && actionItem.dataset_name ? actionItem.dataset_name : ''}
                                    </Typography>
                                </Fragment>
                            }
                        </Grid>
                        <UserList users={actionItem && actionItem.users ? actionItem.users : []} itemCount={3} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Grid container alignItems="center" justify="space-between">
                    {/* <img className={classes.actionListImg} src={actionItem ? actionItem.asset_path : ''} alt="" onClick={() => openDialogDetail(actionItem)} /> */}
                    <Typography className={classes.actionListPriorityTxt} onClick={() => openDialogDetail(actionItem)}>
                        {actionItem && actionItem.priority ? actionItem.priority : 'High'}
                    </Typography>
                    <Typography className={classes.actionListTimeTxt} onClick={() => openDialogDetail(actionItem)}>
                        {
                            ((actionItem.comments) && (moment(actionItem && actionItem.created_date).format('MMM DD YYYY') === presentDate)) ?
                            moment(actionItem && actionItem.created_date).calendar() :
                            moment(actionItem && actionItem.created_date).format('MMM DD, YYYY HH:mm')
                        }
                    </Typography>
                    <IconButton onClick={(event) => openPopover(event, actionItem)}>
                        <MoreVertIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

ActionItemDetailList.propTypes = {
    classes: PropTypes.object,
    actionItem: PropTypes.object,
    openDialogDetail: PropTypes.func,
    updateActionItemStatus: PropTypes.func,
    theme: PropTypes.object,
    openPopover: PropTypes.func
};

export default withStyles((theme) => ({
    ...ActionItemStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ActionItemDetailList);