/**
 * NOTE : IF YOU CHANGE ANYTHING IN DYNAMIC COMPONENTS THEN YOU NEED TO CHECK Dataset/Properties/ListView/FieldPanenl COMPONENTS TOO.
 */

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from "prop-types";
import { Grid, Typography, withStyles, Switch, Box, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { DateTimePicker, DatePicker, TimePicker } from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Import Components
import TextBox from '../TextBox/TextBox.jsx';
import NumberInput from '../TextBox/NumberInput.jsx';
import IntegerInput from '../TextBox/IntegerInput.jsx';
import { appConstants } from '../../constants/appConstants';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import Loader from '../Loaders/Loader.jsx';

// Import Style
import DynamicElementByDataTypeStyles from './DynamicElementByDataTypeStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import DatasetStyles from '../Dataset/DatasetStyles.jsx';
import TileViewStyles from '../Dataset/Properties/TileView/TileViewStyles.jsx';
import MasterSearchPanelStyles from '../MasterSearchPanel/MasterSearchPanelStyles.jsx';
import { getCustomGroups, updateGroup, updateGroupOrder } from '../../actions/settingActions';
import { useDispatch, useSelector } from 'react-redux';
import Collapse from '@material-ui/core/Collapse';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';


/**
 *DynamicElementByDataType component
 *
 * @class DynamicElementByDataType
 * @extends {React.Component}
 */
const DynamicElementByDataType = (props) => {

    /**
     * Get Props
     */
    const { classes, fields, properties, dyanamicEleOnChange, isEditable, level, datasource, dataset, attribute } = props;
    const dispatch = useDispatch();
    const [groups, setGroups] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const userid = useSelector(({ account }) => account.user);
    const uid = userid && userid.id;
    const [tabLoading, setTabLoading] = useState(true);

    const getGroups = useCallback(() => {
        setTabLoading(false);
        dispatch(getCustomGroups(0, "catalog")).then((response) => {
            if (response) {
                const filteredGroup = [];
                response.map((group, index) =>
                    fields.forEach((field) => {
                        if (field.group_name === group.name) {
                            if (filteredGroup.filter((grp) => grp.name === group.name).length === 0) {
                                filteredGroup.push({ "name": group.name, "id": group.id, 'collapse': group.collapse, 'position': group.position });
                            }
                        }
                    })
                );
                if (filteredGroup && filteredGroup.length && filteredGroup[0].position) {
                    if (uid in filteredGroup[0].position) {
                        const sorter = (a, b) => {
                            if (a.position[uid] < b.position[uid]) {
                                return -1;
                            } return 1;
                        };
                        filteredGroup.sort(sorter);
                    }
                }

                setGroups([...filteredGroup]);
            }
            setLoading(false);
        });
    }, [dispatch, fields, uid]);

    useEffect(() => {
        if (tabLoading) {
            getGroups();
        }
    }, [getGroups, tabLoading]);

    /**
     * Bind Element By DataType
     * @param {*} field
     * @returns
     */
    const getElementByDataType = (field) => {
        let value = getValue(field);

        switch (field.data_type) {
            case 'Text':
                return <TextBox
                    placeholder={field.name}
                    name={field.name}
                    id={field.name}
                    value={value}
                    onChange={(event) => dyanamicEleOnChange(event.target.value, field)}
                    onBlur={() => dyanamicEleOnChange(value, field, true, true)}
                    disabled={!isEditable}
                    fullWidth
                    multiline
                />;
            case 'Integer':
                return <IntegerInput
                    placeholder={field.name}
                    name={field.name}
                    id={field.name}
                    type={'number'}
                    value={value}
                    className={classNames(classes.textboxContainer, classes.inputOutline, classes.businessNameTextBox)}
                    onChange={(event) => dyanamicEleOnChange(event.target.value, field)}
                    onBlur={() => dyanamicEleOnChange(value, field, true, true)}
                    disabled={!isEditable}
                />;
            case 'Numeric':
                return <NumberInput
                    placeholder={field.name}
                    name={field.name}
                    id={field.name}
                    type={'number'}
                    value={value}
                    className={classNames(classes.textboxContainer, classes.inputOutline, classes.businessNameTextBox)}
                    onChange={(event) => dyanamicEleOnChange(event.target.value, field)}
                    onBlur={() => dyanamicEleOnChange(value, field, true, true)}
                    disabled={!isEditable}
                />;
            case 'Boolean':
                value = !(value === '' || value === 'False' || value === null);
                return <Switch
                    id={field.name}
                    name={field.name}
                    checked={Boolean(value)}
                    color="secondary"
                    onChange={(event) => dyanamicEleOnChange(event.target.checked, field, true, true)}
                    disabled={!isEditable}
                />;
            case 'Date':
                return <DatePicker
                    autoOk
                    name={field.name}
                    placeholder="MM-DD-YYYY"
                    className={classNames(classes.dateInputContainer, classes.textboxContainer, classes.inputOutline, classes.businessNameTextBox)}
                    variant="inline"
                    format="MM-DD-YYYY"
                    value={value ? value : null}
                    onChange={
                        (date) => {
                            dyanamicEleOnChange(date, field);
                        }
                    }
                    onBlur={
                        () => {
                            dyanamicEleOnChange(value, field, true);
                        }
                    }
                    disabled={!isEditable}
                />;
            case 'Time':
                return <TimePicker
                    autoOk
                    name={field.name}
                    placeholder="HH:mm"
                    className={classNames(classes.dateInputContainer, classes.textboxContainer, classes.inputOutline, classes.businessNameTextBox)}
                    variant="inline"
                    format="HH:mm"
                    value={value ? value : null}
                    onChange={
                        (date) => {
                            dyanamicEleOnChange(date, field);
                        }
                    }
                    onBlur={
                        () => {
                            dyanamicEleOnChange(value, field, true);
                        }
                    }
                    disabled={!isEditable}
                />;
            case 'DateTime':
                return <DateTimePicker
                    autoOk
                    name={field.name}
                    placeholder="MM-DD-YYYY HH:mm"
                    className={classNames(classes.dateInputContainer, classes.textboxContainer, classes.inputOutline, classes.businessNameTextBox)}
                    variant="inline"
                    format="MM-DD-YYYY HH:mm"
                    ampm={false}
                    value={value ? value : null}
                    onChange={
                        (date) => {
                            dyanamicEleOnChange(date, field);
                        }
                    }
                    onBlur={
                        () => {
                            dyanamicEleOnChange(value, field, true);
                        }
                    }
                    disabled={!isEditable}
                />;
            case 'DropDown':
                return (
                    <FormControl className={classNames(classes.minWidth200, classes.width100)}>
                        <Select
                            placeholder={field.name}
                            value={value}
                            className={classNames(classes.textboxContainer, classes.inputOutline, classes.paddingZero)}
                            onChange={(event) => dyanamicEleOnChange(event.target.value, field, true, true)}
                        >
                            {/* <MenuItem value={"None"}>
                                {"None"}
                            </MenuItem> */}
                            {
                                field.dropdown_options.map((option, index) => {
                                    return (
                                        <MenuItem key={`option_${index}`} value={option}>
                                            {option}
                                        </MenuItem>);
                                })
                            }
                        </Select>
                    </FormControl>);
            default:
                return null;
        }
    };

    /**
     * Get Value
     * @param {*} field
     * @returns
     */
    const getValue = (field) => {

        let obj = null;

        if (level === appConstants.fieldKeys.datasource) { obj = properties.filter((p) => p.custom_field === field.id && p.datasource === datasource)[0]; }
        else if (level === appConstants.fieldKeys.dataset) { obj = properties.filter((p) => p.custom_field === field.id && p.datasource === datasource && p.dataset === dataset)[0]; }
        else if (level === appConstants.fieldKeys.attribute) { obj = properties.filter((p) => p.custom_field === field.id && p.dataset === dataset && p.attribute === attribute)[0]; }
        else { obj = null; }

        if (obj) { return obj.value || ''; }
        return '';
    };

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const changePosition = (attributes, sort = true) => {
        let position = 1;
        if (sort) {
            attributes = attributes.sort((a, b) => a.id - b.id);
        }
        for (const attribute of attributes) {
            attribute.position[uid] = position;
            position += 1;
        }
        return attributes;
    };

    const handleDrag = (result) => {
        if (!result.destination) {
            return;
        }
        let orderedItems = reorder(
            groups,
            result.source.index,
            result.destination.index
        );
        orderedItems = changePosition(orderedItems, false);
        setGroups([...orderedItems]);
        dispatch(updateGroupOrder([...orderedItems]));
    };

    const updateGroupAccess = (name, index) => {
        const group_name = { name: name };
        if (groups[index].collapse === null) {
            groups[index].collapse = [];
        }
        const check_collapse = groups[index].collapse.indexOf(uid);
        if (check_collapse !== -1) {
            groups[index].collapse.splice(check_collapse, 1);
        } else {
            groups[index].collapse.push(uid);
        }
        setGroups([...groups]);
        dispatch(updateGroup(group_name));
    };


    return (

        fields ?
            <DragDropContext
                onDragEnd={handleDrag}>
                <Droppable droppableId="groupsuid">
                    {
                        (provided) => (
                            <Box
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {
                                    groups.map((groupname, index) =>
                                        <Draggable key={groupname.id}
                                            draggableId={`${groupname.id}`}
                                            index={index}>
                                            {
                                                (provided) => (
                                                    <Grid className={classes.dragOverviewContainer}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <Grid className={classNames(classes.cardbox, classes.accordionBox)}>
                                                            <Box className={classNames(classes.collapseHead, groups.length <= 1 ? classes.noPointer : null)} onClick={() => updateGroupAccess(groupname.name, index)}>
                                                                <Typography variant="h5" component="h5">
                                                                    {groupname.name ? groupname.name : "Uncategorized"}
                                                                </Typography>
                                                                <IconButton className={classes.alignIconCenter}>
                                                                    {
                                                                        groupname.collapse?.includes(uid) ?
                                                                            <ToolTipComponent title={"Expand"} arrow>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                                                                    <path d="M24 24H0V0h24v24z" fill="none" opacity=".87" />
                                                                                    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" />
                                                                                </svg>
                                                                            </ToolTipComponent> :
                                                                            <ToolTipComponent title={"Collapse"} arrow>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                                                    <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z" />
                                                                                </svg>
                                                                            </ToolTipComponent>
                                                                    }
                                                                </IconButton>
                                                            </Box>
                                                            <Collapse in={!groupname.collapse?.includes(uid)}>
                                                                <Grid container xs={12}>
                                                                    {
                                                                        fields.filter((filteredfield) => {
                                                                            return filteredfield.group_name === groupname.name;
                                                                        }).map((field, index) =>
                                                                            <Grid key={`field_${index}`} item xs={3} sm={3} md={4} lg={4} xl={4} className={classNames(classes.padding15, classes.paddingLR30)}>
                                                                                <Grid container direction="column" wrap="nowrap">
                                                                                    <Typography component="h6" variant="h6">
                                                                                        {field.name}
                                                                                    </Typography>
                                                                                    <ToolTipComponent title={field.description ? field.description : 'Description'}>
                                                                                        <Typography variant="body2" className={classNames(classes.marginTop10, classes.marginBottom10, "description")}>
                                                                                            {field.description ? field.description : 'Description'}
                                                                                        </Typography>
                                                                                    </ToolTipComponent>
                                                                                    {
                                                                                        field.data_type === "text" &&
                                                                                        <Typography className={classes.hidden}>
                                                                                            {getValue(field)}
                                                                                        </Typography>
                                                                                    }
                                                                                    <Grid item className={classNames(classes.sliderInput, classes.propertyComponents)}>
                                                                                        {getElementByDataType(field)}
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        )
                                                                    }
                                                                </Grid>
                                                            </Collapse>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                        </Draggable>
                                    )
                                }
                                {
                                    isLoading &&
                                    <Loader />
                                }
                                {provided.placeholder}
                            </Box>
                        )
                    }
                </Droppable>
            </DragDropContext> : <NoResultFound />
    );
};

DynamicElementByDataType.defaultProps = {
    fields: [],
    properties: [],
    classes: {},
    dyanamicEleOnChange: () => { },
    isEditable: true,
    removeBorder: false,
    level: '',
    datasource: null,
    dataset: null,
    attribute: null
};


DynamicElementByDataType.propTypes = {
    fields: PropTypes.array.isRequired,
    properties: PropTypes.array.isRequired,
    classes: PropTypes.object,
    dyanamicEleOnChange: PropTypes.func.isRequired,
    isEditable: PropTypes.bool,
    level: PropTypes.string,
    datasource: PropTypes.number,
    dataset: PropTypes.number,
    attribute: PropTypes.number
};


export default withStyles((theme) => ({
    ...DynamicElementByDataTypeStyles(theme),
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...MasterSearchPanelStyles(theme),
    ...Styles(theme)
}))(DynamicElementByDataType);