const AttributeSelectStyles = (theme) => ({
    root: {
        flexGrow: 1,
        height: 250
    },
    container: {
        flexGrow: 1,
        position: 'relative',
        '& .MuiBackdrop-root': {
            opacity: '0 !important',
            zIndex: 1330
        }
    },
    paper: {
        position: 'absolute',
        zIndex: 1330,
        marginTop: theme.spacing(1),
        maxWidth: 'max-content',
        maxHeight: 200,
        overflowY: 'auto',
        width: 300,
        top: 45,
        left: 0,
        backgroundColor: '#fff !important'
    },
    chip: {
        margin: theme.spacing(0.5, 0.25)
    },
    inputRoot: {
        flexWrap: 'wrap',
        height: 35
    },
    inputInput: {
        width: 'auto',
        flexGrow: 1,
        textTransform: 'capitalize',
        fontSize: 14,
        fontFamily: theme.palette.titleFont,
        fontWeight: 500
    },
    divider: {
        height: theme.spacing(2)
    },
    list: {
        padding: '3px 10px 3px 10px',
        '& .MuiListItemIcon-root': {
            minWidth: 'auto',
            alignItems: 'center'
        }
    },
    active: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)'
    },
    icon: {
        padding: 3
    },
    listText: {
        marginLeft: 8,
        '& .MuiTypography-body1': {
            fontSize: 13,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            minWidth: 150
        }
    }
});

export default AttributeSelectStyles;