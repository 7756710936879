import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import * as apiService from '../services/apiService';
import { searchActions } from '../constants/actionTypes/searchActionTypes';

export const updateSearchFilter = (data) => {
    return (dispatch) => {
        dispatch(success(searchActions.UPDATE_SERACH_FILTERS, data));
    };
};

export const updateSearchResultOffset = (data) => {
    return (dispatch) => {
        dispatch(success(searchActions.UPDATE_SERACH_RESULT_OFFSET, data));
    };
};

export const updateSelectedAttribute = (data) => {
    return (dispatch) => {
        dispatch(success(searchActions.UPDATE_SELECTED_ATTRIBUTE, data));
    };
};

export const clearSearchResult = () => {
    return (dispatch) => {
        dispatch(success(searchActions.CLEAR_SERACH_RESULTS));
    };
};

export const getDashboards = () => {
    return (dispatch) => {
        dispatch(request(searchActions.GET_SEARCH_RESULT_REQUEST));
        return apiService.getRequest(appConstants.END_POINTS.search.dashboard).then((response) => {
            dispatch(success(searchActions.GET_SEARCH_DASHBORAD_INFO_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(searchActions.GET_SEARCH_DASHBORAD_INFO_FAILURE, error));
        });
    };
};

export const addusersearch = (data) => {
    return (dispatch) => {
        dispatch(request(searchActions.SAVE_USER_SEARCH_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.catalog.addusersearchactivity, data).then((response) => {
            dispatch(success(searchActions.SAVE_USER_SEARCH_SUCCESS));
            return response;
        }, (error) => {
            dispatch(failure(searchActions.SAVE_USER_SEARCH_FAILURE, error));
        });
    };
};

export const addfilterusersearch = (data) => {
    return (dispatch) => {
        dispatch(request(searchActions.SAVE_USER_FILTERSEARCH_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.catalog.adduserfilteractivity, data).then((response) => {
            dispatch(success(searchActions.SAVE_USER_FILTERSEARCH_SUCCESS));
            return response;
        }, (error) => {
            dispatch(failure(searchActions.SAVE_USER_FILTERSEARCH_FAILURE, error));
        });
    };
};

export const trackSearch = (data) => {
    return (dispatch) => {
        dispatch(request(searchActions.SAVE_SEARCH_TRACK_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.search.track, data).then((response) => {
            dispatch(success(searchActions.SAVE_SEARCH_TRACK_SUCCESS));
            return response;
        }, (error) => {
            dispatch(failure(searchActions.SAVE_SEARCH_TRACK_FAILURE, error));
        });
    };
};

export const getSearch = (searchBy, filter) => {
    return (dispatch) => {
        dispatch(request(searchActions.GET_SEARCH_RESULT_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.search.searchBy, filter).then((response) => {
            dispatch(success(searchActions.GET_SEARCH_RESULT_SUCCESS, { [searchBy]: response.data, total_count: response.total_counts }));
            return response;
        }, (error) => {
            dispatch(failure(searchActions.GET_SEARCH_RESULT_FAILURE, error));
        });
    };
};

export const getSearchCount = (searchBy, filter) => {
    return (dispatch) => {
        dispatch(request(searchActions.GET_SEARCH_RESULT_COUNT_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.search.searchBy, filter).then((response) => {
            dispatch(success(searchActions.GET_SEARCH_RESULT_COUNT_SUCCESS, { [searchBy]: response.data, total_count: response.total_counts }));
            return response;
        }, (error) => {
            dispatch(failure(searchActions.GET_SEARCH_RESULT_COUNT_FAILURE, error));
        });
    };
};

export const getAllTags = () => {
    return (dispatch) => {
        dispatch(request(searchActions.GET_SEARCH_RESULT_TAGS_REQUEST));
        return apiService.getRequest(appConstants.END_POINTS.search.getAllTags).then((response) => {
            dispatch(success(searchActions.GET_SEARCH_RESULT_TAGS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(searchActions.GET_SEARCH_RESULT_TAGS_FAILURE, error));
        });
    };
};