const SkeletonLoaderStyles = (theme) => ({
    skeletonContainer: {
        marginBottom: 10,
        display: 'flex',
        '& .MuiSkeleton-root': {
            display: 'inline-flex'
        }
    },
    textContent: {
        maxWidth: 'calc(100% - 120px)',
        flexBasis: 'calc(100% - 120px)',
        marginLeft: 10
    },
    skeletonText: {
        display: 'block !important',
        width: '100%'
    }

});

export default SkeletonLoaderStyles;