import React, { Component } from "react";
import {
  Grid,
  Typography,
  Button,
  withStyles,
  CircularProgress,
  InputAdornment,
  IconButton,
  Box
} from "@material-ui/core";
import { Visibility } from "@material-ui/icons";
import { ValidatorForm } from "react-material-ui-form-validator";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import VisibilityOffOutlinedIcon from "@material-ui/icons/VisibilityOffOutlined";

import * as accountActions from "../../actions/accountActions";
import TextBox from "../../components/TextBox/TextBox.jsx";
import Slider from "../../components/Slider/Slider.jsx";
import AccountStyles from "./AccountStyles.jsx";
import Styles from "../../layouts/Styles.jsx";
import Localization from "../../constants/localization";
import Logo from "../../assets/images/dq_logo.svg";

import { appConstants } from "../../constants/appConstants";
import {
  getVersionDetails,
  getDnsDetails,
  getSSODomainDetails,
  getPropertiesOrganization
} from "../../actions/settingActions";
import {
  loadConnectionTypes,
  getDefaultTypes,
  getCurationRules,
  getReferences,
  getConnectionTypes,
  updateJobStatus
} from "../../actions/datasourceActions";
import {
  updateActions,
  updateConversationList
} from "../../actions/notificationActions";
import { returnImage } from "../../helpers/appHelpers";
// import SocketService from "../../services/socketService";
import { ssoAlert } from '../../actions/alertActions.js';

class Login extends Component {
  /**
   * Constructor
   * @description Class Constructor
   * @param {object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      showPassword: false,
      version: [],
      dns_address: "",
      sso_domain: {}
    };
  }

  componentDidMount() {
    this.props.loadConnectionTypes();
    this.props.getSSODomainDetails().then((response) => {
      if (response) {
        this.setState({ sso_domain: { ...response } });
      }
    });
    this.props.getDnsDetails().then((response) => {
      if (response) {
        this.setState({ dns_address: response.data['dns_address'] });
      }
    });

    this.props.getVersionDetails().then((response) => {
      if (response) {
        this.setState({ version: [...response] });
      }
    });
    this.props.getPageTitle('Login');
  }

  /**
   * HandleChange
   * @description Update input value to state variable
   * @param {string} name Input name
   * @param {object} event Input field event
   */
  handleChange = (name) => (event) => {
    const value = event.target.value.trim();
    this.setState({ [name]: value });
  };

  /**
   * HandleSubmit
   * @description Submit Login form
   */
  handleSubmit() {
    this.props.login(this.state).then((response) => {
      if (response) {

        /*
         * const socket = new SocketService();
         * socket.getDispatch({
         *   notification: this.props.updateActions,
         *   conversation: this.props.updateConversationList,
         *   jobStatusUpdate: this.props.updateJobStatus
         * });
         * socket.getNotification();
         * socket.getJobStatus();
         * socket.getHistory(this.props.history);
         * socket.receiveMessage();
         */

        this.props.getDefaultTypes();
        this.props.getCurationRules();
        this.props.getReferences();
        this.props.getConnectionTypes();
				this.props.getPropertiesOrganization(response.organization ? response.organization : response.organization_id);
      }
    });
  }

  /**
   * SSO Submit
   * @description User login with SSO
   */
  loginSSO = () => {
    if (this.state.username === "") {
      this.props.ssoAlert('Need to Enter Email');
      return false;
    }

    // eslint-disable-next-line no-useless-escape
    const regex = new RegExp('^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$', 'i');
    if (!regex.test(this.state.username)) {
      this.props.ssoAlert('Enter Valid Email Address');
      return false;
    }

    const splitEmail = this.state.username.split("@");
    const domainName = splitEmail[1].split(".");
    const domain_details = this.state.sso_domain[domainName[0]];
    if (domain_details === undefined) {
      this.props.ssoAlert('Your Domain Not mapping any organization in SSO Please check System Admin.');
      return false;
    }
    window.location = this.state.dns_address + "/api/sso/" + domain_details + "/?sso";
  };

  loginLDAP = () => {
    if (this.state.username === "") {
      this.props.ssoAlert('Need to Enter Email.');
      return false;
    }
    if (this.state.password === "") {
      this.props.ssoAlert('Need to Enter Password.');
      return false;
    }
    this.props.loginldap(this.state).then((response) => {
      if (response) {

        /*
         * const socket = new SocketService();
         * socket.getDispatch({
         *   notification: this.props.updateActions,
         *   conversation: this.props.updateConversationList,
         *   jobStatusUpdate: this.props.updateJobStatus
         * });
         * socket.getNotification();
         * socket.getJobStatus();
         * socket.getHistory(this.props.history);
         * socket.receiveMessage();
         */

        this.props.getDefaultTypes();
        this.props.getCurationRules();
        this.props.getReferences();
        this.props.getConnectionTypes();
				this.props.getPropertiesOrganization(response.organization ? response.organization : response.organization_id);
      }
    });

    // eslint-disable-next-line no-useless-escape
    /*
     * const regex = new RegExp('^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,15})$', 'i');
     * if (!regex.test(this.state.username)) {
     *   this.props.ssoAlert('Enter Valid Email Address');
     *   return false;
     * }
     */

    /*
     * const splitEmail = this.state.username.split("@");
     * const domainName = splitEmail[1].split(".");
     * window.location = this.state.dns_address + "/api/sso/" + domainName[0] + "/?sso";
     */
  };

  render() {
    const { classes, isLoading, theme } = this.props;
    return (
      <Grid container className={classes.container}>
        <Grid item xs={8} className={classes.sliderContainer}>
          {
            theme.palette && theme.palette.banner !== "" ? (
              <Grid
                className={classes.bannerBg}
                style={
                  {
                    backgroundImage: `url(${returnImage(
                      appConstants.API_BASE_URL,
                      theme.palette.banner
                    )})`
                  }
                }
              />
            ) : (
              <Slider />
            )
          }
        </Grid>
        <Grid item xs={4} className={classes.container}>
          <Grid
            container
            direction="column"
            justify="space-between"
            className={classes.section}
            wrap="nowrap"
          >
            <Grid item align="center">
              <img
                src={
                  theme.palette && theme.palette.logo !== ""
                    ? returnImage(appConstants.API_BASE_URL, theme.palette.logo)
                    : Logo
                }
                alt="Logo"
                className={classes.logo}
              />
            </Grid>
            <Grid item>
              <ValidatorForm
                name="loginform"
                onSubmit={() => this.handleSubmit()}
              >
                <Typography variant="h3" className={classes.accountTitleLogin}>
                  {"Login"}
                </Typography>
                <TextBox
                  placeholder={Localization.email}
                  onChange={this.handleChange("username")}
                  name="username"
                  validators={["required", "isEmail"]}
                  errorMessages={
                    [
                      "Email ID is required",
                      "Enter valid Email ID"
                    ]
                  }
                  id="username"
                  value={this.state.username}
                  margin="normal"
                  fullWidth
                  className={
                    classNames(
                      classes.inputOutline,
                      classes.validationTextBox
                    )
                  }
                />
                <TextBox
                  placeholder={Localization.password}
                  type={this.state.showPassword ? "text" : "password"}
                  onChange={this.handleChange("password")}
                  name="password"
                  validators={["required"]}
                  errorMessages={["Password is required"]}
                  id="password"
                  value={this.state.password}
                  fullWidth
                  className={
                    classNames(
                      classes.inputOutline,
                      classes.validationTextBox
                    )
                  }
                  InputProps={
                    {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            className={classes.passwordAdornment}
                            onClick={
                              () =>
                                this.setState({
                                  showPassword: !this.state.showPassword
                                })
                            }
                          >
                            {
                              this.state.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOffOutlinedIcon />
                              )
                            }
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }
                />
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  className={classes.btnContainer}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      type="submit"
                      size="large"
                      color="primary"
                      className={classes.loginbtn}
                      disabled={isLoading}
                    >
                      {
                        isLoading ? (
                          <CircularProgress
                            className={classes.progress}
                            color="primary"
                            size={24}
                            thickness={4}
                          />
                        ) : (
                          `${Localization.login}`
                        )
                      }
                    </Button>
                  </Grid>
                  <Grid item align="right">
                    <Button
                      disabled={isLoading}
                      className={
                        classNames(
                          classes.block,
                          classes.nopadding,
                          classes.inlineButton,
                          classes.bgNone,
                          classes.Linkhover
                        )
                      }
                      onClick={() => this.props.history.push("/forgotpassword")}
                    >
                      {Localization.forgot}
                    </Button>
                    <Button
                      disabled={isLoading}
                      className={
                        classNames(
                          classes.block,
                          classes.nopadding,
                          classes.inlineButton,
                          classes.bgNone,
                          classes.Linkhover
                        )
                      }
                      onClick={() => this.loginLDAP()}
                    >
                      {Localization.ldap}
                    </Button>
                    <Button
                      disabled={isLoading}
                      className={
                        classNames(
                          classes.block,
                          classes.nopadding,
                          classes.inlineButton,
                          classes.bgNone,
                          classes.Linkhover
                        )
                      }
                      onClick={() => this.loginSSO()}
                    >
                      {Localization.sso}
                    </Button>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </Grid>
            <Grid item align="center">
              <Typography variant="body2" className={classes.copyrightText}>
                {theme.palette.loginCopyrightText}
              </Typography>
              <Typography variant="body2" style={{ minHeight: 45 }}>
                <Box component="span" display="inline" style={{ fontSize: 10 }} className={classes.copyrightText}>
                  Version: &nbsp;
                </Box>
                {
                  this.state.version.map((d) => (
                    d.name !== "timestamp" ?
                      <Box component="span" display="inline" style={{ fontSize: 10 }} className={classes.copyrightText} key={d.name}>
                        {d.version}
                        (
                        {d.name}
                        )
                        &nbsp;
                      </Box>
                      :
                      ""
                  ))
                }
                <Typography variant="body2" style={{ fontSize: 10 }} className={classes.copyrightText}>
                  {this.state.version && this.state.version.length > 0 && this.state.version.length === 4 ? this.state.version[3].version : ""}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object,
  isLoading: PropTypes.bool,
  theme: PropTypes.object,
  login: PropTypes.func,
  loginldap: PropTypes.func,
  history: PropTypes.object,
  getVersionDetails: PropTypes.func,
  loadConnectionTypes: PropTypes.func,
  /*
   * updateConversationList: PropTypes.func,
   * updateActions: PropTypes.func,
   */
  getDefaultTypes: PropTypes.func,
  getCurationRules: PropTypes.func,
  getReferences: PropTypes.func,
  getDnsDetails: PropTypes.func,
  getSSODomainDetails: PropTypes.func,
  getConnectionTypes: PropTypes.func,
  ssoAlert: PropTypes.func,
  // updateJobStatus: PropTypes.func
  getPageTitle: PropTypes.func,
  getPropertiesOrganization: PropTypes.func
};

/**
 * Map State To Props
 * @description Get Reducer Props value to Props property
 * @param {object} account
 */
const mapStateToProps = ({ account, setting }) => {
  return {
    ...account,
    setting
  };
};

/**
 * Dispatch Props
 * @description dispatch Props actions
 * @param {object} dispatch
 */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      ...accountActions,
      getVersionDetails,
      getDnsDetails,
      getSSODomainDetails,
      loadConnectionTypes,
      updateActions,
      updateConversationList,
      getDefaultTypes,
      getCurationRules,
      getReferences,
      getConnectionTypes,
      updateJobStatus,
      ssoAlert,
      getPropertiesOrganization
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withStyles(
    (theme) => ({
      ...AccountStyles(theme),
      ...Styles(theme)
    }),
    { withTheme: true }
  )(Login)
);