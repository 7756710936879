import React from 'react';

import { Grid, withStyles, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

import Styles from '../../layouts/Styles.jsx';
import DriftDashBoardStyle from './DriftDashBoardStyle.jsx';
import TextBox from '../TextBox/TextBox.jsx';

const AlertRuleAccordianHeader = (props) => {
    const { classes, theme, selectedChartType, onChartTypeChange } = props;
    const chartTypeFilter = ['Percentage Change', 'Value'];

    const DropDownIcon = (iconProps) => {
        return (
            <svg {...iconProps} className={classes.DropDownIcon} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" top="" width="12px" height="18px" viewBox="0 0 451.847 451.847">
                <g>
                    <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" fill={theme.palette.grey.dark} />
                </g>
            </svg>
        );
    };

    return (
        <Grid container justify="flex-end">
            <Grid item>
                <TextBox
                    className={classes.inlinetxt}
                    onChange={(event) => setTimeout(() => onChartTypeChange(event.target.value), 10)}
                    value={selectedChartType}
                    select
                    SelectProps={
                        {
                            MenuProps: {
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "center"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "center"
                                },
                                getContentAnchorEl: null
                            },
                            IconComponent: (iconProps) => DropDownIcon(iconProps)
                        }
                    }
                >
                    {
                        chartTypeFilter.map((filterOption, index) => (
                            <MenuItem key={`filterBy_${index}`} value={filterOption} className={classes.menuItem}>
                                {filterOption}
                            </MenuItem>
                        ))
                    }
                </TextBox>
            </Grid>
        </Grid>
    );
};

AlertRuleAccordianHeader.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    selectedChartType: PropTypes.string,
    onChartTypeChange: PropTypes.func
};

export default withStyles((theme) => ({
    ...DriftDashBoardStyle(theme),
    ...Styles(theme)
}), { withTheme: true })(AlertRuleAccordianHeader);