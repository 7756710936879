import React, { useEffect, useCallback, useState } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import DashboardChartTile from '../Dashboard/DashboardChartTile.jsx';
import DomainTile from './DomainTile.jsx';
import { getDomainMetricAnalysis, getDomainMetrics } from '../../actions/domainActions';
import DomainStyles from './DomainStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';

const DomainSummary = (props) => {
    const { classes, domainId, onChangeTab } = props;
    const [timelineAnalysis, setTimelineAnalysis] = useState({});
    const [analysis, setAnalysis] = useState({});
    const dispatch = useDispatch();

    const getDomainMetric = useCallback(() => {
        dispatch(getDomainMetrics(domainId)).then((response) => {
            if (response) {
                setAnalysis({ ...response });
            }
        });
    }, [dispatch, domainId]);

    const getDomainMetricTimelineAnalysis = useCallback(() => {
        dispatch(getDomainMetricAnalysis(domainId)).then((response) => {
            if (response) {
                const timelineAnalysis = {
                    datasource: [],
                    dataset: [],
                    attribute: [],
                    totalRecords: response.total_recors ? response.total_recors : 0,
                    invalidRecords: 0,
                    totalRules: response.total_rules ? response.total_rules : 0
                };
                for (const analysis of response) {
                    timelineAnalysis.datasource.push(analysis.datasource_timeline);
                    timelineAnalysis.dataset.push(analysis.dataset_timeline);
                    timelineAnalysis.attribute.push(analysis.attribute_timeline);
                }
                setTimelineAnalysis({ ...timelineAnalysis });
            }
        });
    }, [dispatch, domainId]);

    useEffect(() => {
        getDomainMetric();
        getDomainMetricTimelineAnalysis();
    }, [getDomainMetric, getDomainMetricTimelineAnalysis]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <Grid className={classNames(classes.marginBottom15, classes.anchorLink)} onClick={() => onChangeTab(1)}>
                    <DashboardChartTile chartColor="#FF5826" chartClassName={"datasource-timeline"} type="Datasources" count={analysis ? analysis.datasource : 0} analysis={timelineAnalysis.datasource ? timelineAnalysis.datasource : []} />
                </Grid>
                <Grid>
                    <DomainTile count={analysis.total_records ? analysis.total_records : 0} titleText="Total Records" color="#85e8ef" />
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Grid className={classNames(classes.marginBottom15, classes.anchorLink)} onClick={() => onChangeTab(1)}>
                    <DashboardChartTile chartColor="#5EB5EF" chartClassName={"dataset-timeline"} type="Datasets" count={analysis ? analysis.dataset : 0} analysis={timelineAnalysis.dataset ? timelineAnalysis.dataset : []} />
                </Grid>
                <Grid>
                    <DomainTile count={analysis.invalid_records ? analysis.invalid_records : 0} titleText="Invalid Records" color="#656d98" />
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Grid className={classNames(classes.marginBottom15, classes.anchorLink)} onClick={() => onChangeTab(1)}>
                    <DashboardChartTile chartColor="#EC286D" chartClassName={"attribute-timeline"} type="Attributes" count={analysis ? analysis.attribute : 0} analysis={timelineAnalysis.attribute ? timelineAnalysis.attribute : []} />
                </Grid>
                <Grid>
                    <DomainTile count={analysis.total_rules ? analysis.total_rules : 0} titleText="Total Rules" color="#b2dbf7" />
                </Grid>
            </Grid>
        </Grid>
    );
};

DomainSummary.propTypes = {
    classes: PropTypes.object,
    domainId: PropTypes.number,
    // totalRules: PropTypes.number,
    onChangeTab: PropTypes.object
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DomainSummary);