import React from "react";
import PropTypes from 'prop-types';


export const DatasourceIco = ({ theme }) => <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 11.289 15.394" fill={theme.palette.grey.light}>
    <path id="database_17_" data-name="database (17)" d="M15.645,17.394c2.805,0,5.645-.793,5.645-2.309V4.309C21.289,2.793,18.45,2,15.645,2S10,2.793,10,4.309V15.085C10,16.6,12.839,17.394,15.645,17.394Zm0-14.368c2.865,0,4.618.831,4.618,1.283s-1.753,1.283-4.618,1.283-4.618-.831-4.618-1.283S12.78,3.026,15.645,3.026ZM11.026,5.695a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924V7.9c0,.452-1.753,1.283-4.618,1.283S11.026,8.353,11.026,7.9Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Z" transform="translate(-10 -2)" fill={theme.palette.grey.light} />
                                            </svg>;


export const DatasetIco = ({ theme }) => <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17.751 16.711">
    <path id="table" d="M0,15V31.711H17.751V15Zm16.711,1.04v2.115H1.04V16.04ZM9.4,22.315h3.12V24.4H9.4ZM8.355,24.4H5.235v-2.08h3.12Zm1.04-3.12V19.2h3.12v2.08Zm-1.04,0H5.235V19.2h3.12Zm-4.16,0H1.04V19.2H4.2Zm0,1.04V24.4H1.04v-2.08Zm0,3.12v2.08H1.04v-2.08Zm1.04,0h3.12v2.08H5.235Zm3.12,3.12v2.115H5.235V28.556Zm1.04,0h3.12v2.115H9.4Zm0-1.04v-2.08h3.12v2.08Zm4.16-2.08h3.155v2.08H13.556Zm0-1.04v-2.08h3.155V24.4Zm0-3.12V19.2h3.155v2.08ZM1.04,28.556H4.2v2.115H1.04Zm12.516,2.115V28.556h3.155v2.115Z" transform="translate(0 -15)" fill={theme.palette.grey.light} />
                                         </svg>;

export const AttributesIco = ({ theme }) => <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17.773 16.732">
    <path id="table" d="M0,15V31.732H17.773V15Zm16.732,1.041v2.118h-7.3l.024-2.118H4.265v2.118H1.041V16.041H16.732ZM9.407,22.325h3.124v2.083H9.407Zm0-1.041V19.2h3.124v2.083Zm-5.207,0H1.041V19.2H4.2Zm0,1.041v2.083H1.041V22.325Zm0,3.124v2.083H1.041V25.449Zm5.207,3.124h3.124v2.118H9.407Zm0-1.041V25.449h3.124v2.083Zm4.166-2.083h3.159v2.083H13.573Zm0-1.041V22.325h3.159v2.083Zm0-3.124V19.2h3.159v2.083ZM1.041,28.573H4.2v2.118H1.041Zm12.532,2.118V28.573h3.159v2.118Z" transform="translate(0 -15)" fill={theme.palette.grey.light} />
                                            </svg>;

export const DomainsIco = ({ theme }) => <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16.068 18.554">
    <g id="rubik" transform="translate(-34.297)">
        <g id="Group_3147" data-name="Group 3147" transform="translate(34.297)">
            <path id="Path_1474" data-name="Path 1474" d="M42.331,0,34.3,4.639v9.277l8.034,4.639,8.034-4.639V4.639Zm0,1.259,2.655,1.533L42.331,4.324,39.677,2.791Zm-4.29,13.56-2.655-1.533V10.221l2.655,1.533Zm0-4.324L35.387,8.962V5.9L38.042,7.43Zm-2.11-5.542,2.655-1.533,2.655,1.533L38.587,6.486Zm5.854,12.028-2.655-1.533V12.383l2.655,1.533Zm0-4.324-2.655-1.533V8.059l2.655,1.533Zm-2.11-5.542,2.655-1.533,2.655,1.533L42.331,8.648Zm5.854,8.333-2.655,1.533V13.916l2.655-1.533Zm0-4.324-2.655,1.533V9.592l2.655-1.533Zm-2.11-6.171,2.655-1.533L48.73,4.953,46.076,6.486Zm5.854,8.333-2.655,1.533V11.754l2.655-1.533Zm0-4.324L46.621,10.5V7.43L49.275,5.9Z" transform="translate(-34.297)" fill={theme.palette.grey.light} />
        </g>
    </g>
                                         </svg>;

export const CommentsIco = ({ theme }) => <svg id="chat-comment-oval-speech-bubble-with-text-lines" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 22.576 22.576">
    <g id="_x32__26_">
        <g id="Group_161282" data-name="Group 161282">
            <path id="Path_2725" data-name="Path 2725" d="M14.816,11.994H7.761a.706.706,0,0,0,0,1.411h7.055a.706.706,0,0,0,0-1.411Zm1.411-4.233H6.35a.706.706,0,0,0,0,1.411h9.877a.706.706,0,0,0,0-1.411ZM11.288,0C5.054,0,0,4.422,0,9.877a9.454,9.454,0,0,0,4.233,7.7v5l4.945-3a12.945,12.945,0,0,0,2.11.179c6.235,0,11.288-4.422,11.288-9.877S17.523,0,11.288,0Zm0,18.343A11.417,11.417,0,0,1,8.9,18.084l-3.322,2,.044-3.272A8.18,8.18,0,0,1,1.411,9.877c0-4.675,4.422-8.466,9.877-8.466S21.165,5.2,21.165,9.877,16.743,18.343,11.288,18.343Z" fill={theme.palette.grey.light} />
        </g>
    </g>
                                          </svg>;

DatasourceIco.propTypes = {
    theme: PropTypes.object
};

DatasetIco.propTypes = {
    theme: PropTypes.object
};

AttributesIco.propTypes = {
    theme: PropTypes.object
};

DomainsIco.propTypes = {
    theme: PropTypes.object
};

CommentsIco.propTypes = {
    theme: PropTypes.object
};