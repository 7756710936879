import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, withStyles, Typography, IconButton, Table, TableBody, TableHead, TableRow, TableCell, Chip } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import { getEnrichments, deleteEnrichment } from '../../actions/settingActions';
import Loader from '../Loaders/Loader.jsx';
import { appConstants } from '../../constants/appConstants';
import Search from '../TextBox/Search.jsx';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import classNames from 'classnames';
import ToolTipComponent from '../../components/Tooltip/Tooltip.jsx';
import ChipPopover from './../ChipPopover/ChipPopover.jsx';

const EnrichmentList = (props) => {
    const { classes, theme, organizationId, onChangeEnrichment } = props;
    const dispatch = useDispatch();
    const [enrichments, setEnrichment] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [enrichmentList, setEnrichmentList] = useState([]);
    const [search, setSearch] = useState('');
    const [selectedEnrichment, setSelectedEnrichment] = useState(null);
    const authTypes = { 'no_auth': 'No Auth', 'api_key': 'Api Key', 'basic_auth': 'Basic Auth' };


    const getAllEnrichment = useCallback(() => {
        setLoading(true);
        dispatch(getEnrichments(organizationId)).then((response) => {
            setLoading(false);
            if (response) {
                // Remove Deduplicate
                response = response.filter((x) => x.name !== 'Deduplicate');
                setEnrichmentList([...response]);
                setEnrichment([...response]);
            }
        });
    }, [dispatch, organizationId]);

    useEffect(() => {
        getAllEnrichment();
    }, [organizationId, getAllEnrichment]);

    const searchEnrichment = (value) => {
        setSearch(value);
        const searchResult = value !== "" ? enrichmentList.filter((item) => item.name.toLowerCase().includes(value.toLowerCase())) : enrichmentList;
        setEnrichment([...searchResult]);
    };

    const deleteEnrichmentList = (selectedEnrichment) => {
        const index = enrichmentList.findIndex((enrichment) => enrichment.id === selectedEnrichment.id);
        if (index <= -1) {
            return;
        }
        enrichmentList.splice(index, 1);
        setSelectedEnrichment(null);
        setEnrichment([...enrichmentList]);
        setEnrichmentList([...enrichmentList]);
        dispatch(deleteEnrichment(selectedEnrichment.id));
    };

    const dispalyAuthorization = (val) => {
        let d = '';
        try {
            d = JSON.parse(val);
        } catch (error) {
            d = val;
        }
        return authTypes[d.type];
    };

    return (
        <Grid className={organizationId !== 0 ? classes.referenceContainer : classes.referenceContainer2} >
            <Grid container justify="space-between" className={classes.marginBottom12}>
                <Grid item>
                    <Typography className={classes.tabTitle} variant="h5" component="h5">
                        Enrichment Lists
                    </Typography>
                </Grid>
                <Grid item className={classes.search} >
                    <Search className={classes.dqSearchBoxRefList} placeholder="Search Enrichment" value={search} onChange={(value) => searchEnrichment(value)} />
                    <ToolTipComponent title={"Add Enrichment"} arrow>
                        <IconButton className={classes.alignIconCenter} onClick={() => onChangeEnrichment('add', '')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                    <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                        <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                        <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                    </g>
                                </g>
                            </svg>
                        </IconButton>
                    </ToolTipComponent>
                </Grid>
            </Grid>
            <Grid className={classes.tableWrapperStyle}>
                <Table className={classes.tableStyle}>
                    <TableHead>
                        <TableRow>
                            {
                                appConstants.enrichmentHeaders.map((header, index) =>
                                    <TableCell className={classes.tableHeadCell} key={index} align={header === "Actions" ? "center" : "left"}>
                                        {header}
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            enrichments.map((enrichment, index) =>
                                <TableRow key={`row_${index}`}>
                                    <TableCell className={classes.tableCell}>
                                        <Typography className={classes.enrichmentTableCell}>
                                            {enrichment.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <ToolTipComponent title={enrichment.endpoint} arrow>
                                            <Typography className={classes.enrichmentTableCell}>
                                                {enrichment.endpoint}
                                            </Typography>
                                        </ToolTipComponent>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Typography className={classes.enrichmentTableCell}>
                                            {dispalyAuthorization(enrichment.authorization)}
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {
                                            enrichment.url_parameters &&
                                            enrichment.url_parameters.split(",").map((v, index) =>
                                                <Chip key={`params_${index}`} className={classNames(classes.paramChip)} label={v} />
                                            )
                                        }
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {
                                            enrichment.query_parameters &&
                                            enrichment.query_parameters.split(",").map((v, index) =>
                                                <ToolTipComponent key={`params_${index}`} title={v} arrow>
                                                    <Chip key={`params_${index}`} className={classNames(classes.paramChip)} label={v} />
                                                </ToolTipComponent>
                                            )
                                        }
                                    </TableCell>
                                    <TableCell className={classNames(classes.tableCell, classes.fixedChipWidth)}>
                                        <ChipPopover
                                            values={enrichment.response_parameters ? enrichment.response_parameters.split(",") : []}
                                            displayCount={1}
                                            name="advanced"
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        <ToolTipComponent title={"Edit"} arrow>
                                            <IconButton className={classes.padding6} onClick={() => onChangeEnrichment('edit', enrichment)}>
                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22" viewBox="0 0 32 32" >
                                                    <g id="edit" transform="translate(0 0)">
                                                        <path fill={theme.palette.primary.main}
                                                            stroke={theme.palette.primary.main}
                                                            strokeWidth="0.3"
                                                            strokeMiterlimit="10"
                                                            d="M27.2,8L24,4.8c-0.8-0.8-2.2-0.8-3,0L6.5,19.4c-0.1,0.1-0.1,0.1-0.1,0.2l-2.1,7.5c-0.1,0.3,0.1,0.6,0.4,0.7c0.1,0,0.2,0,0.3,0l7.5-2.1c0.1,0,0.2-0.1,0.2-0.1l14.6-14.4C28,10.2,28,8.9,27.2,8L27.2,8z M18.6,8.7l2,2L10.2,21l-0.7-1.5c-0.1-0.2-0.3-0.3-0.5-0.3H8.2L18.6,8.7z M5.5,26.5L6.2,24L8,25.8L5.5,26.5z M11.7,24.7l-2.6,0.7l-2.6-2.6l0.7-2.6h1.4l0.9,1.8c0.1,0.1,0.1,0.2,0.2,0.2l1.8,0.9L11.7,24.7L11.7,24.7z M12.8,23.8V23c0-0.2-0.1-0.4-0.3-0.5L11,21.7l10.3-10.3l2,2L12.8,23.8zM26.4,10.3l-2.4,2.3l-4.7-4.7l2.3-2.4c0.4-0.4,1.1-0.4,1.5,0l3.2,3.2C26.8,9.2,26.8,9.9,26.4,10.3L26.4,10.3z" />
                                                    </g>
                                                </svg>
                                            </IconButton>
                                        </ToolTipComponent>
                                        <ToolTipComponent title={"Delete"} arrow>
                                            <IconButton className={classes.padding6} onClick={() => setSelectedEnrichment(enrichment)}>
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="15.879" height="18" viewBox="0 0 15.879 18">
                                                    <path fill={theme.palette.primary.main} d="M126.181,166H114.543a.449.449,0,0,0-.438.5l1.194,10.447a2.282,2.282,0,0,0,2.267,2.025h5.794a2.272,2.272,0,0,0,2.27-2.066l.991-10.411a.453.453,0,0,0-.113-.344A.446.446,0,0,0,126.181,166Zm-1.431,10.816a1.4,1.4,0,0,1-1.392,1.272h-5.794a1.4,1.4,0,0,1-1.39-1.246l-1.138-9.96H125.7Z" transform="translate(-112.422 -160.971)" />
                                                    <path fill={theme.palette.primary.main} d="M91.473,54.206H86.8v-.842A1.376,1.376,0,0,0,85.424,52h-2.9a1.376,1.376,0,0,0-1.374,1.364v.842H76.476a.441.441,0,0,0,0,.882h15a.441.441,0,0,0,0-.882Zm-5.557,0H82.033v-.842a.493.493,0,0,1,.492-.481h2.9a.493.493,0,0,1,.492.481Z" transform="translate(-76.035 -52)" />
                                                </svg> */}
                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 32 32">
                                                    <g id="Delete">
                                                        <path fill={theme.palette.primary.main}
                                                            stroke={theme.palette.primary.main}
                                                            strokeWidth="0.92"
                                                            strokeMiterlimit="10"
                                                            d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                                        <path fill={theme.palette.primary.main}
                                                            stroke={theme.palette.primary.main}
                                                            strokeWidth="0.92"
                                                            strokeMiterlimit="10"
                                                            d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                    </g>
                                                </svg>
                                            </IconButton>
                                        </ToolTipComponent>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </Grid>
            {
                enrichments.length === 0 && !isLoading &&
                <NoResultFound height="80%" />
            }
            {isLoading && <Loader />}
            <AlertDialog title="Delete Enrichment"
                message={`Are you sure you want to delete the enrichment - ${selectedEnrichment ? selectedEnrichment.name : ''}?`}
                okButtonText="OK"
                cancelButtonText="Cancel"
                show={Boolean(selectedEnrichment)}
                onClickOK={() => deleteEnrichmentList(selectedEnrichment)}
                onClickCancel={() => setSelectedEnrichment(null)} />
        </Grid>
    );
};

EnrichmentList.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    onChangeEnrichment: PropTypes.func,
    organizationId: PropTypes.number
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(EnrichmentList);