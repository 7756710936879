import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import NormalDistribution from './Anomaly/NormalDistribution.jsx';
import BoxPlot from './Anomaly/BoxPlot.jsx';
import ScatterMarginal from './Anomaly/ScatterMarginal.jsx';
import KMeans from './Anomaly/KMeans.jsx';
import { appConstants } from '../../constants/appConstants.js';
import { createRule, getFieldType, getDefaultOperator, getOperators } from '../RuleBuilder/QueryBuilderUtil.jsx';
import { useSelector } from 'react-redux';

const AnomalyChart = (props) => {
    const { selectedAttribute, onFilter, anomalyRule, getRulePlan, profileRuleEnable, properties, renderAnomaly, attributeChange } = props;
    const datasource = useSelector(({ datasource }) => datasource.datasource);
    const [menuType, setMenuType] = useState('Normal Distribution');
    const [chartType, setChartType] = useState('Normal Distribution');
    const [edited, setEdited] = useState(false);


    const onFilterChange = useCallback((selectedValue) => {
        const inputParams = {
            chartType: appConstants.charts.anomaly.type,
            chartName: menuType,
            filterOptions: false,
            defaultField: selectedAttribute.name,
            defaultFieldType: getFieldType(selectedAttribute.type),
            attributeType: selectedAttribute.type,
            connectionType: datasource?.type,
            datatype: selectedAttribute.type ? selectedAttribute.type : 'text',
            defaultQuery: ''
        };

        if (selectedValue) {
            inputParams.data = selectedValue;
            const ruleParams = {
                defaultField: selectedAttribute.name,
                defaultFieldType: getFieldType(selectedAttribute.type),
                attributeType: selectedAttribute.type,
                connectionType: datasource?.type,
                isScan: datasource?.scan
            };
            const rule = createRule(ruleParams);
            let operator = getDefaultOperator(rule.fieldType);
            if (selectedValue.value.operator) {
                const operators = getOperators(rule.fieldType);
                operator = operators.find((p) => p.label.toLowerCase() === selectedValue.value.operator.toLowerCase());
            }
            rule.operator = { ...operator };
            if (selectedValue.value.operator) {
                rule.value = [selectedValue.value.min, selectedValue.value.max];
            } else {
                rule.value = selectedValue.value;
            }
            inputParams.rule = rule;
            inputParams.filterType = selectedValue.filterType;

        } else {
            let chartInputParams = {};
            switch (menuType) {
                case "Normal Distribution":
                    chartInputParams = properties.chartData['Normal Distribution'] ? properties.chartData['Normal Distribution'].input_params : {};
                    break;
                case "Scatter With Marginal Plot":
                    chartInputParams = properties.chartData['Scatter Plot'] ? properties.chartData['Scatter Plot'].input_params : {};
                    break;
                case "Box Plot":
                case "BoxPlot":
                    chartInputParams = properties.chartData['Box Plot'] ? properties.chartData['Box Plot'].input_params : {};
                    break;
                default:
                    chartInputParams = properties.chartData['K Means'] ? properties.chartData['K Means'].input_params : {};
                    break;
            }
            if (chartInputParams) {
                inputParams.defaultQuery = `select * from <table_name> where <type> ${selectedAttribute.name}.null_format between ${chartInputParams.lower_limit} and ${chartInputParams.upper_limit}`;
            }
        }

        onFilter(inputParams);
    }, [datasource?.scan, datasource?.type, menuType, onFilter, properties.chartData, selectedAttribute]);

    const discardEdit = useCallback(() => {
        setEdited(false);
    }, []);

    const renderChart = useCallback(() => {
        if (!(properties.filters).includes(menuType)) {
            setMenuType(properties.filters[0]);
        }
        switch (menuType) {
            case "Normal Distribution":
                return <NormalDistribution
                    attribute={selectedAttribute.name ? selectedAttribute.name : ''}
                    chartData={properties.chartData['Normal Distribution']}
                    onFilterChange={(params) => onFilterChange(params)}
                    attributeChange={attributeChange}
                    edited={edited}
                    discardEdit={discardEdit} />;
            case "Scatter With Marginal Plot":
                return <ScatterMarginal
                    attribute={selectedAttribute.name ? selectedAttribute.name : ''}
                    chartData={properties.chartData['Scatter Plot']}
                    onFilterChange={(params) => onFilterChange(params)}
                    attributeChange={attributeChange}
                    edited={edited}
                    discardEdit={discardEdit} />;
            case "Box Plot":
            case "BoxPlot":
                return <BoxPlot
                    attribute={selectedAttribute.name ? selectedAttribute.name : ''}
                    chartData={properties.chartData['Box Plot']}
                    onFilterChange={(params) => onFilterChange(params)}
                    attributeChange={attributeChange}
                    edited={edited}
                    discardEdit={discardEdit} />;
            case 'KMeans':
                return <KMeans
                    attribute={selectedAttribute.name ? selectedAttribute.name : ''}
                    chartData={properties.chartData['K Means']}
                    onFilterChange={(params) => onFilterChange(params)}
                    attributeChange={attributeChange}
                    edited={edited}
                    discardEdit={discardEdit} />;
            default:
                break;
        }
    }, [attributeChange, discardEdit, edited, menuType, onFilterChange, properties.chartData, properties.filters, selectedAttribute.name]);

    const onChangeMenu = useCallback((value) => {
        setMenuType(value);
        let chartValue = '';
        switch (value) {
            case "Normal Distribution":
                chartValue = "Normal Distribution";
                break;
            case "Scatter With Marginal Plot":
                chartValue = "Scatter Plot";
                break;
            case "BoxPlot":
                chartValue = "Box Plot";
                break;
            default:
                chartValue = "K Means";
                break;
        }
        setChartType(chartValue);
        setEdited(true);
        renderChart();
    }, [renderChart]);

    const onRuleChange = (value) => {
        profileRuleEnable(chartType, value);
    };

    const loadChartType = useCallback(() => {
        onChangeMenu(anomalyRule.name);
    }, [anomalyRule, onChangeMenu]);

    useEffect(() => {
        if (anomalyRule.isChanged) {
            loadChartType(anomalyRule);
            renderAnomaly();
        }
    }, [anomalyRule, anomalyRule.isChanged, loadChartType, renderAnomaly]);
    return (
        <ChartContainer
            id={appConstants.charts.anomaly.id}
            title={appConstants.charts.anomaly.name}
            menuProperties={
                [
                    {
                        options: properties.filters ? properties.filters.sort() : [],
                        value: menuType,
                        onChange: onChangeMenu
                    }
                ]
            }
            filterProperties={
                {
                    enableFilter: true,
                    onFilter: onFilterChange
                }
            }
            ruleProperties={
                {
                    edit: true,
                    onRule: onRuleChange,
                    rule: getRulePlan(chartType)
                }
            }
            chartData={properties.chartData}
        >
            {renderChart()}
        </ChartContainer>
    );
};

AnomalyChart.propTypes = {
    chartData: PropTypes.object,
    selectedAttribute: PropTypes.object,
    onFilter: PropTypes.func,
    getRulePlan: PropTypes.func,
    profileRuleEnable: PropTypes.func,
    properties: PropTypes.object,
    anomalyRule: PropTypes.string,
    renderAnomaly: PropTypes.func,
    attributeChange: PropTypes.bool
};

export default AnomalyChart;