import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Grid, Popover, withStyles, Switch, Typography, FormLabel } from '@material-ui/core';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import PatternInputStyles from './PatternInputStyles.jsx';
import { useSelector } from 'react-redux';
import PatternQueryBuilder from '../PatternQueryBuilder/PatternQueryBuilder.jsx';
import { createPatternRuleGroup } from '../PatternQueryBuilder/PatternQueryBuilderUtil.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const PatternInputEditor = (props) => {
    const { classes, open, anchorEl, pattern, onChange, onSave, onClose } = props;
    const datasource = useSelector(({ datasource }) => datasource.datasource);

    const isComplexPattern = (pattern) => {
        return (typeof pattern.sqlPattern === 'object');
    };

    const onChangeType = useCallback((multi) => {
        pattern.multi = multi;
        if (multi && !isComplexPattern(pattern)) {
            pattern.sqlPattern = createPatternRuleGroup();
        }
        if (onChange) {
            onChange(pattern);
        }
    }, [onChange, pattern]);


    const getPatternInput = (pattern) => {
        let patternInput = pattern.sqlPattern ? pattern.sqlPattern : "";
        patternInput = patternInput ? patternInput : createPatternRuleGroup();
        return patternInput;
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => onClose()}
            anchorOrigin={
                {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
            transformOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'center'
                }
            }
        >

            <ValidatorForm className={classes.fullwidth} name="ruleForm" autoComplete="off" onSubmit={() => onSave()}>
                <Grid container direction="column" className={classes.modelEditor}>
                    <Typography variant="h5" className={classes.pb10}>
                        Add Pattern
                    </Typography>
                    <Grid container direction="column" justify="flex-start" wrap="nowrap" style={{ height: '100%' }}>
                        <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
                            <Grid item xs={12}>
                                <TextBox
                                    id="rule_name"
                                    name="name"
                                    label="Pattern Name"
                                    value={pattern.name ? pattern.name : ''}
                                    validators={['required']}
                                    errorMessages={['Patten Name is required']}
                                    fullWidth
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                />
                            </Grid>
                        </Grid>
                        <TextBox
                            name={"pattern"}
                            label={"Posix Pattern"}
                            className={classes.marginTop10}
                            value={pattern.pattern ? pattern.pattern : ''}
                            validators={['required']}
                            errorMessages={['Pattern is required']}
                            fullWidth
                            onChange={(event) => onChange(event.target.name, event.target.value)}
                        />
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.patternCheckBoxContainer}>
                            <Grid container>
                                <Grid container justify="space-between" alignItems="center">
                                    <Grid item xs={3}>
                                        <Typography>
                                            {'SQL Pattern'}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <ToolTipComponent title="Complex Pattern">
                                            <Switch color="secondary"
                                                name="multi"
                                                checked={Boolean(pattern.multi)}
                                                onChange={() => onChangeType(!pattern.multi)}
                                            />
                                        </ToolTipComponent>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    {
                                        pattern.multi ?
                                        <PatternQueryBuilder
                                            ruleGroup={getPatternInput(pattern)}
                                            onQueryChange={(ruleGroup) => onChange('sqlPattern', ruleGroup)}
                                            selectedDatasource={datasource}
                                        />
                                        :
                                        <TextBox
                                            name={"sqlPattern"}
                                            value={!isComplexPattern(pattern) ? pattern.sqlPattern : ''}
                                            fullWidth
                                            onChange={(event) => onChange(event.target.name, event.target.value)}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.patternCheckBoxContainer}>
                            <FormLabel>
                                Is Valid
                            </FormLabel>
                            <Switch size="small"
                                color="secondary"
                                name="isvalid"
                                checked={Boolean(pattern.isvalid)}
                                onChange={(event) => onChange(event.target.name, event.target.checked)} />
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginBottom: 20 }} className={classes.patternCheckBoxContainer}>
                            <FormLabel>
                                Sense
                            </FormLabel>
                            <Switch size="small"
                                color="secondary"
                                name="sense"
                                checked={Boolean(pattern.sense)}
                                onChange={(event) => onChange(event.target.name, event.target.checked)} />
                        </Grid>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                            <Grid container direction="row" justify="flex-end">
                                <Grid item className={classes.btnContainer}>
                                    <Button type="submit" variant="contained" color="primary" className={classes.actionButtons}>
                                        {'Save'}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button className={classNames(classes.cancelButton, classes.actionButtons, classes.paddingCancelButton, classes.marginLeft15)}
                                        onClick={() => onClose && onClose()}>
                                        {'Cancel'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
            </ValidatorForm>
        </Popover>
    );
};

PatternInputEditor.defaultProps = {
    attribute: {},
    rule: {},
    fields: [],
    anchorEl: null,
    open: false,
    attributeName: ''
};

PatternInputEditor.propTypes = {
    classes: PropTypes.object,
    pattern: PropTypes.object,
    onChange: PropTypes.func,
    anchorEl: PropTypes.object,
    open: PropTypes.bool,
    onSave: PropTypes.func,
    onClose: PropTypes.func
};

export default withStyles((theme) => ({
    ...PatternInputStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(PatternInputEditor);