import React, { memo, useEffect, useState, useCallback } from 'react';
import {
    Grid, withStyles, IconButton, Button, ButtonGroup
} from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TooltipComponent from '../Tooltip/Tooltip.jsx';

import Styles from '../../layouts/Styles.jsx';
import DatasetStyles from './DatasetStyles.jsx';
import { getWorkbookItems, deleteWorkbookItems } from '../../actions/datasetActions';
import { useDispatch } from 'react-redux';
import TextBox from '../TextBox/TextBox.jsx';
import WorkbookItemGrid from './WorkbookItemGrid.jsx';
import WorkbookItemList from './WorkbookItemList.jsx';
import Loader from '../../components/Loaders/Loader.jsx';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';


const WorkbookItems = ({ datasourceId, workbookId, classes, theme, history, datasource, workbook }) => {

    const dispatch = useDispatch();
    const [items, setItems] = useState([]);
    const [defaultItems, setDefaultItems] = useState([]);
    const [defaultView, setDefaultView] = useState('list');
    const [search, setSearchText] = useState("");
    const [loading, setLoading] = useState(true);
    const [isChecked, datasetChecked] = useState(false);
    const [selectedItems, setSelectedItems] = useState(null);


    /**
     * Loads the Workbook Views
     */
    useEffect(() => {
        const requestParams = {
            datasource_id: datasourceId,
            workbook_id: workbook.id
        };
        dispatch(getWorkbookItems(requestParams)).then((response) => {
            if (response && response.length > 0) {
                setDefaultItems([...response]);
                setItems([...response]);
                setLoading(false);
            }
        });
    }, [datasourceId, dispatch, workbook.id, workbookId]);

    const onChangeSearch = (value) => {
        setSearchText(value);
        const datasetItems = JSON.parse(JSON.stringify(defaultItems));
        const dataset = value !== "" ? datasetItems.filter((dataset) => {
            return dataset.name.toLowerCase().includes(value.toLowerCase());
        }) : datasetItems;
        setItems([...dataset]);
    };

    const onSelectDataset = (event, property) => {
        const itemLists = defaultItems;
        for (const itemSet of itemLists) {
            if (property === "All") {
                itemSet.isChecked = event.target.checked;
            } else {
                if (itemSet.id === property) {
                    itemSet.isChecked = event.target.checked;
                    break;
                }
            }
        }
        const isAllChecked = defaultItems.filter((item) => item.isChecked).length === defaultItems.length;
        datasetChecked(isAllChecked);
        setItems([...itemLists]);
    };

    const onDeleteDatasets = useCallback((value) => {
        let selectedDatasets = [];
        const itemLists = defaultItems;
        if (value) {
            for (const itemSet of itemLists) {
                    if (itemSet.id === value) {
                        itemSet.isChecked = true;
                        break;
                    }
                }
        }
        selectedDatasets = itemLists.filter((dataset) => dataset.isChecked);
        if (selectedDatasets && selectedDatasets.length > 0) {
            setSelectedItems([...selectedDatasets]);
        }
    }, [defaultItems]);

    const deleteDatasets = () => {
        const model = {
            items: []
        };
        for (const item of selectedItems) {
                model.items.push(item.id);
        }
        setSelectedItems(null);
        dispatch(deleteWorkbookItems(model)).then((response) => {
                const itemsList = items.filter((item) => !item.isChecked);
                setDefaultItems([...itemsList]);
                setItems([...itemsList]);
                datasetChecked(false);
        });
    };


    const redirectDataset = (item) => {
        const dataId = {
            'source_id': datasource.id,
            'dataset_id': workbook.id,
            'item_id': item.id,
            'item_name': item.name
        };
        history.push({ pathname: `/item/${item.id}`, state: { dataId: dataId } });
    };


    return (
        <Grid>
            <Grid container justify="flex-end" alignItems="center">
                <Grid>
                    <Grid style={{ marginRight: '7px' }} className={`${classNames(classes.includeSearchContainer, classes.dqSearchBox, classes.inlineFlex)}`}>
                        <TextBox
                            value={search}
                            placeholder="Search Dataset"
                            onChange={(event) => onChangeSearch(event.target.value)}
                            className={classNames(classes.inputOutline, classes.searchTextbox)}
                        />

                        <IconButton className={classes.includeSearchIcon} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="13.094" height="13.092" viewBox="0 0 13.094 13.092">
                                <g transform="translate(0 -0.035)">
                                    <path fill="#afb2b3" d="M5.27,10.57A5.257,5.257,0,0,0,8.5,9.46l3.483,3.483a.655.655,0,0,0,.926-.926L9.427,8.534A5.267,5.267,0,1,0,5.27,10.57ZM2.471,2.5a3.958,3.958,0,1,1,0,5.6h0a3.944,3.944,0,0,1-.02-5.577l.02-.02Z" transform="translate(0 0)" />
                                </g>
                            </svg>
                        </IconButton>

                    </Grid>


                    {
                        defaultView === 'list' &&
                        <TooltipComponent title="Delete Dataset" arrow>
                            <IconButton className={classes.iconButtonSmall} onClick={() => onDeleteDatasets()}>
                                <svg version="1.1"
                                    id="Layer_1"
                                    width="18"
                                    height="18"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    viewBox="0 0 32 32">
                                    <g id="Delete">
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
                                    L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
                                    L22.3,25.8z" />
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
                                    c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
                                    c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                    </g>
                                </svg>

                            </IconButton>
                        </TooltipComponent>
                    }

                </Grid>
                <Grid className={classes.marginLeft15}>
                    <ButtonGroup className={classes.buttonGroup}>
                        <Button variant="contained"
                            className={classNames(defaultView === 'grid' ? 'active' : '')}
                            onClick={() => setDefaultView('grid')}>
                            <svg id="dashboard_black_24dp" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path id="Path_2630" data-name="Path 2630" d="M0,0H24V24H0Z" fill="none" />
                                <path id="Path_2631" data-name="Path 2631" d="M3,13h8V3H3Zm0,8h8V15H3Zm10,0h8V11H13ZM13,3V9h8V3Z" fill={defaultView === 'grid' ? "#ffffff" : "#afb2b3"} />
                            </svg>

                        </Button>
                        <Button variant="contained"
                            className={classNames(defaultView === 'list' ? 'active' : '')}
                            onClick={() => setDefaultView('list')}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
                                <g id="Layer_2" data-name="Layer 2" transform="translate(-2.268 -4)">
                                    <path id="Path_236" data-name="Path 236" d="M21.71,4H11.3a1.3,1.3,0,1,0,0,2.6H21.71a1.3,1.3,0,0,0,0-2.6Z" transform="translate(-2.743)" fill={defaultView === 'list' ? "#ffffff" : "#afb2b3"} />
                                    <circle id="Ellipse_88" data-name="Ellipse 88" cx="1" cy="1" r="1" transform="translate(2.268 4)" fill={defaultView === 'list' ? "#ffffff" : "#afb2b3"} />
                                    <path id="Path_237" data-name="Path 237" d="M21.71,14H11.3a1.3,1.3,0,1,0,0,2.6H21.71a1.3,1.3,0,0,0,0-2.6Z" transform="translate(-2.743 -3.495)" fill={defaultView === 'list' ? "#ffffff" : "#afb2b3"} />
                                    <circle id="Ellipse_89" data-name="Ellipse 89" cx="1" cy="1" r="1" transform="translate(2.268 11)" fill={defaultView === 'list' ? "#ffffff" : "#afb2b3"} />
                                    <path id="Path_238" data-name="Path 238" d="M21.71,24H11.3a1.3,1.3,0,1,0,0,2.6H21.71a1.3,1.3,0,0,0,0-2.6Z" transform="translate(-2.743 -6.989)" fill={defaultView === 'list' ? "#ffffff" : "#afb2b3"} />
                                    <ellipse id="Ellipse_90" data-name="Ellipse 90" cx="1" cy="1.5" rx="1" ry="1.5" transform="translate(2.268 17)" fill={defaultView === 'list' ? "#ffffff" : "#afb2b3"} />
                                </g>
                            </svg>
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            {
                !loading ?
                    <Grid>
                        {defaultView === 'list' ? <WorkbookItemList items={items} onSelectDataset={onSelectDataset} isChecked={isChecked} selectView={redirectDataset} /> : <WorkbookItemGrid items={items} deleteView={onDeleteDatasets} selectView={redirectDataset} />}
                    </Grid>
                    : <Loader />
            }

            <AlertDialog title="Delete Dataset"
                message={`Are you sure you want to delete the selected datasets?`}
                okButtonText="OK"
                cancelButtonText="Cancel"
                show={Boolean(selectedItems)}
                onClickOK={() => deleteDatasets()}
                onClickCancel={() => setSelectedItems(null)} />
        </Grid>
    );
};

WorkbookItems.propTypes = {
    classes: PropTypes.object,
    datasourceId: PropTypes.number,
    workbookId: PropTypes.string,
    theme: PropTypes.object,
    history: PropTypes.object,
    datasource: PropTypes.object,
    workbook: PropTypes.object
};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(memo(WorkbookItems));