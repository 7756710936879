import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Button, Card, CardActionArea } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import { getDatasourceIcons } from '../../helpers/appHelpers.js';
import IconSelectorStyles from './IconSelectorStyles.jsx';


const DatasourceIconSelector = (props) => {
    const { classes, open, icon, onSave, onCancel } = props;
    const [datasourceIcons, setDatasourceIcons] = useState([...getDatasourceIcons()]);
    const [selectedIcon, setSelectedIcon] = useState(null);

    const onSelectIcon = useCallback((selectedIcon) => {
        for (const datasourceIcon of datasourceIcons) {
            datasourceIcon.isSelected = (selectedIcon.iconName.toLowerCase() === datasourceIcon.iconName.toLowerCase());
        }
        setDatasourceIcons([...datasourceIcons]);
        setSelectedIcon({ ...selectedIcon });
    }, [datasourceIcons]);

    const loadSelectedIcon = useCallback((selectedIcon) => {
        for (const datasourceIcon of datasourceIcons) {
            datasourceIcon.isSelected = (selectedIcon.iconName.toLowerCase() === datasourceIcon.iconName.toLowerCase());
        }
    }, [datasourceIcons]);

    useEffect(() => {
        if (icon) {
            loadSelectedIcon(icon);
        }
    }, [icon, loadSelectedIcon]);


    return (
        <Dialog open={open} onClose={() => onCancel()}>
            <DialogTitle>
                {'Select Icon'}
            </DialogTitle>
            <DialogContent>
                <Grid container direction="row">
                    {
                        datasourceIcons && datasourceIcons.map((datasourceIcon, index) => {
                            return (
                                <Grid item key={`datasource_icon${index}`} align="center">
                                    <Card onClick={() => onSelectIcon(datasourceIcon)}>
                                        <CardActionArea className={classNames(classes.iconContainer, datasourceIcon.isSelected ? classes.SelectediconContainer : '')}>
                                            {/* <CardActionArea className={`${classes.iconContainer} ${datasourceIcon.isSelected ? classes.SelectediconContainer : classes.SelectediconContainer}`}> */}
                                            <img id={datasourceIcon.isSelected ? "selectedImg" : ""} src={require(`../../assets/images/sourceIcons/${datasourceIcon.iconName}.png`).default} alt={datasourceIcon.iconName} />
                                            {/* {datasourceIcon.isSelected && <Grid className={classes.selectedIcon} />} */}
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            );
                        })
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onSave(selectedIcon)} variant="contained" color="primary">
                    {'Save'}
                </Button>
                <Button className={classes.cancelBtn}
                    onClick={() => onCancel()}
                    autoFocus
                    variant="contained">
                    {'Cancel'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

DatasourceIconSelector.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    icon: PropTypes.object,
    onSave: PropTypes.func,
    onCancel: PropTypes.func
};

export default withStyles((theme) => ({
    ...IconSelectorStyles(theme),
    ...Styles(theme)
}))(DatasourceIconSelector);