const DatasourceOverviewStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    flexContain: {
		display: "flex",
		marginRight: 25,
		alignItems: 'center',
		maxWidth: '100%'
	},
    widthCon: {
		width: '100%'
	},
    highlight_source: {
		fontWeight: 'bold',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	}
});
export default DatasourceOverviewStyles;