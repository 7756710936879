import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import { alertActions } from '../constants/actionTypes/alertActionTypes';
import * as apiService from '../services/apiService';
import { settingActions } from '../constants/actionTypes/settingActionTypes';
import FileSaver from 'file-saver';
import fileDownload from 'js-file-download';
import { getCancellationToken } from '../helpers/appHelpers';

export const getProfile = () => {
    return (dispatch) => {
        dispatch(request(settingActions.GET_PROFILE_REQUEST));
        return apiService.getRequest(appConstants.END_POINTS.authenticate.getProfile).then((response) => {
            dispatch(success(settingActions.GET_PROFILE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_PROFILE_FAILURE, error));
        });
    };
};

export const updateProfile = (requestParams, userId) => {
    const endpoint = `${appConstants.END_POINTS.authenticate.updateProfile}/${userId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_PROFILE_REQUEST));
        return apiService.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_PROFILE_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Profile Updated Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_PROFILE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getOrganizations = () => {
    return (dispatch) => {
        dispatch(request(settingActions.GET_ORGANIZATONS_REQUEST));
        return apiService.getRequest(appConstants.END_POINTS.setting.getOrganizations).then((response) => {
            dispatch(success(settingActions.GET_ORGANIZATONS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_ORGANIZATONS_FAILURE, error));
        });
    };
};

export const createOrganization = (requestParams) => {
    return (dispatch) => {
        dispatch(request(settingActions.CREATE_ORGANIZATION_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.setting.createOrganization, requestParams).then((response) => {
            dispatch(success(settingActions.CREATE_ORGANIZATION_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Organization Created Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.CREATE_ORGANIZATION_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getOrganizationDetail = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getOrganizationDetail}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_ORGANIZATION_DETAIL_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_ORGANIZATION_DETAIL_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_ORGANIZATION_DETAIL_FAILURE, error));
        });
    };
};

export const getRoles = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getRoles}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_ROLES_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_ROLES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_ROLES_FAILURE, error));
        });
    };
};

export const deleteUser = (userId) => {
    const endpoint = `${appConstants.END_POINTS.authenticate.deleteUser}/${userId}`;
    return (dispatch) => {
        dispatch(request(settingActions.DELETE_USER_REQUEST));
        return apiService.deleteRequest(endpoint).then((response) => {
            dispatch(success(settingActions.DELETE_USER_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "User Deleted Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.DELETE_USER_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const updateUserProfile = (requestParams) => {
    return (dispatch) => {
        dispatch(request(settingActions.UPLOAD_USER_PROFILE_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.setting.uploadUserProfile, requestParams, true).then((response) => {
            dispatch(success(settingActions.UPLOAD_USER_PROFILE_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "User Profile Updated Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPLOAD_USER_PROFILE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getReferenceLibrary = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getReferenceLibrary}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_REFERENCE_LIBRARY_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_REFERENCE_LIBRARY_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_REFERENCE_LIBRARY_FAILURE, error));
        });
    };
};

export const getEnrichmentLibrary = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getEnrichmentLibrary}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_ENRICHMENT_LIBRARY_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_ENRICHMENT_LIBRARY_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_ENRICHMENT_LIBRARY_FAILURE, error));
        });
    };
};

export const updateEnrichmentLibrary = (requestParams, organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateEnrichmentLibrary}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_ENRICHMENT_LIBRARY_REQUEST));
        return apiService.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_ENRICHMENT_LIBRARY_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Library Updated Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_ENRICHMENT_LIBRARY_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const updateReferenceLibrary = (requestParams, organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateReferenceLibrary}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_REFERENCE_LIBRARY_REQUEST));
        return apiService.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_REFERENCE_LIBRARY_SUCCESS, response.data));
            if (!requestParams.is_create) {
                dispatch(success(alertActions.ALERT_SUCCESS, "Library Updated Successfully"));
            }
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_REFERENCE_LIBRARY_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const uploadEnrichmentLibrary = (requestParams, organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.uploadEnrichmentLibrary}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPLOAD_ENRICHMENT_LIBARARY_REQUEST));
        return apiService.postRequest(endpoint, requestParams, true).then((response) => {
            dispatch(success(settingActions.UPLOAD_ENRICHMENT_LIBARARY_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Library file uploded Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPLOAD_ENRICHMENT_LIBARARY_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const uploadReferenceLibrary = (requestParams, organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.uploadReferenceLibrary}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPLOAD_REFERENCE_LIBARARY_REQUEST));
        return apiService.postRequest(endpoint, requestParams, true).then((response) => {
            dispatch(success(settingActions.UPLOAD_REFERENCE_LIBARARY_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Library file uploded Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPLOAD_REFERENCE_LIBARARY_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const deleteEnrichmentLibrary = (id, organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.deleteEnrichmentLibrary}/${id}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.DELETE_ENRICHMENT_LIBARARY_REQUEST));
        return apiService.deleteRequest(endpoint).then((response) => {
            dispatch(success(settingActions.DELETE_ENRICHMENT_LIBARARY_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Library deleted Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.DELETE_ENRICHMENT_LIBARARY_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const deleteReferenceLibrary = (id, organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.deleteReferenceLibrary}/${id}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.DELETE_REFERENCE_LIBARARY_REQUEST));
        return apiService.deleteRequest(endpoint).then((response) => {
            dispatch(success(settingActions.DELETE_REFERENCE_LIBARARY_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Library deleted Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.DELETE_REFERENCE_LIBARARY_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const updateOrganization = (requestParams, organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateOrganization}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_ORGANIZATION_REQUEST));
        return apiService.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_ORGANIZATION_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Organization Updated Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_ORGANIZATION_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };

};
export const deleteOrganization = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.deleteOrganization}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.DELETE_ORGANIZATION_REQUEST));
        return apiService.deleteRequest(endpoint).then((response) => {
            dispatch(success(settingActions.DELETE_ORGANIZATION_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Organization Deleted Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.DELETE_ORGANIZATION_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getTheme = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getTheme}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_THEME_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            localStorage.setItem('theme', JSON.stringify(response.data));
            dispatch(success(settingActions.GET_THEME_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_THEME_FAILURE, error));
        });
    };
};

export const updateTheme = (requestParams, organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateTheme}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_THEME_REQUEST));
        return apiService.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_THEME_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Theme Updated Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_THEME_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const uploadThemeFile = (requestParams, organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.uploadThemeFile}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPLOAD_THEME_FILE_REQUEST));
        return apiService.postRequest(endpoint, requestParams, true).then((response) => {
            dispatch(success(settingActions.UPLOAD_THEME_FILE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPLOAD_THEME_FILE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, "Failed To Upload Logo"));
        });
    };
};

export const setThemeUI = (data) => {
    return {
        type: settingActions.SET_THEME,
        responseData: data
    };
};

export const getModuleConfig = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getModuleConfig}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_MODULE_CONFIG_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_MODULE_CONFIG_SUCCESS, response.data));
            return response;
        }, (error) => {
            dispatch(failure(settingActions.GET_MODULE_CONFIG_FAILURE, error));
        });
    };
};

export const updateModuleConfig = (requestParams, organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateModuleConfig}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_MODULE_CONFIG_REQUEST));
        return apiService.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_MODULE_CONFIG_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Module Updated Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_MODULE_CONFIG_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getStorage = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getStorage}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_STORAGE_CONFIG_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_STORAGE_CONFIG_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_STORAGE_CONFIG_FAILURE, error));
        });
    };
};

export const updateStorage = (requestParams, organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateStorage}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_STORAGE_CONFIG_REQUEST));
        return apiService.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_STORAGE_CONFIG_SUCCESS, requestParams));
            dispatch(success(alertActions.ALERT_SUCCESS, "Storage Updated Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_STORAGE_CONFIG_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getOrganizationConfig = () => {
    const cancellationToken = getCancellationToken();
    const endpoint = `${appConstants.END_POINTS.setting.getOrganizationConfig}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_ORGANIZATION_CONFIG_REQUEST));
        return apiService.getRequest(endpoint, cancellationToken).then((response) => {
            dispatch(success(settingActions.GET_ORGANIZATION_CONFIG_SUCCESS, response.data));
            localStorage.setItem('config', JSON.stringify(response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_ORGANIZATION_CONFIG_FAILURE, error));
        });
    };
};


export const LoadDefaultConfig = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.loadDefaultConfig}`;
    return (dispatch) => {
        dispatch(request(settingActions.LOAD_DEFAULT_CONFIG_REQUEST));
        return apiService.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.LOAD_DEFAULT_CONFIG_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.LOAD_DEFAULT_CONFIG_FAILURE, error));
        });
    };
};

export const getProperties = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getProperties}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_PROPERTIES_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_PROPERTIES_SUCCESS, response.data));
            dispatch(success(settingActions.SET_PROPERTIES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_PROPERTIES_FAILURE, error));
        });
    };
};

export const getPropertiesOrganization = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getPropertiesOrganization}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_PROPERTIES_ORGANIZATION_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_PROPERTIES_ORGANIZATION_SUCCESS, response.data));
            dispatch(success(settingActions.SET_PROPERTIES_ORGANIZATION_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_PROPERTIES_ORGANIZATION_FAILURE, error));
        });
    };
};

export const getDriftProperties = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getDriftProperties}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_DRIFT_PROPERTIES_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_DRIFT_PROPERTIES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_DRIFT_PROPERTIES_FAILURE, error));
        });
    };
};

export const getLicenses = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getLicenses}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_LICENSE_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_LICENSE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_LICENSE_FAILURE, error));
        });
    };
};

export const validateLicense = (organizationId, properties) => {
    const endpoint = `${appConstants.END_POINTS.setting.validateLicense}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.VALIDATE_LICENSE_REQUEST));
        return apiService.postRequest(endpoint, properties).then((response) => {
            dispatch(success(settingActions.VALIDATE_LICENSE_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Updated Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.VALIDATE_LICENSE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, "License Key is Invalid or Expired Please Contact Your Admin for Info."));
        });
    };
};

export const getSSODomainDetails = () => {
    const endpoint = `${appConstants.END_POINTS.setting.getSSODomainDetails}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_SSODOMAIN_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_SSODOMAIN_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_SSODOMAIN_FAILURE, error));
        });
    };
};

export const getVersionDetails = () => {
    const endpoint = `${appConstants.END_POINTS.setting.getVersionDetails}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_VERSION_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_VERSION_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_VERSION_FAILURE, error));
        });
    };
};

export const getDnsDetails = () => {
    const endpoint = `${appConstants.END_POINTS.setting.getDnsDetails}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_DNS_DETAILS_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_DNS_DETAILS_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(settingActions.GET_DNS_DETAILS_FAILURE, error));
        });
    };
};

export const updateProperties = (organizationId, properties) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateProperties}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_PROPERTIES_REQUEST));
        return apiService.putRequest(endpoint, properties).then((response) => {
            dispatch(success(settingActions.UPDATE_PROPERTIES_SUCCESS, response.data));
            dispatch(success(settingActions.SET_PROPERTIES_SUCCESS, properties));
            dispatch(success(alertActions.ALERT_SUCCESS, "Properties Updated Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_PROPERTIES_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getEnrichments = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getEnrichments}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_ENRICHMENTS_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_ENRICHMENTS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_ENRICHMENTS_FAILURE, error));
        });
    };
};

export const createEnrichment = (requestParams) => {
    return (dispatch) => {
        dispatch(request(settingActions.CREATE_ENRICHMENT_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.setting.createEnrichment, requestParams).then((response) => {
            dispatch(success(settingActions.CREATE_ENRICHMENT_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Enrichment Created Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.CREATE_ENRICHMENT_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getEnrichmentDetail = (enrichmentId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getEnrichmentDetail}/${enrichmentId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_ENRICHMENT_DETAIL_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_ENRICHMENT_DETAIL_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_ENRICHMENT_DETAIL_FAILURE, error));
        });
    };
};

export const updateEnrichment = (requestParams, enrichmentId) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateEnrichment}/${enrichmentId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_ENRICHMENT_REQUEST));
        return apiService.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_ENRICHMENT_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Enrichment Updated Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_ENRICHMENT_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };

};

export const deleteEnrichment = (enrichmentId) => {
    const endpoint = `${appConstants.END_POINTS.setting.deleteEnrichment}/${enrichmentId}`;
    return (dispatch) => {
        dispatch(request(settingActions.DELETE_ENRICHMENT_REQUEST));
        return apiService.deleteRequest(endpoint).then((response) => {
            dispatch(success(settingActions.DELETE_ENRICHMENT_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Enrichment Deleted Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.DELETE_ENRICHMENT_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const exportOrganizationReport = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.exportReport}`;
    return (dispatch) => {
        return apiService.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.EXPORT_REPORT, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Exported Successfully"));
            if (response.data) {
                const curateReport = JSON.stringify(response.data);
                const blob = new Blob([curateReport], { type: 'application/json' });
                FileSaver.saveAs(blob, `organization_report-${requestParams.organization}.json`);
            }
            return true;
        }, (error) => {
            dispatch(failure(settingActions.EXPORT_REPORT, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const importOrganizationReport = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.importReport}`;
    return (dispatch) => {
        return apiService.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.IMPORT_REPORT, response));
            dispatch(success(alertActions.ALERT_SUCCESS, "Imported Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.IMPORT_REPORT, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

/**
 * @name getAllFields
 * @description Get All Field Details By Organization Id
 * @param {*} organizationId
 * @author Ashok
 */
export const getAllFields = (organizationId, sortBy) => {
    organizationId = organizationId ? organizationId : 0;
    const endpoint = `${appConstants.END_POINTS.setting.getFields}/${organizationId}/${sortBy}`;
    return (dispatch) => {
        dispatch(request(settingActions.GETALL_ORGANIZATION_FIELD_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GETALL_ORGANIZATION_FIELD_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GETALL_ORGANIZATION_FIELD_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

/**
 * @name getAllFieldsByLevel
 * @description Get All Field Details By Organization Id and Level
 * @param {*} organizationId
 * @author level
 */
export const getAllFieldsByLevel = (level) => {
    const endpoint = `${appConstants.END_POINTS.setting.getFieldsByLevel}/${level}`;
    return (dispatch) => {
        dispatch(request(settingActions.GETALL_ORGANIZATION_FIELD_BY_LEVEL_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GETALL_ORGANIZATION_FIELD_BY_LEVEL_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GETALL_ORGANIZATION_FIELD_BY_LEVEL_FAILURE, error));
        });
    };
};

/**
 * @name createField
 * @description Create New Field For Orgainzation
 * @param {*} field
 * @author Ashok
 */
export const createField = (field) => {
    return (dispatch) => {
        dispatch(request(settingActions.CREATE_ORGANIZATION_FIELD_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.setting.createField, field).then((response) => {
            dispatch(success(settingActions.CREATE_ORGANIZATION_FIELD_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.CREATE_ORGANIZATION_FIELD_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
            throw error;
        });
    };
};

/**
 * @name updateField
 * @description Update Field For Organization
 * @param {*} field
 * @author Ashok
 */
export const updateField = (field) => {
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_ORGANIZATION_FIELD_REQUEST));
        return apiService.putRequest(appConstants.END_POINTS.setting.updateField, field).then((response) => {
            dispatch(success(settingActions.UPDATE_ORGANIZATION_FIELD_SUCCESS, response.status));
            return response.status;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_ORGANIZATION_FIELD_FAILURE, error));
        });
    };
};

/**
 * @name updateGroup
 * @description Update Group For Organization
 * @param {*} field
 * @author Ashok
 */
export const updateGroup = (group_name) => {
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_ORGANIZATION_GROUP_REQUEST));
        return apiService.putRequest(appConstants.END_POINTS.setting.updateGroup, group_name).then((response) => {
            dispatch(success(settingActions.UPDATE_ORGANIZATION_GROUP_SUCCESS, response.status));
            return response.status;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_ORGANIZATION_GROUP_FAILURE, error));
        });
    };
};

/**
 * @name updateGroup
 * @description Update Group Order For Organization
 * @param {*} field
 * @author Ashok
 */
export const updateGroupOrder = (group) => {
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_ORGANIZATION_GROUP_ORDER_REQUEST));
        return apiService.putRequest(appConstants.END_POINTS.setting.updateGroupOrder, group).then((response) => {
            dispatch(success(settingActions.UPDATE_ORGANIZATION_GROUP_ORDER_SUCCESS, response.status));
            return response.status;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_ORGANIZATION_GROUP_ORDER_FAILURE, error));
        });
    };
};

/**
 * @name deleteField
 * @description Delete Field by FieldId For Orgainzation
 * @param {*} fieldId
 * @author Ashok
 */
export const deleteField = (fieldId) => {
    fieldId = fieldId ? fieldId : 0;
    const endpoint = `${appConstants.END_POINTS.setting.deleteField}/${fieldId}`;
    return (dispatch) => {
        dispatch(request(settingActions.DELETE_ORGANIZATION_FIELD_REQUEST));
        return apiService.deleteRequest(endpoint).then((response) => {
            dispatch(success(settingActions.DELETE_ORGANIZATION_FIELD_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.DELETE_ORGANIZATION_FIELD_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
            throw error;
        });
    };
};


export const getOrganizationNotificationConfig = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getOrganizationNotificationConfig}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_ORGANIZATION_NOTIFICATION_CONFIG_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_ORGANIZATION_NOTIFICATION_CONFIG_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_ORGANIZATION_NOTIFICATION_CONFIG_FAILURE, error));
        });
    };
};

export const updateOrganizationNotificationConfig = (organizationId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateOrganizationNotificationConfig}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_ORGANIZATION_NOTIFICATION_CONFIG_REQUEST));
        return apiService.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_ORGANIZATION_NOTIFICATION_CONFIG_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_ORGANIZATION_NOTIFICATION_CONFIG_FAILURE, error));
        });
    };
};


export const generateOrganizationToken = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.generateOrganizationToken}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GENERATE_ORGANIZATION_TOKEN_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GENERATE_ORGANIZATION_TOKEN_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GENERATE_ORGANIZATION_TOKEN_FAILURE, error));
        });
    };
};


export const revokeOrganizationToken = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.revokeOrganizationToken}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.REVOKE_ORGANIZATION_TOKEN_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.REVOKE_ORGANIZATION_TOKEN_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.REVOKE_ORGANIZATION_TOKEN_FAILURE, error));
        });
    };
};

export const getOrganizationToken = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getOrganizationToken}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_ORGANIZATION_TOKEN_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_ORGANIZATION_TOKEN_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_ORGANIZATION_TOKEN_FAILURE, error));
        });
    };
};


export const createGlossaryList = (requestParams) => {
    return (dispatch) => {
        dispatch(request(settingActions.CREATE_GLOSSARY_LIST_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.setting.createGlossaryList, requestParams).then((response) => {
            dispatch(success(settingActions.CREATE_GLOSSARY_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.CREATE_GLOSSARY_LIST_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getGlossaryList = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getGlossaryList}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_GLOSSARY_LIST_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_GLOSSARY_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_GLOSSARY_LIST_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};


export const getGlossaryById = (glossaryId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getGlossaryById}/${glossaryId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_GLOSSARY_LIST_BY_ID_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_GLOSSARY_LIST_BY_ID_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_GLOSSARY_LIST_BY_ID_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const updateGlossaryList = (glossaryId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateGlossaryList}/${glossaryId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_GLOSSARY_LIST_REQUEST));
        return apiService.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_GLOSSARY_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_GLOSSARY_LIST_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};


export const deleteGlossaryList = (glossaryId) => {
    const endpoint = `${appConstants.END_POINTS.setting.deleteGlossaryList}/${glossaryId}`;
    return (dispatch) => {
        dispatch(request(settingActions.DELETE_GLOSSARY_LIST_REQUEST));
        return apiService.deleteRequest(endpoint).then((response) => {
            dispatch(success(settingActions.DELETE_GLOSSARY_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.DELETE_GLOSSARY_LIST_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const linkAssets = (requestParams) => {
    return (dispatch) => {
        dispatch(request(settingActions.LINK_ASSET_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.setting.linkAssets, requestParams).then((response) => {
            dispatch(success(settingActions.LINK_ASSET_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.LINK_ASSET_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getLinkAssets = (glossaryId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getLinkAssets}/${glossaryId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_LINK_ASSET_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_LINK_ASSET_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_LINK_ASSET_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};
export const deleteLinkAsset = (assetId) => {
    const endpoint = `${appConstants.END_POINTS.setting.deleteLinkAsset}/${assetId}`;
    return (dispatch) => {
        dispatch(request(settingActions.DELETE_LINK_ASSET_REQUEST));
        return apiService.deleteRequest(endpoint).then((response) => {
            dispatch(success(settingActions.DELETE_LINK_ASSET_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.DELETE_LINK_ASSET_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};


export const getGlossaryTerm = () => {
    return (dispatch) => {
        dispatch(request(settingActions.GET_GLOSSARY_TERMS_REQUEST));
        return apiService.getRequest(appConstants.END_POINTS.setting.getGlossaryTerm).then((response) => {
            dispatch(success(settingActions.GET_GLOSSARY_TERMS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_GLOSSARY_TERMS_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getLogging = () => {
    return (dispatch) => {
        dispatch(request(settingActions.GET_LOGGING_REQUEST));
        return apiService.getRequest(appConstants.END_POINTS.setting.getLogging).then((response) => {
            dispatch(success(settingActions.GET_LOGGING_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_LOGGING_FAILURE, error));
        });
    };
};

export const getCurrentlyRunningJobs = () => {
    return (dispatch) => {
        dispatch(request(settingActions.GET_RUNNINGJOBS_REQUEST));
        return apiService.getRequest(appConstants.END_POINTS.setting.getCurrentRunningJobs).then((response) => {
            dispatch(success(settingActions.GET_RUNNINGJOBS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_RUNNINGJOBS_FAILURE, error));
        });
    };
};

export const getCurrentlyLivyStatementJobs = () => {
    return (dispatch) => {
        dispatch(request(settingActions.GET_RUNNING_LIVYJOBS_REQUEST));
        return apiService.getRequest(appConstants.END_POINTS.setting.getCurrentlivyJobs).then((response) => {
            dispatch(success(settingActions.GET_RUNNING_LIVYJOBS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_RUNNING_LIVYJOBS_FAILURE, error));
        });
    };
};

export const deleteStatementJobs = (jobid) => {
    return (dispatch) => {
        const endpoint = `${appConstants.END_POINTS.setting.killstatement}/${jobid}`;
        dispatch(request(settingActions.GET_KILL_LIVYSTATEMENT_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_KILL_LIVYSTATEMENT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_KILL_LIVYSTATEMENT_FAILURE, error));
        });
    };
};

export const deleteBatchsJobs = (jobid) => {
    return (dispatch) => {
        const endpoint = `${appConstants.END_POINTS.setting.killbatch}/${jobid}`;
        dispatch(request(settingActions.GET_KILL_LIVYBATCH_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_KILL_LIVYBATCH_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_KILL_LIVYBATCH_FAILURE, error));
        });
    };
};

export const deleteSessionJobs = () => {
    return (dispatch) => {
        dispatch(request(settingActions.GET_KILL_LIVYSESSION_REQUEST));
        return apiService.getRequest(appConstants.END_POINTS.setting.killsession).then((response) => {
            dispatch(success(settingActions.GET_KILL_LIVYSESSION_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_KILL_LIVYSESSION_FAILURE, error));
        });
    };
};

export const getCurrentlyScheduleList = () => {
    return (dispatch) => {
        dispatch(request(settingActions.GET_SCHEDULE_LIST_REQUEST));
        return apiService.getRequest(appConstants.END_POINTS.setting.getScheduleDatasets).then((response) => {
            dispatch(success(settingActions.GET_SCHEDULE_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_SCHEDULE_LIST_FAILURE, error));
        });
    };
};

export const getCustomTabs = (organizationId, type) => {
    const endpoint = `${appConstants.END_POINTS.setting.getCustomTabs}/${organizationId}/${type}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_CUSTOM_TABS_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_CUSTOM_TABS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_CUSTOM_TABS_FAILURE, error));
        });
    };
};

export const getCustomGroups = (organizationId, type) => {
    const endpoint = `${appConstants.END_POINTS.setting.getCustomGroups}/${organizationId}/${type}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_CUSTOM_GROUPS_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_CUSTOM_GROUPS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_CUSTOM_GROUPS_FAILURE, error));
        });
    };
};


export const getRoleList = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getRoleList}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_ROLES_LIST_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_ROLES_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_ROLES_LIST_FAILURE, error));
        });
    };
};

export const getSuperOrganization = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getIsSuperOrganization}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_ISSUPER_ORGANIZATION_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_ISSUPER_ORGANIZATION_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_ISSUPER_ORGANIZATION_FAILURE, error));
        });
    };
};


export const getRoleConfiguration = (roleId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getRoleConfiguration}/${roleId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_ROLES_CONFIGURATION_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_ROLES_CONFIGURATION_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_ROLES_CONFIGURATION_FAILURE, error));
        });
    };
};

export const editRole = (roleId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.editRole}/${roleId}`;
    return (dispatch) => {
        dispatch(request(settingActions.EDIT_ROLE_REQUEST));
        return apiService.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.EDIT_ROLE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.EDIT_ROLE_FAILURE, error));
        });
    };
};


export const createRole = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.createRole}`;
    return (dispatch) => {
        dispatch(request(settingActions.CREATE_ROLE_REQUEST));
        return apiService.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.CREATE_ROLE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.CREATE_ROLE_FAILURE, error));
        });
    };
};


export const deleteRole = (roleId) => {
    const endpoint = `${appConstants.END_POINTS.setting.deleteRole}/${roleId}`;
    return (dispatch) => {
        dispatch(request(settingActions.DELETE_ROLE_REQUEST));
        return apiService.deleteRequest(endpoint).then((response) => {
            dispatch(success(settingActions.DELETE_ROLE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.DELETE_ROLE_FAILURE, error));
        });
    };
};

export const getSystemLicenseStatus = () => {
    return (dispatch) => {
        dispatch(request(settingActions.GET_SYSTEM_LICENSE_REQUEST));
        return apiService.getRequest(appConstants.END_POINTS.setting.getSystemLicenseStatus).then((response) => {
            dispatch(success(settingActions.GET_SYSTEM_LICENSE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_SYSTEM_LICENSE_FAILURE, error));
        });
    };
};


export const getLicenseModules = (organizationId) => {
    const cancellationToken = getCancellationToken();
    const endpoint = `${appConstants.END_POINTS.setting.getLicenseModules}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_LICENSE_MODULES_REQUEST));
        return apiService.getRequest(endpoint, cancellationToken).then((response) => {
            dispatch(success(settingActions.GET_LICENSE_MODULES_SUCCESS, response.data));
            localStorage.setItem('licenseConfig', JSON.stringify(response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_LICENSE_MODULES_FAILURE, error));
        });
    };
};


export const updateLicenseConfiguration = (organizationId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateLicenseConfiguration}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_LICENSE_CONFIG_REQUEST));
        return apiService.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_LICENSE_CONFIG_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_LICENSE_CONFIG_FAILURE, error));
        });
    };
};

export const updateConnectorConfig = (organizationId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateConnectorConfig}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_CONNECTOR_CONFIG_REQUEST));
        return apiService.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_CONNECTOR_CONFIG_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_CONNECTOR_CONFIG_FAILURE, error));
        });
    };
};

export const getUpdateLicenseConnectionStatus = () => {
    const cancellationToken = getCancellationToken();
    return (dispatch) => {
        dispatch(request(settingActions.GET_UPDATE_LICENSE_CONNECTION_STATUS_REQUEST));
        return apiService.getRequest(appConstants.END_POINTS.setting.getUpdateLicenseConnectionStatus, cancellationToken).then((response) => {
            dispatch(success(settingActions.GET_UPDATE_LICENSE_CONNECTION_STATUS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_UPDATE_LICENSE_CONNECTION_STATUS_FAILURE, error));
        });
    };
};

export const updateLicenseConnectionStatus = () => {
    return {
        type: settingActions.GET_UPDATE_LICENSE_CONNECTION_STATUS_SUCCESS,
        responseData: true
    };
};

export const resetConfig = () => {
    localStorage.setItem('config', JSON.stringify({}));
    localStorage.setItem('licenseConfig', JSON.stringify({}));
    return {
        type: settingActions.RESET_CONFIG,
        responseData: null
    };
};

export const resetOrganizationConfig = () => {
    localStorage.setItem('config', JSON.stringify({}));
    return {
        type: settingActions.RESET_ORGANIZATION_CONFIG,
        responseData: null
    };
};

/**
 * @name getCustomFields
 * @description Get All Field Details By Organization Id and Level
 * @param {*} organizationId
 * @author Srinath
 * Modified by Naveen
 */
export const getCustomFields = (level) => {
    const endpoint = `${appConstants.END_POINTS.setting.getCustomFields}/${level}`;
    return (dispatch) => {
        dispatch(request(settingActions.GETALL_ORGANIZATION_FIELD_BY_LEVEL_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GETALL_ORGANIZATION_FIELD_BY_LEVEL_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GETALL_ORGANIZATION_FIELD_BY_LEVEL_FAILURE, error));
        });
    };
};

/**
 * @name getCustomFields
 * @description Get All Field Details By Organization Id and Level
 * @param {*} organizationId
 * @author Srinath
 * Modified by Naveen
 */
export const getCustomFieldsByOrg = (level) => {
    const endpoint = `${appConstants.END_POINTS.setting.getCustomFieldsByOrg}/${level}`;
    return (dispatch) => {
        dispatch(request(settingActions.GETALL_ORGANIZATION_FIELD_BY_LEVEL_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GETALL_ORGANIZATION_FIELD_BY_LEVEL_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GETALL_ORGANIZATION_FIELD_BY_LEVEL_FAILURE, error));
        });
    };
};

export const getExportMetadata = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.exportMetadata}`;
    return (dispatch) => {
        dispatch(request(settingActions.EXPORT_DATA_REQUEST));
        return apiService.postRequest(endpoint, requestParams)
            .then((response) => {
                dispatch(success(settingActions.EXPORT_DATA_SUCCESS, response));
                if (response) {
                    fileDownload(response, 'export_metadata.xlsx');
                }
                return response ? 'Successfully Exported' : '';
            }, (error) => {
                dispatch(failure(settingActions.EXPORT_DATA_FAILURE, error));
            });
    };
};

export const getExportRules = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.exportRules}`;
    return (dispatch) => {
        dispatch(request(settingActions.EXPORT_DATA_REQUEST));
        return apiService.postRequest(endpoint, requestParams)
            .then((response) => {
                dispatch(success(settingActions.EXPORT_DATA_SUCCESS, response));
                if (response) {
                    fileDownload(response, 'export_rules.xlsx');
                }
                return response ? 'Successfully Exported' : '';
            }, (error) => {
                dispatch(failure(settingActions.EXPORT_DATA_FAILURE, error));
            });
    };
};

export const getLicenseInfo = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getLicenseInfo}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_LICENSE_INFO_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_LICENSE_INFO_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_LICENSE_INFO_FAILURE, error));
        });
    };
};

export const logoutuser = () => {
    const endpoint = `${appConstants.END_POINTS.setting.logoutuser}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_USER_LOGOUT_REQUEST));
        return apiService.postRequest(endpoint).then((response) => {
            dispatch(success(settingActions.UPDATE_USER_LOGOUT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_USER_LOGOUT_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getExportDQMetrics = () => {
    return (dispatch) => {
        dispatch(request(settingActions.GET_EXPORT_DQMETRICS_REQUEST));
        return apiService.getRequest(appConstants.END_POINTS.setting.getExportDQMetrics).then((response) => {
            dispatch(success(settingActions.GET_EXPORT_DQMETRICS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_EXPORT_DQMETRICS_FAILURE, error));
        });
    };
};

export const getSSODetails = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getSSODetais}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_SSO_DETAILS_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_SSO_DETAILS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_SSO_DETAILS_FAILURE, error));
        });
    };
};

export const getLDAPDetails = (organizationId) => {
    const endpoint = `${appConstants.END_POINTS.setting.getLDAPDetais}/${organizationId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_LDAP_DETAILS_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_LDAP_DETAILS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_LDAP_DETAILS_FAILURE, error));
        });
    };
};

export const updateExportDQMetrics = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateExportDQMetrics}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_EXPORT_DQMETRICS_REQUEST));
        return apiService.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(settingActions.UPDATE_EXPORT_DQMETRICS_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "DQ Metrics Updated Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPDATE_EXPORT_DQMETRICS_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };

};

export const updateSSODomain = (requestParams) => {
    return (dispatch) => {
        dispatch(request(settingActions.UPLOAD_SSO_DOMAIN_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.setting.updateSSODomain, requestParams).then((response) => {
            dispatch(success(settingActions.UPLOAD_SSO_DOMAIN_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPLOAD_SSO_DOMAIN_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const updateLDAPDomain = (requestParams) => {
    return (dispatch) => {
        dispatch(request(settingActions.UPLOAD_LDAP_DOMAIN_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.setting.updateLDAPDomain, requestParams).then((response) => {
            dispatch(success(settingActions.UPLOAD_LDAP_DOMAIN_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPLOAD_LDAP_DOMAIN_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const uploadSSOFiles = (requestParams) => {
    return (dispatch) => {
        dispatch(request(settingActions.UPLOAD_SSO_FILE_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.setting.uploadSSOFile, requestParams, true).then((response) => {
            dispatch(success(settingActions.UPLOAD_SSO_FILE_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, "Federation File Successfully Uploaded."));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.UPLOAD_SSO_FILE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const uploadSSOAlerts = (errorMsg) => {
    return (dispatch) => {
        dispatch(failure(alertActions.ALERT_ERROR, errorMsg));
    };
};

export const showUserCustomMsg = (errorMsg) => {
    return (dispatch) => {
        dispatch(success(alertActions.ALERT_SUCCESS, errorMsg));
    };
};

export const updateSSOPrivateKey = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateSSOPrivatekey}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_SSO_PRIVATE_KEY_REQUEST));
        return apiService.postRequest(endpoint, requestParams)
            .then((response) => {
                dispatch(success(settingActions.UPDATE_SSO_PRIVATE_KEY_SUCCESS, response));
                dispatch(success(alertActions.ALERT_SUCCESS, "Private Key Updated Successfully."));
            }, (error) => {
                dispatch(failure(settingActions.UPDATE_SSO_PRIVATE_KEY_FAILURE, error));
                dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
            });
    };
};

export const updateLDAPDetails = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.updateLDAPDetails}`;
    return (dispatch) => {
        dispatch(request(settingActions.UPDATE_LDAP_DETAILS_REQUEST));
        return apiService.postRequest(endpoint, requestParams)
            .then((response) => {
                dispatch(success(settingActions.UPDATE_LDAP_DETAILS_SUCCESS, response));
                dispatch(success(alertActions.ALERT_SUCCESS, "LDAP Details Updated Successfully."));
            }, (error) => {
                dispatch(failure(settingActions.UPDATE_LDAP_DETAILS_FAILURE, error));
                dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
            });
    };
};

export const getExportUsers = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.exportUsers}`;
    return (dispatch) => {
        dispatch(request(settingActions.EXPORT_DATA_REQUEST));
        return apiService.postRequest(endpoint, requestParams)
            .then((response) => {
                dispatch(success(settingActions.EXPORT_DATA_SUCCESS, response));
                if (response) {
                    fileDownload(response, 'export_users.xlsx');
                }
                return response ? 'Successfully Exported' : '';
            }, (error) => {
                dispatch(failure(settingActions.EXPORT_DATA_FAILURE, error));
            });
    };
};

export const getExportUsersActivity = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.exportUsersActivity}`;
    return (dispatch) => {
        dispatch(request(settingActions.USERACTIVITY_EXPORT_DATA_REQUEST));
        return apiService.postRequest(endpoint, requestParams)
            .then((response) => {
                dispatch(success(settingActions.USERACTIVITY_EXPORT_DATA_SUCCESS, response));
                if (response) {
                    fileDownload(response, 'export_users_activity.xlsx');
                }
                return response ? 'Successfully Exported' : '';
            }, (error) => {
                dispatch(failure(settingActions.USERACTIVITY_EXPORT_DATA_FAILURE, error));
            });
    };
};

export const getExportUsersActivityInfo = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.setting.exportUsersInfoActivity}`;
    return (dispatch) => {
        dispatch(request(settingActions.USERACTIVITYINFO_EXPORT_DATA_REQUEST));
        return apiService.postRequest(endpoint, requestParams)
            .then((response) => {
                dispatch(success(settingActions.USERACTIVITYINFO_EXPORT_DATA_SUCCESS, response));
                if (response) {
                    fileDownload(response, 'export_users_audit_activity.xlsx');
                }
                return response ? 'Successfully Exported' : '';
            }, (error) => {
                dispatch(failure(settingActions.USERACTIVITYINFO_EXPORT_DATA_FAILURE, error));
            });
    };
};

export const checkStewardUserStatus = (userId) => {
    const endpoint = `${appConstants.END_POINTS.setting.checkStewardUserStatus}/${userId}`;
    return (dispatch) => {
        dispatch(request(settingActions.GET_STEWARD_USER_STATUS_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(settingActions.GET_STEWARD_USER_STATUS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(settingActions.GET_STEWARD_USER_STATUS_FAILURE, error));
        });
    };
};