import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Card, Typography, Table, TableHead, TableBody, TableRow, TableCell, IconButton, Box, TableSortLabel } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import moment from 'moment-timezone';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import Styles from '../../layouts/Styles.jsx';
import Loader from '../Loaders/Loader.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import DatasetStyles from './DatasetStyles.jsx';
import { getRecentlyUsedQueries, getDatasourceCatalogTables, getDatasetCatalogTables, runMetadata, getDDLQuery } from '../../actions/catalogActions.js';
import PropertiesListViewStyles from './Properties/ListView/PropertiesListViewStyles.jsx';
import { appConstants } from '../../constants/appConstants.js';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import DatasourceStyles from '../Datasource/DatasourceStyles.jsx';
import { bytesToSize, copyToClipBoard, sortTable } from '../../helpers/appHelpers.js';


    const Prev = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#F38080">
                 <path d="M0 0h24v24H0V0z" fill="none" />
                 <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" />
                 </svg>;

    const next = <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#F38080">
                 <path d="M0 0h24v24H0V0z" fill="none" />
                 <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6-6-6z" />
                 </svg>;


const CatalogInfo = (props) => {
    const { classes, datasourceId, datasetId, type, theme } = props;
    const datasourceCatalogInfo = useSelector(({ datasource }) => datasource.datasourceCatalogInfo);
    const datasetCatalogInfo = useSelector(({ datasource }) => datasource.datasetCatalogInfo);
    const recentQueries = useSelector(({ datasource }) => datasource.recentQueries);
    const ddlQuery = useSelector(({ datasource }) => datasource.ddlQuery);
    const frequentTables = useSelector(({ datasource }) => datasource.frequentTables);
    const frequentAttributes = useSelector(({ datasource }) => datasource.frequentAttributes);
    const frequentRoles = useSelector(({ datasource }) => datasource.frequentRoles);
    const tableConstraints = useSelector(({ datasource }) => datasource.tableConstraints);
    const datasourceSchemaCatalog = useSelector(({ datasource }) => datasource.datasourceSchemaCatalog);

    const [queries, setQueries] = useState([]);
    const [tables, setTables] = useState([]);
    const [columns, setColumns] = useState([]);
    const [roles, setRoles] = useState([]);
    const [constraints, setConstraints] = useState([]);
    const [schemaDetails, setSchemaDeatails] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [isQueryLoading, setIsQueryLoading] = useState(true);
    const [isDataSourceLoading, setDataSourceLoading] = useState(false);
    const [isDataSetLoading, setDataSetLoading] = useState(false);
    const [columnCount, setColumnCount] = useState(0);
    const [rolesCount, setRolesCount] = useState(0);
    const [tablesCount, setTablesCount] = useState(0);
    const [schemaCount, setSchemaCount] = useState(0);
    const dispatch = useDispatch();


    // Datasource level
    useEffect(() => {
        if (frequentTables && frequentRoles && datasourceSchemaCatalog) {
            if (recentQueries && ddlQuery) {
                setQueries([...recentQueries.slice(0, 5)]);
                setIsQueryLoading(false);
            }
            setTables([...frequentTables]);
            setRoles([...frequentRoles]);
            setSchemaDeatails([...datasourceSchemaCatalog]);
            setIsLoading(false);
        }
    }, [frequentTables, frequentRoles, recentQueries, datasourceSchemaCatalog, ddlQuery]);

    // Dataset Level
    useEffect(() => {
        if (frequentAttributes && tableConstraints) {
            if (recentQueries && ddlQuery) {
                setQueries([...recentQueries.slice(0, 5)]);
                setIsQueryLoading(false);
            }
            setColumns([...frequentAttributes]);
            setConstraints([...tableConstraints]);
            setIsLoading(false);
        }
    }, [ddlQuery, frequentAttributes, recentQueries, tableConstraints]);

    const loadDatasourceDetails = useCallback((datasourceId) => {
        if ((frequentTables && frequentRoles && schemaDetails) || isDataSourceLoading) {
            return;
        }
        setDataSourceLoading(true);
        Promise.all([
            dispatch(getRecentlyUsedQueries(datasourceId)),
            dispatch(getDatasourceCatalogTables(datasourceId))
        ]).then((response) => {
            setIsQueryLoading(false);
            if (frequentTables && frequentRoles && schemaDetails) {
                setDataSourceLoading(false);
            }
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            setDataSourceLoading(false);
        });
    }, [dispatch, frequentTables, frequentRoles, schemaDetails, isDataSourceLoading]);

    const loadDatasetDetails = useCallback((datasourceId, datasetId) => {
        if ((frequentAttributes && recentQueries && tableConstraints) || isDataSetLoading) {
            return;
        }
        setDataSetLoading(true);
        Promise.all([
            dispatch(getRecentlyUsedQueries(datasourceId, datasetId)),
            dispatch(getDDLQuery(datasourceId, datasetId)),
            dispatch(getDatasetCatalogTables(datasetId))
        ]).then((response) => {
            setIsQueryLoading(false);
            if (frequentAttributes && recentQueries && tableConstraints) {
                setDataSetLoading(false);
            }
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            setDataSetLoading(false);
        });
    }, [dispatch, frequentAttributes, recentQueries, tableConstraints, isDataSetLoading]);

    useEffect(() => {
        if (type === 'datasource' && !datasourceId) {
            return;
        }
        if (type === 'dataset' && (!datasourceId || !datasetId)) {
            return;
        }
        if (type === 'datasource' && datasourceId) {
            loadDatasourceDetails(datasourceId);
        }
        if (type === 'dataset' && datasourceId && datasetId) {
            loadDatasetDetails(datasourceId, datasetId);
        }
    }, [datasetId, datasourceId, dispatch, loadDatasetDetails, loadDatasourceDetails, type]);

    const loadMoreQueries = (type) => {
        if (type === 'more') {
            setQueries((prev) => [...prev, ...recentQueries.slice(5, 10)]);
        } else {
            setQueries([...recentQueries.slice(0, 5)]);
        }

    };

    const rerun_catalog = (() => {
        dispatch(runMetadata(datasourceId));
    });

    const quickNav = (type, table) => {
        if (table === 'columns' && type === 'prev') {
            setColumnCount(columnCount - 10);
        } else if (table === 'columns' && type === 'next') {
            setColumnCount(columnCount + 10);
        } else if (table === 'roles' && type === 'prev') {
            setRolesCount(rolesCount - 10);
        } else if (table === 'roles' && type === 'next') {
            setRolesCount(rolesCount + 10);
        } else if (table === 'tables' && type === 'prev') {
            setTablesCount(tablesCount - 10);
        } else if (table === 'tables' && type === 'next') {
            setTablesCount(tablesCount + 10);
        } else if (table === 'schema' && type === 'prev') {
            setSchemaCount(schemaCount - 10);
        } else if (table === 'schema' && type === 'next') {
            setSchemaCount(schemaCount + 10);
        }
    };

    const SchemaTable = () => {

        const [orderBy, setOrderBy] = useState('roles_count');
        const [order, setOrder] = useState('desc');

        const onChangeOrder = useCallback((property) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }, [order, orderBy]);

        return (
            <Grid item className={classNames(classes.flex1, classes.cardbox, classes.marginTop15)}>
                <Card className={classNames('infoContainer')}>
                    <Grid container justify="space-between">
                        <Typography component="h5" variant="h5">
                            {"Schema Details"}
                        </Typography>
                    </Grid>
                    {
                        schemaDetails.length > 10 &&
                        <Box className={classes.navigator}>
                            <IconButton
                                className={classNames(classes.navButton, schemaCount === 0 ? classes.buttonDisable : "")}
                                onClick={schemaCount === 0 ? undefined : () => quickNav('prev', 'schema')}>
                                {Prev}
                            </IconButton>
                            <IconButton
                                className={classNames(classes.navButton, schemaCount + 10 >= schemaDetails.length ? classes.buttonDisable : "")}
                                onClick={schemaCount + 10 >= schemaDetails.length ? undefined : () => quickNav('next', 'schema')}>
                                {next}
                            </IconButton>
                        </Box>
                    }
                    {
                        (!isLoading && (type === 'datasource' && roles.length > 0)) &&
                        <Grid container justify="flex-start" alignItems="flex-start" className={`${classes.tableWrapperStyle} queryContainer`}>
                            <Table stickyHeader className={classNames(classes.designTable, classes.tableStyle)}>
                                <TableHead>
                                    <TableRow>

                                        {
                                            appConstants.catalogSchemaHeaders.map((header, index) => (
                                                <TableCell key={`frequent_role_header_${index}`} width="25%">
                                                    <Typography className={classes.infoTableHeader}>
                                                        {
                                                            header.sort === false ?
                                                                header.label :
                                                                <TableSortLabel
                                                                    active={orderBy === header.id}
                                                                    direction={orderBy === header.id ? order : 'asc'}
                                                                    onClick={() => onChangeOrder(header.id)}
                                                                >

                                                                    {header.label}
                                                                </TableSortLabel>
                                                        }
                                                    </Typography>
                                                </TableCell>
                                            ))
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        schemaDetails && sortTable(schemaDetails.slice(schemaCount, schemaCount + 10), order, orderBy).map((role, index) => {
                                            return (
                                                <TableRow key={`frequent_role_row_${index}`}>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {role.schema_name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {role.schema_comment}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {role.roles_count}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {role.roles}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    }
                    {(!isLoading && (type === 'datasource' && roles.length <= 0)) && <NoResultFound />}
                </Card>
            </Grid>
        );
    };

    const UserTable = () => {

        const [orderBy, setOrderBy] = useState('assigned_to_users_count');
        const [order, setOrder] = useState('desc');

        const onChangeOrder = useCallback((property) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }, [order, orderBy]);

        return (
            <Grid item className={classNames(classes.flex1, classes.cardbox, classes.marginBottom15)}>
                <Card className={classNames('infoContainer')}>
                    <Grid container justify="space-between">
                        <Typography component="h5" variant="h5">
                            {"Users & Roles"}
                        </Typography>
                    </Grid>
                    {
                        roles.length > 10 &&
                        <Box className={classes.navigator}>
                            <IconButton
                                className={classNames(classes.navButton, rolesCount === 0 ? classes.buttonDisable : "")}
                                onClick={rolesCount === 0 ? undefined : () => quickNav('prev', 'roles')}>
                                {Prev}
                            </IconButton>
                            <IconButton
                                className={classNames(classes.navButton, rolesCount + 10 >= roles.length ? classes.buttonDisable : "")}
                                onClick={rolesCount + 10 >= roles.length ? undefined : () => quickNav('next', 'roles')}>
                                {next}
                            </IconButton>
                        </Box>
                    }
                    {
                        (!isLoading && (type === 'datasource' && roles.length > 0)) &&
                        <Grid container justify="flex-start" alignItems="flex-start" className={`${classes.tableWrapperStyle} queryContainer`}>
                            <Table stickyHeader className={classNames(classes.tableStyle, classes.designTable)}>
                                <TableHead>
                                    <TableRow>
                                        {
                                            appConstants.catalogFrequentRoleHeaders.map((header, index) => (
                                                <TableCell key={`frequent_role_header_${index}`} width={header.id === "role_name" || header.id === "assigned_to_users_count" ? "15%" : "35%"} >
                                                    <Typography className={classes.infoTableHeader}>
                                                        {
                                                            header.sort === false ?
                                                                header.label :
                                                                <TableSortLabel
                                                                    active={orderBy === header.id}
                                                                    direction={orderBy === header.id ? order : 'asc'}
                                                                    onClick={() => onChangeOrder(header.id)}
                                                                >

                                                                    {header.label}
                                                                </TableSortLabel>
                                                        }
                                                    </Typography>
                                                </TableCell>
                                            ))
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        roles && sortTable(roles.slice(rolesCount, rolesCount + 10), order, orderBy).map((role, index) => {
                                            return (
                                                <TableRow key={`frequent_role_row_${index}`}>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {role.role_name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {role.role_comment}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {role.assigned_to_users_count}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Box className={classes.userScroll}>
                                                            <Typography className={classes.infoTableValue}>
                                                                {role.assigned_to_users}
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    }
                    {(!isLoading && (type === 'datasource' && roles.length <= 0)) && <NoResultFound />}
                </Card>
            </Grid>
        );
    };

    const FrequentTable = () => {

        const [orderBy, setOrderBy] = useState('usage_count');
        const [order, setOrder] = useState('desc');

        const onChangeOrder = useCallback((property) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }, [order, orderBy]);

        return (
            <Grid item className={classNames(classes.flex1, classes.cardbox)}>
                <Card className={classNames('infoContainer')}>
                    <Grid container justify="space-between">
                        <Typography component="h5" variant="h5">
                            {"Frequently Used Tables"}
                        </Typography>
                    </Grid>
                    {
                        tables.length > 10 &&
                        <Box className={classes.navigator}>
                            <IconButton
                                className={classNames(classes.navButton, tablesCount === 0 ? classes.buttonDisable : "")}
                                onClick={tablesCount === 0 ? undefined : () => quickNav('prev', 'tables')}>
                                {Prev}
                            </IconButton>
                            <IconButton
                                className={classNames(classes.navButton, tablesCount + 10 >= tables.length ? classes.buttonDisable : "")}
                                onClick={tablesCount + 10 >= tables.length ? undefined : () => quickNav('next', 'tables')}>
                                {next}
                            </IconButton>
                        </Box>
                    }
                    {
                        (!isLoading && (type === 'datasource' && tables.length > 0)) &&
                        <Grid container justify="flex-start" alignItems="flex-start" className={`${classes.tableWrapperStyle} queryContainer`}>
                            <Table stickyHeader className={classNames(classes.tableStyle, classes.designTable)}>
                                <TableHead>
                                    <TableRow>
                                        {
                                            appConstants.catalogFrequentTableHeaders.map((header, index) => (
                                                <TableCell key={`frequent_table_header_${index}`} width="25%">
                                                    <Typography className={classes.infoTableHeader}>
                                                        {
                                                            header.sort === false ?
                                                                header.label :
                                                                <TableSortLabel
                                                                    active={orderBy === header.id}
                                                                    direction={orderBy === header.id ? order : 'asc'}
                                                                    onClick={() => onChangeOrder(header.id)}
                                                                >

                                                                    {header.label}
                                                                </TableSortLabel>
                                                        }
                                                    </Typography>
                                                </TableCell>
                                            ))
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        tables && sortTable(tables.slice(tablesCount, tablesCount + 10), order, orderBy).map((table, index) => {
                                            return (
                                                <TableRow key={`frequent_table_row_${index}`}>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {table.table_name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {table.table_type}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {table.table_owner}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {table.usage_count}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    }
                    {(!isLoading && (type === 'datasource' && tables.length <= 0)) && <NoResultFound />}
                </Card>
            </Grid>
        );

    };

    const FrequentColumn = () => {

        const [orderBy, setOrderBy] = useState('usage_count');
        const [order, setOrder] = useState('desc');

        const onChangeOrder = useCallback((property) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }, [order, orderBy]);

        return (
            <Grid item className={classNames(classes.flex1, classes.cardbox)}>
                <Card className={classNames('infoContainer')}>
                    <Grid container justify="space-between">
                        <Typography component="h5" variant="h5">
                            {"Frequently Used Columns"}
                        </Typography>
                    </Grid>
                    <Box className={classes.navigator}>
                        <IconButton
                            className={classNames(classes.navButton, columnCount === 0 ? classes.buttonDisable : "")}
                            onClick={columnCount === 0 ? undefined : () => quickNav('prev', 'columns')}>
                            {Prev}
                        </IconButton>
                        <IconButton
                            className={classNames(classes.navButton, columnCount + 10 >= columns.length ? classes.buttonDisable : "")}
                            onClick={columnCount + 10 >= columns.length ? undefined : () => quickNav('next', 'columns')}>
                            {next}
                        </IconButton>
                    </Box>
                    {
                        (!isLoading && (type === 'dataset' && columns.length > 0)) &&
                        <Grid container justify="flex-start" alignItems="flex-start" className={`${classes.tableWrapperStyle} queryContainer`}>
                            <Table stickyHeader className={classNames(classes.tableStyle, classes.designTable)}>
                                <TableHead>
                                    <TableRow>
                                        {
                                            appConstants.catalogFrequentColumnsHeaders.map((header, index) => (
                                                <TableCell key={`frequent_columns_header_${index}`} sortDirection={orderBy === header.id ? order : false}>
                                                    <Typography className={classes.infoTableHeader}>
                                                        {
                                                            header.sort === false ?
                                                                header.label :
                                                                <TableSortLabel
                                                                    active={orderBy === header.id}
                                                                    direction={orderBy === header.id ? order : 'asc'}
                                                                    onClick={() => onChangeOrder(header.id)}
                                                                >

                                                                    {header.label}
                                                                </TableSortLabel>
                                                        }
                                                    </Typography>
                                                </TableCell>
                                            ))
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        columns && sortTable(columns.slice(columnCount, columnCount + 10), order, orderBy).map((column, index) => {
                                            return (
                                                <TableRow key={`frequent_columns_row_${index}`}>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {column.attribute_name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {column.datatype}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {column.usage_count}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    }
                    {(!isLoading && (type === 'dataset' && columns.length <= 0)) && <NoResultFound />}
                </Card>
            </Grid>

        );
    };

    const ConstarintsTable = () => {

        const [orderBy, setOrderBy] = useState('column_name');
        const [order, setOrder] = useState('asc');

        const onChangeOrder = useCallback((property) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        }, [order, orderBy]);

        return (
            <Grid item className={classNames(classes.flex1, type === 'dataset' && columns && columns.length > 0 ? classes.marginLeft10 : "", classes.cardbox)}>
                <Card className={classNames('infoContainer')}>
                    <Grid container justify="space-between">
                        <Typography component="h5" variant="h5">
                            {"Table Contraints"}
                        </Typography>
                    </Grid>
                    {
                        (!isLoading && (type === 'dataset' && constraints.length > 0)) &&
                        <Grid container justify="flex-start" alignItems="flex-start" className={`${classes.tableWrapperStyle} queryContainer`}>
                            <Table stickyHeader className={classNames(classes.tableStyle, classes.designTable)}>
                                <TableHead>
                                    <TableRow>
                                        {
                                            appConstants.catalogTableConstraints.map((header, index) => (
                                                <TableCell key={`table_constraint_header_${index}`}>
                                                    <Typography className={classes.infoTableHeader}>
                                                        {
                                                            header.sort === false ?
                                                                header.label :
                                                                <TableSortLabel
                                                                    active={orderBy === header.id}
                                                                    direction={orderBy === header.id ? order : 'asc'}
                                                                    onClick={() => onChangeOrder(header.id)}
                                                                >

                                                                    {header.label}
                                                                </TableSortLabel>
                                                        }
                                                    </Typography>
                                                </TableCell>
                                            ))
                                        }

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        constraints && sortTable(constraints, order, orderBy).map((column, index) => {
                                            return (
                                                <TableRow key={`constraint_columns_row_${index}`}>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {column.table_name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {column.column_name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography className={classes.infoTableValue}>
                                                            {column.constraint === "FKEY_1" ? "Forgien Key" : "Primary Key"}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    }
                    {(!isLoading && (type === 'dataset' && constraints.length <= 0)) && <NoResultFound />}
                </Card>
            </Grid>
        );
    };

    return (
        <Grid container className={classes.tabSection}>
            <Grid item xs={12} className={(!isLoading && ((type === 'datasource' && datasourceCatalogInfo && Object.keys(datasourceCatalogInfo).length > 0) || (type === 'dataset' && datasetCatalogInfo && Object.keys(datasetCatalogInfo).length > 0))) ? null : classes.catalogInfoView} style={{ paddingRight: 5 }}>
                {
                    (!isLoading && ((type === 'datasource' && datasourceCatalogInfo && Object.keys(datasourceCatalogInfo).length > 0) || (type === 'dataset' && datasetCatalogInfo && Object.keys(datasetCatalogInfo).length > 0))) &&
                    <Grid container justify="flex-start" alignItems="center" spacing={1} style={{ paddingRight: '45px' }} wrap="wrap" className={classNames(classes.card, classes.metadataPanel)}>
                        {
                            type === 'datasource' && datasourceCatalogInfo &&
                            <Fragment>
                                {
                                    datasourceCatalogInfo.database_name &&
                                    <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                                        <Typography component="h5" variant="h5" className={classes.breakAll}>
                                            {datasourceCatalogInfo.database_name}
                                        </Typography>
                                        <Typography className={classes.datasetText}>
                                            Database
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    datasourceCatalogInfo.database_owner &&
                                    <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                                        <Typography component="h5" variant="h5" className={classes.breakAll}>
                                            {datasourceCatalogInfo.database_owner}
                                        </Typography>
                                        <Typography className={classes.datasetText}>
                                            Database Owner
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    datasourceCatalogInfo.created &&
                                    <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                                        <Typography component="h5" variant="h5">
                                            {moment(datasourceCatalogInfo.created).format("MMM DD, YYYY HH:mm")}
                                        </Typography>
                                        <Typography className={classes.datasetText}>
                                            Created Date
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    datasourceCatalogInfo.last_altered &&
                                    <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                                        <Typography component="h5" variant="h5">
                                            {moment(datasourceCatalogInfo.last_altered).format("MMM DD, YYYY HH:mm")}
                                        </Typography>
                                        <Typography className={classes.datasetText}>
                                            Last Modified
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    datasourceCatalogInfo.tables_count &&
                                    <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                                        <Typography component="h5" variant="h5">
                                            {datasourceCatalogInfo.tables_count}
                                        </Typography>
                                        <Typography className={classes.datasetText}>
                                            Tables
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    datasourceCatalogInfo.columns_count &&
                                    <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                                        <Typography component="h5" variant="h5">
                                            {datasourceCatalogInfo.columns_count}
                                        </Typography>
                                        <Typography className={classes.datasetText}>
                                            Columns
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    datasourceCatalogInfo.database_size &&
                                    <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                                        <Typography component="h5" variant="h5">
                                            {bytesToSize(datasourceCatalogInfo.database_size)}
                                        </Typography>
                                        <Typography className={classes.datasetText}>
                                            Database Size
                                        </Typography>
                                    </Grid>
                                }
                            </Fragment>
                        }

                        {
                            type === 'dataset' && datasetCatalogInfo &&
                            <Fragment>
                                {
                                    datasetCatalogInfo.database &&
                                    <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
                                        <Typography component="h5" variant="h5">
                                            {datasetCatalogInfo.database}
                                        </Typography>
                                        <Typography className={classes.datasetText}>
                                            Database
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    datasetCatalogInfo.schema &&
                                    <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
                                        <Typography component="h5" variant="h5">
                                            {datasetCatalogInfo.schema}
                                        </Typography>
                                        <Typography className={classes.datasetText}>
                                            Schema
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    datasetCatalogInfo.table_name &&
                                    <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
                                        <ToolTipComponent title={datasetCatalogInfo.table_name} placement="left" arrow>
                                            <Typography noWrap component="h5" variant="h5">
                                                {datasetCatalogInfo.table_name}
                                            </Typography>
                                        </ToolTipComponent>
                                        <Typography className={classes.datasetText}>
                                            Table Name
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    datasetCatalogInfo.owner &&
                                    <Grid item xs={4} sm={4} md={3} lg={2} xl={2}>
                                        <Typography component="h5" variant="h5">
                                            {datasetCatalogInfo.owner}
                                        </Typography>
                                        <Typography className={classes.datasetText}>
                                            Table Owner
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    datasetCatalogInfo.created &&
                                    <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                                        <Typography component="h5" variant="h5">
                                            {moment(datasetCatalogInfo.created).format("MMM DD, YYYY HH:mm")}
                                        </Typography>
                                        <Typography className={classes.datasetText}>
                                            Created Date
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    datasetCatalogInfo.last_accessed &&
                                    <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                                        <Typography component="h5" variant="h5">
                                            {moment(datasetCatalogInfo.last_accessed).format("MMM DD, YYYY")}
                                        </Typography>
                                        <Typography className={classes.datasetText}>
                                            Last Accessed
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    datasetCatalogInfo.last_altered &&
                                    <Grid item xs={3} sm={3} md={2} lg={2} xl={2} className={classes.marginTop10}>
                                        <Typography component="h5" variant="h5">
                                            {moment(datasetCatalogInfo.last_altered).format("MMM DD, YYYY")}
                                        </Typography>
                                        <Typography className={classes.datasetText}>
                                            Last Altered
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    datasetCatalogInfo.usage_count &&
                                    <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                                        <Typography component="h5" variant="h5">
                                            {datasetCatalogInfo.usage_count}
                                        </Typography>
                                        <Typography className={classes.datasetText}>
                                            Usage Count
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    datasetCatalogInfo.queried_users &&
                                    <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                                        <ToolTipComponent title={datasetCatalogInfo.queried_users}>
                                            <Typography noWrap component="h5" variant="h5">
                                                {datasetCatalogInfo.queried_users}
                                            </Typography>
                                        </ToolTipComponent>
                                        <Typography className={classes.datasetText}>
                                            Queried Users
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    datasetCatalogInfo.queried_roles &&
                                    <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                                        <ToolTipComponent title={datasetCatalogInfo.queried_roles}>
                                            <Typography noWrap component="h5" variant="h5">
                                                {datasetCatalogInfo.queried_roles}
                                            </Typography>
                                        </ToolTipComponent>
                                        <Typography className={classes.datasetText}>
                                            Queried Roles
                                        </Typography>
                                    </Grid>
                                }
                            </Fragment>
                        }
                        {
                            type === 'datasource' &&
                            <Box style={{ position: 'absolute', right: 10, top: "50%", marginTop: '-18px' }}>
                                <ToolTipComponent title={"Run Now"} arrow>
                                    <IconButton className={classNames(classes.dataSetUparrow, classes.padding8, classes.marginLeft5)} onClick={() => rerun_catalog()}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 19.003 22.001">
                                            <g transform="translate(-34.883 0)">
                                                <g transform="translate(34.883 0)">
                                                    <path fill={theme.palette.primary.main} d="M52.457,8.461,39.463.452a3,3,0,0,0-4.58,2.559V18.957a3.036,3.036,0,0,0,3,3.044h.013a3.015,3.015,0,0,0,1.558-.469.858.858,0,1,0-.894-1.465,1.316,1.316,0,0,1-.668.218A1.333,1.333,0,0,1,36.6,18.957V3.01a1.289,1.289,0,0,1,1.964-1.1l12.994,8.01a1.289,1.289,0,0,1-.006,2.2l-9.395,5.752a.858.858,0,1,0,.9,1.463l9.394-5.752a3,3,0,0,0,.011-5.123Z" transform="translate(-34.883 0)" />
                                                </g>
                                            </g>
                                        </svg>
                                    </IconButton>
                                </ToolTipComponent>
                            </Box>
                        }
                    </Grid>
                }
            </Grid>
            {
                type === 'dataset' && datasetCatalogInfo &&
                <Grid container justify="flex-start" alignItems="center" wrap="wrap" className={classNames(classes.card, classes.metadataPanel, classes.marginTop10)}>
                    <Grid item xs={3} sm={3} md={2} lg={4} xl={4} style={{ paddingRight: 25 }}>
                        <Typography component="h5" variant="h5">
                            {datasetCatalogInfo.server_name}
                        </Typography>
                        <Typography className={classes.datasetText}>
                            {"Server Name"}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} sm={3} md={2} lg={3} xl={3} style={{ paddingRight: 25 }}>
                        <Typography component="h5" variant="h5">
                            {datasetCatalogInfo.table_type}
                        </Typography>
                        <Typography className={classes.datasetText}>
                            {"Table Type"}
                        </Typography>
                    </Grid>
                </Grid>
            }
            <Grid container className={classNames(classes.tabProfileContainer, classes.catalogInfo, type === 'dataset' ? classes.datasetInfo : null)}>
                {
                    type === 'datasource' && roles && roles.length > 0 &&
                    <UserTable />
                }
                <Box className={classNames(classes.flexFull, classes.heightAuto, classes.marginBottom15)}>
                    {
                        type === 'datasource' && tables && tables.length > 0 &&
                        <FrequentTable />
                    }
                    {
                        type === 'dataset' && columns && columns.length > 0 &&
                        <FrequentColumn />
                    }
                    {
                        type === 'dataset' && constraints && constraints.length > 0 &&
                        <ConstarintsTable />
                    }
                </Box>
                {
                    type === 'datasource' && schemaDetails && schemaDetails.length > 0 &&
                    <Box className={classNames(classes.flexFull, classes.heightAuto, classes.marginBottom15)}>
                        <SchemaTable />
                    </Box>
                }
                {
                    <Grid item xs={12} className={classNames(classes.cardbox, classes.marginBottom15)} style={{ height: 'max-content' }}>
                        <Card className={classNames('infoContainer', classes.padding15)}>
                            {
                                type === 'dataset' &&
                                <Grid container justify="space-between">
                                    <Typography component="h5" variant="h5">
                                        {"Source SQL"}
                                    </Typography>
                                </Grid>
                            }
                            {
                                type === 'dataset' && (!isQueryLoading ?
                                    <Grid container justify="flex-start" alignItems="center">
                                        {
                                            (!isLoading && (type === 'dataset' && ddlQuery.length > 0)) ?
                                                <Box className={classNames('queryContainer', 'ddlQueryContainer')}>
                                                    {
                                                        ddlQuery.map((query, index) => {
                                                            return (
                                                                <Fragment key={`recent_query_${index}`}>
                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                        <Grid container justify="flex-start" alignItems="center">
                                                                            <Grid item xs={12} className={classes.queryTextContainer}>
                                                                                <Typography id={`ddl_query_${index}`} className={classes.queryText}>
                                                                                    {query.query}
                                                                                </Typography>
                                                                                <IconButton onClick={() => copyToClipBoard(`ddl_query_${index}`)}>
                                                                                    <FileCopyIcon />
                                                                                </IconButton>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Fragment>
                                                            );
                                                        })
                                                    }
                                                </Box> :
                                                <Box style={{ width: '100%' }}>
                                                    <Typography variant="body2" className={classes.noQueries}>
                                                        No Queries Found
                                                    </Typography>
                                                </Box>
                                        }
                                    </Grid>
                                    :
                                    <Grid item className={classNames(classes.catalogInfo)} style={{ position: 'relative', minHeight: '100px', width: '100%' }}>
                                        <Loader />
                                    </Grid>)
                            }
                            <Grid container justify="space-between" className={classes.paddingTop15}>
                                <Typography component="h5" variant="h5">
                                    {"Recent Queries"}
                                </Typography>
                            </Grid>
                            {
                                !isQueryLoading ?
                                    <Grid container justify="flex-start" alignItems="center">
                                        {
                                            (!isLoading && ((type === 'datasource' || type === 'dataset') && queries.length > 0)) ?
                                                <Box className={classNames('queryContainer')}>
                                                    {
                                                        queries.map((query, index) => {
                                                            return (
                                                                <Fragment key={`recent_query_${index}`}>
                                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                        <Grid container justify="flex-start" alignItems="center">
                                                                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} className={classes.queryTextContainer}>
                                                                                <Typography id={`recent_queries_${query.query_id}`} className={classes.queryText}>
                                                                                    {query.query}
                                                                                </Typography>
                                                                                <IconButton onClick={() => copyToClipBoard(`recent_queries_${query.query_id}`)}>
                                                                                    <FileCopyIcon />
                                                                                </IconButton>
                                                                            </Grid>
                                                                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={classNames(classes.roleText, classes.align)} >
                                                                                <Typography noWrap component="h5" variant="h5">
                                                                                    {query.queried_role}
                                                                                </Typography>
                                                                                <Typography className={classes.datasetText}>
                                                                                    Queried Role
                                                                                </Typography>
                                                                            </Grid>
                                                                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className={classNames(classes.roleText, classes.align)}>
                                                                                <Typography noWrap component="h5" variant="h5">
                                                                                    {query.queried_user}
                                                                                </Typography>
                                                                                <Typography className={classes.datasetText}>
                                                                                    Queried User
                                                                                </Typography>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>

                                                                </Fragment>

                                                            );
                                                        })
                                                    }
                                                    {
                                                        recentQueries && recentQueries.length > 5 &&
                                                        <Typography variant="body2"
                                                            className={classes.showallQueries}
                                                            onClick={() => loadMoreQueries(queries.length < 6 ? "more" : "less")}>
                                                            {queries.length < 6 ? "Show all" : "Show less"}
                                                        </Typography>
                                                    }
                                                </Box> :
                                                <Box style={{ width: '100%' }}>
                                                    <Typography variant="body2" className={classes.noQueries}>
                                                        No Queries Found
                                                    </Typography>
                                                </Box>
                                        }
                                    </Grid>
                                    :
                                    <Grid item className={classNames(classes.catalogInfo)} style={{ position: 'relative', minHeight: '100px', width: '100%' }}>
                                        <Loader />
                                    </Grid>
                            }
                        </Card>
                    </Grid>

                }
            </Grid>
            {isLoading && <Loader />}
        </Grid>
    );
};

CatalogInfo.propTypes = {
    classes: PropTypes.object,
    datasourceId: PropTypes.number,
    datasetId: PropTypes.number,
    type: PropTypes.string,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...DatasourceStyles(theme),
    ...PropertiesListViewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(CatalogInfo);