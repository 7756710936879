import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles, Grid, Typography, IconButton, MenuItem } from '@material-ui/core';
import DatasetStyles from '../../DatasetStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';
import TileViewStyles from './TileViewStyles.jsx';
import { getDataTypeColor, sortTable } from '../../../../helpers/appHelpers.js';
import { useSelector, useDispatch } from 'react-redux';
import AutoComplete from '../../../AutoComplete/AutoComplete.jsx';
import { updateProperties } from '../../../../actions/datasetActions';
import TooltipComponent from '../../../Tooltip/Tooltip.jsx';
import Rating from '../../../Rating/Rating.jsx';
import TextBox from '../../../TextBox/TextBox.jsx';
import Verified from '../../../../assets/images/verified.svg';
import InProgess from '../../../../assets/images/wip.svg';
import Deprecated from '../../../../assets/images/deprecated.svg';
import Issues from '../../../../assets/images/issues.svg';
import { appConstants } from '../../../../constants/appConstants.js';


const AttributeTileHeader = (props) => {
    const { classes, attribute, isEditable, updatePropertiesList, updateFieldProperties, theme, attribRating } = props;
    const datatypes = useSelector(({ datasource }) => datasource.datatypes);
    const fieldTypes = useSelector(({ datasource }) => datasource.fieldTypes);
    const datasetId = useSelector(({ dataset }) => dataset.selectedDatasetId);
    const attributesCatalogInfo = useSelector(({ datasource }) => datasource.attributesCatalogInfo);
    const [datatype] = useState('Text');
    const dispatch = useDispatch();
    const [property, setProperty] = useState({
        datatype: 'Text',
        fieldType: '',
        status: 'verified',
        is_delete: false
    });

    useEffect(() => {
        if (attribute) {
            setProperty({ datatype: attribute.datatype, fieldtype: attribute.fieldtype, status: attribute.status, is_delete: attribute.is_delete });
        }
    }, [attribute, datatypes]);
    const onPropertyChange = useCallback((name, value) => {
        property[name] = value;
        setProperty({ ...property });
        updatePropertiesList({ name: attribute.name, value: property });
        dispatch(updateProperties(datasetId, { id: attribute.id, attribute: attribute.name, value: property, 'changed_properties': [name] })).then((response) => {
            if (response) {
                updateFieldProperties(response, attribute);
            }
        });
    }, [attribute, datasetId, dispatch, property, updateFieldProperties, updatePropertiesList]);

    const getAttributeCatalogComment = () => {
        let comment = "";
        if (attributesCatalogInfo && attributesCatalogInfo.length !== 0) {
            comment = attributesCatalogInfo.find((data) => (data.attribute_name === attribute.name ? attribute.name : ''));
            comment = comment && comment.comment ? comment.comment : "";
        }
        return comment;
    };

    const attribRatingCalc = (rating) => {
        if (rating) {
            return Number.isInteger(rating) ? rating : rating?.toFixed(1);
        }
        return 0;

    };

    const renderStatusIcon = (status) => {
        if (status.toLowerCase() === "verified") {
            return Verified;
        } else if (status.toLowerCase() === "work in progress") {
            return InProgess;
        } else if (status.toLowerCase() === "issues") {
            return Issues;
        }
        return Deprecated;
    };

    return (
        <Grid container direction="column" justify="flex-start">
            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.attibuteHeaderDetails}>
                <Grid item sm={4} className={classes.flex} alignItems="center">
                    {
                        (getAttributeCatalogComment().length !== 0) &&
                        <TooltipComponent arrow title={(getAttributeCatalogComment())} placement="bottom-start">
                            <IconButton className={classNames(classes.nopadding, classes.marginRight5, classes.smallIcon)}>
                                <svg id="information" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                                    <path id="Path_15040" data-name="Path 15040" d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,18.182A8.182,8.182,0,1,1,18.182,10,8.191,8.191,0,0,1,10,18.182Z" fill={theme.palette.secondary.main} />
                                    <path id="Path_15041" data-name="Path 15041" d="M146.214,70a1.212,1.212,0,1,0,1.212,1.213A1.214,1.214,0,0,0,146.214,70Z" transform="translate(-136.214 -65.758)" fill={theme.palette.secondary.main} />
                                    <path id="Path_15042" data-name="Path 15042" d="M150.909,140a.909.909,0,0,0-.909.909v5.455a.909.909,0,1,0,1.818,0v-5.455A.909.909,0,0,0,150.909,140Z" transform="translate(-140.909 -131.515)" fill={theme.palette.secondary.main} />
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                    }
                    <TooltipComponent arrow title={attribute.name} placement="bottom-start">
                        <Typography component="h5" variant="h5" className={classes.attributeHeaderTitle}>
                            {attribute.name ? attribute.name : ''}
                            {
                                attribute.is_drift &&
                                <TooltipComponent title="Drift" placement="right" arrow>
                                    <IconButton className={classes.iconButtonSmall}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17.474 30.574">
                                            <g id="lightning-electric-energy" transform="translate(-205.617)">
                                                <path id="Path_2313" data-name="Path 2313" d="M206.709,30.381l9.786-9.831a.637.637,0,0,0-.452-1.086h-.78a.637.637,0,0,1-.5-1.032l5.189-6.543a.636.636,0,0,0-.5-1.032h-2.609a.637.637,0,0,1-.51-1.019l6.629-8.818A.637.637,0,0,0,222.452,0H211.968a.633.633,0,0,0-.57.354l-5.126,10.369a.638.638,0,0,0,.57.921h2.364a.638.638,0,0,1,.57.921l-3.5,7.091a.638.638,0,0,0,.57.921h2.227a.636.636,0,0,1,.573.914l-3.957,8.165A.636.636,0,0,0,206.709,30.381Z" fill="#49006a" />
                                            </g>
                                        </svg>
                                    </IconButton>
                                </TooltipComponent>
                            }
                        </Typography>
                    </TooltipComponent>
                    <Grid item className={classNames(classes.flex, classes.sensitivityContainer)}>
                        <TooltipComponent title={"The overall rating of the datasource/dataset"} arrow>
                            <Grid container alignItems="center">
                                <Rating
                                    name="read-only"
                                    value={attribRating}
                                    size="large"
                                    readOnly
                                    max={1} />
                                <Typography className={classNames(classes.datasourceDetailText, classes.alias, classes.ratingCount)} variant="body2">
                                    {attribRatingCalc(attribRating)}
                                </Typography>
                            </Grid>
                        </TooltipComponent>
                    </Grid>
                    {
                        attribute.sensitivity > 1 &&
                        <Grid item className={classNames(classes.flex, classes.sensitivityContainer)}>
                            <TooltipComponent title={'Contains Sensitive Information'} arrow>
                                <svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" width="32" height="32">
                                    <g id="Logo" clipPath="url(#clip-Logo)">
                                        <g id="Group_4" data-name="Group 4" transform="translate(-18.99 -64.748)">
                                            <g id="Group_3" data-name="Group 3" transform="translate(29.99 87.748)">
                                                <line id="Line_1" data-name="Line 1" x2="31.611" transform="translate(86.008 38.817)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeWidth="8" />
                                                <line id="Line_2" data-name="Line 2" x2="38.119" transform="translate(86.008 58.315)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeWidth="8" />
                                                <g id="Group_2" data-name="Group 2" transform="translate(18.138 16.479)">
                                                    <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(10.103 0)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8">
                                                        <circle cx="20.159" cy="20.159" r="20.159" stroke="none" />
                                                        <circle cx="20.159" cy="20.159" r="16.159" fill="none" />
                                                    </g>
                                                    <path id="Path_1" data-name="Path 1" d="M226.1,131.262c.074-.283,3.875-28.637,29.415-28.637s30.225,28.907,29.96,28.637S226.029,131.544,226.1,131.262Z" transform="translate(-226.103 -60.531)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" />
                                                </g>
                                                <g id="Group_1" data-name="Group 1" transform="translate(108.877 66.576)">
                                                    <g id="Rectangle_2" data-name="Rectangle 2" transform="translate(0 27.27)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8">
                                                        <rect width="69.739" height="59.932" rx="10" stroke="none" />
                                                        <rect x="4" y="4" width="61.739" height="51.932" rx="6" fill="none" />
                                                    </g>
                                                    <path id="Path_2" data-name="Path 2" d="M339.964,142.246s-4.831-29.99,19.725-29.889,19.222,29.889,19.222,29.889" transform="translate(-324.329 -112.357)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" />
                                                    <g id="Rectangle_3" data-name="Rectangle 3" transform="translate(6.538 33.808)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeWidth="10">
                                                        <rect width="56.663" height="47.945" rx="3" stroke="none" />
                                                        <rect x="5" y="5" width="46.663" height="37.945" rx="2" fill="none" />
                                                    </g>
                                                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="7.628" cy="7.628" r="7.628" transform="translate(27.242 49.064)" fill={theme.palette.primary.main} />
                                                </g>
                                                <path id="Path_3" data-name="Path 3" d="M349.571,85.856s1.193-37.659,0-40.24-4.906-6.753-10.15-6.854-122.488.394-127.475.193-6.955,1.994-6.955,9.317v99.934s.666,6.854,9.51,7.075,16.361,0,16.361,0,4.276-10.853,12.179-11.008,12.241,11.008,12.241,11.008l38.119-.471" transform="translate(-204.99 -38.748)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            </TooltipComponent>
                        </Grid>
                    }
                </Grid>
                <Grid sm={3} item className={classes.boxListDataType} style={{ marginLeft: '15px' }}>
                    <Grid className={classNames(classes.typeIcon, classes.AttriTypeIcon)} style={{ color: getDataTypeColor(attribute.datatype ? attribute.datatype : '') }}>
                        {datatype ? datatype[0] : ""}
                    </Grid>
                    <Grid style={{ position: 'relative' }} className={classNames(classes.borderNoneAutocomplete, classes.focusbg)}>
                        <AutoComplete
                            className={classes.borderNone}
                            disabled={!isEditable}
                            placeholder="Data Type"
                            onChange={(event) => onPropertyChange("datatype", event.target.value)}
                            onBlur={() => { }}
                            value={property.datatype ? property.datatype : ''}
                            options={sortTable(datatypes, "asc", "name")}
                            selectedValuePath="name"
                            displayMemberPath="name"
                            removeClass
                            noDropPostion={1}
                            openSuggestionsOnClick
                            name="datatype"
                            id="datatype"
                            subComponent
                            position={{ top: 40, left: 130 }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
                <Grid item sm={3}>
                    <Grid container direction="row" justify="flex-start" alignItems="center">
                        <Typography variant="body2">
                            Field Type
                        </Typography>
                        <Grid className={classNames(classes.fieldAutoComplete, classes.borderNoneAutocomplete, classes.focusbg)}>
                            <AutoComplete
                                className={classes.borderNone}
                                disabled={!isEditable}
                                placeholder="Enter Field Type"
                                onChange={(event) => onPropertyChange("fieldtype", event.target.value)}
                                onBlur={() => { }}
                                value={property.fieldtype ? property.fieldtype : ''}
                                options={sortTable(fieldTypes, "asc", "name")}
                                selectedValuePath="name"
                                displayMemberPath="name"
                                removeClass
                                noDropPostion={1}
                                openSuggestionsOnClick
                                name="fieldtype"
                                id="fieldtype"
                                fullWidth
                                subComponent
                                position={{ top: 70, left: 80 }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item sm={1}>
                    <Grid container direction="row" justify="space-between" alignItems="right">
                        <TextBox
                            name="status"
                            select
                            disabled={property.is_delete || attribute?.is_depricated || false}
                            onChange={(event) => onPropertyChange(event.target.name, event.target.value)}
                            value={property.status ? property.status : appConstants.glossaryStatus[1].value}
                            className={classes.glossarySelect}
                            SelectProps={
                                {
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }
                                    // IconComponent: () => DropDownIcon()
                                }
                            }
                        >
                            {
                                appConstants.glossaryStatus.map((status, index) =>
                                    <MenuItem key={index} className={classes.menuItem} value={status.value}>
                                        <img src={renderStatusIcon(status.value)} alt="" className={classes.marginRight5} />
                                        <Typography>
                                            {status.name}
                                        </Typography>
                                    </MenuItem>
                                )
                            }
                        </TextBox>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>

    );
};

AttributeTileHeader.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    attribute: PropTypes.object,
    isEditable: PropTypes.bool,
    updatePropertiesList: PropTypes.func,
    updateFieldProperties: PropTypes.func,
    attribRating: PropTypes.number
};


export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(AttributeTileHeader);