import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography, IconButton } from '@material-ui/core';
import TileViewStyles from './TileViewStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';


const LearningListItem = (props) => {
    const { classes, item, theme, deleteLearning, index } = props;

    return (
        <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                    <Grid item>
                        <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                            <Typography className={classes.inputText} noWrap>
                                {item && item.input_value ? item.input_value : ''}
                            </Typography>
                            <Typography className={classes.inputSeparator} noWrap>
                                {' - '}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item className={classes.curateText}>
                        <Typography className={classes.inputText} noWrap>
                            {item && item.curated_value ? item.curated_value : ''}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <IconButton className={classes.learningDeleteButton} onClick={() => deleteLearning(index)}>
                    <svg version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        width="22px"
                        height="22px"
                        viewBox="0 0 32 32">
                        <g id="Delete">
                            <path fill={theme.palette.primary.main}
                                stroke={theme.palette.primary.main}
                                strokeWidth="0.92"
                                strokeMiterlimit="10"
                                d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
        L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
        L22.3,25.8z" />
                            <path fill={theme.palette.primary.main}
                                stroke={theme.palette.primary.main}
                                strokeWidth="0.92"
                                strokeMiterlimit="10"
                                d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
        c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
        c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                        </g>
                    </svg>
                </IconButton>
            </Grid>
        </Grid>
    );
};

LearningListItem.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    item: PropTypes.object,
    deleteLearning: PropTypes.func,
    index: PropTypes.number
};

export default withStyles((theme) => ({
    ...TileViewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(LearningListItem);