import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, Button, withStyles, TextField, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Dropzone from 'react-dropzone';
import * as XLSX from 'xlsx';

// import Styles
import DatasourceimportStyles from './DatasourceimportStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import classnames from 'classnames';
import FileUpload from '../../assets/images/file-upload.svg';

// import Actions
import { showAlert, successAlert } from '../../actions/alertActions';
import { importDatasetRules } from '../../actions/datasourceActions';

// import Component
import AutoCompleteInput from '../../components/AutoComplete/AutoCompleteInput.jsx';
import { history } from '../../config/history';
import Loader from '../../components/Loaders/Loader.jsx';

const ImportRules = (props) => {

    const [show1, setShow1] = useState(true);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [datasourceIndex, setDatasourceIndex] = useState('');
    const [datasetIndex, setDatasetIndex] = useState('');
    const [attributeIndex, setAttributeIndex] = useState('');
    const [ruleNameIndex, setRuleNameIndex] = useState('');
    const [ruleTypeIndex, setRuleTypeIndex] = useState('');
    const [selectedAttributeIndex, setSelectedAttributeIndex] = useState('');
    const [conditionIndex, setConditionIndex] = useState('');
    const [valueIndex, setValueIndex] = useState('');
    const [ruleOperatorIndex, setRuleOperatorIndex] = useState('');
    const [selectedGroupAttributeIndex, setSelectedGroupAttributeIndex] = useState('');
    const [groupConditionIndex, setGroupConditionIndex] = useState('');
    const [groupValueIndex, setGroupValueIndex] = useState('');
    const [profileQueryIndex, setProfileQueryIndex] = useState('');
    const [fileData, setFileData] = useState([]);
    const [fileFields, setFileFields] = useState([]);
    const [defaultFields, setDefaultFields] = useState({
        'datasource': '',
        'dataset': '',
        'attribute': '',
        'rule name': '',
        'rule type': '',
        'selected rule attribute': '',
        'rule condition': '',
        'rule value': '',
        'rule operator': '',
        'selected group attribute': '',
        'group condition': '',
        'group value': '',
        'profile query': ''
    });
    const dispatch = useDispatch();

    useEffect(() => {
        props.getPageTitle('Import Rules');
    });

    const handleBack = () => {
        history.back();
    };

    const handleBack1 = () => {
        setShow2(false);
        setShow1(true);
    };

    const handleBack2 = () => {
        setShow3(false);
        setShow2(true);
    };

    const onFieldChange = (property, value) => {
        const index = fileFields.indexOf(value);
        switch (property) {
            case 'datasource':
                setDatasourceIndex(index);
                break;
            case 'dataset':
                setDatasetIndex(index);
                break;
            case 'attribute':
                setAttributeIndex(index);
                break;
            case 'rule name':
                setRuleNameIndex(index);
                break;
            case 'rule type':
                setRuleTypeIndex(index);
                break;
            case 'selected rule attribute':
                setSelectedAttributeIndex(index);
                break;
            case 'condition':
                setConditionIndex(index);
                break;
            case 'value':
                setValueIndex(index);
                break;
            case 'rule operator':
                setRuleOperatorIndex(index);
                break;
            case 'selected group attribute':
                setSelectedGroupAttributeIndex(index);
                break;
            case 'group condition':
                setGroupConditionIndex(index);
                break;
            case 'group value':
                setGroupValueIndex(index);
                break;
            case 'profile query':
                setProfileQueryIndex(index);
                break;
            default:
                break;
        }
    };

    const handleFileUpload = useCallback((report) => {
        const reader = new FileReader();
        reader.onload = (file) => {
            const bstr = file.target.result;
            const wb = XLSX.read(bstr, { type: 'array' });
            const wsname = wb.SheetNames[0];
            const sheet = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
            const importData = data && data.length > 0 ? Object.assign(data, []) : [];
            setFileData(importData);
            const dataLength = data.length;
            const headerData = dataLength > 0 ? JSON.parse(JSON.stringify(data[0])) : [];
            const filteredData = [];
            if (headerData.length > 0) {
                let columnMapping = {
                    'datasource': '',
                    'dataset': '',
                    'attribute': '',
                    'rule name': '',
                    'rule type': '',
                    'selected rule attribute': '',
                    'rule condition': '',
                    'rule value': '',
                    'rule operator': '',
                    'selected group attribute': '',
                    'group condition': '',
                    'group value': '',
                    'profile query': ''
                };
                headerData.map((item, index) => {
                    const itemValue = item ? item.toLowerCase().trim() : '';
                    if (itemValue === 'datasource' || itemValue === 'datasources') {
                        setDatasourceIndex(index);
                    }
                    if (itemValue === 'dataset' || itemValue === 'datasets') {
                        setDatasetIndex(index);
                    }
                    if (itemValue === 'attribute' || itemValue === 'attributes') {
                        setAttributeIndex(index);
                    }
                    if (itemValue === 'rule name' || itemValue === 'rulename') {
                        setRuleNameIndex(index);
                    }
                    if (itemValue === 'rule type' || itemValue === 'ruletype') {
                        setRuleTypeIndex(index);
                    }
                    if (itemValue === 'selected rule attribute' || itemValue === 'selectedattribute') {
                        setSelectedAttributeIndex(index);
                    }
                    if (itemValue === 'rule condition' || itemValue === 'condition') {
                        setConditionIndex(index);
                    }
                    if (itemValue === 'rule value' || itemValue === 'value') {
                        setValueIndex(index);
                    }
                    if (itemValue === 'rule operator' || itemValue === 'ruleoperator') {
                        setRuleOperatorIndex(index);
                    }
                    if (itemValue === 'selected group attribute' || itemValue === 'selectedattribute') {
                        setSelectedGroupAttributeIndex(index);
                    }
                    if (itemValue === 'group condition' || itemValue === 'groupcondition') {
                        setGroupConditionIndex(index);
                    }
                    if (itemValue === 'group value' || itemValue === 'value') {
                        setGroupValueIndex(index);
                    }
                    if (itemValue === 'profile query' || itemValue === 'value') {
                        setProfileQueryIndex(index);
                    }
                    if (columnMapping[itemValue] !== undefined) {
                        columnMapping = {
                            ...columnMapping,
                            [itemValue]: item
                        };
                        filteredData.push(item);
                    }
                    return '';
                });
                setShow1(false);
                setShow2(true);
                setDefaultFields(columnMapping);
                setFileFields(headerData.filter((item) => item !== null));
            } else {
                dispatch(showAlert('Invalid Excel'));
            }
        };
        reader.readAsArrayBuffer(report[0]);
    }, [dispatch]);

    const handleImport = () => {
        const requestParams = {
            datasource_index: datasourceIndex,
            dataset_index: datasetIndex,
            attribute_index: attributeIndex,
            rule_name_index: ruleNameIndex,
            rule_type_index: ruleTypeIndex,
            selected_attribute_index: selectedAttributeIndex,
            rule_condition_index: conditionIndex,
            rule_value_index: valueIndex,
            rule_operator_index: ruleOperatorIndex,
            selected_group_attribute_index: selectedGroupAttributeIndex,
            group_condition_index: groupConditionIndex,
            group_value_index: groupValueIndex,
            profile_query_index: profileQueryIndex,
            data: fileData
        };
        if (datasourceIndex !== '' && datasetIndex !== '' && attributeIndex !== '') {
            setIsLoading(true);
            dispatch(importDatasetRules(requestParams)).then((response) => {
                if (response) {
                    dispatch(successAlert('Successfully Imported'));
                    history.back();
                } else {
                    dispatch(showAlert('No Records Imported'));
                    history.back();
                }
            });
        } else if (datasourceIndex === '') {
            dispatch(showAlert('Select the datasource field'));
            setIsLoading(false);
        } else if (datasetIndex === '') {
            dispatch(showAlert('Select the dataset field'));
            setIsLoading(false);
        } else if (attributeIndex === '') {
            dispatch(showAlert('Select the attribute field'));
            setIsLoading(false);
        }
    };

    const getRemainingOptions = (value) => {
        const selectedValues = [...Object.values(defaultFields)];
        const remain = fileFields.filter((f) => selectedValues.indexOf(f) < 0);
        if (value) {
            remain.push(value);
        }
        return remain;
    };

    const { classes } = props;
    return (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start">
            {
                show1 &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid className={classes.backIcon}>
                        <Button className={classnames(classes.block, classes.nopadding, classes.backButton, classes.bgNone)}
                            onClick={() => handleBack()}>
                            <svg id="backArrow" xmlns="http://www.w3.org/2000/svg" width="8.335" height="13.909" viewBox="0 0 8.335 13.909" style={{ fill: '#6f777c', marginRight: "5px" }}>
                                <defs />
                                <g transform="translate(-11.172 0)">
                                    <path className="a" d="M11.564,5.967a1.359,1.359,0,0,1,.228-.181L17.183.395A1.347,1.347,0,0,1,19.088,2.3L14.446,6.943l4.666,4.667a1.347,1.347,0,1,1-1.9,1.905L11.792,8.1a1.4,1.4,0,0,1-.228-2.133Z" transform="translate(0 0)" />
                                </g>
                            </svg>
                            {'Back'}
                        </Button>
                    </Grid>
                    <Grid>
                        <Dropzone
                            maxSize={200 * (1024 * 1024)}
                            accept={['.csv', '.xlsx']}
                            disabled={false}
                            onDrop={(acceptedFiles) => handleFileUpload(acceptedFiles)}
                        >
                            {
                                ({ getRootProps, getInputProps, isDragActive }) => (
                                    <Grid className={classnames(classes.droparea)}
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="center"
                                        {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Grid container
                                            direction="column"
                                            justify="center"
                                            alignItems="center"
                                            wrap="nowrap"
                                            className={classes.dropareaContent}>
                                            <Grid item>
                                                <img src={FileUpload} alt="file upload" className={classes.dropareaImage} />
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="h3" className={classes.dropareaText}>
                                                    {'Drag & Drop files here or'}
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="contained" color="primary">
                                                    {'Choose File(s)'}
                                                </Button>
                                            </Grid>

                                            <Grid item className={classes.hintContainer}>
                                                <Grid container direction="column" wrap="nowrap" justify="flex-start" alignItems="baseline">
                                                    <Typography variant="h3" className={classes.hintText}>
                                                        {'Supported file types: xlsx, csv'}
                                                    </Typography>
                                                    <Typography variant="h3" className={classes.hintText}>
                                                        {'Max. file size limit : 100MB'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Dropzone>
                    </Grid>
                </Grid>
            }
            {
                show2 &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.maxHeight}>
                    <Grid className={classes.backIcon}>
                        <Button className={classnames(classes.block, classes.nopadding, classes.backButton, classes.bgNone)}
                            onClick={() => handleBack1()}>
                            <svg id="backArrow" xmlns="http://www.w3.org/2000/svg" width="8.335" height="13.909" viewBox="0 0 8.335 13.909" style={{ fill: '#6f777c', marginRight: "5px" }}>
                                <defs />
                                <g transform="translate(-11.172 0)">
                                    <path className="a" d="M11.564,5.967a1.359,1.359,0,0,1,.228-.181L17.183.395A1.347,1.347,0,0,1,19.088,2.3L14.446,6.943l4.666,4.667a1.347,1.347,0,1,1-1.9,1.905L11.792,8.1a1.4,1.4,0,0,1-.228-2.133Z" transform="translate(0 0)" />
                                </g>
                            </svg>
                            {'Back'}
                        </Button>
                    </Grid>
                    <Grid className={classes.tablearea}>
                        <Grid className={classes.tableHeading}>
                            <Typography variant="h2" component="h2">
                                Upload Rules
                            </Typography>
                        </Grid>
                        <Grid className={classes.table}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            Fields
                                        </TableCell>
                                        <TableCell className={classes.fieldWidth}>
                                            Columns
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        Object.keys(defaultFields).map((item, index) => {
                                            const property = item;
                                            return (
                                                <TableRow key={index} className={classes.rowHeight}>
                                                    <TableCell className={classes.fieldText}>
                                                        {item}
                                                    </TableCell>
                                                    <TableCell>
                                                        <AutoCompleteInput
                                                            name={`${index}DFields`}
                                                            popperWidth={300}
                                                            options={getRemainingOptions(defaultFields[property] ? defaultFields[property] : "")}
                                                            value={defaultFields[property] ? defaultFields[property] : ""}
                                                            renderInput={
                                                                (params) => <TextField {...params}
                                                                    className={classnames(classes.autocompleteWidth, classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                                                    placeholder="Fields" />
                                                            }
                                                            onChange={(_, selectedValue) => onFieldChange(property, selectedValue)}
                                                            capitalize
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid className={classes.tableFooter}>
                            <Button disabled={isLoading} onClick={() => handleImport()} title="Import" variant="contained" color="primary" className={classes.importButton}>
                                {'Import'}
                                {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                            </Button>
                            <Button onClick={() => handleBack1()} title="Cancel" className={classnames(classes.cancelButton, classes.actionButtons)}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                show3 &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.maxHeight}>
                    <Grid className={classes.backIcon}>
                        <Button className={classnames(classes.block, classes.nopadding, classes.backButton, classes.bgNone)}
                            onClick={() => handleBack2()}>
                            <svg id="backArrow" xmlns="http://www.w3.org/2000/svg" width="8.335" height="13.909" viewBox="0 0 8.335 13.909" style={{ fill: '#6f777c', marginRight: "5px" }}>
                                <defs />
                                <g transform="translate(-11.172 0)">
                                    <path className="a" d="M11.564,5.967a1.359,1.359,0,0,1,.228-.181L17.183.395A1.347,1.347,0,0,1,19.088,2.3L14.446,6.943l4.666,4.667a1.347,1.347,0,1,1-1.9,1.905L11.792,8.1a1.4,1.4,0,0,1-.228-2.133Z" transform="translate(0 0)" />
                                </g>
                            </svg>
                            {'Back'}
                        </Button>
                    </Grid>
                    <Grid className={classes.backIcon}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Invalid Records
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {/* {
                                    invalidRecords.map((item, index) => {
                                        return (
                                            <TableRow key={index}>
                                                <TableCell>
                                                    {item}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                } */}
                            </TableBody>
                        </Table>
                    </Grid>
                    <Grid className={classes.tableFooter}>
                        <Button onClick={() => history.back()} title="Done" variant="contained" color="primary" className={classes.importButton}>
                            Done
                        </Button>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
};

ImportRules.propTypes = {
    classes: PropTypes.object,
    getPageTitle: PropTypes.func
};

export default withStyles((theme) => ({
    ...DatasourceimportStyles(theme),
    ...Styles(theme)
}))(ImportRules);