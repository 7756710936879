import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Typography, Avatar, Popover, List, ListItem, ListItemAvatar, ListItemText, Divider, ListItemSecondaryAction, Button } from '@material-ui/core';
import PermissionStyles from './PermissionStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import { appConstants } from '../../constants/appConstants';
import { getRandomColor, getInitials, returnImage } from '../../helpers/appHelpers';
import Loader from '../Loaders/Loader.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const RequestAccess = (props) => {
    const { classes, governance, open, anchorEl, onclose, respondRequest } = props;

    const getRequestText = (user) => {
       if (user.level === "Dataset") {
           if (user.is_higher_requested) {
               return `Dataset ${user.dataset_name} Request - Level ${user.new_access_level}`;
           }
           return `Dataset ${user.dataset_name} Request`;
       } else if (user.level !== "Dataset" && user.is_higher_requested) {
            return `Datasource Request - Level ${user.new_access_level}`;
       }
       return "Datasource Request";
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={() => onclose()}
            anchorOrigin={
                {
                    vertical: 'top',
                    horizontal: 'center'
                }
            }
            transformOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }
        >
            <Grid className={classes.accessPopover}>
                <Typography component="h5" variant="h5">
                    Request Access
                </Typography>
                {
                    governance && governance.length !== 0 ?
                        <List className={classes.marginTop10}>
                            {
                                governance && governance.map((user, index) =>
                                    <Fragment key={index}>
                                        <ListItem>
                                            <ListItemAvatar>
                                                <Avatar
                                                    style={
                                                        {
                                                            backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, user.avatar)})`,
                                                            backgroundColor: getRandomColor(user.email)
                                                        }
                                                    }>
                                                    {
                                                        !user.avatar &&
                                                        <span>
                                                            {getInitials(user.email)}
                                                        </span>
                                                    }
                                                </Avatar>
                                            </ListItemAvatar>
                                            <Grid className={classes.requestInfo}>
                                                {getRequestText(user)}
                                                <ToolTipComponent title={user.request_comment} arrow>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                                                </svg>
                                                </ToolTipComponent>
                                            </Grid>
                                            <ListItemText className={classes.wordBreak} primary={user.email} />
                                            <ListItemSecondaryAction>
                                                {
                                                    !user.isLoading ?
                                                        <Fragment>
                                                            <Button disabled={user.isLoading} color="primary" onClick={() => respondRequest('accept', user)}>
                                                                Accept
                                                            </Button>
                                                            <Button disabled={user.isLoading} onClick={() => respondRequest('decline', user)}>
                                                                Decline
                                                            </Button>
                                                        </Fragment> :
                                                        <Loader size="small" type="button" />
                                                }
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                        <Divider />
                                    </Fragment>
                                )
                            }
                        </List> :
                        <Typography className={classes.marginTop15} align="center">
                            No Access Request Found
                        </Typography>
                }
            </Grid>
        </Popover>
    );
};

RequestAccess.propTypes = {
    classes: PropTypes.object,
    governance: PropTypes.array,
    open: PropTypes.bool,
    onclose: PropTypes.func,
    anchorEl: PropTypes.element,
    respondRequest: PropTypes.func
};

export default withStyles((theme) => ({
    ...PermissionStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(RequestAccess);