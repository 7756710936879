import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import { alertActions } from '../constants/actionTypes/alertActionTypes';
import * as apiService from '../services/apiService';
import { connectorsActions } from '../constants/actionTypes/connectorActionTypes';
import { alerts } from '../config/alerts';

export const uploadFiles = (requestParams) => {
    return (dispatch) => {
        dispatch(request(connectorsActions.CONNECT_FILES_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.connectors.connectFiles, requestParams, true).then((response) => {
            dispatch(success(connectorsActions.CONNECT_FILES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(connectorsActions.CONNECT_FILES_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};


export const getConnectedFiles = (datasourceId) => {
    const endpoint = `${appConstants.END_POINTS.connectors.getConnectedFiles}/${datasourceId}`;
    return (dispatch) => {
        dispatch(request(connectorsActions.GET_CONNECTED_FILES_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(connectorsActions.GET_CONNECTED_FILES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(connectorsActions.GET_CONNECTED_FILES_FAILURE, error));
        });
    };
};


export const connectDatasource = (datasourceConfig) => {
    const endpoint = `${appConstants.END_POINTS.connectors.connectDatasource}`;
    return (dispatch) => {
        dispatch(request(connectorsActions.CONNECT_DATASOURCE_REQUEST));
        return apiService.postRequest(endpoint, datasourceConfig).then((response) => {
            dispatch(success(connectorsActions.CONNECT_DATASOURCE_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.connectDatasource));
            return response.data;
        }, (error) => {
            dispatch(failure(connectorsActions.CONNECT_DATASOURCE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, alerts.connectDatasourceFailure));
        });
    };
};

export const uploadfileppk = (datasourceConfig) => {
    const endpoint = `${appConstants.END_POINTS.connectors.uploadfiles}`;
    return (dispatch) => {
        dispatch(request(connectorsActions.CONNECT_UPLOADFILE_REQUEST));
        return apiService.postRequest(endpoint, datasourceConfig, true).then((response) => {

            dispatch(success(connectorsActions.CONNECT_UPLOADFILE_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(connectorsActions.CONNECT_UPLOADFILE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};


export const connectDataset = (datasetConfig) => {
    const endpoint = `${appConstants.END_POINTS.connectors.connectDataset}`;
    return (dispatch) => {
        dispatch(request(connectorsActions.CONNECT_DATASET_REQUEST));
        if (datasetConfig && datasetConfig.datasets && datasetConfig.datasets.length > 0) {
            return apiService.postRequest(endpoint, datasetConfig).then((response) => {
                dispatch(success(connectorsActions.CONNECT_DATASET_SUCCESS, response.data));
                return response.data;
            }, (error) => {
                dispatch(failure(connectorsActions.CONNECT_DATASET_FAILURE, error));
                dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
            });
        }
    };
};

export const connect_tableau = (datasetConfig) => {
    const endpoint = `${appConstants.END_POINTS.connectors.connectTableau}`;
    return (dispatch) => {
        dispatch(request(connectorsActions.CONNECT_DATASET_REQUEST));
        if (datasetConfig && datasetConfig.datasets && datasetConfig.datasets.length > 0) {
            return apiService.postRequest(endpoint, datasetConfig).then((response) => {
                dispatch(success(connectorsActions.CONNECT_DATASET_SUCCESS, response.data));
                return response.data;
            }, (error) => {
                dispatch(failure(connectorsActions.CONNECT_DATASET_FAILURE, error));
                dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
            });
        }
    };
};


export const getConnectedDatasets = (datasourceId, page = 1) => {
    const endpoint = `${appConstants.END_POINTS.connectors.getConnectedDatasets}/${datasourceId}/${page}`;
    return (dispatch) => {
        dispatch(request(connectorsActions.GET_CONNECTED_DATASETS_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(connectorsActions.GET_CONNECTED_DATASETS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(connectorsActions.GET_CONNECTED_DATASETS_FAILURE, error));
        });
    };
};

export const getRestAPIConnectedDatasets = (datasourceId) => {
    const endpoint = `${appConstants.END_POINTS.connectors.getRestapiConnectedDatasets}/${datasourceId}`;
    return (dispatch) => {
        dispatch(request(connectorsActions.GET_RESTAPI_CONNECTED_DATASETS_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(connectorsActions.GET_RESTAPI_CONNECTED_DATASETS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(connectorsActions.GET_RESTAPI_CONNECTED_DATASETS_FAILURE, error));
        });
    };
};

export const connectRestAPIDatasource = (datasourceConfig) => {
    const endpoint = `${appConstants.END_POINTS.connectors.connectRestApi}`;
    return (dispatch) => {
        dispatch(request(connectorsActions.CONNECT_RESTAPI_REQUEST));
        return apiService.postRequest(endpoint, datasourceConfig).then((response) => {
            dispatch(success(connectorsActions.CONNECT_RESTAPI_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(connectorsActions.CONNECT_RESTAPI_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const connectRestApiDataset = (datasetConfig) => {
    const endpoint = `${appConstants.END_POINTS.connectors.connectRestApiDataset}`;
    return (dispatch) => {
        dispatch(request(connectorsActions.CONNECT_DATASET_RESTAPI_REQUEST));
        return apiService.postRequest(endpoint, datasetConfig).then((response) => {
            dispatch(success(connectorsActions.CONNECT_DATASET_RESTAPI_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(connectorsActions.CONNECT_DATASET_RESTAPI_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const getSchema = (datasetConfig) => {
    const endpoint = `${appConstants.END_POINTS.connectors.getSchema}`;
    return (dispatch) => {
        dispatch(request(connectorsActions.GET_DATASET_SCHEMA_REQUEST));
        return apiService.postRequest(endpoint, datasetConfig).then((response) => {
            dispatch(success(connectorsActions.GET_DATASET_SCHEMA_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(connectorsActions.GET_DATASET_SCHEMA_FAILURE, error));
        });
    };
};


export const updateDatasourceProperties = (datasourceDetails) => {
    const endpoint = `${appConstants.END_POINTS.connectors.updateDatasourceProperties}`;
    return (dispatch) => {
        dispatch(request(connectorsActions.UPDATE_DATASOURCE_PROPERTIES_REQUEST));
        return apiService.postRequest(endpoint, datasourceDetails).then((response) => {
            dispatch(success(connectorsActions.UPDATE_DATASOURCE_PROPERTIES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(connectorsActions.UPDATE_DATASOURCE_PROPERTIES_FAILURE, error));
        });
    };
};


export const getConnectionViews = (datasourceId) => {
    const endpoint = `${appConstants.END_POINTS.connectors.getConnectionViews}/${datasourceId}`;
    return (dispatch) => {
        dispatch(request(connectorsActions.GET_DATASET_CONNECTION_VIEWS_COUNT_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(connectorsActions.GET_DATASET_CONNECTION_VIEWS_COUNT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(connectorsActions.GET_DATASET_CONNECTION_VIEWS_COUNT_FAILURE, error));
        });
    };
};


export const getLimitedDatasets = (datasetConfig, cancellationToken = null) => {
    const endpoint = `${appConstants.END_POINTS.connectors.getLimitedDatasets}`;
    return (dispatch) => {
        dispatch(request(connectorsActions.GET_LIMITED_DATASETS_REQUEST));
        return apiService.postRequest(endpoint, datasetConfig, false, cancellationToken).then((response) => {
            dispatch(success(connectorsActions.GET_LIMITED_DATASETS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(connectorsActions.GET_LIMITED_DATASETS_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};