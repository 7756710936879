const ChipSelectListStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    chip: {
        marginTop: 5,
        marginBottom: 5,
        '& .MuiChip-label': {
            maxWidth: 200
        }
    },
    tagContainer: {
        display: "flex",
        flexFlow: "row wrap",
        marginTop: 10,
        minHeight: 40,
        alignItems: "center"
    },
    tagInputContainer: {
        flex: 1,
        display: "inline-flex",
        flexWrap: "wrap"
    },
    addIcon: {
        // padding: 2, // height: 18 // marginTop: 8
    },
    addTags: {
        color: theme.palette.primary.dark,
        // paddingLeft: 7,
        cursor: 'pointer'
    },
    closeIcon: {
        width: 15,
        height: 15
    }
});

export default ChipSelectListStyles;