const CompleteNessStyle = (theme) => ({
    listContainer: {
        marginLeft: 10,
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    auditLogPaper: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    list: {
        borderBottom: `1px solid ${theme.palette.border.default}`
    },
    auditLogContainer: {
        minHeight: 192,
        height: 192,
        overflow: 'hidden',
        borderBottom: 'none !important',
        '& .MuiList-padding': {
            paddingTop: '0px',
            paddingBottom: '0px'
        },
        '& .MuiListItem-root': {
            paddingTop: '4px',
            paddingBottom: '5px'
        }
    },
    logUsername: {
        color: theme.palette.grey.default,
        marginRight: 5,
        fontWeight: 500
    },
    logTimeTxt: {
        fontStyle: 'italic',
        color: theme.palette.grey.light,
        fontSize: 12
    },
    logTxt: {
        color: theme.palette.grey.dark
    },
    card: {
        padding: "10px 15px",
        boxShadow: "0px 0px 6px #e1e5e6",
        height: "100%",
        backgroundColor: "#fff"
    },
    showAllBtn: {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        fontWeight: 500,
        '&:hover': {
            color: theme.palette.primary.dark
        }
    },
    alertNoResult: {
        height: '100%',
        '& img': {
            maxWidth: '48%',
            marginBottom: 2,
            paddingTop: 36
        }
    }
});

export default CompleteNessStyle;