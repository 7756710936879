const datasourceTypes = [
    {
        type: 'File',
        logo: require('../assets/images/sourceTypes/file.png')
    },
    {
        type: 'FTP',
        logo: require('../assets/images/sourceTypes/ftp.png')
    },
    {
        type: 'Sql',
        logo: require('../assets/images/sourceTypes/sqlserver.png')
    },
    {
        type: 'Mysql',
        logo: require('../assets/images/sourceTypes/mysql.png')
    },
    {
        type: 'Oracle',
        logo: require('../assets/images/sourceTypes/oracle.png')
    },
    {
        type: 'Salesforce',
        logo: require('../assets/images/sourceTypes/salesforce.png')
    },
    {
        type: 'Quickbase',
        logo: require('../assets/images/sourceTypes/quickbase.png')
    },
    {
        type: 'Rest',
        logo: require('../assets/images/sourceTypes/restapi.png')
    },
    {
        type: 'S3',
        logo: require('../assets/images/sourceTypes/s3.png')
    },
    {
        type: 'SFTP',
        logo: require('../assets/images/sourceTypes/sftp.png')
    },
    {
        type: 'Postgres',
        logo: require('../assets/images/sourceTypes/postgresql.png')
    },
    {
        type: 'Hadoop',
        logo: require('../assets/images/sourceTypes/hadoop.png')
    },
    {
        type: 'Snowflake',
        logo: require('../assets/images/sourceTypes/snowflake.png')
    },
    {
        type: 'MongoDB',
        logo: require('../assets/images/sourceTypes/mango.png')
    },
    {
        type: 'Kafka',
        logo: require('../assets/images/sourceTypes/kafka.png')
    },
    {
        type: 'Qlik',
        logo: require('../assets/images/sourceTypes/qlik.png')
    },
    {
        type: 'DeltaLake',
        logo: require('../assets/images/sourceTypes/deltalake.jpg')
    },
    {
        type: 'DBFS',
        logo: require('../assets/images/sourceTypes/dbfs.png')
    },
    {
        type: 'Delta Lake',
        logo: require('../assets/images/sourceTypes/deltalake.jpg')
    },
    {
        type: 'DB2',
        logo: require('../assets/images/sourceTypes/ibmdb.jpg')
    },
    {
        type: 'Tableau',
        logo: require('../assets/images/sourceTypes/tableau.png')
    },
    {
        type: 'Redshift',
        logo: require('../assets/images/sourceTypes/redshift.svg')
    },
    {
        type: 'AzuredataLake',
        logo: require('../assets/images/sourceTypes/adls.svg')
    }
];

export default datasourceTypes;