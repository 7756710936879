const Styles = (theme) => ({
    root: {
        height: '100%!important',
        fontSize: 13
    },
    flexContainset: {
        display: "flex",
        marginRight: 25,
        alignItems: 'center',
        maxWidth: '100%'
    },
    widthConset: {
        width: '100%'
    },
    highlight_source2: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    subtitle1: {
        fontSize: 10
    },
    horzspace: {
        flexGrow: 1
    },
    marginTopm8: {
        marginTop: -8
    },
    marginTop10: {
        marginTop: 10
    },
    marginTop15: {
        marginTop: 15
    },
    marginTop20: {
        marginTop: 20
    },
    marginTop30: {
        marginTop: 30
    },
    marginBottom5: {
        marginBottom: 5
    },
    marginBottom10: {
        marginBottom: 10
    },
    marginLeft15: {
        marginLeft: 15
    },
    marginLeft10: {
        marginLeft: 10
    },
    marginLeft20: {
        marginLeft: 20
    },
    marginRight25: {
        marginRight: 25
    },
    marginRight5: {
        marginRight: 5
    },
    paddingZero: {
        padding: '0px !important'
    },
    padding5: {
        padding: 5
    },
    padding6: {
        padding: 6
    },
    paddingRight12: {
        paddingRight: 12
    },
    padding15: {
        padding: 15
    },
    paddingLeft10: {
        paddingLeft: 10,
        fontSize: 13
    },
    paddingTop15: {
        paddingTop: 15
    },
    paddingBottom2: {
        padding: 2
    },
    paddingBottom4: {
        paddingBottom: 4
    },
    paddingBottom15: {
        paddingBottom: 15
    },
    paddingLR10: {
        paddingLeft: 10,
        paddingRight: 10
    },
    paddingLR30: {
        paddingLeft: 30,
        paddingRight: 30
    },
    boxedContainerW: {
        padding: 15,
        background: "#ffffff"
    },
    height32: {
        height: 32
    },
    heightAuto: {
        height: 'auto'
    },
    inlineFlex: {
        display: 'inline-flex'
    },
    flex: {
        display: 'flex'
    },
    flexFull: {
        display: 'flex',
        width: '100%'
    },
    flex1: {
        flex: '1'
    },
    relative: {
        position: 'relative'
    },
    height100p: {
        height: '100%'
    },
    width100: {
        width: '100%'
    },
    breakAll: {
        wordBreak: "break-word"
    },
    dimension: {
        width: 36,
        height: 32
    },
    inputOutline: {
        padding: '0px',
        width: '100%',
        '& .MuiInput-underline:before': {
            border: `1px solid ${theme.palette.grey.light}`,
            height: '100%'
        },
        '& .MuiInput-underline:after': {
            display: 'none'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent'
        },
        '& .Mui-focused:before': {
            border: `1px solid ${theme.palette.primary.dark} !important`
        },
        '& .Mui-focused': {
            borderColor: 'transparent'
        },
        '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: `transparent !important`
        },
        '& input': {
            height: 40,
            padding: '0px 10px',
            background: theme.palette.common.white
        },
        '& .MuiInput-underline:hover:not(.Mui-disabled)::before': {
            border: `1px solid ${theme.palette.grey.dark}`
        },
        '& .Mui-error:before': {
            border: '1px solid #F44336 !important'
        }
    },
    block: {
        display: 'block'
    },
    nopadding: {
        padding: '0px !important'
    },
    rightComponent: {
        padding: '0px 25px',
        width: 'calc(100% - 50px)',
        // height: 'calc(100vh - 26px)',
        overflow: 'hidden'
    },
    inlineButton: {
        fontFamily: theme.palette.textFont,
        fontSize: 13,
        color: theme.palette.grey.darkTxt
    },
    container: {
        height: '100%'
    },
    datasourceSection: {
        paddingTop: 20,
        height: '100%'
    },
    breadcrumb: {
        color: theme.palette.grey.dark,
        fontSize: 13
    },
    breadcrumbnew: {
        fontSize: 14,
        cursor: 'pointer'
    },
    datasourceTitle: {
        display: 'flex',
        alignItems: 'center',
        '& img': {
            marginRight: 7,
            verticalAlign: 'middle'
        }
    },
    datasourceTitleText: {
        fontSize: 24,
        color: theme.palette.grey.darkTxt
    },
    datasourceDetailIcons: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 50
        // marginTop: 10
    },
    datasourceAvatar: {
        margin: '0px 8px'
    },
    datasourceIcon: {
        // margin: '0px 8px',
        verticalAlign: 'middle',
        // cursor: 'pointer',
        marginRight: "8px !important"
    },
    datasourceDetailText: {
        color: theme.palette.grey.dark
    },
    datasourceTabContainer: {
        borderBottom: `1px solid ${theme.palette.grey.extraLight}`
    },
    datasetOverviewTable: {
        height: 'calc(100vh - 220px)',
        position: 'relative',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    scrollFix: {
        overflowX: 'hidden',
        overflowY: 'scroll'
    },
    card: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px 0px 6px #e1e5e6',
        // borderRadius: 2,
        padding: 15,
        position: 'relative',
        flexGrow: 1,
        alignItems: "center",
        height: '100%'
    },
    alertscard: {
        // minHeight: 230,
        height: 'calc(100% - 38px) !important'
    },
    cardCustom: {
        minHeight: 150
    },
    inlinetxt: {
        "& div": {
            "&:before": {
                borderBottom: `1px solid transparent !important`
            }
        },
        "& div:hover": {
            "&:before": {
                borderBottom: `1px solid ${theme.palette.grey.dark} !important`
            }
        }
    },
    headerTitleTxt: {
        '& input': {
            fontSize: 20,
            color: theme.palette.grey.darkTxt,
            fontFamily: theme.palette.titleFont,
            fontWeight: 500,
            // width: '230px',
            maxWidth: "900px",
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        fontSize: 20,
        color: theme.palette.grey.darkTxt,
        fontFamily: theme.palette.titleFont,
        fontWeight: 500,
        marginRight: 20
    },
    paddingLeft15: {
        paddingLeft: 15
    },
    disabledtxt: {
        color: `${theme.palette.grey.light} !important`
    },
    typeIcon: {
        width: 18,
        background: '#EDF2F4',
        marginRight: 10,
        color: '#5EB5EF',
        fontSize: 12,
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        fontWeight: 500
    },
    typeText: {
        fontSize: 13,
        minWidth: 80,
        textTransform: 'uppercase'
    },
    cancelButton: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.grey.light,
        borderRadius: 0,
        padding: '6px 18px',
        "&:hover": {
            backgroundColor: theme.palette.grey.light,
            boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important'
            // opacity: 0.6
        }
    },
    backButton: {
        fontFamily: theme.palette.titleFont,
        fontSize: 15,
        fontWeight: 600,
        color: theme.palette.grey.darkGrey,
        textAlign: 'left',
        letterSpacing: 0,
        '& .MuiButton-label': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& img': {
                marginRight: 5
            }
        },
        '&:hover': {
            '& .MuiButton-label': {
                color: theme.palette.primary.dark
            },
            '& .MuiButton-label:hover': {
                '& svg#backArrow': {
                    fill: `${theme.palette.primary.dark} !important`
                }
            }
        }
    },
    bodyContent: {
        height: '100%'
    },
    logout: {
        minWidth: 120,
        padding: '5px 10px'
    },

    logoutTxt: {
        color: theme.palette.grey.default
    },
    tabContainer: {
        overflowY: 'auto !important',
        overflowX: 'hidden',
        height: 'calc(100vh - 185px)',
        paddingRight: 10,
        position: 'relative'
    },
    tabProfileContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 'calc(100vh - 280px)',
        // paddingRight: 8,
        position: 'relative'
    },
    tabCurationContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 'auto',
        // paddingRight: 8,
        position: 'relative'
    },
    tabSection: {
        position: 'relative',
        height: 'calc(100vh - 200px)'
    },
    link: {
        color: theme.palette.grey.default,
        position: 'relative',
        marginRight: 5,
        '&:hover': {
            color: theme.palette.primary.dark,
            cursor: 'pointer',
            '&:hover:last-child': {
                color: `${theme.palette.grey.dark} !important`,
                cursor: 'default !important'
            }
        },
        '&:last-child': {
            color: `${theme.palette.grey.dark} !important`
        }
    },
    passwordAdornment: {
        color: theme.palette.grey.light,
        '&:hover': {
            color: 'rgba(0, 0, 0, 0.47)'
        }
    },
    marginBottom15: {
        marginBottom: 15
    },
    noPaddingLeft: {
        paddingLeft: '0px !important'
    },
    chipList: {
        position: 'relative',
        '& .chipremove': {
            display: 'none',
            position: 'absolute',
            top: 3,
            right: 7,
            borderRadius: 25,
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.border.default}`
        },
        '& .inlineChip': {
            top: '-6px',
            left: 18
        },
        '& .inlineClose': {
            top: '-6px',
            right: '-7px'
        },
        '&:hover .chipremove': {
            display: 'block',
            zIndex: 1
        },
        '& .MuiTypography-body1': {
            fontSize: 13
        }
    },
    chipIcon: {
        fontSize: 8,
        zIndex: 1,
        width: 16,
        height: 16
    },
    addIconDomain: {
        padding: 2,
        height: 18
        // marginTop: 5
    },
    closeIconDomain: {
        width: 15,
        height: 15
    },
    tableHeader: {
        fontWeight: 600
    },
    impactTxt: {
        fontSize: 12,
        color: theme.palette.grey.darkGrey
    },
    impactContainer: {
        marginLeft: 10,
        display: 'flex',
        alignItems: 'center'
    },
    sensitivityContainer: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0px 15px',
        cursor: 'default'
    },
    impactIcon: {
        color: theme.palette.chartColors.impactValid
    },
    addDomain: {
        color: theme.palette.primary.dark,
        // paddingLeft: 7,
        cursor: 'pointer'
    },
    buttonGroup: {
        '& .MuiButton-root': {
            border: `1px solid ${theme.palette.grey.light}`,
            backgroundColor: theme.palette.common.white,
            color: theme.palette.grey.main,
            fontSize: '13px !important',
            padding: 7,
            '&:hover': {
                boxShadow: 'none'
            },
            '&.active': {
                borderColor: theme.palette.primary.main,
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,

                '& svg>g>path, svg>g>circle, svg>g>ellipse': {
                    fill: theme.palette.common.white
                },

                '& svg>g>g': {
                    stroke: theme.palette.common.white
                }
            },
            '&:first-child': {
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
            },
            '&:last-child': {
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0
            }
        }
    },
    groupButton: {
        fontSize: '13px !important',
        '&:hover': {
            boxShadow: 'none'
        },
        '&.active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white
        }
    },


    //DataSourceInfo
    dataSetUparrow: {
        '& .MuiSvgIcon-root': {
            fontSize: '2rem !important'
        }
    },
    bgNone: {
        '&:hover': {
            backgroundColor: 'rgb(0 0 0 / 0%) !important',
            '& p': {
                color: theme.palette.primary.dark
            }
        },
        '& .MuiTouchRipple-root': {
            display: 'none !important'
        }
    },
    descriptionTooltip: {

    },
    //List Styles
    reportType: {
        marginRight: '10%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        cursor: 'pointer',
        marginTop: 6,
        color: theme.palette.grey.default,
        fontWeight: 400,
        '&:last-child': {
            marginRight: 0
        },
        '& img': {
            marginRight: 8
        },
        '&:hover': {
            '& img': {
                filter: 'invert(59%) sepia(65%) saturate(431%) hue-rotate(310deg) brightness(112%) contrast(225%) !important'
            }
        }
    },
    greyText: {
        "& div": {
            "&:before": {
                borderBottom: `1px solid ${theme.palette.grey.default} !important`
            }
        },
        "& div:hover": {
            "&:before": {
                borderBottom: `1px solid ${theme.palette.grey.default} !important`
            }
        },
        "& input": {
            fontSize: 13
        },
        '& .MuiSelect-selectMenu': {
            fontSize: 13
        }
    },

    //ListView
    listViewContainer: {
        height: 'calc(100vh - 242px) !important',
        overflowY: 'auto !important',
        overflowX: 'hidden !important'
    },
    focusbg: {
        '& .Mui-focused': {
            background: "#ededed !important"
        },
        '&:hover': {
            background: "#ededed !important"
        }
    },
    inlineBlock: {
        display: "inline-block"
    },
    displayFlex: {
        display: "flex"
    },
    marginRight10: {
        marginRight: 10
    },
    overviewtab: {
        height: 'calc(100vh - 160px)',
        overflowY: 'auto'
    },
    dashboardHeaderContainer: {
        marginTop: 26,
        marginBottom: 8,
        '& .MuiTypography-h2': {
            fontSize: 24
        }
    },
    align: {
        textAlign: 'center'
    },
    dashboardContainer: {
        height: 'calc(100vh - 130px)',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    sidePanelHeader: {
        height: 50,
        padding: '0 15px',
        borderBottom: `1px solid ${theme.palette.grey.extraLight}`,
        marginBottom: 12
    },
    panelNotification: {
        height: 22,
        width: 22,
        backgroundColor: theme.palette.grey.light,
        borderRadius: 50,
        fontSize: 10,
        color: theme.palette.background.paper,
        marginLeft: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    sidePanelContainer: {
        maxWidth: 300,
        flexBasis: 300,
        transition: 'all 0.5s',
        position: 'relative',
        left: '-350px'
    },
    sidePanelContainerActive: {
        left: '-76px !important'
    },
    rightPanelActiveContainer: {
        maxWidth: '100%',
        flexBasis: '100%'
        // transition: 'all 0.5s',
    },
    rightPanelContainer: {
        // transition: 'all 0.5s',
        maxWidth: `calc(100% - 300px)`,
        flexBasis: `calc(100% - 300px)`
        // alignContent: 'flex-end'
    },
    marginRight15: {
        marginRight: 15
    },
    actionItemListContainer: {
        height: 'calc(100vh - 220px)',
        overflowY: 'auto',
        marginTop: 15,
        paddingRight: 5
    },
    paddingTop20: {
        paddingTop: 20
    },
    cardSection: {
        flexBasis: '19%'
    },
    zIndex1: {
        zIndex: 1
    },
    fullScreen: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        height: '100%'
    },
    driftIcon: {
        width: '8px'
    },
    noDataContainer: {
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    noDataImg: {
        minWidth: 200
    },
    cursor: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.secondary.main
        }
    },
    tooltip: {
        background: theme.palette.background.paper,
        border: `1px solid rgba(26,54,126,0.125)`,
        boxShadow: '0 4px 25px 0 rgba(0,0,0,.1)',
        padding: 5,
        fontSize: 12,
        '& span': {
            marginLeft: 5
        }
    },
    SensitivityMuiSlider: {
        '& .MuiSlider-thumb': {
            '&:hover': {
                boxShadow: '0px 0px 0px 6px rgba(105, 201, 251, 0.16) !important'
            }
        },
        '& .MuiSlider-active': {
            boxShadow: '0px 0px 0px 14px rgba(105, 203, 251, 0.16) !important'
        }
    },
    includeSearchContainer: {
        position: 'relative'
    },
    includeSearchIcon: {
        position: 'absolute',
        top: 1,
        right: 3,
        padding: 9
    },
    includeDeleteIcon: {
        position: 'absolute',
        top: 1,
        right: 3,
        padding: 6
    },
    dqSearchBox: {
        transitionDuration: '500ms',
        transition: 'width .55s ease',
        animation: 'slide-left180 0.4s ease',
        width: '180px'
    },
    searchTextbox: {
        width: '180px !important',
        // marginTop: 8,
        '& .MuiInput-underline:before': {
            height: '30px !important'
        },
        '& input': {
            height: '30px !important',
            fontSize: 13
        }
    },
    profileScore: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    cursorAuto: {
        cursor: 'auto'
    },
    tag: {
        fontWeight: 500,
        padding: 10,
        backgroundColor: theme.palette.background.container
    },
    iconButtonSmall: {
        width: 35,
        height: 35,
        padding: 0
    },
    boldText: {
        fontWeight: 500
    },
    noResultFound: {
        height: "50%",
        width: "auto",
        position: "absolute",
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        '& .MuiTypography-root': {
            color: 'gray',
            overflowY: 'hidden'
        }
    },
    marginLeft5: {
        marginLeft: 5
    },
    overflow: {
        overflow: 'auto'
    },
    displayContents: {
        display: 'contents'
    },
    capitalize: {
        textTransform: 'capitalize'
    },
    minWidth200: {
        minWidth: '200px'
    },
    addIconButton: {
        padding: 5,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: `${theme.palette.primary.dark} !important`
        },
        '& .MuiSvgIcon-root': {
            fill: '#fff'
        }
    },
    smallIcon: {
        fontSize: 12,
        '& .MuiSvgIcon-root': {
            width: 16,
            height: 16
        }
    },
    visibilityHidden: {
        visibility: 'hidden',
        filter: 'invert(59%) sepia(65%) saturate(431%) hue-rotate(320deg) brightness(112%) contrast(225%)'
    },
    infoIcon: {
        color: `${theme.palette.secondary.main} !important`
    },
    crumbIcon: {
        background: `${theme.palette.primary.main} !important`,
        width: 15,
        height: 15,
        padding: 3,
        borderRadius: 5
    },
    flexCol: {
        display: 'flex',
        alignItems: 'center',
        '& span': {
            paddingLeft: 5
        }
    },
    errorTxt: {
        color: '#f44336'
    },
    warningIcon: {
        color: theme.palette.primary.main
    },
    auditLogAlertContainer: {
        '& .auditLogContainer': {
            height: '100%'
        },
        '& .auditLogCard': {
            height: 'calc(100% - 38px)'
        }
    },
    anchorLink: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.dark
        }
    },
    bgWhite: {
        '& th': {
            backgroundColor: '#FFFFFF'
        }
    },
    chipSection: {
        padding: 5,
        border: `1px solid ${theme.palette.border.default}`,
        backgroundColor: theme.palette.border.default,
        borderRadius: 50,
        height: 28,
        '& p': {
            maxWidth: 60,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: 12
        }
    },
    inline: {
        display: 'inline'
    },
    activeCard: {
        color: theme.palette.secondary.main
    },
    cardMultiSection: {
        flexBasis: '14%'
    },
    cardMultiSections: {
        flexBasis: '12%'
    },
    chipStyles: {
        position: 'relative',
        '&:hover svg': {
            display: 'block !important'
        }
    },
    oneLineEllip: {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    imagePreview: {
        width: "100%",
        backgroundColor: theme.palette.grey.exteremeLight,
        padding: "35px 50px",
        marginTop: 20,
        textAlign: "center",
        overflow: "auto",
        '& img': {
            minWidth: "100%"
        }
    },
    itemSubmitBtnDiv: {
        textAlign: "right",
        marginTop: "7px"
    },
    itemoverviewPanel: {
        marginTop: 20,
        height: 'calc(100vh - 155px)',
        overflowY: 'auto'
    },
    itemoverviewCard: {
        height: 'auto',
        marginBottom: 15
    },
    notificationCardTile: {
        '& .loader': {
            width: 'calc(100% - 8px)',
            height: 'calc(100% - 8px)',
            top: 4,
            left: 4
        }
    },
    standardTable: {
        border: `1px solid ${theme.palette.grey.extraLight}`,
        tableLayout: 'auto',
        borderCollapse: 'seperate',
        textTransform: 'capitalize',
        // borderBottom: `1px solid ${theme.palette.grey.extraLight}`, // borderLeft: 'none', // borderRight: 'none',
        '& th': {
            backgroundColor: '#f4f6f9',
            padding: '10px 16px',
            whiteSpace: 'nowrap',
            maxWidth: '600px !important'
        },
        '& td': {
            padding: '10px 16px',
            maxWidth: '600px !important'
        }
    },
    standardContainer: {
        color: 'rgba(0, 0, 0, 0.87)',
        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        backgroundColor: '#fff',
        padding: '0px 15px'
    },
    passwordBox: {
        '& .adornment': {
            color: theme.palette.grey.light,
            '&:hover': {
                color: 'rgba(0, 0, 0, 0.47)'
            }
        }
    },
    rowTopAlign: {
        verticalAlign: 'top'
    },
    noPointer: {
        cursor: 'default'
    },
    calendarIcon: {
        fill: theme.palette.primary.main
    },
    tableWrapperStyle: {
        overflow: 'auto',
        maxWidth: '100%'
    },
    tableStyle: {
        '& .MuiTableCell-root': {
            maxWidth: 900
        },
        '& .MuiInputBase-input': {
            maxWidth: 890
        },
        '& .MuiTableCell-head': {
            whiteSpace: "nowrap"
        },
        '& .MuiLinearProgress-root': {
            minWidth: 50
        },
        '@media only screen and (min-width: 1900px)': {
            '& .MuiTableCell-root': {
                maxWidth: '1200px !important'
            }
        }
    },
    designTable: {
        border: `1px solid ${theme.palette.grey.extraLight}`,
        borderCollapse: 'seperate !important',
        '& .MuiTableCell-head': {
            background: `${theme.palette.background.default} !important`,
            borderBottom: `1px solid ${theme.palette.grey.extraLight}`
        },
        '& .MuiTableCell-body': {
            borderBottom: `1px solid ${theme.palette.grey.extraLight}`
        },
        '& .MuiTableRow-root:last-child .MuiTableCell-body': {
            borderBottom: `none !important`
        },
        '& .MuiTableRow-root': {
            border: 'none !important'
        }
    },
    fixedChipWidth: {
        '& .MuiChip-root': {
            maxWidth: '200px !important'
        }
    },
    scrollWidth: {
        width: 'calc(100% - 8px)'
    },
    textRight: {
        textAlign: 'right'
    },
    noDataText: {
        flexDirection: 'column',
        '& img': {
            height: '50%',
            marginBottom: 20
        }
    },
    horizontalOverflowScroll: {
        width: '100%',
        overflowX: 'auto',
        height: 'calc(100vh - 150px)'
    },
    reportContainer: {
        height: 'calc(100vh - 100px)',
        display: 'flex',
        alignItems: 'flex-start',
        background: "#fff",
        padding: 15
    },
    textoverflow: {
        minWidth: '135px !important',
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    flexLast: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& td p': {
            width: 'auto !important'
        }
    },
    flexSpaceBetween: {
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    minWidth110: {
        minWidth: 110
    },
    executionTitle: {
        padding: '12px 15px 0px 27px',
        '& .MuiIconButton-root': {
            color: theme.palette.primary.main
        }
    },
    sensitiveIc: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 5,
        padding: "5px 10px",
        color: "rgba(255, 255, 255)",
        display: 'flex',
        '& svg': {
            marginRight: 5
        }
    },
    dqscoreIc: {
        backgroundColor: theme.palette.grey.extraLight,
        borderRadius: 5,
        padding: "5px 10px",
        // color: "rgba(255, 255, 255, 0.8)",
        display: 'flex',
        '& svg': {
            marginRight: 5
        }
    },
    valueText: {
        fontWeight: "bold",
        color: theme.palette.grey.default,
        paddingLeft: 5,
        fontSize: 13
    },
    minHeight50: {
        minHeight: 50
    },
    curserPointer: {
        cursor: 'default'
    },
    collapseIcon: {
        position: 'absolute',
        right: 0,
        top: '50%',
        'border-radius': '0px',
        background: '#dddddd',
        width: '15px',
        height: '60px',
        marginTop: '-20px'
    },
    assigneewidth: {
        minWidth: '50px'
    },
    ribbon: {
        position: "absolute",
        top: -13,
        left: -12,
        width: 25,
        transform: 'rotate(-45deg)',
        background: theme.palette.primary.main,
        transformOrigin: 0,
        height: 34
        // borderRadius: "30px"
    },
    ribbonPosition: {
        position: "relative"
    },
    governanceRibbon: {
        position: "absolute",
        top: 0,
        left: 0,
        width: 0,
        height: 0,
        borderRight: '20px solid transparent',
        borderTop: '20px solid #F38080'
    },
    widthIncrease: {
        '& .MuiSelect-selectMenu': {
            minWidth: '60px'
        }
    },
    scoringValuePicker: {
        display: 'flex',
        alignItems: 'center',
        border: `1px solid ${theme.palette.grey.light}`
        // margin: 4
    },
    colorPicker: {
        padding: '6px 15px',
        background: theme.palette.grey.light,
        position: 'relative',
        cursor: 'pointer',
        '& svg': {
            width: '14px',
            height: '20px'
        },
        '& .typeColor': {
            width: '100%',
            border: 'none',
            height: '100%',
            display: 'block',
            opacity: 0,
            position: 'absolute',
            top: 0,
            right: 0,
            cursor: 'pointer'
        }
    },
    rangeFields: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiOutlinedInput-root': {
            height: '2rem !important',
            fontSize: '13px'
        },
        '& .MuiOutlinedInput-input': {
            padding: '10px 8px',
            textAlign: 'center'
        },
        '& .MuiInputAdornment-root .MuiButtonBase-root': {
            padding: '2px'
        },
        '& fieldset': {
            borderColor: 'transparent !important'
        },
        '& .Mui-focused fieldset': {
            border: 'none !important'
        },
        '& .MuiInputAdornment-root': {
            marginLeft: 0
        },
        '& .rangeHypen': {
            marginLeft: 15,
            marginRight: 15
        }
    },
    txteditorContainer: {
        '&: hover': {
            borderBottom: `1px solid ${theme.palette.primary.dark}`
        },
        '& .ck.ck-editor__editable_inline': {
            maxHeight: '61px',
            paddingLeft: 0,
            height: 61,
            overflow: 'auto',
            color: `${theme.palette.grey.dark}`,
            fontSize: 11,
            borderBottom: `1px solid ${theme.palette.grey.light}`,
            scrollbarColor: `#9EAEB4 ${theme.palette.background.default}`,
            scrollbarWidth: 'thin',
            "&::-webkit-scrollbar": {
                height: '8px',
                backgroundColor: theme.palette.background.default,
                width: '8px',
                zIndex: 9999
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: '#9EAEB4',
                borderRadius: '4px',
                zIndex: 9999
            }
        },
        '& .ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused': {
            outline: 'none !important',
            border: 'none !important',
            borderBottom: `1px solid ${theme.palette.grey.dark} !important`,
            boxShadow: 'none !important'
        },
        '& .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred': {
            outline: 'none !important'
        },
        '& .ck-content p': {
            wordBreak: 'break-all',
            fontSize: 14,
            color: `${theme.palette.grey.dark}`,
            marginTop: '0 !important',
            marginBottom: 3
        },
        '& .ck.ck-content.ck-editor__editable.ck-rounded-corners.ck-editor__editable_inline.ck-blurred:hover': {
            borderBottom: `1px solid ${theme.palette.grey.dark} !important`
        }
    },
    txteditor: {
        // borderBottom: `1px solid ${theme.palette.primary.default}`
    },
    childFullWith: {
        '& > div': {
            width: '100%'
        }
    }
});

export default Styles;