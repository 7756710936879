import { lineageActions } from '../constants/actionTypes/lineageActionTypes';

const initialState = {
    graphData: {},
    sourceCode: {},
    type: ''
};

const lineageReducer = (state = initialState, action) => {
    switch (action.type) {
        case lineageActions.GET_LINEAGE_GRPAH_REQUEST: return {
            ...state,
            type: action.type
        }; case lineageActions.GET_LINEAGE_GRPAH_SUCCESS: return {
            ...state,
            graphData: action.responseData,
            type: action.type
        }; case lineageActions.GET_LINEAGE_GRPAH_FAILURE: return {
            ...state,
            type: action.type
        };
        case lineageActions.GET_SOURCECODE_REQUEST: return {
            ...state,
            type: action.type
        }; case lineageActions.GET_LINEAGE_ENTRY_SUCCESS: return {
            ...state,
            sourceCode: action.responseData,
            type: action.type
        }; case lineageActions.GET_LINEAGE_ENTRY_FAILURE: return {
            ...state,
            type: action.type
        };
        case lineageActions.CLEAR_LINEAGE_GRPAH: return {
            graphData: {},
            type: action.type
        };
        default: return state;
    }
};

export default lineageReducer;