import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, withStyles, Typography, IconButton, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import DatasetStyles from '../../DatasetStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';
import TextBox from '../../../TextBox/TextBox.jsx';
import ValuesListItem from '../ValuesListItem.jsx';
import { updateProperties } from '../../../../actions/datasetActions';
import TileViewStyles from './TileViewStyles.jsx';
import Localization from '../../../../constants/localization';
import PatternInputEditor from '../../../PatternInput/PatternInputEditor.jsx';
import { DatePicker, TimePicker, DateTimePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import { toggleDatePicker } from '../../../../actions/commonActions.js';
import ToolTipComponent from '../../../Tooltip/Tooltip.jsx';

const AttributeValues = (props) => {
    const { classes, attribute, theme, isEditable, updatePropertiesList, isDeletePermission, isEditPermission } = props;
    const dispatch = useDispatch();
    const datasetId = useSelector(({ dataset }) => dataset.selectedDatasetId);
    const [isChanged, setInputChanged] = useState(false);
    const [visiblePatternDialog, setVisiblePatternDialog] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedPattern, setSelectedPattern] = useState(null);
    const [anchorEditorEl, setAnchorEditorEl] = useState(null);
    const [property, setProperty] = useState({
        patterns: [],
        'min_length': '',
        'max_length': '',
        min: '',
        max: '',
        enum: {}
    });
    const [trackEnumUpdate, setEnumUpdate] = useState(false);
    // const attributeMetadata = useSelector(({ datasource }) => datasource.attributeMetadata);

    const showAddPatternDialog = useCallback((event, index) => {
        setAnchorEditorEl(event.target);
        if (index === -1) {
            setSelectedPattern({
                name: '',
                pattern: '',
                isvalid: true,
                sense: false
            });
        } else {
            const patterns = [...property.patterns];
            setSelectedPattern({ ...patterns[index] });
        }
        setSelectedIndex(index);
        setVisiblePatternDialog(true);
    }, [property]);

    const hideAddPatternDialog = () => {
        setVisiblePatternDialog(false);
        setSelectedPattern({
            name: '',
            pattern: '',
            isvalid: true,
            sense: false
        });
        setSelectedIndex(null);
    };

    const onHandleChange = (property, value) => {
        selectedPattern[property] = value;
        /*
         * if (property === "isvalid") {
         *     selectedPattern.sense = value;
         * }
         */

        setSelectedPattern({ ...selectedPattern });
    };

    const addPattern = useCallback(() => {
        const patterns = property.patterns;

        if (selectedIndex === -1) {
            patterns.splice(0, 0, selectedPattern);
        } else {
            patterns[selectedIndex] = selectedPattern;
        }
        setProperty({ ...property, patterns: [...patterns] });
        setInputChanged(true);
        hideAddPatternDialog();
    }, [property, selectedIndex, selectedPattern]);

    const removePattern = useCallback((index) => {
        const patterns = property.patterns;
        patterns.splice(index, 1);
        setProperty({ ...property, patterns: [...patterns] });
        setInputChanged(true);
    }, [property]);

    const addEnum = useCallback(() => {
        const enums = property.enum.values;
        if (enums && enums.length > 0 && enums[0].value.length <= 0) {
            return;
        }
        const enumeration = {
            value: '',
            isvalid: true,
            sense: false
        };

        enums.splice(0, 0, enumeration);
        property.enum.values = [...enums];
        setProperty({ ...property });
        setInputChanged(true);
        if (!trackEnumUpdate) {
            setEnumUpdate(true);
        }
    }, [property, trackEnumUpdate]);

    const removeEnum = useCallback((index) => {
        const enums = property.enum.values;
        enums.splice(index, 1);
        property.enum.values = [...enums];
        setProperty({ ...property });
        setInputChanged(true);
        if (!trackEnumUpdate) {
            setEnumUpdate(true);
        }
    }, [property, trackEnumUpdate]);

    const updateEnum = useCallback((propertyName, value, index) => {
        if (value.trim() !== "") {
            const enums = property.enum.values;
            enums[index][propertyName] = value.trim();
            property.enum.values = [...enums];
            setProperty({ ...property });
            setInputChanged(true);
            if (!trackEnumUpdate) {
                setEnumUpdate(true);
            }
        }
    }, [property, trackEnumUpdate]);

    const updateValues = useCallback((propertyName, value) => {
        if (!propertyName) {
            return;
        }
        const regex = /^[0-9]*$/;
        const minRegex = /^-?\d*[.,-]?\d*$/;
        if ((attribute.datatype !== "Date" && attribute.datatype !== "Time" && attribute.datatype !== "DateTime") && (propertyName === "min" || propertyName === "max") && !minRegex.test(value)) {
            return false;
        } else if ((propertyName === "min_length" || propertyName === "max_length") && !regex.test(value)) {
            return false;
        }
        property[propertyName] = value;
        setInputChanged(true);
        setProperty({ ...property });
    }, [attribute.datatype, property]);

    useEffect(() => {
        setProperty({
            patterns: attribute.patterns ? [...attribute.patterns].sort((a, b) => a && b && (!isNaN(a.count) && !isNaN(b.count) && a.count > b.count ? -1 : 1)) : [],
            'min_length': attribute.min_length > -1 ? `${attribute.min_length}` : '',
            'max_length': attribute.max_length > -1 ? `${attribute.max_length}` : '',
            min: attribute.min ? `${attribute.min}` : '',
            max: attribute.max ? `${attribute.max}` : '',
            enum: attribute.enum ? Object.assign(attribute.enum, {}) : {}
        });
    }, [attribute]);

    const compareProperties = () => {
        const changedProperties = [];
        if (property.min_length !== attribute.min_length) {
            changedProperties.push("min_length");
        }
        if (property.max_length !== attribute.max_length) {
            changedProperties.push("max_length");
        }
        if (property.min !== attribute.min) {
            changedProperties.push("min");
        }
        if (property.max !== attribute.max) {
            changedProperties.push("max");
        }
        if (trackEnumUpdate) {
            changedProperties.push("enum");
        }
        if (JSON.stringify(property.patterns) !== JSON.stringify(attribute.patterns)) {
            changedProperties.push("patterns");
        }
        return changedProperties;

    };

    const updateAttributeProps = () => {
        if (isChanged) {
            property.enum.values = property.enum.values.filter((data) => data.value && data.value.trim() !== "");
            property.enum.on = Boolean((property.enum.values.length > 0) || property.enum.ref_id);
            property.patterns = property.patterns.filter((data) => data.name && data.name.trim() !== "");
            const changedProperties = compareProperties();
            updatePropertiesList({ name: attribute.name, value: property });
            dispatch(updateProperties(datasetId, { id: attribute.id, attribute: attribute.name, value: property, 'changed_properties': changedProperties }));
            setInputChanged(false);
            setEnumUpdate(false);
        }
    };

    /*
     * const attributeData = (name, type) => {
     *   const attribute = attributeMetadata ? attributeMetadata.find((attribute) => attribute.attribute_name === name) : {};
     *   const metadata = attribute && attribute.metadata ? JSON.parse(attribute.metadata) : {};
     *   return metadata[type] !== null && metadata[type] !== undefined ? metadata[type].toString() : null;
     * };
     */

    return (
        <Grid container direction="row" wrap="nowrap" spacing={4} onMouseLeave={() => updateAttributeProps()}>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Grid container direction="column" justify="flex-start" wrap="nowrap">
                    <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap" className={classes.valuesTitleContainer}>
                        <Typography component="h6" variant="h6">
                            {'Pattern'}
                        </Typography>
                        {
                            (isEditable || isEditPermission) &&
                            <ToolTipComponent title="Add Pattern" arrow>
                                <IconButton onClick={(event) => showAddPatternDialog(event, -1)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                            <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                                <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                                <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                            </g>
                                        </g>
                                    </svg>
                                </IconButton>
                            </ToolTipComponent>
                        }

                        {
                            visiblePatternDialog &&
                            <PatternInputEditor
                                pattern={selectedPattern}
                                attributeName={attribute ? attribute.attribute_name : ''}
                                open={visiblePatternDialog}
                                anchorEl={anchorEditorEl}
                                onSave={() => addPattern()}
                                onClose={() => hideAddPatternDialog()}
                                onChange={(property, value) => onHandleChange(property, value)} />
                        }
                    </Grid>
                    <Typography variant="body2" className={classes.propertyDescritionEllipsis}>
                        {Localization.properties.value.pattern}
                    </Typography>

                    <Grid container className={classNames(classes.patternListContainer)} style={{ overflow: 'auto' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {
                                property.patterns.map((data, index) =>
                                    data &&
                                    <ValuesListItem
                                        key={`patternList${index}`}
                                        isPatternList
                                        displayKey={'name'}
                                        index={index}
                                        data={data}
                                        count={data.count}
                                        onDelete={(index) => removePattern(index)}
                                        onUpdate={(event, index) => showAddPatternDialog(event, index)}
                                        disabled={!isEditable}
                                        isEditPermission={isEditPermission}
                                        isDeletePermission={isDeletePermission} />
                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Grid container direction="column" justify="flex-start" wrap="nowrap" spacing={3}>
                    <Grid item>
                        <Grid container direction="column" justify="flex-start" wrap="nowrap">
                            <Typography component="h6" variant="h6">
                                {'Length'}
                            </Typography>
                            <Typography variant="body2" className={classes.propertyDescritionEllipsis}>
                                {Localization.properties.value.length}
                            </Typography>
                            <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                                <ToolTipComponent title={property.min_length ? property.min_length : ''} arrow>
                                    <Grid item className={classNames(classes.valuesTextboxContainer, classes.sliderInput, classes.propertyComponents)}>
                                        <TextBox placeholder="Min"
                                            name="min_length"
                                            id="min_length"
                                            value={property.min_length ? property.min_length : ''}
                                            onChange={(event) => updateValues(event.target.name, event.target.value)}
                                            // onBlur={(event) => updateProperty(event.target.name, event.target.value)}
                                            className={classNames(classes.inputOutline)}
                                            disabled={!isEditable && !isEditPermission} />
                                    </Grid>
                                </ToolTipComponent>

                                <Grid item>
                                    <Typography variant="body1" className={classes.rangeSeparator}>
                                        {'-'}
                                    </Typography>
                                </Grid>
                                <ToolTipComponent title={property.max_length ? property.max_length : ''} arrow>
                                    <Grid item className={classNames(classes.valuesTextboxContainer, classes.sliderInput, classes.propertyComponents)}>
                                        <TextBox placeholder="Max"
                                            name="max_length"
                                            id="max_length"
                                            value={property.max_length ? property.max_length : ''}
                                            onChange={(event) => updateValues(event.target.name, event.target.value)}
                                            // onBlur={(event) => updateProperty(event.target.name, event.target.value)}
                                            className={classNames(classes.inputOutline)}
                                            disabled={!isEditable && !isEditPermission} />
                                    </Grid>
                                </ToolTipComponent>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item style={{ paddingBottom: 0 }}>
                        <Grid container direction="column" justify="flex-start" wrap="nowrap">
                            <Typography component="h6" variant="h6">
                                {'Range'}
                            </Typography>
                            <Typography variant="body2" className={classes.propertyDescritionEllipsis}>
                                {Localization.properties.value.range}
                            </Typography>
                            <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                                {
                                    (attribute.datatype !== "Date") && (attribute.datatype !== "Time") && (attribute.datatype !== "DateTime") && (attribute.datatype !== "DateTimeOffset") &&
                                    <ToolTipComponent title={property.min ? property.min : ''} arrow>
                                        <Grid item className={classNames(classes.valuesTextboxContainer, classes.sliderInput, classes.propertyComponents)}>
                                            <TextBox placeholder="Min"
                                                name="min"
                                                id="min"
                                                value={property.min ? property.min : ''}
                                                onChange={(event) => updateValues(event.target.name, event.target.value)}
                                                // onBlur={(event) => updateProperty(event.target.name, event.target.value)}
                                                className={classNames(classes.inputOutline)}
                                                disabled={!isEditable && !isEditPermission} />
                                        </Grid>
                                    </ToolTipComponent>
                                }
                                {
                                    (attribute.datatype === "Date") &&
                                    <DatePicker
                                        autoOk
                                        variant="inline"
                                        label="min"
                                        format="MM/DD/YYYY"
                                        value={property.min ? moment(property.min).format('MM/DD/YYYY') : Date.now()}
                                        onChange={(date) => updateValues('min', moment(date).format('MM/DD/YYYY'))}
                                        maxDate={property.max ? moment(property.max).format('MM/DD/YYYY') : Date.now()}
                                        onOpen={() => dispatch(toggleDatePicker(true))}
                                        onClose={() => dispatch(toggleDatePicker(false))}
                                        // onBlur={(event) => updateProperty(event.target.name, event.target.value)}
                                        className={classNames(classes.inputOutline, classes.textBox)}
                                        disabled={!isEditable && !isEditPermission}
                                    />
                                }
                                {
                                    (attribute.datatype === "Time") &&
                                    <TimePicker
                                        ampm={false}
                                        label="min"
                                        format="HH:mm"
                                        value={property.min ? moment(property.min).format("HH:mm") : Date.now()}
                                        onChange={(time) => updateValues('min', moment(time).format("HH:mm"))}
                                        // onBlur={(event) => updateProperty(event.target.name, event.target.value)}
                                        className={classNames(classes.inputOutline)}
                                        disabled={!isEditable && !isEditPermission}
                                    />
                                }
                                {
                                    (attribute.datatype === "DateTime" || attribute.datatype === "DateTimeOffset") &&
                                    <DateTimePicker
                                        autoOk
                                        renderInput={(props) => <TextField {...props} />}
                                        label="min"
                                        format="MM/DD/YYYY HH:mm"
                                        minDateMessage=""
                                        value={property.min ? moment(property.min).format("MM/DD/YYYY HH:mm") : Date.now()}
                                        onChange={(time) => updateValues('min', moment(time).format("MM/DD/YYYY HH:mm"))}
                                        // onBlur={(event) => updateProperty(event.target.name, event.target.value)}
                                        className={classNames(classes.inputOutline)}
                                        disabled={!isEditable && !isEditPermission}
                                    />
                                }
                                <Grid item>
                                    <Typography variant="body1" className={classes.rangeSeparator}>
                                        {'-'}
                                    </Typography>
                                </Grid>
                                {/* <Grid item className={classNames(classes.valuesTextboxContainer, classes.sliderInput, classes.propertyComponents)}> */}
                                {
                                    (attribute.datatype !== "Date") && (attribute.datatype !== "Time") && (attribute.datatype !== "DateTime") && (attribute.datatype !== "DateTime") && (attribute.datatype !== "DateTimeOffset") &&
                                    <ToolTipComponent title={property.max ? property.max : ''} arrow>
                                        <Grid item className={classNames(classes.valuesTextboxContainer, classes.sliderInput, classes.propertyComponents)}>
                                            <TextBox placeholder="Max"
                                                name="max"
                                                id="max"
                                                value={property.max ? property.max : ''}
                                                onChange={(event) => updateValues(event.target.name, event.target.value)}
                                                // onBlur={(event) => updateProperty(event.target.name, event.target.value)}
                                                className={classNames(classes.inputOutline)}
                                                disabled={!isEditable && !isEditPermission} />
                                        </Grid>
                                    </ToolTipComponent>
                                }
                                {
                                    (attribute.datatype === "Date") &&
                                    <DatePicker
                                        autoOk
                                        variant="inline"
                                        label="max"
                                        format="MM/DD/YYYY"
                                        maxDateMessage=""
                                        minDateMessage=""
                                        maxDate={moment('12/31/9999').format('MM/DD/YYYY')}
                                        minDate={property.min ? moment(property.min).format('MM/DD/YYYY') : Date.now()}
                                        value={property.max ? moment(property.max).format('MM/DD/YYYY') : Date.now()}
                                        onChange={(date) => updateValues('max', moment(date).format('MM/DD/YYYY'))}
                                        onOpen={() => dispatch(toggleDatePicker(true))}
                                        onClose={() => dispatch(toggleDatePicker(false))}
                                        // onBlur={(event) => updateProperty(event.target.name, event.target.value)}
                                        className={classNames(classes.inputOutline, classes.textBox)}
                                        disabled={!isEditable && !isEditPermission}
                                    />
                                }
                                {
                                    (attribute.datatype === "Time") &&
                                    <TimePicker
                                        ampm={false}
                                        label="max"
                                        format="HH:mm"
                                        value={property.max ? moment(property.max).format("HH:mm") : Date.now()}
                                        onChange={(time) => updateValues('max', moment(time).format("HH:mm"))}
                                        // onBlur={(event) => updateProperty(event.target.name, event.target.value)}
                                        className={classNames(classes.inputOutline)}
                                        disabled={!isEditable && !isEditPermission}
                                    />
                                }
                                {
                                    (attribute.datatype === "DateTime" || attribute.datatype === "DateTimeOffset") &&
                                    <DateTimePicker
                                        autoOk
                                        renderInput={(props) => <TextField {...props} />}
                                        label="max"
                                        format="MM/DD/YYYY HH:mm"
                                        value={property.max ? moment(property.max).format("MM/DD/YYYY HH:mm") : Date.now()}
                                        onChange={(time) => updateValues('max', moment(time).format("MM/DD/YYYY HH:mm"))}
                                        // onBlur={(event) => updateProperty(event.target.name, event.target.value)}
                                        className={classNames(classes.inputOutline)}
                                        disabled={!isEditable && !isEditPermission}
                                    />
                                }
                                {/* </Grid> */}
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <Grid item>
                        <Grid container direction="column" justify="flex-start" wrap="nowrap">
                            <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                                {
                                    attributeData(attribute.name ? attribute.name : '', "precision") &&
                                    <Grid item className={classNames(classes.valuesTextboxContainer, classes.sliderInput, classes.propertyComponents)}>
                                        <Typography component="h6" variant="h6">
                                            {'Precision'}
                                        </Typography>
                                        <Typography variant="body2" className={classes.propertyDescritionEllipsis}>
                                            {attributeData(attribute.name ? attribute.name : '', "precision")}
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    attributeData(attribute.name ? attribute.name : '', "precision") && attributeData(attribute.name ? attribute.name : '', "scale") &&
                                    <Grid item>
                                        <Typography variant="body1" className={classes.rangeSeparator}>
                                            {'-'}
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    attributeData(attribute.name ? attribute.name : '', "scale") &&
                                    <Grid item className={classNames(classes.valuesTextboxContainer, classes.sliderInput, classes.propertyComponents)}>
                                        <Typography component="h6" variant="h6">
                                            {'Scale'}
                                        </Typography>
                                        <Typography variant="body2" className={classes.propertyDescritionEllipsis}>
                                            {attributeData(attribute.name ? attribute.name : '', "scale")}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                <Grid container direction="column" justify="flex-start" wrap="nowrap">
                    <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap" className={classes.valuesTitleContainer}>
                        <Typography component="h6" variant="h6">
                            {'Enumeration'}
                        </Typography>
                        {
                            (isEditable || isEditPermission) &&
                            <ToolTipComponent title="Add Enumeration" arrow>
                                <IconButton onClick={() => addEnum()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                            <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                                <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                                <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                            </g>
                                        </g>
                                    </svg>
                                </IconButton>
                            </ToolTipComponent>
                        }
                    </Grid>
                    <Typography variant="body2" className={classes.propertyDescritionEllipsis}>
                        {Localization.properties.value.enum}
                    </Typography>
                    <Grid container className={classNames(classes.patternListContainer)} style={{ overflow: 'auto' }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {
                                property.enum && property.enum.values && property.enum.values.map((data, index) =>
                                    <ValuesListItem
                                        key={`enumerationList${index}`}
                                        // style={}
                                        displayKey={'value'}
                                        index={index}
                                        data={data}
                                        onDelete={(index) => removeEnum(index)}
                                        onUpdate={(...props) => updateEnum(...props)}
                                        disabled={!isEditable}
                                        isEditPermission={isEditPermission}
                                        isDeletePermission={isDeletePermission} />

                                )
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
};

AttributeValues.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    attribute: PropTypes.object,
    isEditable: PropTypes.bool,
    updatePropertiesList: PropTypes.func,
    isDeletePermission: PropTypes.bool,
    isEditPermission: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(AttributeValues);