import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

import ConversationMessage from './ConversationMessage.jsx';

const ConversationMessageList = (props) => {
    const { conversation } = props;
    const user = useSelector(({ account }) => account.user);
    return (
        <Grid>
            {
                conversation.messages && conversation.messages.map((message, index) =>
                <ConversationMessage
                        type="messageList"
                        key={index}
                        message={message}
                        userId={user.id}
                    />
                )
            }
        </Grid>
    );

};

ConversationMessageList.propTypes = {
    conversation: PropTypes.object
};

export default (ConversationMessageList);