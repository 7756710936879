/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Chip, Tooltip, IconButton } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import DatasourceListStyles from './DatasourceListStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import Circular from '../Charts/Circular.jsx';
import UserList from './UserList.jsx';
import Loader from '../Loaders/Loader.jsx';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import Filetype from '../../assets/images/logo-sql-server.png';
import { getValue, getDataTypeColor } from '../../helpers/appHelpers.js';
import { appConstants } from '../../constants/appConstants';
import datasourceTypes from '../../config/datasourceTypes';
import WarningIcon from '@material-ui/icons/Warning';
import { navigatePage } from '../../actions/notificationActions';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import { Link } from 'react-router-dom';


const DescTooltip = withStyles((theme) => ({
    arrow: {
        color: '#444'
    },
    tooltip: {
        maxWidth: 500,
        backgroundColor: '#444',
        maxHeight: 200,
        textOverflow: "ellipsis",
        overflow: "hidden",
        webkitLlineClamp: 4,
        webkitBoxOrient: 'vertical'
    }
}))(Tooltip);


const DatasourceInfo = (props) => {

    const { classes, source, theme, type, index, getSteward, getDatasourceIcon, getSharingIcon, editData, editDataLink, openRequestAccess, history } = props;
    const connectionTypes = useSelector(({ datasource }) => datasource.connectionTypes);
    const licenseConfig = useSelector(({ setting }) => setting.licenseConfig);
    const isLicenseLoaded = useSelector(({ datasource }) => datasource.isLicenseRequestLoading);
    const config = useSelector(({ setting }) => setting.config);
    const dqScoreThreshold = useSelector(({ setting }) => setting.user_config.dq_score_threshold);
    const dispatch = useDispatch();
    const getIcon = (sourceType) => {
        let icon;
        if (sourceType) {
            icon = datasourceTypes.find((p) => p.type.toLowerCase() === sourceType.toLowerCase());
        }
        if (icon && icon.logo && typeof (icon.logo) === 'object') {
            return icon.logo.default;
        }
        return icon ? icon.logo : Filetype;
    };

    const renderIcon = (source) => {
        return (
            (source.icon || source.image_url) ?
                <Grid className={classes.sourceIconContainer}>
                    {
                        type !== appConstants.filterTypes[3] ?
                            <img src={require(`../../assets/images/sourceIcons/${source.icon}.png`).default} alt={source.icon} className={classes.dataSourceIcon} /> :
                            <img src={source.image_url} alt={source.icon} className={classes.sourceIcon} />
                    }
                </Grid>
                : getDatasourceIcon(source)

        );
    };

    const datasourceIco =
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 11.289 15.394" fill={theme.palette.primary.main} style={{ flexShrink: '0' }}>
            <path id="database_17_" data-name="database (17)" d="M15.645,17.394c2.805,0,5.645-.793,5.645-2.309V4.309C21.289,2.793,18.45,2,15.645,2S10,2.793,10,4.309V15.085C10,16.6,12.839,17.394,15.645,17.394Zm0-14.368c2.865,0,4.618.831,4.618,1.283s-1.753,1.283-4.618,1.283-4.618-.831-4.618-1.283S12.78,3.026,15.645,3.026ZM11.026,5.695a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924V7.9c0,.452-1.753,1.283-4.618,1.283S11.026,8.353,11.026,7.9Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Z" transform="translate(-10 -2)" fill={theme.palette.primary.main} />
        </svg>;

    const datasetIco =
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17.751 16.711" style={{ flexShrink: '0' }}>
            <path id="table" d="M0,15V31.711H17.751V15Zm16.711,1.04v2.115H1.04V16.04ZM9.4,22.315h3.12V24.4H9.4ZM8.355,24.4H5.235v-2.08h3.12Zm1.04-3.12V19.2h3.12v2.08Zm-1.04,0H5.235V19.2h3.12Zm-4.16,0H1.04V19.2H4.2Zm0,1.04V24.4H1.04v-2.08Zm0,3.12v2.08H1.04v-2.08Zm1.04,0h3.12v2.08H5.235Zm3.12,3.12v2.115H5.235V28.556Zm1.04,0h3.12v2.115H9.4Zm0-1.04v-2.08h3.12v2.08Zm4.16-2.08h3.155v2.08H13.556Zm0-1.04v-2.08h3.155V24.4Zm0-3.12V19.2h3.155v2.08ZM1.04,28.556H4.2v2.115H1.04Zm12.516,2.115V28.556h3.155v2.115Z" transform="translate(0 -15)" fill={theme.palette.primary.main} />
        </svg>;

    const attributesIco =
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17.773 16.732" style={{ flexShrink: '0' }}>
            <path id="table" d="M0,15V31.732H17.773V15Zm16.732,1.041v2.118h-7.3l.024-2.118H4.265v2.118H1.041V16.041H16.732ZM9.407,22.325h3.124v2.083H9.407Zm0-1.041V19.2h3.124v2.083Zm-5.207,0H1.041V19.2H4.2Zm0,1.041v2.083H1.041V22.325Zm0,3.124v2.083H1.041V25.449Zm5.207,3.124h3.124v2.118H9.407Zm0-1.041V25.449h3.124v2.083Zm4.166-2.083h3.159v2.083H13.573Zm0-1.041V22.325h3.159v2.083Zm0-3.124V19.2h3.159v2.083ZM1.041,28.573H4.2v2.118H1.041Zm12.532,2.118V28.573h3.159v2.118Z" transform="translate(0 -15)" fill={theme.palette.primary.main} />
        </svg>;

    const domainsIco =
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16.068 18.554" style={{ flexShrink: '0' }}>
            <g id="rubik" transform="translate(-34.297)">
                <g id="Group_3147" data-name="Group 3147" transform="translate(34.297)">
                    <path id="Path_1474" data-name="Path 1474" d="M42.331,0,34.3,4.639v9.277l8.034,4.639,8.034-4.639V4.639Zm0,1.259,2.655,1.533L42.331,4.324,39.677,2.791Zm-4.29,13.56-2.655-1.533V10.221l2.655,1.533Zm0-4.324L35.387,8.962V5.9L38.042,7.43Zm-2.11-5.542,2.655-1.533,2.655,1.533L38.587,6.486Zm5.854,12.028-2.655-1.533V12.383l2.655,1.533Zm0-4.324-2.655-1.533V8.059l2.655,1.533Zm-2.11-5.542,2.655-1.533,2.655,1.533L42.331,8.648Zm5.854,8.333-2.655,1.533V13.916l2.655-1.533Zm0-4.324-2.655,1.533V9.592l2.655-1.533Zm-2.11-6.171,2.655-1.533L48.73,4.953,46.076,6.486Zm5.854,8.333-2.655,1.533V11.754l2.655-1.533Zm0-4.324L46.621,10.5V7.43L49.275,5.9Z" transform="translate(-34.297)" fill={theme.palette.primary.main} />
                </g>
            </g>
        </svg>;


    /**
     *
     * @param {*} type
     * @returns
     */
    const getIcons = (type) => {
        switch (type) {
            case 'By Datasource':
                return datasourceIco;
            case 'By Dataset':
                return datasetIco;
            case 'By Attribute':
                return attributesIco;
            case 'By Domain':
                return domainsIco;
            default:
                return datasourceIco;
        }
    };

    const inputConnections = licenseConfig && licenseConfig.input_connectors ? licenseConfig.input_connectors : [];
    const connections = connectionTypes.filter((connection) => inputConnections.includes(connection.id));
    let connectionType = "";
    if (type === appConstants.filterTypes[0]) {
        connectionType = source.type ? source.type : "";
    } else if (type === appConstants.filterTypes[1]) {
        connectionType = source.datasource && source.datasource.type ? source.datasource.type : "";
    }
    const connectionStatus = connections.some((data) => data.type.replace(' ', '') === connectionType.replace(' ', ''));

    const navigate = (path, title) => {
        dispatch(navigatePage(title));
        history.push(`/${path}`);
    };

    const getSourcePath = (datasource, viewType) => {
        let path = '';
        if (datasource && !datasource.configuration) {
            return path;
        }
        let configuration = datasource.configuration;
        if (configuration && typeof configuration === 'string') {
            configuration = JSON.parse(configuration);
            if (viewType === appConstants.filterTypes[1]) {
                const input_config = configuration.input_config ? configuration.input_config : {};
                configuration = {
                    ...configuration,
                    ...input_config
                };
            }
        }
        switch (viewType) {
            case appConstants.filterTypes[0]:
                if (datasource.type === "Tableau") {
                    path = `${datasource.type} / ~ / ${configuration?.site ?? ''}`;
                } else {
                    path = `${datasource.type} / ~ / ${configuration?.database ?? configuration?.bucketname ?? ''}`;
                }
                break;
            case appConstants.filterTypes[1]:
                if (datasource.type === "Tableau") {
                    path = `${datasource.type} / ~ / ${configuration?.site ?? ''} / ${configuration?.name ?? ''}`;
                } else {
                    path = `${source.datasource.type} / ~ / ${configuration?.schema ?? configuration?.bucketname ?? ''} / ~ / ${configuration?.name ?? ''}`;
                }
                break;
            case appConstants.filterTypes[2]:
                path = `${source.datasource.type} / ~ / ${configuration?.schema} / ~ / ${configuration?.name ?? ''}`;
                break;
            default:
                path = '';
                break;
        }
        return path;
    };

    return (
        <Grid className={classes.cardContainer}>
            <Grid container>
                <Grid item className={classNames(classes.listContainerfull, classes.listContainer)} >
                    {
                        type === appConstants.filterTypes[0] && getSteward(source.sharing_info ? source.sharing_info : []) &&
                        <ToolTipComponent title="Request Access Notification" arrow>
                            <Grid className={classes.ribbon} onClick={(event) => openRequestAccess(event, index)} />
                        </ToolTipComponent>
                    }
                    <Grid container className={classes.catdetailContainer}>
                        <Grid item xs={6} className={classes.detailContainer}>
                            <Grid className={classNames(classes.titleContainer, type === appConstants.filterTypes[3] ? classes.titleSection : null)}>
                                <Grid style={{ "display": "flex", "alignItems": "center" }}>
                                    {getIcons(type)}
                                    <ToolTipComponent title={source.name} placement="bottom-start" arrow>
                                        <Link style={{ textDecoration: 'unset', color: 'inherit' }} to={editDataLink(source, "main")} >
                                            <Typography style={{ "marginBottom": "0px" }} id={`catalog_${index}`} component="span" variant="h4" className={classNames(classes.titleTxt, classes.flexCont, classes.linkHeading)}>
                                                {source.name ? source.name : 'Untitled Datasource'}
                                            </Typography>
                                        </Link>
                                    </ToolTipComponent>
                                    {
                                        type !== appConstants.filterTypes[3] &&
                                        <Grid alignItems="center" className={classes.flexCont}>
                                            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="18" viewBox="0 0 24 24" width="18" fill={source.rating ? "#F4B206" : "#B5B6B8"}>
                                                <g>
                                                    <path d="M0,0h24v24H0V0z" fill="none" />
                                                    <path d="M0,0h24v24H0V0z" fill="none" />
                                                </g>
                                                <g>
                                                    <path d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z" />
                                                </g>
                                            </svg>
                                            <Typography variant="h6" component="span" className={classes.starLabel}>
                                                {source.rating ? source.rating.toFixed(1) : '0'}
                                            </Typography>
                                        </Grid>
                                    }
                                    {
                                        source.connectortype && source.connectortype === 'Tableau' ?
                                            null :
                                            <Grid alignItems="center" className={classes.flexCont}>
                                                <ToolTipComponent title={"DQScore"} arrow>
                                                    <span>
                                                        <Circular
                                                            showPercentage={Boolean(source.dqscore && source.dqscore !== 0)}
                                                            showValue
                                                            value={source.dqscore}
                                                            size={40}
                                                            dqScoreThreshold={source.dq_score_threshold && Object.keys(source.dq_score_threshold).length > 0 ? source.dq_score_threshold : dqScoreThreshold}
                                                        />
                                                    </span>
                                                </ToolTipComponent>
                                                {
                                                    source.impact_score > 0 &&
                                                    <ToolTipComponent title={"Impact Score"} arrow>
                                                        <span className={classes.flex}>
                                                            <ArrowDropUpIcon
                                                                className={
                                                                    classNames(
                                                                        classes.impactIcon,
                                                                        classes.impactArrow
                                                                    )
                                                                }
                                                            />
                                                            <Typography className={classes.curateTxt}>
                                                                {`${getValue(source.impact_score)}%`}
                                                            </Typography>
                                                        </span>
                                                    </ToolTipComponent>
                                                }
                                            </Grid>
                                    }
                                    {
                                        (
                                            (source.sensitive_datasets && source.sensitive_datasets >= 1) ||
                                            (source.sensitive_attributes && source.sensitive_attributes >= 1) ||
                                            (source.sensitivity && source.sensitivity > 1)
                                        ) &&
                                        <Grid style={{ "marginRight": "10px", "display": "flex", "alignItems": "center", "paddingLeft": "10px" }}>
                                            <ToolTipComponent title={'Contains Sensitive Information'} arrow>
                                                <svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" width="32" height="32">
                                                    <g id="Logo" clipPath="url(#clip-Logo)">
                                                        <g id="Group_4" data-name="Group 4" transform="translate(-18.99 -64.748)">
                                                            <g id="Group_3" data-name="Group 3" transform="translate(29.99 87.748)">
                                                                <line id="Line_1" data-name="Line 1" x2="31.611" transform="translate(86.008 38.817)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeWidth="8" />
                                                                <line id="Line_2" data-name="Line 2" x2="38.119" transform="translate(86.008 58.315)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeWidth="8" />
                                                                <g id="Group_2" data-name="Group 2" transform="translate(18.138 16.479)">
                                                                    <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(10.103 0)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8">
                                                                        <circle cx="20.159" cy="20.159" r="20.159" stroke="none" />
                                                                        <circle cx="20.159" cy="20.159" r="16.159" fill="none" />
                                                                    </g>
                                                                    <path id="Path_1" data-name="Path 1" d="M226.1,131.262c.074-.283,3.875-28.637,29.415-28.637s30.225,28.907,29.96,28.637S226.029,131.544,226.1,131.262Z" transform="translate(-226.103 -60.531)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" />
                                                                </g>
                                                                <g id="Group_1" data-name="Group 1" transform="translate(108.877 66.576)">
                                                                    <g id="Rectangle_2" data-name="Rectangle 2" transform="translate(0 27.27)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8">
                                                                        <rect width="69.739" height="59.932" rx="10" stroke="none" />
                                                                        <rect x="4" y="4" width="61.739" height="51.932" rx="6" fill="none" />
                                                                    </g>
                                                                    <path id="Path_2" data-name="Path 2" d="M339.964,142.246s-4.831-29.99,19.725-29.889,19.222,29.889,19.222,29.889" transform="translate(-324.329 -112.357)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" />
                                                                    <g id="Rectangle_3" data-name="Rectangle 3" transform="translate(6.538 33.808)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeWidth="10">
                                                                        <rect width="56.663" height="47.945" rx="3" stroke="none" />
                                                                        <rect x="5" y="5" width="46.663" height="37.945" rx="2" fill="none" />
                                                                    </g>
                                                                    <circle id="Ellipse_2" data-name="Ellipse 2" cx="7.628" cy="7.628" r="7.628" transform="translate(27.242 49.064)" fill={theme.palette.primary.main} />
                                                                </g>
                                                                <path id="Path_3" data-name="Path 3" d="M349.571,85.856s1.193-37.659,0-40.24-4.906-6.753-10.15-6.854-122.488.394-127.475.193-6.955,1.994-6.955,9.317v99.934s.666,6.854,9.51,7.075,16.361,0,16.361,0,4.276-10.853,12.179-11.008,12.241,11.008,12.241,11.008l38.119-.471" transform="translate(-204.99 -38.748)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                </svg>
                                            </ToolTipComponent>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} align="right">
                            <Grid container alignItems="center" justify="flex-end">
                                {
                                    !connectionStatus && !isLicenseLoaded && (type === appConstants.filterTypes[0] || type === appConstants.filterTypes[1]) &&
                                    <ToolTipComponent title={"Connector Disabled"} arrow>
                                        <IconButton className={classNames(classes.noPadding, classes.paddingRight15, classes.fontSize13, classes.warningIcon)}>
                                            <WarningIcon />
                                        </IconButton>
                                    </ToolTipComponent>
                                }
                                {
                                    type !== appConstants.filterTypes[2] && config.action_items &&
                                    <Grid className={classes.paddingRight15} onClick={() => navigate('actionitem', 'actionitem')}>
                                        <Grid container alignItems="center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 26 27.791" fill="#F38080">
                                                <defs />
                                                <g transform="translate(-16.5 0)">
                                                    <path fill={theme.palette.primary.main} d="M85.855,145H81.346a.846.846,0,0,0,0,1.691h4.509a.846.846,0,0,0,0-1.691Z" transform="translate(-60.392 -137.148)" />
                                                    <path fill={theme.palette.primary.main} d="M85.855,225H81.346a.846.846,0,1,0,0,1.691h4.509a.846.846,0,1,0,0-1.691Z" transform="translate(-60.392 -212.816)" />
                                                    <path fill={theme.palette.primary.main} d="M85.855,305H81.346a.846.846,0,0,0,0,1.691h4.509a.846.846,0,0,0,0-1.691Z" transform="translate(-60.392 -288.484)" />
                                                    <path fill={theme.palette.primary.main} d="M36.8,14.923V7.49a4.047,4.047,0,0,0-1.165-2.851l-3.3-3.416,0,0A4.1,4.1,0,0,0,29.423,0H18.943A2.445,2.445,0,0,0,16.5,2.443V23.557A2.445,2.445,0,0,0,18.943,26H31.526A6.472,6.472,0,1,0,36.8,14.923ZM18.128,23.557V2.443a.815.815,0,0,1,.814-.814H29.423a2.459,2.459,0,0,1,1.741.728l3.3,3.416,0,0a2.428,2.428,0,0,1,.7,1.712v7.434a6.457,6.457,0,0,0-4.884,9.448H18.943a.815.815,0,0,1-.814-.814Zm17.858,2.605a4.831,4.831,0,1,1,4.885-4.831A4.836,4.836,0,0,1,35.986,26.163Z" />
                                                    <path fill={theme.palette.primary.main} d="M334.041,354.2a.846.846,0,0,0-1.191.108l-1.427,1.712-.324-.647a.846.846,0,0,0-1.513.756l.9,1.8a.846.846,0,0,0,.659.462.86.86,0,0,0,.1.006.846.846,0,0,0,.649-.3l2.255-2.706A.846.846,0,0,0,334.041,354.2Z" transform="translate(-296.151 -334.919)" />
                                                    <path fill={theme.palette.primary.main} d="M241.346,146.691h.9a.846.846,0,1,0,0-1.691h-.9a.846.846,0,0,0,0,1.691Z" transform="translate(-211.891 -137.148)" />
                                                    <path fill={theme.palette.primary.main} d="M243.093,225.846a.846.846,0,0,0-.846-.846h-.9a.846.846,0,1,0,0,1.691h.9A.846.846,0,0,0,243.093,225.846Z" transform="translate(-211.891 -212.816)" />
                                                </g>
                                            </svg>
                                            <Typography component="span" className={classNames(classes.viewTxt, classes.anchorLink)}>
                                                {`${source.actionitems ? source.actionitems : 0} Action Items`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    type !== appConstants.filterTypes[2] && config.conversation &&
                                    <Grid item className={classes.paddingRight15} onClick={() => navigate('conversation', 'conversation', source.name)}>
                                        <Grid container alignItems="center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18.421" viewBox="0 0 22 18.421">
                                                <path fill={theme.palette.primary.main} d="M2.371,22.928A1.379,1.379,0,0,1,1,21.557V10.278A2.845,2.845,0,0,1,3.838,7.44H17.221a2.845,2.845,0,0,1,2.845,2.838v7.517a2.845,2.845,0,0,1-2.838,2.845H6.067a.6.6,0,0,0-.352.117L3.2,22.657a1.386,1.386,0,0,1-.829.271ZM3.838,8.907a1.379,1.379,0,0,0-1.371,1.371v11.1l2.369-1.789a2.083,2.083,0,0,1,1.232-.411H17.221A1.379,1.379,0,0,0,18.6,17.795V10.278a1.379,1.379,0,0,0-1.371-1.371Z" transform="translate(-1 -4.507)" />
                                                <circle fill={theme.palette.primary.main} cx="0.733" cy="0.733" r="0.733" transform="translate(11.689 8.866)" />
                                                <circle fill={theme.palette.primary.main} cx="0.733" cy="0.733" r="0.733" transform="translate(8.844 8.866)" />
                                                <circle fill={theme.palette.primary.main} cx="0.733" cy="0.733" r="0.733" transform="translate(5.911 8.866)" />
                                                <path fill={theme.palette.primary.main} d="M23.6,14.44a.733.733,0,0,1-.733-.733V6.278A1.379,1.379,0,0,0,21.5,4.907H6.733a.733.733,0,1,1,0-1.467H21.5a2.845,2.845,0,0,1,2.838,2.838v7.429A.733.733,0,0,1,23.6,14.44Z" transform="translate(-2.333 -3.44)" />
                                            </svg>
                                            <Typography component="span" className={classNames(classes.viewTxt, classes.anchorLink)}>
                                                {`${source.conversations ? source.conversations : 0} Conversations`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    type !== appConstants.filterTypes[2] &&
                                    <Grid item>
                                        <Grid container alignItems="center">
                                            <svg version="1.1"
                                                id="Layer_1"
                                                width="22"
                                                height="22"
                                                xmlns="http://www.w3.org/2000/svg"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 32 32" >
                                                <g id="Chart">
                                                    <path fill={theme.palette.primary.main}
                                                        d="M26.5,26.6v-15h-0.8v0h-4v0h-0.3v15h-2.7v-8.9h-0.8v0h-4v0h-0.3v8.9h-2.8v-4.8H10H8.8H7.7H6H5.7v4.8H4v2h24v-2
                                                        H26.5z M23.3,13.7h1.1v12.9h-1.1V13.7z M15.5,19.7h1.1v6.9h-1.1V19.7z M7.7,23.8h1.1v2.8H7.7V23.8z" />
                                                    <g>
                                                        <g transform="translate(2.368)">
                                                            <path fill={theme.palette.primary.main}
                                                                stroke={theme.palette.primary.main}
                                                                strokeWidth="0.6"
                                                                strokeMiterlimit="10"
                                                                d="M24.1,3.9c0-0.3-0.2-0.5-0.5-0.5c0,0,0,0,0,0h-3c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h1.8l-4.9,4.9
                                                                l-1.8-1.8c-0.2-0.2-0.5-0.2-0.7,0c0,0,0,0,0,0l-8.8,8.8C6,16.5,6,16.8,6.2,17c0.2,0.2,0.5,0.2,0.7,0l0,0l8.5-8.5l1.8,1.8
                            c0.2,0.2,0.5,0.2,0.7,0c0,0,0,0,0,0l5.2-5.2v1.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5L24.1,3.9
                         C24.1,3.9,24.1,3.9,24.1,3.9z" />
                                                        </g>
                                                        <g transform="translate(1.437 8.101)">
                                                            <circle fill={theme.palette.primary.main} cx="5.8" cy="10.2" r="1" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>

                                            <Typography component="span" className={classes.viewTxt}>
                                                {`${source.views ? source.views : 0} Views`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    type !== appConstants.filterTypes[2] && type !== appConstants.filterTypes[3] &&
                                    <span>
                                        {
                                            !source.isLoading ?
                                                getSharingIcon(source.sharing_info, source, index)
                                                :
                                                <Typography style={{ position: 'relative', marginRight: 20, paddingLeft: 15 }}>
                                                    <Loader size={'small'} type={'button'} />
                                                </Typography>
                                        }
                                    </span>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        source.description && source.description.length > 0 ? (
                            <DescTooltip className={classes.descriptionTooltip} title={source.description} arrow placement="bottom-start">
                                <Typography className={classNames(classes.descriptionTxt, 'description')} variant="subtitle1" component="div">
                                    {source.description}
                                </Typography>
                            </DescTooltip>
                        ) : null
                    }
                    <Grid container className={classes.datasetContainer}>
                        {
                            type === appConstants.filterTypes[0] ?
                                <Grid item xs={7}>
                                    {
                                        source.type === "Tableau" ?
                                            <Grid container>
                                                {
                                                    (type === appConstants.filterTypes[0] || type === appConstants.filterTypes[3]) &&
                                                    <Grid item className={classes.marginRight15}>
                                                        <Typography variant="subtitle1" component="span">
                                                            Workbooks:
                                                        </Typography>
                                                        <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                            {source.workbooks ? source.workbooks : 0}
                                                        </Typography>
                                                    </Grid>
                                                }
                                                {
                                                    (type === appConstants.filterTypes[0] || type === appConstants.filterTypes[1] || type === appConstants.filterTypes[3]) &&
                                                    <Grid item className={classes.marginRight15}>
                                                        <Typography variant="subtitle1" component="span">
                                                            Worksheet:
                                                        </Typography>
                                                        <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                            {source.worksheets ? source.worksheets : 0}
                                                        </Typography>
                                                    </Grid>
                                                }
                                                <Grid item className={classes.marginRight15}>
                                                    <Typography variant="subtitle1" component="span">
                                                        Datasource:
                                                    </Typography>
                                                    <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                        {source.worksheets_datasources ? source.worksheets_datasources : 0}
                                                    </Typography>
                                                </Grid>
                                            </Grid> :
                                            <Grid container>
                                                {
                                                    (type === appConstants.filterTypes[0] || type === appConstants.filterTypes[3]) &&
                                                    <Grid item className={classes.marginRight15}>
                                                        <Typography variant="subtitle1" component="span">
                                                            Total Datasets:
                                                        </Typography>
                                                        <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                            {source.total_datasets ? source.total_datasets : 0}
                                                        </Typography>
                                                    </Grid>
                                                }
                                                {
                                                    (type === appConstants.filterTypes[0] || type === appConstants.filterTypes[1] || type === appConstants.filterTypes[3]) &&
                                                    <Grid item className={classes.marginRight15}>
                                                        <Typography variant="subtitle1" component="span">
                                                            Total Columns:
                                                        </Typography>
                                                        <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                            {source.total_columns ? source.total_columns : 0}
                                                        </Typography>
                                                    </Grid>
                                                }
                                                <Grid item className={classes.marginRight15}>
                                                    <Typography variant="subtitle1" component="span">
                                                        Total Rows:
                                                    </Typography>
                                                    <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                        {source.total_rows}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                    }
                                </Grid> :
                                <Grid item xs={type === appConstants.filterTypes[3] ? 5 : 7}>
                                    {
                                        source.type === "Tableau" && type === appConstants.filterTypes[1] ?
                                            <Grid container>
                                                <Grid item className={classes.marginRight15}>
                                                    <Typography variant="subtitle1" component="span">
                                                        Worksheet:
                                                    </Typography>
                                                    <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                        {source.worksheets ? source.worksheets : 0}
                                                    </Typography>
                                                </Grid>
                                                <Grid item className={classes.marginRight15}>
                                                    <Typography variant="subtitle1" component="span">
                                                        Datasource:
                                                    </Typography>
                                                    <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                        {source.worksheets_datasources ? source.worksheets_datasources : 0}
                                                    </Typography>
                                                </Grid>
                                            </Grid> :
                                            <Grid container>
                                                {
                                                    (type === appConstants.filterTypes[0] || type === appConstants.filterTypes[3]) &&
                                                    <Grid item className={classes.marginRight15}>
                                                        <Typography variant="subtitle1" component="span">
                                                            Total Datasource:
                                                        </Typography>
                                                        <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                            {source.datasources}
                                                        </Typography>
                                                    </Grid>
                                                }
                                                {
                                                    (type === appConstants.filterTypes[0] || type === appConstants.filterTypes[3]) &&
                                                    <Grid item className={classes.marginRight15}>
                                                        <Typography variant="subtitle1" component="span">
                                                            Total Datasets:
                                                        </Typography>
                                                        <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                            {source.total_datasets ? source.total_datasets : 0}
                                                        </Typography>
                                                    </Grid>
                                                }
                                                {
                                                    (type === appConstants.filterTypes[0] || type === appConstants.filterTypes[1] || type === appConstants.filterTypes[3]) &&
                                                    <Grid item className={classes.marginRight15}>
                                                        <Typography variant="subtitle1" component="span">
                                                            Total Columns:
                                                        </Typography>
                                                        <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                            {source.total_columns ? source.total_columns : 0}
                                                        </Typography>
                                                    </Grid>
                                                }
                                                <Grid item className={classes.marginRight15}>
                                                    <Typography variant="subtitle1" component="span">
                                                        Total Rows:
                                                    </Typography>
                                                    <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                                        {source.total_rows}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                    }
                                </Grid>
                        }
                        {
                            type !== appConstants.filterTypes[3] &&
                            <Grid item xs={3}>
                                <Grid container alignItems="left" justify="flex-start">
                                    <Grid alignItems="center" className={`${classes.flexContainer} ${classes.widthCont}`}>
                                        <img src={getIcon(type === appConstants.filterTypes[0] ? source.type : source.datasource.type)} alt={"file type"} height={20} />
                                        <ToolTipComponent title={getSourcePath(source, type)} arrow>
                                            <Typography variant="subtitle1" component="span" className={`${classes.paddingLeft10} ${classes.highlight_path}`}>
                                                {getSourcePath(source, type)}
                                            </Typography>
                                        </ToolTipComponent>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={type === appConstants.filterTypes[3] ? 3 : 2} align="right">
                            {
                                type !== appConstants.filterTypes[3] &&
                                <UserList users={source.sharing_info ? source.sharing_info.filter((user) => user.is_request_accepted && user.user_id) : []} itemCount={3} navigateDatasource={() => editData(source, "main")} />
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
};

DatasourceInfo.propTypes = {
    classes: PropTypes.object,
    source: PropTypes.object,
    type: PropTypes.string,
    getSteward: PropTypes.func,
    theme: PropTypes.object,
    getDatasourceIcon: PropTypes.func,
    getSharingIcon: PropTypes.func,
    index: PropTypes.number,
    editData: PropTypes.func,
    editDataLink: PropTypes.func,
    openRequestAccess: PropTypes.func,
    history: PropTypes.object
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DatasourceInfo);