import React, { useCallback, useEffect, useState } from 'react';
import { Popover, Grid, Typography, IconButton, withStyles, Chip, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import PropTypes from 'prop-types';
import DataPreparationStyles from './DataPreparationStyles.jsx';
import { getTransformations, deleteTransform } from '../../actions/dataPreparationActions';
import { useDispatch } from 'react-redux';
import { Close } from '@material-ui/icons';
import Styles from '../../layouts/Styles.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';

const DataPreparationTransformationPreview = (props) => {
    const { open, anchorElement, onClose, theme, transformId, classes } = props;
    const [transformations, setTransformations] = useState([]);
    const [isInputChanged, setInputChanged] = useState(false);
    const dispatch = useDispatch();

    const getTransform = useCallback(() => {
        dispatch(getTransformations(transformId)).then((response) => {
            if (response) {
                setTransformations([...response]);
            }
        });
    }, [dispatch, transformId]);

    const deleteTransformList = useCallback((id) => {
        if (isInputChanged) {
            setInputChanged(true);
        }
        const index = transformations.findIndex((transform) => transform.id === id);
        transformations.splice(index, 1);
        setTransformations([...transformations]);
        dispatch(deleteTransform(id));
    }, [dispatch, isInputChanged, transformations]);

    useEffect(() => {
        getTransform();
    }, [getTransform]);
    return (
        <Popover
            open={open}
            anchorEl={anchorElement}
            onClose={() => onClose(isInputChanged)}
            anchorOrigin={
                {
                    vertical: 'top',
                    horizontal: 'center'
                }
            }
            transformOrigin={
                {
                    vertical: 'top',
                    horizontal: 'right'
                }
            }>
            <Grid className={classes.transformModelContainer}>
                <Grid container justify="space-between">
                    <Typography component="h6" variant="h6">
                        {'Transformation Applied'}
                    </Typography>
                    <IconButton className={classes.nopadding} onClick={() => onClose(isInputChanged)}>
                        <Close />
                    </IconButton>
                </Grid>
                <Grid className={classes.marginTop15}>
                    {
                        transformations && transformations.length > 0 &&
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableHeadCell}>
                                        <Typography className={classes.tableTitleTxt}>
                                            Title
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableHeadCell}>
                                        <Typography className={classes.tableTitleTxt}>
                                            Input Attributes
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableHeadCell}>
                                        <Typography className={classes.tableTitleTxt}>
                                            Output Attribute
                                        </Typography>
                                    </TableCell>
                                    <TableCell className={classes.tableHeadCell}>
                                        <Typography className={classes.tableTitleTxt}>
                                            Actions
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    transformations && transformations.map((transform, index) =>
                                        <TableRow key={index}>
                                            <TableCell>
                                                <Typography className={classes.tableCellTitle}>
                                                    {transform.name}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    transform.input_attributes && transform.input_attributes.map((attribute, attributeIndex) =>
                                                        <Chip
                                                            key={`option_${attributeIndex}`}
                                                            label={attribute.name}
                                                        />
                                                    )
                                                }
                                            </TableCell>
                                            <TableCell>
                                                <Typography>
                                                    {transform.output_attribute ? transform.output_attribute : ""}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <IconButton onClick={() => deleteTransformList(transform.id)}>
                                                    <svg id="delete_1_" data-name="delete (1)" xmlns="http://www.w3.org/2000/svg" width="16.2" height="18" viewBox="0 0 16.2 18">
                                                        <path id="Path_898" data-name="Path 898" d="M132.635,4.7V4.25A2.251,2.251,0,0,1,134.885,2h.9a2.249,2.249,0,0,1,2.25,2.25V4.7h4.5a.9.9,0,1,1,0,1.8h-.9v9.9a3.6,3.6,0,0,1-3.6,3.6h-5.4a3.6,3.6,0,0,1-3.6-3.6V6.5h-.9a.9.9,0,0,1,0-1.8Zm7.2,1.8h-9v9.9a1.8,1.8,0,0,0,1.8,1.8h5.4a1.8,1.8,0,0,0,1.8-1.8Zm-3.6,2.7v6.3a.9.9,0,0,0,1.8,0V9.2a.9.9,0,0,0-1.8,0Zm-3.6,0v6.3a.9.9,0,0,0,1.8,0V9.2a.9.9,0,0,0-1.8,0Zm3.6-4.5V4.25a.449.449,0,0,0-.45-.45h-.9a.45.45,0,0,0-.45.45V4.7Z" transform="translate(-127.235 -2)" fill={theme.palette.primary.main} />
                                                    </svg>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    }
                    {
                        transformations && transformations.length === 0 &&
                        <NoResultFound />
                    }
                </Grid>
            </Grid>
        </Popover>
    );
};

DataPreparationTransformationPreview.propTypes = {
    open: PropTypes.bool,
    anchorElement: PropTypes.element,
    onClose: PropTypes.func,
    transformId: PropTypes.number,
    theme: PropTypes.object,
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    ...DataPreparationStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DataPreparationTransformationPreview);