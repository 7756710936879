import React, { useEffect } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import noprofile from "../../assets/images/np_profile_data.jpg";
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';
import { wrapText } from '../../helpers/appHelpers.js';

const GroupHeatMapChart = (props) => {
    const { classes, chartData, chartClassName, clientHeight, height, theme, chartWidth, onFilter } = props;

    useEffect(() => {
        if (chartData && chartData.length) {
            let width = 300;
            const margin = { top: 15, right: 10, bottom: 0, left: 150 };
            const xDomain = [...new Set(chartData.map((data) => data.xaxis))];
            const times = xDomain.length;
            width = Math.max(900, chartWidth) - (margin.left + margin.right);
            const groupBars = d3
                .nest()
                .key((d) => (d.dataset_id ? d.dataset_id : d.id))
                .entries(chartData);
            let yAxisLength = 0;
            for (const group of groupBars) {
                const bars = [...new Set(group.values.map((data) => data.yaxis))];
                yAxisLength += bars.length;
            }

            const chartContainer = document.getElementById(chartClassName);
            const parentNodeHeight = chartContainer ? chartContainer.clientHeight : 0;
            const height = parentNodeHeight ? parentNodeHeight : 600;
            width = chartContainer ? (chartContainer.clientWidth) : width;

            const cellHeight = Math.floor((height - 100) / yAxisLength);
            const cellWidth = Math.floor((width - 150) / times);

            d3.select(`.${chartClassName}_chart > svg`).remove();
            d3.select(`.${chartClassName} > .heatmaplegend-container`).remove();

            d3.select(`.${chartClassName}_chart > .tooltip`).remove();

            const toolTip = d3
                .select(`.${chartClassName}_chart`)
                .append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);


            let minValue = d3.min(chartData, (d) => {
                return d.count;
            });
            const maxValue = d3.max(chartData, (d) => {
                return d.count;
            });
            if (maxValue === minValue) {
                minValue = maxValue - minValue;
            }

            const colorFn = d3
                .scaleSequential(d3.interpolateRdPu)
                .domain([Math.floor(minValue), Math.ceil(maxValue)]);

            const svg = d3
                .select(`.${chartClassName}_chart`)
                .append("svg")
                .attr("class", `${chartClassName}_chart`)
                .attr("viewBox", [0, 0, width, height])
                .attr("widht", width)
                .attr("height", height)
                .attr("aspect", (width / height))
                .attr("overflow", "auto")
                .append("g")
                .attr("transform", `translate(0,${100})`);

            const x = d3.scaleBand()
                .domain(xDomain)
                .range([margin.left, width - margin.right]);

            svg
                .append("g")
                .attr("transform", `translate(0,0)`)
                .attr("class", "axis-x")
                .call(d3.axisTop(x))
                .selectAll("text")
                .attr("y", 0)
                .attr("x", 10)
                .attr("dy", ".15em")
                .attr("transform", "rotate(-90)")
                .style("text-anchor", "start")
                .select(".domain").remove();


            svg.selectAll('.tick line').remove();
            svg.selectAll('.axis-x text').text("").append('tspan').text((d) => { return d; }).each((d, i, nodes) => {
                const textElement = d3.select(nodes[i]);
                wrapText(textElement, 80);
            }).on("mouseover", (d, i, p) => {
                // Show Tooltip
                toolTip.transition()
                    .duration(200)
                    .style("opacity", 0.9);
                let x = 0;
                let y = 0;
                toolTip.attr("transform", (_, i, nodes) => {
                    const mouseCoords = d3.mouse(nodes[i].parentNode);
                    let xCo = 0;
                    let yCo = 0;
                    if (mouseCoords[0] + 10 >= width * 0.80) {
                        xCo = mouseCoords[0] - parseFloat(toolTip
                            .attr("width"));
                        yCo = mouseCoords[1] + 10;
                    } else {
                        xCo = mouseCoords[0] + 10;
                        yCo = mouseCoords[1];
                    }
                    x = isNaN(xCo) ? mouseCoords[0] : xCo;
                    y = isNaN(yCo) ? mouseCoords[1] : yCo;
                });
                d3.select(p[i]).style("opacity", 0.6);
                toolTip.transition().duration(200).style("opacity", 0.9);
                toolTip
                    .html(d)
                    .style("left", x + "px")
                    .style("top", y + "px");
            }).on("mouseout", (_, i, p) => {
                d3.select(p[i]).style("opacity", 1);
                toolTip.transition().duration(500).style("opacity", 0);
            });

            let previousRectHeight = 0;
            let index = 0;
            for (const group of groupBars) {
                const yAxisDomain = [...new Set(group.values.map((data) => data.yaxis))].reverse();
                const values = [...group.values].sort((a, b) => a.count - b.count);
                let yAxisGroupName = values.find((data) => parseInt(group.key) === (data.dataset_id ? data.dataset_id : data.id));
                yAxisGroupName = yAxisGroupName ? yAxisGroupName : {};

                const y = d3.scaleBand()
                    .domain(yAxisDomain)
                    .range([yAxisDomain.length * cellHeight, 0]);
                svg.append("g")
                    .attr("transform", `translate(${margin.left},${previousRectHeight})`)
                    .attr('class', 'axis-y')
                    .call(d3.axisLeft(y))
                    .selectAll("text")
                    .select(".domain").remove();
                svg.selectAll('.axis-y text').text("").append('tspan').text((d) => { return d; }).each((d, i, nodes) => {
                    const textElement = d3.select(nodes[i]);
                    wrapText(textElement, 100);
                }).on("mouseover", (d, i, p) => {
                    // Show Tooltip
                    toolTip.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    let x = 0;
                    let y = 0;
                    toolTip.attr("transform", (_, i, nodes) => {
                        const mouseCoords = d3.mouse(nodes[i].parentNode);
                        let xCo = 0;
                        let yCo = 0;
                        if (mouseCoords[0] + 10 >= width * 0.80) {
                            xCo = mouseCoords[0] - parseFloat(toolTip
                                .attr("width"));
                            yCo = mouseCoords[1] + 10;
                        } else {
                            xCo = mouseCoords[0] + 10;
                            yCo = mouseCoords[1];
                        }
                        x = isNaN(xCo) ? mouseCoords[0] : xCo;
                        y = isNaN(yCo) ? mouseCoords[1] : yCo;
                    });
                    d3.select(p[i]).style("opacity", 0.6);
                    toolTip.transition().duration(200).style("opacity", 0.9);
                    toolTip
                        .html(d)
                        .style("left", x + "px")
                        .style("top", y + "px");
                }).on("mouseout", (_, i, p) => {
                    d3.select(p[i]).style("opacity", 1);
                    toolTip.transition().duration(500).style("opacity", 0);
                });
                const rectContainer = svg.append("g").attr("transform", `translate(0,${(previousRectHeight)})`);
                rectContainer
                    .selectAll()
                    .data(values)
                    .enter()
                    .append("rect")
                    .attr("x", (d) => { return x(d.xaxis); })
                    .attr("y", (d) => { return y(d.yaxis); })
                    .attr("width", cellWidth)
                    .attr("height", cellHeight)
                    .style("fill", (d) => colorFn(d.count))
                    .attr("stroke-width", 1)
                    .attr("stroke", "#dae7ed")
                    .on("mouseover", (d, i, p) => {
                        // Show Tooltip
                        toolTip.transition()
                            .duration(200)
                            .style("opacity", 0.9);
                        let x = 0;
                        let y = 0;
                        toolTip.attr("transform", (_, i, nodes) => {
                            const mouseCoords = d3.mouse(nodes[i].parentNode);
                            let xCo = 0;
                            let yCo = 0;
                            if (mouseCoords[0] + 10 >= width * 0.80) {
                                xCo = mouseCoords[0] - parseFloat(toolTip
                                    .attr("width"));
                                yCo = mouseCoords[1] + 10;
                            } else {
                                xCo = mouseCoords[0] + 10;
                                yCo = mouseCoords[1];
                            }
                            x = isNaN(xCo) ? mouseCoords[0] : xCo;
                            y = isNaN(yCo) ? mouseCoords[1] : yCo;
                        });
                        d3.select(p[i]).style("opacity", 0.6);
                        toolTip.transition().duration(200).style("opacity", 0.9);
                        toolTip
                            .html(`Count : ${d.count} <br/> XAxis : ${d.xaxis} <br/> YAxis : ${d.yaxis} <br/> Group : ${d.type}`)
                            .style("left", x + "px")
                            .style("top", y + "px");
                    })
                    .on("mouseout", (_, i, p) => {
                        d3.select(p[i]).style("opacity", 1);
                        toolTip.transition().duration(500).style("opacity", 0);
                    }).on("click", (d) => {
                        onFilter(d);
                    });


                const rectHeight = (yAxisDomain.length * y.bandwidth()) + previousRectHeight;
                previousRectHeight = rectHeight;
                const groupTextContainer = svg.append("g").attr("class", "y-label").attr(
                    "transform", `translate(50, ${yAxisDomain.length === 1 ? rectHeight : rectHeight})`);
                groupTextContainer.append("text")
                    .attr("class", `${yAxisGroupName.type ? yAxisGroupName.type : group.key}`)
                    .attr("x", `${(cellHeight / 4)}`)
                    .attr("y", -40)
                    .attr("text-anchor", "start")
                    .attr("font-size", 13)
                    .attr("font-weight", 500)
                    .attr("transform", "rotate(270)")
                    .style("text-transform", "capitalize")
                    .text(yAxisGroupName.type ? yAxisGroupName.type : group.key);

                svg.selectAll(`.${yAxisGroupName.type ? yAxisGroupName.type : group.key}`).each((d, i, nodes) => {
                    const textElement = d3.select(nodes[i]);
                    wrapText(textElement, (yAxisDomain.length * y.bandwidth()) - 20);
                });

                svg.select(`.${yAxisGroupName.type}`).on("mouseover", (d, i, p) => {
                    // Show Tooltip
                    toolTip.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    let x = 0;
                    let y = 0;
                    toolTip.attr("transform", (_, i, nodes) => {
                        const mouseCoords = d3.mouse(nodes[i].parentNode);
                        let xCo = 0;
                        let yCo = 0;
                        if (mouseCoords[0] + 10 >= width * 0.80) {
                            xCo = mouseCoords[0] - parseFloat(toolTip
                                .attr("width"));
                            yCo = mouseCoords[1] + 10;
                        } else {
                            xCo = mouseCoords[0] + 10;
                            yCo = mouseCoords[1];
                        }
                        x = isNaN(xCo) ? mouseCoords[0] : xCo;
                        y = isNaN(yCo) ? mouseCoords[1] : yCo;
                    });
                    d3.select(p[i]).style("opacity", 0.6);
                    toolTip.transition().duration(200).style("opacity", 0.9);
                    toolTip
                        .html(yAxisGroupName.type)
                        .style("left", x + "px")
                        .style("top", y + "px");
                }).on("mouseout", (_, i, p) => {
                    d3.select(p[i]).style("opacity", 1);
                    toolTip.transition().duration(500).style("opacity", 0);
                });

                if (index !== groupBars.length - 1) {
                    svg.append("line")
                        .attr("x1", margin.left)
                        .attr("y1", rectHeight)
                        .attr("x2", 20)
                        .attr("y2", rectHeight)
                        .attr("class", "groupLine");
                }
                index++;
            }
            const categoriesCount = 6;
            const categories = [...Array(categoriesCount)].map((_, i) => {
                const upperBound = (maxValue / categoriesCount) * (i + 1);
                const lowerBound = (maxValue / categoriesCount) * i;

                return {
                    upperBound,
                    lowerBound,
                    color: d3.interpolateRdPu(upperBound / maxValue),
                    selected: true
                };
            });


            const legendsContainer = d3.select(`.${chartClassName}`).append("div")
                .attr('class', 'heatmaplegend-container');

            legendsContainer.selectAll('.heatmaplegend-container')
                .data(categories)
                .enter()
                .append("div")
                .style("width", parseInt(width / categories.length))
                .attr("class", "heatmap-legend-column");
            legendsContainer.selectAll('.heatmap-legend-column')
                .data(categories)
                .append("div")
                .style('background-color', (d) => d.color)
                .attr("class", "heatmap-pattern");
            legendsContainer.selectAll('.heatmap-legend-column')
                .data(categories)
                .append("div")
                .attr('class', 'legend-text')
                .text((d) => `< ${parseInt(d.upperBound)}`);

        }

    }, [chartClassName, chartData, chartWidth, clientHeight, onFilter, theme.palette.background.paper, theme.palette.border.default]);

    return (
        <Grid
            style={{ padding: 20 }}
            className={
                classNames(
                    classes.chartStyles,
                    classes.widgetContainer,
                    chartClassName
                )
            }
        >
            {
                chartData.length === 0 && (
                    <img src={noprofile} alt="" style={{ maxWidth: "100%" }} />
                )
            }
            <Grid id={chartClassName}
                className={
                    classNames(
                        `${chartClassName}_chart`
                    )
                }
                style={{ height: height - 80 }}
            />
        </Grid>
    );

};

GroupHeatMapChart.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.array,
    chartClassName: PropTypes.string,
    clientHeight: PropTypes.number,
    theme: PropTypes.object,
    chartWidth: PropTypes.number,
    height: PropTypes.number,
    onFilter: PropTypes.func
};

export default withStyles(
    (theme) => ({
        ...ChartStyles(theme),
        ...Styles(theme)
    }),
    { withTheme: true }
)(GroupHeatMapChart);