const DataPreparationStyles = (theme) => ({
    root: {
        flexGrow: '1 auto',
        height: '100%'
    },
    datasetCard: {
        marginBottom: 15,
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.default}`,
        padding: 10,
        boxShadow: '0px 0px 6px #e1e5e6',
        position: 'relative',
        '& button': {
            position: 'absolute',
            right: 0,
            display: 'none',
            top: 15
        },
        '&:hover button': {
            display: 'block'
        }
    },
    cardSourceTxt: {
        color: theme.palette.grey.main,
        fontSize: 13,
        width: 180,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    joinIcon: {
        boxShadow: '0px 0px 6px #e1e5e6',
        width: 75,
        height: 75,
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.paper,
        position: 'relative'
    },
    connector: {
        position: 'absolute',
        content: '""',
        width: 12,
        height: 12,
        backgroundColor: theme.palette.primary.main,
        borderRadius: 50,
        border: `2px solid ${theme.palette.background.paper}`
    },
    joinContainer: {
        height: 'calc(100vh - 230px)',
        display: 'flex',
        alignItems: 'center'
    },
    targetCard: {
        minHeight: 80
    },
    databaseIcon: {
        backgroundColor: theme.palette.grey.light,
        padding: 10,
        borderRadius: 50,
        width: 45,
        height: 45,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    titleTxt: {
        width: 180,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },
    transformDialog: {
        padding: 15,
        width: 420
    },
    transformModelContainer: {
        padding: 16,
        minWidth: 420
    },
    tableTitleTxt: {
        fontWeight: 500
    },
    tableCellTitle: {
        fontWeight: 500,
        color: theme.palette.grey.darkTxt
    },
    cloneModalContainer: {
        padding: 15
    }
});

export default DataPreparationStyles;