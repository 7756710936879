import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    withStyles, Grid, Typography, Button, Table, TableHead, FormControlLabel,
    TableBody, TableRow, TableCell, IconButton, TableSortLabel, MenuItem, TextField, Switch
} from '@material-ui/core';
import DropZone from 'react-dropzone';
import classnames from 'classnames';
import moment from 'moment-timezone';


import ConnectorStyles from '../ConnectorStyles.jsx';
import FileConnectorStyles from './FileConnectorStyles.jsx';
import FileUpload from '../../../assets/images/file-upload.svg';
import { appConstants } from '../../../constants/appConstants.js';
import Styles from '../../../layouts/Styles.jsx';
import { appConfig } from '../../../config/appConfig.js';
import { uploadFiles, getConnectedFiles } from '../../../actions/connectorActions';
import { createDataSource } from '../../../actions/datasourceActions.js';
import { deleteDataSet } from '../../../actions/datasetActions.js';
import Loader from '../../Loaders/Loader.jsx';
import AlertDialog from '../../AlertDialog/AlertDialog.jsx';
import CheckboxComponent from '../../ChecboxComponent/CheckboxComponent.jsx';
import { sortTable } from '../../../helpers/appHelpers.js';
import ToolTipComponent from '../../Tooltip/Tooltip.jsx';


const FileConnector = (props) => {
    const { classes, datasourceType, datasourceId, onConnect, onCancel, theme } = props;
    const dispatch = useDispatch();
    const [isAllSelected, setIsAllSelected] = useState(true);
    const [datasets, setDatasets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [editable] = useState(true);
    const [selectedDataSet, setSelectedDataSet] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const [filterSelected, setFilterSelected] = useState(false);
    const userConfig = useSelector(({ setting }) => setting.user_config);

    const onChangeOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    const onFilter = useCallback((_event) => {
        setFilterSelected(!filterSelected);
    }, [filterSelected]);

    /**
     * Converts the bytes into KB or MB in sizes
     * @param {Number} bytes - The size of the file in bytes
     */
    const bytesToSize = useCallback((bytes) => {
        if (bytes === 0) {
            return;
        }
        const sizes = ['Bytes', 'KB', 'MB'];
        const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
        if (i === 0) {
            return `${bytes} ${sizes[i]}`;
        }
        return `${(bytes / (1024 ** i)).toFixed(2)} ${sizes[i]}`;
    }, []);

    /**
     * Handles drop event of the file uploader
     * @param {Array} acceptedFiles - List of accepted files
     *
     */
    const onDrop = useCallback((acceptedFiles) => {
        const files = [...datasets];

        const existingFiles = [];
        for (const file of acceptedFiles) {
            const isUploaded = files.filter((p) => p.name.toLowerCase() === file.name.toLowerCase()).length > 0;
            if (isUploaded) {
                existingFiles.push(file.name);
                continue;
            }

            const fileParts = file.name.split('.');
            let fileType = fileParts[fileParts.length - 1];
            fileType = appConstants.acceptedFileTypes.find((p) => p.type.toLowerCase().includes(fileType.toLowerCase()));
            files.push({
                name: file.name,
                size: file.size,
                type: file.type,
                modifiedDate: moment.utc(file.lastModifiedDate),
                inputFile: file,
                fileType,
                isSelected: true
            });
        }
        if (existingFiles && existingFiles.length > 0) {
            setAlertMessage(`${existingFiles.join(', ')} has already been connected! Please rename the file and connect it!`);
            setShowAlert(true);
        }

        setDatasets([...files]);
    }, [datasets]);

    /**
     * Handles the cancel event of alert dialog
     */
    const onAlertCancel = useCallback(() => {
        setShowAlert(false);
        setTimeout(() => {
            if (selectedDataSet) {
                setSelectedDataSet(null);
            }
            setAlertMessage('');
        }, 200);
    }, [selectedDataSet]);

    /**
     * Removes the selected file
     * @param {Object} dataset - This file object
     */
    const removeFile = useCallback((dataset) => {
        const files = [...datasets];
        const index = files.indexOf(dataset);
        if (index > -1) {
            files.splice(index, 1);
            setDatasets([...files]);
            if (dataset.dataset_id) {
                setHasChanges(true);
                dispatch(deleteDataSet({ datasets: [dataset.dataset_id] }));
                onAlertCancel();
            }
        }
    }, [datasets, dispatch, onAlertCancel]);


    const deleteSelectedDataset = useCallback((dataset) => {
        if (!dataset.dataset_id) {
            removeFile(dataset);
            return;
        }
        setAlertMessage(`Are you sure you want to delete the selected dataset ${dataset ? dataset.name : ''}?`);
        setShowAlert(true);
        setSelectedDataSet(dataset);
    }, [removeFile]);


    const onSelectionChange = useCallback((selectedDataset) => {
        for (const dataset of datasets) {
            if (dataset.name.toLowerCase() === selectedDataset.name.toLowerCase()) {
                dataset.isSelected = !dataset.isSelected;
            }
        }
        setIsAllSelected(datasets.filter((p) => p.isSelected).length === datasets.length);
        setDatasets([...datasets]);
    }, [datasets]);

    /**
     * Handles the select all files option
     */
    const onSelectAll = useCallback(() => {
        setIsAllSelected(!isAllSelected);
        for (const dataset of datasets) {
            dataset.isSelected = !isAllSelected;
        }
        setDatasets(datasets);
    }, [datasets, isAllSelected]);

    /**
     * Upload all the files into server and
     * Creates a new data source
     */
    const connectFiles = useCallback((datasourceId) => {
        const files = new FormData();
        for (const dataset of datasets) {
            dataset.datasourceType = datasourceType.type;
            files.append(dataset.name, dataset.inputFile);
        }
        files.append('source_id', datasourceId);
        files.append('configuration', JSON.stringify(datasets));
        setIsLoading(true);
        dispatch(uploadFiles(files)).then((uploadedFiles) => {
            if (!uploadedFiles) {
                setIsLoading(false);
                return;
            }
            if (onConnect) {
                onConnect(datasourceId, true);
            }
        }).catch(() => setIsLoading(false));
    }, [datasets, datasourceType, dispatch, onConnect]);


    /**
     * Creates a new file data source with uploaded files
     * @param {Array} uploadedFiles - List of uploaded files
     */
    const createFileDataSource = useCallback(() => {
        const datasource = {
            name: appConfig.defaultDataSourceName,
            type: datasourceType.type,
            description: ''
        };
        setIsLoading(true);
        if (!datasourceId) {
            dispatch(createDataSource(datasource)).then((datasource) => {
                if (!datasource) {
                    setIsLoading(false);
                    return;
                }
                connectFiles(datasource.id);
            }).catch(() => setIsLoading(false));
        } else {
            connectFiles(datasourceId);
        }

    }, [connectFiles, datasourceId, datasourceType, dispatch]);

    const loadDatasets = useCallback((datasets) => {
        for (const dataset of datasets) {
            dataset.isSelected = true;
            dataset.include_organization_domain_score = (dataset.include_organization_domain_score || dataset.include_organization_domain_score === false) ? dataset.include_organization_domain_score : true;
        }
        setDatasets([...datasets]);
        setHasChanges(false);
    }, [setDatasets]);

    useEffect(() => {
        if (!datasourceId) {
            return;
        }
        setIsPageLoading(true);
        dispatch(getConnectedFiles(datasourceId)).then((response) => {
            if (response) {
                loadDatasets(response);
            }
            setIsPageLoading(false);
        });
    }, [datasourceId, dispatch, loadDatasets]);

    const onUpdateDataset = useCallback((dataset, property, value) => {
        const tables = [...datasets];
        const index = tables.indexOf(dataset);
        if (index > -1) {
            dataset[property] = value;
            if (property === 'con_type') {
                dataset['con_type'] = value;
            }
            tables.splice(index, 1, { ...dataset });
            setDatasets([...tables]);
        }
    }, [datasets, setDatasets]);

    const selectedDatasets = datasets.filter((dataset) => dataset.isSelected);
    const getDatasets = () => {
        let filterdDatasetList = sortTable(datasets, order, orderBy);
        let otherDatasets = [];
        if (filterSelected) {
            otherDatasets = filterdDatasetList.filter((dataset) => !dataset.isSelected);
            filterdDatasetList = filterdDatasetList.filter((dataset) => dataset.isSelected);
            filterdDatasetList = [...filterdDatasetList, ...otherDatasets];
        }
        return filterdDatasetList;
    };
    return (
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" style={{ height: '100%' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <DropZone accept=".csv, .xlsx, .xls, .pdf, .json, .xml"
                    maxSize={200 * (1024 * 1024)}
                    onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
                    disabled={!editable || isLoading}>
                    {
                        ({ getRootProps, getInputProps }) => (
                            <Grid className={classnames(classes.droparea, (datasets.length > 0) ? classes.hasDatasets : null)}
                                container
                                direction="row"
                                alignItems="center"
                                justify="center"
                                {...getRootProps()}>
                                <input {...getInputProps()} />
                                <Grid container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    wrap="nowrap"
                                    className={classes.dropareaContent}>
                                    <Grid item>
                                        <img src={FileUpload} alt="file upload" className={classes.dropareaImage} />
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h3" className={classes.dropareaText}>
                                            {'Drag & drop files here or'}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" color="primary">
                                            {'Choose File(s)'}
                                        </Button>
                                    </Grid>

                                    <Grid item className={classes.hintContainer}>
                                        <Grid container direction="column" wrap="nowrap" justify="flex-start" alignItems="baseline">
                                            <Typography variant="h3" className={classes.hintText}>
                                                {'Supported file types: xls, csv'}
                                            </Typography>
                                            <Typography variant="h3" className={classes.hintText}>
                                                {'Max. file size limit : 100MB'}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        )
                    }
                </DropZone>
            </Grid>
            {
                (datasets.length > 0) ?
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classnames(classes.tableContainer, classes.tableWrapperStyle)}>
                        <Grid justify="flex-end" style={{ display: 'flex' }}>
                            <FormControlLabel
                                control={
                                    <CheckboxComponent
                                        className={"attributeSelectorCheck"}
                                        checked={filterSelected}
                                        onClick={onFilter}
                                        checkboxLabel={''}
                                    />
                                }
                                label="Show Selected"
                            />
                        </Grid>
                        <Table stickyHeader className={classnames(classes.connectorTable, classes.tableStyle)}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <CheckboxComponent checked={isAllSelected} onClick={() => onSelectAll()} />
                                        {/* <Checkbox checked={isAllSelected} onClick={() => onSelectAll()} /> */}
                                    </TableCell>
                                    {
                                        appConstants && appConstants.fileConnectorTableHeadersObj &&
                                        appConstants.fileConnectorTableHeadersObj.filter((elem) => (userConfig.include_organization_domain_score ? true : elem.id !== 'include_organization_domain_score')).map((header, index) => {
                                            return (
                                                <TableCell key={`fileConnectorTable${index}`}
                                                    className={(index !== 0 && index !== 3)}
                                                    sortDirection={orderBy === header.id ? order : false}>
                                                    {
                                                        header.sort === false ?
                                                            header.label :
                                                            <TableSortLabel
                                                                active={orderBy === header.id}
                                                                direction={orderBy === header.id ? order : 'asc'}
                                                                onClick={() => onChangeOrder(header.id)}
                                                            >
                                                                {header.label}
                                                            </TableSortLabel>
                                                    }
                                                </TableCell>
                                            );
                                        })
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    datasets && getDatasets().map((dataset, index) => {
                                        return (

                                            <TableRow key={`file${index}`}>
                                                <TableCell>
                                                    <CheckboxComponent checked={dataset.isSelected} onClick={() => onSelectionChange(dataset)} />
                                                    {/* <Checkbox color="primary" checked={dataset.isSelected} onClick={() => onSelectionChange(dataset)} /> */}
                                                </TableCell>
                                                <TableCell>
                                                    <ToolTipComponent title={dataset.name} arrow placement="bottom-start">
                                                        <Grid className={classes.oneLineEllip}>
                                                            {dataset.name}
                                                        </Grid>
                                                    </ToolTipComponent>
                                                </TableCell>
                                                <TableCell>
                                                    {`${dataset.fileType.description} (${dataset.fileType.type})`}
                                                </TableCell>
                                                <TableCell>
                                                    {bytesToSize(dataset.size)}
                                                </TableCell>
                                                <TableCell>
                                                    {moment(dataset.modifiedDate).format(appConfig.dateTimeFormat)}
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        name="con_type"
                                                        value={dataset.con_type ? dataset.con_type : appConstants.connectionType[0].value}
                                                        onClick={(event) => event.stopPropagation()}
                                                        select
                                                        disabled={!dataset.isSelected}
                                                        onChange={(event) => onUpdateDataset(dataset, event.target.name, event.target.value)}
                                                        fullWidth
                                                    >
                                                        {
                                                            appConstants.connectionType.map((conType, index) => {
                                                                return (
                                                                    <MenuItem key={`conType${index}`} value={conType.value}>
                                                                        {conType.name}
                                                                    </MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </TextField>
                                                </TableCell>
                                                {
                                                    userConfig.include_organization_domain_score &&
                                                    <TableCell align="center" onClick={(event) => event.stopPropagation()}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={dataset.include_organization_domain_score}
                                                                    onChange={(event) => { onUpdateDataset(dataset, 'include_organization_domain_score', !dataset.include_organization_domain_score); }}
                                                                    name="include_organization_domain_score"
                                                                    disabled={!dataset.isSelected}
                                                                />
                                                            }
                                                        />
                                                    </TableCell>
                                                }
                                                <TableCell>
                                                    <IconButton
                                                        disabled={isLoading}
                                                        onClick={() => deleteSelectedDataset(dataset)}>
                                                        {/* <img src={Delete} alt="delete" /> */}
                                                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19.408 22">
                                                            <g transform="translate(-76.035 -52)">
                                                                <path d="M128.865,166H114.641a.549.549,0,0,0-.536.61l1.459,12.768a2.789,2.789,0,0,0,2.77,2.474h7.082a2.777,2.777,0,0,0,2.775-2.525L129.4,166.6a.554.554,0,0,0-.138-.421A.546.546,0,0,0,128.865,166Zm-1.748,13.219a1.708,1.708,0,0,1-1.7,1.555h-7.082a1.715,1.715,0,0,1-1.7-1.523l-1.39-12.173h13.028Z" transform="translate(-36.014 -107.853)" fill="#F38080" />
                                                                <path d="M94.9,54.7H89.19v-1.03A1.682,1.682,0,0,0,87.51,52H83.968a1.682,1.682,0,0,0-1.68,1.667V54.7H76.574a.539.539,0,0,0,0,1.078H94.9a.539.539,0,0,0,0-1.078Zm-6.792,0H83.366v-1.03a.6.6,0,0,1,.6-.588H87.51a.6.6,0,0,1,.6.588Z" fill="#F38080" />
                                                            </g>
                                                        </svg> */}
                                                        <ToolTipComponent title="Delete" arrow>
                                                            <svg version="1.1" id="Layer_1" width="22" height="22" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
                                                                <g id="Delete">
                                                                    <path fill={theme.palette.primary.main}
                                                                        stroke={theme.palette.primary.main}
                                                                        strokeWidth="0.92"
                                                                        strokeMiterlimit="10"
                                                                        d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                                                    <path fill={theme.palette.primary.main}
                                                                        stroke={theme.palette.primary.main}
                                                                        strokeWidth="0.92"
                                                                        strokeMiterlimit="10"
                                                                        d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                                </g>
                                                            </svg>
                                                        </ToolTipComponent>
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                    </Grid>
                    : null
            }
            {
                (datasourceId || (datasets.length > 0)) &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container direction="row" justify="flex-end">
                        <Grid item className={classes.btnContainer}>
                            <Button variant="contained"
                                disabled={isLoading || selectedDatasets.length === 0}
                                color="primary"
                                onClick={() => createFileDataSource()}
                                className={classnames(classes.actionButtons)}>
                                {'Connect'}
                                {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button className={classnames(classes.cancelButton, classes.actionButtons)}
                                disabled={isLoading}
                                onClick={() => onCancel(hasChanges)}>
                                {'Cancel'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }

            <AlertDialog title={selectedDataSet ? "Delete Dataset" : "File Connector"}
                message={alertMessage}
                okButtonText="OK"
                cancelButtonText={selectedDataSet ? "Cancel" : ""}
                show={showAlert}
                onClickOK={
                    () => {
                        if (selectedDataSet) {
                            removeFile(selectedDataSet);
                        } else {
                            onAlertCancel();
                        }
                    }
                }
                onClickCancel={() => onAlertCancel()} />
            {isPageLoading && <Loader />}
        </Grid>
    );

};

FileConnector.propTypes = {
    classes: PropTypes.object,
    datasourceType: PropTypes.object,
    datasourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onConnect: PropTypes.func,
    onCancel: PropTypes.func,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...FileConnectorStyles(theme),
    ...ConnectorStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(FileConnector);