import React, { useCallback, useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography, Table, TableRow, TableHead, TableCell, TableBody, TextField, Card, IconButton } from '@material-ui/core';
import { useDispatch } from 'react-redux';
// import AddIcon from '@material-ui/icons/Add';
import Styles from '../../layouts/Styles.jsx';
import MasterStyles from './MasterStyles.jsx';
import classNames from 'classnames';
import { getAttributes, deleteAttribute, updateAttributesPosition, setMasterAttributes, createModelAttributeFields } from '../../actions/masterActions';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { ValidatorForm } from 'react-material-ui-form-validator';
import DroppableTableBody from './MasterGroup/DroppableTableBody.jsx';
import MasterAttributeCollapsibleRow from './MasterAttributeCollapsibleRow.jsx';
import { sortTable } from '../../helpers/appHelpers.js';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';
import Loader from '../Loaders/Loader.jsx';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';

const MasterAttributes = (props) => {
    const { classes, tabIndex, modelId, isEditable, fields, onChangeModel, theme } = props;
    const headers = ['Attribute Name', 'Data Type', 'Length', 'Parent Key', 'Primary Key', 'Group Name', 'Sensitivity', 'Occurance', 'Weightage', 'Fuzzy', 'Actions'];
    // const defaultAttributes = useState({ 'position': 1, "name": '', "data_type": '', "length": '', "parent_key": false, "primary_key": false, "group_name": '', "sensitivity": 1, "occurance": 1, "score": 0, 'is_fuzzy': false });
    const [attributes, setAttributes] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [selectedFields, setSelectedFields] = useState([]);

    const prepareFields = () => {
        const attributeNameList = [...attributes, ...selectedFields].map((attribute) => attribute.name);
        return fields.filter((attribute) => !attributeNameList.includes(attribute.name));
    };


    const updatePosition = useCallback((attributes) => {
        const positions = [];
        for (const attribute of attributes) {
            positions.push({
                id: attribute.id,
                position: attribute.position
            });
        }
        const attributesPosition = {
            'attributes': [...positions],
            'model_id': modelId
        };
        dispatch(updateAttributesPosition(attributesPosition));
    }, [dispatch, modelId]);

    const changePosition = (attributes, sort = true) => {
        let position = 1;
        if (sort) {
            attributes = attributes.sort((a, b) => a.position - b.position);
        }
        for (const attribute of attributes) {
            attribute.position = position;
            position += 1;
        }
        return attributes;
    };

    const addMasterFields = () => {
        const getLength = (field) => {
            const { max_length, min_length, min_range, max_range } = field;
            let length = 'NA';
            if (
                (max_length && (max_length !== '')) &&
                (min_length && (min_length !== ''))
            ) {
                length = `${min_length} - ${max_length}`;
            }
            if (
                (max_range && (max_range !== '')) &&
                (min_range && (min_range !== '')) &&
                length === 'NA'
            ) {
                length = `${min_length} - ${max_length}`;
            }
            return length;
        };

        const fields = selectedFields.map((field, index) => {
            return {
                'position': attributes.length + index + 1,
                "name": field.name,
                "data_type": field.datatype ? field.datatype : "Text",
                "length": getLength(field),
                "parent_key": false,
                "primary_key": field.is_primary ? field.is_primary : false,
                "group_name": '',
                "sensitivity": field.sensitivity,
                "occurance": 1,
                "score": 0,
                'is_fuzzy': false,
                "semantic_attribute_id": field.id ? field.id : ''
            };
        });
        const requestParams = {
            'model_id': modelId,
            'attributes': fields
        };
        setSelectedFields([]);
        dispatch(createModelAttributeFields(requestParams)).then((response) => {
            if (response) {
                for (const attribute of response) {
                    attribute.isOpen = false;
                }
                const attributeList = [...response, ...attributes];
                setAttributes([...changePosition(attributeList)]);
                onChangeModel('status', 'work in progress');
            }
        });
    };

    const onChangeProperties = useCallback((selectedAttribute) => {
        let attributeIndex = attributes.findIndex((p) => p.id === selectedAttribute.id);
        if (!selectedAttribute.id) {
            attributeIndex = attributes.length - 1;
        }
        if (attributeIndex >= 0) {
            attributes.splice(attributeIndex, 1, { ...selectedAttribute });
        }
        onChangeModel('status', 'work in progress');
    }, [attributes, onChangeModel]);

    const onCreateAttribute = useCallback((attribute) => {
        attributes.splice(attributes.length - 1, 1, { ...attribute });
        const orderedAttributes = changePosition(attributes);
        setAttributes([...orderedAttributes]);
        dispatch(setMasterAttributes(orderedAttributes));
        onChangeModel('status', 'work in progress');
    }, [attributes, dispatch, onChangeModel]);

    const onGroupChange = useCallback((property, selectedAttribute) => {
        const attributeIndex = attributes.findIndex((p) => p.id === selectedAttribute.id);
        if (attributeIndex >= 0) {
            attributes.splice(attributeIndex, 1, { ...selectedAttribute });
        }
        if (property === 'primary_key') {
            for (const attribute of attributes) {
                if (attribute.id !== selectedAttribute.id) {
                    attribute['primary_key'] = false;
                }
            }
        }
        setAttributes([...changePosition(attributes)]);
        onChangeModel('status', 'work in progress');
    }, [attributes, onChangeModel]);

    const prepareAttributes = useCallback((attributes) => {
        const hashTable = Object.create(null);
        attributes.forEach((attribute) => hashTable[attribute.name] = { ...attribute, childAttributes: [] });
        const attributesTree = [];
        attributes.forEach((attribute) => {
            if (attribute.group_name) {
                hashTable[attribute.group_name].childAttributes.push(hashTable[attribute.name]);
            }
            else {
                attributesTree.push(hashTable[attribute.name]);
            }
        });
        return attributesTree;
    }, []);

    const getAllAttributes = useCallback(() => {
        dispatch(getAttributes(modelId)).then((response) => {
            if (response) {
                const orderedItems = sortTable([...response], 'asc', 'position');
                for (const attribute of response) {
                    attribute.isOpen = false;
                }
                setAttributes([...orderedItems]);
            }
            setIsLoading(false);
        });
    }, [dispatch, modelId]);

    const onAlertCancel = useCallback(() => {
        setTimeout(() => {
            setSelectedAttribute(null);
        }, 200);
    }, []);

    useEffect(() => {
        if (tabIndex !== 1) {
            return;
        }
        setIsLoading(true);
        getAllAttributes();
    }, [tabIndex, getAllAttributes]);

    const deleteModelAttribute = useCallback((attribute) => {
        for (const childAttribute of attribute.childAttributes) {
            if (childAttribute.id) {
                dispatch(deleteAttribute(modelId, childAttribute.id));
            }
            const attributeIndex = attributes.findIndex((p) => p.id === childAttribute.id);
            if (attributeIndex >= 0) {
                attributes.splice(attributeIndex, 1);
            }
        }
        if (attribute.id) {
            dispatch(deleteAttribute(modelId, attribute.id));
        }
        let attributeIndex = attributes.findIndex((p) => p.id === attribute.id);
        if (!attribute.id) {
            attributeIndex = attributes.length - 1;
        }
        if (attributeIndex >= 0) {
            attributes.splice(attributeIndex, 1);
        }
        const orderedAttributes = changePosition(attributes);
        setAttributes([...orderedAttributes]);
        dispatch(setMasterAttributes(orderedAttributes));
        onAlertCancel();
        onChangeModel('status', 'work in progress');
    }, [attributes, dispatch, modelId, onAlertCancel, onChangeModel]);


    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = useCallback((result, groupAttributes) => {
        if (!result.destination) {
            return;
        }
        let orderedItems = reorder(
            groupAttributes,
            result.source.index,
            result.destination.index
        );
        orderedItems = changePosition(orderedItems, false);
        for (const attribute of orderedItems) {
            const attributeIndex = attributes.findIndex((p) => p.name === attribute.name);
            if (attributeIndex >= 0) {
                attributes.splice(attributeIndex, 1, { ...attribute });
            }
        }
        setAttributes([...attributes]);
        updatePosition([...orderedItems]);
    }, [attributes, updatePosition]);


    const handleKeyDown = (event) => {
        if (event.keyCode === 13 && selectedFields.length) {
            addMasterFields();
        }
    };

    const groupedAttributes = prepareAttributes(attributes);
    return (
        <Grid container direction="column" style={{ position: 'relative' }} >
            <Card className={classNames(classes.standardContainer)}>
                <ValidatorForm name="master_attributes" style={{ width: '100%' }} onSubmit={() => { }}>
                    {/* {
                    isEditable &&
                    <Grid item xs={12} align="right">
                        <IconButton onClick={() => addAttribute()} className={classes.addIconButton}>
                            <AddIcon />
                        </IconButton>
                    </Grid>
                } */}
                    <Grid container direction="row-reverse">
                        <Grid item xs={4}>
                            {
                                isEditable &&
                                <Grid>
                                        <AutoCompleteInput
                                            name="fields"
                                            popperWidth={300}
                                            options={prepareFields()}
                                            getOptionLabel={
                                                (option) => {
                                                    if (option && option.name) {
                                                        return option.name;
                                                    }
                                                    return option;
                                                }
                                            }
                                            value={selectedFields ? selectedFields : []}
                                            getOptionSelected={
                                                (option, value) => {
                                                    if (option && value) {
                                                        return option.name === value.name;
                                                    }
                                                    return false;
                                                }
                                            }
                                            renderInput={
                                                (params) => <TextField {...params}
                                                    className={classNames(classes.inputOutline, classes.assetInput, classes.fontSize13, classes.hoverBorderNone, classes.semanticsMultiSelect)}
                                                    placeholder="Select and Add Attribute"
                                                />
                                            }
                                            onChange={(_, selectedValue) => setSelectedFields(selectedValue)}
                                            openOnFocus
                                            blurOnSelect
                                            disableClearable
                                            multiple
                                            fullWidth
                                            forcePopupIcon={false}
                                            noOptionsText={""}
                                            onKeyDown={handleKeyDown}
                                        />
                                    {/* <img className={classes.addIcon} src={searchIco} alt="add-field" onClick={() => (selectedFields.length ? addMasterFields() : null)} width={35} /> */}
                                    {
                                    selectedFields.length > 0 &&
                                        <IconButton className={classes.addIcon} onClick={() => (selectedFields.length ? addMasterFields() : null)} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                        <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                                            <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                                                <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                                                <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                                            </g>
                                                        </g>
                                                    </svg>
                                        </IconButton>
                                    }
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classes.marginTop15}>
                        <Grid container direction="row" justify="center" className={classes.masterAttributesTableContainer}>
                            <Table stickyHeader className={classNames(classes.propertyListPageTable, classes.defineTable, classes.standardTable)}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classNames(classes.dragHandlerContainer, 'dragHandlerContainer')}>
                                            <DragIndicatorIcon />
                                        </TableCell>
                                        <TableCell className={classes.attributeHeader}>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'Field Name'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'Data Type'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: 50 }}>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'Length'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'Parent Key'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'Primary Key'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'Group Name'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: '15%' }}>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'Sensitivity'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: '15%' }}>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'Occurance'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ width: '15%' }}>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'Weightage'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'Fuzzy'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="body1" className={classes.tableHeader}>
                                                {'Actions'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody component={DroppableTableBody(onDragEnd, 'master_attributes_container', groupedAttributes)}>
                                    {
                                        sortTable(groupedAttributes, 'asc', 'position').map((attribute, index) => {
                                            return (
                                                <Fragment key={index}>
                                                    <MasterAttributeCollapsibleRow
                                                        index={index}
                                                        isParrent
                                                        attribute={{ ...attribute }}
                                                        attributes={[...attributes]}
                                                        modelId={modelId}
                                                        isEditable={isEditable}
                                                        headers={headers}
                                                        onCreateAttribute={onCreateAttribute}
                                                        onDragEnd={onDragEnd}
                                                        onChange={onChangeProperties}
                                                        onGroupChange={onGroupChange}
                                                        onDelete={(attribute) => setSelectedAttribute(attribute)}
                                                    />
                                                </Fragment>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                            {
                                attributes.length === 0 &&
                                <Grid className={classes.attributesEmptyContainer}>
                                    <NoResultFound />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Card>
            {isLoading && <Loader />}
            <AlertDialog title={"Delete Attribute"}
                message={"Deleting this attribute also delete's related child attributes and groups. Are you sure you want to delete the selected attribute?"}
                okButtonText="OK"
                cancelButtonText={selectedAttribute ? "Cancel" : ""}
                show={Boolean(selectedAttribute)}
                onClickOK={
                    () => {
                        if (selectedAttribute) {
                            deleteModelAttribute(selectedAttribute);
                        } else {
                            onAlertCancel();
                        }
                    }
                }
                onClickCancel={() => onAlertCancel()} />
        </Grid >
    );
};

MasterAttributes.propTypes = {
    classes: PropTypes.object,
    modelId: PropTypes.string,
    isEditable: PropTypes.bool,
    tabIndex: PropTypes.number,
    fields: PropTypes.array,
    onChangeModel: PropTypes.func,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...MasterStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MasterAttributes);