import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import DatasourceListStyles from '../DatasourceList/DatasourceListStyles.jsx';
/*
 * import { useSelector, useDispatch } from 'react-redux';
 * import { updateViews } from '../../actions/datasourceActions';
 */
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader.jsx';
import NoResultImg from '../../assets/images/no_result.png';
import DataPreparationListDetail from './DataPreparationListDetail.jsx';


const DataPreparationList = (props) => {
    const { classes, sources, isPageLoading, history } = props;
    const getDatasourceIcon = useCallback((source) => {
        if (source.name === 'Untitled Data Source') {
            return (
                <svg id="Group_2154" data-name="Group 2154" xmlns="http://www.w3.org/2000/svg" width="34.459" height="18.227" viewBox="0 0 34.459 18.227">
                    <path id="Path_246" data-name="Path 246" d="M170.469,193.936V175.959h6.379a9.332,9.332,0,0,1,3.77.709,7.743,7.743,0,0,1,2.708,1.924,8.013,8.013,0,0,1,1.632,2.848,10.934,10.934,0,0,1,.547,3.48,10.745,10.745,0,0,1-.606,3.708,7.944,7.944,0,0,1-1.743,2.835,7.837,7.837,0,0,1-2.734,1.823,9.436,9.436,0,0,1-3.572.645Zm11.6-9.012a7.816,7.816,0,0,0-.345-2.38,5.359,5.359,0,0,0-1.013-1.873,4.518,4.518,0,0,0-1.641-1.215,5.432,5.432,0,0,0-2.213-.43h-2.972v11.847h2.967a5.237,5.237,0,0,0,2.237-.456,4.592,4.592,0,0,0,1.632-1.257,5.62,5.62,0,0,0,1-1.886,7.736,7.736,0,0,0,.344-2.355Zm18.634,7.848a8.634,8.634,0,0,1-2.077.95,8.047,8.047,0,0,1-2.369.342,7.717,7.717,0,0,1-3.5-.785,8.87,8.87,0,0,1-2.7-2.051,9.222,9.222,0,0,1-1.743-2.911,9.505,9.505,0,0,1-.618-3.367,9.224,9.224,0,0,1,.655-3.443,9.54,9.54,0,0,1,1.806-2.911,8.618,8.618,0,0,1,2.734-2.012,7.954,7.954,0,0,1,3.437-.747,7.569,7.569,0,0,1,3.474.8,8.709,8.709,0,0,1,2.677,2.081,9.661,9.661,0,0,1,1.731,2.937,9.427,9.427,0,0,1,.618,3.342,9.33,9.33,0,0,1-.606,3.342,9.226,9.226,0,0,1-1.693,2.835l2.4,2.764h-3.214Zm-4.515-1.8a4.561,4.561,0,0,0,1.249-.165,4.893,4.893,0,0,0,1.075-.443l-2.67-3.088h3.214l1.211,1.393a7.272,7.272,0,0,0,1.04-3.721,7.154,7.154,0,0,0-.358-2.253,6.262,6.262,0,0,0-1.013-1.924,5,5,0,0,0-1.608-1.342,4.483,4.483,0,0,0-2.139-.507,4.574,4.574,0,0,0-2.2.518,4.844,4.844,0,0,0-1.608,1.38,6.349,6.349,0,0,0-.977,1.937,7.421,7.421,0,0,0,.013,4.44,6.062,6.062,0,0,0,1,1.924,5,5,0,0,0,1.608,1.342A4.54,4.54,0,0,0,196.183,190.97Z" transform="translate(-170.469 -175.837)" fill="#fff" />
                    <g id="Group_2192" data-name="Group 2192" transform="translate(24.368 10.787)">
                        <path id="Path_245-2" data-name="Path 245-2" d="M236.075,204.878h3.656l6.429,7.306-3.509.009Z" transform="translate(-236.075 -204.878)" fill="#fff" />
                    </g>
                </svg>
            );
        }
        const name = source.name ? source.name.trim() : "";
        let title = name.split(' ');
        if (title.length === 1) {
            title = `${title[0][0]}${title[0][1]}`;
        }
        if (title.length >= 1) {
            title = `${title[0][0]}${title[1][0]}`;
        }

        return (
            <Typography variant="h3" className={classes.categoryListLogoTxt}>
                {title}
            </Typography>
        );
    }, [classes.categoryListLogoTxt]);

    const editData = (source) => {
        history.push({ pathname: `/datapreparation/${source.id}`, state: { source: source } });
    };

    const [limit, setLimit] = useState(10);
    const sourceList = sources.filter((value, index) => index < limit);
    const onScroll = (event) => {
        if (event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight) {
            setLimit(limit + 10);
        }
    };

    return (
        <Grid className={classes.datasouceListContainer} onScroll={onScroll}>
            {
                sourceList && sourceList.length > 0 &&
                sourceList.map((source, index) =>
                    <DataPreparationListDetail
                        key={index}
                        index={index}
                        source={source}
                        getDatasourceIcon={getDatasourceIcon}
                        editData={editData}
                    />
                )
            }
            {
                isPageLoading &&
                <SkeletonLoader />
            }
            {
                (!sourceList || sourceList.length === 0) && !isPageLoading &&
                <Grid container alignItems="center" justify="center" className={classes.noResultFound}>
                    <Grid>
                        <img src={NoResultImg} alt="No Result Found" />
                        <Typography variant="h4" align="center">
                            {'No Result Found'}
                        </Typography>
                    </Grid>
                </Grid>
            }

        </Grid>
    );
};
DataPreparationList.propTypes = {
    classes: PropTypes.object,
    sources: PropTypes.array,
    history: PropTypes.object,
    isPageLoading: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DataPreparationList);