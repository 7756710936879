import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, Typography, withStyles } from '@material-ui/core';
import DashboardStyles from './DashboardStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import moment from 'moment-timezone';
import { numberFormatter } from '../../helpers/appHelpers';
// import TimelineAnalysis from '../Charts/TimelineAnalysis.jsx';
import { AreaChart, Area, Tooltip, ResponsiveContainer } from 'recharts';


const DashboardChartTile = (props) => {
    const { classes, analysis, count, type, chartColor, isHighlight } = props;

    const CustomTooltip = (context) => {
        const { active, payload } = context;
        if (!active) { return null; }
        const toolTipPayload = payload ? payload[0] : {};
        if (toolTipPayload && toolTipPayload.payload) {
            return (
                <div className={classNames(classes.tooltip, classes.tooltipWidth)}>
                    <div>
                        Date :
                        <span>
                            {`${moment(toolTipPayload.payload.date).format('MM/DD/YYYY')}`}
                        </span>
                    </div>
                    <div>
                        Count :
                        <span>
                            {toolTipPayload.payload.count}
                        </span>
                    </div>
                </div>
            );
        }
        return null;
    };
    return (
        <Grid container className={classes.cardTile}>
            <Grid item xs={7}>
                <Typography className={classNames(classes.cardTitle, classes.fontWeight600, isHighlight ? classes.activeCard : null)}>
                    {count ? numberFormatter(count) : 0}
                </Typography>
                <Typography className={classes.cardTxt}>
                    {type ? type : ''}
                </Typography>
            </Grid>
            <Grid item xs={5} className={classes.pl15}>
                {
                    analysis.length > 0 &&
                    <ResponsiveContainer width="100%" height="100%">
                        <AreaChart
                            width={200}
                            height={60}
                            data={analysis}
                            margin={
                                {
                                    top: 5,
                                    right: 0,
                                    left: 0,
                                    bottom: 5
                                }
                            }
                        >
                            <Area type="monotone" dataKey="count" stroke={chartColor} fill={chartColor} />
                            <Tooltip content={<CustomTooltip />} wrapperStyle={{ right: "10px" }} />
                        </AreaChart>
                    </ResponsiveContainer>
                }
            </Grid>
        </Grid>
    );
};

DashboardChartTile.propTypes = {
    classes: PropTypes.object,
    count: PropTypes.number,
    type: PropTypes.string,
    analysis: PropTypes.array,
    chartColor: PropTypes.string,
    isHighlight: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}))(DashboardChartTile);