import React from "react";
import PropTypes from 'prop-types';
import { Grid, Typography, TableRow, TableCell, IconButton, AccordionSummary, MenuItem, FormControlLabel, Switch } from '@material-ui/core';
import classnames from 'classnames';
import CheckboxComponent from '../../ChecboxComponent/CheckboxComponent.jsx';
import ToolTipComponent from '../../Tooltip/Tooltip.jsx';
import { appConstants } from '../../../constants/appConstants.js';
import WarningIcon from "../../Warning/WarningIcon.jsx";
import TextBox from '../../TextBox/TextBox.jsx';


export const AccordionTable = (props) => {
    const { dataset, index, classes, isLoading, theme, onSelectionChange, deleteSelectedDataset, showCheckBox, onUpdateDataset, userConfig } = props;

    return (
        <TableRow className={classes.tableRowClass}>
            <TableCell component="td" scope="row" className={classnames(classes.paddingLeft6, classes.flexWidth)}>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                    <Grid className={classnames(classes.datasetTitleSection, classes.checkBoxTitle, classes.noPaddingLeft)}>
                        {!showCheckBox && <WarningIcon />}
                        <CheckboxComponent disable={!showCheckBox} checked={Boolean(dataset.isSelected)} onClick={(event) => event.stopPropagation()} onChange={() => onSelectionChange(dataset)} />
                        <ToolTipComponent title={dataset.name} arrow>
                            <Typography className={classnames(classes.datasetName, classes.flexWidth)} component="span">
                                {dataset.name}
                            </Typography>
                        </ToolTipComponent>
                    </Grid>
                </AccordionSummary>
            </TableCell>
            <TableCell>
                <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                    <Typography className={classes.datasetTabText}>
                        {dataset.project && dataset.project.path ? dataset.project.path : ""}
                    </Typography>
                </AccordionSummary>
            </TableCell>
            <TableCell className={classes.loadTypeInput}>
                <TextBox
                    name="con_type"
                    value={dataset.con_type ? dataset.con_type : appConstants.connectionType[0].value}
                    onClick={(event) => event.stopPropagation()}
                    select
                    disabled={!dataset.isSelected || dataset.dataset_id}
                    onChange={(event) => onUpdateDataset(dataset, event.target.name, event.target.value)}
                    fullWidth
                >
                    {
                        appConstants.connectionType.map((conType, conIndex) => {
                            return (
                                <MenuItem key={`conType${conIndex}`} value={conType.value}>
                                    {conType.name}
                                </MenuItem>
                            );
                        })
                    }
                </TextBox>
            </TableCell>
            {
                userConfig.include_organization_domain_score &&
                <TableCell align="center" onClick={(event) => event.stopPropagation()}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={dataset.include_organization_domain_score}
                                onChange={(event) => { onUpdateDataset(dataset, 'include_organization_domain_score', !dataset.include_organization_domain_score); }}
                                name="include_organization_domain_score"
                                disabled={!dataset.isSelected}
                            />
                        }
                    />
                </TableCell>
            }
            <TableCell>
                <Grid className={classnames(classes.flexBox, classes.floatRight)}>
                    <ToolTipComponent title="Delete" arrow>
                        <IconButton
                            disabled={isLoading}
                            onClick={(event) => deleteSelectedDataset(event, dataset)}>
                            <svg version="1.1"
                                id="Layer_1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                width="22px"
                                height="22px"
                                viewBox="0 0 32 32">
                                <g id="Delete">
                                    <path fill={theme.palette.primary.main}
                                        stroke={theme.palette.primary.main}
                                        strokeWidth="0.92"
                                        strokeMiterlimit="10"
                                        d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
                                                                            L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
                                                                            L22.3,25.8z" />
                                    <path fill={theme.palette.primary.main}
                                        stroke={theme.palette.primary.main}
                                        strokeWidth="0.92"
                                        strokeMiterlimit="10"
                                        d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
                                                                            c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
                                                                            c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                </g>
                            </svg>
                        </IconButton>
                    </ToolTipComponent>
                </Grid>
            </TableCell>
        </TableRow>
    );
};

AccordionTable.propTypes = {
    dataset: PropTypes.object,
    index: PropTypes.number,
    classes: PropTypes.object,
    isLoading: PropTypes.bool,
    theme: PropTypes.object,
    onSelectionChange: PropTypes.func,
    deleteSelectedDataset: PropTypes.func,
    onUpdateDataset: PropTypes.func,
    showCheckBox: PropTypes.bool,
    userConfig: PropTypes.object
};