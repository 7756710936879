const AutoCompleteCheckBoxStyles = () => ({
    formControl: {
        margin: 1,
        width: 180
    },
    filterChip: {
        position: "absolute"
    },
    searchDropdown: {
        marginLeft: 0,
        '& .MuiInputBase-input': {
            padding: '10px 15px'
        }
    },
    fullWidth: {
        width: "100%"
    },
    selectChip: {
        position: 'absolute',
        top: 25
    }
});

export default AutoCompleteCheckBoxStyles;