import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import SunBurst from '../Charts/SunBurst.jsx';
import { getSensitivityAnalysis } from '../../actions/scheduleActions';
import Loader from '../../components/Loaders/Loader.jsx';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DashboardStyles from './DashboardStyles.jsx';

const DashboardCharts = (props) => {
    const { classes } = props;
    const dispatch = useDispatch();
    const [analysis, setAnalysis] = useState({});
    const [isLoading, setLoading] = useState(true);

    const getDistribution = useCallback(() => {
        dispatch(getSensitivityAnalysis()).then((response) => {
            if (response && response.length > 0) {
                const analysisData = {
                    name: '',
                    children: []
                };
                const datasetIds = [...new Set(response.map((dataset) => dataset.dataset_id))];
                for (const dataset of datasetIds) {
                    const sensitivity1 = response.filter((data) => (dataset === data.dataset_id && data.sensitivity === 1));
                    const sensitivity2 = response.filter((data) => (dataset === data.dataset_id && data.sensitivity === 2));
                    const sensitivity3 = response.filter((data) => (dataset === data.dataset_id && data.sensitivity === 3));
                    analysisData.children.push({
                        name: 'sensitivity1',
                        value: sensitivity1.length,
                        datasetName: sensitivity1[0] ? sensitivity1[0].dataset_name : '',
                        datasourceName: sensitivity1[0] ? sensitivity1[0].datasource_name : '',
                        children: [
                            {
                                name: 'sensitivity2',
                                value: sensitivity2.length,
                                datasetName: sensitivity2[0] ? sensitivity2[0].dataset_name : '',
                                datasourceName: sensitivity2[0] ? sensitivity2[0].datasource_name : '',
                                children: [
                                    {
                                        name: 'sensitivity3',
                                        value: sensitivity3.length,
                                        datasetName: sensitivity3[0] ? sensitivity3[0].dataset_name : '',
                                        datasourceName: sensitivity3[0] ? sensitivity3[0].datasource_name : ''
                                    }
                                ]
                            }
                        ]
                    });
                }
                setAnalysis({ ...analysisData });
                setLoading(false);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getDistribution();
    }, [getDistribution]);
    return (
        <ChartContainer
            title="Sensitivity Analysis (Attribute)"
            chartData
        >
            <div className={classNames(classes.relative, classes.chartLoaderArea)}>
                {isLoading && <Loader />}
                <SunBurst profileData={analysis} />
            </div>
        </ChartContainer>
    );
};

DashboardCharts.propTypes = {
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}))(DashboardCharts);