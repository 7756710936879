import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    withStyles, Grid, Typography, Button, Table, TableHead, Card, Switch, FormControlLabel,
    TableBody, TableRow, TableCell, IconButton, RadioGroup, Radio, TableSortLabel
} from '@material-ui/core';
import classnames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import ConnectorStyles from '../ConnectorStyles.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextBox from '../../TextBox/TextBox.jsx';
import { appConstants } from '../../../constants/appConstants.js';
import Styles from '../../../layouts/Styles.jsx';
import { appConfig } from '../../../config/appConfig.js';
import { getConnectedDatasets, connectDataset, connectDatasource, getSchema, updateDatasourceProperties, getConnectionViews } from '../../../actions/connectorActions';
import { deleteDataSet } from '../../../actions/datasetActions.js';
import Loader from '../../Loaders/Loader.jsx';
import AlertDialog from '../../AlertDialog/AlertDialog.jsx';
import CheckboxComponent from '../../ChecboxComponent/CheckboxComponent.jsx';
import ToolTipComponent from '../../Tooltip/Tooltip.jsx';
import PullConfiguration from '../PullConfiguration.jsx';
import QuerySetContainer from '../QuerySetContainer.jsx';
import { AccordionTable } from "./CollapseTable.jsx";
import { sortTable } from '../../../helpers/appHelpers.js';
import { failure } from '../../../actions/baseActions';
import { alertActions } from '../../../constants/actionTypes/alertActionTypes';
import WarningIcon from '../../Warning/WarningIcon.jsx';

const SnowFlakeConnector = (props) => {
    const { classes, datasourceType, datasourceId, onConnect, onCancel, theme, history, copyConfig } = props;
    const dispatch = useDispatch();
    const licenseConfig = useSelector(({ setting }) => setting.config);
    const [connectionConfig, setConnectionConfig] = useState({
        user: '',
        password: '',
        url: '',
        database: '',
        account: '',
        warehouse: '',
        'schema_name': '',
        mode: 'datasets',
        role: '',
        metadata: false,
        scan: Boolean(licenseConfig.scan && !licenseConfig.pull),
        application: 'DQLabs'
    });

    const [pullConfiguration, setPullConfiguration] = useState(null);
    const [pushonly, setPullPush] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [showAlert, setshowAlert] = useState(false);
    const [datasets, setDatasets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [datasource, setDatasource] = useState(null);
    const [selectedDataSet, setSelectedDataSet] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const [isOnUpdate, setIsOnUpdate] = useState(Boolean(!datasourceId));
    const [isValid, setIsValid] = useState(true);
    const [isAnySelected, setIsAnySelected] = useState(false);
    const [views, setViews] = useState([]);
    const [isViewCountUpdated, setIsViewCountUpdated] = useState(false);
    const [lookupLineage, setLookupLineage] = useState('No');
    const [onUpdate, setOnUpdate] = useState(false);
    const [filterSelected, setFilterSelected] = useState(false);
    const userConfig = useSelector(({ setting }) => setting.user_config);

    const onChangeOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    const onFilter = useCallback((_event) => {
        setFilterSelected(!filterSelected);
    }, [filterSelected]);

    const onSearchDatasets = useCallback((key) => {
        setSearchKey(key);
    }, []);

    const handleLineageChange = useCallback((value) => {
        setLookupLineage(value);
    }, []);


    /**
     * Handles the connection configuration changes
     * @param {Name of the property} property
     * @param {Value of the property} value
     */
    const handleChange = useCallback((property, value) => {
        if (isPageLoading) {
            return false;
        }
        if (datasourceId && (connectionConfig[property] !== value)) {
            setIsOnUpdate(true);
        }
        connectionConfig[property] = value;
        if (property === 'url') {
            connectionConfig.account = value.replace('.snowflakecomputing.com', '');
        }
        if (property === "mode" && value === "query") {
            connectionConfig.metadata = false;
        }
        setConnectionConfig({ ...connectionConfig });
        setHasChanges(true);
    }, [connectionConfig, datasourceId, isPageLoading]);

    const handleChangeEvent = useCallback((event) => {
        const datasetConfig = {
            'id': datasource.id,
            'pushonly': event.target.checked
        };
        setPullPush(event.target.checked);
        dispatch(updateDatasourceProperties(datasetConfig));
    }, [datasource, dispatch]);

    /**
     * Removes the selected file
     * @param {Object} dataset - This file object
     */
    const removeDataset = useCallback((dataset) => {
        let tables = [...datasets];
        const index = tables.findIndex((p) => p.dataset_id === dataset.dataset_id);

        if (index > -1) {
            if (connectionConfig && connectionConfig.mode === 'datasets' && dataset.dataset_id) {
                setHasChanges(true);
                dispatch(deleteDataSet({ datasets: [dataset.dataset_id] })).then((response) => {
                    if (response) {
                        dataset['dataset_id'] = null;
                        dataset.isSelected = false;
                        tables.splice(index, 1, { ...dataset });
                        setDatasets([...tables]);
                        setIsAllSelected(false);
                    }
                });
                setSelectedDataSet(null);
            } else {
                if (dataset.dataset_id) {
                    setHasChanges(true);
                    dispatch(deleteDataSet({ datasets: [dataset.dataset_id] })).then((response) => {
                        if (response) {
                            dataset['dataset_id'] = null;
                            dataset.isSelected = true;
                            tables.splice(index, 1);
                            if (tables && tables.length <= 0) {
                                tables = [{ ...appConstants.defaultQuerySet }];
                            }
                            setDatasets([...tables]);
                        }
                    });
                    setSelectedDataSet(null);
                } else {
                    dataset['dataset_id'] = null;
                    tables.splice(index, 1);
                    if (tables && tables.length <= 0) {
                        tables = [{ ...appConstants.defaultQuerySet }];
                    }
                    setDatasets([...tables]);
                    setSelectedDataSet(null);
                }

            }
        }
    }, [connectionConfig, datasets, dispatch]);


    const deleteSelectedDataset = useCallback((event, dataset) => {
        event.stopPropagation();
        if (!dataset.dataset_id) {
            removeDataset(dataset);
            return;
        }
        setSelectedDataSet(dataset);
    }, [removeDataset]);


    const onSelectionChange = useCallback((selectedDataset) => {
        let index = -1;
        const datasetIndex = datasets.indexOf(selectedDataset);
        for (const dataset of datasets) {
            index += 1;
            if (dataset.name.toLowerCase() !== selectedDataset.name.toLowerCase()) {
                continue;
            }
            if (datasetIndex >= 0 && index !== datasetIndex) {
                continue;
            }
            dataset.isSelected = !dataset.isSelected;
            const hasSelectedAttributes = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length > 0;
            if (dataset.isSelected && dataset.attributes && !hasSelectedAttributes) {
                for (const attribute of dataset.attributes) {
                    attribute.isSelected = true;
                }
                dataset.isAllAttributesSelected = true;
            }

            if (!dataset.isSelected && dataset.attributes) {
                for (const attribute of dataset.attributes) {
                    attribute.isSelected = false;
                }
                dataset.isAllAttributesSelected = false;
            }
        }
        setIsAllSelected(datasets.filter((p) => p.name.isSelected).length === datasets.length);
        setDatasets([...datasets]);
    }, [datasets]);

    const onUpdateDataset = useCallback((dataset, property, value) => {
        const tables = [...datasets];
        const index = tables.indexOf(dataset);
        if (index > -1) {
            dataset[property] = value;
            if (property === 'load_type' && dataset.incremental_columns && dataset.incremental_columns.length > 0) {
                dataset['watermark_column'] = (value === appConstants.loadType[1]) ? dataset.incremental_columns[0] : '';
            }
            if (property === 'load_type' && dataset.load_type === null) {
                // setOnUpdate(true);
                dataset['load_type'] = "Full Load";
            } else if (property === 'load_type') {
                // setOnUpdate(true);
                dataset['load_type'] = value;
            }
            if (property === 'con_type') {
                // setOnUpdate(true);
                dataset['con_type'] = value;
            }
            if (property === 'pull_config' && dataset.pull_config) {
                // setOnUpdate(true);
                dataset['configuration_detail'] = value;
            }
            if (property === 'query_string') {
                // setOnUpdate(true);
                dataset['query_string'] = value;
            } else if (property === 'name') {
                // setOnUpdate(true);
                dataset.name = value;
            }
            if ((property === 'include_organization_domain_score' || property === 'name' || property === 'query_string' || (property === 'pull_config' && dataset.pull_config) || property === 'con_type' || property === 'load_type') && !onUpdate) {
                setOnUpdate(true);
            }
            tables.splice(index, 1, { ...dataset });
            setDatasets([...tables]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasets]);
    /**
     * Handles the select all files option
     */
    const onSelectAll = useCallback(() => {
        setIsAllSelected(!isAllSelected);
        for (const dataset of datasets) {
            dataset.isSelected = !isAllSelected;
            const hasSelectedAttributes = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length > 0;
            if (dataset.isSelected && dataset.attributes && !hasSelectedAttributes) {
                for (const attribute of dataset.attributes) {
                    attribute.isSelected = true;
                }
            }
            if (!dataset.isSelected && dataset.attributes) {
                for (const attribute of dataset.attributes) {
                    attribute.isSelected = false;
                }
            }
            dataset.isAllAttributesSelected = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length === dataset.attributes.length;
        }
        setDatasets(datasets);
    }, [datasets, isAllSelected]);

    const loadDataSource = useCallback((datasource) => {
        if (datasource && datasource.config) {
            const config = {
                url: datasource.config.url ? datasource.config.url : '',
                user: datasource.config.user ? datasource.config.user : '',
                password: datasource.config.password ? datasource.config.password : '',
                database: datasource.config.database ? datasource.config.database : '',
                account: datasource.config.account ? datasource.config.account : '',
                warehouse: datasource.config.warehouse ? datasource.config.warehouse : '',
                'schema_name': datasource.config.schema_name ? datasource.config.schema_name : '',
                mode: datasource.config.mode ? datasource.config.mode : 'datasets',
                role: datasource.config.role ? datasource.config.role : '',
                metadata: datasource.config.metadata ? datasource.config.metadata : false,
                'source_id': datasource.id ? datasource.id : null,
                scan: datasource.config.scan ? datasource.config.scan : false,
                'connection_establish': datasource.connection_establish,
                application: datasource.config.application ? datasource.config.application : 'DQLabs'
            };

            setPullPush(datasource.pushonly);
            setConnectionConfig({ ...config });
            setDatasource({ ...datasource });
        }
    }, []);

    const loadDatasets = useCallback((datasets, connectionConfig) => {
        if (connectionConfig && connectionConfig.mode === 'query') {
            const selectedQueryDatasets = datasets.filter((d) => d.isSelected);
            datasets = [{ ...appConstants.defaultQuerySet }, ...selectedQueryDatasets];
            setIsAnySelected(datasets.filter((p) => p.isSelected).length > 0 && selectedQueryDatasets.length > 0);
        }
        if (datasets && datasets.length > 0 && connectionConfig && connectionConfig.mode === 'datasets') {
            setIsAllSelected(datasets.filter((p) => p.isSelected).length === datasets.length);
            setIsAnySelected(datasets.filter((p) => p.isSelected).length > 0);
        }
        datasets = datasets.map((elem) => {
            elem.include_organization_domain_score = (elem.include_organization_domain_score || elem.include_organization_domain_score === false) ? elem.include_organization_domain_score : true;
            return elem;
        });
        setDatasets([...datasets]);
        setHasChanges(false);
    }, []);

    const getViews = useCallback((datasourceId) => {
        dispatch(getConnectionViews(datasourceId)).then((response) => {
            if (response) {
                setViews([...response]);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        if (datasets && datasets.length > 0 && views && views.length > 0 && !isViewCountUpdated) {
            const tempDatasets = [...datasets];
            views.forEach((view) => {
                const tempDataset = tempDatasets.find((dataset) => dataset.name === view.name);
                if (tempDataset) {
                    const tempDatasetIndex = tempDatasets.indexOf(tempDataset);
                    if (tempDatasetIndex > -1) {
                        tempDataset.num_rows = view.num_rows;
                        tempDatasets.splice(tempDatasetIndex, 1, { ...tempDataset });
                    }
                }
            });
            setDatasets(tempDatasets);
            setIsViewCountUpdated(true);
        }
    }, [datasets, views, isViewCountUpdated]);

    /**
     * Connectes the source with the given credentials
     */
    const connectToDatasource = useCallback(() => {
        connectionConfig['connection_type'] = datasourceType.type;
        const datasource = {
            datasourceId: datasourceId ? datasourceId : 0,
            name: appConfig.defaultDataSourceName,
            type: datasourceType.type,
            description: '',
            config: {
                ...connectionConfig,
                account: connectionConfig.url.replace('.snowflakecomputing.com', '')
            }
        };
        setIsLoading(true);
        dispatch(connectDatasource(datasource)).then((response) => {
            if (!response) {
                setIsLoading(false);
                setIsValid(false);
                return;
            }
            connectionConfig['source_id'] = response.datasource ? response.datasource.id : null;
            connectionConfig['connection_establish'] = true;
            setConnectionConfig({ ...connectionConfig });
            setDatasource(response.datasource ? { ...response.datasource } : {});
            if (!datasourceId && connectionConfig && connectionConfig.mode === 'query') {
                loadDatasets([], connectionConfig);
                setIsLoading(false);
                setIsOnUpdate(false);
                return;
            }
            if (datasourceId) {
                dispatch(getConnectedDatasets(datasourceId)).then((response) => {
                    setIsLoading(false);
                    setIsValid(true);
                    if (response) {
                        loadDataSource(response.datasource);
                        loadDatasets(response.datasets ? [...response.datasets] : [], connectionConfig);
                        setIsOnUpdate(false);
                    }
                });
            } else {
                setIsLoading(false);
                setIsValid(true);
                setIsViewCountUpdated(false);
                loadDatasets(response.datasets ? [...response.datasets] : [], connectionConfig);
                getViews(connectionConfig['source_id']);
                setIsOnUpdate(false);
            }
        }).catch(() => {
            setIsLoading(false);
            setIsOnUpdate(true);
            setIsValid(false);
        });
    }, [connectionConfig, datasourceId, datasourceType.type, dispatch, getViews, loadDataSource, loadDatasets]);

    const displayViewRowCount = useCallback((name) => {
        const view = views.find((view) => view.name === name);
        return view && view.num_rows !== 0 ? view.num_rows : 'NA';
    }, [views]);

    /**
     * Creates a new dataset
     */
    const createDatasets = useCallback(() => {
        if (!datasourceId && !datasource) {
            return;
        }
        let selectedDatasets = datasets.filter((p) => p.isSelected);
        if (connectionConfig && connectionConfig.mode === 'query') {
            selectedDatasets = selectedDatasets.filter((p) => p.name.length > 0 && p.query_string.length > 0);
        }
        const datasetsToConnect = [];
        for (const dataset of selectedDatasets) {
            if (dataset.query_string === '') {
                dispatch(failure(alertActions.ALERT_ERROR, 'Query string is required'));
                return;
            }
            if ((dataset.load_type === "Incremental Load") && (!dataset.watermark_column)) {
                setshowAlert(true);
                return;
            }
            dataset['rows_count'] = dataset['num_rows'];
            if (!dataset.pull_config && dataset.configuration_detail) {
                dataset.pull_config = dataset.configuration_detail;
            }
            if ("attributes" in dataset && "copyattributes" in dataset) {
                dataset.attributes = dataset.copyattributes;
            }
            if (!dataset.dataset_id) {
                datasetsToConnect.push(dataset);
            } else if (dataset.dataset_id && onUpdate) {
                datasetsToConnect.push(dataset);
            }
        }
        if (datasetsToConnect.length === 0) {
            dispatch(failure(alertActions.ALERT_ERROR, 'No new datasets are found'));
            return;
        }

        let selectedDatasourceId = datasourceId;
        if (!datasourceId && datasource) {
            selectedDatasourceId = datasource.id;
        }

        const datasetConfig = {
            'datasource_id': selectedDatasourceId,
            datasets: datasetsToConnect,
            lookupLineage
        };
        setIsLoading(true);
        dispatch(connectDataset(datasetConfig)).then((response) => {
            if (!response) {
                setIsLoading(false);
                return;
            }
            if (onConnect) {
                onConnect(selectedDatasourceId, true);
            }
        }).catch(() => setIsLoading(false));
    }, [connectionConfig, datasets, datasource, datasourceId, dispatch, onConnect, lookupLineage, onUpdate]);


    useEffect(() => {
        if (copyConfig) {
            if (copyConfig.source_id) {
                delete copyConfig.source_id;
            }
            if (copyConfig.connection_establish) {
                delete copyConfig.connection_establish;
            }
            setConnectionConfig({ ...copyConfig });
        }
    }, [copyConfig]);


    useEffect(() => {
        if (!datasourceId || (connectionConfig && connectionConfig.source_id)) {
            return;
        }
        setIsPageLoading(true);
        dispatch(getConnectedDatasets(datasourceId)).then((response) => {
            setIsPageLoading(false);
            if (response) {
                loadDataSource(response.datasource);
                loadDatasets(response.datasets, response.datasource ? response.datasource.config : {});
            }
        });
        getViews(datasourceId);
        setIsViewCountUpdated(false);
    }, [connectionConfig, datasourceId, dispatch, getViews, loadDataSource, loadDatasets]);

    const getDatasetSchema = useCallback((dataset) => {
        if (!dataset || (dataset && dataset.attributes && dataset.attributes.length > 0)) {
            return;
        }

        dataset.isLoadingAttributes = true;
        const datasetIndex = datasets.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
        datasets.splice(datasetIndex, 1, { ...dataset });

        setDatasets([...datasets]);
        const datasetConfig = {
            'connection_type': datasourceType.type,
            'table': dataset.name,
            ...connectionConfig,
            ...dataset
        };
        dispatch(getSchema(datasetConfig)).then((response) => {
            if (response && response.length > 0) {
                dataset.attributes = [...response];
                dataset.copyattributes = [...response];
                const hasSelectedAttributes = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length > 0;
                if (dataset.isSelected && dataset.attributes && !hasSelectedAttributes) {
                    for (const attribute of dataset.attributes) {
                        attribute.isSelected = true;
                    }
                    for (const attribute of dataset.copyattributes) {
                        attribute.isSelected = true;
                    }
                    dataset.isAllAttributesSelected = true;
                }
            }
            dataset.isAllAttributesSelected = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length === dataset.attributes.length;
            dataset.isLoadingAttributes = false;
            const datasetIndex = datasets.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
            datasets.splice(datasetIndex, 1, { ...dataset });
            setDatasets([...datasets]);
        });

    }, [connectionConfig, datasets, datasourceType.type, dispatch]);

    const addNewQuerySet = useCallback(() => {
        if (datasets && datasets.length > 0 && datasets[0].name.length > 0 && datasets[0].query_string.length > 0) {
            datasets.splice(0, 0, { ...appConstants.defaultQuerySet });
            setDatasets([...datasets]);
        }
    }, [datasets]);

    const onKeySearchAttribute = useCallback((dataset, searchkey) => {
        const selectedAttributes = dataset.copyattributes.filter((p) => (p.name.toLowerCase().includes(searchkey.toLowerCase())));
        dataset.attributes = [...selectedAttributes];
        const selectedDatasetIndex = datasets.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
        datasets.splice(selectedDatasetIndex, 1, JSON.parse(JSON.stringify(dataset)));
        setDatasets([...datasets]);
    }, [datasets]);

    const onSelectAttribute = useCallback((dataset, attribute, attributeIndex) => {
        const attributes = [...dataset.attributes];
        const copyattributes = [...dataset.copyattributes];
        const copyattributesindex = copyattributes.findIndex((cpn) => cpn.name.toLowerCase() === attribute.name.toLowerCase());
        attribute.isSelected = !attribute.isSelected;
        attributes.splice(attributeIndex, 1, { ...attribute });
        copyattributes.splice(copyattributesindex, 1, { ...attribute });
        dataset.attributes = [...attributes];
        dataset.copyattributes = [...copyattributes];
        dataset.isAllAttributesSelected = attributes.filter((p) => p.isSelected).length === attributes.length;
        dataset.isSelected = dataset.attributes.filter((attribute) => attribute.isSelected).length === 0;

        const selectedDatasetIndex = datasets.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
        datasets.splice(selectedDatasetIndex, 1, JSON.parse(JSON.stringify(dataset)));
        setOnUpdate(true);
        setDatasets([...datasets]);
        onSelectionChange(dataset);
    }, [datasets, onSelectionChange]);

    const onSelectAllAttribute = useCallback((dataset) => {
        dataset.isAllAttributesSelected = !dataset.isAllAttributesSelected;
        for (const attribute of dataset.attributes) {
            attribute.isSelected = dataset.isAllAttributesSelected;
        }
        for (const attribute of dataset.copyattributes) {
            attribute.isSelected = dataset.isAllAttributesSelected;
        }
        dataset.isSelected = dataset.attributes.filter((attribute) => attribute.isSelected).length === 0;
        const selectedDatasetIndex = datasets.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
        datasets.splice(selectedDatasetIndex, 1, JSON.parse(JSON.stringify(dataset)));
        setDatasets([...datasets]);
        onSelectionChange(dataset);
    }, [datasets, onSelectionChange]);

    const onCloseDatasetConfigModal = useCallback((dataset) => {
        setPullConfiguration(null);
    }, []);

    const onOpenDatasetConfigModal = useCallback((event, dataset) => {
        event.stopPropagation();
        setPullConfiguration({
            anchorElement: event.target,
            dataset,
            onCancel: onCloseDatasetConfigModal,
            onSave: onUpdateDataset
        });
    }, [onCloseDatasetConfigModal, onUpdateDataset]);

    let selectedDatasets = [...datasets];
    if (searchKey) {
        selectedDatasets = datasets.filter((p) => (p.name.toLowerCase().includes(searchKey.toLowerCase()))
            || (p.attributes && p.attributes.filter((attribute) => attribute.name.toLowerCase().includes(searchKey.toLowerCase())).length > 0)
        );
    }

    ValidatorForm.addValidationRule('isValidName', (value) => (value === 'DQLabs'));

    const getDatasets = () => {
        let filterdDatasetList = sortTable(selectedDatasets, order, orderBy);
        let otherDatasets = [];
        if (filterSelected) {
            otherDatasets = filterdDatasetList.filter((dataset) => !dataset.isSelected);
            filterdDatasetList = filterdDatasetList.filter((dataset) => dataset.isSelected);
            filterdDatasetList = [...filterdDatasetList, ...otherDatasets];
        }
        return filterdDatasetList;
    };

    return (
        <ValidatorForm form="sql_config" autoComplete={"off"} onSubmit={() => connectToDatasource()} style={{ height: '100%' }}>
            <Grid container direction="column" className={classes.snowflakeConnector}>
                <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container>
                            <Grid item xs={10}>
                                <Typography component="h4" variant="h4" className={classes.connectTitle}>
                                    {'Configure SnowFlake Connection'}
                                </Typography>
                                <Typography variant="body2" className={classes.connectdescription}>
                                    {datasourceType.description}
                                </Typography>
                            </Grid>
                            {
                                datasourceId &&
                                <Grid item xs={2} className={classnames(classes.textRight, classes.duplicateDatasource)}>
                                    <ToolTipComponent title="Duplicate Datasource" arrow>
                                        <IconButton onClick={() => history.push({ pathname: '/configuredatasource', state: { datasourceType: datasourceType, con_config: connectionConfig, isCopy: true } })}>
                                            <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={theme.palette.primary.main}>
                                                <path d="M0 0h24v24H0V0z" fill="none" />
                                                <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                                            </svg>
                                        </IconButton>
                                    </ToolTipComponent>
                                </Grid>
                            }
                        </Grid>

                        <Grid container direction="row" spacing={3} style={{ marginTop: 10 }}>

                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid xs={8}>
                                        <TextBox
                                            label="URL"
                                            name="url"
                                            validators={['required']}
                                            errorMessages={['URL is required']}
                                            value={connectionConfig.url}
                                            onChange={(event) => handleChange(event.target.name, event.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid xs={8}>
                                        <TextBox
                                            disabled={isAnySelected}
                                            label="Database Name"
                                            name="database"
                                            validators={['required']}
                                            errorMessages={['Database Name is required']}
                                            value={connectionConfig.database}
                                            onChange={(event) => handleChange(event.target.name, event.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid xs={8}>
                                        <TextBox
                                            label="Username"
                                            name="user"
                                            disabled={isAnySelected}
                                            validators={['required']}
                                            errorMessages={['Username is required']}
                                            value={connectionConfig.user}
                                            onChange={(event) => handleChange(event.target.name, event.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid xs={8}>
                                        <TextBox
                                            label="Password"
                                            name="password"
                                            validators={['required']}
                                            errorMessages={['password is required']}
                                            type="password"
                                            value={connectionConfig.password}
                                            onChange={(event) => handleChange(event.target.name, event.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid xs={8}>
                                        <TextBox
                                            label="Role"
                                            name="role"
                                            disabled={isAnySelected}
                                            value={connectionConfig.role}
                                            onChange={(event) => handleChange(event.target.name, event.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid xs={8}>
                                        <TextBox
                                            disabled={isAnySelected}
                                            label="Schema Name"
                                            name="schema_name"
                                            value={connectionConfig.schema_name}
                                            onChange={(event) => handleChange(event.target.name, event.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid xs={8}>
                                        <TextBox
                                            // disabled={isAnySelected}
                                            label="Warehouse Name"
                                            name="warehouse"
                                            validators={['required']}
                                            errorMessages={['Warehouse Name  is required']}
                                            value={connectionConfig.warehouse}
                                            onChange={(event) => handleChange(event.target.name, event.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={6}>
                                <Grid container>
                                    <Grid xs={8}>
                                        <TextBox
                                            disabled={isAnySelected}
                                            label="Application Name"
                                            name="application"
                                            validators={['required', 'isValidName']}
                                            errorMessages={['Application name  is required', 'Invalid application name']}
                                            value={connectionConfig.application}
                                            onChange={(event) => handleChange(event.target.name, event.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid> */}

                            <Grid item xs={6}>
                                <Grid container className={classes.queryOptions}>
                                    <RadioGroup name="mode"
                                        value={connectionConfig && connectionConfig.mode ? connectionConfig.mode : ''}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}>
                                        <FormControlLabel disabled={datasets.length} value="datasets" control={<Radio />} label="Datasets" />
                                        {
                                            licenseConfig.query &&
                                            <FormControlLabel disabled={datasets.length} value="query" control={<Radio />} label="Query" />
                                        }
                                    </RadioGroup>
                                </Grid>
                            </Grid>

                            <Grid item xs={4}>
                                <Grid container>
                                    {
                                        licenseConfig.metadata &&
                                        <Grid item xs={4} className={classes.checkboxContainer}>
                                            <CheckboxComponent disabled={connectionConfig.mode === "query" || isAnySelected || datasets.length > 0} checked={connectionConfig.metadata} onClick={() => handleChange("metadata", !connectionConfig.metadata)} checkboxLabel="Metadata" />
                                        </Grid>
                                    }
                                    {
                                        licenseConfig.scan &&
                                        <Grid item xs={4} className={classes.checkboxContainer}>
                                            <CheckboxComponent disabled={isAnySelected || datasets.length > 0} checked={connectionConfig.scan} onClick={() => handleChange("scan", !connectionConfig.scan)} checkboxLabel="Scan" />
                                        </Grid>
                                    }
                                    {
                                        licenseConfig.lineage &&
                                        <Grid item xs={4} className={classes.checkboxContainer} style={{ display: 'none' }}>
                                            <CheckboxComponent disabled={connectionConfig.mode === "query"} checked={lookupLineage === 'Yes'} onClick={() => handleLineageChange(lookupLineage === 'Yes' ? 'No' : 'Yes')} checkboxLabel="Lineage" />
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    ((connectionConfig && connectionConfig.mode === 'datasets' && datasets.length > 0) ||
                                        (connectionConfig && connectionConfig.mode === 'query' && connectionConfig.source_id && connectionConfig.connection_establish)) &&
                                    <Grid container direction="column" className={'searchPanel'}>
                                        <Grid container direction="row" justify="space-between" alignItems="center">
                                            <Grid item xs={8}>
                                                <Grid container direction="row" justify="flex-start" alignItems="center" className={classnames(classes.searchInputContainer, classes.noMargin)}>
                                                    <TextBox placeholder={'Search Dataset'}
                                                        onChange={(event) => onSearchDatasets(event.target.value)}
                                                        name="searchKey"
                                                        id="searchKey"
                                                        value={searchKey}
                                                        className={classes.inputOutline} />

                                                    {
                                                        searchKey.length === 0 ?
                                                            <IconButton className={classes.includeSearchIcon}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.094" height="13.092" viewBox="0 0 13.094 13.092">
                                                                    <g transform="translate(0 -0.035)">
                                                                        <path fill="#afb2b3" d="M5.27,10.57A5.257,5.257,0,0,0,8.5,9.46l3.483,3.483a.655.655,0,0,0,.926-.926L9.427,8.534A5.267,5.267,0,1,0,5.27,10.57ZM2.471,2.5a3.958,3.958,0,1,1,0,5.6h0a3.944,3.944,0,0,1-.02-5.577l.02-.02Z" transform="translate(0 0)" />
                                                                    </g>
                                                                </svg>
                                                            </IconButton> :
                                                            <IconButton className={classes.includeDeleteIcon} onClick={() => onSearchDatasets('')}>
                                                                <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                                    <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                                    <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                                                </svg>
                                                            </IconButton>
                                                    }
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={4}>
                                                <Grid container direction="row" justify="space-between" alignItems="center">
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={pushonly}
                                                                onChange={handleChangeEvent}
                                                                name="pullpushonly"
                                                            // color="primary"
                                                            />
                                                        }
                                                        label="Pull and Push only"
                                                    />
                                                    {
                                                        connectionConfig && connectionConfig.mode !== 'query' &&
                                                        <FormControlLabel
                                                            control={
                                                                <CheckboxComponent
                                                                    className={"attributeSelectorCheck"}
                                                                    checked={filterSelected}
                                                                    onClick={onFilter}
                                                                    checkboxLabel={''}
                                                                />
                                                            }
                                                            label="Show Selected"
                                                        />
                                                    }
                                                    {
                                                        connectionConfig && connectionConfig.mode === 'query' &&
                                                        <ToolTipComponent title="Add Query" placement="bottom" arrow>
                                                            <IconButton className={classes.addIconButton}
                                                                classes={
                                                                    {
                                                                        root: classes.addIconButtonRoot
                                                                    }
                                                                }
                                                                onClick={() => addNewQuerySet()}>
                                                                <AddIcon className={classes.addIcon} />
                                                            </IconButton>
                                                        </ToolTipComponent>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        {
                            (isOnUpdate) &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Grid container direction="row" justify="flex-start" style={{ marginTop: 40 }}>
                                    <Grid item className={classes.btnContainer}>
                                        <Button type="submit"
                                            variant="contained"
                                            disabled={isLoading}
                                            color="primary">
                                            {'Validate'}
                                            {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button className={classnames(classes.cancelButton)}
                                            style={{ marginLeft: 20 }}
                                            disabled={isLoading}
                                            onClick={() => onCancel(hasChanges)}>
                                            {'Cancel'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>


                <Grid container direction="column" className={classnames('datasetConfiguration', (connectionConfig && connectionConfig.mode === 'query') ? classes.connectTableContainer : null)}>
                    {
                        (connectionConfig && connectionConfig.mode === 'datasets') &&
                        <Card className={classnames(classes.tableaccordian, classes.connectTableContainer, classes.table)}>

                            {
                                selectedDatasets && selectedDatasets.length >= 1 &&

                                <Table stickyHeader className={classnames(classes.datasettable, classes.designTable)} aria-label="a dense table" >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.datasetHeaderTitleCell}>
                                                <TableSortLabel
                                                    active={orderBy === "name"}
                                                    direction={orderBy === "name" ? order : 'asc'}
                                                    onClick={() => onChangeOrder("name")}
                                                >
                                                    <Grid className={classes.datasetTitleSection}>
                                                        <Grid className={classnames(classes.inlineBlock, classes.displayFlex)}>
                                                            {!isValid && <WarningIcon />}
                                                            <CheckboxComponent
                                                                className={"attributeSelectorCheck"}
                                                                checked={isAllSelected}
                                                                onClick={() => onSelectAll()}
                                                                checkboxLabel={'Datasets'}
                                                                snowFlakeConnectorCursor="text"
                                                                showCheckBox={isValid}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </TableSortLabel>
                                            </TableCell>
                                            {
                                                appConstants && appConstants.SnowFlakeConnectorTableHeadersObj &&
                                                appConstants.SnowFlakeConnectorTableHeadersObj.filter((elem) => (userConfig.include_organization_domain_score ? true : elem.id !== 'include_organization_domain_score')).map((header, index) => {
                                                    return (
                                                        <TableCell key={`sqlConnectorTable${index}`}
                                                            className={header === 'Actions' ? classes.actionItemContainer : null}
                                                            sortDirection={orderBy === header.id ? order : false}>
                                                            {
                                                                header.sort === false ?
                                                                    header.label :
                                                                    <TableSortLabel
                                                                        active={orderBy === header.id}
                                                                        direction={orderBy === header.id ? order : 'asc'}
                                                                        onClick={() => onChangeOrder(header.id)}
                                                                    >
                                                                        {header.label}
                                                                    </TableSortLabel>
                                                            }
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.datasetTableAccordHeader}>
                                        {
                                            selectedDatasets && getDatasets().map((dataset, index) => {
                                                return (
                                                    <AccordionTable key={index}
                                                        dataset={dataset}
                                                        index={index}
                                                        classes={classes}
                                                        isLoading={isLoading}
                                                        theme={theme}
                                                        rootColumns={appConstants.SnowFlakeConnectorTableHeadersObj}
                                                        getDatasetSchema={getDatasetSchema}
                                                        onSelectionChange={onSelectionChange}
                                                        deleteSelectedDataset={deleteSelectedDataset}
                                                        onOpenDatasetConfigModal={onOpenDatasetConfigModal}
                                                        onSelectAllAttribute={onSelectAllAttribute}
                                                        onSelectAttribute={onSelectAttribute}
                                                        onKeySearchAttribute={onKeySearchAttribute}
                                                        onUpdateDataset={onUpdateDataset}
                                                        showCheckBox={isValid}
                                                        displayViewRowCount={displayViewRowCount}
                                                        userConfig={userConfig}
                                                    />
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>

                            }

                        </Card>
                    }
                    {
                        (connectionConfig && connectionConfig.mode === 'query' && connectionConfig.source_id && connectionConfig.connection_establish) &&
                        <QuerySetContainer
                            datasets={selectedDatasets ? [...selectedDatasets] : []}
                            onUpdateDataset={onUpdateDataset}
                            isMultiLine
                            deleteSelectedDataset={deleteSelectedDataset}
                        />
                    }
                </Grid>
                <Grid container direction="column" className={'footer'}>
                    {
                        (selectedDatasets.length > 0 && !isOnUpdate) &&
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container direction="row" justify="flex-end">
                                <Grid item className={classes.btnContainer}>
                                    <Button variant="contained"
                                        disabled={isLoading}
                                        color="primary"
                                        onClick={() => createDatasets()}
                                        className={classnames(classes.actionButtons)}>
                                        {'Connect'}
                                        {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button className={classnames(classes.cancelButton, classes.actionButtons)}
                                        disabled={isLoading}
                                        onClick={() => onCancel(hasChanges)}>
                                        {'Cancel'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>

                <AlertDialog title="Delete Dataset"
                    message={`Are you sure you want to delete the selected dataset ${selectedDataSet ? selectedDataSet.name : ''}?`}
                    okButtonText="OK"
                    cancelButtonText="Cancel"
                    show={Boolean(selectedDataSet)}
                    onClickOK={() => removeDataset(selectedDataSet)}
                    onClickCancel={() => setSelectedDataSet(null)} />
                {isPageLoading && <Loader />}
            </Grid>
            <AlertDialog title="Alert"
                message={`Please select the fingerprint column.`}
                okButtonText="OK"
                show={Boolean(showAlert)}
                onClickOK={() => setshowAlert(false)}
                onClickCancel={() => setshowAlert(false)} />
            <PullConfiguration
                open={Boolean(pullConfiguration)}
                anchorEl={pullConfiguration && pullConfiguration.anchorElement ? pullConfiguration.anchorElement : null}
                dataset={pullConfiguration && pullConfiguration.dataset ? pullConfiguration.dataset : null}
                onSave={pullConfiguration && pullConfiguration.onSave ? pullConfiguration.onSave : null}
                onCancel={pullConfiguration && pullConfiguration.onCancel ? pullConfiguration.onCancel : null}
            />
        </ValidatorForm >
    );

};

SnowFlakeConnector.propTypes = {
    classes: PropTypes.object,
    datasourceType: PropTypes.object,
    theme: PropTypes.object,
    datasourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onConnect: PropTypes.func,
    onCancel: PropTypes.func,
    history: PropTypes.object,
    copyConfig: PropTypes.object
};

export default withStyles((theme) => ({
    ...ConnectorStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SnowFlakeConnector);