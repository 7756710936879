import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classNames from 'classnames';
import moment from 'moment';
import SemanticRuleGroup from './SemanticRuleGroup.jsx';
import { createParamRule, createParamRuleGroup, getFieldType, isRuleGroup } from './QueryBuilderUtil.jsx';
import RuleBuilderStyles from './QueryBuilderStyles.jsx';
import Styles from '../../layouts/Styles.jsx';

class SemanticQueryBuilder extends Component {
    constructor(props) {
        super(props);
        const initialRule = createParamRuleGroup(this.props.defaultFieldType ? this.props.defaultFieldType : "");
        this.state = {
            params: this.props.params && this.props.params.length ? this.props.params : [{ ...initialRule }]
        };
    }

    /*
     * static getDerivedStateFromProps(props, state) {
     *     if (props.params && props.params.id !== state.params.id) {
     *         return {
     *             ...state,
     *             params: { ...props.params }
     *         };
     *     }
     *     return {
     *         ...state,
     *         params: { ...createParamRuleGroup('') }
     *     };
     * }
     */

    componentDidMount() {
        const { defaultFieldType } = this.props;
        const ruleGroup = createParamRuleGroup(getFieldType(defaultFieldType));
        this.setState({ params: this.props.params && this.props.params.length ? [...this.props.params] : [{ ...ruleGroup }] });
    }

    onAddRule(parentRuleId) {
        const ruleGroup = [...this.state.params];
        const parentRule = this.findRule(parentRuleId, ruleGroup[0]);
        const rule = createParamRule(this.props.defaultFieldType);
        parentRule.rules.push({ ...rule });
        this.onQueryChange(ruleGroup);
    }


    onAddRuleGroup(parentRuleId) {
        const ruleGroup = [...this.state.params];
        const rule = this.findRule(parentRuleId, ruleGroup[0]);
        const childRuleGroup = createParamRuleGroup(getFieldType(this.props.defaultFieldType));
        rule.rules.push({
            ...childRuleGroup
        });
        this.onQueryChange(ruleGroup);
    }

    findRule(ruleId, parentRule) {
        if (parentRule.id === ruleId) { return parentRule; }

        for (const rule of parentRule.rules) {
            if (rule.id === ruleId) {
                return rule;
            } else if (isRuleGroup(rule)) {
                const subRule = this.findRule(ruleId, rule);
                if (subRule) { return subRule; }
            }
        }
    }

    onPropertyChange(property, value, ruleId) {
        const ruleGroup = [...this.state.params];
        const rule = this.findRule(ruleId, ruleGroup[0]);
        if (property === 'operator') {
            rule[property] = value;
            if (value && value.label && value.label.toLowerCase() === 'is between') {
                rule.value = rule.fieldType.toLowerCase() === 'datetime'
                    ? [
                        moment(),
                        moment().add(1, 'days')
                    ]
                    : ['', ''];
            } else {
                rule.value = '';
                rule['input_value'] = '';
            }
        } else if (property === 'not') {
            rule[property] = value;
            rule['not_enabled'] = value;
        }
        else {
            rule[property] = value;
        }
        this.onQueryChange(ruleGroup);
    }

    onRuleRemove(ruleId, parentId) {
        const ruleGroup = [...this.state.params];
        const parentRule = this.findRule(parentId, ruleGroup[0]);
        const ruleIndex = parentRule.rules.findIndex((rule) => rule.id === ruleId);
        parentRule.rules.splice(ruleIndex, 1);
        if (parentRule.rules.length <= 0) {
            const rule = createParamRule(this.props.defaultFieldType);
            parentRule.rules.push({ ...rule });
        }
        this.onQueryChange(ruleGroup);
    }

    onGroupRemove(groupId, parentId) {
        const ruleGroup = [...this.props.params];
        const parentRule = this.findRule(parentId, ruleGroup[0]);
        const ruleIndex = parentRule.rules.findIndex((group) => group.id === groupId);
        parentRule.rules.splice(ruleIndex, 1);
        this.onQueryChange(ruleGroup);
    }


    onQueryChange(ruleGroup, isConditionalRule) {
        if (this.props.onQueryChange) {
            this.props.onQueryChange(ruleGroup, isConditionalRule);
        }
    }

    renderQueryBuilder() {
        const { classes, operators, isLoading, showSubmitButton, onClear, theme, defaultFieldType, ...props } = this.props;
        const { params } = this.state;
        const builderConfig = {
            operators: operators ? [...operators] : [],
            classes: classes,
            onAddRule: (...props) => this.onAddRule(...props),
            onAddRuleGroup: (...props) => this.onAddRuleGroup(...props),
            onPropertyChange: (...props) => this.onPropertyChange(...props),
            onRuleRemove: (...props) => this.onRuleRemove(...props),
            onGroupRemove: (...props) => this.onGroupRemove(...props),
            ...props
        };

        return (
            <Grid container className={classNames(classes.queryBuilder)}>
                <Grid item xs={12} className={classNames('ruleQueryBuilder')}>
                    {
                        params && params.map((param, index) =>
                            <SemanticRuleGroup
                                key={index}
                                id={param.id}
                                parentId={null}
                                rules={[...param.rules]}
                                connector={param.connector}
                                className={classes.parentRule}
                                not={param.not}
                                theme={theme}
                                builderConfig={builderConfig}
                                defaultFieldType={getFieldType(defaultFieldType ? defaultFieldType : "text")} />
                        )
                    }

                </Grid>
            </Grid>
        );
    }

    render() {
        const { classes, showSubmitButton, onSubmit } = this.props;
        if (showSubmitButton) {
            return (
                <ValidatorForm className={classes.fullwidth}
                    name="ruleForm"
                    autoComplete="off"
                    onSubmit={() => showSubmitButton && onSubmit()}>
                    {this.renderQueryBuilder()}
                </ValidatorForm>
            );
        }
        return (this.renderQueryBuilder());
    }
}

SemanticQueryBuilder.propTypes = {
    classes: PropTypes.object,
    showSubmitButton: PropTypes.bool,
    onSubmit: PropTypes.func,
    operators: PropTypes.array,
    params: PropTypes.object,
    isLoading: PropTypes.bool,
    onClear: PropTypes.func,
    theme: PropTypes.object,
    onQueryChange: PropTypes.func,
    defaultFieldType: PropTypes.string,
    onRuleTypeChange: PropTypes.func
};

export default withStyles((theme) => ({
    ...RuleBuilderStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SemanticQueryBuilder);