import React from "react";
import PropTypes from 'prop-types';
import { Grid, Typography, Table, TableHead, TableBody, TableRow, TableCell, AccordionSummary, FormControlLabel, Switch } from '@material-ui/core';
import classnames from 'classnames';
import CheckboxComponent from '../../ChecboxComponent/CheckboxComponent.jsx';
import ToolTipComponent from '../../Tooltip/Tooltip.jsx';
import { appConstants } from '../../../constants/appConstants.js';
import Loader from '../../Loaders/Loader.jsx';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import WarningIcon from '../../Warning/WarningIcon.jsx';

export const AccordionTable = (props) => {
    const { dataset, rootColumns, index, classes, getDatasetSchema, onSelectionChange, onSelectAllAttribute, onSelectAttribute, showCheckBox, userConfig, onUpdateDataset } = props;

    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <TableRow className={classes.tableRowClass}
                onClick={
                    () => {
                        setOpen(!open);
                        getDatasetSchema(dataset);
                    }
                }>
                <TableCell component="td" scope="row" className={classes.paddingLeft6} onClick={() => setOpen(!open)}>
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                        <Grid className={classnames(classes.datasetTitleSection, classes.checkBoxTitle, classes.noPaddingLeft)}>
                            {!showCheckBox && <WarningIcon />}
                            <CheckboxComponent checked={Boolean(dataset.isSelected)} onClick={(event) => event.stopPropagation()} onChange={() => onSelectionChange(dataset)} showCheckBox={showCheckBox} />
                        </Grid>
                    </AccordionSummary>
                </TableCell>
                <TableCell component="td" scope="row" className={classes.paddingLeft6} onClick={() => setOpen(!open)}>
                    <AccordionSummary aria-controls="panel1a-content" id="panel1a-header" >
                        <Grid className={classnames(classes.datasetTitleSection, classes.checkBoxTitle, classes.noPaddingLeft)}>
                            <ToolTipComponent title={dataset.name} arrow>
                                <Typography className={classnames(classes.datasetName, classes.datasetSftpName)} component="span">
                                    {dataset.name}
                                </Typography>
                            </ToolTipComponent>
                        </Grid>
                    </AccordionSummary>
                </TableCell>
                {
                    userConfig.include_organization_domain_score &&
                    <TableCell align="center" onClick={(event) => event.stopPropagation()}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={dataset.include_organization_domain_score}
                                    onChange={(event) => { onUpdateDataset(dataset, 'include_organization_domain_score', !dataset.include_organization_domain_score); }}
                                    name="include_organization_domain_score"
                                    disabled={!dataset.isSelected}
                                />
                            }
                        />
                    </TableCell>
                }
                <TableCell onClick={() => setOpen(!open)} >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ padding: 0, height: "auto" }} colSpan={rootColumns.length + 1}>
                    <Collapse in={open} timeout="auto" unmountOnExit style={{ position: "relative" }} className={classes.collapseTable}>
                        {
                            dataset.isLoadingAttributes &&
                            <Grid container className={classes.attributeLoader}>
                                <Loader size="small" />
                            </Grid>
                        }
                        {
                            dataset.attributes &&
                            <Grid container>
                                <Table>
                                    <TableHead>
                                        <TableRow className={classes.noBackground}>
                                            <TableCell className={classes.datasetHeaderTitleCell}>
                                                <Grid className={classes.datasetTitleSection}>
                                                    <Grid className={classes.inlineBlock}>
                                                        <CheckboxComponent
                                                            className={"attributeSelectorCheck"}
                                                            checked={dataset.isAllAttributesSelected}
                                                            onClick={() => onSelectAllAttribute(dataset)}
                                                            checkboxLabel={'Attribute'}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableCell>
                                            {
                                                appConstants.sqlConnectorAttributesTableHeaders.map((header, index) =>
                                                    index !== 0 &&
                                                    <TableCell key={`attribute_${index}`}>
                                                        {header}
                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            dataset.attributes.map((attribute, attrIndex) =>
                                                <TableRow className={classes.tableRowClass} key={`dataset${index}attribute${attrIndex}`}>
                                                    <TableCell>
                                                        <Grid className={classnames(classes.datasetTitleSection, classes.checkBoxTitle, classes.noPaddingLeft)}>
                                                            <CheckboxComponent checked={Boolean(attribute.isSelected)} onClick={(event) => event.stopPropagation()} onChange={() => onSelectAttribute(dataset, attribute, attrIndex)} />
                                                            <ToolTipComponent title={attribute.name} arrow>
                                                                <Typography className={classes.datasetName} component="span">
                                                                    {attribute.name}
                                                                </Typography>
                                                            </ToolTipComponent>
                                                        </Grid>
                                                    </TableCell>
                                                    <TableCell>
                                                        {
                                                            attribute.business_name ? attribute.business_name :
                                                                <span className={classes.disabledtxt}>
                                                                    {"NA"}
                                                                </span>
                                                        }
                                                    </TableCell>
                                                    <TableCell>
                                                        <span className="description">
                                                            {
                                                                attribute.description ? attribute.description :
                                                                    <span className={classes.disabledtxt}>
                                                                        {"NA"}
                                                                    </span>
                                                            }
                                                        </span>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography className={classes.typeText}>
                                                            {attribute.datatype ? attribute.datatype : ''}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                    </TableBody>
                                </Table>

                            </Grid>
                        }

                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
};

AccordionTable.propTypes = {
    dataset: PropTypes.object,
    index: PropTypes.number,
    classes: PropTypes.object,
    getDatasetSchema: PropTypes.func,
    onSelectionChange: PropTypes.func,
    onSelectAllAttribute: PropTypes.func,
    onSelectAttribute: PropTypes.func,
    showCheckBox: PropTypes.bool,
    rootColumns: PropTypes.array,
    onUpdateDataset: PropTypes.func,
    userConfig: PropTypes.object
};