import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Chip, Grid, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

import MultiSelect from '../MultiSelect/MultiSelect.jsx';
import Styles from '../../layouts/Styles.jsx';
import TagStyles from './TagsStyles.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

class Tags extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tagInput: '',
            isOpen: false
        };
        this.inputRef = React.createRef();
    }

    enableChipEdit = () => {
        if (!this.props.disabled) {
            this.setState({ isOpen: true }, () => {
                const focusElement = document.getElementById('tag_target');
                if (focusElement) {
                    focusElement.focus();
                }
            });
        }
    }

    handleChange = (value) => {
        const onlyNums = value.replace(/[^0-9a-zA-Z]+/ig, "");
        this.setState({ tagInput: onlyNums });
    }

    handleKeyDown = (event) => {
        if (event.keyCode === 13 && event.target.value.trim() !== '') {
            this.props.updateTags(event.target.value, "add");
            this.setState({ tagInput: '' });
        } else if (event.keyCode === 8 && event.target.value === "") { this.props.updateTags("", "remove", -1, "Select"); }
    }

    handleInputLeave = () => {
        if (this.state.tagInput !== "") {
            this.props.updateTags(this.state.tagInput, "add");
        }
        this.setState({ tagInput: "", isOpen: false });
        this.props.updateTagItem();
    }


    render() {
        const { classes, tags, updateTags, theme, disabled } = this.props;
        return (
            <Grid className={classes.tagContainer}>
                {
                    this.state.isOpen ?
                        <MultiSelect
                            placeholder="Update Tags"
                            dropDown={false}
                            selectedList={tags}
                            availableList={[]}
                            displayValue="name"
                            selectType="array"
                            onKeyDown={(event) => this.handleKeyDown(event)}
                            onInputValueChange={(value) => this.handleChange(value)}
                            inputValue={this.state.tagInput}
                            onClose={() => this.handleInputLeave()}
                            onRemoveItem={(index) => updateTags("", "remove", index, "Select")}
                            inputRef={this.inputRef}
                            tagTarget /> :
                        <Grid style={{ display: 'flex', alignItems: 'center', flexWrap: "wrap" }}>
                            {
                                tags && tags.map((tag, index) =>
                                    <Grid key={`${tag}-${index}`} className={classes.chipList}>
                                        <ToolTipComponent title={tag} arrow>
                                            <Chip
                                                className={classes.chip}
                                                label={tag}
                                                onClick={() => this.enableChipEdit()}
                                            />
                                        </ToolTipComponent>
                                        {
                                            !disabled &&
                                            <IconButton onClick={() => updateTags("", "remove", index, "component")} className={classNames(classes.nopadding, 'chipremove')}>
                                                <CloseIcon className={classNames(classes.chipIcon, classes.closeIcon)} />
                                            </IconButton>
                                        }

                                    </Grid>
                                )
                            }
                            {
                                !disabled &&
                                <ToolTipComponent title="Add Tag" arrow>
                                    <IconButton onClick={() => this.enableChipEdit()} className={classes.addIcon}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18">
                                            <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                                <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                                    <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.secondary.main} />
                                                    <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.secondary.main} />
                                                </g>
                                            </g>
                                        </svg>
                                    </IconButton>
                                </ToolTipComponent>
                            }
                            {
                                (!disabled && tags && tags.length === 0) ?
                                    <Typography onClick={() => this.enableChipEdit()} variant={"body2"} className={classes.addTags}>
                                        {'Add Tags'}
                                    </Typography>
                                    : ""
                            }
                        </Grid>
                }
            </Grid>
        );
    }
}

Tags.propTypes = {
    classes: PropTypes.object,
    tags: PropTypes.array,
    updateTags: PropTypes.func,
    updateTagItem: PropTypes.func,
    theme: PropTypes.object,
    disabled: PropTypes.bool
};

export default withStyles((theme) => ({
    ...TagStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Tags);