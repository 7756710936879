import { compose, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import appReducer from '../reducers';
import { accountActions } from '../constants/actionTypes/accountActionTypes';

const rootReducer = (state, action) => {

	if (action.type === accountActions.LOGOUT) {
		state = undefined;
	}


	return appReducer(state, action);
};

const middlewares = [thunkMiddleware];
const environment = process.env.REACT_APP_ENVIRONMENT || 'development';
if (environment === 'development') {
	const { logger } = require(`redux-logger`);
	middlewares.push(logger);
}

const enhancer = compose(
	applyMiddleware(...middlewares)
);

export default function configureStore(initialState) {
	return createStore(
		rootReducer,
		initialState,
		enhancer
	);
}