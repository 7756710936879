import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, withStyles, Typography } from '@material-ui/core';
import DashboardStyles from './DashboardStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import { getValue } from '../../helpers/appHelpers';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { useDispatch, useSelector } from 'react-redux';
import { getOverAllScore } from '../../actions/scheduleActions';
import PieCircular from '../Charts/PieCircular.jsx';
import Loader from '../../components/Loaders/Loader.jsx';

const DashboardOverAllScore = (props) => {
    const { classes } = props;
    const [analysis, setAnalysis] = useState({});
    const [isLoading, setLoading] = useState(true);
    const config = useSelector(({ setting }) => setting.config);
    const dispatch = useDispatch();

    const getAnalysis = useCallback(() => {
        dispatch(getOverAllScore()).then((response) => {
            if (response) {
                setAnalysis({ ...response });
                setLoading(false);
            }
        });
    }, [dispatch]);
    useEffect(() => {
        getAnalysis();
    }, [getAnalysis]);
    return (
        <Grid className={classNames(classes.overAllContainer, classes.section)}>
            <Typography variant="h4" component="h4">
                Overall Quality
            </Typography>
            <Grid container alignItems="center" justify="space-around" className={classNames(classes.overAllSection, classes.relative)}>
                {isLoading && <Loader />}
                <Grid item className={classes.profileContainer}>
                    <Typography variant="h1" component="h1" className={classNames(classes.profileTxt, classes.fontWeight600)} >
                        {`${analysis.dqscore ? getValue(analysis.dqscore) : 0}%`}
                    </Typography>
                    {
                        config.curate &&
                        <Grid className={classes.curateScore}>
                            <ArrowDropUpIcon className={classNames(classes.impactIcon, classes.curateIcon)} />
                            <Typography className={classes.curateTxt}>
                                {`${analysis.impact_score ? getValue(analysis.impact_score) : 0}%`}
                            </Typography>
                        </Grid>
                    }
                </Grid>
                <Grid item>
                    <PieCircular
                        chartClassName="overall-progress"
                        width={120}
                        height={120}
                        innerRadius={40}
                        value={analysis.dqscore ? analysis.dqscore : 0} />
                </Grid>
            </Grid>
        </Grid>
    );
};

DashboardOverAllScore.propTypes = {
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DashboardOverAllScore);