import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography, Switch, IconButton, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import DatasetStyles from '../../DatasetStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';
import TileViewStyles from './TileViewStyles.jsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { updateProperties, getDriftCustomRule } from '../../../../actions/datasetActions';
import classNames from 'classnames';
import MultiSelectChipInput from '../../../MultiSelectChipInput/MultiSelectChipInput.jsx';
import AutoCompleteInput from '../../../AutoComplete/AutoCompleteInput.jsx';
import ToolTipComponent from '../../../Tooltip/Tooltip.jsx';
import { sortTable } from '../../../../helpers/appHelpers.js';

const AttributeDrift = (props) => {
    const { classes, attribute, isEditable, theme, updatePropertiesList, isEditPermission } = props;
    const [property, setProperty] = useState({
        'is_drift': false,
        'is_lastday_alerts': true,
        'drift_relative_attribute': '',
        'statistical_report': [],
        'behaviour_analysis': [],
        'custom_rules': []
    });
    const dispatch = useDispatch();
    const datasetId = useSelector(({ dataset }) => dataset.selectedDatasetId);
    const rulesList = useSelector(({ dataset }) => dataset.driftCustomRules);
    const properties = useSelector(({ dataset }) => dataset.properties);
    const [isChanged, setInputChanged] = useState(false);

    const getDateAttribute = () => {
        const attributes = Object.keys(properties).filter((data) => attribute.name !== data);
        return attributes.map((attribute) => { return { name: attribute, datatype: properties[attribute].datatype ? properties[attribute].datatype : "Text" }; });
    };

    const addDriftAttribute = useCallback(() => {
        property.behaviour_analysis.splice(0, 0, { "attribute": [], "type": "Avg" });
        setProperty({ ...property });
        if (!isChanged) {
            setInputChanged(true);
        }
    }, [isChanged, property]);

    const updateDriftType = useCallback((value, index) => {
        property.behaviour_analysis[index].type = value;
        setProperty({ ...property });
        if (!isChanged) {
            setInputChanged(true);
        }

    }, [isChanged, property]);


    const getCustomDriftRules = useCallback(() => {
        dispatch(getDriftCustomRule());
    }, [dispatch]);

    const updateDriftAttribute = useCallback((value, index) => {
        property.behaviour_analysis[index].attribute = [...value];
        setProperty({ ...property });
        if (!isChanged) {
            setInputChanged(true);
        }
    }, [isChanged, property]);

    const deleteDriftAttribute = useCallback((index) => {
        property.behaviour_analysis.splice(index, 1);
        setProperty({ ...property });
        if (!isChanged) {
            setInputChanged(true);
        }
    }, [isChanged, property]);

    const onHandleChange = useCallback((propertyName, value, event) => {
        property[propertyName] = value;
        setProperty({ ...property });
        if (!isChanged) {
            setInputChanged(true);
        }
    }, [isChanged, property]);

    useEffect(() => {
        setProperty({
            'is_drift': attribute.is_drift ? attribute.is_drift : '',
            'is_lastday_alerts': attribute.is_lastday_alerts ? attribute.is_lastday_alerts : '',
            'drift_relative_attribute': attribute.drift_relative_attribute,
            'statistical_report': attribute.statistical_report,
            'behaviour_analysis': attribute.behaviour_analysis ? attribute.behaviour_analysis : [],
            'custom_rules': attribute.custom_rules ? attribute.custom_rules : []
        });
        if (rulesList.length === 0) {
            getCustomDriftRules();
        }
    }, [attribute, getCustomDriftRules, rulesList.length]);

    const updateAttributeProps = () => {
        if (isChanged) {
            property["behaviour_analysis"] = property.behaviour_analysis.filter((analysis) => analysis.attribute && analysis.attribute.length !== 0);
            updatePropertiesList({ name: attribute.name, value: property });
            dispatch(updateProperties(datasetId, { id: attribute.id, attribute: attribute.name, value: property }));
            setInputChanged(false);
        }
    };

    const selectedColumns = getDateAttribute();

    return (
        <Grid container direction="column" justify="flex-start" alignItems="flex-start" wrap="nowrap" style={{ height: '100%', overflow: 'hidden' }} onMouseLeave={() => updateAttributeProps()}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: '100%' }}>
                <Grid container direction="row" wrap="nowrap" spacing={4}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ paddingBottom: 0 }}>
                        <Grid container direction="column" justify="flex-start" wrap="nowrap" style={{ height: '100%' }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ flex: 0 }}>
                                <Grid container direction="row" justify="flex-start" wrap="nowrap" >

                                    <Grid container direction="column" wrap="nowrap">
                                        <Typography component="h6" variant="h6">
                                            {'Enable Drift Analysis'}
                                        </Typography>
                                        <Typography variant="body2">
                                            {'Select Attribute for Drift analysis'}
                                        </Typography>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.propertyComponents}>
                                            <Grid item>
                                                <FormControlLabel style={{ marginLeft: 0 }}
                                                    control={
                                                        <Switch checked={property.is_drift}
                                                            color="secondary"
                                                            name="is_drift"
                                                            disabled={!isEditable && !isEditPermission}
                                                            onChange={(event) => onHandleChange(event.target.name, event.target.checked)} />
                                                    }
                                                    label="ON/ OFF"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid container direction="column" wrap="nowrap" style={{ width: 300 }}>
                                        <Typography component="h6" variant="h6">
                                            {'Drift Rules'}
                                        </Typography>
                                        <Typography variant="body2">
                                            {'Select the rules for Drift analysis'}
                                        </Typography>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" className={classNames(classes.propertyComponents, classes.driftCustomRulebox, classes.driftChips)}>
                                            <Grid item className={classes.fullWidth2}>
                                                <AutoCompleteInput
                                                    name="attributes"
                                                    popperWidth={300}
                                                    options={sortTable(rulesList, "asc", "name")}
                                                    freeSolo
                                                    value={property.custom_rules ? property.custom_rules : []}
                                                    getOptionLabel={
                                                        (option) => {
                                                            if (option && option.name) {
                                                                return option.name;
                                                            }
                                                            return option;
                                                        }
                                                    }
                                                    renderInput={
                                                        (params) =>
                                                            <TextField {...params}
                                                                className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                                                placeholder="Select Rules"
                                                                margin="normal"
                                                            /*
                                                             * onBlur={() => onUpdate(attributes, index)}
                                                             * disabled={disabled}
                                                             */
                                                            />
                                                    }
                                                    onChange={(_, selectedValue) => onHandleChange("custom_rules", selectedValue)}
                                                    openOnFocus
                                                    disableCloseOnSelect
                                                    disableClearable
                                                    fullWidth
                                                    multiple
                                                    forcePopupIcon={false}
                                                    noOptionsText={"No Attributes Found"}
                                                    disabled={!isEditable && !isEditPermission}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* <Grid container direction="column" wrap="nowrap" style={{ marginLeft: '15px' }}>
                                        <Typography component="h6" variant="h6">
                                            {'Current Day Alerts'}
                                        </Typography>
                                        <Typography variant="body2">
                                            {'Select Attribute for current day Drift Alert'}
                                        </Typography>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.propertyComponents}>
                                            <Grid item>
                                                <FormControlLabel style={{ marginLeft: 0 }}
                                                    control={
                                                        <Switch checked={property.is_lastday_alerts}
                                                            color="secondary"
                                                            name="is_lastday_alerts"
                                                            disabled={!isEditable}
                                                            onChange={(event) => onHandleChange(event.target.name, event.target.checked)} />
                                                    }
                                                    label="ON/ OFF"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid> */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6} style={{ paddingBottom: 0, top: -5 }}>
                        <Grid container direction="column" justify="flex-start" wrap="nowrap">
                            <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap" className={classes.valuesTitleContainer}>
                                <Typography component="h6" variant="h6">
                                    {'Behavioral Analysis'}
                                </Typography>
                                {
                                    // isEditable && (basicCurationRules && basicCurationRules.length > 0) &&
                                    (isEditable || isEditPermission) &&
                                    <ToolTipComponent title="Add Drift Attribute" arrow>
                                        <IconButton onClick={() => addDriftAttribute()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                                    <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                                        <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                                        <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                                    </g>
                                                </g>
                                            </svg>
                                        </IconButton>
                                    </ToolTipComponent>
                                }
                            </Grid>
                            <Typography variant="body2" className={classes.curateDescritionEllipsis}>
                                {'Time-Series Comparison for Multiple Attributes C-Character ( Text analysis) N-Numeric T-Timeseries'}
                            </Typography>

                            <Grid container className={classNames(classes.basicRulesListContainer)}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.basicRuleContainer}>
                                    {
                                        property.behaviour_analysis && property.behaviour_analysis.map((rule, index) => {
                                            return (
                                                <MultiSelectChipInput
                                                    key={`basicRuleList${index}`}
                                                    displayKey={'name'}
                                                    index={index}
                                                    rule={rule.attribute}
                                                    keyvalue={rule.type}
                                                    options={selectedColumns}
                                                    onUpdate={(...props) => updateDriftAttribute(...props)}
                                                    onDelete={(...props) => deleteDriftAttribute(...props)}
                                                    onTypeUpdae={(...props) => updateDriftType(...props)}
                                                    disabled={!isEditable && !isEditPermission}
                                                    defaultAttributeType={attribute.datatype ? attribute.datatype.substring(0, 1) : "T"} />
                                            );
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

AttributeDrift.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    attribute: PropTypes.object,
    isEditable: PropTypes.bool,
    updatePropertiesList: PropTypes.func,
    isEditPermission: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(AttributeDrift);