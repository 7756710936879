import { masterActions } from '../constants/actionTypes/masterActionTypes';

const initialState = {
    isLoading: false,
    attributes: [],
    masterData: [{ City: "PASADENA", DateOfBirth: "1974-03-16", FirstName: "RAJ", LastName: "JOSEPH", PersonConfidence: "91.00", PersonID: "840111110006321", State: "CALIFORNIA", ZipCode: "91101", details: [{ columns: ["Prefix", "FirstName", "MiddleInitial", "MiddleName", "LastName", "Suffix", "LastReportedTimeStamp", "MostCurrent", "NameConfidence"], data: [{ FirstName: "RAJ", LastName: "JOSEPH", LastReportedTimeStamp: "2020-08-18", MiddleInitial: "", MiddleName: "", MostCurrent: "True", NameConfidence: "92.00", Prefix: "", Suffix: "" }] }, { columns: ["Email", "LastReportedTimeStamp", "MostCurrent", "EmailConfidence"], data: [{ Email: "RAJ@DQLABS.AI", EmailConfidence: "90.00", LastReportedTimeStamp: "2020-08-18", MostCurrent: "True" }] }, { columns: ["PhoneNumber", "LastReportedTimeStamp", "MostCurrent", "PhoneConfidence"], data: [{ LastReportedTimeStamp: "2020-08-18", MostCurrent: "True", PhoneConfidence: "99.00", PhoneNumber: "5094895920" }, { LastReportedTimeStamp: "2020-08-18", MostCurrent: "False", PhoneConfidence: "83.00", PhoneNumber: "7202567540" }] }, { columns: ["AddressLine1", "AddressLine2", "City", "State", "ZipCode", "ParcelNumber", "Longitude", "Latitude", "LastReportedTimeStamp", "MostCurrent", "AddressConfidence"], data: [{ AddressConfidence: "91.00", AddressLine1: "680 EAST COLORADO BOULEVARD SUITE 180", AddressLine2: "", City: "PASADENA", LastReportedTimeStamp: "2020-08-18", Latitude: "0.000000", Longitude: "0.000000", MostCurrent: "True", ParcelNumber: "", State: "CALIFORNIA", ZipCode: "91101" }] }] }],
    searchResults: [],
    modelFieldTypes: []
};

export const masterReducer = (state = initialState, action) => {
    if (!action || (action && !action.type)) { return state; }
    switch (action.type) {
        case masterActions.CREATE_MASTER_MODEL_REQUEST:
        case masterActions.CREATE_MASTER_MODEL_SUCCESS:
        case masterActions.CREATE_MASTER_MODEL_FAILURE:
            return {
                ...state
            };
        case masterActions.SET_SEARCH:
            let masterData = [...state.masterData];
            const columns = Object.keys(masterData[0]).filter((data) => data !== "details");
            if (action.responseData) {
                masterData = masterData.filter((person) => {
                    let search;
                    const queryString = Object.keys(action.responseData);
                    for (const query of queryString) {
                        if (!search) {
                            search = true;
                        }
                        if (columns.includes(query)) {
                            search = search && person[query].toLowerCase() === action.responseData[query].toLowerCase();
                        }
                        for (const data of person.details) {
                            if (data.columns.includes(query)) {
                                for (const columns of data.data) {
                                    const key = Object.keys(columns);
                                    if (key.includes(query)) {
                                        search = search && columns[query].toLowerCase() === action.responseData[query].toLowerCase();
                                    }
                                }
                            }
                        }
                    }
                    return search;
                });
            }
            return {
                ...state,
                searchResults: [...masterData]
            };
        case masterActions.CREATE_MODEL_ATTRIBUTE_FIELDS_SUCCESS:
            const addedAttrList = action.responseData.data;
            const modifiedAttrList = [...state.attributes];
            addedAttrList.forEach((newAttr) => {
                const targetIndex = modifiedAttrList.findIndex((attr) => attr.id === newAttr.id);
                if (targetIndex === -1) {
                    modifiedAttrList.push(newAttr);
                }

            });
            return {
                ...state,
                attributes: [...modifiedAttrList]
            };
        case masterActions.GET_MODEL_ATTRIBUTES_SUCCESS:
            return {
                ...state,
                attributes: [...action.responseData]
            };
        case masterActions.SET_ATTRIBUTES:
            return {
                ...state,
                attributes: [...action.attributes]
            };
        case masterActions.GET_MODEL_ATTRIBUTES_FAILURE:
            return {
                ...state,
                attributes: []
            };
        case masterActions.SEARCH_SEMANTIC_MODEL_SUCCESS:
            return {
                ...state,
                searchResults: action.responseData ? [...action.responseData] : []
            };
        case masterActions.CLEAR_MASTER_SEARCH_RESULTS:
        case masterActions.SEARCH_SEMANTIC_MODEL_FAILURE:
            return {
                ...state,
                searchResults: []
            };
        case masterActions.GET_MASTER_MODEL_TYPES_SUCCESS:
            return {
                ...state,
                modelFieldTypes: action.responseData ? [...action.responseData] : []
            };
        case masterActions.GET_MASTER_MODEL_TYPES_FAILURE:
            return {
                ...state,
                modelFieldTypes: []
            };
        default:
            return state;
    }
};