export const dashboardActions = {
    GET_DASHBOARDS_REQUEST: 'GET_DASHBOARDS_REQUEST',
    GET_DASHBOARDS_SUCCESS: 'GET_DASHBOARDS_SUCCESS',
    GET_DASHBOARDS_FAILURE: 'GET_DASHBOARDS_FAILURE',

    CREATE_DASHBOARD_REQUEST: 'CREATE_DASHBOARD_REQUEST',
    CREATE_DASHBOARD_SUCCESS: 'CREATE_DASHBOARD_SUCCESS',
    CREATE_DASHBOARD_FAILURE: 'CREATE_DASHBOARD_FAILURE',

    UPDATE_DASHBOARD_REQUEST: 'UPDATE_DASHBOARD_REQUEST',
    UPDATE_DASHBOARD_SUCCESS: 'UPDATE_DASHBOARD_SUCCESS',
    UPDATE_DASHBOARD_FAILURE: 'UPDATE_DASHBOARD_FAILURE',

    DELETE_DASHBOARD_REQUEST: 'DELETE_DASHBOARD_REQUEST',
    DELETE_DASHBOARD_SUCCESS: 'DELETE_DASHBOARD_SUCCESS',
    DELETE_DASHBOARD_FAILURE: 'DELETE_DASHBOARD_FAILURE'
};