import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid, Typography, Button, withStyles, Paper } from '@material-ui/core';
import classNames from 'classnames';
import Wallet from '../../assets/images/emptyWallet.png';

import Styles from '../../layouts/Styles.jsx';
import WelcomeStyles from './WelcomeStyles.jsx';
import localization from '../../constants/localization';

const Welcome = (props) => {
	const { classes, history, account, getPageTitle } = props;

	useEffect(() => {
		getPageTitle('Welcome');
	});

	return (
		<Grid container className={classes.container} justify="center" alignItems="center" direction="row">
			<Grid item xs={6} align="center">
				<img src={Wallet} alt="" />
			</Grid>
			<Grid item xs={6}>
				<Typography className={classes.welcomeTxt} component="h1" variant="h1">
					Welcome,
					{' '}
					{account && account.user ? `${account.user.first_name ? account.user.first_name : ''} ${account.user.last_name ? account.user.last_name : ''}` : ''}
				</Typography>
				<Typography className={classes.secondaryText} component="h5" variant="h5">
					Increase your Revenue by discovering trustable data
					<br />
					in minutes.
				</Typography>
				<Button variant="contained" color="primary" onClick={() => history.push('/datasourcetypes')}>
					Let's Get Started
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={3} justify="center">
					{
						localization.welcomeTiles.map((welcomeTile, index) => {
							return (
								<Grid key={`welcomeTile${index}`} item xs={12} sm={6} md={3} lg={2} align="center" className="welcomeTiles">
									<Paper className={classNames(classes.card, classes.tileContainer)}>
										<Grid className={classes.cirlceContainer}>
											<Paper className={classes.circle} />
											<Grid className={classes.iconContainer}>
												<Grid className={classes.icon}>
													<img src={welcomeTile.icon} alt="" />
												</Grid>
											</Grid>

										</Grid>
										<Grid className={classes.titleContainer}>
											<Typography component="h5" variant="h5" className={classes.titleTxt}>
												{welcomeTile.title}
											</Typography>
											<Typography variant="body2" className={classes.paragraphTxt}>
												{welcomeTile.description}
											</Typography>
										</Grid>
									</Paper>
								</Grid>
							);
						})
					}
				</Grid>
			</Grid>
		</Grid>
	);
};

Welcome.propTypes = {
	classes: PropTypes.object,
	history: PropTypes.object,
	account: PropTypes.object,
	getPageTitle: PropTypes.func
};

const mapStateToProps = (account) => {
	return {
		...account
	};
};

export default connect(mapStateToProps)(withStyles((theme) => ({
	...WelcomeStyles(theme),
	...Styles(theme)
}), { withTheme: true })(Welcome));