const DatasetProfileStyles = (theme) => ({
    scoreContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    scoreContent: {
        marginLeft: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    attributeName: {
        fontWeight: 500,
        fontSize: 15,
        marginBottom: 5
    },
    selectContainer: {
        paddingLeft: 10,
        position: 'relative',
        '&>div': {
            maxWidth: 250
        }
    },
    chartcontainer: {
        padding: '8px 16px !important'
    },
    curateScore: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    curateIcon: {
        width: 40,
        height: 40,
        margin: '0px -5px 0px -5px'
    },
    curateTxt: {
        fontWeight: 600
    },
    dqscoreText: {
        marginLeft: 10,
        marginTop: 10,
        marginBottom: -10
    },
    attributeAnalysisText: {
        fontWeight: 'bold'
    },
    invalidAttributeAnalysisText: {
        fontWeight: 'bold',
        color: '#F38080',
        cursor: 'pointer'
    },
    iconHeight: {
        height: 20
    },
    invalidRecords: {
        cursor: 'pointer',
        '& h5': {
            color: theme.palette.tertiary
        }
    },
    scrollContainer: {
        height: 'calc(100vh - 270px)',
        overflowY: 'auto'
    }
});

export default DatasetProfileStyles;