import React, { Fragment, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, withStyles, Typography, IconButton, MenuItem } from '@material-ui/core';
import classNames from 'classnames';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import Logo from '../../assets/images/dqicon_placeholder.svg';
import Permission from '../Permission/Permission.jsx';
import DatasourceStyles from '../Datasource/DatasourceStyles.jsx';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import { runMasterModelJob, loadGenericTable, generatePersonID, updateMasterModelStatus } from '../../actions/masterActions.js';
import MasterSchedule from './MasterSchedule/MasterSchedule.jsx';
import MasterStyles from './MasterStyles.jsx';
import { appConstants } from '../../constants/appConstants.js';
import { sortTable } from '../../helpers/appHelpers.js';
import Verified from '../../assets/images/verified.svg';
import InProgess from '../../assets/images/wip.svg';
import Deprecated from '../../assets/images/deprecated.svg';
import Issues from '../../assets/images/issues.svg';


const MasterInfo = (props) => {
    const { classes, model, theme, breadcrumb, modelId, governance, isEditable, onChangeModel, onSave, history, createSourceShare, updateUser, deleteShare, deleteModelData } = props;
    const users = useSelector(({ account }) => account.users);
    const user = useSelector(({ account }) => account.user);
    const attributes = useSelector(({ master }) => master.attributes);
    const dispatch = useDispatch();
    const [schedule, setSchedule] = useState({ open: false, anchorElement: null, modelId: 0 });
    const [isLoading] = useState(false);
    const [modelStatus, setModelStatus] = useState(model && model.status ? model.status : null);

    const openScheduler = useCallback((event) => {
        setSchedule({ open: true, anchorElement: event.target, modelId: modelId });
    }, [modelId]);

    const runJob = useCallback(() => {
        dispatch(runMasterModelJob(modelId));
    }, [dispatch, modelId]);

    const loadGenericTables = useCallback(() => {
        dispatch(loadGenericTable(modelId));
    }, [dispatch, modelId]);

    const generatePersonIds = useCallback(() => {
        dispatch(generatePersonID(modelId));
    }, [dispatch, modelId]);

    /*
     * const publishSemanticModel = useCallback(() => {
     *     setIsLoading(true);
     *     dispatch(publishModel(modelId)).then(() => {
     *         setIsLoading(false);
     *     });
     * }, [dispatch, modelId]);
     */

    const renderStatusIcon = (status) => {
        if (status.toLowerCase() === "verified") {
            return Verified;
        } else if (status.toLowerCase() === "work in progress") {
            return InProgess;
        } else if (status.toLowerCase() === "issues") {
            return Issues;
        }
        return Deprecated;
    };

    const onStatusChange = (name, value) => {
        if (modelStatus !== value) {
            onChangeModel('status', value);
        }
    };

    const saveStatus = useCallback(() => {
        if (modelStatus && (model.status !== modelStatus)) {
            dispatch(updateMasterModelStatus({ modelId, status: model.status }));
        }
    }, [dispatch, model.status, modelId, modelStatus]);

    useEffect(() => {
        if (!modelStatus && model && model.status) {
            setModelStatus(model.status);
        }
    }, [model, modelStatus]);

    useEffect(() => {
        if (model && model.status) {
            setModelStatus(model.status);
            saveStatus();
        }
    }, [saveStatus, model]);

    return (
        <Fragment>
            <Grid container className={classes.marginBottom5}>
                {
                    breadcrumb && breadcrumb.map((crumb, index) =>
                        <Typography variant="body2" key={`crumb_${index}`} component="span" className={classes.link} onClick={() => (crumb.path !== "" ? history.push(crumb.path) : null)}>
                            {crumb.name}
                            {index !== breadcrumb.length - 1 && ' | '}
                        </Typography>
                    )
                }
            </Grid>
            <Grid container justify="space-between" className={classes.paddingBottom4}>
                <Grid item className={classes.datasourceTitle}>
                    <Grid className={classes.defaultIconContainer}>
                        <img src={Logo} alt="logo" className={classNames(classes.datasourceIcon, classes.datasourceIconSelectImg)} />
                    </Grid>
                    <TextBox
                        name="name"
                        placeholder="Untitled Semantic Model"
                        value={model && model.name ? model.name : ''}
                        className={classNames(classes.inlinetxt, classes.headerTitleTxt)}
                        onChange={(event) => onChangeModel(event.target.name, event.target.value)}
                        onBlur={
                            (event) => {
                                if (event.target.getAttribute('aria-invalid') === 'true') {
                                    return;
                                }
                                if (event.target.value !== "") {
                                    onSave('name');
                                }
                            }
                        }
                        disabled
                        error={isEditable && model.name === ""}
                        helperText={isEditable && model.name === "" ? "Please enter the model name" : ""}
                        validators={['matchRegexp:^[A-Za-z][A-Za-z0-9]+$']}
                        errorMessages={['']}
                    />
                </Grid>
                <Grid item>
                    {
                    modelStatus &&
                        <TextBox
                            name="status"
                            select
                            onChange={(event) => onStatusChange(event.target.name, event.target.value)}
                            // onBlur={() => saveStatus("status")}
                            value={modelStatus}
                            className={classes.statusSelect}
                            SelectProps={
                                {
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    },
                                    IconComponent: () => null
                                }
                            }
                        >
                            {
                                sortTable(appConstants.glossaryStatus, "asc", "name").map((status, index) =>
                                    <MenuItem style={{ display: 'flex' }} key={index} className={classes.menuItem} value={status.value}>
                                        <img src={renderStatusIcon(status.value)} alt="" className={classes.marginRight5} />
                                        <Typography>
                                            {status.name}
                                        </Typography>
                                    </MenuItem>
                                )
                            }
                        </TextBox>
                    }
                    <Fragment>
                        {
                            isEditable && model.name !== "" &&
                            <TooltipComponent title={"Schedule"} arrow>
                                <IconButton className={classes.padding8}
                                    onClick={(event) => openScheduler(event)}
                                    disabled={isLoading}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 22 22">
                                        <circle fill={theme.palette.primary.main} cx="1" cy="1" r="1" transform="translate(12 11.999)" />
                                        <path fill={theme.palette.primary.main} d="M276,272.78l-4.718,4.708a.86.86,0,0,0-.215.359l-1.027,3.383a.859.859,0,0,0,1.052,1.078l3.469-.961a.86.86,0,0,0,.378-.22l4.708-4.7A2.578,2.578,0,0,0,276,272.78Zm-2.117,6.97-1.745.483.511-1.681,3.183-3.176,1.216,1.216Zm4.548-4.539-.167.166-1.215-1.215.166-.166a.859.859,0,0,1,1.216,1.215Z" transform="translate(-258.398 -260.338)" />
                                        <ellipse fill={theme.palette.primary.main} cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(8 15.999)" />
                                        <circle fill={theme.palette.primary.main} cx="1" cy="1" r="1" transform="translate(8 11.999)" />
                                        <circle fill={theme.palette.primary.main} cx="1" cy="1" r="1" transform="translate(8 7.999)" />
                                        <circle fill={theme.palette.primary.main} cx="1" cy="1" r="1" transform="translate(4 11.999)" />
                                        <ellipse fill={theme.palette.primary.main} cx="1" cy="0.5" rx="1" ry="0.5" transform="translate(4 15.999)" />
                                        <circle fill={theme.palette.primary.main} cx="1" cy="1" r="1" transform="translate(4 7.999)" />
                                        <ellipse fill={theme.palette.primary.main} cx="0.5" cy="1" rx="0.5" ry="1" transform="translate(16 7.999)" />
                                        <path fill={theme.palette.primary.main} d="M18.562,1.719H17.445V.859a.859.859,0,0,0-1.719,0v.859h-3.91V.859a.859.859,0,1,0-1.719,0v.859H6.23V.859a.859.859,0,1,0-1.719,0v.859H3.437A3.441,3.441,0,0,0,0,5.156V18.562A3.441,3.441,0,0,0,3.437,22H8.68a.859.859,0,1,0,0-1.719H3.437a1.721,1.721,0,0,1-1.719-1.719V5.156A1.721,1.721,0,0,1,3.437,3.437H4.512V4.3a.859.859,0,1,0,1.719,0V3.437H10.1V4.3a.859.859,0,1,0,1.719,0V3.437h3.91V4.3a.859.859,0,0,0,1.719,0V3.437h1.117a1.721,1.721,0,0,1,1.719,1.719V9.023a.859.859,0,1,0,1.719,0V5.156A3.441,3.441,0,0,0,18.562,1.719Z" />
                                        <circle fill={theme.palette.primary.main} cx="1" cy="1" r="1" transform="translate(12 7.999)" />
                                    </svg>
                                </IconButton>
                            </TooltipComponent>
                        }
                        {
                            isEditable && model.name !== "" &&
                            <TooltipComponent title={"Create Gentric Table"} arrow>
                                <IconButton onClick={() => runJob()}
                                    className={classNames(classes.dataSetUparrow, classes.padding8, classes.marginLeft5)}
                                    disabled={isLoading}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 19.003 22.001">
                                        <g transform="translate(-34.883 0)">
                                            <g transform="translate(34.883 0)">
                                                <path fill={theme.palette.primary.main} d="M52.457,8.461,39.463.452a3,3,0,0,0-4.58,2.559V18.957a3.036,3.036,0,0,0,3,3.044h.013a3.015,3.015,0,0,0,1.558-.469.858.858,0,1,0-.894-1.465,1.316,1.316,0,0,1-.668.218A1.333,1.333,0,0,1,36.6,18.957V3.01a1.289,1.289,0,0,1,1.964-1.1l12.994,8.01a1.289,1.289,0,0,1-.006,2.2l-9.395,5.752a.858.858,0,1,0,.9,1.463l9.394-5.752a3,3,0,0,0,.011-5.123Z" transform="translate(-34.883 0)" />
                                            </g>
                                        </g>
                                    </svg>
                                </IconButton>
                            </TooltipComponent>
                        }
                    </Fragment>

                    {
                        isEditable && model.name !== "" && attributes && attributes.length > 0 &&
                        <TooltipComponent title={"LoadGenericTable"} arrow>
                            <IconButton style={{ color: theme.palette.primary.main }}
                                className={classes.padding5}
                                onClick={() => loadGenericTables()}
                                disabled={isLoading}>
                                <svg width="28" height="28" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" fill={theme.palette.primary.main} style={{ marginBottom: '-8px' }}>
                                    <g>
                                        <path d="m372.4 461.44v-244.16l91.84 91.84 31.922-31.359-146.16-146.16-146.16 146.16 31.922 31.359 91.84-91.84v244.16z" />
                                        <path d="m202.72 48.719h294.56v44.801h-294.56z" />
                                    </g>
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                    }

                    {
                        isEditable && model.name !== "" && attributes && attributes.length > 0 &&
                        <TooltipComponent title={"Generate RecordID"} arrow>
                            <IconButton style={{ color: theme.palette.primary.main, marginRight: 8 }}
                                className={classes.padding5}
                                onClick={() => generatePersonIds()}
                                disabled={isLoading}>
                                <svg width="28" height="28" version="1.1" viewBox="0 0 700 700" xmlns="http://www.w3.org/2000/svg" fill={theme.palette.primary.main} style={{ marginBottom: '-8px' }}>
                                    <g>
                                        <path d="m372.4 461.44v-244.16l91.84 91.84 31.922-31.359-146.16-146.16-146.16 146.16 31.922 31.359 91.84-91.84v244.16z" />
                                        <path d="m202.72 48.719h294.56v44.801h-294.56z" />
                                    </g>
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                    }

                    {
                        isEditable &&
                        <TooltipComponent title={"Delete Model"} arrow>
                            <IconButton className={classes.padding5} onClick={() => deleteModelData()} disabled={isLoading}>
                                <svg version="1.1" id="Layer_1" width="22" height="22" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
                                    <g id="Delete">
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                        <path fill={theme.palette.primary.main}
                                            stroke={theme.palette.primary.main}
                                            strokeWidth="0.92"
                                            strokeMiterlimit="10"
                                            d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                    </g>
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                    }
                </Grid>
            </Grid>
            <Grid container>
                <Grid item className={classes.datasourceDetailIcons}>
                    <Typography className={classes.datasourceDetailText} variant="body2">
                        Steward
                    </Typography>
                    <Permission
                        users={users}
                        // updateAuditLog={updateAuditLog}
                        createSourceShare={createSourceShare}
                        governance={governance}
                        sourceId={modelId}
                        /*
                         * inviteSourceUser={inviteSourceUser}
                         * getAuditLogs={getAuditLogs}
                         */
                        user={user}
                        type="Master"
                        steward
                        // loading={loading}
                        isEditable={isEditable && model.name !== ""}
                        deleteShare={deleteShare}
                        updateUser={updateUser}
                        hideAuditlog
                    />
                </Grid>
            </Grid>
            <MasterSchedule scheduleProps={schedule} onClose={() => setSchedule({ open: false, anchorElement: null, datasetId: 0, datasourceId: 0 })} />
        </Fragment>
    );
};


MasterInfo.propTypes = {
    classes: PropTypes.object,
    breadcrumb: PropTypes.array,
    theme: PropTypes.object,
    model: PropTypes.object,
    governance: PropTypes.array,
    modelId: PropTypes.string,
    isEditable: PropTypes.bool,
    onChangeModel: PropTypes.func,
    onSave: PropTypes.func,
    history: PropTypes.object,
    createSourceShare: PropTypes.func,
    updateUser: PropTypes.func,
    deleteShare: PropTypes.func,
    deleteModelData: PropTypes.func
};

export default withStyles((theme) => ({
    ...DatasourceStyles(theme),
    ...Styles(theme),
    ...MasterStyles(theme)
}), { withTheme: true })(MasterInfo);