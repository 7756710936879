import React, { useState, useCallback, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, withStyles, Typography, IconButton, Table, TableBody, TableHead, TableRow, TableCell, Switch } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import { getOrganizations, updateOrganization, deleteOrganization, exportOrganizationReport, importOrganizationReport } from '../../actions/settingActions';
import Loader from '../Loaders/Loader.jsx';
import { appConstants } from '../../constants/appConstants';
import Search from '../TextBox/Search.jsx';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import DropZone from 'react-dropzone';
import classNames from 'classnames';

const OrganizationList = (props) => {
    const { classes, theme, onChangeOrganization } = props;
    const dispatch = useDispatch();
    const [organizations, setOrganization] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [organizationList, setOrganizationList] = useState([]);
    const [, setEnableSearch] = useState(false);
    const [search, setSearch] = useState('');
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [isExportLoading, setIsExportLoading] = useState(false);
    const [exportOrganization, setExportOrganization] = useState('');
    const [isImportLoading, setIsImportLoading] = useState(false);
    const organizationConfig = useSelector(({ setting }) => setting.licenseConfig);
    const organizationCount = organizationConfig && organizationConfig.no_of_organizations ? organizationConfig.no_of_organizations : 0;

    const getAllOrganization = useCallback(() => {
        setLoading(true);
        dispatch(getOrganizations()).then((response) => {
            setLoading(false);
            if (response) {
                setOrganizationList([...response]);
                setOrganization([...response]);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getAllOrganization();
    }, [getAllOrganization]);

    const searchOrganization = (value) => {
        setSearch(value);
        const searchResult = value !== "" ? organizationList.filter((item) => item.name.toLowerCase().includes(value.toLowerCase())) : organizationList;
        setOrganization([...searchResult]);
    };

    const onChangeActive = (value, data) => {
        const model = {
            ...data,
            "is_active": value
        };
        const index = organizations.findIndex((organization) => organization.id === data.id);
        organizations[index]["is_active"] = value;
        setOrganization([...organizations]);
        dispatch(updateOrganization(model, data.id));
    };

    const deleteOrganizationList = (selectedOrganization) => {
        const index = organizationList.findIndex((organization) => organization.id === selectedOrganization.id);
        if (index <= -1) {
            return;
        }
        organizationList.splice(index, 1);
        setSelectedOrganization(null);
        setOrganization([...organizationList]);
        setOrganizationList([...organizationList]);
        dispatch(deleteOrganization(selectedOrganization.id));
    };

    const exportReport = useCallback((organizationToExport) => {
        setIsExportLoading(true);
        setExportOrganization(organizationToExport);
        const requestParams = {
            "organization": organizationToExport,
            "datasource": 0,
            "dataset": 0,
            "include_metadata": true,
            "include_profile": true,
            "include_curate": true
        };
        dispatch(exportOrganizationReport(requestParams)).then(() => setIsExportLoading(false));
    }, [dispatch]);

    const importReport = useCallback((organizationToImport, reportToImport) => {
        setIsImportLoading(true);
        const reader = new FileReader();
        reader.onload = (file) => {
            const requestParams = {
                "type": "organization",
                "organization": organizationToImport,
                "datasource": 0,
                "dataset": 0,
                "report": JSON.parse(file.target.result)
            };
            dispatch(importOrganizationReport(requestParams)).then(() => setIsImportLoading(false));
        };
        reader.readAsText(reportToImport[0]);
    }, [dispatch]);

    return (
        <Grid className={classes.settingTabContainer} >
            <Grid container justify="space-between" className={classes.marginBottom12}>
                <Grid item>
                    <Typography className={classes.tabTitle} variant="h5" component="h5">
                        Organization Lists
                    </Typography>
                </Grid>
                <Grid item className={classes.search} >
                    <Search className={classes.dqSearchBoxRefList} placeholder="Search Organization" value={search} onChange={(value) => searchOrganization(value)} onBlur={() => setEnableSearch(false)} />
                    {/* {
                        !enableSearch &&
                        <IconButton onMouseEnter={() => setEnableSearch(!enableSearch)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24.943 24.939">
                                <g transform="translate(0 -0.035)">
                                    <path d="M10.039,20.1a10.014,10.014,0,0,0,6.154-2.114l6.636,6.636a1.248,1.248,0,0,0,1.764-1.764l-6.636-6.636A10.033,10.033,0,1,0,10.039,20.1ZM4.707,4.738a7.54,7.54,0,1,1,0,10.663h0A7.513,7.513,0,0,1,4.668,4.777l.039-.039Z" transform="translate(0 0)" fill={theme.palette.primary.main} />
                                </g>
                            </svg>
                        </IconButton>
                    }  */}
                    {
                        organizationConfig.import_export_metadata &&
                        <Fragment>
                            <TooltipComponent title={"Export All"} arrow>
                                <IconButton variant="contained" color="primary" className={classes.marginLeft5} onClick={() => exportReport("All")}>
                                    {
                                        (isExportLoading && (exportOrganization === 'All')) ?
                                            <Loader size={'extraSmall'} type={'button'} classList={classes.relative} /> :

                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24.342 24.106" fill={theme.palette.primary.main}>
                                                <g id="upload_button" transform="translate(-1568.963 -266.973)">
                                                    <g id="Group_2" data-name="Group 2">
                                                        <path id="Path_2" data-name="Path 2" d="M1593.3,291.079h-24.342V276.71h2v12.369H1591.3V276.71h2Z" />
                                                        <path id="Path_3" data-name="Path 3" d="M1586.4,272.323l-1.41,1.42-3.03-3v10.78h-2v-10.7l-2.87,2.84-1.41-1.42,5.32-5.27Z" />
                                                    </g>
                                                </g>
                                            </svg>
                                    }
                                </IconButton>
                            </TooltipComponent>
                            <DropZone accept=".json" onDrop={(dataToImport) => importReport("All", dataToImport)}>
                                {
                                    ({ getRootProps, getInputProps }) => (
                                        <TooltipComponent title="Import" arrow>
                                            <IconButton variant="contained" color="primary" className={classes.organizationImport}>
                                                <span {...getRootProps()}>
                                                    <input {...getInputProps()} />
                                                    {
                                                        isImportLoading ?
                                                            <Loader size={'extraSmall'} type={'button'} classList={classes.relative} /> :

                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24.343 23.846" fill={theme.palette.primary.main}>
                                                                <g id="download_button" transform="translate(-1600.338 -267.233)">
                                                                    <g id="Group_3" data-name="Group 3">
                                                                        <path id="Path_4" data-name="Path 4" d="M1624.68,291.079h-24.343V276.71h2v12.369h20.343V276.71h2Z" />
                                                                        <path id="Path_5" data-name="Path 5" d="M1617.779,276.513l-5.32,5.27-5.4-5.35,1.4-1.42,3.04,3v-10.78h2v10.7l2.87-2.84Z" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                    }

                                                </span>
                                            </IconButton>
                                        </TooltipComponent>
                                    )
                                }
                            </DropZone>
                        </Fragment>
                    }
                    {
                        organizationCount > organizationList.length &&
                        <TooltipComponent title={"Add Organization"} arrow>
                            <IconButton className={classes.alignIconCenter} onClick={() => onChangeOrganization('add', '')}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                        <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                            <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                            <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                        </g>
                                    </g>
                                </svg>
                            </IconButton>
                        </TooltipComponent>
                    }

                </Grid>
            </Grid>
            <Grid className={classes.tableWrapperStyle}>
                <Table className={classNames(classes.tableStyle, classes.organizationTable)}>
                    <TableHead>
                        <TableRow>
                            {
                                appConstants.organizationHeaders.map((header, index) =>
                                    <TableCell className={classes.tableHeadCell} key={index} align={header === "actions" ? "center" : "left"}>
                                        {header}
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            organizations.map((organization, index) =>
                                <TableRow key={`row_${index}`}>
                                    <TableCell className={classes.tableCell}>
                                        {organization.name}
                                    </TableCell>
                                    <TableCell className={classNames(classes.tableCell, classes.oneLineEllip)} nowrap>
                                        {organization.email}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {organization.phone_number}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {organization.address_line1}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        {organization.country}
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <Switch color="secondary" size="small" checked={organization.is_active} onChange={() => onChangeActive(!organization.is_active, organization)} />
                                    </TableCell>
                                    <TableCell align="center" className={classes.organizationAction}>
                                        <TooltipComponent title={"Export"} arrow>
                                            <IconButton className={classes.padding8} color="primary" onClick={() => exportReport(organization.id)}>
                                                {
                                                    (isExportLoading && (exportOrganization === organization.id)) ?
                                                        <Loader size={'extraSmall'} type={'button'} classList={classes.relative} /> :

                                                        <svg fill={theme.palette.primary.main} xmlns="http://www.w3.org/2000/svg" width="16.5" height="16.5" viewBox="0 0 24.342 24.106">
                                                            <g id="upload_button" transform="translate(-1568.963 -266.973)">
                                                                <g id="Group_2" data-name="Group 2">
                                                                    <path id="Path_2" data-name="Path 2" d="M1593.3,291.079h-24.342V276.71h2v12.369H1591.3V276.71h2Z" />
                                                                    <path id="Path_3" data-name="Path 3" d="M1586.4,272.323l-1.41,1.42-3.03-3v10.78h-2v-10.7l-2.87,2.84-1.41-1.42,5.32-5.27Z" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                }
                                            </IconButton>
                                        </TooltipComponent>
                                        <TooltipComponent title={"Edit"} arrow>
                                            <IconButton className={classes.padding8} onClick={() => onChangeOrganization('edit', organization)}>
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="17.181" height="17.181" viewBox="0 0 17.181 17.181">
                                                    <g id="pencil" data-name="pencil" transform="translate(0 -0.002)">
                                                        <g id="Group_1096" data-name="Group 1096" transform="translate(0 0.002)">
                                                            <path id="Path_269" data-name="Path 269" d="M16.723,2.79,14.38.445a1.6,1.6,0,0,0-2.21,0l-10.5,10.6a.393.393,0,0,0-.1.168L.015,16.685a.391.391,0,0,0,.483.483L5.964,15.6a.392.392,0,0,0,.168-.1L16.723,5a1.564,1.564,0,0,0,0-2.21Zm-6.254.5,1.437,1.437-7.5,7.5-.539-1.077a.391.391,0,0,0-.349-.216H2.889ZM.959,16.224l.509-1.78,1.272,1.272Zm4.508-1.288L3.6,15.47,1.713,13.584l.534-1.868H3.273l.673,1.346a.391.391,0,0,0,.175.175l1.346.673v1.026Zm.781-.642v-.625a.391.391,0,0,0-.216-.349l-1.077-.539,7.5-7.5L13.9,6.714Zm9.924-9.836L14.45,6.164,11.018,2.732l1.705-1.721a.8.8,0,0,1,1.1,0l2.343,2.343a.781.781,0,0,1,0,1.1Z" transform="translate(0 -0.002)" fill={theme.palette.secondary.main} />
                                                        </g>
                                                    </g>
                                                </svg> */}
                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22" viewBox="0 0 32 32" >
                                                    <g id="edit" transform="translate(0 0)">
                                                        <path fill={theme.palette.primary.main}
                                                            stroke={theme.palette.primary.main}
                                                            strokeWidth="0.3"
                                                            strokeMiterlimit="10"
                                                            d="M27.2,8L24,4.8c-0.8-0.8-2.2-0.8-3,0L6.5,19.4c-0.1,0.1-0.1,0.1-0.1,0.2l-2.1,7.5c-0.1,0.3,0.1,0.6,0.4,0.7c0.1,0,0.2,0,0.3,0l7.5-2.1c0.1,0,0.2-0.1,0.2-0.1l14.6-14.4C28,10.2,28,8.9,27.2,8L27.2,8z M18.6,8.7l2,2L10.2,21l-0.7-1.5c-0.1-0.2-0.3-0.3-0.5-0.3H8.2L18.6,8.7z M5.5,26.5L6.2,24L8,25.8L5.5,26.5z M11.7,24.7l-2.6,0.7l-2.6-2.6l0.7-2.6h1.4l0.9,1.8c0.1,0.1,0.1,0.2,0.2,0.2l1.8,0.9L11.7,24.7L11.7,24.7z M12.8,23.8V23c0-0.2-0.1-0.4-0.3-0.5L11,21.7l10.3-10.3l2,2L12.8,23.8zM26.4,10.3l-2.4,2.3l-4.7-4.7l2.3-2.4c0.4-0.4,1.1-0.4,1.5,0l3.2,3.2C26.8,9.2,26.8,9.9,26.4,10.3L26.4,10.3z" />
                                                    </g>
                                                </svg>
                                            </IconButton>
                                        </TooltipComponent>
                                        <TooltipComponent title={"Delete"} arrow>
                                            <IconButton className={classes.padding8} onClick={() => setSelectedOrganization(organization)}>
                                                {/* <svg xmlns="http://www.w3.org/2000/svg" width="15.879" height="18" viewBox="0 0 15.879 18">
                                                    <path fill={theme.palette.primary.main} d="M126.181,166H114.543a.449.449,0,0,0-.438.5l1.194,10.447a2.282,2.282,0,0,0,2.267,2.025h5.794a2.272,2.272,0,0,0,2.27-2.066l.991-10.411a.453.453,0,0,0-.113-.344A.446.446,0,0,0,126.181,166Zm-1.431,10.816a1.4,1.4,0,0,1-1.392,1.272h-5.794a1.4,1.4,0,0,1-1.39-1.246l-1.138-9.96H125.7Z" transform="translate(-112.422 -160.971)" />
                                                    <path fill={theme.palette.primary.main} d="M91.473,54.206H86.8v-.842A1.376,1.376,0,0,0,85.424,52h-2.9a1.376,1.376,0,0,0-1.374,1.364v.842H76.476a.441.441,0,0,0,0,.882h15a.441.441,0,0,0,0-.882Zm-5.557,0H82.033v-.842a.493.493,0,0,1,.492-.481h2.9a.493.493,0,0,1,.492.481Z" transform="translate(-76.035 -52)" />
                                                </svg> */}
                                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 32 32">
                                                    <g id="Delete">
                                                        <path fill={theme.palette.primary.main}
                                                            stroke={theme.palette.primary.main}
                                                            strokeWidth="0.92"
                                                            strokeMiterlimit="10"
                                                            d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                                        <path fill={theme.palette.primary.main}
                                                            stroke={theme.palette.primary.main}
                                                            strokeWidth="0.92"
                                                            strokeMiterlimit="10"
                                                            d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                    </g>
                                                </svg>
                                            </IconButton>
                                        </TooltipComponent>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </Grid>
            {
                organizations.length === 0 && !isLoading &&
                <NoResultFound height="80%" />
            }
            {isLoading && <Loader />}
            <AlertDialog title="Delete Organization"
                message={`Are you sure you want to delete the organization - ${selectedOrganization ? selectedOrganization.name : ''}?`}
                okButtonText="OK"
                cancelButtonText="Cancel"
                show={Boolean(selectedOrganization)}
                onClickOK={() => deleteOrganizationList(selectedOrganization)}
                onClickCancel={() => setSelectedOrganization(null)} />
        </Grid>
    );
};

OrganizationList.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    onChangeOrganization: PropTypes.func,
    isSystemLicense: PropTypes.number
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(OrganizationList);