import React, { useState, Fragment, useCallback, useEffect } from 'react';
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import {
    withStyles, Grid, Typography, Button, Table, TableHead,
    TableBody, TableRow, TableCell, IconButton, MenuItem, FormControlLabel, Switch
} from '@material-ui/core';
import classnames from 'classnames';
import TextBox from '../../TextBox/TextBox.jsx';
import Styles from '../../../layouts/Styles.jsx';
import ConnectorStyles from '../ConnectorStyles.jsx';
import Loader from '../../Loaders/Loader.jsx';
import { appConfig } from '../../../config/appConfig.js';
import { connectRestAPIDatasource, connectRestApiDataset, getRestAPIConnectedDatasets } from '../../../actions/connectorActions';
import { appConstants } from '../../../constants/appConstants.js';
import { deleteDataSet } from '../../../actions/datasetActions.js';
import Parameters from './Parameters.jsx';
import AlertDialog from '../../AlertDialog/AlertDialog.jsx';
import CheckboxComponent from '../../ChecboxComponent/CheckboxComponent.jsx';

const RestApiConnector = (props) => {
    const { classes, datasourceType, datasourceId, onConnect, onCancel, theme } = props;
    const dispatch = useDispatch();
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [datasets, setDatasets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isValidating, setIsValidating] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [datasource, setDatasource] = useState(null);
    const [selectedDataSet, setSelectedDataSet] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [connectionConfig, setConnectionConfig] = useState({
        /*
         * "authType": appConstants.restAuthTypes[1],
         * "url": "",
         * "base_url": "https://secure.rec1.com",
         * "token_url": "/api/v1/token",
         * "swagger_url": "https://secure.rec1.com/api/v1/swagger.json",
         * "conn_params": [{ key: "grant_type", value: "client_credentials" }, { key: "client_id", value: "SpokaneWA" }, { key: "client_secret", value: "36B2D08j7v" }, { key: "scope", value: "api_read api_write" }]
         */
        authType: appConstants.restAuthTypes[0],
        "url": "",
        "base_url": "",
        "token_url": "",
        "swagger_url": "",
        "conn_params": [{ key: "", value: "" }]
    });
    const [parameterProps, setParameterProps] = useState({ open: false, index: -1, anchorEl: null, parameters: [] });
    const userConfig = useSelector(({ setting }) => setting.user_config);

    const onSearchDatasets = useCallback((key) => {
        setSearchKey(key);
    }, []);

    const handleChange = useCallback((property, value) => {
        connectionConfig[property] = value;
        if (property === 'authType') {
            if (value === appConstants.restAuthTypes[0]) {
                connectionConfig['base_url'] = '';
                connectionConfig['token_url'] = '';
                connectionConfig['swagger_url'] = '';
                connectionConfig['conn_params'] = [];
            } else {
                connectionConfig.url = '';
            }
        }
        setConnectionConfig({ ...connectionConfig });
        setHasChanges(true);
    }, [connectionConfig]);

    const onChange = (name, value) => {
        connectionConfig[name] = value;
        setConnectionConfig({ ...connectionConfig });
        if (!hasChanges) {
            setHasChanges(true);
        }
    };

    const addParameter = () => {
        connectionConfig.conn_params.push({ key: "", value: "" });
        setConnectionConfig({ ...connectionConfig });
        if (!hasChanges) {
            setHasChanges(true);
        }
    };

    const removeParameter = (index) => {
        connectionConfig.conn_params.splice(index, 1);
        setConnectionConfig({ ...connectionConfig });
        if (!hasChanges) {
            setHasChanges(true);
        }
    };

    const onChangeParameter = (name, value, index) => {
        connectionConfig.conn_params[index][name] = value;
        setConnectionConfig({ ...connectionConfig });
        if (!hasChanges) {
            setHasChanges(true);
        }
    };

    const removeDataset = useCallback((dataset) => {
        const tables = [...datasets];
        const index = tables.indexOf(dataset);
        if (index > -1) {

            if (dataset.dataset_id) {
                setHasChanges(true);
                dispatch(deleteDataSet({ datasets: [dataset.dataset_id] })).then((response) => {
                    if (response) {
                        dataset['dataset_id'] = null;
                        dataset.isSelected = false;
                        tables.splice(index, 1, { ...dataset });
                        setDatasets([...tables]);
                        setIsAllSelected(false);
                    }
                });
                setSelectedDataSet(null);
            } else {
                dataset.isSelected = false;
                tables.splice(index, 1, { ...dataset });
                setDatasets([...tables]);
                setIsAllSelected(false);
                setSelectedDataSet(null);
            }
        }
    }, [datasets, dispatch]);

    const deleteSelectedDataset = useCallback((dataset) => {
        if (!dataset.dataset_id) {
            removeDataset(dataset);
            return;
        }
        setSelectedDataSet(dataset);
    }, [removeDataset]);


    const onSelectionChange = useCallback((selectedDataset) => {
        for (const dataset of datasets) {
            if (dataset.name.toLowerCase() === selectedDataset.name.toLowerCase()) {
                dataset.isSelected = !dataset.isSelected;
            }
        }
        setIsAllSelected(datasets.filter((p) => p.isSelected).length === datasets.length);
        setDatasets([...datasets]);
    }, [datasets]);

    const onSelectAll = useCallback(() => {
        setIsAllSelected(!isAllSelected);
        for (const dataset of datasets) {
            dataset.isSelected = !isAllSelected;
        }
        setDatasets(datasets);
    }, [datasets, isAllSelected]);

    const loadDataSource = useCallback((datasource) => {
        if (datasource && datasource.config) {
            const config = {
                authType: datasource.config.authType ? datasource.config.authType : appConstants.restAuthTypes[0],
                "url": datasource.config.url ? datasource.config.url : '',
                "base_url": datasource.config.base_url ? datasource.config.base_url : '',
                "token_url": datasource.config.token_url ? datasource.config.token_url : '',
                "swagger_url": datasource.config.swagger_url ? datasource.config.swagger_url : '',
                "conn_params": datasource.config.conn_params ? datasource.config.conn_params : []
            };
            setConnectionConfig({ ...config });
            setDatasource({ ...datasource });
        }
    }, []);

    const loadDatasets = useCallback((datasets) => {
        datasets = datasets.map((elem) => {
            elem.include_organization_domain_score = (elem.include_organization_domain_score || elem.include_organization_domain_score === false) ? elem.include_organization_domain_score : true;
            return elem;
        });
        setIsAllSelected(datasets.filter((p) => p.isSelected).length === datasets.length);
        setDatasets([...datasets]);
        setHasChanges(false);
    }, [setDatasets]);

    /*
     * const addToRestApi = useCallback(() => {
     *     const resturl = [...datasets];
     *     resturl.push({
     *         name: connectionConfig.url,
     *         authType: 'Rest Api'
     *     });
     *     setDatasets([...resturl]);
     * }, [connectionConfig, datasets]);
     */

    const connectToDatasource = useCallback(() => {
        connectionConfig['connection_type'] = datasourceType.type;
        if (connectionConfig.authType === appConstants.restAuthTypes[0]) {
            const resturl = [...datasets];
            const selectedIndex = datasets.findIndex((p) => p.name.toLowerCase() === connectionConfig.url.toLowerCase());
            if (selectedIndex < 0) {
                resturl.push({
                    name: connectionConfig.url,
                    authType: 'Rest Api'
                });
                setDatasets([...resturl]);
            }
        } else {
            const datasource = {
                name: appConfig.defaultDataSourceName,
                type: datasourceType.type,
                description: '',
                config: {
                    ...connectionConfig
                }
            };
            setIsValidating(true);
            dispatch(connectRestAPIDatasource(datasource)).then((response) => {
                setIsValidating(false);
                if (!response) {
                    return;
                }
                setDatasource(response.datasource ? { ...response.datasource } : {});
                loadDatasets(response.datasets ? [...response.datasets] : []);
            }).catch(() => setIsValidating(false));
        }
    }, [connectionConfig, datasourceType, datasets, dispatch, loadDatasets]);


    const createDatasets = useCallback(() => {
        if (!datasourceId && !datasource) {
            return;
        }
        const selectedDatasets = datasets.filter((p) => p.isSelected);
        const datasetsToConnect = [];
        for (const dataset of selectedDatasets) {
            if (!dataset.dataset_id) {
                datasetsToConnect.push(dataset);
            }
        }
        if (!datasetsToConnect) {
            return;
        }

        let selectedDatasourceId = datasourceId;
        if (!datasourceId && datasource) {
            selectedDatasourceId = datasource.id;
        }

        const datasetConfig = {
            'datasource_id': selectedDatasourceId,
            datasets: datasetsToConnect
        };
        setIsLoading(true);
        dispatch(connectRestApiDataset(datasetConfig)).then((response) => {
            if (!response) {
                setIsLoading(false);
                return;
            }
            if (onConnect) {
                onConnect(selectedDatasourceId, true);
            }
        }).catch(() => setIsLoading(false));
    }, [datasets, datasource, datasourceId, dispatch, onConnect]);

    const connectToDatasourceRestApi = useCallback(() => {
        connectionConfig['connection_type'] = datasourceType.type;
        const datasource = {
            name: appConfig.defaultDataSourceName,
            type: datasourceType.type,
            description: '',
            config: {
                ...connectionConfig
            }
        };
        setIsLoading(true);

        if (!datasourceId) {
            dispatch(connectRestAPIDatasource(datasource)).then((response) => {
                if (!response) {
                    return;
                }

                const selectedDatasets = datasets.filter((p) => p.isSelected);
                const datasetsToConnect = [];
                for (const dataset of selectedDatasets) {
                    if (!dataset.dataset_id) {
                        datasetsToConnect.push(dataset);
                    }
                }
                if (!datasetsToConnect) {
                    return;
                }
                const datasetConfig = {
                    'datasource_id': response.datasource.id,
                    datasets: datasetsToConnect
                };

                const datasourceId = response.datasource.id;

                dispatch(connectRestApiDataset(datasetConfig)).then((response) => {
                    if (!response) {
                        setIsLoading(false);
                        return;
                    }
                    if (onConnect) {
                        onConnect(datasourceId, true);
                    }
                }).catch(() => setIsLoading(false));

            }).catch(() => setIsLoading(false));
        } else {
            createDatasets(datasourceId);
        }
    }, [connectionConfig, datasourceType.type, datasourceId, dispatch, datasets, onConnect, createDatasets]);

    useEffect(() => {
        if (!datasourceId) {
            return;
        }
        setIsPageLoading(true);
        dispatch(getRestAPIConnectedDatasets(datasourceId)).then((response) => {
            setIsPageLoading(false);
            if (response) {
                loadDataSource(response.datasource);
                loadDatasets(response.datasets);
            }
        });
    }, [datasourceId, dispatch, loadDataSource, loadDatasets]);

    const enableParameter = (event, index) => {
        parameterProps.index = index;
        parameterProps.open = true;
        parameterProps.anchorEl = event.target;
        parameterProps.parameters = datasets[index].parameters;
        setParameterProps({ ...parameterProps });
    };

    const handleParameter = (value, index) => {
        parameterProps.parameters[index].paramvalue = value;
        setParameterProps({ ...parameterProps });
    };

    const onCloseParameter = () => {
        parameterProps.open = false;
        parameterProps.parameters = [];
        setParameterProps({ ...parameterProps });
    };

    const saveParameter = () => {
        datasets[parameterProps.index].parameters = parameterProps.parameters;
        parameterProps.open = false;
        parameterProps.parameters = [];
        setParameterProps({ ...parameterProps });
        setDatasets([...datasets]);
    };

    const onUpdateDataset = useCallback((dataset, property, value) => {
        const tables = [...datasets];
        const index = tables.indexOf(dataset);
        if (index > -1) {
            dataset[property] = value;
            if (property === 'con_type') {
                dataset['con_type'] = value;
            }
            tables.splice(index, 1, { ...dataset });
            setDatasets([...tables]);
        }
    }, [datasets, setDatasets]);

    let selectedDatasets = [...datasets];
    if (searchKey) {
        selectedDatasets = datasets.filter((p) => p.name.toLowerCase().includes(searchKey.toLowerCase()));
    }


    return (
        <ValidatorForm form="restapi_config" autoComplete={"off"} onSubmit={() => connectToDatasource()} style={{ height: '100%' }}>
            <Grid container className={classnames(classes.connectcontainer)} direction="row" justify="flex-start" alignItems="flex-start">
                <Grid item xs={12}>
                    <Typography variant={"h4"} className={classes.connectTitle}>
                        {'Configure Rest API Connection'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" className={classes.connectdescription}>
                        {datasourceType.description}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={4} style={{ marginTop: 20 }}>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="API Type"
                                        name="authType"
                                        validators={['required']}
                                        errorMessages={['API type is required']}
                                        value={connectionConfig.authType}
                                        select
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    >
                                        {
                                            appConstants.restAuthTypes.map((authType, index) => {
                                                return (
                                                    <MenuItem key={`authType_${index}`} value={authType}>
                                                        {authType}
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </TextBox>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6}>
                            {
                                connectionConfig.authType !== appConstants.restAuthTypes[1] &&
                                <Grid container>
                                    <Grid xs={8}>
                                        <TextBox
                                            label="URL"
                                            name="url"
                                            value={connectionConfig.url ? connectionConfig.url : ''}
                                            validators={['required']}
                                            errorMessages={['URL is required']}
                                            onChange={(event) => onChange(event.target.name, event.target.value)}
                                            fullWidth
                                            color="primary"
                                        />
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                        {
                            connectionConfig.authType !== appConstants.restAuthTypes[0] &&
                            <Grid item sm={6}>
                                <Grid container>
                                    <Grid xs={8}>
                                        <TextBox
                                            label="Base URL"
                                            name="base_url"
                                            value={connectionConfig.base_url ? connectionConfig.base_url : ''}
                                            validators={['required']}
                                            errorMessages={['Base URL is required']}
                                            onChange={(event) => onChange(event.target.name, event.target.value)}
                                            fullWidth
                                            color="primary"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {
                            connectionConfig.authType !== appConstants.restAuthTypes[0] &&
                            <Grid item sm={6}>
                                <Grid container>
                                    <Grid xs={8}>
                                        <TextBox
                                            label="Token URL"
                                            name="token_url"
                                            value={connectionConfig.token_url ? connectionConfig.token_url : ''}
                                            validators={['required']}
                                            errorMessages={['Token URL is required']}
                                            onChange={(event) => onChange(event.target.name, event.target.value)}
                                            fullWidth
                                            color="primary"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {
                            connectionConfig.authType !== appConstants.restAuthTypes[0] &&
                            <Grid item sm={6}>
                                <Grid container>
                                    <Grid xs={8}>
                                        <TextBox
                                            label="Swagger URL"
                                            name="swagger_url"
                                            value={connectionConfig.swagger_url ? connectionConfig.swagger_url : ''}
                                            onChange={(event) => onChange(event.target.name, event.target.value)}
                                            fullWidth
                                            color="primary"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item sm={6} />
                        {
                            connectionConfig.authType !== appConstants.restAuthTypes[0] &&
                            connectionConfig.conn_params.map((parameter, index) =>
                                <Fragment key={index}>
                                    <Grid item sm={6}>
                                        <Grid container>
                                            <Grid xs={8}>
                                                <TextBox
                                                    label="Key"
                                                    name="key"
                                                    value={parameter.key ? parameter.key : ''}
                                                    onChange={(event) => onChangeParameter(event.target.name, event.target.value, index)}
                                                    fullWidth
                                                    color="primary"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <Grid container>
                                            <Grid xs={8}>
                                                <TextBox
                                                    label="Value"
                                                    name="value"
                                                    value={parameter.value ? parameter.value : ''}
                                                    onChange={(event) => onChangeParameter(event.target.name, event.target.value, index)}
                                                    fullWidth
                                                    color="primary"
                                                />
                                            </Grid>
                                            <Grid xs={4}>
                                                {
                                                    index !== 0 &&
                                                    <IconButton onClick={() => removeParameter(index)}>
                                                        <svg id="delete_1_" data-name="delete (1)" xmlns="http://www.w3.org/2000/svg" width="16.2" height="18" viewBox="0 0 16.2 18">
                                                            <path id="Path_898" data-name="Path 898" d="M132.635,4.7V4.25A2.251,2.251,0,0,1,134.885,2h.9a2.249,2.249,0,0,1,2.25,2.25V4.7h4.5a.9.9,0,1,1,0,1.8h-.9v9.9a3.6,3.6,0,0,1-3.6,3.6h-5.4a3.6,3.6,0,0,1-3.6-3.6V6.5h-.9a.9.9,0,0,1,0-1.8Zm7.2,1.8h-9v9.9a1.8,1.8,0,0,0,1.8,1.8h5.4a1.8,1.8,0,0,0,1.8-1.8Zm-3.6,2.7v6.3a.9.9,0,0,0,1.8,0V9.2a.9.9,0,0,0-1.8,0Zm-3.6,0v6.3a.9.9,0,0,0,1.8,0V9.2a.9.9,0,0,0-1.8,0Zm3.6-4.5V4.25a.449.449,0,0,0-.45-.45h-.9a.45.45,0,0,0-.45.45V4.7Z" transform="translate(-127.235 -2)" fill="#61cbff" />
                                                        </svg>
                                                    </IconButton>
                                                }
                                                {
                                                    (index === connectionConfig.conn_params.length - 1) &&
                                                    <IconButton onClick={() => addParameter()}>
                                                        <svg id="add_circle_outline-24px" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                            <path id="Path_242" data-name="Path 242" d="M0,0H20V20H0Z" fill="none" />
                                                            <path id="Path_243" data-name="Path 243" d="M11.167,6.167H9.5V9.5H6.167v1.667H9.5V14.5h1.667V11.167H14.5V9.5H11.167ZM10.333,2a8.333,8.333,0,1,0,8.333,8.333A8.336,8.336,0,0,0,10.333,2Zm0,15A6.667,6.667,0,1,1,17,10.333,6.676,6.676,0,0,1,10.333,17Z" transform="translate(-0.333 -0.333)" fill="#6bb3d2" />
                                                        </svg>
                                                    </IconButton>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </Grid>
                    {
                        //  connectionConfig.authType !== appConstants.restAuthTypes[0] &&
                        <Grid item xs={12} className={classes.connectorButtons}>
                            <Grid container spacing={4}>
                                <Grid item xs={12}>
                                    <Button type="submit"
                                        variant="contained"
                                        disabled={isValidating}
                                        color="primary">
                                        {'Validate'}
                                        {isValidating && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                    </Button>
                                    <Button className={classnames(classes.cancelButton)}
                                        style={{ marginLeft: 20 }}
                                        disabled={isValidating}
                                        onClick={() => onCancel(hasChanges)}>
                                        {'Cancel'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {
                        (datasets.length > 0) &&
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.searchInputContainer}>
                                <TextBox placeholder={'Search Dataset'}
                                    onChange={(event) => onSearchDatasets(event.target.value)}
                                    name="searchKey"
                                    id="searchKey"
                                    value={searchKey}
                                    className={classes.inputOutline} />
                                {
                                    searchKey.length === 0 ?
                                        <IconButton className={classes.includeSearchIcon}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.094" height="13.092" viewBox="0 0 13.094 13.092">
                                                <g transform="translate(0 -0.035)">
                                                    <path fill="#afb2b3" d="M5.27,10.57A5.257,5.257,0,0,0,8.5,9.46l3.483,3.483a.655.655,0,0,0,.926-.926L9.427,8.534A5.267,5.267,0,1,0,5.27,10.57ZM2.471,2.5a3.958,3.958,0,1,1,0,5.6h0a3.944,3.944,0,0,1-.02-5.577l.02-.02Z" transform="translate(0 0)" />
                                                </g>
                                            </svg>
                                        </IconButton> :
                                        <IconButton className={classes.includeDeleteIcon} onClick={() => onSearchDatasets('')}>
                                            <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                            </svg>
                                        </IconButton>
                                }
                            </Grid>
                        </Grid>
                    }
                    {
                        (selectedDatasets.length > 0) &&
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classnames(classes.connectTableContainer, classes.serverView)}>
                            <Table stickyHeader className={classnames(classes.designTable)}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            {/* <Checkbox color="primary" checked={isAllSelected} onClick={() => onSelectAll()} /> */}
                                            <CheckboxComponent checked={isAllSelected} onClick={() => onSelectAll()} />
                                        </TableCell>
                                        {
                                            appConstants && appConstants.restapiConnectorTableHeaders &&
                                            appConstants.restapiConnectorTableHeaders.filter((elem) => (userConfig.include_organization_domain_score ? true : elem !== 'Include DQScore')).map((header, index) => {
                                                return (
                                                    <TableCell key={`fileConnectorTable${index}`} className={(index !== 0 && index !== 3) ? classes.textCenter : null}>
                                                        {header}
                                                    </TableCell>
                                                );
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        selectedDatasets.map((dataset, index) => {
                                            return (
                                                <TableRow key={`file${index}`}>
                                                    <TableCell>
                                                        <CheckboxComponent checked={dataset.isSelected ? dataset.isSelected : false} onClick={() => onSelectionChange(dataset)} />
                                                        {/* <Checkbox color="primary" checked={dataset.isSelected ? dataset.isSelected : false} onClick={() => onSelectionChange(dataset)} /> */}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography noWrap>
                                                            {dataset.name}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.textCenter}>
                                                        {dataset.method}
                                                    </TableCell>
                                                    <TableCell className={classes.textCenter}>
                                                        <Typography onClick={(event) => enableParameter(event, index)}>
                                                            Add Parameter
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell className={classes.loadTypeInput}>
                                                        <TextBox
                                                            name="con_type"
                                                            value={dataset.con_type ? dataset.con_type : appConstants.connectionType[0].value}
                                                            onClick={(event) => event.stopPropagation()}
                                                            select
                                                            disabled={!dataset.isSelected}
                                                            onChange={(event) => onUpdateDataset(dataset, event.target.name, event.target.value)}
                                                            fullWidth
                                                        >
                                                            {
                                                                appConstants.connectionType.map((conType, index) => {
                                                                    return (
                                                                        <MenuItem key={`conType${index}`} value={conType.value}>
                                                                            {conType.name}
                                                                        </MenuItem>
                                                                    );
                                                                })
                                                            }
                                                        </TextBox>
                                                    </TableCell>
                                                    {
                                                        userConfig.include_organization_domain_score &&
                                                        <TableCell align="center" onClick={(event) => event.stopPropagation()}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Switch
                                                                        checked={dataset.include_organization_domain_score}
                                                                        onChange={(event) => { onUpdateDataset(dataset, 'include_organization_domain_score', !dataset.include_organization_domain_score); }}
                                                                        name="include_organization_domain_score"
                                                                        disabled={!dataset.isSelected}
                                                                    />
                                                                }
                                                            />
                                                        </TableCell>
                                                    }
                                                    <TableCell className={classes.textCenter}>
                                                        {
                                                            dataset.dataset_id &&
                                                            <IconButton
                                                                disabled={isLoading}
                                                                onClick={() => deleteSelectedDataset(dataset)}
                                                            >
                                                                <svg version="1.1"
                                                                    id="Layer_1"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    x="0px"
                                                                    y="0px"
                                                                    width="22px"
                                                                    height="22px"
                                                                    viewBox="0 0 32 32">
                                                                    <g id="Delete">
                                                                        <path fill={theme.palette.primary.main}
                                                                            stroke={theme.palette.primary.main}
                                                                            strokeWidth="0.92"
                                                                            strokeMiterlimit="10"
                                                                            d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
		L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
		L22.3,25.8z" />
                                                                        <path fill={theme.palette.primary.main}
                                                                            stroke={theme.palette.primary.main}
                                                                            strokeWidth="0.92"
                                                                            strokeMiterlimit="10"
                                                                            d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
		c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
		c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                                    </g>
                                                                </svg>
                                                            </IconButton>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Grid>
                    }
                    {
                        (selectedDatasets.length > 0) && connectionConfig.authType !== appConstants.restAuthTypes[0] &&
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container direction="row" justify="flex-end">
                                <Grid item className={classes.btnContainer}>
                                    <Button variant="contained"
                                        disabled={isLoading}
                                        color="primary"
                                        onClick={() => createDatasets()}
                                        className={classnames(classes.actionButtons)}>
                                        {'Connect'}
                                        {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button className={classnames(classes.cancelButton, classes.actionButtons)}
                                        disabled={isLoading}
                                        onClick={() => onCancel(hasChanges)}>
                                        {'Cancel'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {
                        (selectedDatasets.length > 0) && connectionConfig.authType !== appConstants.restAuthTypes[1] &&
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Grid container direction="row" justify="flex-end">
                                <Grid item className={classes.btnContainer}>
                                    <Button variant="contained"
                                        disabled={isLoading}
                                        color="primary"
                                        onClick={() => connectToDatasourceRestApi()}
                                        className={classnames(classes.actionButtons)}>
                                        {'Connect'}
                                        {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Button className={classnames(classes.cancelButton, classes.actionButtons)}
                                        disabled={isLoading}
                                        onClick={() => onCancel(hasChanges)}>
                                        {'Cancel'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
            {
                parameterProps.open &&
                <Parameters
                    parameterProps={parameterProps}
                    handleChange={handleParameter}
                    onClose={() => onCloseParameter()}
                    saveParameter={() => saveParameter()}
                />
            }
            <AlertDialog title="Delete Dataset"
                message={`Are you sure you want to delete the selected dataset ${selectedDataSet ? selectedDataSet.name : ''}?`}
                okButtonText="OK"
                cancelButtonText="Cancel"
                show={Boolean(selectedDataSet)}
                onClickOK={() => removeDataset(selectedDataSet)}
                onClickCancel={() => setSelectedDataSet(null)} />
            {isPageLoading && <Loader />}
        </ValidatorForm >
    );
};

RestApiConnector.propTypes = {
    classes: PropTypes.object,
    datasourceType: PropTypes.object,
    theme: PropTypes.object,
    datasourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onConnect: PropTypes.func,
    onCancel: PropTypes.func
};

export default withStyles((theme) => ({
    ...ConnectorStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(RestApiConnector);