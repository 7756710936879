import palette from "../../theme/palette";

const ColorConst = palette.Lineage;

const LineageStyle = (type) => ({
    svgStyle: {
        width: '100%',
        height: '100%'
    },
    lineageBox: (selected, index, toggle) => ({
        border: `1px solid ${ColorConst.NodeType[type] || 'black'}`,
        width: index !== undefined ? "250px" : "90%",
        height: toggle ? 'auto' : ''
        // margin: index !== undefined ? "0px" : "10px auto"
    }),
    lineageBoxHeading: (selected) => ({
        color: `${selected ? 'black' : 'white'}`,
        textAlign: "center",
        backgroundColor: `${selected ? ColorConst.SearchSelected : (ColorConst.NodeType[type] || 'black')}`,
        padding: "0rem 1rem 0rem 1rem",
        cursor: 'pointer'
    }),
    lineageList: {
        margin: "1rem 0px 1rem 0px",
        padding: "0px"
    },
    lineageSourcecodeBody: {
        height: '90%',
        padding: "1rem",
        overflowY: "auto"
    },
    lineageListItem: (activeType, scoreColor) => ({
        listStyleType: "none",
        cursor: 'pointer',
        padding: "0rem 1rem 0rem 1rem",
        backgroundColor: {
            1: '#dddddd',
            2: ColorConst.SearchSelected,
            3: scoreColor
        // }[activeType] || scoreColor || '',
        }[activeType] || '',
        wordWrap: "break-word"
    }),
    noResultFound: {
        height: "100%",
        position: "absolute",
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        '& .MuiTypography-root': {
            marginTop: '15px',
            color: 'gray',
            overflowY: 'hidden'
        }
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center',
        wordWrap: "break-word"
    },
    headerImage: {
        width: '1rem'
    },
    headerText: {
        paddingLeft: '0.5rem',
        wordWrap: "break-word",
        maxWidth: '10rem'
    },
    filterBoxContainer: {
        display: 'flex',
        height: '5rem',
        width: '100%',
        alignItems: 'center'
    }
});
export default LineageStyle;