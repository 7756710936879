import React, { useState, useEffect, useCallback } from 'react';
import { Grid, Popover, withStyles, Typography, Button, IconButton, Table, TableBody, TableHead, TableCell, TableRow, TextField, TableContainer } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import TooltipComponent from '../Tooltip/Tooltip.jsx';

import EnrichmentStyles from './EnrichmentStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import { getDatasetEnrichments, createDatasetEnrichment, updateDatasetEnrichment, deleteDatasetEnrichment } from '../../actions/datasourceActions';
import { getEnrichments } from '../../actions/settingActions';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';

import Loader from '../Loaders/Loader.jsx';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';


const Enrichment = (props) => {
    const { classes, enrichmentProps, onClose } = props;
    const { open, anchorElement, datasetId, datasourceId, theme, datasetAttributes } = enrichmentProps;
    const datasetAttributeNames = datasetAttributes ? datasetAttributes.map((a) => a.name) : [];
    const [enrichments, setEnrichments] = useState([]);
    const [enrichmentNames, setEnrichmentNames] = useState([]);
    const [enrichmentList, setEnrichmentList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [selectedEnrichment, setSelectedEnrichment] = useState(null);
    const dispatch = useDispatch();

    const onEnrichmentAdd = useCallback(() => {
        enrichmentList.push({
            source_id: datasourceId,
            dataset_id: datasetId,
            url_attributes: {},
            query_attributes: {},
            response_attributes: {},
            deduplicate_attributes: []
        });
        setEnrichmentList([...enrichmentList]);
    }, [datasetId, datasourceId, enrichmentList]);

    useEffect(() => {
        setLoading(true);
        dispatch(getEnrichments(0)).then((resp_enrichment_list) => {
            if (resp_enrichment_list) {
                setEnrichments(resp_enrichment_list);
                setEnrichmentNames(resp_enrichment_list.map((r) => r.name));
            }
            dispatch(getDatasetEnrichments(datasourceId, datasetId)).then((response) => {
                setLoading(false);
                if (response) {
                    response = response.filter((r) => r.source === parseInt(datasourceId) && r.dataset === parseInt(datasetId));
                    response = response.map((r) => {
                        const enrich_master = resp_enrichment_list.filter((item) => item.id === r.enrichment)[0];
                        const url_attr = JSON.parse(r['url_attributes']);
                        const query_attr = JSON.parse(r['query_attributes']);
                        const response_attr = JSON.parse(r['response_attributes']);
                        const url_parameters = enrich_master['url_parameters'].split(',');
                        const query_parameters = enrich_master['query_parameters'].split(',');
                        const response_parameters = enrich_master['response_parameters'].split(',');
                        const url_attributes = url_parameters.reduce((acc, key) => {
                            if (key.trim() !== '') {
                                acc[key] = url_attr[key] || '';
                            }
                            return acc;
                        }, {});
                        const query_attributes = query_parameters.reduce((acc, key) => {
                            if (key.trim() !== '') {
                                if (query_attr[key]) {
                                    acc[key] = query_attr[key].split(',').filter((x) => x.trim() !== '');
                                } else {
                                    acc[key] = [];
                                }
                            }
                            return acc;
                        }, {});
                        const response_attributes = response_parameters.reduce((acc, key) => {
                            if (key.trim() !== '') {
                                acc[key] = response_attr[key] || '';
                            }
                            return acc;
                        }, {});
                        const deduplicate_attributes = r['deduplicate_attributes'].split(",").filter((x) => x.trim() !== '');
                        return ({
                            ...r,
                            url_attributes,
                            query_attributes,
                            response_attributes,
                            deduplicate_attributes,
                            name: enrich_master.name,
                            enrichment_id: r.enrichment
                        });
                    });
                    setEnrichmentList(response);
                    /*
                     * if (response.length === 0) {
                     *     onEnrichmentAdd();
                     * }
                     */
                }
            });
        });
    }, [datasetId, datasourceId, dispatch]);

    const objWithEmptyVal = (arr) => {
        return arr.split(',').reduce((obj, item) => {
            if (item.trim() !== '') {
                obj[item] = "";
            }
            return obj;
        }, {});
    };

    const objWithEmptyArray = (arr) => {
        return arr.split(',').reduce((obj, item) => {
            if (item.trim() !== '') {
                obj[item] = [];
            }
            return obj;
        }, {});
    };

    const onEnrichmentChange = (index, value) => {
        let modified_row = enrichmentList[index];
        const enrichment = enrichments.filter((item) => item.name === value)[0];
        const url_attributes = objWithEmptyVal(enrichment['url_parameters']);
        const query_attributes = objWithEmptyArray(enrichment['query_parameters']);
        const response_attributes = objWithEmptyVal(enrichment['response_parameters']);

        modified_row = Object.assign(modified_row, {
            enrichment_id: enrichment.id,
            name: enrichment.name,
            url_attributes,
            query_attributes,
            response_attributes
        });

        enrichmentList[index] = modified_row;
        setEnrichmentList([...enrichmentList]);
    };


    const onEnrichmentDelete = (enrichment) => {
        if (enrichment.name) {
            setSelectedEnrichment(enrichment);
        } else {
            deleteEnrichmentList(enrichment);
        }
    };

    const deleteEnrichmentList = (selectedEnrichment) => {
        const index = enrichmentList.findIndex((enrichment) => enrichment.name === selectedEnrichment.name);
        if (index <= -1) {
            return;
        }
        enrichmentList.splice(index, 1);
        setSelectedEnrichment(null);
        setEnrichmentList([...enrichmentList]);
        if (selectedEnrichment.id) {
            dispatch(deleteDatasetEnrichment(selectedEnrichment.id));
        }
    };

    const onUrlAttributeChange = (param, index, value) => {
        enrichmentList[index]['url_attributes'][param] = value;
        setEnrichmentList([...enrichmentList]);
    };

    const onQueryAttributeChange = (param, index, value) => {
        enrichmentList[index]['query_attributes'][param] = value;
        setEnrichmentList([...enrichmentList]);
    };

    const onResponseAttributeChange = (param, index, value) => {
        enrichmentList[index]['response_attributes'][param] = value;
        setEnrichmentList([...enrichmentList]);
    };

    const onDeduplicateAttributeChange = (index, value) => {
        enrichmentList[index]['deduplicate_attributes'] = value;
        setEnrichmentList([...enrichmentList]);
    };

    const onSave = () => {
        enrichmentList.map((x) => {
            const query_attributes = {};
            for (const item in x['query_attributes']) {
                if (x['query_attributes'][item]) {
                    query_attributes[item] = x['query_attributes'][item].join();
                }
            }

            const obj = {
                ...x, ...{
                    'url_attributes': JSON.stringify(x['url_attributes']),
                    'query_attributes': JSON.stringify(query_attributes),
                    'response_attributes': JSON.stringify(x['response_attributes']),
                    'deduplicate_attributes': x['deduplicate_attributes'].join()
                }
            };

            dispatch(obj.id > 0 ? updateDatasetEnrichment(obj, obj.id) : createDatasetEnrichment(obj)).then((response) => {
                onClose();
            });
            return x;
        });
    };

    return (
        <Popover
            open={open}
            anchorEl={anchorElement}
            onClose={() => onClose()}
            anchorOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }
            transformOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            }>
            <Grid className={classNames(classes.enrichmentContainer)} >
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Typography component="h5" variant="h5">
                        {'Dataset Enrichment'}
                    </Typography>
                    <TooltipComponent title="Add Enrichment" arrow>
                        <IconButton onClick={() => onEnrichmentAdd()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                    <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                        <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                        <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                    </g>
                                </g>
                            </svg>
                        </IconButton>
                    </TooltipComponent>
                </Grid>
                <ValidatorForm autoComplete="off" onSubmit={() => onSave()}>
                    <Grid container direction="row" justify="space-between" alignItems="center">
                        <TableContainer className={classNames(classes.enrichmentTable)} >
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Typography>
                                                {'Enrichment'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                {'Request Attributes'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                {'Response Attributes'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                {'Actions'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        enrichmentList.map((enrichment, index) =>
                                            <TableRow key={`row_${index}`} className={classNames(classes.enrichmentTabelRow)} >
                                                <TableCell className={classNames(classes.enrichmentTableCell)} >
                                                    <Grid container direction="column" justify="center" alignItems="flex-start">
                                                        <AutoCompleteInput
                                                            name="Name"
                                                            options={enrichmentNames}
                                                            value={enrichment.name}
                                                            renderInput={
                                                                (params) =>
                                                                    <TextField {...params}
                                                                        className={classNames(classes.autoCompleteInput)}
                                                                        label="Name"
                                                                        fullWidth
                                                                    />
                                                            }
                                                            onChange={(_, selectedValue) => onEnrichmentChange(index, selectedValue)}
                                                            fullWidth
                                                            openOnFocus
                                                        />
                                                    </Grid>
                                                </TableCell>
                                                <TableCell className={classNames(classes.enrichmentTableCell)}>
                                                    <Grid container direction="column" justify="center" alignItems="flex-start" className={classNames(classes.enrichmentListAlign)}>
                                                        {
                                                            (enrichment['enrichment_id'] === 1) &&
                                                            <AutoCompleteInput
                                                                name="deduplicateAttributes"
                                                                options={datasetAttributeNames}
                                                                value={enrichment['deduplicate_attributes']}
                                                                renderInput={
                                                                    (params) =>
                                                                        <TextField {...params}
                                                                            className={classNames(classes.autoCompleteInput)}
                                                                            fullWidth
                                                                        />
                                                                }
                                                                onChange={(_, selectedValue) => onDeduplicateAttributeChange(index, selectedValue)}
                                                                fullWidth
                                                                multiple
                                                                openOnFocus
                                                                filterSelectedOptions
                                                            />
                                                        }
                                                        {
                                                            Object.keys(enrichment['url_attributes']).map((param, req_att_index) =>
                                                                <AutoCompleteInput
                                                                    key={`row_${req_att_index}`}
                                                                    name={param}
                                                                    options={datasetAttributeNames}
                                                                    freeSolo
                                                                    value={enrichment['url_attributes'][param]}
                                                                    renderInput={
                                                                        (params) =>
                                                                            <TextField {...params}
                                                                                className={classNames(classes.autoCompleteInput)}
                                                                                label={param}
                                                                                fullWidth
                                                                            />
                                                                    }
                                                                    onChange={(_, selectedValue) => onUrlAttributeChange(param, index, selectedValue)}
                                                                    onInputChange={(_, selectedValue) => onUrlAttributeChange(param, index, selectedValue)}
                                                                    fullWidth
                                                                    openOnFocus
                                                                />
                                                            )
                                                        }
                                                        {
                                                            Object.keys(enrichment['query_attributes']).map((param, req_att_index) =>
                                                                <AutoCompleteInput
                                                                    key={`row_${req_att_index}`}
                                                                    name={param}
                                                                    options={datasetAttributeNames}
                                                                    freeSolo
                                                                    value={enrichment['query_attributes'][param]}
                                                                    renderInput={
                                                                        (params) =>
                                                                            <TextField {...params}
                                                                                className={classNames(classes.autoCompleteInput)}
                                                                                label={param}
                                                                                fullWidth
                                                                            />
                                                                    }
                                                                    onChange={(_, selectedValue) => onQueryAttributeChange(param, index, selectedValue)}
                                                                    // onInputChange={(_, selectedValue) => onQueryAttributeChange(param, index, selectedValue)}
                                                                    fullWidth
                                                                    multiple
                                                                    openOnFocus
                                                                    filterSelectedOptions
                                                                />
                                                            )
                                                        }
                                                    </Grid>
                                                </TableCell>
                                                <TableCell className={classNames(classes.enrichmentTableCell)}>
                                                    <Grid container direction="column" justify="center" alignItems="flex-start">
                                                        {
                                                            Object.keys(enrichment['response_attributes']).map((param, res_att_index) =>
                                                                <AutoCompleteInput
                                                                    key={`row_${res_att_index}`}
                                                                    name={param}
                                                                    options={datasetAttributeNames}
                                                                    freeSolo
                                                                    value={enrichment['response_attributes'][param]}
                                                                    renderInput={
                                                                        (params) =>
                                                                            <TextField {...params}
                                                                                className={classNames(classes.autoCompleteInput)}
                                                                                label={param}
                                                                                fullWidth
                                                                            />
                                                                    }
                                                                    onChange={(_, selectedValue) => onResponseAttributeChange(param, index, selectedValue)}
                                                                    onInputChange={(_, selectedValue) => onResponseAttributeChange(param, index, selectedValue)}
                                                                    fullWidth
                                                                    openOnFocus
                                                                />
                                                            )
                                                        }
                                                    </Grid>
                                                </TableCell>
                                                <TableCell className={classNames(classes.enrichmentTableCell)}>
                                                    <TooltipComponent title="Delete Enrichment" arrow>
                                                        <IconButton className={classNames(classes.padding10, classes.deleteDataset)} onClick={() => onEnrichmentDelete(enrichment)}>
                                                            <svg version="1.1"
                                                                id="Layer_1"
                                                                width="21"
                                                                height="21"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                x="0px"
                                                                y="0px"
                                                                viewBox="0 0 32 32">
                                                                <g id="Delete">
                                                                    <path fill={theme.palette.primary.main}
                                                                        stroke={theme.palette.primary.main}
                                                                        strokeWidth="0.92"
                                                                        strokeMiterlimit="10"
                                                                        d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
                                    L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
                                    L22.3,25.8z" />
                                                                    <path fill={theme.palette.primary.main}
                                                                        stroke={theme.palette.primary.main}
                                                                        strokeWidth="0.92"
                                                                        strokeMiterlimit="10"
                                                                        d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
                                    c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
                                    c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                                </g>
                                                            </svg>
                                                        </IconButton>
                                                    </TooltipComponent>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {
                        enrichments.length === 0 && !isLoading &&
                        <NoResultFound height="80%" />
                    }

                    <Grid container direction="row" justify="flex-end" alignItems="flex-end" className={classes.marginTop10}>
                        {
                            enrichmentList.length > 0 &&
                            <Button className={classes.tabBtn} type="submit" variant="contained" color="primary">
                                Save
                            </Button>
                        }
                        <Button onClick={() => onClose()} style={{ marginLeft: '10px' }} variant="contained" className={classNames(classes.cancelBtn, classes.tabBtn)}>
                            Cancel
                        </Button>
                    </Grid>
                </ValidatorForm>
            </Grid>

            {isLoading && <Loader />}
            <AlertDialog title="Delete Enrichment"
                message={`Are you sure you want to delete the enrichment - ${selectedEnrichment ? selectedEnrichment.name : ''}?`}
                okButtonText="OK"
                cancelButtonText="Cancel"
                show={Boolean(selectedEnrichment)}
                onClickOK={() => deleteEnrichmentList(selectedEnrichment)}
                onClickCancel={() => setSelectedEnrichment(null)} />
        </Popover>
    );
};

Enrichment.propTypes = {
    classes: PropTypes.object,
    enrichmentProps: PropTypes.object,
    onClose: PropTypes.func
};

export default withStyles((theme) => ({
    ...EnrichmentStyles(theme),
    ...Styles(theme)
}))(Enrichment);