import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import MentionStyles from './MentionCommentStyles.jsx';
import { MentionsInput, Mention } from 'react-mentions';
import classnames from 'classnames';

const MentionComment = (props) => {
    const { classes, comment, onChangeComment, users, getAssignUsers, placeholder } = props;

    const handleChange = useCallback((event, newValue, newPlainTextValue, mention) => {
        onChangeComment(newValue);
        if (mention && mention.length) {
            getAssignUsers(mention.map((user) => { return { "user": user.id, "is_owner": false }; }));
        }
    }, [getAssignUsers, onChangeComment]);

    return (
        <Grid className={classnames(classes.mentionContainer)}>
            <Grid className={classnames(classes.marginBottom15, classes.commentBorder)}>
                <MentionsInput
                    value={comment}
                    onChange={handleChange}
                    markup="@{{__user__||__id__||__display__}}"
                    placeholder={placeholder}
                    className="mentions"
                >
                    <Mention
                        type="user"
                        trigger="@"
                        data={users}
                        className="mentions__mention"
                    />
                </MentionsInput>
            </Grid>
        </Grid>
    );

};

MentionComment.propTypes = {
    classes: PropTypes.object,
    comment: PropTypes.string,
    onChangeComment: PropTypes.func,
    users: PropTypes.array,
    getAssignUsers: PropTypes.func,
    placeholder: PropTypes.string
};

export default withStyles((theme) => ({
    ...MentionStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MentionComment);