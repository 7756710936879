import { alertActions } from '../constants/actionTypes/alertActionTypes';

export const clearAlert = () => {
	return {
		type: alertActions.CLEAR_ALERT
	};
};

export const ssoAlert = (userMessage) => {
	return {
		type: alertActions.SSO_ALERT,
		responseData: userMessage
	};
};

export const showAlert = (userMessage) => {
	return {
		type: alertActions.ALERT_ERROR,
		error: userMessage
	};
};

export const successAlert = (userMessage) => {
	return {
		type: alertActions.ALERT_SUCCESS,
		responseData: userMessage
	};
};