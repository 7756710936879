import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import * as apiService from '../services/apiService';
import { metricsActions } from '../constants/actionTypes/metricsActionTypes';


export const getTotalCount = () => {
    return (dispatch) => {
        dispatch(request(metricsActions.GET_TOTAL_COUNT_REQUEST));
        return apiService.getRequest(appConstants.END_POINTS.metrics.getTotalCount).then((response) => {
            dispatch(success(metricsActions.GET_TOTAL_COUNT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(metricsActions.GET_TOTAL_COUNT_FAILURE, error));
        });
    };
};

export const getFilterDatasourceList = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.metrics.getDatasourceList}`;
    return (dispatch) => {
        dispatch(request(metricsActions.GET_FILTERDATASOURCE_LIST_REQUEST));
        return apiService.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(metricsActions.GET_FILTERDATASOURCE_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(metricsActions.GET_FILTERDATASOURCE_LIST_FAILURE, error));
        });
    };
};

export const getFilterDatasetList = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.metrics.getDatasetList}`;
    return (dispatch) => {
        dispatch(request(metricsActions.GET_FILTERDATASET_LIST_REQUEST));
        return apiService.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(metricsActions.GET_FILTERDATASET_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(metricsActions.GET_FILTERDATASET_LIST_FAILURE, error));
        });
    };
};

export const getFilterAttributeList = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.metrics.getAttributeList}`;
    return (dispatch) => {
        dispatch(request(metricsActions.GET_FILTERATTRIBUTE_LIST_REQUEST));
        return apiService.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(metricsActions.GET_FILTERATTRIBUTE_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(metricsActions.GET_FILTERATTRIBUTE_LIST_FAILURE, error));
        });
    };
};

export const getDatasourceList = (requestParams, cancellationToken = null) => {
    const endpoint = `${appConstants.END_POINTS.metrics.getDatasourceList}`;
    return (dispatch) => {
        dispatch(request(metricsActions.GET_DATASOURCE_LIST_REQUEST));
        return apiService.postRequest(endpoint, requestParams, false, cancellationToken).then((response) => {
            dispatch(success(metricsActions.GET_DATASOURCE_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(metricsActions.GET_DATASOURCE_LIST_FAILURE, error));
        });
    };
};

export const getDatasetList = (requestParams, cancellationToken = null) => {
    const endpoint = `${appConstants.END_POINTS.metrics.getDatasetList}`;
    return (dispatch) => {
        dispatch(request(metricsActions.GET_DATASET_LIST_REQUEST));
        return apiService.postRequest(endpoint, requestParams, false, cancellationToken).then((response) => {
            dispatch(success(metricsActions.GET_DATASET_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(metricsActions.GET_DATASET_LIST_FAILURE, error));
        });
    };
};

export const getAttributeList = (requestParams, cancellationToken = null) => {
    const endpoint = `${appConstants.END_POINTS.metrics.getAttributeList}`;
    return (dispatch) => {
        dispatch(request(metricsActions.GET_ATTRIBUTE_LIST_REQUEST));
        return apiService.postRequest(endpoint, requestParams, false, cancellationToken).then((response) => {
            dispatch(success(metricsActions.GET_ATTRIBUTE_LIST_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(metricsActions.GET_ATTRIBUTE_LIST_FAILURE, error));
        });
    };
};

export const getProfileRules = (requestParams, cancellationToken = null) => {
    const endpoint = `${appConstants.END_POINTS.metrics.getProfileRules}`;
    return (dispatch) => {
        dispatch(request(metricsActions.GET_PROFILE_RULES_REQUEST));
        return apiService.postRequest(endpoint, requestParams, false, cancellationToken).then((response) => {
            dispatch(success(metricsActions.GET_PROFILE_RULES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(metricsActions.GET_PROFILE_RULES_FAILURE, error));
        });
    };
};

export const getDqscoreRules = (requestParams, cancellationToken = null) => {
    const endpoint = `${appConstants.END_POINTS.metrics.getDqscoreRules}`;
    return (dispatch) => {
        dispatch(request(metricsActions.GET_DQSCORE_RULES_REQUEST));
        return apiService.postRequest(endpoint, requestParams, false, cancellationToken).then((response) => {
            dispatch(success(metricsActions.GET_DQSCORE_RULES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(metricsActions.GET_DQSCORE_RULES_FAILURE, error));
        });
    };
};

export const getMetrics = (requestParams) => {
    return (dispatch) => {
        dispatch(request(metricsActions.GET_METRIC_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.metrics.getMetrics, requestParams).then((response) => {
            dispatch(success(metricsActions.GET_METRIC_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(metricsActions.GET_METRIC_FAILURE, error));
        });
    };
};

export const getPreviewDataByCategory = (requestParams) => {
    return (dispatch) => {
        dispatch(request(metricsActions.GET_PREVIEW_DATA_CATEGORY_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.metrics.getPreviewDataByCategory, requestParams).then((response) => {
            dispatch(success(metricsActions.GET_PREVIEW_DATA_CATEGORY_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(metricsActions.GET_PREVIEW_DATA_CATEGORY_FAILURE, error));
        });
    };
};

export const getExportDataByCategory = (requestParams) => {
    return (dispatch) => {
        dispatch(request(metricsActions.GET_EXPORT_DATA_CATEGORY_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.metrics.getExportDataByCategory, requestParams).then((response) => {
            dispatch(success(metricsActions.GET_EXPORT_DATA_CATEGORY_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(metricsActions.GET_EXPORT_DATA_CATEGORY_FAILURE, error));
        });
    };
};


export const setMetricChartData = (metricData) => {
    return {
        type: metricsActions.SET_METRIC_DATA_SUCCESS,
        metricData
    };
};

export const clearWidget = () => {
    return {
        type: metricsActions.CLEAR_WIDGET
    };
};

export const setSearchKey = (searchType, key) => {
    return {
        type: metricsActions.SET_SEARCH_KEY,
        searchType,
        key
    };
};