import React, { useEffect, useState, useCallback } from 'react';
import { Grid, withStyles, Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import ChartStyles from './ChartStyles.jsx';
import { createRule, getFieldType, getDefaultOperator, getOperators } from '../RuleBuilder/QueryBuilderUtil.jsx';
import { appConstants } from '../../constants/appConstants';
import { useSelector } from 'react-redux';

const HistogramChart = (props) => {
    const { classes, attribute, profileData, theme, tabIndex, profileRuleEnable, isActive, onFilter, attributeChange } = props;
    const datasource = useSelector(({ datasource }) => datasource.datasource);
    const [chartData, setChartData] = useState({});
    const [menuType, setMenuType] = useState(appConstants.histogramBins[0]);
    const [selectedRange, setSelectedRange] = useState(0);
    const [edited, setEdited] = useState(false);

    const loadChartData = useCallback((data) => {
        if (data.input_data) {
            data.data = data.input_data.map((d) => d[attribute.name]);
        }
        setChartData({ ...data });
    }, [attribute.name]);

    useEffect(() => {
        if (attributeChange) {
            const chartData = profileData.Histogram;
            loadChartData({ ...chartData });
        }

    }, [attribute, attributeChange, loadChartData, profileData]);


    const onChangeMenu = (value, index) => {
        setEdited(true);
        setMenuType(value);
    };

    const discardEdit = useCallback(() => {
        setEdited(false);
    }, []);

    const onFilterChange = useCallback((selectedValue) => {
        const inputParams = {
            chartType: appConstants.charts.histogram.type,
            chartName: menuType,
            filterOptions: false,
            defaultQuery: ''
        };

        if (selectedValue) {
            inputParams.data = selectedValue;
            const ruleParams = {
                defaultField: attribute.name,
                defaultFieldType: getFieldType(attribute.type),
                attributeType: attribute.type,
                connectionType: datasource?.type,
                isScan: datasource?.scan
            };
            const rule = createRule(ruleParams);
            let operator = getDefaultOperator(rule.fieldType);
            if (selectedValue.value.operator) {
                const operators = getOperators(rule.fieldType);
                operator = operators.find((p) => p.label.toLowerCase() === selectedValue.value.operator.toLowerCase());
            }
            rule.operator = { ...operator };
            if (selectedValue.value.operator) {
                rule.value = [selectedValue.value.min, selectedValue.value.max];
            } else {
                rule.value = selectedValue.value;
            }
            inputParams.rule = rule;
            inputParams.filterType = selectedValue.filterType;
            inputParams.datatype = attribute.type ? attribute.type : 'text';
        }

        onFilter(inputParams);
    }, [menuType, onFilter, attribute.name, attribute.type, datasource?.type, datasource?.scan]);

    const onBinSelected = useCallback((range) => {
        setEdited(true);
        setSelectedRange([...range]);
    }, []);

    useEffect(() => {
        if (chartData && Object.keys(chartData).length !== 0 && (attributeChange || edited)) {
            if (edited) {
                discardEdit();
            }
            let inputData = chartData.data;
            const range = selectedRange;
            if (range.length > 0) {
                inputData = inputData.filter((value) => (value >= range[0] && value <= range[1]));
            }

            const histogramData = [];
            for (const value of inputData) {
                histogramData.push({ value: parseFloat(value) });
            }
            d3.select('.histogram-chart > *').remove();
            const binCount = parseInt(menuType.toString().replace(' bins', ''));
            const formatValue = d3.format(".2s");
            const margin = { top: 40, right: 20, bottom: 50, left: 40 };
            let width = 960 - margin.left - margin.right;
            const height = 300;

            const barOpacity = 0.5;
            const barHighlightOpacity = 0.75;

            d3.select(".histogram-chart > *").remove();

            const svg = d3.select(".histogram-chart").append("svg")
                .attr("width", "100%")
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("width", (_, i, nodes) => {
                    width = nodes[i].parentNode.clientWidth - 160;
                    return width;
                })
                .attr("height", height + margin.top + margin.bottom)
                .attr("transform",
                    "translate(" + (margin.left + 30) + "," + (margin.top / 2) + ")");

            // X axis: scale and draw:
            let minX = d3.min(histogramData, (d) => { return d.value; });
            let maxX = d3.max(histogramData, (d) => { return d.value; });
            if (selectedRange && selectedRange.length === 2) {
                minX = selectedRange[0];
                maxX = selectedRange[1];
            }
            const x = d3.scaleLinear()
                .domain([minX, maxX + (maxX * 0.03)])
                .range([0, width]).nice();
            svg.append("g")
                .attr("color", theme.palette.chartColors.axis)
                .attr("class", "axis-x")
                .attr("transform", "translate(0," + height + ")")
                .transition()
                .duration(2000)
                .call(d3.axisBottom(x).ticks(10).tickFormat((d) => { return formatValue(d); }));

            // set the parameters for the histogram
            const histogram = d3.histogram(binCount)
                .value((d) => { return d.value; })
                .domain(x.domain())
                .thresholds(x.ticks(binCount));

            // And apply this function to data to get the bins
            const bins = histogram(histogramData);
            // Y axis: scale and draw:
            const maxY = d3.max(bins, (d) => { return d.length; });
            const y = d3.scaleLinear()
                .range([height, 0]);
            y.domain([0, (maxY + maxY * 0.03)]);
            svg.append("g")
                .attr("color", theme.palette.chartColors.axis)
                .attr("class", "axis-y")
                .transition()
                .duration(2000)
                .call(d3.axisLeft(y).ticks(10));

            // text label for the y axis
            svg.append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - (margin.left + 20))
                .attr("x", 0 - (height / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text("Count");

            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
            svg.selectAll('.axis-y .tick-text').text('').append('tspan').text((d) => { return d; });

            const div = d3.select(".histogram-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0);
            svg.selectAll("rect")
                .data(bins)
                .enter()
                .append("rect")
                .attr("x", 1)
                .attr("transform", (d) => {
                    let yPosition = y(d.length);
                    const h = height - y(d.length);
                    if (d.length > 0 && h < 1) {
                        yPosition -= 2;
                    }
                    return "translate(" + x(d.x0) + "," + yPosition + ")";
                })
                .attr("width", (d) => { return x(d.x1) - x(d.x0); })
                .style("fill", "#87ceeb")
                .style("opacity", barOpacity)
                .on('mouseover', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", barHighlightOpacity);
                    const pos = nodes[i].attributes[1].textContent
                        .replace('translate(', '').replace(')', '')
                        .split(',');
                    // Show Tooltip
                    const rect = nodes[i].getBoundingClientRect();
                    const percentage = parseFloat((d.length / chartData.data.length) * 100);
                    let values = '';
                    if (d.length <= 5) {
                        const inputValues = [];
                        for (const value of d) {
                            inputValues.push(value.value);
                        }
                        values = inputValues.join(',');
                    } else { values = [...new Set(d.map((item) => item.value))].join(','); }
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html(`Range : ${d.x0} - ${d.x1} <br/> Count : ${d.length} <br/> Percentage : ${percentage.toFixed(2)}%${values ? ` <br/> Values : ${values}` : ''}`)
                        .style("left", parseFloat(pos[0]) + (rect.width / 2) + "px")
                        .style("top", parseFloat(pos[1]) - 70 + "px");
                })
                .on('mouseout', (_, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", barOpacity);

                    // Hide Tooltip
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .on("click", (d) => {
                    d.length === 1 ? onFilterChange({ value: d[0].value, filterType: appConstants.QueryFilterTypes[0] }) : onBinSelected([d.x0, d.x1]);
                })
                .attr("height", 0)
                .attr("y", (d) => { return height - y(d.length); })
                .transition()
                .duration(500)
                .delay((d, i) => { return i * 20; })
                .attr("y", (d) => { return 0; })
                .attr("height", (d, i) => {
                    let barHeight = height - y(d.length);
                    if (d.length > 0 && barHeight < 1) {
                        barHeight = 4;
                    }
                    return barHeight;
                });
            const legendX = (width / 2);
            const legendY = (height + margin.top) + 20;
            svg.append("rect")
                .attr("x", legendX)
                .attr("y", legendY - 20)
                .attr("width", 10)
                .attr("height", 10)
                .style("fill", "#87ceeb");
            svg.append("text")
                .attr("x", legendX + 20)
                .attr("y", legendY - 10)
                .text("Bins")
                .attr("class", classes.legendText);


        }
    }, [attribute.name, chartData, classes.legendText, menuType, selectedRange, tabIndex, theme.palette.chartColors.axis, onFilterChange, onBinSelected, onFilter, attributeChange, edited, discardEdit]);

    const onRuleChange = (value) => {
        profileRuleEnable(appConstants.charts.histogram.type, value);
    };

    return (
        <ChartContainer
            menuProperties={
                [
                    {
                        options: appConstants.histogramBins,
                        onChange: onChangeMenu,
                        value: menuType
                    }
                ]
            }
            filterProperties={
                {
                    enableFilter: false,
                    onFilter: onFilterChange
                }
            }
            ruleProperties={
                {
                    edit: true,
                    onRule: onRuleChange,
                    rule: isActive
                }
            }
            id={appConstants.charts.histogram.id}
            title={appConstants.charts.histogram.name}
            chartData={chartData}>
            <Grid container className={classNames(classes.chartStyles, "histogram-chart")} />
            {
                (selectedRange && selectedRange.length > 0) ?
                    <Chip
                        className={classes.rangeChip}
                        label={`${selectedRange[0]} - ${selectedRange[1]}`}
                        onDelete={() => onBinSelected([])}
                        deleteIcon={
                            <svg xmlns="http://www.w3.org/2000/svg" width="6px" height="6px" viewBox="0 0 8.029 8.029">
                                <g id="close_1_" data-name="close (1)" transform="translate(0 -0.001)">
                                    <path id="Path_120" data-name="Path 120" d="M4.725,4.015,7.882.858a.5.5,0,1,0-.71-.71L4.015,3.305.857.148a.5.5,0,0,0-.71.71L3.3,4.015.147,7.173a.5.5,0,1,0,.71.71L4.015,4.725,7.172,7.883a.5.5,0,1,0,.71-.71Z" transform="translate(0 0)" fill="#9eaeb4" />
                                </g>
                            </svg>
                        }
                    /> :
                    null
            }
        </ChartContainer >
    );
};

HistogramChart.propTypes = {
    classes: PropTypes.object,
    profileData: PropTypes.object,
    theme: PropTypes.object,
    attribute: PropTypes.object,
    tabIndex: PropTypes.number,
    profileRuleEnable: PropTypes.func,
    isActive: PropTypes.bool,
    onFilter: PropTypes.func,
    attributeChange: PropTypes.bool
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(HistogramChart);