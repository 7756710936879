import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { driftDatasetDashboard } from '../../actions/scheduleActions';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import { appConstants } from '../../constants/appConstants';
import PieChart from '../Charts/PieCharts.jsx';
import StockerBarChart from '../Charts/StockedBarChart.jsx';
import DriftDashBoardStyle from './DriftDashBoardStyle.jsx';
import { Grid, withStyles } from '@material-ui/core';

const DriftDatasetDashboard = (props) => {
    const { datasetId } = props;
    const [analysisData, setAnalysisData] = useState({});
    const [trendData, setTrendData] = useState([]);

    const dispatch = useDispatch();

    const getDashboardStatistics = useCallback(() => {
        dispatch(driftDatasetDashboard(datasetId)).then((response) => {
            if (response) {
                setAnalysisData({ ...response.data });
                setTrendData([...response.stocked_bar_data]);
            }
        });
    }, [datasetId, dispatch]);

    useEffect(() => {
        getDashboardStatistics();
    }, [getDashboardStatistics]);


    return (
        <ChartContainer
            title={appConstants.charts.driftDashBoardStatistics.name}
            id={appConstants.charts.driftDashBoardStatistics.id}
            chartData={analysisData}
        >
            <Grid container spacing={3}>
                <Grid item xs={7} md={7}>
                     <StockerBarChart
                        chartData = {trendData}
                        chartClassName="drift-stocked-chart"
                        colorCode={["#081d58", "#045281", "#225ea8", "#1d91c0"]}
                        listName={["Duplicate", "Count", "Schema", "Correlation"]}
                    />
                </Grid>
                <Grid item xs={5} md={5}>
                    <PieChart
                        chartData={analysisData}
                        chartClassName="drift-pie-chart"
                    />
                </Grid>
            </Grid>
        </ChartContainer>
    );
};

DriftDatasetDashboard.propTypes = {
    datasetId: PropTypes.number
};

export default withStyles((theme) => ({
    ...DriftDashBoardStyle(theme),
    ...Styles(theme)
}), { withTheme: true })(DriftDatasetDashboard);