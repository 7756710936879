import React, { Fragment } from 'react';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import classnames from 'classnames';
import { Grid, withStyles } from '@material-ui/core';

import Header from '../components/Common/Header.jsx';
import Styles from './Styles.jsx';
import { logout } from '../actions/accountActions';
import SidePanel from '../components/SidePanel/SidePanel.jsx';
import ActionItem from '../components/ActionItem/ActionItem.jsx';


const PrivateLayout = (props) => {
	const { classes, history, notification, location } = props;
	let enableActionItem = false;
	const config = useSelector(({ setting }) => setting.config);
	if (location && location.pathname) {
		enableActionItem = ((location.pathname.includes('/catalog/')) || (location.pathname.includes('/dataset/')) || (location.pathname.includes('/domain/'))) && config.action_items;
	}

	return (
		<Fragment>
			<Grid container direction="row" id="main_container">
				<Grid item>
					<Header logout={props.logout} history={history} />
				</Grid>
				<Grid item className={`${"pr-0"} ${classnames(classes.rightComponent, classes.zIndex1)}`}>
					<Grid container className={classes.bodyContent}>
						<Grid item className={classnames(notification.sidePanel ? classes.sidePanelContainerActive : classes.sidePanelContainerInActive, classes.sidePanelContainer)}>
							<SidePanel history={history} />
						</Grid>
						<Grid item className={classnames(notification.sidePanel ? classes.rightPanelContainer : classes.rightPanelActiveContainer, classes.bodyContent)}>
							{props.children}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			{enableActionItem && <ActionItem history={history} />}
		</Fragment>
	);
};


PrivateLayout.propTypes = {
	classes: PropTypes.object,
	history: PropTypes.object,
	location: PropTypes.object,
	children: PropTypes.node,
	logout: PropTypes.func,
	notification: PropTypes.object
};

const mapStateToProps = (state) => {
	return {
		...state
	};
};

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		logout
	}, dispatch);
};

export default
	connect(mapStateToProps,
		mapDispatchToProps)(withStyles(Styles)(PrivateLayout));