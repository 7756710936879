import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { getOrganizationUser } from '../../actions/accountActions';
import { getCatalogListData, clearCatalogList, getAllDatasource, createSourceShare, getAllDatasets, getAllAttributes, getAllDomains, getSourceDatasets, updateDatasource, getReferences, updateDataset } from '../../actions/datasourceActions';
import { getPropertiesOrganization, getStorage, getUpdateLicenseConnectionStatus } from "../../actions/settingActions";
import { createDatasetShare } from '../../actions/datasetActions';
import { getMasterFieldTypes } from '../../actions/masterActions';
import DatasourceListFilter from '../../components/DatasourceList/DatasourceFilter.jsx';
import DatasourceList from '../../components/DatasourceList/DatasourceList.jsx';
import DatasourceDetails from '../../components/DatasourceList/DatasourceDetails.jsx';
import { appConstants } from '.././../constants/appConstants';
import { switchCatalog } from '../../actions/notificationActions';
import Styles from '../../layouts/Styles.jsx';
import SelectConnectors from '../../components/SelectConnectors/SelectConnectors.jsx';
import { setCurrentPage } from '../../helpers/apiHelpers';

class Datasource extends Component {
    constructor(props) {
        super(props);
        setCurrentPage('catalog');
        this.state = {
            isDatasourceView: true,
            initialFetch: 10,
            isPageLoading: true,
            loadPage: true,
            viewType: appConstants.filterTypes[0],
            selectedDatasource: {},
            breadcrumb: [
                {
                    name: 'Catalog',
                    path: '/catalog',
                    type: 'catalog'
                }
            ],
            openSelectDialog: false,
            sortBy: 'Relevant'
        };
        this.containerRef = React.createRef();
        this.sortOptions = ["Relevant", "Name A - Z", "Name Z - A", "Conversations - High to Low", "Views - High to Low", "Ratings - High to Low", "Ratings - Low to High"];

    }

    static getDerivedStateFromProps(props, state) {
        if (!state.isDatasourceView && props.catalogView) {
            setTimeout(() => { props.switchCatalog(false); }, 0);
            return {
                ...state,
                isDatasourceView: true
            };
        }
        if (props.catalog && props.catalog.filters && state.viewType !== props.catalog.filters.viewType) {
            let sortBy = state.sortBy;
            if (
                (props.catalog.filters.viewType === 'By Attribute' &&
                    (sortBy === "Conversations - High to Low" || sortBy === "Views - High to Low"))
            ) {
                sortBy = 'Relevant';
            }

            if (
                (props.catalog.filters.viewType === 'By Domain' &&
                    (sortBy === "Ratings - High to Low" || sortBy === "Ratings - Low to High"))
            ) {
                sortBy = 'Relevant';
            }
            return {
                ...state,
                viewType: props.catalog.filters.viewType,
                sortBy
            };
        }
        return { ...state };
    }

    componentDidMount() {
        this.getCatalogList();
        this.props.getMasterFieldTypes();
        this.props.getStorage(this.props.user.organization);
        const organizationId = this.props.systemConfiguration ? 0 : this.props.user.organization;
        this.props.getPropertiesOrganization(organizationId);
        this.getLicenseConnectionStatus();
        if (this.containerRef && this.containerRef.current) {
            this.containerRef.current.addEventListener("scroll", () => {
                if (
                    this.containerRef.current.scrollTop + this.containerRef.current.clientHeight + 55 >=
                    this.containerRef.current.scrollHeight && (!this.props.catalog.isLoading && this.state.loadPage && !this.state.isPageLoading)
                ) {
                    this.setState({ isPageLoading: true });
                    setTimeout(() => this.getCatalogList(), 100);
                }
            });
        }
        this.props.getPageTitle('Catalog');
        this.props.getReferences();
    }

    componentWillUnmount() {
        this.props.clearCatalogList();
    }

    getLicenseConnectionStatus = () => {
        this.props.getUpdateLicenseConnectionStatus().then((response) => {
            this.setState({ openSelectDialog: Boolean(!response) });
        });
    }

    getCatalogList = (shouldReset = false) => {
        const searchConfig = {
            ...this.props.catalog.filters,
            limit: this.state.initialFetch,
            sortBy: this.state.sortBy
        };
        const viewType = this.props.catalog.filters.viewType;
        if (shouldReset) {
            searchConfig.offset = 0;
        }
        else if (viewType === appConstants.filterTypes[0]) {
            const datasources = this.getViewData(viewType);
            searchConfig.offset = datasources.length;
        } else if (viewType === appConstants.filterTypes[1]) {
            const datasets = this.getViewData(viewType);
            searchConfig.offset = datasets.length;
        } else if (viewType === appConstants.filterTypes[2]) {
            const attributes = this.getViewData(viewType);
            searchConfig.offset = attributes.length;
        }
        else if (viewType === appConstants.filterTypes[3]) {
            const domains = this.getViewData(viewType);
            searchConfig.offset = domains.length;
        }
        this.props.getCatalogListData(searchConfig, shouldReset).then((response) => {
            this.setState({ isPageLoading: false });
            if (!response || (response && response.length === 0)) {
                this.setState({ loadPage: false });
            }
        });
    };

    getViewData(viewType) {
        if (viewType === appConstants.filterTypes[0]) {
            return [...this.props.catalog.datasources];
        } else if (viewType === appConstants.filterTypes[1]) {
            return [...this.props.catalog.datasets];
        } else if (viewType === appConstants.filterTypes[2]) {
            return [...this.props.catalog.attributes];
        }
        return [...this.props.catalog.domains];
    }

    requestAccess = (index) => {
        const userId = (this.props.user && this.props.user.id) ? this.props.user.id : 0;
        const userInfo = this.props.users.find((data) => userId === data.id);
        let access = 3;
        if (userInfo && userInfo.governance === "Level 2") {
            access = 2;
        } else if (userInfo && userInfo.governance === "Level 1") {
            access = 1;
        }
        const model = {
            "source_id": null,
            "dataset_id": null,
            "shareUserList": [
                {
                    "user_id": (this.props.user && this.props.user.id) ? this.props.user.id : 0,
                    "access_level": access,
                    "type": "datasource",
                    "is_stewarduser": false,
                    "is_owner": false,
                    "is_requested": true,
                    "is_request_accepted": false,
                    "is_decline": false,
                    "source_id": null,
                    "dataset_id": null
                }
            ]
        };
        if (this.state.viewType === appConstants.filterTypes[0]) {
            const datasources = [...this.props.catalog.datasources];
            datasources[index].isLoading = true;
            this.props.updateDatasource([...datasources]);
            model["source_id"] = datasources[index].id;
            model.shareUserList["source_id"] = datasources[index].id;
            this.props.createSourceShare(model).then(() => {
                datasources[index].isLoading = false;
                datasources[index]["sharing_info"].push({
                    "source_id": datasources[index].id,
                    "user_id": (this.props.user && this.props.user.id) ? this.props.user.id : 0,
                    "access_level": 1,
                    "is_requested": true,
                    "is_request_accepted": false,
                    "is_request_decline": false
                });
                this.props.updateDatasource([...datasources]);
            });
        } else if (this.state.viewType === appConstants.filterTypes[1]) {
            const datasets = [...this.props.catalog.datasets];
            datasets[index].isLoading = true;
            this.props.updateDataset([...datasets]);
            model["source_id"] = datasets[index].source_id;
            model["dataset_id"] = datasets[index].id;
            model.shareUserList["source_id"] = datasets[index].source_id;
            model.shareUserList["dataset_id"] = datasets[index].id;
            this.props.createDatasetShare(model).then(() => {
                datasets[index].isLoading = false;
                datasets[index]["sharing_info"].push({
                    "source_id": datasets[index].id,
                    "dataset_id": datasets[index]["source_id"],
                    "user_id": (this.props.user && this.props.user.id) ? this.props.user.id : 0,
                    "access_level": 1,
                    "is_requested": true,
                    "is_request_accepted": false,
                    "is_request_decline": false
                });
                this.props.updateDataset([...datasets]);
            });
        }
    }

    redirectDetail = (type, data) => {
        const { breadcrumb } = this.state;
        if (type === "detail") {
            breadcrumb.push({
                name: data.name ? data.name : 'Untitled Data Source',
                path: '',
                type: 'datasource'
            });
            this.setState({ isDatasourceView: false, selectedDatasource: data, breadcrumb });
        } else {
            breadcrumb.pop();
            this.setState({ isDatasourceView: true, breadcrumb });
        }
    }


    responseRequest = (type, model) => {
        if (type === appConstants.filterTypes[0]) {
            const datasources = [...this.props.catalog.datasources];
            const index = datasources.findIndex((datasource) => datasource.id === model.source_id);
            datasources[index].sharing_info = datasources[index].sharing_info.map((user) => {
                const userInfo = { ...user };
                if (userInfo.user_id === model.shareUserList[0].user_id) {
                    userInfo.is_request_accepted = model.shareUserList[0].is_request_accepted;
                    userInfo.is_decline = model.shareUserList[0].is_decline;
                    userInfo.is_higher_request_accepted = model.shareUserList[0].is_higher_request_accepted;
                    userInfo.is_higher_request_decline = model.shareUserList[0].is_higher_request_decline;
                }
                return userInfo;
            });
            if (model.shareUserList[0].is_higher_requested) {
                datasources[index].datasets = datasources[index].datasets.map((dataset) => {
                    const datasetInfo = { ...dataset };
                    const updatedModel = { ...model };
                    updatedModel.dataset_id = dataset.id;
                    datasetInfo.sharing_info.map((userInfo, index_usr) => {
                        if (userInfo.user_id === model.shareUserList[0].user_id && model.shareUserList[0].is_higher_request_accepted) {
                            datasetInfo.sharing_info[index_usr].is_higher_request_accepted = true;
                            this.props.createDatasetShare(updatedModel);
                        }
                        return userInfo;
                    });
                    return datasetInfo;
                });
            }
            this.props.updateDatasource([...datasources]);
            this.props.createSourceShare(model);
            const filtered_source = datasources.filter((src) => { return src.id === model.source_id; });
            filtered_source.forEach((f_source, index_in) => {
                const { datasets } = f_source;
                datasets.forEach((dtst, index_inner) => {
                    dtst.sharing_info = dtst.sharing_info.map((user) => {
                        const userInfo = { ...user };
                        if (userInfo.user_id === model.shareUserList[0].user_id && model.shareUserList[0].is_request_accepted) {
                            userInfo.is_request_accepted = model.shareUserList[0].is_request_accepted;
                        }
                        return userInfo;
                    });
                    this.props.updateDataset([...datasets]);

                });
            });
        } else {
            const datasources = [...this.props.catalog.datasources];
            datasources.forEach((source, index_in) => {
                const { datasets } = source;
                const index = datasets && datasets.length > 0 && datasets.findIndex((dataset) => dataset.id === model.dataset_id);
                if (index !== null && index >= 0) {
                    datasets[index].sharing_info = datasets[index].sharing_info.map((user) => {
                        const userInfo = { ...user };
                        if (userInfo.user_id === model.shareUserList[0].user_id) {
                            userInfo.is_request_accepted = model.shareUserList[0].is_request_accepted;
                            userInfo.is_decline = model.shareUserList[0].is_decline;
                            userInfo.is_higher_request_accepted = model.shareUserList[0].is_higher_request_accepted;
                            userInfo.is_higher_request_decline = model.shareUserList[0].is_higher_request_decline;
                        }
                        return userInfo;
                    });
                    this.props.createDatasetShare(model);
                    this.props.updateDataset([...datasets]);
                }
            });
        }
    }

    manageImportData = (newSource) => {
        newSource['sharing_info'] = newSource.sharing_info.map((sharing) => {
            sharing.name = sharing.user__first_name !== "" ? `${sharing.user__first_name} ${sharing.user__last_name}` : sharing.user__email;
            sharing.avatar = sharing.user__avatar;
            return sharing;
        });
        this.setState({ datasources: [newSource, ...this.state.datasources] });
    }

    setConnectionUpdated = () => {
        this.setState({ openSelectDialog: false });
    }

    onSortingChange = (value) => {
        this.setState({ sortBy: value }, () => {
            this.props.clearCatalogList();
            this.getCatalogList(true);
        });
    };

    getSortOptions = () => {
        let filteredOptions = this.sortOptions;
        if (this.state.viewType === "By Attribute") {
            filteredOptions = this.sortOptions.filter((option) => option !== "Conversations - High to Low" && option !== "Views - High to Low");
        }
        if (this.state.viewType === "By Domain") {
            filteredOptions = this.sortOptions.filter((option) => option !== "Ratings - High to Low" && option !== "Ratings - Low to High");
        }
        return filteredOptions;
    };

    render() {
        const { classes } = this.props;
        return (
            <Grid className={classes.container}>
                {
                    !this.props.isLicensedValidated && !this.props.isLicenseRequestLoading ?
                        <Grid className={classNames(classes.container, classes.flex)} justify="center" alignItems="center">
                            <Typography variant="h6" className={classes.anchorLink} component="h6" onClick={() => this.props.history.push({ pathname: `/settings`, state: { tabIndex: 2, navigateTab: "License" } })}>
                                Please click here to add a license
                            </Typography>
                        </Grid> :
                        <Grid>
                            {
                                this.state.isDatasourceView ?
                                    <Fragment>
                                        <DatasourceListFilter
                                            config={this.props.config}
                                            history={this.props.history}
                                            updateSourceList={this.manageImportData}
                                            sortBy={this.state.sortBy}
                                        />
                                        {/* <Grid item justify="flex-end" style={{ display: 'flex' }} className={classes.minWidth110}>
                                            <Grid xs={2}>
                                                <ValidatorForm
                                                    name="searchform"
                                                >
                                                    <TextBox
                                                        className={classes.inlinetxt}
                                                        onChange={(event) => this.onSortingChange(event.target.value)}
                                                        value={this.state.sortBy}
                                                        select
                                                        name="sortby"
                                                        label="Sort By"
                                                        SelectProps={
                                                            {
                                                                MenuProps: {
                                                                    anchorOrigin: {
                                                                        vertical: "bottom",
                                                                        horizontal: "center"
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: "top",
                                                                        horizontal: "center"
                                                                    },
                                                                    getContentAnchorEl: null
                                                                }
                                                                // IconComponent: () => DropDownIcon()
                                                            }
                                                        }
                                                        fullWidth>
                                                        {
                                                            this.getSortOptions().map((option, index) => (
                                                                <MenuItem key={index} value={option} className={classes.menuItem}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </TextBox>
                                                </ValidatorForm>
                                            </Grid>
                                        </Grid> */}
                                        <DatasourceList
                                            history={this.props.history}
                                            type={this.props.catalog.filters.viewType}
                                            sources={this.getViewData(this.props.catalog.filters.viewType)}
                                            loadMore={this.state.loadMore}
                                            containerRef={this.containerRef}
                                            isPageLoading={this.state.isPageLoading}
                                            loadPage={this.state.loadPage}
                                            requestAccess={this.requestAccess}
                                            redirectDetail={this.redirectDetail}
                                            responseRequest={this.responseRequest}
                                        />
                                    </Fragment> :
                                    <DatasourceDetails
                                        breadcrumb={this.state.breadcrumb}
                                        redirect={this.redirectDetail}
                                        source={this.state.selectedDatasource}
                                        history={this.props.history}
                                    />
                            }
                        </Grid>
                }
                {
                    this.state.openSelectDialog && this.props.isLicensedValidated &&
                    <SelectConnectors
                        open={this.state.openSelectDialog}
                        onClose={() => this.setState({ openSelectDialog: false })}
                        connectionUpdated={() => this.setConnectionUpdated()}
                        organizationId={this.props.user && this.props.user.organization ? this.props.user.organization : this.props.user.organization_id}
                    />
                }

            </Grid>
        );
    }
}

Datasource.propTypes = {
    classes: PropTypes.object,
    config: PropTypes.object,
    history: PropTypes.object,
    filters: PropTypes.object,
    user: PropTypes.object,
    createDatasetShare: PropTypes.func,
    createSourceShare: PropTypes.func,
    getMasterFieldTypes: PropTypes.func,
    getPropertiesOrganization: PropTypes.func,
    systemConfiguration: PropTypes.bool,
    getStorage: PropTypes.func,
    catalogView: PropTypes.bool,
    switchCatalog: PropTypes.func,
    catalog: PropTypes.object,
    getCatalogListData: PropTypes.func,
    clearCatalogList: PropTypes.func,
    updateDatasource: PropTypes.func,
    updateDataset: PropTypes.func,
    users: PropTypes.array,
    isLicensedValidated: PropTypes.bool,
    isLicenseRequestLoading: PropTypes.bool,
    getUpdateLicenseConnectionStatus: PropTypes.func,
    getReferences: PropTypes.func,
    getPageTitle: PropTypes.func
};

/**
 * Map State To Props
 * @description Get Reducer Props value to Props property
 * @param {object} datasource,account
 */
const mapStateToProps = ({ datasource, account, setting, notification, catalog }) => {
    return {
        ...datasource,
        ...account,
        ...setting,
        ...notification,
        catalog: {
            ...catalog
        }
    };
};

/**
 * Dispatch Props
 * @description dispatch Props actions
 * @param {object} dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getOrganizationUser, getAllDatasource, createSourceShare, getAllDatasets, getAllAttributes, getReferences, createDatasetShare, getAllDomains, getMasterFieldTypes, getSourceDatasets, getPropertiesOrganization, getStorage, switchCatalog, getCatalogListData, clearCatalogList, updateDatasource, updateDataset, getUpdateLicenseConnectionStatus
    }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(Datasource));