import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    withStyles, Grid, Typography, Button, Table, TableHead, Card, FormControlLabel,
    TableBody, TableRow, TableCell, IconButton, TableSortLabel
} from '@material-ui/core';
import classnames from 'classnames';
import ConnectorStyles from '../ConnectorStyles.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextBox from '../../TextBox/TextBox.jsx';
import { appConstants } from '../../../constants/appConstants.js';
import Styles from '../../../layouts/Styles.jsx';
import { appConfig } from '../../../config/appConfig.js';
import { getConnectedDatasets, connectDataset, connectDatasource, getSchema } from '../../../actions/connectorActions';
import { deleteDataSet } from '../../../actions/datasetActions.js';
import Loader from '../../Loaders/Loader.jsx';
import AlertDialog from '../../AlertDialog/AlertDialog.jsx';
import CheckboxComponent from '../../ChecboxComponent/CheckboxComponent.jsx';
import { AccordionTable } from "./CollapseTable.jsx";
import { sortTable } from '../../../helpers/appHelpers.js';
import WarningIcon from '../../Warning/WarningIcon.jsx';
import { success } from '../../../actions/baseActions';
import { alertActions } from '../../../constants/actionTypes/alertActionTypes';
import ToolTipComponent from '../../Tooltip/Tooltip.jsx';


const MongoDBConnector = (props) => {
    const { classes, datasourceType, datasourceId, onConnect, onCancel, theme, history, copyConfig } = props;
    const dispatch = useDispatch();
    const [connectionConfig, setConnectionConfig] = useState({
        server: '',
        port: '',
        username: '',
        password: '',
        schema: '',
        performAction: false
    });
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [datasets, setDatasets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [datasource, setDatasource] = useState(null);
    const [selectedDataSet, setSelectedDataSet] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const [isOnUpdate, setIsOnUpdate] = useState(Boolean(!datasourceId));
    const [isValid, setIsValid] = useState(true);
    const [isAnySelected, setIsAnySelected] = useState(false);
    const [filterSelected, setFilterSelected] = useState(false);
    const userConfig = useSelector(({ setting }) => setting.user_config);

    const onChangeOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    const onFilter = useCallback((_event) => {
        setFilterSelected(!filterSelected);
    }, [filterSelected]);

    const onSearchDatasets = useCallback((key) => {
        setSearchKey(key);
    }, []);

    /**
     * Handles the connection configuration changes
     * @param {Name of the property} property
     * @param {Value of the property} value
     */
    const handleChange = useCallback((property, value) => {
        if (isPageLoading) {
            return false;
        }
        if (datasourceId && (connectionConfig[property] !== value)) {
            setIsOnUpdate(true);
        }
        connectionConfig[property] = value;
        if (property === 'performAction' && !value) {
            connectionConfig.username = '';
            connectionConfig.password = '';
        }
        setConnectionConfig({ ...connectionConfig });
        setHasChanges(true);
    }, [connectionConfig, datasourceId, isPageLoading]);

    /**
     * Removes the selected file
     * @param {Object} dataset - This file object
     */
    const removeDataset = useCallback((dataset) => {
        const tables = [...datasets];
        const index = tables.findIndex((p) => p.dataset_id === dataset.dataset_id);
        if (index > -1) {

            if (dataset.dataset_id) {
                setHasChanges(true);
                dispatch(deleteDataSet({ datasets: [dataset.dataset_id] })).then((response) => {
                    if (response) {
                        dataset['dataset_id'] = null;
                        dataset.isSelected = false;
                        tables.splice(index, 1, { ...dataset });
                        setDatasets([...tables]);
                        setIsAllSelected(false);
                    }
                });
                setSelectedDataSet(null);
            }
        }
    }, [datasets, dispatch]);


    const deleteSelectedDataset = useCallback((event, dataset) => {
        event.stopPropagation();
        if (!dataset.dataset_id) {
            removeDataset(dataset);
            return;
        }
        setSelectedDataSet(dataset);
    }, [removeDataset]);


    const onSelectionChange = useCallback((selectedDataset) => {
        for (const dataset of datasets) {
            if (dataset.name.toLowerCase() === selectedDataset.name.toLowerCase()) {
                dataset.isSelected = !dataset.isSelected;
                const hasSelectedAttributes = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length > 0;
                if (dataset.isSelected && dataset.attributes && !hasSelectedAttributes) {
                    for (const attribute of dataset.attributes) {
                        attribute.isSelected = true;
                    }
                    dataset.isAllAttributesSelected = true;
                }

                if (!dataset.isSelected && dataset.attributes) {
                    for (const attribute of dataset.attributes) {
                        attribute.isSelected = false;
                    }
                    dataset.isAllAttributesSelected = false;
                }
            }
        }
        setIsAllSelected(datasets.filter((p) => p.name.isSelected).length === datasets.length);
        setDatasets([...datasets]);
    }, [datasets]);

    /**
     * Handles the select all files option
     */
    const onSelectAll = useCallback(() => {
        setIsAllSelected(!isAllSelected);
        for (const dataset of datasets) {
            dataset.isSelected = !isAllSelected;
            const hasSelectedAttributes = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length > 0;
            if (dataset.isSelected && dataset.attributes && !hasSelectedAttributes) {
                for (const attribute of dataset.attributes) {
                    attribute.isSelected = true;
                }
            }
            if (!dataset.isSelected && dataset.attributes) {
                for (const attribute of dataset.attributes) {
                    attribute.isSelected = false;
                }
            }
            dataset.isAllAttributesSelected = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length === dataset.attributes.length;
        }
        setDatasets(datasets);
    }, [datasets, isAllSelected]);

    const loadDataSource = useCallback((datasource) => {
        if (datasource && datasource.config) {
            const config = {
                server: datasource.config.server ? datasource.config.server : '',
                port: datasource.config.port ? datasource.config.port : '',
                username: datasource.config.username ? datasource.config.username : '',
                password: datasource.config.password ? datasource.config.password : '',
                schema: datasource.config.schema ? datasource.config.schema : '',
                performAction: datasource.config.performAction ? datasource.config.performAction : false
            };
            setConnectionConfig({ ...config });
            setDatasource({ ...datasource });
        }
    }, []);

    const loadDatasets = useCallback((datasets) => {
        datasets = datasets.map((elem) => {
            elem.include_organization_domain_score = (elem.include_organization_domain_score || elem.include_organization_domain_score === false) ? elem.include_organization_domain_score : true;
            return elem;
        });
        setIsAllSelected(datasets.filter((p) => p.isSelected).length === datasets.length);
        setIsAnySelected(datasets.filter((p) => p.isSelected).length > 0);
        setDatasets([...datasets]);
        setHasChanges(false);
    }, [setDatasets]);

    /**
     * Connectes the source with the given credentials
     */
    const connectToDatasource = useCallback(() => {
        connectionConfig['connection_type'] = datasourceType.type;
        const datasource = {
            datasourceId: datasourceId ? datasourceId : 0,
            name: appConfig.defaultDataSourceName,
            type: datasourceType.type,
            description: '',
            config: {
                ...connectionConfig
            }
        };
        setIsLoading(true);
        dispatch(connectDatasource(datasource)).then((response) => {
            if (!response) {
                setIsLoading(false);
                setIsValid(false);
                return;
            }
            setDatasource(response.datasource ? { ...response.datasource } : {});
            if (datasourceId) {
                dispatch(getConnectedDatasets(datasourceId)).then((response) => {
                    setIsLoading(false);
                    setIsValid(true);
                    if (response) {
                        loadDataSource(response.datasource);
                        loadDatasets(response.datasets ? [...response.datasets] : []);
                        setIsOnUpdate(false);
                    }
                });
            } else {
                setIsLoading(false);
                loadDatasets(response.datasets ? [...response.datasets] : []);
                setIsOnUpdate(false);
            }
        }).catch(() => {
            setIsLoading(false);
            setIsOnUpdate(true);
            setIsValid(false);
        });
    }, [connectionConfig, datasourceId, datasourceType.type, dispatch, loadDataSource, loadDatasets]);


    /**
     * Creates a new dataset
     */
    const createDatasets = useCallback(() => {
        if (!datasourceId && !datasource) {
            return;
        }
        const selectedDatasets = datasets.filter((p) => p.isSelected);
        const datasetsToConnect = [];
        for (const dataset of selectedDatasets) {
            dataset.schema = connectionConfig.schema;
            if (!dataset.dataset_id) {
                datasetsToConnect.push(dataset);
            }
        }
        if (!datasetsToConnect) {
            return;
        }

        let selectedDatasourceId = datasourceId;
        if (!datasourceId && datasource) {
            selectedDatasourceId = datasource.id;
        }

        const datasetConfig = {
            'datasource_id': selectedDatasourceId,
            datasets: datasetsToConnect
        };
        setIsLoading(true);
        dispatch(connectDataset(datasetConfig)).then((response) => {
            if (!response) {
                setIsLoading(false);
                return;
            }
            if (onConnect) {
                onConnect(selectedDatasourceId, true);
            }
        }).catch(() => setIsLoading(false));
    }, [connectionConfig.schema, datasets, datasource, datasourceId, dispatch, onConnect]);

    useEffect(() => {
        if (copyConfig) {
            if (copyConfig.source_id) {
                delete copyConfig.source_id;
            }
            if (copyConfig.connection_establish) {
                delete copyConfig.connection_establish;
            }
            setConnectionConfig({ ...copyConfig });
            dispatch(success(alertActions.ALERT_SUCCESS, 'Datasource Duplicated Successfully'));
        }
    }, [copyConfig, dispatch]);


    useEffect(() => {
        if (!datasourceId) {
            return;
        }
        setIsPageLoading(true);
        dispatch(getConnectedDatasets(datasourceId)).then((response) => {
            setIsPageLoading(false);
            if (response) {
                loadDataSource(response.datasource);
                loadDatasets(response.datasets);
            }
        });
    }, [datasourceId, dispatch, loadDataSource, loadDatasets]);

    const getDatasetSchema = useCallback((dataset) => {
        if (!dataset || (dataset && dataset.attributes && dataset.attributes.length > 0)) {
            return;
        }

        dataset.isLoadingAttributes = true;
        const datasetIndex = datasets.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
        datasets.splice(datasetIndex, 1, { ...dataset });

        setDatasets([...datasets]);
        const datasetConfig = {
            'connection_type': datasourceType.type,
            'table': dataset.name,
            ...connectionConfig,
            ...dataset
        };
        dispatch(getSchema(datasetConfig)).then((response) => {
            if (response && response.length > 0) {
                dataset.attributes = [...response];
                const hasSelectedAttributes = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length > 0;
                if (dataset.isSelected && dataset.attributes && !hasSelectedAttributes) {
                    for (const attribute of dataset.attributes) {
                        attribute.isSelected = true;
                    }
                    dataset.isAllAttributesSelected = true;
                }
            }
            dataset.isAllAttributesSelected = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length === dataset.attributes.length;
            dataset.isLoadingAttributes = false;
            const datasetIndex = datasets.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
            datasets.splice(datasetIndex, 1, { ...dataset });
            setDatasets([...datasets]);
        });

    }, [connectionConfig, datasets, datasourceType.type, dispatch]);


    const onSelectAttribute = useCallback((dataset, attribute, attributeIndex) => {
        const attributes = [...dataset.attributes];
        attribute.isSelected = !attribute.isSelected;
        attributes.splice(attributeIndex, 1, { ...attribute });
        dataset.attributes = [...attributes];
        dataset.isAllAttributesSelected = attributes.filter((p) => p.isSelected).length === attributes.length;
        dataset.isSelected = dataset.attributes.filter((attribute) => attribute.isSelected).length === 0;

        const selectedDatasetIndex = datasets.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
        datasets.splice(selectedDatasetIndex, 1, JSON.parse(JSON.stringify(dataset)));
        setDatasets([...datasets]);
        onSelectionChange(dataset);
    }, [datasets, onSelectionChange]);

    const onSelectAllAttribute = useCallback((dataset) => {
        dataset.isAllAttributesSelected = !dataset.isAllAttributesSelected;
        for (const attribute of dataset.attributes) {
            attribute.isSelected = dataset.isAllAttributesSelected;
        }
        dataset.isSelected = dataset.attributes.filter((attribute) => attribute.isSelected).length === 0;
        const selectedDatasetIndex = datasets.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
        datasets.splice(selectedDatasetIndex, 1, JSON.parse(JSON.stringify(dataset)));
        setDatasets([...datasets]);
        onSelectionChange(dataset);
    }, [datasets, onSelectionChange]);

    const onUpdateDataset = useCallback((dataset, property, value) => {
        const tables = [...datasets];
        const index = tables.indexOf(dataset);
        if (index > -1) {
            dataset[property] = value;
            if (property === 'con_type') {
                dataset['con_type'] = value;
            }
            tables.splice(index, 1, { ...dataset });
            setDatasets([...tables]);
        }
    }, [datasets, setDatasets]);

    let selectedDatasets = [...datasets];
    if (searchKey) {
        selectedDatasets = datasets.filter((p) => p.name.toLowerCase().includes(searchKey.toLowerCase()));
    }

    const getDatasets = () => {
        let filterdDatasetList = sortTable(selectedDatasets, order, orderBy);
        let otherDatasets = [];
        if (filterSelected) {
            otherDatasets = filterdDatasetList.filter((dataset) => !dataset.isSelected);
            filterdDatasetList = filterdDatasetList.filter((dataset) => dataset.isSelected);
            filterdDatasetList = [...filterdDatasetList, ...otherDatasets];
        }
        return filterdDatasetList;
    };

    return (
        <ValidatorForm form="sql_config" autoComplete={"off"} onSubmit={() => connectToDatasource()} style={{ height: '100%' }}>
            <Grid container className={classes.connectcontainer} direction="row" justify="flex-start" alignItems="flex-start" style={{ height: '100%', position: 'relative' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography component="h4" variant="h4" className={classes.connectTitle}>
                                {'Configure MongoDB Connection'}
                            </Typography>
                            <Typography variant="body2" className={classes.connectdescription}>
                                {datasourceType.description}
                            </Typography>
                        </Grid>
                        {
                            datasourceId &&
                            <Grid item xs={2} className={classnames(classes.textRight, classes.duplicateDatasource)}>
                                <ToolTipComponent title="Duplicate Datasource" arrow>
                                    <IconButton onClick={() => history.push({ pathname: '/configuredatasource', state: { datasourceType: datasourceType, con_config: connectionConfig, isCopy: true } })}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={theme.palette.primary.main}>
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                                        </svg>
                                    </IconButton>
                                </ToolTipComponent>
                            </Grid>
                        }
                    </Grid>

                    <Grid container direction="row" spacing={3} style={{ marginTop: 20 }}>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <CheckboxComponent checked={connectionConfig.performAction} onChange={(event) => handleChange('performAction', event.target.checked)} disabled={isAnySelected} checkboxLabel="Perform Authentication" />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="Server Name"
                                        name="server"
                                        validators={['required']}
                                        errorMessages={['Server Name is required']}
                                        value={connectionConfig.server}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        {
                            connectionConfig.performAction &&
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid xs={8}>
                                        <TextBox
                                            label="Username"
                                            name="username"
                                            disabled={isAnySelected}
                                            validators={['required']}
                                            errorMessages={['Username is required']}
                                            value={connectionConfig.username}
                                            onChange={(event) => handleChange(event.target.name, event.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        {
                            connectionConfig.performAction &&
                            <Grid item xs={6}>
                                <Grid container>
                                    <Grid xs={8}>
                                        <TextBox
                                            label="Password"
                                            name="password"
                                            validators={['required']}
                                            errorMessages={['password is required']}
                                            type="password"
                                            value={connectionConfig.password}
                                            onChange={(event) => handleChange(event.target.name, event.target.value)}
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>

                        }
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="Port"
                                        name="port"
                                        validators={['required']}
                                        errorMessages={['Port  is required']}
                                        type="number"
                                        value={connectionConfig.port}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="Database Name"
                                        name="schema"
                                        disabled={isAnySelected}
                                        validators={['required']}
                                        errorMessages={['Database Name is required']}
                                        value={connectionConfig.schema}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    (isOnUpdate) &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container direction="row" justify="flex-start" style={{ marginTop: 40 }}>
                            <Grid item className={classes.btnContainer}>
                                <Button type="submit"
                                    variant="contained"
                                    disabled={isLoading}
                                    color="primary">
                                    {'Validate'}
                                    {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button className={classnames(classes.cancelButton)}
                                    style={{ marginLeft: 20 }}
                                    disabled={isLoading}
                                    onClick={() => onCancel(hasChanges)}>
                                    {'Cancel'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {
                    (datasets.length > 0) &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.searchInputContainer}>
                            <TextBox placeholder={'Search Dataset'}
                                onChange={(event) => onSearchDatasets(event.target.value)}
                                name="searchKey"
                                id="searchKey"
                                value={searchKey}
                                className={classes.inputOutline} />
                            {
                                searchKey.length === 0 ?
                                    <IconButton className={classes.includeSearchIcon}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.094" height="13.092" viewBox="0 0 13.094 13.092">
                                            <g transform="translate(0 -0.035)">
                                                <path fill="#afb2b3" d="M5.27,10.57A5.257,5.257,0,0,0,8.5,9.46l3.483,3.483a.655.655,0,0,0,.926-.926L9.427,8.534A5.267,5.267,0,1,0,5.27,10.57ZM2.471,2.5a3.958,3.958,0,1,1,0,5.6h0a3.944,3.944,0,0,1-.02-5.577l.02-.02Z" transform="translate(0 0)" />
                                            </g>
                                        </svg>
                                    </IconButton> :
                                    <IconButton className={classes.includeDeleteIcon} onClick={() => onSearchDatasets('')}>
                                        <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                            <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                            <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                        </svg>
                                    </IconButton>
                            }
                        </Grid>
                        <Grid xs={12} justify="flex-end" style={{ display: 'flex' }}>
                            <FormControlLabel
                                control={
                                    <CheckboxComponent
                                        className={"attributeSelectorCheck"}
                                        checked={filterSelected}
                                        onClick={onFilter}
                                        checkboxLabel={''}
                                    />
                                }
                                label="Show Selected"
                            />
                        </Grid>
                    </Grid>
                }
                {
                    <Card className={classnames(classes.tableaccordian, classes.connectTableContainer, connectionConfig.authType === appConstants.sqlAuthTypes[1] ? classes.serverView : '')}>
                        {
                            selectedDatasets && selectedDatasets.length >= 1 &&
                            <Table stickyHeader className={classnames(classes.datasettable, classes.designTable)} aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.datasetHeaderTitleCell}>
                                            <TableSortLabel
                                                active={orderBy === "name"}
                                                direction={orderBy === "name" ? order : 'asc'}
                                                onClick={() => onChangeOrder("name")}
                                            >
                                                <Grid className={classes.datasetTitleSection}>
                                                    <Grid className={classnames(classes.inlineBlock, classes.displayFlex)}>
                                                        {!isValid && <WarningIcon />}
                                                        <CheckboxComponent
                                                            className={"attributeSelectorCheck"}
                                                            checked={isAllSelected}
                                                            onClick={() => onSelectAll()}
                                                            checkboxLabel={'Datasets'}
                                                            showCheckBox={isValid}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </TableSortLabel>
                                        </TableCell>
                                        {
                                            appConstants && appConstants.mongoDBConnectorTableHeadersObj &&
                                            appConstants.mongoDBConnectorTableHeadersObj.filter((elem) => (userConfig.include_organization_domain_score ? true : elem.id !== 'include_organization_domain_score')).map((header, index) => {
                                                return (
                                                    <TableCell key={`sqlConnectorTable${index}`}
                                                        className={appConstants.mongoDBConnectorTableHeaders.length === index && classes.textCenter}
                                                        sortDirection={orderBy === header.id ? order : false}>
                                                        {
                                                            header.sort === false ?
                                                                header.label :
                                                                <TableSortLabel
                                                                    active={orderBy === header.id}
                                                                    direction={orderBy === header.id ? order : 'asc'}
                                                                    onClick={() => onChangeOrder(header.id)}
                                                                >
                                                                    {header.label}
                                                                </TableSortLabel>
                                                        }
                                                    </TableCell>
                                                );
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.datasetTableAccordHeader}>
                                    {
                                        selectedDatasets && getDatasets().map((dataset, index) => {
                                            return (
                                                <AccordionTable key={index}
                                                    dataset={dataset}
                                                    index={index}
                                                    classes={classes}
                                                    isLoading={isLoading}
                                                    theme={theme}
                                                    rootColumns={appConstants.mongoDBConnectorTableHeadersObj}
                                                    getDatasetSchema={getDatasetSchema}
                                                    onSelectionChange={onSelectionChange}
                                                    deleteSelectedDataset={deleteSelectedDataset}
                                                    onSelectAllAttribute={onSelectAllAttribute}
                                                    onSelectAttribute={onSelectAttribute}
                                                    onUpdateDataset={onUpdateDataset}
                                                    showCheckBox={isValid}
                                                    userConfig={userConfig}
                                                />
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        }
                    </Card>
                }
                {
                    (selectedDatasets.length > 0 && !isOnUpdate) &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container direction="row" justify="flex-end">
                            <Grid item className={classes.btnContainer}>
                                <Button variant="contained"
                                    disabled={isLoading}
                                    color="primary"
                                    onClick={() => createDatasets()}
                                    className={classnames(classes.actionButtons)}>
                                    {'Connect'}
                                    {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button className={classnames(classes.cancelButton, classes.actionButtons)}
                                    disabled={isLoading}
                                    onClick={() => onCancel(hasChanges)}>
                                    {'Cancel'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                <AlertDialog title="Delete Dataset"
                    message={`Are you sure you want to delete the selected dataset ${selectedDataSet ? selectedDataSet.name : ''}?`}
                    okButtonText="OK"
                    cancelButtonText="Cancel"
                    show={Boolean(selectedDataSet)}
                    onClickOK={() => removeDataset(selectedDataSet)}
                    onClickCancel={() => setSelectedDataSet(null)} />
                {isPageLoading && <Loader />}
            </Grid>
        </ValidatorForm >
    );

};

MongoDBConnector.propTypes = {
    classes: PropTypes.object,
    datasourceType: PropTypes.object,
    datasourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onConnect: PropTypes.func,
    onCancel: PropTypes.func,
    theme: PropTypes.object,
    history: PropTypes.object,
    copyConfig: PropTypes.object
};

export default withStyles((theme) => ({
    ...ConnectorStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MongoDBConnector);