import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, MenuItem, withStyles, Avatar, Typography, Backdrop, IconButton } from '@material-ui/core';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import UserAutoCompleteStyles from './UserAutoCompleteStyles.jsx';
import classNames from 'classnames';
import { getRandomColor, getInitials, returnImage } from '../../helpers/appHelpers';
import { appConstants } from '../../constants/appConstants';
import CloseIcon from '@material-ui/icons/Close';

const UserAutoComplete = (props) => {

    const { classes, selectedList, availableList, displayValue, inputValue, onChange, onInputValueChange, onRemoveItem, disabled } = props;
    const [open, setOpen] = useState(false);


    const inputChange = (value) => {
        onInputValueChange(value);
    };

    const renderInput = () => {
        return (
            <TextBox
                className={classNames(classes.inlinetxt, classes.input)}
                value={inputValue ? inputValue : ''}
                fullWidth
                onChange={(event) => inputChange(event.target.value)}
                onClick={disabled ? null : () => setOpen(true)}
                disabled={Boolean(disabled)}
            />
        );
    };

    const renderList = () => {
        return (
            <div className={classes.chipContainer}>
                {
                    selectedList && selectedList.map((item, index) =>
                        <Fragment key={index}>
                            {
                                !item.isDefault ?
                                    <Grid className={classNames(classes.listChip, classes.chipList)}>
                                        <Avatar
                                            className={classNames(classes.avatar, classes.filterSelectedavatar)}
                                            style={
                                                {
                                                    backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, item.avatar)})`,
                                                    backgroundColor: getRandomColor(item.name)
                                                }
                                            }
                                        >
                                            {
                                                !item.avatar &&
                                                <span>
                                                    {getInitials(item.name)}
                                                </span>
                                            }
                                        </Avatar>
                                        <IconButton onClick={() => onChange(item.name)} className={classNames(classes.nopadding, classes.inlineClose, 'chipremove', 'inlineChip')}>
                                            <CloseIcon className={classes.chipIcon} />
                                        </IconButton>
                                    </Grid> :
                                    <Typography className={classes.allText}>
                                        {item.name}
                                    </Typography>
                            }

                        </Fragment>
                    )
                }
            </div>
        );
    };

    const getSuggestions = (input, displayValue, list) => {
        let suggestions = [...list];
        if (!input) {
            return suggestions;
        }
        suggestions = list.filter((item) => item[displayValue].toLowerCase().includes(input.toLowerCase()));
        return [...suggestions];
    };

    const renderSuggestion = (params) => {
        const { item, itemProps, displayValue } = params;
        return (
            <MenuItem
                key={`index_${item.name}`}
                {...itemProps}
                selected={item.isSelected}
                component="div"
                className={classNames(item.isSelected ? classes.highlight : '', classes.menuItem)}
                onClick={() => onChange(item.name)}
            >
                <div style={{ display: 'inline-flex' }}>
                    {
                        !item.isDefault &&
                        <Avatar
                            className={classes.avatar}
                            style={
                                {
                                    backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, item.avatar)})`,
                                    backgroundColor: getRandomColor(item.name)
                                }
                            }
                        >
                            {
                                !item.avatar &&
                                <span>
                                    {getInitials(item.name)}
                                </span>
                            }
                        </Avatar>
                    }
                    <Typography style={{ marginTop: '2px' }}>
                        {item[displayValue]}
                    </Typography>
                </div>
            </MenuItem>
        );
    };

    return (
        <Grid className={classes.selectContainer}>
            {renderList()}
            <Grid className={classes.selectDropDown}>
                {renderInput()}
                {
                    open &&
                    (
                        <Paper className={classes.paper} square>
                            {
                                getSuggestions(inputValue, displayValue, availableList).map((item, index) =>
                                    renderSuggestion({
                                        item,
                                        index,
                                        selectedList,
                                        displayValue,
                                        onRemoveItem
                                    })
                                )
                            }
                        </Paper>
                    )
                }
            </Grid>
            {open && <Backdrop style={{ zIndex: 1 }} open={open} onClick={() => setOpen(false)} />}
        </Grid>
    );
};
UserAutoComplete.propTypes = {
    classes: PropTypes.object,
    selectedList: PropTypes.array,
    availableList: PropTypes.array,
    displayValue: PropTypes.string,
    onChange: PropTypes.func,
    onRemoveItem: PropTypes.func,
    inputValue: PropTypes.string,
    onInputValueChange: PropTypes.func,
    disabled: PropTypes.bool
};
export default withStyles((theme) => ({
    ...UserAutoCompleteStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(UserAutoComplete);