import React, { useCallback, useState, useEffect, Fragment, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, withStyles, TextField, Button, FormControlLabel, Switch, Typography, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import DashboardStyles from './DashboardStyles.jsx';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import { appConstants } from '../../constants/appConstants.js';
import { getDatasourceList, getDatasetList, getAttributeList, getProfileRules, getDqscoreRules, setSearchKey } from '../../actions/metricsActions';
import { sortTable } from '../../helpers/appHelpers';
import TextBox from '../TextBox/TextBox.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import AutoCompleteCheckBox from '../AutoCompleteCheckBox/AutoCompleteCheckBox.jsx';
import { failure } from '../../actions/baseActions';
import { alertActions } from '../../constants/actionTypes/alertActionTypes';

const operationOptions = ['Count', 'Average', 'Sum', 'Min', 'Max'];

const WidgetSettingsView = (props) => {
    const { classes, widget, onSaveSettings, onCancelSettings } = props;
    const dispatch = useDispatch();
    const [metricsTypes] = useState([
        { name: 'Total Count', id: 'Total_Count' },
        { name: 'Invalid Records', id: 'Invalid_Records' },
        { name: 'Valid Records', id: 'Valid_Records' },
        { name: 'DQScore', id: 'DQ_Score' },
        { name: 'Drift Alerts', id: 'Drift_Alerts' },
        { name: 'Unique Count', id: 'Unique_count' },
        { name: 'Duplicate Count', id: 'Duplicate_count' },
        { name: 'Distinct Count', id: 'Distinct_count' },
        { name: 'Valid and Invalid Records', id: 'Total_valid_invalid' },
        { name: 'Asset Level DQScore', id: 'Asset_level_dqscore' },
        { name: 'Record Count', id: 'Record_count' },
        { name: 'Record Count Timeline Chart', id: 'Record_Count_Timeline_Chart' }
    ]);

    const [leveldqscore] = useState([
        { name: 'Datasource', id: 'Datasource' },
        { name: 'Dataset', id: 'Dataset' },
        { name: 'Attribute', id: 'Attribute' }
    ]);

    const [recordLevel] = useState([
        { name: 'Valid Records', id: 'valid_records' },
        { name: 'Invalid Records', id: 'invalid_records' }
    ]);

    const datasourceList = useSelector(({ metric }) => metric?.datasources?.datasources);
    const datasetList = useSelector(({ metric }) => metric?.datasets?.datasets);
    let attributeList = useSelector(({ metric }) => metric?.attributes?.attributes);
    const profileRules = useSelector(({ metric }) => metric?.profileRules?.rules);
    const dqscoreRules = useSelector(({ metric }) => metric?.dqscoreRules?.rules);

    const [currentWidget, setCurrentWidget] = useState(null);
    const [showLevel, setShowLevel] = useState(false);
    const [recordLevels, setRecordLevels] = useState(false);
    const [showDatasource, setShowDatasource] = useState(false);
    const [showMultiSelectDatasource, setshowMultiSelectDatasource] = useState(false);
    const [showDataset, setShowDataset] = useState(false);
    const [showMultiSelectDataset, setshowMultiSelectDataset] = useState(false);
    const [showAttributes, setShowAttributes] = useState(false);
    const [showAttributeNames, setShowAttributeNames] = useState(false);
    const [showSingleSelectAttributes, setshowSingleSelectAttributes] = useState(false);
    const [showRules, setShowRules] = useState(false);
    const [showDqRules, setShowDqRules] = useState(false);
    const [showRange, setShowRange] = useState(false);
    const [showXaxis, setShowXaxis] = useState(false);
    const [showYaxis, setShowYaxis] = useState(false);
    const [showFilterNull, setShowFilterNull] = useState(false);
    const [showFilterEmpty, setShowFilterEmpty] = useState(false);
    const [showOperationValue, setShowOperationValue] = useState(false);

    const datasourceControllerRef = useRef();
    const datasetControllerRef = useRef();
    const attributesControllerRef = useRef();
    const profileRulesControllerRef = useRef();
    const dqscoreRulesControllerRef = useRef();

    const loadDataSources = useCallback((widget, search_key = '') => {
        if (datasourceControllerRef && datasourceControllerRef.current) {
            datasourceControllerRef.current.abort();
        }
        const controller = new AbortController();
        datasourceControllerRef.current = controller;
        const token = { signal: datasourceControllerRef?.current?.signal };

        const key = search_key ? search_key : 'all';
        const requestParams = {
            widgetId: widget.i,
            datasource_ids: widget.datasource_ids,
            key
        };
        dispatch(setSearchKey('datasource', key));
        dispatch(getDatasourceList(requestParams, token));
    }, [dispatch]);

    const loadDatasets = useCallback((widget, search_key = '') => {
        if (!widget?.datasource_id || (widget?.datasource_id && widget?.datasource_id?.length === 0)) {
            return;
        }

        if (datasetControllerRef && datasetControllerRef.current) {
            datasetControllerRef.current.abort();
        }
        const controller = new AbortController();
        datasetControllerRef.current = controller;
        const token = { signal: datasetControllerRef?.current?.signal };

        const key = search_key ? search_key : 'all';
        let datasource_ids = widget.datasource_id && widget.datasource_id.id ? [widget.datasource_id.id] : widget.datasource_id;
        if (datasource_ids) {
            datasource_ids = datasource_ids.map((data) => (typeof data === 'object' && 'id' in data ? data.id : data));
        }
        const requestParams = {
            widgetId: widget.i,
            datasources: datasource_ids,
            datasets: widget.dataset_id ? widget.dataset_id : [],
            key
        };
        dispatch(setSearchKey('dataset', key));
        dispatch(getDatasetList(requestParams, token));
    }, [dispatch]);

    const loadAttributes = useCallback((widget, search_key = '') => {
        if (attributesControllerRef && attributesControllerRef.current) {
            attributesControllerRef.current.abort();
        }
        const controller = new AbortController();
        attributesControllerRef.current = controller;
        const token = { signal: attributesControllerRef?.current?.signal };

        const key = search_key ? search_key : 'all';
        let datasource_ids = widget.datasource_id && widget.datasource_id.id ? [widget.datasource_id.id] : widget.datasource_id;
        if (datasource_ids) {
            datasource_ids = datasource_ids.map((data) => (typeof data === 'object' && 'id' in data ? data.id : data));
        }
        const requestParams = {
            widgetId: widget.i,
            datasources: datasource_ids,
            datasets: widget.dataset_ids && widget.dataset_ids.id ? [widget.dataset_ids] : widget.dataset_ids || [],
            attributes: widget.attribute_ids ? widget.attribute_ids : [],
            key
        };
        dispatch(setSearchKey('attributes', key));
        dispatch(getAttributeList(requestParams, token));
    }, [dispatch]);

    const loadProfileRules = useCallback((widget, search_key = '') => {
        if (profileRulesControllerRef && profileRulesControllerRef.current) {
            profileRulesControllerRef.current.abort();
        }
        const controller = new AbortController();
        profileRulesControllerRef.current = controller;
        const token = { signal: profileRulesControllerRef?.current?.signal };

        const type_of_charts = widget.metrics_type ? widget.metrics_type.id : "";
        const key = search_key ? search_key : 'all';
        const requestParams = {
            widgetId: widget.i,
            datasets: widget.dataset_ids && widget.dataset_ids.id ? [widget.dataset_ids] : widget.dataset_ids || [],
            chart_type: type_of_charts,
            key
        };
        dispatch(getProfileRules(requestParams, token));
    }, [dispatch]);

    const loadDQScoreRules = useCallback((widget, search_key = '') => {
        if (dqscoreRulesControllerRef && dqscoreRulesControllerRef.current) {
            dqscoreRulesControllerRef.current.abort();
        }
        const controller = new AbortController();
        dqscoreRulesControllerRef.current = controller;
        const token = { signal: dqscoreRulesControllerRef?.current?.signal };

        const type_of_charts = widget.metrics_type ? widget.metrics_type.id : "";
        const key = search_key ? search_key : 'all';
        const requestParams = {
            dataset_id: 0,
            widgetId: widget.i,
            datasets: widget.dataset_ids ? widget.dataset_ids : [],
            chart_type: type_of_charts,
            key
        };
        dispatch(getDqscoreRules(requestParams, token));
    }, [dispatch]);

    const onChartTypeChange = useCallback((widget, isInitialLoad = false) => {
        if (!widget || (widget && !widget.metrics_type)) {
            return;
        }
        if (!isInitialLoad) {
            widget.datasource_id = [];
            widget.dataset_ids = [];
            widget.attribute_ids = [];
            widget.rules = [];
            widget.xaxis = '';
            widget.yaxis = '';
        }
        if (widget.metrics_type.id === "Total_Count") {
            setShowDatasource(false);
            setShowDataset(false);
            setshowMultiSelectDataset(false);
            setShowAttributes(false);
            setshowMultiSelectDatasource(false);
            setShowAttributeNames(false);
            setShowRules(false);
            setShowDqRules(false);
            setShowRange(false);
            setShowXaxis(false);
            setShowYaxis(false);
            setShowFilterNull(false);
            setShowFilterEmpty(false);
            setRecordLevels(false);
            setShowLevel(true);
            setShowOperationValue(false);
        } else if (["Invalid_Records", "Valid_Records", "Total_valid_invalid"].includes(widget.metrics_type.id)) {
            setShowLevel(false);
            setShowRange(false);
            setRecordLevels(false);
            setShowAttributes(false);
            setshowMultiSelectDatasource(false);
            setShowDqRules(false);
            setShowDatasource(true);
            setShowDataset(false);
            setshowMultiSelectDataset(true);
            setShowAttributeNames(true);
            setShowRules(true);
            setShowXaxis(widget.metrics_type.id !== "Total_valid_invalid");
            setShowYaxis(true);
            setShowFilterNull(true);
            setShowFilterEmpty(true);
            setShowOperationValue(false);
        } else if (widget.metrics_type.id === "DQ_Score") {
            setShowLevel(false);
            setShowYaxis(false);
            setShowFilterNull(false);
            setRecordLevels(false);
            setShowFilterEmpty(false);
            setShowAttributes(false);
            setShowRules(false);
            setshowMultiSelectDatasource(false);

            setShowDatasource(true);
            setShowDataset(false);
            setshowMultiSelectDataset(true);
            setShowAttributeNames(true);
            setShowDqRules(true);
            setShowXaxis(true);
            setShowRange(true);
            setShowOperationValue(false);
            widget.range = '15';
        } else if (widget.metrics_type.id === "Asset_level_dqscore") {
            setShowLevel(false);
            setShowYaxis(false);
            setShowFilterNull(false);
            setShowFilterEmpty(false);
            setShowAttributes(false);
            setRecordLevels(false);
            setShowRules(false);
            setShowAttributeNames(false);
            setShowDatasource(false);
            setShowDqRules(false);
            setShowXaxis(false);
            setShowRange(false);
            setshowMultiSelectDatasource(true);
            setShowOperationValue(false);
            if ("scorelevel" in widget) {
                if (widget.scorelevel.id === "Dataset") {
                    setShowAttributes(false);
                    setShowDataset(false);
                    setshowMultiSelectDataset(true);
                }
                if (widget.scorelevel.id === "Attribute") {
                    setShowDataset(false);
                    setshowMultiSelectDataset(true);
                    setShowAttributes(true);
                }
                if (widget.scorelevel.id === "Datasource") {
                    setShowDataset(false);
                    setshowMultiSelectDataset(false);
                    setShowAttributes(false);
                }
            } else {
                setShowAttributes(false);
                setShowDataset(false);
                setshowMultiSelectDataset(false);
            }
        } else if (widget.metrics_type.id === "Record_count") {
            setShowLevel(false);
            setShowYaxis(false);
            setShowFilterNull(false);
            setShowFilterEmpty(false);
            setShowAttributes(false);
            setRecordLevels(false);
            setShowRules(false);
            setShowAttributeNames(false);
            setShowDatasource(false);
            setShowDqRules(false);
            setShowXaxis(false);
            setShowRange(false);
            setshowMultiSelectDatasource(true);
            setShowOperationValue(false);
            if ("scorelevel" in widget) {
                if (widget.scorelevel.id === "Dataset") {
                    setShowAttributes(false);
                    setShowDataset(false);
                    setshowMultiSelectDataset(true);
                }
                if (widget.scorelevel.id === "Attribute") {
                    setShowDataset(false);
                    setshowMultiSelectDataset(true);
                    setShowAttributes(true);
                    setShowRules(true);
                    setRecordLevels(true);
                }
                if (widget.scorelevel.id === "Datasource") {
                    setShowDataset(false);
                    setshowMultiSelectDataset(false);
                    setShowAttributes(false);
                }
            } else {
                setShowAttributes(false);
                setShowDataset(false);
                setshowMultiSelectDataset(false);
            }
        } else if (widget.metrics_type.id === "Drift_Alerts") {
            const isHeatMap = widget.chart_type !== appConstants.driftChartTypes[3];
            setShowLevel(false);
            setShowYaxis(false);
            setRecordLevels(false);
            setShowFilterNull(false);
            setShowFilterEmpty(false);
            setShowAttributeNames(false);
            setShowRules(false);
            setShowDqRules(false);
            setShowXaxis(false);
            setShowDatasource(isHeatMap);
            setShowDataset(false);
            setshowMultiSelectDataset(true);
            setshowMultiSelectDatasource(false);
            setShowAttributes(true);
            setShowDqRules(true);
            setShowRange(isHeatMap);
            setShowOperationValue(false);
        } else if (widget.metrics_type.id === "Record_Count_Timeline_Chart") {
            setShowLevel(false);
            setShowRange(false);
            setShowAttributes(false);
            setshowMultiSelectDatasource(false);
            setShowDqRules(false);
            setShowDatasource(true);
            setShowDataset(true);
            setshowMultiSelectDataset(false);
            setShowAttributeNames(false);
            setshowSingleSelectAttributes(true);
            setShowRules(true);
            setShowXaxis(true);
            setShowYaxis(false);
            setShowFilterNull(false);
            setShowFilterEmpty(false);
            setShowOperationValue(true);
        } else {
            setShowLevel(false);
            setShowYaxis(false);
            setRecordLevels(false);
            setShowFilterNull(false);
            setshowMultiSelectDatasource(false);
            setShowFilterEmpty(false);
            setShowAttributeNames(false);
            setShowRules(false);
            setShowDqRules(false);
            setShowXaxis(false);
            setShowDatasource(true);
            setShowDataset(false);
            setshowMultiSelectDataset(true);
            setShowAttributes(false);
            setShowDqRules(false);
            setShowRange(false);
            setShowOperationValue(false);
        }
        setCurrentWidget({ ...widget });
    }, []);

    const loadWidget = useCallback((widget, loadSettings = true) => {
        widget = JSON.parse(JSON.stringify(widget));
        setCurrentWidget({ ...widget, isModified: false });
        if (!loadSettings) {
            return;
        }
        loadDataSources(widget);
        loadDatasets(widget);
        loadAttributes(widget);
        loadProfileRules(widget);
        loadDQScoreRules(widget);
        onChartTypeChange(widget, true);
    }, [loadAttributes, loadDQScoreRules, loadDataSources, loadDatasets, loadProfileRules, onChartTypeChange]);

    useEffect(() => {
        if (widget && !currentWidget) {
            loadWidget(widget);
        }
        if (widget && currentWidget?.isModified) {
            loadWidget(widget, false);
        }
    }, [currentWidget, loadWidget, widget]);

    const onChangescoreSettings = useCallback((property, value, isList = false) => {
        const widget = { ...currentWidget };
        widget[property] = value;
        setCurrentWidget(widget);
        if (property === "recordLevel") {
            onChartTypeChange(widget, true);
        } else {
            onChartTypeChange(widget);
        }
    }, [currentWidget]);

    const onChangeSettings = useCallback((property, value, isList = false) => {
        const regex = /^[0-9]*$/;
        if (property === "filter_count" && value !== "" && !regex.test(value)) {
            return false;
        }
        const widget = { ...currentWidget };
        if (widget.chart_type === 'Heat Map' && property === "yaxis" && value.includes("Attribute") && widget.xaxis === "Attribute") {
            widget.xaxis = '';
        }
        if (widget.chart_type === 'Heat Map' && property === "yaxis" && value.includes("Drift Rules") && widget.xaxis === "Rules") {
            widget.xaxis = '';
        }
        if (showMultiSelectDatasource) {
            if (!value.isSelected && value.name !== "all") {
                if (property === "datasource_id") {
                    const number_datasource = (widget.datasource_id instanceof Array) ? widget.datasource_id : [];
                    if (number_datasource.length > 9) {
                        dispatch(failure(alertActions.ALERT_ERROR, 'Please restrict your selection to 10 datasources.'));
                        return false;
                    }
                }

                if (property === "dataset_ids") {
                    const number_dataset = (widget.dataset_ids instanceof Array) ? widget.dataset_ids : [];
                    if (number_dataset.length > 9) {
                        dispatch(failure(alertActions.ALERT_ERROR, 'Please restrict your selection to 10 datasets.'));
                        return false;
                    }
                }

                if (property === "attribute_ids") {
                    const number_attribute = (widget.attribute_ids instanceof Array) ? widget.attribute_ids : [];
                    if (number_attribute.length > 49) {
                        dispatch(failure(alertActions.ALERT_ERROR, 'Please restrict your selection to 50 attributes.'));
                        return false;
                    }
                }
            }
        }
        if (showDatasource) {
            if (property === "datasource_id") {
                widget.dataset_ids = [];
                widget.attribute_ids = [];
                widget.rules = [];
            } else if (property === "dataset_ids") {
                widget.attribute_ids = [];
                widget.rules = [];
            }
        }
        if (isList) {
            if (property.includes('datasource')) {
                widget.datasource_id = (widget.datasource_id instanceof Array) ? widget.datasource_id : [];
            }
            if ((property in widget && !widget[property]) || !(property in widget) || (property in widget && !(widget[property] instanceof Array))) {
                widget[property] = [];
            }
            if (value.name === 'all') {
                if (property.includes('datasource')) {
                    widget.isAllDatasourceSelected = Boolean(!widget.isAllDatasourceSelected);
                    const selectedItems = datasourceList.map((item) => { return { ...item, isSelected: widget.isAllDatasourceSelected }; });
                    widget[property] = widget.isAllDatasourceSelected ? selectedItems : [];
                } else if (property.includes('dataset')) {
                    widget.isAllDatasetSelected = Boolean(!widget.isAllDatasetSelected);
                    const selectedItems = datasetList.map((item) => { return { ...item, isSelected: widget.isAllDatasetSelected }; });
                    widget[property] = widget.isAllDatasetSelected ? selectedItems : [];
                } else if (property.includes('attribute')) {
                    widget.isAllAttributesSelected = Boolean(!widget.isAllAttributesSelected);
                    const selectedItems = attributeList.map((item) => { return { ...item, isSelected: widget.isAllAttributesSelected }; });
                    widget[property] = widget.isAllAttributesSelected ? selectedItems : [];
                } else if (property.includes('rules')) {
                    widget.isAllRulesSelected = Boolean(!widget.isAllRulesSelected);
                    let selectedItems = [];
                    if (showDqRules) {
                        selectedItems = dqscoreRules.map((item) => { return { ...item, isSelected: widget.isAllRulesSelected }; });
                    }
                    if (showRules) {
                        selectedItems = profileRules.map((item) => { return { ...item, isSelected: widget.isAllRulesSelected }; });
                    }
                    widget[property] = widget.isAllRulesSelected ? selectedItems : [];
                    widget['value_type'] = '';
                }
            } else {
                if (property.includes('datasource')) {
                    widget.isAllDatasourceSelected = false;
                } else if (property.includes('dataset')) {
                    widget.isAllDatasetSelected = false;
                } else if (property.includes('attribute')) {
                    widget.isAllAttributesSelected = false;
                } else if (property.includes('rules')) {
                    widget.isAllRulesSelected = false;
                    widget['value_type'] = '';
                }
                const selectionLabel = (property === "rules") ? "name" : "id";
                const index = widget[property].findIndex((item) => item && value && item[selectionLabel] === value[selectionLabel]);
                if (index > -1) {
                    widget[property].splice(index, 1);
                } else {
                    if (value) {
                        value.isSelected = true;
                        widget[property].push(value);
                    }
                }
            }
        } else {
            if (property === 'attribute_ids') {
                widget['operation_value'] = '';
            } else if (property === 'category') {
                widget['date_category'] = '';
            }
            widget[property] = value;
        }


        if (property.includes('datasource')) {
            if (!widget.isAllDatasourceSelected) {
                if (widget && widget.dataset_ids) {
                    for (const dataset of widget.dataset_ids) {
                        const selectedDataset = widget.datasource_id.find((item) => item.id === dataset.source_id);
                        dataset.isSelected = Boolean(selectedDataset);
                    }
                    widget.dataset_ids = widget.dataset_ids.filter((item) => item.isSelected);
                }
                if (widget && widget.attribute_ids) {
                    for (const attribute of widget.attribute_ids) {
                        const selectedAttribute = widget.datasource_id.find((item) => item.id === attribute.source_id);
                        attribute.isSelected = Boolean(selectedAttribute);
                    }
                    widget.attribute_ids = widget.attribute_ids.filter((item) => item.isSelected);
                }
            }
        } else if (property.includes('dataset')) {
            if (!widget.isAllDatasetSelected) {
                if (widget && widget.attribute_ids) {
                    for (const attribute of widget.attribute_ids) {
                        const selectedAttribute = widget.dataset_ids.find((item) => item.id === attribute.dataset_id);
                        attribute.isSelected = Boolean(selectedAttribute);
                    }
                    widget.attribute_ids = widget.attribute_ids.filter((item) => item.isSelected);
                }
            }
        }

        setCurrentWidget(widget);
        if (property === 'metrics_type' || property === 'chart_type') {
            onChartTypeChange(widget);
        }
    }, [attributeList, currentWidget, datasetList, datasourceList, dqscoreRules, onChartTypeChange, profileRules, showDatasource, showDqRules, showRules]);

    const onSave = () => {
        if (currentWidget && currentWidget.metrics_type.id === "Drift_Alerts" && currentWidget?.chart_type === appConstants.driftChartTypes[3]) {
            if (!currentWidget.yaxis || (currentWidget.yaxis && currentWidget.yaxis.length !== 2)) {
                return false;
            }
            if (!currentWidget.datasource_id || (currentWidget.datasource_id && currentWidget.datasource_id.length === 0)) {
                return false;
            }
            if (!currentWidget.xaxis || currentWidget.xaxis === "") {
                return false;
            }
        }

        if (currentWidget && currentWidget.metrics_type.id === "Asset_level_dqscore") {
            if (currentWidget.scorelevel.id === "Datasource") {
                if (!currentWidget.datasource_id || (currentWidget.datasource_id && currentWidget.datasource_id.length === 0)) {
                    dispatch(failure(alertActions.ALERT_ERROR, 'Need to select atleast 1 datasource.'));
                    return false;
                }
            }

            if (currentWidget.scorelevel.id === "Dataset") {
                if (!currentWidget.datasource_id || !currentWidget.dataset_ids || (currentWidget.datasource_id && currentWidget.datasource_id.length === 0) || (currentWidget.dataset_ids && currentWidget.dataset_ids.length === 0)) {
                    dispatch(failure(alertActions.ALERT_ERROR, 'Need to select atleast 1 datasource and 1 dataset.'));
                    return false;
                }
            }

            if (currentWidget.scorelevel.id === "Attribute") {
                if (!currentWidget.datasource_id || !currentWidget.dataset_ids || !currentWidget.attribute_ids || (currentWidget.datasource_id && currentWidget.datasource_id.length === 0) || (currentWidget.dataset_ids && currentWidget.dataset_ids.length === 0) || (currentWidget.attribute_ids && currentWidget.attribute_ids.length === 0)) {
                    dispatch(failure(alertActions.ALERT_ERROR, 'Need to select atleast 1 datasource and 1 dataset and 1 attribute.'));
                    return false;
                }
            }
        }

        if (currentWidget && currentWidget.metrics_type.id === "Record_count") {
            if (currentWidget.scorelevel.id === "Datasource") {
                if (!currentWidget.datasource_id || (currentWidget.datasource_id && currentWidget.datasource_id.length === 0)) {
                    dispatch(failure(alertActions.ALERT_ERROR, 'Need to select atleast 1 datasource.'));
                    return false;
                }
            }

            if (currentWidget.scorelevel.id === "Dataset") {
                if (!currentWidget.datasource_id || !currentWidget.dataset_ids || (currentWidget.datasource_id && currentWidget.datasource_id.length === 0) || (currentWidget.dataset_ids && currentWidget.dataset_ids.length === 0)) {
                    dispatch(failure(alertActions.ALERT_ERROR, 'Need to select atleast 1 datasource and 1 dataset.'));
                    return false;
                }
            }

            if (currentWidget.scorelevel.id === "Attribute") {
                if (!currentWidget.datasource_id || !currentWidget.dataset_ids || !currentWidget.attribute_ids || (currentWidget.datasource_id && currentWidget.datasource_id.length === 0) || (currentWidget.dataset_ids && currentWidget.dataset_ids.length === 0) || (currentWidget.attribute_ids && currentWidget.attribute_ids.length === 0)) {
                    dispatch(failure(alertActions.ALERT_ERROR, 'Need to select atleast 1 datasource and 1 dataset and 1 attribute.'));
                    return false;
                }
            }
        }

        onSaveSettings(currentWidget);
    };

    const getDriftyAxisOptions = (xAxisValue = []) => {
        if (xAxisValue.includes("Attribute")) {
            return ["Rules"];
        }
        return ["Attribute", "Rules"];
    };

    const getDriftOptions = (yAxis) => {
        if (yAxis.length === 0) {
            return [...appConstants.heatMapAxis];
        } else if (yAxis.includes("Datasource")) {
            return ["Dataset", "Attribute", "Drift Rules"];
        } else if (yAxis.includes("Dataset")) {
            return ["Attribute", "Drift Rules"];
        }
        return [];
    };

    const getMaskedDatasetName = (name) => {
        if (name.length > 10) {
            const maxCharacters = 5;
            const firstFiveChars = name.substring(0, maxCharacters);
            const lastFiveChars = name.substring(name.length - maxCharacters, name.length);
            name = `${firstFiveChars}****${lastFiveChars}`;
        }
        return name;
    };

    // Update selected attributes
    let attributeNames = [];
    if (attributeList) {
        attributeNames = attributeList.map((attribute) => attribute.name);
        attributeNames = [...new Set(attributeNames)];
        attributeList = attributeList.map((attribute) => { return { ...attribute, attributeName: `${getMaskedDatasetName(attribute.dataset_name)}.${attribute.name}` }; });
    }

    if (datasourceList && (currentWidget?.datasource_id instanceof Array)) {
        for (const datasource of datasourceList) {
            const selectedDatasource = currentWidget?.datasource_id.find((item) => item.id === datasource.id);
            datasource.isSelected = Boolean(selectedDatasource);
        }
    }

    if (datasetList && (currentWidget?.dataset_ids instanceof Array)) {
        for (const dataset of datasetList) {
            const selectedDataset = currentWidget?.dataset_ids.find((item) => item.id === dataset.id);
            dataset.isSelected = Boolean(selectedDataset);
        }
    }

    if (attributeList && (currentWidget?.attribute_ids instanceof Array)) {
        for (const attribute of attributeList) {
            const selectedAttribute = currentWidget?.attribute_ids.find((item) => item.id === attribute.id);
            attribute.isSelected = Boolean(selectedAttribute);
        }
    }

    if (showRules && profileRules) {
        for (const rule of profileRules) {
            const selectedAttribute = currentWidget?.rules.find((item) => item.name === rule.name);
            rule.isSelected = Boolean(selectedAttribute);
        }
    }

    if (showDqRules && dqscoreRules) {
        for (const rule of dqscoreRules) {
            const selectedAttribute = currentWidget?.rules.find((item) => item.name === rule.name);
            rule.isSelected = Boolean(selectedAttribute);
        }
    }

    return (
        <ValidatorForm name="Setting" onSubmit={() => onSave()} className={classes.container}>
            <Grid container className={classnames(classes.widgetData, classes.formControlMargin)} justify="space-between" alignItems="flex-start">
                <Grid item className={classes.widgtDataContainer}>
                    <Grid container spacing={2} direction="column" justify="flex-start" alignItems="stretch">
                        <Grid item>
                            <TextField
                                label="Name"
                                name="title"
                                value={currentWidget?.name ?? ''}
                                fullWidth
                                onChange={(event) => onChangeSettings("name", event.target.value)}
                            />
                        </Grid>
                        <Grid item>
                            <AutoCompleteInput
                                name="metrics_type"
                                options={sortTable(metricsTypes, "asc", "name")}
                                value={currentWidget?.metrics_type ?? ''}
                                getOptionLabel={
                                    (option) => {
                                        if (option && option.name) {
                                            return option.name;
                                        }
                                        return option;
                                    }
                                }
                                renderInput={
                                    (params) =>
                                        <TextField {...params}
                                            label="Metrics Type"
                                            fullWidth
                                        />
                                }
                                onChange={(_, newValue) => onChangeSettings("metrics_type", newValue)}
                                fullWidth
                            />
                        </Grid>
                        {
                            currentWidget && currentWidget.metrics_type && currentWidget.metrics_type?.id === "Drift_Alerts" &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="chart_type"
                                    options={appConstants.driftChartTypes}
                                    value={currentWidget.chart_type}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Chart Type"
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangeSettings("chart_type", newValue)}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            showLevel &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="level"
                                    options={['Attributes', 'Datasets', 'Records']}
                                    value={currentWidget?.level ?? ''}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Level"
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangeSettings("level", newValue)}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            currentWidget && currentWidget.metrics_type && currentWidget.metrics_type?.id === "Drift_Alerts" && currentWidget?.chart_type === appConstants.driftChartTypes[3] &&
                            <Grid item>
                                <AutoCompleteCheckBox
                                    value={currentWidget && currentWidget.datasource_id ? currentWidget.datasource_id : []}
                                    selectionData={currentWidget && currentWidget.datasource_id ? currentWidget.datasource_id : []}
                                    availableList={sortTable(datasourceList ? datasourceList : [], "asc", "name")}
                                    displayValue="name"
                                    onChange={(value) => onChangeSettings("datasource_id", value, true)}
                                    onSearch={(value) => loadDataSources(currentWidget, value)}
                                    fullWidth
                                    label="Datasource"
                                />
                            </Grid>
                        }
                        {
                            showDatasource &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="datasource_id"
                                    options={sortTable(datasourceList ? datasourceList : [], "asc", "name")}
                                    value={currentWidget?.datasource_id}
                                    getOptionLabel={(option) => (option ? option.name : '')}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Datasource"
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangeSettings("datasource_id", newValue)}
                                    onInputChange={(_, value) => loadDataSources(currentWidget, value)}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            showMultiSelectDatasource &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="scorelevel"
                                    options={sortTable(leveldqscore ? leveldqscore : [], "asc", "name")}
                                    value={currentWidget?.scorelevel}
                                    getOptionLabel={(option) => (option ? option.name : '')}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label={currentWidget && currentWidget.metrics_type && currentWidget.metrics_type.id && currentWidget.metrics_type.id === "Record_count" ? "Record Count Level" : "DQ Score Level"}
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangescoreSettings("scorelevel", newValue)}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            showMultiSelectDatasource &&
                            <Grid item>
                                <AutoCompleteCheckBox
                                    value={currentWidget && currentWidget.datasource_id ? currentWidget.datasource_id : []}
                                    selectionData={currentWidget && currentWidget.datasource_id ? currentWidget.datasource_id : []}
                                    availableList={sortTable(datasourceList ? datasourceList : [], "asc", "name")}
                                    displayValue="name"
                                    onChange={(value) => onChangeSettings("datasource_id", value, true)}
                                    onSearch={(value) => loadDataSources(currentWidget, value)}
                                    fullWidth
                                    label="Datasources"
                                    noMargin
                                    isHideSelectedAllCount
                                    selectedAll
                                />
                            </Grid>
                        }
                        {
                            showDataset &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="dataset_id"
                                    options={sortTable(datasetList ? datasetList : [], "asc", "name")}
                                    value={currentWidget && currentWidget.dataset_ids ? currentWidget.dataset_ids : {}}
                                    getOptionLabel={(option) => (option ? option.name : '')}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Dataset"
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangeSettings("dataset_ids", newValue)}
                                    onInputChange={(_, value) => loadDatasets(currentWidget, value)}
                                    onOpen={() => loadDatasets(currentWidget, '')}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            showMultiSelectDataset &&
                            <Grid item>
                                <AutoCompleteCheckBox
                                    value={currentWidget && currentWidget.dataset_ids ? currentWidget.dataset_ids : []}
                                    selectionData={currentWidget && currentWidget.dataset_ids ? currentWidget.dataset_ids : []}
                                    availableList={sortTable(datasetList ? datasetList : [], "asc", "name")}
                                    displayValue="name"
                                    onChange={(value) => onChangeSettings("dataset_ids", value, true)}
                                    onSearch={(value) => loadDatasets(currentWidget, value)}
                                    fullWidth
                                    label="Dataset"
                                    noMargin
                                    isHideSelectedAllCount
                                    selectedAll={showMultiSelectDatasource ? showMultiSelectDatasource : false}
                                />
                            </Grid>
                        }
                        {
                            showSingleSelectAttributes &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="attribute_ids"
                                    options={sortTable(attributeList ? attributeList : [], "asc", "name")}
                                    value={currentWidget && currentWidget.attribute_ids ? currentWidget.attribute_ids : {}}
                                    getOptionLabel={(option) => (option && option.name ? `${option.name}` : '')}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Attributes"
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangeSettings("attribute_ids", newValue)}
                                    onInputChange={(_, value) => loadAttributes(currentWidget, value)}
                                    onOpen={() => loadAttributes(currentWidget, '')}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            (showAttributes || showAttributeNames) &&
                            <Grid item>
                                <AutoCompleteCheckBox
                                    value={currentWidget && currentWidget.attribute_ids ? currentWidget.attribute_ids : []}
                                    selectionData={currentWidget && currentWidget.attribute_ids ? currentWidget.attribute_ids : []}
                                    availableList={sortTable(attributeList ? attributeList : [], "asc", "name")}
                                    displayValue="attributeName"
                                    onChange={(value) => onChangeSettings("attribute_ids", value, true)}
                                    onSearch={(value) => loadAttributes(currentWidget, value)}
                                    fullWidth
                                    label="Attributes"
                                    noMargin
                                    isHideSelectedAllCount
                                    selectedAll={showMultiSelectDatasource ? showMultiSelectDatasource : false}
                                />
                            </Grid>
                        }
                        {
                            showRules &&
                            <Grid item>
                                <AutoCompleteCheckBox
                                    value={currentWidget && currentWidget.rules ? currentWidget.rules : []}
                                    selectionData={currentWidget && currentWidget.rules ? currentWidget.rules : []}
                                    availableList={sortTable(profileRules ? profileRules : [], "asc", "name")}
                                    displayValue="name"
                                    onChange={(value) => onChangeSettings("rules", value, true)}
                                    onSearch={(value) => loadProfileRules(currentWidget, value)}
                                    fullWidth
                                    label="Rules"
                                    noMargin
                                />
                            </Grid>
                        }
                        {
                            showDqRules &&
                            <Grid item>
                                <AutoCompleteCheckBox
                                    value={currentWidget && currentWidget.rules ? currentWidget.rules : []}
                                    selectionData={currentWidget && currentWidget.rules ? currentWidget.rules : []}
                                    availableList={sortTable(dqscoreRules ? dqscoreRules : [], "asc", "name")}
                                    displayValue="name"
                                    onChange={(value) => onChangeSettings("rules", value, true)}
                                    onSearch={(value) => loadDQScoreRules(currentWidget, value)}
                                    fullWidth
                                    label="Rules"
                                    noMargin
                                />
                            </Grid>
                        }

                        {
                            showOperationValue &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="operation_value"
                                    options={sortTable(currentWidget?.attribute_ids?.datatype === 'Integer' ? operationOptions : [operationOptions[0]], "asc", "name")}
                                    value={currentWidget && currentWidget.operation_value ? currentWidget.operation_value : ''}
                                    getOptionLabel={(option) => (option ? option : '')}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Operation Value"
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangeSettings("operation_value", newValue)}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            showOperationValue &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="value_type"
                                    options={sortTable(['Valid Count', 'Invalid Count'], "asc", "name")}
                                    value={currentWidget && currentWidget.value_type ? currentWidget.value_type : ''}
                                    getOptionLabel={(option) => (option ? option : '')}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Value Type"
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangeSettings("value_type", newValue)}
                                    fullWidth
                                    disabled={currentWidget ? currentWidget.rules && currentWidget.rules.length === 0 : true}
                                />
                            </Grid>
                        }
                        {
                            recordLevels &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="recordLevel"
                                    options={sortTable(recordLevel ? recordLevel : [], "asc", "name")}
                                    value={currentWidget?.recordLevel}
                                    getOptionLabel={(option) => (option ? option.name : '')}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Records Level"
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangescoreSettings("recordLevel", newValue)}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            currentWidget && currentWidget.metrics_type && currentWidget.metrics_type?.id === "Drift_Alerts" && currentWidget?.chart_type === appConstants.driftChartTypes[3] &&
                            <Fragment>
                                <Grid item>
                                    <AutoCompleteInput
                                        name="yaxis"
                                        options={getDriftOptions((currentWidget?.yaxis instanceof Array) ? currentWidget.yaxis : [])}
                                        value={(currentWidget?.yaxis instanceof Array) ? currentWidget.yaxis : []}
                                        renderInput={
                                            (params) =>
                                                <TextField {...params}
                                                    label="Y-Axis"
                                                    error={!currentWidget.yaxis || (currentWidget.yaxis && currentWidget.yaxis.length !== 2)}
                                                    helperText={!currentWidget.yaxis || (currentWidget.yaxis && currentWidget.yaxis.length !== 2) ? "Please select Minimum 2 values" : ""}
                                                    fullWidth
                                                />
                                        }
                                        freeSolo={!(currentWidget.yaxis && currentWidget.yaxis.length > 1)}
                                        getOptionDisabled={(options) => (currentWidget.yaxis && currentWidget.yaxis.length > 1)}
                                        onChange={(_, newValue) => onChangeSettings("yaxis", newValue)}
                                        fullWidth
                                        multiple
                                        openOnFocus
                                        filterSelectedOptions
                                        disableCloseOnSelect
                                    />
                                </Grid>
                                <Grid item>
                                    <AutoCompleteInput
                                        name="xaxis"
                                        options={getDriftyAxisOptions((currentWidget?.yaxis instanceof Array) ? currentWidget.yaxis : [])}
                                        value={currentWidget && currentWidget.xaxis ? currentWidget.xaxis : ""}
                                        renderInput={
                                            (params) =>
                                                <TextField {...params}
                                                    label="X-Axis"
                                                    fullWidth
                                                    error={!currentWidget.xaxis || currentWidget.xaxis === ""}
                                                    helperText={!currentWidget.xaxis || currentWidget.xaxis === "" ? "X Axis is required" : ""}
                                                />
                                        }
                                        onChange={(_, newValue) => onChangeSettings("xaxis", newValue)}
                                        fullWidth
                                    />
                                </Grid>
                            </Fragment>
                        }
                        {
                            showXaxis && showOperationValue &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="xaxis"
                                    options={sortTable(attributeList ? attributeList : [], "asc", "name")}
                                    getOptionLabel={(option) => (option && option.name ? `${option.name}` : '')}
                                    value={currentWidget?.xaxis ?? ''}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="X-Axis"
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangeSettings("xaxis", newValue)}
                                    onInputChange={(_, value) => loadAttributes(currentWidget, value)}
                                    onOpen={() => loadAttributes(currentWidget, '')}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            showXaxis && !showOperationValue &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="xaxis"
                                    options={['Attributes', 'Rules']}
                                    value={currentWidget?.xaxis ?? ''}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="X-Axis"
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangeSettings("xaxis", newValue)}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            showYaxis &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="yaxis"
                                    options={sortTable(attributeNames ? attributeNames : [], "asc", "name")}
                                    value={currentWidget?.yaxis ?? ''}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Y-Axis"
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangeSettings("yaxis", newValue)}
                                    onInputChange={(_, value) => loadAttributes(currentWidget, value)}
                                    onOpen={() => loadAttributes(currentWidget, "")}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            showOperationValue && (currentWidget?.xaxis?.datatype === "DateTime" || currentWidget?.xaxis?.datatype === "Date") &&
                            <Grid item>
                                <TextField
                                    label="Year"
                                    name="data_year"
                                    value={currentWidget && currentWidget.data_year ? currentWidget.data_year : ''}
                                    fullWidth
                                    onChange={(event) => onChangeSettings("data_year", event.target.value)}
                                />
                            </Grid>
                        }
                        {
                            showXaxis && showOperationValue &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="category"
                                    options={sortTable(attributeList ? attributeList : [], "asc", "name")}
                                    getOptionLabel={(option) => (option && option.name ? `${option.name}` : '')}
                                    value={currentWidget?.category ?? ''}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Category"
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangeSettings("category", newValue)}
                                    onInputChange={(_, value) => loadAttributes(currentWidget, value)}
                                    onOpen={() => loadAttributes(currentWidget, '')}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            showOperationValue && (currentWidget?.category?.datatype === "DateTime" || currentWidget?.category?.datatype === "Date") &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="date_category"
                                    options={['Date', 'Month', 'Quarter', "Year"]}
                                    value={currentWidget?.date_category ?? ''}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Date Type Category"
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangeSettings("date_category", newValue)}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            showRange &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="range"
                                    options={['15', '30', '45', '60']}
                                    value={currentWidget && currentWidget.range ? currentWidget.range : "15"}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Date Range"
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangeSettings("range", newValue)}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            currentWidget && currentWidget.metrics_type && currentWidget.metrics_type?.id === "Drift_Alerts" && currentWidget?.chart_type === appConstants.driftChartTypes[3] &&
                            <Grid item>
                                <TextBox
                                    label="Date Range"
                                    name="range"
                                    value={currentWidget && currentWidget.range ? currentWidget.range : appConstants.heatMapRange[0].value}
                                    select
                                    onChange={(event, value) => onChangeSettings("range", event.target.value)}
                                    SelectProps={
                                        {
                                            MenuProps: {
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "center"
                                                },
                                                getContentAnchorEl: null
                                            }
                                        }
                                    }
                                    fullWidth
                                >
                                    {
                                        appConstants.heatMapRange.map((option, index) =>
                                            <MenuItem key={`menuProperty_Options_${index}`} value={option.value} className={classes.menuItem}>
                                                {option.name}
                                            </MenuItem>
                                        )
                                    }

                                </TextBox>
                            </Grid>
                        }
                        {
                            currentWidget && currentWidget.metrics_type && currentWidget.metrics_type?.id === "Drift_Alerts" && currentWidget?.chart_type === appConstants.driftChartTypes[2] &&
                            <Grid item>
                                <AutoCompleteInput
                                    name="bins"
                                    options={['10', '25', '50', '100']}
                                    value={currentWidget && currentWidget.bins ? currentWidget.bins : '10'}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Bin Range"
                                                fullWidth
                                            />
                                    }
                                    onChange={(_, newValue) => onChangeSettings("bins", newValue)}
                                    fullWidth
                                />
                            </Grid>
                        }
                        {
                            currentWidget && currentWidget.metrics_type && currentWidget.metrics_type?.id === "Drift_Alerts" && currentWidget?.chart_type === appConstants.driftChartTypes[3] &&
                            <Grid item>
                                <TextBox
                                    label="Select Order"
                                    name="filter_count"
                                    value={currentWidget.filter_count}
                                    onChange={(event) => onChangeSettings("filter_count", event.target.value)}
                                    validators={['required', 'minNumber:1', 'maxNumber:50', 'matchRegexp:[0-9]$']}
                                    errorMessages={["Select Order is required", "Minimum value greater than 0", "Maximum value less than 50", "Enter Valid Number"]}
                                    fullWidth
                                />
                            </Grid>
                        }
                        <Grid item>
                            <Grid container justify="space-between" spacing={3} className={classes.bottomToggle}>
                                {
                                    showFilterNull &&
                                    <Grid item xs={6}>
                                        <Grid container justify="flex-start">
                                            <Grid item xs={8}>
                                                <Typography className={classes.formLabel}>
                                                    Show Null
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControlLabel
                                                    className={classes.formLabel}
                                                    control={
                                                        <Switch
                                                            color="secondary"
                                                            size="small"
                                                            name="show_null"
                                                            value="show_null"
                                                            checked={Boolean(currentWidget?.show_null)}
                                                            onChange={(event) => onChangeSettings("show_null", !currentWidget.show_null)}
                                                        />
                                                    }
                                                    label="on"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    showFilterEmpty &&
                                    <Grid item xs={6}>
                                        <Grid container justify="flex-end">
                                            <Grid item xs={8}>
                                                <Typography className={classnames(classes.formLabel, classes.toogleText)}>
                                                    Show Empty
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControlLabel
                                                    className={classes.formLabel}
                                                    control={
                                                        <Switch
                                                            color="secondary"
                                                            size="small"
                                                            name="show_empty"
                                                            value="show_empty"
                                                            checked={Boolean(currentWidget?.show_empty)}
                                                            onChange={(event) => onChangeSettings("show_empty", !currentWidget.show_empty)}
                                                        />
                                                    }
                                                    label="on"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item style={{ padding: 8 }}>
                    <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">
                        <Button className={classes.tabBtn} type="submit" variant="contained" color="primary">
                            Save
                        </Button>
                        {
                            currentWidget && !currentWidget.isNew &&
                            <Button onClick={() => onCancelSettings(currentWidget.i)} style={{ marginLeft: '10px' }} variant="contained" className={classnames(classes.cancelBtn, classes.tabBtn)}>
                                Cancel
                            </Button>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
};

WidgetSettingsView.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    widget: PropTypes.object,
    onChangeSettings: PropTypes.func,
    onSaveSettings: PropTypes.func,
    onCancelSettings: PropTypes.func
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(WidgetSettingsView);