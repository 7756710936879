import React, { useState } from 'react';
import classNames from 'classnames';
import {
    withStyles, Dialog, DialogTitle, DialogContent, Grid, Typography, IconButton, Slide,
    Table, TableHead, TableBody, TableRow, TableCell
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import UserAuditLogDetailsStyles from './UserauditlogDetailsStyles.jsx';
import { Close } from '@material-ui/icons';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import { ExportJsonCsv } from 'react-export-json-csv';
import Loader from '../../components/Loaders/Loader.jsx';

const Transition = React.forwardRef(Object.assign((props, ref) => <Slide direction="up" ref={ref} {...props} />), { displayName: 'Transition' });
const UserAuditLogDetails = (props) => {
    const { classes, open, onClose, userAudit, closepop, isLoadingcontent, theme } = props;
    const [downloadHeader] = useState([{ 'key': 'created_date', 'name': 'created_date' }, { 'key': 'audittype', 'name': 'audittype' }, { 'key': 'attribute__name', 'name': 'attribute' }, { 'key': 'dataset__name', 'name': 'assets' }, { 'key': 'notification_text', 'name': 'notification_text' }]);


    const getHeaders = () => {
        return [{ name: 'created_date', value: 'created_date' }, { name: 'Audit type', value: 'audittype' }, { name: 'Attribute', value: 'attribute' }, { name: 'Assets', value: 'Assets' }, { name: 'Notification Text', value: 'Notification text' }];
    };

    return (
        <Dialog open={open}
            maxWidth="md"
            fullWidth
            TransitionComponent={Transition}
            onClose={onClose}
        >
            <DialogTitle>
                <Grid container justify="space-between" alignItems="center">
                    {isLoadingcontent && <Loader />}
                    <Grid item>
                        <Typography variant="h5">
                            {'User Audits'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ExportJsonCsv headers={downloadHeader} items={userAudit}>
                            <ToolTipComponent title="Download" arrow>
                                <IconButton className={classes.padding6}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 21 21">
                                        <g id="download_4_" data-name="download (4)" transform="translate(-1.5 -1.5)">
                                            <path id="Path_14400" data-name="Path 14400" d="M22,23.714v2.857A1.43,1.43,0,0,1,20.571,28H3.428A1.43,1.43,0,0,1,2,26.572V23.714a.714.714,0,0,1,1.428,0v2.857H20.571V23.714a.714.714,0,0,1,1.429,0Z" transform="translate(0 -6)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                            <path id="Path_14401" data-name="Path 14401" d="M8.21,12.5a.714.714,0,1,1,1.01-1.01L13,15.275V2.714a.714.714,0,1,1,1.428,0V15.275l3.781-3.78a.714.714,0,1,1,1.01,1.01l-5,5a.715.715,0,0,1-1.011,0Z" transform="translate(-1.715)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                        </g>
                                    </svg>
                                </IconButton>
                            </ToolTipComponent>
                        </ExportJsonCsv>
                        <IconButton className={classes.nopadding} onClick={() => closepop()}>
                        <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                getHeaders().map((header, index) =>
                                    <TableCell className={classes.tableHeadCell} key={`header_${index}`}>
                                        {header.name}
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            userAudit && userAudit.map((data, index) =>
                                <TableRow key={`row_${index}`}>
                                    <TableCell className={classes.tableCell} key={`header_${index}`}>
                                        {data.created_date}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} key={`header_${index}`}>
                                        {data.audittype}
                                    </TableCell>
                                    <TableCell title={data.attribute__name} className={classNames(classes.tableCell, classes.logntext)} key={`header_${index}`}>
                                        {data.attribute__name}
                                    </TableCell>
                                    <TableCell title={data.dataset__name} className={classNames(classes.tableCell, classes.logntext)} key={`header_${index}`}>
                                        {data.dataset__name}
                                    </TableCell>
                                    <TableCell title={data.notification_text} className={classNames(classes.tableCell, classes.logntext)} key={`header_${index}`}>
                                        {data.notification_text}
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    );
};

UserAuditLogDetails.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    userAudit: PropTypes.object,
    open: PropTypes.bool,
    isLoadingcontent: PropTypes.bool,
    onClose: PropTypes.func,
    closepop: PropTypes.func
};

export default withStyles((theme) => ({
    ...UserAuditLogDetailsStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(UserAuditLogDetails);