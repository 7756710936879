import { combineReducers } from 'redux';

// Import Reducers
import { accountReducer } from './accountReducer';
import { alertReducer } from './alertReducer';
import { datasourceReducer } from './datasourceReducer';
import { datasetReducer } from './datasetReducer';
import { settingReducer } from './settingReducer';
import { notificationReducer } from './notificationReducer';
import { masterReducer } from './masterReducer';
import { domainReducer } from './domainReducer';
import { dataPreparationReducer } from './dataPreparationReducer';
import { modelReducer } from './modelReducer';
import { commonReducer } from './commonReducer';
import lineageReducer from './lineageReducer';
import { metricReducer } from './metricReducer';
import { catalogReducer } from './catalogReducer';
import { searchReducer } from './searchReducer';

export default combineReducers({
    account: accountReducer,
    alert: alertReducer,
    datasource: datasourceReducer,
    dataset: datasetReducer,
    setting: settingReducer,
    notification: notificationReducer,
    master: masterReducer,
    domain: domainReducer,
    dataPreparation: dataPreparationReducer,
    model: modelReducer,
    common: commonReducer,
    lineage: lineageReducer,
    metric: metricReducer,
    catalog: catalogReducer,
    search: searchReducer
});