import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import classnames from 'classnames';
import DashboardStyles from './DashboardStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import MostView from './MostView.jsx';
import { getDashboardViews } from '../../actions/scheduleActions';
import noprofile from "../../assets/images/np_profile_data.jpg";


const DashboardMostView = (props) => {
    const { classes } = props;
    const dispatch = useDispatch();
    const [mostViewData, setMostViewData] = useState({});

    const getDashboardSourceViews = useCallback(() => {
        dispatch(getDashboardViews()).then((response) => {
            if (response) {
                const metrics = { ...response, attributes: response.attributes ? response.attributes.slice(0, 3) : [] };
                setMostViewData({ ...metrics });
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getDashboardSourceViews();
    }, [getDashboardSourceViews]);
    return (
        <Grid className={classnames(classes.mostviewContainer, classes.section)}>
            <Typography variant="h4" component="h4">
                Most Viewed
            </Typography>
            {
                mostViewData && (mostViewData.datasources?.length > 0 || mostViewData.datasets?.length > 0 || mostViewData.attributes?.length > 0) ?
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <Typography className={classes.viewTitleTxt}>
                                Datasource
                            </Typography>
                            {
                                mostViewData && mostViewData.datasources && mostViewData.datasources.map((datasource, index) =>
                                    <MostView type="datasource" viewData={datasource} key={index} />
                                )
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.viewTitleTxt}>
                                Dataset
                            </Typography>
                            {
                                mostViewData && mostViewData.datasets && mostViewData.datasets.map((dataset, index) =>
                                    <MostView type="dataset" viewData={dataset} key={index} />
                                )
                            }
                        </Grid>
                        <Grid item xs={4}>
                            <Typography className={classes.viewTitleTxt}>
                                Attribute
                            </Typography>
                            {
                                mostViewData && mostViewData.attributes && mostViewData.attributes.map((attribute, index) =>
                                    <MostView type="attribute" viewData={attribute} key={index} />
                                )
                            }
                        </Grid>
                    </Grid> :
                    <Grid className={classnames(classes.noDataContainer, classes.noDataText)}>
                        <img src={noprofile} alt="" style={{ height: "50%" }} />
                        <Typography variant="h4" align="center" color="textSecondary">
                            No Data Found
                        </Typography>
                    </Grid>
            }
        </Grid>
    );
};

DashboardMostView.propTypes = {
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}))(DashboardMostView);