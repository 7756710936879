const CommentStyles = (theme) => ({
    listSection: {
        maxHeight: 'calc(100vh - 360px)',
        overflowY: 'auto',
        padding: '0px 10px',
        marginBottom: 10
    },
    commentSection: {
        position: 'relative',
        '& .options': {
            position: 'absolute',
            right: 2,
            top: 0,
            padding: 0,
            display: 'none'
        },
        '& :hover .options': {
            display: 'block'
        }
    },
    editText: {
        position: 'absolute',
        fontSize: 11,
        color: `${theme.palette.grey.darkGrey} !important`
    },
    commentInput: {
        backgroundColor: theme.palette.background.paper,
        '& .MuiInput-formControl': {
            padding: 10
        }
    },
    ratingContainer: {
        marginLeft: 20
    },
    commentName: {
        fontWeight: 500,
        maxWidth: 250
    },
    commentRateTitle: {
        fontWeight: 500,
        fontSize: 14,
        marginRight: 20
    },
    commentBox: {
        backgroundColor: '#eef1f2',
        padding: 10,
        marginLeft: 40,
        minHeight: 45,
        marginTop: 8,
        borderRadius: 5,
        position: 'relative',
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottom: 'none !important'
        }
    },
    commentEdit: {
        padding: '0px !important'
    },
    commentEditBox: {
        backgroundColor: '#ffffff',
        border: '1px solid #c8cbcc',
        borderRadius: '0px',
        padding: 8,
        '& .MuiInput-underline:before': {
            border: 'none !important'
        },
        '& .MuiInput-root': {
            paddingRight: 50
        }
    },
    commentText: {
        color: theme.palette.grey.dark,
        wordBreak: 'break-all'
    },
    alignCenter: {
        alignItems: 'center'
    },
    split: {
        marginLeft: 5,
        marginRight: 5
    },
    commentContainer: {
        backgroundColor: '#f6f8f9',
        padding: 30
    },
    addCommentContainer: {
        // marginLeft: 40
    },
    editActions: {
        marginTop: 8,
        position: 'absolute',
        right: 12,
        top: 0
    },
    emptyComment: {
        display: 'none'
    },
    commentWidth: {
        marginBottom: 10,
        minWidth: '50%',
        width: '100%'
    }
});

export default CommentStyles;