import React, { useCallback, useEffect, useState, memo } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import ChartStyles from './ChartStyles.jsx';
import driftHigh from '../../assets/images/drift_high.png';
import driftMedium from '../../assets/images/drift_medium.png';
import driftLow from '../../assets/images/drift_low.png';
import moment from 'moment-timezone';
import { wrapText } from "../../helpers/appHelpers";

const TrendingChart = (props) => {
    const { classes, chartData, theme, createdDate, title, showYAxis, chartClassName, indicatorType, domain, loadChart } = props;
    const [analysis, setAnalysis] = useState([]);

    const loadChartData = useCallback((analysisData) => {
        setAnalysis([...analysisData]);
    }, []);

    useEffect(() => {
        loadChartData([...chartData]);
    }, [chartData, loadChartData]);

    const getFill = useCallback((alert) => {
        if (alert === "") {
            return theme.palette.background.paper;
        } else if (alert === "high") {
            return driftHigh;
        } else if (alert === "medium") {
            return driftMedium;
        }
        return driftLow;
    }, [theme.palette.background.paper]);


    useEffect(() => {
        if (analysis && analysis.length !== 0 && !loadChart) {
            let trendingData = analysis.map((data) => {
                return {
                    month: new Date(data.end_time),
                    score: data.dqscore,
                    alert: data.alert ? data.alert : ""
                };
            });

            if (trendingData.length === 1) {
                trendingData.unshift({
                    month: new Date(createdDate),
                    score: 0,
                    alert: ""
                });
            }

            trendingData = trendingData.sort((a, b) => a.month - b.month);
            const margin = { top: 40, right: 20, bottom: 50, left: 40 };
            const height = 100;
            let width = 960 - margin.left - margin.right;
            d3.select(`.${chartClassName} > *`).remove();
            const svg = d3.select(`.${chartClassName}`)
                .append("svg")
                .attr("width", "100%")
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("width", (_, i, nodes) => {
                    width = nodes[i].parentNode.clientWidth - margin.left;
                    return width;
                })
                .attr("transform",
                    "translate(" + margin.left + "," + margin.top + ")")
                .attr("height", height + margin.top + margin.bottom);

            const x = d3.scaleTime()
                .domain(d3.extent(trendingData, (d) => { return d.month; }))
                .rangeRound([10, width - 10]);

            svg.append("g")
                .attr("class", "axis-x")
                .attr("color", theme.palette.chartColors.axis)
                .attr("transform", "translate(0," + height + ")")
                .call(d3.axisBottom(x));

            const y = d3.scaleLinear()
                .range([height, 0])
                .domain(domain);
            svg.append("g")
                .attr("class", "axis-y")
                .attr("color", theme.palette.chartColors.axis)
                .call(d3.axisLeft(y).ticks(4).tickPadding(5).tickSize(-width));

            svg.selectAll(".axis-x path").remove();
            svg.select('.axis-y path.domain').remove();
            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
            svg.selectAll(".axis-x .tick:first-of-type").attr('transform', 'translate(10.5,0)');
            svg.selectAll('.axis-x text').text((d) => moment(d).format('MM/DD/YYYY HH:mm:ss')).each((d, i, nodes) => {
                const textElement = d3.select(nodes[i]);
                const axisLength = textElement.node().getBoundingClientRect().width;
                wrapText(textElement, axisLength - 20);
            })
                .on("mouseover", (d, i, p) => {
                    // Show Tooltip
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    let x = 0;
                    let y = 0;
                    div.attr("transform", (_, i, nodes) => {
                        const mouseCoords = d3.mouse(nodes[i].parentNode);
                        let xCo = 0;
                        let yCo = 0;
                        if (mouseCoords[0] + 10 >= width * 0.80) {
                            xCo = mouseCoords[0] - parseFloat(div
                                .attr("width"));
                            yCo = mouseCoords[1] + 10;
                        } else {
                            xCo = mouseCoords[0] + 10;
                            yCo = mouseCoords[1];
                        }
                        x = isNaN(xCo) ? mouseCoords[0] : xCo;
                        y = isNaN(yCo) ? mouseCoords[1] : yCo;
                    });
                    d3.select(p[i]).style("opacity", 0.6);
                    div.transition().duration(200).style("opacity", 0.9);
                    div
                        .html(moment(d).format('MM/DD/YYYY HH:mm:ss'))
                        .style("left", x + "px")
                        .style("top", y + "px");
                }).on("mouseout", (_, i, p) => {
                    d3.select(p[i]).style("opacity", 1);
                    div.transition().duration(500).style("opacity", 0);
                });

            svg.selectAll(".axis-x .tick:last-of-type").attr('transform', `translate(${width - (margin.left + margin.right)},0)`);
            if (!showYAxis) {
                svg.selectAll('.axis-y .tick text').remove();
            }

            svg.append("path")
                .datum(trendingData)
                .attr("fill", theme.palette.chartColors.trending)
                .attr("fill-opacity", 0.3)
                .attr("stroke", "none")
                .attr("stroke-linejoin", "round")
                .attr("d", d3.area()
                    .curve(d3.curveMonotoneX)
                    .x(0)
                    .y0(height)
                    .y1((d) => { return y(d.score); })
                )
                .transition()
                .duration(2000)
                .attr("d", d3.area()
                    .curve(d3.curveMonotoneX)
                    .x((d) => { return x(d.month); })
                    .y0(height)
                    .y1((d) => { return y(d.score); })
                );
            svg.append("path")
                .datum(trendingData)
                .attr("fill", "none")
                .attr("stroke", theme.palette.chartColors.trending)
                .attr("stroke-width", 4)
                .attr("stroke-linejoin", "round")
                .attr("d", d3.line()
                    .curve(d3.curveMonotoneX)
                    .x(0)
                    .y((d) => { return y(d.score); })
                )
                .transition()
                .duration(2000)
                .attr("d", d3.line()
                    .curve(d3.curveMonotoneX)
                    .x((d) => { return x(d.month); })
                    .y((d) => { return y(d.score); })
                );

            d3.select(`.${chartClassName} > .tooltip`).remove();
            const div = d3.select(`.${chartClassName}`).append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            if (indicatorType === "circle") {
                svg.selectAll("myCircles")
                    .data(trendingData)
                    .enter()
                    .append("circle")
                    .attr("fill", theme.palette.background.paper)
                    .attr("stroke-width", 2)
                    .attr("stroke", theme.palette.chartColors.trending)
                    .attr("cx", 0)
                    .attr("cy", (d) => { return y(d.score); })
                    .attr("r", 4)
                    .on('mouseover', (d, i, nodes) => {
                        const rect = nodes[i].getBBox();
                        div.transition()
                            .duration(200)
                            .style("opacity", 0.9);
                        div.html(`'Time' : ${d.month}<br> DQScore : ${d.score}`)
                            .style("left", x(d.month) + "px")
                            .style("top", (rect.y - 30) + "px");
                    })
                    .on('mouseout', () => {
                        div.transition()
                            .duration(500)
                            .style("opacity", 0);
                    })
                    .transition()
                    .delay((d, i) => { return Number(i); })
                    .duration(2000)
                    .attr("cx", (d) => { return x(d.month); });
            } else {
                svg.selectAll("myCircles")
                    .data(trendingData)
                    .enter()
                    .append("image")
                    .attr("href", (d) => getFill(d.alert))
                    .attr("x", 0)
                    .on('mouseover', (d, i, nodes) => {
                        const rect = nodes[i].getBBox();
                        div.transition()
                            .duration(200)
                            .style("opacity", 0.9);
                        div.html(`'Time' : ${d.month}<br> Alerts : ${d.score}`)
                            .style("left", x(d.month) + "px")
                            .style("top", (rect.y - 30) + "px");
                    })
                    .on('mouseout', () => {
                        div.transition()
                            .duration(500)
                            .style("opacity", 0);
                    })
                    .transition()
                    .delay((d, i) => { return Number(i); })
                    .duration(2000)
                    .attr("x", (d) => { return x(d.month) - 10; })
                    .attr("y", (d) => { return y(d.score); });

            }
        }
    }, [analysis, chartClassName, chartData, createdDate, domain, getFill, indicatorType, loadChart, showYAxis, theme.palette.background.paper, theme.palette.chartColors.axis, theme.palette.chartColors.trending]);


    return (
        <ChartContainer
            title={title}
            chartData={chartData}>
            <Grid container className={classNames(classes.chartStyles, classes.barline, chartClassName)} />
        </ChartContainer>
    );
};

TrendingChart.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    theme: PropTypes.object,
    createdDate: PropTypes.string,
    title: PropTypes.string,
    showYAxis: PropTypes.bool,
    chartClassName: PropTypes.string,
    indicatorType: PropTypes.string,
    domain: PropTypes.array,
    loadChart: PropTypes.bool
};

export default memo(withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(TrendingChart));