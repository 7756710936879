import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography, IconButton, Switch, TableCell, TableContainer, TableHead, TableRow, Table, TableBody, TableSortLabel, ClickAwayListener, FormLabel, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import Search from '../TextBox/Search.jsx';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import Loader from '../Loaders/Loader.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';

import { createCustomReportSchedule, deleteCustomReportScheduleByReportId, deleteReportById, editReportReportStatus, getCustomReportScheduleByReportId, getDatasetAttributes, getReport, updateCustomReportScheduleById } from '../../actions/scheduleActions.js';
import Scheduler from '../Scheduler/Schedule.jsx';
import moment from 'moment-timezone';
import { appConstants } from '../../constants/appConstants.js';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';
import { sortTable } from '../../helpers/appHelpers.js';
import TextBox from '../TextBox/TextBox.jsx';
import { getOrganizationDatasources } from '../../actions/datasourceActions.js';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { getDatasetNames } from '../../actions/datasetActions.js';
import AutoCompleteCheckBox from '../AutoCompleteCheckBox/AutoCompleteCheckBox.jsx';

const ReportList = (props) => {
    const { classes, theme, onEdit, organizationId } = props;
    const dispatch = useDispatch();

    const zones = [{ name: moment.tz.guess() }, ...appConstants.TimeZones];

    const defaultParams = {
        "schedule_method": "Month",
        "start_profile": true,
        "start_curate": true,
        "week_day": ["Sunday"],
        "month_date": [moment(new Date()).format('DD')],
        "date": new Date(),
        "time": new Date(),
        "time_zone": zones[0] ? zones[0].name : ""
    };

    const [isLoading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [reports, setReport] = useState([]);
    // const userRole = useSelector(({ account }) => account.user.role);
    const [schedule, setSchedule] = useState({ open: false, anchorElement: null, reportId: 0 });
    const [selectedReport, setSelectedReport] = useState(null);

    const [scheduleData, setScheduleData] = useState({ ...defaultParams });
    const [showRemoveSchedule, setShowRemoveSchedule] = useState(false);
    const [isExistingSchedule, setExistingSchedule] = useState(false);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const [enableFilter, setEnableFilter] = useState(false);
    const [searchFilters, setSearchFilters] = useState({
        datasources: [],
        datasets: [],
        attributes: [],
        emailAlertType: '',
        status: ''
    });
    const [datasourceNames, setDatasourceNames] = useState([]);
    const [datasetNames, setDatasetNames] = useState([]);
    const [attributesNames, setAttributesNames] = useState([]);

    let datasourceList = useSelector(({ datasource }) => datasource.organizationSource);
    datasourceList = datasourceList.filter((elem) => elem.name.trim() !== '');

    let datasetList = useSelector(({ dataset }) => dataset.datasetNames);
    if (searchFilters.datasources.length > 0) {
        datasetList = datasetList.filter((elem) => searchFilters.datasources.some((item) => item === elem.source_id));
    }
    let attributesList = useSelector(({ dataset }) => dataset.attributeNames);
    if (searchFilters.datasources.length > 0 || searchFilters.datasets.length > 0) {
        if (searchFilters.datasources.length > 0) {
            attributesList = attributesList.filter((elem) => searchFilters.datasources.some((item) => item === elem.source_id));
        }
        if (searchFilters.datasets.length > 0) {
            attributesList = attributesList.filter((elem) => searchFilters.datasets.some((item) => item === elem.dataset_id));
        }
    }

    useEffect(() => {
        dispatch(getOrganizationDatasources()).then((response) => {
            if (response) {
                response = response.filter((elem) => elem.name.trim() !== '');
                setDatasourceNames(response);
            } else {
                setDatasourceNames([]);
            }
        });
        dispatch(getDatasetNames(0)).then((response) => {
            if (response) {
                setDatasetNames(response);
            } else {
                setDatasetNames([]);
            }
        });
        dispatch(getDatasetAttributes(0, 0)).then((response) => {
            if (response) {
                setAttributesNames(response);
            } else {
                setAttributesNames([]);
            }
        });
        fetchReport(searchFilters);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, searchFilters]);

    const onChangeOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    const deleteReportList = (reportId) => {
        const index = reports.findIndex((reportInfo) => reportInfo.id === reportId);
        if (index !== -1) {
            setLoading(true);
            reports.splice(index, 1);
            setReport([...reports]);
            dispatch(deleteReportById(reportId)).then(() => setLoading(false));
        }
        setSelectedReport(null);
    };

    const editReportList = (reportId, value) => {
        const index = reports.findIndex((reportInfo) => reportInfo.id === reportId);
        if (index !== -1) {
            setLoading(true);
            reports[index].is_active = value;
            setReport([...reports]);
            const requestParams = {
                is_active: value
            };
            dispatch(editReportReportStatus(reportId, requestParams)).then(() => setLoading(false));
        }
    };

    const fetchReport = (filter) => {
        setLoading(true);
        dispatch(getReport(filter, organizationId)).then((response) => {
            const datetimeFormat = 'MM/DD/YYYY HH:mm:ss';
            if (response && response.length > 0) {
                response.map((elem, index) => {
                    if (elem['last_run']) {
                        elem['last_run'] = moment(elem['last_run']).format(datetimeFormat);
                    }
                    elem.email_report_type = elem.email_report_type ? appConstants.emailReportType[elem.email_report_type - 1].name : "NA";
                    return elem;
                });
                setReport(response);
            } else {
                setReport([]);
            }
            setLoading(false);
        });
    };

    const openSchedule = (event, reportId) => {
        event.stopPropagation();
        const targetElem = event.target;
        dispatch(getCustomReportScheduleByReportId(reportId)).then((response) => {
            if (response && Object.keys(response).length !== 0) {
                let hour = response && response.day_week_month_time !== "" ? response.day_week_month_time : '';
                if (hour === 0) {
                    hour = "00";
                }
                const min = response && response.day_week_month_minitues ? response.day_week_month_minitues : '';
                let time = `${hour}:${min}`;
                const hasTime = (time.split(':').filter((p) => p.length > 0)).length > 0;
                if (!hasTime) {
                    time = Date.now();
                } else {
                    time = time ? moment(moment().tz(response.time_zone).format('YYYY-MM-DD') + ` ${time}`) : '';
                }
                let minutesInterval = 0;
                if (response.schedule_method === "Hour") {
                    minutesInterval = (response.minitues_interval !== "" ? parseInt(response.minitues_interval) / 60 : 0);
                } else {
                    minutesInterval = (response.minitues_interval !== "" ? response.minitues_interval : 0);
                }
                const schedule = {
                    "id": response.id,
                    "minitues_interval": minutesInterval,
                    "time_zone": response.time_zone,
                    "week_day": response.week_day !== "" ? response.week_day.split(',') : ["Sunday"],
                    "month_date": response.month_date !== "" ? response.month_date.split(',') : [moment(new Date()).format('DD')],
                    "schedule_method": response.schedule_method,
                    "date": moment(response.start_time).tz(response.time_zone),
                    "time": moment(response.start_time).tz(response.time_zone),
                    "day": time
                };
                setExistingSchedule(true);
                setScheduleData(schedule);
            }
            setSchedule({ open: true, anchorElement: targetElem, reportId: reportId });
        });
    };

    const createScheduler = (scheduler) => {
        if (scheduleData.id) {
            dispatch(updateCustomReportScheduleById(scheduleData.id, { ...scheduler, custom_report_id: schedule.reportId })).then((response) => {
                setScheduleData({ ...response });
                setSchedule({ open: false, anchorElement: null, reportId: 0 });
                setExistingSchedule(true);
            });
        } else {
            dispatch(createCustomReportSchedule({ ...scheduler, custom_report_id: schedule.reportId })).then((response) => {
                setScheduleData({ ...response });
                setSchedule({ open: false, anchorElement: null, reportId: 0 });
                setExistingSchedule(true);
            });
        }
    };

    const onRemoveSchedule = () => {
        dispatch(deleteCustomReportScheduleByReportId(schedule.reportId)).then(() => {
            setScheduleData({ ...defaultParams });
            setExistingSchedule(false);
            setShowRemoveSchedule(false);
            setSchedule({ open: false, anchorElement: null, reportId: 0 });
        });
    };

    const onChangeFilter = (name, value) => {
        if (value || value === false) {
            let filterChange = { ...JSON.parse(JSON.stringify(searchFilters)) };
            if (name === 'attributes') {
                let attributesListCopy = [...JSON.parse(JSON.stringify(attributesNames))];
                if (value.name === 'all') {
                    if (value.isSelected) {
                        attributesListCopy = attributesListCopy.map((elem) => ({ ...elem, isSelected: true }));
                    } else {
                        attributesListCopy = attributesListCopy.map((elem) => ({ ...elem, isSelected: false }));
                    }
                    filterChange = { ...filterChange, [name]: attributesListCopy.filter((elem) => elem.isSelected).map((elem) => elem.id) };
                } else {
                    const valueIndex = attributesListCopy.findIndex((elem) => elem.id === value.id);
                    if (valueIndex > -1) {
                        if (attributesListCopy[valueIndex].isSelected) {
                            attributesListCopy[valueIndex].isSelected = false;
                        } else {
                            attributesListCopy[valueIndex].isSelected = true;
                        }
                    }
                    filterChange = { ...filterChange, [name]: attributesListCopy.filter((elem) => elem.isSelected).map((elem) => elem.id) };
                }
                setAttributesNames(attributesListCopy);
            } else if (name === 'datasets') {
                let datasetNamesCopy = [...JSON.parse(JSON.stringify(datasetNames))];
                if (value.name === 'all') {
                    if (value.isSelected) {
                        datasetNamesCopy = datasetNamesCopy.map((elem) => ({ ...elem, isSelected: true }));
                    } else {
                        datasetNamesCopy = datasetNamesCopy.map((elem) => ({ ...elem, isSelected: false }));
                    }
                    filterChange = { ...filterChange, [name]: datasetNamesCopy.filter((elem) => elem.isSelected).map((elem) => elem.id) };
                } else {
                    const valueIndex = datasetNamesCopy.findIndex((elem) => elem.id === value.id);
                    if (valueIndex > -1) {
                        if (datasetNamesCopy[valueIndex].isSelected) {
                            datasetNamesCopy[valueIndex].isSelected = false;
                        } else {
                            datasetNamesCopy[valueIndex].isSelected = true;
                        }
                    }
                    filterChange = { ...filterChange, [name]: datasetNamesCopy.filter((elem) => elem.isSelected).map((elem) => elem.id) };
                }
                setDatasetNames(datasetNamesCopy);
            } else if (name === 'datasources') {
                let datsourceNamesCopy = [...JSON.parse(JSON.stringify(datasourceNames))];
                if (value.name === 'all') {
                    if (value.isSelected) {
                        datsourceNamesCopy = datsourceNamesCopy.map((elem) => ({ ...elem, isSelected: true }));
                    } else {
                        datsourceNamesCopy = datsourceNamesCopy.map((elem) => ({ ...elem, isSelected: false }));
                    }
                    filterChange = { ...filterChange, [name]: datsourceNamesCopy.filter((elem) => elem.isSelected).map((elem) => elem.id) };
                } else {
                    const valueIndex = datsourceNamesCopy.findIndex((elem) => elem.id === value.id);
                    if (valueIndex > -1) {
                        if (datsourceNamesCopy[valueIndex].isSelected) {
                            datsourceNamesCopy[valueIndex].isSelected = false;
                        } else {
                            datsourceNamesCopy[valueIndex].isSelected = true;
                        }
                    }
                    filterChange = { ...filterChange, [name]: datsourceNamesCopy.filter((elem) => elem.isSelected).map((elem) => elem.id) };
                }
                setDatasourceNames(datsourceNamesCopy);
            } else {
                filterChange = { ...filterChange, [name]: value };
            }
            setSearchFilters(filterChange);
            fetchReport(filterChange);
        }
    };

    const resetFilter = () => {
        const filterChange = {
            datasources: [],
            datasets: [],
            attributes: [],
            emailAlertType: '',
            status: ''
        };
        setSearchFilters(filterChange);
        fetchReport(filterChange);
        setDatasourceNames(datasourceList);
        setDatasetNames(datasetList);
        setAttributesNames(attributesList);
    };

    const searchOptions = (name, search_key = '') => {
        if (name === 'attributes') {
            let attributesListCopy = [...JSON.parse(JSON.stringify(attributesList))];
            attributesListCopy = attributesListCopy.map((datasource) => {
                if (searchFilters[name].some((elem) => datasource.id === elem)) {
                    return {
                        ...datasource,
                        isSelected: true
                    };
                }
                return datasource;
            });
            if (search_key) {
                attributesListCopy = attributesListCopy.filter((elem) => new RegExp(search_key, 'i').test(elem.name));
            }
            setAttributesNames(attributesListCopy);
        } else if (name === 'datasets') {
            let datasetListCopy = [...JSON.parse(JSON.stringify(datasetList))];
            datasetListCopy = datasetListCopy.map((datasource) => {
                if (searchFilters[name].some((elem) => datasource.id === elem)) {
                    return {
                        ...datasource,
                        isSelected: true
                    };
                }
                return datasource;
            });
            if (search_key) {
                datasetListCopy = datasetListCopy.filter((elem) => new RegExp(search_key, 'i').test(elem.name));
            }
            setDatasetNames(datasetListCopy);
        } else if (name === 'datasources') {
            let datasourceListCopy = [...JSON.parse(JSON.stringify(datasourceList))];
            datasourceListCopy = datasourceListCopy.map((datasource) => {
                if (searchFilters[name].some((elem) => datasource.id === elem)) {
                    return {
                        ...datasource,
                        isSelected: true
                    };
                }
                return datasource;
            });
            if (search_key) {
                datasourceListCopy = datasourceListCopy.filter((elem) => new RegExp(search_key, 'i').test(elem.name));
            }
            setDatasourceNames(datasourceListCopy);
        }
    };


    const filterReport = search && search.length > 0 ? reports.filter((report) => report.report_name.toLowerCase().includes(search.toLowerCase()) || report.description.toLowerCase().includes(search.toLowerCase()) || report.email_report_type.toLowerCase().includes(search.toLowerCase()) || (report.datasource && report.datasource.toLowerCase().includes(search.toLowerCase())) || (report.dataset && report.dataset.toLowerCase().includes(search.toLowerCase()))) : reports;

    return (
        <Grid className={classes.referenceContainer}>
            <Grid container justify="space-between">
                <Typography variant="h5" component="h5">
                    Reports
                </Typography>
                <Grid item className={classes.search}>
                    <Search value={search}
                        placeholder="Search Report"
                        onChange={(value) => setSearch(value)}
                        className={classNames(classes.dqSearchBoxRefList)}
                    />
                    <ToolTipComponent title="Filters" placement="bottom" arrow>
                        <IconButton onClick={() => setEnableFilter(!enableFilter)} className={classNames(classes.marginLeft10, classes.filterImg)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.923" viewBox="0 0 20 16.923">
                                <g transform="translate(-3 -5)">
                                    <path id="Path_1391" data-name="Path 1391" d="M22.231,6.538H11.462A1.54,1.54,0,0,0,9.923,5H8.385A1.54,1.54,0,0,0,6.846,6.538H3.769a.769.769,0,1,0,0,1.538H6.846A1.54,1.54,0,0,0,8.385,9.615H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,8.077V6.538H9.923V7.3s0,0,0,0,0,0,0,0v.766Z" fill={theme.palette.primary.main} />
                                    <path id="Path_1392" data-name="Path 1392" d="M22.231,14.538H17.615A1.54,1.54,0,0,0,16.077,13H14.538A1.54,1.54,0,0,0,13,14.538H3.769a.769.769,0,0,0,0,1.538H13a1.54,1.54,0,0,0,1.538,1.538h1.538a1.54,1.54,0,0,0,1.538-1.538h4.615a.769.769,0,1,0,0-1.538Zm-7.692,1.538V14.538h1.538V15.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -1.846)" fill={theme.palette.primary.main} />
                                    <path id="Path_1393" data-name="Path 1393" d="M22.231,22.538H11.462A1.54,1.54,0,0,0,9.923,21H8.385a1.54,1.54,0,0,0-1.538,1.538H3.769a.769.769,0,0,0,0,1.538H6.846a1.54,1.54,0,0,0,1.538,1.538H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,24.077V22.538H9.923V23.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -3.692)" fill={theme.palette.primary.main} />
                                </g>
                            </svg>
                        </IconButton>
                    </ToolTipComponent>

                    <ToolTipComponent title={"Add Report"} arrow>
                        <IconButton className={classNames(classes.marginLeft10, classes.alignIconCenter)} onClick={() => onEdit("Add Report", null, reports)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                    <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                        <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                        <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                    </g>
                                </g>
                            </svg>
                        </IconButton>
                    </ToolTipComponent>
                </Grid>
            </Grid>


            <Grid container className={classNames(classes.referenceSection, classes.marginTop20)}>
                <TableContainer className={classes.tableWrapperStyle} style={{ height: '75vh' }} >
                    <Table className={classes.tableStyle}>
                        <TableHead style={
                            {
                                position: 'sticky',
                                top: 0,
                                background: 'white',
                                zIndex: 1
                            }
                        }>
                            <TableRow>
                                {
                                    appConstants.reportListTableHeader.map((header, index) => (
                                        <TableCell key={index} sortDirection={orderBy === header.id ? order : false}>
                                            {
                                                header.sort ?
                                                    <TableSortLabel
                                                        active={orderBy === header.id}
                                                        direction={orderBy === header.id ? order : 'asc'}
                                                        onClick={() => onChangeOrder(header.id)}
                                                    >
                                                        <Typography variant="body1" className={classes.tableHeader}>
                                                            {header.label}
                                                        </Typography>
                                                    </TableSortLabel>
                                                    :
                                                    <Typography variant="body1" className={classes.tableHeader} align="center">
                                                        Actions
                                                    </Typography>
                                            }
                                        </TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                sortTable(filterReport, order, orderBy).map((report, index) =>
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Grid item>
                                                <ToolTipComponent title={report.report_name ? report.report_name : "NA"} arrow>
                                                    <Typography className={classes.ellipse180}>
                                                        {report.report_name ? report.report_name : "NA"}
                                                    </Typography>
                                                </ToolTipComponent>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item>
                                                <ToolTipComponent title={report.description ? report.description : "NA"} arrow>
                                                    <Typography className={classes.ellipse180}>
                                                        {report.description ? report.description : "NA"}
                                                    </Typography>
                                                </ToolTipComponent>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item>
                                                <ToolTipComponent title={report.email_report_type ? report.email_report_type : "NA"} arrow>
                                                    <Typography className={classes.ellipse180}>
                                                        {report.email_report_type ? report.email_report_type : "NA"}
                                                    </Typography>
                                                </ToolTipComponent>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item>
                                                <ToolTipComponent title={report.datasource ? report.datasource : "NA"} arrow>
                                                    <Typography className={classes.ellipse180}>
                                                        {report.datasource ? report.datasource : "NA"}
                                                    </Typography>
                                                </ToolTipComponent>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item>
                                                <ToolTipComponent title={report.dataset ? report.dataset : "NA"} arrow>
                                                    <Typography className={classes.ellipse180}>
                                                        {report.dataset ? report.dataset : "ALL"}
                                                    </Typography>
                                                </ToolTipComponent>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item>
                                                <ToolTipComponent title={report.last_run ? report.last_run : "NA"} arrow>
                                                    <Typography className={classes.ellipse180}>
                                                        {report.last_run ? report.last_run : "NA"}
                                                    </Typography>
                                                </ToolTipComponent>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid item>
                                                <Switch
                                                    color="secondary"
                                                    size="small"
                                                    name="is_active"
                                                    value="is_active"
                                                    checked={report.is_active}
                                                    // disabled={userRole.name !== 'Admin'}
                                                    onChange={() => editReportList(report.id, !report.is_active)}
                                                />
                                            </Grid>
                                        </TableCell>
                                        <TableCell style={{ minWidth: 180 }}>
                                            <Grid item align="right">
                                                <ToolTipComponent title={"Edit/Update Report"} arrow>
                                                    <IconButton className={classNames(classes.referenceRoleIcon, classes.marginRight5)} onClick={() => onEdit("Edit Report", report.id, reports)}>
                                                        <svg
                                                            version="1.1"
                                                            id="Layer_1"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="21.349"
                                                            height="23.349"
                                                            x="0px"
                                                            y="0px"
                                                            viewBox="0 0 32 32"
                                                        >
                                                            <g id="edit" transform="translate(0 0)">
                                                                <path
                                                                    fill={theme.palette.primary.main}
                                                                    stroke={theme.palette.primary.main}
                                                                    strokeWidth="0.3"
                                                                    strokeMiterlimit="10"
                                                                    d="M27.2,8L24,4.8c-0.8-0.8-2.2-0.8-3,0L6.5,19.4c-0.1,0.1-0.1,0.1-0.1,0.2l-2.1,7.5c-0.1,0.3,0.1,0.6,0.4,0.7
                        c0.1,0,0.2,0,0.3,0l7.5-2.1c0.1,0,0.2-0.1,0.2-0.1l14.6-14.4C28,10.2,28,8.9,27.2,8L27.2,8z M18.6,8.7l2,2L10.2,21l-0.7-1.5
                        c-0.1-0.2-0.3-0.3-0.5-0.3H8.2L18.6,8.7z M5.5,26.5L6.2,24L8,25.8L5.5,26.5z M11.7,24.7l-2.6,0.7l-2.6-2.6l0.7-2.6h1.4l0.9,1.8
                        c0.1,0.1,0.1,0.2,0.2,0.2l1.8,0.9L11.7,24.7L11.7,24.7z M12.8,23.8V23c0-0.2-0.1-0.4-0.3-0.5L11,21.7l10.3-10.3l2,2L12.8,23.8z
                        M26.4,10.3l-2.4,2.3l-4.7-4.7l2.3-2.4c0.4-0.4,1.1-0.4,1.5,0l3.2,3.2C26.8,9.2,26.8,9.9,26.4,10.3L26.4,10.3z"
                                                                />
                                                            </g>
                                                        </svg>
                                                    </IconButton>
                                                </ToolTipComponent>
                                                {
                                                    report.email_report_type === appConstants.emailReportType[1].name && report.is_active &&
                                                    <ToolTipComponent title={"Schedule"} arrow>
                                                        <IconButton
                                                            onClick={(event) => openSchedule(event, report.id)}
                                                            className={classes.iconButtonSmall}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                id="calendar_4_"
                                                                data-name="calendar (4)"
                                                                width="18.174"
                                                                height="18.174"
                                                                viewBox="0 0 18.174 18.174"
                                                            >
                                                                <g id="Group_1602" data-name="Group 1602">
                                                                    <g id="Group_1601" data-name="Group 1601">
                                                                        <ellipse
                                                                            id="Ellipse_946"
                                                                            data-name="Ellipse 946"
                                                                            cx="1"
                                                                            cy="0.5"
                                                                            rx="1"
                                                                            ry="0.5"
                                                                            transform="translate(13.087 7.087)"
                                                                            fill={theme.palette.primary.main}
                                                                        />
                                                                        <path
                                                                            id="Path_890"
                                                                            data-name="Path 890"
                                                                            d="M15.335,1.42h-.923V.71a.71.71,0,0,0-1.42,0v.71H9.762V.71a.71.71,0,1,0-1.42,0v.71H5.147V.71a.71.71,0,0,0-1.42,0v.71H2.84A2.843,2.843,0,0,0,0,4.26V15.335a2.843,2.843,0,0,0,2.84,2.84H8.271a.71.71,0,1,0,0-1.42H2.84a1.421,1.421,0,0,1-1.42-1.42V4.26A1.421,1.421,0,0,1,2.84,2.84h.887v.71a.71.71,0,1,0,1.42,0V2.84H8.342v.71a.71.71,0,1,0,1.42,0V2.84h3.23v.71a.71.71,0,0,0,1.42,0V2.84h.923a1.421,1.421,0,0,1,1.42,1.42V8.306a.71.71,0,0,0,1.42,0V4.26A2.843,2.843,0,0,0,15.335,1.42Z"
                                                                            fill={theme.palette.primary.main}
                                                                        />
                                                                        <path
                                                                            id="Path_891"
                                                                            data-name="Path 891"
                                                                            d="M274.3,270a4.3,4.3,0,1,0,4.3,4.3A4.3,4.3,0,0,0,274.3,270Zm0,7.17a2.875,2.875,0,1,1,2.875-2.875A2.878,2.878,0,0,1,274.3,277.17Z"
                                                                            transform="translate(-260.416 -260.416)"
                                                                            fill={theme.palette.primary.main}
                                                                        />
                                                                        <path
                                                                            id="Path_892"
                                                                            data-name="Path 892"
                                                                            d="M372.739,331.455h-.319v-.745a.71.71,0,1,0-1.42,0v1.455a.71.71,0,0,0,.71.71h1.029a.71.71,0,0,0,0-1.42Z"
                                                                            transform="translate(-357.831 -318.286)"
                                                                            fill={theme.palette.primary.main}
                                                                        />
                                                                        <circle
                                                                            id="Ellipse_947"
                                                                            data-name="Ellipse 947"
                                                                            cx="0.5"
                                                                            cy="0.5"
                                                                            r="0.5"
                                                                            transform="translate(10.087 7.087)"
                                                                            fill={theme.palette.primary.main}
                                                                        />
                                                                        <circle
                                                                            id="Ellipse_948"
                                                                            data-name="Ellipse 948"
                                                                            cx="0.5"
                                                                            cy="0.5"
                                                                            r="0.5"
                                                                            transform="translate(7.087 10.087)"
                                                                            fill={theme.palette.primary.main}
                                                                        />
                                                                        <ellipse
                                                                            id="Ellipse_949"
                                                                            data-name="Ellipse 949"
                                                                            cx="1"
                                                                            cy="0.5"
                                                                            rx="1"
                                                                            ry="0.5"
                                                                            transform="translate(3.087 7.087)"
                                                                            fill={theme.palette.primary.main}
                                                                        />
                                                                        <ellipse
                                                                            id="Ellipse_950"
                                                                            data-name="Ellipse 950"
                                                                            cx="1"
                                                                            cy="0.5"
                                                                            rx="1"
                                                                            ry="0.5"
                                                                            transform="translate(3.087 10.087)"
                                                                            fill={theme.palette.primary.main}
                                                                        />
                                                                        <circle
                                                                            id="Ellipse_951"
                                                                            data-name="Ellipse 951"
                                                                            cx="1"
                                                                            cy="1"
                                                                            r="1"
                                                                            transform="translate(3.087 13.087)"
                                                                            fill={theme.palette.primary.main}
                                                                        />
                                                                        <ellipse
                                                                            id="Ellipse_952"
                                                                            data-name="Ellipse 952"
                                                                            cx="0.5"
                                                                            cy="1"
                                                                            rx="0.5"
                                                                            ry="1"
                                                                            transform="translate(7.087 13.087)"
                                                                            fill={theme.palette.primary.main}
                                                                        />
                                                                        <circle
                                                                            id="Ellipse_953"
                                                                            data-name="Ellipse 953"
                                                                            cx="0.5"
                                                                            cy="0.5"
                                                                            r="0.5"
                                                                            transform="translate(7.087 7.087)"
                                                                            fill={theme.palette.primary.main}
                                                                        />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </IconButton>
                                                    </ToolTipComponent>
                                                }
                                                <ToolTipComponent title={"Delete Report"} arrow>
                                                    <IconButton className={classes.referenceRoleIcon} onClick={() => setSelectedReport(report)}>
                                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 32 32">
                                                            <g id="Delete">
                                                                <path fill={theme.palette.primary.main}
                                                                    stroke={theme.palette.primary.main}
                                                                    strokeWidth="0.92"
                                                                    strokeMiterlimit="10"
                                                                    d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                                                <path fill={theme.palette.primary.main}
                                                                    stroke={theme.palette.primary.main}
                                                                    strokeWidth="0.92"
                                                                    strokeMiterlimit="10"
                                                                    d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                            </g>
                                                        </svg>
                                                    </IconButton>
                                                </ToolTipComponent>
                                            </Grid>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                        {
                            !isLoading && filterReport.length === 0 &&
                            <NoResultFound />
                        }
                        {
                            isLoading &&
                            <Loader />
                        }
                    </Table>
                </TableContainer>
            </Grid>
            {
                schedule.open &&
                <Scheduler
                    scheduleProps={schedule}
                    onClose={() => setSchedule({ open: false, anchorElement: null, reportId: 0 })}
                    createScheduler={(schedule) => { createScheduler(schedule); }}
                    scheduleData={scheduleData}
                    showRemoveSchedule={showRemoveSchedule}
                    isExistingSchedule={isExistingSchedule}
                    onRemoveSchedule={() => { onRemoveSchedule(); }}
                    setExistingSchedule={(val) => { setExistingSchedule(val); }}
                    setShowRemoveSchedule={(val) => { setShowRemoveSchedule(val); }}
                    setDatasetSchedule={(val) => setScheduleData(val)}
                />
            }
            {
                selectedReport && <AlertDialog
                    title="Delete Report"
                    message={`Are you sure you want to delete the report - ${selectedReport ? selectedReport.report_name : ''}?`}
                    okButtonText="OK"
                    cancelButtonText="Cancel"
                    show={Boolean(selectedReport)}
                    onClickOK={() => deleteReportList(selectedReport.id)}
                    onClickCancel={() => setSelectedReport(null)}
                />
            }
            {
                enableFilter &&
                <Grid container className={classes.filterSection}>
                    <ValidatorForm onSubmit={() => null} style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item className={classNames(classes.FilterHideShowContainer, classes.filterPosition)}>
                            <ClickAwayListener onClickAway={() => setEnableFilter(false)} mouseEvent={'onMouseUp'} touchEvent={'onTouchEnd'}>
                                <Grid container alignItems="center" justify="space-between" className={classNames(classes.datasourceFilterSection, "datasourceFilterSection")}>
                                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)} xs={2}>
                                        <AutoCompleteCheckBox
                                            value={searchFilters.datasources ? searchFilters.datasources : []}
                                            availableList={sortTable(datasourceNames ? datasourceNames : [], "asc", "name")}
                                            onChange={(value) => onChangeFilter("datasources", value)}
                                            onSearch={(value) => searchOptions("datasources", value)}
                                            fullWidth
                                            label="Datasource"
                                            noMargin
                                            isHideSelectedAllCount
                                        />
                                    </Grid>
                                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)} xs={2}>
                                        <AutoCompleteCheckBox
                                            value={searchFilters.datasets ? searchFilters.datasets : []}
                                            availableList={sortTable(datasetNames ? datasetNames : [], "asc", "name")}
                                            onChange={(value) => onChangeFilter("datasets", value)}
                                            onSearch={(value) => searchOptions("datasets", value)}
                                            fullWidth
                                            label="Datasets"
                                            noMargin
                                            isHideSelectedAllCount
                                        />
                                    </Grid>
                                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)} xs={2}>
                                        <AutoCompleteCheckBox
                                            value={searchFilters.attributes ? searchFilters.attributes : []}
                                            // selectionData={searchFilters.attributes ? searchFilters.attributes : []}
                                            availableList={sortTable(attributesNames ? attributesNames : [], "asc", "name")}
                                            onChange={(value) => onChangeFilter("attributes", value)}
                                            onSearch={(value) => searchOptions("attributes", value)}
                                            fullWidth
                                            label="Attributes"
                                            noMargin
                                            isHideSelectedAllCount
                                        />
                                    </Grid>
                                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)} xs={2}>
                                        <FormLabel>
                                            Email Alert Type
                                        </FormLabel>
                                        <TextBox
                                            style={{ marginTop: 12 }}
                                            name="emailAlertType"
                                            placeholder="select"
                                            value={searchFilters.emailAlertType ? searchFilters.emailAlertType : []}
                                            select
                                            onChange={(event, value) => { onChangeFilter("emailAlertType", event.target.value); }}
                                            SelectProps={
                                                {
                                                    MenuProps: {
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "center"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "center"
                                                        },
                                                        getContentAnchorEl: null
                                                    }
                                                    // IconComponent: () => DropDownIcon()
                                                }
                                            }
                                            fullWidth
                                        >
                                            {
                                                appConstants.emailReportType && appConstants.emailReportType.length ?
                                                    appConstants.emailReportType.map((option, index) => (
                                                        <MenuItem key={`menuProperty_Options_${index}`} value={option.value} className={classes.menuItem}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))
                                                    :
                                                    <MenuItem key={`menuProperty_Options`} disabled className={classes.menuItem}>
                                                        No options
                                                    </MenuItem>
                                            }
                                        </TextBox>
                                    </Grid>
                                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)} xs={1}>
                                        <FormLabel>
                                            Status
                                        </FormLabel>
                                        <TextBox
                                            style={{ marginTop: 12 }}
                                            name="status"
                                            placeholder="select"
                                            value={(searchFilters.status || searchFilters.status === false) ? searchFilters.status : ''}
                                            select
                                            onChange={(event, value) => { onChangeFilter("status", event.target.value); }}
                                            SelectProps={
                                                {
                                                    MenuProps: {
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "center"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "center"
                                                        },
                                                        getContentAnchorEl: null
                                                    }
                                                    // IconComponent: () => DropDownIcon()
                                                }
                                            }
                                            fullWidth
                                        >
                                            {
                                                appConstants.activeStatus && appConstants.activeStatus.length ?
                                                    appConstants.activeStatus.map((option, index) => (
                                                        <MenuItem key={`menuProperty_Options_${index}`} value={option.value} className={classes.menuItem}>
                                                            {option.name}
                                                        </MenuItem>
                                                    ))
                                                    :
                                                    <MenuItem key={`menuProperty_Options`} disabled className={classes.menuItem}>
                                                        No options
                                                    </MenuItem>
                                            }
                                        </TextBox>
                                    </Grid>
                                    <Grid item xs={1} style={{ display: 'flex' }}>
                                        <ToolTipComponent title="Clear Filter" placement="bottom" arrow>
                                            <IconButton onClick={() => resetFilter()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22.795" viewBox="0 0 20 22.795">
                                                    <g id="filter_10_" data-name="filter (10)" transform="translate(-31.388)">
                                                        <path id="Path_1437" data-name="Path 1437" d="M46.071,0a5.326,5.326,0,0,0-5.1,3.8h-8.59a1,1,0,0,0-.814,1.564l5.776,7.882a1.046,1.046,0,0,1,.185.6V21.75a1.044,1.044,0,0,0,1.668.835l2.564-1.937a1.527,1.527,0,0,0,.612-1.22V13.84a1.047,1.047,0,0,1,.185-.6l2.07-2.825A5.31,5.31,0,1,0,46.071,0ZM41.477,12.462a2.375,2.375,0,0,0-.438,1.377c0,6.076.033,5.658-.075,5.737l-2.1,1.586V13.84a2.376,2.376,0,0,0-.428-1.364l-5.38-7.341h7.7A5.307,5.307,0,0,0,43.369,9.88Zm4.594-3.18a3.973,3.973,0,1,1,3.982-3.973A3.982,3.982,0,0,1,46.071,9.282Z" transform="translate(0)" fill="#f38080" />
                                                        <path id="Path_1438" data-name="Path 1438" d="M321.45,76.089a.668.668,0,0,0-.944,0l-.793.791-.793-.791a.668.668,0,0,0-.944.945l.791.79-.791.79a.668.668,0,0,0,.944.945l.793-.791.793.791a.668.668,0,0,0,.944-.945l-.791-.79.791-.79A.668.668,0,0,0,321.45,76.089Z" transform="translate(-273.642 -72.515)" fill="#f38080" />
                                                    </g>
                                                </svg>
                                            </IconButton>
                                        </ToolTipComponent>
                                        <ToolTipComponent title="Close" placement="bottom" arrow>
                                            <IconButton onClick={() => setEnableFilter(!enableFilter)} className={classes.marginRight12}>
                                                <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                    <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                                </svg>
                                            </IconButton>
                                        </ToolTipComponent>
                                    </Grid>
                                </Grid>
                            </ClickAwayListener>
                        </Grid>
                    </ValidatorForm>
                </Grid>
            }
        </Grid>
    );

};

ReportList.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    onEdit: PropTypes.func,
    organizationId: PropTypes.number
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ReportList);