const MasterStyles = (theme) => ({
    root: {
        flexGrow: '1 auto',
        height: '100%'
    },
    sliderValue: {
        marginLeft: 16,
        marginTop: 4,
        color: `${theme.palette.grey.default} !important`
    },
    addIconButton: {
        padding: 5,
        color: theme.palette.background.paper,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: `${theme.palette.primary.dark} !important`
        }
    },
    secondaryText: {
        lineHeight: 1.7
    },
    dragHandlerContainer: {
        padding: '0 8px 0 10px !important',
        minWidth: 30,
        maxWidth: 40,
        '&.childRow': {
            cursor: 'auto !important',
            '& > svg': {
                visibility: 'hidden',
                cursor: 'auto',
                '&:hover': {
                    cursor: 'auto'
                }
            }
        }
    },
    attributeHeader: {
        // padding: '10px 10px 10px 40px !important'
        paddingLeft: '0 !important',
        '& .MuiInputBase-input': {
            width: 100,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        }
    },
    attributeRow: {
        backgroundColor: theme.palette.background.paper,
        '& .MuiTableCell-body': {
            borderBottom: 0
        }
    },
    collapsibleAttributeRow: {
        padding: 10
    },
    attributeRowExpandIcon: {
        transform: 'rotate(0deg)',
        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&.expanded': {
            transform: 'rotate(180deg)'
        }
    },
    childAttributeRow: {
        paddingLeft: 10,
        backgroundColor: '#fafafa'
    },
    childCell: {
        paddingLeft: 10
    },
    emptyRow: {
        height: 10,
        boxShadow: 'none !important',
        background: 'transparent !important'
    },
    masterAttributesTableContainer: {
        height: 'calc(100vh - 250px)',
        overflowY: 'auto',
        alignItems: 'flex-start',
        '& th.dragHandlerContainer': {
            cursor: 'auto',
            '& > svg': {
                visibility: 'hidden',
                cursor: 'auto',
                '&:hover': {
                    cursor: 'auto'
                }
            }
        },
        '& td.dragHandlerContainer': {
            width: 30,
            minWidth: 30,
            cursor: 'move',
            '& > svg': {
                display: 'flex',
                margin: '0 auto',
                cursor: 'move',
                '&:hover': {
                    cursor: 'move'
                }
            }
        },
        '& tr': {
            background: theme.palette.common.white,
            boxShadow: '0px 0px 6px #e1e5e6',
            '& td': {
                border: 0
            }
        }
    },
    autoHeight: {
        height: 'auto',
        maxHeight: 'calc(100vh - 250px)'
    },
    paddingTop24: {
        paddingTop: '24px'
    },
    masterAttributesTable: {
        height: 'auto',
        border: `1px solid ${theme.palette.grey.extraLight}`,
        borderBottom: '0 !important',
        padding: 0,
        '& th.dragHandlerContainer': {
            cursor: 'auto',
            '& > svg': {
                visibility: 'hidden',
                cursor: 'auto',
                '&:hover': {
                    cursor: 'auto'
                }
            }
        },
        '& td.dragHandlerContainer': {
            width: 30,
            minWidth: 30,
            cursor: 'move',
            '& > svg': {
                display: 'flex',
                margin: '0 auto',
                cursor: 'move',
                '&:hover': {
                    cursor: 'move'
                }
            }
        },
        '& tr': {
            background: theme.palette.common.white,
            border: `1px solid ${theme.palette.grey.extraLight}`,
            boxShadow: 'none !important',
            '& td': {
                borderBottom: `1px solid ${theme.palette.grey.extraLight} !important`
            }
        }
    },
    maxWidth: {
        maxWidth: 149
    },
    marginTop4: {
        marginTop: 4
    },
    marginTop10: {
        marginTop: 10
    },
    marginBottom6: {
        marginBottom: 6
    },

    // Master Groups Tab Style
    groupsContainer: {
        position: 'relative',
        '& .dragTemplate': {
            background: `${theme.palette.grey.extraLight} !important`,
            width: '100% !important',
            '& > td': {
                width: '15% !important',
                '&:first-child > *': {
                    display: 'inline-block'
                },
                '&:last-child > *': {
                    display: 'none'
                }
            }
        },
        '& .MuiTableContainer-root': {
            height: 'calc(100vh - 220px)',
            padding: 0,
            background: theme.palette.background.container,
            '& .MuiTableCell-head p': {
                fontWeight: 600
            }
        },
        '& th.dragHandlerContainer': {
            cursor: 'auto',
            '& > svg': {
                visibility: 'hidden',
                cursor: 'auto',
                '&:hover': {
                    cursor: 'auto'
                }
            }
        },
        '& th': {
            border: 0
        },
        '& tr': {
            background: theme.palette.common.white,
            marginBottom: 10,
            boxShadow: '0px 0px 6px #e1e5e6',
            '& td': {
                border: 0
            }
        },
        '& td.dragHandlerContainer': {
            width: 30,
            minWidth: 30,
            cursor: 'move',
            '& > svg': {
                display: 'flex',
                margin: '0 auto',
                cursor: 'move',
                '&:hover': {
                    cursor: 'move'
                }
            }
        },
        '& .MuiAutocomplete-tag': {
            fontWeight: 'normal'
        },
        '& th.rank': {
            width: 60
        },
        '& th.prefix': {
            width: 150
        },
        '& th.level': {
            width: 150
        },
        '& th.actions': {
            width: 100
        }
    },
    actionBar: {
        marginBottom: 8
    },
    emptyBorder: {
        height: 10,
        boxShadow: 'none !important',
        background: 'transparent !important'
    },
    levelInput: {
        '& .MuiInput-underline.Mui-disabled:before': {
            borderBottom: '0px !important'
        },
        '& .MuiInput-underline.Mui-disabled:hover:before': {
            borderBottom: '0px !important'
        }
    },
    mappingsContainer: {
        position: 'relative',
        height: 'calc(100vh - 190px)',
        overflowY: 'auto',
        background: '#fff',
        padding: '5px 22px',
        '& .MuiTableCell-stickyHeader': {
            background: '#fff'
        }
    },
    mappingsTableContainer: {
        '& tr > td': {
            padding: '7px 16px',
            backgroundColor: theme.palette.common.white,
            '& > p': {
                padding: '6px 0px',
                color: theme.palette.grey.default
            }
        }
    },
    executionLogContainer: {
        height: 'calc(100vh - 200px)',
        backgroundColor: theme.palette.common.white,
        position: 'relative',
        width: '100%',
        '& .MuiCard-root': {
            height: '100%',
            overflow: 'auto',
            padding: '0px 27px'
        },
        '& .MuiTable-stickyHeader': {
            padding: '6px 0'
        },
        '& .MuiTableCell-head': {
            color: "#222222"
        },
        '& .MuiTableCell-root': {
            padding: '7px 16px',
            background: "#fff"
        },
        '& .MuiTableCell-body > p': {
            color: theme.palette.grey.darkTxt
        }
    },
    errorLogContainer: {
        padding: 8
    },
    executionLogActions: {
        padding: 4
    },
    attributesEmptyContainer: {
        height: 'calc(100vh - 320px)'
    },
    assetInput: {
        marginTop: 10,
        marginBottom: 10,
        backgroundColor: '#fff',
        '& .MuiAutocomplete-input': {
            height: 20
        },
        '& .MuiInputBase-formControl': {
            padding: '5px 52px 5px 16px'
        },
        '& .MuiChip-label': {
            maxWidth: 120
        }
    },
    defineTable: {
        '& .MuiTableCell-head': {
            whiteSpace: 'nowrap'
        }
    },
    hoverdefault: {
        '&:hover': {
            cursor: 'default'
        }
    },
    hoverBgGrey: {
        '& .MuiInput-root:hover': {
            backgroundColor: theme.palette.grey.exteremeLight
        }
    },
    semanticsMultiSelect: {
        '& .MuiChip-deleteIcon': {
            top: '-5px !important'
        },
        '& .MuiChip-root': {
            marginTop: '2px !important',
            marginBottom: '2px !important'
        }
    },
    matchCombinationChip: {
        '& .MuiChip-root': {
            maxWidth: '100% !important'
        }
    },
    statusSelect: {
        '& .MuiSelect-selectMenu': {
            display: 'flex'
        }
    },
    search: {
        margin: "0px auto",
        "& .MuiFormControl-root": {
            width: "100%"
        }
    },
    searchicon: {
        position: "relative",
        "& img": {
            position: "absolute",
            top: 4,
            right: 5,
            maxWidth: 32,
            display: "block !important",
            cursor: "pointer",
            zIndex: 9999,
            borderRadius: "40%",
            padding: 7,
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)"
            }
        }
    },
    searchclear: {
        "& .MuiAutocomplete-clearIndicator": {
            visibility: "visible"
        },
        "& .MuiAutocomplete-endAdornment": {
            right: "40px !important"
        }
    },
    newInput: {
        width: "100%",
        border: "1px solid #E6EAEA",
        background: "#FFFFFF",
        padding: "5px 20px",
        "& .MuiInput-underline:before": {
            border: 0
        },
        "& .MuiInput-underline:hover:before": {
            border: 0
        }
    },
    addIcon: {
        top: '8px',
        right: '18px',
        position: 'absolute'
    },
    listSearch: {
        margin: 0
    },
    reportAttributesTableContainer: {
        height: 'calc(100vh - 150px)',
        overflowY: 'auto',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        '& th.dragHandlerContainer': {
            cursor: 'auto',
            '& > svg': {
                visibility: 'hidden',
                cursor: 'auto',
                '&:hover': {
                    cursor: 'auto'
                }
            }
        },
        '& td.dragHandlerContainer': {
            width: 30,
            minWidth: 30,
            cursor: 'move',
            '& > svg': {
                display: 'flex',
                margin: '0 auto',
                cursor: 'move',
                '&:hover': {
                    cursor: 'move'
                }
            }
        },
        '& tr': {
            background: theme.palette.common.white,
            boxShadow: '0px 0px 6px #e1e5e6',
            '& td': {
                border: 0
            }
        }
    },
    collapsibleReportRow: {
        padding: 10,
        background: theme.palette.background.container
    },
    collapsibleSubRow: {
        '& td': {
            background: theme.palette.background.container,
            paddingTop: '0px !important',
            paddingBottom: '0px !important'
        },
        '& .MuiTable-root': {
            border: '0px !important'
        }
    },
    firstColumn: {
        '& p': {
        width: '300px !important'
        }
    },
    otherColumn: {
        '& p': {
            width: '80px !important'
        }
    },
    placeHolder: {
        width: '44px',
        height: '1px'
    },
    parentFirstCol: {
        '& p': {
            width: '310px !important'
        }
    },
    parentLastCol: {
        '& p': {
            width: '90px !important'
        }
    },
    actionText: {
        cursor: 'pointer'
    }
});

export default MasterStyles;