import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, withStyles, Typography, TableCell, Slider, TextField, Switch, TableRow } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Styles from '../../layouts/Styles.jsx';
import MasterStyles from '../Master/MasterStyles.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import classNames from 'classnames';
import AutoComplete from '../AutoComplete/AutoCompleteInput.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import ChipInput from '../ChipInput/ChipInput.jsx';
import PatternInput from '../PatternInput/PatternInput.jsx';
import SemanticModelStyles from './SemanticModelStyles.jsx';


const SemanticModelAttributeRow = (props) => {
    const { classes, attribute, onChangeProperty, onValueChange, theme, onDelete } = props;
    const datatypes = useSelector(({ datasource }) => datasource.datatypes);
    // const fieldTypes = useSelector(({ datasource }) => datasource.fieldTypes);

    const onChange = (property, value) => {
        const regex = /^([+-])?[0-9]*(.[0-9]*)?$/;
        if ((property === "min_length" || property === "max_length") && !regex.test(value)) {
            return false;
        }
        attribute[property] = value;
        if (property === "name") {
            attribute.type = value.trim();
        }
        if (property === "sensitivity" || property === "threshold" || property === "is_active") {
            onValueChange(attribute);
        }
        onChangeProperty({ ...attribute });
    };

    const saveEnumValue = (property, value, index) => {
        if (value.trim() !== "") {
            const enumValues = attribute.enum.values;
            let enumValue = {};
            if (index >= 0) {
                enumValue = enumValues[index];
                enumValue.value = value.trim();
                enumValues.splice(index, 1, { ...enumValue });
            } else {
                index = 0;
                enumValue = {
                    value,
                    isvalid: true
                };
                enumValues.splice(index, 0, { ...enumValue });
            }
            attribute.enum.values = [...enumValues];
            onChangeProperty(attribute);
            onValueChange(attribute);
        }
    };

    const removeEnumValue = (property, index) => {
        const enumValues = attribute.enum.values;
        enumValues.splice(index, 1);
        attribute.enum.values = [...enumValues];
        onChangeProperty(attribute);
        onValueChange(attribute);
    };

    const saveKeywordValue = (property, value, index) => {
        if (value.trim() !== "") {
            const values = attribute.keyword[property];
            if (index >= 0) {
                values[index] = value.trim();
            } else {
                index = 0;
                values.splice(index, 0, value.trim());
            }
            attribute.keyword[property] = [...values];
            onChangeProperty(attribute);
            onValueChange(attribute);
        }
    };

    const removeKeywordValue = (property, index) => {
        const values = attribute.keyword[property];
        values.splice(index, 1);
        attribute.keyword[property] = [...values];
        onChangeProperty(attribute);
        onValueChange(attribute);
    };

    const savePattern = (pattern, index) => {
        if (index >= 0) {
            attribute.patterns[index] = { ...pattern };
        } else {
            attribute.patterns.push({ ...pattern });
        }
        onChangeProperty(attribute);
        onValueChange(attribute);
    };

    const removePattern = (index) => {
        attribute.patterns.splice(index, 1);
        onChangeProperty(attribute);
        onValueChange(attribute);
    };


    return (
        <TableRow className={classes.attributeRow}>
            <TableCell>
                <TextBox
                    name="name"
                    value={attribute.name ? attribute.name : ''}
                    onChange={(event) => onChange(event.target.name, event.target.value)}
                    onBlur={
                        (event) => {
                            if (event.target.getAttribute('aria-invalid') === 'true') {
                                return;
                            }
                            if (event.target.value !== "") {
                                onValueChange(attribute);
                            }
                        }
                    }
                    className={classNames(classes.inlinetxt, classes.maxWidth, classes.marginTop4)}
                    validators={['matchRegexp:^[A-Za-z][A-Za-z0-9_]+$']}
                    errorMessages={['']}
                    placeholder="FieldName"
                />
            </TableCell>
            <TableCell>
                <AutoComplete
                    className={classes.marginBottom6}
                    name="datatype"
                    options={datatypes}
                    value={attribute.datatype ? attribute.datatype : ''}
                    getOptionLabel={
                        (option) => {
                            if (option && option.name) {
                                return option.name;
                            }
                            return option;
                        }
                    }
                    getOptionSelected={(option, value) => option.name === value}
                    renderInput={(params) => <TextField {...params} className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)} margin="normal" placeholder="DataType" />}
                    onChange={(_, selectedValue) => onChange("datatype", selectedValue.name)}
                    onBlur={(event) => onValueChange(attribute)}
                    openOnFocus
                    disableClearable
                    fullWidth
                    forcePopupIcon={false}
                    noOptionsText={"No datatypes"}
                    disabled={attribute.name === ""}
                />
            </TableCell>
            <TableCell>
                <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
                    <TextField
                        name="min_length"
                        value={attribute && attribute.min_length ? attribute.min_length : ''}
                        className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.bgGrey)}
                        onChange={(event) => onChange(event.target.name, event.target.value)}
                        onBlur={(event) => onValueChange(attribute)}
                        placeholder="Min"
                        disabled={attribute.name === ""}
                    />
                    <Typography variant="h2" className={classes.Rangehyphen}>
                        {'-'}
                    </Typography>
                    <TextField
                        name="max_length"
                        value={attribute && attribute.max_length ? attribute.max_length : ''}
                        className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.bgGrey)}
                        onChange={(event) => onChange(event.target.name, event.target.value)}
                        onBlur={(event) => onValueChange(attribute)}
                        placeholder="Max"
                        disabled={attribute.name === ""}
                    />
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                    <Slider name="sensitivity"
                        color="secondary"
                        className={classes.SensitivityMuiSlider}
                        style={{ width: '95%' }}
                        defaultValue={1}
                        track={'normal'}
                        value={attribute && attribute.sensitivity ? attribute.sensitivity : 1}
                        onChange={(_event, value) => onChange('sensitivity', value)}
                        min={1}
                        max={3}
                        step={1}
                        disabled={attribute.name === ""}
                    />
                    <Typography variant="body2" className={classes.sliderValue}>
                        {attribute.sensitivity ? attribute.sensitivity : '1'}
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                    <Slider name="threshold"
                        color="secondary"
                        className={classes.SensitivityMuiSlider}
                        style={{ width: '95%' }}
                        defaultValue={67}
                        track={'normal'}
                        value={attribute && attribute.threshold ? attribute.threshold : 1}
                        onChange={(_event, value) => onChange('threshold', value)}
                        min={1}
                        max={100}
                        disabled={attribute.name === ""}
                    />
                    <Typography variant="body2" className={classes.sliderValue}>
                        {attribute.threshold ? `${attribute.threshold}%` : '67%'}
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell className={classes.paddingTop24}>
                <PatternInput
                    displayName="name"
                    values={[...attribute.patterns]}
                    displayCount={1}
                    enableAddButton
                    isSemantics
                    onSave={(pattern, index) => savePattern(pattern, index)}
                    onDelete={(index) => removePattern(index)}
                    disabled={attribute.name === ""}
                    chipSize={60}
                />
            </TableCell>
            <TableCell>
                <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
                    <Grid item>
                        <Typography variant="body2" className={classes.marginBottom5}>
                            {'Equals'}
                        </Typography>
                        <ChipInput
                            values={[...attribute.keyword.equals]}
                            displayCount={1}
                            name="equals"
                            onSave={(_property, value, index) => saveKeywordValue("equals", value, index)}
                            onDelete={(index) => removeKeywordValue("equals", index)}
                            disabled={attribute.name === ""}
                            chipSize={60}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h2" className={classes.Rangehyphen}>
                            {'-'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="body2" className={classes.marginBottom5}>
                            {'Contains'}
                        </Typography>
                        <ChipInput
                            values={[...attribute.keyword.contains]}
                            displayCount={1}
                            name="contains"
                            onSave={(_property, value, index) => saveKeywordValue("contains", value, index)}
                            onDelete={(index) => removeKeywordValue("contains", index)}
                            disabled={attribute.name === ""}
                            chipSize={60}
                        />
                    </Grid>
                </Grid>
            </TableCell>
            <TableCell className={classNames(classes.tableChips, classes.paddingTop24)}>
                <ChipInput
                    values={[...attribute.enum.values]}
                    displayName="value"
                    displayCount={1}
                    name="enum"
                    onSave={(_property, value, index) => saveEnumValue("enum", value, index)}
                    onDelete={(index) => removeEnumValue("enum", index)}
                    disabled={attribute.name === ""}
                    chipSize={100}
                />
            </TableCell>
            <TableCell>
            <Grid container alignItems="center" justify="center" className={classes.sematicTabAction}>
                <Switch color="secondary" size="small" checked={attribute.is_active} onChange={() => onChange('is_active', !attribute.is_active)} />
                <ToolTipComponent title={"Delete Attribute"} arrow>
                    <IconButton onClick={() => onDelete(attribute)}>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 32 32">
                            <g id="Delete">
                                <path fill={theme.palette.primary.main}
                                    stroke={theme.palette.primary.main}
                                    strokeWidth="0.92"
                                    strokeMiterlimit="10"
                                    d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                <path fill={theme.palette.primary.main}
                                    stroke={theme.palette.primary.main}
                                    strokeWidth="0.92"
                                    strokeMiterlimit="10"
                                    d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                            </g>
                        </svg>
                    </IconButton>
                </ToolTipComponent>
            </Grid>
            </TableCell>

        </TableRow>
    );
};

SemanticModelAttributeRow.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    modelId: PropTypes.string,
    attribute: PropTypes.object,
    onChangeProperty: PropTypes.func,
    onValueChange: PropTypes.func,
    onDelete: PropTypes.func
};

export default withStyles((theme) => ({
    ...SemanticModelStyles(theme),
    ...MasterStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SemanticModelAttributeRow);