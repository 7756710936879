import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { Grid, withStyles, Button, Typography } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Styles from '../../layouts/Styles.jsx';
import MasterStyles from '../Master/MasterStyles.jsx';
import TextBox from '../../components/TextBox/TextBox.jsx';
import { createSemanticModel } from '../../actions/modelActions';


const CreateSemanticModel = (props) => {
    const { classes, history, getPageTitle } = props;
    const [masterModel, setMasterModel] = useState({ name: '', description: '' });
    const dispatch = useDispatch();

    useEffect(() => {
        getPageTitle('Create Sematic Model');
    });

    const onChange = useCallback((property, value) => {
        masterModel[property] = value;
        setMasterModel({ ...masterModel });
    }, [masterModel]);

    const createModel = useCallback(() => {
        dispatch(createSemanticModel(masterModel)).then((response) => {
            if (response) {
                history.push({ pathname: '/model' });
                // history.push({ pathname: `/model/${response.id}`, state: { model: response } });
            }
        });
    }, [dispatch, history, masterModel]);

    return (
        <ValidatorForm onSubmit={() => createModel()} className={classes.container}>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.contentContainer}>

                    <Grid container>
                        <Grid item sm={12} className={classes.Headercontainer}>
                            <Grid container alignItems="center" className={classnames(classes.marginTop30, classes.height32)}>
                                <Grid item sm={6}>
                                    <Typography variant="h1">
                                        {'Create Semantic Model'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} className={classes.subTitleContainer}>
                            <Typography variant="subtitle1">
                                {'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem'}
                            </Typography>
                            {/* <Typography variant="subtitle1" >
                                {'it usable, accurate, and trustworthy. Select a Datasource Type below.'}
                            </Typography> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.formContainer}>
                    <Grid container justify="space-between">
                        <Grid item xs={5}>
                            <TextBox
                                name="name"
                                label="Model Name"
                                value={masterModel.name ? masterModel.name : ''}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                validators={['required', 'matchRegexp:^[A-Za-z][A-Za-z0-9]+$']}
                                errorMessages={['Model Name is required', 'Model Name should be without special characters']}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.descriptionContainer}>
                            <TextBox
                                rows={4}
                                name="description"
                                label="Description"
                                value={masterModel.description ? masterModel.description : ''}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item className={classes.btnCancelItem}>
                        <Button type="submit"
                            color="primary"
                            variant="contained"
                            className={classnames(classes.actionButtons, classes.actionSubmit)}>
                            {'Submit'}
                        </Button>
                        <Button className={classnames(classes.cancelButton, classes.actionButtons)}
                            onClick={() => history.goBack()}>
                            {'Cancel'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
};

CreateSemanticModel.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    getPageTitle: PropTypes.func
};

export default withStyles((theme) => ({
    ...MasterStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(CreateSemanticModel);