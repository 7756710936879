import React, { useEffect, useState, useCallback } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import ChartStyles from './ChartStyles.jsx';
import { appConstants } from '../../constants/appConstants';
import { createRule, getFieldType } from '../RuleBuilder/QueryBuilderUtil.jsx';
import { useSelector } from 'react-redux';
import ProfileChartFilterOperators from '../../constants/chartOperators.js';

const SoundxChart = (props) => {
    const { attribute, profileData, classes, theme, tabIndex, onFilter, profileRuleEnable, isActive, attributeChange } = props;
    const datasource = useSelector(({ datasource }) => datasource.datasource);
    const [menuType, setMenuType] = useState(appConstants.soundexRangeFilterOptions[1]);
    const [chartData, setChartData] = useState({});
    const [edited, setEdited] = useState(false);

    const loadChartData = useCallback((chartData) => {
        setChartData({ ...chartData });
    }, []);

    useEffect(() => {
        if (attributeChange) {
            const chartData = profileData['Soundex Frequency'];
            loadChartData({ ...chartData });
        }
    }, [attribute, attributeChange, loadChartData, profileData]);

    const onFilterChange = useCallback((soundexValue) => {
        const inputParams = {
            chartType: appConstants.charts.soundex.type,
            chartName: appConstants.charts.soundex.name,
            filterOptions: false,
            defaultQuery: ''
        };

        if (soundexValue) {
            inputParams.data = soundexValue;
            const ruleParams = {
                defaultField: attribute.name,
                defaultFieldType: getFieldType(attribute.type),
                attributeType: attribute.type,
                connectionType: datasource?.type,
                isScan: datasource?.scan
            };
            const rule = createRule(ruleParams);
            const operators = ProfileChartFilterOperators.Soundex;
            rule.operator = { ...operators[0] };
            rule.value = soundexValue.soundex;
            inputParams.rule = rule;
            inputParams.filterType = appConstants.QueryFilterTypes[2];
        }

        onFilter(inputParams);
    }, [attribute.name, attribute.type, datasource?.scan, datasource?.type, onFilter]);

    const onChangeMenu = (value) => {
        setMenuType(value);
        setEdited(true);
    };

    const discardEdit = useCallback(() => {
        setEdited(false);
    }, []);

    useEffect(() => {
        if (chartData && Object.keys(chartData).length !== 0 && (attributeChange || edited)) {
            if (edited) {
                discardEdit();
            }
            const valueType = menuType === appConstants.soundexRangeFilterOptions[0] ? 'topN' : 'bottomN';
            const selectedPropData = chartData.frequency_count ? chartData.frequency_count.soundex[valueType] : [];
            for (let index = 0; index < selectedPropData.length; index++) {
                selectedPropData[index].index = index;
            }
            const margin = { top: 40, right: 20, bottom: 50, left: 40 };
            let width = 960 - margin.left - margin.right;
            const height = 300;
            // const tickCount = 10;

            d3.select(".soundex-frequency >*").remove();
            const svg = d3.select(".soundex-frequency").append("svg")
                .attr("width", "100%")
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("width", (d, i, p) => {
                    width = p[i].parentNode.clientWidth - 50;
                    return width;
                })
                .attr("height", height + margin.top + margin.bottom)
                .attr("transform",
                    "translate(" + (margin.left + 40) + "," + (margin.top / 2) + ")");

            const minX = d3.min(selectedPropData, (d) => { return d.index; });
            const maxX = d3.max(selectedPropData, (d) => { return d.index; });
            const xScale = d3.scaleLinear()
                .domain([minX, maxX])
                .range([0, width - (margin.left + margin.right)]);

            const minY = d3.min(selectedPropData, (d) => { return d.count; });
            const maxY = d3.max(selectedPropData, (d) => { return d.count; });
            const yScale = d3.scaleLinear()
                .domain([minY, maxY])
                .range([height, 0])
                .nice();

            // add the x Axis
            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .attr("color", theme.palette.chartColors.axis)
                .attr('class', 'axis-x')
                .transition()
                .duration(2000)
                .call(d3.axisBottom(xScale).ticks(10));

            // text label for the x axis
            svg.append("text")
                .attr("transform", "translate(" + (width / 2) + " ," + (height + margin.top + 10) + ")")
                .style("text-anchor", "middle")
                .text("Total Records");

            // add the y Axis
            svg.append("g")
                .attr("color", theme.palette.chartColors.axis)
                .attr('class', 'axis-y')
                .transition()
                .duration(2000)
                .call(d3.axisLeft().scale(yScale).ticks(10));
            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');

            // text label for the y axis
            svg.append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - (margin.left + 30))
                .attr("x", 0 - (height / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text("Value");

            svg.append("g")
                .selectAll("scatter-dots")
                .data(selectedPropData)
                .enter().append("circle")
                .attr("cx", 0)
                .attr("cy", (d) => {
                    return yScale(d.count);
                })
                .attr("r", 5)
                .style("fill", theme.palette.chartColors.soundex)
                .on('mouseover', (d, i, p) => {
                    const circle = d3.select(p[i]);
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html(`Soundex Name : ${d.soundex} <br/> Count : ${d.count} <br/> Values: ${d.value}`)
                        .style("left", circle.attr("cx") + "px")
                        .style("top", (circle.attr("cy") - 50) + "px");
                })
                .on('mouseout', () => {
                    // Hide Tooltip
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .on('click', (d) => {
                    onFilterChange(d);
                });

            d3.select(".soundex-frequency > .tooltip").remove();
            const div = d3.select(".soundex-frequency").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            svg.selectAll("circle")
                .transition()
                .delay((d, i) => { return (Number(i)); })
                .duration(2000)
                .attr("cx", (d) => { return xScale(d.index); })
                .attr("cy", (d) => { return yScale(d.count); });
        }
    }, [attributeChange, chartData, discardEdit, edited, menuType, onFilterChange, tabIndex, theme.palette.chartColors.axis, theme.palette.chartColors.soundex]);

    const onRuleChange = (value) => {
        profileRuleEnable(appConstants.charts.soundex.type, value);
    };

    return (
        <ChartContainer
            menuProperties={
                [
                    {
                        options: appConstants.soundexRangeFilterOptions,
                        onChange: onChangeMenu,
                        value: menuType
                    }
                ]
            }
            editProperties={
                {
                    edited: false,
                    edit: false
                }
            }
            filterProperties={
                {
                    enableFilter: false,
                    onFilter: onFilterChange
                }
            }
            ruleProperties={
                {
                    edit: true,
                    onRule: onRuleChange,
                    rule: isActive
                }
            }
            id={appConstants.charts.soundex.id}
            title={appConstants.charts.soundex.name}
            chartData={chartData} >
            <Grid container className={classNames(classes.chartStyles, "soundex-frequency")} />
        </ChartContainer >
    );
};

SoundxChart.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    attribute: PropTypes.object,
    profileData: PropTypes.object,
    tabIndex: PropTypes.number,
    onFilter: PropTypes.func,
    profileRuleEnable: PropTypes.func,
    isActive: PropTypes.bool,
    attributeChange: PropTypes.bool
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SoundxChart);