import React, { memo } from 'react';
import {
    Grid, withStyles, Typography,
    Card, Table, TableBody, TableCell, TableRow, TableHead
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import DatasetStyles from '../Dataset/DatasetStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const DataPreviewTable = (props) => {
    const { classes, theme, previewData, headers } = props;

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classNames(classes.attributesTableContainer, classes.marginTop15, classes.dataContainer)} style={{ position: 'relative' }}>
                {
                    headers && headers.length > 0 &&
                    <Grid>
                        <Card className={classes.accordian}>
                            <Grid container className={classNames(classes.attributesTable)}>
                                <Table stickyHeader aria-label="sticky table" className={classes.domainDataTable} cellSpacing={1}>
                                    <TableHead>
                                        <TableRow>
                                            {
                                                headers &&
                                                headers.map((header, index) => {
                                                    return (
                                                        <TableCell key={`header${index}`} style={{ padding: '2px 10px 2px 10px', maxWidth: '100%', backgroundColor: theme.palette.common.white }}>
                                                            <Grid className={classes.inlineBlock}>
                                                                <Typography className={classNames(classes.typeText, classes.AttritypeText)} style={{ textTransform: 'none' }}>
                                                                    {header}
                                                                </Typography>
                                                            </Grid>
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                        {
                                            previewData.inputData && previewData.inputData.length > 0 &&
                                            previewData.inputData.map((row, index) => (
                                                <TableRow key={`invalidInputData${index}`}>
                                                    {
                                                        headers &&
                                                        headers.map((header, index) => (
                                                            <TableCell key={`header_${index}`}
                                                                align="left"
                                                            >
                                                                <ToolTipComponent title={row[header] ? row[header] : ''} arrow>
                                                                    <Typography noWrap style={{ height: row[header] ? 'auto' : '42px' }}>
                                                                        {row[header]}
                                                                    </Typography>
                                                                </ToolTipComponent>
                                                            </TableCell>
                                                        ))
                                                    }
                                                </TableRow>
                                            ))
                                        }

                                    </TableBody>
                                </Table>
                            </Grid>
                        </Card>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};

DataPreviewTable.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    previewData: PropTypes.object,
    headers: PropTypes.array
};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(memo(DataPreviewTable));