const MasterStyles = (theme) => ({
    subTitleContainer: {
        padding: "0 10px",
        marginTop: 10
    },
    formContainer: {
        marginTop: 15,
        backgroundColor: theme.palette.background.paper,
        padding: 15,
        height: 'calc(100vh - 170px)'
    },
    descriptionContainer: {
        marginTop: 10,
        marginBottom: 25
    },
    actionSubmit: {
        marginRight: 10
    }
});
export default MasterStyles;