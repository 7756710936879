const AccountStyles = (theme) => ({
	root: {
		flexGrow: '1 auto',
		height: '100%'
	},
	container: {
		height: '100%'
	},
	section: {
		height: '100%',
		padding: '40px 90px',
		backgroundColor: theme.palette.background.paper
	},
	btnContainer: {
		marginTop: 45
	},
	logo: {
		marginTop: 60,
		// height: 42
		height: 'auto',
		width: '150px'
	},
	copyrightText: {
		color: theme.palette.grey.main
	},
	accountlabel: {
		color: theme.palette.grey.main,
		marginBottom: 15
	},
	accountTitle: {
		marginBottom: 5
	},
	accountTitleLogin: {
		marginBottom: 15
	},
	sliderContainer: {
		'& .owl-dots': {
			position: 'absolute',
			bottom: 10,
			left: 0,
			right: 0,
			textAlign: 'center',
			display: 'none'
		},
		'& .owl-dot.active': {
			backgroundColor: '#B4B9CC'
		},
		'& .owl-dot': {
			display: 'inline-block',
			backgroundColor: '#EBECF0',
			width: 18,
			height: 5,
			marginRight: 5
		}
	},
	bgNone: {
		'&:hover': {
			backgroundColor: 'rgb(0 0 0 / 0%) !important',
			color: theme.palette.primary.dark
		},
		'& .MuiTouchRipple-root': {
			display: 'none !important'
		}
	},
	pageContainer: {
		'&.owl-dots': {
			display: "none"
		}
	},
	bannerBg: {
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		height: '100%',
		backgroundRepeat: 'no-repat'
	},
	Linkhover: {
		'&:hover .MuiButton-label': {
			color: theme.palette.primary.dark
		},
		'&:hover': {
			backgroundColor: 'transparent !important'
		}
	},
	validationTextBox: {
		height: 64
	}

});

export default AccountStyles;