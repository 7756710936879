import React, { useState, useCallback, useEffect } from "react";
import PropTypes from 'prop-types';
import { useDispatch } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { getVersionDetails } from "../../actions/settingActions";

const AboutUS = (props) => {
  const [vesions, setVesions] = useState([]);
  const dispatch = useDispatch();

  const getVersions = useCallback(() => {
    dispatch(getVersionDetails()).then((response) => {
      if (response) {
        setVesions([...response]);
      }
    });
  }, [dispatch]);

  useEffect(() => {
    getVersions();
  }, [getVersions]);

  useEffect(() => {
    props.getPageTitle('About Us');
  });

  return (
    <Grid>
      {
        <div>
          <Typography variant="h3" style={{ marginTop: 15, marginBottom: 15 }}>
            {"Vesion Details"}
          </Typography>
          {
            vesions.map((d) => (
              <Grid key={d.name}>
                {d.name}
                -
                {d.version}
              </Grid>
            ))
          }
        </div>
      }
    </Grid>
  );
};


AboutUS.propTypes = {
  getPageTitle: PropTypes.func
};

export default AboutUS;