const ScheduleStyles = (theme) => ({
    root: {
        flexGrow: '1 auto',
        height: '100%'
    },
    scheduleContainer: {
        maxWidth: 450,
        minWidth: 450,
        padding: '15px 25px',
        '& .active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.paper
        }
    },
    scheduleContent: {
        padding: '20px 10px 15px 10px'
    },
    schedulerSection: {
        border: `1px solid ${theme.palette.border.default}`
    },
    tabBtn: {
        fontSize: '13px !important',
        '&:hover': {
            boxShadow: 'none'
        }
    },
    dayBtn: {
        fontSize: '12px !important',
        '&:hover': {
            boxShadow: 'none'
        }
    },
    TimingBtnGroup: {
        '& .MuiButtonGroup-grouped': {
            minWidth: "80px"
        }
    },
    monitoringContainer: {
        '& .active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.paper
        }
    },
    timeZone: {
        '& .MuiPaper-root': {
            left: 'auto !important',
            bottom: '0px !important',
            position: 'absolute'
        }
    },
    schedulerTimeChip: {
        '&:hover': {
            '& .MuiChip-deleteIcon': {
                display: 'block'
            }
        }
    },
    tabs: {
        '& .MuiTabs-indicator': {
            width: '100% !important'
        }
    }

});

export default ScheduleStyles;