import React, { useEffect, useState, useCallback } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import ChartStyles from './ChartStyles.jsx';
import { getValue } from '../../helpers/appHelpers';

const HorizontalBarChart = (props) => {
    const { classes, theme, attribute, analysis, chartClass, ruleType, chartProps, enableLearning } = props;
    const [chartData, setChartData] = useState([]);

    const getChartProperties = useCallback((analysisData) => {
        setChartData([...analysisData]);
    }, []);

    useEffect(() => {
        getChartProperties([...analysis]);
    }, [attribute, getChartProperties, analysis]);

    const openDialog = useCallback((rule) => {
        enableLearning(rule, ruleType);
    }, [enableLearning, ruleType]);

    useEffect(() => {
        if (chartData && chartData.length !== 0) {
            const margin = { top: 40, right: 20, bottom: 50, left: 100 };
            let width = 960 - margin.left - margin.right;
            const height = 340;
            const barHeight = 20;
            const barOpacity = 0.5;
            d3.select(`.${chartClass} > *`).remove();
            d3.select(`.${chartClass} svg`).remove();
            const svg = d3.select(`.${chartClass}`).append("svg")
                .attr("width", "100%")
                .attr("height", (height + margin.top + margin.bottom))
                .append("g")
                .attr("width", (d, i, p) => {
                    width = p[i].parentNode.clientWidth === 0 ? document.getElementById("tab-container").clientWidth : p[i].parentNode.clientWidth;
                    return width;
                })
                .attr("height", height + margin.top + margin.bottom - 10)
                .attr("transform",
                    "translate(" + (margin.left + 65) + "," + margin.right + ")");
            d3.select(`.${chartClass} >.tooltip`).remove();
            const div = d3.select(`.${chartClass}`).append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            const xScale = d3.scaleLinear()
                .domain(d3.extent(chartData, (d) => d.changed_record_percentage))
                .range([0, width - (margin.left + margin.right)])
                .nice();
            const yScale = d3.scaleBand()
                .range([0, height])
                .domain(chartData.map((d) => { return d.rule_name; }))
                .padding(1);
            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .attr("color", theme.palette.chartColors.axis)
                .attr('class', 'axis-x')
                .call(d3.axisBottom(xScale).ticks(10));
            svg.append("g")
                .attr("color", theme.palette.chartColors.axis)
                .attr('class', 'axis-y')
                .call(d3.axisLeft(yScale));
            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');

            svg.selectAll('.axis-y .tick-text').on('click', (_, i) => {
                openDialog(chartData[i]);
            });
            svg.selectAll("bar")
                .data(chartData)
                .enter()
                .append('rect')
                .attr("height", 20)
                .attr("x", 0)
                .attr("y", (d) => { return yScale(d.rule_name) + ((yScale.bandwidth() / 2) - (barHeight / 2)); })
                .attr("width", 0)
                .attr('fill', theme.palette.chartColors.presense)
                .style("opacity", barOpacity)
                .on('mouseover', (d, i, nodes) => {
                    let x = 0;
                    let y = 0;
                    div.attr("transform", (_, i, nodes) => {
                        const mouseCoords = d3.mouse(nodes[i].parentNode);
                        let xCo = 0;
                        let yCo = 0;
                        if (mouseCoords[0] + 10 >= width * 0.80) {
                            xCo = mouseCoords[0] - parseFloat(div
                                .attr("width"));
                            yCo = mouseCoords[1] + 10;
                        } else {
                            xCo = mouseCoords[0] + 10;
                            yCo = mouseCoords[1];
                        }
                        x = xCo;
                        y = yCo;
                    });
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html(`Impact Percentage : ${getValue(d.changed_record_percentage)}`)
                        .style("left", x + "px")
                        .style("top", y + "px");
                })
                .on('mouseout', (_, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", barOpacity);
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .on('click', (d) => {
                    openDialog(d);
                })
                .transition()
                .duration(500)
                .attr("width", (d) => {
                    return xScale(d.changed_record_percentage);
                });

            const xAxisHeight = height + (margin.top + 10);
            svg.append("text")
                .attr("transform",
                    "translate(" + ((width / 2) - 140) + " ," +
                    (xAxisHeight) + ")")
                .style("text-anchor", "middle")
                .text("Percentage");
        }
    }, [chartClass, chartData, openDialog, theme.palette.chartColors.axis, theme.palette.chartColors.impactValid, theme.palette.chartColors.presense]);

    return (
        <ChartContainer
            title={chartProps.name}
            id={chartProps.id}
            chartData>
            <Grid container className={classNames(classes.chartStyles, chartClass)} />
        </ChartContainer>
    );
};

HorizontalBarChart.propTypes = {
    classes: PropTypes.object,
    attribute: PropTypes.string,
    analysis: PropTypes.object,
    theme: PropTypes.object,
    chartClass: PropTypes.string,
    ruleType: PropTypes.string,
    chartProps: PropTypes.object,
    enableLearning: PropTypes.func
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(HorizontalBarChart);