export const dataPreparationActions = {
    CLEAR_TRANSFORMATION: 'CLEAR_TRANSFORMATION',

    PREVIEW_TRANSFORMATION_REQUEST: 'PREVIEW_TRANSFORMATION_REQUEST',
    PREVIEW_TRANSFORMATION_SUCCESS: 'PREVIEW_TRANSFORMATION_SUCCESS',
    PREVIEW_TRANSFORMATION_FAILURE: 'PREVIEW_TRANSFORMATION_FAILURE',

    APPLY_TRANSFORMATION_REQUEST: 'APPLY_TRANSFORMATION_REQUEST',
    APPLY_TRANSFORMATION_SUCCESS: 'APPLY_TRANSFORMATION_SUCCESS',
    APPLY_TRANSFORMATION_FAILURE: 'APPLY_TRANSFORMATION_FAILURE',

    CREATE_DATA_PREPARATION_REQUEST: 'CREATE_DATA_PREPARATION_REQUEST',
    CREATE_DATA_PREPARATION_SUCCESS: 'CREATE_DATA_PREPARATION_SUCCESS',
    CREATE_DATA_PREPARATION_FAILURE: 'CREATE_DATA_PREPARATION_FAILURE',

    GET_ALL_DATA_PREPARATION_REQUEST: 'GET_ALL_DATA_PREPARATION_REQUEST',
    GET_ALL_DATA_PREPARATION_SUCCESS: 'GET_ALL_DATA_PREPARATION_SUCCESS',
    GET_ALL_DATA_PREPARATION_FAILURE: 'GET_ALL_DATA_PREPARATION_FAILURE',

    GET_DATA_PREPARATION_REQUEST: 'GET_DATA_PREPARATION_REQUEST',
    GET_DATA_PREPARATION_SUCCESS: 'GET_DATA_PREPARATION_SUCCESS',
    GET_DATA_PREPARATION_FAILURE: 'GET_DATA_PREPARATION_FAILURE',

    UPDATE_DATA_PREPEATION_REQUEST: 'UPDATE_DATA_PREPEATION_REQUEST',
    UPDATE_DATA_PREPEATION_SUCCESS: 'UPDATE_DATA_PREPEATION_SUCCESS',
    UPDATE_DATA_PREPEATION_FAILURE: 'UPDATE_DATA_PREPEATION_FAILURE',

    DELETE_DATA_PREPARATION_REQUEST: 'DELETE_DATA_PREPARATION_REQUEST',
    DELETE_DATA_PREPARATION_SUCCESS: 'DELETE_DATA_PREPARATION_SUCCESS',
    DELETE_DATA_PREPARATION_FAILURE: 'DELETE_DATA_PREPARATION_FAILURE',

    GET_DATASET_ATTRIBUTE_REQUEST: 'GET_DATASET_ATTRIBUTE_REQUEST',
    GET_DATASET_ATTRIBUTE_SUCCESS: 'GET_DATASET_ATTRIBUTE_SUCCESS',
    GET_DATASET_ATTRIBUTE_FAILURE: 'GET_DATASET_ATTRIBUTE_FAILURE',

    GET_DATA_PREPARATION_CONFIGURATION_REQUEST: 'GET_DATA_PREPARATION_CONFIGURATION_REQUEST',
    GET_DATA_PREPARATION_CONFIGURATION_SUCCESS: 'GET_DATA_PREPARATION_CONFIGURATION_SUCCESS',
    GET_DATA_PREPARATION_CONFIGURATION_FAILURE: 'GET_DATA_PREPARATION_CONFIGURATION_FAILURE',

    UPDATE_DATA_PREPEATION_CONFIGURATION_REQUEST: 'UPDATE_DATA_PREPEATION_CONFIGURATION_REQUEST',
    UPDATE_DATA_PREPEATION_CONFIGURATION_SUCCESS: 'UPDATE_DATA_PREPEATION_CONFIGURATION_SUCCESS',
    UPDATE_DATA_PREPEATION_CONFIGURATION_FAILURE: 'UPDATE_DATA_PREPEATION_CONFIGURATION_FAILURE',

    CREATE_DATA_PREPEATION_CONFIGURATION_REQUEST: 'CREATE_DATA_PREPEATION_CONFIGURATION_REQUEST',
    CREATE_DATA_PREPEATION_CONFIGURATION_SUCCESS: 'CREATE_DATA_PREPEATION_CONFIGURATION_SUCCESS',
    CREATE_DATA_PREPEATION_CONFIGURATION_FAILURE: 'CREATE_DATA_PREPEATION_CONFIGURATION_FAILURE',

    PUSH_TRANSFORMED_DATASET_REQUEST: 'PUSH_TRANSFORMED_DATASET_REQUEST',
    PUSH_TRANSFORMED_DATASET_SUCCESS: 'PUSH_TRANSFORMED_DATASET_SUCCESS',
    PUSH_TRANSFORMED_DATASET_FAILURE: 'PUSH_TRANSFORMED_DATASET_FAILURE',

    DELETE_DATA_TRANSFORM_REQUEST: 'DELETE_DATA_TRANSFORM_REQUEST',
    DELETE_DATA_TRANSFORM_SUCCESS: 'DELETE_DATA_TRANSFORM_SUCCESS',
    DELETE_DATA_TRANSFORM_FAILURE: 'DELETE_DATA_TRANSFORM_FAILURE',

    GET_TRANSFORMATION_REQUEST: 'GET_TRANSFORMATION_REQUEST',
    GET_TRANSFORMATION_SUCCESS: 'GET_TRANSFORMATION_SUCCESS',
    GET_TRANSFORMATION_FAILURE: 'GET_TRANSFORMATION_FAILURE',

    DELETE_TRANSFORM_REQUEST: 'DELETE_TRANSFORM_REQUEST',
    DELETE_TRANSFORM_SUCCESS: 'DELETE_TRANSFORM_SUCCESS',
    DELETE_TRANSFORM_FAILURE: 'DELETE_TRANSFORM_FAILURE'

};