import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from '@material-ui/core/styles';

const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
        color: '#444'
    },
    tooltip: {
        backgroundColor: '#444',
        opacity: 0
    }
}));

/**
 *Tool tip component
 *
 * @class ToolTip
 * @extends {React.Component}
 */
const ToolTipComponent = (props) => {
    const { title, placement, children, arrow, interactive, classeName } = props;
    const tooltipClasses = useStylesBootstrap();

    return (
        <Tooltip classes={tooltipClasses} title={title} interactive={interactive} placement={placement} arrow={arrow} className={classeName}>
            {children}
        </Tooltip>
    );
};

ToolTipComponent.defaultProps = {
    title: "",
    placement: "bottom",
    classeName: "",
    arrow: false,
    TooltipComponent: null,
    interactive: false,
    classes: {},
    children: {}
};

ToolTipComponent.propTypes = {
    title: PropTypes.string.isRequired,
    placement: PropTypes.string.isRequired,
    children: PropTypes.object,
    classeName: PropTypes.string,
    interactive: PropTypes.bool,
    arrow: PropTypes.bool
};

export default ToolTipComponent;