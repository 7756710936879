import React, { useCallback } from 'react';
import { Grid, withStyles, Typography, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DatasourceListStyles from '../DatasourceList/DatasourceListStyles.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import Circular from '../Charts/Circular.jsx';
import Styles from '../../layouts/Styles.jsx';
import Logo from '../../assets/images/dqicon_placeholder.svg';
import DatasourceStyles from '../Datasource/DatasourceStyles.jsx';
import DomainStyles from './DomainStyles.jsx';
import ViewIcon from '../../assets/images/eye3.svg';
import { toggleSidePanel } from '../../actions/notificationActions';
import Lottie from 'react-lottie';

import animationData from '../../assets/json/heartanim.json';

const Domain = (props) => {
    const { classes, domain, breadcrumb, redirectCatalog, loading, onChange, onSave, onChangeTab, dq_score_threshold } = props;
    const dispatch = useDispatch();


    const showPanel = useCallback((type) => {
        dispatch(toggleSidePanel({ type: type, sidePanel: true }));
    }, [dispatch]);

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <Grid>
            <Grid container className={classNames(classes.marginBottom5, classes.domainInfoContainer)}>
                {
                    breadcrumb && breadcrumb.map((crumb, index) =>
                        <Typography variant="body2" key={`crumb_${index}`} component="span" className={classes.link} onClick={() => (crumb.path !== "" ? redirectCatalog() : null)}>
                            {crumb.name}
                            {index !== breadcrumb.length - 1 && ' | '}
                        </Typography>
                    )
                }
            </Grid>
            <Grid container justify="space-between">
                <Grid item className={classes.datasourceTitle}>
                    <Grid className={classes.defaultIconContainer}>
                        <img src={domain.image_url ? domain.image_url : Logo} alt="logo" className={classNames(classes.datasourceIcon, classes.datasourceIconSelectImg)} />
                    </Grid>
                    <TextBox
                        name="name"
                        placeholder="Domain"
                        value={domain.name ? domain.name : ''}
                        className={classNames(classes.inlinetxt, classes.headerTitleTxt)}
                        onChange={(event) => onChange(event.target.name, event.target.value)}
                        onBlur={() => onSave('name')}
                        error={!loading && domain.name === ""}
                        helperText={!loading && domain.name === "" ? "Please enter the domain name" : ""}
                    />
                    <span onClick={() => onChangeTab(1)}>
                        <Circular
                            showPercentage={Boolean(domain.dqscore && domain.dqscore !== 0)}
                            showValue
                            value={domain.dqscore}
                            size={40}
                            dqScoreThreshold={dq_score_threshold}
                        />
                    </span>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item className={classes.datasourceDetailIcons}>
                    <img className={classes.datasourceIcon} src={ViewIcon} alt="view" style={{ width: "22px", height: "21px" }} />
                    <Typography className={classes.datasourceDetailText} variant="body2">
                        {`${domain.views ? domain.views : 0} Views`}
                    </Typography>
                </Grid>
                <Grid item className={classNames(classes.datasourceDetailIcons, classes.cursor)} onClick={() => showPanel('actionitem')}>
                    <span className={classes.relative}>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" className={classes.datasourceIcon} width="23" height="20" viewBox="0 0 32 32" >
                            <g id="Actions_1_">
                                <path fill="#AFB2B3"
                                    d="M23.1,16.7v-1.6v-0.6c-0.5,0.3-1,0.5-1.6,0.5c-0.1,0-0.2,0-0.3,0v0.1v1.6c-2.9,0.4-5.2,2.9-5.2,5.9c0,0.7,0.1,1.4,0.3,2H5.9v-19h11.7l1.3,1.9c0.5-0.6,1-1.1,1.7-1.3l-2-2.8H4.1v23.5h13.8c1.1,1.1,2.6,1.8,4.2,1.8c3.3,0,6-2.7,6-6C28.1,19.7,25.9,17.2,23.1,16.7z M22.1,26.6c-2.2,0-4-1.8-4-4s1.8-4,4-4c2.2,0,4,1.8,4,4S24.3,26.6,22.1,26.6z" />
                                <g>
                                    <rect x="7.6" y="10.2" fill="#AFB2B3" width="5.5" height="2.2" />
                                    <rect x="15.3" y="10.2" fill="#AFB2B3" width="2.5" height="2.2" />
                                    <rect x="7.6" y="14" fill="#AFB2B3" width="5.5" height="2.2" />
                                    <rect x="7.6" y="17.8" fill="#AFB2B3" width="5.5" height="2.2" />
                                    <rect x="15.3" y="14" fill="#AFB2B3" width="2.5" height="2.2" />
                                    <polyline fill="none" stroke="#AFB2B3" strokeWidth="2" strokeMiterlimit="10" points="20.1,21.6 21.4,23.6 25.1,21.6 25.1,21.6 " />
                                </g>
                                <circle fill="#54DF9A" cx="21.5" cy="10.5" r="3" />
                            </g>
                        </svg>
                    </span>

                    <Typography className={classes.datasourceDetailText} variant="body2">
                        {`${domain.actionitems ? domain.actionitems : 0} Action Items`}
                    </Typography>
                </Grid>
                <Grid item className={classNames(classes.cursor, classes.datasourceDetailIcons)} onClick={() => showPanel('conversation')}>
                    <span className={classes.relative}>
                        <svg className={classes.datasourceIcon} version="1.1" id="Layer_1" width="23" height="23.421" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 32 32">
                            <g id="conversation_1_">
                                <g>
                                    <path fill="#AFB2B3" d="M13.5,18.4c0.6,0,1-0.4,1-0.9c0-0.6-0.4-1-1-1s-1,0.4-1,1C12.5,18,12.9,18.4,13.5,18.4z" />
                                    <polygon fill="#AFB2B3" points="24.9,8.9 26.8,8.9 26.8,7 24.9,7 7.8,7 7.8,8.9 24,8.9 " />
                                    <path fill="#AFB2B3" d="M17.3,18.4c0.6,0,1-0.4,1-0.9c0-0.6-0.4-1-1-1s-1,0.4-1,1C16.3,18,16.7,18.4,17.3,18.4z" />
                                    <path fill="#AFB2B3" d="M9.7,18.4c0.6,0,1-0.4,1-0.9c0-0.6-0.4-1-1-1s-1,0.4-1,1C8.8,18,9.1,18.4,9.7,18.4z" />
                                    <path fill="#AFB2B3" d="M5.9,10.8H4v1.9v11.4V26l5.7-1.9h11.4H23v-1.9v-9.5v-1.9h-1.9H5.9z M21.1,22.2H9.7l-3.8,1.2V12.7h15.2V22.2z" />
                                    <g>
                                        <rect x="24.9" y="15" fill="#AFB2B3" width="1.9" height="4.3" />
                                        <rect x="24.9" y="10.8" fill="#AFB2B3" width="1.9" height="2.2" />
                                    </g>
                                    <circle cx="26" fill="#54DF9A" cy="12" r="3" />
                                </g>
                            </g>
                        </svg>

                    </span>

                    <Typography className={classes.datasourceDetailText} variant="body2">
                        {`${domain.conversations ? domain.conversations : 0} Conversations`}
                    </Typography>
                </Grid>
                <Grid item className={classes.datasourceDetailIcons}>
                    <Button className={classNames(classes.bgNone, classes.animateIcons)} onClick={() => onChange("favorite", !domain.favorite)}>
                        {
                            domain.favorite ?
                                <Lottie options={defaultOptions}
                                    height={32}
                                    width={32}
                                    position="absolute"
                                />
                                :
                                <svg version="1.1"
                                    className={classes.datasourceIcon}
                                    id="Layer_1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    x="0px"
                                    y="0px"
                                    width="19"
                                    height="19"
                                    viewBox="0 0 32 32">
                                    <g id="heart_2_" transform="translate(0 -16.896)">
                                        <g id="Group_1546" transform="translate(0 16.896)">
                                            <g id="Path_249">
                                                <path fill="#AFB2B3"
                                                    d="M25.6,7.3c-2.4-2.5-6.3-2.7-8.9-0.3c-0.1,0.1-0.2,0.2-0.3,0.3L16,7.8l-0.4-0.4C13.2,4.8,9.3,4.6,6.7,7
				c0,0,0,0,0,0C6.6,7.1,6.5,7.2,6.4,7.3c-2.5,2.7-2.5,6.9,0,9.6l9,9.5c0.3,0.3,0.8,0.3,1.2,0c0,0,0,0,0,0l0,0l9-9.5
				C28.1,14.2,28.1,10,25.6,7.3z M24.4,15.8L24.4,15.8L16,24.7l-8.4-8.9c-1.9-2.1-1.9-5.3,0-7.3c1.7-1.9,4.7-2,6.6-0.3c0,0,0,0,0,0
				c0.1,0.1,0.2,0.2,0.3,0.3l1,1.1c0.3,0.3,0.9,0.3,1.2,0l1-1.1c1.7-1.9,4.7-2,6.6-0.3c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3
				C26.4,10.5,26.4,13.7,24.4,15.8L24.4,15.8z" />
                                                <path fill="#AFB2B3"
                                                    d="M16,26.9C16,26.9,16,26.9,16,26.9c-0.3,0-0.6-0.1-0.8-0.3l-9-9.5c-2.6-2.8-2.6-7.1,0-9.9
				c0.1-0.1,0.2-0.2,0.3-0.3c2.6-2.4,6.8-2.3,9.2,0.3L16,7.4l0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c1.3-1.2,2.9-1.8,4.7-1.7
				c1.7,0.1,3.3,0.8,4.5,2.1l0,0c2.6,2.8,2.6,7.1,0,9.9l-9.1,9.6C16.5,26.8,16.3,26.9,16,26.9z M11,5.5c-1.5,0-3,0.5-4.1,1.6
				C6.8,7.3,6.6,7.4,6.5,7.5c-2.4,2.6-2.4,6.7,0,9.3l9,9.5c0.2,0.2,0.6,0.2,0.8,0l9-9.5c2.4-2.6,2.4-6.7,0-9.3
				c-1.1-1.2-2.6-1.9-4.2-1.9c-1.6-0.1-3.2,0.5-4.3,1.6c-0.1,0.1-0.2,0.2-0.3,0.3L16,8.1l-0.6-0.6C14.2,6.2,12.6,5.5,11,5.5z M16,25
				l-8.6-9.1c-2-2.1-2-5.5,0-7.6c0.9-1,2.1-1.5,3.4-1.6c1.3-0.1,2.5,0.4,3.5,1.3c0.1,0.1,0.2,0.2,0.3,0.3l1,1.1
				c0.2,0.2,0.6,0.2,0.9,0l1-1.1c0.9-1,2.1-1.5,3.4-1.6c1.3-0.1,2.5,0.4,3.5,1.3c0.1,0.1,0.2,0.2,0.3,0.3c1.9,2,2,5.1,0.3,7.3h0
				l-0.4,0.4L16,25z M11,7.2c-0.1,0-0.1,0-0.2,0c-1.2,0-2.3,0.6-3.1,1.4c-1.8,2-1.8,5.1,0,7l8.3,8.7l8.3-8.7c1.8-2,1.8-5.1,0-7
				c-0.1-0.1-0.2-0.2-0.3-0.3c-0.9-0.8-2-1.2-3.2-1.2c-1.2,0-2.3,0.6-3.1,1.4l-1,1.1c-0.4,0.4-1.1,0.4-1.5,0l-1-1.1
				c-0.1-0.1-0.2-0.2-0.3-0.3C13.2,7.6,12.1,7.2,11,7.2z" />
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                        }
                        <Typography className={classNames(classes.datasourceDetailText, classes.iconWithText)} variant="body2">
                            {domain.favorite ? 'Added to Favorites' : 'Add to Favorites'}
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

Domain.propTypes = {
    classes: PropTypes.object,
    domain: PropTypes.object,
    breadcrumb: PropTypes.array,
    redirectCatalog: PropTypes.func,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onChangeTab: PropTypes.func,
    dq_score_threshold: PropTypes.object
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...DomainStyles(theme),
    ...DatasourceStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Domain);