export const datasourceActions = {
    GET_DATASOURCE_REQUEST: 'GET_DATASOURCE_REQUEST',
    GET_DATASOURCE_SUCCESS: 'GET_DATASOURCE_SUCCESS',
    GET_DATASOURCE_FAILURE: 'GET_DATASOURCE_FAILURE',

    UPDATE_DATASOURCE_REQUEST: 'UPDATE_DATASOURCE_REQUEST',
    UPDATE_DATASOURCE_SUCCESS: 'UPDATE_DATASOURCE_SUCCESS',
    UPDATE_DATASOURCE_FAILURE: 'UPDATE_DATASOURCE_FAILURE',

    UPDATE_DATASOURCE_ICON_REQUEST: 'UPDATE_DATASOURCE_ICON_REQUEST',
    UPDATE_DATASOURCE_ICON_SUCCESS: 'UPDATE_DATASOURCE_ICON_SUCCESS',
    UPDATE_DATASOURCE_ICON_FAILURE: 'UPDATE_DATASOURCE_ICON_FAILURE',

    GET_AUDIT_LOGS_REQUEST: 'GET_AUDIT_LOGS_REQUEST',
    GET_AUDIT_LOGS_SUCCESS: 'GET_AUDIT_LOGS_SUCCESS',
    GET_AUDIT_LOGS_FAILURE: 'GET_AUDIT_LOGS_FAILURE',

    CREATE_DATASOURCE_REQUEST: 'CREATE_DATASOURCE_REQUEST',
    CREATE_DATASOURCE_SUCCESS: 'CREATE_DATASOURCE_SUCCESS',
    CREATE_DATASOURCE_FAILURE: 'CREATE_DATASOURCE_FAILURE',

    DELETE_DATASOURCE_REQUEST: 'DELETE_DATASOURCE_REQUEST',
    DELETE_DATASOURCE_SUCCESS: 'DELETE_DATASOURCE_SUCCESS',
    DELETE_DATASOURCE_FAILURE: 'DELETE_DATASOURCE_FAILURE',

    CREATE_AUDITLOG_REQUEST: 'CREATE_AUDITLOG_REQUEST',
    CREATE_AUDITLOG_SUCCESS: 'CREATE_AUDITLOG_SUCCESS',
    CREATE_AUDITLOG_FAILURE: 'CREATE_AUDITLOG_FAILURE',

    CREATE_DATASOURCE_SHARE_REQUEST: 'CREATE_DATASOURCE_SHARE_REQUEST',
    CREATE_DATASOURCE_SHARE_SUCCESS: 'CREATE_DATASOURCE_SHARE_SUCCESS',
    CREATE_DATASOURCE_SHARE_FAILURE: 'CREATE_DATASOURCE_SHARE_FAILURE',

    GET_SOURCE_SHARE_REQUEST: 'GET_SOURCE_SHARE_REQUEST',
    GET_SOURCE_SHARE_SUCCESS: 'GET_SOURCE_SHARE_SUCCESS',
    GET_SOURCE_SHARE_FAILURE: 'GET_SOURCE_SHARE_FAILURE',

    INVITE_SOURCE_SHARE_REQUEST: 'INVITE_SOURCE_SHARE_REQUEST',
    INVITE_SOURCE_SHARE_SUCCESS: 'INVITE_SOURCE_SHARE_SUCCESS',
    INVITE_SOURCE_SHARE_FAILURE: 'INVITE_SOURCE_SHARE_FAILURE',

    GET_PREVIEW_DATA_REQUEST: 'GET_PREVIEW_DATA_REQUEST',
    GET_PREVIEW_DATA_SUCCESS: 'GET_PREVIEW_DATA_SUCCESS',
    GET_PREVIEW_DATA_FAILURE: 'GET_PREVIEW_DATA_FAILURE',

    GET_FAVOURITES_REQUEST: 'GET_FAVOURITES_REQUEST',
    GET_FAVORITES_SUCCESS: 'GET_FAVORITES_SUCCESS',
    GET_FAVORITES_FAILURE: 'GET_FAVORITES_FAILURE',

    UPDATE_FAVOURITES_REQUEST: 'UPDATE_FAVOURITES_REQUEST',
    UPDATE_FAVORITES_SUCCESS: 'UPDATE_FAVORITES_SUCCESS',
    UPDATE_FAVORITES_FAILURE: 'UPDATE_FAVORITES_FAILURE',

    GET_SOURCE_DQSCORE_REQUEST: 'GET_SOURCE_DQSCORE_REQUEST',
    GET_SOURCE_DQSCORE_SUCCESS: 'GET_SOURCE_DQSCORE_SUCCESS',
    GET_SOURCE_DQSCORE_FAILURE: 'GET_SOURCE_DQSCORE_FAILURE',

    GET_DATASET_REQUEST: 'GET_DATASET_REQUEST',
    GET_DATASET_SUCCESS: 'GET_DATASET_SUCCESS',
    GET_DATASET_FAILURE: 'GET_DATASET_FAILURE',

    GET_ATTRIBUTES_REQUEST: 'GET_ATTRIBUTES_REQUEST',
    GET_ATTRIBUTES_SUCCESS: 'GET_ATTRIBUTES_SUCCESS',
    GET_ATTRIBUTES_FAILURE: 'GET_ATTRIBUTES_FAILURE',

    GET_CURATION_RULES_REQUEST: 'GET_CURATION_RULES_REQUEST',
    GET_CURATION_RULES_SUCCESS: 'GET_CURATION_RULES_SUCCESS',
    GET_CURATION_RULES_FAILURE: 'GET_CURATION_RULES_FAILURE',

    GET_DEFAULT_TYPES_REQUEST: 'GET_DEFAULT_TYPES_REQUEST',
    GET_DEFAULT_TYPES_SUCCESS: 'GET_DEFAULT_TYPES_SUCCESS',
    GET_DEFAULT_TYPES_FAILURE: 'GET_DEFAULT_TYPES_FAILURE',
    GET_ORGANIZATION_DATASOURCE_REQUEST: 'GET_ORGANIZATION_DATASOURCE_REQUEST',
    GET_ORGANIZATION_DATASOURCE_SUCCESS: 'GET_ORGANIZATION_DATASOURCE_SUCCESS',
    GET_ORGANIZATION_DATASOURCE_FAILURE: 'GET_ORGANIZATION_DATASOURCE_FAILURE',

    CHECK_LICENSE_REQUEST: 'CHECK_LICENSE_REQUEST',
    CHECK_LICENSE_SUCCESS: 'CHECK_LICENSE_SUCCESS',
    CHECK_LICENSE_FAILURE: 'CHECK_LICENSE_FAILURE',

    GET_USER_SHARING_REQUEST: 'GET_USER_SHARING_REQUEST',
    GET_USER_SHARING_SUCCESS: 'GET_USER_SHARING_SUCCESS',
    GET_USER_SHARING_FAILURE: 'GET_USER_SHARING_FAILURE',

    GET_VIEWS_REQUEST: 'GET_VIEWS_REQUEST',
    GET_VIEWS_SUCCESS: 'GET_VIEWS_SUCCESS',
    GET_VIEWS_FAILURE: 'GET_VIEWS_FAILURE',

    UPDATE_VIEWS_REQUEST: 'UPDATE_VIEWS_REQUEST',
    UPDATE_VIEWS_SUCCESS: 'UPDATE_VIEWS_SUCCESS',
    UPDATE_VIEWS_FAILURE: 'UPDATE_VIEWS_FAILURE',

    GET_REFERENCE_RULES_REQUEST: 'GET_REFERENCE_RULES_REQUEST',
    GET_REFERENCE_RULES_SUCCESS: 'GET_REFERENCE_RULES_SUCCESS',
    GET_REFERENCE_RULES_FAILURE: 'GET_REFERENCE_RULES_FAILURE',

    DELETE_DATASOURCE_SHARE_REQUEST: 'DELETE_DATASOURCE_SHARE_REQUEST',
    DELETE_DATASOURCE_SHARE_SUCCESS: 'DELETE_DATASOURCE_SHARE_SUCCESS',
    DELETE_DATASOURCE_SHARE_FAILURE: 'DELETE_DATASOURCE_SHARE_FAILURE',

    DELETE_DATASET_SHARE_REQUEST: 'DELETE_DATASET_SHARE_REQUEST',
    DELETE_DATASET_SHARE_SUCCESS: 'DELETE_DATASET_SHARE_SUCCESS',
    DELETE_DATASET_SHARE_FAILURE: 'DELETE_DATASET_SHARE_FAILURE',

    LOAD_CONNECTION_TYPES_REQUEST: 'LOAD_CONNECTION_TYPES_REQUEST',
    LOAD_CONNECTION_TYPES_SUCCESS: 'LOAD_CONNECTION_TYPES_SUCCESS',
    LOAD_CONNECTION_TYPES_FAILURE: 'LOAD_CONNECTION_TYPES_FAILURE',

    GET_CONNECTION_TYPES_REQUEST: 'GET_CONNECTION_TYPES_REQUEST',
    GET_CONNECTION_TYPES_SUCCESS: 'GET_CONNECTION_TYPES_SUCCESS',
    GET_CONNECTION_TYPES_FAILURE: 'GET_CONNECTION_TYPES_FAILURE',

    GET_MODULE_CONNECTION_TYPES_REQUEST: 'GET_MODULE_CONNECTION_TYPES_REQUEST',
    GET_MODULE_CONNECTION_TYPES_SUCCESS: 'GET_MODULE_CONNECTION_TYPES_SUCCESS',
    GET_MODULE_CONNECTION_TYPES_FAILURE: 'GET_MODULE_CONNECTION_TYPES_FAILURE',

    GET_ORGANIZATION_DATASET_REQUEST: 'GET_ORGANIZATION_DATASET_REQUEST',
    GET_ORGANIZATION_DATASET_SUCCESS: 'GET_ORGANIZATION_DATASET_SUCCESS',
    GET_ORGANIZATION_DATASET_FAILURE: 'GET_ORGANIZATION_DATASET_FAILURE',

    GET_ORGANIZATION_ATTRIBUTE_REQUEST: 'GET_ORGANIZATION_ATTRIBUTE_REQUEST',
    GET_ORGANIZATION_ATTRIBUTE_SUCCESS: 'GET_ORGANIZATION_ATTRIBUTE_SUCCESS',
    GET_ORGANIZATION_ATTRIBUTE_FAILURE: 'GET_ORGANIZATION_ATTRIBUTE_FAILURE',

    GET_USER_DATASOURCES_REQUEST: 'GET_USER_DATASOURCES_REQUEST',
    GET_USER_DATASOURCES_SUCCESS: 'GET_USER_DATASOURCES_SUCCESS',
    GET_USER_DATASOURCES_FAILURE: 'GET_USER_DATASOURCES_FAILURE',

    SET_SELECTED_DATASOURCE_ID: 'SET_SELECTED_DATASOURCE_ID',

    GET_ORGANIZATION_SOURCE_REQUEST: 'GET_ORGANIZATION_SOURCE_REQUEST',
    GET_ORGANIZATION_SOURCE_SUCCESS: 'GET_ORGANIZATION_SOURCE_SUCCESS',
    GET_ORGANIZATION_SOURCE_FAILURE: 'GET_ORGANIZATION_SOURCE_FAILURE',

    GET_ORGANIZATION_DOMAIN_REQUEST: 'GET_ORGANIZATION_DOMAIN_REQUEST',
    GET_ORGANIZATION_DOMAIN_SUCCESS: 'GET_ORGANIZATION_DOMAIN_SUCCESS',
    GET_ORGANIZATION_DOMAIN_FAILURE: 'GET_ORGANIZATION_DOMAIN_FAILURE',

    CREATE_DOMAIN_REQUEST: 'CREATE_DOMAIN_REQUEST',
    CREATE_DOMAIN_SUCCESS: 'CREATE_DOMAIN_SUCCESS',
    CREATE_DOMAIN_FAILURE: 'CREATE_DOMAIN_FAILURE',

    GET_ORGANIZATION_ALL_DOMAIN_REQUEST: 'GET_ORGANIZATION_ALL_DOMAIN_REQUEST',
    GET_ORGANIZATION_ALL_DOMAIN_SUCCESS: 'GET_ORGANIZATION_ALL_DOMAIN_SUCCESS',
    GET_ORGANIZATION_ALL_DOMAIN_FAILURE: 'GET_ORGANIZATION_ALL_DOMAIN_FAILURE',

    GET_DOMAIN_DETAIL_REQUEST: 'GET_DOMAIN_DETAIL_REQUEST',
    GET_DOMAIN_DETAIL_SUCCESS: 'GET_DOMAIN_DETAIL_SUCCESS',
    GET_DOMAIN_DETAIL_FAILURE: 'GET_DOMAIN_DETAIL_FAILURE',

    GET_DOMAIN_SOURCE_SHARE_REQUEST: 'GET_DOMAIN_SOURCE_SHARE_REQUEST',
    GET_DOMAIN_SOURCE_SHARE_SUCCESS: 'GET_DOMAIN_SOURCE_SHARE_SUCCESS',
    GET_DOMAIN_SOURCE_SHARE_FAILURE: 'GET_DOMAIN_SOURCE_SHARE_FAILURE',

    SET_SOURCE_FILTERS: 'SET_SOURCE_FILTERS',

    UPDATE_DOMAIN_REQUEST: 'UPDATE_DOMAIN_REQUEST',
    UPDATE_DOMAIN_SUCCESS: 'UPDATE_DOMAIN_SUCCESS',
    UPDATE_DOMAIN_FAILURE: 'UPDATE_DOMAIN_FAILURE',

    DELETE_DOMAIN_REQUEST: 'DELETE_DOMAIN_REQUEST',
    DELETE_DOMAIN_SUCCESS: 'DELETE_DOMAIN_SUCCESS',
    DELETE_DOMAIN_FAILURE: 'DELETE_DOMAIN_FAILURE',

    UPLOAD_DOMAIN_IMAGE_REQUEST: 'UPLOAD_DOMAIN_IMAGE_REQUEST',
    UPLOAD_DOMAIN_IMAGE_SUCCESS: 'UPLOAD_DOMAIN_IMAGE_SUCCESS',
    UPLOAD_DOMAIN_IMAGE_FAILURE: 'UPLOAD_DOMAIN_IMAGE_FAILURE',

    UPDATE_JOB_STATUS: 'UPDATE_JOB_STATUS',

    GET_DATASET_ENRICHMENTS_REQUEST: 'GET_DATASET_ENRICHMENTS_REQUEST',
    GET_DATASET_ENRICHMENTS_SUCCESS: 'GET_DATASET_ENRICHMENTS_SUCCESS',
    GET_DATASET_ENRICHMENTS_FAILURE: 'GET_DATASET_ENRICHMENTS_FAILURE',

    CREATE_DATASET_ENRICHMENTS_REQUEST: 'CREATE_DATASET_ENRICHMENTS_REQUEST',
    CREATE_DATASET_ENRICHMENTS_SUCCESS: 'CREATE_DATASET_ENRICHMENTS_SUCCESS',
    CREATE_DATASET_ENRICHMENTS_FAILURE: 'CREATE_DATASET_ENRICHMENTS_FAILURE',

    UPDATE_DATASET_ENRICHMENTS_REQUEST: 'UPDATE_DATASET_ENRICHMENTS_REQUEST',
    UPDATE_DATASET_ENRICHMENTS_SUCCESS: 'UPDATE_DATASET_ENRICHMENTS_SUCCESS',
    UPDATE_DATASET_ENRICHMENTS_FAILURE: 'UPDATE_DATASET_ENRICHMENTS_FAILURE',

    DELETE_DATASET_ENRICHMENTS_REQUEST: 'DELETE_DATASET_ENRICHMENTS_REQUEST',
    DELETE_DATASET_ENRICHMENTS_SUCCESS: 'DELETE_DATASET_ENRICHMENTS_SUCCESS',
    DELETE_DATASET_ENRICHMENTS_FAILURE: 'DELETE_DATASET_ENRICHMENTS_FAILURE',

    GET_FIELD_PROPERTIES_REQUEST: 'GET_FIELD_PROPERTIES_REQUEST',
    GET_FIELD_PROPERTIES_SUCCESS: 'GET_FIELD_PROPERTIES_SUCCESS',
    GET_FIELD_PROPERTIES_FAILURE: 'GET_FIELD_PROPERTIES_FAILURE',

    CREATE_FIELD_PROPERTIES_REQUEST: 'CREATE_FIELD_PROPERTIES_REQUEST',
    CREATE_FIELD_PROPERTIES_SUCCESS: 'CREATE_FIELD_PROPERTIES_SUCCESS',
    CREATE_FIELD_PROPERTIES_FAILURE: 'CREATE_FIELD_PROPERTIES_FAILURE',

    EXPORT_REPORT: 'EXPORT_REPORT',
    IMPORT_REPORT: 'IMPORT_REPORT',

    GET_ORGANIZATION_SOURCE_NAMES_REQUEST: 'GET_ORGANIZATION_SOURCE_NAMES_REQUEST',
    GET_ORGANIZATION_SOURCE_NAMES_SUCCESS: 'GET_ORGANIZATION_SOURCE_NAMES_SUCCESS',
    GET_ORGANIZATION_SOURCE_NAMES_FAILURE: 'GET_ORGANIZATION_SOURCE_NAMES_FAILURE',

    GET_ORGANIZATION_SOURCE_DATASETS_REQUEST: 'GET_ORGANIZATION_SOURCE_DATASETS_REQUEST',
    GET_ORGANIZATION_SOURCE_DATASETS_SUCCESS: 'GET_ORGANIZATION_SOURCE_DATASETS_SUCCESS',
    GET_ORGANIZATION_SOURCE_DATASETS_FAILURE: 'GET_ORGANIZATION_SOURCE_DATASETS_FAILURE',

    GET_SOURCE_RATING_REQUEST: 'GET_SOURCE_RATING_REQUEST',
    GET_SOURCE_RATING_SUCCESS: 'GET_SOURCE_RATING_SUCCESS',
    GET_SOURCE_RATING_FAILURE: 'GET_SOURCE_RATING_FAILURE',

    GET_LINK_GLOSSARY_REQUEST: 'GET_LINK_GLOSSARY_REQUEST',
    GET_LINK_GLOSSARY_SUCCESS: 'GET_LINK_GLOSSARY_SUCCESS',
    GET_LINK_GLOSSARY_FAILURE: 'GET_LINK_GLOSSARY_FAILURE',

    LINK_TERM_REQUEST: 'LINK_TERM_REQUEST',
    LINK_TERM_SUCCESS: 'LINK_TERM_SUCCESS',
    LINK_TERM_FAILURE: 'LINK_TERM_FAILURE',

    GET_DATASET_DUPLICATE_REQUEST: 'GET_DATASET_DUPLICATE_REQUEST',
    GET_DATASET_DUPLICATE_SUCCESS: 'GET_DATASET_DUPLICATE_SUCCESS',
    GET_DATASET_DUPLICATE_FAILURE: 'GET_DATASET_DUPLICATE_FAILURE',

    SAVE_DUPLICATE_REQUEST: 'SAVE_DUPLICATE_REQUEST',
    SAVE_DUPLICATE_SUCCESS: 'SAVE_DUPLICATE_SUCCESS',
    SAVE_DUPLICATE_FAILURE: 'SAVE_DUPLICATE_FAILURE',

    GET_CATALOG_DATASOURCES_REQUEST: 'GET_CATALOG_DATASOURCES_REQUEST',
    GET_CATALOG_DATASOURCES_SUCCESS: 'GET_CATALOG_DATASOURCES_SUCCESS',
    GET_CATALOG_DATASOURCES_FAILURE: 'GET_CATALOG_DATASOURCES_FAILURE',

    CLEAR_CATALOG_LIST_DATA: 'CLEAR_CATALOG_LIST_DATA',

    UPDATE_DATASOURCE_LOAD: 'UPDATE_DATASOURCE_LOAD',
    UPDATE_DATASET_LOAD: 'UPDATE_DATASET_LOAD',

    IMPORT_DATA_REQUEST: 'IMPORT_DATA_REQUEST',
    IMPORT_DATA_SUCCESS: 'IMPORT_DATA_SUCCESS',
    IMPORT_DATA_FAILURE: 'IMPORT_DATA_FAILURE',

    GET_DATASET_LIST_BYDATASOURCE_REQUEST: 'GET_DATASET_LIST_BYDATASOURCE_REQUEST',
    GET_DATASET_LIST_BYDATASOURCE_SUCCESS: 'GET_DATASET_LIST_BYDATASOURCE_SUCCESS',
    GET_DATASET_LIST_BYDATASOURCE_FAILURE: 'GET_DATASET_LIST_BYDATASOURCE_FAILURE',

    GET_ATTRIBUTE_LIST_BYDATASOURCE_REQUEST: 'GET_ATTRIBUTE_LIST_BYDATASOURCE_REQUEST',
    GET_ATTRIBUTE_LIST_BYDATASOURCE_SUCCESS: 'GET_ATTRIBUTE_LIST_BYDATASOURCE_SUCCESS',
    GET_ATTRIBUTE_LIST_BYDATASOURCE_FAILURE: 'GET_ATTRIBUTE_LIST_BYDATASOURCE_FAILURE',

    UPDATE_CERTIFIED_REQUEST: 'UPDATE_CERTIFIED_REQUEST',
    UPDATE_CERTIFIED_SUCCESS: 'UPDATE_CERTIFIED_SUCCESS',
    UPDATE_CERTIFIED_FAILURE: 'UPDATE_CERTIFIED_FAILURE',

    GET_CERTIFIED_REQUEST: 'GET_CERTIFIED_REQUEST',
    GET_CERTIFIED_SUCCESS: 'GET_CERTIFIED_SUCCESS',
    GET_CERTIFIED_FAILURE: 'GET_CERTIFIED_FAILURE',

    GET_ATTRIBUTE_RATING_REQUEST: 'GET_ATTRIBUTE_RATING_REQUEST',
    GET_ATTRIBUTE_RATING_SUCCESS: 'GET_ATTRIBUTE_RATING_SUCCESS',
    GET_ATTRIBUTE_RATING_FAILURE: 'GET_ATTRIBUTE_RATING_FAILURE',

    UPDATE_TABINDEX_REQUEST: 'UPDATE_TABINDEX_REQUEST',
    UPDATE_TABINDEX_SUCCESS: 'UPDATE_TABINDEX_SUCCESS',
    UPDATE_TABINDEX_FAILURE: 'UPDATE_TABINDEX_FAILURE'
};