import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import { alertActions } from '../constants/actionTypes/alertActionTypes';
import { masterActions } from '../constants/actionTypes/masterActionTypes';
import * as service from '../services/apiService';
import { alerts } from '../config/alerts';

export const createMasterModel = (requestParams) => {
    return (dispatch) => {
        dispatch(request(masterActions.CREATE_MASTER_MODEL_REQUEST));
        return service.postRequest(appConstants.END_POINTS.master.createMasterModel, requestParams).then((response) => {
            dispatch(success(masterActions.CREATE_MASTER_MODEL_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.CREATE_MASTER_MODEL_FAILURE, error));
        });
    };
};

export const getAllMasterModel = () => {
    return (dispatch) => {
        dispatch(request(masterActions.GET_ALL_MASTER_MODEL_REQUEST));
        return service.getRequest(appConstants.END_POINTS.master.getAllMasterModel).then((response) => {
            dispatch(success(masterActions.GET_ALL_MASTER_MODEL_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.GET_ALL_MASTER_MODEL_FAILURE, error));
        });
    };
};

export const getModel = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.master.getModel}/${modelId}`;
    return (dispatch) => {
        dispatch(request(masterActions.GET_ALL_MASTER_MODEL_BY_ID_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(masterActions.GET_ALL_MASTER_MODEL_BY_ID_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.GET_ALL_MASTER_MODEL_BY_ID_FAILURE, error));
        });
    };
};

export const getMasterSharingInfo = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.master.getMasterSharingInfo}/${modelId}`;
    return (dispatch) => {
        dispatch(request(masterActions.GET_ALL_MASTER_MODEL_SHARING_INFO_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(masterActions.GET_ALL_MASTER_MODEL_SHARING_INFO_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.GET_ALL_MASTER_MODEL_SHARING_INFO_FAILURE, error));
        });
    };
};

export const createModelAttributes = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.master.createModelAttributes}`;
    return (dispatch) => {
        dispatch(request(masterActions.CREATE_MODEL_ATTRIBUTES_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(masterActions.CREATE_MODEL_ATTRIBUTES_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.CREATE_MODEL_ATTRIBUTES_FAILURE, error));
        });
    };
};

export const updateModelAttributes = (attributeId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.master.updateModelAttributes}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(masterActions.UPDATE_MODEL_ATTRIBUTES_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(masterActions.UPDATE_MODEL_ATTRIBUTES_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.UPDATE_MODEL_ATTRIBUTES_FAILURE, error));
        });
    };
};

export const getAttributes = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.master.getAttributes}/${modelId}`;
    return (dispatch) => {
        dispatch(request(masterActions.GET_MODEL_ATTRIBUTES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(masterActions.GET_MODEL_ATTRIBUTES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.GET_MODEL_ATTRIBUTES_FAILURE, error));
        });
    };
};

export const setMasterAttributes = (attributes) => {
    return {
        type: masterActions.SET_ATTRIBUTES,
        attributes
    };
};

export const deleteAttribute = (modelId, attributeId) => {
    const endpoint = `${appConstants.END_POINTS.master.deleteAttribute}/${modelId}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(masterActions.DELETE_MODEL_ATTRIBUTES_REQUEST));
        return service.deleteRequest(endpoint).then((response) => {
            dispatch(success(masterActions.DELETE_MODEL_ATTRIBUTES_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.DELETE_MODEL_ATTRIBUTES_FAILURE, error));
        });
    };
};

export const masterSearch = (params) => {
    const endpoint = `${appConstants.END_POINTS.master.searchSemanticModel}`;
    return (dispatch) => {
        dispatch(request(masterActions.SEARCH_SEMANTIC_MODEL_REQUEST));
        return service.postRequest(endpoint, params).then((response) => {
            dispatch(success(masterActions.SEARCH_SEMANTIC_MODEL_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.SEARCH_SEMANTIC_MODEL_FAILURE, error));
        });
    };
};

export const clearMasterSearchResults = () => {
    return {
        type: masterActions.CLEAR_MASTER_SEARCH_RESULTS
    };
};


export const updateMasterModel = (modelId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.master.updateMasterModel}/${modelId}`;
    return (dispatch) => {
        dispatch(request(masterActions.UPDATE_MODEL_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(masterActions.UPDATE_MODEL_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.UPDATE_MODEL_FAILURE, error));
        });
    };
};


export const createMasterShare = (requestParams) => {
    return (dispatch) => {
        dispatch(request(masterActions.CREATE_MASTER_SHARE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.master.createMasterShare, requestParams).then((response) => {
            dispatch(success(masterActions.CREATE_MASTER_SHARE_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.CREATE_MASTER_SHARE_FAILURE, error));
        });
    };
};

export const deleteMasterShare = (requestParams) => {
    return (dispatch) => {
        dispatch(request(masterActions.DELETE_MASTER_SHARE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.master.deleteMasterShare, requestParams).then((response) => {
            dispatch(success(masterActions.DELETE_MASTER_SHARE_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.DELETE_MASTER_SHARE_FAILURE, error));
        });
    };
};

export const deleteMasterModel = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.master.deleteModel}/${modelId}`;
    return (dispatch) => {
        dispatch(request(masterActions.DELETE_MODEL_REQUEST));
        return service.deleteRequest(endpoint).then((response) => {
            dispatch(success(masterActions.DELETE_MODEL_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.DELETE_MODEL_FAILURE, error));
        });
    };
};


export const createMatchCombination = (matchCombination) => {
    const endpoint = `${appConstants.END_POINTS.master.createMatchCombination}`;
    return (dispatch) => {
        dispatch(request(masterActions.CREATE_MATCH_COMBINATION_REQUEST));
        return service.postRequest(endpoint, matchCombination).then((response) => {
            dispatch(success(masterActions.CREATE_MATCH_COMBINATION_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.CREATE_MATCH_COMBINATION_FAILURE, error));
        });
    };
};

export const updateMatchCombination = (matchCombination) => {
    const endpoint = `${appConstants.END_POINTS.master.updateMatchCombination}`;
    return (dispatch) => {
        dispatch(request(masterActions.UPDATE_MATCH_COMBINATION_REQUEST));
        return service.putRequest(endpoint, matchCombination).then((response) => {
            dispatch(success(masterActions.UPDATE_MATCH_COMBINATION_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.UPDATE_MATCH_COMBINATION_FAILURE, error));
        });
    };
};


export const deleteMatchCombination = (modelId, combinationId) => {
    const endpoint = `${appConstants.END_POINTS.master.deleteMatchCombination}/${modelId}/${combinationId}`;
    return (dispatch) => {
        dispatch(request(masterActions.DELETE_MATCH_COMBINATION_REQUEST));
        return service.deleteRequest(endpoint).then((response) => {
            dispatch(success(masterActions.DELETE_MATCH_COMBINATION_SUCCESS, response));
        }, (error) => {
            dispatch(failure(masterActions.DELETE_MATCH_COMBINATION_FAILURE, error));
        });
    };
};

export const getMatchCombinations = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.master.getAllMatchCombinations}/${modelId}`;
    return (dispatch) => {
        dispatch(request(masterActions.GET_ALL_MATCH_COMBINATIONS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(masterActions.GET_ALL_MATCH_COMBINATIONS_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.GET_ALL_MATCH_COMBINATIONS_FAILURE, error));
        });
    };
};

export const updateMatchCombinationRank = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.master.updateMatchCombinationRank}`;
    return (dispatch) => {
        dispatch(request(masterActions.UPDATE_MATCH_COMBINATION_RANK_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(masterActions.UPDATE_MATCH_COMBINATION_RANK_SUCCESS, response));
        }, (error) => {
            dispatch(failure(masterActions.UPDATE_MATCH_COMBINATION_RANK_FAILURE, error));
        });
    };
};

export const publishModel = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.master.publishModel}/${modelId}`;
    return (dispatch) => {
        dispatch(success(alertActions.ALERT_SUCCESS, alerts.publishModelStarted));
        dispatch(request(masterActions.PUBLISH_SEMANTIC_MODEL_REQUEST));
        return service.postRequest(endpoint, {}).then((response) => {
            dispatch(success(masterActions.PUBLISH_SEMANTIC_MODEL_SUCCESS, response));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.publishModel));
        }, (error) => {
            dispatch(failure(masterActions.PUBLISH_SEMANTIC_MODEL_FAILURE, error));
        });
    };
};

export const updateAttributesPosition = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.master.updateAttributesPosition}`;
    return (dispatch) => {
        dispatch(request(masterActions.UPDATE_ATTRIBUTES_POSITION_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(masterActions.UPDATE_ATTRIBUTES_POSITION_SUCCESS, response));
        }, (error) => {
            dispatch(failure(masterActions.UPDATE_ATTRIBUTES_POSITION_FAILURE, error));
        });
    };
};


export const getSemanticModelSchedule = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.master.getSchedule}/${modelId}`;
    return (dispatch) => {
        dispatch(request(masterActions.GET_DATASET_SCHEDULE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(masterActions.GET_DATASET_SCHEDULE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.GET_DATASET_SCHEDULE_FAILURE, error));
        });
    };
};

export const createSemanticModelSchedule = (requestParams) => {
    return (dispatch) => {
        dispatch(request(masterActions.CREATE_DATASET_SCHEDULE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.master.createSchedule, requestParams).then((response) => {
            dispatch(success(masterActions.CREATE_DATASET_SCHEDULE_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.createSemanticModelSchedule));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.CREATE_DATASET_SCHEDULE_FAILURE, error));
        });
    };
};

export const updateSemanticModelSchedule = (requestParams) => {
    return (dispatch) => {
        dispatch(request(masterActions.UPDATE_DATASET_SCHEDULE_REQUEST));
        return service.putRequest(appConstants.END_POINTS.master.updateSchedule, requestParams).then((response) => {
            dispatch(success(masterActions.UPDATE_DATASET_SCHEDULE_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.updateSemanticModelSchedule));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.UPDATE_DATASET_SCHEDULE_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
        });
    };
};

export const runMasterModelJob = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.master.runMasterModelJob}/${modelId}`;
    return (dispatch) => {
        dispatch(request(masterActions.RUN_MASTER_MODEL_JOB_REQUEST));
        return service.postRequest(endpoint).then((response) => {
            dispatch(success(masterActions.RUN_MASTER_MODEL_JOB_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.runMasterModelJob));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.RUN_MASTER_MODEL_JOB_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
        });
    };
};

export const loadGenericTable = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.master.loadGenericTable}/${modelId}`;
    return (dispatch) => {
        dispatch(request(masterActions.LOAD_GENERIC_TABLE_JOB_REQUEST));
        return service.postRequest(endpoint).then((response) => {
            dispatch(success(masterActions.LOAD_GENERIC_TABLE_JOB_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.loadGenericTable));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.LOAD_GENERIC_TABLE_JOB_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
        });
    };
};

export const generatePersonID = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.master.generatePersonID}/${modelId}`;
    return (dispatch) => {
        dispatch(request(masterActions.GENERATE_PERSION_ID_JOB_REQUEST));
        return service.postRequest(endpoint).then((response) => {
            dispatch(success(masterActions.GENERATE_PERSION_ID_JOB_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.generatePersonID));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.GENERATE_PERSION_ID_JOB_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error));
        });
    };
};

export const deleteSemanticModelSchedule = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.master.deleteSchedule}/${modelId}`;
    return (dispatch) => {
        dispatch(request(masterActions.DELETE_DATASET_SCHEDULE_REQUEST));
        return service.deleteRequest(endpoint).then((response) => {
            dispatch(success(masterActions.DELETE_DATASET_SCHEDULE_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.deleteSemanticModelSchedule));
        }, (error) => {
            dispatch(failure(masterActions.DELETE_DATASET_SCHEDULE_FAILURE, error));
        });
    };
};

export const getSemanticModelMappings = (modelId, searchKey) => {
    const endpoint = `${appConstants.END_POINTS.master.getMappings}/${modelId}/${searchKey ? searchKey : 'all'}`;
    return (dispatch) => {
        dispatch(request(masterActions.GET_MODEL_MAPPINGS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(masterActions.GET_MODEL_MAPPINGS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.GET_MODEL_MAPPINGS_FAILURE, error));
        });
    };
};

export const getModelExecutionLogs = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.master.getExecutionLogs}/${modelId}`;
    return (dispatch) => {
        dispatch(request(masterActions.GET_EXECUTION_LOGS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(masterActions.GET_EXECUTION_LOGS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.GET_EXECUTION_LOGS_FAILURE, error));
        });
    };
};

export const killMasterJob = (scheduleId) => {
    const endpoint = `${appConstants.END_POINTS.master.killMasterJob}/${scheduleId}`;
    return (dispatch) => {
        dispatch(request(masterActions.KILL_MASTER_MODEL_JOB_REQUEST));
        return service.postRequest(endpoint).then((response) => {
            dispatch(success(masterActions.KILL_MASTER_MODEL_JOB_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.KILL_MASTER_MODEL_JOB_FAILURE, error));
        });
    };
};


export const restartMasterJobSchedule = (scheduleId) => {
    const endpoint = `${appConstants.END_POINTS.master.restartMasterJobSchedule}/${scheduleId}`;
    return (dispatch) => {
        dispatch(request(masterActions.RESTART_MASTER_JOB_SCHEDULE_REQUEST));
        return service.putRequest(endpoint).then((response) => {
            dispatch(success(masterActions.RESTART_MASTER_JOB_SCHEDULE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.RESTART_MASTER_JOB_SCHEDULE_FAILURE, error));
        });
    };
};


export const getMasterFieldTypes = () => {
    const endpoint = `${appConstants.END_POINTS.master.getMasterFieldTypes}`;
    return (dispatch) => {
        dispatch(request(masterActions.GET_MASTER_MODEL_TYPES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(masterActions.GET_MASTER_MODEL_TYPES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.GET_MASTER_MODEL_TYPES_FAILURE, error));
        });
    };
};


export const getGlossaryFields = () => {
    const endpoint = `${appConstants.END_POINTS.master.getGlossaryFields}`;
    return (dispatch) => {
        dispatch(request(masterActions.GET_MASTER_GLOSSARY_FIELDS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(masterActions.GET_MASTER_GLOSSARY_FIELDS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.GET_MASTER_GLOSSARY_FIELDS_FAILURE, error));
        });
    };
};

export const createModelAttributeFields = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.master.createModelAttributeFields}`;
    return (dispatch) => {
        dispatch(request(masterActions.CREATE_MODEL_ATTRIBUTE_FIELDS_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(masterActions.CREATE_MODEL_ATTRIBUTE_FIELDS_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.CREATE_MODEL_ATTRIBUTE_FIELDS_FAILURE, error));
        });
    };
};

export const updateMasterModelStatus = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.master.updateMasterModelStatus}`;
    return (dispatch) => {
        dispatch(request(masterActions.UPDATE_MASTER_MODEL_STATUS_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(masterActions.UPDATE_MASTER_MODEL_STATUS_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(masterActions.UPDATE_MASTER_MODEL_STATUS_FAILURE, error));
        });
    };
};