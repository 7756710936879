import React, { useState, useCallback, useEffect } from 'react';
import { Grid, Typography, withStyles, Table, TableHead, TableCell, TableBody, TableRow, IconButton, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import Loader from '../Loaders/Loader.jsx';
import { getLogging } from '../../actions/settingActions';
import { appConstants } from '../../constants/appConstants';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const Logging = (props) => {
    const { classes, theme } = props;
    const [logging, setLogging] = useState({ error: [], info: [], libary_error: [] });
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const download = (url) => {
        const endpoint = appConstants.API_BASE_URL.replace("api", "");
        window.open(`${endpoint}${url}`);
    };

    const getLog = useCallback(() => {
        setLoading(true);
        dispatch(getLogging()).then((response) => {
            if (response) {
                setLogging({ ...response });
            }
            setLoading(false);
        });
    }, [dispatch]);

    useEffect(() => {
        getLog();
    }, [getLog]);

    return (
        <Grid className={classes.referenceContainer2}>
            <Grid container justify="space-between">
                <Typography variant="h5" component="h5">
                    Logging
                </Typography>
            </Grid>
            <Grid>
                <Grid className={classes.marginTop10}>
                    <Typography variant="h6" component="h6">
                        Error Logging
                    </Typography>
                    <Divider />
                    <Table className={classes.marginTop10}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeadCell}>
                                    File Name
                                </TableCell>
                                <TableCell className={classes.tableHeadCell}>
                                    Created Date
                                </TableCell>
                                <TableCell className={classes.tableHeadCell} align="center">
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.loggingTable}>
                            {
                                logging && logging.error && logging.error.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map((log, index) =>
                                    <TableRow key={`error_${index}`}>
                                        <TableCell className={classes.tableCell}>
                                            {log.name ? log.name : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {log.timestamp ? log.timestamp : ""}
                                        </TableCell>
                                        <TableCell align="center">
                                            <ToolTipComponent title="Download" arrow>
                                                <IconButton className={classes.padding6} onClick={() => download(log.file_path)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 21 21">
                                                        <g id="download_4_" data-name="download (4)" transform="translate(-1.5 -1.5)">
                                                            <path id="Path_14400" data-name="Path 14400" d="M22,23.714v2.857A1.43,1.43,0,0,1,20.571,28H3.428A1.43,1.43,0,0,1,2,26.572V23.714a.714.714,0,0,1,1.428,0v2.857H20.571V23.714a.714.714,0,0,1,1.429,0Z" transform="translate(0 -6)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                                            <path id="Path_14401" data-name="Path 14401" d="M8.21,12.5a.714.714,0,1,1,1.01-1.01L13,15.275V2.714a.714.714,0,1,1,1.428,0V15.275l3.781-3.78a.714.714,0,1,1,1.01,1.01l-5,5a.715.715,0,0,1-1.011,0Z" transform="translate(-1.715)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                                        </g>
                                                    </svg>
                                                </IconButton>
                                            </ToolTipComponent>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </Grid>
                {/* <Grid className={classes.marginTop30}>
                    <Typography variant="h6" component="h6">
                        Info Logging
                    </Typography>
                    <Divider />
                    <Table className={classes.marginTop15}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeadCell}>
                                    File Name
                                </TableCell>
                                <TableCell className={classes.tableHeadCell}>
                                    Created Date
                                </TableCell>
                                <TableCell className={classes.tableHeadCell} align="center">
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.loggingTable}>
                            {
                                logging && logging.info && logging.info.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map((log, index) =>
                                    <TableRow key={`error_${index}`}>
                                        <TableCell className={classes.tableCell}>
                                            {log.name ? log.name : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {log.timestamp ? log.timestamp : ""}
                                        </TableCell>
                                        <TableCell align="center">
                                            <ToolTipComponent title="Download" arrow>
                                                <IconButton className={classes.padding6} onClick={() => download(log.file_path)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 21 21">
                                                        <g id="download_4_" data-name="download (4)" transform="translate(-1.5 -1.5)">
                                                            <path id="Path_14400" data-name="Path 14400" d="M22,23.714v2.857A1.43,1.43,0,0,1,20.571,28H3.428A1.43,1.43,0,0,1,2,26.572V23.714a.714.714,0,0,1,1.428,0v2.857H20.571V23.714a.714.714,0,0,1,1.429,0Z" transform="translate(0 -6)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                                            <path id="Path_14401" data-name="Path 14401" d="M8.21,12.5a.714.714,0,1,1,1.01-1.01L13,15.275V2.714a.714.714,0,1,1,1.428,0V15.275l3.781-3.78a.714.714,0,1,1,1.01,1.01l-5,5a.715.715,0,0,1-1.011,0Z" transform="translate(-1.715)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                                        </g>
                                                    </svg>
                                                </IconButton>
                                            </ToolTipComponent>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </Grid> */}
                <Grid className={classes.marginTop30}>
                    <Typography variant="h6" component="h6">
                        Library Error Logging
                    </Typography>
                    <Divider />
                    <Table className={classes.marginTop15}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeadCell}>
                                    File Name
                                </TableCell>
                                <TableCell className={classes.tableHeadCell}>
                                    Created Date
                                </TableCell>
                                <TableCell className={classes.tableHeadCell} align="center">
                                    Actions
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.loggingTable}>
                            {
                                logging && logging.libary_error && logging.libary_error.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map((log, index) =>
                                    <TableRow key={`error_${index}`}>
                                        <TableCell className={classes.tableCell}>
                                            {log.name ? log.name : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {log.timestamp ? log.timestamp : ""}
                                        </TableCell>
                                        <TableCell align="center">
                                            <ToolTipComponent title="Download" arrow>
                                                <IconButton className={classes.padding6} onClick={() => download(log.file_path)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 21 21">
                                                        <g id="download_4_" data-name="download (4)" transform="translate(-1.5 -1.5)">
                                                            <path id="Path_14400" data-name="Path 14400" d="M22,23.714v2.857A1.43,1.43,0,0,1,20.571,28H3.428A1.43,1.43,0,0,1,2,26.572V23.714a.714.714,0,0,1,1.428,0v2.857H20.571V23.714a.714.714,0,0,1,1.429,0Z" transform="translate(0 -6)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                                            <path id="Path_14401" data-name="Path 14401" d="M8.21,12.5a.714.714,0,1,1,1.01-1.01L13,15.275V2.714a.714.714,0,1,1,1.428,0V15.275l3.781-3.78a.714.714,0,1,1,1.01,1.01l-5,5a.715.715,0,0,1-1.011,0Z" transform="translate(-1.715)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                                        </g>
                                                    </svg>
                                                </IconButton>
                                            </ToolTipComponent>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            {
                isLoading &&
                <Loader />
            }
        </Grid>
    );
};

Logging.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Logging);