const UserAutoCompleteStyles = (theme) => ({
    paper: {
        position: 'fixed',
        zIndex: 1330,
        marginTop: 20,
        maxWidth: 'max-content',
        maxHeight: 200,
        overflowY: 'auto',
        width: 300,
        boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)"
    },
    chipContainer: {
        width: 'auto',
        display: 'inline-flex',
        marginBottom: '2px'
    },
    avatar: {
        marginRight: 5,
        transition: 'all 2s'
    },
    allText: {
        marginRight: 8,
        marginTop: 3
    },
    menuItem: {
        justifyContent: 'space-between'
    },
    padding2: {
        padding: 2
    },
    filterSelectedavatar: {
        width: '20px !important',
        height: '20px !important',
        fontSize: '9px !important'
    },
    selectContainer: {
        display: 'flex'
    },
    selectDropDown: {
        position: 'relative'
    },
    listChip: {
        display: 'flex',
        marginRight: 5,
        alignItems: "center",
        animation: 'slide-up 0.4s ease',
        '& .MuiAvatar-root': {
            display: 'inline-flex',
            marginRight: 2
        }
    },
    inlineClose: {
        zIndex: 1
    },
    chipIcon: {
        fontSize: 8
    },
    selectDropDownValue: {
        minWIdth: 260
    }
});

export default UserAutoCompleteStyles;