const EnrichmentStyles = (theme) => ({
    enrichmentContainer: {
        maxWidth: '800px',
        minWidth: '800px',
        padding: '15px 25px',
        '& .active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.paper
        }
    },
    enrichmentTable: {
        maxHeight: '480px'
    },
    enrichmentTabelRow: {
        backgroundColor: theme.palette.grey.exteremeLight
    },
    enrichmentTableCell: {
        paddingTop: '18px',
        paddingBottom: '18px'
    },
    enrichmentListAlign: {
        '& .MuiAutocomplete-endAdornment': {
           top: 'auto',
           bottom: '0px'
        }
    }

});

export default EnrichmentStyles;