import React, { useState, useCallback, useEffect, memo, useRef } from 'react';
import {
    Grid, withStyles, Typography,
    Card, Table, TableBody, TableCell, TableRow, TableHead
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Styles from '../../layouts/Styles.jsx';
import { BarChart, Bar, Tooltip } from 'recharts';
import { useSelector, useDispatch } from 'react-redux';
import { getPreviewData, updateAttributes, getAllAttributeStats } from '../../actions/datasetActions';
import DatasetStyles from './DatasetStyles.jsx';
import { getDataTypeColor, dataAccessRestrict } from '../../helpers/appHelpers.js';
import Loader from '../Loaders/Loader.jsx';
import CheckboxComponent from '../ChecboxComponent/CheckboxComponent.jsx';
import NoResultImg from '../../assets/images/NoDataset.png';
import AttributeSelect from '../AttributeSelect/AttributeSelect.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const initialRowDataLimit = { skip: 0, limit: 30 };
const initialColumnDataLimit = { skip: 0, limit: 50 };

const DataSetAttributes = (props) => {
    const { classes, dataset, theme, isEditable } = props;
    const dispatch = useDispatch();
    const [previewData, setPreviewData] = useState({});
    const [attributes, setAttributes] = useState(previewData.columns ? [...previewData.columns] : []);
    const [valueCounts, setValueCounts] = useState(previewData.values_count ? { ...previewData.values_count } : {});
    const [inputData, setInputData] = useState(previewData.data ? [...previewData.data] : []);
    let properties = useSelector(({ dataset }) => dataset.propertiesList);
    const datasetPreviewData = useSelector(({ dataset }) => dataset.attributesPreview);
    const [isLoading, setLoading] = useState(true);
    const [selectAll, setSelectAll] = useState(true);
    const [searchKey, setSearchKey] = useState('');
    const [changedAttribute, setchangedAttribute] = useState({});
    const [attributeLoaded, setAttributeLoaded] = useState(false);
    // const [isSearch, enableSearch] = useState(false);
    const [filterAttributes, setFilterAttributes] = useState([]);
    const [attributeStats, setAttributeStats] = useState([]);

    const tableRef = useRef();

    const [columnDataLength, setColumnDataLength] = useState(initialColumnDataLimit);
    const [rowDataLength, setRowDataLength] = useState(initialRowDataLimit);

    const getAttributeStats = useCallback(() => {
        dispatch(getAllAttributeStats(dataset.id)).then((response) => {
            setAttributeStats(response.data);
        });
    }, [dataset.id, dispatch]);

    const fetchProperties = (propertiesList) => {
        const propertyItems = {};
        for (const property of propertiesList) {
            propertyItems[property.name] = { datatype: property.type, name: property.name, sensitivity: property.sensitivity };
        }
        return propertyItems;
    };

    properties = fetchProperties(properties);

    const updateAttributeProps = () => {
        if (Object.keys(changedAttribute).length > 0) {
            dispatch(updateAttributes(changedAttribute)).then((response) => {
                setchangedAttribute({});
            });
        }
    };

    const updateSelectedAttributes = useCallback(() => {
        const inputAttributes = {
            'dataset_id': dataset.id,
            'attributes': attributes.filter((p) => p.isSelected)
        };
        setchangedAttribute(inputAttributes);
        // dispatch(updateAttributes(inputAttributes));
    }, [attributes, dataset.id]);

    /**
     * Initializes the attributes and inputData with preview data
     */
    const setPreviewInput = useCallback((response) => {
        /**
         * .sort((a, b) => { const fa = a.name.toLowerCase(), fb = b.name.toLowerCase(); if (fa < fb) { return -1; } if (fa > fb) { return 1; } return 0; }) // Sorting was done before
         */
        const currentAttributes = response.columns ? [...response.columns] : [];
        setAttributes(currentAttributes);
        getAttributeStats();
        setInputData(response.data ? [...response.data] : []);
        setValueCounts(response.values_count ? { ...response.values_count } : {});
        if (response.columns && response.columns.length) {
            const columns = response.columns.map((column) => { return { ...column, isSelected: false }; });
            setFilterAttributes([...columns]);
        }

        let isAllSelected = false;
        if (response && response.columns) {
            isAllSelected = (response.columns.filter((p) => p.isSelected).length === response.columns.length);
        }
        setSelectAll(isAllSelected);

    }, [getAttributeStats]);

    const getPreview = useCallback(() => {
        if (datasetPreviewData && Object.keys(datasetPreviewData).length > 0) {
            setPreviewData({ ...datasetPreviewData });
            setPreviewInput(datasetPreviewData);
            setLoading(false);
            return;
        }
        if (!attributeLoaded) {
            setLoading(true);
            dispatch(getPreviewData(dataset.id)).then(() => {
                setLoading(false);
                setAttributeLoaded(true);
            });
        }
    }, [attributeLoaded, dataset.id, datasetPreviewData, dispatch, setPreviewInput]);

    /**
     * Loads the preview of input data
     */
    useEffect(() => {
        if (!dataset.id) {
            return;
        }
        getPreview();
    }, [dataset.id, dispatch, getPreview, setPreviewInput]);

    /**
     * Handles search key change event
     * Searched the attributes based on the given search key
     */
    const searchAttributes = useCallback((key) => {
        setSearchKey(key);
        const selectedAttributes = previewData.columns.filter((column) => column.name.toLowerCase().includes(key.toLowerCase()));
        setAttributes([...selectedAttributes]);
        getAttributeStats();
        setColumnDataLength(initialColumnDataLimit);
        setRowDataLength(initialRowDataLimit);
    }, [getAttributeStats, previewData.columns]);


    /**
     * Handles the select all event
     */

    const onSelectAll = useCallback(() => {
        for (const attribute of attributes) {
            attribute.isSelected = !selectAll;
        }
        setAttributes([...attributes]);
        getAttributeStats();
        setSelectAll(!selectAll);
        updateSelectedAttributes();
    }, [attributes, getAttributeStats, selectAll, updateSelectedAttributes]);


    const getDatatype = useCallback((attributeName) => {
        let datatype = '';
        if (!attributeName || !properties) {
            return datatype;
        }
        let selectedAttribute = attributeName;
        const attributeNames = Object.keys(properties);
        if (!attributeNames.includes(attributeName)) {
            attributeName = attributeName.replace(/[^A-Za-z0-9_]/g, '_');
            selectedAttribute = attributeNames.find((name) => (name.toLowerCase() === attributeName.toLowerCase()) || ((name.toLowerCase().includes(attributeName.toLowerCase()))));
        }
        if (attributeNames.indexOf(selectedAttribute) < 0) {
            datatype = '';
        } else {
            const attribute = properties[selectedAttribute];
            datatype = attribute && attribute.datatype ? attribute.datatype : '';
        }
        return datatype;
    }, [properties]);


    /**
     * Handles attribute selection event, which adds/removes
     * attributes to the profiling
     */
    const onSelectAttribute = useCallback((selectedAttribute) => {
        for (const attribute of attributes) {
            if (attribute.name === selectedAttribute.name) {
                attribute.isSelected = !selectedAttribute.isSelected;
            }
            if (!attribute.datatype) {
                let datatype = getDatatype(attribute.name);
                datatype = datatype ? datatype.datatype : '';
                attribute.datatype = datatype;
            }
        }

        const isAllSelected = (attributes.filter((p) => p.isSelected).length === attributes.length);
        setAttributes([...attributes]);
        getAttributeStats();
        setSelectAll(isAllSelected);
        updateSelectedAttributes();
    }, [attributes, getAttributeStats, getDatatype, updateSelectedAttributes]);

    const onTableScroll = () => {
        const isBottomReached = tableRef.current.clientHeight >= (tableRef.current.scrollHeight - tableRef.current.scrollTop - 150);
        // const isTopReached = tableRef.current.scrollTop <= 90;
        const isLeftReached = tableRef.current.clientWidth >= (tableRef.current.scrollWidth - tableRef.current.scrollLeft - 150);
        // const isRightReached = tableRef.current.scrollLeft <= 150;
        if (isBottomReached) {
            // const skipCount = (rowDataLength.limit + initialRowDataLimit.limit) / 2;
            if (inputData.length > rowDataLength.limit + initialRowDataLimit.limit) {
                setRowDataLength({ skip: 0, limit: rowDataLength.limit + initialRowDataLimit.limit });
            } else {
                if (inputData.length !== rowDataLength.limit) {
                    setRowDataLength({ skip: 0, limit: inputData.length });
                }
            }
        }
        /*
         * if (isTopReached && rowDataLength.skip > 0) {
         *     if (rowDataLength.limit - initialRowDataLimit.limit !== 0) {
         *         const skipCount = (rowDataLength.limit - initialRowDataLimit.limit) / 2;
         *         if ((rowDataLength.limit - initialRowDataLimit.limit) === initialRowDataLimit.limit) {
         *             setRowDataLength({ skip: 0, limit: rowDataLength.limit - initialRowDataLimit.limit });
         *         } else {
         *             setRowDataLength({ skip: skipCount, limit: rowDataLength.limit - initialRowDataLimit.limit });
         *         }
         *     } else {
         *         setRowDataLength({ skip: 0, limit: initialRowDataLimit.limit });
         *     }
         * }
         */
        /*
         * if (isRightReached && columnDataLength.skip > 0) {
         *     if (columnDataLength.limit - initialColumnDataLimit.limit !== 0) {
         *         const skipCount = (columnDataLength.limit - initialColumnDataLimit.limit) / 2;
         *         if ((columnDataLength.limit - initialColumnDataLimit.limit) === initialColumnDataLimit.limit) {
         *             setColumnDataLength({ skip: 0, limit: columnDataLength.limit - initialColumnDataLimit.limit });
         *         } else {
         *             setColumnDataLength({ skip: skipCount, limit: columnDataLength.limit - initialColumnDataLimit.limit });
         *         }
         *     } else {
         *         setColumnDataLength({ skip: 0, limit: initialColumnDataLimit.limit });
         *     }
         * }
         */
        if (isLeftReached) {
            // const skipCount = (columnDataLength.limit + initialColumnDataLimit.limit) / 2;
            if (attributes.length > columnDataLength.limit + initialColumnDataLimit.limit) {
                setColumnDataLength({ skip: 0, limit: columnDataLength.limit + initialColumnDataLimit.limit });
            } else {
                if (attributes.length !== columnDataLength.limit) {
                    setColumnDataLength({ skip: 0, limit: attributes.length });
                }
            }
        }

    };

    return (
        <Grid container onMouseLeave={() => updateAttributeProps()}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                <Grid container direction="row" justify="space-between" alignItems="center">
                    <Grid item className={classNames(classes.propertiesSearch, classes.includeSearchContainer, classes.dqSearchBox)}>
                        <AttributeSelect
                            placeholder="Search Attribute"
                            value={searchKey ? searchKey : ''}
                            onChange={(event) => searchAttributes(event.target.value)}
                            options={filterAttributes ? filterAttributes : []}
                            selectedValuePath="name"
                            displayMemberPath="name"
                            removeClass
                            openSuggestionsOnClick
                            name="Attribute"
                            id="Attribute"
                            hideIcon
                            fullWidth
                        />
                    </Grid>

                    {/* <Grid item className={classes.selectAllCheckbox}>
                        <Grid className={classes.inlineBlock}>
                            <CheckboxComponent
                                checked={selectAll}
                                disabled={!isEditable}
                                name={'selectAll'}
                                onClick={() => onSelectAll()}
                                checkboxLabel={"Select All"}
                            />
                        </Grid>
                    </Grid> */}
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classNames(classes.attributesTableContainer, classes.marginTop15, attributes && attributes.length <= 0 ? 'loading' : '')} style={{ position: 'relative' }}>
                {
                    attributes && attributes.length > 0 &&
                    <Grid>
                        <Card className={classes.accordian}>
                            <Grid ref={tableRef} container className={classNames(classes.attributesTable)} onScroll={onTableScroll}>
                                <Table stickyHeader aria-label="sticky table">
                                    <TableHead className={classes.attributeTabHeader}>
                                        <TableRow className={classes.rowTopAlign}>
                                            {
                                                attributes &&
                                                attributes.slice(columnDataLength.skip, columnDataLength.limit).map((attribute, index) =>
                                                    <TableCell key={`header${index}`} align="center" style={{ padding: '2px 10px 2px 10px', backgroundColor: theme.palette.common.white }}>
                                                        {/* <FormControlLabel className={classes.attributeTitle}
                                                                control={
                                                                    <Checkbox checked={attribute.isSelected}
                                                                        disabled={!isEditable}
                                                                        name={attribute.name}
                                                                        onChange={() => onSelectAttribute(attribute)}
                                                                        size="small"
                                                                        color="primary" />
                                                                }
                                                                label={attribute.name}
                                                            /> */}
                                                        <ToolTipComponent title={attribute.name} arrow>
                                                            <Grid className={classes.inlineBlock}>
                                                                {/* <CheckboxComponent
                                                                    checked={attribute.isSelected}
                                                                    disabled={true}
                                                                    name={attribute.name}
                                                                    onChange={() => { }}
                                                                    onClick={() => onSelectAttribute(attribute)}
                                                                    checkboxLabel={attribute.name}
                                                                /> */}
                                                                {attribute.name}
                                                            </Grid>
                                                        </ToolTipComponent>
                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                        <TableRow>
                                            {
                                                attributes &&
                                                attributes.slice(columnDataLength.skip, columnDataLength.limit).map((attribute, index) => {
                                                    const datatype = getDatatype(attribute.name);
                                                    return (
                                                        <TableCell key={`datatype${index}`} align="center" style={{ padding: 8, backgroundColor: theme.palette.common.white }}>
                                                            <Grid className={classes.attributeTableDataType}>
                                                                <Grid className={classes.typeIcon}
                                                                    style={
                                                                        {
                                                                            color: getDataTypeColor(datatype ? datatype : ''),
                                                                            marginRight: '10px'
                                                                        }
                                                                    }>
                                                                    {datatype ? datatype[0].toUpperCase() : 'T'}
                                                                </Grid>
                                                                <Typography className={classNames(classes.typeText, classes.AttritypeText)}>
                                                                    {datatype ? datatype : 'Text'}
                                                                </Typography>
                                                            </Grid>
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                        <TableRow className={classes.attributesTableChartRow}>
                                            {
                                                attributes && valueCounts &&
                                                attributes.slice(columnDataLength.skip, columnDataLength.limit).map((attribute, index) => {
                                                    const datatype = getDatatype(attribute.name);
                                                    let chartValues = [];
                                                    const targetIndex = attributeStats.findIndex((d) => d.name === attribute.name);
                                                    if (targetIndex > -1) {
                                                        const attrStats = attributeStats[targetIndex];
                                                        const minVal = (datatype === 'Integer' && attrStats.fieldType === '') ? attrStats.min_val : attrStats.min_length;
                                                        const maxVal = (datatype === 'Integer' && attrStats.fieldType === '') ? attrStats.max_val : attrStats.max_length;
                                                        let minPercentage = (minVal && maxVal !== 0) ? 100 * (minVal / maxVal) : 0;
                                                        let maxPercentage = 100;
                                                        if (minVal === maxVal) {
                                                            minPercentage = 100;
                                                        }

                                                        if (minPercentage < 0) {
                                                            minPercentage = 0;
                                                        }

                                                        if (minPercentage > 100) {
                                                            minPercentage = 100;
                                                        }

                                                        if (minVal === maxVal === 0) {
                                                            minPercentage = 0;
                                                            maxPercentage = 0;
                                                        }
                                                        chartValues = [
                                                            {
                                                                value: 'Null',
                                                                count: attrStats.null_percentage,
                                                                isPercentage: true
                                                            },
                                                            {
                                                                value: 'Zero',
                                                                count: attrStats.zero_values_percentage,
                                                                isPercentage: true
                                                            },
                                                            {
                                                                value: 'Blank',
                                                                count: attrStats.blank_percentage,
                                                                isPercentage: true
                                                            },
                                                            {
                                                                value: `${(datatype === 'Integer' && attrStats.fieldType === '') ? 'Min' : 'Min length'}`,
                                                                alternateVal: (datatype === 'Integer' && attrStats.fieldType === '') ? attrStats.min_val : attrStats.min_length,
                                                                isPercentage: false,
                                                                count: minPercentage.toFixed(2)
                                                            },
                                                            {
                                                                value: `${(datatype === 'Integer' && attrStats.fieldType === '') ? 'Max' : 'Max length'}`,
                                                                alternateVal: (datatype === 'Integer' && attrStats.fieldType === '') ? attrStats.max_val : attrStats.max_length,
                                                                isPercentage: false,
                                                                count: maxPercentage
                                                            }
                                                        ];
                                                    }
                                                    return (
                                                        <TableCell key={`chart${index}`} align="center" style={{ padding: 0 }}>
                                                            <Grid style={{ display: 'flex', alignItem: 'center' }}>
                                                                <BarChart width={150} style={{ margin: 'auto', marginLeft: 0 }} height={50} data={chartValues}>
                                                                    <Bar dataKey="count"
                                                                        barSize={10}
                                                                        barGap={3}
                                                                        fill={getDataTypeColor(datatype ? datatype : '')}
                                                                    />
                                                                    <Tooltip content={
                                                                        ({ active, payload }) => {
                                                                            if (active && payload && payload.length > 0) {
                                                                                let data = payload[0].payload.value.toString();
                                                                                data = data.replace(/[a-zA-Z0-9|:@._!@#$%&*=()'/''-']/g, 'x');
                                                                                const sensitivity = properties[attribute.name] ? properties[attribute.name].sensitivity : 1;
                                                                                const attributeHovered = (sensitivity > props.sensitivityLevel) ? data : payload[0].payload.value;
                                                                                return (
                                                                                    <Grid className={classes.tooltip}>
                                                                                        <Typography>
                                                                                            {`${attributeHovered}: ${payload[0].payload.alternateVal ? payload[0].payload.alternateVal : payload[0].payload.count}${payload[0].payload.isPercentage ? '%' : ''}`}
                                                                                        </Typography>
                                                                                    </Grid>
                                                                                );
                                                                            }
                                                                        }
                                                                    }
                                                                        cursor={{ opacity: 0.5 }}
                                                                        // wrapperStyle={{ visibility: "visible", pointerEvents: "auto" }}
                                                                        wrapperStyle={{ visibility: "visible" }}
                                                                    />
                                                                </BarChart>
                                                            </Grid>
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody >
                                        {
                                            inputData.slice(rowDataLength.skip, rowDataLength.limit).map((row, index) => (
                                                <TableRow key={`inputData${index}`}>
                                                    {
                                                        attributes &&
                                                        attributes.slice(columnDataLength.skip, columnDataLength.limit).map((attribute) =>
                                                            <TableCell key={`values${attribute.name}${index}`} align="left">
                                                                <ToolTipComponent title={dataAccessRestrict(row[attribute.name], properties && properties[attribute.name] && properties[attribute.name].sensitivity ? properties[attribute.name].sensitivity : 1, props.sensitivityLevel)} arrow placement="bottom-start">
                                                                    <Typography noWrap>
                                                                        {dataAccessRestrict(row[attribute.name], properties && properties[attribute.name] && properties[attribute.name].sensitivity ? properties[attribute.name].sensitivity : 1, props.sensitivityLevel)}
                                                                    </Typography>
                                                                </ToolTipComponent>
                                                            </TableCell>)
                                                    }
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Card>
                    </Grid>
                }
                {
                    attributes && attributes.length === 0 && !isLoading &&
                    <Grid container alignItems="center" justify="center" className={classes.noResultFound}>
                        <Grid>
                            <img src={NoResultImg} alt="No Result Found" />
                            <Typography variant="h4" align="center">
                                {'No Result Found'}
                            </Typography>
                        </Grid>
                    </Grid>
                }
                {isLoading && <Loader />}
            </Grid>
        </Grid>
    );
};

DataSetAttributes.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    dataset: PropTypes.object,
    isEditable: PropTypes.bool,
    sensitivityLevel: PropTypes.number
};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(memo(DataSetAttributes));