/**
 * @name ExportDQMetrics.jsx
 * @description Define Add , Update , List and Delete Field Functionalities
 * @author Srinath
 * CreatedAt : 16-Feb-2022
 */

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, withStyles, Button } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classNames from 'classnames';

// Import Styles
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';

// Import Components
import Loader from '../../components/Loaders/Loader.jsx';
import TextBox from '../TextBox/TextBox.jsx';

// Import Actions
import { getExportDQMetrics, updateExportDQMetrics } from '../../actions/settingActions';

const ExportDQMetrics = (props) => {

    // Get Props
    const { classes } = props;

    // Set State
    const [connectionConfig, setConnectionConfig] = useState({
        user: '',
        password: '',
        url: '',
        database: '',
        account: '',
        warehouse: '',
        'schema_name': '',
        application: 'DQLabs'
    });
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    /**
     * handle export on click
     */
    const handleSave = () => {
        setIsLoading(true);
        const requestParams = {
            connectionConfig: connectionConfig
        };
        dispatch(updateExportDQMetrics(requestParams)).then((response) => {
            setIsLoading(false);
        });
    };

    const handleChange = useCallback((property, value) => {
        connectionConfig[property] = value;
        if (property === 'url') {
            connectionConfig.account = value.replace('.snowflakecomputing.com', '');
        }
        setConnectionConfig({ ...connectionConfig });
    }, [connectionConfig]);

    /**
     * @name useEffect
     * @description UseEffect run when page initialize and any updates happends
     */
     useEffect(() => {
        dispatch(getExportDQMetrics()).then((response) => {
            if (response) {
                setConnectionConfig(response.metric_config);
            }
        });
    }, [dispatch]);

    return (
        <Grid>
            <ValidatorForm name="export_metadata" onSubmit={() => null}>
                <Grid className={classNames(classes.filterContainer)}>
                    <Grid container direction="row" spacing={3} style={{ marginTop: 10 }}>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="URL"
                                        name="url"
                                        validators={['required']}
                                        errorMessages={['URL is required']}
                                        value={connectionConfig.url}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        disabled={false}
                                        label="Database Name"
                                        name="database"
                                        validators={['required']}
                                        errorMessages={['Database Name is required']}
                                        value={connectionConfig.database}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="Username"
                                        name="user"
                                        disabled={false}
                                        validators={['required']}
                                        errorMessages={['Username is required']}
                                        value={connectionConfig.user}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="Password"
                                        name="password"
                                        validators={['required']}
                                        errorMessages={['password is required']}
                                        type="password"
                                        value={connectionConfig.password}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="Role"
                                        name="role"
                                        disabled={false}
                                        validators={['required']}
                                        errorMessages={['password is required']}
                                        value={connectionConfig.role}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        disabled={false}
                                        label="Schema Name"
                                        name="schema_name"
                                        value={connectionConfig.schema_name}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        // disabled={false}
                                        label="Warehouse Name"
                                        name="warehouse"
                                        validators={['required']}
                                        errorMessages={['Warehouse Name  is required']}
                                        value={connectionConfig.warehouse}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.exportMetadataButton}>
                    <Button disabled={false} onClick={() => handleSave()} variant="contained" color="primary">
                        {'Save'}
                        {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                    </Button>
                </Grid>
            </ValidatorForm>
        </Grid>
    );
};

ExportDQMetrics.propTypes = {
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ExportDQMetrics);