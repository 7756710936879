import React, { useCallback, useEffect, useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';
import moment from 'moment-timezone';

const VerticalStackedChart = (props) => {
    const { classes, analysisData, stackgroup, theme, label } = props;
    const [chartData, setChartData] = useState([]);

    const loadProperties = useCallback((analysis) => {
        setChartData([...analysis]);
    }, []);

    useEffect(() => {
        loadProperties([...analysisData]);
    }, [analysisData, loadProperties]);

    useEffect(() => {
        if (chartData && chartData.length !== 0) {
            const data = chartData.map((data) => {
                return {
                    ...data,
                    date: new Date(data.date)
                };
            });

            const margin = {
                top: 80,
                right: 80,
                bottom: 20,
                left: 20
            },
                height = 210;
            let width = 960 - margin.left - margin.right;

            d3.select(".vertical-stacked-chart > svg").remove();
            const svg = d3.select(".vertical-stacked-chart")
                .append("svg")
                .attr("width", "100%")
                .style("margin", "0px auto")
                .attr("height", height + margin.top + margin.top);
            const g = svg
                .append("g")
                .attr("width", (_, i, nodes) => {
                    width = nodes[i].parentNode.clientWidth - margin.left - margin.top;
                    return width;
                })
                .attr("height", height)
                .attr("transform",
                    "translate(" + (margin.left + 35) + "," + (margin.left + margin.top + 15) + ")");

            d3.select(".vertical-stacked-chart >.tooltip").remove();
            const div = d3.select(".vertical-stacked-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            const xMin = d3.min(data, (d) => {
                return d.date;
            });

            const xMax = d3.max(data, (d) => {
                return d.date;
            });

            // scale using range
            const x = d3
                .scaleTime()
                .domain([xMin, xMax])
                .rangeRound([50, width])
                .nice();
            const y = d3
                .scaleLinear()
                .range([0, height])
                .domain([0, d3.max(data, (d) => d.total) + 10]);

            const z = d3.scaleOrdinal()
                .domain(stackgroup)
                .range(["#FF829D", "#70C6FF", "#FFCD56"]);

            g.append("g")
                .attr("class", "x-axis")
                .attr("color", theme.palette.chartColors.axis)
                .attr("transform", `translate(0,0)`)
                .call(d3.axisTop(x).ticks(data.length - 1).tickSize(-height));


            if (label) {
                // text label for the x axis
                svg.append("text")
                    .attr("transform",
                        "translate(" + (width / 2) + " ," +
                        (margin.top - 60) + ")")
                    .style("text-anchor", "middle")
                    .text(label.x);

                // text label for the y axis
                svg.append("text")
                    .attr("transform", "rotate(-90)")
                    .attr("y", 0)
                    .attr("x", 0 - (height / 2) - margin.top - 20)
                    .attr("dy", "1em")
                    .style("text-anchor", "middle")
                    .text(label.y);
            }


            g.append("g")
                .attr("class", "y-axis")
                .attr("color", theme.palette.chartColors.axis)
                .call(d3.axisLeft(y).ticks(10).tickSize(-width));


            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');

            svg.selectAll('.x-axis .tick-text').attr("transform", "rotate(270)").attr("x", 32).attr("y", 5);

            const stackedData = d3.stack()
                .keys(stackgroup)
                .order(d3.stackOrderNone)
                .offset(d3.stackOffsetNone)(data);

            g.append("g")
                .selectAll("g")
                .data(stackedData)
                .enter().append("g")
                .attr("fill", (d) => { return z(d.key); })
                .selectAll("rect")
                .data((d) => { return d; })
                .enter().append("rect")
                .attr("x", (d) => { return x(d.data.date) - 72; })
                .attr("y", (d) => { return y(d[0]); })
                .attr("height", (d) => { return y(d[1]) - y(d[0]); })
                .attr("width", 12)
                .on('mouseover', (d, i, p) => {
                    const x = d3.mouse(p[i])[0] - 15;
                    const y = d3.mouse(p[i])[1] - 25;
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html(`Date : ${moment(d.data.date).format('MMMM DD YYYY')} <br/> Count : ${parseInt(d[1] - d[0])}`)
                        .style("left", x + "px")
                        .style("top", y + "px");
                }).on('mouseout', (d, i, p) => {
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                });
            const legendGap = 220;
            const legendGroup = svg.append("g")
                .attr("class", "legends")
                .style("margin-right", 22)
                .attr("transform", "translate(" + ((width / 2) - legendGap) + "," + (height + 145) + ")");

            // Interval Legend rectangle
            legendGroup.selectAll(".legend-group")
                .data(stackgroup)
                .enter()
                .append("g")
                .attr("class", "legend-group")
                .style("opacity", 1)
                .append("rect")
                .attr("class", "legend-rect")
                .attr("width", 14)
                .attr("height", 10)
                .attr('x', (_, index) => { return (index * legendGap); })
                .style("fill", (d, index) => z(d));

            legendGroup.selectAll(".legend-group")
                .append("text")
                .attr("class", "legend-text")
                .attr("x", (_, index) => { return index * legendGap + 20; })
                .attr("y", 6)
                .text((line, i, nodes) => {
                    d3.select(nodes[i]).attr("class", `legend-text ${line} `);
                    return line;
                })
                .attr("alignment-baseline", "middle");
        }
    }, [chartData, label, stackgroup, theme.palette.chartColors.axis]);

    return (
        <Grid className={classNames(classes.chartStyles, classes.barline, 'vertical-stacked-chart')} />
    );
};

VerticalStackedChart.propTypes = {
    classes: PropTypes.object,
    analysisData: PropTypes.array,
    theme: PropTypes.object,
    stackgroup: PropTypes.array,
    label: PropTypes.object
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(VerticalStackedChart);