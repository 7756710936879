import React, { useState, Fragment, useCallback, useEffect } from 'react';
import { withStyles, Typography, TableRow, TableCell, Collapse, Table, TableBody, TableHead } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Styles from '../../layouts/Styles.jsx';
import MasterStyles from './MasterStyles.jsx';
import MasterAttributeRow from './MasterAttributeRow.jsx';
import DraggableTableRow from './MasterGroup/DraggableTableRow.jsx';
import DroppableTableBody from './MasterGroup/DroppableTableBody.jsx';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { sortTable } from '../../helpers/appHelpers.js';

const MasterAttributCollapsibleRow = (props) => {
    const { attribute, attributes, classes, onDragEnd, modelId, index, headers, isEditable, isParrent, onGroupChange, onDelete, onCreateAttribute, onChange } = props;
    const [open, setOpen] = useState(Boolean(attribute.isOpen));
    const childAttributes = attribute.childAttributes ? [...attribute.childAttributes] : [];
    const groupName = attribute.group_name ? attribute.group_name : '';

    const toggleRow = useCallback((event) => {
        event.stopPropagation();
        setOpen(!open);
    }, [open]);

    const loadAttribute = useCallback((attribute) => {
        setOpen(Boolean(attribute.isOpen));
    }, []);

    useEffect(() => {
        if (modelId && !attribute) {
            return;
        }
        loadAttribute(attribute);
    }, [modelId, attribute, loadAttribute]);

    const getTableCellProps = (header) => {
        let tableCellProps = {};
        switch (header) {
            case 'Attribute Name':
                tableCellProps = {
                    className: classes.attributeHeader
                };
                break;
            case 'Sensitivity':
            case 'Occurance':
            case 'Weightage':
                tableCellProps = {
                    style: { width: '15%' }
                };
                break;
            case 'Actions':
                tableCellProps = {
                    align: 'center'
                };
                break;
            default:
                tableCellProps = {};
                break;
        }
        return tableCellProps;
    };

    return (
        <Fragment>
            <TableRow className={classes.attributeRow}
                component={DraggableTableRow(`${groupName ? groupName : 'row'}_${index}`, index, attributes ? attributes.length : 0, classes.dragHandlerContainer)}
            >
                <MasterAttributeRow
                    attribute={{ ...attribute }}
                    attributes={[...attributes]}
                    modelId={modelId}
                    type={isParrent ? "parent" : "child"}
                    isEditable={isEditable}
                    enableToggle={childAttributes && childAttributes.length > 0}
                    toggleRow={toggleRow}
                    isExpanded={open}
                    onCreated={onCreateAttribute}
                    onChanged={onChange}
                    onGroupChange={onGroupChange}
                    deleteAtribute={onDelete}
                />
            </TableRow>
            {
                childAttributes && childAttributes.length > 0 &&
                <TableRow className={classes.collapseContainer}>
                    <TableCell colSpan={headers.length + 1} style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }} >
                        <Collapse in={open} timeout="auto" unmountOnExit className={classes.collapsibleAttributeRow}>
                            <Table stickyHeader className={classes.masterAttributesTable}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classNames(classes.dragHandlerContainer, 'dragHandlerContainer')}>
                                            <DragIndicatorIcon />
                                        </TableCell>
                                        {

                                            headers.map((header, index) =>
                                                <TableCell key={`collapsibleTableHeader_accordian_${index}`} {...getTableCellProps(header)}>
                                                    <Typography variant="body1" className={classes.tableHeader}>
                                                        {header}
                                                    </Typography>
                                                </TableCell>
                                            )
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody component={DroppableTableBody(onDragEnd, `master_attributes_container${attribute.name && attribute.name.length > 0 ? `_${attribute.name}` : ''}`, childAttributes)}>
                                    {
                                        childAttributes && childAttributes.length > 0 &&
                                        sortTable(childAttributes, 'asc', 'position').map((attribute, index) => {
                                            return (
                                                <MasterAttributCollapsibleRow
                                                    key={index}
                                                    index={index}
                                                    attribute={{ ...attribute }}
                                                    attributes={[...attributes]}
                                                    modelId={modelId}
                                                    isParrent={false}
                                                    isEditable={isEditable}
                                                    headers={headers}
                                                    onDragEnd={onDragEnd}
                                                    classes={classes}
                                                    onCreateAttribute={onCreateAttribute}
                                                    onGroupChange={onGroupChange}
                                                    onDelete={onDelete}
                                                />
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            }
        </Fragment >
    );
};

MasterAttributCollapsibleRow.propTypes = {
    classes: PropTypes.object,
    headers: PropTypes.array,
    attribute: PropTypes.object,
    attributes: PropTypes.array,
    onDragEnd: PropTypes.func,
    modelId: PropTypes.string,
    index: PropTypes.number,
    isEditable: PropTypes.bool,
    isParrent: PropTypes.bool,
    onGroupChange: PropTypes.func,
    onDelete: PropTypes.func,
    onCreateAttribute: PropTypes.func,
    onChange: PropTypes.func
};

export default withStyles((theme) => ({
    ...MasterStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MasterAttributCollapsibleRow);