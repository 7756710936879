const ConfigureDataSourceStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    title: {
        marginTop: 25,
        marginBottom: 9
    },
    Headercontainer: {
        padding: "0 10px"
    },
    subTitleContainer: {
        padding: "0 10px",
        marginTop: 10
    },
    textboxContainer: {
        '& input': {
            background: theme.palette.common.white
        }
    },
    inputSearchDatasrc: {
        '& .MuiInput-underline:before': {
            height: '32px !important'
        },
        '& input': {
            height: '32px !important'
        }
    },
    tileContainer: {
        // height: '100%',
        overflow: 'hidden',
        // overflowY: 'auto',
        margin: "-10px",
        padding: 20,
        paddingRight: 30
    },
    tileContainerBlock: {
        height: 'calc( 100vh - 180px )',
        overflowY: 'auto',
        // paddingBottom: 60,
        alignContent: 'baseline'
    },
    btnCancel: {
        marginTop: 10
    },
    btnCancelItem: {
        position: 'fixed',
        right: 24,
        bottom: 12
    },
    backButtonContainer: {
        marginTop: 20
    },
    connectorContainer: {
        marginTop: 15,
        height: '100%',
        width: '100%'
    },
    includeSearchContainer: {
        position: 'relative',
        minWidth: 280
    },
    includeSearchIcon: {
        position: 'absolute',
        top: 1,
        right: 3,
        padding: 9
    },
    includeDeleteIcon: {
        position: 'absolute',
        top: 1,
        right: 3,
        padding: 6
    },
    boxWidth: {
        width: "185px !important"
    }
});

export default ConfigureDataSourceStyles;