const SemanticGlossaryStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    section: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 4px #CFE0E766",
        height: '100%',
        padding: 15,
        position: 'relative'
    },
    leftSection: {
        maxWidth: 300,
        flexBasis: 300,
        transition: 'all ease 0.3s'
    },
    leftSectionExpand: {
        maxWidth: 60,
        flexBasis: 60,
        transition: 'all ease 0.3s',
        paddingLeft: '30px !important'
    },
    rightSectionExpand: {
        maxWidth: 'calc(100% - 60px)',
        flexBasis: 'calc(100% - 60px)',
        paddingLeft: '0px !important',
        transition: 'all ease 0.3s'
    },
    rightSection: {
        maxWidth: 'calc(100% - 300px)',
        flexBasis: 'calc(100% - 300px)',
        paddingLeft: '0px !important',
        transition: 'all ease 0.3s'
    },
    glossaryNameTextField: {
        '& .MuiInputBase-input': {
            ...theme.typography.h4
        }
    },
    glossaryTreeItem: {
        display: 'flex',
        position: 'relative',
        '& .options': {
            position: 'absolute',
            right: 2,
            top: 0,
            padding: 0,
            color: theme.palette.grey.dark,
            display: 'none'
        },
        '&:hover .options': {
            display: 'block'
        }
    },
    glossaryTree: {
        '& li.MuiTreeItem-root': {
            paddingTop: 5,
            paddingBottom: 5
        },
        '& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
            backgroundColor: 'rgb(244 246 249)'
        },
        marginTop: 20,
        height: 'calc(100vh - 160px)',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    glossarySelect: {
        '& .MuiSelect-select.MuiSelect-select': {
            display: 'flex'
        }
    },
    glossaryMenu: {
        padding: '5px 18px',
        minWidth: 120
    },
    deleteText: {
        color: theme.palette.primary.main
    },
    glossaryList: {
        backgroundColor: theme.palette.background.container,
        marginBottom: 10,
        padding: 15,
        boxShadow: '0px 0px 4px #CFE0E766',
        border: `1px solid #e8e8e8`
    },
    listDescriptionText: {
        color: theme.palette.grey.dark,
        fontSize: 14,
        marginLeft: 35
    },
    lightTxt: {
        color: theme.palette.grey.dark
    },
    lightTxtInfo: {
        color: theme.palette.primary.dark,
        fontSize: 11
    },
    descriptionTxt: {
        color: theme.palette.grey.dark,
        fontSize: 14
    },
    seperator: {
        paddingLeft: 8,
        paddingRight: 8
    },
    titleTxt: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.primary.dark
        }
    },
    categoryListContainer: {
        overflowY: 'auto',
        height: 'calc(100vh - 300px)',
        marginTop: 20
    },
    assetModal: {
        minWidth: 480,
        padding: 10
    },
    smallFontSize: {
        fontSize: 13
    },
    assetTable: {
        height: 'calc(100vh - 480px)',
        overflowY: 'auto'
    },
    assetTableHeaderText: {
        fontSize: 14,
        fontWeight: 600
    },
    definitionContainer: {
        padding: '0px 20px 20px 20px',
        overflowY: 'auto',
        height: 'calc(100vh - 400px)',
        position: 'relative',
        marginTop: 0
    },
    formLabelSection: {
        width: '19%',
        paddingBottom: 10,
        marginLeft: 0,
        '& .MuiSwitch-sizeSmall': {
            marginRight: 4
        }
    },
    formLabel: {
        '& .MuiTypography-body1': {
            fontSize: 13,
            color: '#222'
        }
    },
    definitionSliderContainer: {
        width: '30%'
    },
    sliderValue: {
        marginLeft: 16,
        marginTop: 4,
        color: `${theme.palette.grey.default} !important`
    },
    valuesTextboxContainer: {
        '& .MuiInput-underline:before': {
            height: '30px !important'
        },
        '& input': {
            width: 68,
            height: '30px !important',
            fontFamily: theme.palette.textFont
        },
        '& .MuiTextField-root': {
            width: 'inherit'
        }
    },
    propertyComponents: {
        marginTop: 5,
        color: theme.palette.grey.default,
        '& .MuiGrid-item': {
            marginRight: '10%',
            '&:last-child': {
                marginRight: 0
            }
        }
    },
    rangeSeparator: {
        marginLeft: 20,
        marginRight: 20
    },
    datatypeContainer: {
        width: 150,
        display: 'flex'
    },
    assetInput: {
        '& .MuiAutocomplete-input': {
            height: 20
        },
        '& .MuiInputBase-formControl': {
            padding: '5px 16px'
        },
        '& .MuiChip-label': {
            maxWidth: 120
        }
    },
    grid11: {
        flexGrow: 0,
        maxWidth: '90%',
        flexBasis: '90%'
    },
    grid1: {
        flexGrow: 0,
        maxWidth: '10%',
        flexBasis: '10%'
    },
    libraryContainer: {
        maxWidth: 440,
        padding: 15,
        margin: 0
    },
    addTags: {
        color: theme.palette.primary.dark,
        // paddingLeft: 7,
        cursor: 'pointer'
    },
    arrowIconContainer: {
        position: 'absolute',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: '10px',
        right: '20px'
    },
    arrowIconBtn: {
        background: '#fff',
        height: '80px',
        borderRadius: '6px',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ddd',
        '&:hover': {
            backgroundColor: 'rgb(234 236 239) !important'
        }
    },
    semanticLeftSection: {
        height: '100%',
        padding: 15,
        position: 'relative',
        minWidth: 20
    },
    semanticLeftSectionActive: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 4px #CFE0E766",
        '& .arrowIconContainer': {
            right: '-8px !important'
        }
    },
    sematicBreadcrumb: {
        '& .MuiTypography-root:first-child:hover': {
            color: `${theme.palette.grey.default} !important`,
            cursor: 'default'
        }
    },
    iconStyle: {
        height: 18,
        marginTop: 21
    },
    hoverBgGrey: {
        '& .MuiInput-root:hover': {
            backgroundColor: theme.palette.grey.exteremeLight,
            '& textarea': {
                padding: '0px 5px !important',
                transition: 'all 0.2s linear'
            }
        }
    },
    semanticsMultiSelect: {
        '& .MuiChip-deleteIcon': {
            top: '-5px !important'
        },
        '& .MuiChip-root': {
            marginTop: '2px !important',
            marginBottom: '2px !important'
        }
    },
    attributeCell: {
        maxWidth: '300px !important',
        wordWrap: 'break-word'
    }
});

export default SemanticGlossaryStyles;