import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Button, Paper, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../layouts/Styles.jsx';
import ActionItemStyles from './ActionItemStyles.jsx';
import { getUserActionItem, createActionComment, updateActionItem, toggleSidePanel } from '../../actions/notificationActions';
import ActionListComment from './ActionListComment.jsx';
import ActionListDetail from './ActionListDetail.jsx';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CloseIcon from '@material-ui/icons/Close';
import { getMentionName } from '../../helpers/appHelpers';
import Loader from '../Loaders/Loader.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';

const ActionList = (props) => {
    const { classes, history } = props;
    const [actionItems, setActionItems] = useState([]);
    const [detailView, setDetailView] = useState(false);
    const [actionItem, setSelectedItem] = useState({});
    const [title, setTitle] = useState('Action Items');
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector(({ account }) => account.user);
    const users = useSelector(({ account }) => account.users);
    const datasourceId = useSelector(({ datasource }) => datasource.selectedDatasourceId);
    const domainId = useSelector(({ domain }) => domain.selectedDomainId);
    const datasetId = useSelector(({ dataset }) => dataset.selectedDatasetId);

    const getActionItem = useCallback(() => {
        setLoading(true);
        dispatch(getUserActionItem(window.location.href.includes('/domain') ? domainId : datasourceId, window.location.href.includes('/dataset/') ? datasetId : 0, window.location.href.includes('/domain') ? "domain" : "source")).then((response) => {
            if (response) {
                setActionItems([...response]);
            }
            setLoading(false);
        });
    }, [datasetId, datasourceId, dispatch, domainId]);

    useEffect(() => {
        getActionItem();
    }, [getActionItem]);

    const onChange = useCallback((property, value) => {
        const requestParams = {
            property: property,
            value: value
        };
        actionItem[property] = value;
        setSelectedItem({ ...actionItem });
        dispatch(updateActionItem(requestParams, actionItem.id));
    }, [actionItem, dispatch]);

    const updateItem = useCallback((property, value, actionId) => {
        const requestParams = {
            property: property,
            value: value
        };
        dispatch(updateActionItem(requestParams, actionId));
    }, [dispatch]);

    const selectActionItem = useCallback((item) => {

        setDetailView(true);
        setTitle(item.comments ? item.comments[0].comment : '');
        if (item.status === "new") {
            item.status = "open";
            updateItem('status', 'open', item.id);
        }
        setSelectedItem({ ...item });
    }, [updateItem]);

    const addComment = useCallback((message) => {
        const comment = {
            "comment": message,
            "is_first_comment": false,
            "comment_user_id": user.id,
            "created_date": Date.now(),
            "action_id": actionItem.id,
            ...user
        };
        actionItem.comments.push(comment);
        dispatch(createActionComment(comment));
        setSelectedItem({ ...actionItem });
    }, [actionItem, dispatch, user]);


    const hidePanel = useCallback(() => {
        dispatch(toggleSidePanel({ panel: false, type: '' }));
    }, [dispatch]);

    const goBack = useCallback(() => {
        setDetailView(false);
        setTitle('Action Items');
        const index = actionItems.findIndex((item) => item.id === actionItem.id);
        if (index !== -1) {
            actionItems[index] = { ...actionItem };
            setActionItems([...actionItems]);
        }
    }, [actionItem, actionItems]);

    const viewAll = useCallback(() => {
        history.push('/actionitem');
        dispatch(toggleSidePanel({ panel: false, type: '' }));
    }, [dispatch, history]);

    return (
        <Grid style={{ position: 'relative' }}>
            <Grid container justify="space-between" alignItems="center" className={classes.sidePanelHeader}>
                <Grid item style={{ display: 'flex' }}>
                    {
                        detailView &&
                        <IconButton className={classes.nopadding} onClick={() => goBack()}>
                            <KeyboardBackspaceIcon />
                        </IconButton>
                    }

                    <Typography component="h5" variant="h5" style={{ marginLeft: detailView ? "5px" : "0px" }} className={detailView ? classes.actionTitleTxt : ''} dangerouslySetInnerHTML={{ __html: getMentionName(title, users) }} />
                    {
                        !detailView &&
                        <Paper className={classes.panelNotification}>
                            {actionItems.length}
                        </Paper>
                    }
                </Grid>
                <Grid item>
                    {
                        !detailView &&
                        <Button variant="inline" onClick={() => viewAll()}>
                            View All
                        </Button>
                    }
                    <IconButton className={classes.nopadding} onClick={() => hidePanel()}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid className={classes.listContainer}>
                {isLoading && <Loader />}
                {!isLoading && actionItems.length === 0 && <NoResultFound />}
                {
                    !detailView ?
                        <Grid>
                            {
                                actionItems.map((item, index) =>
                                    <ActionListComment
                                        type="main"
                                        actionItem={item}
                                        key={index}
                                        selectActionItem={(item) => selectActionItem(item)} />
                                )
                            }
                        </Grid> :
                        <ActionListDetail
                            actionItem={actionItem}
                            addComment={(comment) => addComment(comment)}
                            onChange={(property, value) => onChange(property, value)}
                            history={history}
                        />
                }
            </Grid>
        </Grid>
    );

};

ActionList.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object
};


export default withStyles((theme) => ({
    ...ActionItemStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ActionList);