import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Button, Popover, Typography } from '@material-ui/core';
import classNames from 'classnames';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import ConnectorStyles from './ConnectorStyles.jsx';

const ReferenceConfig = (props) => {
    const { classes, open, anchorEl, dataset, onChange, onClose, onSave } = props;
    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={
                {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
            transformOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'center'
                }
            }>
            <Grid className={classes.configurationDialog}>
                <Typography variant="h5">
                    Configuration
                </Typography>
                <Grid container>
                    <Grid item xs={12}>
                        <TextBox
                            value={dataset.root_tag ? dataset.root_tag : ''}
                            name="root_tag"
                            placeholder="Root Tag"
                            validators={['required']}
                            errorMessages={['Enter the Root Tag']}
                            label="Root Tag"
                            fullWidth
                            margin="normal"
                            autoComplete="off"
                            onChange={(event) => onChange(event.target.name, event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextBox
                            value={dataset.row_tag ? dataset.row_tag : ''}
                            name="row_tag"
                            placeholder="Row Tag"
                            validators={['required']}
                            errorMessages={['Enter the Row Tag']}
                            label="Row Tag"
                            fullWidth
                            margin="normal"
                            autoComplete="off"
                            onChange={(event) => onChange(event.target.name, event.target.value)}
                        />
                    </Grid>

                </Grid>
                <Grid container justify="flex-end" alignItems="center" className={classes.marginTop15}>
                    <Button onClick={() => onSave()} variant="contained" color="primary" type="submit" className={classNames(classes.actionButtons, classes.submitBtn)}>
                        Save
                    </Button>
                    <Button onClick={() => onClose()} className={classNames(classes.cancelButton, classes.actionButtons)}>
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </Popover >
    );
};

ReferenceConfig.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    anchorEl: PropTypes.element,
    onClose: PropTypes.func,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    dataset: PropTypes.object
};

export default withStyles((theme) => ({
    ...ConnectorStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ReferenceConfig);