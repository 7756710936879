import { lineageActions } from '../constants/actionTypes/lineageActionTypes';
import { appConstants } from '../constants/appConstants';
import * as service from '../services/apiService';
import { request, success, failure } from './baseActions';

export const loadLineage = () => {
    const url = appConstants.END_POINTS.lineage.getmantaflowviewerurl;
    service.getRequest(url).then((response) => {
        window.open(response.data, '_blank');
    });
};

export const getLineageGraph = (datasource_id) => {
    const endpoint = `${appConstants.END_POINTS.lineage.getmantaflowgraphurl}/${datasource_id}`;
    return (dispatch) => {
        dispatch(request(lineageActions.GET_LINEAGE_GRPAH_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(lineageActions.GET_LINEAGE_GRPAH_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(lineageActions.GET_LINEAGE_GRPAH_FAILURE, error));
        });
    };
};

export const getLineageTableauGraph = (datasource_id, workbook_id) => {
    const endpoint = `${appConstants.END_POINTS.lineage.getTableauLineage}/${workbook_id}/${datasource_id}`;
    return (dispatch) => {
        dispatch(request(lineageActions.GET_LINEAGE_GRPAH_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(lineageActions.GET_LINEAGE_GRPAH_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(lineageActions.GET_LINEAGE_GRPAH_FAILURE, error));
        });
    };
};

export const getLineageEntry = (datasource_id) => {
    const endpoint = `${appConstants.END_POINTS.lineage.getlineageentryurl}/${datasource_id}`;
    return (dispatch) => {
        dispatch(request(lineageActions.GET_LINEAGE_ENTRY_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(lineageActions.GET_LINEAGE_ENTRY_SUCCESS, response));
            return response.is_lineage_enabled;
        }, (error) => {
            dispatch(failure(lineageActions.GET_LINEAGE_ENTRY_FAILURE, error));
        });
    };
};

export const getSourceCodeLineage = (fileName) => {
    const endpoint = `${appConstants.END_POINTS.lineage.getSourceCode}/${fileName}`;
    return (dispatch) => {
        dispatch(request(lineageActions.GET_SOURCECODE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(lineageActions.GET_SOURCECODE_SUCCESS, response));
            return response;
        }, (error) => {
            dispatch(failure(lineageActions.GET_SOURCECODE_FAILURE, error));
        });
    };
};

export const clearLineageResponse = () => ({
    type: lineageActions.CLEAR_LINEAGE_GRPAH
});