import React, { useEffect } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';
import PropTypes from 'prop-types';
import { getValue } from '../../helpers/appHelpers';

const PieCircular = (props) => {
    const { chartClassName, height, width, showPercentage, innerRadius, value } = props;

    useEffect(() => {
        const iRadius = innerRadius;
        let data = {
            valid: value,
            inValid: (100 - value)
        };

        const radius = Math.min(width, height) / 6;
        d3.select(`.${chartClassName} > *`).remove();

        const svg = d3.select(`.${chartClassName}`)
            .append("svg")
            .attr("width", (width - 20))
            .attr("height", (height - 20))
            .append("g")
            .attr("transform", "translate(" + (width - 20) / 2 + "," + (height - 20) / 2 + ")");
        const color = d3.scaleOrdinal()
            .domain(data)
            .range(["#54df9a", "#e1e5e6"]);

        // Compute the position of each group on the pie:
        const pie = d3.pie()
            .value((d) => d.value);
        data = pie(d3.entries(data));

        svg
            .selectAll('whatever')
            .data(data)
            .enter()
            .append('path')
            .attr('d', d3.arc()
                .innerRadius(iRadius)
                .outerRadius(radius)
            )
            .attr('fill', (d) => { return (color(d.data.key)); })
            .attr("stroke", "#fff")
            .style("stroke-width", "2px");

        if (showPercentage) {
            svg.append("text")
                .attr("transform", "translate(0,5)")
                .attr("text-anchor", "middle")
                .text(`${getValue(value)}%`);
        }
    }, [chartClassName, height, innerRadius, showPercentage, value, width]);

    return (
        <Grid className={chartClassName} />
    );
};

PieCircular.propTypes = {
    chartClassName: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    showPercentage: PropTypes.bool,
    height: PropTypes.number,
    width: PropTypes.number,
    innerRadius: PropTypes.number
};
export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(PieCircular);