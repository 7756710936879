import React, { Fragment } from 'react';
import { Grid, Button, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import SemanticRule from './SemanticRule.jsx';
import { isRuleGroup } from './QueryBuilderUtil.jsx';
import CombinatorSelector from './Widgets/CombinatorSelector.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const SemanticRuleGroup = (props) => {
    const { id, parentId, rules, connector, not, builderConfig, className, theme, defaultFieldType } = props;
    const { classes, onAddRule, onAddRuleGroup, onGroupRemove, onPropertyChange } = builderConfig;
    const hasParentGroup = () => Boolean(parentId);
    const onCombinatorChange = (value) => {
        onPropertyChange('connector', value, id);
    };

    const onNotToggleChange = (checked) => {
        onPropertyChange('not', checked, id);
    };

    const addRule = () => {
        onAddRule(id);
    };

    const addGroup = () => {
        onAddRuleGroup(id);
    };

    const removeGroup = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onGroupRemove(id, parentId);
    };

    return (
        <Grid container className={classNames(classes.group, className)}>
            <Grid container justify="space-between" alignItems="center" wrap="nowrap" className={classes.groupHeader}>
                <Grid item>
                    <CombinatorSelector
                        selectedConnector={connector}
                        not={not}
                        disabled={rules.length <= 1}
                        onNotToggleChange={() => onNotToggleChange(!not)}
                        onChange={(value) => onCombinatorChange(value)}
                    />
                </Grid>
                <Grid item>
                    <Button className={classes.btnAction} onClick={addRule}>
                        {'Add Rule'}
                    </Button>
                    <Button className={classes.btnAction} onClick={addGroup} style={{ marginRight: 10 }}>
                        {'Add Group'}
                    </Button>
                    {
                        hasParentGroup() &&
                        <ToolTipComponent title="Remove Group" arrow>
                            <IconButton className={classes.actionIconButton} onClick={(event) => removeGroup(event)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.408" height="16" viewBox="0 0 19.408 22">
                                    <g transform="translate(-76.035 -52)">
                                        <path d="M128.865,166H114.641a.549.549,0,0,0-.536.61l1.459,12.768a2.789,2.789,0,0,0,2.77,2.474h7.082a2.777,2.777,0,0,0,2.775-2.525L129.4,166.6a.554.554,0,0,0-.138-.421A.546.546,0,0,0,128.865,166Zm-1.748,13.219a1.708,1.708,0,0,1-1.7,1.555h-7.082a1.715,1.715,0,0,1-1.7-1.523l-1.39-12.173h13.028Z" transform="translate(-36.014 -107.853)" fill={theme.palette.primary.main} />
                                        <path d="M94.9,54.7H89.19v-1.03A1.682,1.682,0,0,0,87.51,52H83.968a1.682,1.682,0,0,0-1.68,1.667V54.7H76.574a.539.539,0,0,0,0,1.078H94.9a.539.539,0,0,0,0-1.078Zm-6.792,0H83.366v-1.03a.6.6,0,0,1,.6-.588H87.51a.6.6,0,0,1,.6.588Z" fill={theme.palette.primary.main} />
                                    </g>
                                </svg>
                            </IconButton>
                        </ToolTipComponent>
                    }
                </Grid>
            </Grid>
            <Grid className={classes.groupChildren}>
                {
                    rules.map((rule, index) => {
                        return (
                            <Fragment key={index}>
                                {
                                    isRuleGroup(rule)
                                        ? <SemanticRuleGroup id={rule.id}
                                            parentId={id}
                                            rules={[...rule.rules]}
                                            connector={rule.connector}
                                            not={rule.not}
                                            builderConfig={builderConfig}
                                            theme={theme}
                                            defaultFieldType={defaultFieldType} />
                                        : <SemanticRule id={rule.id}
                                            parentId={id}
                                            rule={rule}
                                            builderConfig={builderConfig}
                                            theme={theme}
                                            defaultFieldType={defaultFieldType} />
                                }
                            </Fragment>
                        );
                    })
                }
            </Grid>
        </Grid >
    );
};

SemanticRuleGroup.propTypes = {
    classes: PropTypes.object,
    rules: PropTypes.array,
    id: PropTypes.string,
    parentId: PropTypes.string,
    connector: PropTypes.string,
    not: PropTypes.bool,
    className: PropTypes.string,
    builderConfig: PropTypes.object,
    theme: PropTypes.object,
    defaultFieldType: PropTypes.string
};

export default SemanticRuleGroup;