import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import ConnectPointsWrapper from './ConnectPointsWrapper.jsx';


const ConnectionBox = (props) => {
    const { handler, addArrow, boxId, draggable, joinDisabled } = props;
    const ref0 = useRef();
    return (
        <div
            style={{ position: 'relative' }}
            id={boxId}
            ref={ref0}
            onDragOver={(event) => event.preventDefault()}
            onDrop={
                (event) => {
                    if (event.dataTransfer.getData("arrow") !== boxId) {
                        const refs = { start: event.dataTransfer.getData("arrow"), end: boxId };
                        addArrow(refs);
                    }
                }
            }
        >
            {props.children}
            <ConnectPointsWrapper {...{ boxId, handler, ref0, draggable, joinDisabled }} />
        </div>
    );
};

ConnectionBox.propTypes = {
    boxId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    handler: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    ref0: PropTypes.element,
    addArrow: PropTypes.func,
    children: PropTypes.element,
    draggable: PropTypes.bool,
    joinDisabled: PropTypes.bool
};

export default ConnectionBox;