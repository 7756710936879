import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Grid, withStyles, Typography } from '@material-ui/core';

import SensitivityChart from '../Charts/SensitivityChart.jsx';
import DataQualityChart from '../Charts/DataQualityChart.jsx';
import Styles from '../../layouts/Styles.jsx';
import DatasourceMetricsStyles from './DatasourceMetricsStyles.jsx';
import TimelineChart from '../Charts/TimelineChart.jsx';
import { getDatasourceTimeline, getSourceSensitivity, getMetricDataQuality } from '../../actions/scheduleActions';
import { getDatasetNames } from '../../actions/datasetActions.js';


const DatasourceMetrics = (props) => {
    const { datasourceId, datasetInfo, datasourceName, classes, dq_score_threshold } = props;
    const [timelineData, setTimeLineData] = useState([]);
    const [sensitivityData, setSensitivityData] = useState([]);
    const [dataQualityData, setDataQualityData] = useState({});
    const [qualityLinearData, setQualityLinearData] = useState([]);
    const [datasets, setDatasets] = useState([]);
    const dispatch = useDispatch();

    const getTimeLine = useCallback(() => {
        dispatch(getDatasourceTimeline(datasourceId)).then((response) => {
            if (response) {
                setTimeLineData([...response]);
            }
        });
    }, [datasourceId, dispatch]);


    const datasetNames = useCallback(() => {
        dispatch(getDatasetNames(datasourceId)).then((response) => {
            if (response) {
                const datasets = response.map((dataset) => { return { name: dataset.name, value: dataset.id }; });
                if (datasets.length) {
                    datasets.unshift({
                        name: 'All', value: 'All'
                    });
                }
                setDatasets([...datasets]);
            }
        });
    }, [datasourceId, dispatch]);

    const getSensitivity = useCallback(() => {
        dispatch(getSourceSensitivity(datasourceId)).then((response) => {
            if (response) {
                setSensitivityData([...response.list]);
            }
        });
    }, [datasourceId, dispatch]);

    const getDataQuality = useCallback(() => {
        dispatch(getMetricDataQuality(datasourceId)).then((response) => {
            if (response) {
                response = response.map((data) => {
                    return {
                        name: data.name,
                        attributes: data.attributes.sort((a, b) => b.dqscore - a.dqscore)
                    };
                });

                setQualityLinearData([...response]);
                const qualityData = { name: '', isDatasource: true, dqscore: 0, children: [] };
                for (let index = 0; index < response.length; index++) {
                    const dataset = { name: response[index].name, isDataset: true, isDrillDown: false, node: index, children: [], dqscore: 0 };
                    for (const attribute of response[index].attributes) {
                        dataset.children.push({
                            ...attribute,
                            node: index,
                            isAttribute: true,
                            isDrillDown: false
                        });
                    }
                    qualityData.children.push(dataset);
                }
                setDataQualityData({ ...qualityData });
            }
        });
    }, [datasourceId, dispatch]);

    useEffect(() => {
        getTimeLine();
        datasetNames();
        getSensitivity();
        getDataQuality();
    }, [getTimeLine, datasourceId, datasetNames, getSensitivity, getDataQuality]);

    return (
        <Grid className={classes.metricContiner}>
            <Grid container className={classes.metricsCountContainer} justify="space-between">
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item className={classNames(classes.metricsTableIconBg, classes.metricTabIcon1)}>
                            <svg style={{ marginTop: '8px' }} xmlns="http://www.w3.org/2000/svg" width="22.114" height="18.712" viewBox="0 0 22.114 18.712" className={classes.metricsTableIcon}>
                                <path className="a" d="M21.489,37.17a2.047,2.047,0,0,0-1.5-.625H2.126a2.048,2.048,0,0,0-1.5.625A2.048,2.048,0,0,0,0,38.671V53.13a2.048,2.048,0,0,0,.625,1.5,2.047,2.047,0,0,0,1.5.624H19.987a2.132,2.132,0,0,0,2.126-2.126V38.671A2.048,2.048,0,0,0,21.489,37.17ZM6.8,53.13a.409.409,0,0,1-.425.425H2.126A.409.409,0,0,1,1.7,53.13V50.578a.41.41,0,0,1,.425-.425H6.379a.41.41,0,0,1,.425.425V53.13Zm0-5.1a.41.41,0,0,1-.425.425H2.126a.409.409,0,0,1-.425-.425V45.475a.409.409,0,0,1,.425-.425H6.379a.409.409,0,0,1,.425.425v2.552Zm0-5.1a.41.41,0,0,1-.425.425H2.126a.409.409,0,0,1-.425-.425V40.372a.409.409,0,0,1,.425-.425H6.379a.409.409,0,0,1,.425.425v2.552Zm6.8,10.206a.408.408,0,0,1-.425.425H8.931a.409.409,0,0,1-.425-.425V50.578a.41.41,0,0,1,.425-.425h4.253a.409.409,0,0,1,.425.425V53.13h0Zm0-5.1a.409.409,0,0,1-.425.425H8.931a.409.409,0,0,1-.425-.425V45.475a.409.409,0,0,1,.425-.425h4.253a.408.408,0,0,1,.425.425v2.552Zm0-5.1a.409.409,0,0,1-.425.425H8.931a.409.409,0,0,1-.425-.425V40.372a.409.409,0,0,1,.425-.425h4.253a.409.409,0,0,1,.425.425v2.552Zm6.8,10.206a.408.408,0,0,1-.425.425H15.735a.408.408,0,0,1-.425-.425V50.578a.409.409,0,0,1,.425-.425h4.252a.409.409,0,0,1,.425.425Zm0-5.1a.409.409,0,0,1-.425.425H15.735a.409.409,0,0,1-.425-.425V45.475a.408.408,0,0,1,.425-.425h4.252a.409.409,0,0,1,.425.425Zm0-5.1a.409.409,0,0,1-.425.425H15.735a.409.409,0,0,1-.425-.425V40.372a.409.409,0,0,1,.425-.425h4.252a.409.409,0,0,1,.425.425Z" transform="translate(0 -36.545)" />
                            </svg>
                        </Grid>
                        <Grid item className={classes.metricsTableText}>
                            <Typography variant={"h3"}>
                                {datasetInfo ? datasetInfo.count : 0}
                            </Typography>
                            <Typography variant={"body1"} className={classes.metricCountText}>
                                {"Total Datasets"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item className={classNames(classes.metricsTableIconBg, classes.metricTabIcon2)}>
                            <svg style={{ marginTop: '8px' }} xmlns="http://www.w3.org/2000/svg" width="22.114" height="18.712" viewBox="0 0 22.114 18.712" className={classes.metricsTableIcon}>
                                <path className="a" d="M21.489,37.17a2.047,2.047,0,0,0-1.5-.625H2.126a2.048,2.048,0,0,0-1.5.625A2.048,2.048,0,0,0,0,38.671V53.13a2.048,2.048,0,0,0,.625,1.5,2.047,2.047,0,0,0,1.5.624H19.987a2.132,2.132,0,0,0,2.126-2.126V38.671A2.048,2.048,0,0,0,21.489,37.17ZM6.8,53.13a.409.409,0,0,1-.425.425H2.126A.409.409,0,0,1,1.7,53.13V50.578a.41.41,0,0,1,.425-.425H6.379a.41.41,0,0,1,.425.425V53.13Zm0-5.1a.41.41,0,0,1-.425.425H2.126a.409.409,0,0,1-.425-.425V45.475a.409.409,0,0,1,.425-.425H6.379a.409.409,0,0,1,.425.425v2.552Zm0-5.1a.41.41,0,0,1-.425.425H2.126a.409.409,0,0,1-.425-.425V40.372a.409.409,0,0,1,.425-.425H6.379a.409.409,0,0,1,.425.425v2.552Zm6.8,10.206a.408.408,0,0,1-.425.425H8.931a.409.409,0,0,1-.425-.425V50.578a.41.41,0,0,1,.425-.425h4.253a.409.409,0,0,1,.425.425V53.13h0Zm0-5.1a.409.409,0,0,1-.425.425H8.931a.409.409,0,0,1-.425-.425V45.475a.409.409,0,0,1,.425-.425h4.253a.408.408,0,0,1,.425.425v2.552Zm0-5.1a.409.409,0,0,1-.425.425H8.931a.409.409,0,0,1-.425-.425V40.372a.409.409,0,0,1,.425-.425h4.253a.409.409,0,0,1,.425.425v2.552Zm6.8,10.206a.408.408,0,0,1-.425.425H15.735a.408.408,0,0,1-.425-.425V50.578a.409.409,0,0,1,.425-.425h4.252a.409.409,0,0,1,.425.425Zm0-5.1a.409.409,0,0,1-.425.425H15.735a.409.409,0,0,1-.425-.425V45.475a.408.408,0,0,1,.425-.425h4.252a.409.409,0,0,1,.425.425Zm0-5.1a.409.409,0,0,1-.425.425H15.735a.409.409,0,0,1-.425-.425V40.372a.409.409,0,0,1,.425-.425h4.252a.409.409,0,0,1,.425.425Z" transform="translate(0 -36.545)" />
                            </svg>
                        </Grid>
                        <Grid item className={classes.metricsTableText}>
                            <Typography variant={"h3"}>
                                {datasetInfo ? datasetInfo.total_records : 0}
                            </Typography>
                            <Typography variant={"body1"} className={classes.metricCountText}>
                                {"Total Records"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item className={classNames(classes.metricsTableIconBg, classes.metricTabIcon3)}>
                            <svg style={{ marginTop: '8px' }} xmlns="http://www.w3.org/2000/svg" width="22.114" height="18.712" viewBox="0 0 22.114 18.712" className={classes.metricsTableIcon}>
                                <path className="a" d="M21.489,37.17a2.047,2.047,0,0,0-1.5-.625H2.126a2.048,2.048,0,0,0-1.5.625A2.048,2.048,0,0,0,0,38.671V53.13a2.048,2.048,0,0,0,.625,1.5,2.047,2.047,0,0,0,1.5.624H19.987a2.132,2.132,0,0,0,2.126-2.126V38.671A2.048,2.048,0,0,0,21.489,37.17ZM6.8,53.13a.409.409,0,0,1-.425.425H2.126A.409.409,0,0,1,1.7,53.13V50.578a.41.41,0,0,1,.425-.425H6.379a.41.41,0,0,1,.425.425V53.13Zm0-5.1a.41.41,0,0,1-.425.425H2.126a.409.409,0,0,1-.425-.425V45.475a.409.409,0,0,1,.425-.425H6.379a.409.409,0,0,1,.425.425v2.552Zm0-5.1a.41.41,0,0,1-.425.425H2.126a.409.409,0,0,1-.425-.425V40.372a.409.409,0,0,1,.425-.425H6.379a.409.409,0,0,1,.425.425v2.552Zm6.8,10.206a.408.408,0,0,1-.425.425H8.931a.409.409,0,0,1-.425-.425V50.578a.41.41,0,0,1,.425-.425h4.253a.409.409,0,0,1,.425.425V53.13h0Zm0-5.1a.409.409,0,0,1-.425.425H8.931a.409.409,0,0,1-.425-.425V45.475a.409.409,0,0,1,.425-.425h4.253a.408.408,0,0,1,.425.425v2.552Zm0-5.1a.409.409,0,0,1-.425.425H8.931a.409.409,0,0,1-.425-.425V40.372a.409.409,0,0,1,.425-.425h4.253a.409.409,0,0,1,.425.425v2.552Zm6.8,10.206a.408.408,0,0,1-.425.425H15.735a.408.408,0,0,1-.425-.425V50.578a.409.409,0,0,1,.425-.425h4.252a.409.409,0,0,1,.425.425Zm0-5.1a.409.409,0,0,1-.425.425H15.735a.409.409,0,0,1-.425-.425V45.475a.408.408,0,0,1,.425-.425h4.252a.409.409,0,0,1,.425.425Zm0-5.1a.409.409,0,0,1-.425.425H15.735a.409.409,0,0,1-.425-.425V40.372a.409.409,0,0,1,.425-.425h4.252a.409.409,0,0,1,.425.425Z" transform="translate(0 -36.545)" />
                            </svg>
                        </Grid>
                        <Grid item className={classes.metricsTableText}>
                            <Typography variant={"h3"}>
                                {datasetInfo?.total_attributes ?? 0}
                            </Typography>
                            <Typography variant={"body1"} className={classes.metricCountText}>
                                {"Total Attributes"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container alignItems="center">
                        <Grid item className={classNames(classes.metricsTableIconBg, classes.metricTabIcon4)}>
                            <svg style={{ marginTop: '8px' }} xmlns="http://www.w3.org/2000/svg" width="22.114" height="18.712" viewBox="0 0 22.114 18.712" className={classes.metricsTableIcon}>
                                <path className="a" d="M21.489,37.17a2.047,2.047,0,0,0-1.5-.625H2.126a2.048,2.048,0,0,0-1.5.625A2.048,2.048,0,0,0,0,38.671V53.13a2.048,2.048,0,0,0,.625,1.5,2.047,2.047,0,0,0,1.5.624H19.987a2.132,2.132,0,0,0,2.126-2.126V38.671A2.048,2.048,0,0,0,21.489,37.17ZM6.8,53.13a.409.409,0,0,1-.425.425H2.126A.409.409,0,0,1,1.7,53.13V50.578a.41.41,0,0,1,.425-.425H6.379a.41.41,0,0,1,.425.425V53.13Zm0-5.1a.41.41,0,0,1-.425.425H2.126a.409.409,0,0,1-.425-.425V45.475a.409.409,0,0,1,.425-.425H6.379a.409.409,0,0,1,.425.425v2.552Zm0-5.1a.41.41,0,0,1-.425.425H2.126a.409.409,0,0,1-.425-.425V40.372a.409.409,0,0,1,.425-.425H6.379a.409.409,0,0,1,.425.425v2.552Zm6.8,10.206a.408.408,0,0,1-.425.425H8.931a.409.409,0,0,1-.425-.425V50.578a.41.41,0,0,1,.425-.425h4.253a.409.409,0,0,1,.425.425V53.13h0Zm0-5.1a.409.409,0,0,1-.425.425H8.931a.409.409,0,0,1-.425-.425V45.475a.409.409,0,0,1,.425-.425h4.253a.408.408,0,0,1,.425.425v2.552Zm0-5.1a.409.409,0,0,1-.425.425H8.931a.409.409,0,0,1-.425-.425V40.372a.409.409,0,0,1,.425-.425h4.253a.409.409,0,0,1,.425.425v2.552Zm6.8,10.206a.408.408,0,0,1-.425.425H15.735a.408.408,0,0,1-.425-.425V50.578a.409.409,0,0,1,.425-.425h4.252a.409.409,0,0,1,.425.425Zm0-5.1a.409.409,0,0,1-.425.425H15.735a.409.409,0,0,1-.425-.425V45.475a.408.408,0,0,1,.425-.425h4.252a.409.409,0,0,1,.425.425Zm0-5.1a.409.409,0,0,1-.425.425H15.735a.409.409,0,0,1-.425-.425V40.372a.409.409,0,0,1,.425-.425h4.252a.409.409,0,0,1,.425.425Z" transform="translate(0 -36.545)" />
                            </svg>
                        </Grid>
                        <Grid item className={classes.metricsTableText}>
                            <Typography variant={"h3"}>
                                {datasetInfo?.users_count ?? 0}
                            </Typography>
                            <Typography variant={"body1"} className={classes.metricCountText}>
                                {"Users"}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {
                    (timelineData && timelineData.length !== 0) &&
                    <Grid item xs={12}>
                        <TimelineChart datasets={datasets} profileData={timelineData} />
                    </Grid>
                }
                {
                    (dataQualityData && Object.keys(dataQualityData).length !== 0 && (dataQualityData.children && dataQualityData.children.length !== 0)) &&
                    <Grid item xs={12}>
                        <DataQualityChart linearData={qualityLinearData} chartQualityData={dataQualityData} datasourceId={datasourceId} dqScoreThreshold={dq_score_threshold} />
                    </Grid>
                }
                {
                    (sensitivityData && sensitivityData.length !== 0) &&
                    <Grid item xs={12}>
                        <SensitivityChart datasourceName={datasourceName} chartData={sensitivityData} />
                    </Grid>
                }
                {/* <Grid item xs={12}>
                    <DatasourceMetricCorrelation datasetList={datasets} />
                </Grid> */}

            </Grid>
        </Grid>
    );
};


DatasourceMetrics.propTypes = {
    datasourceId: PropTypes.string,
    datasourceName: PropTypes.string,
    classes: PropTypes.object,
    datasetInfo: PropTypes.object,
    dq_score_threshold: PropTypes.object
};

export default withStyles((theme) => ({
    ...DatasourceMetricsStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DatasourceMetrics);