import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardActionArea, Typography, withStyles, Grid } from '@material-ui/core';
import classNames from 'classnames';
import DataSourceTileStyles from './DataSourceTileStyles.jsx';

import datasourceTypes from '../../config/datasourceTypes';
import Filetype from '../../assets/images/logo-sql-server.png';

const DataSourceTile = (props) => {
    const { classes, datasource, onClick } = props;

    const getIcon = (sourceType) => {
        let icon;
        if (sourceType) {
            icon = datasourceTypes.find((p) => p.type.toLowerCase() === sourceType.toLowerCase());
        }
        if (icon && icon.logo && typeof (icon.logo) === 'object') {
            return icon.logo.default;
        }
        return icon ? icon.logo : Filetype;
    };

    return (
        <Card className={classNames(classes.root, classes.tileCard)} onClick={() => onClick()}>
            <CardActionArea className={classes.actionContainer}>
                <Grid className={classes.logoContainer}>
                    <img className={classes.logo}
                        alt={datasource.name}
                        src={getIcon(datasource.type)} />
                </Grid>

                <CardContent className={classes.cardContent}>
                    <Typography variant="h4" align="center">
                        {datasource.name}
                    </Typography>
                    <Typography variant="body2" align="center" className={classes.subtitle}>
                        {datasource.description}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

DataSourceTile.propTypes = {
    classes: PropTypes.object,
    datasource: PropTypes.object,
    onClick: PropTypes.func
};

export default withStyles(DataSourceTileStyles)(DataSourceTile);