import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Table, TableRow, TableHead, TableCell, Typography, TableBody } from '@material-ui/core';
import PropertiesListViewStyles from './PropertiesListViewStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';
import DatasetStyles from '../../DatasetStyles.jsx';
import TileViewStyles from '../TileView/TileViewStyles.jsx';
import { useDispatch, useSelector } from 'react-redux';
import ChipInput from '../../../ChipInput/ChipInput.jsx';
import AutoComplete from '../../../AutoComplete/AutoComplete.jsx';
import RuleFilter from './RuleFilter.jsx';
import { updateListDatasetRules, updateListProperties, updateRuleAttributes } from '../../../../actions/datasetActions.js';
import ToolTipComponent from '../../../Tooltip/Tooltip.jsx';
import classNames from 'classnames';


const CuratePanel = (props) => {
    const { classes, properties, isEditable, updateProperties, isDeletePermission, isEditPermission } = props;
    const dispatch = useDispatch();
    const datasetId = useSelector(({ dataset }) => dataset.selectedDatasetId);
    const rules = useSelector(({ dataset }) => dataset.rules.curate);
    const curationRules = useSelector(({ datasource }) => datasource.curationRules);
    const references = useSelector(({ datasource }) => datasource.references);
    const [basicRules, setBasicRules] = useState({});
    const [advancedRules, setAdvancedRules] = useState({});
    const [reference, setReference] = useState({});
    const [anchroEl, setAnchroEl] = useState(null);
    const [filterRules, setFilterRules] = useState([]);
    const [selectedAttribute, setSelectedAttribute] = useState('');
    const [ruleType, setRuleType] = useState('basic');

    const updateRules = useCallback((type, attribute) => {
        const attributeProperty = properties[attribute];
        const rules = (type === 'basic') ? basicRules[attribute] : advancedRules[attribute];
        const rulesInput = {
            attributeId: attributeProperty.id,
            attribute,
            type: 'curate',
            'rule_type': type,
            'rules': [...rules]
        };
        dispatch(updateListDatasetRules(datasetId, rulesInput));
        dispatch(updateRuleAttributes(rulesInput));
    }, [basicRules, advancedRules, dispatch, datasetId, properties]);

    const updateAttributeProperties = useCallback((attributeValue, enumValue) => {
        const attribute = attributeValue.name;
        const attributeProperty = {
            id: attributeValue.id,
            attribute,
            property: 'enum',
            value: { ...enumValue }
        };
        updateProperties(attributeProperty);
        dispatch(updateListProperties(datasetId, attributeProperty));
    }, [datasetId, dispatch, updateProperties]);

    const addRule = useCallback((anchroEl, attributeValue, type, filterRules) => {
        const attribute = attributeValue.name;
        setSelectedAttribute(attribute);
        setFilterRules(filterRules);
        setAnchroEl(anchroEl);
        setRuleType(type);
    }, []);


    const resetRuleFilter = useCallback(() => {
        setSelectedAttribute('');
        setFilterRules([]);
        setAnchroEl(null);
        setRuleType('basic');
    }, []);

    const onSelectRule = useCallback((type, rule, index) => {
        if (type === 'basic') {
            basicRules[selectedAttribute].push({ ...rule });
            setBasicRules({ ...basicRules });
        }
        if (type === 'advanced') {
            advancedRules[selectedAttribute].push({ ...rule });
            setAdvancedRules({ ...advancedRules });
        }
        updateRules(type, selectedAttribute);
        resetRuleFilter();
    }, [advancedRules, basicRules, resetRuleFilter, selectedAttribute, updateRules]);

    const deleteBasicRule = useCallback((attributeValue, ruleIndex) => {
        const attribute = attributeValue.name;
        const attributeRules = basicRules[attribute];
        attributeRules.splice(ruleIndex, 1);
        basicRules[attribute] = [...attributeRules];
        setBasicRules({ ...basicRules });
        updateRules('basic', attribute);
    }, [basicRules, updateRules]);

    const deleteAdvanceRule = useCallback((attributeValue, ruleIndex) => {
        const attribute = attributeValue.name;
        const attributeRules = advancedRules[attribute];
        attributeRules.splice(ruleIndex, 1);
        advancedRules[attribute] = [...attributeRules];
        setAdvancedRules({ ...advancedRules });
        updateRules('advanced', attribute);
    }, [advancedRules, updateRules]);

    const updateReference = useCallback((attribute, property, value) => {
        const attributeName = attribute.name;
        const attributeReference = reference[attributeName];
        if (property === 'name') {
            attributeReference.refId = value && value.id ? value.id : '';
            attributeReference.name = value && value.name ? value.name : '';
            attributeReference.attribute = '';
            attributeReference.resultantAttribute = '';
        }
        else {
            if (property === 'attribute') {
                attributeReference.resultantAttribute = '';
            }
            const attribute = value && value.attribute ? value.attribute : '';
            attributeReference[property] = attribute;
        }
        reference[attributeName] = { ...attributeReference };
        setReference({ ...reference });
        updateAttributeProperties(attribute, attributeReference);
    }, [reference, updateAttributeProperties]);

    const getBasicCurationRules = useCallback((attribute) => {
        const remainingRules = [];
        if (!curationRules || !(attribute in basicRules)) {
            return remainingRules;
        }
        const defaultBasicCurationRules = curationRules.filter((p) => p.rule_type === 'basic');
        for (const rule of defaultBasicCurationRules) {
            const hasRule = basicRules[attribute].find((p) => p.name.toLowerCase() === rule.name.toLowerCase());
            if (!hasRule) {
                remainingRules.push(rule);
            }
        }
        return remainingRules;
    }, [curationRules, basicRules]);


    const getAdvancedCurationRules = useCallback((attribute) => {
        const remainingRules = [];
        if (!curationRules || !(attribute in advancedRules)) {
            return remainingRules;
        }
        const defaultAdvancedCurationRules = curationRules.filter((p) => p.rule_type === 'advanced');
        for (const rule of defaultAdvancedCurationRules) {
            const hasRule = advancedRules[attribute].find((p) => p.name.toLowerCase() === rule.name.toLowerCase());
            if (!hasRule) {
                remainingRules.push(rule);
            }
        }
        return remainingRules;
    }, [curationRules, advancedRules]);

    const getReferenceAttributes = useCallback((reference) => {
        let refAttributes = [];
        if (reference && reference.refId && references && references.length > 0) {
            const attributeReference = references.find((p) => p.id === reference.refId);
            if (attributeReference && attributeReference.attributes && attributeReference.attributes.length > 0) {
                refAttributes = [...attributeReference.attributes];
            }
        }
        return refAttributes;
    }, [references]);


    const getResultantAttributes = useCallback((reference) => {
        let resultantAttributes = [];
        if (reference && reference.attribute && references && references.length > 0) {
            const attributeReference = references.find((p) => p.id === reference.refId);
            if (attributeReference && attributeReference.attributes && attributeReference.attributes.length > 0) {
                resultantAttributes = attributeReference.attributes.filter((p) => p.attribute.toLowerCase() !== reference.attribute.toLowerCase());
            }
        }
        return resultantAttributes;
    }, [references]);

    const loadReference = useCallback(() => {
        const attributes = Object.keys(properties);
        const datasetReference = {};
        for (const attributeName of attributes) {
            const attribute = properties[attributeName];
            const enumValue = attribute && attribute.enum ? attribute.enum : {};
            datasetReference[attributeName] = { ...enumValue };
        }
        setReference({ ...datasetReference });
    }, [properties]);


    const loadRules = useCallback((properties) => {
        const attributes = Object.keys(properties);
        const advancedRules = {};
        const basicRules = {};
        for (const attributeName of attributes) {
            const attribute = properties[attributeName];
            let attributeBasicRules = [];
            let attributeAdvancedRules = [];
            if (rules) {
                const attributeRules = rules[attribute.name];
                if (attributeRules) {
                    const rmDuplicates = attributeRules.filter((ele, ind) => ind === attributeRules.findIndex((elem) => elem.id === ele.id && elem.name === ele.name));
                    attributeBasicRules = rmDuplicates.filter((p, i) => p.type === 'curate' && p.rule_type === 'basic');
                    for (const rule of attributeBasicRules) {
                        rule.isSelected = true;
                    }
                    attributeAdvancedRules = rmDuplicates.filter((p) => p.type === 'curate' && p.rule_type === "advanced");
                    for (const rule of attributeAdvancedRules) {
                        rule.isSelected = true;
                    }
                }
            }
            attributeBasicRules = attributeBasicRules.sort((a, b) => b['rule_id'] - a['rule_id']);
            attributeAdvancedRules = attributeAdvancedRules.sort((a, b) => b['rule_id'] - a['rule_id']);
            basicRules[attributeName] = [...attributeBasicRules];
            advancedRules[attributeName] = [...attributeAdvancedRules];
        }
        setBasicRules({ ...basicRules });
        setAdvancedRules({ ...advancedRules });
    }, [rules]);

    useEffect(() => {
        if (!properties) {
            return;
        }
        loadRules(properties);
        loadReference();
    }, [loadReference, loadRules, properties, rules]);

    return (
        <Grid container direction="column">
            <Table stickyHeader className={classes.propertyListPageTable}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Attribute'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Basic'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Advanced'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Reference Dataset'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Lookup Attribute'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Resultant Attribute'}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.propListPageTableBody}>
                    {
                        Object.keys(properties).map((attribute, index) => {
                            const property = properties[attribute];
                            let attributeReference = {};
                            if (attribute in reference) {
                                attributeReference = reference[attribute];
                            }

                            const attributeBasicCurationRules = getBasicCurationRules(attribute);
                            const attributeAdvancedCurationRules = getAdvancedCurationRules(attribute);

                            const refAttributes = getReferenceAttributes(attributeReference);
                            const resultantAttributes = getResultantAttributes(attributeReference);

                            return (
                                <TableRow key={`attributeProperty${index}`}>
                                    <TableCell>
                                        <ToolTipComponent title={property && property.name ? property.name : ''} arrow placement="bottom-start">
                                            <Typography className={classes.curateAttributeTitle}>
                                                {property && property.name ? property.name : ''}
                                            </Typography>
                                        </ToolTipComponent>
                                    </TableCell>
                                    <TableCell className={classNames(classes.tableChips, classes.curatePanelChipContainer)}>
                                        <ChipInput
                                            addtooltiptitle="Add"
                                            values={attribute in basicRules ? basicRules[attribute] : []}
                                            displayName="name"
                                            displayCount={1}
                                            name="basic"
                                            isEditable={isEditable}
                                            isEditPermission={isEditPermission}
                                            enableAddButton={attributeBasicCurationRules.length > 0 && (isEditable)}
                                            onClickAdd={(event) => addRule(event.target, property, 'basic', attributeBasicCurationRules)}
                                            onDelete={(index) => deleteBasicRule(property, index)}
                                            isDeletePermission={isDeletePermission}
                                        />
                                    </TableCell>
                                    <TableCell className={classNames(classes.tableChips, classes.curatePanelChipContainer)}>
                                        <ChipInput
                                            addtooltiptitle="Add"
                                            values={attribute in advancedRules ? advancedRules[attribute] : []}
                                            displayName="name"
                                            displayCount={1}
                                            name="advanced"
                                            isEditable={isEditable || isEditPermission}
                                            enableAddButton={attributeAdvancedCurationRules.length > 0 && (isEditable)}
                                            onClickAdd={(event) => addRule(event.target, property, 'advanced', attributeAdvancedCurationRules)}
                                            onDelete={(index) => deleteAdvanceRule(property, index)}
                                            isDeletePermission={isDeletePermission}
                                        />
                                    </TableCell>
                                    <ToolTipComponent title={attributeReference.name ? attributeReference.name : ''} arrow>
                                        <TableCell className={classes.hoverBgAutoComplete}>
                                            <AutoComplete
                                                placeholder="Dataset"
                                                value={attributeReference.name ? attributeReference.name : ''}
                                                noDropPostion={1}
                                                options={references}
                                                selectedValuePath="name"
                                                displayMemberPath="name"
                                                name="Dataset"
                                                onChange={(event) => updateReference(property, 'name', event.target.selectedItem)}
                                                disabled={!isEditable && !isEditPermission} />
                                        </TableCell>
                                    </ToolTipComponent>

                                    <ToolTipComponent title={attributeReference.attribute ? attributeReference.attribute : ''} arrow>
                                        <TableCell className={classes.hoverBgAutoComplete}>
                                            <AutoComplete
                                                placeholder="Attribute"
                                                value={attributeReference.attribute ? attributeReference.attribute : ''}
                                                noDropPostion={1}
                                                options={[...refAttributes]}
                                                selectedValuePath="attribute"
                                                displayMemberPath="attribute"
                                                disabled={(reference.refId <= 0 || (!isEditable && !isEditPermission))}
                                                name="ref_attribute"
                                                onChange={(event) => updateReference(property, 'attribute', event.target.selectedItem)} />
                                        </TableCell>
                                    </ToolTipComponent>

                                    <ToolTipComponent title={attributeReference.resultantAttribute ? attributeReference.resultantAttribute : ''} arrow>
                                        <TableCell className={classes.hoverBgAutoComplete}>
                                            <AutoComplete
                                                placeholder="Resultant Attribute"
                                                value={attributeReference.resultantAttribute ? attributeReference.resultantAttribute : ''}
                                                noDropPostion={1}
                                                options={[...resultantAttributes]}
                                                selectedValuePath="attribute"
                                                displayMemberPath="attribute"
                                                disabled={(resultantAttributes.length <= 0 || (!isEditable && !isEditPermission))}
                                                name="resultant_attribute"
                                                onChange={(event) => updateReference(property, 'resultantAttribute', event.target.selectedItem)} />
                                        </TableCell>
                                    </ToolTipComponent>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>

            <RuleFilter anchroEl={anchroEl} rules={filterRules} type={ruleType} onSelectionChange={(type, rule, index) => onSelectRule(type, rule, index)} onClose={() => resetRuleFilter()} />
        </Grid>
    );
};

CuratePanel.propTypes = {
    classes: PropTypes.object,
    properties: PropTypes.object,
    isEditable: PropTypes.bool,
    updateProperties: PropTypes.func,
    isDeletePermission: PropTypes.bool,
    isEditPermission: PropTypes.bool
};

export default withStyles((theme) => ({
    ...PropertiesListViewStyles(theme),
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}))(CuratePanel);