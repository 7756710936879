import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, withStyles, Typography, ButtonGroup, Button, FormControl, MenuItem } from '@material-ui/core';
import DashboardStyles from './DashboardStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import RelationShipChart from '../Charts/RelationShipChart.jsx';
import { getRelationShipAnalysis } from '../../actions/scheduleActions';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import HeatMapChart from '../Charts/HeatMapChart.jsx';
import Loader from '../../components/Loaders/Loader.jsx';
import TextBox from '../TextBox/TextBox.jsx';


const DashboardRelationShip = (props) => {
    const datatype = useSelector(({ datasource }) => datasource.datatypes);
    const fieldType = useSelector(({ datasource }) => datasource.fieldTypes);
    const settingConfig = useSelector(({ setting }) => setting.config);
    let datasourceTypesList = useSelector(({ datasource }) => datasource.connectionTypes);
    datasourceTypesList = datasourceTypesList.filter((data) => settingConfig.input_connectors.includes(data.id));
    const { classes } = props;
    const dispatch = useDispatch();
    const [type, setType] = useState('bubble');
    const [analysis, setAnalysis] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [filter, setFilter] = useState({
        type: 'Datasource Type',
        name: 'type'
    });

    const handleChange = (event) => {
        setLoading(true);
        const name = event.target.name;
        setFilter({
            ...filter,
            [name]: event.target.value
        });
    };

    let datatypes = [];

    if (filter.type === "Datasource Type") {
        datatypes = datasourceTypesList.map((items) => items.name);
    }
    else if (filter.type === "Field Type") {
        datatypes = fieldType.map((items) => items.name);
    }
    else if (filter.type === "Data Type") {
        datatypes = datatype.map((items) => items.name);
    }
    else if (filter.type === "Sensitivity Level") {
        datatypes = [1, 2, 3];
    }

    const getAnalysis = useCallback(() => {
        const requestParams = {
            type: type,
            filter: filter.type
        };
        dispatch(getRelationShipAnalysis(requestParams)).then((response) => {
            if (response) {
                const analysisData = response.map((data) => {
                    return {
                        count: data.count,
                        type: data.type,
                        name: data.first_name !== "" ? `${data.first_name} ${data.last_name}` : data.email
                    };
                });
                setAnalysis([...analysisData]);
                setLoading(false);
            }
        });
    }, [dispatch, type, filter.type]);

    useEffect(() => {
        getAnalysis();
    }, [getAnalysis]);

    return (
        <Grid className={classes.section}>
            <Grid container alignItems="center" justify="space-between">
                <Grid item>
                    <Typography variant="h4" component="h4">
                        Relationship
                    </Typography>
                </Grid>
                <Grid item className={classes.relationHeaderSection}>
                    <ButtonGroup className={classNames(classes.buttonGroup, classes.iconPadding)}>
                        <Button variant="contained"
                            className={type === "bubble" ? 'active' : ''}
                            onClick={
                                () => {
                                    setType('bubble');
                                    setLoading(true);
                                }
                            }>
                            <TooltipComponent title="Bubble represents count" placement="bottom" arrow>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 21 21">
                                    <g id="Group_3937" data-name="Group 3937" transform="translate(-1209 -1155)">
                                        <circle id="Ellipse_1026" data-name="Ellipse 1026" cx="4" cy="4" r="4" transform="translate(1214 1155)" fill="#e1e5e6" />
                                        <circle id="Ellipse_1027" data-name="Ellipse 1027" cx="2.5" cy="2.5" r="2.5" transform="translate(1209 1164)" fill="#e1e5e6" />
                                        <circle id="Ellipse_1028" data-name="Ellipse 1028" cx="3" cy="3" r="3" transform="translate(1214 1170)" fill="#e1e5e6" />
                                        <circle id="Ellipse_1029" data-name="Ellipse 1029" cx="3" cy="3" r="3" transform="translate(1224 1170)" fill="#e1e5e6" />
                                    </g>
                                </svg>
                            </TooltipComponent>
                        </Button>
                        <Button variant="contained"
                            className={type === "heat" ? 'active' : ''}
                            onClick={
                                () => {
                                    setType('heat');
                                    setLoading(true);
                                }
                            }>
                            <TooltipComponent title="Heat represents count" placement="bottom" arrow>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 21 21">
                                    <g id="Group_3938" data-name="Group 3938" transform="translate(-1246 -1155)">
                                        <g id="Rectangle_993" data-name="Rectangle 993" transform="translate(1247 1156)" fill="#e1e5e6" stroke="#afb2b3" strokeWidth="1">
                                            <rect width="7" height="7" stroke="none" />
                                            <rect x="0.5" y="0.5" width="6" height="6" fill="none" />
                                        </g>
                                        <g id="Rectangle_996" data-name="Rectangle 996" transform="translate(1247 1162)" fill="#fff" stroke="#afb2b3" strokeWidth="1">
                                            <rect width="7" height="7" stroke="none" />
                                            <rect x="0.5" y="0.5" width="6" height="6" fill="none" />
                                        </g>
                                        <g id="Rectangle_1038" data-name="Rectangle 1038" transform="translate(1247 1167)" fill="#fff" stroke="#afb2b3" strokeWidth="1">
                                            <rect width="7" height="7" stroke="none" />
                                            <rect x="0.5" y="0.5" width="6" height="6" fill="none" />
                                        </g>
                                        <g id="Rectangle_994" data-name="Rectangle 994" transform="translate(1253 1156)" fill="#fff" stroke="#afb2b3" strokeWidth="1">
                                            <rect width="7" height="7" stroke="none" />
                                            <rect x="0.5" y="0.5" width="6" height="6" fill="none" />
                                        </g>
                                        <g id="Rectangle_997" data-name="Rectangle 997" transform="translate(1253 1162)" fill="#c8cbcc" stroke="#afb2b3" strokeWidth="1">
                                            <rect width="7" height="7" stroke="none" />
                                            <rect x="0.5" y="0.5" width="6" height="6" fill="none" />
                                        </g>
                                        <g id="Rectangle_1037" data-name="Rectangle 1037" transform="translate(1253 1167)" fill="#e1e5e6" stroke="#afb2b3" strokeWidth="1">
                                            <rect width="7" height="7" stroke="none" />
                                            <rect x="0.5" y="0.5" width="6" height="6" fill="none" />
                                        </g>
                                        <g id="Rectangle_995" data-name="Rectangle 995" transform="translate(1259 1156)" fill="#e1e5e6" stroke="#afb2b3" strokeWidth="1">
                                            <rect width="7" height="7" stroke="none" />
                                            <rect x="0.5" y="0.5" width="6" height="6" fill="none" />
                                        </g>
                                        <g id="Rectangle_998" data-name="Rectangle 998" transform="translate(1259 1162)" fill="#fff" stroke="#afb2b3" strokeWidth="1">
                                            <rect width="7" height="7" stroke="none" />
                                            <rect x="0.5" y="0.5" width="6" height="6" fill="none" />
                                        </g>
                                        <g id="Rectangle_1039" data-name="Rectangle 1039" transform="translate(1259 1167)" fill="#f6f8f9" stroke="#afb2b3" strokeWidth="1">
                                            <rect width="7" height="7" stroke="none" />
                                            <rect x="0.5" y="0.5" width="6" height="6" fill="none" />
                                        </g>
                                    </g>
                                </svg>
                            </TooltipComponent>
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <Grid style={{ width: '100%', margin: '0 auto' }}>
                <Grid className={classes.relationshipTypeSection}>
                    <FormControl>
                        <TextBox
                            className={classes.inlinetxt}
                            onChange={(event) => handleChange(event)}
                            value={filter.type}
                            select
                            name="type"
                            SelectProps={
                                {
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }
                                    // IconComponent: (iconProps) => DropDownIcon(iconProps)
                                }
                            }
                            fullWidth>
                            {
                                ["Data Type", "Datasource Type", "Field Type", "Sensitivity Level"].map((option, index) => (
                                    <MenuItem key={`menuProperty_Options_${index}`} value={option} className={classes.menuItem}>
                                        {option}
                                    </MenuItem>
                                ))
                            }
                        </TextBox>
                    </FormControl>
                </Grid>
                <Grid container className={classes.displayFlex}>
                    <Grid item className={classes.relationshipUserSection}>
                        <Typography>
                            User
                        </Typography>
                    </Grid>
                    <Grid item className={classNames(classes.relationChart, classes.relative)}>
                        {isLoading && <Loader />}
                        <Grid className={classes.relationChartSection}>
                            {
                                type === "bubble" ?
                                    <RelationShipChart datatypes={datatypes} chartData={analysis} filterValue={filter.type} /> :
                                    <HeatMapChart datatypes={datatypes} chartData={analysis} />
                            }
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        </Grid >
    );
};

DashboardRelationShip.propTypes = {
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DashboardRelationShip);