import React, { Component } from "react";
import PropTypes from "prop-types";
import { Grid, withStyles, Typography, IconButton } from "@material-ui/core";
import classNames from "classnames";
import DatasetProfileStyles from "./DatasetProfileStyles.jsx";
import Styles from "../../layouts/Styles.jsx";
import AttributeSelect from "../AttributeSelect/AttributeSelect.jsx";
// import DriftRulesStatistics from "../Charts/DriftRulesStatistics.jsx";
import Loader from "../Loaders/Loader.jsx";
import NoResultFound from "../NoResultFound/NoResultFound.jsx";
/*
 * import { getFieldType } from "../../helpers/appHelpers";
 * import DriftTrending from "../Drift/DriftTrending.jsx";
 * import DriftCountStatistics from "../Drift/DriftCountStatistics.jsx";
 * import DriftDescriptive from "../Drift/DriftDescriptive.jsx";
 * import DriftLengthStatistics from "../Drift/DriftLengthStatistics.jsx";
 * import DriftPattenStatistics from "../Drift/DriftPatternStatistics.jsx";
 * import DriftExtremeStatistics from "../Drift/DriftExtrmeStatistics.jsx";
 */
import DriftDashboard from "../Drift/DriftDashBoardStatistics.jsx";
import DriftRulesDetails from "../Drift/DriftRulesDetails.jsx";
import DriftDatasetDashboard from "../Drift/DriftDatasetDashBoard.jsx";
import DriftDatasetRulesDetails from "../Drift/DriftDatasetRulesDetails.jsx";
import drift from "../../assets/images/drift.svg";
import driftLow from "../../assets/images/drift_low.svg";
import driftMedium from "../../assets/images/drift_medium.svg";
import driftNone from "../../assets/images/drift_none.svg";

class DatasetLearning extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: [],
      isLoading: false,
      disableAttribute: false,
      driftOption: [{ name: "Attribute" }, { name: "Dataset" }],
      currentDriftOption: "Attribute"
    };
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    this.getAttributes();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.driftAttributes !== this.props.driftAttributes) {
      this.getAttributes();
    }
  }

  getAttributes() {
    let attributeId = 0;
    let scheduleId = 0;
    const isSelectedAttribute = this.props.driftAttributes.some((data) => data.name === this.props.selectedAttribute);
    const attributes = this.props.driftAttributes.map((attribute, index) => {
      if (index === 0) {
        attributeId = attribute.id ? attribute.id : 0;
      }
      if (index === 0) {
        scheduleId = attribute.schedule_id ? attribute.schedule_id : 0;
      }
      return {
        id: attribute.id ? attribute.id : 0,
        name: attribute.name ? attribute.name : "",
        isSelected: !isSelectedAttribute ? index === 0 : this.props.selectedAttribute === attribute.name,
        type: attribute.datatype ? attribute.datatype : "Text",
        sensitivity: attribute.sensitivity,
        fieldType: attribute.fieldtype ? attribute.fieldtype : "",
        status: attribute.drift_status ? attribute.drift_status : "low",
        runs: attribute.runs ? attribute.runs : 0,
        totalrecord: attribute.totalrecord ? attribute.totalrecord : 0,
        "schedule_id": attribute.schedule_id ? attribute.schedule_id : 0
      };
    });
    this.setState({ attributes: [...attributes] });
    if (attributes.length > 0) {
      const attributeName = attributes.find((attribute) => attribute.isSelected);
      this.props.setSelectedAttribute(attributeName ? attributeName.name : "");
    }
    this.setState({
      currentDriftOption: attributes.length === 0 ? "Dataset" : "Attribute"
    });
    this.getDriftRules(attributeId, scheduleId);
  }

  getDriftRules(attributeId, scheduleId) {
    this.props.getDriftRules(attributeId, scheduleId);
  }

  updatedAlertRule = (id) => {
    this.props.driftAcceptRules(id);
  };

  getTotalRecords = (rules) => {
    const rulesKey = Object.keys(rules);
    for (const rule of rulesKey) {
      if (rules[rule] && rules[rule] !== "Failed") {
        break;
      }
    }
  };

  getCurationAnalysis(attributeId) {
    this.props.getCurationAnalysis(attributeId).then((response) => {
      if (response) {
        this.setState({ curate: { ...response } });
        this.getTotalRecords(response);
      }
      this.setState({ isLoading: false });
    });
  }

  onChangeDatasetAttribute(event) {
    if (event.target.value === "Attribute") {
      this.setState({ currentDriftOption: event.target.value, disableAttribute: false });
    } else {
      this.setState({ currentDriftOption: event.target.value, disableAttribute: true });
    }
  }

  onChangeAttribute(event) {
    if (!event.target.value) {
      return;
    }
    let attributeId = 0;
    let scheduleId = 0;
    const attributes = this.state.attributes.map((attribute) => {
      const attributeItem = attribute;
      attributeItem.isSelected = false;
      if (attributeItem.name === event.target.value) {
        attributeItem.isSelected = true;
        attributeId = attributeItem.id;
        scheduleId = attributeItem.schedule_id;
      }
      return attributeItem;
    });

    this.props.setSelectedAttribute(event.target.value);
    this.setState({ attributes: [...attributes] });
    this.getDriftRules(attributeId, scheduleId);
  }

  navigateChart = () => {
    if (this.props.selectedRule && this.props.selectedRule !== "") {
      setTimeout(() => {
        const element = document.getElementById(this.props.selectedRule);
        if (element && this.containerRef) {
          this.containerRef.current.scrollTop = (element.offsetTop + element.offsetHeight) - 30;
          this.props.onResetDriftRule();
        } else {
          const elementList = document.querySelectorAll('.drift-chart .MuiAccordion-root');
          let elementId = "";
          for (const driftElement of elementList) {
            if (driftElement && driftElement.id && driftElement.id.toLowerCase().includes(this.props.selectedRule.toLowerCase())) {
              elementId = driftElement.id;
              this.props.onSetDriftRule(elementId);
              break;
            }
          }
          const element = document.getElementById(elementId);
          if (element && this.containerRef) {
            this.containerRef.current.scrollTop = (element.offsetTop + element.offsetHeight) + 250;
            setTimeout(() => {
              this.props.onResetDriftRule();
            }, 500);
          }
        }
      }, 1000);
    }
  };


  render() {
    const { classes } = this.props;
    let selectedAttribute = {};
    const attribute = this.state.attributes.find(
      (attribute) => attribute.isSelected
    );
    if (attribute) {
      selectedAttribute = attribute;
    }
    return (
      <Grid className={classNames(classes.tabSection, classes.scrollWidth)}>
        <Grid container>
          <Grid item xs={12}>
            <Grid
              container
              alignItems="center"
              justify="space-between"
              direction="row"
              className={classNames(classes.card, classes.container)}
            >
              <Grid
                item
                xs={2}
                className={classNames(classes.selectContainer, classes.CurationSelectAttribute)}
              >
                <AttributeSelect
                  label="Select Dataset or Attribute"
                  placeholder="Select Dataset or Attribute"
                  value={this.state.currentDriftOption}
                  onChange={(event) => this.onChangeDatasetAttribute(event)}
                  options={this.state.driftOption}
                  selectedValuePath="name"
                  displayMemberPath="name"
                  removeClass
                  openSuggestionsOnClick
                  name="DriftOptions"
                  id="DriftOptions"
                  fullWidth
                />
              </Grid>
              <Grid
                item
                xs={2}
                className={classNames(classes.selectContainer, classes.CurationSelectAttribute)}
              >
                <AttributeSelect
                  label="Select Attribute"
                  placeholder="Select Attribute"
                  value={selectedAttribute.name ? selectedAttribute.name : ""}
                  onChange={(event) => this.onChangeAttribute(event)}
                  options={this.state.attributes}
                  selectedValuePath="name"
                  displayMemberPath="name"
                  removeClass
                  openSuggestionsOnClick
                  name="Attribute"
                  id="Attribute"
                  disabled={this.state.disableAttribute}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <Grid className={classes.scoreContainer}>
                  <Grid>
                    <IconButton className={classes.iconButtonSmall}>
                      {
                        selectedAttribute.status === "None" && (
                          <img src={driftNone} className={classes.iconHeight} alt="" />
                        )
                      }
                      {
                        selectedAttribute.status === "Low" && (
                          <img src={driftLow} className={classes.iconHeight} alt="" />
                        )
                      }
                      {
                        selectedAttribute.status === "Medium" && (
                          <img src={driftMedium} className={classes.iconHeight} alt="" />
                        )
                      }
                      {
                        selectedAttribute.status === "High" && (
                          <img src={drift} className={classes.iconHeight} alt="" />
                        )
                      }
                    </IconButton>
                  </Grid>
                  <Grid>
                    <Typography
                      component="h5"
                      variant="h5"
                      className={classes.attributeAnalysisText}
                    >
                      {
                        selectedAttribute.status
                          ? selectedAttribute.status
                          : "Low"
                      }
                    </Typography>
                    <Typography>
                      {"Drift Level"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  component="h5"
                  variant="h5"
                  className={classes.attributeAnalysisText}
                >
                  {
                    selectedAttribute.totalrecord
                      ? selectedAttribute.totalrecord
                      : 0
                  }
                </Typography>
                <Typography>
                  Total Records
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography
                  component="h5"
                  variant="h5"
                  className={classes.attributeAnalysisText}
                >
                  {selectedAttribute.runs ? selectedAttribute.runs : 0}
                </Typography>
                <Typography>
                  Days
                </Typography>
              </Grid>

              <Grid item xs={2}>
                <Typography
                  component="h5"
                  variant="h5"
                  className={classes.attributeAnalysisText}
                >
                  {
                    selectedAttribute.fieldType ? (
                      selectedAttribute.fieldType
                    ) : (
                      <span style={{ visibility: "hidden" }}>
                        " "
                      </span>
                    )
                  }
                </Typography>
                <Typography>
                  Field Type
                </Typography>
              </Grid>

              <Grid item xs={1}>
                <Typography
                  component="h5"
                  variant="h5"
                  className={classes.attributeAnalysisText}
                >
                  {selectedAttribute.type ? selectedAttribute.type : "Text"}
                </Typography>
                <Typography>
                  Data Type
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          {
            selectedAttribute &&
            Object.keys(selectedAttribute).length !== 0 &&
            this.state.currentDriftOption ===
            this.state.driftOption[0].name && (
              <Grid
                container
                spacing={2}
                className={classNames(classes.tabProfileContainer, classes.paddingTop15)}
                ref={this.containerRef}
              >
                {/* <Grid container className={classes.scrollContainer}> */}
                {/* <Grid item xs={12}>
                                <DriftTrending attributeId={selectedAttribute.id} />
                            </Grid> */}

                <Grid item xs={12}>
                  <DriftDashboard attributeId={selectedAttribute.id} />
                </Grid>

                <Grid item xs={12}>
                  <DriftRulesDetails attributeId={selectedAttribute.id} filterRule={this.props.filterRule} onScrollRule={(ruleId) => this.navigateChart(ruleId)} selectedRule={this.props.selectedRule} />
                </Grid>
                {/* </Grid> */}

                {/* <Grid item xs={selectedAttribute && selectedAttribute.type && getFieldType(selectedAttribute.type.toLowerCase()) === 'numeric' ? 5 : 12}>
                                <DriftCountStatistics attributeId={selectedAttribute.id} scheduleId={selectedAttribute.schedule_id} />
                            </Grid>
                            {
                                selectedAttribute && selectedAttribute.type && getFieldType(selectedAttribute.type.toLowerCase()) === 'numeric' &&
                                <Grid item xs={7}>
                                    <DriftDescriptive attributeId={selectedAttribute.id} scheduleId={selectedAttribute.schedule_id} />
                                </Grid>
                            }
                            <Grid item xs={12}>
                                <DriftPattenStatistics attributeId={selectedAttribute.id} scheduleId={selectedAttribute.schedule_id} />
                            </Grid>
                            <Grid item xs={selectedAttribute && selectedAttribute.type && getFieldType(selectedAttribute.type.toLowerCase()) === 'numeric' ? 6 : 12}>
                                <DriftLengthStatistics attributeId={selectedAttribute.id} scheduleId={selectedAttribute.schedule_id} />
                            </Grid>
                            {
                                selectedAttribute && selectedAttribute.type && getFieldType(selectedAttribute.type.toLowerCase()) === 'numeric' &&
                                <Grid item xs={6}>
                                    <DriftExtremeStatistics attributeId={selectedAttribute.id} scheduleId={selectedAttribute.schedule_id} />
                                </Grid>
                            } */}
                {/* {
                                this.state.driftRules && this.state.driftRules.length > 0 &&
                                <Grid item xs={12}>
                                    <DriftRulesStatistics driftRules={this.state.driftRules} updatedAlertRule={this.updatedAlertRule} scheduleId={selectedAttribute.schedule_id} />
                                </Grid>
                            } */}
              </Grid>
            )
          }
          {
            this.state.currentDriftOption === this.state.driftOption[1].name && (
              <Grid
                container
                spacing={2}
                className={classNames(classes.tabProfileContainer, classes.paddingTop15)}
                ref={this.containerRef}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <DriftDatasetDashboard datasetId={this.props.datasetId} />
                  </Grid>

                  <Grid item xs={12}>
                    <DriftDatasetRulesDetails datasetId={this.props.datasetId} onScrollRule={(ruleId) => this.navigateChart(ruleId)} selectedRule={this.props.selectedRule} />
                  </Grid>
                </Grid>
              </Grid>
            )
          }

          {this.state.isLoading && <Loader />}
          {
            this.state.curate &&
            Object.keys(this.state.curate).length === 0 &&
            !this.state.isLoading && <NoResultFound />
          }
        </Grid>
      </Grid>
    );
  }
}

DatasetLearning.propTypes = {
  classes: PropTypes.object,
  datasetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getCurationAnalysis: PropTypes.func,
  setSelectedAttribute: PropTypes.func,
  getDriftRules: PropTypes.func,
  driftAcceptRules: PropTypes.func,
  driftAttributes: PropTypes.array,
  selectedAttribute: PropTypes.string,
  selectedRule: PropTypes.string,
  filterRule: PropTypes.string,
  onResetDriftRule: PropTypes.func,
  onSetDriftRule: PropTypes.func
};

export default withStyles(
  (theme) => ({
    ...DatasetProfileStyles(theme),
    ...Styles(theme)
  }),
  { withTheme: true }
)(DatasetLearning);