import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, MenuItem, Paper } from '@material-ui/core';
import classNames from 'classnames';

import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import DomainSelectStyles from '../DomainSelect/DomainSelectStyles.jsx';

const FieldSelect = (props) => {

    const { classes, availableList, displayValue, inputValue, onBlur, onSelectDomain, onInputValueChange, isEditable, placeholder } = props;
    const [open, setOpen] = useState(false);

    const inputChange = (value) => {
        onInputValueChange(value);
    };

    const handleKeyDown = (event, value) => {
        if (event.keyCode === 13 || event.target.value === "") {
            onBlur(value);
        }
    };

    const handleBlur = (input) => {
        input = input ? input : "";
        const filterList = availableList.filter((item) => item[displayValue].toLowerCase() === input.toLowerCase());
        if (filterList.length === 0) {
            onBlur(input);
        }
    };

    const renderInput = () => {
        return (
            <TextBox
                placeholder={placeholder ? placeholder : "Create or Select a domain"}
                className={classNames(classes.inlinetxt, classes.input, classes.inputHoverBg)}
                value={inputValue ? inputValue : ''}
                fullWidth
                onChange={(event) => inputChange(event.target.value)}
                onKeyDown={(event) => handleKeyDown(event, event.target.value)}
                onBlur={(event) => handleBlur(event.target.value)}
                onClick={() => setOpen(Boolean(!isEditable))}
                disabled={isEditable}
            />
        );
    };

    const getSuggestions = (input, displayValue, list) => {
        input = input ? input : "";
        return list.filter((item) => item[displayValue].toLowerCase().includes(input.toLowerCase()));
    };

    const selectDomain = (value) => {
        onSelectDomain(value);
        setOpen(false);
    };


    const renderSuggestion = (params) => {
        const { item, itemProps, displayValue } = params;
        return (
            <MenuItem
                key={item.id ? item.id : item.name}
                {...itemProps}
                selected={item.isSelected}
                component="div"
                onClick={() => selectDomain({ name: item.name, id: item.id })}
            >
                {item[displayValue]}
            </MenuItem>
        );
    };

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
          /**
           * Alert if clicked on outside of element
           */
          function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setOpen(false);
            }
          }
          // Bind the event listener
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
          };
        }, [ref]);
      };

      const wrapperRef = useRef(null);
      useOutsideAlerter(wrapperRef);

    return (
        <Grid className={classes.selectContainer}>
            <Grid className={classes.selectDropDown}>
                {renderInput()}
                {
                    open &&
                    (
                        <Paper className={classes.paper} square ref={wrapperRef}>
                            {
                                getSuggestions(inputValue, displayValue, availableList).map((item, index) =>
                                    renderSuggestion({
                                        item,
                                        index,
                                        displayValue
                                    })
                                )
                            }
                        </Paper>
                    )
                }
            </Grid>
        </Grid>
    );

};

FieldSelect.propTypes = {
    classes: PropTypes.object,
    availableList: PropTypes.array,
    displayValue: PropTypes.string,
    onSelectDomain: PropTypes.func,
    inputValue: PropTypes.string,
    onInputValueChange: PropTypes.func,
    onBlur: PropTypes.func,
    isEditable: PropTypes.bool,
    placeholder: PropTypes.string
};

export default withStyles((theme) => ({
    ...DomainSelectStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(FieldSelect);