import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Button } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextBox from '../../TextBox/TextBox.jsx';
import Styles from '../../../layouts/Styles.jsx';
import Loader from '../../Loaders/Loader.jsx';

const MySQL = (props) => {
    const { classes, onSave, onChange, storage, type, isLoading } = props;
    return (
        <ValidatorForm onSubmit={() => onSave(type)}>
            <Grid container direction="row" spacing={2} >
                <Grid item xs={6}>
                    <Grid container>
                        <Grid xs={8}>
                            <TextBox
                                label="Server"
                                name="server"
                                validators={['required']}
                                errorMessages={['Server is required']}
                                value={storage.server ? storage.server : ''}
                                onChange={(event) => onChange(type, event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid xs={8}>
                            <TextBox
                                label="Port"
                                name="port"
                                validators={['required']}
                                errorMessages={['Port is required']}
                                value={storage.port ? storage.port : ''}
                                onChange={(event) => onChange(type, event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid xs={8}>
                            <TextBox
                                label="Username"
                                name="username"
                                validators={['required']}
                                errorMessages={['Username is required']}
                                value={storage.username ? storage.username : ''}
                                onChange={(event) => onChange(type, event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid xs={8}>
                            <TextBox
                                label="Password"
                                name="password"
                                validators={['required']}
                                errorMessages={['Password is required']}
                                type="password"
                                value={storage.password ? storage.password : ''}
                                onChange={(event) => onChange(type, event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid xs={8}>
                            <TextBox
                                label="Database Name"
                                name="database"
                                validators={['required']}
                                errorMessages={['Database name is required']}
                                value={storage.database ? storage.database : ''}
                                onChange={(event) => onChange(type, event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classes.actionButtons}>
                        {'Connect'}
                        {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                    </Button>
                </Grid>
            </Grid>

        </ValidatorForm>
    );
};

MySQL.propTypes = {
    storage: PropTypes.object,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    classes: PropTypes.object,
    type: PropTypes.string,
    isLoading: PropTypes.bool
};


export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(MySQL);