import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Grid, withStyles, Typography, Button, IconButton } from '@material-ui/core';

import TextBox from '../../components/TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import DataSourceTile from '../../components/DataSourceTile/DataSourceTile.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import ConfigureDataSourceStyles from './ConfigureDataSourceStyles.jsx';
import NoResultFound from '../../components/NoResultFound/NoResultFound.jsx';
import SelectConnectors from '../../components/SelectConnectors/SelectConnectors.jsx';


class DataSourceTypes extends Component {
    constructor(props) {
        super(props);
        const inputConnectors = this.props.config && this.props.config.input_connectors ? [...this.props.config.input_connectors, 22] : [];
        this.state = {
            datasourceType: '',
            datasourceTypes: this.props.connectionTypes.filter((connection) => inputConnectors.includes(connection.id)),
            searchCss: false,
            openSelectDialog: false,
            connectionUpdated: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        if ((state.datasourceTypes.length === 0 && props.connectionTypes && Object.keys(props.config).length !== 0) || state.connectionUpdated) {
            const connectionTypes = props.connectionTypes.filter((connection) => props.config.input_connectors.includes(connection.id));
            return {
                ...state,
                datasourceTypes: connectionTypes,
                connectionUpdated: false
            };
        }
        return { ...state };
    }

    componentDidMount() {
        if ((this.props.config && this.props.config.input_connectors.length === 0) || !this.props.connectionStatus) {
            this.setState({ openSelectDialog: true });
        }
        this.props.getPageTitle('Datasource Types');
    }

    /**
     * Handles the search key change event
     * @param {string} name - The name of the datasource
     */
    handleChange = (name) => {
        this.setState({
            datasourceType: name
        });
    }

    setConnectionUpdated = () => {
        this.setState({ connectionUpdated: true });
    }


    render() {
        const { classes, history } = this.props;
        const datasourceTypes = this.state.datasourceTypes.filter((p) => p.is_input_connection && p.name.toLowerCase().includes(this.state.datasourceType.toLowerCase()));
        return (
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.contentContainer}>

                    <Grid container>
                        <Grid item sm={12} className={classes.Headercontainer}>
                            <Grid container alignItems="center" className={classnames(classes.marginTop30, classes.height32)}>
                                <Grid item sm={6} onClick={() => { this.setState({ searchCss: false }); }}>
                                    <Typography variant="h1">
                                        {'Select a Datasource Type'}
                                    </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Grid container justify="flex-end">
                                        <Grid
                                            item
                                            className={classnames(classes.textboxContainer, classes.includeSearchContainer, classes.dqSearchBox, classes.boxWidth)}
                                        >
                                            <ValidatorForm onSubmit={() => null}>
                                                <TextBox placeholder={'Search Datasource Type'}
                                                    onChange={(event) => this.handleChange(event.target.value)}
                                                    name="datasourceType"
                                                    id="datasourceType"
                                                    value={this.state.datasourceType}
                                                    fullWidth
                                                    className={classnames(classes.inputOutline, classes.inputSearchDatasrc)}
                                                />
                                            </ValidatorForm>
                                            {
                                                this.state.datasourceType.length === 0 ?
                                                    <IconButton className={classes.includeSearchIcon} >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.094" height="13.092" viewBox="0 0 13.094 13.092">
                                                            <g transform="translate(0 -0.035)">
                                                                <path fill="#afb2b3" d="M5.27,10.57A5.257,5.257,0,0,0,8.5,9.46l3.483,3.483a.655.655,0,0,0,.926-.926L9.427,8.534A5.267,5.267,0,1,0,5.27,10.57ZM2.471,2.5a3.958,3.958,0,1,1,0,5.6h0a3.944,3.944,0,0,1-.02-5.577l.02-.02Z" transform="translate(0 0)" />
                                                            </g>
                                                        </svg>
                                                    </IconButton> :
                                                    <IconButton className={classes.includeDeleteIcon} onClick={() => this.handleChange('')}>
                                                        <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                            <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                            <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                                        </svg>
                                                    </IconButton>
                                            }
                                        </Grid>
                                        {/* {
                                            !this.state.searchCss &&
                                            <IconButton
                                                onMouseOver={() => { this.setState({ searchCss: true }); }}
                                                // onMouseOut={() => {this.setState({searchCss:false});} }
                                                className={classnames(classes.padding5, classes.dqSearchIcon)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24.943 24.939" style={{ fill: '#F38080' }}>
                                                    <defs />
                                                    <g transform="translate(0 -0.035)">
                                                        <path d="M10.039,20.1a10.014,10.014,0,0,0,6.154-2.114l6.636,6.636a1.248,1.248,0,0,0,1.764-1.764l-6.636-6.636A10.033,10.033,0,1,0,10.039,20.1ZM4.707,4.738a7.54,7.54,0,1,1,0,10.663h0A7.513,7.513,0,0,1,4.668,4.777l.039-.039Z" transform="translate(0 0)" />
                                                    </g>
                                                </svg>
                                            </IconButton>
                                        } */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} className={classes.subTitleContainer} onClick={() => { this.setState({ searchCss: false }); }}>
                            <Typography variant="subtitle1">
                                {'DQLabs rapidly collects and integrates data from the broadest range of sources and transforms data to make'}
                            </Typography>
                            <Typography variant="subtitle1" >
                                {'it usable, accurate, and trustworthy. Select a Datasource Type below.'}
                            </Typography>
                        </Grid>
                        <Grid item sm={12} className={classes.marginTop20} onClick={() => { this.setState({ searchCss: false }); }}>
                            <Grid item className={classnames(classes.tileContainer)}>
                                <Grid container direction="row" alignItems="center" spacing={3} className={classes.tileContainerBlock}>
                                    {
                                        datasourceTypes.map((datasource, index) => {
                                            return (
                                                <Grid sm={3} className={classes.fileTypeContainer} item key={`datasourceType_${index}`}>
                                                    <DataSourceTile datasource={datasource} onClick={() => history.push({ pathname: '/configuredatasource', state: { datasourceType: datasource } })} />
                                                </Grid>
                                            );
                                        })
                                    }
                                    {datasourceTypes.length === 0 && <NoResultFound />}
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>


                    <Grid container direction="column" justify="flex-start" alignItems="center" wrap="nowrap" style={{ height: '100%' }} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.btnCancel}>
                    <Grid item className={classes.btnCancelItem}>
                        <Button className={classes.cancelButton}
                            onClick={() => history.goBack()}>
                            {'Cancel'}
                        </Button>
                    </Grid>
                </Grid>
                {
                    this.state.openSelectDialog && this.props.isLicensedValidated &&
                    <SelectConnectors
                        open={this.state.openSelectDialog}
                        onClose={() => this.setState({ openSelectDialog: false })}
                        connectionUpdated={() => this.setConnectionUpdated()}
                        organizationId={this.props.user && this.props.user.organization ? this.props.user.organization : this.props.user.organization_id}
                    />
                }
            </Grid>
        );
    }
}

DataSourceTypes.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    connectionTypes: PropTypes.array,
    config: PropTypes.object,
    isLicensedValidated: PropTypes.bool,
    connectionStatus: PropTypes.bool,
    user: PropTypes.object,
    getPageTitle: PropTypes.func
};

const mapStateToProps = ({ setting, datasource, account }) => {
    return {
        ...setting,
        ...datasource,
        ...account
    };
};

export default connect(mapStateToProps)(withStyles((theme) => ({
    ...ConfigureDataSourceStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DataSourceTypes));