// import react
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

// import material-ui components
import { Grid, withStyles, AccordionSummary, Accordion, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import styles
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';

// import components
import Modules from '../../components/Settings/Modules.jsx';
import Properties from '../../components/Settings/Properties.jsx';
import Notifications from '../../components/Settings/Notification.jsx';
import Domain from '../../components/Settings/Domains.jsx';
import PropTypes from 'prop-types';

const System = (props) => {
    const { classes, organizationId, navigateTab, isSystem, isSystemLicense } = props;
    const [selectedPanel, setSelectedPanel] = useState(navigateTab ? navigateTab : 'My Profile');
    const config = useSelector(({ setting }) => setting.config);

    const toggleExpansionPanel = (panel) => (event, isExpanded) => {
        isExpanded = isExpanded ? panel : false;
        setSelectedPanel(isExpanded);
    };

    const isShowModules = isSystem ? isSystemLicense : true;

    return (
        <Grid className={classes.referenceContainer2}>
            {
                isShowModules &&
                <Accordion expanded={selectedPanel === "Modules"} onChange={toggleExpansionPanel("Modules")} className={classes.storageAcoordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography className={classes.heading}>
                            {"Modules"}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid className={classes.accordianSection}>
                            <Modules organizationId={organizationId} isSystem={isSystem} className={classes.paddingLR10} isSystemLicense={isSystemLicense} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            <Accordion expanded={selectedPanel === "Properties"} onChange={toggleExpansionPanel("Properties")} className={classes.storageAcoordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography className={classes.heading}>
                        {"Properties"}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid className={classes.accordianSection}>
                        <Properties organizationId={organizationId} />
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {
                organizationId !== 0 &&
                <Accordion expanded={selectedPanel === "Notifications"} onChange={toggleExpansionPanel("Notifications")} className={classes.storageAcoordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography className={classes.heading}>
                            {"Notifications"}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.displayBlock}>
                        <Grid className={classes.accordianSection}>
                            <Notifications organizationId={organizationId} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            {
                organizationId !== 0 && config.domain &&
                <Accordion expanded={selectedPanel === "Domains"} onChange={toggleExpansionPanel("Domains")} className={classes.storageAcoordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography className={classes.heading}>
                            {"Domains"}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.displayBlock}>
                        <Grid className={classes.accordianSection}>
                            <Domain organizationId={organizationId} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
        </Grid>
    );
};

System.propTypes = {
    classes: PropTypes.object,
    organizationId: PropTypes.number,
    navigateTab: PropTypes.string,
    isSystem: PropTypes.bool,
    isSystemLicense: PropTypes.bool
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}))(System);