const ConversationStyles = (theme) => ({
    root: {
        height: '100%!important'
    },
    conversationListSection: {
        marginLeft: 10,
        backgroundColor: theme.palette.background.container,
        borderRadius: 4,
        border: `1px solid ${theme.palette.border.normal}`,
        padding: '7px 10px',
        marginBottom: 10
    },
    conversationListContainer: {
        display: 'flex',
        width: '100%'
    },
    conversationUserTxt: {
        fontWeight: 500,
        fontSize: 13
    },
    messageTxt: {
        fontSize: 13,
        color: theme.palette.grey.dark,
        wordBreak: 'break-all'
    },
    unreadSection: {
        padding: "2.5px 0.5px 0px 1.2px",
        display: 'flex',
        width: 15,
        height: 15,
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.background.paper,
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 11,
        borderRadius: 50
    },
    conversationMessageSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
    },
    timeTxt: {
        fontSize: 11,
        color: theme.palette.grey.dark
    },
    listSection: {
        height: 'calc(100vh - 178px)',
        overflowY: 'auto',
        padding: '0px 10px',
        marginBottom: 10
    },
    commentForm: {
        padding: '0px 10px'
    },
    commentContainer: {
        position: 'relative',
        backgroundColor: '#fff',
        '& textarea': {
            border: 'none !important',
            padding: 5
        },
        '& .mentions': {
            margin: 0,
            width: '85%'
        }
    },
    commentSendBtn: {
        position: 'absolute',
        right: 0,
        bottom: 15
    },
    titleSection: {
        display: 'flex',
        alignItems: 'center'
    },
    chatBubble: {
        border: `1px solid ${theme.palette.border.normal}`,
        backgroundColor: theme.palette.background.container,
        padding: 5,
        borderRadius: 4,
        width: '90%',
        marginBottom: 8
    },
    leftChat: {
        '& .MuiTypography-body1': {
            color: `${theme.palette.grey.dark} !important`
        }
    },
    rightChat: {
        backgroundColor: theme.palette.primary.main,
        border: 'none',
        '& .MuiTypography-body1': {
            color: `${theme.palette.background.paper} !important`
        }
    },
    textarea: {
        width: '85%'
    },
    conversationUserCard: {
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.default}`,
        padding: 20
    },
    conversationAreaSection: {
        backgroundColor: '#E1E5E64D',
        border: `1px solid ${theme.palette.border.default}`,
        padding: 20
    },
    whitebg: {
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.border.default}`
    },
    messageContainer: {
        height: 'calc(100vh - 280px)',
        overflowY: 'auto',
        paddingRight: 5,
        width: '100%'
    },
    messageContainerRt: {
        height: 'calc(100vh - 330px)',
        overflowY: 'auto',
        paddingRight: 5,
        width: '100%'
    },
    conversationUser: {
        display: 'flex',
        width: '100%',
        alignItems: 'center'
    },
    cardSelect: {
        backgroundColor: '#CBECFC66',
        border: `1px solid #61CBFF`
    },
    conversationDataTxt: {
        fontWeight: 500,
        fontSize: 12
    },
    breakAll: {
        wordBreak: 'break-all'
    }
});

export default ConversationStyles;