import React, { useState, useEffect, Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button, Checkbox, Dialog, FormControlLabel, FormHelperText, Grid, IconButton, MenuItem, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, withStyles } from '@material-ui/core';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import { sortTable } from '../../helpers/appHelpers';
import { appConstants } from '../../constants/appConstants';
import { ValidatorForm } from 'react-material-ui-form-validator';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import theme from '../../theme';
import NumberInput from '../TextBox/NumberInput.jsx';
import { getOrganizationDatasources } from '../../actions/datasourceActions.js';
import { getDatasetNames } from '../../actions/datasetActions.js';
import { createCustomReportSchedule, createUpdateReport, deleteCustomReportScheduleByReportId, getAttributeRules, getCustomReportScheduleByReportId, getDatasetAttributes, getRecipientUsers, getReportById, updateCustomReportScheduleById } from '../../actions/scheduleActions.js';
import classNames from 'classnames';
import Scheduler from '../Scheduler/Schedule.jsx';
import moment from 'moment-timezone';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import AutoCompleteCheckBox from '../AutoCompleteCheckBox/AutoCompleteCheckBox.jsx';
import CKEditor from '@ckeditor/ckeditor5-react';
import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import Loader from '../Loaders/Loader.jsx';

const defaultCustomValue = {
    operator: '',
    value: '',
    flag: ''
};
const defaultAttachmentDetails = {
    column_name: '',
    column_value: '',
    custom_value: [JSON.parse(JSON.stringify(defaultCustomValue))]
};


const defaultCustomNotification = {
    custom_rule_simple: true,
    custom_rule_detailed: true,
    send_as_link: false,
    previewcount: 10,
    threshold: 2
};

const defaultEmailSetting = {
    record_count: null,
    limit_valid_invalid_record_count: false,
    limit_by_custom_value: false,
    custom_value: []
};


const ReportConfiguration = (props) => {
    const { classes, reportId, onClose, organizationId } = props;
    const dispatch = useDispatch();

    const zones = [{ name: moment.tz.guess() }, ...appConstants.TimeZones];

    const defaultParams = {
        "schedule_method": "Month",
        "start_profile": true,
        "start_curate": true,
        "week_day": ["Sunday"],
        "month_date": [moment(new Date()).format('DD')],
        "date": new Date(),
        "time": new Date(),
        "time_zone": zones[0] ? zones[0].name : ""
    };

    const [report, setReport] = useState({
        report_name: '',
        email_report_type: appConstants.emailReportType[0].value,
        is_active: appConstants.activeStatus[0].value,
        description: '',
        datasource: '',
        dataset: '',
        attribute: '',
        rule: '',
        recipients: [],
        subject: '',
        body: '',
        attachment_details: [],
        custom_notification: defaultCustomNotification,
        organization_id: organizationId,
        email_setting: defaultEmailSetting
    });
    const [isChanged, setChanged] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [customValue, setCustomValue] = useState(null);
    const [datsetsBySource, setDatsetsBySource] = useState([]);
    const [attributesList, setAttributesList] = useState([]);
    const [rulesByAttributes, setRulesByAttributes] = useState([]);
    const [recipientsUserList, setRecipientsUserList] = useState([]);
    const [customFlags, setCustomFlags] = useState([]);
    const [schedule, setSchedule] = useState({ open: false, anchorElement: null, reportId: null });

    const [scheduleData, setScheduleData] = useState({ ...defaultParams });
    const [showRemoveSchedule, setShowRemoveSchedule] = useState(false);
    const [isExistingSchedule, setExistingSchedule] = useState(false);
    const [selectedAttachment, setSelectedAttachment] = useState(null);
    const [selectedCustomAttachment, setSelectedCustomAttachment] = useState(null);

    let dataSources = useSelector(({ datasource }) => datasource.organizationSource);
    dataSources = dataSources.filter((elem) => elem.name.trim() !== '');

    const recipientUsers = useSelector(({ dataset }) => dataset.recipientsNames);

    // const custom_flags = custom_attachment && custom_attachment.length > 0 ? custom_attachment[0]?.custom_value.map((elem) => ({ name: elem.flag })) : [];

    useEffect(() => {
        dispatch(getOrganizationDatasources());
    }, [dispatch]);

    useEffect(() => {
        if (reportId) {
            setIsLoading(true);
            dispatch(getReportById(reportId)).then((reportRes) => {
                if (reportRes) {
                    const custom_attachment_res = reportRes.attachment_details.filter((elem) => elem.column_value === 'Custom');
                    if (custom_attachment_res && custom_attachment_res.length > 0) {
                        const customValueCopy = formatCustomFlags(custom_attachment_res, reportRes);
                        setCustomFlags(customValueCopy);
                    }
                    setReport({ ...report, ...reportRes });
                    dispatch(getDatasetNames(reportRes.datasource)).then((response) => {
                        if (response) {
                            setDatsetsBySource(response);
                        } else {
                            setDatsetsBySource(response);
                        }
                        if (reportRes.dataset) {
                            dispatch(getDatasetAttributes(0, reportRes.dataset)).then((response) => {
                                if (response) {
                                    setAttributesList(response);
                                } else {
                                    setAttributesList([]);
                                }
                                dispatch(getAttributeRules(reportRes.attribute)).then((response) => {
                                    if (response) {
                                        setRulesByAttributes(response);
                                    } else {
                                        setRulesByAttributes([]);
                                    }
                                    dispatch(getRecipientUsers(0, reportRes.dataset)).then((response) => {
                                        if (response) {
                                            const recipientsCopy = response.map((elem) => {
                                                if (report.recipients.some((item) => item === elem.id)) {
                                                    return {
                                                        ...elem,
                                                        isSelected: true
                                                    };
                                                }
                                                return {
                                                    ...elem,
                                                    isSelected: false
                                                };
                                            });
                                            setRecipientsUserList(recipientsCopy);
                                        } else {
                                            setRecipientsUserList([]);
                                        }
                                        setIsLoading(false);
                                    });
                                });
                            });
                        } else {
                            dispatch(getRecipientUsers(reportRes.datasource, 0)).then((response) => {
                                if (response) {
                                    const recipientsCopy = response.map((elem) => {
                                        if (report.recipients.some((item) => item === elem.id)) {
                                            return {
                                                ...elem,
                                                isSelected: true
                                            };
                                        }
                                        return {
                                            ...elem,
                                            isSelected: false
                                        };
                                    });
                                    setRecipientsUserList(recipientsCopy);
                                } else {
                                    setRecipientsUserList([]);
                                }
                                setIsLoading(false);
                            });
                        }
                    });
                }
            });
            dispatch(getCustomReportScheduleByReportId(reportId)).then((response) => {
                if (response && Object.keys(response).length !== 0) {
                    let hour = response && response.day_week_month_time !== "" ? response.day_week_month_time : '';
                    if (hour === 0) {
                        hour = "00";
                    }
                    const min = response && response.day_week_month_minitues ? response.day_week_month_minitues : '';
                    let time = `${hour}:${min}`;
                    const hasTime = (time.split(':').filter((p) => p.length > 0)).length > 0;
                    if (!hasTime) {
                        time = Date.now();
                    } else {
                        time = time ? moment(moment().tz(response.time_zone).format('YYYY-MM-DD') + ` ${time}`) : '';
                    }
                    let minutesInterval = 0;
                    if (response.schedule_method === "Hour") {
                        minutesInterval = (response.minitues_interval !== "" ? parseInt(response.minitues_interval) / 60 : 0);
                    } else {
                        minutesInterval = (response.minitues_interval !== "" ? response.minitues_interval : 0);
                    }
                    const schedule = {
                        "id": response.id,
                        "minitues_interval": minutesInterval,
                        "time_zone": response.time_zone,
                        "week_day": response.week_day !== "" ? response.week_day.split(',') : ["Sunday"],
                        "month_date": response.month_date !== "" ? response.month_date.split(',') : [moment(new Date()).format('DD')],
                        "schedule_method": response.schedule_method,
                        "date": moment(response.start_time).tz(response.time_zone),
                        "time": moment(response.start_time).tz(response.time_zone),
                        "day": time
                    };
                    setExistingSchedule(true);
                    setScheduleData(schedule);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, reportId]);

    const formatCustomFlags = (custom_attachment, reportData) => {
        return custom_attachment[0]?.custom_value.map((elem) => {
            if (reportData['email_setting'] && reportData['email_setting']['custom_value'] && reportData['email_setting']['custom_value'].some((item) => item === elem.flag)) {
                return {
                    name: elem.flag,
                    id: elem.flag,
                    isSelected: true
                };
            }
            return ({ name: elem.flag, id: elem.flag, isSelected: false });
        });
    };

    const onChangeReportConfig = (property, value) => {
        if (property === 'email_report_type') {
            report[property] = value;
            if (value === 1) {
                report.attachment_details = [];
                report.custom_notification = defaultCustomNotification;
            } else {
                report.custom_notification = {};
                report.attachment_details = [JSON.parse(JSON.stringify(defaultAttachmentDetails))];
            }
        } else if (property === 'attachment_details') {
            report[property][value.index][value.name] = value.value;
        } else if (property === 'custom_notification') {
            report[property] = value;
            if ((report?.custom_notification?.custom_rule_simple || report?.custom_notification?.custom_rule_detailed)) {
                report[property]['send_as_link'] = false;
            } else if (report?.custom_notification?.send_as_link) {
                report[property]['custom_rule_simple'] = false;
                report[property]['custom_rule_detailed'] = false;
            }
        } else if (property === 'recipients') {
            let recipientsUserListCopy = [...JSON.parse(JSON.stringify(recipientsUserList))];
            if (value.name === 'all') {
                if (value.isSelected) {
                    recipientsUserListCopy = recipientsUserListCopy.map((elem) => ({ ...elem, isSelected: true }));
                } else {
                    recipientsUserListCopy = recipientsUserListCopy.map((elem) => ({ ...elem, isSelected: false }));
                }
                report[property] = recipientsUserListCopy.filter((elem) => elem.isSelected).map((elem) => elem.id);
            } else {
                const valueIndex = recipientsUserListCopy.findIndex((elem) => elem.id === value.id);
                if (valueIndex > -1) {
                    if (recipientsUserListCopy[valueIndex].isSelected) {
                        recipientsUserListCopy[valueIndex].isSelected = false;
                    } else {
                        recipientsUserListCopy[valueIndex].isSelected = true;
                    }
                }
                report[property] = recipientsUserListCopy.filter((elem) => elem.isSelected).map((elem) => elem.id);
            }
            setRecipientsUserList(recipientsUserListCopy);
        } else if (property === 'custom_value') {
            report['attachment_details'][customValue.index][property][value.index][value.name] = value.value;
            if (value.name === 'flag') {
                const custom_attachment = report.attachment_details.filter((elem) => elem.column_value === 'Custom');
                report['email_setting']['custom_value'] = [];
                if (custom_attachment && custom_attachment.length > 0) {
                    const customValueCopy = formatCustomFlags(custom_attachment, report);
                    const valueIndex = customValueCopy.findIndex((elem) => elem.id === value.id);
                    if (valueIndex > -1) {
                        if (customValueCopy[valueIndex].isSelected) {
                            customValueCopy[valueIndex].isSelected = false;
                        } else {
                            customValueCopy[valueIndex].isSelected = true;
                        }
                    }
                    setCustomFlags(customValueCopy);
                }
            }
        } else if (property === 'email_setting_custom_value') {
            let customFlagsCopy = [...JSON.parse(JSON.stringify(customFlags))];
            if (value.name === 'all') {
                if (value.isSelected) {
                    customFlagsCopy = customFlagsCopy.map((elem) => ({ ...elem, isSelected: true }));
                } else {
                    customFlagsCopy = customFlagsCopy.map((elem) => ({ ...elem, isSelected: false }));
                }
                report['email_setting']['custom_value'] = customFlagsCopy.filter((elem) => elem.isSelected).map((elem) => elem.id);
            } else {
                const valueIndex = customFlagsCopy.findIndex((elem) => elem.id === value.id);
                if (valueIndex > -1) {
                    if (customFlagsCopy[valueIndex].isSelected) {
                        customFlagsCopy[valueIndex].isSelected = false;
                    } else {
                        customFlagsCopy[valueIndex].isSelected = true;
                    }
                }
                report['email_setting']['custom_value'] = customFlagsCopy.filter((elem) => elem.isSelected).map((elem) => elem.id);
            }
            setCustomFlags(customFlagsCopy);
        } else {
            report[property] = value;
        }
        if (property === 'datasource') {
            report.dataset = '';
            report.attribute = '';
            report.rule = '';
            report.recipients = [];
            if (report.email_report_type === 1) {
                dispatch(getDatasetNames(value)).then((response) => {
                    setDatsetsBySource(response);
                });
            } else {
                dispatch(getDatasetAttributes(value, 0)).then((response) => {
                    setAttributesList(response);
                });
            }
            dispatch(getRecipientUsers(value, 0)).then((response) => {
                setRecipientsUserList(response);
            });
        } else if (property === 'dataset') {
            report.attribute = '';
            report.rule = '';
            report.recipients = [];
            dispatch(getDatasetAttributes(0, value)).then((response) => {
                setAttributesList(response);
            });
            dispatch(getRecipientUsers(0, value)).then((response) => {
                setRecipientsUserList(response);
            });
        } else if (property === 'attribute') {
            report.rule = '';
            dispatch(getAttributeRules(value)).then((response) => {
                setRulesByAttributes(response);
            });
        }
        setChanged(true);
        setIsSubmitted(false);
        setReport({ ...report });
    };

    const openSchedule = (event) => {
        event.stopPropagation();
        setSchedule({ open: true, anchorElement: event.target, reportId: report.id });
    };

    const createScheduler = (schedule) => {
        if (scheduleData.id) {
            dispatch(updateCustomReportScheduleById(scheduleData.id, { ...schedule, custom_report_id: report.id })).then((response) => {
                setScheduleData({ ...response });
                setExistingSchedule(true);
            });
        } else {
            dispatch(createCustomReportSchedule({ ...schedule, custom_report_id: report.id })).then((response) => {
                setScheduleData({ ...response });
                setExistingSchedule(true);
            });
        }
    };

    const onRemoveSchedule = () => {
        dispatch(deleteCustomReportScheduleByReportId(report.id)).then(() => {
            setScheduleData({ ...defaultParams });
            setExistingSchedule(false);
            setShowRemoveSchedule(false);
            onClose();
        });
    };

    const searchOptions = useCallback((name, search_key = '') => {
        if (name === 'recipients') {
            let recipientUsersCopy = [...JSON.parse(JSON.stringify(recipientUsers))];
            recipientUsersCopy = recipientUsersCopy.map((user) => {
                if (report[name].some((elem) => user.id === elem)) {
                    return {
                        ...user,
                        isSelected: true
                    };
                }
                return user;
            });
            if (search_key) {
                recipientUsersCopy = recipientUsersCopy.filter((elem) => new RegExp(search_key, 'i').test(elem.name));
            }
            setRecipientsUserList(recipientUsersCopy);
        }
    }, [recipientUsers, report]);

    return (
        <ValidatorForm
            onSubmit={
                () => {
                    setIsSubmitted(true);
                    if (report.email_report_type === appConstants.emailReportType[0].value) {
                        if (report.datasource && report.dataset && report.attribute && report.recipients) {
                            dispatch(createUpdateReport(report));
                            onClose();
                        }
                    } else {
                        if (report.datasource && report.recipients && (report.email_setting.limit_by_custom_value ? report.email_setting.custom_value && report.email_setting.custom_value.length > 0 : true)) {
                            dispatch(createUpdateReport(report));
                            onClose();
                        }
                    }
                }
            }
            onError={() => { setIsSubmitted(true); }}
            className={classes.marginTop20}
        >
            {
                !isLoading &&
                <Grid container spacing={4} className={classes.reportConfigurationContainer}>
                    <Grid item xs={10}>
                        <Typography variant="h5" component="h5">
                            {!reportId ? "New Report" : "Edit Report"}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {
                            report.email_report_type === 2 && report.is_active &&
                            <ToolTipComponent title={"Schedule"} arrow>
                                <IconButton
                                    onClick={(event) => openSchedule(event)}
                                    className={classes.iconButtonSmall}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        id="calendar_4_"
                                        data-name="calendar (4)"
                                        width="18.174"
                                        height="18.174"
                                        viewBox="0 0 18.174 18.174"
                                    >
                                        <g id="Group_1602" data-name="Group 1602">
                                            <g id="Group_1601" data-name="Group 1601">
                                                <ellipse
                                                    id="Ellipse_946"
                                                    data-name="Ellipse 946"
                                                    cx="1"
                                                    cy="0.5"
                                                    rx="1"
                                                    ry="0.5"
                                                    transform="translate(13.087 7.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <path
                                                    id="Path_890"
                                                    data-name="Path 890"
                                                    d="M15.335,1.42h-.923V.71a.71.71,0,0,0-1.42,0v.71H9.762V.71a.71.71,0,1,0-1.42,0v.71H5.147V.71a.71.71,0,0,0-1.42,0v.71H2.84A2.843,2.843,0,0,0,0,4.26V15.335a2.843,2.843,0,0,0,2.84,2.84H8.271a.71.71,0,1,0,0-1.42H2.84a1.421,1.421,0,0,1-1.42-1.42V4.26A1.421,1.421,0,0,1,2.84,2.84h.887v.71a.71.71,0,1,0,1.42,0V2.84H8.342v.71a.71.71,0,1,0,1.42,0V2.84h3.23v.71a.71.71,0,0,0,1.42,0V2.84h.923a1.421,1.421,0,0,1,1.42,1.42V8.306a.71.71,0,0,0,1.42,0V4.26A2.843,2.843,0,0,0,15.335,1.42Z"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <path
                                                    id="Path_891"
                                                    data-name="Path 891"
                                                    d="M274.3,270a4.3,4.3,0,1,0,4.3,4.3A4.3,4.3,0,0,0,274.3,270Zm0,7.17a2.875,2.875,0,1,1,2.875-2.875A2.878,2.878,0,0,1,274.3,277.17Z"
                                                    transform="translate(-260.416 -260.416)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <path
                                                    id="Path_892"
                                                    data-name="Path 892"
                                                    d="M372.739,331.455h-.319v-.745a.71.71,0,1,0-1.42,0v1.455a.71.71,0,0,0,.71.71h1.029a.71.71,0,0,0,0-1.42Z"
                                                    transform="translate(-357.831 -318.286)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <circle
                                                    id="Ellipse_947"
                                                    data-name="Ellipse 947"
                                                    cx="0.5"
                                                    cy="0.5"
                                                    r="0.5"
                                                    transform="translate(10.087 7.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <circle
                                                    id="Ellipse_948"
                                                    data-name="Ellipse 948"
                                                    cx="0.5"
                                                    cy="0.5"
                                                    r="0.5"
                                                    transform="translate(7.087 10.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <ellipse
                                                    id="Ellipse_949"
                                                    data-name="Ellipse 949"
                                                    cx="1"
                                                    cy="0.5"
                                                    rx="1"
                                                    ry="0.5"
                                                    transform="translate(3.087 7.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <ellipse
                                                    id="Ellipse_950"
                                                    data-name="Ellipse 950"
                                                    cx="1"
                                                    cy="0.5"
                                                    rx="1"
                                                    ry="0.5"
                                                    transform="translate(3.087 10.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <circle
                                                    id="Ellipse_951"
                                                    data-name="Ellipse 951"
                                                    cx="1"
                                                    cy="1"
                                                    r="1"
                                                    transform="translate(3.087 13.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <ellipse
                                                    id="Ellipse_952"
                                                    data-name="Ellipse 952"
                                                    cx="0.5"
                                                    cy="1"
                                                    rx="0.5"
                                                    ry="1"
                                                    transform="translate(7.087 13.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                                <circle
                                                    id="Ellipse_953"
                                                    data-name="Ellipse 953"
                                                    cx="0.5"
                                                    cy="0.5"
                                                    r="0.5"
                                                    transform="translate(7.087 7.087)"
                                                    fill={theme.palette.primary.main}
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </IconButton>
                            </ToolTipComponent>
                        }
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextBox
                            label="Report Name"
                            value={report.report_name ? report.report_name : ""}
                            name="report_name"
                            fullWidth
                            validators={['required']}
                            errorMessages={['Report Name is required']}
                            onChange={(event) => onChangeReportConfig(event.target.name, event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextBox
                            label="Email Report Type"
                            name="email_report_type"
                            fullWidth
                            select
                            onChange={(event) => onChangeReportConfig("email_report_type", event.target.value)}
                            value={report.email_report_type ? report.email_report_type : appConstants.emailReportType[0].value}
                            className={classes.inlineTxt}
                            // disabled={((report.name && report.name.toLowerCase() === "admin") || (userRole.name && userRole.name.toLowerCase() !== "admin"))}
                            SelectProps={
                                {
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }
                                    // IconComponent: () => DropDownIcon()
                                }
                            }
                            validators={['required']}
                            errorMessages={['Email Report Type is required']}
                        >
                            {
                                appConstants.emailReportType && appConstants.emailReportType.length ?
                                    sortTable(appConstants.emailReportType, "asc", "name").map((option, index) => (
                                        <MenuItem key={`menuProperty_Options_${index}`} value={option.value} className={classes.menuItem}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                    :
                                    <MenuItem key={`menuProperty_Options`} disabled className={classes.menuItem}>
                                        No options
                                    </MenuItem>
                            }
                        </TextBox>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextBox
                            label="Status"
                            name="is_active"
                            fullWidth
                            select
                            onChange={(event) => onChangeReportConfig("is_active", event.target.value)}
                            value={report.is_active}
                            className={classes.inlineTxt}
                            // disabled={((report.name && report.name.toLowerCase() === "admin") || (userRole.name && userRole.name.toLowerCase() !== "admin"))}
                            SelectProps={
                                {
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }
                                    // IconComponent: () => DropDownIcon()
                                }
                            }
                            validators={['required']}
                            errorMessages={['Status is required']}
                        >
                            {
                                appConstants.activeStatus && appConstants.activeStatus.length ?
                                    appConstants.activeStatus.map((option, index) => (
                                        <MenuItem key={`menuProperty_Options_${index}`} value={option.value} className={classes.menuItem}>
                                            {option.name}
                                        </MenuItem>
                                    ))
                                    :
                                    <MenuItem key={`menuProperty_Options`} disabled className={classes.menuItem}>
                                        No options
                                    </MenuItem>
                            }
                        </TextBox>
                    </Grid>
                    <Grid item xs={12}>
                        <TextBox
                            label="Description"
                            value={report.description ? report.description : ""}
                            name="description"
                            fullWidth
                            rows="2"
                            multiline
                            onChange={(event) => onChangeReportConfig(event.target.name, event.target.value)}
                            validators={['required']}
                            errorMessages={['Description is required']}
                        // disabled={isLoading || (role.name && role.name.toLowerCase() === "admin") || (userRole.name && userRole.name.toLowerCase() !== "admin")}
                        />
                    </Grid>
                    {
                        !isLoading &&
                        <Grid item xs={12} md={4}>
                            <AutoCompleteInput
                                name="datasource"
                                options={sortTable(dataSources ? dataSources : [], "asc", "name")}
                                value={report.datasource ? report.datasource : ''}
                                getOptionLabel={
                                    (option) => {
                                        if (option) {
                                            if (option.name) {
                                                return option.name;
                                            }
                                            const optionName = dataSources.filter((elem) => elem.id === option);
                                            if (optionName.length > 0) {
                                                return optionName[0].name;
                                            }
                                        }
                                        return option;
                                    }
                                }
                                getOptionSelected={(option, value) => option.id === value}
                                renderInput={
                                    (params) =>
                                        <TextField {...params}
                                            label="Select Datasource"
                                            fullWidth
                                            error={!report.datasource && isSubmitted}
                                            helperText={!report.datasource && isSubmitted ? 'Datasource is required' : ''}
                                        />
                                }
                                onChange={(_, selectedName) => onChangeReportConfig("datasource", selectedName ? selectedName.id : '')}
                                fullWidth
                            />
                        </Grid>
                    }
                    {
                        !isLoading && report.email_report_type === appConstants.emailReportType[0].value &&
                        <Fragment>
                            <Grid item xs={12} md={4}>
                                <AutoCompleteInput
                                    name="dataset"
                                    options={sortTable(datsetsBySource ? datsetsBySource : [], "asc", "name")}
                                    value={report.dataset ? report.dataset : ''}
                                    getOptionLabel={
                                        (option) => {
                                            if (option) {
                                                if (option.name) {
                                                    return option.name;
                                                }
                                                const optionName = datsetsBySource.filter((elem) => elem.id === option);
                                                if (optionName.length > 0) {
                                                    return optionName[0].name;
                                                }
                                            }
                                            return option;
                                        }
                                    }
                                    getOptionSelected={(option, value) => option.id === value}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Select Dataset"
                                                fullWidth
                                                error={!report.dataset && isSubmitted}
                                                helperText={!report.dataset && isSubmitted ? 'Dataset is required' : ''}
                                            />
                                    }
                                    onChange={(_, selectedName) => onChangeReportConfig("dataset", selectedName ? selectedName.id : '')}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <AutoCompleteInput
                                    name="attribute"
                                    options={sortTable(attributesList ? attributesList : [], "asc", "name")}
                                    value={report.attribute ? report.attribute : ''}
                                    getOptionLabel={
                                        (option) => {
                                            if (option) {
                                                if (option.name) {
                                                    return option.name;
                                                }
                                                const optionName = attributesList.filter((elem) => elem.id === option);
                                                if (optionName.length > 0) {
                                                    return optionName[0].name;
                                                }
                                            }
                                            return option;
                                        }
                                    }
                                    getOptionSelected={(option, value) => option.id === value}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Select Attribute"
                                                fullWidth
                                                error={!report.attribute && isSubmitted}
                                                helperText={!report.attribute && isSubmitted ? 'Attribute is required' : ''}
                                            />
                                    }
                                    onChange={(_, selectedName) => onChangeReportConfig("attribute", selectedName ? selectedName.id : '')}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <AutoCompleteInput
                                    name="rule"
                                    options={sortTable(rulesByAttributes ? rulesByAttributes : [], "asc", "name")}
                                    value={report.rule ? report.rule : ''}
                                    getOptionLabel={
                                        (option) => {
                                            if (option) {
                                                if (option.name) {
                                                    return option.name;
                                                }
                                                const optionName = rulesByAttributes.filter((elem) => elem.id === option);
                                                if (optionName.length > 0) {
                                                    return optionName[0].name;
                                                }
                                            }
                                            return option;
                                        }
                                    }
                                    getOptionSelected={(option, value) => option.id === value}
                                    renderInput={
                                        (params) =>
                                            <TextField {...params}
                                                label="Select Rule"
                                                fullWidth
                                                error={!report.rule && isSubmitted}
                                                helperText={!report.rule && isSubmitted ? 'Rule is required' : ''}
                                            />
                                    }
                                    onChange={(_, selectedName) => onChangeReportConfig("rule", selectedName ? selectedName.id : '')}
                                    fullWidth
                                />
                            </Grid>
                        </Fragment>
                    }
                    {
                        !isLoading &&
                        <Grid item xs={12} md={4}>
                            <AutoCompleteCheckBox
                                selectionData={report.recipients ? report.recipients : []}
                                availableList={sortTable(recipientsUserList ? recipientsUserList : [], "asc", "name")}
                                onChange={(value) => onChangeReportConfig("recipients", value)}
                                onSearch={(value) => searchOptions("recipients", value)}
                                fullWidth
                                label="Select Recipients"
                                noMargin
                                isHideSelectedAllCount
                                error={report.recipients.length === 0 && isSubmitted}
                                helperText={report.recipients.length === 0 && isSubmitted ? 'Rule is required' : ''}
                            />
                        </Grid>
                    }
                    {
                        report.email_report_type === appConstants.emailReportType[0].value &&
                        <Grid item xs={12} style={{ backgroundColor: '#ebebeb' }}>
                            <Typography variant="h5" component="h5">
                                Notification Type
                            </Typography>
                            <Grid container style={{ padding: '16px 24px', justifyContent: 'space-between' }}>
                                <Grid item style={{ display: 'flex' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={report?.custom_notification?.custom_rule_simple}
                                                onChange={() => onChangeReportConfig('custom_notification', { ...report?.custom_notification, 'custom_rule_simple': !report?.custom_notification?.custom_rule_simple })}
                                                name="custom_rule_simple"
                                                color="primary"
                                            />
                                        }
                                        label="Simple"
                                    />
                                </Grid>
                                <Grid item style={{ display: 'flex' }}>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={report?.custom_notification?.custom_rule_detailed}
                                                onChange={() => onChangeReportConfig('custom_notification', { ...report?.custom_notification, 'custom_rule_detailed': !report?.custom_notification?.custom_rule_detailed })}
                                                name="custom_rule_detailed"
                                                color="primary"
                                            />
                                        }
                                        label="Detailed"
                                    />
                                </Grid>
                                <Grid item>
                                    <NumberInput
                                        name="previewcount"
                                        label="Preview Count"
                                        // fullWidth
                                        onChange={(event) => onChangeReportConfig('custom_notification', { ...report?.custom_notification, 'previewcount': event.target.value })}
                                        value={report?.custom_notification?.previewcount}
                                        validators={['required']}
                                        errorMessages={['Preview Count is required']}
                                    />
                                </Grid>
                                <Grid item>
                                    <NumberInput
                                        name="threshold"
                                        label="Threshold"
                                        // fullWidth
                                        onChange={(event) => onChangeReportConfig('custom_notification', { ...report?.custom_notification, 'threshold': event.target.value })}
                                        value={report?.custom_notification?.threshold}
                                        validators={['required']}
                                        errorMessages={['Threshold is required']}
                                    />
                                </Grid>
                                <Grid item style={{ display: 'flex' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={report?.custom_notification?.send_as_link}
                                                onChange={() => onChangeReportConfig('custom_notification', { ...report?.custom_notification, 'send_as_link': !report?.custom_notification?.send_as_link, 'custom_rule_simple': report?.custom_notification?.send_as_link, 'custom_rule_detailed': report?.custom_notification?.send_as_link })}
                                                name="send_as_link"
                                                color="primary"
                                            />
                                        }
                                        label="Send as a Link"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant="h5" component="h5">
                            Email Content
                        </Typography>
                        <Typography variant="body2" component="h5">
                            Provide the email subject and body in the below input box
                        </Typography>
                        <Grid container spacing={2} className={classes.marginTop10}>
                            <Grid item xs={12}>
                                <TextBox
                                    label="Email Subject"
                                    value={report.subject ? report.subject : ""}
                                    name="subject"
                                    fullWidth
                                    rows="1"
                                    multiline
                                    onChange={(event) => onChangeReportConfig(event.target.name, event.target.value)}
                                    validators={['required']}
                                    errorMessages={['Email Subject is required']}
                                // disabled={isLoading || (role.name && role.name.toLowerCase() === "admin") || (userRole.name && userRole.name.toLowerCase() !== "admin")}
                                />
                            </Grid>
                            <Grid item xs={12} className={classes.txteditorContainer}>
                                <CKEditor
                                    className={classes.txteditor}
                                    disabled={false}
                                    // onInit={(editor) => { editor.setData(report.body ? report.body : "") }}
                                    onChange={
                                        (event, editor) => {
                                            onChangeReportConfig('body', editor.getData());
                                        }
                                    }
                                    config={
                                        {
                                            placeholder: "Email Body",
                                            toolbar: ['bold', 'italic', 'underline', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
                                        }
                                    }
                                    editor={InlineEditor}
                                    data={report.body ? report.body : ""}
                                />
                                {
                                    isSubmitted && !report.body &&
                                    <FormHelperText error>
                                        Email Body is required
                                    </FormHelperText>
                                }
                            </Grid>

                        </Grid>
                    </Grid>
                    {
                        report.email_report_type === appConstants.emailReportType[1].value &&
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={10}>
                                    <Typography variant="h5" component="h5">
                                        Attachment Details
                                    </Typography>
                                    <Typography variant="body2" component="h5">
                                        Provide the columns and values to be sent in the attachment of this email report
                                    </Typography>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: 'right' }}>
                                    <ToolTipComponent title="Add Column" arrow>
                                        <IconButton
                                            onClick={
                                                () => {
                                                    report.attachment_details.push(JSON.parse(JSON.stringify(defaultAttachmentDetails)));
                                                    setReport({ ...report });
                                                }
                                            }
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                                    <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                                        <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                                        <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                                    </g>
                                                </g>
                                            </svg>
                                        </IconButton>
                                    </ToolTipComponent>
                                </Grid>
                                <Table stickyHeader className={classes.marginTop20}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="body1" className={classes.tableHeader}>
                                                    {'Column Name'}
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="body1" className={classes.tableHeader}>
                                                    {'Column Value'}
                                                </Typography>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Typography variant="body1" className={classes.tableHeader}>
                                                    {'Actions'}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody className={classes.propListPageTableBody}>
                                        {
                                            report?.attachment_details.map((attribute, index) => {
                                                return (
                                                    <TableRow key={`attributeProperty${index}`}>
                                                        <TableCell>
                                                            <TextBox
                                                                label=""
                                                                value={attribute?.column_name ? attribute?.column_name : ""}
                                                                name="column_name"
                                                                fullWidth
                                                                onChange={(event) => onChangeReportConfig('attachment_details', { name: event.target.name, value: event.target.value, index })}
                                                            // disabled={isLoading || (role.name && role.name.toLowerCase() === "admin") || (userRole.name && userRole.name.toLowerCase() !== "admin")}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextBox
                                                                label=""
                                                                name="column_value"
                                                                fullWidth
                                                                select
                                                                onChange={(event) => onChangeReportConfig("attachment_details", { name: event.target.name, value: event.target.value, index })}
                                                                value={attribute.column_value ? attribute.column_value : appConstants.levelPermission[2]}
                                                                className={classes.inlineTxt}
                                                                // disabled={((report.name && report.name.toLowerCase() === "admin") || (userRole.name && userRole.name.toLowerCase() !== "admin"))}
                                                                SelectProps={
                                                                    {
                                                                        MenuProps: {
                                                                            anchorOrigin: {
                                                                                vertical: "bottom",
                                                                                horizontal: "center"
                                                                            },
                                                                            transformOrigin: {
                                                                                vertical: "top",
                                                                                horizontal: "center"
                                                                            },
                                                                            getContentAnchorEl: null
                                                                        }
                                                                        // IconComponent: () => DropDownIcon()
                                                                    }
                                                                }
                                                            >
                                                                {
                                                                    ['Datasource Name', 'Dataset Name', 'Attribute Name', 'Rule Name', 'DQ Score', 'Invalid Records Count', 'Valid Records Count', 'Invalid Records', 'Valid Records', 'Custom'].filter((elem) => (elem === attribute.column_value || !report?.attachment_details.map((item) => item.column_value).includes(elem))).map((option, indexM) => (
                                                                        <MenuItem
                                                                            key={`menuProperty_Options_${indexM}`}
                                                                            value={option}
                                                                            className={classes.menuItem}
                                                                            onClick={
                                                                                () => {
                                                                                    if (option === 'Custom') {
                                                                                        setCustomValue({ ...report['attachment_details'][index], index: index });
                                                                                    }
                                                                                }
                                                                            }
                                                                            disabled={(option === 'Invalid Records' || option === 'Valid Records' || option === 'Valid Records Count' || option === 'Invalid Records Count') && report['attachment_details'].filter((elem) => elem.column_value === 'Rule Name').length === 0}
                                                                        >
                                                                            {option}
                                                                        </MenuItem>
                                                                    ))
                                                                }
                                                            </TextBox>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Grid item align="center">
                                                                <ToolTipComponent title={"Delete Column"} arrow>
                                                                    <IconButton
                                                                        className={classes.referenceRoleIcon}
                                                                        onClick={
                                                                            () => {
                                                                                setSelectedAttachment({ ...attribute, index });
                                                                            }
                                                                        }
                                                                    >
                                                                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 32 32">
                                                                            <g id="Delete">
                                                                                <path fill={theme.palette.primary.main}
                                                                                    stroke={theme.palette.primary.main}
                                                                                    strokeWidth="0.92"
                                                                                    strokeMiterlimit="10"
                                                                                    d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                                                                <path fill={theme.palette.primary.main}
                                                                                    stroke={theme.palette.primary.main}
                                                                                    strokeWidth="0.92"
                                                                                    strokeMiterlimit="10"
                                                                                    d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                                            </g>
                                                                        </svg>
                                                                    </IconButton>
                                                                </ToolTipComponent>
                                                            </Grid>
                                                        </TableCell>
                                                    </TableRow>

                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </Grid>
                        </Grid>
                    }
                    {
                        report.email_report_type === appConstants.emailReportType[1].value &&
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" component="h5">
                                        Email Settings
                                    </Typography>
                                    <Typography variant="body2" component="h5">
                                        Configure the email settings here
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2}>
                                        <Grid item style={{ display: 'flex' }} xs={6}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={report?.email_setting?.limit_valid_invalid_record_count}
                                                        onChange={() => onChangeReportConfig('email_setting', { ...report?.email_setting, 'limit_valid_invalid_record_count': !report?.email_setting?.limit_valid_invalid_record_count })}
                                                        name="limit_valid_invalid_record_count"
                                                        color="primary"
                                                    />
                                                }
                                                label="Limit Valid and Invalid Record Count"
                                                disabled={report['attachment_details'].filter((elem) => elem.column_value === 'Rule Name').length === 0}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <NumberInput
                                                name="record_count"
                                                label="Record Count"
                                                fullWidth
                                                onChange={(event) => onChangeReportConfig('email_setting', { ...report?.email_setting, 'record_count': event.target.value })}
                                                value={report?.email_setting?.record_count}
                                                disabled={!report?.email_setting?.limit_valid_invalid_record_count}
                                                validators={report?.email_setting?.limit_valid_invalid_record_count ? ['required'] : []}
                                                errorMessages={report?.email_setting?.limit_valid_invalid_record_count ? ['Record Count is required'] : []}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={report?.email_setting?.limit_by_custom_value}
                                                        onChange={() => onChangeReportConfig('email_setting', { ...report?.email_setting, 'limit_by_custom_value': !report?.email_setting?.limit_by_custom_value })}
                                                        name="limit_by_custom_value"
                                                        color="primary"
                                                    />
                                                }
                                                label="Limit Data by Custom Value"
                                                disabled={customFlags && customFlags.length === 0}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <AutoCompleteCheckBox
                                                selectionData={report?.email_setting?.custom_value ? report?.email_setting?.custom_value : []}
                                                availableList={sortTable(customFlags ? customFlags : [], "asc", "name")}
                                                onChange={(value) => onChangeReportConfig("email_setting_custom_value", value)}
                                                // onSearch={(value) => searchOptions("recipients", value)}
                                                fullWidth
                                                label="Select Custom Values"
                                                noMargin
                                                isHideSelectedAllCount
                                                disabled={!report?.email_setting?.limit_by_custom_value}
                                                error={report.email_setting?.custom_value?.length === 0 && isSubmitted}
                                                helperText={report.email_setting?.custom_value?.length === 0 && isSubmitted ? 'Custom Condition is required' : ''}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid container alignItems="center" className={classes.marginTop20}>
                        {
                            isChanged &&
                            <Button variant="contained" color="primary" type="submit" className={classNames(classes.actionButtons, classes.submitBtn)}>
                                Save
                                {/* {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />} */}
                            </Button>
                        }
                        <Button onClick={() => onClose()} className={classNames(classes.cancelButton, classes.actionButtons)}>
                            Cancel
                        </Button>
                    </Grid>
                    {
                        customValue &&
                        <Dialog open onClose={() => setCustomValue(null)} maxWidth="lg">
                            <Grid container style={{ backgroundColor: '#f4f6f9', alignItems: 'center', padding: 8, justifyContent: 'space-between', minWidth: '60vw' }}>
                                <Grid item>
                                    Custom Value
                                </Grid>
                                <Grid item>
                                    <Button
                                        className={classes.btnAction}
                                        onClick={
                                            () => {
                                                report.attachment_details[customValue.index]['custom_value'].push(JSON.parse(JSON.stringify(defaultCustomValue)));
                                                setReport({ ...report });
                                            }
                                        }
                                    >
                                        {'Add Rule'}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Table stickyHeader>
                                <TableBody className={classes.propListPageTableBody}>
                                    {
                                        report?.attachment_details[customValue.index]['custom_value'].map((attribute, index) => {
                                            return (
                                                <TableRow key={`attributeProperty${index}`}>
                                                    <TableCell>
                                                        <TextBox
                                                            label=""
                                                            value={"DQ Score"}
                                                            name="column_name"
                                                            fullWidth
                                                            onChange={(event) => onChangeReportConfig('custom_value', { name: event.target.name, value: event.target.value, index })}
                                                            disabled
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextBox
                                                            label=""
                                                            name="operator"
                                                            fullWidth
                                                            select
                                                            onChange={(event) => onChangeReportConfig("custom_value", { name: event.target.name, value: event.target.value, index })}
                                                            value={attribute.operator ? attribute.operator : ''}
                                                            className={classes.inlineTxt}
                                                            // disabled={((report.name && report.name.toLowerCase() === "admin") || (userRole.name && userRole.name.toLowerCase() !== "admin"))}
                                                            SelectProps={
                                                                {
                                                                    MenuProps: {
                                                                        anchorOrigin: {
                                                                            vertical: "bottom",
                                                                            horizontal: "center"
                                                                        },
                                                                        transformOrigin: {
                                                                            vertical: "top",
                                                                            horizontal: "center"
                                                                        },
                                                                        getContentAnchorEl: null
                                                                    }
                                                                    // IconComponent: () => DropDownIcon()
                                                                }
                                                            }
                                                        >
                                                            {
                                                                ['EQUAL TO', 'LESS THAN', 'GREATER THAN', 'LESS THAN OR EQUAL TO', 'GREATER THAN OR EQUAL TO', 'NOT EQUAL TO'].map((option, index) => (
                                                                    <MenuItem key={`menuProperty_Options_${index}`} value={option} className={classes.menuItem}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))
                                                            }
                                                        </TextBox>
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextBox
                                                            label=""
                                                            value={attribute?.value ? attribute?.value : ""}
                                                            name="value"
                                                            fullWidth
                                                            onChange={(event) => onChangeReportConfig('custom_value', { name: event.target.name, value: event.target.value, index })}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        THEN PRINT
                                                    </TableCell>
                                                    <TableCell>
                                                        <TextBox
                                                            label=""
                                                            value={attribute?.flag ? attribute?.flag : ""}
                                                            name="flag"
                                                            fullWidth
                                                            onChange={(event) => onChangeReportConfig('custom_value', { name: event.target.name, value: event.target.value, index })}
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        <Grid item align="center">
                                                            <ToolTipComponent title={"Delete Column"} arrow>
                                                                <IconButton
                                                                    className={classes.referenceRoleIcon}
                                                                    onClick={
                                                                        () => {
                                                                            setSelectedCustomAttachment({ ...attribute, column_name: report?.attachment_details[customValue.index]['column_name'], index });
                                                                        }
                                                                    }
                                                                >
                                                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 32 32">
                                                                        <g id="Delete">
                                                                            <path fill={theme.palette.primary.main}
                                                                                stroke={theme.palette.primary.main}
                                                                                strokeWidth="0.92"
                                                                                strokeMiterlimit="10"
                                                                                d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                                                            <path fill={theme.palette.primary.main}
                                                                                stroke={theme.palette.primary.main}
                                                                                strokeWidth="0.92"
                                                                                strokeMiterlimit="10"
                                                                                d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                                        </g>
                                                                    </svg>
                                                                </IconButton>
                                                            </ToolTipComponent>
                                                        </Grid>
                                                    </TableCell>
                                                </TableRow>

                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </Dialog>
                    }
                </Grid>
            }
            {
                selectedAttachment && <AlertDialog
                    title="Delete Attachment Column"
                    message={`Are you sure you want to delete the attachment column - ${selectedAttachment ? selectedAttachment.column_name : ''}?`}
                    okButtonText="OK"
                    cancelButtonText="Cancel"
                    show={Boolean(selectedAttachment)}
                    onClickOK={
                        () => {
                            report.attachment_details.splice(selectedAttachment.index, 1);
                            if (selectedAttachment?.column_value === 'Custom') {
                                report.email_setting = { ...report.email_setting, limit_by_custom_value: false, custom_value: [] };
                                setCustomFlags([]);
                            } else if (selectedAttachment?.column_value === 'Rule Name') {
                                report.email_setting = { ...report.email_setting, limit_valid_invalid_record_count: false, record_count: '' };
                            }
                            setReport({ ...report });
                            setSelectedAttachment(null);
                            setChanged(true);
                        }
                    }
                    onClickCancel={() => setSelectedAttachment(null)}
                />
            }
            {
                selectedCustomAttachment && <AlertDialog
                    title="Delete Custom Rule Column"
                    message={`Are you sure you want to delete the custom rule column - ${selectedCustomAttachment ? selectedCustomAttachment.column_name : ''}?`}
                    okButtonText="OK"
                    cancelButtonText="Cancel"
                    show={Boolean(selectedCustomAttachment)}
                    onClickOK={
                        () => {
                            report.attachment_details[customValue.index]['custom_value'].splice(selectedCustomAttachment.index, 1);
                            const custom_attachment_res = report.attachment_details.filter((elem) => elem.column_value === 'Custom');
                            if (custom_attachment_res && custom_attachment_res.length > 0) {
                                const customValueCopy = formatCustomFlags(custom_attachment_res, report);
                                report.email_setting = { ...report.email_setting, custom_value: [] };
                                setCustomFlags(customValueCopy);
                            }
                            setReport({ ...report });
                            setSelectedCustomAttachment(null);
                            setChanged(true);
                        }
                    }
                    onClickCancel={() => setSelectedCustomAttachment(null)}
                />
            }
            {
                schedule.open &&
                <Scheduler
                    scheduleProps={schedule}
                    onClose={() => setSchedule({ open: false, anchorElement: null, reportId: null })}
                    createScheduler={(schedule) => { createScheduler(schedule); }}
                    scheduleData={scheduleData}
                    showRemoveSchedule={showRemoveSchedule}
                    isExistingSchedule={isExistingSchedule}
                    onRemoveSchedule={() => { onRemoveSchedule(); }}
                    setExistingSchedule={(val) => { setExistingSchedule(val); }}
                    setShowRemoveSchedule={(val) => { setShowRemoveSchedule(val); }}
                    setDatasetSchedule={(val) => setScheduleData(val)}
                />
            }
            {isLoading && <Loader />}
        </ValidatorForm>
    );
};

ReportConfiguration.propTypes = {
    classes: PropTypes.object,
    reportId: PropTypes.number,
    onClose: PropTypes.func,
    organizationId: PropTypes.number
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ReportConfiguration);