import React, { useEffect } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../../layouts/Styles.jsx';
import ChartStyles from '../ChartStyles.jsx';
import { appConstants } from '../../../constants/appConstants.js';
// import Group2138 from '../../../assets/images/Group_2138.svg';
import Group2136 from '../../../assets/images/Group_2136.svg';
import Group2138 from '../../../assets/images/Group_2138.svg';

const KMeans = (props) => {

    const { classes, chartData, attribute, onFilterChange, theme, discardEdit, attributeChange, edited } = props;

    useEffect(() => {
        renderChart();
    });

    const renderChart = () => {
        if (chartData && Object.keys(chartData).length > 0 && chartData.status !== "Failed" && (attributeChange || edited)) {
            if (edited) {
                discardEdit();
            }
            const upperLimit = chartData.input_params.upper_limit;
            const lowerLimit = chartData.input_params.lower_limit;
            const centers = [];
            const barWidth = 150;
            for (const value of chartData.centers) {
                centers.push({ x: value[0], y: value[0] });
            }
            if (chartData && typeof(chartData.valid_data) === "object") {
                chartData.valid_data = [];
            }
            if (chartData && typeof(chartData.outliers) === "object") {
                chartData.outliers = [];
            }
            const selectionData = [...chartData.valid_data, ...chartData.outliers];
            const kMeansData = selectionData.map((data, index) => {
                const scatterData = {
                    x: parseFloat(data[attribute]),
                    y: parseFloat(data[attribute]),
                    isValid: !data.outlier,
                    ...data
                };
                return scatterData;
            });

            const margin = { top: 40, right: 20, bottom: 50, left: 40 };
            let width = 960 - margin.left - margin.right;
            const height = 300;
            d3.select(".kmeans-chart > *").remove();
            d3.select(".kmeans-chart svg").remove();
            d3.select(".kmeans-chart .tooltip").remove();

            const svg = d3.select(".kmeans-chart").append("svg")
                .attr("width", "75%")
                .style("margin", "0 0")
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("width", (_, i, nodes) => {
                    width = nodes[i].parentNode.clientWidth;
                    return width;
                })
                .attr("height", height + margin.bottom)
                .attr("transform", "translate(" + (margin.left + 40) + "," + (margin.top / 2) + ")");

            const minX = d3.min(kMeansData, (d) => { return d.x; });
            const maxX = d3.max(kMeansData, (d) => { return d.x; });

            const x = d3.scaleLinear()
                .range([0, width - (margin.left + margin.right + 150)])
                .nice();

            const y = d3.scaleLinear().range([height, 0]).nice();

            const xScale = d3.axisBottom(x).ticks(10);
            const yScale = d3.axisLeft(y).ticks(10);
            const color = d3.scaleOrdinal(d3.schemeCategory10);

            let minY = d3.min(kMeansData, (d) => { return d.y; });
            if (lowerLimit < minY) {
                minY = lowerLimit;
            }
            let maxY = d3.max(kMeansData, (d) => { return d.y; });
            if (upperLimit > maxY) {
                maxY = upperLimit;
            }

            x.domain([minX, (maxX + (maxX / 10))]).nice();
            y.domain([minY, (maxY + (maxY / 10))]).nice();

            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .attr("color", theme.palette.background.default)
                .attr('class', 'axis-x')
                .call(xScale);

            // add the y Axis
            svg.append("g")
                .attr("color", theme.palette.background.default)
                .attr('class', 'axis-y')
                .call(yScale);

            const div = d3.select(".kmeans-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0);

            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
            svg.selectAll('.axis-y .tick-text').text('').append('tspan').text((d) => { return d; });

            const brush = d3.brush().extent([[0, 0], [width, height]]).on("end", zoomEnd);
            let idleTimeout = null;
            const idleDelay = 350;

            svg.append("defs").append("clipPath")
                .attr("id", "clip")
                .append("rect")
                .attr("width", width)
                .attr("height", height)
                .attr("x", 0)
                .attr("y", 0);

            const scatter = svg.append("g")
                .attr("id", "kmeans")
                .attr("clip-path", "url(#clip)");

            scatter.append("g")
                .attr("class", "brush")
                .call(brush);

            function zoomEnd() {
                const s = d3.event.selection;
                if (!s) {
                    if (!idleTimeout) { return idleTimeout = setTimeout(idled, idleDelay); }
                    const kmeansMinX = d3.min(kMeansData, (d) => { return d.x; });
                    const kmeansMaxX = d3.max(kMeansData, (d) => { return d.x; });

                    let minY = d3.min(kMeansData, (d) => { return d.y; });
                    if (lowerLimit < minY) {
                        minY = lowerLimit;
                    }
                    let maxY = d3.max(kMeansData, (d) => { return d.y; });
                    if (upperLimit > maxY) {
                        maxY = upperLimit;
                    }
                    x.domain([kmeansMinX, (kmeansMaxX + (kmeansMaxX / 10))]).range([0, width - (margin.left + margin.right + 150)]).nice();
                    y.domain([minY, (maxY + (maxY / 10))]).nice();
                } else {
                    x.domain([s[0][0], s[1][0]].map(x.invert, x)).range([0, width - (margin.left + margin.right + 150)]);
                    y.domain([s[1][1], s[0][1]].map(y.invert, y));
                    scatter.select(".brush").call(brush.move, null);
                }
                loadLimit();
                zoom();
            }

            function idled() {
                idleTimeout = null;
            }

            function zoom() {
                const t = scatter.transition().duration(750);
                svg.select(".axis-x").transition(t).call(xScale);
                svg.select(".axis-y").transition(t).call(yScale);
                svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
                scatter.selectAll("circle").transition(t)
                    .attr("cx", (d) => { return x(d.x); })
                    .attr("cy", (d) => { return y(d.y); });
            }

            scatter.selectAll(".dot")
                .data(kMeansData)
                .enter().append("circle")
                .attr("class", "dot")
                .attr("r", 5)
                .attr("cx", (d) => { return x(d.x); })
                .attr("cy", (d) => { return y(d.y); })
                .style("fill-opacity", 0.6)
                .on('mouseover', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", 1);
                    // Show Tooltip
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html("Value: " + Math.round(d.x))
                        .style("left", (d3.mouse(d3.select(".kmeans-chart").node())[0]) - 40 + "px")
                        .style("top", (d3.mouse(d3.select(".kmeans-chart").node())[1]) - 50 + "px")
                        .style("opacity", 0.9);
                })
                .on('mouseout', (_, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", 1);
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .on('click', (d) => {
                    onFilterChange({ value: d.y, filterType: appConstants.QueryFilterTypes[0] });
                })
                .style("fill", (d) => { return color(d.cluster); });
            scatter.selectAll(".center")
                .data(centers)
                .enter().append("circle")
                .attr("class", "center")
                .attr("r", 6)
                .style("stroke", "#222")
                .style("fill-opacity", 0.8)
                .attr("cx", (d) => { return x(d.x) - 2.5; })
                .attr("cy", (d) => { return y(d.y) - 2.5; })
                .style("fill", (d, i) => { return color(i); })
                .on('mouseover', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", 1);
                    // Show Tooltip
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html("Value: " + Math.round(d.x))
                        .style("left", (d3.mouse(d3.select(".kmeans-chart").node())[0]) - 40 + "px")
                        .style("top", (d3.mouse(d3.select(".kmeans-chart").node())[1]) - 50 + "px")
                        .style("opacity", 0.9);
                })
                .on('mouseout', (_, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", 1);
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .on('click', (d) => {
                    onFilterChange({ value: d.y, filterType: appConstants.QueryFilterTypes[0] });
                });
            const lines = [{ labelName: 'Upper Limit', value: upperLimit }, { labelName: 'Lower Limit', value: lowerLimit }, { labelName: 'Clusters', value: centers.length }];

            const legendGroup = d3.select(".kmeans-chart").append("div")
                .attr("class", "anomaly-legend");

            legendGroup.selectAll(".legend-group")
                .data(lines)
                .enter()
                .append("p")
                .attr("class", "legend-text")
                .attr("alignment-baseline", "middle")
                .text((line) => {
                    return `${line.labelName} (${line.value})`;
                })
                .style("opacity", 0)
                .transition()
                .duration(1000)
                .style("opacity", 1);

            loadLimit();

            function loadLimit() {
                const lineWidth = (width - (margin.left + margin.right + barWidth));
                const limitTextX = (lineWidth + 30);
                svg.selectAll('.interval-line-text').remove();
                svg.selectAll('.interval-kmeans').remove();
                svg.append("text").attr("class", "interval-line-text").attr("y", () => {
                    if (upperLimit === lowerLimit) {
                        return y(lowerLimit) + 20;
                    }
                    return y(lowerLimit) + 2;
                }).attr("x", limitTextX).text("Lower Limit");
                svg.append("g").append("line")
                    .attr("class", "zero interval-kmeans")
                    .attr("x1", -30)
                    .attr("y1", y(lowerLimit))
                    .attr("x2", lineWidth)
                    .attr("y2", y(lowerLimit))
                    .style("stroke", theme.palette.secondary.main)
                    .attr("transform", "translate(30,0)");

                //upper limit
                const linesUpper = ['Upper Limit', 'Lower Limit'];
                const boxDescription = ['Computed upper limit of ', 'Computed Lower limit of '];
                svg.append("text").attr("class", "interval-line-text").attr("y", y(upperLimit) + 2).attr("x", limitTextX).text("Upper Limit");
                svg.append("g").append("line")
                    .attr("class", "zero interval-kmeans")
                    .attr("x1", -30)
                    .attr("y1", y(upperLimit))
                    .attr("x2", lineWidth)
                    .attr("y2", y(upperLimit))
                    .style("stroke", theme.palette.secondary.main)
                    .attr("transform", "translate(30,0)");
                // K Means
                d3.select(".kmeans-chart .anomaly-kmeans").remove();
                const legendBox = d3.select(".kmeans-chart").append("div")
                    .attr("class", "anomaly-kmeans")
                    .style("position", 'absoulte')
                    .append('div')
                    .attr("class", "anomaly-kmeans-row")
                    .style("position", 'relative');

                legendBox.selectAll(".anomaly-kmeans-row")
                    .data(linesUpper)
                    .enter()
                    .append("div")
                    .attr("class", "kmeans-column")
                    .style("left", (line) => {
                        if (line === "Lower Limit") {
                            if ((y(lowerLimit) - y(upperLimit)) < 75) {
                                return '-110px';
                            }
                                return '-140px';

                        } else if (line === "Upper Limit") {
                            return '-140px';
                        }
                    })
                    .style("top", (line) => {
                        if (line === "Lower Limit") {
                            if ((y(lowerLimit) - y(upperLimit)) < 75) {
                                return y(lowerLimit) - 18 + 'px';
                            }
                                return y(lowerLimit) - 95 + 'px';
                        } else if (line === "Upper Limit") {
                            return y(upperLimit) - 50 + 'px';
                        }
                    });

                legendBox.selectAll(".kmeans-column")
                    .data(linesUpper)
                    .append("b")
                    .text((lines) => {
                        return lines;
                    })
                    .attr("class", "legend-kmeans-bold");

                legendBox.selectAll(".kmeans-column")
                    .data(linesUpper)
                    .append("p")
                    .text((line, index) => {
                        if (line === "Upper Limit") {
                            return `${boxDescription[index]} ${upperLimit}`;
                        } else if (line === "Lower Limit") {
                            return `${boxDescription[index]} ${lowerLimit}`;

                        }
                    })
                    .attr("class", "legend-kmeans-text");
                legendBox.selectAll(".kmeans-column")
                    .data(linesUpper)
                    .append("img")
                    .attr('src', (line, index) => {
                        if (line === "Upper Limit") {
                            return Group2136;
                        } else if (line === "Lower Limit") {
                            if ((y(lowerLimit) - y(upperLimit)) < 75) {
                                return Group2138;
                            }
                                return Group2136;
                        }
                    })
                    .style('transform', (line, index) => {
                        if (line === "Upper Limit") {
                            return 'rotate(151deg)';
                        } else if (line === "Lower Limit") {
                            if ((y(lowerLimit) - y(upperLimit)) < 75) {
                                return 'rotate(41deg)';
                            }
                                return 'rotate(151deg)';
                        }
                    })

                    .attr("class", "legend-kmeans-img")
                    .attr("width", "80")
                    .attr("height", "45");
            }

        }

    };


    return (
        <Grid container className={classNames(classes.chartStyles, "kmeans-chart")} />
    );
};

KMeans.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.object,
    attribute: PropTypes.string,
    onFilterChange: PropTypes.func,
    theme: PropTypes.object,
    discardEdit: PropTypes.func,
    attributeChange: PropTypes.bool,
    edited: PropTypes.bool
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(KMeans);