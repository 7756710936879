import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, IconButton } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';
// import TextBox from '../TextBox/TextBox.jsx';
import Search from '../TextBox/Search.jsx';
import DatasourceListStyles from '../DatasourceList/DatasourceListStyles.jsx';
import AddIcon from '@material-ui/icons/Add';
import TooltipComponent from '../Tooltip/Tooltip.jsx';

const DataPreparationFilter = (props) => {
    const { classes, history, onChangeFilter, search } = props;

    return (

        <Grid container alignItems="center" justify="space-between" className={classNames(classes.headerContainer, classes.catHeaderContainer)}>
            <Grid item>
                <Typography variant="h2" component="h2">
                    Data Preparation
                </Typography>
            </Grid>
            <Grid item>
                <Grid container>
                    <Grid item className={classes.marginRight25}>
                        <Search
                            value={search ? search : ''}
                            placeholder="Search Here"
                            className={classes.dqSearchBox}
                            onChange={(value) => onChangeFilter(value)}
                        />
                    </Grid>
                    <TooltipComponent title="Add Data Preparation" placement="bottom" arrow>
                        <IconButton className={classes.addIconButton}
                            classes={
                                {
                                    root: classes.addIconButtonRoot
                                }
                            }
                            onClick={() => history.push('/createdatapreparation')}
                        >
                            <AddIcon className={classes.addIcon} />
                        </IconButton>
                    </TooltipComponent>
                </Grid>
            </Grid>
        </Grid>
    );
};

DataPreparationFilter.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    onChangeFilter: PropTypes.func,
    search: PropTypes.string
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DataPreparationFilter);