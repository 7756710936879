import React, { useState } from 'react';
import { Grid, withStyles, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import TabPanel from '../TabPanel/TabPanel.jsx';
import Styles from '../../layouts/Styles.jsx';
import SemanticGlossaryStyles from './SemanticGlossaryStyles.jsx';
import { appConstants } from '../../constants/appConstants';
import SemanticGlossaryDefinition from './SemanticGlossaryDefinition.jsx';
import SemanticFieldAssets from './SemanticFieldAssets.jsx';

const SemanticFieldDetail = (props) => {
    const { glossary, classes, search, attributes, dataType } = props;
    const [tabIndex, setTabIndex] = useState(0);
    return (
        <Grid className={classes.marginTop10}>
            <Grid>
                <Tabs
                    value={tabIndex}
                    onChange={(event, index) => setTabIndex(index)}
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="dashboard tabs"
                >
                    {
                        appConstants.fieldTabs.map((tab, index) => {
                            return (
                                <Tab key={index} label={tab} />
                            );
                        })
                    }
                </Tabs>
            </Grid>
            <Grid>
                <TabPanel value={tabIndex} index={0}>
                    {
                        tabIndex === 0 &&
                        <SemanticGlossaryDefinition glossaryId={glossary.id} dataType={dataType} />
                    }
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    {
                        tabIndex === 1 &&
                        <SemanticFieldAssets glossary={glossary} search={search} attributeList={attributes} />
                    }
                </TabPanel>
            </Grid>
        </Grid>
    );
};

SemanticFieldDetail.propTypes = {
    glossary: PropTypes.object,
    classes: PropTypes.object,
    search: PropTypes.string,
    attributes: PropTypes.array,
    dataType: PropTypes.string
};

export default withStyles((theme) => ({
    ...SemanticGlossaryStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SemanticFieldDetail);