import React from 'react';
import { Grid, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';

const StatisticsDetails = (props) => {
    const { statistics } = props;
    let statisticsKeys = [];
    if (statistics) {
        statisticsKeys = Object.keys(statistics);
    }
    return (
        <Grid>
            <Table>
                <TableBody>
                    {
                        statisticsKeys.map((key, index) => (
                            <TableRow key={'descriptive' + index}>
                                <TableCell>
                                    {key}
                                </TableCell>
                                <TableCell align="right">
                                    {statistics[key]}
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </Grid>
    );
};

StatisticsDetails.propTypes = {
    statistics: PropTypes.object
};
export default StatisticsDetails;