const DataPreparationjoinBuilderStyles = (theme) => ({
    root: {
        height: '100%!important'
    },
    cardContainer: {
        backgroundColor: theme.palette.background.container,
        padding: 10,
        marginBottom: 8
    },
    modelContainer: {
        minWidth: 550,
        padding: 18,
        position: 'fixed',
        right: 0,
        top: 110,
        boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
        zIndex: 999
    },
    modelSection: {
        overflowY: 'auto',
        maxHeight: 'calc(100vh - 300px)'
    }
});

export default DataPreparationjoinBuilderStyles;