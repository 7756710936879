const ChartStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    progressbackground: {
        position: 'absolute',
        color: '#c1d6c652'
    },
    progressContainer: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    },
    value: {
        position: 'absolute',
        fontWeight: 600,
        fontSize: 10
    },
    null: {
        color: theme.palette.grey.extraLight
    },
    color25: {
        color: theme.palette.chartColors.score25,
        '& .MuiLinearProgress-bar1Determinate': {
            backgroundColor: theme.palette.chartColors.score25
        }
    },
    color50: {
        color: theme.palette.chartColors.score50,
        '& .MuiLinearProgress-bar1Determinate': {
            backgroundColor: theme.palette.chartColors.score50
        }
    },
    color100: {
        color: theme.palette.chartColors.score100,
        '& .MuiLinearProgress-bar1Determinate': {
            backgroundColor: theme.palette.chartColors.score100
        }

    },
    nullText: {
        color: theme.palette.grey.light,
        position: 'absolute',
        fontSize: 13
    },
    linearContainer: {
        width: '65%',
        position: 'relative'
    },
    linearValue: {
        position: 'absolute',
        color: theme.palette.grey.darkTxt,
        fontWeight: 500,
        fontSize: 13,
        top: '-2px',
        right: '-50px'
    },
    linearNAValue: {
        position: 'absolute',
        color: theme.palette.grey.darkTxt,
        fontWeight: 500,
        fontSize: 13,
        top: '-2px'
    },
    stackChart: {
        margin: '0 auto'
    },
    spiderchart: {
        padding: '25px 0px 25px 0px !important',
        justifyContent: 'center'
    },
    distribution: {
        overflow: 'hidden'
    },
    chartStyles: {
        padding: '25px 30px 25px 30px',
        position: 'relative',
        '& .tick text': {
            fontSize: 12,
            fill: theme.palette.grey.darkTxt,
            fontWeight: 500,
            fontFamily: theme.palette.textFont
        },
        '& .path-fill': {
            opacity: 0
        },
        '& .axis-x': {
            color: '#dae7ed'
        },
        '& .axis-y': {
            color: '#dae7ed'
        },
        '& .spider-label': {
            fontSize: 13,
            fontFamily: theme.palette.textFont,
            textTransform: 'Capitalize'
        },
        '& .length-interval-line': {
            stroke: theme.palette.secondary.main,
            strokeDasharray: 5,
            strokeWidth: 1.5,
            fill: 'none',
            strokeLinecap: "butt"
        },
        '& .interval-line-text': {
            fontSize: 13,
            fill: theme.palette.grey.dark,
            textTransform: 'capitalize',
            fontWeight: 500
        },
        // Length Chart css
        '& .length-container': {
            position: 'absolute',
            width: '100%',
            margin: '0 auto',
            top: '0px'
        },
        '& .length-row': {
            fontSize: 13,
            fill: theme.palette.grey.dark,
            margin: '0 auto'
        },
        '& .length-column': {
            position: "absolute",
            width: '101px'
        },

        '& .interval-up-normal-text': {
            fontSize: 13,
            fill: theme.palette.grey.dark,
            textTransform: 'capitalize',
            margin: '0'
        },
        '& .interval-up-bold-text': {
            fontSize: 13,
            fill: theme.palette.grey.dark,
            textTransform: 'capitalize',
            margin: '0'
        },
        // NormalDistribution Css
        '& .normal-container': {
            position: 'absolute',
            width: '100%',
            margin: '0 auto',
            top: '0px'
        },
        '& .normal-row': {
            width: '75%',
            fontSize: 13,
            fill: theme.palette.grey.dark,
            margin: '0 auto'
        },
        '& .normal-image': {
            position: 'absolute',
            top: '20px'
        },
        '& .normal-column': {
            position: "absolute"
        },
        '& .normal-text-desc': {
            margin: "0",
            fontSize: 13,
            color: theme.palette.grey.dark
        },
        '& .normal-text-bold': {
            fontSize: 13,
            color: theme.palette.grey.dark
        },

        // BoxPlot Css
        '& .anomaly-box': {
            right: '54px',
            position: 'absolute'
        },
        '& .anomaly-box-row': {
            width: '100%',
            fontSize: 13,
            fill: theme.palette.grey.dark,
            margin: '0 auto'
        },
        '& .box-column': {
            position: 'relative'
        },
        '& .legend-box-text': {
            margin: "0",
            color: theme.palette.grey.dark
        },
        '& .legend-box-bold': {
            color: theme.palette.grey.dark
        },
        '& .legend-box-img': {
            position: 'absolute',
            left: '-87px',
            top: '8px'
        },
        // kmeans chart
        '& .anomaly-kmeans': {
            position: 'absolute',
            right: '54px'
        },
        '& .anomaly-kmeans-row': {
            width: '100%',
            fontSize: 13,
            fill: theme.palette.grey.dark,
            margin: '0 auto'
        },
        '& .kmeans-column': {
            position: 'relative'
        },
        '& .legend-kmeans-text': {
            margin: "0",
            color: theme.palette.grey.dark
        },
        '& .legend-kmeans-bold': {
            color: theme.palette.grey.dark
        },
        '& .legend-kmeans-img': {
            position: 'absolute',
            left: '-87px',
            top: '8px'
        },
        // Extreme Chart
        '& .extreme-column': {
            position: 'absolute',
            top: '-25px'
        },
        '& .extreme-container': {
            position: 'absolute',
            width: '100%'
        },
        '& .extreme-legend-bold': {
            color: theme.palette.grey.dark
        },
        '& .extreme-legend-text': {
            margin: "0",
            color: theme.palette.grey.dark
        },

        '& .tick-text': {
            fontSize: 12,
            fill: theme.palette.grey.default,
            fontWeight: 500,
            fontFamily: theme.palette.textFont
        },
        '& .legend-text': {
            fontSize: 12,
            fill: theme.palette.grey.default,
            textTransform: 'capitalize',
            fontWeight: 500,
            color: theme.palette.grey.default
        },
        '& .legend-text-value': {
            fontSize: 12,
            fill: theme.palette.grey.default,
            textTransform: 'capitalize',
            fontWeight: 500
        },
        '& div.tooltip': {
            position: 'absolute',
            textAlign: 'left',
            padding: 10,
            fontSize: 12,
            // borderRadius: 8,
            pointerEvents: 'none',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
            maxWidth: 200,
            overflow: 'hidden',
            background: theme.palette.background.paper,
            border: `1px solid rgba(26,54,126,0.125)`,
            boxShadow: '0 4px 25px 0 rgba(0,0,0,.1)',
            zIndex: 1,
            wordBreak: 'break-all'
        },
        '& .axisText': {
            fontSize: 12,
            background: "#fafbfc",
            marginBottom: '6.5px',
            height: 33,
            width: 157,
            padding: "6px 8px",
            lineHeight: '15px',
            textTransform: 'capitalize',
            borderLeft: `3px solid ${theme.palette.primary.main}`
        },
        '& .axisText:nth-child(even)': {
            borderLeft: `3px solid ${theme.palette.grey.light}`
        },
        '& .axisText.invalid': {
            borderLeft: `3px solid ${theme.palette.chartColors.inValid}`,
            cursor: 'pointer'
        },
        '& .axisText.valid': {
            borderLeft: `3px solid ${theme.palette.chartColors.valid}`
        },
        '& .yAxis': {
            position: 'absolute',
            top: 30
        },
        '& .pattern-legend': {
            position: 'absolute',
            right: 0,
            bottom: 0,
            display: 'flex',
            paddingBottom: 25
        },
        '& .pattern-legendgroup': {
            marginRight: 50,
            display: 'flex',
            alignItems: 'center',
            '& span': {
                fontSize: 13,
                fill: theme.palette.grey.default,
                textTransform: 'capitalize',
                fontWeight: 500
            }
        },
        '& .pattern-legendgroup:last-child': {
            marginRight: 100
        },
        '& .lengend-valid': {
            backgroundColor: theme.palette.chartColors.valid,
            marginRight: 10,
            width: 14,
            height: 10
        },
        '& .lengend-invalid': {
            backgroundColor: theme.palette.chartColors.inValid,
            marginRight: 10,
            width: 14,
            height: 10
        },
        '& .sensitivity1': {
            backgroundColor: "#ff8a67",
            marginRight: 10,
            width: 14,
            height: 10
        }, '& .sensitivity2': {
            backgroundColor: "#4cdde8",
            marginRight: 10,
            width: 14,
            height: 10
        }, '& .sensitivity3': {
            backgroundColor: "#eb6494",
            marginRight: 10,
            width: 14,
            height: 10
        },
        '& .yLabel': {
            position: 'absolute',
            // left: 10,
            transform: 'rotate(-90deg)',
            top: '40%'
        },
        '& .distributeYLabel': {
            position: 'absolute',
            left: '10%',
            transform: 'rotate(-90deg)',
            top: '40%'
        },
        '& .anomaly-legend': {
            position: 'absolute',
            right: 50,
            top: 40
        },
        '& .anomaly-legend p': {
            margin: '2px 0px'
        },
        '& .interval-line': {
            fill: 'none',
            stroke: theme.palette.secondary.main,
            strokeWidth: 1.5,
            strokeDasharray: 5
        },
        '& .active': {
            strokeWidth: 3,
            strokeDasharray: 0
        },
        '& .highlight': {
            stroke: "#80aabd",
            strokeDasharray: 0,
            strokeWidth: 3,
            cursor: "move"
        },
        '& .density-chart': {
            backgroundColor: '#eee'
        },
        '& .distribution-interval-line': {
            fill: 'none',
            stroke: theme.palette.secondary.main,
            strokeWidth: 1,
            strokeDasharray: 2
        },
        '& .distribution-interval-line.min': {
            stroke: "#F38080"
        },
        '& .distribution-interval-line.max': {
            stroke: "#F38080"
        },
        '& .distribution-interval-line.mean': {
            stroke: "#222"
        },
        '& .sentenceCase': {
            textTransform: 'capitalize'
        },
        '& .sunburst-legend': {
            fontSize: 11
        },

        // Step Chart Style
        '&.step-chart > svg': {
            marginTop: 5,
            overflow: 'visible'
        },
        '& .legend-container': {
            width: '100%',
            display: 'flex',
            // justifyContent: 'space-between',
            marginTop: 15,
            flexWrap: 'wrap',
            maxHeight: 45,
            paddingBottom: 5,
            overflowY: 'auto'
        },
        '& .legend-column': {
            display: 'flex',
            alignItems: 'center',
            marginRight: 10,
            maxWidth: 230
        },
        '& .legend-pattern': {
            marginRight: 8,
            width: 15,
            height: 8
        },
        '& .groupLine': {
            stroke: '#dae7ed',
            strokeWidth: 1
        },
        '& .heatmap-legend-column': {
            textAlign: 'center'
        },
        '& .heatmap-pattern': {
            width: 50,
            height: 15
        },
        '& .heatmaplegend-container': {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: 15,
            flexWrap: 'wrap',
            maxHeight: 45,
            paddingBottom: 5,
            overflowY: 'auto'
        }
    },
    timelineChart: {
        padding: '0px !important',
        '& .dqscore-highlight': {
            backgroundColor: '#333',
            padding: 5
        }
    },
    chartcontainer: {
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 15
    },
    horizontainYAxis: {
        position: 'absolute'
    },
    rulesTableContainer: {
        maxHeight: 300,
        overflowY: 'auto',
        marginTop: 15
    },
    tableHeadCell: {
        fontSize: 13,
        color: '#222'
    },
    tableCell: {
        color: "#222",
        fontSize: 13,
        padding: "3px 16px",
        textTransform: "capitalize"
    },
    status: {
        backgroundColor: '#BBF2D7',
        color: '#000',
        fontSize: 13,
        height: 28,
        width: 75,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    failedStatus: {
        backgroundColor: '#FFCECE'
    },
    descriptionTxt: {
        width: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: 13
    },
    rangeChip: {
        backgroundColor: theme.palette.background.default,
        height: 24,
        textTransform: 'capitalize',
        marginRight: 8,
        position: 'absolute',
        top: 60,
        right: 20
    },
    statisticsContainer: {
        '& .MuiTabs-root': {
            width: '100%',
            height: 30
        },
        '& div': {
            width: '100%'
        },
        width: "100%",
        height: 420,
        '& .MuiTableCell-root': {
            padding: '12px 10px',
            fontSize: 13
        },
        '& .MuiTab-root': {
            textTransform: 'uppercase'
        },
        '& .MuiTableCell-body': {
            color: theme.palette.grey.darkTxt
        }
    },
    statisticsTable: {
        '& .MuiTableCell-body': {
            color: '#1D2C2F'
        }
    },
    groupchart: {
        padding: 0,
        '& .tick text': {
            fontSize: 12,
            fill: theme.palette.grey.darkTxt,
            fontWeight: 500,
            fontFamily: theme.palette.textFont
        }
    },
    sunburst: {
        padding: 0
    },
    barline: {
        padding: '20px 20px 0px 20px'
    },
    alertStatus: {
        width: '150px',
        margin: '0px auto',
        backgroundColor: '#fa9fb5',
        padding: '5px'
    },
    circlespiderChart: {
        padding: "30px 20px",
        textAlign: 'center'
    },
    marginTop10: {
        marginTop: '10px'
    },
    minWidth: {
        minWidth: '100px'
    },
    widgetContainer: {
        width: '100%',
        height: '100%'
    },
    chartContainer: {
        overflowY: 'auto'
    },
    overflowBox: {
        overflowX: 'auto'
    },
    noPadding: {
        padding: 0
    },
    ruleStatisticsAction: {
        whiteSpace: 'nowrap'
    },
    totalCountContainer: {
        alignContent: 'center',
        height: '100%',
        textAlign: 'center'
    }
});

export default ChartStyles;