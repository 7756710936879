import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Table, TableRow, TableHead, TableCell, Typography, TableBody, Switch, IconButton, TextField } from '@material-ui/core';
import PropertiesListViewStyles from './PropertiesListViewStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';
import DatasetStyles from '../../DatasetStyles.jsx';
import TileViewStyles from '../TileView/TileViewStyles.jsx';
import { updateListProperties, getDriftCustomRule } from '../../../../actions/datasetActions.js';
import MultiSelectChipInput from '../../../MultiSelectChipInput/MultiSelectChipInput.jsx';
import { useDispatch, useSelector } from 'react-redux';
import ToolTipComponent from '../../../Tooltip/Tooltip.jsx';
import AutoCompleteInput from '../../../AutoComplete/AutoCompleteInput.jsx';
import classNames from 'classnames';


const DriftPanel = (props) => {
    const { classes, properties, datasetId, isEditable, theme, updateProperties, isEditPermission } = props;
    const dispatch = useDispatch();
    const [selectedProperties, setSelectedProperties] = useState({});
    const rulesList = useSelector(({ dataset }) => dataset.driftCustomRules);

    const getAttributes = () => {
        const keys = Object.keys(properties);
        return keys.map((key) => { return { name: key, datatype: properties[key].datatype ? properties[key].datatype : "Text" }; });
    };

    const updateDriftType = (propertyName, value, index) => {
        selectedProperties[propertyName].behaviour_analysis[index].type = value;
        updateAttributeProperties(propertyName, "behaviour_analysis", selectedProperties[propertyName].behaviour_analysis, true);
    };

    const onUpdateBehaviourAnalysis = (propertyName, value, index) => {
        selectedProperties[propertyName].behaviour_analysis[index].attribute = value;
        updateAttributeProperties(propertyName, "behaviour_analysis", selectedProperties[propertyName].behaviour_analysis, true);
    };

    const onDeleteBehaviourAnalysis = (propertyName, index) => {
        selectedProperties[propertyName].behaviour_analysis.splice(index, 1);
        updateAttributeProperties(propertyName, "behaviour_analysis", selectedProperties[propertyName].behaviour_analysis, true);
    };

    const updateAttributeProperties = useCallback((attribute, property, value, update = false) => {
        const regex = /^([+-])?[0-9]*(.[0-9]*)?$/;
        if ((property === "min_length" || property === "max_length" || property === "min" || property === "max") && !regex.test(value)) {
            return false;
        }
        selectedProperties[attribute][property] = value;
        setSelectedProperties({ ...selectedProperties });
        if (update) {
            const attributeProperty = {
                id: selectedProperties[attribute] ? selectedProperties[attribute].id : 0,
                attribute,
                property,
                value
            };
            updateProperties(attributeProperty);
            dispatch(updateListProperties(datasetId, attributeProperty));
        }
    }, [datasetId, dispatch, selectedProperties, updateProperties]);

    const loadProperties = useCallback((properties) => {
        if (properties) {
            setSelectedProperties({ ...properties });
        }
    }, []);

    const getCustomDriftRules = useCallback(() => {
        dispatch(getDriftCustomRule());
    }, [dispatch]);

    useEffect(() => {
        if (datasetId && properties) {
            loadProperties(properties);
        }
        if (rulesList.length === 0) {
            getCustomDriftRules();
        }
    }, [datasetId, getCustomDriftRules, loadProperties, properties, rulesList.length]);

    const addDriftAttribute = (attribute) => {
        selectedProperties[attribute].behaviour_analysis.splice(0, 0, { "attribute": [], "type": "Avg" });
        setSelectedProperties({ ...selectedProperties });
    };

    return (
        <Grid container direction="column">
            <Table stickyHeader className={classes.propertyListPageTable}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Attribute'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Drift Analysis'}
                            </Typography>
                        </TableCell>
                        {/* <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Current Day Alerts Only'}
                            </Typography>
                        </TableCell> */}
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Behaviour Analysis'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Drift Rules'}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Object.keys(properties).map((attribute, index) => {
                            const property = properties[attribute];
                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        <ToolTipComponent title={property && property.name ? property.name : ''} arrow placement="bottom-start">
                                            <Typography>
                                                {property && property.name ? property.name : ''}
                                                {
                                                    property.is_drift &&
                                                    <ToolTipComponent title="Drift" placement="right" arrow>
                                                        <IconButton className={classes.iconButtonSmall}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17.474 30.574">
                                                                <g id="lightning-electric-energy" transform="translate(-205.617)">
                                                                    <path id="Path_2313" data-name="Path 2313" d="M206.709,30.381l9.786-9.831a.637.637,0,0,0-.452-1.086h-.78a.637.637,0,0,1-.5-1.032l5.189-6.543a.636.636,0,0,0-.5-1.032h-2.609a.637.637,0,0,1-.51-1.019l6.629-8.818A.637.637,0,0,0,222.452,0H211.968a.633.633,0,0,0-.57.354l-5.126,10.369a.638.638,0,0,0,.57.921h2.364a.638.638,0,0,1,.57.921l-3.5,7.091a.638.638,0,0,0,.57.921h2.227a.636.636,0,0,1,.573.914l-3.957,8.165A.636.636,0,0,0,206.709,30.381Z" fill="#49006a" />
                                                                </g>
                                                            </svg>
                                                        </IconButton>
                                                    </ToolTipComponent>
                                                }
                                            </Typography>
                                        </ToolTipComponent>
                                    </TableCell>
                                    <TableCell>
                                        <Switch checked={Boolean(property.is_drift)}
                                            color="secondary"
                                            name="is_drift"
                                            onChange={(event) => updateAttributeProperties(attribute, event.target.name, event.target.checked, true)}
                                            disabled={!isEditable && !isEditPermission} />
                                    </TableCell>
                                    {/* <TableCell>
                                        <Switch checked={Boolean(property.is_lastday_alerts)}
                                            color="secondary"
                                            name="is_lastday_alerts"
                                            onChange={(event) => updateAttributeProperties(attribute, event.target.name, event.target.checked, true)}
                                            disabled={!isEditable} />
                                    </TableCell> */}
                                    <TableCell>
                                        {
                                            property.is_drift &&
                                            <Grid container style={{ maxWidth: 400 }}>
                                                <Grid container alignItems="center" justify="space-between" >
                                                    <Typography>
                                                        {'Add Attributes'}
                                                    </Typography>
                                                    {
                                                        (isEditPermission || isEditable) &&
                                                        <ToolTipComponent title="Add Drift Attribute" arrow>
                                                            <IconButton onClick={() => addDriftAttribute(property.name)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                                    <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                                                        <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                                                            <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                                                            <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </IconButton>
                                                        </ToolTipComponent>
                                                    }
                                                </Grid>
                                                {
                                                    property.behaviour_analysis && property.behaviour_analysis.map((rule, index) => {
                                                        return (
                                                            <MultiSelectChipInput
                                                                key={`basicRuleList${index}`}
                                                                displayKey={'name'}
                                                                index={index}
                                                                rule={rule.attribute}
                                                                keyvalue={rule.type}
                                                                options={[...getAttributes()]}
                                                                onTypeUpdae={(...props) => updateDriftType(property.name, ...props)}
                                                                onUpdate={(...props) => onUpdateBehaviourAnalysis(property.name, ...props)}
                                                                onDelete={(...props) => onDeleteBehaviourAnalysis(property.name, ...props)}
                                                                disabled={!isEditable && !isEditPermission}
                                                                defaultAttributeType={property.datatype ? property.datatype.substring(0, 1) : "T"} />
                                                        );
                                                    })
                                                }
                                            </Grid>
                                        }
                                    </TableCell>
                                    <TableCell className={classNames(classes.hoverBgAutoComplete, classes.driftChip)}>
                                        <AutoCompleteInput
                                            name="attributes"
                                            popperWidth={300}
                                            options={rulesList}
                                            freeSolo
                                            value={property.custom_rules ? property.custom_rules : []}
                                            getOptionLabel={
                                                (option) => {
                                                    if (option && option.name) {
                                                        return option.name;
                                                    }
                                                    return option;
                                                }
                                            }
                                            renderInput={
                                                (params) =>
                                                    <TextField {...params}
                                                        // className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                                        placeholder="Select Rules"
                                                        margin="normal"
                                                    /*
                                                     * onBlur={() => onUpdate(attributes, index)}
                                                     * disabled={disabled}
                                                     */
                                                    />
                                            }
                                            tab="drift"
                                            onChange={(_, selectedValue) => updateAttributeProperties(attribute, "custom_rules", selectedValue, true)}
                                            openOnFocus
                                            disableCloseOnSelect
                                            disableClearable
                                            fullWidth
                                            multiple
                                            forcePopupIcon={false}
                                            noOptionsText={"No Attributes Found"}
                                            disabled={!isEditable && !isEditPermission}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }

                </TableBody>
            </Table>
        </Grid>
    );
};

DriftPanel.propTypes = {
    classes: PropTypes.object,
    properties: PropTypes.object,
    datasetId: PropTypes.string,
    isEditable: PropTypes.bool,
    theme: PropTypes.object,
    updateProperties: PropTypes.func,
    isEditPermission: PropTypes.bool
};

export default withStyles((theme) => ({
    ...PropertiesListViewStyles(theme),
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DriftPanel);