import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography, Button } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import { KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker } from '@material-ui/pickers';
import TextBox from '../TextBox/TextBox.jsx';
import classNames from 'classnames';
import MasterSearchPanelStyles from './MasterSearchPanelStyles.jsx';
import CheckboxComponent from '../ChecboxComponent/CheckboxComponent.jsx';
import { appConstants } from '../../constants/appConstants.js';
import { sortTable } from '../../helpers/appHelpers.js';

const MasterSearchPanel = (props) => {
    const { classes, tabIndex, searchTabIndex, modelAttributes, search, cancelSearch } = props;
    const [isExactMatch, setIsExactMatch] = useState(false);
    const maxParamsLimit = appConstants.MAX_SEARCH_PARAMS;
    const [isLimitReached, setIsLimitReached] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [isSearchDisabled, setSearchIsSearchDisabled] = useState(true);

    const onChange = useCallback((value, property, datatype) => {
        searchParams[property] = {
            key: property,
            value,
            datatype
        };
        if (!value || (value && value.toString().length === 0)) {
            delete searchParams[property];
        }
        const paramsCount = Object.keys(searchParams).length;
        setIsLimitReached(paramsCount >= maxParamsLimit);
        setSearchParams({ ...searchParams });
        setSearchIsSearchDisabled(paramsCount === 0);
    }, [maxParamsLimit, searchParams]);

    const checkIsDisabled = useCallback((attributeName) => {
        const isDisabled = (isLimitReached && ((searchParams && !(attributeName in searchParams)) || (searchParams && attributeName in searchParams && !searchParams[attributeName].value)));
        return isDisabled;
    }, [isLimitReached, searchParams]);

    const resetPanel = useCallback(() => {
        setSearchParams({});
        setIsLimitReached(false);
        setIsExactMatch(false);
    }, []);

    const onCancel = useCallback(() => {
        resetPanel();
        cancelSearch();
    }, [cancelSearch, resetPanel]);

    const renderTextBox = (attribute) => {
        return (
            (attribute.data_type === 'Text' && attribute.name.toLowerCase().search('address') !== -1) ?
                <TextBox
                    multiline
                    rows={2}
                    value={searchParams && searchParams[attribute.name] && searchParams[attribute.name].value ? searchParams[attribute.name].value : ''}
                    placeholder={attribute.name}
                    fullWidth
                    disabled={checkIsDisabled(attribute.name)}
                    autoComplete="new-password"
                    inputProps={
                        {
                            autoComplete: 'new-password'
                        }
                    }
                    onChange={(event) => onChange(event.target.value, attribute.name, attribute.data_type)}
                    className={classNames(classes.textareaContainer, classes.inputOutline, classes.validationTextBox, attribute.name)} />
                :
                <TextBox
                    value={searchParams && searchParams[attribute.name] && searchParams[attribute.name].value ? searchParams[attribute.name].value : ''}
                    placeholder={attribute.name}
                    fullWidth
                    disabled={checkIsDisabled(attribute.name)}
                    autoComplete="new-password"
                    inputProps={
                        {
                            autoComplete: 'new-password'
                        }
                    }
                    onChange={(event) => onChange(event.target.value, attribute.name, attribute.data_type)}
                    className={classNames(classes.inputOutline, classes.validationTextBox, attribute.name)} />

        );
    };
    useEffect(() => {
        if (tabIndex !== searchTabIndex) {
            resetPanel();
        }
    }, [resetPanel, searchTabIndex, tabIndex]);

    const renderPicker = useCallback((attribute) => {
        let picker = null;
        const datatype = attribute.data_type;
        switch (datatype) {
            case 'Date':
                picker = (
                    <KeyboardDatePicker
                        clearable
                        fullWidth
                        autoOk
                        placeholder={attribute.name}
                        className={classNames(classes.dateInputContainer, classes.textareaContainer, classes.inputOutline, classes.validationTextBox, attribute.name)}
                        format="YYYY-MM-DD"
                        variant="inline"
                        emptyLabel=""
                        autoComplete="nope"
                        value={searchParams && searchParams[attribute.name] && searchParams[attribute.name].value ? searchParams[attribute.name].value : null}
                        disabled={checkIsDisabled(attribute.name)}
                        onChange={(event) => { onChange(event ? event.format('YYYY-MM-DD') : null, attribute.name, attribute.data_type); }}
                    />
                );
                break;
            case 'DateTime':
            case 'DateTimeOffset':
                picker = (
                    <KeyboardDateTimePicker
                        clearable
                        fullWidth
                        autoOk
                        placeholder={attribute.name}
                        className={classNames(classes.dateInputContainer, classes.textareaContainer, classes.inputOutline, classes.validationTextBox, attribute.name)}
                        format="YYYY-MM-DD HH:mm"
                        variant="inline"
                        emptyLabel=""
                        autoComplete="nope"
                        value={searchParams && searchParams[attribute.name] && searchParams[attribute.name].value ? searchParams[attribute.name].value : null}
                        disabled={checkIsDisabled(attribute.name)}
                        onChange={(event) => { onChange(event ? event.format('YYYY-MM-DD HH:mm') : null, attribute.name, attribute.data_type); }}
                    />
                );
                break;
            case 'Time':
                picker = (
                    <KeyboardTimePicker
                        clearable
                        fullWidth
                        autoOk
                        placeholder={attribute.name}
                        className={classNames(classes.dateInputContainer, classes.textareaContainer, classes.inputOutline, classes.validationTextBox, attribute.name)}
                        format="HH:mm"
                        variant="inline"
                        emptyLabel=""
                        autoComplete="nope"
                        value={searchParams && searchParams[attribute.name] && searchParams[attribute.name].value ? searchParams[attribute.name].value : null}
                        disabled={checkIsDisabled(attribute.name)}
                        onChange={(event) => { onChange(event ? event.format('HH:mm') : null, attribute.name, attribute.data_type); }}
                    />
                );
                break;
            default:
                picker = null;
                break;
        }
        return picker;
    }, [checkIsDisabled, classes, onChange, searchParams]);

    return (
        <Grid className={classes.searchPanel}>
            <Grid continer justify="space-between" alignItems="center" direction="row" className={classes.masterSearchHeader}>
                <Typography variant="h6" component="h6">
                    {'Attributes'}
                </Typography>

                <CheckboxComponent checked={isExactMatch} onChange={() => setIsExactMatch(!isExactMatch)} checkboxLabel="Exact Match" />
            </Grid>
            <Grid justify="flex-start" direction="row" className={classes.modelContainer}>
                {
                    modelAttributes && sortTable(modelAttributes, 'asc', 'position').map((attribute, index) =>
                        <Grid item key={index} className={(classes.marginTop10)} xs={12} md={12} sm={12} lg={12}>
                            <Typography>
                                {attribute.name}
                            </Typography>
                            {
                                (attribute.data_type === 'Date' || attribute.data_type === 'DateTime'
                                    || attribute.data_type === 'DateTimeOffset' || attribute.data_type === 'Time') ?
                                    renderPicker(attribute)
                                    :
                                    renderTextBox(attribute)
                            }

                        </Grid>
                    )
                }
            </Grid>
            <Grid container justify="space-between" className={classes.btnContainer}>
                <Button disabled={isSearchDisabled} variant="contained" color="primary" onClick={() => search(searchParams, isExactMatch)}>
                    Search
                </Button>
                <Button variant="contained" onClick={() => onCancel()}>
                    Cancel
                </Button>
            </Grid>
        </Grid>
    );
};

MasterSearchPanel.propTypes = {
    classes: PropTypes.object,
    tabIndex: PropTypes.number,
    searchTabIndex: PropTypes.number,
    modelAttributes: PropTypes.array,
    search: PropTypes.func,
    cancelSearch: PropTypes.func
};

export default withStyles((theme) => ({
    ...MasterSearchPanelStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MasterSearchPanel);