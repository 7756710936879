/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import { Draggable } from 'react-beautiful-dnd';
import classNames from 'classnames';

/*
 * Reference: https://github.com/hmarggraff/react-material-ui-table-row-drag-and-drop/blob/master/src/index.js
 * Draggable TableRow - A Higher Order Component
 */
const DraggableTableRow = (id, index, rowsCount, draghandleContainerClasses) => (props) => {
    return (
        <Draggable key={id} draggableId={id} index={index}>
            {
                (provided, snapshot) => (
                    <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={provided.draggableProps.style}
                        {...props}
                        className={classNames(props.classNames, snapshot.isDragging ? 'dragTemplate' : '')}
                    >
                        <TableCell {...provided.dragHandleProps} data-rbd-drag-handle-draggable-id={rowsCount > 1 ? `${id}` : ''} className={classNames('dragHandlerContainer', draghandleContainerClasses ? draghandleContainerClasses : null)}>
                            <DragIndicatorIcon />
                        </TableCell>
                        {props.children}
                    </TableRow>
                )
            }
        </Draggable>
    );
};

export default DraggableTableRow;