import React, { useCallback, useState, useEffect } from 'react';
import { Grid, withStyles, Typography, IconButton, Button, ButtonGroup } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Styles from '../../../layouts/Styles.jsx';
import DatasetStyles from '../DatasetStyles.jsx';
import classNames from 'classnames';
import leftArrow from '../../../assets/images/left_arrow.svg';
import rightArrow from '../../../assets/images/right_arrow.svg';
import AttributeSelect from '../../AttributeSelect/AttributeSelect.jsx';

const AttributePropertiesHeader = (props) => {
  const { classes, view, carouselRef, onSearchKeyChange, onViewChange, setSelectedAttribute, changeSlider } = props;
  const [searchKey, setSearchKey] = useState('');
  const properties = useSelector(({ dataset }) => dataset.propertiesList);
  const currentIndex = useSelector(({ dataset }) => dataset.propertySliderIndex);
  const [totalCount, setTotalCount] = useState(0);

  const onChangeSlide = useCallback((type) => {
    if (type === 'next') {
      carouselRef.slickNext();
      changeSlider(currentIndex + 1);
    } else {
      carouselRef.slickPrev();
    }
  }, [carouselRef, changeSlider, currentIndex]);

  const toggleView = useCallback((view) => {
    if (onViewChange) {
      onViewChange(view);
    }
  }, [onViewChange]);


  const onSearchAttributes = useCallback((key) => {
    setSearchKey(key);
    onSearchKeyChange(key);
    setSelectedAttribute(key);
    if (carouselRef) {
      carouselRef.slickGoTo(0);
    }
    if (key !== "") {
      setTotalCount(1);
    } else {
      setTotalCount(properties ? properties.length : 0);
    }
  }, [onSearchKeyChange, setSelectedAttribute, carouselRef, properties]);


  const loadProperties = useCallback((properties) => {
    setTotalCount(properties ? properties.length : 0);
  }, []);


  useEffect(() => {
    loadProperties(properties);
  }, [loadProperties, properties, currentIndex]);


  useEffect(() => {
    const selectedattr = properties;
    setSelectedAttribute(selectedattr[currentIndex] ? selectedattr[currentIndex].name : "");
  }, [currentIndex, properties, setSelectedAttribute]);


  return (
    properties.length === 0 ? null :
      <Grid container direction="row" justify="space-between" alignItems="center">
        <Grid item>
          <Grid item className={classNames(classes.propertiesSearch, classes.includeSearchContainer, classes.dqSearchBox)}>
            <AttributeSelect
              placeholder="Select Attribute"
              value={searchKey ? searchKey : ''}
              onChange={(event) => onSearchAttributes(event.target.value)}
              options={properties}
              selectedValuePath="name"
              displayMemberPath="name"
              removeClass
              openSuggestionsOnClick
              name="Attribute"
              id="Attribute"
              hideIcon
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="center" alignItems="center" wrap="nowrap">

            {/* Item Navigation */}
            {
              view === 'tile' &&
              <Grid container direction="row" justify="center" alignItems="center" wrap="nowrap" className={classes.AttributeSliderNav}>
                <IconButton onClick={() => onChangeSlide('prev')} disabled={Boolean((currentIndex + 1) <= 1)} className={classes.navButton}>
                  <img src={leftArrow} alt="arrow left" />
                </IconButton>
                <Typography variant="body2" className={classes.sliderNavText} style={{ width: 'max-content' }}>
                  {`${totalCount > 0 ? currentIndex + 1 : 0} of ${totalCount}`}
                </Typography>
                <IconButton onClick={() => onChangeSlide('next')} disabled={Boolean(currentIndex + 1 === totalCount)} className={classes.navButton}>
                  <img src={rightArrow} alt="arrow right" />
                </IconButton>
              </Grid>
            }

            {/* Views */}
            <Grid container direction="row" justify="center" alignItems="center" wrap="nowrap" className={classes.marginLeft15}>
              <ButtonGroup className={classes.buttonGroup}>
                <Button variant="contained"
                  className={classNames(view === 'tile' ? 'active' : '')}
                  onClick={() => toggleView('tile')}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16" viewBox="0 0 25 16">
                    <g id="Group_1523" data-name="Group 1523" transform="translate(-1233.716 -173.46)">
                      <g id="Rectangle_618" data-name="Rectangle 618" transform="translate(1240.716 173.46)" fill="none" stroke="#afb2b3" strokeWidth="1.5">
                        <rect width="11" height="16" stroke="none" />
                        <rect x="0.75" y="0.75" width="9.5" height="14.5" fill="none" />
                      </g>
                      <g id="Rectangle_619" data-name="Rectangle 619" transform="translate(1253.716 176.46)" fill="none" stroke="#afb2b3" strokeWidth="1.5">
                        <rect width="5" height="11" stroke="none" />
                        <rect x="0.75" y="0.75" width="3.5" height="9.5" fill="none" />
                      </g>
                      <g id="Rectangle_620" data-name="Rectangle 620" transform="translate(1233.716 176.46)" fill="none" stroke="#afb2b3" strokeWidth="1.5">
                        <rect width="5" height="11" stroke="none" />
                        <rect x="0.75" y="0.75" width="3.5" height="9.5" fill="none" />
                      </g>
                    </g>
                  </svg>
                </Button>
                <Button variant="contained"
                  className={classNames(view === 'list' ? 'active' : '')}
                  onClick={() => toggleView('list')}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
                    <g id="Layer_2" data-name="Layer 2" transform="translate(-2.268 -4)">
                      <path id="Path_236" data-name="Path 236" d="M21.71,4H11.3a1.3,1.3,0,1,0,0,2.6H21.71a1.3,1.3,0,0,0,0-2.6Z" transform="translate(-2.743)" fill="#afb2b3" />
                      <circle id="Ellipse_88" data-name="Ellipse 88" cx="1" cy="1" r="1" transform="translate(2.268 4)" fill="#afb2b3" />
                      <path id="Path_237" data-name="Path 237" d="M21.71,14H11.3a1.3,1.3,0,1,0,0,2.6H21.71a1.3,1.3,0,0,0,0-2.6Z" transform="translate(-2.743 -3.495)" fill="#afb2b3" />
                      <circle id="Ellipse_89" data-name="Ellipse 89" cx="1" cy="1" r="1" transform="translate(2.268 11)" fill="#afb2b3" />
                      <path id="Path_238" data-name="Path 238" d="M21.71,24H11.3a1.3,1.3,0,1,0,0,2.6H21.71a1.3,1.3,0,0,0,0-2.6Z" transform="translate(-2.743 -6.989)" fill="#afb2b3" />
                      <ellipse id="Ellipse_90" data-name="Ellipse 90" cx="1" cy="1.5" rx="1" ry="1.5" transform="translate(2.268 17)" fill="#afb2b3" />
                    </g>
                  </svg>
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
};

AttributePropertiesHeader.propTypes = {
  classes: PropTypes.object,
  carouselRef: PropTypes.object,
  view: PropTypes.string,
  onSearchKeyChange: PropTypes.func,
  onViewChange: PropTypes.func,
  setSelectedAttribute: PropTypes.func,
  changeSlider: PropTypes.func
};

export default withStyles((theme) => ({
  ...DatasetStyles(theme),
  ...Styles(theme)
}), { withTheme: true })(AttributePropertiesHeader);