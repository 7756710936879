import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, withStyles, Typography, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import Search from '../TextBox/Search.jsx';
import Loader from '../Loaders/Loader.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import { createDomain, deleteDomain, getDomain, updateDomain, updateDomainImage } from '../../actions/datasourceActions.js';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';
import Dropzone from 'react-dropzone';
import Logo from '../../assets/images/sourceIcon.svg';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const Domains = (props) => {
    const { classes, theme, organizationId } = props;
    const [searchKey, setSearchKey] = useState('');
    const [selectedDomain, setSelectedDomain] = useState(null);
    const [domain] = useState({
        name: '',
        description: '',
        'image_url': ''
    });
    const [domains, setDomains] = useState([{ ...domain }]);
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);

    const getDomains = useCallback(() => {
        setLoading(true);
        dispatch(getDomain(organizationId)).then((response) => {
            setLoading(false);
            const domains = response && response.length !== 0 ? [...response] : [{ ...domain }];
            setDomains([...domains]);
        });
    }, [dispatch, domain, organizationId]);

    useEffect(() => {
        if (!organizationId) {
            return;
        }
        getDomains();
    }, [getDomains, organizationId]);

    const addDomain = useCallback(() => {
        if (domains.length > 0 && domains[0].name.length <= 0) {
            return;
        }
        domains.splice(0, 0, { ...domain });
        setDomains([...domains]);
    }, [domain, domains]);

    const onChangeSearch = useCallback((value) => {
        setSearchKey(value);
    }, []);

    const onChange = useCallback((index, property, value) => {
        const domain = domains[index];
        domain[property] = value;
        domains.splice(index, 1, { ...domain });
        setDomains([...domains]);
    }, [domains]);

    const deleteSelectedDomain = (domain, index) => {
        if (index === 0 && domain && domain.name.length <= 0) {
            return;
        }
        setSelectedDomain(domain);
    };

    const removeDomain = useCallback((domain) => {
        const index = domains.indexOf(domain);
        if (index > -1) {
            domains.splice(index, 1);
            setDomains([...domains]);
            setSelectedDomain(null);
            if (domain.id) {
                dispatch(deleteDomain(domain.id));
            }
        }
    }, [dispatch, domains]);

    const saveDomain = useCallback((domain, index) => {
        if (!domain || (domain && !domain.name)) {
            return;
        }
        domain['organization_id'] = organizationId;
        if (!domain.id) {
            dispatch(createDomain(domain)).then((response) => {
                if (!response) {
                    return;
                }
                const domain = domains[index];
                domain.id = response.id;
                domains.splice(index, 1, { ...domain });
                dispatch(updateDomain(domain)).then((res) => {
                    setDomains([...domains]);
                });
            });
        } else {
            dispatch(updateDomain(domain));
        }
    }, [dispatch, domains, organizationId]);

    const uploadImage = useCallback((files, index, domain) => {
        if (!files || (files && files.length <= 0)) {
            return;
        }
        const file = new FormData();
        file.append('domain_id', domain.id);
        file.append('organization_id', organizationId);
        file.append('file', files[0]);
        dispatch(updateDomainImage(file)).then((response) => {
            if (response) {
                onChange(index, 'image_url', response);
            }
        });
    }, [dispatch, onChange, organizationId]);

    const selectedDomains = searchKey !== "" ? domains.filter((item) => item.name && item.name.toLowerCase().includes(searchKey.toLowerCase())) : domains;
    return (
        <Grid>
            <Grid container justify="space-between">
                <Grid item className={classes.search} >
                    <Search value={searchKey}
                        placeholder="Search Domains"
                        onChange={(value) => onChangeSearch(value)}
                        className={classNames(classes.dqSearchBoxRefList)} />
                </Grid>

                <Grid item>
                    <ToolTipComponent title="Add Domain" arrow>
                        <IconButton className={classes.marginLeft10} onClick={() => addDomain()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                    <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                        <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                        <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                    </g>
                                </g>
                            </svg>
                        </IconButton>
                    </ToolTipComponent>
                </Grid>
            </Grid>
            <Grid className={classes.referenceSection}>
                <ValidatorForm onSubmit={() => { }}>
                    <Grid container spacing={3} className={classes.referenceAddSection}>
                        <Grid item xs={5} className={classes.referenceHeader}>
                            <Typography variant="body1" className={classes.referenceTxtHeader}>
                                Name
                            </Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.referenceHeader}>
                            <Typography variant="body1" className={classes.referenceTxtHeader}>
                                Description
                            </Typography>
                        </Grid>
                        <Grid item xs={2} align="center" className={classes.referenceHeader}>
                            <Typography variant="body1" className={classes.referenceTxtHeader}>
                                Actions
                            </Typography>
                        </Grid>
                    </Grid>
                    {
                        selectedDomains.map((domain, index) =>
                            <Grid key={index} spacing={3} container style={{ marginTop: 12 }}>
                                <Grid item xs={5} className={classes.domainBodyRow}>
                                    <Grid className={classes.marginRight15}>
                                        <Dropzone accept="image/*" onDrop={(acceptedFiles, _) => uploadImage(acceptedFiles, index, domain)}>
                                            {
                                                ({ getRootProps, getInputProps }) => (
                                                    <Grid {...getRootProps()} className={classes.domainImgContainer} style={{ backgroundImage: `url(${!domain.image_url ? Logo : domain.image_url})` }}>
                                                        <input {...getInputProps()} />
                                                    </Grid>
                                                )
                                            }
                                        </Dropzone>
                                    </Grid>
                                    <TextBox
                                        placeholder="Domain Name"
                                        value={domain.name ? domain.name : ''}
                                        className={classNames(classes.inlinetxt, classes.referenceInput)}
                                        name="name"
                                        onChange={(event) => onChange(index, event.target.name, event.target.value)}
                                        onBlur={() => saveDomain(domain, index)}
                                        fullWidth />
                                </Grid>
                                <Grid item xs={5} className={classes.domainBodyRow}>
                                    <TextBox
                                        placeholder="Description"
                                        value={domain.description ? domain.description : ''}
                                        className={classNames(classes.inlinetxt, classes.referenceInput)}
                                        name="description"
                                        onChange={(event) => onChange(index, event.target.name, event.target.value)}
                                        onBlur={() => saveDomain(domain, index)}
                                        fullWidth
                                        disabled={domain.name && domain.name.length <= 0} />
                                </Grid>
                                <Grid item xs={2} align="right" className={classes.domainActions}>
                                    <ToolTipComponent title="Delete" arrow>
                                        <IconButton className={classNames(classes.referenceIcon, classes.iconButtonSmall)} onClick={() => deleteSelectedDomain(domain, index)}>
                                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 32 32">
                                                <g id="Delete">
                                                    <path fill={theme.palette.primary.main}
                                                        stroke={theme.palette.primary.main}
                                                        strokeWidth="0.92"
                                                        strokeMiterlimit="10"
                                                        d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                                    <path fill={theme.palette.primary.main}
                                                        stroke={theme.palette.primary.main}
                                                        strokeWidth="0.92"
                                                        strokeMiterlimit="10"
                                                        d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                </g>
                                            </svg>
                                        </IconButton>
                                    </ToolTipComponent>
                                    {domain.isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                </Grid>
                            </Grid>

                        )
                    }
                </ValidatorForm>
                {
                    selectedDomains.length === 0 && !isLoading &&
                    <NoResultFound height="90%" />
                }
            </Grid>
            {isLoading && <Loader />}

            <AlertDialog title={"Delete Domain"}
                message="Are you sure to delete the selected domain?"
                okButtonText="OK"
                cancelButtonText="Cancel"
                show={selectedDomain !== null}
                onClickOK={
                    () => {
                        if (selectedDomain) {
                            removeDomain(selectedDomain);
                        } else {
                            setSelectedDomain(null);
                        }
                    }
                }
                onClickCancel={() => setSelectedDomain(null)} />
        </Grid >
    );

};

Domains.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    organizationId: PropTypes.number,
    type: PropTypes.string
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Domains);