import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Grid, withStyles, Typography, Avatar } from '@material-ui/core';
import { getRandomColor, getInitials, returnImage, getMentionName } from '../../helpers/appHelpers';
import Styles from '../../layouts/Styles.jsx';
import ActionItemStyles from './ActionItemStyles.jsx';
import { appConstants } from '../../constants/appConstants';
import moment from 'moment-timezone';
import { useSelector } from 'react-redux';
const ActionListDetailComment = (props) => {
    const { classes, comment } = props;
    const users = useSelector(({ account }) => account.users);
    const presentDate = moment().format('MMM DD YYYY');

    return (
        <Grid style={{ display: 'flex' }}>
            <Avatar
                style={
                    {
                        backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, comment.avatar)})`,
                        backgroundColor: getRandomColor(comment.first_name !== "" ? `${comment.first_name} ${comment.last_name}` : comment.email),
                        marginRight: 8
                    }
                }>
                {
                    !comment.avatar &&
                    <span>
                        {getInitials(comment.first_name !== "" ? `${comment.first_name} ${comment.last_name}` : comment.email)}
                    </span>
                }
            </Avatar>
            <Grid className={classes.actionitemlist}>
                <Typography className={classnames('description', classes.marginBottom10)} dangerouslySetInnerHTML={{ __html: getMentionName(comment.comment, users) }} />
                <Grid container justify="space-between">
                    <Typography>
                        {comment.first_name !== "" ? `${comment.first_name} ${comment.last_name}` : comment.email}
                    </Typography>
                    <Typography className={classes.actionTimeTxt}>
                        {
                            ((comment) && (moment(comment.created_date).format('MMM DD YYYY') === presentDate)) ?
                            moment(comment.created_date).calendar() :
                            moment(comment.created_date).format('MMM DD, YYYY HH:mm')
                        }
                    </Typography>
                </Grid>
            </Grid>
        </Grid >
    );

};

ActionListDetailComment.propTypes = {
    classes: PropTypes.object,
    comment: PropTypes.object
};


export default withStyles((theme) => ({
    ...ActionItemStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ActionListDetailComment);