import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextValidator } from 'react-material-ui-form-validator';

class NumberInput extends Component {
	onChange = (event) => {
		let pattern = this.props.integerOnly ? '^[+-]?[0-9]+$' : '^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$';
		if (this.props.pattern) {
			pattern = this.props.pattern;
		}
		const regex = new RegExp(pattern);
		if (event.target.value === '' || regex.test(event.target.value)) {
			this.props.onChange(event);
		}
	}

	render() {
		return (
			<TextValidator autoComplete={"off"} {...this.props} onChange={(event) => this.onChange(event)} />
		);
	}
}

NumberInput.propTypes = {
	onChange: PropTypes.func,
	integerOnly: PropTypes.bool,
	pattern: PropTypes.string
};

export default NumberInput;