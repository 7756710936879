import { settingActions } from '../constants/actionTypes/settingActionTypes';

const defaultTheme = {
    logo: "",
    banner: "",
    "login_copyright_text": "©2020 DQLabs, Inc. All rights reserved.",
    "footer_copyright_text": "©2020 DQLabs, Inc. All rights reserved.",
    "primary_color": "#F38080",
    "secondary_color": "#3abeff",
    darkTxt: "#1D2C2F",
    default: "#646666",
    exteremeLight: "#f2f6f7",
    extraLight: "#e1e5e6",
    light: "#c8cbcc",
    main: "#afb2b3",
    "heading_font": "Poppins",
    "text_font": "Lato"
};
const theme = localStorage.getItem('theme') ? JSON.parse(localStorage.getItem('theme')) : { ...defaultTheme };
const initialState = {
    defaultTheme: { ...theme },
    theme: { ...theme },
    defaultConfig: {
        catalog: true,
        profile: true,
        curate: true,
        master: false,
        query: true,
        search: false,
        transform: true,
        conversation: true,
        lineage: false,
        semantics: true,
        dashboard: true,
        action_items: true,
        input_connectors: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        output_connectors: [],
        catalog_search: true,
        catalog_connect: true,
        profile_learning: true,
        profile_query: true,
        profile_rules: true,
        curate_learning: true,
        curate_basic: true,
        curate_advanced: true,
        curate_reference: true,
        master_create: true,
        master_api: true,
        master_search: true,
        master_person_modal: true,
        master_patient_modal: true,
        master_product_modal: true,
        master_vechile_modal: true,
        master_employee_modal: true,
        master_multi_modal: true,
        catalog_price: "",
        profile_price: "",
        master_price: "",
        curate_price: "",
        transform_price: "",
        query_price: "",
        conversation_price: "",
        search_price: "",
        custom_rule_notification: false
    },
    terms: [],
    profileRulesConfig: {},
    profileSetting: {},
    domainProperties: {
        domain_propagation: false
    },
    systemConfiguration: true,
    config: localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config', '{}')) : {},
    licenseConfig: localStorage.getItem('licenseConfig') ? JSON.parse(localStorage.getItem('licenseConfig', '{}')) : {},
    connectionStatus: true,
    isSystemLicense: false,
    driftRulesConfig: [],
    isSkipDuplicate: false,
    licenseInfo: {},
    dq_score_threshold: {},
    user_config: {
        profileRulesConfig: {},
        domainProperties: {},
        profileSetting: {},
        driftRulesConfig: [],
        isSkipDuplicate: false,
        dq_score_threshold: {},
        include_organization_domain_score: false
    },
    include_organization_domain_score: false
};

export const settingReducer = (state = initialState, action) => {
    if (!action || (action && !action.type)) {
        return state;
    }
    switch (action.type) {
        case settingActions.SET_THEME:
            if (action.responseData && Object.keys(action.responseData).length <= 0) {
                return state;
            }
            return {
                ...state,
                theme: action.responseData
            };
        case settingActions.GET_ORGANIZATION_CONFIG_REQUEST:
            return {
                ...state
            };
        case settingActions.GET_ORGANIZATION_CONFIG_SUCCESS:
            return {
                ...state,
                config: action.responseData
            };
        case settingActions.GET_ORGANIZATION_CONFIG_FAILURE:
            return {
                ...state,
                config: state.defaultConfig
            };
        case settingActions.UPDATE_MODULE_CONFIG_SUCCESS:
            return {
                ...state,
                config: { ...JSON.parse(action.responseData.module_config) }
            };
        case settingActions.GET_GLOSSARY_TERMS_REQUEST:
        case settingActions.GET_GLOSSARY_TERMS_FAILURE:
            return {
                ...state,
                terms: []
            };
        case settingActions.GET_GLOSSARY_TERMS_SUCCESS:
            return {
                ...state,
                terms: action.responseData ? action.responseData : []
            };
        case settingActions.SET_PROPERTIES_SUCCESS:
            return {
                ...state,
                profileRulesConfig: { ...action.responseData.profileRules },
                domainProperties: { ...state.domainProperties, ...action.responseData.domainProperties },
                profileSetting: { ...action.responseData.profileConfig },
                driftRulesConfig: action.responseData && action.responseData.driftProperties ? Object.keys(action.responseData.driftProperties) : [],
                isSkipDuplicate: action.responseData && action.responseData.skip_duplicate_count,
                dq_score_threshold: action.responseData && action.responseData.dq_score_threshold ? action.responseData.dq_score_threshold : {},
                include_organization_domain_score: action.responseData && action.responseData.include_organization_domain_score ? action.responseData.include_organization_domain_score : false
            };
        case settingActions.SET_PROPERTIES_ORGANIZATION_SUCCESS:
            return {
                ...state,
                user_config: {
                    profileRulesConfig: { ...action.responseData.profileRules },
                    domainProperties: { ...action.responseData.domainProperties },
                    profileSetting: { ...action.responseData.profileConfig },
                    driftRulesConfig: action.responseData && action.responseData.driftProperties ? Object.keys(action.responseData.driftProperties) : [],
                    isSkipDuplicate: action.responseData && action.responseData.skip_duplicate_count,
                    dq_score_threshold: action.responseData && action.responseData.dq_score_threshold ? action.responseData.dq_score_threshold : {},
                    include_organization_domain_score: action.responseData && action.responseData.include_organization_domain_score ? action.responseData.include_organization_domain_score : false,
                    enable_event_based_scheduling: action.responseData && action.responseData.enable_event_based_scheduling ? action.responseData.enable_event_based_scheduling : false,
                    enable_custom_export_metrics: action.responseData && action.responseData.enable_custom_export_metrics ? action.responseData.enable_custom_export_metrics : false
                }
            };
        case settingActions.UPDATE_STORAGE_CONFIG_SUCCESS:
        case settingActions.GET_STORAGE_CONFIG_SUCCESS:
            return {
                ...state,
                systemConfiguration: action.responseData.systemConfiguration
            };
        case settingActions.GET_LICENSE_MODULES_SUCCESS:
            return {
                ...state,
                licenseConfig: action.responseData
            };
        case settingActions.GET_LICENSE_MODULES_REQUEST:
        case settingActions.GET_LICENSE_MODULES_FAILURE:
            return {
                ...state,
                licenseConfig: {}
            };
        case settingActions.GET_UPDATE_LICENSE_CONNECTION_STATUS_FAILURE:
            return {
                ...state,
                connectionStatus: false
            };
        case settingActions.GET_UPDATE_LICENSE_CONNECTION_STATUS_SUCCESS:
            return {
                ...state,
                connectionStatus: action.responseData
            };
        case settingActions.RESET_CONFIG:
            return {
                ...state,
                config: {},
                licenseConfig: {}
            };
        case settingActions.GET_SYSTEM_LICENSE_SUCCESS:
            return {
                ...state,
                isSystemLicense: action.responseData
            };
        case settingActions.GET_SYSTEM_LICENSE_REQUEST:
        case settingActions.GET_SYSTEM_LICENSE_FAILURE:
            return {
                ...state,
                isSystemLicense: false
            };
        case settingActions.RESET_ORGANIZATION_CONFIG:
            return {
                ...state,
                config: {}
            };
        case settingActions.GET_LICENSE_INFO_SUCCESS:
            return {
                ...state,
                licenseInfo: action.responseData
            };
        case settingActions.UPDATE_ORGANIZATION_NOTIFICATION_CONFIG_SUCCESS:
            return {
                ...state,
                config: {
                    ...state.config,
                    ...action.responseData
                }
            };
        default:
            return state;
    }
};