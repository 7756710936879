const DataSourceTileStyles = (theme) => ({
    root: {
        // width: 219,
        height: 162,
        marginLeft: 2
    },
    actionContainer: {
        height: '100%',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        paddingTop: 16,
        border: '2px solid #fff',
        '&:hover': {
            boxShadow: '0 4px 25px 0 rgba(0,0,0,.1)',
            backgroundColor: "white !important",
            border: `1px solid ${theme.palette.primary.dark}`
        }
    },
    logoContainer: {
        height: 38,
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
    },
    logo: {
        height: 38
    },
    cardContent: {
        height: '100%',
        padding: '10px 16px 16px 16px'
    },
    subtitle: {
        color: theme.palette.grey.light,
        marginTop: 8,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical'
    },
    tileCard: {
        boxShadow: "0px 0px 15px #5F60611A",
        '&:hover': {
            transform: 'scale(1.05)',
            transition: '0.5s ease'
        }
    }
});

export default DataSourceTileStyles;