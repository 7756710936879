/* eslint-disable multiline-comment-style */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, IconButton, withStyles, Tabs, Tab, Switch, Popover, TextField } from '@material-ui/core';
import classNames from 'classnames';
import Styles from '../../../../layouts/Styles.jsx';
import DatasetStyles from '../../DatasetStyles.jsx';
import TileViewStyles from './TileViewStyles.jsx';
import TextBox from '../../../TextBox/TextBox.jsx';
import QueryBuilder from '../../../RuleBuilder/QueryBuilder.jsx';
import { createRuleGroup, getFieldType, getOperators, getConditionOperators, createRule, getQueryString, getSelectedAttributes, getAttributeNames, removeFormatting, functionQueryString, isRuleGroup, getTableNames } from '../../../RuleBuilder/QueryBuilderUtil.jsx';
import { useSelector, useDispatch } from 'react-redux';
import Search from '../../../TextBox/Search.jsx';
import TabPanel from '../../../TabPanel/TabPanel.jsx';
import ReactQueryEditor from '../../../ReactQueryEditor/ReactQueryEditor.jsx';

import config from '../../../RuleBuilder/QueryBuilderConfig.jsx';
import { updateDatasetRules, updateRuleAttributes } from '../../../../actions/datasetActions.js';
import Localization from '../../../../constants/localization';
import ToolTipComponent from '../../../Tooltip/Tooltip.jsx';
import SettingsIcon from '@material-ui/icons/Settings';
import AutoCompleteInput from '../../../AutoComplete/AutoCompleteInput.jsx';
import { sortTable } from '../../../../helpers/appHelpers.js';

const defaultCustomNotification = {
    custom_rule: false,
    custom_rule_simple: false,
    custom_rule_detailed: false,
    send_as_link: false,
    previewcount: null,
    threshold: null
};

const AttributeRules = (props) => {
    const { classes, attribute, theme, isEditable, isDeletePermission, profileSetting, isEditPermission } = props;
    const dispatch = useDispatch();
    const datasetId = useSelector(({ dataset }) => dataset.selectedDatasetId);
    const selectedDataset = useSelector(({ dataset }) => dataset.selectedDataset);
    const [rules, setRules] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);
    const properties = useSelector(({ dataset }) => dataset.properties);
    const datasetRules = useSelector(({ dataset }) => dataset.rules);
    const referenceDatasets = useSelector(({ datasource }) => datasource.references);
    const selectedDatasource = useSelector(({ datasource }) => datasource.datasource);
    const userAttributes = useSelector(({ datasource }) => datasource.userAttributes);
    const lookup = useSelector(({ setting }) => setting?.user_config?.profileRulesConfig?.lookup);
    const [duplicateNameError, setduplicateNameError] = useState(false);
    const [fields, setFields] = useState([]);
    const [ruleGroup, setRuleGroup] = useState({
        ...createRuleGroup({
            defaultField: attribute.name,
            defaultFieldType: getFieldType(attribute.datatype),
            attributeType: attribute.datatype,
            connectionType: selectedDatasource?.type,
            isScan: selectedDatasource?.scan
        })
    });
    const [conditionalRuleGroup, setConditionalRuleGroup] = useState({
        ...createRuleGroup({
            defaultField: attribute.name,
            defaultFieldType: getFieldType(attribute.datatype),
            attributeType: attribute.datatype,
            connectionType: selectedDatasource?.type,
            isScan: selectedDatasource?.scan
        })
    });
    const [selectedRule, setSelectedRule] = useState({});
    const [selectedRuleIndex, setSelectedRuleIndex] = useState(0);
    const [search, setSearchString] = useState('');
    const [, enableSearch] = useState(false);
    const [inputChanged, setChanged] = useState(false);
    const [selectedAttribute, setSelectedAttribute] = useState(null);
    const containerRef = useRef();
    const [selectedRuleChanged, setSelectedRuleChanged] = useState(false);
    const [type, setType] = useState('');
    const [isAttributeLoad, setAttributeLoad] = useState(false);
    const controllerRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedReferenceDataset, setSelectedReferenceDataset] = useState({});
    // const configSettings = useSelector(({ setting }) => setting.config);
    const { enable_custom_export_metrics } = useSelector(({ setting }) => setting?.user_config);

    let ruleGroupTabs = ["Conditional", "Functions"];

    if (lookup && lookup.length > 0) {
        ruleGroupTabs = ["Conditional", "Functions", 'Lookup'];
    }


    const onSelectRule = useCallback((rule, index) => {
        setSelectedRule({ ...rule, custom_notification: rule.custom_notification ? rule.custom_notification : defaultCustomNotification });
        setSelectedRuleIndex(index);
        if (rule.isQueryFilter) {
            setTabIndex(1);
        } else if (rule.is_lookup_rule) {
            setTabIndex(2);
        } else {
            setTabIndex(0);
        }
        setSelectedRuleChanged(true);
        const attributefiltername = rules.filter((element) => element.name === rule.name);
        if (attributefiltername.length > 1) {
            setduplicateNameError(true);
        } else {
            setduplicateNameError(false);
        }
        setTimeout(() => {
            setSelectedRuleChanged(false);
        }, 100);
    }, [rules]);

    const addRule = useCallback(() => {
        setTabIndex(0);
        const rule = {
            attribute: attribute.name,
            priority: 1000,
            fieldtypes: [],
            params: [],
            name: '',
            description: '',
            query: '',
            conditionalQuery: '',
            type: 'profile',
            ruleGroup: {
                ...createRuleGroup({
                    defaultField: attribute.name,
                    defaultFieldType: getFieldType(attribute.datatype),
                    attributeType: attribute.datatype,
                    connectionType: selectedDatasource?.type,
                    isScan: selectedDatasource?.scan
                })
            },
            conditionalRuleGroup: {
                ...createRuleGroup({
                    connectionType: selectedDatasource?.type,
                    isScan: selectedDatasource?.scan
                })
            },
            selectedAttributes: [],
            polarity: 'positive',
            'fieldtypes_except': [],
            'is_active': true,
            'class_name': 'CustomRule',
            'metric_weight': 100,
            'category': ['Existance'],
            'allow_dqscore': true,
            'is_user_defined': true,
            'rule_type': 'custom',
            'is_complex_rule': false,
            'is_sql_editor': false,
            isQueryFilter: false,
            isInvalidQuery: true,
            custom_notification: defaultCustomNotification
        };
        rules.splice(0, 0, rule);
        setRules([...rules]);
        setSelectedRule({ ...rule });
        setduplicateNameError(false);
        setSelectedRuleIndex(0);
        setChanged(true);
    }, [attribute.datatype, attribute.name, rules, selectedDatasource?.scan, selectedDatasource?.type]);

    const prepareFields = useCallback((isComplexRule) => {
        const fields = [];
        if (isComplexRule) {
            for (const selectedAttribute of userAttributes) {
                fields.push({
                    ...selectedAttribute,
                    type: selectedAttribute.datatype ? selectedAttribute.datatype : 'Text',
                    attributeSchema: selectedAttribute.attribute_schema ? selectedAttribute.attribute_schema : '',
                    name: selectedAttribute.attribute_schema ? selectedAttribute.attribute_schema : ''
                });
            }
        } else {
            for (const attribute of Object.keys(properties)) {
                const selectedAttribute = properties[attribute];
                fields.push({
                    name: selectedAttribute.name,
                    type: selectedAttribute.datatype,
                    datatype: selectedAttribute.datatype ? selectedAttribute.datatype : 'Text',
                    attributeSchema: ''
                });
            }
        }
        return [...fields];
    }, [properties, userAttributes]);

    const prepareSelectedAttributes = useCallback((rule) => {
        const fields = prepareFields(rule.is_complex_rule);
        let ruleGroupAttributes = getSelectedAttributes(rule.ruleGroup);
        if (!rule.isQueryFilter) {
            const conditionalRuleGroupAttributes = getSelectedAttributes(rule.conditionalRuleGroup);
            ruleGroupAttributes = [...ruleGroupAttributes, ...conditionalRuleGroupAttributes];
        } else {
            ruleGroupAttributes = getAttributeNames(fields, rule.query);
        }
        ruleGroupAttributes = [...new Set(ruleGroupAttributes)];
        const attributes = [];
        const selectedDatasources = [];
        for (const attribute of ruleGroupAttributes) {
            const field = fields.find((p) => p.name.toLowerCase() === attribute.toLowerCase());
            if (!field) {
                continue;
            }
            const schema = field.attribute_schema ? field.attribute_schema : '';
            attributes.push({
                name: field.name,
                datatype: field.datatype,
                schema
            });
            if (rule.is_complex_rule || (!selectedDatasource?.scan && rule.is_sql_editor)) {
                const hasDatasource = selectedDatasources.find((p) => p.dataset_id === field.dataset_id);
                if (!hasDatasource) {
                    selectedDatasources.push({ ...field });
                }
            }
        }
        if (attributes.length === 0 && (rule.is_complex_rule || (!selectedDatasource?.scan && rule.is_sql_editor))) {
            const tables = getTableNames(fields, rule.query);
            for (const table of tables) {
                const field = fields.find((p) => p.dataset_schema.toLowerCase() === table.toLowerCase());
                if (!field) {
                    continue;
                }
                const hasDatasource = selectedDatasources.find((p) => p.dataset_id === field.dataset_id);
                if (!hasDatasource) {
                    selectedDatasources.push({ ...field });
                }
            }
        }
        rule.query = rule.isQueryFilter ? functionQueryString(rule.query, attributes) : getQueryString(rule.ruleGroup);
        if (!rule.isQueryFilter) {
            rule.conditionalQuery = getQueryString(rule.conditionalRuleGroup, true);
        }
        if (rule.query && rule.conditionalQuery) {
            if (selectedDatasources) {
                const joinedDatasets = [...new Set(selectedDatasources.filter((p) => p.dataset_name !== selectedDataset.name).map((p) => p.dataset_schema))];
                for (const dataset_schema of joinedDatasets) {
                    rule.query = rule.query.replace('<table_name>', dataset_schema, 1);
                }
            }
            const queryString = `select * from <table_name> ${rule.query} where ${rule.conditionalQuery}`;
            rule.query = queryString;
        }
        rule.selectedAttributes = [...attributes];
        rule.datasources = [...selectedDatasources];
    }, [prepareFields, selectedDataset.name, selectedDatasource?.scan]);

    const updateComplexRule = useCallback((ruleGroup, isComplexRule) => {
        ruleGroup.isComplexRule = isComplexRule;
        for (const rule of ruleGroup.rules) {
            if (isRuleGroup(rule)) {
                return updateComplexRule(ruleGroup, isComplexRule);
            }
            rule.isComplexRule = isComplexRule;
        }
        return ruleGroup;
    }, []);


    const updateRuleValue = useCallback((property, value) => {
        const rule = selectedRule;
        rule[property] = value;
        if (property === 'is_complex_rule') {
            rule.isComplexRule = value;
            if (rule.ruleGroup) {
                rule.ruleGroup = updateComplexRule(rule.ruleGroup, value);
            }
            if (rule.conditionalRuleGroup) {
                rule.conditionalRuleGroup = updateComplexRule(rule.conditionalRuleGroup, value);
            }
        }
        if (property === 'ruleGroup') {
            rule.query = getQueryString(value);
        }
        if (property === 'conditionalRuleGroup') {
            rule.conditionalQuery = getQueryString(value, true);
        }
        if (property === 'name') {
            setduplicateNameError(false);
        }
        const updatedRule = JSON.parse(JSON.stringify(rule));
        rules[selectedRuleIndex] = updatedRule;
        setRules([...rules]);
        setSelectedRule(updatedRule);
    }, [rules, selectedRule, selectedRuleIndex, updateComplexRule]);

    const prepareCustomRule = useCallback((attributeRule, fieldType) => {
        const operators = getOperators(fieldType);
        const conditionalOperators = getConditionOperators();
        const ruleParams = {
            defaultField: attribute.name,
            defaultFieldType: fieldType,
            attributeType: attribute.datatype,
            connectionType: selectedDatasource?.type,
            isScan: selectedDatasource?.scan
        };
        const customAttributeRule = createRule(ruleParams);
        const operator = operators.find((p) => p.label.toLowerCase() === (typeof (attributeRule.operator) === "object" ? attributeRule.operator.label.toLowerCase() : attributeRule.operator.toLowerCase()));
        if (operator) {
            customAttributeRule.operator = operator;
        }
        customAttributeRule.value = attributeRule.value;
        if (attributeRule.condition) {
            const conditionOperator = conditionalOperators.find((p) => p.label.toLowerCase() === attributeRule.condition.toLowerCase());
            if (conditionOperator) {
                customAttributeRule.conditionOperator = conditionOperator;
            }
            customAttributeRule.count = attributeRule.count;
        }
        if (attributeRule.conditionOperator) {
            customAttributeRule.conditionOperator = attributeRule.conditionOperator;
            customAttributeRule.count = attributeRule.count;
        }
        return customAttributeRule;
    }, [attribute.datatype, attribute.name, selectedDatasource?.scan, selectedDatasource?.type]);

    const prepareCustomRuleGroup = useCallback((attributeRule, fieldType) => {
        const processedRules = [];
        if (attributeRule.rules) {
            for (const rule of attributeRule.rules) {
                const ruleInfo = rule.connector ? prepareCustomRuleGroup(rule, fieldType) : prepareCustomRule(rule, fieldType);
                processedRules.push({ ...ruleInfo });
            }
        }
        attributeRule.rules = [...processedRules];
        return attributeRule;
    }, [prepareCustomRule]);


    const prepareRule = useCallback((customRule) => {
        if (customRule.ruleGroup) {
            return customRule;
        }
        const rule = customRule.params[0];
        const fieldType = getFieldType(attribute.datatype);

        const ruleGroup = createRuleGroup({
            defaultField: attribute.name,
            defaultFieldType: fieldType,
            attributeType: attribute.datatype,
            connectionType: selectedDatasource?.type,
            isScan: selectedDatasource?.scan
        });
        if (rule && rule.connector) {
            const connector = config.connectors.find((p) => p.name.toLowerCase() === rule.connector.toLowerCase());
            if (connector) {
                ruleGroup.connector = connector.value;
            }
        }

        const rules = rule && rule.rules ? rule.rules : [];
        const processedRules = [];
        for (const attributeRule of rules) {
            const ruleInfo = !attributeRule.connector ? prepareCustomRule(attributeRule, fieldType) : prepareCustomRuleGroup(attributeRule, fieldType);
            processedRules.push({ ...ruleInfo });
        }
        ruleGroup.rules = [...processedRules];
        customRule.ruleGroup = { ...ruleGroup };
        customRule.query = getQueryString(ruleGroup);
        return customRule;
    }, [attribute.datatype, attribute.name, prepareCustomRule, prepareCustomRuleGroup, selectedDatasource?.scan, selectedDatasource?.type]);

    const onChangeRuleType = useCallback((isComplexRule) => {
        const fields = prepareFields(isComplexRule);
        if (isComplexRule) {
            updateRuleValue('ruleGroup', {
                ...createRuleGroup({
                    isComplexRule,
                    isTriggerRule: true,
                    connectionType: selectedDatasource?.type,
                    isScan: selectedDatasource?.scan
                })
            });
            updateRuleValue('conditionalRuleGroup', {
                ...createRuleGroup({
                    isComplexRule,
                    connectionType: selectedDatasource?.type,
                    isScan: selectedDatasource?.scan
                })
            });
        } else {
            updateRuleValue('ruleGroup', {
                ...createRuleGroup({
                    defaultField: attribute.name,
                    defaultFieldType: getFieldType(attribute.datatype),
                    attributeType: attribute.datatype,
                    isComplexRule,
                    connectionType: selectedDatasource?.type,
                    isScan: selectedDatasource?.scan
                })
            });
            updateRuleValue('conditionalRuleGroup', {
                ...createRuleGroup({
                    isComplexRule,
                    connectionType: selectedDatasource?.type,
                    isScan: selectedDatasource?.scan
                })
            });
        }
        updateRuleValue('is_complex_rule', isComplexRule);
        setFields([...fields]);
    }, [attribute.datatype, attribute.name, prepareFields, selectedDatasource?.scan, selectedDatasource?.type, updateRuleValue]);

    const onChangeBuilderType = useCallback((value) => {
        updateRuleValue('ruleGroup', {
            ...createRuleGroup({
                defaultField: attribute.name,
                defaultFieldType: getFieldType(attribute.datatype),
                attributeType: attribute.datatype,
                connectionType: selectedDatasource?.type,
                isScan: selectedDatasource?.scan
            })
        });
        updateRuleValue('conditionalRuleGroup', {
            ...createRuleGroup({
                connectionType: selectedDatasource?.type,
                isScan: selectedDatasource?.scan
            })
        });
        updateRuleValue("is_sql_editor", value === 1);
        updateRuleValue('query', '');
        updateRuleValue("isQueryFilter", value === 1);
        setTabIndex(value);
        onChangeRuleType(true);
        updateRuleValue("is_complex_rule", value === 1);
        updateRuleValue("is_lookup_rule", value === 2);
        // if (value === 1 && selectedDatasource?.scan) {

    }, [attribute.datatype, attribute.name, onChangeRuleType, selectedDatasource?.scan, selectedDatasource?.type, updateRuleValue]);


    useEffect(() => {
        if (selectedRule && selectedRule.ruleGroup) {
            if (selectedRule.isQueryFilter) {
                selectedRule.query = removeFormatting(selectedRule.query ? selectedRule.query : "");
            }
            setRuleGroup(selectedRule.ruleGroup);
            setConditionalRuleGroup(selectedRule.conditionalRuleGroup ? selectedRule.conditionalRuleGroup : {
                ...createRuleGroup({
                    isComplexRule: selectedRule.selectedRule ? selectedRule.selectedRule : false,
                    connectionType: selectedDatasource?.type,
                    isScan: selectedDatasource?.scan
                })
            });
        }
    }, [selectedDatasource?.scan, selectedDatasource?.type, selectedRule]);

    useEffect(() => {
        const fields = prepareFields(selectedRule.is_complex_rule);
        setFields([...fields]);
    }, [prepareFields, selectedRule, setFields]);

    useEffect(() => {
        if (referenceDatasets && referenceDatasets.length > 0 && selectedRule?.reference_dataset) {
            setSelectedReferenceDataset(referenceDatasets.filter((elem) => elem?.file_url === selectedRule?.reference_dataset?.file_url)[0] || {});

        }
    }, [referenceDatasets, selectedRule]);

    const loadRules = useCallback((customRules, attribute) => {
        setRules([...customRules]);
        setSelectedAttribute({ ...attribute });
        if (customRules.length > 0) {
            const selectedIndex = selectedRuleIndex ? selectedRuleIndex : 0;
            const rule = customRules[selectedIndex];
            setSelectedRule(rule);
            setSelectedRuleIndex(selectedIndex);
            if (rule.isQueryFilter) {
                setTabIndex(1);
            } else if (rule.is_lookup_rule) {
                setTabIndex(2);
            } else {
                setTabIndex(0);
            }
        }
        if (controllerRef?.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;
        if (!isAttributeLoad) {
            setAttributeLoad(true);
        }
    }, [isAttributeLoad, selectedRuleIndex]);

    const updateRules = useCallback(() => {
        if (inputChanged) {
            for (const rule of rules) {
                prepareSelectedAttributes(rule);
            }
            const validateRules = [];
            for (const rule of rules) {
                const ruleGroupRules = [...rule.ruleGroup.rules];
                let hasEmptyValueRule = false;
                if (rule.isQueryFilter) {
                    hasEmptyValueRule = rule.query.length === 0;
                } else if (rule.is_lookup_rule) {
                    hasEmptyValueRule = false;
                } else {
                    hasEmptyValueRule = ruleGroupRules.filter((p) => p.operator && p.operator.valueRequired && !p.value).length > 0;
                }
                const attributefiltername = rules.filter((element) => element.name === rule.name);
                if (attributefiltername.length > 1) {
                    return false;
                }
                if (rule.name.length > 0 && !hasEmptyValueRule) {
                    validateRules.push(rule);
                }
            }
            const rulesInput = {
                attributeId: attribute.id,
                attribute: attribute.name,
                type: 'profile',
                'rule_type': 'custom',
                rules: [...validateRules]
            };
            /*
             *const profileRules = datasetRules && datasetRules.profile ? datasetRules.profile : {};
             *let attributeProfileRules = [];
             *if (!(attribute.name in profileRules)) {
             *  attributeProfileRules = [];
             *} else {
             *  attributeProfileRules = profileRules[attribute.name];
             *}
             *const attributeRules = attributeProfileRules.filter((p) => p.class_name !== "CustomRule");
             *rulesInput.rules = [...rulesInput.rules];
             */
            if (rulesInput.rules.length !== 0 || type === 'delete') {
                setChanged(false);
                dispatch(updateRuleAttributes(rulesInput));
                dispatch(updateDatasetRules(datasetId, rulesInput));
                setType('');
            }
        }
    }, [attribute.id, attribute.name, datasetId, dispatch, inputChanged, prepareSelectedAttributes, rules, type]);


    const updateRule = useCallback((property, value) => {
        if (selectedRule?.is_semantic_rule) {
            return;
        }
        const rule = { ...selectedRule };
        rule[property] = value;
        // if (property === 'custom_notification') {
        //     if (selectedRule?.custom_notification?.custom_rule === rule?.custom_notification?.custom_rule) {
        //         if (!rule?.custom_notification?.custom_rule_simple && !rule?.custom_notification?.custom_rule_detailed && !rule?.custom_notification?.send_as_link) {
        //             rule[property]['custom_rule'] = false;
        //         } else if ((rule?.custom_notification?.custom_rule_simple || rule?.custom_notification?.custom_rule_detailed)) {
        //             rule[property]['custom_rule'] = true;
        //             rule[property]['send_as_link'] = false;
        //         } else if (rule?.custom_notification?.send_as_link) {
        //             rule[property]['custom_rule'] = true;
        //             rule[property]['custom_rule_simple'] = false;
        //             rule[property]['custom_rule_detailed'] = false;
        //         }
        //     } else {
        //         if (rule?.custom_notification?.custom_rule) {
        //             rule[property]['custom_rule_simple'] = true;
        //         } else {
        //             rule[property]['custom_rule_simple'] = false;
        //             rule[property]['custom_rule_detailed'] = false;
        //         }
        //     }
        // }
        if (property === 'ruleGroup' && !rule.isQueryFilter) {
            rule.query = getQueryString(value);
        }
        if (property === 'conditionalRuleGroup' && !rule.isQueryFilter) {
            rule.conditionalQuery = getQueryString(value, true);
        }
        if (property === 'name' && !rule.isQueryFilter) {
            if (selectedRuleIndex === 0 && ("rule_id" in rule) === false) {
                const attributefiltername = rules.filter((element) => element.name === rule.name);
                if (attributefiltername.length > 1) {
                    setduplicateNameError(true);
                    return false;
                }
                setduplicateNameError(false);
            } else {
                const attributefiltername = rules.filter((element) => element.name === rule.name);
                if (attributefiltername.length > 1) {
                    setduplicateNameError(true);
                    return false;
                }
                setduplicateNameError(false);
            }
        }
        if (property === 'reference_dataset' || property === 'lookup_attribute') {
            if (property === 'reference_dataset') {
                rule['lookup_attribute'] = '';
            }
            rule[property] = value;
        }
        rules[selectedRuleIndex] = { ...rule };
        setRules([...rules]);
        setSelectedRule({ ...rule });
        setChanged(true);
    }, [rules, selectedRule, selectedRuleIndex]);

    const deleteRule = useCallback((type) => {
        rules.splice(selectedRuleIndex, 1);
        setRules([...rules]);
        if (rules.length >= 0) {
            if (rules[0]) {
                if (rules[0].isQueryFilter) {
                    setTabIndex(1);
                } else if (rules[0].is_lookup_rule) {
                    setTabIndex(2);
                } else {
                    setTabIndex(0);
                }
            }
            setSelectedRule({ ...rules[0] });
            setSelectedRuleIndex(0);
        }
        setChanged(true);
        setduplicateNameError(false);
        setType(type);
    }, [rules, selectedRuleIndex]);

    useEffect(() => {
        if (selectedAttribute && attribute && selectedAttribute.name === attribute.name) {
            return;
        }
        const profileRules = datasetRules && datasetRules.profile ? datasetRules.profile : {};
        let attributeProfileRules = [];
        if (!(attribute.name in profileRules)) {
            attributeProfileRules = [];
        } else {
            attributeProfileRules = profileRules[attribute.name];
        }
        const attributeRules = attributeProfileRules.filter((p) => p.class_name === "CustomRule");
        let customRules = [];
        for (const rule of attributeRules) {
            const customRule = prepareRule(rule);
            customRules.push({ ...customRule });
        }
        customRules = customRules.sort((a, b) => b['rule_id'] - a['rule_id']);
        loadRules(customRules, attribute);
    }, [attribute, datasetRules, loadRules, prepareRule, selectedAttribute, rules.length]);

    const ruleList = search.length ? rules.filter((rule) => rule.name.toLowerCase().includes(search.toLowerCase())) : [...rules];
    return (
        <Grid container direction="column" wrap="nowrap" style={!props.list ? { height: '100%' } : { padding: '10px' }} onMouseLeave={() => updateRules()}>
            <Grid container direction="row" justify="space-between" alignItems="center" className={classes.valuesTitleContainer}>
                <Typography component="h6" variant="h6" >
                    {!props.list ? 'Rule' : ''}
                </Typography>
                <Grid item className={!props.list ? '' : classes.listViewAlignment}>
                    <Grid container direction="row" justify="flex-end" wrap="nowrap" alignItems="center">
                        <React.Fragment>
                            <Grid item>
                                <Search
                                    onChange={(value) => setSearchString(value)}
                                    value={search}
                                    // autoFocus
                                    onBlur={() => enableSearch(false)}
                                    placeholder="Search Rule"
                                    className={classes.dqSearchBoxAttributeRule} />
                            </Grid>
                            <Grid item style={{ marginLeft: 10 }}>
                                {
                                    (isEditable || isEditPermission) &&
                                    <ToolTipComponent title="Add Rule" arrow>
                                        <IconButton onClick={() => addRule()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                                    <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                                        <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                                        <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                                    </g>
                                                </g>
                                            </svg>
                                        </IconButton>
                                    </ToolTipComponent>
                                }
                            </Grid>
                        </React.Fragment>

                    </Grid>

                </Grid>
            </Grid>
            <Grid container direction="row">
                <Typography variant="body2" noWrap style={{ margin: '5px 0px 8px 0px' }}>
                    {Localization.properties.rule.rule}
                </Typography>
            </Grid>
            {
                ruleList && ruleList.length > 0 ?
                    <Grid container direction="row" justify="flex-start" wrap="nowrap" className={classes.queryBuilderContainer}>
                        <Grid item xl={3} lg={3} md={3} sm={3} xs={3} className={classes.rulesListContainer}>
                            {
                                ruleList && ruleList.map((rule, index) => {
                                    return (
                                        <Typography key={`${attribute && attribute.name ? attribute.name : ''}rules${index}`}
                                            variant={'body2'}
                                            noWrap
                                            className={classNames(classes.ruleListItem, (selectedRule && selectedRule.name && selectedRule.name.toLowerCase() === rule.name.toLowerCase()) || (index === selectedRuleIndex && selectedRule && selectedRule.name === "") ? 'active' : '')}
                                            onClick={() => onSelectRule(rule, index)}>
                                            {rule && rule.name ? rule.name : 'Untitled Rule Name'}
                                        </Typography>
                                    );
                                })
                            }
                        </Grid>
                        <Grid item xl={9} lg={9} md={9} sm={9} xs={9} className={classes.attributeRuleContainer}>
                            <Grid container direction="column" justify="flex-start" wrap="nowrap" style={{ height: '100%' }}>
                                <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
                                    <Grid item xs={10}>
                                        <TextBox
                                            name="name"
                                            placeholder="Rule Name"
                                            value={selectedRule && selectedRule.name ? selectedRule.name : ''}
                                            fullWidth
                                            onChange={(event) => updateRuleValue(event.target.name, event.target.value)}
                                            onBlur={(event) => updateRule(event.target.name, event.target.value)}
                                            className={classNames(classes.inlinetxt, classes.ruleTitle)}
                                            disabled={!isEditable && !isEditPermission}
                                            error={duplicateNameError}
                                            helperText={duplicateNameError ? "Name is Duplicated" : ""}
                                        />
                                    </Grid>
                                    {
                                        (isEditable || isDeletePermission) &&
                                        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'right' }}>
                                            {/* {
                                                configSettings?.custom_rule_notification &&
                                                <ToolTipComponent title="Custom Rule Notification" arrow>
                                                    <IconButton className={classes.actionIconButton} onClick={(event) => setAnchorEl(event.currentTarget)}>
                                                        <SettingsIcon style={{ color: theme.palette.primary.main }} />
                                                    </IconButton>
                                                </ToolTipComponent>

                                            } */}
                                            {
                                                enable_custom_export_metrics &&
                                                <ToolTipComponent title={selectedRule?.export_metrics_custom_value ? "Custom Values for Export Metrics ON" : "Custom Values for Export Metrics OFF"}>
                                                    <IconButton className={classes.actionIconButton} onClick={(event) => setAnchorEl(event.currentTarget)}>
                                                        <SettingsIcon style={{ color: theme.palette.primary.main }} />
                                                    </IconButton>
                                                </ToolTipComponent>
                                            }
                                            <ToolTipComponent title="Delete Rule" arrow>
                                                <IconButton className={classes.actionIconButton} onClick={() => deleteRule('delete')}>
                                                    <svg version="1.1"
                                                        id="Layer_1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        x="0px"
                                                        y="0px"
                                                        width="22"
                                                        height="22"
                                                        viewBox="0 0 32 32">
                                                        <g id="Delete">
                                                            <path fill={theme.palette.primary.main}
                                                                stroke={theme.palette.primary.main}
                                                                strokeWidth="0.92"
                                                                strokeMiterlimit="10"
                                                                d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
    L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
    L22.3,25.8z" />
                                                            <path fill={theme.palette.primary.main}
                                                                stroke={theme.palette.primary.main}
                                                                strokeWidth="0.92"
                                                                strokeMiterlimit="10"
                                                                d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
    c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
    c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                                        </g>
                                                    </svg>
                                                </IconButton>
                                            </ToolTipComponent>
                                            <Popover
                                                id="custom-notification"
                                                open={Boolean(anchorEl)}
                                                anchorEl={anchorEl}
                                                onClose={() => setAnchorEl(null)}
                                                anchorOrigin={
                                                    {
                                                        vertical: 'bottom',
                                                        horizontal: 'left'
                                                    }
                                                }
                                            >
                                                <Grid container style={{ padding: '16px 24px', maxWidth: '30vw' }}>
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={10} >
                                                                <Typography variant="h4">
                                                                    Custom Labels
                                                                </Typography>
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <ToolTipComponent title={selectedRule?.export_metrics_custom_value ? "Custom Values for Export Metrics ON" : "Custom Values for Export Metrics OFF"}>
                                                                    <Switch color="secondary"
                                                                        name="export_metrics_custom_value"
                                                                        checked={selectedRule?.export_metrics_custom_value ? selectedRule.export_metrics_custom_value : false}
                                                                        onChange={(event) => updateRule(event.target.name, event.target.checked)}
                                                                    />
                                                                </ToolTipComponent>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                    <Grid item xs={12} style={{ paddingTop: 8 }}>
                                                        <TextBox
                                                            id="valid_label"
                                                            name="valid_label"
                                                            placeholder="Valid Data"
                                                            value={selectedRule.valid_label ? selectedRule.valid_label : ''}
                                                            // validators={['required']}
                                                            // errorMessages={['Rule Name is required']}
                                                            fullWidth
                                                            // className={classNames(classes.inlinetxt, classes.ruleTitle)}
                                                            // error={duplicateNameError}
                                                            // helperText={duplicateNameError ? "Name is Duplicated" : ""}
                                                            onChange={(event) => updateRule(event.target.name, event.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} style={{ paddingTop: 8 }}>
                                                        <TextBox
                                                            id="invalid_label"
                                                            name="invalid_label"
                                                            placeholder="Invalid Data"
                                                            value={selectedRule.invalid_label ? selectedRule.invalid_label : ''}
                                                            // validators={['required']}
                                                            // errorMessages={['Rule Name is required']}
                                                            fullWidth
                                                            // className={classNames(classes.inlinetxt, classes.ruleTitle)}
                                                            // error={duplicateNameError}
                                                            // helperText={duplicateNameError ? "Name is Duplicated" : ""}
                                                            onChange={(event) => updateRule(event.target.name, event.target.value)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Popover>
                                        </Grid>
                                    }

                                </Grid>
                                <TextBox
                                    name="description"
                                    placeholder="Rule Description"
                                    value={selectedRule && selectedRule.description ? selectedRule.description : ''}
                                    fullWidth
                                    onChange={(event) => updateRuleValue(event.target.name, event.target.value)}
                                    onBlur={(event) => updateRule(event.target.name, event.target.value)}
                                    className={classNames(classes.inlinetxt, classes.ruleDescription)}
                                    disabled={!isEditable && !isEditPermission}
                                />
                                {
                                    profileSetting && profileSetting.valid_records_notification &&
                                    <Grid container justify="flex-end">
                                        <ToolTipComponent title={"Mark as Valid"}>
                                            <Switch color="secondary"
                                                name="isvalidrule"
                                                checked={selectedRule && selectedRule.isvalidrule ? selectedRule.isvalidrule : false}
                                                onChange={(event) => updateRule(event.target.name, event.target.checked)}
                                            />
                                        </ToolTipComponent>
                                    </Grid>
                                }
                                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className={classes.queryContainer} ref={containerRef}>
                                    <Tabs
                                        value={tabIndex}
                                        indicatorColor="secondary"
                                        textColor="secondary"
                                        onChange={(event, index) => onChangeBuilderType(index)}
                                        aria-label="dataset tabs"
                                    >
                                        {
                                            ruleGroupTabs.map((name, index) =>
                                                <Tab key={index} label={name} />
                                            )
                                        }
                                    </Tabs>
                                    <TabPanel value={tabIndex} index={0} style={{ height: 'unset' }}>
                                        <QueryBuilder
                                            fields={fields}
                                            defaultField={attribute.name}
                                            defaultFieldType={attribute.datatype}
                                            ruleGroup={ruleGroup}
                                            conditionalRuleGroup={conditionalRuleGroup}
                                            onQueryChange={(ruleGroup, isConditionalRule) => updateRule(isConditionalRule ? 'conditionalRuleGroup' : 'ruleGroup', ruleGroup)}
                                            onRuleTypeChange={(isComplexRule) => onChangeRuleType(isComplexRule)}
                                            disabled={!isEditable && !isEditPermission}
                                            selectedDatasource={selectedDatasource}
                                            isComplexRule={selectedRule && selectedRule.is_complex_rule}
                                            export_metrics_custom_value={selectedRule && selectedRule.export_metrics_custom_value}
                                            selectedDataset={selectedDataset}
                                            isShowValidate={Boolean(true)}
                                            selectedRule={selectedRule}
                                            selectedAttribute={attribute}
                                        />
                                    </TabPanel>
                                    <TabPanel value={tabIndex} index={1} style={{ height: 'unset' }}>
                                        <ReactQueryEditor
                                            fields={fields}
                                            rule={selectedRule ? selectedRule : {}}
                                            isInvalidQuery={selectedRule.isInvalidQuery}
                                            onChange={(property, value) => updateRule(property, value)}
                                            isShowValidate
                                            container={containerRef}
                                            disabled={!isEditable && !isEditPermission}
                                            selectedRuleChanged={selectedRuleChanged}
                                        />
                                    </TabPanel>
                                    <TabPanel value={tabIndex} index={2} style={{ height: 'unset' }}>
                                        <Grid container spacing={1} style={{ paddingTop: 12 }}>
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography component="h6" variant="h6" >
                                                            Reference Dataset
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        Validate against a reference dataset to check valid / invalid data
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <AutoCompleteInput
                                                            name="reference_dataset"
                                                            options={sortTable(referenceDatasets ? referenceDatasets : [], "asc", "name")}
                                                            value={selectedReferenceDataset ? selectedReferenceDataset : {}}
                                                            getOptionLabel={(option) => (option && option.name ? `${option.name}` : '')}
                                                            renderInput={
                                                                (params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        label="Reference Dataset"
                                                                        fullWidth
                                                                    />
                                                            }
                                                            onChange={
                                                                (_, newValue) => {
                                                                    if (newValue) {
                                                                        updateRule('reference_dataset', {
                                                                            file_url: newValue.file_url,
                                                                            file_type: newValue.file_type,
                                                                            storage_method: newValue.storage_method,
                                                                            name: newValue.name,
                                                                            id: newValue.id
                                                                        });
                                                                    } else {
                                                                        updateRule('reference_dataset', {});
                                                                    }
                                                                    setSelectedReferenceDataset(newValue);
                                                                }
                                                            }
                                                            onInputChange={(_, value) => { }}
                                                            onOpen={() => { }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12}>
                                                        <Typography component="h6" variant="h6" >
                                                            Lookup Attribute
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        Define the field that need to be validated against
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <AutoCompleteInput
                                                            name="lookup_attribute"
                                                            options={sortTable(selectedReferenceDataset && selectedReferenceDataset.attributes ? selectedReferenceDataset.attributes : [], "asc", "name")}
                                                            value={selectedRule && selectedRule.lookup_attribute ? { attribute: selectedRule.lookup_attribute } : {}}
                                                            getOptionLabel={(option) => (option && option.attribute ? option.attribute : '')}
                                                            renderInput={
                                                                (params) =>
                                                                    <TextField
                                                                        {...params}
                                                                        label="Lookup Attribute"
                                                                        fullWidth
                                                                    />
                                                            }
                                                            onChange={(_, newValue) => { updateRule('lookup_attribute', newValue?.attribute || ''); }}
                                                            onInputChange={(_, value) => { }}
                                                            onOpen={() => { }}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    :
                    <Grid container direction="row" justify="center" alignItems="center" wrap="nowrap" className={classNames(classes.queryBuilderContainer, classes.emptyRuleContainer)} onClick={() => (isEditPermission || isEditable ? addRule() : null)}>
                        <Grid item>
                            <Typography variant="h4" style={{ cursor: "pointer", color: '#646666' }}>
                                {'Add Rule'}
                            </Typography>
                        </Grid>
                    </Grid>
            }
        </Grid >
    );
};

AttributeRules.propTypes = {
    classes: PropTypes.object,
    attribute: PropTypes.object,
    profileSetting: PropTypes.object,
    theme: PropTypes.object,
    isEditable: PropTypes.bool,
    list: PropTypes.number,
    isDeletePermission: PropTypes.bool,
    isEditPermission: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(AttributeRules);