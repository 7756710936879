/**
 * @name ExportMetadata.jsx
 * @description Define Add , Update , List and Delete Field Functionalities
 * @author Srinath
 * CreatedAt : 01-Dec-2021
 */

import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, withStyles, FormLabel, Button, FormControl, MenuItem, Select } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classNames from 'classnames';

// Import Styles
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';

// Import Components
import Loader from '../../components/Loaders/Loader.jsx';
import AutoCompleteCheckBox from '../AutoCompleteCheckBox/AutoCompleteCheckBox.jsx';
// import CheckboxComponent from '../ChecboxComponent/CheckboxComponent.jsx';

// Import Actions
import { getOrganizationDatasources } from '../../actions/datasourceActions';
import { getOrganizationDatasetList, getOrganizationAttributeList } from '../../actions/datasetActions';
import { getExportMetadata, getExportRules, getExportUsers, getExportUsersActivity, getExportUsersActivityInfo } from '../../actions/settingActions';

//import Helpers
import { sortTable } from '../../helpers/appHelpers.js';

const ExportMetadata = (props) => {

    // Get Props
    const { classes, isOrganization } = props;

    // Set State
    const [isDsSelected, setIsDsSelected] = useState(false);
    const [isDsetSelected, setIsDsetSelected] = useState(false);
    const [datasources, setDatasources] = useState([]);
    const [datasets, setDatasets] = useState([]);
    const [attributes, setAttributes] = useState([]);
    const [filtereddatasets, setFilteredDatasets] = useState([]);
    const [filteredattributes, setFilteredAttributes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    // const [isAllSelected, setIsAllSelected] = useState(false);
    const [isDisabled, setIsDisabled] = useState(true);
    const [isAttributeLoaded, setIsAttributeLoaded] = useState(true);
    const [exportData, setExportData] = useState({
        datasources: [{ id: 0, name: 'All', isSelected: false }],
        datasets: [{ id: 0, name: 'All', isSelected: false }],
        attributes: [{ id: 0, name: 'All', isSelected: false }]
    });
    const [selectedType, setSelectedType] = useState('');
    const dispatch = useDispatch();
    // Get Datasource List

    const sortAccending = (a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    };

    const getDatasources = useCallback((exportDataChange) => {
        dispatch(getOrganizationDatasources()).then((response) => {
            if (response) {
                response = response.map((data) => { return { ...data, name: data.name ? data.name : "Untitled Datasource" }; }).sort(sortAccending);
                const filterDatasource = exportDataChange ? exportDataChange : exportData.datasources;
                const index = filterDatasource.findIndex((data) => data.name === "All");
                if (filterDatasource[index]?.name === 'All' && filterDatasource[index]?.isSelected === true) {
                    response = response.map((option) => { return { ...option, isSelected: true }; });
                    setIsDsSelected(true);
                } else {
                    let isDatasourceSelected = false;
                    const selectedIdList = filterDatasource.map((data) => data.id);
                    response = response.map((data) => {
                        const isSelected = selectedIdList.includes(data.id);
                        if (!isDatasourceSelected && isSelected) {
                            isDatasourceSelected = isSelected;
                        }
                        return { ...data, isSelected };
                    });
                    setIsDsSelected(isDatasourceSelected);
                }
                setDatasources([...response]);
            }
        });
    }, [dispatch, exportData.datasources]);

    // Get Dataset List
    const getDatasets = useCallback((exportDataChange) => {
        dispatch(getOrganizationDatasetList()).then((response) => {
            if (response) {
                response = response.map((data) => { return { ...data, name: `${data.source_name ? data.source_name : "Untitled Datasource"}.${data.name ? data.name : "Untitled Dataset"}` }; }).sort(sortAccending);
                const filterDatasets = exportDataChange ? exportDataChange : exportData.datasets;
                const index = filterDatasets.findIndex((data) => data.name === "All");
                let hasSelectedDset = false;
                if (filterDatasets[index]?.name === 'All' && filterDatasets[index]?.isSelected === true) {
                    response = response.map((option) => { return { ...option, isSelected: true }; });
                    hasSelectedDset = true;
                    setFilteredDatasets([]);
                } else {
                    const selectedIdList = filterDatasets.map((data) => data.id);
                    response = response.map((data) => {
                        const isSelected = selectedIdList.includes(data.id);
                        if (!hasSelectedDset && isSelected) {
                            hasSelectedDset = isSelected;
                        }
                        return { ...data, isSelected };
                    });
                }
                setDatasets([...response]);
                setIsDsetSelected(hasSelectedDset);
            }
        });
    }, [dispatch, exportData.datasets]);

    // Get Attribute List
    const getAttributes = useCallback((exportDataChange) => {
        dispatch(getOrganizationAttributeList()).then((response) => {
            if (response) {
                response = response.map((data) => { return { ...data }; }).sort(sortAccending);
                const filterAttributes = exportDataChange ? exportDataChange : exportData.attributes;
                const index = filterAttributes.findIndex((data) => data.name === "All");
                if (filterAttributes[index]?.name === 'All' && filterAttributes[index]?.isSelected === true) {
                    response = response.map((option) => { return { ...option, isSelected: true }; });
                    setFilteredAttributes([]);
                } else {
                    const selectedIdList = filterAttributes.map((data) => data.id);
                    response = response.map((data) => { return { ...data, isSelected: selectedIdList.includes(data.id) }; });
                }
                setAttributes([...response]);
                setIsAttributeLoaded(false);
            }
        });
    }, [dispatch, exportData.attributes]);

    const getSourceList = useCallback((property) => {
        switch (property) {
            case 'datasources': return datasources;
            case 'datasets': return datasets;
            case 'attributes': return attributes;
            default: return [];
        }
    }, [attributes, datasets, datasources]);

    const updateFilter = useCallback((property, list) => {
        const selectedIds = [];
        const updateProperty = getSourceList(property);
        let hasSelected = false;
        let idString = "";
        if (property === "datasources") {
            idString = 'id';
        } else if (property === "datasets") {
            idString = 'source_id';
        } else {
            idString = 'dataset_id';
        }
        list.forEach((data) => {
            if (!hasSelected && data.isSelected) {
                hasSelected = data.isSelected;
            }
            data.isSelected && selectedIds.push(property === "datasources" ? data.source_id : data.id);
        });


        const updatedList = [];
        updateProperty.forEach((p) => {
            if (selectedIds.includes(p[idString])) {
                updatedList.push({ ...p, isSelected: true });
            } else {
                updatedList.push({ ...p, isSelected: false });
            }
        });
        if (property === "datasets") {
            setDatasets([...updatedList]);
            setIsDsSelected(hasSelected);
        } else if (property === "attributes") {
            setAttributes([...updatedList]);
            setIsDsetSelected(hasSelected);
        }
        return updatedList.filter((item) => item.isSelected === true);
    }, [getSourceList]);

    // On change Autocomplete type
    const onChangeType = (event) => {
        if (event.target.value === 3 || event.target.value === 4 || event.target.value === 5) {
            setIsAttributeLoaded(false);
            setIsDisabled(false);
        }
        setSelectedType(event.target.value);
    };

    // On change Autocomplete
    const onChangeFilter = useCallback((property, value) => {
        if (typeof value !== 'string') {
            let sourceList = getSourceList(property);
            if (value.name === 'all') {
                if (value.isSelected) {
                    sourceList = sourceList.map((option) => { return { ...option, isSelected: true }; });
                    setIsDisabled(false);
                } else {
                    sourceList = sourceList.map((option) => { return { ...option, isSelected: false }; });
                    exportData[property] = exportData[property].map((p) => { return { ...p, isSelected: false }; });
                    setIsDisabled(true);
                }
            } else {
                const sourceItemIndex = sourceList.findIndex((p) => p.id === value.id);
                sourceList[sourceItemIndex].isSelected = !sourceList[sourceItemIndex]?.isSelected;
                exportData[property] = sourceList.filter((p) => p.isSelected === true);
            }

            const isSelectedList = sourceList.filter((item) => item.isSelected === true);

            let updatedList = [];
            switch (property) {
                case 'datasources': {
                    setDatasources([...sourceList]);
                    updatedList = updateFilter('datasets', isSelectedList);
                    setFilteredDatasets([...updatedList]);
                    updatedList = updateFilter('attributes', updatedList);
                    setFilteredAttributes([...updatedList]);
                    setIsDsSelected(isSelectedList.length > 0);
                } break;
                case 'datasets': {
                    setDatasets([...sourceList]);
                    const modifiedFilteredDataset = filtereddatasets.map((dSet) => {
                        const modifiedDset = dSet;
                        const targetIndex = sourceList.findIndex((oDset) => oDset.id === dSet.id);
                        if (sourceList && targetIndex > -1) {
                            modifiedDset.isSelected = sourceList[targetIndex].isSelected;
                        }
                        return modifiedDset;
                    });
                    setFilteredDatasets(modifiedFilteredDataset);
                    updatedList = updateFilter('attributes', sourceList);
                    setFilteredAttributes([...updatedList]);
                    setIsDsetSelected(isSelectedList.length > 0);
                } break;
                case 'attributes': {
                    setAttributes([...sourceList]);
                    const modifiedFilteredAttributes = filteredattributes.map((dAttr) => {
                        const modifiedAttr = dAttr;
                        const targetIndex = sourceList.findIndex((oAttr) => oAttr.id === dAttr.id);
                        if (sourceList && targetIndex > -1) {
                            modifiedAttr.isSelected = sourceList[targetIndex].isSelected;
                        }
                        return modifiedAttr;
                    });
                    setFilteredAttributes(modifiedFilteredAttributes);
                } break;
                default:
            }
            const selectedList = [];
            const unselectedList = [];
            sourceList.forEach((p) => (p.isSelected ? selectedList.push(p) : unselectedList.push(p)));
            updatedList.forEach((p) => (p.isSelected ? selectedList.push(p) : unselectedList.push(p)));
            // (unselectedList.length > 0) ? setIsAllSelected(false) : setIsAllSelected(true);
            (selectedList.length > 0) ? setIsDisabled(false) : setIsDisabled(true);
            setExportData(exportData);
        }
    }, [exportData, filteredattributes, filtereddatasets, getSourceList, updateFilter]);

    /**
     * handle export on click
     */
    const handleExport = () => {
        const exportDataTmp = JSON.parse(JSON.stringify(exportData));
        if (exportDataTmp.datasources.length > 0) {
            const selectedDatasource = [];
            const selectedDataset = [];
            const selectedAttribute = [];
            if (exportDataTmp.datasources.length > 0) {
                if (exportDataTmp.datasources.length === 1 && exportDataTmp.datasources[0].name === 'All') {
                    exportData.datasources = datasources;
                }
                exportData.datasources.map((item) => {
                    if (item.id !== '' || item.id !== 0 || item.id !== null) {
                        selectedDatasource.push(item.id);
                    }
                    return '';
                });
            }
            if (exportDataTmp.datasets.length > 0) {
                if (exportDataTmp.datasets.length === 1 && exportDataTmp.datasets[0].name === 'All') {
                    exportData.datasets = datasets.filter((d) => d.isSelected);
                }
                exportData.datasets.map((item) => {
                    if (item.id !== '' || item.id !== 0 || item.id !== null) {
                        selectedDataset.push(item.id);
                    }
                    return '';
                });
            }
            if (exportDataTmp.attributes.length > 0) {
                if (exportDataTmp.attributes.length === 1 && exportDataTmp.attributes[0].name === 'All') {
                    exportData.attributes = attributes.filter((d) => d.isSelected);
                }
                exportData.attributes.map((item) => {
                    if (item.attribute_id !== '' || item.attribute_id !== 0 || item.attribute_id !== null) {
                        selectedAttribute.push(item.attribute_id);
                    }
                    return '';
                });
            }
            const requestParams = {
                datasources: selectedDatasource,
                datasets: selectedDataset,
                attributes: selectedAttribute
            };
            setIsLoading(true);
            if (selectedType) {
                if (selectedType === 1) {
                    dispatch(getExportMetadata(requestParams)).then((response) => {
                        const exportDataTemp = {
                            datasources: [{ id: 0, name: 'All', isSelected: false }],
                            datasets: [{ id: 0, name: 'All', isSelected: false }],
                            attributes: [{ id: 0, name: 'All', isSelected: false }]
                        };
                        exportDataTemp.datasources = exportDataTemp.datasources.map((d) => ({ ...d, isSelected: false }));
                        exportDataTemp.datasets = exportDataTemp.datasets.map((d) => ({ ...d, isSelected: false }));
                        exportDataTemp.attributes = exportDataTemp.attributes.map((d) => ({ ...d, isSelected: false }));
                        setExportData({ ...exportDataTemp });
                        setFilteredAttributes([]);
                        setFilteredDatasets([]);
                        getDatasources(exportDataTemp.datasources);
                        getDatasets(exportDataTemp.datasets);
                        getAttributes(exportDataTemp.attributes);
                        // setIsAllSelected(false);
                        setIsDisabled(true);
                        setIsLoading(false);
                    });
                } else if (selectedType === 2) {
                    dispatch(getExportRules(requestParams)).then((response) => {
                        const exportDataTemp = {
                            datasources: [{ id: 0, name: 'All', isSelected: false }],
                            datasets: [{ id: 0, name: 'All', isSelected: false }],
                            attributes: [{ id: 0, name: 'All', isSelected: false }]
                        };
                        exportDataTemp.datasources = exportDataTemp.datasources.map((d) => ({ ...d, isSelected: false }));
                        exportDataTemp.datasets = exportDataTemp.datasets.map((d) => ({ ...d, isSelected: false }));
                        exportDataTemp.attributes = exportDataTemp.attributes.map((d) => ({ ...d, isSelected: false }));
                        setExportData({ ...exportDataTemp });
                        setFilteredAttributes([]);
                        setFilteredDatasets([]);
                        getDatasources(exportDataTemp.datasources);
                        getDatasets(exportDataTemp.datasets);
                        getAttributes(exportDataTemp.attributes);
                        // setIsAllSelected(false);
                        setIsDisabled(true);
                        setIsLoading(false);
                    });
                } else if (selectedType === 3) {
                    dispatch(getExportUsers(requestParams)).then((response) => {
                        setIsDisabled(true);
                        setIsLoading(false);
                    });
                } else if (selectedType === 4) {
                    dispatch(getExportUsersActivity(requestParams)).then((response) => {
                        setIsDisabled(true);
                        setIsLoading(false);
                    });
                }
                else if (selectedType === 5) {
                    dispatch(getExportUsersActivityInfo(requestParams)).then((response) => {
                        setIsDisabled(true);
                        setIsLoading(false);
                    });
                }
            }
        }
    };

    /**
     * @name useEffect
     * @description UseEffect run when page initialize and any updates happends
     */
    useEffect(() => {
        if (datasources.length === 0) {
            getDatasources();
        }
        if (datasets.length === 0) {
            getDatasets();
        }
        if (attributes.length === 0) {
            getAttributes();
        }
    }, [datasets.length, datasources.length, attributes.length, getDatasets, getDatasources, getAttributes]);

    const getDatasetOptions = () => {
        let options = datasets;
        if (filtereddatasets.length > 0) {
            options = filtereddatasets;
        } else if (!isDsSelected) {
            options = [];
        }
        return options;
    };

    const getAttributeOptions = () => {
        let options = attributes;
        const datasetOptions = getDatasetOptions();
        if (filteredattributes.length > 0) {
            options = filteredattributes;
        } else if (!isDsetSelected) {
            options = [];
        }
        options = options.filter((attr) => {
            const targetIndex = datasetOptions.findIndex((dSet) => attr.dataset_id === dSet.id);
            return targetIndex > -1;
        });
        return options;
    };

    return (
        <Grid>
            <ValidatorForm name="export_metadata" onSubmit={() => null}>
                <Grid className={classNames(classes.filterContainer)}>
                    <Grid container alignItems="center" justify="space-between" className={classNames(classes.datasourceFilterSection, "datasourceFilterSection")}>
                        {/* <Grid item xs={3} sm={3} className={classes.displayGrid}>
                            <CheckboxComponent checked={isAllSelected} onClick={() => onChangeFilter("datasources", { isSelected: !isAllSelected, name: "all" })} name={'Select All'} checkboxLabel="Select All" />
                        </Grid> */}
                        <Grid item xs={3} sm={3} className={classes.displayGrid}>
                            <FormLabel>
                                Export Type
                            </FormLabel>
                            <FormControl variant="standard" className={classes.typeWidth}>
                                {/* <InputLabel id="demo-simple-select-standard-label">Age</InputLabel> */}
                                {
                                    isOrganization &&
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={selectedType}
                                            onChange={onChangeType}
                                            label="Export"
                                            >
                                            <MenuItem value={1}>
                                                Export Metadata
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                Export Rules
                                            </MenuItem>
                                            <MenuItem value={3}>
                                                Export Users
                                            </MenuItem>
                                            <MenuItem value={4}>
                                                Export User Activity
                                            </MenuItem>
                                            <MenuItem value={5}>
                                                Export User Audit Logs
                                            </MenuItem>
                                        </Select>
                                }
                                {
                                    !isOrganization &&
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            value={selectedType}
                                            onChange={onChangeType}
                                            label="Export"
                                            >
                                            <MenuItem value={1}>
                                                Export Metadata
                                            </MenuItem>
                                            <MenuItem value={2}>
                                                Export Rules
                                            </MenuItem>
                                        </Select>
                                }
                            </FormControl>
                        </Grid>
                        {
                            selectedType !== 3 && selectedType !== 4 && selectedType !== 5 &&
                            <Grid item xs={3} sm={3} className={classes.displayGrid}>
                                <FormLabel>
                                    Datasources
                                </FormLabel>
                                <AutoCompleteCheckBox
                                    availableList={sortTable(datasources, "asc", "name")}
                                    displayValue="name"
                                    onChange={(value) => onChangeFilter("datasources", value)}
                                />
                            </Grid>
                        }
                        {
                            selectedType !== 3 && selectedType !== 4 && selectedType !== 5 &&
                            <Grid item xs={3} sm={3} className={classes.displayGrid}>
                                <FormLabel>
                                    Datasets
                                </FormLabel>
                                <AutoCompleteCheckBox
                                    availableList={sortTable(getDatasetOptions(), "asc", "name")}
                                    displayValue="name"
                                    onChange={(value) => onChangeFilter("datasets", value)}
                                />
                            </Grid>
                        }
                        {
                            selectedType !== 3 && selectedType !== 4 && selectedType !== 5 &&
                            <Grid item xs={3} sm={3} className={classes.displayGrid}>
                                <FormLabel>
                                    Attributes
                                </FormLabel>
                                <AutoCompleteCheckBox
                                    availableList={sortTable(getAttributeOptions(), "asc", "name")}
                                    displayValue="name"
                                    onChange={(value) => onChangeFilter("attributes", value)}
                                />
                            </Grid>
                        }
                    </Grid>
                </Grid>
                <Grid className={classes.exportMetadataButton}>
                    <Button disabled={isLoading || isDisabled || isAttributeLoaded || selectedType === ''} onClick={() => handleExport()} variant="contained" color="primary">
                        {'Export'}
                        {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                    </Button>
                </Grid>
            </ValidatorForm>
        </Grid>
    );
};

ExportMetadata.propTypes = {
    classes: PropTypes.object,
    isOrganization: PropTypes.bool
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ExportMetadata);