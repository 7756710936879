import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Table, TableRow, TableHead, TableCell, TableBody, TableSortLabel } from '@material-ui/core';
import classNames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import { getGroupedDriftReport } from '../../actions/notificationActions';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../Loaders/Loader.jsx';
import MasterStyles from '../Master/MasterStyles.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import { sortTable } from '../../helpers/appHelpers.js';


const GroupedDriftReportView = (props) => {
    const { classes, history } = props;
    const filters = useSelector(({ notification }) => notification.driftFilters);
    const groupDriftReport = useSelector(({ notification }) => notification.groupedDriftRules);
    const dispatch = useDispatch();
    const [reportData, setReportData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');

    const loadReportData = useCallback((reportData) => {
        setReportData([...reportData]);
        if (reportData.length > 0) {
            const keys = Object.keys(reportData[0]);
            const navigationLinkAttribute = 'attribute_name';
            if (filters.grouped_keys) {
                const keyIndex = keys.indexOf(navigationLinkAttribute);
                if (keyIndex > -1) {
                    keys.splice(keyIndex, 1);
                    keys.push(navigationLinkAttribute);
                }
            }
            setColumns([...keys]);
        }
        setIsLoading(false);
    }, [filters]);

    useEffect(() => {
        loadReportData(groupDriftReport);
    }, [groupDriftReport, loadReportData]);

    const getReport = useCallback((requestParams) => {
        setIsLoading(true);
        dispatch(getGroupedDriftReport(requestParams));
    }, [dispatch]);

    const getGroupedDriftReportInitLoad = useCallback(() => {
        const requestParams = {
            'filter_count': filters.filter_count,
            'range': filters.range,
            'group_by': filters.group_by,
            'datasets': filters.datasets && filters.datasets.some((data) => data.name === "All") ? [] : filters.datasets.filter((data) => data.isSelected).map((data) => data.id),
            'datasources': filters.datasources && filters.datasources.some((data) => data.name === "All") ? [] : filters.datasources.filter((data) => data.isSelected).map((data) => data.id),
            'attributes': filters.attributes && filters.attributes.some((data) => data.name === "All") ? [] : filters.attributes.filter((data) => data.isSelected).map((data) => data.id),
            'rules': filters.rules && filters.rules.some((data) => data.name === "All") ? [] : filters.rules.filter((data) => data.isSelected).map((data) => data.name),
            'group_by_attributes': filters.group_by_attributes && filters.group_by_attributes.some((data) => data.name === "All") ? [] : filters.group_by_attributes.filter((data) => data.isSelected).map((data) => data.name.replace(`${data.source_name}.${data.dataset_name}.`, '')),
            "grouped_keys": filters.grouped_keys && filters.grouped_keys.toLowerCase() !== "all" ? filters.grouped_keys : ""
        };
        getReport(requestParams);
    }, [filters, getReport]);

    const onChangeOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    useEffect(() => {
        getGroupedDriftReportInitLoad();
    }, [getGroupedDriftReportInitLoad]);


    const navigateReport = useCallback((columnName, report) => {
        const groupedAttributes = filters.group_by_attributes.filter((data) => data.isSelected);
        let attribute = null;
        if (groupedAttributes && groupedAttributes.length > 0) {
            attribute = groupedAttributes[0];
        }
        if (!attribute) {
            return;
        }
        columnName = columnName ? columnName.trim() : "";
        history.push({ pathname: `/dataset/${attribute.dataset_id}`, state: { datasource: {}, datasourceId: attribute.source_id, isdrift: true, attribute: columnName, ruleName: `${report.fips}&${report.attribute}`, filterRule: `${report.fips}&${report.attribute}` } });
    }, [filters, history]);


    return (
        <Grid className={classNames(classes.container, classes.relative)} container>
            <Grid container direction="row" justify="center" className={classes.reportAttributesTableContainer}>
                {
                    reportData && reportData.length > 0 ?
                        <Table stickyHeader className={classNames(classes.propertyListPageTable, classes.defineTable, classes.standardTable)}>
                            <TableHead>
                                <TableRow>
                                    {
                                        columns.map((column, index) =>
                                            <TableCell key={`column_${index}`} className={classNames(column !== "attribute_name" ? classes.otherColumn : "")}>
                                                <TableSortLabel
                                                    active={orderBy === column}
                                                    direction={orderBy === column ? order : 'asc'}
                                                    onClick={() => onChangeOrder(column)}
                                                >
                                                    <Typography variant="body1" className={classes.tableHeader}>
                                                        {column === "attribute_name" ? "rules" : column}
                                                    </Typography>
                                                </TableSortLabel>
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    reportData && sortTable(reportData, order, orderBy).map((row, index) =>
                                        <TableRow key={`body_row_${index}`}>
                                            {
                                                columns.map((column, index) =>
                                                    <TableCell key={`bovy_column_${index}`} className={classNames(column !== "attribute_name" ? classes.otherColumn : "")}>
                                                        {
                                                            column !== "attribute_name" &&
                                                            <Typography variant="body1" className={classes.tableHeader}>
                                                                {row[column]}
                                                            </Typography>
                                                        }
                                                        {
                                                            column === "attribute_name" &&
                                                            <Typography className={classNames(classes.tableHeader)}>
                                                                {
                                                                    row[column].split(',').map((attribute, index, arr) => {
                                                                        return (
                                                                            <span key={`attribute_index${index}`} className={classNames(classes.tableHeader, classes.actionText)} onClick={() => navigateReport(attribute, row)}>
                                                                                {attribute}
                                                                                {index === arr.length - 1 ? "" : ","}
                                                                            </span>
                                                                        );
                                                                    })
                                                                }
                                                            </Typography>
                                                        }
                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table> :
                        <NoResultFound />
                }
            </Grid>
            {isLoading && <Loader />}
        </Grid>
    );
};

GroupedDriftReportView.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object
};

export default withStyles((theme) => ({
    ...MasterStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(GroupedDriftReportView);