import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, withStyles, Typography, Button, AccordionSummary, Accordion, AccordionDetails, Switch, FormControlLabel } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classnames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SettingComponentStyles from '../Settings/SettingComponentStyles.jsx';
import DatasourceStyles from './DatasourceStyles.jsx';

const DatasourceConfiguration = (props) => {
    const { classes, configuration, onSubmit } = props;
    const [properties, setProperties] = useState({
        profileRules: {
            count: [],
            statistical: [],
            basic: [],
            outlier: [],
            sampling: [],
            text: [],
            custom: ['CustomRule'],
            fieldtype: ['fieldtype'],
            datatype: ['datatype'],
            invalidrecords: ['invalidrecords']
        }
    });
    const [isChanged, setChanged] = useState(false);
    const [selectedPanel, setSelectedPanel] = useState('Sense');
    const [selectedProfileRulesPanel, setSelectedProfileRulesPanel] = useState('');
    const config = useSelector(({ setting }) => setting.config);

    useEffect(() => {
        setProperties(configuration);
    }, [configuration]);

    const profileRuleChange = (event, propertyName, key, type) => {
        const countRule = ['null_count', 'blank', 'digits', 'distinct_count', 'duplicate', 'unique_count', 'characters', 'white_space', 'special_characters', 'zero_values'];
        const statisticalRule = ['descriptive', 'quantile'];
        const basicRule = ['Length', 'Pattern', 'Range', 'ValidValuesCheck'];
        const outlierRule = ['NormalDistribution', 'KMeansCluster', 'BoxPlot', 'ScatterPlot'];
        const samplingRule = ['HistogramRule'];
        const textRule = ['SoundexFrequency', 'CaseSensitive'];
        const custom = ['CustomRule'];
        const fieldtype = ['fieldtype'];
        const datatype = ['datatype'];
        const invalidrecords = ['invalidrecords'];

        if (!(propertyName in properties)) {
            properties[propertyName] = {};
        }

        if (type === "parent") {
            if (event.target.checked) {
                switch (event.target.name) {
                    case 'count': properties[propertyName][event.target.name] = countRule; break;
                    case 'statistical': properties[propertyName][event.target.name] = statisticalRule; break;
                    case 'basic': properties[propertyName][event.target.name] = basicRule; break;
                    case 'outlier': properties[propertyName][event.target.name] = outlierRule; break;
                    case 'sampling': properties[propertyName][event.target.name] = samplingRule; break;
                    case 'text': properties[propertyName][event.target.name] = textRule; break;
                    case 'custom': properties[propertyName][event.target.name] = custom; break;
                    case 'fieldtype': properties[propertyName][event.target.name] = fieldtype; break;
                    case 'datatype': properties[propertyName][event.target.name] = datatype; break;
                    case 'invalidrecords': properties[propertyName][event.target.name] = invalidrecords; break;
                    default: break;
                }
            }
            else {
                properties[propertyName][event.target.name] = [];
            }
        } else {
            if (!(key in properties[propertyName])) {
                properties[propertyName][key] = [];
            }
            if (event.target.checked) {
                properties[propertyName][key].push(event.target.name);
            } else {
                const index = properties[propertyName][key].indexOf(event.target.name);
                if (index !== -1) {
                    properties[propertyName][key].splice(index, 1);
                }
            }
        }

        // Remove infinite & missing count values in existing configurations if any
        if (key.toLowerCase() === 'count') {
            const countRules = properties[propertyName][key];
            const keysToRemove = ['infinite', 'missing'];
            for (const ruleKey of keysToRemove) {
                const index = countRules.indexOf(ruleKey);
                if (index > -1) {
                    countRules.splice(index, 1);
                }
            }
            properties[propertyName][key] = countRules;
        }

        setProperties({ ...properties });
        setChanged(true);
    };

    const toggleExpansionPanel = (panel) => (event, isExpanded) => {
        isExpanded = isExpanded ? panel : false;
        setSelectedPanel(isExpanded);
        setSelectedProfileRulesPanel(false);
    };

    const profileRulesPanel = (panel) => (event, isExpanded) => {
        isExpanded = isExpanded ? panel : false;
        setSelectedProfileRulesPanel(isExpanded);
    };

    return (
        <ValidatorForm
            form="My properties"
            onSubmit={
                () => {
                    onSubmit('properties', properties);
                    setChanged(false);
                }
            }
        >
            <Grid container spacing={4} className={`${classes.configurationContainer}`}>
                <Grid item xs={12}>
                    <Accordion expanded={selectedPanel === "Profile"} onChange={toggleExpansionPanel("Profile")} className={classes.storageAcoordion}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header">
                            <Typography className={classes.heading}>
                                {"Rules"}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Accordion expanded={selectedProfileRulesPanel === "Count"} onChange={profileRulesPanel("Count")} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }} className={classes.storageAcoordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header">
                                                <Typography className={classes.heading}>
                                                    <Switch defaultChecked
                                                        checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={(event) => profileRuleChange(event, "profileRules", "count", "parent")}
                                                        name="count"
                                                        value="Count"
                                                    />
                                                    {"Count"}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container style={{ marginLeft: '30px' }}>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('null_count')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="null_count"
                                                                    value="Null Count"
                                                                />
                                                            }
                                                            label="Null Count"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('blank')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="blank"
                                                                    value="Blank"

                                                                />
                                                            }
                                                            label="Blank"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('digits')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="digits"
                                                                    value="Digits"

                                                                />
                                                            }
                                                            label="Digits"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('distinct_count')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="distinct_count"
                                                                    value="Distinct Count"

                                                                />
                                                            }
                                                            label="Distinct Count"
                                                        />
                                                    </Grid>
                                                    {/* <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('infinite')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="infinite"
                                                                    value="Infinite"
                                                                />
                                                            }
                                                            label="Infinite"
                                                        />
                                                    </Grid> */}
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('duplicate')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="duplicate"
                                                                    value="Duplicate"

                                                                />
                                                            }
                                                            label="Duplicate"
                                                        />
                                                    </Grid>
                                                    {/* <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('missing')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="missing"
                                                                    value="Missing"

                                                                />
                                                            }
                                                            label="Missing"
                                                        />
                                                    </Grid> */}
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('unique_count')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="unique_count"
                                                                    value="Unique Count"

                                                                />
                                                            }
                                                            label="Unique Count"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('characters')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="characters"
                                                                    value="Characters"

                                                                />
                                                            }
                                                            label="Characters"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('white_space')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="white_space"
                                                                    value="White Space"

                                                                />
                                                            }
                                                            label="White Space"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('special_characters')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="special_characters"
                                                                    value="Special Characters"

                                                                />
                                                            }
                                                            label="Special Characters"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.count && Object.keys(properties.profileRules.count).length > 0 && Object.values(properties.profileRules.count).includes('zero_values')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "count", "child")}
                                                                    name="zero_values"
                                                                    value="Zero Values"

                                                                />
                                                            }
                                                            label="Zero Values"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                }
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Accordion expanded={selectedProfileRulesPanel === "Statistical"} onChange={profileRulesPanel("Statistical")} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }} className={classes.storageAcoordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header">
                                                <Typography className={classes.heading}>
                                                    <Switch defaultChecked
                                                        checked={properties.profileRules && properties.profileRules.statistical && Object.keys(properties.profileRules.statistical).length > 0}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={(event) => profileRuleChange(event, "profileRules", "statistical", "parent")}
                                                        name="statistical"
                                                        value="Statistical"
                                                    />
                                                    {"Statistical"}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container style={{ marginLeft: '30px' }}>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.statistical && Object.keys(properties.profileRules.statistical).length > 0 && Object.values(properties.profileRules.statistical).includes('descriptive')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "statistical", "child")}
                                                                    name="descriptive"
                                                                    value="Descriptive"
                                                                />
                                                            }
                                                            label="Descriptive"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.statistical && Object.keys(properties.profileRules.statistical).length > 0 && Object.values(properties.profileRules.statistical).includes('quantile')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "statistical", "child")}
                                                                    name="quantile"
                                                                    value="Quantile"
                                                                />
                                                            }
                                                            label="Quantile"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                }
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Accordion expanded={selectedProfileRulesPanel === "Basic"} onChange={profileRulesPanel("Basic")} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }} className={classes.storageAcoordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header">
                                                <Typography className={classes.heading}>
                                                    <Switch defaultChecked
                                                        checked={properties.profileRules && properties.profileRules.basic && Object.keys(properties.profileRules.basic).length > 0}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={(event) => profileRuleChange(event, "profileRules", "basic", "parent")}
                                                        name="basic"
                                                        value="Basic"
                                                    />
                                                    {"Basic"}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container style={{ marginLeft: '30px' }}>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.basic && Object.keys(properties.profileRules.basic).length > 0 && Object.values(properties.profileRules.basic).includes('Length')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "basic", "child")}
                                                                    name="Length"
                                                                    value="Length"
                                                                />
                                                            }
                                                            label="Length"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.basic && Object.keys(properties.profileRules.basic).length > 0 && Object.values(properties.profileRules.basic).includes('Pattern')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "basic", "child")}
                                                                    name="Pattern"
                                                                    value="Pattern"
                                                                />
                                                            }
                                                            label="Pattern"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.basic && Object.keys(properties.profileRules.basic).length > 0 && Object.values(properties.profileRules.basic).includes('Range')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "basic", "child")}
                                                                    name="Range"
                                                                    value="Range (Extreme Value)"
                                                                />
                                                            }
                                                            label="Range (Extreme Value)"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.basic && Object.keys(properties.profileRules.basic).length > 0 && Object.values(properties.profileRules.basic).includes('ValidValuesCheck')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "basic", "child")}
                                                                    name="ValidValuesCheck"
                                                                    value="Values"
                                                                />
                                                            }
                                                            label="Values"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                }
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Accordion expanded={selectedProfileRulesPanel === "Outlier"} onChange={profileRulesPanel("Outlier")} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }} className={classes.storageAcoordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header">
                                                <Typography className={classes.heading}>
                                                    <Switch
                                                        checked={properties.profileRules && properties.profileRules.outlier && Object.keys(properties.profileRules.outlier).length > 0}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={(event) => profileRuleChange(event, "profileRules", "outlier", "parent")}
                                                        name="outlier"
                                                        value="Outlier"
                                                    />
                                                    {"Outlier"}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container style={{ marginLeft: '30px' }}>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={properties.profileRules && properties.profileRules.outlier && Object.keys(properties.profileRules.outlier).length > 0 && Object.values(properties.profileRules.outlier).includes('NormalDistribution')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "outlier", "child")}
                                                                    name="NormalDistribution"
                                                                    value="Normal Distribution"
                                                                />
                                                            }
                                                            label="Normal Distribution"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={properties.profileRules && properties.profileRules.outlier && Object.keys(properties.profileRules.outlier).length > 0 && Object.values(properties.profileRules.outlier).includes('KMeansCluster')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "outlier", "child")}
                                                                    name="KMeansCluster"
                                                                    value="K-means"
                                                                />
                                                            }
                                                            label="K-means"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={properties.profileRules && properties.profileRules.outlier && Object.keys(properties.profileRules.outlier).length > 0 && Object.values(properties.profileRules.outlier).includes('BoxPlot')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "outlier", "child")}
                                                                    name="BoxPlot"
                                                                    value="Box Plot"
                                                                />
                                                            }
                                                            label="Box Plot"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={properties.profileRules && properties.profileRules.outlier && Object.keys(properties.profileRules.outlier).length > 0 && Object.values(properties.profileRules.outlier).includes('ScatterPlot')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "outlier", "child")}
                                                                    name="ScatterPlot"
                                                                    value="Scatter Plot"
                                                                />
                                                            }
                                                            label="Scatter Plot"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                }
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Accordion expanded={selectedProfileRulesPanel === "Sampling"} onChange={profileRulesPanel("Sampling")} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }} className={classes.storageAcoordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header">
                                                <Typography className={classes.heading}>
                                                    <Switch
                                                        checked={properties.profileRules && properties.profileRules.sampling && Object.keys(properties.profileRules.sampling).length > 0}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={(event) => profileRuleChange(event, "profileRules", "sampling", "parent")}
                                                        name="sampling"
                                                        value="Sampling"
                                                    />
                                                    {"Sampling"}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container style={{ marginLeft: '30px' }}>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch
                                                                    checked={properties.profileRules && properties.profileRules.sampling && Object.keys(properties.profileRules.sampling).length > 0 && Object.values(properties.profileRules.sampling).includes('HistogramRule')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "sampling", "child")}
                                                                    name="HistogramRule"
                                                                    value="Histogram"
                                                                />
                                                            }
                                                            label="Histogram"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                }
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Accordion expanded={selectedProfileRulesPanel === "Text"} onChange={profileRulesPanel("Text")} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }} className={classes.storageAcoordion}>
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel2a-content"
                                                id="panel2a-header">
                                                <Typography className={classes.heading}>
                                                    <Switch defaultChecked
                                                        checked={properties.profileRules && properties.profileRules.text && Object.keys(properties.profileRules.text).length > 0}
                                                        onClick={(event) => event.stopPropagation()}
                                                        onChange={(event) => profileRuleChange(event, "profileRules", "text", "parent")}
                                                        name="text"
                                                        value="Text"
                                                    />
                                                    {"Text"}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <Grid container style={{ marginLeft: '30px' }}>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.text && Object.keys(properties.profileRules.text).length > 0 && Object.values(properties.profileRules.text).includes('SoundexFrequency')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "text", "child")}
                                                                    name="SoundexFrequency"
                                                                    value="Soundex"
                                                                />
                                                            }
                                                            label="Soundex"
                                                        />
                                                    </Grid>
                                                    <Grid xs={6}>
                                                        <FormControlLabel
                                                            control={
                                                                <Switch defaultChecked
                                                                    checked={properties.profileRules && properties.profileRules.text && Object.keys(properties.profileRules.text).length > 0 && Object.values(properties.profileRules.text).includes('CaseSensitive')}
                                                                    onChange={(event) => profileRuleChange(event, "profileRules", "text", "child")}
                                                                    name="CaseSensitive"
                                                                    value="Case Sensitivity"
                                                                />
                                                            }
                                                            label="Case Sensitivity"
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion>
                                    </Grid>
                                }
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Typography className={classnames(classes.heading, classes.accordianStyles)} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }}>
                                            <Switch defaultChecked
                                                checked={properties.profileRules && properties.profileRules.custom && Object.keys(properties.profileRules.custom).length > 0}
                                                onClick={(event) => event.stopPropagation()}
                                                onChange={(event) => profileRuleChange(event, "profileRules", "custom", "parent")}
                                                name="custom"
                                                value="Custom"
                                            />
                                            {"Custom"}
                                        </Typography>
                                    </Grid>
                                }
                                <Grid xs={4}>
                                    <Typography className={classnames(classes.heading, classes.accordianStyles)} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }}>
                                        <Switch defaultChecked
                                            checked={properties.profileRules && properties.profileRules.fieldtype && Object.keys(properties.profileRules.fieldtype).length > 0}
                                            onClick={(event) => event.stopPropagation()}
                                            onChange={(event) => profileRuleChange(event, "profileRules", "fieldtype", "parent")}
                                            name="fieldtype"
                                            value="fieldtype"
                                        />
                                        {"Field Type Sense"}
                                    </Typography>
                                </Grid>
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Typography className={classnames(classes.heading, classes.accordianStyles)} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }}>
                                            <Switch defaultChecked
                                                checked={properties.profileRules && properties.profileRules.datatype && Object.keys(properties.profileRules.datatype).length > 0}
                                                onClick={(event) => event.stopPropagation()}
                                                onChange={(event) => profileRuleChange(event, "profileRules", "datatype", "parent")}
                                                name="datatype"
                                                value="datatype"
                                            />
                                            {"Data Type Sense"}
                                        </Typography>
                                    </Grid>
                                }
                                {
                                    config.profile &&
                                    <Grid xs={4}>
                                        <Typography className={classnames(classes.heading, classes.accordianStyles)} style={{ marginLeft: '2.5px', marginRight: '2.5px', marginBottom: '5px' }}>
                                            <Switch defaultChecked
                                                checked={properties.profileRules && properties.profileRules.invalidrecords && Object.keys(properties.profileRules.invalidrecords).length > 0}
                                                onClick={(event) => event.stopPropagation()}
                                                onChange={(event) => profileRuleChange(event, "profileRules", "invalidrecords", "parent")}
                                                name="invalidrecords"
                                                value="invalidrecords"
                                            />
                                            {"Invalid Records Save"}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Grid>
                {
                    isChanged &&
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" type="submit" className={classnames(classes.actionButtons, classes.submitBtn)}>
                            Save
                        </Button>
                    </Grid>
                }
            </Grid>
        </ValidatorForm >
    );
};

DatasourceConfiguration.propTypes = {
    classes: PropTypes.object,
    configuration: PropTypes.object,
    onSubmit: PropTypes.func
};

export default withStyles((theme) => ({
    ...DatasourceStyles(theme),
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DatasourceConfiguration);