export const catalogActions = {
    GET_DATASOURCE_CATALOG_INFO_REQUEST: 'GET_DATASOURCE_CATALOG_INFO_REQUEST',
    GET_DATASOURCE_CATALOG_INFO_SUCCESS: 'GET_DATASOURCE_CATALOG_INFO_SUCCESS',
    GET_DATASOURCE_CATALOG_INFO_FAILURE: 'GET_DATASOURCE_CATALOG_INFO_FAILURE',

    GET_DATASET_CATALOG_INFO_REQUEST: 'GET_DATASET_CATALOG_INFO_REQUEST',
    GET_DATASET_CATALOG_INFO_SUCCESS: 'GET_DATASET_CATALOG_INFO_SUCCESS',
    GET_DATASET_CATALOG_INFO_FAILURE: 'GET_DATASET_CATALOG_INFO_FAILURE',

    GET_ATTRIBUTES_CATALOG_INFO_REQUEST: 'GET_ATTRIBUTES_CATALOG_INFO_REQUEST',
    GET_ATTRIBUTES_CATALOG_INFO_SUCCESS: 'GET_ATTRIBUTES_CATALOG_INFO_SUCCESS',
    GET_ATTRIBUTES_CATALOG_INFO_FAILURE: 'GET_ATTRIBUTES_CATALOG_INFO_FAILURE',

    GET_RECENT_QUERIES_REQUEST: 'GET_RECENT_QUERIES_REQUEST',
    GET_RECENT_QUERIES_SUCCESS: 'GET_RECENT_QUERIES_SUCCESS',
    GET_RECENT_QUERIES_FAILURE: 'GET_RECENT_QUERIES_FAILURE',

    GET_DDL_QUERY_REQUEST: 'GET_DDL_QUERY_REQUEST',
    GET_DDL_QUERY_SUCCESS: 'GET_DDL_QUERY_SUCCESS',
    GET_DDL_QUERY_FAILURE: 'GET_DDL_QUERY_FAILURE',


    GET_DATASOURCE_CATALOG_TABLES_REQUEST: 'GET_DATASOURCE_CATALOG_TABLES_REQUEST',
    GET_DATASOURCE_CATALOG_TABLES_SUCCESS: 'GET_DATASOURCE_CATALOG_TABLES_SUCCESS',
    GET_DATASOURCE_CATALOG_TABLES_FAILURE: 'GET_DATASOURCE_CATALOG_TABLES_FAILURE',

    GET_DATASET_CATALOG_TABLES_REQUEST: 'GET_DATASET_CATALOG_TABLES_REQUEST',
    GET_DATASET_CATALOG_TABLES_SUCCESS: 'GET_DATASET_CATALOG_TABLES_SUCCESS',
    GET_DATASET_CATALOG_TABLES_FAILURE: 'GET_DATASET_CATALOG_TABLES_FAILURE',

    CLEAR_CATALOG_INFO: 'CLEAR_CATALOG_INFO',

    GET_DATASOURCE_METADATA_LOAD_REQUEST: 'GET_DATASOURCE_METADATA_LOAD_REQUEST',
    GET_DATASOURCE_METADATA_LOAD_SUCCESS: 'GET_DATASOURCE_METADATA_LOAD_SUCCESS',
    GET_DATASOURCE_METADATA_LOAD_FAILURE: 'GET_DATASOURCE_METADATA_LOAD_FAILURE',

    GET_DATASET_METADATA_LOAD_REQUEST: 'GET_DATASET_METADATA_LOAD_REQUEST',
    GET_DATASET_METADATA_LOAD_SUCCESS: 'GET_DATASET_METADATA_LOAD_SUCCESS',
    GET_DATASET_METADATA_LOAD_FAILURE: 'GET_DATASET_METADATA_LOAD_FAILURE',

    GET_TABLE_COLUMN_METADATA_REQUEST: 'GET_TABLE_COLUMN_METADATA_REQUEST',
    GET_TABLE_COLUMN_METADATA_SUCCESS: 'GET_TABLE_COLUMN_METADATA_SUCCESS',
    GET_TABLE_COLUMN_METADATA_FAILURE: 'GET_TABLE_COLUMN_METADATA_FAILURE',

    RUN_CATALOG_REQUEST: 'RUN_CATALOG_REQUEST',
    RUN_CATALOG_SUCCESS: 'RUN_CATALOG_SUCCESS',
    RUN_CATALOG_FAILURE: 'RUN_CATALOG_FAILURE'
};