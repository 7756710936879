import React, { useEffect, useState, useCallback } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import { wrapText } from '../../helpers/appHelpers';
import ChartStyles from './ChartStyles.jsx';
import { appConstants } from '../../constants/appConstants';
import { createRule, getDefaultOperator, getFieldType } from '../RuleBuilder/QueryBuilderUtil.jsx';
import { useSelector } from 'react-redux';


const ExtremeValueChart = (props) => {
    const { attribute, profileData, classes, theme, onFilter, tabIndex, config, profileRuleEnable, isActive, attributeChange, invalidQuery } = props;
    const datasource = useSelector(({ datasource }) => datasource.datasource);
    const [menuType, setMenuType] = useState(appConstants.rangeFilterOptions[0]);
    const [chartData, setChartData] = useState({});
    const [edited, setEdited] = useState(false);

    const loadChartData = useCallback((chartData) => {
        setChartData({ ...chartData });
    }, []);

    useEffect(() => {
        if (attributeChange) {
            const chartData = profileData.Range;
            loadChartData({ ...chartData });
        }
    }, [attribute, attributeChange, loadChartData, profileData]);

    const updateLegendText = (lineValue, barWidth) => {
        return lineValue >= 0 ? lineValue - (barWidth / 2) : -9999;
    };

    const onChangeMenu = (value) => {
        setEdited(true);
        setMenuType(value);
    };

    const onFilterChange = useCallback((barValue) => {
        const inputParams = {
            chartType: appConstants.charts.range.type,
            chartName: appConstants.charts.range.name,
            "min": chartData?.input_params?.min,
            "max": chartData?.input_params?.max,
            filterOptions: true,
            ruleName: 'Range',
            defaultQuery: ''
        };
        if (barValue) {
            inputParams.data = barValue;
            const ruleParams = {
                defaultField: attribute.name,
                defaultFieldType: getFieldType(attribute.type),
                attributeType: attribute.type,
                connectionType: datasource?.type,
                isScan: datasource?.scan
            };
            const rule = createRule(ruleParams);
            const operators = getDefaultOperator(barValue.length === "null" ? barValue.length : '');
            rule.operator = { ...operators };
            rule.value = barValue.length;
            inputParams.rule = rule;
            inputParams.filterType = barValue.filterType;
            inputParams.datatype = attribute.type ? attribute.type : 'text';
        } else {
            inputParams.filterOptions = false;
            if (invalidQuery) {
                inputParams.defaultQuery = `select * from <table_name> where ${invalidQuery}`;
            } else {
                inputParams.defaultQuery = `select * from <table_name> where not ${attribute.name}.format between ${chartData?.input_params?.min} and ${chartData?.input_params?.max}`;
            }
        }

        onFilter(inputParams);
    }, [attribute.name, attribute.type, chartData?.input_params?.max, chartData?.input_params?.min, datasource?.scan, datasource?.type, invalidQuery, onFilter]);

    const discardEdit = useCallback(() => {
        setEdited(false);
    }, []);


    const getScaleValue = (value, max) => {
        let scaleValue = 0.5;
        if (max >= 11 && max <= 99) {
            scaleValue = 1;
        } else if (max >= 100 && max <= 999) {
            scaleValue = 10;
        } else if (max >= 1000 && max <= 9999) {
            scaleValue = 100;
        }
        return value * scaleValue;
    };
    const updateLengthTopPosition = useCallback((line, statistics, max) => {
        if (line === "max") {
            if ((statistics.min <= getScaleValue(2, max))) {
                return '100px';
            }
        }
        return 'auto';
    }, []);

    const extractValues = useCallback((frequency, records, valid) => {
        const keys = Object.keys(frequency);
        return keys.map((key) => {
            const keyValue = key.length ? key : "null";
            const value = (parseFloat(frequency[key]) / records) * 100;
            return {
                key: typeof (keyValue) === "string" ? keyValue : parseFloat(keyValue),
                value: Math.round(value) !== value ? parseFloat(value.toFixed(2)) : value,
                count: frequency[key],
                isValid: valid
            };
        }).sort((a, b) => ((a.count > b.count) && -1) || 1);
    }, []);

    const isFloat = (n) => {
        return n !== "" && !isNaN(n) && Math.round(n) !== n;
    };

    useEffect(() => {
        if (Object.keys(chartData).length !== 0 && (attributeChange || edited)) {
            if (edited) {
                discardEdit();
            }
            const selectionRange = menuType === 'Top 10' ? 'topN' : 'bottomN';
            const validValues = extractValues(chartData.frequency_count.valid ? chartData.frequency_count.valid : {}, chartData.total_records_processed, true);
            const invalidValues = extractValues(chartData.frequency_count.invalid ? chartData.frequency_count.invalid : {}, chartData.total_records_processed, false);
            const validData = selectionRange === "topN" ? validValues.slice(0, 10) : validValues.slice(Math.max(validValues.length - 10, 1));
            const invalidData = selectionRange === "topN" ? invalidValues.slice(0, 10) : invalidValues.slice(Math.max(invalidValues.length - 10, 1));
            let chartPrepareData = [...validData, ...invalidData];
            chartPrepareData = chartPrepareData.sort((a, b) => (a.count < b.count ? 1 : -1));
            const lengthChartData = [...chartPrepareData];
            const statistics = {
                "min": !isFloat(chartData.input_params.min) ? chartData.input_params.min : parseFloat(chartData.input_params.min).toFixed(2),
                "max": !isFloat(chartData.input_params.max) ? chartData.input_params.max : parseFloat(chartData.input_params.max).toFixed(2)
            };


            const margin = { top: 40, right: 20, bottom: 50, left: 40 };
            // const tickCount = 10;
            const height = 280;
            let width = 960 - margin.left - margin.right;
            const minBarHeightThreshold = 2;
            const barWidth = 20;
            const barOpacity = 0.5;
            const barHighlightOpacity = 0.75;
            d3.select('.extreme-value-chart > *').remove();
            const svg = d3.select(".extreme-value-chart").append("svg")
                .attr("width", "100%")
                .style("margin", "10px auto auto auto")
                .attr("height", height + margin.top + margin.bottom + 20)
                .append("g")
                .attr("width", (d, i, p) => {
                    width = p[i].parentNode.clientWidth;
                    return width;
                })
                .attr("height", height + margin.top + margin.bottom - 10)
                .attr("transform",
                    "translate(" + (margin.left + 20) + "," + ((margin.top / 2) - 15) + ")");
            // ToolTip
            d3.select(".extreme-value-chart >.tooltip").remove();
            d3.select(".extreme-value-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            const x = d3.scaleBand()
                .domain(lengthChartData.map((data) => data.key))
                .range([0, width - (margin.left + margin.right)])
                .padding(1);

            const yMax = d3.max(lengthChartData, (d) => { return d.value; });
            const y = d3.scaleLinear()
                .domain([0, yMax])
                .range([height, 0])
                .nice();
            // Tooltip div
            d3.select(".extreme-value-chart >.tooltip").remove();
            const div = d3.select(".extreme-value-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            // add the x Axis
            svg.append("g")
                .attr('class', 'axis-x')
                .attr("transform", "translate(0," + height + ")")
                .attr("color", theme.palette.chartColors.axis)
                .call(d3.axisBottom(x).ticks(10));

            // text label for the x axis
            svg.append("text")
                .attr("transform",
                    "translate(" + (width / 2) + " ," +
                    (height + margin.top + 10 + ((margin.top / 2) - 5)) + ")")
                .style("text-anchor", "middle")
                .text("Value");

            // add the y Axis
            svg.append("g")
                .attr("color", theme.palette.chartColors.axis)
                .call(d3.axisLeft(y).ticks(10));

            // text label for the y axis
            svg.append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - (margin.left + 20))
                .attr("x", 0 - (height / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text("Percentage");

            const xBandWidth = x.bandwidth() === 0 ? 60 : x.bandwidth();
            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
            svg.selectAll('.axis-x .tick-text').text("").append('tspan').text((d) => { return d; }).each((d, i, nodes) => {
                const textElement = d3.select(nodes[i]);
                wrapText(textElement, xBandWidth - 2);
            })
                .attr('cursor', 'alias')
                .on('mouseover', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", 0.4);
                    // Show Tooltip
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    let x = 0;
                    let y = 0;
                    div.attr("transform", (_, i, nodes) => {
                        const mouseCoords = d3.mouse(nodes[i].parentNode);
                        let xCo = 0;
                        let yCo = 0;
                        if (mouseCoords[0] + 10 >= width * 0.80) {
                            xCo = mouseCoords[0] - parseFloat(div
                                .attr("width"));
                            yCo = mouseCoords[1] + 10;
                        } else {
                            xCo = mouseCoords[0] + 10;
                            yCo = mouseCoords[1];
                        }
                        x = isNaN(xCo) ? mouseCoords[0] : xCo;
                        y = isNaN(yCo) ? mouseCoords[1] : yCo;
                    });
                    div.html(`${d}`)
                        .style("left", x + "px")
                        .style("top", y + "px");
                }).on('mouseout', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", 1);
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                });

            svg.selectAll(".bar")
                .data(lengthChartData)
                .enter().append("rect")
                .attr("x", (d) => { return x(d.key) - (barWidth / 2); })
                .attr("width", barWidth)
                .attr("fill", (d) => {
                    return d.isValid ? theme.palette.chartColors.valid : theme.palette.chartColors.inValid;
                })
                .attr("class", (d) => {
                    const color = d.isValid ? 'accepted' : 'not-accepted';
                    return "bar " + color;
                })
                .attr("y", height)
                .style("opacity", barOpacity)
                .on('mouseover',
                    (d, i, nodes) => {
                        d3.select(nodes[i]).style("opacity", barHighlightOpacity);

                        // Show Tooltip
                        const rect = nodes[i].getBBox();
                        div.transition()
                            .duration(200)
                            .style("opacity", 0.9)
                            .style("zIndex", 1);
                        div.html(`Value : ${d.key} <br/> Percentage : ${d.value}% <br/> Count : ${d.count}`)
                            .style("left", (rect.x + (barWidth / 2)) + "px")
                            .style("top", (rect.y - ((barWidth * 2) + barWidth)) + "px");
                    })
                .on('mouseout', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", barOpacity);
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .on('click', (d) => {
                    const filterType = d.isValid ? appConstants.QueryFilterTypes[0] : appConstants.QueryFilterTypes[1];
                    onFilterChange({ length: d.key, filterType });
                })
                .attr("height", 0)
                .transition()
                .duration(500)
                .delay((d, i) => {
                    return i * 100;
                })
                .attr("y", (d, i) => {
                    return height - y(d.value) > minBarHeightThreshold ? y(d.value) : y(d.value) - minBarHeightThreshold;
                })
                .attr("height", (d, i) => {
                    return height - y(d.value) > minBarHeightThreshold ? height - y(d.value) : (height - y(d.value) + minBarHeightThreshold);
                });


            let lines = ['min', 'max'];

            svg.selectAll('.length-interval-line')
                .data(lines)
                .enter().append("line")
                .attr("fill", "none")
                .attr("x1", (line, i, p) => {
                    let x1 = parseFloat(statistics[line] ? statistics[line] : 0);
                    d3.select(p[i]).attr("class", "length-interval-line " + line + " ").attr('line', line)
                        .attr("value", x1);
                    x1 = x(x1);
                    return x1 >= 0 ? x1 : -9999;
                })
                .attr("x2", (line) => {
                    let x2 = parseFloat(statistics[line] ? statistics[line] : 0);
                    x2 = x(x2);
                    return x2 >= 0 ? x2 : -9999;
                })
                .attr("y1", () => {
                    return y(-6) <= 305 ? y(-6) : 305;
                })
                .attr("y2", () => { return y(d3.max(lengthChartData, (d) => { return d[1] + (barWidth / 2); })); })
                .attr("stroke-linecap", "butt");

            svg.selectAll('.interval-line-text')
                .data(lines)
                .enter().append("text")
                .style('text-transform', 'capitalize')
                .style('font-size', 13)
                .text((line) => { return line; })
                .attr("x", (line, index) => {
                    let lineValue = parseFloat(statistics[line] ? statistics[line] : 0);
                    d3.select(this).attr("class", `interval-line-text "${line}`);
                    lineValue = x(lineValue);
                    return updateLegendText(lineValue, barWidth);
                })
                .attr("y", () => { return y(0) + 35; });

            const legendPosition = 45;
            //   Interval Legends
            let legendGap = 200;

            let legendX = lines.length > 1 ? 0 : (width / 2) - (legendGap / 2);
            legendX = 0;
            let legendGroup = svg.append("g")
                .attr("class", "legends")
                .attr("transform", "translate(" + (legendX - 50) + "," + (height + margin.top + legendPosition) + ")");

            // Interval Legend rectangle
            legendGroup.selectAll(".legend-group")
                .data(lines)
                .enter()
                .append("g")
                .attr("class", "legend-group")
                .style("opacity", 1)
                .append("rect")
                .attr("class", "legend-rect")
                .attr("width", 14)
                .attr("height", 8)
                .attr('x', (_, index) => { return (index * legendGap); })
                .style("fill", theme.palette.chartColors.valid);

            legendGroup.selectAll(".legend-group")
                .append("text")
                .attr("class", "legend-text")
                .attr("x", (_, index) => { return index * legendGap + 20; })
                .attr("y", 6)
                .text((line, i, nodes) => {
                    d3.select(nodes[i]).attr("class", `legend-text ${line}`);
                    return `${line} (${statistics[line]})`;
                })
                .attr("alignment-baseline", "middle");

            lines = ["Valid", "Invalid"];
            legendGap = 130;

            legendX = (width / 1.1) - 180;
            legendGroup = svg.append("g")
                .attr("class", "legends")
                .attr("transform", "translate(" + (legendX - 50) + "," + (height + margin.top + legendPosition) + ")");


            //Legend rectangle
            legendGroup.selectAll(".legend-group")
                .data(lines)
                .enter()
                .append("g")
                .attr("class", "legend-group")
                .style("opacity", 1)
                .on("mouseover", (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", barHighlightOpacity);
                    const selectedBar = (d === "valid") ? "accepted" : "not-accepted";
                    d3.selectAll(`.length-chart .bar.${selectedBar}`).style("opacity", barHighlightOpacity);
                })
                .on("mouseout", (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", 1);
                    d3.selectAll(`.bar`).style("opacity", barOpacity);
                })
                .append("rect")
                .attr("class", "legend-rect")
                .attr("width", 14)
                .attr("height", 8)
                .attr('x', (_, index) => { return (index * legendGap); })
                .style("fill", (_, index) => { return index === 0 ? theme.palette.chartColors.valid : theme.palette.chartColors.inValid; });

            // Legend label
            legendGroup.selectAll(".legend-group")
                .append("text")
                .attr("class", "legend-text")
                .attr("x", (_, index) => { return index * legendGap + 20; })
                .attr("y", 6)
                .text((line) => {
                    let percent = line === "Valid" ? chartData.valid_percentage : chartData.invalid_percentage;
                    if (Math.round(percent) !== percent) {
                        percent = percent.toFixed(2);
                    }
                    return `${line} (${percent}%)`;
                })
                .attr("alignment-baseline", "middle");

        }
    }, [attributeChange, chartData, discardEdit, edited, menuType, onFilterChange, tabIndex, theme.palette.chartColors.axis, theme.palette.chartColors.inValid, theme.palette.chartColors.valid, updateLengthTopPosition, extractValues]);

    const onRuleChange = (value) => {
        profileRuleEnable(appConstants.charts.range.type, value);
    };

    return (
        <ChartContainer
            menuProperties={
                [
                    {
                        options: appConstants.rangeFilterOptions,
                        onChange: onChangeMenu,
                        value: menuType
                    }
                ]
            }
            filterProperties={
                {
                    enableFilter: config.query,
                    onFilter: onFilterChange
                }
            }
            ruleProperties={
                {
                    edit: true,
                    onRule: onRuleChange,
                    rule: isActive
                }
            }
            title={appConstants.charts.range.name}
            id={appConstants.charts.range.id}
            chartData={chartData} >
            <Grid container className={classNames(classes.chartStyles, "extreme-value-chart")} />
        </ChartContainer >
    );
};

ExtremeValueChart.propTypes = {
    classes: PropTypes.object,
    attribute: PropTypes.object,
    profileData: PropTypes.object,
    theme: PropTypes.object,
    tabIndex: PropTypes.number,
    onFilter: PropTypes.func,
    config: PropTypes.object,
    profileRuleEnable: PropTypes.func,
    isActive: PropTypes.bool,
    attributeChange: PropTypes.bool,
    invalidQuery: PropTypes.string
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ExtremeValueChart);