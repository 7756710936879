import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Tabs, Tab } from '@material-ui/core';
import TabPanel from '../TabPanel/TabPanel.jsx';
import AuditLogs from '../../components/AuditLogs/AuditLogs.jsx';
import CompleteNess from '../../components/CompleteNess/CompleteNess.jsx';

const AuditLogComponent = (props) => {
    const { history, auditLogs, auditLogsLength, sourceId, users, fileType, logtype, connector_type } = props;
    const [tabIndex, setTabIndex] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };
    return (
            <Grid className={'auditLogContainer'}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    aria-label="dashboard tabs"
                >
                    <Tab label="Audit Logs" value={0} />
                    { connector_type !== 'Tableau' && <Tab label="Alerts" value={1} /> }
                </Tabs>
                <TabPanel value={tabIndex} index={0}>
                {
                    tabIndex === 0 &&
                    <AuditLogs auditLogs={auditLogs} auditLogsLength={auditLogsLength} sourceId={sourceId} users={users} history={history} fileType={fileType} />

                }
                </TabPanel>

                <TabPanel value={tabIndex} index={1}>
                {
                    tabIndex === 1 &&
                    <CompleteNess sourceId={sourceId} history={history} logtype={logtype} />

                }
                </TabPanel>
            </Grid>
    );
};

AuditLogComponent.propTypes = {
    users: PropTypes.array,
    auditLogs: PropTypes.array,
    fileType: PropTypes.string,
    history: PropTypes.object,
    auditLogsLength: PropTypes.number,
    sourceId: PropTypes.number,
    logtype: PropTypes.string,
    connector_type: PropTypes.string
};

export default AuditLogComponent;