import { metricsActions } from '../constants/actionTypes/metricsActionTypes';

const initialState = {
    isLoading: false,
    datasources: {
        datasources: [],
        params: {}
    },
    datasets: {
        datasets: [],
        params: {}
    },
    attributes: {
        attributes: [],
        params: {}
    },
    profileRules: {
        rules: [{ name: "null_count" }, { name: "blank_count" }, { name: "digits_count" }, { name: "characters_count" }, { name: "white_space_count" }, { name: "special_characters_count" }, { name: "zero_values_count" }],
        params: {}
    },
    dqscoreRules: {
        rules: [],
        params: {}
    },
    metricData: {},
    searchKey: {
        datasource: 'all',
        dataset: 'all',
        attributes: 'all'
    }
};

export const metricReducer = (state = initialState, action) => {
    if (!action || (action && !action.type)) { return state; }
    switch (action.type) {
        case metricsActions.GET_DATASOURCE_LIST_REQUEST:
        case metricsActions.GET_DATASOURCE_LIST_FAILURE:
            return {
                ...state,
                datasources: initialState.datasources
            };
        case metricsActions.GET_DATASOURCE_LIST_SUCCESS:
            const datasourceParams = { ...action.responseData?.params ?? {} };
            if (datasourceParams.key !== state.searchKey.datasource) {
                return { ...state };
            }
            return {
                ...state,
                datasources: action.responseData ?? initialState.datasources
            };
        case metricsActions.GET_DATASET_LIST_REQUEST:
            case metricsActions.GET_DATASET_LIST_FAILURE:
                return {
                    ...state,
                    datasets: initialState.datasets
                };
        case metricsActions.GET_DATASET_LIST_SUCCESS:
            const datasetParams = { ...action.responseData?.params ?? {} };
            if (datasetParams.key !== state.searchKey.dataset) {
                return { ...state };
            }
            return {
                ...state,
                datasets: action.responseData ?? initialState.datasets
            };
        case metricsActions.GET_ATTRIBUTE_LIST_REQUEST:
        case metricsActions.GET_ATTRIBUTE_LIST_FAILURE:
                return {
                    ...state,
                    attributes: initialState.attributes
                };
        case metricsActions.GET_ATTRIBUTE_LIST_SUCCESS:
            const attributesParams = { ...action.responseData?.params ?? {} };
            if (attributesParams.key !== state.searchKey.attributes) {
                return { ...state };
            }
            return {
                ...state,
                attributes: action.responseData ?? initialState.attributes
            };
        case metricsActions.GET_PROFILE_RULES_REQUEST:
        case metricsActions.GET_PROFILE_RULES_FAILURE:
                return {
                    ...state,
                    profileRules: initialState.profileRules
                };
        case metricsActions.GET_PROFILE_RULES_SUCCESS:
            let profileRules = action.responseData && action.responseData.rules ? action.responseData.rules : [];
            profileRules = [...profileRules, ...initialState.profileRules.rules];
            return {
                ...state,
                profileRules: { ...action.responseData, rules: [...profileRules] }
            };
        case metricsActions.GET_DQSCORE_RULES_REQUEST:
        case metricsActions.GET_DQSCORE_RULES_FAILURE:
                return {
                    ...state,
                    dqscoreRules: initialState.dqscoreRules
                };
        case metricsActions.GET_DQSCORE_RULES_SUCCESS:
            return {
                ...state,
                dqscoreRules: action.responseData ?? initialState.dqscoreRules
            };
        case metricsActions.CLEAR_WIDGET:
            return {
                ...state,
                datasources: initialState.datasources,
                datasets: initialState.datasets,
                attributes: initialState.attributes,
                profileRules: initialState.profileRules,
                dqscoreRules: initialState.dqscoreRules
            };

        case metricsActions.SET_SEARCH_KEY:
            const searchKey = { ...state.searchKey };
            searchKey[action.searchType] = action.key;
            return {
                ...state,
                searchKey
            };
        case metricsActions.SET_METRIC_DATA_SUCCESS:
                const widgetData = action.metricData ? action.metricData : {};
                const metricChartData = { ...state.metricData, ...widgetData };
                return {
                    ...state,
                    metricData: metricChartData
                };
        default:
            return state;
    }
};