import React, { useEffect } from "react";
import { Grid, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as d3 from "d3";
import Styles from "../../layouts/Styles.jsx";
import ChartStyles from "./ChartStyles.jsx";

const StockerBarChart = (props) => {
    const { classes, chartData, chartClassName, theme, colorCode, listName } = props;

    useEffect(() => {
        // const tickCount = 10;
        const margin = { top: 40, right: 20, bottom: 50, left: 40 };
        const height = 280;
        let width = 860 - margin.left - margin.right;

        const barOpacity = 0.8;
        const barHighlightOpacity = 1;

        let columns = ['Length', 'Quantile', 'Descriptive', 'Character', 'Unique', 'Range', 'Null', 'Datatype', 'Behaviour', 'Pattern', 'Validvalues', 'CustomRule'];
        let legendColors = ["#023858", "#045281", "#0667a1", "#1a7db6", "#4394c3", "#72a8cf", "#9bb9d9", "#bfc9e2", "#dbdaeb", "#efeaf4", "#1ac7c2", "#1ddfa3"];

        if (colorCode) {
            columns = listName;
            legendColors = colorCode;
        }

        d3.select(`.${chartClassName} > svg`).remove();
        d3.select(`.${chartClassName} > .legend-container`).remove();

        const svg = d3
            .select(`.${chartClassName}`).append("svg")
            .attr("width", "100%")
            .style('margin-top', '45px')
            .attr("height", height + margin.top + margin.bottom + 20)
            .append("g")
            // .attr("width", 1574)
            .attr("width", (d, i, p) => {
                width = p[i].parentNode.clientWidth;
                return width;
            })
            .attr("height", height + margin.top + margin.bottom - 10)
            .attr("transform",
                "translate(" + (margin.left + 20) + "," + ((margin.top / 2) - 15) + ")");

        const x = d3.scaleBand()
            .range([0, width])
            .domain(chartData.map((d) => { return d.name; }))
            .padding([0.9]);

        const y = d3.scaleLinear()
            .domain([0, d3.max(chartData, (d) => { return d.total; })])
            .range([height, 0]).nice();

        const z = d3.scaleOrdinal()
            .domain(columns)
            .range(legendColors);

        svg.append("g")
            .attr("class", "x-axis")
            .attr("color", theme.palette.chartColors.axis)
            .attr("transform", "translate(0," + height + ")")
            .call(d3.axisBottom(x));

        svg.append("g")
            .attr("class", "y-axis")
            .attr("color", theme.palette.chartColors.axis)
            .call(d3.axisLeft(y));

        svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
        const stackedData = d3.stack()
            .keys(columns)
            .order(d3.stackOrderNone)
            .offset(d3.stackOffsetNone)(chartData);

        d3.select(`.${chartClassName} > .tooltip`).remove();
        const div = d3.select(`.${chartClassName}`)
            .append("div")
            .attr("class", "tooltip")
            .style("opacity", 0)
            .style("zIndex", 1);

        svg.append("g")
            .selectAll("g")
            .data(stackedData)
            .enter().append("g")
            .attr("fill", (d) => { return z(d.key); })
            .attr("key", (d) => { return d.key; })
            .selectAll("rect")
            .data((d) => { return d; })
            .enter().append("rect")
            .attr("x", (d) => { return x(d.data.name); })
            .attr("y", (d) => { return y(d[1]); })
            .attr("height", height)
            .attr("width", x.bandwidth())
            .style("opacity", barOpacity)
            .on('mouseover',
                (d, i, nodes) => {
                    const key = d3.select(nodes[i].parentNode).datum().key;
                    d3.select(nodes[i]).style("opacity", barHighlightOpacity);
                    let x = 0;
                    let y = 0;
                    div.attr("transform", (_, i, nodes) => {
                        const mouseCoords = d3.mouse(nodes[i].parentNode);
                        let xCo = 0;
                        let yCo = 0;
                        if (mouseCoords[0] + 10 >= width * 0.80) {
                            xCo = mouseCoords[0] - parseFloat(div
                                .attr("width"));
                            xCo = isNaN(xCo) ? mouseCoords[0] : xCo;
                            yCo = mouseCoords[1] + 10;
                        } else {
                            xCo = mouseCoords[0] + 10;
                            yCo = mouseCoords[1];
                        }
                        x = xCo;
                        y = yCo;
                    });
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);

                    div.html(`Rule : ${key} <br /> Count : ${d[1] - d[0]}`)
                        .style("left", (x) + "px")
                        .style("top", (y) + "px");
                })
            .on('mouseout', (d, i, nodes) => {
                d3.select(nodes[i]).style("opacity", barOpacity);
                div.transition()
                    .duration(500)
                    .style("opacity", 0);
            })
            .attr("height", 0)
            .transition()
            .duration(500)
            .delay((d, i) => {
                return i * 100;
            })
            .attr("height", (d) => { return y(d[0]) - y(d[1]); });

        if (columns.length > 0) {
            const legendsContainer = d3.select(`.${chartClassName}`).append("div")
                .attr('class', 'legend-container');

            legendsContainer.selectAll('.legend-container')
                .data(columns)
                .enter()
                .append("div")
                .attr("class", "legend-column");

            legendsContainer.selectAll('.legend-column')
                .data(columns)
                .append("div")
                .style('background-color', (d, index) => legendColors[index])
                .attr("class", "legend-pattern");

            legendsContainer.selectAll('.legend-column')
                .data(columns)
                .append("span")
                .attr('class', 'legend-text')
                .style('text-transform', 'capitalize')
                .text((legend) => legend);
        }

        /*
         * const line = d3
         * .line()
         * .x((d) => {
         *     console.log(d);
         * return x(d.name);
         * })
         * .y((d) => {
         * return y(d.total);
         * });
         */

        /*
         * svg.selectAll(".line")
         *     .data(chartData)
         *     .enter()
         *     .append("path")
         *     .attr("class", "path")
         *     .attr("fill", "none")
         *     .attr("stroke", (_, i) => { return "red"; })
         *     .attr("stroke-width", 1.5)
         *     .attr('d', line);
         */

        svg.append("path")
            .datum(chartData)
            .attr("fill", "none")
            .attr("stroke", "#78D2FF")
            .attr("stroke-width", 2)
            .attr("stroke-linejoin", "round")
            .attr("d", d3.line()
                .x((d) => { return (x(d.name) + (x.bandwidth() / 2)); })
                .y((d) => { return y(d.total); })
                .curve(d3.curveMonotoneX)
            );

        svg.selectAll("myCircles")
            .data(chartData)
            .enter()
            .append("circle")
            .attr("fill", theme.palette.background.paper)
            .attr("stroke-width", 2)
            .attr("stroke", "#78D2FF")
            .attr("cx", 10)
            .attr("cy", (d) => { return y(d.total); })
            .attr("r", 4)
            .transition()
            .delay((d, i) => { return Number(i); })
            .duration(2000)
            .attr("cx", (d) => { return (x(d.name) + (x.bandwidth() / 2)); });

    }, [chartClassName, chartData, colorCode, listName, theme.palette.background.paper, theme.palette.chartColors.axis]);

    return (
        <Grid
            style={{ padding: 20 }}
            className={classNames(classes.chartStyles, chartClassName)}
        />
    );
};

StockerBarChart.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.array,
    colorCode: PropTypes.array,
    listName: PropTypes.array,
    theme: PropTypes.object,
    chartClassName: PropTypes.string
};

export default withStyles(
    (theme) => ({
        ...ChartStyles(theme),
        ...Styles(theme)
    }),
    { withTheme: true }
)(StockerBarChart);