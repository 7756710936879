const TransformationsModalStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    transformationsModal: {
        minWidth: 512,
        maxWidth: 512,
        padding: 10
    },
    scrollContainer: {
        minHeight: 300
    }
});

export default TransformationsModalStyles;