import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Button, ButtonGroup, IconButton, ClickAwayListener, FormLabel, MenuItem, TextField } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';
import Search from '../TextBox/Search.jsx';
import { appConstants } from '../../constants/appConstants';
import DatasourceListStyles from '../DatasourceList/DatasourceListStyles.jsx';
import AddIcon from '@material-ui/icons/Add';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import DropZone from 'react-dropzone';
import TextBox from '../TextBox/TextBox.jsx';

import Verified from '../../assets/images/verified.svg';
import InProgess from '../../assets/images/wip.svg';
import Deprecated from '../../assets/images/deprecated.svg';
import Issues from '../../assets/images/issues.svg';
import { useDispatch } from 'react-redux';
import { ValidatorForm } from 'react-material-ui-form-validator';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import { getAllDefinitionTags } from '../../actions/modelActions.js';


const SemanticModelFilter = (props) => {
    const { classes, changeView, filters: { search = '', sort = '', type = '', status = '', tags }, viewType, onChangeFilter, theme, history, importModel, resetFilter } = props;

    const dispatch = useDispatch();

    const [enableFilter, setFilter] = useState(false);
    const [tagList, setTagList] = useState([]);

    useEffect(() => {
        dispatch(getAllDefinitionTags()).then((response) => {
            if (response) {
                setTagList([...response]);
            } else {
                setTagList([]);
            }
        });
    }, [dispatch]);

    const renderStatusIcon = (status) => {
        if (status.toLowerCase() === "verified") {
            return Verified;
        } else if (status.toLowerCase() === "work in progress") {
            return InProgess;
        } else if (status.toLowerCase() === "issues") {
            return Issues;
        } else if (status.toLowerCase() === "deprecated") {
            return Deprecated;
        }
        return '';
    };

    const updateTagFilter = (tag, value) => {
        onChangeFilter('tags', value);
    };

    return (
        <Grid container alignItems="center" justify="space-between" className={classNames(classes.headerContainer, classes.catHeaderContainer)}>
            <Grid item>
                <Typography variant="h2" component="h2">
                    Semantics
                </Typography>
            </Grid>
            <Grid item>
                {
                    <ValidatorForm onSubmit={() => null} style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid container alignItems="center">
                            <Grid item className={classes.marginRight25}>
                                <Search
                                    value={search ? search : ''}
                                    placeholder="Search Here"
                                    className={classes.dqSearchBox}
                                    onChange={(value) => onChangeFilter('search', value)}
                                />
                            </Grid>
                            <TooltipComponent title="Filters" placement="bottom" arrow>
                                <IconButton onClick={() => setFilter(!enableFilter)} className={classNames(classes.marginRight7, classes.filterImg)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.923" viewBox="0 0 20 16.923">
                                        <g transform="translate(-3 -5)">
                                            <path id="Path_1391" data-name="Path 1391" d="M22.231,6.538H11.462A1.54,1.54,0,0,0,9.923,5H8.385A1.54,1.54,0,0,0,6.846,6.538H3.769a.769.769,0,1,0,0,1.538H6.846A1.54,1.54,0,0,0,8.385,9.615H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,8.077V6.538H9.923V7.3s0,0,0,0,0,0,0,0v.766Z" fill={theme.palette.primary.main} />
                                            <path id="Path_1392" data-name="Path 1392" d="M22.231,14.538H17.615A1.54,1.54,0,0,0,16.077,13H14.538A1.54,1.54,0,0,0,13,14.538H3.769a.769.769,0,0,0,0,1.538H13a1.54,1.54,0,0,0,1.538,1.538h1.538a1.54,1.54,0,0,0,1.538-1.538h4.615a.769.769,0,1,0,0-1.538Zm-7.692,1.538V14.538h1.538V15.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -1.846)" fill={theme.palette.primary.main} />
                                            <path id="Path_1393" data-name="Path 1393" d="M22.231,22.538H11.462A1.54,1.54,0,0,0,9.923,21H8.385a1.54,1.54,0,0,0-1.538,1.538H3.769a.769.769,0,0,0,0,1.538H6.846a1.54,1.54,0,0,0,1.538,1.538H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,24.077V22.538H9.923V23.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -3.692)" fill={theme.palette.primary.main} />
                                        </g>
                                    </svg>
                                </IconButton>
                            </TooltipComponent>
                            {
                                enableFilter &&
                                <Grid className={classNames(classes.FilterHideShowContainer)}>
                                    <ClickAwayListener onClickAway={() => setFilter(false)} mouseEvent={'onMouseUp'} touchEvent={'onTouchEnd'}>
                                        <Grid container alignItems="center" justify="space-between" className={classNames(classes.datasourceFilterSection, "datasourceFilterSection")}>
                                            <TooltipComponent title="Close" placement="bottom" arrow>
                                                <IconButton onClick={() => setFilter(!enableFilter)} className={classes.marginRight12}>
                                                    <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                        <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                                    </svg>
                                                </IconButton>
                                            </TooltipComponent>
                                            <Grid item className={classNames(classes.marginRight25, classes.statusSelect, classes.select, classes.filterselect)}>
                                                <FormLabel>
                                                    Sort By
                                                </FormLabel>
                                                <TextBox
                                                    name="sort"
                                                    select
                                                    onChange={(event, value) => { onChangeFilter("sort", event.target.value); }}
                                                    value={sort}
                                                    className={classes.glossarySelect}
                                                    SelectProps={
                                                        {
                                                            MenuProps: {
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "center"
                                                                },
                                                                transformOrigin: {
                                                                    vertical: "top",
                                                                    horizontal: "center"
                                                                },
                                                                getContentAnchorEl: null
                                                            }
                                                            // IconComponent: () => DropDownIcon()
                                                        }
                                                    }

                                                >
                                                    {
                                                        [{ name: "", value: "" }, ...appConstants.sortOptions].map((sort, index) =>
                                                            <MenuItem key={index} className={classes.menuItem} value={sort.value}>
                                                                <img src={renderStatusIcon(sort.value)} alt="" className={classes.marginRight5} />
                                                                <Typography>
                                                                    {sort.name}
                                                                </Typography>
                                                            </MenuItem>
                                                        )
                                                    }
                                                </TextBox>
                                            </Grid>
                                            <Grid item className={classNames(classes.marginRight25, classes.statusSelect, classes.select, classes.filterselect)}>
                                                <FormLabel>
                                                    Status
                                                </FormLabel>
                                                <TextBox
                                                    name="status"
                                                    select
                                                    onChange={(event, value) => { onChangeFilter("status", event.target.value); }}
                                                    value={status}
                                                    className={classes.glossarySelect}
                                                    SelectProps={
                                                        {
                                                            MenuProps: {
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "center"
                                                                },
                                                                transformOrigin: {
                                                                    vertical: "top",
                                                                    horizontal: "center"
                                                                },
                                                                getContentAnchorEl: null
                                                            }
                                                            // IconComponent: () => DropDownIcon()
                                                        }
                                                    }

                                                >
                                                    {
                                                        [{ name: "", value: "" }, ...appConstants.glossaryStatus].map((status, index) =>
                                                            <MenuItem key={index} className={classes.menuItem} value={status.value}>
                                                                <img src={renderStatusIcon(status.value)} alt="" className={classes.marginRight5} />
                                                                <Typography>
                                                                    {status.name}
                                                                </Typography>
                                                            </MenuItem>
                                                        )
                                                    }
                                                </TextBox>
                                            </Grid>
                                            <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                                                <FormLabel>
                                                    Type
                                                </FormLabel>
                                                <TextBox
                                                    // label="Source Type"
                                                    name="type"
                                                    placeholder="select"
                                                    value={type}
                                                    select
                                                    onChange={(event, value) => { onChangeFilter("type", event.target.value); }}
                                                    SelectProps={
                                                        {
                                                            /*
                                                             * multiple: false,
                                                             * value: type || [],
                                                             * onChange: (event) => onChangeFilter("type", event.target.value),
                                                             * renderValue: (selected) => selected.join(', '),
                                                             */
                                                            MenuProps: {
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "center"
                                                                },
                                                                transformOrigin: {
                                                                    vertical: "top",
                                                                    horizontal: "center"
                                                                },
                                                                getContentAnchorEl: null
                                                            }
                                                            // IconComponent: () => DropDownIcon()
                                                        }
                                                    }
                                                    fullWidth
                                                >
                                                    {
                                                        [{ name: 'Categories', value: "category" }, { name: 'Terms', value: "field" }].map((elem, index) =>
                                                            <MenuItem key={index} value={elem.value}>
                                                                <Typography>
                                                                    {elem.name}
                                                                </Typography>
                                                                {/* <CheckboxComponent checked={type.includes(elem.value)} name={elem.name} checkboxLabel={elem.name} /> */}
                                                            </MenuItem>
                                                        )
                                                    }
                                                </TextBox>
                                            </Grid>
                                            <Grid item className={classNames(classes.userSelectContainer, classes.tagFilter)}>
                                                <AutoCompleteInput
                                                    name="tags"
                                                    popperWidth={300}
                                                    options={tagList}
                                                    freeSolo
                                                    value={tags || []}
                                                    getOptionLabel={
                                                        (option) => {
                                                            if (option && option.name) {
                                                                return option.name;
                                                            }
                                                            return option;
                                                        }
                                                    }
                                                    renderInput={
                                                        (params) =>
                                                            <TextField {...params}
                                                                placeholder="Select Tags"
                                                                margin="normal"
                                                            />
                                                    }
                                                    tab="drift"
                                                    onChange={(_, selectedValue) => updateTagFilter("selected_tag", selectedValue)}
                                                    openOnFocus
                                                    disableCloseOnSelect
                                                    disableClearable
                                                    fullWidth
                                                    multiple
                                                    forcePopupIcon={false}
                                                    noOptionsText={"No Tags Found"}
                                                    noIcon
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TooltipComponent title="Clear Filter" placement="bottom" arrow>
                                                    <IconButton onClick={() => resetFilter()}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22.795" viewBox="0 0 20 22.795">
                                                            <g id="filter_10_" data-name="filter (10)" transform="translate(-31.388)">
                                                                <path id="Path_1437" data-name="Path 1437" d="M46.071,0a5.326,5.326,0,0,0-5.1,3.8h-8.59a1,1,0,0,0-.814,1.564l5.776,7.882a1.046,1.046,0,0,1,.185.6V21.75a1.044,1.044,0,0,0,1.668.835l2.564-1.937a1.527,1.527,0,0,0,.612-1.22V13.84a1.047,1.047,0,0,1,.185-.6l2.07-2.825A5.31,5.31,0,1,0,46.071,0ZM41.477,12.462a2.375,2.375,0,0,0-.438,1.377c0,6.076.033,5.658-.075,5.737l-2.1,1.586V13.84a2.376,2.376,0,0,0-.428-1.364l-5.38-7.341h7.7A5.307,5.307,0,0,0,43.369,9.88Zm4.594-3.18a3.973,3.973,0,1,1,3.982-3.973A3.982,3.982,0,0,1,46.071,9.282Z" transform="translate(0)" fill="#f38080" />
                                                                <path id="Path_1438" data-name="Path 1438" d="M321.45,76.089a.668.668,0,0,0-.944,0l-.793.791-.793-.791a.668.668,0,0,0-.944.945l.791.79-.791.79a.668.668,0,0,0,.944.945l.793-.791.793.791a.668.668,0,0,0,.944-.945l-.791-.79.791-.79A.668.668,0,0,0,321.45,76.089Z" transform="translate(-273.642 -72.515)" fill="#f38080" />
                                                            </g>
                                                        </svg>
                                                    </IconButton>
                                                </TooltipComponent>
                                            </Grid>
                                        </Grid>
                                    </ClickAwayListener>
                                </Grid>
                            }
                            <Grid item className={classes.marginRight12}>
                                <ButtonGroup className={classNames(classes.buttonGroup)}>
                                    <Button
                                        variant="contained"
                                        onClick={() => changeView(appConstants.semanticTypes[0])}
                                        className={classNames(viewType === appConstants.semanticTypes[0] ? 'active' : '')}>
                                        <TooltipComponent title="Glossary" placement="bottom" arrow>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12.377" viewBox="0 0 18 12.377">
                                                <g id="list-text" transform="translate(-0.001 -80)">
                                                    <g id="Group_4258" data-name="Group 4258" transform="translate(3.381 80)">
                                                        <g id="Group_4257" data-name="Group 4257" transform="translate(0 0)">
                                                            <path id="Path_2554" data-name="Path 2554" d="M108.551,80H97.3a1.125,1.125,0,1,0,0,2.249h11.246a1.125,1.125,0,0,0,0-2.249Z" transform="translate(-96.18 -80)" fill={viewType === appConstants.semanticTypes[0] ? theme.palette.background.paper : theme.palette.grey.light} />
                                                        </g>
                                                    </g>
                                                    <g id="Group_4260" data-name="Group 4260" transform="translate(0.001 80.005)">
                                                        <g id="Group_4259" data-name="Group 4259" transform="translate(0 0)">
                                                            <path id="Path_2555" data-name="Path 2555" d="M2.256,81.255a1.125,1.125,0,0,0-2.249,0,.957.957,0,0,0,0,.219,1.014,1.014,0,0,0,.062.214,1.305,1.305,0,0,0,.107.191,1.029,1.029,0,0,0,.141.174,1,1,0,0,0,.169.135.916.916,0,0,0,.191.107.928.928,0,0,0,.236.084.987.987,0,0,0,.219,0,1.125,1.125,0,0,0,.793-.326,1.029,1.029,0,0,0,.141-.174,1.305,1.305,0,0,0,.107-.191,1.044,1.044,0,0,0,.084-.214A.957.957,0,0,0,2.256,81.255Z" transform="translate(-0.001 -80.135)" fill={viewType === appConstants.semanticTypes[0] ? theme.palette.background.paper : theme.palette.grey.light} />
                                                        </g>
                                                    </g>
                                                    <g id="Group_4262" data-name="Group 4262" transform="translate(0.007 85.065)">
                                                        <g id="Group_4261" data-name="Group 4261" transform="translate(0 0)">
                                                            <path id="Path_2556" data-name="Path 2556" d="M2.429,225.255a.961.961,0,0,0,0-.219.911.911,0,0,0-.084-.208,1.124,1.124,0,0,0-.107-.2.809.809,0,0,0-.141-.169,1.125,1.125,0,0,0-1.917.793,1.122,1.122,0,0,0,.186.624,1.08,1.08,0,0,0,.146.169.821.821,0,0,0,.169.141.912.912,0,0,0,.191.107.957.957,0,0,0,.214.062.934.934,0,0,0,.219.022.958.958,0,0,0,.219,0,.912.912,0,0,0,.208-.062.939.939,0,0,0,.2-.107.821.821,0,0,0,.169-.141.821.821,0,0,0,.141-.169.9.9,0,0,0,.107-.191.951.951,0,0,0,.084-.236A.93.93,0,0,0,2.429,225.255Z" transform="translate(-0.18 -224.135)" fill={viewType === appConstants.semanticTypes[0] ? theme.palette.background.paper : theme.palette.grey.light} />
                                                        </g>
                                                    </g>
                                                    <g id="Group_4264" data-name="Group 4264" transform="translate(0.007 90.126)">
                                                        <g id="Group_4263" data-name="Group 4263" transform="translate(0 0)">
                                                            <path id="Path_2557" data-name="Path 2557" d="M2.427,369.255a.965.965,0,0,0,0-.219.957.957,0,0,0-.084-.214,1.307,1.307,0,0,0-.107-.191.821.821,0,0,0-.141-.169,1.125,1.125,0,0,0-1.586,0,.821.821,0,0,0-.141.169,1.307,1.307,0,0,0-.107.191.921.921,0,0,0-.062.214.966.966,0,0,0-.022.219,1.125,1.125,0,0,0,.332.793.82.82,0,0,0,.169.141.913.913,0,0,0,.191.107.957.957,0,0,0,.214.062.934.934,0,0,0,.219.022.958.958,0,0,0,.219,0,.913.913,0,0,0,.208-.062.939.939,0,0,0,.2-.107.82.82,0,0,0,.169-.141.821.821,0,0,0,.141-.169.9.9,0,0,0,.107-.191.954.954,0,0,0,.084-.236A.93.93,0,0,0,2.427,369.255Z" transform="translate(-0.178 -368.135)" fill={viewType === appConstants.semanticTypes[0] ? theme.palette.background.paper : theme.palette.grey.light} />
                                                        </g>
                                                    </g>
                                                    <g id="Group_4266" data-name="Group 4266" transform="translate(3.381 85.061)">
                                                        <g id="Group_4265" data-name="Group 4265" transform="translate(0 0)">
                                                            <path id="Path_2558" data-name="Path 2558" d="M109.675,224H97.3a1.125,1.125,0,1,0,0,2.249h12.371a1.125,1.125,0,0,0,0-2.249Z" transform="translate(-96.18 -224)" fill={viewType === appConstants.semanticTypes[0] ? theme.palette.background.paper : theme.palette.grey.light} />
                                                        </g>
                                                    </g>
                                                    <g id="Group_4268" data-name="Group 4268" transform="translate(3.381 90.121)">
                                                        <g id="Group_4267" data-name="Group 4267" transform="translate(0 0)">
                                                            <path id="Path_2559" data-name="Path 2559" d="M104.615,368H97.3a1.125,1.125,0,1,0,0,2.249h7.31a1.125,1.125,0,1,0,0-2.249Z" transform="translate(-96.18 -368)" fill={viewType === appConstants.semanticTypes[0] ? theme.palette.background.paper : theme.palette.grey.light} />
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </TooltipComponent>
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => changeView(appConstants.semanticTypes[1])}
                                        className={classNames(viewType === appConstants.semanticTypes[1] ? 'active' : '')}>
                                        <TooltipComponent title="Fields" placement="bottom" arrow>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18.167" viewBox="0 0 16 18.167">
                                                <g id="block_1_" data-name="block (1)" transform="translate(-30.534 0)">
                                                    <path id="Path_2551" data-name="Path 2551" d="M53.052,8.619,60.518,4.31,53.052,0,45.586,4.31Z" transform="translate(-14.518)" fill={viewType === appConstants.semanticTypes[1] ? theme.palette.background.paper : theme.palette.grey.light} />
                                                    <path id="Path_2552" data-name="Path 2552" d="M38,151.866l-7.466-4.309v8.621L38,160.488Z" transform="translate(0 -142.321)" fill={viewType === appConstants.semanticTypes[1] ? theme.palette.background.paper : theme.palette.grey.light} />
                                                    <path id="Path_2553" data-name="Path 2553" d="M271.056,151.866v8.623l7.466-4.31v-8.621Z" transform="translate(-231.988 -142.321)" fill={viewType === appConstants.semanticTypes[1] ? theme.palette.background.paper : theme.palette.grey.light} />
                                                </g>
                                            </svg>
                                        </TooltipComponent>
                                    </Button>
                                </ButtonGroup>
                            </Grid>
                            {
                                viewType === appConstants.semanticTypes[0] ?
                                    <Grid item>
                                        <DropZone accept=".json" onDrop={(acceptFiles) => importModel(acceptFiles)}>
                                            {
                                                ({ getRootProps, getInputProps }) => (
                                                    <TooltipComponent title="Import Field" arrow>
                                                        <IconButton className={classNames(classes.padding5, classes.marginLeft5, classes.marginRight5)}>
                                                            <span {...getRootProps()}>
                                                                <input {...getInputProps()} />
                                                                <svg fill={theme.palette.primary.main} xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24.343 23.846">
                                                                    <g id="download_button" transform="translate(-1600.338 -267.233)">
                                                                        <g id="Group_3" data-name="Group 3">
                                                                            <path id="Path_4" data-name="Path 4" d="M1624.68,291.079h-24.343V276.71h2v12.369h20.343V276.71h2Z" />
                                                                            <path id="Path_5" data-name="Path 5" d="M1617.779,276.513l-5.32,5.27-5.4-5.35,1.4-1.42,3.04,3v-10.78h2v10.7l2.87-2.84Z" />
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </IconButton>
                                                    </TooltipComponent>
                                                )
                                            }
                                        </DropZone>
                                        <TooltipComponent title="Create new semantic" placement="bottom" arrow>
                                            <IconButton className={classes.addIconButton}
                                                classes={
                                                    {
                                                        root: classes.addIconButtonRoot
                                                    }
                                                }
                                                onClick={() => history.push('/createmodel')}
                                            >
                                                <AddIcon className={classes.addIcon} />
                                            </IconButton>
                                        </TooltipComponent>

                                    </Grid> :
                                    <Grid item>
                                        <TooltipComponent title="Create new semantic model" placement="bottom" arrow>
                                            <IconButton className={classes.addIconButton}
                                                classes={
                                                    {
                                                        root: classes.addIconButtonRoot
                                                    }
                                                }
                                                onClick={() => history.push('/createmaster')}
                                            >
                                                <AddIcon className={classes.addIcon} />
                                            </IconButton>
                                        </TooltipComponent>
                                    </Grid>
                            }

                        </Grid>
                    </ValidatorForm>
                }
            </Grid>
        </Grid>
    );
};

SemanticModelFilter.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    changeView: PropTypes.func,
    search: PropTypes.string,
    viewType: PropTypes.string,
    onChangeFilter: PropTypes.func,
    history: PropTypes.object,
    importModel: PropTypes.func,
    filters: PropTypes.func,
    resetFilter: PropTypes.func
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SemanticModelFilter);