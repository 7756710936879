export const notificationActions = {

    TOGGLE_SIDE_PANEL: 'TOGGLE_SIDE_PANEL',

    CREATE_ACTION_ITEM_REQUEST: 'CREATE_ACTION_ITEM_REQUEST',
    CREATE_ACTION_ITEM_SUCCESS: 'CREATE_ACTION_ITEM_SUCCESS',
    CREATE_ACTION_ITEM_FAILURE: 'CREATE_ACTION_ITEM_FAILURE',

    CREATE_ACTION_ITEM_COMMENT_REQUEST: 'CREATE_ACTION_ITEM_COMMENT_REQUEST',
    CREATE_ACTION_ITEM_COMMENT_SUCCESS: 'CREATE_ACTION_ITEM_COMMENT_SUCCESS',
    CREATE_ACTION_ITEM_COMMENT_FAILURE: 'CREATE_ACTION_ITEM_COMMENT_FAILURE',

    GET_ACTION_ITEM_BY_USER_REQUEST: 'GET_ACTION_ITEM_BY_USER_REQUEST',
    GET_ACTION_ITEM_BY_USER_SUCCESS: 'GET_ACTION_ITEM_BY_USER_SUCCESS',
    GET_ACTION_ITEM_BY_USER_FAILURE: 'GET_ACTION_ITEM_BY_USER_FAILURE',

    UPDATE_ACTION_ITEM_REQUEST: 'UPDATE_ACTION_ITEM_REQUEST',
    UPDATE_ACTION_ITEM_SUCCESS: 'UPDATE_ACTION_ITEM_SUCCESS',
    UPDATE_ACTION_ITEM_FAILURE: 'UPDATE_ACTION_ITEM_FAILURE',

    GET_ACTION_ITEM_COUNT_REQUEST: 'GET_ACTION_ITEM_COUNT_REQUEST',
    GET_ACTION_ITEM_COUNT_SUCCESS: 'GET_ACTION_ITEM_COUNT_SUCCESS',
    GET_ACTION_ITEM_COUNT_FAILURE: 'GET_ACTION_ITEM_COUNT_FAILURE',

    GET_ALL_ACTION_ITEM_REQUEST: 'GET_ALL_ACTION_ITEM_REQUEST',
    GET_ALL_ACTION_ITEM_SUCCESS: 'GET_ALL_ACTION_ITEM_SUCCESS',
    GET_ALL_ACTION_ITEM_FAILURE: 'GET_ALL_ACTION_ITEM_FAILURE',

    GET_ALL_ACTION_ITEM_METRICS_ANALYSIS_REQUEST: 'GET_ALL_ACTION_ITEM_METRICS_ANALYSIS_REQUEST',
    GET_ALL_ACTION_ITEM_METRICS_ANALYSIS_SUCCESS: 'GET_ALL_ACTION_ITEM_METRICS_ANALYSIS_SUCCESS',
    GET_ALL_ACTION_ITEM_METRICS_ANALYSIS_FAILURE: 'GET_ALL_ACTION_ITEM_METRICS_ANALYSIS_FAILURE',

    GET_MY_ACTION_ITEM_REQUEST: 'GET_MY_ACTION_ITEM_REQUEST',
    GET_MY_ACTION_ITEM_SUCCESS: 'GET_MY_ACTION_ITEM_SUCCESS',
    GET_MY_ACTION_ITEM_FAILURE: 'GET_MY_ACTION_ITEM_FAILURE',

    DELETE_ACTION_ITEM_REQUEST: 'DELETE_ACTION_ITEM_REQUEST',
    DELETE_ACTION_ITEM_SUCCESS: 'DELETE_ACTION_ITEM_SUCCESS',
    DELETE_ACTION_ITEM_FAILURE: 'DELETE_ACTION_ITEM_FAILURE',

    GET_USER_NOTIFICATION_REQUEST: 'GET_USER_NOTIFICATION_REQUEST',
    GET_USER_NOTIFICATION_SUCCESS: 'GET_USER_NOTIFICATION_SUCCESS',
    GET_USER_NOTIFICATION_FAILURE: 'GET_USER_NOTIFICATION_FAILURE',

    GET_USER_AUDIT_REQUEST: 'GET_USER_AUDIT_REQUEST',
    GET_USER_AUDIT_SUCCESS: 'GET_USER_AUDIT_SUCCESS',
    GET_USER_AUDIT_FAILURE: 'GET_USER_AUDIT_FAILURE',

    UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',

    UPDATE_USER_NOTIFICATION_REQUEST: 'UPDATE_USER_NOTIFICATION_REQUEST',
    UPDATE_USER_NOTIFICATION_SUCCESS: 'UPDATE_USER_NOTIFICATION_SUCCESS',
    UPDATE_USER_NOTIFICATION_FAILURE: 'UPDATE_USER_NOTIFICATION_FAILURE',

    UPDATE_NOTIFICATION_PPROPERTY: 'UPDATE_NOTIFICATION_PPROPERTY',

    UPDATE_CONVERSATION_LIST: 'UPDATE_CONVERSATION_LIST',

    GET_USER_NOTIFICATION_METRICS_REQUEST: 'GET_USER_NOTIFICATION_METRICS_REQUEST',
    GET_USER_NOTIFICATION_METRICS_SUCCESS: 'GET_USER_NOTIFICATION_METRICS_SUCCESS',
    GET_USER_NOTIFICATION_METRICS_FAILURE: 'GET_USER_NOTIFICATION_METRICS_FAILURE',

    CREATE_CONVERSATION_REQUEST: 'CREATE_CONVERSATION_REQUEST',
    CREATE_CONVERSATION_SUCCESS: 'CREATE_CONVERSATION_SUCCESS',
    CREATE_CONVERSATION_FAILURE: 'CREATE_CONVERSATION_FAILURE',

    GET_CONVERSATION_BY_SOURCE_REQUEST: 'GET_CONVERSATION_BY_SOURCE_REQUEST',
    GET_CONVERSATION_BY_SOURCE_SUCCESS: 'GET_CONVERSATION_BY_SOURCE_SUCCESS',
    GET_CONVERSATION_BY_SOURCE_FAILURE: 'GET_CONVERSATION_BY_SOURCE_FAILURE',

    GROUP_CONVERSATION_LIST: 'GROUP_CONVERSATION_LIST',

    GROUP_ALL_CONVERSATION_LIST: 'GROUP_ALL_CONVERSATION_LIST',

    SELECT_CONVERSATION: 'SELECT_CONVERSATION',

    GET_ALL_CONVERSATION_BY_SOURCE_REQUEST: 'GET_ALL_CONVERSATION_BY_SOURCE_REQUEST',
    GET_ALL_CONVERSATION_BY_SOURCE_SUCCESS: 'GET_ALL_CONVERSATION_BY_SOURCE_SUCCESS',
    GET_ALL_CONVERSATION_BY_SOURCE_FAILURE: 'GET_ALL_CONVERSATION_BY_SOURCE_FAILURE',

    UPDATE_CONVERSATION_READ_REQUEST: 'UPDATE_CONVERSATION_READ_REQUEST',
    UPDATE_CONVERSATION_READ_SUCCESS: 'UPDATE_CONVERSATION_READ_SUCCESS',
    UPDATE_CONVERSATION_READ_FAILURE: 'UPDATE_CONVERSATION_READ_FAILURE',

    GET_ALL_CONVERSATION_METRICS_ANALYSIS_REQUEST: 'GET_ALL_CONVERSATION_METRICS_ANALYSIS_REQUEST',
    GET_ALL_CONVERSATION_METRICS_ANALYSIS_SUCCESS: 'GET_ALL_CONVERSATION_METRICS_ANALYSIS_SUCCESS',
    GET_ALL_CONVERSATION_METRICS_ANALYSIS_FAILURE: 'GET_ALL_CONVERSATION_METRICS_ANALYSIS_FAILURE',

    GET_CONVERSATION_COUNT_REQUEST: 'GET_CONVERSATION_COUNT_REQUEST',
    GET_CONVERSATION_COUNT_SUCCESS: 'GET_CONVERSATION_COUNT_SUCCESS',
    GET_CONVERSATION_COUNT_FAILURE: 'GET_CONVERSATION_COUNT_FAILURE',

    READ_NOTIFICATION_REQUEST: 'READ_NOTIFICATION_REQUEST',
    READ_NOTIFICATION_SUCCESS: 'READ_NOTIFICATION_SUCCESS',
    READ_NOTIFICATION_FAILURE: 'READ_NOTIFICATION_FAILURE',

    GET_NOTIFICATION_COUNT_REQUEST: 'GET_NOTIFICATION_COUNT_REQUEST',
    GET_NOTIFICATION_COUNT_SUCCESS: 'GET_NOTIFICATION_COUNT_SUCCESS',
    GET_NOTIFICATION_COUNT_FAILURE: 'GET_NOTIFICATION_COUNT_FAILURE',

    GET_CONVERSATION_UNREAD_REQUEST: 'GET_CONVERSATION_UNREAD_REQUEST',
    GET_CONVERSATION_UNREAD_SUCCESS: 'GET_CONVERSATION_UNREAD_SUCCESS',
    GET_CONVERSATION_UNREAD_FAILURE: 'GET_CONVERSATION_UNREAD_FAILURE',

    NAVIGATE: 'NAVIGATE',

    GET_ACTION_ITEM_DETAIL_REQUEST: 'GET_ACTION_ITEM_DETAIL_REQUEST',
    GET_ACTION_ITEM_DETAIL_SUCCESS: 'GET_ACTION_ITEM_DETAIL_SUCCESS',
    GET_ACTION_ITEM_DETAIL_FAILURE: 'GET_ACTION_ITEM_DETAIL_FAILURE',

    GET_NOTIFICATION_METRIC_REQUEST: 'GET_NOTIFICATION_METRIC_REQUEST',
    GET_NOTIFICATION_METRIC_SUCCESS: 'GET_NOTIFICATION_METRIC_SUCCESS',
    GET_NOTIFICATION_METRIC_FAILURE: 'GET_NOTIFICATION_METRIC_FAILURE',

    GET_COMMENTS_REQUEST: 'GET_COMMENTS_REQUEST',
    GET_COMMENTS_SUCCESS: 'GET_COMMENTS_SUCCESS',
    GET_COMMENTS_FAILURE: 'GET_COMMENTS_FAILURE',

    CREATE_COMMENTS_REQUEST: 'CREATE_COMMENTS_REQUEST',
    CREATE_COMMENTS_SUCCESS: 'CREATE_COMMENTS_SUCCESS',
    CREATE_COMMENTS_FAILURE: 'CREATE_COMMENTS_FAILURE',

    GET_COMMENT_COUNT_REQUEST: 'GET_COMMENT_COUNT_REQUEST',
    GET_COMMENT_COUNT_SUCCESS: 'GET_COMMENT_COUNT_SUCCESS',
    GET_COMMENT_COUNT_FAILURE: 'GET_COMMENT_COUNT_FAILURE',

    DELETE_COMMENT_REQUEST: 'DELETE_COMMENT_REQUEST',
    DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
    DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE',

    UPDATE_COMMENT_REQUEST: 'UPDATE_COMMENT_REQUEST',
    UPDATE_COMMENT_SUCCESS: 'UPDATE_COMMENT_SUCCESS',
    UPDATE_COMMENT_FAILURE: 'UPDATE_COMMENT_FAILURE',

    SWITCH_CATALOG: 'SWITCH_CATALOG',
    CLEAR_NOTIFICATIONS: 'CLEAR_NOTIFICATIONS',
    SET_NOTIFICATION_FILTER: 'SET_NOTIFICATION_FILTER',

    GET_NOTIFICATION_RULES_REQUEST: 'GET_NOTIFICATION_RULES_REQUEST',
    GET_NOTIFICATION_RULES_SUCCESS: 'GET_NOTIFICATION_RULES_SUCCESS',
    GET_NOTIFICATION_RULES_FAILURE: 'GET_NOTIFICATION_RULES_FAILURE',

    SET_DRIFT_FILTER: 'SET_DRIFT_FILTER',

    GET_DRIFT_REPORT_REQUEST: 'GET_DRIFT_REPORT_REQUEST',
    GET_DRIFT_REPORT_SUCCESS: 'GET_DRIFT_REPORT_SUCCESS',
    GET_DRIFT_REPORT_FAILURE: 'GET_DRIFT_REPORT_FAILURE',

    GET_DRIFT_RULES_REQUEST: 'GET_DRIFT_RULES_REQUEST',
    GET_DRIFT_RULES_SUCCESS: 'GET_DRIFT_RULES_SUCCESS',
    GET_DRIFT_RULES_FAILURE: 'GET_DRIFT_RULES_FAILURE',

    TOGGLE_GROUP_DRIFT_REPORT_VIEW: 'TOGGLE_GROUP_DRIFT_REPORT_VIEW',

    GET_GROUP_DRIFT_REPORT_REQUEST: 'GET_GROUP_DRIFT_REPORT_REQUEST',
    GET_GROUP_DRIFT_REPORT_SUCCESS: 'GET_GROUP_DRIFT_REPORT_SUCCESS',
    GET_GROUP_DRIFT_REPORT_FAILURE: 'GET_GROUP_DRIFT_REPORT_FAILURE',

    GET_GROUPBY_ATTRIBUTES_REQUEST: 'GET_GROUPBY_ATTRIBUTES_REQUEST',
    GET_GROUPBY_ATTRIBUTES_SUCCESS: 'GET_GROUPBY_ATTRIBUTES_SUCCESS',
    GET_GROUPBY_ATTRIBUTES_FAILURE: 'GET_GROUPBY_ATTRIBUTES_FAILURE'

};