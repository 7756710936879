import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Chip, Grid, Paper, Popover, Typography, withStyles } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import ChipPopoverStyles from './ChipPopoverStyles.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';


const ChipPopover = (props) => {
    const { classes, values, displayName, displayCount, isEditable, chipSize } = props;
    const [anchorEl, setAnchorElement] = useState(null);

    return (
        <Fragment>
                    <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                        <Grid item>
                            <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                                {
                                    values && values.map((value, index) =>
                                        <Fragment key={index}>
                                            {
                                                index < displayCount &&
                                                <ToolTipComponent title={displayName ? value[displayName] : value}>
                                                    <Grid className={classNames(classes.chipList, classes.chipContainer)}>
                                                        <Chip
                                                            className={classes.chip}
                                                            classes={
                                                                {
                                                                    root: classes.nomargin
                                                                }
                                                            }
                                                            style={{ width: chipSize }}
                                                            label={displayName ? value[displayName] : value}
                                                            disabled={!isEditable}
                                                        />
                                                    </Grid>
                                                </ToolTipComponent>
                                            }
                                            {
                                                index === values.length - 1 && index >= displayCount &&
                                                <Typography className={classes.displayItemCount} onClick={(event) => setAnchorElement(event.target)}>
                                                    {`+${(values.length - displayCount)}`}
                                                </Typography>
                                            }
                                        </Fragment>
                                    )
                                }
                            </Grid>
                        </Grid>
                    </Grid>
            {
                anchorEl &&
                <Popover anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorElement(null)}
                    anchorOrigin={
                        {
                            vertical: 'center',
                            horizontal: 'center'
                        }
                    }
                    transformOrigin={
                        {
                            vertical: 'center',
                            horizontal: 'center'
                        }
                    }
                >
                    {
                        <Paper elevation={2} className={classes.listContainer}>
                            {
                                values && values.map((value, index) => (
                                    index >= displayCount &&
                                    <Grid key={`value-${index}`} container direction="row" justify="flex-start" alignItems="center">
                                        <Grid item xl={9} lg={9} md={9} sm={9} xs={9}>
                                            <Typography noWrap>
                                                {displayName ? value[displayName] : value}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Paper>

                    }

                </Popover>
            }
        </Fragment>
    );
};

ChipPopover.defaultProps = {
    displayCount: 2,
    values: [],
    isEditable: true,
    enableAddButton: true
};

ChipPopover.propTypes = {
    classes: PropTypes.object,
    values: PropTypes.array,
    displayCount: PropTypes.number,
    displayName: PropTypes.string,
    isEditable: PropTypes.bool,
    chipSize: PropTypes.number
};

export default withStyles((theme) => ({
    ...ChipPopoverStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ChipPopover);