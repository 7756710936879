import React, { useEffect, useCallback, useState } from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import DomainListView from './DomainListView.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { updateViews } from '../../actions/datasourceActions';
import { updateRuleWeightage, getDomainDataset } from '../../actions/domainActions';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import Loader from '../Loaders/Loader.jsx';
import DomainDataPreview from '../DomainDataPreview/DomainDataPreview.jsx';
import Search from '../TextBox/Search.jsx';
import DomainStyles from './DomainStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';

const DomainRules = (props) => {
    const { domainId, history, selectedRule, redirectData, classes, dq_score_threshold, sourceRules, setRules, rulesLoading } = props;
    // const [sourceRules, setRules] = useState([]);
    const [groupedRule, setGroupedRule] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [isInvalidDataset, setisInvalidDataset] = useState(false);
    const [isInvalidAttribute, setisInvalidAttribute] = useState(false);
    const [group_by_datasource, setgroup_by_datasource] = useState(false);
    const [datasets, setDatasets] = useState([]);
    const dispatch = useDispatch();
    const [open, setOpenDialog] = useState(false);
    const [previewParams, setPreviewParams] = useState({
        type: 'datasource'
    });
    const [search, setSearch] = useState('');
    const userConfig = useSelector(({ setting }) => setting.user_config);

    const groupDomainRule = useCallback((rules) => {
        const datasources = [...new Set(rules.map((rule) => rule.source_id))];
        const ruleList = datasources.map((datasource) => {
            let totalRecords = 0;
            let datsourceInvalidRecordCount = 0;
            const datasets = [...new Set(rules.filter((rule) => rule.source_id === datasource).map((rule) => rule.dataset_id))].slice(0, 5);
            let totalDatasourceDqscore = 0;
            let totalMetricWeight = 0;

            const datasetList = datasets.map((dataset) => {
                const filterRules = rules.filter((rule) => rule.dataset_id === dataset);
                const attributeList = [...new Set(filterRules.map((rule) => rule.attribute_id))].slice(0, 10);
                const attributes = attributeList.map((attribute) => {
                    const attributeInfo = rules.find((rule) => rule.attribute_id === attribute);
                    const attributeInvalidRowCount = attributeInfo && attributeInfo.attribute_invalid_rows_count ? attributeInfo.attribute_invalid_rows_count : 0;
                    const ruleDetails = filterRules.filter((rule) => rule.attribute_id === attribute).map((rule) => {
                        const totalRecords = rule.total_records ? rule.total_records : 0;
                        const invalidRecords = rule.invalid_records ? rule.invalid_records : 0;
                        const invalidPercentage = invalidRecords && totalRecords ? (invalidRecords / totalRecords) * 100 : 0;
                        let dqscore = rule.dqscore;
                        if (rule.polarity === 'negative') {
                            dqscore = 100 - dqscore;
                        }
                        return {
                            "name": rule.rule_name ? rule.rule_name : "",
                            "class_name": rule.class_name ? rule.class_name : "",
                            "total_records": totalRecords,
                            "metric_weight": rule.metric_weight ? rule.metric_weight : 100,
                            "dqscore": dqscore,
                            "polarity": rule.polarity,
                            "invalid_records": invalidRecords,
                            "allow_dqscore": rule.allow_dqscore,
                            "ruleId": rule.rule_id ? rule.rule_id : 0,
                            "dataset_id": rule.dataset_id ? rule.dataset_id : 0,
                            "is_active": Boolean(rule.allow_dqscore && rule.is_active),
                            "invalid_percentage": invalidPercentage,
                            "attribute_name": attributeInfo.attribute_name ? attributeInfo.attribute_name : "",
                            "actual_score": rule.actual_score ? rule.actual_score : 0,
                            "source_id": datasource
                        };
                    });
                    const attributeTotalRecords = attributeInfo.total_records ? attributeInfo.total_records : 0;
                    const attributeInvalidPercentage = attributeInvalidRowCount && attributeTotalRecords ? (attributeInvalidRowCount / attributeTotalRecords) * 100 : 0;
                    return {
                        "rule_id": attributeInfo.rule_id ? attributeInfo.rule_id : 0,
                        "name": attributeInfo.attribute_name ? attributeInfo.attribute_name : "",
                        "dqscore": attributeInfo.attribute_dqscore ? attributeInfo.attribute_dqscore : 0,
                        "dataset_id": attributeInfo.dataset_id ? attributeInfo.dataset_id : 0,
                        "source_id": datasource,
                        "attribute_id": attributeInfo.attribute_id ? attributeInfo.attribute_id : 0,
                        "invalid_records": attributeInvalidRowCount,
                        "total_records": attributeTotalRecords,
                        "rules": ruleDetails,
                        "invalid_percentage": attributeInvalidPercentage,
                        "fieldtype": attributeInfo.fieldtype ? attributeInfo.fieldtype : "Others"
                    };
                });
                const datasetInfo = rules.find((rule) => dataset === rule.dataset_id);
                totalRecords += datasetInfo.total_records ? datasetInfo.total_records : 0;
                datsourceInvalidRecordCount += datasetInfo && datasetInfo.dataset_invalid_rows_count ? datasetInfo.dataset_invalid_rows_count : 0;
                if (userConfig.include_organization_domain_score) {
                    if (datasetInfo.include_organization_domain_score) {
                        totalDatasourceDqscore += (datasetInfo.dataset_dqscore && datasetInfo.dataset_metric_weight ? (datasetInfo.dataset_dqscore * datasetInfo.dataset_metric_weight) : 0);
                        totalMetricWeight += (datasetInfo.dataset_metric_weight ? datasetInfo.dataset_metric_weight : 0);
                    }
                } else {
                    totalDatasourceDqscore += (datasetInfo.dataset_dqscore && datasetInfo.dataset_metric_weight ? (datasetInfo.dataset_dqscore * datasetInfo.dataset_metric_weight) : 0);
                    totalMetricWeight += (datasetInfo.dataset_metric_weight ? datasetInfo.dataset_metric_weight : 0);
                }
                const datasetTotalRecords = datasetInfo.total_records ? datasetInfo.total_records : 0;
                const datasetInvalidRowCount = datasetInfo && datasetInfo.dataset_invalid_rows_count ? datasetInfo.dataset_invalid_rows_count : 0;
                const datasetInvalidPercentage = datasetInvalidRowCount && datasetTotalRecords ? (datasetInvalidRowCount / datasetTotalRecords) * 100 : 0;
                const datasetDetail = {
                    "rule_id": datasetInfo.rule_id ? datasetInfo.rule_id : 0,
                    "name": datasetInfo.dataset_name ? datasetInfo.dataset_name : "Untitled Dataset",
                    "dataset_id": datasetInfo.dataset_id ? datasetInfo.dataset_id : 0,
                    "source_id": datasource,
                    "dqscore": datasetInfo.dataset_dqscore ? datasetInfo.dataset_dqscore : 0,
                    "total_records": datasetInfo.total_records ? datasetInfo.total_records : 0,
                    "invalid_records": datasetInvalidRowCount ? datasetInvalidRowCount : 0,
                    "attributes": attributes,
                    "invalid_percentage": datasetInvalidPercentage ? datasetInvalidPercentage : 0,
                    "include_organization_domain_score": (datasetInfo.include_organization_domain_score || datasetInfo.include_organization_domain_score === false) ? datasetInfo.include_organization_domain_score : null
                };
                return datasetDetail;
            });
            const datasourceInfo = rules.find((rule) => datasource === rule.source_id);
            const invalidPercentage = datsourceInvalidRecordCount && totalRecords ? (datsourceInvalidRecordCount / totalRecords) * 100 : 0;
            const dqscore = (totalDatasourceDqscore && totalMetricWeight) ? (totalDatasourceDqscore / totalMetricWeight) : 0;
            return {
                "name": datasourceInfo.source_name ? datasourceInfo.source_name : "Untitled Datasource",
                "source_id": datasourceInfo.source_id ? datasourceInfo.source_id : 0,
                "dqscore": dqscore ? dqscore : 0,
                "total_records": totalRecords,
                "datasets": datasetList,
                "invalid_records": datsourceInvalidRecordCount,
                "invalid_percentage": invalidPercentage,
                'dq_score_threshold': datasourceInfo.dq_score_threshold
            };
        });
        setGroupedRule([...ruleList]);
    }, []);

    const applyFilter = useCallback((searchString) => {
        const filterRules = searchString ? sourceRules.filter((rule) =>
        (
            (rule.rule_name && rule.rule_name.toLowerCase().includes(searchString.toLowerCase()))
            || (rule.source_name && rule.source_name.toLowerCase().includes(searchString.toLowerCase()))
            || (rule.dataset_name && rule.dataset_name.toLowerCase().includes(searchString.toLowerCase()))
            || (rule.attribute_name && rule.attribute_name.toLowerCase().includes(searchString.toLowerCase()))
        )
        ) : sourceRules;
        groupDomainRule(filterRules);
    }, [groupDomainRule, sourceRules]);


    const onChangeSearch = useCallback((value) => {
        setSearch(value);
        applyFilter(value);
    }, [applyFilter]);

    const getDatasets = useCallback(() => {
        dispatch(getDomainDataset(domainId)).then((response) => {
            if (response) {
                setDatasets([...response.data]);
                setisInvalidDataset(response.showdataset);
                setisInvalidAttribute(response.showAttribute);
                setgroup_by_datasource(response.group_by_datasource);
            }
        });
    }, [dispatch, domainId]);

    useEffect(() => {
        getDatasets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        groupDomainRule(sourceRules);
        setLoading(rulesLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rulesLoading, sourceRules]);

    const updateSourceRule = useCallback((ruleId, property, value) => {
        const ruleList = [...sourceRules];
        const index = ruleList.findIndex((rule) => rule.rule_id === ruleId);
        if (index !== -1) {
            ruleList[index][property] = value;
            setRules([...ruleList]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceRules]);


    const onChangeRuleProperty = useCallback((property, value, sourceIndex, datasetIndex, attributeIndex, ruleIndex) => {
        groupedRule[sourceIndex].datasets[datasetIndex].attributes[attributeIndex].rules[ruleIndex][property] = value;
        const ruleId = groupedRule[sourceIndex].datasets[datasetIndex].attributes[attributeIndex].rules[ruleIndex].ruleId;
        setGroupedRule([...groupedRule]);
        const model = {
            [property]: value
        };
        dispatch(updateRuleWeightage(ruleId, model));

        updateSourceRule(ruleId, property, value);
    }, [dispatch, groupedRule, updateSourceRule]);

    const openDialog = useCallback((rule, type, contenttype) => {
        if (contenttype === "Valid_Records") {
            if (parseInt(rule.invalid_percentage) === 100) {
                return;
            }
        } else {
            if (!rule.invalid_records) {
                return;
            }
        }
        let ruleName = type === "rule" ? rule.name : null;
        if (ruleName) {
            ruleName = rule.class_name === 'CustomRule' ? rule.name : rule.name;
        }
        setPreviewParams({
            type: type,
            name: rule.name ? rule.name : "",
            datasetId: rule.dataset_id ? rule.dataset_id : 0,
            attributeName: type === "attribute" ? rule.name : rule.attribute_name,
            ruleName: ruleName,
            polarity: rule?.polarity ?? 'positive',
            domainId: domainId,
            metrics_type: contenttype
        });
        setOpenDialog(true);
    }, [domainId]);

    const redirectProperty = useCallback((rule, type) => {
        const model = {
            "source_id": rule.source_id ? rule.source_id : 0,
            "dataset_id": type !== "source" ? rule.dataset_id : null
        };
        dispatch(updateViews(model));
        if (type === "attribute" || type === "rule") {
            history.push({ pathname: `/dataset/${rule.dataset_id}`, state: { datasource: {}, datasourceId: rule.source_id, attribute: rule.attribute_name ? rule.attribute_name : rule.name } });
        } else if (type === "dataset") {
            history.push({ pathname: `/dataset/${rule.dataset_id}`, state: { datasource: {}, datasourceId: rule.source_id } });
        } else {
            history.push({ pathname: `/catalog/${rule.source_id}` });
        }
    }, [dispatch, history]);

    return (
        <Grid className={classNames(classes.ruleListContainer, classes.relative, classes.height100p)} align="center">
            <Grid container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography component="h5" variant="h5">
                        {'Domain Quality'}
                    </Typography>
                </Grid>
                <Grid align="right">
                    <Search
                        value={search}
                        onChange={(value) => onChangeSearch(value)}
                        placeholder="Search Here"
                    />
                </Grid>
            </Grid>
            {
                groupedRule.length > 0 &&
                <Grid>
                    <DomainListView
                        selectedRule={selectedRule}
                        openDialog={(rule, type, contenttype) => openDialog(rule, type, contenttype)}
                        rules={groupedRule}
                        isInvalidDataset={isInvalidDataset}
                        isInvalidAttribute={isInvalidAttribute}
                        group_by_datasource={group_by_datasource}
                        redirectData={() => redirectData()}
                        redirect={(rule, type) => redirectProperty(rule, type)}
                        onChange={(property, value, sourceIndex, datasetIndex, attributeIndex, ruleIndex) => onChangeRuleProperty(property, value, sourceIndex, datasetIndex, attributeIndex, ruleIndex)}
                        dq_score_threshold={dq_score_threshold}
                    />
                </Grid>
            }
            {(isLoading || groupedRule.length === 0) && <Loader />}
            {
                groupedRule.length === 0 && !isLoading &&
                <NoResultFound />
            }
            <DomainDataPreview
                open={open}
                params={previewParams}
                datasets={datasets}
                onClose={() => setOpenDialog(false)} />
        </Grid>
    );
};

DomainRules.propTypes = {
    domainId: PropTypes.number,
    history: PropTypes.object,
    selectedRule: PropTypes.object,
    redirectData: PropTypes.func,
    classes: PropTypes.object,
    dq_score_threshold: PropTypes.object,
    sourceRules: PropTypes.array,
    setRules: PropTypes.func,
    rulesLoading: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DomainRules);