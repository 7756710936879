import React, { useState, useCallback } from 'react';
import { Grid, Popover, withStyles, Typography, ButtonGroup, Button, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { DatePicker, TimePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment-timezone';

import AutoComplete from '../AutoComplete/AutoComplete.jsx';
import NumberInput from '../TextBox/NumberInput.jsx';
import ScheduleStyles from './ScheduleStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import { appConstants } from '../../constants/appConstants';
import ScheduleMonthCalender from '../ScheduleMonthCalender/ScheduleMonthCalender.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';


const Scheduler = (props) => {
    const { classes, scheduleProps, onClose, createScheduler, scheduleData, onRemoveSchedule, showRemoveSchedule, isExistingSchedule, setShowRemoveSchedule, setDatasetSchedule } = props;
    const zones = [{ name: moment.tz.guess() }, ...appConstants.TimeZones];

    const [timeZones] = useState([...zones]);
    const [open, setMonthCalender] = useState(false);
    const [anchorEl, setAchorEl] = useState(null);
    const [submission, setSubmission] = useState(true);

    const changeSchedule = (value, property) => {
        const schedule = scheduleData;
        if (property === "week_day" || property === "month_date") {
            const index = schedule[property].indexOf(value);
            if (index !== -1) {
                schedule[property].splice(index, 1);
            } else {
                schedule[property].push(value);
            }
        } else {
            if (property === "schedule_method") {
                schedule["minitues_interval"] = '';
                if (value === "Minute") {
                    schedule["minitues_interval"] = 60;
                }
                if (value === "Hour") {
                    schedule["minitues_interval"] = 1;
                }
            }
            if (property === 'time_zone') {
                schedule.date = moment(schedule.date).tz(value);
                schedule.time = moment(schedule.time).tz(value);
            }
            schedule[property] = value;
        }
        setDatasetSchedule({ ...schedule });
    };

    const closeMonthCalender = () => {
        setAchorEl(null);
        setMonthCalender(false);
    };

    const openMonthCalender = (event) => {
        setAchorEl(event.target);
        setMonthCalender(true);
    };

    const onSchedule = useCallback(() => {
        setSubmission(false);
        const hours = scheduleData.day !== "" ? parseInt(moment(scheduleData.day).format("HH")) : 0;
        const minitues = scheduleData.day !== "" ? parseInt(moment(scheduleData.day).format("mm")) : 0;
        let minutesInterval = 0;
        if (scheduleData.schedule_method === "Hour") {
            minutesInterval = (scheduleData.minitues_interval ? parseInt(scheduleData.minitues_interval) * 60 : 0);
        } else {
            minutesInterval = (scheduleData.minitues_interval ? scheduleData.minitues_interval : 0);
        }

        const schedule = {
            "schedule_method": scheduleData.schedule_method,
            "minitues_interval": minutesInterval,
            "time_zone": scheduleData.time_zone,
            "week_day": scheduleData.schedule_method === "Week" ? scheduleData.week_day.toString() : '',
            "month_date": scheduleData.schedule_method === "Month" ? scheduleData.month_date.toString() : '',
            "start_time": `${moment(scheduleData.date).format('YYYY-MM-DD')} ${moment(scheduleData.time).format('HH:mm')}`,
            "day_week_month_time": hours,
            "day_week_month_minitues": minitues,
            'user_timezone': moment.tz.guess()
        };
        createScheduler(schedule);
    }, [scheduleData.day, scheduleData.schedule_method, scheduleData.time_zone, scheduleData.week_day, scheduleData.month_date, scheduleData.date, scheduleData.time, scheduleData.minitues_interval, createScheduler]);

    const getMinDate = (date) => {
        date = new Date(date);
        let minDate = date;
        const currentDate = new Date();
        if (currentDate < date) {
            minDate = currentDate;
        }
        return minDate;
    };

    const onRemoveScheduler = () => {
        onRemoveSchedule();
    };

    const showRemoveAlert = () => {
        setShowRemoveSchedule(true);
    };

    const closeScheduleAlert = () => {
        setShowRemoveSchedule(false);
    };

    return (
        <Popover
            open={scheduleProps.open}
            anchorEl={scheduleProps.anchorElement}
            onClose={() => onClose()}
            anchorOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }
            transformOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            }>
            <ValidatorForm autoComplete="off" className={classNames(classes.scheduleContainer)} onSubmit={() => onSchedule()}>
                <Grid>
                    <Typography component="h5" variant="h5">
                        {'Schedule'}
                    </Typography>
                    <Grid container direction="row" justify="space-between" className={classes.marginTop15}>
                        <DatePicker autoOk
                            variant="inline"
                            label="Start Date"
                            format="MM/DD/YYYY"
                            minDate={scheduleData.date ? getMinDate(scheduleData.date) : new Date()}
                            value={scheduleData.date ? scheduleData.date : Date.now()}
                            onChange={(date) => changeSchedule(date, 'date')}
                        />
                        <TimePicker
                            ampm={false}
                            label="Start Time"
                            format="HH:mm"
                            value={scheduleData.time ? scheduleData.time : Date.now()}
                            onChange={(time) => changeSchedule(time, 'time')}
                        />
                    </Grid>
                    <Grid className={classNames(classes.marginTop15, classes.schedulerSection)}>
                        <Grid container justify="center" alignItems="center">
                            <ButtonGroup className={classes.TimingBtnGroup}>

                                {
                                    appConstants.scheduleMethods.map((method, index) =>
                                        <Button
                                            className={classNames(classes.tabBtn, method.value === scheduleData.schedule_method ? 'active' : '')}
                                            variant="contained"
                                            key={`schedule_${index}`}
                                            onClick={() => changeSchedule(method.value, 'schedule_method')}>
                                            {method.name}
                                        </Button>
                                    )
                                }
                            </ButtonGroup>
                        </Grid>
                        <Grid className={classes.scheduleContent}>
                            {
                                appConstants.scheduleMethods[0].value === scheduleData.schedule_method &&
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <Grid item xs={4}>
                                        <NumberInput label="Every"
                                            id="minute"
                                            value={scheduleData.minitues_interval ? scheduleData.minitues_interval : ''}
                                            onChange={(event) => changeSchedule(event.target.value, "minitues_interval")}
                                            validators={['required', 'maxNumber:60']}
                                            errorMessages={['Duration is required', 'Enter below 60 Minutes']}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>
                                            Minute(s)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            {
                                appConstants.scheduleMethods[1].value === scheduleData.schedule_method &&
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <Grid item xs={4}>
                                        <NumberInput label="Every"
                                            id="hour"
                                            value={scheduleData.minitues_interval ? scheduleData.minitues_interval : ''}
                                            onChange={(event) => changeSchedule(event.target.value, "minitues_interval")}
                                            validators={['required']}
                                            errorMessages={['Duration is required']}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>
                                            Hour(s)
                                        </Typography>
                                    </Grid>
                                </Grid>
                            }
                            {
                                appConstants.scheduleMethods[2].value === scheduleData.schedule_method &&
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <TimePicker
                                        ampm={false}
                                        label="Select a Time"
                                        format="HH:mm"
                                        value={scheduleData.day ? scheduleData.day : Date.now()}
                                        onChange={(time) => changeSchedule(time, "day")}
                                    />
                                </Grid>
                            }
                            {
                                appConstants.scheduleMethods[3].value === scheduleData.schedule_method &&
                                <Grid container direction="row" justify="center" alignItems="center">
                                    <ButtonGroup>
                                        {
                                            appConstants.weekDays.map((days, index) =>
                                                <Button
                                                    onClick={() => changeSchedule(days.value, "week_day")}
                                                    variant="contained"
                                                    key={`days_${index}`}
                                                    className={classNames(classes.dayBtn, scheduleData.week_day.indexOf(days.value) !== -1 ? 'active' : '')}
                                                >
                                                    {days.name}
                                                </Button>
                                            )
                                        }
                                    </ButtonGroup>
                                    <TimePicker
                                        ampm={false}
                                        label="Select a Time"
                                        format="HH:mm"
                                        margin="normal"
                                        value={scheduleData.day ? scheduleData.day : Date.now()}
                                        onChange={(time) => changeSchedule(time, "day")}
                                    />
                                </Grid>
                            }
                            {
                                appConstants.scheduleMethods[4].value === scheduleData.schedule_method &&
                                <Grid container align="center" justify="center" spacing={4}>
                                    <Grid item>
                                        <TextBox
                                            label="Select a Date"
                                            value={scheduleData.month_date.toString()}
                                            readOnly
                                        />
                                        <IconButton onClick={(event) => openMonthCalender(event)}>
                                            <TodayIcon className={classes.calendarIcon} />
                                        </IconButton>
                                    </Grid>
                                    <Grid item>
                                        <TimePicker
                                            ampm={false}
                                            label="Select a Time"
                                            format="HH:mm"
                                            margin="normal"
                                            value={scheduleData.day ? scheduleData.day : Date.now()}
                                            onChange={(time) => changeSchedule(time, "day")}
                                        />
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid container className={classes.marginTop15}>
                        <Grid item xs={12}>
                            <AutoComplete
                                label="Timezone"
                                placeholder="Select Timezone"
                                onChange={(event) => changeSchedule(event.target.value, "time_zone")}
                                onBlur={() => { }}
                                value={scheduleData.time_zone ? scheduleData.time_zone : ''}
                                validators={['required']}
                                errorMessages={['Timezone is required']}
                                options={timeZones}
                                selectedValuePath="name"
                                displayMemberPath="name"
                                removeClass
                                openSuggestionsOnClick
                                name="timzone"
                                id="timzone"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container justify="space-between" alignItems="center" className={classes.marginTop20}>
                        <Grid item xs={6}>
                            <Button onClick={() => showRemoveAlert()} className={classes.tabBtn} variant="contained" color="primary" disabled={!isExistingSchedule} >
                                Remove Schedule
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justify="flex-end" alignItems="center">
                                <Button className={classes.tabBtn} type="submit" variant="contained" color="primary" disabled={!submission} >
                                    Submit
                                </Button>
                                <Button onClick={() => onClose()} style={{ marginLeft: '10px' }} variant="contained" className={classNames(classes.cancelBtn, classes.tabBtn)}>
                                    Cancel
                                </Button>
                            </Grid>
                        </Grid>
                        {
                            showRemoveSchedule && <AlertDialog
                                title="Delete Schedule"
                                message={`Are you sure you want to delete the schedule?`}
                                okButtonText="OK"
                                cancelButtonText="Cancel"
                                show={showRemoveSchedule}
                                onClickOK={onRemoveScheduler}
                                onClickCancel={closeScheduleAlert}
                            />
                        }
                    </Grid>
                    {
                        open && <ScheduleMonthCalender
                            onOpenPopover={open}
                            anchorElement={anchorEl}
                            onClose={() => closeMonthCalender()}
                            onDateSelectionChange={(day) => changeSchedule(day, "month_date")}
                            selectedDays={scheduleData.month_date}
                        />
                    }
                </Grid>
            </ValidatorForm>
        </Popover>
    );
};

Scheduler.propTypes = {
    classes: PropTypes.object,
    scheduleProps: PropTypes.object,
    onClose: PropTypes.func,
    createScheduler: PropTypes.func,
    scheduleData: PropTypes.object,
    onRemoveSchedule: PropTypes.func,
    showRemoveSchedule: PropTypes.bool,
    isExistingSchedule: PropTypes.bool,
    setShowRemoveSchedule: PropTypes.func,
    setDatasetSchedule: PropTypes.func
};

export default withStyles((theme) => ({
    ...ScheduleStyles(theme),
    ...Styles(theme)
}))(Scheduler);