import React, { useCallback, useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, withStyles, Typography, TableCell, SvgIcon, Slider, TextField, Switch } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import Styles from '../../layouts/Styles.jsx';
import MasterStyles from './MasterStyles.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import classNames from 'classnames';
import AutoComplete from '../AutoComplete/AutoCompleteInput.jsx';
import EjectIcon from '@material-ui/icons/Eject';
import { createModelAttributes, updateModelAttributes } from '../../actions/masterActions.js';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import { sortTable } from '../../helpers/appHelpers.js';

const MasterAttributeRow = (props) => {
    const { classes, theme, attributes, modelId, attribute, type, isEditable, deleteAtribute, onGroupChange, toggleRow, isExpanded, enableToggle, onCreated, onChanged } = props;
    const [selectedAttribute, setSelectedAttribute] = useState({});
    const [attributeId, setAttributeId] = useState(-1);
    const datatypes = useSelector(({ datasource }) => datasource.datatypes);
    const dispatch = useDispatch();

    const onToggleRow = useCallback((event) => {
        if (toggleRow) {
            toggleRow(event);
        }
        if (onChanged) {
            selectedAttribute.isOpen = !selectedAttribute.isOpen;
            onChanged(selectedAttribute);
        }
    }, [toggleRow, onChanged, selectedAttribute]);

    const updateProperties = useCallback((property) => {
        const attribute = { ...selectedAttribute };
        delete attribute.childAttributes;
        delete attribute.isOpen;
        const requestParams = {
            ...attribute,
            "model_id": modelId,
            'property': property
        };
        if (attribute.id) {
            dispatch(updateModelAttributes(attribute.id, requestParams));
        } else {
            dispatch(createModelAttributes(requestParams)).then((response) => {
                if (response) {
                    attribute.id = response.id;
                    attribute.isOpen = selectedAttribute.isOpen;
                    setSelectedAttribute({ ...attribute });
                    if (onCreated) {
                        onCreated(attribute);
                    }
                }
            });
        }
    }, [dispatch, modelId, onCreated, selectedAttribute]);

    const onValueChange = useCallback((property, value) => {
        selectedAttribute[property] = value;
        setSelectedAttribute({ ...selectedAttribute });
        updateProperties(property);
        if ((property === 'group_name' || property === 'primary_key') && onGroupChange) {
            onGroupChange(property, selectedAttribute);
        }
        if (onChanged) {
            onChanged(selectedAttribute);
        }
    }, [onChanged, onGroupChange, selectedAttribute, updateProperties]);

    const onChange = useCallback((property, value, event) => {
        selectedAttribute[property] = value;
        setSelectedAttribute({ ...selectedAttribute });
        if (onChanged) {
            onChanged(selectedAttribute);
        }
    }, [selectedAttribute, onChanged]);

    const loadAttribute = useCallback((attribute) => {
        delete attribute.data;
        setSelectedAttribute({ ...attribute });
        setAttributeId(attribute.id);
    }, [setAttributeId]);

    useEffect(() => {
        if (attribute && attributeId <= 0) {
            loadAttribute(attribute);
        }
    }, [attribute, attributeId, loadAttribute]);

    const getGroup = useCallback((attribute) => {
        return attributes.filter((p) => p.name !== attribute.name).map((p) => { return { name: p.name }; });
    }, [attributes]);

    /* const shortenValue = (value) => { if (value.length > 5) { value = value.slice(0, 5) + '...'; return value; } return value; }; */
    return (
        <Fragment>
            <TableCell className={type === "child" ? classes.childCell : classes.attributeHeader}>
                <ToolTipComponent title={selectedAttribute.name ? selectedAttribute.name : ''} arrow>
                    <Grid>
                        <TextBox
                            name="name"
                            // value={selectedAttribute.name ? shortenValue(selectedAttribute.name) : ''}
                            value={selectedAttribute.name}
                            onChange={(event) => onChange(event.target.name, event.target.value)}
                            onBlur={
                                (event) => {
                                    if (event.target.getAttribute('aria-invalid') === 'true') {
                                        return;
                                    }
                                    if (event.target.value !== "") {
                                        onValueChange(event.target.name, event.target.value);
                                    }
                                }
                            }
                            disabled={!isEditable}
                            className={classNames(classes.inlinetxt, classes.maxWidth, classes.marginTop4)}
                            validators={['matchRegexp:^[A-Za-z][A-Za-z0-9_]+$']}
                            errorMessages={['']}
                        />
                    </Grid>
                </ToolTipComponent>
            </TableCell>
            <TableCell style={{ minWidth: 100 }}>
                <AutoComplete
                    className={classes.marginBottom6}
                    name="data_type"
                    options={sortTable(datatypes, 'asc', 'name')}
                    value={selectedAttribute.data_type ? selectedAttribute.data_type : ''}
                    getOptionLabel={
                        (option) => {
                            if (option && option.name) {
                                return option.name;
                            }
                            return option;
                        }
                    }
                    getOptionSelected={(option, value) => option.name === value}
                    renderInput={
                        (params) =>
                            <ToolTipComponent title={selectedAttribute.data_type ? selectedAttribute.data_type : 'DataType'} arrow>
                                <TextField {...params} className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)} margin="normal" placeholder="Data Type" />
                            </ToolTipComponent>
                    }
                    onChange={(_, selectedValue) => onValueChange("data_type", selectedValue.name)}
                    openOnFocus
                    blurOnSelect
                    disableClearable
                    fullWidth
                    forcePopupIcon={false}
                    noOptionsText={"No datatypes"}
                    disabled
                />
            </TableCell>
            <TableCell>
                <TextBox
                    name="length"
                    placeholder="Length"
                    value={selectedAttribute.length ? selectedAttribute.length : 'NA'}
                    onChange={(event) => onChange(event.target.name, event.target.value)}
                    onBlur={(event) => onValueChange(event.target.name, event.target.value)}
                    disabled
                    className={classNames(classes.inlinetxt, classes.maxWidth, classes.marginTop4)}
                />
            </TableCell>
            <TableCell align="center">
                <IconButton disabled={selectedAttribute.name === "" || !isEditable} onClick={() => onValueChange("parent_key", !selectedAttribute.parent_key)}>
                    <SvgIcon className={selectedAttribute.parent_key ? 'iconactive' : 'icon'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" id="Capa_1" x="0px" y="0px" viewBox="0 0 512 512">

                            <g>
                                <path d="M131.5,472H60.693c-8.538,0-13.689-4.765-15.999-7.606c-3.988-4.906-5.533-11.29-4.236-17.519     c20.769-99.761,108.809-172.616,210.445-174.98c1.693,0.063,3.39,0.105,5.097,0.105c1.722,0,3.434-0.043,5.142-0.107     c24.853,0.567,49.129,5.24,72.236,13.917c10.34,3.885,21.871-1.352,25.754-11.693c3.883-10.34-1.352-21.871-11.693-25.754     c-3.311-1.244-6.645-2.408-9.995-3.512C370.545,220.021,392,180.469,392,136C392,61.01,330.991,0,256,0     c-74.991,0-136,61.01-136,136c0,44.509,21.492,84.092,54.643,108.918c-30.371,9.998-58.871,25.546-83.813,46.062     c-45.732,37.617-77.529,90.086-89.532,147.743c-3.762,18.066,0.744,36.622,12.363,50.908C25.221,503.847,42.364,512,60.693,512     H131.5c11.046,0,20-8.954,20-20C151.5,480.954,142.546,472,131.5,472z M160,136c0-52.935,43.065-96,96-96s96,43.065,96,96     c0,51.367-40.554,93.438-91.326,95.885c-1.557-0.028-3.114-0.052-4.674-0.052c-1.564,0-3.127,0.023-4.689,0.051     C200.546,229.43,160,187.362,160,136z" />
                                <path d="M496.689,344.607c-8.561-19.15-27.845-31.558-49.176-31.607h-62.372c-0.045,0-0.087,0-0.133,0     c-22.5,0-42.13,13.26-50.029,33.807c-1.051,2.734-2.336,6.178-3.677,10.193H200.356c-5.407,0-10.583,2.189-14.35,6.068     l-34.356,35.388c-7.567,7.794-7.529,20.203,0.085,27.95l35,35.612c3.76,3.826,8.9,5.981,14.264,5.981h65c11.046,0,20-8.954,20-20     c0-11.046-8.954-20-20-20h-56.614l-15.428-15.698L208.814,397h137.491c9.214,0,17.235-6.295,19.426-15.244     c1.618-6.607,3.648-12.959,6.584-20.596c1.936-5.036,6.798-8.16,12.741-8.16c0.013,0,0.026,0,0.039,0h62.371     c5.656,0.013,10.524,3.053,12.705,7.932c5.369,12.012,11.78,30.608,11.828,50.986c0.048,20.529-6.356,39.551-11.739,51.894     c-2.17,4.978-7.079,8.188-12.56,8.188c-0.011,0-0.022,0-0.033,0h-63.125c-5.533-0.013-10.716-3.573-12.896-8.858     c-2.339-5.671-4.366-12.146-6.197-19.797c-2.571-10.742-13.367-17.366-24.105-14.796c-10.743,2.571-17.367,13.364-14.796,24.106     c2.321,9.699,4.978,18.118,8.121,25.738c8.399,20.364,27.939,33.555,49.827,33.606h63.125c0.043,0,0.083,0,0.126,0     c21.351-0.001,40.647-12.63,49.18-32.201c6.912-15.851,15.137-40.511,15.072-67.975     C511.935,384.434,503.638,360.153,496.689,344.607z" />
                                <circle cx="431" cy="412" r="20" />
                            </g>
                        </svg>
                    </SvgIcon>
                </IconButton>
            </TableCell>
            <TableCell align="center">
                <IconButton disabled={selectedAttribute.name === "" || !isEditable} onClick={() => onValueChange("primary_key", !selectedAttribute.primary_key)}>
                    <SvgIcon className={selectedAttribute.primary_key ? 'iconactive' : 'icon'}>
                        <g id="key-tool" transform="translate(-13.899)">
                            <path id="Path_194" data-name="Path 194" d="M21.569,13.726l-1.291-1.291,4.443-4.443A4.26,4.26,0,1,0,23,6.271l-8.746,8.748a1.217,1.217,0,0,0,1.721,1.721l1.291,1.291a.608.608,0,0,0,.861,0l.861-.86a.607.607,0,0,0,0-.86L17.7,15.019l.86-.863,1.291,1.293a.608.608,0,0,0,.861,0l.861-.86A.611.611,0,0,0,21.569,13.726ZM25.441,2.969a1.826,1.826,0,1,1,0,2.582A1.828,1.828,0,0,1,25.441,2.969Z" transform="translate(0)" fill="inherit" />
                        </g>
                    </SvgIcon>
                </IconButton>
            </TableCell>
            <TableCell style={{ minWidth: 170 }}>
                <AutoComplete
                    className={classes.marginBottom6}
                    name="group_name"
                    options={sortTable(getGroup(attribute), "asc", "name")}
                    value={selectedAttribute.group_name ? selectedAttribute.group_name : ''}
                    getOptionLabel={
                        (option) => {
                            if (option && option.name) {
                                return option.name;
                            }
                            return option;
                        }
                    }
                    getOptionSelected={(option, value) => option.name === value}
                    renderInput={
                        (params) =>
                            <ToolTipComponent title={selectedAttribute.group_name ? selectedAttribute.group_name : 'Group Name'} arrow>
                                <TextField {...params} className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)} margin="normal" placeholder="Group Name (optional)" />
                            </ToolTipComponent>
                    }
                    onChange={(_, selectedValue) => onValueChange("group_name", selectedValue.name)}
                    openOnFocus
                    blurOnSelect
                    disableClearable
                    fullWidth
                    forcePopupIcon={false}
                    disabled={selectedAttribute.name === "" || !isEditable || (selectedAttribute.data && selectedAttribute.data.length)}
                    noOptionsText={"No Group Names"}
                />
            </TableCell>
            <TableCell>
                <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                    <Slider name="sensitivity"
                        color="secondary"
                        className={classes.SensitivityMuiSlider}
                        style={{ width: '95%' }}
                        defaultValue={1}
                        track={'normal'}
                        value={attribute && selectedAttribute.sensitivity ? selectedAttribute.sensitivity : 1}
                        onChange={(_event, value) => onValueChange('sensitivity', value)}
                        onChangeCommitted={() => onValueChange('sensitivity', selectedAttribute.sensitivity)}
                        min={1}
                        max={3}
                        step={1}
                        disabled
                    />
                    <Typography variant="body2" className={classes.sliderValue}>
                        {selectedAttribute.sensitivity ? selectedAttribute.sensitivity : '1'}
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                    <Slider name="occurance"
                        color="secondary"
                        className={classes.SensitivityMuiSlider}
                        style={{ width: '95%' }}
                        defaultValue={1}
                        track={'normal'}
                        value={selectedAttribute.occurance ? selectedAttribute.occurance : 1}
                        onChange={(_event, value) => onValueChange('occurance', value)}
                        onChangeCommitted={() => onValueChange('occurance', selectedAttribute.occurance)}
                        min={1}
                        max={3}
                        step={1}
                        disabled={selectedAttribute.name === "" || !isEditable}
                    />
                    <Typography variant="body2" className={classes.sliderValue}>
                        {selectedAttribute.occurance ? selectedAttribute.occurance : '1'}
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                    <Slider name="score"
                        className={classes.SensitivityMuiSlider}
                        color="secondary"
                        style={{ width: '95%' }}
                        defaultValue={0}
                        track={'normal'}
                        value={selectedAttribute.score ? selectedAttribute.score : 0}
                        onChange={(_event, value) => onValueChange('score', value)}
                        onChangeCommitted={() => onValueChange('score', selectedAttribute.score)}
                        min={1}
                        max={100}
                        step={1}
                        disabled={selectedAttribute.name === "" || !isEditable}
                    />
                    <Typography variant="body2" className={classes.sliderValue}>
                        {selectedAttribute.score ? selectedAttribute.score : '1'}
                    </Typography>
                </Grid>
            </TableCell>
            <TableCell>
                <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                    {
                        attribute && selectedAttribute.data_type === 'Text' ?
                            <Switch checked={Boolean(selectedAttribute.is_fuzzy)}
                                value={Boolean(selectedAttribute.is_fuzzy)}
                                color="secondary"
                                name="is_fuzzy"
                                disabled={!isEditable}
                                onChange={() => onValueChange('is_fuzzy', !selectedAttribute.is_fuzzy)} />
                            :
                            <Typography className={classes.hoverdefault}>
                                {"NA"}
                            </Typography>
                    }
                </Grid>
            </TableCell>
            <TableCell align="center">
                {
                    <Grid container wrap="nowrap">
                        {
                            type === "child" && isEditable &&
                            <ToolTipComponent title={"Remove From Group"} arrow>
                                <IconButton color="primary" onClick={() => onValueChange('group_name', '')}>
                                    <EjectIcon />
                                </IconButton>
                            </ToolTipComponent>
                        }
                        {
                            isEditable &&
                            <ToolTipComponent title={"Delete Attribute"} arrow>
                                <IconButton onClick={() => deleteAtribute(attribute)}>
                                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 32 32">
                                        <g id="Delete">
                                            <path fill={theme.palette.primary.main}
                                                stroke={theme.palette.primary.main}
                                                strokeWidth="0.92"
                                                strokeMiterlimit="10"
                                                d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                            <path fill={theme.palette.primary.main}
                                                stroke={theme.palette.primary.main}
                                                strokeWidth="0.92"
                                                strokeMiterlimit="10"
                                                d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                        </g>
                                    </svg>
                                </IconButton>
                            </ToolTipComponent>
                        }
                        {
                            enableToggle &&
                            <IconButton onClick={onToggleRow} className={classNames(classes.attributeRowExpandIcon, isExpanded ? 'expanded' : '')}>
                                <ExpandMoreIcon />
                            </IconButton>
                        }
                    </Grid>
                }
            </TableCell>
        </Fragment>
    );
};

MasterAttributeRow.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    modelId: PropTypes.string,
    isEditable: PropTypes.bool,
    attributes: PropTypes.array,
    attribute: PropTypes.object,
    type: PropTypes.string,
    deleteAtribute: PropTypes.func,
    onGroupChange: PropTypes.func,
    toggleRow: PropTypes.func,
    isExpanded: PropTypes.bool,
    enableToggle: PropTypes.bool,
    onCreated: PropTypes.func,
    onChanged: PropTypes.func
};

export default withStyles((theme) => ({
    ...MasterStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MasterAttributeRow);