const UserAuditLogDetailsStyles = (theme) => ({
    root: {
        height: '100%!important'
    },
    tableHeadCell: {
        fontSize: 13,
        padding: '10px !important',
        fontWeight: 500
    },
    tableCell: {
        fontSize: 13,
        color: theme.palette.grey.darkTxt,
        padding: '8px 10px'
    },
    logntext: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    nopadding: {
        padding: 0
    }
});

export default UserAuditLogDetailsStyles;