import React, { Fragment, useCallback } from 'react';
import { Grid, withStyles, Typography, Chip, Tooltip, IconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import UserList from '../DatasourceList/UserList.jsx';
import DatasourceListStyles from '../DatasourceList/DatasourceListStyles.jsx';
import MasterStyles from './MasterStyles.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import { Link } from 'react-router-dom';


const DescTooltip = withStyles((theme) => ({
    arrow: {
        color: '#444'
    },
    tooltip: {
        maxWidth: 1000,
        backgroundColor: '#444',
        maxHeight: 100,
        textOverflow: "ellipsis",
        overflow: "hidden",
        webkitLlineClamp: 4,
        webkitBoxOrient: 'vertical'
    }
}))(Tooltip);

const MasterListDetail = (props) => {
    const { classes, history, model, getSteward, getDatasourceIcon, theme } = props;
    const redirect = useCallback((type, model) => {
        if (type === "main") {
            history.push({ pathname: `/semanticmodel/${model.id}`, state: { model: model } });
        } else if (type === "search") {
            history.push({ pathname: `/semanticmodel/${model.id}`, state: { model: model, search: true } });
        } else {
            history.push({ pathname: `/semanticmodel/${model.id}`, state: { model: model, attribute: true } });
        }
    }, [history]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const redirectLink = useCallback((type, model) => {
        if (type === "main") {
            return ({ pathname: `/semanticmodel/${model.id}`, state: { model: model } });
        } else if (type === "search") {
            return ({ pathname: `/semanticmodel/${model.id}`, state: { model: model, search: true } });
        }
            return ({ pathname: `/semanticmodel/${model.id}`, state: { model: model, attribute: true } });

    });

    return (
        <Grid className={classes.cardContainer}>
            <Grid container>
                <Grid item className={classes.modelListContainer}>
                    {
                        getSteward(model.sharing_info) &&
                        <ToolTipComponent title="Request Access Notification" arrow>
                            <Grid className={classes.ribbon}
                            // onClick={(event) => openRequestAccess(event, index)}
                            />
                        </ToolTipComponent>
                    }
                    <Grid container className={classes.catdetailContainer}>
                        <Grid item xs={6} className={classes.detailContainer}>
                            <Grid className={
                                classNames(classes.dataSourceImg,
                                    model.name === 'Untitled Data Source' ? 'default-image' : ''
                                )
                            }
                                container
                                alignItems="center"
                                justify="center">
                                {getDatasourceIcon(model)}
                            </Grid>
                            <Grid className={classes.titleContainer}>
                                <ToolTipComponent title={model.name} placement="bottom-start" arrow>
                                    <Link style={{ textDecoration: 'unset', color: 'inherit' }} to={redirectLink("main", model)}>
                                        <Typography component="h5" variant="h5" className={classes.titleTxt}>
                                            {model.name ? model.name : 'Untitled Model'}
                                        </Typography>
                                    </Link>
                                </ToolTipComponent>

                                <Grid container alignItems="center">
                                    <Typography className={classNames(classes.dataTypeTxt, classes.secondaryText)}>
                                        {model.prefix}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6} align="right">
                            <Grid container alignItems="center" justify="flex-end">
                                <Grid item>
                                    <ToolTipComponent title={"Search"} arrow>
                                        <Grid container alignItems="center">
                                            <IconButton onClick={() => redirect("search", model)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 32 32" width="18">
                                                    <g>
                                                        <path fill={theme.palette.primary.main} d="m27.7 8.3-6-6c-.2-.2-.3-.3-.7-.3h-16c-.6 0-1 .4-1 1v26c0 .6.4 1 1 1h22c.6 0 1-.4 1-1v-20c0-.3-.1-.5-.3-.7zm-3.1-.3h-2.6v-2.6zm-18.6 20v-24h14v5c0 .6.4 1 1 1h5v18z" />
                                                        <path fill={theme.palette.primary.main} d="m19.2 11.7c-2.3-2.3-6.1-2.3-8.5 0s-2.3 6.1 0 8.5c2.1 2.1 5.4 2.3 7.7.6l3 3c.4.4 1 .4 1.4 0s.4-1 0-1.4l-3-3c1.7-2.3 1.5-5.6-.6-7.7zm-1.5 7.1c-1.6 1.6-4.1 1.6-5.7 0s-1.6-4.1 0-5.7 4.1-1.6 5.7 0 1.6 4.1 0 5.7z" />
                                                    </g>
                                                </svg>
                                            </IconButton>
                                        </Grid>
                                    </ToolTipComponent>
                                </Grid>
                                {/* <span>
                                    {
                                        !model.isLoading ?
                                            getSharingIcon(model.sharing_info, model)
                                            :
                                            <Typography style={{ position: 'relative', marginRight: 20 }}>
                                                <Loader size={'small'} type={'button'} />
                                            </Typography>
                                    }
                                </span> */}
                            </Grid>
                        </Grid>
                    </Grid>
                    {
                        model.description && model.description.length > 0 ? (
                            <DescTooltip className={classes.descriptionTooltip} title={model.description} arrow>
                                <Typography className={classNames(classes.descriptionTxt, 'description')} variant="body2">
                                    {model.description}
                                </Typography>
                            </DescTooltip>
                        ) : null
                    }
                    <Grid container className={classes.datasetContainer}>
                        <Grid item xs={10}>
                            {
                                model.attributes && model.attributes.map((attribute, index) =>
                                    <Fragment key={`attribute_${index}`}>
                                        {
                                            index <= 3 &&
                                            <ToolTipComponent title={attribute.name} arrow>
                                                <Chip className={classNames(classes.chip, classes.linkChips)}
                                                    label={attribute.name}
                                                    onClick={() => redirect("attribute", model)}
                                                />
                                            </ToolTipComponent>
                                        }
                                    </Fragment>
                                )
                            }
                            {
                                model.attributes && model.attributes.length > 4 &&
                                <Typography variant="body2" className={classes.lightTxt}>
                                    {`+${model.attributes.length - 4} more Attribute`}
                                </Typography>
                            }
                        </Grid>
                        <Grid item xs={2} align="right">
                            <UserList users={model.sharing_info ? model.sharing_info : []} itemCount={3} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
};


MasterListDetail.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    model: PropTypes.object,
    getSteward: PropTypes.func,
    getDatasourceIcon: PropTypes.func,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...MasterStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MasterListDetail);