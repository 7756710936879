const ConnectorStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    actionButtons: {
        marginTop: 16,
        marginLeft: 20
    },
    modalStyle: {
        marginLeft: 52,
        padding: '26px !important',
        boxShadow: 'none',
        background: '#f4f6f9'
    },
    btnContainer: {
        position: 'relative',
        '& button.MuiButton-contained': {
            position: 'relative'
        },
        '& button.Mui-disabled': {
            color: `${theme.palette.common.white} !important`,
            backgroundColor: `${theme.palette.primary.main} !important`
        }
    },
    btnLoader: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        '& div.MuiCircularProgress-root': {
            color: theme.palette.common.white
        }
    },
    textCenter: {
        textAlign: 'center'
    },
    tableContainer: {
        height: 'calc(100vh - 450px)',
        overflow: 'hidden',
        overflowY: 'auto',
        marginTop: 50
    },
    hasDatasets: {
        paddingTop: 250
    },
    connectcontainer: {
        background: '#fff',
        padding: '25px',
        height: 'calc(100vh - 80px) !important',
        overflowY: 'auto',
        alignContent: 'baseline'
    },
    connectdescription: {
        color: theme.palette.grey.dark
    },
    connectTitle: {
        marginBottom: "10px"
    },
    connectorButtons: {
        marginTop: 40
    },
    searchInputContainer: {
        position: 'relative',
        marginTop: 15,
        width: 250,
        '& .MuiTextField-root': {
            width: 250
        },
        '& input': {
            height: '32px !important'
        },
        '& .MuiInput-underline:before': {
            height: '32px !important'
        }
    },
    noMargin: {
        margin: 0
    },
    includeSearchIcon: {
        position: 'absolute',
        top: 1,
        right: 3,
        padding: 9
    },
    includeDeleteIcon: {
        position: 'absolute',
        top: 1,
        right: 3,
        padding: 6
    },
    s3TableContainer: {
        height: 'calc(100vh - 520px)',
        overflow: 'hidden'
    },
    table: {
        height: 'calc(100vh - 400px) !important'
    },
    connectTableContainer: {
        maxHeight: 'calc(100vh - 125px)',
        overflow: 'hidden',
        overflowY: 'auto',
        overflowX: 'auto',
        marginTop: '20px !important',
        '& .MuiTable-root': {
            width: '99.95%'
        },
        '& .MuiTableCell-root': {
            height: 40
        },
        '& .MuiTableCell-head': {
            fontSize: 13,
            textTransform: 'capitalize'
        },
        '& .MuiTableCell-body': {
            fontSize: 13
        }
    },
    serverView: {
        height: 'calc(100vh - 500px) !important'
    },
    s3View: {
        height: 'calc(100vh - 520px) !important'
    },
    restapicontainer: {
        height: 'calc(100vh - 80px)',
        overflowY: 'auto'
    },


    accordian: {
        '& .MuiAccordionSummary-root': {
            padding: "0px 13px",
            border: `1px solid ${theme.palette.border.normal}`,
            minHeight: "50px",
            boxShadow: "0px 0px 2px #0000001A",
            position: "relative",
            marginBottom: 10
        },
        '& .MuiAccordionSummary-expandIcon': {
            position: "absolute",
            right: 5
        },
        '& .MuiAccordionSummary-content': {
            margin: 0
        },
        '& .MuiAccordionDetails-root': {
            padding: "2px 15px 15px 15px",
            background: theme.palette.background.default,
            border: `1px solid ${theme.palette.grey.extraLight}`,
            position: 'relative'
        },
        '& .MuiTableCell-root': {
            borderBottom: 0,
            padding: 10
        },
        '& .MuiTableCell-head': {
            color: theme.palette.grey.dark,
            fontSize: 13,
            fontFamily: theme.palette.textFont
        },
        '& .MuiTableBody-root .MuiTableRow-root': {
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.grey.extraLight}`
        },
        '& .MuiTableCell-body': {
            fontSize: 13,
            color: theme.palette.grey.darkTxt
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            marginBottom: '0px'
        },
        '& .MuiAccordion-root:before': {
            backgroundColor: 'transparent'
        }
    },
    tableaccordian: {
        width: '100%',
        marginTop: 0,
        '& .datasetTableHeader': {
            visibility: "collapse"
        },
        '& .datasetTableHeaderAccordions:first-child .datasetTableHeader': {
            visibility: "visible"
        },
        '& .MuiAccordionSummary-root': {
            padding: "0px 0px",
            minHeight: "40px",
            position: "relative"
            // marginBottom: 10
        },
        '& .MuiAccordionSummary-expandIcon': {
            position: "absolute",
            right: 5
        },
        '& .MuiAccordionSummary-content': {
            margin: 0
        },
        '& .MuiAccordionDetails-root': {
            padding: "2px 15px 15px 15px",
            background: theme.palette.background.default,
            border: `1px solid ${theme.palette.grey.extraLight}`,
            position: "relative"
        },
        '& .MuiTableCell-root': {
            borderBottom: 0,
            padding: 10
        },
        '& .MuiTableCell-head': {
            // color: theme.palette.grey.dark,
            fontWeight: 600,
            fontSize: 13,
            fontFamily: theme.palette.textFont
        },
        '& .MuiTableBody-root .MuiTableRow-root': {
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.grey.extraLight}`
        },
        '& .MuiTableCell-body': {
            fontSize: 13,
            color: theme.palette.grey.darkTxt
        },
        '& .MuiAccordion-root.Mui-expanded': {
            margin: "0px !important"
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            marginBottom: '0px'
        },
        '& .MuiAccordion-root:before': {
            backgroundColor: 'transparent'
        }
    },
    datasetTableAccordHeader: {
        '& .MuiTypography-body1': {
            fontWeight: 400
        },
        '& .MuiTableCell-body': {
            padding: '2px 16px'
        }
    },
    fixedTableCell: {
        minWidth: '135px !important',
        maxWidth: '135px !important',
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
    },
    datasetTabText: {
        fontSize: 13,
        color: theme.palette.grey.darkTxt,
        '&.whiteSpaceNowrap': {
            whiteSpace: 'nowrap'
        }
    },
    attributesTable: {
        maxHeight: 'calc(100vh - 236px)',
        overflow: 'auto',
        maxWidth: '100%',
        '& .MuiTableCell-root': {
            borderBottom: '1px solid #e1e5e6',
            padding: 10
        },
        '& .attributesTableChartRow': {
            backgroundColor: `${theme.palette.background.default} !important`
        }
    },
    attributeTitle: {
        '& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
            fontWeight: 500
        }
    },
    attributesTableChartRow: {
        height: '60px',
        backgroundColor: `${theme.palette.background.default} !important`
    },
    attributeTableDataType: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    textboxContainer: {
        '& .MuiInput-underline:before': {
            height: '36px !important'
        },
        '& input': {
            width: 200,
            height: '36px !important',
            fontFamily: theme.palette.textFont
        }
    },
    datasettabContainer: {
        backgroundColor: theme.palette.background.paper,
        padding: '15px 15px 15px 15px',
        height: '100%',
        position: 'relative'
    },
    datasetName: {
        fontSize: 13,
        color: theme.palette.grey.darkTxt,
        maxWidth: 150,
        display: "block",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        '&:hover': {
            color: theme.palette.primary.dark
        }
    },
    attributeName: {
        fontSize: 13,
        color: theme.palette.grey.darkTxt,
        maxWidth: 150,
        display: "block",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        cursor: "auto"
    },
    duplicateDatasource: {
        zIndex: 999
    },
    datasetTitleSection: {
        '& > .checkbox ': {
            paddingLeft: 3
        },
        display: "flex",
        alignItems: "center",
        '& .MuiCheckbox-root': {
            padding: '0px 9px'
        }
    },
    checkBoxTitle: {
        '& > .checkbox.path input:checked + svg': {
            left: 3
        }
    },
    attributeLoader: {
        height: 30
    },
    loadTypeInput: {
        width: 179
    },
    conTypeInput: {
        minWidth: 100
    },
    watermarkInput: {
        width: 154
    },
    configurationDialog: {
        padding: 10
    },
    submitBtn: {
        marginRight: 10
    },
    datasetSftpName: {
        maxWidth: '100%'
    },
    actionItemContainer: {
        width: '10%',
        borderRight: '1px solid #e1e5e6'
    },
    actionItems: {
        marginRight: 15,
        width: 62,
        height: 42
    },
    pullConfigContainer: {
        minWidth: 300,
        padding: 20,
        '& .MuiFormGroup-root': {
            marginTop: 20,
            marginBottom: 10,
            flexDirection: 'row',
            flexWrap: 'nowrap'
        },
        '& .MuiFormControlLabel-root': {
            marginRight: 50,
            '&:last-child': {
                marginRight: 0
            }
        }
    },
    pullParamsContainer: {
        backgroundColor: '#f6f8f9',
        padding: '15px 10px 15px 25px',
        border: '1px solid #f6f8f9',
        '& .checkbox': {
            marginTop: 20
        },
        '& .checkboxlabel': {
            marginRight: 20
        }
    },
    queryOptions: {
        '& .MuiFormGroup-root': {
            flexDirection: 'row',
            flexWrap: 'nowrap'
        },
        '& .MuiFormControlLabel-root': {
            marginRight: 50,
            '&:last-child': {
                marginRight: 0
            }
        }
    },
    flexBox: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // justifyContent: "space-between",
        cursor: "pointer"
    },
    floatRight: {
        // float: "right"
    },
    textRight: {
        textAlign: "right"
    },
    slimHeader: {
        '& th': {
            padding: '10px !important',
            '& span': {
                lineHeight: "16px"
            }
        }
    },
    querySetContainer: {
        background: 'white',
        '& .checkboxContainer': {
            minWidth: '20px',
            padding: '10px !important',
            width: '20px',
            '& label': {
                margin: '0 auto',
                padding: '0 !important',
                minWidth: 'inherit',
                maxWidth: 'inherit',
                '& .checkboxlabel': {
                    display: 'none'
                }
            }
        },
        '& .queryInput': {
            paddingLeft: '10px !important'
        },
        '& .datasetInput': {
            width: '15%',
            borderLeft: '1px solid #e1e5e6'
        },
        '& th': {
            fontSize: '13px !important'
        }
    },
    snowflakeConnector: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexWrap: 'nowrap',
        // height: '100%',
        padding: 25,
        background: '#fff',
        height: 'calc(100vh - 75px)',
        overflowY: 'auto',
        '& .connectionConfiguration': {
            flex: '0 1 auto',
            '& .MuiGrid-item': {
                padding: '8px 12px'
            }
        },
        '& .datasetConfiguration': {
            flex: '1 1 auto',
            marginTop: 10,
            overflow: 'visible',
            '&.connected': {
                height: 'calc(100vh - 600px)',
                overflow: 'hidden',
                overflowY: 'auto'
            }
        },
        '& .searchPanel': {
            flex: '0 1 auto'
        },
        '& .datasetsContainer': {
            flex: '0 1 auto'
        },
        '& .footer': {
            flex: '0 1 auto'
        }
    },
    addIconButton: {
        padding: 5,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: `${theme.palette.primary.dark} !important`
        },
        '& svg': {
            fontSize: 19,
            color: theme.palette.background.paper,
            fill: '#fff'
        }
    },
    nopadding: {
        padding: "0px !important"
    },
    collapseTable: {
        padding: "2px 15px 15px 15px",
        background: "#f4f6f9"
    },
    noBackground: {
        background: "none !important",
        border: "0px !important",
        '& th': {
            background: "none !important"
        }
    },
    flexWidth: {
        maxWidth: "350px !important"
    },
    checkboxContainer: {
        '& span': {
            fontSize: 13,
            marginTop: 0
        }
    },
    badge: {
        color: 'rgba(255, 255, 255)',
        display: 'flex',
        padding: '5px 10px',
        borderRadius: '5px',
        backgroundColor: '#F38080'
    }
});

export default ConnectorStyles;