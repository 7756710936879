import React, { useEffect, useState } from 'react';
import { withStyles, Grid, Button, ButtonGroup, IconButton, Typography, Paper, ClickAwayListener } from '@material-ui/core';
import PropTypes from 'prop-types';
import Search from '../TextBox/Search.jsx';
import { useDispatch } from 'react-redux';
import { getdomaindatasets, getdomaindatasources, getDomainMonitoring } from '../../actions/domainActions';
import Loader from '../Loaders/Loader.jsx';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';
import DomainStyles from './DomainStyles.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import { sortTable } from '../../helpers/appHelpers.js';
import MonitoringCard from './MonitoringCard.jsx';
import { appConstants } from '../../constants/appConstants.js';
import { ValidatorForm } from 'react-material-ui-form-validator';
import AutoCompleteCheckBox from '../AutoCompleteCheckBox/AutoCompleteCheckBox.jsx';
import { updateViews } from '../../actions/datasourceActions.js';

const DomainMonitoring = (props) => {
    const { classes, domainId, history, redirectData, dq_score_threshold, theme } = props;
    const [isLoading, setLoading] = useState(true);
    const [search, setSearch] = useState('');
    const [monitoringTiles, setMonitoringTiles] = useState([]);
    const [monitoringList, setMonitoringList] = useState([]);
    const [view, setView] = useState('list');
    const dispatch = useDispatch();
    const [enableFilter, setEnableFilter] = useState(false);
    const [searchFilters, setSearchFilters] = useState({
        datasources: [],
        datasets: [],
        monitoringTypes: []
    });
    const [datasourceList, setDatasourceList] = useState([]);
    const [datasetList, setDatasetList] = useState([]);
    const [datasourceNames, setDatasourceNames] = useState([]);
    const [datasetNames, setDatasetNames] = useState([]);
    const [monitoringTypes, setMonitoringTypes] = useState(appConstants.monitoringTypes);

    useEffect(() => {
        setLoading(true);
        if (domainId) {
            getDomainMonitoringList(domainId, search, searchFilters);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domainId, search]);

    useEffect(() => {
        if (domainId) {
            dispatch(getdomaindatasources({ domain_id: domainId, method: 'datasource' })).then((response) => {
                const responseData = response.data;
                setDatasourceList(responseData);
            });
            dispatch(getdomaindatasets({ domain_id: domainId })).then((response) => {
                const responseData = response.data;
                setDatasetList(responseData);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domainId]);

    const getDomainMonitoringList = (domainId, search = '', filter = null) => {
        dispatch(getDomainMonitoring({ domain_id: domainId, search: search, filter: filter })).then((response) => {
            setMonitoringList(response);
            const tileResponse = [];
            response.map((elem) => {
                const tileIndex = tileResponse.findIndex((item) => item.dataset_id === elem.dataset_id);
                if (tileIndex > -1) {
                    tileResponse[tileIndex].monitorings.push(elem);
                } else {
                    tileResponse.push({
                        dataset_id: elem.dataset_id,
                        dataset_name: elem.dataset_name,
                        monitorings: [elem]
                    });
                }
                return tileResponse;
            });
            setMonitoringTiles(tileResponse);
            setLoading(false);
        });
    };

    const onChangeSearch = (value) => {
        setSearch(value);
    };

    const searchOptions = (name, search_key = '') => {
        if (name === 'datasets') {
            let datasetListCopy = [...JSON.parse(JSON.stringify(datasetList))];
            datasetListCopy = datasetListCopy.map((datasource) => {
                if (searchFilters[name].some((elem) => datasource.id === elem)) {
                    return {
                        ...datasource,
                        isSelected: true
                    };
                }
                return datasource;
            });
            if (search_key) {
                datasetListCopy = datasetListCopy.filter((elem) => new RegExp(search_key, 'i').test(elem.name));
            }
            setDatasetNames(datasetListCopy);
        } else if (name === 'datasources') {
            let datasourceListCopy = [...JSON.parse(JSON.stringify(datasourceList))];
            datasourceListCopy = datasourceListCopy.map((datasource) => {
                if (searchFilters[name].some((elem) => datasource.id === elem)) {
                    return {
                        ...datasource,
                        isSelected: true
                    };
                }
                return datasource;
            });
            if (search_key) {
                datasourceListCopy = datasourceListCopy.filter((elem) => new RegExp(search_key, 'i').test(elem.name));
            }
            setDatasourceNames(datasourceListCopy);
        } else if (name === 'monitoringTypes') {
            let monitoringTypeListCopy = [...JSON.parse(JSON.stringify(appConstants.monitoringTypes))];
            monitoringTypeListCopy = monitoringTypeListCopy.map((type) => {
                if (searchFilters[name].some((elem) => type.name === elem)) {
                    return {
                        ...type,
                        isSelected: true
                    };
                }
                return type;
            });
            if (search_key) {
                monitoringTypeListCopy = monitoringTypeListCopy.filter((elem) => new RegExp(search_key, 'i').test(elem.name));
            }
            setMonitoringTypes(monitoringTypeListCopy);
        }
    };

    const onChangeFilter = (name, value) => {
        if (value || value === false) {
            let filterChange = { ...JSON.parse(JSON.stringify(searchFilters)) };
            if (name === 'datasets') {
                let datasetNamesCopy = [...JSON.parse(JSON.stringify(datasetNames))];
                if (value.name === 'all') {
                    if (value.isSelected) {
                        datasetNamesCopy = datasetNamesCopy.map((elem) => ({ ...elem, isSelected: true }));
                    } else {
                        datasetNamesCopy = datasetNamesCopy.map((elem) => ({ ...elem, isSelected: false }));
                    }
                    filterChange = { ...filterChange, [name]: datasetNamesCopy.filter((elem) => elem.isSelected).map((elem) => elem.id) };
                } else {
                    const valueIndex = datasetNamesCopy.findIndex((elem) => elem.id === value.id);
                    if (valueIndex > -1) {
                        if (datasetNamesCopy[valueIndex].isSelected) {
                            datasetNamesCopy[valueIndex].isSelected = false;
                        } else {
                            datasetNamesCopy[valueIndex].isSelected = true;
                        }
                    }
                    filterChange = { ...filterChange, [name]: datasetNamesCopy.filter((elem) => elem.isSelected).map((elem) => elem.id) };
                }
                setDatasetNames(datasetNamesCopy);
            } else if (name === 'datasources') {
                let datsourceNamesCopy = [...JSON.parse(JSON.stringify(datasourceNames))];
                if (value.name === 'all') {
                    if (value.isSelected) {
                        datsourceNamesCopy = datsourceNamesCopy.map((elem) => ({ ...elem, isSelected: true }));
                    } else {
                        datsourceNamesCopy = datsourceNamesCopy.map((elem) => ({ ...elem, isSelected: false }));
                    }
                    filterChange = { ...filterChange, [name]: datsourceNamesCopy.filter((elem) => elem.isSelected).map((elem) => elem.id) };
                } else {
                    const valueIndex = datsourceNamesCopy.findIndex((elem) => elem.id === value.id);
                    if (valueIndex > -1) {
                        if (datsourceNamesCopy[valueIndex].isSelected) {
                            datsourceNamesCopy[valueIndex].isSelected = false;
                        } else {
                            datsourceNamesCopy[valueIndex].isSelected = true;
                        }
                    }
                    filterChange = { ...filterChange, [name]: datsourceNamesCopy.filter((elem) => elem.isSelected).map((elem) => elem.id) };
                }
                setDatasourceNames(datsourceNamesCopy);
            } else if (name === 'monitoringTypes') {
                let datsourceNamesCopy = [...JSON.parse(JSON.stringify(monitoringTypes))];
                if (value.name === 'all') {
                    if (value.isSelected) {
                        datsourceNamesCopy = datsourceNamesCopy.map((elem) => ({ ...elem, isSelected: true }));
                    } else {
                        datsourceNamesCopy = datsourceNamesCopy.map((elem) => ({ ...elem, isSelected: false }));
                    }
                    filterChange = { ...filterChange, [name]: datsourceNamesCopy.filter((elem) => elem.isSelected).map((elem) => elem.value) };
                } else {
                    const valueIndex = datsourceNamesCopy.findIndex((elem) => elem.name === value.name);
                    if (valueIndex > -1) {
                        if (datsourceNamesCopy[valueIndex].isSelected) {
                            datsourceNamesCopy[valueIndex].isSelected = false;
                        } else {
                            datsourceNamesCopy[valueIndex].isSelected = true;
                        }
                    }
                    filterChange = { ...filterChange, [name]: datsourceNamesCopy.filter((elem) => elem.isSelected).map((elem) => elem.value) };
                }
                setMonitoringTypes(datsourceNamesCopy);
            } else {
                filterChange = { ...filterChange, [name]: value };
            }
            setSearchFilters(filterChange);
            getDomainMonitoringList(domainId, search, filterChange);
        }
    };
    const resetFilter = () => {
        const filterChange = {
            datasources: [],
            datasets: [],
            monitoringTypes: []
        };
        setSearchFilters(filterChange);
        getDomainMonitoringList(domainId, search, filterChange);
        setDatasetNames(datasetList);
        setDatasourceNames(datasourceList);
        setMonitoringTypes(appConstants.monitoringTypes);

    };

    const redirectSource = (monitoring) => {
        const model = {
            "dataset_id": monitoring?.dataset_id || 0
        };
        dispatch(updateViews(model));
        history.push({ pathname: `/dataset/${monitoring.dataset_id}`, state: { datasource: {}, monitoring: monitoring?.monitoring_id || 1 } });

    };

    return (
        <Grid container className={classNames(classes.relative)}>
            <Grid item align="right" xs={12}>
                <Grid container style={{ justifyContent: 'right', alignItems: 'center' }} >
                    <Grid item >
                        <Search
                            value={search}
                            onChange={(value) => onChangeSearch(value)}
                            placeholder="Search Here"
                        />
                    </Grid>
                    <Grid item>
                        <ToolTipComponent title="Filters" placement="bottom" arrow>
                            <IconButton onClick={() => setEnableFilter(!enableFilter)} className={classNames(classes.marginRight7, classes.filterImg)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.923" viewBox="0 0 20 16.923">
                                    <g transform="translate(-3 -5)">
                                        <path id="Path_1391" data-name="Path 1391" d="M22.231,6.538H11.462A1.54,1.54,0,0,0,9.923,5H8.385A1.54,1.54,0,0,0,6.846,6.538H3.769a.769.769,0,1,0,0,1.538H6.846A1.54,1.54,0,0,0,8.385,9.615H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,8.077V6.538H9.923V7.3s0,0,0,0,0,0,0,0v.766Z" fill={theme.palette.primary.main} />
                                        <path id="Path_1392" data-name="Path 1392" d="M22.231,14.538H17.615A1.54,1.54,0,0,0,16.077,13H14.538A1.54,1.54,0,0,0,13,14.538H3.769a.769.769,0,0,0,0,1.538H13a1.54,1.54,0,0,0,1.538,1.538h1.538a1.54,1.54,0,0,0,1.538-1.538h4.615a.769.769,0,1,0,0-1.538Zm-7.692,1.538V14.538h1.538V15.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -1.846)" fill={theme.palette.primary.main} />
                                        <path id="Path_1393" data-name="Path 1393" d="M22.231,22.538H11.462A1.54,1.54,0,0,0,9.923,21H8.385a1.54,1.54,0,0,0-1.538,1.538H3.769a.769.769,0,0,0,0,1.538H6.846a1.54,1.54,0,0,0,1.538,1.538H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,24.077V22.538H9.923V23.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -3.692)" fill={theme.palette.primary.main} />
                                    </g>
                                </svg>
                            </IconButton>
                        </ToolTipComponent>
                    </Grid>
                    <Grid item >
                        <Grid container direction="row" justify="center" alignItems="center" wrap="nowrap" className={classes.marginLeft15}>
                            <ButtonGroup className={classes.buttonGroup}>
                                <Button variant="contained"
                                    className={classNames(view === 'list' ? 'active' : '')}
                                    onClick={() => setView('list')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16">
                                        <g id="Layer_2" data-name="Layer 2" transform="translate(-2.268 -4)">
                                            <path id="Path_236" data-name="Path 236" d="M21.71,4H11.3a1.3,1.3,0,1,0,0,2.6H21.71a1.3,1.3,0,0,0,0-2.6Z" transform="translate(-2.743)" fill="#afb2b3" />
                                            <circle id="Ellipse_88" data-name="Ellipse 88" cx="1" cy="1" r="1" transform="translate(2.268 4)" fill="#afb2b3" />
                                            <path id="Path_237" data-name="Path 237" d="M21.71,14H11.3a1.3,1.3,0,1,0,0,2.6H21.71a1.3,1.3,0,0,0,0-2.6Z" transform="translate(-2.743 -3.495)" fill="#afb2b3" />
                                            <circle id="Ellipse_89" data-name="Ellipse 89" cx="1" cy="1" r="1" transform="translate(2.268 11)" fill="#afb2b3" />
                                            <path id="Path_238" data-name="Path 238" d="M21.71,24H11.3a1.3,1.3,0,1,0,0,2.6H21.71a1.3,1.3,0,0,0,0-2.6Z" transform="translate(-2.743 -6.989)" fill="#afb2b3" />
                                            <ellipse id="Ellipse_90" data-name="Ellipse 90" cx="1" cy="1.5" rx="1" ry="1.5" transform="translate(2.268 17)" fill="#afb2b3" />
                                        </g>
                                    </svg>
                                </Button>
                                <Button variant="contained"
                                    className={classNames(view === 'tile' ? 'active' : '')}
                                    onClick={() => setView('tile')}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="16" viewBox="0 0 25 16">
                                        <g id="Group_1523" data-name="Group 1523" transform="translate(-1233.716 -173.46)">
                                            <g id="Rectangle_618" data-name="Rectangle 618" transform="translate(1240.716 173.46)" fill="none" stroke="#afb2b3" strokeWidth="1.5">
                                                <rect width="11" height="16" stroke="none" />
                                                <rect x="0.75" y="0.75" width="9.5" height="14.5" fill="none" />
                                            </g>
                                            <g id="Rectangle_619" data-name="Rectangle 619" transform="translate(1253.716 176.46)" fill="none" stroke="#afb2b3" strokeWidth="1.5">
                                                <rect width="5" height="11" stroke="none" />
                                                <rect x="0.75" y="0.75" width="3.5" height="9.5" fill="none" />
                                            </g>
                                            <g id="Rectangle_620" data-name="Rectangle 620" transform="translate(1233.716 176.46)" fill="none" stroke="#afb2b3" strokeWidth="1.5">
                                                <rect width="5" height="11" stroke="none" />
                                                <rect x="0.75" y="0.75" width="3.5" height="9.5" fill="none" />
                                            </g>
                                        </g>
                                    </svg>
                                </Button>
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                view === 'list' &&
                <Grid item xs={12}>
                    <Grid container className={classes.listSection} spacing={2}>
                        <Grid item xs={2}>
                            <Typography>
                                Monitoring Job
                            </Typography>
                        </Grid>
                        {/* <Grid item xs={2} >
                            <Typography>
                                Type
                            </Typography>
                        </Grid> */}
                        <Grid item xs={3} >
                            <Typography>
                                Source Dataset
                            </Typography>
                        </Grid>
                        <Grid item xs={3} >
                            <Typography>
                                Target Dataset
                            </Typography>
                        </Grid>
                        <Grid item xs={1} >
                            <Typography>
                                Updated Time
                            </Typography>
                        </Grid>
                        <Grid item xs={1} >
                            <Typography>
                                Source Row Count
                            </Typography>
                        </Grid>
                        <Grid item xs={1} >
                            <Typography>
                                Target Row Count
                            </Typography>
                        </Grid>
                        <Grid item xs={1} align="center">
                            <Typography>
                                Percentage difference
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.domainMonitoringListContainer}>
                        <Grid item xs={12}>
                            {
                                isLoading &&
                                <Loader />
                            }
                            {
                                !isLoading && monitoringList.map((monitoring, index) => (
                                    <Grid key={index} container className={classes.domainMonitoringValueListContainer} spacing={2}>
                                        <Grid item xs={2}>
                                            {/* <ToolTipComponent title={monitoring?.monitoring_name || 'N/A'} arrow> */}
                                            <Typography onClick={() => redirectSource(monitoring)} className={classNames(classes.cursor, classes.domainAttributetxt, classes.wordWrap)}>
                                                {monitoring?.monitoring_name || 'N/A'}
                                            </Typography>
                                            {/* </ToolTipComponent> */}
                                        </Grid>
                                        {/* <Grid item xs={2} >
                                            <ToolTipComponent title={monitoring?.monitoring_type || 'N/A'} arrow>
                                                <Typography>
                                                    {monitoring?.monitoring_type || 'N/A'}
                                                </Typography>
                                            </ToolTipComponent>
                                        </Grid> */}
                                        <Grid item xs={3} >
                                            {/* <ToolTipComponent title={monitoring?.dataset_name || 'N/A'} arrow> */}
                                            <Typography className={classes.wordWrap}>
                                                {monitoring?.dataset_name || 'N/A'}
                                            </Typography>
                                            {/* </ToolTipComponent> */}
                                        </Grid>
                                        <Grid item xs={3} className={classes.wordWrap}>
                                            {/* <ToolTipComponent title={monitoring?.target_name || 'N/A'} arrow> */}
                                            <Typography>
                                                {monitoring?.target_name || 'N/A'}
                                            </Typography>
                                            {/* </ToolTipComponent> */}
                                        </Grid>
                                        <Grid item xs={1} className={classes.wordWrap}>
                                            {/* <ToolTipComponent title={monitoring?.monitoring_run_time || 'N/A'} arrow> */}
                                            <Typography>
                                                {monitoring?.monitoring_run_time || 'N/A'}
                                            </Typography>
                                            {/* </ToolTipComponent> */}
                                        </Grid>
                                        <Grid item xs={1} className={classes.wordWrap} align="center">
                                            {/* <ToolTipComponent title={monitoring?.source_count || 'N/A'} arrow> */}
                                            <Typography>
                                                {monitoring?.source_count || 0}
                                            </Typography>
                                            {/* </ToolTipComponent> */}
                                        </Grid>
                                        <Grid item xs={1} className={classes.wordWrap} align="center">
                                            {/* <ToolTipComponent title={monitoring?.target_count || 'N/A'} arrow> */}
                                            <Typography>
                                                {monitoring?.target_count || 0}
                                            </Typography>
                                            {/* </ToolTipComponent> */}
                                        </Grid>
                                        <Grid item xs={1} align="center">
                                            <Paper className={classes.domainMonitoringDifference}>
                                                {/* <ToolTipComponent title={((monitoring?.source_count || 0) - (monitoring?.target_count || 0)) || 'N/A'} arrow> */}
                                                <Typography>
                                                    {monitoring.percentage ? `${parseFloat(monitoring.percentage).toFixed(2)}%(${((monitoring?.source_count || 0) - (monitoring?.target_count || 0))})` : "NIL"}
                                                </Typography>
                                                {/* </ToolTipComponent> */}
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                view === 'tile' &&
                <Grid item xs={12}>
                    <Grid className={classes.kanbanContainer}>
                        {
                            monitoringTiles.sort((a, b) => b.dataset_name - a.dataset_name).map((rule, index) =>
                                <Grid key={index} className={classes.kanbanviewSection}>
                                    <Grid className={classNames(classes.titleContainer, rule.dataset_name !== "" ? classes.kanbanBlueHeader : classes.kanbanGreyHeader)} container justify="space-between">
                                        <Typography className={classes.titleTxt} >
                                            {rule.dataset_name ? rule.dataset_name : "Others"}
                                        </Typography>
                                        {/* <Typography align="right" className={classes.titleTxt}>
                                            {`${rule.score ? getValue(rule.score) : 0}%`}
                                        </Typography> */}

                                    </Grid>
                                    <Grid className={classes.kanbanSection}>
                                        {
                                            isLoading &&
                                            <Loader />
                                        }
                                        {
                                            !isLoading && rule.monitorings && rule.monitorings.map((monitoring, monitorIndex) =>
                                                <MonitoringCard redirectData={(params) => redirectData(params)} key={monitorIndex} monitoring={monitoring} index={monitorIndex} history={history} dq_score_threshold={dq_score_threshold} redirectSource={redirectSource} />
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                </Grid>
            }
            {
                enableFilter &&
                <Grid container className={classes.filterSection}>
                    <ValidatorForm onSubmit={() => null} style={{ display: 'flex', alignItems: 'center' }}>
                        <Grid item className={classNames(classes.FilterHideShowContainer, classes.filterPosition)}>
                            <ClickAwayListener onClickAway={() => setEnableFilter(false)} mouseEvent={'onMouseUp'} touchEvent={'onTouchEnd'}>
                                <Grid container alignItems="center" justify="space-between" className={classNames(classes.datasourceFilterSection, "datasourceFilterSection")}>
                                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)} xs={3}>
                                        <AutoCompleteCheckBox
                                            value={searchFilters.datasources ? searchFilters.datasources : []}
                                            availableList={sortTable(datasourceNames ? datasourceNames : [], "asc", "name")}
                                            onChange={(value) => onChangeFilter("datasources", value)}
                                            onSearch={(value) => searchOptions("datasources", value)}
                                            fullWidth
                                            label="Datasource"
                                            noMargin
                                            isHideSelectedAllCount
                                        />
                                    </Grid>
                                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)} xs={3}>
                                        <AutoCompleteCheckBox
                                            value={searchFilters.datasets ? searchFilters.datasets : []}
                                            availableList={sortTable(datasetNames ? datasetNames : [], "asc", "name")}
                                            onChange={(value) => onChangeFilter("datasets", value)}
                                            onSearch={(value) => searchOptions("datasets", value)}
                                            fullWidth
                                            label="Datasets"
                                            noMargin
                                            isHideSelectedAllCount
                                        />
                                    </Grid>
                                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)} xs={3}>
                                        <AutoCompleteCheckBox
                                            value={searchFilters.monitoringTypes ? searchFilters.monitoringTypes : []}
                                            availableList={sortTable(monitoringTypes || [], "asc", "name")}
                                            onChange={(value) => onChangeFilter("monitoringTypes", value)}
                                            onSearch={(value) => searchOptions("monitoringTypes", value)}
                                            fullWidth
                                            label="Monitoring Type"
                                            noMargin
                                            isHideSelectedAllCount
                                        />
                                    </Grid>
                                    <Grid item xs={1} style={{ display: 'flex' }}>
                                        <ToolTipComponent title="Clear Filter" placement="bottom" arrow>
                                            <IconButton onClick={() => resetFilter()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22.795" viewBox="0 0 20 22.795">
                                                    <g id="filter_10_" data-name="filter (10)" transform="translate(-31.388)">
                                                        <path id="Path_1437" data-name="Path 1437" d="M46.071,0a5.326,5.326,0,0,0-5.1,3.8h-8.59a1,1,0,0,0-.814,1.564l5.776,7.882a1.046,1.046,0,0,1,.185.6V21.75a1.044,1.044,0,0,0,1.668.835l2.564-1.937a1.527,1.527,0,0,0,.612-1.22V13.84a1.047,1.047,0,0,1,.185-.6l2.07-2.825A5.31,5.31,0,1,0,46.071,0ZM41.477,12.462a2.375,2.375,0,0,0-.438,1.377c0,6.076.033,5.658-.075,5.737l-2.1,1.586V13.84a2.376,2.376,0,0,0-.428-1.364l-5.38-7.341h7.7A5.307,5.307,0,0,0,43.369,9.88Zm4.594-3.18a3.973,3.973,0,1,1,3.982-3.973A3.982,3.982,0,0,1,46.071,9.282Z" transform="translate(0)" fill="#f38080" />
                                                        <path id="Path_1438" data-name="Path 1438" d="M321.45,76.089a.668.668,0,0,0-.944,0l-.793.791-.793-.791a.668.668,0,0,0-.944.945l.791.79-.791.79a.668.668,0,0,0,.944.945l.793-.791.793.791a.668.668,0,0,0,.944-.945l-.791-.79.791-.79A.668.668,0,0,0,321.45,76.089Z" transform="translate(-273.642 -72.515)" fill="#f38080" />
                                                    </g>
                                                </svg>
                                            </IconButton>
                                        </ToolTipComponent>
                                        <ToolTipComponent title="Close" placement="bottom" arrow>
                                            <IconButton onClick={() => setEnableFilter(!enableFilter)} className={classes.marginRight12}>
                                                <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                    <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                                </svg>
                                            </IconButton>
                                        </ToolTipComponent>
                                    </Grid>
                                </Grid>
                            </ClickAwayListener>
                        </Grid>
                    </ValidatorForm>
                </Grid>
            }
        </Grid >
    );
};

DomainMonitoring.propTypes = {
    classes: PropTypes.object,
    domainId: PropTypes.number,
    history: PropTypes.object,
    redirectData: PropTypes.func,
    dq_score_threshold: PropTypes.object,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DomainMonitoring);