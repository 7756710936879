/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React from 'react';
import { TableBody } from '@material-ui/core';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

/*
 * Reference: https://github.com/hmarggraff/react-material-ui-table-row-drag-and-drop/blob/master/src/index.js
 * Droppable TableBody - A Higher Order Component
 */
const DroppableTableBody = (onDragEnd, id, attributes = []) => (props) => {
    return (
        <DragDropContext onDragEnd={(result) => onDragEnd(result, attributes)}>
            <Droppable droppableId={id ? id : 'master_group_container'} direction="vertical">
                {
                    (provided) => {
                        return (
                            <TableBody ref={provided.innerRef} {...provided.droppableProps} {...props}>
                                {props.children}
                                {provided.placeholder}
                            </TableBody>
                        );
                    }
                }
            </Droppable>
        </DragDropContext>
    );
};

export default DroppableTableBody;