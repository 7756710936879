export const SearchBoxLineageStyle = (theme) => ({
    searchSourceText: {
        color: theme.palette.grey.dark,
        fontSize: 11,
        marginBottom: 10
    },
    searchListItemContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    listIcon: {
        width: '1rem'
    },
    listTextContainer: {
        marginLeft: '0.5rem'
    },
    searchBoxContainer: {
        transitionDuration: '500ms',
        transition: 'width .55s ease',
        animation: 'slide-left220 0.4s ease',
        width: '220px',
        height: 30,
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #dcdcdc !important'
        }
    }
});