import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { withStyles, Grid, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import classNames from 'classnames';
import NumberInput from '../../TextBox/NumberInput.jsx';
import { DateTimePicker, DatePicker, TimePicker } from '@material-ui/pickers';
import TextBox from '../../TextBox/TextBox.jsx';
import RuleBuilderStyles from '../QueryBuilderStyles.jsx';
import RangeValueEditor from './RangeValueEditor.jsx';
import CharacterCountEditor from './CharacterCountEditor.jsx';

// import FormatEditor from './FormatEditor.jsx';


const ValueEditor = (props) => {
    const { rule, onChange, classes, disabled } = props;
    const [inputRule, setInputRule] = useState({});

    const onUpdateValues = useCallback((value, property) => {
        property = property ? property : 'value';
        inputRule[property] = value;
        setInputRule({ ...inputRule });
    }, [inputRule]);

    const onKeyDown = useCallback((event) => {
        const inputElement = event.target;
        if (event.keyCode === 13 && inputElement) {
            onChange(event.target.value);
        }
    }, [onChange]);

    const loadRule = useCallback((rule) => {
        setInputRule({ ...rule });
    }, []);

    useEffect(() => {
        loadRule(rule);
    }, [loadRule, rule]);


    if (!inputRule || (inputRule && (!inputRule.operator))) {
        return null;
    }
    if (inputRule.operator && !inputRule.operator.valueRequired) {
        return null;
    }

    if (inputRule.operator && inputRule.operator.label && inputRule.operator.label.toLowerCase().includes('length of character')) {
        return (
            <Grid>
                <CharacterCountEditor {...props}
                    rule={{ ...inputRule }}
                    onChange={(...props) => onUpdateValues(...props)}
                    onKeyDown={(event) => onKeyDown(event)}
                    onBlur={(...props) => onChange(...props)} />
            </Grid>
        );
    }

    if (inputRule.operator && inputRule.operator.label && inputRule.operator.label.toLowerCase().includes('between')) {
        return (
            <Grid>
                <RangeValueEditor {...props}
                    rule={{ ...inputRule }}
                    onChange={(...props) => onUpdateValues(...props)}
                    onKeyDown={(event) => onKeyDown(event)}
                    onBlur={(...props) => onChange(...props)} />
            </Grid>

        );
    }

    if (inputRule.operator && inputRule.operator.label && inputRule.operator.label.toLowerCase().includes('matches')) {
        return (
            <Grid>
                <TextBox className={classNames(classes.inputStyle)}
                    onChange={(event) => onUpdateValues(event.target.value)}
                    onBlur={(event) => onChange(event.target.value)}
                    onKeyDown={(event) => onKeyDown(event)}
                    validators={['required']}
                    errorMessages={['Value is required']}
                    value={inputRule.value ? inputRule.value : ''}
                    fullWidth />
            </Grid>

        );
    }

    /*
     * if (inputRule.operator && inputRule.operator.isFormat) {
     *     return (
     *         <Grid>
     *             <FormatEditor
     *                 {...props}
     *                 rule={{ ...inputRule }}
     *                 onChange={(...props) => onUpdateValues(...props)}
     *                 onKeyDown={(event) => onKeyDown(event)}
     *                 onBlur={(...props) => onChange(...props)} />
     *         </Grid>
     *     );
     * }
     */

    switch (inputRule.fieldType.toLowerCase()) {
        case 'numeric':
            return (
                <Grid>
                    <NumberInput className={classNames(classes.inputStyle)}
                        onChange={(event) => onUpdateValues(event.target.value)}
                        onBlur={(event) => onChange(event.target.value)}
                        onKeyDown={(event) => onKeyDown(event)}
                        validators={['required']}
                        errorMessages={['Value is required']}
                        disabled={disabled}
                        pattern={/^([+-])?[0-9]*(.[0-9]*)?$/}
                        value={inputRule.value ? inputRule.value : ''}
                        fullWidth />
                </Grid>
            );
            /*
             * return (
             *     <Grid>
             *         <TextBox className={classNames(classes.inputStyle)}
             *             onChange={(event) => onUpdateValues(event.target.value)}
             *             onBlur={(event) => onChange(event.target.value)}
             *             onKeyDown={(event) => onKeyDown(event)}
             *             validators={['required']}
             *             errorMessages={['Value is required']}
             *             value={inputRule.value ? inputRule.value : ''}
             *             fullWidth />
             *     </Grid>
             * );
             */

        case 'datetime':
            if (inputRule.operator && inputRule.operator.isChartOperation) {
                return (
                    <Grid>
                        <TextBox className={classNames(classes.inputStyle)}
                            onChange={(event) => onUpdateValues(event.target.value)}
                            onBlur={(event) => onChange(event.target.value)}
                            onKeyDown={(event) => onKeyDown(event)}
                            disabled={disabled}
                            validators={['required']}
                            errorMessages={['Value is required']}
                            value={inputRule.value ? inputRule.value : ''}
                            fullWidth />
                    </Grid>
                );
            }
            return (
                <Grid>
                    <Fragment>
                        {/* {
                            inputRule.attributeType && isNaN(Date.parse(inputRule.value)) &&
                            <TextBox className={classNames(classes.inputStyle)}
                                onChange={(event) => onUpdateValues(event.target.value)}
                                onBlur={(event) => onChange(event.target.value)}
                                onKeyDown={(event) => onKeyDown(event)}
                                validators={['required']}
                                errorMessages={['Value is required']}
                                value={inputRule.value ? inputRule.value : ''}
                                fullWidth />
                        } */}
                        {
                            inputRule.attributeType && inputRule.attributeType.toString().toLowerCase() === 'datetimeoffset' &&
                            <DateTimePicker autoOk
                                className={classNames(classes.inputStyle)}
                                variant="inline"
                                ampm={false}
                                format="MM-DD-YYYY HH:mm:ss"
                                disabled={disabled}
                                value={inputRule.value ? inputRule.value : null}
                                onChange={(date) => onUpdateValues(moment(date).format('MM-DD-YYYY HH:mm:ss'))}
                                onBlur={(date) => onChange(inputRule.value ? moment(inputRule.value).format('MM-DD-YYYY HH:mm:ss') : moment(date).format('MM-DD-YYYY HH:mm:ss'))}
                            />
                        }
                        {
                            inputRule.attributeType && inputRule.attributeType.toString().toLowerCase() === 'datetime' &&
                            <DateTimePicker autoOk
                                className={classNames(classes.inputStyle)}
                                variant="inline"
                                ampm={false}
                                format="MM-DD-YYYY HH:mm"
                                disabled={disabled}
                                value={inputRule.value ? inputRule.value : null}
                                onChange={(date) => onUpdateValues(moment(date).format('MM-DD-YYYY HH:mm'))}
                                onBlur={(date) => onChange(inputRule.value ? moment(inputRule.value).format('MM-DD-YYYY HH:mm') : moment(date).format('MM-DD-YYYY HH:mm'))}
                            />
                        }
                        {
                            inputRule.attributeType && inputRule.attributeType.toString().toLowerCase() === 'date' &&
                            <DatePicker autoOk
                                className={classNames(classes.inputStyle)}
                                variant="inline"
                                format="MM-DD-YYYY"
                                disabled={disabled}
                                value={inputRule.value ? inputRule.value : null}
                                onChange={(date) => onUpdateValues(moment(date).format('MM-DD-YYYY'))}
                                onBlur={(date) => onChange(inputRule.value ? moment(inputRule.value).format('MM-DD-YYYY') : moment(date).format('MM-DD-YYYY'))} />
                        }
                        {
                            inputRule.attributeType && inputRule.attributeType.toString().toLowerCase() === 'time' &&
                            <TimePicker
                                autoOk
                                ampm={false}
                                className={classNames(classes.inputStyle)}
                                variant="inline"
                                format="HH:mm"
                                disabled={disabled}
                                value={inputRule.value}
                                onChange={(date) => onUpdateValues(moment(date))}
                                onBlur={() => onChange(inputRule.value)}
                            />
                        }
                    </Fragment>
                </Grid>
            );
        default:
            return (
                <Grid>
                    <Fragment>
                        {
                            !inputRule.options &&
                            <TextBox className={classNames(classes.inputStyle, rule.operator && (rule.operator.label.toString().toLowerCase() === 'starts with' || rule.operator.label.toString().toLowerCase() === 'ends with') ? classes.removeCase : '')}
                                onChange={(event) => onUpdateValues(event.target.value)}
                                onBlur={(event) => onChange(event.target.value)}
                                onKeyDown={(event) => onKeyDown(event)}
                                disabled={disabled}
                                validators={['required']}
                                errorMessages={['Value is required']}
                                value={inputRule.value ? inputRule.value : ''}
                                fullWidth />
                        }
                        {
                            inputRule.options && inputRule.options.length > 0 &&
                            <TextBox className={classNames(classes.inputStyle, classes.selectContainer)}
                                onChange={(event) => onUpdateValues(event.target.value)}
                                onBlur={(event) => onChange(event.target.value)}
                                onKeyDown={(event) => onKeyDown(event)}
                                disabled={disabled}
                                validators={['required']}
                                errorMessages={['Value is required']}
                                value={inputRule.value}
                                fullWidth
                                select>
                                {
                                    inputRule.options.map((option, index) => {
                                        return (
                                            <MenuItem key={`option_${index}`} value={option} className={classes.option}>
                                                {option.name}
                                            </MenuItem>
                                        );
                                    })
                                }
                            </TextBox>
                        }
                    </Fragment>
                </Grid>
            );
    }
};

ValueEditor.propTypes = {
    classes: PropTypes.object,
    rule: PropTypes.object,
    onChange: PropTypes.func,
    disabled: PropTypes.bool
};

export default withStyles(RuleBuilderStyles)(ValueEditor);