import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import { notificationActions } from '../constants/actionTypes/notificationActionTypes.js';
import * as service from '../services/apiService';
import { scrollConversation } from '../helpers/appHelpers';

export const toggleSidePanel = (value) => {
    return {
        type: notificationActions.TOGGLE_SIDE_PANEL,
        responseData: value
    };
};

export const createActionItem = (requestParams) => {
    return (dispatch) => {
        dispatch(request(notificationActions.CREATE_ACTION_ITEM_REQUEST));
        return service.postRequest(appConstants.END_POINTS.notification.createActionItem, requestParams).then((response) => {
            dispatch(success(notificationActions.CREATE_ACTION_ITEM_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.CREATE_ACTION_ITEM_FAILURE, error));
        });
    };
};

export const createActionComment = (requestParams) => {
    return (dispatch) => {
        dispatch(request(notificationActions.CREATE_ACTION_ITEM_COMMENT_REQUEST));
        return service.postRequest(appConstants.END_POINTS.notification.createActionComment, requestParams).then((response) => {
            dispatch(success(notificationActions.CREATE_ACTION_ITEM_COMMENT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.CREATE_ACTION_ITEM_COMMENT_FAILURE, error));
        });
    };
};

export const updateActionItem = (requestParams, actionId) => {
    const endpoint = `${appConstants.END_POINTS.notification.updateActionItem}/${actionId}`;
    return (dispatch) => {
        dispatch(request(notificationActions.UPDATE_ACTION_ITEM_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(notificationActions.UPDATE_ACTION_ITEM_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.UPDATE_ACTION_ITEM_FAILURE, error));
        });
    };
};

export const getUserActionItem = (datasourceId, datasetId, type) => {
    const endpoint = `${appConstants.END_POINTS.notification.getUserActionItem}/${datasourceId}/${datasetId}/${type}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_ACTION_ITEM_BY_USER_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.GET_ACTION_ITEM_BY_USER_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_ACTION_ITEM_BY_USER_FAILURE, error));
        });
    };
};

export const getActionItemCount = (datasourceId, datasetId) => {
    const endpoint = `${appConstants.END_POINTS.notification.getActionItemCount}/${datasourceId}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_ACTION_ITEM_COUNT_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.GET_ACTION_ITEM_COUNT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_ACTION_ITEM_COUNT_FAILURE, error));
        });
    };
};


export const getAllActionItem = () => {
    const endpoint = `${appConstants.END_POINTS.notification.getAllActionItem}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_ALL_ACTION_ITEM_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.GET_ALL_ACTION_ITEM_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_ALL_ACTION_ITEM_FAILURE, error));
        });
    };
};


export const getActionItemAnalysis = () => {
    const endpoint = `${appConstants.END_POINTS.notification.getActionItemAnalysis}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_ALL_ACTION_ITEM_METRICS_ANALYSIS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.GET_ALL_ACTION_ITEM_METRICS_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_ALL_ACTION_ITEM_METRICS_ANALYSIS_FAILURE, error));
        });
    };
};

export const getMyActionItem = () => {
    const endpoint = `${appConstants.END_POINTS.notification.getMyActionItem}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_MY_ACTION_ITEM_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.GET_MY_ACTION_ITEM_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_MY_ACTION_ITEM_FAILURE, error));
        });
    };
};

export const deleteActionItem = (actionId) => {
    const endpoint = `${appConstants.END_POINTS.notification.deleteActionItem}/${actionId}`;
    return (dispatch) => {
        dispatch(request(notificationActions.DELETE_ACTION_ITEM_REQUEST));
        return service.deleteRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.DELETE_ACTION_ITEM_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.DELETE_ACTION_ITEM_FAILURE, error));
        });
    };
};

export const getNotification = (params, resetData = false, cancellationToken = null) => {
    return (dispatch) => {
        dispatch(request(notificationActions.GET_USER_NOTIFICATION_REQUEST));
        return service.postRequest(appConstants.END_POINTS.notification.getNotification, params, false, cancellationToken).then((response) => {
            dispatch(success(notificationActions.GET_USER_NOTIFICATION_SUCCESS, { data: response.data, resetData }));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_USER_NOTIFICATION_FAILURE, error));
        });
    };
};

export const getUserBasedActivity = (params) => {
    return (dispatch) => {
        dispatch(request(notificationActions.GET_USER_AUDIT_REQUEST));
        return service.postRequest(appConstants.END_POINTS.notification.getuserbasedactivity, params).then((response) => {
            dispatch(success(notificationActions.GET_USER_AUDIT_SUCCESS));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_USER_AUDIT_FAILURE, error));
        });
    };
};

export const updateActions = (value) => {
    return {
        type: notificationActions.UPDATE_NOTIFICATION,
        responseData: value
    };
};

export const updateNotification = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.notification.updateNotification}`;
    return (dispatch) => {
        dispatch(request(notificationActions.UPDATE_USER_NOTIFICATION_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(notificationActions.UPDATE_USER_NOTIFICATION_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.UPDATE_USER_NOTIFICATION_FAILURE, error));
        });
    };
};

export const updateNotificationProperty = (requestParams) => {
    return {
        type: notificationActions.UPDATE_NOTIFICATION_PPROPERTY,
        responseData: requestParams
    };
};

export const updateConversationList = (users, data, type, conversationType) => {
    return {
        type: notificationActions.UPDATE_CONVERSATION_LIST,
        responseData: {
            users: users,
            data: data,
            type: type,
            conversationType: conversationType
        }
    };
};

export const getNotificationMetrics = () => {
    const endpoint = `${appConstants.END_POINTS.notification.getNotificationMetrics}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_USER_NOTIFICATION_METRICS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.GET_USER_NOTIFICATION_METRICS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_USER_NOTIFICATION_METRICS_FAILURE, error));
        });
    };
};

export const createConversation = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.notification.createConversation}`;
    return (dispatch) => {
        dispatch(request(notificationActions.CREATE_CONVERSATION_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(notificationActions.CREATE_CONVERSATION_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.CREATE_CONVERSATION_FAILURE, error));
        });
    };
};


export const getConversationBySource = (datasourceId, datasetId, type) => {
    const endpoint = `${appConstants.END_POINTS.notification.getConversationBySource}/${datasourceId}/${datasetId}/${type}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_CONVERSATION_BY_SOURCE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.GET_CONVERSATION_BY_SOURCE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_CONVERSATION_BY_SOURCE_FAILURE, error));
        });
    };
};


export const groupListConversation = (conversations, users, userId, type) => {
    const conversationList = [];
    const messages = conversations.map((message) => {
        const senderUser = users.find((user) => user.id === message.sender_id);
        const receiveUser = users.find((user) => user.id === message.receiver_id);
        return {
            ...message,
            senderUser: senderUser,
            receiveUser: receiveUser
        };
    });
    const userListIds = messages.map((data) => { return { sourceId: data.source_id, datasetId: data.dataset_id, userId: data.sender_id === userId ? data.receiver_id : data.sender_id, sourceName: data.source__name, datasetName: data.dataset__name, domainId: data.domain_id, domainName: data.domain__name, type: data.domain_id ? "Domain" : "Source" }; });
    for (const list of userListIds) {
        const index = conversationList.findIndex((conversation) => conversation.sourceId === list.sourceId && conversation.datasetId === list.datasetId && conversation.id === list.userId && conversation.domainId === list.domainId);
        if (index === -1) {
            const userData = users.find((user) => user.id === list.userId);
            const data = messages.filter((message) => message.source_id === list.sourceId && message.dataset_id === list.datasetId && message.domain_id === list.domainId && (message.receiver_id === list.userId || message.sender_id === list.userId));

            conversationList.push({
                ...userData,
                sourceId: list.sourceId,
                datasetId: list.datasetId,
                domainId: list.domainId,
                sourceName: list.sourceName,
                datasetName: list.datasetName,
                domainName: list.domainName,
                messages: data,
                type: list.type
            });
        }
    }
    if (type === "conversation") {
        scrollConversation();
    }
    return {
        type: notificationActions.GROUP_CONVERSATION_LIST,
        responseData: { data: JSON.parse(JSON.stringify(conversationList)), type: type }
    };
};

export const selectConversationItem = (conversation) => {
    scrollConversation();
    return {
        type: notificationActions.SELECT_CONVERSATION,
        responseData: conversation
    };
};

export const mergeConversation = (conversation, conversations) => {
    const index = conversations.findIndex((item) => item.id === conversation.id);
    conversations[index] = { ...conversation };
    return {
        type: notificationActions.MERGE_CONVERSATION_LIST,
        responseData: conversations
    };
};

export const getAllConversation = () => {
    return (dispatch) => {
        dispatch(request(notificationActions.GET_ALL_CONVERSATION_BY_SOURCE_REQUEST));
        return service.getRequest(appConstants.END_POINTS.notification.getAllConversation).then((response) => {
            dispatch(success(notificationActions.GET_ALL_CONVERSATION_BY_SOURCE_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_ALL_CONVERSATION_BY_SOURCE_FAILURE, error));
        });
    };
};

export const updateNotificationRead = (requestParams) => {
    return (dispatch) => {
        dispatch(request(notificationActions.UPDATE_CONVERSATION_READ_REQUEST));
        return service.putRequest(appConstants.END_POINTS.notification.updateConversation, requestParams).then((response) => {
            dispatch(success(notificationActions.UPDATE_CONVERSATION_READ_SUCCESS, requestParams));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.UPDATE_CONVERSATION_READ_FAILURE, error));
        });
    };
};

export const getConversationAnalysis = () => {
    const endpoint = `${appConstants.END_POINTS.notification.getConversationAnalysis}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_ALL_CONVERSATION_METRICS_ANALYSIS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.GET_ALL_CONVERSATION_METRICS_ANALYSIS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_ALL_CONVERSATION_METRICS_ANALYSIS_FAILURE, error));
        });
    };
};

export const getConversationCount = (datasourceId, datasetId) => {
    const endpoint = `${appConstants.END_POINTS.notification.getConversationCount}/${datasourceId}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_CONVERSATION_COUNT_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.GET_CONVERSATION_COUNT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_CONVERSATION_COUNT_FAILURE, error));
        });
    };
};


export const readNotification = () => {
    return (dispatch) => {
        dispatch(request(notificationActions.READ_NOTIFICATION_REQUEST));
        return service.getRequest(appConstants.END_POINTS.notification.readNotification).then((response) => {
            dispatch(success(notificationActions.READ_NOTIFICATION_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.READ_NOTIFICATION_FAILURE, error));
        });
    };
};

export const getNotificationCount = () => {
    return (dispatch) => {
        dispatch(request(notificationActions.GET_NOTIFICATION_COUNT_REQUEST));
        return service.getRequest(appConstants.END_POINTS.notification.getNotificationCount).then((response) => {
            dispatch(success(notificationActions.GET_NOTIFICATION_COUNT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_NOTIFICATION_COUNT_FAILURE, error));
        });
    };
};


export const getDomainActionItemCount = (domainId) => {
    const endpoint = `${appConstants.END_POINTS.notification.getDomainActionItemCount}/${domainId}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_ACTION_ITEM_COUNT_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.GET_ACTION_ITEM_COUNT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_ACTION_ITEM_COUNT_FAILURE, error));
        });
    };
};

export const navigatePage = (navigationPath) => {
    return {
        type: notificationActions.NAVIGATE,
        responseData: navigationPath
    };
};

export const getActionItemDetail = (actionId) => {
    const endpoint = `${appConstants.END_POINTS.notification.getActionItemDetail}/${actionId}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_ACTION_ITEM_DETAIL_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.GET_ACTION_ITEM_DETAIL_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_ACTION_ITEM_DETAIL_FAILURE, error));
        });
    };
};

export const getNotificationMetricAnalysis = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.notification.getNotificationMetricAnalysis}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_NOTIFICATION_METRIC_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(notificationActions.GET_NOTIFICATION_METRIC_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_NOTIFICATION_METRIC_FAILURE, error));
        });
    };
};


export const getComments = (datasourceId, datasetId, itemId, attributeId) => {
    const endpoint = `${appConstants.END_POINTS.notification.getComments}/${datasourceId}/${datasetId}/${itemId}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_COMMENTS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.GET_COMMENTS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_COMMENTS_FAILURE, error));
        });
    };
};

export const createComment = (requestParams) => {
    const endpoint = `${appConstants.END_POINTS.notification.createComment}`;
    return (dispatch) => {
        dispatch(request(notificationActions.CREATE_COMMENTS_REQUEST));
        return service.postRequest(endpoint, requestParams).then((response) => {
            dispatch(success(notificationActions.CREATE_COMMENTS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.CREATE_COMMENTS_FAILURE, error));
        });
    };
};

export const getCommentCount = (datasourceId, datasetId, itemId, attributeId) => {
    const endpoint = `${appConstants.END_POINTS.notification.getCommentCount}/${datasourceId}/${datasetId}/${itemId}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_COMMENT_COUNT_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.GET_COMMENT_COUNT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_COMMENT_COUNT_FAILURE, error));
        });
    };
};

export const deleteComment = (commentId) => {
    const endpoint = `${appConstants.END_POINTS.notification.deleteComment}/${commentId}`;
    return (dispatch) => {
        dispatch(request(notificationActions.DELETE_COMMENT_REQUEST));
        return service.deleteRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.DELETE_COMMENT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.DELETE_COMMENT_FAILURE, error));
        });
    };
};


export const updateComment = (commentId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.notification.updateComment}/${commentId}`;
    return (dispatch) => {
        dispatch(request(notificationActions.UPDATE_COMMENT_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(notificationActions.UPDATE_COMMENT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.UPDATE_COMMENT_FAILURE, error));
        });
    };
};

export const switchCatalog = (value) => {
    return {
        type: notificationActions.SWITCH_CATALOG,
        responseData: value
    };
};

export const clearNotification = () => {
    return {
        type: notificationActions.CLEAR_NOTIFICATIONS
    };
};

export const setFilters = (filters) => {
    return {
        type: notificationActions.SET_NOTIFICATION_FILTER,
        filters
    };
};

export const getNotificationRules = () => {
    const endpoint = `${appConstants.END_POINTS.notification.getNotificationRules}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_NOTIFICATION_RULES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.GET_NOTIFICATION_RULES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_NOTIFICATION_RULES_FAILURE, error));
        });
    };
};


export const setDriftFilters = (filters) => {
    return {
        type: notificationActions.SET_DRIFT_FILTER,
        filters
    };
};


export const getDriftReport = (requestParams, cancellationToken = null) => {
    const endpoint = `${appConstants.END_POINTS.notification.getDriftReport}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_DRIFT_REPORT_REQUEST));
        return service.postRequest(endpoint, requestParams, false, cancellationToken).then((response) => {
            dispatch(success(notificationActions.GET_DRIFT_REPORT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_DRIFT_REPORT_FAILURE, error));
        });
    };
};

export const getDriftRules = () => {
    const endpoint = `${appConstants.END_POINTS.notification.getDriftRules}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_DRIFT_RULES_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(notificationActions.GET_DRIFT_RULES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_DRIFT_RULES_FAILURE, error));
        });
    };
};

export const toggleGroupReportView = (value) => {
    return {
        type: notificationActions.TOGGLE_GROUP_DRIFT_REPORT_VIEW,
        value
    };
};

export const getGroupedDriftReport = (requestParams, cancellationToken = null) => {
    const endpoint = `${appConstants.END_POINTS.notification.getGroupedDriftReport}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_GROUP_DRIFT_REPORT_REQUEST));
        return service.postRequest(endpoint, requestParams, false, cancellationToken).then((response) => {
            dispatch(success(notificationActions.GET_GROUP_DRIFT_REPORT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_GROUP_DRIFT_REPORT_FAILURE, error));
        });
    };
};

export const getGroupByAttributes = (requestParams, cancellationToken = null) => {
    const endpoint = `${appConstants.END_POINTS.notification.getGroupByAttributes}`;
    return (dispatch) => {
        dispatch(request(notificationActions.GET_GROUPBY_ATTRIBUTES_REQUEST));
        return service.postRequest(endpoint, requestParams, false, cancellationToken).then((response) => {
            dispatch(success(notificationActions.GET_GROUPBY_ATTRIBUTES_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(notificationActions.GET_GROUPBY_ATTRIBUTES_FAILURE, error));
        });
    };
};