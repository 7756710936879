import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Table, TableHead, TableBody, TableRow, TableCell, Typography, withStyles, Card, IconButton, Popover, Box, TableSortLabel, MenuItem } from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import ReplayIcon from '@material-ui/icons/Replay';

import { appConstants } from '../../constants/appConstants';
import { getDatasetExecutionLogs, getDatasourceExecutionLogs, restartSchedule, killJob, downloadExportRules } from '../../actions/scheduleActions';
import Styles from '../../layouts/Styles.jsx';
import { getDuration } from '../../helpers/appHelpers';
import DatasourceStyles from './DatasourceStyles.jsx';
import Loader from '../Loaders/Loader.jsx';
import ExecutionLogQueryFilter from '../ExecutionLogQueryFilter/ExecutionLogQueryFilter.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import ExecutionLogDetails from './ExecutionLogDetails.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import { Link } from 'react-router-dom';


const ExecutionLogs = (props) => {
    const { classes, datasourceId, datasetId, theme, logType, isEditable, datasource, connectorType } = props;
    const limit = 30;
    const [executionLogs, setExecutionLogs] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [jobDetails, setJobDetails] = useState({});
    const [error, setError] = useState('');
    const [anchorElement, setAnchorElement] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [statusFilter, setStatusFilter] = useState('All');
    const [selectExecutionLog, setSelectedExecutionLog] = useState({ isOpen: false });
    const [orderBy, setOrderBy] = useState('start_time');
    const [order, setOrder] = useState('desc');
    const [detailAnchorEl, setDetailAnchorEl] = useState(null);
    const dispatch = useDispatch();

    const onChangeOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        getExecutionLogs(true, statusFilter, true, property, isAsc ? 'desc' : 'asc');
    }, [order, orderBy]);

    const loadExecutionLogs = useCallback((response, reset) => {
        const datetimeFormat = 'MM/DD/YYYY HH:mm:ss';
        let logs = response?.execution_logs ?? [];
        const count = response?.count_data?.total ?? 0;
        const count_data = response?.count_data ? response?.count_data : {};
        delete count_data.total;
        for (const executionLog of logs) {
            executionLog['start_time'] = executionLog['start_time'] ? moment(executionLog['start_time']).format(datetimeFormat) : executionLog.comment;
            if (executionLog['end_time']) {
                executionLog['end_time'] = moment(executionLog['end_time']).format(datetimeFormat);
            }
        }
        if (!reset) {
            logs = logs ? [...executionLogs, ...logs] : [...executionLogs];
        }
        setExecutionLogs(logs);
        setTotalCount(count);
        setJobDetails(count_data ?? {});
    }, [executionLogs]);

    const showError = (event, error) => {
        if (!error) {
            return;
        }
        setError(error);
        setAnchorElement(event.currentTarget);
    };

    const closeError = () => {
        setError('');
        setAnchorElement(null);
    };

    const getExecutionLogs = useCallback((reset = false, filter = statusFilter, sort = false, order_by = orderBy, order_type = order) => {
        setIsInitialLoad(false);
        const offset = !reset ? executionLogs.length : 0;
        if (offset === 0 || offset < totalCount) {
            if (logType === 'dataset') {
                if (!datasetId) {
                    return;
                }
                dispatch(getDatasetExecutionLogs(datasetId, filter, offset, limit, order_by, order_type)).then((response) => {
                    setIsLoading(false);
                    if (response) {
                        loadExecutionLogs(response, reset || sort);
                    }
                });
            } else {
                if (!datasourceId) {
                    return;
                }
                dispatch(getDatasourceExecutionLogs(datasourceId, filter, offset, limit, order_by, order_type)).then((response) => {
                    setIsLoading(false);
                    if (response) {
                        loadExecutionLogs(response, reset || sort);
                    }
                });
            }
        } else {
            setIsLoading(false);
        }
    }, [datasetId, datasourceId, dispatch, executionLogs, loadExecutionLogs, logType, statusFilter, totalCount]);

    const reloadExecution = () => {
        setIsLoading(true);
        getExecutionLogs(true);
    };

    const onFilterChange = useCallback((status) => {
        setStatusFilter(status);
        getExecutionLogs(true, status);
    }, [getExecutionLogs]);

    useEffect(() => {
        if (isInitialLoad && executionLogs && executionLogs.length <= 0) {
            getExecutionLogs(true);
        }
    }, [datasourceId, datasetId, dispatch, getExecutionLogs, executionLogs, isInitialLoad]);

    const killJobs = useCallback((scheduleId) => {
        dispatch(killJob(scheduleId)).then(() => {
            getExecutionLogs();
        });
    }, [dispatch, getExecutionLogs]);

    const onSelectExecutionLog = (log) => {
        setSelectedExecutionLog({
            datasetId: log.dataset_id,
            scheduleLogId: log.schedule_id,
            type: log.schedule_type,
            isOpen: true
        });
    };

    const onExportDownload = (log) => {
        const requestParams = {
            dataset_id: log.dataset_id,
            schedule_id: log.schedule_id
        };
        dispatch(downloadExportRules(requestParams)).then((response) => {
            const endpoint = appConstants.API_BASE_URL.replace('api', '');
            if (response) {
                if (response.inbound === 'hadoop') {
                    window.open(`${response.file_location}`, 'Download');
                    // window.open(``, '_blank');
                } else {
                    // window.location.href = `${endpoint}${response.file_location}`;
                    window.open(`${endpoint}${response.file_location}`);
                }
            }
        });
    };

    const onCloseLog = () => {
        setSelectedExecutionLog({ isOpen: false });
    };

    const onScroll = useCallback((event) => {
        if ((event.target.scrollTop + event.target.offsetHeight + 55) >= event.target.scrollHeight && !isLoading) {
            setIsLoading(true);
            getExecutionLogs();
        }
    }, [getExecutionLogs, isLoading]);

    const open = Boolean(anchorElement);
    return (
        <Grid className={classes.executionLogContainer} onScroll={onScroll}>
            <Grid container justify="space-between" alignItems="center" className={classes.executionTitle}>
                <Typography component="h5" variant="h5">
                    {'Execution Logs'}
                </Typography>
                <Grid item>
                    <TextBox
                        name="statusFilter"
                        select
                        onChange={(event) => onFilterChange(event.target.value)}
                        value={statusFilter ? statusFilter : 'All'}
                        className={classes.inlineTxt}
                        SelectProps={
                            {
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "center"
                                    },
                                    getContentAnchorEl: null
                                }
                                // IconComponent: () => DropDownIcon()
                            }
                        }
                    >
                        {
                            appConstants.executionLogStatusFilter.map((option, index) => (
                                <MenuItem key={`executionLogStatusFilter_optins_${index}`} value={option} className={classes.menuItem}>
                                    {option}
                                </MenuItem>
                            ))
                        }
                    </TextBox>
                    <ToolTipComponent title="Reload" arrow>
                        <IconButton className={classNames(classes.reloadIcon, classes.nopadding)} onClick={() => reloadExecution()}>
                            <ReplayIcon />
                        </IconButton>
                    </ToolTipComponent>
                    <ToolTipComponent title="Job Status" arrow>
                        <IconButton className={classNames(classes.marginLeft5, classes.nopadding, classes.infoIcon)} onClick={(event) => setDetailAnchorEl(event.currentTarget)}>
                            <svg id="information" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 20 20">
                                <path id="Path_15040" data-name="Path 15040" d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,18.182A8.182,8.182,0,1,1,18.182,10,8.191,8.191,0,0,1,10,18.182Z" fill={theme.palette.secondary.main} />
                                <path id="Path_15041" data-name="Path 15041" d="M146.214,70a1.212,1.212,0,1,0,1.212,1.213A1.214,1.214,0,0,0,146.214,70Z" transform="translate(-136.214 -65.758)" fill={theme.palette.secondary.main} />
                                <path id="Path_15042" data-name="Path 15042" d="M150.909,140a.909.909,0,0,0-.909.909v5.455a.909.909,0,1,0,1.818,0v-5.455A.909.909,0,0,0,150.909,140Z" transform="translate(-140.909 -131.515)" fill={theme.palette.secondary.main} />
                            </svg>
                        </IconButton>
                    </ToolTipComponent>
                </Grid>
            </Grid>
            <Grid container style={{ height: '93%' }}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ height: '100%', overflow: 'auto' }}>
                    <Card className={classNames(classes.executionLogTable, classes.tableWrapperStyle)}>
                        <Table stickyHeader className={classes.tableStyle} >
                            <TableHead>
                                <TableRow>
                                    {
                                        appConstants.executionLogHeaders.map((executionLogHeader, index) =>
                                            <TableCell key={`executionLogHeader${index}`}>
                                                {
                                                    executionLogHeader.header !== 'Actions' ?
                                                        <TableSortLabel
                                                            active={orderBy === executionLogHeader.key}
                                                            direction={orderBy === executionLogHeader.key ? order : 'asc'}
                                                            onClick={() => onChangeOrder(executionLogHeader.key)}
                                                        >
                                                            <Typography variant="body1" className={classes.tableHeader}>
                                                                {executionLogHeader.header}
                                                            </Typography>
                                                        </TableSortLabel>
                                                        :
                                                        <Typography variant="body1" className={classes.tableHeader}>
                                                            {executionLogHeader.header}
                                                        </Typography>
                                                }
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    executionLogs.map((executionLog, index) => {
                                        return (
                                            <TableRow key={`executionLog${index}`}>
                                                {
                                                    appConstants.executionLogHeaders.map((executionLogHeader, headerIndex) =>
                                                        <TableCell key={`executionLog${index}${headerIndex}`}>
                                                            {
                                                                executionLogHeader.key ?
                                                                    <ToolTipComponent title={executionLog[executionLogHeader.key]} arrow placement={"bottom-start"}>
                                                                        {
                                                                            (executionLogHeader.key === "dataset_name" && logType === 'datasource') ?
                                                                                <Link style={{ textDecoration: 'unset', color: '#1D2C2F' }} to={{ pathname: `/dataset/${executionLog.dataset_id}`, state: { datasource: datasource } }} >
                                                                                    <Typography variant="body2" noWrap>
                                                                                        <span className={(executionLogHeader.key === "dataset_name" && logType === 'datasource') ? classes.anchorLink : null}>
                                                                                            {executionLog[executionLogHeader.key]}{(executionLog[executionLogHeader.key] === "Monitoring") ? ` (${executionLog['monitoringname']})` : ""}
                                                                                        </span>
                                                                                    </Typography>
                                                                                </Link>
                                                                                :
                                                                                <Typography variant="body2" noWrap>
                                                                                    {
                                                                                        executionLogHeader.key === 'duration' ?
                                                                                            <span className={(executionLogHeader.key === "dataset_name" && logType === 'datasource') ? classes.anchorLink : null}>
                                                                                                {getDuration(executionLog[executionLogHeader.key])}
                                                                                            </span>
                                                                                            :
                                                                                            <span className={(executionLogHeader.key === "dataset_name" && logType === 'datasource') ? classes.anchorLink : null}>
                                                                                                {executionLog[executionLogHeader.key]}{(executionLog[executionLogHeader.key] === "Monitoring") ? ` (${executionLog['monitoringname']})` : ""}
                                                                                            </span>
                                                                                    }
                                                                                </Typography>
                                                                        }

                                                                    </ToolTipComponent>
                                                                    :
                                                                    <Grid>
                                                                        {
                                                                            isEditable && (executionLog && executionLog.status !== 'Started') &&
                                                                            <ToolTipComponent title="Run" arrow>
                                                                                <IconButton className={classes.executionLogActions} onClick={() => dispatch(restartSchedule(executionLog.schedule_id))}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18.174" height="18.174" viewBox="0 0 18.174 18.174">
                                                                                        <g id="ui" transform="translate(-1 -1)">
                                                                                            <path id="Path_893" data-name="Path 893" d="M10.087,1a9.087,9.087,0,1,0,9.087,9.087A9.087,9.087,0,0,0,10.087,1Zm0,16.522a7.435,7.435,0,1,1,7.435-7.435A7.435,7.435,0,0,1,10.087,17.522Z" transform="translate(0 0)" fill={theme.palette.primary.main} />
                                                                                            <path id="Path_894" data-name="Path 894" d="M15.873,10.256,9.264,6.126A.826.826,0,0,0,8,6.826v8.261a.826.826,0,0,0,1.264.7l6.609-4.131a.826.826,0,0,0,0-1.4ZM9.652,13.6V8.317l4.224,2.639Z" transform="translate(-1.217 -0.869)" fill={theme.palette.primary.main} />
                                                                                        </g>
                                                                                    </svg>
                                                                                </IconButton>
                                                                            </ToolTipComponent>
                                                                        }
                                                                        {
                                                                            (executionLog && executionLog.status === 'Completed' && (executionLog.schedule_type === "Curate" || executionLog.schedule_type === "Enrich")) &&
                                                                            <ToolTipComponent title="Data Preview" arrow>
                                                                                <IconButton className={classes.executionLogActions} onClick={() => onSelectExecutionLog(executionLog)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 32 32" width="18">
                                                                                        <g>
                                                                                            <path fill={theme.palette.primary.main} d="m27.7 8.3-6-6c-.2-.2-.3-.3-.7-.3h-16c-.6 0-1 .4-1 1v26c0 .6.4 1 1 1h22c.6 0 1-.4 1-1v-20c0-.3-.1-.5-.3-.7zm-3.1-.3h-2.6v-2.6zm-18.6 20v-24h14v5c0 .6.4 1 1 1h5v18z" />
                                                                                            <path fill={theme.palette.primary.main} d="m19.2 11.7c-2.3-2.3-6.1-2.3-8.5 0s-2.3 6.1 0 8.5c2.1 2.1 5.4 2.3 7.7.6l3 3c.4.4 1 .4 1.4 0s.4-1 0-1.4l-3-3c1.7-2.3 1.5-5.6-.6-7.7zm-1.5 7.1c-1.6 1.6-4.1 1.6-5.7 0s-1.6-4.1 0-5.7 4.1-1.6 5.7 0 1.6 4.1 0 5.7z" />
                                                                                        </g>
                                                                                    </svg>
                                                                                </IconButton>
                                                                            </ToolTipComponent>
                                                                        }
                                                                        {
                                                                            (executionLog && executionLog.status === 'Completed') && executionLog.schedule_type === "ExportMetrics" &&
                                                                            <ToolTipComponent title="Download" arrow>
                                                                                <IconButton className={classes.executionLogActions} onClick={() => onExportDownload(executionLog)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 21 21">
                                                                                        <g id="download_4_" data-name="download (4)" transform="translate(-1.5 -1.5)">
                                                                                            <path id="Path_14400" data-name="Path 14400" d="M22,23.714v2.857A1.43,1.43,0,0,1,20.571,28H3.428A1.43,1.43,0,0,1,2,26.572V23.714a.714.714,0,0,1,1.428,0v2.857H20.571V23.714a.714.714,0,0,1,1.429,0Z" transform="translate(0 -6)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                                                                            <path id="Path_14401" data-name="Path 14401" d="M8.21,12.5a.714.714,0,1,1,1.01-1.01L13,15.275V2.714a.714.714,0,1,1,1.428,0V15.275l3.781-3.78a.714.714,0,1,1,1.01,1.01l-5,5a.715.715,0,0,1-1.011,0Z" transform="translate(-1.715)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                                                                        </g>
                                                                                    </svg>
                                                                                </IconButton>
                                                                            </ToolTipComponent>
                                                                        }
                                                                        {
                                                                            isEditable && (executionLog && executionLog.status === 'Started') &&
                                                                            <ToolTipComponent title="Kill" arrow>
                                                                                <IconButton className={classes.executionLogActions} onClick={() => killJobs(executionLog.schedule_id)}>
                                                                                    <HighlightOff color="primary" />
                                                                                </IconButton>
                                                                            </ToolTipComponent>
                                                                        }
                                                                        {
                                                                            (executionLog.status === 'Failed' || executionLog.status === 'Skipped') &&
                                                                            <ToolTipComponent title="View Error Log" arrow>
                                                                                <IconButton color={'primary'}
                                                                                    aria-owns={open ? 'view-error-popover' : undefined}
                                                                                    aria-describedby={open ? 'view-error-popover' : undefined}
                                                                                    aria-haspopup="true"
                                                                                    className={classes.executionLogActions}
                                                                                    // eslint-disable-next-line no-nested-ternary
                                                                                    onClick={(event) => showError(event, executionLog.status === 'Skipped' && !executionLog.error ? "Previous Jobs Running More Time So This Schedule Is Skiped" : executionLog.error === 'Rerun' ? "" : executionLog.error)}>
                                                                                    <svg id="information" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                                                                                        <path id="Path_15040" data-name="Path 15040" d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,18.182A8.182,8.182,0,1,1,18.182,10,8.191,8.191,0,0,1,10,18.182Z" fill={theme.palette.primary.main} />
                                                                                        <path id="Path_15041" data-name="Path 15041" d="M146.214,70a1.212,1.212,0,1,0,1.212,1.213A1.214,1.214,0,0,0,146.214,70Z" transform="translate(-136.214 -65.758)" fill={theme.palette.primary.main} />
                                                                                        <path id="Path_15042" data-name="Path 15042" d="M150.909,140a.909.909,0,0,0-.909.909v5.455a.909.909,0,1,0,1.818,0v-5.455A.909.909,0,0,0,150.909,140Z" transform="translate(-140.909 -131.515)" fill={theme.palette.primary.main} />
                                                                                    </svg>
                                                                                </IconButton>
                                                                            </ToolTipComponent>
                                                                        }
                                                                        {
                                                                            (executionLog.status === 'Killed' && executionLog.error && (executionLog.error.includes("Job killed as it passed the maximum time limit set up") || executionLog.error.includes("The job does not have a active livy session.") || executionLog.error.includes("Job Submission Failed."))) &&
                                                                            <ToolTipComponent title="View Error Log" arrow>
                                                                                <IconButton color={'primary'}
                                                                                    aria-owns={open ? 'view-error-popover' : undefined}
                                                                                    aria-describedby={open ? 'view-error-popover' : undefined}
                                                                                    aria-haspopup="true"
                                                                                    className={classes.executionLogActions}
                                                                                    // eslint-disable-next-line no-nested-ternary
                                                                                    onClick={(event) => showError(event, executionLog.status === 'Skipped' && !executionLog.error ? "Previous Jobs Running More Time So This Schedule Is Skiped" : executionLog.error === 'Rerun' ? "" : executionLog.error)}>
                                                                                    <svg id="information" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20">
                                                                                        <path id="Path_15040" data-name="Path 15040" d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0Zm0,18.182A8.182,8.182,0,1,1,18.182,10,8.191,8.191,0,0,1,10,18.182Z" fill={theme.palette.primary.main} />
                                                                                        <path id="Path_15041" data-name="Path 15041" d="M146.214,70a1.212,1.212,0,1,0,1.212,1.213A1.214,1.214,0,0,0,146.214,70Z" transform="translate(-136.214 -65.758)" fill={theme.palette.primary.main} />
                                                                                        <path id="Path_15042" data-name="Path 15042" d="M150.909,140a.909.909,0,0,0-.909.909v5.455a.909.909,0,1,0,1.818,0v-5.455A.909.909,0,0,0,150.909,140Z" transform="translate(-140.909 -131.515)" fill={theme.palette.primary.main} />
                                                                                    </svg>
                                                                                </IconButton>
                                                                            </ToolTipComponent>
                                                                        }
                                                                    </Grid>
                                                            }
                                                        </TableCell>
                                                    )
                                                }
                                            </TableRow>
                                        );
                                    })
                                }
                            </TableBody>
                        </Table>
                        {
                            (executionLogs.length === 0) && !isLoading &&
                            <NoResultFound />
                        }
                    </Card>
                    {isLoading && <Loader />}
                </Grid>
            </Grid>
            <Popover id="view-error-popover"
                open={open}
                anchorEl={anchorElement}
                classes={
                    {
                        paper: classes.errorLogContainer
                    }
                }
                anchorOrigin={
                    {
                        vertical: 'bottom',
                        horizontal: 'left'
                    }
                }
                transformOrigin={
                    {
                        vertical: 'top',
                        horizontal: 'left'
                    }
                }
                onClose={() => closeError()}>
                <Box p={2} className={classes.errorContainer}>
                    <Grid container justify="space-between" alignItems="center" spacing={2}>
                        <Grid item>
                            <Typography component="h5" variant="h5">
                                Error Log
                            </Typography>
                        </Grid>
                        <Grid item>
                            <IconButton className={classes.padding6} onClick={() => closeError()}>
                                <CloseIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Typography className={classes.marginTop15}>
                        {error}
                    </Typography>
                </Box>
            </Popover>
            {
                selectExecutionLog.isOpen &&
                <ExecutionLogQueryFilter
                    selectedExecutionLog={selectExecutionLog}
                    onCloseLog={() => onCloseLog()} />
            }
            {
                detailAnchorEl &&
                <ExecutionLogDetails
                    jobDetails={jobDetails ? jobDetails : {}}
                    onClose={() => setDetailAnchorEl(null)}
                    anchorEl={detailAnchorEl}
                    connector_type={connectorType ? connectorType : datasource.type}
                    sourceId={datasourceId}
                    datasetId={datasetId}
                />
            }
        </Grid>
    );
};

ExecutionLogs.propTypes = {
    classes: PropTypes.object,
    datasourceId: PropTypes.number,
    datasetId: PropTypes.number,
    theme: PropTypes.object,
    logType: PropTypes.string,
    isEditable: PropTypes.bool,
    datasource: PropTypes.object,
    connectorType: PropTypes.string
};

export default withStyles((theme) => ({
    ...DatasourceStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ExecutionLogs);