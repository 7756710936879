import React, { Component } from 'react';
import { Grid, withStyles, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tags from '../Tags/Tags.jsx';
import DomainSelect from '../DomainSelect/DomainSelect.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import DatasourceStyles from './DatasourceStyles.jsx';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';

class DatasourceDetail extends Component {

    constructor(props) {
        super(props);
        this.state = { };
    }

    updateTags = (value, type, index, propertyType) => {
        let tags = [];
        if (this.props.type === "dataset") {
            tags = this.props.dataset.tags ? this.props.dataset.tags : [];
        } else {
            tags = this.props.source.tags ? this.props.source.tags : [];
        }

        value = value.trim();
        if (type === "add") {
            const tagExist = tags.some((tag) => tag.toLowerCase() === value.toLowerCase());
            if (!tagExist) { tags.push(value); }
            this.props.onChange('updateTags', tags);
        } else {
            if (index === -1) { tags.splice(tags.length - 1, 1); }
            else { tags.splice(index, 1); }
            this.props.onChange('updateTags', tags);
        }
    }

    updateTagItem = () => {
        this.props.onSave('tags');
    }

    getAttributeList = () => {
        return this.props.properties ? this.props.properties : [];
    }

    onHandleChange = (propertyName, value, event) => {
        this.props.updateDuplicate(value);
    }

    onDelete = (value, index, event) => {
        const values = [...this.props.duplicateValues];
        values.splice(index, 1);
        this.props.updateDuplicate(values);
        this.props.onSaveDuplicateCloumn(values);
    }

    onSaveDuplicateColumn = () => {
        this.props.onSaveDuplicateCloumn(this.props.duplicateValues);
    }

    render() {
        const { classes, source, dataset, onChange, onSave, isEditable, onSelectDomain, type, domain, createNewDomain, isSkipDuplicate } = this.props;
        const details = (type === 'dataset' && dataset) ? dataset : source;
        return (
            <Grid container className={classNames(classes.card, classes.overViewcard)} alignItems="baseline">
                <Grid item xs={8}>
                    <Typography className={classNames(classes.marginBottom5)} component="h5" variant="h5" >
                        Description
                    </Typography>
                    <TextBox
                        name="description"
                        placeholder="Enter Description"
                        multiline
                        value={details.description ? details.description : ''}
                        rows="3"
                        onChange={(event) => onChange(event.target.name, event.target.value)}
                        onBlur={(event) => onSave('description', event.target.value)}
                        fullWidth
                        className={classNames(classes.inlinetxt, classes.descriptionTextBox)}
                        disabled={!isEditable}
                    />
                </Grid>
                {
                    this.props.isDomainEnabled &&
                    <Grid item xs={4} className={classes.pl15}>
                        <Typography component="h5" variant="h5" className={classNames(classes.marginBottom5)}>
                            Domain
                        </Typography>
                        <DomainSelect
                            placeholder="Domain"
                            onBlur={(value) => onSave('createDomain', value)}
                            onSelectDomain={(domain) => onSelectDomain(domain)}
                            displayValue="name"
                            availableList={domain}
                            isEditable={!isEditable}
                            onChange={onChange}
                            details={details}
                            createNewDomain={createNewDomain}
                        />
                    </Grid>
                }
                <Grid item xs={8}>
                    <Typography component="h5" variant="h5" className={classNames(classes.marginTop5, classes.marginBottom5)}>
                        Tags
                    </Typography>
                    <Tags type="tags" focus tags={details.tags ? details.tags : []} updateTags={this.updateTags} disabled={!isEditable} updateTagItem={this.updateTagItem} />
                </Grid>
                {
                    type === "dataset" && isSkipDuplicate &&
                    <Grid item xs={4}>
                        <AutoCompleteInput
                            name="attributes"
                            popperWidth={300}
                            options={this.getAttributeList()}
                            freeSolo
                            value={this.props.duplicateValues}
                            getOptionLabel={
                                (option) => {
                                    if (option && option.name) {
                                        return option.name;
                                    }
                                    return option;
                                }
                            }
                            getOptionSelected={
                                (option, value) => {
                                    if (option && option.name && value && value.name) {
                                        return option.name === value.name;
                                    }
                                    return option === value;
                                }
                            }
                            renderInput={
                                (params) =>
                                    <TextField {...params}
                                        className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                        placeholder="Duplicate Column Attributes"
                                        margin="normal"
                                    />
                            }
                            onChange={(_, selectedValue) => this.onHandleChange("custom_rules", selectedValue)}
                            onDeleteChip ={(value, index, event) => this.onDelete(value, index, event)}
                            onBlur={() => this.onSaveDuplicateColumn()}
                            openOnFocus
                            disableCloseOnSelect
                            disableClearable
                            fullWidth
                            multiple
                            forcePopupIcon={false}
                            noOptionsText={"No Attributes Found"}
                        />
                    </Grid>
                }
            </Grid>
        );
    }
}

DatasourceDetail.propTypes = {
    classes: PropTypes.object,
    source: PropTypes.object,
    dataset: PropTypes.object,
    duplicateValues: PropTypes.object,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    isEditable: PropTypes.bool,
    onSelectDomain: PropTypes.func,
    type: PropTypes.string,
    domain: PropTypes.array,
    onSaveDuplicateCloumn: PropTypes.func,
    updateDuplicate: PropTypes.func,
    properties: PropTypes.object,
    createNewDomain: PropTypes.bool,
    isDomainEnabled: PropTypes.bool,
    isSkipDuplicate: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DatasourceStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DatasourceDetail);