import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Table, TableRow, TableHead, TableCell, Typography, TableBody, Chip } from '@material-ui/core';
import PropertiesListViewStyles from './PropertiesListViewStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';
import DatasetStyles from '../../DatasetStyles.jsx';
import TileViewStyles from '../TileView/TileViewStyles.jsx';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { deleteLearning } from '../../../../actions/scheduleActions';
import { useDispatch } from 'react-redux';
import ToolTipComponent from '../../../Tooltip/Tooltip.jsx';


const LearningPanel = (props) => {
    const { classes, properties, datasetId, isDeletePermission, isEditable } = props;
    const dispatch = useDispatch();

    const removeLearning = (attribute, type, index) => {
        if (type === "accept") {
            properties[attribute].curate_learning.accept.splice(index, 1);
        } else {
            properties[attribute].curate_learning.decline.splice(index, 1);
        }
        const model = {
            attribute: attribute,
            type: type,
            property: type === "accept" ? properties[attribute].curate_learning.accept : properties[attribute].curate_learning.decline
        };
        dispatch(deleteLearning(datasetId, model));
    };
    return (
        <Grid container direction="column">
            <Table stickyHeader className={classes.propertyListPageTable}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Attribute'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Accept'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Decline'}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.propListPageTableBody}>
                    {
                        Object.keys(properties).map((attribute, index) => {
                            const property = properties[attribute];
                            return (
                                <TableRow key={`attributeProperty${index}`}>
                                    <TableCell>
                                        <ToolTipComponent title={property && property.name ? property.name : ''} arrow placement="bottom-start">
                                            <Typography>
                                                {property && property.name ? property.name : ''}
                                            </Typography>
                                        </ToolTipComponent>
                                    </TableCell>
                                    <TableCell>
                                        {
                                            property.curate_learning && property.curate_learning.accept && property.curate_learning.accept.map((learning, index) =>
                                                <ToolTipComponent key={`accept-learning-${index}`} title={`${learning.input_value} - ${learning.curated_value}`} arrow>
                                                    <Chip
                                                        className={classes.learningChip}
                                                        key={`accept-learning-${index}`}
                                                        label={`${learning.input_value} - ${learning.curated_value}`}
                                                        deleteIcon={<ClearRoundedIcon />}
                                                        onDelete={() => removeLearning(attribute, 'accept', index)}
                                                        disabled={!isDeletePermission || !isEditable}
                                                    />
                                                </ToolTipComponent>
                                            )
                                        }
                                    </TableCell>
                                    <TableCell>
                                        {
                                            property.curate_learning && property.curate_learning.decline && property.curate_learning.decline.map((learning, index) =>
                                                <ToolTipComponent key={`decline-learning-${index}`} title={`${learning.input_value} - ${learning.curated_value}`} arrow>
                                                    <Chip
                                                        className={classes.learningChip}
                                                        key={`decline-learning-${index}`}
                                                        label={`${learning.input_value} - ${learning.curated_value}`}
                                                        deleteIcon={<ClearRoundedIcon />}
                                                        onDelete={() => removeLearning(attribute, 'decline', index)}
                                                        disabled={!isDeletePermission || !isEditable}
                                                    />
                                                </ToolTipComponent>
                                            )
                                        }
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </Grid>
    );
};

LearningPanel.propTypes = {
    classes: PropTypes.object,
    properties: PropTypes.object,
    datasetId: PropTypes.string,
    isDeletePermission: PropTypes.bool,
    isEditable: PropTypes.bool
};

export default withStyles((theme) => ({
    ...PropertiesListViewStyles(theme),
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}))(LearningPanel);