const ChipPopoverStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    nomargin: {
        margin: '0px !important'
    },
    removeIcon: {
        top: '-4px !important',
        right: '0 !important'
    },
    chipContainer: {
        marginRight: 5
    },
    listContainer: {
        padding: '8px 0px 8px 16px',
        maxHeight: 250,
        overflow: 'auto',
        minWidth: '160px',
        '& .MuiIconButton-root': {
            padding: 8
        }
    },
    addButton: {
        padding: 4,
        '& svg': {
            height: 14,
            width: 14
        }
    },
    displayItemCount: {
        display: 'inline-flex',
        width: 24,
        height: 24,
        borderRadius: 25,
        backgroundColor: '#f2f6f7',
        border: '1px solid #DAE7ED',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: "pointer"
    },
    clearButton: {
        padding: 6,
        marginLeft: 5,
        '& svg': {
            fontSize: 14
        }
    }
});

export default ChipPopoverStyles;