import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, withStyles, Typography } from '@material-ui/core';

import { deleteLearning } from '../../../../actions/scheduleActions';
import DatasetStyles from '../../DatasetStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';
import TileViewStyles from './TileViewStyles.jsx';
import LearningListItem from './LearningListItem.jsx';
import Localization from '../../../../constants/localization';


const AttributeLearning = (props) => {
    const { classes, attribute, datasetId, updatePropertiesList, isDeletePermission } = props;
    const dispatch = useDispatch();
    const [acceptValues, setAcceptValues] = useState([]);
    const [declineValues, setDeclineValues] = useState([]);

    const deleteCurateLearning = (type, index) => {
        if (type === "accept") {
            acceptValues.splice(index, 1);
            setAcceptValues([...acceptValues]);
        } else {
            declineValues.splice(index, 1);
            setDeclineValues([...declineValues]);
        }
        const property = {
            type: type,
            attribute: attribute.name,
            property: type === "accept" ? acceptValues : declineValues
        };
        updatePropertiesList({ name: attribute.name, value: { "curate_learning": { "accept": acceptValues, "decline": declineValues } } });
        dispatch(deleteLearning(datasetId, property));
    };

    useEffect(() => {
        setAcceptValues(attribute.curate_learning && attribute.curate_learning.accept ? attribute.curate_learning.accept : []);
        setDeclineValues(attribute.curate_learning && attribute.curate_learning.decline ? attribute.curate_learning.decline : []);
    }, [attribute]);

    return (
        <Grid container direction="column" justify="flex-start" alignItems="flex-start" wrap="nowrap" style={{ height: '100%', overflow: 'hidden' }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ flexBasis: 0 }}>
                <Typography variant="body2" className={classes.curateDescritionEllipsis}>
                    {Localization.properties.learning.learning}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: '100%' }} className={classes.learningContainer}>
                <Grid container direction="row" justify="flex-start" wrap="nowrap" spacing={8}>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                            <Typography component="h6" variant="h6">
                                {'Accept'}
                            </Typography>
                        </Grid>
                        <Typography variant="body2" className={classes.curateDescritionEllipsis} style={{ marginBottom: 10 }}>
                            {Localization.properties.learning.accept}
                        </Typography>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" wrap="nowrap" className={classes.learningListContainer}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ width: '100%' }}>
                                {
                                    acceptValues.map((acceptedValue, index) => {
                                        return (
                                            <LearningListItem isDeletePermission={isDeletePermission} key={`accepted_values${index}`} item={acceptedValue} deleteLearning={(index) => deleteCurateLearning('accept', index)} />
                                        );
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                            <Typography component="h6" variant="h6">
                                {'Decline'}
                            </Typography>
                        </Grid>
                        <Typography variant="body2" className={classes.curateDescritionEllipsis} style={{ marginBottom: 10 }}>
                            {Localization.properties.learning.decline}
                        </Typography>
                        <Grid container direction="column" justify="flex-start" alignItems="flex-start" wrap="nowrap" className={classes.learningListContainer}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ width: '100%' }}>
                                {
                                    declineValues.map((declinedValue, index) => {
                                        return (
                                            <LearningListItem isDeletePermission={isDeletePermission} key={`declined_values${index}`} item={declinedValue} deleteLearning={(index) => deleteCurateLearning('decline', index)} />
                                        );
                                    })
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

AttributeLearning.propTypes = {
    classes: PropTypes.object,
    attribute: PropTypes.object,
    datasetId: PropTypes.string,
    updatePropertiesList: PropTypes.func,
    isDeletePermission: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}))(AttributeLearning);