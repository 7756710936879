import React, { Component } from 'react';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import { Grid, Paper, withStyles, Backdrop, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import AttributeSelectStyles from './AttributeSelectStyles.jsx';
import classNames from 'classnames';

const renderInput = (inputProps) => {
    const { InputProps, classes, ref, inputRef, removeClass, textBoxStyles, inputStyles, scheduleRef, ...other } = inputProps;
    return (
        <TextBox ref={inputRef}
            className={classNames(!removeClass ? classes.inlinetxt : '', textBoxStyles)}
            InputProps={
                {
                    inputRef: ref,
                    ref: scheduleRef,
                    classes: {
                        root: classes.inputRoot,
                        input: classNames(classes.inputInput, inputStyles)
                    },
                    ...InputProps
                }
            }
            {...other}
        />
    );
};


const renderSuggestion = (suggestionProps) => {
    const { suggestion, displayMemberPath, index, itemProps, highlightedIndex, hideIcon, classes } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    return (
        <ListItem dataValue={suggestion[displayMemberPath]} selected={isHighlighted} {...itemProps} button className={classNames(classes.list, suggestion.isSelected ? classes.active : '')} key={`attribute_${index}`}>
            <ListItemIcon edge="start">
                {
                    !hideIcon &&
                    <Grid className={classes.typeIcon}>
                        {suggestion.type ? suggestion.type.substring(0, 1) : ''}
                    </Grid>
                }
            </ListItemIcon>
            <ListItemText className={classes.listText} primary={suggestion[displayMemberPath]} />
        </ListItem>
    );
};

const getSuggestions = (value, { options, displayMemberPath, showEmpty = false, openSuggestionsOnClick, isInputChanged } = {}) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    if (openSuggestionsOnClick && !isInputChanged) { return options; }

    const suggestions = inputLength === 0 && !showEmpty
        ? []
        : options.filter((suggestion) => suggestion[displayMemberPath].toLowerCase().includes(inputValue.toLowerCase()));
    return suggestions;
};

class AttributeSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInputChanged: false
        };
        this.inputRef = React.createRef();
    }

    handleChange(selectedItem) {
        if (this.props.blurOnSelect && this.inputRef && this.inputRef.current) {
            this.inputRef.current.blur();
        }
        if (!selectedItem) {
            this.props.onChange({
                target: {
                    value: '',
                    selectedItem: ''
                }
            });
            return;
        }

        const { options, displayMemberPath, selectedValuePath } = this.props;
        const selectedOption = options.find((p) => p[displayMemberPath].toLowerCase() === selectedItem.toLowerCase());
        if (selectedOption) {
            this.props.onChange({
                target: {
                    value: selectedOption[selectedValuePath],
                    selectedItem: selectedOption
                }
            });
        }
    }

    getDropdownPosition = (inputElement) => {
        if (!inputElement) { return; }
        const viewportHeight = window.innerHeight;
        const inputPosition = inputElement.getBoundingClientRect();
        const itemCount = this.props.options && this.props.options.length > 0 ? this.props.options.length : 0;
        const componentPosition = itemCount > 5 ? (inputPosition.bottom + 200) : (inputPosition.bottom + (itemCount * 30));
        const dropdownPosition = (viewportHeight > componentPosition) ? { top: inputPosition.bottom, left: inputPosition.left } : { bottom: viewportHeight - inputPosition.top, left: inputPosition.left };
        return dropdownPosition;
    }

    render() {
        const { classes, id, placeholder, label, name, disabled, displayMemberPath, inputRef,
            options, value, style, removeClass, inputStyles, textBoxStyles, openSuggestionsOnClick, hideIcon } = this.props;
        const { isInputChanged } = this.state;
        return (
            <div className={classes.root}>
                <Downshift selectedItem={value !== '' ? value : null} onChange={(item) => this.handleChange(item)}>
                    {
                        ({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            isOpen,
                            inputValue,
                            openMenu,
                            closeMenu,
                            selectedItem
                        }) => {
                            const { onBlur, onChange, onFocus, onKeyDown, ...inputProps } = getInputProps({
                                onChange: (event) => {
                                    this.setState({ isInputChanged: true });
                                    if (event.target.value === '') {
                                        clearSelection();
                                    }
                                },
                                onFocus: (event) => {
                                    openMenu(event);
                                    this.setState({ isInputChanged: false });
                                    this.inputElement = event.currentTarget;
                                    if (this.props.onFocus) { this.props.onFocus({ currentTarget: event.currentTarget }); }
                                },
                                placeholder,
                                label,
                                name,
                                disabled,
                                onBlur: (event) => {
                                    this.setState({ isInputChanged: false });
                                },
                                onKeyDown: (event) => {
                                    this.setState({ isInputChanged: true });
                                    if (this.props.onKeyDown) { this.props.onKeyDown(event); }
                                }
                            });

                            return (
                                <div className={classes.container}>
                                    {
                                        renderInput({
                                            fullWidth: true,
                                            classes,
                                            label,
                                            id,
                                            InputLabelProps: getLabelProps({ shrink: true }),
                                            InputProps: { onBlur, onChange, onFocus, onKeyDown },
                                            inputProps,
                                            validators: this.props.validators ? this.props.validators : [],
                                            errorMessages: this.props.errorMessages ? this.props.errorMessages : [],
                                            value,
                                            style,
                                            removeClass,
                                            inputStyles: inputStyles ? inputStyles : '',
                                            textBoxStyles: textBoxStyles ? textBoxStyles : '',
                                            inputRef,
                                            ref: this.inputRef
                                        })
                                    }

                                    <div {...getMenuProps()}>
                                        {isOpen ? <Backdrop open={isOpen} onClick={closeMenu} /> : null}
                                        {
                                            isOpen ? (
                                                <Paper className={classNames(classes.paper, classes.scroll)}
                                                    square
                                                    elevation={8}
                                                >
                                                    {
                                                        getSuggestions(inputValue, { options, displayMemberPath, showEmpty: true, openSuggestionsOnClick, isInputChanged }).map((suggestion, index) =>
                                                            renderSuggestion({
                                                                suggestion,
                                                                displayMemberPath,
                                                                index,
                                                                itemProps: getItemProps({ item: suggestion[displayMemberPath] }),
                                                                highlightedIndex,
                                                                classes,
                                                                hideIcon
                                                            })
                                                        )
                                                    }
                                                </Paper>
                                            ) : null
                                        }

                                    </div>
                                </div>
                            );
                        }
                    }
                </Downshift>
            </div>
        );
    }
}
AttributeSelect.propTypes = {
    classes: PropTypes.object,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    displayMemberPath: PropTypes.string,
    inputRef: PropTypes.element,
    options: PropTypes.array,
    value: PropTypes.string,
    style: PropTypes.object,
    removeClass: PropTypes.bool,
    inputStyles: PropTypes.string,
    textBoxStyles: PropTypes.string,
    openSuggestionsOnClick: PropTypes.bool,
    selectedValuePath: PropTypes.string,
    onChange: PropTypes.func,
    validators: PropTypes.array,
    errorMessages: PropTypes.array,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    hideIcon: PropTypes.bool,
    blurOnSelect: PropTypes.bool
};


export default withStyles((theme) => ({
    ...AttributeSelectStyles(theme),
    ...Styles(theme)
}))(AttributeSelect);