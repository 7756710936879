import { notificationActions } from '../constants/actionTypes/notificationActionTypes';

const config = localStorage.getItem('config') ? JSON.parse(localStorage.getItem('config')) : {};

const initialState = {
    isLoading: false,
    sidePanel: false,
    type: '',
    count: 0,
    notifications: [],
    conversations: [],
    conversation: {},
    conversationAll: [],
    conversationCount: 0,
    notificationCount: 0,
    showGrouppedReportView: true,
    groupedDriftRules: [],
    navigation: "",
    commentCount: 0,
    catalogView: false,
    notificationFilters: {
        users: [
            {
                isDefault: true,
                isSelected: true,
                isUser: false,
                name: 'All'
            }
        ],
        datasources: [{ name: 'All', isDefault: true }],
        datasets: [{ name: 'All', isDefault: true }],
        attributes: [{ name: 'All', isDefault: true }],
        types: "All",
        filter: "Last 3 Days",
        jobstatus: "All",
        notification: config.drift ? "Drift" : "All",
        search: ""
    },
    driftFilters: {
        datasources: [{ name: 'All', isDefault: true }],
        datasets: [{ name: 'All', isDefault: true }],
        attributes: [{ name: 'All', isDefault: true }],
        rules: [{ name: 'All', isDefault: true }],
        group_by: "attribute",
        range: "3",
        filter_count: 20,
        group_by_attributes: [{ name: 'All', isDefault: true }],
        grouped_keys: ""
    }

};

export const notificationReducer = (state = initialState, action) => {
    if (!action || (action && !action.type)) {
        return state;
    }

    switch (action.type) {
        case notificationActions.CLEAR_NOTIFICATIONS:
            return {
                ...state,
                notifications: [],
                notificationCount: 0
            };
        case notificationActions.TOGGLE_SIDE_PANEL:
            return {
                ...state,
                sidePanel: action.responseData ? action.responseData.sidePanel : false,
                type: action.responseData ? action.responseData.type : ''
            };
        case notificationActions.CREATE_ACTION_ITEM_REQUEST:
        case notificationActions.CREATE_ACTION_ITEM_FAILURE:
        case notificationActions.GET_USER_NOTIFICATION_REQUEST:
            return {
                ...state
            };
        case notificationActions.GET_ACTION_ITEM_COUNT_REQUEST:
        case notificationActions.GET_ACTION_ITEM_COUNT_FAILURE:
            return {
                ...state,
                count: 0
            };
        case notificationActions.CREATE_ACTION_ITEM_SUCCESS:
            return {
                ...state,
                count: (state.count + 1)
            };
        case notificationActions.GET_ACTION_ITEM_COUNT_SUCCESS:
            return {
                ...state,
                count: action.responseData
            };
        case notificationActions.GET_USER_NOTIFICATION_SUCCESS:
            const actionData = action.responseData.data ? action.responseData.data : [];
            const notificationData = action.responseData.resetData ? [...actionData] : [...state.notifications, ...actionData];
            return {
                ...state,
                notifications: [...notificationData],
                notificationCount: 0
            };
        case notificationActions.GET_USER_NOTIFICATION_FAILURE:
            return {
                ...state,
                notifications: []
            };
        case notificationActions.UPDATE_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications, action.responseData],
                notificationCount: state.notificationCount + 1
            };
        case notificationActions.UPDATE_NOTIFICATION_PPROPERTY:
            const notificationList = [...state.notifications];
            const index = notificationList.findIndex((data) => data.id === action.responseData.id);
            notificationList[index][action.responseData.property] = action.responseData.value;
            return {
                ...state,
                notifications: [...notificationList]
            };
        case notificationActions.UPDATE_CONVERSATION_LIST:
            const conversationsList = [...state.conversations];
            const selectedConversation = { ...state.conversation };
            const users = action.responseData.users;
            const type = action.responseData.type;
            const conversationType = action.responseData.conversationType;
            const data = action.responseData.data;
            const senderUser = users.find((user) => user.id === data.sender_id);
            const receiveUser = users.find((user) => user.id === data.receiver_id);
            const lastMessage = { ...data, senderUser: senderUser, receiveUser: receiveUser };
            let selectedIndex = -1;
            if (conversationType === "list") {
                selectedIndex = conversationsList.findIndex((item) => item.id === (type === "receive" ? data.sender_id : data.receiver_id));
            } else {
                selectedIndex = conversationsList.findIndex((item) => item.id === (type === "receive" ? data.sender_id : data.receiver_id) && item.sourceId === data.source_id && item.datasetId === data.dataset_id && item.domainId === data.domain_id);
            }

            if (selectedIndex !== -1) {
                if (selectedConversation && selectedConversation.messages) {
                    selectedConversation.messages.push({ ...lastMessage });
                    conversationsList[selectedIndex].messages = [...selectedConversation.messages];
                } else {
                    conversationsList[selectedIndex].messages.push({ ...lastMessage });
                }

            } else {
                const userData = users.find((user) => (user.id === (type === "receive" ? data.sender_id : data.receiver_id)));
                conversationsList.push({
                    ...userData,
                    sourceId: data.source_id,
                    datasetId: data.dataset_id,
                    messages: [{ ...lastMessage }]
                });
            }
            return {
                ...state,
                conversations: JSON.parse(JSON.stringify([...conversationsList])),
                conversation: JSON.parse(JSON.stringify({ ...selectedConversation })),
                conversationCount: (state.conversationCount + 1)
            };
        case notificationActions.GROUP_CONVERSATION_LIST:
            return {
                ...state,
                conversations: JSON.parse(JSON.stringify([...action.responseData.data])),
                conversation: action.responseData.type === "conversation" ? JSON.parse(JSON.stringify({ ...action.responseData.data[0] })) : {}
            };
        case notificationActions.SELECT_CONVERSATION:
            return {
                ...state,
                conversation: { ...action.responseData }
            };
        case notificationActions.GROUP_ALL_CONVERSATION_LIST:
            return {
                ...state,
                conversations: JSON.parse(JSON.stringify([...action.responseData])),
                conversation: JSON.parse(JSON.stringify({ ...action.responseData[0] }))
            };
        case notificationActions.UPDATE_CONVERSATION_READ_SUCCESS:
            const datasetId = action.responseData.dataset_id ? action.responseData.dataset_id : null;
            const conversationIndex = state.conversations.findIndex((conversation) => conversation.id === action.responseData.user_id && conversation.sourceId === action.responseData.source_id && conversation.datasetId === datasetId);
            const conversations = JSON.parse(JSON.stringify([...state.conversations]));
            conversations[conversationIndex].messages = conversations[conversationIndex].messages.map((item) => {
                return {
                    ...item,
                    "is_read": true
                };
            });
            return {
                ...state,
                conversations: JSON.parse(JSON.stringify([...conversations]))
            };
        case notificationActions.GET_ALL_CONVERSATION_BY_SOURCE_SUCCESS:
            return {
                ...state,
                conversationAll: [action.responseData]
            };
        case notificationActions.GET_CONVERSATION_COUNT_SUCCESS:
            return {
                ...state,
                conversationCount: action.responseData
            };
        case notificationActions.GET_CONVERSATION_COUNT_REQUEST:
        case notificationActions.GET_CONVERSATION_COUNT_FAILURE:
            return {
                ...state,
                conversationCount: 0
            };
        case notificationActions.GET_NOTIFICATION_COUNT_REQUEST:
        case notificationActions.GET_NOTIFICATION_COUNT_FAILURE:
            return {
                ...state,
                notificationCount: 0
            };
        case notificationActions.GET_NOTIFICATION_COUNT_SUCCESS:
            return {
                ...state,
                notificationCount: action.responseData ? action.responseData : 0
            };
        case notificationActions.NAVIGATE:
            return {
                ...state,
                navigation: action.responseData
            };
        case notificationActions.GET_NOTIFICATION_FILTER_REQUEST:
        case notificationActions.GET_NOTIFICATION_FILTER_FAILURE:
            return {
                ...state
            };
        case notificationActions.GET_NOTIFICATION_FILTER_SUCCESS:
            return {
                ...state,
                notifications: [...action.responseData]
            };
        case notificationActions.GET_COMMENT_COUNT_SUCCESS:
            return {
                ...state,
                commentCount: action.responseData
            };
        case notificationActions.GET_COMMENT_COUNT_REQUEST:
        case notificationActions.GET_COMMENT_COUNT_FAILURE:
            return {
                ...state,
                commentCount: 0
            };
        case notificationActions.CREATE_COMMENTS_SUCCESS:
            return {
                ...state,
                commentCount: state.commentCount + 1
            };
        case notificationActions.DELETE_COMMENT_SUCCESS:
            return {
                ...state,
                commentCount: state.commentCount - 1
            };
        case notificationActions.SWITCH_CATALOG:
            return {
                ...state,
                catalogView: action.responseData
            };
        case notificationActions.SET_NOTIFICATION_FILTER:
            return {
                ...state,
                notificationFilters: { ...action.filters }
            };
        case notificationActions.SET_DRIFT_FILTER:
            return {
                ...state,
                driftFilters: { ...action.filters }
            };
        case notificationActions.TOGGLE_GROUP_DRIFT_REPORT_VIEW:
            return {
                ...state,
                showGrouppedReportView: action.value
            };
        case notificationActions.GET_GROUP_DRIFT_REPORT_SUCCESS:
            return {
                ...state,
                groupedDriftRules: [...action.responseData]
            };
        case notificationActions.GET_GROUP_DRIFT_REPORT_FAILURE:
            return {
                ...state,
                groupedDriftRules: []
            };
        default:
            return state;
    }
};