import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { FormControl, InputBase, Paper, IconButton, withStyles } from '@material-ui/core';
import TextBoxStyles from './TextBoxStyles.jsx';

const Search = (props) => {
	const [classname, setClassname] = React.useState(null);
	const { classes, className, onChange, placeholder, value, autoFocus, onBlur } = props;
	const inputBlur = () => {
		if (onBlur) {
			onBlur();
		}
		setClassname(null);
	};

	return (
		<FormControl>
			<Paper className={`${"searchBox"} ${classNames(classes.searchContainer)} ${classname ? "activeSearchBox" : ""}`}>
				<InputBase
					autoComplete={"off"}
					className={`${classes.input} ${className}`}
					placeholder={placeholder}
					inputProps={{ 'aria-label': placeholder }}
					autoFocus={autoFocus}
					onChange={
						(event) => {
							onChange(event.target.value);
						}
					}
					onBlur={() => inputBlur()}
					onFocus={() => { setClassname("active"); }}
					value={value ? value : ''}
					fullWidth
				/>
				{
					value.length === 0 ?
						<IconButton style={{ width: '30px', padding: 7 }} aria-label="search">
							<svg xmlns="http://www.w3.org/2000/svg" width="13.094" height="13.092" viewBox="0 0 13.094 13.092">
								<g transform="translate(0 -0.035)">
									<path fill="#afb2b3" d="M5.27,10.57A5.257,5.257,0,0,0,8.5,9.46l3.483,3.483a.655.655,0,0,0,.926-.926L9.427,8.534A5.267,5.267,0,1,0,5.27,10.57ZM2.471,2.5a3.958,3.958,0,1,1,0,5.6h0a3.944,3.944,0,0,1-.02-5.577l.02-.02Z" transform="translate(0 0)" />
								</g>
							</svg>
						</IconButton> :
						<IconButton style={{ width: '30px', padding: 7 }} onClick={() => onChange('')}>
							<svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
								<path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
								<path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
							</svg>
						</IconButton>
				}

			</Paper>
		</FormControl>
	);
};

Search.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	value: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	autoFocus: PropTypes.bool,
	onBlur: PropTypes.func
};

export default withStyles(TextBoxStyles)(Search);