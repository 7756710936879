import React, { useCallback, useEffect, useState, memo } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';
import moment from 'moment-timezone';
import { getValue } from '../../helpers/appHelpers';
import { useSelector } from 'react-redux';


const GroupedBarChart = (props) => {
    const { analysis, theme, classes, chartHeight, curveColor, yAxisText, legends, chartClassName, showYAxis, fullscreen, barColor } = props;
    const [chartData, setChartData] = useState([]);
    const { sidePanel, type } = useSelector((state) => state.notification);


    const loadProperties = useCallback((analysisData) => {
        setChartData([...analysisData]);
    }, []);

    useEffect(() => {
        loadProperties([...analysis]);
    }, [analysis, loadProperties]);

    useEffect(() => {
        if (chartData && chartData.length !== 0) {

            d3.select(`.${chartClassName} > *`).remove();

            const margin = { top: 50, right: 50, bottom: 50, left: 50 };
            let width = window.innerWidth - margin.left - margin.right - (fullscreen ? 50 : 190);
            const height = chartHeight;

            const svg = d3
                .select(`.${chartClassName}`)
                .append("svg")
                .attr("width", "100%")
                .style("margin", "0px auto")
                .attr("height", height + margin.top + margin.top + margin.top)
                .append("g")
                .attr("width", (_, i, nodes) => {
                    width = nodes[i].parentNode.clientWidth - margin.left;
                    return width;
                })
                .attr("height", height)
                .attr("transform",
                    "translate(" + (margin.left) + "," + margin.left + ")");

            d3.select(`.${chartClassName} >.tooltip`).remove();


            /*
             * const xMin = d3.min(chartData, (d) => {
             *     return d.date;
             * });
             */

            /*
             * const xMax = d3.max(chartData, (d) => {
             *     return d.date;
             * });
             */

            const yMin = d3.min(chartData, (d) => {
                return d.count;
            });

            const yMax = d3.max(chartData, (d) => {
                return d.count;
            });

            const x = d3
                .scaleTime()
                .domain(d3.extent(chartData, (d) => { return d.date; }))
                .rangeRound([10, width - 10]);

            const y = d3
                .scaleLinear()
                .domain([0, 100])
                .range([height, 0]);

            svg.append("g")
                .attr("class", "x-axis")
                .attr("color", theme.palette.chartColors.axis)
                .attr("transform", `translate(0,${height})`)
                .call(d3.axisBottom(x).ticks(chartData.length - 1));

            svg.append("g")
                .attr("class", "y-axis")
                .attr("color", theme.palette.chartColors.axis)
                .call(d3.axisLeft(y).tickSize(-width).ticks(4));

            if (showYAxis) {
                svg.append("text")
                    .attr("transform", "rotate(-90)")
                    .attr("y", 0 - (margin.left))
                    .attr("x", 0 - (height / 2))
                    .attr("dy", "1em")
                    .style("text-anchor", "middle")
                    .text(yAxisText);
            }
            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
            svg.select('.y-axis path.domain').remove();

            const barYAxis = d3.scaleLinear().domain([yMin, yMax]).range([height, 0]);

            d3.select(`.${chartClassName} > .tooltip`).remove();
            const div = d3.select(`.${chartClassName}`).append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            const bars = svg.append("g")
                .attr("class", "bars");

            bars
                .selectAll('.high-bar')
                .data(chartData)
                .enter()
                .append('rect')
                .attr('class', (d, index) => `high-bar_${index}`)
                .attr('fill', barColor)
                .attr('width', 15)
                .attr('height', 0)
                .attr('x', (d) => x(d.date))
                .attr('y', height)
                .attr('height', 0)
                .on('mouseover',
                    (d, i, nodes) => {

                        // Show Tooltip
                        const rect = nodes[i].getBBox();
                        div.transition()
                            .duration(200)
                            .style("opacity", 0.9);
                        div.html(`Count : ${d.count}`)
                            .style("left", x(d.date) + "px")
                            .style("top", (rect.y - 30) + "px");
                        d3.select(nodes[i]).style("opacity", 1);
                    })
                .on('mouseout', (d, i, nodes) => {
                    d3.select(nodes[i]).style("opacity", 0.9);
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .transition()
                .duration(500)
                .delay((d, i) => {
                    return i * 100;
                })
                .attr('y', (d) => barYAxis(d.count))
                .attr('height', (d) => height - barYAxis(d.count));

            svg.append("path")
                .datum(chartData)
                .attr("fill", curveColor)
                .attr("fill-opacity", 0.1)
                .attr("stroke", "none")
                .attr("stroke-linejoin", "round")
                .attr("d", d3.area()
                    .x(0)
                    .y0(height)
                    .y1((d) => { return y(d.score); })
                    .curve(d3.curveMonotoneX)
                )
                .transition()
                .duration(2000)
                .attr("d", d3.area()
                    .x((d) => { return x(d.date); })
                    .y0(height)
                    .y1((d) => { return y(d.score); })
                    .curve(d3.curveMonotoneX)
                );
            svg.append("path")
                .datum(chartData)
                .attr("fill", "none")
                .attr("stroke", curveColor)
                .attr("stroke-width", 2)
                .attr("stroke-linejoin", "round")
                .attr("d", d3.line()
                    .x(0)
                    .y((d) => { return y(d.score); })
                    .curve(d3.curveMonotoneX)
                )
                .transition()
                .duration(2000)
                .attr("d", d3.line()
                    .x((d) => { return x(d.date); })
                    .y((d) => { return y(d.score); })
                    .curve(d3.curveMonotoneX)
                );

            svg.selectAll("myCircles")
                .data(chartData)
                .enter()
                .append("circle")
                .attr("fill", theme.palette.background.paper)
                .attr("stroke-width", 2)
                .attr("stroke", curveColor)
                .attr("cx", 0)
                .attr("cy", (d) => { return y(d.score); })
                .attr("r", 4)
                .on('mouseover', (d, i, nodes) => {
                    const rect = nodes[i].getBBox();
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html(`Date : ${moment(d.date).format('MMMM DD YYYY')}<br> DQScore : ${getValue(d.score)}`)
                        .style("left", x(d.date) + "px")
                        .style("top", (rect.y - 30) + "px");
                })
                .on('mouseout', () => {
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })

                .transition()
                .delay((d, i) => { return Number(i); })
                .duration(2000)
                .attr("cx", (d) => { return x(d.date); });


            if (legends && legends.length) {
                const legendGroup = svg.append("g")
                    .attr("class", "legends")
                    .attr("transform", "translate(" + ((width / 2) - 100) + "," + (height + margin.left + 10) + ")");

                const legendGap = 130;
                // const legendColors = align === "center" ? colors : colors.reverse();

                legendGroup.selectAll(".legend-group")
                    .data(legends)
                    .enter()
                    .append("g")
                    .attr("class", "legend-group")
                    .style("opacity", 1)
                    .append("rect")
                    .attr("class", "legend-rect")
                    .attr("width", 14)
                    .attr("height", 10)
                    .attr('x', (_, index) => { return (index * legendGap); })
                    .style("fill", (legend, index) => legend.color);

                const legendText = legendGroup.selectAll(".legend-group")
                    .append("text")
                    .attr("class", "legend-text")
                    .attr("x", (_, index) => { return index * legendGap + 20; })
                    .attr("y", 10)
                    .attr("alignment-baseline", "middle");

                legendText
                    .append('tspan')
                    .text((line) => {
                        return line.name;
                    });

                legendText
                    .append('tspan')
                    .attr("x", (_, i) => i * legendGap + margin.top)
                    .attr("dy", "1.2em")
                    .text((line) => {
                        return line.value;
                    });
            }
        }
    }, [analysis, barColor, chartClassName, chartData, chartHeight, curveColor, fullscreen, legends, showYAxis, theme.palette.background.paper, theme.palette.chartColors.axis, theme.palette.groupChart, yAxisText]);

    return (
        <Grid className={classNames(chartClassName, (sidePanel && type === "conversation") ? classes.noPadding : null, classes.chartStyles)} />
    );
};

GroupedBarChart.propTypes = {
    classes: PropTypes.object,
    analysis: PropTypes.array,
    chartHeight: PropTypes.number,
    fullscreen: PropTypes.bool,
    theme: PropTypes.object,
    chartClassName: PropTypes.string,
    legends: PropTypes.array,
    curveColor: PropTypes.string,
    showYAxis: PropTypes.bool,
    yAxisText: PropTypes.string,
    barColor: PropTypes.string
};

export default memo(withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(GroupedBarChart));