import React, { useEffect } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes, { instanceOf } from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import jStat from 'jstat';
import Styles from '../../../layouts/Styles.jsx';
import ChartStyles from '../ChartStyles.jsx';
import { appConstants } from '../../../constants/appConstants.js';
import Group2138 from '../../../assets/images/Group_2138.svg';
import Group2136 from '../../../assets/images/Group_2136.svg';

const NormalDistribution = (props) => {
    const { chartData, classes, theme, attribute, onFilterChange, attributeChange, edited, discardEdit } = props;

    useEffect(() => {
        renderChart();
    });

    const renderChart = () => {
        if (chartData && Object.keys(chartData).length !== 0 && (attributeChange || edited)) {
            if (edited) {
                discardEdit();
            }
            const statisticalCount = chartData.input_params;
            const normalDistributionData = [];
            if (chartData && instanceOf(chartData.valid_data) === "object") {
                chartData.valid_data = [];
            }
            if (chartData && instanceOf(chartData.outliers) === "object") {
                chartData.outliers = [];
            }
            const selectionData = [...chartData.valid_data, ...chartData.outliers];
            for (const value of selectionData) {
                normalDistributionData.push({
                    x: parseFloat((value[attribute] === undefined) ? value[attribute.toLowerCase()] : value[attribute]),
                    y: jStat.normal.pdf((value[attribute] === undefined) ? value[attribute.toLowerCase()] : value[attribute], statisticalCount.mean, statisticalCount.standard_deviation),
                    isValid: !value.outlier
                });
            }

            const margin = { top: 40, right: 20, bottom: 50, left: 40 };
            let width = 960 - margin.left - margin.right;
            const height = 280;
            d3.select('.normal-distribution-chart > *').remove();
            d3.select('.normal-distribution-chart > svg').remove();
            d3.select('.normal-distribution-chart > .yLabel').remove();
            d3.select('.normal-distribution-chart > .pattern-legend').remove();
            d3.select('.normal-distribution-chart > .anomaly-legend').remove();
            d3.select('.normal-distribution-chart > .length-container').remove();
            const svg = d3.select(".normal-distribution-chart").append("svg")
                .attr("width", "75%")
                .style("z-index", 1)
                .style("margin", "45px auto auto auto")
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("width", (_, i, nodes) => {
                    width = nodes[i].parentNode.clientWidth - 100;
                    return width;
                })
                .attr("height", height + margin.bottom)
                .attr("transform", "translate(" + (margin.left + 40) + "," + (margin.top / 2) + ")");

            // set the ranges
            let minX = d3.min(normalDistributionData, (d) => { return d.x; });
            let maxX = d3.max(normalDistributionData, (d) => { return d.x; });
            maxX += maxX / 10;
            if (statisticalCount.lower_limit < minX) { minX = statisticalCount.lower_limit; }
            if (statisticalCount.upper_limit > maxX) { maxX = statisticalCount.upper_limit; }
            const x = d3.scaleLinear().domain([minX, maxX])
                .range([0, width - (margin.left + margin.right + 100)]).nice();
            const minY = d3.min(normalDistributionData, (d) => { return d.y; });
            const maxY = d3.max(normalDistributionData, (d) => { return d.y; });
            const y = d3.scaleLinear().domain([minY, maxY])
                .range([height, 0]).nice();

            const xScale = d3.axisBottom(x);
            const yScale = d3.axisLeft(y);

            // Tooltip div
            d3.select(".normal-distribution-chart > .tooltip").remove();
            const div = d3.select(".normal-distribution-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0);
            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .attr("color", theme.palette.chartColors.axis)
                .attr('class', 'axis-x')
                .transition()
                .duration(2000)
                .call(xScale);
            svg.append("text")
                .attr("transform", "translate(" + (width / 2) + " ," + (height + margin.top + 20) + ")")
                .style("text-anchor", "middle")
                .text("Value");

            // add the y Axis
            svg.append("g")
                .attr("color", theme.palette.chartColors.axis)
                .attr('class', 'axis-y')
                .transition()
                .duration(2000)
                .call(yScale);

            d3.select('.normal-distribution-chart').append("div").attr("class", "distributeYLabel").html("Distribution");

            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
            svg.selectAll(".axis-x .tick:last-of-type text").remove();
            svg.selectAll('.axis-y .tick-text').text('').append('tspan').text((d) => { return d; });

            setTimeout(() => {
                const subStringCount = width <= 800 ? 3 : 8;
                svg.selectAll(".axis-x .tick .tick-text").text('').append('tspan').text((d) => {
                    let value = d ? d.toString() : '';
                    if (value.length > 8) {
                        value = value.substring(subStringCount, 0);
                        value = value.concat('...');
                    }
                    return value;
                });
            }, 500);

            const brush = d3.brush().extent([[0, 0], [width, height]]).on("end", zoomEnd);
            let idleTimeout = null;
            const idleDelay = 350;

            svg.append("defs").append("clipPath")
                .attr("id", "clip")
                .append("rect")
                .attr("width", width)
                .attr("height", height)
                .attr("x", 0)
                .attr("y", 0);

            const scatter = svg.append("g")
                .attr("id", "distribution")
                .attr("clip-path", "url(#clip)");

            scatter.append("g")
                .attr("class", "brush")
                .call(brush);

            function zoomEnd() {
                const s = d3.event.selection;
                if (!s) {
                    if (!idleTimeout) { return idleTimeout = setTimeout(idled, idleDelay); }
                    let minX = d3.min(normalDistributionData, (d) => { return d.x; });
                    let maxX = d3.max(normalDistributionData, (d) => { return d.x; });
                    maxX += maxX / 10;
                    if (statisticalCount.lower_limit < minX) { minX = statisticalCount.lower_limit; }
                    if (statisticalCount.upper_limit > maxX) { maxX = statisticalCount.upper_limit; }
                    const distributionMinY = d3.min(normalDistributionData, (d) => { return d.y; });
                    const distributionMaxY = d3.max(normalDistributionData, (d) => { return d.y; });
                    x.domain([minX, maxX]).range([0, width - (margin.left + margin.right + 100)]).nice();
                    y.domain([distributionMinY, distributionMaxY]).nice();
                } else {
                    x.domain([s[0][0], s[1][0]].map(x.invert, x)).range([0, width - (margin.left + margin.right + 100)]).nice();
                    y.domain([s[1][1], s[0][1]].map(y.invert, y)).nice();
                    scatter.select(".brush").call(brush.move, null);
                }
                zoom();
                loadLegend();
            }

            function idled() {
                idleTimeout = null;
            }

            function zoom() {
                const t = scatter.transition().duration(750);
                svg.select(".axis-x").transition(t).call(xScale);
                svg.select(".axis-y").transition(t).call(yScale);
                svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
                scatter.selectAll("circle").transition(t)
                    .attr("cx", (d) => { return x(d.x); })
                    .attr("cy", (d) => { return y(d.y); });
            }


            scatter.append("g")
                .attr("id", "circles")
                .selectAll("circle")
                .data(normalDistributionData)
                .enter()
                .append("circle")
                .attr("class", "dot")
                .attr("cx", (d) => { return x(d.x); })
                .attr("cy", height)
                .attr("r", 3.0)
                .attr("fill", (d) => { return d.isValid ? theme.palette.chartColors.valid : theme.palette.chartColors.inValid; })
                .attr("stroke", (d) => { return d.isValid ? theme.palette.chartColors.valid : theme.palette.chartColors.inValid; })
                .on('mouseover', (d) => {
                    // Show Tooltip
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    let x = 0;
                    let y = 0;
                    div.attr("transform", (_, i, nodes) => {
                        const mouseCoords = d3.mouse(nodes[i].parentNode);
                        let xCo = 0;
                        let yCo = 0;
                        if (mouseCoords[0] + 10 >= width * 0.80) {
                            xCo = mouseCoords[0] - parseFloat(div
                                .attr("width"));
                            yCo = mouseCoords[1] + 10;
                        } else {
                            xCo = mouseCoords[0] + 10;
                            yCo = mouseCoords[1];
                        }
                        x = xCo;
                        y = yCo;
                    });
                    div.html(`Value : ${d.x}`)
                        .style("left", x + "px")
                        .style("top", y + "px");
                }).on('mouseout', () => {
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .on('click', (d) => {
                    onFilterChange({ value: d.x, filterType: appConstants.QueryFilterTypes[0] });
                })
                .transition()
                .delay(100)
                .duration(2000)
                .attr("cy", (d) => { return y(d.y); });

            loadLegend();
            function loadLegend() {
                svg.selectAll('.interval-line').remove();
                svg.selectAll('.interval-line-text').remove();
                d3.select('.normal-distribution-chart .pattern-legend').remove();
                d3.select('.normal-distribution-chart .anomaly-legend').remove();
                d3.select('.normal-distribution-chart .normal-container').remove();

                let lines = ["upper_limit", "lower_limit", "mean"];
                svg.selectAll('.interval-line')
                    .data(lines)
                    .enter().append("line")
                    .attr("fill", "none")
                    .attr("x1", (line, index, nodes) => {
                        let x1 = parseFloat(statisticalCount[line] ? statisticalCount[line] : 0);
                        d3.select(nodes[index]).attr("class", "interval-line " + line + " ").attr('line', line)
                            .attr("value", x1);
                        x1 = x(x1);
                        return x1 >= 0 ? x1 : 0;
                    })
                    .attr("x2", (line) => {
                        let x2 = parseFloat(statisticalCount[line] ? statisticalCount[line] : 0);
                        x2 = x(x2);
                        return x2 >= 0 ? x2 : 0;
                    })
                    .attr("y1", () => { return y(0) <= 280 ? y(0) : 280; })
                    .attr("y2", () => { return y(d3.max(normalDistributionData, (d) => { return d[1]; })); });

                // Text for min, max and avg
                svg.selectAll('.interval-line-text')
                    .data(lines)
                    .enter().append("text")
                    .attr("class", "interval-line-text")
                    .text((line) => {
                        if ((statisticalCount.upper_limit === statisticalCount.lower_limit) || (statisticalCount.upper_limit === statisticalCount.mean) || (statisticalCount.lower_limit === statisticalCount.mean)) {
                            return '';
                        }
                        let lineText = '';
                        if (line === "upper_limit") { lineText = "UB"; }
                        else if (line === "lower_limit") { lineText = "LB"; }
                        else { lineText = "M"; }
                        return lineText;
                    })
                    .attr("x", (line, i, nodes) => {
                        let x1 = x(statisticalCount[line]);
                        x1 = x1 >= 0 ? x1 : 0;
                        const textLength = d3.select(nodes[i]).node().getComputedTextLength();
                        x1 -= (textLength / 2);
                        return x1;
                    })
                    .attr("y", () => { return y(0) + 35; });

                lines = ["valid", "invalid"];
                //  Legends
                let legendGap = 100;
                const legendPosition = 17;
                let legendX = (width / 2) - legendGap;
                let legendGroup = d3.select('.normal-distribution-chart').append("div")
                    .attr("class", "pattern-legend");

                // Legend rectangle
                legendGroup.selectAll(".pattern-legendgroup")
                    .data(['valid', 'invalid'])
                    .enter()
                    .append("div")
                    .attr("class", "pattern-legendgroup")
                    .append("div")
                    .attr("class", (d) => {
                        return d === "valid" ? 'lengend-valid' : 'lengend-invalid';
                    });


                d3.select('.normal-distribution-chart .length-container').remove();
                // Line Text
                const linesDescription = ["computed lower bound", "computed Mean bound", "computed Upper bound "];
                lines = ["lower_limit", "mean", "upper_limit"];
                const topClass = d3.select(".normal-distribution-chart").append("div")
                    .attr('class', 'length-container')
                    .style("width", `${width}px` + 5)
                    .style("transform", `translate(${margin.left * 2}px,${(margin.top / 2) - 15}px)`)
                    .style('left', '15%')
                    .append('div')
                    .attr("class", "length-row")
                    .style("position", 'relative');

                const updateLengthTopPosition = (lines, statisticalCount) => {
                    if ((lines === "mean") && (x(statisticalCount.upper_limit) - x(statisticalCount.mean) < 105)) {
                        return "100px";
                    }
                    else if ((lines === "upper_limit") && (x(statisticalCount.upper_limit) - x(statisticalCount.mean) < 105)) {
                        return "200px";
                    }
                    return "0px";
                };
                topClass.selectAll('.length-row')
                    .data(lines)
                    .enter()
                    .append("div")
                    .attr("class", (lines) => {
                        if (lines === "lower_limit") {
                            return 'length-column lower_limit';
                        } else if (lines === "mean") {
                            return 'length-column mean';
                        } else if (lines === "upper_limit") {
                            return 'length-column upper_limit';
                        }
                    })
                    .style(('left'), (lines) => {
                        const leftPosition = x(statisticalCount[lines]) + 35;
                        return `${leftPosition}px`;
                    })
                    .style('top', (lines) => {
                        updateLengthTopPosition(lines, statisticalCount);
                    });

                d3.select(".normal-distribution-chart .length-container .length-row .upper_limit").style("top", updateLengthTopPosition("upper_limit", statisticalCount));
                d3.select(".normal-distribution-chart .length-container .length-row .mean").style("top", updateLengthTopPosition("mean", statisticalCount));

                // Bold value

                topClass.selectAll('.length-column')
                    .data(lines)
                    .append("b")
                    .attr('class', 'interval-up-bold-text')
                    .style('text-transform', 'capitalize')
                    .style('font-weight', 'bold')
                    .text((line) => `${line.replace('_', ' ').replace('limit', 'bound')}`);


                // Normal Text
                topClass.selectAll('.length-column')
                    .data(lines)
                    .append("p")
                    .attr('class', 'interval-up-normal-text')
                    .style('text-transform', 'capitalize')
                    .text((line, index) => `${linesDescription[index]} ${statisticalCount[line]}`);


                // Image Text
                topClass.selectAll('.length-column')
                    .data(lines)
                    .append("img")
                    .attr('class', 'interval-up-img-text')
                    .attr('src', (line, index) => {
                        if (line === "min") {
                            return Group2136;
                        }
                        return Group2138;
                    })
                    .attr("width", "60")
                    .attr("height", "50");

                // Legend label
                legendGroup.selectAll(".pattern-legendgroup")
                    .append("span")
                    .text((line) => { return line; });

                //   Interval Legends
                lines = ["lower_limit", "mean", "upper_limit", "standard_deviation"];
                legendGap = 20;
                legendX = width - (margin.left + margin.right);
                legendGroup = d3.select(".normal-distribution-chart").append("div")
                    .attr("class", "anomaly-legend");

                const maxTextLength = 0;
                legendGroup.selectAll(".legend-group")
                    .data(lines)
                    .enter()
                    .append("p")
                    .attr("class", "legend-text")
                    .attr("alignment-baseline", "middle")
                    .text((line) => {
                        if (line === 'standard_deviation') { return `std(${statisticalCount[line]})`; }
                        return `${line.replace('_', ' ').replace('limit', 'bound')}(${statisticalCount[line]})`;
                    })
                    .style("opacity", 0)
                    .transition()
                    .duration(1000)
                    .style("opacity", 1);

                legendGroup.attr("transform", "translate(" + (legendX - (maxTextLength / 2)) + "," + legendPosition + ")");
            }


        }
    };

    return (
        <Grid container className={classNames(classes.chartStyles, "normal-distribution-chart")} />
    );
};

NormalDistribution.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.object,
    theme: PropTypes.object,
    attribute: PropTypes.string,
    onFilterChange: PropTypes.func,
    attributeChange: PropTypes.bool,
    edited: PropTypes.bool,
    discardEdit: PropTypes.func
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(NormalDistribution);