
const MentionStyles = (theme) => ({
    paper: {
        width: 300,
        zIndex: 1330,
        position: 'fixed',
        maxWidth: 'max-content',
        boxShadow: '0px 5px 5px - 3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
        marginTop: 20,
        maxHeight: 200,
        overflowY: ' auto'
    },
    inputContainer: {
        height: 80,
        borderBottom: `1px solid ${theme.palette.border.default}`
    },
    mentionInput: {
        '& .MuiInput-underline:before': {
            border: `1px solid ${theme.palette.grey.light}`,
            height: '100%'
        },
        '& textarea': {
            padding: 5
        }
    },
    mentionContainer: {
        position: 'relative',
        '& .mentions--multiLine .mentions__control': {
            fontSize: 13,
            height: 80
        },
        '& .mentions--multiLine .mentions__suggestions': {
            left: "18px !important",
            top: "-15px !important"
        },
        '& .mentions--multiLine.mentions__highlighter': {
            padding: 9
        },
        '& .mentions--multiLine.mentions__input': {
            padding: 9,
            minHeight: 63,
            outline: 0,
            border: 0
        },
        '& .mentions__suggestions__list': {
            backgroundColor: '#fff',
            border: '1px solid rgba(0, 0, 0, 0.15)',
            fontSize: 13,
            width: 300,
            zIndex: 1330,
            position: 'fixed',
            maxWidth: 'max-content',
            boxShadow: '0px 5px 5px - 3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
            marginTop: 20,
            maxHeight: 90,
            overflowY: ' auto'
        },
        '& .mentions__suggestions__item': {
            padding: '5px 15px'
            // borderBottom: '1px solid rgba(0, 0, 0, 0.15)'
        },

        '& .mentions__suggestions__item--focused': {
            backgroundColor: '#cee4e5'
        },

        '& .mentions__mention': {
            backgroundColor: '#cee4e5'
        }
    },
    commentBorder: {
        // border: `1px solid ${theme.palette.border.normal}`,
        backgroundColor: theme.palette.grey.exteremeLight,
        '& textarea': {
            borderColor: 'transparent !important'
        }
    }

});

export default MentionStyles;