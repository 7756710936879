import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import { alertActions } from '../constants/actionTypes/alertActionTypes';
import * as apiService from '../services/apiService';
import { dashboardActions } from '../constants/actionTypes/dashboardActionTypes';
import { alerts } from '../config/alerts';


export const getDashboards = () => {
    return (dispatch) => {
        dispatch(request(dashboardActions.GET_DASHBOARDS_REQUEST));
        return apiService.getRequest(appConstants.END_POINTS.dashboard.getAllDashboards).then((response) => {
            dispatch(success(dashboardActions.GET_DASHBOARDS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(dashboardActions.GET_DASHBOARDS_FAILURE, error));
        });
    };
};

export const createDashboard = (requestParams) => {
    return (dispatch) => {
        dispatch(request(dashboardActions.CREATE_DASHBOARD_REQUEST));
        return apiService.postRequest(appConstants.END_POINTS.dashboard.createDashboard, requestParams).then((response) => {
            dispatch(success(dashboardActions.CREATE_DASHBOARD_SUCCESS, response.data));
            // dispatch(success(alertActions.ALERT_SUCCESS, "Dashboard Created Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(dashboardActions.CREATE_DASHBOARD_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};

export const updateDashboard = (requestParams, dashboardId) => {
    const endpoint = `${appConstants.END_POINTS.dashboard.updateDashboard}/${dashboardId}`;
    return (dispatch) => {
        dispatch(request(dashboardActions.UPDATE_DASHBOARD_REQUEST));
        return apiService.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(dashboardActions.UPDATE_DASHBOARD_SUCCESS, response.data));
            // dispatch(success(alertActions.ALERT_SUCCESS, "Dashboard Updated Successfully"));
            return response.data;
        }, (error) => {
            dispatch(failure(dashboardActions.UPDATE_DASHBOARD_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };

};

export const deleteDashboard = (enrichmentId) => {
    const endpoint = `${appConstants.END_POINTS.dashboard.deleteDashboard}/${enrichmentId}`;
    return (dispatch) => {
        dispatch(request(dashboardActions.DELETE_DASHBOARD_REQUEST));
        return apiService.deleteRequest(endpoint).then((response) => {
            dispatch(success(dashboardActions.DELETE_DASHBOARD_SUCCESS, response.data));
            dispatch(success(alertActions.ALERT_SUCCESS, alerts.deleteDashboard));
            return response.data;
        }, (error) => {
            dispatch(failure(dashboardActions.DELETE_DASHBOARD_FAILURE, error));
            dispatch(failure(alertActions.ALERT_ERROR, error.toString()));
        });
    };
};