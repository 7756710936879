import React, { useState, useCallback } from 'react';
import { withStyles, Dialog, DialogTitle, DialogContent, Grid, Typography, IconButton, Slide } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Close } from '@material-ui/icons';
import classNames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import DomainDataTable from '../DomainDataPreview/DomainDataTable.jsx';
import DomainDataPreviewStyles from '../DomainDataPreview/DomainDataPreviewStyles.jsx';
import Loader from '../Loaders/Loader.jsx';
import NoResultImg from '../../assets/images/NoDataset.png';
import { previewInvalidData } from '../../actions/scheduleActions.js';
import Search from '../TextBox/Search.jsx';

const Transition = React.forwardRef(Object.assign((props, ref) => <Slide direction="up" ref={ref} {...props} />), { displayName: 'Transition' });

const ProfileInvalidRecordsPreview = (props) => {
    const { classes, open, onClose, selectedAttribute, datasetId } = props;
    const [searchKey, setSearchKey] = useState('');
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [previewData, setPreviewData] = useState({
        userAccessLevel: 1,
        headers: [],
        attributes: {},
        inputData: [],
        totalRecords: 0,
        count: 0
    });

    const getPreviewData = useCallback(() => {
        const model = {
            "type": 'attribute',
            "dataset_id": datasetId,
            "attribute_name": selectedAttribute.name,
            "rule_name": ''
        };
        setSearchKey('');
        setLoading(true);

        dispatch(previewInvalidData(0, model)).then((response) => {
            const headers = response && response.attributes ? response.attributes.map((attribute) => attribute.replace(/ /g, "_")) : [];
            if (selectedAttribute.name.length > 0) {
                const attributeIndex = headers.findIndex((header) => header === selectedAttribute.name);
                if (attributeIndex > -1) {
                    headers.splice(attributeIndex, 1);
                    headers.splice(0, 0, selectedAttribute.name);
                }
            }
            setPreviewData({
                attributes: response && response.attribute_rules ? { ...response.attribute_rules } : {},
                inputData: response && response.preview_data ? [...response.preview_data] : [],
                headers: [...headers],
                userAccessLevel: response && response.user_access_level ? response.user_access_level : 1,
                totalRecords: response && response.total_count ? response.total_count : 0,
                invalidRows: response && response.invalid_rows ? [...response.invalid_rows] : [],
                count: response && response.count ? response.count : 0
            });
            setLoading(false);
        });
    }, [datasetId, dispatch, selectedAttribute.name]);

    const onOpenDialog = useCallback(() => {
        getPreviewData();
    }, [getPreviewData]);

    const headers = searchKey ? previewData.headers.filter((header) => header.toString().toLowerCase().includes(searchKey.toLowerCase())) : previewData.headers;

    return (
        <Dialog open={open}
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={Transition}
            className={classes.queryFilterDialog}
            onEnter={() => onOpenDialog()}
            onExit={() => onClose()}>
            <DialogTitle style={{ paddingBottom: 0 }}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6" className={classNames(classes.QueryfilterDataTitle)}>
                            {'Invalid Records Preview'}
                        </Typography>
                        <Typography variant="h6" className={classNames(classes.marginBottom5, classes.QueryfilterTitle)}>
                            {selectedAttribute.name}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton className={classes.nopadding} onClick={() => onClose()}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ position: "relative" }} className={classes.scroll}>
                <Grid>
                    <Grid container justify="space-between" alignItems="center" style={{ marginTop: 4 }}>
                        <Grid item>
                            <Grid container alignItems="center" direction="row" style={{ margin: '3px 0' }}>
                                {
                                    (!isLoading && previewData && previewData.headers.length > 0) &&
                                    <Grid item className={classes.marginLeft15}>
                                        <Typography>
                                            {`Showing ${previewData.totalRecords < previewData.count ? previewData.totalRecords : previewData.count} of ${previewData.totalRecords} records`}
                                        </Typography>
                                    </Grid>
                                }
                            </Grid>

                        </Grid>

                        <Grid item className={classNames(classes.includeSearchContainer)}>
                            <Search
                                value={searchKey}
                                placeholder="Search Attributes"
                                className={classes.dqSearchBox}
                                onChange={(value) => setSearchKey(value)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className={classes.previewContainer}>
                    {
                        (!isLoading && headers && headers.length > 0) &&
                        <DomainDataTable
                            headers={headers ? [...headers] : []}
                            previewData={previewData}
                        />
                    }
                    {
                        headers && headers.length === 0 && !isLoading &&
                        <Grid container alignItems="center" justify="center" className={classes.noResultFound}>
                            <Grid>
                                <img src={NoResultImg} alt="No Result Found" />
                                <Typography variant="h4" align="center">
                                    {'No Result Found'}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    {isLoading && <Loader />}
                </Grid>
            </DialogContent>

        </Dialog>
    );
};

ProfileInvalidRecordsPreview.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    selectedAttribute: PropTypes.object,
    datasetId: PropTypes.number
};

export default withStyles((theme) => ({
    ...DomainDataPreviewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ProfileInvalidRecordsPreview);