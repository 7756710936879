import React from 'react';
import { Grid, Table, TableHead, TableBody, TableRow, TableCell, withStyles, Typography, IconButton, Switch } from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import Circular from './Circular.jsx';
import ChartStyles from './ChartStyles.jsx';
import { appConstants } from '../../constants/appConstants';
import classNames from 'classnames';
import ruleAnalysis from '../../assets/images/ruleAnalysis.svg';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const RulesStatistics = (props) => {
    const { rules, classes, redirectChart, getRulePlan, profileRuleEnable, getCountStatisticsRules, enableCountStatisticsRule, dq_score_threshold } = props;
    return (
        <ChartContainer
            title={appConstants.charts.rules.name}
            chartData={rules}
        >
            <Grid className={classNames(classes.rulesTableContainer, classes.tableWrapperStyle)}>
                <Table stickyHeader className={classes.tableStyle}>
                    <TableHead className={classes.bgWhite}>
                        <TableRow>
                            {
                                appConstants.rulesHeader.map((header, index) =>
                                    <TableCell align={(header === "Rule" || header === "Description") ? 'left' : 'center'} className={classes.tableHeadCell} key={`header_${index}`}>
                                        {header}
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rules && Object.keys(rules).length > 0 && rules.map((rule, index) =>
                                <TableRow className={classes.tableRow} key={`rulecontent_${index}`}>
                                    <TableCell className={classes.tableCell}>
                                        <ToolTipComponent title={rule.name} arrow>
                                            <Typography className={classes.descriptionTxt}>
                                                {rule.name}
                                            </Typography>
                                        </ToolTipComponent>
                                    </TableCell>
                                    <TableCell className={classes.tableCell}>
                                        <ToolTipComponent title={rule.description} arrow>
                                            <Typography className={classes.descriptionTxt}>
                                                {rule.description}
                                            </Typography>
                                        </ToolTipComponent>
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="center">
                                        {rule.polarity === "negative" ? rule.invalid_records : rule.valid_records}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="center">
                                        {rule.polarity === "negative" ? rule.valid_records : rule.invalid_records}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="center">
                                        {rule.total_records_processed}
                                    </TableCell>
                                    <TableCell className={classes.tableCell} align="center">
                                        {
                                            rule.isEnable ?
                                                <Circular
                                                    customStyle={classes.profileScore}
                                                    showPercentage={rule.valid_percentage && rule.valid_percentage !== 0}
                                                    showValue
                                                    value={(rule.default_name === "distinct_count" || rule.default_name === "unique_count") ? rule.invalid_percentage : rule.valid_percentage}
                                                    dqScoreThreshold={dq_score_threshold}
                                                />
                                                :
                                                <Circular
                                                    customStyle={classes.profileScore}
                                                    // showPercentage={rule.valid_percentage && rule.valid_percentage !== 0}
                                                    showPercentage={false}
                                                    showValue
                                                    isDisabled={!getCountStatisticsRules(rule.default_name)}
                                                    // value={(rule.default_name === "distinct_count" || rule.default_name === "unique_count") ? rule.invalid_percentage : rule.valid_percentage}
                                                    value={0}
                                                    dqScoreThreshold={dq_score_threshold}
                                                />
                                        }
                                    </TableCell>
                                    <TableCell className={classNames(classes.tableCell, classes.ruleStatisticsAction)} align="center">
                                        {
                                            rule.type !== 'countstatistics' ?
                                                <ToolTipComponent title={"Profile Rule"} arrow>
                                                    <Switch
                                                        color="secondary"
                                                        size="small"
                                                        onChange={(event) => profileRuleEnable(rule.name, event.target.checked)}
                                                        checked={getRulePlan(rule.name)} />
                                                </ToolTipComponent>
                                                :
                                                <ToolTipComponent title={"Count Statistics Rule"} arrow>
                                                    <Switch
                                                        color="secondary"
                                                        size="small"
                                                        onChange={(event) => enableCountStatisticsRule(rule.default_name, event.target.checked)}
                                                        checked={getCountStatisticsRules(rule)} />
                                                </ToolTipComponent>
                                        }
                                        <IconButton onClick={() => redirectChart(rule.rule_name.toLocaleLowerCase())}>
                                            <ToolTipComponent title={"Redirect Chart"} arrow>
                                                <img src={ruleAnalysis} alt="" />
                                            </ToolTipComponent>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        }

                    </TableBody>
                </Table>
            </Grid>
        </ChartContainer>
    );
};

RulesStatistics.propTypes = {
    classes: PropTypes.object,
    rules: PropTypes.array,
    redirectChart: PropTypes.func,
    profileRuleEnable: PropTypes.func,
    getRulePlan: PropTypes.func,
    getCountStatisticsRules: PropTypes.func,
    enableCountStatisticsRule: PropTypes.func,
    dq_score_threshold: PropTypes.object
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(RulesStatistics);