import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Avatar } from '@material-ui/core';
import classnames from 'classnames';

import { getRandomColor, getInitials, returnImage } from '../../helpers/appHelpers';
import { appConstants } from '../../constants/appConstants';
import ConversationStyles from './ConversationStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import moment from 'moment-timezone';

const ConversationMessage = (props) => {
    const { classes, message, type, userId } = props;
    const presentDate = moment().format('MMM DD YYYY');
    const messageDate = moment(message.created_date).format('MMM DD YYYY');
    const user = message.senderUser ? message.senderUser : message;
    return (
        <Grid container direction={message.sender_id !== userId ? "row" : "row-reverse"}>
            {
                (type === "messageItems" && message.sender_id !== userId) &&
                <Avatar
                    className={classes.marginRight15}
                    style={
                        {
                            backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, user.avatar)})`,
                            backgroundColor: getRandomColor(user.first_name !== "" ? `${user.first_name} ${user.last_name}` : user.email)
                        }
                    }>
                    {
                        !user.avatar &&
                        <span>
                            {getInitials(user.first_name !== "" ? `${user.first_name} ${user.last_name}` : user.email)}
                        </span>
                    }
                </Avatar>
            }
            <Grid style={{ width: type === "messageItems" ? '35%' : '90%' }} className={classnames(classes.chatBubble, (type === "messageItems" && message.sender_id !== userId) ? classes.whitebg : null, message.sender_id !== userId ? classes.leftChat : classes.rightChat)}>
                <Typography className={classes.breakAll}>
                    {message.message}
                </Typography>
                <Typography align="right" className={classes.timeTxt}>
                    {(messageDate === presentDate) ? moment(message.created_date).calendar() : moment(message.created_date).format('MMM DD, YYYY HH:mm')}
                </Typography>
            </Grid>

        </Grid>
    );

};

ConversationMessage.propTypes = {
    classes: PropTypes.object,
    message: PropTypes.object,
    type: PropTypes.string,
    userId: PropTypes.number
    // selectConversation: PropTypes.func
};

export default withStyles((theme) => ({
    ...ConversationStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ConversationMessage);