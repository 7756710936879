import React, { Fragment } from 'react';
import {
    withStyles, Dialog, DialogTitle, DialogContent, Grid, Typography, IconButton, Slide,
    Table, TableHead, TableBody, TableRow, TableCell, TextField
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Styles from '../../layouts/Styles.jsx';
import CurationDataPreviewStyles from './CurationDataPreviewStyles.jsx';
import { Close } from '@material-ui/icons';
import { appConstants } from '../../constants/appConstants';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { getValue } from '../../helpers/appHelpers';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const Transition = React.forwardRef(Object.assign((props, ref) => <Slide direction="up" ref={ref} {...props} />), { displayName: 'Transition' });
const CurationDataPreview = (props) => {
    const { classes, open, title, previewData, onClose, ruleType, learningAction, learningEdit, theme } = props;

    const getHeaders = () => {
        return ruleType === "advanced" ? appConstants.curationAdvanceHeaders : appConstants.curationHeaders;
    };

    const renderImpactPercentage = (header, data, index) => {
        return (
            (header.name === "Impact Percentage" && ruleType === "advanced") ?
                data[header.value].toFixed(2) :
                <Fragment>
                    <IconButton style={{ padding: 2, color: theme.palette.chartColors.valid }} onClick={() => learningAction({ ...data, "rule_name": previewData[header.value] }, index, true)}>
                        <CheckCircleIcon />
                    </IconButton>
                    <IconButton style={{ padding: 2, color: theme.palette.chartColors.inValid }} onClick={() => learningAction({ ...data, "rule_name": previewData[header.value] }, index, false)}>
                        <CancelIcon />
                    </IconButton>
                </Fragment>
        );
    };

    const renderValue = (header, previewData, data) => {
        let value;
        if (header.value === "rule_name") {
            value = previewData[header.value];
        }
        else if (header.value === "impact_percentage") {
            value = getValue(data[header.value]);
        } else {
            value = data[header.value];
        }
        return value;
    };

    const download = (previewData) => {
        const curate_statistical = {
            'rule_name': previewData['rule_name'],
            'statistical_result': previewData['statistical_result']
        };
        const blob = new Blob([JSON.stringify(curate_statistical)], { type: 'text/json' });
        const a = document.createElement('a');
        a.download = `${previewData['rule_name']}.json`;
        a.href = window.URL.createObjectURL(blob);
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true
        });
        a.dispatchEvent(clickEvt);
        a.remove();
    };

    return (
        <Dialog open={open}
            maxWidth="md"
            TransitionComponent={Transition}
            className={classes.queryFilterDialog}
            onClose={onClose}
        >
            <DialogTitle>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h5">
                            {title}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ToolTipComponent title="Download" arrow>
                            <IconButton className={classes.padding6} onClick={() => download(previewData)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 21 21">
                                    <g id="download_4_" data-name="download (4)" transform="translate(-1.5 -1.5)">
                                        <path id="Path_14400" data-name="Path 14400" d="M22,23.714v2.857A1.43,1.43,0,0,1,20.571,28H3.428A1.43,1.43,0,0,1,2,26.572V23.714a.714.714,0,0,1,1.428,0v2.857H20.571V23.714a.714.714,0,0,1,1.429,0Z" transform="translate(0 -6)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                        <path id="Path_14401" data-name="Path 14401" d="M8.21,12.5a.714.714,0,1,1,1.01-1.01L13,15.275V2.714a.714.714,0,1,1,1.428,0V15.275l3.781-3.78a.714.714,0,1,1,1.01,1.01l-5,5a.715.715,0,0,1-1.011,0Z" transform="translate(-1.715)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                    </g>
                                </svg>
                            </IconButton>
                        </ToolTipComponent>
                        <IconButton className={classes.nopadding} onClick={() => onClose()}>
                        <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                getHeaders().map((header, index) =>
                                    <TableCell className={classes.tableHeadCell} key={`header_${index}`}>
                                        {header.name}
                                    </TableCell>
                                )
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            previewData && previewData.statistical_result.map((data, index) =>
                                <TableRow key={`row_${index}`}>
                                    {
                                        getHeaders().map((header, headerindex) =>
                                            <TableCell className={classes.tableCell} key={`header_${headerindex}`}>
                                                {
                                                    ((header.name === "Curate Value" && ruleType === "advanced") || header.name === "Actions" || (header.name === "Impact Percentage" && ruleType === "advanced")) ?
                                                        <Grid style={{ display: 'flex' }}>
                                                            {
                                                                (header.name === "Curate Value" && ruleType === "advanced") ?
                                                                    <TextField
                                                                        onChange={(event) => learningEdit(index, header.value, event.target.value)}
                                                                        value={data[header.value] ? data[header.value] : ""}
                                                                    /> :
                                                                    renderImpactPercentage(header, data, index)
                                                            }
                                                        </Grid> :
                                                        <Grid style={{ overflowWrap: 'break-word' }}>
                                                            {renderValue(header, previewData, data)}
                                                        </Grid>
                                                }
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </DialogContent>
        </Dialog>
    );
};

CurationDataPreview.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.string,
    open: PropTypes.bool,
    previewData: PropTypes.object,
    onClose: PropTypes.func,
    ruleType: PropTypes.string,
    learningAction: PropTypes.func,
    learningEdit: PropTypes.func,
    theme: PropTypes.object

};

export default withStyles((theme) => ({
    ...CurationDataPreviewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(CurationDataPreview);