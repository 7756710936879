import React, { useCallback, useEffect, useState } from 'react';
import { Grid, withStyles, Typography, MenuItem, Checkbox } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Styles from '../../layouts/Styles.jsx';
import DriftDashBoardStyle from './DriftDashBoardStyle.jsx';
import TextBox from '../TextBox/TextBox.jsx';

const AlertRuleAccordianHeader = (props) => {
    const { attributeId, data, classes, theme, selectedChartType, onChartTypeChange, onInputValueChange } = props;
    const [inputLimit, setInputLimit] = useState({
        lowerbound: -1,
        upperbound: 1

    });
    const [inputPercentage, setInputPercentage] = useState({
        lowerpercentage: -10,
        upperpercentage: 10
    });

    const [inputValue, setInputValue] = useState({
        lowervalue: -100,
        uppervalue: 100
    });
    const chartTypeFilter = ['Percentage Change', 'Z Score', 'Value'];

    const updateInputLimit = useCallback((property, value) => {
        if (data[property] !== value && onInputValueChange) {
            onInputValueChange(property, value);
        }
    }, [data, onInputValueChange]);

    const loadValues = useCallback((data) => {
        setInputLimit({
            lowerbound: data.lowerbound !== null ? data.lowerbound : -1,
            upperbound: data.upperbound !== null ? data.upperbound : 1
        });

        setInputPercentage({
            lowerpercentage: data.lowerpercentage !== null ? data.lowerpercentage : -10,
            upperpercentage: data.upperpercentage !== null ? data.upperpercentage : 10
        });

        setInputValue({
            lowervalue: data.lowervalue !== null ? data.lowervalue : -100,
            uppervalue: data.uppervalue !== null ? data.uppervalue : 100
        });
    }, []);

    useEffect(() => {
        if (attributeId && data) {
            loadValues(data);
        }
    }, [attributeId, data, loadValues]);

    const DropDownIcon = (iconProps) => {
        return (
            <svg {...iconProps} className={classes.DropDownIcon} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" top="" width="12px" height="18px" viewBox="0 0 451.847 451.847">
                <g>
                    <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" fill={theme.palette.grey.dark} />
                </g>
            </svg>
        );
    };

    return (
        <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
            <Grid item>
                <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                    <Grid item>
                        <Grid container direction="row" alignItems="center" justify="space-between" wrap="nowrap">
                            <Typography component="p">
                                {'Limit'}
                            </Typography>
                            <TextBox placeholder="Low"
                                name="lowerbound"
                                id="lowerbound"
                                value={inputLimit.lowerbound !== null ? inputLimit.lowerbound : ''}
                                onChange={(event) => setInputLimit({ ...inputLimit, [event.target.name]: event.target.value })}
                                onBlur={() => updateInputLimit('lowerbound', inputLimit.lowerbound)}
                                className={classNames(classes.limitInput, classes.inputOutline)} />

                            <TextBox placeholder="High"
                                name="upperbound"
                                id="upperbound"
                                value={inputLimit.upperbound !== null ? inputLimit.upperbound : ''}
                                onChange={(event) => setInputLimit({ ...inputLimit, [event.target.name]: event.target.value })}
                                onBlur={() => updateInputLimit('upperbound', inputLimit.upperbound)}
                                className={classNames(classes.limitInput, classes.inputOutline)} />

                            <Checkbox
                                defaultChecked
                                disabled
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                    <Grid item style={{ marginLeft: 60 }}>
                        <Grid container direction="row" alignItems="center" justify="space-between" wrap="nowrap">
                            <Typography component="p">
                                {'Percentage'}
                            </Typography>
                            <TextBox placeholder="Low"
                                name="lowerpercentage"
                                id="lowerpercentage"
                                value={inputPercentage.lowerpercentage !== null ? inputPercentage.lowerpercentage : ''}
                                onChange={(event) => setInputPercentage({ ...inputPercentage, [event.target.name]: event.target.value })}
                                onBlur={() => updateInputLimit('lowerpercentage', inputPercentage.lowerpercentage)}
                                className={classNames(classes.limitInput, classes.inputOutline)} />

                            <TextBox placeholder="High"
                                name="upperpercentage"
                                id="upperpercentage"
                                value={inputPercentage.upperpercentage !== null ? inputPercentage.upperpercentage : ''}
                                onChange={(event) => setInputPercentage({ ...inputPercentage, [event.target.name]: event.target.value })}
                                onBlur={() => updateInputLimit('upperpercentage', inputPercentage.upperpercentage)}
                                className={classNames(classes.limitInput, classes.inputOutline)} />

                            <Checkbox
                                defaultChecked
                                disabled
                                color="primary"
                            />
                        </Grid>
                    </Grid>

                    <Grid item style={{ marginLeft: 60 }}>
                        <Grid container direction="row" alignItems="center" justify="space-between" wrap="nowrap">
                            <Typography component="p">
                                {'Values'}
                            </Typography>
                            <TextBox placeholder="Low"
                                name="lowervalue"
                                id="lowervalue"
                                value={inputValue.lowervalue !== null ? inputValue.lowervalue : ''}
                                onChange={(event) => setInputValue({ ...inputValue, [event.target.name]: event.target.value })}
                                onBlur={() => updateInputLimit('lowervalue', inputValue.lowervalue)}
                                className={classNames(classes.limitInput, classes.inputOutline)} />

                            <TextBox placeholder="High"
                                name="uppervalue"
                                id="uppervalue"
                                value={inputValue.uppervalue !== null ? inputValue.uppervalue : ''}
                                onChange={(event) => setInputValue({ ...inputValue, [event.target.name]: event.target.value })}
                                onBlur={() => updateInputLimit('uppervalue', inputValue.uppervalue)}
                                className={classNames(classes.limitInput, classes.inputOutline)} />

                            <Checkbox
                                defaultChecked
                                disabled
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item>
                <TextBox
                    className={classes.inlinetxt}
                    onChange={(event) => setTimeout(() => onChartTypeChange(event.target.value), 10)}
                    value={selectedChartType}
                    select
                    SelectProps={
                        {
                            MenuProps: {
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "center"
                                },
                                transformOrigin: {
                                    vertical: "top",
                                    horizontal: "center"
                                },
                                getContentAnchorEl: null
                            },
                            IconComponent: (iconProps) => DropDownIcon(iconProps)
                        }
                    }
                >
                    {
                        chartTypeFilter.map((filterOption, index) => (
                            <MenuItem key={`filterBy_${index}`} value={filterOption} className={classes.menuItem}>
                                {filterOption}
                            </MenuItem>
                        ))
                    }
                </TextBox>
            </Grid>
        </Grid>
    );
};

AlertRuleAccordianHeader.propTypes = {
    attributeId: PropTypes.number,
    data: PropTypes.object,
    classes: PropTypes.object,
    theme: PropTypes.object,
    selectedChartType: PropTypes.string,
    onChartTypeChange: PropTypes.func,
    onInputValueChange: PropTypes.func
};

export default withStyles((theme) => ({
    ...DriftDashBoardStyle(theme),
    ...Styles(theme)
}), { withTheme: true })(AlertRuleAccordianHeader);