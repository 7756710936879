const PropertiesListViewStyles = (theme) => ({
    propertiesPanel: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey.extraLight}`,
        boxShadow: '0px 0px 15px #0000000F'
    },
    ruleBuilderModalContainer: {
        padding: 16,
        boxShadow: '0px 0px 15px #0000000F',
        backgroundColor: theme.palette.common.white,
        maxWidth: 1000
    },
    actionButtons: {
        padding: '2px 10px !important',
        marginRight: 5,
        marginTop: 10
    },
    iconButton: {
        padding: 5,
        '& svg': {
            margin: '0 !important'
        }
    },
    propertyTabContainer: {
        overflowY: 'auto',
        overflowX: 'hidden',
        height: 'calc(100vh - 292px)',
        position: 'relative',
        margin: 15,
        '& .MuiFormControl-marginNormal': {
            marginTop: 5,
            marginBottom: 5
        }
    },
    propertyListPageTable: {
        tableLayout: 'auto',
        '& th': {
            whiteSpace: 'nowrap',
            maxWidth: '900px !important'
        },
        '& .MuiInputBase-input': {
            maxWidth: 890
        },
        '& td': {
            maxWidth: '900px !important'
        },
        border: '1px solid #EDF2F4',
        '& .MuiTableCell-head': {
            padding: '9px 9px !important'
        }
    },
    propertyListPageTableBody: {
        '& .MuiTableCell-root': {
            padding: '0px 9px'
        },
        '& .MuiTableRow-root': {
            height: 40
        },
        '& .MuiTableRow-root:last-child td.MuiTableCell-root': {
            borderBottom: 'none !important'
        }
    },
    propListPageTableBody: {
        '& .MuiTableCell-root': {
            padding: '6px 9px'
        },
        '& .MuiTableRow-root': {
            height: 40
        },
        '& .MuiTableRow-root:last-child td.MuiTableCell-root': {
            borderBottom: 'none !important'
        }
    },
    listViewTableContainer: {
        height: 'calc(100vh - 350px)',
        overflow: 'auto'
    },
    bgGrey: {
        '& .MuiInput-root': {
            backgroundColor: theme.palette.grey.exteremeLight,
            '& .MuiInputBase-input': {
                textAlign: "center",
                paddingLeft: '6px',
                paddingRight: '6px'
            }
        }
    },
    hoverBgGrey: {
        '& .MuiInput-root:hover': {
            backgroundColor: theme.palette.grey.exteremeLight
        }
    },
    Rangehyphen: {
        padding: '0 5px'
    },
    tableChips: {
        '& .MuiChip-label': {
            // maxWidth: 120
        }
    },
    paddingCancelButton: {
        padding: '2px 22px !important'
    },
    masterInputContainer: {
        minWidth: 146,
        '& .MuiAutocomplete-endAdornment': {
            display: 'none'
        },
        '& .MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
            paddingRight: '0 !important',
            '& input': {
                padding: '6px 0px !important',
                fontFamily: theme.palette.textFont
            }
        },
        '& .MuiAutocomplete-option': {
            width: 'max-content !important'
        }
    },
    FieldInputContainer: {
        minWidth: 120,
        '& .MuiAutocomplete-endAdornment': {
            display: 'none'
        },
        '& .MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot, .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
            paddingRight: '0px !important'
        }
    },
    minWidth100: {
        minWidth: '120px'
    },
    infoTable: {
        textTransform: 'capitalize'
    },
    curateAttributeTitle: {
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
    },
    valueAttributeHeader: {
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    hoverBgAutoComplete: {
        '& .MuiInput-root:hover': {
            backgroundColor: theme.palette.grey.exteremeLight
        }
    },
    fieldPanelContainer: {
        '& .MuiInputBase-root input': {
            height: '16px !important'
        }
    },
    rulePanelChipContainer: {
        '& .chipitem': {
            width: '100%'
        },
        '& .MuiChip-root': {
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        '& .chipList': {
            maxWidth: "calc(50% - 24px)"
        }
    },
    curatePanelChipContainer: {
        '& .chipitem': {
            width: '100%'
        },
        '& .MuiChip-root': {
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden'
        },
        '& .chipList': {
            maxWidth: "calc(500px - 24px)"
        }
    },
    driftChip: {
        '& .MuiChip-root': {
            maxWidth: '100%'
        }
    },
    tableInput: {
        minWidth: 74
    }
});

export default PropertiesListViewStyles;