import React, { useState, useCallback, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography, MenuItem, AccordionSummary, Accordion, AccordionDetails, FormGroup, FormControlLabel, Switch } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { appConstants } from '../../constants/appConstants';
import Disk from './Connections/Disk.jsx';
import Hadoop from './Connections/Hadoop.jsx';
import MySQL from './Connections/MySQL.jsx';
import MSSQL from './Connections/MSSQL.jsx';
import PostgreSQL from './Connections/PostgreSQL.jsx';
import Snowflake from './Connections/Snowflake.jsx';
import DBFS from './Connections/DBFS.jsx';
import { getStorage, updateStorage } from '../../actions/settingActions.js';
import { useDispatch, useSelector } from 'react-redux';
import CheckboxComponent from '../ChecboxComponent/CheckboxComponent.jsx';
import DeltaLake from './Connections/DeltaLake.jsx';
import { sortTable } from '../../helpers/appHelpers.js';
// import Loader from '../Loaders/Loader.jsx';

const Storage = (props) => {
    const { classes, organizationId, isOrganization, isSystemLicense } = props;
    const [config, setConfig] = useState({
        inboundType: appConstants.inboundConnectors[0],
        outboundType: appConstants.outboundConnectors[0],
        inbound: {},
        outbound: {},
        'semantic_model': {},
        systemConfiguration: false,
        storageMethod: appConstants.inboundStorageMethods[0]
    });
    const moduleConfig = useSelector(({ setting }) => setting.config);
    const [isSameOutbound, setIsSameOutbound] = useState(false);
    const [pushReportKey, setPushReportKey] = useState(false);
    const [, setHasOutbound] = useState(false);
    const [selectedPanel, setSelectedPanel] = useState('');
    const [inputLoading, setInputLoading] = useState(false);
    const [outputLoading, setOutputLoading] = useState(false);
    const [semanticModelLoading, setSemanticModelLoading] = useState(false);
    const connections = useSelector(({ datasource }) => datasource.moduleConnectionTypes);
    const [outputConnection, setOutputConnection] = useState([]);
    const [inputConnection, setInputConnection] = useState([]);
    const [selectedProfileRulesPanel, setSelectedProfileRulesPanel] = useState('');
    const settingConfig = useSelector(({ setting }) => setting.config);
    // const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const loadProps = useCallback(() => {
        const configInboundConnectors = settingConfig.inbound_connectors ? settingConfig.inbound_connectors : [];
        const configOutboundConnectors = settingConfig.outbound_connectors ? settingConfig.outbound_connectors : [];
        const inputConnection = connections.filter((connection) => configInboundConnectors.indexOf(connection.id) !== -1).map((connection) => (connection.name.toLowerCase() === "file" ? "Disk" : connection.name));
        const outputConnection = connections.filter((connection) => configOutboundConnectors.indexOf(connection.id) !== -1).map((connection) => (connection.name.toLowerCase() === "file" ? "Disk" : connection.name));
        if (outputConnection.length === 0) {
            outputConnection.push("Disk");
            inputConnection.push("Disk");
        }
        setOutputConnection([...outputConnection]);
        setInputConnection([...inputConnection]);
    }, [connections, settingConfig.inbound_connectors, settingConfig.outbound_connectors]);

    const getStorageConfig = useCallback(() => {
        dispatch(getStorage(organizationId)).then((response) => {
            if (response) {
                setConfig({
                    ...response,
                    'semantic_model': response.semantic_model ? { ...response.semantic_model } : {},
                    storageMethod: response && response.storageMethod ? response.storageMethod : appConstants.inboundStorageMethods[0]
                });
                setIsSameOutbound(response.isSameOutbound ? response.isSameOutbound : false);
                setPushReportKey(response.pushReportKey ? response.pushReportKey : false);
                if (response.inboundType) {
                    const hasOutput = appConstants.outboundConnectors.includes(response.inboundType);
                    setHasOutbound(hasOutput);
                }
            }
        });
    }, [dispatch, organizationId]);

    useEffect(() => {
        loadProps();
        getStorageConfig();
    }, [getStorageConfig, loadProps, organizationId]);

    const onChange = (type, property, value) => {
        // setIsLoading(true);
        if (type !== "") {
            config[type][property] = value;
        } else {
            config[property] = value;
            if (property === "inboundType" || property === "outboundType") {
                config[property === "inboundType" ? "inbound" : "outbound"] = {};
            }
        }
        if (property === 'inboundType') {
            const hasOutput = appConstants.outboundConnectors.includes(value);
            setHasOutbound(hasOutput);
            if (hasOutput) {
                setIsSameOutbound(true);
            }
        }
        setConfig({ ...config });
        if (property === "systemConfiguration") {
            dispatch(updateStorage(config, organizationId));
        }
    };

    const onSaveConfig = (type) => {
        if (type === "inbound") {
            setInputLoading(true);
        } else if (type === "outbound") {
            setOutputLoading(true);
        } else {
            setSemanticModelLoading(true);
        }
        config.isSameOutbound = isSameOutbound;
        config.pushReportKey = pushReportKey;
        if (isSameOutbound) {
            config.outboundType = config.inboundType;
            config.outbound = { ...config.inbound };
        }
        dispatch(updateStorage(config, organizationId)).then(() => {
            if (type === "inbound") {
                setInputLoading(false);
            } else if (type === "outbound") {
                setOutputLoading(false);
            } else {
                setSemanticModelLoading(false);
            }
        });
    };

    const toggleExpansionPanel = (panel) => (event, isExpanded) => {
        isExpanded = isExpanded ? panel : false;
        setSelectedPanel(isExpanded);
    };

    const profileRulesPanel = (panel) => (event, isExpanded) => {
        isExpanded = isExpanded ? panel : false;
        setSelectedProfileRulesPanel(isExpanded);
    };

    return (
        <ValidatorForm form="My profile" onSubmit={() => { }}>
            <Grid item xs={12}>
                {
                    isOrganization &&
                    <FormGroup row style={{ marginLeft: 15, marginBottom: 16 }}>
                        <FormControlLabel
                            className={classes.storageSysConfig}
                            control={
                                <Switch
                                    // disabled={isLoading}
                                    color="secondary"
                                    name="systemConfiguration"
                                    checked={config.systemConfiguration && config.systemConfiguration ? config.systemConfiguration : false}
                                    onChange={(event) => onChange("", event.target.name, !config.systemConfiguration)}
                                    disabled={!isSystemLicense} />
                            }
                            label="Use System Level Configuration"
                        />
                        {/* {isLoading && <Loader size={'small'} type={'button'} classList={(classes.btnLoader, classes.displayContents)} />} */}
                    </FormGroup>
                }
                {
                    !config.systemConfiguration &&
                    <Fragment>
                        <Accordion expanded={selectedPanel === "Inbound"} onChange={toggleExpansionPanel("Inbound")} className={classes.storageAcoordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography className={classes.heading}>
                                    {'Inbound'}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={4} className={classes.marginTop15}>
                                        <TextBox
                                            label="Select Inbound Storage Type"
                                            value={config.inboundType}
                                            name="inboundType"
                                            fullWidth
                                            select
                                            onChange={(event) => onChange("", event.target.name, event.target.value)}
                                            SelectProps={
                                                {
                                                    value: config.inboundType,
                                                    onChange: (event) => onChange("", event.target.name, event.target.value),
                                                    MenuProps: {
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "center"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "center"
                                                        },
                                                        getContentAnchorEl: null
                                                    }
                                                    // IconComponent: () => DropDownIcon()
                                                }
                                            }
                                        >
                                            {
                                                sortTable(inputConnection, "asc", "name").map((data, index) =>
                                                    <MenuItem key={index} value={data}>
                                                        {data}
                                                    </MenuItem>
                                                )
                                            }
                                        </TextBox>
                                    </Grid>
                                    <Grid item xs={3} className={classes.marginTop15}>
                                        <TextBox
                                            label="Select Storage Method"
                                            value={config.storageMethod}
                                            name="storageMethod"
                                            fullWidth
                                            select
                                            onChange={(event) => onChange("", event.target.name, event.target.value)}
                                            SelectProps={
                                                {
                                                    value: config.storageMethod,
                                                    onChange: (event) => onChange("", event.target.name, event.target.value),
                                                    MenuProps: {
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "center"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "center"
                                                        },
                                                        getContentAnchorEl: null
                                                    }
                                                    // IconComponent: () => DropDownIcon()
                                                }
                                            }
                                        >
                                            {
                                                sortTable(appConstants.inboundStorageMethods, "asc", "name").map((storageType, index) =>
                                                    <MenuItem key={`storage_method_${index}`} value={storageType}>
                                                        {storageType}
                                                    </MenuItem>
                                                )
                                            }
                                        </TextBox>
                                    </Grid>
                                    <Grid item xs={2} className={classes.marginTop15}>
                                        <FormGroup row>
                                            <CheckboxComponent checked={isSameOutbound} onChange={(event) => setIsSameOutbound(!isSameOutbound)} checkboxLabel="Use outbound as same as inbound" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={2} className={classes.marginTop15}>
                                        <FormGroup row>
                                            <CheckboxComponent checked={pushReportKey} onChange={(event) => setPushReportKey(!pushReportKey)} checkboxLabel="Push Report Attribute Only" />
                                        </FormGroup>
                                    </Grid>
                                    <Grid item xs={12} className={classes.marginTop15}>
                                        {config.inboundType === "Disk" && <Disk isLoading={inputLoading} onSave={onSaveConfig} storage={config.inbound} type="inbound" onChange={onChange} />}
                                        {config.inboundType === "Hadoop" && <Hadoop isLoading={inputLoading} onSave={onSaveConfig} storage={config.inbound} type="inbound" onChange={onChange} />}
                                        {config.inboundType === "DBFS" && <DBFS isLoading={inputLoading} onSave={onSaveConfig} storage={config.inbound} type="inbound" onChange={onChange} />}
                                    </Grid>

                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={selectedProfileRulesPanel === "OutBound"} disabled={isSameOutbound} onChange={profileRulesPanel("OutBound")} className={classes.storageAcoordion}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2a-content"
                                id="panel2a-header">
                                <Typography className={classes.heading}>
                                    {"Outbound"}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container>
                                    <Grid item xs={3} className={classes.marginTop15}>
                                        <TextBox
                                            label="Select Outbound Storage Type"
                                            value={config.outboundType}
                                            name="outboundType"
                                            fullWidth
                                            select
                                            onChange={(event) => onChange("", event.target.name, event.target.value)}
                                            SelectProps={
                                                {
                                                    value: config.outboundType,
                                                    onChange: (event) => onChange("", event.target.name, event.target.value),
                                                    MenuProps: {
                                                        anchorOrigin: {
                                                            vertical: "bottom",
                                                            horizontal: "center"
                                                        },
                                                        transformOrigin: {
                                                            vertical: "top",
                                                            horizontal: "center"
                                                        },
                                                        getContentAnchorEl: null
                                                    }
                                                    // IconComponent: () => DropDownIcon()
                                                }
                                            }
                                        >
                                            {
                                                sortTable(outputConnection, "asc", "name").map((data, index) =>
                                                    <MenuItem key={index} value={data}>
                                                        {data}
                                                    </MenuItem>
                                                )
                                            }
                                        </TextBox>
                                    </Grid>
                                    <Grid item xs={12} className={classes.marginTop15}>
                                        {config.outboundType === "Hadoop" && <Hadoop isLoading={outputLoading} onSave={onSaveConfig} storage={config.outbound} type="outbound" onChange={onChange} />}
                                        {config.outboundType === "MSSQL" && <MSSQL isLoading={outputLoading} onSave={onSaveConfig} storage={config.outbound} type="outbound" onChange={onChange} />}
                                        {config.outboundType === "MySQL" && <MySQL isLoading={outputLoading} onSave={onSaveConfig} storage={config.outbound} type="outbound" onChange={onChange} />}
                                        {config.outboundType === "PostgreSQL" && <PostgreSQL isLoading={outputLoading} onSave={onSaveConfig} storage={config.outbound} type="outbound" onChange={onChange} />}
                                        {config.outboundType === "Snowflake" && <Snowflake isLoading={outputLoading} onSave={onSaveConfig} storage={config.outbound} type="outbound" onChange={onChange} />}
                                        {config.outboundType === "Delta Lake" && <DeltaLake isLoading={outputLoading} onSave={onSaveConfig} storage={config.outbound} type="outbound" onChange={onChange} />}
                                    </Grid>

                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        {
                            moduleConfig && moduleConfig.master &&
                            <Accordion expanded={selectedPanel === "Semantic Model"} onChange={toggleExpansionPanel("Semantic Model")} className={classes.storageAcoordion}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header">
                                    <Typography className={classes.heading}>
                                        {"Semantic Model"}
                                    </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <MSSQL isLoading={semanticModelLoading} onSave={onSaveConfig} storage={config.semantic_model} type="semantic_model" onChange={onChange} />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        }
                    </Fragment>
                }
            </Grid>
        </ValidatorForm >
    );
};

Storage.propTypes = {
    classes: PropTypes.object,
    organizationId: PropTypes.number,
    isOrganization: PropTypes.bool,
    isSystemLicense: PropTypes.bool
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}))(Storage);