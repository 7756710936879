import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { ValidatorForm } from "react-material-ui-form-validator";
import { Grid, Typography, withStyles, IconButton, TextField } from '@material-ui/core';
import classNames from 'classnames';

import { appConstants } from '../../constants/appConstants';
import { returnImage, numberFormatter } from '../../helpers/appHelpers';
import Styles from '../../layouts/Styles.jsx';
import SearchStyles from './SearchStyles.jsx';
import localization from '../../constants/localization';
import ToolTipComponent from '../../components/Tooltip/Tooltip.jsx';

import { getDashboards, updateSearchFilter, clearSearchResult, updateSelectedAttribute, addusersearch } from '../../actions/searchActions';
import searchIco from '../../assets/images/search.svg';
import sampleLogo from '../../assets/images/tacoma_search_logo.svg';
import Logo from '../../assets/images/headerLogo.svg';
import searchTick from '../../assets/images/searchtick.svg';

import { DatasourceIco, DatasetIco, AttributesIco, DomainsIco, CommentsIco } from './SearchIcons.jsx';
import DatasourceDetails from '../../components/DatasourceList/DatasourceDetails.jsx';
import { setCurrentPage } from '../../helpers/apiHelpers';


const SearchPage = (props) => {
	const dispatch = useDispatch();
	const { classes, theme, history, getPageTitle } = props;
	const [search, setSearch] = useState('');
	const [dashboard, setDashboard] = useState({});
	const [trendView, setTrendView] = useState(false);
	const [unPermissionSource, setUnPermissionSource] = useState(null);

	const { suggestions, frequent_searchkeys } = useSelector(({ search }) => search);
	const organizationId = useSelector(({ account }) => account.user.organization);
	const user = useSelector(({ account }) => account.user);
	// const settings = useSelector(({ setting }) => setting);
	const [filteredKeyword, setFilteredKeyword] = useState([]);
	const config = useSelector(({ setting }) => setting.config);
	const datasourceTypes = useSelector(({ datasource }) => datasource.connectionTypes);
	const isTableauConnection = datasourceTypes.some((data) => data.name === "Tableau" && config.input_connectors && config.input_connectors.includes(data.id));

	/**
	 *
	 */
	useEffect(() => {
		setFilteredKeyword([...frequent_searchkeys]);
	}, [frequent_searchkeys]);

	useEffect(() => {
		getPageTitle('Search and Discover Data Assets');
	});

	/**
	 *
	 */
	const loadDashboard = useCallback(() => {
		dispatch(getDashboards()).then((response) => {
			if (response) {
				setDashboard(response);
			}
		});
	}, [dispatch]);

	/**
	 *
	 * @param {*} value
	 */
	const onChangeSearch = (event) => {
		const getKeys = [...frequent_searchkeys];
		const newArr = getKeys.filter((keys, index) => {
			return keys.toLowerCase().includes(event.target.value.toLowerCase());
		});
		setFilteredKeyword([...newArr]);
		setSearch(event.target.value);
	};

	/**
	 *
	 */
	const handleSubmit = useCallback((value) => {
		let searchVal = search;
		if (value) {
			searchVal = value;
		}

		if (searchVal && searchVal.length > 0) {
			const searchFilter = {
				search: searchVal,
				tabIndex: 0,
				viewType: 'All',
				isSearchCall: true
			};
			dispatch(updateSearchFilter(searchFilter));
			dispatch(addusersearch({ 'search': searchVal, 'page': 'search and discovery' }));
			history.push({ pathname: `/search/list`, state: { search: searchVal } });
		}
	}, [dispatch, history, search]);

	/**
	 *
	 */
	useEffect(() => {
		setCurrentPage('search');
		loadDashboard();
	}, [loadDashboard]);

	/**
	 *
	 * @returns
	 */
	const getSearchLogo = () => {
		if (organizationId === 15) {
			return sampleLogo;
		}
		else if (theme && theme.palette.logo) {
			return returnImage(appConstants.API_BASE_URL, theme.palette.logo);
		}
		return Logo;

	};

	/**
	 *
	 * @param {*} tabIndex
	 * @param {*} viewType
	 */
	const searchByViewType = (tabIndex, viewType) => {
		dispatch(addusersearch({ 'search': viewType, 'page': 'search page' }));
		dispatch(clearSearchResult());
		if (viewType) {
			const searchFilter = {
				search,
				tabIndex: tabIndex,
				viewType: viewType,
				offset: 0
			};
			dispatch(updateSearchFilter(searchFilter));
			history.push({ pathname: `/search/list`, state: { search: search } });
		}
	};

	const getIcons = (type) => {
		switch (type) {
			case 'datasource':
				return <DatasourceIco theme={theme} />;
			case 'dataset':
				return <DatasetIco theme={theme} />;
			case 'attribute':
				return <AttributesIco theme={theme} />;
			case 'domain':
				return <DomainsIco theme={theme} />;
			case 'comments':
				return <CommentsIco theme={theme} />;
			default:
				return <DatasourceIco theme={theme} />;
		}
	};

	/**
	 *
	 * @param {*} data
	 */
	const redirectToSelectedItem = (data) => {
		dispatch(addusersearch({ 'search': data.name, 'page': 'search page' }));
		if (data.type !== "domain") {
			const userId = user ? user.id : 0;
			const filterUser = data.users.find((data) => data.user_id === userId);
			if (!filterUser) {
				data.sharing_info = data.users.map((userList) => { return { ...userList, is_request_accepted: true }; });
				data.id = data.source_id;
				data.loadDatasource = Boolean(data.dataset_id);
				setUnPermissionSource({ ...data });
				return false;
			}
		}
		if (data) {
			switch (data.type) {
				case 'datasource':
					history.push(`/catalog/${data.redirect_id}`);
					break;
				case 'dataset':
					history.push(`/dataset/${data.redirect_id}`);
					break;
				case 'attribute':
					dispatch(updateSelectedAttribute(data.name));
					history.push(`/dataset/${data.redirect_id}`);
					break;
				case 'domain':
					history.push({ pathname: `/domain/${data.redirect_id}`, state: { domain: data } });
					break;
				case 'comments':
					if (data.params.dataset_id) {
						history.push(`/dataset/${data.redirect_id}`);
					} else {
						history.push(`/catalog/${data.redirect_id}`);
					}
					break;
				default:
					break;
			}
		}
	};

	return (
		<Grid className={classNames(classes.container, classes.width100)}>
			{
				unPermissionSource ?
					<Grid className={classNames(classes.container, classes.width100)}>
						<DatasourceDetails breadcrumb={[{ name: 'Search', path: '/search' }, { name: unPermissionSource.name, path: '' }]} redirect={() => setUnPermissionSource(null)} source={unPermissionSource} history={history} />
					</Grid> :
					<Grid container className={classes.contNew} justify="center" alignItems="center" direction="row">
						<Grid container item xs={8} align="center" spacing={2}>
							<Grid item xs={3} align="left">
								<Grid className={classes.logocontainer}>
									<img className={classes.searchLogo} src={getSearchLogo()} alt="logo" />
									<img src={searchTick} className={classes.searchtick} alt="Search" />
								</Grid>
							</Grid>
							<Grid item xs={9} align="left">
								<Grid container item xs={10} align="center" class={classes.textCenter}>
									<Typography class={classes.header} component="h1">
										{localization.search.titile}
									</Typography>
									<Typography class={classes.description} component="body">
										{localization.search.description}
									</Typography>
									<ValidatorForm
										name="searchform"
										onSubmit={() => handleSubmit()}
										autoComplete={"off"}
										className={search ? classes.searchclear : null}
									>
										<Grid item className={classNames(classes.cardTitle, classes.popIndex, classes.searchicon)} >
											<TextField id="outlined-search" autocomplete="off" placeholder="Search field" type="text" onChange={(value) => onChangeSearch(value)} className={classes.newInput} onFocus={() => setTrendView(true)} />
											<img src={searchIco} alt="" style={{ display: "none" }} onClick={() => handleSubmit()} width={35} />
											{
												!search ?
													trendView && suggestions.length > 0 &&
													<Grid container className={classes.trendBox}>
														<Typography variant="h6" gutterBottom style={{ color: theme.palette.grey.light }}>
															Trending Searches
														</Typography>
														{
															suggestions && suggestions.map((data, index) =>
																<Grid container key={index} className={classes.valignCenter} onClick={() => redirectToSelectedItem(data)}>
																	{getIcons(data.type)}
																	<Typography variant="h6" className={classes.paddingLeft10}>
																		{data.name}
																	</Typography>
																</Grid>
															)
														}
													</Grid>
													:
													filteredKeyword.length > 0 &&
													<Grid container className={classes.trendBox}>
														{
															filteredKeyword && filteredKeyword.slice(0, 5).map((data, index) =>
																<Grid container key={index} className={classes.valignCenter} onClick={() => handleSubmit(data)}>
																	<Typography variant="h6" className={classes.paddingLeft10}>
																		{data}
																	</Typography>
																</Grid>
															)
														}
													</Grid>
											}
											{
												trendView &&
												<Grid className={classes.backDrop} onClick={() => setTrendView(false)} />
											}

										</Grid>


									</ValidatorForm>
								</Grid>
							</Grid>
						</Grid>
						<Grid container xs={8} align="center" className={classes.cardList} spacing={5}>
							<Grid item xs={4} align="center">
								<Grid container className={classes.cardTile} spacing={2} onClick={() => searchByViewType(1, 'Datasources')}>
									<Grid item xs={6} className={classes.textLeft}>
										<Typography className={classNames(classes.cardTitle, classes.fontWeight600)}>
											{dashboard && dashboard.datasources ? numberFormatter(dashboard.datasources) : 0}
										</Typography>
										<Typography className={classes.cardTxt}>
											Datasources
										</Typography>
									</Grid>
									<Grid item xs={6} className={classes.textRight}>
										<ToolTipComponent title={"Datasources"} arrow className={classes.icoBtn}>
											<IconButton className={classNames(classes.icoBtn)}>
												<svg xmlns="http://www.w3.org/2000/svg" width="24.845" height="24.845" viewBox="0 0 11.289 15.394" fill={theme.palette.primary.main}>
													<path id="database_17_" data-name="database (17)" d="M15.645,17.394c2.805,0,5.645-.793,5.645-2.309V4.309C21.289,2.793,18.45,2,15.645,2S10,2.793,10,4.309V15.085C10,16.6,12.839,17.394,15.645,17.394Zm0-14.368c2.865,0,4.618.831,4.618,1.283s-1.753,1.283-4.618,1.283-4.618-.831-4.618-1.283S12.78,3.026,15.645,3.026ZM11.026,5.695a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924V7.9c0,.452-1.753,1.283-4.618,1.283S11.026,8.353,11.026,7.9Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Z" transform="translate(-10 -2)" fill={theme.palette.primary.main} />
												</svg>
											</IconButton>
										</ToolTipComponent>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={4} align="center">
								<Grid container className={classes.cardTile} spacing={2} onClick={() => searchByViewType(2, 'Datasets')}>
									<Grid item xs={6} className={classes.textLeft}>
										<Typography className={classNames(classes.cardTitle, classes.fontWeight600)}>
											{dashboard && dashboard.datasets ? numberFormatter(dashboard.datasets) : 0}
										</Typography>
										<Typography className={classes.cardTxt}>
											Datasets
										</Typography>
									</Grid>
									<Grid item xs={6} className={classes.textRight}>
										<ToolTipComponent title={"Datasets"} arrow className={classes.icoBtn}>
											<IconButton className={classNames(classes.icoBtn)}>
												<svg xmlns="http://www.w3.org/2000/svg" width="24.845" height="24.845" viewBox="0 0 17.751 16.711">
													<path id="table" d="M0,15V31.711H17.751V15Zm16.711,1.04v2.115H1.04V16.04ZM9.4,22.315h3.12V24.4H9.4ZM8.355,24.4H5.235v-2.08h3.12Zm1.04-3.12V19.2h3.12v2.08Zm-1.04,0H5.235V19.2h3.12Zm-4.16,0H1.04V19.2H4.2Zm0,1.04V24.4H1.04v-2.08Zm0,3.12v2.08H1.04v-2.08Zm1.04,0h3.12v2.08H5.235Zm3.12,3.12v2.115H5.235V28.556Zm1.04,0h3.12v2.115H9.4Zm0-1.04v-2.08h3.12v2.08Zm4.16-2.08h3.155v2.08H13.556Zm0-1.04v-2.08h3.155V24.4Zm0-3.12V19.2h3.155v2.08ZM1.04,28.556H4.2v2.115H1.04Zm12.516,2.115V28.556h3.155v2.115Z" transform="translate(0 -15)" fill={theme.palette.primary.main} />
												</svg>
											</IconButton>
										</ToolTipComponent>
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={4} align="center">
								<Grid container className={classes.cardTile} spacing={2} onClick={() => searchByViewType(3, 'Attributes')}>
									<Grid item xs={6} className={classes.textLeft}>
										<Typography className={classNames(classes.cardTitle, classes.fontWeight600)}>
											{dashboard && dashboard.attributes ? numberFormatter(dashboard.attributes) : 0}
										</Typography>
										<Typography className={classes.cardTxt}>
											Attributes
										</Typography>
									</Grid>
									<Grid item xs={6} className={classes.textRight}>
										<ToolTipComponent title={"Attributes"} arrow className={classes.icoBtn}>
											<IconButton className={classNames(classes.icoBtn)}>
												<svg xmlns="http://www.w3.org/2000/svg" width="24.845" height="24.845" viewBox="0 0 17.773 16.732">
													<path id="table" d="M0,15V31.732H17.773V15Zm16.732,1.041v2.118h-7.3l.024-2.118H4.265v2.118H1.041V16.041H16.732ZM9.407,22.325h3.124v2.083H9.407Zm0-1.041V19.2h3.124v2.083Zm-5.207,0H1.041V19.2H4.2Zm0,1.041v2.083H1.041V22.325Zm0,3.124v2.083H1.041V25.449Zm5.207,3.124h3.124v2.118H9.407Zm0-1.041V25.449h3.124v2.083Zm4.166-2.083h3.159v2.083H13.573Zm0-1.041V22.325h3.159v2.083Zm0-3.124V19.2h3.159v2.083ZM1.041,28.573H4.2v2.118H1.041Zm12.532,2.118V28.573h3.159v2.118Z" transform="translate(0 -15)" fill={theme.palette.primary.main} />
												</svg>
											</IconButton>
										</ToolTipComponent>
									</Grid>
								</Grid>
							</Grid>
							{
								isTableauConnection &&
								<Grid item xs={4} align="center">
									<Grid container className={classes.cardTile} spacing={2} onClick={() => searchByViewType(4, 'Workbooks')}>
										<Grid item xs={6} className={classes.textLeft}>
											<Typography className={classNames(classes.cardTitle, classes.fontWeight600)}>
												{dashboard && dashboard.workbooks ? numberFormatter(dashboard.workbooks) : 0}
											</Typography>
											<Typography className={classes.cardTxt}>
												Workbooks
											</Typography>
										</Grid>
										<Grid item xs={6} className={classes.textRight}>
											<ToolTipComponent title={"Workbooks"} arrow className={classes.icoBtn}>
												<IconButton className={classNames(classes.icoBtn)}>
													<svg xmlns="http://www.w3.org/2000/svg" width="18.545" height="22.236" viewBox="0 0 18.545 22.236">
														<g id="notebook" transform="translate(-43.221 0.25)">
															<path id="Path_2643" data-name="Path 2643" d="M59.493,0H45.936A1.148,1.148,0,0,0,44.79,1.146V2.752h-.682a.637.637,0,0,0,0,1.274h.682V6.491h-.682a.637.637,0,0,0,0,1.274h.682v2.466h-.682a.637.637,0,0,0,0,1.274h.682v2.466h-.682a.637.637,0,0,0,0,1.274h.682V17.71h-.682a.637.637,0,0,0,0,1.274h.682V20.59a1.148,1.148,0,0,0,1.146,1.146H59.493a2.025,2.025,0,0,0,2.023-2.023V2.023A2.025,2.025,0,0,0,59.493,0Zm.749,19.713a.75.75,0,0,1-.749.749H46.063V18.984h.682a.637.637,0,1,0,0-1.274h-.682V15.244h.682a.637.637,0,1,0,0-1.274h-.682V11.5h.682a.637.637,0,1,0,0-1.274h-.682V7.765h.682a.637.637,0,1,0,0-1.274h-.682V4.025h.682a.637.637,0,1,0,0-1.274h-.682V1.274h13.43a.75.75,0,0,1,.749.749Z" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="0.5" />
															<path id="Path_2644" data-name="Path 2644" d="M295.683,417.179h-3.038a.637.637,0,1,0,0,1.274h3.038a.637.637,0,0,0,0-1.274Z" transform="translate(-237.987 -399.469)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="0.5" />
														</g>
													</svg>

												</IconButton>
											</ToolTipComponent>
										</Grid>
									</Grid>
								</Grid>
							}
							<Grid item xs={4} align="center">
								<Grid container className={classes.cardTile} spacing={2} onClick={() => searchByViewType(6, 'Domains')}>
									<Grid item xs={6} className={classes.textLeft}>
										<Typography className={classNames(classes.cardTitle, classes.fontWeight600)}>
											{dashboard && dashboard.domains ? numberFormatter(dashboard.domains) : 0}
										</Typography>
										<Typography className={classes.cardTxt}>
											Domains
										</Typography>
									</Grid>
									<Grid item xs={6} className={classes.textRight}>
										<ToolTipComponent title={"Domains"} arrow className={classes.icoBtn}>
											<IconButton className={classNames(classes.icoBtn)}>
												<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16.068 18.554" style={{ flexShrink: '0' }}>
													<g id="rubik" transform="translate(-34.297)">
														<g id="Group_3147" data-name="Group 3147" transform="translate(34.297)">
															<path id="Path_1474" data-name="Path 1474" d="M42.331,0,34.3,4.639v9.277l8.034,4.639,8.034-4.639V4.639Zm0,1.259,2.655,1.533L42.331,4.324,39.677,2.791Zm-4.29,13.56-2.655-1.533V10.221l2.655,1.533Zm0-4.324L35.387,8.962V5.9L38.042,7.43Zm-2.11-5.542,2.655-1.533,2.655,1.533L38.587,6.486Zm5.854,12.028-2.655-1.533V12.383l2.655,1.533Zm0-4.324-2.655-1.533V8.059l2.655,1.533Zm-2.11-5.542,2.655-1.533,2.655,1.533L42.331,8.648Zm5.854,8.333-2.655,1.533V13.916l2.655-1.533Zm0-4.324-2.655,1.533V9.592l2.655-1.533Zm-2.11-6.171,2.655-1.533L48.73,4.953,46.076,6.486Zm5.854,8.333-2.655,1.533V11.754l2.655-1.533Zm0-4.324L46.621,10.5V7.43L49.275,5.9Z" transform="translate(-34.297)" fill={theme.palette.primary.main} />
														</g>
													</g>
												</svg>

											</IconButton>
										</ToolTipComponent>
									</Grid>
								</Grid>
							</Grid>
							{
								config.collaboration &&
								<Grid item xs={4} align="center">
									<Grid container className={classes.cardTile} spacing={2} onClick={() => searchByViewType(7, 'Comments')}>
										<Grid item xs={6} className={classes.textLeft}>
											<Typography className={classNames(classes.cardTitle, classes.fontWeight600)}>
												{dashboard && dashboard.comments ? numberFormatter(dashboard.comments) : 0}
											</Typography>
											<Typography className={classes.cardTxt}>
												Comments
											</Typography>
										</Grid>
										<Grid item xs={6} className={classes.textRight}>
											<ToolTipComponent title={"Comments"} arrow className={classes.icoBtn}>
												<IconButton className={classNames(classes.icoBtn)}>
													<svg id="chat-comment-oval-speech-bubble-with-text-lines" xmlns="http://www.w3.org/2000/svg" width="22.576" height="22.576" viewBox="0 0 22.576 22.576">
														<g id="_x32__26_">
															<g id="Group_161282" data-name="Group 161282">
																<path id="Path_2725" data-name="Path 2725" d="M14.816,11.994H7.761a.706.706,0,0,0,0,1.411h7.055a.706.706,0,0,0,0-1.411Zm1.411-4.233H6.35a.706.706,0,0,0,0,1.411h9.877a.706.706,0,0,0,0-1.411ZM11.288,0C5.054,0,0,4.422,0,9.877a9.454,9.454,0,0,0,4.233,7.7v5l4.945-3a12.945,12.945,0,0,0,2.11.179c6.235,0,11.288-4.422,11.288-9.877S17.523,0,11.288,0Zm0,18.343A11.417,11.417,0,0,1,8.9,18.084l-3.322,2,.044-3.272A8.18,8.18,0,0,1,1.411,9.877c0-4.675,4.422-8.466,9.877-8.466S21.165,5.2,21.165,9.877,16.743,18.343,11.288,18.343Z" fill={theme.palette.primary.main} />
															</g>
														</g>
													</svg>
												</IconButton>
											</ToolTipComponent>
										</Grid>
									</Grid>
								</Grid>
							}
						</Grid>
					</Grid>
			}
		</Grid>
	);
};

SearchPage.propTypes = {
	classes: PropTypes.object,
	theme: PropTypes.object,
	history: PropTypes.object,
	getPageTitle: PropTypes.func
};

export default withStyles((theme) => ({
	...SearchStyles(theme),
	...Styles(theme)
}), { withTheme: true })(SearchPage);