import React from 'react';
import { withStyles, Grid, ButtonGroup, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import config from './PatternQueryBuilderConfig.jsx';
import PatternQueryBuilderStyles from './PatternQueryBuilderStyles.jsx';

const PatternCombinatorSelector = (props) => {
    const { selectedConnector, not, classes, disabled, onChange, onNotToggleChange } = props;
    return (
        <Grid>
            <ButtonGroup>
                <Button className={classNames(classes.btnCombinator, not ? 'active not' : '')}
                    onClick={() => onNotToggleChange()}>
                    {'Not'}
                </Button>
                {
                    config.connectors.map((connector, index) => (
                        <Button key={`query_connector_${index}`}
                            disabled={disabled}
                            className={classNames(classes.btnCombinator, connector.value === selectedConnector ? 'active' : '')}
                            onClick={() => onChange(connector.value)}>
                            {connector.name}
                        </Button>
                    ))
                }
            </ButtonGroup>
        </Grid>
    );
};

PatternCombinatorSelector.propTypes = {
    classes: PropTypes.object,
    selectedConnector: PropTypes.string,
    not: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onNotToggleChange: PropTypes.func
};

export default withStyles(PatternQueryBuilderStyles)(PatternCombinatorSelector);