import React, { useCallback, useEffect, useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import moment from 'moment-timezone';
import { getValue } from '../../helpers/appHelpers.js';
import Loader from '../Loaders/Loader.jsx';

const BarAndLineChart = (props) => {
    const { classes, analysisData, theme, isLoading, config } = props;
    const [filter, setFilter] = useState('Attribute');
    const [chartData, setChartData] = useState({});

    const loadProperties = useCallback(() => {
        setChartData({ ...analysisData });
    }, [analysisData]);

    useEffect(() => {
        loadProperties();
    }, [loadProperties]);

    const fillColor = useCallback(() => {
        if (filter === "Datasource") {
            return "#FF5826";
        } else if (filter === "Dataset") {
            return "#5EB5EF";
        } else if (filter === "Domain") {
            return "#9d7ffe";
        }
        return "#EC2C6F";
    }, [filter]);

    useEffect(() => {
        if (chartData && Object.keys(chartData).length !== 0) {
            let data = chartData[filter.toLowerCase()] ? chartData[filter.toLowerCase()].map((data) => {
                return {
                    ...data,
                    date: new Date(data.date)
                };
            }) : [];
            data = data.sort((a, b) => b.date - a.date);
            data = data.reverse();
            const margin = {
                top: 80,
                right: 80,
                bottom: 20,
                left: 20
            },
                height = 180;
            let width = 960 - margin.left - margin.right;

            d3.select(".bar-line-chart > svg").remove();
            // append the svg object to the body of the page
            const svg = d3.select(".bar-line-chart")
                .append("svg")
                .attr("width", "100%")
                .attr("height", height + margin.top)
                .append("g")
                .attr("width", (_, i, nodes) => {
                    width = nodes[i].parentNode.clientWidth - margin.left;
                    return width;
                })
                .attr("height", height)
                .attr("transform",
                    "translate(" + (margin.left + 30) + "," + margin.left + ")");

            // List of subgroups = header of the csv files = soil condition here
            const subgroups = ['low', 'medium', 'high'];

            // List of groups = species here = value of the first column called group -> I show them on the X axis
            const groups = data.map((elem) => moment(elem.date).format('DD MMM, h:mm:ss a'));


            // Add X axis
            const xBar = d3.scaleBand()
                .domain(groups)
                .rangeRound([0, width])
                .padding([0.2]);

            // Add Y axis
            const yBar = d3.scaleLinear()
                .domain([0, 100])
                .range([height, 0]);

            const xMin = d3.min(data, (d) => {
                return d.date;
            });

            const xMax = d3.max(data, (d) => {
                return d.date;
            });

            // // scale using range
            const x = d3
                .scaleTime()
                .domain([xMin, xMax])
                .rangeRound([0, width - 32]);
            const y = d3
                .scaleLinear()
                .domain([0, 100])
                .range([height, 0]);

            // Another scale for subgroup position?
            const xSubgroup = d3.scaleBand()
                .domain(subgroups)
                .range([0, xBar.bandwidth()])
                .padding([0.05]);

            // color palette = one color per subgroup
            const color = d3.scaleOrdinal()
                .domain(subgroups)
                .range([theme.palette.barColors.low, theme.palette.barColors.medium, theme.palette.barColors.high]);

            d3.select(".bar-line-chart >.tooltip").remove();
            const div = d3.select(".bar-line-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            svg.append("g")
                .attr("class", "x-axis")
                .attr("color", theme.palette.chartColors.axis)
                .attr("transform", `translate(0,${height})`)
                .call(d3.axisBottom(x).ticks(data.length - 1)
                    .tickFormat(d3.timeFormat("%-d %b %y")));

            svg.append("g")
                .attr("class", "y-axis")
                .attr("color", theme.palette.chartColors.axis)
                .call(d3.axisLeft(y).tickSize(-width).ticks(4));

            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
            svg.select('.y-axis path.domain').remove();

            svg.append("text")
                .attr("transform", "rotate(-90)")
                .attr("y", 0 - (margin.left + 30))
                .attr("x", 0 - (height / 2))
                .attr("dy", "1em")
                .style("text-anchor", "middle")
                .text("DQScore/Count");

            svg.append("path")
                .datum(data)
                .attr("fill", fillColor())
                .attr("fill-opacity", 0.1)
                .attr("stroke", "none")
                .attr("stroke-linejoin", "round")
                .attr("d", d3.area()
                    .x(0)
                    .y0(height)
                    .y1((d) => { return y(d.average); })
                    .curve(d3.curveMonotoneX)
                )
                .transition()
                .duration(2000)
                .attr("d", d3.area()
                    .x((d) => { return x(d.date); })
                    .y0(height)
                    .y1((d) => { return y(d.average); })
                    .curve(d3.curveMonotoneX)
                );
            svg.append("path")
                .datum(data)
                .attr("fill", "none")
                .attr("stroke", fillColor())
                .attr("stroke-width", 2)
                .attr("stroke-linejoin", "round")
                .attr("d", d3.line()
                    .x(0)
                    .y((d) => { return y(d.average); })
                    .curve(d3.curveMonotoneX)
                )
                .transition()
                .duration(2000)
                .attr("d", d3.line()
                    .x((d) => { return x(d.date); })
                    .y((d) => { return y(d.average); })
                    .curve(d3.curveMonotoneX)
                );

            svg.selectAll("myCircles")
                .data(data)
                .enter()
                .append("circle")
                .attr("fill", theme.palette.background.paper)
                .attr("stroke-width", 2)
                .attr("stroke", fillColor())
                .attr("cx", 0)
                .attr("cy", (d) => { return y(d.average); })
                .attr("r", 4)
                .on('mouseover', (d, i, nodes) => {
                    const rect = nodes[i].getBBox();
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html(`Date : ${moment(d.date).format('MMMM DD YYYY')}<br> DQ Score : ${getValue(d.average)}`)
                        .style("left", x(d.date) + "px")
                        .style("top", (rect.y - 30) + "px");
                })
                .on('mouseout', () => {
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .transition()
                .delay((d, i) => { return Number(i); })
                .duration(2000)
                .attr("cx", (d) => { return x(d.date); });

            // Show the bars
            svg.append("g")
                .selectAll("g")
                // Enter in data = loop group per group
                .data(data)
                .enter()
                .append("g")
                .attr("transform", (d) => {
                    return "translate(" + xBar(moment(d.date).format('DD MMM, h:mm:ss a')) + ",0)";
                })
                .selectAll("rect")
                .data((d) => { return subgroups.map((key) => { return { key: key, value: d[key], date: d.date }; }); })
                .enter().append("rect")
                .attr("x", (d) => { return xSubgroup(d.key); })
                .attr("y", (d) => { return yBar(d.value); })
                .attr("width", 15)
                // .attr("width", xSubgroup.bandwidth() / 2)
                .attr("height", (d) => { return height - yBar(d.value); })
                .attr("fill", (d) => { return color(d.key); })
                // .attr('width', 15)
                .attr('height', 0)
                .attr('y', height)
                .attr('height', 0)
                .on('mouseover', (d, i, p) => {
                    const x = d3.event.pageX - 100;
                    const y = d3.mouse(p[i])[1] - 25;
                    let htmlTemp = '';
                    if (d.key === 'low') {
                        htmlTemp = `Date : ${moment(d.date).format('MMM DD YYYY')} <br/> DQScore below 33% Count : ${d.value}`;
                    } else if (d.key === 'medium') {
                        htmlTemp = `Date : ${moment(d.date).format('MMM DD YYYY')} <br/> DQScore above 33% Count : ${d.value}`;
                    } else {
                        htmlTemp = `Date : ${moment(d.date).format('MMM DD YYYY')} <br/> DQScore above 75% Count : ${d.value}`;
                    }
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    div.html(htmlTemp)
                        .style("left", x + "px")
                        .style("top", y + "px");
                })
                .on('mouseout', (d) => {
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .transition()
                .duration(500)
                .delay((d, i) => {
                    return i * 100;
                })
                .attr('y', (d) => y(d.value))
                .attr('height', (d) => height - y(d.value));

            const legendPosition = 45;
            //   Interval Legends
            const legendGap = 130;
            const lines = ["High", "Medium", "Low"];

            const legendX = (width / 2) - legendGap * 1.5;
            const legendGroup = svg.append("g")
                .attr("class", "legends")
                .attr("transform", "translate(" + legendX + "," + (height + legendPosition) + ")");

            // Interval Legend rectangle
            legendGroup.selectAll(".legend-group")
                .data(lines)
                .enter()
                .append("g")
                .attr("class", "legend-group")
                .style("opacity", 1)
                .append("rect")
                .attr("class", "legend-rect")
                .attr("width", 14)
                .attr("height", 10)
                .attr('x', (_, index) => { return (index * legendGap); })
                .style("fill", (d) => theme.palette.barColors[d.toLowerCase()]);

            legendGroup.selectAll(".legend-group")
                .append("text")
                .attr("class", "legend-text")
                .attr("x", (_, index) => { return index * legendGap + 20; })
                .attr("y", 6)
                .text((line, i, nodes) => {
                    d3.select(nodes[i]).attr("class", `legend-text ${line} `);
                    return line;
                })
                .attr("alignment-baseline", "middle");


        }
    }, [chartData, fillColor, filter, theme.palette.background.paper, theme.palette.barColors, theme.palette.barColors.high, theme.palette.barColors.low, theme.palette.barColors.medium, theme.palette.chartColors.axis, theme.palette.chartColors.trending, theme.palette.chartColors.valid]);

    return (
        <ChartContainer
            title="Timeline"
            menuProperties={
                [
                    {
                        options: config.domain ? ["Attribute", "Dataset", "Datasource", "Domain"] : ["Attribute", "Dataset", "Datasource"],
                        value: filter,
                        onChange: (value) => setFilter(value)
                    }
                ]
            }
            chartData
        >
            <Grid className={classNames(classes.chartStyles, classes.barline, 'bar-line-chart', classes.relative)} >
                {isLoading && <Loader />}
            </Grid>
        </ChartContainer>
    );
};

BarAndLineChart.propTypes = {
    classes: PropTypes.object,
    analysisData: PropTypes.object,
    theme: PropTypes.object,
    isLoading: PropTypes.bool,
    config: PropTypes.object
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(BarAndLineChart);