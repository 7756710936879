import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, IconButton, Paper, Popover, TextField, Typography, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Styles from '../../../../layouts/Styles.jsx';
import DatasetStyles from '../../DatasetStyles.jsx';
import PropertiesListViewStyles from './PropertiesListViewStyles.jsx';

const RuleFilter = (props) => {
    const { classes, rules, type, anchroEl, onSelectionChange, onClose } = props;
    const [searchKey, setSearchKey] = useState('');
    const selectedRules = searchKey ? rules.filter((p) => p.name.toLowerCase().includes(searchKey.toLowerCase())) : rules;

    return (
        <Popover anchorEl={anchroEl}
            open={Boolean(anchroEl)}
            onClose={() => onClose()}
            anchorOrigin={
                {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
            transformOrigin={
                {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
        >
            <Paper elevation={2} className={classes.listContainer}>
                <Grid container direction="column" justify="flex-start" alignItems="center">
                    <Grid item>
                        <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                            <TextField
                                name="search rule"
                                value={searchKey}
                                className={classNames(classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone)}
                                onChange={(event) => setSearchKey(event.target.value)}
                                autoFocus
                            />
                            <IconButton className={classes.clearButton}
                                color="primary"
                                onClick={
                                    () => {
                                        setSearchKey('');
                                    }
                                }>
                                <CloseIcon className={classes.chipIcon} onClick={() => onClose()} />
                            </IconButton>
                        </Grid>
                    </Grid>
                    {
                        selectedRules && selectedRules.map((rule, index) => (
                            <Grid key={`rule-filter-${index}`} container direction="row" justify="flex-start" alignItems="center">
                                <Typography onClick={() => onSelectionChange(type, rule, index)}>
                                    {rule.name ? rule.name : ''}
                                </Typography>
                            </Grid>
                        ))
                    }
                </Grid>
            </Paper>
        </Popover>
    );
};

RuleFilter.defaultProps = {
    rules: [],
    anchorEl: null,
    type: 'basic'
};

RuleFilter.propTypes = {
    classes: PropTypes.object,
    rules: PropTypes.array,
    anchroEl: PropTypes.object,
    type: PropTypes.string,
    onSelectionChange: PropTypes.func,
    onClose: PropTypes.func
};

export default withStyles((theme) => ({
    ...PropertiesListViewStyles(theme),
    ...DatasetStyles(theme),
    ...Styles(theme)
}))(RuleFilter);