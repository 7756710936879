import React from 'react';
import PropTypes from "prop-types";
import {
    Grid, Popover, Table, TableBody, TableRow, TableHead,
    TableCell, Button, withStyles, Typography
} from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import classnames from 'classnames';
import TextBox from '../../TextBox/TextBox.jsx';
import Styles from '../../../layouts/Styles.jsx';
import RestApiConnectorStyles from './RestApiConnectorStyles.jsx';

const Parameters = (props) => {
    const { classes, parameterProps, onClose, handleChange, saveParameter } = props;
    return (
        <Popover
            open={parameterProps ? parameterProps.open : false}
            anchorEl={parameterProps ? parameterProps.anchorEl : null}
            onClose={() => onClose()}
            anchorOrigin={
                {
                    vertical: 'center',
                    horizontal: 'left'
                }
            }
            transformOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            }>
            <ValidatorForm>
                <Grid container className={classes.tablecontainer}>
                    <Grid item xs={12}>
                        <Typography component="h5" variant="h5" className={classes.marginBottom15}>
                            Parameters
                        </Typography>
                        <Table stickyHeader className={classes.innertable}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.tableheadbg}>
                                        <Typography>
                                            Parameter
                                        </Typography>
                                    </TableCell>

                                    <TableCell className={classes.tableheadbg}>
                                        <Typography>
                                            Value
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    parameterProps.parameters && parameterProps.parameters.length > 0 ? parameterProps.parameters.map((parameter, index) => {
                                        const validators = parameter.required === 'true' ? ['required'] : [];
                                        return (
                                            <TableRow key={'parameters_table' + index}>
                                                <TableCell>
                                                    <Typography>
                                                        {parameter.paramname}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <TextBox autoComplete="off"
                                                        placeholder="Value"
                                                        name={parameter.paramname}
                                                        value={parameter.paramvalue ? parameter.paramvalue : ''}
                                                        validators={[...validators]}
                                                        errorMessages={[`${parameter.paramname} is required`]}
                                                        onChange={(event) => handleChange(event.target.value, index)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })
                                        : null
                                }
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
                <Grid container justify="flex-end" alignItems="center" style={{ paddingTop: 20 }}>
                    <Button
                        variant="contained"
                        className={classnames(classes.actionButtons)}
                        onClick={() => saveParameter()}
                        color="primary">
                        Save
                    </Button>
                    <Button
                        className={classnames(classes.cancelButton, classes.actionButtons)}
                        variant="contained"
                        onClick={() => onClose()}>
                        Cancel
                    </Button>
                </Grid>
            </ValidatorForm>

        </Popover>
    );

};

Parameters.propTypes = {
    classes: PropTypes.object,
    parameterProps: PropTypes.object,
    onClose: PropTypes.func,
    handleChange: PropTypes.func,
    saveParameter: PropTypes.func
};

export default withStyles((theme) => ({
    ...RestApiConnectorStyles(theme),
    ...Styles(theme)
}))(Parameters);