import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    withStyles, Grid, Typography, Button, Table, TableHead, Card, Switch, FormControlLabel,
    TableBody, TableRow, TableCell, IconButton, MenuItem, TableSortLabel
} from '@material-ui/core';
import classnames from 'classnames';
import ConnectorStyles from '../ConnectorStyles.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextBox from '../../TextBox/TextBox.jsx';
import { appConstants } from '../../../constants/appConstants.js';
import Styles from '../../../layouts/Styles.jsx';
import { appConfig } from '../../../config/appConfig.js';
import { getConnectedDatasets, connectDatasource, connectDataset, getSchema, updateDatasourceProperties } from '../../../actions/connectorActions';
import { deleteDataSet } from '../../../actions/datasetActions.js';
import Loader from '../../Loaders/Loader.jsx';
import AlertDialog from '../../AlertDialog/AlertDialog.jsx';
import CheckboxComponent from '../../ChecboxComponent/CheckboxComponent.jsx';
import ConnectorXMLConfiguration from '../ConnectorXMLConfiguration.jsx';
import { AccordionTable } from "./CollapseTable.jsx";
import { sortTable } from '../../../helpers/appHelpers.js';
import moment from 'moment-timezone';
import WarningIcon from '../../Warning/WarningIcon.jsx';
import PasswordInput from '../../TextBox/PasswordInput.jsx';
import PullConfiguration from '../PullConfiguration.jsx';
import { success } from '../../../actions/baseActions';
import ToolTipComponent from '../../Tooltip/Tooltip.jsx';
import { alertActions } from '../../../constants/actionTypes/alertActionTypes';


const S3Connector = (props) => {
    const { classes, datasourceType, datasourceId, onConnect, onCancel, theme, history, copyConfig } = props;
    const dispatch = useDispatch();
    const licenseConfig = useSelector(({ setting }) => setting.config);
    const [connectionConfig, setConnectionConfig] = useState({
        accesskey: '',
        secretkey: '',
        bucketname: '',
        prefix: '',
        is_delta: false,
        'watermark_column': '',
        'is_current_day': false,
        'load_type': 'Full Load',
        is_json: false,
        scan: Boolean(licenseConfig.scan && !licenseConfig.pull)
    });
    const [pullConfiguration, setPullConfiguration] = useState(null);
    const [pushonly, setPullPush] = useState(false);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [datasets, setDatasets] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [datasource, setDatasource] = useState(null);
    const [selectedDataSet, setSelectedDataSet] = useState(null);
    const [hasChanges, setHasChanges] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [isOpenConfigurationDialog, setConfigurationDialog] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const containerRef = useRef();
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const [isOnUpdate, setIsOnUpdate] = useState(Boolean(!datasourceId));
    const [isValid, setIsValid] = useState(true);
    const [isAnySelected, setIsAnySelected] = useState(false);
    const [filterSelected, setFilterSelected] = useState(false);
    const userConfig = useSelector(({ setting }) => setting.user_config);

    const onChangeOrder = useCallback((property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    }, [order, orderBy]);

    const onSearchDatasets = useCallback((key) => {
        setSearchKey(key);
    }, []);

    const onFilter = useCallback((_event) => {
        setFilterSelected(!filterSelected);
    }, [filterSelected]);

    /**
     * Handles the connection configuration changes
     * @param {Name of the property} property
     * @param {Value of the property} value
     */
    const handleChange = useCallback((property, value) => {
        connectionConfig[property] = value;
        setConnectionConfig({ ...connectionConfig });
        setHasChanges(true);
        // setIsOnUpdate(true);
    }, [connectionConfig]);

    const handleChangeEvent = useCallback((event) => {
        const datasetConfig = {
            'id': datasource.id,
            'pushonly': event.target.checked
        };
        setPullPush(event.target.checked);
        dispatch(updateDatasourceProperties(datasetConfig));
    }, [datasource, dispatch]);

    /**
     * Removes the selected file
     * @param {Object} dataset - This file object
     */
    const removeDataset = useCallback((dataset) => {
        const tables = [...datasets];
        const index = tables.findIndex((p) => p.dataset_id === dataset.dataset_id);
        if (index > -1) {

            if (dataset.dataset_id) {
                setHasChanges(true);
                dispatch(deleteDataSet({ datasets: [dataset.dataset_id] })).then((response) => {
                    if (response) {
                        dataset['dataset_id'] = null;
                        dataset.isSelected = false;
                        tables.splice(index, 1, { ...dataset });
                        setDatasets([...tables]);
                        setIsAllSelected(false);
                    }
                });
                setSelectedDataSet(null);
            }
        }
    }, [datasets, dispatch]);


    const deleteSelectedDataset = useCallback((event, dataset) => {
        event.stopPropagation();
        if (!dataset.dataset_id) {
            removeDataset(dataset);
            return;
        }
        setSelectedDataSet(dataset);
    }, [removeDataset]);

    const enableDatasetConfiguration = useCallback((dataset, event) => {
        event.stopPropagation();
        setSelectedDataSet(dataset);
        setConfigurationDialog(true);
        setAnchorEl(event.target);
    }, []);

    const onCloseConfiguration = useCallback(() => {
        setConfigurationDialog(false);
        setSelectedDataSet(null);
    }, []);

    const onChangeDatasetConfiguration = useCallback((property, value) => {
        selectedDataSet[property] = value;
        setSelectedDataSet({ ...selectedDataSet });
    }, [selectedDataSet]);

    const onSelectionChange = useCallback((selectedDataset) => {
        for (const dataset of datasets) {
            if (dataset.name.toLowerCase() === selectedDataset.name.toLowerCase()) {
                dataset.isSelected = !dataset.isSelected;
                const hasSelectedAttributes = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length > 0;
                if (dataset.isSelected && dataset.attributes && !hasSelectedAttributes) {
                    for (const attribute of dataset.attributes) {
                        attribute.isSelected = true;
                    }
                    dataset.isAllAttributesSelected = true;
                }

                if (!dataset.isSelected && dataset.attributes) {
                    for (const attribute of dataset.attributes) {
                        attribute.isSelected = false;
                    }
                    dataset.isAllAttributesSelected = false;
                }
            }
        }
        setIsAllSelected(datasets.filter((p) => p.isSelected).length === datasets.length);
        setDatasets([...datasets]);
    }, [datasets]);

    /**
     * Handles the select all files option
     */
    const onSelectAll = useCallback(() => {
        setIsAllSelected(!isAllSelected);
        for (const dataset of datasets) {
            dataset.isSelected = !isAllSelected;
            const hasSelectedAttributes = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length > 0;
            if (dataset.isSelected && dataset.attributes && !hasSelectedAttributes) {
                for (const attribute of dataset.attributes) {
                    attribute.isSelected = true;
                }
            }
            if (!dataset.isSelected && dataset.attributes) {
                for (const attribute of dataset.attributes) {
                    attribute.isSelected = false;
                }
            }
            dataset.isAllAttributesSelected = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length === dataset.attributes.length;
        }
        setDatasets(datasets);
    }, [datasets, isAllSelected]);

    const loadDataSource = useCallback((datasource) => {
        if (datasource && datasource.config) {
            const config = {
                accesskey: datasource.config.accesskey ? datasource.config.accesskey : '',
                secretkey: datasource.config.secretkey ? datasource.config.secretkey : '',
                bucketname: datasource.config.bucketname ? datasource.config.bucketname : '',
                prefix: datasource.config.prefix ? datasource.config.prefix : '',
                is_delta: datasource.config.is_delta ? datasource.config.is_delta : false,
                "watermark_column": datasource.config.watermark_column ? datasource.config.watermark_column : '',
                "is_current_day": datasource.config.is_current_day ? datasource.config.is_current_day : false,
                "load_type": datasource.config.load_type ? datasource.config.load_type : 'Full Load',
                scan: datasource.config.scan ? datasource.config.scan : false,
                is_json: datasource.config.is_json ? datasource.config.is_json : false
            };
            setPullPush(datasource.pushonly);
            setConnectionConfig({ ...config });
            setDatasource({ ...datasource });
        }
    }, []);


    const loadDatasets = useCallback((datasets, loadType, loadDataType) => {
        for (const dataset of datasets) {
            const fileParts = dataset.name.split('.');
            let fileType = dataset.is_delta ? 'delta' : fileParts[fileParts.length - 1];
            fileType = appConstants.acceptedFileTypes.find((p) => p.type.toLowerCase().includes(fileType.toLowerCase()));
            dataset.fileType = fileType;
            dataset.modified_date = moment(dataset.modified_date).unix();
            dataset.isSelected = loadType === "Incremental Load" || loadDataType === "initalLoad" || dataset.isSelected;
            dataset.include_organization_domain_score = (dataset.include_organization_domain_score || dataset.include_organization_domain_score === false) ? dataset.include_organization_domain_score : true;
        }
        setIsAllSelected(datasets.filter((p) => p.isSelected).length === datasets.length);
        setIsAnySelected(datasets.filter((p) => p.isSelected).length > 0);
        setDatasets([...datasets]);
        setHasChanges(false);
    }, []);

    /**
     * Connectes the source with the given credentials
     */
    const connectToDatasource = useCallback(() => {
        connectionConfig['connection_type'] = datasourceType.type;
        const datasource = {
            datasourceId: datasourceId ? datasourceId : 0,
            name: appConfig.defaultDataSourceName,
            type: datasourceType.type,
            description: '',
            config: {
                ...connectionConfig
            }
        };
        setIsLoading(true);
        dispatch(connectDatasource(datasource)).then((response) => {
            if (!response) {
                setIsLoading(false);
                setIsValid(false);
                return;
            }
            connectionConfig['source_id'] = response.datasource ? response.datasource.id : null;
            setConnectionConfig({ ...connectionConfig });
            setDatasource(response.datasource ? { ...response.datasource } : {});
            if (datasourceId) {
                dispatch(getConnectedDatasets(datasourceId)).then((response) => {
                    setIsLoading(false);
                    setIsValid(true);
                    if (response) {
                        loadDataSource(response.datasource);
                        loadDatasets(response.datasets ? [...response.datasets] : [], connectionConfig['load_type'], connectionConfig['watermark_column'] !== "" ? "initalLoad" : "EditLoad");
                        setIsOnUpdate(false);
                    }
                });
            } else {
                setIsLoading(false);
                loadDatasets(response.datasets ? [...response.datasets] : [], connectionConfig['load_type'], connectionConfig['watermark_column'] !== "" ? "initalLoad" : "EditLoad");
                setIsOnUpdate(false);
            }
        }).catch(() => {
            setIsLoading(false);
            setIsOnUpdate(true);
            setIsValid(false);
        });
    }, [connectionConfig, datasourceId, datasourceType.type, dispatch, loadDataSource, loadDatasets]);


    /**
     * Creates a new dataset
     */
    const createDatasets = () => {
        if (!datasourceId && !datasource) {
            return;
        }
        const datasetsToConnect = [];
        if (connectionConfig.load_type === "Full Load") {
            const selectedDatasets = datasets.filter((p) => p.isSelected);
            for (const dataset of selectedDatasets) {
                if (!dataset.dataset_id) {
                    datasetsToConnect.push({
                        ...dataset,
                        'watermark_column': "",
                        'load_type': "Full Load"
                    });
                }
            }
        } else {
            const dataset = datasets.length > 0 ? datasets[0] : {};
            const incrementalFiles = datasets.map((list) => list.name);
            datasetsToConnect.push({
                ...dataset,
                table: dataset.name,
                "watermark_column": connectionConfig.watermark_column,
                'is_current_day': connectionConfig.is_current_day,
                "load_type": "Incremental Load",
                "incremental_file_name": incrementalFiles
            });
        }
        if (!datasetsToConnect) {
            return;
        }

        let selectedDatasourceId = datasourceId;
        if (!datasourceId && datasource) {
            selectedDatasourceId = datasource.id;
        }

        const datasetConfig = {
            'datasource_id': selectedDatasourceId,
            datasets: datasetsToConnect
        };
        setIsLoading(true);
        dispatch(connectDataset(datasetConfig)).then((response) => {
            if (!response) {
                setIsLoading(false);
                return;
            }
            if (onConnect) {
                onConnect(selectedDatasourceId, true);
            }
        }).catch(() => setIsLoading(false));
    };


    const getDatasetSchema = useCallback((dataset) => {
        if (!dataset || (dataset && dataset.attributes && dataset.attributes.length > 0)) {
            return;
        }

        dataset.isLoadingAttributes = true;
        const datasetIndex = datasets.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
        datasets.splice(datasetIndex, 1, { ...dataset });

        setDatasets([...datasets]);
        const datasetConfig = {
            'connection_type': datasourceType.type,
            'table': dataset.name,
            ...connectionConfig,
            ...dataset
        };
        dispatch(getSchema(datasetConfig)).then((response) => {
            if (response && response.length > 0) {
                dataset.attributes = [...response];
                const hasSelectedAttributes = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length > 0;
                if (dataset.isSelected && dataset.attributes && !hasSelectedAttributes) {
                    for (const attribute of dataset.attributes) {
                        attribute.isSelected = true;
                    }
                    dataset.isAllAttributesSelected = true;
                }
            }
            dataset.isAllAttributesSelected = dataset.attributes && dataset.attributes.filter((p) => p.isSelected).length === dataset.attributes.length;
            dataset.isLoadingAttributes = false;
            const datasetIndex = datasets.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
            datasets.splice(datasetIndex, 1, { ...dataset });
            setDatasets([...datasets]);
        });

    }, [connectionConfig, datasets, datasourceType.type, dispatch]);

    useEffect(() => {
        if (copyConfig) {
            if (copyConfig.source_id) {
                delete copyConfig.source_id;
            }
            if (copyConfig.connection_establish) {
                delete copyConfig.connection_establish;
            }
            setConnectionConfig({ ...copyConfig });
            dispatch(success(alertActions.ALERT_SUCCESS, 'Datasource Duplicated Successfully'));
        }
    }, [copyConfig, dispatch]);


    useEffect(() => {
        if (!datasourceId) {
            return;
        }
        setIsPageLoading(true);
        dispatch(getConnectedDatasets(datasourceId)).then((response) => {
            setIsPageLoading(false);
            if (response) {
                loadDataSource(response.datasource);
                const loadType = response.datasource && response.datasource.config && response.datasource.config.load_type ? response.datasource.config.load_type : 'Full Load';
                loadDatasets(response.datasets, loadType, "EditLoad");
            }
        });
    }, [datasourceId, dispatch, loadDataSource, loadDatasets]);


    const onSelectAttribute = useCallback((dataset, attribute, attributeIndex) => {
        const attributes = [...dataset.attributes];
        attribute.isSelected = !attribute.isSelected;
        attributes.splice(attributeIndex, 1, { ...attribute });
        dataset.attributes = [...attributes];
        dataset.isAllAttributesSelected = attributes.filter((p) => p.isSelected).length === attributes.length;
        dataset.isSelected = dataset.attributes.filter((attribute) => attribute.isSelected).length === 0;

        const selectedDatasetIndex = datasets.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
        datasets.splice(selectedDatasetIndex, 1, JSON.parse(JSON.stringify(dataset)));
        setDatasets([...datasets]);
        onSelectionChange(dataset);
    }, [datasets, onSelectionChange]);

    const onSaveConfiguration = useCallback((event) => {
        const selectedDatasetIndex = datasets.findIndex((p) => p.name.toLowerCase() === selectedDataSet.name.toLowerCase());
        datasets.splice(selectedDatasetIndex, 1, JSON.parse(JSON.stringify(selectedDataSet)));
        setDatasets([...datasets]);
        setConfigurationDialog(false);
        setSelectedDataSet(null);
    }, [datasets, selectedDataSet]);

    const onSelectAllAttribute = useCallback((dataset) => {
        dataset.isAllAttributesSelected = !dataset.isAllAttributesSelected;
        for (const attribute of dataset.attributes) {
            attribute.isSelected = dataset.isAllAttributesSelected;
        }
        dataset.isSelected = dataset.attributes.filter((attribute) => attribute.isSelected).length === 0;
        const selectedDatasetIndex = datasets.findIndex((p) => p.name.toLowerCase() === dataset.name.toLowerCase());
        datasets.splice(selectedDatasetIndex, 1, JSON.parse(JSON.stringify(dataset)));
        setDatasets([...datasets]);
        onSelectionChange(dataset);
    }, [datasets, onSelectionChange]);

    const onUpdateDataset = useCallback((dataset, property, value) => {
        const tables = [...datasets];
        const index = tables.indexOf(dataset);
        if (index > -1) {
            dataset[property] = value;
            if (property === 'con_type') {
                dataset['con_type'] = value;
            }
            tables.splice(index, 1, { ...dataset });
            setDatasets([...tables]);
        }
    }, [datasets]);

    const onCloseDatasetConfigModal = useCallback((dataset) => {
        setPullConfiguration(null);
    }, []);

    const onOpenDatasetConfigModal = useCallback((event, dataset) => {
        event.stopPropagation();
        setPullConfiguration({
            anchorElement: event.target,
            dataset,
            onCancel: onCloseDatasetConfigModal,
            onSave: onUpdateDataset
        });
    }, [onCloseDatasetConfigModal, onUpdateDataset]);

    let selectedDatasets = [...datasets];
    if (searchKey) {
        selectedDatasets = datasets.filter((p) => p.name.toLowerCase().includes(searchKey.toLowerCase()));
    }

    const headers = connectionConfig.is_delta ? appConstants.s3DeltaConnectorTableHeadersObj : appConstants.s3ConnectorTableHeadersObj;

    const getDatasets = () => {
        let filterdDatasetList = sortTable(selectedDatasets, order, orderBy);
        let otherDatasets = [];
        if (filterSelected) {
            otherDatasets = filterdDatasetList.filter((dataset) => !dataset.isSelected);
            filterdDatasetList = filterdDatasetList.filter((dataset) => dataset.isSelected);
            filterdDatasetList = [...filterdDatasetList, ...otherDatasets];
        }
        return filterdDatasetList;
    };

    return (
        <ValidatorForm form="sql_config" autoComplete={"off"} onSubmit={() => connectToDatasource()} style={{ height: '100%' }}>
            <Grid container className={classes.connectcontainer} direction="row" justify="flex-start" alignItems="flex-start" style={{ height: '100%', position: 'relative' }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container>
                        <Grid item xs={10}>
                            <Typography component="h4" variant="h4" className={classes.connectTitle}>
                                {'Configure AWS S3 Connection'}
                            </Typography>
                            <Typography variant="body2" className={classes.connectdescription}>
                                {datasourceType.description}
                            </Typography>
                        </Grid>
                        {
                            datasourceId &&
                            <Grid item xs={2} className={classnames(classes.textRight, classes.duplicateDatasource)}>
                                <ToolTipComponent title="Duplicate Datasource" arrow>
                                    <IconButton onClick={() => history.push({ pathname: '/configuredatasource', state: { datasourceType: datasourceType, con_config: connectionConfig, isCopy: true } })}>
                                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill={theme.palette.primary.main}>
                                            <path d="M0 0h24v24H0V0z" fill="none" />
                                            <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
                                        </svg>
                                    </IconButton>
                                </ToolTipComponent>
                            </Grid>
                        }
                    </Grid>
                    <Grid container direction="row" spacing={3} style={{ marginTop: 20 }}>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="AWS Access Key"
                                        name="accesskey"
                                        disabled={isAnySelected}
                                        validators={['required']}
                                        errorMessages={['Access key is required']}
                                        value={connectionConfig.accesskey}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <PasswordInput
                                        label="AWS Secret Key"
                                        name="secretkey"
                                        validators={['required']}
                                        errorMessages={['Secret key is required']}
                                        type="password"
                                        password={connectionConfig.secretkey}
                                        onChange={(event, value) => handleChange(event.target.name, value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="Bucket Name"
                                        name="bucketname"
                                        disabled={isAnySelected}
                                        validators={['required']}
                                        errorMessages={['Bucker name is required']}
                                        value={connectionConfig.bucketname}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="Prefix (optional)"
                                        name="prefix"
                                        disabled={isAnySelected}
                                        value={connectionConfig.prefix ? connectionConfig.prefix : ''}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="Load Type"
                                        name="load_type"
                                        value={connectionConfig.load_type ? connectionConfig.load_type : appConstants.loadType[0]}
                                        select
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    >
                                        {
                                            appConstants.loadType.map((loadType, index) => {
                                                return (
                                                    <MenuItem key={`loadType_${index}`} value={loadType}>
                                                        {loadType}
                                                    </MenuItem>
                                                );
                                            })
                                        }
                                    </TextBox>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={8}>
                                    <TextBox
                                        label="Watermark Column"
                                        name="watermark_column"
                                        value={connectionConfig.watermark_column ? connectionConfig.watermark_column : ""}
                                        onChange={(event) => handleChange(event.target.name, event.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid xs={12} className={classes.marginTop10}>
                                    <CheckboxComponent
                                        className={"attributeSelectorCheck"}
                                        checked={Boolean(connectionConfig.is_current_day)}
                                        onClick={() => handleChange("is_current_day", !connectionConfig.is_current_day)}
                                        checkboxLabel={'Current Day/ Current Month/ Current Year'}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container>
                                <Grid xs={6}>
                                    <CheckboxComponent
                                        checked={Boolean(connectionConfig.is_delta)}
                                        onClick={() => handleChange("is_delta", !connectionConfig.is_delta)}
                                        checkboxLabel="Delta Table" />
                                </Grid>
                                <Grid xs={2} className={classes.checkboxContainer}>
                                    <CheckboxComponent
                                        checked={Boolean(connectionConfig.is_json)}
                                        onClick={() => handleChange("is_json", !connectionConfig.is_json)}
                                        checkboxLabel="JSON Data" />
                                </Grid>
                                {
                                    licenseConfig.scan &&
                                    <Grid item xs={3} className={classes.checkboxContainer}>
                                        <CheckboxComponent disabled={(connectionConfig.mode === "query" || !licenseConfig.pull) || isAnySelected || datasets.length > 0} checked={connectionConfig.scan} onClick={() => handleChange("scan", !connectionConfig.scan)} checkboxLabel="Scan" />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {
                    (isOnUpdate) &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container direction="row" justify="flex-start" style={{ marginTop: 40 }}>
                            <Grid item className={classes.btnContainer}>
                                <Button type="submit"
                                    variant="contained"
                                    disabled={isLoading}
                                    color="primary">
                                    {'Validate'}
                                    {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button className={classnames(classes.cancelButton)}
                                    style={{ marginLeft: 20 }}
                                    disabled={isLoading}
                                    onClick={() => onCancel(hasChanges)}>
                                    {'Cancel'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {
                    (datasets.length > 0) &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container justify="space-between" alignItems="center">
                            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.searchInputContainer}>
                                <TextBox placeholder={'Search Dataset'}
                                    onChange={(event) => onSearchDatasets(event.target.value)}
                                    name="searchKey"
                                    id="searchKey"
                                    value={searchKey}
                                    className={classes.inputOutline} />
                                {
                                    searchKey.length === 0 ?
                                        <IconButton className={classes.includeSearchIcon}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.094" height="13.092" viewBox="0 0 13.094 13.092">
                                                <g transform="translate(0 -0.035)">
                                                    <path fill="#afb2b3" d="M5.27,10.57A5.257,5.257,0,0,0,8.5,9.46l3.483,3.483a.655.655,0,0,0,.926-.926L9.427,8.534A5.267,5.267,0,1,0,5.27,10.57ZM2.471,2.5a3.958,3.958,0,1,1,0,5.6h0a3.944,3.944,0,0,1-.02-5.577l.02-.02Z" transform="translate(0 0)" />
                                                </g>
                                            </svg>
                                        </IconButton> :
                                        <IconButton className={classes.includeDeleteIcon} onClick={() => onSearchDatasets('')}>
                                            <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                            </svg>
                                        </IconButton>
                                }
                            </Grid>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={pushonly}
                                        onChange={handleChangeEvent}
                                        name="pullpushonly"
                                    />
                                }
                                label="Pull and Push only"
                            />
                            <FormControlLabel
                                control={
                                    <CheckboxComponent
                                        className={"attributeSelectorCheck"}
                                        checked={filterSelected}
                                        onClick={onFilter}
                                        checkboxLabel={''}
                                    />
                                }
                                label="Show Selected"
                            />
                        </Grid>
                    </Grid>
                }
                {
                    <Card id="s3-connector" ref={containerRef} className={classnames(classes.tableaccordian, classes.connectTableContainer, classes.s3View)}>
                        {
                            selectedDatasets && selectedDatasets.length >= 1 &&
                            <Table stickyHeader className={classnames(classes.datasettable, classes.designTable)} aria-label="a dense table" >
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.datasetHeaderTitleCell}>
                                            <Grid className={classes.datasetTitleSection}>
                                                <Grid className={classnames(classes.inlineBlock, classes.displayFlex)}>
                                                    {!isValid && <WarningIcon />}
                                                    <CheckboxComponent
                                                        className={"attributeSelectorCheck"}
                                                        checked={isAllSelected}
                                                        onClick={() => onSelectAll()}
                                                        showCheckBox={isValid}
                                                    // checkboxLabel={'Datasets'}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        {
                                            headers && headers.filter((elem) => (userConfig.include_organization_domain_score ? true : elem.id !== 'include_organization_domain_score')).map((header, index) => {
                                                if (connectionConfig.scan && header.isPullOnly) {
                                                    return null;
                                                }
                                                return (
                                                    <TableCell key={`sqlConnectorTable${index}`}
                                                        className={appConstants.s3ConnectorTableHeaders.length === index && classes.textCenter}
                                                        sortDirection={orderBy === header.id ? order : false}>
                                                        {
                                                            header.sort === false ?
                                                                header.label :
                                                                <TableSortLabel
                                                                    active={orderBy === header.id}
                                                                    direction={orderBy === header.id ? order : 'asc'}
                                                                    onClick={() => onChangeOrder(header.id)}
                                                                >
                                                                    {header.label}
                                                                </TableSortLabel>
                                                        }
                                                    </TableCell>
                                                );
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.datasetTableAccordHeader}>
                                    {
                                        selectedDatasets && getDatasets().map((dataset, index) => {
                                            return (
                                                <AccordionTable key={index}
                                                    dataset={dataset}
                                                    index={index}
                                                    classes={classes}
                                                    isLoading={isLoading}
                                                    theme={theme}
                                                    rootColumns={headers}
                                                    getDatasetSchema={getDatasetSchema}
                                                    onSelectionChange={onSelectionChange}
                                                    deleteSelectedDataset={deleteSelectedDataset}
                                                    onSelectAllAttribute={onSelectAllAttribute}
                                                    onSelectAttribute={onSelectAttribute}
                                                    enableDatasetConfiguration={enableDatasetConfiguration}
                                                    onUpdateDataset={onUpdateDataset}
                                                    onOpenDatasetConfigModal={onOpenDatasetConfigModal}
                                                    showCheckBox={isValid}
                                                    isScan={connectionConfig.scan}
                                                    userConfig={userConfig}
                                                />
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        }
                    </Card>
                }


                {
                    (selectedDatasets.length > 0 && !isOnUpdate) &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container direction="row" justify="flex-end">
                            <Grid item className={classes.btnContainer}>
                                <Button variant="contained"
                                    disabled={isLoading}
                                    color="primary"
                                    onClick={() => (!isOpenConfigurationDialog ? createDatasets() : null)}
                                    className={classnames(classes.actionButtons)}>
                                    {'Connect'}
                                    {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button className={classnames(classes.cancelButton, classes.actionButtons)}
                                    disabled={isLoading}
                                    onClick={() => onCancel(hasChanges)}>
                                    {'Cancel'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                }

                {

                    !isOpenConfigurationDialog &&
                    <AlertDialog title="Delete Dataset"
                        message={`Are you sure you want to delete the selected dataset ${selectedDataSet ? selectedDataSet.name : ''}?`}
                        okButtonText="OK"
                        cancelButtonText="Cancel"
                        show={Boolean(selectedDataSet)}
                        onClickOK={() => removeDataset(selectedDataSet)}
                        onClickCancel={() => setSelectedDataSet(null)} />
                }
                {isPageLoading && <Loader />}
                {
                    isOpenConfigurationDialog &&
                    <ConnectorXMLConfiguration
                        open={isOpenConfigurationDialog}
                        onClose={() => onCloseConfiguration()}
                        anchorEl={anchorEl}
                        onChange={(property, value) => onChangeDatasetConfiguration(property, value)}
                        onSave={() => onSaveConfiguration()}
                        dataset={selectedDataSet}
                    />
                }

            </Grid>
            <PullConfiguration
                isS3Delta={Boolean(connectionConfig.is_delta)}
                open={Boolean(pullConfiguration)}
                anchorEl={pullConfiguration && pullConfiguration.anchorElement ? pullConfiguration.anchorElement : null}
                dataset={pullConfiguration && pullConfiguration.dataset ? pullConfiguration.dataset : null}
                onSave={pullConfiguration && pullConfiguration.onSave ? pullConfiguration.onSave : null}
                onCancel={pullConfiguration && pullConfiguration.onCancel ? pullConfiguration.onCancel : null}
            />
        </ValidatorForm >
    );
};

S3Connector.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    datasourceType: PropTypes.object,
    datasourceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onConnect: PropTypes.func,
    onCancel: PropTypes.func,
    history: PropTypes.object,
    copyConfig: PropTypes.object
};

export default withStyles((theme) => ({
    ...ConnectorStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(S3Connector);