const AlertDialogStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    message: {
        fontSize: 14,
        padding: '0px 8px'
    },
    cancelButton: {
        color: '#f44336',
        textTransform: 'uppercase'
    }
});

export default AlertDialogStyles;