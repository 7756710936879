import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Avatar } from '@material-ui/core';
import DashboardStyles from '../Dashboard/DashboardStyles.jsx';
import Styles from '../../layouts/Styles.jsx';

const DashboardChartTile = (props) => {
    const { classes, color, count, theme, titleText } = props;
    return (
        <Grid container alignItems="center" className={classes.cardTile}>
            <Avatar
                className={classes.tileAvatar}
                style={
                    {
                        backgroundColor: color
                    }
                }>
                <svg xmlns="http://www.w3.org/2000/svg" id="copy_4_" data-name="copy (4)" width="22.698" height="26.845" viewBox="0 0 22.698 26.845">
                    <path id="Path_1475" data-name="Path 1475" d="M68.653,3.712V0H53.639L49.852,3.607V23.133h3.9v3.712h18.8V3.712ZM54.187,1.7V4.128H51.64ZM51.5,5.7h4.335V1.573H67V21.56H51.5ZM70.9,25.272H55.4V23.133H68.653V5.285H70.9Z" transform="translate(-49.852)" fill={theme.palette.background.paper} />
                    <path id="Path_1476" data-name="Path 1476" d="M123.019,138.734h10.744v1.573H123.019Z" transform="translate(-118.991 -131.46)" fill={theme.palette.background.paper} />
                    <path id="Path_1477" data-name="Path 1477" d="M123.019,198.734h10.744v1.573H123.019Z" transform="translate(-118.991 -188.314)" fill={theme.palette.background.paper} />
                    <path id="Path_1478" data-name="Path 1478" d="M123.019,258.734h10.744v1.573H123.019Z" transform="translate(-118.991 -245.168)" fill={theme.palette.background.paper} />
                </svg>
            </Avatar>
            <Grid className={classes.marginLeft10}>
                <Typography className={classes.cardTitle}>
                    {count}
                </Typography>
                <Typography className={classes.cardTxt}>
                    {titleText}
                </Typography>
            </Grid>
        </Grid>
    );
};

DashboardChartTile.propTypes = {
    classes: PropTypes.object,
    count: PropTypes.number,
    color: PropTypes.string,
    theme: PropTypes.object,
    titleText: PropTypes.string
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DashboardChartTile);