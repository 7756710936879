import useDynamicRefs from '../../helpers/dynamicRef';
import React, { useEffect, useState, useCallback } from 'react';
import LineageTableauStyle from './LineageTableauStyle.jsx';
import { appConstants } from '../../constants/appConstants';
import * as d3 from 'd3';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { getLineageTableauGraph } from '../../actions/lineageActions';
import PropTypes from 'prop-types';
import SearchBoxLineage from '../../components/Lineage/SearchBoxLineage.jsx';
import { Box, Typography } from '@material-ui/core';
import Loader from '../../components/Loaders/Loader.jsx';
import { lineageIcons } from '../../components/Lineage/LineageIconStore.jsx';
import AlertDialog from '../../components/AlertDialog/AlertDialog.jsx';
import BackForwardLineageButtons from '../../components/Lineage/BackForwardLineageButtons.jsx';

const linkGen = d3.linkHorizontal();
const zoom = d3.zoom().scaleExtent([0.5, 3]);
const nodePaddingX = appConstants.lineageModule.paddingConstants.nodePaddingX;

const LineageTableau = ({ datasource_id, workbook_id }) => {
    const [getRef, setRef] = useDynamicRefs();
    const [intialNodePosition, setIntialNodePosition] = useState({});
    const [lineagePathNodes, setLineagePathNodes] = useState([]);
    const [searchSeletedNode, setSearchSelectedNode] = useState('');
    const [currentActiveNodesDetails, setCurrentActiveNodesDetails] = useState([]);
    const [childrenToggleMapper, setChildrenToggleMapper] = useState({});
    const [groupPlaceChange, setGroupPlaceChange] = useState(false);
    const [groupCoordinates, setGroupCoordinates] = useState({ x: 0, y: 0, height: 0, width: 0 });
    const [workbookName, setWorkbookName] = useState('');
    const [loader, setLoader] = useState(true);
    const [sourceCodeMapper, setSourceCodeMapper] = useState({});
    const [sourceCode, setSourceCode] = useState('');
    const [toggleSourceCode, setToggleSourceCode] = useState(false);
    const [parentChildMapper, setParentChildMapper] = useState({});
    const [parentChildIdMapper, setParentChildIdMapper] = useState({});
    const [selectedLineageNode, setCurrentLineageNode] = useState('');
    const [currentDirection, setCurrentDirection] = useState(appConstants.lineageModule.BO);
    const dispatch = useDispatch();
    const [lineage, setLineage] = useState({
        nodes: {
            upstreamDatabases: {
                x: 0,
                y: 0,
                data: []
            },
            upstreamTables: {
                x: 0,
                y: 0,
                data: []
            },
            workbook: {
                x: 0,
                y: 0,
                data: []
            },
            sheets: {
                x: 0,
                y: 0,
                data: []
            },
            dashboards: {
                x: 0,
                y: 0,
                data: []
            },
            owner: {
                x: 0,
                y: 0,
                data: []
            }
        },
        edges: [],
        linkNoRegenerate: false
    });

    const groupDeviationCalculator = useCallback((parentCoordinate, newCoordinates, scale) => {
        const currentGroupCoordinats = { x: groupCoordinates?.x, y: groupCoordinates?.y };
        const currentParentCoordinats = { x: parentCoordinate?.x, y: parentCoordinate?.y };
        if (newCoordinates?.x > currentGroupCoordinats?.x) {
            currentParentCoordinats.x += (newCoordinates?.x - currentGroupCoordinats?.x);
        } else {
            currentParentCoordinats.x -= (currentGroupCoordinats?.x - newCoordinates?.x);
        }

        if (newCoordinates?.y > currentGroupCoordinats?.y) {
            currentParentCoordinats.y += (newCoordinates?.y - currentGroupCoordinats?.y);
        } else {
            currentParentCoordinats.y -= (currentGroupCoordinats?.y - newCoordinates?.y);
        }
        return currentParentCoordinats;
    }, [groupCoordinates?.x, groupCoordinates?.y]);

    const drawEdges = useCallback((nodes) => {
        const nodeBound = getRef(`parentSvg`).current?.getBoundingClientRect();
        const groupbound = getRef(`svgGroup`).current?.getBoundingClientRect();
        const scale = parseTransformAttribute(d3.select(getRef(`svgGroup`).current)?.attr('transform')?.split(' ')?.join(','))?.k;
        if (!groupPlaceChange) {
            setGroupCoordinates({
                x: groupbound?.x,
                y: groupbound?.y
            });
        }
        const filteredLinks = lineage?.edges.map((link) => {
            let sourceNode = _.find(nodes, { id: link.sourceId });
            let targetNode = _.find(nodes, { id: link.targetId });

            if (sourceNode && targetNode) {

                const nodeContainerRef = getRef(`child_${sourceNode.id}`)?.current?.getBoundingClientRect();
                const xOffset = nodeContainerRef?.width || 0;

                let tempNodebound = nodeBound;
                if (groupPlaceChange) {
                    tempNodebound = groupDeviationCalculator(nodeBound, groupbound, scale);
                }

                const sourceRef = getRef(`child_${sourceNode.id}`)?.current?.getBoundingClientRect();
                sourceNode = {
                    ...sourceNode,
                    x: (sourceRef?.x - tempNodebound?.x),
                    y: (sourceRef?.y - tempNodebound?.y) + (sourceRef?.height / 2)
                };

                const targetRef = getRef(`child_${targetNode.id}`)?.current?.getBoundingClientRect();
                targetNode = {
                    ...targetNode,
                    x: (targetRef?.x - tempNodebound?.x),
                    y: (targetRef?.y - tempNodebound?.y) + (targetRef?.height / 2)
                };
                return { source: [sourceNode.x + xOffset, sourceNode.y], target: [targetNode.x - 5, targetNode.y], active: link?.active && link?.linkDisplay, sourceId: sourceNode.id, targetId: targetNode.id, isRadial: link?.isRadial };
            }
            return undefined;


        }).filter((d) => d);
        d3.select(getRef(`svgGroup`).current)
            .selectAll("path")
            .data([...filteredLinks])
            .join("path")
            .attr("d", linkGen)
            .attr("fill", "none")
            .attr("stroke", "#cfcfcf")
            .attr("class", "link")
            .attr("marker-end", "url(#arrowhead)")
            .attr("marker-start", "url(#arrowhead)")
            .style('opacity', (event) => {
                return Number(event.active);
            })
            .style("stroke-linejoin", "round")
            .attr("id", (event) => `link_${event.sourceId}_${event.targetId}`);
    }, [getRef, lineage?.edges, groupDeviationCalculator, groupPlaceChange]);

    useEffect(() => {
        let allNodes = [];
        const tempParentChildMapper = {};
        const tempParentChildIdMapper = {};
        Object.keys(lineage.nodes)?.forEach((key) => {
            const lineageData = lineage.nodes[key]?.data.map((d) => ({ ...d, parentType: key?.replace('upstream', '').toLocaleLowerCase() }));
            let children = [];
            lineage.nodes[key]?.data.forEach((data) => {
                if (data?.fields) {
                    data.fields = data?.fields.map((c) => ({ ...c, parentName: data?.name }));
                    children = [...children, ...data?.fields];
                }
                tempParentChildIdMapper[data?.id] = [];
                data?.fields?.forEach((child) => {
                    tempParentChildMapper[child?.id] = data?.id;
                    tempParentChildIdMapper[data?.id].push(child?.id);
                });
            });
            allNodes = [...allNodes, ...lineageData, ...children];
        });
        setParentChildMapper(tempParentChildMapper);
        setParentChildIdMapper(tempParentChildIdMapper);
        setCurrentActiveNodesDetails(allNodes.filter((d) => d.name));
        drawEdges(allNodes);
    }, [drawEdges, lineage?.nodes, childrenToggleMapper]);

    const positionCalculator = useCallback((response) => {
        const tempGraphData = {
            upstreamDatabases: {
                x: 0,
                y: 0,
                data: response?.upstreamDatabases.map((data) => ({ ...data, parentId: 0 })) || []
            },
            upstreamTables: {
                x: 0,
                y: 0,
                id: 1,
                data: response?.upstreamTables.map((data) => ({ ...data, parentId: 1 })) || []
            },
            workbook: {
                x: 0,
                y: 0,
                id: 1,
                data: response?.workbook.map((data) => ({ ...data, parentId: 4 })) || []
            },
            sheets: {
                x: 0,
                y: 0,
                id: 2,
                data: response?.sheets.map((data) => ({ ...data, parentId: 2 })) || []
            },
            dashboards: {
                x: 0,
                y: 0,
                id: 3,
                data: response?.dashboards.map((data) => ({ ...data, parentId: 3 })) || []
            },
            owner: {
                x: 0,
                y: 0,
                id: 4,
                // data: response?.owner ? [response?.owner].map((data) => ({ ...data, parentId: 4 })) : []
                data: response ? [response.workbook_owner].map((data) => ({ ...data, parentId: 4 })) : []
            }
        };
        if (intialNodePosition?.x && intialNodePosition?.y) {
            Object.keys(tempGraphData).filter((key) => {
                return tempGraphData[key]?.data?.length;
            }).forEach((key, index) => {
                let currentx = intialNodePosition?.x;
                let currenty = intialNodePosition?.y;
                if (index) {
                    currentx = intialNodePosition?.x + (index * nodePaddingX);
                    currenty = intialNodePosition?.y;
                }
                tempGraphData[key].x = currentx;
                tempGraphData[key].y = currenty;
            });

            const tempGraphData2 = { ...tempGraphData };
            const tempArray = [...Object.keys(tempGraphData2)];
            tempArray.forEach((element) => {
                if (!tempGraphData2[element]?.data?.length) {
                    delete tempGraphData2[element];
                }
            });


            setLineage((pevState) => ({
                ...pevState,
                nodes: { ...tempGraphData2 }
            }));
            setTimeout(() => {
                setLoader(false);
            }, 100);
        }
    }, [intialNodePosition?.x, intialNodePosition?.y]);

    const handleZoom = useCallback(() => {
        setGroupPlaceChange(true);
        const svgGrp = document.getElementById('svgGroup');
        const transformObject = d3?.event?.transform;
        d3.select(svgGrp).attr('transform', transformObject);
    }, []);

    /**
     * Display lineage edges
     * Direction available  - type : ( F - forward, B - backward, BO - both )
     */
    const displayLineageEdges = useCallback((event, id, type = appConstants.lineageModule.BO) => {
        setCurrentDirection(type);
        if (event) {
            event?.stopPropagation();
            event?.preventDefault();
        }
        if (!id && !selectedLineageNode) { return; }
        let currentId = '';
        if (id) {
            currentId = id;
            setCurrentLineageNode(id);
        } else {
            currentId = selectedLineageNode;
        }


        let iteration = (type === appConstants.lineageModule.BO) ? 2 : 1;
        let itr = 0;

        const failSafeCount = 1000;
        const edgesTemp = [...lineage.edges.map((edge) => ({ ...edge, isEdge: false }))];
        while (iteration) {
            let array = [currentId];
            const edgeKey = {
                [appConstants.lineageModule.B]: 'targetId',
                [appConstants.lineageModule.F]: 'sourceId',
                [appConstants.lineageModule.BO]: (iteration === 2) ? 'sourceId' : 'targetId'
            }[type];
            while (array.length && itr < failSafeCount) {
                itr++;
                const tempArray = [...array];
                const sourceFiltered = [];
                // eslint-disable-next-line no-loop-func
                tempArray.forEach((selected_id) => {
                    edgesTemp.forEach((edge) => {
                        if (edge[edgeKey] === selected_id && edge?.active) {
                            edge.isEdge = true;
                            sourceFiltered.push(edge);
                        }
                    });
                    const sourceArray = sourceFiltered.map((edge) => {
                        return edge[edgeKey === 'sourceId' ? 'targetId' : 'sourceId'];
                    });
                    array = [...sourceArray];
                });
            }
            iteration--;
        }

        // Activate edges
        d3.selectAll(`.link`).attr("stroke", "#cfcfcf");
        let lineagePathNodes = [];
        setTimeout(() => {
            edgesTemp.filter((edge) => edge.isEdge).forEach((edge) => {
                d3.select(`#link_${edge.sourceId}_${edge.targetId}`).attr("stroke", "#efaa94");
                lineagePathNodes = [...lineagePathNodes, edge.sourceId, edge.targetId];
            });
            setLineagePathNodes(lineagePathNodes);
        }, 100);
    }, [lineage.edges, selectedLineageNode]);

    const initZoom = useCallback(() => {
        if (zoom) {
            d3.select(getRef(`parentSvg`).current).call(zoom).on('dblclick.zoom', null).on('wheel.zoom', null);
        }
    }, [getRef]);

    useEffect(() => {
        zoom.on('zoom', handleZoom);
    }, [lineage, handleZoom]);

    useEffect(() => {
        setIntialNodePosition({
            x: getRef(`parentSvg`)?.current?.getBoundingClientRect().width / 12,
            y: getRef(`parentSvg`)?.current?.getBoundingClientRect().height / 8
        });
        setLoader(true);
        dispatch(getLineageTableauGraph(datasource_id, workbook_id)).then((response) => {
            if (response) {
                const workbook = response?.nodes ? response?.nodes : {};
                setWorkbookName(response?.nodes?.name);
                const sheets = workbook.sheets ? workbook.sheets : [];
                const owners = workbook.owner ? workbook.owner : [];
                const ownerEmptyNodes = [];
                if (sheets.length > 0 && owners.length > 0) {
                    workbook.sheets = sheets.map((sheet) => {
                        let containedInDashboards = sheet.containedInDashboards ? sheet.containedInDashboards : [];
                        if (!containedInDashboards.length) {
                            ownerEmptyNodes.push({
                                active: true,
                                linkDisplay: true,
                                sourceId: sheet.id,
                                targetId: owners[0].id,
                                isRadial: true
                            });
                            containedInDashboards = [owners[0]];
                        }
                        sheet.containedInDashboards = [...containedInDashboards];
                        return {
                            ...sheet
                        };
                    });
                }
                let edges = response.edges ? response.edges : [];
                edges = [...ownerEmptyNodes, ...edges];
                setLineage((prevState) => ({ ...prevState, edges: edges }));
                setSourceCodeMapper(response?.sqlCodeMapper);
                initZoom();
                positionCalculator(workbook);
            }
        }).catch(() => {
            setLoader(false);
        });
    }, [getRef, positionCalculator, dispatch, datasource_id, workbook_id, initZoom]);

    function parseTransformAttribute(a) {
        if (!a) {
            return {
                x: 0, y: 0, k: 1
            };
        }
        const b = {};
        for (const i in a = a.match(/(\w+\((-?\d+\.?\d*e?-?\d*,?)+\))+/g)) {
            const c = a[i].match(/[\w.-]+/g);
            b[c.shift()] = c;
        }
        return {
            x: isNaN(Number(b?.translate[0])) ? 0 : Number(b?.translate[0]),
            y: isNaN(Number(b?.translate[1])) ? 0 : Number(b?.translate[1]),
            k: isNaN(Number(b?.scale[0])) ? 1 : Number(b?.scale[0])
        };
    }

    const focusNodeAfterSearch = (selectedNode) => {
        setSearchSelectedNode(selectedNode?.id);
        const selectedId = (selectedNode?.parentId) ? selectedNode?.parentId : selectedNode?.id;
        if (!selectedId) { return; }
        const parentRef = document.getElementById('svgGroup');
        const parentContainerRef = document.getElementById('parentSvg');
        const svgBound = parentRef?.getBoundingClientRect();
        let nodeRootContainerDynamic = document.getElementById(`child_${selectedNode?.id}`);

        if (!nodeRootContainerDynamic && parentChildMapper[selectedNode?.id]) {
            childToggle(undefined, parentChildMapper[selectedNode?.id], parentChildIdMapper[parentChildMapper[selectedNode?.id]]);
        }

        setTimeout(() => {
            nodeRootContainerDynamic = document.getElementById(`child_${selectedNode?.id}`);
            const nodeBound = nodeRootContainerDynamic?.getBoundingClientRect();

            const parsedTransformation = parseTransformAttribute(d3.select(nodeRootContainerDynamic)?.attr('transform')?.split(' ')?.join(','));

            const xOffset = (((intialNodePosition?.x) + (parsedTransformation?.x)) - (nodeBound?.x - svgBound?.x));
            const yOffset = ((intialNodePosition?.y + (parsedTransformation?.y)) - (nodeBound?.y - svgBound?.y));

            d3.select(parentContainerRef).transition().duration(500).call(zoom?.transform, d3.zoomIdentity.translate(xOffset, yOffset).scale(parsedTransformation?.k));
        }, 100);
    };

    const childToggle = (event, id, childrensIds) => {
        if (event) {
            event?.stopPropagation();
            event?.preventDefault();
        }
        const tempMapper = { ...childrenToggleMapper };
        tempMapper[id] = !tempMapper[id];

        // Enabling links
        let tempLinks = [...lineage?.edges];

        tempLinks = tempLinks.map((link) => {
            if (link?.sourceId === id || link?.targetId === id) {
                link.active = !tempMapper[id];
            }
            if (childrensIds.indexOf(link?.sourceId) !== -1 || childrensIds.indexOf(link?.targetId) !== -1) {
                link.active = tempMapper[id];
            }
            return link;
        });
        setTimeout(() => {
            setChildrenToggleMapper(tempMapper);
            setLineage((prevState) => ({ ...prevState, edges: tempLinks }));
        }, 100);
    };

    const clearSelectedSearch = () => {
        setSearchSelectedNode('');
    };

    const displaySourceCode = (event, id) => {
        event?.stopPropagation();
        event?.preventDefault();
        setToggleSourceCode(true);
        setSourceCode(sourceCodeMapper[id]);
    };

    const changeLineageDirection = (direction) => {
        displayLineageEdges(undefined, undefined, direction);
    };


    return (
        <React.Fragment>
            {loader && <Loader />}
            <Box style={{ height: '5rem' }}>
                <div style={LineageTableauStyle.optionsContainer}>
                    <SearchBoxLineage
                        onSearchNode={focusNodeAfterSearch}
                        nodes={currentActiveNodesDetails}
                    />
                </div>
                <BackForwardLineageButtons
                    currentDirection={currentDirection}
                    onDirectionChange={changeLineageDirection}
                />
            </Box>
            <svg
                style={LineageTableauStyle.svgStyle}
                ref={setRef('parentSvg')}
                id="parentSvg"
                onClick={clearSelectedSearch}
            >
                <marker id="arrowhead"
                    markerWidth="10"
                    markerHeight="7"
                    refX="0"
                    refY="3.5"
                    orient="auto">
                    <circle cx="4" cy="4" r="2" stroke="#bbbdbe" strokeWidth="2" fill="#fff" />
                </marker>
                <g id="svgGroup" ref={setRef('svgGroup')}>
                    {
                        Object.keys(lineage.nodes).map((listKey, index) => (
                            <foreignObject x={lineage.nodes[listKey]?.x}
                                y={lineage.nodes[listKey]?.y}
                                height={'5000%'}
                                width={'15%'}
                                key={index}
                                id={`nodeRootContainer_${lineage.nodes[listKey]?.id}`}
                                ref={setRef(`nodeRootContainer_${lineage.nodes[listKey]?.id}`)}
                            >
                                <div style={LineageTableauStyle.tableContainer}>
                                    <div style={LineageTableauStyle.tableHeader}>
                                        <img src={lineageIcons[`${listKey?.replace('upstream', '').toLocaleLowerCase()}Tableau`]} alt={`${listKey?.replace('upstream', '').toLocaleLowerCase()}Tableau`} style={LineageTableauStyle.headerImage} />
                                        <Typography style={LineageTableauStyle.headerText}>
                                            {listKey?.replace('upstream', '')}
                                            {
                                                listKey?.replace('upstream', '') !== 'workbook' ?
                                                    `(${lineage.nodes[listKey]?.data?.length})` : ` - ${workbookName}`
                                            }
                                        </Typography>
                                    </div>
                                    <div style={LineageTableauStyle.listParent}>
                                        {
                                            listKey?.replace('upstream', '') === 'workbook' &&
                                            (
                                                <span style={LineageTableauStyle.embededDatasourceContainer}>
                                                    Embeded datasource
                                                </span>)
                                        }
                                        <ul style={LineageTableauStyle.listContainer}>
                                            {
                                                lineage.nodes[listKey]?.data?.map((item, index) => {
                                                    let selectedItem = (lineagePathNodes.indexOf(item?.id) !== -1) ? 1 : 2;
                                                    selectedItem = (item?.id === searchSeletedNode) ? 3 : selectedItem;
                                                    return (
                                                        <li style={LineageTableauStyle.listItem(selectedItem)} key={index} ref={setRef(`child_${item.id}`)} onClick={(event) => { displayLineageEdges(event, item?.id); }} id={`child_${item.id}`}>
                                                            <div style={LineageTableauStyle.parentNameContainer}>
                                                                {item?.name || 'SENSITIVE DATA'}
                                                                <Box style={LineageTableauStyle.actionContainer}>
                                                                    {sourceCodeMapper[item?.id] && <img src={lineageIcons.sourceCodeTableau} alt={'source code'} style={LineageTableauStyle.actionImage} onClick={(event) => displaySourceCode(event, item?.id)} />}
                                                                    {item?.fields && !childrenToggleMapper[item?.id] && <img src={lineageIcons.expandTableau} alt={'expandTableau'} style={LineageTableauStyle.actionImage} onClick={(event) => childToggle(event, item?.id, item?.fields.map((d) => d?.id))} />}
                                                                    {item?.fields && childrenToggleMapper[item?.id] && <img src={lineageIcons.expandCloseTableau} alt={'expandCloseTableau'} style={LineageTableauStyle.actionImage} onClick={(event) => childToggle(event, item?.id, item?.fields.map((d) => d?.id))} />}
                                                                </Box>
                                                            </div>
                                                            {
                                                                item?.fields && childrenToggleMapper[item?.id] &&
                                                                <ul style={LineageTableauStyle.childrenItemsContainer}>
                                                                    {
                                                                        item?.fields.map((data, index) => {
                                                                            let selectedItem = (lineagePathNodes.indexOf(data?.id) !== -1) ? 1 : 2;
                                                                            selectedItem = (data?.id === searchSeletedNode) ? 3 : selectedItem;
                                                                            return (
                                                                                <li style={LineageTableauStyle.childrenItem(selectedItem)} key={index} ref={setRef(`child_${data.id}`)} id={`child_${data.id}`} onClick={(event) => { displayLineageEdges(event, data?.id); }}>
                                                                                    {data.name}
                                                                                </li>
                                                                            );
                                                                        })
                                                                    }
                                                                </ul>
                                                            }
                                                        </li>
                                                    );
                                                }
                                                )
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </foreignObject>
                        ))
                    }
                </g>
            </svg>
            <AlertDialog title={"Source SQL"}
                message={
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                        {sourceCode}
                    </div>
                }
                okButtonText="OK"
                show={toggleSourceCode}
                onClickOK={() => setToggleSourceCode(false)} />
        </React.Fragment>
    );
};

LineageTableau.propTypes = {
    datasource_id: PropTypes.number,
    workbook_id: PropTypes.string
};

export default LineageTableau;