import React, { useState, useEffect, useCallback } from 'react';
import { Grid, withStyles, Button, FormLabel } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { getOrganizationToken, revokeOrganizationToken, generateOrganizationToken } from '../../actions/settingActions';
import TextBox from '../TextBox/TextBox.jsx';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { copyToClipBoard } from '../../helpers/appHelpers';
import { ValidatorForm } from 'react-material-ui-form-validator';

const Api = (props) => {
    const { classes, organizationId } = props;

    const [tokenDetail, setTokenDetail] = useState({});
    const dispatch = useDispatch();

    const getToken = useCallback(() => {
        dispatch(getOrganizationToken(organizationId)).then((response) => {
            if (response) {
                setTokenDetail({ ...response });
            }
        });
    }, [dispatch, organizationId]);

    const generateToken = () => {
        dispatch(generateOrganizationToken(organizationId)).then((response) => {
            if (response) {
                setTokenDetail({ ...response });
            }
        });
    };

    const revokeToken = () => {
        dispatch(revokeOrganizationToken(organizationId)).then((response) => {
            if (response) {
                setTokenDetail({ ...response });
            }
        });
    };

    useEffect(() => {
        if (organizationId !== 0) {
            getToken();
        }
    }, [getToken, organizationId]);

    return (
        <ValidatorForm>
            <Grid className={classes.marginTop10}>
                {
                    organizationId !== 0 && tokenDetail && !tokenDetail.client_id ?
                        <Button onClick={() => generateToken()} variant="contained" color="primary" className={classNames(classes.actionButtons, classes.submitBtn)}>
                            Generate Token
                        </Button> :
                        <Grid>
                            <Grid container spacing={4}>
                                <Grid item xs={4} className={classes.tokenSection}>
                                    <FormLabel>
                                        Client ID
                                    </FormLabel>
                                    <TextBox
                                        value={tokenDetail.client_id ? tokenDetail.client_id : ""}
                                        margin="normal"
                                        fullWidth
                                        readOnly
                                        id="client_id"
                                        className={classNames(classes.inputOutline)}
                                    />
                                    <Button onClick={() => copyToClipBoard("client_id")} variant="contained" className={classNames(classes.cancelButton, "button")}>
                                        <FileCopyIcon />
                                    </Button>
                                </Grid>
                                <Grid item xs={8} className={classes.tokenSection}>
                                    <FormLabel>
                                        Client Secret
                                    </FormLabel>
                                    <TextBox
                                        value={tokenDetail.client_token ? tokenDetail.client_token : ""}
                                        margin="normal"
                                        id="client_token"
                                        fullWidth
                                        readOnly
                                        className={classNames(classes.inputOutline)}
                                    />
                                    <Button onClick={() => copyToClipBoard("client_token")} variant="contained" className={classNames(classes.cancelButton, "button")}>
                                        <FileCopyIcon />
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button onClick={() => generateToken()} variant="contained" color="primary" className={classNames(classes.actionButtons, classes.submitBtn)}>
                                        Re-Generate Token
                                    </Button>
                                    <Button onClick={() => revokeToken()} variant="contained" className={classNames(classes.actionButtons, classes.cancelButton)}>
                                        Revoke Token
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                }
            </Grid>
        </ValidatorForm>
    );
};

Api.propTypes = {
    classes: PropTypes.object,
    organizationId: PropTypes.number
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}))(Api);