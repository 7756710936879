import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import AppComponent from './App';

import * as serviceWorker from './serviceWorker';


import configureStore from './config/storeConfig';
export const store = configureStore();
ReactDOM.render(
	<Provider store={store}>
		<Router>
			<AppComponent />
		</Router>
	</Provider>,
	document.getElementById('root'));

/*
 * If you want your app to work offline and load faster, you can change
 * The unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();