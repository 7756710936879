import React, { Component } from 'react';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import { MenuItem, Paper, withStyles, Backdrop } from '@material-ui/core';
import TextBox from '../TextBox/TextBox.jsx';
import AutoCompleteStyles from './AutoCompleteStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';

const renderInput = (textInputProps) => {
    const { InputProps, inputProps, classes, ref, inputRef, removeClass, textBoxStyles, inputStyles, scheduleRef, direction, ...other } = textInputProps;
    return (
        <TextBox ref={inputRef}
            autoComplete="off"
            className={classNames(!removeClass ? classes.inlinetxt : '', textBoxStyles)}
            InputProps={
                {
                    inputRef: ref,
                    ref: scheduleRef,
                    autoComplete: 'off',
                    classes: {
                        root: classes.inputRoot,
                        input: classNames(classes.inputInput, inputStyles)
                    },
                    ...InputProps
                }
            }
            {...other}
            inputProps={
                {
                    ...inputProps,
                    style: {
                        direction: direction ? direction : '',
                        textRendering: direction ? 'geometricPrecision' : 'auto'
                    },
                    autoComplete: 'off'
                }
            }
        />
    );
};

const renderSuggestion = (suggestionProps) => {
    const { suggestion, displayMemberPath, index, itemProps, highlightedIndex, selectedItem } = suggestionProps;
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion[displayMemberPath]) > -1;
    return (
        <MenuItem
            {...itemProps}
            key={suggestion[displayMemberPath] + index}
            selected={isHighlighted}
            component="div"
            disabled={suggestion.disabled}
            style={
                {
                    fontWeight: isSelected ? 500 : 400,
                    textTransform: 'capitalize',
                    overflow: 'initial',
                    padding: '8px 16px'
                }
            }
        >
            {suggestion[displayMemberPath]}
        </MenuItem>
    );
};

const getSuggestions = (value, { options, displayMemberPath, showEmpty = false, openSuggestionsOnClick, isInputChanged } = {}) => {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    if (openSuggestionsOnClick && !isInputChanged) { return options; }

    const suggestions = inputLength === 0 && !showEmpty
        ? []
        : options.filter((suggestion) => suggestion[displayMemberPath].toLowerCase().includes(inputValue.toLowerCase()));
    return suggestions;
};

class AutoComplete extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isInputChanged: false
        };
    }

    handleChange(selectedItem) {
        if (!selectedItem) {
            this.props.onChange({
                target: {
                    value: '',
                    selectedItem: ''
                }
            });
            return;
        }

        const { options, displayMemberPath, selectedValuePath } = this.props;
        const selectedOption = options.find((p) => p[displayMemberPath].toLowerCase() === selectedItem.toLowerCase());
        if (selectedOption) {
            this.props.onChange({
                target: {
                    value: selectedOption[selectedValuePath],
                    selectedItem: selectedOption
                }
            });
        }
    }

    /**
     * Set the autocomplete dropdown position
     * @param {*} inputPosition
     * @output {top, left} position
     */
    getPlacementPosition = (inputPosition) => {
        return this.props.noDropPostion ? {} : { top: inputPosition.bottom, left: inputPosition.left };
    }

    getDropdownPosition = (inputElement) => {
        if (!inputElement) { return; }
        const viewportHeight = window.innerHeight;
        const inputPosition = inputElement.getBoundingClientRect();
        const itemCount = this.props.options && this.props.options.length > 0 ? this.props.options.length : 0;
        const componentPosition = itemCount > 5 ? (inputPosition.bottom + 200) : (inputPosition.bottom + (itemCount * 30));
        const dropdownPosition = (viewportHeight > componentPosition) ? this.getPlacementPosition(inputPosition) : { bottom: viewportHeight - (inputPosition.top + inputPosition.height), left: (inputPosition.left - (inputPosition.width / 2)) };
        return dropdownPosition;
    }

    render() {
        const { classes, id, placeholder, label, name, disabled, displayMemberPath, inputRef, onInputValueChange, direction,
            options, value, style, removeClass, inputStyles, textBoxStyles, openSuggestionsOnClick, scheduleRef, subComponent, position } = this.props;
        const { isInputChanged } = this.state;
        return (
            <div className={classes.root}>
                <Downshift selectedItem={value !== '' ? value : null} onChange={(item) => this.handleChange(item)}>
                    {
                        ({
                            clearSelection,
                            getInputProps,
                            getItemProps,
                            getLabelProps,
                            getMenuProps,
                            highlightedIndex,
                            isOpen,
                            inputValue,
                            openMenu,
                            closeMenu,
                            selectedItem
                        }) => {
                            const { onBlur, onChange, onFocus, onKeyDown, ...inputProps } = getInputProps({
                                onChange: (event) => {
                                    this.setState({ isInputChanged: true });
                                    if (onInputValueChange) {
                                        onInputValueChange(event.target.value);
                                    }

                                    if (event.target.value === '') {
                                        clearSelection();
                                    }
                                },
                                onFocus: (event) => {
                                    openMenu(event);
                                    if (direction && event.currentTarget) {
                                        event.currentTarget.style.direction = 'ltr';
                                        event.currentTarget.style.textRendering = 'auto';
                                    }
                                    this.setState({ isInputChanged: false });
                                    this.inputElement = event.currentTarget;
                                    if (this.props.onFocus) { this.props.onFocus({ currentTarget: event.currentTarget }); }
                                },
                                placeholder,
                                label,
                                name,
                                disabled,
                                onBlur: (event) => {
                                    if (direction && event.currentTarget) {
                                        event.currentTarget.style.direction = 'rtl';
                                        event.currentTarget.style.textRendering = 'geometricPrecision';
                                    }
                                    this.setState({ isInputChanged: false });
                                    if (this.props.onBlur) { this.props.onBlur(event); }
                                },
                                onKeyDown: (event) => {
                                    this.setState({ isInputChanged: true });
                                    if (this.props.onKeyDown) { this.props.onKeyDown(event); }
                                }
                            });

                            return (
                                <div className={classes.container}>
                                    {
                                        renderInput({
                                            fullWidth: true,
                                            classes,
                                            label,
                                            id,
                                            InputLabelProps: getLabelProps({ shrink: true }),
                                            InputProps: { onBlur, onChange, onFocus, onKeyDown },
                                            inputProps,
                                            validators: this.props.validators ? this.props.validators : [],
                                            errorMessages: this.props.errorMessages ? this.props.errorMessages : [],
                                            value,
                                            style,
                                            removeClass,
                                            inputStyles: inputStyles ? inputStyles : '',
                                            textBoxStyles: textBoxStyles ? textBoxStyles : '',
                                            scheduleRef,
                                            inputRef,
                                            direction
                                        })
                                    }

                                    <div {...getMenuProps()}>
                                        {isOpen ? <Backdrop style={{ zIndex: 1330 }} open={isOpen} onClick={closeMenu} /> : null}
                                        {
                                            isOpen ? (
                                                <Paper className={classNames(classes.paper)}
                                                    square
                                                    elevation={8}
                                                    style={this.inputElement ? { ...this.getDropdownPosition(this.inputElement, subComponent, position) } : { top: -9999 }}>
                                                    {
                                                        getSuggestions(inputValue, { options, displayMemberPath, showEmpty: true, openSuggestionsOnClick, isInputChanged }).map((suggestion, index) =>
                                                            renderSuggestion({
                                                                suggestion,
                                                                displayMemberPath,
                                                                index,
                                                                itemProps: getItemProps({ item: suggestion[displayMemberPath] }),
                                                                highlightedIndex,
                                                                selectedItem
                                                            })
                                                        )
                                                    }
                                                </Paper>
                                            ) : null
                                        }

                                    </div>
                                </div>
                            );
                        }
                    }
                </Downshift>
            </div>
        );
    }
}
AutoComplete.propTypes = {
    classes: PropTypes.object,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    displayMemberPath: PropTypes.string,
    inputRef: PropTypes.element,
    options: PropTypes.array,
    value: PropTypes.string,
    style: PropTypes.object,
    removeClass: PropTypes.bool,
    inputStyles: PropTypes.string,
    textBoxStyles: PropTypes.string,
    openSuggestionsOnClick: PropTypes.bool,
    scheduleRef: PropTypes.element,
    selectedValuePath: PropTypes.string,
    onChange: PropTypes.func,
    validators: PropTypes.array,
    errorMessages: PropTypes.array,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    subComponent: PropTypes.bool,
    position: PropTypes.object,
    noDropPostion: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    onInputValueChange: PropTypes.func,
    direction: PropTypes.string
};


export default withStyles((theme) => ({
    ...AutoCompleteStyles(theme),
    ...Styles(theme)
}))(AutoComplete);