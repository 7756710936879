import React, { useEffect } from "react";
import { Grid, MenuItem, Typography, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as d3 from "d3";
import Styles from "../../layouts/Styles.jsx";
import ChartStyles from "./ChartStyles.jsx";
import { wrapText, getValue } from "../../helpers/appHelpers";
// import noprofile from "../../assets/images/np_profile_data.jpg";
import moment from "moment";
import TextBox from "../TextBox/TextBox.jsx";
import { ValidatorForm } from "react-material-ui-form-validator";

const StackedBarChart = (props) => {
    const {
        classes,
        chartData,
        chartClassName,
        theme,
        colors,
        xAxis,
        yAxis,
        legends,
        showXAxis,
        showYAxis,
        height,
        onFilter,
        xAxisType,
        yAxisType,
        widgetDirection,
        tooltipLabelType,
        showPercentage,
        chartFilter = false,
        mouseOverData,
        xAxisTypeDate = true
    } = props;


    useEffect(() => {
        const Yvalue = chartData?.map((data) => data[yAxis]);
        const Highval = Yvalue?.sort((a, b) => b.length - a.length);
        let maxWidth = Highval && Highval.length > 0 && Highval[0] ? Highval[0].length : 15;

        if (maxWidth < 7) {
            maxWidth = 7;
        }

        if (chartData && chartData.length) {
            let width = 300;
            const margin = { top: 15, right: 10, bottom: 0, left: (maxWidth * 7.5) };
            let height = widgetDirection === "vertical" ? 400 : chartData.length * 40;
            const barHeight = 25;
            let totalRecords = 0;
            if (showPercentage) {
                totalRecords = chartData.reduce((a, b) => a + (b.total || 0), 0);
            }


            d3.select(`.${chartClassName}_chart > svg`).remove();
            d3.select(`.${chartClassName} > .legend-container`).remove();

            d3.select(`.${chartClassName}_chart > .tooltip`).remove();


            const toolTip = d3
                .select(`.${chartClassName}_chart`)
                .append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            const svg = d3
                .select(`.${chartClassName}_chart`)
                .append("svg")
                .attr("width", (_, i, p) => {
                    width = p[i].parentNode.clientWidth;
                    if (width <= 200) {
                        margin.left = 50;
                    }
                    if (widgetDirection === "vertical") {
                        height = p[i].parentNode.clientHeight - margin.top;
                        margin.left = 50;
                    }
                })
                .attr("class", `${chartClassName}_chart`)
                .attr("viewBox", [0, 0, width, height])
                .attr("overflow", "auto")
                .append("g");
            let y;
            let x;

            if (yAxisType === "number") {
                y = d3.scaleLinear()
                    .domain([0, d3.max(chartData, (d) => { return d[yAxis]; })])
                    .range([height - margin.top, margin.top])
                    .nice();
            } else {
                y = d3
                    .scaleBand()
                    .domain(chartData.map((d) => d[yAxis]))
                    .range([margin.top, height - margin.bottom])
                    .padding(0.08);
            }
            const zScale = d3.scaleOrdinal().domain(legends).range(colors);

            if (xAxisType === "string") {
                x = d3
                    .scaleBand()
                    .domain(chartData.map((data) => data[xAxis]))
                    .range([margin.left, width - margin.right])
                    .padding(0.2);
            } else {
                x = d3
                    .scaleLinear()
                    .domain([
                        0,
                        d3.max(chartData, (d) => {
                            return d[xAxis];
                        })
                    ])
                    .range([margin.left, width - margin.right]);
            }

            if (showXAxis) {
                if (widgetDirection === "vertical") {
                    svg
                        .append("g")
                        .attr("transform", `translate(0,${height - (margin.top + (margin.top / 2))})`)
                        .attr("class", "axis-x")
                        .call(d3.axisBottom(x))
                        .call((g) => g.selectAll(".domain").remove());
                } else {
                    svg
                        .append("g")
                        .attr("transform", `translate(0,${margin.top})`)
                        .attr("class", "axis-x")
                        .call(d3.axisTop(x))
                        .call((g) => g.selectAll(".domain").remove());
                }
            }

            if (showYAxis) {
                svg
                    .append("g")
                    .attr("transform", `translate(${margin.left},0)`)
                    .attr("class", "axis-y")
                    .call(d3.axisLeft(y).tickSizeOuter(0))
                    .call((g) => g.selectAll(".domain").remove());
            }

            if (showXAxis || showYAxis) {
                svg.selectAll(".tick").selectAll("text").attr("class", "tick-text");
                svg.selectAll(".tick").selectAll("line").remove();
                if (xAxisType === "string") {
                    svg.selectAll(".axis-x text").text((d) => {
                        if (xAxisTypeDate) {
                            return moment(d).format("MM/DD/YYYY");
                        }
                        return d;
                    });
                    svg.selectAll('.axis-x .tick-text').text("").append('tspan').text((d) => {
                        if (xAxisTypeDate) {
                            return moment(d).format("MM/DD/YYYY");
                        }
                        return d;
                    }).each((d, i, nodes) => {
                        const textElement = d3.select(nodes[i]);
                        wrapText(textElement, x.bandwidth());
                    });
                }
                if (yAxisType !== "number") {
                    svg
                        .selectAll(".axis-y .tick-text")
                        .text("")
                        .append("tspan")
                        .text((d) => {
                            d = typeof d === "number" ? d.toString() : d;
                            if (d === "") {
                                return "Blank";
                            } else if (d && d.trim() === "" && d.length > 0) {
                                return "Space";
                            } else if (d === "VALUE_IS_EMPTY") {
                                return "Null";
                            }
                            return d;
                        })
                        .on("mouseover", (d, i, p) => {
                            // eslint-disable-next-line no-unused-vars
                            const x = d3.mouse(p[i])[0] - 5;
                            const y = d3.mouse(p[i])[1] - 5;
                            d3.select(p[i]).style("opacity", 0.6);
                            toolTip.transition().duration(200).style("opacity", 0.9);
                            const parent = d3.select(p[i]).node().parentNode;
                            const yValue = d3.select(parent).datum().key;
                            toolTip
                                .html(`${yValue ? yValue : "Count"} : ${d}`)
                                .style("left", 0 + "px")
                                .style("top", 35 * (i + 1) + y + "px");
                        })
                        .on("mouseout", (_, i, p) => {
                            d3.select(p[i]).style("opacity", 1);
                            toolTip.transition().duration(500).style("opacity", 0);
                        })
                        .each((d, i, nodes) => {
                            const textElement = d3.select(nodes[i]);
                            wrapText(textElement, margin.left - 20);
                        })
                        .style("font-style", (d) => {
                            d = typeof d === "number" ? d.toString() : d;
                            if (
                                d === "VALUE_IS_EMPTY" ||
                                (d.trim() === "" && d.length > 0) ||
                                d === ""
                            ) {
                                return "italic";
                            }
                            return "";
                        });
                }
            }

            const series = d3
                .stack()
                .keys(legends)
                .order(d3.stackOrderNone)
                .offset(d3.stackOffsetNone)(chartData);

            const g = svg
                .append("g")
                .selectAll("g")
                .data(series)
                .join("g")
                .attr("fill", (d) => zScale(d.key));

            if (widgetDirection === "vertical") {
                g.selectAll("rect")
                    .data((d) => d)
                    .join("rect")
                    .attr("x", (d) => { return x(d.data[xAxis]); })
                    .attr("y", (d) => { return y(d[1]); })
                    .attr("height", height)
                    .attr("width", x.bandwidth())
                    .attr("height", (d) => { return y(d[0]) - y(d[1]); })
                    .on("click", (d, i, p) => {
                        const parent = d3.select(p[i]).node().parentNode;
                        const xValue = d3.select(parent).datum().key;
                        onFilter({
                            xaxis_value: xValue,
                            yaxis_value: d.data[yAxis]
                        });
                    })
                    .on("mouseover", (d, i, p) => {
                        const x = d3.mouse(p[i])[0] - 5;
                        const y = d3.mouse(p[i])[1] - 5;
                        d3.select(p[i]).style("opacity", 0.6);
                        toolTip.transition().duration(200).style("opacity", 0.9);
                        const parent = d3.select(p[i]).node().parentNode;
                        const yValue = d3.select(parent).datum().key;
                        if (mouseOverData) {
                            toolTip
                                .html(`Datasource: ${mouseOverData.datasource} <br /> Dataset: ${mouseOverData.dataset} <br /> Attribute : ${mouseOverData.attribute} <br /> Category Name : ${yValue} <br /> Count : ${d[1] - d[0]}`)
                                .style("left", x + "px")
                                .style("top", y + "px");
                        } else {
                            toolTip
                                .html(`${yValue ? yValue : "Count"} : ${d[1] - d[0]}`)
                                .style("left", x + "px")
                                .style("top", y + "px");
                        }

                    })
                    .on("mouseout", (_, i, p) => {
                        d3.select(p[i]).style("opacity", 1);
                        toolTip.transition().duration(500).style("opacity", 0);
                    });
            } else {
                g.selectAll("rect")
                    .data((d) => d)
                    .join("rect")
                    .attr("y", (d) => {
                        return widgetDirection === "vertical" ? y(d.data[yAxis]) + (barHeight / 2) : y(d.data[yAxis]) + (y.bandwidth() / 2 - barHeight / 2);
                    })
                    .attr("height", barHeight)
                    .attr("x", (d) => x(d[0]))
                    .attr("width", (d) => x(d[1]) - x(d[0]))
                    .on("click", (d, i, p) => {
                        const parent = d3.select(p[i]).node().parentNode;
                        const xValue = d3.select(parent).datum().key;
                        onFilter({
                            xaxis_value: xValue,
                            yaxis_value: d.data[yAxis]
                        });
                    })
                    .on("mouseover", (d, i, p) => {
                        const x = d3.mouse(p[i])[0] - 5;
                        const y = d3.mouse(p[i])[1] - 5;
                        d3.select(p[i]).style("opacity", 0.6);
                        toolTip.transition().duration(200).style("opacity", 0.9);
                        if (showPercentage) {
                            const percentage = ((d[1] - d[0]) / totalRecords) * 100;
                            toolTip
                                .html(`${yAxis} : ${d.data[yAxis]} <br/> Percentage : ${getValue(percentage)}% <br/> Count : ${d[1] - d[0]}`)
                                .style("left", x + "px")
                                .style("top", y + "px");
                        } else {
                            toolTip
                                .html(`${yAxis} : ${d.data[yAxis]} <br/> Count : ${d[1] - d[0]}`)
                                .style("left", x + "px")
                                .style("top", y + "px");
                        }

                    })
                    .on("mouseout", (_, i, p) => {
                        d3.select(p[i]).style("opacity", 1);
                        toolTip.transition().duration(500).style("opacity", 0);
                    });
            }

            // Display bar data in text
            g.selectAll("text")
                .data((d) => d)
                .enter()
                .append("text")
                .attr("class", "dynamicdashboard")
                .style("font-size", "12px")
                .style("font-weight", 500)
                .text((d) => {
                    return `${getValue(d[1] - d[0])}`;
                })
                .attr("x", (d, i, p) => {
                    if (widgetDirection === "vertical") {
                        const height = d3.select(p[i]).node().getBoundingClientRect().height;
                        return x(d.data[xAxis]) + (x.bandwidth() / 2 + height / 2 - 20);
                    }
                    const textLength = d3.select(p[i]).node().getComputedTextLength();
                    return x(d[0]) + (x(d[1]) - x(d[0])) / 2 - textLength / 2;
                })
                .attr("y", (d, i, p) => {
                    const height = d3.select(p[i]).node().getBoundingClientRect().height;
                    if (widgetDirection === "vertical") {
                        // const textLength = d3.select(p[i]).node().getComputedTextLength();
                        return y(d[0]) + (y(d[1]) - y(d[0])) / 2 + height / 2;
                    }
                    // const height = d3.select(p[i]).node().getBoundingClientRect().height;
                    return y(d.data[yAxis]) + (y.bandwidth() / 2 + height / 2 - 3);
                })
                .attr("height", barHeight)
                .attr("width", (d) => {
                    return x(d[1]) - x(d[0]);
                })
                .attr("fill", "#222");

            svg.selectAll("text.dynamicdashboard").each((d, i, nodes) => {
                const element = d3.select(nodes[i]);
                let text = element.text();
                const width = widgetDirection === "vertical" ? (y(d[0]) - y(d[1])) - 2 : x(d[1]) - x(d[0]);
                let textLength = element.node().getComputedTextLength();
                while (textLength > width && text.length > 0) {
                    text = text.slice(0, -1);
                    element.text("");
                    textLength = element.node().getComputedTextLength();
                }
            }).on('click', (d, i, p) => {
                const parent = d3.select(p[i]).node().parentNode;
                const xValue = d3.select(parent).datum().key;
                onFilter({
                    "xaxis_value": xValue,
                    "yaxis_value": d.data[yAxis]
                });
            });
            if (legends.length > 0) {
                const legendsContainer = d3
                    .select(`.${chartClassName}`)
                    .append("div")
                    .attr("class", "legend-container");

                legendsContainer
                    .selectAll(".legend-container")
                    .data(legends)
                    .enter()
                    .append("div")
                    .attr("class", "legend-column");

                legendsContainer
                    .selectAll(".legend-column")
                    .data(legends)
                    .append("div")
                    .style("background-color", (d) => zScale(d))
                    .attr("class", "legend-pattern")
                    .attr('tooltip', (legend) => legend)
                    .on("mouseover", (d, i, p) => {
                        let x = 0;
                        let y = 0;
                        toolTip.attr("transform", (_, i, nodes) => {
                            const mouseCoords = d3.mouse(nodes[i].parentNode);
                            let xCo = 0;
                            let yCo = 0;
                            if (mouseCoords[0] + 10 >= width * 0.8) {
                                xCo = mouseCoords[0] - parseFloat(toolTip.attr("width"));
                                yCo = mouseCoords[1] + 10;
                            } else {
                                xCo = mouseCoords[0] + 10;
                                yCo = mouseCoords[1];
                            }
                            x = xCo;
                            y = yCo;
                        });
                        d3.select(p[i]).style("opacity", 0.6);
                        toolTip.transition().duration(200).style("opacity", 0.9);
                        toolTip
                            .html(d)
                            .style("left", x + "px")
                            .style("top", y + "px");
                    })
                    .on("mouseout", (_, i, p) => {
                        d3.select(p[i]).style("opacity", 1);
                        toolTip.transition().duration(500).style("opacity", 0);
                    });

                legendsContainer
                    .selectAll(".legend-column")
                    .data(legends)
                    .append("span")
                    .attr("class", "legend-text")
                    .style("text-transform", "capitalize")
                    .text((legend) => legend);

            }
        }
    }, [chartClassName, chartData, colors, legends, onFilter, showXAxis, showYAxis, theme.palette.chartColors.axis, xAxis, yAxis, yAxisType, xAxisType, widgetDirection, tooltipLabelType, showPercentage]);

    const DropDownIcon = (iconProps) => {
        return (
            <svg {...iconProps} className={classes.DropDownIcon} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="12px" height="18px" viewBox="0 0 451.847 451.847">
                <g>
                    <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" fill={theme.palette.grey.dark} />
                </g>
            </svg>
        );
    };

    return (
        <Grid
            style={{ padding: 20 }}
            className={
                classNames(classes.chartStyles, classes.widgetContainer, chartClassName, {
                    chartClassName: chartData.length !== 0
                })
            }
        >
            {
                chartData.length === 0 && (
                    // eslint-disable-next-line multiline-comment-style
                    // <Grid align="center">
                    //     <img src={noprofile} alt="" style={{ maxWidth: "100%", marginBottom: 20 }} />
                    //     <Typography variant="h4" align="center" color="textSecondary">
                    //         No Data Found
                    //     </Typography>
                    // </Grid>
                    <Grid className={classes.totalCountContainer} container>
                        <Grid item xs={12}>
                            <Typography component="h1" variant="h1" style={{ fontSize: 36 }}>
                                0
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography component="h4" variant="h4" style={{ color: theme.palette.grey.main }}>
                                {'Records'}
                            </Typography>
                        </Grid>
                    </Grid>
                )
            }
            {
                chartData.length !== 0 && (
                    <Grid container>
                        {
                            chartFilter &&
                            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'right' }}>
                                <ValidatorForm>
                                    <TextBox
                                        className={classNames(classes.inlinetxt, classes.minWidth)}
                                        onChange={(event) => chartFilter.onFilter && chartFilter.onFilter(event.target.value)}
                                        value={chartFilter.value}
                                        select
                                        SelectProps={
                                            {
                                                MenuProps: {
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "center"
                                                    },
                                                    transformOrigin: {
                                                        vertical: "top",
                                                        horizontal: "center"
                                                    },
                                                    getContentAnchorEl: null
                                                },
                                                IconComponent: (iconProps) => DropDownIcon(iconProps)
                                            }
                                        }
                                    >
                                        {
                                            chartFilter.properties.map((option, index) => (
                                                <MenuItem key={`menuProperty_Options_${index}`} value={option.value} className={classes.menuItem}>
                                                    {option.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </TextBox>
                                </ValidatorForm>
                            </Grid>
                        }
                        <Grid
                            item
                            xs={12}
                            className={
                                classNames(
                                    `${chartClassName}_chart`,
                                    classes.chartContainer
                                )
                            }
                            style={{ height: height - 100 }}
                        />
                    </Grid>
                )
            }
        </Grid>
    );
};

StackedBarChart.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.array,
    theme: PropTypes.object,
    chartClassName: PropTypes.string,
    colors: PropTypes.array,
    xAxis: PropTypes.string,
    yAxis: PropTypes.string,
    legends: PropTypes.array,
    showXAxis: PropTypes.bool,
    showYAxis: PropTypes.bool,
    height: PropTypes.number,
    onFilter: PropTypes.number,
    xAxisType: PropTypes.string,
    yAxisType: PropTypes.string,
    widgetDirection: PropTypes.string,
    tooltipLabelType: PropTypes.string,
    showPercentage: PropTypes.bool,
    chartFilter: PropTypes.bool,
    mouseOverData: PropTypes.object,
    xAxisTypeDate: PropTypes.bool
};

export default withStyles(
    (theme) => ({
        ...ChartStyles(theme),
        ...Styles(theme)
    }),
    { withTheme: true }
)(StackedBarChart);