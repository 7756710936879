import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Button, MenuItem } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextBox from '../../TextBox/TextBox.jsx';
import Styles from '../../../layouts/Styles.jsx';
import Loader from '../../Loaders/Loader.jsx';
import { appConstants } from '../../../constants/appConstants';
import { sortTable } from '../../../helpers/appHelpers.js';

const MSSQL = (props) => {
    const { classes, onSave, onChange, storage, type, isLoading } = props;
    return (
        <ValidatorForm onSubmit={() => onSave(type)}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextBox
                        label="Authentication Type"
                        name="authType"
                        validators={['required']}
                        errorMessages={['Authentication type is required']}
                        value={storage.authType ? storage.authType : ''}
                        select
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                    >
                        {
                            sortTable(appConstants.sqlAuthTypes, "asc", "name").map((authType, index) => {
                                return (
                                    <MenuItem key={`authType_${index}`} value={authType}>
                                        {authType}
                                    </MenuItem>
                                );
                            })
                        }
                    </TextBox>
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4}>
                    <TextBox
                        label="Server"
                        name="server"
                        validators={['required']}
                        errorMessages={['Server is required']}
                        value={storage.server ? storage.server : ''}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextBox
                        label="Database Name"
                        name="database"
                        errorMessages={['Database name is required']}
                        value={storage.database ? storage.database : ""}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4}>
                    <TextBox
                        label="Port"
                        name="port"
                        validators={['required']}
                        errorMessages={['port is required']}
                        value={storage.port ? storage.port : ""}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={4}>
                    <TextBox
                        label="Username"
                        name="username"
                        value={storage.username ? storage.username : ""}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                        validators={['required']}
                        errorMessages={['Username is required']}
                    />
                </Grid>
                <Grid item xs={2} />
                <Grid item xs={4}>
                    <TextBox
                        label="Password"
                        name="password"
                        validators={['required']}
                        errorMessages={['password is required']}
                        type="password"
                        value={storage.password ? storage.password : ""}
                        onChange={(event) => onChange(type, event.target.name, event.target.value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button disabled={isLoading} variant="contained" color="primary" type="submit" className={classes.actionButtons}>
                        {'Connect'}
                        {isLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                    </Button>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
};

MSSQL.propTypes = {
    storage: PropTypes.object,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    classes: PropTypes.object,
    type: PropTypes.string,
    isLoading: PropTypes.bool
};


export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(MSSQL);