import TableBlack from '../../assets/images/lineageIcons/table_black.svg';
import TableWhite from '../../assets/images/lineageIcons/table_white.svg';

import ReturnBlack from '../../assets/images/lineageIcons/return_black.svg';
import ReturnWhite from '../../assets/images/lineageIcons/return_white.svg';

import ReturnValueBlack from '../../assets/images/lineageIcons/return_value_black.svg';
import ReturnValueWhite from '../../assets/images/lineageIcons/return_value_white.svg';

import CreateViewBlack from '../../assets/images/lineageIcons/createview_black.svg';
import CreateViewWhite from '../../assets/images/lineageIcons/createview_white.svg';

import FunctionBlack from '../../assets/images/lineageIcons/function_black.svg';
import FunctionWhite from '../../assets/images/lineageIcons/function_white.svg';

import InsertBlack from '../../assets/images/lineageIcons/insert_black.svg';
import InsertWhite from '../../assets/images/lineageIcons/insert_white.svg';

import ColumnBlack from '../../assets/images/lineageIcons/column_black.svg';
import ColumnWhite from '../../assets/images/lineageIcons/column_white.svg';

import ParameterBlack from '../../assets/images/lineageIcons/parameter_black.svg';
import ParameterWhite from '../../assets/images/lineageIcons/parameter_white.svg';

import DefaultBlack from '../../assets/images/lineageIcons/default-black.svg';
import DefaultWhite from '../../assets/images/lineageIcons/default-white.svg';

import ArrowRight from '../../assets/images/lineageIcons/rightArrow.svg';
import ArrowDouble from '../../assets/images/lineageIcons/arrowDouble.svg';

import datasourceBlack from '../../assets/images/lineageIcons/datasource_black.svg';
import datasourceWhite from '../../assets/images/lineageIcons/datasource_white.svg';

import schemaBlack from '../../assets/images/lineageIcons/schema_black.svg';
import schemaWhite from '../../assets/images/lineageIcons/schema_white.svg';

import openWhite from '../../assets/images/lineageIcons/open_white.svg';
import closeWhite from '../../assets/images/lineageIcons/close_white.svg';

import SourcecodeWhite from '../../assets/images/lineageIcons/sourcecode_white.svg';
import SourcecodeBlack from '../../assets/images/lineageIcons/sourcecode_black.svg';

import dashboardsTableau from '../../assets/images/lineageIcons/dashboard_tableau.svg';
import sheetsTableau from '../../assets/images/lineageIcons/sheet_tableau.svg';
import ownerTableau from '../../assets/images/lineageIcons/owner_tableau.svg';
import tablesTableau from '../../assets/images/lineageIcons/table_tableau.svg';
import workbookTableau from '../../assets/images/lineageIcons/workbook_tableau.svg';
import databasesTableau from '../../assets/images/lineageIcons/database_tableau.svg';

import expandTableau from '../../assets/images/lineageIcons/expand_tableau.svg';
import expandCloseTableau from '../../assets/images/lineageIcons/expandClose_tableau.svg';

import sourceCodeTableau from '../../assets/images/lineageIcons/sourcecode_tableau.svg';


export const lineageIcons = {
    TableBlack,
    TableWhite,
    ReturnBlack,
    ReturnWhite,
    ReturnValueBlack,
    ReturnValueWhite,
    CreateViewBlack,
    CreateViewWhite,
    ViewBlack: TableBlack,
    ViewWhite: TableWhite,
    FunctionBlack,
    FunctionWhite,
    ProcedureBlack: FunctionBlack,
    ProcedureWhite: FunctionWhite,
    InsertBlack,
    InsertWhite,
    ColumnBlack,
    ColumnWhite,
    ColumnFlowBlack: ColumnBlack,
    ColumnFlowWhite: ColumnWhite,
    ParameterBlack,
    ParameterWhite,
    DefaultBlack,
    DefaultWhite,
    ArrowRight,
    ArrowDouble,
    datasourceBlack,
    datasourceWhite,
    schemaBlack,
    schemaWhite,
    openWhite,
    closeWhite,
    SourcecodeWhite,
    SourcecodeBlack,

    dashboardsTableau,
    sheetsTableau,
    ownerTableau,
    tablesTableau,
    workbookTableau,
    databasesTableau,

    expandTableau,
    expandCloseTableau,

    sourceCodeTableau
};