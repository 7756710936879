const DatasetStyles = (theme) => ({
    root: {
        flexGrow: '1 auto',
        height: '100%'
    },
    accordian: {
        '& .MuiAccordionSummary-root': {
            padding: "0px 13px",
            border: `1px solid ${theme.palette.border.default}`,
            minHeight: "50px",
            boxShadow: "0px 0px 2px #0000001A",
            position: "relative",
            marginBottom: 10
        },
        '& .MuiAccordionSummary-expandIcon': {
            position: "absolute",
            right: 5
        },
        '& .MuiAccordionSummary-content': {
            margin: 0
        },
        '& .MuiAccordionDetails-root': {
            padding: "2px 15px 15px 15px",
            background: theme.palette.background.default,
            border: `1px solid ${theme.palette.grey.extraLight}`
        },
        '& .MuiTableCell-root': {
            borderBottom: 0,
            padding: 10
        },
        '& .MuiTableCell-head': {
            color: '#1d2c2f',
            fontFamily: theme.palette.textFont,
            fontSize: 13,
            fontWeight: 500,
            textAlign: 'left'
        },
        '& .MuiTableBody-root .MuiTableRow-root': {
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.grey.extraLight}`
        },
        '& .MuiTableCell-body': {
            fontSize: 13,
            color: theme.palette.grey.darkTxt,
            // textAlign: 'left',
            '& p': {
                fontSize: 13,
                color: theme.palette.grey.darkTxt,
                height: '22px !important'
            }
        },
        '& .MuiAccordionSummary-root.Mui-expanded': {
            marginBottom: '0px'
        },
        '& .MuiAccordion-root:before': {
            backgroundColor: 'transparent'
        }
    },
    datasetTabText: {
        fontSize: 13,
        color: theme.palette.grey.darkTxt
    },
    marginTop5: {
        marginTop: 5
    },
    marginTop8: {
        marginTop: 8
    },
    selectAllCheckbox: {
        '& .MuiButtonBase-root': {
            padding: '5px'
        }
    },
    attributesTable: {
        maxHeight: 'calc(100vh - 250px)',
        overflow: 'auto',
        maxWidth: '100%',
        // border: `1px solid ${theme.palette.grey.extraLight}`,
        '& .MuiTableCell-root': {
            borderBottom: '1px solid #e1e5e6',
            padding: '10px'
        },
        '& .attributesTableChartRow': {
            backgroundColor: `${theme.palette.background.default} !important`,
            '& th svg path.recharts-tooltip-cursor': {
                backgroundColor: 'red',
                fill: 'red !important'
            }
        },
        '& .td.MuiTableCell-root p': {
            height: '22px !important'
        },
        '& .MuiTableCell-stickyHeader': {
            padding: '10px !important'
        }
    },
    attributeTitle: {
        '& .MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1': {
            fontWeight: 500
        }
    },
    attributesTableChartRow: {
        height: '60px',
        backgroundColor: `${theme.palette.background.default} !important`
    },
    attributeTableDataType: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left'
    },
    textboxContainer: {
        '& .MuiInput-underline:before': {
            height: '30px !important'
        },
        '& input': {
            width: 200,
            height: '30px !important',
            fontFamily: theme.palette.textFont
        },
        '& .MuiTextField-root': {
            width: 'inherit'
        }
    },
    valuesTextboxContainer: {
        '& .MuiInput-underline:before': {
            height: '30px !important'
        },
        '& input': {
            width: 60,
            height: '30px !important',
            fontFamily: theme.palette.textFont
        },
        '& .MuiTextField-root': {
            width: 'inherit'
        }
    },
    valuesListItemText: {
        color: `${theme.palette.grey.darkTxt} !important`,
        fontWeight: 400
    },
    valuesListItemTextClickable: {
        cursor: 'pointer',
        padding: '5px 0px',
        transition: 'padding-left 0.4s',
        maxWidth: '60%',
        '&:hover': {
            background: "#ededed !important",
            paddingLeft: '5px',
            transition: 'padding-left 0.4s'
        }
    },
    searchTextbox: {
        width: '180px !important',
        // marginTop: 8,
        '& .MuiInput-underline:before': {
            height: '30px !important'
        },
        '& input': {
            height: '30px !important',
            fontSize: 13
        }
    },
    propertiesTab: {
        marginTop: 10,
        '& .Mui-selected': {
            backgroundColor: '#f38080',
            color: '#fff'
        }
    },
    boxList: {
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        boxShadow: '2px 2px 15px #1D2C2F29',
        borderLeft: '4px solid red',
        height: '84px',
        marginBottom: '10px'
    },
    boxListTitle: {
        marginLeft: '10px'
    },
    boxListRightText: {
        color: theme.palette.grey.main
    },
    boxListDataType: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    boxListTypeText: {
        color: theme.palette.grey.default,
        textTransform: 'uppercase'
    },
    boxListButtons: {
        width: '75px',
        height: '24px',
        background: '#F6F8F9',
        color: '#646666',
        fontSize: '13px !important',
        border: '1px solid #e1e5e6'
    },
    buttonTabs: {
        minWidth: '75px',
        minHeight: '24px',
        fontSize: '12px',
        borderRadius: '4px'
    },
    buttonTabSection: {
        marginLeft: '20px'
    },
    listContainer: {
        padding: 10
    },
    listHeading: {
        color: '#AFB2B3',
        padding: '10px 0px'
    },
    listContent: {
        color: '#969899',
        fontStyle: 'italic'
    },
    newTabStyle: {
        background: '#F6F8F9',
        color: '#646666',
        fontSize: '13px',
        border: '1px solid #e1e5e5',
        marginRight: theme.spacing(1)
    },
    propertiesSection: {
        minHeight: 'calc(100vh - 350px)',
        marginTop: '10px',
        background: '#fff',
        border: '1px solid #e1e5e6',
        padding: '10px',
        overflowY: 'auto'
    },
    propertySubTitle: {
        color: '#646666',
        fontSize: '13px',
        marginLeft: '10px'
    },
    datasetDetailContent: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-end',
        alignItems: 'flex-start'
    },
    propertySliderContainer: {
        height: 'calc(100vh - 280px)',
        padding: 0,
        borderRadius: 2,
        border: '0 !important',
        position: 'relative',
        overflowX: 'hidden',
        overflowY: 'auto',
        '& .MuiTypography-body2': {
            color: theme.palette.grey.dark
        }
    },
    sliderNavText: {
        width: 'max-content',
        color: theme.palette.grey.dark,
        letterSpacing: 0.28
    },
    navButton: {
        padding: '8px 12px',
        '&:last-child': {
            // marginRight: 30
        },
        '&.Mui-disabled': {
            opacity: 0.6
        }
    },
    tooltip: {
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey.extraLight}`,
        borderRadius: 2,
        padding: 3,
        '& .MuiTypography-root': {
            color: theme.palette.grey.darkTxt
        }
    },
    listSubHeading: {
        color: theme.palette.grey.darkTxt,
        fontSize: '16px'
    },
    rightHeading: {
        marginLeft: '16px'
    },
    listPropertyInput: {
        '& .MuiInput-root': {
            width: '300px'
        }
    },
    attributesTableContainer: {
        position: 'relative',
        '&.loading': {
            height: 'calc(100vh - 250px)'
            // border: `1px solid ${theme.palette.grey.extraLight}`
        }
    },
    loaderContainer: {
        marginTop: 15,
        border: `1px solid ${theme.palette.grey.extraLight}`
    },
    AttributeSliderNav: {
        '& .MuiIconButton-root:hover': {
            '& img': {
                filter: 'invert(59%) sepia(65%) saturate(431%) hue-rotate(192deg) brightness(112%) contrast(225%) !important'
            }
        }
    },
    includeSearchContainer: {
        position: 'relative'
    },
    includeSearchIcon: {
        position: 'absolute',
        top: 1,
        right: 3,
        padding: 8
    },
    hoverBorderNone: {
        "& .MuiInputBase-root:hover": {
            "&:before": {
                borderBottom: `none !important`
            }
        }
    },
    AttritypeText: {
        display: 'flex',
        minWidth: 'auto !important',
        fontWeight: 600
    },
    dqSearchBox: {
        transitionDuration: '500ms',
        transition: 'width .55s ease',
        animation: 'slide-left220 0.4s ease',
        width: '220px',
        height: 30,
        '& .MuiInput-underline:before': {
            borderBottom: '1px solid #dcdcdc !important'
        }
    },
    dqSearchBoxAttributeRule: {
        transitionDuration: '500ms',
        transition: 'width .55s ease',
        animation: 'slide-left186 0.4s ease',
        width: '186px'
    },
    animateLeft: {
        '& .Mui-focused': {
            background: "#ededed !important"
        },
        '&:hover': {
            background: "#ededed !important",
            paddingLeft: '5px',
            transition: 'padding-left 0.4s'
        }
    },
    textBox: {
        width: '150px !important'
    },
    termsTitle: {
        fontWeight: 500,
        marginTop: 10
    },
    datasetInfo: {
        height: 'calc(100% - 215px) !important'
    },
    catalogInfo: {
        height: 'calc(100% - 110px)',
        display: "flex",
        alignContent: 'flex-start',
        '& .infoContainer': {
            height: 'auto',
            padding: '15px 7px 15px 15px'
        },
        '& .padreset': {
            padding: '0px',
            marginRight: "10px"
        },
        '& .ddlQueryContainer': {
            width: '100%'
        },
        '& .queryContainer': {
            height: "auto",
            paddingRight: 8,
            overflow: 'auto',
            paddingTop: 12,
            '& > div': {
                backgroundColor: '#eef1f2',
                padding: 10,
                marginTop: 8,
                borderRadius: 5
            },
            '& > table': {
                marginTop: 5,
                '& .MuiTableCell-stickyHeader': {
                    backgroundColor: theme.palette.common.white
                }
            }
        }
    },

    catalogInfoAcoordion: {
        marginBottom: 16,
        '&:last-child': {
            marginBottom: 0
        },
        '&::before': {
            top: 0,
            height: 0
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '8px 0 !important'
        },
        '& .MuiIconButton-root': {
            padding: '8px'
        },
        '& .MuiAccordionSummary-content': {
            margin: '6px 0',
            padding: '0 10px'
        },
        '& .MuiAccordionSummary-root': {
            minHeight: '42px',
            background: '#f8f8f8',
            paddingRight: '10px'
        },
        '& .MuiAccordionDetails-root': {
            padding: '0px 0px 16px',
            marginTop: 8,
            maxHeight: 300,
            overflow: 'auto'
        }
    },
    catalogInfoAcoordionHeading: {
        fontWeight: 'bold',
        minWidth: '50%'
    },
    infoTableHeader: {
        fontWeight: 600
    },
    infoTableValue: {
        color: '#1D2C2F',
        padding: '10px 0 10px',
        wordBreak: 'break-word'
    },
    userScroll: {
        maxHeight: '100px',
        overflowY: 'auto'
    },
    roleText: {
        paddingLeft: 8
    },
    accessedCount: {
        color: '#646666',
        marginTop: 6,
        '& .value': {
            color: '#646666',
            fontWeight: 500,
            marginLeft: 4
        }
    },
    schedulePopover: {
        height: 'auto',
        minWidth: 450,
        maxWidth: 450,
        display: 'flex',
        flexDirection: 'column',
        padding: '15px 25px 15px 25px'
    },
    displayFlexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    AlertsHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    scoreContainer: {
        display: 'flex',
        alignItems: 'center'
    },
    scoreContent: {
        marginLeft: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    attributeName: {
        fontWeight: 500,
        fontSize: 15,
        marginBottom: 5
    },
    selectContainer: {
        paddingLeft: 10,
        position: 'relative',
        '&>div': {
            maxWidth: 250
        }
    },
    chartcontainer: {
        padding: '8px 16px !important'
    },
    curateScore: {
        display: 'inline-flex',
        alignItems: 'center'
    },
    curateIcon: {
        width: 40,
        height: 40,
        margin: '0px -5px 0px -5px'
    },
    curateTxt: {
        fontWeight: 600
    },
    dqscoreText: {
        marginLeft: 10,
        marginTop: 10,
        marginBottom: -10
    },
    attributeAnalysisText: {
        fontWeight: 'bold'
    },
    invalidAttributeAnalysisText: {
        fontWeight: 'bold',
        color: '#F38080',
        cursor: 'pointer'
    },
    iconHeight: {
        height: 20
    },
    invalidRecords: {
        cursor: 'pointer',
        '& h5': {
            color: theme.palette.tertiary
        }
    },
    showallQueries: {
        color: '#F38080',
        width: '100%',
        textAlign: 'center',
        fontSize: '1rem',
        padding: 10,
        cursor: 'pointer'
    },
    noQueries: {
        width: '100%',
        textAlign: 'center',
        fontSize: '1rem',
        padding: 10
    },
    cardbox: {
        height: 'auto',
        padding: '15px',
        position: 'relative',
        flexGrow: 1,
        boxShadow: '0px 0px 6px #e1e5e6',
        alignItems: 'center',
        backgroundColor: '#ffffff'
        // marginTop: '10px'
    },
    navigator: {
        position: 'absolute',
        right: 15,
        zIndex: 10,
        top: 30
    },
    buttonDisable: {
        cursor: 'initial',
        '&:hover': {
            background: 'none !important'
        },
        '& svg': {
            fill: '#CCCCCC'
        }
    },
    collapseHead: {
        position: 'relative',
        padding: 15,
        cursor: 'pointer',
        '& button': {
            position: 'absolute',
            top: '8px',
            right: '8px',
            padding: 6
        }
    },
    accordionBox: {
        padding: 0
    },
    driftCustomRulebox: {
        '& .MuiChip-label': {
            paddingLeft: '5px !important'
        },
        '& input': {
            width: '100% !important'
        }
    },
    attributeTabHeader: {
        '& .checkboxlabel': {
            textOverflow: 'ellipsis',
            maxWidth: '88%',
            overflow: 'hidden'
        }
    },
    tableInputEllip: {
        '& .MuiInputBase-input': {
            textOverflow: 'ellipsis !important'
        }
    },
    tableContainer: {
        background: "#FFFFFF",
        padding: "30px 20px",
        marginTop: 20
    },
    fontWeight500: {
        fontWeight: 500
    },
    fontWeight600: {
        fontWeight: 600
    },
    tableCellPadding: {
        padding: 15
    },
    itemGrid: {
        boxShadow: "0px 0px 6px #e1e5e6",
        backgroundColor: theme.palette.common.white,
        margin: 20,
        position: "relative"
    },
    gridTag: {
        color: theme.palette.grey.main,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        '& span': {
            color: theme.palette.grey.darkTxt
        }
    },
    icoBtn: {
        backgroundColor: theme.palette.common.white,
        position: "absolute",
        padding: 5,
        right: 5,
        top: 5
    },
    imgContainer: {
        padding: 10,
        backgroundColor: theme.palette.grey.deepLight
    },
    noprevContainer: {
        height: 180,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center"
    },
    icoContainer: {
        padding: 20,
        paddingTop: 23,
        backgroundColor: theme.palette.grey.deepLight,
        textAlign: "center"
    },
    itemHeight: {
        height: "calc(100vh - 245px)",
        overflowY: "auto"
    },
    sourceName: {
        textAlign: "center",
        fontWeight: "bold",
        marginBottom: 10,
        wordWrap: "break-word",
        display: "-webkit-box",
        WebkitLineClamp: 4,
        WebkitBoxOrient: "vertical",
        overflow: "hidden"
    },
    listImage: {
        border: `1px solid ${theme.palette.grey.light}`,
        padding: 1,
        marginRight: 5
    },
    cursorPointer: {
        cursor: "pointer"
    },
    flexWidth: {
        maxWidth: "max-content"
    },
    patternCountDelete: {
        display: 'flex',
        maxWidth: '40%'
    },
    driftChips: {
        '& .MuiInput-root': {
            paddingTop: 6
        }
    },
    maxEcclipse: {
        maxWidth: "calc(100vw - 500px)",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "block"

    }

});

export default DatasetStyles;