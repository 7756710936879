import React, { useCallback, useEffect, useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';
import { getColor, getValue } from '../../helpers/appHelpers';
import { appConstants } from '../../constants/appConstants';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import moment from 'moment-timezone';

const TimelineChart = (props) => {
    const { classes, theme, profileData, datasets } = props;
    const [chartData, setChartData] = useState([]);
    const [fullscreen, setFullscreen] = useState(false);
    const [timelineFilter, setTimelineFilter] = useState('All');

    const loadChartData = useCallback((timelineData) => {
        setChartData([...timelineData]);
    }, []);

    useEffect(() => {
        const chartData = profileData;
        loadChartData([...chartData]);
    }, [loadChartData, profileData]);

    const enableFullScreen = (value) => {
        setFullscreen(value);
    };

    const responsivefy = (svg) => {
        // get container + svg aspect ratio
        const container = d3.select(svg.node().parentNode),
            width = parseInt(svg.style('width')),
            height = parseInt(svg.style('height')),
            aspect = width / height;

        // get width of container and resize svg to fit it
        const resize = () => {
            const targetWidth = parseInt(container.style('width'));
            svg.attr('width', targetWidth);
            svg.attr('height', Math.round(targetWidth / aspect));
        };

        /*
         * add viewBox and preserveAspectRatio properties,
         * and call resize so that svg resizes on inital page load
         */
        svg
            .attr('viewBox', '0 0 ' + width + ' ' + height)
            .attr('perserveAspectRatio', 'xMinYMid')
            .call(resize);

        d3.select(window).on('resize.' + container.attr('id'), resize);
    };

    const onFilter = (value) => {
        setTimelineFilter(value);
        if (value === "All") {
            setChartData([...profileData]);
        } else {
            const filterData = profileData.filter((data) => data.dataset_id === value);
            setChartData([...filterData]);
        }
    };

    useEffect(() => {
        if (chartData && chartData.length !== 0) {
            const data = chartData.map((data) => {
                return {
                    ...data,
                    date: new Date(data.end_time),
                    volume: data.dqscore
                };
            });
            const lineData = [];
            if (timelineFilter === "All") {
                for (const dataset of datasets) {
                    if (dataset.value !== "All") {
                        lineData.push({
                            name: dataset.value,
                            values: data.filter((d) => d.dataset_id === dataset.value)
                        });
                    }
                }
            } else {
                lineData.push({
                    name: timelineFilter,
                    values: data.filter((d) => d.dataset_id === timelineFilter)
                });
            }
            const margin = { top: 50, right: 50, bottom: 50, left: 50 };
            const width = window.innerWidth - margin.left - margin.right - (fullscreen ? 50 : 190);
            const height = fullscreen ? window.innerHeight - 200 : 250;

            // find data range
            const xMin = d3.min(data, (d) => {
                return d.date;
            });

            const xMax = d3.max(data, (d) => {
                return d.date;
            });

            // scale using range
            const x = d3
                .scaleTime()
                .domain([xMin, xMax])
                .range([0, width])
                .nice();

            const y = d3
                .scaleLinear()
                .domain([0, 110])
                .range([height, 0]);

            d3.select('.timeline-chart > *').remove();

            // add chart SVG to the page
            const svg = d3
                .select('.timeline-chart')
                .append('svg')
                .attr('width', '100%')
                .attr('height', height + margin.top + margin.bottom)
                .call(responsivefy)
                .append('g')
                .attr('width', width + margin.left + margin.right)
                .attr('transform', `translate(${margin.left}, ${margin.top})`);

            // create the axes component
            svg.append("g")
                .attr("class", "x-axis")
                .attr("clip-path", "url(#clip)")
                .attr("transform", `translate(0,${height})`)
                .call(d3.axisBottom(x));

            // create x-axis label
            svg.append("text")
                .attr("class", "x label")
                .attr("text-anchor", "center")
                .attr("x", width / 2.20)
                .attr("y", height + 40)
                .text("Time");

            svg.append("g")
                .attr("class", "y-axis")
                .attr("transform", `translate(${width},0)`)
                .call(d3.axisRight(y).tickSize(-width).ticks(4));

            // create y-axis label
            svg.append("text")
                .attr("class", "y label")
                .attr("text-anchor", "end")
                .attr("x", -100)
                .attr("y", width + 35)
                .attr("dy", ".75em")
                .attr("transform", "rotate(-90)")
                .text("DQScore");

            svg.append('line')
                .attr('y1', height)
                .attr('y2', 0)
                .attr('x1', width)
                .attr('x2', width)
                .attr('stroke', theme.palette.border.normal);

            svg.select('.y-axis path.domain').remove();
            svg.selectAll(".y-axis .tick:first-of-type text").remove();
            svg.selectAll('.tick line')
                .attr('stroke', theme.palette.border.normal);

            svg.append("defs").append("clipPath")
                .attr("id", "clip")
                .append("rect")
                .attr("width", width)
                .attr("height", height)
                .attr("x", 0)
                .attr("y", 0);

            const timeline = svg.append("g")
                .attr("id", "timeline")
                .attr("clip-path", "url(#clip)");

            /* Volume series bars */
            const volData = data.filter((d) => d.volume !== null && d.volume !== 0);

            /*
             * const yMinVolume = d3.min(volData, (d) => {
             *     return Math.min(d.volume);
             * });
             */

            /*
             * const yMaxVolume = d3.max(volData, (d) => {
             *     return Math.max(d.volume);
             * });
             */

            /*
             * const yVolumeScale = d3
             *     .scaleLinear()
             *     .domain([yMinVolume, yMaxVolume])
             *     .range([height, 0])
             *     .nice();
             */

            const rectarea = timeline.append('g');

            rectarea
                .selectAll()
                .data(volData)
                .enter()
                .append('rect')
                .attr('x', (d) => {
                    return x(d.date);
                })
                .attr('y', (d) => {
                    return y(d.volume);
                })
                .attr('class', 'vol')
                .attr('fill', (d, i) => {
                    return getColor(d.volume);
                })
                .attr('width', width / x.ticks().length)
                .attr('height', (d) => {
                    return height - y(d.volume);
                });


            // renders close price line chart and moving average line chart
            const line = d3
                .line()
                .x((d) => {
                    return x(d.date);
                })
                .y((d) => {
                    return y(d.dqscore);
                });

            const colors = ['#FFD778', '#FF829D'];

            if (timelineFilter === "All") {
                timeline.selectAll(".line")
                    .data(lineData)
                    .enter()
                    .append("path")
                    .attr("class", "path")
                    .attr("fill", "none")
                    .attr("stroke", (_, i) => { return colors[i]; })
                    .attr("stroke-width", 1.5)
                    .attr("d", (d) => {
                        return d3.line()
                            .x((d) => { return x(d.date); })
                            .y((d) => { return y(d.dqscore); })(d.values);
                    });

            } else {
                timeline
                    .append('path')
                    .data([data])
                    .style('fill', 'none')
                    .attr('class', 'path')
                    .attr("clip-path", "url(#clip)")
                    .attr('stroke', colors[0])
                    .attr('stroke-width', '1.5')
                    .attr('d', line);
            }

            //Highlight Data
            const scoreHighlight = svg.append('g').attr('class', 'score-highlight').attr('display', 'none')
                .attr('transform', `translate(${width - 10},0)`);
            scoreHighlight.append('rect').style('width', 60).style('height', 15).style('fill', '#333');
            scoreHighlight.append('text').attr('class', 'score-text').style('fill', '#fff').attr('transform', 'translate(15,12)').style('font-size', 11).attr('class', 'dqscore-highlight').text('100');

            const dateHighlight = svg.append('g').attr('class', 'date-highlight').attr('display', 'none')
                .attr('transform', `translate(${width - 10},0)`);
            dateHighlight.append('rect').style('width', 130).style('height', 15).style('fill', '#333');
            dateHighlight.append('text').attr('class', 'score-text').style('fill', '#fff').attr('transform', 'translate(12,12)').style('font-size', 11).attr('class', 'dqscore-highlight').text('100');

            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
            if (data.length === 1) {
                svg.selectAll('.x-axis text').text((d) => moment(d).format('MM/DD/YYYY HH:mm:ss'));
            }

            // renders x and y crosshair
            const focus = svg
                .append('g')
                .attr('class', 'focus')
                .style('display', 'none');

            focus.append('line').classed('x', true);
            focus.append('line').classed('y', true);

            svg
                .append('rect')
                .attr('class', 'overlay')
                .attr('width', width)
                .attr('height', height)
                .on('mouseover', () => focus.style('display', null))
                .on('mouseout', () => {
                    focus.style('display', 'none');
                    scoreHighlight.style('display', 'none');
                    dateHighlight.style('display', 'none');
                })
                .on('mousemove', generateCrosshair);

            d3.select('.overlay').style('fill', 'none');
            d3.select('.overlay').style('pointer-events', 'all');

            d3.selectAll('.focus line').style('fill', 'none');
            d3.selectAll('.focus line').style('stroke', '#67809f');
            d3.selectAll('.focus line').style('stroke-width', '1.5px');
            d3.selectAll('.focus line').style('stroke-dasharray', '3 3');

            //returs insertion point
            const bisectDate = d3.bisector((d) => d.date).left;

            /* mouseover function to generate crosshair */
            function generateCrosshair() {
                //returns corresponding value from the domain
                const correspondingDate = x.invert(d3.mouse(this)[0]);
                //gets insertion point
                const i = bisectDate(data, correspondingDate, 1);
                const d0 = data[i - 1];
                const d1 = data[i];
                if (d0 && d1 && d0.date && d1.date) {
                    const currentPoint =
                        correspondingDate - d0.date > d1.date - correspondingDate ? d1 : d0;
                    focus.attr(
                        'transform',
                        `translate(${x(currentPoint.date)}, ${y(
                            currentPoint.dqscore
                        )})`
                    );
                    const datetimeFormat = 'MM/DD/YYYY HH:mm:ss';
                    const focusWidth = width - 200;
                    focus
                        .select('line.x')
                        .attr('x1', -focusWidth)
                        .attr('x2', width - x(currentPoint.date));
                    focus
                        .select('line.y')
                        .attr('y1', height - y(currentPoint.dqscore))
                        .attr('y2', -(height - 150));

                    scoreHighlight.style('display', 'block').attr('transform', `translate(${width}, ${y(
                        currentPoint.dqscore
                    ) - 10})`);
                    scoreHighlight.select('text').text(getValue(currentPoint.dqscore));
                    dateHighlight.style('display', 'block').attr('transform', `translate(${x(currentPoint.date) - 35},${height + 20})`);
                    dateHighlight.select('text').text(moment(currentPoint.date).format(datetimeFormat));
                }
            }

            svg.call(zoom);

            function zoom() {
                const extent = [
                    [margin.left, margin.top],
                    [width - margin.right, height - margin.top]
                ];

                const zooming = d3.zoom()
                    .scaleExtent([1, 3])
                    .translateExtent(extent)
                    .extent(extent)
                    .on("zoom", zoomed);

                svg.call(zooming);

                function zoomed() {
                    x.range([margin.left, width - margin.right]
                        .map((d) => d3.event.transform.applyX(d)));

                    svg.select(".path")
                        .attr("d", line);

                    svg.select(".x-axis")
                        .call(d3.axisBottom(x));

                    svg.selectAll(".vol").attr("x", (d) => x(d.date)).attr("width", width / x.ticks().length);
                }
            }


        }

    }, [theme.palette.chartColors.score100, fullscreen, chartData, theme.palette.secondary.main, theme.palette.border.normal, datasets, timelineFilter]);

    return (
        <Grid className={fullscreen ? classes.fullScreen : ''}>
            <ChartContainer
                title={appConstants.charts.timelineChart.name}
                id={appConstants.charts.timelineChart.id}
                fullScreenProperties={
                    {
                        enable: fullscreen,
                        onEdit: enableFullScreen
                    }
                }
                chartFilter={
                    {
                        value: timelineFilter,
                        onFilter: onFilter,
                        properties: datasets
                    }
                }
                chartData={chartData}
                textBoxWidth={classes.minWidth}>
                <Grid container className={classNames(classes.timelineChart, classes.chartStyles, "timeline-chart")} />
            </ChartContainer>
        </Grid>
    );
};

TimelineChart.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    profileData: PropTypes.array,
    datasets: PropTypes.array
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(TimelineChart);