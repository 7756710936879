import { alertActions } from '../constants/actionTypes/alertActionTypes';

const initialState = {
	message: '',
	type: ''
};

export const alertReducer = (state = initialState, action) => {
	if (!action || (action && !action.type)) {
		return state;
	}

	switch (action.type) {
		case alertActions.ALERT_ERROR:
			return {
				...state,
				message: action.error,
				type: alertActions.ALERT_ERROR
			};
		case alertActions.ALERT_INFO:
			return {
				...state,
				message: action.responseData,
				type: alertActions.ALERT_INFO
			};
		case alertActions.ALERT_SUCCESS:
			return {
				...state,
				message: action.responseData,
				type: alertActions.ALERT_SUCCESS
			};
		case alertActions.CLEAR_ALERT:
			return {
				...state,
				message: initialState.message,
				type: initialState.type
			};
		case alertActions.SSO_ALERT:
			return {
				...state,
				message: action.responseData,
				type: alertActions.SSO_ALERT
			};
		default:
			return state;
	}
};