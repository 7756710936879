import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// Import Component
import { appConstants } from '../../../../constants/appConstants.js';
import DynamicElementByDataType from '../../../DynamicElementByDataType/DynamicElementByDataType.jsx';
import { getFieldPropertyByLevel, createFieldProperty } from '../../../../actions/datasourceActions';

const AttributeProperty = (props) => {
    const { datasetId, attribute, isEditable, fields } = props;
    const [fieldProperties, setFieldProperties] = useState([]);
    const user = useSelector(({ account }) => account.user);
    const dispatch = useDispatch();

     /**
      * Get Field Property List
      */
      const getFieldPropertyByAttribute = useCallback(() => {
        dispatch(getFieldPropertyByLevel(attribute.attribute, appConstants.fieldKeys.attribute, datasetId)).then((response) => {
            if (response && response.length > 0) {
                setFieldProperties(response);
            } else {
                setFieldProperties([]);
            }
        });
    }, [attribute.attribute, datasetId, dispatch]);

    /**
     * @name useEffect
     * @description UseEffect run when page initialize and any updates happends
     */
    useEffect(() => {
        getFieldPropertyByAttribute();
    }, [datasetId, fields, attribute, getFieldPropertyByAttribute]);


    /**
     * Get Dynamic Element On Change Event
     * @param {*} name
     * @param {*} value
     * @param {*} data_type
     */
    const dyanamicEleOnChange = (value, field, saveDB, isBoolean) => {

        let properties = [...fieldProperties];

        const fieldIndex = properties.findIndex((i) => parseInt(i.custom_field) === parseInt(field.id));
        if (fieldIndex > -1) {
            // Update
            properties[fieldIndex].value = value;
            setFieldProperties(properties);

            if (saveDB) {
                if (properties[fieldIndex].id && properties[fieldIndex].id !== '') {
                    // Update field in db
                    dispatch(createFieldProperty(properties[fieldIndex]));
                } else {
                    // Create field in db
                    dispatch(createFieldProperty(properties[fieldIndex])).then((response) => {
                        if (!response) {
                            return;
                        }
                        properties[fieldIndex].id = response.id;
                        setFieldProperties(properties);
                    });
                }
            }
        } else {
            // Create new field in state
            const obj = {
                id: '',
                organization: user.organization,
                custom_field: field.id,
                dataset: parseInt(datasetId),
                attribute: parseInt(attribute.attribute),
                value: value
            };

            properties = [...properties, obj];
            setFieldProperties(properties);

            if (isBoolean) {
                // Create field in db
                dispatch(createFieldProperty(obj)).then((response) => {
                    if (!response) {
                        return;
                    }
                    obj.id = response.id;
                    properties = [...properties, obj];
                    setFieldProperties(properties);
                });
            }
        }
    };

    return (
        <DynamicElementByDataType
            fields={fields}
            properties={fieldProperties}
            removeBorder
            isEditable={isEditable}
            level={appConstants.fieldKeys.attribute}
            dataset={parseInt(datasetId)}
            attribute={parseInt(attribute.attribute)}
            dyanamicEleOnChange={dyanamicEleOnChange}
         />
    );
};

AttributeProperty.propTypes = {
    attribute: PropTypes.object,
    isEditable: PropTypes.bool,
    fields: PropTypes.array,
    datasetId: PropTypes.number
};

export default AttributeProperty;