import React, { useCallback, useEffect, useState } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import TextBox from '../TextBox/TextBox.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const PatternRule = (props) => {
    const { rule, id, parentId, isConditionalRule, builderConfig, theme } = props;
    const { onPropertyChange, onRuleRemove, classes } = builderConfig;
    const [inputRule, setInputRule] = useState(null);

    const onValueChanged = useCallback((property, value) => {
        inputRule[property] = value;
        setInputRule({ ...inputRule });
    }, [inputRule]);

    const onInputValueChange = (property, value) => {
        onPropertyChange(property, value, id);
    };

    const removeRule = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onRuleRemove(id, parentId, isConditionalRule);
    };

    const loadInputRule = useCallback((rule) => {
        setInputRule({ ...rule });
    }, []);

    useEffect(() => {
        if (!inputRule && rule) {
            loadInputRule(rule);
        }
    }, [id, inputRule, loadInputRule, rule]);


    return (
        <Grid container direction="row" wrap="nowrap" justify="space-between" alignItems="center" className={classNames(classes.rule, classes.scroll)}>
            <Grid item className={classes.ruleContainer}>
                <Grid container direction="row" alignItems="center" wrap="nowrap">
                    <Grid className={classes.marginRight10}>
                        <TextBox
                            name="pattern"
                            value={inputRule?.pattern ?? ''}
                            onChange={(event) => onValueChanged('pattern', event.target.value)}
                            onBlur={(event) => onInputValueChange('pattern', event.target.value)}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item>
                <ToolTipComponent title="Delete" arrow>
                    <IconButton className={classes.actionIconButton} onClick={(event) => removeRule(event)}>
                        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 32 32">
                            <g id="Delete">
                                <path fill={theme.palette.primary.main}
                                    stroke={theme.palette.primary.main}
                                    strokeWidth="0.92"
                                    strokeMiterlimit="10"
                                    d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                <path fill={theme.palette.primary.main}
                                    stroke={theme.palette.primary.main}
                                    strokeWidth="0.92"
                                    strokeMiterlimit="10"
                                    d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                            </g>
                        </svg>
                    </IconButton>
                </ToolTipComponent>
            </Grid>
        </Grid>
    );
};

PatternRule.propTypes = {
    rule: PropTypes.object,
    id: PropTypes.string,
    parentId: PropTypes.string,
    builderConfig: PropTypes.object,
    theme: PropTypes.object,
    isConditionalRule: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
};

export default PatternRule;