import React, { useState, useCallback, useEffect } from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, withStyles, Switch, Typography, IconButton, MenuItem, Box } from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { driftDatasetStepChart, updateDriftDatasetProperties } from '../../actions/scheduleActions.js';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import UserList from '../Permission/UserList.jsx';

import Styles from '../../layouts/Styles.jsx';
import StepChart from '../Charts/StepCharts.jsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DriftDashBoardStyle from './DriftDashBoardStyle.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import Loader from '../Loaders/Loader.jsx';
import AlertRuleAccordianHeader from './AlertRuleAccordianHeader.jsx';
import TooltipComponent from '../Tooltip/Tooltip.jsx';

const DriftDatasetRulesDetails = (props) => {
    const { datasetId, classes, theme } = props;
    const [alertRules, setAlertRules] = useState([]);
    const [searchKey, setSearchKey] = useState('');
    const [filterBy, setFilterBy] = useState('All Drift Types');
    const [alertfilterBy, setAlertFilterBy] = useState('All');
    const [pageloader, updatepageloader] = useState(false);
    const [enableFilter, setFilter] = useState(false);
    let userList = useSelector(({ account }) => account.users);
    userList = userList.map((user) => {
        return {
            name: (user.first_name !== "" && user.first_name) ? `${user.first_name} ${user.last_name}` : user.username,
            isSelected: false,
            ...user
        };
    });
    const dispatch = useDispatch();

    const updateProperties = useCallback((ruleProperties) => {
        dispatch(updateDriftDatasetProperties(ruleProperties));
    }, [dispatch]);

    const changeChartType = useCallback((chartType, index, data) => {
        const ruleIndex = alertRules.findIndex((rule) => rule.id === data.id);
        if (ruleIndex < 0) {
            return;
        }
        const rule = alertRules[ruleIndex];
        rule.selectedChartType = chartType;
        alertRules.splice(ruleIndex, 1, { ...rule });
        setAlertRules([...alertRules]);
    }, [alertRules]);

    const toggleAlertConfig = useCallback((event, property, value, index) => {
        event.stopPropagation();
        const rule = alertRules[index];
        rule[property] = value;
        rule['dataset_id'] = datasetId;
        alertRules.splice(index, 1, { ...rule });
        setAlertRules([...alertRules]);
        updateProperties(rule);
    }, [datasetId, alertRules, updateProperties]);

    const updateRange = useCallback((property, value, index, data) => {
        const ruleIndex = alertRules.findIndex((rule) => rule.id === data.id);
        if (ruleIndex < 0) {
            return;
        }
        const rule = alertRules[ruleIndex];
        rule[property] = parseInt(value);
        rule['dataset_id'] = datasetId;
        rule['is_auto_rule'] = false;
        alertRules.splice(ruleIndex, 1, { ...rule });
        setAlertRules([...alertRules]);
        updateProperties(rule);
    }, [datasetId, alertRules, updateProperties]);

    const getDashboardStatistics = useCallback(() => {
        updatepageloader(true);
        dispatch(driftDatasetStepChart(datasetId)).then((response) => {
            if (response) {
                const alertRules = [...response.step_chart_data];
                for (const alertRule of alertRules) {
                    alertRule.selectedChartType = 'Value';
                }
                setAlertRules([...alertRules]);
            }
            updatepageloader(false);
        });
    }, [datasetId, dispatch]);

    useEffect(() => {
        getDashboardStatistics();
    }, [getDashboardStatistics]);

    const DropDownIcon = (iconProps) => {
        return (
            <svg {...iconProps} className={classes.DropDownIcon} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" top="" width="12px" height="18px" viewBox="0 0 451.847 451.847">
                <g>
                    <path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751   c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0   c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z" fill={theme.palette.grey.dark} />
                </g>
            </svg>
        );
    };

    const onRemoveUser = useCallback((name, id, index) => {
        const rule = alertRules[index];
        const userIndex = rule.accessuser.findIndex((users) => users.email === name);
        if (userIndex !== -1) {
            rule.accessuser.splice(userIndex, 1);
        }
        rule['dataset_id'] = datasetId;
        alertRules.splice(index, 1, { ...rule });
        setAlertRules([...alertRules]);
        updateProperties(rule);
    }, [alertRules, datasetId, updateProperties]);

    const filteruserList = (id) => {
        const ruleIndex = alertRules.findIndex((rule) => rule.id === id);
        if (ruleIndex < 0) {
            return;
        }
        let accessuserlist = alertRules[ruleIndex].accessuser;
        accessuserlist = accessuserlist.map((user) => user.id);
        return userList.filter((user) => !accessuserlist.includes(user.id));
    };

    const onChange = useCallback((value, id, index) => {
        const rule = alertRules[index];
        if (!rule.accessuser) {
            rule.accessuser = [];
        }
        const user = userList.find((users) => users.email === value);
        rule.accessuser.push({ ...user });
        rule.username = "";
        rule['dataset_id'] = datasetId;
        alertRules.splice(index, 1, { ...rule });
        setAlertRules([...alertRules]);
        updateProperties(rule);
    }, [alertRules, userList, datasetId, updateProperties]);

    const onInputValueOnChange = (value, id) => {
        const ruleIndex = alertRules.findIndex((rule) => rule.id === id);
        if (ruleIndex < 0) {
            return;
        }
        alertRules[ruleIndex].username = value;
        setAlertRules([...alertRules]);
    };

    const onBlurList = (id) => {
        const ruleIndex = alertRules.findIndex((rule) => rule.id === id);
        if (ruleIndex < 0) {
            return;
        }
        alertRules[ruleIndex].expended = false;
        setAlertRules([...alertRules]);
    };

    const expandList = (id, event) => {
        event.stopPropagation();
        const ruleIndex = alertRules.findIndex((rule) => rule.id === id);
        if (ruleIndex < 0) {
            return;
        }
        alertRules[ruleIndex].expended = true;
        setAlertRules([...alertRules]);
    };

    const getFilterOptions = useCallback(() => {
        return alertRules ? ['All Drift Types', ...new Set(alertRules.map((p) => p.Schema))] : [];
    }, [alertRules]);

    const resetFilter = useCallback(() => {
        setAlertFilterBy("All");
        setFilterBy("All Drift Types");
    }, []);

    const getAlertFilterOptions = useCallback(() => {
        return ["All", "Alerts only"];
    }, []);

    const getAlertRules = useCallback((array, filterBy, alertfilterBy, searchKey) => {
        if (filterBy && filterBy !== 'All Drift Types' && filterBy !== 'Alerts only' && filterBy !== 'No Alerts') {
            array = array.filter((p) => p.Schema.toLowerCase() === filterBy.toLowerCase());
        }
        if (searchKey) {
            array = array.filter((p) => p.RuleName.toLowerCase().includes(searchKey.toLowerCase()));
        }

        if (alertfilterBy && alertfilterBy !== 'All') {
            if (alertfilterBy === 'Alerts only') { array = array.filter((p) => p.is_alert === true); }
        }
        return array ? [...array] : [];
    }, []);


    const getChartInputKey = (selectedChartType) => {
        let key = 'points';
        switch (selectedChartType) {
            case 'Percentage Change':
                key = 'percentage';
                break;
            case 'Z Score':
                key = 'zscore';
                break;
            default:
                key = 'points';
                break;
        }
        return key;
    };

    const getDrfitLimit = useCallback((rule) => {
        let lowValue = 0, highValue = 0;
        const valueKey = getChartInputKey(rule.selectedChartType);
        const chartInput = rule.step_chart_data ? [...rule.step_chart_data] : [];
        if (!chartInput) {
            return { lowValue, highValue };
        }
        const sortderData = chartInput.sort((a, b) => {
            return a[valueKey] - b[valueKey];
        });
        lowValue = sortderData[0][valueKey];
        highValue = sortderData[sortderData.length - 1][valueKey];
        return { lowValue, highValue };
    }, []);

    return (
        <Grid container direction="column">
            {pageloader && <Loader />}
            {
                !pageloader &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container direction="row" justify="space-between" alignItems="center" className={classNames(classes.titleContainer, classes.alertContainer)}>
                        <Grid item>
                            <Typography className={classes.titleTxt} variant="h4" component="h4">
                                {'Alerts'}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" direction="row" justify="flex-end" wrap="nowrap">
                                <Grid item style={{ marginTop: '2px', marginRight: '15px' }} className={`${classNames(classes.includeSearchContainer, classes.dqSearchBox, classes.inlineFlex)}`}>
                                    <TextBox
                                        value={searchKey}
                                        placeholder="Search"
                                        onChange={(event) => setSearchKey(event.target.value)}
                                        className={classNames(classes.inputOutline, classes.searchTextbox)}
                                    />
                                    {
                                        searchKey.length === 0 ?
                                            <IconButton className={classes.includeSearchIcon} >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.094" height="13.092" viewBox="0 0 13.094 13.092">
                                                    <g transform="translate(0 -0.035)">
                                                        <path fill="#afb2b3" d="M5.27,10.57A5.257,5.257,0,0,0,8.5,9.46l3.483,3.483a.655.655,0,0,0,.926-.926L9.427,8.534A5.267,5.267,0,1,0,5.27,10.57ZM2.471,2.5a3.958,3.958,0,1,1,0,5.6h0a3.944,3.944,0,0,1-.02-5.577l.02-.02Z" transform="translate(0 0)" />
                                                    </g>
                                                </svg>
                                            </IconButton>
                                            :
                                            <IconButton className={classes.includeDeleteIcon} onClick={() => setSearchKey('')}>
                                                <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24">
                                                    <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                    <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                                </svg>
                                            </IconButton>
                                    }
                                </Grid>
                                {
                                    enableFilter &&
                                    <Grid className={classNames(classes.FilterHideShowContainer)}>
                                        <Grid container alignItems="center" justify="space-between" className={classNames(classes.datasourceFilterSection, "datasourceFilterSection")}>
                                            <TooltipComponent title="Close" placement="bottom" arrow>
                                                <IconButton onClick={() => setFilter(!enableFilter)} className={classNames(classes.marginRight12, classes.closeIcon)}>
                                                    <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                        <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                        <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                                    </svg>
                                                </IconButton>
                                            </TooltipComponent>
                                            <Grid item>
                                                <TextBox
                                                    className={classes.inlinetxt}
                                                    onChange={(event) => setTimeout(() => setFilterBy(event.target.value), 100)}
                                                    value={filterBy}
                                                    select
                                                    SelectProps={
                                                        {
                                                            MenuProps: {
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "center"
                                                                },
                                                                transformOrigin: {
                                                                    vertical: "top",
                                                                    horizontal: "center"
                                                                },
                                                                getContentAnchorEl: null
                                                            },
                                                            IconComponent: (iconProps) => DropDownIcon(iconProps)
                                                        }
                                                    }
                                                >
                                                    {
                                                        getFilterOptions().map((filterOption, index) => (
                                                            <MenuItem key={`filterBy_${index}`} value={filterOption} className={classes.menuItem}>
                                                                {filterOption}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </TextBox>
                                            </Grid>
                                            <Grid item style={{ marginLeft: '15px' }} >
                                                <TextBox
                                                    className={classes.inlinetxt}
                                                    onChange={(event) => setTimeout(() => setAlertFilterBy(event.target.value), 100)}
                                                    value={alertfilterBy}
                                                    select
                                                    SelectProps={
                                                        {
                                                            MenuProps: {
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "center"
                                                                },
                                                                transformOrigin: {
                                                                    vertical: "top",
                                                                    horizontal: "center"
                                                                },
                                                                getContentAnchorEl: null
                                                            },
                                                            IconComponent: (iconProps) => DropDownIcon(iconProps)
                                                        }
                                                    }
                                                >
                                                    {
                                                        getAlertFilterOptions().map((filterOption, index) => (
                                                            <MenuItem key={`alertfilterBy_${index}`} value={filterOption} className={classes.menuItem}>
                                                                {filterOption}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </TextBox>
                                            </Grid>
                                            <Grid item>
                                                <TooltipComponent title="Clear Filter" placement="bottom" arrow>
                                                    <IconButton onClick={() => resetFilter()} className={classes.filterIcon}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22.795" viewBox="0 0 20 22.795">
                                                            <g id="filter_10_" data-name="filter (10)" transform="translate(-31.388)">
                                                                <path id="Path_1437" data-name="Path 1437" d="M46.071,0a5.326,5.326,0,0,0-5.1,3.8h-8.59a1,1,0,0,0-.814,1.564l5.776,7.882a1.046,1.046,0,0,1,.185.6V21.75a1.044,1.044,0,0,0,1.668.835l2.564-1.937a1.527,1.527,0,0,0,.612-1.22V13.84a1.047,1.047,0,0,1,.185-.6l2.07-2.825A5.31,5.31,0,1,0,46.071,0ZM41.477,12.462a2.375,2.375,0,0,0-.438,1.377c0,6.076.033,5.658-.075,5.737l-2.1,1.586V13.84a2.376,2.376,0,0,0-.428-1.364l-5.38-7.341h7.7A5.307,5.307,0,0,0,43.369,9.88Zm4.594-3.18a3.973,3.973,0,1,1,3.982-3.973A3.982,3.982,0,0,1,46.071,9.282Z" transform="translate(0)" fill="#f38080" />
                                                                <path id="Path_1438" data-name="Path 1438" d="M321.45,76.089a.668.668,0,0,0-.944,0l-.793.791-.793-.791a.668.668,0,0,0-.944.945l.791.79-.791.79a.668.668,0,0,0,.944.945l.793-.791.793.791a.668.668,0,0,0,.944-.945l-.791-.79.791-.79A.668.668,0,0,0,321.45,76.089Z" transform="translate(-273.642 -72.515)" fill="#f38080" />
                                                            </g>
                                                        </svg>
                                                    </IconButton>
                                                </TooltipComponent>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item>
                                    <TooltipComponent title="Filters" placement="bottom" arrow>
                                        <IconButton onClick={() => setFilter(!enableFilter)} className={classNames(classes.marginRight7, classes.filterImg)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.923" viewBox="0 0 20 16.923">
                                                <g transform="translate(-3 -5)">
                                                    <path id="Path_1391" data-name="Path 1391" d="M22.231,6.538H11.462A1.54,1.54,0,0,0,9.923,5H8.385A1.54,1.54,0,0,0,6.846,6.538H3.769a.769.769,0,1,0,0,1.538H6.846A1.54,1.54,0,0,0,8.385,9.615H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,8.077V6.538H9.923V7.3s0,0,0,0,0,0,0,0v.766Z" fill={theme.palette.primary.main} />
                                                    <path id="Path_1392" data-name="Path 1392" d="M22.231,14.538H17.615A1.54,1.54,0,0,0,16.077,13H14.538A1.54,1.54,0,0,0,13,14.538H3.769a.769.769,0,0,0,0,1.538H13a1.54,1.54,0,0,0,1.538,1.538h1.538a1.54,1.54,0,0,0,1.538-1.538h4.615a.769.769,0,1,0,0-1.538Zm-7.692,1.538V14.538h1.538V15.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -1.846)" fill={theme.palette.primary.main} />
                                                    <path id="Path_1393" data-name="Path 1393" d="M22.231,22.538H11.462A1.54,1.54,0,0,0,9.923,21H8.385a1.54,1.54,0,0,0-1.538,1.538H3.769a.769.769,0,0,0,0,1.538H6.846a1.54,1.54,0,0,0,1.538,1.538H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,24.077V22.538H9.923V23.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -3.692)" fill={theme.palette.primary.main} />
                                                </g>
                                            </svg>
                                        </IconButton>
                                    </TooltipComponent>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }

            {
                !pageloader &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {
                        getAlertRules(alertRules, filterBy, alertfilterBy, searchKey).map((data, index) => {
                            const { lowValue, highValue } = getDrfitLimit(data);
                            return (
                                <Accordion key={`drfit_rule_index_${index}`} className={classes.ruleAccordian}>
                                    <AccordionSummary className={classes.ruleAccordianSummary} style={{ borderColor: data.is_alert ? '#F38080' : '#54DF9A' }}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <Grid container direction="column">
                                                    <Typography className={classes.heading} noWrap>
                                                        {data.Schema ? data.Schema : ' '}
                                                    </Typography>
                                                    <Grid item>
                                                        <Grid container direction="column" wrap="nowrap" justify="space-between" alignItems="center" className={classes.ruleName}>
                                                            <Typography component="h3" noWrap>
                                                                {data.RuleName ? data.RuleName : ' '}
                                                            </Typography>
                                                            <Box style={{ backgroundColor: data.is_alert ? '#F38080' : '#54DF9A' }}>
                                                                <Typography>
                                                                    {data.last_value ? `${data.last_value}` : '0'}
                                                                </Typography>
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography className={classes.ruleDescription} component="span" noWrap>
                                                        {data.description ? data.description : ' '}
                                                    </Typography>
                                                    <Typography>
                                                        {data.last_profile_time ? moment(data.last_profile_time).format('DD MMM YYYY HH:mm A') : ' '}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                                                <Grid container direction="row" justify="center" alignItems="center" wrap="nowrap" className={classes.limit}>
                                                    <Grid item>
                                                        <Typography component="h5" variant="h5">
                                                            {lowValue ? `${lowValue}${data.selectedChartType === 'Percentage Change' ? '%' : ''}` : `0${data.selectedChartType === 'Percentage Change' ? '%' : ''}`}
                                                        </Typography>
                                                        <Typography component="p">
                                                            {'Low'}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        <Typography component="h5" variant="h5" className={classes.attributeAnalysisText}>
                                                            {highValue ? `${highValue}${data.selectedChartType === 'Percentage Change' ? '%' : ''}` : `0${data.selectedChartType === 'Percentage Change' ? '%' : ''}`}
                                                        </Typography>
                                                        <Typography component="p">
                                                            {'High'}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                                                <StepChart
                                                    inputKey={getChartInputKey(data.selectedChartType)}
                                                    chartData={data['step_chart_data']}
                                                    chartClassName={"drift-step-chart" + index}
                                                    chartWidth={100}
                                                    chartHeight={70}
                                                    showSnapShot
                                                />
                                            </Grid>
                                            <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                                                <Grid style={{ marginLeft: '15px' }}>
                                                    <UserList
                                                        expandList={(level, event) => expandList(data.id, event)}
                                                        level="accessLevel3"
                                                        isOPen={data.expended}
                                                        users={filteruserList(data.id)}
                                                        selectedList={data.accessuser ? data.accessuser : []}
                                                        inputValue={data.username ? data.username : ''}
                                                        onChange={(event) => onChange(event, data.id, index)}
                                                        onBlur={() => onBlurList(data.id)}
                                                        onInputValueOnChange={(value) => onInputValueOnChange(value, data.id)}
                                                        onRemoveUser={(name, type) => onRemoveUser(name, data.id, index)}
                                                        type="drift"
                                                        showAdd
                                                        count={3} />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>

                                                <Grid container direction="row" justify="flex-end" alignItems="center" wrap="nowrap">
                                                    <Box className={classNames(classes.btnDriftMode, data.is_auto_rule === true ? 'active' : '')}>
                                                        <Typography>
                                                            {data.is_auto_rule === true ? 'Auto' : 'Manual'}
                                                        </Typography>
                                                    </Box>
                                                    <FormControlLabel className={classes.ruleSwitch}
                                                        control={
                                                            <Switch checked={Boolean(data.is_rule)}
                                                                color="secondary"
                                                                onClick={(event) => toggleAlertConfig(event, 'is_rule', !data.is_rule, index)}
                                                                name="is_rule"
                                                            />
                                                        }
                                                        label="Rule"
                                                    />
                                                    <IconButton className={classNames(classes.btnMute, data.is_mute === true ? 'active' : '')}
                                                        onClick={(event) => toggleAlertConfig(event, 'is_mute', !data.is_mute, index)}>
                                                        <NotificationsNoneIcon className={classes.NotificationIcon} />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.accordianChartContainer}>
                                        <Grid container direction="row" justify="flex-start" alignItems="center">
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                                                <AlertRuleAccordianHeader
                                                    attributeId={datasetId}
                                                    data={data}
                                                    selectedChartType={data.selectedChartType}
                                                    onChartTypeChange={(value) => changeChartType(value, index, data)}
                                                    onInputValueChange={(property, value) => updateRange(property, value, index, data)}
                                                />
                                            </Grid>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginTop: 20, marginBottom: 10 }}>
                                                <StepChart
                                                    inputKey={getChartInputKey(data.selectedChartType)}
                                                    chartData={data['step_chart_data']}
                                                    chartType={data.charttype}
                                                    chartClassName={"drift-step-chart-large" + index}
                                                    chartHeight={300}
                                                />
                                            </Grid>
                                            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} style={{ marginTop: 10 }}>
                                                <Typography component="p">
                                                    {/* {'Row count decreased from 10% to 5%'} */}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                    </AccordionDetails>
                                </Accordion>
                            );
                        })
                    }
                </Grid>
            }
        </Grid>


    );
};

DriftDatasetRulesDetails.propTypes = {
    datasetId: PropTypes.number,
    classes: PropTypes.object,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...DriftDashBoardStyle(theme),
    ...Styles(theme)
}), { withTheme: true })(DriftDatasetRulesDetails);