import React, { useCallback, useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { FormControl, MenuItem, withStyles, Select, Chip, TextField, FormLabel, FormHelperText } from '@material-ui/core';
import AutoCompleteCheckBoxStyles from "./AutoCompleteCheckBoxStyles.jsx";
import Styles from "../../layouts/Styles.jsx";
import CheckboxComponent from "../ChecboxComponent/CheckboxComponent.jsx";

let keyword = '';
const AutoCompleteCheckBox = (props) => {
    const { classes, availableList, onChange, selectionData, fullWidth, label, displayValue, onSearch, noMargin, isHideSelectedAllCount, selectedAll, error, helperText, disabled = false } = props;
    const selectedList = selectionData ? selectionData : availableList.filter((option) => option.isSelected);
    const isAllSelected = selectedList.length === availableList.length;
    const [displayList, setDisplayList] = useState([]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
                padding: 0
            }
        },
        getContentAnchorEl: null,
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "center"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "center"
        },
        variant: "menu"
    };
    const selectAll = { 'name': 'all', 'isSelected': !isAllSelected };
    const filterList = useCallback((value) => {
        if (onSearch) {
            onSearch(value);
            return;
        }
        let list = [];
        keyword = value;
        if (value !== '') {
            list = availableList.filter((option) => {
                if (displayValue && displayValue in option) {
                    return option[displayValue].toLowerCase().includes(value.toLowerCase());
                }
                return option.name.toLowerCase().includes(value.toLowerCase());
            });
            setDisplayList([...list]);
        } else {
            setDisplayList([...availableList]);
        }
    }, [availableList, displayValue, onSearch]);

    useEffect(() => {
        if (availableList) {
            setDisplayList(availableList);
            if (!onSearch) {
                filterList(keyword);
            }
        }
    }, [availableList, filterList, onSearch]);

    return (
        <FormControl className={fullWidth ? classes.fullWidth : classes.formControl} style={{ position: 'relative' }} error={error}>
            {
                label &&
                <FormLabel className={classes.marginBottom10}>
                    {label}
                </FormLabel>
            }
            {selectedList.length > 0 && <Chip className={fullWidth ? classes.selectChip : classes.filterChip} label={selectedList.length === displayList.length ? `Selected All (${!isHideSelectedAllCount ? displayList.length : ""} items)` : `${selectedList.length} items selected`} />}
            <Select
                labelId="mutiple-select-label"
                multiple
                onChange={(event) => onChange(event.target.value[0])}
                value={[]}
                MenuProps={MenuProps}
                onOpen={() => { keyword = ''; filterList(keyword); }}
                style={{ marginTop: noMargin ? 10 : null }}
                disabled={disabled}
            >
                <TextField fullWidth className={classes.searchDropdown} value={''} onChange={(event) => filterList(event.target.value)} placeholder="Search" onKeyDown={(event) => event.stopPropagation()} />
                {
                    displayList.length > 0 && !selectedAll &&
                    <MenuItem key={'all'} value={selectAll}>
                        <CheckboxComponent checked={isAllSelected} name={'Select All'} checkboxLabel="Select All" showTooltip />
                    </MenuItem>
                }
                {
                    displayList.length > 0 ? displayList.slice(0, 30).map((option, index) => (
                        <MenuItem key={index} value={option} >
                            <CheckboxComponent checked={option.isSelected} name={displayValue && displayValue in option ? option[displayValue] : option.name} checkboxLabel={displayValue && displayValue in option ? option[displayValue] : option.name} showTooltip />
                        </MenuItem>
                    )) :
                        <MenuItem>
                            No Results Found
                        </MenuItem>
                }
            </Select>
            {
                helperText &&
                <FormHelperText>
                    {helperText}
                </FormHelperText>
            }
        </FormControl>
    );
};

AutoCompleteCheckBox.propTypes = {
    classes: PropTypes.func,
    availableList: PropTypes.array,
    onChange: PropTypes.func,
    selectionData: PropTypes.array,
    fullWidth: PropTypes.bool,
    label: PropTypes.string,
    displayValue: PropTypes.string,
    onSearch: PropTypes.func,
    noMargin: PropTypes.bool,
    isHideSelectedAllCount: PropTypes.bool,
    selectedAll: PropTypes.bool,
    error: PropTypes.bool,
    helperText: PropTypes.bool,
    disabled: PropTypes.bool
};

export default withStyles((theme) => ({
    ...AutoCompleteCheckBoxStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(AutoCompleteCheckBox);