import React from 'react';
import { Grid, IconButton, TextField, Typography } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

// import AutoComplete from '../AutoComplete/AutoComplete.jsx';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';
import { getOperators } from './QueryBuilderUtil.jsx';
import ValueEditor from './Widgets/ValueEditor.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import { sortTable } from '../../helpers/appHelpers.js';

const Rule = (props) => {
    const { rule, id, parentId, isConditionalRule, builderConfig, theme, isPatternChart, disabled, export_metrics_custom_value, hasParentGroup } = props;
    const { fields, operators, defaultField, onPropertyChange, onRuleRemove, classes, isComplexRule } = builderConfig;

    ValidatorForm.addValidationRule('dateValidation', (value, params) => {
        const validationParams = params ? params.split(',') : [];
        if (validationParams.length < 1) {
            throw Error('Invalid validation params');
        }
        const format = validationParams[0];
        const disableValidation = validationParams.length > 1 ? (validationParams[1] === 'true') : false;
        if (disableValidation) {
            return true;
        }
        const dateValue = moment(new Date(value), format);
        return dateValue.isValid();
    });


    const onElementChanged = (property, value) => {
        onPropertyChange(property, value, id, isConditionalRule);
    };

    const onFieldChanged = (value) => {
        onElementChanged('field', value);
    };

    const onOperatorChanged = (value, property) => {
        onElementChanged(property ? property : 'operator', value);
    };

    const onValueChanged = (value, property) => {
        onElementChanged(property ? property : 'value', value);
    };

    const onInputValueChange = (value) => {
        onElementChanged('input_value', value);
    };

    const removeRule = (event) => {
        event.preventDefault();
        event.stopPropagation();
        onRuleRemove(id, parentId, isConditionalRule);
    };

    const getUserAttributeFields = (inputValue) => {
        let attributeFields = [];
        if (fields && rule && rule.field) {
            attributeFields = fields.filter((p) => p.name.toLowerCase() !== rule.field.toLowerCase());
            if (inputValue) {
                attributeFields = attributeFields.filter((p) => p.name.toLowerCase().includes(inputValue.toLowerCase()));
            }
        }
        return attributeFields;
    };

    const getRuleOperators = (rule) => {
        let selectedOperators = [];
        if (!rule) {
            return selectedOperators;
        }
        if (rule.field === defaultField) {
            selectedOperators = [...getOperators(rule.fieldType.toLowerCase(), rule.isComplexRule, operators)];
        } else {
            selectedOperators = [...getOperators(rule.fieldType.toLowerCase(), rule.isComplexRule)];
        }
        selectedOperators = selectedOperators.filter((operator, index) => {
            return selectedOperators.indexOf(operator) === index;
        });

        selectedOperators = selectedOperators.filter((operator, index) => {
            return selectedOperators.indexOf(operator) === index;
        }).sort((a, b) => {
            if (a.label > b.label) {
                return 1;
            }
            if (b.label > a.label) {
                return -1;
            }
            return 0;
        });
        return selectedOperators;
    };

    const getPlaceholderText = (rule) => {
        let placeholderText = '';
        if (!rule) {
            return placeholderText;
        }
        if (rule && rule.fieldType) {
            switch (rule.fieldType.toLowerCase()) {
                case 'datetime':
                    placeholderText = 'MM-DD-YYYY HH:mm';
                    break;
                case 'date':
                    placeholderText = 'MM-DD-YYYY';
                    break;
                case 'time':
                    placeholderText = 'HH:mm';
                    break;
                default:
                    placeholderText = '';
                    break;
            }
        }
        return placeholderText;
    };

    const placeholderText = getPlaceholderText(rule);

    return (
        <Grid container direction="row" wrap="nowrap" justify="space-between" alignItems="center" className={classNames(classes.rule, classes.scroll)}>
            <Grid item className={classes.ruleContainer}>
                <Grid container direction="row" alignItems="center" wrap="nowrap">
                    <Grid className={classes.marginRight10}>
                        <AutoCompleteInput
                            name="attribute"
                            options={fields ? sortTable(fields, "asc", "name") : []}
                            value={rule.field ? rule.field : ''}
                            popperWidth={300}
                            getOptionLabel={
                                (option) => {
                                    if (option && option.name) {
                                        return option.name;
                                    }
                                    return option;
                                }
                            }
                            getOptionSelected={(option, value) => option.name === value}
                            direction={rule.isComplexRule && rule.field ? 'rtl' : ''}
                            renderInput={
                                (params) => <TextField {...params}
                                    className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                    onFocus={
                                        (event) => {
                                            if (isComplexRule && rule.field && event.currentTarget) {
                                                event.currentTarget.style.direction = 'ltr';
                                                event.currentTarget.style.textRendering = 'auto';
                                            }
                                        }
                                    }
                                    onBlur={
                                        (event) => {
                                            if (isComplexRule && rule.field && event.currentTarget) {
                                                event.currentTarget.style.direction = 'rtl';
                                                event.currentTarget.style.textRendering = 'geometricPrecision';
                                            }
                                        }
                                    }
                                    placeholder="Attribute" />
                            }
                            onChange={(_, selectedValue) => onFieldChanged(selectedValue)}
                            openOnFocus
                            blurOnSelect
                            disabled={disabled}
                            disableClearable
                            fullWidth
                            forcePopupIcon={false}
                            noOptionsText={""}
                        />
                        {/* <AutoComplete
                            placeholder="Attribute"
                            onChange={(event) => event.target.selectedItem && onFieldChanged(event.target.selectedItem)}
                            onBlur={() => { }}
                            value={rule.field ? rule.field : ''}
                            options={fields ? fields : []}
                            selectedValuePath="name"
                            displayMemberPath="name"
                            openSuggestionsOnClick
                            noDropPostion
                            direction={rule.isComplexRule && rule.field ? 'rtl' : ''}
                            name="attribute" /> */}
                    </Grid>
                    {
                        rule.field &&
                        <Grid className={classes.marginRight10}>
                            <AutoCompleteInput
                                name="operator"
                                popperWidth={300}
                                options={[...getRuleOperators(rule)]}
                                value={rule.operator && rule.operator.label ? rule.operator.label : ''}
                                getOptionLabel={
                                    (option) => {
                                        if (option && option.label) {
                                            return option.label;
                                        }
                                        return option;
                                    }
                                }
                                getOptionSelected={(option, value) => option.label === value}
                                renderInput={
                                    (params) => <TextField {...params}
                                        className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                        placeholder="Operator" />
                                }
                                onChange={(_, selectedValue) => onOperatorChanged(selectedValue)}
                                disabled={disabled || !rule.field}
                                openOnFocus
                                blurOnSelect
                                disableClearable
                                fullWidth
                                forcePopupIcon={false}
                                noOptionsText={""}
                            />
                            {/* <AutoComplete
                                placeholder="Operator"
                                onChange={(event) => event.target.selectedItem && onOperatorChanged(event.target.selectedItem)}
                                value={rule.operator && rule.operator.label ? rule.operator.label : ''}
                                options={
                                    rule.field === defaultField
                                        ? [...operators, ...getOperators(rule.fieldType.toLowerCase())]
                                        : [...getOperators(rule.fieldType.toLowerCase(), isComplexRule)]
                                }
                                selectedValuePath="value"
                                displayMemberPath="label"
                                disabled={!rule.field}
                                openSuggestionsOnClick
                                noDropPostion
                                name="operator" /> */}
                        </Grid>
                    }
                    {
                        // Value editor for complex rules
                        rule.operator && rule.operator.valueRequired && rule.isComplexRule && !rule.operator.range &&
                        <Grid className={classes.marginRight10}>
                            <AutoCompleteInput
                                options={getUserAttributeFields(rule.input_value)}
                                value={rule.input_value ? rule.input_value : ''}
                                popperWidth={300}
                                getOptionLabel={
                                    (option) => {
                                        if (option && option.name) {
                                            return option.name;
                                        }
                                        return option;
                                    }
                                }
                                getOptionSelected={(option, value) => option.name === value}
                                renderInput={
                                    (params) =>
                                        <ValidatorForm
                                            name="ruleValueForm"
                                            autoComplete="off"
                                            onSubmit={() => { }}>
                                            <TextBox {...params}
                                                placeholder={placeholderText}
                                                value={rule.input_value ? rule.input_value : ''}
                                                className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey, rule.operator && (rule.operator.label.toString().toLowerCase() === 'starts with' || rule.operator.label.toString().toLowerCase() === 'ends with') ? classes.removeCase : '')}
                                                onFocus={
                                                    (event) => {
                                                        if (isComplexRule && rule.field && event.currentTarget) {
                                                            event.currentTarget.style.direction = 'ltr';
                                                            event.currentTarget.style.textRendering = 'auto';
                                                        }
                                                    }
                                                }
                                                onBlur={
                                                    (event) => {
                                                        if (isComplexRule && rule.field && rule.input_value && (fields.filter((p) => p.name.toLowerCase() === rule.input_value.toLowerCase()).length > 0) && event.currentTarget) {
                                                            event.currentTarget.style.direction = 'rtl';
                                                            event.currentTarget.style.textRendering = 'geometricPrecision';
                                                        }
                                                    }
                                                }
                                                validators={[placeholderText && placeholderText.length > 0 ? `dateValidation:${placeholderText},${Boolean(isComplexRule && rule.field && rule.input_value && (fields.filter((p) => p.name.toLowerCase() === rule.input_value.toLowerCase()).length > 0))}` : '']}
                                                errorMessages={[placeholderText && placeholderText.length > 0 ? 'Invalid format' : '']}
                                            />
                                        </ValidatorForm>
                                }
                                onChange={(_, selectedValue) => onValueChanged(selectedValue)}
                                onInputChange={(event) => event && onInputValueChange(event.target.value)}
                                openOnFocus
                                blurOnSelect
                                disabled={disabled}
                                disableClearable
                                fullWidth
                                freeSolo
                                direction={isComplexRule && rule.field && rule.input_value && (fields.filter((p) => p.name.toLowerCase() === rule.input_value.toLowerCase()).length > 0) ? 'rtl' : ''}
                                forcePopupIcon={false}
                                noOptionsText={""}
                            />
                            {/* <AutoComplete
                                placeholder=""
                                onChange={(event) => event.target.selectedItem && onValueChanged(event.target.selectedItem)}
                                onBlur={() => onInputValueChange(rule.input_value)}
                                onInputValueChange={(value) => onInputValueChange(value)}
                                value={rule.input_value ? rule.input_value : ''}
                                options={getUserAttributeFields()}
                                selectedValuePath="name"
                                displayMemberPath="name"
                                openSuggestionsOnClick
                                noDropPostion
                                direction={rule.isComplexRule && rule.value && typeof (rule.value) !== 'string' ? 'rtl' : ''}
                                name="attribute" /> */}
                        </Grid>
                    }
                    {
                        // Value editor for simple rules
                        rule.operator && rule.operator.valueRequired && (!rule.isComplexRule || rule.operator.range) &&
                        <Grid className={classes.marginRight10}>
                            <ValueEditor rule={{ ...rule }}
                                onChange={(...props) => onValueChanged(...props)}
                                onOperatorChanged={(...props) => onOperatorChanged(...props)}
                                disabled={disabled}
                                isPatternChart={isPatternChart} />
                        </Grid>
                    }
                    {
                        export_metrics_custom_value && !hasParentGroup &&
                        <Grid className={classes.marginRight10}>
                            <Typography >
                                THEN PRINT
                            </Typography>
                        </Grid>
                    }
                    {
                        export_metrics_custom_value && !hasParentGroup &&
                        <Grid className={classes.marginRight10}>
                            <TextBox
                                label=""
                                value={rule.custom_value ? rule?.custom_value : ""}
                                name="custom_value"
                                fullWidth
                                onChange={(event) => onValueChanged(event.target.value, event.target.name)}
                            />
                        </Grid>
                    }
                </Grid>
            </Grid>

            {
                !disabled &&
                <Grid item>
                    <ToolTipComponent title="Delete" arrow>
                        <IconButton className={classes.actionIconButton} onClick={(event) => removeRule(event)}>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" width="16.408" height="16" viewBox="0 0 19.408 22">
                            <g transform="translate(-76.035 -52)">
                                <path d="M128.865,166H114.641a.549.549,0,0,0-.536.61l1.459,12.768a2.789,2.789,0,0,0,2.77,2.474h7.082a2.777,2.777,0,0,0,2.775-2.525L129.4,166.6a.554.554,0,0,0-.138-.421A.546.546,0,0,0,128.865,166Zm-1.748,13.219a1.708,1.708,0,0,1-1.7,1.555h-7.082a1.715,1.715,0,0,1-1.7-1.523l-1.39-12.173h13.028Z" transform="translate(-36.014 -107.853)" fill={theme.palette.primary.main} />
                                <path d="M94.9,54.7H89.19v-1.03A1.682,1.682,0,0,0,87.51,52H83.968a1.682,1.682,0,0,0-1.68,1.667V54.7H76.574a.539.539,0,0,0,0,1.078H94.9a.539.539,0,0,0,0-1.078Zm-6.792,0H83.366v-1.03a.6.6,0,0,1,.6-.588H87.51a.6.6,0,0,1,.6.588Z" fill={theme.palette.primary.main} />
                            </g>
                        </svg> */}
                            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="18" height="18" viewBox="0 0 32 32">
                                <g id="Delete">
                                    <path fill={theme.palette.primary.main}
                                        stroke={theme.palette.primary.main}
                                        strokeWidth="0.92"
                                        strokeMiterlimit="10"
                                        d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3L22.3,25.8z" />
                                    <path fill={theme.palette.primary.main}
                                        stroke={theme.palette.primary.main}
                                        strokeWidth="0.92"
                                        strokeMiterlimit="10"
                                        d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                </g>
                            </svg>
                        </IconButton>
                    </ToolTipComponent>
                </Grid>
            }
        </Grid>
    );
};

Rule.propTypes = {
    rule: PropTypes.object,
    id: PropTypes.string,
    parentId: PropTypes.string,
    builderConfig: PropTypes.object,
    theme: PropTypes.object,
    isPatternChart: PropTypes.bool,
    disabled: PropTypes.bool,
    isConditionalRule: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    export_metrics_custom_value: PropTypes.bool,
    hasParentGroup: PropTypes.bool
};

export default Rule;