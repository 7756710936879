import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TextValidator } from 'react-material-ui-form-validator';

class IntegerInput extends Component {
	onChange = (event) => {
		const regex = new RegExp('^[0-9]*$');
		if (event.target.value === '' || regex.test(event.target.value)) {
			this.props.onChange(event);
		}
	}

	render() {
		return (
			<TextValidator autoComplete={"off"} {...this.props} onChange={(event) => this.onChange(event)} />
		);
	}
}

IntegerInput.propTypes = {
	onChange: PropTypes.func
};

export default IntegerInput;