import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import * as apiService from '../services/apiService';
import { catalogActions } from '../constants/actionTypes/catalogActionTypes';
import { alertActions } from '../constants/actionTypes/alertActionTypes';
import { alerts } from '../config/alerts';

export const getDatasourceCatalog = (datasourceId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDatasourceCatalog}/${datasourceId}`;
    return (dispatch) => {
        dispatch(request(catalogActions.GET_DATASOURCE_CATALOG_INFO_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(catalogActions.GET_DATASOURCE_CATALOG_INFO_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(catalogActions.GET_DATASOURCE_CATALOG_INFO_FAILURE, error));
            throw error;
        });
    };
};

export const getDatasetCatalog = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDatasetCatalog}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(catalogActions.GET_DATASET_CATALOG_INFO_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(catalogActions.GET_DATASET_CATALOG_INFO_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(catalogActions.GET_DATASET_CATALOG_INFO_FAILURE, error));
            throw error;
        });
    };
};

export const getAttributesCatalog = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getAttributesCatalog}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(catalogActions.GET_ATTRIBUTES_CATALOG_INFO_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(catalogActions.GET_ATTRIBUTES_CATALOG_INFO_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(catalogActions.GET_ATTRIBUTES_CATALOG_INFO_FAILURE, error));
            throw error;
        });
    };
};

export const clearCatalogInfo = () => {
    return {
        type: catalogActions.CLEAR_CATALOG_INFO
    };
};

export const getRecentlyUsedQueries = (datasourceId, datasetId = 0) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getRecentQueries}/${datasourceId}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(catalogActions.GET_RECENT_QUERIES_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(catalogActions.GET_RECENT_QUERIES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(catalogActions.GET_RECENT_QUERIES_FAILURE, error));
            return null;
        });
    };
};

export const getDDLQuery = (datasourceId, datasetId = 0) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDDLQuery}/${datasourceId}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(catalogActions.GET_DDL_QUERY_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(catalogActions.GET_DDL_QUERY_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(catalogActions.GET_DDL_QUERY_FAILURE, error));
            return null;
        });
    };
};

export const getDatasourceCatalogTables = (datasourceId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDatasourceCatalogTables}/${datasourceId}`;
    return (dispatch) => {
        dispatch(request(catalogActions.GET_DATASOURCE_CATALOG_TABLES_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(catalogActions.GET_DATASOURCE_CATALOG_TABLES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(catalogActions.GET_DATASOURCE_CATALOG_TABLES_FAILURE, error));
            return null;
        });
    };
};

export const getDatasetCatalogTables = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getDatasetCatalogTables}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(catalogActions.GET_DATASET_CATALOG_TABLES_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(catalogActions.GET_DATASET_CATALOG_TABLES_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(catalogActions.GET_DATASET_CATALOG_TABLES_FAILURE, error));
            return null;
        });
    };
};

export const getAttributesMetadata = (datasetId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.getAttributesMetadata}/${datasetId}`;
    return (dispatch) => {
        dispatch(request(catalogActions.GET_TABLE_COLUMN_METADATA_REQUEST));
        return apiService.getRequest(endpoint).then((response) => {
            dispatch(success(catalogActions.GET_TABLE_COLUMN_METADATA_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(catalogActions.GET_TABLE_COLUMN_METADATA_FAILURE, error));
            return null;
        });
    };
};

export const runMetadata = (datasourceId) => {
    const endpoint = `${appConstants.END_POINTS.catalog.runCatalog}/${datasourceId}`;
    return (dispatch) => {
        dispatch(request(catalogActions.RUN_CATALOG_REQUEST));
        return apiService.putRequest(endpoint).then((response) => {
            dispatch(success(catalogActions.RUN_CATALOG_SUCCESS, response.data));
			const message = alerts.jobStatus.replace("<job_name>", response.data);
            dispatch(success(alertActions.ALERT_SUCCESS, message));
        }, (error) => {
            dispatch(failure(catalogActions.RUN_CATALOG_FAILURE, error));
        });
    };
};