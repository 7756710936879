import React, { memo } from 'react';
import {
    Grid, withStyles, Card, Table, TableHead, TableRow, TableCell, Typography, TableBody, IconButton
} from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import moment from 'moment-timezone';
import NoResultImg from '../../assets/images/NoDataset.png';


import Styles from '../../layouts/Styles.jsx';
import DatasetStyles from './DatasetStyles.jsx';
import CheckboxComponent from '../ChecboxComponent/CheckboxComponent.jsx';
import noPrev from '../../assets/images/nopreview.svg';

const WorkbookItemList = ({ datasourceId, workbookId, classes, theme, items, onSelectDataset, isChecked, selectView }) => {

    const datetimeFormat = 'MM/DD/YYYY HH:mm';

    return (
        <Grid>
            {
                items.length > 0 ?
                    <Grid className={classNames(classes.tableContainer, classes.itemHeight)}>
                        <Card className={classes.tableaccordian}>
                            <Table className={classes.scrollWidthFix}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>
                                            <Grid>
                                                <Grid container>
                                                    <CheckboxComponent
                                                        className={"attributeSelectorCheck"}
                                                        checked={isChecked}
                                                        onClick={(event) => onSelectDataset(event, "All")}
                                                    />
                                                    <Typography className={classNames(classes.fontWeight600)}>
                                                        {'Name'}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={classNames(classes.fontWeight600)}>
                                                {'Type'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={classNames(classes.fontWeight600)}>
                                                {'Owner'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={classNames(classes.fontWeight600)}>
                                                {'Created Date'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography className={classes.fontWeight600}>
                                                {'Modified Date'}
                                            </Typography>
                                        </TableCell>
                                        <TableCell className={classes.noPaddingLeft} style={{ width: '75px' }}>
                                            <Typography className={classes.fontWeight600}>
                                                {'Actions'}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        items && items.map((item, index) =>
                                            <TableRow key={index} className={classes.cursorPointer} onClick={() => selectView(item)}>
                                                <TableCell className={classNames(classes.tableCellPadding, classes.flexWidth)}>
                                                    <Grid container alignItems="center" className={classes.datasetTitleSection}>
                                                        <CheckboxComponent
                                                            checked={Boolean(item.isChecked)}
                                                            onClick={(event) => event.stopPropagation()}
                                                            onChange={(event) => onSelectDataset(event, item.id)}
                                                        />
                                                        <img src={item.thumb_image !== "" ? item.thumb_image : noPrev} alt="" width={50} height={30} className={classes.listImage} />
                                                        <TooltipComponent arrow>
                                                            <Typography
                                                                className={classes.datasetName}
                                                                component="span"
                                                            >
                                                                {item.name}
                                                            </Typography>
                                                        </TooltipComponent>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className={classes.datasetTabText}>
                                                        {item && item.view_type ? item.view_type : 'Sheet'}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Grid className={classes.timeSection}>
                                                        <Typography className={classes.datasetTabText}>
                                                        {item && item.owner ? item.owner : 'admin'}
                                                        </Typography>
                                                    </Grid>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className={classes.datasetTabText}>
                                                        {item['c_date'] ? moment(item['c_date']).format(datetimeFormat) : moment(item['created_date']).format(datetimeFormat)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>
                                                    <Typography className={classes.datasetTabText}>
                                                        {item['m_date'] ? moment(item['m_date']).format(datetimeFormat) : moment(item['modified_date']).format(datetimeFormat)}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell className={classes.flextable}>
                                                <IconButton className={classes.iconButtonSmall} onClick={() => selectView(item)}>
                                                    <svg
                                                        version="1.1"
                                                        id="Layer_1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="21.349"
                                                        height="23.349"
                                                        x="0px"
                                                        y="0px"
                                                        viewBox="0 0 32 32"
                                                    >
                                                        <g id="edit" transform="translate(0 0)">
                                                            <path
                                                                fill={theme.palette.primary.main}
                                                                stroke={theme.palette.primary.main}
                                                                strokeWidth="0.3"
                                                                strokeMiterlimit="10"
                                                                d="M27.2,8L24,4.8c-0.8-0.8-2.2-0.8-3,0L6.5,19.4c-0.1,0.1-0.1,0.1-0.1,0.2l-2.1,7.5c-0.1,0.3,0.1,0.6,0.4,0.7
                        c0.1,0,0.2,0,0.3,0l7.5-2.1c0.1,0,0.2-0.1,0.2-0.1l14.6-14.4C28,10.2,28,8.9,27.2,8L27.2,8z M18.6,8.7l2,2L10.2,21l-0.7-1.5
                        c-0.1-0.2-0.3-0.3-0.5-0.3H8.2L18.6,8.7z M5.5,26.5L6.2,24L8,25.8L5.5,26.5z M11.7,24.7l-2.6,0.7l-2.6-2.6l0.7-2.6h1.4l0.9,1.8
                        c0.1,0.1,0.1,0.2,0.2,0.2l1.8,0.9L11.7,24.7L11.7,24.7z M12.8,23.8V23c0-0.2-0.1-0.4-0.3-0.5L11,21.7l10.3-10.3l2,2L12.8,23.8z
                        M26.4,10.3l-2.4,2.3l-4.7-4.7l2.3-2.4c0.4-0.4,1.1-0.4,1.5,0l3.2,3.2C26.8,9.2,26.8,9.9,26.4,10.3L26.4,10.3z"
                                                            />
                                                        </g>
                                                    </svg>
                                                </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                        </Card>
                    </Grid>
                    :
                    <Grid container alignItems="center" justify="center" className={classes.noResultFound}>
                        <Grid>
                            <img src={NoResultImg} alt="No Result Found" />
                            <Typography variant="h4" align="center">
                                {'No Result Found'}
                            </Typography>
                        </Grid>
                    </Grid>
            }
        </Grid>
    );
};

WorkbookItemList.propTypes = {
    classes: PropTypes.object,
    datasourceId: PropTypes.number,
    workbookId: PropTypes.string,
    theme: PropTypes.object,
    items: PropTypes.array,
    onSelectDataset: PropTypes.func,
    isChecked: PropTypes.bool,
    selectView: PropTypes.number
};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(memo(WorkbookItemList));