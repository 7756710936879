const DriftDashBoardStyle = (theme) => ({
    titleContainer: {
        marginTop: 8,
        marginBottom: 16
    },
    alertContainer: {
        marginLeft: 8
    },
    ruleAccordian: {
        margin: '16px 0',
        boxShadow: '0px 0px 6px #e1e5e6',
        '&::before': {
            height: 0
        },
        '&:first-child': {
            marginTop: 0
        }
    },
    ruleAccordianSummary: {
        padding: '2px 16px',
        borderLeft: '5px solid',
        '& div > p': {
            marginTop: 2,
            color: theme.palette.grey.dark
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0'
        }
    },
    ruleName: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
        maxWidth: 300,
        '& h3': {
            fontSize: 16,
            textTransform: 'uppercase'
            // maxWidth: 300
        },
        '& .MuiBox-root': {
            padding: '2px 8px',
            marginLeft: 8,
            '& p': {
                fontSize: 14,
                color: theme.palette.common.white,
                fontWeight: 'bold'
            }
        }
    },
    ruleDescription: {
        lineHeight: 1.2,
        maxWidth: 300,
        paddingTop: 2
    },
    limit: {
        '& .MuiGrid-item': {
            padding: 16,
            '&:first-child': {
                marginRight: 10
            }
        },
        '& h5': {
            fontWeight: 'bold',
            fontSize: 18
        },
        '& p': {
            fontSize: 14,
            fontWeight: 'normal',
            color: `inherit !important`
        }
    },
    heading: {
        marginBottom: -8
    },
    ruleSwitch: {
        margin: '0 20px',
        display: 'flex',
        flexDirection: 'column-reverse',
        alignItems: 'baseline',
        '& span.MuiFormControlLabel-label': {
            marginBottom: -10,
            fontSize: 14,
            paddingLeft: 4
        }
    },
    accordianChartContainer: {
        background: theme.palette.background.container,
        padding: 20,
        '&>.MuiGrid-container': {
            background: theme.palette.common.white,
            border: `1px solid ${theme.palette.grey.extraLight}`,
            padding: '20px 16px 20px 35px'
        }
    },
    limitInput: {
        width: '80px !important',
        margin: '0 8px',
        '& input': {
            height: '28px !important',
            background: `${theme.palette.background.container} !important`
        }
    },
    btnDriftMode: {
        height: 26,
        minWidth: 50,
        marginRight: 14,
        fontSize: '13px !important',
        padding: '0px 10px !important',
        backgroundColor: theme.palette.grey.extraLight,
        color: theme.palette.grey.main,
        cursor: 'pointer',
        '& p': {
            fontWeight: 'bold',
            color: `${theme.palette.grey.main} !important`
        },
        '&.active': {
            backgroundColor: '#78D2FF',
            '& p': {
                fontWeight: 'bold',
                color: `${theme.palette.common.white} !important`
            }
        }
    },
    btnMute: {
        padding: 10,
        '&.active': {
            color: '#78D2FF'
        },
        '& .MuiSvgIcon-root': {
            height: 24,
            width: 24
        }
    },
    datasourceFilterSectionDrift: {
        background: '#fff',
        padding: 2
    },
    closeIcon: {
        padding: 0,
        margin: '0px 10px'
    },
    filterIcon: {
        padding: 0,
        margin: '0px 10px'
    },
    driftFilter: {
        marginRight: 15
    },
    FilterHideShowContainer: {
        '& .datasourceFilterSection': {
            background: '#fff',
            padding: 2
        }
    },
    scrollContainer: {
        overflowY: 'auto',
        maxHeight: '300px'
    }
});

export default DriftDashBoardStyle;