import React, { useState, useCallback, useEffect } from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import Styles from '../../layouts/Styles.jsx';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CompleteNessStyle from './CompleteNessStyle.jsx';
import { getDatasetLevelProfileData } from '../../actions/scheduleActions';

//Image
import NoDataset from '../../assets/images/NoNotification.svg';

const CompleteNess = (props) => {
    const { sourceId, classes, logtype } = props;
    const [prodileDatasetData, setProdileData] = useState([]);
    const dispatch = useDispatch();

    const getDatasetProfileDashboard = useCallback(() => {
        dispatch(getDatasetLevelProfileData(sourceId, logtype)).then((response) => {
            if (response) {
                setProdileData([...response]);
            }
        });
    }, [sourceId, logtype, dispatch]);


    useEffect(() => {
        getDatasetProfileDashboard();
    }, [getDatasetProfileDashboard]);

    return (
        <Grid className={classNames(classes.card, classes.alertscard)}>
            {
                prodileDatasetData.length > 0 ?
                    prodileDatasetData.map((row, index) => (
                        <Typography key={index} component="h5" variant="h5">
                            {row.notification_text}
                        </Typography>
                    ))
                    :
                    <Grid container className={classes.alertNoResult} alignItems="center" justify="center" direction="column">
                        <Grid align="center">
                            <img src={NoDataset} alt="No Record Found" />
                            <Typography color="textSecondary" component="h5" variant="h5" align="center" className={classes.marginBottom10}>
                                {"No Alerts Found"}
                            </Typography>
                        </Grid>
                    </Grid>
            }
        </Grid>
    );
};

CompleteNess.propTypes = {
    classes: PropTypes.object,
    sourceId: PropTypes.number,
    logtype: PropTypes.string
};

export default withStyles((theme) => ({
    ...CompleteNessStyle(theme),
    ...Styles(theme)
}), { withTheme: true })(CompleteNess);