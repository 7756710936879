import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Typography, withStyles, MenuItem } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';
import TextBox from '../TextBox/TextBox.jsx';
import Search from '../TextBox/Search.jsx';
import DatasourceListStyles from '../DatasourceList/DatasourceListStyles.jsx';
import { appConstants } from '../../constants/appConstants';

const ActionItemFilter = (props) => {
    const { classes, filters, onChangeFilter } = props;
    const filterStatus = ["All", "New", "Open", "Resolved"];
    const users = useSelector(({ account }) => account.users);

    return (

        <Grid container alignItems="center" justify="space-between" className={classNames(classes.headerContainer, classes.catHeaderContainer)}>
            <Grid item>
                <Typography variant="h2" component="h2">
                    Action items
                </Typography>
            </Grid>
            <Grid item>
                <Grid container alignItems="center">
                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                        <TextBox
                            label="Reporter"
                            value={filters.reporter}
                            name="reporter"
                            fullWidth
                            select
                            onChange={(event) => onChangeFilter(event.target.name, event.target.value)}
                            SelectProps={
                                {
                                    value: filters.reporter,
                                    onChange: (event) => onChangeFilter(event.target.name, event.target.value),
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }
                                    // IconComponent: () => DropDownIcon()
                                }
                            }
                        >
                            {
                                <MenuItem value={'All'}>
                                    {'All'}
                                </MenuItem>
                            }
                            {
                                users.map((item, index) =>
                                    <MenuItem key={index} value={item.username}>
                                        {item.username}
                                    </MenuItem>
                                )
                            }
                        </TextBox>
                    </Grid>
                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                        <TextBox
                            label="Filter By"
                            value={filters.filter}
                            name="filter"
                            fullWidth
                            select
                            onChange={(event) => onChangeFilter(event.target.name, event.target.value)}
                            SelectProps={
                                {
                                    value: filters.filter,
                                    onChange: (event) => onChangeFilter(event.target.name, event.target.value),
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }
                                    // IconComponent: () => DropDownIcon()
                                }
                            }
                        >
                            {
                                filterStatus.map((status, index) =>
                                    <MenuItem key={index} value={status}>
                                        {status}
                                    </MenuItem>
                                )
                            }
                        </TextBox>
                    </Grid>
                    <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                        <TextBox
                            label="Sort By"
                            value={filters.sort}
                            name="sort"
                            fullWidth
                            select
                            onChange={(event) => onChangeFilter(event.target.name, event.target.value)}
                            SelectProps={
                                {
                                    value: filters.sort,
                                    onChange: (event) => onChangeFilter(event.target.name, event.target.value),
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }
                                    // IconComponent: () => DropDownIcon()
                                }
                            }
                        >
                            {
                                appConstants.priority.map((priority, index) =>
                                    <MenuItem key={index} value={priority}>
                                        {priority}
                                    </MenuItem>
                                )
                            }
                        </TextBox>
                    </Grid>
                    <Grid item>
                        <Search
                            value={filters.search}
                            placeholder="Search Here"
                            className={classes.dqSearchBox}
                            onChange={(value) => onChangeFilter('search', value)}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    );
};

ActionItemFilter.propTypes = {
    classes: PropTypes.object,
    filters: PropTypes.object,
    onChangeFilter: PropTypes.func
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ActionItemFilter);