import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography, FormGroup, FormControlLabel, Switch, Slider, IconButton } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import SemanticGlossaryStyles from './SemanticGlossaryStyles.jsx';
import Loader from '../Loaders/Loader.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import { getSemanticModelDefinition, updateAttributes, getAllEnrichment, deleteSemanticModelAttributeRule } from '../../actions/modelActions';
import ValuesListItem from '../Dataset/Properties/ValuesListItem.jsx';
import PatternInputEditor from '../PatternInput/PatternInputEditor.jsx';
import ChipSelectList from '../ChipSelectList/ChipSelectList.jsx';
import Localization from '../../constants/localization';
import SelectLibrary from './SelectLibrary.jsx';
import SemanticGlossaryRules from './SemanticGlossaryRules.jsx';
import { getFieldType } from '../RuleBuilder/QueryBuilderUtil.jsx';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const SemanticGlossaryDefinition = (props) => {
    const { classes, theme, dataType } = props;
    const [definition, setDefinition] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [visiblePatternDialog, setVisiblePatternDialog] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedPattern, setSelectedPattern] = useState(null);
    const [anchorEditorEl, setAnchorEditorEl] = useState(null);
    const [trackEnumUpdate, setEnumUpdate] = useState(false);
    const dispatch = useDispatch();
    const enrichment = useSelector(({ model }) => model.enrichment);
    const glossary = useSelector(({ model }) => model.selectedGlossary);
    const glossaryId = glossary ? glossary.id : 0;

    const getEnrichment = useCallback(() => {
        dispatch(getAllEnrichment());
    }, [dispatch]);

    const updateDefinition = useCallback((property, value) => {
        if (property === "equals" || property === "contains") {
            property = "keyword";
            const keyword = definition.keyword;
            keyword[property] = value;
            value = keyword;
        }
        const requestParams = {
            [property]: value ? value : definition[property]
        };
        dispatch(updateAttributes(glossaryId, requestParams));
    }, [definition, dispatch, glossaryId]);

    const showAddPatternDialog = useCallback((event, index) => {
        setAnchorEditorEl(event.target);
        if (index === -1) {
            setSelectedPattern({
                name: '',
                pattern: '',
                isvalid: true,
                sense: false
            });
        } else {
            const patterns = [...definition.patterns];
            setSelectedPattern({ ...patterns[index] });
        }
        setSelectedIndex(index);
        setVisiblePatternDialog(true);
    }, [definition]);

    const onChange = (property, value) => {
        const regex = /^[0-9]*$/;
        const minRegex = /^-?\d*[.,-]?\d*$/;
        if ((property === "min_range" || property === "max_range") && !minRegex.test(value) && (dataType !== "Date" && dataType !== "Time" && dataType !== "DateTime")) {
            return false;
        } else if ((property === "min_length" || property === "max_length") && !regex.test(value)) {
            return false;
        }
        if (property === "is_null" || property === "is_blank" || property === "is_unique" || property === "is_primary" || property === "sensitivity" || property === "threshold") {
            if (property === 'is_primary') {
                definition['is_null'] = false;
                updateDefinition('is_null', false);
                definition['is_blank'] = false;
                updateDefinition('is_blank', false);
                definition['is_unique'] = value;
                updateDefinition('is_unique', value);
                definition[property] = value;
                updateDefinition(property, value);
            } else {
                definition[property] = value;
                updateDefinition(property, value);
            }
        } else if (property === "equals" || property === "contains") {
            value.forEach((element, idx) => {
                value[idx] = element.replaceAll(/[^a-zA-Z0-9]/gi, '');
            });
            // eslint-disable-next-line id-denylist
            const finalvalue = value.filter((e) => e !== '');
            if (!definition.keyword[property]) {
                definition.keyword[property] = [];
            }
            definition.keyword[property] = finalvalue;
        } else {
            definition[property] = value;
        }

        if (definition.is_primary && (property === 'is_null' || property === 'is_blank' || property === 'is_unique')) {
            definition['is_null'] = false;
            updateDefinition('is_null', false);
            definition['is_blank'] = false;
            updateDefinition('is_blank', false);
            definition['is_unique'] = true;
            updateDefinition('is_unique', true);
        }
        setDefinition({ ...definition });
    };

    const updateCustomRules = (value) => {
        definition.custom_rules = value;
        setDefinition({ ...definition });
        updateDefinition("custom_rules", value);
    };

    const deleteUpdateCustomRules = (rule) => {
        const requestParams = {
            "deleted_rules": { ...rule }
        };
        dispatch(deleteSemanticModelAttributeRule(glossaryId, requestParams));
    };

    const hideAddPatternDialog = () => {
        setVisiblePatternDialog(false);
        setSelectedPattern({
            name: '',
            pattern: '',
            isvalid: true,
            sense: false
        });
        setSelectedIndex(null);
    };

    const addPattern = useCallback(() => {
        const patterns = definition.patterns;
        if (selectedIndex === -1) {
            patterns.splice(0, 0, selectedPattern);
        } else {
            patterns[selectedIndex] = selectedPattern;
        }
        setDefinition({ ...definition, patterns: [...patterns] });
        hideAddPatternDialog();
        updateDefinition("patterns", patterns);
    }, [definition, selectedIndex, selectedPattern, updateDefinition]);

    const removePattern = useCallback((index) => {
        const patterns = definition.patterns;
        patterns.splice(index, 1);
        updateDefinition("patterns", patterns);
        setDefinition({ ...definition, patterns: [...patterns] });
    }, [definition, updateDefinition]);

    const onHandleChange = (property, value) => {
        let pattern = selectedPattern;
        if (typeof property !== 'object') {
            pattern[property] = value;
            if (property === "isvalid") {
                pattern.sense = value;
            }
        } else {
            pattern = property;
        }
        setSelectedPattern({ ...pattern });
    };

    const updateEnumeration = useCallback(() => {
        definition.enum.values = definition.enum.values.filter((data) => data.value && data.value.trim() !== "");
        definition.enum.on = Boolean((definition.enum.values.length > 0) || definition.enum.ref_id);
        setDefinition({ ...definition });
        updateDefinition("enum", definition.enum);
    }, [definition, updateDefinition]);

    const addEnum = useCallback(() => {
        const enums = definition.enum.values;
        if (enums && enums.length > 0 && enums[0].value.length <= 0) {
            return;
        }
        const enumeration = {
            value: '',
            isvalid: true,
            sense: false
        };

        enums.splice(0, 0, enumeration);
        definition.enum.values = [...enums];
        setDefinition({ ...definition });
        if (!trackEnumUpdate) {
            setEnumUpdate(true);
        }
    }, [definition, trackEnumUpdate]);

    const removeEnum = useCallback((index) => {
        const enums = definition.enum.values;
        enums.splice(index, 1);
        definition.enum.values = [...enums];
        setDefinition({ ...definition });
        if (!trackEnumUpdate) {
            setEnumUpdate(true);
        }
    }, [definition, trackEnumUpdate]);

    const updateEnum = useCallback((propertyName, value, index) => {
        if (value.trim() !== "") {
            const enums = definition.enum.values;
            enums[index][propertyName] = value.trim();
            definition.enum.values = [...enums];
            setDefinition({ ...definition });
            if (!trackEnumUpdate) {
                setEnumUpdate(true);
            }
        }
    }, [definition, trackEnumUpdate]);

    const getDefinition = useCallback(() => {
        setLoading(true);
        dispatch(getSemanticModelDefinition(glossaryId)).then((response) => {
            if (response) {
                setDefinition({ ...response });
            }
            setLoading(false);
        });
    }, [dispatch, glossaryId]);

    useEffect(() => {
        getDefinition();
    }, [getDefinition]);

    useEffect(() => {
        if (enrichment.length === 0) {
            getEnrichment();
        }
    }, [enrichment.length, getEnrichment]);

    return (
        <Grid container spacing={4} className={classes.definitionContainer}>
            {/* <Grid item xs={12}>
                <Typography component="h6" variant="h6">
                    Tags
                </Typography>
                <Tags type="tags" focus tags={definition.tags ? definition.tags : []} updateTags={updateTags} updateTagItem={updateTagItem} />
            </Grid> */}
            <Grid item xs={6}>
                <Typography component="h6" variant="h6">
                    Properties
                </Typography>
                <Typography className={classes.lightTxt}>
                    {Localization.properties.property.nullblank}
                </Typography>
                <FormGroup row className={classes.paddingLeft12}>
                    <FormControlLabel
                        className={classnames(classes.formLabelSection, classes.formLabel)}
                        control={
                            <Switch
                                color="secondary"
                                name="is_null"
                                checked={Boolean(definition.is_null)}
                                value={definition.is_null}
                                onChange={(event) => onChange(event.target.name, !definition.is_null)}
                            />
                        }
                        label="Null"
                    />
                    <FormControlLabel
                        className={classnames(classes.formLabelSection, classes.formLabel)}
                        control={
                            <Switch
                                color="secondary"
                                name="is_blank"
                                checked={Boolean(definition.is_blank)}
                                value={definition.is_blank}
                                onChange={(event) => onChange(event.target.name, !definition.is_blank)}
                            />
                        }
                        label="Blank"
                    />
                    <FormControlLabel
                        className={classnames(classes.formLabelSection, classes.formLabel)}
                        control={
                            <Switch
                                color="secondary"
                                name="is_unique"
                                checked={Boolean(definition.is_unique)}
                                value={definition.is_unique}
                                onChange={(event) => onChange(event.target.name, !definition.is_unique)}
                            />
                        }
                        label="Unique"
                    />
                    <FormControlLabel
                        className={classnames(classes.formLabelSection, classes.formLabel)}
                        control={
                            <Switch
                                color="secondary"
                                name="is_primary"
                                checked={Boolean(definition.is_primary)}
                                value={definition.is_primary}
                                onChange={(event) => onChange(event.target.name, !definition.is_primary)}
                            />
                        }
                        label="Primary"
                    />
                </FormGroup>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
                <Typography component="h6" variant="h6">
                    Sensitivity/Threshold
                </Typography>
                <Typography className={classes.lightTxt}>
                    {Localization.properties.property.sensitivity}
                </Typography>
                <Grid container>
                    <Grid item className={classes.definitionSliderContainer}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                            <Slider name="sensitivity"
                                color="secondary"
                                className={classes.SensitivityMuiSlider}
                                style={{ width: '95%' }}
                                defaultValue={1}
                                track={'normal'}
                                value={definition && definition.sensitivity ? definition.sensitivity : 1}
                                onChange={(_event, value) => onChange('sensitivity', value)}
                                min={1}
                                max={3}
                                step={1}
                            />
                            <Typography variant="body2" className={classes.sliderValue}>
                                {definition.sensitivity ? definition.sensitivity : '1'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item style={{ width: '10%' }} />
                    <Grid item className={classes.definitionSliderContainer}>
                        <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                            <Slider name="threshold"
                                color="secondary"
                                className={classes.SensitivityMuiSlider}
                                style={{ width: '95%' }}
                                defaultValue={67}
                                track={'normal'}
                                value={definition && definition.threshold ? definition.threshold : 1}
                                onChange={(_event, value) => onChange('threshold', value)}
                                min={1}
                                max={100}
                            />
                            <Typography variant="body2" className={classes.sliderValue}>
                                {definition.threshold ? `${definition.threshold}%` : '67%'}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Typography component="h6" variant="h6">
                    Length
                </Typography>
                <Typography className={classes.lightTxt}>
                    {Localization.properties.value.length}
                </Typography>
                <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                    <Grid item className={classnames(classes.valuesTextboxContainer, classes.sliderInput, classes.propertyComponents)}>
                        <TextBox placeholder="Min Length"
                            name="min_length"
                            id="min_length"
                            value={definition.min_length ? definition.min_length : ''}
                            onChange={(event) => onChange(event.target.name, event.target.value)}
                            onBlur={(event) => updateDefinition(event.target.name, event.target.value)}
                            className={classes.inputOutline} />
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" className={classes.rangeSeparator}>
                            {'-'}
                        </Typography>
                    </Grid>
                    <Grid item className={classnames(classes.valuesTextboxContainer, classes.sliderInput, classes.propertyComponents)}>
                        <TextBox placeholder="Max Length"
                            name="max_length"
                            id="max_length"
                            value={definition.max_length ? definition.max_length : ''}
                            onChange={(event) => onChange(event.target.name, event.target.value)}
                            onBlur={(event) => updateDefinition(event.target.name, event.target.value)}
                            className={classes.inputOutline} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={5}>
                <Typography component="h6" variant="h6">
                    Range
                </Typography>
                <Typography className={classes.lightTxt}>
                    {Localization.properties.value.range}
                </Typography>
                <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                    <Grid item className={classnames(classes.valuesTextboxContainer, classes.sliderInput, classes.propertyComponents)}>
                        {
                            (dataType === "Date") ?
                                <DatePicker
                                    autoOk
                                    variant="inline"
                                    format="MM/DD/YYYY"
                                    minDateMessage=""
                                    value={definition.min_range ? moment(definition.min_range).format('MM/DD/YYYY') : Date.now()}
                                    onChange={(date) => onChange("min_range", moment(date).format('MM/DD/YYYY'))}
                                    maxDate={definition.max_range ? moment(definition.max_range).format('MM/DD/YYYY') : Date.now()}
                                    onBlur={(date) => updateDefinition('min_range', definition.min_range, true)}
                                    className={classes.inputOutline}
                                /> :
                                <TextBox placeholder="Min Range"
                                    name="min_range"
                                    id="min_range"
                                    value={definition.min_range ? definition.min_range : ''}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    onBlur={(event) => updateDefinition(event.target.name, event.target.value)}
                                    className={classes.inputOutline} />
                        }
                    </Grid>
                    <Grid item>
                        <Typography variant="body1" className={classes.rangeSeparator}>
                            {'-'}
                        </Typography>
                    </Grid>
                    <Grid item className={classnames(classes.valuesTextboxContainer, classes.sliderInput, classes.propertyComponents)}>
                        {
                            (dataType === "Date") ?
                                <DatePicker
                                    autoOk
                                    variant="inline"
                                    format="MM/DD/YYYY"
                                    maxDateMessage=""
                                    minDateMessage=""
                                    maxDate={moment('12/31/9999').format('MM/DD/YYYY')}
                                    minDate={definition.min_range ? moment(definition.min_range).format('MM/DD/YYYY') : Date.now()}
                                    value={definition.max_range ? moment(definition.max_range).format('MM/DD/YYYY') : Date.now()}
                                    onChange={(date) => onChange('max_range', moment(date).format('MM/DD/YYYY'))}
                                    onBlur={(date) => updateDefinition('max_range', definition.max_range, true)}
                                    className={classes.inputOutline}
                                />
                                :
                                <TextBox placeholder="Max Range"
                                    name="max_range"
                                    id="max_range"
                                    value={definition.max_range ? definition.max_range : ''}
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    onBlur={(event) => updateDefinition(event.target.name, event.target.value)}
                                    className={classes.inputOutline} />
                        }
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={5}>
                <Typography component="h6" variant="h6">
                    Equals
                </Typography>
                <Typography className={classes.lightTxt}>
                    Please Provide the Equals
                </Typography>
                <ChipSelectList
                    type="addChip"
                    displayType="array"
                    lowerCase
                    availableList={[]}
                    placeholder="Update Keywork Equals"
                    items={definition.keyword && definition.keyword.equals ? definition.keyword.equals : []}
                    property="equals"
                    onUpdate={(property, value) => onChange(property, value)}
                    onBlur={(property, value) => updateDefinition(property, value)} />
                    <Typography className={classes.lightTxtInfo}>
                        <b>
                            Note:
                        </b>
                        Entered Special Characters will be removed automatically.
                    </Typography>
            </Grid>
            <Grid item xs={2} />

            <Grid item xs={5}>
                <Typography component="h6" variant="h6">
                    Contains
                </Typography>
                <Typography className={classes.lightTxt}>
                    Please Provide the Contains
                </Typography>
                <ChipSelectList
                    type="addChip"
                    displayType="array"
                    lowerCase
                    availableList={[]}
                    placeholder="Update Keywork Contains"
                    items={definition.keyword && definition.keyword.contains ? definition.keyword.contains : []}
                    property="contains"
                    onUpdate={(property, value) => onChange(property, value)}
                    onBlur={(property, value) => updateDefinition(property, value)} />
                <Typography className={classes.lightTxtInfo}>
                        <b>
                            Note:
                        </b>
                        Entered Special Characters will be removed automatically.
                </Typography>
            </Grid>

            <Grid item xs={4}>
                <Grid container justify="space-between" alignItems="center">
                    <Typography component="h6" variant="h6">
                        Patterns
                    </Typography>
                    <ToolTipComponent title="Add Pattern" arrow>
                        <IconButton onClick={(event) => showAddPatternDialog(event, -1)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18">
                                <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                    <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                        <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                        <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                    </g>
                                </g>
                            </svg>
                        </IconButton>
                    </ToolTipComponent>
                </Grid>
                <Typography className={classes.lightTxt}>
                    {Localization.properties.value.pattern}
                </Typography>
                {
                    visiblePatternDialog &&
                    <PatternInputEditor
                        pattern={selectedPattern}
                        open={visiblePatternDialog}
                        anchorEl={anchorEditorEl}
                        isSemantics
                        onSave={() => addPattern()}
                        onClose={() => hideAddPatternDialog()}
                        onChange={(property, value) => onHandleChange(property, value)} />
                }
                <Grid container className={classnames(classes.patternListContainer)}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {
                            definition.patterns && definition.patterns.map((data, index) =>
                                <ValuesListItem
                                    key={`patternList${index}`}
                                    isPatternList
                                    displayKey={'name'}
                                    index={index}
                                    data={data}
                                    count={data.count}
                                    onDelete={(index) => removePattern(index)}
                                    onUpdate={(event, index) => showAddPatternDialog(event, index)}
                                    isDeletePermission
                                    isEditPermission
                                />
                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={4}>
                <Grid container justify="space-between" alignItems="center">
                    <Typography component="h6" variant="h6">
                        Enumeration
                    </Typography>
                    <ToolTipComponent title="Add Enumeration" arrow>
                        <IconButton onClick={(event) => addEnum()}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18">
                                <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                    <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                        <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                        <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                    </g>
                                </g>
                            </svg>
                        </IconButton>
                    </ToolTipComponent>
                </Grid>
                <Typography className={classes.lightTxt}>
                    {Localization.properties.value.enum}
                </Typography>
                <Grid container className={classnames(classes.patternListContainer)} onMouseLeave={() => updateEnumeration()}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        {
                            definition.enum && definition.enum.values && definition.enum.values.map((data, index) =>
                                <ValuesListItem
                                    key={`enumerationList${index}`}
                                    // style={}
                                    displayKey={'value'}
                                    index={index}
                                    data={data}
                                    onDelete={(index) => removeEnum(index)}
                                    onUpdate={(...props) => updateEnum(...props)}
                                    isDeletePermission
                                    isEditPermission />

                            )
                        }
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography component="h6" variant="h6">
                    Rules
                </Typography>
                <Typography className={classes.lightTxt}>
                    {Localization.properties.rule.rule}
                </Typography>
                <Grid className={classes.marginTop10}>
                    <SemanticGlossaryRules
                        semanticId={glossaryId}
                        ruleList={definition.custom_rules ? definition.custom_rules : []}
                        updateCustomRules={(value) => updateCustomRules(value)}
                        field={glossary.name ? glossary.name : ""}
                        defaultFieldType={getFieldType(dataType ? dataType : "text")}
                        deleteCustomRules={(rules) => deleteUpdateCustomRules(rules)} />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Typography component="h6" variant="h6">
                    Reference files/API
                </Typography>
                <Typography className={classes.lightTxt}>
                    {Localization.properties.curate.reference}
                </Typography>
                <SelectLibrary
                    property="curation_libraries"
                    items={definition.curation_libraries ? definition.curation_libraries : []}
                    onUpdate={(property, value) => onChange(property, value)}
                    onBlur={(property, value) => updateDefinition(property, value)}
                />
            </Grid>
            <Grid item xs={12}>
                <Typography component="h6" variant="h6">
                    Curation Libraries
                </Typography>
                <Typography className={classes.lightTxt}>
                    {Localization.properties.curate.reference}
                </Typography>
                <ChipSelectList
                    dropdown
                    type="selectChip"
                    availableList={enrichment}
                    displayType="array of object"
                    placeholder="Add Reference Files/API"
                    items={definition.reference_files ? definition.reference_files : []}
                    property="reference_files"
                    onUpdate={(property, value) => onChange(property, value)}
                    onBlur={(property, value) => updateDefinition(property, value)} />
                {/* <ChipSelectList
                    dropdown
                    type="selectChip"
                    availableList={libraries}
                    displayType="array of object"
                    placeholder="Add Curation Library"
                    items={definition.curation_libraries ? definition.curation_libraries : []}
                    property="curation_libraries"
                    onUpdate={(property, value) => onChange(property, value)}
                    onBlur={(property, value) => updateDefinition(property, value)} /> */}
            </Grid>
            {isLoading && <Loader />}
        </Grid >
    );

};

SemanticGlossaryDefinition.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    dataType: PropTypes.string
};

export default withStyles((theme) => ({
    ...SemanticGlossaryStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SemanticGlossaryDefinition);