import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Avatar, IconButton, MenuItem, Popover, Divider, TextField, Box } from '@material-ui/core';
import classnames from 'classnames';

import { getRandomColor, getInitials, returnImage } from '../../helpers/appHelpers';
import { appConstants } from '../../constants/appConstants';
import ConversationStyles from '../Conversation/ConversationStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import moment from 'moment-timezone';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import CommentStyles from './CommentStyles.jsx';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import Rating from '../Rating/Rating.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import Pingrey from '../../assets/images/pin_grey.svg';
import PinActive from '../../assets/images/pin_pink.svg';


const CommentsList = (props) => {
    const { classes, comment, deleteComment, editComment, userId, active, isSuperuser } = props;
    const presentDate = moment().format('MMM DD YYYY');
    const messageDate = moment(comment.created_date).format('MMM DD YYYY');
    const [open, enablePopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isEdited, setEdited] = useState(false);
    const [commentItem, setCommentItem] = useState({});
    const [linkedComment, setLinkedComment] = useState('');

    const openPopOver = (event) => {
        setAnchorEl(event.target);
        enablePopover(true);
    };

    const createLinkedComment = useCallback((comment_text) => {
        const urlRegex = /(https?:\/\/[^\s]+)/gi;
        const new_comment = comment_text.replace(urlRegex, ((url) => {
            return '<a target="_blank" href="' + url + '">' + url + '</a>';
          }));
        setLinkedComment(new_comment);
    }, []);

    useEffect(() => {
        createLinkedComment(comment.comment);
    }, [comment.comment, createLinkedComment]);


    const onChangeComment = (property, value) => {
        if (property === 'isPinned') {
            editComment({ ...comment, isPinned: value }, comment.id);
        } else {
            commentItem[property] = value;
            setCommentItem({ ...commentItem });
        }
    };

    const onClose = () => {
        enablePopover(false);
        setAnchorEl(null);
        setCommentItem({});
    };

    const enableEdit = () => {
        setEdited(true);
        enablePopover(false);
        setCommentItem({ ...comment });
    };

    const edit = () => {
        const commentValue = commentItem.comment ? commentItem.comment.replace(/(?:\r\n|\r|\n|\s)/g, '') : "";
        if (commentItem && commentItem.comment !== '' && commentValue !== '') {
            editComment(commentItem, comment.id);
        }
        setEdited(false);
        setCommentItem({});
    };

    return (
        <Grid container className={classnames(classes.marginBottom10, "optionsContainer")} direction="column">
            <Grid item className={classnames(classes.alignCenter, classes.flex)}>
                <Avatar
                    className={classes.marginRight15}
                    style={
                        {
                            backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, comment.avatar)})`,
                            backgroundColor: getRandomColor(comment.first_name !== "" ? `${comment.first_name} ${comment.last_name}` : comment.email)
                        }
                    }>
                    {
                        !comment.avatar &&
                        <span>
                            {getInitials(comment.first_name !== "" ? `${comment.first_name} ${comment.last_name}` : comment.email)}
                        </span>
                    }
                </Avatar>
                <ToolTipComponent title={comment.first_name !== "" ? `${comment.first_name} ${comment.last_name}` : comment.email}>
                    <Typography noWrap className={classes.commentName}>
                        {comment.first_name !== "" ? `${comment.first_name} ${comment.last_name}` : comment.email}
                    </Typography>
                </ToolTipComponent>
                <Typography className={classes.split}>
                    {','}
                </Typography>
                <Typography className={classes.timeTxt}>
                    {(messageDate === presentDate) ? moment(comment.created_date).calendar() : moment(comment.created_date).format('MMM DD, YYYY HH:mm')}
                </Typography>
                <Grid className={classes.ratingContainer}>
                    <Rating
                        name="rating"
                        value={isEdited ? commentItem.rating : comment.rating}
                        precision={0.5}
                        size="large"
                        readOnly={!isEdited}
                        onChange={(event, value) => onChangeComment("rating", value)} />
                </Grid>
            </Grid>
            <Grid item className={classnames(classes.commentBox, isEdited && classes.commentEdit, !isEdited && comment && !comment.comment ? classes.emptyComment : '')}>
                {
                    isEdited ?
                        <Grid className={classes.commentEditBox}>
                            <TextField
                                value={commentItem.comment ? commentItem.comment : ''}
                                placeholder={"Comment"}
                                onChange={(event) => onChangeComment("comment", event.target.value)}
                                fullWidth
                            />
                            <Grid align="right" className={classes.editActions}>
                                <IconButton className={classnames(classes.nopadding, classes.marginRight5)} onClick={() => { setEdited(false); setCommentItem({}); }}>
                                    <CloseIcon />
                                </IconButton>
                                <IconButton className={classnames(classes.nopadding)} onClick={() => edit()}>
                                    <CheckIcon />
                                </IconButton>
                            </Grid>
                        </Grid> :
                        <Typography className={classes.commentText} dangerouslySetInnerHTML={{ __html: (linkedComment && linkedComment) }} />
                }

            </Grid>
            <Box className="options-btn">
                {
                    // (isSuperuser || comment.created_by_id === userId) && !isEdited &&
                    (isSuperuser || (comment.created_by_id === userId && (!comment.modified_by_id || comment.modified_by_id === userId))) && !isEdited &&
                    <ToolTipComponent title="Options" arrow>
                        <IconButton onClick={(event) => openPopOver(event)}>
                            <MoreHorizIcon />
                        </IconButton>
                    </ToolTipComponent>
                }
                <ToolTipComponent title="Pin" arrow>
                    <IconButton onClick={() => onChangeComment("isPinned", !comment.isPinned)} className={active ? "pinactive" : ""}>
                        <img src={active ? PinActive : Pingrey} alt="" />
                    </IconButton>
                </ToolTipComponent>
            </Box>

            <Popover
                open={open}
                onClose={onClose}
                anchorEl={anchorEl}
                anchorOrigin={
                    {
                        vertical: 'center',
                        horizontal: 'right'
                    }
                }
                transformOrigin={
                    {
                        vertical: 'bottom',
                        horizontal: 'left'
                    }
                }>
                <MenuItem className={classes.logout} onClick={() => enableEdit()}>
                    <Typography className={classes.logoutTxt}>
                        {'Edit'}
                    </Typography>
                </MenuItem>
                <Divider />
                <MenuItem className={classes.logout} onClick={() => { deleteComment(comment.id); enablePopover(false); }}>
                    <Typography className={classes.logoutTxt}>
                        {'Delete'}
                    </Typography>
                </MenuItem>
            </Popover>
        </Grid>
    );

};

CommentsList.propTypes = {
    classes: PropTypes.object,
    comment: PropTypes.object,
    deleteComment: PropTypes.func,
    editComment: PropTypes.func,
    userId: PropTypes.number,
    active: PropTypes.bool,
    isSuperuser: PropTypes.bool
};

export default withStyles((theme) => ({
    ...ConversationStyles(theme),
    ...CommentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(CommentsList);