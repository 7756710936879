import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import BarAndLineChart from '../Charts/BarAndLineChart.jsx';
import { getOrganizationTimeline } from '../../actions/scheduleActions';

const DashboardTimeline = (props) => {
    const { config } = props;
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(true);
    const [timelineData, setTimelineData] = useState({});

    const getTimeline = useCallback(() => {
        dispatch(getOrganizationTimeline()).then((response) => {
            const datasets = [];
            const datasources = [];
            const attributes = [];
            const domains = [];
            if (response) {
                for (const data of response) {
                    if (data.timeline_analysis) {
                        datasources.push({ ...data.timeline_analysis.datasource });
                        datasets.push({ ...data.timeline_analysis.dataset });
                        attributes.push({ ...data.timeline_analysis.attribute });
                        domains.push({ ...data.timeline_analysis.domain });
                    }
                }
                setLoading(false);
            }
            setTimelineData({ dataset: datasets, datasource: datasources, attribute: attributes, domain: domains });
        });
    }, [dispatch]);

    useEffect(() => {
        getTimeline();
    }, [getTimeline]);

    return (
        <BarAndLineChart analysisData={timelineData} isLoading={isLoading} config={config} />
    );
};

DashboardTimeline.propTypes = {
    config: PropTypes.object
};

export default withStyles((theme) => ({
    ...Styles(theme)
}))(DashboardTimeline);