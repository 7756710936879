import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Tabs, Tab } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import StatisticsDetails from './StatisticsDetails.jsx';
import Styles from '../../layouts/Styles.jsx';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import ChartStyles from './ChartStyles.jsx';

const Statistics = (props) => {
    const { classes, getStatistics, statisticalRules } = props;
    const [activeTabIndex, setTabIndex] = useState(0);
    let tabs = ["descriptive", "quantile"];
    tabs = statisticalRules.filter((rule) => tabs.filter((tab) => tab === rule));
    tabs.map((rule, index) => tabs[index] = rule[0].toUpperCase() + rule.slice(1) + " Statistics");
    return (
        <ChartContainer
            height={"100%"}
            chartData={{}} >
            <Grid container className={classes.statisticsContainer}>
                <Tabs
                    value={activeTabIndex}
                    indicatorColor="secondary"
                    variant="fullWidth"
                    textColor="secondary"
                    onChange={(event, index) => setTabIndex(index)}
                >
                    {
                        tabs.map((tab, index) => (
                            <Tab className={classes.statisticsTabTitle} key={`header-${tab}-${index}`} label={tab} />
                        ))
                    }
                </Tabs>
                <SwipeableViews index={activeTabIndex}>
                    {
                        tabs.map((tab, index) => (
                            <StatisticsDetails classes={classes} key={`${tab}-${index}`} statistics={getStatistics(tab)} />
                        ))
                    }
                </SwipeableViews>
            </Grid>
        </ChartContainer >
    );
};

Statistics.propTypes = {
    classes: PropTypes.object,
    getStatistics: PropTypes.func,
    statisticalRules: PropTypes.array
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Statistics);