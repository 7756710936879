import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { Grid, withStyles, Button, Typography } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Styles from '../../layouts/Styles.jsx';
import CreateDataPreparationStyles from './CreateDataPreparationStyles.jsx';
import TextBox from '../../components/TextBox/TextBox.jsx';
import { createDataPreparation } from '../../actions/dataPreparationActions';


const CreateDataPreparation = (props) => {
    const { classes, history } = props;
    const [dataPreparation, setDataPreparation] = useState({ name: '', description: '' });
    const dispatch = useDispatch();

    const onChange = useCallback((property, value) => {
        dataPreparation[property] = value;
        setDataPreparation({ ...dataPreparation });
    }, [dataPreparation]);

    const createData = useCallback(() => {
        dispatch(createDataPreparation(dataPreparation)).then((response) => {
            if (response) {
                history.push({ pathname: `/datapreparation/${response.id}`, state: { source: response } });
            }
        });
    }, [dispatch, history, dataPreparation]);

    return (
        <ValidatorForm onSubmit={() => createData()} className={classes.container}>
            <Grid container direction="row" justify="center" alignItems="center">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.contentContainer}>

                    <Grid container>
                        <Grid item sm={12} className={classes.Headercontainer}>
                            <Grid container alignItems="center" className={classnames(classes.marginTop30, classes.height32)}>
                                <Grid item sm={6}>
                                    <Typography variant="h1">
                                        {'Create Data Preparation'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={12} className={classes.subTitleContainer}>
                            <Typography variant="subtitle1">
                                {'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem'}
                            </Typography>
                            {/* <Typography variant="subtitle1" >
                                {'it usable, accurate, and trustworthy. Select a Datasource Type below.'}
                            </Typography> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.formContainer}>
                    <Grid container justify="space-between">
                        <Grid item xs={5}>
                            <TextBox
                                name="name"
                                label="Name"
                                value={dataPreparation.name ? dataPreparation.name : ''}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                validators={['required']}
                                errorMessages={['Name is required']}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.descriptionContainer}>
                            <TextBox
                                rows={4}
                                name="description"
                                label="Description"
                                value={dataPreparation.description ? dataPreparation.description : ''}
                                onChange={(event) => onChange(event.target.name, event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid item className={classes.btnCancelItem}>
                        <Button type="submit"
                            color="primary"
                            variant="contained"
                            className={classnames(classes.actionButtons, classes.actionSubmit)}>
                            {'Submit'}
                        </Button>
                        <Button className={classnames(classes.cancelButton, classes.actionButtons)}
                            onClick={() => history.goBack()}>
                            {'Cancel'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </ValidatorForm>
    );
};

CreateDataPreparation.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object
};

export default withStyles((theme) => ({
    ...CreateDataPreparationStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(CreateDataPreparation);