const TileViewStyles = (theme) => ({
    root: {
        flexGrow: '1 auto',
        height: '100%'
    },
    sliderView: {
        '& .owl-stage-outer': {
            marginLeft: '-23px !important'
        }
    },
    propertySubTitle: {
        color: '#646666',
        fontSize: '13px',
        marginLeft: '10px'
    },
    datasetDetailContent: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        justifyContent: 'flex-end',
        alignItems: 'flex-start'
    },
    propertySliderItem: {
        padding: 16,
        backgroundColor: theme.palette.common.white,
        border: `1px solid ${theme.palette.grey.extraLight}`,
        boxShadow: '0px 0px 15px #0000000F',
        position: 'relative'
        // width: 'calc(100vh + 49%) !important'
    },

    propertyTabContainer: {
        // height: 'calc(100vh - 385px) !important',
        border: '0 !important',
        overflowY: "hidden !important"
    },
    propertyRow: {
        marginBottom: 7,
        '&:last-child': {
            marginBottom: 0
        }
    },
    propertyComponents: {
        marginTop: 5,
        color: theme.palette.grey.default,
        '& .MuiGrid-item': {
            marginRight: '10%',
            '&:last-child': {
                marginRight: 0
            }
        }
    },
    AttributeInputDesc: {
        height: 30,
        '& .MuiInput-underline:before': {
            height: '30px !important'
        },
        '& input': {
            height: '30px !important'
        }
    },
    propertyRangeSlider: {
        '& .MuiSlider-thumb': {
            marginLeft: '2px'
        }
    },
    businessNameTextBox: {
        width: '65% !important',
        '& input': {
            width: '100% !important'
        }
    },
    sliderValue: {
        marginLeft: 16,
        marginTop: 4,
        color: `${theme.palette.grey.default} !important`
    },
    reportType: {
        marginRight: '10%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        cursor: 'pointer',
        marginTop: 6,
        color: theme.palette.grey.default,
        fontWeight: 400,
        '&:last-child': {
            marginRight: 0
        },
        '& img': {
            marginRight: 8
        }
    },
    selectedReportType: {
        color: theme.palette.grey.default,
        fontWeight: 600,
        '& svg': {
            fill: `${theme.palette.primary.main} !important`
        }
    },
    keyLogo: {
        height: 15,
        width: 'auto !important',
        display: 'inline !important',
        marginRight: 12
    },
    valuesTitleContainer: {
        height: 32,
        '& .MuiIconButton-root': {
            padding: 4,
            '& svg': {
                height: 14,
                width: 14
            }
        }
    },
    propertyDescritionEllipsis: {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 3
    },
    curateDescritionEllipsis: {
        display: '-webkit-box',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 2
    },
    rangeSeparator: {
        marginTop: 16,
        marginLeft: 20,
        marginRight: 20
    },
    patternListContainer: {
        height: 'calc(100vh - 475px)',
        marginTop: 16
    },
    valuesListItem: {
        padding: '10px 0',
        paddingRight: '8px',
        justifyContent: 'space-between',
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& .MuiIconButton-root': {
            padding: 4,
            height: 24,
            width: 24
        }
    },
    countLable: {
        width: 'max-content',
        // minWidth: 49,
        textAlign: 'center',
        padding: '5px 10px',
        marginLeft: 15,
        marginRight: 15,
        color: `${theme.palette.common.white} !important`,
        maxWidth: 'calc(100% - 24px)',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    },
    queryBuilderContainer: {
        height: 'calc(100vh - 420px)',
        border: `1px solid ${theme.palette.grey.extraLight}`
    },
    emptyRuleContainer: {
        cursor: 'pointer',
        '&:hover': {
            background: theme.palette.grey.exteremeLight
        }
    },
    ruleListItem: {
        color: `${theme.palette.grey.darkTxt} !important`,
        padding: 12,
        letterSpacing: 0.26,
        borderBottom: `1px solid ${theme.palette.grey.extraLight}`,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
        },
        '&.active': {
            backgroundColor: theme.palette.grey.extraLight
        }
    },
    rulesListContainer: {
        marginLeft: -1,
        borderRight: `1px solid ${theme.palette.grey.extraLight}`,
        overflow: 'auto',
        '& p.active': {
            marginTop: -1,
            marginLeft: -1
        }
    },
    AttriTypeIcon: {
        marginRight: '5px !important'
    },
    ruleContainer: {
        padding: '10px 20px',
        // backgroundColor: theme.palette.background.container,
        "& .MuiInput-underline": {
            "&:hover::before": {
                borderBottom: `0px solid ${theme.palette.grey.default} !important`
            },
            "&::before": {
                borderBottom: `0px solid ${theme.palette.grey.default} !important`,
                marginRight: 5,
                transition: '0.3s'
            },
            "&::after": {
                borderBottom: `0px solid ${theme.palette.grey.default}`
            }
        },
        "& input": {
            border: `1px solid ${theme.palette.grey.default}`,
            height: 26,
            fontSize: 13,
            borderColor: theme.palette.grey.extraLight,
            borderRadius: 0,
            padding: '1px 7px',
            marginRight: 14
        }
    },
    CurateruleContainer: {
        padding: '10px 20px',
        '& .MuiPaper-elevation8': {
            maxHeight: '150px !important'
        },
        // backgroundColor: theme.palette.background.container,
        "& .MuiInput-underline": {
            "&:hover::before": {
                border: `1px solid ${theme.palette.grey.dark} !important`
            },
            "&::before": {
                border: `1px solid ${theme.palette.grey.light} !important`
                /*
                 * marginRight: 5,
                 * transition: '0.3s'
                 */
            }

        },
        "& .MuiInput-underline.Mui-focused": {
            "&::after": {
                border: `1px solid ${theme.palette.primary.dark} !important`,
                height: 32,
                transition: "auto !important"
            },
            "&::before": {
                border: `0px solid ${theme.palette.grey.light} !important`
                /*
                 * marginRight: 5,
                 * transition: '0.3s'
                 */
            },
            "&:hover::before": {
                border: `0px solid ${theme.palette.grey.dark} !important`
            }
        },
        "& input": {
            // border: `1px solid ${theme.palette.grey.default}`,
            height: 26,
            fontSize: 13,
            // borderColor: theme.palette.grey.extraLight,
            borderRadius: 0,
            padding: '1px 7px'
            // marginRight: 14
        }
    },
    basicruleContainer: {
        "& input": {
            height: 26,
            fontSize: 13,
            padding: '1px 7px',
            marginRight: 14
        }
    },
    width100: {
        '& .root': {
            width: '100% !important'
        }
    },
    noMaxHeight: {
        maxHeight: 'auto !important'
    },
    queryContainer: {
        overflow: 'auto',
        marginTop: 8,
        border: '1px solid #CFE0E7',
        padding: '10px',
        position: 'relative',
        borderRadius: '3px',
        marginBottom: '5px',
        backgroundColor: 'rgba(237, 242, 244, 0.6)',
        "& input": {
            marginRight: '0 !important'
        },
        '& .MuiTabs-root': {
            borderBottom: `1px solid ${theme.palette.border.default}`
        },
        "& .MuiTabs-indicator": {
            width: '160px !important'
        }
    },
    actionIconButton: {
        padding: 4
    },
    ruleTitle: {
        fontWeight: 500,
        // marginRight: 8,
        '& input': {
            fontWeight: 500
        }
    },
    ruleDescription: {
        fontSize: 13,
        '& input': {
            fontSize: 13
        }
    },
    advancedRulesContainer: {
        height: 'auto',
        maxHeight: 'calc(100vh - 545px)',
        marginTop: 9,
        overflowY: 'auto',
        overflowX: 'hidden',
        '&>div': {
            height: '100%',
            width: '100%'
        }
    },
    advancedRulesListItem: {
        padding: '7px 0',
        paddingLeft: '5px',
        '&>div': {
            padding: '0 !important'
        },
        '& .MuiGrid-item': {
            padding: '10px 16px'
        },
        '& .title': {
            width: '35%'
        },
        '& .description': {
            width: '20%',
            '&>p': {
                width: '90%'
            }
        },
        '& .threshold': {
            width: '40%'
        },
        '& .actions': {
            width: '5%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            '&.MuiIconButton-root': {
                padding: 4
            }
        },
        '&:hover': {
            background: theme.palette.grey.exteremeLight
        }
    },
    listViewAlignment: {
        position: 'relative',
        top: '-45px'
    },
    fieldAutoComplete: {
        position: 'relative',
        marginLeft: 10
    },
    attributeCurateClass: {
        border: '1px solid #646666',
        height: '26px',
        padding: '1px 7px',
        fontSize: '13px',
        borderColor: '#e1e5e6',
        marginRight: '14px',
        borderRadius: '0'
    },
    basicRulesListContainer: {
        height: 'calc(100vh - 440px)',
        marginTop: 10
    },
    basicRuleContainer: {
        padding: 0,
        height: '100%',
        paddingRight: 5,
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    basicRuleListItem: {
        padding: '7px 0',
        fontSize: 13,
        fontWeight: 400,
        '& .MuiFormControl-root': {
            width: '100%'
        },
        '& .MuiIconButton-root': {
            padding: 4,
            height: 24,
            width: 24
        },
        '&:hover': {
            background: theme.palette.grey.exteremeLight
        }
    },
    autoCompleteInputContainer: {
        '& > div': {
            width: "100%",
            marginRight: 5,
            fontSize: 13,
            fontWeight: 400
        }
    },
    attributeRuleContainer: {
        padding: '10px 20px',
        // backgroundColor: theme.palette.background.container,
        "& .MuiInput-underline": {
            "&::before": {
                marginRight: 5
            },
            "&::after": {
                borderBottom: `0px solid ${theme.palette.grey.default}`
            }
        },
        "& input": {
            height: 26,
            fontSize: 13,
            borderColor: theme.palette.grey.extraLight,
            borderRadius: 0,
            padding: '1px 7px',
            marginRight: 14
        },
        '& input[type="checkbox"]': {
            height: 18
        }
    },
    borderNoneAutocomplete: {
        paddingLeft: 5,
        '& .MuiInput-underline:before': {
            borderBottom: "none !important"
        }
    },
    attibuteHeaderDetails: {
        margin: '-12px 0 0px -12px',
        padding: '6px 12px'
    },
    fontSize13: {
        '& input': {
            fontSize: 13
        }
    },
    learningContainer: {
        width: '100%',
        maxHeight: '300px',
        overflowX: 'hidden',
        overflowY: 'auto',
        paddingTop: 10
    },
    learningListContainer: {
        maxHeight: 'calc(100vh - 460px)',
        overflowX: 'hidden',
        overflowY: 'auto',
        height: 'auto'
    },
    inputText: {
        maxWidth: 100,
        fontSize: 13
    },
    inputSeparator: {
        fontSize: 13,
        margin: '0px 5px'
    },
    curateText: {
        fontSize: 13,
        '& input': {
            fontSize: 13
        }
    },
    learningDeleteButton: {
        marginLeft: 20,
        padding: 4,
        width: 24,
        height: 24
    },
    dqSearchBoxAttributeRule: {
        transitionDuration: '500ms',
        transition: 'width .55s ease',
        animation: 'slide-left186 0.4s ease',
        width: '186px'
    },
    attributeHeaderTitle: {
        maxWidth: '210px',
        overflow: 'hidden',
        textOverflow: "ellipsis"
    },
    inActiveTile: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: theme.palette.transparent,
        cursor: "pointer"
    },
    sliderHelpText: {
        width: '20%',
        float: 'left',
        textAlign: 'center',
        position: 'relative',
        right: '15px'
    },
    sliderRightHelpText: {
        width: '20%',
        float: 'left',
        textAlign: 'center',
        position: 'relative',
        left: '15px'
    },
    sliderContent: {
        width: '60%',
        float: 'left'
    },
    basicRuleItem: {
        '&:hover': {
            paddingLeft: '5px',
            transition: 'padding-left 0.4s'
        }
    },
    masterTextboxContainer: {
        '& .MuiInput-underline:before': {
            height: '30px !important'
        },
        '& .MuiAutocomplete-inputRoot': {
            width: '100%',
            padding: 0,
            '& input': {
                padding: '0 6px !important',
                height: '30px !important',
                fontFamily: theme.palette.textFont,
                minWidth: 120
            }
        }
    },
    fullWidth: {
        width: "100%"
    },
    fullWidth2: {
        width: "100%",
        '& > .MuiAutocomplete-root > .MuiFormControl-root': {
            height: 'calc(100vh - 450px)',
            overflowY: 'auto'
        }
    },
    glossarySelect: {
        '& .MuiSelect-select.MuiSelect-select': {
            display: 'flex'
        }
    }
});

export default TileViewStyles;