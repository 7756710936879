import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Button, IconButton } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { getDatasetAttributes, updateConfiguration } from '../../actions/dataPreparationActions';
import DataPreparationjoinBuilder from './DataPreparationjoinBuilder.jsx';
import CloseIcon from '@material-ui/icons/Close';
import DataPreparationjoinBuilderStyles from './DataPreparationjoinBuilderStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import { useDispatch } from 'react-redux';
import { removeExtension } from '../../helpers/appHelpers';
import Loader from '../Loaders/Loader.jsx';


const DataPreparationjoinBuilderModal = (props) => {
    const { classes, onClose, sourceDataset, setJoinPerform, dataPreparation, updatePreparationProperty } = props;
    const defaultJoinParams = {
        "current_dataset": "",
        "current_attribute": "",
        "condition": "",
        "join_dataset": sourceDataset && sourceDataset.name ? sourceDataset.name : "",
        "join_attribute": ""
    };
    const [joins, setJoins] = useState({
        rules: [{ ...defaultJoinParams, "join_dataset": sourceDataset && sourceDataset.name ? sourceDataset.name : "" }], query: ""
    });
    const [datasets, setDatasets] = useState([]);
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);

    const getDatasetList = useCallback(() => {
        dispatch(getDatasetAttributes(dataPreparation.id)).then((response) => {
            if (response) {
                for (const dataset of response) {
                    if (dataset.attributes && dataset.attributes.length > 0) {
                        continue;
                    }
                    const config = JSON.parse(dataset.configuration);
                    dataset.attributes = config && 'attribute_datatypes' in config ? config.attribute_datatypes : [];
                }
                setDatasets([...response]);
            }
        });
    }, [dataPreparation.id, dispatch]);

    const getJoinQuery = useCallback(() => {
        if (dataPreparation && dataPreparation.join_configuration && Object.keys(dataPreparation.join_configuration).length > 0) {
            setJoins({ ...dataPreparation.join_configuration });
        }
    }, [dataPreparation]);

    useEffect(() => {
        getDatasetList();
        getJoinQuery();
    }, [getDatasetList, getJoinQuery]);


    const updateJoinRules = useCallback((joinRules) => {
        const requestParams = {
            "join_configuration": joinRules,
            "data_preparation_id": dataPreparation.data_preparation_id
        };
        setLoading(true);
        dispatch(updateConfiguration(dataPreparation.id, requestParams)).then((response) => {
            setLoading(false);
            onClose();
        });
        updatePreparationProperty(requestParams, dataPreparation.id);
    }, [dataPreparation.data_preparation_id, dataPreparation.id, dispatch, onClose, updatePreparationProperty]);

    const getDatasetInputConfiguration = useCallback((rules) => {
        const joinRuleList = [...new Set(rules.map((rule) => rule.current_dataset))];
        joinRuleList.push(sourceDataset.name);
        const inputConfiguration = joinRuleList.map((rule) => {
            const datasetConfig = datasets.find((dataset) => dataset.name === rule);
            return { id: datasetConfig.id, name: datasetConfig.name, "input_config": datasetConfig.input_config };
        });
        return inputConfiguration;
    }, [datasets, sourceDataset]);

    const generateQuery = useCallback((rules, dataset) => {
        const connectedDatasets = [];
        let query = `select * from ${dataset && dataset.name ? removeExtension(dataset.name) : ""}`;
        for (const join of rules) {
            if (!connectedDatasets.includes(join.current_dataset)) {
                connectedDatasets.push(join.current_dataset);
                query = `${query} join ${removeExtension(join.current_dataset)} on ${removeExtension(join.current_dataset)}.${join.current_attribute} ${join.condition.value} ${removeExtension(join.join_dataset)}.${join.join_attribute}`;
            } else {
                query = `${query} and ${removeExtension(join.current_dataset)}.${join.current_attribute} ${join.condition.value} ${removeExtension(join.join_dataset)}.${join.join_attribute}`;
            }
        }
        return query;
    }, []);

    const joinTable = useCallback(() => {
        const rules = joins.rules ? joins.rules.sort((a, b) => {
            if (a.current_dataset < b.current_dataset) {
                return 1;
            }
            return -1;
        }) : [];

        joins.query = generateQuery(rules, sourceDataset);
        joins.datasets = getDatasetInputConfiguration(rules);
        setJoins({ ...joins });
        setJoinPerform(true);
        updateJoinRules({ ...joins });
    }, [joins, generateQuery, sourceDataset, getDatasetInputConfiguration, setJoinPerform, updateJoinRules]);

    const addMoreJoin = () => {
        joins.rules.push({ ...defaultJoinParams });
        setJoins({ ...joins });
    };

    const deleteJoin = (index) => {
        joins.rules.splice(index, 1);
        if (joins.rules.length === 0) {
            joins.rules.push({ ...defaultJoinParams });
        }
        setJoins({ ...joins });
    };

    const onFieldChanged = useCallback((value, property, index) => {
        if (property === 'condition') {
            joins.rules[index][property] = value;
        } else {
            joins.rules[index][property] = value ? value.name : "";
        }
        setJoins({ ...joins });
    }, [joins]);


    return (
        <Grid className={classes.modelContainer}>
            <ValidatorForm onSubmit={() => joinTable()} >
                <Grid container alignItems="center" justify="space-between">
                    <Grid item>
                        <Typography component="h5" variant="h5">
                            {'Join Conditions'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={() => onClose()}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid className={classes.modelSection}>
                    {
                        joins && joins.rules.map((dataset, index) =>
                            <DataPreparationjoinBuilder
                                onFieldChanged={(value, property, joinIndex) => onFieldChanged(value, property, joinIndex)}
                                key={index}
                                datasets={datasets ? datasets : []}
                                selectedJoin={dataset}
                                index={index}
                                deleteJoin={(joinIndex) => deleteJoin(joinIndex)} />
                        )
                    }
                </Grid>
                <Grid>
                    <Button variant="text" color="primary" onClick={() => addMoreJoin()}>
                        + Add More
                    </Button>
                </Grid>
                <Grid container direction="row" justify="flex-end" style={{ marginTop: 40 }}>
                    <Grid item className={classes.btnContainer}>
                        <Button type="submit"
                            variant="contained"
                            color="primary">
                            {isLoading && <Loader />}
                            {'Join'}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button className={classes.cancelButton}
                            onClick={() => onClose()}
                            style={{ marginLeft: 20 }}>
                            {'Cancel'}
                        </Button>
                    </Grid>
                </Grid>
            </ValidatorForm>
        </Grid>
    );
};

DataPreparationjoinBuilderModal.propTypes = {
    classes: PropTypes.object,
    onClose: PropTypes.func,
    sourceDataset: PropTypes.object,
    setJoinPerform: PropTypes.func,
    dataPreparation: PropTypes.object,
    updatePreparationProperty: PropTypes.func
};

export default withStyles((theme) => ({
    ...DataPreparationjoinBuilderStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DataPreparationjoinBuilderModal);