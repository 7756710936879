import React from 'react';
import PropTypes from 'prop-types';
import { LinearProgress, withStyles, Grid, makeStyles } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';
import { getValue } from '../../helpers/appHelpers';

const customStyles = makeStyles({
    customColor: (props) => ({
        color: props.color,
        '& .MuiLinearProgress-bar1Determinate': {
            backgroundColor: props.color
        }
    })
});

const Linear = (props) => {
    const { classes, value, showPercentage, dqScoreThreshold } = props;
    const getColor = (value) => {
        value = value ? parseFloat(value) : 0;
        if (dqScoreThreshold?.scores?.length > 0) {
            let chartColor = null;
            dqScoreThreshold?.scores.forEach((score) => {
                if (Math.floor(value) >= score.min && Math.floor(value) <= score.max) {
                    chartColor = score.color;
                }

            });
            return chartColor;
        }
        else if (value <= 33) { return classes.color25; }
        else if (value > 33 && value <= 66) { return classes.color50; }
        return classes.color100;
    };

    const { customColor } = customStyles({ color: getColor(value) });
    /*
     * const getValue = (value) => {
     *     let progressText = value ? value : 0;
     *     if (Math.round(progressText) !== progressText) {
     *         progressText = parseFloat(progressText).toFixed(1);
     *     }
     *     return progressText;
     * };
     */

    return (
        <Grid className={`${classes.linearContainer} linearContainer`}>
            {
                value !== 'N/A' &&
                <LinearProgress
                    className={customColor}
                    variant="determinate"
                    value={parseFloat(value)}
                />
            }
            {
                showPercentage && value === 'N/A' &&
                <div className={classes.linearNAValue}>
                    {'N/A'}
                </div>
            }
            {
                showPercentage && value !== 'N/A' &&
                <div className={classes.linearValue}>
                    {(parseFloat(getValue(value)) !== 100) ? parseFloat(getValue(value)).toFixed(1) : parseFloat(getValue(value))}
                    {'%'}
                </div>
            }
        </Grid>
    );
};

Linear.propTypes = {
    classes: PropTypes.object,
    value: PropTypes.number,
    showPercentage: PropTypes.bool,
    dqScoreThreshold: PropTypes.object
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}))(Linear);