import React, { useEffect } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../../layouts/Styles.jsx';
import ChartStyles from '../ChartStyles.jsx';
import { appConstants } from '../../../constants/appConstants.js';

const ScatterMarginal = (props) => {

    const { classes, chartData, theme, attribute, onFilterChange, discardEdit, attributeChange, edited } = props;

    useEffect(() => {
        renderChart();
    });

    const renderChart = () => {
        if (chartData && Object.keys(chartData).length > 0 && (attributeChange || edited)) {
            if (edited) {
                discardEdit();
            }
            if (chartData && !(chartData.valid_data instanceof Array)) {
                chartData.valid_data = [];
            }
            if (chartData && !(chartData.outliers instanceof Array)) {
                chartData.outliers = [];
            }
            const selectionData = [...chartData.valid_data, ...chartData.outliers];
            const scatterPlotData = selectionData.map((data, index) => {
                const scatterData = {
                    x: index,
                    y: parseFloat(data[attribute.toLowerCase()] ? data[attribute.toLowerCase()] : data[attribute]),
                    isValid: !data.outlier,
                    ...data
                };
                return scatterData;
            });
            const margin = {
                top: 80,
                right: 80,
                bottom: 20,
                left: 20
            },
                height = 460 - margin.top - margin.bottom;
            let width = 960 - margin.left - margin.right;

            // append the svg object to the body of the page
            d3.select(".scatter-marginal-chart > svg").remove();
            const svg = d3.select(".scatter-marginal-chart")
                .append("svg")
                .attr("width", "65%")
                .style("margin", "0px auto")
                .attr("height", height + margin.top + margin.bottom + margin.left)
                .append("g")
                .attr("width", (_, i, nodes) => {
                    width = nodes[i].parentNode.clientWidth - margin.left - margin.top - margin.right - margin.bottom;
                    return width;
                })
                .attr("height", height + margin.bottom)
                .attr("transform",
                    "translate(" + (margin.left + 40) + "," + margin.top + ")");


            const minX = d3.min(scatterPlotData, (d) => { return d.x; });
            const maxX = d3.max(scatterPlotData, (d) => { return d.x; });
            const minY = d3.min(scatterPlotData, (d) => { return d.y; });
            const maxY = d3.max(scatterPlotData, (d) => { return d.y; });
            const x = d3.scaleLinear().range([0, width]).nice(),
                y = d3.scaleLinear().range([height, 0]).nice();

            const xScale = d3.axisBottom(x).tickSize(-height).ticks(6);
            const yScale = d3.axisLeft(y).tickSize(-width).ticks(6);

            x.domain([minX, maxX]).nice();
            y.domain([minY, maxY]).nice();

            svg.append("g")
                .attr("transform", "translate(0," + height + ")")
                .attr("color", theme.palette.chartColors.axis)
                .attr('class', 'axis-x')
                .transition()
                .duration(2000)
                .call(xScale);

            // add the y Axis
            svg.append("g")
                .attr("color", theme.palette.chartColors.axis)
                .attr('class', 'axis-y')
                .transition()
                .duration(2000)
                .call(yScale);

            // Customization
            svg.selectAll('.tick line')
                .attr('stroke', '#E1E5E6');

            d3.select(".scatter-marginal-chart > .tooltip").remove();
            const div = d3.select(".scatter-marginal-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0);

            const brush = d3.brush().extent([[0, 0], [width, height]]).on("end", zoomEnd);
            let idleTimeout = null;
            const idleDelay = 350;

            svg.append("defs").append("clipPath")
                .attr("id", "clip")
                .append("rect")
                .attr("width", width)
                .attr("height", height)
                .attr("x", 0)
                .attr("y", 0);

            const scatter = svg.append("g")
                .attr("id", "scatterplot")
                .attr("clip-path", "url(#clip)");

            scatter.append("g")
                .attr("class", "brush")
                .call(brush);

            function zoomEnd() {
                const s = d3.event.selection;
                if (!s) {
                    if (!idleTimeout) { return idleTimeout = setTimeout(idled, idleDelay); }
                    const scaleMinX = d3.min(scatterPlotData, (d) => { return d.x; });
                    const scaleMaxX = d3.max(scatterPlotData, (d) => { return d.x; });
                    const sclaeMinY = d3.min(scatterPlotData, (d) => { return d.y; });
                    const sclaeMaxY = d3.max(scatterPlotData, (d) => { return d.y; });
                    x.domain([scaleMinX, scaleMaxX]).nice();
                    y.domain([sclaeMinY, sclaeMaxY]).nice();
                } else {
                    x.domain([s[0][0], s[1][0]].map(x.invert, x)).nice();
                    y.domain([s[1][1], s[0][1]].map(y.invert, y)).nice();
                    scatter.select(".brush").call(brush.move, null);
                }
                zoom();
            }

            function idled() {
                idleTimeout = null;
            }

            function zoom() {
                const t = scatter.transition().duration(750);
                svg.select(".axis-x").transition(t).call(xScale);
                svg.select(".axis-y").transition(t).call(yScale);
                svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
                scatter.selectAll("circle").transition(t)
                    .attr("cx", (d) => { return x(d.x); })
                    .attr("cy", (d) => { return y(d.y); });
            }

            scatter.append("g")
                .attr("id", "circles")
                .selectAll("circle")
                .data(scatterPlotData)
                .enter()
                .append("circle")
                .attr("cx", 0)
                .attr("cy", (d) => { return y(d.y); })
                .attr("r", 3)
                .style("fill", (d) => { return d.isValid ? theme.palette.chartColors.scatterValid : theme.palette.chartColors.scatterInvalid; })
                .on('mouseover', (d) => {
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);
                    let x = 0;
                    let y = 0;
                    div.attr("transform", (_, i, nodes) => {
                        const mouseCoords = d3.mouse(nodes[i].parentNode);
                        let xCo = 0;
                        let yCo = 0;
                        if (mouseCoords[0] + 10 >= width * 0.80) {
                            xCo = mouseCoords[0] - parseFloat(div
                                .attr("width"));
                            yCo = mouseCoords[1] + 10;
                        } else {
                            xCo = mouseCoords[0] + 10;
                            yCo = mouseCoords[1];
                        }
                        x = isNaN(xCo) ? mouseCoords[0] : xCo;
                        y = isNaN(yCo) ? mouseCoords[1] : yCo;
                    });
                    div.html(`Value : ${d.y}`)
                        .style("left", x + "px")
                        .style("top", y + "px");
                })
                .on('mouseout', () => {
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                })
                .on('click', (d) => {
                    onFilterChange({ value: d.y, filterType: appConstants.QueryFilterTypes[0] });
                })
                .transition()
                .delay((d, i) => { return Number(i); })
                .duration(2000)
                .attr("cx", (d) => { return x(d.x); });
            const xBins = d3.histogram()
                .domain(x.domain())
                .thresholds(x.ticks(10))
                .value((d) => {
                    return d.x;
                })(scatterPlotData);

            const xy = d3.scaleLinear()
                .domain([
                    0, d3.max(xBins, (d) => {
                        return d.length;
                    })
                ])
                .range([margin.top, 0]);
            const gTop = d3.select(".scatter-marginal-chart svg").append("g")
                .attr("transform",
                    "translate(" + ((margin.left + 40) * 2) + "," + 0 + ")");
            const scatterWidth = svg.node().getBoundingClientRect().width;
            let translateX = 0;
            const xBar = gTop.selectAll(".bar")
                .data(xBins)
                .enter().append("g")
                .attr("class", "bar")
                .attr("transform", (d, i) => {
                    if (i !== 0) {
                        translateX += 20;
                    }
                    return "translate(" + translateX + "," + xy(d.length) + ")";
                });

            xBar.append("rect")
                .attr("x", 1)
                .attr("width", 18)
                .attr("height", 0)
                .style("fill", theme.palette.chartColors.scatterBar)
                .attr("y", (d) => { return margin.top - xy(d.length); })
                .on('click', (d) => {
                    const values = d.map((p) => p.y);
                    const minValue = Math.min(...values);
                    const maxValue = Math.max(...values);
                    onFilterChange({
                        value: {
                            min: minValue,
                            max: maxValue,
                            operator: 'is between'
                        }, filterType: appConstants.QueryFilterTypes[0]
                    });
                })
                .transition()
                .duration(500)
                .delay((_, i) => {
                    return i * 100;
                })
                .attr("y", (d) => { return 0; })
                .attr("height", (d) => {
                    return margin.top - xy(d.length);
                });


            // right histogram
            const gRight = d3.select(".scatter-marginal-chart svg").append("g")
                .attr("transform",
                    "translate(" + (scatterWidth + (margin.top - margin.left)) + "," + margin.top + ")");

            const yBins = d3.histogram()
                .domain(y.domain())
                .thresholds(y.ticks(10))
                .value((d) => {
                    return d.y;
                })(scatterPlotData);

            const yx = d3.scaleLinear()
                .domain([
                    0, d3.max(yBins, (d) => {
                        return d.length;
                    })
                ])
                .range([0, margin.right]);

            let translateY = 0;
            const yBar = gRight.selectAll(".bar")
                .data(yBins)
                .enter().append("g")
                .attr("class", "bar")
                .attr("transform", (d, i) => {
                    if (i !== 0) {
                        translateY += 20;
                    }
                    return "translate(" + 0 + "," + translateY + ")";
                });

            yBar.append("rect")
                .attr("y", 1)
                .attr("width", 0)
                .attr("height", 18)
                .on('click', (d) => {
                    const values = d.map((p) => p.y);
                    const minValue = Math.min(...values);
                    const maxValue = Math.max(...values);
                    onFilterChange({
                        value: {
                            min: minValue,
                            max: maxValue,
                            operator: 'is between'
                        }, filterType: appConstants.QueryFilterTypes[0]
                    });
                })
                .style("fill", theme.palette.chartColors.scatterBar)
                .transition()
                .duration(500)
                .delay((d, i) => {
                    return i * 100;
                })
                .attr("width", (d) => {
                    return yx(d.length);
                });

            svg.selectAll('.tick').selectAll('text').attr('class', 'tick-text');
            svg.selectAll(".axis-y .tick:first-of-type text").remove();
            svg.selectAll('.axis-y .tick-text').text('').append('tspan').text((d) => { return d; });
        }

    };

    return (
        <Grid container className={classNames(classes.chartStyles, "scatter-marginal-chart")} />
    );
};

ScatterMarginal.propTypes = {
    classes: PropTypes.object,
    chartData: PropTypes.object,
    theme: PropTypes.object,
    attribute: PropTypes.string,
    onFilterChange: PropTypes.func,
    discardEdit: PropTypes.func,
    attributeChange: PropTypes.bool,
    edited: PropTypes.bool
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ScatterMarginal);