import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Popover, Grid, Typography, MenuItem, Button, IconButton } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useSelector } from 'react-redux';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import ActionItemStyles from './ActionItemStyles.jsx';
import { appConstants } from '../../constants/appConstants.js';
import MentionComment from '../MentionComment/MentionComment.jsx';
import CloseIcon from '@material-ui/icons/Close';

const ActionComments = (props) => {
    const { classes, postComment, onChangeComment, action, open, closePopover } = props;
    const users = useSelector(({ account }) => account.users);
    const user = useSelector(({ account }) => account.user);
    const [anchorEl] = useState('anchorPosition');
    const [enableEdit, setEnableEdit] = useState(false);
    const [userList, setUserList] = useState([]);
    const datasetSharing = useSelector(({ dataset }) => dataset.sharingInfo);
    const datasourceSharing = useSelector(({ datasource }) => datasource.sharingInfo);
    const domainSharing = useSelector(({ datasource }) => datasource.domainSharingInfo);

    const onPostComment = useCallback(() => {
        setEnableEdit(false);
        postComment(userList);
    }, [postComment, userList]);

    const onChange = useCallback((value, index) => {
        setEnableEdit(true);
        onChangeComment("comment", value, index);
    }, [onChangeComment]);

    const returnUsers = useCallback(() => {
        let sharingInfo = datasourceSharing;
        if (window.location.href.includes('dataset')) {
            sharingInfo = datasetSharing;
        } else if (window.location.href.includes('domain')) {
            sharingInfo = domainSharing;
        }
        sharingInfo = sharingInfo.map((info) => info.user_id);
        return users.filter((item) => sharingInfo.includes(item.id) && item.id !== user.id).map((user) => { return { ...user, display: user.first_name !== "" ? `${user.first_name} ${user.last_name}` : user.email }; });
    }, [datasetSharing, datasourceSharing, domainSharing, user.id, users]);

    const getAssignUsers = useCallback((userList) => {
        setUserList([...userList]);
    }, []);

    return (
        <Popover
            open={open}
            anchorReference={anchorEl}
            anchorOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }
            transformOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }
            onClose={() => closePopover()}
            anchorPosition={{ left: action.position.left + 50, top: action.position.top - 20 }}
            style={{ position: 'fixed' }}
        >
            <Grid className={classes.popOverContainer}>
                <ValidatorForm name="comments" onSubmit={() => onPostComment()}>
                    <Grid container justify="space-between">
                        <Typography>
                            Leave Comments
                        </Typography>
                        <IconButton className={classes.nopadding} onClick={() => closePopover()}>
                            <CloseIcon />
                        </IconButton>
                    </Grid>
                    <Grid className={classes.marginBottom10}>

                        {
                            action.comments && action.comments.map((comment, index) =>
                                <Fragment key={index}>
                                    <MentionComment
                                        key={index}
                                        rowCount={4}
                                        comment={comment.comment ? comment.comment : ''}
                                        onChangeComment={(value) => onChange(value, index)}
                                        users={returnUsers()}
                                        showHelperText={enableEdit && comment.comment === ""}
                                        helperText={"Comment is required"}
                                        getAssignUsers={getAssignUsers}
                                        placeholder="Leave Comment @ using mention People"
                                    />
                                </Fragment>
                            )
                        }
                    </Grid>
                    <Grid container justify="space-between">
                        <Grid item>
                            <TextBox
                                name="priority"
                                fullWidth
                                select
                                label="Status"
                                onChange={(event) => onChangeComment(event.target.name, event.target.value)}
                                value={action.priority ? action.priority : 'High'}
                                className={classes.inlineTxt}
                                SelectProps={
                                    {
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center"
                                            },
                                            getContentAnchorEl: null
                                        }
                                        // IconComponent: () => DropDownIcon()
                                    }
                                }
                            >
                                {
                                    appConstants.priority.map((option, index) => (
                                        <MenuItem key={`menuProperty_Options_${index}`} value={option} className={classes.menuItem}>
                                            {option}
                                        </MenuItem>
                                    ))
                                }
                            </TextBox>
                        </Grid>
                        <Grid item>
                            <TextBox
                                name="assigneduser"
                                fullWidth
                                select
                                label="Assignee"
                                onChange={(event) => onChangeComment(event.target.name, event.target.value)}
                                value={action.assigneduser ? action.assigneduser : ''}
                                className={classes.widthIncrease}
                                SelectProps={
                                    {
                                        MenuProps: {
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center"
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center"
                                            },
                                            getContentAnchorEl: null
                                        }
                                        // IconComponent: () => DropDownIcon()
                                    }
                                }
                            >
                                {
                                    returnUsers().map((option, index) => (
                                        <MenuItem key={`assigned_user_${index}`} value={option.username} className={classes.menuItem}>
                                            {option.username}
                                        </MenuItem>
                                    ))
                                }
                            </TextBox>
                        </Grid>
                        <Grid item>
                            <Button disabled={(action.comments && action.comments[0].comment === "") || (!action.assigneduser || action.assigneduser === "")} type="submit" variant="contained" color="primary" size="large">
                                Post
                            </Button>
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Popover>
    );
};

ActionComments.propTypes = {
    classes: PropTypes.object,
    postComment: PropTypes.func,
    onChangeComment: PropTypes.func,
    action: PropTypes.object,
    open: PropTypes.bool,
    closePopover: PropTypes.func
};

export default withStyles((theme) => ({
    ...ActionItemStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ActionComments);