import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Popover, Grid, Typography, Divider, MenuItem } from '@material-ui/core';
import DatasourceStyles from './DatasourceStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import CheckboxComponent from '../ChecboxComponent/CheckboxComponent.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import { appConstants } from '../../constants/appConstants.js';
import { getExecutionCurrentJobDetail } from '../../actions/scheduleActions';

const ExecutionLogDetails = (props) => {
    const { classes, jobDetails, anchorEl, onClose, sourceId, datasetId } = props;
    const [currentJobDetail, setCurrentJobDetail] = useState({ isCurrentJob: false, jobType: 'pull/scan' });
    const defaultStatistics = { pending: 0, completed: 0 };
    const [jobStatistics, setJobStatistics] = useState({ ...defaultStatistics });
    const dispatch = useDispatch();

    const onChange = (property, value) => {
        currentJobDetail[property] = value;
        setCurrentJobDetail({ ...currentJobDetail });
        if (currentJobDetail.isCurrentJob) {
            getCurrentJobDetail(currentJobDetail.jobType);
        }
    };

    const getCurrentJobDetail = (jobType) => {
        const requestParams = {
            source_id: sourceId,
            dataset_id: datasetId,
            job_type: jobType
        };
        dispatch(getExecutionCurrentJobDetail(requestParams)).then((response) => {
            if (response) {
                setJobStatistics({ ...response });
            }
        });
    };

    return (
        <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            anchorOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }
            transformOrigin={
                {
                    vertical: 'top',
                    horizontal: 'left'
                }
            }
            onClose={() => onClose()}
        >
            <Grid className={classes.statisticsContainer}>
                <Grid container justify="space-between" className={classes.marginBottom10}>
                    <Grid item>
                        <Typography component="h4" variant="h4">
                            {'Execution Details'}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Grid className={classes.flex}>
                            <Grid className={classes.marginRight5}>
                                <CheckboxComponent
                                    checked={currentJobDetail.isCurrentJob}
                                    onChange={() => onChange("isCurrentJob", !currentJobDetail.isCurrentJob)}
                                    checkboxLabel="Current Job" />
                            </Grid>
                            {
                                currentJobDetail.isCurrentJob &&
                                <TextBox
                                    name="jobType"
                                    fullWidth
                                    select
                                    onChange={(event) => onChange(event.target.name, event.target.value)}
                                    value={currentJobDetail.jobType ? currentJobDetail.jobType : 'pull/scan'}
                                    className={classes.inlineTxt}
                                    SelectProps={
                                        {
                                            MenuProps: {
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "center"
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "center"
                                                },
                                                getContentAnchorEl: null
                                            }
                                            // IconComponent: () => DropDownIcon()
                                        }
                                    }
                                >
                                    {
                                        appConstants.executionJobTypes.map((option, index) => (
                                            <MenuItem key={`menuProperty_Options_${index}`} value={option.value} className={classes.menuItem}>
                                                {option.name}
                                            </MenuItem>
                                        ))
                                    }
                                </TextBox>
                            }
                        </Grid>

                    </Grid>
                </Grid>
                {
                    !currentJobDetail.isCurrentJob ?
                        <Grid>
                            {
                                jobDetails && Object.keys(jobDetails).map((logInfo, index) => {
                                    const log = jobDetails[logInfo] ? jobDetails[logInfo] : {};
                                    return (
                                        <Grid key={index} className={classes.marginBottom10}>
                                            <Typography component="h6" variant="h6">
                                                {logInfo}
                                            </Typography>
                                            <Divider />
                                            <Grid container justify="space-between" className={classes.marginTop5}>
                                                <Grid item className={classes.displayFlex}>
                                                    <Typography className={classes.disabledtxt}>
                                                        Pending
                                                    </Typography>
                                                    <Typography className={classNames(classes.marginLeft5)}>
                                                        {log.pending ? log.pending : 0}
                                                    </Typography>
                                                </Grid>
                                                <Grid item className={classes.displayFlex}>
                                                    <Typography className={classes.disabledtxt}>
                                                        Start
                                                    </Typography>
                                                    <Typography className={classNames(classes.marginLeft5)}>
                                                        {log.started ? log.started : 0}
                                                    </Typography>
                                                </Grid>
                                                <Grid item className={classes.displayFlex}>
                                                    <Typography className={classes.disabledtxt}>
                                                        Completed
                                                    </Typography>
                                                    <Typography className={classNames(classes.marginLeft5)}>
                                                        {log.completed ? log.completed : 0}
                                                    </Typography>
                                                </Grid>
                                                <Grid item className={classes.displayFlex}>
                                                    <Typography className={classes.disabledtxt}>
                                                        Failed
                                                    </Typography>
                                                    <Typography className={classNames(classes.marginLeft5)}>
                                                        {log.failed ? log.failed : 0}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                }
                                )
                            }
                        </Grid> :
                        <Grid container justify="space-between" className={classes.marginTop5}>
                            <Grid item className={classes.displayFlex}>
                                <Typography className={classes.disabledtxt}>
                                    Pending
                                </Typography>
                                <Typography className={classNames(classes.marginLeft5)}>
                                    {jobStatistics.pending ? jobStatistics.pending : 0}
                                </Typography>
                            </Grid>
                            <Grid item className={classes.displayFlex}>
                                <Typography className={classes.disabledtxt}>
                                    Completed
                                </Typography>
                                <Typography className={classNames(classes.marginLeft5)}>
                                    {jobStatistics.completed ? jobStatistics.completed : 0}
                                </Typography>
                            </Grid>

                        </Grid>
                }
            </Grid>
        </Popover >
    );
};

ExecutionLogDetails.propTypes = {
    classes: PropTypes.object,
    jobDetails: PropTypes.object,
    anchorEl: PropTypes.element,
    onClose: PropTypes.func,
    sourceId: PropTypes.number,
    datasetId: PropTypes.number

};

export default withStyles((theme) => ({
    ...DatasourceStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ExecutionLogDetails);