import React from 'react';
import { Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute.jsx';
import PrivateRoute from './PrivateRoute.jsx';

import Login from '../containers/Account/Login.jsx';
import ForgotPassword from '../containers/Account/ForgotPassword.jsx';
import Welcome from '../containers/Welcome/Welcome.jsx';
import DataSourceTypes from '../containers/ConfigureDataSource/DataSourceTypes.jsx';
import ConfigureDataSource from '../containers/ConfigureDataSource/ConfigureDataSource.jsx';
import DatasourceOverview from '../containers/DatasourceOverview/DatasourceOverview.jsx';
import DatasetOverview from '../containers/DatasetOverview/DatasetOverview.jsx';
import ItemOverview from '../containers/ItemOverview/ItemOverview.jsx';
import Datasource from '../containers/Datasource/Datasource.jsx';
import UserActivate from '../containers/Account/UserActivate.jsx';
import Settings from '../containers/Settings/Settings.jsx';
import ResetPassword from '../containers/Account/ResetPassword.jsx';
import Dashboard from '../containers/Dashboard/Dashboard.jsx';
import ActionItem from '../containers/ActionItem/ActionItem.jsx';
import Notification from '../containers/Notification/Notification.jsx';
import Master from '../containers/Master/Master.jsx';
import MasterDetailInfo from '../containers/Master/MasterDetailInfo.jsx';
import AddMaster from '../containers/Master/AddMaster.jsx';
import Conversation from '../containers/Conversation/Conversation.jsx';
import Domain from '../containers/Domain/Domain.jsx';
import DataPreparation from '../containers/DataPreparation/DataPreparation.jsx';
import CreateDataPreparation from '../containers/CreateDataPreparation/CreateDataPreparation.jsx';
import DataPreparationDetail from '../containers/DataPreparationDetail/DataPreparationDetail.jsx';
import DataPreparationData from '../containers/DataPrepearationData/DataPreparationData.jsx';
import Lineage from '../containers/Lineage/Lineage.jsx';
import SemanticModel from '../containers/SemanticModel/SemanticModel.jsx';
import CreateSemanticModel from '../containers/SemanticModel/CreateSemanticModel.jsx';
import SemanticDetailInfo from '../containers/SemanticModel/SematicDetailInfo.jsx';
import NopermissionComponent from '../containers/NopermissionComponent/NopermissionComponent.jsx';
import AboutUS from '../containers/AboutUs/AboutUs.jsx';
import DataSourceImport from '../containers/Datasourceimport/Datasourceimport.jsx';
import ImportRules from '../containers/Datasourceimport/ImportRules.jsx';
import ImportUsers from '../containers/Datasourceimport/ImportUsers.jsx';
import Search from '../containers/Search/Search.jsx';
import SearchList from '../containers/Search/SearchList.jsx';
import DriftReport from '../containers/DriftReport/DriftReport.jsx';


const Routes = () => {
	return (
		<Switch>
			<PublicRoute exact path="/" component={Login} />
			<PublicRoute exact path="/forgotpassword" component={ForgotPassword} />
			<PublicRoute exact path="/activation/:id" component={UserActivate} />
			<PublicRoute exact path="/resetpassword/:token" component={ResetPassword} />
			<PrivateRoute exact path="/welcome" component={Welcome} />
			<PrivateRoute exact path="/datasourcetypes" component={DataSourceTypes} />
			<PrivateRoute exact path="/configuredatasource" component={ConfigureDataSource} />
			<PrivateRoute exact path="/catalog/:id" component={DatasourceOverview} />
			<PrivateRoute exact path="/dataset/:id" component={DatasetOverview} />
			<PrivateRoute exact path="/item/:id" component={ItemOverview} />
			<PrivateRoute exact path="/catalog" component={Datasource} />
			<PrivateRoute exact path="/settings" component={Settings} />
			<PrivateRoute exact path="/dashboard" component={Dashboard} />
			<PrivateRoute exact path="/actionitem" component={ActionItem} />
			<PrivateRoute exact path="/notification" component={Notification} />
			<PrivateRoute exact path="/semanticmodel" component={Master} />
			<PrivateRoute exact path="/semanticmodel/:id" component={MasterDetailInfo} />
			<PrivateRoute exact path="/createmaster" component={AddMaster} />
			<PrivateRoute exact path="/conversation" component={Conversation} />
			<PrivateRoute exact path="/domain/:id" component={Domain} />
			{/* unsed routes */}
			{/* ---- */}
			<PrivateRoute exact path="/datapreparation" component={DataPreparation} />
			<PrivateRoute exact path="/createdatapreparation" component={CreateDataPreparation} />
			<PrivateRoute exact path="/datapreparation/:id" component={DataPreparationDetail} />
			<PrivateRoute exact path="/transform/:id" component={DataPreparationData} />
			{/* ----- */}
			<PrivateRoute exact path="/lineage" component={Lineage} />
			<PrivateRoute exact path="/model" component={SemanticModel} />
			<PrivateRoute exact path="/aboutus" component={AboutUS} />
			<PrivateRoute exact path="/createmodel" component={CreateSemanticModel} />
			<PrivateRoute exact path="/model/:id" component={SemanticDetailInfo} />
			<PrivateRoute exact path="/404" component={NopermissionComponent} />
			<PrivateRoute exact path="/datasourceimport" component={DataSourceImport} />
			<PrivateRoute exact path="/importrules" component={ImportRules} />
			<PrivateRoute exact path="/importusers" component={ImportUsers} />
			<PrivateRoute exact path="/search" component={Search} />
			<PrivateRoute exact path="/search/list" component={SearchList} />
			<PrivateRoute exact path="/report" pageTitle="Report" component={DriftReport} />
		</Switch>
	);
};

Routes.propTypes = {
};

export default Routes;