import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Grid, IconButton, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import AddIcon from '@material-ui/icons/Add';
import TabPanel from '../../components/TabPanel/TabPanel.jsx';
// import classnames from 'classnames';

import DashboardCharts from '../../components/Dashboard/DashboardCharts.jsx';
import DashboardItem from '../../components/Dashboard/DashboardItem.jsx';
import { setCurrentPage } from '../../helpers/apiHelpers.js';
import ToolTipComponent from '../../components/Tooltip/Tooltip.jsx';
import AlertDialog from '../../components/AlertDialog/AlertDialog.jsx';
import Styles from '../../layouts/Styles.jsx';
import { getDashboards, createDashboard, deleteDashboard } from '../../actions/dashboardActions.js';
import DashboardWidgetStyles from './DashboardWidgetStyles.jsx';


class Dashboard extends Component {
    constructor(props) {
        super(props);
        setCurrentPage('dashboard');
        this.state = {
            dashboards: [],
            selectedDashboard: null,
            tabIndex: 0
        };
    }

    componentDidMount() {
        this.getAllDashboards();
        this.props.getPageTitle('Dashboard');
    }

    handleTabChange = (event, selectedTabIndex) => {
        this.setState({ tabIndex: selectedTabIndex });
    };

    getAllDashboards = () => {
        this.props.getDashboards().then((response) => {
            if (response) {
                for (const dashboard of response) {
                    dashboard.name = dashboard.name ? dashboard.name : 'New';
                    let widgets = dashboard.widgets && typeof dashboard.widgets === 'string' ? JSON.parse(dashboard.widgets) : dashboard.widgets;
                    widgets = (widgets instanceof Array) ? widgets : [];
                    for (const widget of widgets) {
                        widget.isModified = true;
                        widget.isResizable = true;
                        widget.isCancelled = false;
                    }
                    dashboard.widgets = widgets;
                }
                this.setState({ dashboards: [...response] });
            }
        });
    }

    onAddDashboard = () => {
        const dashboard = { name: 'new', description: '' };
        dashboard.widgets = JSON.stringify([]);
        this.props.createDashboard(dashboard).then((response) => {
            const widgets = response.widgets && typeof response.widgets === 'string' ? JSON.parse(response.widgets) : response.widgets;
            for (const widget of widgets) {
                widget.isModified = true;
            }
            response.widgets = widgets;
            this.setState({
                dashboards: [...this.state.dashboards, response],
                tabIndex: response.id
            });
        });
    };

    onUpdateDashboard = (updatedDashboard) => {
        const { dashboards } = this.state;
        const index = dashboards.findIndex((dashboard) => dashboard.id === updatedDashboard.id);
        dashboards[index] = { ...updatedDashboard };
        this.setState({ dashboards: [...dashboards] });
    };

    onDeleteDashboard = () => {
        const { selectedDashboard } = this.state;
        this.props.deleteDashboard(selectedDashboard.id).then(() => {
            const dashboards = [...this.state.dashboards];
            const index = dashboards.findIndex((d) => d.id === selectedDashboard.id);
            dashboards.splice(index, 1);
            let tabIndex = 0;
            if (dashboards && dashboards.length > 0 && (dashboards.length - 1 > -1) && ((dashboards.length - 1) >= (index - 1))) {
                const previousDataset = dashboards[index - 1];
                tabIndex = previousDataset?.id ?? 0;
            }
            this.setState({
                selectedDashboard: null,
                dashboards: [...dashboards],
                tabIndex
            });
        });
    };

    render() {
        const { classes, licenseConfig, history } = this.props;
        return (
            <Grid container>
                <Grid container direction="row" alignItems="center" justify="space-between" className={classes.dashboardHeaderContainer}>
                    <Typography component="h2" variant="h2">
                        Dashboard
                    </Typography>
                    {
                        licenseConfig && licenseConfig.custom_dashboard &&
                        <ToolTipComponent title="Add Dashboard" placement="bottom" arrow>
                            <IconButton className={classes.addIconButton}
                                classes={{ root: classes.addIconButtonRoot }}
                                onClick={() => this.onAddDashboard()}>
                                <AddIcon className={classes.addIcon} />
                            </IconButton>
                        </ToolTipComponent>
                    }
                </Grid>
                <Grid className={classNames(classes.datasourceTabContainer, classes.dashboardTabs)}>
                    <Tabs
                        value={this.state.tabIndex}
                        onChange={this.handleTabChange}
                        indicatorColor="secondary"
                        textColor="secondary"
                        aria-label="dashboard tabs"
                        variant="scrollable"
                    >
                        <Tab label="Organization" value={0} />
                        {
                            licenseConfig && licenseConfig.custom_dashboard &&
                            this.state.dashboards.map((dashboard) => {
                                return (
                                    <Tab key={dashboard.id} label={dashboard.name} value={dashboard.id} />
                                );
                            })
                        }
                    </Tabs>

                </Grid>
                <TabPanel value={this.state.tabIndex} index={0}>
                    <DashboardCharts history={history} />
                </TabPanel>
                {
                    this.state.dashboards.map((dashboard) => {
                        return (
                            <TabPanel key={dashboard.id} value={this.state.tabIndex} index={dashboard.id} className={classes.customTabPanel}>
                                <DashboardItem
                                    history={history}
                                    dashboard={dashboard}
                                    tabIndex={dashboard.id}
                                    selectedTabIndex={this.state.tabIndex}
                                    onUpdateDashboard={(dashboard) => this.onUpdateDashboard(dashboard)}
                                    onDeleteDashboard={(dashboard) => this.setState({ selectedDashboard: dashboard })}
                                />
                            </TabPanel>
                        );
                    })
                }
                <AlertDialog title="Delete Dashboard"
                    message={`Are you sure you want to delete the dashboard - ${this.state.selectedDashboard ? this.state.selectedDashboard.name : ''}?`}
                    okButtonText="OK"
                    cancelButtonText="Cancel"
                    show={Boolean(this.state.selectedDashboard)}
                    onClickOK={() => this.onDeleteDashboard()}
                    onClickCancel={() => this.setState({ selectedDashboard: null })} />
            </Grid >
        );
    }
}

Dashboard.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    licenseConfig: PropTypes.object,
    getDashboards: PropTypes.func,
    createDashboard: PropTypes.func,
    deleteDashboard: PropTypes.func,
    getPageTitle: PropTypes.func
};

/**
 * Map State To Props
 * @description Get Reducer Props value to Props property
 * @param {object} datasource,account
 */
const mapStateToProps = ({ setting }) => {
    return {
        ...setting
    };
};

/**
 * Dispatch Props
 * @description dispatch Props actions
 * @param {object} dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ getDashboards, createDashboard, deleteDashboard }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...DashboardWidgetStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(Dashboard));