import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, TextField, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';

import Styles from '../../layouts/Styles.jsx';
import AutoCompleteInput from '../AutoComplete/AutoCompleteInput.jsx';

const GroupByTransform = (props) => {
    const { classes, theme, attributes, transformation, onUpdateTransformation } = props;
    const [sourceAttributes, setSourceAttributes] = useState([]);
    const [transform, setTransform] = useState({
        inputAttributes: [],
        aggregations: []
    });

    const updateTransformation = useCallback((transform) => {
        if (transform.inputAttributes && transform.inputAttributes.length <= 0) {
            return;
        }
        const aggregations = [];
        if (transform && transform.aggregations) {
            for (const aggregation of transform.aggregations) {
                if (aggregation.name && aggregation.name.length > 0 && aggregation.attribute) {
                    aggregations.push(aggregation);
                }
            }
        }
        if (onUpdateTransformation) {
            onUpdateTransformation({
                'input_attributes': transform.inputAttributes ? transform.inputAttributes : [],
                'params': {
                    'aggregations': aggregations ? [...aggregations] : []
                }
            });
        }
    }, [onUpdateTransformation]);

    const loadTransform = useCallback((transform) => {
        setTransform({
            inputAttributes: transform && transform.inputAttributes ? [...transform.inputAttributes] : [],
            aggregations: transform && transform.params && transform.params.attributes ? [...transform.params.attributes] : []
        });
    }, []);

    const addAttribute = useCallback((property, attributes) => {
        transform[property] = [...attributes];
        setTransform({ ...transform });
    }, [transform]);


    const addAggregation = useCallback(() => {
        if (!transform.aggregations) {
            transform.aggregations = [];
        }
        transform.aggregations.push({
            name: '',
            'class_name': '',
            attribute: ''
        });
        setTransform({ ...transform });
    }, [transform]);


    const removeAggregation = useCallback((aggregationIndex) => {
        transform.aggregations.splice(aggregationIndex, 1);
        setTransform({ ...transform });
        updateTransformation(transform);
    }, [transform, updateTransformation]);

    const updateAggregation = useCallback((aggregationIndex, property, value) => {
        const selectedAggregation = transform.aggregations[aggregationIndex];
        if (property === 'aggregation') {
            selectedAggregation.name = value.name;
            selectedAggregation['class_name'] = value.class_name;
        } else {
            selectedAggregation[property] = value;
        }
        transform.aggregations.splice(aggregationIndex, 1, selectedAggregation);
        setTransform({ ...transform });
        updateTransformation(transform);
    }, [transform, updateTransformation]);

    const loadSourceAttributes = useCallback((attributes) => setSourceAttributes([...attributes]), [setSourceAttributes]);

    useEffect(() => {
        if (sourceAttributes && sourceAttributes.length > 0) {
            return;
        }
        loadSourceAttributes(attributes);
    }, [attributes, loadSourceAttributes, sourceAttributes]);

    useEffect(() => {
        if (transformation) {
            return;
        }
        loadTransform(transformation);
    }, [loadTransform, transformation]);

    const getAttributes = useCallback(() => {
        const selectedAttributes = transform && transform.inputAttributes ? [...transform.inputAttributes] : [];
        const attributeList = [];
        for (const attribute of sourceAttributes) {
            if (attribute.isTransformAttribute) {
                continue;
            }
            const attributeIndex = selectedAttributes.findIndex((p) => p.name === attribute.name);
            if (attributeIndex === -1) {
                attributeList.push({ ...attribute });
            }
        }
        return attributeList;
    }, [sourceAttributes, transform]);

    return (
        <Grid cotainer direction="column" justify="flex-start">
            <Grid item>
                <AutoCompleteInput
                    name="Group By"
                    popperWidth={300}
                    options={[...getAttributes()]}
                    value={transform && transform.inputAttributes ? [...transform.inputAttributes] : []}
                    getOptionLabel={
                        (option) => {
                            if (option && option.name) {
                                return option.name;
                            }
                            return option;
                        }
                    }
                    renderInput={
                        (params) =>
                            <TextField {...params}
                                className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                placeholder="Input Attributes"
                                onBlur={() => updateTransformation(transform)}
                            />
                    }
                    onChange={(_, selectedValue) => addAttribute('inputAttributes', selectedValue)}
                    openOnFocus
                    disableCloseOnSelect
                    disableClearable
                    fullWidth
                    multiple
                    forcePopupIcon={false}
                    noOptionsText={"No Attributes Found"}
                />
            </Grid>
            <Grid item>
                <Grid container direction="row" justify="space-between">
                    <Typography>
                        {'Aggregations'}
                    </Typography>
                    <IconButton onClick={() => addAggregation()}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                    <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                    <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                </g>
                            </g>
                        </svg>
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item>
                {
                    transform.aggregations && transform.aggregations.length > 0 && transform.aggregations.map((aggregation, index) => {
                        return (
                            <Grid container direction="row" justify="flex-start" alignItems="center" key={`aggregation_${index}`}>
                                <Grid item xs={4}>
                                    <AutoCompleteInput
                                        name="aggregateFunction"
                                        options={transformation.functions ? [...transformation.functions] : []}
                                        value={aggregation}
                                        getOptionLabel={
                                            (option) => {
                                                if (option && option.name) {
                                                    return option.name;
                                                }
                                                return option;
                                            }
                                        }
                                        renderInput={
                                            (params) =>
                                                <TextField {...params}
                                                    className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                                    placeholder="Aggregation"
                                                />
                                        }
                                        onChange={(_, selectedValue) => updateAggregation(index, 'aggregation', selectedValue)}
                                        openOnFocus
                                        blurOnSelect
                                        disableClearable
                                        forcePopupIcon={false}
                                        noOptionsText={"No aggregate functions found"}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <AutoCompleteInput
                                        name="attribute"
                                        options={sourceAttributes ? [...sourceAttributes] : []}
                                        value={aggregation && aggregation.attribute ? aggregation.attribute : {}}
                                        getOptionLabel={
                                            (option) => {
                                                if (option && option.name) {
                                                    return option.name;
                                                }
                                                return option;
                                            }
                                        }
                                        renderInput={
                                            (params) =>
                                                <TextField {...params}
                                                    className={classNames(classes.autoCompleteInput, classes.inlinetxt, classes.fontSize13, classes.hoverBorderNone, classes.hoverBgGrey)}
                                                    placeholder="Attribute"
                                                />
                                        }
                                        onChange={(_, selectedValue) => updateAggregation(index, 'attribute', selectedValue)}
                                        openOnFocus
                                        blurOnSelect
                                        disableClearable
                                        forcePopupIcon={false}
                                        noOptionsText={"No attributes found"}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton className={classNames(classes.deleteDataset)} style={{ padding: 8 }} onClick={() => removeAggregation(index)}>
                                        <svg version="1.1"
                                            id="Layer_1"
                                            width="21"
                                            height="21"
                                            xmlns="http://www.w3.org/2000/svg"
                                            x="0px"
                                            y="0px"
                                            viewBox="0 0 32 32">
                                            <g id="Delete">
                                                <path fill={theme.palette.primary.main}
                                                    stroke={theme.palette.primary.main}
                                                    strokeWidth="0.92"
                                                    strokeMiterlimit="10"
                                                    d="M24.4,10.3H7.6C7.3,10.3,7,10.6,7,11c0,0,0,0,0,0.1l1.7,15c0.2,1.7,1.6,2.9,3.3,2.9h8.3c1.7,0,3.1-1.3,3.3-3
		L25,11c0-0.2,0-0.4-0.2-0.5C24.7,10.4,24.5,10.3,24.4,10.3z M22.3,25.8c-0.1,1-1,1.8-2,1.8H12c-1,0-1.9-0.8-2-1.8L8.4,11.6h15.3
		L22.3,25.8z" />
                                                <path fill={theme.palette.primary.main}
                                                    stroke={theme.palette.primary.main}
                                                    strokeWidth="0.92"
                                                    strokeMiterlimit="10"
                                                    d="M26.8,6.2h-6.7V5c0-1.1-0.9-2-2-2h-4.2c-1.1,0-2,0.9-2,2v1.2H5.2c-0.3,0-0.6,0.3-0.6,0.6
		c0,0.3,0.3,0.6,0.6,0.6h21.5c0.3,0,0.6-0.3,0.6-0.6C27.4,6.5,27.1,6.2,26.8,6.2z M18.8,6.2h-5.6V5c0-0.4,0.3-0.7,0.7-0.7h4.2
		c0.4,0,0.7,0.3,0.7,0.7L18.8,6.2z" />
                                            </g>
                                        </svg>

                                    </IconButton>
                                </Grid>
                            </Grid>
                        );
                    })
                }
            </Grid>
        </Grid>
    );
};

GroupByTransform.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    transformation: PropTypes.object,
    attributes: PropTypes.array,
    onUpdateTransformation: PropTypes.func
};

export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(GroupByTransform);