// import react
import React, { useState, useCallback } from 'react';

// import react-redux
import { useDispatch, useSelector } from 'react-redux';

// import material-ui components
import { Grid, withStyles, IconButton, AccordionSummary, Accordion, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import styles
import SettingComponentStyles from './SettingComponentStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import theme from '../../theme';

// import components
import Storage from '../../components/Settings/Storage.jsx';
import Fields from '../../components/Settings/Fields.jsx';
import Api from '../../components/Settings/Api.jsx';
import License from '../../components/Settings/License.jsx';
import ExportMetadata from '../../components/Settings/ExportMetadata.jsx';
import ExportDQMetrics from '../../components/Settings/ExportDQMetrics.jsx';
import SSO from '../../components/Settings/Sso.jsx';
import Ldap from './Ldap.jsx';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import Loader from '../Loaders/Loader.jsx';
import ImportSettings from '../../components/Settings/ImportSettings.jsx';

// import action files
import { exportOrganizationReport } from '../../actions/settingActions';

// import prop-types
import PropTypes from 'prop-types';

const System = (props) => {
    const { classes, organizationId, isOrganization, navigateTab, isSystemLicense, history } = props;
    const dispatch = useDispatch();

    // useState
    const [isExportLoading, setExportLoading] = useState(false);
    const [selectedPanel, setSelectedPanel] = useState(navigateTab ? navigateTab : '');
    const config = useSelector(({ setting }) => setting.config);
    const licenseConfig = useSelector(({ setting }) => setting.licenseConfig);

    const toggleExpansionPanel = (panel) => (event, isExpanded) => {
        isExpanded = isExpanded ? panel : false;
        setSelectedPanel(isExpanded);
    };


    const exportReport = useCallback((organizationToExport) => {
        setExportLoading(true);
        const requestParams = {
            "organization": organizationToExport,
            "datasource": 0,
            "dataset": 0,
            "include_metadata": true,
            "include_profile": true,
            "include_curate": true
        };
        dispatch(exportOrganizationReport(requestParams)).then((response) => response && setExportLoading(false));
    }, [dispatch]);

    const isShowLicense = organizationId !== 0 ? !isSystemLicense : true;

    return (
        <Grid className={organizationId !== 0 ? classes.referenceContainer : classes.referenceContainer2}>
            {
                organizationId !== 0 &&
                <Grid className={classes.exportButton}>
                    <TooltipComponent title={"Export Organization"} arrow>
                        <IconButton variant="contained" color="primary" onClick={() => exportReport(organizationId)}>
                            <svg fill={theme.palette.primary.main} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24.342 24.106">
                                <g id="upload_button" transform="translate(-1568.963 -266.973)">
                                    <g id="Group_2" data-name="Group 2">
                                        <path id="Path_2" data-name="Path 2" d="M1593.3,291.079h-24.342V276.71h2v12.369H1591.3V276.71h2Z" />
                                        <path id="Path_3" data-name="Path 3" d="M1586.4,272.323l-1.41,1.42-3.03-3v10.78h-2v-10.7l-2.87,2.84-1.41-1.42,5.32-5.27Z" />
                                    </g>
                                </g>
                            </svg>
                            {isExportLoading && <Loader size={'small'} type={'button'} classList={classes.btnLoader} />}
                        </IconButton>
                    </TooltipComponent>
                </Grid>
            }
            {
                organizationId !== 0 && config.api &&
                <Accordion expanded={selectedPanel === "Api"} onChange={toggleExpansionPanel("Api")} className={classes.storageAcoordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography className={classes.heading}>
                            {"Api"}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid className={classes.accordianSection}>
                            <Api organizationId={organizationId} className={classes.paddingLR10} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            {
                isShowLicense &&
                <Accordion expanded={selectedPanel === "License"} onChange={toggleExpansionPanel("License")} className={classes.storageAcoordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography className={classes.heading}>
                            {"License"}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.displayBlock}>
                        <Grid className={classes.accordianSection}>
                            <License organizationId={organizationId} className={classes.paddingLR10} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            <Accordion expanded={selectedPanel === "Storage"} onChange={toggleExpansionPanel("Storage")} className={classes.storageAcoordion}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <Typography className={classes.heading}>
                        {"Storage"}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.displayBlock}>
                    <Grid className={classes.accordianSection}>
                        <Storage organizationId={organizationId} isOrganization={isOrganization} isSystemLicense={isSystemLicense} />
                    </Grid>
                </AccordionDetails>
            </Accordion>
            {
                licenseConfig.custom_fields &&
                <Accordion expanded={selectedPanel === "Fields"} onChange={toggleExpansionPanel("Fields")} className={classes.storageAcoordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography className={classes.heading}>
                            {"Fields"}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.displayBlock}>
                        <Grid className={classes.accordianSection}>
                            <Fields organizationId={organizationId} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }

            {
                licenseConfig.import_export_metadata &&
                <Accordion expanded={selectedPanel === "Import Metadata Properties"} onChange={toggleExpansionPanel("Import Metadata Properties")} className={classes.storageAcoordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography className={classes.heading}>
                            {"Import"}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.displayBlock}>
                        <Grid className={classes.accordianSection}>
                            <ImportSettings organizationId={organizationId} history={history} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }

            {
                licenseConfig.import_export_metadata &&
                <Accordion expanded={selectedPanel === "Export Metadata Properties"} onChange={toggleExpansionPanel("Export Metadata Properties")} className={classes.storageAcoordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography className={classes.heading}>
                            {"Export"}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.displayBlock}>
                        <Grid className={classes.accordianSection}>
                            <ExportMetadata organizationId={organizationId} isOrganization={isOrganization} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }

            {
                licenseConfig.export_metrics &&
                <Accordion expanded={selectedPanel === "Export DQ Metrics"} onChange={toggleExpansionPanel("Export DQ Metrics")} className={classes.storageAcoordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography className={classes.heading}>
                            {"Export DQ Metrics"}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.displayBlock}>
                        <Grid className={classes.accordianSection}>
                            <ExportDQMetrics organizationId={organizationId} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            {
                organizationId !== 0 &&
                <Accordion expanded={selectedPanel === "SSO"} onChange={toggleExpansionPanel("SSO")} className={classes.storageAcoordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography className={classes.heading}>
                            {"SSO"}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.displayBlock}>
                        <Grid className={classes.accordianSection}>
                            <SSO organizationId={organizationId} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }
            {
                organizationId !== 0 &&
                <Accordion expanded={selectedPanel === "LDAP"} onChange={toggleExpansionPanel("LDAP")} className={classes.storageAcoordion}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2a-content"
                        id="panel2a-header">
                        <Typography className={classes.heading}>
                            {"LDAP"}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.displayBlock}>
                        <Grid className={classes.accordianSection}>
                            <Ldap organizationId={organizationId} />
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            }

        </Grid>
    );
};

System.propTypes = {
    classes: PropTypes.object,
    organizationId: PropTypes.number,
    isOrganization: PropTypes.bool,
    navigateTab: PropTypes.string,
    isSystemLicense: PropTypes.bool,
    history: PropTypes.object
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}))(System);