import React, { Fragment } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import DashboardOverAllScore from './DashboardOverAllScore.jsx';
import DashboardGovernance from './DashboardGovernance.jsx';
import DashboardMetrics from './DashboardMetrics.jsx';

import DashboardMostView from './DashboardMostView.jsx';
import UserAnalysis from './UserAnalysis.jsx';
import DQScoreAnalysis from './DQScoreAnalysis.jsx';
import DashboardRelationShip from './DashboardRelationShip.jsx';
import DashboardTimeline from './DashboardTimeline.jsx';
import DashboardConversation from './DashboardConversation.jsx';
import DashboardSensitivity from './DashboardSensitivity.jsx';
import DashboardDistribution from './DashboardDistribution.jsx';
import DashboardDomain from './DashboardDomain.jsx';
import DashboardDomainAsset from './DashboardDomainAsset.jsx';

import { ValidatorForm } from 'react-material-ui-form-validator';
import DashboardJobStatus from './DashboardJobStatus.jsx';

const DashboardCharts = (props) => {
    const { classes, history } = props;
    const config = useSelector(({ setting }) => setting.config);
    return (
        <Grid container spacing={2} className={classnames(classes.dashboardContainer, classes.marginTop10)}>
            <Grid item sm={3}>
                <DashboardOverAllScore />
            </Grid>
            <Grid item sm={4}>
                <DashboardGovernance history={history} config={config} />
            </Grid>
            <Grid item sm={5}>
                <DashboardMetrics />
            </Grid>
            <Grid item xs={12}>
                <ValidatorForm form="form" onSubmit={() => null} style={{ height: '100%' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DashboardTimeline config={config} />
                        </Grid>
                        {
                            config.domain &&
                            <Fragment>
                                {
                                    config.profile &&
                                    <Grid item xs={12}>
                                        <DashboardDomain history={history} />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <DashboardDomainAsset />
                                </Grid>
                            </Fragment>
                        }
                        {
                            config.profile &&
                            <Grid item xs={9}>
                                <DashboardDistribution />
                            </Grid>
                        }
                        {
                            config.profile &&
                            <Grid item xs={3}>
                                <DQScoreAnalysis config={config} />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <DashboardRelationShip />
                        </Grid>
                        <Grid item xs={9}>
                            <DashboardMostView />
                        </Grid>
                        <Grid item xs={3}>
                            <DashboardSensitivity />
                        </Grid>
                        <Grid item xs={3}>
                            <UserAnalysis />
                        </Grid>
                        <Grid item xs={9}>
                            <DashboardConversation isConversation={config.conversation} />
                        </Grid>
                        <Grid item xs={12}>
                            <DashboardJobStatus />
                        </Grid>
                    </Grid>
                </ValidatorForm>
            </Grid>
        </Grid>
    );
};

DashboardCharts.propTypes = {
    history: PropTypes.object,
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    ...Styles(theme)
}))(DashboardCharts);