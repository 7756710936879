import React, { useCallback, useState, useEffect } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { ValidatorForm } from 'react-material-ui-form-validator';
import SemanticGlossaryInfo from './SemanticGlossaryInfo.jsx';
import { getGlossaryDetail, getBreadCrumbs } from '../../actions/modelActions';
import SemanticGlossaryCategoryList from './SemanticGlossaryCategoryList.jsx';
import SemanticFieldDetail from './SemanticFieldDetail.jsx';
import Loader from '../Loaders/Loader.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import Styles from '../../layouts/Styles.jsx';

const SemanticGlossaryDetail = (props) => {
    const { onChangeGlossary, onSave, redirect, glossaryNames, filters: { search = '' }, glossaryDetail, classes, attributes } = props;
    const defaultBreadCrumb = [{ "name": "Semantics", "path": "" }];
    const [breadcrumb, setBreadcrumb] = useState(defaultBreadCrumb);
    const [semanticInfo, setSemanticInfo] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const glossary = useSelector(({ model }) => model.selectedGlossary);
    const dispatch = useDispatch();

    const onChange = (property, value) => {
        onChangeGlossary(property, value);
        if (property === "name") {
            breadcrumb[breadcrumb.length - 1].name = value;
            setBreadcrumb([...breadcrumb]);
        }
    };

    const getDetail = useCallback((id, semanticType) => {
        setLoading(true);
        dispatch(getGlossaryDetail(id, semanticType)).then((response) => {
            if (response) {
                setSemanticInfo([...response]);
            }
            setLoading(false);
        });
    }, [dispatch]);

    const updateBreadBrumbs = useCallback(() => {
        setBreadcrumb([{ "name": "Semantics", "path": "" }, { "name": glossary.name, "path": glossary }]);
    }, [glossary]);

    const getCrumbs = useCallback(() => {
        dispatch(getBreadCrumbs(glossary.semantic_model ? glossary.semantic_model : glossary.semantic_model_id, glossary.id)).then((response) => {
            if (response) {
                const crumb = response.map((data) => {
                    return {
                        "name": data.name,
                        "path": data
                    };
                });
                crumb.unshift({ "name": "Semantics", "path": "" });
                setBreadcrumb([...crumb]);
            }
        });
    }, [dispatch, glossary.id, glossary.semantic_model, glossary.semantic_model_id]);

    const callGlossaryDetail = useCallback(() => {
        if (glossary.semantic_type) {
            if (glossary.semantic_type !== "field") {
                getDetail(glossary.id, glossary.semantic_type);
            }
            if (glossary.semantic_type === "glossary") {
                updateBreadBrumbs();
            } else {
                getCrumbs();
            }
        }
    }, [getCrumbs, getDetail, glossary.id, glossary.semantic_type, updateBreadBrumbs]);


    useEffect(() => {
        callGlossaryDetail();
    }, [callGlossaryDetail]);

    return (
        <Grid className={classes.container}>
            {
                Object.keys(glossary).length ?
                    <ValidatorForm name="glossary Detail" onSubmit={() => null}>
                        <SemanticGlossaryInfo
                            breadcrumb={breadcrumb}
                            glossary={glossaryDetail}
                            onChange={(property, value) => onChange(property, value)}
                            semanticInfo={[...semanticInfo]}
                            onSave={(property) => onSave(property)}
                            redirect={(data) => redirect(data)}
                            glossaryNames={glossaryNames} />
                        {
                            glossary.semantic_type !== "field" ?
                                <SemanticGlossaryCategoryList categories={semanticInfo} redirect={(data) => redirect(data)} filters={props.filters} />
                                :
                                <SemanticFieldDetail glossary={glossary} search={search} attributes={attributes} dataType={glossaryDetail.datatype} />
                        }
                    </ValidatorForm> :
                    <NoResultFound />
            }
            {isLoading && <Loader />}
        </Grid>
    );

};

SemanticGlossaryDetail.propTypes = {
    onChangeGlossary: PropTypes.func,
    onSave: PropTypes.func,
    redirect: PropTypes.func,
    glossaryNames: PropTypes.array,
    filters: PropTypes.object,
    glossaryDetail: PropTypes.object,
    classes: PropTypes.object,
    attributes: PropTypes.array
};

export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(SemanticGlossaryDetail);