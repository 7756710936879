import React from 'react';
import { Grid, withStyles, Typography, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import DomainStyles from './DomainStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import { useDispatch } from 'react-redux';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const MonitoringCard = (props) => {
    const { classes, monitoring, dq_score_threshold, redirectSource } = props;

    return (
        <Grid
            container
            key={monitoring.monitoring_id}
            className={classNames(classes.domainCardContainer)}
        // style={{ borderColor: getColor(monitoring.dqscore ? monitoring.dqscore : 0, dq_score_threshold) }}
        >
            <Grid item xs={12}>

                <Grid container alignItems="center" justify="space-between" wrap="nowrap">
                    <Grid item xs={10}>
                        <Typography variant="h5" component="h5" onClick={() => redirectSource(monitoring)} className={classNames(classes.cursor, classes.domainAttributetxt)}>
                            {monitoring.monitoring_name ? monitoring.monitoring_name : ""}
                        </Typography>
                        <Grid className={classes.sourceTitleSection}>
                            <Typography className={classNames(classes.domainTxt)}>
                                {monitoring.monitoring_type ? monitoring.monitoring_type : "None"}
                            </Typography>
                            <Typography className={classNames(classes.domainTxt, classes.domainSplit)}>
                                {" | "}
                            </Typography>
                            <Typography className={classNames(classes.domainTxt)}>
                                {monitoring.monitoring_run_time ? monitoring.monitoring_run_time : 'N/A'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} align="center">
                        <div className={classes.domainMonitoringDifference}>
                            {/* <ToolTipComponent title={((monitoring?.source_count || 0) - (monitoring?.target_count || 0)) || 'N/A'} arrow> */}
                                <Typography>
                                    {monitoring.percentage ? `${parseFloat(monitoring.percentage).toFixed(2)}%(${((monitoring?.source_count || 0) - (monitoring?.target_count || 0))})` : "NIL"}
                                </Typography>
                            {/* </ToolTipComponent> */}
                        </div>
                        {/* <Circular
                        showPercentage={Boolean(monitoring.dqscore && monitoring.dqscore !== 0)}
                        showValue
                        value={monitoring.dqscore}
                        dqScoreThreshold={monitoring.dq_score_threshold && Object.keys(monitoring.dq_score_threshold).length > 0 ? monitoring.dq_score_threshold : dq_score_threshold}
                    /> */}
                    </Grid>
                </Grid>
            </Grid>
            {/* </Grid>
            {
                monitoring.description.length > 0 &&
                <Typography className={classNames(classes.domainTxt, classes.marginTop15, "description")}>
                    {monitoring.description ? monitoring.description : ""}
                </Typography>
            } */}
            <Grid item xs={12}>
                <Grid container alignItems="center" justify="space-between" className={classes.marginTop15}>
                    <Grid item title={monitoring.source_dataset_name ? monitoring.source_dataset_name : ''}>
                        <Typography className={classes.domainTxt}>
                            Source row count
                        </Typography>
                        <ToolTipComponent title={monitoring?.source_dataset_name || 'N/A'} arrow>
                            <Typography variant="h6" component="h6" className={classes.cardCountText}>
                                {monitoring.source_count ? monitoring.source_count : 0}
                            </Typography>
                        </ToolTipComponent>
                    </Grid>
                    <Grid item title={monitoring.targe_dataset_name ? monitoring.targe_dataset_name : ''}>
                        <Typography className={classes.domainTxt}>
                            Target row count
                        </Typography>
                        <ToolTipComponent title={monitoring?.targe_dataset_name || 'N/A'} arrow>
                            <Typography variant="h6" component="h6" className={classes.cardCountText}>
                                {monitoring.target_count ? monitoring.target_count : 0}
                            </Typography>
                        </ToolTipComponent>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

MonitoringCard.propTypes = {
    classes: PropTypes.object,
    monitoring: PropTypes.object,
    dq_score_threshold: PropTypes.object,
    redirectSource: PropTypes.func
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(MonitoringCard);