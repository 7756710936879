import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import RuleBuilderModal from '../Dataset/Properties/ListView/RuleBuilderModal.jsx';
import ChipInput from '.././ChipInput/ChipInput.jsx';


const SemanticGlossaryRules = (props) => {
    const { ruleList, updateCustomRules, field, defaultFieldType, deleteCustomRules } = props;
    const [rules, setRules] = useState([]);
    const [selectedRule, setSelectedRule] = useState(null);
    const [selectedRuleIndex, setSelectedRuleIndex] = useState(-1);
    const [anchorEl, setAnchorElement] = useState('');

    const addRule = () => {
        const rule = {
            priority: 1000,
            fieldtypes: [field],
            params: [],
            name: '',
            description: '',
            isvalidrule: false,
            type: 'profile',
            polarity: 'positive',
            attributeType: defaultFieldType,
            'fieldtypes_except': [],
            'is_active': true,
            'class_name': 'CustomRule',
            'allow_dqscore': true,
            'is_user_defined': false,
            'rule_type': 'custom',
            'is_complex_rule': false,
            "is_sql_editor": false,
            "is_semantic_rule": true
        };
        setSelectedRule({ ...rule });
        setSelectedRuleIndex(-1);
        setAnchorElement(anchorEl);
    };


    const onEditRule = useCallback((rule, index, anchorEl) => {
        setSelectedRule({ ...rule });
        setSelectedRuleIndex(index);
        setAnchorElement(anchorEl);
    }, []);


    const deleteRule = useCallback((ruleIndex) => {
        const attributeRules = rules;
        const deletedRule = { ...attributeRules[ruleIndex] };
        deleteCustomRules(deletedRule);
        attributeRules.splice(ruleIndex, 1);
        setRules([...attributeRules]);
        updateCustomRules([...attributeRules]);
    }, [deleteCustomRules, rules, updateCustomRules]);

    const resetSelectedAttribute = useCallback(() => {
        setSelectedRule(null);
        setSelectedRuleIndex(-1);
        setAnchorElement(null);
    }, []);

    const onSave = useCallback((rule) => {
        if (rule.name.trim() !== "") {
            const attributeRules = rules;
            if (selectedRuleIndex <= -1) {
                attributeRules.splice(0, 0, { ...rule });
            } else {
                attributeRules.splice(selectedRuleIndex, 1, { ...rule });
            }
            setRules([...attributeRules]);
            updateCustomRules([...attributeRules]);
            resetSelectedAttribute();
        }
    }, [resetSelectedAttribute, rules, selectedRuleIndex, updateCustomRules]);

    const loadRules = useCallback(() => {
        setRules([...ruleList]);
    }, [ruleList]);

    useEffect(() => {
        loadRules(ruleList);
    }, [loadRules, ruleList]);


    return (
        <Grid container direction="column">
            <ChipInput
                addtooltiptitle="Add Rules"
                values={rules ? rules : []}
                displayName="name"
                displayCount={2}
                name="patterns"
                enableAddButton
                isEditable
                isEditPermission
                onClickAdd={(event) => addRule(event.target)}
                onClickEdit={(rule, index, event) => onEditRule(rule, index, event.target)}
                onDelete={(index) => deleteRule(index)}
                isDeletePermission />
            {
                selectedRule &&
                <RuleBuilderModal open={Boolean(selectedRule)}
                    anchroEl={anchorEl}
                    rule={selectedRule}
                    properties={{}}
                    isSemanticBuilder
                    defaultField={defaultFieldType}
                    onSave={(rule) => onSave(rule)}
                    onClose={() => resetSelectedAttribute()}
                />
            }
        </Grid>
    );
};

SemanticGlossaryRules.propTypes = {
    ruleList: PropTypes.array,
    updateCustomRules: PropTypes.func,
    field: PropTypes.string,
    defaultFieldType: PropTypes.string,
    deleteCustomRules: PropTypes.func
};

export default SemanticGlossaryRules;