const DashboardWidgetStyles = (theme) => ({
    root: {
        height: '100%!important'
    },
    dashboardTabs: {
        width: '100% !important',
        height: '100% !important'
    },
    customTabPanel: {
        width: '100% !important'
    }
});

export default DashboardWidgetStyles;