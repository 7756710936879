import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { InputAdornment, IconButton, withStyles } from "@material-ui/core";
import { TextValidator } from 'react-material-ui-form-validator';
import { Visibility, VisibilityOffOutlined } from "@material-ui/icons";
import Styles from '../../layouts/Styles.jsx';

const PasswordInput = (props) => {
	const { classes, onChange, password, InputProps, ...rest } = props;
	const [type, setType] = useState('password');

	const onValueChange = useCallback((event) => {
		const valueLength = event?.target?.value?.length ?? 0;
		let inputValue = password;
		if (inputValue?.length > valueLength) {
			inputValue = inputValue.slice(0, valueLength);
		} else {
			const value = event?.nativeEvent?.data ?? '';
			inputValue = `${password}${value}`;
			inputValue = (event?.nativeEvent?.inputType === 'insertFromPaste') ? event?.target?.value : inputValue;
		}
		if (onChange) {
			onChange(event, inputValue ? inputValue : '');
		}
	}, [password, onChange]);

	return (
		<TextValidator
			{...rest}
			autoComplete={"off"}
			onChange={(event) => onValueChange(event)}
			className={classes.passwordBox}
			type={type}
			value={type === 'password' ? "*".repeat(password?.length ?? 0) : password}
			InputProps={
				{
					...InputProps,
					endAdornment: (
						<InputAdornment position="end">
							<IconButton className={"adornment"} onClick={() => setType(type === 'password' ? 'text' : 'password')}>
							{
								type === 'password' ? (
									<VisibilityOffOutlined />
								) : (
									<Visibility />
								)
							}
							</IconButton>
						</InputAdornment>
					)
				}
			}
		/>
	);
};

PasswordInput.propTypes = {
	classes: PropTypes.object,
	onChange: PropTypes.func,
	password: PropTypes.string.isRequired,
	InputProps: PropTypes.object
};

export default withStyles((theme) => ({
    ...Styles (theme)
}))(PasswordInput);