import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Grid, withStyles } from '@material-ui/core';
import DashboardChartTile from './DashboardChartTile.jsx';
import DashboardStyles from './DashboardStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import { getOrganizationMetrics, getOrganizationMetricAnalysis } from '../../actions/scheduleActions';
import Loader from '../../components/Loaders/Loader.jsx';
import classNames from 'classnames';

const DashboardMetrics = (props) => {
    const { classes } = props;
    const [analysis, setAnalysis] = useState({});
    const [timelineAnalysis, setTimelineAnalysis] = useState({});
    const [isLoading, setLoading] = useState(true);
    const dispatch = useDispatch();

    const getMetrics = useCallback(() => {
        dispatch(getOrganizationMetrics()).then((response) => {
            if (response) {
                setAnalysis({ ...response });
                setLoading(false);
            }
        });
    }, [dispatch]);

    const getMetricAnalysis = useCallback(() => {
        dispatch(getOrganizationMetricAnalysis()).then((response) => {
            if (response) {
                const timelineAnalysis = {
                    datasource: [],
                    dataset: [],
                    attribute: [],
                    type: []
                };
                for (const analysis of response) {
                    timelineAnalysis.datasource.push(analysis.datasource_timeline);
                    timelineAnalysis.dataset.push(analysis.dataset_timeline);
                    timelineAnalysis.attribute.push(analysis.attribute_timeline);
                    timelineAnalysis.type.push(analysis.type_timeline);
                }
                setTimelineAnalysis({ ...timelineAnalysis });
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getMetrics();
        getMetricAnalysis();
    }, [getMetricAnalysis, getMetrics]);
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Grid className={classNames(classes.marginBottom15, classes.relative)}>
                    {isLoading && <Loader />}
                    <DashboardChartTile chartColor="#FF5826" chartClassName={"datasource-timeline"} type="Datasources" count={analysis ? analysis.datasource : 0} analysis={timelineAnalysis.datasource ? timelineAnalysis.datasource : []} />
                </Grid>
                <Grid className={classes.relative}>
                    {isLoading && <Loader />}
                    <DashboardChartTile chartColor="#EC286D" chartClassName={"attribute-timeline"} type="Attributes" count={analysis ? analysis.attribute : 0} analysis={timelineAnalysis.attribute ? timelineAnalysis.attribute : []} />
                </Grid>
            </Grid>
            <Grid item xs={6}>
                <Grid className={classNames(classes.marginBottom15, classes.relative)}>
                    {isLoading && <Loader />}
                    <DashboardChartTile chartColor="#5EB5EF" chartClassName={"dataset-timeline"} type="Datasets" count={analysis ? analysis.dataset : 0} analysis={timelineAnalysis.dataset ? timelineAnalysis.dataset : []} />
                </Grid>
                <Grid className={classes.relative}>
                    {isLoading && <Loader />}
                    <DashboardChartTile chartColor="#00CFDE" chartClassName={"type-timeline"} type="Types" count={analysis ? analysis.types : 0} analysis={timelineAnalysis.type ? timelineAnalysis.type : []} />
                </Grid>
            </Grid>
        </Grid>
    );
};

DashboardMetrics.propTypes = {
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DashboardMetrics);