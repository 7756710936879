const operators = [
    {
        name: "is equal to",
        value: "=="
    }, {
        name: "is not equal to",
        value: "!="
    }
];

export default operators;