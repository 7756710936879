const QueryEditorSQLFunctions = [
    {
        label: 'ABORT',
        operator: 'abort()',
        types: ['numeric', 'datetime', 'text'],
        valueRequired: false,
        description: "Query abort(Numeric | Datetime | String) Return message"
    }, {
        label: 'ABS',
        operator: 'abs()',
        types: ['numeric'],
        valueRequired: false,
        description: "Query abs(Numeric) Return Integer Value"
    }, {
        label: 'ASCII',
        operator: 'ascii()',
        types: ['text'],
        valueRequired: false,
        description: "Query ascii(String) Return Integer Value"
    }, {
        label: 'AVG',
        operator: 'avg()',
        types: ['numeric'],
        description: "Query avg(Numeric) Return Interger Value"
    }, {
        label: 'ANY',
        operator: 'any()',
        types: ['text', 'datetime', 'numeric'],
        description: "Query any(String | Datetime | Numeric)"
    }, {
        label: 'CEIL',
        operator: 'ceil()',
        types: ['numeric'],
        description: "Query ceil(column_name) Return Interger Value"
    }, {
        label: 'CHRCODE',
        operator: 'charcode()',
        types: ['text'],
        description: "Query charcode(column_name) Return Interger Value"
    }, {
        label: 'LOWER',
        operator: 'lower()',
        types: ['text'],
        description: "Query lower(String) Return all string as lowercase"
    }, {
        label: 'INITCAP',
        operator: 'initcap()',
        types: ['text'],
        description: "Query initcap(String) Return Capitalizes the first letter in each word of a string and converts all other letters to lowercase."
    }, {
        label: 'CHR',
        operator: 'chr()',
        types: ['numeric'],
        description: "Query chr(Numeric) Return The value you want to return as an ASCII or Unicode character"
    }, {
        label: 'FLOOR',
        operator: 'floor()',
        types: ['numeric'],
        description: "Query floor(Numeric) Return the largest integer less than or equal to the numeric value you pass to this function"
    }, {
        label: 'EXP',
        operator: 'exp()',
        types: ['numeric'],
        description: "Query exp(Numeric) Returns the largest integer less than or equal to the numeric value you pass to this function"
    }, {
        label: 'CONCAT',
        operator: 'concat()',
        types: ['text'],
        description: "Query concat(String , String). Concat 2 columns Return concat string"
    }, {
        label: 'DATE_DIFF',
        operator: 'datediff()',
        types: ['datetime'],
        description: "Query datediff(Datetime , End Date). Returns Numeric value"
    }, {
        label: 'INSTR',
        operator: 'instr()',
        types: ['text', 'numeric', 'datetime'],
        description: "Query instr(String | Datetime | Numeric). Returns the position of a character set in a string, counting from left to right."
    }, {
        label: 'LAST_DAY',
        operator: 'last_day()',
        types: ['datetime'],
        description: "Query last_day(Datetime). Returns the previous date"
    }, {
        label: 'LOG',
        operator: 'log()',
        types: ['numeric'],
        description: "Query log(Numeric, Exponent). Returns the logarithm of a numeric value"
    }, {
        label: 'MOD',
        operator: 'mod()',
        types: ['numeric'],
        description: "Query mod(Numeric, divisor). Returns the remainder of a division calculation. For example, MOD(8,5) returns 3."
    }, {
        label: 'ROUND',
        operator: 'round()',
        types: ['numeric'],
        description: "Query round(Numeric, precision). Rounds numbers to a specified number of digits or decimal places. You can also use ROUND to round dates"
    }, {
        label: 'LTRIM',
        operator: 'ltrim()',
        types: ['text'],
        description: "Query ltrim(String, trim_set). ltrim(LAST_NAME, 'S.')"
    }, {
        label: 'TRIM',
        operator: 'rtrim()',
        types: ['text'],
        description: "Query rtrim(trim_set, column). ltrim('S.',LAST_NAME)"
    }, {
        label: 'IN',
        operator: 'in()',
        types: ['numeric'],
        description: "Query in(Numeric). "
    }, {
        label: 'REPLACE',
        operator: 'replace()',
        types: ['text'],
        description: "Query replace(String, Value, Value)"
    }, {
        label: 'LPAD',
        operator: 'lpad()',
        types: ['text'],
        description: "Query lpad(String, Length, Secondary Text)"
    }, {
        label: 'RPAD',
        operator: 'rpad()',
        types: ['text'],
        description: "Query lpad(String, Length, Secondary Text)"
    }, {
        label: 'REG_EXTRACT',
        operator: 'regexp_extract()',
        types: ['text'],
        description: "Query regexp_extract(String, 'pattern', subPatternNum)"
    }, {
        label: 'TO_TIMESTAMP',
        operator: 'to_timestamp()',
        types: ['datetime'],
        description: "Query to_timestamp(Datetime, DateFormat)"
    }, {
        label: 'TRUNC',
        operator: 'trunc()',
        types: ['datetime'],
        description: "Query trunc(Datetime, DateFormat)"
    }, {
        label: 'COUNT',
        operator: 'count()',
        types: ['numeric'],
        description: "Query count(Numeric)"
    }, {
        label: 'STD_DEV',
        operator: 'stddev()',
        types: ['numeric'],
        description: "Query stddev(Numeric)"
    }, {
        label: 'SUM',
        operator: 'sum()',
        types: ['numeric'],
        description: "Query stddev(Numeric)"
    }, {
        label: 'VARIANCE',
        operator: 'variance()',
        types: ['numeric'],
        description: "Query variance(Numeric)"
    }, {
        label: 'MIN',
        operator: 'min()',
        types: ['numeric'],
        description: "Query min(Numeric)"
    }, {
        label: 'MAX',
        operator: 'max()',
        types: ['numeric'],
        description: "Query min(Numeric)"
    }, {
        label: 'MEDIAN',
        operator: 'mean()',
        types: ['numeric'],
        description: "Query mean(Numeric)"
    }, {
        label: 'SOUNDEX',
        operator: 'soundex()',
        types: ['text'],
        description: "Query soundex(String)"
    }, {
        label: 'SUBSTR',
        operator: 'substring()',
        types: ['text'],
        description: "Query substring(String, Numeric value)"
    }, {
        label: 'UPPER',
        operator: 'upper()',
        types: ['text'],
        description: "Query upper(String)"
    }, {
        label: 'FIRST',
        operator: 'first()',
        types: ['text', 'datetime', 'numeric'],
        description: "Query first(String | Numeric | Datetime)"
    }, {
        label: 'LAST',
        operator: 'last()',
        types: ['text', 'datetime', 'numeric'],
        description: "Query last(String | Numeric | Datetime)"
    }, {
        label: 'TO_DATE',
        operator: 'to_date()',
        types: ['datetime'],
        description: "Query to_date(Datetime, Format)"
    }, {
        label: 'IS_NULL',
        operator: 'isnull()',
        types: ['text', 'datetime', 'numeric'],
        description: "Query is_null(Datetime | Numeric | String)"
    }, {
        label: 'COS',
        operator: 'cos()',
        types: ['numeric'],
        description: "Query cos(Numeric)"
    }, {
        label: 'COSH',
        operator: 'cosh()',
        types: ['numeric'],
        description: "Query cosh(Numeric)"
    }, {
        label: 'CRC32',
        operator: 'crc32()',
        types: ['text', 'datetime', 'numeric'],
        description: "Query crc32(Numeric | String | Datetime)"
    }, {
        label: 'MD5',
        operator: 'md5()',
        types: ['text', 'datetime', 'numeric'],
        description: "Query md5(Numeric | String | Datetime)"
    }, {
        label: 'rand',
        operator: 'rand()',
        types: ['text', 'datetime', 'numeric'],
        description: "Query rand()"
    }, {
        label: 'reverse',
        operator: 'reverse()',
        types: ['text'],
        description: "Query reverse(String)"
    }, {
        label: 'SIGN',
        operator: 'sign()',
        types: ['numeric'],
        description: "Query sign(Numeric)"
    }, {
        label: 'SIN',
        operator: 'sin()',
        types: ['numeric'],
        description: "Query sin(Numeric)"
    }, {
        label: 'SINH',
        operator: 'sinh()',
        types: ['numeric'],
        description: "Query sinh(Numeric)"
    }, {
        label: 'SQRT',
        operator: 'sqrt()',
        types: ['numeric'],
        description: "Query sqrt(Numeric)"
    }, {
        label: 'TAN',
        operator: 'tan()',
        types: ['numeric'],
        description: "Query tan(Numeric)"
    }, {
        label: 'TANH',
        operator: 'tanh()',
        types: ['numeric'],
        description: "Query tanh(Numeric)"
    }, {
        label: 'IFF',
        operator: 'if()',
        types: ['numeric', 'datetime', 'text'],
        description: "Query if(Numeric | Datetime | Text)"
    }, {
        label: 'ENCODE',
        operator: 'encode()',
        types: ['numeric', 'datetime', 'text'],
        description: "Query encode(Numeric | Datetime | Text , encode_type)"
    }, {
        label: 'DECODE',
        operator: 'decode()',
        types: ['numeric', 'datetime', 'text'],
        description: "Query decode(Numeric | Datetime | Text , decode_type)"
    }, {
        label: 'LENGTH',
        operator: 'length()',
        types: ['numeric', 'datetime', 'text'],
        description: "Query length(Numeric | Datetime | Text)"
    }, {
        label: 'LAG',
        operator: 'lag()',
        types: ['numeric'],
        description: "Query lag(Numeric)"
    }, {
        label: 'LEAST',
        operator: 'least()',
        types: ['numeric'],
        description: "Query least(Numeric | String | Datetime)"
    }, {
        label: 'GREATEST',
        operator: 'greatest()',
        types: ['numeric'],
        description: "Query greatest(Numeric | String | Datetime)"
    }, {
        label: 'ARRAY',
        operator: 'array()',
        types: ['numeric', 'datetime', 'text'],
        description: "Query array(Numeric | String | Datetime)"
    }, {
        label: 'PERCENTILE',
        operator: 'percentile()',
        types: ['numeric'],
        description: "Query percentile(Numeric, Numeric value)"
    }, {
        label: 'SIZE',
        operator: 'size()',
        types: ['text'],
        description: "Query size(array of string)"
    }, {
        label: 'GET_TIMESTAMP',
        operator: 'get_timestamp()',
        types: ['datetime'],
        description: "Query get_timestamp(Date) Returns value as integer"
    }, {
        label: 'INDEXOF',
        operator: 'indexof()',
        types: ['text'],
        description: "Query indexof(String, Value) Returns the index of matched value"
    }, {
        label: 'CHOOSE',
        operator: 'choose()',
        types: ['text'],
        description: "Query choose(String, Value) Returns String value for given index"
    }, {
        label: 'CHARCODE',
        operator: 'charcode()',
        types: ['text', 'numeric'],
        description: "Query charcode(String | Numeric) Returns ascii code for value first character"
    }, {
        label: 'TO_INTEGER',
        operator: 'to_integer()',
        types: ['text', 'numeric'],
        description: "Query to_integer(String | Numeric)"
    }, {
        label: 'TO_FLOAT',
        operator: 'to_float()',
        types: ['text', 'numeric'],
        description: "Query to_float(String | Numeric)"
    }, {
        label: 'TO_BIGINT',
        operator: 'to_bigint()',
        types: ['text', 'numeric'],
        description: "Query to_bigint(String | Numeric)"
    }, {
        label: 'TO_CHAR(Numeric)',
        operator: 'to_char()',
        types: ['numeric'],
        description: "Query to_char(Numeric)"
    }, {
        label: 'TO_CHAR(Date)',
        operator: 'to_char_date()',
        types: ['datetime'],
        description: "Query to_char_date(Date, Date format)"
    }, {
        label: 'TO_DECIMAL',
        operator: 'to_decimal()',
        types: ['text', 'numeric'],
        description: "Query to_decimal(String | Numeric , Decimal Count)"
    }, {
        label: 'TO_DECIMAL38',
        operator: 'to_decimal()',
        types: ['text', 'numeric'],
        description: "Query to_decimal(String | Numeric , Decimal Count)"
    }, {
        label: 'IS_DATE',
        operator: 'is_date()',
        types: ['text', 'datetime'],
        description: "Query to_decimal(String | Date). Return Boolean value"
    }, {
        label: 'IS_NUMBER',
        operator: 'is_numeric()',
        types: ['numeric', 'text'],
        description: "Query is_number(String | Numeric). Return Boolean value"
    }, {
        label: 'IS_SPACES',
        operator: 'is_spaces()',
        types: ['text'],
        description: "Query is_spaces(String). Return Boolean value"
    }, {
        label: 'STRUCT',
        operator: 'struct()',
        types: ['text', 'numeric', 'datetime'],
        description: "Query struct(String | Numeric | Datetime). Return as object"
    }, {
        label: 'STRUCT_AS',
        operator: 'struct(,)',
        types: ['text', 'numeric', 'datetime'],
        description: "Query struct(String | Numeric | Datetime). Return as object"
    }, {
        label: 'REG_MATCH',
        operator: 'rlike',
        types: ['text', 'numeric', 'datetime'],
        description: "Query Column rlike Pattern."
    }, {
        label: 'CAST',
        operator: 'cast()',
        types: ['text', 'numeric', 'datetime'],
        description: "Query cast(String | Numeric | Datetime as Datatype). Convert as given datatype"
    }, {
        label: 'COLLECT_LIST',
        operator: 'collect_list()',
        types: ['text', 'numeric', 'datetime'],
        description: "Query collect_list(String | Numeric | Datetime). Returns as array"
    }, {
        label: 'IN',
        operator: 'in()',
        types: ['text'],
        description: "Query in(String, value). Returns as Boolean value"
    }, {
        label: 'ENC_BASE64',
        operator: 'enc_base64()',
        types: ['text', 'datetime', 'numeric'],
        description: "Query enc_base64(String, value). Returns encrypted base64"
    }, {
        label: 'DEC_BASE64',
        operator: 'dec_base64()',
        types: ['text', 'datetime', 'numeric'],
        description: "Query dec_base64(String, value). Returns decrypted base64"
    }, {
        label: 'LIKE',
        operator: 'like',
        types: ['text', 'datetime', 'numeric'],
        description: "Query Column like Pattern."
    }, {
        label: 'TO_DATE',
        operator: 'to_date()',
        types: ['datetime'],
        description: "Query to_date(Date,  Format)"
    }, {
        label: 'CREATE_TIMESTAMP_TZ',
        operator: 'create_timestamp_tz()',
        types: ['datetime'],
        description: "Query create_timestamp_tz(Date,  Format)"
    }, {
        label: 'ADD_DATE',
        operator: 'add_date()',
        types: ['datetime'],
        description: "Query add_date(Date,  Format)"
    }, {
        label: 'POWER',
        operator: 'power()',
        types: ['numeric'],
        description: "Query power(Numeric,  Numeric)"
    }, {
        label: 'LN',
        operator: 'ln()',
        types: ['numeric'],
        description: "Query ln(Numeric)"
    }, {
        label: 'CONVERT_BASE',
        operator: 'convert_base()',
        types: ['numeric'],
        description: "Query convert_base(Numeric, convert value)"
    }, {
        label: 'UUID4',
        operator: 'uuid4()',
        types: [],
        description: "Query uuid4()"
    }, {
        label: 'UUID_UNPARSE',
        operator: 'uuid_unparse()',
        types: ["text"],
        description: "Query uuid_unparse(String)"
    }, {
        label: 'PV',
        operator: 'pv()',
        types: ["text"],
        description: "Query pv(rate,nper,payment,fv)"
    }, {
        label: 'FV',
        operator: 'fv()',
        types: ["text"],
        description: "Query fv(rate,nper,payment,fv)"
    }, {
        label: 'RATE',
        operator: 'rate()',
        types: ["text"],
        description: "Query rate(rate,nper,payment,fv)"
    }, {
        label: 'PMT',
        operator: 'pmt()',
        types: ["text"],
        description: "Query pmt(rate,nper,payment,fv)"
    }, {
        label: 'NPER',
        operator: 'nper()',
        types: ["text"],
        description: "Query nper(rate,nper,payment,fv)"
    }, {
        label: 'NPER',
        operator: 'nper()',
        types: ["text"],
        description: "Query nper(rate,nper,payment,fv)"
    }, {
        label: 'Group by',
        operator: 'group by',
        types: ["text"],
        description: "Query group by"
    }, {
        label: 'Order by',
        operator: 'order by',
        types: ["text"],
        description: "Query order by"
    }, {
        label: 'IS_SSN',
        operator: 'is_ssn()',
        types: ["text"],
        description: "Query is_ssn()"
    }, {
        label: 'ISPII',
        operator: 'ispii()',
        types: ["text"],
        description: "Query ispii()"
    }, {
        label: 'Whole_Number',
        operator: 'whole_number()',
        types: ["text"],
        description: "Query whole_number()"
    }, {
        label: 'IS_HOME_PHONE',
        operator: 'is_home_phone()',
        types: ["text"],
        description: "Query is_home_phone()"
    }, {
        label: 'IS_EMAIL',
        operator: 'is_email()',
        types: ["text"],
        description: "Query is_email()"
    }, {
        label: 'IS_PARENT_ACCOUNT',
        operator: 'is_parent_account()',
        types: ["text"],
        description: "Query is_parent_account()"
    }, {
        label: 'IS_CITY',
        operator: 'is_city()',
        types: ["text"],
        description: "Query is_city()"
    }, {
        label: 'IS_STATE',
        operator: 'is_state()',
        types: ["text"],
        description: "Query is_state()"
    }, {
        label: 'IS_SEX',
        operator: 'is_sex()',
        types: ["text"],
        description: "Query is_sex()"
    }, {
        label: 'IS_TITLE',
        operator: 'is_title()',
        types: ["text"],
        description: "Query is_title()"
    }, {
        label: 'IS_LOCATOR',
        operator: 'is_locator()',
        types: ["text"],
        description: "Query is_locator()"
    }, {
        label: 'IS_DUPLICATE',
        operator: 'is_duplicate()',
        types: ["text"],
        description: "Query is_duplicate()"
    }, {
        label: 'IS_VALID_PHONE_NUMBER',
        operator: 'is_valid_phone_number()',
        types: ["text"],
        description: "Query is_valid_phone_number()"
    }, {
        label: 'IS_VALID_ZIP_CODE',
        operator: 'is_valid_zip_code()',
        types: ["text"],
        description: "Query is_valid_zip_code()"
    }, {
        label: 'IS_NEGATIVE',
        operator: 'is_negative()',
        types: ["text"],
        description: "Query is_negative()"
    }
];
export default QueryEditorSQLFunctions;