/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar, Grid, withStyles, MenuItem, Popover, Typography, Badge } from '@material-ui/core';

import Styles from '../../layouts/Styles.jsx';
import CommonStyles from './CommonStyles.jsx';

import Logo from '../../assets/images/headerLogo.svg';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { appConstants } from '../../constants/appConstants';
import { returnImage, getMenuPath } from '../../helpers/appHelpers';
import { useSelector, useDispatch } from 'react-redux';
import Divider from '@material-ui/core/Divider';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import { clearNotification, navigatePage, switchCatalog } from '../../actions/notificationActions';
import { getLicenseInfo } from '../../actions/settingActions';
/*
 * import SocketService from '../../services/socketService.js';
 * import { loadLineage } from '../../actions/lineageActions';
 */

// import { toggleSidePanel } from '../../actions/notificationActions';

const Header = (props) => {
	const { classes, logout, history, theme } = props;
	const [open, enablePopover] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const config = useSelector(({ setting }) => setting.config);
	const licenseInfo = useSelector(({ setting }) => setting.licenseInfo);
	const notificationCount = useSelector(({ notification }) => notification.notificationCount);
	const navigateReducePath = useSelector(({ notification }) => notification.navigation);
	const organizationId = useSelector(({ account }) => account.user.organization);
	const navigationPath = navigateReducePath !== "" ? navigateReducePath : sessionStorage.getItem('navigationPath');
	let selectedPage = navigationPath ? navigationPath : "catalog";
	if (window.location.href.indexOf("notification") > -1) {
		selectedPage = "notification";
	}
	const currentURL = window.location.href;
	if (currentURL.includes('dashboard')) {
		selectedPage = 'dashboard';
	}
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getLicenseInfo(organizationId));
	}, [dispatch, organizationId]);


	const navigate = (path, title) => {
		dispatch(clearNotification());
		if (path !== "") {
			sessionStorage.setItem('navigationPath', title);
			localStorage.setItem('newNotificationCount', notificationCount);
			if (path === "catalog") {
				dispatch(switchCatalog(true));
			}
			dispatch(navigatePage(title));
			history.push(`/${path}`);
		}
	};

	const handleToggle = () => {
		enablePopover(!open);
	};

	useEffect(() => {
		dispatch(getLicenseInfo(organizationId));
	}, [dispatch, organizationId]);

	const openPopOver = (event) => {
		setAnchorEl(event.target);
		enablePopover(true);
	};

	const userLogout = () => {
		/*
		 * const socket = new SocketService();
		 * socket.disconnectSocket();
		 */
		logout();
	};

	return (
		<AppBar position="static" color="inherit" className={classes.LeftMenu}>
			<Toolbar>
				<Grid container className={classes.container} alignContent="space-between" justify="space-evenly">
					<Grid item>
						<Grid item className={classes.HeaderMenuicon}>
							<TooltipComponent title={`${licenseInfo.client_name ? licenseInfo.client_name : 'DQLabs'}`} placement="right" arrow>
								<a onClick={() => navigate(getMenuPath(config), getMenuPath(config))} className={selectedPage === 'dashboard' ? 'active' : ''}>
									<img className={classes.logo} src={theme && theme.palette.logo !== "" ? returnImage(appConstants.API_BASE_URL, theme.palette.logo) : Logo} alt="logo" />
								</a>
							</TooltipComponent>
						</Grid>
						{
							config.dashboard &&
							<Grid item className={classes.Menuicon}>
								<TooltipComponent title="Dashboard" placement="right" arrow>
									<a onClick={() => navigate('dashboard', 'dashboard')} className={selectedPage === 'dashboard' ? 'active' : ''}>
										<svg xmlns="http://www.w3.org/2000/svg" className={classes.MenuiconImg} width="24.845" height="24.845" viewBox="0 0 24.845 24.845">
											<g id="graph_1_" data-name="graph (1)" transform="translate(0 0)">
												<path id="Path_262" data-name="Path 262" d="M24.117,191.8h-.873V178.892A1.9,1.9,0,0,0,21.351,177H19.022a1.9,1.9,0,0,0-1.892,1.892V191.8h-1.65V181.737a1.585,1.585,0,0,0-1.583-1.583H10.948a1.585,1.585,0,0,0-1.583,1.583V191.8H7.715v-6.892a1.651,1.651,0,0,0-1.649-1.649H3.25A1.651,1.651,0,0,0,1.6,184.909V191.8H.728a.728.728,0,1,0,0,1.456H24.117a.728.728,0,1,0,0-1.456Zm-21.06,0v-6.892a.193.193,0,0,1,.193-.193H6.067a.193.193,0,0,1,.193.193V191.8Zm7.764,0V181.737a.127.127,0,0,1,.127-.127H13.9a.127.127,0,0,1,.127.127V191.8Zm7.764,0V178.892a.437.437,0,0,1,.437-.437h2.329a.437.437,0,0,1,.437.437V191.8Z" transform="translate(0 -168.411)" fill="#afb2b3" />
												<path id="Path_263" data-name="Path 263" d="M.76,9.317h1.4a2.281,2.281,0,0,0,4.422-.89L10.707,6.8A2.283,2.283,0,0,0,14.73,5.22l3.743-1.472a2.28,2.28,0,0,0,3.9-.74h1.769a.728.728,0,0,0,0-1.456H22.378a2.28,2.28,0,0,0-4.439.842L14.194,3.867a2.282,2.282,0,0,0-4.019,1.581L6.044,7.072a2.281,2.281,0,0,0-3.921.789H.76a.728.728,0,1,0,0,1.456ZM20.217,1.456a.825.825,0,1,1-.825.825A.826.826,0,0,1,20.217,1.456ZM12.453,4.513a.825.825,0,1,1-.825.825A.826.826,0,0,1,12.453,4.513ZM4.3,7.715a.825.825,0,1,1-.825.825A.826.826,0,0,1,4.3,7.715Z" transform="translate(-0.03)" fill="#afb2b3" />
											</g>
										</svg>
									</a>
								</TooltipComponent>
							</Grid>
						}
						{
							config.catalog &&
							<Grid item className={classes.Menuicon}>
								<TooltipComponent title="Catalog" placement="right" arrow>
									<a onClick={() => navigate('catalog', 'catalog')} className={selectedPage === 'catalog' ? 'active' : ''}>
										<svg xmlns="http://www.w3.org/2000/svg" className={classes.MenuiconImg} width="26.176" height="25.086" viewBox="0 0 26.176 25.086">
											<g id="security" transform="translate(0 -1)">
												<path id="Path_251" data-name="Path 251" d="M18.544,25.27a.547.547,0,0,1-.212-.043c-.26-.11-6.332-2.734-6.332-8.1V12.571a.546.546,0,0,1,.371-.517l6-2.025a.546.546,0,0,1,.349,0l6,2.025a.545.545,0,0,1,.371.517v4.558c0,5.364-6.073,7.988-6.332,8.1a.547.547,0,0,1-.212.043ZM13.091,12.963v4.165c0,4.127,4.423,6.5,5.453,6.995,1.03-.5,5.453-2.878,5.453-6.995V12.963l-5.453-1.842Z" transform="translate(1.088 0.816)" fill="#afb2b3" />
												<path id="Path_252" data-name="Path 252" d="M17.727,19.408H17.7a.541.541,0,0,1-.4-.2L15.12,16.476a.545.545,0,0,1,.852-.682l1.8,2.25,3.387-3.385a.545.545,0,1,1,.771.771l-3.817,3.817a.543.543,0,0,1-.385.16Z" transform="translate(1.36 1.224)" fill="#afb2b3" />
												<path id="Path_253" data-name="Path 253" d="M20.178,8.635H3.817A3.817,3.817,0,0,1,3.817,1h16.36a3.817,3.817,0,1,1,0,7.635ZM3.817,2.091a2.727,2.727,0,1,0,0,5.453h16.36a2.727,2.727,0,1,0,0-5.453Z" transform="translate(0 0)" fill="#afb2b3" />
												<path id="Path_254" data-name="Path 254" d="M4.636,6.272A1.636,1.636,0,1,1,6.272,4.636,1.638,1.638,0,0,1,4.636,6.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,4.636,4.091Z" transform="translate(0.272 0.181)" fill="#afb2b3" />
												<path id="Path_255" data-name="Path 255" d="M9.636,6.272a1.636,1.636,0,1,1,1.636-1.636A1.638,1.638,0,0,1,9.636,6.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,9.636,4.091Z" transform="translate(0.725 0.181)" fill="#afb2b3" />
												<path id="Path_256" data-name="Path 256" d="M10.361,14.635H3.817A3.817,3.817,0,0,1,3.817,7h16.36a3.74,3.74,0,0,1,2.321.792.546.546,0,0,1-.671.861,2.656,2.656,0,0,0-1.65-.562H3.817a2.727,2.727,0,0,0,0,5.453h6.544a.545.545,0,0,1,0,1.091Z" transform="translate(0 0.544)" fill="#afb2b3" />
												<path id="Path_257" data-name="Path 257" d="M4.636,12.272a1.636,1.636,0,1,1,1.636-1.636A1.638,1.638,0,0,1,4.636,12.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,4.636,10.091Z" transform="translate(0.272 0.725)" fill="#afb2b3" />
												<path id="Path_258" data-name="Path 258" d="M9.636,12.272a1.636,1.636,0,1,1,1.636-1.636A1.638,1.638,0,0,1,9.636,12.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,9.636,10.091Z" transform="translate(0.725 0.725)" fill="#afb2b3" />
												<path id="Path_259" data-name="Path 259" d="M10.361,20.635H3.817a3.817,3.817,0,0,1,0-7.635h6.544a.545.545,0,0,1,0,1.091H3.817a2.727,2.727,0,0,0,0,5.453h6.544a.545.545,0,1,1,0,1.091Z" transform="translate(0 1.088)" fill="#afb2b3" />
												<path id="Path_260" data-name="Path 260" d="M4.636,18.272a1.636,1.636,0,1,1,1.636-1.636A1.638,1.638,0,0,1,4.636,18.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,4.636,16.091Z" transform="translate(0.272 1.269)" fill="#afb2b3" />
												<path id="Path_261" data-name="Path 261" d="M9.636,18.272a1.636,1.636,0,1,1,1.636-1.636A1.638,1.638,0,0,1,9.636,18.272Zm0-2.181a.545.545,0,1,0,.545.545A.547.547,0,0,0,9.636,16.091Z" transform="translate(0.725 1.269)" fill="#afb2b3" />
											</g>
										</svg>
									</a>
								</TooltipComponent>
							</Grid>
						}

						{/* {
							config.lineage &&
							<Grid item className={classes.Menuicon}>
							 	<TooltipComponent title="Lineage" placement="right" arrow>
							 		<a onClick={() => dispatch(toggleSidePanel({ type: 'lineage', sidePanel: !sidePanelToggle }))} className={selectedPage === 'lineage' ? 'active' : ''}>
							 			<svg xmlns="http://www.w3.org/2000/svg" className={classes.MenuiconImg} width="22.6" height="21.9" viewBox="0 0 22.6 21.9" fill="#afb2b3">
							 				<g transform="translate(-0.2 -1)">
							 					<rect id="Rectangle_1221" data-name="Rectangle 1221" width="11.6" height="2" transform="translate(5.2 11)" />
							 					<rect id="Rectangle_1223" data-name="Rectangle 1223" width="5.6" height="2" transform="translate(11.2 1.2)" />
							 					<rect id="Rectangle_1225" data-name="Rectangle 1225" width="2.6" height="2.4" transform="translate(20.2 1)" />
							 					<rect id="Rectangle_1226" data-name="Rectangle 1226" width="2.6" height="2.4" transform="translate(20.2 10.7)" />
							 					<rect id="Rectangle_1228" data-name="Rectangle 1228" width="2.6" height="2.4" transform="translate(0.2 10.7)" />
							 					<rect id="Rectangle_1227" data-name="Rectangle 1227" width="2.6" height="2.4" transform="translate(20.2 20.5)" />
							 					<rect id="Rectangle_1222" data-name="Rectangle 1222" width="2" height="21.6" transform="translate(10.8 1.2)" />
							 					<rect id="Rectangle_1224" data-name="Rectangle 1224" width="5.6" height="2" transform="translate(11.2 20.8)" />
							 				</g>
							 			</svg>
							 		</a>
							 	</TooltipComponent>
							</Grid>
						} */}
						{
							config.action_items &&
							<Grid item className={classes.Menuicon}>
								<TooltipComponent title="Action Items" placement="right" arrow>
									<a onClick={() => navigate('actionitem', 'actionitem')} className={selectedPage === 'actionitem' ? 'active' : ''}>
										<svg xmlns="http://www.w3.org/2000/svg" className={classes.MenuiconImg} width="26" height="27.791" viewBox="0 0 26 27.791" fill="#afb2b3">
											<defs />
											<g transform="translate(-16.5 0)">
												<path d="M85.855,145H81.346a.846.846,0,0,0,0,1.691h4.509a.846.846,0,0,0,0-1.691Z" transform="translate(-60.392 -137.148)" />
												<path d="M85.855,225H81.346a.846.846,0,1,0,0,1.691h4.509a.846.846,0,1,0,0-1.691Z" transform="translate(-60.392 -212.816)" />
												<path d="M85.855,305H81.346a.846.846,0,0,0,0,1.691h4.509a.846.846,0,0,0,0-1.691Z" transform="translate(-60.392 -288.484)" />
												<path d="M36.8,14.923V7.49a4.047,4.047,0,0,0-1.165-2.851l-3.3-3.416,0,0A4.1,4.1,0,0,0,29.423,0H18.943A2.445,2.445,0,0,0,16.5,2.443V23.557A2.445,2.445,0,0,0,18.943,26H31.526A6.472,6.472,0,1,0,36.8,14.923ZM18.128,23.557V2.443a.815.815,0,0,1,.814-.814H29.423a2.459,2.459,0,0,1,1.741.728l3.3,3.416,0,0a2.428,2.428,0,0,1,.7,1.712v7.434a6.457,6.457,0,0,0-4.884,9.448H18.943a.815.815,0,0,1-.814-.814Zm17.858,2.605a4.831,4.831,0,1,1,4.885-4.831A4.836,4.836,0,0,1,35.986,26.163Z" />
												<path d="M334.041,354.2a.846.846,0,0,0-1.191.108l-1.427,1.712-.324-.647a.846.846,0,0,0-1.513.756l.9,1.8a.846.846,0,0,0,.659.462.86.86,0,0,0,.1.006.846.846,0,0,0,.649-.3l2.255-2.706A.846.846,0,0,0,334.041,354.2Z" transform="translate(-296.151 -334.919)" />
												<path d="M241.346,146.691h.9a.846.846,0,1,0,0-1.691h-.9a.846.846,0,0,0,0,1.691Z" transform="translate(-211.891 -137.148)" />
												<path d="M243.093,225.846a.846.846,0,0,0-.846-.846h-.9a.846.846,0,1,0,0,1.691h.9A.846.846,0,0,0,243.093,225.846Z" transform="translate(-211.891 -212.816)" />
											</g>
										</svg>
									</a>
								</TooltipComponent>
							</Grid>
						}
						{
							config.conversation &&
							<Grid item className={classes.Menuicon}>
								<TooltipComponent title="Conversations" placement="right" arrow>
									<a onClick={() => navigate('conversation', 'conversation')} className={selectedPage === 'conversation' ? 'active' : ''}>
										<svg xmlns="http://www.w3.org/2000/svg" width="30" height="25.12" className={classes.MenuiconImg} viewBox="0 0 30 25.12" fill="#afb2b3">
											<g transform="translate(-1 -3.44)">
												<path d="M2.87,28.56A1.88,1.88,0,0,1,1,26.69V11.31A3.88,3.88,0,0,1,4.87,7.44H23.12A3.88,3.88,0,0,1,27,11.31V21.56a3.88,3.88,0,0,1-3.87,3.88H7.91a.82.82,0,0,0-.48.16L4,28.19a1.89,1.89,0,0,1-1.13.37Zm2-19.12A1.88,1.88,0,0,0,3,11.31V26.44L6.23,24a2.84,2.84,0,0,1,1.68-.56H23.12A1.88,1.88,0,0,0,25,21.56V11.31a1.88,1.88,0,0,0-1.87-1.87Z" />
												<circle cx="1" cy="1" r="1" transform="translate(16.94 15.53)" />
												<circle cx="1" cy="1" r="1" transform="translate(13.06 15.53)" />
												<circle cx="1" cy="1" r="1" transform="translate(9.06 15.53)" />
												<path d="M30,18.44a1,1,0,0,1-1-1V7.31a1.88,1.88,0,0,0-1.87-1.87H7a1,1,0,0,1,0-2H27.13A3.88,3.88,0,0,1,31,7.31V17.44A1,1,0,0,1,30,18.44Z" />
											</g>
										</svg>
									</a>
								</TooltipComponent>
							</Grid>
						}

						{/* <Grid item className={classes.Menuicon}>
							<TooltipComponent title="Data Preparation" placement="right" arrow>
								<a onClick={() => navigate('datapreparation', 'DataPreparation')} className={selectedPage === 'DataPreparation' ? 'active' : ''}>
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="23.98" viewBox="0 0 24 23.98" className={classes.MenuiconImg}>
										<g id="workflow" transform="translate(0 -0.054)">
											<path id="Path_2345" data-name="Path 2345" d="M5.25,20H1.5A1.5,1.5,0,0,0,0,21.5v3.75a1.5,1.5,0,0,0,1.5,1.5H5.25a1.5,1.5,0,0,0,1.5-1.5V21.5A1.5,1.5,0,0,0,5.25,20ZM1.5,25.25V21.5H5.25v3.75Z" transform="translate(0 -12.466)" fill="#afb2b3" />
											<path id="Path_2346" data-name="Path 2346" d="M29.75,25.25V21.5a1.5,1.5,0,0,0-1.5-1.5H24.5A1.5,1.5,0,0,0,23,21.5v3.75a1.5,1.5,0,0,0,1.5,1.5h3.75A1.5,1.5,0,0,0,29.75,25.25ZM24.5,21.5h3.75v3.75H24.5Z" transform="translate(-14.375 -12.466)" fill="#afb2b3" />
											<path id="Path_2347" data-name="Path 2347" d="M51.25,20H47.5A1.5,1.5,0,0,0,46,21.5v3.75a1.5,1.5,0,0,0,1.5,1.5h3.75a1.5,1.5,0,0,0,1.5-1.5V21.5A1.5,1.5,0,0,0,51.25,20ZM47.5,25.25V21.5h3.75v3.75Z" transform="translate(-28.75 -12.466)" fill="#afb2b3" />
											<path id="Path_2348" data-name="Path 2348" d="M15.749,3.945a.788.788,0,0,0-1.17.255A.75.75,0,0,0,14.916,5.2l1.5.75a.75.75,0,0,0,.934-.221l1.125-1.5a.75.75,0,0,0-.15-1.05.784.784,0,0,0-1.155.289A5.25,5.25,0,0,0,7,5.284a.75.75,0,1,0,1.5,0,3.75,3.75,0,0,1,7.249-1.339Z" transform="translate(-4.375 0)" fill="#afb2b3" />
											<path id="Path_2349" data-name="Path 2349" d="M40.35,42.3a.75.75,0,0,0-.934-.221l-1.5.75a.75.75,0,0,0-.338,1.005.788.788,0,0,0,1.17.255A3.75,3.75,0,0,1,31.5,42.75a.75.75,0,0,0-1.5,0,5.25,5.25,0,0,0,10.17,1.811.784.784,0,0,0,1.155.289.75.75,0,0,0,.15-1.05Z" transform="translate(-18.75 -26.216)" fill="#afb2b3" />
											<path id="Path_2350" data-name="Path 2350" d="M50.414,9.529a.75.75,0,0,0,.979.071l3-2.25a.75.75,0,1,0-.9-1.2L51.014,8.025l-.667-.679A.75.75,0,0,0,49.289,8.4Z" transform="translate(-30.693 -3.716)" fill="#afb2b3" />
											<path id="Path_2351" data-name="Path 2351" d="M7.2,43.275,5.7,42.15a.75.75,0,0,0-.9,0L3.3,43.275a.75.75,0,0,0-.15,1.05c.364.5.975.3,1.35-.075V46.5a.75.75,0,1,0,1.5,0V44.25c.375.375.986.57,1.35.075a.75.75,0,0,0-.15-1.05Z" transform="translate(-1.875 -26.216)" fill="#afb2b3" />
											<circle id="Ellipse_1857" data-name="Ellipse 1857" cx="0.75" cy="0.75" r="0.75" transform="translate(2.625 22.534)" fill="#f38080" />
										</g>
									</svg>
								</a>
							</TooltipComponent>
						</Grid> */}
						{/* <Grid item className={classes.Menuicon}>
							<TooltipComponent title="Execution Logs" placement="right">
								<a href="#">
									<img src={Textfile} alt="Textfile" className={classes.MenuiconImg} />
								</a>
							</TooltipComponent>
						</Grid>
						<Grid item className={classes.Menuicon}>
							<TooltipComponent title="Action Items" placement="right">
								<a href="#">
									<img src={Notepadlist} alt="Notepadlist" className={classes.MenuiconImg} />
								</a>
							</TooltipComponent>
						</Grid> */}
						{
							config.search &&
							<Grid item className={classes.Menuicon}>
								<TooltipComponent title="Search" placement="right" arrow>
									<a onClick={() => navigate('search', 'search')} className={selectedPage === 'search' ? 'active' : ''}>
										<svg xmlns="http://www.w3.org/2000/svg" className={classes.MenuiconImg} width="24.943" height="24.939" viewBox="0 0 24.943 24.939" fill="#afb2b3">
											<g transform="translate(0 -0.035)">
												<path d="M10.039,20.1a10.014,10.014,0,0,0,6.154-2.114l6.636,6.636a1.248,1.248,0,0,0,1.764-1.764l-6.636-6.636A10.033,10.033,0,1,0,10.039,20.1ZM4.707,4.738a7.54,7.54,0,1,1,0,10.663h0A7.513,7.513,0,0,1,4.668,4.777l.039-.039Z" transform="translate(0 0)" />
											</g>
										</svg>
									</a>
								</TooltipComponent>
							</Grid>
						}
						{/* {
							config.master &&
							<Grid item className={classes.Menuicon}>
								<TooltipComponent title="Semantic Model" placement="right" arrow>
									<a onClick={() => navigate('semanticmodel', 'semanticmodel')} className={selectedPage === 'semanticmodel' ? 'active' : ''}>
										<svg version="1.1" viewBox="0 0 32 32" className={classes.MenuiconImg} fill="#afb2b3">
											<path
												d="M17.1,12.8l0.3,0.9l0.5-0.8c0.2-0.4,0.5-0.7,0.9-1c1-0.9,2.4-1,3.1-1.1v1.9h2H26v0h2v0v-2V8.3V6.6V6.3h-6.1v0.3
    v1.7v0.2l0,0c-1.3,0-3.1,0.3-4.6,1.6c-0.3,0.3-0.6,0.5-0.8,0.8l-0.2,0.3l0.2,0.3C16.7,11.9,16.9,12.3,17.1,12.8z M23.9,8.3H26v2.4
    h-2.1V8.3z M15,19.2l-0.3-0.9l-0.5,0.8c-0.2,0.4-0.5,0.7-0.9,1c-1,0.9-2.4,1-3.2,1.1v-1.5v-0.3H4v0.3v1.7v2.4v2h2h2.1v0h2v0v-2v-0.2
    l0,0c1.1,0,3-0.3,4.6-1.6c0.3-0.3,0.6-0.5,0.8-0.8l0.2-0.3l-0.2-0.3C15.4,20.1,15.1,19.7,15,19.2z M8.1,23.7H6v-2.4h2.1V23.7z
     M21.9,19.3v0.3v1.5c-0.7,0-0.9-0.1-0.9-0.1c-0.2,0-0.5-0.1-0.7-0.2c-0.7-0.2-1.3-0.6-1.8-1.1c-0.5-0.5-0.9-1.2-1.1-1.9
    c-0.2-0.6-0.2-1.3-0.2-1.9c0-0.4,0-0.8-0.1-1.2c-0.2-1.9-1-3.5-2.4-4.7c-0.9-0.7-2-1.2-3.2-1.5c-0.4-0.1-0.9-0.1-1.4-0.1l0,0V8.3
    V6.6V6.3H4v0.3v1.7v2.4v2h2h2.1v0h2v0v-1.8c1.2,0,2.2,0.3,2.9,0.8c1.2,0.9,1.9,2.2,1.9,4.2c0,2.3,0.5,3.8,1.5,5.1
    c1.2,1.5,3.1,2.4,5.2,2.5c0.1,0,0.2,0,0.3,0v0.3v2h2H26v0h2v0v-2v-2.4v-1.7v-0.3H21.9z M8.1,10.6H6V8.3h2.1V10.6z M26,23.7h-2.1
    v-2.4H26V23.7z" />
										</svg>
									</a>
								</TooltipComponent>
							</Grid>
						} */}
						{
							config.semantics &&
							<Grid item className={classes.Menuicon}>
								<TooltipComponent title="Semantics" placement="right" arrow>
									<a onClick={() => navigate('model', 'model')} className={selectedPage === 'model' ? 'active' : ''}>
										<svg id="Capa_1" enableBackground="new 0 0 512 512" fill="#afb2b3" className={classes.MenuiconImg} height="27" viewBox="0 0 512 512" width="27" xmlns="http://www.w3.org/2000/svg">
											<g>
												<g>
													<path d="m198.73 338.21c-2.096-5.031-8.015-7.499-13.06-5.41-5.036 2.086-7.488 8.032-5.4 13.06 2.091 5.035 8.007 7.493 13.05 5.41 5.029-2.077 7.505-8.032 5.41-13.06z" />
													<path d="m241.21 338.21c-2.096-5.03-8.018-7.498-13.06-5.41-5.028 2.082-7.497 8.033-5.41 13.06 2.09 5.036 8.023 7.49 13.06 5.41 5.029-2.077 7.505-8.032 5.41-13.06z" />
													<path d="m198.73 408.26c-2.067-5.042-8.036-7.49-13.06-5.41-5.041 2.088-7.484 8.027-5.4 13.06 2.086 5.037 8.02 7.482 13.05 5.4 5.026-2.08 7.506-8.019 5.41-13.05z" />
													<path d="m241.21 408.26c-2.065-5.037-8.039-7.499-13.06-5.41-5.029 2.093-7.498 8.019-5.41 13.06 2.085 5.034 8.031 7.487 13.06 5.41 5.027-2.076 7.503-8.036 5.41-13.06z" />
													<path d="m502 54.576h-116.994c-5.522 0-10 4.477-10 10v48.497h-50.509v-48.497c0-5.523-4.478-10-10-10h-116.994c-5.523 0-10 4.477-10 10v48.497h-50.509v-48.497c0-5.523-4.477-10-10-10h-116.994c-5.523 0-10 4.477-10 10v116.994c0 5.523 4.477 10 10 10h48.497v34.786c0 14.679 11.942 26.622 26.622 26.622h161.443v43.387h-85.47c-2.626 0-5.147 1.033-7.018 2.876l-1.569 1.545c-10.87 10.703-17.104 25.59-17.104 40.844 0 12.76 4.375 25.253 12.164 35.265-7.788 10.012-12.164 22.505-12.164 35.265 0 15.261 6.239 30.153 17.116 40.857l1.56 1.535c1.871 1.84 4.39 2.872 7.014 2.872h190.94c2.623 0 5.142-1.031 7.012-2.87l1.561-1.535c.001-.001.002-.001.003-.002 10.877-10.705 17.115-25.596 17.115-40.857 0-12.759-4.375-25.251-12.163-35.265 7.788-10.012 12.163-22.505 12.163-35.265 0-15.253-6.233-30.141-17.104-40.845l-1.571-1.547c-1.871-1.841-4.391-2.874-7.016-2.874h-85.47v-43.387h36.942c5.522 0 10-4.477 10-10s-4.478-10-10-10h-36.942v-41.408h47.936c5.522 0 10-4.477 10-10v-48.497h50.509v48.497c0 5.523 4.478 10 10 10h48.174v34.786c0 3.651-2.97 6.622-6.621 6.622h-35.076c-5.522 0-10 4.477-10 10s4.478 10 10 10h35.076c14.679 0 26.621-11.942 26.621-26.622v-34.786h48.82c5.522 0 10-4.477 10-10v-116.993c0-5.523-4.478-10-10-10zm-154.131 382.848h-182.615c-6.282-6.844-9.853-15.949-9.853-25.264s3.57-18.42 9.854-25.266h182.613c6.283 6.845 9.854 15.951 9.854 25.266s-3.571 18.42-9.853 25.264zm9.853-95.794c0 9.316-3.57 18.42-9.853 25.265h-182.615c-6.283-6.845-9.853-15.949-9.853-25.265 0-9.315 3.57-18.42 9.854-25.266h182.613c6.283 6.845 9.854 15.95 9.854 25.266zm-337.722-267.054h96.994v96.994h-48.487c-.003 0-.006 0-.01 0s-.006 0-.01 0h-48.487zm226.562 158.401h-161.443c-3.651 0-6.622-2.97-6.622-6.622v-34.786h48.497c5.523 0 10-4.477 10-10v-48.497h50.509v48.497c0 5.523 4.477 10 10 10h49.059zm57.935-61.408h-96.994v-96.993h96.994zm187.503 0h-48.811c-.003 0-.006 0-.01 0s-.006 0-.01 0h-48.164v-96.993h96.995z" />
													<path d="m356.2 237.43c-2.987-4.558-9.352-5.776-13.86-2.77-4.543 3.029-5.766 9.335-2.76 13.87 3.015 4.548 9.332 5.763 13.859 2.76 4.544-3.013 5.771-9.337 2.761-13.86z" />
												</g>
											</g>
										</svg>
									</a>
								</TooltipComponent>
							</Grid>
						}
					</Grid>
					<Grid item>
						<Grid item className={classes.Menuicon}>
							<TooltipComponent title="Notification" placement="right" arrow>
								<a onClick={() => navigate('notification', 'notification')} className={selectedPage === 'notification' ? 'active' : ''}>
									<Badge color="secondary" badgeContent={notificationCount ? notificationCount : 0} >
										<NotificationsNoneIcon className={classes.NotificationIcon} />
									</Badge>
								</a>
							</TooltipComponent>
						</Grid>
						<Grid item className={classes.MenuiconHelp}>
							<TooltipComponent title="Help" placement="right" arrow>
								<a onClick={() => window.open("https://dqlabs-ai.gitbook.io/dqlabs-userguide/", "_blank")} >
									<svg id="question" className={classes.MenuiconImg} xmlns="http://www.w3.org/2000/svg" width="27.175" height="27.175" viewBox="0 0 27.175 27.175">
										<g id="Group_1334" data-name="Group 1334">
											<g id="Group_1333" data-name="Group 1333">
												<circle id="Ellipse_84" data-name="Ellipse 84" cx="1.327" cy="1.327" r="1.327" transform="translate(12.261 18.763)" fill="#afb2b3" />
												<path id="Path_122" data-name="Path 122" d="M13.588,0A13.588,13.588,0,1,0,27.175,13.588,13.58,13.58,0,0,0,13.588,0Zm0,25.052A11.465,11.465,0,1,1,25.052,13.588,11.458,11.458,0,0,1,13.588,25.052Z" fill="#afb2b3" />
												<path id="Path_123" data-name="Path 123" d="M180.246,128.5A4.251,4.251,0,0,0,176,132.746a1.062,1.062,0,0,0,2.123,0,2.123,2.123,0,1,1,2.123,2.123,1.062,1.062,0,0,0-1.062,1.062v2.654a1.062,1.062,0,0,0,2.123,0v-1.727a4.246,4.246,0,0,0-1.062-8.358Z" transform="translate(-166.659 -121.68)" fill="#afb2b3" />
											</g>
										</g>
									</svg>
								</a>
							</TooltipComponent>
						</Grid>
						<Grid item className={classes.MenuiconLast}>
							<TooltipComponent title="User" placement="right" arrow>
								<a onClick={(event) => openPopOver(event)} className={selectedPage === 'settings' ? classes.MenuIconActive : ''}>
									<svg xmlns="http://www.w3.org/2000/svg" className={classes.MenuiconUserImg} width="30.022" height="30.022" viewBox="0 0 30.022 30.022">
										<g id="Group_2555" data-name="Group 2555" transform="translate(-642 -1635.287)">
											<g id="Bg" transform="translate(642 1635.287)" fill="none">
												<path d="M15.011,0A15.011,15.011,0,0,1,30.022,15.011h0A15.011,15.011,0,0,1,0,15.011H0A15.011,15.011,0,0,1,15.011,0Z" stroke="none" />
												<path id="fillPath" d="M 15.01095008850098 1.999996185302734 C 11.53560066223145 1.999996185302734 8.268260955810547 3.353366851806641 5.810819625854492 5.810815811157227 C 3.353370666503906 8.268257141113281 2 11.53559684753418 2 15.01126670837402 C 2 18.48661613464355 3.353370666503906 21.75395584106445 5.810819625854492 24.21139717102051 C 8.268260955810547 26.66884613037109 11.53560066223145 28.022216796875 15.01095008850098 28.022216796875 C 18.48630142211914 28.022216796875 21.75363922119141 26.66884613037109 24.21108055114746 24.21139717102051 C 26.66852951049805 21.75395584106445 28.02190017700195 18.48661613464355 28.02190017700195 15.01094627380371 C 28.02190017700195 11.53559684753418 26.66852951049805 8.268257141113281 24.21108055114746 5.810815811157227 C 21.75363922119141 3.353366851806641 18.48630142211914 1.999996185302734 15.01095008850098 1.999996185302734 M 15.01095008850098 -3.814697265625e-06 C 23.30126953125 -3.814697265625e-06 30.02190017700195 6.720626831054688 30.02190017700195 15.01094627380371 L 30.02190017700195 15.01126670837402 C 30.02190017700195 23.30158615112305 23.30126953125 30.022216796875 15.01095008850098 30.022216796875 C 6.720630645751953 30.022216796875 0 23.30158615112305 0 15.01126670837402 L 0 15.01094627380371 C 0 6.720626831054688 6.720630645751953 -3.814697265625e-06 15.01095008850098 -3.814697265625e-06 Z" stroke="none" fill="#afb2b3" />
											</g>
											<ellipse id="Oval" cx="3.184" cy="3.141" rx="3.184" ry="3.141" transform="translate(653.821 1643.52)" fill="#afb2b3" />
											<path id="Shape" d="M5.772,0A5.733,5.733,0,0,0,0,5.694H11.543A5.733,5.733,0,0,0,5.772,0Z" transform="translate(651.234 1651.373)" fill="#afb2b3" />
										</g>
									</svg>
								</a>
							</TooltipComponent>
						</Grid>
					</Grid>
				</Grid>
			</Toolbar>
			<Popover
				open={open}
				onClose={handleToggle}
				anchorEl={anchorEl}
				anchorOrigin={
					{
						vertical: 'center',
						horizontal: 'right'
					}
				}
				transformOrigin={
					{
						vertical: 'bottom',
						horizontal: 'left'
					}
				}>
				<MenuItem className={classes.logout}
					onClick={
						() => {
							navigate('settings', 'settings');
							enablePopover(false);
						}
					}>
					<Typography className={classes.logoutTxt}>
						{'Settings'}
					</Typography>
				</MenuItem>
				{/* <MenuItem className={classes.logout}
					onClick={
						() => {
							navigate('aboutus', 'settings');
							enablePopover(false);
						}
					}>
					<Typography className={classes.logoutTxt}>
						{'About Us'}
					</Typography>
				</MenuItem> */}
				<Divider />
				<MenuItem className={classes.logout} onClick={() => userLogout()}>
					<Typography className={classes.logoutTxt}>
						{'Logout'}
					</Typography>
					<div className={classes.horzspace} />
					<div>
						<svg xmlns="http://www.w3.org/2000/svg" width="13.241" height="16" viewBox="0 0 13.241 16">
							<g id="shutdown1" transform="translate(0 0)">
								<path id="Path_218" data-name="Path 218" d="M6.621,108.218A6.622,6.622,0,0,1,4.865,95.211a.552.552,0,0,1,.292,1.064A5.517,5.517,0,1,0,8.2,96.31a.552.552,0,1,1,.316-1.057,6.622,6.622,0,0,1-1.9,12.965Zm0,0" transform="translate(0 -92.217)" fill="#f44436" />
								<path id="Path_219" data-name="Path 219" d="M194.758,7.173a.552.552,0,0,1-.552-.552V.552a.552.552,0,1,1,1.1,0V6.621A.551.551,0,0,1,194.758,7.173Zm0,0" transform="translate(-188.138)" fill="#f44436" />
							</g>
						</svg>
					</div>
				</MenuItem>
			</Popover>
		</AppBar >
	);
};

Header.propTypes = {
	classes: PropTypes.object,
	logout: PropTypes.func,
	history: PropTypes.object,
	theme: PropTypes.object
};

export default withStyles((theme) => ({
	...Styles(theme),
	...CommonStyles(theme)
}), { withTheme: true })(Header);