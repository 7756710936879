import { appConstants } from '../constants/appConstants';
import { request, success, failure } from './baseActions';
import { modelActions } from '../constants/actionTypes/modelActionTypes';
import * as service from '../services/apiService';

export const createSemanticModel = (requestParams) => {
    return (dispatch) => {
        dispatch(request(modelActions.CREATE_SEMANTIC_MODEL_REQUEST));
        return service.postRequest(appConstants.END_POINTS.semanticModel.createModel, requestParams).then((response) => {
            dispatch(success(modelActions.CREATE_SEMANTIC_MODEL_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.CREATE_SEMANTIC_MODEL_FAILURE, error));
        });
    };
};

export const getAllSemanticModel = () => {
    return (dispatch) => {
        dispatch(request(modelActions.GET_ALL_SEMANTIC_MODEL_REQUEST));
        return service.getRequest(appConstants.END_POINTS.semanticModel.getAllModels).then((response) => {
            dispatch(success(modelActions.GET_ALL_SEMANTIC_MODEL_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.GET_ALL_SEMANTIC_MODEL_FAILURE, error));
        });
    };
};

export const getModel = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.semanticModel.getModelById}/${modelId}`;
    return (dispatch) => {
        dispatch(request(modelActions.GET_ALL_SEMANTIC_MODEL_BY_ID_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(modelActions.GET_ALL_SEMANTIC_MODEL_BY_ID_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.GET_ALL_SEMANTIC_MODEL_BY_ID_FAILURE, error));
        });
    };
};

export const createAttribute = (requestParams) => {
    return (dispatch) => {
        dispatch(request(modelActions.CREATE_ATTRIBUTE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.semanticModel.createAttribute, requestParams).then((response) => {
            dispatch(success(modelActions.CREATE_ATTRIBUTE_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.CREATE_ATTRIBUTE_FAILURE, error));
        });
    };
};

export const getAttributes = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.semanticModel.getAttributes}/${modelId}`;
    return (dispatch) => {
        dispatch(request(modelActions.GET_ALL_ATTRIBUTE_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(modelActions.GET_ALL_ATTRIBUTE_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.GET_ALL_ATTRIBUTE_FAILURE, error));
        });
    };
};

export const deleteAttributes = (modelId, attributeId) => {
    const endpoint = `${appConstants.END_POINTS.semanticModel.deleteAttributes}/${modelId}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(modelActions.DELETE_ATTRIBUTE_REQUEST));
        return service.deleteRequest(endpoint).then((response) => {
            dispatch(success(modelActions.DELETE_ATTRIBUTE_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.DELETE_ATTRIBUTE_FAILURE, error));
        });
    };
};

export const updateAttributes = (attributeId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.semanticModel.updateAttributes}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(modelActions.UPDATE_ATTRIBUTE_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(modelActions.UPDATE_ATTRIBUTE_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.UPDATE_ATTRIBUTE_FAILURE, error));
        });
    };
};

export const deleteSemanticModelAttributeRule = (attributeId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.semanticModel.deleteSemanticModelAttributeRule}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(modelActions.DELETE_SMENATIC_MODEL_ATTRIBUTE_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(modelActions.DELETE_SMENATIC_MODEL_ATTRIBUTE_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.DELETE_SMENATIC_MODEL_ATTRIBUTE_FAILURE, error));
        });
    };
};


export const updateSemanticModel = (modelId, requestParams) => {
    const endpoint = `${appConstants.END_POINTS.semanticModel.updateModel}/${modelId}`;
    return (dispatch) => {
        dispatch(request(modelActions.UPDATE_MODEL_REQUEST));
        return service.putRequest(endpoint, requestParams).then((response) => {
            dispatch(success(modelActions.UPDATE_MODEL_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.UPDATE_MODEL_FAILURE, error));
        });
    };
};

export const importModel = (requestParams) => {
    return (dispatch) => {
        dispatch(request(modelActions.IMPORT_MODEL_REQUEST));
        return service.postRequest(appConstants.END_POINTS.semanticModel.importModel, requestParams).then((response) => {
            dispatch(success(modelActions.IMPORT_MODEL_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.IMPORT_MODEL_FAILURE, error));
        });
    };
};

export const getSemanticModelSharingInfo = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.semanticModel.getSemanticModelSharingInfo}/${modelId}`;
    return (dispatch) => {
        dispatch(request(modelActions.GET_ALL_SEMANTIC_MODEL_SHARING_INFO_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(modelActions.GET_ALL_SEMANTIC_MODEL_SHARING_INFO_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.GET_ALL_SEMANTIC_MODEL_SHARING_INFO_FAILURE, error));
        });
    };
};

export const createSemanticModelShare = (requestParams) => {
    return (dispatch) => {
        dispatch(request(modelActions.CREATE_SEMANTIC_MODEL_SHARE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.semanticModel.createSemanticModelShare, requestParams).then((response) => {
            dispatch(success(modelActions.CREATE_SEMANTIC_MODEL_SHARE_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.CREATE_SEMANTIC_MODEL_SHARE_FAILURE, error));
        });
    };
};

export const deleteSemanticModelShare = (requestParams) => {
    return (dispatch) => {
        dispatch(request(modelActions.DELETE_SEMANTIC_MODEL_SHARE_REQUEST));
        return service.postRequest(appConstants.END_POINTS.semanticModel.deleteSemanticModelShare, requestParams).then((response) => {
            dispatch(success(modelActions.DELETE_SEMANTIC_MODEL_SHARE_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.DELETE_SEMANTIC_MODEL_SHARE_FAILURE, error));
        });
    };
};

export const exportModel = (requestParams) => {
    return (dispatch) => {
        dispatch(request(modelActions.EXPORT_MODEL_REQUEST));
        return service.postRequest(appConstants.END_POINTS.semanticModel.exportModel, requestParams).then((response) => {
            dispatch(success(modelActions.EXPORT_MODEL_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.EXPORT_MODEL_FAILURE, error));
        });
    };
};

export const deleteModel = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.semanticModel.deleteModel}/${modelId}`;
    return (dispatch) => {
        dispatch(request(modelActions.DELETE_MODEL_REQUEST));
        return service.deleteRequest(endpoint).then((response) => {
            dispatch(success(modelActions.DELETE_MODEL_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.DELETE_MODEL_FAILURE, error));
        });
    };
};

export const getSemanticModelGlossary = () => {
    return (dispatch) => {
        dispatch(request(modelActions.GET_SEMANTIC_MODEL_GLOSSARY_REQUEST));
        return service.getRequest(appConstants.END_POINTS.semanticModel.getSemanticModelGlossary).then((response) => {
            dispatch(success(modelActions.GET_SEMANTIC_MODEL_GLOSSARY_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.GET_SEMANTIC_MODEL_GLOSSARY_FAILURE, error));
        });
    };
};

export const createSemanticGlossary = (requestParams) => {
    return (dispatch) => {
        dispatch(request(modelActions.CREATE_SEMANTIC_GLOSSARY_REQUEST));
        return service.postRequest(appConstants.END_POINTS.semanticModel.createSemanticGlossary, requestParams).then((response) => {
            dispatch(success(modelActions.CREATE_SEMANTIC_GLOSSARY_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.CREATE_SEMANTIC_GLOSSARY_FAILURE, error));
        });
    };
};

export const getGlossaryDetail = (semanticId, type) => {
    const endpoint = `${appConstants.END_POINTS.semanticModel.getGlossaryDetail}/${semanticId}/${type}`;
    return (dispatch) => {
        dispatch(request(modelActions.GET_GLOSSARY_DETAIL_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(modelActions.GET_GLOSSARY_DETAIL_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.GET_GLOSSARY_DETAIL_FAILURE, error));
        });
    };
};

export const getAssetFields = (fieldName) => {
    const endpoint = `${appConstants.END_POINTS.semanticModel.getAssetFields}/${fieldName}`;
    return (dispatch) => {
        dispatch(request(modelActions.GET_GLOSSARY_ASSET_FIELDS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(modelActions.GET_GLOSSARY_ASSET_FIELDS_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.GET_GLOSSARY_ASSET_FIELDS_FAILURE, error));
        });
    };
};

export const getSemanticModelDefinition = (modelId) => {
    const endpoint = `${appConstants.END_POINTS.semanticModel.getSemanticModelDefinition}/${modelId}`;
    return (dispatch) => {
        dispatch(request(modelActions.GET_SEMANTIC_MODEL_DEFINITION_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(modelActions.GET_SEMANTIC_MODEL_DEFINITION_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.GET_SEMANTIC_MODEL_DEFINITION_FAILURE, error));
        });
    };
};

export const linkAssets = (requestParams) => {
    return (dispatch) => {
        dispatch(request(modelActions.LINK_ASSET_GLOSSARY_REQUEST));
        return service.postRequest(appConstants.END_POINTS.semanticModel.linkAssets, requestParams).then((response) => {
            dispatch(success(modelActions.LINK_ASSET_GLOSSARY_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.LINK_ASSET_GLOSSARY_FAILURE, error));
        });
    };
};

export const unlinkAssetField = (attributeId) => {
    const endpoint = `${appConstants.END_POINTS.semanticModel.unlinkAssetField}/${attributeId}`;
    return (dispatch) => {
        dispatch(request(modelActions.UNLINK_ASSET_FIELD_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(modelActions.UNLINK_ASSET_FIELD_SUCCESS, response));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.UNLINK_ASSET_FIELD_FAILURE, error));
        });
    };
};

export const getAllEnrichment = () => {
    return (dispatch) => {
        dispatch(request(modelActions.GET_ALL_ENRICHMENT_REQUEST));
        return service.getRequest(appConstants.END_POINTS.semanticModel.getAllEnrichment).then((response) => {
            dispatch(success(modelActions.GET_ALL_ENRICHMENT_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.GET_ALL_ENRICHMENT_FAILURE, error));
        });
    };
};

export const getBreadCrumbs = (modelId, fieldId) => {
    const endpoint = `${appConstants.END_POINTS.semanticModel.getBreadCrumbs}/${modelId}/${fieldId}`;
    return (dispatch) => {
        dispatch(request(modelActions.GET_BREAD_CRUMBS_REQUEST));
        return service.getRequest(endpoint).then((response) => {
            dispatch(success(modelActions.GET_BREAD_CRUMBS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.GET_BREAD_CRUMBS_FAILURE, error));
        });
    };
};

export const selectGlossary = (glossary) => {
    return {
        type: modelActions.SELECT_GLOSSARY,
        data: glossary
    };
};

export const setSemanticFilter = (filters) => {
    return {
        type: modelActions.SET_SEMANTIC_FILTER,
        filters
    };
};

export const getAllDefinitionTags = () => {
    return (dispatch) => {
        dispatch(request(modelActions.GET_SEARCH_RESULT_TAGS_REQUEST));
        return service.getRequest(appConstants.END_POINTS.semanticModel.getAllDefinitionTags).then((response) => {
            dispatch(success(modelActions.GET_SEARCH_RESULT_TAGS_SUCCESS, response.data));
            return response.data;
        }, (error) => {
            dispatch(failure(modelActions.GET_SEARCH_RESULT_TAGS_FAILURE, error));
        });
    };
};