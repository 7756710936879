import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import DashboardStyles from './DashboardStyles.jsx';
import StackedBarChart from '../Charts/StackedBarChart.jsx';
import Loader from '../Loaders/Loader.jsx';
import MultiLineChart from '../Charts/MultiLineChart.jsx';
import { getMetrics, getTotalCount, setMetricChartData } from '../../actions/metricsActions';
import DashboardDataPreview from './DashboardDataPreview.jsx';
import DynamicHistogramChart from '../Charts/DynamicHistogramChart.jsx';
import GroupHeatMapChart from '../Charts/GroupHeatMapChart.jsx';
import { appConstants } from '../../constants/appConstants.js';
import Circular from '../Charts/Circular.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';


const WidgetChartView = (props) => {
    const { classes, theme, widget, dashboard, history, refresh } = props;
    const dispatch = useDispatch();
    const [widgetData, setWidgetData] = useState({});
    const containerRef = useRef();
    const widgetType = widget && widget.metrics_type && widget.metrics_type.id ? widget.metrics_type.id : "";
    const widgetChartData = useSelector(({ metric }) => metric.metricData);
    const [assetLevelData, setassetLevelData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [previewParams, setRequestParams] = useState(null);
    const [openPreview, setPreviewDialog] = useState(false);
    const [scorelevel, selscorelevel] = useState('');
    const [timelineFilter, setTimelineFilter] = useState(null);
    // const [recordlevel, selrecordslevel] = useState('');

    const getCount = useCallback(() => {
        setLoading(true);
        dispatch(getTotalCount()).then((response) => {
            setLoading(false);
            if (response) {
                const chartData = widget && widget.level ? response[widget.level.toLowerCase()] : 0;
                setWidgetData({ data: chartData });
                const widgetName = `${widgetType}_${widget.i}`;
                dispatch(setMetricChartData({ [widgetName]: { data: chartData } }));
            }
        });
    }, [dispatch, widget, widgetType]);

    const openPreviewDialog = useCallback((params) => {
        const attributeId = widget.attribute_ids ? widget.attribute_ids.filter((data) => Object.keys(data).length !== 0).map((data) => data.name).toString() : [];
        const rules = widget.rules ? widget.rules.filter((data) => Object.keys(data).length !== 0).map((data) => data.name).toString() : [];
        const datasetId = widget.dataset_ids ? widget.dataset_ids.filter((data) => Object.keys(data).length !== 0).map((source) => source.id).toString() : "";
        const requestParams = {
            "metrics_type": widgetType,
            "datasource_id": widget.datasource_id ? widget.datasource_id.id : "",
            "dataset_ids": datasetId,
            "rules": rules,
            "attribute_ids": attributeId,
            "xaxis": widget.xaxis,
            "yaxis": widget.yaxis ? widget.yaxis : "",
            "range": widget.range ? widget.range : 0,
            ...params
        };
        setRequestParams({ ...requestParams });
        setPreviewDialog(true);
    }, [widget.attribute_ids, widget.dataset_ids, widget.datasource_id, widget.range, widget.rules, widget.xaxis, widget.yaxis, widgetType]);

    const closePreviewDialog = () => {
        setPreviewDialog(false);
    };

    const driftNavigation = (params) => {
        let datasetId = 0;
        let selectedAttribute = "";
        if (widget.attribute_ids && widget.attribute_ids.length === 0) {
            let datasets = [];
            if (widget.dataset_ids.length !== 0) {
                datasets = widget.dataset_ids ? widget.dataset_ids : [];
            } else {
                datasets = widget.datasets ? widget.datasets : [];
            }
            const dataset = datasets.find((dataset) => dataset.name === params.xaxis_value);
            datasetId = dataset && dataset.id ? dataset.id : null;

        } else {
            selectedAttribute = params.xaxis_value && params.xaxis_value.includes("- ") ? params.xaxis_value.split("- ")[1] : "";
            const attribute = widget.attribute_ids ? widget.attribute_ids.find((attribute) => attribute.name === selectedAttribute) : {};
            datasetId = attribute && attribute.dataset_id ? attribute.dataset_id : null;
        }
        if (datasetId) {
            history.push({ pathname: `/dataset/${datasetId}`, state: { datasource: {}, datasourceId: widget.datasource_id ? widget.datasource_id.id : 0, isdrift: true, attribute: selectedAttribute } });
        }
    };

    const heatMapNavigation = (params) => {
        let attribute = widget.xaxis.toLowerCase() === "attribute" ? params.xaxis : "";
        let rule = widget.xaxis.toLowerCase() === "rules" ? params.xaxis : "";
        if (attribute === "" && widget.yaxis && widget.yaxis.includes("Attribute")) {
            attribute = params.yaxis ? params.yaxis : "";
        }
        if (rule === "" && widget.yaxis && widget.yaxis.includes("Drift Rules")) {
            rule = params.yaxis ? params.yaxis : "";
        }
        if (widget.attribute_ids.length === 0 && widget.yaxis.includes("Datasource") && widget.yaxis.includes("Dataset")) {
            const attributes = widget.attribute_ids.find((data) => data.dataset_id = params.dataset_id);
            attribute = attributes ? attributes.name : "";
        }
        history.push({ pathname: `/dataset/${params.dataset_id ? params.dataset_id : params.id}`, state: { datasource: {}, isdrift: true, attribute: attribute, ruleName: rule } });
    };

    const widgetUnCountData = useCallback((groupValue = null) => {
        setLoading(true);
        let datasources = "";
        let scorelevel = "";
        let attributeId = '';
        let datasetId = '';
        let recordslevel = "";
        let xaxis = '';
        let xaxisDataType = '';
        let timelineFilterGroup = '';
        if (widget.datasource_id instanceof Array) {
            datasources = widget.datasource_id ? widget.datasource_id.map((data) => data.id) : [];
        } else {
            datasources = widget.datasource_id ? widget.datasource_id.id : "";
        }

        if (widget.attribute_ids instanceof Array) {
            attributeId = widget.attribute_ids ? widget.attribute_ids.filter((data) => Object.keys(data).length !== 0) : [];
        } else {
            attributeId = widget.attribute_ids ? widget.attribute_ids.id : "";
        }

        if (widget.dataset_ids instanceof Array) {
            datasetId = widget.dataset_ids ? widget.dataset_ids.filter((data) => Object.keys(data).length !== 0).map((data) => data.id).toString() : "";
        } else {
            datasetId = widget.dataset_ids ? widget.dataset_ids.id : "";
        }

        if (widget.xaxis instanceof Object) {
            xaxis = widget.xaxis ? widget.xaxis?.name || '' : '';
            xaxisDataType = widget.xaxis ? widget.xaxis?.datatype || '' : '';
            if (xaxisDataType === 'DateTime' || xaxisDataType === 'Date') {
                timelineFilterGroup = groupValue ? groupValue : 'Yearly';
                setTimelineFilter(timelineFilterGroup);
            }
        } else {
            xaxis = widget.xaxis || '';
        }

        if ("scorelevel" in widget) {
            scorelevel = widget.scorelevel.id;
        }

        if ("recordLevel" in widget) {
            recordslevel = widget.recordLevel.id;
        }
        selscorelevel(scorelevel);
        // selrecordslevel(recordslevel);

        // attributeId = widget.attribute_ids ? widget.attribute_ids.filter((data) => Object.keys(data).length !== 0) : [];
        const rules = widget.rules ? widget.rules.filter((data) => Object.keys(data).length !== 0).map((data) => data.name).toString() : [];
        // const datasetId = widget.dataset_ids ? widget.dataset_ids.filter((data) => Object.keys(data).length !== 0).map((data) => data.id).toString() : "";
        const requestParams = {
            "metrics_type": widgetType,
            "datasource_id": datasources,
            "dataset_ids": datasetId,
            "rules": rules,
            "attribute_ids": attributeId,
            "xaxis": xaxis,
            "yaxis": widget.yaxis ? widget.yaxis : "",
            "range": widget.range ? widget.range : 0,
            "show_null": widget.show_null,
            "show_empty": widget.show_empty,
            "chart_type": widget.chart_type,
            "filter_count": widget.filter_count ? widget.filter_count : 10,
            'is_all_datasource': Boolean(widget.isAllDatasourceSelected),
            'is_all_dataset': Boolean(widget.isAllDatasetSelected),
            'is_all_attributes': Boolean(widget.isAllAttributesSelected),
            'is_all_rules': Boolean(widget.isAllRulesSelected),
            'scorelevel': scorelevel,
            'operation_value': widget.operation_value || '',
            'value_type': widget.value_type || '',
            'data_year': widget.data_year || '',
            'recordlevel': recordslevel,
            'dashboard_id': dashboard.id,
            'timeline_group': timelineFilterGroup ? groupValue || timelineFilterGroup : '',
            'category': widget?.category?.name || '',
            'date_category': widget?.date_category || ''
        };
        if (widgetType === "Invalid_Records" || widgetType === "Valid_Records" || widgetType === "Total_valid_invalid" || widgetType === "DQ_Score") {
            requestParams["attribute_ids"] = attributeId.map((data) => data.name).toString();
        } else if (widgetType === "Drift_Alerts") {
            requestParams["attribute_ids"] = attributeId ? attributeId.map((source) => source.id).toString() : "";
        }
        const widgetName = `${widgetType}_${widget.i}`;
        dispatch(getMetrics(requestParams)).then((response) => {
            setLoading(false);
            if (widgetType === "Record_count") {
                setWidgetData({ data: response });
                const widgetName = `${widgetType}_${widget.i}`;
                dispatch(setMetricChartData({ [widgetName]: { data: response } }));
            }
            if (response && Object.keys(response).length > 0) {
                if (widgetType === "Asset_level_dqscore") {
                    setassetLevelData(response);
                } else {
                    const chartData = {};
                    if (widgetType === "Invalid_Records" || widgetType === "Valid_Records" || widgetType === "Total_valid_invalid" || widgetType.toLowerCase() === "duplicate_count" || widgetType.toLowerCase() === "unique_count" || widgetType.toLowerCase() === "distinct_count") {
                        response = response.actual;
                        response = Object.keys(response).map((data) => {
                            return {
                                [data]: {
                                    ...response[data]
                                }
                            };
                        });
                        chartData.xaxis = requestParams.xaxis ? requestParams.xaxis : "total";
                        chartData.yaxis = requestParams.yaxis;
                        chartData.legends = [];
                        for (const data of response) {
                            const parentkey = Object.keys(data)[0];
                            chartData.legends = [...chartData.legends, ...Object.keys(data[parentkey])];
                        }
                        chartData.legends = [...new Set(chartData.legends)].filter((data) => data !== "total_count");
                        chartData.data = response.map((data) => {
                            const parentkey = Object.keys(data)[0];
                            const legendData = {};
                            for (const legend of chartData.legends) {
                                legendData[legend] = data[parentkey][legend] ? data[parentkey][legend] : 0;
                            }
                            const legendValues = chartData.legends.map((data) => (legendData[data] ? legendData[data] : 0));
                            const total = legendValues.reduce((a, b) => a + b, 0);
                            return {
                                [chartData.xaxis]: total,
                                [requestParams.yaxis]: parentkey,
                                ...legendData
                            };
                        });
                        setWidgetData({ ...chartData });
                        dispatch(setMetricChartData({ [widgetName]: chartData }));
                    } else if (widgetType === "DQ_Score" || widgetType === "Drift_Alerts") {
                        const legend = response && response.meta && response.meta.legend ? response.meta.legend : {};
                        const actualData = response.actual;
                        if (widget.chart_type === appConstants.driftChartTypes[3]) {
                            chartData.data = [...actualData.sort((a, b) => b.count - a.count)];
                        } else {
                            let dates = [];
                            let series = [];
                            for (const key of Object.keys(actualData)) {
                                const values = Object.keys(actualData[key]).map((data) => data.split(".")[0]);
                                dates = [...dates, ...values];
                            }
                            dates = [...new Set(dates)];
                            dates = dates.sort((a, b) => new Date(b) - new Date(a));
                            if (widget.chart_type === appConstants.driftChartTypes[2]) {
                                for (const key of Object.keys(actualData)) {
                                    const keyValues = actualData[key];
                                    const values = dates.map((date) => (keyValues[date] ? keyValues[date] : 0));
                                    series = [...values, ...series];
                                }
                            } else if (widget.chart_type !== appConstants.driftChartTypes[0]) {
                                if (dates.length === 1) {
                                    const yesterday = new Date(dates[0]);
                                    yesterday.setDate(yesterday.getDate() - 1);
                                    dates.unshift(yesterday);
                                }
                                for (const key of Object.keys(actualData)) {
                                    const keyValues = actualData[key];
                                    const values = dates.map((date) => (keyValues[date] ? keyValues[date] : 0));
                                    series.push({ name: legend[key] ? legend[key] : key, values: values });
                                }
                            } else {
                                dates = dates.sort((a, b) => new Date(a) - new Date(b));
                                for (const date of dates) {
                                    const groupData = {};
                                    let totalCount = 0;
                                    for (const key of Object.keys(actualData)) {
                                        const keyValues = actualData[key];
                                        groupData[key] = keyValues[date] ? keyValues[date] : 0;
                                        totalCount += keyValues[date] ? keyValues[date] : 0;
                                    }
                                    series.push({ ...groupData, totalCount: totalCount, date: new Date(date) });
                                }
                                series = series.filter((data) => data.totalCount !== 0);
                            }
                            chartData.legends = Object.keys(legend).length ? Object.keys(legend).map((key) => legend[key]) : Object.keys(actualData);
                            chartData.data = {
                                dates: dates,
                                series: series
                            };
                        }
                        setWidgetData({ ...chartData });
                        dispatch(setMetricChartData({ [widgetName]: chartData }));
                        /*
                         * dispatch(setMetricChartData(requestParams)).then((response) => {
                         *  setLoading(false);
                         */

                    } else if (widgetType.toLowerCase() === "record_count_timeline_chart") {
                        // const legend = response && response.meta && response.meta.legend ? response.meta.legend : {};
                        const actualData = response.actual;
                        const dataKeys = Object.keys(actualData);
                        const data = [];
                        const legends = [];
                        if (dataKeys.length > 0) {
                            dataKeys.forEach((elem, i) => {
                                const chartElem = actualData[elem];
                                const chartElemKey = Object.keys(chartElem);
                                let totalCount = 0;
                                chartElemKey.forEach((key) => {
                                    totalCount += chartElem[key];
                                    if (legends.indexOf(key) < 0) {
                                        legends.push(`${key}`);
                                    }
                                });
                                data.push({
                                    date: elem,
                                    name: elem,
                                    ...chartElem,
                                    totalCount: totalCount
                                });
                            });
                            chartData.data = data;
                            chartData.legends = legends;
                            chartData.mouseOverData = response && response.meta ? response.meta : null;
                            /*
                             * chartData.xaxis = requestParams.xaxis;
                             * chartData.yaxis = requestParams.xaxis;
                             */
                        }
                        setWidgetData({ ...chartData });
                        dispatch(setMetricChartData({ [widgetName]: chartData }));
                    }
                    /*
                     * dispatch(setMetricChartData(requestParams)).then((response) => {
                     * setLoading(false);
                     */

                }
            }

        });
    }, [dispatch, widget.attribute_ids, widget.chart_type, widget.dataset_ids, widget.datasource_id, widget.filter_count, widget.i, widget.isAllAttributesSelected, widget.isAllDatasetSelected, widget.isAllDatasourceSelected, widget.isAllRulesSelected, widget.range, widget.rules, widget.show_empty, widget.show_null, widget.xaxis, widget.yaxis, widgetType]);

    const setWidgetChartData = useCallback(() => {
        const widgetName = `${widgetType}_${widget.i}`;
        const presistWidgetData = widgetChartData && widgetChartData[widgetName] ? widgetChartData[widgetName] : {};
        setWidgetData({ ...presistWidgetData });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widget.i, widgetType]);
    useEffect(() => {
        if (widget && (widget.isModified || widget.isLayoutUpdated)) {
            if (widgetType === "Total_Count") {
                getCount();
            } else {
                widgetUnCountData();
            }
        }
        if (widget && widget.isCancelled) {
            setWidgetChartData();
        }
    }, [dispatch, getCount, setWidgetChartData, widget, widgetType, widgetUnCountData, refresh]);

    const onFilter = (value) => {
        setTimelineFilter(value);
        widgetUnCountData(value);
    };
    return (
        <Grid container className={classes.widgetData} ref={containerRef} direction="column" justify="center" alignItems="center">
            {
                (widgetType === "Invalid_Records" || widgetType === "Valid_Records" || widgetType === "Total_valid_invalid" || widgetType.toLowerCase() === "duplicate_count" || widgetType.toLowerCase() === "unique_count" || widgetType.toLowerCase() === "distinct_count") &&
                <StackedBarChart
                    colors={["#ffaf97", "#b2dbf7", "#f295b6", "#85e8ef", "#656d98", "#676B97", "#CE98DE", "#F16C9A", "#F194B3", "#F6BDD1", "#6BB3D2", "#ABDBFA", "#92DDE5", "#6CCCCA", "#A5DBCO", "#A7DF97", "#7DC460", "#E3F792", "#F8AD93"]}
                    xAxis={widgetData && widgetData.xaxis ? widgetData.xaxis : ""}
                    yAxis={widgetData && widgetData.yaxis ? widgetData.yaxis : ""}
                    legends={widgetData && widgetData.legends ? widgetData.legends : []}
                    // showXAxis
                    chartData={widgetData && widgetData.data ? widgetData.data : []}
                    showYAxis
                    chartClassName={`${widgetType}_${widget.i}`}
                    height={containerRef && containerRef.current ? containerRef.current.clientHeight : 200}
                    onFilter={(params) => openPreviewDialog(params)}
                    showPercentage={widgetType === "Total_valid_invalid"}
                />
            }
            {
                widgetType === "Record_Count_Timeline_Chart" &&
                <StackedBarChart
                    colors={["#ffaf97", "#b2dbf7", "#f295b6", "#85e8ef", "#656d98", "#676B97", "#CE98DE", "#F16C9A", "#F194B3", "#F6BDD1", "#6BB3D2", "#ABDBFA", "#92DDE5", "#6CCCCA", "#A5DBCO", "#A7DF97", "#7DC460", "#E3F792", "#F8AD93"]}
                    xAxis={widgetData && widgetData.xaxis ? widgetData.xaxis : "date"}
                    yAxis={widgetData && widgetData.yaxis ? widgetData.yaxis : "totalCount"}
                    legends={widgetData && widgetData.legends ? widgetData.legends : []}
                    showXAxis
                    chartData={widgetData && widgetData.data ? widgetData.data : []}
                    showYAxis
                    chartClassName={`${widgetType}_${widget.i}`}
                    height={containerRef && containerRef.current ? containerRef.current.clientHeight : 200}
                    onFilter={(params) => openPreviewDialog(params)}
                    showPercentage={widgetType === "Total_valid_invalid"}
                    widgetDirection="vertical"
                    xAxisType="string"
                    yAxisType="number"
                    xAxisTypeDate={false}
                    mouseOverData={widgetData?.mouseOverData || null}
                    chartFilter={
                        timelineFilter ?
                            {
                                value: timelineFilter,
                                onFilter: onFilter,
                                properties: [
                                    {
                                        name: 'Monthly',
                                        value: 'Monthly'
                                    },
                                    {
                                        name: 'Quarterly',
                                        value: 'Quarterly'
                                    },
                                    {
                                        name: 'Yearly',
                                        value: 'Yearly'
                                    }
                                ]
                            } :
                            false
                    }
                />
            }
            {
                (widgetType === "Drift_Alerts" && widget.chart_type === appConstants.driftChartTypes[0]) &&
                <StackedBarChart
                    colors={["#ffaf97", "#b2dbf7", "#f295b6", "#85e8ef", "#656d98", "#676B97", "#CE98DE", "#F16C9A", "#F194B3", "#F6BDD1", "#6BB3D2", "#ABDBFA", "#92DDE5", "#6CCCCA", "#A5DBCO", "#A7DF97", "#7DC460", "#E3F792", "#F8AD93"]}
                    xAxis={widgetData && widgetData.xaxis ? widgetData.xaxis : "date"}
                    yAxis={widgetData && widgetData.yaxis ? widgetData.yaxis : "totalCount"}
                    legends={widgetData && widgetData.legends ? widgetData.legends : []}
                    showXAxis
                    chartData={widgetData && widgetData.data && widgetData.data.series ? widgetData.data.series : []}
                    showYAxis
                    chartClassName={`${widgetType}_${widget.i}`}
                    height={containerRef && containerRef.current ? containerRef.current.clientHeight : 200}
                    onFilter={(params) => driftNavigation(params)}
                    xAxisType="string"
                    yAxisType="number"
                    widgetDirection="vertical"
                    tooltipLabelType="date"
                />
            }
            {
                (widgetType === "Drift_Alerts" && widget.chart_type === appConstants.driftChartTypes[2]) &&
                <DynamicHistogramChart
                    colors={["#ffaf97", "#b2dbf7", "#f295b6", "#85e8ef", "#656d98", "#676B97", "#CE98DE", "#F16C9A", "#F194B3", "#F6BDD1", "#6BB3D2", "#ABDBFA", "#92DDE5", "#6CCCCA", "#A5DBCO", "#A7DF97", "#7DC460", "#E3F792", "#F8AD93"]}
                    xAxis={widgetData && widgetData.xaxis ? widgetData.xaxis : "date"}
                    yAxis={widgetData && widgetData.yaxis ? widgetData.yaxis : "totalCount"}
                    legends={widgetData && widgetData.legends ? widgetData.legends : []}
                    showXAxis
                    chartData={widgetData && widgetData.data && widgetData.data.series ? widgetData.data.series : []}
                    showYAxis
                    chartClassName={`${widgetType}_${widget.i}`}
                    height={containerRef && containerRef.current ? containerRef.current.clientHeight : 200}
                    onFilter={(params) => driftNavigation(params)}
                    binCount={widgetData && widgetData.bins ? widgetData.bins : 0}
                />
            }
            {
                (widgetType === "Drift_Alerts" && widget.chart_type === appConstants.driftChartTypes[3]) &&
                <GroupHeatMapChart
                    chartData={widgetData.data ? widgetData.data : []}
                    chartClassName={`${widgetType}_${widget.i}`}
                    clientHeight={containerRef && containerRef.current ? containerRef.current.clientHeight : 200}
                    chartWidth={containerRef && containerRef.current ? containerRef.current.clientWidth : 200}
                    height={containerRef && containerRef.current ? containerRef.current.clientHeight : 200}
                    onFilter={(params) => heatMapNavigation(params)}
                />
            }
            {
                (widgetType === "DQ_Score" || (widgetType === "Drift_Alerts" && (widget.chart_type === appConstants.driftChartTypes[1] || widget.chart_type === "" || !widget.chart_type))) &&
                <MultiLineChart
                    colors={["#c0adfe", "#6dbcf0", "#f39fbd", "#ff9d81", "#656d98", "#F8AD93", "#E3F792", "#7DC460", "#A7DF97", "#A5DBCO", "#6CCCCA", "#92DDE5", "#ABDBFA", "#6BB3D2", "#F6BDD1", "#F194B3", "#F16C9A", "#COAEF8", "#CE98DE", "#676B97"]}
                    chartClassName={`${widgetType}_${widget.i}`}
                    legends={widgetData && widgetData.legends ? widgetData.legends : []}
                    chartData={widgetData && widgetData.data ? widgetData.data : {}}
                    height={containerRef && containerRef.current ? containerRef.current.clientHeight : 200}
                    onFilter={(params) => openPreviewDialog(params)}
                    tooltipLabel={widgetType === "DQ_Score" ? "Percentage" : "Count"}
                />
            }
            {
                (widgetType === "Asset_level_dqscore" && scorelevel === "Datasource") &&
                <Grid container>
                    {/* <Grid container>
                        <Typography className={classes.wedgetHeaders}>
                            {'Datasource'}
                        </Typography>
                    </Grid> */}
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {
                            assetLevelData && assetLevelData.map((datasource, index) => (
                                <Grid item xs={6} key={index} className={classes.viewListnoborder}>
                                    <Grid container>
                                        <Grid item>
                                            <Circular
                                                showPercentage={Boolean(datasource.dqscore && datasource.dqscore !== 0)}
                                                showValue
                                                value={datasource.dqscore ? datasource.dqscore : 0}
                                                size={50}
                                                dqScoreThreshold={100}
                                            />
                                        </Grid>
                                        <Grid item alignItems="center" className={classes.viewListData} style={{ marginTop: '15px' }}>
                                            <ToolTipComponent title={datasource.datasource_name !== '' ? datasource.datasource_name : 'Untiled Data Source'} arrow>
                                                <Typography>
                                                    {datasource.datasource_name !== '' ? datasource.datasource_name : 'Untiled Data Source'}
                                                </Typography>
                                            </ToolTipComponent>
                                            <Typography>
                                                {'Datasource'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
            }
            {
                (widgetType === "Asset_level_dqscore" && scorelevel === "Dataset") &&
                <Grid container>
                    {/* <Grid container>
                        <Typography className={classes.wedgetHeaders}>
                            {'Dataset'}
                        </Typography>
                    </Grid> */}
                    {
                        assetLevelData && assetLevelData.map((datasource, index) => (
                            <Grid container key={index} className={classes.datasourcecontainer} style={{ marginTop: '10px' }}>
                                <Grid item xs={4} key={index} className={classNames(classes.viewListnoborder, classes.datasetcontainer)} >
                                    <Grid container alignItems="center" justifyContent="center" style={{ width: '100%', height: '100%', paddingLeft: '10px' }}>
                                        <Grid item>
                                            <Circular
                                                showPercentage={Boolean(datasource.dqscore && datasource.dqscore !== 0)}
                                                showValue
                                                value={datasource.dqscore ? datasource.dqscore : 0}
                                                size={50}
                                                dqScoreThreshold={100}
                                            />
                                        </Grid>
                                        <Grid item alignItems="center" className={classes.viewListData}>
                                            <ToolTipComponent title={datasource.datasource_name !== '' ? datasource.datasource_name : 'Untiled Data Source'} arrow>
                                                <Typography>
                                                    {datasource.datasource_name !== '' ? datasource.datasource_name : 'Untiled Data Source'}
                                                </Typography>
                                            </ToolTipComponent>
                                            <Typography>
                                                {'Datasource'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={8} className={classes.dataContainer}>
                                    <Grid container key={index}>
                                        {
                                            datasource.datasets && datasource.datasets.map((dataset, currentindex) => (
                                                <Grid item key={currentindex} xs={4} style={{ marginTop: '10px', paddingLeft: '10px' }} >
                                                    <Grid container key={currentindex} alignItems="center">
                                                        <Grid item>
                                                            <Circular
                                                                showPercentage={Boolean(dataset.dqscore && dataset.dqscore !== 0)}
                                                                showValue
                                                                value={dataset.dqscore ? dataset.dqscore : 0}
                                                                size={50}
                                                                dqScoreThreshold={100}
                                                            />
                                                        </Grid>
                                                        <Grid item className={classes.viewListData}>
                                                            <ToolTipComponent title={dataset.dataset_name !== '' ? dataset.dataset_name : 'Untiled Dataset'} arrow>
                                                                <Typography>
                                                                    {dataset.dataset_name !== '' ? dataset.dataset_name : 'Untiled Dataset'}
                                                                </Typography>
                                                            </ToolTipComponent>
                                                            <Typography>
                                                                {'Dataset'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            }
            {
                (widgetType === "Asset_level_dqscore" && scorelevel === "Attribute") &&
                <Grid container>
                    {/* <Grid container>
                        <Typography className={classes.wedgetHeaders}>
                            {'Attribute'}
                        </Typography>
                    </Grid> */}
                    {
                        assetLevelData && assetLevelData.map((datasource, index) => (
                            <Grid container key={index} style={{ marginTop: '10px' }}>
                                <Grid container>
                                    <Grid container key={index} alignItems="center">
                                        <Grid item>
                                            <Circular
                                                showPercentage={Boolean(datasource.dqscore && datasource.dqscore !== 0)}
                                                showValue
                                                value={datasource.dqscore ? datasource.dqscore : 0}
                                                size={50}
                                                dqScoreThreshold={100}
                                            />
                                        </Grid>
                                        <Grid item className={classes.viewListData}>
                                            <ToolTipComponent title={datasource.datasource_name !== '' ? datasource.datasource_name : 'Untiled Data Source'} arrow>
                                                <Typography>
                                                    {datasource.datasource_name !== '' ? datasource.datasource_name : 'Untiled Data Source'}
                                                </Typography>
                                            </ToolTipComponent>
                                            <Typography>
                                                {'Datasource'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    {
                                        datasource.datasets && datasource.datasets.map((dataset, currentindex) => (
                                            <Grid container key={currentindex} className={classes.datasourcecontainer} style={{ marginTop: '10px' }}>
                                                <Grid item xs={4} className={classNames(classes.viewListnoborder, classes.datasetcontainer)}>
                                                    <Grid container alignItems="center" justifyContent="center" style={{ width: '100%', height: '100%', paddingLeft: '10px' }}>
                                                        <Grid item>
                                                            <Circular
                                                                showPercentage={Boolean(dataset.dqscore && dataset.dqscore !== 0)}
                                                                showValue
                                                                value={dataset.dqscore ? dataset.dqscore : 0}
                                                                size={50}
                                                                dqScoreThreshold={100}
                                                            />
                                                        </Grid>
                                                        <Grid item alignItems="center" className={classes.viewListData}>
                                                            <ToolTipComponent title={dataset.dataset_name !== '' ? dataset.dataset_name : 'Untiled Dataset'} arrow>
                                                                <Typography>
                                                                    {dataset.dataset_name !== '' ? dataset.dataset_name : 'Untiled Dataset'}
                                                                </Typography>
                                                            </ToolTipComponent>
                                                            <Typography>
                                                                {'Dataset'}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={8} className={classes.dataContainer}>
                                                    <Grid container key={index}>
                                                        {
                                                            dataset.attributes && dataset.attributes.map((currentattribute, attributeindex) => (
                                                                <Grid item key={attributeindex} xs={4} style={{ marginTop: '10px', paddingLeft: '5px' }}>
                                                                    <Grid container key={attributeindex} alignItems="center">
                                                                        <Grid item>
                                                                            <Circular
                                                                                showPercentage={Boolean(currentattribute.dqscore && currentattribute.dqscore !== 0)}
                                                                                showValue
                                                                                value={currentattribute.dqscore ? currentattribute.dqscore : 0}
                                                                                size={50}
                                                                                dqScoreThreshold={100}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item className={classes.viewListData}>
                                                                            <ToolTipComponent title={currentattribute.attribute_name !== '' ? currentattribute.attribute_name : 'Untiled Attribute'} arrow>
                                                                                <Typography>
                                                                                    {currentattribute.attribute_name !== '' ? currentattribute.attribute_name : 'Untiled Attribute'}
                                                                                </Typography>
                                                                            </ToolTipComponent>
                                                                            <Typography>
                                                                                {'Attribute'}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            ))
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            }
            {
                widgetType === "Total_Count" &&
                <Grid className={classes.totalCountContainer}>
                    <Grid item>
                        <Typography component="h1" variant="h1" style={{ fontSize: 36 }}>
                            {widgetData && widgetData.data ? widgetData.data : 0}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4" style={{ color: theme.palette.grey.main }}>
                            {widget.level}
                        </Typography>
                    </Grid>
                </Grid>
            }
            {
                widgetType === "Record_count" &&
                <Grid className={classes.totalCountContainer}>
                    <Grid item>
                        <Typography component="h1" variant="h1" style={{ fontSize: 36 }}>
                            {widgetData && widgetData.data ? widgetData.data : 0}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography component="h4" variant="h4" style={{ color: theme.palette.grey.main }}>
                            {'Records'}
                        </Typography>
                    </Grid>
                </Grid>
            }
            {
                isLoading &&
                <Loader />
            }
            {
                openPreview && previewParams &&
                <DashboardDataPreview
                    onClose={() => closePreviewDialog()}
                    open={openPreview}
                    params={previewParams}
                    widgetName={widget && widget.name ? widget.name : ""}

                />
            }
        </Grid>
    );
};

WidgetChartView.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    widget: PropTypes.object,
    dashboard: PropTypes.object,
    history: PropTypes.object,
    refresh: PropTypes.string
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(WidgetChartView);