import React, { Component } from 'react';
import { Grid, Typography, Button, withStyles, CircularProgress } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import * as accountActions from '../../actions/accountActions';
import TextBox from '../../components/TextBox/TextBox.jsx';
import Slider from '../../components/Slider/Slider.jsx';
import AccountStyles from './AccountStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import Localization from '../../constants/localization';
import Logo from '../../assets/images/logo.png';


class UserActivate extends Component {
    /**
     * Constructor
     * @description Class Constructor
     * @param {object} props
     */
    constructor(props) {
        super(props);
        this.state = {
            "first_name": '',
            "last_name": '',
            "password": '',
            "confirm_password": '',
            "userid": ''
        };

        //Validate Password match validation
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });
    }

    /**
     * ComponentDidMount
     * @description Update the state userid property to encoded userid
     */
    componentDidMount() {
        this.setState({ userid: this.props.match.params.id });
        this.props.getPageTitle('Activation');
    }

    /**
     * HandleChange
     * @description Update input value to state variable
     * @param {string} name Input name
     * @param {object} event Input field event
     */
    handleChange = (name) => (event) => {
        this.setState({ [name]: event.target.value });
    }


    /**
     * HandleSubmit
     * @description Activate user
     */
    handleSubmit = () => {
        const model = Object.assign({}, this.state);
        delete model.confirm_password;
        this.props.userActivation(model);
    }

    render() {
        const { classes, isLoading, theme } = this.props;
        return (
            <Grid container className={classes.container}>
                <Grid item xs={8} className={classes.sliderContainer}>
                    <Slider />
                </Grid>
                <Grid item xs={4} className={classes.container}>
                    <Grid container direction="column" justify="space-between" className={classes.section} wrap="nowrap">
                        <Grid item align="center">
                            <img src={Logo} alt="Logo" className={classes.logo} />
                        </Grid>
                        <Grid item>
                            <ValidatorForm name="loginform" onSubmit={() => this.handleSubmit()}>
                                <TextBox
                                    label="First Name"
                                    onChange={this.handleChange('first_name')}
                                    name="first_name"
                                    validators={['required']}
                                    errorMessages={['First Name is required']}
                                    id="first_name"
                                    value={this.state.first_name}
                                    margin="normal"
                                    fullWidth
                                    autoComplete="off"
                                />
                                <TextBox
                                    label="Last Name"
                                    onChange={this.handleChange('last_name')}
                                    name="last_name"
                                    validators={['required']}
                                    errorMessages={['Last Name is required']}
                                    id="last_name"
                                    value={this.state.last_name}
                                    fullWidth
                                    margin="normal"
                                    autoComplete="off"
                                />
                                <TextBox
                                    label="Password"
                                    type="password"
                                    onChange={this.handleChange('password')}
                                    name="password"
                                    validators={['required']}
                                    errorMessages={['Password is required']}
                                    id="password"
                                    value={this.state.password}
                                    fullWidth
                                    margin="normal"
                                    autoComplete="off"
                                />
                                <TextBox
                                    label="Confirm Password"
                                    type="password"
                                    onChange={this.handleChange('confirm_password')}
                                    name="confirm_password"
                                    validators={['required', 'isPasswordMatch']}
                                    errorMessages={['Confirm Password is required', 'Password not match']}
                                    id="confirm_password"
                                    value={this.state.confirm_password}
                                    fullWidth
                                    margin="normal"
                                    autoComplete="off"
                                />
                                <Grid container direction="row" justify="space-between" className={classes.btnContainer}>
                                    <Grid item>
                                        <Button variant="contained"
                                            type="submit"
                                            size="large"
                                            color="primary"
                                            className={classes.loginbtn}
                                            disabled={isLoading}>

                                            {
                                                isLoading ?
                                                    <CircularProgress className={classes.progress}
                                                        color="primary"
                                                        size={24}
                                                        thickness={4} /> : `${Localization.singup}`
                                            }
                                        </Button>
                                    </Grid>
                                    <Grid item align="right">
                                        <Button disabled={isLoading}
                                            className={classNames(classes.block, classes.nopadding, classes.inlineButton, classes.Linkhover)}
                                            onClick={() => this.props.history.push('/')}>
                                            Already have an account?
                                        </Button>
                                    </Grid>
                                </Grid>
                            </ValidatorForm>
                        </Grid>
                        <Grid item align="center">
                            <Typography variant="body2" className={classes.copyrightText}>
                                {theme.palette.loginCopyrightText}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

UserActivate.propTypes = {
    classes: PropTypes.object,
    isLoading: PropTypes.bool,
    theme: PropTypes.object,
    userActivation: PropTypes.func,
    history: PropTypes.object,
    match: PropTypes.object,
    getPageTitle: PropTypes.func
};

/**
 * Map State To Props
 * @description Get Reducer Props value to Props property
 * @param {object} account
 */
const mapStateToProps = ({ account }) => {
    return {
        ...account
    };
};

/**
 * Dispatch Props
 * @description dispatch Props actions
 * @param {object} dispatch
 */
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ ...accountActions }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...AccountStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(UserActivate));