import React, { useEffect, useCallback, useState } from 'react';
import { Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as d3 from 'd3';
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';

const SunBurstChart = (props) => {
    const { classes, profileData } = props;
    const [chartData, setChartData] = useState({});

    const loadChartData = useCallback((chartData) => {
        setChartData({ ...chartData });
    }, []);

    useEffect(() => {
        loadChartData(profileData);
    }, [loadChartData, profileData]);

    const loadColor = useCallback((sensitivity) => {
        const colors = ["#00CFDE", "#FF5826", "#E32266"];
        if (sensitivity.toLowerCase() === "sensitivity1") {
            return colors[0];
        } else if (sensitivity.toLowerCase() === "sensitivity2") {
            return colors[1];
        }
        return colors[2];
    }, []);

    useEffect(() => {
        if (chartData && Object.keys(chartData).length !== 0) {
            let width = 300;
            const height = 300;
            const colors = ["#00CFDE", "#FF5826", "#E32266"];
            d3.select('.sun-burst-chart > *').remove();
            const svg = d3.select('.sun-burst-chart')
                .append("svg")
                .attr("width", (_, i, nodes) => {
                    width = nodes[i].parentNode.clientWidth;
                    return width;
                })
                .attr('height', height);
            const g = svg
                .append('g')
                .attr("width", width - 50)
                .attr('transform', 'translate(' + width / 2 + ',' + ((height / 2) - 20) + ')');

            d3.select(".sun-burst-chart >.tooltip").remove();
            const div = d3.select(".sun-burst-chart").append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
                .style("zIndex", 1);

            const radius = (width / 2) - 80;
            // Data strucure
            const partition = d3.partition()
                .size([2 * Math.PI, radius]);

            // Find data root
            const root = d3.hierarchy(chartData)
                .sum((d) => { return d.value; });
            // Size arcs
            partition(root);
            const arc = d3.arc()
                .startAngle((d) => { return d.x0; })
                .endAngle((d) => { return d.x1; })
                .innerRadius((d) => { return d.y0; })
                .outerRadius((d) => { return d.y1; });

            // Put it all together
            g.selectAll('path')
                .data(root.descendants())
                .enter().append('path')
                .attr("display", (d) => { return d.depth === 0 ? "none" : null; })
                .attr("class", (d) => { return "myArea " + d.data.name; })
                .attr("d", arc)
                .style('stroke', '#fff')
                .style("fill", (d) => loadColor(d.data.name))
                .on('mouseover', (d) => {
                    d3.select(this).style('opacity', 0.5);
                    let x = 0;
                    let y = 0;
                    div.attr("transform", (_, i, nodes) => {
                        const mouseCoords = d3.mouse(nodes[i].parentNode);
                        let xCo = 0;
                        let yCo = 0;
                        if (mouseCoords[0] + 10 >= width * 0.80) {
                            xCo = mouseCoords[0] - parseFloat(div
                                .attr("width"));
                            yCo = mouseCoords[1] + 10;
                        } else {
                            xCo = mouseCoords[0] + 10;
                            yCo = mouseCoords[1];
                        }
                        x = xCo;
                        y = yCo;
                    });
                    div.transition()
                        .duration(200)
                        .style("opacity", 0.9);

                    div.html(`DatasourceName : ${d.data.datasourceName} <br/>DatasetName : ${d.data.datasetName} <br/> Sensitivity : ${d.data.name ? d.data.name.replace(/\D/g, '') : ''} <br/> Count : ${d.data.value}`)
                        .style("left", x + "px")
                        .style("top", y + "px");
                })
                .on('mouseout', (d) => {
                    d3.select(this).style('opacity', 1);
                    div.transition()
                        .duration(500)
                        .style("opacity", 0);
                });

            const highlight = (d) => {
                d3.selectAll(".myArea").style("opacity", 0.1);
                d3.selectAll("." + d.toLowerCase()).style("opacity", 1);
            };

            // And when it is not hovered anymore
            const noHighlight = (d) => {
                d3.selectAll(".myArea").style("opacity", 1);
            };
            //   Interval Legends
            const legendGap = 100;
            const lines = ["Sensitivity1", "Sensitivity2", "Sensitivity3"];
            const xTranslate = () => {
                if ((window.innerWidth >= 1549) && (window.innerWidth <= 1860)) {
                    return 30;
                }
                else if (window.innerWidth >= 1861) {
                    return 70;
                }
                return 0;
            };
            const legendGroup = svg.append("g")
                .attr("class", "legends")
                .attr("transform", "translate(" + xTranslate() + "," + (height - 20) + ")");

            // Interval Legend rectangle
            legendGroup.selectAll(".legend-group")
                .data(lines)
                .enter()
                .append("g")
                .attr("class", "legend-group")
                .style("opacity", 1)
                .append("rect")
                .attr("class", "legend-rect")
                .attr("width", 14)
                .attr("height", 10)
                .attr('x', (_, index) => { return (index * legendGap); })
                .style("fill", (d, index) => colors[index])
                .on("mouseover", highlight)
                .on("mouseleave", noHighlight);

            legendGroup.selectAll(".legend-group")
                .append("text")
                .attr("class", "legend-text")
                .attr("x", (_, index) => { return index * legendGap + 20; })
                .attr("y", 6)
                .text((line, i, nodes) => {
                    d3.select(nodes[i]).attr("class", `legend-text ${line} `);
                    return line;
                })
                .attr("alignment-baseline", "middle")
                .on("mouseover", highlight)
                .on("mouseleave", noHighlight);

        }
    }, [chartData, loadColor]);

    return (
        <Grid container className={classNames(classes.chartStyles, classes.sunburst, "sun-burst-chart")} />
    );
};

SunBurstChart.propTypes = {
    classes: PropTypes.object,
    profileData: PropTypes.object
};

export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SunBurstChart);