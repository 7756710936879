import React, { useCallback, useState, useEffect } from 'react';
import { Grid, withStyles, Button, Card, Table, TableBody, TableHead, TableRow, TableCell, Typography, CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { highlight } from "prismjs/components/prism-core";
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import QueryEditorFunctions from './Config/QueryEditorFunctions.jsx';
import QueryEditorSQLFunctions from './Config/QueryEditorSQLFunctions.jsx';
import PlSqlConfig from './Config/PlSqlConfig.jsx';
/*
 * import TabPanel from '../TabPanel/TabPanel.jsx';
 * import FunctionTab from './Tabs/FunctionTab.jsx';
 * import ColumnTab from './Tabs/ColumnTab.jsx';
 * import ExpressionTab from './Tabs/ExpressionTab.jsx';
 */
import Editor from './Editor/Editor.jsx';
import { functionQueryString, getAttributeNames, getTableNames } from '../RuleBuilder/QueryBuilderUtil.jsx';
import { getTextAreaPosition } from '../../helpers/appHelpers';
import { validateQuery } from '../../actions/datasetActions';

import Styles from '../../layouts/Styles.jsx';
import ReactQueryEditorStyles from './ReactQueryEditorStyles.jsx';
import Loader from '../Loaders/Loader.jsx';

const ReactQueryEditor = (props) => {
    /*
     * const tabList = ["Functions", "Columns"];
     * const [tabIndex, setTabIndex] = useState(0);
     */
    const { fields, classes, rule, onChange, container, showSubmitButton, isLoading, onClear, onSubmit, isShowValidate, selectedRuleChanged, isModel, disabled } = props;
    const [openSuggestion, setOpenSuggestion] = useState(false);
    const datasetId = useSelector(({ dataset }) => dataset.selectedDatasetId);
    const datasource = useSelector(({ datasource }) => datasource?.datasource);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [headers, setHeaders] = useState([]);
    const [previewData, setPreviewData] = useState({});
    const [inputData, setInputData] = useState([]);
    const [isPreviewShow, SetPreviewShow] = useState(false);

    const getSuggestion = useCallback(() => {
        const functions = rule.is_sql_editor ? QueryEditorSQLFunctions : QueryEditorFunctions;
        let list = functions.map((func) => {
            return {
                suggestionType: "function",
                label: func.label,
                operator: func.operator

            };
        });
        const columns = fields.map((column) => {
            return {
                ...column,
                suggestionType: "column",
                label: column.name
            };
        });
        let tables = [];
        if (rule.is_sql_editor) {
            tables = fields ? fields.map((field) => {
                return {
                    ...field,
                    suggestionType: "table",
                    label: field.dataset_schema ? field.dataset_schema : ""
                };
            }) : [];

            tables = tables.reduce((acc, current) => {
                const x = acc.find((item) => item.label === current.label);
                if (!x) {
                    return acc.concat([current]);
                }
                return acc;

            }, []);
        }
        list = [...columns, ...list, ...tables];
        return list.sort((a, b) => b.label - a.label);
    }, [fields, rule.is_sql_editor]);

    /*
     * const onSelect = (type, value) => {
     *     let data = rule.query ? rule.query : "";
     *     if (type === "column" && (data.includes('{column}') || data.includes('<value>'))) {
     *         data = data.includes('{column}') ? data.replace('{column}', `${value}`) : data.replace('<value>', `${value}`);
     *     } else {
     *         data = textareaInsertText("sql-editor", value);
     *     }
     *     onChange("query", data);
     *     validateQuery(data);
     * };
     */

    const enableSuggestion = (value) => {
        setOpenSuggestion(value);
    };


    const getContainerHeight = useCallback(() => {
        let clientHeight = 300;
        if (container && container.current && container.current.clientHeight) {
            clientHeight = container.current.clientHeight;
        }
        return clientHeight;
    }, [container]);


    /*
     * const enableEditor = useCallback(() => {
     *     onChange("is_sql_editor", !rule.is_sql_editor);
     * }, [onChange, rule.is_sql_editor]);
     */


    const validateSqlQuery = () => {
        SetPreviewShow(true);
        let ruleGroupAttributes = getAttributeNames(fields, rule.query);
        ruleGroupAttributes = [...new Set(ruleGroupAttributes)];
        const attributes = [];
        const selectedDatasources = [];
        for (const attribute of ruleGroupAttributes) {
            const field = fields.find((p) => p.name.toLowerCase() === attribute.toLowerCase());
            if (!field) {
                continue;
            }
            const schema = field.attribute_schema ? field.attribute_schema : '';
            attributes.push({
                name: field.name,
                datatype: field.datatype,
                schema
            });
            const hasDatasource = selectedDatasources.find((p) => p.dataset_id === field.dataset_id);
            if (!hasDatasource) {
                selectedDatasources.push({ ...field });
            }
        }
        if (attributes.length === 0) {
            const tables = getTableNames(fields, rule.query);
            for (const table of tables) {
                const field = fields.find((p) => p.dataset_schema.toLowerCase() === table.toLowerCase());
                if (!field) {
                    continue;
                }
                const hasDatasource = selectedDatasources.find((p) => p.dataset_id === field.dataset_id);
                if (!hasDatasource) {
                    selectedDatasources.push({ ...field });
                }
            }
        }
        if (!datasource?.scan) {
            onChange("datasources", [...selectedDatasources]);
        }
        const model = {
            "query_params": {
                ...rule,
                selectedAttributes: attributes,
                datasources: selectedDatasources,
                "query_string": functionQueryString(rule.query, attributes)
            },
            "method": "userquery",
            "dataset_id": datasetId
        };
        setLoading(true);
        resetPreviewData();
        dispatch(validateQuery(model)).then((response) => {
            setLoading(false);
            if (response && response.length !== 0) {
                if (response.result.length > 0) {
                    const columnList = Object.keys(response.result[0]);
                    if (response.columns.length !== columnList.length) {
                        setHeaders([...columnList]);
                    } else {
                        setHeaders(response.columns ? [...response.columns] : []);
                    }
                }

                setInputData(response.result ? [...response.result] : []);
                setPreviewData({ ...response, headers: [...response.columns] });
            }
        });
    };

    const resetPreviewData = () => {
        setHeaders([]);
        setInputData([]);
        setPreviewData({});
    };

    useEffect(() => {
        if (selectedRuleChanged) {
            SetPreviewShow(false);
            resetPreviewData();
        }
    }, [selectedRuleChanged]);

    let containerHeight = getContainerHeight();
    containerHeight = containerHeight >= 250 ? containerHeight : 250;
    const suggestions = getSuggestion();
    // const height = window.screen.height - (containerHeight * 2) - 68;
    return (
        <Grid container spacing={2}>
            {/* {
                !datasource?.scan &&
                <Grid item xs={4}>
                    <Grid className={classes.editorTab}>
                        <Tabs
                            value={tabIndex}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={(_, index) => setTabIndex(index)}
                            aria-label="Query tabs">
                            {
                                tabList.map((name, index) =>
                                    <Tab key={index} label={name} />
                                )
                            }
                        </Tabs>
                        {
                            tabIndex === 0 ?
                                <TabPanel value={tabIndex} index={0}>
                                    <FunctionTab
                                        functions={QueryEditorFunctions.sort((a, b) => b.name < a.name)}
                                        onSelect={(value) => onSelect("function", value)}
                                        containerHeight={containerHeight} />
                                </TabPanel> :
                                <TabPanel value={tabIndex} index={1}>
                                    <ColumnTab
                                        columns={fields ? fields : []}
                                        onSelect={(value) => onSelect("column", value)}
                                        containerHeight={containerHeight} />
                                </TabPanel>
                        }
                    </Grid>
                </Grid>
            } */}
            {/* <Grid item xs={datasource?.scan ? 12 : 8}> */}
            <Grid item xs={12}>
                <Grid className={classNames(classes.editorTab, "sql-editor")} style={{ height: `${(containerHeight) - (isShowValidate ? 100 : 60)}px` }} onClick={() => enableSuggestion(false)}>
                    {/* {
                        !datasource?.scan &&
                        <ExpressionTab
                            onSelect={(value) => onSelect("expression", value)}
                            enableEditor={() => enableEditor()}
                            isScanMode={(datasource?.scan)}
                            isSqlEditor={rule.is_sql_editor} />

                    } */}
                    <Editor
                        position={getTextAreaPosition("sql-editor", "position")}
                        suggestions={datasource?.scan ? [] : suggestions}
                        value={rule.query ? rule.query : ""}
                        onChange={(code) => onChange("query", code)}
                        highlight={(code) => highlight(code, PlSqlConfig)}
                        suggesstionHeight={containerHeight >= 450 ? 150 : 90}
                        // onBlur={(code) => onBlurQuery(code)}
                        padding={10}
                        openSuggestion={openSuggestion}
                        enableSuggestion={(value) => enableSuggestion(value)}
                        isScan={Boolean(datasource?.scan)}
                        isShowValidate={isShowValidate}
                        isModel={isModel}
                        disabled={disabled}
                        style={
                            {
                                fontFamily: '"Roboto"',
                                fontSize: 13
                            }
                        } />
                    {
                        (Boolean(isShowValidate)) && !disabled &&
                        <Button variant="contained"
                            disabled={loading || (!datasource.scan && fields.length === 0)}
                            color="primary"
                            className={classNames(classes.actionButton, classes.btnValidate)}
                            onClick={() => validateSqlQuery()}
                        >
                            {'Validate'}
                        </Button>
                    }
                </Grid>
            </Grid>
            {
                ((isShowValidate && isPreviewShow) || (datasource?.scan && isPreviewShow)) &&
                <Grid container style={{ backgroundColor: "#fff" }}>
                    <Grid container justify="space-between" alignItems="center" style={{ marginTop: 4 }}>
                        <Grid item />
                        <Grid item>
                            {
                                (!loading && inputData && inputData.length > 0 && previewData) &&
                                <Grid item>
                                    <Typography>
                                        {`${previewData.count} of ${previewData.total_records} records`}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={classNames(classes.tablecontainer, classes.previewContainer)}>
                        {
                            (!loading && inputData && inputData.length > 0) &&
                            <Card className={classNames(classes.previewTable)}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            {
                                                headers && headers.map((header, index) => {
                                                    return (
                                                        <TableCell key={'dataPreviewTableHeader' + index}
                                                            className={classNames(classes.tableheadbg)}>
                                                            <Typography>
                                                                {header}
                                                            </Typography>
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {
                                            inputData.map((row, index) => {
                                                return (
                                                    <TableRow key={'executionTable' + index}>
                                                        {
                                                            headers.map((header, index) => {
                                                                return (
                                                                    <TableCell key={'executionTableCell' + index}>
                                                                        <Typography noWrap>
                                                                            {row[header]}
                                                                        </Typography>
                                                                    </TableCell>
                                                                );
                                                            })
                                                        }
                                                    </TableRow>
                                                );
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </Card>
                        }
                        {
                            (!loading && inputData && inputData.length <= 0 && isPreviewShow) &&
                            <Grid container justify="center" alignItems="center">
                                <Typography className={
                                    classNames(classes.noDataFoundMessage,
                                        classes.noDataMessage)
                                }>
                                    No data found
                                </Typography>
                            </Grid>
                        }
                        {
                            loading &&
                            <Grid container justify="center" alignItems="center" className={classes.loader}>
                                <CircularProgress color="primary" />
                            </Grid>
                        }
                    </Grid>
                </Grid>
            }
            {
                showSubmitButton &&
                <Grid item xs={12}>
                    <Grid container direction="row" justify="flex-start">
                        <Grid item className={classes.btnContainer}>
                            <Button variant="contained"
                                disabled={isLoading}
                                color="primary"
                                className={classNames(classes.actionButton)}
                                onClick={() => onSubmit()}
                            >
                                {loading ? <Loader /> : "Submit"}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button className={classNames(classes.cancelButton, classes.actionButton, classes.marginLeft10)}
                                disabled={isLoading}
                                onClick={() => onClear()}>
                                {'Clear All'}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
        </Grid>
    );
};

ReactQueryEditor.propTypes = {
    classes: PropTypes.object,
    fields: PropTypes.array,
    rule: PropTypes.string,
    onChange: PropTypes.func,
    container: PropTypes.element,
    showSubmitButton: PropTypes.bool,
    isLoading: PropTypes.bool,
    onClear: PropTypes.bool,
    onSubmit: PropTypes.func,
    isShowValidate: PropTypes.bool,
    isModel: PropTypes.bool,
    selectedRuleChanged: PropTypes.bool,
    disabled: PropTypes.bool
};


export default withStyles((theme) => ({
    ...ReactQueryEditorStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ReactQueryEditor);