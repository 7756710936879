import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Button, Popover, Typography, List, ListItem, ListItemText, ListItemIcon, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import { ValidatorForm } from 'react-material-ui-form-validator';
import TextBox from '../TextBox/TextBox.jsx';
import Dropzone from 'react-dropzone';
import Styles from '../../layouts/Styles.jsx';
import SettingComponentStyles from './SettingComponentStyles.jsx';

const ReferenceConfig = (props) => {
    const { classes, open, anchorEl, onClose, theme, config, addParameter, removeParameter, onChange, onSave, uploadFiles } = props;
    return (
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={
                {
                    vertical: 'center',
                    horizontal: 'center'
                }
            }
            transformOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'center'
                }
            }>
            {
                config && config.type === "File" ?
                    <Grid className={classes.referencePopover}>
                        <Typography variant="h5">
                            Upload Reference File
                        </Typography>
                        <Dropzone accept=".csv, .xlsx, .xls" maxSize={100 * (1024 * 1024)} onDrop={(acceptedFiles) => uploadFiles(acceptedFiles)} >
                            {
                                ({ getRootProps, getInputProps }) => (
                                    <Grid className={classes.droparea}
                                        container
                                        direction="row"
                                        alignItems="center"
                                        justify="center"
                                        style={{ outline: 'none' }}
                                        {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <Grid>
                                            <List>
                                                <ListItem >
                                                    <ListItemIcon>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="41.7" height="38.54" viewBox="0 0 70.329 65.007">
                                                            <g id="upload" transform="translate(0 -18.4)">
                                                                <g id="Group_376" data-name="Group 376" transform="translate(0 18.4)">
                                                                    <path id="Path_130" data-name="Path 130" d="M57.2,35.378A20.372,20.372,0,0,0,19.451,28.61a11.67,11.67,0,0,0-2.14-.2,11.165,11.165,0,0,0-11.15,11.15,12.188,12.188,0,0,0,.231,2.314A15.516,15.516,0,0,0,0,54.381,16.588,16.588,0,0,0,4.208,65.358a15.02,15.02,0,0,0,10.413,5.235H27.174a1.952,1.952,0,0,0,0-3.9H14.795C8.88,66.327,3.9,60.7,3.9,54.367a11.591,11.591,0,0,1,5.741-9.979,1.968,1.968,0,0,0,.853-2.372,7.085,7.085,0,0,1-.434-2.487,7.258,7.258,0,0,1,7.245-7.245,7.122,7.122,0,0,1,2.473.434,1.961,1.961,0,0,0,2.444-1,16.466,16.466,0,0,1,31.281,5.423,1.95,1.95,0,0,0,1.62,1.735,14.074,14.074,0,0,1-1.331,27.8H43.14a1.952,1.952,0,1,0,0,3.9H54.016a17.3,17.3,0,0,0,11.6-5.727A18,18,0,0,0,57.2,35.378Z" transform="translate(0 -18.4)" fill="#6bb3d2" />
                                                                    <path id="Path_131" data-name="Path 131" d="M182.228,199.081a1.946,1.946,0,0,0,0-2.762l-10.34-10.34a1.976,1.976,0,0,0-1.374-.578,1.915,1.915,0,0,0-1.374.578l-10.34,10.34a1.96,1.96,0,0,0,1.374,3.341,1.905,1.905,0,0,0,1.374-.578l7.014-7.014V224.3a1.952,1.952,0,1,0,3.9,0V192.067l7.014,7.014A1.929,1.929,0,0,0,182.228,199.081Z" transform="translate(-135.343 -161.248)" fill="#6bb3d2" />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </ListItemIcon>
                                                    <ListItemText className={classes.dropareatxt} primary=" Drag and drop or choose a file to upload your data." />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Dropzone>
                    </Grid> :
                    <Grid className={classes.referencePopover}>
                        <Typography variant="h5">
                            Reference API
                        </Typography>
                        <ValidatorForm onSubmit={() => onSave()}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextBox
                                        value={config.path ? config.path : ''}
                                        name="path"
                                        placeholder="API Url"
                                        validators={['required']}
                                        errorMessages={['Enter the Reference URL details']}
                                        fullWidth
                                        margin="normal"
                                        autoComplete="off"
                                        onChange={(event) => onChange(event.target.name, event.target.value, 0)}
                                    />
                                </Grid>
                                {
                                    config.parameters.map((parameter, index) =>
                                        <Fragment key={index}>
                                            <Grid item xs={5}>
                                                <TextBox
                                                    autoComplete="off"
                                                    placeholder="Key"
                                                    name="key"
                                                    value={parameter.key ? parameter.key : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    onChange={(event) => onChange(event.target.name, event.target.value, index)}
                                                />
                                            </Grid>
                                            <Grid item xs={5}>
                                                <TextBox
                                                    autoComplete="off"
                                                    placeholder="Value"
                                                    name="value"
                                                    value={parameter.value ? parameter.value : ''}
                                                    fullWidth
                                                    margin="normal"
                                                    onChange={(event) => onChange(event.target.name, event.target.value, index)}
                                                />
                                            </Grid>
                                            <Grid item xs={2} align="center">
                                                {
                                                    config.parameters.length - 1 === index &&
                                                    <IconButton onClick={() => addParameter()}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                            <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                                                <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                                                    <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.primary.main} />
                                                                    <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.primary.main} />
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </IconButton>
                                                }
                                                {
                                                    index !== 0 &&
                                                    <IconButton onClick={() => removeParameter(index)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.408" height="22" viewBox="0 0 19.408 22">
                                                            <g transform="translate(-76.035 -52)">
                                                                <path d="M128.865,166H114.641a.549.549,0,0,0-.536.61l1.459,12.768a2.789,2.789,0,0,0,2.77,2.474h7.082a2.777,2.777,0,0,0,2.775-2.525L129.4,166.6a.554.554,0,0,0-.138-.421A.546.546,0,0,0,128.865,166Zm-1.748,13.219a1.708,1.708,0,0,1-1.7,1.555h-7.082a1.715,1.715,0,0,1-1.7-1.523l-1.39-12.173h13.028Z" transform="translate(-36.014 -107.853)" fill={theme.palette.primary.main} />
                                                                <path d="M94.9,54.7H89.19v-1.03A1.682,1.682,0,0,0,87.51,52H83.968a1.682,1.682,0,0,0-1.68,1.667V54.7H76.574a.539.539,0,0,0,0,1.078H94.9a.539.539,0,0,0,0-1.078Zm-6.792,0H83.366v-1.03a.6.6,0,0,1,.6-.588H87.51a.6.6,0,0,1,.6.588Z" fill={theme.palette.primary.main} />
                                                            </g>
                                                        </svg>
                                                    </IconButton>
                                                }
                                            </Grid>
                                        </Fragment>
                                    )

                                }
                            </Grid>
                            <Grid container justify="flex-end" alignItems="center" className={classes.marginTop15}>
                                <Button variant="contained" color="primary" type="submit" className={classNames(classes.actionButtons, classes.submitBtn)}>
                                    Save
                                </Button>
                                <Button onClick={() => onClose()} className={classNames(classes.cancelButton, classes.actionButtons)}>
                                    Cancel
                                </Button>
                            </Grid>
                        </ValidatorForm>
                    </Grid>
            }
        </Popover >
    );
};

ReferenceConfig.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    anchorEl: PropTypes.element,
    onClose: PropTypes.func,
    theme: PropTypes.object,
    config: PropTypes.object,
    addParameter: PropTypes.func,
    removeParameter: PropTypes.func,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    uploadFiles: PropTypes.func
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ReferenceConfig);