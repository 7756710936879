import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Avatar, withStyles, Typography } from '@material-ui/core';
import DatasourceListStyles from './DatasourceListStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import { getInitials, getRandomColor, returnImage } from '../../helpers/appHelpers';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import { appConstants } from '../../constants/appConstants';

const UserList = (props) => {
    const { classes, itemCount, users, navigateDatasource } = props;

    const getName = (user) => {
        let userName = '';
        if (!user) {
            return userName;
        }
        if (user && user.first_name && user.last_name) {
            userName = `${user.first_name} ${user.last_name}`;
        }
        else if (user.first_name !== "") {
            userName = `${user.first_name} ${user.last_name}`;
        } else {
            userName = user.email;
        }
        return userName;

    };

    const getDisplayUsers = () => {
        const userlist = [];
        const usersData = [];
        for (const user of users) {
            if (userlist.length <= itemCount - 1) {
                // if (!userlist.includes(1) && user.access_level === 1) {
                usersData.push({ ...user });
                userlist.unshift(user.level);
                /*
                 * }
                 * if (!userlist.includes(2) && user.access_level === 2) {
                 *     usersData.push({ ...user });
                 *     userlist.unshift(user.level);
                 * }
                 * else if (!userlist.includes(3) && user.access_level === 3) {
                 *     usersData.push({ ...user });
                 *     userlist.unshift(user.level);
                 * } else if (!userlist.includes("owner") && user.is_steward_user) {
                 *     usersData.push({ ...user });
                 *     userlist.unshift("owner");
                 * }
                 */
            } else {
                break;
            }
        }
        return usersData;
    };

    return (
        <Grid style={{ 'display': 'inline-flex' }}>
            {
                getDisplayUsers().map((user, index) =>
                    <Fragment key={`user_${index}`}>
                        <TooltipComponent title={getName(user)} arrow>
                            <Avatar
                                className={classes.userAvatar}
                                style={
                                    {
                                        backgroundImage: `url(${returnImage(appConstants.API_BASE_URL, user.avatar)})`,
                                        backgroundColor: getRandomColor(getName(user))
                                    }
                                }>
                                {
                                    !user.avatar &&
                                    <span className={classes.avatarText}>
                                        {getInitials(getName(user))}
                                    </span>
                                }
                            </Avatar>
                        </TooltipComponent>
                    </Fragment>
                )
            }
            {
                users.length > itemCount &&
                <Typography className={classes.lightTxt} onClick={navigateDatasource} style={{ 'cursor': 'pointer' }}>
                    {`+${users.length - itemCount}`}
                </Typography>
            }
        </Grid>
    );
};

UserList.propTypes = {
    classes: PropTypes.object,
    itemCount: PropTypes.number,
    users: PropTypes.array,
    navigateDatasource: PropTypes.func
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(UserList);