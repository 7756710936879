import { TextField, Typography, Grid, withStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SearchBoxLineageStyle } from './SearchBoxLIneageStyle.jsx';
import { lineageIcons } from './LineageIconStore.jsx';

const SearchBoxLineage = ({ nodes, onSearchNode, classes }) => {
    const [inputValue, setInputValue] = useState("");
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        if (inputValue.length > 0) {
            setOpen(true);
        }
    };

    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        if (newInputValue.length > 0) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    };

    return (
        <Autocomplete
            id="search-lineage-nodes"
            freeSolo
            open={open}
            onOpen={handleOpen}
            onClose={() => setOpen(false)}
            inputValue={inputValue}
            onInputChange={handleInputChange}
            options={nodes.map((option) => option)}
            getOptionLabel={(option) => option.name}
            renderOption={
                (option) => (
                    <Grid className={classes.searchListItemContainer}>
                        <img src={lineageIcons[`${option?.type}Black`] || lineageIcons[`${option?.parentType}Tableau`] || lineageIcons.DefaultBlack} alt={option?.type} className={classes.listIcon} />
                        <Grid className={classes.listTextContainer}>
                            <Typography>
                                {option?.parentName ? `${option?.parentName}/` : ''}
                                {option?.name}
                            </Typography>
                            {
                                option?.schemaString && (<Typography className={classes.searchSourceText}>
                                    {option?.schemaString.split('.').join('/')}
                                                         </Typography>)
                            }
                        </Grid>
                    </Grid>
                )
            }
            className={classes.searchBoxContainer}
            onChange={
                (event, value) => {
                    onSearchNode(value);
                }
            }
            renderInput={
                (params) => (
                    <TextField {...params} label="Search" margin="normal" variant="outlined" />
                )
            }
        />
    );
};

SearchBoxLineage.propTypes = {
    nodes: PropTypes.array,
    onSearchNode: PropTypes.func,
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    ...SearchBoxLineageStyle(theme)
}), { withTheme: true })(SearchBoxLineage);