import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Chip, Tooltip } from '@material-ui/core';
import classNames from 'classnames';
import DatasourceListStyles from '../DatasourceList/DatasourceListStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import Circular from '../Charts/Circular.jsx';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import { getValue } from '../../helpers/appHelpers.js';
import TooltipComponent from '../Tooltip/Tooltip.jsx';

const DescTooltip = withStyles((theme) => ({
    arrow: {
        color: '#444'
    },
    tooltip: {
        maxWidth: 500,
        backgroundColor: '#444',
        maxHeight: 100,
        textOverflow: "ellipsis",
        overflow: "hidden",
        webkitLlineClamp: 4,
        webkitBoxOrient: 'vertical'
    }
}))(Tooltip);


const DataPreparationListDetail = (props) => {

    const { classes, source, theme, index, getDatasourceIcon, editData } = props;

    return (
        <Grid className={classes.cardContainer}>
            <Grid container>
                <Grid item className={classes.listContainer}>
                    <Grid container className={classes.catdetailContainer}>
                        <Grid item xs={6} className={classes.detailContainer}>
                            <Grid className={
                                classNames(source.icon ? classes.dataSourceImgIcon : classes.dataSourceImg,
                                    source.name === 'Untitled Data Source' ? 'default-image' : ''
                                )
                            }
                                container
                                alignItems="center"
                                justify="center">
                                {
                                    source.icon ?
                                        <img src={require(`../../assets/images/sourceIcons/${source.icon}.png`)} alt={source.icon} />
                                        : getDatasourceIcon(source)
                                }
                            </Grid>
                            <Grid className={classNames(classes.titleContainer)}>
                                <TooltipComponent title={source.name} placement="bottom-start" arrow>
                                    <Typography id={`catalog_${index}`} component="h5" variant="h5" className={classes.titleTxt} onClick={() => editData(source, "main")}>
                                        {source.name ? source.name : 'Untitled Data Preparation'}
                                    </Typography>
                                </TooltipComponent>
                            </Grid>
                        </Grid>

                        <Grid item xs={6} align="right">
                            <Grid container alignItems="center" justify="flex-end">
                                <Grid className={classes.paddingRight15}>
                                    <TooltipComponent title={"Action Items"} arrow>
                                        <Grid container alignItems="center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 26 27.791" fill="#F38080">
                                                <defs />
                                                <g transform="translate(-16.5 0)">
                                                    <path d="M85.855,145H81.346a.846.846,0,0,0,0,1.691h4.509a.846.846,0,0,0,0-1.691Z" transform="translate(-60.392 -137.148)" />
                                                    <path d="M85.855,225H81.346a.846.846,0,1,0,0,1.691h4.509a.846.846,0,1,0,0-1.691Z" transform="translate(-60.392 -212.816)" />
                                                    <path d="M85.855,305H81.346a.846.846,0,0,0,0,1.691h4.509a.846.846,0,0,0,0-1.691Z" transform="translate(-60.392 -288.484)" />
                                                    <path d="M36.8,14.923V7.49a4.047,4.047,0,0,0-1.165-2.851l-3.3-3.416,0,0A4.1,4.1,0,0,0,29.423,0H18.943A2.445,2.445,0,0,0,16.5,2.443V23.557A2.445,2.445,0,0,0,18.943,26H31.526A6.472,6.472,0,1,0,36.8,14.923ZM18.128,23.557V2.443a.815.815,0,0,1,.814-.814H29.423a2.459,2.459,0,0,1,1.741.728l3.3,3.416,0,0a2.428,2.428,0,0,1,.7,1.712v7.434a6.457,6.457,0,0,0-4.884,9.448H18.943a.815.815,0,0,1-.814-.814Zm17.858,2.605a4.831,4.831,0,1,1,4.885-4.831A4.836,4.836,0,0,1,35.986,26.163Z" />
                                                    <path d="M334.041,354.2a.846.846,0,0,0-1.191.108l-1.427,1.712-.324-.647a.846.846,0,0,0-1.513.756l.9,1.8a.846.846,0,0,0,.659.462.86.86,0,0,0,.1.006.846.846,0,0,0,.649-.3l2.255-2.706A.846.846,0,0,0,334.041,354.2Z" transform="translate(-296.151 -334.919)" />
                                                    <path d="M241.346,146.691h.9a.846.846,0,1,0,0-1.691h-.9a.846.846,0,0,0,0,1.691Z" transform="translate(-211.891 -137.148)" />
                                                    <path d="M243.093,225.846a.846.846,0,0,0-.846-.846h-.9a.846.846,0,1,0,0,1.691h.9A.846.846,0,0,0,243.093,225.846Z" transform="translate(-211.891 -212.816)" />
                                                </g>
                                            </svg>
                                            <Typography component="span" className={classes.viewTxt}>
                                                {`${source.actionitems ? source.actionitems : 0} Action Items`}
                                            </Typography>
                                        </Grid>
                                    </TooltipComponent>
                                </Grid>

                                <Grid item className={classes.paddingRight15}>
                                    <TooltipComponent title={"Conversation"} arrow>
                                        <Grid container alignItems="center">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18.421" viewBox="0 0 22 18.421">
                                                <path fill={theme.palette.primary.main} d="M2.371,22.928A1.379,1.379,0,0,1,1,21.557V10.278A2.845,2.845,0,0,1,3.838,7.44H17.221a2.845,2.845,0,0,1,2.845,2.838v7.517a2.845,2.845,0,0,1-2.838,2.845H6.067a.6.6,0,0,0-.352.117L3.2,22.657a1.386,1.386,0,0,1-.829.271ZM3.838,8.907a1.379,1.379,0,0,0-1.371,1.371v11.1l2.369-1.789a2.083,2.083,0,0,1,1.232-.411H17.221A1.379,1.379,0,0,0,18.6,17.795V10.278a1.379,1.379,0,0,0-1.371-1.371Z" transform="translate(-1 -4.507)" />
                                                <circle fill={theme.palette.primary.main} cx="0.733" cy="0.733" r="0.733" transform="translate(11.689 8.866)" />
                                                <circle fill={theme.palette.primary.main} cx="0.733" cy="0.733" r="0.733" transform="translate(8.844 8.866)" />
                                                <circle fill={theme.palette.primary.main} cx="0.733" cy="0.733" r="0.733" transform="translate(5.911 8.866)" />
                                                <path fill={theme.palette.primary.main} d="M23.6,14.44a.733.733,0,0,1-.733-.733V6.278A1.379,1.379,0,0,0,21.5,4.907H6.733a.733.733,0,1,1,0-1.467H21.5a2.845,2.845,0,0,1,2.838,2.838v7.429A.733.733,0,0,1,23.6,14.44Z" transform="translate(-2.333 -3.44)" />
                                            </svg>
                                            <Typography component="span" className={classes.viewTxt}>
                                                {`${source.conversations ? source.conversations : 0} Conversations`}
                                            </Typography>
                                        </Grid>
                                    </TooltipComponent>
                                </Grid>

                                <Grid item>
                                    <TooltipComponent title={"Views"} arrow>
                                        <Grid container alignItems="center">
                                            <svg version="1.1"
                                                id="Layer_1"
                                                width="22"
                                                height="22"
                                                xmlns="http://www.w3.org/2000/svg"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 32 32" >
                                                <g id="Chart">
                                                    <path fill="#F38080"
                                                        d="M26.5,26.6v-15h-0.8v0h-4v0h-0.3v15h-2.7v-8.9h-0.8v0h-4v0h-0.3v8.9h-2.8v-4.8H10H8.8H7.7H6H5.7v4.8H4v2h24v-2
		H26.5z M23.3,13.7h1.1v12.9h-1.1V13.7z M15.5,19.7h1.1v6.9h-1.1V19.7z M7.7,23.8h1.1v2.8H7.7V23.8z" />
                                                    <g>
                                                        <g transform="translate(2.368)">
                                                            <path fill="#F38080"
                                                                stroke="#F38080"
                                                                strokeWidth="0.6"
                                                                strokeMiterlimit="10"
                                                                d="M24.1,3.9c0-0.3-0.2-0.5-0.5-0.5c0,0,0,0,0,0h-3c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h1.8l-4.9,4.9
				l-1.8-1.8c-0.2-0.2-0.5-0.2-0.7,0c0,0,0,0,0,0l-8.8,8.8C6,16.5,6,16.8,6.2,17c0.2,0.2,0.5,0.2,0.7,0l0,0l8.5-8.5l1.8,1.8
				c0.2,0.2,0.5,0.2,0.7,0c0,0,0,0,0,0l5.2-5.2v1.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5L24.1,3.9
				C24.1,3.9,24.1,3.9,24.1,3.9z" />
                                                        </g>
                                                        <g transform="translate(1.437 8.101)">
                                                            <circle fill="#F38080" cx="5.8" cy="10.2" r="1" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>

                                            <Typography component="span" className={classes.viewTxt}>
                                                {`${source.views ? source.views : 0} Views`}
                                            </Typography>
                                        </Grid>
                                    </TooltipComponent>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {
                        source.description && source.description.length > 0 ? (
                            <DescTooltip className={classes.descriptionTooltip} title={source.description} arrow>
                                <Typography className={classNames(classes.descriptionTxt, 'description')} variant="body2">
                                    {source.description}
                                </Typography>
                            </DescTooltip>
                        ) : null
                    }


                    <Grid container className={classes.datasetContainer}>
                        <Grid item xs={12}>
                            {
                                source.dataset_configuration && source.dataset_configuration.map((dataset, index) =>
                                    <Fragment key={`dataset_${index}`}>
                                        {index <= 3 && <Chip className={classNames(classes.chip, classes.linkChips)} label={dataset.name} onClick={() => editData({ ...source, datasetId: dataset.id }, "dataset")} />}
                                    </Fragment>
                                )
                            }
                            {
                                source.dataset_configuration && source.dataset_configuration.length > 4 &&
                                <Typography variant="body2" className={classes.lightTxt}>
                                    {`+${source.dataset_configuration.length - 4} more Dataset`}
                                </Typography>
                            }
                        </Grid>


                    </Grid>
                </Grid>
                <Grid item className={classes.dqScoreContainer}>
                    <Grid className={classNames(classes.dqScoreSection, classes.dqScoreSectionCatList)}>
                        <Grid onClick={() => editData(source, "dqscore")} className={classes.pointer}>
                            <Circular
                                showPercentage={Boolean(source.dqscore && source.dqscore !== 0)}
                                showValue
                                value={source.dqscore}
                                className={classes.dqScoreNumber}
                                size={50}
                                circularFont={13} />
                            <Typography className={classes.dqScoreTxt}>
                                DQScore
                            </Typography>
                        </Grid>
                        {
                            Boolean(source.impactScore && source.impactScore !== 0) &&
                            <Grid className={classes.dqscoreimpactContainer}>
                                <ArrowDropUpIcon className={classes.impactIcon} />
                                <Typography className={classes.impactTxt} component="span">
                                    {source.impactScore ? `${getValue(source.impactScore)}%` : '0%'}
                                </Typography>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

DataPreparationListDetail.propTypes = {
    classes: PropTypes.object,
    source: PropTypes.object,
    theme: PropTypes.object,
    getDatasourceIcon: PropTypes.func,
    index: PropTypes.number,
    editData: PropTypes.func
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DataPreparationListDetail);