const RestApiConnectorStyles = (theme) => ({
    root: {
        flexGrow: 1
    },
    tablecontainer: {
        maxHeight: '250px',
        overflow: 'auto'
    }


});

export default RestApiConnectorStyles;