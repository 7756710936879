import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, withStyles, Typography, Switch, Slider, TextField } from '@material-ui/core';
import DatasetStyles from '../../DatasetStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';
import TextBox from '../../../TextBox/TextBox.jsx';
import primarykey from '../../../../assets/images/primarykey.svg';
import masterkey from '../../../../assets/images/masterkey.svg';
import { updateProperties } from '../../../../actions/datasetActions';
import { useDispatch, useSelector } from 'react-redux';
import TileViewStyles from './TileViewStyles.jsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Localization from '../../../../constants/localization';
import AutoCompleteInput from '../../../AutoComplete/AutoCompleteInput.jsx';
import { sortTable } from '../../../../helpers/appHelpers.js';
import Tags from '../../../Tags/Tags.jsx';


const AttributeProperty = (props) => {
    const { classes, attribute, isEditable, updatePropertiesList } = props;
    const dispatch = useDispatch();
    const attributesCatalogInfo = useSelector(({ datasource }) => datasource.attributesCatalogInfo);
    const datasetId = useSelector(({ dataset }) => dataset.selectedDatasetId);
    const semanticModelAttributes = useSelector(({ master }) => master.modelFieldTypes);
    const [isChanged, setInputChanged] = useState(false);
    const [tagList, setTagList] = useState([]);
    const [property, setProperty] = useState({
        'business_name': '',
        'is_null': false,
        'is_blank': false,
        'is_unique': false,
        'primary_key': false,
        description: '',
        master: {
            on: false,
            value: '',
            attribute: {}
        },
        sensitivity: 3,
        report: ''
    });

    const onHandleChange = useCallback((propertyName, value, event) => {
        if (propertyName === "sensitivity") {
            event.stopPropagation();
        }
        property[propertyName] = value;
        if (propertyName === 'primary_key') {
            if (value) {
                property['is_unique'] = true;
                property['is_null'] = false;
                property['is_blank'] = false;
            }
        }
        if (propertyName === 'is_unique') {
            if (value) {
                property['is_null'] = false;
                property['is_blank'] = false;
            }
        }
        setProperty({ ...property });
        setInputChanged(true);
    }, [property]);

    const compareProperties = () => {
        const changedProperties = [];
        if (property.business_name !== attribute.business_name) {
            changedProperties.push("business_name");
        }
        if (property.is_null !== attribute.is_null) {
            changedProperties.push("is_null");
        }
        if (property.is_unique !== attribute.is_unique) {
            changedProperties.push("is_unique");
        }
        if (property.is_blank !== attribute.is_blank) {
            changedProperties.push("is_blank");
        }
        if (property.primary_key !== attribute.primary_key) {
            changedProperties.push("primary_key");
        }
        if (property.sensitivity !== attribute.sensitivity) {
            changedProperties.push("sensitivity");
        }
        if (property.business_name !== attribute.business_name) {
            changedProperties.push("business_name");
        }
        if (property.description !== attribute.description) {
            changedProperties.push("description");
        }
        if (JSON.stringify(property.master) !== JSON.stringify(attribute.master)) {
            changedProperties.push("master");
        }
        if (property.report !== attribute.report) {
            changedProperties.push("report");
        }
        return changedProperties;

    };

    const updateAttributeProps = () => {
        if (isChanged) {
            setInputChanged(false);
            const changedProperties = compareProperties();
            updatePropertiesList({ id: attribute.id, name: attribute.name, value: property });
            dispatch(updateProperties(datasetId, { id: attribute.id, attribute: attribute.name, value: property, 'changed_properties': changedProperties }));
        }
    };

    const loadAttribute = useCallback((attribute) => {
        setProperty({
            'id': attribute.id,
            'business_name': attribute.business_name ? attribute.business_name : '',
            'is_null': Boolean(attribute.is_null),
            'is_blank': Boolean(attribute.is_blank),
            'is_unique': Boolean(attribute.is_unique),
            'primary_key': Boolean(attribute.primary_key),
            'description': attribute.description,
            master: attribute.master ? attribute.master : {
                on: false,
                value: '',
                attribute: {}
            },
            sensitivity: attribute.sensitivity ? attribute.sensitivity : 1,
            report: attribute.report
        });
    }, []);

    useEffect(() => {
        if (attribute && property.id === attribute.id) {
            return;
        }
        loadAttribute(attribute);
    }, [attribute, loadAttribute, property.id]);

    useEffect(() => {
        setTagList(attribute.tags);
    }, [attribute.tags]);

    const getPrecisionLableText = (datatype, lableType) => {
        let text = lableType === 'Title' ? 'Precision' : 'Data type Precision';
        switch (datatype) {
            case 'NUMBER':
                text = lableType === 'Title' ? 'Precision and Scale' : 'Data type precision and Scale';
                break;
            case 'TEXT':
                text = lableType === 'Title' ? 'Character Max Length and Octet Length' : 'Data type character maximum length and octet length';
                break;
            default:
                text = lableType === 'Title' ? 'Precision' : 'Data type Precision';
                break;
        }
        return text;
    };

    const updateTags = (value, type, index) => {
        const tags = [...tagList];
        value = value.trim();
        if (type === "add") {
            const tagExist = tags.some((tag) => tag?.toLowerCase() === value?.toLowerCase());
            if (!tagExist) { tags.push(value); }
            setTagList(tags);
            updateTagItem(tags);
        } else {
            if (index === -1) { tags.splice(tags.length - 1, 1); }
            else { tags.splice(index, 1); }
            setTagList(tags);
            updateTagItem(tags);
        }
    };

    const updateTagItem = (newtags) => {
        const updated_tags = { ...property };
        updated_tags.tags = newtags ? newtags : tagList;
        const changedProperties = compareProperties();
        updatePropertiesList({ id: attribute.id, name: attribute.name, value: updated_tags });
        dispatch(updateProperties(datasetId, { source_id: attribute.source, attrib_id: attribute.attribute, id: attribute.id, attribute: attribute.name, value: property, tags: newtags ? newtags : tagList, 'changed_properties': changedProperties }));
    };

    let attributeCatalogInfo = null;
    if (attributesCatalogInfo && attributesCatalogInfo.length > 0) {
        attributeCatalogInfo = attributesCatalogInfo.find((p) => p.attribute_name?.toLowerCase() === attribute.name?.toLowerCase());
    }

    return (
        <Grid container direction="column" wrap="nowrap" onMouseLeave={() => updateAttributeProps()}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.propertyRow}>
                <Grid container direction="row" wrap="nowrap" spacing={4} >
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Grid container direction="column" wrap="nowrap">
                            <Typography component="h6" variant="h6">
                                {'Attribute Business Name'}
                            </Typography>
                            <Typography variant="body2">
                                {Localization.properties.property.businessName}
                            </Typography>
                            <Grid item className={classNames(classes.textboxContainer, classes.sliderInput, classes.propertyComponents)}>
                                <TextBox placeholder="Business Name"
                                    name="business_name"
                                    id="business_name"
                                    value={property.business_name ? property.business_name : ''}
                                    onChange={(event) => onHandleChange(event.target.name, event.target.value)}
                                    className={classNames(classes.inputOutline, classes.businessNameTextBox)}
                                    disabled={!isEditable} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Grid container direction="column" wrap="nowrap">
                            <Typography component="h6" variant="h6">
                                {'Null / Blank / Unique'}
                            </Typography>
                            <Typography variant="body2">
                                {Localization.properties.property.nullblank}
                            </Typography>
                            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.propertyComponents}>
                                <Grid item>
                                    <FormControlLabel style={{ marginLeft: 0 }}
                                        control={
                                            <Switch checked={Boolean(property.is_null)}
                                                color="secondary"
                                                name="is_null"
                                                disabled={!isEditable || (property.is_unique || property.primary_key)}
                                                onChange={(event) => onHandleChange(event.target.name, event.target.checked)} />
                                        }
                                        label="Null"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Switch checked={Boolean(property.is_blank)}
                                                color="secondary"
                                                name="is_blank"
                                                disabled={!isEditable || (property.is_unique || property.primary_key)}
                                                onChange={(event) => onHandleChange(event.target.name, event.target.checked)} />
                                        }
                                        label="Blank"
                                    />
                                </Grid>
                                <Grid item>
                                    <FormControlLabel
                                        control={
                                            <Switch checked={Boolean(property.is_unique)}
                                                color="secondary"
                                                name="is_unique"
                                                disabled={!isEditable || (property.primary_key)}
                                                onChange={(event) => onHandleChange(event.target.name, event.target.checked)} />
                                        }
                                        label="Unique"
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Grid container direction="column" wrap="nowrap">
                            <Typography component="h6" variant="h6">
                                <img src={primarykey} alt="primary" className={classes.keyLogo} />
                                { attributeCatalogInfo && attributeCatalogInfo.key && attributeCatalogInfo.key === 'Foreign Key' ? 'Primary / Foreign Key' : 'Primary'}
                            </Typography>
                            <Typography variant="body2">
                                {Localization.properties.property.primary}
                            </Typography>
                            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.propertyComponents}>
                                <Grid item>
                                    <FormControlLabel style={{ marginLeft: 0 }}
                                        control={
                                            <Switch checked={Boolean(property.primary_key)}
                                                color="secondary"
                                                name="primary_key"
                                                disabled={!isEditable}
                                                onChange={(event) => onHandleChange(event.target.name, event.target.checked)} />
                                        }
                                        label={property.primary_key ? 'ON' : 'OFF'}
                                    />
                                </Grid>
                                {
                                    attributeCatalogInfo && attributeCatalogInfo.key && attributeCatalogInfo.key === 'Foreign Key' &&
                                    <Grid item>
                                        <FormControlLabel
                                            control={
                                                <Switch checked
                                                    color="secondary"
                                                    name="foreign_key"
                                                    disabled />
                                            }
                                            label={'ON'}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.propertyRow}>
                <Grid container direction="column" wrap="nowrap">
                    <Typography component="h6" variant="h6">
                        {'Attribute Description'}
                    </Typography>
                    <Grid item className={classNames(classes.sliderInput, classes.propertyComponents)}>
                        <TextBox placeholder={Localization.properties.property.description}
                            name="description"
                            id="description"
                            value={property.description ? property.description : ''}
                            onChange={(event) => onHandleChange(event.target.name, event.target.value)}
                            className={classNames(classes.inputOutline, classes.AttributeInputDesc)}
                            disabled={!isEditable}
                            fullWidth />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.propertyRow}>
                <Grid container direction="row" wrap="nowrap" spacing={4} >
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Grid container direction="column" wrap="nowrap">
                            <Typography component="h6" variant="h6">
                                {'Sensitivity'}
                            </Typography>
                            <Typography variant="body2">
                                {Localization.properties.property.sensitivity}
                            </Typography>
                            <Grid className={classes.propertyComponents}>
                                <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                                    <Slider name="sensitivity"
                                        color="secondary"
                                        style={{ width: '45%' }}
                                        className={classNames(classes.propertyRangeSlider, classes.SensitivityMuiSlider)}
                                        defaultValue={1}
                                        track={'normal'}
                                        value={property.sensitivity ? property.sensitivity : 1}
                                        onChange={(_event, value) => onHandleChange('sensitivity', value, _event)}
                                        marks
                                        min={1}
                                        max={3}
                                        step={1}
                                        disabled={!isEditable}
                                    />
                                    <Typography variant="body2" className={classes.sliderValue}>
                                        {property.sensitivity ? property.sensitivity : '1'}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Grid container direction="column" wrap="nowrap">
                            <Typography component="h6" variant="h6">
                                <img src={masterkey} alt="master" className={classes.keyLogo} />
                                {'Master'}
                            </Typography>
                            <Typography variant="body2">
                                {Localization.properties.property.master}
                            </Typography>
                            <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.propertyComponents}>
                                <Grid item>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" wrap="nowrap">
                                        <FormControlLabel style={{ marginLeft: 0 }}
                                            control={
                                                <Switch checked={Boolean(property.master.on)}
                                                    color="secondary"
                                                    name="master"
                                                    disabled={!isEditable}
                                                    onChange={(event) => onHandleChange(event.target.name, { ...property.master, on: !property.master.on })}
                                                />
                                            }
                                            label={property.master.on ? 'ON' : 'OFF'}
                                        />
                                    </Grid>
                                </Grid>
                                {
                                    property.master.on &&
                                    <Grid item style={{ marginLeft: '15px' }} className={classNames(classes.masterTextboxContainer, classes.sliderInput)}>
                                        <AutoCompleteInput
                                            name="master"
                                            options={sortTable(semanticModelAttributes, "asc", "qualified_name")}
                                            value={property && property.master && property.master.value ? property.master.value : ''}
                                            getOptionLabel={
                                                (option) => {
                                                    if (option && option.qualified_name) {
                                                        return option.qualified_name;
                                                    }
                                                    return option;
                                                }
                                            }
                                            getOptionSelected={(option, value) => option.qualified_name === value}
                                            renderInput={(params) => <TextField {...params} disabled={!isEditable} fullWidth className={classNames(classes.inputOutline, classes.fontSize13)} placeholder="Master Attribute" />}
                                            onChange={
                                                (_, selectedValue) => onHandleChange('master', {
                                                    ...property.master,
                                                    value: selectedValue && selectedValue.qualified_name ? selectedValue.qualified_name : '',
                                                    attribute: selectedValue ? { ...selectedValue } : {}
                                                })
                                            }
                                            openOnFocus
                                            blurOnSelect
                                            fullWidth
                                            disabled={!isEditable}
                                            forcePopupIcon={false}
                                            noOptionsText={"No attributes"}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Grid container direction="column" wrap="nowrap">
                            <Typography component="h6" variant="h6">
                                {'Report'}
                            </Typography>
                            <Typography variant="body2">
                                {Localization.properties.property.report}
                            </Typography>
                            <Grid container direction="row" justify="space-between" alignItems="center">
                                <Grid item style={{ width: '100%' }}>
                                    <Grid container direction="row" justify="flex-start" alignItems="center" className={classes.propertyComponents}>
                                        <Grid item
                                            className={classNames(classes.reportType, property.report === 'dimension' ? classes.selectedReportType : '')}
                                            // eslint-disable-next-line no-nested-ternary
                                            onClick={() => (isEditable ? (property.report === 'dimension' ? onHandleChange('report', '') : onHandleChange('report', 'dimension')) : null)}
                                            disabled={!isEditable}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className={classes.reportTypeImg} width="20" height="20" viewBox="0 0 20 20" style={{ fill: "#e1e5e6", marginRight: "8px" }}>
                                                <defs />
                                                <path className="a" d="M10,0,0,2.5V15l10,5,10-5V2.5Zm8,3.25L10.625,6,2.375,3.25,10,1.25l8,2Zm-16.75,11V4.125l8.75,3v11.5Z" />
                                            </svg>
                                            {'Dimension'}
                                        </Grid>
                                        <Grid item
                                            className={classNames(classes.reportType, property.report === 'measure' ? classes.selectedReportType : '')}
                                            // eslint-disable-next-line no-nested-ternary
                                            onClick={() => (isEditable ? (property.report === 'measure' ? onHandleChange('report', '') : onHandleChange('report', 'measure')) : null)}
                                            disabled={!isEditable}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className={classes.reportTypeImg} width="22" height="15.555" viewBox="0 0 22 15.555" style={{ fill: "#e1e5e6", marginRight: "8px" }}>
                                                <g transform="translate(0 -150)">
                                                    <path className="a" d="M21.355,164.266h-.687V150.645a.644.644,0,0,0-.645-.645H17.445a.644.644,0,0,0-.645.645v13.621H15.512v-9.754a.644.644,0,0,0-.645-.645H12.289a.644.644,0,0,0-.645.645v9.754H10.355v-4.6a.644.644,0,0,0-.645-.645H7.133a.644.644,0,0,0-.645.645v4.6H5.2V157.09a.644.644,0,0,0-.645-.645H1.977a.644.644,0,0,0-.645.645v7.176H.645a.645.645,0,1,0,0,1.289H21.355a.645.645,0,1,0,0-1.289Zm0,0" transform="translate(0 0)" />
                                                </g>
                                            </svg>
                                            {'Measure'}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {
                attributeCatalogInfo &&
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.propertyRow}>
                    <Grid container direction="row" wrap="nowrap" spacing={4} >
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Grid container direction="column" wrap="nowrap">
                                <Typography component="h6" variant="h6">
                                    {'Usage Count '}
                                </Typography>
                                <Typography variant="body2">
                                    {'Attribute Usage counts in query'}
                                </Typography>
                                <Typography variant="body2">
                                    {attributeCatalogInfo.usage_count ? attributeCatalogInfo.usage_count : 0}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Grid container direction="column" wrap="nowrap">
                                <Typography component="h6" variant="h6">
                                    {'Data Type '}
                                </Typography>
                                <Typography variant="body2">
                                    {'Database data type'}
                                </Typography>
                                <Typography variant="body2" >
                                    {attributeCatalogInfo.datatype}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Grid container direction="column" wrap="nowrap">
                                <Typography component="h6" variant="h6">
                                    {getPrecisionLableText(attributeCatalogInfo.datatype, 'Title')}
                                </Typography>
                                <Typography variant="body2">
                                    {getPrecisionLableText(attributeCatalogInfo.datatype, 'Subtitle')}
                                </Typography>
                                <Typography variant="body2">
                                    {attributeCatalogInfo.precision}
                                    {' - '}
                                    {attributeCatalogInfo.scale}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.propertyRow}>
                <Grid container direction="column" wrap="nowrap">
                    <Typography component="h6" variant="h6">
                        {'Tags'}
                    </Typography>
                    <Tags type="tags" focus attributTag tags={tagList} updateTags={updateTags} disabled={!isEditable} updateTagItem={updateTagItem} />
                </Grid>
            </Grid>
        </Grid>
    );
};

AttributeProperty.propTypes = {
    classes: PropTypes.object,
    attribute: PropTypes.object,
    isEditable: PropTypes.bool,
    updatePropertiesList: PropTypes.func
};

export default withStyles((theme) => ({
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}))(AttributeProperty);