import React, { useCallback, memo, useRef } from 'react';
import {
    Grid, withStyles, Typography,
    Card, Table, TableBody, TableCell, TableRow, TableHead
} from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Styles from '../../layouts/Styles.jsx';
import { BarChart, Bar, Tooltip, Cell } from 'recharts';
import { getDataTypeColor, dataAccessRestrict } from '../../helpers/appHelpers.js';
import DomainStyles from '../Domain/DomainStyles.jsx';
import DatasetStyles from '../Dataset/DatasetStyles.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';

const DomainData = (props) => {
    const { classes, theme, previewData, headers } = props;
    const scrollRef = useRef();

    const isInvalidCell = useCallback((row, attribute) => {
        let isInvalid = false;
        const attributeName = attribute && attribute.name ? attribute.name.replace(/[^A-Za-z0-9_]/g, '_').toLowerCase() : "";
        if (previewData.invalidRows && previewData.invalidRows.length > 0) {
            const rowIndex = previewData.invalidRows.find((p) => p.temp_row_index === row.temp_row_index && p.attributes.indexOf(attributeName) > -1);
            isInvalid = Boolean(rowIndex);
        }
        return isInvalid;
    }, [previewData.invalidRows]);

    const getColor = (attribute) => {
        if (!attribute) { return ''; }
        let value = attribute && attribute.dqscore ? attribute.dqscore : 0;
        value = value ? parseFloat(value) : 0;
        if (value <= 23) { return theme.palette.chartColors.score25; }
        else if (value > 23 && value <= 74) { return theme.palette.chartColors.score50; }
        return theme.palette.chartColors.score100;
    };

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classNames(classes.attributesTableContainer, classes.marginTop15, classes.dataContainer, previewData.attributes && previewData.attributes.length <= 0 ? 'loading' : '')} style={{ position: 'relative' }}>
                {
                    headers && headers.length > 0 &&
                    <Grid>
                        <Card className={classes.accordian}>
                            <Grid container className={classNames(classes.attributesTable)}>
                                <Table stickyHeader aria-label="sticky table" className={classes.domainDataTable} cellSpacing={1}>
                                    <TableHead>
                                        <TableRow>
                                            {
                                                headers && previewData.attributes &&
                                                headers.map((header, index) => {
                                                    return (
                                                        <TableCell key={`header${index}`} align="center" style={{ padding: '2px 10px 2px 10px', backgroundColor: theme.palette.common.white }}>
                                                            <Grid>
                                                                <ToolTipComponent title={previewData.attributes && previewData.attributes[header] && previewData.attributes[header].name ? previewData.attributes[header].name : ''} arrow>
                                                                    <Typography className={classNames(classes.typeText, classes.attributethead)} style={{ textTransform: 'none' }}>
                                                                        {previewData.attributes && previewData.attributes[header] && previewData.attributes[header].name ? previewData.attributes[header].name : ''}
                                                                    </Typography>
                                                                </ToolTipComponent>
                                                            </Grid>
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                        <TableRow>
                                            {
                                                headers && previewData.attributes && Object.keys(previewData.attributes).length > 0 &&
                                                headers.map((header, index) => {
                                                    return (
                                                        <TableCell key={`datatype${index}`} align="center" style={{ padding: 6, backgroundColor: theme.palette.common.white }}>
                                                            <Grid className={classes.attributeTableDataType}>
                                                                <Grid className={classes.typeIcon}
                                                                    style={
                                                                        {
                                                                            color: getDataTypeColor(previewData.attributes && previewData.attributes[header] && previewData.attributes[header].datatype ? previewData.attributes[header].datatype : ''),
                                                                            marginRight: '10px'
                                                                        }
                                                                    }>
                                                                    {previewData.attributes && previewData.attributes[header] && previewData.attributes[header].datatype ? previewData.attributes[header].datatype[0].toUpperCase() : 'T'}
                                                                </Grid>
                                                                <Typography className={classNames(classes.typeText, classes.attributethead)}>
                                                                    {previewData.attributes && previewData.attributes[header] && previewData.attributes[header].datatype ? previewData.attributes[header].datatype : 'Text'}
                                                                </Typography>
                                                            </Grid>
                                                        </TableCell>
                                                    );
                                                })
                                            }
                                        </TableRow>
                                        <TableRow className={classes.attributesTableChartRow}>
                                            {
                                                headers && previewData.attributes && Object.keys(previewData.attributes).length > 0 &&
                                                headers.map((header, attrIndex) =>
                                                    <TableCell key={`chart${attrIndex}`} align="center" style={{ padding: 0 }}>
                                                        <BarChart width={150} style={{ margin: 'auto' }} height={50} data={previewData.attributes && previewData.attributes[header] && previewData.attributes[header].rules ? [...previewData.attributes[header].rules.filter((p) => p.dqscore > 0)] : []}>
                                                            <Bar stackId="a"
                                                                dataKey="dqscore"
                                                                barSize={10}
                                                                barGap={5}>
                                                                {
                                                                    previewData.attributes && previewData.attributes[header] && previewData.attributes[header].rules && previewData.attributes[header].rules.filter((p) => p.dqscore > 0).map((rule, ruleIndex) => {
                                                                        return <Cell key={`barIndex${attrIndex}${ruleIndex}`} fill={getColor(rule)} />;
                                                                    })
                                                                }
                                                            </Bar>
                                                            <Tooltip content={
                                                                ({ active, payload }) => {
                                                                    if (active && payload && payload.length > 0) {
                                                                        return (
                                                                            <Grid className={classes.tooltip}>
                                                                                <Typography>
                                                                                    {`${payload[0].payload.name}: ${payload[0].payload.dqscore}%`}
                                                                                </Typography>
                                                                            </Grid>
                                                                        );
                                                                    }
                                                                }
                                                            }
                                                                cursor={{ opacity: 0.5 }} />
                                                        </BarChart>

                                                    </TableCell>
                                                )
                                            }
                                        </TableRow>
                                    </TableHead>

                                    <TableBody ref={scrollRef}>
                                        {
                                            previewData.inputData && previewData.inputData.length > 0 &&
                                            previewData.inputData.map((row, index) => (
                                                <TableRow key={`invalidInputData${index}`}>
                                                    {
                                                        headers &&
                                                        headers.map((header, index) => {
                                                            const value = dataAccessRestrict(row[header], previewData.attributes[header] ? previewData.attributes[header].sensitivity : 1, previewData.userAccessLevel ? previewData.userAccessLevel : 1);
                                                            return (
                                                                <TableCell key={`values${previewData.attributes[header] ? previewData.attributes[header].name : ""}${index}`}
                                                                    align="center"
                                                                >
                                                                    <ToolTipComponent title={value} arrow>
                                                                        <Typography className={classes.oneLineEllip} noWrap style={{ height: value ? 'auto' : '42px', backgroundColor: isInvalidCell(row, previewData.attributes[header]) ? '#fcf0ef' : theme.palette.common.white }}>
                                                                            {value}
                                                                        </Typography>
                                                                    </ToolTipComponent>
                                                                </TableCell>
                                                            );
                                                        })
                                                    }
                                                </TableRow>
                                            ))
                                        }

                                    </TableBody>
                                </Table>
                            </Grid>
                        </Card>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};

DomainData.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    previewData: PropTypes.object,
    headers: PropTypes.array
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...DatasetStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(memo(DomainData));