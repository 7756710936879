import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Chip, Grid, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';

import MultiSelect from '../MultiSelect/MultiSelect.jsx';
import Styles from '../../layouts/Styles.jsx';
import TagStyles from './ChipSelectListStyles.jsx';
import TooltipComponent from '../Tooltip/Tooltip.jsx';

class ChipSelectList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            input: '',
            isOpen: false,
            list: []
        };
        this.inputRef = React.createRef();
    }

    static getDerivedStateFromProps(props, state) {
        if (state.list.length === 0 && props.availableList && props.availableList.length !== 0) {
            const items = props.availableList.map((list) => {
                return {
                    ...list,
                    isSelected: false
                };
            });
            return {
                ...state,
                list: [...items]
            };
        }
        return { ...state };
    }


    enableChipEdit = () => {
        if (!this.props.disabled) {
            this.setState({ isOpen: true }, () => {
                const focusElement = document.getElementById('downshift-0-input');
                if (focusElement) {
                    focusElement.focus();
                }
            });
        }
    }

    handleChange = (value) => {
        value = this.props.lowerCase ? value.toLowerCase() : value;
        this.setState({ input: value });
    }

    handleInputLeave = () => {
        this.setState({ input: "", isOpen: false });
        this.props.onBlur(this.props.property, this.props.items);
    }

    onChangeList = (value) => {
        const items = this.props.items;
        const selectedItem = this.state.list.find((p) => p.name === value);
        this.setState({ input: "" });
        if (selectedItem) {
            items.push({ ...selectedItem });
            this.props.onUpdate(this.props.property, items);
        }
    }

    onRemoveItem = (index) => {
        const items = this.props.items;
        items.splice(index, 1);
        this.props.onUpdate(this.props.property, items);
    }

    removeChip(index) {
        const items = this.props.items;
        items.splice(index, 1);
        this.props.onUpdate(this.props.property, items);
        this.props.onBlur(this.props.property, items);
    }

    handleKeyDown = (event) => {
        const items = this.props.items;
        if (event.keyCode === 13 && event.target.value.trim() !== '') {
            items.push(this.props.lowerCase ? event.target.value.toLowerCase() : event.target.value);
            this.props.onUpdate(this.props.property, items);
            this.setState({ input: '' });
        } else if (event.keyCode === 8 && event.target.value === "") {
            items.pop();
            this.props.onUpdate(this.props.property, items);
        }
    }


    render() {
        const { classes, theme, disabled, items, placeholder, dropdown, type, displayType } = this.props;
        return (
            <Grid className={classes.tagContainer}>
                {
                    this.state.isOpen ?
                        <MultiSelect
                            placeholder={placeholder}
                            dropDown={dropdown}
                            selectedList={items}
                            availableList={[...this.state.list]}
                            displayValue="name"
                            selectType={displayType}
                            onChange={(event) => this.onChangeList(event)}
                            onKeyDown={(event) => (type === "addChip" ? this.handleKeyDown(event) : null)}
                            onInputValueChange={(value) => this.handleChange(value)}
                            inputValue={this.state.input}
                            onClose={() => this.handleInputLeave()}
                            onRemoveItem={(index) => this.onRemoveItem(index)}
                            inputRef={this.inputRef} /> :
                        <Grid style={{ display: 'flex', alignItems: 'center', flexWrap: "wrap" }}>
                            {
                                items.map((item, index) =>
                                    <Grid key={`${item.name}-${index}`} className={classes.chipList}>
                                        <TooltipComponent title={displayType === "array" ? item : item.name} arrow>
                                            <Chip
                                                className={classes.chip}
                                                label={displayType === "array" ? item : item.name}
                                                onClick={() => this.enableChipEdit()}
                                            />
                                        </TooltipComponent>
                                        {
                                            !disabled &&
                                            <IconButton onClick={() => this.removeChip(index)} className={classNames(classes.nopadding, 'chipremove')}>
                                                <CloseIcon className={classNames(classes.chipIcon, classes.closeIcon)} />
                                            </IconButton>
                                        }

                                    </Grid>
                                )
                            }
                            {
                                !disabled &&
                                <TooltipComponent title="Add" arrow>
                                    <IconButton onClick={() => this.enableChipEdit()} className={classes.addIcon}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18">
                                            <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                                <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                                    <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.secondary.main} />
                                                    <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.secondary.main} />
                                                </g>
                                            </g>
                                        </svg>
                                    </IconButton>
                                </TooltipComponent>
                            }
                            {
                                (!disabled && items.length === 0) ?
                                    <Typography onClick={() => this.enableChipEdit()} variant={"body2"} className={classes.addTags}>
                                        {'Add'}
                                    </Typography>
                                    : ""
                            }
                        </Grid>
                }
            </Grid>
        );
    }
}

ChipSelectList.propTypes = {
    classes: PropTypes.object,
    items: PropTypes.array,
    onUpdate: PropTypes.func,
    theme: PropTypes.object,
    disabled: PropTypes.bool,
    property: PropTypes.string,
    availableList: PropTypes.array,
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    dropdown: PropTypes.bool,
    type: PropTypes.string,
    displayType: PropTypes.string,
    lowerCase: PropTypes.bool
};

export default withStyles((theme) => ({
    ...TagStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ChipSelectList);