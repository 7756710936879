import React, { useCallback, useState, useEffect } from 'react';
import { Grid, withStyles, Button, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import DashboardStyles from './DashboardStyles.jsx';
import { sortTable } from '../../helpers/appHelpers';
import { getFilterDatasourceList, getFilterDatasetList, getFilterAttributeList } from '../../actions/metricsActions';
import AutoCompleteCheckBox from '../AutoCompleteCheckBox/AutoCompleteCheckBox.jsx';
import { failure } from '../../actions/baseActions';
import { alertActions } from '../../constants/actionTypes/alertActionTypes';


const DashboardFilter = (props) => {
    const { theme, dashboard, closeDashboardFilter, updateDashboardFilter, removeDashboardFilter, classes } = props;

    const dispatch = useDispatch();

    const [filterOption, setFilterOption] = useState(dashboard && dashboard.filters ? dashboard.filters : {});
    const [datasourceList, setdatasourceList] = useState([]);
    const [datasetList, setdatasetList] = useState([]);
    const [attributeList, setattributeList] = useState([]);

    const loadDataSources = useCallback((filterOption, search_key = '') => {
        const key = search_key ? search_key : 'all';
        const requestParams = {
            key
        };
        let old_datasource_ids = filterOption.datasource_id && filterOption.datasource_id.id ? [filterOption.datasource_id.id] : filterOption.datasource_id;
        if (old_datasource_ids) {
            old_datasource_ids = old_datasource_ids.map((data) => (typeof data === 'object' && 'id' in data ? data.id : data));
        }
        dispatch(getFilterDatasourceList(requestParams)).then((response) => {
            if (response && response.datasources.length > 0) {
                response.datasources.forEach((values, i) => {
                    if (old_datasource_ids && old_datasource_ids.some((item) => item === values.id)) {
                        response.datasources[i].isSelected = true;
                    }
                });
                setdatasourceList([...response.datasources]);
            }
        });
    }, [dispatch]);

    const loadDatasets = useCallback((filterOption, search_key = '') => {
        const key = search_key ? search_key : 'all';
        let datasource_ids = filterOption.datasource_id && filterOption.datasource_id.id ? [filterOption.datasource_id.id] : filterOption.datasource_id;
        if (datasource_ids) {
            datasource_ids = datasource_ids.map((data) => (typeof data === 'object' && 'id' in data ? data.id : data));
        }

        let old_dataset_ids = filterOption.dataset_ids && filterOption.dataset_ids.id ? [filterOption.dataset_ids.id] : filterOption.dataset_ids;
        if (old_dataset_ids) {
            old_dataset_ids = old_dataset_ids.map((data) => (typeof data === 'object' && 'id' in data ? data.id : data));
        }
        const requestParams = {
            datasources: datasource_ids,
            datasets: filterOption.dataset_id ? filterOption.dataset_id : [],
            key
        };
        dispatch(getFilterDatasetList(requestParams)).then((response) => {
            if (response && response.datasets.length > 0) {
                response.datasets.forEach((values, i) => {
                    if (old_dataset_ids && old_dataset_ids.some((item) => item === values.id)) {
                        response.datasets[i].isSelected = true;
                    }
                });
                setdatasetList([...response.datasets]);
            }
        });
    }, [dispatch]);

    const updateDashboardFilters = (filterOption) => {
        updateDashboardFilter(filterOption);
    };

    const removeDashboardFilters = () => {
        removeDashboardFilter();
        setFilterOption({});
    };

    const getMaskedDatasetName = (name) => {
        if (name.length > 10) {
            const maxCharacters = 5;
            const firstFiveChars = name.substring(0, maxCharacters);
            const lastFiveChars = name.substring(name.length - maxCharacters, name.length);
            name = `${firstFiveChars}****${lastFiveChars}`;
        }
        return name;
    };

    const loadAttributes = useCallback((filterOption, search_key = '') => {
        const key = search_key ? search_key : 'all';
        let datasource_ids = filterOption.datasource_id && filterOption.datasource_id.id ? [filterOption.datasource_id.id] : filterOption.datasource_id;
        if (datasource_ids) {
            datasource_ids = datasource_ids.map((data) => (typeof data === 'object' && 'id' in data ? data.id : data));
        }

        let old_attribute_ids = filterOption.attribute_ids && filterOption.attribute_ids.id ? [filterOption.attribute_ids.id] : filterOption.attribute_ids;
        if (old_attribute_ids) {
            old_attribute_ids = old_attribute_ids.map((data) => (typeof data === 'object' && 'id' in data ? data.id : data));
        }

        const requestParams = {
            datasources: datasource_ids,
            datasets: filterOption.dataset_ids ? filterOption.dataset_ids : [],
            attributes: filterOption.attribute_ids ? filterOption.attribute_ids : [],
            key
        };
        dispatch(getFilterAttributeList(requestParams)).then((response) => {
            if (response && response.attributes.length > 0) {
                response.attributes.forEach((values, i) => {
                    if (old_attribute_ids && old_attribute_ids.some((item) => item === values.id)) {
                        response.attributes[i].isSelected = true;
                    }
                });
                response.attributes = response.attributes.map((attribute) => { return { ...attribute, attributeName: `${getMaskedDatasetName(attribute.dataset_name)}.${attribute.name}` }; });
                setattributeList([...response.attributes]);
            }
        });
    }, [dispatch]);

    const onChangeSettings = useCallback((property, value, isList = false) => {
        const widget = { ...filterOption };
        if (!value.isSelected && value.name !== "all") {
            if (property === "datasource_id") {
                const number_datasource = (widget.datasource_id instanceof Array) ? widget.datasource_id : [];
                if (number_datasource.length > 9) {
                    dispatch(failure(alertActions.ALERT_ERROR, 'Please restrict your selection to 10 datasources.'));
                    return false;
                }
            }

            if (property === "dataset_ids") {
                const number_dataset = (widget.dataset_ids instanceof Array) ? widget.dataset_ids : [];
                if (number_dataset.length > 9) {
                    dispatch(failure(alertActions.ALERT_ERROR, 'Please restrict your selection to 10 datasets.'));
                    return false;
                }
            }

            if (property === "attribute_ids") {
                const number_attribute = (widget.attribute_ids instanceof Array) ? widget.attribute_ids : [];
                if (number_attribute.length > 49) {
                    dispatch(failure(alertActions.ALERT_ERROR, 'Please restrict your selection to 50 attributes.'));
                    return false;
                }
            }
        }

        if (isList) {
            if (property.includes('datasource')) {
                widget.datasource_id = (widget.datasource_id instanceof Array) ? widget.datasource_id : [];
            }
            if ((property in widget && !widget[property]) || !(property in widget) || (property in widget && !(widget[property] instanceof Array))) {
                widget[property] = [];
            }
            const selectionLabel = (property === "rules") ? "name" : "id";
            const index = widget[property].findIndex((item) => item && value && item[selectionLabel] === value[selectionLabel]);
            if (index > -1) {
                if (property.includes('datasource')) {
                    const datasourceindex = datasourceList.findIndex((x) => x.id === value.id);
                    if (datasourceindex > -1) {
                        datasourceList[datasourceindex].isSelected = false;
                        setdatasourceList(datasourceList);
                    }
                } else if (property.includes('dataset')) {
                    const datasetListindex = datasetList.findIndex((x) => x.id === value.id);
                    if (datasetListindex > -1) {
                        datasetList[datasetListindex].isSelected = false;
                        setdatasetList(datasetList);
                    }
                } else if (property.includes('attribute')) {
                    const attributeindex = attributeList.findIndex((x) => x.id === value.id);
                    if (attributeindex > -1) {
                        attributeList[attributeindex].isSelected = false;
                        setattributeList(attributeList);
                    }
                }
                widget[property].splice(index, 1);
            } else {
                if (value) {
                    value.isSelected = true;
                    widget[property].push(value);
                }
            }
        } else {
            widget[property] = value;
        }


        if (property.includes('datasource')) {
            if (!widget.isAllDatasourceSelected) {
                if (widget && widget.dataset_ids) {
                    for (const dataset of widget.dataset_ids) {
                        const selectedDataset = widget.datasource_id.find((item) => item.id === dataset.source_id);
                        dataset.isSelected = Boolean(selectedDataset);
                    }
                    widget.dataset_ids = widget.dataset_ids.filter((item) => item.isSelected);
                }
                if (widget && widget.attribute_ids) {
                    for (const attribute of widget.attribute_ids) {
                        const selectedAttribute = widget.datasource_id.find((item) => item.id === attribute.source_id);
                        attribute.isSelected = Boolean(selectedAttribute);
                    }
                    widget.attribute_ids = widget.attribute_ids.filter((item) => item.isSelected);
                }
            }
        } else if (property.includes('dataset')) {
            if (!widget.isAllDatasetSelected) {
                if (widget && widget.attribute_ids) {
                    for (const attribute of widget.attribute_ids) {
                        const selectedAttribute = widget.dataset_ids.find((item) => item.id === attribute.dataset_id);
                        attribute.isSelected = Boolean(selectedAttribute);
                    }
                    widget.attribute_ids = widget.attribute_ids.filter((item) => item.isSelected);
                }
            }
        }
        setFilterOption(widget);
    }, [attributeList, datasetList, datasourceList, dispatch, filterOption]);


    useEffect(() => {
        loadDataSources(filterOption, '');
        loadDatasets(filterOption, '');
        loadAttributes(filterOption, '');
    }, [filterOption, loadAttributes, loadDataSources, loadDatasets]);

    return (
        <Grid className={classes.dashboardFilterContainer}>
            <Typography component="h4" variant="h4" style={{ color: theme.palette.grey.main }}>
                {'Apply Filter'}
            </Typography>
            <Grid item style={{ marginTop: 10 }}>
                <AutoCompleteCheckBox
                    value={filterOption && filterOption.datasource_id ? filterOption.datasource_id : []}
                    selectionData={filterOption && filterOption.datasource_id ? filterOption.datasource_id : []}
                    availableList={sortTable(datasourceList ? datasourceList : [], "asc", "name")}
                    displayValue="name"
                    onChange={(value) => onChangeSettings("datasource_id", value, true)}
                    onSearch={(value) => loadDataSources(filterOption, value)}
                    fullWidth
                    label="Datasources"
                    noMargin
                    isHideSelectedAllCount
                    selectedAll
                />
            </Grid>
            <Grid item style={{ marginTop: 10 }}>
                <AutoCompleteCheckBox
                    value={filterOption && filterOption.dataset_ids ? filterOption.dataset_ids : []}
                    selectionData={filterOption && filterOption.dataset_ids ? filterOption.dataset_ids : []}
                    availableList={sortTable(datasetList ? datasetList : [], "asc", "name")}
                    displayValue="name"
                    onChange={(value) => onChangeSettings("dataset_ids", value, true)}
                    onSearch={(value) => loadDatasets(filterOption, value)}
                    fullWidth
                    label="Datasets"
                    noMargin
                    isHideSelectedAllCount
                    selectedAll
                />
            </Grid>

            <Grid item style={{ marginTop: 10 }}>
                <AutoCompleteCheckBox
                    value={filterOption && filterOption.attribute_ids ? filterOption.attribute_ids : []}
                    selectionData={filterOption && filterOption.attribute_ids ? filterOption.attribute_ids : []}
                    availableList={sortTable(attributeList ? attributeList : [], "asc", "name")}
                    displayValue="attributeName"
                    onChange={(value) => onChangeSettings("attribute_ids", value, true)}
                    onSearch={(value) => loadAttributes(filterOption, value)}
                    fullWidth
                    label="Attributes"
                    noMargin
                    isHideSelectedAllCount
                    selectedAll
                />
            </Grid>

            <Grid item style={{ padding: 8, marginTop: 10 }}>
                <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">
                    <Button className={classes.tabBtn} onClick={() => updateDashboardFilters(filterOption)} type="submit" variant="contained" color="primary">
                        Save
                    </Button>
                    <Button style={{ marginLeft: '10px' }} onClick={() => closeDashboardFilter()} variant="contained" className={classnames(classes.cancelBtn, classes.tabBtn)}>
                        Cancel
                    </Button>
                    {
                        filterOption && Object.keys(filterOption).length > 0 &&
                        <Button className={classes.tabBtn} style={{ marginLeft: '10px' }} onClick={() => removeDashboardFilters()} variant="contained" className={classnames(classes.cancelBtn, classes.tabBtn)}>
                            Remove Filter
                        </Button>
                    }
                </Grid>
            </Grid>
        </Grid>
    );
};

DashboardFilter.propTypes = {
    theme: PropTypes.object,
    dashboard: PropTypes.object,
    classes: PropTypes.object,
    closeDashboardFilter: PropTypes.func,
    updateDashboardFilter: PropTypes.func,
    removeDashboardFilter: PropTypes.func
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DashboardFilter);