import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
// import MasterFilter from '../../components/Master/MasterFilter.jsx';
import { getAllMasterModel } from '../../actions/masterActions';
import SkeletonLoader from '../../components/SkeletonLoader/SkeletonLoader.jsx';
import MasterList from '../../components/Master/MasterList.jsx';
import NoResultImg from '../../assets/images/no_result.png';
import Styles from '../../layouts/Styles.jsx';
import { setCurrentPage } from '../../helpers/apiHelpers';


const Master = (props) => {
    const { history, filters: { search = '' }, classes } = props;
    const [isLoading, setLoading] = useState(true);
    const [models, setModels] = useState([]);
    const dispatch = useDispatch();

    const getModels = useCallback(() => {
        dispatch(getAllMasterModel()).then((response) => {
            setLoading(false);
            if (response) {
                setModels([...response]);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        setCurrentPage('model');
        getModels();
    }, [getModels]);

    let masterModels = models;
    if (search && search.trim()) {
        masterModels = models.filter((model) => model.name.trim().toLowerCase().includes(search.trim().toLowerCase()) || model.attributes.some((attribute) => attribute.name.trim().toLowerCase().includes(search.trim().toLowerCase())));
    }

    return (
        <Grid>
            {
                isLoading ?
                    <SkeletonLoader /> :
                    <Grid>
                        {
                            masterModels.length > 0 ?
                                <MasterList models={masterModels} history={history} />
                                :
                                <Grid container alignItems="center" justify="center" className={classes.noResultFound}>
                                    <Grid>
                                        <img src={NoResultImg} alt="No Result Found" />
                                        <Typography variant="h4" align="center">
                                            {'No Result Found'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                        }
                    </Grid>
            }
            {/* <MasterFilter search={search} history={history} onChangeFilter={(value) => onChangeFilter(value)} /> */}
        </Grid>
    );
};

Master.propTypes = {
    history: PropTypes.object,
    search: PropTypes.string,
    classes: PropTypes.object,
    filters: PropTypes.func
};

export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(Master);