import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { filterInputData, getDatasetAttributes } from '../../actions/datasetActions';
import QueryFilterModel from '../QueryFilterModel/QueryFilterModel.jsx';

const ExecutionLogQueryFilter = (props) => {
    const { selectedExecutionLog, onCloseLog } = props;
    const selectedDatasource = useSelector(({ datasource }) => datasource.datasource);
    const [attributes, setAttributes] = useState([]);
    const dispatch = useDispatch();

    const getAttributes = useCallback(() => {
        dispatch(getDatasetAttributes(selectedExecutionLog.datasetId)).then((response) => {
            if (response && response.length > 0) {
                setAttributes([...response]);
            } else {
                onCloseLog();
            }
        });
    }, [dispatch, onCloseLog, selectedExecutionLog.datasetId]);


    useEffect(() => {
        getAttributes();
    }, [getAttributes]);

    return (
        <Grid>
            {
                selectedExecutionLog.isOpen && attributes.length > 0 &&
                <QueryFilterModel
                    open={selectedExecutionLog.isOpen}
                    selectedAttribute={{}}
                    properties={{}}
                    datasetId={selectedExecutionLog.datasetId}
                    scheduleId={selectedExecutionLog.scheduleLogId}
                    onClose={() => onCloseLog()}
                    attributes={[...attributes]}
                    userAttributes={[]}
                    selectedDatasource={selectedDatasource}
                    getPreviewData={(params) => dispatch(filterInputData(params))}
                    inputParams={{ ruleType: "curate", filterType: "All", chartType: selectedExecutionLog.type }}
                />
            }

        </Grid>
    );
};

ExecutionLogQueryFilter.propTypes = {
    selectedExecutionLog: PropTypes.object,
    onCloseLog: PropTypes.func
};

export default ExecutionLogQueryFilter;