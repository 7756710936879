/**
 * NOTE : IF YOU CHANGE ANYTHING IN DYNAMIC COMPONENTS THEN YOU NEED TO CHECK DYNAMICELEMENTBYDATATYPE COMPONENTS TOO.
 */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles, Grid, Table, TableRow, TableHead, TableCell, Typography, TableBody, Switch } from '@material-ui/core';
import moment from 'moment-timezone';
import { DateTimePicker, DatePicker, TimePicker } from '@material-ui/pickers';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// Import Styles
import PropertiesListViewStyles from './PropertiesListViewStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';
import DatasetStyles from '../../DatasetStyles.jsx';
import TileViewStyles from '../TileView/TileViewStyles.jsx';

// Import Component
import { appConstants } from '../../../../constants/appConstants.js';
import TextBox from '../../../TextBox/TextBox.jsx';
import NumberInput from '../../../TextBox/NumberInput.jsx';
import IntegerInput from '../../../TextBox/IntegerInput.jsx';
import { getFieldPropertyByLevel, createFieldProperty } from '../../../../actions/datasourceActions';
import ToolTipComponent from '../../../Tooltip/Tooltip.jsx';


const GrossaryPanel = (props) => {
    const { classes, datasetId, properties, isEditable, fields } = props;
    const [fieldProperties, setFieldProperties] = useState([]);
    const user = useSelector(({ account }) => account.user);
    const dispatch = useDispatch();

    /**
     * Get Field Property List
     */
    const getFieldPropertyByAttribute = useCallback(() => {
        dispatch(getFieldPropertyByLevel(0, appConstants.fieldKeys.attribute, datasetId)).then((response) => {
            if (response && response.length > 0) {
                setFieldProperties(response);
            }
        });
    }, [datasetId, dispatch]);

    /**
     * @name useEffect
     * @description UseEffect run when page initialize and any updates happends
     */
    useEffect(() => {
        getFieldPropertyByAttribute();
    }, [datasetId, getFieldPropertyByAttribute, fields, properties]);

    /**
     * Bind Element By DataType
     * @param {*} field
     * @returns
     */
    const getElementByDataType = (field, attribute) => {
        let value = getValue(field, attribute);
        switch (field.data_type) {
            case 'Text':
                return <TextBox
                    placeholder={field.description}
                    name={field.name}
                    id={field.name}
                    value={value}
                    className={classNames(classes.textboxContainer, classes.businessNameTextBox)}
                    onChange={(event) => dyanamicEleOnChange(event.target.value, field, attribute)}
                    onBlur={() => dyanamicEleOnChange(value, field, attribute, true)}
                    disabled={!isEditable}
                />;
            case 'Integer':
                return <IntegerInput
                    placeholder={field.description}
                    name={field.name}
                    id={field.name}
                    value={value}
                    type={'number'}
                    className={classNames(classes.textboxContainer, classes.businessNameTextBox)}
                    onChange={(event) => dyanamicEleOnChange(event.target.value, field, attribute)}
                    onBlur={() => dyanamicEleOnChange(value, field, attribute, true)}
                    disabled={!isEditable}
                />;
            case 'Numeric':
                return <NumberInput
                    placeholder={field.description}
                    name={field.name}
                    id={field.name}
                    value={value}
                    type={'number'}
                    className={classNames(classes.textboxContainer, classes.businessNameTextBox)}
                    onChange={(event) => dyanamicEleOnChange(event.target.value, field, attribute)}
                    onBlur={() => dyanamicEleOnChange(value, field, attribute, true)}
                    disabled={!isEditable}
                />;
            case 'Boolean':
                value = !(value === '' || value === 'False' || value === null);
                return <Switch
                    id={field.name}
                    name={field.name}
                    checked={Boolean(value)}
                    color="secondary"
                    onChange={(event) => dyanamicEleOnChange(event.target.checked, field, attribute, true, true)}
                    disabled={!isEditable}
                />;
            case 'Date':
                return <DatePicker
                    autoOk
                    name={field.name}
                    placeholder={field.description}
                    className={classNames(classes.textboxContainer, classes.businessNameTextBox)}
                    variant="inline"
                    format="MM-DD-YYYY"
                    value={value ? value : null}
                    onChange={
                        (date) => {
                            dyanamicEleOnChange(moment(date), field, attribute);
                        }
                    }
                    onBlur={
                        () => {
                            dyanamicEleOnChange(value, field, attribute, true);
                        }
                    }
                    disabled={!isEditable}
                />;
            case 'Time':
                return <TimePicker
                    autoOk
                    name={field.name}
                    placeholder={field.description}
                    className={classNames(classes.textboxContainer, classes.businessNameTextBox)}
                    variant="inline"
                    format="HH:mm"
                    value={value ? value : null}
                    onChange={
                        (date) => {
                            dyanamicEleOnChange(moment(date), field, attribute);
                        }
                    }
                    onBlur={
                        () => {
                            dyanamicEleOnChange(value, field, attribute, true);
                        }
                    }
                    disabled={!isEditable}
                />;
            case 'DateTime':
                return <DateTimePicker
                    autoOk
                    name={field.name}
                    placeholder={field.description}
                    className={classNames(classes.textboxContainer, classes.businessNameTextBox)}
                    variant="inline"
                    format="MM-DD-YYYY HH:mm"
                    ampm={false}
                    value={value ? value : null}
                    onChange={
                        (date) => {
                            dyanamicEleOnChange(moment(date), field, attribute);
                        }
                    }
                    onBlur={
                        () => {
                            dyanamicEleOnChange(value, field, attribute, true);
                        }
                    }
                    disabled={!isEditable}
                />;
            case 'DropDown':
                return (
                    <FormControl className={classes.minWidth200}>
                        <Select
                            placeholder={field.name}
                            value={value}
                            className={classNames(classes.textboxContainer, classes.inputOutline, classes.businessNameTextBox, classes.paddingZero)}
                            onChange={(event) => dyanamicEleOnChange(event.target.value, field, attribute, true, true)}
                        >
                            {/* <MenuItem value={"None"}>
                                {"None"}
                            </MenuItem> */}
                            {
                                field.dropdown_options.map((option, index) => {
                                    return (
                                        <MenuItem key={`option_${index}`} value={option}>
                                            {option}
                                        </MenuItem>);
                                })
                            }
                        </Select>
                    </FormControl>);
            default:
                return null;
        }
    };

    /**
     * Get Value
     * @param {*} field
     * @returns
     */
    const getValue = (field, attribute) => {
        const obj = fieldProperties.filter((p) => p.custom_field === field.id && attribute.dataset === p.dataset && attribute.attribute === p.attribute)[0];
        if (obj) { return obj.value || ''; }
        return '';
    };

    /**
     * Get Dynamic Element On Change Event
     * @param {*} name
     * @param {*} value
     * @param {*} data_type
     */
    const dyanamicEleOnChange = (value, field, attribute, saveDB, isBoolean) => {

        let properties = [...fieldProperties];
        const fieldIndex = fieldProperties.findIndex((p) => p.custom_field === field.id && attribute.dataset === p.dataset && attribute.attribute === p.attribute);

        if (fieldIndex > -1) {
            // Update
            properties[fieldIndex].value = value;
            setFieldProperties(properties);

            if (saveDB) {
                if (properties[fieldIndex].id && properties[fieldIndex].id !== '') {
                    // Update field in db
                    dispatch(createFieldProperty(properties[fieldIndex]));
                } else {
                    // Create field in db
                    dispatch(createFieldProperty(properties[fieldIndex])).then((response) => {
                        if (!response) {
                            return;
                        }
                        properties[fieldIndex].id = response.id;
                        setFieldProperties(properties);
                    });
                }
            }
        } else {
            // Create
            const obj = {
                id: '',
                organization: user.organization,
                custom_field: field.id,
                dataset: parseInt(datasetId),
                attribute: parseInt(attribute.attribute),
                value: value
            };

            properties = [...properties, obj];
            setFieldProperties(properties);

            if (isBoolean) {
                // Create field in db
                dispatch(createFieldProperty(obj)).then((response) => {
                    if (!response) {
                        return;
                    }
                    obj.id = response.id;
                    properties = [...properties, obj];
                    setFieldProperties(properties);
                });
            }
        }
    };

    return (
        <Grid container direction="column" className={classes.fieldPanelContainer}>
            <Table stickyHeader className={classes.propertyListPageTable}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '20%' }}>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Attribute'}
                            </Typography>
                        </TableCell>
                        {
                            fields.map((field, index) =>
                                <TableCell key={`field_index_${index}`}>
                                    <Typography variant="body1" className={classes.tableHeader}>
                                        {field.name}
                                    </Typography>
                                </TableCell>
                            )
                        }
                    </TableRow>
                </TableHead>
                <TableBody className={classes.propListPageTableBody}>
                    {
                        Object.keys(properties).map((attribute, index) => {
                            const property = properties[attribute];
                            return (
                                <TableRow key={`attributeProperty${index}`}>
                                    <TableCell>
                                        <ToolTipComponent title={property && property.name ? property.name : ''} arrow>
                                            <Typography noWrap>
                                                {property && property.name ? property.name : ''}
                                            </Typography>
                                        </ToolTipComponent>
                                    </TableCell>

                                    {
                                        fields.map((field, index) =>
                                            <TableCell key={`field_element_index_${index}`} className={classes.hoverBgAutoComplete}>
                                                {getElementByDataType(field, property)}
                                            </TableCell>
                                        )
                                    }
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
        </Grid>
    );
};

GrossaryPanel.propTypes = {
    classes: PropTypes.object,
    properties: PropTypes.object,
    isEditable: PropTypes.bool,
    fields: PropTypes.array,
    datasetId: PropTypes.number
};

export default withStyles((theme) => ({
    ...PropertiesListViewStyles(theme),
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}))(GrossaryPanel);