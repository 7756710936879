import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Tabs, Tab } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch, useSelector, connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import MasterInfo from '../../components/Master/MasterInfo.jsx';
import MasterDetail from '../../components/Master/MasterDetail.jsx';
import MasterAttributes from '../../components/Master/MasterAttributes.jsx';
import MasterSearch from '../../components/MasterSearchDetail/MasterSearch.jsx';
import TabPanel from '../../components/TabPanel/TabPanel.jsx';
import { appConstants } from '../../constants/appConstants';
import { getModel, getMasterSharingInfo, updateMasterModel, createMasterShare, deleteMasterShare, deleteMasterModel, getAttributes, getGlossaryFields } from '../../actions/masterActions';
import { updateUser } from '../../actions/accountActions';
import AlertDialog from '../../components/AlertDialog/AlertDialog.jsx';
import MasterGroups from '../../components/Master/MasterGroup/MasterGroups.jsx';
import MasterModelMappings from '../../components/Master/MasterModelMappings.jsx';
import MasterExecutionLogs from '../../components/Master/MasterExecutionLogs.jsx';

const MasterDetailInfo = (props) => {
    const { classes, match, location, history, getPageTitle } = props;
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = useState(0);
    const modelId = match.params.id;
    const user = useSelector(({ account }) => account.user);
    const [glossaryFields, setGlossaryFields] = useState([]);
    const [model, setModel] = useState({
        "name": '',
        "description": '',
        "terms_description": ''
    });
    const [governance, setGovernance] = useState([]);
    const [isDelete, setDeleteDialog] = useState(false);

    const onChange = useCallback((property, value) => {
        model[property] = value;
        if (property === 'name' && value) {
            model.prefix = value.toLowerCase().replace(/ /g, '_');
        }
        setModel({ ...model });
    }, [model]);

    const updateProperties = useCallback((property) => {
        dispatch(updateMasterModel(modelId, model));
    }, [dispatch, model, modelId]);

    const getSharingInfo = useCallback(() => {
        dispatch(getMasterSharingInfo(modelId)).then((response) => {
            if (response) {
                setGovernance([...response]);
            }
        });
    }, [dispatch, modelId]);

    const getModelById = useCallback((modelId) => {
        dispatch(getModel(modelId)).then((response) => {
            if (response) {
                getPageTitle(`Sematic Model | ${response.name} | ${appConstants.masterTabs[tabIndex]}`);
                setModel({ ...response });
            }
        });
        dispatch(getAttributes(modelId));
    }, [dispatch, getPageTitle, tabIndex]);

    const loadTab = useCallback(() => {
        let activeTabIndex = tabIndex || 0;
        if (location?.state?.attribute) {
            activeTabIndex = 1;
        } else if (location?.state?.search) {
            activeTabIndex = 3;
        }
        setTabIndex(activeTabIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location?.state?.attribute, location?.state?.search]);

    const getFields = useCallback(() => {
        dispatch(getGlossaryFields()).then((response) => {
            if (response) {
                setGlossaryFields([...response]);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        if (!modelId) {
            return;
        }
        getModelById(modelId);
        getSharingInfo();
        getFields();
        loadTab();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modelId]);

    const deleteModelData = useCallback(() => {
        setDeleteDialog(true);
    }, []);

    const deleteModel = useCallback(() => {
        dispatch(deleteMasterModel(modelId)).then(() => {
            history.push('/model');
        });
    }, [dispatch, history, modelId]);
    const userId = user.id ? user.id : 0;
    const isStewardUser = governance.some((user) => user.is_steward_user && user.user_id === userId);
    const breadcrumb = [
        {
            name: 'Semantics',
            path: '/model'
        },
        {
            name: model?.name || '',
            path: ''
        }
    ];
    return (
        <Grid>
            <ValidatorForm name="master" onSubmit={() => null}>
                <Grid className={classes.paddingTop20}>
                    <MasterInfo
                        isEditable={isStewardUser}
                        breadcrumb={breadcrumb}
                        model={model}
                        modelId={modelId}
                        governance={governance}
                        onSave={updateProperties}
                        onChangeModel={(...props) => onChange(...props)}
                        history={history}
                        deleteShare={props.deleteMasterShare}
                        updateUser={props.updateUser}
                        createSourceShare={props.createMasterShare}
                        deleteModelData={deleteModelData}
                    />
                </Grid>
                <Grid className={classes.datasourceTabContainer}>
                    <Tabs
                        value={tabIndex}
                        indicatorColor="secondary"
                        textColor="secondary"
                        onChange={
                            (event, index) => {
                                getPageTitle(`Sematic Model | ${model.name} | ${appConstants.masterTabs[index]}`);
                                setTabIndex(index);
                            }
                        }
                        aria-label="tabs"
                    >
                        {
                            appConstants && appConstants.masterTabs.map((name, index) =>
                                <Tab key={index} label={name} />
                            )
                        }
                    </Tabs>
                </Grid>
                <Grid className={classNames(classes.marginTop15, classes.tabContainerOverview)} >
                    <TabPanel value={tabIndex} index={0}>
                        {
                            tabIndex === 0 &&
                            <MasterDetail
                                onSave={updateProperties}
                                isEditable={isStewardUser}
                                model={model}
                                governance={governance}
                                onChangeModel={(...props) => onChange(...props)}
                                createMasterShare={props.createMasterShare}
                                deleteMasterShare={props.deleteMasterShare}
                                updateUser={props.updateUser} />
                        }
                    </TabPanel>
                    <TabPanel value={tabIndex} index={1}>
                        {
                            tabIndex === 1 &&
                            <MasterAttributes isEditable={isStewardUser} modelId={modelId} tabIndex={tabIndex} fields={glossaryFields} onChangeModel={(...props) => onChange(...props)} />
                        }
                    </TabPanel>
                    <TabPanel value={tabIndex} index={2}>
                        {
                            tabIndex === 2 &&
                            <MasterGroups isEditable={isStewardUser} modelId={modelId} tabIndex={tabIndex} onChangeModel={(...props) => onChange(...props)} />
                        }
                    </TabPanel>
                    <TabPanel value={tabIndex} index={3}>
                        {
                            tabIndex === 3 &&
                            <MasterSearch modelId={modelId} model={model} tabIndex={tabIndex} />
                        }
                    </TabPanel>
                    <TabPanel value={tabIndex} index={4}>
                        {
                            tabIndex === 4 &&
                            <MasterModelMappings isEditable={isStewardUser} modelId={modelId} tabIndex={tabIndex} history={history} />
                        }
                    </TabPanel>
                    <TabPanel value={tabIndex} index={5}>
                        {
                            tabIndex === 5 &&
                            <MasterExecutionLogs isEditable={isStewardUser} modelId={modelId} tabIndex={tabIndex} />
                        }
                    </TabPanel>
                </Grid>
            </ValidatorForm>
            <AlertDialog title="Delete Model"
                message={`Are you sure you want to delete the model ${model ? model.name : ''}?`}
                okButtonText="OK"
                cancelButtonText="Cancel"
                show={isDelete}
                onClickOK={() => deleteModel()}
                onClickCancel={() => setDeleteDialog(false)} />
        </Grid>
    );
};

MasterDetailInfo.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object,
    deleteMasterShare: PropTypes.func,
    createMasterShare: PropTypes.func,
    updateUser: PropTypes.func,
    getPageTitle: PropTypes.func
};

const mapStateToProps = ({ master }) => {
    return { ...master };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        dispatch,
        createMasterShare, deleteMasterShare, updateUser
    }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...Styles(theme)
}))(MasterDetailInfo));