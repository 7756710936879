import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, withStyles, MenuItem } from '@material-ui/core';
import classNames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import ActionItemStyles from './ActionItemStyles.jsx';
import ActionListComment from './ActionListComment.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import SendIcon from '@material-ui/icons/Send';
import { appConstants } from '../../constants/appConstants.js';
import CheckboxComponent from '../ChecboxComponent/CheckboxComponent.jsx';
import { useSelector } from 'react-redux';
import { getPosition } from '../../helpers/appHelpers';

const ActionListDetail = (props) => {
    const { classes, actionItem, addComment, onChange, history } = props;
    const [comment, setComment] = useState('');
    const user = useSelector(({ account }) => account.user);

    const permission = actionItem && actionItem.users ? actionItem.users.some((userInfo) => userInfo.assign_user_id === user.id || user.email === actionItem.assigneduser) : false;
    return (
        <Grid>
            <ValidatorForm name="comments" onSubmit={() => null}>
                <Grid container justify="space-between">
                    <CheckboxComponent disabled={!permission} checked={actionItem.status === "resolved"} onChange={(event) => onChange("status", event.target.checked ? "resolved" : "open")} checkboxLabel="Resolved" />
                    <TextBox
                        name="priority"
                        select
                        onChange={(event) => onChange(event.target.name, event.target.value)}
                        value={actionItem.priority ? actionItem.priority : 'High'}
                        className={classes.inlineTxt}
                        disabled={!permission}
                        SelectProps={
                            {
                                MenuProps: {
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "center"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "center"
                                    },
                                    getContentAnchorEl: null
                                }
                                // IconComponent: () => DropDownIcon()
                            }
                        }
                    >
                        {
                            appConstants.priority.map((option, index) => (
                                <MenuItem key={`menuProperty_Options_${index}`} value={option} className={classes.menuItem}>
                                    {option}
                                </MenuItem>
                            ))
                        }
                    </TextBox>
                </Grid>
                <Grid className={classes.listScrollContainer}>
                    <Grid className={classes.actionImgContainer}
                        style={{ backgroundImage: `url(${actionItem.asset_path})` }}
                        onClick={
                            () => {
                                if (actionItem && actionItem.source_id) {
                                    if (actionItem.dataset_id) {
                                        switch (actionItem.tab_index) {
                                            case 'Info': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: true, executionLogs: false, curate: false, dqscore: false, attributeList: false, attribute: false, monitoring: false } });
                                                            break;
                                            case 'Attributes': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, executionLogs: false, curate: false, dqscore: false, attributeList: true, attribute: false, monitoring: false } });
                                                            break;
                                            case 'Properties': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, executionLogs: false, curate: false, dqscore: false, attributeList: false, attribute: true, monitoring: false } });
                                                            break;
                                            case 'Profile': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, executionLogs: false, curate: false, dqscore: true, attributeList: false, attribute: false, monitoring: false } });
                                                            break;
                                            case 'Monitoring': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, executionLogs: false, curate: false, dqscore: false, attributeList: false, attribute: false, monitoring: true } });
                                                            break;
                                            case 'Exportmetrics': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, executionLogs: false, curate: false, dqscore: false, attributeList: false, attribute: false, monitoring: true } });
                                                            break;
                                            case 'Curate': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, executionLogs: false, curate: true, dqscore: false, attributeList: false, attribute: false, monitoring: false } });
                                                            break;
                                            case 'Execution Logs': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, executionLogs: true, curate: false, dqscore: false, attributeList: false, attribute: false, monitoring: false } });
                                                            break;
                                            default: history.push({ pathname: `/dataset/${actionItem.dataset_id}` });
                                                break;
                                        }
                                    } else {
                                        switch (actionItem.tab_index) {
                                            case 'Info': history.push({ pathname: `/dataset/${actionItem.dataset_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: true, executionLogs: false, curate: false, dqscore: false, attributeList: true, attribute: false, monitoring: false } });
                                                            break;
                                            case 'Datasets': history.push({ pathname: `/catalog/${actionItem.source_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, dqscore: true, executionLogs: false, metrics: false } });
                                                            break;
                                            case 'Metrics': history.push({ pathname: `/catalog/${actionItem.source_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, dqscore: false, executionLogs: false, metrics: true } });
                                                            break;
                                            case 'Execution Logs': history.push({ pathname: `/catalog/${actionItem.source_id}`, state: { datasource: {}, datasourceId: actionItem.source_id, info: false, dqscore: false, executionLogs: true, metrics: false } });
                                                            break;
                                            default: history.push(`/catalog/${actionItem.source_id}`);
                                                    break;
                                        }
                                    }
                                } else {
                                    history.push(`/actionitem`);
                                }
                            }
                    } >
                        <Grid className={classes.smallDot} style={{ top: getPosition(actionItem.screen.height, 150, actionItem.position.top), left: getPosition(actionItem.screen.width, 280, actionItem.position.left) }}>
                            {actionItem.count}
                        </Grid>
                    </Grid>
                    <Grid>
                        {
                            actionItem.comments.map((comment, index) =>
                                <ActionListComment type="comment" actionItem={comment} key={index} />
                            )
                        }
                    </Grid>
                </Grid>
                {
                    permission &&
                    <Grid className={classes.addCommentSection}>
                        <Grid className={classes.commentContainer}>
                            <TextBox
                                name="comment"
                                multiline
                                rows="4"
                                placeholder="Comment"
                                id="comment"
                                fullWidth
                                margin="normal"
                                value={comment ? comment : ''}
                                onChange={(event) => setComment(event.target.value)}
                                className={classNames(classes.inputOutline, classes.validationTextBox)} />
                            <IconButton onClick={() => (comment !== "" ? addComment(comment) : null)} className={classes.commentSendBtn}>
                                <SendIcon color="primary" />
                            </IconButton>
                        </Grid>
                    </Grid>
                }
            </ValidatorForm>
        </Grid>
    );

};

ActionListDetail.propTypes = {
    classes: PropTypes.object,
    actionItem: PropTypes.object,
    addComment: PropTypes.func,
    onChange: PropTypes.func,
    history: PropTypes.object
};


export default withStyles((theme) => ({
    ...ActionItemStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(ActionListDetail);