import React, { useEffect } from "react";
import { Grid, withStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import classNames from "classnames";
import * as d3 from "d3";
import Styles from "../../layouts/Styles.jsx";
import ChartStyles from "./ChartStyles.jsx";

const PieChart = (props) => {
  const { classes, chartData, chartClassName, theme } = props;

  const barHighlightOpacity = 1;
  const barOpacity = 0.8;

  useEffect(() => {
    if (chartData && Object.keys(chartData).length !== 0) {
      const margin = { top: 40, right: 20, bottom: 50, left: 40 };
      let width = 250;
      const height = 250;
      const innerRadius = 100;

      const radius = Math.min(width, height) / 2;
      // let data = { ...chartData.frequency_count };

      let data = { ...chartData };

      let total_count = 0;
      for (const keys in data) {
        total_count += data[keys];
      }

      d3.select(`.${chartClassName} > svg`).remove();

      const dataCount = d3.entries(data).length;

      const svg = d3
        .select(`.${chartClassName}`)
        .append("svg")
        .attr("id", "piechart-group")
        .attr("width", (_, i, p) => {
          width = p[i].parentNode.clientWidth;
          return "100%";
        })
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("width", width)
        .attr("height", height + margin.top + margin.bottom)
        .attr(
          "transform",
          "translate(" +
          (width / 2 - (margin.left + margin.right)) +
          "," +
          (height + margin.top + margin.bottom) / 2 +
          ")"
        );

      // Color codes

      const colors = d3
        .scaleLinear()
        .domain(d3.range(dataCount))
        .range(["#023858", "#045281", "#0667a1", "#1a7db6", "#4394c3", "#72a8cf", "#9bb9d9", "#bfc9e2", "#dbdaeb", "#efeaf4", "#1ac7c2", "#1ddfa3"]);

      const pie = d3.pie().value((d) => d.value);
      data = pie(d3.entries(data));

      // Helper to build arcs
      const arcGenerator = d3.arc().innerRadius(innerRadius).outerRadius(radius);

      // Helper to animate arcs
      const arcOver = d3
        .arc()
        .innerRadius(innerRadius)
        .outerRadius(radius + 5);

      function formatString(string) {
        return string ? string.replace(/ /g, "_") : "";
      }

      d3.select(`.${chartClassName} > .tooltip`).remove();
      const div = d3.select(`.${chartClassName}`)
          .append("div")
          .attr("class", "tooltip")
          .style("opacity", 0)
          .style("zIndex", 1);

      svg
        .selectAll("slices")
        .data(data)
        .enter()
        .append("path")
        .attr("id", (d) => {
          return `pie-chart_arcPath_${formatString(d.data.key)}`;
        })
        .attr("d", arcGenerator)
        .attr("fill", (_, index) => {
          if (dataCount <= 1) {
            return theme.palette.secondary.light;
          }
          return colors(index);
        })
        .attr("stroke", "transparent")
        .style("stroke-width", (d) => {
          return d.data.value ? "1px" : "0px";
        })
        .style("opacity", 0.7)
        .on("mouseover", (d) => {
          d3.select(`.${formatString(d.data.key)}_polygone`).style(
            "stroke-width",
            "1"
          );
          d3.select(`.${formatString(d.data.key)}_text`).style("opacity", "1");

          d3.select(`.pie-chart_value_${formatString(d.data.key)}`)
            .transition()
            .delay(100)
            .style("opacity", "1");
          d3.select(this).transition().attr("d", arcOver);
        })
        .on("mouseout", (d) => {
          d3.select(`.${formatString(d.data.key)}_polygone`).style(
            "stroke-width",
            "0"
          );
          d3.select(`.${formatString(d.data.key)}_text`).style("opacity", "0");
          d3.select(`.pie-chart_value_${formatString(d.data.key)}`)
            .transition()
            .delay(100)
            .style("opacity", "0");
          d3.select(this).transition().attr("d", arcGenerator);
        })
        .on('mouseover',
          (d, i, nodes) => {
              d3.select(nodes[i]).style("opacity", barHighlightOpacity);
              let x = 0;
              let y = 0;
              div.attr("transform", (_, i, nodes) => {
                  const mouseCoords = d3.mouse(nodes[i].parentNode);
                  let xCo = 0;
                  let yCo = 0;
                  if (mouseCoords[0] + 10 >= width * 0.80) {
                      xCo = mouseCoords[0] - parseFloat(div
                          .attr("width"));
                      xCo = isNaN(xCo) ? mouseCoords[0] : xCo;
                      yCo = mouseCoords[1] + 10;
                  } else {
                      xCo = mouseCoords[0] + 10;
                      yCo = mouseCoords[1];
                  }
                  x = xCo;
                  y = yCo;
              });
              div.transition()
                  .duration(200)
                  .style("opacity", 0.9);

              div.html(`Rule : ${d.data.key}<br> Count : ${d.value}`)
                  .style("left", (x) + "px")
                  .style("top", (y) + "px");
          })
        .on('mouseout', (d, i, nodes) => {
          d3.select(nodes[i]).style("opacity", barOpacity);
          div.transition()
              .duration(500)
              .style("opacity", 0);
          });

      // Add arc values
      svg
        .selectAll("slices")
        .data(data)
        .enter()
        .append("text")
        .attr("transform", (d) => {
          const position = dataCount > 1 ? arcGenerator.centroid(d) : [0, 0];
          return "translate(" + position + ")";
        });

      const pieValue = svg
        .append("g")
        .attr("class", "pie-legend")
        .attr("transform", "translate(" + 0 + "," + -150 + ")");

      // Interval Legend rectangle
      pieValue
        .selectAll(".pie-legend")
        .data(data)
        .enter()
        .append("g")
        .attr("class", "pie-legend")
        .attr("transform", "translate(" + -50 + "," + 130 + ")")
        .style("opacity", 0);

      // Interval Legend label
      pieValue
        .selectAll(".pie-legend")
        .attr("class", (d) => {
          return `pie-chart_value_${formatString(d.data.key)}`;
        })
        .append("text")
        .attr("class", "legend-value")
        .attr("x", 20)
        .attr("y", 0)
        .attr("alignment-baseline", "middle")
        .each((d) => {
          d3.select(this)
            .append("tspan")
            .attr("dy", 0)
            .attr("x", 0)
            .style("text-transform", "capitalize")
            .style("font-size", "14px")
            .text(d.data.key);
          d3.select(this)
            .append("tspan")
            .attr("dy", 20)
            .attr("x", 0)
            .style("font-size", "12px")
            .text(`Count : ${d.value}`);
          d3.select(this)
            .append("tspan")
            .attr("dy", 20)
            .attr("x", 0)
            .style("font-size", "12px")
            .text(
              `Percentage : ${(
                (d.value / total_count) *
                100
              ).toFixed(1)}%`
            );
        });

      /*
       *   let lines = Object.keys(data).sort((a, b) =>
       *     a > b ? 1 : -1
       *   );
       *   let index = lines.indexOf("others");
       *   if (index >= 0) {
       *     lines.splice(index, 1);
       *     lines.push("others");
       *   }
       */

      /*
       *   if (lines.length > 0) {
       *     //   Interval Legends
       *     let legendGap = 30;
       *     let legendLeft = height / 2 + 10;
       *     let legendTop = -(height / 2);
       */

      /*
       *     let legendGroup = svg
       *       .append("g")
       *       .attr("class", "legends")
       *       .attr("transform", "translate(" + legendLeft + "," + legendTop + ")");
       */

      /*
       *     // Interval Legend rectangle
       *     legendGroup
       *       .selectAll(".legend-group")
       *       .data(lines)
       *       .enter()
       *       .append("g")
       *       .attr("class", "legend-group")
       *       .attr("transform", "translate(" + 0 + "," + 0 + ")")
       *       .style("opacity", 1)
       *       .on("mouseover", function (d) {
       *         d3.select(`.${formatString(d)}_polygone`).style(
       *           "stroke-width",
       *           "1"
       *         );
       *         d3.select(`.${formatString(d)}_text`).style("opacity", "1");
       *         d3.select(`.pie-chart_value_${formatString(d)}`)
       *           .transition()
       *           .delay(100)
       *           .style("opacity", "1");
       *         d3.select(`#pie-chart_arcPath_${formatString(d)}`)
       *           .transition()
       *           .attr("d", arcOver);
       *       })
       *       .on("mouseout", function (d) {
       *         d3.select(`.${formatString(d)}_polygone`).style(
       *           "stroke-width",
       *           "0"
       *         );
       *         d3.select(`.${formatString(d)}_text`).style("opacity", "0");
       *         d3.select(`.pie-chart_value_${formatString(d)}`)
       *           .transition()
       *           .delay(100)
       *           .style("opacity", "0");
       *         d3.select(`#pie-chart_arcPath_${formatString(d)}`)
       *           .transition()
       *           .attr("d", arcGenerator);
       *       })
       *       .append("rect")
       *       .attr("class", "legend-rect")
       *       .attr("width", 10)
       *       .attr("height", 10)
       *       .attr("y", function (_, index) {
       *         return index * legendGap;
       *       })
       *       .style("fill", function (d) {
       *         if (dataCount <= 1) return theme.palette.secondary.light;
       *         let keys = Object.keys(data);
       *         let index = keys.indexOf(d);
       *         return colors(index);
       *       });
       */

      /*
       *     // Interval Legend label
       *     legendGroup
       *       .selectAll(".legend-group")
       *       .append("text")
       *       .attr("class", "legend-text")
       *       .attr("x", 20)
       *       .attr("y", function (_, index) {
       *         return index * legendGap + 10;
       *       })
       *       .attr("class", "legend-text")
       *       .attr("alignment-baseline", "middle")
       *       .append("tspan")
       *       .text(function (line) {
       *         return line;
       *       });
       *   }
       */
    }
  }, [chartClassName, chartData, theme.palette.chartColors.axis, theme.palette.secondary.light]);

  return (
    <Grid
      style={{ padding: 20 }}
      className={classNames(classes.chartStyles, chartClassName)}
    />
  );
};

PieChart.propTypes = {
  classes: PropTypes.object,
  chartData: PropTypes.array,
  theme: PropTypes.object,
  chartClassName: PropTypes.string
};

export default withStyles(
  (theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
  }),
  { withTheme: true }
)(PieChart);