import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, MenuItem, Paper, IconButton, Chip, Typography } from '@material-ui/core';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import TextBox from '../TextBox/TextBox.jsx';
import Styles from '../../layouts/Styles.jsx';
import DomainSelectStyles from './DomainSelectStyles.jsx';
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import { sortTable } from '../../helpers/appHelpers.js';

const DomainSelect = (props) => {

    const { classes, availableList, displayValue, onBlur, onSelectDomain, isEditable, placeholder, onChange, details, theme, createNewDomain } = props;
    const [open, setOpen] = useState(false);
    const [addDoamin, setAddDomain] = useState(true);
    const [inputValue, setInputValue] = useState('');

    const inputChange = (value) => {
        setInputValue(value);
    };

    const handleKeyDown = (event, value) => {
        if (event.keyCode === 13 && !createNewDomain) {
            setOpen(true);
            setAddDomain(false);
        } else if (event.keyCode === 13) {
            onBlur(value);
            setOpen(false);
            setAddDomain(true);
            setInputValue('');
        }
    };

    const renderChips = () => {
        return (
            <Grid style={{ display: 'flex', alignItems: 'center', flexWrap: "wrap" }}>
                {
                    details.domains.map((domainName, index) =>
                        <Grid key={`${domainName}-${index}`} className={classes.chipList} style={{ marginTop: 5 }}>
                            <ToolTipComponent title={domainName} arrow>
                                <Chip
                                    className={classNames(classes.chip, classes.chipEllipsis)}
                                    label={domainName}
                                />
                            </ToolTipComponent>
                            <IconButton onClick={() => onChange('removeDomain', index)} className={classNames(classes.nopadding, 'chipremove')} style={{ top: -3 }}>
                                <CloseIcon className={classNames(classes.chipIcon, classes.closeIconDomain)} />
                            </IconButton>
                        </Grid>
                    )
                }
                {
                    addDoamin &&
                    <ToolTipComponent title="Add Domain" arrow>
                        <IconButton onClick={() => setAddDomain(false)} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 18 18">
                                <g id="Group_1479" data-name="Group 1479" transform="translate(4.962 1)">
                                    <g id="add_7_" data-name="add" transform="translate(-4.962 -1)">
                                        <path id="Path_895" data-name="Path 895" d="M10,1a9,9,0,1,0,9,9,9,9,0,0,0-9-9Zm0,16.364A7.364,7.364,0,1,1,17.364,10,7.364,7.364,0,0,1,10,17.364Z" transform="translate(-1 -1)" fill={theme.palette.secondary.main} />
                                        <path id="Path_896" data-name="Path 896" d="M14.25,9.75h-3v-3a.75.75,0,0,0-1.5,0v3h-3a.75.75,0,0,0,0,1.5h3v3a.75.75,0,0,0,1.5,0v-3h3a.75.75,0,0,0,0-1.5Z" transform="translate(-1.5 -1.5)" fill={theme.palette.secondary.main} />
                                    </g>
                                </g>
                            </svg>
                        </IconButton>
                    </ToolTipComponent>
                }
                {
                    (addDoamin && details.domains.length === 0) ?
                        <Typography onClick={() => setAddDomain(false)} variant={"body2"} className={classes.addDomain}>
                            {'Add Domain'}
                        </Typography>
                        : ""
                }
            </Grid>
        );
    };

    const renderInput = () => {
        return (
            <TextBox
                placeholder={placeholder ? placeholder : "Create or Select a domain"}
                className={classNames(classes.inlinetxt, classes.input)}
                value={inputValue}
                fullWidth
                onChange={(event) => inputChange(event.target.value)}
                onKeyDown={(event) => event.target.value.trim().length > 0 && handleKeyDown(event, event.target.value)}
                onClick={() => setOpen(Boolean(!isEditable))}
                disabled={isEditable}
                onBlur={() => setAddDomain(true)}
                autoFocus
            />
        );
    };

    const getSuggestions = (list, displayValue, availableList, input) => {
        list = list ? list : [];
        input = input ? input : '';
        return availableList.filter((item) => list.indexOf(item[displayValue]) === -1 && item[displayValue]?.toLowerCase().includes(input.toLowerCase()));
    };

    const selectDomain = (value) => {
        onSelectDomain(value);
        setOpen(false);
        setAddDomain(true);
        setInputValue('');
    };


    const renderSuggestion = (params) => {
        const { item, itemProps, displayValue } = params;
        return (
            <MenuItem
                key={item.id ? item.id : item.name}
                {...itemProps}
                selected={item.isSelected}
                component="div"
                onClick={() => selectDomain({ name: item.name, id: item.id })}
            >
                {item[displayValue]}
            </MenuItem>
        );
    };

    const useOutsideAlerter = (ref) => {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpen(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    return (
        <Grid className={classes.selectContainer}>
            <Grid className={classes.selectDropDown}>
                {renderChips()}
                {!addDoamin && renderInput()}
                {
                    open &&
                    (
                        <Paper className={classes.paper} square ref={wrapperRef}>
                            {
                                getSuggestions(details.domains, displayValue, sortTable(availableList, "asc", "name"), inputValue).map((item, index) =>
                                    renderSuggestion({
                                        item,
                                        index,
                                        displayValue
                                    })
                                )
                            }
                        </Paper>
                    )
                }
            </Grid>
        </Grid>
    );

};

DomainSelect.propTypes = {
    classes: PropTypes.object,
    availableList: PropTypes.array,
    displayValue: PropTypes.string,
    onSelectDomain: PropTypes.func,
    inputValue: PropTypes.string,
    onInputValueChange: PropTypes.func,
    onBlur: PropTypes.func,
    isEditable: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    details: PropTypes.array,
    theme: PropTypes.object,
    createNewDomain: PropTypes.bool
};

export default withStyles((theme) => ({
    ...DomainSelectStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DomainSelect);