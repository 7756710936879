const NopermissionComponentStyles = () => ({
    container: {
        height: "calc(100% - 92.5px)"
    },
    text: {
        fontSize: "16px"
    },
    center: {
        textAlign: "center"
    }
});

export default NopermissionComponentStyles;