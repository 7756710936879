import React, { useState, useCallback } from 'react';
import { withStyles, Dialog, DialogTitle, DialogContent, Grid, Typography, IconButton, Slide } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Close } from '@material-ui/icons';
import classNames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import { getPreviewDataByCategory } from '../../actions/metricsActions';
import Loader from '../Loaders/Loader.jsx';
import NoResultImg from '../../assets/images/NoDataset.png';
// import TextBox from '../TextBox/TextBox.jsx';
import Search from '../TextBox/Search.jsx';
import DomainDataPreviewStyles from '../DomainDataPreview/DomainDataPreviewStyles.jsx';
import DataPreviewTable from '../DataPreviewTable/DataPreviewTable.jsx';
// eslint-disable-next-line no-duplicate-imports
import ToolTipComponent from '../Tooltip/Tooltip.jsx';
import { ExportJsonCsv } from 'react-export-json-csv';

const Transition = React.forwardRef(Object.assign((props, ref) => <Slide direction="up" ref={ref} {...props} />), { displayName: 'Transition' });


const DashboardDataPreview = (props) => {
    const { classes, open, params, onClose, widgetName, theme } = props;
    const [searchKey, setSearchKey] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [downloadHeader, setDownloadHeader] = useState([]);
    const dispatch = useDispatch();
    const [previewData, setPreviewData] = useState({
        headers: [],
        inputData: []
    });

    const getPreviewData = useCallback(() => {
        setSearchKey('');
        setLoading(true);
        dispatch(getPreviewDataByCategory(params)).then((response) => {
            const headers = response && response.attributes ? response.attributes.map((attribute) => attribute.replace(/ /g, "_")) : [];
            const values = [];
            // eslint-disable-next-line func-names
            headers.forEach(function (value) {
                values.push({ 'key': value, 'name': value });
            });
            setDownloadHeader(values);
            setPreviewData({
                inputData: response && response.preview_data ? [...response.preview_data] : [],
                headers: [...headers]
            });
            setLoading(false);
        });
    }, [dispatch, params]);


    const onOpenDialog = useCallback(() => {
        getPreviewData();
    }, [getPreviewData]);

    const headers = searchKey ? previewData.headers.filter((header) => header.toString().toLowerCase().includes(searchKey.toLowerCase())) : previewData.headers;

    return (
        <Dialog open={open}
            disableBackdropClick
            disableEscapeKeyDown
            TransitionComponent={Transition}
            className={classes.queryFilterDialog}
            onEnter={() => onOpenDialog()}
            onExit={() => onClose()}>
            <DialogTitle style={{ paddingBottom: 0 }}>
                <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="h6">
                            {'Data Preview'}
                            {widgetName ? ` - ${widgetName} (${params.yaxis_value} - ${params.xaxis_value})` : ""}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton className={classes.nopadding} onClick={() => onClose()}>
                            <Close />
                        </IconButton>
                    </Grid>
                </Grid>
            </DialogTitle>
            <DialogContent style={{ position: "relative" }} className={classes.scroll}>
                <Grid>
                    <Grid container justify="flex-start" alignItems="center" style={{ marginTop: 4 }}>
                        <Grid item className={classNames(classes.includeSearchContainer)}>
                            <Search
                                value={searchKey}
                                placeholder="Search Attributes"
                                className={classes.dqSearchBox}
                                onChange={(value) => setSearchKey(value)}
                            />
                        </Grid>
                        {
                            (!isLoading && headers && headers.length > 0) &&
                            <Grid item style={{ marginLeft: 10 }}>
                                <ExportJsonCsv headers={downloadHeader} items={previewData.inputData}>
                                    <ToolTipComponent title="Download" arrow>
                                        <IconButton className={classes.padding6}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 21 21">
                                                <g id="download_4_" data-name="download (4)" transform="translate(-1.5 -1.5)">
                                                    <path id="Path_14400" data-name="Path 14400" d="M22,23.714v2.857A1.43,1.43,0,0,1,20.571,28H3.428A1.43,1.43,0,0,1,2,26.572V23.714a.714.714,0,0,1,1.428,0v2.857H20.571V23.714a.714.714,0,0,1,1.429,0Z" transform="translate(0 -6)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                                    <path id="Path_14401" data-name="Path 14401" d="M8.21,12.5a.714.714,0,1,1,1.01-1.01L13,15.275V2.714a.714.714,0,1,1,1.428,0V15.275l3.781-3.78a.714.714,0,1,1,1.01,1.01l-5,5a.715.715,0,0,1-1.011,0Z" transform="translate(-1.715)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                                </g>
                                            </svg>
                                        </IconButton>
                                    </ToolTipComponent>
                                </ExportJsonCsv>
                            </Grid>
                        }
                        {/* {
                            (!isLoading && headers && headers.length > 0) &&
                            <Grid item style={{ marginLeft: 10 }}>
                                {
                                    <TooltipComponent title="Export Data" arrow>
                                        <IconButton className={classes.padding8} onClick={() => exportData()}>
                                            {
                                                isExportLoading ?
                                                    <Loader size={'extraSmall'} type={'button'} classList={classes.relative} /> :
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24.342 24.106" fill={theme.palette.primary.main}>
                                                        <g id="upload_button" transform="translate(-1568.963 -266.973)">
                                                            <g id="Group_2" data-name="Group 2">
                                                                <path id="Path_2" data-name="Path 2" d="M1593.3,291.079h-24.342V276.71h2v12.369H1591.3V276.71h2Z" />
                                                                <path id="Path_3" data-name="Path 3" d="M1586.4,272.323l-1.41,1.42-3.03-3v10.78h-2v-10.7l-2.87,2.84-1.41-1.42,5.32-5.27Z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                            }
                                        </IconButton>
                                    </TooltipComponent>
                                }
                            </Grid>
                        } */}
                    </Grid>
                </Grid>
                <Grid className={classes.previewContainer}>
                    {
                        (!isLoading && headers && headers.length > 0) &&
                        <DataPreviewTable
                            headers={headers ? [...headers] : []}
                            previewData={previewData}
                        />
                    }
                    {
                        headers && headers.length === 0 && !isLoading &&
                        <Grid container alignItems="center" justify="center" className={classes.noResultFound}>
                            <Grid>
                                <img src={NoResultImg} alt="No Result Found" />
                                <Typography variant="h4" align="center">
                                    {'No Result Found'}
                                </Typography>
                            </Grid>
                        </Grid>
                    }
                    {isLoading && <Loader />}

                </Grid>
            </DialogContent>
        </Dialog>
    );
};

DashboardDataPreview.propTypes = {
    classes: PropTypes.object,
    open: PropTypes.bool,
    params: PropTypes.object,
    onClose: PropTypes.func,
    widgetName: PropTypes.string,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...DomainDataPreviewStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DashboardDataPreview);