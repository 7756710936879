import React from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import DriftReportFilter from '../../components/DriftReport/DriftReportFilter.jsx';
import DriftReportComponent from '../../components/DriftReport/DriftReport.jsx';
import { useSelector } from 'react-redux';
import GroupedDriftReportView from '../../components/DriftReport/GroupedDriftReportView.jsx';


const DriftReport = (props) => {
    const { history, classes } = props;
    const showGrouppedReportView = useSelector(({ notification }) => notification.showGrouppedReportView);
    return (
        <Grid>
            <ValidatorForm name="Notification" onSubmit={() => null}>
                <DriftReportFilter />
                {
                    !showGrouppedReportView &&
                    <Grid className={classes.reportContainer}>
                        <DriftReportComponent history={history} />
                    </Grid>
                }
                {
                    showGrouppedReportView &&
                    <Grid className={classes.reportContainer}>
                        <GroupedDriftReportView history={history} />
                    </Grid>
                }
            </ValidatorForm>
        </Grid>
    );
};

DriftReport.propTypes = {
    history: PropTypes.object,
    classes: PropTypes.object
};

export default withStyles((theme) => ({
    ...Styles(theme)
}), { withTheme: true })(DriftReport);