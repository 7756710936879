import React from 'react';
import { CircularProgress, withStyles, Grid } from '@material-ui/core';
import Styles from '../../layouts/Styles.jsx';
import ChartStyles from './ChartStyles.jsx';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getValue } from '../../helpers/appHelpers';

const Circular = (props) => {
    const { classes, value, size, showPercentage, circularFont, showValue, customStyle, isDisabled, noLink, dqScoreThreshold } = props;
    const getColor = (value) => {
        value = value ? parseFloat(value) : null;
        if (value === null || (!showPercentage || isDisabled)) {
            return classes.null;
        } else if (dqScoreThreshold?.scores?.length > 0) {
            let chartColor = null;
            dqScoreThreshold?.scores.forEach((score) => {
                if (Math.floor(value) >= score.min && Math.floor(value) <= score.max) {
                    chartColor = score.color;
                }

            });
            return chartColor;
        } else if (value <= 33) {
            return classes.color25;
        } else if (value > 33 && value <= 66) {
            return classes.color50;
        }
        return classes.color100;
    };
    const scoreStyle = (customStyle) ? customStyle : classes.progressContainer;
    return (
        <Grid className={classNames(scoreStyle, noLink ? classes.noPointer : null)} >
            <CircularProgress className={classes.progressbackground} size={size} variant="static" value={100} />
            <CircularProgress className={getColor(value)} style={{ color: getColor(value) }} size={size} variant="static" value={parseFloat(value)} />
            {
                showValue &&
                <div style={{ fontSize: circularFont ? circularFont : 10 }} className={!showPercentage || isDisabled ? classes.nullText : classes.value}>
                    {showPercentage ? `${getValue(value)}%` : 'NA'}
                </div>
            }
        </Grid>
    );
};

Circular.propTypes = {
    classes: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    size: PropTypes.number,
    showPercentage: PropTypes.bool,
    showValue: PropTypes.bool,
    circularFont: PropTypes.number,
    customStyle: PropTypes.object,
    isDisabled: PropTypes.bool,
    noLink: PropTypes.bool,
    dqScoreThreshold: PropTypes.object
};
export default withStyles((theme) => ({
    ...ChartStyles(theme),
    ...Styles(theme)
}))(Circular);