const MasterSearchPanelStyles = (theme) => ({
    searchPanel: {
        backgroundColor: theme.palette.background.paper,
        padding: '12px 0 12px 12px',
        '& .Mui-disabled': {
            background: `${theme.palette.grey.exteremeLight} !important`,
            '& > input': {
                background: `${theme.palette.grey.exteremeLight} !important`
            },
            '& > textarea': {
                background: `${theme.palette.grey.exteremeLight} !important`
            }
        }
    },
    masterSearchHeader: {
        paddingRight: 17,
        display: "flex",
        alignItems: "center"
    },
    srcsearchblock: {
        padding: '10px 16px!important'
    },
    textareaContainer: {
        marginTop: '5px',
        borderRadius: '3px',
        '& input': {
            marginTop: 5,
            paddingLeft: 10
        },
        '& .MuiInput-underline:before': {
            borderBottom: 0
        },
        '& .MuiInputBase-multiline': {
            padding: 0
        },
        '& textarea': {
            marginTop: 5,
            paddingLeft: 10
        }
    },
    dateInputContainer: {
        '& input': {
            marginTop: '0 !important'
        }
    },
    modelContainer: {
        height: 'calc(100vh - 280px)',
        overflowY: 'auto',
        paddingRight: 12
    },
    btnContainer: {
        marginTop: 20,
        paddingRight: 17
    },
    autoComplete: {
        borderBottom: '1px solid #eee',
        marginTop: 10
    },
    height: {
        '& input': {
            height: '36px !important',
            marginTop: 0
        }
    }
});

export default MasterSearchPanelStyles;