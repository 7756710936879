export const alerts = {
    // accountActions
    login: "Invalid Email or Password",
    inviteUser: "Invited User Successfully",

    // job alerts
    jobStatus: "<job_name> triggered successfully",

    // connectorsActions
    connectDatasource: "Connection Established Sucessfully",
    connectDatasourceFailure: "Please check the connection parameters before validate",
    deleteDashboard: "Dashboard Deleted Successfully",

    // datasetActions
    deleteDataSet: "Dataset Deleted Successfully",

    // datasourceActions
    deleteDatasource: "Datasource Deleted Successfully",
    domainNameError: "Error in Name",
    deleteDomain: "Domain Deleted Successfully",
    createDatasetEnrichment: "Dataset Enrichment Created Successfully",
    updateDatasetEnrichment: "Dataset Enrichment Updated Successfully",
    deleteDatasetEnrichment: "Dataset Enrichment Deleted Successfully",
    getImportReport: "Imported Successfully",

    // masterActions
    publishModelStarted: "Started to publish your model!",
    publishModel: "Model has been published successfully!",
    createSemanticModelSchedule: "Schedule Created Successfully",
    updateSemanticModelSchedule: "Schedule Updated Successfully",
    runMasterModelJob: "Model Job Scheduled Successfully",
    loadGenericTable: "Load Generic Table Successfully",
    generatePersonID: "Persion ID Generation Job Triggered Successfully",
    deleteSemanticModelSchedule: "Schedule has been cleared successfully",

    // scheduleActions
    runProfile: "Dataset Scheduled Successfully",
    updateSchedule: "Update Scheduled Successfully",
    killJob: "Killed Successfully",
    addMonitoringDetails: "Monitoring Successfully Added/ Updated"
};