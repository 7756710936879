import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Grid, Table, TableRow, TableHead, TableCell, Typography, TableBody } from '@material-ui/core';
import PropertiesListViewStyles from './PropertiesListViewStyles.jsx';
import Styles from '../../../../layouts/Styles.jsx';
import DatasetStyles from '../../DatasetStyles.jsx';
import TileViewStyles from '../TileView/TileViewStyles.jsx';
import RuleBuilderModal from './RuleBuilderModal.jsx';
import ChipInput from '../../../ChipInput/ChipInput.jsx';
import { useDispatch, useSelector } from 'react-redux';
import { createRule, createRuleGroup, getConditionOperators, getFieldType, getOperators, getQueryString, removeFormatting } from '../../../RuleBuilder/QueryBuilderUtil.jsx';
import config from '../../../RuleBuilder/QueryBuilderConfig.jsx';
import { updateListDatasetRules, updateRuleAttributes } from '../../../../actions/datasetActions.js';
import ToolTipComponent from '../../../Tooltip/Tooltip.jsx';


const RulesPanel = (props) => {
    const { classes, properties, isEditable, isDeletePermission, isEditPermission, profileSetting } = props;
    const dispatch = useDispatch();
    const datasetId = useSelector(({ dataset }) => dataset.selectedDatasetId);
    const datasource = useSelector(({ datasource }) => datasource.datasource);
    const propertiesList = useSelector(({ dataset }) => dataset.propertiesList);
    const userAttributes = useSelector(({ datasource }) => datasource.userAttributes);
    const [rules, setRules] = useState({});
    const [selectedProperties, setSelectedProperties] = useState({});
    const datasetRules = useSelector(({ dataset }) => dataset.rules);
    const [selectedRule, setSelectedRule] = useState(null);
    const [duplicateNameError, setduplicateNameError] = useState(false);
    const [selectedRuleIndex, setSelectedRuleIndex] = useState(-1);
    const [selectedAttribute, setSelectedAttribute] = useState('');
    const [anchorEl, setAnchorElement] = useState('');

    const resetSelectedAttribute = useCallback(() => {
        setSelectedRule(null);
        setSelectedRuleIndex(-1);
        setSelectedAttribute('');
        setAnchorElement(null);
    }, []);

    const addRule = useCallback((attributeName, anchorEl) => {
        const attribute = selectedProperties[attributeName];
        const ruleGroupParams = {
            defaultField: attribute.name,
            defaultFieldType: getFieldType(attribute.datatype),
            attributeType: attribute.datatype,
            connectionType: datasource?.type,
            isScan: datasource?.scan
        };
        const conditionalRuleParams = {
            connectionType: datasource?.type,
            isScan: datasource?.scan
        };
        const rule = {
            attribute: attribute && attribute.name ? attribute.name : '',
            datatype: attribute.datatype,
            priority: 1000,
            fieldtypes: [],
            params: [],
            name: '',
            description: '',
            isvalidrule: false,
            query: '',
            conditionalQuery: '',
            type: 'profile',
            ruleGroup: { ...createRuleGroup(ruleGroupParams) },
            conditionalRuleGroup: { ...createRuleGroup(conditionalRuleParams) },
            selectedAttributes: [],
            polarity: 'positive',
            'fieldtypes_except': [],
            'is_active': true,
            'class_name': 'CustomRule',
            'allow_dqscore': true,
            'is_user_defined': true,
            'rule_type': 'custom',
            'is_complex_rule': false,
            "is_sql_editor": false
        };
        setSelectedRule({ ...rule });
        setSelectedRuleIndex(-1);
        setduplicateNameError(false);
        setSelectedAttribute(attributeName);
        setAnchorElement(anchorEl);
    }, [datasource?.scan, datasource?.type, selectedProperties]);

    const updateRules = useCallback((rulesInput, rule = null) => {
        rulesInput.rules = [...rulesInput.rules];
        dispatch(updateListDatasetRules(datasetId, rulesInput)).then((responseRules) => {
            if (rule && responseRules) {
                let attributeRules = responseRules[rule?.type][rule?.attribute];
                attributeRules = attributeRules.filter((elem) => elem.class_name === 'CustomRule');
                rulesInput.rules = [...attributeRules];
            }
            dispatch(updateRuleAttributes(rulesInput));
        });
    }, [datasetId, dispatch]);

    const updateRulename = () => {
        setduplicateNameError(false);
    };

    const onSave = useCallback((rule) => {
        if (rule.name.trim() !== "") {
            let attributeRules = rules[selectedAttribute];
            attributeRules = attributeRules ? attributeRules : [];
            if (selectedRuleIndex <= -1) {
                const attributefiltername = attributeRules.filter((element) => element.name === rule.name);
                if (attributefiltername.length > 0) {
                    setduplicateNameError(true);
                    return false;
                }
                attributeRules.splice(0, 0, { ...rule });
            } else {
                attributeRules.splice(selectedRuleIndex, 1, { ...rule });
                const attributefiltername = attributeRules.filter((element) => element.name === rule.name);
                if (attributefiltername.length > 1) {
                    setduplicateNameError(true);
                    return false;
                }
            }
            rules[selectedAttribute] = [...attributeRules];
            const attributeProperty = properties[selectedAttribute];
            const rulesInput = {
                attributeId: attributeProperty.id,
                attribute: selectedAttribute,
                type: 'profile',
                'rule_type': 'custom',
                rules: [...attributeRules]
            };
            updateRules(rulesInput, rule);
            resetSelectedAttribute();
        }
    }, [resetSelectedAttribute, rules, selectedAttribute, selectedRuleIndex, properties, updateRules]);

    const onEditRule = useCallback((attributeName, rule, index, anchorEl) => {
        setSelectedAttribute(attributeName);
        if (rule.isQueryFilter) {
            rule.query = removeFormatting(rule.query ? rule.query : "");
        }
        setSelectedRule({ ...rule });
        setSelectedRuleIndex(index);
        setAnchorElement(anchorEl);
    }, []);

    const deleteRule = useCallback((attribute, ruleIndex) => {
        if (!(attribute in rules)) {
            return;
        }
        const attributeRules = rules[attribute];
        attributeRules.splice(ruleIndex, 1);
        rules[attribute] = [...attributeRules];
        const attributeProperty = properties[attribute];
        const rulesInput = {
            attributeId: attributeProperty.id,
            attribute,
            type: 'profile',
            'rule_type': 'custom',
            rules: [...attributeRules]
        };
        updateRules(rulesInput);
        resetSelectedAttribute();
    }, [resetSelectedAttribute, rules, properties, updateRules]);

    const loadProperties = useCallback((properties) => {
        if (properties) {
            setSelectedProperties({ ...properties });
        }
    }, []);

    useEffect(() => {
        if (datasetId && properties) {
            loadProperties(properties);
        }
    }, [datasetId, loadProperties, properties]);

    const prepareCustomRule = useCallback((attributeRule, fieldType, customRule) => {
        const operators = getOperators(fieldType);
        const conditionalOperators = getConditionOperators();
        const ruleParams = {
            defaultField: customRule.attribute,
            defaultFieldType: fieldType,
            attributeType: customRule.datatype,
            connectionType: datasource?.type,
            isScan: datasource?.scan
        };
        const customAttributeRule = createRule(ruleParams);
        const operator = operators.find((p) => p.label.toLowerCase() === (typeof (attributeRule.operator) === "object" ? attributeRule.operator.label.toLowerCase() : attributeRule.operator.toLowerCase()));
        if (operator) {
            customAttributeRule.operator = operator;
        }
        customAttributeRule.value = attributeRule.value;
        if (attributeRule.condition) {
            const conditionOperator = conditionalOperators.find((p) => p.label.toLowerCase() === attributeRule.condition.toLowerCase());
            if (conditionOperator) {
                customAttributeRule.conditionOperator = conditionOperator;
            }
            customAttributeRule.count = attributeRule.count;
        }
        if (attributeRule.conditionOperator) {
            customAttributeRule.conditionOperator = attributeRule.conditionOperator;
            customAttributeRule.count = attributeRule.count;
        }
        return customAttributeRule;
    }, [datasource?.scan, datasource?.type]);

    const prepareCustomRuleGroup = useCallback((attributeRule, fieldType, customRule) => {
        const processedRules = [];
        if (attributeRule.rules) {
            for (const rule of attributeRule.rules) {
                const ruleInfo = rule.connector ? prepareCustomRuleGroup(rule, fieldType, customRule) : prepareCustomRule(rule, fieldType, customRule);
                processedRules.push({ ...ruleInfo });
            }
        }
        attributeRule.rules = [...processedRules];
        return attributeRule;
    }, [prepareCustomRule]);

    const prepareRule = useCallback((customRule) => {
        if ('ruleGroup' in customRule) {
            return customRule;
        }
        const rule = customRule.params[0];
        const fieldType = getFieldType(customRule.datatype);

        const ruleGroupParams = {
            defaultField: customRule.attribute,
            defaultFieldType: fieldType,
            attributeType: customRule.datatype,
            connectionType: datasource?.type,
            isScan: datasource?.scan
        };
        const ruleGroup = createRuleGroup(ruleGroupParams);
        if (rule && rule.connector) {
            const connector = config.connectors.find((p) => p.name.toLowerCase() === rule.connector.toLowerCase());
            if (connector) {
                ruleGroup.connector = connector.value;
            }
        }

        const rules = rule && rule.rules ? rule.rules : [];
        const processedRules = [];
        for (const attributeRule of rules) {
            const ruleInfo = !attributeRule.connector ? prepareCustomRule(attributeRule, fieldType, customRule) : prepareCustomRuleGroup(attributeRule, fieldType, customRule);
            processedRules.push({ ...ruleInfo });
        }
        ruleGroup.rules = [...processedRules];
        customRule.ruleGroup = { ...ruleGroup };
        customRule.query = getQueryString(ruleGroup);
        return customRule;
    }, [datasource?.scan, datasource?.type, prepareCustomRule, prepareCustomRuleGroup]);

    const loadRules = useCallback((customRules) => {
        setRules({ ...customRules });
    }, []);

    useEffect(() => {
        if (selectedProperties && datasetRules && datasetRules.profile) {
            const profileRules = datasetRules && datasetRules.profile ? datasetRules.profile : {};
            const attributes = Object.keys(selectedProperties);
            const rules = {};
            for (const attributeName of attributes) {
                const attribute = selectedProperties[attributeName];
                let attributeProfileRules = [];
                if (!(attribute.name in profileRules)) {
                    attributeProfileRules = [];
                } else {
                    attributeProfileRules = profileRules[attribute.name];
                }
                const attributeRules = attributeProfileRules.filter((p) => p.class_name === "CustomRule");
                let customRules = [];
                for (const rule of attributeRules) {
                    rule.attribute = attribute.name;
                    rule.datatype = attribute.datatype;
                    const customRule = prepareRule(rule);
                    customRules.push({ ...customRule });
                }
                customRules = customRules.sort((a, b) => b['rule_id'] - a['rule_id']);
                rules[attributeName] = customRules;
            }
            loadRules({ ...rules });
        }
    }, [datasetRules, selectedProperties, loadRules, prepareRule]);

    // Handle if rules are empty
    let selectedRules = rules;
    if (rules && Object.keys(rules).length <= 0 && propertiesList && propertiesList.length > 0) {
        selectedRules = {};
        for (const propertyItem of propertiesList) {
            if (!propertyItem.attribute_id) {
                continue;
            }
            const key = propertyItem.name;
            selectedRules[key] = [];
        }
    }

    return (
        <Grid container direction="column">
            <Table stickyHeader className={classes.propertyListPageTable}>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Attribute'}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant="body1" className={classes.tableHeader}>
                                {'Rules'}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.propListPageTableBody}>
                    {
                        Object.keys(selectedRules).map((attribute, index) => {
                            return (
                                <TableRow key={`attributeProperty${index}`}>
                                    <TableCell>
                                        <ToolTipComponent title={attribute ? attribute : ''} arrow placement="bottom-start">
                                            <Typography className={classes.curateAttributeTitle}>
                                                {attribute}
                                            </Typography>
                                        </ToolTipComponent>
                                    </TableCell>
                                    <TableCell className={classes.rulePanelChipContainer}>
                                        <ChipInput
                                            addtooltiptitle="Add Rules"
                                            values={attribute in selectedRules ? selectedRules[attribute] : []}
                                            displayName="name"
                                            displayCount={2}
                                            name="patterns"
                                            enableAddButton={isEditPermission || isEditable}
                                            isEditable={isEditable}
                                            isEditPermission={isEditPermission}
                                            onClickAdd={(event) => addRule(attribute, event.target)}
                                            onClickEdit={(rule, index, event) => onEditRule(attribute, rule, index, event.target)}
                                            onDelete={(index) => deleteRule(attribute, index)}
                                            isDeletePermission={isDeletePermission} />
                                    </TableCell>
                                </TableRow>
                            );
                        })
                    }
                </TableBody>
            </Table>
            {
                selectedRule &&
                <RuleBuilderModal open={Boolean(selectedRule)}
                    anchroEl={anchorEl}
                    duplicateNameError={duplicateNameError}
                    attribute={properties[selectedAttribute]}
                    rule={selectedRule}
                    updateRulename={updateRulename}
                    properties={selectedProperties}
                    profileSetting={profileSetting}
                    userAttributes={[...userAttributes]}
                    onSave={(rule) => onSave(rule)}
                    onClose={() => resetSelectedAttribute()}
                />
            }
        </Grid>
    );
};

RulesPanel.propTypes = {
    classes: PropTypes.object,
    properties: PropTypes.object,
    profileSetting: PropTypes.object,
    isEditable: PropTypes.bool,
    isDeletePermission: PropTypes.bool,
    isEditPermission: PropTypes.bool
};

export default withStyles((theme) => ({
    ...PropertiesListViewStyles(theme),
    ...DatasetStyles(theme),
    ...TileViewStyles(theme),
    ...Styles(theme)
}))(RulesPanel);