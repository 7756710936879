const WelcomeStyles = (theme) => ({
	root: {
		flexGrow: '1 auto',
		height: '100%'
	},
	container: {
		height: '100%'
	},
	tileContainer: {
		padding: '0 !important',
		height: '100%'
	},
	welcomeTxt: {
		color: theme.palette.grey.darkTxt
	},
	secondaryText: {
		color: theme.palette.grey.dark,
		margin: '18px 0px 30px 0px'
	},
	paragraphTxt: {
		color: theme.palette.grey.dark,
		margin: '10px 2px'
	},
	cirlceContainer: {
		minHeight: 125,
		width: 'calc(100% - 1px)',
		marginTop: 1,
		position: 'relative',
		display: 'inline-block',
		overflow: 'hidden'
	},
	circle: {
		width: "100%",
		height: 145,
		position: "absolute",
		top: '-85px',
		left: 0,
		right: 0,
		backgroundColor: theme.palette.background.default,
		borderRadius: 0,
		borderBottomLeftRadius: '50%',
		borderBottomRightRadius: '50%'
	},
	iconContainer: {
		position: 'absolute',
		top: 32,
		left: 0,
		right: 0,
		margin: '0 auto'
	},
	icon: {
		width: 40,
		height: 40,
		backgroundColor: theme.palette.secondary.main,
		boxShadow: `0px 3px 15px ${theme.palette.secondary.main}`,
		borderRadius: '50%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	titleContainer: {
		padding: '0 10px 10px'
	},
	titleTxt: {
		marginTop: -40
	}


});

export default WelcomeStyles;