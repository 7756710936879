import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import Styles from '../../layouts/Styles.jsx';
import DataPreparationInfo from '../../components/DataPreparation/DataPreparationInfo.jsx';
import DataPreparationDataTable from '../../components/DataPreparation/DataPreparationDataTable.jsx';
import DataPreparationTransformationPreview from '../../components/DataPreparation/DataPreparationTransformationPreview.jsx';
import { deleteConfiguration, updateConfiguration } from '../../actions/dataPreparationActions';
import AlertDialog from '../../components/AlertDialog/AlertDialog.jsx';

const DataPreparationDetail = (props) => {
    const { classes, match, location, history } = props;
    const dispatch = useDispatch();
    const trasformDatasetId = match.params.id;
    const breadcrumb = [
        {
            name: 'Data Preparation',
            path: '/datapreparation',
            type: "main"
        },
        {
            name: location.state.source && location.state.source.name ? location.state.source.name : "",
            path: `/datapreparation/${location.state.source.id}`,
            type: "config"
        }, {
            name: location.state.type === "join" ? "Target Dataset" : location.state.transform.target_dataset.target_name,
            path: ''
        }
    ];
    const [search, setSearch] = useState('');
    const [dataPreparation, setDataPreparation] = useState({
        "name": '',
        'is_disabled': false
    });
    const [isDelete, setDeleteDialog] = useState(false);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const prepareTransformConfig = useCallback(() => {
        let transformConfig = {};
        if (location.state.type === "join") {
            transformConfig = {
                ...location.state.transform,
                "name": "Target Dataset",
                "is_disabled": true
            };
        } else {
            transformConfig = {
                ...location.state.transform,
                "name": location.state.transform && location.state.transform.target_dataset ? location.state.transform.target_dataset.target_name : "Target Dataset",
                "is_disabled": false
            };
        }
        setDataPreparation({ ...transformConfig });
    }, [location.state.transform, location.state.type]);

    useEffect(() => {
        prepareTransformConfig();
    }, [prepareTransformConfig]);

    const updateProperties = useCallback((property) => {
        let model = {};
        if (property === "name") {
            model = { "target_dataset": { ...dataPreparation.target_dataset, "target_name": dataPreparation.name } };

        }
        dispatch(updateConfiguration(trasformDatasetId, model));
    }, [dispatch, dataPreparation, trasformDatasetId]);


    const onChange = useCallback((property, value) => {
        dataPreparation[property] = value;
        setDataPreparation({ ...dataPreparation });
    }, [dataPreparation]);


    const deleteData = useCallback(() => {
        dispatch(deleteConfiguration(trasformDatasetId)).then(() => {
            history.push({ pathname: `/datapreparation/${location.state.source.id}`, state: { source: location.state.source } });
        });
    }, [dispatch, trasformDatasetId, history, location.state.source]);

    const infoAction = useCallback((type) => {
        if (type === "delete") {
            setDeleteDialog(true);
        }
    }, []);

    const onChangeSearch = (value) => {
        setSearch(value);
    };


    const redirect = (data) => {
        if (data.type === "main") {
            history.push(data.path);
        } else if (data.type === "config") {
            history.push({ pathname: data.path, state: { source: location.state.source } });
        }
    };

    const openTransformationDialog = (event) => {
        setOpen(true);
        setAnchorEl(event.target);
    };


    const onCloseDialog = () => {
        setOpen(false);
    };

    return (
        <Grid className={classes.container}>
            <ValidatorForm name="master" onSubmit={() => null} >
                <Grid className={classes.paddingTop20}>
                    <DataPreparationInfo
                        redirect={redirect}
                        breadcrumb={breadcrumb}
                        dataPreparation={dataPreparation}
                        trasformDatasetId={trasformDatasetId}
                        dataId={trasformDatasetId}
                        onSave={updateProperties}
                        onChange={onChange}
                        history={history}
                        isPreview
                        infoAction={infoAction}
                        search={search}
                        onChangeSearch={onChangeSearch}
                        openTransformationDialog={openTransformationDialog}
                    />
                </Grid>
                <DataPreparationDataTable transformId={trasformDatasetId} search={search} />
            </ValidatorForm >
            <AlertDialog title="Delete Transform Dataset"
                message={`Are you sure you want to delete the transform Dataset ${dataPreparation ? dataPreparation.name : 'Target Dataset'}?`}
                okButtonText="OK"
                cancelButtonText="Cancel"
                show={isDelete}
                onClickOK={() => deleteData()}
                onClickCancel={() => setDeleteDialog(false)} />
            {
                open &&
                <DataPreparationTransformationPreview
                    onClose={() => onCloseDialog()}
                    open={open}
                    anchorElement={anchorEl}
                    transformId={trasformDatasetId} />
            }
        </Grid >
    );
};

DataPreparationDetail.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object
};


export default (withStyles((theme) => ({
    ...Styles(theme)
}))(DataPreparationDetail));