import React, { useEffect, useCallback, useState } from 'react';
import { Grid, withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { updateViews } from '../../actions/datasourceActions';
import { updateRuleWeightage, getDomainDataset, getdomaindatasources, getdomaindatasets, getdomainattributes, getdomainrules, searchDomain } from '../../actions/domainActions';
import DomainDataPreview from '../DomainDataPreview/DomainDataPreview.jsx';
import Search from '../TextBox/Search.jsx';
import DomainStyles from './DomainStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import classNames from 'classnames';
import DomainQualityListView from './DomainQualityListView.jsx';


const DomainQuality = (props) => {
    const { domainId, history, selectedRule, redirectData, classes, dq_score_threshold } = props;

    const [isLoading, setLoading] = useState(false);
    const [isInvalidDataset, setisInvalidDataset] = useState(false);
    const [isInvalidAttribute, setisInvalidAttribute] = useState(false);
    const [group_by_datasource, setgroup_by_datasource] = useState(false);
    const [domainDatasources, setDomainDatasources] = useState([]);
    const [domainDatasets, setDomainDatasets] = useState([]);
    const [domainAttributes, setDomainAttributes] = useState([]);
    const [domainRules, setDomainRules] = useState([]);
    const [datasets, setDatasets] = useState([]);
    const dispatch = useDispatch();
    const [open, setOpenDialog] = useState(false);
    const [previewParams, setPreviewParams] = useState({
        type: 'datasource'
    });
    const [search, setSearch] = useState('');
    const [searchData, setSearchData] = useState({});
    const [datasourceExpanded, setDatasourceExpanded] = React.useState(false);
    const [datasetExpanded, setDatasetExpanded] = React.useState(false);
    const [attributeExpanded, setAttributeExpanded] = React.useState(false);
    const [timeOutValue, setTimeOutValue] = React.useState(null);

    useEffect(() => {
        if (domainId) {
            getDatasets();
        }
    }, [domainId]);

    const getDatasets = useCallback(() => {
        setLoading(true);
        dispatch(getDomainDataset(domainId)).then((response) => {
            if (response) {
                setDatasets([...response.data]);
                setisInvalidDataset(response.showdataset);
                setisInvalidAttribute(response.showAttribute);
                setgroup_by_datasource(response.group_by_datasource);
                loadDomainDatasources(response.group_by_datasource);
            }
        });
    }, [dispatch, domainId]);

    const loadDomainDatasources = (datasource_group, isLoadMore = false, search = null) => {
        if (!isLoadMore) {
            setLoading(true);
        }
        const requestParams = {
            domain_id: domainId,
            method: datasource_group ? 'datasource' : 'dataset',
            offset: isLoadMore ? domainDatasources.length : 0,
            limit: 30,
            search_ids: datasource_group ? search?.datasources?.filter((elem) => elem) || searchData?.datasources?.filter((elem) => elem) || [] : search?.datasets?.filter((elem) => elem) || searchData?.datasets?.filter((elem) => elem) || []
        };
        dispatch(getdomaindatasources(requestParams)).then((response) => {
            let responseData = response?.data || [];
            if (isLoadMore) {
                responseData = [...domainDatasources, ...responseData];
            }
            setDomainDatasources(responseData);
            if (!isLoadMore) {
                setLoading(false);
            }
        });
    };

    const loadDomainDatasets = (source_id, isLoadMore = false) => {
        const requestParams = {
            domain_id: domainId,
            source_id: source_id,
            offset: isLoadMore ? domainDatasets.length : 0,
            limit: 30,
            search_ids: searchData?.datasets?.filter((elem) => elem) || []
        };
        dispatch(getdomaindatasets(requestParams)).then((response) => {
            let responseData = response?.data || [];
            if (isLoadMore) {
                responseData = [...domainDatasets, ...responseData];
            }
            setDomainDatasets(responseData);
        });
    };

    const loadDomainAttributes = (dataset_id, isLoadMore = false) => {
        const requestParams = {
            domain_id: domainId,
            dataset_id: dataset_id,
            offset: isLoadMore ? domainAttributes.length : 0,
            limit: 30,
            search_ids: searchData?.attributes?.filter((elem) => elem) || []
        };
        dispatch(getdomainattributes(requestParams)).then((response) => {
            let responseData = response?.data || [];
            if (isLoadMore) {
                responseData = [...domainAttributes, ...responseData];
            }
            setDomainAttributes(responseData);
        });
    };

    const loadDomainRules = (attribute_id, schedule_id, isLoadMore = false) => {
        const requestParams = {
            domain_id: domainId,
            attribute_id: attribute_id,
            schedule_id: schedule_id,
            /*
             * offset: isLoadMore ? domainRules.length : 0,
             * limit: 30,
             */
            search_ids: searchData?.rules?.filter((elem) => elem) || []
        };
        dispatch(getdomainrules(requestParams)).then((response) => {
            let responseData = response?.data || [];
            if (isLoadMore) {
                responseData = [...domainRules, ...responseData];
            }
            setDomainRules(responseData);
        });
    };

    const updateSourceRule = useCallback((ruleId, property, value) => {
        const ruleList = [...domainRules];
        const index = ruleList.findIndex((rule) => rule.id === ruleId);
        if (index !== -1) {
            ruleList[index][property] = value;
            setDomainRules([...ruleList]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domainRules]);

    const onChangeRuleProperty = useCallback((property, value, ruleId) => {
        const model = {
            [property]: value
        };
        dispatch(updateRuleWeightage(ruleId, model));

        updateSourceRule(ruleId, property, value);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domainRules]);

    const openDialog = useCallback((rule, type, contenttype) => {
        if (contenttype === "Valid_Records") {
            if (parseInt(rule.invalid_percentage) === 100) {
                return;
            }
        } else {
            if (!rule.invalid_records) {
                return;
            }
        }
        let ruleName = type === "rule" ? rule.name : null;
        if (ruleName) {
            ruleName = rule.class_name === 'CustomRule' ? rule.name : rule.name;
        }
        setPreviewParams({
            type: type,
            name: rule.name ? rule.name : "",
            datasetId: rule.dataset_id ? rule.dataset_id : 0,
            attributeName: type === "attribute" ? rule.name : rule.attribute_name,
            ruleName: ruleName,
            polarity: rule?.polarity ?? 'positive',
            domainId: domainId,
            metrics_type: contenttype
        });
        setOpenDialog(true);
    }, [domainId]);

    const onChangeSearch = (value) => {
        setSearch(value);
        if (timeOutValue) {
            clearTimeout(timeOutValue);
        }
        setTimeOutValue(setTimeout(() => {
            setLoading(true);
            const requestParams = {
                search: value,
                domain_id: domainId
            };
            dispatch(searchDomain(requestParams)).then((response) => {
                setSearchData(response?.data || {});
                setDatasourceExpanded(false);
                setDatasetExpanded(false);
                setAttributeExpanded(false);
                loadDomainDatasources(group_by_datasource, false, response?.data || {});
            });
        }, 3000));
    };


    const handleAccordionChange = (panel, type, id, schedule_id = null) => (event, isExpanded) => {
        switch (type) {
            case 'datasource':
                setDatasourceExpanded(isExpanded ? panel : false);
                setDatasetExpanded(false);
                setAttributeExpanded(false);
                if (isExpanded) {
                    setDomainDatasets([]);
                    setDomainAttributes([]);
                    setDomainRules([]);
                    loadDomainDatasets(id);
                }
                break;
            case 'dataset':
                setDatasetExpanded(isExpanded ? panel : false);
                setAttributeExpanded(false);
                if (isExpanded) {
                    setDomainAttributes([]);
                    setDomainRules([]);
                    loadDomainAttributes(id);
                }
                break;
            case 'attribute':
                setAttributeExpanded(isExpanded ? panel : false);
                if (isExpanded) {
                    setDomainRules([]);
                    loadDomainRules(id, schedule_id);
                }
                break;
            default:
                break;
        }
        // setDatasourceExpanded(isExpanded ? panel : false);
    };

    const redirectProperty = useCallback((rule, type) => {
        const model = {
            "source_id": rule.source_id ? rule.source_id : rule.id || 0,
            "dataset_id": type !== "source" ? rule?.dataset_id || rule?.id || 0 : null
        };
        dispatch(updateViews(model));
        if (type === "attribute" || type === "rule") {
            history.push({ pathname: `/dataset/${rule.dataset_id}`, state: { datasource: {}, datasourceId: rule.source_id, attribute: rule.attribute_name ? rule.attribute_name : rule.name } });
        } else if (type === "dataset") {
            history.push({ pathname: `/dataset/${rule.id}`, state: { datasource: {}, datasourceId: rule.source_id } });
        } else {
            history.push({ pathname: `/catalog/${rule.id}` });
        }
    }, [dispatch, history]);


    return (
        <Grid className={classNames(classes.ruleListContainer, classes.relative, classes.height100p)} align="center">
            <Grid container justify="space-between" alignItems="center">
                <Grid item>
                    <Typography component="h5" variant="h5">
                        {'Domain Quality'}
                    </Typography>
                </Grid>
                <Grid align="right">
                    <Search
                        value={search}
                        onChange={(value) => onChangeSearch(value)}
                        placeholder="Search Here"
                    />
                </Grid>
            </Grid>
            {
                // !isLoading &&
                <Grid>
                    <DomainQualityListView
                        selectedRule={selectedRule}
                        openDialog={(rule, type, contenttype) => openDialog(rule, type, contenttype)}
                        datasources={domainDatasources}
                        datasets={domainDatasets}
                        attributes={domainAttributes}
                        rules={domainRules}
                        isInvalidDataset={isInvalidDataset}
                        isInvalidAttribute={isInvalidAttribute}
                        group_by_datasource={group_by_datasource}
                        redirectData={() => redirectData()}
                        redirect={(rule, type) => redirectProperty(rule, type)}
                        onChange={(property, value, ruleId) => onChangeRuleProperty(property, value, ruleId)}
                        dq_score_threshold={dq_score_threshold}
                        loadDomainDatasources={loadDomainDatasources}
                        loadDomainDatasets={loadDomainDatasets}
                        loadDomainAttributes={loadDomainAttributes}
                        loadDomainRules={loadDomainRules}
                        handleAccordionChange={handleAccordionChange}
                        datasourceExpanded={datasourceExpanded}
                        datasetExpanded={datasetExpanded}
                        attributeExpanded={attributeExpanded}
                        isLoading={isLoading}
                    />
                </Grid>
            }
            <DomainDataPreview
                open={open}
                params={previewParams}
                datasets={datasets}
                onClose={() => setOpenDialog(false)} />
        </Grid>
    );
};

DomainQuality.propTypes = {
    domainId: PropTypes.number,
    history: PropTypes.object,
    selectedRule: PropTypes.object,
    redirectData: PropTypes.func,
    classes: PropTypes.object,
    dq_score_threshold: PropTypes.object
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DomainQuality);