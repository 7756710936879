import React, { Component } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PatternRuleGroup from './PatternRuleGroup.jsx';
import { isPatternRuleGroup, createPatternRule, createPatternRuleGroup } from './PatternQueryBuilderUtil.jsx';
import PatternRuleBuilderStyles from './PatternQueryBuilderStyles.jsx';
import Styles from '../../layouts/Styles.jsx';

class PatternQueryBuilder extends Component {
    constructor(props) {
        super(props);
        const ruleGroupParams = {
            connectionType: props.selectedDatasource?.type ?? '',
            isScan: props.selectedDatasource?.scan ?? false
        };
        const initialRule = createPatternRuleGroup(ruleGroupParams);
        this.state = {
            ruleGroup: {
                ...initialRule
            },
            conditionalRuleGroup: {
                ...initialRule
            },
            isComplexRule: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.ruleGroup && props.ruleGroup.id !== state.ruleGroup.id) {
            return {
                ...state,
                ruleGroup: { ...props.ruleGroup }
            };
        }
        const ruleGroupParams = {
            connectionType: props.selectedDatasource?.type ?? '',
            isScan: props.selectedDatasource?.scan ?? false
        };
        return {
            ...state,
            ruleGroup: { ...createPatternRuleGroup(ruleGroupParams) }
        };
    }

    componentDidMount() {
        const ruleGroupParams = {
            connectionType: this.props.selectedDatasource?.type ?? '',
            isScan: this.props.selectedDatasource?.scan ?? false
        };
        const ruleGroup = createPatternRuleGroup(ruleGroupParams);
        this.setState({ ruleGroup: { ...ruleGroup } });
    }

    onAddRule(parentRuleId) {
        const ruleGroup = { ...this.props.ruleGroup };
        const parentRule = this.findRule(parentRuleId, ruleGroup);
        const ruleParams = {
            connectionType: this.props.selectedDatasource?.type ?? '',
            isScan: this.props.selectedDatasource?.scan ?? false
        };
        const rule = createPatternRule(ruleParams);
        parentRule.rules.push({
            ...rule
        });
        this.onQueryChange(ruleGroup);
    }

    onAddRuleGroup(parentRuleId) {
        const ruleGroup = { ...this.props.ruleGroup };
        const parentRule = this.findRule(parentRuleId, ruleGroup);

        const ruleGroupParams = {
            connectionType: this.props.selectedDatasource?.type ?? '',
            isScan: this.props.selectedDatasource?.scan ?? false
        };
        const childRuleGroup = createPatternRuleGroup(ruleGroupParams);
        parentRule.rules.push({
            ...childRuleGroup
        });
        this.onQueryChange(ruleGroup);
    }

    findRule(ruleId, parentRule) {
        if (parentRule.id === ruleId) { return parentRule; }

        for (const rule of parentRule.rules) {
            if (rule.id === ruleId) {
                return rule;
            } else if (isPatternRuleGroup(rule)) {
                const subRule = this.findRule(ruleId, rule);
                if (subRule) { return subRule; }
            }
        }
    }

    onPropertyChange(property, value, ruleId) {
        const ruleGroup = { ...this.props.ruleGroup };
        const rule = this.findRule(ruleId, ruleGroup);
        rule[property] = value;
        this.onQueryChange(ruleGroup);
    }

    onRuleRemove(ruleId, parentId) {
        const ruleGroup = { ...this.props.ruleGroup };
        const parentRule = this.findRule(parentId, ruleGroup);
        const ruleIndex = parentRule.rules.findIndex((rule) => rule.id === ruleId);
        parentRule.rules.splice(ruleIndex, 1);

        if (ruleGroup.rules.length <= 0) {
            const ruleParams = {
                connectionType: this.props.selectedDatasource?.type ?? '',
                isScan: this.props.selectedDatasource?.scan ?? false
            };
            const rule = createPatternRule(ruleParams);
            parentRule.rules.push({ ...rule });
        }

        this.onQueryChange(ruleGroup);
    }

    onGroupRemove(groupId, parentId) {
        const ruleGroup = { ...this.props.ruleGroup };
        const parentRule = this.findRule(parentId, ruleGroup);
        const ruleIndex = parentRule.rules.findIndex((group) => group.id === groupId);
        parentRule.rules.splice(ruleIndex, 1);
        this.onQueryChange(ruleGroup);
    }

    onQueryChange(ruleGroup) {
        if (this.props.onQueryChange) {
            this.props.onQueryChange(ruleGroup);
        }
    }

    render() {
        const { classes, ruleGroup, isLoading, onClear, theme, ...props } = this.props;
        const builderConfig = {
            classes: classes,
            onAddRule: (...props) => this.onAddRule(...props),
            onAddRuleGroup: (...props) => this.onAddRuleGroup(...props),
            onPropertyChange: (...props) => this.onPropertyChange(...props),
            onRuleRemove: (...props) => this.onRuleRemove(...props),
            onGroupRemove: (...props) => this.onGroupRemove(...props),
            ...props
        };

        return (
            <Grid container className={classNames(classes.queryBuilder)}>
                <Grid item xs={12} className={classNames('ruleQueryBuilder')}>
                    <PatternRuleGroup id={ruleGroup.id}
                        parentId={null}
                        rules={[...ruleGroup.rules]}
                        connector={ruleGroup.connector}
                        className={classes.parentRule}
                        not={ruleGroup.not}
                        theme={theme}
                        builderConfig={builderConfig} />
                </Grid>
            </Grid>
        );
    }
}

PatternQueryBuilder.propTypes = {
    classes: PropTypes.object,
    ruleGroup: PropTypes.object,
    isLoading: PropTypes.bool,
    onClear: PropTypes.func,
    theme: PropTypes.object,
    onQueryChange: PropTypes.func,
    selectedDatasource: PropTypes.object
};

export default withStyles((theme) => ({
    ...PatternRuleBuilderStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(PatternQueryBuilder);