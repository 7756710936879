import React from 'react';
import { withStyles, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import DomainStyles from './DomainStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import DomainMetrics from './DomainMetrics.jsx';
import DomainTimeline from './DomainTimeline.jsx';
import DomainDimension from './DomainDimension.jsx';
// import PieCircular from '../Charts/PieCircular.jsx';
import Circular from '../Charts/Circular.jsx';
const DomainSummary = (props) => {
    const { classes, domain, domainId, totalRules, onChangeTab, dq_score_threshold } = props;

    return (
        <Grid container spacing={2} style={{ overflowY: 'auto', overflowX: 'hidden', height: 'calc(100vh - 170px)' }}>
            <Grid item xs={12}>
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Grid className={classes.cardSummary} container alignItems="center" onClick={() => onChangeTab(1)}>
                            <Circular
                                circularFont={16}
                                showPercentage={Boolean(domain.dqscore !== 0)}
                                showValue
                                size={100}
                                value={domain.dqscore ? domain.dqscore : 0}
                                dqScoreThreshold={dq_score_threshold}
                            />
                            {/* <PieCircular
                                chartClassName="governance-progress"
                                width={160}
                                height={160}
                                showPercentage
                                innerRadius={60}
                                value={domain.dqscore ? domain.dqscore : 0} /> */}
                            <Typography className={classes.marginTop10} component="h4" variant="h4">
                                Overall Domain Score
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={9}>
                        <DomainMetrics domainId={domainId} totalRules={totalRules} onChangeTab={(index) => onChangeTab(index)} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <DomainDimension domainId={domainId} />
            </Grid>
            <Grid item xs={8}>
                <DomainTimeline domainId={domainId} />
            </Grid>
        </Grid>
    );
};

DomainSummary.propTypes = {
    classes: PropTypes.object,
    domain: PropTypes.object,
    domainId: PropTypes.number,
    totalRules: PropTypes.number,
    onChangeTab: PropTypes.func,
    dq_score_threshold: PropTypes.object
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DomainSummary);