/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearLineageResponse, getLineageGraph } from '../../actions/lineageActions';
import PropTypes from 'prop-types';
import _ from 'lodash';
import useDynamicRefs from '../../helpers/dynamicRef';
import * as d3 from 'd3';
import LineageStyle from './LineageStyle.jsx';
import Loader from '../../components/Loaders/Loader.jsx';
import { lineageActions } from '../../constants/actionTypes/lineageActionTypes';
import { Box, FormControlLabel, Grid, Tooltip, Typography, Switch } from '@material-ui/core';
import BackForwardLineageButtons from '../../components/Lineage/BackForwardLineageButtons.jsx';
import { appConstants } from '../../constants/appConstants';
import SearchBoxLineage from '../../components/Lineage/SearchBoxLineage.jsx';
import errorImage from '../../assets/images/NoDataset.png';
import { lineageIcons } from '../../components/Lineage/LineageIconStore.jsx';
import { getMetricDataQuality } from '../../actions/scheduleActions';
import { getColorLineage } from '../../helpers/appHelpers';
import AlertDialog from '../../components/AlertDialog/AlertDialog.jsx';


// import { graphDataMock } from './mockData';

const linkGen = d3.linkHorizontal();
const zoom = d3.zoom().scaleExtent([0.5, 3]);
const nodePaddingCorrection = appConstants.lineageModule.paddingConstants.nodePaddingCorrection;
const nodePaddingX = appConstants.lineageModule.paddingConstants.nodePaddingX;
const nodeItemPadding = appConstants.lineageModule.paddingConstants.nodeItemPadding;

const LineagePannel = ({ datasource_id }) => {
    const [graphData, setGraphData] = useState({
        nodes: [],
        links: [],
        firstLayer: []
    });
    const dispatch = useDispatch();
    const selector = useSelector((state) => state.lineage);
    const [getRef, setRef] = useDynamicRefs();
    const [nodeMapper, setNodeMapper] = useState({});
    const [lineagePathNodes, setLineagePathNodes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentActiveNodes, setCurrentActiveNodes] = useState([]);
    const [currentActiveNodesDetails, setCurrentActiveNodesDetails] = useState([]);
    const [selectedLineageNode, setCurrentLineageNode] = useState('');
    const [level_initial_position, set_level_initial_position] = useState({});
    const [searchSeletedNode, setSearchSelectedNode] = useState('');
    const [levelsDetails, setLevelsDetails] = useState([]);
    const [nodeIdMapper, setNodeIdMapper] = useState({});
    const [scoreMapper, setScoreMapper] = useState({});
    const [sourceCodeModel, setSourceCodeModel] = useState(false);
    const [sourceCode, setSourceCode] = useState('');
    const [dqScoreToggle, setDqScoreToggle] = useState(false);
    const [dqScore, setDqScore] = useState(0);
    const [dqScoreColor, setDqScoreColor] = useState('');
    const [currentNestedNodeId, setCurrentNestedNodeId] = useState('');
    const [sourcecodeToggleMapper, setSourcecodeToggleMapper] = useState({});

    useEffect(() => {
        return () => {
            dispatch(clearLineageResponse());
        };
    }, [dispatch]);

    const getDataQuality = useCallback((datasourceId) => {
        dispatch(getMetricDataQuality(datasourceId)).then((response) => {
            if (response) {
                const scoreMapperTemp = {};
                response = response.map((data) => {
                    return {
                        name: data.name,
                        attributes: data.attributes.sort((a, b) => {
                            return b.dqscore - a.dqscore;
                        }).map((attr) => {
                            scoreMapperTemp[`${data.name}.${attr.name}`] = attr.dqscore;
                            return attr;
                        })
                    };
                });
                setScoreMapper(scoreMapperTemp);
            }
        });
    }, [dispatch]);

    const handleZoom = useCallback((emptyTransform) => {
        /*
         * const svgGrp = ReactDOM.findDOMNode(getRef(`svgGroup`).current);
         * setTimeout(() => {
         */
        const svgGrp = document.getElementById('svgGroup');
        const transformObject = d3?.event?.transform;
        d3.select(svgGrp).attr('transform', transformObject);
        if (emptyTransform) {
            zoom?.transform(d3.select(getRef(`parentSvg`).current), d3.zoomIdentity.translate(0, 0).scale(1));
        }

    }, [getRef]);

    useEffect(() => {
        zoom.on('zoom', handleZoom);
    }, [graphData]);

    useEffect(() => {
        if (datasource_id) {
            setIsLoading(true);
            dispatch(getLineageGraph(datasource_id));
            getDataQuality(datasource_id);
            set_level_initial_position({
                x: getRef(`parentSvg`)?.current?.getBoundingClientRect().width / 6,
                y: getRef(`parentSvg`)?.current?.getBoundingClientRect().height / 6
            });
        }
    }, [datasource_id, dispatch, getDataQuality, getRef]);

    /*
     * const getSourceCode = (node) => {
     *  if (!node) { return; }
     *  const sourcecodeToggleMapperTemp = { ...sourcecodeToggleMapper };
     *  sourcecodeToggleMapperTemp[node?.id] = !sourcecodeToggleMapperTemp[node?.id];
     *  setSourcecodeToggleMapper(sourcecodeToggleMapperTemp);
     *  setTimeout(() => {
     *      const nodeHeight = getRef(`nodeContainer_${node?.id}`)?.current?.getBoundingClientRect()?.height;
     *      const levelsDetailsTemp = [...levelsDetails];
     *      levelsDetailsTemp[node?.layer].map((levelData) => {
     *          if (levelData?.id === node?.id) {
     *              levelData.height = nodeHeight;
     *          }
     *          return levelData;
     *      });
     *      positionNodes(levelsDetailsTemp, true);
     *  }, 100);
     *  dispatch(getSourceCodeLineage(fileName)).then((response) => {
     *      if (response?.status) {
     *          setSourceCode(response?.data);
     *          setSourceCodeModel(true);
     *      }
     *  });
     * };
     */

    const getNodeHeaderHeight = useCallback((nodeId) => getRef(`nodeHeader_${nodeId}`)?.current?.getBoundingClientRect()?.height || 0, [getRef]);

    const drawEdges = useCallback((nodes) => {
        const filteredLinks = graphData?.links.map((link) => {
            const sourceNode = _.find(nodes, { id: link.source });
            const targetNode = _.find(nodes, { id: link.target });

            if (sourceNode && targetNode) {

                const nodeContainerRef = (sourceNode?.rootNode) ? getRef(`nodeHeader_${sourceNode.id}`)?.current?.getBoundingClientRect() : getRef(`child_${sourceNode.id}`)?.current?.getBoundingClientRect();


                const xOffset = nodeContainerRef?.width || 0;
                const yOffsetSource = (sourceNode?.rootNode) ? (getNodeHeaderHeight(sourceNode.id) / 2) : 0;
                const yOffsetTarget = (targetNode?.rootNode) ? (getNodeHeaderHeight(targetNode.id) / 2) : 0;

                return { source: [sourceNode.x + xOffset, sourceNode.y + yOffsetSource], target: [targetNode.x, targetNode.y + yOffsetTarget], isEdge: link.isEdge, active: ((sourceNode?.rootNode && sourceNode?.status) || (!sourceNode?.rootNode && sourceNode?.isChild)) && ((targetNode?.rootNode && targetNode?.status) || (!targetNode?.rootNode && targetNode?.isChild)), sourceId: sourceNode.id, targetId: targetNode.id };
            }
            return undefined;


        }).filter((d) => d);

        d3.select(getRef(`svgGroup`).current)
            .selectAll("path")
            .data([...filteredLinks])
            .join("path")
            .attr("d", linkGen)
            .attr("fill", "none")
            .attr("stroke", "#cfcfcf")
            .attr("class", "link")
            .attr("marker-end", "url(#arrowhead)")
            .style('opacity', (event) => {
                return Number(event.active);
            })
            .style("stroke-linejoin", "round")
            .attr("id", (event) => `link_${event.sourceId}_${event.targetId}`);
        /*
         * Closing all the children initially
         * toggleNode(undefined, nodes.filter((node) => node.rootNode).map((node) => node.id));
         */
    }, [getNodeHeaderHeight, getRef, graphData?.links]);


    const caculateChildPosition = useCallback(() => {
        let activeTempNodes = [];
        const nodeIdMapperTemp = {};
        setTimeout(() => {
            const nodeBound = getRef(`parentSvg`).current?.getBoundingClientRect();
            graphData?.nodes.forEach((node) => {
                nodeIdMapperTemp[node.id] = node;
                activeTempNodes.push({ ...node, children: [] });
                node?.children?.forEach((child) => {
                    if (child) {
                        nodeIdMapperTemp[child.id] = child;
                        const childNodeBound = getRef(`child_${child.id}`)?.current?.getBoundingClientRect();
                        child.x = (childNodeBound?.x - nodeBound?.x);
                        child.y = (childNodeBound?.y - nodeBound?.y) + (childNodeBound?.height / 2);
                        child.isChild = true;
                        child.parentId = node?.id;
                    }
                });
                activeTempNodes = [...activeTempNodes, ...node?.children];
            });
            if (!Object.keys(nodeIdMapper).length) { setNodeIdMapper(nodeIdMapperTemp); }
            setCurrentActiveNodesDetails([...activeTempNodes.map((d) => { if (d.isChild) { d.status = true; } return d; })]);
            drawEdges(activeTempNodes);
        }, 100);
    }, [drawEdges, getRef, graphData?.nodes, nodeIdMapper, graphData?.firstLayer]);

    useEffect(() => {
        caculateChildPosition();
    }, [graphData, caculateChildPosition]);

    const getActiveEdges = useCallback((activeNodes) => {
        d3.selectAll(`.link`).style('opacity', 0);
        graphData?.links.forEach((link) => {
            const sourceNode = _.find(activeNodes, { id: link.source });
            const targetNode = _.find(activeNodes, { id: link.target });
            if (sourceNode && targetNode) {
                d3.select(`#link_${sourceNode.id}_${targetNode.id}`).style('opacity', 1);
            }
        });
    }, [graphData?.links]);


    const recalculateChildren = useCallback(() => {
        let activeNodes = [];
        graphData?.nodes?.forEach((node) => {
            if (!nodeMapper[node.id] || (['Sourcecode', 'View'].indexOf(node?.type) !== -1)) {
                activeNodes.push({ ...node, children: [] });
            } else {
                activeNodes = [...activeNodes, ...node.children];
            }
        });
        setCurrentActiveNodes([...activeNodes.map((node) => node.id)]);
        getActiveEdges(activeNodes);
    }, [getActiveEdges, graphData?.nodes, nodeMapper, graphData?.firstLayer]);

    useEffect(() => {
        recalculateChildren();
    }, [nodeMapper, recalculateChildren]);

    const positionNodes = useCallback((levels, noCalculateHeight = false, initialCall = false) => {
        handleZoom(true);
        levels.forEach((level, i) => {
            let currentx = level_initial_position?.x;
            let tempy = level_initial_position?.y;
            if (i) {
                currentx = level_initial_position?.x + (i * nodePaddingX);
                tempy = level_initial_position?.y;
            }
            level.forEach((node, i2, nodeArray) => {
                let currenty = tempy;
                const childrenNodeHeight = nodeArray[i2 - 1]?.children?.length || 5;
                const currentchildrenNodeHeight = nodeArray[i2]?.children?.length || 5;
                if (i2) {
                    tempy = nodeArray[i2 - 1]?.y || tempy;
                    const correction = nodePaddingCorrection / childrenNodeHeight;
                    const positionalNode = childrenNodeHeight * (nodeItemPadding + correction);
                    currenty = i2 ? (tempy + positionalNode) : tempy;
                }
                const currentNodeCorrection = nodePaddingCorrection / currentchildrenNodeHeight;
                node.x = currentx;
                node.y = currenty;
                node.height = currentchildrenNodeHeight * (nodeItemPadding + currentNodeCorrection);
            });
        });
        const tempLevels = levels.flat();
        const nodeMapperTemp = {};
        const sourcecodeToggleMapperTemp = {};
        tempLevels.forEach((nodeData) => {
            nodeData.rootNode = true;
            nodeData.status = (['Sourcecode', 'View'].indexOf(nodeData?.type) !== -1);
            nodeData.childrenStatus = true;
            nodeMapperTemp[nodeData.id] = true;
            if (nodeData.type === 'Sourcecode' && initialCall) {
                sourcecodeToggleMapperTemp[nodeData.id] = true;
            }
        });
        if (initialCall) { setSourcecodeToggleMapper(sourcecodeToggleMapperTemp); }
        setNodeMapper({ ...nodeMapperTemp });
        setGraphData((prevState) => ({ ...prevState, nodes: tempLevels }));
    }, [handleZoom, level_initial_position?.x, level_initial_position?.y, graphData?.firstLayer]);

    const initZoom = useCallback(() => {
        if (zoom) {
            d3.select(getRef(`parentSvg`).current).call(zoom).on('dblclick.zoom', null);
        }
    }, [getRef]);

    const groupNodeLayer = useCallback((nodes) => {
        const levels = [];
        nodes.forEach((node) => {
            if (!levels[node.layer]) { levels[node.layer] = []; }
            levels[node.layer].push(node);
        });
        setLevelsDetails(levels);
        positionNodes(levels, false, true);
    }, [positionNodes]);

    useEffect(() => {
        /*
         * if (selector?.type === lineageActions.GET_LINEAGE_GRPAH_SUCCESS) {
         * setTimeout(() => setIsLoading(false), 1000);
         * setGraphData({ links: selector?.graphData?.data?.links || [], nodes: [], inprogress: selector?.graphData?.inprogress, firstLayer: selector?.graphData?.data?.firstLayer });
         * setGraphData({ links: graphDataMock?.links || [], nodes: [], inprogress: graphDataMock?.inprogress, firstLayer: graphDataMock?.firstLayer });
         * groupNodeLayer(graphDataMock?.nodes || []);
         * initZoom();
         */
        /*
         * }
         * if (selector?.type === lineageActions.GET_LINEAGE_GRPAH_FAILURE) {
         *     setIsLoading(false);
         * }
         */
        if (selector?.type === lineageActions.GET_LINEAGE_GRPAH_SUCCESS) {
            setTimeout(() => setIsLoading(false), 1000);
            setGraphData({ links: selector?.graphData?.data?.links || [], nodes: [], inprogress: selector?.graphData?.inprogress, firstLayer: selector?.graphData?.data?.firstLayer });
            groupNodeLayer(selector?.graphData?.data?.nodes || []);
            initZoom();
        }
        if (selector?.type === lineageActions.GET_LINEAGE_GRPAH_FAILURE) {
            setIsLoading(false);
        }
    }, [selector, groupNodeLayer, initZoom]);

    /*
     * useEffect(() => {
     *     graphData?.nodes?.forEach((nodeElement, index) => {
     *         const parentheignt = getRef(`nodeContainer_${nodeElement.id}`)?.current?.getBoundingClientRect().height;
     *         // getRef(`nodeRootContainer_${nodeElement.id}`)?.current.setAttribute('height', parentheignt);
     *     });
     * }, [graphData, getRef]);
     */


    const changeLineageDirection = (direction) => {
        // return // !Need to enable when column level lineage is done
        displayLineageEdges(undefined, direction);
    };


    let currentParentId = '';
    const nestedNodeLocationRelocator = useCallback((nodeid, nodeArray) => {
        for (let i = 0; i < nodeArray.length; i++) {
            if (nodeArray[i].id === nodeid) {
                currentParentId = nodeArray[i].schema_id;
                setCurrentNestedNodeId(nodeArray[i].schema_id);
                const tempNode = { ...nodeArray[i] };
                nodeArray.splice(i, 1);
                nodeArray.unshift(tempNode);
                break;
                // return
            }
            if (nodeArray[i].children && nodeArray[i].children.length) {
                nestedNodeLocationRelocator(nodeid, nodeArray[i].children);
                if (currentParentId === nodeArray[i].id) {
                    currentParentId = nodeArray[i].schema_id;
                    setCurrentNestedNodeId(nodeArray[i].schema_id);
                    const tempNode = { ...nodeArray[i] };
                    nodeArray.splice(i, 1);
                    nodeArray.unshift(tempNode);
                    return;
                }
            }
        }

    }, [currentNestedNodeId]);

    const relocateNodes = useCallback((nodesToRelocate) => {
        setCurrentNestedNodeId('');
        const levelsTemp = [...levelsDetails];
        nodesToRelocate.forEach((node) => {
            if (node.layer === 0) {
                nestedNodeLocationRelocator(node.id, levelsTemp[node.layer]);
            } else {
                levelsTemp[node.layer]?.some((layerNode, index) => {
                    if (layerNode.id === node.id) {
                        levelsTemp[node.layer].splice(index, 1);
                        levelsTemp[node.layer].unshift(layerNode);
                        return true;
                    }
                    return false;
                });
            }

        });
        positionNodes(levelsTemp);
    }, [levelsDetails, positionNodes, nestedNodeLocationRelocator]);

    /**
     * Display lineage edges
     * Direction available  - type : ( F - forward, B - backward, BO - both )
     */
    const displayLineageEdges = useCallback((id, type = appConstants.lineageModule.BO, callRelocation) => {
        if (!id && !selectedLineageNode) { return; }
        let currentId = '';
        let dqScoreColorTemp = '';
        if (id) {
            currentId = id;
            setCurrentLineageNode(id);
        } else {
            currentId = selectedLineageNode;
        }

        let iteration = (type === appConstants.lineageModule.BO) ? 2 : 1;
        let itr = 0;

        const failSafeCount = 1000;
        const edgesTemp = [...graphData.links.map((edge) => ({ ...edge, isEdge: false }))];
        while (iteration) {
            let array = [currentId];
            const edgeKey = {
                [appConstants.lineageModule.B]: 'target',
                [appConstants.lineageModule.F]: 'source',
                [appConstants.lineageModule.BO]: (iteration === 2) ? 'source' : 'target'
            }[type];
            while (array.length && itr < failSafeCount) {
                itr++;
                const tempArray = [...array];
                // eslint-disable-next-line no-loop-func
                tempArray.forEach((selected_id) => {
                    const sourceFiltered = [];
                    edgesTemp.forEach((edge) => {
                        if (edge[edgeKey] === selected_id && currentActiveNodes.indexOf(edge.source) !== -1 && currentActiveNodes.indexOf(edge.target) !== -1) {
                            edge.isEdge = true;
                            sourceFiltered.push(edge);
                        }
                    });
                    const sourceArray = sourceFiltered.map((edge) => edge[edgeKey === 'source' ? 'target' : 'source']);
                    array = [...sourceArray];
                });
            }
            iteration--;
        }

        // Activate edges
        d3.selectAll(`.link`).attr("stroke", "#cfcfcf");
        let lineagePathNodes = [];
        const nodesToRelocate = {};
        setTimeout(() => {
            edgesTemp.filter((edge) => edge.isEdge).forEach((edge) => {
                if (scoreMapper[`${nodeIdMapper[nodeIdMapper[edge.source].parentId]?.name}.${nodeIdMapper[edge.source]?.name}`] && !dqScoreColorTemp && callRelocation) {
                    const score = scoreMapper[`${nodeIdMapper[nodeIdMapper[edge.source].parentId]?.name}.${nodeIdMapper[edge.source]?.name}`];
                    dqScoreColorTemp = getColorLineage(score);
                    setDqScoreColor(dqScoreColorTemp);
                    setDqScore(score);
                }
                const keyTempSource = (nodeIdMapper[edge.source]?.type === 'Sourcecode') ? edge.source : nodeIdMapper[edge.source].parentId;
                const keyTempTarget = (nodeIdMapper[edge.target]?.type === 'Sourcecode') ? edge.target : nodeIdMapper[edge.target].parentId;

                if (nodeIdMapper[edge.source]?.type === 'Sourcecode') {
                    nodesToRelocate[edge.target] = { ...nodeIdMapper[edge.target], dqScoreColor };
                }

                nodesToRelocate[keyTempSource] = { ...nodeIdMapper[keyTempSource], dqScoreColor };
                nodesToRelocate[keyTempTarget] = { ...nodeIdMapper[keyTempTarget], dqScoreColor };
                lineagePathNodes = [...lineagePathNodes, edge.source, edge.target];
                d3.select(`#link_${edge.source}_${edge.target}`).attr("stroke", "#000000");
            });
            setLineagePathNodes(lineagePathNodes);
            if (callRelocation) {
                relocateNodes([...Object.values(nodesToRelocate)]);
            }
        }, 100);

    }, [currentActiveNodes, graphData.links, nodeIdMapper, relocateNodes, selectedLineageNode, scoreMapper, dqScoreColor]);

    useEffect(() => {
        displayLineageEdges();
    }, [currentActiveNodes, displayLineageEdges]);

    function parseTransformAttribute(a) {
        if (!a) {
            return {
                x: 0, y: 0, k: 1
            };
        }
        const b = {};
        for (const i in a = a.match(/(\w+\((-?\d+\.?\d*e?-?\d*,?)+\))+/g)) {
            const c = a[i].match(/[\w.-]+/g);
            b[c.shift()] = c;
        }
        return {
            x: isNaN(Number(b?.translate[0])) ? 0 : Number(b?.translate[0]),
            y: isNaN(Number(b?.translate[1])) ? 0 : Number(b?.translate[1]),
            k: isNaN(Number(b?.scale[0])) ? 1 : Number(b?.scale[0])
        };
    }

    const focusNodeAfterSearch = (selectedNode) => {
        setSearchSelectedNode(selectedNode?.id);
        const selectedId = (selectedNode?.rootNode) ? selectedNode?.id : selectedNode?.parentId;
        if (!selectedId) { return; }
        /*
         * const parentRef = getRef(`svgGroup`)?.current;
         * const parentContainerRef = getRef(`parentSvg`)?.current;
         */
        const parentRef = document.getElementById('svgGroup');
        const parentContainerRef = document.getElementById('parentSvg');
        const svgBound = parentRef?.getBoundingClientRect();
        // const nodeRootContainerDynamic = getRef(`nodeRootContainer_${selectedId}`)?.current;
        const nodeRootContainerDynamic = document.getElementById(`nodeRootContainer_${selectedId}`);
        const nodeBound = nodeRootContainerDynamic?.getBoundingClientRect();

        const parsedTransformation = parseTransformAttribute(d3.select(nodeRootContainerDynamic)?.attr('transform')?.split(' ')?.join(','));

        const xOffset = (((level_initial_position?.x) + (parsedTransformation?.x)) - (nodeBound?.x - svgBound?.x));
        const yOffset = ((level_initial_position?.y + (parsedTransformation?.y)) - (nodeBound?.y - svgBound?.y));

        d3.select(parentContainerRef).transition().duration(500).call(zoom?.transform, d3.zoomIdentity.translate(xOffset, yOffset).scale(parsedTransformation?.k));
    };

    const clearSelectedSearch = () => {
        setSearchSelectedNode('');
    };

    return (
        <React.Fragment>
            {isLoading && <Loader />}
            {
                (graphData?.inprogress && !isLoading) &&
                <Grid container alignItems="center" justify="center" style={LineageStyle().noResultFound}>
                    <Grid>
                        <Typography variant="h4" align="center">
                            {'Computing lineage inprogress'}
                        </Typography>
                    </Grid>
                </Grid>
            }
            {
                (graphData?.nodes?.length === 0 && !graphData?.inprogress && !isLoading) &&
                <Grid container alignItems="center" justify="center" style={LineageStyle().noResultFound}>
                    <Grid>
                        <img src={errorImage} alt="No Result Found" />
                        <Typography variant="h4" align="center">
                            {'No Result Found'}
                        </Typography>
                    </Grid>
                </Grid>
            }
            {
                <React.Fragment>
                    <Box style={LineageStyle().filterBoxContainer}>
                        <Box style={{ flexGrow: 1 }}>
                            <SearchBoxLineage
                                onSearchNode={focusNodeAfterSearch}
                                nodes={currentActiveNodesDetails}
                            />
                        </Box>
                        <Box>
                            <FormControlLabel
                                control={
                                    <Switch checked={dqScoreToggle}
                                        color="secondary"
                                        name="is_null"
                                        onChange={(event) => setDqScoreToggle(event.target.checked)} />
                                }
                                label="DQ Score"
                            />
                        </Box>
                        <Box>
                            <BackForwardLineageButtons
                                onDirectionChange={changeLineageDirection}
                            />
                        </Box>
                    </Box>
                    <svg
                        style={LineageStyle().svgStyle}
                        ref={setRef('parentSvg')}
                        id="parentSvg"
                        onClick={clearSelectedSearch}
                    >
                        <marker id="arrowhead"
                            markerWidth="10"
                            markerHeight="7"
                            refX="0"
                            refY="3.5"
                            orient="auto">
                            <polygon points="0 0, 10 3.5, 0 7" />
                        </marker>
                        <g id="svgGroup" className="svgGroup" ref={setRef('svgGroup')}>
                            {
                                graphData?.nodes?.map((nodeElement, index) => {
                                    const style = LineageStyle(nodeElement.type);
                                    const nodeSearchSelected = searchSeletedNode === nodeElement?.id;
                                    return (
                                        <foreignObject key={index} x={nodeElement.x} y={nodeElement.y} width="50%" height={nodeElement.height} className="foreignObjectContainer" id={`nodeRootContainer_${nodeElement.id}`} ref={setRef(`nodeRootContainer_${nodeElement.id}`)}>
                                            <div style={style.lineageBox(nodeSearchSelected, '', sourcecodeToggleMapper[nodeElement.id])} ref={setRef(`nodeContainer_${nodeElement.id}`)}>
                                                <div style={style.lineageBoxHeading(nodeSearchSelected)}
                                                    className="lineage-box-heading"
                                                    onClick={
                                                        () => {
                                                            if (!nodeElement?.sourceCode) { return; }
                                                            setSourceCode(nodeElement?.sourceCode);
                                                            setSourceCodeModel(true);
                                                        }
                                                    }
                                                    // onClick={() => getSourceCode(nodeElement)}
                                                    ref={setRef(`nodeHeader_${nodeElement.id}`)}
                                                >
                                                    <Grid style={style.headerContainer}>
                                                        <img src={lineageIcons[`${nodeElement?.type}${nodeSearchSelected ? 'Black' : 'White'}`]} alt={nodeElement?.type} style={style.headerImage} />
                                                        <Typography style={style.headerText}>
                                                            {nodeElement?.name}
                                                        </Typography>
                                                    </Grid>
                                                </div>
                                                {
                                                    nodeMapper[nodeElement.id] && !nodeElement?.codeBody && (
                                                        <ul style={style.lineageList}>
                                                            {
                                                                nodeElement?.children?.map((childElement, ChildIndex) => {
                                                                    let styleParam = 0;
                                                                    const dqscore = dqScore?.toFixed(2) || 0;
                                                                    const listItemColor = dqScoreColor;
                                                                    const isSelectedNode = (lineagePathNodes.indexOf(childElement.id) !== -1);
                                                                    if (childElement?.id === searchSeletedNode) { styleParam = 2; }
                                                                    if (isSelectedNode && !dqScoreToggle) { styleParam = 1; }
                                                                    if (isSelectedNode && dqScoreToggle) { styleParam = 3; }
                                                                    return (
                                                                        <li key={ChildIndex} style={(style.lineageListItem(styleParam, listItemColor))} onClick={() => displayLineageEdges(childElement.id, undefined, true)} ref={setRef(`child_${childElement.id}`)}>
                                                                            <Tooltip title={dqScoreToggle && (isSelectedNode) ? `DQScore : ${dqscore}` : ''}>
                                                                                <Typography>
                                                                                    {childElement?.name}
                                                                                </Typography>
                                                                            </Tooltip>
                                                                        </li>
                                                                    );
                                                                })
                                                            }
                                                        </ul>
                                                    )
                                                }
                                                {
                                                    nodeMapper[nodeElement.id] && nodeElement?.codeBody && sourcecodeToggleMapper[nodeElement.id] && (
                                                        <div style={{ ...style.lineageSourcecodeBody, whiteSpace: 'pre-wrap', cursor: 'pointer' }}
                                                            onClick={
                                                                () => {
                                                                    setSourceCode(nodeElement?.codeBody);
                                                                    setSourceCodeModel(true);
                                                                }
                                                            }
                                                        >
                                                            {nodeElement?.codeBody?.slice(0, 100)}
                                                            ...
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </foreignObject>
                                    );
                                })
                            }
                        </g>
                    </svg>
                </React.Fragment>
            }
            <AlertDialog title={"Source Code"}
                message={
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                        {sourceCode}
                    </div>
                }
                okButtonText="OK"
                show={sourceCodeModel}
                onClickOK={() => setSourceCodeModel(false)} />
        </React.Fragment>
    );
};

LineagePannel.propTypes = {
    datasource_id: PropTypes.string
};

export default LineagePannel;