import React from 'react';
import { Grid, withStyles } from '@material-ui/core';
import SkeletonLoaderStyles from './SkeletonLoaderStyles.jsx';
import PropTypes from 'prop-types';
import Skeleton from '@material-ui/lab/Skeleton';

const SkeletonLoader = (props) => {
    const { classes } = props;
    return (
        <Grid>
            <Grid className={classes.skeletonContainer}>
                <Skeleton animation="wave" variant="rect" width={120} height={120} />
                <Grid className={classes.textContent}>
                    <Skeleton animation="wave" variant="text" className={classes.skeletonText} />
                    <Skeleton animation="wave" variant="text" className={classes.skeletonText} />
                    <Skeleton animation="wave" variant="text" className={classes.skeletonText} />
                    <Skeleton animation="wave" variant="text" width="65%" className={classes.skeletonText} />
                </Grid>
            </Grid>
            <Grid className={classes.skeletonContainer}>
                <Skeleton animation="wave" variant="rect" width={120} height={120} />
                <Grid className={classes.textContent}>
                    <Skeleton animation="wave" variant="text" className={classes.skeletonText} />
                    <Skeleton animation="wave" variant="text" className={classes.skeletonText} />
                    <Skeleton animation="wave" variant="text" className={classes.skeletonText} />
                    <Skeleton animation="wave" variant="text" width="65%" className={classes.skeletonText} />
                </Grid>
            </Grid>
            <Grid className={classes.skeletonContainer}>
                <Skeleton animation="wave" variant="rect" width={120} height={120} />
                <Grid className={classes.textContent}>
                    <Skeleton animation="wave" variant="text" className={classes.skeletonText} />
                    <Skeleton animation="wave" variant="text" className={classes.skeletonText} />
                    <Skeleton animation="wave" variant="text" className={classes.skeletonText} />
                    <Skeleton animation="wave" variant="text" width="65%" className={classes.skeletonText} />
                </Grid>
            </Grid>
            <Grid className={classes.skeletonContainer}>
                <Skeleton animation="wave" variant="rect" width={120} height={120} />
                <Grid className={classes.textContent}>
                    <Skeleton animation="wave" variant="text" className={classes.skeletonText} />
                    <Skeleton animation="wave" variant="text" className={classes.skeletonText} />
                    <Skeleton animation="wave" variant="text" className={classes.skeletonText} />
                    <Skeleton animation="wave" variant="text" width="65%" className={classes.skeletonText} />
                </Grid>
            </Grid>
        </Grid>
    );
};


SkeletonLoader.propTypes = {
    classes: PropTypes.object
};


export default withStyles((theme) => ({
    ...SkeletonLoaderStyles(theme)
}), { withTheme: true })(SkeletonLoader);