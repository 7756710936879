import { commonActions } from '../constants/actionTypes/commonActionTypes';

const initialState = {
	datePickerToggle: false
};

export const commonReducer = (state = initialState, action) => {
	if (!action || (action && !action.type)) {
		return state;
	}

	switch (action.type) {
		case commonActions.DATE_PICKER_TOGGLE:
			return {
				...state,
				datePickerToggle: action.payload
			};
		default:
			return state;
	}
};