const DashboardStyles = (theme) => ({
    root: {
        height: '100%!important'
    },
    pl15: {
        paddingLeft: 15
    },
    alignCenter: {
        alignItems: 'center'
    },
    section: {
        boxShadow: '0px 0px 6px #e1e5e6',
        backgroundColor: theme.palette.background.paper,
        padding: '15px'
    },
    governanceList: {
        paddingLeft: 0,
        '& .MuiTypography-body1': {
            fontSize: 14
        },
        '& .MuiListItemText-root': {
            paddingLeft: 10
        },
        '& .MuiAvatar-root': {
            height: 40,
            width: 40,
            backgroundColor: theme.palette.background.container
        }
    },
    governanceSummaryTxt: {
        fontSize: 16,
        fontFamily: theme.palette.titleFont,
        marginLeft: 20
    },
    governanceActionText: {
        color: theme.palette.MetricsTiles.tileThree
    },
    governanceConversationText: {
        color: theme.palette.MetricsTiles.tileFour
    },
    governanceissueText: {
        color: theme.palette.MetricsTiles.tileOne
    },
    actionContainer: {
        marginBottom: 6,
        paddingBottom: 4,
        borderBottom: `1px solid ${theme.palette.border.normal}`
    },
    actionProperties: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    actionPropertyTxt: {
        display: 'inline-flex',
        color: theme.palette.grey.main
    },
    cardTile: {
        padding: '15px 15px',
        boxShadow: '0px 0px 6px #e1e5e6',
        backgroundColor: theme.palette.background.paper,
        '& .recharts-tooltip-wrapper': {
            zIndex: 99
        }
    },
    cardTitle: {
        fontSize: 30,
        fontFamily: theme.palette.titleFont
    },
    cardTxt: {
        fontSize: 13,
        color: theme.palette.grey.main
    },
    viewListData: {
        paddingLeft: 10,
        maxWidth: '100%',
        overflow: 'hidden'
    },
    datasourcecontainer: {
        backgroundColor: '#F5F5F5'
    },
    dataContainer: {
        height: '120px',
        overflowY: 'auto'
    },
    datasetcontainer: {
        width: '100%',
        height: '100%',
        backgroundColor: '#ECECEC'
    },
    viewList: {
        marginBottom: 10,
        paddingBottom: 8,
        borderBottom: `1px solid ${theme.palette.border.normal}`,
        '& .MuiTypography-body1': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '100%'
        }
    },
    viewListnoborder: {
        marginBottom: 10,
        paddingBottom: 8,
        '& .MuiTypography-body1': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: '100%'
        }
    },
    wedgetHeaders: {
        fontSize: 18,
        fontWeight: 500,
        letterSpacing: '0.00735em'
    },
    viewListDateTxt: {
        fontStyle: 'italic',
        color: theme.palette.grey.dark
    },
    viewTitleTxt: {
        margin: '15px 0px',
        fontSize: 14
    },
    engagedContainer: {
        display: 'flex',
        marginTop: 20,
        flexWrap: 'wrap'
    },
    avatarContainer: {
        display: 'inline-flex',
        marginRight: 10,
        marginBottom: 10
    },
    avatarUserTxt: {
        color: theme.palette.grey.default,
        paddingLeft: 5
    },
    tabContainer: {
        marginTop: 15,
        '& .MuiTab-root': {
            minWidth: 90,
            fontSize: 13,
            padding: '6px'
        }
    },
    linearListContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10
    },
    linearText: {
        width: 130,
        marginRight: 15,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    linearSection: {
        width: '100%'
    },
    analysisContainer: {
        marginTop: 15
    },
    relationHeaderSection: {
        display: 'flex',
        alignItems: 'center'
    },
    relationshipTypeSection: {
        backgroundColor: theme.palette.background.container,
        height: 30,
        marginLeft: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 30
    },
    relationshipUserSection: {
        backgroundColor: theme.palette.background.container,
        width: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& .MuiTypography-body1': {
            transform: 'rotate(-90deg)'
        }
    },
    relationChart: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '95%'
    },
    relationChartSection: {
        width: '100%'
    },
    mostviewContainer: {
        height: '100%'
    },
    container: {
        height: '100%'
    },
    governanceListContainer: {
        padding: 3
    },
    actionTitleTxt: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer'
    },
    domainCircle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // width: '20%',
        marginTop: 10
    },
    tileAvatar: {
        height: 60,
        width: 60,
        backgroundColor: theme.palette.background.container
    },
    profileTxt: {
        fontSize: 35
    },
    overAllContainer: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    curateScore: {
        marginTop: 10,
        display: 'flex',
        alignItems: 'center'
    },
    curateIcon: {
        width: 50,
        height: 50
    },
    curateTxt: {
        fontSize: 18
    },
    overAllSection: {
        padding: 20,
        marginTop: 20
    },
    domainTitleTxt: {
        fontWeight: 500
    },
    fontWeight600: {
        fontWeight: 600
    },
    tooltipWidth: {
        width: 120
    },
    iconPadding: {
        padding: "4px 6px"
    },
    displayFlex: {
        display: 'Flex',
        flexDirection: 'row'
    },
    addIcon: {
        fontSize: 19,
        color: theme.palette.background.paper
    },
    addIconButton: {
        padding: 5,
        marginRight: 5,
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: `${theme.palette.primary.dark} !important`
        }
    },
    dashboardWidgetContainer: {
        maxHeight: ' calc(100vh - 110px)',
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    dashboardComponentContainer: {
        marginBottom: 20,
        padding: 18,
        borderRadius: 0,
        overflow: "hidden",
        border: '1px solid rgba(26,54,126,0.125)',
        background: theme.palette.background.paper,
        '&:last-child': {
            marginBottom: "none"
        },
        '& .MuiCardContent-root:last-child': {
            paddingBottom: '0px'
        }
    },
    iconLight: {
        fill: theme.palette.grey.light
    },
    iconDark: {
        fill: theme.palette.grey.dark
    },
    widgetContainer: {
        width: '100%',
        height: '100%'
    },
    widgetData: {
        height: '100%'
    },
    widgtDataContainer: {
        width: '100%',
        padding: 8,
        height: '88%',
        overflowY: 'auto'
    },
    totalCountContainer: {
        width: '100%',
        textAlign: 'center'
    },
    chartLoaderArea: {
        height: '93%'
    },
    domainNametxt: {
        width: 150,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'

    },
    domainCircleScore: {
        position: "absolute",
        top: "50%",
        right: 0,
        left: 0,
        transform: "translateY(-35%)"
    },
    formLabel: {
        '& .MuiTypography-body1': {
            fontSize: 13,
            color: '#222'
        }
    },
    toogleText: {
        paddingRight: '10px'
    },
    bottomToggle: {
        marginLeft: '-16px',
        marginRight: '-16px',
        overflowX: 'hidden',
        '& .MuiFormControlLabel-root': {
            marginLeft: 0
        }
    },
    avatarLetter: {
        textTransform: 'uppercase'
    },
    widgetTitle: {
        textTransform: 'capitalize'
    },
    dashboardFilterContainer: {
        position: 'absolute',
        width: '350px',
        backgroundColor: 'white',
        zIndex: '10',
        padding: '10px',
        top: '164px',
        right: '10px'
    },
    widgetToggle: {
        '& .MuiFormControlLabel-root': {
            marginRight: 0,
            marginLeft: 0
        }
    }
});

export default DashboardStyles;