import React, { useState, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, IconButton, Slider, ButtonGroup, Button, MenuItem, FormLabel, ClickAwayListener } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import AddIcon from '@material-ui/icons/Add';
import DatasourceListStyles from './DatasourceListStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import { appConstants } from '../../constants/appConstants';
import Search from '../TextBox/Search.jsx';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import TextBox from '../TextBox/TextBox.jsx';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import UserAutoComplete from '../UserAutoComplete/UserAutoComplete.jsx';
import CheckboxComponent from '../ChecboxComponent/CheckboxComponent.jsx';
import { setFilters, getCatalogListData } from '../../actions/datasourceActions';
import { addusersearch, addfilterusersearch } from '../../actions/searchActions';

import { sortTable } from '../../helpers/appHelpers.js';
import Lottie from 'react-lottie';
import animationData from '../../assets/json/heartanim.json';
import Verified from '../../assets/images/verified.svg';
import InProgess from '../../assets/images/wip.svg';
import Deprecated from '../../assets/images/deprecated.svg';
import Issues from '../../assets/images/issues.svg';

const DatasourceFilter = (props) => {
    const { classes, history, config, theme, sortBy } = props;
    const fetchLimit = 25;
    const datasources = useSelector(({ catalog }) => catalog.datasources);
    const datasets = useSelector(({ catalog }) => catalog.datasets);
    const attributes = useSelector(({ catalog }) => catalog.attributes);
    const domains = useSelector(({ catalog }) => catalog.domains);
    const controllerRef = useRef();

    const settingConfig = useSelector(({ setting }) => setting.config);
    const licenseConfig = useSelector(({ setting }) => setting.licenseConfig);
    const catalogFilters = useSelector(({ catalog }) => catalog.filters);
    let datasourceTypesList = useSelector(({ datasource }) => datasource.connectionTypes);
    datasourceTypesList = settingConfig && settingConfig.input_connectors ? datasourceTypesList.filter((data) => settingConfig.input_connectors.includes(data.id)) : [];
    const users = useSelector(({ account }) => account.users);
    const userRole = useSelector(({ account }) => account.user.role);
    const [filters, setCatalogFilters] = useState({
        viewType: appConstants.filterTypes[0],
        search: '',
        connectionType: [],
        sensitivity: [],
        users: [
            {
                isDefault: true,
                isSelected: true,
                isUser: false,
                name: 'All'
            }
        ],
        dqScore: [0, 100],
        favourite: false,
        primary_key: false,
        status: ''
    });
    const [usersList, setUsersList] = useState([
        {
            isDefault: true,
            isSelected: true,
            isUser: false,
            name: 'All'
        }
    ]);
    const [isFiltersLoaded, setIsFiltersLoaded] = useState(false);
    const [siderValue, setsiderValue] = useState(100);
    const [enableFilter, setFilter] = useState(false);
    const [userInput, setUserInput] = useState('');
    const dispatch = useDispatch();
    const isLicensedValidated = useSelector(({ datasource }) => datasource.isLicensedValidated);


    const getCatalogList = useCallback((isFilterChanged = false) => {
        const searchConfig = {
            ...filters,
            limit: fetchLimit,
            sortBy
        };
        if (filters.viewType === appConstants.filterTypes[0]) {
            searchConfig.offset = !isFilterChanged ? datasources.length : 0;
        } else if (filters.viewType === appConstants.filterTypes[1]) {
            searchConfig.offset = !isFilterChanged ? datasets.length : 0;
        } else if (filters.viewType === appConstants.filterTypes[2]) {
            searchConfig.offset = !isFilterChanged ? attributes.length : 0;
        } else if (filters.viewType === appConstants.filterTypes[3]) {
            searchConfig.offset = !isFilterChanged ? domains.length : 0;
        }
        const resetData = (searchConfig.offset === 0);

        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;
        dispatch(getCatalogListData(searchConfig, resetData, { signal: controllerRef.current?.signal }));
    }, [attributes.length, datasets.length, datasources.length, dispatch, domains.length, filters, sortBy]);

    useEffect(() => {
        if (config) {
            return;
        }
        getCatalogList();
    }, [config, getCatalogList]);

    useEffect(() => {
        if (!isFiltersLoaded) {
            setCatalogFilters({ ...catalogFilters });
            setIsFiltersLoaded(true);
        }
    }, [catalogFilters, getCatalogList, isFiltersLoaded]);

    useEffect(() => {
        if (usersList.length === 1 && users.length > 0) {
            const organizationUsers = users.map((user) => {
                return {
                    ...user,
                    isSelected: false,
                    name: user.first_name !== "" ? `${user.first_name} ${user.last_name}` : user.email,
                    isDefault: false,
                    isUser: true
                };
            });
            organizationUsers.unshift({
                isDefault: true,
                isSelected: true,
                isUser: false,
                name: 'All'
            });
            setUsersList([...sortTable(organizationUsers, "asc", "username")]);

        }
    }, [users, usersList]);

    const getThresholdText = (value) => {
        return value ? `${value}%` : '0%';
    };

    const resetFilter = useCallback((index) => {
        const filters = {
            viewType: appConstants.filterTypes[0],
            search: '',
            connectionType: [],
            sensitivity: [],
            users: [
                {
                    isDefault: true,
                    isSelected: true,
                    isUser: false,
                    name: 'All'
                }
            ],
            dqScore: [0, 100],
            favourite: false,
            status: '',
            primary_key: false
        };
        for (const user of usersList) {
            user.isSelected = user.isDefault;
        }
        setUsersList([...sortTable(usersList, "asc", "username")]);
        setUserInput('');
        const searchConfig = {
            ...filters,
            limit: fetchLimit
        };
        const resetData = true;
        setCatalogFilters({ ...filters });
        dispatch(getCatalogListData(searchConfig, resetData, { signal: controllerRef.current?.signal }));
    }, [dispatch, usersList]);

    const onSliderChange = useCallback((property, value) => {
        let diff = value[0];
        if (filters.dqScore.includes(value[0])) {
            diff = value[1];
        }
        filters[property] = value;
        setsiderValue(diff);
        setCatalogFilters({ ...filters });
    }, [filters]);

    const diff = useCallback((obj1, obj2) => {
        const result = {};
        if (Object.is(obj1, obj2)) {
            return undefined;
        }
        if (!obj2 || typeof obj2 !== 'object') {
            return obj2;
        }
        Object.keys(obj1 || {}).concat(Object.keys(obj2 || {})).forEach(key => {
            if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
                result[key] = obj2[key];
            }
            if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
                const value = diff(obj1[key], obj2[key]);
                if (value !== undefined) {
                    result[key] = value;
                }
            }
        });
        return result;
    }, []);

    const keyPress = useCallback(() => {
        dispatch(addusersearch({ 'search': filters.search, 'page': 'catalog' }));
    }, [dispatch, filters.search]);

    const onChangeFilter = useCallback((property, value) => {
        const old_filter = { ...filters };
        const defaultUsers = [
            {
                isDefault: true,
                isSelected: true,
                isUser: false,
                name: 'All'
            }
        ];
        if (property === "users") {
            if (value === 'All') {
                filters[property] = defaultUsers;
            } else {
                const user = usersList.find((p) => p.name === value);
                const index = filters.users.findIndex((p) => p.id === user.id);
                if (index > -1) {
                    dispatch(addfilterusersearch({ "filter": { "user": user.email }, "page": "catalog", "appliedType": "removed" }));
                    filters.users.splice(index, 1);
                } else {
                    dispatch(addfilterusersearch({ "filter": { "user": user.email }, "page": "catalog", "appliedType": "applied" }));
                    filters.users.push({ ...user });
                }

                if (filters.users.length === 0) {
                    filters.users = defaultUsers;
                } else {
                    const defaultUserIndex = filters.users.findIndex((p) => p.name === 'All');
                    if (defaultUserIndex > -1) {
                        filters.users.splice(defaultUserIndex, 1);
                    }
                }
                const userItem = usersList.find((p) => p.name === value);
                const userItemIndex = usersList.findIndex((p) => p.id === user.id);
                userItem.isSelected = !userItem.isSelected;
                usersList.splice(userItemIndex, 1, { ...userItem });
                setUsersList(sortTable(usersList, "asc", "username"));
            }
        } else if (property === "sensitivity") {
            const index = filters[property].indexOf(value);
            if (index !== -1) {
                filters[property].splice(index, 1);
                dispatch(addfilterusersearch({ "filter": { "sensitivity": value }, "page": "catalog" , "appliedType": "removed" }));
            } else {
                filters[property].push(value);
                dispatch(addfilterusersearch({ "filter": { "sensitivity": value }, "page": "catalog", "appliedType": "applied" }));
            }
        } else if (property === "primary_key" || (property === "status" && filters[property] !== '')) {
            filters[property] = value;
        } else {
            if (property === "favourite") {
                filters.users = defaultUsers;
            }
            filters[property] = value;
        }
        const difference = diff(old_filter, { ...filters });
        setCatalogFilters({ ...filters });
        if (property === "connectionType" || property === "dqScore") {
            if (property === "connectionType") {
                let differance_value = old_filter.connectionType.filter((x) => filters.connectionType.indexOf(x) === -1);
                let appliedvalue = "removed";
                if (differance_value.length === 0) {
                    differance_value = filters.connectionType.filter((x) => old_filter.connectionType.indexOf(x) === -1);
                    appliedvalue = "applied";
                }
                if (differance_value.length > 0) {
                    dispatch(addfilterusersearch({ "filter": { "connectionType": differance_value[0] }, "page": "catalog", "appliedType": appliedvalue }));
                }
            } else {
                dispatch(addfilterusersearch({ "filter": { "dqScore": siderValue }, "page": "catalog" }));
            }
        }
        else {
            if (property !== 'search' && property !== 'viewType' && property !== 'sensitivity' && property !== 'dqScore') {
                if (Object.keys(difference).length > 0) {
                    dispatch(addfilterusersearch({ "filter": difference, "page": "catalog" }));
                }
            }
        }
        setUserInput('');
        dispatch(setFilters({ ...filters }));
        setTimeout(() => getCatalogList(true), 100);
    }, [diff, dispatch, filters, getCatalogList, siderValue, usersList]);

    const onChangeUser = (event) => {
        onChangeFilter("users", event);
    };

    const onInputValueOnChange = (value) => {
        setUserInput(value);
    };

    const removeItem = useCallback((index) => {
        usersList.splice(index, 1);
        setUsersList([...sortTable(usersList, "asc", "username")]);
        setTimeout(() => {
            setUserInput('');
        }, 100);
    }, [usersList]);

    const tooltipContent = () => {
        let content = "";
        if (filters.connectionType.length > 0) {
            filters.connectionType.forEach((value, index) => {
                content += filters.connectionType.length !== index + 1 ? value + ", " : value;
            });
        }
        return content;
    };

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const renderStatusIcon = (status) => {
        if (status.toLowerCase() === "verified") {
            return Verified;
        } else if (status.toLowerCase() === "work in progress") {
            return InProgess;
        } else if (status.toLowerCase() === "issues") {
            return Issues;
        } else if (status.toLowerCase() === "deprecated") {
            return Deprecated;
        }
        return '';
    };

    return (
        <Grid container alignItems="center" justify="space-between" className={classNames(classes.headerContainer, classes.catHeaderContainer)}>
            <Grid item>
                <Typography variant="h2" component="h2">
                    Catalog
                </Typography>
            </Grid>
            <Grid item className={classes.filterSection}>
                {
                    <ValidatorForm onSubmit={() => null} style={{ display: 'flex', alignItems: 'center' }}>
                        {
                            config.catalog_search &&
                            <Grid style={{ marginRight: 7 }}>
                                <Search
                                    value={filters.search}
                                    placeholder="Search Here"
                                    className={classes.dqSearchBox}
                                    onChange={(value) => onChangeFilter('search', value)}
                                    onBlur={() => keyPress()}
                                />
                            </Grid>
                        }
                        {
                            enableFilter &&
                            <Grid className={classNames(classes.FilterHideShowContainer)}>
                                <ClickAwayListener onClickAway={() => setFilter(false)} mouseEvent={'onMouseUp'} touchEvent={'onTouchEnd'}>
                                    <Grid container alignItems="center" justify="space-between" className={classNames(classes.datasourceFilterSection, "datasourceFilterSection")}>
                                        <TooltipComponent title="Close" placement="bottom" arrow>
                                            <IconButton onClick={() => setFilter(!enableFilter)} className={classes.marginRight12}>
                                                <svg id="close-24px_4_" data-name="close-24px (4)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                                    <path id="Path_1435" data-name="Path 1435" d="M0,0H24V24H0Z" fill="none" />
                                                    <path id="Path_1436" data-name="Path 1436" d="M19,6.41,17.59,5,12,10.59,6.41,5,5,6.41,10.59,12,5,17.59,6.41,19,12,13.41,17.59,19,19,17.59,13.41,12Z" fill="#afb2b3" />
                                                </svg>
                                            </IconButton>
                                        </TooltipComponent>
                                        <Grid item className={classNames(classes.marginRight25)}>
                                            {/* <IconButton onClick={() => onChangeFilter("favourite", !filters.favourite)}> */}
                                            <Button className={classNames(classes.bgNone, classes.animateIcons)} onClick={() => onChangeFilter("favourite", !filters.favourite)}>
                                                <TooltipComponent title="Favourites" placement="bottom" arrow>
                                                    {
                                                        filters.favourite ?
                                                            <Lottie options={defaultOptions}
                                                                height={32}
                                                                width={32}
                                                                position="absolute"
                                                            />
                                                            :
                                                            <svg version="1.1"
                                                                className={classes.datasourceIcon}
                                                                id="Layer_1"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                x="0px"
                                                                y="0px"
                                                                width="19"
                                                                height="19"
                                                                viewBox="0 0 32 32">
                                                                <g id="heart_2_" transform="translate(0 -16.896)">
                                                                    <g id="Group_1546" transform="translate(0 16.896)">
                                                                        <g id="Path_249">
                                                                            <path fill="#AFB2B3"
                                                                                d="M25.6,7.3c-2.4-2.5-6.3-2.7-8.9-0.3c-0.1,0.1-0.2,0.2-0.3,0.3L16,7.8l-0.4-0.4C13.2,4.8,9.3,4.6,6.7,7
				c0,0,0,0,0,0C6.6,7.1,6.5,7.2,6.4,7.3c-2.5,2.7-2.5,6.9,0,9.6l9,9.5c0.3,0.3,0.8,0.3,1.2,0c0,0,0,0,0,0l0,0l9-9.5
				C28.1,14.2,28.1,10,25.6,7.3z M24.4,15.8L24.4,15.8L16,24.7l-8.4-8.9c-1.9-2.1-1.9-5.3,0-7.3c1.7-1.9,4.7-2,6.6-0.3c0,0,0,0,0,0
				c0.1,0.1,0.2,0.2,0.3,0.3l1,1.1c0.3,0.3,0.9,0.3,1.2,0l1-1.1c1.7-1.9,4.7-2,6.6-0.3c0,0,0,0,0,0c0.1,0.1,0.2,0.2,0.3,0.3
				C26.4,10.5,26.4,13.7,24.4,15.8L24.4,15.8z" />
                                                                            <path fill="#AFB2B3"
                                                                                d="M16,26.9C16,26.9,16,26.9,16,26.9c-0.3,0-0.6-0.1-0.8-0.3l-9-9.5c-2.6-2.8-2.6-7.1,0-9.9
				c0.1-0.1,0.2-0.2,0.3-0.3c2.6-2.4,6.8-2.3,9.2,0.3L16,7.4l0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.3c1.3-1.2,2.9-1.8,4.7-1.7
				c1.7,0.1,3.3,0.8,4.5,2.1l0,0c2.6,2.8,2.6,7.1,0,9.9l-9.1,9.6C16.5,26.8,16.3,26.9,16,26.9z M11,5.5c-1.5,0-3,0.5-4.1,1.6
				C6.8,7.3,6.6,7.4,6.5,7.5c-2.4,2.6-2.4,6.7,0,9.3l9,9.5c0.2,0.2,0.6,0.2,0.8,0l9-9.5c2.4-2.6,2.4-6.7,0-9.3
				c-1.1-1.2-2.6-1.9-4.2-1.9c-1.6-0.1-3.2,0.5-4.3,1.6c-0.1,0.1-0.2,0.2-0.3,0.3L16,8.1l-0.6-0.6C14.2,6.2,12.6,5.5,11,5.5z M16,25
				l-8.6-9.1c-2-2.1-2-5.5,0-7.6c0.9-1,2.1-1.5,3.4-1.6c1.3-0.1,2.5,0.4,3.5,1.3c0.1,0.1,0.2,0.2,0.3,0.3l1,1.1
				c0.2,0.2,0.6,0.2,0.9,0l1-1.1c0.9-1,2.1-1.5,3.4-1.6c1.3-0.1,2.5,0.4,3.5,1.3c0.1,0.1,0.2,0.2,0.3,0.3c1.9,2,2,5.1,0.3,7.3h0
				l-0.4,0.4L16,25z M11,7.2c-0.1,0-0.1,0-0.2,0c-1.2,0-2.3,0.6-3.1,1.4c-1.8,2-1.8,5.1,0,7l8.3,8.7l8.3-8.7c1.8-2,1.8-5.1,0-7
				c-0.1-0.1-0.2-0.2-0.3-0.3c-0.9-0.8-2-1.2-3.2-1.2c-1.2,0-2.3,0.6-3.1,1.4l-1,1.1c-0.4,0.4-1.1,0.4-1.5,0l-1-1.1
				c-0.1-0.1-0.2-0.2-0.3-0.3C13.2,7.6,12.1,7.2,11,7.2z" />
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                    }
                                                </TooltipComponent>
                                            </Button>
                                        </Grid>
                                        {
                                            filters.viewType === appConstants.filterTypes[2] &&
                                            <Grid item className={classNames(classes.marginRight25)}>
                                                <TooltipComponent title="Primary Key" placement="bottom" arrow>
                                                    <IconButton onClick={() => onChangeFilter("primary_key", !filters.primary_key)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 16 17.044">
                                                            <defs />
                                                            <g transform="translate(-13.899)">
                                                                <path fill={filters.primary_key ? theme.palette.primary.main : "#e1e5e6"} d="M21.078,12.848,19.87,11.639l4.158-4.159A3.988,3.988,0,1,0,22.418,5.87l-8.187,8.188a1.139,1.139,0,0,0,1.611,1.611l1.208,1.208a.569.569,0,0,0,.806,0l.806-.805a.569.569,0,0,0,0-.805l-1.208-1.208.805-.808,1.209,1.211a.569.569,0,0,0,.806,0l.805-.805A.572.572,0,0,0,21.078,12.848ZM24.7,2.779a1.71,1.71,0,1,1,0,2.416A1.711,1.711,0,0,1,24.7,2.779Z" transform="translate(0)" />
                                                            </g>
                                                        </svg>
                                                    </IconButton>
                                                </TooltipComponent>
                                            </Grid>
                                        }
                                        {
                                            filters.viewType === appConstants.filterTypes[2] &&
                                            <Grid item className={classes.marginRight25}>
                                                <Typography className={classes.filterdqscoreText}>
                                                    Sensitivity Level
                                                </Typography>
                                                <Grid className={classes.sensitivitySelect}>
                                                    <Typography onClick={() => onChangeFilter("sensitivity", 1)} component="div" className={filters.sensitivity.includes(1) ? 'active' : ''}>
                                                        1
                                                    </Typography>
                                                    <Typography onClick={() => onChangeFilter("sensitivity", 2)} component="div" className={filters.sensitivity.includes(2) ? 'active' : ''}>
                                                        2
                                                    </Typography>
                                                    <Typography onClick={() => onChangeFilter("sensitivity", 3)} component="div" className={filters.sensitivity.includes(3) ? 'active' : ''}>
                                                        3
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                        <Grid item className={classes.marginRight25}>
                                            <Typography className={classNames(classes.filterdqscoreText)} style={{ width: '115px' }}>
                                                {`DQScore (${filters.dqScore[0]} - ${filters.dqScore[1]})`}
                                            </Typography>
                                            <Slider
                                                defaultValue={0}
                                                getAriaValueText={(value) => getThresholdText(value)}
                                                valueLabelDisplay="auto"
                                                value={filters.dqScore ? filters.dqScore : 0}
                                                onChange={(event, value) => onSliderChange("dqScore", value)}
                                                onChangeCommitted={(event, value) => onChangeFilter("dqScore", value)}
                                                step={1}
                                                min={0}
                                                max={100}
                                                color="secondary"
                                                className={classNames(classes.slider, classes.filterSlider, classes.SensitivityMuiSlider)}
                                            />
                                        </Grid>
                                        {
                                            !(filters.viewType === appConstants.filterTypes[3]) &&
                                            <Grid item className={classNames(classes.marginRight25, classes.statusSelect, classes.select, classes.filterselect)}>
                                                <FormLabel>
                                                    Status
                                                </FormLabel>
                                                <TextBox
                                                    name="status"
                                                    select
                                                    onChange={(event, value) => { onChangeFilter("status", event.target.value); }}
                                                    value={filters.status}
                                                    className={classes.glossarySelect}
                                                    SelectProps={
                                                        {
                                                            MenuProps: {
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "center"
                                                                },
                                                                transformOrigin: {
                                                                    vertical: "top",
                                                                    horizontal: "center"
                                                                },
                                                                getContentAnchorEl: null
                                                            }
                                                            // IconComponent: () => DropDownIcon()
                                                        }
                                                    }

                                                >
                                                    {
                                                        [{ name: "", value: "" }, ...appConstants.glossaryStatus].map((status, index) =>
                                                            <MenuItem key={index} className={classes.menuItem} value={status.value}>
                                                                <img src={renderStatusIcon(status.value)} alt="" className={classes.marginRight5} />
                                                                <Typography>
                                                                    {status.name}
                                                                </Typography>
                                                            </MenuItem>
                                                        )
                                                    }
                                                </TextBox>
                                            </Grid>
                                        }
                                        <Grid item className={classNames(classes.marginRight25, classes.select, classes.filterselect)}>
                                            <FormLabel>
                                                Source Type
                                            </FormLabel>
                                            <TooltipComponent title={tooltipContent()} arrow>
                                                <Grid>
                                                    <TextBox
                                                        // label="Source Type"
                                                        name="connectionType"
                                                        placeholder="select"
                                                        value={filters.connectionType ? filters.connectionType : []}
                                                        select
                                                        onChange={(event, value) => { onChangeFilter("connectionType", event.target.value); }}
                                                        SelectProps={
                                                            {
                                                                multiple: true,
                                                                value: filters.connectionType ? filters.connectionType : [],
                                                                onChange: (event) => onChangeFilter("connectionType", event.target.value),
                                                                renderValue: (selected) => selected.join(', '),
                                                                MenuProps: {
                                                                    anchorOrigin: {
                                                                        vertical: "bottom",
                                                                        horizontal: "center"
                                                                    },
                                                                    transformOrigin: {
                                                                        vertical: "top",
                                                                        horizontal: "center"
                                                                    },
                                                                    getContentAnchorEl: null
                                                                }
                                                                // IconComponent: () => DropDownIcon()
                                                            }
                                                        }
                                                        fullWidth
                                                    >
                                                        {
                                                            sortTable(datasourceTypesList, "asc", "name").map((connection, index) =>
                                                                <MenuItem key={index} value={connection.type}>
                                                                    <CheckboxComponent checked={filters.connectionType.includes(connection.type)} name={connection.name} checkboxLabel={connection.name} />
                                                                </MenuItem>
                                                            )
                                                        }
                                                    </TextBox>
                                                </Grid>
                                            </TooltipComponent>
                                        </Grid>
                                        <Grid item className={classes.userSelectContainer}>
                                            <FormLabel>
                                                Users
                                            </FormLabel>
                                            <UserAutoComplete
                                                availableList={sortTable(usersList, "asc", "name")}
                                                selectedList={filters.users ? filters.users : []}
                                                displayValue="name"
                                                onChange={onChangeUser}
                                                onInputValueChange={onInputValueOnChange}
                                                inputValue={userInput}
                                                onRemoveItem={removeItem}
                                                disabled={Boolean(filters.favourite)}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <TooltipComponent title="Clear Filter" placement="bottom" arrow>
                                                <IconButton onClick={() => resetFilter()}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22.795" viewBox="0 0 20 22.795">
                                                        <g id="filter_10_" data-name="filter (10)" transform="translate(-31.388)">
                                                            <path id="Path_1437" data-name="Path 1437" d="M46.071,0a5.326,5.326,0,0,0-5.1,3.8h-8.59a1,1,0,0,0-.814,1.564l5.776,7.882a1.046,1.046,0,0,1,.185.6V21.75a1.044,1.044,0,0,0,1.668.835l2.564-1.937a1.527,1.527,0,0,0,.612-1.22V13.84a1.047,1.047,0,0,1,.185-.6l2.07-2.825A5.31,5.31,0,1,0,46.071,0ZM41.477,12.462a2.375,2.375,0,0,0-.438,1.377c0,6.076.033,5.658-.075,5.737l-2.1,1.586V13.84a2.376,2.376,0,0,0-.428-1.364l-5.38-7.341h7.7A5.307,5.307,0,0,0,43.369,9.88Zm4.594-3.18a3.973,3.973,0,1,1,3.982-3.973A3.982,3.982,0,0,1,46.071,9.282Z" transform="translate(0)" fill="#f38080" />
                                                            <path id="Path_1438" data-name="Path 1438" d="M321.45,76.089a.668.668,0,0,0-.944,0l-.793.791-.793-.791a.668.668,0,0,0-.944.945l.791.79-.791.79a.668.668,0,0,0,.944.945l.793-.791.793.791a.668.668,0,0,0,.944-.945l-.791-.79.791-.79A.668.668,0,0,0,321.45,76.089Z" transform="translate(-273.642 -72.515)" fill="#f38080" />
                                                        </g>
                                                    </svg>
                                                </IconButton>
                                            </TooltipComponent>
                                        </Grid>
                                    </Grid>
                                </ClickAwayListener>
                            </Grid>
                        }

                    </ValidatorForm>
                }
                <TooltipComponent title="Filters" placement="bottom" arrow>
                    <IconButton onClick={() => setFilter(!enableFilter)} className={classNames(classes.marginRight7, classes.filterImg)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16.923" viewBox="0 0 20 16.923">
                            <g transform="translate(-3 -5)">
                                <path id="Path_1391" data-name="Path 1391" d="M22.231,6.538H11.462A1.54,1.54,0,0,0,9.923,5H8.385A1.54,1.54,0,0,0,6.846,6.538H3.769a.769.769,0,1,0,0,1.538H6.846A1.54,1.54,0,0,0,8.385,9.615H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,8.077V6.538H9.923V7.3s0,0,0,0,0,0,0,0v.766Z" fill={theme.palette.primary.main} />
                                <path id="Path_1392" data-name="Path 1392" d="M22.231,14.538H17.615A1.54,1.54,0,0,0,16.077,13H14.538A1.54,1.54,0,0,0,13,14.538H3.769a.769.769,0,0,0,0,1.538H13a1.54,1.54,0,0,0,1.538,1.538h1.538a1.54,1.54,0,0,0,1.538-1.538h4.615a.769.769,0,1,0,0-1.538Zm-7.692,1.538V14.538h1.538V15.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -1.846)" fill={theme.palette.primary.main} />
                                <path id="Path_1393" data-name="Path 1393" d="M22.231,22.538H11.462A1.54,1.54,0,0,0,9.923,21H8.385a1.54,1.54,0,0,0-1.538,1.538H3.769a.769.769,0,0,0,0,1.538H6.846a1.54,1.54,0,0,0,1.538,1.538H9.923a1.54,1.54,0,0,0,1.538-1.538H22.231a.769.769,0,1,0,0-1.538ZM8.385,24.077V22.538H9.923V23.3s0,0,0,0,0,0,0,0v.766Z" transform="translate(0 -3.692)" fill={theme.palette.primary.main} />
                            </g>
                        </svg>
                    </IconButton>
                </TooltipComponent>
                {
                    userRole && userRole.properties_permission.is_edit && licenseConfig.import_export_metadata &&
                    <TooltipComponent title="Import Metadata" placement="bottom" arrow>
                        <IconButton onClick={() => history.push('./datasourceimport')} className={classNames(classes.marginRight7, classes.filterImg, classes.dimension)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24.343 23.846" fill={theme.palette.primary.main}>
                                <g id="download_button" transform="translate(-1600.338 -267.233)">
                                    <g id="Group_3" data-name="Group 3">
                                        <path id="Path_4" data-name="Path 4" d="M1624.68,291.079h-24.343V276.71h2v12.369h20.343V276.71h2Z" />
                                        <path id="Path_5" data-name="Path 5" d="M1617.779,276.513l-5.32,5.27-5.4-5.35,1.4-1.42,3.04,3v-10.78h2v10.7l2.87-2.84Z" />
                                    </g>
                                </g>
                            </svg>
                        </IconButton>
                    </TooltipComponent>
                }
                {/* {
                    userRole && userRole.properties_permission.is_edit &&
                    <TooltipComponent title="Import Metadata" placement="bottom" arrow>
                        <IconButton onClick={() => history.push('./datasourceimport')} className={classNames(classes.marginRight7, classes.filterImg, classes.dimension)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.776" height="20.776" viewBox="0 0 20.776 20.776">
                                <g id="download" transform="translate(-0.55 -0.55)">
                                    <path id="Path_2632" data-name="Path 2632" d="M18.731,21.326H3.145A2.6,2.6,0,0,1,.55,18.731V.55H9.568l1.7,1.724H21.326V18.731a2.6,2.6,0,0,1-2.595,2.595ZM2.274,2.274V18.731a.907.907,0,0,0,.907.907h15.55a.907.907,0,0,0,.907-.907V4.007H10.566L8.842,2.274Z" fill="#f38080" />
                                    <path id="Path_2633" data-name="Path 2633" d="M1.5,6.27H20.552V8H1.5Z" transform="translate(-0.088 -0.53)" fill="#f38080" />
                                    <path id="Path_2634" data-name="Path 2634" d="M11.05,10.09h1.733v7.793H11.05Z" transform="translate(-0.974 -0.885)" fill="#f38080" />
                                    <path id="Path_2635" data-name="Path 2635" d="M11.584,19.488,7.51,15.415,8.735,14.19l2.849,2.849,2.849-2.849,1.225,1.225Z" transform="translate(-0.645 -1.265)" fill="#f38080" />
                                </g>
                            </svg>
                        </IconButton>
                    </TooltipComponent>
                } */}
                <ButtonGroup className={classNames(classes.buttonGroup, classes.marginRight10)}>
                    {
                        settingConfig.domain &&
                        <Button
                            variant="contained"
                            onClick={() => filters.viewType !== appConstants.filterTypes[3] && onChangeFilter("viewType", appConstants.filterTypes[3])}
                            className={classNames(filters.viewType === appConstants.filterTypes[3] ? 'active' : '')}>
                            <TooltipComponent title="Domain" placement="bottom" arrow>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.068" height="18.554" viewBox="0 0 16.068 18.554">
                                    <g id="rubik" transform="translate(-34.297)">
                                        <g id="Group_3147" data-name="Group 3147" transform="translate(34.297)">
                                            <path id="Path_1474" data-name="Path 1474" d="M42.331,0,34.3,4.639v9.277l8.034,4.639,8.034-4.639V4.639Zm0,1.259,2.655,1.533L42.331,4.324,39.677,2.791Zm-4.29,13.56-2.655-1.533V10.221l2.655,1.533Zm0-4.324L35.387,8.962V5.9L38.042,7.43Zm-2.11-5.542,2.655-1.533,2.655,1.533L38.587,6.486Zm5.854,12.028-2.655-1.533V12.383l2.655,1.533Zm0-4.324-2.655-1.533V8.059l2.655,1.533Zm-2.11-5.542,2.655-1.533,2.655,1.533L42.331,8.648Zm5.854,8.333-2.655,1.533V13.916l2.655-1.533Zm0-4.324-2.655,1.533V9.592l2.655-1.533Zm-2.11-6.171,2.655-1.533L48.73,4.953,46.076,6.486Zm5.854,8.333-2.655,1.533V11.754l2.655-1.533Zm0-4.324L46.621,10.5V7.43L49.275,5.9Z" transform="translate(-34.297)" fill={filters.viewType === appConstants.filterTypes[3] ? theme.palette.background.paper : theme.palette.grey.light} />
                                        </g>
                                    </g>
                                </svg>
                            </TooltipComponent>
                        </Button>
                    }
                    <Button
                        variant="contained"
                        onClick={() => filters.viewType !== appConstants.filterTypes[0] && onChangeFilter("viewType", appConstants.filterTypes[0])}
                        className={classNames(filters.viewType === appConstants.filterTypes[0] ? 'active' : '')}>
                        <TooltipComponent title="Data Source" placement="bottom" arrow>
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.289" height="15.394" viewBox="0 0 11.289 15.394" fill={theme.palette.primary.main}>
                                <path id="database_17_" data-name="database (17)" d="M15.645,17.394c2.805,0,5.645-.793,5.645-2.309V4.309C21.289,2.793,18.45,2,15.645,2S10,2.793,10,4.309V15.085C10,16.6,12.839,17.394,15.645,17.394Zm0-14.368c2.865,0,4.618.831,4.618,1.283s-1.753,1.283-4.618,1.283-4.618-.831-4.618-1.283S12.78,3.026,15.645,3.026ZM11.026,5.695a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924V7.9c0,.452-1.753,1.283-4.618,1.283S11.026,8.353,11.026,7.9Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Z" transform="translate(-10 -2)" fill={filters.viewType === appConstants.filterTypes[0] ? theme.palette.background.paper : theme.palette.grey.light} />
                            </svg>
                        </TooltipComponent>
                    </Button>
                    <Button variant="contained"
                        onClick={() => filters.viewType !== appConstants.filterTypes[1] && onChangeFilter("viewType", appConstants.filterTypes[1])}
                        className={classNames(filters.viewType === appConstants.filterTypes[1] ? 'active' : '')}>
                        <TooltipComponent title="Dataset" placement="bottom" arrow>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.751" height="16.711" viewBox="0 0 17.751 16.711">
                                <path id="table" d="M0,15V31.711H17.751V15Zm16.711,1.04v2.115H1.04V16.04ZM9.4,22.315h3.12V24.4H9.4ZM8.355,24.4H5.235v-2.08h3.12Zm1.04-3.12V19.2h3.12v2.08Zm-1.04,0H5.235V19.2h3.12Zm-4.16,0H1.04V19.2H4.2Zm0,1.04V24.4H1.04v-2.08Zm0,3.12v2.08H1.04v-2.08Zm1.04,0h3.12v2.08H5.235Zm3.12,3.12v2.115H5.235V28.556Zm1.04,0h3.12v2.115H9.4Zm0-1.04v-2.08h3.12v2.08Zm4.16-2.08h3.155v2.08H13.556Zm0-1.04v-2.08h3.155V24.4Zm0-3.12V19.2h3.155v2.08ZM1.04,28.556H4.2v2.115H1.04Zm12.516,2.115V28.556h3.155v2.115Z" transform="translate(0 -15)" fill={filters.viewType === appConstants.filterTypes[1] ? theme.palette.background.paper : theme.palette.grey.light} />
                            </svg>
                        </TooltipComponent>
                    </Button>
                    <Button variant="contained"
                        onClick={() => filters.viewType !== appConstants.filterTypes[2] && onChangeFilter("viewType", appConstants.filterTypes[2])}
                        className={classNames(filters.viewType === appConstants.filterTypes[2] ? 'active' : '')}>
                        <TooltipComponent title="Data Attribute" placement="bottom" arrow>
                            <svg xmlns="http://www.w3.org/2000/svg" width="17.773" height="16.732" viewBox="0 0 17.773 16.732">
                                <path id="table" d="M0,15V31.732H17.773V15Zm16.732,1.041v2.118h-7.3l.024-2.118H4.265v2.118H1.041V16.041H16.732ZM9.407,22.325h3.124v2.083H9.407Zm0-1.041V19.2h3.124v2.083Zm-5.207,0H1.041V19.2H4.2Zm0,1.041v2.083H1.041V22.325Zm0,3.124v2.083H1.041V25.449Zm5.207,3.124h3.124v2.118H9.407Zm0-1.041V25.449h3.124v2.083Zm4.166-2.083h3.159v2.083H13.573Zm0-1.041V22.325h3.159v2.083Zm0-3.124V19.2h3.159v2.083ZM1.041,28.573H4.2v2.118H1.041Zm12.532,2.118V28.573h3.159v2.118Z" transform="translate(0 -15)" fill={filters.viewType === appConstants.filterTypes[2] ? theme.palette.background.paper : theme.palette.grey.light} />
                            </svg>
                        </TooltipComponent>
                    </Button>
                </ButtonGroup>
                {
                    config.catalog_connect && isLicensedValidated &&
                    <TooltipComponent title="Add Datasource" placement="bottom" arrow>
                        <IconButton className={classes.addIconButton}
                            classes={
                                {
                                    root: classes.addIconButtonRoot
                                }
                            }
                            onClick={() => history.push('/datasourcetypes')}>
                            <AddIcon className={classes.addIcon} />
                        </IconButton>
                    </TooltipComponent>
                }
            </Grid >
        </Grid >
    );
};

DatasourceFilter.propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    config: PropTypes.object,
    theme: PropTypes.object,
    sortBy: PropTypes.object
};

export default withStyles((theme) => ({
    ...DatasourceListStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DatasourceFilter);