import React, { useState, useCallback, useEffect } from 'react';
import { Grid, Typography, withStyles, Table, TableHead, TableCell, TableBody, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import SettingComponentStyles from './SettingComponentStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import Loader from '../Loaders/Loader.jsx';
import { getCurrentlyRunningJobs } from '../../actions/settingActions';
import moment from 'moment-timezone';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';


const RunningJobs = (props) => {
    const { classes, theme } = props;
    const [runningjobs, setrunningjobs] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const getRunningJob = useCallback(() => {
        setLoading(true);
        dispatch(getCurrentlyRunningJobs()).then((response) => {
            if (response) {
                setrunningjobs(response);
            }
            setLoading(false);
        });
    }, [dispatch]);

    useEffect(() => {
        getRunningJob();
    }, [getRunningJob]);

    return (
        <Grid className={classes.referenceContainer2}>
            <Grid container justify="space-between">
                <Typography variant="h5" component="h5">
                    Running Jobs
                </Typography>
            </Grid>
            <Grid>
                <Grid className={classes.marginTop10}>
                    <Table className={classes.marginTop10}>
                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.tableHeadCell}>
                                    Source ID
                                </TableCell>
                                <TableCell className={classes.tableHeadCell}>
                                    Source Name
                                </TableCell>
                                <TableCell className={classes.tableHeadCell}>
                                    Dataset ID
                                </TableCell>
                                <TableCell className={classes.tableHeadCell}>
                                    Dataset Name
                                </TableCell>
                                <TableCell className={classes.tableHeadCell}>
                                    Source Type
                                </TableCell>
                                <TableCell className={classes.tableHeadCell}>
                                    Job Type
                                </TableCell>
                                <TableCell className={classes.tableHeadCell}>
                                    Job ID
                                </TableCell>
                                <TableCell className={classes.tableHeadCell}>
                                    Started Time
                                </TableCell>
                                {/* <TableCell className={classes.tableHeadCell} align="center">
                                    Actions
                                </TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody className={classes.loggingTable}>
                            {
                                runningjobs && runningjobs.map((jobs, index) =>
                                    <TableRow key={`error_${index}`}>
                                        <TableCell className={classes.tableCell}>
                                            {jobs.source_id ? jobs.source_id : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {jobs.source__name ? jobs.source__name : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {jobs.dataset_id ? jobs.dataset_id : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {jobs.dataset__name ? jobs.dataset__name : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {jobs.source__type ? jobs.source__type : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {jobs.type ? jobs.type : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {jobs.job_id ? jobs.job_id : ""}
                                        </TableCell>
                                        <TableCell className={classes.tableCell}>
                                            {jobs.start_time ? moment(jobs.start_time).format('MM/DD/YYYY HH:mm:ss') : ""}
                                        </TableCell>
                                        {/* <TableCell align="center">
                                            <ToolTipComponent title="Download" arrow>
                                                <IconButton className={classes.padding6} onClick={() => download(jobs.job_id)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 21 21">
                                                        <g id="download_4_" data-name="download (4)" transform="translate(-1.5 -1.5)">
                                                            <path id="Path_14400" data-name="Path 14400" d="M22,23.714v2.857A1.43,1.43,0,0,1,20.571,28H3.428A1.43,1.43,0,0,1,2,26.572V23.714a.714.714,0,0,1,1.428,0v2.857H20.571V23.714a.714.714,0,0,1,1.429,0Z" transform="translate(0 -6)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                                            <path id="Path_14401" data-name="Path 14401" d="M8.21,12.5a.714.714,0,1,1,1.01-1.01L13,15.275V2.714a.714.714,0,1,1,1.428,0V15.275l3.781-3.78a.714.714,0,1,1,1.01,1.01l-5,5a.715.715,0,0,1-1.011,0Z" transform="translate(-1.715)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="1" />
                                                        </g>
                                                    </svg>
                                                </IconButton>
                                            </ToolTipComponent>
                                        </TableCell> */}
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
            {
                isLoading &&
                <Loader />
            }
            {!isLoading && runningjobs && runningjobs.length === 0 && <NoResultFound />}
        </Grid>
    );
};

RunningJobs.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object
};

export default withStyles((theme) => ({
    ...SettingComponentStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(RunningJobs);