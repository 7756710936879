import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Tabs, Tab } from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import Styles from '../../layouts/Styles.jsx';
import SemanticModelInfo from '../../components/SemanticModel/SemanticModelInfo.jsx';
import TabPanel from '../../components/TabPanel/TabPanel.jsx';
import { getModel, updateSemanticModel, exportModel, deleteModel } from '../../actions/modelActions';
import AlertDialog from '../../components/AlertDialog/AlertDialog.jsx';
import { appConstants } from '../../constants/appConstants';
// import SemanticModelDetail from '../../components/SemanticModel/SemanticModelDetail.jsx';
import SemanticModelAttributes from '../../components/SemanticModel/SemanticModelAttributes.jsx';

const SemanticDetailInfo = (props) => {

    const { classes, match, location, history } = props;
    const dispatch = useDispatch();
    const [tabIndex, setTabIndex] = useState(0);
    const modelId = match.params.id;
    const [breadcrumb, setBreadCrumb] = useState([
        {
            name: 'Semantics',
            path: '/semanticmodel'
        },
        {
            name: location.state.model.name,
            path: ''
        }
    ]);
    const [model, setModel] = useState({
        "name": '',
        "description": '',
        "terms_description": ''
    });
    const [isDelete, setDeleteDialog] = useState(false);

    const onChange = useCallback((property, value) => {
        model[property] = value;
        if (property === "name") {
            breadcrumb[breadcrumb.length - 1].name = value;
            setBreadCrumb([...breadcrumb]);
        }
    }, [breadcrumb, model]);

    const updateProperties = useCallback((property) => {
        dispatch(updateSemanticModel(modelId, model));
    }, [dispatch, model, modelId]);

    const getModelById = useCallback((modelId) => {
        dispatch(getModel(modelId)).then((response) => {
            if (response) {
                setModel({ ...response });
            }
        });
    }, [dispatch]);

    const deleteModelData = useCallback(() => {
        setDeleteDialog(true);
    }, []);

    const deleteModelInfo = () => {
        dispatch(deleteModel(modelId)).then((response) => {
            history.push("/model");
        });
    };

    const exportSemanticModel = () => {
        const requestParams = {
            "model_id": modelId
        };
        dispatch(exportModel(requestParams)).then((response) => {
            if (response) {
                const fileName = response.name;
                const json = JSON.stringify(response);
                const blob = new Blob([json], { type: 'application/json' });
                const href = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.download = `${fileName}.json`;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        });
    };

    useEffect(() => {
        if (!modelId) {
            return;
        }
        getModelById(modelId);
    }, [modelId, getModelById]);


    return (
        <Grid>
            <ValidatorForm name="master" onSubmit={() => null}>
                <Grid className={classes.paddingTop20}>
                    <SemanticModelInfo
                        breadcrumb={breadcrumb}
                        model={model}
                        modelId={modelId}
                        onSave={updateProperties}
                        onChangeModel={(...props) => onChange(...props)}
                        history={history}
                        deleteModelData={deleteModelData}
                        exportModel={() => exportSemanticModel()}
                    />
                </Grid>
                <Grid className={classes.datasourceTabContainer}>
                    <Tabs
                        value={tabIndex}
                        indicatorColor="secondary"
                        textColor="secondary"
                        onChange={(event, index) => setTabIndex(index)}
                        aria-label="tabs"
                    >
                        {
                            appConstants && appConstants.modelTabs.map((name, index) =>
                                <Tab key={index} label={name} />
                            )
                        }
                    </Tabs>
                </Grid>
                <Grid className={classNames(classes.marginTop15, classes.tabContainerOverview)}>
                    {/* <TabPanel value={tabIndex} index={0}>
                        {
                            tabIndex === 0 &&
                            <SemanticModelDetail
                                onSave={updateProperties}
                                isEditable={isStewardUser}
                                model={model}
                                governance={governance}
                                onChangeModel={(...props) => onChange(...props)}
                                createMasterShare={props.createSemanticModelShare}
                                deleteMasterShare={props.deleteSemanticModelShare}
                                updateUser={props.updateUser} />
                        }
                    </TabPanel> */}
                    <TabPanel value={tabIndex} index={0}>
                        {
                            tabIndex === 0 &&
                            <SemanticModelAttributes modelId={modelId} tabIndex={tabIndex} />
                        }
                    </TabPanel>
                </Grid>
            </ValidatorForm>
            <AlertDialog title="Delete Model"
                message={`Are you sure you want to delete the model ${model ? model.name : ''}?`}
                okButtonText="OK"
                cancelButtonText="Cancel"
                show={isDelete}
                onClickOK={() => deleteModelInfo()}
                onClickCancel={() => setDeleteDialog(false)} />
        </Grid>
    );
};

SemanticDetailInfo.propTypes = {
    classes: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object,
    history: PropTypes.object
};


export default (withStyles((theme) => ({
    ...Styles(theme)
}))(SemanticDetailInfo));