import React, { useCallback, useEffect, useState } from 'react';
import { Grid, Typography, withStyles, Tabs, Tab, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import classnames from 'classnames';
import TextBox from '../TextBox/TextBox.jsx';
import DashboardStyles from './DashboardStyles.jsx';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { getDqScoreAnalysis } from '../../actions/scheduleActions';
import Linear from '../Charts/Linear.jsx';
import TooltipComponent from '../Tooltip/Tooltip.jsx';
import noprofile from "../../assets/images/np_profile_data.jpg";

const DQScoreAnalysis = (props) => {
    const { classes, config } = props;
    const [activeTabIndex, setTabIndex] = useState(2);
    const [filter, setFilter] = useState("Top 10");
    const [analysisData, setAnalysisData] = useState({});
    const tabList = ["Datasource", "Dataset", "Attribute", "Domain"];
    const dispatch = useDispatch();
    const dq_score_threshold = useSelector(({ setting }) => setting.user_config.dq_score_threshold);

    const getAnalysis = useCallback(() => {
        dispatch(getDqScoreAnalysis()).then((response) => {
            if (response && response) {
                const analysisReponse = response;
                setAnalysisData({ ...analysisReponse });
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getAnalysis();
    }, [getAnalysis]);

    const onChangeFilter = useCallback((value) => {
        setFilter(value);
    }, []);

    const onChangeTab = useCallback((index) => {
        if (filter === "Bottom 10") {
            setFilter("Top 10");
        }
        setTabIndex(index);
    }, [filter]);

    const getTabs = () => {
        let tabs = ["Datasource", "Dataset", "Attribute", "Domain"];
        if (!config.domain) {
            tabs = ["Datasource", "Dataset", "Attribute"];
        }
        return tabs;
    };

    const selectedFilter = filter === "Top 10" ? "top_n" : "bottom_n";
    const selectedTab = tabList[activeTabIndex].toLowerCase();
    const analysis = analysisData && Object.keys(analysisData).length && analysisData[selectedTab] ? analysisData[selectedTab][selectedFilter] : [];
    return (
        <Grid className={classnames(classes.section, classes.container)}>
            <ValidatorForm form="analysis" onSubmit={() => null} style={{ height: '100%' }}>
                <Grid container justify="space-between">
                    <Grid item>
                        <Typography variant="h4" component="h4">
                            DQScore Analysis
                        </Typography>
                    </Grid>
                    <Grid item>
                        <TextBox
                            placeholder="Filter"
                            className={classes.inlinetxt}
                            onChange={(event) => onChangeFilter(event.target.value)}
                            value={filter}
                            select
                            SelectProps={
                                {
                                    MenuProps: {
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "center"
                                        },
                                        transformOrigin: {
                                            vertical: "top",
                                            horizontal: "center"
                                        },
                                        getContentAnchorEl: null
                                    }
                                    // IconComponent: (iconProps) => DropDownIcon(iconProps)
                                }
                            }
                            fullWidth>
                            {
                                ["Bottom 10", "Top 10"].map((option, index) => (
                                    <MenuItem key={`menuProperty_Options_${index}`} value={option} className={classes.menuItem}>
                                        {option}
                                    </MenuItem>
                                ))
                            }
                        </TextBox>
                    </Grid>
                </Grid>
                <Tabs
                    className={classes.tabContainer}
                    value={activeTabIndex}
                    indicatorColor="secondary"
                    variant="scrollable"
                    scrollButtons={false}
                    textColor="secondary"
                    onChange={(event, index) => onChangeTab(index)}
                >
                    {
                        getTabs().map((tab, index) => (
                            <Tab key={`header-${tab}-${index}`} label={tab} />
                        ))
                    }
                </Tabs>
                <SwipeableViews index={activeTabIndex}>
                    {
                        getTabs().map((tab, index) => (
                            <Grid key={index} className={classes.analysisContainer}>
                                {
                                    analysis.length > 0 ?
                                        analysis.map((source, index) =>
                                            <Grid key={index} className={classes.linearListContainer}>
                                                <TooltipComponent arrow title={source.name}>
                                                    <Typography className={classes.linearText}>
                                                        {source.name !== "" ? source.name : `${tabList[activeTabIndex].toLowerCase() === "datasource" ? "Untitled Data Source" : "Untitled Data Set"}`}
                                                    </Typography>
                                                </TooltipComponent>
                                                <Grid className={classes.linearSection}>
                                                    <Linear showPercentage value={source.dqscore} dqScoreThreshold={source.dq_score_threshold && Object.keys(source.dq_score_threshold).length > 0 ? source.dq_score_threshold : dq_score_threshold} />
                                                </Grid>
                                            </Grid>
                                        ) :
                                        <img src={noprofile} alt="" style={{ maxWidth: "100%" }} />
                                }
                            </Grid>
                        ))
                    }
                </SwipeableViews>
            </ValidatorForm>
        </Grid>
    );
};

DQScoreAnalysis.propTypes = {
    classes: PropTypes.object,
    config: PropTypes.object
};

export default withStyles((theme) => ({
    ...DashboardStyles(theme)
}), { withTheme: true })(DQScoreAnalysis);