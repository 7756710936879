import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, withStyles, Typography, Tabs, Tab } from '@material-ui/core';
import classNames from 'classnames';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TabPanel from '../../components/TabPanel/TabPanel.jsx';
import MyProfile from '../../components/Settings/MyProfile.jsx';
import { appConstants } from '../../constants/appConstants';
import SettingStyles from './SettingStyles.jsx';
import Styles from '../../layouts/Styles.jsx';

import OrganizationList from '../../components/Settings/OrganizationList.jsx';
import OrganizationDetail from '../../components/Settings/OrganizationDetail.jsx';
import Reference from '../../components/Settings/Reference.jsx';
import Users from '../../components/Settings/Users.jsx';
import Theme from '../../components/Settings/Theme.jsx';
import { getUserProperty } from '../../helpers/appHelpers';
import { getModuleConnectionTypes } from '../../actions/datasourceActions';
import EnrichmentList from '../../components/Settings/EnrichmentList.jsx';
import EnrichmentDetail from '../../components/Settings/EnrichmentDetail.jsx';
import Profile from '../../components/Settings/Profile.jsx';
import System from '../../components/Settings/System.jsx';
import Configuration from '../../components/Settings/SettingsConfiguration.jsx';
import Logging from '../../components/Settings/Logging.jsx';
import RunningJobs from '../../components/Settings/RunningJobs.jsx';
import LivyJobs from '../../components/Settings/livyJobs.jsx';
import ScheduleDatasets from '../../components/Settings/ScheduleDatasets.jsx';
import Role from '../../components/Settings/Role.jsx';
import { getSystemLicenseStatus } from '../../actions/settingActions';
import { setCurrentPage } from '../../helpers/apiHelpers.js';
import Report from '../../components/Settings/Report.jsx';

class Settings extends Component {
    constructor(props) {
        super(props);
        setCurrentPage('settings');
        const user = getUserProperty();
        this.state = {
            tabIndex: (this.props.location.state && this.props.location.state.tabIndex) ? this.props.location.state.tabIndex : 0,
            organizationTabIndex: (this.props.location.state && this.props.location.state.tabIndex) ? this.props.location.state.tabIndex : 0,
            isOrganization: (this.props.location.state && this.props.location.state.isOrganization) ? this.props.location.state.isOrganization : !user.is_superuser,
            organizationId: this.getOrganizationId(user),
            breadcrumb: [
                {
                    name: 'Organization',
                    path: '/'
                }
            ],
            organizationHeaders: this.getOrganizationHeaders(user),
            enrichmentId: 0,
            enrichmentDetailView: false,
            navigateTab: (this.props.location.state && this.props.location.state.navigateTab) ? this.props.location.state.navigateTab : '',
            roleConfiguration: false,
            reportConfiguration: false,
            systemHeaders: this.getSystemHeaders()
        };
    }


    componentDidMount() {
        this.props.getSystemLicenseStatus();
        this.props.getModuleConnectionTypes();
        this.props.getPageTitle(this.state.isOrganization ? `Settings | ${this.state.breadcrumb.map((elem) => elem.name).join(' | ')}` : `Settings | ${this.state.systemHeaders[this.tabIndex || 0]}`);
    }

    getOrganizationId(user) {
        if (this.props.location.state && this.props.location.state.organizationId) {
            return this.props.location.state.organizationId;
        } else if (user.is_superuser) {
            return 0;
        }
        return user.organization;
    }

    getOrganizationHeaders(user) {
        let headers = [];
        let organizationTab = false;
        if (user.is_superuser) {
            headers = [...appConstants.organizationTabs];
        } else if (user.role && user.role.page_permission === "All") {
            organizationTab = true;
            headers = [...appConstants.organizationAdminTabs];
        } else {
            headers = ["Profile", 'Logging', 'Reports'];
        }
        if (!this.props.config.theme) {
            const index = headers.indexOf("Theme");
            if (index > -1) {
                headers.splice(index, 1);
            }
        }
        /*
         * if (!this.props.config.curate) {
         *     const index = headers.indexOf("Libraries");
         *     if (index > -1) {
         *         headers.splice(index, 1);
         *     }
         * }
         */
        if (!this.props.config.enrichment) {
            const index = headers.indexOf("Enrichment");
            if (index > -1) {
                headers.splice(index, 1);
            }
        }
        if (user.is_superuser || !user.role.logs_permission) {
            if ((!organizationTab && user.is_superuser) || (!user.is_superuser && !user.role.logs_permission && organizationTab)) {
                const index = headers.indexOf("Logging");
                if (index > -1) {
                    headers.splice(index, 1);
                }
            }
        }
        return headers;
    }

    getSystemHeaders() {
        const headers = [...appConstants.settingStewardHeaders];
        if (!this.props.config.theme) {
            const index = headers.indexOf("Theme");
            headers.splice(index, 1);
        }
        /*
         * if (!this.props.config.curate) {
         *     const index = headers.indexOf("Libraries");
         *     headers.splice(index, 1);
         * }
         */
        if (!this.props.config.enrichment) {
            const index = headers.indexOf("Enrichment");
            headers.splice(index, 1);
        }
        return headers;
    }

    onChangeOrganization = (type, data) => {
        const { breadcrumb, systemHeaders, organizationHeaders } = this.state;
        if (type === "edit" || type === "add") {
            this.setState({ isOrganization: true });
            breadcrumb.push({ name: data ? data.name : '', path: '' });
            this.props.getPageTitle(`Settings | ${breadcrumb.map((elem) => elem.name).join(' | ')} | ${organizationHeaders[this.tabIndex || 0]}`);
            if (type === "edit") {
                this.setState({ organizationId: data.id });
            }
        } else if (type === "back") {
            if (data === "organization") {
                this.onEditRoleConfig();
                this.onEditReportConfig();
                breadcrumb.pop();
            } else {
                this.setState({ isOrganization: false, organizationId: 0, tabIndex: 1 });
                breadcrumb.forEach(() => {
                    breadcrumb.pop();
                });
            }
            this.props.getPageTitle(`Settings | ${systemHeaders[this.tabIndex || 0]}`);
        }
        this.setState({ breadcrumb });
    }

    setOrganizationId = (data) => {
        const { breadcrumb } = this.state;
        breadcrumb.push({ name: data ? data.name : '', path: '' });
        this.setState({ organizationId: data.id });
        this.setState({ breadcrumb });
    }

    onChangeEnrichment = (type, data) => {
        if (type === "add") {
            this.setState({ enrichmentDetailView: true });
            this.setState({ enrichmentId: 0 });
        } else if (type === "edit") {
            this.setState({ enrichmentDetailView: true });
            this.setState({ enrichmentId: data.id });
        } else if (type === "back") {
            this.setState({ enrichmentDetailView: false });
        }
    }

    getTabIndex = (tabName) => {
        const tabs = this.state.isOrganization ? this.state.organizationHeaders : this.state.systemHeaders;
        return tabs.indexOf(tabName);
    }

    onEditRoleConfig = (type) => {
        if (type) {
            const breadcrumb = this.state.breadcrumb;
            breadcrumb[breadcrumb.length - 1].path = "organization";
            breadcrumb.push({ name: type, path: '' });
            this.setState({ breadcrumb: breadcrumb, roleConfiguration: !this.state.roleConfiguration });
        } else {
            this.setState({ roleConfiguration: false });
        }
    }

    onEditReportConfig = (type) => {
        if (type) {
            const breadcrumb = this.state.breadcrumb;
            breadcrumb[breadcrumb.length - 1].path = "organization";
            breadcrumb.push({ name: type, path: '' });
            this.setState({ breadcrumb: breadcrumb, reportConfiguration: !this.state.reportConfiguration });
        } else {
            this.setState({ reportConfiguration: false });
        }
    }

    onCloseRoleEdit = () => {
        const breadcrumb = this.state.breadcrumb;
        breadcrumb.pop();
        this.setState({ breadcrumb: breadcrumb, roleConfiguration: false });
    }

    onCloseReportEdit = () => {
        const breadcrumb = this.state.breadcrumb;
        breadcrumb.pop();
        this.setState({ breadcrumb: breadcrumb, reportConfiguration: false });
    }


    render() {
        const { classes, history } = this.props;
        return (
            <Grid className={classes.container}>
                <Grid className={classes.marginTop15}>
                    {
                        this.state.breadcrumb.length > 1 && this.state.breadcrumb.map((crumb, index) =>
                            <Typography variant="body2" key={`crumb_${index}`} component="span" className={classes.link} onClick={() => this.onChangeOrganization(crumb.path !== "" ? "back" : "", crumb.path)}>
                                {crumb.name}
                                {(index !== this.state.breadcrumb.length - 1) ? ' | ' : null}
                            </Typography>
                        )
                    }
                </Grid>
                <Typography component="h3" variant="h3" className={classNames(classes.title, this.state.breadcrumb.length === 1 ? classes.marginTop15 : '')}>
                    Settings
                </Typography>
                <Grid className={classNames(classes.datasourceTabContainer, classes.settingsTabContainer)}>
                    {
                        !this.state.isOrganization ?
                            <Tabs
                                value={this.state.tabIndex}
                                indicatorColor="secondary"
                                textColor="secondary"
                                onChange={
                                    (event, index) => {
                                        this.setState({ tabIndex: index });
                                        this.props.getPageTitle(`Settings | ${this.state.systemHeaders[index]}`);
                                    }
                                }
                                aria-label="dataset tabs"
                                variant="scrollable"
                                scrollButtons="on"
                            >
                                {
                                    this.state.systemHeaders.map((name, index) =>
                                        <Tab key={index} label={name} />
                                    )
                                }
                            </Tabs> :
                            <Tabs
                                value={this.state.organizationTabIndex}
                                indicatorColor="secondary"
                                textColor="secondary"
                                onChange={
                                    (event, index) => {
                                        this.setState({ organizationTabIndex: index });
                                        if (this.state.breadcrumb[this.state.breadcrumb.length - 1].name === 'Edit Role') {
                                            const breadcrumb = this.state.breadcrumb;
                                            breadcrumb.pop();
                                            this.setState({ breadcrumb: breadcrumb, roleConfiguration: false });
                                        }
                                        this.setState({ tabIndex: index });
                                        this.props.getPageTitle(`Settings | ${this.state.breadcrumb.map((elem) => elem.name).join(' | ')} | ${this.state.organizationHeaders[index]}`);
                                    }
                                }
                                aria-label="dataset tabs"
                                variant="scrollable"
                                scrollButtons="on">
                                {
                                    this.state.organizationHeaders.map((name, index) =>
                                        <Tab key={index} label={name} disabled={name === "General" ? false : Boolean(this.state.organizationId === 0)} />
                                    )
                                }
                            </Tabs>
                    }
                </Grid>
                {
                    !this.state.isOrganization ?
                        <Grid className={classNames(classes.container, classes.relative)}>
                            {
                                this.state.tabIndex === this.getTabIndex("Profile") &&
                                <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Profile")}>
                                    <MyProfile />
                                </TabPanel>
                            }
                            {
                                this.state.tabIndex === this.getTabIndex("Organization") &&
                                <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Organization")}>
                                    <OrganizationList onChangeOrganization={(type, data) => this.onChangeOrganization(type, data)} isSystemLicense={this.props.isSystemLicense} />
                                </TabPanel>
                            }
                            {
                                this.state.tabIndex === this.getTabIndex("System") &&
                                <TabPanel value={this.state.tabIndex} index={this.getTabIndex("System")}>
                                    <System organizationId={this.state.organizationId} isOrganization={this.state.isOrganization} navigateTab={this.state.navigateTab} isSystemLicense={this.props.isSystemLicense} history={history} />
                                </TabPanel>
                            }
                            {
                                this.state.tabIndex === this.getTabIndex("Configurations") &&
                                <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Configurations")}>
                                    <Configuration organizationId={this.state.organizationId} navigateTab={this.state.navigateTab} isSystem isSystemLicense={this.props.isSystemLicense} />
                                </TabPanel>
                            }
                            {
                                this.state.tabIndex === this.getTabIndex("Libraries") &&
                                <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Libraries")}>
                                    <Reference organizationId={this.state.organizationId} />
                                </TabPanel>
                            }
                            {
                                this.state.tabIndex === this.getTabIndex("Theme") &&
                                <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Theme")}>
                                    <Theme organizationId={this.state.organizationId} organization={this.state.isOrganization} />
                                </TabPanel>
                            }
                            {
                                this.state.tabIndex === this.getTabIndex("Enrichment") &&
                                <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Enrichment")}>
                                    {
                                        this.state.enrichmentDetailView ?
                                            <EnrichmentDetail type="settingsPanel" organizationId={this.state.organizationId} enrichmentId={this.state.enrichmentId} onChangeEnrichment={(type, data) => this.onChangeEnrichment(type, data)} /> :
                                            <EnrichmentList organizationId={this.state.organizationId} onChangeEnrichment={(type, data) => this.onChangeEnrichment(type, data)} />
                                    }
                                </TabPanel>
                            }
                            {
                                this.state.tabIndex === this.getTabIndex("Logging") &&
                                <TabPanel value={this.state.tabIndex} index={this.getTabIndex("Logging")}>
                                    <Logging />
                                </TabPanel>
                            }
                            {
                                this.state.tabIndex === this.getTabIndex("RunningJobs") &&
                                <TabPanel value={this.state.tabIndex} index={this.getTabIndex("RunningJobs")}>
                                    <RunningJobs />
                                </TabPanel>
                            }
                            {
                                this.state.tabIndex === this.getTabIndex("LivyJobs") &&
                                <TabPanel value={this.state.tabIndex} index={this.getTabIndex("LivyJobs")}>
                                    <LivyJobs />
                                </TabPanel>
                            }
                            {
                                this.state.tabIndex === this.getTabIndex("ScheduleDataset") &&
                                <TabPanel value={this.state.tabIndex} index={this.getTabIndex("ScheduleDataset")}>
                                    <ScheduleDatasets />
                                </TabPanel>
                            }
                        </Grid > :
                        <Grid className={classNames(classes.container, classes.relative)}>
                            {
                                this.state.organizationTabIndex === this.getTabIndex("Profile") &&
                                <TabPanel value={this.state.organizationTabIndex} index={this.getTabIndex("Profile")}>
                                    <Profile organizationId={this.state.organizationId} />
                                </TabPanel>
                            }
                            {
                                this.state.organizationTabIndex === this.getTabIndex("General") &&
                                <TabPanel value={this.state.organizationTabIndex} index={this.getTabIndex("General")}>
                                    <OrganizationDetail type="settingsPanel" organizationId={this.state.organizationId} setOrganizationId={(data) => this.setOrganizationId(data)} />
                                </TabPanel>
                            }
                            {
                                this.state.organizationTabIndex === this.getTabIndex("System") &&
                                <TabPanel value={this.state.organizationTabIndex} index={this.getTabIndex("System")}>
                                    <System organizationId={this.state.organizationId} user={"organization"} navigateTab={this.state.navigateTab} isOrganization={this.state.isOrganization} isSystemLicense={this.props.isSystemLicense} history={history} />
                                </TabPanel>
                            }
                            {
                                this.state.organizationTabIndex === this.getTabIndex("Configurations") &&
                                <TabPanel value={this.state.organizationTabIndex} index={this.getTabIndex("Configurations")}>
                                    <Configuration organizationId={this.state.organizationId} isSystemLicense={this.props.isSystemLicense} />
                                </TabPanel>
                            }
                            {
                                this.state.organizationTabIndex === this.getTabIndex("Theme") &&
                                <TabPanel value={this.state.organizationTabIndex} index={this.getTabIndex("Theme")}>
                                    <Theme type="settingsPanel" organizationId={this.state.organizationId} isOrganization={this.state.isOrganization} />
                                </TabPanel>
                            }
                            {
                                this.state.organizationTabIndex === this.getTabIndex("Libraries") &&
                                <TabPanel value={this.state.organizationTabIndex} index={this.getTabIndex("Libraries")}>
                                    <Reference type="settingsPanel" organizationId={this.state.organizationId} />
                                </TabPanel>
                            }
                            {
                                this.state.organizationTabIndex === this.getTabIndex("Users") &&
                                <TabPanel value={this.state.organizationTabIndex} index={this.getTabIndex("Users")}>
                                    <Users type="settingsPanel" organizationId={this.state.organizationId} history={this.props.history} />
                                </TabPanel>
                            }
                            {
                                this.state.organizationTabIndex === this.getTabIndex("Enrichment") &&
                                <TabPanel value={this.state.organizationTabIndex} index={this.getTabIndex("Enrichment")}>
                                    {
                                        this.state.enrichmentDetailView ?
                                            <EnrichmentDetail type="settingsPanel" organizationId={this.state.organizationId} enrichmentId={this.state.enrichmentId} onChangeEnrichment={(type, data) => this.onChangeEnrichment(type, data)} /> :
                                            <EnrichmentList organizationId={this.state.organizationId} onChangeEnrichment={(type, data) => this.onChangeEnrichment(type, data)} />
                                    }
                                </TabPanel>
                            }
                            {
                                this.state.organizationTabIndex === this.getTabIndex("Roles") &&
                                <TabPanel value={this.state.organizationTabIndex} index={this.getTabIndex("Roles")}>
                                    <Role
                                        isConfiguration={this.state.roleConfiguration}
                                        organizationId={this.state.organizationId}
                                        onEditConfig={(type) => this.onEditRoleConfig(type)}
                                        onClose={() => this.onCloseRoleEdit()} />
                                </TabPanel>
                            }
                            {
                                this.state.organizationTabIndex === this.getTabIndex("Logging") &&
                                <TabPanel value={this.state.organizationTabIndex} index={this.getTabIndex("Logging")}>
                                    <Logging />
                                </TabPanel>
                            }
                            {
                                this.state.organizationTabIndex === this.getTabIndex("Reports") &&
                                <TabPanel value={this.state.organizationTabIndex} index={this.getTabIndex("Reports")}>
                                    <Report
                                        organizationId={this.state.organizationId}
                                        isConfiguration={this.state.reportConfiguration}
                                        onEditConfig={(type) => this.onEditReportConfig(type)}
                                        onClose={() => this.onCloseReportEdit()}
                                    />
                                </TabPanel>
                            }
                        </Grid >
                }
            </Grid>
        );
    }
}

Settings.propTypes = {
    classes: PropTypes.object,
    getModuleConnectionTypes: PropTypes.func,
    location: PropTypes.object,
    getSystemLicenseStatus: PropTypes.object,
    config: PropTypes.object,
    isSystemLicense: PropTypes.bool,
    history: PropTypes.object,
    getPageTitle: PropTypes.func
};

const mapStateToProps = ({ account, setting }) => {
    return {
        ...account,
        ...setting
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getModuleConnectionTypes,
        getSystemLicenseStatus
    }, dispatch);

};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles((theme) => ({
    ...SettingStyles(theme),
    ...Styles(theme)
}))(Settings));