import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { Grid, Typography, IconButton, withStyles, Popover, MenuItem, Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TreeView, TreeItem } from '@material-ui/lab';
import fileDownload from 'js-file-download';
import Styles from '../../layouts/Styles.jsx';
import SemanticGlossaryStyles from './SemanticGlossaryStyles.jsx';
import classnames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import GlossaryIcon from '../../assets/images/category.svg';
import CategoryIcon from '../../assets/images/folder_black.svg';
import TermIcon from '../../assets/images/term.svg';
import { getSemanticModelGlossary, updateSemanticModel, deleteModel, deleteAttributes, createSemanticGlossary, updateAttributes, selectGlossary, exportModel } from '../../actions/modelActions';
import { appConstants } from '../../constants/appConstants.js';
import AddSemanticGlossary from './AddSemanticGlossary.jsx';
import SemanticGlossaryDetail from './SemanticGlossaryDetail.jsx';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AlertDialog from '../AlertDialog/AlertDialog.jsx';
import NoResultFound from '../NoResultFound/NoResultFound.jsx';
import Loader from '../Loaders/Loader.jsx';
import { getOrganizationAttributeList } from '../../actions/datasetActions';
import { sortArrayObjectString } from '../../helpers/appHelpers.js';

const SemanticGlossary = (props) => {
    const { classes, filters: { search = '', sort = '', type = '', status = '', tags = [] } } = props;
    const [glossaries, setGlossaries] = useState([]);
    const [isAdd, setAdd] = useState(false);
    const [selectedGlossary, setSelectedGlossary] = useState({});
    const [glossaryDetail, setGlossaryDetail] = useState({});
    const [glossaryType, setGlossaryType] = useState('category');
    const [optionsElement, setOptionsElement] = useState(null);
    const [expandedNodes, setExpandedNodes] = useState([]);
    const [expand, setExpand] = useState(false);
    const [glossary, setGlossary] = useState({ status: appConstants.glossaryStatus[1].value });
    const [glossaryNames, setGlossaryNames] = useState([]);
    const [isEnableDeleteDialog, setDeleteDialog] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [createSemanticLoading, setCreateLoading] = useState(false);
    const [attributes, setAttributes] = useState([]);
    const crumbs = useSelector(({ model }) => model.breadCrumbs);

    const dispatch = useDispatch();

    const onChangeSearch = () => {
        if (search.length !== 0) {
            if (glossaryDetail.semantic_type === "field" && !glossaryDetail.name.toLowerCase().includes(search.toLowerCase())) {
                dispatch(selectGlossary({}));
            }
        }
    };

    const onSelectGlossary = useCallback((glossary) => {
        dispatch(selectGlossary(glossary));
        setGlossaryDetail({ ...glossary });
    }, [dispatch]);

    const onChangeGlossary = (property, value) => {
        glossary[property] = value;
        if (property === "name") {
            glossary.type = value;
        }
        setGlossary({ ...glossary });
    };

    const onChangeGlossaryDetail = (property, value) => {
        glossaryDetail[property] = value;
        if (property === "name") {
            glossaryDetail.type = value;
        }
        setGlossaryDetail({ ...glossaryDetail });
    };

    const onUpdateGlossaryDetail = (property) => {
        const requestParams = {
            [property]: glossaryDetail[property]
        };
        if (property === "name" && glossaryDetail.semantic_type !== "glossary") {
            requestParams.type = glossaryDetail[property];
        }
        const updateMethod = glossaryDetail.semantic_type === "glossary" ? updateSemanticModel(glossaryDetail.id, requestParams) : updateAttributes(glossaryDetail.id, requestParams);
        dispatch(updateMethod).then(() => {
            getGlossaries("update");
        });
    };

    const redirect = (glossary) => {
        onSelectGlossary({ ...glossary });
    };

    const cancelCreateGlossary = () => {
        setAdd(false);
        setGlossary({});
    };

    const createGlossary = () => {
        const requestParams = {
            ...glossary,
            status: glossary.status ? glossary.status : appConstants.glossaryStatus[1].value,
            'semantic_type': glossaryType,
            'enum': { values: [] },
            'keyword': { "equals": [], "contains": [] }
        };
        setCreateLoading(true);
        dispatch(createSemanticGlossary(requestParams)).then((response) => {
            if (response) {
                getGlossaries("update");
                onSelectGlossary({ ...response });
                expandedNodes.unshift(response.semantic_model.toString());
                if (response.semantic_link_id) {
                    expandedNodes.unshift(response.semantic_link_id.toString());
                }
                expandedNodes.unshift(response.id.toString());
                setExpandedNodes([...expandedNodes]);
                setAdd(false);
                setGlossary({ status: appConstants.glossaryStatus[1].value });
            }
            setCreateLoading(false);
        });
    };

    const addGlossary = (type) => {
        setOptionsElement(null);
        setAdd(true);
        setGlossaryType(type);
        glossary['semantic_link_id'] = selectedGlossary.semantic_type === "glossary" ? null : selectedGlossary.id;
        glossary['semantic_model_id'] = selectedGlossary.semantic_type === "glossary" ? selectedGlossary.id : selectedGlossary.semantic_model_id;
        setGlossary({ ...glossary });
    };

    const deleteGlossary = () => {
        const method = selectedGlossary.semantic_type === "glossary" ? deleteModel(selectedGlossary.id) : deleteAttributes(selectedGlossary.semantic_model_id, selectedGlossary.id);
        dispatch(method).then(() => {
            if (selectedGlossary.id === glossaryDetail.id && selectedGlossary.semantic_type !== "glossary") {
                crumbs.pop();
                onSelectGlossary({ ...crumbs[crumbs.length - 1] });
            }
            getGlossaries(selectedGlossary.semantic_type === "glossary" && selectedGlossary.id === glossaryDetail.id ? "initial" : "update");
        });
        setSelectedGlossary({});
        setOptionsElement(null);
    };

    const findSubCategories = useCallback((category, categories, fields) => {
        const subCategoryList = categories.filter((subCategory) => subCategory.semantic_link_id === category.id);
        const termList = fields.filter((field) => field.semantic_link_id === category.id);
        for (const subCategory of subCategoryList) {
            const index = categories.findIndex((r) => r.id === subCategory.id);
            if (index !== -1) {
                categories.splice(index, 1);
            }
            findSubCategories(subCategory, categories, fields);
        }
        category.children = [...subCategoryList, ...termList];
    }, []);

    const prepareGlossary = useCallback((semanticGlossaries) => {
        let glossaries = semanticGlossaries.filter((subCategory) => subCategory.semantic_type.toLowerCase() === "glossary");
        const categories = semanticGlossaries.filter((subCategory) => subCategory.semantic_type.toLowerCase() === "category");
        const fields = semanticGlossaries.filter((field) => field.semantic_type.toLowerCase() === "field");
        for (const category of categories) {
            findSubCategories(category, categories, fields);
        }
        glossaries = glossaries.map((glossary) => {
            const filterCategories = categories.filter((category) => category.semantic_model_id === glossary.id);
            const filterFields = fields.filter((field) => field.semantic_model_id === glossary.id && !field.semantic_link_id);
            return {
                ...glossary,
                children: [...filterCategories, ...filterFields]
            };
        });
        setGlossaries([...glossaries]);
    }, [findSubCategories]);

    const getGlossaries = useCallback((type) => {
        if (type === "initial") {
            setLoading(true);
        }
        dispatch(getSemanticModelGlossary()).then((response) => {
            if (response) {
                const semanticModels = response.models ? response.models.map((model) => {
                    return {
                        ...model,
                        'semantic_type': 'glossary'
                    };
                }) : [];
                if (type === "initial") {
                    onSelectGlossary({ ...semanticModels[0] });
                }
                const glossaryList = response.glossary ? response.glossary : [];
                const glossaryNameList = [...glossaryList, ...semanticModels].map((data) => data.name);
                if (glossaryNameList.length === 0 && type === "initial") {
                    setExpand(true);
                }
                setGlossaryNames([...glossaryNameList]);
                prepareGlossary([...semanticModels, ...glossaryList]);
            }
            setLoading(false);
        });
    }, [dispatch, onSelectGlossary, prepareGlossary]);


    useEffect(() => {
        getGlossaries("initial");
    }, [getGlossaries]);

    const getAttributes = useCallback(() => {
        dispatch(getOrganizationAttributeList()).then((response) => {
            if (response) {
                setAttributes([...response]);
            }
        });
    }, [dispatch]);

    useEffect(() => {
        getAttributes();
    }, [getAttributes]);

    const exportGlossary = (glossary) => {
        setSelectedGlossary({});
        setOptionsElement(null);
        const requestParams = {
            'model_id': glossary.id
        };
        dispatch(exportModel(requestParams)).then((response) => {
            if (response) {
                fileDownload(JSON.stringify(response), 'semantic.json');
            }
        });
    };


    const dfs = (node) => {
        // Implement your search functionality
        let isMatching = true;

        if (search && search.trim()) {
            isMatching = isMatching && node.name && node.name.toLowerCase().includes(search.trim().toLowerCase());
        }
        if (type) {
            isMatching = isMatching && node.semantic_type && node.semantic_type.toLowerCase() === type.toLowerCase();
        }
        if (status) {
            isMatching = isMatching && node.status && node.status.toLowerCase() === status.toLowerCase();
        }
        if (tags && tags.length > 0) {
            isMatching = isMatching && node.tags && node.tags.some((elem) => tags.includes(elem));
        }
        if (Array.isArray(node.children)) {
            node.children.forEach((child) => {
                const hasMatchingChild = dfs(child);
                isMatching = isMatching || hasMatchingChild;
            });
        }
        return isMatching;
    };

    const filter = (data) => {
        if (sort) {
            const sortArray = sort.split('_');
            return data
                .filter((item) => dfs(item))
                .map((item) => ({
                    ...item,
                    children: item.children ? sortArrayObjectString(filter(item.children), sortArray[0], sortArray[1]) : []
                }));
        }
        return data
            .filter((item) => dfs(item))
            .map((item) => ({
                ...item,
                children: item.children ? filter(item.children) : []
            }));
    };

    const searchFunc = () => {
        // We wrap data in an object to match the node shape

        // filter the original data so that only matching items (and their fathers if they have) are returned
        if (sort) {
            const sortArray = sort.split('_');
            return sortArrayObjectString(filter(glossaries), sortArray[0], sortArray[1]);
        }
        return filter(glossaries);
    };

    const glossaryList = searchFunc(search.trim());
    onChangeSearch();
    const renderTree = (node) => {
        return (
            <TreeItem nodeId={`${node.semantic_type}_${node.id.toString()}`}
                key={node.id}
                label={
                    <Grid className={classnames(classes.glossaryTreeItem, "optionsContainer")} onClick={(event) => { onSelectGlossary(node); cancelCreateGlossary(); }}>
                        {node.semantic_type.toLowerCase() === "glossary" && <img src={GlossaryIcon} alt="" />}
                        {node.semantic_type.toLowerCase() === "category" && <img src={CategoryIcon} alt="" />}
                        {node.semantic_type.toLowerCase() === "field" && <img src={TermIcon} alt="" />}
                        <Typography className={classes.marginLeft5}>
                            {node.name}
                        </Typography>
                        <IconButton className="options-btn" onClick={(event) => { event.stopPropagation(); setSelectedGlossary(node); setOptionsElement(event.target); }}>
                            <MoreHorizIcon />
                        </IconButton>
                    </Grid>
                }>
                {Array.isArray(node.children) ? node.children.map((child, childIndex) => renderTree(child, childIndex)) : null}
            </TreeItem>
        );
    };

    return (
        <Grid container spacing={4} className={classnames(classes.container, classes.relative)}>
            <Grid item xs={3} className={expand ? classes.leftSectionExpand : classes.leftSection}>
                <Grid className={classnames(!expand && classes.semanticLeftSectionActive, classes.semanticLeftSection)}>
                    <Grid align="right" className={`${classes.arrowIconContainer} arrowIconContainer`}>
                        <IconButton align="right" className={classnames(classes.nopadding, classes.arrowIconBtn)} onClick={() => setExpand(!expand)}>
                            {expand ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </Grid>
                    {
                        !expand &&
                        <Grid className={classes.glossaryTree}>
                            <TreeView
                                className={classes.root}
                                expanded={expandedNodes}
                                onNodeToggle={(event, nodeIds) => setExpandedNodes(nodeIds)}
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                            >
                                {
                                    glossaryList.map((node, index) =>
                                        renderTree(node, index)
                                    )
                                }
                            </TreeView>
                        </Grid>
                    }
                </Grid>
            </Grid>
            <Grid item xs={9} className={classnames(expand ? classes.rightSectionExpand : classes.rightSection)}>
                {
                    glossaries.length !== 0 ?
                        <Grid className={classes.section}>
                            {
                                isAdd ?
                                    <AddSemanticGlossary
                                        type={glossaryType}
                                        glossary={glossary}
                                        onChange={(property, value) => onChangeGlossary(property, value)}
                                        onSave={() => createGlossary()}
                                        onCancel={() => cancelCreateGlossary()}
                                        glossaryNames={glossaryNames}
                                        loading={createSemanticLoading}
                                    /> :
                                    <SemanticGlossaryDetail
                                        glossaryDetail={{ ...glossaryDetail }}
                                        onChangeGlossary={(property, value) => onChangeGlossaryDetail(property, value)}
                                        onSave={(property) => onUpdateGlossaryDetail(property)}
                                        redirect={(glossary) => redirect(glossary)}
                                        glossaryNames={glossaryNames}
                                        filters={props.filters}
                                        attributes={attributes}
                                    />
                            }
                        </Grid> :
                        <Grid className={classes.container} container justify="center" alignItems="center" alignContent="center">
                            {
                                !isLoading &&
                                <Grid item className={classes.marginTop30}>
                                    <NoResultFound />
                                </Grid>
                            }
                        </Grid>
                }
                {
                    isLoading &&
                    <Loader />
                }
            </Grid>

            {
                optionsElement &&
                <Popover
                    open={Boolean(optionsElement)}
                    onClose={() => setOptionsElement(null)}
                    anchorEl={optionsElement}
                    anchorOrigin={
                        {
                            vertical: 'center',
                            horizontal: 'right'
                        }
                    }
                    transformOrigin={
                        {
                            vertical: 'bottom',
                            horizontal: 'left'
                        }
                    }>
                    {
                        selectedGlossary && selectedGlossary.semantic_type !== "field" &&
                        <Fragment>
                            <MenuItem className={classes.glossaryMenu}>
                                <Typography className={classes.logoutTxt} onClick={(event) => { event.stopPropagation(); addGlossary("category"); }}>
                                    {'+   Add Category'}
                                </Typography>
                            </MenuItem>
                            <MenuItem className={classes.glossaryMenu}>
                                <Typography className={classes.logoutTxt} onClick={(event) => { event.stopPropagation(); addGlossary("field"); }}>
                                    {'+   Add Term'}
                                </Typography>
                            </MenuItem>
                            {
                                selectedGlossary && selectedGlossary.semantic_type === "glossary" &&
                                <MenuItem className={classes.glossaryMenu}>
                                    <Typography className={classes.logoutTxt} onClick={(event) => { event.stopPropagation(); exportGlossary(selectedGlossary); }}>
                                        {'Export Glossary'}
                                    </Typography>
                                </MenuItem>
                            }
                            <Divider />
                        </Fragment>
                    }

                    <MenuItem className={classes.glossaryMenu}>
                        <Typography className={classes.deleteText} onClick={(event) => { event.stopPropagation(); setOptionsElement(null); setDeleteDialog(true); }}>
                            {'Delete this item'}
                        </Typography>
                    </MenuItem>
                </Popover>
            }

            <AlertDialog title={`Delete ${selectedGlossary.semantic_type}`}
                message={`Are you sure you want to delete this ${selectedGlossary.semantic_type}? This process cannot be undone.`}
                okButtonText="OK"
                cancelButtonText={isEnableDeleteDialog ? "Cancel" : ""}
                show={isEnableDeleteDialog}
                onClickOK={
                    () => {
                        if (isEnableDeleteDialog) {
                            setDeleteDialog(false);
                            deleteGlossary();
                        } else {
                            setDeleteDialog(false);
                        }
                    }
                }
                onClickCancel={() => setDeleteDialog(false)} />
        </Grid>
    );

};

SemanticGlossary.propTypes = {
    classes: PropTypes.object,
    filters: PropTypes.object
};

export default withStyles((theme) => ({
    ...SemanticGlossaryStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SemanticGlossary);