import React, { useEffect, useCallback, useState } from 'react';
import { withStyles, Grid } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import ChartContainer from '../ChartContainer/ChartContainer.jsx';
import LineCurveChart from '../Charts/LineCurveChart.jsx';
import { appConstants } from '../../constants/appConstants';
import { getDomainTimeline } from '../../actions/domainActions';
import DomainStyles from './DomainStyles.jsx';
import Styles from '../../layouts/Styles.jsx';
import noprofile from '../../assets/images/np_profile_data.jpg';

const DomainSummary = (props) => {
    const { classes, domainId } = props;
    const [analysis, setAnalysis] = useState([]);
    const [fullscreen, setFullscreen] = useState(false);
    const dispatch = useDispatch();

    const enableFullScreen = (value) => {
        setFullscreen(value);
    };

    const getTimelineAnalysis = useCallback(() => {
        dispatch(getDomainTimeline(domainId)).then((response) => {
            if (response) {
                const timelineAnalysis = [];
                for (const analysis of response) {
                    timelineAnalysis.push({
                        date: analysis.dqscore_analyis && analysis.dqscore_analyis.date ? new Date(analysis.dqscore_analyis.date) : new Date(),
                        count: analysis.dqscore_analyis && analysis.dqscore_analyis.row_count ? analysis.dqscore_analyis.row_count : 0,
                        score: analysis.dqscore_analyis && analysis.dqscore_analyis.dqscore ? analysis.dqscore_analyis.dqscore : 0
                    });
                }
                setAnalysis([...timelineAnalysis]);
            }
        });
    }, [dispatch, domainId]);

    useEffect(() => {
        getTimelineAnalysis();
    }, [getTimelineAnalysis]);


    const getChartHeight = () => {
        if (!fullscreen) {
            if (window.innerWidth > 1600) {
                return 200;
            } else if (window.innerWidth < 1600 && window.innerWidth >= 1400) {
                return 140;
            }
            return 100;
        }
        return 300;
    };

    return (
        <Grid className={classNames(classes.timelineContainer, fullscreen ? classes.fullScreen : null)}>
            <ChartContainer
                title={appConstants.charts.domainTimeline.name}
                id={appConstants.charts.domainTimeline.id}
                fullScreenProperties={
                    {
                        enable: fullscreen,
                        onEdit: enableFullScreen
                    }
                }
                chartData={analysis}>
                <Grid className={classes.marginTop15}>
                    {
                        analysis && analysis.length > 0 ?
                            <LineCurveChart
                                chartHeight={getChartHeight()}
                                chartClassName="domain-timeline"
                                analysis={analysis}
                                curveColor="#f295b6"
                                barColor="#b9def7"
                                fullscreen={fullscreen}
                                showYAxis
                                yAxisText="DQScore %"
                                legends={[{ name: 'DQScore', color: '#f295b6' }, { name: 'Row Count', color: '#b9def7' }]}

                            /> :
                            <Grid align="center" className={classes.noDataContainer}>
                                <img src={noprofile} alt="" className={classes.noDataImg} />
                            </Grid>
                    }
                </Grid>
            </ChartContainer>
        </Grid>
    );
};

DomainSummary.propTypes = {
    classes: PropTypes.object,
    domainId: PropTypes.number
};

export default withStyles((theme) => ({
    ...DomainStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(DomainSummary);