import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, withStyles, Dialog } from '@material-ui/core';
import classNames from 'classnames';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import Filetype from '../../assets/images/logo-sql-server.png';
import datasourceTypes from '../../config/datasourceTypes';
import Styles from '../../layouts/Styles.jsx';
import SearchStyles from './SearchStyles.jsx';
import Loader from '../../components/Loaders/Loader.jsx';
import UserList from '../../components/DatasourceList/UserList.jsx';
import ToolTipComponent from '../../components/Tooltip/Tooltip.jsx';
import Circular from '../../components/Charts/Circular.jsx';
import SkeletonLoader from '../../components/SkeletonLoader/SkeletonLoader.jsx';
import { getValue } from '../../helpers/appHelpers';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


const SearchResultComponent = (props) => {
    const { classes, theme, searchResults, isLoading, isLazyLoading, onScrollChange, redirectToSelectedItem, getRedirectToSelectedItemLink } = props;

    const dq_score_threshold = useSelector(({ setting }) => setting.user_config.dq_score_threshold);

    /**
     *
     * @param {*} sourceType
     * @returns
     */
    const getIcon = (sourceType) => {
        let icon;
        if (sourceType) {
            icon = datasourceTypes.find((p) => p.type.toLowerCase() === sourceType.toLowerCase());
        }
        if (icon && icon.logo && typeof (icon.logo) === 'object') {
            return icon.logo.default;
        }
        return icon ? icon.logo : Filetype;
    };

    /**
     *
     * @param {*} event
     */
    const onScroll = (event) => {
        if (event.target.scrollTop + event.target.clientHeight + 55 >= event.target.scrollHeight && onScrollChange && !isLazyLoading) {
            onScrollChange();
        }
    };

    const datasourceIco =
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 11.289 15.394" fill={theme.palette.primary.main} style={{ flexShrink: '0' }}>
            <path id="database_17_" data-name="database (17)" d="M15.645,17.394c2.805,0,5.645-.793,5.645-2.309V4.309C21.289,2.793,18.45,2,15.645,2S10,2.793,10,4.309V15.085C10,16.6,12.839,17.394,15.645,17.394Zm0-14.368c2.865,0,4.618.831,4.618,1.283s-1.753,1.283-4.618,1.283-4.618-.831-4.618-1.283S12.78,3.026,15.645,3.026ZM11.026,5.695a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924V7.9c0,.452-1.753,1.283-4.618,1.283S11.026,8.353,11.026,7.9Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Zm0,3.592a9.555,9.555,0,0,0,4.618.924,9.555,9.555,0,0,0,4.618-.924v2.206c0,.452-1.753,1.283-4.618,1.283s-4.618-.831-4.618-1.283Z" transform="translate(-10 -2)" fill={theme.palette.primary.main} />
        </svg>;

    const datasetIco =
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17.751 16.711" style={{ flexShrink: '0' }}>
            <path id="table" d="M0,15V31.711H17.751V15Zm16.711,1.04v2.115H1.04V16.04ZM9.4,22.315h3.12V24.4H9.4ZM8.355,24.4H5.235v-2.08h3.12Zm1.04-3.12V19.2h3.12v2.08Zm-1.04,0H5.235V19.2h3.12Zm-4.16,0H1.04V19.2H4.2Zm0,1.04V24.4H1.04v-2.08Zm0,3.12v2.08H1.04v-2.08Zm1.04,0h3.12v2.08H5.235Zm3.12,3.12v2.115H5.235V28.556Zm1.04,0h3.12v2.115H9.4Zm0-1.04v-2.08h3.12v2.08Zm4.16-2.08h3.155v2.08H13.556Zm0-1.04v-2.08h3.155V24.4Zm0-3.12V19.2h3.155v2.08ZM1.04,28.556H4.2v2.115H1.04Zm12.516,2.115V28.556h3.155v2.115Z" transform="translate(0 -15)" fill={theme.palette.primary.main} />
        </svg>;

    const attributesIco =
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 17.773 16.732" style={{ flexShrink: '0' }}>
            <path id="table" d="M0,15V31.732H17.773V15Zm16.732,1.041v2.118h-7.3l.024-2.118H4.265v2.118H1.041V16.041H16.732ZM9.407,22.325h3.124v2.083H9.407Zm0-1.041V19.2h3.124v2.083Zm-5.207,0H1.041V19.2H4.2Zm0,1.041v2.083H1.041V22.325Zm0,3.124v2.083H1.041V25.449Zm5.207,3.124h3.124v2.118H9.407Zm0-1.041V25.449h3.124v2.083Zm4.166-2.083h3.159v2.083H13.573Zm0-1.041V22.325h3.159v2.083Zm0-3.124V19.2h3.159v2.083ZM1.041,28.573H4.2v2.118H1.041Zm12.532,2.118V28.573h3.159v2.118Z" transform="translate(0 -15)" fill={theme.palette.primary.main} />
        </svg>;

    const domainsIco =
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16.068 18.554" style={{ flexShrink: '0' }}>
            <g id="rubik" transform="translate(-34.297)">
                <g id="Group_3147" data-name="Group 3147" transform="translate(34.297)">
                    <path id="Path_1474" data-name="Path 1474" d="M42.331,0,34.3,4.639v9.277l8.034,4.639,8.034-4.639V4.639Zm0,1.259,2.655,1.533L42.331,4.324,39.677,2.791Zm-4.29,13.56-2.655-1.533V10.221l2.655,1.533Zm0-4.324L35.387,8.962V5.9L38.042,7.43Zm-2.11-5.542,2.655-1.533,2.655,1.533L38.587,6.486Zm5.854,12.028-2.655-1.533V12.383l2.655,1.533Zm0-4.324-2.655-1.533V8.059l2.655,1.533Zm-2.11-5.542,2.655-1.533,2.655,1.533L42.331,8.648Zm5.854,8.333-2.655,1.533V13.916l2.655-1.533Zm0-4.324-2.655,1.533V9.592l2.655-1.533Zm-2.11-6.171,2.655-1.533L48.73,4.953,46.076,6.486Zm5.854,8.333-2.655,1.533V11.754l2.655-1.533Zm0-4.324L46.621,10.5V7.43L49.275,5.9Z" transform="translate(-34.297)" fill={theme.palette.primary.main} />
                </g>
            </g>
        </svg>;

    const commentsIco =
        <svg id="chat-comment-oval-speech-bubble-with-text-lines" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 22.576 22.576" style={{ flexShrink: '0' }}>
            <g id="_x32__26_">
                <g id="Group_161282" data-name="Group 161282">
                    <path id="Path_2725" data-name="Path 2725" d="M14.816,11.994H7.761a.706.706,0,0,0,0,1.411h7.055a.706.706,0,0,0,0-1.411Zm1.411-4.233H6.35a.706.706,0,0,0,0,1.411h9.877a.706.706,0,0,0,0-1.411ZM11.288,0C5.054,0,0,4.422,0,9.877a9.454,9.454,0,0,0,4.233,7.7v5l4.945-3a12.945,12.945,0,0,0,2.11.179c6.235,0,11.288-4.422,11.288-9.877S17.523,0,11.288,0Zm0,18.343A11.417,11.417,0,0,1,8.9,18.084l-3.322,2,.044-3.272A8.18,8.18,0,0,1,1.411,9.877c0-4.675,4.422-8.466,9.877-8.466S21.165,5.2,21.165,9.877,16.743,18.343,11.288,18.343Z" fill={theme.palette.primary.main} />
                </g>
            </g>
        </svg>;


    const workbookIco =
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18.545 22.236">
            <g id="notebook" transform="translate(-43.221 0.25)">
                <path id="Path_2643" data-name="Path 2643" d="M59.493,0H45.936A1.148,1.148,0,0,0,44.79,1.146V2.752h-.682a.637.637,0,0,0,0,1.274h.682V6.491h-.682a.637.637,0,0,0,0,1.274h.682v2.466h-.682a.637.637,0,0,0,0,1.274h.682v2.466h-.682a.637.637,0,0,0,0,1.274h.682V17.71h-.682a.637.637,0,0,0,0,1.274h.682V20.59a1.148,1.148,0,0,0,1.146,1.146H59.493a2.025,2.025,0,0,0,2.023-2.023V2.023A2.025,2.025,0,0,0,59.493,0Zm.749,19.713a.75.75,0,0,1-.749.749H46.063V18.984h.682a.637.637,0,1,0,0-1.274h-.682V15.244h.682a.637.637,0,1,0,0-1.274h-.682V11.5h.682a.637.637,0,1,0,0-1.274h-.682V7.765h.682a.637.637,0,1,0,0-1.274h-.682V4.025h.682a.637.637,0,1,0,0-1.274h-.682V1.274h13.43a.75.75,0,0,1,.749.749Z" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="0.5" />
                <path id="Path_2644" data-name="Path 2644" d="M295.683,417.179h-3.038a.637.637,0,1,0,0,1.274h3.038a.637.637,0,0,0,0-1.274Z" transform="translate(-237.987 -399.469)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} strokeWidth="0.5" />
            </g>
        </svg>;


    const worksheetIco =
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20.66 20.661">
            <g id="dashboard_2" transform="translate(-8203 -2812)">
                <path id="Rectangle_13" data-name="Rectangle 13" d="M2.22,1.727a.494.494,0,0,0-.493.493V7.586a.494.494,0,0,0,.493.493H7.586a.494.494,0,0,0,.493-.493V2.22a.494.494,0,0,0-.493-.493H2.22M2.22,0H7.586a2.22,2.22,0,0,1,2.22,2.22V7.586a2.22,2.22,0,0,1-2.22,2.22H2.22A2.22,2.22,0,0,1,0,7.586V2.22A2.22,2.22,0,0,1,2.22,0Z" transform="translate(8203 2812)" fill={theme.palette.primary.main} />
                <path id="Rectangle_15" data-name="Rectangle 15" d="M2.22,1.727a.494.494,0,0,0-.493.493V7.586a.494.494,0,0,0,.493.493H7.586a.494.494,0,0,0,.493-.493V2.22a.494.494,0,0,0-.493-.493H2.22M2.22,0H7.586a2.22,2.22,0,0,1,2.22,2.22V7.586a2.22,2.22,0,0,1-2.22,2.22H2.22A2.22,2.22,0,0,1,0,7.586V2.22A2.22,2.22,0,0,1,2.22,0Z" transform="translate(8203 2822.854)" fill={theme.palette.primary.main} />
                <path id="Rectangle_16" data-name="Rectangle 16" d="M4.9,1.727A3.176,3.176,0,1,0,8.079,4.9,3.18,3.18,0,0,0,4.9,1.727M4.9,0A4.9,4.9,0,1,1,0,4.9,4.9,4.9,0,0,1,4.9,0Z" transform="translate(8213.854 2822.854)" fill={theme.palette.primary.main} />
                <path id="Rectangle_14" data-name="Rectangle 14" d="M2.22,1.727a.494.494,0,0,0-.493.493V7.586a.494.494,0,0,0,.493.493H7.586a.494.494,0,0,0,.493-.493V2.22a.494.494,0,0,0-.493-.493H2.22M2.22,0H7.586a2.22,2.22,0,0,1,2.22,2.22V7.586a2.22,2.22,0,0,1-2.22,2.22H2.22A2.22,2.22,0,0,1,0,7.586V2.22A2.22,2.22,0,0,1,2.22,0Z" transform="translate(8213.854 2812)" fill={theme.palette.primary.main} />
            </g>
        </svg>;

    /**
     *
     * @param {*} type
     * @returns
     */
    const getIcons = (type) => {
        switch (type) {
            case 'datasource':
                return datasourceIco;
            case 'dataset':
                return datasetIco;
            case 'attribute':
                return attributesIco;
            case 'domain':
                return domainsIco;
            case 'comments':
                return commentsIco;
            case 'workbook':
                return workbookIco;
            case 'worksheet':
                return worksheetIco;
            default:
                return datasourceIco;
        }
    };

    /**
     *
     * @param {*} condition
     * @param {*} then
     * @param {*} otherwise
     * @returns
     */
    const iff = (condition, then, otherwise) => (condition ? then : otherwise);


    const ResultLoop = ({ result, index }) => {

        const [open, setOpen] = React.useState(false);

        const handleClose = (value) => {
            setOpen(false);
        };

        const handleClickOpen = () => {
            setOpen(true);
        };

        const hasSensitiveInfo = Boolean(result.sensitive_attributes && result.sensitive_attributes >= 1);
        return (
            <Grid item key={index} className={classes.listContainer}>
                <Grid container>
                    <Grid item xs={8}>
                        <Grid container alignItems="left" wrap="nowrap">
                            <Grid className={classes.flexCont} onClick={() => redirectToSelectedItem(result)}>
                                {getIcons(result.type)}
                                {
                                    result.type && result.type !== 'comments' && result.type !== 'attribute' &&
                                    <ToolTipComponent title={result.name ? result.name : `Untitled ${result.type}`} arrow>
                                        <Link style={{ textDecoration: 'unset', color: 'inherit' }} to={getRedirectToSelectedItemLink(result)}>
                                            <Typography variant="h4" component="span" className={classes.linkHeading} >
                                                {result.name ? result.name : `Untitled ${result.type}`}
                                            </Typography>
                                        </Link>
                                    </ToolTipComponent>
                                }
                                {
                                    result.type && result.type === 'comments' &&
                                    <Link style={{ textDecoration: 'unset', color: 'inherit' }} to={getRedirectToSelectedItemLink(result)}>
                                        <Typography variant="h4" component="span" className={classes.linkHeading} >
                                            {result.datasource_name ? result.datasource_name : ''}
                                            {result.dataset_name ? ' / ' : ''}
                                            {result.dataset_name ? result.dataset_name : ''}
                                        </Typography>
                                    </Link>
                                }
                                {
                                    result.type && result.type === 'attribute' &&
                                    <ToolTipComponent title={result.name ? result.name : ''} arrow>
                                        <Link style={{ textDecoration: 'unset', color: 'inherit' }} onClick={() => redirectToSelectedItem(result)} to={getRedirectToSelectedItemLink(result)}>
                                            <Typography variant="h4" component="span" className={classes.linkHeading} >
                                                {result.name ? result.name : ''}
                                            </Typography>
                                        </Link>
                                    </ToolTipComponent>
                                }
                            </Grid>

                            {
                                result.type && result.type !== 'domain' &&
                                <Grid alignItems="center" className={classes.flexCont}>
                                    <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="18" viewBox="0 0 24 24" width="18" fill={result.ratings ? "#F4B206" : "#B5B6B8"}>
                                        <g>
                                            <path d="M0,0h24v24H0V0z" fill="none" />
                                            <path d="M0,0h24v24H0V0z" fill="none" />
                                        </g>
                                        <g>
                                            <path d="M12,17.27L18.18,21l-1.64-7.03L22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.46,4.73L5.82,21L12,17.27z" />
                                        </g>
                                    </svg>
                                    <Typography variant="h6" component="span" className={classes.starLabel}>
                                        {result.ratings ? result.ratings.toFixed(1) : '0'}
                                    </Typography>
                                </Grid>
                            }

                            {
                                result.type && result.type !== 'comments' && result.connectortype !== 'Tableau' &&
                                <Grid alignItems="center" className={classes.flexCont}>
                                    <ToolTipComponent title={"DQScore"} arrow>
                                        <span className={classes.marginRight5}>
                                            <Circular
                                                showPercentage={Boolean(result.dqscore && result.dqscore !== 0)}
                                                showValue
                                                value={result.dqscore}
                                                size={40}
                                                dqScoreThreshold={result?.dq_score_threshold && Object.keys(result.dq_score_threshold).length > 0 ? result.dq_score_threshold : dq_score_threshold}
                                            />
                                        </span>
                                    </ToolTipComponent>
                                    {
                                        result.impact_score > 0 &&
                                        <ToolTipComponent title={"Impact Score"} arrow>
                                            <span className={classes.flex}>
                                                <ArrowDropUpIcon
                                                    className={
                                                        classNames(
                                                            classes.impactIcon,
                                                            classes.impactArrow
                                                        )
                                                    }
                                                />
                                                <Typography className={classes.curateTxt}>
                                                    {`${getValue(result.impact_score)}%`}
                                                </Typography>
                                            </span>
                                        </ToolTipComponent>
                                    }
                                </Grid>
                            }
                            {
                                hasSensitiveInfo &&
                                <Grid alignItems="center" className={classes.flexCont}>
                                    <ToolTipComponent title={'Contains Sensitive Information'} arrow>
                                        <svg version="1.2" baseProfile="tiny-ps" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" width="32" height="32">
                                            <g id="Logo" clipPath="url(#clip-Logo)">
                                                <g id="Group_4" data-name="Group 4" transform="translate(-18.99 -64.748)">
                                                    <g id="Group_3" data-name="Group 3" transform="translate(29.99 87.748)">
                                                        <line id="Line_1" data-name="Line 1" x2="31.611" transform="translate(86.008 38.817)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeWidth="8" />
                                                        <line id="Line_2" data-name="Line 2" x2="38.119" transform="translate(86.008 58.315)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeWidth="8" />
                                                        <g id="Group_2" data-name="Group 2" transform="translate(18.138 16.479)">
                                                            <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(10.103 0)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8">
                                                                <circle cx="20.159" cy="20.159" r="20.159" stroke="none" />
                                                                <circle cx="20.159" cy="20.159" r="16.159" fill="none" />
                                                            </g>
                                                            <path id="Path_1" data-name="Path 1" d="M226.1,131.262c.074-.283,3.875-28.637,29.415-28.637s30.225,28.907,29.96,28.637S226.029,131.544,226.1,131.262Z" transform="translate(-226.103 -60.531)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" />
                                                        </g>
                                                        <g id="Group_1" data-name="Group 1" transform="translate(108.877 66.576)">
                                                            <g id="Rectangle_2" data-name="Rectangle 2" transform="translate(0 27.27)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8">
                                                                <rect width="69.739" height="59.932" rx="10" stroke="none" />
                                                                <rect x="4" y="4" width="61.739" height="51.932" rx="6" fill="none" />
                                                            </g>
                                                            <path id="Path_2" data-name="Path 2" d="M339.964,142.246s-4.831-29.99,19.725-29.889,19.222,29.889,19.222,29.889" transform="translate(-324.329 -112.357)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" />
                                                            <g id="Rectangle_3" data-name="Rectangle 3" transform="translate(6.538 33.808)" fill="#fff" stroke="#fff" strokeLinecap="round" strokeWidth="10">
                                                                <rect width="56.663" height="47.945" rx="3" stroke="none" />
                                                                <rect x="5" y="5" width="46.663" height="37.945" rx="2" fill="none" />
                                                            </g>
                                                            <circle id="Ellipse_2" data-name="Ellipse 2" cx="7.628" cy="7.628" r="7.628" transform="translate(27.242 49.064)" fill={theme.palette.primary.main} />
                                                        </g>
                                                        <path id="Path_3" data-name="Path 3" d="M349.571,85.856s1.193-37.659,0-40.24-4.906-6.753-10.15-6.854-122.488.394-127.475.193-6.955,1.994-6.955,9.317v99.934s.666,6.854,9.51,7.075,16.361,0,16.361,0,4.276-10.853,12.179-11.008,12.241,11.008,12.241,11.008l38.119-.471" transform="translate(-204.99 -38.748)" fill="none" stroke={theme.palette.primary.main} strokeLinecap="round" strokeLinejoin="round" strokeWidth="8" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </ToolTipComponent>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container alignItems="left" justify="flex-end">
                            {
                                result.type && result.type === 'workbook' &&
                                <Grid alignItems="center" className={classes.flexCont}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="17.296" height="17.296" viewBox="0 0 17.296 17.296">
                                        <g id="flowchart_1_" data-name="flowchart (1)" transform="translate(0.175 17.121) rotate(-90)">
                                            <g id="Group_2598" data-name="Group 2598" transform="translate(0 13.639)">
                                                <g id="Group_2597" data-name="Group 2597">
                                                    <rect id="Rectangle_1216" data-name="Rectangle 1216" width="3.306" height="3.306" fill="#f38080" stroke="#fff" strokeWidth="0.35" />
                                                </g>
                                            </g>
                                            <g id="Group_2600" data-name="Group 2600" transform="translate(13.639 13.639)">
                                                <g id="Group_2599" data-name="Group 2599">
                                                    <rect id="Rectangle_1217" data-name="Rectangle 1217" width="3.306" height="3.306" fill="#f38080" stroke="#fff" strokeWidth="0.35" />
                                                </g>
                                            </g>
                                            <g id="Group_2602" data-name="Group 2602" transform="translate(6.613 13.639)">
                                                <g id="Group_2601" data-name="Group 2601">
                                                    <rect id="Rectangle_1218" data-name="Rectangle 1218" width="3.72" height="3.306" fill="#f38080" stroke="#fff" strokeWidth="0.35" />
                                                </g>
                                            </g>
                                            <g id="Group_2604" data-name="Group 2604" transform="translate(6.613)">
                                                <g id="Group_2603" data-name="Group 2603">
                                                    <rect id="Rectangle_1219" data-name="Rectangle 1219" width="3.72" height="3.306" fill="#f38080" stroke="#fff" strokeWidth="0.35" />
                                                </g>
                                            </g>
                                            <g id="Group_2606" data-name="Group 2606" transform="translate(0.85 5.084)">
                                                <g id="Group_2605" data-name="Group 2605">
                                                    <path id="Path_1401" data-name="Path 1401" d="M8.473,2.54V0H6.778V2.54H0V6.778H1.695V4.234H6.778V6.778H8.473V4.234h5.084V6.778h1.695V2.54Z" fill="#f38080" stroke="#fff" strokeWidth="0.35" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>

                                    <Typography variant="body2" component="span" className={classes.paddingLeft10}>
                                        Lineage
                                    </Typography>
                                </Grid>
                            }
                            {
                                result.type && result.type !== 'comments' && result.type !== 'domain' && result.type !== 'attribute' &&
                                <Grid alignItems="center" className={classes.flexCont}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="18.421" viewBox="0 0 22 18.421">
                                        <path fill={theme.palette.primary.main} d="M2.371,22.928A1.379,1.379,0,0,1,1,21.557V10.278A2.845,2.845,0,0,1,3.838,7.44H17.221a2.845,2.845,0,0,1,2.845,2.838v7.517a2.845,2.845,0,0,1-2.838,2.845H6.067a.6.6,0,0,0-.352.117L3.2,22.657a1.386,1.386,0,0,1-.829.271ZM3.838,8.907a1.379,1.379,0,0,0-1.371,1.371v11.1l2.369-1.789a2.083,2.083,0,0,1,1.232-.411H17.221A1.379,1.379,0,0,0,18.6,17.795V10.278a1.379,1.379,0,0,0-1.371-1.371Z" transform="translate(-1 -4.507)" />
                                        <circle fill={theme.palette.primary.main} cx="0.733" cy="0.733" r="0.733" transform="translate(11.689 8.866)" />
                                        <circle fill={theme.palette.primary.main} cx="0.733" cy="0.733" r="0.733" transform="translate(8.844 8.866)" />
                                        <circle fill={theme.palette.primary.main} cx="0.733" cy="0.733" r="0.733" transform="translate(5.911 8.866)" />
                                        <path fill={theme.palette.primary.main} d="M23.6,14.44a.733.733,0,0,1-.733-.733V6.278A1.379,1.379,0,0,0,21.5,4.907H6.733a.733.733,0,1,1,0-1.467H21.5a2.845,2.845,0,0,1,2.838,2.838v7.429A.733.733,0,0,1,23.6,14.44Z" transform="translate(-2.333 -3.44)" />
                                    </svg>
                                    <Typography variant="body2" component="span" className={classNames(classes.paddingLeft10, classes.curserPointer)}>
                                        {result.comments ? result.comments : '0'}
                                        {' '}
                                        Comments
                                    </Typography>
                                </Grid>
                            }
                            {
                                result.type && result.type !== 'comments' && result.type !== 'attribute' &&
                                <Grid alignItems="center" className={classes.flex}>
                                    <svg version="1.1"
                                        id="Layer_1"
                                        width="22"
                                        height="22"
                                        xmlns="http://www.w3.org/2000/svg"
                                        x="0px"
                                        y="0px"
                                        viewBox="0 0 32 32" >
                                        <g id="Chart">
                                            <path fill={theme.palette.primary.main}
                                                d="M26.5,26.6v-15h-0.8v0h-4v0h-0.3v15h-2.7v-8.9h-0.8v0h-4v0h-0.3v8.9h-2.8v-4.8H10H8.8H7.7H6H5.7v4.8H4v2h24v-2
		                            H26.5z M23.3,13.7h1.1v12.9h-1.1V13.7z M15.5,19.7h1.1v6.9h-1.1V19.7z M7.7,23.8h1.1v2.8H7.7V23.8z" />
                                            <g>
                                                <g transform="translate(2.368)">
                                                    <path fill={theme.palette.primary.main}
                                                        stroke={theme.palette.primary.main}
                                                        strokeWidth="0.6"
                                                        strokeMiterlimit="10"
                                                        d="M24.1,3.9c0-0.3-0.2-0.5-0.5-0.5c0,0,0,0,0,0h-3c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5h1.8l-4.9,4.9
                                        l-1.8-1.8c-0.2-0.2-0.5-0.2-0.7,0c0,0,0,0,0,0l-8.8,8.8C6,16.5,6,16.8,6.2,17c0.2,0.2,0.5,0.2,0.7,0l0,0l8.5-8.5l1.8,1.8
                                        c0.2,0.2,0.5,0.2,0.7,0c0,0,0,0,0,0l5.2-5.2v1.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5L24.1,3.9
                                        C24.1,3.9,24.1,3.9,24.1,3.9z" />
                                                </g>
                                                <g transform="translate(1.437 8.101)">
                                                    <circle fill={theme.palette.primary.main} cx="5.8" cy="10.2" r="1" />
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    <Typography variant="body2" component="span" className={classNames(classes.paddingLeft10, classes.curserPointer)}>
                                        {result.views ? result.views : '0'}
                                        {' '}
                                        Views
                                    </Typography>
                                </Grid>

                            }

                            {/* <Grid alignItems="center" className={classes.flexCont}>
                                        <Chip icon={
                                            <svg xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">
                                                <rect fill="none" height="20" width="20" />
                                                <path d="M22,5.18L10.59,16.6l-4.24-4.24l1.41-1.41l2.83,2.83l10-10L22,5.18z M19.79,10.22C19.92,10.79,20,11.39,20,12 c0,4.42-3.58,8-8,8s-8-3.58-8-8c0-4.42,3.58-8,8-8c1.58,0,3.04,0.46,4.28,1.25l1.44-1.44C16.1,2.67,14.13,2,12,2C6.48,2,2,6.48,2,12 c0,5.52,4.48,10,10,10s10-4.48,10-10c0-1.19-0.22-2.33-0.6-3.39L19.79,10.22z" />
                                            </svg>
                                        }
                                            label="Verified"
                                            className={classes.greenChip} />
                                    </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    {
                        result.type && result.type !== 'comments' &&
                        <Typography variant="subtitle1" component="div" className={classes.searchCont} onClick={result.description?.length > 350 ? handleClickOpen : null}>
                            {result.description ? result.description : ''}
                        </Typography>
                    }
                    {
                        result.type && result.type === 'comments' &&
                        <Typography variant="subtitle1" component="div" className={classes.searchCont}>
                            {result.name ? result.name : ''}
                        </Typography>
                    }
                    {
                        result.description &&
                        <Dialog onClose={handleClose} open={open} maxWidth="lg">
                            {result.description}
                        </Dialog>
                    }
                </Grid>
                <Grid container alignItems="center">
                    <Grid item xs={7}>
                        <Grid container alignItems="left">
                            {
                                result.type && result.type === 'domain' &&
                                <Grid item className={classes.marginRight15}>
                                    <Typography variant="subtitle1" component="span">
                                        Datasources:
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                        {result.datasources ? result.datasources : '0'}
                                    </Typography>
                                </Grid>
                            }
                            {
                                result.type && (result.type === 'datasource' || result.type === 'domain') && result.connectortype !== 'Tableau' &&
                                <Grid item className={classes.marginRight15}>
                                    <Typography variant="subtitle1" component="span">
                                        Datasets
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                        {result.datasets ? result.datasets : '0'}
                                    </Typography>
                                </Grid>
                            }
                            {
                                result.type && (result.type === 'datasource' || result.type === 'dataset' || result.type === 'domain') && result.connectortype !== 'Tableau' &&
                                <Grid item className={classes.marginRight15}>
                                    <Typography variant="subtitle1" component="span">
                                        Columns:
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                        {result.attributes ? result.attributes : iff(result.dataset_total_columns, result.dataset_total_columns, 0)}
                                    </Typography>
                                </Grid>
                            }
                            {
                                result.type && (result.type === 'datasource' || result.type === 'dataset' || result.type === 'attribute' || result.type === 'domain') && result.connectortype !== 'Tableau' &&
                                <Grid item className={classes.marginRight15}>
                                    <Typography variant="subtitle1" component="span">
                                        Total Rows:
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                        {result.rows ? result.rows : iff(result.dataset_rows_count, result.dataset_rows_count, 0)}
                                    </Typography>
                                </Grid>
                            }
                            {
                                result.type && result.connectortype === 'Tableau' && result.type === 'datasource' &&
                                <Grid item className={classes.marginRight15}>
                                    <Typography variant="subtitle1" component="span">
                                        Workbooks:
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                        {result.workbooks ? result.workbooks : '0'}
                                    </Typography>
                                </Grid>
                            }
                            {
                                result.type && result.connectortype === 'Tableau' && (result.type === 'datasource' || result.type === 'workbook') &&
                                <Grid item className={classes.marginRight15}>
                                    <Typography variant="subtitle1" component="span">
                                        Worksheet:
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                        {result.worksheets ? result.worksheets : '0'}
                                    </Typography>
                                </Grid>
                            }
                            {
                                result.type && result.connectortype === 'Tableau' && (result.type === 'datasource' || result.type === 'workbook') &&
                                <Grid item className={classes.marginRight15}>
                                    <Typography variant="subtitle1" component="span">
                                        Datasource:
                                    </Typography>
                                    <Typography variant="subtitle1" component="span" className={classes.valueText}>
                                        {result.worksheets_datasources ? result.worksheets_datasources : '0'}
                                    </Typography>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                    {
                        result.type && result.type !== 'domain' &&
                        <Grid item xs={3}>
                            <Grid container alignItems="right" justify="flex-start">
                                <Grid alignItems="center" className={`${classes.flexCont} ${classes.width100}`}>
                                    <img src={getIcon(result.connectortype)} alt={"file type"} height={20} />
                                    {
                                        result.connectortype !== 'Tableau' &&
                                        <ToolTipComponent title={`${result.connectortype} ${result.database_source ? ' / ~ / ' : ''} ${result.database_source ? result.database_source : ''} ${result.dataset_source ? ' / ' : ''}  ${result.dataset_source ? result.dataset_source : ''}`} arrow>
                                            <Typography variant="subtitle1" component="span" className={`${classes.paddingLeft10} ${classes.highlight_text}`}>
                                                {result.connectortype}
                                                {result.database_source ? ' / ~ / ' : ''}
                                                {result.database_source ? result.database_source : ''}
                                                {result.dataset_source ? ' / ' : ''}
                                                {result.dataset_source ? result.dataset_source : ''}
                                            </Typography>
                                        </ToolTipComponent>
                                    }
                                    {
                                        result.connectortype === 'Tableau' &&
                                        <ToolTipComponent title={result.tableau_path} arrow>
                                            <Typography variant="subtitle1" component="span" className={`${classes.paddingLeft10} ${classes.highlight_text}`}>
                                                {result.connectortype}
                                                {result.tableau_path ? ' / ~ / ' : ''}
                                                {result.tableau_path ? result.tableau_path : ''}
                                            </Typography>
                                        </ToolTipComponent>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    <Grid item xs={2}>
                        <Grid container alignItems="left" justify="flex-end">
                            <UserList users={result && result.users ? result.users : []} itemCount={3} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    ResultLoop.propTypes = {
        result: PropTypes.object,
        index: PropTypes.number
    };

    return (
        <Grid container className={classes.searchListContainer} onScroll={onScroll}>
            {
                isLoading &&
                <Loader />
            }
            {
                !isLoading && searchResults.map((searchval, indexval) =>
                    <ResultLoop result={searchval} key={indexval} index={indexval} />
                )
            }
            {
                isLazyLoading &&
                <SkeletonLoader />
            }
        </Grid>
    );
};


SearchResultComponent.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    searchResults: PropTypes.array,
    isLoading: PropTypes.bool,
    isLazyLoading: PropTypes.bool,
    onScrollChange: PropTypes.func,
    redirectToSelectedItem: PropTypes.func,
    getRedirectToSelectedItemLink: PropTypes.func
};

export default withStyles((theme) => ({
    ...SearchStyles(theme),
    ...Styles(theme)
}), { withTheme: true })(SearchResultComponent);